import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchOrganisationProfile } from '../actions/organisationProfile';
import { CompanyProfileApiResponse } from '../models';

const fetchOrganisationProfile = () => http.get<CompanyProfileApiResponse>('/settings/subscriber/details');

function* sagaFetchOrganisationProfile() {
  try {
    const data = yield* call(fetchOrganisationProfile);
    yield put(actionFetchOrganisationProfile.success({ data }));
  } catch (error) {
    yield put(actionFetchOrganisationProfile.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchOrganisationProfile.request, sagaFetchOrganisationProfile)
];
