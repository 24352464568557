import * as React from 'react';

import { FormikProps } from 'formik';

import { BankAccountTypeEnum, PaymentMethodEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import {
  UpdateWorkspaceDirectionsApiRequestBody,
  WorkspaceDirectionsApiResponse,
  WorkspaceDirectionsCategoriesApiResponse,
  WorkspaceParticipantApiResponse
} from '@sympli/api-gateway/models';

import Formik from 'src/components/formik';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import DirectionsSummary from '../../../../components/directions-summary';
import { isDistributionValid, isSourceFundValid } from '../../../../helpers';
import { DistributionFormikModel, SourceFundFormikModel } from '../../../../models';
import DirectionForm from '../../components/direction-form';
import DirectionRecordList from '../../components/direction-record-list';
import SourceFundList from '../../components/source-fund-list';
import { NEW_SOURCEFUND } from '../../components/source-fund-list/models';
import { getKeyValueMatchingNumberInObject } from '../../helper';
import useEditDirections from '../../hooks/useEditDirections';
import { directionsSummarySelector, otherPartiesDirectionsSummarySelector } from '../../selectors';
import { getInitialDmDirection } from './helpers';
import { DischargeDirectionsFormModel, SourceFundModel } from './models';
import MortgageEditor from './mortgage-editor';
import { getValidationSchema } from './validationSchema';

const WORKSPACE_ROLE = WorkspaceRoleEnum.DischargingMortgagee;
export interface Props {
  canEdit: boolean;
  queryParams: WorkspaceDetailRouteParams;
  detail: WorkspaceDirectionsApiResponse;
  currentParticipant: WorkspaceParticipantApiResponse;
  workspaceType: WorkspaceTypeEnum;
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  portalIdForDirectionSummary: string;
  portalIdForFormSubmit: string;
  requiresStampDuty: boolean;
  onFormCancel(): void;
  havePurchaserParticipant?: boolean;
  numberOfLinkedWorkspaces?: number;
}

function DischargeMortgageDirections({
  directionsCategoriesDetail,
  portalIdForDirectionSummary,
  currentParticipant,
  workspaceType,
  canEdit,
  portalIdForFormSubmit,
  queryParams,
  detail,
  requiresStampDuty,
  onFormCancel,
  havePurchaserParticipant,
  numberOfLinkedWorkspaces
}: Props) {
  const { workspaceId, participantId, defaultDistributionsValue, onPostSubmit, isSaveAsDraft, setIsSaveAsDraft, trustAccountOptions, trustAccountBankDetailMap } =
    useEditDirections(queryParams, detail, currentParticipant, directionsCategoriesDetail.directionCategories);
  const sourceFundDefaultValue = React.useMemo(() => {
    return { ...NEW_SOURCEFUND, subscriberName: currentParticipant.name };
  }, [currentParticipant.name]);

  const getInitialValues = React.useCallback((): DischargeDirectionsFormModel => {
    const { directions, sourceFunds } = detail;
    return {
      distributions: directions.map(item => {
        const direction = getInitialDmDirection(item);
        const result: DistributionFormikModel = {
          ...defaultDistributionsValue,
          ...direction
        };
        return {
          ...result,
          isEditorOpen: !isDistributionValid(result)
        };
      }),
      sourceFunds: sourceFunds.map(item => {
        const result: SourceFundFormikModel = { ...NEW_SOURCEFUND, ...item };
        return {
          ...result,
          isEditorOpen: !isSourceFundValid(result)
        };
      })
    };
  }, [defaultDistributionsValue, detail]);

  const handleOnPreSubmit = React.useCallback(
    (values: DischargeDirectionsFormModel): UpdateWorkspaceDirectionsApiRequestBody => {
      const distributions: UpdateWorkspaceDirectionsApiRequestBody['directions'] = values.distributions
        .filter(item => !item.isLocked)
        .map(item => {
          const { bankDetails, paymentMethod, holdingAccountDetails, amount, directionCategory, category, categoryOther, bankAccountId, id, linkedSettlementItem } = item;
          const commonValues = {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id
          };

          switch (paymentMethod) {
            case PaymentMethodEnum.HoldingAccount:
              return { ...commonValues, holdingAccountDetails };
            case PaymentMethodEnum.TrustAccount:
              return { ...commonValues, bankAccountId, bankDetails };
            case PaymentMethodEnum.Linked:
              return {
                ...commonValues,
                linkedSettlementItem: {
                  reference: item.reference,
                  linkedParticipantId: linkedSettlementItem?.linkedToParticipantId!,
                  linkedWorkspaceId: linkedSettlementItem?.linkedToWorkspaceId!
                }
              };
            case PaymentMethodEnum.BankTransfer:
            default:
              return { ...commonValues, bankDetails };
          }
        });

      const sourceFunds: SourceFundModel[] = values.sourceFunds.map(item => {
        const { trustAccountId, amount, category, reference, categoryOther, id, paymentMethod } = item;

        const trustAccount = trustAccountBankDetailMap[trustAccountId];
        if (trustAccount && trustAccount.bankAccountType === BankAccountTypeEnum.SympliSourceAccount) {
          return {
            trustAccountId,
            amount: Number(amount),
            reference: paymentMethod !== PaymentMethodEnum.Linked ? trustAccount.bankDetails.accountNumber : reference,
            category,
            categoryOther,
            id,
            paymentMethod: PaymentMethodEnum.BankTransfer
          };
        }
        return { trustAccountId, amount: Number(amount), reference, category, categoryOther, id, paymentMethod: PaymentMethodEnum.BankTransfer };
      });

      return { directions: distributions, sourceFunds, isSaveAsDraft };
    },
    [isSaveAsDraft, trustAccountBankDetailMap]
  );

  return (
    <Formik
      method="post"
      action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/directions`}
      httpConfig={{ withCredentials: true }}
      getInitialValues={getInitialValues}
      validationSchema={getValidationSchema(numberOfLinkedWorkspaces)}
      onPreSubmit={handleOnPreSubmit}
      onPostSubmit={onPostSubmit}
    >
      {(formikProps: FormikProps<DischargeDirectionsFormModel>) => {
        const { distributions, sourceFunds } = formikProps.values;
        const isMultipleEditorsOpen = getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) >= 1;
        // * formik will update twice with same value (due to async validation)
        // * these selector prevent recalculation and re-render
        const otherPartiesSummary = otherPartiesDirectionsSummarySelector({
          workspaceRole: WORKSPACE_ROLE,
          directionsSummary: detail.directionsSummary,
          sourceFunds: detail.sourceFunds,
          directions: detail.directions
        });

        const updatedSummary = directionsSummarySelector({
          workspaceRole: WORKSPACE_ROLE,
          otherPartiesSummary,
          sourceFunds: detail.sourceFunds,
          distributions: detail.directions,
          loanAdvanceRequiredAmount: detail.directionsSummary.loanAdvanceRequiredAmount
        });

        // make sure all editors are closed for add and edit,
        // todo is it ok if it is edit model but no data been modified? do we want to keep it disabled?
        const distributionsInEditModel = distributions.some(item => item.isEditorOpen);
        const sourceFundsInEditModel = sourceFunds.some(item => item.isEditorOpen);

        return (
          <DirectionForm
            workflowType={detail.distributionWorkflowType}
            stepperCanEdit={canEdit && !distributionsInEditModel && !sourceFundsInEditModel}
            canEdit={canEdit}
            isSubmitting={formikProps.isSubmitting}
            isSaveAsDraft={isSaveAsDraft}
            dirty={formikProps.dirty && isMultipleEditorsOpen}
            onFormCancel={onFormCancel}
            handleOnSaveAndContinueClick={() => {
              setIsSaveAsDraft(false);
              formikProps.submitForm();
            }}
            portalIdForFormSubmit={portalIdForFormSubmit}
          >
            <DirectionRecordList
              canEdit={canEdit}
              isMultipleEditorsOpen={isMultipleEditorsOpen}
              formikProps={formikProps}
              defaultValue={defaultDistributionsValue}
              currentParticipant={currentParticipant}
              trustAccountOptions={trustAccountOptions}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              workspaceId={workspaceId}
              participantId={participantId}
              workspaceRole={WORKSPACE_ROLE}
              paymentMethodOptions={detail.directionPaymentMethods}
            >
              {index => (
                <MortgageEditor
                  detail={detail}
                  formikProps={formikProps}
                  index={index}
                  queryParams={queryParams}
                  trustAccountMap={trustAccountBankDetailMap}
                  workspaceId={workspaceId}
                  participantId={participantId}
                />
              )}
            </DirectionRecordList>
            <DirectionsSummary //
              workspaceTypeId={workspaceType}
              workspaceRoleId={WORKSPACE_ROLE}
              directionsSummary={updatedSummary}
              portalIdForDirectionSummary={portalIdForDirectionSummary}
              requiresStampDuty={requiresStampDuty}
              havePurchaserParticipant={Boolean(havePurchaserParticipant)}
            />

            <SourceFundList
              workspaceId={workspaceId}
              participantId={participantId}
              canEdit={canEdit}
              formikProps={formikProps}
              currentParticipant={currentParticipant}
              defaultValue={sourceFundDefaultValue}
              trustAccountOptions={trustAccountOptions}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              sourceFundCategories={directionsCategoriesDetail.sourceFundsCategories}
              isMultipleEditorsOpen={isMultipleEditorsOpen}
            />
          </DirectionForm>
        );
      }}
    </Formik>
  );
}

export default DischargeMortgageDirections;
