import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

export interface StyleProps {
  workspaceType: WorkspaceTypeEnum;
}

export const useTableStyles = makeStyles(
  (theme: Theme) => ({
    root({ workspaceType }: StyleProps) {
      return {
        '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: workspaceType === WorkspaceTypeEnum.RegistrationOnly ? 30 : 28
        },
        '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: workspaceType === WorkspaceTypeEnum.RegistrationOnly ? 30 : 28
        }
      };
    }
  }),
  {
    name: 'FinancialTable'
  }
);
