import queryString from 'query-string';
import { call, put, takeLatest } from 'typed-redux-saga';

import { actionFetchMessageHistory, MessageHistoryRequest } from 'src/actions/chat';
import { MessageApiResponse } from 'src/components/chat-window/models';
import http from 'src/utils/http';

const fetchMessageHistory = (payload: MessageHistoryRequest) => http.get<MessageApiResponse[]>(`/realtime/chat/history?${queryString.stringify(payload)}`);

export function* sagaFetchMessageHistory(action: ReturnType<typeof actionFetchMessageHistory.request>) {
  try {
    const messageHistory = yield* call(fetchMessageHistory, action.payload);
    const { destinationId } = action.payload;
    yield put(actionFetchMessageHistory.success({ messageHistory, roomId: destinationId }));
  } catch (error) {
    yield put(actionFetchMessageHistory.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchMessageHistory.request, sagaFetchMessageHistory)
];
