// this file was automatically generated from enums.ts.mustache
import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum TransactionInstruments$DocumentTypeEnum {
  Caveat = 'Caveat',
  Mortgage = 'Mortgage',
  ReleaseOfMortgage = 'Release of Mortgage',
  RemovalOfLapsedCaveat = 'Release of Lapsed Caveat',
  Transfer = 'Transfer',
  Transmission = 'Transmission',
  WithdrawalOfCaveat = 'Withdrawal of Caveat',
  AdversePossession = 'Adverse Possession',
  Amalgamation = 'Amalgamation',
  AmalgamationOfWaterAllocation = 'Amalgamation of Water Allocation',
  Amendment = 'Amendment',
  AnnulmentOfBankruptcy = 'Annulment of Bankruptcy',
  AppointmentOfAdministrator = 'Appointment of Administrator',
  AppointmentOfAdministratorOfAnInterest = 'Appointment of Administrator of an Interest',
  AppointmentOfManager = 'Appointment of Manager',
  AppointmentOfManagerOfAnInterest = 'Appointment of Manager of an Interest',
  AppointmentOfNewTrustees = 'Appointment of New Trustees',
  BuildingUnitPlan = 'Building Unit Plan',
  Cancellation = 'Cancellation',
  CarbonAbatementInterest = 'Carbon Abatement Interest',
  CaveatOfAnInterest = 'Caveat of an Interest',
  ChangeOfAdministeringDepartment = 'Change of Administering Department',
  ChangeOfName = 'Change of Name',
  ChangeOfNameOfAnInterest = 'Change of Name of an Interest',
  ChangeOfNameOfStrataPlan = 'Change of Name of Strata Plan',
  ChargeLifeEstate = 'Charge (Life Estate)',
  CommonwealthAcquisition = 'Commonwealth Acquisition',
  CorrectionOfName = 'Correction of Name',
  CorrectionOfNameOfAnInterest = 'Correction of Name of an Interest',
  Covenant = 'Covenant',
  DeclaredBeachArea = 'Declared Beach Area',
  Determination = 'Determination',
  DischargeOfTrustee = 'Discharge of Trustee',
  DischargeWritCaveat = 'Discharge Writ/Caveat',
  DisclaimerOfLease = 'Disclaimer of Lease',
  Easement = 'Easement',
  EasementInGross = 'Easement in Gross',
  Extinguishment = 'Extinguishment',
  GroupTitlesPlan = 'Group Titles Plan',
  HighDensityDevelopmentEasement = 'High-Density Development Easement',
  Lapsing = 'Lapsing',
  Lease = 'Lease',
  LocalGovernmentRequest = 'Local Government Request',
  Merger = 'Merger',
  MergerOfAnInterest = 'Merger of an Interest',
  MortgageOfAnInterest = 'Mortgage of an Interest',
  OrderOfTheCourt = 'Order of the Court',
  OrderOfTheCourtOfAnInterest = 'Order of the Court of an Interest',
  PartialSurrender = 'Partial Surrender',
  PartialSurrenderOfAnInterest = 'Partial Surrender of an Interest',
  PlanOfAmalgamationOfBUP = 'Plan of Amalgamation of BUP',
  PlanOfAmalgamationOfGTP = 'Plan of Amalgamation of GTP',
  PlanOfResubdivisionOfBUP = 'Plan of Resubdivision of BUP',
  PlanOfResubdivisionOfGTP = 'Plan of Resubdivision of GTP',
  PriorityOfMortgage = 'Priority of Mortgage',
  ProfitAPrendre = 'Profit a Prendre',
  RecordOfDeath = 'Record of Death',
  RecordOfDeathOfAnInterest = 'Record of Death of an Interest',
  Release = 'Release',
  Removal = 'Removal',
  RemovalOfAdministrativeAdvice = 'Removal of Administrative Advice',
  RemovalOfAdministrator = 'Removal of Administrator',
  RemovalOfTrustee = 'Removal of Trustee',
  RemovalOfTrusteeOfAnInterest = 'Removal of Trustee of an Interest',
  RequestForFirstCms = 'Request for First Cms',
  ResignationOfTrustee = 'Resignation of Trustee',
  Resumption = 'Resumption',
  ResumptionOfAnInterest = 'Resumption of an Interest',
  RetirementOfTrustee = 'Retirement of Trustee',
  RetirementOfTrusteeOfAnInterest = 'Retirement of Trustee of an Interest',
  Revocation = 'Revocation',
  RevocationOfResumptionOfLand = 'Revocation of Resumption of Land',
  Satisfaction = 'Satisfaction',
  StatutoryCharge = 'Statutory Charge',
  SubLease = 'Sub Lease',
  SubdivisionOfWaterAllocation = 'Subdivision of Water Allocation',
  Surrender = 'Surrender',
  SurrenderToTheState = 'Surrender to the State',
  SurveyPlan = 'Survey Plan',
  TransferByMortgagee = 'Transfer by Mortgagee',
  TransferOfAnInterest = 'Transfer of an Interest',
  TransmissionByBankruptcy = 'Transmission by Bankruptcy',
  TransmissionByBankruptcyOfAnInterest = 'Transmission by Bankruptcy of an Interest',
  TransmissionOfAnInterest = 'Transmission of an Interest',
  Vesting = 'Vesting',
  VestingOfAnInterest = 'Vesting of an Interest',
  WaterAllocationChange = 'Water Allocation Change',
  Withdrawal = 'Withdrawal',
  Writ = 'Writ',
  WritOfAnInterest = 'Writ of an Interest'
}
export const TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<string, string>[] = Object.values(TransactionInstruments$DocumentTypeEnum).map(
  (name: string): LookupEnumModel<string, string> => ({
    id: name,
    name
  })
);
