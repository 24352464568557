import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { StampDutyProgressStatusEnum } from '../../models';
import userStyles from './styles';

interface Props {
  stampDutyStatus: StampDutyProgressStatusEnum;
  onClick?: () => void;
  href?: string;
  disabled: boolean;
  loading?: boolean;
}

export default React.memo(function ActionButtons(props: Props) {
  const { stampDutyStatus, onClick, href, disabled, loading } = props;
  const classes = userStyles();
  switch (stampDutyStatus) {
    case StampDutyProgressStatusEnum.TransactionCreated:
    case StampDutyProgressStatusEnum.VerificationError:
    case StampDutyProgressStatusEnum.TransactionCancellationError:
      return (
        <SympliButton onClick={onClick} arrowRight isLoading={loading} disabled={disabled || loading} color="primary" variant="contained">
          Verify Stamp Duty
        </SympliButton>
      );
    case StampDutyProgressStatusEnum.Verifying:
      return (
        <SympliButton onClick={onClick} arrowRight isLoading={loading} disabled={true} color="primary" variant="contained">
          Verify Stamp Duty
        </SympliButton>
      );
    case StampDutyProgressStatusEnum.Verified:
      return (
        <FlexLayout>
          <SympliButton onClick={onClick} isLoading={loading} disabled={disabled || loading} color="primary" variant="outlined" className={classes.buttonMarginRight}>
            Reverify
          </SympliButton>
          <SympliButton href={href} arrowRight color="primary" variant="contained">
            See settlement distributions
          </SympliButton>
        </FlexLayout>
      );
    case StampDutyProgressStatusEnum.Paid:
      return (
        <SympliButton href={href} arrowRight color="primary" variant="contained">
          See settlement distributions
        </SympliButton>
      );

    case StampDutyProgressStatusEnum.ReadyForTransactionCreation:
    case StampDutyProgressStatusEnum.CancellingTransaction:
      return null;

    case StampDutyProgressStatusEnum.NotApplicable:
    case StampDutyProgressStatusEnum.PreparingDocument:
    default:
      return <span>`Unsupported status: ${stampDutyStatus}`</span>;
  }
});
