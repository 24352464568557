import { createAction, createAsyncAction } from 'typesafe-actions';

import { UserProfileModel } from '@sympli/api-gateway/shared';

export const actionFetchProfile = createAsyncAction(
  //
  'FETCH_PROFILE',
  'FETCH_PROFILE_SUCCESS',
  'FETCH_PROFILE_ERROR'
)<
  //
  undefined,
  { data: UserProfileModel },
  { error: Error; redirectOnClose?: string }
>();

export const actionUpdateProfile = createAction(
  //
  'UPDATE_PROFILE'
)<
  //
  Partial<UserProfileModel>
>();

export const actionRequireLinkDevice = createAction(
  //
  'REQUIRE_LINK_DEVICE'
)<undefined>();
