import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    iconRightContainer: {
      flexDirection: 'row-reverse'
    },
    toolTipTitle: {
      fontWeight: 'bold',
      fontSize: 13,
      color: colors.WHITE
    },
    toolTipDescription: {
      fontSize: 13,
      color: colors.WHITE
    },
    infoIcon: {
      width: 11,
      height: 11,
      marginLeft: 3
    }
  });

export type ClassKeys = typeof styles;

export default styles;
