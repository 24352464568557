import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import {
  actionFormatter,
  addressFormatter,
  participantsFormatter,
  proprietorsFormatter,
  settlementDateTimeFormatter,
  titleFormatter,
  workspaceTypeFormatter
} from 'src/containers/dashboard/shared/formatters';
import { titleAddress } from 'src/utils/formatters';
import { modelKey } from 'src/utils/formUtils';
import { ActiveWorkspaceItem } from './models';

const cf = columnFormatterCheck<ActiveWorkspaceItem>();
type WithExtra<T, ExtraProps> = {
  [P in keyof T]: Omit<T[P], keyof ExtraProps> & ExtraProps;
};

function dynamicColumns<R extends object, CH>(
  conditionalColumns: WithExtra<
    //
    ColumnsModel<R>,
    {
      thClassName?: ((args: CH) => string | undefined) | string;
      className?: ((args: CH) => string | undefined) | string;
      label: ((args: CH) => string | JSX.Element) | (string | JSX.Element);
      visibilityCheck?: (args: CH) => boolean;
    }
  >,
  args: CH
): ColumnsModel<R> {
  // recalculate columns based on their visibility
  const columns: ColumnsModel<R> = Object.entries(conditionalColumns) //
    .reduce((acc: ColumnsModel<R>, [key, { visibilityCheck, label, thClassName, className, ...columnDef }]) => {
      if (visibilityCheck?.(args)) {
        if (typeof label === 'function') {
          label = label(args);
        }

        if (typeof thClassName === 'function') {
          thClassName = thClassName(args);
        }

        if (typeof className === 'function') {
          className = className(args);
        }

        acc[key] = {
          ...columnDef,
          label,
          thClassName,
          className
        };
      }
      return acc;
    }, {});

  // return only visible columns
  return columns;
}

type DynamicArgs = {
  workspaceType: WorkspaceTypeEnum;
  role?: WorkspaceRoleEnum;
};
const fieldName = modelKey<ActiveWorkspaceItem>();

export const getColumns = ({ workspaceType, role }: DynamicArgs) => {
  const args: DynamicArgs = {
    workspaceType
  };

  return resolveColumns(args);
};

function resolveColumns(args: DynamicArgs) {
  const columns: ColumnsModel<ActiveWorkspaceItem> = dynamicColumns<ActiveWorkspaceItem, DynamicArgs>(
    {
      titles: {
        label: 'TITLE',
        visibilityCheck() {
          return true;
        },
        thClassName: 'w-[108px]',
        sortable: false,
        name: fieldName('titles'),
        get: ({ titles, sympliId }: ActiveWorkspaceItem) => ({
          //
          titles: titles.map(t => t.reference),
          sympliId
        }),
        formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'title':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <b className="leading-[20px]">TITLES</b>
                  {row.titles.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item.reference} {item.landDescriptions}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      addresses: {
        label: 'ADDRESS',
        thClassName: 'w-[160px]',
        visibilityCheck() {
          return true;
        },
        name: fieldName('addresses'),
        sortable: false,
        get: ({ addresses, jurisdiction }: ActiveWorkspaceItem) => ({ addresses, jurisdictionId: jurisdiction }),
        formatter: cf<{ addresses: ActiveWorkspaceItem['addresses']; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <b className="leading-[20px]">ADDRESS</b>
                  {row.addresses.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {titleAddress(item, row.jurisdiction)}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      participants: {
        label: 'PARTICIPANTS',
        thClassName: 'w-[160px]',
        visibilityCheck() {
          return true;
        },
        name: fieldName('participants'),
        sortable: false,
        get: ({ participants }: ActiveWorkspaceItem) => ({ participants }),
        formatter: cf<{ participants: ActiveWorkspaceItem['participants'] }>(participantsFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'participant':
            case 'role':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <Box>
                  <Typography variant="body2_bold" className="text-[var(--neutral-000)]">
                    PARTICIPANTS
                  </Typography>
                  {row.participants.map((item, i) => {
                    return (
                      <Box key={i} className="mt-[8px]">
                        <Typography variant="body2" className="leading-[18px] text-[var(--neutral-000)]">
                          {item.subscriberName} - {!item.hasAccepted ? 'Invited as ' : ''}
                          {item.role.toUpperCase()}
                        </Typography>
                        <Typography variant="body2" className="leading-[18px] text-[var(--neutral-400)]">
                          {item.assignedGroupName && `(Assigned to group: ${item.assignedGroupName})`}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              );
            default:
              return null;
          }
        }
      },
      proprietors: {
        label: 'PROPRIETOR ON TITLE',
        thClassName: 'w-[100px]',
        visibilityCheck() {
          return true;
        },
        name: fieldName('proprietors'),
        sortable: false,
        get: ({ proprietors }: ActiveWorkspaceItem) => ({ proprietors }),
        formatter: cf<{ proprietors: ActiveWorkspaceItem['proprietors'] }>(proprietorsFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'first-proprietor':
            case 'second-proprietor':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <b className="leading-[20px]">PROPRIETOR ON TITLE</b>
                  {row.proprietors.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] leading-[18px] text-[var(--neutral-000)]">
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      workspaceType: {
        label: 'WS TYPE',
        thClassName: 'w-[88px]',
        visibilityCheck() {
          return true;
        },
        name: fieldName('workspaceType'),
        sortable: false,
        get: ({ workspaceType }: ActiveWorkspaceItem) => ({ workspaceType }),
        formatter: cf<{ workspaceType: ActiveWorkspaceItem['workspaceType'] }>(workspaceTypeFormatter)
      },
      setlDate: {
        thClassName: 'w-[90px]',
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SETL DATE',
        visibilityCheck() {
          return true;
        },
        name: fieldName('expectedSettlementDate'),
        get: ({ workspaceStatusId, pendingProposedSettlementDate, expectedSettlementDate }: ActiveWorkspaceItem) => ({
          workspaceStatusId,
          pendingProposedSettlementDate,
          expectedSettlementDate,
          primaryClassName: 'text-[var(--neutral-800)]'
        }),
        formatter: cf<{
          //
          workspaceStatusId: ActiveWorkspaceItem['workspaceStatusId'];
          pendingProposedSettlementDate: ActiveWorkspaceItem['pendingProposedSettlementDate'];
          expectedSettlementDate: ActiveWorkspaceItem['expectedSettlementDate'];
          primaryClassName: string;
        }>(settlementDateTimeFormatter)
      },
      action: {
        label: 'ACTION',
        visibilityCheck({ workspaceType }: DynamicArgs) {
          if (workspaceType === WorkspaceTypeEnum.RegistrationOnly) return false;
          return true;
        },
        // do not set width
        sortable: false,
        name: fieldName('joinWorkspaceCondition'),
        get: ({ showIndicator, clickHandler, disabled, joinWorkspaceCondition }: ActiveWorkspaceItem) => ({
          showIndicator,
          clickHandler,
          disabled,
          joinWorkspaceCondition
        }),
        formatter: cf<{
          clickHandler: ActiveWorkspaceItem['clickHandler'];
          showIndicator: ActiveWorkspaceItem['showIndicator'];
          disabled: ActiveWorkspaceItem['disabled'];
          joinWorkspaceCondition: ActiveWorkspaceItem['joinWorkspaceCondition'];
        }>(actionFormatter)
      }
    },
    args
  );
  return columns;
}
