import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { QLD_FORM25_ADDRESS_FORM_CONFIG, QLD_FORM25_PARTY_FORM_CONFIG } from './config';
import { ForeignTypeEnum } from './enums';
import { qldForm25FallbackMap } from './fallback';
import { ApiForm25_2_24_1Model, ForeignShareholderModel, Form25_2_24_1Model } from './models';

class Converter implements IConverter<Form25_2_24_1Model, ApiForm25_2_24_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: QLD_FORM25_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_FORM25_PARTY_FORM_CONFIG
});

export function convertFromApiToFormModel(apiModel: ApiForm25_2_24_1Model): Form25_2_24_1Model {
  const { partyBook, addressBook } = apiModel;
  let formModel: Form25_2_24_1Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(partyBook),
    addressBook: convertAddressBookFromApiToFormModel(addressBook)
  };

  formModel = applyDefaultMap(formModel, qldForm25FallbackMap);

  return formModel;
}

export function convertFromFormToApiModel(formModel: Form25_2_24_1Model, originalApiModel: ApiForm25_2_24_1Model): ApiForm25_2_24_1Model {
  formModel = applyVisibilityFallbackMap(formModel, qldForm25FallbackMap);
  const {
    //
    partyBook: formPartyBook,
    addressBook: formAddressBook,
    countryCode: formCountryCode,
    foreignType,
    foreignShareholders: formForeignShareholders,
    ...rest
  } = formModel;

  const foreignShareholders: ForeignShareholderModel[] =
    foreignType === ForeignTypeEnum.WhollyForeignCompany && formForeignShareholders.length === 1 //
      ? [{ ...formForeignShareholders[0], percent: 100 }]
      : formForeignShareholders;

  const countryCode: string =
    foreignType === ForeignTypeEnum.NaturalPerson //
      ? formCountryCode
      : getCountryCodeFromForeignShareholders(foreignShareholders);

  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel.partyBook),
    addressBook: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel.addressBook),
    countryCode,
    foreignType,
    foreignShareholders
  };
}

export function getCountryCodeFromForeignShareholders(foreignShareholders: ForeignShareholderModel[]): string {
  const foreignShareholdersCopy: ForeignShareholderModel[] = [...foreignShareholders];
  const majorityCountryShareholder = foreignShareholdersCopy.sort((a: ForeignShareholderModel, b: ForeignShareholderModel) => b.percent - a.percent)[0].country;
  return majorityCountryShareholder;
}

export default new Converter();
