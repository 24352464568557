import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      position: 'fixed',
      top: 'calc((100% - 265px) / 2)',
      left: 'calc((100% - 712px) / 2)',
      zIndex: 2,

      width: 610,

      display: 'flex',
      padding: '27px 50px',
      flexDirection: 'column',
      borderRadius: 16,
      background: colors.GREEK_WATERS_TRANSLUCENT,
      boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.25)',
      alignItems: 'center'
    },

    title: {
      color: colors.SYMPLI_GREEN,
      textAlign: 'center',
      fontFamily: 'volkswagen_serial-xbold',
      fontSize: 26,
      fontWeight: 800,
      lineHeight: '24px',
      marginTop: 10
    },

    divider: {
      width: 32,
      height: 3,
      borderRadius: 3,
      background: colors.SUNNY_DAY,
      marginTop: 4,
      marginBottom: 16
    },

    text: {
      color: colors.NEUTRAL_800,
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px'
    },

    subText: {
      color: colors.NEUTRAL_600,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px'
    },

    button: {
      width: 88,
      height: 26,
      fontSize: 12,
      fontFamily: 'Roboto',
      lineHeight: '20px',
      fontWeight: 700,
      minHeight: 26,
      padding: '0px 24px',
      marginBottom: 10,
      color: colors.WHITE,
      backgroundColor: colors.SUNNY_DAY,

      '&:hover': {
        backgroundColor: colors.SUNNY_DAY_DARK
      }
    }
  }),
  {
    name: 'WelcomeMessage'
  }
);
