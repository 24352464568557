import { createAsyncAction } from 'typesafe-actions';

import { WorkspaceDirectionsOverviewApiResponse } from '@sympli/api-gateway/models';

import { DirectionsApiRequest } from '../directions/sagas';

export const actionFetchAllDirections = createAsyncAction(
  //
  'FETCH_ALL_DIRECTIONS',
  'FETCH_ALL_DIRECTIONS_SUCCESS',
  'FETCH_ALL_DIRECTIONS_ERROR'
)<
  //
  DirectionsApiRequest,
  { data: WorkspaceDirectionsOverviewApiResponse },
  { error: Error }
>();

export interface UpdateAllDistributionParticipantModel {
  distributionsParticipant: Partial<WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]>;
  participantId: string;
}
export const actionUpdateAllDistributionParticipant = createAsyncAction(
  //
  'UPDATE_ALL_DISTRIBUTION_PARTICIPANT',
  'UPDATE_ALL_DISTRIBUTION_PARTICIPANT_SUCCESS',
  'UPDATE_ALL_DISTRIBUTION_PARTICIPANT_ERROR'
)<
  //
  UpdateAllDistributionParticipantModel,
  { data: WorkspaceDirectionsOverviewApiResponse },
  { error: Error }
>();
