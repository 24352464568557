import { endOfToday, endOfYesterday, isBefore } from 'date-fns';
import _get from 'lodash-es/get';
import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { memoizeSchemaWithContext, testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { getParentProprietorPath } from '../../../../transmission-application-without-duty/2-21-1/helpers';
import {
  TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS,
  TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS
} from '../../enums';
import { titlesHasMatchingPartyNameAndPartyType, titlesHasSameTenancyStructureAndNumberOfGroups } from '../../helpers';
import { IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { DateOfDeathModel, DeceasedJointTenantsModel, DeceasedProprietorModel, EvidenceModel, NoticeOfDeath2_21_1Model } from '../../models';
import {
  VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE,
  VISIBILITY_CHECK_DECEASED_JOINT_TENANTS,
  VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE,
  VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE
} from '../../visibilityChecks';

interface NoticeOfDeathContext {
  partyBook: NoticeOfDeath2_21_1Model['partyBook'];
  selectedTitleReferences: NoticeOfDeath2_21_1Model['titleReferences'];
  deceasedJointTenants: NoticeOfDeath2_21_1Model['deceasedJointTenants'];
}

export const MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE = 'The parties on the selected titles do not match; please provide name justification or remove the mismatched titles.';
export const MUST_BE_TODAY_OR_PAST_DATE = 'Must be today or a past date';
export const MUST_BE_PAST_DATE = 'Must be a past date';
export const EVIDENCE_DATE_MUST_BE_EARLIER_THAN_DATE_OF_DEATH = 'Evidence date can not be earlier than date of death';
export const PLEASE_SELECT_A_JOIN_TENANT = 'Please select a joint tenant';

const contextResolver = ({ partyBook, titleReferences, deceasedJointTenants }: NoticeOfDeath2_21_1Model): NoticeOfDeathContext => ({
  //
  partyBook,
  deceasedJointTenants,
  selectedTitleReferences: titleReferences.filter(tr => tr.isSelected)
});

const END_OF_TODAY = endOfToday();
export const END_OF_YESTERDAY = endOfYesterday();

const deceasedJointTenantsValidationSchema = yup
  .array<DeceasedJointTenantsModel>()
  .of(
    yup.object<DeceasedJointTenantsModel>({
      deceasedProprietors: yup
        .array()
        .of<DeceasedProprietorModel>(
          yup
            .object<DeceasedProprietorModel>({
              deceasedProprietorId: yup.mixed<string>(),
              isAffectedProprietor: yup.mixed<boolean>(),
              partyBookId: yup.string().default('').trim().required(msg.REQUIRED), // suspicious validation, user can't see this!
              dateOfDeath: yup.mixed<DateOfDeathModel>().test(
                validateWhenVisible({
                  visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE,
                  validationWhenVisible: yup
                    .object<DateOfDeathModel>()
                    .nullable()
                    .shape({
                      dateOfDeathType: yup
                        .mixed()
                        .required(msg.REQUIRED)
                        .oneOf(
                          getLookupValuesAllowingEmpty(TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS),
                          msg.INVALID_SELECTION
                        ),
                      deathDate: yup
                        .mixed<Date | string | null>()
                        .nullable()
                        .test(
                          validateWhenVisible({
                            visibilityCheck: VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE,
                            validationWhenVisible: yupDatePicker //
                              .required(msg.REQUIRED)
                              .max(END_OF_YESTERDAY, MUST_BE_PAST_DATE)
                          })
                        ),
                      fromDate: yup
                        .mixed<Date | string | null>()
                        .nullable()
                        .test(
                          validateWhenVisible({
                            visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
                            validationWhenVisible: yupDatePicker //
                              .required(msg.REQUIRED)
                              .max(END_OF_YESTERDAY, MUST_BE_PAST_DATE)
                          })
                        ),
                      toDate: yup
                        .mixed<Date | string | null>()
                        .nullable()
                        .test(
                          validateWhenVisible({
                            visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
                            validationWhenVisible: yupDatePicker //
                              .required(msg.REQUIRED)
                              .max(END_OF_YESTERDAY, MUST_BE_PAST_DATE)
                              .test(
                                testContextualRule({
                                  uniqueTestName: '"deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.toDate" contextual validation rule #1',
                                  requirement: (_, context: DateOfDeathModel): boolean => {
                                    return isNotNullOrEmpty(context.fromDate) && isBefore(new Date(context.fromDate!), new Date(context.toDate!));
                                  },
                                  message: 'To date cannot be equal to or earlier than From date'
                                })
                              )
                              .defined()
                          })
                        ),
                      dateDescription: yup
                        .mixed()
                        .nullable()
                        .test(
                          validateWhenVisible({
                            visibilityCheck: VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION,
                            validationWhenVisible: yup.string().default('').trim().nullable().required(msg.REQUIRED).max(200, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(200))
                          })
                        )
                    }),
                  isObjectOrArray: true
                })
              ),
              evidence: yup.mixed<EvidenceModel>().test(
                validateWhenVisible({
                  visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE,
                  validationWhenVisible: yup
                    .object<EvidenceModel>()
                    .nullable()
                    .shape({
                      evidenceTypeValue: yup
                        .mixed()
                        .required(msg.REQUIRED)
                        .oneOf(
                          getLookupValuesAllowingEmpty(TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS),
                          msg.INVALID_SELECTION
                        ),
                      evidenceDate: yup.mixed<Date | string | null>().test(
                        validateWhenVisible({
                          visibilityCheck: VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE,
                          validationWhenVisible: yupDatePicker //
                            .required(msg.REQUIRED)
                            .max(END_OF_TODAY, MUST_BE_TODAY_OR_PAST_DATE)
                            .test('Validate evidence date', EVIDENCE_DATE_MUST_BE_EARLIER_THAN_DATE_OF_DEATH, function test(this: yup.TestContext, evidenceDate: Date) {
                              const rootModel = this.options.context!;
                              const parentProprietorPath = getParentProprietorPath(this.path);
                              const parentDeceasedProprietor: DeceasedProprietorModel = _get(rootModel, parentProprietorPath);

                              if (VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE(parentDeceasedProprietor.dateOfDeath!)) {
                                if (isNotNullOrEmpty(parentDeceasedProprietor.dateOfDeath?.deathDate)) {
                                  const deathDate = new Date(parentDeceasedProprietor.dateOfDeath!.deathDate!);
                                  deathDate.setHours(0, 0, 0, 0);
                                  return !isBefore(evidenceDate, deathDate);
                                }
                              }
                              if (VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(parentDeceasedProprietor.dateOfDeath!)) {
                                if (isNotNullOrEmpty(parentDeceasedProprietor.dateOfDeath?.toDate)) {
                                  const toDate = new Date(parentDeceasedProprietor.dateOfDeath!.toDate!);
                                  toDate.setHours(0, 0, 0, 0);
                                  return !isBefore(evidenceDate, toDate);
                                }
                              }
                              return true;
                            })
                        })
                      ),
                      evidenceDocumentReference: yup.mixed().test(
                        validateWhenVisible({
                          visibilityCheck: VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE,
                          validationWhenVisible: yup
                            .string()
                            .default('')
                            .trim()
                            .nullable()
                            .test(
                              testContextualRule({
                                /**
  * what we found in schema:
  [{"required":true,"message":"Must be present if Evidence Type is one of 'Death Certificate', 'Coroners Report' or 'Medical Certificate certifying death'","onlyIf":{"evidenceTypeValue":{"$not":0}}}]
  *
  */
                                uniqueTestName:
                                  '"titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].evidence.evidenceDocumentReference" contextual validation rule #1',
                                onlyIf: IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_DOCUMENT_REFERENCE,
                                requirement: isNotNullOrEmpty,
                                message: msg.REQUIRED
                              })
                            )
                        })
                      )
                    }),
                  isObjectOrArray: true
                })
              )
            })
            .defined()
        )
        .required()
        .defined(),
      isSelected: yup.bool(),
      tenancyType: yup.mixed()
    })
  )
  .defined()
  .log();

export const resolveDeceasedJointTenantsValidationSchema = yup.mixed<DeceasedJointTenantsModel[]>().test(
  validateWhenVisible({
    isObjectOrArray: true,
    visibilityCheck: VISIBILITY_CHECK_DECEASED_JOINT_TENANTS,
    validationWhenVisible: deceasedJointTenantsValidationSchema
  })
);

export const yupDeceasedJointTenantsSectionValidation = memoizeSchemaWithContext(
  yup.mixed<unknown>().test('Deceased proprietor groups validation', 'Validate deceased proprietor groups', function test(this: yup.TestContext<NoticeOfDeathContext>) {
    const { selectedTitleReferences, partyBook, deceasedJointTenants } = this.options.context!;

    if (selectedTitleReferences.length < 1 || deceasedJointTenants.length < 1) {
      return true;
    }

    if (!titlesHasSameTenancyStructureAndNumberOfGroups(selectedTitleReferences)) return true;
    if (!titlesHasMatchingPartyNameAndPartyType(selectedTitleReferences, partyBook)) {
      return this.createError({ message: MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE });
    }

    if (deceasedJointTenants.every(group => group.deceasedProprietors.every(x => !x.isAffectedProprietor))) {
      return this.createError({ message: PLEASE_SELECT_A_JOIN_TENANT });
    }

    return true;
  }),
  contextResolver
);

export default memoizeSchemaWithContext(deceasedJointTenantsValidationSchema, contextResolver);
