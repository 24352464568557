import { AdditionalAttachmentsDocumentTypeEnum } from '@sympli-mfe/enums-2-19/wa';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache
// only subset of NECDS lookup options
export const ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<AdditionalAttachmentsDocumentTypeEnum>[] = [
  {
    id: AdditionalAttachmentsDocumentTypeEnum.AustralianSecuritiesInvestmentCommissionCertificate,
    name: 'Australian Securities Investment Commission Certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.ChangeOfNameCertificate,
    name: 'Change of name certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.CitizenshipCertificate,
    name: 'Citizenship certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.ConsentLetter,
    name: 'Consent letter'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.CourtOrder,
    name: 'Court Order'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.MarriageCertificate,
    name: 'Marriage certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.Other,
    name: 'Other'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.StateAdministrativeTribunalOrder,
    name: 'State Administrative Tribunal Order'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.StatutoryDeclaration,
    name: 'Statutory Declaration'
  }
];

export enum Caveats$Caveators$PartyCapacityEnum {
  None = 0,
  Administrator = 2,
  Executor = 5,
  TrusteeInBankruptcy = 9
}
