import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    iconButton: {
      marginRight: 20
    },
    downloadLabel: {
      textDecoration: 'underline',
      fontWeight: 'bold'
    },
    downloadIcon: {
      color: theme.palette.primary.main,
      paddingRight: 5
    }
  }),
  {
    name: 'ReportHistoryDownloadButton'
  }
);
