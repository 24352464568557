import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiExtensionOfPriorityNoticeModel, ExtensionOfPriorityNoticeModel } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<ExtensionOfPriorityNoticeModel, ApiExtensionOfPriorityNoticeModel> {
  fromApiToFormModel(apiModel: ApiExtensionOfPriorityNoticeModel, _context: IConverterContext): ExtensionOfPriorityNoticeModel {
    // Implement any necessary conversion from API to form values
    let formModel: ExtensionOfPriorityNoticeModel = {
      ...apiModel
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: ExtensionOfPriorityNoticeModel, _originalApiModel: ApiExtensionOfPriorityNoticeModel): ApiExtensionOfPriorityNoticeModel {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      ...rest
    } = formModel;
    return {
      ...rest
    };
  }
}

export default new Converter();
