import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS } from '../../enums';
import { EvidenceModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import { VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE } from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function Evidence({ name, className }: Props): JSX.Element {
  const { values } = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const value: EvidenceModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<EvidenceModel>(name);
  const evidenceTypeDisabled = values.applicantCapacity ? true : false;
  const classes = useStyles();

  return (
    <div data-section={name} className={className} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Field //
          name={fieldName('evidenceTypeValue')}
          component={SelectField}
          label={'Evidence type'}
          disabled={value.disableSelection ?? (evidenceTypeDisabled || disabled)}
          format="number"
          options={TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          className={classes.fieldWidth}
        />
        <Field //
          name={fieldName('evidenceDate')}
          label={'Evidence date'}
          component={DatePickerField}
          disabled={disabled}
          disableFuture={true}
          className={classes.fieldWidth}
        />
        {VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE(value) && (
          <Field //
            name={fieldName('evidenceDocumentReference')}
            label={'Evidence document reference'}
            component={InputField}
            disabled={disabled}
            className={classes.fieldWidth}
          />
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(Evidence);
