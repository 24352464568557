import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

/*
import ButtonLink from '@sympli/ui-framework/components/button-link';
import { IconArrowsAltV } from '@sympli/ui-framework/icons';
*/
interface Props {
  workspaceCount: number;
  workspaceOrder: number;
  currentWorkspaceId: string;
  currentParticipantId: string;
}

function LinkAndReorderPanel({
  //
  workspaceCount,
  workspaceOrder
}: // currentWorkspaceId,
// currentParticipantId
Props) {
  const classes = useStyles();

  return (
    <FlexLayout alignItems="center" justifyContent="space-between" className={classes.root}>
      <div className={classes.title}>
        This is <b>#{workspaceOrder}</b> of {workspaceCount} linked workspaces
        {/* <ButtonLink //
          icon={<IconArrowsAltV className={classes.changeOrderIcon} />}
          className={classes.changeOrderButton}
          disabled
          color="inherit"
        >
          Change order
        </ButtonLink> */}
      </div>
      {/* <Button //
        color="primary"
        arrowRight
        disabled
      >
        Link workspace
      </Button> */}
    </FlexLayout>
  );
}

export default React.memo(LinkAndReorderPanel);
