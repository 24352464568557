import { WaAddressChange, waPartyAddressChangeConfig } from '@sympli-mfe/document-components/party-form/wa/2-27/components/address-justification';
import { WaNameChange, waPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/wa/2-27/components/party-justification';
import { WA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/wa/2-27/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { JUSTIFICATION_REASON_LOOKUP_OPTIONS, JustificationReasonEnum, NAME_SUFFIX_LOOKUP_OPTIONS, NameSuffixEnum } from '@sympli-mfe/enums-2-27/wa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';

import { WA_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...WA_TENANCY_COMPONENT_LABEL_CONFIG,
  header: {
    ...WA_TENANCY_COMPONENT_LABEL_CONFIG.header,
    jointTenants: CommonTenancyLabelsEnum.JointPropretiors
  },
  items: CommonTenancyLabelsEnum.JointPropretiors,
  footer: {
    ...WA_TENANCY_COMPONENT_LABEL_CONFIG.footer,
    jointTenants: CommonTenancyLabelsEnum.AddJointProprietors
  },
  receivingPartyRole: CommonTenancyLabelsEnum.Mortgagee
};

export const WA_MORTGAGE_PARTY_FORM_CONFIG: PartyFormConfig<WaNameChange, WaAddressChange> = {
  ...WA_PARTY_FORM_CONFIG,
  nameChangeConfig: {
    ...waPartyNameChangeConfig,
    customIndividualJustificationReasonOptions: restrictedLookupOptions(JUSTIFICATION_REASON_LOOKUP_OPTIONS, {
      whitelist: [JustificationReasonEnum.ErrorInRegister, JustificationReasonEnum.NoLongerAMinor]
    }),
    customOrganisationJustificationReasonOptions: restrictedLookupOptions(JUSTIFICATION_REASON_LOOKUP_OPTIONS, {
      whitelist: [JustificationReasonEnum.ErrorInRegister]
    })
  },
  addressChangeConfig: waPartyAddressChangeConfig
};

//Mortgagees are not allowed to be minors
export const WA_MORTGAGE_PARTY_FORM_FOR_MORTGAGEE_CONFIG: PartyFormConfig<WaNameChange, WaAddressChange> = {
  ...WA_MORTGAGE_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...WA_MORTGAGE_PARTY_FORM_CONFIG.individualPartyFormConfig!,
    nameSuffixLookupEnum: restrictedLookupOptions(NAME_SUFFIX_LOOKUP_OPTIONS, {
      blacklist: [NameSuffixEnum.Minor]
    })
  },
  nameChangeConfig: {
    ...WA_MORTGAGE_PARTY_FORM_CONFIG.nameChangeConfig!,
    hideMinorNameSuffixOption: true
  }
};
