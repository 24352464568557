import * as React from 'react';
import { memo } from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from '../models';
import PreviousCard from './components/PreviousCard';
import ThisWeekCard from './components/ThisWeekCard';
import TodayCard from './components/TodayCard';
import TomorrowCard from './components/TomorrowCard';
import TotalCard from './components/TotalCard';
import { FinancialDashboardAggregatorApiRequest, FinancialDashboardAggregatorApiResponse } from './models';
import { useStyles } from './styles';

export interface AggregatorsProps {
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(args: FinancialDashboardAggregatorApiRequest): Promise<FinancialDashboardAggregatorApiResponse>;
  onActionClick(filters: FinancialWorkspacesV2ApiRequest, engagedTab: FinancialDashboardRouteTabsEnumV2, currentTabFilters: DashboardFilterModel): void;
  refetching?: boolean;
}

const Aggregators = (props: AggregatorsProps) => {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="row" className={classes.root}>
      <PreviousCard {...props} />
      <TodayCard {...props} />
      <TomorrowCard {...props} />
      <ThisWeekCard {...props} />
      <TotalCard {...props} />
    </FlexLayout>
  );
};

export default memo(Aggregators);
