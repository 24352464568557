import endpoints from '@sympli/api-gateway/endpoints';

export interface UnsignTaaRequestModel {
  workspaceId: string;
  participantId: string;
  trustAccountId: string;
}

export function unsignTrustAccountAuthorisationRecords(query: UnsignTaaRequestModel) {
  return endpoints.unsignDirectionTrustAccountAuthorisationRecords(query);
  // const { workspaceId, participantId, trustAccountId } = query;
  // const svc = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/directions/trustAccountAuthorisationRecords/${encodeURIComponent(
  //   trustAccountId
  // )}/unsign`;
  // return http.post<TrustAccountAuthorisationRecordsApiResponse>(svc, {}, { withCredentials: true });
}
