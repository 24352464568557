import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { UserRoleEnum } from '@sympli/api-gateway/enums';

import { UserReportsPermissionsApiResponse } from 'src/containers/dashboard/reports/models';
import DetailContainerWrapper from 'src/containers/settings/components/detail-container-wrapper';
import { useDetailPostSubmitHandler, useRedirectHandler } from 'src/containers/settings/hooks';
import { useRouterParams } from 'src/hooks';
import { actionFetchUserReportsPermissions } from '../../../actions/users';
import { useUserDetails } from '../../../reducers/userDetails';
import { useUserReportsPermissions } from '../../../reducers/userReportsPermissions';
import { USER_PROFILE_ROUTE, USERS_DEFAULT_ROUTE } from '../constants';
import { UserReportsPermissionsDetailFormModel } from '../models';
import ReportsPermissionsForm from './components/reports-permissions-form';
import {
  convertUserReportsPermissionsApiResponseToFormModel,
  convertUserReportsPermissionsApiResponseToFormOptions,
  convertUserReportsPermissionsFormModelToApiResponse
} from './helpers';

interface RouteParams {
  userId: string;
}

interface ReportsPermissionsContainerProps {
  isPersonalDetailMode: boolean;
}

function ReportsPermissionsContainer(props: ReportsPermissionsContainerProps) {
  const redirectBack = useRedirectHandler(props.isPersonalDetailMode ? USER_PROFILE_ROUTE : USERS_DEFAULT_ROUTE);
  const { userId } = useRouterParams<RouteParams>();
  const userDetails = useUserDetails(userId).detail;
  const { detail, status, error } = useUserReportsPermissions(userId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionFetchUserReportsPermissions.request(userId));
  }, [dispatch, userId]);

  const handleOnPostSubmit = useDetailPostSubmitHandler<UserReportsPermissionsDetailFormModel, Partial<UserReportsPermissionsApiResponse>>('User reports permissions', args => {
    if (!args.error) {
      dispatch(actionFetchUserReportsPermissions.request(userId));
      if (!props.isPersonalDetailMode) {
        redirectBack();
      }
    }
  });

  return (
    <DetailContainerWrapper //
      header="Reports permissions"
      status={status}
      error={error}
    >
      {() => {
        if (!userDetails) {
          return null;
        }

        const initialValues = convertUserReportsPermissionsApiResponseToFormModel(detail!);

        const options = convertUserReportsPermissionsApiResponseToFormOptions(detail!);

        const handleOnPreSubmit = (values: UserReportsPermissionsDetailFormModel) => {
          return convertUserReportsPermissionsFormModelToApiResponse(values, options);
        };

        return (
          <ReportsPermissionsForm
            options={options}
            initialValues={initialValues}
            userId={userId}
            onCancel={redirectBack}
            onPreSubmit={handleOnPreSubmit}
            onPostSubmit={handleOnPostSubmit}
            isSuperAdmin={userDetails.role === UserRoleEnum.SuperAdmin}
          />
        );
      }}
    </DetailContainerWrapper>
  );
}

export default React.memo(ReportsPermissionsContainer);
