import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { resolveMemoizedReceivingTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/validation-schema';

const yupOtherParties = ({
  partyFormConfig,
  isAddressRequired,
  partyCapacityDetailMaxLength
}: {
  partyFormConfig: PartyFormConfig;
  isAddressRequired: boolean;
  partyCapacityDetailMaxLength: number;
}) =>
  resolveMemoizedReceivingTenancyValidation({
    partyFormConfig: partyFormConfig,
    partyCapacityDetailMaxLength: partyCapacityDetailMaxLength,
    isAddressRequired: isAddressRequired,
    forceDobVisibilityOnReceivingIndividual: false,
    minGroupLength: 0
  });

export default yupOtherParties;
