// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { SubLease2_21_1Model } from './models';
import yupDocumentAttachments from './sections/document-attachments/validationSchema';
import yupInterestsOnTitle from './sections/interests-on-title/validationSchema';
import yupLeaseDetails from './sections/lease-details/validationSchema';
import yupLessees from './sections/lessees/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupRentDetails from './sections/rent-details/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<SubLease2_21_1Model>({
    lessors: yup.mixed(), // no validation
    workspaceTitleReferences: yup.mixed(), // no validation
    titleReferences: yupTitleReferences,
    interestsOnTitle: yupInterestsOnTitle,
    lessees: yupLessees,
    leaseDetails: yupLeaseDetails,
    rentDetails: yupRentDetails,
    documentAttachments: yupDocumentAttachments,
    partyBook: yupPartyBook
  })
  .defined()
  .log();
