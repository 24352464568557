import React from 'react';

import { VIC_ADDRESS_FORM_INITIAL_VALUES as VIC_TRANSFER_ADDRESS_FORM_INITIAL_VALUES } from '@sympli-mfe/document-components/address-field/vic/2-18/config';
import Tenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { PartyCapacityEnum, StateEnum } from '@sympli-mfe/enums-2-18/vic';

import { VIC_TRANSFER_ADDRESS_FORM_CONFIG, VIC_TRANSFER_PARTY_FORM_CONFIG, VIC_TRANSFER_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { TransfereeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup } from '../../enums';

interface Props {
  name: string;
  /** Tenants parties cannot be added/removed/switched to other parties; only rearranged */
  isChangingMannerOfHoldingOnly: boolean;
  className?: string;
}

function SectionTransfereeDetails({ name, className, isChangingMannerOfHoldingOnly }: Props): JSX.Element {
  return (
    <Tenancy
      name={name}
      className={className}
      isChangingMannerOfHoldingOnly={isChangingMannerOfHoldingOnly}
      partyFormConfig={VIC_TRANSFER_PARTY_FORM_CONFIG}
      addressFormConfig={VIC_TRANSFER_ADDRESS_FORM_CONFIG}
      addressFormInitialValues={VIC_TRANSFER_ADDRESS_FORM_INITIAL_VALUES}
      jurisdiction={StateEnum.VIC}
      partyCapacityLookup={TransfereeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup}
      partyCapacityMinor={PartyCapacityEnum.Minor}
      componentLabels={VIC_TRANSFER_TENANCY_COMPONENT_LABEL_CONFIG}
    />
  );
}

export default React.memo(SectionTransfereeDetails);
