import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { DateOfDeathModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import { END_OF_YESTERDAY } from '../../sections/deceased-proprietor-groups/validationSchema';
import {
  VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE
} from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function DateOfDeath({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const { values } = formikProps;
  const value: DateOfDeathModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DateOfDeathModel>(name);
  const classes = useStyles();

  return (
    <div data-testid={name} className={className}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Field //
          name={fieldName('dateOfDeathType')}
          label={'Details of death date'}
          component={SelectField}
          disabled={disabled}
          format="number"
          options={TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          className={classes.fieldWidth}
        />
        {VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE(value) && (
          <Field //
            name={fieldName('deathDate')}
            label={'Date of death'}
            component={DatePickerField}
            disabled={disabled}
            maxDate={END_OF_YESTERDAY}
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(value) && (
          <Field //
            name={fieldName('fromDate')}
            label={'From date'}
            component={DatePickerField}
            disabled={disabled}
            maxDate={END_OF_YESTERDAY}
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(value) && (
          <Field //
            name={fieldName('toDate')}
            label={'To date'}
            component={DatePickerField}
            disabled={disabled}
            maxDate={END_OF_YESTERDAY}
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION(value) && (
          <Field //
            name={fieldName('dateDescription')}
            label={'Date description'}
            component={InputField}
            disabled={disabled}
            className={classes.fieldWidth}
          />
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(DateOfDeath);
