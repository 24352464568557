import { resolveContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { VIC_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { TransferDocumentModel } from '../../models';

export const yupParty = resolvePartyModelValidationSchema(VIC_TRANSFER_PARTY_FORM_CONFIG);

export const yupPartyBook = resolveContextBasedPartyBookValidationSchema<TransferDocumentModel>(
  yupParty,
  // If the party is not transferor, we do not need detailed validation
  // (Note: If unaffected proprietors are selected in other sections, non-detailed validation (abortEarly: true) should be performed in the validation of those sections.)
  ({ transferorDetails }, partyIdForCheck) =>
    transferorDetails.relinquishingProprietorGroups.some(({ parties }) => parties.some(transferor => transferor.partyBookId === partyIdForCheck))
);
