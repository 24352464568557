import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import { Exclusive } from '@sympli/ui-framework/utils/type';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import CreateNewWorkspaceDialogContext from 'src/containers/dashboard/components/create-new-workspace/dialogs/create-new-workspace-dialog/CreateNewWorkspaceDialog.context';
import { getSuccessMessage, SuccessMessageEnum } from 'src/containers/dashboard/invitation/messages';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useSafeDispatch } from 'src/hooks';
import { AxiosError } from 'src/utils/http';
import { ActiveWorkspaceItem } from '../search-join-table/models';
import ActiveWorkspaceAccordion from './ActiveWorkspaceAccordion';
import { joinWorkspace, JoinWorkspaceApiRequest } from './api';
import { getJoinActiveFinancialWorkspaceActions, getJoinActiveFinancialWorkspaceFailureNotification } from './helpers';

interface BaseProps {
  //
  isLoading: boolean;
  activeWorkspaces?: ActiveWorkspaceItem[];
  groupId: string;
  onClose: () => void;
}

export type FinancialProps = {
  workspaceType: WorkspaceTypeEnum.FinancialSettlement;
  role: WorkspaceRoleEnum; // the role I want to join the workspace as
  userPermissions: UserProfileModel['userPermissions'];
  subscriberId: string;
  matter: string;
};

export type StandaloneProps = {
  workspaceType: WorkspaceTypeEnum.RegistrationOnly;
  role?: undefined;
  userPermissions?: undefined;
  subscriberId?: undefined;
  matter?: undefined;
};

export type ActiveWorkspaceAccordionProps = Exclusive<FinancialProps, StandaloneProps> & BaseProps;

const ActiveWorkspaceAccordionContainer = ({
  //
  activeWorkspaces,
  isLoading,
  role,
  groupId,
  userPermissions,
  workspaceType,
  subscriberId,
  matter,
  onClose
}: ActiveWorkspaceAccordionProps) => {
  const dispatch = useSafeDispatch(useDispatch());
  const navigate = useNavigate();
  const { setVisibility } = React.useContext(CreateNewWorkspaceDialogContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [row, setRow] = React.useState<number | undefined>(undefined);

  const getWarningMessage = useCallback((statusCode: number | undefined): { message: string | React.ReactNode; secondaryMessage?: string | React.ReactNode } => {
    switch (statusCode) {
      case 403:
        return {
          message: <b>Access denied.</b>,
          secondaryMessage: 'You do not have permission to access this page. Please contact your admin.'
        };
      default:
        return {
          message: <b>Something went wrong.</b>,
          secondaryMessage: 'We have encountered Technical Issues, please try again'
        };
    }
  }, []);

  const handleOnJoinWorkspace = useCallback(
    //
    async (data: JoinWorkspaceApiRequest) => {
      try {
        const { participantId, isSuccessful, validationErrorType } = await joinWorkspace(data);

        if (isSuccessful) {
          dispatch(
            actionOpenGlobalSimpleNotification({
              ...getSuccessMessage(SuccessMessageEnum.JoinWorkspace, data.reference),
              variant: 'new-success',
              autoHideDuration: 5000
            })
          );

          navigate(
            resolveWorkspaceDetailLink({
              workspaceId: data.workspaceId,
              participantId
            })
          );
        }

        // show customized error message based on validationErrorType
        if (validationErrorType !== undefined) {
          dispatch(
            actionOpenGlobalSimpleNotification({
              message: getJoinActiveFinancialWorkspaceFailureNotification(validationErrorType),
              variant: 'new-warning',
              autoHideDuration: 5000
            })
          );
          setOpen(false);
          // close the create workspace dialog and reset the visibility so it will be visible the next time it is opened
          onClose();
          setVisibility(true);
        } else {
          dispatch(
            actionOpenGlobalSimpleNotification({
              message: <b>Something went wrong.</b>,
              secondaryMessage: 'This workspace is currently unable to join.',
              variant: 'new-warning',
              autoHideDuration: 5000
            })
          );
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        let displayMsg = getWarningMessage(axiosError.response?.status);

        if (axiosError.response?.status === 403 || axiosError.response?.status === 400) {
          // if backend returns the msg we use it, otherwise use the generic one
          const msg = (axiosError.response?.data as any)?.message;
          displayMsg = msg ? { message: msg } : { ...displayMsg };
        }

        dispatch(
          actionOpenGlobalSimpleNotification({
            ...displayMsg,
            variant: 'new-warning',
            autoHideDuration: 5000
          })
        );
      }
    },
    [dispatch, getWarningMessage, navigate, onClose, setVisibility]
  );

  if (!activeWorkspaces?.length) {
    return null;
  }

  const getActiveWorkspaces = (): ActiveWorkspaceItem[] => {
    return activeWorkspaces.map((x, index) => {
      const item: ActiveWorkspaceItem = { ...x };
      item.addresses = x.titles.map(title => (title.address.addressLine2 ? title.address.addressLine1 + ' ' + title.address.addressLine2 : title.address.addressLine1));
      item.clickHandler = () => {
        setOpen(true); // open join workspace dialog
        setVisibility(false); // hide the create workspace dialog
        setRow(index);
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'open-join-dialog',
          logGroupId: 'search-and-join-workspace',
          sympliId: item.sympliId,
          workspaceId: item.workspaceId,
          jurisdiction: item.jurisdiction
        });
      };

      if (workspaceType !== WorkspaceTypeEnum.RegistrationOnly) {
        return getJoinActiveFinancialWorkspaceActions(item, userPermissions, role, subscriberId);
      }

      return item;
    });
  };

  const handleOnClose = () => {
    setOpen(false); // close join workspace dialog
    setVisibility(true); // show the create workspace dialog
  };

  return (
    <ActiveWorkspaceAccordion
      row={row}
      items={getActiveWorkspaces()}
      workspaceType={workspaceType}
      isLoadingTable={isLoading}
      open={open}
      handleOnClose={handleOnClose}
      role={role}
      groupId={groupId}
      onJoinWorkspace={handleOnJoinWorkspace}
      matter={matter}
    />
  );
};

export default memo(ActiveWorkspaceAccordionContainer);
