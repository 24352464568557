import React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconErrorCircle } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  id: string;
  warningMessage?: string;
}

function SignWarning({ id, warningMessage }: Props) {
  const classes = useStyles();
  return warningMessage ? (
    <div id={id} className={classes.root}>
      <FlexLayout alignItems="center">
        <IconErrorCircle className={classes.warningIcon} />
        <Typography className={classes.heading}>{warningMessage}</Typography>
      </FlexLayout>
    </div>
  ) : null;
}
export default SignWarning;
