import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: 66
    },
    title: {
      fontWeight: 'bold',
      fontSize: 32,
      lineHeight: '32px'
    }
  }),
  {
    name: 'SettingsHeader'
  }
);
