import _cloneDeep from 'lodash-es/cloneDeep';

import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { BaseTitleReferenceModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { CaveatModel, CaveatorPartyModel, WithdrawalOfCaveat2_26_0Model } from './models';

export const getTitleReferences = (caveats: CaveatModel[]): BaseTitleReferenceModel[] => {
  // get unique list of title references across multiple caveats
  const titleReferences = caveats
    .flatMap(x => x.titleReferences)
    .reduce<BaseTitleReferenceModel[]>((titles: BaseTitleReferenceModel[], currentTitle: BaseTitleReferenceModel) => {
      const exists = titles.findIndex(tr => tr.reference === currentTitle.reference);
      if (exists !== -1) {
        titles[exists].isSelected = titles[exists].isSelected || currentTitle.isSelected;
      } else {
        titles.push(currentTitle);
      }
      return titles;
    }, []);
  return _cloneDeep(titleReferences);
};

export const getCaveatsBeingWithdrawn = (caveats: CaveatModel[], titleReferences: BaseTitleReferenceModel[]): CaveatModel[] => {
  // get some caveats which contains all the selected title references
  const selectedTitleReferences = getSelectedTitleReferences(titleReferences);
  if (selectedTitleReferences.length === 0) {
    return [];
  }

  // filter caveats which include all the selected title references
  const caveatsBeingWithdrawn = caveats.filter(caveat => {
    const caveatTitleReferences = caveat.titleReferences.flatMap(title => title.reference);
    // check whether the caveat title references includes the title reference selected by the user
    return selectedTitleReferences.every(title => caveatTitleReferences.includes(title.reference));
  });

  return caveatsBeingWithdrawn;
};

export const getSelectedCaveats = (caveatSelection: CaveatModel[]): CaveatModel[] => {
  // get selected caveat
  // automatically select the first one incase there is only one caveat
  if (caveatSelection.length === 1) {
    caveatSelection[0].isSelected = true;
    return [caveatSelection[0]];
  }

  return caveatSelection.filter(({ isSelected }) => isSelected);
};

export const getCaveatorsForSelectedCaveat = (caveats: CaveatModel[]): CaveatorPartyModel[] => {
  const selectedCaveats = getSelectedCaveats(caveats);
  // group same caveators
  const uniqueCaveators = selectedCaveats
    .flatMap(e => e.caveators)
    .reduce<CaveatorPartyModel[]>((caveators: CaveatorPartyModel[], caveator: CaveatorPartyModel) => {
      if (caveators.every(e => e.partyBookId !== caveator.partyBookId)) {
        caveators.push(caveator);
      }

      return caveators;
    }, []);
  // !We need to return always full clone to prevent formik issue with touched objects not being correctly populated
  // when the user hits submit due to the implementation of the setNestedObjectValues within which they check for referential
  // equality of processed object/array eg. Add Address msg
  return _cloneDeep(uniqueCaveators);
};

export const getSelectedTitleReferences = (titleReferences: BaseTitleReferenceModel[]) => {
  // get selected title references
  return titleReferences.filter(({ isSelected }) => isSelected);
};

export const getOtherWithdrawalsOfCaveatDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.WithdrawalOfCaveat && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherWithdrawalsOfCaveat = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as WithdrawalOfCaveat2_26_0Model;
    return data.caveats.filter(caveat => caveat.isSelected).flatMap(caveat => caveat.dealingNumber);
  });
};
