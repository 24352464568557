import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { verifyTitles } from 'src/containers/shared/verify-property-section/api';
import { TitleVerificationRequest, VerifiedTitleResponse } from './models';

export const resolveTitles = (titleChips: string[], titles: VerifiedTitleResponse[] = []): string[] => {
  if (titles.length) {
    const references = Array.from(new Set([...titles.map(({ titleReference }) => titleReference), ...titleChips]));
    return references;
  }

  if (titleChips.length) {
    const references = Array.from(new Set(titleChips));
    if (references.length !== titleChips.length) {
      return references;
    }
  }
  //keep original reference
  return titleChips;
};

export const verifyTitleReferences = async (payload: TitleVerificationRequest) => {
  const references = payload.references;
  if (payload.jurisdictionId === JurisdictionsEnum.WA) {
    payload.references = references.map(r => {
      return prepareTitleForSubmission(payload.jurisdictionId, r);
    });

    const titleVerificationResult = await verifyTitles(payload);

    titleVerificationResult?.titles?.forEach(t => {
      const titleWithSlash = t.titleReference.replace('-', '/');
      if (references.includes(titleWithSlash)) t.titleReference = titleWithSlash;
    });
    return titleVerificationResult;
  }
  return await verifyTitles(payload);
};

export function prepareTitleForSubmission(jurisdictionId: JurisdictionsEnum, title: string) {
  return jurisdictionId === JurisdictionsEnum.WA ? title.replace('/', '-') : title;
}
