import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import { ClassNameMap } from '@mui/styles/withStyles';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import PartLandAffected from '@sympli-mfe/document-forms-framework/components/sections/title-reference/components/part-land-affected';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { CheckboxGroupClassKeys } from '@sympli/ui-framework/components/form/base-components/checkbox-group';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { Lease2_21_1Model, TitleReferenceModel } from '../../models';
import { useStyles } from './styles';
import { PART_LAND_DESCRIPTION_LIMIT } from './validationSchema';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
  onChange: (e, resolvedValue) => void;
}

function SectionTitleReferences({ name, focusRef, onChange }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<Lease2_21_1Model>();
  const { titleReferences } = values;

  const resolveItemLabel = useCallback(
    (title: TitleReferenceModel, index: number) => {
      const itemBinding = `${name}[${index}]`;
      const fieldName = createModelKeyAppender<TitleReferenceModel>(`[${index}]`);
      return (
        <FlexLayout fullWidth flexDirection="row" className={classes.labelFlexLayout}>
          <div className={classes.titleReferenceContainer} data-testid={itemBinding}>
            Title reference: <strong>{title.reference}</strong>
          </div>
          {title.isSelected && (
            <PartLandAffected //
              disabled={disabled}
              renderProps={{ fieldName: fieldName, itemBinding: itemBinding, item: title }}
              isSingleTitle={(titleReferences?.length ?? 0) > 1}
              partLandDescriptionLimit={PART_LAND_DESCRIPTION_LIMIT}
              focusRef={focusRef}
            />
          )}
        </FlexLayout>
      );
    },
    [classes.labelFlexLayout, classes.titleReferenceContainer, disabled, focusRef, name, titleReferences?.length]
  );

  const overrideClasses: Partial<ClassNameMap<keyof ReturnType<CheckboxGroupClassKeys>>> = {
    checkboxComponentRoot: classes.checkboxComponentRoot
  };

  return (
    <GenericItemSelection //
      name={name}
      data-testid={name}
      disabled={disabled}
      focusRef={focusRef}
      mode="multiple"
      resolveItemLabel={resolveItemLabel}
      overrideClasses={overrideClasses}
      onChange={onChange}
    />
  );
}

export default React.memo(SectionTitleReferences);
