import { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';
import createStyles from '@mui/styles/createStyles';

import { AvatarListItemProps } from './avatar-list-item';
import { IconListItemProps } from './icon-list-item';

type StyleProps = IconListItemProps & AvatarListItemProps & Omit<MuiListItemProps, 'button' | 'dense' | 'alignItems'>;

export const sharedStyles = createStyles({
  itemRoot: {
    '&$itemSelected': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white'
      }
    },
    '&$focusVisible': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  },
  itemSelected: {},
  focusVisible: {},
  textPrimary: {
    lineHeight: ({ secondary }: StyleProps) => (secondary ? 1 : undefined),
    textDecoration: ({ linkTo, selected }: StyleProps) => (linkTo && !selected ? 'underline' : undefined),
    fontWeight: 500,
    letterSpacing: 0
  },
  textSecondary: {
    lineHeight: ({ primary, secondary }: StyleProps) => (primary && secondary ? 1 : undefined),
    fontSize: 13
  },
  iconRoot: {
    minWidth: 24
  },
  iconAlignItemsFlexStart: {
    marginTop: 4
  },
  nestedList: {
    paddingLeft: 24
  }
});
