import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getCharacterCountHelperText, modelKey, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { InputClassKeys, InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { ADDITIONAL_COVENANT_WARNING_VALUES } from '../../helper';
import { IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS } from '../../isRequiredChecks';
import { AdditionalCovenantListingModel, Mortgage2_25_0Model } from '../../models';
import { useStyles } from './styles';
import { COVENANTS_MAX_CHARACTERS } from './validationSchema';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
}

interface InternalProps extends Props {
  isRequired: boolean;
}

function resolveAdditionalCovenantsHelper(
  additionalCovenants: string = '',
  classes: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>>
): Pick<InputComponentProps, 'classes' | 'helperText'> {
  const additionalCovenantsCount = additionalCovenants.trim().length;
  const helperText = getCharacterCountHelperText(
    //
    additionalCovenantsCount,
    COVENANTS_MAX_CHARACTERS
  );

  if (ADDITIONAL_COVENANT_WARNING_VALUES.some(warningValue => ~additionalCovenants.indexOf(warningValue))) {
    return {
      helperText: `Please be aware that certain words within the terms and conditions are used for specific purposes only e.g. Trust, Trustee, Beneficial, Beneficiary. You may proceed to lodgement, however, there may be delays in registration. ${helperText}`,
      classes
    };
  }
  return {
    helperText
  };
}

const fieldName = modelKey<AdditionalCovenantListingModel>();

function SectionAdditionalCovenants({ name, isRequired }: InternalProps): JSX.Element {
  const formikProps = useFormikContext<AdditionalCovenantListingModel>();
  const {
    values: { value: additionalCovenants }
  } = formikProps;

  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const fieldClasses: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControlValidation: classes.formControlValidation
    };
  }, [classes]);

  const acClasses: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControl: classes.additionalCovenantWarningBorder,
      formControlValidation: classes.additionalCovenantWarningHelperText
    };
  }, [classes]);
  return (
    <div data-section={name}>
      <FormGroup //
        title="Additional terms and conditions"
        classes={{ root: classes.additionalCovenants }}
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('value')}
            component={InputField}
            disabled={disabled}
            classes={fieldClasses}
            className={classes.noBottomMargin}
            fullWidth
            multiline
            minRows={2}
            placeholder={resolvePlaceholder(isRequired)}
            {...resolveAdditionalCovenantsHelper(additionalCovenants?.toLowerCase(), acClasses)}
          />
        )}
      </FormGroup>
    </div>
  );
}

const MemoizedAdditionalCovenants = React.memo(SectionAdditionalCovenants);

export default ({ name }: Props) => {
  const {
    values: { mcpNumber, additionalAttachments }
  } = useFormikContext<Mortgage2_25_0Model>();
  return (
    <Subform //
      name={name}
      isRequired={IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS(mcpNumber, additionalAttachments)}
      subformBindingPath={name}
      component={MemoizedAdditionalCovenants}
    />
  );
};
