import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { QldPartyForm_2_20 as PartyForm } from '@sympli-mfe/document-components/party-form/qld/2-20';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { QLD_WOC_PARTY_FORM_CONFIG } from '../../config';
import { CaveatorPartyModel, WithdrawalOfCaveat2_20_1Model } from '../../models';
import PartyCapacity from '../caveator-party-capacity';

type FormValues = WithdrawalOfCaveat2_20_1Model;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  className?: string;
}

function Caveator({ name, formikProps, disabled, dialogPortalId, className }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<CaveatorPartyModel>(name);
  const { values } = formikProps;
  const value: CaveatorPartyModel = _get(values, name);

  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={QLD_WOC_PARTY_FORM_CONFIG}
      />
      <PartyCapacity name={fieldName('partyCapacity')} formikProps={formikProps} isReadonly />
    </div>
  );
}

export default React.memo(Caveator);
