import React from 'react';

import { useStyles } from './styles';

interface UserReportProps {
  tableauReportRef: React.RefObject<HTMLDivElement>;
}

// This is for the tableau iframe
function UserReport({ tableauReportRef }: UserReportProps) {
  const classes = useStyles();

  // ! tableau required a root node so they can replace with the iframe
  return <div ref={tableauReportRef} className={classes.root}></div>;
}
export default React.memo(UserReport);
