import { JurisdictionsEnum, UserLevelPermissionEnum, UserRoleEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { GroupDetailsApiResponse } from '../group-settings/models';

export const ENABLE_SIGNING_LIMIT_OPTIONS: LookupEnumModel<boolean, number | null>[] = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
];

export interface UserPasswordModel {
  password: string;
  password2: string;
}

export interface UserPermissionsEntityModel {
  id: UserLevelPermissionEnum;
  isSelected: boolean;
  name: string;
}
export interface UserGroupAccessModel extends GroupDetailsApiResponse {
  isSelected: boolean;
  isEditable: boolean;
}

export interface RoleEntityModel extends LookupEnumModel {
  isSelected: boolean;
  isEditable: boolean;
}

export interface UpdatePermissionCategories {
  name: string;
  permissions: Array<PermissionsSpecification>;
}

export interface PermissionsSpecification {
  id: UserLevelPermissionEnum;
  name: string;
  isEditable: boolean;
}

export interface CertificateManagerModel {
  isSelected: boolean;
  isVerified: boolean;
  verifiedOn: string;
  isEditable: boolean;
}

export interface DebitAccountSignatories {
  accountId: string;
  accountName: string;
  isSelected: boolean;
  isVerified: boolean;
  verifiedOn: string;
}

export interface UserCertifierDesignationsModel {
  userId: string;
  jurisdiction: JurisdictionsEnum;
  certifierDesignation: CertifierDesignationEnum;
}

export interface UserSigningRuleModel {
  hasPaymentSigningLimit: boolean;
  paymentSigningLimitAmount?: number;
  hasSourceFundSigningLimit: boolean;
  sourceFundSigningLimitAmount?: number;
}

// Permission detail in redux store, raw data from api
export interface UserPermissionsApiResponse {
  roles: Array<RoleEntityModel>;
  groups: Array<UserGroupAccessModel>;
  permissions: Array<UserPermissionsEntityModel>;
  updatePermissionCategories: Array<UpdatePermissionCategories>;
  isEditableUserCertifierDesignations: boolean;
  userCertifierDesignations: Array<UserCertifierDesignationsModel>;
  userSigningRule?: UserSigningRuleModel;
  // TODO: check do we need this field
  // debitAccountSignatories: Array<DebitAccountSignatories>;
}

export interface UserLookupModel {
  id: string;
  name: string;
  email: string;
  role: UserRoleEnum;
}

export interface UpdateUserPermissionsApiRequest {
  role: UserRoleEnum | string;
  permissions: Array<UserLevelPermissionEnum>;
  groups: Array<string>;
  userCertifierDesignations: UserCertifierDesignationsModel[];
  userSigningRule?: UserSigningRuleModel;
}

export interface UserPermissionsFormikValueModel {
  role: UserRoleEnum | string;
  permissions: Array<UserLevelPermissionEnum>;
  groups: Array<string>;
  designation?: number | null;
  userSigningRule?: UserSigningRuleModel;
}

export enum UserDetailsPageTabEnum {
  PersonalDetails = 'detail',
  Permissions = 'permissions',
  ReportsPermissions = 'reports-permissions',
  LinkedDevices = 'linked-device'
}

export enum CertifierDesignationEnum {
  AustralianLegalPractitioner = 0,
  AuthorisedSignatory = 1,
  ConveyancingPractice = 2,
  LawPractice = 3,
  LicensedConveyancer = 4
}
