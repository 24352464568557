import { Action, createReducer } from 'typesafe-actions';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchInvitationsFeedV2, showInvitationsDashboardFeedDetail } from '../actions';
import { InvitationsDashboardV2ApiRequest } from '../models';
import { InvitationsTableRowModel } from '../table/models';

export interface InvitationsDashboardReduxStateV2 {
  rowDetailIndex: number | undefined;
  items: InvitationsTableRowModel[];
  error?: string;
  status: ApiStatus;
  totalCount: number;
  query: InvitationsDashboardV2ApiRequest;
  updatedTime: Date;
}

const initialState: InvitationsDashboardReduxStateV2 = {
  rowDetailIndex: undefined,

  items: [],
  status: 'idle',
  error: undefined,

  totalCount: 0,
  updatedTime: new Date(),

  query: {
    assignmentType: AssignmentTypeEnum.AssignedToMe,

    settlementPeriod: undefined,

    jurisdictions: undefined,

    pageSize: 20,
    pageNumber: 1 // service page number is index from 1
  }
};

export const useInvitationsFeedV2 = () => {
  const feed = useStoreSelector(store => store.invitationsFeedV2);

  // TODO why do we need this?
  if (feed) {
    return feed;
  }

  // We need to retain searchTerm (if any) when switching between tabs
  return { ...initialState, query: { ...initialState.query } };
};

const reducer = createReducer<
  //
  InvitationsDashboardReduxStateV2,
  Action
>(initialState)
  .handleAction(showInvitationsDashboardFeedDetail, (state, action): InvitationsDashboardReduxStateV2 => {
    return {
      ...state,
      rowDetailIndex: action.payload.rowDetailIndex
    };
  })
  .handleAction(actionFetchInvitationsFeedV2.request, (state, action): InvitationsDashboardReduxStateV2 => {
    return {
      ...initialState,
      query: action.payload,
      items: [],
      status: 'pending',
      error: undefined
    };
  })
  .handleAction(actionFetchInvitationsFeedV2.success, (state, action): InvitationsDashboardReduxStateV2 => {
    return {
      ...state,
      ...action.payload.data,
      status: 'resolved',
      updatedTime: new Date(),
      error: undefined
    };
  })
  .handleAction(actionFetchInvitationsFeedV2.failure, (state, action): InvitationsDashboardReduxStateV2 => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
