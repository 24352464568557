import { $contains, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { StandardTermsModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - termsAndConditions.warning

 schema version:
[
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "Trust"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "trust"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "trustee"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "Trustee"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "Beneficial"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "benefitial"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "benefisial"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "Beneficiary"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "benefitiary"
          }
        }
      }
    }
  },
  {
    "additionalCovenants": {
      "$items": {
        "$oneOf": {
          "covenant": {
            "$contains": "beneficiary"
          }
        }
      }
    }
  }
]
*/
export const VISIBILITY_CHECK_TERMS_AND_CONDITIONS$WARNING = createContextCheck((context: StandardTermsModel): boolean => {
  const { additionalCovenants = '' } = context;

  return (
    $contains(additionalCovenants, 'Trust') ||
    $contains(additionalCovenants, 'trust') ||
    $contains(additionalCovenants, 'trustee') ||
    $contains(additionalCovenants, 'Trustee') ||
    $contains(additionalCovenants, 'Beneficial') ||
    $contains(additionalCovenants, 'benefitial') ||
    $contains(additionalCovenants, 'benefisial') ||
    $contains(additionalCovenants, 'Beneficiary') ||
    $contains(additionalCovenants, 'beneficiary')
  );
});
