import React from 'react';

import { useNavigate } from 'react-router-dom';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';

import converter from './conversion';
import { ApiLodgementInstructions2_20_1Model, LodgementInstructions2_20_1Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

// this file was automatically generated from DocumentForm.tsx.mustache
export type Props = DocumentFormProps<LodgementInstructions2_20_1Model, ApiLodgementInstructions2_20_1Model>;

function DocumentForm(props: Props): JSX.Element {
  const navigate = useNavigate();
  const { currentParticipant } = useDocumentContext();
  const { queryParams, onPostSubmit } = props;
  const { workspaceId, participantId } = queryParams!;

  const handleOnPostSubmit = (args: FormikPostSubmitArgs<LodgementInstructions2_20_1Model, ApiLodgementInstructions2_20_1Model>) =>
    new Promise<void>(resolve => {
      if (!args.error) {
        //Changing workspace responsible participant should redirect user to the workspace overview
        if (
          currentParticipant?.isResponsibleParticipant && //
          currentParticipant?.id !== args.formValues.responsibleParticipantId
        ) {
          navigate(resolveWorkspaceDetailLink({ workspaceId, participantId }));
        } else {
          onPostSubmit?.(args);
        }
        resolve();
      }
    });

  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validationSchema={validationSchema}
        RootForm={RootForm}
        onPostSubmit={handleOnPostSubmit}
      />
    </DndContextProvider>
  );
}

export default React.memo(DocumentForm);
