import { Action, createReducer } from 'typesafe-actions';

import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';

import { actionFetchDocumentDefaultFields } from '../actions/defaultFieldPopulation';

export enum DocumentFieldIdentifierEnum {
  MCP = 0,
  AdditionalTermsAndConditions = 1
}

export enum FieldTypeEnum {
  Collection = 0
}

export enum FieldValueTypeEnum {
  Text = 0,
  TextArea = 1
  // Date = 1,
  // Address = 2
}

export interface DocumentFieldModel {
  fieldIdentifier: DocumentFieldIdentifierEnum; // * this may be more dynamic in the future
  fieldType: FieldTypeEnum;
  valueType: FieldValueTypeEnum;
  label: string; // the description
  maxLength: number;
  maxNumberOfItems: number;
  values: Array<DocumentFieldModelValue>; // * this will be more dynamic in the future
}

export interface DocumentFieldModelValue {
  value: string;
  description?: string;
  isDefault: boolean;
}

export interface DocumentFieldDetailApiResponse {
  documentType: {
    name: string;
    identifier: DocumentTypeIdentifierEnum;
  };
  documentCustomFieldMappings: Array<DocumentFieldModel>;
}

export interface DocumentDefaultFieldListState {
  details?: DocumentFieldDetailApiResponse;
  isLoading: boolean;
  error?: string;
}

const initialState: DocumentDefaultFieldListState = {
  details: undefined,
  isLoading: false,
  error: undefined
};

const reducer = createReducer<
  //
  DocumentDefaultFieldListState,
  Action
>(initialState)
  .handleAction(actionFetchDocumentDefaultFields.request, (state): DocumentDefaultFieldListState => {
    return {
      ...state,
      isLoading: true,
      error: undefined
    };
  })
  .handleAction(actionFetchDocumentDefaultFields.success, (state, action): DocumentDefaultFieldListState => {
    return {
      ...state,
      details: action.payload.data,
      isLoading: false
    };
  })
  .handleAction(actionFetchDocumentDefaultFields.failure, (state, action): DocumentDefaultFieldListState => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error.message
    };
  });

export default reducer;
