import React, { memo, useCallback } from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import { LinkedWorkspacesState } from 'src/containers/shared/linked-workspace-list/reducer';
import { useStoreSelector } from 'src/hooks';
import { LinkedWorkspaceRowItem } from './models';
import { resolveComponentItemsSelector } from './selectors';
import TabLinkedWorkspaces from './TabLinkedWorkspaces';

interface Props {
  workspaceId: string;
  participantId: string;
}

function TabLinkedWorkspacesContainer({
  //
  workspaceId,
  participantId
}: Props) {
  const linkedWorkspaces = useStoreSelector<LinkedWorkspacesState>(state => state.linkedWorkspaces);
  const currentSubscriberId = useStoreSelector<string>(state => state.profile.data!.subscriberId);
  const { status, isLoading, error, items: originalItems } = linkedWorkspaces;

  const handleOnUnlinkWorkspaceClick = useCallback(
    (workspaceId: string) => {
      alert(`${participantId} will be unlinked from workspace ${workspaceId}`);
    },
    [participantId]
  );

  if (isLoading) {
    return 'Loading ....'; // TODO
  }

  if (status === 'rejected') {
    return (
      <FormHelperText //
        error={true}
        role="alert"
      >
        {error}
      </FormHelperText>
    );
  }

  if (!originalItems.length) {
    return 'No linked workspaces found';
  }

  const items: LinkedWorkspaceRowItem[] = resolveComponentItemsSelector({ originalItems });
  const currentWorkspaceIndex = originalItems.findIndex(item => item.workspaceId === workspaceId);

  return (
    <TabLinkedWorkspaces //
      currentWorkspaceIndex={currentWorkspaceIndex}
      currentWorkspaceId={workspaceId}
      currentParticipantId={participantId}
      currentSubscriberId={currentSubscriberId}
      items={items}
      onUnlinkWorkspaceClick={handleOnUnlinkWorkspaceClick}
    />
  );
}

export default memo(TabLinkedWorkspacesContainer);
