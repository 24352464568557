import * as React from 'react';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  onClose: (data?: boolean) => void;
  isLoading: boolean;

  sentToName: string | undefined;
}

function WithdrawDialog(props: Props): JSX.Element {
  const { onClose, sentToName, isLoading } = props;
  const classes = useStyles();

  const renderDialogTitle = () => {
    return (
      <FlexLayout flexDirection="row" alignItems="center">
        <IconDelete className={classes.optionIcon} />
        <span>Withdraw invite</span>
      </FlexLayout>
    );
  };

  return (
    <ConfirmationDialog
      isLoading={isLoading}
      title={renderDialogTitle()}
      color="secondary"
      showActionButtons={true}
      open={true}
      scroll="body"
      okButtonText="Withdraw invite"
      onClose={onClose}
    >
      <strong>{sentToName || 'This participant'}</strong> will no longer be able to access this workspace.
    </ConfirmationDialog>
  );
}

export default WithdrawDialog;
