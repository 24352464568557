import { UserRoleEnum } from '@sympli/api-gateway/enums';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useFeatureFlag, useRouterParams } from 'src/hooks';
import { UserDetailsState, useUserDetails } from '../../reducers/userDetails';
import { showReportPermissionTab } from '../helpers';

interface UsersSettingsRouteParams {
  userId: string;
}
/**
 * This hook is to determine in the settings->users->details page,
 * whether we want to show report-permissions link or not.
 */
export const useReportsPermissionCheck = () => {
  const profile = useProfile().data;

  const { userId } = useRouterParams<UsersSettingsRouteParams>();
  const userDetails: UserDetailsState = useUserDetails(userId);

  const isBiReportingEnabled: boolean = useFeatureFlag(FeatureToggleEnum.bIReporting);

  return showReportPermissionTab({
    isBiReportingEnabled,
    isProfileUserSuperAdmin: profile?.role === UserRoleEnum.SuperAdmin,
    isCurrentUserActive: userDetails.detail?.isSuspended === false && userDetails.detail?.isActivated === true
  });
};
