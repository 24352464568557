import React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { NswAddressSelectField_2_21 as AddressSelectField } from '@sympli-mfe/document-components/address-field/nsw/2-21';
import { AddressBookEntityModel, AddressTypeEnum } from '@sympli-mfe/document-forms-framework/components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import Area from '../../components/area';
import { NSW_NOTICE_OF_SALE_PROPERTY_ADDRESS_FORM_CONFIG } from '../../config';
import { PROPERTY$NATURE_OF_PROPERTY_LOOKUP_OPTIONS, TRANSFER_ARRANGEMENT_LOOKUP_OPTIONS } from '../../enums';
import { ADDRESS_BOOK_KEY, NoticeOfSaleModel, PropertyModel } from '../../models';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
  dialogPortalId: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionProperty({ name, dialogPortalId, className, focusRef }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfSaleModel>();
  const { values } = formikProps;
  const { [ADDRESS_BOOK_KEY]: addressBook } = values;
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<PropertyModel>(name);

  const filterPhysicalAddresses = (address: AddressBookEntityModel) => {
    // Address book may contain physical, postal and overseas addresses due to service of notices field
    // However, Property address can only be a physical address
    return address?.type === AddressTypeEnum.PhysicalAddress;
  };
  const addressBookOptions = useAddressBookOptions(addressBook, NSW_NOTICE_OF_SALE_PROPERTY_ADDRESS_FORM_CONFIG, filterPhysicalAddresses);

  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Property address"
        fieldAreaDirection="column"
      >
        <AddressSelectField
          name={fieldName('addressBookId')}
          disabled={disabled}
          bookRef={ADDRESS_BOOK_KEY}
          dialogPortalId={dialogPortalId}
          optionsOverride={addressBookOptions}
          focusRef={focusRef}
          addressFormConfig={NSW_NOTICE_OF_SALE_PROPERTY_ADDRESS_FORM_CONFIG}
        />
      </FormGroup>
      <FormGroup //
        title="Transfer arrangement"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('transferArrangement')}
            component={SelectField}
            debug={debug}
            disabled={disabled}
            format="number"
            options={TRANSFER_ARRANGEMENT_LOOKUP_OPTIONS}
          />
        )}
      </FormGroup>

      <FormGroup //
        title="Nature of property"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('natureOfProperty')}
            component={SelectField}
            debug={debug}
            disabled={disabled}
            format="number"
            options={PROPERTY$NATURE_OF_PROPERTY_LOOKUP_OPTIONS}
            placeholder={resolveSelectPlaceholder(true)}
          />
        )}
      </FormGroup>
      <FormGroup //
        title="Area of property"
        fieldAreaDirection="row"
      >
        <Area //
          name={fieldName('area')}
        />
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionProperty);
