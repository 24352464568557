import { endOfToday } from 'date-fns';
import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { getLookupValuesAllowingEmpty, requiredDependentOnContext, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { testUnaffectedProvider } from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/party-book/test-unaffected-provider';
import { VISIBILITY_CHECK_CLAIM_CATEGORY, VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID, VISIBILITY_CHECK_CLAIM_PARTIES } from '../../checks';
import { ClaimPartyCapacityEnum, ENUM_CLAIM_CATEGORY_OPTIONS, ENUM_CLAIM_PARTY_CAPACITY_OPTIONS, ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS } from '../../enums';
import { Claim, ClaimParty } from '../../models';
import { REQUIRED_CHECK_CLAIM_DATE, REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM, VISIBILITY_CHECK_CLAIM_DATE, VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM } from './checks';

const END_OF_TODAY = endOfToday();

const yupClaimPartyItem = yup
  .object<ClaimParty>()
  .nullable(true)
  .shape({
    partyBookId: yup // reference to party store
      .string()
      .default('')
      .nullable(true)
      .required(msg.REQUIRED)
      .test('Referenced party validity check', 'Party is invalid', testUnaffectedProvider)
      .test(createPartyBookItemTestForPartyBookId(NSW_CAVEAT_PARTY_FORM_CONFIG)),
    claimPartyCapacity: yup.mixed<ClaimPartyCapacityEnum>().oneOf(getLookupValuesAllowingEmpty(ENUM_CLAIM_PARTY_CAPACITY_OPTIONS), msg.INVALID_SELECTION).required(msg.REQUIRED)
  });

// path: claimDetails
export default yup
  .object<Claim>()
  .nullable(true)
  .shape({
    estateOrInterestClaimed: v.number().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS), msg.INVALID_SELECTION),
    claimCategory: yup //
      .mixed<number>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_CLAIM_CATEGORY,
          validationWhenVisible: v.number().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(ENUM_CLAIM_CATEGORY_OPTIONS), msg.INVALID_SELECTION)
        })
      ),
    detailsSupportingTheClaim: yup
      .string()
      .default('')
      .trim()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM,
          validationWhenVisible: yup
            .string()
            .default('')
            .max(255, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(255))
            .test(
              requiredDependentOnContext({
                isRequiredCheck: REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM
              })
            )
        })
      ),
    claimParties: yup.mixed<ClaimParty[]>().test(
      validateWhenVisible({
        isObjectOrArray: true,
        visibilityCheck: VISIBILITY_CHECK_CLAIM_PARTIES,
        validationWhenVisible: yup.array<ClaimParty>().nullable(true).of(yupClaimPartyItem)
      })
    ),
    claimDate: yup //
      .mixed<Date | string | null>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_CLAIM_DATE,
          validationWhenVisible: yupDatePicker.max(END_OF_TODAY).test(
            requiredDependentOnContext({
              isRequiredCheck: REQUIRED_CHECK_CLAIM_DATE
            })
          )
        })
      ),
    lrDocumentID: yup //
      .mixed<string>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID,
          validationWhenVisible: yup.string().default('').trim().typeError(msg.INVALID_VALUE).nullable(true)
        })
      )
  });
