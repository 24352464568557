import * as yup from 'yup';

import { BaseTitleReferenceModel, LandDescriptionModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference';

export function resolveTitleReferenceItemSchema<T extends BaseTitleReferenceModel, C extends object = {}>(disablePartDescriptionSupport?: boolean) {
  return yup
    .object<BaseTitleReferenceModel>({
      isSelected: yup.boolean().defined(),
      reference: yup.string(),
      landDescriptions: yup.mixed<LandDescriptionModel[]>()
    })
    .test(
      'titleReferences land descriptions check',
      disablePartDescriptionSupport ? 'Land Description must be provided' : 'Either Part Description or Land Description must be provided',
      function test(this: yup.TestContext<BaseTitleReferenceModel[]>, value: BaseTitleReferenceModel) {
        // Incase part description is supported, if the user can either provide part description as free text or select one of the land descriptions
        // The user has not selected part land affected
        const landDescriptions = value.landDescriptions ?? [];
        if (!(value.isSelected && value.isPartLandAffected)) {
          return true;
        }

        // The user has selected part land affected
        if (landDescriptions.length > 1) {
          if (disablePartDescriptionSupport) {
            // part description not supported, hence atleast one land description should be selected
            return landDescriptions.some(({ isSelected }) => isSelected);
          }
          const partDescription = value.partDescription ?? '';
          // either part description should be provided or one of the land descriptions must be selected
          return partDescription.length > 0 || landDescriptions.some(({ isSelected }) => isSelected);
        }

        return true;
      }
    )
    .defined() as yup.ObjectSchema<T, C>;
}
