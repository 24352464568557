import * as React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';

import converter from './conversion';
import { ApiDischargeOfMortgage2_24_0Model, DischargeOfMortgage2_24_0Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<DischargeOfMortgage2_24_0Model, ApiDischargeOfMortgage2_24_0Model>;

function DocumentForm(props: Props): JSX.Element {
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validationSchema={validationSchema}
        RootForm={RootForm}
      />
    </DndContextProvider>
  );
}

export default React.memo(DocumentForm);
