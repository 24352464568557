import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { DeceasedTenancyConfig, PartyCapacityLookup } from '../../deceasedTenancyConfig';
import { DeceasedTenancyPartyCapacityEnum } from '../../enums';
import { BaseDeceasedTenancyDetailComponentModel, EvidenceModel } from '../../models';
import { EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, EvidenceTypeEnum } from './enums';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_EVIDENCE_DATE, VISIBILITY_CHECK_EVIDENCE_DOCUMENT_REFERENCE, VISIBILITY_CHECK_EVIDENCE_JURISDICTION } from './visibilityChecks';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  disabled: boolean;
  proprietorSelected: boolean;
  deceasedTenancyConfig: DeceasedTenancyConfig;
}

function Evidence({ name, disabled, proprietorSelected, deceasedTenancyConfig }: Props): JSX.Element {
  const { values } = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { applicantCapacity } = values;
  const { disableEvidenceType, disableDefaultAddedEvidenceType, evidenceJurisdictionOptions, partyCapacityLookups } = deceasedTenancyConfig;
  const value: EvidenceModel = _get(values, name);
  const fieldName = createModelKeyAppender<EvidenceModel>(name);
  const classes = useStyles();

  const options = getEvidenceTypes(value.defaultAdded, proprietorSelected, partyCapacityLookups, applicantCapacity);
  const evidenceTypeDisabled = disabled || (disableEvidenceType && ((disableDefaultAddedEvidenceType && value.defaultAdded) || options.length === 1));

  return (
    <div data-section={name} className={classes.sectionWidth} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Field //
          name={fieldName('type')}
          component={SelectField}
          label="Evidence type"
          disabled={evidenceTypeDisabled}
          format="string"
          options={options}
          placeholder={resolveSelectPlaceholder(true)}
          className={classes.fieldWidth}
        />

        {VISIBILITY_CHECK_EVIDENCE_DATE(value) && (
          <Field //
            name={fieldName('date')}
            label="Evidence date"
            component={DatePickerField}
            disabled={disabled}
            disableFuture={true}
            className={classes.halfFieldWidth}
            validationOverflowDirection="horizontal"
          />
        )}

        {VISIBILITY_CHECK_EVIDENCE_JURISDICTION(value) && (
          <Field //
            name={fieldName('jurisdiction')}
            label="Jurisdiction"
            component={SelectField}
            disabled={disabled}
            format="string"
            options={evidenceJurisdictionOptions}
            placeholder={resolveSelectPlaceholder(true)}
            className={classes.halfFieldWidth}
          />
        )}

        {VISIBILITY_CHECK_EVIDENCE_DOCUMENT_REFERENCE(value) && (
          <Field //
            name={fieldName('documentReference')}
            label="Evidence document reference"
            component={InputField}
            disabled={disabled}
            className={classes.fieldWidth}
          />
        )}
      </FlexLayout>
    </div>
  );
}

function getEvidenceTypes(
  defaultAdded: boolean,
  isSelected: boolean,
  partyCapacityLookups: PartyCapacityLookup[],
  partyCapacity?: DeceasedTenancyPartyCapacityEnum | null
): LookupItemModel<EvidenceTypeEnum>[] {
  if (!isSelected) {
    return [{ id: EvidenceTypeEnum.DeathCertificate, name: EvidenceTypeEnum.DeathCertificate }];
  }

  if (!partyCapacity || !defaultAdded) {
    return EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS;
  }

  const evidenceTypes = partyCapacityLookups.find(pcl => pcl.capacity === partyCapacity)!.evidenceTypes;
  return evidenceTypes;
}

export default React.memo(Evidence);
