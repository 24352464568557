import React from 'react';

import Typography from '@mui/material/Typography';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import FeatureToggle from 'src/containers/shared/auth/profile-feature-toggle';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { ApiStatus } from 'src/utils/http';
import { resolveReportLink } from '../../helper';
import { ReportFeatureTypeEnum, SavedReportModel } from '../../models';
import ReportCard from './components/report-card';
import ReportFavouriteTable from './components/report-favourite-table';
import ReportScheduledTable from './components/report-schedule-table';
import { useStyles } from './styles';

export interface Props {
  favouriteReports: Array<SavedReportModel>;
  scheduledReports: Array<SavedReportModel>;
  reportPermissions: LookupEnumModel<ReportTypeEnum, string>[];
  reportCardsStatus: ApiStatus;
  savedReportsStatus: ApiStatus;
  className?: string;
}

function ReportDashboard({ favouriteReports, scheduledReports, reportPermissions, reportCardsStatus, savedReportsStatus, className }: Props) {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="column" className={className}>
      <Typography variant="h1" className={classes.title}>
        Reports
      </Typography>
      <FlexLayout //
        className={classes.cardContainer}
      >
        {reportCardsStatus === 'pending' || reportCardsStatus === 'idle' ? (
          <BlockLoader size={64} /> // set the size because the default size is smaller than the report-card component, so it will change the position of tables when it finishes loading
        ) : (
          reportPermissions.map(item => <ReportCard key={item.id} linkTo={resolveReportLink(ReportFeatureTypeEnum.Favourite, item.id)} title={item.name} type={item.id} />)
        )}
      </FlexLayout>
      <FeatureToggle featureToggleType={FeatureToggleEnum.biReportingSchedulerFavoriteEnabled}>
        <ReportFavouriteTable reports={favouriteReports} savedReportsStatus={savedReportsStatus} />
        <ReportScheduledTable reports={scheduledReports} savedReportsStatus={savedReportsStatus} />
      </FeatureToggle>
    </FlexLayout>
  );
}

export default React.memo(ReportDashboard);
