import { Action, createReducer } from 'typesafe-actions';

import { ResultItem, ResultItemModel, SearchBoxUpdatedApiRequest } from 'src/containers/shared/app-bar/components/search-box-new/models';
import useStoreSelector from 'src/hooks/useStoreSelector';
import { PaginatedItemsModel } from 'src/models';
import { ApiStatus } from 'src/utils/http';
import { actionFetchSearchBoxUpdatedFeed, actionResetSearchBoxFeed } from '../actions';

export interface SearchBoxesUpdatedApiResponse extends PaginatedItemsModel<ResultItem> {
  searchTerm?: string;
}

export interface SearchBoxUpdatedFeedState {
  items?: Array<ResultItemModel>;
  // This has same data structure as items, however, it will accumulate if searchTerm is the same, this is used for load more functionality in the global search
  newSearchItems?: Array<ResultItemModel>;
  totalCount: number;
  totalPages: number;
  query: SearchBoxUpdatedApiRequest;
  isLoading: boolean;
  isRefetching: boolean;
  status: ApiStatus;
  error?: string;
  pageNumber: number;
  pageSize: number;
}

const initialState: SearchBoxUpdatedFeedState = {
  items: undefined,
  newSearchItems: undefined,
  totalCount: 0,
  totalPages: 0,
  query: {
    searchTerm: ''
  },
  isLoading: false,
  isRefetching: false,
  status: 'idle',
  error: undefined,
  pageNumber: 1,
  pageSize: 15
};

export const useSearchBoxUpdated = (searchTerm: string) => {
  const searchFeed = useStoreSelector(store => store.searchBoxUpdatedFeed);
  if (searchFeed.query.searchTerm?.trim() === searchTerm?.trim()) {
    return searchFeed;
  }

  return { ...initialState };
};

const reducer = createReducer<
  //
  SearchBoxUpdatedFeedState,
  Action
>(initialState)
  .handleAction(actionFetchSearchBoxUpdatedFeed.request, (state, action): SearchBoxUpdatedFeedState => {
    const status = action.payload.searchTerm === state.query.searchTerm ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      // when current state's searchTerm is different from the action's searchTerm, that means user has changed searchTerm, we need to reset newSearchItems
      newSearchItems: action.payload.searchTerm !== state.query.searchTerm ? undefined : state.newSearchItems,
      query: {
        ...state.query,
        // these are the default
        pageNumber: 1,
        pageSize: 15,
        // payload will contain things to override pageNumber or pageSize if needed
        ...action.payload
      },
      error: undefined,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      status
    };
  })
  .handleAction(actionFetchSearchBoxUpdatedFeed.success, (state, action): SearchBoxUpdatedFeedState => {
    let newSearchItems: Array<ResultItemModel> = [...action.payload.data.items];
    // when state's searchTerm is same as the action's searchTerm, we accumulate the items
    if (
      action.payload.data.searchTerm === state.query.searchTerm && //
      state.newSearchItems &&
      state.pageNumber < action.payload.data.pageNumber // !important when the current state's pageNumber is bigger or equal to the api returned pageNumber, this means current state's data is stale data
    ) {
      newSearchItems = [...state.newSearchItems, ...action.payload.data.items];
    }

    return {
      ...state,
      ...action.payload.data,
      newSearchItems,
      status: 'resolved',
      isLoading: false,
      isRefetching: false
    };
  })
  .handleAction(actionFetchSearchBoxUpdatedFeed.failure, (state, action): SearchBoxUpdatedFeedState => {
    return {
      ...state,
      isLoading: false,
      isRefetching: false,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionResetSearchBoxFeed, (state, action): SearchBoxUpdatedFeedState => {
    return {
      ...initialState,
      query: {
        ...state.query,
        ...action.payload
      }
    };
  });

export default reducer;
