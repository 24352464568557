import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import { RadioClassKeys } from '@sympli/ui-framework/components/form/base-components/radio';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { TRANSACTION_DETAIL_CONSIDERATION_TYPE_LOOKUP_OPTIONS, YES_NO_LOOKUP_OPTIONS } from '../../enums';
import { NoticeOfAcquisitionDocumentModel, TransactionDetailModel } from '../../models';
import {
  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
  VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER,
  VISIBILITY_CHECK_TRANSACTION_DETAIL$DATE_OF_POSSESSION_TRANSFER,
  VISIBILITY_CHECK_TRANSACTION_DETAIL$SETTLEMENT_DATE
} from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
}

function SectionDetailOfTransaction({ name }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;
  const value: TransactionDetailModel = values.transactionDetail;
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<TransactionDetailModel>(name);
  const radioFieldClasses = useMemo<Partial<ClassNameMap<keyof ReturnType<RadioClassKeys>>>>(
    () => ({
      formControlLabel: classes.radioFormControlLabel
    }),
    [classes]
  );

  return (
    <div data-name={name} className={classes.root}>
      <FormGroup //
        title="Consideration"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('considerationType')}
            component={SelectField}
            debug={debug}
            disabled={true}
            format="number"
            options={TRANSACTION_DETAIL_CONSIDERATION_TYPE_LOOKUP_OPTIONS}
            placeholder={resolveSelectPlaceholder(true)}
            fullWidth
          />
        )}
      </FormGroup>
      {VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT(
        value
      ) && (
        <>
          <FormGroup //
            title="Details of transaction in AUD ($)"
            fieldAreaDirection="column"
          >
            <div className={classes.currencyGrid}>
              <Field //
                name={fieldName('totalSalePrice')}
                component={CurrencyInputField}
                disabled={true}
                label="Total sale price (GST inclusive)"
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
                classes={{
                  helperTextError: classes.totalSalePriceHelperTextError
                }}
              />
              <Field //
                name={fieldName('gstAmount')}
                component={CurrencyInputField}
                disabled={disabled}
                label={
                  <>
                    GST amount (if applicable)
                    <InfoIconWithTooltip flow="inline" tooltipContent="Supply the GST amount included in the Total sale price." />
                  </>
                }
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
              />
              <Field //
                name={fieldName('deposit')}
                component={CurrencyInputField}
                disabled={disabled}
                label="Deposit amount (optional)"
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
              />
            </div>
          </FormGroup>

          <FormGroup //
            title="Additional amounts (if applicable)"
            fieldAreaDirection="column"
          >
            <div className={classes.currencyGrid}>
              <Field //
                name={fieldName('businessGoodwillAmount')}
                component={CurrencyInputField}
                disabled={disabled}
                label="Business goodwill amount"
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
              />
              <Field //
                name={fieldName('businessIntellectualPropertyAmount')}
                component={CurrencyInputField}
                disabled={disabled}
                label="Business intellectual property amount"
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
              />
              <Field //
                name={fieldName('goodsComponentAmount')}
                component={CurrencyInputField}
                disabled={disabled}
                label="Goods component amount"
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
              />
              <Field //
                name={fieldName('contractRebateAmount')}
                component={CurrencyInputField}
                disabled={disabled}
                label="Contract rebate amount"
                labelTextAlign="center"
                placeholder="0.00"
                format="number"
              />
            </div>
          </FormGroup>
        </>
      )}

      {VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER(value) && (
        <>
          <FormGroup //
            title="Market value in AUD ($)"
            fieldAreaDirection="column"
          >
            {({ titleId }) => (
              <Field //
                aria-labelledby={titleId}
                name={fieldName('marketValue')}
                component={CurrencyInputField}
                disabled={disabled}
                placeholder="0.00"
                format="number"
              />
            )}
          </FormGroup>
          <FormGroup //
            title="Valuer details (optional)"
            fieldAreaDirection="column"
          >
            <Field //
              name={fieldName('valuerName')}
              component={InputField}
              disabled={disabled}
              label="Valuer name"
              placeholder={resolvePlaceholder()}
              fullWidth
            />

            <Field //
              name={fieldName('valuerLicenceNumber')}
              component={InputField}
              disabled={disabled}
              label="Valuer licence number"
              placeholder={resolvePlaceholder()}
              fullWidth
            />
          </FormGroup>
        </>
      )}

      <FormGroup //
        title="Date of contract (if applicable)"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('contractDate')}
            component={DatePickerField}
            disabled={disabled}
            className={classes.quarterWidth}
            disableFuture
            validationOverflowDirection="horizontal"
          />
        )}
      </FormGroup>

      <FormGroup //
        title="Date of possession or transfer"
        fieldAreaDirection="column"
        formTip="The date of possession is not the date of occupancy, and must not be a future date. It is the date on which legal possession was taken."
      >
        {({ titleId }) => (
          <>
            <Field //
              name={fieldName('isTransferDateSameAsSettlementDate')}
              component={CheckboxField}
              disabled={disabled}
              label="Same as settlement date"
              className={classes.sameAsSettlementDate}
            />

            {VISIBILITY_CHECK_TRANSACTION_DETAIL$DATE_OF_POSSESSION_TRANSFER(value) && (
              <Field //
                aria-labelledby={titleId}
                name={fieldName('dateOfPossessionTransfer')}
                component={DatePickerField}
                className={classes.quarterWidth}
                disabled={disabled}
                validationOverflowDirection="horizontal"
              />
            )}
            {VISIBILITY_CHECK_TRANSACTION_DETAIL$SETTLEMENT_DATE(value) && (
              <Field //
                aria-labelledby={titleId}
                name={fieldName('settlementDate')}
                component={DatePickerField}
                className={classes.quarterWidth}
                disabled={true}
                validationOverflowDirection="horizontal"
              />
            )}
          </>
        )}
      </FormGroup>

      <FormGroup //
        title="Is this purchase a terms sale?"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('isPurchaseTermsSale')}
            component={RadioField}
            options={YES_NO_LOOKUP_OPTIONS}
            format="boolean"
            disabled={disabled}
            classes={radioFieldClasses}
          />
        )}
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionDetailOfTransaction);
