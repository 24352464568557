import React, { useCallback } from 'react';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import CurrentPriorityNotice from '../../components/current-priority-notice';
import { CurrentPriorityNoticeModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionPriorityNotices({ name, className, ...rest }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<CurrentPriorityNoticeModel>) => (
      //
      <CurrentPriorityNotice name={itemBinding} />
    ),
    []
  );

  return (
    <div data-section={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={ItemRenderProps => (ItemRenderProps.itemIndex === 0 ? 'Priority notices' : '')}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
        classes={{
          formGroup: classes.formGroup
        }}
      />
    </div>
  );
}

export default React.memo(SectionPriorityNotices);
