import React from 'react';

import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';

import { IconTeamWorkload } from 'src/containers/dashboard/reports/icons/IconTeamWorkload';
import { IconUserActivity } from 'src/containers/dashboard/reports/icons/IconUserActivity';
import { IconUserPermissions } from 'src/containers/dashboard/reports/icons/IconUserPermissions';
import { useStyles } from './styles';

export interface Props {
  linkTo: string;
  title: string;
  type: ReportTypeEnum;
}

function ReportCard({ linkTo, title, type }: Props) {
  const classes = useStyles();

  const renderIcon = (type: ReportTypeEnum) => {
    switch (type) {
      default:
      case ReportTypeEnum.UserActivityReport:
        return <IconUserActivity className={classes.avatar} />;
      case ReportTypeEnum.UserPermissionReport:
        return <IconUserPermissions className={classes.avatar} />;
      case ReportTypeEnum.TeamWorkloadReport:
        return <IconTeamWorkload className={classes.avatar} />;
    }
  };

  return (
    <NavLink to={linkTo} className={classes.root}>
      {renderIcon(type)}
      <Typography variant="subtitle1" className={classes.text}>
        {title}
      </Typography>
    </NavLink>
  );
}

export default React.memo(ReportCard);
