import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {},
    accordionRoot: {
      marginTop: 16,
      marginBottom: 24,
      backgroundColor: colors.SUNNY_DAY_TRANSLUCENT,
      boxShadow: 'none',
      '&::before': {
        height: 0
      }
    },
    accordionSummaryRoot: {
      padding: '0 12px'
    },
    accordionSummaryText: {
      marginLeft: 4,
      color: colors.NEUTRAL_1000,
      fontSize: 14,
      fontWeight: 400
    }
  }),
  {
    name: 'ActiveWorkspaceAccordion.tsx'
  }
);
