import NonDashboardWrapper from 'src/components/layout/v2/NonDashboardWrapper';
import { useProfile } from '../shared/auth/reducers';
import HelpPage from './HelpPage';

function HelpPageContainer() {
  const subscriberName = useProfile().data?.subscriberName;
  const supportPageUrl = useProfile().data?.supportPageUrl;

  return (
    <NonDashboardWrapper>
      <HelpPage subscriberName={subscriberName} supportPageUrl={supportPageUrl} />
    </NonDashboardWrapper>
  );
}

export default HelpPageContainer;
