import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { AssignmentTypeEnum, DashboardTypeEnum } from 'src/containers/dashboard/shared/models';
import { WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import { AggregatorComponentBaseProps, AggregatorNameEnum, AggregatorQuery, StandaloneDashboardAggregatorApiRequest } from '../models';
import { useStyles } from './styles';

const payloadResolver = (assignmentType: AssignmentTypeEnum): StandaloneDashboardAggregatorApiRequest => {
  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.toBeReworked,
      workspaceStatus: [WorkspaceStatusEnum.InPreparationWithErrors]
    }
  ];

  const payload: StandaloneDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

function ToBeReworkedCard(props: AggregatorComponentBaseProps) {
  const classes = useStyles();

  return (
    <AggregatorCardWrapper //
      payloadResolver={payloadResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <AggregatorCard
          title="Total"
          cardType={DashboardTypeEnum.Standalone}
          isLoading={isLoading}
          onActionClick={onActionClick}
          titleStyle={classes.cardTodayTitle}
          contentStyle={classes.cardToday}
          statusStyle={classes.cardStatusToday}
          actionStyle={classes.cardToday}
          isFetchingGridData={isFetchingGridData}
          items={[
            {
              id: AggregatorNameEnum.toBeReworked,
              content,
              status: 'To be Reworked',
              actionText: 'Show me'
            }
          ]}
        />
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(ToBeReworkedCard);
