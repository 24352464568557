import _cloneDeep from 'lodash-es/cloneDeep';

import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructions2_24_0Model, LodgementInstructions2_24_0Model } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions2_24_0Model, ApiLodgementInstructions2_24_0Model> {
  public originalApiModel: ApiLodgementInstructions2_24_0Model;
  constructor(initialValues: ApiLodgementInstructions2_24_0Model) {
    this.originalApiModel = _cloneDeep(initialValues);
  }

  fromApiToFormModel(apiValues: ApiLodgementInstructions2_24_0Model, context: IConverterContext): LodgementInstructions2_24_0Model {
    let formValues = applyDefaultMap(apiValues, fallbackMap);
    formValues.isResponsibleParticipant = context.currentParticipant?.isResponsibleParticipant ?? false;

    return {
      ...formValues,
      isSameRoleAsResponsible: context.participants?.find(p => p.id === formValues.responsibleParticipantId)?.workspaceRole.id === context.currentParticipant?.workspaceRole.id,
      currentParticipantId: context.currentParticipant?.id ?? null
    };
  }
  fromFormToApiModel(formValues: LodgementInstructions2_24_0Model, _: ApiLodgementInstructions2_24_0Model): ApiLodgementInstructions2_24_0Model {
    const { currentParticipantId, isSameRoleAsResponsible, ...rest } = applyVisibilityFallbackMap(formValues, fallbackMap);

    // If participant was responsible and then responsibleParticipantId is changed, only persist the responsibleParticipantId change
    if (formValues.isResponsibleParticipant && currentParticipantId !== formValues.responsibleParticipantId) {
      return { ...this.originalApiModel, responsibleParticipantId: rest.responsibleParticipantId };
    }

    return rest;
  }
}

export default Converter;
