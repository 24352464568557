import { AuthorityComplianceReportTypeEnum, LogTypeEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { SettlementNotificationStatusEnum, SettlementNotificationTypeEnum } from '../../models';

export interface LogWorkspaceSettlementInProgressEntity {
  Message: string;
  NotificationType: SettlementNotificationTypeEnum;
  StatusCode: SettlementNotificationStatusEnum;
}

export interface LogWorkspaceInviteEntity {
  InvitorId: string;
  InvitorName: string;
  InviteeId: string;
  InviteeName: string;
  InviteeRole: WorkspaceRoleEnum;
}

export interface WorkspaceActivityLogModel {
  participant?: WorkspaceParticipantApiResponse;
  participantId?: string;
  dateTime: string;
  dateTimeUtc: string;
  userId?: string;
  reference?: string;
  text: string;
  workspaceLogType: LogTypeEnum;
  data: string;
  dataObject?: object;
  isPublicView: boolean;
  isTriggeredBySystem: boolean;
  template: string; // the markdown template generated from the item record
}

export interface LogEntity {
  dateTime: string;
  text: string;
}

export interface LogLineEntity {
  time: string;
  text: string;
}

export interface LogDateGroupEntity {
  date: string;
  logs: Array<LogLineEntity>;
}

export interface GetAuthorityComplianceReportRequest {
  authorityMessageId: string;
  messageType: AuthorityComplianceReportTypeEnum;
}

export interface AuthorityComplianceData {
  AuthorityMessageId: string;
  LodgementCaseName?: string;
  LodgementCaseId?: string;
}

export const AuthorityComplianceReportTypeDisplayMapping: Record<AuthorityComplianceReportTypeEnum, string> = {
  [AuthorityComplianceReportTypeEnum.LodgementVerification]: 'Lodgement verification',
  [AuthorityComplianceReportTypeEnum.Lodgement]: 'Lodgement'
};

export enum WorkspaceActivityLogLinkEnum {
  DirectionLink = 1,
  TitleLink = 2,
  DocumentLink = 3,
  AllDirectionsLink = 4
}

export const AuthorityLodgementVerificationComplianceLogType: LogTypeEnum[] = [
  LogTypeEnum.LodgementVerificationFailed,
  LogTypeEnum.LodgementVerificationSuccess,
  LogTypeEnum.SLCLodgementVerificationSuccess,
  LogTypeEnum.SLCLodgementVerificationFailed,
  LogTypeEnum.SettlementLodgementVerificationFailed,
  LogTypeEnum.SettlementLodgementVerificationSucceeded
];

export const AuthorityLodgementComplianceLogType: LogTypeEnum[] = [
  LogTypeEnum.LodgementFailed,
  LogTypeEnum.LodgementSuccess,
  LogTypeEnum.LodgementCompleted,
  LogTypeEnum.SLCLodgementCompleted,
  LogTypeEnum.SLCLodgementFailed,
  LogTypeEnum.SLCLodgementSuccess,
  LogTypeEnum.SettlementLodgementFailed,
  LogTypeEnum.SettlementLodgementSucceeded
];

export const AuthorityComplianceLogType: LogTypeEnum[] = [...AuthorityLodgementVerificationComplianceLogType, ...AuthorityLodgementComplianceLogType];
