import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import environments from 'src/environments';
import http from 'src/utils/http';
import { StandaloneDashboardAggregatorApiRequest, StandaloneDashboardAggregatorApiResponse } from './aggregators/models';

const { TARGET_MACHINE, TARGET_API_V2 } = environments;

export const fetchAggregatorInfo = (request: StandaloneDashboardAggregatorApiRequest) => {
  const { assignmentType: existingAssignmentType, ...rest } = request;

  // reassign is equal to assign to group
  const assignmentType = existingAssignmentType === AssignmentTypeEnum.ReAssigned ? AssignmentTypeEnum.AssignedToGroup : existingAssignmentType;

  return http.post<StandaloneDashboardAggregatorApiResponse>(
    '/dashboards/aggregators?workspaceType=0',
    { assignmentType, ...rest },
    { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` }
  );
};
