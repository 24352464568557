import { call, put, select, takeLatest } from 'typed-redux-saga';

import { UserProfileModel } from '@sympli/api-gateway/shared';

import http from 'src/utils/http';
import {
  actionFetchTrustAccountDetail,
  actionStartTrustAccountDetails,
  actionTrustAccountRequestPostSave,
  actionUpdateTrustAccountDetails,
  actionUpdateTrustAccountRequestProgress
} from '../actions/trustAccountDetails';
import {
  DEFAULT_TRUST_ACCOUNT_DETAILS,
  TrustAccountDetails,
  TrustAccountDetailsApiResponse,
  TrustAccountRequestDetailsApiResponse,
  TrustAccountRequestStatusEnum,
  TrustAccountRequestTypeEnum,
  TrustAccountSetupStepEnum,
  TrustAccountSignatoriesTypeEnum
} from '../trust-accounts/detail/models';
import { trustAccountDetailsSelector, trustAccountRequestDetailsSelector } from '../trust-accounts/detail/selectors';

const fetchTrustAccountRequestDetail = (trustAccountId: string) =>
  http.get<TrustAccountRequestDetailsApiResponse>(`/settings/trust-accounts/requests/${encodeURIComponent(trustAccountId)}`);

const fetchTrustAccountDetail = (trustAccountId: string) => http.get<TrustAccountDetailsApiResponse>(`/settings/trust-accounts/${encodeURIComponent(trustAccountId)}`);

function* startTrustAccountDetails() {
  const data: UserProfileModel | undefined = yield select(s => s.profile.data);
  const details: TrustAccountDetails = {
    ...DEFAULT_TRUST_ACCOUNT_DETAILS,
    ...(data
      ? {
          contactPerson: {
            fullName: [data.firstName, data.middleName, data.lastName].filter(Boolean).join(' '),
            email: data.email,
            work: data.work || data.mobileNumber
          }
        }
      : {})
  };

  yield put(
    actionFetchTrustAccountDetail.success({
      data: {
        details,
        step: TrustAccountSetupStepEnum.AccountDetails,
        progress: TrustAccountSetupStepEnum.AccountDetails
      }
    })
  );
}

function* sagaFetchTrustAccountDetails({ payload: { id, isRequest, changeType } }: ReturnType<typeof actionFetchTrustAccountDetail.request>) {
  try {
    let details: TrustAccountDetails;
    if (isRequest) {
      const trustAccountRequestDetail = yield* call(fetchTrustAccountRequestDetail, id);
      details = trustAccountRequestDetailsSelector(trustAccountRequestDetail);
    } else {
      const trustAccountDetail = yield* call(fetchTrustAccountDetail, id);
      details = trustAccountDetailsSelector(trustAccountDetail);
    }

    if (changeType?.toLowerCase() === 'add-signers') {
      details.type = TrustAccountRequestTypeEnum.AddSignatories;
      details.status = TrustAccountRequestStatusEnum.NotSubmitted;
    }

    yield put(
      actionFetchTrustAccountDetail.success({
        data: {
          details,
          step: getInitialStep(details),
          progress: getCurrentProgress(details)
        }
      })
    );
  } catch (error) {
    yield put(actionFetchTrustAccountDetail.failure({ error }));
  }
}

function* sagaTrustAccountRequestPostSave({ payload }: ReturnType<typeof actionTrustAccountRequestPostSave>) {
  const details = trustAccountRequestDetailsSelector(payload);
  yield put(actionUpdateTrustAccountDetails(details));
  yield put(actionUpdateTrustAccountRequestProgress({ status: payload.status }));
}

const getInitialStep = (details: TrustAccountDetails) => {
  if (details.type === TrustAccountRequestTypeEnum.AddSignatories) {
    return details.status >= TrustAccountRequestStatusEnum.Reviewed ? TrustAccountSetupStepEnum.FormUpload : TrustAccountSetupStepEnum.Signers;
  } else {
    return details.status >= TrustAccountRequestStatusEnum.Reviewed ? TrustAccountSetupStepEnum.FormUpload : TrustAccountSetupStepEnum.AccountDetails;
  }
};

const getCurrentProgress = (details: TrustAccountDetails) => {
  if (details.type === TrustAccountRequestTypeEnum.AddSignatories) {
    if (details.status >= TrustAccountRequestStatusEnum.Reviewed) {
      return TrustAccountSetupStepEnum.FormUpload;
    }
    if (details.trustAccountSignatories?.trustAccountSignatoriesDetails.some(s => s.type === TrustAccountSignatoriesTypeEnum.Added)) {
      return TrustAccountSetupStepEnum.Review;
    } else {
      return TrustAccountSetupStepEnum.Signers;
    }
  } else {
    if (details.status >= TrustAccountRequestStatusEnum.Reviewed) {
      return TrustAccountSetupStepEnum.FormUpload;
    }
    if (details.trustAccountOwners?.accountOwners.length) {
      return TrustAccountSetupStepEnum.Review;
    }
    if (details.trustAccountSignatories?.trustAccountSignatoriesDetails?.length) {
      return TrustAccountSetupStepEnum.Owners;
    }
    if (details.trustAccountDetail?.accountDescription) {
      return TrustAccountSetupStepEnum.Signers;
    }
    return TrustAccountSetupStepEnum.AccountDetails;
  }
};

export default [
  //
  takeLatest(actionFetchTrustAccountDetail.request, sagaFetchTrustAccountDetails),
  takeLatest(actionStartTrustAccountDetails, startTrustAccountDetails),
  takeLatest(actionTrustAccountRequestPostSave, sagaTrustAccountRequestPostSave)
];
