import { ParticipantArchivedTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export interface WithdrawParticipantFormikModel {
  reasonOption: WithdrawReasonEnum | null;
  reasonDescription?: string;
  isApplyToLinkedWorkspace: boolean;
}

export interface WithdrawParticipantApiModel {
  reason: string;
  archiveType: ParticipantArchivedTypeEnum;
  isApplyToLinkedWorkspace: boolean;
}

export enum WithdrawReasonEnum {
  ContractNotProceeding,
  WeAreNotRepresentingThisParty,
  TransactionCannotBeCompletedElectronically,
  WeWereInvitedIncorrectly,
  TransactionCannotBeCompletedOnSympliPlatform,
  CustomerNoLongerProceeding,
  SupportingDocumentationNotProvided,
  Other
}

export const WITHDRAW_PARTICIPANT_REASON_OPTIONS: LookupEnumModel<WithdrawReasonEnum>[] = [
  { id: WithdrawReasonEnum.ContractNotProceeding, name: 'Contract not proceeding' },
  { id: WithdrawReasonEnum.WeAreNotRepresentingThisParty, name: 'We are not representing this party' },
  { id: WithdrawReasonEnum.TransactionCannotBeCompletedElectronically, name: 'Transaction cannot be completed electronically' },
  { id: WithdrawReasonEnum.WeWereInvitedIncorrectly, name: 'We were invited incorrectly' },
  { id: WithdrawReasonEnum.TransactionCannotBeCompletedOnSympliPlatform, name: 'Transaction cannot be completed on Sympli platform' },
  { id: WithdrawReasonEnum.CustomerNoLongerProceeding, name: 'Customer no longer proceeding' },
  { id: WithdrawReasonEnum.SupportingDocumentationNotProvided, name: 'Supporting documentation not provided' },
  { id: WithdrawReasonEnum.Other, name: 'Other' }
];

export const WithdrawParticipantReasonNameMap: Record<WithdrawReasonEnum, string> = {
  [WithdrawReasonEnum.ContractNotProceeding]: 'Contract not proceeding',
  [WithdrawReasonEnum.WeAreNotRepresentingThisParty]: 'We are not representing this party',
  [WithdrawReasonEnum.TransactionCannotBeCompletedElectronically]: 'Transaction cannot be completed electronically',
  [WithdrawReasonEnum.WeWereInvitedIncorrectly]: 'We were invited incorrectly',
  [WithdrawReasonEnum.TransactionCannotBeCompletedOnSympliPlatform]: 'Transaction cannot be completed on Sympli platform',
  [WithdrawReasonEnum.CustomerNoLongerProceeding]: 'Customer no longer proceeding',
  [WithdrawReasonEnum.SupportingDocumentationNotProvided]: 'Supporting documentation not provided',
  [WithdrawReasonEnum.Other]: 'Other'
};
