import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import { WaAddressSelectField_2_19 as AddressSelectField } from '@sympli-mfe/document-components/address-field/wa/2-19';
import { WA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/wa/2-19/config';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import { RadioClassKeys } from '@sympli/ui-framework/components/form/base-components/radio';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import Section from 'src/containers/settings/components/section';
import { ISSUING_INSTRUCTION_LOOKUP_OPTIONS, ISSUING_METHOD_LOOKUP_OPTIONS, ISSUING_OFFICE_LOOKUP_OPTIONS } from '../../enums';
import { DuplicateHoldingDetailModel, DuplicateIssuingInstructionModel, IssuingPartyModel, LodgementInstructions2_19_1Model } from '../../models';
import { VISIBILITY_CHECK_EDITION_DATE, VISIBILITY_CHECK_ISSUING_BOX_NUMBER, VISIBILITY_CHECK_ISSUING_OFFICE, VISIBILITY_CHECK_ISSUING_PARTY } from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

interface FormProps {
  partyBook?: PartyModel[];
  addressBook?: AddressBookEntityModel[];
  setPartyBook: (value: PartyModel[]) => void;
  setAddressBook: (value: AddressBookEntityModel[]) => void;
}

const fieldName = modelKey<LodgementInstructions2_19_1Model>();
const duplicateHolingDetailFieldName = modelKey<DuplicateHoldingDetailModel>();
const issuingInstructionDetailFieldName = createModelKeyAppender<DuplicateIssuingInstructionModel>(duplicateHolingDetailFieldName('issuingInstructionDetails'));
const issuingPartyFieldName = createModelKeyAppender<IssuingPartyModel>(issuingInstructionDetailFieldName('issuingParty'));

function DuplicateHoldingDetail({ partyBook = [], addressBook = [], setPartyBook, setAddressBook }: FormProps): JSX.Element {
  const { disabled, dialogPortalId } = useDocumentContext();
  const { values } = useFormikContext<DuplicateHoldingDetailModel>();
  const { titleReference, issuingInstructionDetails } = values;
  const classes = useStyles();
  const title = `Duplicate holding details for ${titleReference}`;
  const isDuplicateToIssue = issuingInstructionDetails && VISIBILITY_CHECK_ISSUING_PARTY(null, issuingInstructionDetails, null, null, null);

  const radioFieldClasses = useMemo<Partial<ClassNameMap<keyof ReturnType<RadioClassKeys>>>>(
    () => ({
      formControlLabel: classes.radioFormControlLabel
    }),
    [classes]
  );

  return (
    <FormGroup title={title} paddingBottom fieldAreaDirection="column">
      <div className={classes.grid}>
        <Field //
          name={duplicateHolingDetailFieldName('editionNumber')}
          label="Duplicate edition number"
          component={InputField}
          disabled={disabled}
        />
        {VISIBILITY_CHECK_EDITION_DATE(values) && (
          <Field //
            name={duplicateHolingDetailFieldName('editionDate')}
            label="Edition date"
            component={DatePickerField}
            disabled={disabled}
            className={classes.halfWidth}
          />
        )}
      </div>
      <Field //
        aria-label="Issuing Instructions"
        component={RadioField}
        disabled={disabled}
        name={issuingInstructionDetailFieldName('issuingInstruction')}
        label="Duplicate issuing instructions"
        options={ISSUING_INSTRUCTION_LOOKUP_OPTIONS}
        format="number"
        vertical
        className={classNames(classes.issuingInstruction, isDuplicateToIssue && classes.isDuplicateToIssue)}
      />
      {isDuplicateToIssue && (
        <ArrowBox className={classNames(classes.issuingInstructionDetails)} backgroundColor="darkGrey">
          <PartySelectField //
            name={issuingPartyFieldName('partyBookId')}
            data-name={issuingPartyFieldName('partyBookId')}
            dialogPortalId={dialogPortalId}
            partyFormConfig={WA_PARTY_FORM_CONFIG}
            disabled={disabled}
            book={partyBook}
            setBookValue={setPartyBook}
            unit="Issuing party"
          />
          <AddressSelectField //
            name={issuingPartyFieldName('addressBookId')}
            data-name={issuingPartyFieldName('addressBookId')}
            dialogPortalId={dialogPortalId}
            disabled={disabled}
            book={addressBook}
            setBookValue={setAddressBook}
            label="Delivery address"
          />
          <Field //
            name={issuingInstructionDetailFieldName('issuingMethod')}
            label="Issuing method"
            options={ISSUING_METHOD_LOOKUP_OPTIONS}
            component={RadioField}
            disabled={disabled}
            format="number"
            classes={radioFieldClasses}
          />
          {VISIBILITY_CHECK_ISSUING_OFFICE(issuingInstructionDetails) && (
            <Field //
              name={issuingInstructionDetailFieldName('issuingOffice')}
              label="Issuing office"
              options={ISSUING_OFFICE_LOOKUP_OPTIONS}
              component={RadioField}
              disabled={disabled}
              format="number"
              classes={radioFieldClasses}
            />
          )}

          {VISIBILITY_CHECK_ISSUING_BOX_NUMBER(issuingInstructionDetails) && (
            <Field //
              name={issuingInstructionDetailFieldName('issuingBoxNumber')}
              label="Issuing box number"
              component={InputField}
              disabled={disabled}
              className={classes.halfWidth}
            />
          )}
        </ArrowBox>
      )}
    </FormGroup>
  );
}

const MemoisedDuplicateHoldingDetail = React.memo(DuplicateHoldingDetail);

function SectionDuplicateHoldingDetail({ name, className }: Props): JSX.Element {
  const { values, setFieldValue } = useFormikContext<LodgementInstructions2_19_1Model>();
  const { duplicateHoldingDetail, partyBook, addressBook } = values;

  const setPartyBook = useCallback((value: PartyModel[]) => setFieldValue(fieldName('partyBook'), value), [setFieldValue]);
  const setAddressBook = useCallback((value: AddressBookEntityModel[]) => setFieldValue(fieldName('addressBook'), value), [setFieldValue]);
  return (
    <Section title="Validate your CT" data-section={name} className={className} data-testid={name}>
      {duplicateHoldingDetail?.map((value, index) => {
        return (
          <Subform //
            key={`${fieldName('duplicateHoldingDetail')}.${value.titleReference}`}
            subformBindingPath={`${fieldName('duplicateHoldingDetail')}[${index}]`}
            component={MemoisedDuplicateHoldingDetail}
            partyBook={partyBook}
            setPartyBook={setPartyBook}
            addressBook={addressBook}
            setAddressBook={setAddressBook}
          />
        );
      })}
    </Section>
  );
}

export default React.memo(SectionDuplicateHoldingDetail);
