import * as yup from 'yup';

import { QldDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { yupApplicantAddress } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/applicant-address/validationSchema';
import { yupApplicants } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/applicants/validationSchema';
import yupPartyBook from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/party-book/validationSchema';
import { yupTitleReferences } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/title-references/validationSchema';
import { yupDeceasedTenancyDetailSchema } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/validationSchema';
import { getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSMISSION_DECEASED_TENANCY_VALIDATION_CONFIGURATION, QLD_TRANSMISSION_PARTY_FORM_CONFIG } from './config';
import { TransmissionApplication2_24_0_Model } from './models';

export default yup
  .object<TransmissionApplication2_24_0_Model>({
    partyBookApi: yup.array<ApiDocumentPartyModel<QldDocumentPartyJustification>>().defined(),
    deceasedTenancyDetail: yupDeceasedTenancyDetailSchema(getLegalEntityNameQLD, false, QLD_TRANSMISSION_DECEASED_TENANCY_VALIDATION_CONFIGURATION),
    titleReferences: yupTitleReferences(true),
    partyBook: yupPartyBook(QLD_TRANSMISSION_PARTY_FORM_CONFIG),
    applicants: yupApplicants(QLD_TRANSMISSION_PARTY_FORM_CONFIG, true),
    invalidParties: yup.mixed<boolean>(),
    applicantAddress: yupApplicantAddress,
    addressBook: yup.array<AddressBookEntityModel>().defined()
  })
  .defined()
  .log();
