import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { DischargeOfMortgage2_21_1Model } from './models';

export const fallbackMap: FallbackMap<DischargeOfMortgage2_21_1Model> = {
  partyBook: NO_FALLBACK,
  partyBookApi: NO_FALLBACK,
  mortgages: NO_FALLBACK,
  mortgagees: NO_FALLBACK,
  needToGeneratePartyBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        landDescriptions: NO_FALLBACK
      }
    }
  }
};
