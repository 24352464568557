import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { colors } from '@sympli/ui-framework/theme';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs/Breadcrumbs';
import StatusProgress from 'src/components/workspace-status-rebuild/status-progress';
import { getLodgementOnlyWorkspaceStatusEnumMapping, tooltipDescription } from './helper';
import { LodgementOnlyQueuedWorkspaceSteps, LodgementOnlyWorkspaceSteps } from './models';

interface Props {
  workspaceStatusId: WorkspaceStatusEnum;
  lodgementCaseStatusId: LodgementCaseStatusEnum;
}

function LodgementOnlyWorkspaceStatusProgress({
  //
  workspaceStatusId,
  lodgementCaseStatusId
}: Props) {
  const steps = workspaceStatusId === WorkspaceStatusEnum.LodgementInQueue ? LodgementOnlyQueuedWorkspaceSteps : LodgementOnlyWorkspaceSteps;

  const { step: currentStatus, variant } = getLodgementOnlyWorkspaceStatusEnumMapping({
    //
    workspaceStatusId,
    lodgementCaseStatusId
  });
  const currentStep = steps.indexOf(currentStatus) + 1;
  const customizedStep = workspaceStatusId === WorkspaceStatusEnum.Archived || workspaceStatusId === WorkspaceStatusEnum.Abandoned ? currentStatus : undefined;

  return (
    <FlexLayout flexDirection="row" justifyContent="flex-start">
      <StatusProgress //
        steps={steps}
        currentStep={currentStep}
        variant={variant}
        customizedStep={customizedStep}
        sxWrapper={{
          margin: 'unset'
        }}
        tooltipTitle={
          <Box display="flex" flexDirection="column">
            <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
              {currentStatus}
            </Typography>
            {tooltipDescription(currentStatus, workspaceStatusId)}
            {!customizedStep ? (
              <Breadcrumbs //
                currentStep={currentStep} // WEB-31850 - if queued we need to display Ready
                steps={LodgementOnlyWorkspaceSteps}
                sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
              />
            ) : undefined}
          </Box>
        }
      />
    </FlexLayout>
  );
}

export default React.memo(LodgementOnlyWorkspaceStatusProgress);
