import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { PartyModel } from 'src/containers/documents/scaffolded-form/vic/2-18/components/nomination/models';
import { NominationDetailsModel } from '../../models';

interface Props {
  name: string;
  ctControllerName: string;
  ctControllerValue: PartyModel;
  autoFocus: boolean;
}

function SectionNominationDetails({ name, ctControllerName, ctControllerValue, autoFocus }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<NominationDetailsModel>(name);
  const fieldNameCtController = createModelKeyAppender<PartyModel>(ctControllerName);

  return (
    <Section title="Nomination details" data-name={name}>
      <FormGroup title="CT controller">
        <TextOnly name={fieldNameCtController('legalEntityName')} value={ctControllerValue.legalEntityName} fullWidth />
      </FormGroup>
      <FormGroup title="Instrument text">
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            autoFocus={autoFocus}
            name={fieldName('instrumentText')}
            multiline
            minRows={2}
            disabled={disabled}
            component={InputField}
            fullWidth
          />
        )}
      </FormGroup>
      <FormGroup title="Parties in instrument text">
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('partiesInInstrumentText')}
            multiline
            minRows={2}
            disabled={disabled}
            component={InputField}
            fullWidth
          />
        )}
      </FormGroup>
    </Section>
  );
}

export default React.memo(SectionNominationDetails);
