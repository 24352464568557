import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    content: {
      paddingLeft: 18,
      width: 'auto'
    },
    errorMessage: {
      margin: '16px auto'
    }
  },
  { name: 'DocumentViewer' }
);
