import * as React from 'react';

import { useDispatch } from 'react-redux';

import { MfaLinkedDeviceModel } from 'src/containers/personal-profile/multi-factor-auth-devices/api';
import { actionFetchUserLinkedDevicesFeed, ConfirmDialogModel } from 'src/containers/settings/settings/actions/users';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import TabLinkedDevices from './TabLinkedDevices';

interface Props {
  userId: string;
  currentUserId: string;
  isEditMode: boolean;
  isLoading: boolean;
  error?: string;
  initialValues?: MfaLinkedDeviceModel[];
}

function TabLinkedDevicesContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const loggedUserId: string | undefined = props.currentUserId;
  const confirmDialog: ConfirmDialogModel = useStoreSelector(store => store.userLinkedDevicesFeed.confirmDialog);

  React.useEffect(() => {
    if (props.isEditMode && !props.initialValues) {
      // the parent will save this data into tabStore and and pass them down to us so we will re-render
      dispatch(actionFetchUserLinkedDevicesFeed.request(props.userId));
    }
  }, [dispatch, props.isEditMode, props.initialValues, props.userId]);

  return (
    <TabLinkedDevices //
      {...props}
      dispatch={dispatch}
      loggedUserId={loggedUserId}
      confirmDialog={confirmDialog}
    />
  );
}

export default React.memo(TabLinkedDevicesContainer);
