import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    possessionDate: {
      '& > div': {
        width: 120
      }
    }
  }),
  {
    name: 'TransactionDetails'
  }
);
