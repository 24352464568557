import * as React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FormLeaseDetails as LeaseDetailsModel } from '../../models';
import DatePeriod from '../components/date-period/DatePeriod';
import RenewDetails from '../components/renew-details/RenewDetails';

interface Props {
  name: string;
  isSubLease?: boolean;
}

const YES_NO_LOOKUP_OPTIONS: LookupEnumModel<boolean>[] = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
];

function LeaseDetails({ name, isSubLease = false }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<LeaseDetailsModel>(name);
  const { disabled } = useDocumentContext();
  const sectionTitle = isSubLease ? 'Sub lease' : 'Lease';
  return (
    <Section title={sectionTitle} data-testid={name}>
      <FormGroup title="Details" direction="row">
        <DatePeriod name={fieldName('leasePeriod')} displayLeaseLengthWarning />
      </FormGroup>

      <FormGroup title="Option to renew" direction="row">
        <RenewDetails name={fieldName('renewDetails')} />
      </FormGroup>

      <FormGroup title="Option to purchase">
        <Field //
          name={fieldName('optionToPurchase')}
          component={RadioField}
          options={YES_NO_LOOKUP_OPTIONS}
          format="boolean"
          disabled={disabled}
          aria-label="optionToPurchase"
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(LeaseDetails);
