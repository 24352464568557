import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    link() {
      return {
        padding: '6px 14px',
        margin: 0,
        fontSize: 14,
        color: theme.palette.text.primary,
        display: 'block',
        textDecoration: 'none',
        '&.active': {
          fontWeight: 'bold',
          background: theme.palette.common.white
        }
      };
    },
    sectionHeader: {
      marginTop: 20,
      marginBottom: 10,
      paddingLeft: 12
    }
  }),
  {
    name: 'PersonalProfileRoot'
  }
);
