import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  {
    text: {
      letterSpacing: 0
    },
    centerText: {
      textAlign: 'center'
    },
    text101: {
      letterSpacing: 0,
      lineHeight: '20px',
      fontSize: 13,
      color: colors.GREY_400
    }
  },
  {
    name: 'WorkspaceDetailTimestamp'
  }
);
