import React from 'react';

import { colors } from 'src/theme';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function ErrorHint({ style }: Props) {
  const styles: React.CSSProperties = {
    width: '3px',
    height: '9px',
    background: colors.RED_NEW,
    borderRadius: '2px',
    boxShadow: '0px 0px 4px 0px rgba(250, 34, 89, 0.60)',
    ...style
  };

  return <div style={styles}></div>;
}

export default React.memo(ErrorHint);
