import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDetailApiResponse } from '@sympli/api-gateway/models';

export function canInviteWorkspaceParticipant(detail?: WorkspaceDetailApiResponse) {
  return !(detail?.isLocked ?? true); // default as locked
}

export function isTaskAccessible(workspaceStatusId?: WorkspaceStatusEnum) {
  return workspaceStatusId !== WorkspaceStatusEnum.SettlementInProgress && workspaceStatusId !== WorkspaceStatusEnum.SettlementIssue;
}
