import { createAction, createAsyncAction } from 'typesafe-actions';

import { TrustAccountDetailsApiResponse, TrustAccountRequestDetailsApiResponse } from '../trust-accounts/detail/models';

export interface FetchTrustAccountListsApiResponse {
  registeredItems: TrustAccountDetailsApiResponse[];
  requestedItems: TrustAccountRequestDetailsApiResponse[];
}

export const actionFetchTrustAccountLists = createAsyncAction(
  //
  'FETCH_TRUST_ACCOUNT_LISTS',
  'FETCH_TRUST_ACCOUNT_LISTS_SUCCESS',
  'FETCH_TRUST_ACCOUNT_LISTS_ERROR'
)<undefined, { data: FetchTrustAccountListsApiResponse }, { error: Error }>();

export const actionToggleNewAccountAddedFlag = createAction(
  //
  'TOGGLE_NEW_TRUST_ACCOUNT_ADDED_FLAG'
)<boolean>();
