import dateFormat from 'dateformat';

import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { QLD_FORM24A_PARTY_FORM_CONFIG, QLD_FORM24A_POSTAL_ADDRESS_FORM_CONFIG } from './config';
import { qldForm24AFallbackMap } from './fallback';
import { ApiForm24A_2_24_1Model, Form24A_2_24_1Model } from './models';

class Converter implements IConverter<Form24A_2_24_1Model, ApiForm24A_2_24_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: QLD_FORM24A_POSTAL_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_FORM24A_PARTY_FORM_CONFIG
});

export function convertFromApiToFormModel(apiModel: ApiForm24A_2_24_1Model): Form24A_2_24_1Model {
  const { partyBook, addressBook } = apiModel;
  let formModel: Form24A_2_24_1Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(partyBook),
    addressBook: convertAddressBookFromApiToFormModel(addressBook)
  };

  formModel = applyDefaultMap(formModel, qldForm24AFallbackMap);

  return formModel;
}

export function convertFromFormToApiModel(formModel: Form24A_2_24_1Model, originalApiModel: ApiForm24A_2_24_1Model): ApiForm24A_2_24_1Model {
  formModel = applyVisibilityFallbackMap(formModel, qldForm24AFallbackMap);
  const {
    //
    partyBook: formPartyBook,
    addressBook: formAddressBook,
    transactionDetails,
    ...rest
  } = formModel;

  return {
    ...rest,
    transactionDetails: {
      ...transactionDetails,
      possessionDate:
        !transactionDetails.isPossessionDateSameAsSettlement && transactionDetails.possessionDate
          ? dateFormat(transactionDetails.possessionDate as Date, DateFormatEnum.DATE)
          : transactionDetails.possessionDate
    },
    partyBook: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel.partyBook),
    addressBook: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel.addressBook)
  };
}

export default new Converter();
