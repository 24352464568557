import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    container: {},
    root: {
      width: '18px',
      height: '18px',
      transform: 'translate(-20px, -20px) scale(0.2) translate(20px, 20px)',
      WebkitTransform: 'translate(-22px, -22px) scale(0.2) translate(20px, 20px)',
      '& div': {
        left: '94px',
        top: '64px',
        position: 'absolute',
        WebkitAnimation: '$root linear 0.8s infinite',
        animation: '$root linear 0.8s infinite',
        background: '#13c3a9',
        width: '12px',
        height: '12px',
        borderRadius: '40%',
        WebkitTransformOrigin: '6px 36px',
        transformOrigin: '6px 36px'
      },
      '& div:nth-child(1)': {
        WebkitTransform: 'rotate(0deg)',
        transform: 'rotate(0deg)',
        WebkitAnimationDelay: '-0.7s',
        animationDelay: '-0.7s'
      },
      '& div:nth-child(2)': {
        WebkitTransform: 'rotate(45deg)',
        transform: 'rotate(45deg)',
        WebkitAnimationDelay: '-0.6s',
        animationDelay: '-0.6s'
      },
      '& div:nth-child(3)': {
        WebkitTransform: 'rotate(90deg)',
        transform: 'rotate(90deg)',
        WebkitAnimationDelay: '-0.5s',
        animationDelay: '-0.5s'
      },
      '& div:nth-child(4)': {
        WebkitTransform: 'rotate(135deg)',
        transform: 'rotate(135deg)',
        WebkitAnimationDelay: '-0.4s',
        animationDelay: '-0.4s'
      },
      '& div:nth-child(5)': {
        WebkitTransform: 'rotate(180deg)',
        transform: 'rotate(180deg)',
        WebkitAnimationDelay: '-0.3s',
        animationDelay: '-0.3s'
      },
      '& div:nth-child(6)': {
        WebkitTransform: 'rotate(225deg)',
        transform: 'rotate(225deg)',
        WebkitAnimationDelay: '-0.2s',
        animationDelay: '-0.2s'
      },
      '& div:nth-child(7)': {
        WebkitTransform: 'rotate(270deg)',
        transform: 'rotate(270deg)',
        WebkitAnimationDelay: '-0.1s',
        animationDelay: '-0.1s'
      },
      '& div:nth-child(8)': {
        WebkitTransform: 'rotate(315deg)',
        transform: 'rotate(315deg)',
        WebkitAnimationDelay: '0s',
        animationDelay: '0s'
      }
    },
    '@keyframes root': {
      '0%': {
        opacity: 1
      },
      '100%': {
        opacity: 0
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
