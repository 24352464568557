import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { WeekdayEnum } from 'src/components/Weekdays/models';
import { ApiStatus } from 'src/utils/http';
import {
  DayOfMonthEnum,
  DayOfMonthEnumMapping,
  ReportDetailEnumRoutingMap,
  ReportFeatureTypeEnum,
  ReportTypeEnumDisplayMap,
  TableauParameter,
  UserReportsPermissionsApiResponse,
  WeekdayFullNameMap
} from './models';

export const getReportPermissionOptions = (
  //
  response?: UserReportsPermissionsApiResponse
): Array<LookupItemModel<ReportTypeEnum>> => {
  if (!response) {
    return [];
  }

  const reportPermissions = response.reportPermissions
    .filter(x => x.hasAccess) //
    .map(x => ({
      id: x.id,
      name: ReportTypeEnumDisplayMap[x.id]
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return reportPermissions;
};

export const resolveCombinedStatus = (status1: ApiStatus, status2: ApiStatus): ApiStatus => {
  if (status1 === 'pending' || status2 === 'pending') return 'pending';
  if (status1 === 'idle' || status2 === 'idle') return 'idle';
  if (status1 === 'rejected' || status2 === 'rejected') return 'rejected';
  return 'resolved';
};

export const resolveReportLink = (feature: ReportFeatureTypeEnum, reportType: ReportTypeEnum, reportId?: number): string => {
  const link =
    feature === ReportFeatureTypeEnum.Favourite //
      ? `/reports/${ReportDetailEnumRoutingMap[reportType]}/${reportId ?? 'new'}`
      : `/reports/scheduled/history/${reportId}`;

  return link;
};

export const resolveDailyReportGeneratedText = (reportSendTime: string, interval?: number): string => {
  // 0 or undefined should always return empty string
  if (!interval) return '';

  let prefixString = '';

  switch (interval) {
    case 1:
      prefixString = 'Daily';
      break;
    case 2:
      prefixString = 'Every 2nd day';
      break;
    case 3:
      prefixString = 'Every 3rd day';
      break;
    default:
      prefixString = `Every ${interval}th day`;
  }
  return `${prefixString} at ${reportSendTime}`;
};

export const resolveMonthlyReportGeneratedText = (reportSendTime: string, interval?: number, dayOfMonth?: DayOfMonthEnum): string => {
  /* 
    interval 0 or undefined should always return empty string
    dayOfMonth can be undefined for old data
  */
  if (!interval || dayOfMonth == null) return '';

  let prefixString = '';

  switch (interval) {
    case 1:
      prefixString = `Monthly (${DayOfMonthEnumMapping[dayOfMonth]})`;
      break;
    default:
      prefixString = `Every ${interval} months (${DayOfMonthEnumMapping[dayOfMonth]})`;
  }
  return `${prefixString} at ${reportSendTime}`;
};

export const resolveWeeklyReportGeneratedText = (reportSendTime: string, daysOfWeek?: WeekdayEnum[]): string => {
  /* 
    daysOfWeek undefined or empty array, we return empty string
  */
  if (!daysOfWeek?.length) return '';

  let prefixString = '';

  // i.e. Mon, Tue and Fri
  prefixString = daysOfWeek.reduce((prev, curr, index) => {
    switch (index) {
      case 0:
        return `${WeekdayFullNameMap[curr].substring(0, 3)}`;
      case daysOfWeek.length - 1:
        return `${prev} and ${WeekdayFullNameMap[curr].substring(0, 3)}`;
      default:
        return `${prev}, ${WeekdayFullNameMap[curr].substring(0, 3)}`;
    }
  }, '');

  return `Weekly (${prefixString}) at ${reportSendTime}`;
};

//https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#changeParameterValueAsync
export const resolveTableauParameterValue = (parameter: TableauParameter) => {
  /*
    In order to use viz.workbook().changeParameterValueAsync, tableau needs to ensure the value to change abides by their 
    mapping from ParameterDataType to native JS type, otherwise, inputted values will be ignored and no changes will be updated.
  */
  switch (parameter.type) {
    case tableau.ParameterDataType.DATE:
    case tableau.ParameterDataType.DATETIME:
      return new Date(parameter.value);
    case tableau.ParameterDataType.INTEGER:
    case tableau.ParameterDataType.FLOAT:
      return Number(parameter.value);
    default:
      return parameter.value;
  }
};
