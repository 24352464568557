import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import wizardStepperStyles, { ClassKeys as WizardStepperClassKeys } from '@sympli-mfe/document-forms-framework/styles/wizard-stepper';

// this file was automatically generated from styles.ts.mustache

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    titleReferences: {
      padding: '14px 0 0 20px'
    },
    ...wizardStepperStyles(theme)
  });

export type ClassKeys = WizardStepperClassKeys & typeof styles;

export default styles;
