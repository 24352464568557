import {
  CommonTenancyLabelsEnum,
  RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  ReceivingTenancyComponentLabelConfig
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';

import { TenancyPartyModel } from './models';

export const WA_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  header: {
    ...RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG.header,
    tenantsInCommon: CommonTenancyLabelsEnum.TenantsInCommon
  },
  footer: {
    jointTenants: CommonTenancyLabelsEnum.AddJointTenants,
    tenantsInCommon: CommonTenancyLabelsEnum.AddTenantsInCommon
  },
  partyCapacity: CommonTenancyLabelsEnum.RepresentativeCapacity,
  createNewGroup: (initialTenant?: TenancyPartyModel) => ({
    id: resolveUuid('string', 20),
    parties: [
      initialTenant ?? {
        id: resolveUuid('string', 20),
        partyBookId: '',
        partyCapacity: null,
        partyCapacityDetail: '',
        addressBookId: '',
        birthDate: null,
        isTransactingParty: true
      }
    ],
    shareQuantity: null
  })
};
