import * as React from 'react';

import classNames from 'classnames';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { LodgementCaseStatusEnum, SettleSmartStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import {
  IconFundsUnbalanced,
  IconLodgementDelayed,
  IconLodgementFailed,
  IconLodgementInProgress,
  IconLodgementPreSettlement,
  IconLodgementSuccess,
  IconReadyForLodgement
} from 'src/containers/workspace/shared/components/task-box-icons';
import MyTasks from 'src/containers/workspace/shared/detail/components/my-tasks';
import { ReadyForSettlementFailureCodeEnum, SettlementStepEnum } from 'src/containers/workspace/shared/detail/models';
import { WorkspaceTasksState } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';
import { dateTimeLine, TIME_ONLY_DISPLAY_FORMAT } from 'src/utils/formatters';
import { resolveDirectionsLink } from '../../../directions/helpers';
import { resolveSettlementDateLink } from '../../../settlement-date/helper';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  // route props
  workspaceId: string;
  participantId: string;
  // redux data
  // basic info
  isRollover?: boolean;
  workspaceStatusId?: WorkspaceStatusEnum;
  lodgementCaseStatusId?: LodgementCaseStatusEnum;
  settlementDate?: WorkspaceDateTimeModel;
  // full detail
  numberOfAllTasks: number;
  settlementStatusId?: SettleSmartStatusEnum;
  settlementFsfReference?: string;
  settlementErrors?: string[];
  lastSettlementReadyCheckReasonId?: ReadyForSettlementFailureCodeEnum;
  // latest transaction
  settlementStep?: SettlementStepEnum;

  workspaceTasksState: WorkspaceTasksState;
  // other
  isLoadingWorkspace: boolean;
  backLink: string;
  onTaskAction(action: Action): void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class TabTasks extends React.PureComponent<Props> {
  render() {
    const { workspaceStatusId, isRollover, settlementStatusId, workspaceTasksState, isLoadingWorkspace, onTaskAction } = this.props;

    // 2. render tasks first, if any

    const { items, status, error } = workspaceTasksState;

    // Show loader for task section when refetching to avoid old task list present to  customers
    // https://tickleme.atlassian.net/browse/WEB-18877
    if (isLoadingWorkspace || status === 'pending' || status === 'idle' || status === 'refetching') {
      return (
        <MyTasks
          // redux data
          // tasks
          isLoading
        />
      );
    }

    if (items.length && settlementStatusId !== SettleSmartStatusEnum.InProgress) {
      return (
        <MyTasks
          // redux data
          // tasks
          items={items}
          // we display error only if settlement is not in progress
          {...(status === 'rejected' && {
            //
            error
          })}
          workspaceStatusId={workspaceStatusId}
          isRollover={isRollover}
          // other
          onTaskAction={onTaskAction}
        />
      );
    }

    return this.renderSettlementStatus();
  }

  private renderSettlementStatus() {
    const {
      //
      lodgementCaseStatusId,
      settlementDate,
      settlementStatusId,
      settlementFsfReference,
      settlementErrors,
      settlementStep,
      isRollover,
      classes,
      backLink
    } = this.props;

    switch (settlementStatusId) {
      case SettleSmartStatusEnum.InProgress:
        //when in progress, check for PreSettlement
        switch (settlementStep) {
          case SettlementStepEnum.PreSettlement:
            return (
              <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
                <IconLodgementPreSettlement />
                <Typography className={classes.header} variant="subtitle1">
                  Pre-settlement check is in progress
                </Typography>
                <Typography className={classes.message} align="center">
                  Your workspace is currently being checked and <br />
                  will soon start settling.
                </Typography>
                <br />
                <Typography className={classes.message} align="center">
                  If your workspace is not ready to settle it will be unlocked.
                </Typography>
                <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
                  Back to dashboard
                </SympliButton>
              </FlexLayout>
            );
          default:
            return (
              <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
                <IconLodgementInProgress />
                <Typography className={classes.header} variant="subtitle1">
                  Settlement in progress
                </Typography>
                <Typography className={classes.message}>Lodging documents and transferring funds.</Typography>
                <Typography className={classes.description}>This may take a while, you can leave this page and we'll notify you of any updates.</Typography>
                <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
                  Back to dashboard
                </SympliButton>
              </FlexLayout>
            );
        }
      case SettleSmartStatusEnum.Completed:
        let subHeading = 'Settlement completed and awaiting registration';
        if (lodgementCaseStatusId === LodgementCaseStatusEnum.Registered) {
          subHeading = 'Settlement completed and document registered';
        }
        const message = `${lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementSuccess ? "We'll notify you of any updates. " : ''}Thank you for choosing Sympli.`;
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementSuccess />
            <Typography className={classes.header} variant="subtitle1">
              {subHeading}
            </Typography>
            {settlementFsfReference && <Typography className={classes.message}>Your FSF reference number is {settlementFsfReference}.</Typography>}
            <Typography className={classNames(classes.description, classes.wideWidth)}>{message}</Typography>
            <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
              Back to dashboard
            </SympliButton>
          </FlexLayout>
        );
      case SettleSmartStatusEnum.PartiallyCompleted:
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementFailed />
            <Typography className={classes.header} variant="subtitle1">
              We are experiencing technical issues
            </Typography>
            <Typography className={classes.message}>Please call 1300 SYMPLI (1300 796 754) for assistance.</Typography>
          </FlexLayout>
        );
      case SettleSmartStatusEnum.Delayed:
        return isRollover ? (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementDelayed />
            <Typography className={classes.header} variant="subtitle1">
              Settlement rolled
            </Typography>
            {settlementDate && (
              <Typography className={classes.message}>Settlement will now begin at {dateTimeLine(settlementDate.workspaceLocalTime, TIME_ONLY_DISPLAY_FORMAT)}</Typography>
            )}
            {!!settlementErrors?.length && (
              <>
                <Typography className={classNames(classes.description, classes.wideWidth)}>Your workspace could not settle at the scheduled time due to:</Typography>
                <ul className={classNames(classes.descriptionList)}>
                  {settlementErrors.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </>
            )}
            <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
              Back to dashboard
            </SympliButton>
          </FlexLayout>
        ) : (
          this.renderWorkspaceStatus()
        );
      case SettleSmartStatusEnum.Fail:
      default:
        return this.renderWorkspaceStatus();
    }
  }

  private renderWorkspaceStatus() {
    const { classes, backLink, workspaceStatusId, settlementDate } = this.props;

    // TODO: talk to product team how to handle finalised and success statuses

    if (workspaceStatusId === WorkspaceStatusEnum.ReadyForSettlement) {
      return (
        <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
          <IconReadyForLodgement />
          <Typography className={classes.header} variant="subtitle1">
            {settlementDate?.daysTill === 0 ? 'Settlement day' : 'All done!'}
          </Typography>
          <Typography className={classes.message}>Your workspace is ready for settlement.</Typography>
          <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
            Back to dashboard
          </SympliButton>
        </FlexLayout>
      );
    }

    switch (workspaceStatusId) {
      case WorkspaceStatusEnum.Success:
        // dealingNumber = this.resolveDealingNumber();
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementSuccess />
            <Typography className={classes.header} variant="subtitle1">
              Documents registered
            </Typography>
            <Typography className={classNames(classes.description, classes.wideWidth)}>
              Your documents are registered with the land registry.
              <br />
              Your payment has been received, thank you for choosing Sympli.
            </Typography>
            <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
              Back to dashboard
            </SympliButton>
          </FlexLayout>
        );
      case WorkspaceStatusEnum.Failed: {
        const { workspaceId, participantId } = this.props;
        const link = resolveSettlementDateLink({ workspaceId, participantId });
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementFailed />
            <Typography className={classes.header} variant="subtitle1">
              We've found issues during settlement
            </Typography>
            <Typography className={classNames(classes.description, classes.wideWidth)}>
              To unlock this workspace and resolve issues, <br />
              please rebook settlement now.
            </Typography>
            <SympliButton href={link} variant="outlined" color="primary" className={classes.actionButton} arrowRight>
              Rebook settlement
            </SympliButton>
          </FlexLayout>
        );
      }
      case WorkspaceStatusEnum.SettlementIssue:
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementFailed />
            <Typography className={classes.header} variant="subtitle1">
              We've found issues during settlement
            </Typography>
            <Typography className={classNames(classes.description, classes.wideWidth)}>Settlement has been partially completed. We will be in contact soon.</Typography>
          </FlexLayout>
        );
      // * may need more specific status box for 'Zero task' situation
      default:
        return this.renderNoTasks();
    }
  }

  private renderNoTasks() {
    const { lastSettlementReadyCheckReasonId, workspaceTasksState, numberOfAllTasks, classes, backLink } = this.props;

    // TODO optimize this and use lastWorkspaceSettlementReadyCheck from workspace basic reducer
    if (lastSettlementReadyCheckReasonId === ReadyForSettlementFailureCodeEnum.FinancialBalanceCheckFailed) {
      const { workspaceId, participantId } = this.props;
      const link = resolveDirectionsLink({ workspaceId, participantId });
      return (
        <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
          <IconFundsUnbalanced />
          <Typography className={classes.header} variant="subtitle1">
            Funds not balanced
          </Typography>
          <Typography className={classNames(classes.description, classes.wideWidth)}>
            This workspace is not ready for settlement as the funds are not balanced. <br />
            Please review your source funds and financials.
          </Typography>
          <FlexLayout flexDirection="row" alignItems="center">
            <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
              Back to dashboard
            </SympliButton>
            <SympliButton href={link} variant="contained" color="primary" className={classes.actionButton} arrowRight>
              See financials
            </SympliButton>
          </FlexLayout>
        </FlexLayout>
      );
    }

    // display no tasks message
    return (
      <MyTasks
        // redux data
        // tasks
        // other
        backLink={backLink}
        {...(numberOfAllTasks > workspaceTasksState.items.length && {
          noTaskMessage: 'Another participant/user has to complete some tasks but your work is finished.'
        })}
      />
    );
  }
}

export default withStyles(styles)(TabTasks);
