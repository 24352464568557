import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { NSW_LEASE_PARTY_FORM_CONFIG } from '../../config';

const yupLessees = resolveMemoizedTenancyValidation({
  //
  isAddressRequired: false,
  partyFormConfig: NSW_LEASE_PARTY_FORM_CONFIG
});

export default yupLessees;
