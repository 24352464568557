import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { fallbackMap } from '../../../../shared/2-24/extension-priority-notice/fallback';
import { ApiExtensionOfPriorityNoticeModel, ExtensionOfPriorityNoticeModel } from '../../../../shared/2-24/extension-priority-notice/models';
import { QLD_EPN_PARTY_FORM_CONFIG } from './config';

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_EPN_PARTY_FORM_CONFIG
});

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<ExtensionOfPriorityNoticeModel, ApiExtensionOfPriorityNoticeModel> {
  fromApiToFormModel(apiModel: ApiExtensionOfPriorityNoticeModel, _context: IConverterContext): ExtensionOfPriorityNoticeModel {
    // Implement any necessary conversion from API to form values
    let formModel: ExtensionOfPriorityNoticeModel = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: ExtensionOfPriorityNoticeModel, _originalApiModel: ApiExtensionOfPriorityNoticeModel): ApiExtensionOfPriorityNoticeModel {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook,
      ...rest
    } = formModel;
    const { partyBook: apiPartyBook } = _originalApiModel;
    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBook, apiPartyBook)
    };
  }
}

export default new Converter();
