import { QldNameChange, QldPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/qld/2-20/components/party-justification';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-20/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const QLD_WOC_PARTY_FORM_CONFIG: PartyFormConfig<QldNameChange> = {
  ...QLD_PARTY_FORM_CONFIG,
  nameChangeConfig: QldPartyNameChangeConfig
};
