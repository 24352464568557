import { QldDocumentPartyJustification, QldNameChange, QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { RootFormContextProvider } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import RootForm, { Context } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/RootForm';
import {
  QLD_TRANSMISSION_ADDRESS_FORM_CONFIG,
  QLD_TRANSMISSION_ADDRESS_FORM_INITIAL_VALUES,
  QLD_TRANSMISSION_DECEASED_TENANCY_CONTEXT_CONFIG,
  QLD_TRANSMISSION_PARTY_FORM_CONFIG,
  QLD_TRANSMISSION_RECEIVING_TENANCY_LABEL_CONFIG
} from './config';
import converter from './conversion';
import { TRANSMISSION_PARTY_CAPACITY_LOOKUP_OPTIONS } from './enums';
import { ApiTransmissionApplication2_24_0_Model, TransmissionApplication2_24_0_Model } from './models';
import validationSchema from './validationSchema';

export default function DocumentForm(props: DocumentFormProps<TransmissionApplication2_24_0_Model, ApiTransmissionApplication2_24_0_Model>): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<TransmissionApplication2_24_0_Model>(validationSchema, validateDebounce);
  return (
    <RootFormContextProvider<Context<QldDocumentPartyJustification, QldNameChange>>
      partyFormConfig={QLD_TRANSMISSION_PARTY_FORM_CONFIG}
      nameChangeConversion={QldNameChangeConversion}
      partLandAffectedType="description"
      sharedAddressFormConfig={QLD_TRANSMISSION_ADDRESS_FORM_CONFIG}
      componentLabels={QLD_TRANSMISSION_RECEIVING_TENANCY_LABEL_CONFIG}
      deceasedTenancyConfig={QLD_TRANSMISSION_DECEASED_TENANCY_CONTEXT_CONFIG}
      partyCapacityLookup={TRANSMISSION_PARTY_CAPACITY_LOOKUP_OPTIONS}
      sharedInitialValuesForNew={QLD_TRANSMISSION_ADDRESS_FORM_INITIAL_VALUES}
    >
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
        validateOnMount
      />
    </RootFormContextProvider>
  );
}
