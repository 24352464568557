import * as React from 'react';
import { PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import Markdown, { MarkdownToJSX } from '@sympli/markdown-to-jsx';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { dateTimeLine, TIME_ONLY_DISPLAY_FORMAT } from 'src/utils/formatters';
import { resolveLogLink } from '../../helper';
import { WorkspaceActivityLogModel } from '../../models';
import ActivityLogItemIcon from '../activity-log-item-icon';
import ActivityLogItemParticipant from '../activity-log-item-participant';
import { useStyles } from './styles';

interface Props {
  workspaceId: string;
  participantId: string;
  item: WorkspaceActivityLogModel;
  isLinkDisabled?: boolean;
  dataTestId?: string;
  onAccessDenied?(): void;
}

function ActivityLogItem({ item, children, workspaceId, participantId, isLinkDisabled, onAccessDenied, dataTestId }: PropsWithChildren<Props>) {
  const classes = useStyles();
  const logTime = dateTimeLine(item.dateTime, TIME_ONLY_DISPLAY_FORMAT);

  const handleOnClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onAccessDenied?.();
    },
    [onAccessDenied]
  );

  const markDownOption = React.useMemo((): MarkdownToJSX.Options => {
    return {
      disableParsingRawHTML: true,
      overrides: {
        a: {
          component: props => {
            const { href, children, ...rest } = props;
            const to = resolveLogLink(workspaceId, participantId, href, item.dataObject);
            if (isLinkDisabled) {
              return (
                <Link to={to} {...rest} onClick={handleOnClick}>
                  {children}
                </Link>
              );
            }
            return (
              <Link to={to} {...rest}>
                {children}
              </Link>
            );
          },
          props: { className: classes.linkLabel }
        }
      }
    };
  }, [workspaceId, participantId, item.dataObject, classes.linkLabel, isLinkDisabled, handleOnClick]);

  return (
    <FlexLayout alignItems="center" className={classes.lineItem} data-testid={dataTestId}>
      <Typography className={classes.timeLabel}>{logTime}</Typography>
      <FlexLayout alignItems="flex-start" justifyContent="flex-start" className={classes.messageContainer}>
        <ActivityLogItemIcon type={item.workspaceLogType} dataObject={item.dataObject} />
        <Typography className={classes.message}>{children ?? <Markdown options={markDownOption}>{item.template}</Markdown>}</Typography>
      </FlexLayout>
      <ActivityLogItemParticipant item={item} />
    </FlexLayout>
  );
}

export default React.memo(ActivityLogItem);
