import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import Logger, { PageActionEnum } from '@sympli/ui-logger';

// WEB-17390
const loggerMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
  // report each redux action
  Logger.capturePageAction(PageActionEnum.ReduxAction, {
    // explicitly send only action type and no payload
    type: action.type
  });
  return next(action);
};

export default loggerMiddleware;
