import { DocumentTypeIdentifierEnum, JurisdictionsEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { DuplicateWorkspaceItem } from './components/duplicate-workspaces';

export interface VerifyTitleFormModel {
  existingTitles?: string[];
  jurisdictionId: JurisdictionsEnum | null; // populated from its parent context
  titleInputValue: string; // This is temporary input field value in the form for Chips
  titleChips: string[];

  // results from additional svc calls that we need for validation to be handled purely within yup
  //
  // this will be undefined if there are no titles to be verified
  titleVerificationResult?: TitleVerificationResponse;
}

export interface TitleVerificationRequest {
  references: string[];
  jurisdictionId: JurisdictionsEnum;
  workspaceType: WorkspaceTypeEnum;
  documentTypeIds?: DocumentTypeIdentifierEnum[]; // financial workspace does not need this
  checkDuplicateWorkspaces?: boolean;
  checkTitleReferences?: boolean;
  workspaceId?: string; // used by add title, workspace creation does not need this
}

export interface TitleVerificationResponse {
  titles: VerifiedTitleResponse[];
  duplicateWorkspaces?: DuplicateWorkspaceItem[];
  hasPaperTitle: boolean;
}

export enum VerifyPropertyContextEnum {
  AddTitles = 'add-titles',
  WorkspaceCreation = 'workspace-creation'
}

export enum TitleVerificationStatusEnum {
  Success = 'Success', // can proceed without any further clarification from the user
  WarningPaperTitle = 'WarningPaperTitle',
  ErrorControllerNotRegistered = 'ErrorControllerNotRegistered',
  ErrorInconsistentControllers = 'ErrorInconsistentControllers',
  ErrorNonElectronicLodgement = 'ErrorNonElectronicLodgement',
  TitleAlreadyAdded = 'TitleAlreadyAdded',
  WarningControllerDoesNotExistInWorkspace = 'WarningControllerDoesNotExistInWorkspace',
  ErrorNoEligiblePriorityNotice = 'ErrorNoEligiblePriorityNotice',
  ErrorRegistrarOfTitles = 'ErrorRegistrarOfTitles',
  ErrorControllerNotRegistrarOfTitles = 'ErrorControllerNotRegistrarOfTitles',
  ErrorControllerNotCurrentSubscriber = 'ErrorControllerNotCurrentSubscriber',
  ErrorPaperTitle = 'ErrorPaperTitle'
}

export const titleVerificationErrorStatuses: Set<TitleVerificationStatusEnum> = new Set([
  TitleVerificationStatusEnum.ErrorControllerNotRegistered,
  TitleVerificationStatusEnum.ErrorInconsistentControllers,
  TitleVerificationStatusEnum.ErrorNonElectronicLodgement,
  TitleVerificationStatusEnum.ErrorNoEligiblePriorityNotice,
  TitleVerificationStatusEnum.ErrorRegistrarOfTitles,
  TitleVerificationStatusEnum.ErrorControllerNotRegistrarOfTitles,
  TitleVerificationStatusEnum.ErrorControllerNotCurrentSubscriber,
  TitleVerificationStatusEnum.ErrorPaperTitle
]);

export interface VerifiedTitleResponse {
  isElectronicLodgementEligible: boolean;
  isPaperLodgementEligible: boolean;
  ineligibleReason: string;
  titleReference: string;
  address: {
    addressLine1: string;
    addressLine2: string;
  };
  controller?: {
    subscriberId?: string; // will be present only if controller is registered with Sympli
    isControlledByCurrentSubscriber: boolean;
    name: string;
    abn: string;
    elnoId?: string;
  };
  status: TitleVerificationStatusEnum;
}
