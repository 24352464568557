import * as React from 'react';

import { useFormikContext } from 'formik';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';

import { ADDRESS_BOOK_KEY, Form24A_2_24_1Model, TransfereeReferenceModel } from '../../models';
import { Context } from '../../RootForm';
import { ForeignPersonOrCorporation } from './ForeignPersonOrCorporation';
import { useStyles } from './styles';

type FormValues = Form24A_2_24_1Model;

interface Props {
  name: string;
  transferee: TransfereeReferenceModel;
}

function Transferee({ name, transferee }: Props) {
  const { values } = useFormikContext<FormValues>();
  const context = useRootFormContext<Context>();
  const { disabled, dialogPortalId } = useDocumentContext();
  const classes = useStyles();

  const fieldName = createModelKeyAppender<TransfereeReferenceModel>(name);
  const partySourceFieldName = createModelKeyAppender<ApiPartyReferenceModel>(fieldName('transferee'));

  const party = values.partyBook.find(pb => pb.id === transferee.transferee.partyBookId)!;
  const displayName = party.organisationName;

  return (
    <FormGroup title="Organisation" fieldAreaDirection="column">
      <ReadOnlyField //
        label="Name"
        value={displayName}
      />
      <div className={classes.acnGroup}>
        {!!party.acn && (
          <ReadOnlyField //
            label="ACN"
            value={party.acn}
          />
        )}
        {!!party.abn && (
          <ReadOnlyField //
            label="ABN"
            value={party.abn}
          />
        )}
        {!!party.acln && (
          <ReadOnlyField //
            label="ACLN"
            value={party.acln}
          />
        )}
        {!!party.arbn && (
          <ReadOnlyField //
            label="ARBN"
            value={party.arbn}
          />
        )}
      </div>

      <AddressSelectField
        name={partySourceFieldName('addressBookId')}
        disabled={disabled}
        bookRef={ADDRESS_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        sharedAddressFormConfig={context.addressFormConfig}
        sharedInitialValuesForNew={context.addressFormInitialValues}
        label="Business address after possession"
      />

      <ForeignPersonOrCorporation name={fieldName('isForeignPersonOrCorp')} />
    </FormGroup>
  );
}

export default React.memo(Transferee);
