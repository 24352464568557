import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DocumentListAction, DocumentListModel } from '../../models';

export interface Props {
  document: DocumentListModel;
  dataTestId?: string;
  variant?: DocumentListAction; // only 'delete' and 'reorder' will use this component
}

interface Styles {
  iconBackgroundColor: string;
  linearBackground: string;
}

const getStyles = (variant?: DocumentListAction): Styles => {
  if (variant === 'reorder') {
    return {
      iconBackgroundColor: 'bg-[var(--sunny-day)] hover:bg-[var(--sunny-day-bright)]',
      linearBackground: 'linear-gradient(90deg, rgba(214, 171, 0, 0.00) 0%, #D6AB00 100%)'
    };
  }

  return {
    iconBackgroundColor: 'bg-[var(--watermelon)] hover:bg-[var(--watermelon-bright)]',
    linearBackground: 'linear-gradient(90deg, rgba(219, 61, 75, 0.00) 0%, #DB3D4B 100%)'
  };
};

function DocumentItemWithAction({ document, dataTestId, variant }: Props) {
  const { name, icon, description } = document;
  const styles = getStyles(variant);

  return (
    <FlexLayout className="min-h-[42px] px-[14px] py-[4px] mb-[8px]" data-testid={`${dataTestId}-text`}>
      <FlexLayout className={classNames('relative w-[56px] min-w-[56px] rounded-l-[6px]', icon && styles.iconBackgroundColor)} justifyContent="center" alignItems="center">
        {icon}
        {icon && <div className="w-[4px] h-full absolute right-[0px]" style={{ background: styles.linearBackground }}></div>}
      </FlexLayout>
      <FlexLayout className="bg-[var(--neutral-025)] grow px-[8px] py-[4px]" flexDirection="column" justifyContent="center">
        <Typography variant="body2_bold">{name}</Typography>
        <Typography variant="body4" className="*:text-[var(--neutral-600)]">
          {description}
        </Typography>
      </FlexLayout>
    </FlexLayout>
  );
}

export default DocumentItemWithAction;
