import React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch } from 'src/hooks';
import SourceFundList, { FormModel, OwnProps as Props } from './SourceFundList';

function SourceFundListContainer(props: Omit<Props<FormModel>, 'dispatch'>) {
  const dispatch = useSafeDispatch(useDispatch());
  return (
    <SourceFundList //
      {...props}
      dispatch={dispatch}
    />
  );
}

export default React.memo(SourceFundListContainer);
