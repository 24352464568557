import { subMonths } from 'date-fns';

import { QLD_ADDRESS_FORM_CONFIG, QLD_ADDRESS_FORM_INITIAL_VALUES, QLD_PHYSICAL_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/qld/2-24/config';
import { QldNameChange, QldPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import {
  AddressFieldFormConfig,
  AddressFormValueModel,
  InitialValuesAddressEntityModel,
  PhysicalAddressFormConfig
} from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import { resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { NECDS_EVIDENCE_JURISDICTION_LOOKUP_OPTIONS, PartyTypeEnum, UnitTypeEnum } from '@sympli-mfe/enums-2-24/qld';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';

import {
  DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS,
  DateOfDeathTypeEnum
} from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/components/date-of-death/enums';
import { DeceasedTenancyDetailValidationConfiguration } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/config';
import { DeceasedTenancyConfig, PartyCapacityLookup } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/deceasedTenancyConfig';
import { DeceasedTenancyPartyCapacityEnum } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/enums';
import { getLegalEntityNameQLD } from '../../helpers';
import { TRANSMISSION_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS, TRANSMISSION_NAME_SUFFIX_LOOKUP_OPTIONS } from './enums';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file
export const QLD_TRANSMISSION_PARTY_FORM_CONFIG: PartyFormConfig<QldNameChange> = {
  ...QLD_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...QLD_PARTY_FORM_CONFIG.individualPartyFormConfig,
    nameSuffixLookupEnum: TRANSMISSION_NAME_SUFFIX_LOOKUP_OPTIONS
  },
  nameChangeConfig: { ...QldPartyNameChangeConfig }
};

export const QLD_TRANSMISSION_PARTY_FORM_CONFIG_WITH_NAME_CHANGE: PartyFormConfig<QldNameChange> = {
  ...QLD_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...QldPartyNameChangeConfig, formatNewName }
};

export const QLD_TRANSMISSION_RECEIVING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = {
  partyRole: 'Applicant',
  footer: { jointTenants: 'Add Applicant', tenantsInCommon: '' },
  header: {
    jointTenants: 'Applicant',
    tenantsInCommon: ''
  },
  items: {
    jointTenants: 'Applicant',
    tenantsInCommon: ''
  },
  partyCapacity: 'Party Capacity'
};

function formatNewName(partyType: PartyTypeEnum, nameChangeModel: QldNameChange): string {
  if (partyType === PartyTypeEnum.Individual) {
    const { firstName, lastName } = nameChangeModel;
    return [firstName, lastName].filter(Boolean).join(' ');
  } else if (partyType === PartyTypeEnum.Organisation) {
    const { organisationName } = nameChangeModel;
    return organisationName;
  } else {
    throw new Error('Unsupported party type.');
  }
}

export const QLD_TRANSMISSION_PARTY_CAPACITY_LOOKUPS: PartyCapacityLookup[] = [DeceasedTenancyPartyCapacityEnum.PersonalRepresentative].map(
  (capacity: DeceasedTenancyPartyCapacityEnum) => {
    return {
      capacity,
      evidenceTypes: TRANSMISSION_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS
    };
  }
);

export const QLD_TRANSMISSION_DECEASED_TENANCY_CONTEXT_CONFIG: DeceasedTenancyConfig = new DeceasedTenancyConfig(
  QLD_TRANSMISSION_PARTY_CAPACITY_LOOKUPS,
  getLegalEntityNameQLD,
  false,
  true,
  false,
  1,
  restrictedLookupOptions(DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS, { whitelist: [DateOfDeathTypeEnum.ActualDate, DateOfDeathTypeEnum.DateRange, DateOfDeathTypeEnum.ApproximateDate] }),
  true,
  NECDS_EVIDENCE_JURISDICTION_LOOKUP_OPTIONS,
  true,
  true
);

const QLD_TRANSMISSION_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG,
  unitType: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitType,
    hidden: true
  },
  unitNumber: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitNumber,
    placeholder: resolvePlaceholder(false)
  }
};

export const QLD_TRANSMISSION_ADDRESS_FORM_INITIAL_VALUES: AddressFormValueModel<InitialValuesAddressEntityModel> = {
  ...QLD_ADDRESS_FORM_INITIAL_VALUES,
  addressDetail: {
    ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail,
    physicalAddress: {
      ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail.physicalAddress!,
      unitType: UnitTypeEnum.UNIT
    }
  }
};

export const QLD_TRANSMISSION_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG,
  physicalAddressFormConfig: QLD_TRANSMISSION_PHYSICAL_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false,
  overseasAddressFormConfig: false
};

export const QLD_TRANSMISSION_DECEASED_TENANCY_VALIDATION_CONFIGURATION: DeceasedTenancyDetailValidationConfiguration = {
  minDeathOfDateRange: new Date('1982-01-01T00:00:00.000'),
  allowTodayDeathOfDate: true,
  minEvidenceDate: subMonths(Date.now(), 2),
  minEvidenceDateError: 'Must not be more than 2 calendar months prior to today.',
  disableProprietorPartyCapacity: true
};
