import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    headerContainer: {
      height: 50
    },
    headerLabel: {
      fontSize: 16,
      color: theme.palette.common.white
    },
    subheaderLabel: {
      paddingLeft: 16,
      marginBottom: 8,
      fontSize: 12,
      lineHeight: '20px',
      background: theme.palette.grey[100]
    },
    backButton: {
      top: 5,
      left: 0,
      position: 'absolute',
      height: '40px',
      width: '40px'
    },
    backIcon: {
      fill: theme.palette.primary.main
    },

    // Select participant
    participant: {
      paddingLeft: 15,
      paddingRight: 10
    },
    participantName: {
      fontSize: '13px',
      lineHeight: '15px',
      fontWeight: 'bold'
    },
    participantRole: {
      fontSize: '13px',
      lineHeight: '15px',
      color: theme.palette.text.secondary
    },
    participantAvatar: {
      marginLeft: 'auto'
    },
    additionalDesc: {
      padding: 15,
      marginBottom: 'auto',
      fontSize: 12,
      lineHeight: '14px',
      color: theme.palette.text.secondary
    },

    // Conversation detail form
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: theme.palette.action.disabledBackground
    },
    selectedParticipant: {
      alignItems: 'center',
      height: 32,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      marginBottom: 4,
      padding: '0px 8px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      background: theme.palette.background.default
    },
    subjectBox: {
      padding: '0px 15px 10px',
      background: '#23757A'
    },
    subjectField: {
      width: '100%',
      marginBottom: 0
    },
    subjectMultiline: {
      maxHeight: 50,
      overflowY: 'hidden'
    },
    multiline: {
      maxHeight: 68,
      overflowY: 'auto'
    },
    multilineInput: {
      overflowY: 'hidden'
    },
    submitButton: {
      minWidth: 0,
      marginLeft: 8,
      paddingLeft: 10,
      paddingRight: 10
    },
    submitIcon: {
      fill: theme.palette.common.white
    },
    loader: {
      marginLeft: -2,
      marginRight: -2
    },
    helperTextError: {
      marginTop: 0,
      paddingTop: 2,
      paddingLeft: 10,
      top: '95%',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      width: 280,
      lineHeight: 1.5,
      color: `${theme.palette.common.white} !important`,
      backgroundColor: theme.palette.error.main
    },
    marginBottom: {
      marginBottom: 20
    },
    privacyLink: {
      fontWeight: 'bold'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
