import * as React from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import MuiBox from '@mui/material/Box';

import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { ColumnsModel, SortDirectionEnum, TableV2 as Table } from '@sympli/ui-framework/components/table';
import { IconCheckboxTick } from '@sympli/ui-framework/icons';
import { Exclusive } from '@sympli/ui-framework/utils/type';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { ApiStatus } from 'src/utils/http';
import DidNotLoad from '../../components/svg/DidNotLoad';
import NoResults from '../../components/svg/NoResults';
import { actionResendInvite, actionWithdrawParticipantInvitation } from '../actions';
import { InvitationsDashboardRouteTabsEnumV2, WithdrawParticipantInvitationRequestModel } from '../models';
import { useInvitationsFeedV2 } from '../reducers/invitationsFeedV2';
import WithdrawDialogContainer from '../withdraw-dialog';
import { resolveColumns } from './helpers';
import { InvitationsTableContext, InvitationsTableRowModel } from './models';
import { useInvitationsTableStyles, useTableCellBodyCheckboxStyles, useTableCellHeadCheckboxStyles } from './styles';

const InvitationsTable = ({
  //
  invitationGroup,
  assignmentType,
  onPaginationInfoChange,
  onPageChange,
  onSortChange,
  pageNumber,
  pageSize,
  rows,
  apiStatus,
  totalCount,
  sortBy,
  sortOrder,
  onRowClick,
  selectedRowIndexes,
  onSelectionChange,
  rowDetailNode,
  rowDetailIndex,
  onRowToggle,
  hasManageInvitationPermission
}: {
  invitationGroup: InvitationsDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
  onPaginationInfoChange(paginationInfo: React.ReactNode): void;
  onPageChange(args: { pageNumber: number }): void;
  onSortChange?(args: {
    //
    sortBy: string;
    sortOrder: DashboardSortDirectionEnum;
  }): void;

  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: DashboardSortDirectionEnum;
  rows: InvitationsTableRowModel[];
  apiStatus?: ApiStatus;
  totalCount: number;
  // row action
  onRowClick?(rowIndex: number, rowData: InvitationsTableRowModel): void;

  rowDetailNode: React.ReactNode;
  rowDetailIndex?: number;
  onRowToggle(rowIndex: number): void;
  // selection mode
  selectedRowIndexes: number[];
  onSelectionChange: (selectedRowIndexes: number[]) => void;
  hasManageInvitationPermission: boolean;
}) => {
  //TODO make a container to handle all these not pure stuff.
  const isForwardInvitationToSubscriberEnabled = useFeatureFlag(FeatureToggleEnum.forwardInvitationToSubscriberEnabled);
  const dispatch = useSafeDispatch(useDispatch());
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { query } = useInvitationsFeedV2();
  const [currentRowInfo, setCurrentRowInfo] = React.useState<WithdrawParticipantInvitationRequestModel>({ workspaceId: '', participantId: '', isLinkedWorkspace: false });

  const tableContext = React.useMemo<InvitationsTableContext>(() => ({ screenVariant, selectedTab: invitationGroup }), [screenVariant, invitationGroup]);

  const onWithdrawnDialogClose = React.useCallback(
    (confirmed?: boolean) => {
      if (!confirmed) {
        setIsOpen(false);
        return;
      }

      if (!hasManageInvitationPermission) {
        dispatch(
          actionCreateGlobalErrorMessage({
            title: 'User Permission Required',
            message: 'You do not have permission to send, manage or withdraw invitations. Please contact your admin for more information on managing workspaces.'
          })
        );
        setIsOpen(false);
        return;
      }

      dispatch(
        actionWithdrawParticipantInvitation.request({
          //
          workspaceId: currentRowInfo.workspaceId,
          participantId: currentRowInfo.participantId,
          participantName: currentRowInfo.participantName,
          isNewDashboard: true,
          isLinkedWorkspace: currentRowInfo.isLinkedWorkspace,
          query
        })
      );
    },
    [currentRowInfo.participantId, currentRowInfo.participantName, currentRowInfo.workspaceId, currentRowInfo.isLinkedWorkspace, dispatch, query, hasManageInvitationPermission]
  );

  const columns: ColumnsModel<InvitationsTableRowModel> = React.useMemo(
    () =>
      resolveColumns({
        //
        screenVariant,
        invitationGroup,
        assignmentType
      }),
    [screenVariant, invitationGroup, assignmentType]
  );
  const classes = useInvitationsTableStyles({ invitationGroup, screenVariant, assignmentType });
  const isLoading = apiStatus === 'idle' || apiStatus === 'pending';
  const noDataMessage = apiStatus === 'rejected' ? <DidNotLoad /> : <NoResults />;

  const handleOnPageChange = React.useCallback(
    (pageNumber: number) => {
      onPageChange({ pageNumber });
    },
    [onPageChange]
  );

  const handleOnSortChange = React.useCallback(
    (sortBy: string, sortDirection: SortDirectionEnum) => {
      onSortChange?.({
        sortBy,
        sortOrder: sortDirection === SortDirectionEnum.asc ? DashboardSortDirectionEnum.asc : DashboardSortDirectionEnum.desc
      });
    },
    [onSortChange]
  );

  const tableCellHeadCheckboxClasses = useTableCellHeadCheckboxStyles();
  const tableCellBodyCheckboxClasses = useTableCellBodyCheckboxStyles();

  const additionalParams: Exclusive<
    {
      rowHoverActionNode: React.ReactNode;
      rowHideActionNode?: React.ReactNode;
      rowHoverActionColumnStartIndex: number;
      rowHoverActionColumnEndIndex?: number;
    } & Exclusive<
      {
        onRowToggle(rowIndex: number, rowData: InvitationsTableRowModel): void;
        rowDetailNode: React.ReactNode;
        rowDetailIndex?: number;
      },
      {}
    >,
    {}
  > =
    invitationGroup === InvitationsDashboardRouteTabsEnumV2.sent
      ? {
          rowHoverActionNode: (rowIndex: number, row: InvitationsTableRowModel) => {
            return (
              <div className="flex justify-evenly">
                <SympliButton
                  href={
                    row.heroWorkspace
                      ? resolveWorkspaceDetailLink({
                          workspaceId: row.heroWorkspace.workspaceId,
                          participantId: row.heroWorkspace.participantId
                        })
                      : resolveWorkspaceDetailLink({
                          workspaceId: row.workspaceId,
                          participantId: row.invitedByParticipantId
                        })
                  }
                  hasRadius
                  variant="contained"
                  className={classNames(tableCellBodyCheckboxClasses.button, tableCellBodyCheckboxClasses.buttonPrimary)}
                >
                  View workspace
                </SympliButton>
                <SympliButton
                  hasRadius
                  variant="contained"
                  className={classNames(tableCellBodyCheckboxClasses.button, tableCellBodyCheckboxClasses.buttonSecondary)}
                  onClick={() => {
                    setIsOpen(true);
                    setCurrentRowInfo({
                      workspaceId: row.workspaceId,
                      participantId: row.participantId,
                      participantName: row.toSubscriberName,
                      isLinkedWorkspace: row.heroWorkspace ? true : false
                    });
                  }}
                >
                  Withdraw
                </SympliButton>
                <SympliButton
                  hasRadius
                  variant="contained"
                  className={classNames(tableCellBodyCheckboxClasses.button, tableCellBodyCheckboxClasses.buttonPrimary)}
                  onClick={() =>
                    row.canResend
                      ? !hasManageInvitationPermission
                        ? dispatch(
                            actionCreateGlobalErrorMessage({
                              title: 'User Permission Required',
                              message: 'You do not have permission to send, manage or withdraw invitations. Please contact your admin for more information on managing workspaces.'
                            })
                          )
                        : dispatch(
                            actionResendInvite.request({
                              id: row.participantId,
                              participantName: row.toSubscriberName,
                              isNewDashboard: true,
                              query
                            })
                          )
                      : dispatch(
                          actionOpenGlobalSimpleNotification({
                            //
                            message: (
                              <>
                                <b>Invitation not resent.</b> Invitations can only be resent once in a 24 hour period
                              </>
                            ),
                            autoHideDuration: 2000,
                            variant: 'new-warning'
                          })
                        )
                  }
                >
                  Resend
                </SympliButton>
              </div>
            );
          },
          rowHoverActionColumnStartIndex: assignmentType === AssignmentTypeEnum.AssignedToGroup ? -3 : -2,
          rowHoverActionColumnEndIndex: -1
        }
      : invitationGroup === InvitationsDashboardRouteTabsEnumV2.archived && isForwardInvitationToSubscriberEnabled
        ? {
            rowHoverActionNode: 'Invitation Details',
            rowHideActionNode: 'Hide Invitation Details',
            rowHoverActionColumnStartIndex: assignmentType === AssignmentTypeEnum.AssignedToGroup ? -3 : -2,
            rowHoverActionColumnEndIndex: -1,
            onRowToggle,
            rowDetailNode,
            rowDetailIndex
          }
        : {};

  return (
    <>
      <Table //
        className={classNames(classes.root, assignmentType === AssignmentTypeEnum.ReAssigned && 'mb-[144px]')}
        context={tableContext}
        sortBy={sortBy}
        sortDirection={sortOrder === DashboardSortDirectionEnum.asc ? SortDirectionEnum.asc : SortDirectionEnum.desc}
        onSortChange={onSortChange ? handleOnSortChange : undefined}
        onPaginationInfoChange={onPaginationInfoChange}
        onPageChange={handleOnPageChange}
        noDataMessage={noDataMessage}
        totalCount={totalCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
        selectionMode={assignmentType === AssignmentTypeEnum.ReAssigned ? 'all' : 'none'}
        selectedRowIndexes={assignmentType === AssignmentTypeEnum.ReAssigned ? selectedRowIndexes : undefined}
        onSelectionChange={assignmentType === AssignmentTypeEnum.ReAssigned ? onSelectionChange : undefined}
        classesTableHead={tableCellHeadCheckboxClasses}
        tableHeadCheckboxIcon={<MuiBox sx={{ width: 24, height: 24, border: '1px solid #333' }} />}
        tableHeadCheckboxCheckedIcon={
          <MuiBox sx={{ width: 24, height: 24, border: '1px solid #333', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IconCheckboxTick width={20} height={20} />
          </MuiBox>
        }
        tableBodyCheckboxIcon={<MuiBox sx={{ width: 24, height: 24, border: '1px solid #D0D0D0' }} />}
        tableBodyCheckboxCheckedIcon={
          <MuiBox sx={{ width: 24, height: 24, border: '1px solid #D0D0D0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IconCheckboxTick width={20} height={20} />
          </MuiBox>
        }
        classesTableBody={tableCellBodyCheckboxClasses}
        disableHeadCheckboxIndeterminate
        recordType="Invitation"
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        {...(invitationGroup === InvitationsDashboardRouteTabsEnumV2.received &&
          onRowClick && {
            onRowClick
          })}
        {...additionalParams}
      />

      {isOpen && <WithdrawDialogContainer sentToName={currentRowInfo.participantName} onClose={onWithdrawnDialogClose} />}
    </>
  );
};

export default React.memo(InvitationsTable);
