import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import SupportingDocumentUploader from '@sympli-mfe/document-forms-framework/components/supporting-document-uploader-field';
import WarningField from '@sympli-mfe/document-forms-framework/components/warning-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveLabel, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { InputClassKeys } from '@sympli/ui-framework/components/form/base-components/input';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { getCharacterCountHelperText } from '../../../../../../../helpers';
import StandardTermsDealingNumbersArray from '../../components/standard-terms-dealing-numbers-array';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS_COVENANTS } from '../../isRequiredChecks';
import { Mortgage_2_23_0__Model, StandardTermsModel } from '../../models';
import { VISIBILITY_CHECK_TERMS_AND_CONDITIONS$WARNING } from '../../visibilityChecks';
import { useStyles } from './styles';
import { COVENANTS_MAX_CHARACTERS, MAX_SUPPORTING_DOCUMENT_SIZE, MAX_TERM_NUMBERS } from './validationSchema';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionTermsAndConditions({ name, className }: Props): JSX.Element {
  const classes = useStyles();

  const fieldClasses: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControlValidation: classes.formControlValidation
    };
  }, [classes]);

  const formikProps = useFormikContext<Mortgage_2_23_0__Model>();
  const { values } = formikProps;
  const value: StandardTermsModel = _get(values, name);
  const { disabled, filesApi } = useDocumentContext();
  const fieldName = createModelKeyAppender<StandardTermsModel>(name);

  const additionalTncCharacterCount = (value.additionalCovenants ?? '').trim().length;
  const additionalTncCharacterCountText = getCharacterCountHelperText(
    //
    additionalTncCharacterCount,
    COVENANTS_MAX_CHARACTERS
  );

  const isAdditionalCovenantsRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS_COVENANTS(value);

  return (
    <div data-name={name} className={className}>
      <StandardTermsDealingNumbersArray //
        name={fieldName('standardTermsDealingNumbers')}
        maxItems={MAX_TERM_NUMBERS}
        minItems={1}
      />
      <FormGroup //
        title={resolveLabel('Additional terms and conditions', true)}
        classes={{ root: classes.additionalCovenants }}
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('additionalCovenants')}
            component={InputField}
            disabled={disabled}
            classes={fieldClasses}
            className={classes.noBottomMargin}
            fullWidth
            multiline
            minRows={2}
            placeholder={resolvePlaceholder(isAdditionalCovenantsRequired)}
            helperText={additionalTncCharacterCountText}
          />
        )}
      </FormGroup>
      {VISIBILITY_CHECK_TERMS_AND_CONDITIONS$WARNING(value) && (
        <FormGroup //
          title="Warning"
          fieldAreaDirection="column"
        >
          <Field //
            name={fieldName('warning')}
            component={WarningField}
          />
        </FormGroup>
      )}
      <SupportingDocumentUploader //
        name={fieldName('supportingDocuments')}
        titleLabel="Upload supporting document"
        disabled={disabled}
        maxItems={MAX_SUPPORTING_DOCUMENT_SIZE}
        onUpload={filesApi.upload}
        onDownload={filesApi.download}
      />
    </div>
  );
}

export default React.memo(SectionTermsAndConditions);
