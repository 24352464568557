import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import Alert from '@sympli/ui-framework/components/alert';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { AppEnvironmentEnum, emailPattern } from 'src/environments/models';
import { UserDetailsApiResponse } from '../models';
import { EMAIL_MAY_NOT_BE_REAL, SYMPLI_EMAIL_MISS_SUBDOMAIN } from './models';
import { useStyles } from './styles';

export interface OwnProps {
  open: boolean;
  values?: UserDetailsApiResponse;
  onClose?: (confirmed?: boolean) => void;
  environmentName: AppEnvironmentEnum;
}

export default React.memo(function PersonalDetailsDialog(props: OwnProps) {
  const classes = useStyles();
  const { values, open, onClose, environmentName } = props;
  const [message, setMessage] = React.useState('');

  const showEmailWarningMessage = React.useMemo(() => {
    if (
      !values ||
      ![
        //
        AppEnvironmentEnum.local,
        AppEnvironmentEnum.dev,
        AppEnvironmentEnum.feat,
        AppEnvironmentEnum.sit,
        AppEnvironmentEnum.iwt
      ].includes(environmentName)
    ) {
      return false;
    }

    const { email } = values;

    if (!emailPattern.test(email)) {
      setMessage(EMAIL_MAY_NOT_BE_REAL);
      return true;
    }

    // if it's dev1, feat1, sit, iwt
    if (environmentName !== AppEnvironmentEnum.local) {
      // email ends with sympli.com.au, need to check if they are the same env
      const match: Array<string> = email.match(emailPattern)!;

      if (!match[0].includes(environmentName)) {
        setMessage(SYMPLI_EMAIL_MISS_SUBDOMAIN);
        return true;
      }
    }

    return false;
  }, [environmentName, values]);

  if (!values) {
    return null;
  }

  const { firstName, middleName, lastName, email } = values;

  return (
    <ConfirmationDialog open={open} title="Confirm user details" okButtonText="Confirm" onClose={onClose}>
      <Typography className={classes.desc}>Please make sure these details are correct, as these cannot be changed later.</Typography>
      <Typography className={classes.heading}>First Name</Typography>
      <Typography>{firstName}</Typography>
      <Typography className={classes.heading}>Middle Name</Typography>
      {middleName ? <Typography>{middleName}</Typography> : <Typography className={classes.lightText}>Not provided</Typography>}
      <Typography className={classes.heading}>Last Name</Typography>
      <Typography>{lastName}</Typography>
      <Typography className={classNames(classes.heading, classes.topBorder)}>Email Address</Typography>
      <Typography>{email}</Typography>
      {showEmailWarningMessage && (
        <Alert variant="warning" data-testid="email-warning">
          {message}
        </Alert>
      )}
    </ConfirmationDialog>
  );
});
