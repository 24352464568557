import * as React from 'react';

import { useFormikContext } from 'formik';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import { NswPartyForm_2_21 as PartyForm } from '@sympli-mfe/document-components/party-form/nsw/2-21';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { Lease2_21_1Model, LessorPartyModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  isSubLease?: boolean;
}
const fieldName = modelKey<Lease2_21_1Model>();

function Lessors({ name, isSubLease = false }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<Lease2_21_1Model>();
  const { values } = formikProps;
  const { partyBook } = values;
  const roleName = isSubLease ? 'Sub lessor' : 'Lessor';
  const renderItem = React.useCallback(
    //
    ({ item }: DocumentArrayItemRenderProps<LessorPartyModel>) => {
      // we will be doing updates directly to partyBook
      // find the index of current mortgagor within partyBook
      const bookIndexOfPartyId = partyBook.findIndex(party => party.id === item.partyBookId);
      const itemBinding = `${fieldName('partyBook')}[${bookIndexOfPartyId}]`;

      return (
        <div data-name={`${name}[${bookIndexOfPartyId}]`}>
          <Subform //
            subformBindingPath={itemBinding}
            component={PartyForm}
            disabled={disabled}
            showFormGroups={false}
          />
        </div>
      );
    },
    [partyBook, disabled, name]
  );

  const lessorCount = values.lessors.length;
  return (
    <div data-testid={name}>
      <Section //
        data-name={fieldName('lessors')}
        title={pluralize(roleName, lessorCount)}
        error={getFormikError(formikProps, fieldName('lessors'), true)}
      >
        {lessorCount ? (
          <DocumentFieldArray //
            shouldMemoizeContext={false} // explicitly don't memoize the lessors array because items are bound to partyBook
            arrayBinding={name}
            renderItem={renderItem}
            itemTitle={roleName}
            disabled={disabled}
            itemStyle="formGroup"
            mode="fixed"
          />
        ) : (
          <Typography //
            className={classes.noDataMessage}
          >{`Please select a ${isSubLease ? 'Lease interest' : 'title reference'} to identify the ${roleName}`}</Typography>
        )}
      </Section>
    </div>
  );
}

export default React.memo(Lessors);
