import * as yup from 'yup';

import { resolveRelinquishingTenancyValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG } from '../../config';
import { Transfer2_21_1Model } from '../../models';

interface TransferorContext {
  partyBook: Transfer2_21_1Model['partyBook'];
  selectedTitleReferences: Transfer2_21_1Model['titleReferences'];
  transferors: Transfer2_21_1Model['transferors'];
}

export const MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE = 'The parties on the selected titles do not match; please provide name justification or remove the mismatched titles.';
const yupTransferors: yup.Schema<Transfer2_21_1Model['transferors']> = resolveRelinquishingTenancyValidationSchema<Transfer2_21_1Model['transferors']>({
  //
  relinquishingPartyRole: NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG.relinquishingPartyRole
}).test(
  //
  'Transferor requires name change test',
  MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE,
  function test(this: yup.TestContext<TransferorContext>) {
    const { selectedTitleReferences, partyBook } = this.options.context!;
    if (selectedTitleReferences.length <= 1) return true;
    return PartyValidations.verifyJustification(partyBook, getLegalEntityNameNSW);
  }
);

const contextResolver = ({ partyBook, titleReferences, transferors }: Transfer2_21_1Model): TransferorContext => ({
  //
  partyBook,
  transferors,
  selectedTitleReferences: titleReferences.filter(tr => tr.isSelected)
});

export default memoizeSchemaWithContext(yupTransferors, contextResolver);
