import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { ArchivedInvitationDetailApiResponse } from '@sympli/api-gateway/models';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchInvitationsArchivedFeed } from '../actions';
import { InvitationDashboardArchivedDetailsApiRequest } from '../api';

export interface InvitationsArchivedFeed {
  items: ArchivedInvitationDetailApiResponse;
  error?: string;
  isLoading: boolean;
  isRefetching: boolean;
  status: ApiStatus;
  args?: InvitationDashboardArchivedDetailsApiRequest;
}

const initialState: InvitationsArchivedFeed = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export const useInvitationsArchivedFeed = (invitationId?: string) => {
  const state = useStoreSelector(store => store.invitationsArchivedFeed);

  if (state.args?.invitationId !== invitationId) {
    return initialState;
  }
  return state;
};

const reducer = createReducer<
  //
  InvitationsArchivedFeed,
  Action
>(initialState)
  .handleAction(actionFetchInvitationsArchivedFeed.request, (state, action): InvitationsArchivedFeed => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchInvitationsArchivedFeed.success, (state, action): InvitationsArchivedFeed => {
    const { data } = action.payload;

    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      error: undefined,
      items: data
    };
  })
  .handleAction(actionFetchInvitationsArchivedFeed.failure, (state, action): InvitationsArchivedFeed => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  });

export default reducer;
