import { SearchCategoryEnum, SearchPredefineEnum } from '../../models';

export interface SearchBoxUpdatedApiRequest {
  searchTerm?: string;
  searchCategoryId?: SearchCategoryEnum;
  pageSize?: number;
  pageNumber?: number;
  searchPredefineId?: SearchPredefineEnum;
}

export const REGEX_HIGHLIGHT = /\*\*(.*?)\*\*/g; // regexp to match highlighted parts of the text
export const SEARCH_TERM_MIN_LENGTH = 3; // The minimum number of characters before triggering the search api call
export const SEARCH_TERM_MAX_LENGTH = 50; // The maximum number of characters before triggering the search api call
export const FIRST_N_SUGGESTIONS = 5; // The initial records shown in the suggestions result
export const NUMBER_OF_CHARACTER_IN_ROW = 70; // The maximum number of characters to display in a row
