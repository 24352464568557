import React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { IS_REQUIRED_CHECK_MCP_NUMBER } from '../../isRequiredChecks';
import { Mortgage2_27_0Model, PreRegisteredStandardTerm } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionMcpNumber({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const { values, errors, touched } = useFormikContext<Mortgage2_27_0Model>();
  const classes = useStyles();
  const hasError = touched.mcpNumber && typeof errors.mcpNumber === 'string';
  const { mcpNumber, preRegisteredStandardTermsDealingNumbers = [], preRegisteredStandardTerms = [], additionalCovenants, additionalAttachments } = values;

  const optionValues: PreRegisteredStandardTerm[] =
    preRegisteredStandardTerms ??
    preRegisteredStandardTermsDealingNumbers.map(x => {
      return { value: x };
    }); // preRegisteredStandardTermsDealingNumbers is obsolete, do not use in new document version. Use PreRegisteredStandardTerms instead

  const options = optionValues.map(({ value, description }) => ({ key: value, value: description ? `${value} (${description})` : value }));
  const isMcpNumberRequired = IS_REQUIRED_CHECK_MCP_NUMBER(additionalCovenants?.value, additionalAttachments);
  const placeholder = options.length ? resolveComboboxPlaceholder(isMcpNumberRequired) : resolvePlaceholder(isMcpNumberRequired);

  const isFilled = (mcpNumber ?? '').trim() !== '';
  const showFormTip = isFilled && !optionValues.some(x => x.value === (mcpNumber ?? '').trim());
  const formTip = showFormTip ? 'This document reference will only be used in this workspace. Please contact your Super Admin to have this added to the default list.' : undefined;

  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Document reference"
        fieldAreaDirection="column"
        formTip={formTip}
        className={isFilled && hasError ? classes.largePadding : undefined}
      >
        <Field //
          aria-label="Dealing number"
          name={name}
          component={SympliAutocompleteField}
          disabled={disabled}
          placeholder={placeholder}
          type="combobox"
          options={options}
          optionIdAttr="key"
          optionNameAttr="value"
          getCustomSelectedOptionLabel={(item: any) => item.key}
          inputComponentProps={{
            classes: {
              formHelperTextRoot: classes.largeError
            }
          }}
        />
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionMcpNumber);
