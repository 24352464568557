import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { formatPartyName } from '@sympli-mfe/document-forms-framework/components/party-form';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { CaveatModel_2_21_1 } from '../../models';

export function resolvePartyLookupOptions(caveatModel: CaveatModel_2_21_1): LookupItemModel[] {
  const FORMATTED_PARTY_OPTIONS: LookupItemModel[] = caveatModel.partyBook.map(party => ({ id: party.id, name: formatPartyName(party, NSW_PARTY_FORM_CONFIG) }));
  return FORMATTED_PARTY_OPTIONS;
}
