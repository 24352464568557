import Button from '@mui/material/Button';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

export interface Props {
  handleOnCancel(): void;
  handleOnSave(): void;
  isSaving: boolean;
}

function ReorderModeButtons({ handleOnCancel, handleOnSave, isSaving }: Props) {
  return (
    <FlexLayout justifyContent="center" alignItems="center" className="bg-[var(--sunny-day)] mx-[-24px] py-[8px] gap-[8px]">
      <Button
        variant="outlined"
        className="hover:bg-[var(--sunny-day-luminous)] rounded-[34px] border-[2px] border-[var(--neutral-000)] text-[var(--neutral-000)] px-[14px] py-[4px] text-[12px] font-bold leading-[16px] min-h-[20px]"
        onClick={handleOnCancel}
        disabled={isSaving}
      >
        Exit Reorder Mode
      </Button>
      <Button
        variant="outlined"
        className="hover:bg-[var(--sunny-day-translucent)] bg-[var(--neutral-000)] rounded-[34px] border-[var(--neutral-000)] text-[var(--sunny-day-dark)] px-[14px] py-[4px] text-[12px] font-bold leading-[16px] min-h-[20px]"
        onClick={handleOnSave}
        disabled={isSaving}
      >
        {isSaving ? 'Saving' : 'Save Order'}
      </Button>
    </FlexLayout>
  );
}

export default ReorderModeButtons;
