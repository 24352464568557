import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import PhoneNumberField from '@sympli/ui-framework/components/formik/phone-number-field';

import { RepresentativeDetailModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
}

function SectionTransferorRepresentativeDetail({ name }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<RepresentativeDetailModel>(name);

  return (
    <FormGroup //
      data-name={name}
      className={classes.root}
      title="Representative"
      fieldAreaDirection="column"
    >
      <Field //
        name={fieldName('name')}
        component={InputField}
        disabled={disabled}
        label="Name"
        fullWidth
      />
      <Field //
        name={fieldName('contactPhoneNumber')}
        component={PhoneNumberField}
        disabled={disabled}
        label="Phone"
      />
      <Field //
        name={fieldName('emailAddress')}
        component={InputField}
        disabled={disabled}
        label="Email"
        fullWidth
      />
    </FormGroup>
  );
}

export default React.memo(SectionTransferorRepresentativeDetail);
