import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import { Action } from 'redux';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { UserProfileModel } from '@sympli/api-gateway/shared';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import msg from '@sympli/ui-framework/utils/messages';

import Formik from 'src/components/formik';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { Header } from 'src/components/layout';
import Tooltip from 'src/components/tooltip';
import SettingsPageContentWrapper from 'src/containers/settings/SettingsPageContentWrapper';
import AuthenticationDialog from 'src/containers/shared/auth/multi-factor-auth/authentication-dialog';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { modelKey } from 'src/utils/formUtils';
import http from 'src/utils/http';
import { ChangePasswordForm, PasswordValidatorResponse } from './model';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  userProfile: UserProfileModel;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  openSnackbar: boolean;
}

const fieldName = modelKey<ChangePasswordForm>();

const PASSWORD_CACHE: { [key: string]: boolean | Promise<boolean> } = {};

const passwordAsyncValidator = async function (password: string) {
  try {
    const response = await http.post<PasswordValidatorResponse>('/profile/is-valid-password', { password: password });
    const isValid = response.result;
    PASSWORD_CACHE[password] = isValid;
    return response.result;
  } catch (e) {
    //We have a backend check as well, in case the call fails validation will be picked up back end
    PASSWORD_CACHE[password] = true;
    return true;
  }
};

class Password extends React.Component<Props, State> {
  public readonly state: Readonly<State> = {
    openSnackbar: false
  };

  private tooltipId = _uniqueId('tooltip-icon');

  private getInitialValues(): ChangePasswordForm {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    };
  }

  private getValidationSchema() {
    const { userProfile } = this.props;
    const { email } = userProfile;
    const schema = yup.object<ChangePasswordForm>({
      oldPassword: yup.string().required(msg.REQUIRED),
      password: yup
        .string()
        .min(10, msg.LENGTH_MUST_BE_AT_LEAST_CHARACTERS(10))
        .required(msg.REQUIRED)
        .test('password', 'New password cannot be your old password', function (this: yup.TestContext<ChangePasswordForm>) {
          const values = this.options.context!;
          return values.oldPassword !== values.password;
        })
        .test('not-username', 'New password cannot be your username', function (this: yup.TestContext<ChangePasswordForm>) {
          const values = this.options.context!;
          return values.password !== email;
        })
        .debouncedTest(
          'must contain at least 1 uppercase character, 1 lowercase character, 1 number and 1 special character', //
          function passwordCheck(this: yup.TestContext<ChangePasswordForm>, value) {
            if (!value || value?.length < 10) {
              return true;
            }

            if (PASSWORD_CACHE.hasOwnProperty(value)) {
              return PASSWORD_CACHE[value];
            }

            return passwordAsyncValidator(value);
          }
        ),
      confirmPassword: yup
        .string()
        .required(msg.REQUIRED)
        .test('confirmPassword', 'Values in new password fields must match', function (this: yup.TestContext<ChangePasswordForm>) {
          const values = this.options.context!;
          return values.password === values.confirmPassword;
        })
    });
    return schema;
  }

  render() {
    const { userProfile } = this.props;
    const { name } = userProfile;

    const { classes } = this.props;
    return (
      <SettingsPageContentWrapper>
        <AuthenticationDialog />
        <Header>Change password</Header>
        <Typography className={classes.header}>User: {name}</Typography>
        <Formik //
          method="post"
          action="/profile/change-password"
          getInitialValues={this.getInitialValues}
          validationSchema={this.getValidationSchema()} // initialize Yup validation schema
          onPostSubmit={this.handleOnPostSubmit}
        >
          {(formikProps: FormikProps<ChangePasswordForm>) => this.renderForm(formikProps)}
        </Formik>
      </SettingsPageContentWrapper>
    );
  }

  private renderForm(formikProps: FormikProps<ChangePasswordForm>) {
    const { classes } = this.props;
    const { touched, errors } = formikProps;
    return (
      <Form>
        <FormGroup title="Change your password" description={this.renderDescription()}>
          <FlexLayout flexDirection="column">
            <Field className={classes.groupItem} label="Old password" name={fieldName('oldPassword')} type="password" component={InputField} />
            <Field className={classes.groupItem} label="New password" name={fieldName('password')} type="password" component={InputField} />
            <Field
              className={touched['password'] && errors['password'] ? classes.confirmPassword : classes.groupItem}
              label="Re-type new password"
              name={fieldName('confirmPassword')}
              type="password"
              component={InputField}
            />
          </FlexLayout>
        </FormGroup>
        <WizardStepper showBackIcon={false} nextLabel="Change password" isLoading={formikProps.isSubmitting} />
      </Form>
    );
  }

  private renderDescription() {
    const { classes } = this.props;

    return (
      <Tooltip classes={{ tooltip: classes.toolTip }} id={this.tooltipId} title={this.renderRequirements()} placement="bottom" leaveDelay={500}>
        <span className={classes.requirement}>See requirements</span>
      </Tooltip>
    );
  }

  private renderRequirements() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography className={classes.description}>The passwords must meet the following requirements:</Typography>
        <ol type="a" className={classNames(classes.description, classes.descriptionList)}>
          <li className={classes.descriptionListItem}>be at least 10 characters in length</li>
          <li className={classes.descriptionListItem}>
            use a combination of upper case characters [e.g. ABCD..], lower case characters [e.g. abcd..], numbers [e.g. 1234..] and special characters [e.g. @, #, $..]
          </li>
          <li className={classes.descriptionListItem}>are unique (e.g. not used across multiple user or system accounts)</li>
          <li className={classes.descriptionListItem}>
            do not include dictionary words or any business-related words that are easy to guess, or use something obvious such as a person's name, birth date or similar
          </li>
          <li className={classes.descriptionListItem}>are not increments of previously used passwords (e.g. "password1", "password2")</li>
        </ol>
      </React.Fragment>
    );
  }

  private handleOnPostSubmit = ({ error, formikHelpers }: FormikPostSubmitArgs<ChangePasswordForm>) => {
    if (!error) {
      this.props.dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: 'Your password has been updated.',
          variant: 'new-success'
        })
      );
      formikHelpers.resetForm();
    }
  };
}

const styledComponent = withStyles(styles)(Password);

export default styledComponent;
