import * as React from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { IconMessengerClose, IconMessengerOpen } from 'src/components/layout/v2/Icons';
import styles, { ClassKeys } from './styles101';
import MessageDetail from './views/message-detail';
import MessageList from './views/message-list';

export interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  // conversations
  newMessageCount: number;
  // pre-open messenger
  messengerOpen?: boolean;
  // other
  className?: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  selectedConversationId?: string;
}

class Messenger101 extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    anchorEl: null,
    isOpen: false
  };
  private messengerButtonRef = React.createRef<HTMLButtonElement>();

  componentDidMount() {
    if (this.props.messengerOpen) {
      this.setState({ anchorEl: this.messengerButtonRef.current, isOpen: true });
    }
  }

  render() {
    return this.renderIcon();
  }

  private renderIcon() {
    const { classes, newMessageCount, className } = this.props;
    const showMessageCount = !this.state.isOpen && newMessageCount !== 0;

    const iconClass = 'h-[28px] w-[28px] large-screen:h-[40px] large-screen:w-[40px]';
    return (
      <>
        <Box className={classNames(classes.rootBox, showMessageCount && classes.animatedRootBox)}>
          <IconButton
            //
            ref={this.messengerButtonRef}
            className={classNames(classes.toggleButton2, className)}
            aria-label={`${this.state.isOpen ? 'close' : 'open'} messenger`}
            onClick={this.handleOnToggleButtonClick}
            size="large"
          >
            <div className={classNames(classes.circle, 'h-[48px] w-[48px] large-screen:h-[72px] large-screen:w-[72px]')}>
              {<div className={classes.messengerOpen}>{this.state.isOpen ? <IconMessengerClose className={iconClass} /> : <IconMessengerOpen className={iconClass} />}</div>}
            </div>
          </IconButton>
          {showMessageCount && <Box className={classes.unreadMessageCount}>{newMessageCount <= 9 ? newMessageCount : '9+'}</Box>}
        </Box>
        {this.renderMessengerDialog2()}
      </>
    );
  }

  private renderMessengerDialog2() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    return (
      <Popper
        open={this.state.isOpen}
        anchorEl={anchorEl}
        placement="left-end"
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-12, -6]
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper className={classes.paper2}>{this.renderContent()}</Paper>
          </Fade>
        )}
      </Popper>
    );
  }

  private renderContent() {
    const { workspaceId, participantId } = this.props;
    const { selectedConversationId } = this.state;
    if (selectedConversationId) {
      return (
        <MessageDetail
          //
          workspaceId={workspaceId}
          participantId={participantId}
          conversationId={selectedConversationId}
          onConversationSelect={this.handleOnConversationSelect}
        />
      );
    }
    return <MessageList workspaceId={workspaceId} participantId={participantId} onConversationSelect={this.handleOnConversationSelect} />;
  }

  private handleOnToggleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!this.state.isOpen) {
      // sending log to NewRelics when user open workspace messenger
      const { workspaceId, participantId, newMessageCount } = this.props;
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'messenger-open',
        logGroupId: 'workspace',
        workspaceId,
        participantId,
        newMessageCount
      });
    }

    const anchorEl = this.state.anchorEl ?? (event.currentTarget as HTMLButtonElement);

    //need to use .currentTarget here since .target is the element which initiated the event, not the element the event listener
    //is attached to (we need to anchor to the messenger button, not the unread message number badge which will disappear once all messages are read)
    this.setState(prevState => ({ anchorEl, isOpen: !prevState.isOpen, selectedConversationId: undefined }));
  };

  private handleOnConversationSelect = (selectedConversationId?: string) => {
    this.setState({ selectedConversationId });
  };
}

export default withStyles(styles)(Messenger101);
