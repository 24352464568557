import SYMPLI_ICON from 'src/assets/sympli.png';

export function resolveCommonIcons(name: string = '') {
  switch (name.toLowerCase()) {
    case 'sympli':
      return SYMPLI_ICON;
    default:
      return undefined;
  }
}
