import * as yup from 'yup';

import { ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { Form24A_2_24_1Model, TransfereeReferenceModel } from '../../models';
import { VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL } from '../../visibilityCheck';

const yupPartyReference = yup
  .object<ApiPartyReferenceModel>({
    isSelected: yup.bool(),
    partyBookId: yup.mixed(),
    addressBookId: yup.string().required(msg.REQUIRED),
    partyCapacity: yup.mixed()
  })
  .defined();

export const yupTransferee = yup
  .object<TransfereeReferenceModel>({
    transferee: yupPartyReference,
    isForeignPersonOrCorp: yup.bool().nullable().required(msg.REQUIRED),
    isResident: validateWhenVisible2<boolean | undefined | null>({
      validationWhenVisible: yup.bool().nullable().required(msg.REQUIRED),
      visibilityCheck: (transferee: TransfereeReferenceModel, context: Form24A_2_24_1Model) => VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL(transferee.transferee, context)
    })
  })
  .defined();
