import { createAsyncAction } from 'typesafe-actions';

import { WorkspacePreferencesApiResponse } from '../models';

export const actionFetchWorkspacePreferences = createAsyncAction(
  //
  'FETCH_WORKSPACE_PREFERENCES',
  'FETCH_WORKSPACE_PREFERENCES_SUCCESS',
  'FETCH_WORKSPACE_PREFERENCES_ERROR'
)<
  //
  undefined,
  { data: WorkspacePreferencesApiResponse },
  { error: Error }
>();
