import { Action, createReducer } from 'typesafe-actions';

import { actionArchiveWorkspace } from '../actions';

export interface ArchiveWorkspaceState {
  isExecuting: boolean;
  error?: string;
}

const initialState: ArchiveWorkspaceState = {
  isExecuting: false,
  error: undefined
};

const archiveWorkspaceReducer = createReducer<
  //
  ArchiveWorkspaceState,
  Action
>(initialState)
  .handleAction(
    actionArchiveWorkspace.request,
    (): ArchiveWorkspaceState => ({
      ...initialState,
      error: undefined,
      isExecuting: true
    })
  )
  .handleAction(
    actionArchiveWorkspace.success,
    (): ArchiveWorkspaceState => ({
      ...initialState,
      isExecuting: false
    })
  )
  .handleAction(
    actionArchiveWorkspace.failure,
    (_, action): ArchiveWorkspaceState => ({
      ...initialState,
      error: action.payload.error.message
    })
  );

export default archiveWorkspaceReducer;
