import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    root: {
      marginTop: 24,
      marginBottom: 16,
      '& .MuiTabs-indicator': {
        //TODO this needs to be stored into colors
        backgroundColor: '#292F36'
      }
    },
    tabRoot: {
      width: 120,
      fontSize: '12px',
      textTransform: 'uppercase'
    }
  },
  {
    name: 'SearchTabs'
  }
);
