import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionLaunchTitleActivityCheck, LaunchTitleActivityCheckRequestModel } from '../actions';

export interface TitleActionsState {
  isLoadingActivitiesCheck: boolean;
  status: ApiStatus;
  // isLoading: boolean;
  // isRefetching: boolean;
  error?: string;
  args?: LaunchTitleActivityCheckRequestModel;
}

const initialState: TitleActionsState = {
  isLoadingActivitiesCheck: false,
  status: 'idle',
  // isLoading: true,
  // isRefetching: false,
  error: undefined,
  args: undefined
};

export function useTitleActions(workspaceId: string, participantId: string, titleReference: string) {
  const state = useStoreSelector(store => store.titleActions);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId && state.args?.titleReference === titleReference) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  TitleActionsState,
  Action
>(initialState)
  .handleAction(actionLaunchTitleActivityCheck.request, (state, action): TitleActionsState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoadingActivitiesCheck: true,
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionLaunchTitleActivityCheck.success, (state): TitleActionsState => {
    return {
      ...state,
      status: 'resolved',
      isLoadingActivitiesCheck: false
    };
  })
  .handleAction(actionLaunchTitleActivityCheck.failure, (state, action): TitleActionsState => {
    return {
      ...state,
      status: 'rejected',
      isLoadingActivitiesCheck: false,
      error: action.payload.error.message
    };
  });

export default reducer;
