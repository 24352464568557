import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';

import { Mortgage2_23_1Model } from './models';
import yupMortgageeDetails from './sections/mortgagee-details/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupTermsAndConditions from './sections/terms-and-conditions/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<Mortgage2_23_1Model>({
    titleReferences: yupTitleReferences,
    mortgageeDetails: yupMortgageeDetails,
    termsAndConditions: yupTermsAndConditions,
    mortgagors: yupMortgagors,
    partyBook: yup.mixed(),
    addressBook: yup.array<AddressBookEntityModel>().defined(),
    needToGenerateMortgagors: yup.boolean(),
    transferees: yup.array<TenancyPartyModel>().defined(),
    proprietors: yup.mixed().nullable()
  })
  .defined()
  .log();
