import * as React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  linkTo?: string;
  title: string;
  description?: string | JSX.Element;
  disabled?: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class PaymentItem extends React.PureComponent<Props> {
  render() {
    const { classes, className, style } = this.props;
    return (
      <FlexLayout flexDirection="row" alignItems="center" className={classNames(classes.root, className)} style={style}>
        <div className={classes.main}>
          {this.renderTitle()}
          {this.renderDescription()}
        </div>
      </FlexLayout>
    );
  }

  private renderTitle() {
    const { classes, title, linkTo, disabled } = this.props;

    return (
      <div
        className={classNames(
          classes.title101,
          {
            [classes.titlePending]: disabled
          },
          classes.textEllipsis
        )}
      >
        {linkTo && !disabled ? ( //
          <Link to={linkTo}>{title}</Link>
        ) : (
          title
        )}
      </div>
    );
  }

  private renderDescription() {
    const { classes, description } = this.props;
    if (!description) return null;

    return <div className={classes.description101}>{description}</div>;
  }
}

export default withStyles(styles)(PaymentItem);
