import { PartyCapacityEnum, PARTY_CAPACITY_LOOKUP_OPTIONS as QLD_PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-24/qld';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

/**
 path:
 - titleReferences[*].mortgagors[*].partyCapacity.partyCapacity
 - mortgageeDetails.transfereeGroups[*].parties[*].partyCapacity

 schema version:
[{"id":1,"value":"PersonalRepresentative","name":"Personal Representative","_meta":null},{"id":4,"value":"Trustee","name":"Trustee","_meta":null}]

 hash:
 WzEsNF0=
*/
// special subset just for Mortgage document
export const PARTY_CAPACITY_LOOKUP_OPTIONS: LookupEnumModel<PartyCapacityEnum>[] = restrictedLookupOptions(QLD_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    //
    PartyCapacityEnum.PersonalRepresentative,
    PartyCapacityEnum.Trustee
  ]
});
