import { QLD_ADDRESS_FORM_CONFIG, QLD_ADDRESS_FORM_INITIAL_VALUES, QLD_PHYSICAL_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/qld/2-24/config';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import {
  AddressFieldFormConfig,
  AddressFormValueModel,
  InitialValuesAddressEntityModel,
  PhysicalAddressFormConfig
} from '@sympli-mfe/document-forms-framework/components/address-field';
import { YupPhysicalAddressSchemaMapModel, yupSuburbBuilder } from '@sympli-mfe/document-forms-framework/components/address-field/validation-schema';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { UnitTypeEnum } from '@sympli-mfe/enums-shared/necds';

import { resolvePlaceholder } from 'src/utils/formUtils';
import { QLD_STATE_OPTION } from './models';

export const QLD_FORM24B_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...QLD_PARTY_FORM_CONFIG
};

const QLD_FORM24B_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG,
  unitType: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitType,
    hidden: true
  },
  unitNumber: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitNumber,
    placeholder: resolvePlaceholder(false)
  },
  suburb: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.suburb
  },
  customizeValidation: (nonJurisdictionSpecificSchemaMap: YupPhysicalAddressSchemaMapModel) => {
    // please note the schemaMap passed in here is the schema passed by the component in mfe-document-forms-framework
    const customiseValidation = QLD_PHYSICAL_ADDRESS_FORM_CONFIG.customizeValidation?.(nonJurisdictionSpecificSchemaMap)!;

    return {
      ...nonJurisdictionSpecificSchemaMap,
      ...customiseValidation,
      suburb: yupSuburbBuilder({ length: 50 })
    };
  }
};

const QLD_FORM24B_PROPERTY_DETAILS_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG,
  unitType: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitType,
    hidden: true
  },
  unitNumber: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitNumber,
    placeholder: resolvePlaceholder(false)
  },
  suburb: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.suburb
  },
  state: {
    options: QLD_STATE_OPTION,
    format: 'string'
  },
  customizeValidation: (nonJurisdictionSpecificSchemaMap: YupPhysicalAddressSchemaMapModel) => {
    // please note the schemaMap passed in here is the schema passed by the component in mfe-document-forms-framework
    const customiseValidation = QLD_PHYSICAL_ADDRESS_FORM_CONFIG.customizeValidation?.(nonJurisdictionSpecificSchemaMap)!;

    return {
      ...nonJurisdictionSpecificSchemaMap,
      ...customiseValidation,
      suburb: yupSuburbBuilder({ length: 50 })
    };
  }
};

export const QLD_FORM24B_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false,
  physicalAddressFormConfig: QLD_FORM24B_PHYSICAL_ADDRESS_FORM_CONFIG,
  electronicAddressFormConfig: false
};

export const QLD_FORM24B_TITLE_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false,
  physicalAddressFormConfig: QLD_FORM24B_PHYSICAL_ADDRESS_FORM_CONFIG,
  electronicAddressFormConfig: false,
  overseasAddressFormConfig: false
};

export const QLD_FORM24B_PROPERTY_DETAILS_TITLE_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false,
  physicalAddressFormConfig: QLD_FORM24B_PROPERTY_DETAILS_PHYSICAL_ADDRESS_FORM_CONFIG,
  electronicAddressFormConfig: false,
  overseasAddressFormConfig: false
};

export const QLD_FORM24B_ADDRESS_FORM_INITIAL_VALUES: AddressFormValueModel<InitialValuesAddressEntityModel> = {
  ...QLD_ADDRESS_FORM_INITIAL_VALUES,
  addressDetail: {
    ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail,
    physicalAddress: {
      ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail.physicalAddress!,
      unitType: UnitTypeEnum.UNIT
    }
  }
};
