import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

/*
import ButtonLink from '@sympli/ui-framework/components/button-link';
import { IconLink } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';
*/
interface Props {
  workspaceId: string;
  // handlers
  onUnlinkWorkspaceClick(workspaceId: string): void;
}

function SympliId({
  //
  workspaceId,
  children,
  // handlers
  onUnlinkWorkspaceClick
}: React.PropsWithChildren<Props>) {
  // const classes = useStyles();

  return (
    <FlexLayout //
      alignItems="center"
      justifyContent="space-between"
    >
      {children}

      {/* <ButtonLink
        disabled
        icon={<IconLink className={classes.unlinkWorkspaceIcon} />}
        // no need to memoize this callback because workspaceId and SympliId (children) are closely related
        // and if any those have changed we will need to re-render anyway
        onClick={() => {
          onUnlinkWorkspaceClick(workspaceId);
        }}
        color="inherit"
      >
        Unlink workspace
      </ButtonLink> */}
    </FlexLayout>
  );
}

export default React.memo(SympliId);
