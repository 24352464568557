import Typography from '@mui/material/Typography';

import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import {
  addressFormatter,
  customerFormatter,
  dashboardTimeFormatter,
  documentTypeFormatter,
  doubleRowPrimarySecondaryFormatter,
  groupNameFormatter,
  jurisdictionFormatter,
  lodgementCaseStatusFormatter,
  memberFormatter,
  resolveCustomerName,
  standaloneWorkspaceStatusBarFormatter,
  standaloneWorkspaceStatusFormatter,
  systemActivityFormatter
} from 'src/containers/dashboard/shared/formatters';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme/screens';
import { titleAddress } from 'src/utils/formatters';
import { modelKey } from 'src/utils/formUtils';
import { StandaloneDashboardRouteTabsEnumV2 } from '../models';
import { StandaloneTableModel } from './models';

const cf = columnFormatterCheck<StandaloneTableModel>();
type WithExtra<T, ExtraProps> = {
  [P in keyof T]: Omit<T[P], keyof ExtraProps> & ExtraProps;
};
// TODO move this to ui-framework
function dynamicColumns<R extends object, CH>(
  conditionalColumns: WithExtra<
    //
    ColumnsModel<R>,
    {
      thClassName?: ((args: CH) => string | undefined) | string;
      className?: ((args: CH) => string | undefined) | string;
      label: ((args: CH) => string | JSX.Element) | (string | JSX.Element);
      visibilityCheck?: (args: CH) => boolean;
    }
  >,
  args: CH
): ColumnsModel<R> {
  // recalculate columns based on their visibility
  const columns: ColumnsModel<R> = Object.entries(conditionalColumns) //
    .reduce((acc: ColumnsModel<R>, [key, { visibilityCheck, label, thClassName, className, ...columnDef }]) => {
      if (visibilityCheck?.(args)) {
        if (typeof label === 'function') {
          label = label(args);
        }

        if (typeof thClassName === 'function') {
          thClassName = thClassName(args);
        }

        if (typeof className === 'function') {
          className = className(args);
        }

        acc[key] = {
          ...columnDef,
          label,
          thClassName,
          className
        };
      }
      return acc;
    }, {});

  // return only visible columns
  return columns;
}

type DynamicArgs = {
  screenSize: ScreenSizeEnum;
  selectedTab: StandaloneDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
};
const fieldName = modelKey<StandaloneTableModel>();

export const resolveColumns = ({
  screenVariant,
  workspaceStatusGroup,
  assignmentType
}: {
  //
  screenVariant: ScreenSizeVariant;
  workspaceStatusGroup: StandaloneDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
}) => {
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);
  const args: DynamicArgs = {
    screenSize,
    selectedTab: workspaceStatusGroup,
    assignmentType
  };

  if (assignmentType === AssignmentTypeEnum.AssignedToMe) {
    return resolveAssignToMeColumns(args);
  }
  return resolveAssignToGroupColumns(args);
};

function resolveAssignToMeColumns(args: DynamicArgs) {
  const columns: ColumnsModel<StandaloneTableModel> = dynamicColumns<StandaloneTableModel, DynamicArgs>(
    {
      accessed: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ACCESSED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   120   120   120   N     N     120
          // 1536   88    88    88    N     N     88
          // 1280   88    88    88    N     N     88
          return selectedTab !== StandaloneDashboardRouteTabsEnumV2.complete && selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('lastAccessed'),
        formatter: cf<StandaloneTableModel['lastAccessed']>(dashboardTimeFormatter)
      },

      completed: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'COMPLETED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL   ACT   TBR   COM   ARC   REC
          // 1920 N     N     N     120   N     N
          // 1536 N     N     N     88    N     N
          // 1280 N     N     N     88    N     N
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.complete;
        },
        name: fieldName('completedDate'),
        formatter: cf<StandaloneTableModel['completedDate']>(dashboardTimeFormatter)
      },

      archived: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ARCHIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   N     N     N     N     120   N
          // 1536   N     N     N     N     88    N
          // 1280   N     N     N     N     88    N
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('archivedDate'),
        formatter: cf<StandaloneTableModel['archivedDate']>(dashboardTimeFormatter)
      },

      reference: {
        label: 'REFERENCE',
        // auto width, we have at least one flexible column width
        visibilityCheck() {
          //        ALL ACT TBR COM ARC REC
          // 1920   Y   Y   Y   Y   Y   Y
          // 1536   Y   Y   Y   Y   Y   Y
          // 1280   Y   Y   Y   Y   Y   Y
          return true;
        },
        sortable: false,
        name: fieldName('sympliId'),
        get: ({ reference: primary, sympliId: secondary }: StandaloneTableModel) => ({ primary, secondary }),
        formatter: cf<{
          //
          primary: StandaloneTableModel['reference'];
          secondary: StandaloneTableModel['sympliId'];
        }>(doubleRowPrimarySecondaryFormatter)
      },

      address: {
        label: 'Address',
        thClassName: 'w-[160px]',
        visibilityCheck({ screenSize }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   160   160   160   160   160   160
          // 1536   160   160   160   160   160   160
          // 1280   N     N     N     N     N     N
          return screenSize !== ScreenSizeEnum.SmallLaptop;
        },
        name: fieldName('addresses'),
        sortable: false,
        get: ({ addresses, jurisdictionId }: StandaloneTableModel) => ({ addresses, jurisdictionId }),
        formatter: cf<{ addresses: StandaloneTableModel['addresses']; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple addresses found</b>
                  </div>
                  {row.addresses.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {titleAddress(item, row.jurisdictionId)}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },

      jdx: {
        thClassName: 'w-[40px]',
        label: 'JDX',
        visibilityCheck({ screenSize }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   N     N     N     N     N     N
          // 1536   N     N     N     N     N     N
          // 1280   40    40    40    40    40    40
          return screenSize === ScreenSizeEnum.SmallLaptop;
        },
        name: fieldName('jurisdictionId'),
        sortable: false,
        formatter: cf<StandaloneTableModel['jurisdictionId']>(jurisdictionFormatter)
      },

      customer: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[120px]';
          }
          return 'w-[160px]';
        },
        label: 'CUSTOMER',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   160   160   160   160   160   160
          // 1536   N     160   160   160   160   160
          // 1280   N     120   120   120   120   120
          return !(selectedTab === StandaloneDashboardRouteTabsEnumV2.all && (screenSize === ScreenSizeEnum.SmallLaptop || screenSize === ScreenSizeEnum.MediumScreen));
        },
        name: fieldName('clients'),
        sortable: false,
        formatter: cf<StandaloneTableModel['clients']>(customerFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple Customers found</b>
                  </div>
                  {row.clients.map((item, i) => {
                    const custName = item ? resolveCustomerName(item) : '';
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {custName ? custName : '-'}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return anchorEl.innerText;
          }
        }
      },

      systemActivity: {
        thClassName: 'w-[240px]',
        label: 'SYSTEM ACTIVITY',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   240   240   240   240   N     240
          // 1536   240   240   240   240   N     240
          // 1280   240   240   240   240   N     240
          return selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('lastSystemActivity'),
        sortable: false,
        formatter: cf<StandaloneTableModel['lastSystemActivity']>(systemActivityFormatter)
      },

      documentType: {
        thClassName: 'w-[240px]',
        label: 'DOCUMENT TYPE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   240   240   240   240   240   240
          // 1536   240   240   240   240   240   240
          // 1280   240   240   240   240   240   240
          return true;
        },
        name: fieldName('documents'),
        sortable: false,
        formatter: cf<StandaloneTableModel['documents']>(documentTypeFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'documents':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple document types found</b>
                  </div>
                  {row.documents.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item ? item : '-'}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },

      documents: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        label: 'DOCUMENTS',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC REC
          // 1920   120   120   120   120   N   120
          // 1536   120   120   120   120   N   120
          // 1280   88    88    88    88    N   88
          return selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived;
        },
        sortable: false,
        get: ({ lodgementCaseStatusId, allDocumentsFullySigned }: StandaloneTableModel) => ({ lodgementCaseStatusId, allDocumentsFullySigned }),
        name: fieldName('lodgementCaseStatusId'),
        formatter: cf<{
          //
          lodgementCaseStatusId: StandaloneTableModel['lodgementCaseStatusId'];
          allDocumentsFullySigned: StandaloneTableModel['allDocumentsFullySigned'];
        }>(lodgementCaseStatusFormatter)
      },

      wsStatusText: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        label({ selectedTab }: DynamicArgs): string {
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.archived ? 'STATUS' : 'WS STATUS';
        },
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   120   N     N     N     120   N
          // 1536   120   N     N     N     120   N
          // 1280   88    N     N     N     88    N
          return (
            selectedTab === StandaloneDashboardRouteTabsEnumV2.all || //
            selectedTab === StandaloneDashboardRouteTabsEnumV2.archived
          );
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        get: ({
          //
          workspaceStatusId,
          lodgementCaseStatusId,
          allDocumentsFullySigned
        }: StandaloneTableModel) => ({ workspaceStatusId, lodgementCaseStatusId, allDocumentsFullySigned }),
        formatter: cf<{
          //
          lodgementCaseStatusId: StandaloneTableModel['lodgementCaseStatusId'];
          workspaceStatusId: StandaloneTableModel['workspaceStatusId'];
          allDocumentsFullySigned: StandaloneTableModel['allDocumentsFullySigned'];
        }>(standaloneWorkspaceStatusFormatter)
      },

      workspaceStatusBar: {
        thClassName: 'w-[6px]',
        label: '',
        visibilityCheck() {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   6     6     6     6     6     6
          // 1536   6     6     6     6     6     6
          // 1280   6     6     6     6     6     6
          return true;
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        padding: 'none',
        get: ({
          //
          workspaceStatusId,
          lodgementCaseStatusId,
          allDocumentsFullySigned
        }: StandaloneTableModel) => ({ workspaceStatusId, lodgementCaseStatusId, allDocumentsFullySigned }),
        formatter: cf<{
          //
          lodgementCaseStatusId: StandaloneTableModel['lodgementCaseStatusId'];
          workspaceStatusId: StandaloneTableModel['workspaceStatusId'];
          allDocumentsFullySigned: StandaloneTableModel['allDocumentsFullySigned'];
        }>(standaloneWorkspaceStatusBarFormatter)
      }
    },
    args
  );
  return columns;
}

function resolveAssignToGroupColumns(args: DynamicArgs) {
  const columns: ColumnsModel<StandaloneTableModel> = dynamicColumns<StandaloneTableModel, DynamicArgs>(
    {
      accessed: {
        thClassName: 'w-[88px]',
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ACCESSED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   88    88    88     N     N    88
          // 1536   88    88    88     N     N    88
          // 1280   88    88    88     N     N    88
          return selectedTab !== StandaloneDashboardRouteTabsEnumV2.complete && selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('lastAccessed'),
        formatter: cf<StandaloneTableModel['lastAccessed']>(dashboardTimeFormatter)
      },

      completed: {
        thClassName: 'w-[88px]',
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'COMPLETED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   N     N     N     88    N     N
          // 1536   N     N     N     88    N     N
          // 1280   N     N     N     88    N     N
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.complete;
        },
        name: fieldName('completedDate'),
        formatter: cf<StandaloneTableModel['completedDate']>(dashboardTimeFormatter)
      },

      archived: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ARCHIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   N     N     N     N     120   N
          // 1536   N     N     N     N     88    N
          // 1280   N     N     N     N     88    N
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('archivedDate'),
        formatter: cf<StandaloneTableModel['archivedDate']>(dashboardTimeFormatter)
      },

      reference: {
        label: 'REFERENCE',

        visibilityCheck() {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        //width: 160, // MR explicitly commented out so we have at least one flexible column width
        sortable: false,
        name: fieldName('sympliId'),
        get: ({ reference: primary, sympliId: secondary }: StandaloneTableModel) => ({ primary, secondary }),
        formatter: cf<{
          //
          primary: StandaloneTableModel['reference'];
          secondary: StandaloneTableModel['sympliId'];
        }>(doubleRowPrimarySecondaryFormatter)
      },

      address: {
        label: 'Address',
        thClassName: 'w-[160px]',
        visibilityCheck({ screenSize, selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   160   160   160   160   160   160
          // 1536   N     N     N     160   160   N
          // 1280   N     N     N     N     N     N
          return (
            screenSize === ScreenSizeEnum.LargeScreen ||
            (screenSize === ScreenSizeEnum.MediumScreen &&
              (selectedTab === StandaloneDashboardRouteTabsEnumV2.archived || selectedTab === StandaloneDashboardRouteTabsEnumV2.complete))
          );
        },
        name: fieldName('addresses'),
        sortable: false,
        get: ({ addresses, jurisdictionId }: StandaloneTableModel) => ({ addresses, jurisdictionId }),
        formatter: cf<{ addresses: StandaloneTableModel['addresses']; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <b>Multiple addresses found</b>
                  <ul>
                    {row.addresses.map(item => {
                      return <li>{titleAddress(item, row.jurisdictionId)}</li>;
                    })}
                  </ul>
                </div>
              );
            default:
              return null;
          }
        }
      },

      jdx: {
        thClassName: 'w-[40px]',
        label: 'JDX',
        visibilityCheck({ screenSize, selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   N     N     N     N     N     N
          // 1536   40   40     40    N     N     40
          // 1280   40   40     40    40    40    40
          return (
            screenSize === ScreenSizeEnum.SmallLaptop ||
            (screenSize === ScreenSizeEnum.MediumScreen &&
              selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived &&
              selectedTab !== StandaloneDashboardRouteTabsEnumV2.complete)
          );
        },
        name: fieldName('jurisdictionId'),
        sortable: false,
        formatter: cf<StandaloneTableModel['jurisdictionId']>(jurisdictionFormatter)
      },

      customer: {
        thClassName: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (selectedTab === StandaloneDashboardRouteTabsEnumV2.archived && screenSize !== ScreenSizeEnum.SmallLaptop) {
            return 'w-[160px]';
          }
          return 'w-[120px]';
        },
        label: 'CUSTOMER',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   120   120   120   120   160   120
          // 1536   N     N     N     N     160   N
          // 1280   N     N     N     N     120   N
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.archived || screenSize === ScreenSizeEnum.LargeScreen;
        },
        name: fieldName('clients'),
        sortable: false,
        formatter: cf<StandaloneTableModel['clients']>(customerFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple Customers found</b>
                  </div>
                  {row.clients.map((item, i) => {
                    const custName = item ? resolveCustomerName(item) : '';
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {custName ? custName : '-'}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return anchorEl.innerText;
          }
        }
      },

      systemActivity: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop || (screenSize === ScreenSizeEnum.MediumScreen && selectedTab === StandaloneDashboardRouteTabsEnumV2.complete)) {
            return 'w-[220px]';
          }
          return 'w-[240px]';
        },
        label: 'SYSTEM ACTIVITY',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC REC
          // 1920   240   240   240   240   N   240
          // 1536   240   240   240   220   N   240
          // 1280   220   220   220   220   N   220
          return selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('lastSystemActivity'),
        sortable: false,
        formatter: cf<StandaloneTableModel['lastSystemActivity']>(systemActivityFormatter)
      },

      documentType: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (
            screenSize === ScreenSizeEnum.SmallLaptop &&
            selectedTab !== StandaloneDashboardRouteTabsEnumV2.complete &&
            selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived
          ) {
            return 'w-[220px]';
          }
          return 'w-[240px]';
        },
        label: 'DOCUMENT TYPE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   240   240   240   240   240   240
          // 1536   240   240   240   240   240   240
          // 1280   220   220   220   240   240   220
          return true;
        },
        name: fieldName('documents'),
        sortable: false,
        formatter: cf<StandaloneTableModel['documents']>(documentTypeFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'documents':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple document types found</b>
                  </div>
                  {row.documents.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item ? item : '-'}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },

      documents: {
        thClassName: ({ selectedTab, screenSize, assignmentType }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop && selectedTab === StandaloneDashboardRouteTabsEnumV2.all) {
            return 'w-[52px]';
          }

          if (
            screenSize === ScreenSizeEnum.MediumScreen &&
            assignmentType === AssignmentTypeEnum.AssignedToGroup &&
            (selectedTab === StandaloneDashboardRouteTabsEnumV2.all || selectedTab === StandaloneDashboardRouteTabsEnumV2.recent)
          ) {
            return 'w-[94px]';
          }

          return 'w-[88px]';
        },
        className: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab !== StandaloneDashboardRouteTabsEnumV2.complete) {
              return 'text-clip';
            }
          }
        },
        label: ({ screenSize, selectedTab }: DynamicArgs) => {
          return screenSize <= ScreenSizeEnum.SmallLaptop && selectedTab === StandaloneDashboardRouteTabsEnumV2.all ? 'DOCS' : 'DOCUMENTS';
        },
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //!important inside () is value for Reassigned Tab
          //        ALL     ACT   TBR   COM   ARC   REC
          // 1920   88      88    88    88    N     88
          // 1536   94(88)  88    88    88    N     94(88)
          // 1280   52      88    88    88    N     88
          return selectedTab !== StandaloneDashboardRouteTabsEnumV2.archived;
        },
        sortable: false,
        get: ({ lodgementCaseStatusId, allDocumentsFullySigned }: StandaloneTableModel) => ({ lodgementCaseStatusId, allDocumentsFullySigned }),
        name: fieldName('lodgementCaseStatusId'),
        formatter: cf<{
          //
          lodgementCaseStatusId: StandaloneTableModel['lodgementCaseStatusId'];
          allDocumentsFullySigned: StandaloneTableModel['allDocumentsFullySigned'];
        }>(lodgementCaseStatusFormatter)
      },

      wsStatusText: {
        thClassName: ({ selectedTab, screenSize, assignmentType }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen && selectedTab === StandaloneDashboardRouteTabsEnumV2.archived) {
            return 'w-[120px]';
          }
          if (
            screenSize === ScreenSizeEnum.SmallLaptop ||
            (screenSize === ScreenSizeEnum.MediumScreen && assignmentType === AssignmentTypeEnum.ReAssigned && selectedTab === StandaloneDashboardRouteTabsEnumV2.all)
          ) {
            return 'w-[88px]';
          }
          return 'w-[94px]';
        },
        label({ selectedTab }: DynamicArgs): string {
          return selectedTab === StandaloneDashboardRouteTabsEnumV2.archived ? 'STATUS' : 'WS STATUS';
        },
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //!important inside () is value for Reassigned Tab
          //        ALL     ACT   TBR   COM   ARC   REC
          // 1920   94      N     N     N     120   N
          // 1536   94(88)  N     N     N     94    N
          // 1280   88      N     N     N     88    N
          return (
            selectedTab === StandaloneDashboardRouteTabsEnumV2.all || //
            selectedTab === StandaloneDashboardRouteTabsEnumV2.archived
          );
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        get: ({
          //
          workspaceStatusId,
          lodgementCaseStatusId,
          allDocumentsFullySigned
        }: StandaloneTableModel) => ({
          workspaceStatusId,
          lodgementCaseStatusId,
          allDocumentsFullySigned
        }),
        formatter: cf<{
          //
          workspaceStatusId: StandaloneTableModel['workspaceStatusId'];
          lodgementCaseStatusId: StandaloneTableModel['lodgementCaseStatusId'];
          allDocumentsFullySigned: StandaloneTableModel['allDocumentsFullySigned'];
        }>(standaloneWorkspaceStatusFormatter)
      },

      group: {
        thClassName: ({ selectedTab, screenSize, assignmentType }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            // large screen
            return 'w-[120px]';
          } else if (screenSize === ScreenSizeEnum.MediumScreen) {
            // medium screen
            if (selectedTab === StandaloneDashboardRouteTabsEnumV2.all) {
              return 'w-[88px]';
            }

            return 'w-[120px]';
          } else {
            // small screen
            return selectedTab === StandaloneDashboardRouteTabsEnumV2.archived ? 'w-[120px]' : 'w-[88px]';
          }
        },
        label: 'Group',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //!important inside () is value for Reassigned Tab
          //        ALL   ACT     TBR   COM   ARC   REC
          // 1920   120   120     120   120   120   120
          // 1536   88    120     120   120   120   120
          // 1280   88    88      88    88    120   88
          return true;
        },
        name: fieldName('groupName'),
        sortable: false,
        formatter: cf<StandaloneTableModel['groupName']>(groupNameFormatter)
      },

      member: {
        thClassName: ({ selectedTab, screenSize, assignmentType }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            // large screen
            return 'w-[120px]';
          } else if (screenSize === ScreenSizeEnum.MediumScreen) {
            // medium screen
            if (selectedTab === StandaloneDashboardRouteTabsEnumV2.all) {
              return 'w-[88px]';
            }

            return 'w-[120px]';
          } else {
            // small screen
            return 'w-[88px]';
          }
        },
        label: 'Member',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        ALL   ACT     TBR   COM   ARC   REC
          // 1920   120   120     120   120   N     120
          // 1536   88    120     120   120   N     120
          // 1280   88    88      88    88    N     88
          return !(selectedTab === StandaloneDashboardRouteTabsEnumV2.archived);
        },
        name: fieldName('member'),
        sortable: false,
        formatter: cf<StandaloneTableModel['member']>(memberFormatter)
      },

      workspaceStatusBar: {
        thClassName: 'w-[6px]',
        label: '',
        visibilityCheck() {
          //        ALL   ACT   TBR   COM   ARC   REC
          // 1920   6     6     6     6     6     6
          // 1536   6     6     6     6     6     6
          // 1280   6     6     6     6     6     6
          return true;
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        padding: 'none',
        get: ({ workspaceStatusId, lodgementCaseStatusId }: StandaloneTableModel) => ({ workspaceStatusId, lodgementCaseStatusId }),
        formatter: cf<{
          //
          lodgementCaseStatusId: StandaloneTableModel['lodgementCaseStatusId'];
          workspaceStatusId: StandaloneTableModel['workspaceStatusId'];
        }>(standaloneWorkspaceStatusBarFormatter)
      }
    },
    args
  );
  return columns;
}
