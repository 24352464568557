import Logger from '@sympli/ui-logger';

import http from 'src/utils/http';
import { SaveAssigneeToWorkspace, UserLookup, UserSearchApiResponse } from './model';

export function getSearchResults(groupId: string, inputValue: string): Promise<UserSearchApiResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await http.get<UserSearchApiResponse>(`/dashboards/groups/${encodeURIComponent(groupId)}/users/search?searchTerm=${encodeURIComponent(inputValue)}`);
      resolve(response.map(({ fullName, name, ...rest }: UserLookup) => ({ name: fullName, fullName, ...rest })));
    } catch (e) {
      Logger.captureException(e);
      reject(e);
    }
  });
}

export interface SaveAssigneeResponse {
  allSuccess: boolean;
  successfulWorkspaceIds: Array<string>;
  failedWorkspaceIdsAndReasons: Array<{ workspaceId: string; reason: string }>;
}

export function saveAssignee(assigneeForWorkspaces: SaveAssigneeToWorkspace[]) {
  return http.post<SaveAssigneeResponse>('/workspaces/user-allocations', { UpdatedWorkspaceUserAllocations: assigneeForWorkspaces });
}
