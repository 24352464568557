import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';

import { CaveatorPartyModel, WithdrawalOfCaveat2_26_0Model } from '../../models';
import Caveator from '../caveator';
import { useStyles } from './styles';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
type FormValues = WithdrawalOfCaveat2_26_0Model;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

function CaveatorPartyReadonlyArray({ name, formikProps, disabled, focusRef, dialogPortalId }: Props) {
  const classes = useStyles();

  const renderItem = (_item: CaveatorPartyModel, itemIndex: number, count: number) => {
    const hasMultipleItems = count > 1;
    const itemBindingPath = `${name}[${itemIndex}]`;
    const title = hasMultipleItems ? `Caveator ${itemIndex + 1}` : 'Caveator';

    return (
      <FormGroup title={title} fieldAreaDirection="column" key={`item-caveators[${itemIndex}]`}>
        <Caveator //
          dialogPortalId={dialogPortalId}
          disabled={disabled}
          formikProps={formikProps}
          name={itemBindingPath}
          focusRef={itemIndex === 0 ? focusRef : undefined}
        />
      </FormGroup>
    );
  };

  const { values } = formikProps;
  const items: CaveatorPartyModel[] = _get(values, name) ?? [];
  const count = items.length;

  return (
    <div data-name={name} className={classes.root}>
      {items.map((item, itemIndex) => renderItem(item, itemIndex, count))}
    </div>
  );
}

export default React.memo(CaveatorPartyReadonlyArray);
