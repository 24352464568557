import * as React from 'react';

import { IconProps } from '@sympli/ui-framework/icons';

export const IconTeamWorkload = React.forwardRef(({ fill, ...rest }: IconProps, ref: React.Ref<any>) => {
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...rest}>
      <title>Team Workload</title>
      <defs>
        <polygon id="icon-team-workload-path-1" points="0 0 8 12 17 0"></polygon>
        <polygon id="icon-team-workload-path-3" points="0 0 0 14 12 12 4 0"></polygon>
        <polygon id="icon-team-workload-path-5" points="0 0 0 10 12 12 24 5 24 0"></polygon>
        <polygon id="icon-team-workload-path-7" points="0 14 12 2 0 0"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M65,130 C29.101,130 0,100.899 0,65 C0,29.101 29.101,0 65,0 C100.899,0 130,29.101 130,65 C130,100.899 100.899,130 65,130 Z" fill="#F5F5F5"></path>
        <polygon fill="#D6D6D6" points="103 115 31.005 114.996 31 19 102.995 19.004"></polygon>
        <polygon fill="#FFFFFF" points="101 113 29.005 112.996 29 17 100.995 17.004"></polygon>
        <path
          d="M37,103 L93,103 L93,101 L37,101 L37,103 Z M37,97 L93,97 L93,95 L37,95 L37,97 Z M37,91 L93,91 L93,89 L37,89 L37,91 Z M37,85 L93,85 L93,83 L37,83 L37,85 Z"
          fill="#9A9A9A"
        ></path>
        <polygon fill="#D6D6D6" points="37 79 93 79 93 73 37 73"></polygon>
        <path d="M75,69 L87,69 L87,67 L75,67 L75,69 Z M43,69 L55,69 L55,67 L43,67 L43,69 Z" fill="#D6D6D6"></path>
        <path d="M81,65 C74.373,65 69,59.627 69,53 C69,46.373 74.373,41 81,41 C87.627,41 93,46.373 93,53 C93,59.627 87.627,65 81,65 Z" fill="#18CDB6"></path>
        <path d="M61,53 C61,59.627 55.627,65 49,65 C42.373,65 37,59.627 37,53 C37,46.373 42.373,41 49,41 C55.627,41 61,46.373 61,53" fill="#18CDB6"></path>
        <g transform="translate(73.000000, 41.000000)">
          <mask id="icon-team-workload-mask-2" fill="white">
            <use xlinkHref="#icon-team-workload-path-1"></use>
          </mask>
          <g></g>
          <path
            d="M20,12 C20,18.627 14.627,24 8,24 C1.373,24 -4,18.627 -4,12 C-4,5.373 1.373,0 8,0 C14.627,0 20,5.373 20,12"
            fill="#FF811A"
            mask="url(#icon-team-workload-mask-2)"
          ></path>
        </g>
        <g transform="translate(69.000000, 41.000000)">
          <mask id="icon-team-workload-mask-4" fill="white">
            <use xlinkHref="#icon-team-workload-path-3"></use>
          </mask>
          <g></g>
          <path
            d="M12,24 C5.373,24 0,18.627 0,12 C0,5.373 5.373,0 12,0 C18.627,0 24,5.373 24,12 C24,18.627 18.627,24 12,24 Z"
            fill="#FFC737"
            mask="url(#icon-team-workload-mask-4)"
          ></path>
        </g>
        <g transform="translate(37.000000, 41.000000)">
          <mask id="icon-team-workload-mask-6" fill="white">
            <use xlinkHref="#icon-team-workload-path-5"></use>
          </mask>
          <g></g>
          <path
            d="M24,12 C24,18.627 18.627,24 12,24 C5.373,24 0,18.627 0,12 C0,5.373 5.373,0 12,0 C18.627,0 24,5.373 24,12"
            fill="#FF811A"
            mask="url(#icon-team-workload-mask-6)"
          ></path>
        </g>
        <g transform="translate(37.000000, 51.000000)">
          <mask id="icon-team-workload-mask-8" fill="white">
            <use xlinkHref="#icon-team-workload-path-7"></use>
          </mask>
          <g></g>
          <path
            d="M12,14 C5.373,14 0,8.627 0,2 C0,-4.627 5.373,-10 12,-10 C18.627,-10 24,-4.627 24,2 C24,8.627 18.627,14 12,14 Z"
            fill="#FFC737"
            mask="url(#icon-team-workload-mask-8)"
          ></path>
        </g>
        <polygon fill="#9A9A9A" points="37 31 61 31 61 27 37 27"></polygon>
      </g>
    </svg>
  );
});
