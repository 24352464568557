import React from 'react';

import MessageGrid, { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

interface Props {
  errors?: MessageModel[];
  warnings?: MessageModel[];
}

function ComplianceMessageLists({ errors, warnings }: Props) {
  return (
    <>
      {errors && errors.length > 0 && (
        <MessageGrid //
          variant="error"
          messageHeader="Land registry verification response"
          remarkHeader="Action required"
          messages={errors}
        />
      )}
      {warnings && warnings.length > 0 && (
        <MessageGrid //
          variant="warning"
          messageHeader="Land registry verification response"
          remarkHeader="Action required"
          messages={warnings}
        />
      )}
    </>
  );
}

export default React.memo(ComplianceMessageLists);
