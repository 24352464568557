import { PartyFormConfig, resolveContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { BaseCaveatModel } from '../../models';

export const yupParty = (partyFormConfig: PartyFormConfig) => resolvePartyModelValidationSchema(partyFormConfig);

export const yupPartyBook = (partyFormConfig: PartyFormConfig) =>
  resolveContextBasedPartyBookValidationSchema<BaseCaveatModel>(
    yupParty(partyFormConfig),

    // If the party is not affected proprietor, we do not need detailed validation
    // (Note: If unaffected proprietors are selected in other sections, non-detailed validation (abortEarly: true) should be performed in the validation of those sections.)
    ({ proprietors, mortgagees }, partyIdForCheck) =>
      proprietors?.some(item => item.isSelected && item.partyBookId === partyIdForCheck) ||
      mortgagees?.some(item => item.isSelected && item.partyBookId === partyIdForCheck) ||
      false
  ).defined();
