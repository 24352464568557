import * as React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconExclamationCircle } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  titleResupplyDisabled: boolean;
  tacIsLoading: boolean;
  tackIsDisabled: boolean;
  titleResupplyOnNext: () => void;
  tacOnClick: () => void;
  onShowWarningClick: () => void;
  showWarningLink: boolean;
}

function TitleTopPanel({ titleResupplyDisabled, tacIsLoading, tackIsDisabled, tacOnClick, titleResupplyOnNext, onShowWarningClick, showWarningLink }: Props) {
  const classes = useStyles();

  return (
    <FlexLayout className={classes.root} alignItems="center">
      {showWarningLink && (
        <ButtonLink onClick={onShowWarningClick} icon={<IconExclamationCircle className={classes.showWarningIcon} />}>
          See warnings
        </ButtonLink>
      )}
      <FlexLayout justifyContent="flex-end" alignItems="center" className={classes.rightContainer}>
        <FlexLayout justifyContent="space-between" alignItems="center">
          <WizardStepper disabled={titleResupplyDisabled} onNext={titleResupplyOnNext} nextLabel="Order title information resupply" className={classes.wizardStepper}>
            <SympliButton color="primary" variant="outlined" isLoading={tacIsLoading} disabled={tackIsDisabled} onClick={tacOnClick}>
              Check for title activities
            </SympliButton>
          </WizardStepper>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}

export default TitleTopPanel;
