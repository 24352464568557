import { batch } from 'react-redux';
import { Action } from 'redux';

import { UpdateWorkspaceDocumentApiResponse } from '@sympli/api-gateway/models';

import { actionUpdateDocumentData } from 'src/containers/documents/actions';
import { DocumentDetailApiRequest } from 'src/containers/documents/models';
import {
  actionCleanupWorkspaceDocumentErrors,
  actionUpdateWorkspaceDocumentsStatus,
  UpdateWorkspaceDocumentsStatusRequestModel
} from 'src/containers/workspace/shared/detail/actions';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';

export function docUpdateHelper(dispatch: SafeDispatch<Action>, queryParams: DocumentDetailApiRequest, response: UpdateWorkspaceDocumentApiResponse) {
  const { documentPermissions, documentDenyPermissions, status, hash, pdfUrl, documentActions, documentWorkflow, isFullySignedByAllParticipants, updatedData } = response;
  const { workspaceId, participantId, documentId } = queryParams;
  // make sure we update statuses in the workspace detail
  // update all docs to same status (For Lodgement only), only support single document in WS}
  const statusUpdates: UpdateWorkspaceDocumentsStatusRequestModel = {
    workspaceId,
    participantId,
    documents: [
      //
      { statusId: status, documentId, documentActions, documentPermissions, documentDenyPermissions, isFullySignedByAllParticipants }
    ]
  };
  batch(() => {
    // update document data that will cause transition to new state
    dispatch(
      actionUpdateDocumentData({
        parsedData: JSON.parse(updatedData),
        documentPermissions,
        documentDenyPermissions,
        status,
        hash,
        pdfUrl,
        documentActions,
        documentWorkflow,
        isFullySignedByAllParticipants
      })
    );
    // cleanup document lodgement errors first
    dispatch(actionCleanupWorkspaceDocumentErrors(workspaceId));
    // update status of the document in the workspace detail
    dispatch(actionUpdateWorkspaceDocumentsStatus(statusUpdates));
  });
}
