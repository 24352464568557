import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import columnStyles from './columnStyles';

const styles = (theme: Theme) => {
  return {
    ...columnStyles(theme),
    ...createStyles({
      bold: {
        fontWeight: 'bold'
      }
    })
  };
};

export default styles;
