import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

const useStyles = makeStyles(
  (theme: Theme) => ({
    summaryRow: {
      fontSize: 13,
      paddingTop: 4,
      paddingBottom: 5
    },
    lastSummaryRow: {
      fontWeight: 'bold',
      borderTop: `1px solid ${theme.palette.divider}`,
      marginLeft: 22
    },
    sourceUnbalanced: {
      color: theme.palette.error.main,
      fontWeight: 'bold'
    },
    newSourceUnbalanced: {
      color: colors.RED_NEW,
      fontWeight: 'bold'
    },
    sourceCleared: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    marginLeft: {
      marginLeft: 20
    },
    halfMarginLeft: {
      marginLeft: '50%'
    }
  }),
  {
    name: 'SympliSourceAccountBalanceText'
  }
);

export default useStyles;
