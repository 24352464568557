import { WorkspaceFileStatusEnum } from './enums';

export function hasVirusOrScript(fileStatus: WorkspaceFileStatusEnum) {
  return fileStatus === WorkspaceFileStatusEnum.VirusFound || fileStatus === WorkspaceFileStatusEnum.ScriptFound;
}

export function hasError(fileStatus: WorkspaceFileStatusEnum) {
  return fileStatus === WorkspaceFileStatusEnum.VirusScanError || fileStatus === WorkspaceFileStatusEnum.ScriptScanError;
}

export function hasIssue(fileStatus: WorkspaceFileStatusEnum) {
  return hasVirusOrScript(fileStatus) || hasError(fileStatus);
}
