import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import documentListStyles from '../document-list/styles';

const styles = (theme: Theme) => ({
  ...documentListStyles(theme),
  ...createStyles({
    settlementHelp: {
      fontSize: 13
    },
    awaitingMsg: {
      marginLeft: 3,
      color: '#F58923'
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
