import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    container: {
      paddingTop: 36
    },
    heading: {
      marginBottom: 40
    },
    description: {
      maxWidth: 400,
      marginTop: 12,
      fontSize: 16,
      lineHeight: '20px',
      textAlign: 'center'
    },
    contact: {
      fontSize: 16,
      lineHeight: '20px',
      textAlign: 'center',
      fontWeight: 'bold'
    }
  },
  {
    name: 'Unauthorized'
  }
);
