import * as React from 'react';
import { useCallback, useState } from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { WeekdayEnum } from './models';
import Weekday from './Weekday';

interface Props {
  title?: React.ReactNode;
  getSelectedDays?: (selectedDays: WeekdayEnum[]) => void;
  initialSelectedDays: WeekdayEnum[];
}

function WeekDays({ getSelectedDays, initialSelectedDays, title }: Props) {
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

  const onWeekdayChange = useCallback(
    (weekday: WeekdayEnum, selected: boolean) => {
      let copyOfSelectedDays = selectedDays.concat();

      // if exist in initial list, then we check if it is selected or de-selected
      // if not exist then we add into the list
      const existingWeekday = copyOfSelectedDays.find(x => x === weekday);
      if (existingWeekday == null) {
        copyOfSelectedDays.push(weekday);
      } else {
        copyOfSelectedDays = copyOfSelectedDays.filter(x => x !== weekday);
      }

      setSelectedDays(copyOfSelectedDays);

      getSelectedDays && getSelectedDays(copyOfSelectedDays);
    },
    [selectedDays, setSelectedDays, getSelectedDays]
  );

  return (
    <FlexLayout flexDirection="column">
      {title}
      <FlexLayout flexDirection="row" justifyContent="space-between">
        {Object.values(WeekdayEnum)
          .filter(x => typeof x === 'string')
          .map(d => {
            // pre-select the value
            const isSelected = selectedDays.some(i => i === WeekdayEnum[d]);
            return <Weekday key={d} isSelected={isSelected} weekday={WeekdayEnum[d]} onWeekdayChange={onWeekdayChange} />;
          })}
      </FlexLayout>
    </FlexLayout>
  );
}

export default React.memo(WeekDays);
