import React from 'react';

import { DocumentPermissionEnum, DocumentStatusEnum, DocumentWorkflowTypeEnum, LodgementCaseStatusEnum } from '@sympli/api-gateway/enums';

import { DocumentDetailDataModel, DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import { getDocumentPrerequisite, getFinancialWorkflowStep, getLodgeOnlyWorkflowStep } from '../helpers';

/**
 * !This functionality was extracted from DocumentsPage.tsx to be reused at the container level.
 * !see the history https://github.com/ticklesource/tickle-ui/blame/6c7963e4037613ed184a00d0d0a00bf936dce5c3/src/containers/documents/DocumentsPage.tsx
 */
export function useWorkflowStep({
  documentId,
  status,
  documentWorkflowType,
  lodgementCaseStatusId,
  isFinancial = true,
  isPrimary = true,
  documentPermissions = [],
  displayDocumentAsPdf = false,
  parsedData
}: Partial<{
  // document data
  documentId: string; // explicitly read documentId from data to trigger effect upon loading
  status: DocumentStatusEnum;
  documentWorkflowType: DocumentWorkflowTypeEnum;
  lodgementCaseStatusId: LodgementCaseStatusEnum;
  isFinancial: boolean;
  isPrimary: boolean;
  documentPermissions: DocumentPermissionEnum[];
  displayDocumentAsPdf: boolean;
  parsedData: DocumentDetailDataModel<{ [key: string]: any }>;
}>) {
  const DEFAULT_STEP = DocumentWorkflowStepsEnum.Write;
  const isPrerequisiteNotReady: boolean | undefined = React.useMemo(() => {
    if (parsedData) {
      const documentPrerequisite = getDocumentPrerequisite(parsedData);
      return documentPrerequisite?.isPrerequisiteNotReady === true;
    }
  }, [parsedData]);
  const getWorkflowStep = isFinancial && isPrimary ? getFinancialWorkflowStep : getLodgeOnlyWorkflowStep;
  const [workflowStep, setWorkflowStep] = React.useState<DocumentWorkflowStepsEnum | undefined>(); // explicitly set to undefined to trigger useEffect
  const updateWorkflowStep = React.useCallback(
    (status: DocumentStatusEnum) => {
      if (!(Number.isInteger(documentWorkflowType) && Number.isInteger(lodgementCaseStatusId))) {
        return;
      }

      const nextWorkflowStep = getWorkflowStep(status, documentWorkflowType!, lodgementCaseStatusId!);
      setWorkflowStep(nextWorkflowStep);
    },
    [documentWorkflowType, getWorkflowStep, lodgementCaseStatusId]
  );

  React.useEffect(() => {
    if (documentId && !Number.isInteger(status)) {
      return;
    }
    updateWorkflowStep(status!);
    // * We only want to update workflow step based on document status for different documents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  React.useEffect(() => {
    if (!Number.isInteger(status)) {
      return;
    }
    updateWorkflowStep(status!);
  }, [status, updateWorkflowStep]);

  React.useEffect(() => {
    if (!(Number.isInteger(status) && Number.isInteger(documentWorkflowType) && Number.isInteger(lodgementCaseStatusId))) {
      return;
    }
    const newWorkflowStep = getWorkflowStep(status!, documentWorkflowType!, lodgementCaseStatusId!);
    if (newWorkflowStep === DocumentWorkflowStepsEnum.Lodged) {
      setWorkflowStep(DocumentWorkflowStepsEnum.Lodged);
    }
    // * We need to make sure the step is changed to lodged when lodgementCaseStatusId is updated eg: background updated via realtime server
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgementCaseStatusId]);

  const handleStepChange = React.useCallback((_: React.MouseEvent<HTMLButtonElement>, targetStep: DocumentWorkflowStepsEnum) => {
    setWorkflowStep(targetStep);
  }, []);

  const resolvedStep: DocumentWorkflowStepsEnum = workflowStep ?? DEFAULT_STEP;

  const hasPermissionToEdit = !!documentPermissions.includes(DocumentPermissionEnum.Write);
  const readOnlyViewMode: 'form' | 'pdf' = hasPermissionToEdit ? 'form' : displayDocumentAsPdf ? 'pdf' : 'form';
  const isEditView = [DocumentWorkflowStepsEnum.Write, DocumentWorkflowStepsEnum.Resolve].includes(resolvedStep) && !(readOnlyViewMode === 'pdf' || isPrerequisiteNotReady);

  return {
    //
    workflowStep: resolvedStep,
    onStepClick: handleStepChange,
    updateWorkflowStep,
    isEditView,
    readOnlyViewMode
  };
}
