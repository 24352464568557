import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { TitleReferenceModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
export const PART_LAND_DESCRIPTION_LIMIT = 200;
// path: titleReferences
const yupTitleReferenceItemSchema = yup
  .object<TitleReferenceModel>({
    lessors: yup.mixed(),
    isSelected: yup.boolean().defined(),
    reference: yup.string(),
    partDescription: yup
      .string()
      .trim()
      .testContextualRule({
        uniqueTestName: 'Check if part description is required',
        message: msg.REQUIRED,
        onlyIf: (parent: TitleReferenceModel) => {
          return parent.isSelected && parent.isPartLandAffected === true;
        },
        requirement: (parent: TitleReferenceModel) => {
          return parent.partDescription !== '';
        }
      })
      .max(PART_LAND_DESCRIPTION_LIMIT, ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(PART_LAND_DESCRIPTION_LIMIT)}. ${value.length}/${max} characters`)
  })
  .defined();

export default yupTitleReferenceItemSchema;
