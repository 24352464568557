import * as React from 'react';

import { isSameYear } from 'date-fns';
import dateFormat from 'dateformat';
import Typography from '@mui/material/Typography';

import { AuthorityComplianceReportTypeEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { LodgementCase } from '@sympli/api-gateway/shared';

import { DateFormatEnum } from '../../../../financial/settlement-date/models';
import ActivityLogItem from './components/activity-log-item';
import AuthorityActivityLogItem from './components/authority-activity-log-item';
import { AuthorityComplianceLogType, WorkspaceActivityLogModel } from './models';
import { useStyles } from './styles';

interface Props {
  items: Record<string, Array<WorkspaceActivityLogModel>>;
  workspaceId: string;
  participantId: string;
  workspaceStatus: WorkspaceStatusEnum;
  lodgementCases: LodgementCase[];
  isLinkDisabled?: boolean;
  onShowLodgementVerificationDialog: (authorityMessageId?: string, authorityComplianceReportType?: AuthorityComplianceReportTypeEnum) => void;
  onAccessDenied(): void;
}

const TODAY = new Date();

function TabLogs({
  //
  items,
  workspaceId,
  participantId,
  workspaceStatus,
  lodgementCases,
  isLinkDisabled,
  onShowLodgementVerificationDialog,
  onAccessDenied
}: Props) {
  const classes = useStyles();
  return (
    <>
      {Object.keys(items).map(dateValue => {
        const date = new Date(dateValue);
        const logs = items[dateValue] || [];
        const formattedDate = dateFormat(date, isSameYear(TODAY, date) ? DateFormatEnum.DATEMONTH : DateFormatEnum.DATEMONTHYEAR);
        const noSpaceDate = formattedDate.split(' ').join('-');
        return (
          <React.Fragment key={dateValue}>
            <Typography className={classes.date} variant="subtitle1" data-testid={`workspace-logs-${noSpaceDate}-head`}>
              {formattedDate}
            </Typography>
            <div className={classes.list} data-testid={`workspace-logs-${noSpaceDate}-lists`}>
              {logs.map((log, index) => {
                if (AuthorityComplianceLogType.includes(log.workspaceLogType)) {
                  return (
                    <AuthorityActivityLogItem
                      workspaceId={workspaceId}
                      item={log}
                      participantId={participantId}
                      workspaceStatus={workspaceStatus}
                      key={`${dateValue}-${index}`}
                      onClick={isLinkDisabled ? onAccessDenied : onShowLodgementVerificationDialog}
                      dataTestId={`workspace-logs-${noSpaceDate}-${index + 1}`}
                      lodgementCases={lodgementCases}
                    />
                  );
                }

                return (
                  <ActivityLogItem //
                    isLinkDisabled={isLinkDisabled}
                    workspaceId={workspaceId}
                    key={`${dateValue}-${index}`}
                    item={log}
                    participantId={participantId}
                    dataTestId={`workspace-logs-${noSpaceDate}-${index + 1}`}
                    onAccessDenied={onAccessDenied}
                  />
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default TabLogs;
