import { createAsyncAction } from 'typesafe-actions';

import { SubscriberSigningRule } from '../models';

export const actionFetchSubscriberSigningRule = createAsyncAction(
  //
  'FETCH_SIGNING_RULE',
  'FETCH_SIGNING_RULE_SUCCESS',
  'FETCH_SIGNING_RULE_ERROR'
)<
  //
  undefined,
  { data: SubscriberSigningRule },
  { error: Error }
>();
