import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    buttonOrderTitleInformationResupply: {
      marginLeft: 20
    },
    buttonOrderTitleActivityCheck: {
      marginLeft: 'auto'
    },
    errorMessage: {
      margin: '16px auto'
    }
  }),
  {
    name: 'TitleDetails'
  }
);
