import { call, put, takeLatest } from 'typed-redux-saga';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import http from 'src/utils/http';
import {
  actionFetchAvailableDocumentFeed,
  actionFetchDocumentDefaultFields,
  actionFetchDocumentGroupFeed,
  AvailbleDocumentApiRequest,
  DocumentDefaultFieldsApiRequest
} from '../actions/defaultFieldPopulation';
import { AvailableDocumentApiResponse } from '../reducers/defaultFieldAvailableDocumentFeed';
import { DocumentFieldDetailApiResponse } from '../reducers/defaultFieldPopulationList';

const fetchDocumentGroupFeed = () => http.get<JurisdictionsEnum[]>('/settings/document-default-fields/jurisdictions');

function* sagaFetchDocumentGroupFeed() {
  try {
    const data = yield* call(fetchDocumentGroupFeed);
    yield put(actionFetchDocumentGroupFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchDocumentGroupFeed.failure({ error }));
  }
}

const fetchAvailableDocumentFeed = ({ jurisdictionId: documentGroup }: AvailbleDocumentApiRequest) =>
  http.get<AvailableDocumentApiResponse[]>(`/settings/document-default-fields/jurisdictions/${documentGroup}/documentTypes`);

function* sagaFetchAvailableDocumentFeed(action: ReturnType<typeof actionFetchAvailableDocumentFeed.request>) {
  try {
    const data = yield* call(fetchAvailableDocumentFeed, action.payload);
    yield put(actionFetchAvailableDocumentFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchAvailableDocumentFeed.failure({ error }));
  }
}

const fetchDocumentDefaultFields = ({ jurisdictionId: documentGroup, documentTypeId: documentId }: DocumentDefaultFieldsApiRequest) =>
  http.get<DocumentFieldDetailApiResponse>(`/settings/document-default-fields/jurisdictions/${documentGroup}/documentTypes/${documentId}`);

function* sagaFetchDocumentDefaultFields(action: ReturnType<typeof actionFetchDocumentDefaultFields.request>) {
  try {
    const data = yield* call(fetchDocumentDefaultFields, action.payload);
    yield put(actionFetchDocumentDefaultFields.success({ data }));
  } catch (error) {
    yield put(actionFetchDocumentDefaultFields.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchDocumentGroupFeed.request, sagaFetchDocumentGroupFeed),
  takeLatest(actionFetchAvailableDocumentFeed.request, sagaFetchAvailableDocumentFeed),
  takeLatest(actionFetchDocumentDefaultFields.request, sagaFetchDocumentDefaultFields)
];
