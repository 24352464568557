import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

const EMAIL_MAX_LENGTH = 100;

export const yupEmailAddress = yup //
  .string()
  .nullable(true)
  .default('')
  .trim()
  .email(msg.INVALID_EMAIL)
  .max(EMAIL_MAX_LENGTH, msg.MAX_LENGTH(EMAIL_MAX_LENGTH));
