import { VicDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/vic/2-18/components/party-justification';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ConsiderationModel } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { ApiReceivingTenancyDetailModel, FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Encumbrance$BenefitedLandReferences$ReferenceTypeEnum } from './enums';

// this file was automatically generated from models.ts.mustache
export interface ApiTransferDocumentModel {
  addressBook?: VICDeliveryAddressModel[];
  consideration: ConsiderationModel;
  dutyAssessment?: DutyAssessmentModel;
  encumbrance: EncumbranceModel;
  partyBook: ApiDocumentPartyModel<VicDocumentPartyJustification>[];
  relatedDocumentIds?: string[];
  titleReferences: TitleReferenceModel[];
  transfereeDetails: ApiReceivingTenancyDetailModel;
  workspaceTitleReferences?: string[];
}

export interface VICDeliveryAddressModel {
  addressLine1?: string;
  addressLine2?: string;
  addressSiteName?: string;
  addressType: number | null;
  countryCode: number | null;
  id?: string;
  levelNumber?: string;
  levelType?: number | null;
  locality?: string;
  postcode?: string;
  streetName?: string;
  streetNumber?: string;
  streetType?: number | null;
  unitNumber?: string;
  unitType?: string | number | null;
}

export interface DutyAssessmentModel {
  dateOfSale?: Date | string | null;
  dutiableAmount?: number | null;
  dutyAmount?: number | null;
  dutyAssessmentDate: Date | string | null;
  dutyAssessmentNumber?: string;
  exemption?: boolean;
  exemptionReason?: string;
  firstTransfer?: boolean;
  foreignOwnershipSurcharge?: number | null;
  penaltyTax?: number | null;
  shareIndicator?: boolean;
  sroClientNumber?: string;
  transactionId?: string;
  utiAmount?: number | null;
  vgoValuedIndicator?: boolean;
}

export interface EncumbranceModel {
  isCovenantToBeCreated: boolean;
  benefitedLandReferences?: BenefitedLandReferenceModel[];
  covenantDetails?: CovenantDetailModel[];
  expiryDate?: Date | string | null;
}

export interface BenefitedLandReferenceModel {
  referenceNumber: string;
  referenceType: Encumbrance$BenefitedLandReferences$ReferenceTypeEnum | null;
}

export interface CovenantDetailModel {
  referenceNumber: string;
}

export interface TitleReferenceModel {
  estateType?: string;
  folio?: string;
  isPartLandAffected?: boolean;
  isSelected: boolean;
  landDescriptions?: LandDescriptionModel[];
  reference?: string;
  volume?: string;
  transferorDetails: RelinquishingTenancyDetailModel;
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export interface TenancyPartyWithAddressModel {
  id: string;
  addressBookId?: string;
  partyBookId?: string;
  partyCapacity?: number | null;
  partyCapacityDetail?: string;
  birthDate?: Date | string | null;
}

export interface FractionModel {
  denominator: number | null;
  numerator: number | null;
}

export interface RelinquishingTenancyDetailModel {
  relinquishingProprietorGroups: RelinquishingProprietorGroupModel[];
}

export type RelinquishingTenancyDetailModelWithMeta = RelinquishingTenancyDetailModel & {
  isGroupStructureMeaningful: boolean;
};

export interface RelinquishingProprietorGroupModel {
  parties: TenancyPartyModel[];
  previouslyHeldShareFraction?: FractionModel;
  proprietorGroupType?: number | null;
  shareFraction?: FractionModel;
  shareTransferred?: number | null;
}

export interface TenancyPartyModel {
  partyBookId?: string;
  partyCapacity?: number | null;
}

export type TransferDocumentModel = Omit<ApiTransferDocumentModel, 'partyBook' | 'addressBook' | 'transfereeDetails'> & {
  partyBook?: PartyModel[];
  addressBook?: AddressBookEntityModel[];
  transferorDetails: RelinquishingTenancyDetailModelWithMeta;
  transfereeDetails: FormReceivingTenancyDetailModel;
};

export const TODAY = new Date();
