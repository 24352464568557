import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    dialogPaper: {
      maxWidth: 365
    },
    desc: {
      marginBottom: 4
    },
    firstItem: {
      marginTop: 15,
      marginBottom: 10
    }
  }),
  {
    name: 'LineItemConfirmationDialog'
  }
);
