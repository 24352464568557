import * as React from 'react';

import { DocumentPreviewEnhanced } from 'src/components/document-preview';
import { useDocumentDetail } from 'src/containers/documents/reducers/document';
import { DocumentPageRouteParams } from '../../models';

interface Props {
  queryParams: DocumentPageRouteParams;
}

function DocPreviewContainer({ queryParams }: Props) {
  const { detail, status, error } = useDocumentDetail(queryParams.workspaceId, queryParams.participantId, queryParams.documentId);

  return (
    <DocumentPreviewEnhanced //
      pdfUrl={detail?.pdfUrl}
      error={error?.message}
      status={status}
    />
  );
}

export default React.memo(DocPreviewContainer);
