import * as React from 'react';

import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

function SignedTrustAccountAuthorisationRecord() {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Signed
      </Typography>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        All done!
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The document is now ready for settlement.
      </Typography>
    </>
  );
}

export default React.memo(SignedTrustAccountAuthorisationRecord);
