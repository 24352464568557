import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { WorkspaceFilesApiResponse } from '../workspace-files/models';
import { actionFetchWorkspaceFiles, actionRemoveFileWithIssue, WorkspaceFilesApiRequest } from './actions';

export interface WorkspaceFilesState {
  items: WorkspaceFilesApiResponse[];
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceFilesApiRequest;
}

const initialState: WorkspaceFilesState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useWorkspaceFiles(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.workspaceFiles);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  WorkspaceFilesState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceFiles.request, (state, action): WorkspaceFilesState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchWorkspaceFiles.success,
    (state, action): WorkspaceFilesState => ({
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.items
    })
  )
  .handleAction(
    actionFetchWorkspaceFiles.failure,
    (state, action): WorkspaceFilesState => ({
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    })
  )
  .handleAction(actionRemoveFileWithIssue.request, (state, action): WorkspaceFilesState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...state,
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined
    };
  });

export default reducer;
