import { LinkedWorkspaceOverallStatusEnum } from '@sympli/api-gateway/enums';

import { LinkedWorkspaceParticipant } from '../../models';

export const LinkedWorkspaceOverallStatusEnumMap: Record<LinkedWorkspaceOverallStatusEnum, string> = {
  [LinkedWorkspaceOverallStatusEnum.Ready]: 'READY',
  [LinkedWorkspaceOverallStatusEnum.NotReady]: 'NOT READY'
};

export interface UserProfileDetail {
  userId: string;
  subscriberId: string;
  groupIds: string[];
}
export interface ParticipantsHaveAccessToLinkedWorkspace extends LinkedWorkspaceParticipant {
  workspaceId: string;
}
