import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 12,
      height: 12,
      fontSize: 16,
      lineHeight: '11px',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    }
  }),
  { name: 'IconExpand' }
);
