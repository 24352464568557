import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 12,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      }
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center'
    },
    docName: {
      flex: '1 1'
    }
  }),
  {
    name: 'DocumentVerificationResults'
  }
);
