import { endOfToday, endOfYesterday, isBefore } from 'date-fns';
import dateFormat from 'dateformat';
import * as yup from 'yup';

import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { DATE_PICKER_MIN_DATE } from '@sympli/ui-framework/components/form/base-components/date-picker';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { DeceasedTenancyDetailValidationConfiguration } from '../../config';
import { DateOfDeathModel } from '../../models';
import { DateOfDeathTypeEnum } from './enums';
import { VISIBILITY_CHECK_DATE_OF_DEATH_DATE, VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION, VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE } from './visibilityChecks';

export const END_OF_YESTERDAY = endOfYesterday();
export const END_OF_TODAY = endOfToday();

export const MUST_NOT_BE_AFTER_TODAY = 'Must not be after today';
const DATE_BE_ON_OR_AFTER_X = (date: Date | string): string => `Must be on or after ${dateFormat(typeof date === 'string' ? new Date(date) : date, DateFormatEnum.AUSDATE)}`;

export const yupDateOfDeath = (config?: DeceasedTenancyDetailValidationConfiguration) =>
  yup.object<DateOfDeathModel>({
    type: yup.mixed<DateOfDeathTypeEnum>().required(msg.REQUIRED).defined().oneOf(Object.values(DateOfDeathTypeEnum), msg.REQUIRED),
    date: validateWhenVisible2({
      visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_DATE,
      validationWhenVisible: yupDatePicker //
        .required(msg.REQUIRED)
        .min(config?.minDeathOfDateRange ?? DATE_PICKER_MIN_DATE, ({ min }) => DATE_BE_ON_OR_AFTER_X(min))
        .max(config?.allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY, config?.allowTodayDeathOfDate ? MUST_NOT_BE_AFTER_TODAY : msg.DATE_MUST_BE_PAST_DATE)
    }),
    from: validateWhenVisible2({
      visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
      validationWhenVisible: yupDatePicker //
        .required(msg.REQUIRED)
        .min(config?.minDeathOfDateRange ?? DATE_PICKER_MIN_DATE, ({ min }) => DATE_BE_ON_OR_AFTER_X(min))
        .max(config?.allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY, config?.allowTodayDeathOfDate ? MUST_NOT_BE_AFTER_TODAY : msg.DATE_MUST_BE_PAST_DATE)
    }),
    to: validateWhenVisible2({
      visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
      validationWhenVisible: yupDatePicker //
        .required(msg.REQUIRED)
        .min(config?.minDeathOfDateRange ?? DATE_PICKER_MIN_DATE, ({ min }) => DATE_BE_ON_OR_AFTER_X(min))
        .max(config?.allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY, config?.allowTodayDeathOfDate ? MUST_NOT_BE_AFTER_TODAY : msg.DATE_MUST_BE_PAST_DATE)
        .testContextualRule({
          uniqueTestName: '"deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.toDate" contextual validation rule #1',
          onlyIf: (deathOfDate: DateOfDeathModel, _) => {
            if (!isNotNullOrEmpty(deathOfDate.from)) {
              return false; // Return false if 'from' date is null, undefined, or empty
            }

            const fromDate = deathOfDate.from ? new Date(deathOfDate.from) : null;

            if (!fromDate) {
              return false; // Handle cases where 'fromDate' could not be created
            }

            return isBefore(fromDate, config?.allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY);
          },
          requirement: (deathOfDate: DateOfDeathModel, _): boolean => {
            const fromDate = deathOfDate.from ? new Date(deathOfDate.from) : null;
            const toDate = deathOfDate.to ? new Date(deathOfDate.to) : null;

            if (!fromDate || !toDate) {
              return false; // Return false if either date is null or undefined
            }

            return isBefore(fromDate, toDate);
          },
          message: "'To date' can not be equal to or earlier than 'From date'."
        })
        .defined()
    }),
    description: validateWhenVisible2({
      visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION,
      validationWhenVisible: yup
        .string()
        .default('')
        .trim()
        .nullable()
        .required(msg.REQUIRED)
        .max(200, ({ max }) => msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max))
    })
  });
