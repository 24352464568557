import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    toolTipContent: {
      marginLeft: '3px',
      paddingTop: '1px'
    }
  }),
  {
    name: 'RootForm'
  }
);
