import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingLeft: 12,
      paddingRight: 12,
      minWidth: 400
    },
    header: {
      textAlign: 'center',
      paddingTop: 9
    },
    message: {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: '19px',
      maxWidth: 505,
      paddingTop: 4
    },
    description: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
      paddingTop: 22
    }
  }),
  {
    name: 'Summary'
  }
);
