import { createSelector } from 'reselect';

import { DocumentActionTypeEnum } from '@sympli/api-gateway/enums';

import { DocumentActionModel } from 'src/containers/documents/models';
import { resolveMultipleDocumentActionInfo } from 'src/containers/workspace/shared/detail/helpers';
import { DocumentActionInfo } from 'src/containers/workspace/shared/detail/models';

interface RequiredSignersParams {
  documentActions: Array<DocumentActionModel>;
  requiredSigners: number;
  participantId: string;
}

const getDocumentActions = (param: RequiredSignersParams) => {
  return param.documentActions;
};

const getRequiredSigners = (param: RequiredSignersParams) => {
  return param.requiredSigners;
};

const getParticipantId = (param: RequiredSignersParams) => {
  return param.participantId;
};

export const resolveTaaSignDocumentActionInfoSelector = createSelector<
  //
  RequiredSignersParams,
  DocumentActionModel[],
  number,
  string,
  DocumentActionInfo
>(getDocumentActions, getRequiredSigners, getParticipantId, (documentActions: Array<DocumentActionModel>, requiredSigners: number, participantId: string): DocumentActionInfo => {
  const currentParticipantDocumentActions = documentActions.filter(d => d.participantId === participantId);
  return resolveMultipleDocumentActionInfo(currentParticipantDocumentActions, DocumentActionTypeEnum.Sign, requiredSigners);
});
