import * as yup from 'yup';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { checkSelectedTitlesHaveSameMortgagors } from '../../helper';
import { Mortgage2_24_0Model, MortgagorPartyModel, TitleReferenceModel } from '../../models';

interface MortgagorsContext {
  isFinancialWorkspace: boolean;
  titleReferences: TitleReferenceModel[];
  partyBook: PartyModel[];
  mortgagorsLength: number;
}

const contextResolver = ({ isFinancialWorkspace, titleReferences, partyBook, mortgagors }: Mortgage2_24_0Model): MortgagorsContext => {
  return {
    isFinancialWorkspace,
    titleReferences,
    partyBook: partyBook!,
    mortgagorsLength: mortgagors.length
  };
};

const yupMortgagors = memoizeSchemaWithContext(
  yup
    .array<MortgagorPartyModel, MortgagorsContext>()
    .defined()
    .testContextualRule({
      uniqueTestName: 'Wait for transfer test',
      message: 'Awaiting Transfer document completion',
      requirement: (parent: Mortgage2_24_0Model, ctx: MortgagorsContext) => {
        if (!ctx.isFinancialWorkspace) {
          return true;
        }
        return ctx.mortgagorsLength !== 0;
      }
    })
    .testContextualRule({
      uniqueTestName: 'Mortgagors name mismatch test',
      message: 'Please be aware that the parties on the selected titles presently do not match.',
      requirement: (parent: Mortgage2_24_0Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return checkSelectedTitlesHaveSameMortgagors(partyBook, titleReferences);
      }
    })
    .log(),
  contextResolver
);

export default yupMortgagors;
