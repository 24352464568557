import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { NoticeOfAcquisitionDocumentModel } from '../../models';
import { ERROR_PLEASE_PROVIDE_CONTACT_DETAILS } from '../../sections/transferees/validationSchema';

export const resolveContactDetailsMeta = <
  TModel extends {
    //
    emailAddress?: string;
    contactPhoneNumber?: string;
    missingContactDetails?: any;
  }
>(
  { errors, touched }: FormikProps<NoticeOfAcquisitionDocumentModel>,
  fieldName: (name: keyof TModel & string) => string,
  value: TModel
) => {
  const hasValidEmail = !!value.emailAddress && !_get(errors, fieldName('emailAddress'));
  const hasValidPhone = !!value.contactPhoneNumber && !_get(errors, fieldName('contactPhoneNumber'));
  const phoneIsMandatory = !hasValidEmail; // based on this we decide placeholder for contact phone field
  const emailIsMandatory = !hasValidPhone; // based on this we decide placeholder for email
  const yupContactDetailValidationErorr = _get(errors, fieldName('missingContactDetails')); // real error from vaidation schema

  // we always want to display the message however sometimes we want to have it in red color and sometimes just grey
  const showContactDetailMessageAsError = yupContactDetailValidationErorr && (_get(touched, fieldName('emailAddress')) || _get(touched, fieldName('contactPhoneNumber')));
  // here we get the yup message and yup says it's valid, we still show the message
  const contactDetailMessage = yupContactDetailValidationErorr || ERROR_PLEASE_PROVIDE_CONTACT_DETAILS;

  return {
    phoneIsMandatory,
    emailIsMandatory,
    contactDetailMessage,
    showContactDetailMessageAsError
  };
};
