import * as React from 'react';

import { PortalSource } from '@sympli/ui-framework/components/portal';

import { PORTAL_ID } from './models';

function SettingsTopPanelPortalSource({ children }: React.PropsWithChildren<{}>) {
  return <PortalSource id={PORTAL_ID}>{children}</PortalSource>;
}

export default SettingsTopPanelPortalSource;
