import * as React from 'react';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconArchive } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface ArchiveDialogProps {
  open: boolean;
  onClose: (data: unknown) => void;
}

function ArchiveDialog(props: ArchiveDialogProps) {
  const { open, onClose } = props;

  const classes = useStyles();

  const renderDialogTitle = () => {
    return (
      <FlexLayout flexDirection="row" alignItems="center">
        <IconArchive className={classes.optionIcon} />
        <span>Archive workspace</span>
      </FlexLayout>
    );
  };

  return (
    <ConfirmationDialog title={renderDialogTitle()} showActionButtons={true} open={open} scroll="body" okButtonText="Archive" onClose={onClose}>
      Your workspace will be archived, you can still find this workspace under the archived tab in your dashboard. This action cannot be undone.
    </ConfirmationDialog>
  );
}

export default React.memo(ArchiveDialog);
