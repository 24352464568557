// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'Lodgement in progress', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="01C-//-REGISTRATION-ONLY-(WITH-SIGN-ALL-DOCS)-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Transaction-locked-for-lodgment" transform="translate(-775.000000, -459.000000)">
          <g id="illo-lodging" transform="translate(775.000000, 459.000000)">
            <g id="Group" fill="#F2F2F2" fillRule="nonzero">
              <g id="SVGID_1_-link">
                <circle id="SVGID_1_" cx="65" cy="65" r="65" />
              </g>
              <circle id="Oval" cx="65" cy="65" r="65" />
              <g id="SVGID_3_-link">
                <circle id="SVGID_3_" cx="65" cy="65" r="65" />
              </g>
            </g>
            <g id="Group" transform="translate(8.357143, 51.071429)" fill="#FF811A" fillRule="nonzero">
              <path
                d="M20.8928571,15.7857143 L2.78571429,15.7857143 C1.76428571,15.7857143 0.835714286,14.95 0.835714286,13.8357143 C0.835714286,12.8142857 1.67142857,11.8857143 2.78571429,11.8857143 L20.8928571,11.8857143 C21.9142857,11.8857143 22.8428571,12.7214286 22.8428571,13.8357143 C22.8428571,14.95 22.0071429,15.7857143 20.8928571,15.7857143 Z"
                id="Shape"
              />
              <path
                d="M26,4.27142857 L9.19285714,4.27142857 C8.17142857,4.27142857 7.24285714,3.43571429 7.24285714,2.32142857 C7.24285714,1.3 8.07857143,0.371428571 9.19285714,0.371428571 L26,0.371428571 C27.0214286,0.371428571 27.95,1.20714286 27.95,2.32142857 C27.95,3.43571429 27.1142857,4.36428571 26,4.27142857 Z"
                id="Shape"
              />
              <path
                d="M21.3571429,27.3 L4.55,27.3 C3.52857143,27.3 2.6,26.4642857 2.6,25.35 C2.6,24.3285714 3.43571429,23.4 4.55,23.4 L21.3571429,23.4 C22.3785714,23.4 23.3071429,24.2357143 23.3071429,25.35 C23.3071429,26.4642857 22.4714286,27.3 21.3571429,27.3 Z"
                id="Shape"
              />
            </g>
            <g id="Group" transform="translate(30.642857, 23.214286)">
              <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" points="60.1714286 82.8285714 0.65 82.8285714 14.8571429 0.742857143 75.4 0.742857143" />
              <g fillRule="nonzero" id="Shape">
                <g opacity="0.1" transform="translate(1.857143, 1.857143)" fill="#000000">
                  <polygon points="59.7071429 82.6428571 0.185714286 82.6428571 14.3928571 0.557142857 74.9357143 0.557142857" />
                </g>
                <polygon fill="#FFFFFF" points="60.1714286 82.8285714 0.65 82.8285714 14.8571429 0.742857143 75.4 0.742857143" />
              </g>
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="41.3214286 26.4642857 15.0428571 26.4642857 15.3214286 24.4214286 41.6928571 24.4214286" />
              <polygon id="Shape" fill="#F2F2F2" fillRule="nonzero" points="66.7642857 19.9642857 16.1571429 19.9642857 16.3428571 18.5714286 66.95 18.5714286" />
              <polygon id="Shape" fill="#F2F2F2" fillRule="nonzero" points="60.2642857 54.7857143 10.1214286 54.7857143 10.3071429 53.3928571 60.5428571 53.3928571" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="59.0571429 60.45 9.1 60.45 9.28571429 59.2428571 59.2428571 59.2428571" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="58.5928571 62.7714286 8.72857143 62.7714286 8.91428571 61.5642857 58.8714286 61.5642857" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="50.5142857 65.0928571 8.26428571 65.0928571 8.45 63.8857143 50.7 63.8857143" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="49.7714286 29.0642857 14.5785714 29.0642857 14.7642857 27.95 49.9571429 27.95" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="49.4 31.3857143 14.1142857 31.3857143 14.3928571 30.2714286 49.5857143 30.2714286" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="38.1642857 43.9214286 11.9785714 43.9214286 12.35 41.8785714 38.5357143 41.8785714" />
              <polygon id="Shape" fill="#F2F2F2" fillRule="nonzero" points="63.5142857 37.3285714 13.0928571 37.3285714 13.3714286 36.0285714 63.7928571 36.0285714" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="46.6142857 46.5214286 11.5142857 46.5214286 11.7 45.3142857 46.8 45.3142857" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="46.2428571 48.8428571 11.1428571 48.8428571 11.3285714 47.6357143 46.4285714 47.6357143" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="20.4285714 75.4928571 6.5 75.4928571 6.68571429 74.3785714 20.6142857 74.3785714" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="17.6428571 77.8142857 6.03571429 77.8142857 6.22142857 76.7 17.9214286 76.7" />
              <g transform="translate(16.714286, 6.500000)" fill="#888888" fillRule="nonzero" id="Shape">
                <polygon points="13.2785714 1.67142857 1.48571429 1.67142857 1.57857143 0.835714286 13.3714286 0.835714286" />
                <polygon points="12.6285714 5.10714286 0.835714286 5.10714286 1.02142857 4.27142857 12.8142857 4.27142857" />
                <polygon points="13 3.43571429 1.20714286 3.43571429 1.3 2.6 13.0928571 2.6" />
                <polygon points="12.35 6.77857143 0.557142857 6.77857143 0.742857143 5.94285714 12.5357143 5.94285714" />
              </g>
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="50.8857143 9.84285714 35.5642857 9.84285714 36.0285714 7.24285714 51.35 7.24285714" />
              <polygon id="Shape" fill="#888888" fillRule="nonzero" points="47.9142857 13.2785714 37.3285714 13.2785714 37.7928571 10.7714286 48.3785714 10.7714286" />
              <polygon id="Shape" stroke="#888888" strokeWidth="0.4191" points="56.55 6.40714286 55.0642857 14.5785714 67.6928571 14.5785714 69.1785714 6.40714286" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
