import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root() {
      return {
        position: 'relative'
      };
    },
    heading() {
      return {
        padding: '36px 32px'
      };
    }
  }),
  {
    name: 'MergedDocumentsPdf'
  }
);
