import queryString from 'query-string';

import { DocumentActionTypeEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import RichText from 'src/components/rich-text';
import { DocumentActionModel } from 'src/containers/documents/models';
import { DocumentActionInfo } from './models';

export const hasV2Flag = (): boolean => {
  return window.location.pathname.includes('/v2/') || window.location.search.includes('v2=true');
};

export const hasV3Flag = (): boolean => {
  return window.location.pathname.includes('/v3');
};

export function prefixWithVersion(link: string) {
  if (hasV2Flag()) {
    return `/v2${link}`;
  }
  return link;
}

interface WorkspaceDetailLinkModel {
  workspaceId: string;
  participantId?: string;
}

export function resolveWorkspaceDetailLink(params: WorkspaceDetailLinkModel) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);

  return prefixWithVersion(`/workspace/${encodeURIComponent(workspaceId)}/detail?${query}`);
}

export function resolveBackLink(workspaceType?: WorkspaceTypeEnum) {
  if (workspaceType === WorkspaceTypeEnum.RegistrationOnly) {
    return {
      linkTo: '/',
      title: 'All lodgement only'
    };
  }

  if (workspaceType === WorkspaceTypeEnum.FinancialSettlement) {
    return {
      linkTo: `/financial-workspaces${hasV2Flag() ? '?v2=true' : ''}`,
      title: 'Financial workspaces'
    };
  }

  return {
    linkTo: `/${hasV2Flag() ? '?v2=true' : ''}`,
    title: 'Back to dashboard'
  };
}

export function resolveSingleDocumentActionInfo(documentActions: DocumentActionModel[], actionType: DocumentActionTypeEnum): DocumentActionInfo {
  const actionInfo = documentActions.find(action => action.type === actionType);
  const actionInfos: Array<DocumentActionModel> = [];
  if (actionInfo) {
    actionInfos.push(actionInfo);
  }

  const info: DocumentActionInfo = {
    actionType: actionType,
    documentActions: actionInfos
  };

  return info;
}

export function resolveMultipleDocumentActionInfo(documentActions: Array<DocumentActionModel>, actionType: DocumentActionTypeEnum, numberOfActions: number): DocumentActionInfo {
  const actionInfos = documentActions.filter(action => action.type === actionType);

  const info: DocumentActionInfo = {
    actionType: actionType,
    documentActions: actionInfos,
    numberOfActions: numberOfActions
  };
  return info;
}

export function convertComplianceToMessageGridModel<
  T extends {
    //
    complianceMessage: string;
    guidanceMessagePlain?: string;
    guidanceMessageRich?: string;
    sympliCode?: string;
  }
>(item: T): MessageModel {
  const sympliCodeSuffix: string = item.sympliCode ? ` (${item.sympliCode})` : '';
  return {
    message: item.complianceMessage,
    remark: item.guidanceMessageRich ? <RichText>{`${item.guidanceMessageRich}${sympliCodeSuffix}`}</RichText> : `${item.guidanceMessagePlain || ''}${sympliCodeSuffix}`
  };
}
