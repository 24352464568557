import React, { useState } from 'react';

import classNames from 'classnames';
import _get from 'lodash-es/get';
import Typography from '@mui/material/Typography';

import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import LinkedWorkspaceSearchContainer from 'src/containers/shared/app-bar/components/search-linked-workspace';
import { SearchTableModel } from 'src/containers/shared/app-bar/components/search-linked-workspace/components/linked-workspace-search-table/models';
import { LinkedDistribution } from 'src/containers/workspace/financial/directions/views/edit-directions/components/direction-record/models';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { EditBankDetailsProps } from './EditBankDetails';
import { CommonProps } from './models';
import { useStyles } from './styles';

interface LinkedPaymentDetailsProps {
  itemFieldName: EditBankDetailsProps['itemFieldName'];
  formikProps: EditBankDetailsProps['formikProps'];
  isLoading: EditBankDetailsProps['isLoading'];
  disableSave: boolean;
  workspaceId: string;
  onUpdate: CommonProps['onUpdate'];
}

function LinkedPaymentDetails(props: LinkedPaymentDetailsProps) {
  const classes = useStyles();
  const [selectedLinkedSettlementRow, setSelectedLinkedSettlementRow] = useState<number | undefined>(undefined);
  const { isLoading, disableSave, workspaceId, formikProps, itemFieldName, onUpdate } = props;
  const fieldName = React.useMemo(() => createModelKeyAppender<LinkedDistribution>(itemFieldName), [itemFieldName]);
  const linkedSettlementItemDetailsFieldName = createModelKeyAppender<LinkedDistribution>(fieldName('linkedSettlementItem'));
  const { setFieldValue } = formikProps;
  const disableAmount = _get(formikProps.values, linkedSettlementItemDetailsFieldName('linkedSettlementFundsNotRequired'));

  const handleOnLinkedPaymentCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(fieldName('amount'), event.target.checked ? 0 : 1);
  };

  const handleOnLinkedWorkspaceRowClick = (rowIndex: number, rowData: SearchTableModel) => {
    const { formikProps } = props;
    const linkedSettlementItemField = fieldName('linkedSettlementItem');
    const linkedSettlementItem: LinkedDistribution = _get(formikProps.values, linkedSettlementItemField);
    formikProps.setFieldValue(linkedSettlementItemField, {
      ...linkedSettlementItem,
      linkedWorkspaceId: rowData.workspaceId,
      linkedParticipantId: rowData.participantId,
      linkedToParticipantMatterReference: rowData.reference
    });

    const linkedDetailsField = fieldName('linkedDetails');

    formikProps.setFieldValue(linkedDetailsField, {
      ...linkedSettlementItem,
      linkedWorkspaceId: rowData.workspaceId,
      linkedParticipantId: rowData.participantId,
      linkedToParticipantMatterReference: rowData.reference
    });

    setSelectedLinkedSettlementRow(rowIndex);
  };

  const handleClearSelectedRow = () => {
    setSelectedLinkedSettlementRow(undefined);
  };

  return (
    <FlexLayout alignItems="center" flexWrap="wrap" className={classes.flexGrow}>
      <FlexLayout justifyContent="flex-start" fullWidth alignItems="center">
        <Field //
          label="Amount ($)"
          name={fieldName('amount')}
          component={CurrencyInputField}
          className={classNames(classes.smallField, classes.marginRight, 'w-[315px]')}
          disabled={disableAmount}
        />
      </FlexLayout>
      <FlexLayout flexDirection="row" className="mt-[-6px]">
        <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px]">
          The <b>'Amount'</b> entered is <b>shared across</b>, and can be <b>edited in, both workspaces.</b> A <b>$1.00 placeholder amount</b> has been provided in order to
          complete the link where the final amount is not yet known.
        </Typography>
      </FlexLayout>
      <Field
        component={CheckboxField}
        name={linkedSettlementItemDetailsFieldName('linkedSettlementFundsNotRequired')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnLinkedPaymentCheckboxChange(event)}
        label="Funds not required"
      />
      <FlexLayout flexDirection="row" className="mt-[-6px]">
        <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px] ml-[26px]">
          By checking this option you are choosing not to have any financial demands from the linked workspace. You will still be able to see a line item in both workspaces but
          with $0 value.
        </Typography>
      </FlexLayout>
      <FlexLayout flexDirection="column" fullWidth alignItems="center">
        <LinkedWorkspaceSearchContainer
          selectedRow={selectedLinkedSettlementRow}
          clearSelectedRow={handleClearSelectedRow}
          onRowClick={(rowIndex: number, rowData: SearchTableModel) => handleOnLinkedWorkspaceRowClick(rowIndex, rowData)}
          searchType="excludeLinkedSourceFunds"
          excludeWorkspaceId={workspaceId}
        />
      </FlexLayout>
      <SympliButton
        className={classNames(classes.saveButton, 'w-[64px]')}
        color="primary"
        variant="contained"
        onClick={_ => onUpdate(itemFieldName, formikProps, true)} // For linked settlement, also does not need bsbCheck
        isLoading={isLoading}
        disabled={disableSave || isLoading || selectedLinkedSettlementRow === undefined}
      >
        Link
      </SympliButton>
    </FlexLayout>
  );
}

export default LinkedPaymentDetails;
