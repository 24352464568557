import * as React from 'react';

import _get from 'lodash-es/get';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import StatusBadge, { StatusBadgeColor } from '@sympli/ui-framework/components/status-badge';

import { LodgementCaseLogMap } from 'src/containers/workspace/shared/detail/models';
import { dateTimeLine } from 'src/utils/formatters';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  lodgementCaseStatus: LodgementCaseStatusEnum;
  workspaceStatus?: WorkspaceStatusEnum;
  lodgementCaseLogSortedMap?: LodgementCaseLogMap;
  size?: 'small' | 'medium';
  onClick?: React.MouseEventHandler<any>;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class LodgementStatusBadge extends React.PureComponent<Props> {
  render() {
    const { size, lodgementCaseStatus, workspaceStatus } = this.props;
    const statusMap = this.getStatusMap(lodgementCaseStatus, workspaceStatus, size);
    return (
      <StatusBadge //
        color={statusMap.color}
        iconColor={statusMap.iconColor}
        size={size ?? 'small'}
        variant={statusMap.variant}
        label={statusMap.label}
        tooltip={this.renderTooltipTitle()}
        onClick={this.props.onClick}
      />
    );
  }

  private renderTooltipTitle() {
    const { classes } = this.props;
    const { toolTipTitle, toolTipDescription } = this.resolveToolTipText();
    if (!toolTipTitle && !toolTipDescription) {
      return null;
    }
    return (
      <>
        {toolTipTitle && <Typography className={classes.toolTipTitle}>{toolTipTitle}</Typography>}
        {toolTipDescription && <Typography className={classes.toolTipDescription}>{toolTipDescription}</Typography>}
      </>
    );
  }

  private getStatusMap = (lodgementCaseStatus: LodgementCaseStatusEnum, workspaceStatus?: WorkspaceStatusEnum, size?: 'small' | 'medium'): StatusMap => {
    switch (lodgementCaseStatus) {
      case LodgementCaseStatusEnum.LodgementError:
        return new StatusMap(size === 'small' ? 'Failed' : 'Lodgement failed', 'error');
      case LodgementCaseStatusEnum.LodgementRequested: {
        if (workspaceStatus === WorkspaceStatusEnum.LodgementInQueue) {
          return new StatusMap('Verified', 'primary');
        }
        return new StatusMap('Lodging', 'secondary');
      }
      case LodgementCaseStatusEnum.LodgementSuccess:
        return new StatusMap('Lodged', 'secondary', 'filled');
      case LodgementCaseStatusEnum.Registered:
        return new StatusMap('Registered', 'secondary', 'filled');
      case LodgementCaseStatusEnum.Rejected:
        return new StatusMap('Rejected', 'secondary', 'filled');
      case LodgementCaseStatusEnum.Withdrawn:
        return new StatusMap('Withdrawn', 'secondary', 'filled');

      case LodgementCaseStatusEnum.LodgementVerificationSuccess:
      case LodgementCaseStatusEnum.LodgementTechnicalError:
      case LodgementCaseStatusEnum.LodgementConnectionError: {
        // WEB-2367 Consider LodgementConnectionError as Verified status for display
        // User can lodge again from the Lodgement progress tab incase of connection failure at the time of lodgement
        return new StatusMap('Verified');
      }
      case LodgementCaseStatusEnum.LodgementVerificationRequested:
        return new StatusMap('Verifying', 'secondary');
      case LodgementCaseStatusEnum.LodgementVerificationError:
        return new StatusMap('Verification failed', 'error');
      case LodgementCaseStatusEnum.Unverified:
      case LodgementCaseStatusEnum.AwaitingInvitee:
        return new StatusMap('Unverified', 'secondary', 'filled');
      case LodgementCaseStatusEnum.Unnecessary:
        return new StatusMap('Unnecessary', 'secondary', 'filled');
      default: {
        return new StatusMap(lodgementCaseStatus, 'secondary');
      }
    }
  };

  //ToDo: This mapping also should move to single place -> getStatusMap
  private resolveToolTipText = () => {
    const { lodgementCaseStatus } = this.props;
    let toolTipTitle = '',
      toolTipDescription = '';
    const timeSuffix = this.getStatusTimeSuffix(lodgementCaseStatus);
    switch (lodgementCaseStatus) {
      case LodgementCaseStatusEnum.Unverified:
      case LodgementCaseStatusEnum.AwaitingInvitee: {
        toolTipTitle = 'Unverified';
        toolTipDescription = 'Lodgement verification will be performed when all documents in the workspace have been approved & stamp duty has been verified.';
        break;
      }
      case LodgementCaseStatusEnum.LodgementVerificationRequested: {
        toolTipTitle = 'Verifying with the land registry';
        break;
      }
      case LodgementCaseStatusEnum.LodgementVerificationSuccess:
      case LodgementCaseStatusEnum.LodgementTechnicalError:
      case LodgementCaseStatusEnum.LodgementConnectionError: {
        // WEB-2367 Consider LodgementConnectionError as Verified status for display
        // User can lodge again from the Lodgement progress tab incase of connection failure at the time of lodgement
        toolTipTitle = `Verified ${timeSuffix}`;
        break;
      }
      case LodgementCaseStatusEnum.LodgementVerificationError: {
        toolTipTitle = `Verification last attempted ${timeSuffix}`;
        toolTipDescription = 'Lodgement verification will be reattempted when all documents in the workspace have been approved.';
        break;
      }
      case LodgementCaseStatusEnum.LodgementRequested: {
        toolTipTitle = 'Lodgement in progress';
        break;
      }
      case LodgementCaseStatusEnum.LodgementError: {
        toolTipTitle = `Lodgement attempted ${timeSuffix}`;
        break;
      }
      case LodgementCaseStatusEnum.LodgementSuccess: {
        toolTipTitle = `Lodged ${timeSuffix}`;
        break;
      }
      case LodgementCaseStatusEnum.Registered: {
        const lodgedTimeSuffix = this.getStatusTimeSuffix(LodgementCaseStatusEnum.LodgementSuccess);
        toolTipTitle = `Registered ${timeSuffix}`;
        toolTipDescription = `Lodged ${lodgedTimeSuffix}`;
        break;
      }
      case LodgementCaseStatusEnum.Rejected: {
        const lodgedTimeSuffix = this.getStatusTimeSuffix(LodgementCaseStatusEnum.LodgementSuccess);
        toolTipTitle = `Rejected ${timeSuffix}`;
        toolTipDescription = `Lodged ${lodgedTimeSuffix}`;
        break;
      }
      case LodgementCaseStatusEnum.Withdrawn: {
        const lodgedTimeSuffix = this.getStatusTimeSuffix(LodgementCaseStatusEnum.LodgementSuccess);
        toolTipTitle = `Withdrawn ${timeSuffix}`;
        toolTipDescription = `Lodged ${lodgedTimeSuffix}`;
        break;
      }
      case LodgementCaseStatusEnum.Unnecessary: {
        const lodgedTimeSuffix = this.getStatusTimeSuffix(LodgementCaseStatusEnum.LodgementSuccess);
        toolTipTitle = `Unnecessary ${timeSuffix}`;
        toolTipDescription = `Lodged ${lodgedTimeSuffix}`;
        break;
      }
      default: {
        break;
      }
    }

    return { toolTipTitle, toolTipDescription };
  };

  private getStatusTimeSuffix(status: LodgementCaseStatusEnum) {
    const { lodgementCaseLogSortedMap } = this.props;
    const lastStatusTime = _get(lodgementCaseLogSortedMap, `${status}[0].createAt`) as unknown as string | number | Date;
    const dateTime = new Date(lastStatusTime);
    const now = new Date();
    let suffix = '';
    if (isNaN(dateTime.getDay())) {
      return suffix;
    }
    suffix = ` on ${dateTimeLine(dateTime, 'd mmm yyyy, "at" h:MM TT')}`;
    const msAgo = now.valueOf() - dateTime.valueOf();
    const minAgo = Math.round(msAgo / 60000);
    const hrAgo = Math.round(minAgo / 60);
    if (minAgo > 0 && minAgo < 60) {
      const roundedMin = Math.round(Math.abs(minAgo));
      suffix = ` ${roundedMin} ${pluralize('min', roundedMin)} ago`;
    } else if (hrAgo > 0 && hrAgo < 24) {
      const roundedHr = Math.round(Math.abs(hrAgo));
      suffix = ` ${roundedHr} ${pluralize('hour', roundedHr)} ago`;
    }
    return suffix;
  }
}

class StatusMap {
  constructor(
    //
    public label: string,
    public color: StatusBadgeColor = 'primary',
    public variant: 'filled' | 'outlined' = 'outlined',
    public iconColor: StatusBadgeColor | undefined = undefined
  ) {}
}

export default withStyles(styles)(LodgementStatusBadge);

// REVIEW: refactor this into nice map, this is a nightmare
