import * as yup from 'yup';

import { WithdrawalOfCaveat2_21_1Model } from './models';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupWithdrawCaveats from './sections/withdraw-caveat/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<WithdrawalOfCaveat2_21_1Model>()
  .shape({
    titleReferences: yupTitleReferences,
    withdrawCaveats: yupWithdrawCaveats,
    partyBook: yupPartyBook
  })
  .defined()
  .log();
