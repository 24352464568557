import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    expandIconContent: {
      margin: '2px 0',
      fontSize: 14,
      width: 16,
      height: 16,
      borderRadius: 4,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.contrastText
    },
    errorExpandIcon: {
      backgroundColor: theme.palette.error.main
    },
    warningExpandIcon: {
      backgroundColor: theme.palette.secondary.main
    },
    infoExpandIcon: {
      backgroundColor: theme.palette.text.secondary
    },
    expandContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    expandText: {
      textDecoration: 'underline',
      marginRight: 4,
      lineHeight: '20px'
    },
    // override MuiAccordionSummary
    root: {
      padding: 12,
      minHeight: 0,
      backgroundColor: theme.palette.common.white,
      '&$expanded': {
        backgroundColor: 'transparent', //theme.palette.grey[50],
        minHeight: 0
      }
    },
    content: {
      margin: 0,
      '&$expanded': {
        margin: 0
      }
    },
    expandIconWrapper: {
      position: 'absolute',
      right: 10,
      marginRight: 0,
      '&$expanded': {
        transform: 'unset'
      }
    },
    expanded: {}
  }),
  {
    name: 'AccordionSummary'
  }
);
