import pluralize from 'pluralize';

import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { SimpleSnackbarNotificationProps } from 'src/components/global-simple-notification/simple-snackbar-notification';

export enum SuccessMessageEnum {
  InvitationAccepted,
  JoinWorkspace,
  InvitationResent,
  InvitationForwarded,
  InvitationWithdrawn,
  InvitationDeclined,
  Assigned
}

export enum WarningMessageEnum {
  NoteReassign,
  Processing,
  SomethingWentWrong,
  SomethingHasChanged,
  NotActioned,
  AccessDenied
}

export enum ErrorMessageEnum {
  InvitationNotResent
}

export function getSuccessMessage(message: SuccessMessageEnum.InvitationAccepted | SuccessMessageEnum.JoinWorkspace, data: string): Omit<SimpleSnackbarNotificationProps, 'open'>;
export function getSuccessMessage(message: SuccessMessageEnum.InvitationResent): Omit<SimpleSnackbarNotificationProps, 'open'>;
export function getSuccessMessage(message: SuccessMessageEnum.InvitationForwarded, data: { groupName: string }): Omit<SimpleSnackbarNotificationProps, 'open'>;
export function getSuccessMessage(message: SuccessMessageEnum.InvitationWithdrawn): Omit<SimpleSnackbarNotificationProps, 'open'>;
export function getSuccessMessage(message: SuccessMessageEnum.InvitationDeclined): Omit<SimpleSnackbarNotificationProps, 'open'>;
export function getSuccessMessage(message: SuccessMessageEnum.Assigned, data: { count: number; assignTo: string }): Omit<SimpleSnackbarNotificationProps, 'open'>;
export function getSuccessMessage(message: SuccessMessageEnum, data: any = {}): Omit<SimpleSnackbarNotificationProps, 'open'> {
  switch (message) {
    case SuccessMessageEnum.InvitationAccepted:
    case SuccessMessageEnum.JoinWorkspace:
      return {
        message: (
          <div className="max-w-[750px] break-words">
            <b>Workspace Joined.</b> Ready to collaborate on {data}
          </div>
        )
      };
    case SuccessMessageEnum.InvitationResent:
      return {
        message: <b>Invitation Resent.</b>,
        secondaryMessage: 'The invite has been successfully resent'
      };
    case SuccessMessageEnum.InvitationForwarded:
      return {
        message: <b>Invitation Forwarded.</b>,
        secondaryMessage: `The invite has been successfully forwarded to ${data.groupName}`
      };
    case SuccessMessageEnum.InvitationWithdrawn:
      return {
        message: <b>Invitation Withdrawn.</b>,
        secondaryMessage: 'The invite has been successfully withdrawn'
      };
    case SuccessMessageEnum.InvitationDeclined:
      return {
        message: <b>Invitation Declined.</b>,
        secondaryMessage: 'The invite has been successfully declined'
      };
    case SuccessMessageEnum.Assigned:
      return {
        message: <b>Assigned!</b>,
        secondaryMessage: `${pluralize('invitation', data.count, true)} ${data.count > 1 ? 'have' : 'has'} been assigned to ${data.assignedTo}`
      };
    default:
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Invalid message format', SuccessMessageEnum[message]), scope);
      return {
        message: <b>Success</b>
      };
  }
}

export function getWarningNotification(message: WarningMessageEnum): Omit<SimpleSnackbarNotificationProps, 'open'> {
  switch (message) {
    case WarningMessageEnum.NoteReassign:
      return {
        message: <b>Note:</b>,
        secondaryMessage: 'You can only reassign invitations from one page at the time, your previous selections have been cleared'
      };
    case WarningMessageEnum.Processing:
      return {
        message: 'Processing...'
      };
    case WarningMessageEnum.SomethingWentWrong:
      return {
        message: <b>Something went wrong.</b>,
        secondaryMessage: 'We have encountered Technical Issues, please try again'
      };
    case WarningMessageEnum.SomethingHasChanged:
      return {
        message: <b>Something has changed.</b>,
        secondaryMessage: "We couldn't process one or more of your selected Invitations"
      };
    case WarningMessageEnum.NotActioned:
      return {
        message: <b>Invitation not actioned.</b>,
        secondaryMessage: 'The invite was withdrawn by another participant.'
      };
    case WarningMessageEnum.AccessDenied:
      return {
        message: <b>Access denied.</b>,
        secondaryMessage: 'You do not have permission to access this page. Please contact your admin.'
      };
    default:
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Invalid message format', WarningMessageEnum[message]), scope);
      return {
        message: 'Warning'
      };
  }
}

export function getErrorNotification(message: ErrorMessageEnum): Omit<SimpleSnackbarNotificationProps, 'open'> {
  switch (message) {
    case ErrorMessageEnum.InvitationNotResent:
      return {
        message: <b>Invitation not resent.</b>,
        secondaryMessage: 'Invitations can only be resent once in a 24 hour period'
      };
    default:
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Invalid message format', ErrorMessageEnum[message]), scope);
      return {
        message: 'Error'
      };
  }
}
