import * as React from 'react';

import { Action } from 'redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { UserRoleEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import MoreActionButton from '@sympli/ui-framework/components/more-action-button';

import { actionUpdateSuspendConfirmDialog, actionUpdateUserSuspension } from 'src/containers/settings/settings/actions/users';
import { UserDetailsState } from 'src/containers/settings/settings/reducers/userDetails';
import { UserSuspensionState } from 'src/containers/settings/settings/reducers/userSuspension';
import IconReactivate from 'src/containers/settings/settings/users-detail/icons/IconReactivate';
import IconSuspend from 'src/containers/settings/settings/users-detail/icons/IconSuspend';
import SuspensionDialog from 'src/containers/settings/settings/users-setting/components/suspension-dialog';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  userDetails: UserDetailsState;
  userSuspension: UserSuspensionState;
  userProfile: UserProfileModel;
  // route params
  userId: string;

  // other
  isEditMode: boolean;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class UserMoreActionButton extends React.PureComponent<Props> {
  render() {
    return (
      <>
        {this.renderConfirmationDialog()}
        {this.renderMoreActionButton()}
      </>
    );
  }

  private renderMoreActionButton() {
    const {
      isEditMode,
      classes,
      userDetails,
      userProfile,
      userSuspension: { status: userSuspensionStatus }
    } = this.props;

    const { detail, status: userDetailsStatus } = userDetails;

    if (userDetailsStatus === 'pending' || userSuspensionStatus === 'pending') {
      return (
        <div className={classes.paddingLeft}>
          <InlineLoader size={24} />
        </div>
      );
    }
    if (detail == null || !isEditMode) {
      return null;
    }
    const { userId, role, isSSOEnabled } = userProfile;

    // WEB-21680, if a user is sso enabled, do not return the action button
    if (isSSOEnabled) {
      return null;
    }

    // Disabled if user is trying to suspend self or is of lower privilege than the suspension target.
    const disabled = userId === detail.id || (role === UserRoleEnum.Admin && detail.role === UserRoleEnum.SuperAdmin);

    return (
      <MoreActionButton disabled={disabled}>
        <MenuItem onClick={this.handleOnSuspendClick}>{this.renderUserSuspendOption(detail.isSuspended!)}</MenuItem>
      </MoreActionButton>
    );
  }

  private renderUserSuspendOption(isSuspended: boolean) {
    const { classes } = this.props;
    if (isSuspended) {
      return (
        <React.Fragment>
          <IconReactivate className={classes.suspendIcon} />
          <Typography>Reactivate user</Typography>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <IconSuspend className={classes.suspendIcon} />
        <Typography>Suspend user</Typography>
      </React.Fragment>
    );
  }

  private renderConfirmationDialog() {
    const {
      userSuspension,
      userDetails: { detail }
    } = this.props;

    if (detail == null) {
      return null;
    }
    const { isConfirmDialogOpen, status } = userSuspension;
    return <SuspensionDialog isSuspended={detail.isSuspended!} open={isConfirmDialogOpen} onClose={this.handleOnDialogClose} apiStatus={status} />;
  }

  private handleOnSuspendClick = () => {
    this.props.dispatch(actionUpdateSuspendConfirmDialog({ isOpen: true }));
  };

  private handleOnDialogClose = (confirm?: boolean) => {
    const {
      userId,
      userDetails: { detail },
      dispatch
    } = this.props;

    if (detail != null && confirm) {
      dispatch(actionUpdateUserSuspension({ id: userId, isSuspended: !detail.isSuspended }));
    } else {
      dispatch(actionUpdateSuspendConfirmDialog({ isOpen: false }));
    }
  };
}

const styledComponent = withStyles(styles)(UserMoreActionButton);

export default styledComponent;
