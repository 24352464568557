import * as React from 'react';

import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import Table, { ColumnsModel } from '@sympli/ui-framework/components/table';
import { IconCalendar } from '@sympli/ui-framework/icons';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchReportsDashboard } from 'src/containers/dashboard/reports/actions';
import { useDeleteDialog } from 'src/containers/dashboard/reports/hooks/useDeleteDialog';
import { useSchedulerDialog } from 'src/containers/dashboard/reports/hooks/useSchedulerDialog';
import { SavedReportModel, SchedulerFormikModel } from 'src/containers/dashboard/reports/models';
import { useSafeDispatch } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import ReportSchedulerDialog from '../../../report-scheduler-dialog';
import ReportDeleteDialog from '../report-favourite-table/components/report-delete-dialog';
import { formatReportGeneratedTime, formatReportNameColumn, formatReportTypeColumn, formatScheduledReportEditDeleteColumn } from '../report-favourite-table/formatters';
import ReportSectionWrapper, { ReportSectionHeader } from '../report-section-wrapper';
import { useStyles } from './styles';

export interface Props {
  reports: Array<SavedReportModel>;
  savedReportsStatus: ApiStatus;
}

type FormModel = SchedulerFormikModel;

function ReportScheduledTable({ reports, savedReportsStatus }: Props) {
  const { deleteReportData, isDeleteDialogOpen, isDeleteDialogLoading, rowClickHandler, deleteDialogClickHandler, deleteDialogCloseHandler } = useDeleteDialog();
  const { schedulerInitialValues, isSchedulerDialogOpen, schedulerDialogCloseHandler, schedulerDialogClickHandler } = useSchedulerDialog();
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());

  const columns: ColumnsModel<SavedReportModel> = {
    name: {
      label: 'Report name',
      className: classes.reportName,
      formatter: formatReportNameColumn
    },
    reportType: {
      label: 'Report type',
      formatter: formatReportTypeColumn,
      className: classes.reportTypes
    },
    reportGeneratedTime: {
      label: 'Report generated (all times in AEST/AEDT)',
      className: classes.reportGeneratedTime,
      formatter: formatReportGeneratedTime
    },
    buttons: {
      label: '',
      formatter: formatScheduledReportEditDeleteColumn,
      className: classes.buttons
    }
  };

  const handleOnPostSubmit = (args: FormikPostSubmitArgs<FormModel>) => {
    if (args.error) {
      return;
    }
    //close the dialog
    schedulerDialogCloseHandler();
    dispatch(
      actionOpenGlobalSimpleNotification({
        //
        message: 'Report has been scheduled',
        autoHideDuration: 3000,
        variant: 'new-success'
      })
    );
    dispatch(actionFetchReportsDashboard.request());
  };

  const renderTable = () => {
    if (savedReportsStatus === 'pending' || savedReportsStatus === 'idle') return <BlockLoader />;

    return reports?.length ? (
      <Table //
        pagination={false}
        sortable={false}
        rows={reports}
        columns={columns}
        bordersWithSpacing={true}
        context={{ onDeleteClick: deleteDialogClickHandler, onEditClick: schedulerDialogClickHandler }}
        onRowClick={rowClickHandler}
      />
    ) : (
      <Typography className={classes.emptyReportList}>Select from any of the reports above to set a regular schedule for a report.</Typography>
    );
  };

  return (
    <>
      <ReportSectionWrapper //
        title={
          <ReportSectionHeader className={classes.sectionHeader}>
            <IconCalendar className={classes.sectionIcon} /> Scheduled reports
          </ReportSectionHeader>
        }
        className={classes.titleWrapper}
      >
        {renderTable()}
      </ReportSectionWrapper>
      <ReportDeleteDialog //
        isLoading={isDeleteDialogLoading}
        isOpen={isDeleteDialogOpen}
        onClose={deleteDialogCloseHandler}
        reportFeatureType={deleteReportData.reportFeatureType}
        reportName={deleteReportData.reportName}
      />
      <ReportSchedulerDialog //
        initialValues={schedulerInitialValues}
        isOpen={isSchedulerDialogOpen}
        onClose={schedulerDialogCloseHandler}
        onPostSubmit={handleOnPostSubmit}
      />
    </>
  );
}

export default React.memo(ReportScheduledTable);
