import { ProfileImageModel } from 'src/containers/shared/profile-image/models';

export interface UpdateUserProfileApiRequest {
  jobTitle: string;
  timezone: string;
  mobileNumber: string;
  work: string;
  avatarUrl: string;
  profileImage?: ProfileImageModel;
}

export interface UserProfileForm {
  jobTitle: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;

  mobileNumber: string;
  work: string;
  timezone: string;
  avatarUrl: string;
  profileImage?: ProfileImageModel;
}

export const DEFAULT_USER_PROFILE: UserProfileForm = {
  jobTitle: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',

  mobileNumber: '',
  work: '',
  timezone: '',
  avatarUrl: ''
};
