// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      padding: `15px 20px 5px 20px`
    },
    dealingNumber: {
      display: 'inline-block',
      position: 'absolute'
    },
    singleDealingNumber: {
      paddingLeft: 270
    },
    multipleDealingNumber: {
      paddingLeft: 246
    }
  }),
  {
    name: 'WithdrawCaveat'
  }
);
