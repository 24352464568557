import * as React from 'react';

import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import AutocompleteField from '@sympli/ui-framework/components/formik/autocomplete-field';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconSearch } from '@sympli/ui-framework/icons';

import ErrorMessage from 'src/components/error-message';
import { RepresentationSubscriberTypeEnum } from 'src/containers/dashboard/components/create-new-workspace/models';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import InvitationDescription from 'src/containers/shared/invitation-description';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { InvitationModel, WorkspaceInviteParticipantsFormModel } from '../../../../models';
import { getSubscriberSuggestion } from '../../api';
import { InvitationItemModel, SubscriberSearchItemModel } from '../../models';
import GroupAndMatterNumber from '../group-and-matter-number/GroupAndMatterNumber';
import { useStyles } from './styles';

interface OwnProps {
  isInteroperable?: boolean;
  jurisdictionId: JurisdictionsEnum;
  groups: GroupOptionModel[];
  name: string;
  isAdditionalInvitation?: boolean;
  representationSubscriberType?: RepresentationSubscriberTypeEnum;
}

function Invitation({ isInteroperable, jurisdictionId, groups, name, isAdditionalInvitation = false, representationSubscriberType }: OwnProps) {
  const formikProps = useFormikContext<WorkspaceInviteParticipantsFormModel>();
  const { setFieldValue, isSubmitting } = formikProps;
  const fieldName = createModelKeyAppender<InvitationModel>(name);
  const dataAttrName = fieldName('invitation');
  const dataFieldName = createModelKeyAppender<InvitationItemModel>(dataAttrName);
  const [, { value }] = useField<InvitationModel>(name);
  const searchingStartAtCharacter: number = 3;
  const classes = useStyles();
  const subscriberItemPath = dataFieldName('subscriber');
  const errorMessageId = subscriberItemPath + '-error';

  const renderInviteOtherField = () => {
    const subscriberFieldName = createModelKeyAppender<SubscriberSearchItemModel>(subscriberItemPath);
    const subscriberResolvedBindingName = subscriberFieldName('name');
    const handleOnSuggestionSelected = (e: React.ChangeEvent<HTMLInputElement>, selectedSuggestion: SubscriberSearchItemModel, suggestionValue?: any) => {
      const subscriber: SubscriberSearchItemModel = {
        id: selectedSuggestion.id,
        name: selectedSuggestion.name,
        elnoId: selectedSuggestion.elnoId,
        elnoSubscriberId: selectedSuggestion.elnoSubscriberId, // Sympli subscribers will not have this populated
        abn: selectedSuggestion.abn
      };
      setFieldValue(subscriberItemPath, subscriber, true);
    };

    return (
      <FlexLayout flexDirection="row" fullWidth>
        <div className={classes.searchInviteSubscriber}>
          <Field //
            label="Search to invite subscriber"
            name={subscriberFieldName('id')}
            resolvedValueName={subscriberResolvedBindingName}
            component={AutocompleteField}
            getSuggestions={(name: string) => getSubscriberSuggestion(name, value.role!, jurisdictionId, isInteroperable, representationSubscriberType)}
            onSuggestionSelected={handleOnSuggestionSelected}
            placeholder="Start typing"
            endAdornment={<IconSearch style={{ marginRight: 4 }} />}
            searchingStartsAt={searchingStartAtCharacter}
            className={classes.searchContainer}
            classes={{ suggestionsContainer: classes.suggestionsContainer }}
            noDataMessage={<Typography className={classes.noDataMessage}>No results found. Please check your spelling and try again.</Typography>}
            disabled={isSubmitting}
            aria-describedby={errorMessageId}
          />
          <ErrorMessage name={subscriberItemPath} formikProps={formikProps}></ErrorMessage>
        </div>
        <InvitationDescription //
          name={dataFieldName('invitationDescription')}
          className={classes.invitationDescription}
          label="Add description to invitation (Optional)"
          fieldOnly
          disabled={isSubmitting}
        />
      </FlexLayout>
    );
  };

  const renderGroupField = () => {
    return <GroupAndMatterNumber name={dataAttrName} jurisdiction={jurisdictionId} disabled={isSubmitting} groups={groups} />;
  };

  return (
    <ArrowBox
      className={
        isAdditionalInvitation //
          ? classes.additionalInvitationArrowBox
          : classes.invitationArrowBox
      }
    >
      <FlexLayout //
        fullWidth
        flexDirection="column"
        className={classNames(classes.fieldLine)}
        justifyContent="flex-start"
      >
        <Field //
          label="Add this role to my Subscriber"
          name={dataFieldName('inviteRoleAsSelf')}
          component={CheckboxField}
          className={classes.checkbox}
          disabled={isSubmitting}
        />
        {value.invitation.inviteRoleAsSelf //
          ? renderGroupField()
          : renderInviteOtherField()}
      </FlexLayout>
    </ArrowBox>
  );
}

export default React.memo(Invitation);
