import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    },
    taskItem: {
      borderLeft: `3px solid ${colors.SUNNY_DAY_LUMINOUS}`,
      backgroundColor: colors.SUNNY_DAY_TRANSLUCENT,
      padding: '12px',
      boxSizing: 'border-box',
      height: 44,
      alignItems: 'center',
      cursor: 'pointer',
      '& + $taskItem': {
        marginTop: 4
      }
    },
    taskItemError: {
      padding: '6px 12px',
      borderLeft: `3px solid ${colors.WATERMELON_LUMINOUS}`,
      backgroundColor: colors.WATERMELON_TRANSLUCENT
    },
    disabled: {
      border: `1px solid ${theme.palette.action.disabled}`,
      backgroundColor: theme.palette.action.disabledBackground,
      cursor: 'not-allowed'
    },
    disabledText: {
      color: theme.palette.action.disabled,
      cursor: 'not-allowed'
    },
    taskItemDescription: {
      flexGrow: 1,
      flexBasis: 'min-content',
      textDecoration: 'unset'
    },
    descriptionFirstSection: {
      fontFamily: 'Roboto',
      color: colors.NEUTRAL_700,
      fontStyle: 'normal',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
      display: 'inline'
    },
    descriptionSecondSection: {
      fontFamily: 'Roboto',
      color: colors.NEUTRAL_500,
      fontStyle: 'normal',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
      display: 'inline'
    },
    overdueWarning: {
      fontFamily: 'Roboto',
      color: colors.NEUTRAL_600,
      fontStyle: 'normal',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      marginRight: 4,
      marginTop: 0,
      textAlign: 'right'
    },
    overdueText: {
      fontFamily: 'Roboto',
      color: colors.NEUTRAL_600,
      fontStyle: 'normal',
      fontSize: 10,
      fontWeight: 500,
      lineHeight: '14px',
      textTransform: 'uppercase',
      textAlign: 'right',
      marginRight: 4,
      marginTop: 0
    },
    proposedSettlementDateTime: {
      fontFamily: 'Roboto',
      color: colors.NEUTRAL_600,
      fontStyle: 'normal',
      fontSize: 14,
      lineHeight: '20px',
      textAlign: 'right'
    },
    marginRight: {
      marginRight: 16
    }
  });

export type ClassKeys = typeof styles;

export default styles;
