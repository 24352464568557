import { SaDocumentPartyJustification, SaNameChange } from '@sympli-mfe/document-components/party-form/sa/2-26/components/party-justification';
import { AddressBookEntityModel, AddressMetadata } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentItemModel, DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { ApiReceivingTenancyDetailModel, FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FormTenancyDetailModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

// this file was automatically generated from models.ts.mustache
export interface ApiMortgage_2_26_0_Model {
  addedAddressBookAddresses?: string[];
  addedPartyBookParties?: string[];
  addressBook?: DocumentDeliveryAddressModel[];
  mortgagors: TenancyPartyModel[];
  mortgageeDetails: ApiReceivingTenancyDetailModel;
  partyBook?: ApiDocumentPartyModel<SaDocumentPartyJustification>[];
  termsAndConditions: StandardTermsModel;
  titleReferences: TitleReferenceModel[];
  workspaceTitleReferences?: string[];
  proprietors: any; // Do not use. Used only by backend.
  needToGenerateMortgagors: boolean;
  transferees: TenancyPartyModel[];
  //Refers to all attachments of the document
  attachments?: DocumentAttachmentItemModel[];
}

export interface DocumentDeliveryAddressModel {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  addressSiteName?: string;
  addressType: number | null;
  countryCode: number | null;
  id?: string;
  isUnstructuredAddress?: boolean;
  levelNumber?: string;
  levelType?: number | null;
  locality?: string;
  lotNumber?: string;
  overseasPostcode?: string;
  postalDeliveryNumber?: string;
  postalDeliveryType?: number | null;
  postcode?: string;
  state?: string;
  streetName?: string;
  streetNumber?: string;
  streetSuffix?: number | null;
  streetType?: number | null;
  unitNumber?: string;
  unitType?: string | number | null;
  metadata?: AddressMetadata;
}

export interface StandardTermsModel {
  additionalCovenants?: string;
  standardTermsDealingNumbers: StandardTermsDealingNumbersModel[];
  preRegisteredStandardTermsDealingNumbers?: string[]; // This is obsolete, do not use or copy in new document version.
  supportingDocuments: DocumentAttachmentModel[];
  warning?: string;
  preRegisteredStandardTerms?: PreRegisteredStandardTerm[];
}

export interface PreRegisteredStandardTerm {
  value: string;
  description?: string;
}

export interface StandardTermsDealingNumbersModel {
  termNumber?: string;
}

export interface TitleReferenceModel {
  estateType?: string;
  isPartLandAffected?: boolean;
  isSelected: boolean;
  landDescriptions?: LandDescriptionModel[];
  mortgagors: TenancyPartyModel[];
  reference?: string;
  hasTransfer?: boolean;
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export interface MortgagorPartyModel {
  addressBookId: string;
  partyBookId: string;
  partyCapacity?: DocumentPartyCapacityModel;
}

export interface DocumentPartyCapacityModel {
  birthDate?: Date | string | null;
  partyCapacity?: string | null;
  partyCapacityDetail?: string;
}

export const EMPTY_MORTGAGOR_PARTY: TenancyPartyModel = {
  id: '',
  partyBookId: undefined,
  addressBookId: undefined
};
// TODO update this model if it differs
export type Mortgage_2_26_0_Model = Omit<ApiMortgage_2_26_0_Model, 'partyBook' | 'addressBook' | 'mortgagors' | 'mortgageeDetails'> & {
  partyBook: PartyModel<SaNameChange>[];
  addressBook?: AddressBookEntityModel[];
  mortgagors: FormTenancyDetailModel;
  mortgageeDetails: FormReceivingTenancyDetailModel;
};
