import { useMemo } from 'react';

import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

// * This method returns both route params and query params
// * Example:
// * For a url like this "/app/workspace/${workspaceId}/documents/${documentId}?participantId=${participantId}"
// * It will return { workspaceId, documentId, participantId }
export default function useRouterParams<Params extends { [K in keyof Params]?: string } = {}>(): Params {
  const params = useParams();
  const location = useLocation();
  return useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return { ...queryParams, ...params } as Params;
  }, [location.search, params]);
}
