import React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import { PARTY_CAPACITY_LOOKUP_OPTIONS } from './enums';
import { DocumentPartyCapacityModel } from './models';
import styles, { ClassKeys } from './styles';
import { VISIBILITY_CHECK_BIRTH_DATE, VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL } from './visibilityChecks';

interface OwnProps {
  name: string;
  formikProps: FormikProps<any>;
  isReadonly: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class PartyCapacity extends React.PureComponent<Props> {
  render() {
    const { formikProps, name, isReadonly, classes } = this.props;
    const { values } = formikProps;
    const value: DocumentPartyCapacityModel = _get(values, name);

    return isReadonly ? (value?.partyCapacity ? this.renderReadOnlyPartyCapacity(value, classes) : null) : this.renderEditablePartyCapacity(value);
  }

  private renderReadOnlyPartyCapacity(value: DocumentPartyCapacityModel, classes: any) {
    const capacity = value.partyCapacity || -1;

    return (
      <FlexLayout flexDirection="column" fullWidth>
        <Typography className={classes.bold}>Party capacity</Typography>
        <Typography className={classes.detailRow}>{PARTY_CAPACITY_LOOKUP_OPTIONS.find(x => x.id === capacity)?.name}</Typography>
        {VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL(value) && value.partyCapacityDetail && (
          <>
            <Typography className={classes.bold}>Party capacity detail</Typography>
            <Typography className={classes.detailRow}>{value.partyCapacityDetail}</Typography>
          </>
        )}
        {VISIBILITY_CHECK_BIRTH_DATE(value) && value.birthDate && (
          <>
            <Typography className={classes.bold}>Birth date</Typography>
            <Typography className={classes.detailRow}>{dateTimeLine(value.birthDate, 'dd mmm yyyy')}</Typography>
          </>
        )}
      </FlexLayout>
    );
  }

  private renderEditablePartyCapacity(value: DocumentPartyCapacityModel) {
    return <span>Placeholder, I dont know what to do yet, pending mock up</span>;
  }
}

export default withStyles(styles)(PartyCapacity);
