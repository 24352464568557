import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    devHelper: {
      '&[data-name]::after': {
        lineHeight: '180px'
      }
    }
  }),
  {
    name: 'ReportsPermissionsForm'
  }
);
