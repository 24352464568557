import * as React from 'react';

/**
 * Help to get a value's previous value
 */
export default function usePreviousValue<T>(value: T) {
  const ref: React.MutableRefObject<T | undefined> = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
