import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: 24,
      paddingTop: 24
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    }
  }),
  { name: 'OffPlatformTasksContainer' }
);
