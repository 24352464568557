import { BankAccountTypeEnum, PaymentMethodEnum } from '@sympli/api-gateway/enums';

import { SourceFundFormikModel } from 'src/containers/workspace/financial/directions/models';

export const NEW_SOURCEFUND: SourceFundFormikModel = {
  isEditorOpen: true,
  trustAccountId: '',
  amount: '' as any,
  reference: '',
  category: '',
  categoryOther: '',
  subscriberName: '',
  participantId: '',
  accountType: BankAccountTypeEnum.SympliSourceAccount,
  paymentMethod: PaymentMethodEnum.BankTransfer
};
