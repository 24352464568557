import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 60,
      padding: '0 30px 0 60px'
    },
    light: {
      background: theme.palette.grey[50]
    },
    dark: {
      background: '#4D5257'
    },
    issuesButton: {
      paddingLeft: 16
    }
  }),
  { name: 'WorkflowPanel' }
);
