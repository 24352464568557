import * as React from 'react';

import MuiSkeleton from '@mui/material/Skeleton';

import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { AllDirectionsState } from 'src/containers/workspace/financial/all-directions/reducer';
import { resolveAllFinancialSettlementScheduleStatusText } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/helpers';
import { AllParticipantFinancialSettlementScheduleStatusEnum } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import { isFullyOptedOut } from 'src/containers/workspace/financial/directions/helpers';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';

interface Row {
  participantId: string;
  participantName: string;
  distributionStatus: DocumentStatusEnum;
  workspaceRole: string;
  isPartiallySigned: boolean;
  isNotApplicable: boolean;
}

function FinancialsCardDetail({
  //
  allDirectionsState: { detail, status, isLoading }
}: {
  allDirectionsState: AllDirectionsState;
}) {
  const isReady: boolean = status === 'resolved' || status === 'refetching';
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const rows: Row[] = isReady
    ? detail!.distributionsParticipants.map(p => {
        return {
          participantId: p.participantId,
          participantName: p.participant.name,
          distributionStatus: p.distributionStatus,
          workspaceRole: p.participant.workspaceRole.name,
          isPartiallySigned: p.isPartiallySigned,
          isNotApplicable: isFullyOptedOut(p.paymentsFinancialStatus, p.sourceFundsFinancialStatus)
        };
      })
    : [];

  const columns: ColumnsModel<Row> = {
    financials: {
      label: 'Financials',
      formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
        return (
          <div className="text-[12px] leading-[14px]">
            <div data-binding="documentName" className="truncated font-[700] text-[var(--neutral-1000)]" {...(isReady && tooltipHandlers)}>
              {isReady ? row.participantName : <MuiSkeleton variant="text" />}
            </div>
            {screenVariant !== 'small-laptop' && (
              <div data-binding="workspaceRole" className="truncated text-[var(--neutral-400)]" {...(isReady && tooltipHandlers)}>
                {isReady ? row.workspaceRole : <MuiSkeleton variant="text" />}
              </div>
            )}
          </div>
        );
      }
    },
    ...(screenVariant === 'small-laptop' && {
      workspaceRole: {
        width: 150,
        label: '',
        formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
          return (
            <div className="text-[12px] leading-[14px]">
              <div data-binding="workspaceRole" className="truncated text-[var(--neutral-400)]" {...(isReady && tooltipHandlers)}>
                {isReady ? row.workspaceRole : <MuiSkeleton variant="text" />}
              </div>
            </div>
          );
        }
      }
    }),
    status: {
      thClassName: screenVariant !== 'small-laptop' ? 'w-[100px]' : 'w-[130px]',
      verticalAlign: 'top',
      label: 'STATUS',
      formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
        const fssStatus = resolveAllFinancialSettlementScheduleStatusText(row.distributionStatus, row.isPartiallySigned, row.isNotApplicable);

        const variant =
          fssStatus === AllParticipantFinancialSettlementScheduleStatusEnum.Signed
            ? 'ok'
            : fssStatus === AllParticipantFinancialSettlementScheduleStatusEnum.NotApplicable
              ? 'neutral'
              : 'warning';

        return (
          <div data-binding="distributionStatus">
            {isReady ? (
              <VerticalStatusLine //
                text={fssStatus}
                variant={variant}
                tooltipHandlers={tooltipHandlers}
                className="h-[14px]"
                textClassName="truncated"
              />
            ) : (
              <MuiSkeleton variant="text" />
            )}
          </div>
        );
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
      {...(isLoading && {
        isLoading: true,
        pageSize: 4
      })}
      {...(isReady && {
        pageSize: rows.length
      })}
    />
  );
}

export default React.memo(FinancialsCardDetail);
