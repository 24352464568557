import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconTimes } from '@sympli/ui-framework/icons';

import styles, { ClassKeys } from '../sharedStyles';

interface OwnProps {
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class UnsignDocument extends React.PureComponent<Props> {
  render() {
    const { classes, isLoading, onClick } = this.props;
    return (
      <MenuItem onClick={onClick}>
        <IconTimes className={classes.menuItemIconUnsign} />
        <Typography className={classes.menuItemLabel}>Unsign document</Typography>
        {isLoading && <InlineLoader size={18} />}
      </MenuItem>
    );
  }
}

export default withStyles(styles)(UnsignDocument);
