import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { ScreenSizeVariant } from 'src/theme/screens';
import { StandaloneDashboardRouteTabsEnumV2 } from '../models';

export interface StyleProps {
  screenVariant: ScreenSizeVariant;
  workspaceStatusGroup: StandaloneDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
}

const gaps = {
  'large-screen': {
    [AssignmentTypeEnum.AssignedToMe]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 34,
      [StandaloneDashboardRouteTabsEnumV2.active]: 56,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 56,
      [StandaloneDashboardRouteTabsEnumV2.complete]: 56,
      [StandaloneDashboardRouteTabsEnumV2.archived]: 106,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 56
    },
    [AssignmentTypeEnum.AssignedToGroup]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 24,
      [StandaloneDashboardRouteTabsEnumV2.active]: 38,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 38,
      [StandaloneDashboardRouteTabsEnumV2.complete]: 38,
      [StandaloneDashboardRouteTabsEnumV2.archived]: 74,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 38
    },
    [AssignmentTypeEnum.ReAssigned]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 20,
      [StandaloneDashboardRouteTabsEnumV2.active]: 31,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 31,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 31
    }
  },
  'medium-screen': {
    [AssignmentTypeEnum.AssignedToMe]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 34,
      [StandaloneDashboardRouteTabsEnumV2.active]: 28,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 28,
      [StandaloneDashboardRouteTabsEnumV2.complete]: 28,
      [StandaloneDashboardRouteTabsEnumV2.archived]: 74,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 28
    },
    [AssignmentTypeEnum.AssignedToGroup]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 26,
      [StandaloneDashboardRouteTabsEnumV2.active]: 34,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 34,
      [StandaloneDashboardRouteTabsEnumV2.complete]: 22,
      [StandaloneDashboardRouteTabsEnumV2.archived]: 38,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 34
    },
    [AssignmentTypeEnum.ReAssigned]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 23,
      [StandaloneDashboardRouteTabsEnumV2.active]: 28,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 28,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 28
    }
  },
  'small-laptop': {
    [AssignmentTypeEnum.AssignedToMe]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 28,
      [StandaloneDashboardRouteTabsEnumV2.active]: 24,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 24,
      [StandaloneDashboardRouteTabsEnumV2.complete]: 24,
      [StandaloneDashboardRouteTabsEnumV2.archived]: 68,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 24
    },
    [AssignmentTypeEnum.AssignedToGroup]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 11,
      [StandaloneDashboardRouteTabsEnumV2.active]: 19,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 19,
      [StandaloneDashboardRouteTabsEnumV2.complete]: 17,
      [StandaloneDashboardRouteTabsEnumV2.archived]: 42,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 19
    },
    [AssignmentTypeEnum.ReAssigned]: {
      [StandaloneDashboardRouteTabsEnumV2.all]: 8,
      [StandaloneDashboardRouteTabsEnumV2.active]: 14,
      [StandaloneDashboardRouteTabsEnumV2.toBeReworked]: 14,
      [StandaloneDashboardRouteTabsEnumV2.recent]: 14
    }
  }
};

export const useStandaloneTableStyles = makeStyles(
  (theme: Theme) => ({
    root({ screenVariant, workspaceStatusGroup, assignmentType }: StyleProps) {
      return {
        '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: gaps[screenVariant] ? gaps[screenVariant][assignmentType][workspaceStatusGroup] : 14
        },
        '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: gaps[screenVariant] ? gaps[screenVariant][assignmentType][workspaceStatusGroup] : 14
        }
      };
    }
  }),
  {
    name: 'FinancialTable'
  }
);

export const useTableCellHeadCheckboxStyles = makeStyles(
  (theme: Theme) => ({
    tableHeadCellSelection: {
      width: 24
    },
    headCheckbox: {
      marginLeft: -4,
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  }),
  {
    name: 'SympliTableCellCheckboxOverrides'
  }
);

export const useTableCellBodyCheckboxStyles = makeStyles(
  (theme: Theme) => ({
    tableBodyCellSelection: {
      width: 24
    },
    bodyCheckbox: {
      marginLeft: -4,
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  }),
  {
    name: 'SympliTableCellCheckboxOverrides'
  }
);
