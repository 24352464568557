import * as React from 'react';

import Typography from '@mui/material/Typography';

import { LodgementDetail } from '@sympli/api-gateway/shared';

import { colors } from 'src/theme';
import LodgementCaseStatusTimestamp from '../components/lodgement-case-status-timestamp';

interface Props {
  lodgementDetail: LodgementDetail;
}

function InPreparationVerificationFailedOtherDocs({ lodgementDetail }: Props) {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Lodgement Verification - Unsuccessful
      </Typography>
      <LodgementCaseStatusTimestamp lodgementDetail={lodgementDetail} />
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        <b>Something went wrong</b> but not on your documents!
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        Other participants need to do additional work. Once they save and approve those documents then another automated Lodgement Verification will be initiated.
      </Typography>
    </>
  );
}

export default React.memo(InPreparationVerificationFailedOtherDocs);
