import React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchSubscriberAssignableGroups, actionFetchSupportedJurisdictions } from 'src/containers/dashboard/shared/actions';
import { useSubscriberAssignableGroups } from 'src/containers/dashboard/shared/reducers/subscriberAssignableGroups';
import { useWorkspaceAssignableGroups } from 'src/containers/dashboard/shared/reducers/workspaceAssignableGroups';
import { useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import http, { NoResponseData } from 'src/utils/http';
import { actionFetchWorkspaceParticipants } from '../../detail/actions';
import { useWorkspaceBasicInfo } from '../../detail/reducers/workspaceBasicInfo';
import { useWorkspaceParticipants } from '../../detail/reducers/workspaceParticipants';
import ReassignWorkspaceGroupDialog from '../reassign-workspace-group-dialog/ReassignWorkspaceGroupDialog';
import { ReassignWorkspaceGroupApiModel, ReassignWorkspaceGroupFormikModel } from './models';

interface OwnProps {
  workspaceId: string;
  participantId: string;
  open: boolean;
  onClose(): void;
}
export function ReassignWorkspaceGroupContainer(props: OwnProps) {
  const { workspaceId, participantId, onClose, open } = props;

  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const workspace = useWorkspaceBasicInfo(workspaceId);
  const participants = useWorkspaceParticipants(workspaceId);
  const { pathname, search } = useLocation();
  const workspaceOverviewLink: string = resolveWorkspaceDetailLink({ workspaceId, participantId });
  const assignableGroups = useSubscriberAssignableGroups();
  const assignableGroupsForCurrentUser = useWorkspaceAssignableGroups();
  const displayLoadingIcon = assignableGroups.status === 'pending' || assignableGroupsForCurrentUser.status === 'pending';
  const [displayNoAccessMessage, setDisplayNoAccessMessage] = React.useState(false);
  const currentParticipantGroupId = participants.items.find(p => p.id === participantId)?.groupId;

  React.useEffect(() => {
    batch(() => {
      dispatch(actionFetchSupportedJurisdictions.request());
      dispatch(actionFetchSubscriberAssignableGroups.request());
    });
  }, [dispatch]);

  const handleOnSubmit = async (formValues: ReassignWorkspaceGroupFormikModel) => {
    try {
      const payload: ReassignWorkspaceGroupApiModel = { groupId: formValues.groupId };
      const selectedGroup = assignableGroups.items.find(x => x.id === formValues.groupId);
      const userHasAccessToGroup = assignableGroupsForCurrentUser.items.some(x => x.id === formValues.groupId);
      if (userHasAccessToGroup || displayNoAccessMessage) {
        await http.post<NoResponseData>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/reassign-group`, payload);
        setDisplayNoAccessMessage(false);
        const message = `Workspace has been assigned to a group - ${selectedGroup?.name}`;
        batch(() => {
          dispatch(
            actionOpenGlobalSimpleNotification({
              //
              message,
              autoHideDuration: 5000,
              variant: 'new-success'
            })
          );
          dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
          onClose();
        });

        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'reassign-workspace-group',
          logGroupId: 'workspace',
          workspaceId,
          participantId,
          workspaceType: WorkspaceTypeDisplayMapping[workspace.detail?.workspaceTypeId!]
        });

        if (displayNoAccessMessage) {
          navigate('/');
        } else if (pathname.concat(search).toLowerCase() !== workspaceOverviewLink.toLowerCase()) {
          navigate(workspaceOverviewLink);
        }
      } else {
        setDisplayNoAccessMessage(true);
      }
    } catch (e) {
      Logger.captureException(e);
      return;
    }
  };

  return (
    <ReassignWorkspaceGroupDialog //
      open={open}
      assignableGroups={assignableGroups.items}
      displayNoAccessMessage={displayNoAccessMessage}
      jurisdiction={workspace.detail?.jurisdictionId}
      onClose={onClose}
      handleOnSubmit={handleOnSubmit}
      displayLoadingIcon={displayLoadingIcon}
      currentParticipantGroupId={currentParticipantGroupId}
    />
  );
}

export default React.memo(ReassignWorkspaceGroupContainer);
