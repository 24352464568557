import * as yup from 'yup';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';
import { RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';

import { NSW_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { Transfer2_21_1Model } from '../../models';

interface PartyBookValidationContext {
  transferors?: RelinquishingTenancyDetailModel<TenancyPartyModel>;
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = ({ transferors }: Transfer2_21_1Model): PartyBookValidationContext => ({
  transferors: transferors
});

const checkShouldValidateItem = ({ transferors }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) =>
  transferors?.relinquishingProprietorGroups.some(({ parties }) => parties.some(e => e.partyBookId === partyIdForCheck)) ?? false;

const yupPartyItem: yup.ObjectSchema<PartyModel<NswNameChange>, PartyBookValidationContext> = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(
  NSW_TRANSFER_PARTY_FORM_CONFIG
);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
