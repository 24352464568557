import { SA_ADDRESS_FORM_CONFIG as SA_M_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-23/config';
import { saNameChangeConversion } from '@sympli-mfe/document-components/party-form/sa/2-23/components/party-justification';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { ConversionMap, convertDataStructure } from '@sympli-mfe/document-forms-framework/utils';

import { getPurchaser } from 'src/containers/documents/helpers';
import { SA_MORTGAGE_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { createFormMortgagors, generateMortgagors } from './helper';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER } from './isRequiredChecks';
import { ApiMortgage_2_23_1_Model, Mortgage_2_23_1_Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: SA_M_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: SA_MORTGAGE_PARTY_FORM_CONFIG,
  // Add name change conversion here if necessary
  nameChangeConversion: saNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiMortgage_2_23_1_Model, context: IConverterContext): Mortgage_2_23_1_Model {
  const conversionMap: ConversionMap = {
    transfereeGroups: 'receivingProprietorGroups',
    tenancyType: 'shareSplitType'
  };
  apiModel = convertDataStructure(apiModel, conversionMap);

  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.mortgagors.map(p => p.partyBookId!).concat(apiModel.transferees.map(t => t.partyBookId!));
  const purchaserName = getPurchaser(context.participants);
  const dataSourceToNameMap = purchaserName ? new Map<DataSource, string>().set(DataSource.Transfer, purchaserName) : undefined;

  let formModel: Mortgage_2_23_1_Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds, dataSourceToNameMap),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || [], dataSourceToNameMap),
    mortgagors: createFormMortgagors(apiModel.mortgagors),
    mortgageeDetails: convertReceivingTenancyDetailFromApiToForm(apiModel.mortgageeDetails)
  };

  if (apiModel.needToGenerateMortgagors) {
    formModel = generateMortgagors(formModel);
  }

  formModel = applyDefaultMap(formModel, fallbackMap);

  // WEB-18683: The term number was not filled with the default one.
  // The following logic will check if the standard term dealing number is required or not.
  // If the user has provided one, or it provided the additional term number or uploaded documents, the standard term dealing number is not required.
  // It will fill the standard dealing term number with the default one in the user's setting only when it is empty and required.
  // The empty check is actually a safe guard just in case the require check failed to check the emptiness and overwrite the value already filled by the user.
  const termsAndConditions = formModel.termsAndConditions;
  const firstStandardTermsDealingNumber = termsAndConditions.standardTermsDealingNumbers[0];
  const originalTermNumber = firstStandardTermsDealingNumber.termNumber;
  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER(firstStandardTermsDealingNumber, termsAndConditions);

  if (isRequired && !originalTermNumber) {
    firstStandardTermsDealingNumber.termNumber = termsAndConditions.preRegisteredStandardTermsDealingNumbers?.[0] || originalTermNumber;
  }
  return formModel;
}

function convertFromFormToApiModel(formModel: Mortgage_2_23_1_Model, originalApiModel: ApiMortgage_2_23_1_Model): ApiMortgage_2_23_1_Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);

  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    mortgagors: mortgagorsFromForm,
    mortgageeDetails,
    mortgagors,
    ...rest
  } = formModel;

  return {
    ...rest,
    needToGenerateMortgagors: false,
    mortgagors: mortgagorsFromForm.proprietorGroups
      .flatMap(pg => pg.parties)
      .map(p => ({ id: p.partyBookId!, partyBookId: p.partyBookId!, addressBookId: p.addressBookId, partyCapacity: p.partyCapacity, partyCapacityDetail: p.partyCapacityDetail })),
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []) as any, // TODO resolve this typing
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
    mortgageeDetails: convertReceivingTenancyDetailFromFormToApi({
      formReceivingTenancyDetail: mortgageeDetails,
      shouldMapSurvivorshipType: true,
      partyBookFromForm: partyBookFromForm || []
    }),
    attachments: [
      {
        //
        documentType: 'Terms and Conditions',
        attachment: formModel.termsAndConditions.supportingDocuments
      }
    ]
  };
}

class Converter implements IConverter<Mortgage_2_23_1_Model, ApiMortgage_2_23_1_Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
