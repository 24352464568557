import * as React from 'react';

import NotFoundAnimation from 'src/components/not-found-animation';
import DetailContainerWrapper from 'src/containers/settings/components/detail-container-wrapper';

interface Props {
  hideTopPanel?: boolean;
}

function DetailNotFoundPage({ hideTopPanel }: Props) {
  return (
    <DetailContainerWrapper header="We can't seem to find the page you're looking for. Please check your URL." status="resolved" hideTopPanel={hideTopPanel}>
      <NotFoundAnimation />
    </DetailContainerWrapper>
  );
}

export default DetailNotFoundPage;
