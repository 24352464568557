import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { mergeArrays } from '@sympli-mfe/document-forms-framework/utils';

import { ApiMortgage2_21_1Model, MortgagorPartyModel, TitleReferenceModel } from './models';

/**
 * returns selected title reference items
 */
export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const filterMortgagors = (values: ApiMortgage2_21_1Model): MortgagorPartyModel[] =>
  mergeArrays(
    getSelectedTitleReferences(values.titleReferences).map(x => x.mortgagors),
    'partyBookId'
  ) as MortgagorPartyModel[];

const getMortgagorsWithNameChange = defaultMemoize((mortgagorsLinkedToSelectedTitles: PartyModel<NswNameChange>[]) => {
  return mortgagorsLinkedToSelectedTitles.filter(mortgagor => {
    const relinquishingDetails = mortgagor.receivingOrRelinquishingDetails as RelinquishingModel<NswNameChange>;
    return relinquishingDetails.isChangingName;
  });
});

const getMortgagorsWithoutNameChange = defaultMemoize((mortgagorsLinkedToSelectedTitles: PartyModel<NswNameChange>[]) => {
  return mortgagorsLinkedToSelectedTitles.filter(mortgagor => {
    const relinquishingDetails = mortgagor.receivingOrRelinquishingDetails as RelinquishingModel<NswNameChange>;
    return !relinquishingDetails.isChangingName;
  });
});

const getPartyName = (organisationName: string, isSingleName: boolean, firstName: string, lastName = ''): string => {
  if (organisationName) {
    return organisationName.trim();
  } else {
    return isSingleName ? firstName.trim() : `${firstName.trim()} ${lastName.trim()}`;
  }
};

/**
 * returns unique list of partyBookIds for title related mortgagors
 */
export const getMortgagorPartyBookIds = defaultMemoize((selectedTitles: TitleReferenceModel[]) => {
  const mortgagorPartyBookIds = selectedTitles //
    .map(title => title.mortgagors.map(mortgagor => mortgagor.partyBookId))
    .flat()
    .sort();
  return _uniq(mortgagorPartyBookIds);
});

/**
 * returns unique list of parties for title related mortgagors
 */
const getMortgagorsLinkedToSelectedTitlesDetails = defaultMemoize((partyBook: PartyModel<NswNameChange>[], selectedTitles: TitleReferenceModel[]) => {
  // unique list of mortgagors based on tile selection
  const mortgagorPartyBookIds = getMortgagorPartyBookIds(selectedTitles);
  return partyBook.filter(party => mortgagorPartyBookIds.includes(party.id));
});

/***************************************************
 * validation checks
 ***************************************************/

// applies for multi title only,
// if there is a name mismatch, where the user would either need to add a justification or unselect a title
export const checkSelectedTitlesHaveSameMortgagors = defaultMemoize((partyBook: PartyModel<NswNameChange>[], titleReferences: TitleReferenceModel[]): boolean => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  if (selectedTitles.length <= 1) return true;

  // unique list of partybook entities (by partyBookId) representing mortgagors on selected titles
  const mortgagorsLinkedToSelectedTitles = getMortgagorsLinkedToSelectedTitlesDetails(partyBook, selectedTitles);

  // unique list of legal entity names
  const uniqueNamesCount = _uniq(mortgagorsLinkedToSelectedTitles.map(mortgagor => mortgagor.legalEntityName)).length;
  // number of mortgagors on the first selected title
  const mortgagorCount = selectedTitles[0].mortgagors.length;
  // Check if a justification is needed
  if (uniqueNamesCount <= mortgagorCount) return true;

  // check if someone has name justification
  return getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles).length > 0;
});

export const checkIfJustifiedPartyNamesAreUnique = defaultMemoize((partyBook: PartyModel<NswNameChange>[], titleReferences: TitleReferenceModel[]): boolean => {
  const selectedTitles: TitleReferenceModel[] = getSelectedTitleReferences(titleReferences);
  if (!selectedTitles.length) return false;

  // unique list of partybook entities (by partyBookId) representing mortgagors on selected titles
  const mortgagorsLinkedToSelectedTitles: PartyModel<NswNameChange>[] = getMortgagorsLinkedToSelectedTitlesDetails(partyBook, selectedTitles);

  // list of mortgagors with name justification
  const mortgagorsWithNameChange: PartyModel<NswNameChange>[] = getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles);
  // list of justification details
  const justifications = mortgagorsWithNameChange.map(mortgagor => mortgagor.receivingOrRelinquishingDetails) as RelinquishingModel<NswNameChange>[];
  let justifiedMortgagorsNames: string[] = [];

  // check if name justifications are unique
  return justifications.every(justification => {
    const { organisationName, isSingleName, firstName, lastName } = justification.nameChange!;
    const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
    // if name was already used in justification, we don't want to continue
    if (justifiedMortgagorsNames.find(x => x === justifiedName)) {
      return false;
    }
    justifiedMortgagorsNames.push(justifiedName);
    return true;
  });
});

export const checkIfMergedPartiesHaveMismatchingPartyType = defaultMemoize((partyBook: PartyModel<NswNameChange>[], titleReferences: TitleReferenceModel[]): number | null => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  if (!selectedTitles.length) return null;

  // unique list of partybook entities (by partyBookId) representing mortgagors on selected titles
  const mortgagorsLinkedToSelectedTitles = getMortgagorsLinkedToSelectedTitlesDetails(partyBook, selectedTitles);

  // list of mortgagors with name justification
  const mortgagorsWithNameChange = getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles);
  if (!mortgagorsWithNameChange.length) return null;

  // list of mortgagors WITHOUT name justification
  const mortgagorsWithoutNameChange = getMortgagorsWithoutNameChange(mortgagorsLinkedToSelectedTitles);
  if (!mortgagorsWithoutNameChange.length) return null;

  // check if justified name already exists in the list of non-justified but with different party type
  for (const mortgagorWithNameChange of mortgagorsWithNameChange) {
    const { nameChange } = mortgagorWithNameChange.receivingOrRelinquishingDetails as RelinquishingModel<NswNameChange>;
    const { organisationName, isSingleName, firstName, lastName } = nameChange!;
    const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();

    for (const mortgagorWithoutNameChange of mortgagorsWithoutNameChange) {
      const { organisationName, isSingleName, firstName, lastName } = mortgagorWithoutNameChange;
      const nonJustifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
      // if party with proposed name already exists but it's of different type we want to show error
      if (justifiedName === nonJustifiedName && mortgagorWithNameChange.partyType !== mortgagorWithoutNameChange.partyType) {
        return mortgagorWithoutNameChange.partyType;
      }
    }
  }

  return null;
});

export const ADDITIONAL_COVENANT_WARNING_VALUES: string[] = ['trust', 'trustee', 'beneficial', 'benefitial', 'benefisial', 'beneficiary', 'benefitiary'];
