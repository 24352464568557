// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title, ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" {...rest}>
      {title && <title>{title}</title>}
      <defs>
        <path
          d="M20 4.5c2.792 0 5.375.698 7.75 2.094a15.662 15.662 0 0 1 5.656 5.656C34.802 14.625 35.5 17.208 35.5 20s-.698 5.375-2.094 7.75a15.662 15.662 0 0 1-5.656 5.656C25.375 34.802 22.792 35.5 20 35.5s-5.375-.698-7.75-2.094a15.662 15.662 0 0 1-5.656-5.656C5.198 25.375 4.5 22.792 4.5 20s.698-5.375 2.094-7.75a15.662 15.662 0 0 1 5.656-5.656C14.625 5.198 17.208 4.5 20 4.5zm-1.688 23.688L26 20.5a.676.676 0 0 0 .187-.5.914.914 0 0 0-.187-.562l-7.688-7.688c-.25-.208-.53-.25-.843-.125-.313.125-.469.354-.469.688v15.375c0 .333.156.562.469.687.312.125.573.063.781-.187h.062z"
          id="icon-reactivate_svg__a"
        />
      </defs>
      <use fill="#18CDB6" fillRule="nonzero" xlinkHref="#icon-reactivate_svg__a" transform="translate(-4 -4)" />
    </svg>
  );
};
