import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { useSafeDispatch } from 'src/hooks';

export const usePermissionDialog = (type: string) => {
  const dispatch = useSafeDispatch(useDispatch());
  const dispatchErrorDialog = useCallback(() => {
    dispatch(
      actionCreateGlobalErrorMessage({
        title: 'User Permission Required',
        message: `You do not have permission to reassign ${type}. Please contact your admin for more information on managing workspaces.`
      })
    );
  }, [dispatch, type]);

  return dispatchErrorDialog;
};
