import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER } from '../../checks';

const REGEXP_DAN = /^(\d+(-\d+)?)*$/;

// path: dutyAssessmentNumber
export default yup //
  .mixed<string>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER,
      validationWhenVisible: yup //
        .string()
        .default('')
        .trim()
        .nullable()
        .required(msg.REQUIRED)
        .max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
        .test('duty assessment format test', msg.INVALID_VALUE, value => REGEXP_DAN.test(value ?? ''))
    })
  );
