import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import { actionFetchTrustAccountAuthorisationRecords } from 'src/containers/workspace/financial/trust-account-authorisation-records/actions';
import { useTrustAccountAuthorisationRecords } from 'src/containers/workspace/financial/trust-account-authorisation-records/reducer';
import TrustAccountAuthorisationRecordsContainer, {
  TrustAccountAuthorisationRecordsRouteParams
} from 'src/containers/workspace/financial/trust-account-authorisation-records/TrustAccountAuthorisationRecordsContainer';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useRouterParams, useSafeDispatch } from 'src/hooks';

function WorkspaceTrustAccountAuthorisationRecordsPageContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId, trustAccountId } = useRouterParams<TrustAccountAuthorisationRecordsRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const trustAccountAuthorisationRecordsState = useTrustAccountAuthorisationRecords(workspaceId, participantId, trustAccountId);

  useEffect(() => {
    dispatch(actionFetchTrustAccountAuthorisationRecords.request({ workspaceId, participantId, trustAccountId }));
  }, [dispatch, workspaceId, participantId, trustAccountId]);

  if (workspaceBasicInfoState.error || workspaceDetailState.error || trustAccountAuthorisationRecordsState.error) {
    return <GeneralCrashedContent />;
  }

  return <TrustAccountAuthorisationRecordsContainer />;
}

export default React.memo(WorkspaceTrustAccountAuthorisationRecordsPageContainer);
