import React from 'react';

import { useDispatch } from 'react-redux';

import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { DocumentLodgementDetail } from 'src/containers/shared/document-list/models';
import { createRegroupIntoErrorsAndWarningsSelector } from 'src/containers/workspace/financial/detail/components/error-message-panel/selectors';
import { actionClearDocumentMessages, actionFetchDocumentMessages } from 'src/containers/workspace/shared/components/workflow-panel/actions';
import { useSafeDispatch } from 'src/hooks';
import { resolveDocumentErrorMessages } from '../helpers';
import { DocumentFormAndDetailModel, DocumentWorkflowStepsEnum } from '../models';
import { getDocumentsReadyToSign } from '../selectors';

const regroupIntoErrorsAndWarningsSelector = createRegroupIntoErrorsAndWarningsSelector<Exclude<DocumentLodgementDetail['documentLodgementCompliances'][number], 'undefined'>>();

function useDocumentIssues(
  participantId: string,
  workflowStep: DocumentWorkflowStepsEnum,
  documentList: WorkspaceDocumentSummaryApiResponse[],
  detail: DocumentFormAndDetailModel,
  lodgementDetail?: DocumentLodgementDetail
) {
  const dispatch = useSafeDispatch(useDispatch());

  const { lodgementCaseId } = detail;
  const documentsReadyToSign = getDocumentsReadyToSign(documentList, lodgementCaseId);
  const isSignMultiple = documentsReadyToSign.length > 1;
  const showMerged = workflowStep === DocumentWorkflowStepsEnum.Sign && isSignMultiple;

  React.useEffect(() => {
    if (!documentList) {
      return;
    }

    const lodgementCaseDocumentList = documentList.filter(d => d.lodgementCaseId === lodgementCaseId);
    const { documentErrorMessages } = resolveDocumentErrorMessages({ showMerged, participantId, documentList: lodgementCaseDocumentList, lodgementDetail });
    const { errors, warnings } = regroupIntoErrorsAndWarningsSelector(documentErrorMessages);

    dispatch(
      actionFetchDocumentMessages({
        errors,
        warnings
      })
    );

    return () => {
      dispatch(actionClearDocumentMessages());
    };
  }, [dispatch, documentList, lodgementDetail, participantId, showMerged, lodgementCaseId]);
}

export { useDocumentIssues };
