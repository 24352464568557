// this file was automatically generated from components/styles.ts.mustache
import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  fullWidth: {
    width: '100%'
  }
});

export type ClassKeys = typeof styles;

export default styles;
