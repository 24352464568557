import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiMortgage2_27_0Model, Mortgage2_27_0Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

// this file was automatically generated from DocumentForm.tsx.mustache
export type Props = DocumentFormProps<Mortgage2_27_0Model, ApiMortgage2_27_0Model>;

function DocumentForm(props: Props): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<Mortgage2_27_0Model>(validationSchema, validateDebounce);

  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
      />
    </DndContextProvider>
  );
}

export default React.memo(DocumentForm);
