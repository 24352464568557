import * as yup from 'yup';

import { resolveMemoizedTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import yupLeaseBaseTitleReferenceItemSchema from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/sections/title-references/validationSchema';
import { SubLease2_21_1Model, TitleReferenceModel } from '../../models';

const { fields } = yupLeaseBaseTitleReferenceItemSchema;
const yupTitleReferenceItem = yup
  .object<TitleReferenceModel>({
    ...fields,
    interestsOnTitle: yup.mixed()
  })
  .defined() as yup.ObjectSchema<TitleReferenceModel, SubLease2_21_1Model>;

const yupTitleReferences = resolveMemoizedTitleReferencesValidationSchema<TitleReferenceModel, SubLease2_21_1Model>(yupTitleReferenceItem);

export default yupTitleReferences;
