import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import { colors } from '@sympli/ui-framework/theme';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import { dateTimeLine } from 'src/utils/formatters';
import { DocumentListItemParticipantMode, DocumentStatusSteps } from '../../models';

export function resolveTooltipContentForIssue(mode: DocumentListItemParticipantMode, datetime?: WorkspaceDateTimeModel) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Lodgement Verification - Unsuccessful
      </Typography>
      <Typography variant="body2" sx={{ color: colors.GREY_40, fontSize: '13px' }}>
        {datetime ? `${dateTimeLine(datetime.userLocalTime, 'd mmm yyyy "at" h:MMTT')} (${datetime.userTimezoneAbbreviation})` : null}
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        <b>Something went wrong</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        This workspace has <b>attempted a lodgement verification</b> with no success, resulting in this document returning to an 'IN PREPARATION' state.
      </Typography>

      {mode !== 'ws-only' ? (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
          <b>Edit, save and approve</b> this document.
        </Typography>
      ) : null}

      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        Once all documents are ready again another automated Lodgement Verification will occur.
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={DocumentStatusSteps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}
