import { MessageNotificationVariant } from '@sympli/ui-framework/components/message-notification';

export interface RealtimeNotificationModel {
  variant: string;
  recipientParticipantIds: Array<string>;
  message: string;
  isPersisted?: boolean;
  secondaryMessage?: string;
  type?: NotificationMessageTypeEnum;
}

export enum NotificationVariantEnum {
  Information = 0,
  Success = 1,
  Warning = 2,
  Error = 3
}

export enum NotificationMessageTypeEnum {
  // currently we only have one, but we will have more in the future
  SLC = 0
}

export const NotificationVariantMapping: Record<NotificationVariantEnum, MessageNotificationVariant> = {
  [NotificationVariantEnum.Information]: 'info',
  [NotificationVariantEnum.Error]: 'error',
  [NotificationVariantEnum.Success]: 'new-success',
  [NotificationVariantEnum.Warning]: 'new-warning'
};

export interface SLCToastMessageModel {
  documentName: string;
  lodgementCaseName: string;
}

export interface DocumentRealtimeActivityLog {
  activities: Array<DocumentRealtimeActivity>;
}

export interface DocumentRealtimeActivity {
  documentId: string;
  documentName: string;
  type: DocumentRealtimeActivityTypeEnum;
  lodgementCaseId: string;
  lodgementCaseName: string;
  lodgementCaseIsPrimary: boolean;
  doneByParticipantId?: string;
}

export enum DocumentRealtimeActivityTypeEnum {
  Added = 0,
  Deleted = 1,
  Updated = 2
}

export interface LodgementRealtimeMessagePayload {
  lodgementCaseId?: string;
  lodgementCaseStatus?: LodgementCaseStatusEnum;
}

export enum LodgementCaseStatusEnum {
  Completed = 0,
  Lodged = 1,
  Others = 2
}
