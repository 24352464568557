import * as yup from 'yup';

import { getCharacterCountHelperText, isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS } from '../../isRequiredChecks';
import { AdditionalCovenantListingModel, Mortgage2_26_0Model } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
export const COVENANTS_MAX_CHARACTERS: number = 4000;
interface AdditionalCovenantsParentContext {
  hasMcpNumber: boolean;
}

// path: additionalCovenants
const contextResolver = ({ mcpNumber, additionalAttachments }: Mortgage2_26_0Model): AdditionalCovenantsParentContext => {
  return {
    hasMcpNumber: IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS(mcpNumber, additionalAttachments)
  };
};

const yupAdditionalCovenants = memoizeSchemaWithContext(
  yup.object<AdditionalCovenantListingModel>({
    warning: yup.string(),
    value: yup
      .string()
      .default('')
      .trim()
      .testContextualRule({
        uniqueTestName: '"additionalCovenants" required check',
        onlyIf: (parent: AdditionalCovenantListingModel, context: AdditionalCovenantsParentContext): boolean => context.hasMcpNumber,
        requirement: (parent: AdditionalCovenantListingModel) => isNotNullOrEmpty(parent.value),
        message: msg.REQUIRED
      })
      .max(COVENANTS_MAX_CHARACTERS, ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${getCharacterCountHelperText(value.length, max)}`)
  }),
  contextResolver
);

export default yupAdditionalCovenants;
