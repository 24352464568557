import * as React from 'react';

import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

function InPreparationUnverifiedFinancial() {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        in preparation
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        One or more document(s) are <b>now active</b> in this workspace. The Lodgement Case has not yet been verified.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        When <b>all documents are approved by all participants</b>, the Lodgement Verification process will <b>automatically commence</b> and lodgement fees will be returned to the
        workspace.
      </Typography>
    </>
  );
}

export default React.memo(InPreparationUnverifiedFinancial);
