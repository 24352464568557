import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchWorkspacePreferences } from '../actions/workspacePreferences';
import { WorkspacePreferencesApiResponse } from '../models';

const fetchWorkspacePreferences = () => http.get<WorkspacePreferencesApiResponse>('/settings/subscriber/workspace-preferences');

function* sagaFetchPreferences() {
  try {
    const data = yield* call(fetchWorkspacePreferences);
    yield put(actionFetchWorkspacePreferences.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspacePreferences.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchWorkspacePreferences.request, sagaFetchPreferences)
];
