import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ApiDocumentPartyModel, ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

// this file was automatically generated from models.ts.mustache
export interface ApiForm24A_2_24_1Model {
  partyBook: ApiDocumentPartyModel[];
  addressBook: any;
  titleReferences: any;
  transfereeDetails: TransfereeDataModel;
  transactionDetails: TransactionDataModel;
}

export interface TransfereeDataModel {
  transferees: TransfereeReferenceModel[];
  phone: string;
  postalAddressId: string;
  email: string | undefined | null;
  trustName: string | undefined | null;
}

export interface TransfereeReferenceModel {
  transferee: ApiPartyReferenceModel;
  isForeignPersonOrCorp: boolean | undefined | null;
  isResident: boolean | undefined | null;
}

export interface TransactionDataModel {
  settlementDate?: Date;
  isPossessionDateSameAsSettlement: boolean;
  possessionDate?: Date | string | null;
}

export type Form24A_2_24_1Model = Omit<ApiForm24A_2_24_1Model, 'partyBook'> & {
  partyBook: PartyModel[];
};
