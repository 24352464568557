import * as React from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';

import { LogTypeEnum } from '@sympli/api-gateway/enums';
import { CheckTitleActivityApiResponse } from '@sympli/api-gateway/models';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import SectionCollapsible from '@sympli/ui-framework/components/form/base-components/section-collapsible';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import MessageNotificationV2 from 'src/components/message-notification';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { actionDismissTacWarning } from 'src/containers/workspace/shared/detail/actions';
import { CreateWorkspaceLog, createWorkspaceLogs } from 'src/containers/workspace/shared/logs/api';
import useSafeDispatch from 'src/hooks/useSafeDispatch';
import { useStyles } from './styles';

const formatDate = (date?: string): string => (date ? dateTimeLine(date, DateFormatEnum.AUSDATETIMETEXT) : '');

export interface TitleProps {
  reference: string;
  tac?: CheckTitleActivityApiResponse;
  user: { id: string; name: string };
  participant: { id: string; subscriberName: string };
  workspaceId: string;
  onShowWarningClick: () => void;
  showWarning: boolean;
}

function TacChanges({ tac, reference, user, participant, workspaceId, onShowWarningClick, showWarning }: TitleProps) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = React.useState({ executing: false, open: false });
  const [dismiss, setDismiss] = React.useState(false);
  const dispatch = useSafeDispatch(useDispatch());

  if (!tac?.documentChanges?.length) return null;

  const warning = tac.hasChanges;
  const divider = warning ? classes.dividerWarning : classes.divider;
  const showConfirmation = () => setConfirmation({ ...confirmation, open: true });

  const dismissHandler = async (e: boolean | undefined) => {
    if (e) {
      try {
        setConfirmation({ ...confirmation, executing: true });
        const log: CreateWorkspaceLog = {
          dateTime: new Date().toUTCString(),
          isPublicView: false,
          isTriggeredBySystem: false,
          userId: user.id,
          participantId: participant.id,
          workspaceLogType: LogTypeEnum.TitleActivityPositiveChangeWarningDismissed,
          reference: reference,
          message: `Positive title activity warning and details for [:{TitleReference}](${reference}) were dismissed`,
          data: JSON.stringify({
            TitleReference: reference,
            UserId: user.id,
            UserName: user.name,
            SubscriberName: participant.subscriberName
          })
        };

        await createWorkspaceLogs(workspaceId, [log]);
        setConfirmation({ executing: false, open: false });
        dispatch(actionDismissTacWarning({ titleReference: reference, tac }));
      } catch (error) {
        setConfirmation({ executing: false, open: false });
        dispatch(actionCreateGlobalErrorMessage({ ...error, title: 'Unable to create log when dismissing TAC warning' }));
      }
    } else {
      setConfirmation({ executing: false, open: false });
    }
  };

  return (
    <div className={classes.root}>
      <SectionCollapsible
        show={showWarning}
        onShowClick={onShowWarningClick}
        headerWhenHidden="Show positive TAC details"
        headerWhenVisible="Hide positive TAC details"
        className={classes.section}
        backgroundColor={warning ? 'warning' : 'darkGrey'}
      >
        <div>
          <MessageNotificationV2 //
            open={warning}
            onClose={onShowWarningClick}
            variant="warning"
            secondary="Changes found on this title."
            primary="To update this workspace, please order title information resupply."
          />
          {tac.documentChanges.map((e, index) => {
            return (
              <div key={e.dealingNumber} className={classNames(classes.content, index !== tac.documentChanges?.length - 1 && divider)}>
                <ReadOnlyField label="Dealing" value={e.documentName || e.documentType} />
                {e.statuses.map((ds, i) => {
                  return (
                    <FlexLayout fullWidth key={i}>
                      <ReadOnlyField label="Dealing number" value={e.dealingNumber} />
                      <ReadOnlyField label="Status" value={ds.status} />
                      <ReadOnlyField label="Date and time" value={formatDate(ds.actionedDateTime)} />
                    </FlexLayout>
                  );
                })}
              </div>
            );
          })}
          {warning && (
            <FlexLayout className={classes.action}>
              <Checkbox label="Dismiss positive title activity warning and details" checked={dismiss} onChange={e => setDismiss(e.target.checked)} />
              <Button onClick={showConfirmation} variant="contained" color="secondary" className={classes.button} disabled={!dismiss}>
                Dismiss
              </Button>
            </FlexLayout>
          )}
        </div>
      </SectionCollapsible>
      <ConfirmationDialog
        open={confirmation.open}
        color="secondary"
        isLoading={confirmation.executing}
        title={
          <FlexLayout alignItems="center">
            <CloseRoundedIcon className={classes.iconConfirmation} />
            <span>Dismiss warnings</span>
          </FlexLayout>
        }
        okButtonText="Dismiss"
        onClose={dismissHandler}
      >
        Positive title activity warning and details for this title will be dismissed from your workspace.
      </ConfirmationDialog>
    </div>
  );
}

export default React.memo(TacChanges);
