import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    formGroupContainer: {
      margin: 0,
      paddingBottom: 0
    },
    formGroupTitle: {
      color: theme.palette.text.primary,
      fontSize: 14,
      lineHeight: '18px'
    },
    formGroupColumnTitleContainer: {
      paddingBottom: 16
    },
    fieldLine: {
      position: 'relative',
      '& + $fieldLine': {
        borderTop: `1px solid ${theme.palette.grey['300']}`,
        paddingTop: 10
      },
      '& > div + div': {
        marginLeft: 10
      }
    },
    searchContainer: {
      width: '100%'
    },
    suggestionsContainer: {
      maxHeight: 110,
      overflow: 'auto'
    }
  }),
  {
    name: 'ParticipantInviteDetail'
  }
);
