import * as yup from 'yup';

import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-19/components/party-justification';
import { AddressBookEntityModel, AddressTypeEnum } from '@sympli-mfe/document-forms-framework/components/address-field';
import { RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-19/wa';
import msg from '@sympli/ui-framework/utils/messages';

import { WaAddressChange } from '../../../../components/address-justification';
import { RelinquishingTenancyPartyModel } from '../../../../models';
import { VISIBILITY_CHECK_ADDRESS } from '../../../../visibilityChecks';
import { checkIfJustifiedPartiesAreTheSame, checkIfMergedPartiesHaveMismatchingPartyType, isMortgageeNameMismatch as isMortgageeNamesMatchesOrJustified } from '../../helpers';
import { DischargeOfMortgageDocument2_19_1Model } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
interface MortgageesContext {
  hasAnyTransactingParty: boolean;
  mortgageesLength: number;
  partyBook: DischargeOfMortgageDocument2_19_1Model['partyBook'];
  mortgages: DischargeOfMortgageDocument2_19_1Model['mortgages'];
  addressBook: AddressBookEntityModel[];
}

const contextResolver = ({ mortgagees, partyBook, mortgages, addressBook }: DischargeOfMortgageDocument2_19_1Model): MortgageesContext => {
  return {
    hasAnyTransactingParty: mortgagees.some(x => x.isTransactingParty),
    mortgageesLength: mortgagees.filter(x => x.partyBookId?.length).length,
    partyBook,
    mortgages,
    addressBook: addressBook || []
  };
};

const yupMortgagee = yup
  .object<RelinquishingTenancyPartyModel, MortgageesContext>({
    partyBookId: yup.mixed<string>(),
    partyCapacity: yup.mixed<number | null>(),
    partyCapacityDetail: yup.mixed<string>(),
    isTransactingParty: yup.mixed<boolean>(),
    addressBookId: yup
      .string()
      .default('')
      .trim()
      .test('addressId conditional required check', msg.REQUIRED, function test(this: yup.TestContext<MortgageesContext>, value: string) {
        const tenancyParty = this.parent as RelinquishingTenancyPartyModel;
        const { partyBook } = this.options.context!;

        const party = partyBook.filter(x => x.id === tenancyParty.partyBookId);

        return !!value?.trim()?.length || !VISIBILITY_CHECK_ADDRESS(party[0]);
      })
  })
  .test(
    'address justification required check',
    'Address justification is required when address is unstructured and party has name change',
    function test(this: yup.TestContext<MortgageesContext>, value: any) {
      const tenancyParty = value as RelinquishingTenancyPartyModel;
      const { partyBook, addressBook } = this.options.context!;

      const party = partyBook.filter(x => x.id === tenancyParty.partyBookId)[0];
      if (!VISIBILITY_CHECK_ADDRESS(party)) {
        return true;
      }

      const address = addressBook.filter(x => x.id === tenancyParty.addressBookId)[0];
      const relinquishingParty = party.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange, WaAddressChange>;
      return !relinquishingParty.isChangingName || address.type !== AddressTypeEnum.UnstructuredAddress || relinquishingParty.isChangingAddress === true;
    }
  )
  .defined();

// path: mortgagees
const yupMortgagees = memoizeSchemaWithContext(
  yup
    .array()
    .of<RelinquishingTenancyPartyModel>(yupMortgagee)
    .defined()
    .testContextualRule({
      uniqueTestName: 'Check if at least 1 mortgagee is selected',
      message: 'At least 1 mortgagee must be selected',
      requirement: (parent: DischargeOfMortgageDocument2_19_1Model, ctx: MortgageesContext) => ctx.hasAnyTransactingParty
    })
    .testContextualRule({
      uniqueTestName: 'Mortgagees name mismatch test',
      message: 'Mortgagee names must match. Please select Name differs to title and provide a reason for name difference.',
      requirement: (parent: DischargeOfMortgageDocument2_19_1Model, { partyBook, mortgages }: MortgageesContext) => {
        return isMortgageeNamesMatchesOrJustified(partyBook, mortgages);
      }
    })
    .testContextualRule({
      uniqueTestName: 'Check if party is justified twice',
      message: 'Unable to change name, the proposed name has already been used in a justification.',
      requirement: (parent: DischargeOfMortgageDocument2_19_1Model, { partyBook, mortgages }: MortgageesContext) => {
        return !checkIfJustifiedPartiesAreTheSame(partyBook, mortgages);
      }
    })
    .test('Check if parties justified does not have same party type', 'Unable to change name', function test(this: yup.TestContext<MortgageesContext>) {
      const { partyBook, mortgages } = this.options.context!;
      const partyType = checkIfMergedPartiesHaveMismatchingPartyType(partyBook, mortgages);
      return partyType
        ? this.createError({
            message: `Unable to change name, the proposed name has already been used for an ${partyType === PartyTypeEnum.Individual ? 'individual' : 'organisation'}.`
          })
        : true;
    })
    .log(),
  contextResolver
);

export default yupMortgagees;
