import { Action, createReducer } from 'typesafe-actions';

import { GetLandRegistryInformationResupplyCostApiResponse } from '@sympli/api-gateway/models';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchTitleResupplyCost, ResupplyTitleInfoCostApiRequest } from '../actions';

// MR - svc moved
// export interface TitleResupplyCostApiResponse {
//   totalCost: number;
//   gst: number;
// }

export interface TitleResupplyCostState {
  status: ApiStatus;
  detail?: GetLandRegistryInformationResupplyCostApiResponse;
  error?: string;
  isLoading: boolean;
  isRefetching: boolean;
  args?: ResupplyTitleInfoCostApiRequest;
}

const initialState: TitleResupplyCostState = {
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  detail: undefined,
  args: undefined
};

export function useTitleResupplyCost(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.titleResupplyCost);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  TitleResupplyCostState,
  Action
>(initialState)
  .handleAction(actionFetchTitleResupplyCost.request, (state, action): TitleResupplyCostState => {
    const status = state.status === 'resolved' ? 'refetching' : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchTitleResupplyCost.success, (state, action): TitleResupplyCostState => {
    return {
      ...state,
      status: 'resolved',
      detail: action.payload.data,
      isLoading: false,
      isRefetching: false
    };
  })
  .handleAction(actionFetchTitleResupplyCost.failure, (state, action): TitleResupplyCostState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false
    };
  });

export default reducer;
