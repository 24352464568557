import React from 'react';

import { Form } from 'formik';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionCurrentPriorityNotices from '@sympli-mfe/document-forms-framework/components/sections/current-priority-notice';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import DocumentDataNotification from 'src/containers/documents/components/document-data-notification';
import { defaultDocumentNotification, WithdrawalOfPriorityNoticeModel } from './models';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<WithdrawalOfPriorityNoticeModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  return (
    <>
      <DocumentDataNotification notification={defaultDocumentNotification} />
      <Form className={className}>
        <SectionCurrentPriorityNotices name={fieldName('priorityNotices')} />
        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
