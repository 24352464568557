import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      marginLeft: 44
    },
    title: {
      minHeight: 'auto',
      paddingTop: 0
    },
    marginRight: {
      marginRight: 4
    }
  }),
  { name: 'Detail' }
);
