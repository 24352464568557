import * as React from 'react';

import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Dropzone, { DropzoneProps, fileRejections } from '@sympli/ui-framework/components/form/base-components/dropzone';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import UserAvatar from 'src/components/avatars/user-avatar';
import styles, { ClassKeys } from './styles';

const MAX_SIZE = 5 * 1024 * 1024; // * 5MB

interface OwnProps {
  src?: string;

  showDropzone: boolean;
  openedImage?: string | File;

  setEditorRef: (editor: AvatarEditor) => void;

  className?: string;
  dropzoneProps?: Partial<DropzoneProps>;
  editorProps?: Partial<AvatarEditorProps>;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class AvatarDropzone extends React.PureComponent<Props> {
  render() {
    const { classes, src, showDropzone, openedImage, editorProps, dropzoneProps } = this.props;

    // * Show image preview
    if (!showDropzone) {
      return (
        <FlexLayout alignItems="center" justifyContent="space-around" className={classes.imagePreview}>
          <Typography>Image preview</Typography>
          <UserAvatar src={src} />
        </FlexLayout>
      );
    }

    // * If there is an opened image, show editor
    if (openedImage) {
      return (
        <AvatarEditor //
          ref={this.setEditorRef}
          image={openedImage}
          width={200}
          height={200}
          borderRadius={100}
          border={0}
          {...editorProps}
        />
      );
    }

    const replacestring: { [key: string]: string } = {
      ',': ' or ',
      'one of ': ' '
    };
    // * Show image dropzone by default
    return (
      <Dropzone
        accept={['image/jpeg', 'image/png']}
        maxSize={MAX_SIZE}
        {...dropzoneProps}
        renderDropzoneErrors={(errors, errorTemplate) => fileRejections(errors, errorTemplate, replacestring)}
      />
    );
  }

  private setEditorRef = (editor: AvatarEditor) => {
    this.props.setEditorRef(editor);
  };
}

export default withStyles(styles)(AvatarDropzone);
