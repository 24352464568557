import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { generateMortgagors } from './helper';
import { Mortgage_2_26_0_Model } from './models';
import MortgageeDetails from './sections/mortgagee-details';
import SectionMortgagors from './sections/mortgagors/Mortgagors';
import TermsAndConditions from './sections/terms-and-conditions';
import { useStyles } from './styles';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Mortgage_2_26_0_Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage_2_26_0_Model>();
  const { setValues } = formikProps;
  const classes = useStyles();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgageesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnMortgageesRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = useCallback(() => {
    setValues(values => generateMortgagors(values));
  }, [setValues]);

  return (
    <>
      <Form className={classNames(classes.root, className)}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
          focusRef={focusOnTitleReferencesRef}
        />

        <SectionMortgagors name={fieldName('mortgagors')} />

        <MortgageeDetails name={fieldName('mortgageeDetails')} focusRef={focusOnMortgageesRef} />

        <Section title="Terms and conditions" data-name={fieldName('termsAndConditions')}>
          <TermsAndConditions name={fieldName('termsAndConditions')} />
        </Section>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
