import * as yup from 'yup';

import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { DischargeOfMortgage2_21_1Model } from '../../models';
import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from '../../visibilityChecks';

const yupMortgagees = yup
  .array<TenancyPartyModel, DischargeOfMortgage2_21_1Model>()
  .defined()
  .testContextualRule({
    message: 'Mortgagee names must match. Please select Name differs to title and provide a reason for name difference.',
    uniqueTestName: 'mortgagees[*] name mismatch',
    onlyIf: (parent: DischargeOfMortgage2_21_1Model) => parent.titleReferences.length > 0 && PartyValidations.verifyTenancyStructure(parent.partyBook) === undefined,
    requirement: (parent: DischargeOfMortgage2_21_1Model) => {
      //ToDo: Move GetLegalEntityNameNSW to common folder for NSW
      return PartyValidations.verifyJustification(parent.partyBook, getLegalEntityNameNSW);
    }
  });

export default yup //
  .mixed<TenancyPartyModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES,
      validationWhenVisible: yupMortgagees,
      isObjectOrArray: true
    })
  );
