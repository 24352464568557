import dateFormat from 'dateformat';

import { convertAttachmentsFromFormToApiModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { caveatFallbackMap } from './checks';
import { NSW_CAVEAT_ADDRESS_FORM_CONFIG, NSW_CAVEAT_PARTY_FORM_CONFIG } from './config';
import { SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { ADDRESS_BOOK_KEY, ApiCaveatModel_2_21_1, CaveatModel_2_21_1, Claim, PARTY_BOOK_KEY } from './models';

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: NSW_CAVEAT_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_CAVEAT_PARTY_FORM_CONFIG
});

export function convertFromApiToFormModel(apiModel: ApiCaveatModel_2_21_1): CaveatModel_2_21_1 {
  const relinquishingIds = (apiModel.proprietors || []).map(proprietor => proprietor.partyBookId).filter(Boolean) as string[];
  let formModel: CaveatModel_2_21_1 = {
    ...apiModel,
    [PARTY_BOOK_KEY]: convertPartyBookFromApiToFormModel(apiModel[PARTY_BOOK_KEY], relinquishingIds),
    [ADDRESS_BOOK_KEY]: convertAddressBookFromApiToFormModel(apiModel[ADDRESS_BOOK_KEY])
  };
  formModel = applyDefaultMap(formModel, caveatFallbackMap);
  return formModel;
}

export function convertFromFormToApiModel(formModel: CaveatModel_2_21_1, originalApiModel: ApiCaveatModel_2_21_1): ApiCaveatModel_2_21_1 {
  formModel = applyVisibilityFallbackMap(formModel, caveatFallbackMap);
  const { [PARTY_BOOK_KEY]: formPartyBook, [ADDRESS_BOOK_KEY]: formAddressBook, claimDetails, ...rest } = formModel;

  const amendedClaimDetails: Claim | undefined =
    claimDetails && isDate(claimDetails.claimDate)
      ? {
          ...claimDetails,
          claimDate: dateFormat(claimDetails.claimDate as Date, DateFormatEnum.DATE)
        }
      : claimDetails;

  return {
    ...rest,
    attachments: convertAttachmentsFromFormToApiModel(formModel.supportingDocuments, SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS),
    claimDetails: amendedClaimDetails,
    [PARTY_BOOK_KEY]: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel[PARTY_BOOK_KEY]),
    [ADDRESS_BOOK_KEY]: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel[ADDRESS_BOOK_KEY])
  };
}

function isDate(possibleDate: unknown) {
  return possibleDate instanceof Date;
}
