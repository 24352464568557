import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: -16,
      marginRight: -16
    },
    item: {
      paddingLeft: 16,
      paddingRight: 16
    },
    errorMessage: {
      margin: '16px auto'
    },
    smallRow: {
      fontSize: 13,
      lineHeight: '15px'
    },
    sectionSpacingTop: {
      paddingTop: 10
    },
    sectionIcon: {
      paddingRight: 4,
      minWidth: 16,
      verticalAlign: 'middle'
    },
    buttonSpacing: {
      marginTop: 22
    },
    fullWidth: {
      width: '100%'
    },
    matter: {
      fontSize: 13,
      fontWeight: 'bold',
      lineHeight: '15px'
    },
    error: {
      color: theme.palette.error.main
    },
    linkDescription: {
      textDecoration: 'underline',
      fontWeight: 'bold'
    },
    link: {
      width: '100%',
      padding: '7px 18px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
