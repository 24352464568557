import * as yup from 'yup';

import { PartyModel, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { VIC_TOC_PARTY_FORM_CONFIG } from '../../config';
import { TransferOfControlModel } from '../../models';

export const yupParty = resolvePartyModelValidationSchema(VIC_TOC_PARTY_FORM_CONFIG);

const yupPartyBookEntry = yup
  .object<PartyModel>()
  .test(
    'Vic Transfer of Control party test',
    'A VIC Transfer of Control party has failed validation',
    function test(this: yup.TestContext<TransferOfControlModel>, party: PartyModel): boolean {
      const transferOfControlModel = this.options.context!;

      try {
        yupParty.validateSync(party, {
          path: this.path,
          abortEarly: false,
          context: transferOfControlModel
        } as any);
        return true;
      } catch (ex) {
        return ex;
      }
    }
  );

export const yupPartyBook = yup.array<PartyModel>(yupPartyBookEntry as yup.Schema<PartyModel>);
