import * as React from 'react';

import { WorkspaceDetailApiResponse } from '@sympli/api-gateway/models';
import { MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';

import { compareRisDetail } from 'src/containers/workspace/shared/detail/selectors';

interface Props {
  userId: string;
  participantId: string;
  titleReference: WorkspaceDetailApiResponse['titleReferences'][number];
}

function RisNotificationMessage({ titleReference, userId, participantId }: Props) {
  const { landRegistryDetail, previousLandRegistryDetail } = titleReference ?? {};
  const secondMessage = React.useMemo(() => {
    if (previousLandRegistryDetail) {
      return compareRisDetail(landRegistryDetail, previousLandRegistryDetail) ? 'No change found.' : 'All documents have been updated with latest title information';
    }
    return null;
  }, [landRegistryDetail, previousLandRegistryDetail]);

  // this means no one ordered this RIS
  if (previousLandRegistryDetail == null) {
    return null;
  }

  const { requestedDate } = landRegistryDetail;

  return (
    <MessageNotificationWithLocalStorage
      storageNamespace={`${userId}/${participantId}/Ris_Notification_TitleDetail`}
      messageId={requestedDate}
      variant="success"
      secondary={secondMessage}
      primary="Title information ordered."
    />
  );
}

export default React.memo(RisNotificationMessage);
