import * as React from 'react';

import Typography from '@mui/material/Typography';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconDelete } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  titleReference: string;
  isDeleting?: boolean;
  onNext(): Promise<void>;
  onClose(): void;
}

function RemoveTitleDialog({ isOpen, titleReference, isDeleting, onNext, onClose }: Props) {
  const classes = useStyles();

  const title = (
    <FlexLayout alignItems="center">
      <IconDelete className={classes.headingIcon} />
      <span className={classes.headingText}>{`Delete ${titleReference}`}</span>
    </FlexLayout>
  );

  return (
    <ConfirmationDialog //
      title={title}
      open={isOpen}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      <Typography>This title will be deleted from the workspace. If it is already used in a document it will continue to be referenced until unselected.</Typography>

      <WizardStepper //
        color="error"
        onBack={onClose}
        backLabel="Cancel"
        nextLabel="Delete"
        onNext={onNext}
        isLoading={isDeleting}
        disabled={isDeleting}
        classes={{ errorButton: classes.errorButton }}
      />
    </ConfirmationDialog>
  );
}

export default React.memo(RemoveTitleDialog);
