import * as React from 'react';

import { DocumentListModel } from 'src/containers/shared/document-list/models';
import { DocumentListBase } from '../document-list-base';

type Props = {
  items: DocumentListModel[];
  forceDisable?: boolean;
};

function DocumentListDelete({ items, forceDisable }: Props) {
  const disabled = items.every(x => !x.canRemove) || forceDisable;

  const getItems = React.useCallback((items: DocumentListModel[]) => {
    if (items.length === 0) return [];
    return items.map(x => {
      const newItem: DocumentListModel = { ...x };
      if (x.disabled) {
        // when a document is not able to be deleted by current participant, we remove delete icon
        newItem.icon = undefined;
      }
      if (x.supportingDocuments) {
        // recursive checking supporting documents
        newItem.supportingDocuments = getItems(newItem.supportingDocuments);
      }
      return newItem satisfies DocumentListModel;
    });
  }, []);

  return <DocumentListBase items={getItems(items)} disabled={disabled} dataTestId="document-list-delete" isCriticalRolesEnabled={false} />;
}

export default DocumentListDelete;
