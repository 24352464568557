import { createSelector } from 'reselect';

import { TrustAccountDetails, TrustAccountDetailsApiResponse, TrustAccountRequestDetailsApiResponse, TrustAccountRequestStatusEnum, TrustAccountRequestTypeEnum } from './models';

export const trustAccountDetailsSelector = createSelector<
  //
  TrustAccountDetailsApiResponse,
  TrustAccountDetailsApiResponse,
  TrustAccountDetails
>(
  (data: TrustAccountDetailsApiResponse) => data,
  (data: TrustAccountDetailsApiResponse) => ({
    trustAccountId: data.id,
    jurisdictionIds: data.jurisdictions,
    contactPerson: data.contactPerson,
    trustAccountDetail: {
      accountDescription: data.accountDescription,
      bsbNumber: data.bsbNumber,
      bankName: data.bankName,
      branchLocation: data.branchLocation,
      accountName: data.accountName,
      accountNumber: data.accountNumber
    },
    signingRule: data.signingRule,
    trustAccountSignatories: data.signatories?.length
      ? {
          trustAccountSignatoriesDetails: data.signatories
        }
      : {
          trustAccountSignatoriesDetails: []
        },
    trustAccountOwners: data.owners?.length
      ? {
          accountOwners: data.owners
        }
      : {
          accountOwners: []
        },
    status: TrustAccountRequestStatusEnum.Approved
  })
);

export const trustAccountRequestDetailsSelector = createSelector<
  //
  TrustAccountRequestDetailsApiResponse,
  TrustAccountRequestDetailsApiResponse,
  TrustAccountDetails
>(
  (data: TrustAccountRequestDetailsApiResponse) => data,
  (data: TrustAccountRequestDetailsApiResponse) => ({
    trustAccountId: data.trustAccountId,
    trustAccountRequestId: data.id,
    jurisdictionIds: data.jurisdictions,
    contactPerson: data.contactPerson,
    trustAccountDetail: {
      accountDescription: data.accountDescription,
      bsbNumber: data.bsbNumber,
      bankName: data.bankName,
      branchLocation: data.branchLocation,
      accountName: data.accountName,
      accountNumber: data.accountNumber
    },
    signingRule: data.signingRule,
    trustAccountSignatories: data.signatories?.length
      ? {
          trustAccountSignatoriesDetails: data.signatories
        }
      : {
          trustAccountSignatoriesDetails: []
        },
    trustAccountOwners: data.owners?.length
      ? {
          accountOwners: data.owners
        }
      : {
          accountOwners: []
        },
    status: data.status,
    type: data.type || TrustAccountRequestTypeEnum.NewAccount
  })
);
