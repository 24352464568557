import * as React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { Form24B_2_24_1Model, SIMPLE_BOOLEAN_OPTIONS, TransactionDataModel } from '../../models';
import { VISIBILITY_CHECK_ARE_PARTIES_RELATED, VISIBILITY_CHECK_HAS_AGREEMENT, VISIBILITY_CHECK_IS_GST_PAYABLE } from '../../visibilityCheck';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;

function TransactionData() {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { disabled } = useDocumentContext();

  const classes = useStyles();
  const fieldName = createModelKeyAppender<TransactionDataModel>('transaction');

  const handleHasAgreementChange = (_: any, resolvedValue: boolean) => {
    if (!resolvedValue) {
      setFieldValue(fieldName('agreementDate'), null, false);
    }
  };
  const handleArePartiesRelatedChange = (_: any, resolvedValue: boolean) => {
    if (!resolvedValue) {
      setFieldValue(fieldName('relatedDegree'), null, false);
    }
  };
  const handleIsGstPayableChange = (_: any, resolvedValue: boolean) => {
    if (!resolvedValue) {
      setFieldValue(fieldName('isMarginScheme'), null, false);
    }
  };

  return (
    <Section title="Transaction information" data-testid="transactionData">
      <FormGroup title="Transaction information">
        <Field
          label="Has an agreement in writing for the transfer of dutiable property been entered into?"
          name={fieldName('hasAgreement')}
          component={RadioField}
          options={SIMPLE_BOOLEAN_OPTIONS}
          className={classes.radio}
          format="boolean"
          disabled={disabled}
          onChange={handleHasAgreementChange}
          formTip={
            <div className={classes.tooltip}>
              Mark “YES” if a written agreement has been executed between the parties for the purpose of the transfer of the property. An example of a written agreement includes a
              contract of sale.
            </div>
          }
        />
        {VISIBILITY_CHECK_HAS_AGREEMENT(values) && (
          <ArrowBox backgroundColor="darkGrey" className={classes.arrowBox}>
            <Field label="Date of written agreement" name={fieldName('agreementDate')} component={DatePickerField} disabled={disabled} />
          </ArrowBox>
        )}
        <Field
          label="Are the transferor and transferee related or associated at the date of the transfer?"
          name={fieldName('arePartiesRelated')}
          component={RadioField}
          options={SIMPLE_BOOLEAN_OPTIONS}
          className={classes.radio}
          format="boolean"
          disabled={disabled}
          onChange={handleArePartiesRelatedChange}
          formTip={
            <div className={classes.tooltip}>
              A relationship may include a relation (up to the third degree) by blood, marriage or de facto.
              <br />
              <br />
              Examples of an association include a person in the capacity of a related body corporate, as a shareholder or director, as trustee or beneficiary of a related or the
              same trust, or through a partnership agreement.
            </div>
          }
        />

        {VISIBILITY_CHECK_ARE_PARTIES_RELATED(values) && (
          <ArrowBox backgroundColor="darkGrey" className={classes.arrowBox}>
            <Field
              label={
                <span>
                  State the degree of relationship or association and supply evidence of value to Office of State Revenue
                  <InfoIconWithTooltip
                    flow="inline"
                    tooltipContent={
                      <div className={classes.tooltip}>
                        State the degree of the relationship or association between the transferor and transferee at the date of the transfer.
                        <br />
                        <br />
                        The Queensland Revenue Office requires independent evidence of the value of the relevant property if the transferor and transferee are associated or related
                        at the date of the transfer.
                        <br />
                        <br />
                        In the case of the transfer of residential properties only, acceptable evidence includes:
                        <ul className={classes.tooltipList}>
                          <li className={classes.tooltipListItem}>a market appraisal; or</li>
                          <li className={classes.tooltipListItem}>written opinion,</li>
                        </ul>
                        as at the date of the transfer, which must include three comparable sales from a local real estate agent.
                      </div>
                    }
                  />
                </span>
              }
              name={fieldName('relatedDegree')}
              component={InputField}
              disabled={disabled}
              className={classes.relatedDegree}
            />
          </ArrowBox>
        )}
        <Field
          label="Is the consideration less than the unencumbered value of the property included in this transaction?"
          name={fieldName('isConsiderationLess')}
          component={RadioField}
          options={SIMPLE_BOOLEAN_OPTIONS}
          className={classes.radio}
          format="boolean"
          disabled={disabled}
          formTip={
            <div className={classes.tooltip}>
              The unencumbered value of the property is considered as the value of the property, disregarding any encumbrance or liability. The unencumbered value may also be
              considered as the value the property would be sold for on the open market. Provide the Queensland Revenue Office with independent evidence of the value of the
              relevant property where the consideration is less than the amount of the unencumbered value of the property.
            </div>
          }
        />
        <Field
          label="Does this transaction form part of an arrangement that includes other dutiable transactions?"
          name={fieldName('isOtherDutiable')}
          component={RadioField}
          options={SIMPLE_BOOLEAN_OPTIONS}
          className={classes.radio}
          format="boolean"
          disabled={disabled}
          formTip={
            <div className={classes.tooltip}>
              Provide the Queensland Revenue Office with full details of any other transaction if this transaction forms part of an arrangement which includes other dutiable
              transactions.
            </div>
          }
        />
        <Field
          label="Is GST Payable on this transaction?"
          name={fieldName('isGstPayable')}
          component={RadioField}
          options={SIMPLE_BOOLEAN_OPTIONS}
          className={classes.radio}
          format="boolean"
          disabled={disabled}
          onChange={handleIsGstPayableChange}
          formTip={
            <div className={classes.tooltip}>
              GST is payable if the transferor is registered or required to be registered for GST and is conducting this transaction as an enterprise as categorised by the ATO.
            </div>
          }
        />
        {VISIBILITY_CHECK_IS_GST_PAYABLE(values) && (
          <ArrowBox backgroundColor="darkGrey" className={classes.arrowBox}>
            <Field
              label="Is the transaction under the margin scheme?"
              name={fieldName('isMarginScheme')}
              component={RadioField}
              options={SIMPLE_BOOLEAN_OPTIONS}
              className={classes.radio}
              format="boolean"
              disabled={disabled}
              formTip={
                <div className={classes.tooltip}>
                  The margin scheme provides sellers with an alternate means of calculating the payable amount of GST. The availability of the margin scheme is contingent on the
                  nature of the purchase and the date the property was purchased.
                </div>
              }
            />
          </ArrowBox>
        )}
        <Field
          label="Is any transferor a non-Australian entity?"
          name={fieldName('isTransferorNonAus')}
          component={RadioField}
          options={SIMPLE_BOOLEAN_OPTIONS}
          className={classes.radio}
          format="boolean"
          disabled={disabled}
          formTip={
            <div className={classes.tooltip}>
              The “Non-Australian” entities may refer to any of the following examples:
              <ul className={classes.tooltipList}>
                <li className={classes.tooltipListItem}>Individuals who are not Australian citizens;</li>
                <li className={classes.tooltipListItem}>Companies not incorporated in Australia;</li>
                <li className={classes.tooltipListItem}>Trusts which have a tax residence in a country that is not Australia;</li>
                <li className={classes.tooltipListItem}>Other bodies not formed in Australia.</li>
              </ul>
            </div>
          }
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(TransactionData);
