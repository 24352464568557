import React from 'react';

import { Form, FormikProps } from 'formik';
import Typography from '@mui/material/Typography';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconStar } from '@sympli/ui-framework/icons';

import Formik from 'src/components/formik';
import { FavouriteFormikModel } from 'src/containers/dashboard/reports/models';
import { modelKey } from 'src/utils/formUtils';
import ReportName from '../../../report-scheduler-dialog/components/report-name';
import { useStyles } from './styles';
import validationSchema from './validationSchema';

export interface Props {
  isOpen: boolean;
  onClose: (value?: boolean) => void;
  maxNameLength: number;
  initialValues: FavouriteFormikModel;
  handleOnPostSubmit?: (args: FormikPostSubmitArgs<FavouriteFormikModel>) => void;
  filterData?: string;
  parameterData?: string;
}

export const defaultValues: FavouriteFormikModel = {
  name: '',
  filterData: '',
  reportType: ReportTypeEnum.UserPermissionReport
};

const fieldName = modelKey<FavouriteFormikModel>();

function FavouriteReportDialog({ isOpen, onClose, maxNameLength, initialValues, handleOnPostSubmit, filterData, parameterData }: Props) {
  const classes = useStyles();

  const renderForm = React.useCallback(
    (formikProps: FormikProps<FavouriteFormikModel>) => {
      const { dirty, isValid, isSubmitting } = formikProps;
      const disabled = !dirty || !isValid || isSubmitting;

      return (
        <Form>
          <Typography variant="body1" className={classes.headerFont}>
            {initialValues.id ? 'Update this report with a unique name below.' : 'Please give this report a unique name to add it to your favourites list.'}
          </Typography>
          <ReportName maxNameLength={maxNameLength} reportNameField={fieldName('name')} disabled={isSubmitting} />
          <WizardStepper //
            onBack={onClose}
            backLabel="Cancel"
            nextLabel="Save"
            disabled={disabled}
            isLoading={isSubmitting}
          />
        </Form>
      );
    },
    [classes.headerFont, maxNameLength, onClose, initialValues]
  );

  const handleOnPreSubmit = (values: FavouriteFormikModel) => {
    // before submit, we trim the name, and set the latest filterData
    return { ...values, name: values.name.trim(), filterData, parameterData };
  };

  return (
    <ConfirmationDialog //
      title={
        <FlexLayout justifyContent="flex-start" alignItems="center">
          <IconStar className={classes.sectionIcon} width={32} height={32} /> Favourite report
        </FlexLayout>
      }
      classes={{ dialogPaper: classes.dialogPaper, root: classes.root }}
      open={isOpen}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      <Formik //
        method={initialValues.id ? 'put' : 'post'}
        action={initialValues.id ? `/reports/favouriteReportParameters/${initialValues.id}` : '/reports/favouriteReportParameters'}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onPostSubmit={handleOnPostSubmit}
        onPreSubmit={handleOnPreSubmit}
      >
        {(formikProps: FormikProps<FavouriteFormikModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}

export default React.memo(FavouriteReportDialog);
