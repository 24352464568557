import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import Fieldset from '@sympli/ui-framework/components/fieldset';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { PartLandExtentEnum, TRANSACTION_INSTRUMENTS$PARTLAND_AFFECTED_EXTENT_TYPE_LOOKUP_OPTIONS } from '../../../../enums';
import { TitleReferenceModel } from '../../../../models';
import { useStyles } from './styles';

interface Props {
  disabled?: boolean;
  disablePartDescriptionSupport?: boolean;
  renderProps: { item: TitleReferenceModel; itemBinding: string; fieldName: <TFieldName extends keyof TitleReferenceModel & string>(key: TFieldName) => string };
  focusRef?: React.RefObject<ButtonBaseActions>;
  partLandDescriptionLimit?: number;
}

function resolveLandDescriptionLabel(extentType: PartLandExtentEnum) {
  const name = TRANSACTION_INSTRUMENTS$PARTLAND_AFFECTED_EXTENT_TYPE_LOOKUP_OPTIONS.find(x => x.id === extentType)!.name;
  const formattedLabel = name.replace('Part - ', '');
  return formattedLabel;
}

function PartLandAffected({
  //
  disabled,
  renderProps
}: Props): JSX.Element {
  const classes = useStyles();
  const { item, itemBinding, fieldName } = renderProps;
  const isPartLandAffected = item.isPartLandAffected;
  const extentType = item.partLandExtentType ?? PartLandExtentEnum.LeaseDescription;

  function handleChange(e: React.ChangeEvent<any>, resolvedValue: any, form?: FormikProps<any> | undefined): void {
    const { setFieldValue } = form!;
    //reset the fields
    setFieldValue(fieldName('partDescription'), '', false);
    setFieldValue(fieldName('landDescription'), '', false);
  }

  return (
    <Fieldset className={classes.partLandSection} data-testid={`${itemBinding}.partLandAffected`}>
      {isPartLandAffected && (
        <ArrowBox className={classNames(classes.landDescriptionsBox)} backgroundColor="darkGrey">
          <Field //
            name={fieldName('partLandExtentType')}
            component={SelectField}
            label="Extent"
            fullWidth
            validationOverflowDirection="horizontal"
            options={TRANSACTION_INSTRUMENTS$PARTLAND_AFFECTED_EXTENT_TYPE_LOOKUP_OPTIONS}
            onChange={handleChange}
            disabled={disabled}
          />
          <Field //
            name={extentType === PartLandExtentEnum.LeaseDescription ? fieldName('partDescription') : fieldName('landDescription')}
            component={InputField}
            label={resolveLandDescriptionLabel(extentType)}
            fullWidth
            validationOverflowDirection="horizontal"
            multiline
            minRows={3}
            disabled={disabled}
          />
        </ArrowBox>
      )}
    </Fieldset>
  );
}

export default React.memo(PartLandAffected);
