import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DeceasedTenancyDetailModel } from './models';

// this file was automatically generated from checks.ts.mustache

/**
 path:
 - deceasedProprietorGroups[*]
*/
export const VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP = createContextCheck((context: DeceasedTenancyDetailModel): boolean => context.proprietorGroups.length !== 0);
