import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import _get from 'lodash-es/get';
import _uniqueId from 'lodash-es/uniqueId';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { UpdateWorkspaceDocumentApiResponse } from '@sympli/api-gateway/models';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Formik from '@sympli/ui-framework/components/formik';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper, { WizardStepperClassKeys } from '@sympli/ui-framework/components/wizard-stepper';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { DocumentBaseTitleReferenceModel } from '../../models';
import MortgageePartyReadonlyArray from './components/mortgagee-party-readonly-array';
import { convertFromApiToFormModel, convertFromFormToApiModel } from './conversion';
import { ApiDischargeOfMortgage_2_23_0_Model, DischargeOfMortgage_2_23_0_Model } from './models';
import Mortgages from './sections/mortgages';
import SetOfOperativeWords from './sections/set-of-operative-words';
import styles, { ClassKeys } from './styles';
import validationSchema from './validationSchema';
import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
type FormValues = DischargeOfMortgage_2_23_0_Model;

export type Props = DocumentFormProps<FormValues, ApiDischargeOfMortgage_2_23_0_Model> & WithStyles<ClassKeys>;

const setOfOperativeWordsTitleId: string = _uniqueId();

class DocumentForm extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    isLoading: false,
    disabled: false,
    submitActionLabel: 'Save'
  };

  private readonly wizardStepperClasses: Partial<ClassNameMap<keyof ReturnType<WizardStepperClassKeys>>> = {
    container: this.props.classes.wizardStepperContainer,
    nextButton: this.props.onSaveChanges ? this.props.classes.wizardStepperNextButton : undefined
  };

  private dialogPortalId: string = _uniqueId();

  private formikProps: FormikProps<FormValues>;
  private focusOnMortgagesRef = React.createRef<ButtonBaseActions>();
  private focusOnTitleReferencesRef = React.createRef<ButtonBaseActions>();

  public componentDidMount(): void {
    this.focusOnFirst();
  }

  private focusOnFirst(): void {
    const focusOnFirstItem = this.focusOnMortgagesRef.current || this.focusOnTitleReferencesRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }

  private get isLoading(): boolean {
    return this.props.isLoading || this.formikProps?.isSubmitting || false;
  }

  private get disabled(): boolean {
    return this.props.disabled || this.isLoading;
  }

  private getInitialValues(): FormValues {
    return convertFromApiToFormModel(this.props.initialApiValues);
  }

  render(): JSX.Element {
    return (
      <Formik //
        initialValues={this.getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={this.handleOnSubmit}
        onPostSubmit={this.props.onPostSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (formikProps: FormikProps<FormValues>): JSX.Element => {
    this.formikProps = formikProps;
    this.props.onRenderForm(formikProps);

    const { classes, className, hideActionsPanel } = this.props;
    const disabled = this.disabled;

    return (
      <>
        <Form className={classNames(classes.root, className)}>
          {this.renderSections(formikProps, disabled)}
          {!hideActionsPanel && this.renderActionsPanel(disabled)}
        </Form>
        <PortalTarget id={this.dialogPortalId} />
      </>
    );
  };

  private renderSections(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const { values } = formikProps;
    return (
      <>
        {this.renderMortgagesSection(formikProps, disabled)}
        {VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES(values) && this.renderTitleReferencesSection(formikProps, disabled)}
        {VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES(values) && this.renderMortgageesSection(formikProps, disabled)}
        {this.renderSetOfOperativeWordsSection(formikProps, disabled)}
      </>
    );
  }

  private renderMortgagesSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const name: keyof FormValues = 'mortgages';

    return (
      <Mortgages //
        name={name}
        formikProps={formikProps}
        disabled={disabled}
        focusRef={this.focusOnMortgagesRef}
      />
    );
  }

  private renderTitleReferencesSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const { values } = formikProps;
    const name: keyof FormValues = 'titleReferences';
    const { classes } = this.props;
    const items = _get(values, name) ?? [];

    return (
      <Section //
        title={pluralize('Title reference', items.length)}
        data-name={name}
      >
        <GenericItemSelection //
          name={name}
          mode="multiple"
          variant="naked"
          disabled={disabled}
          focusRef={this.focusOnTitleReferencesRef}
          resolveItemLabel={this.resolveTitleReferenceLabel}
          noDataMessage="No titles found."
          className={classes.titleReferences}
        />
      </Section>
    );
  }

  private resolveTitleReferenceLabel(titleReference: DocumentBaseTitleReferenceModel) {
    return (
      <>
        Title reference:{' '}
        <strong>
          {titleReference.reference} ({titleReference.landDescriptions?.map(x => x.legalDescription).join(', ')})
        </strong>
      </>
    );
  }

  private renderMortgageesSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const { values } = formikProps;
    const name: keyof FormValues = 'mortgagees';
    const items = _get(values, name) ?? [];
    return (
      <Section //
        title={pluralize('Mortgagee', items.length)}
        data-name={name}
      >
        <MortgageePartyReadonlyArray //
          dialogPortalId={this.dialogPortalId}
          disabled={disabled}
          formikProps={formikProps}
          name={name}
        />
      </Section>
    );
  }

  private renderSetOfOperativeWordsSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const name: keyof FormValues = 'setOfOperativeWords';

    return (
      <Section //
        title="Set of operative words"
        titleId={setOfOperativeWordsTitleId}
        data-name={name}
      >
        <SetOfOperativeWords //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          ariaLabelledbyId={setOfOperativeWordsTitleId}
        />
      </Section>
    );
  }

  private renderActionsPanel(disabled: boolean): JSX.Element {
    const { submitActionLabel, onSaveChanges } = this.props;

    return (
      <WizardStepper
        isLoading={this.isLoading}
        disabled={disabled}
        backLabel="Cancel"
        onBack={this.handleOnBackClick}
        nextLabel={submitActionLabel}
        classes={this.wizardStepperClasses}
      >
        {onSaveChanges && this.renderSaveChangesBtn(disabled)}
      </WizardStepper>
    );
  }

  private renderSaveChangesBtn(disabled: boolean): JSX.Element {
    return (
      <SympliButton color="primary" variant="outlined" className={this.props.classes.wizardStepperSaveChangesButton} disabled={disabled} onClick={this.handleOnSaveChanges}>
        Save changes
      </SympliButton>
    );
  }

  private handleOnSaveChanges = (): void => {
    const apiValues = convertFromFormToApiModel(this.formikProps.values, this.props.initialApiValues);
    this.props.onSaveChanges!(apiValues, this.formikProps);
  };

  private handleOnSubmit = (values: FormValues, formikProps: FormikProps<FormValues>): Promise<UpdateWorkspaceDocumentApiResponse> => {
    const apiValues = convertFromFormToApiModel(values, this.props.initialApiValues);
    return this.props.onSave(apiValues, formikProps);
  };

  private handleOnBackClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    this.props.onCancel();
  };
}

export default withStyles(styles)(DocumentForm) as React.ComponentType<DocumentFormProps<FormValues, ApiDischargeOfMortgage_2_23_0_Model>>;
