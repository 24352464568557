import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { DIRECTION_PREFERENCE$CT_DIRECTION_LOOKUP_OPTIONS } from '../../enums';
import { DirectionPreferenceTypeModel } from '../../models';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionDirectionPreference({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DirectionPreferenceTypeModel>(name);

  return (
    <FormGroup //
      title={
        <>
          Select your preference
          <InfoIconWithTooltip
            flow="inline"
            offsetTop={0}
            tooltipContent="CT directions are only applicable when a new title is to be issued by the Land Registry.  They should only be completed by the party to whom the title will issue."
          />
        </>
      }
      fieldAreaDirection="column"
    >
      <Field //
        name={fieldName('ctDirection')}
        component={RadioField}
        disabled={disabled}
        format="number"
        label="Select your preference"
        options={DIRECTION_PREFERENCE$CT_DIRECTION_LOOKUP_OPTIONS}
        vertical={true}
      />
    </FormGroup>
  );
}

export default React.memo(SectionDirectionPreference);
