import React, { useCallback, useMemo } from 'react';

import { NavLink } from 'react-router-dom';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import MessageList from '@sympli/ui-framework/components/message-notification/components/message-list';
import { useLocalStorageState } from '@sympli/ui-framework/hooks';

import MessageNotificationV2 from 'src/components/message-notification';
import { resolveTitleDetailLink, resolveWorkspaceReadonly } from 'src/containers/workspace/titles/helper';
import { ChangedItem, TitleChangesNotification } from 'src/containers/workspace/titles/models';
import { useStyles } from './styles';

export interface RisTacNotificationProps {
  changedSet: ChangedItem[];
  userId: string;
  workspaceId: string;
  participantId: string;
  workspaceStatus: WorkspaceStatusEnum;
  className?: string;
  notificationType: 'Ris_Notification_WorkspaceOverview' | 'Tac_Notification_WorkspaceOverview';
}

function RisTacNotification({
  //
  changedSet,
  userId,
  workspaceId,
  participantId,
  workspaceStatus,
  className,
  notificationType
}: RisTacNotificationProps) {
  const classes = useStyles();
  const key = `${userId}/${participantId}/${notificationType}`;

  const [savedValue, setSavedValue] = useLocalStorageState<TitleChangesNotification | undefined>(key, undefined, {});

  const shouldDisplayNotification = useMemo(() => {
    if (resolveWorkspaceReadonly(workspaceStatus)) {
      return false;
    }

    if (changedSet.length === 0) {
      return false;
    }

    if (savedValue == null || savedValue.changeSet?.length === 0) {
      return true;
    }

    // make sure new changes already handled
    const storedIds = savedValue.changeSet.map(d => d.id);
    return !changedSet.every(d => storedIds.includes(d.id));
  }, [changedSet, savedValue, workspaceStatus]);

  const handleDismissNotification = useCallback(() => {
    const closedChangeSet: TitleChangesNotification = {
      changeSet: changedSet,
      closedAt: new Date()
    };

    setSavedValue(closedChangeSet);
  }, [setSavedValue, changedSet]);

  const subMessages = useMemo(() => {
    const messages = changedSet.map(item => {
      return (
        <>
          {notificationType === 'Ris_Notification_WorkspaceOverview' ? 'See title changes for' : 'See title information for'}
          <NavLink className={classes.navLink} to={resolveTitleDetailLink({ workspaceId, participantId, titleReference: item.title })}>
            {item.title}
          </NavLink>
        </>
      );
    });

    return <MessageList messages={messages} variant={notificationType === 'Ris_Notification_WorkspaceOverview' ? 'success' : 'warning'} />;
  }, [notificationType, changedSet, workspaceId, participantId, classes]);

  if (!shouldDisplayNotification) {
    return null;
  }

  return (
    <MessageNotificationV2
      variant={notificationType === 'Ris_Notification_WorkspaceOverview' ? 'success' : 'warning'}
      open={shouldDisplayNotification}
      onClose={handleDismissNotification}
      primary={notificationType === 'Ris_Notification_WorkspaceOverview' ? 'Title information ordered.' : 'Changes found on title.'}
      secondary={
        notificationType === 'Ris_Notification_WorkspaceOverview'
          ? 'All documents have been updated with latest title information'
          : 'To see change in detail, please order title information resupply (additional fees apply)'
      }
      expandableContent={subMessages}
      className={className}
    />
  );
}

export default React.memo(RisTacNotification);
