import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import wizardStepperStyles, { ClassKeys as WizardStepperClassKeys } from '@sympli-mfe/document-forms-framework/styles/wizard-stepper';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    ...wizardStepperStyles(theme),
    caveatorsUnselectableMessage: {
      padding: 20,
      fontSize: 13,
      fontStyle: 'italic'
    }
  });

export type ClassKeys = WizardStepperClassKeys & typeof styles;

export default styles;
