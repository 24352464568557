import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    smallField: {
      width: 120
    },
    labelCenter: {
      '& label': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },
    marginRight: {
      marginRight: 10
    },
    transferDetailsContainer: {
      width: 600
    }
  });

export type ClassKeys = typeof styles;

export default styles;
