import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import TitleReferences, { PartLandAffectedModel, TitleReferenceModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import DragNDropButton from '@sympli/ui-framework/components/drag-n-drop/drag-n-drop-button';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import Applicants from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/sections/applicants';
import TransactionInstruments from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/sections/transaction-instruments';
import { modelKey } from 'src/utils/formUtils';
import { PriorityNoticeConfig, TitleReferencesFieldConfig } from './config';
import { BasePriorityNoticeModel } from './models';

export interface Context<T extends PartLandAffectedModel> {
  TitleLabelComponent?: ({ item }: { item: TitleReferenceModel<T> }) => JSX.Element;
  priorityNoticeConfig: PriorityNoticeConfig;
  partyFormConfig: PartyFormConfig;
}

function RootForm<TPartLandAffected extends PartLandAffectedModel, TModel extends BasePriorityNoticeModel<TPartLandAffected>>({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const fieldName = modelKey<TModel>();
  const context = useRootFormContext<Context<TPartLandAffected>>();
  const {
    //
    isLoading,
    disabled,
    setNextActionDisabled,
    nextActionDisabled,
    dialogPortalId,
    nextActionLabel
  } = useDocumentContext();

  const formikProps = useFormikContext<TModel>();
  const { isDndMode, setIsDndMode } = useDndContext();
  const handleDndButtonClick = useCallback(() => {
    setIsDndMode(!isDndMode);
    setNextActionDisabled(!isDndMode);
  }, [isDndMode, setIsDndMode, setNextActionDisabled]);
  const { values } = formikProps;
  const hasMultipleTransactionInstruments = values.transactionInstruments.length > 1;
  const focusRefApplicants = React.useRef<ButtonBaseActions>(null);
  const focusRefTransactionInstruments = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusRefApplicants.current || focusRefTransactionInstruments.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }

    focusOnFirstItem.focusVisible();
  }, [focusRefApplicants, focusRefTransactionInstruments]);

  return (
    <>
      <Form className={className}>
        <Section title="Applicants">
          <Applicants //
            name={fieldName('applicants')}
            focusRef={focusRefApplicants}
            dialogPortalId={dialogPortalId}
            partyFormConfig={context.partyFormConfig}
          />
        </Section>

        <Section
          title="Transaction dealings"
          titleSuffix={
            //
            hasMultipleTransactionInstruments ? (
              <DragNDropButton //
                onClick={handleDndButtonClick}
                activated={isDndMode}
                disabled={disabled}
              />
            ) : undefined
          }
        >
          <TransactionInstruments //
            name={fieldName('transactionInstruments')}
            focusRef={focusRefTransactionInstruments}
            dialogPortalId={dialogPortalId}
            fieldName={fieldName}
            renderTitleReferenceComponent={function (name: string, config: TitleReferencesFieldConfig): JSX.Element {
              return (
                <TitleReferences //
                  name={name}
                  caption="Title references"
                  TitleLabelComponent={context!.TitleLabelComponent}
                  variant="naked"
                  partLandAffectedType="description"
                  partLandDescriptionLimit={config.partLandDescriptionLimit}
                />
              );
            }}
            priorityNoticeConfig={context!.priorityNoticeConfig}
            partyFormConfig={context.partyFormConfig}
          />
        </Section>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
