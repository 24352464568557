import { AdditionalAttachmentsDocumentTypeEnum } from '@sympli-mfe/enums-2-23/wa';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache
/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 path:
 - extentOfLandDischarged
 - extentOfMoneyDischarged

 schema version:
[{"id":0,"value":"Whole","name":"Whole","_meta":null},{"id":1,"value":"Part","name":"Part","_meta":null}]

 hash:
 WzAsMV0=
*/
export enum ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum {
  Whole = 0,
  Part = 1
}
export const EXTENT_OF_LAND_DISCHARGED_OR_EXTENT_OF_MONEY_DISCHARGED_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum.Whole,
    name: 'Whole'
  },
  {
    id: ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum.Part,
    name: 'Part'
  }
];

// only subset of NECDS lookup options
export const ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<AdditionalAttachmentsDocumentTypeEnum>[] = [
  {
    id: AdditionalAttachmentsDocumentTypeEnum.AustralianSecuritiesInvestmentCommissionCertificate,
    name: 'Australian Securities Investment Commission Certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.ChangeOfNameCertificate,
    name: 'Change of name certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.CitizenshipCertificate,
    name: 'Citizenship certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.ConsentLetter,
    name: 'Consent letter'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.CourtOrder,
    name: 'Court Order'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.MarriageCertificate,
    name: 'Marriage certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.Other,
    name: 'Other'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.PaidDutyCertificate,
    name: 'Paid Duty Certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.StatutoryDeclaration,
    name: 'Statutory Declaration'
  }
];
