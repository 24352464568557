import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import { SelectProps } from '@mui/material/Select';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useSelectRefCallbackToAssignFauxButtonActions } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { LodgementInstructions2_19_1Model } from '../../models';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionPriorityNotices({ name, focusRef }: Props): JSX.Element {
  const { values } = useFormikContext<LodgementInstructions2_19_1Model>();
  const { priorityNotices } = values;
  const { disabled } = useDocumentContext();
  const priorityNoticesOptions = priorityNotices?.map(({ dealingNumber }) => ({ id: dealingNumber, name: dealingNumber }));

  const selectRefCallback = useSelectRefCallbackToAssignFauxButtonActions(focusRef);
  const selectProps = useMemo(
    (): SelectProps => ({
      inputRef: selectRefCallback
    }),
    [selectRefCallback]
  );

  return (
    <Section title="Priority notice" data-section={name}>
      <FormGroup //
        title="Dealing number (optional)"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={name}
            component={SelectField}
            options={priorityNoticesOptions}
            disabled={disabled}
            placeholder="Optional (please select if applicable)"
            fullWidth
            SelectProps={selectProps}
          />
        )}
      </FormGroup>
    </Section>
  );
}

export default React.memo(SectionPriorityNotices);
