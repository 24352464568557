import { BankAccountTypeEnum, LoanAdvanceCategoryEnum, PaymentMethodEnum } from '@sympli/api-gateway/enums';
import { LoanAdvanceModel } from '@sympli/api-gateway/shared';

import { SourceFundFormikModel } from '../../models';
import { LOAN_ADVANCE_CATEGORY, LOAN_ADVANCE_RANGE_CATEGORY } from '../edit-directions/forms/income-mortgage-directions/helper';

export function getReadOnlyLoanAdvanceSourceFund(loanAdvance: LoanAdvanceModel, autoBalancedLoanAdvanceAmount: number): SourceFundFormikModel {
  const loanAdvanceDisplayAmount = loanAdvance.category === LoanAdvanceCategoryEnum.Range && autoBalancedLoanAdvanceAmount > 0 ? autoBalancedLoanAdvanceAmount : loanAdvance.amount;

  return {
    id: loanAdvance.id,
    trustAccountId: loanAdvance.accountId ?? '',
    amount: loanAdvance.amount,
    accountType: BankAccountTypeEnum.BankAccount,
    bankDetails: loanAdvance.bankDetails,
    reference: loanAdvance.reference,
    subscriberName: loanAdvance.subscriberName ?? '',
    participantId: loanAdvance.participantId,
    category: loanAdvance.isAutoBalancingSurplusForIMAllowed ? LOAN_ADVANCE_CATEGORY : LOAN_ADVANCE_RANGE_CATEGORY,
    isAutoBalancingSurplusForIMAllowed: loanAdvance.isAutoBalancingSurplusForIMAllowed,
    isEditorOpen: false,
    initialLoanAdvanceAmount: loanAdvanceDisplayAmount,
    displayLoanAdvanceAmount: loanAdvanceDisplayAmount,
    paymentMethod: PaymentMethodEnum.BankTransfer
  };
}
