import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import {
  SA_ADDRESS_FORM_CONFIG as SA_M_ADDRESS_FORM_CONFIG,
  SA_ADDRESS_FORM_INITIAL_VALUES as SA_M_ADDRESS_FORM_INITIAL_VALUES
} from '@sympli-mfe/document-components/address-field/sa/2-23/config';
import SectionTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { PARTY_CAPACITY_LOOKUP_OPTIONS, StateEnum } from '@sympli-mfe/enums-2-23/sa';

import { SA_MORTGAGE_PARTY_FORM_CONFIG, SA_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { Mortgage_2_23_1_Model } from '../../models';

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionMortgageeDetails({ name, focusRef }: Props): JSX.Element {
  const {
    values: { mortgagors }
  } = useFormikContext<Mortgage_2_23_1_Model>();

  const mortgagorsPartyIds = useMemo(
    () =>
      mortgagors.proprietorGroups
        .flatMap(pg => pg.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgagors]
  );

  return (
    <SectionTenancy //
      name={name}
      jurisdiction={StateEnum.SA}
      componentLabels={SA_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG}
      // party setup
      partyFormConfig={SA_MORTGAGE_PARTY_FORM_CONFIG}
      // TODO Check correctness of this
      // should this be imported from local enum file, or not?
      partyCapacityLookup={PARTY_CAPACITY_LOOKUP_OPTIONS}
      // address setup
      addressFormConfig={SA_M_ADDRESS_FORM_CONFIG}
      addressFormInitialValues={SA_M_ADDRESS_FORM_INITIAL_VALUES}
      enableJointType={true}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={mortgagorsPartyIds}
      focusRef={focusRef}
    />
  );
}

export default React.memo(SectionMortgageeDetails);
