import * as React from 'react';

import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

const useStyles = makeStyles(
  (theme: Theme) => ({
    first: {
      fontWeight: 600,
      lineHeight: '16px'
    },
    second: {
      lineHeight: '12px'
    },
    third: {
      fontSize: 11,
      lineHeight: '11px',
      color: colors.NEUTRAL_600
    }
  }),
  {
    name: 'ThreeLineContent'
  }
);

function ThreeLineContent({
  //
  first,
  second,
  third,
  primaryClassName,
  secondaryClassName,
  thirdClassName
}: {
  first: string;
  second: string;
  third?: string;
  primaryClassName?: string;
  secondaryClassName?: string;
  thirdClassName?: string;
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classNames('primary', classes.first, primaryClassName)}>{first}</div>
      <div className={classNames('secondary', classes.second, secondaryClassName)}>{second}</div>
      {third && <div className={classNames(classes.third, thirdClassName)}>{third}</div>}
    </>
  );
}

export default ThreeLineContent;
