import * as React from 'react';

import { FormikProps, FormikValues } from 'formik';
import _get from 'lodash-es/get';
import FormHelperText from '@mui/material/FormHelperText';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconExclamationCircle } from '@sympli/ui-framework/icons';

import { createModelKeyAppender } from 'src/utils/formUtils';
import styles, { ClassKeys } from './styles';

interface OwnProps<T extends FormikValues> {
  name: string;
  formikProps: FormikProps<T>;
}

export type Props<T extends FormikValues> = OwnProps<T> & WithStyles<ClassKeys>;

const DistributionRecordError = <T extends FormikValues>(props: Props<T>) => {
  const { classes, formikProps, name } = props;
  const error = _get(formikProps.errors, name);

  if (error) {
    const fieldName = createModelKeyAppender<T>(name)('amount');
    const errMsg = error[fieldName] ?? 'Record error';
    return (
      <FlexLayout alignItems="center">
        <IconExclamationCircle />
        <FormHelperText className={classes.root}>{errMsg} </FormHelperText>
      </FlexLayout>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(DistributionRecordError);
