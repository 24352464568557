import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionLodgementOrderSummary from '@sympli-mfe/document-forms-framework/components/sections/lodgement-order-summary';
import SectionResponsibleSubscriber from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { LodgementInstructions2_20_1Model } from './models';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<LodgementInstructions2_20_1Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onCancel,
  onSaveChanges
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<LodgementInstructions2_20_1Model>();
  const { values } = formikProps;
  const { participants } = values;
  const { isDndMode } = useDndContext();
  const participantOptions = participants?.map(({ id, role, subscriber }) => ({ id: id, name: `${subscriber?.legalName} (${role})` })) ?? [];
  const focusSubscriberPartyRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    if (participantOptions.length > 1 && focusSubscriberPartyRef.current) {
      focusSubscriberPartyRef.current.focusVisible();
      return;
    }
  }, [participantOptions.length]);

  return (
    <>
      <Form className={className}>
        <SectionResponsibleSubscriber //
          name={fieldName('responsibleParticipantId')}
          options={participantOptions}
          focusRef={focusSubscriberPartyRef}
        />

        <Subform subformBindingPath={fieldName('lodgementOrderSummary')}>
          {() => (
            //
            <SectionLodgementOrderSummary title="Lodgement order" />
          )}
        </Subform>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled || isDndMode}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
