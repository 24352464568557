import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';
import { IconActiveLink } from './Icons';

interface TopPanelLinkProps extends LinkProps {
  isActive?: boolean;
  secondary?: boolean;
}

const useStyles = (secondary?: boolean) =>
  makeStyles(
    () => ({
      root: {
        color: colors.NEUTRAL_800,
        textAlign: 'center',
        fontFamily: 'volkswagen_serial-medium',
        fontSize: secondary ? 12 : 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: secondary ? '18px' : '20px',
        letterSpacing: secondary ? '0.6px' : '0.7px',
        textTransform: 'uppercase',
        textDecoration: 'none',

        '&:hover': {
          color: colors.NEUTRAL_1000,
          textAlign: 'center',
          fontFamily: 'volkswagen_serial-xbold',
          fontSize: secondary ? 12 : 14,
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: secondary ? '18px' : '20px',
          letterSpacing: secondary ? '0.6px' : '0.7px'
        }
      },
      active: {
        color: colors.NEUTRAL_1000,
        textAlign: 'center',
        fontFamily: 'volkswagen_serial-xbold',
        fontSize: secondary ? 12 : 14,
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: secondary ? '18px' : '20px',
        letterSpacing: secondary ? '0.6px' : '0.7px'
      }
    }),
    { name: 'TopPanelLink' }
  );

export default function TopPanelLink({ isActive, className, secondary, ...rest }: TopPanelLinkProps) {
  const classes = useStyles(secondary)();
  const testId = typeof rest.children === 'string' ? rest.children : '';
  return (
    <>
      <Link
        //
        {...rest}
        className={classNames(
          //
          classes.root,
          isActive && classes.active,
          'peer z-[5] px-[16px]',
          className
        )}
        data-testid={`navigation-${testId.toLowerCase()}`}
      />
      <IconActiveLink
        //
        aria-hidden
        className={classNames(isActive ? 'block' : 'hidden', 'absolute left-[50%] peer-hover:block')}
        style={{
          //
          transform: 'translate(-50%, -50%)',
          bottom: 'calc(50% - 18px)',
          width: secondary ? '14px' : '32px'
        }}
      />
    </>
  );
}
