import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { modelKey } from 'src/utils/formUtils';
import { isWithdrawReasonOther } from './helpers';
import { WithdrawParticipantFormikModel } from './model';

const fieldName = modelKey<WithdrawParticipantFormikModel>();

export const getValidationSchema = () => {
  return yup.object<WithdrawParticipantFormikModel>({
    reasonOption: yup.number().nullable().required(msg.REQUIRED),
    reasonDescription: yup
      .string()
      .trim()
      .when(fieldName('reasonOption'), {
        is: val => isWithdrawReasonOther(val),
        then: yup.string().trim().max(255, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(255)).required(msg.REQUIRED)
      }),
    isApplyToLinkedWorkspace: yup.boolean()
  });
};
