import * as React from 'react';

type DashboardContextType = { currentUpdatedTime: Date; resetCurrentUpdatedTime: (date: Date) => void };

const InvitationsDashboardContext = React.createContext<DashboardContextType>({
  currentUpdatedTime: new Date(),
  resetCurrentUpdatedTime: () => {}
});

export default InvitationsDashboardContext;
