import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { SHARE_TRANSFERRED_TYPE_OPTIONS, ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { DEFAULT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

function formatNewName(partyType: PartyTypeEnum, nameChangeModel: NswNameChange): string {
  if (partyType === PartyTypeEnum.Individual) {
    const { firstName, lastName } = nameChangeModel;
    return [firstName, lastName].filter(Boolean).join(' ');
  } else if (partyType === PartyTypeEnum.Organisation) {
    const { organisationName } = nameChangeModel;
    return organisationName;
  } else {
    throw new Error('Unsupported party type.');
  }
}

const allowedShareTransferredType: ShareTransferredTypeEnum[] = [ShareTransferredTypeEnum.Whole, ShareTransferredTypeEnum.Partial];
export const NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE: LookupEnumModel<ShareTransferredTypeEnum, string>[] = SHARE_TRANSFERRED_TYPE_OPTIONS.filter(x =>
  allowedShareTransferredType.includes(x.id)
);

export const PARTY_FORM_WITH_NAME_CHANGE_CONFIG: PartyFormConfig<NswNameChange> = {
  ...DEFAULT_PARTY_FORM_CONFIG,
  nameChangeConfig: nswPartyNameChangeConfig
};

export const NSW_NOD_PARTY_FORM_CONFIG: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName }
};

export const NSW_NOD_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: undefined
};
