import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { getPartyEntityTitle } from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import TransferorDetail from '../../components/transferor-detail';
import { NoticeOfAcquisitionDocumentModel, TransferorDetailModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
}

function SectionTransferors({ name }: Props): JSX.Element {
  const { values } = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<TransferorDetailModel>) => <TransferorDetail name={itemBinding} />,
    []
  );

  const resolveItemTitle = useCallback(
    ({ item }: DocumentArrayItemRenderProps<TransferorDetailModel>) => {
      const party = values.partyBook?.find(book => book.id === item.partyBookId);
      return party ? getPartyEntityTitle(party) : 'Individual or organisation';
    },
    [values.partyBook]
  );

  return (
    <div data-name={name} className={classes.root}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={resolveItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(SectionTransferors);
