import * as React from 'react';

import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import dateFormat from 'dateformat';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DateFormatEnum, DateValueMapModel } from '../../models';
import DateCell from './date-cell';
import { DateCellBaseModel, DateCellStatusEnum } from './date-cell/models';
import styles, { ClassKeys } from './styles';
import { initialiseWeeks } from './utils';

interface OwnProps {
  className?: string;
  yearAndMonth: string;
  jurisdictionId: JurisdictionsEnum;
  data?: DateValueMapModel;
  currentSettlementDate?: string;
  proposedSettlementDate?: string;
  selectedDate?: string;
  isDateUnsupported?: boolean; // For IOP usage, a selected date might be unsupported from PEXA, or other RELNO
  onCellClick?: (event: React.MouseEvent<HTMLTableRowElement>, item: any, date: string) => void;
  settlementAcceptedByAll?: boolean;
  isEditing?: boolean;
}

interface State {
  yearAndMonth?: string;
  jurisdictionId?: JurisdictionsEnum;
  weeksInTable: Array<Array<DateCellBaseModel>>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class MonthTable extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    yearAndMonth: dateFormat(new Date(), DateFormatEnum.DATE),
    data: {},
    currentSettlementDate: undefined,
    selectedDate: undefined,
    onCellClick: undefined
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      yearAndMonth: undefined,
      jurisdictionId: undefined,
      weeksInTable: []
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
    const { yearAndMonth, jurisdictionId } = nextProps;
    if (
      !prevState || // we need to check for empty state as this component does not initialize it' state
      yearAndMonth !== prevState.yearAndMonth ||
      jurisdictionId !== prevState.jurisdictionId
    ) {
      return {
        yearAndMonth,
        jurisdictionId,
        weeksInTable: initialiseWeeks(yearAndMonth, jurisdictionId)
      };
    }
    return null;
  }

  render() {
    const { classes, className } = this.props;

    return (
      <FlexLayout flexDirection="column" className={classNames(classes.root, className)}>
        <FlexLayout flexDirection="row" alignItems="center" className={classNames(classes.header, classes.row)}>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
        </FlexLayout>
        {this.renderDates()}
      </FlexLayout>
    );
  }

  private renderDates() {
    const { isDateUnsupported, data, classes, onCellClick, currentSettlementDate, proposedSettlementDate, settlementAcceptedByAll, isEditing, selectedDate } = this.props;
    const settlementDate = currentSettlementDate ? new Date(currentSettlementDate) : undefined;
    const selected = selectedDate ? new Date(selectedDate) : undefined;

    return this.state.weeksInTable.map((week, i) => {
      return (
        <FlexLayout flexDirection="row" className={classes.row} key={'row-' + i}>
          {week.map((day, j) => {
            const formattedDate: string = dateFormat(day.date, DateFormatEnum.DATE);
            const { count: value, hourCount: item } = data?.[formattedDate] || {};
            const status = this.getCellStatus(day, { settlementDate, selected }, settlementAcceptedByAll);
            const isSelectedDate = this.isSelectedDate(day.date);
            const isProposedSettlementDateUnsupported = isDateUnsupported && proposedSettlementDate !== undefined;
            // dateDetail will not be updated unless the yearAndMonth change
            // * DateCell (PureComponent) will only be updated when isSelectedDate change
            return (
              <DateCell
                key={`row-${i}-cell-${j}`}
                dateDetail={day}
                value={value}
                item={item}
                status={status}
                isSelectedDate={isSelectedDate}
                onCellClick={onCellClick}
                settlementAcceptedByAll={settlementAcceptedByAll}
                isEditing={isEditing}
                isUnsupported={isProposedSettlementDateUnsupported && isSameDay(day.date, new Date(proposedSettlementDate))}
              />
            );
          })}
        </FlexLayout>
      );
    });
  }

  private getCellStatus(
    dateDetail: DateCellBaseModel,
    dates: {
      settlementDate?: Date;
      selected?: Date;
    },
    settlementAcceptedByAll?: boolean
  ): DateCellStatusEnum {
    const { settlementDate: currentSettlementDate, selected: selectedDate } = dates;
    const date = dateDetail.date;

    // if the date is same as currentSettlementDate and selectedDate: use green if settlement date was accepted by all, otherwise amber
    if (currentSettlementDate && selectedDate && isSameDay(date, currentSettlementDate) && isSameDay(date, selectedDate)) {
      return settlementAcceptedByAll ? DateCellStatusEnum.CurrentSettlement : DateCellStatusEnum.ProposedSettlement;
    }
    // if the date is same as selected date, use amber
    if (selectedDate && isSameDay(date, selectedDate)) {
      return DateCellStatusEnum.ProposedSettlement;
    }
    // if the date is same as current settlement date, use green
    if (currentSettlementDate && isSameDay(date, currentSettlementDate)) {
      return DateCellStatusEnum.CurrentSettlement;
    }
    // otherwise, use grey as default
    return DateCellStatusEnum.Default;
  }

  private isSelectedDate(date: Date) {
    const { selectedDate } = this.props;
    return typeof selectedDate !== 'undefined' && isSameDay(date, new Date(selectedDate!));
  }
}

export default withStyles(styles)(MonthTable);
