import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    actionIcon: {
      fontSize: 24,
      fill: theme.palette.action.disabled
    },
    searchBoxIconSearch: {
      margin: '0px 28px',
      width: 32,
      height: 32,
      fill: '#4D5258',
      cursor: 'pointer'
    },
    searchBoxIconClose: {
      marginRight: 28,
      width: 32,
      height: 32,
      cursor: 'pointer'
    },
    searchInput: {
      width: 960,
      flexShrink: 0,
      marginBottom: 0,
      fontFamily: 'volkswagen_serial-medium',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      '& input:placeholder-shown': {
        fontSize: '24px',
        color: colors.NEUTRAL_400
      },
      '& input:not(:placeholder-shown)': {
        fontSize: '40px',
        color: colors.NEUTRAL_1000
      },
      '@media (min-width:1280px) and (max-width: 1536px)': {
        paddingLeft: 100,
        paddingRight: 100
      },
      '@media (min-width:1536px) and (max-width:1920px)': {
        paddingLeft: 208,
        paddingRight: 208
      },
      '@media (min-width:1920px)': {
        paddingLeft: 400,
        paddingRight: 400
      }
    },
    inputStyle: {
      '& .MuiInputAdornment-positionEnd': {
        marginRight: 1
      },
      borderRadius: 44,
      fontWeight: 500,
      background: 'var(--secondary-sunny-day-translucent, #FFF9E1)',
      border: 'none',
      '& .MuiInput-input:-webkit-autofill:focus': {
        // This is a hacky way to stop browser update bg color
        transition: 'background-color 0s 600000s'
      },
      '& .MuiInput-input:-webkit-autofill': {
        // This is a hacky way to stop browser update bg color
        transition: 'background-color 0s 600000s'
      },
      '&.Mui-focused': {
        boxShadow: 'none'
      }
    },
    inputBox: {
      height: 32,
      padding: '28px 0px'
    }
  }),
  {
    name: 'GlobalSearchBox'
  }
);
