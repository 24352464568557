import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchLinkedSettlementDetail } from '../actions';
import { LinkedSettlementDetailApiRequest, LinkedSettlementDetailApiResponse } from '../models';

export interface LinkedSettlementWorkspacesState {
  detail?: LinkedSettlementDetailApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: LinkedSettlementDetailApiRequest;
}

const initialState: LinkedSettlementWorkspacesState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useLinkedSettlementWorkspaces(workspaceId: string, clusterId?: string) {
  const state = useStoreSelector(store => store.linkedSettlementDetail);
  if (state.args?.workspaceId === workspaceId && state.args.clusterId === clusterId) {
    return state;
  }
  return initialState;
}

const linkedSettlementDetailReducer = createReducer<
  //
  LinkedSettlementWorkspacesState,
  Action
>(initialState)
  .handleAction(actionFetchLinkedSettlementDetail.request, (state, action): LinkedSettlementWorkspacesState => {
    const status =
      _isEqual(state.args?.workspaceId, action.payload.workspaceId) && _isEqual(state.args?.clusterId, action.payload.clusterId)
        ? state.status === 'pending'
          ? 'pending'
          : 'refetching'
        : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchLinkedSettlementDetail.success, (state, action): LinkedSettlementWorkspacesState => {
    return {
      ...state,
      detail: action.payload.detail,
      status: 'resolved',
      error: undefined
    };
  })
  .handleAction(actionFetchLinkedSettlementDetail.failure, (state, action): LinkedSettlementWorkspacesState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default linkedSettlementDetailReducer;
