import * as React from 'react';

import { useField, useFormikContext } from 'formik';
import _isEqual from 'lodash-es/isEqual';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import FinalHoldings, { FinalTenancyHoldingsModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/final-holdings';
import { FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { RelinquishingTenancyDetailModelWithMeta } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { CommonTenancyLabelsEnum, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import { NSW_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { Transfer2_21_1Model } from '../../models';
import { buildFinalTenancyHoldings } from '../../tenancyHoldingHelper';

const fieldName = modelKey<Transfer2_21_1Model>();
interface Props {
  name: string;
}

function FinalTenancyHoldings({ name }: Props) {
  const { setFieldValue } = useFormikContext<Transfer2_21_1Model>();
  const [, { value: partyBook }] = useField<PartyModel<NswNameChange>[]>(fieldName('partyBook'));
  const [, { value: transferors }] = useField<RelinquishingTenancyDetailModelWithMeta<TenancyPartyModel>>(fieldName('transferors'));
  const [, { value: transferees }] = useField<FormReceivingTenancyDetailModel>(fieldName('transferees'));
  const [, { value: finalTenancyHoldingsFromForm }] = useField<FinalTenancyHoldingsModel>(fieldName('finalTenancyHoldings'));

  const finalTenancyHoldings = buildFinalTenancyHoldings(transferors.relinquishingProprietorGroups, transferees, partyBook);
  const tenancyHoldingsHasChanged = !_isEqual(finalTenancyHoldingsFromForm, finalTenancyHoldings);

  React.useEffect(() => {
    if (tenancyHoldingsHasChanged) {
      setFieldValue(fieldName('finalTenancyHoldings'), finalTenancyHoldings);
    }
  }, [finalTenancyHoldings, setFieldValue, tenancyHoldingsHasChanged]);
  return (
    <FinalHoldings //
      name={name}
      partyBook={partyBook}
      partyFormConfig={NSW_TRANSFER_PARTY_FORM_CONFIG}
      jointTenantsLabel={CommonTenancyLabelsEnum.JointTenants}
    />
  );
}
export default React.memo(FinalTenancyHoldings);
