import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    section: {
      marginTop: 36
    },
    fullWidth: {
      width: '100%'
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    },
    stickyTopPanel: {
      padding: '0 32px',
      height: 60,
      background: theme.palette.grey[50],
      flexShrink: 0,
      position: 'sticky',
      top: 0,
      zIndex: 2
    }
  });

export type ClassKeys = typeof styles;

export default styles;
