import React from 'react';

import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';

import { actionCloseLodgementVerificationDialog } from 'src/containers/workspace/shared/components/lodgement-verification-dialog/actions';
import LodgementVerificationResultContainer from 'src/containers/workspace/shared/components/lodgement-verification-result';
import { useStoreSelector } from 'src/hooks';
import { useStyles } from './styles';

function LodgementVerificationDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, authorityMessageId, authorityComplianceReportType, lodgementCaseId } = useStoreSelector(store => store.lodgementVerificationDialog);
  const onClose = () => {
    dispatch(actionCloseLodgementVerificationDialog());
  };

  return (
    <Dialog //
      fullWidth
      maxWidth={false}
      open={isOpen}
      onClose={onClose}
      classes={{
        root: classes.lvDialogRoot,
        paper: classes.lvDialogPaperNewLayout
      }}
      hideBackdrop
    >
      {isOpen && (
        <LodgementVerificationResultContainer //
          onClose={onClose}
          authorityMessageId={authorityMessageId}
          authorityComplianceReportType={authorityComplianceReportType}
          lodgementCaseId={lodgementCaseId}
        />
      )}
    </Dialog>
  );
}

export default React.memo(LodgementVerificationDialog);
