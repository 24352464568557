import React from 'react';

import { NavigateFunction, Params, useNavigate } from 'react-router-dom';

import { useRouterParams } from 'src/hooks';

export interface RouterProps {
  routerParams: Params;
  navigate: NavigateFunction;
}

function withRouterProps<P>(Component: React.ComponentType<P & RouterProps>) {
  function ComponentWithRouterProp(props: P) {
    const routerParams = useRouterParams();
    const navigate = useNavigate();
    return <Component {...props} routerParams={routerParams} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

export default withRouterProps;
