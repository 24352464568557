import * as React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { IndividualTransferor, OrganisationTransferor } from '../../components/transferor';
import { Form24B_2_24_1Model } from '../../models';

type FormValues = Form24B_2_24_1Model;

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function TransfereeDetails({ name, focusRef }: Props) {
  const formikProps = useFormikContext<FormValues>();
  const { values } = formikProps;
  const { disabled } = useDocumentContext();

  const { partyBook } = values;

  const getPartyTitles = () => {
    return partyBook.reduce(
      (prev, current) => {
        if (current.partyType === PartyTypeEnum.Individual) {
          prev.inv += 1;
          prev[current.id] = `Individual ${prev.inv}`;
        } else {
          prev.org += 1;
          prev[current.id] = `Organisation ${prev.org}`;
        }

        return prev;
      },
      { org: 0, inv: 0 }
    );
  };

  const partyTitles = getPartyTitles();

  const renderItemTitle = ({ item }: DocumentArrayItemRenderProps<ApiPartyReferenceModel>) => {
    return partyTitles[item.partyBookId];
  };

  const renderItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<ApiPartyReferenceModel>) => {
    const party = partyBook.find(pb => pb.id === item.partyBookId)!;
    return party.partyType === PartyTypeEnum.Individual ? ( //
      <IndividualTransferor party={party} name={itemBinding} />
    ) : (
      <OrganisationTransferor party={party} name={itemBinding} />
    );
  };

  const renderTransferors = () => {
    const isEmpty = !values.transferors.length;

    if (isEmpty) {
      return (
        <FormGroup title="Individual or Organisation">
          <ReadOnlyField //
            label="Name"
            value=""
          />
        </FormGroup>
      );
    }
    return (
      <DocumentFieldArray //
        arrayBinding={name}
        itemTitle={renderItemTitle}
        renderItem={renderItem}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        focusRef={focusRef}
        mode="fixed"
      />
    );
  };

  return <Section title="Transferor's details">{renderTransferors()}</Section>;
}

export default React.memo(TransfereeDetails);
