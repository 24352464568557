import React, { useMemo } from 'react';

import { FormikHelpers, useField } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import { JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import FormGroup, { FormGroupClassKeys } from '@sympli/ui-framework/components/form/layout/form-group';
import AutocompleteField from '@sympli/ui-framework/components/formik/autocomplete-field';
import Field from '@sympli/ui-framework/components/formik/field';
import { IconSearch } from '@sympli/ui-framework/icons';

import { createModelKeyAppender } from 'src/utils/formUtils';
import { DocumentInvitationItemModel, SubscriberSearchApiResponse, SubscriberSearchItemModel } from '../../../../models';
import { InvitationItemModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  label: string;
  name: string;

  jurisdictionId: JurisdictionsEnum;
  roleId: WorkspaceRoleEnum;
  workspaceTypeId: WorkspaceTypeEnum;
  isControllingParty: boolean;
  // helpers
  setFieldValue: FormikHelpers<any>['setFieldValue'];

  onSubscriberSuggestionsRequested(name: string): Promise<SubscriberSearchApiResponse>;
}

function ParticipantInviteDetail({
  //
  name,
  label,
  roleId,
  jurisdictionId,
  workspaceTypeId,
  isControllingParty,
  // helpers
  setFieldValue,
  onSubscriberSuggestionsRequested
}: Props) {
  const classes = useStyles();
  const [, { value }] = useField<DocumentInvitationItemModel>(name);
  const fieldName = createModelKeyAppender<InvitationItemModel>(name);
  const fieldNameSubscriber = createModelKeyAppender<SubscriberSearchItemModel>(fieldName('subscriber'));

  const formGroupClasses = useMemo<Partial<ClassNameMap<keyof ReturnType<FormGroupClassKeys>>>>(
    () => ({
      container: classes.formGroupContainer,
      title: classes.formGroupTitle,
      columnTitleContainer: classes.formGroupColumnTitleContainer
    }),
    [classes]
  );

  const handleOnSuggestionSelected = (
    _e: React.ChangeEvent<HTMLInputElement>, //
    selectedSuggestion: SubscriberSearchItemModel
  ) => {
    setFieldValue(fieldName('subscriber'), selectedSuggestion);
  };

  return (
    <FormGroup //
      key={name}
      title={label}
      classes={formGroupClasses}
    >
      {!isControllingParty && (
        <>
          {value.subscriberIsReadOnly ? (
            <ReadOnlyField label="Subscriber name" value={value.subscriber.name} />
          ) : (
            <Field //
              fullWidth
              label="Search by subscriber name or ABN"
              name={fieldNameSubscriber('id')}
              resolvedValueName={fieldNameSubscriber('name')}
              component={AutocompleteField}
              onSuggestionSelected={handleOnSuggestionSelected}
              getSuggestions={onSubscriberSuggestionsRequested}
              placeholder="Please type at least 3 characters"
              searchingStartsAt={3}
              endAdornment={<IconSearch style={{ marginRight: 4 }} />}
              className={classes.searchContainer}
              classes={{ suggestionsContainer: classes.suggestionsContainer }}
            />
          )}
        </>
      )}
    </FormGroup>
  );
}

export default React.memo(ParticipantInviteDetail);
