import * as React from 'react';

import { DocumentPermissionEnum } from '@sympli/api-gateway/enums';

import { isCurrentUserWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useRouterParams } from 'src/hooks';
import { useTrustAccountAuthorisationRecords } from './reducer';
import TrustAccountAuthorizationRecords2 from './TrustAccountAuthorisationRecords';

export interface TrustAccountAuthorisationRecordsRouteParams {
  workspaceId: string;
  participantId: string;
  trustAccountId: string;
}

function TrustAccountAuthorisationRecordsContainer() {
  const { workspaceId, participantId, trustAccountId } = useRouterParams<TrustAccountAuthorisationRecordsRouteParams>();

  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const trustAccountAuthorisationRecordsState = useTrustAccountAuthorisationRecords(workspaceId, participantId, trustAccountId);
  const isCurrentUserWorkspaceParticipant: boolean = isCurrentUserWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState,
    workspaceStatusId: workspaceBasicInfoState.detail?.workspaceStatusId,
    workspaceTypeId: workspaceBasicInfoState.detail?.workspaceTypeId
  });

  // we need to wait for workspace participants since we need it to resolve whether user is current participant or not
  const isLoading = trustAccountAuthorisationRecordsState.isLoading || trustAccountAuthorisationRecordsState.isRefetching || workspaceParticipantsState.isLoading;
  const disableNextActionButton = !trustAccountAuthorisationRecordsState.detail?.permissions.includes(DocumentPermissionEnum.Sign);

  // we don't want the user to see the content
  // TODO create hoc that will take care of this
  if (!isLoading && !isCurrentUserWorkspaceParticipant) {
    return null;
  }

  return (
    <TrustAccountAuthorizationRecords2
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      trustAccountId={trustAccountId}
      // redux data
      detail={trustAccountAuthorisationRecordsState.detail}
      isLoading={isLoading}
      disableNextActionButton={disableNextActionButton}
    />
  );
}

export default React.memo(TrustAccountAuthorisationRecordsContainer);
