import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    largePadding: {
      paddingBottom: 40
    },
    largeError: {
      left: 0,
      width: 490
    }
  }),
  {
    name: 'McpNumber'
  }
);
