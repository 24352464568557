import React from 'react';

import { useFormikContext } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import { combineDeceasedJointTenantsFromSelectedTitleReferences, extractSurvivingJointTenants } from '../../helpers';
import { NoticeOfDeath2_21_1Model } from '../../models';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

function SectionTitleReferences({ name, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();
  const { setValues } = formikProps;
  const handleSelectedTitleReferenceChange = () => {
    setValues(values => {
      const selectedTitles = values.titleReferences.filter(tr => tr.isSelected);
      const deceasedJointTenants = combineDeceasedJointTenantsFromSelectedTitleReferences(_cloneDeep(selectedTitles), formikProps.values.partyBook);
      const selectedDeceasedJointTenants = deceasedJointTenants
        .flatMap(deceasedJointTenant => deceasedJointTenant.deceasedProprietors)
        .find(proprietor => proprietor.isAffectedProprietor);

      return {
        ...values,
        deceasedJointTenants,
        survivingJointTenants: selectedDeceasedJointTenants ? extractSurvivingJointTenants(deceasedJointTenants, selectedDeceasedJointTenants.deceasedProprietorId!) : []
      };
    });
  };

  return (
    <TitleReferences //
      name={name}
      disabled={disabled}
      focusRef={focusRef}
      data-name={name}
      data-testid={name}
      onChange={handleSelectedTitleReferenceChange}
    />
  );
}

export default React.memo(SectionTitleReferences);
