import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      marginTop: 15,
      width: '100%',
      height: '100%',
      padding: '0px 24px'
    },
    reportContent: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10,
      height: '100%'
    },
    header: {
      marginTop: 15,
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }),
  {
    name: 'ReportDetails'
  }
);
