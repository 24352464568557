import React from 'react';

import dateFormat from 'dateformat';
import { getIn, useFormikContext } from 'formik';

import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { formatDocumentPartyName } from '../../formatters';
import { CurrentPriorityNoticeModel, WithdrawalOfPriorityNoticeModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function CurrentPriorityNotice({ name, className, ...rest }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<CurrentPriorityNoticeModel>(name);
  const classes = useStyles();
  const { values } = useFormikContext<WithdrawalOfPriorityNoticeModel>();
  const currentPriorityNotice = getIn(values, name) as CurrentPriorityNoticeModel;

  return (
    <div data-section={name} className={className}>
      <Field //
        label={currentPriorityNotice.dealingNumber}
        name={fieldName('isSelected')}
        component={CheckboxField}
        disabled={disabled}
        className={classes.checkbox}
      />
      <div className={classes.metaData}>
        <p>Expiry date: {dateFormat(currentPriorityNotice.expiry as Date, DateFormatEnum.DATEMONTHYEAR)}</p>
        <p>Applicants: {currentPriorityNotice.parties?.map(party => formatDocumentPartyName(party)).join(', ')}</p>
      </div>
    </div>
  );
}

export default React.memo(CurrentPriorityNotice);
