import format from 'date-fns/format';

import { AssignmentTypeEnum, DashboardDateRangeModel, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { getFiltersWithSelectedTab } from '../filters/helper';
import { FilterEnum } from '../filters/models';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel, InvitationsDashboardV2ApiRequest } from '../models';
import { SortingFieldEnum } from '../table/models';
import { AggregatorNameEnum, AggregatorQuery } from './models';

export const resolveActionArgs = (props: {
  //
  id: string;
  queries: AggregatorQuery[];
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  isTransactionTypeEnabled: boolean;
}): [/*filters: */ InvitationsDashboardV2ApiRequest, /*engagedTab: */ InvitationsDashboardRouteTabsEnumV2, /*currentTabFilters: */ InvitationsDashboardUiFilterModel] => {
  const { id, queries, assignmentType, localStorageKey, isTransactionTypeEnabled } = props;

  const mappedTab =
    id === AggregatorNameEnum.todayReceived || id === AggregatorNameEnum.totalReceived ? InvitationsDashboardRouteTabsEnumV2.received : InvitationsDashboardRouteTabsEnumV2.sent;

  const [tabFilters, _] = getFiltersWithSelectedTab(localStorageKey, mappedTab, assignmentType, isTransactionTypeEnabled);
  const selectedCardQuery = queries.find(d => d.name === id);

  //!important we need a clean tabFilters to store in our localStorage!
  const newTabFilters: InvitationsDashboardUiFilterModel = { assignmentType: tabFilters.assignmentType, selectedTab: tabFilters.selectedTab };

  const lastInvitationSentPeriod: DashboardDateRangeModel | undefined = selectedCardQuery?.lastInvitationSentPeriod
    ? {
        start: format(selectedCardQuery?.lastInvitationSentPeriod.start as Date, 'dd/MM/yyyy'),
        end: format(selectedCardQuery?.lastInvitationSentPeriod.end as Date, 'dd/MM/yyyy')
      }
    : undefined;

  newTabFilters.lastInvitationSentPeriod = lastInvitationSentPeriod;
  newTabFilters.selectedFilters = [];

  if (id === AggregatorNameEnum.todayReceived) {
    newTabFilters.selectedFilters.push(FilterEnum.ReceivedDate);
  } else if (id === AggregatorNameEnum.todaySent) {
    newTabFilters.selectedFilters.push(FilterEnum.SentDate);
  }

  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    newTabFilters.selectedFilters.push(FilterEnum.Groups);
  }

  return [
    {
      assignmentType,
      lastInvitationSentPeriod,
      pageNumber: 1,
      pageSize: 20,
      invitationType: selectedCardQuery?.invitationType,
      sortBy: selectedCardQuery?.lastInvitationSentPeriod ? SortingFieldEnum.LastInvitationSentDate.toString() : undefined,
      sortOrder: selectedCardQuery?.lastInvitationSentPeriod ? DashboardSortDirectionEnum.asc : undefined
    },
    mappedTab,
    newTabFilters
  ];
};

export const updateLocalStorage = (
  //
  localStorageKey: string,
  updatedValues: InvitationsDashboardUiFilterModel
) => {
  const value = localStorage.getItem(localStorageKey);

  const newItems = value ? (JSON.parse(value) as InvitationsDashboardUiFilterModel[]) : [];

  const existingItemIndex = newItems.findIndex(d => d.selectedTab === updatedValues.selectedTab && d.assignmentType === updatedValues.assignmentType);
  if (existingItemIndex !== -1) {
    newItems[existingItemIndex] = { ...updatedValues };
  } else {
    newItems.push(updatedValues);
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newItems));
};

export const removeLocalStorageSavedModel = (
  //
  localStorageKey: string,
  selectedTab: InvitationsDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
) => {
  const value = localStorage.getItem(localStorageKey);

  if (!value) {
    return;
  }
  const existingItem = JSON.parse(value) as InvitationsDashboardUiFilterModel[];
  const filteredItem = existingItem.filter(d => d.selectedTab !== selectedTab || d.assignmentType !== assignmentType);

  localStorage.setItem(localStorageKey, JSON.stringify(filteredItem));
};
