import { LookupEnumModel } from '@sympli/ui-framework/models';

/**
 path:
 - setOfOperativeWords
 schema version:
[{"id":0,"value":"FullyDischarges","name":"The mortgagee fully discharges the mortgage","_meta":null},{"id":1,"value":"DischargesAsToTheLand","name":"The mortgagee discharges from the mortgage the whole of the land described","_meta":null}]
*/
export enum SetOfOperativeWordsEnum {
  FullyDischarges = 0,
  DischargesAsToTheLand = 1
}
export const SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: SetOfOperativeWordsEnum.FullyDischarges,
    name: 'The mortgagee fully discharges the mortgage'
  },
  {
    id: SetOfOperativeWordsEnum.DischargesAsToTheLand,
    name: 'The mortgagee discharges from the mortgage the whole of the land described'
  }
];
