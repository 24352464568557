import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';

import { CaveatorPartyModel, WithdrawalOfCaveat2_20_1Model } from '../../models';
import Caveator from '../caveator';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
type FormValues = WithdrawalOfCaveat2_20_1Model;

interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class CaveatorPartyReadonlyArray extends React.PureComponent<Props> {
  render() {
    const { name, formikProps, classes } = this.props;
    const { values } = formikProps;
    const items: CaveatorPartyModel[] = _get(values, name) ?? [];
    const count = items.length;

    return (
      <div data-name={name} className={classes.root}>
        {items.map((item, itemIndex) => this.renderItem(item, itemIndex, count))}
      </div>
    );
  }

  private renderItem(item: CaveatorPartyModel, itemIndex: number, count: number) {
    const { disabled, formikProps, name } = this.props;
    const hasMultipleItems = count > 1;
    const itemBindingPath = `${name}[${itemIndex}]`;
    const title = hasMultipleItems ? `Caveator ${itemIndex + 1}` : 'Caveator';

    return (
      <FormGroup title={title} fieldAreaDirection="column" key={`item-caveators[${itemIndex}]`}>
        <Caveator //
          dialogPortalId={this.props.dialogPortalId}
          disabled={disabled}
          formikProps={formikProps}
          name={itemBindingPath}
        />
      </FormGroup>
    );
  }
}

export default withStyles(styles)(CaveatorPartyReadonlyArray);
