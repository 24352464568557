import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    bottomSpace: { marginBottom: 0 },
    iconPass: {
      fill: theme.palette.primary.main,
      paddingRight: 4,
      width: 18,
      height: 18
    },
    iconWarning: {
      fill: colors.ORANGE,
      paddingRight: 4,
      width: 20,
      height: 20
    },
    formGroupContainer: {
      padding: 20
    }
  }),
  {
    name: 'TitleActivity'
  }
);
