import { PortalProps } from '@mui/material/Portal';
import { Components, createTheme as muiCreateTheme, Theme } from '@mui/material/styles';

import { getComponentOverrides, palette, pxToRem, typography } from '@sympli/ui-framework/theme';

import { colors } from './colors';

export { colors } from './colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body2_bold: React.CSSProperties;
    body3: React.CSSProperties;
    body3_bold: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    subtitle3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body2_bold?: React.CSSProperties;
    body3?: React.CSSProperties;
    body3_bold?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body2_bold: true;
    body3: true;
    body3_bold: true;
    body4: true;
    body5: true;
    body6: true;
    subtitle3: true;
  }
}

const theme: Theme = muiCreateTheme({
  typography: {
    ...typography,
    // body2: {
    //   fontWeight: 400,
    //   fontSize: '0.875rem', // 14px
    //   lineHeight: 1.43 // '20px'
    // },
    body2_bold: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43 // 20px
    },
    body3: {
      fontWeight: 400,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.33, // 16px
      textTransform: 'uppercase'
    },
    body3_bold: {
      fontWeight: 700,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.33, // 16px
      textTransform: 'uppercase'
    },
    body4: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: 1.2, // 12px
      textTransform: 'uppercase'
    },
    body5: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: 1.4, // 14px
      textTransform: 'uppercase'
    },
    body6: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: 1.2, // 12px
      textTransform: 'uppercase'
    },
    //TODO overwrite the default one, will move it to ui-framework later
    subtitle1: {
      fontSize: pxToRem(18),
      lineHeight: pxToRem(24),
      fontWeight: 700
    },
    subtitle3: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      fontWeight: 700
    }
  },
  palette: {
    ...palette,
    ...(window.location.host.includes('feat1') && {
      primary: {
        main: '#3C1061'
      }
    }),
    action: {
      ...palette.action,
      selected: colors.TEAL
    },
    mode: 'light'
  }
});

export function createTheme(
  args: {
    //
    container?: PortalProps['container'];
    disableTransitions?: boolean;
  } = {}
): Theme {
  let components: Components = getComponentOverrides(theme, args.container);

  components = {
    ...components,
    MuiDialog: {
      styleOverrides: {
        paperFullWidth: {
          '@media (min-width:1280px) and (max-width: 1536px)': {
            maxWidth: 1184
          },
          '@media (min-width:1536px) and (max-width:1920px)': {
            maxWidth: 1376
          },
          '@media (min-width:1920px)': {
            maxWidth: 1688
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: 'auto'
          // // width: '-webkit-fill-available',
          // '@media (min-width:1280px) and (max-width: 1536px)': {
          //   maxWidth: 'var(--small-content)'
          // },
          // '@media (min-width:1536px) and (max-width:1920px)': {
          //   maxWidth: 'var(--medium-content)'
          // },
          // '@media (min-width:1920px)': {
          //   maxWidth: 'var(--large-content)'
          // }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .primary': {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit'
          },

          '& .secondary': {
            color: colors.NEUTRAL_600,
            fontSize: 12
          },

          '& .bold': {
            color: 'inherit',
            fontWeight: 700
          },

          '& .truncated': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },

          '& .clip': {
            textOverflow: 'clip',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },

          '& .flex': {
            display: 'flex'
          },

          '& .error': {
            color: `${colors.WATERMELON} !important`
          },
          '& .strikethrough': {
            textDecoration: 'line-through'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        ...components.MuiButton?.styleOverrides,
        root: {
          ...(components.MuiButton?.styleOverrides?.root as object),
          '&.MuiButton-containedPrimary:hover': {
            backgroundColor: colors.GREEK_WATERS_BRIGHT
          }
        }
      }
    }
  };

  return {
    ...theme,
    components,
    // we might need this in our tests to improve performance
    ...(args.disableTransitions && {
      transitions: {
        ...theme.transitions,
        // https://github.com/mui/material-ui/issues/10560#issuecomment-439147374
        // disable transitions
        create: () => 'none'
      }
    })
  };
}
