import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';

import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { VariantEnum } from '../../models';
import AccordionSummary from '../accordion-summary';
import ComplianceMessageLists from '../compliance-message-lists';
import { useStyles } from './styles';

interface Props {
  // document data
  errors: MessageModel[];
  warnings: MessageModel[];
  defaultExpanded?: boolean;
  // other
  children: React.ReactNode;
  onResolveIssueClick?: (event: React.SyntheticEvent) => void;
}

function DocumentVerificationResultItem({
  // document data
  errors,
  warnings,
  defaultExpanded = false,
  // other
  children,
  onResolveIssueClick
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const classes = useStyles();

  if (!errors.length && !warnings.length) {
    return null;
  }

  return (
    <Accordion //
      classes={{
        root: classes.root,
        expanded: classes.expanded
      }}
      square
      expanded={expanded}
      onChange={(_e, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionSummary expanded={expanded} variant={errors.length ? VariantEnum.Error : VariantEnum.Warning}>
        {children}
      </AccordionSummary>
      <AccordionDetails //
        classes={{
          root: classes.accordionDetailsRoot
        }}
      >
        <div className={classes.documentDetail}>
          <ComplianceMessageLists //
            errors={errors}
            warnings={warnings}
          />
          {onResolveIssueClick ? (
            <>
              <Divider className={classes.dividerError} />
              <SympliButton //
                arrowRight
                arrowPosition="left"
                color="primary"
                variant="contained"
                onClick={onResolveIssueClick}
              >
                Resolve issues
              </SympliButton>
            </>
          ) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(DocumentVerificationResultItem);
