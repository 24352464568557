import React from 'react';

import Typography from '@mui/material/Typography';

import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import { JoinWorkspaceEnum } from 'src/containers/shared/verify-property-section/components/search-join-table/models';
import { FinancialRoleMapping } from 'src/models/roles';

interface Props {
  joinWorkspaceCondition: JoinWorkspaceEnum;
  role?: WorkspaceRoleEnum;
}

function JoinWorkspaceTooltipContent({ joinWorkspaceCondition, role }: Props) {
  let title: string = 'Cannot join workspace';
  let description: string = '';
  switch (joinWorkspaceCondition) {
    case JoinWorkspaceEnum.OutstandingInvitation:
      title = 'Join workspace';
      description = 'There is an outstanding invitation for the same workspace and role.';
      break;
    case JoinWorkspaceEnum.ParticipantRoleForbidden:
      description = `You are unable to join this workspace in the current role of ${FinancialRoleMapping[role!]}`;
      break;
    case JoinWorkspaceEnum.NoWorkspaceManagePermission:
      description = 'You do not have permission to join workspace. Please contact your admin for more information on managing workspaces.';
      break;
    case JoinWorkspaceEnum.StandaloneWorkspace:
      description = 'Standalone workspaces cannot be joined.';
      break;
    case JoinWorkspaceEnum.CanJoin:
    default:
      return null;
  }

  return (
    <div style={{ width: '240px' }}>
      <Typography variant="body2_bold" className="leading-[20px] text-[var(--neutral-000)]">
        {title}
      </Typography>
      <Typography variant="body2" className="leading-[18px] text-[var(--neutral-000)]">
        {description}
      </Typography>
    </div>
  );
}

export default React.memo(JoinWorkspaceTooltipContent);
