import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import stylesFromDocumentItem from '../document-item-loader/styles';

const styles = (theme: Theme) => {
  const sharedStyles = stylesFromDocumentItem(theme);

  return {
    ...sharedStyles,
    ...createStyles({
      root: {
        ...sharedStyles.root,
        paddingBottom: 24
      },
      // const styles = theme => ({
      backgroundTitleTop: {
        ...sharedStyles.backgroundTitleTop,
        right: 0
      },
      backgroundTitleBottom: {
        ...sharedStyles.backgroundTitleBottom,
        right: 0
      },
      backgroundTitleAfter: {
        ...sharedStyles.backgroundTitleAfter,
        left: 95,
        right: 0
      },
      backgroundDescriptionAfter: {
        ...sharedStyles.backgroundDescriptionAfter,
        left: 165,
        right: 0
      },
      lineSpacingHeader: {
        marginBottom: 15
      },
      lineSpacing: {
        marginTop: 10
      },
      lineSpacingSmall: {
        marginTop: 5
      },
      headerHeight: {
        height: 18
      },
      backgroundHeaderAfter: {
        top: 0,
        bottom: 0,
        left: 200,
        right: 0
      },
      subheaderHeight: {
        height: 16
      },
      backgroundSubheaderAfter: {
        top: 0,
        bottom: 0,
        left: 100,
        right: 0
      },
      smallRowHeight: {
        height: 13
      },
      backgroundSmallRowAfter: {
        top: 0,
        bottom: 0,
        left: 60,
        right: 0
      }
    })
  };
};

export type ClassKeys = typeof styles;

export default styles;
