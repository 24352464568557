import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchDocumentDetail, actionResetDocumentData, actionUpdateDocumentData } from '../actions';
import { DocumentDetailApiRequest, DocumentFormAndDetailModel } from '../models';

export interface DocumentDetailState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  detail?: DocumentFormAndDetailModel;
  error?: Error;
  args?: DocumentDetailApiRequest;
}

const initialState: DocumentDetailState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useDocumentDetail(workspaceId: string, participantId: string, documentId: string) {
  const state = useStoreSelector(store => store.document);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId && state.args?.documentId === documentId) {
    return state;
  }
  return initialState;
}

const documentReducer = createReducer<
  //
  DocumentDetailState,
  Action
>(initialState)
  .handleAction(actionFetchDocumentDetail.request, (state, action): DocumentDetailState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchDocumentDetail.success, (state, action): DocumentDetailState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: action.payload
    };
  })
  .handleAction(actionFetchDocumentDetail.failure, (state, action): DocumentDetailState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error
    };
  })
  .handleAction(actionResetDocumentData, (state, action): DocumentDetailState => {
    if (state.args?.workspaceId === action.payload.workspaceId && state.args?.participantId === action.payload.participantId) {
      return { ...initialState };
    }
    return state;
  })
  .handleAction(actionUpdateDocumentData, (state, action): DocumentDetailState => {
    if (state.detail == null) {
      return { ...initialState };
    }

    const detail: DocumentFormAndDetailModel = { ...state.detail, ...action.payload };

    return {
      ...state,
      detail
    };
  });

export default documentReducer;
