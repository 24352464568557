import * as React from 'react';

import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: '0 auto',
      width: 40,
      borderRadius: 40,
      flexShrink: 0,
      color: colors.BLACK,
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 10,
      fontWeight: 500,
      lineHeight: '22px',
      textTransform: 'uppercase',

      '&.ok': {
        background: colors.GREEK_WATERS_LUMINOUS
      },
      '&.outstanding': {
        background: colors.SUNNY_DAY_LITE
      },
      '&.overdue': {
        background: colors.WATERMELON_LITE
      }
    }
  }),
  {
    name: 'TasksStatus'
  }
);

function TasksStatus({ taskCount, status }: { taskCount: number; status: 'ok' | 'outstanding' | 'overdue' }) {
  const classes = useStyles();

  return (
    <div
      //
      title={`Tasks status: ${status}`}
      className={classNames(classes.root, status)}
    >
      {taskCount ? taskCount : '-'}
    </div>
  );
}

export default TasksStatus;
