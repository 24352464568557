import makeStyles from '@mui/styles/makeStyles';

import { sharedStyles } from '../sharedStyles';
import { AvatarListItemProps } from './AvatarListItem';

export const useStyles = makeStyles(
  {
    ...sharedStyles,
    itemTextRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    textPrimary: {
      fontWeight: ({ avatarSize }: AvatarListItemProps) => (avatarSize === 's' ? undefined : 500)
    },
    itemDense: {
      paddingTop: ({ avatarSize }: AvatarListItemProps) => (avatarSize === 's' ? 0 : 4),
      paddingBottom: ({ avatarSize }: AvatarListItemProps) => (avatarSize === 's' ? 0 : 4)
    },
    avatarRoot: {
      minWidth: ({ avatarSize }: AvatarListItemProps) => (avatarSize === 's' ? 24 : 48)
    },
    avatarSizeS: {
      width: 20,
      height: 20
    }
  },
  { name: 'AvatarListItem', index: 1 } // * https://github.com/mui-org/material-ui/issues/18919
);
