import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',

      borderRadius: 4,
      boxSizing: 'border-box',
      height: 18,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      fontSize: 12,
      paddingLeft: 4,
      paddingRight: 4
    },
    marginLeft: {
      marginLeft: 4
    }
  }),
  { name: 'ParticipantStatusBadge' }
);
