import { createAction } from 'typesafe-actions';

export const actionEnableMfaDialog = createAction(
  //
  'ENABLE_MFA_DIALOG'
)<//
{ mfaId: string }>();

export const actionMfaCheckComplete = createAction(
  //
  'MFA_CHECK_COMPLETE'
)();
