import React from 'react';

import { FormikProps } from 'formik';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import Formik from 'src/components/formik';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import CertificationStatements from './components/certification-statements';
import CheckboxLabel from './components/checkbox-label';
import HeadingToolbar from './components/heading-toolbar';
import LandRegistryNotifications from './components/land-registry-notifications';
import LineItemsGrid from './components/line-items-grid';
import MergedDocumentsPdf from './components/merged-documents-pdf';
import SignError from './components/sign-error/SignError';
import SignWarning from './components/sign-warning';
import { getGridModel } from './helpers';
import { FinancialLineItem, SignDocument, SignDocumentFormItem, SignDocumentsFormModel, SignDocumentsViewMode, SignDocumentTypeEnum } from './models';
import { useStyles } from './styles';
import validationSchema from './validationSchema';

export interface Props {
  // route params
  workspaceId: string;
  participantId: string;
  // signing data
  signDocuments: SignDocument[];
  mergedPdfUrl?: string;
  isLoading: boolean;
  disabled?: boolean;
  lineItems: Map<SignDocumentTypeEnum, FinancialLineItem[]>;
  // wizard handlers
  onSubmit: (values: SignDocumentsFormModel) => void;
  onBack: () => void;
  // compliance issues handlers
  onShowVerificationResultsClick(): void;
  // other
  children: (formikProps: FormikProps<SignDocumentsFormModel>) => React.ReactNode;
}

const fieldName = modelKey<SignDocumentsFormModel>();

function SignDocuments({
  // route params
  workspaceId,
  participantId,
  // signing data
  signDocuments,
  mergedPdfUrl,
  isLoading,
  disabled,
  lineItems,
  // wizard handlers
  onSubmit,
  onBack,
  // compliance issues handlers
  onShowVerificationResultsClick,
  // other
  children
}: Props) {
  const classes = useStyles();
  const [viewMode, setViewMode] = React.useState<SignDocumentsViewMode>('documentList');

  const initialValues: SignDocumentsFormModel = React.useMemo<SignDocumentsFormModel>(
    () => ({
      documents: signDocuments.map(doc => ({
        data: doc,
        isSelected:
          !doc.disabled && (doc.documentStatus === DocumentStatusEnum.Approved || doc.documentStatus === DocumentStatusEnum.Signing) && lineItems.get(doc.type)?.length === 0
      }))
    }),
    [lineItems, signDocuments]
  );

  return (
    <Formik //
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps: FormikProps<SignDocumentsFormModel>) => {
        const {
          values: { documents }
        } = formikProps;

        const enabledDocs = documents.filter(d => !d.data.disabled);
        const selectAllChecked = enabledDocs.length > 0 ? enabledDocs.every(d => d.isSelected) : false;
        return viewMode === 'mergedPdf' ? (
          <MergedDocumentsPdf
            pdfUrl={mergedPdfUrl}
            onBackClick={() => {
              setViewMode('documentList');
            }}
          />
        ) : (
          <WorkspacePageContentWrapper>
            <HeadingToolbar
              disabled={isLoading || disabled}
              onClick={() => {
                setViewMode('mergedPdf');
              }}
              mode="mergedPdf"
            />

            <div className={classes.contentRoot}>
              <div>
                <Checkbox
                  className={classes.checkboxLabelTeal}
                  name={fieldName('documents')}
                  label={'Sign all available Documents and Financials'}
                  disabled={disabled || documents.every(d => d.data.disabled)}
                  onChange={e => {
                    const { checked } = e.target;
                    formikProps.setFieldValue(
                      fieldName('documents'),
                      documents.map(doc => ({
                        ...doc,
                        isSelected: doc.data.disabled ? false : checked
                      }))
                    );
                  }}
                  checked={selectAllChecked}
                />
              </div>
              <Divider className={classes.firstDivider} />
              {signDocuments.map((item: SignDocument, i: number) => {
                const checkboxName = createModelKeyAppender<SignDocumentFormItem>(`${fieldName('documents')}[${i}]`)('isSelected');
                return (
                  <div key={i} className={classes.checkboxContainer}>
                    <label className={classes.documentLabel}>{item.name}</label>
                    <Box display="flex" flexDirection="column" className={classes.documentStatus}>
                      <Typography variant="body4">{item.documentDescription ?? resolveDocumentStatus(item.type, item.documentStatus, item.isOptedOut)}</Typography>
                    </Box>
                    <SignError id={`sign-error-${i}`} errorMessages={item.verificationErrors} />
                    <SignWarning id={`sign-warning-${i}`} warningMessage={item.signingWarningMessage} />
                    <LandRegistryNotifications
                      id={`land-registry-warning-${i}`} //
                      documentLodgementCompliances={item.documentLodgementCompliances}
                      resolveIssuesLink={item.resolveIssuesLink}
                      onShowVerificationResultsClick={onShowVerificationResultsClick}
                    />
                    {!isLoading && lineItems.has(item.type) && <LineItemsGrid isOptedOut={item.isOptedOut} gridModel={getGridModel(lineItems.get(item.type)!, item.type)} />}
                    <CertificationStatements
                      id={`statement-${i}`}
                      certificationStatements={item.certificationStatements}
                      acknowledgementStatements={item.acknowledgementStatements}
                      signDocumentType={item.type}
                      totalAmount={item.totalAmount}
                      accountDescription={item.accountDescription}
                    />
                    {!item.isOptedOut && (
                      <Field //
                        vertical
                        label={
                          <CheckboxLabel isLoading={isLoading} attachments={item.attachments} workspaceId={workspaceId} participantId={participantId} documentId={item.id}>
                            Sign {item.name}
                          </CheckboxLabel>
                        }
                        aria-describedby={`statement-${i} sign-warning-${i} land-registry-warning-${i}`}
                        name={checkboxName}
                        disabled={disabled || item.disabled}
                        classes={{
                          root: classes.checkboxRoot
                        }}
                        component={CheckboxField}
                        className={disabled || item.disabled ? classes.checkbox : classes.checkboxLabelTeal}
                      />
                    )}
                    {i < signDocuments.length - 1 ? <Divider className={classes.divider} /> : null}
                  </div>
                );
              })}
            </div>

            <WizardStepper className={classes.wizardStepper} backLabel="Cancel" showNext={false} onBack={onBack}>
              {children(formikProps)}
            </WizardStepper>
          </WorkspacePageContentWrapper>
        );
      }}
    </Formik>
  );
}

function resolveDocumentStatus(documentType: SignDocumentTypeEnum, documentStatus: DocumentStatusEnum, isOptedOut?: boolean): string | undefined {
  switch (documentType) {
    case SignDocumentTypeEnum.Documents:
    case SignDocumentTypeEnum.FinancialSettlementSchedule:
    case SignDocumentTypeEnum.TrustAccountAuthorisationRecord:
    case SignDocumentTypeEnum.Payments:
    case SignDocumentTypeEnum.SourceFunds:
      if (isOptedOut) {
        return 'N/A';
      }

      switch (documentStatus) {
        case DocumentStatusEnum.Awaiting:
        case DocumentStatusEnum.InPreparation:
        case DocumentStatusEnum.Reviewing:
          return 'IN PREPARATION';

        case DocumentStatusEnum.Approved:
        case DocumentStatusEnum.Signing:
          return 'APPROVED';
        case DocumentStatusEnum.Signed:
          return 'SIGNED';
        default:
          return 'IN PREPARATION';
      }
    default:
      return 'IN PREPARATION';
  }
}

export default SignDocuments;
