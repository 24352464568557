import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignSelf: 'flex-start',
      marginTop: 8,

      '&.simple': {
        marginTop: -8
      },
      '& > span > span:last-of-type': {
        fontWeight: 500,
        fontSize: 14
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
