import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      paddingBottom: 4
    },
    note: {
      marginBottom: 10,
      fontSize: 13
    },
    certInfoContainer: {
      fontSize: 13
    },
    certProvider: {
      marginBottom: 3
    },
    radioFormLabel: {
      display: 'flex',
      alignItems: 'start',
      paddingBottom: 5,
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginRight: 0,
      marginBottom: 5
    },
    formControlLabelRoot: {
      '& .MuiFormControlLabel-label': {
        lineHeight: 'inherit'
      }
    },
    textBold: {
      fontWeight: 'bold'
    },
    textLight: {
      color: theme.palette.text.secondary
    },
    titleIcon: {
      height: 32,
      width: 32,
      marginRight: 8
    }
  });

export type ClassKeys = typeof styles;

export default styles;
