import * as React from 'react';

import Typography from '@mui/material/Typography';

import { LodgementDetail } from '@sympli/api-gateway/shared';

import { colors } from 'src/theme';
import LodgementCaseStatusTimestamp from '../components/lodgement-case-status-timestamp';

interface Props {
  lodgementDetail: LodgementDetail;
}

function ReadyRegistrationOnly({ lodgementDetail }: Props) {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Ready
      </Typography>
      <LodgementCaseStatusTimestamp lodgementDetail={lodgementDetail} />
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The document(s) have <b>been verified and signed</b> and are now <b>ready for lodgement</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        To initiate the lodgement simply click 'Lodge'.
      </Typography>
    </>
  );
}

export default React.memo(ReadyRegistrationOnly);
