import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { CurrentLandUseDataModel, InclusionDataModel, PropertyDataModel, SafetySwitchDataModel, SmokeAlarmDataModel } from '../../models';

const yupInclusion = yup
  .object<InclusionDataModel>({
    plant: yup.boolean(),
    livestock: yup.boolean(),
    crops: yup.boolean(),
    existingRight: yup.boolean(),
    chattels: yup.boolean(),
    waterLicence: yup.boolean(),
    waterAllocation: yup.boolean(),
    other: yup.string().nullable().max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
  })
  .defined();

const yupCurrentLandUse = yup
  .object<CurrentLandUseDataModel>({
    vacantLand: yup.boolean(),
    dwelling: yup.boolean(),
    flats: yup.boolean(),
    multiUnit: yup.boolean(),
    guestHousePrivateHotel: yup.boolean(),
    farming: yup.boolean(),
    industrial: yup.boolean(),
    commercial: yup.boolean(),
    other: yup.string().nullable().trim().max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
  })
  .test(
    'Current land use selection',
    'At least one land use must be selected or other must be supplied.',
    function test(this: yup.TestContext<CurrentLandUseDataModel>, value: CurrentLandUseDataModel) {
      return (
        value.vacantLand || //
        value.dwelling ||
        value.flats ||
        value.multiUnit ||
        value.guestHousePrivateHotel ||
        value.farming ||
        value.industrial ||
        value.commercial ||
        !!value.other
      );
    }
  )
  .defined();

const yupSafetySwitch = yup
  .object<SafetySwitchDataModel>({
    installed: yup.string().nullable().required(msg.REQUIRED),
    informed: yup.string().nullable().required(msg.REQUIRED)
  })
  .defined();

const yupSmokeAlarm = yup
  .object<SmokeAlarmDataModel>({
    installed: yup.string().nullable().required(msg.REQUIRED),
    informed: yup.string().nullable().required(msg.REQUIRED)
  })
  .defined();

export const yupProperty = yup.object<PropertyDataModel>({
  inclusion: yupInclusion,
  currentLandUse: yupCurrentLandUse,
  safetySwitch: yupSafetySwitch,
  smokeAlarm: yupSmokeAlarm
});
