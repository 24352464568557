import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    defaultText: {
      color: theme.palette.text.primary
    },
    root: {
      marginLeft: -16,
      marginRight: -16,
      '& .user-localtime': {
        color: theme.palette.text.secondary
      }
    },
    selected: {
      background: theme.palette.common.white
    },
    listItem: {
      paddingLeft: 16,
      paddingRight: 16
    },
    // body2_bold
    title: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43 // 20px
    },
    icon: {
      paddingRight: 4
    }
  }),
  { name: 'SettlementDateBox' }
);
