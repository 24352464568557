import React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DocumentPreview } from 'src/components/document-preview';
import HeadingToolbar from '../heading-toolbar';
import { useStyles } from './styles';

interface Props {
  pdfUrl?: string;
  onBackClick: () => void;
}

function MergedDocumentsPdf({ pdfUrl, onBackClick }: Props) {
  const classes = useStyles();
  if (!pdfUrl) {
    return null;
  }
  return (
    <FlexLayout flexDirection="column" fullWidth fullHeight className={classes.root}>
      <HeadingToolbar mode="documentList" onClick={onBackClick} className={classes.heading} />
      <DocumentPreview src={pdfUrl} />
    </FlexLayout>
  );
}

export default MergedDocumentsPdf;
