import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DischargeOfMortgage2_17_1Model } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - mortgages[*].mortgagees    
 - mortgages[*].titleReferences    

 schema version:
[
  {
    "isSelected": true
  }
]
*/
export const VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES = createContextCheck((context: DischargeOfMortgage2_17_1Model): boolean => {
  return context.mortgages.some(({ isSelected }) => isSelected);
});
