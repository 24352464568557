import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '../../../../components/party-capacity/enums';
import { WA_MORTGAGE_PARTY_FORM_FOR_MORTGAGEE_CONFIG } from '../../config';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupMortgageeDetails = resolveMemoizedTenancyValidation({
  //
  partyCapacityLookup: PARTY_CAPACITY_LOOKUP_OPTIONS,
  isAddressRequired: true,
  partyFormConfig: WA_MORTGAGE_PARTY_FORM_FOR_MORTGAGEE_CONFIG
});

export default yupMortgageeDetails;
