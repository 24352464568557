import React, { memo, useState } from 'react';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { NewIconView } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

export interface Props {
  dismissMessageHandler(): void;
}

const MultipleLodgementCaseMessage = ({ dismissMessageHandler }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box className={classes.root}>
      <ButtonBase className={classes.header} disabled={open} disableRipple={open} onClick={() => setOpen(true)}>
        <NewIconView />
        <Typography className={classes.headerText}>Read me</Typography>
      </ButtonBase>
      {open && (
        <>
          <Box className={classes.content}>
            <Typography className={classes.contentText}>
              <b>Additional Lodgement Cases, will not affect</b> the automated settlement attempt of the <b>Primary Lodgement Case.</b>
            </Typography>
          </Box>
          <ButtonBase className={classes.dismissButton} onClick={dismissMessageHandler}>
            Don't show me again
          </ButtonBase>
        </>
      )}
    </Box>
  );
};

export default memo(MultipleLodgementCaseMessage);
