import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DIRECTION_PREFERENCE$CT_DIRECTION_LOOKUP_OPTIONS } from '../../enums';
import { DirectionPreferenceTypeModel, LodgementInstructionsDocumentModel } from '../../models';
import { VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
export function yupDirectionPreferenceValidationSchema<CRoot extends object = {}>() {
  const yupDirectionPreference = yup.object<DirectionPreferenceTypeModel, CRoot>({
    ctDirection: validateWhenVisible2<number | null>({
      visibilityCheck: (parent: LodgementInstructionsDocumentModel, context: LodgementInstructionsDocumentModel) =>
        VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY(context),
      validationWhenVisible: yup //
        .mixed<number | null>()
        .required(msg.REQUIRED)
        .oneOf(getLookupValuesAllowingEmpty(DIRECTION_PREFERENCE$CT_DIRECTION_LOOKUP_OPTIONS), msg.INVALID_SELECTION)
    })
  });

  return memoizeSchemaWithContext(yupDirectionPreference, (parentContext: CRoot): CRoot => parentContext);
}

export default yupDirectionPreferenceValidationSchema;
