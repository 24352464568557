import { JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { ExpectedSettlementDate, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';

export interface ActiveWorkspaceItem {
  proprietors: string[];
  sympliId: string;
  workspaceType: WorkspaceTypeEnum;
  jurisdiction: JurisdictionsEnum;

  titles: Title[];
  participants: Array<ParticipantInfo>;

  workspaceStatusId: WorkspaceStatusEnum;
  pendingProposedSettlementDate?: WorkspaceDateTimeModel;
  expectedSettlementDate?: ExpectedSettlementDate;
  workspaceId: string;

  // props not from API
  disabled: boolean;
  showIndicator: boolean;
  joinWorkspaceCondition: JoinWorkspaceEnum;
  clickHandler?: () => void;
  addresses: string[];
}

export interface Title {
  reference: string;
  landDescriptions?: string[];
  address: {
    addressLine1: string;
    addressLine2?: string;
  };
  proprietors: string[];
}

export interface SearchJoinTableContext {
  workspaceType: WorkspaceTypeEnum;
  role?: WorkspaceRoleEnum;
}

export interface ParticipantInfo {
  role: string;
  subscriberName: string;
  hasAccepted: boolean;
  assignedGroupName?: string;
  subscriberId: string;
  roleId: WorkspaceRoleEnum;
  matter?: string;
}

export enum JoinWorkspaceEnum {
  CanJoin = 1,
  /**
   * Preview: ![JoinWorkspaceEnum.OutstandingInvitation](__preview/outstanding-invitation.png)
   */
  OutstandingInvitation = 2,
  /**
   * Preview: ![JoinWorkspaceEnum.OutstandingInvitation](__preview/no-workspace-manage-permission.png)
   */
  NoWorkspaceManagePermission = 3,
  /**
   * Preview: ![JoinWorkspaceEnum.OutstandingInvitation](__preview/role-forbidden.png)
   */
  ParticipantRoleForbidden = 4,
  /**
   * Preview: ![JoinWorkspaceEnum.OutstandingInvitation](__preview/standalone-workspace.png)
   */
  StandaloneWorkspace = 5
}
