import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import sharedStyles from 'src/containers/workspace/financial/directions/views/edit-directions/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...sharedStyles(theme),
    saveButton: {
      display: 'flex',
      // * ideally same size as small field
      width: 120,
      // to solve the issue that loader expands width
      whiteSpace: 'nowrap',
      marginLeft: 'auto'
    },
    fullWidth: {
      width: '100%'
    },
    labelCenter: {
      '& label': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },
    formGroupFooter: {
      backgroundColor: 'none',
      border: 'none',
      paddingLeft: 0,
      fontSize: 14
    },
    bold: {
      fontWeight: 'bold'
    },
    italic: {
      fontStyle: 'italic'
    },
    editButton: {
      padding: '0px 0px 0px 10px'
    },
    flexGrow: {
      flexGrow: 1
    }
  }),
  { name: 'EditBankDetails' }
);
