import * as yup from 'yup';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import msg from '@sympli/ui-framework/utils/messages';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { FavouriteFormikModel } from 'src/containers/dashboard/reports/models';
import http from 'src/utils/http';

const yupName = yup.string().default('').trim().max(50, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(50)).required(msg.REQUIRED);

const NAME_CACHE: { [key: string]: boolean | Promise<boolean> } = {};

async function reportNameAsyncValidation(value: string, id?: number) {
  try {
    const isValid = await http.post<boolean>('/reports/favouriteReportParameters/validate-report-name', { id, name: value });

    NAME_CACHE[value] = isValid;
    return isValid;
  } catch (e) {
    /*
      in case endpoint is down or returning something unexpected, we log the error, and let user to pass validation,
      when they hit save, backend still validates the name
      so we both provide a better user experience, and keep the duplicated name out of our db
    */
    Logger.console(SeverityEnum.Warning, 'Invalid BI reporting name');
    Logger.captureException(e);
    NAME_CACHE[value] = true;
    return true;
  }
}

export default yup.object<FavouriteFormikModel>({
  name: yupName.test('unique report name', 'Report name already exists', function test(this: yup.TestContext<FavouriteFormikModel>, value: string) {
    if (NAME_CACHE.hasOwnProperty(value)) {
      return NAME_CACHE[value];
    }
    yupName.validateSync(value);
    const { id } = this.parent as FavouriteFormikModel;

    return (NAME_CACHE[value] = reportNameAsyncValidation(value, id));
  }),
  // add these as mixed because we don't need to validate them, but they are needed for TS check
  id: yup.mixed<number>(),
  filterData: yup.mixed<string>(),
  reportType: yup.mixed<ReportTypeEnum>()
});
