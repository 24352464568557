import * as yup from 'yup';

import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';

import { TitleReferenceModel } from './models';

export const yupTitleReferenceItemSchema = <TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification>() =>
  yup
    .object<TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>>({
      isSelected: yup.boolean().defined(),
      reference: yup.string(),
      proprietor: yup.mixed()
    })
    .defined();

export default <TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification>() =>
  resolveTitleReferencesValidationSchema(yupTitleReferenceItemSchema<TPartLandAffectedModel, TDocumentPartyJustification>());
