import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export interface StyleProps {
  arrowPosition?: 'top' | 'bottom';
  offset?: number;
}

const ARROW_SIZE = 20; // 20px

type StyleClassKeys = 'paper' | 'closeButton' | 'closeIcon';

export const useStyles = makeStyles<Theme, StyleProps, StyleClassKeys>(
  (theme: Theme) => {
    return createStyles<StyleClassKeys, StyleProps>({
      paper: ({ arrowPosition, offset }) => {
        if (arrowPosition === 'top') {
          return {
            overflowX: 'unset',
            overflowY: 'unset',
            marginTop: ARROW_SIZE,
            '&::before': {
              content: '""',
              position: 'absolute',
              marginTop: '-0.6em',
              top: 0,
              right: 0,
              left: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: ARROW_SIZE,
              height: ARROW_SIZE,
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[1],
              transform: 'rotate(315deg)',
              clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))'
            }
          };
        }

        return {
          overflowX: 'unset',
          overflowY: 'unset',
          marginTop: -ARROW_SIZE,
          marginLeft: offset,
          '&::after': {
            content: '""',
            position: 'absolute',
            marginTop: '-0.6em',
            right: offset ? undefined : 0,
            left: offset ? `calc(50% - ${offset}px - ${ARROW_SIZE / 2}px)` : 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: ARROW_SIZE,
            height: ARROW_SIZE,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            transform: 'rotate(315deg)',
            clipPath: 'polygon(0 100%, 0 0, 100% 100%)'
          }
        };
      },
      closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 40,
        height: 40,
        zIndex: 1
      },
      closeIcon: {
        fontSize: 20
      }
    });
  },
  {
    name: 'Popover'
  }
);
