import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DateOfDeathTypeEnum, EvidenceTypeValueEnum } from './enums';
import { DateOfDeathModel, DeceasedProprietorModel, EvidenceModel, NoticeOfDeath2_21_1Model } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].dateOfDeath.deathDate

 schema version:
[
  {
    "dateOfDeathType": {
      "$noneOf": [
        2,
        1
      ]
    }
  }
]
*/
export const VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE = createContextCheck(
  (context: DateOfDeathModel): boolean => ![DateOfDeathTypeEnum.DateRange, DateOfDeathTypeEnum.DateDescription].includes(context.dateOfDeathType!)
);

/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].dateOfDeath.fromDate
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].dateOfDeath.toDate

 schema version:
[
  {
    "dateOfDeathType": 2
  }
]
*/
export const VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE = createContextCheck((context: DateOfDeathModel): boolean => context.dateOfDeathType === DateOfDeathTypeEnum.DateRange);

/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].dateOfDeath.dateDescription

 schema version:
[
  {
    "dateOfDeathType": 1
  }
]
*/
export const VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION = createContextCheck(
  (context: DateOfDeathModel): boolean => context.dateOfDeathType === DateOfDeathTypeEnum.DateDescription
);

/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].dateOfDeath
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].evidence

 schema version:
[
  {
    "isAffectedProprietor": true
  }
]
*/
export const VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE = createContextCheck((context: DeceasedProprietorModel): boolean => context.isAffectedProprietor === true);

/**
 path:
 - survivingJointTenants[*]
*/
export const VISIBILITY_CHECK_SURVIVING_JOINT_TENANTS = createContextCheck((context: NoticeOfDeath2_21_1Model): boolean => context.survivingJointTenants?.length !== 0);

/**
 path:
 - deceasedJointTenants[*]
*/
export const VISIBILITY_CHECK_DECEASED_JOINT_TENANTS = createContextCheck((context: NoticeOfDeath2_21_1Model): boolean => context.deceasedJointTenants.length !== 0);

export const VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE = createContextCheck(
  (context: EvidenceModel): boolean => context.evidenceTypeValue !== EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct
);
