import { LookupItemModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache

export enum DeceasedTenancyPartyCapacityEnum {
  Trustee = 'Trustee',
  Administrator = 'Administrator',
  Executor = 'Executor',
  ExecutorByRepresentation = 'Executor by representation',
  Beneficiary = 'Beneficiary',
  Devisee = 'Devisee',
  NextOfKin = 'Next of Kin',
  PersonalRepresentative = 'Personal Representative'
}

export const DECEASED_TENANCY_PARTY_CAPACITY_LOOKUP_OPTIONS: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[] = Object.values(DeceasedTenancyPartyCapacityEnum).map(name => ({
  id: name,
  name
}));
