import * as yup from 'yup';

import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-25/components/party-justification';
import { RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { $eq, $get, $items, $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { getSelectedCaveats } from '../../helper';
import { CaveatModel, WithdrawalOfCaveat2_25_0Model } from '../../models';
import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_WITHDRAW_CAVEAT } from '../../visibilityChecks';

// path: withdrawCaveat
const yupWithdrawCaveat = yup
  .array<CaveatModel, WithdrawalOfCaveat2_25_0Model>()
  .required(msg.REQUIRED)
  .testContextualRule({
    /**
         * what we found in schema:
         [{"required":{"withdrawCaveats":{"$items":{"$oneOf":{"isSelected":true}}}},"message":null}]
         *
         */
    uniqueTestName: '"withdrawCaveats" contextual validation rule #1',
    onlyIf: (context: WithdrawalOfCaveat2_25_0Model): boolean => VISIBILITY_CHECK_WITHDRAW_CAVEAT(context),
    requirement: (context: WithdrawalOfCaveat2_25_0Model): boolean =>
      $oneOfItems($items(context, 'withdrawCaveats'), item => context.withdrawCaveats.length === 1 || $eq($get(item, 'isSelected'), true)),
    message: msg.REQUIRED
  })
  .testContextualRule({
    uniqueTestName: '"withdrawCaveats" contextual validation rule #2',
    onlyIf: (context: WithdrawalOfCaveat2_25_0Model): boolean => VISIBILITY_CHECK_CAVEATORS(context),
    requirement: (context: WithdrawalOfCaveat2_25_0Model): boolean => {
      const selectedCaveats = getSelectedCaveats(context.withdrawCaveats);
      if (selectedCaveats.length === 1) return true;

      const justificationApplied = selectedCaveats.some(e =>
        e.caveators.some(c => (context.partyBook.find(pb => pb.id === c.partyBookId)?.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>)?.isChangingName)
      );

      if (justificationApplied) return true;

      const parties = selectedCaveats.map(sc =>
        sc.caveators
          .map(c => c.partyBookId)
          .sort()
          .join()
      );

      return new Set(parties).size === 1;
    },
    message: 'The parties on the selected caveats do not match, you will need withdraw them separately or change the name of one of the caveators below'
  });

export default yupWithdrawCaveat;
