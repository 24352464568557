import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DocumentNotificationModel } from '../../models';
import styles, { ClassKeys } from './styles';

// TODO combine with './components/wizard-error-message'
interface OwnProps {
  notification: DocumentNotificationModel;
}
type Props = OwnProps & WithStyles<ClassKeys>;

function DocumentDataNotification(props: Props) {
  const {
    classes,
    notification: { heading, message }
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.bold}>{heading}</div>
      <div className={classes.message}>{message}</div>
    </div>
  );
}

export default withStyles(styles)(DocumentDataNotification);
