import { Dispatch } from 'react';

import { useLocalStorageState } from '@sympli/ui-framework/hooks';

import { SimpleSnackbarNotificationProps } from 'src/components/global-simple-notification/simple-snackbar-notification';

export type PersistedMessageNotificationWithLocalStorageProps = Omit<SimpleSnackbarNotificationProps, 'autoHideDuration' | 'open'> & {
  messageId: string;
};

const DEFAULT_VALUE: PersistedMessageNotificationWithLocalStorageProps[] = [];

export default function useWorkspacePersistedNotifications(
  workspaceId: string,
  participantId: string
): [PersistedMessageNotificationWithLocalStorageProps[], Dispatch<PersistedMessageNotificationWithLocalStorageProps[]>] {
  const key = getPersistedNotificationKey(workspaceId, participantId);
  const [notifications, setNotifications] = useLocalStorageState<PersistedMessageNotificationWithLocalStorageProps[]>(key, DEFAULT_VALUE);
  return [notifications, setNotifications];
}

export const getPersistedNotificationKey = (workspaceId: string, participantId: string) => `persistedNotification/${workspaceId}/${participantId}`;
