import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    lineItem: {
      paddingTop: 4,
      paddingBottom: 4,
      borderBottom: `1px solid ${theme.palette.grey[100]}`
    },
    timeLabel: {
      fontSize: 13,
      color: theme.palette.text.secondary,
      width: 65,
      minWidth: 65,
      paddingRight: 12,
      fontWeight: 500
    },
    message: {
      fontSize: 13
    },
    messageContainer: {
      marginLeft: 3,
      marginRight: 16,
      flexGrow: 1
    },
    linkLabel: {
      fontWeight: 'bold'
    }
  }),
  {
    name: 'ActivityLogItem'
  }
);
