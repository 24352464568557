import React, { useRef } from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getPartyOptionsExcludingSiblings } from '@sympli-mfe/document-forms-framework/utils';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { NSW_TAE_PARTY_FORM_CONFIG } from '../../config';
import { ApplicantModel } from '../../models';
import { useStyles } from './styles';

// // this file was automatically generated from components/ObjectComponent.tsx.mustache
// import React from 'react';
// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  disabled: boolean;
  focusRef?: React.RefObject<ButtonBaseActions>;
  partyOptions: LookupItemModel[];
  items: ApplicantModel[];
  itemIndex: number;
}

function Applicant({ name, disabled, focusRef, partyOptions, items, itemIndex }: Props): JSX.Element {
  const { dialogPortalId } = useDocumentContext();
  const filteredPartyOptions = getPartyOptionsExcludingSiblings(partyOptions, items, itemIndex);
  const postPartyDialogFocusRef = useRef<ButtonBaseActions>(null);
  const fieldName = createModelKeyAppender<ApplicantModel>(name);
  const classes = useStyles();

  return (
    <PartySelectField
      name={fieldName('partyBookId')}
      partyFormConfig={NSW_TAE_PARTY_FORM_CONFIG}
      disabled={disabled}
      bookRef="partyBook"
      dialogPortalId={dialogPortalId}
      focusRef={focusRef}
      nextFocusRef={postPartyDialogFocusRef}
      optionsOverride={filteredPartyOptions}
      className={classes.fieldWidth}
    />
  );
}

export default React.memo(Applicant);
