import React, { memo, useCallback } from 'react';

import TableRowLikeAccordion from '../table-row-like-accordion';
import Detail from './components/detail';
import LinkAndReorderPanel from './components/link-and-reorder-panel';
import Overview from './components/overview';
import { useSharedStyles } from './components/styles';
import TableLikeHeader from './components/table-like-header';
import { LinkedWorkspaceRowItem } from './models';

interface Props {
  // data
  currentWorkspaceIndex: number;
  currentWorkspaceId: string;
  currentParticipantId: string;
  currentSubscriberId: string;
  items: LinkedWorkspaceRowItem[];
  // handlers
  onUnlinkWorkspaceClick(workspaceId: string): void;
}

function TabLinkedWorkspaces({
  // data
  currentWorkspaceIndex,
  currentWorkspaceId,
  currentParticipantId,
  currentSubscriberId,
  items,
  // handlers
  onUnlinkWorkspaceClick
}: Props) {
  const sharedClasses = useSharedStyles();
  const renderExpandedContent = useCallback(
    (itemIndex: number) => {
      const { detail } = items[itemIndex];

      return (
        <div role="row" className={sharedClasses.row}>
          <Detail //
            currentWorkspaceId={currentWorkspaceId}
            currentParticipantId={currentParticipantId}
            currentSubscriberId={currentSubscriberId}
            onUnlinkWorkspaceClick={onUnlinkWorkspaceClick}
            {...detail}
          />
        </div>
      );
    },
    [items, sharedClasses, currentWorkspaceId, currentSubscriberId, currentParticipantId, onUnlinkWorkspaceClick]
  );

  return (
    <div>
      <LinkAndReorderPanel //
        workspaceCount={items.length}
        workspaceOrder={currentWorkspaceIndex + 1}
        currentWorkspaceId={currentWorkspaceId}
        currentParticipantId={currentParticipantId}
      />

      <div role="table" className={sharedClasses.table}>
        <TableLikeHeader //
          role="row"
        />
        {items.map((item, itemIndex) => {
          return (
            <TableRowLikeAccordion //
              renderExpandedContent={renderExpandedContent}
              itemIndex={itemIndex}
              role="rowgroup"
              key={item.detail.workspaceId}
            >
              <Overview //
                role="row"
                currentParticipantId={currentParticipantId}
                {...item.overview}
              />
            </TableRowLikeAccordion>
          );
        })}
      </div>
    </div>
  );
}

export default memo(TabLinkedWorkspaces);
