import * as yup from 'yup';

import { $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DischargeOfMortgage_2_26_0_Model, MortgageModel } from '../../models';

// path: mortgages
const yupMortgages = yup
  .array<MortgageModel>()
  .required(msg.REQUIRED)
  .test(
    testContextualRule({
      /**
           * what we found in schema:
           [{"required":{"mortgages":{"$items":{"$oneOf":{"isSelected":true}}}},"message":"At least one mortgage must be selected"}]
           *
           */
      uniqueTestName: 'at least 1 mortgage must be selected',
      message: 'At least 1 mortgage must be selected',
      requirement: (
        _model: MortgageModel[], //
        { mortgages }: DischargeOfMortgage_2_26_0_Model
      ): boolean => $oneOfItems(mortgages, item => item.isSelected === true)
    })
  )
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .test(
    testContextualRule({
      uniqueTestName: 'mortgages must contain shared title references',
      message: 'The mortgages selected do not share a common title. Please review the selected mortgages.',
      requirement: (
        _model: MortgageModel[], //
        { mortgages, titleReferences }: DischargeOfMortgage_2_26_0_Model
      ): boolean => {
        // proceed with validation only when atleast one mortage is selected
        if (!$oneOfItems(mortgages, item => item.isSelected === true)) return true;

        // one or more mortgages select, so now check  whether the selected mortgages share common title references
        // no common titles available for selection incase title references is empty
        return titleReferences.length !== 0;
      }
    })
  );

export default yupMortgages;
