// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'All done', ...rest } = props;
  return (
    <svg width="130" height="130" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g fill="none" fillRule="evenodd">
        <circle fill="#F2F2F2" cx="65" cy="65" r="65" />
        <g fill="#1DDDBD">
          <path d="M28.837 99.907L40 96.015l-8.465-3.986zM42.79 59.405l-2.697 8.341L75.442 83.41l1.581-.556-15.628-15.108zM34.419 83.873l17.302 8.064 11.814-4.078-26.233-12.235z" />
        </g>
        <g fill="#2A917F">
          <path d="M61.488 67.746L45.581 51.805l-2.604 7.415zM37.302 75.624L63.535 87.86l11.72-4.171-35.162-16.312zM34.419 83.873l-2.884 8.156L40 96.015l11.72-4.078z" />
        </g>
        <path
          d="M68 60.98c8.837 8.62 13.395 18.259 10.14 21.41-3.163 3.151-12.93-1.297-21.768-10.01-8.837-8.619-13.395-18.258-10.14-21.41 3.163-3.15 12.93 1.391 21.768 10.01z"
          fill="#247767"
        />
        <path
          d="M56.93 39.2c-2.14-2.966.186-4.263 3.07-3.707-2.698-3.337-1.023-5.654 3.256-4.727 1.302.278-.558 2.502-1.675 2.41 3.628 2.687 1.582 5.468-2.14 4.82 3.35 4.633-2.418 3.428-4.93 3.614-.65 3.429 3.256 7.322 1.954 7.322-3.07.092-7.72-10.752.465-9.732z"
          fill="#2A917F"
        />
        <path
          d="M84.558 45.502c-1.953.835-7.442-7.785-.651-7.878-3.907-3.522-3.442-5.19 1.86-5.375-5.953-6.025 3.442-8.156 4.466-4.912.279.926-2.884-.835-3.907.834-1.21 1.946 7.348 7.044-1.489 6.673 3.256 3.336 3.442 4.82-1.767 5.376.65 1.112 2.697 4.726 1.488 5.282z"
          fill="#1DDDBD"
        />
        <path
          d="M86.79 65.893l1.954-1.669s1.86 2.688 3.163 3.708c1.023-4.634.744-7.508 6.14-4.264-3.07-8.063 1.953-5.005 6.79-2.873-.279-2.039 0-1.854 2.14-2.41 1.86 6.859-3.07 4.913-7.163 2.595 2.326 6.21-.093 5.932-5.116 3.8-.093 2.596-.93 5.654-2.512 5.84-1.767.37-5.395-4.727-5.395-4.727zM72.093 46.43c-2.419 3.15-6.14 4.911-8.93 7.6-2.884 2.872-4.651 10.75-4.651 10.75s1.023-8.156 3.814-11.307c2.511-2.873 6.14-4.912 8.093-8.249 3.441-5.931.372-13.902-4.093-18.444.93-.834 2.14-1.853 2.883-2.595 4.186 5.376 7.907 15.664 2.884 22.244z"
          fill="#FF811A"
        />
        <path
          d="M76.093 53.102c-3.442 2.503-5.86 6.118-8.279 9.547-2.14 3.058-8.744 6.766-8.744 6.766S65.395 65.15 67.256 62c2.325-3.893 4.651-7.97 8.372-10.844 7.349-5.654 17.953-5.005 26.232-2.039a290.015 290.015 0 0 1-1.488 3.707s-17.302-4.726-24.279.278z"
          fill="#2A917F"
        />
        <path
          d="M90.698 52.546c-2.233 2.874-3.35 6.395-5.024 9.64-1.581 2.965-3.627 5.838-6.697 7.414-3.35 1.761-10.884 1.112-10.884 1.112s7.535-.092 10.605-2.132c3.162-2.131 4.837-5.746 6.046-9.175 2.326-6.488 5.21-13.532 12-16.405a451.884 451.884 0 0 1 1.396 3.707s-3.721 1.02-7.442 5.84z"
          fill="#1DDDBD"
        />
        <path fill="#18CDB6" d="M30.404 38.83l3.683-3.671 3.684 3.67-3.683 3.67z" />
        <path fill="#EFCA9B" d="M35.55 50.54l3.683-3.67 3.684 3.67-3.684 3.67z" />
        <path fill="#FF811A" d="M45.051 33.09l3.683-3.67 3.684 3.67-3.683 3.67z" />
        <path fill="#1DDDBD" d="M85.382 77.202l3.683-3.67 3.684 3.67-3.684 3.67zM77.132 89.9l3.684-3.67 3.683 3.67-3.683 3.67z" />
        <path fill="#FF811A" d="M93.437 92.838l3.683-3.67 3.684 3.67-3.684 3.67z" />
        <path fill="#EFCA9B" d="M97.056 76.038l3.683-3.67 3.684 3.67-3.684 3.67z" />
        <path fill="#FF811A" d="M90.892 36.99l3.684-3.67 3.683 3.67-3.683 3.67z" />
        <path fill="#1DDDBD" d="M51.133 59.259l3.683-3.67 3.684 3.67-3.684 3.67z" />
      </g>
    </svg>
  );
};
