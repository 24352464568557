import http from 'src/utils/http';
import { VerifyStampDutyApiResponse } from '../../models';

export const cancelTransaction = (workspaceId: string, participantId: string, transactionId: string) => {
  const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/stampduties`;
  const payload = {
    transactionId,
    CancelTransaction: true
  };
  return http.post<VerifyStampDutyApiResponse>(action, payload);
};
