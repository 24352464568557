import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import { colors } from 'src/theme';
import { IN_PREPARATION_DISPLAY_NAME, StampDutySteps, UNAVAILABLE_DISPLAY_NAME, VERIFIED_DISPLAY_NAME } from './models';

export const StampDutyStatusTooltipContent = (step: string) => {
  switch (step) {
    case UNAVAILABLE_DISPLAY_NAME:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            UNAVAILABLE
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE }}>
            The duty assessment form is <b>not available</b> at the moment.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            For stamp duty to be available, we need the <b>Transfer & other supporting documents</b> to be approved.
          </Typography>
          <Breadcrumbs //
            currentStep={1}
            steps={StampDutySteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    case IN_PREPARATION_DISPLAY_NAME:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            IN PREPARATION
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE }}>
            The duty assessment form is <b>now available</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            When everything is ready <b>click on 'Verify Stamp Duty' </b>to activate the verification process with the State Revenue Office.
          </Typography>
          <Breadcrumbs //
            currentStep={2}
            steps={StampDutySteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    case VERIFIED_DISPLAY_NAME:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            VERIFIED
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE }}>
            The duty assessment has <b>been verified.</b>
          </Typography>
          <Breadcrumbs //
            currentStep={3}
            steps={StampDutySteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    default:
      return <span>not supported status {step}</span>;
  }
};
