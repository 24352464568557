import * as React from 'react';

import { FieldProps } from 'formik';
import _debounce from 'lodash-es/debounce';

import { SourceDataReconciler } from '../../modules/SourceDataReconsiler';

interface OwnProps {
  // represents a source from which we are getting data
  // if the source has changed we need to reconcile our data as well (e.g. remove or add)
  sourceName: string;
  // unique identifier in the source
  // we want to deprecate this and use sourceIdentifierName in the future
  itemIdentifierName?: string;
  sourceIdentifierName?: string;
  // name of property in the destination object under which we store the identifier from the source
  // defaults to sourceIdentifierName (itemIdentifierName)
  destinationIdentifierName?: string;
}

type Props = OwnProps & FieldProps & { [x: string]: any };

export default function withSourceBinding(WrappedComponent: React.ComponentType) {
  return class extends React.PureComponent<Props> {
    private sourceDataReconciler: SourceDataReconciler;

    constructor(props: Props) {
      super(props);
      this.sourceDataReconciler = new SourceDataReconciler();
    }

    private get sourceIdentifierName() {
      const { itemIdentifierName, sourceIdentifierName } = this.props;
      return sourceIdentifierName || itemIdentifierName;
    }

    private reconcileData = _debounce(() => {
      const {
        sourceName,

        form: { values, setFieldValue },
        field: { name, value }
      } = this.props;

      if (typeof sourceName === 'string') {
        const sourceIdentifierName = this.sourceIdentifierName;
        const destinationIdentifierName = this.props.destinationIdentifierName || sourceIdentifierName;

        const props = {
          sourceName,
          sourceIdentifierName,
          destinationIdentifierName,
          field: {
            name,
            value
          },
          form: {
            values,
            setFieldValue
          }
        };
        this.sourceDataReconciler.reconcileFieldValues.call(props);
      }
    }, 150);

    componentDidUpdate() {
      this.reconcileData();
    }

    render() {
      this.reconcileData();
      const { sourceName, itemIdentifierName, destinationIdentifierName, ...rest } = this.props;
      const wrappedProps = { ...rest, itemIdentifierName: this.sourceIdentifierName };
      return <WrappedComponent {...wrappedProps} />;
    }
  };
}
