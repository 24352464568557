import * as React from 'react';

import { usePreviousValue, useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import WithdrawDialog from './WithdrawDialog';

interface Props {
  onClose: (data?: boolean) => void;
  sentToName?: string;
}
function WithdrawDialogContainer({ onClose, sentToName }: Props) {
  const status: ApiStatus = useStoreSelector(store => store.withdrawInvitation.status);
  const prevLoadingState = usePreviousValue(status);
  React.useEffect(() => {
    if ((status === 'rejected' || status === 'resolved') && prevLoadingState === 'pending') {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <WithdrawDialog //
      onClose={onClose}
      sentToName={sentToName}
      isLoading={status === 'pending'}
    />
  );
}
export default React.memo(WithdrawDialogContainer);
