import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import _uniqueId from 'lodash-es/uniqueId';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import CaveatorPartyReadonlyArray from './components/caveator-party-readonly-array';
import { getCaveatorsForSelectedCaveat, getCaveatsBeingWithdrawn } from './helper';
import { WithdrawalOfCaveat2_19_1Model } from './models';
import SetOfOperativeWords from './sections/set-of-operative-words';
import SectionWithdrawCaveat from './sections/withdraw-caveat';
import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_WITHDRAW_CAVEAT } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<WithdrawalOfCaveat2_19_1Model>();
const setOfOperativeWordsTitleId: string = _uniqueId();
const dialogPortalId: string = _uniqueId();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<WithdrawalOfCaveat2_19_1Model>();
  const { setValues, values } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnWithdrawCaveatRef = React.useRef<ButtonBaseActions>(null);
  const caveators = _get(values, fieldName('caveators')) ?? [];

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnWithdrawCaveatRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const onTitleReferenceChange = React.useCallback(() => {
    setValues(updatedValues => {
      // get list of withdrawable caveats sharing the selected titles
      const withdrawCaveatsList = getCaveatsBeingWithdrawn(updatedValues.caveats, updatedValues.titleReferences);
      const caveators = getCaveatorsForSelectedCaveat(withdrawCaveatsList);

      return {
        ...updatedValues,
        withdrawCaveats: withdrawCaveatsList,
        caveators: caveators
      };
    });
  }, [setValues]);

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          disabled={disabled}
          focusRef={focusOnTitleReferencesRef}
          onChange={onTitleReferenceChange}
        />

        {VISIBILITY_CHECK_WITHDRAW_CAVEAT(values) && (
          <>
            <SectionWithdrawCaveat //
              name={fieldName('withdrawCaveats')}
              formikProps={formikProps}
              disabled={disabled}
              focusRef={focusOnWithdrawCaveatRef}
            />

            {VISIBILITY_CHECK_CAVEATORS(values) && (
              <>
                <Section //
                  title={pluralize('Caveator', caveators.length)}
                  data-name={fieldName('caveators')}
                >
                  <CaveatorPartyReadonlyArray //
                    dialogPortalId={dialogPortalId}
                    disabled={disabled}
                    formikProps={formikProps}
                    name={fieldName('caveators')}
                  />
                </Section>

                <Section //
                  title="Set of operative words"
                  titleId={setOfOperativeWordsTitleId}
                  data-name={fieldName('setOfOperativeWords')}
                >
                  <SetOfOperativeWords //
                    name={fieldName('setOfOperativeWords')}
                    formikProps={formikProps}
                    disabled={disabled}
                    ariaLabelledbyId={setOfOperativeWordsTitleId}
                  />
                </Section>
              </>
            )}
          </>
        )}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
