import _cloneDeep from 'lodash-es/cloneDeep';

import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { CtDirectionEnum, IssuingInstructionTypeEnum, PartyIssuedWithCoRDEnum } from './enums';
import { fallbackMap } from './fallback';
import { ApiLodgementInstructionsDocumentModel, LodgementInstructions_2_25_2 } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions_2_25_2, ApiLodgementInstructionsDocumentModel> {
  public originalApiModel: ApiLodgementInstructionsDocumentModel;
  constructor(initialValues: ApiLodgementInstructionsDocumentModel) {
    this.originalApiModel = _cloneDeep(initialValues);
  }

  fromApiToFormModel(apiValues: ApiLodgementInstructionsDocumentModel, context: IConverterContext): LodgementInstructions_2_25_2 {
    let formValues = applyDefaultMap(apiValues, fallbackMap);

    return {
      ...formValues,
      isResponsibleParticipant: formValues.responsibleParticipantId === context.currentParticipant?.id || false,
      currentParticipantId: context.currentParticipant!.id,
      isSameRoleAsResponsible: context.participants?.find(p => p.id === formValues.responsibleParticipantId)?.workspaceRole.id === context.currentParticipant?.workspaceRole.id
    };
  }

  fromFormToApiModel(formModel: LodgementInstructions_2_25_2, _: ApiLodgementInstructionsDocumentModel): ApiLodgementInstructionsDocumentModel {
    formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
    const { issuingInstructions: issuingInstructionsFormValue, isSameRoleAsResponsible, currentParticipantId, ...rest } = formModel;

    // if you are not the current responsible participant, you can only update the dealing requirements
    // We do this so that if any changes were done while the responsibleParticipantId was updated, it will not be persisted
    if (currentParticipantId !== formModel.responsibleParticipantId) {
      return {
        ...this.originalApiModel,
        dealingRequirementsSummary: formModel.dealingRequirementsSummary,
        // if you were the responsible participant and then changed responsibleParticipantId, we should persist that change
        responsibleParticipantId: formModel.responsibleParticipantId
      };
    }

    if (issuingInstructionsFormValue?.editable) {
      switch (issuingInstructionsFormValue.issuingInstructionType) {
        case IssuingInstructionTypeEnum.RetainOrAcquireEctControl:
          issuingInstructionsFormValue.ctDirection = CtDirectionEnum.Electronic;
          issuingInstructionsFormValue.partyIssuedWithCoRD = PartyIssuedWithCoRDEnum.IssuingParty;
          break;
        case IssuingInstructionTypeEnum.TransferEctControlToRegistrarOfTitles:
          issuingInstructionsFormValue.ctDirection = undefined;
          issuingInstructionsFormValue.partyIssuedWithCoRD = PartyIssuedWithCoRDEnum.RegistrarOfTitles;
          issuingInstructionsFormValue.ctDirection = CtDirectionEnum.Electronic;
          break;
      }
    }

    return {
      ...rest,
      issuingInstructions: issuingInstructionsFormValue
    };
  }
}

export default Converter;
