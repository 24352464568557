import { isWeekend } from 'date-fns';
import dateFormat from 'dateformat';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';
import publicHoliday from './public-holiday.json';

/**
 * * public-holiday.json data structure
 * {
 *    jurisdiction (Upper case): {
 *      date (yyyy-mm-dd): {
 *        date: string;
 *        rawDate: string;
 *        holidayName: string;
 *        information: string;
 *        moreInformation: string;
 *      }
 *    }
 * }
 */
// export interface PublicHolidayDataModel {
//   date: string;
//   holidayName: string;
//   information: string;
//   moreInformation: string;
// }

export function isBusinessDay(date: Date, jurisdictionId: JurisdictionsEnum) {
  return !isWeekend(date) && !isHoliday(date, jurisdictionId);
}

export function isHoliday(date: Date, jurisdictionId: JurisdictionsEnum) {
  return Boolean(getHoliday(date, jurisdictionId));
}

function getPublicHolidaysByJurisdiction(jurisdictionId: JurisdictionsEnum): Record<string, string> {
  if (jurisdictionId in jurisdictionNameMapping) {
    const jurisdictionName: string = jurisdictionNameMapping[jurisdictionId];
    return publicHoliday[jurisdictionName];
  }

  return {};
}

export function getHoliday(date: Date | string, jurisdictionId: JurisdictionsEnum): string | undefined {
  const isoDate = dateFormat(date, DateFormatEnum.DATE);
  const jurisdictionPublicHoliday = getPublicHolidaysByJurisdiction(jurisdictionId);
  return jurisdictionPublicHoliday[isoDate];
}
