import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: 13,
      color: theme.palette.text.secondary,
      textAlign: 'center'
    },
    contactSupport: {
      marginBottom: 10
    }
  }),
  {
    name: 'Caption'
  }
);
