import React from 'react';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' && typeof window.document !== 'undefined' && typeof window.document.createElement !== 'undefined' ? React.useLayoutEffect : React.useEffect;

/**
 * Copied from https://github.com/jaredpalmer/formik/blob/849ed497e24afbc1c9d41dbd0ad84db8231a48c2/packages/formik/src/Formik.tsx#L1200
 */
export default function useEventCallback<T extends (...args: any[]) => any>(fn: T): T {
  const ref: any = React.useRef(fn);

  // we copy a ref to the callback scoped to the current state/props on each render
  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });

  return React.useCallback((...args: any[]) => ref.current.apply(void 0, args), []) as T;
}
