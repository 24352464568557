import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchNotificationsSettings } from '../actions/notificationsSettings';
import { NotificationSettingsApiResponse } from '../preferences/detail/notifications/models';

const fetchNotificationsSettings = () => http.get<NotificationSettingsApiResponse>('/settings/subscriber/preferences/notification-settings');

function* sagaFetchNotificationsSettings() {
  try {
    const data = yield* call(fetchNotificationsSettings);
    yield put(actionFetchNotificationsSettings.success({ data }));
  } catch (error) {
    yield put(actionFetchNotificationsSettings.failure({ error }));
  }
}

export default [takeLatest(actionFetchNotificationsSettings.request, sagaFetchNotificationsSettings)];
