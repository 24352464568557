import React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-20/qld';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DocumentPartyCapacityModel, WithdrawalOfCaveat2_20_1Model } from '../../models';
import styles, { ClassKeys } from './styles';
import { VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL } from './visibilityChecks';

type FormValues = WithdrawalOfCaveat2_20_1Model;

interface OwnProps {
  name: string;
  isReadonly: boolean;
  formikProps: FormikProps<FormValues>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class PartyCapacity extends React.PureComponent<Props> {
  render() {
    const { isReadonly, name, formikProps } = this.props;
    const { values } = formikProps;
    const documentPartyCapacity: DocumentPartyCapacityModel = _get(values, name);
    const capacity = PARTY_CAPACITY_LOOKUP_OPTIONS.find(x => x.id === documentPartyCapacity.partyCapacity)?.name;
    return isReadonly && documentPartyCapacity.partyCapacity ? (
      <FlexLayout flexDirection="column" fullWidth>
        {capacity && <ReadOnlyField label="Party capacity" value={capacity} />}
        {VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL(documentPartyCapacity) && documentPartyCapacity.partyCapacityDetail && (
          <ReadOnlyField label="Party capacity detail" value={documentPartyCapacity.partyCapacityDetail} />
        )}
      </FlexLayout>
    ) : null;
  }
}

export default withStyles(styles)(PartyCapacity);
