import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { colors } from '@sympli/ui-framework/theme';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import { DocumentListItemParticipantMode, DocumentListItemStatusEnum, DocumentStatusDisplayEnum, DocumentStatusSteps } from '../../models';

export function resolveTooltipContentForDocStatus(
  mode: DocumentListItemParticipantMode,
  displayStatus: string,
  uiStatus: DocumentListItemStatusEnum,
  isSharedDoc: boolean,
  badgeOnly: boolean
) {
  // need to override the status as multiple signers have diff description
  // Partially signed (1/2) or signed (2/2)
  if (displayStatus.startsWith(DocumentStatusDisplayEnum.PartiallySigned)) {
    displayStatus = DocumentStatusDisplayEnum.PartiallySigned;
  }
  if (displayStatus.startsWith(`${DocumentStatusDisplayEnum.Signed} (`)) {
    displayStatus = DocumentStatusDisplayEnum.Signed;
  }
  // Lodged with a dealing number will arrive as: Lodge, DEALING: 950010662
  if (displayStatus.toUpperCase().startsWith(`${DocumentStatusDisplayEnum.Lodged}`)) {
    displayStatus = DocumentStatusDisplayEnum.Lodged;
  }

  switch (displayStatus) {
    case DocumentStatusDisplayEnum.ToBeInvited:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            TO BE INVITED
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            A participant has <b>not yet been invited</b> to this role.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            When an invitation is send the document will change to invited.
          </Typography>
        </Box>
      );
    case DocumentStatusDisplayEnum.Invited:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            INVITED
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The participant has now <b>been invited to the workspace</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            When they accept the invitation the document will progress to 'In Preparation' status.
          </Typography>
        </Box>
      );
    case DocumentStatusDisplayEnum.InPreparation:
      if (isSharedDoc && !badgeOnly) {
        return resolveInPreparationSharedDocument();
      }

      if (mode === 'primary') {
        if (uiStatus === DocumentListItemStatusEnum.AwaitingApproveFromOthers) {
          return resolveApprovalStatus();
        }

        if (uiStatus === DocumentListItemStatusEnum.ReadyToApprove && isSharedDoc) {
          return resolveInPreparationSharedDocument();
        }

        return resolveInPreparationEditing();
      }

      if (mode === 'non-primary') {
        if (uiStatus === DocumentListItemStatusEnum.AwaitingEditFromOthers) {
          return resolveInPreparationEditAwaiting();
        }
        if (uiStatus === DocumentListItemStatusEnum.Edit) {
          return resolveInPreparationEditing();
        }

        if (uiStatus === DocumentListItemStatusEnum.ReadyToApprove) {
          return isSharedDoc ? resolveInPreparationSharedDocument() : resolveInPreparationEditing();
        }
      }
      return resolveInPreparationInDraft();
    case DocumentStatusDisplayEnum.Approved:
      return resolveApprovalStatus();
    case DocumentStatusDisplayEnum.PartiallySigned:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            PARTIALLY SIGNED
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            This document has now <b>been partially signed</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            Once the second user has signed the document, it will be <b>ready for lodgement</b>.
          </Typography>
          <Breadcrumbs //
            currentStep={2}
            steps={DocumentStatusSteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    case DocumentStatusDisplayEnum.Signed:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            SIGNED
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The participant <b>has signed their documents(s)</b>.
          </Typography>
          <Breadcrumbs //
            currentStep={3}
            steps={DocumentStatusSteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    case DocumentStatusDisplayEnum.Lodging:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            LODGING
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The lodgement process has <b>now started</b>. This might take a few minutes.
          </Typography>
          <Breadcrumbs //
            currentStep={3}
            steps={DocumentStatusSteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    case DocumentStatusDisplayEnum.Lodged:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            LODGED
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The lodgement has <b>been accepted by the Land Registry</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            They are presently <b>processing your documents</b>.
          </Typography>
          <Breadcrumbs //
            currentStep={3}
            steps={DocumentStatusSteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      );
    case DocumentStatusDisplayEnum.NA:
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
            N/A
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            A participant has removed the participation from the document.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            When a participant joins the document, the status will change to 'In preparation'.
          </Typography>
        </Box>
      );
    default:
      return <span>not supported status {displayStatus}</span>;
  }
}

function resolveInPreparationInDraft() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
        IN PREPARATION
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The participant is <b>actively working</b> on their document(s).
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        When everything is ready the status will progress to 'Approved'.
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={DocumentStatusSteps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

function resolveInPreparationEditAwaiting() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
        IN PREPARATION
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The document is <b>now active</b>.
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
          The Purchaser will need to <b>edit and approve</b> the document before the Vendor can approve it.
        </Typography>
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
          When everything is ready by both participants the status will change to 'Approved'.
        </Typography>
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={DocumentStatusSteps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

function resolveInPreparationSharedDocument() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
        IN PREPARATION
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The document is <b>now active</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        When everything is ready by both participants the status will change to 'Approved'.
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={DocumentStatusSteps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

function resolveInPreparationEditing() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
        IN PREPARATION
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The document(s) is <b>now editable</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        When everything is ready simply save and approve the document to continue.
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={DocumentStatusSteps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

function resolveApprovalStatus() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE }}>
        APPROVED
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The participant has now <b>approved their documents(s)</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        Once <b>all documents have been approved,</b> the <b>Lodgement Verification</b> will <b>automatically commence</b> and lodgement fees will be returned to the workspace.
      </Typography>
      <Breadcrumbs //
        currentStep={2}
        steps={DocumentStatusSteps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}
