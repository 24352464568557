import { colors as tickleColors } from '@sympli/ui-framework/theme';

interface NewColors {
  // primary
  GREEK_WATERS_TRANSLUCENT: string;
  GREEK_WATERS_LITE: string;
  GREEK_WATERS_LUMINOUS: string;
  GREEK_WATERS: string;
  GREEK_WATERS_DARK: string;
  GREEK_WATERS_BRIGHT: string;
  SYMPLI_GREEN: string;

  // secondary
  SUNNY_DAY_TRANSLUCENT: string;
  SUNNY_DAY_LITE: string;
  SUNNY_DAY_DARK: string;
  SUNNY_DAY: string;
  SUNNY_DAY_BRIGHT: string;
  SUNNY_DAY_LUMINOUS: string;
  WATERMELON_TRANSLUCENT: string;
  WATERMELON_LITE: string;
  WATERMELON_LUMINOUS: string;
  WATERMELON_DARK: string;
  WATERMELON: string;
  WATERMELON_BRIGHT: string;

  // others
  NEUTRAL_000: string;
  NEUTRAL_025: string;
  NEUTRAL_050: string;
  NEUTRAL_100: string;
  NEUTRAL_200: string;
  NEUTRAL_300: string;
  NEUTRAL_400: string;
  NEUTRAL_500: string;
  NEUTRAL_600: string;
  NEUTRAL_700: string;
  NEUTRAL_800: string;
  NEUTRAL_900: string;
  NEUTRAL_1000: string;

  RED_NEW: string;
  GREY_NEW: string;
  GREEN_NEW: string;
  GREY_400: string;
  RED_LIGHT_NEW: string;

  PROFILE_GREEN_HOVER: string;
}

export const colors: Record<keyof typeof tickleColors | keyof NewColors, string> = {
  ...tickleColors,
  // primary
  GREEK_WATERS_TRANSLUCENT: 'var(--greek-waters-translucent)',
  GREEK_WATERS_LITE: 'var(--greek-waters-lite)',
  GREEK_WATERS_LUMINOUS: 'var(--greek-waters-luminous)',
  GREEK_WATERS: 'var(--greek-waters)',
  GREEK_WATERS_DARK: 'var(--greek-waters-dark)',
  GREEK_WATERS_BRIGHT: 'var(--greek-waters-bright)',

  // secondary
  SYMPLI_GREEN: 'var(--sympli-green)',
  SUNNY_DAY_TRANSLUCENT: 'var(--sunny-day-translucent)',
  SUNNY_DAY_LITE: 'var(--sunny-day-lite)',
  SUNNY_DAY_DARK: 'var(--sunny-day-dark)',
  SUNNY_DAY: 'var(--sunny-day)',
  SUNNY_DAY_BRIGHT: 'var(--sunny-day-bright)',
  SUNNY_DAY_LUMINOUS: 'var(--sunny-day-luminous)',
  WATERMELON_TRANSLUCENT: 'var(--watermelon-translucent)',
  WATERMELON_LITE: 'var(--watermelon-lite)',
  WATERMELON_LUMINOUS: 'var(--watermelon-luminous)',
  WATERMELON_DARK: 'var(--watermelon-dark)',
  WATERMELON: 'var(--watermelon)',
  WATERMELON_BRIGHT: 'var(--watermelon-bright)',
  // others
  NEUTRAL_000: 'var(--neutral-000)',
  NEUTRAL_025: 'var(--neutral-025)',
  NEUTRAL_050: 'var(--neutral-050)',
  NEUTRAL_100: 'var(--neutral-100)',
  NEUTRAL_200: 'var(--neutral-200)',
  NEUTRAL_300: 'var(--neutral-300)',
  NEUTRAL_400: 'var(--neutral-400)',
  NEUTRAL_500: 'var(--neutral-500)',
  NEUTRAL_600: 'var(--neutral-600)',
  NEUTRAL_700: 'var(--neutral-700)',
  NEUTRAL_800: 'var(--neutral-800)',
  NEUTRAL_900: 'var(--neutral-900)',
  NEUTRAL_1000: 'var(--neutral-1000)',
  // TODO designer review whether we still need them or not
  RED_NEW: 'var(--red-new)',
  GREY_NEW: 'var(--grey-new)',
  GREEN_NEW: 'var(--green-new)',
  GREY_400: 'var(--grey-400)',
  RED_LIGHT_NEW: 'var(--red-light-new)',
  PROFILE_GREEN_HOVER: 'var(--profile-green-hover)'
};
