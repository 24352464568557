import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    fullWidth: {
      width: '100%'
    },
    SearchBar: {
      marginBottom: 20
      // position: 'relative',
      // justifyContent: 'center'
    },
    existSearchStyle: {
      textAlign: 'center',
      display: 'inline-flex',
      alignItems: 'center',
      position: 'absolute',
      fontSize: 14,
      lineHeight: '88px', // same height of Search Input
      color: colors.SUNNY_DAY_DARK,
      fontStyle: 'normal',
      fontWeight: 700,
      textDecorationLine: 'underline',
      height: 'min-content',
      margin: 'auto 0',
      right: 0
    }
  }),
  {
    name: 'LinkedWorkspaceSearchContainer'
  }
);
