import { WaAddressChange } from '@sympli-mfe/document-components/party-form/wa/2-25/components/address-justification';
import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-25/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { WA_MORTGAGE_PARTY_FORM_CONFIG } from '../../config';
import { getMortgagorPartyBookIds, getSelectedTitleReferences } from '../../helper';
import { Mortgage2_25_0Model, TitleReferenceModel } from '../../models';

interface PartyBookValidationContext {
  titleReferences: TitleReferenceModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: Mortgage2_25_0Model): PartyBookValidationContext => {
  return {
    titleReferences: parentContext.titleReferences
  };
};

const checkShouldValidateItem = ({ titleReferences }: PartyBookValidationContext, partyIdForCheck: PartyModel<WaNameChange, WaAddressChange>['id']) => {
  const selectedTitleReferences = getSelectedTitleReferences(titleReferences);
  const selectedMortgagorsIds = getMortgagorPartyBookIds(selectedTitleReferences);

  const isSelectedMortgagor = !!~selectedMortgagorsIds.indexOf(partyIdForCheck);
  return isSelectedMortgagor;
};

const yupPartyItem = resolvePartyModelValidationSchema<WaNameChange, PartyBookValidationContext, WaAddressChange>(WA_MORTGAGE_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<WaNameChange, WaAddressChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
