// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '15px 20px 5px 20px'
    },
    dealingNumber: {
      display: 'inline-block',
      position: 'absolute'
    },
    singleDealingNumber: {
      paddingLeft: 270
    },
    multipleDealingNumber: {
      paddingLeft: 246
    },
    warning: {
      color: theme.palette.error.main,
      fontSize: 12,
      marginBottom: -10
    }
  }),
  {
    name: 'WithdrawCaveat'
  }
);
