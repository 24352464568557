import * as React from 'react';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { useStyles } from './styles';

export interface Props {
  isOpen?: boolean;
  canManageGroupPermission: boolean;
  onClose(path?: string): void;
}

function AssignableGroupsIssueDialog({
  //
  canManageGroupPermission,
  isOpen,
  onClose
}: Props) {
  const classes = useStyles();

  const message = canManageGroupPermission
    ? 'To create a new workspace, you must set up an assignable group.'
    : 'To create a new workspace, contact your admin or super admin to set up an assignable group.';

  return (
    <ConfirmationDialog
      okButtonText={canManageGroupPermission ? 'Update group settings' : 'Continue'}
      title="Create new workspace"
      classes={{
        dialogPaper: classes.dialogPaper,
        dialogContent: classes.createWorkspaceDialogContent,
        root: classes.root
      }}
      open={isOpen}
      onClose={(value?: boolean) => {
        canManageGroupPermission && value ? onClose('/settings/groups') : onClose();
      }}
      showActionButtons={true}
      scroll="body"
      buttonArrow={true}
    >
      <div className={classes.borderBottom}>
        <p>{message}</p>
      </div>
    </ConfirmationDialog>
  );
}

export default AssignableGroupsIssueDialog;
