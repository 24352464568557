import * as React from 'react';

import classNames from 'classnames';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useSharedStyles } from '../styles';
import { useStyles } from './styles';

type Props = Pick<React.HTMLAttributes<HTMLDivElement>, 'role'>;

function TableLikeHeader({ role }: Props) {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  return (
    <FlexLayout //
      flexDirection="row"
      role={role}
      className={classNames(sharedClasses.row, classes.header)}
    >
      <div role="columnheader" className={sharedClasses.columnOrder}>
        Order
      </div>
      <div role="columnheader" className={sharedClasses.columnJurisdiction}>
        Jurisdiction
      </div>
      <div role="columnheader" className={sharedClasses.columnWorkspaceType}>
        Workspace type
      </div>
      <div role="columnheader" className={sharedClasses.columnParticipants}>
        Participants
      </div>
      <div role="columnheader" className={sharedClasses.columnStatus}>
        Status
      </div>
    </FlexLayout>
  );
}

export default React.memo(TableLikeHeader);
