import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_DISCHARGE_AMOUNT } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: dischargeAmount
const yupDischargeAmount = yup
  .mixed<number | null>()
  .defined()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_DISCHARGE_AMOUNT,
      validationWhenVisible: v.number().required(msg.REQUIRED)
    })
  );

export default yupDischargeAmount;
