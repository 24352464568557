import React from 'react';

import { NavLink } from 'react-router-dom';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import { FormatterInputModel } from '@sympli/ui-framework/components/table';

import { resolveDailyReportGeneratedText, resolveMonthlyReportGeneratedText, resolveWeeklyReportGeneratedText } from 'src/containers/dashboard/reports/helper';
import { ScheduledFrequencyEnum } from 'src/containers/dashboard/reports/models';
import { stopPropagation } from 'src/utils/dom';
import { resolveReportLink } from '../../../../helper';
import { EditDeleteButton, FavouriteFormikModel, ReportTypeEnumDisplayMap, SavedReportModel, SchedulerFormikModel } from '../../../../models';
import ReportUpdateButtons from './components/report-update-buttons';

const formatReportTypeColumn = (config: FormatterInputModel<SavedReportModel, string>) => {
  const { row } = config;

  return ReportTypeEnumDisplayMap[row.reportType];
};

const formatReportNameColumn = (config: FormatterInputModel<SavedReportModel, string>) => {
  const { value, row } = config;
  const link = resolveReportLink(row.feature, row.reportType, row.id);

  return (
    <NavLink to={link} title={`Show ${value} report detail`} onClick={stopPropagation}>
      {value}
    </NavLink>
  );
};

const formatScheduledReportEditDeleteColumn = (config: FormatterInputModel<SavedReportModel, string, EditDeleteButton>) => {
  const { row, context } = config;
  const isDeleteDisabled = row.reportType === ReportTypeEnum.UserPermissionReport;
  const titleForDelete = isDeleteDisabled ? 'Not allowed' : 'Delete report';

  const schedulerInitialValues: SchedulerFormikModel = {
    id: row.id,
    name: row.name,
    scheduledFrequency: row.scheduledFrequency,
    filterData: row.filterData,
    reportSendTime: row.reportSendTime,
    interval: row.interval,
    daysOfWeek: row.daysOfWeek,
    dayOfMonth: row.dayOfMonth,
    reportType: row.reportType,
    subscriberId: row.subscriberId
  };

  if (context) {
    return (
      <ReportUpdateButtons
        reportId={row.id}
        onDeleteClick={context.onDeleteClick}
        reportName={row.name}
        onEditClick={context.onEditClick}
        initialValues={schedulerInitialValues}
        reportFeatureType={row.feature}
        titleForDelete={titleForDelete}
        titleForEdit="Edit"
        isDeleteDisabled={isDeleteDisabled}
      />
    );
  }

  return null;
};

const formatFavouriteReportEditDeleteColumn = (config: FormatterInputModel<SavedReportModel, string, EditDeleteButton>) => {
  const { row, context } = config;

  const favouriteInitialVaues: FavouriteFormikModel = {
    id: row.id,
    name: row.name,
    filterData: row.filterData,
    reportType: row.reportType
  };

  if (context) {
    return (
      <ReportUpdateButtons //
        reportId={row.id}
        onDeleteClick={context.onDeleteClick}
        reportName={row.name}
        onEditClick={context.onEditClick}
        initialValues={favouriteInitialVaues}
        reportFeatureType={row.feature}
        titleForDelete="Remove favourite"
        titleForEdit="Edit"
      />
    );
  }

  return null;
};

const formatReportGeneratedTime = (config: FormatterInputModel<SavedReportModel, string>) => {
  const { row } = config;
  const { scheduledFrequency, interval, reportSendTime, dayOfMonth, daysOfWeek } = row;

  switch (scheduledFrequency) {
    case ScheduledFrequencyEnum.Daily:
      return resolveDailyReportGeneratedText(reportSendTime, interval);
    case ScheduledFrequencyEnum.Monthly:
      return resolveMonthlyReportGeneratedText(reportSendTime, interval, dayOfMonth);
    case ScheduledFrequencyEnum.Weekly:
      return resolveWeeklyReportGeneratedText(reportSendTime, daysOfWeek);
    default:
      return '';
  }
};

export {
  //
  formatReportNameColumn,
  formatReportTypeColumn,
  formatScheduledReportEditDeleteColumn,
  formatFavouriteReportEditDeleteColumn,
  formatReportGeneratedTime
};
