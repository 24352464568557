import * as React from 'react';

import { Location, useLocation } from 'react-router-dom';

import { Scope } from '@sympli/ui-logger';

import ErrorBoundary from './ErrorBoundary';

export type ErrorBoundaryInjectedProps = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  reset: () => void;
};

type ComponentWithProps<TComponentProps> = {
  /**
   * A fallback component that gets rendered when the error boundary encounters an error.
   */
  component: React.ComponentType<TComponentProps>;
} & Omit<TComponentProps, keyof ErrorBoundaryInjectedProps>;

export type Props<TComponentProps> = {
  /** Called before the error is captured by logger, allows for you to add tags or context by mutating scope */
  beforeCapture?(scope: Scope, error: Error | null, componentStack: string | null): void;
  /** Called when the error boundary encounters an error */
  onError?(error: Error, componentStack: string, eventId: string | null): void;
} & ComponentWithProps<TComponentProps>;

function ErrorBoundaryContainer<TComponentProps>(props: React.PropsWithChildren<Props<TComponentProps>>) {
  const location: Location = useLocation();

  return (
    <ErrorBoundary //
      // route params
      {...props}
      location={location}
    />
  );
}

export default React.memo(ErrorBoundaryContainer);
