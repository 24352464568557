import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    sectionHeader: {
      display: 'flex',
      alignItems: 'center'
    },
    reportName: {
      width: '23%',
      '& a': {
        fontWeight: 'bold',
        color: colors.BLACK
      },
      '& a:hover': {
        textDecoration: 'none'
      }
    },
    reportTypes: {
      width: '33%'
    },
    buttons: {
      width: '6%'
    },
    sectionIcon: {
      marginRight: 10
    },
    titleWrapper: {
      borderBottom: `1px solid ${colors.MEDIUM_GREY}`
    },
    reportsSectionRoot: {
      marginBottom: 20
    },
    emptyReportList: {
      marginTop: 15,
      marginBottom: 40,
      fontStyle: 'italic',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontColor: theme.palette.common.black,
      lineHight: 16
    },
    reportGeneratedTime: {
      width: '38%'
    }
  }),
  {
    name: 'ReportScheduleTable'
  }
);
