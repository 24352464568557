import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { ActionProhibitedEnum, ClaimCategoryEnum, ClaimPartyCapacityEnum, EstateOfInterestClaimedEnum } from './enums';

export interface BaseTitleReference {
  isPartLandAffected?: boolean; // we made this optional
  isSelected: boolean;
  landDescriptions?: LandDescription[];
  reference?: string;
}

interface LandDescription {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponent[];
  legalDescription?: string;
}

interface LandDescriptionComponent {
  name?: string;
  value?: string;
}

export interface ApiCaveatModel {
  addressBook: BaseAddress[];
  partyBook: ApiDocumentPartyModel[];

  titleReferences?: BaseTitleReference[];
  extentOfProhibitions?: ExtentOfProhibition[];
  claimDetails: Claim;
  dutyAssessmentNumber?: string;
  caveators?: CaveatParty[] | null;

  detailsForServiceOfNotice?: ServiceOfNoticeDetails;
  proprietors?: Proprietor[];
  workspaceTitleReferences?: string[];

  caveatStatementsAgreed: boolean;
}

export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

export type CaveatModel = Omit<ApiCaveatModel, 'addressBook' | 'partyBook'> & {
  [ADDRESS_BOOK_KEY]: AddressBookEntityModel[];
  [PARTY_BOOK_KEY]: PartyModel[];
};

export interface CaveatParty {
  party?: ApiDocumentPartyModel;
  partyBookId: string | null; // reference to party store

  address?: BaseAddress;
  addressBookId: string | null; // reference to address store
}

export interface Claim {
  estateOrInterestClaimed: EstateOfInterestClaimedEnum | null; // number
  claimCategory?: ClaimCategoryEnum | null; // number
  detailsSupportingTheClaim?: string;
  claimParties?: ClaimParty[];
  claimDate?: Date | string | null;
  lrDocumentID?: string | null;
  supportingDocuments?: DocumentAttachment[];
}

export interface ClaimParty {
  claimPartyCapacity: ClaimPartyCapacityEnum | null; // number

  party?: ApiDocumentPartyModel;
  partyBookId: string | null; // reference to party store

  // address?: NSWDeliveryAddress;
  // addressBookId: string | null; // reference to address store
}

export interface DocumentAttachment {
  id: string;
  fileName: string;
  fileType: string;
}

export interface ServiceOfNoticeDetails {
  contacts?: CaveatParty[];
}

export interface ExtentOfProhibition {
  actionProhibitedOption?: ActionProhibitedEnum | null; // we made this optional
  lrDocumentId?: string;
  qualification?: string;
}

export interface Proprietor {
  isAffected: boolean;

  party?: ApiDocumentPartyModel;
  partyBookId: string | null; // reference to party store

  address?: BaseAddress;
  addressBookId: string | null; // reference to address store
}

// export interface PartyJustification {
//   actionRequiredValue: ActionRequiredEnumModel | null; // number
//   firstName?: string;
//   justificationReason: JustificationReasonEnumModel | null; // number
//   lastName?: string;
//   legalEntityName?: string;
//   organisationName?: string;

//   partyType: NswPartyTypeEnum | null; // number

//   supportingEvidences?: SupportingEvidence[];
// }

// export interface SupportingEvidence {
//   evidenceDate?: string;
//   evidenceDocumentReference?: string;
//   evidenceTypeValue: EvidenceTypeEnumModel | null; // number
// }
