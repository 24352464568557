import React from 'react';

import isAfter from 'date-fns/isAfter';
import Typography from '@mui/material/Typography';

import {
  DocumentActionTypeEnum,
  DocumentLodgementStatusEnum,
  DocumentPermissionEnum,
  DocumentStatusEnum,
  DocumentTypeIdentifierEnum,
  JurisdictionsEnum,
  ParticipantStatusEnum,
  WorkspaceStatusEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { NewDeleteIcon, NewReorderIcon } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import CopyIcon from 'src/components/copy-icon';
import IconTypography from 'src/components/workspace-status-rebuild/icon-typography';
import {
  ConsolidationModel,
  DocumentItemVisualMode,
  DocumentListAction,
  DocumentListItemParticipantMode,
  DocumentListItemStatusEnum,
  DocumentListModel,
  DocumentListParticipant,
  DocumentParticipant,
  documentParticipantOrderedList,
  DocumentStatusDisplayEnum
} from 'src/containers/shared/document-list/models';
import { DocumentDetailLinkModel, resolveDocumentDetailLink } from '../../documents/helpers';
import { sortParticipants } from '../participant-list/helpers';
import { resolveTooltipContentForIssue } from './components/document-list-tooltip/ContentForIssue';
import { resolveTooltipContentForDocStatus } from './components/document-list-tooltip/ContentForStandardWorkflow';
import { SEALED_WORKSPACE_STATUSES } from './models';

const PRE_APPROVE_UI_STATUSES: DocumentListItemStatusEnum[] = [DocumentListItemStatusEnum.AwaitingInviteAcceptance, DocumentListItemStatusEnum.AwaitingEditFromOthers];

const PRE_SIGN_STEP_DOC_STATUSES: DocumentStatusEnum[] = [DocumentStatusEnum.Awaiting, DocumentStatusEnum.InPreparation, DocumentStatusEnum.Reviewing];

export const isLodgementInstructions = (documentIdentifier: LookupEnumModel<DocumentTypeIdentifierEnum>) =>
  documentIdentifier.id === DocumentTypeIdentifierEnum.LodgementInstructions;

export const isVicLodgementInstructions = (jurisdictionId?: JurisdictionsEnum, documentIdentifier?: DocumentTypeIdentifierEnum) =>
  jurisdictionId === JurisdictionsEnum.VIC && documentIdentifier === DocumentTypeIdentifierEnum.LodgementInstructions;

export function resolveColorVariant(uiStatus: DocumentListItemStatusEnum): 'error' | 'ok' | undefined {
  if (uiStatus === DocumentListItemStatusEnum.IssuesFound || uiStatus === DocumentListItemStatusEnum.ResolveIssues) {
    return 'error';
  }

  if (uiStatus === DocumentListItemStatusEnum.Lodged || uiStatus === DocumentListItemStatusEnum.Signed) {
    return 'ok';
  }

  return;
}

/*
 ** shared doc like NOA/transfer has a special display rules for other participants.
 ** we need to check if the current participant's document status is ready to sign or signed and also the participant has sign permission
 ** and other participants have not saved/reviewed the doc, we will display awaiting {role}
 */

export const computedSharedParticipants = (
  documentIdentifier: LookupEnumModel<DocumentTypeIdentifierEnum>,
  jurisdiction: LookupEnumModel<JurisdictionsEnum>,
  currentParticipantId: string,
  documentParticipants: Array<DocumentParticipant>
): Array<DocumentParticipant> => {
  //WEB-15513 - VIC LI Rebuild - All document participants should be displayed
  if (isVicLodgementInstructions(jurisdiction?.id, documentIdentifier?.id)) {
    return [...documentParticipants];
  }

  const hasSigningOrSignedStatus = documentParticipants.find(
    d => d.id === currentParticipantId && (d.documentStatus === DocumentStatusEnum.Signing || d.documentStatus === DocumentStatusEnum.Signed)
  );

  if (hasSigningOrSignedStatus) {
    // check if other participants have been approved the document,
    // if not we will need to display awaiting {self role} for the ready to sign participant
    documentParticipants.forEach((participant, index, self) => {
      if (currentParticipantId !== participant.id) {
        if (participant.documentStatus === DocumentStatusEnum.Reviewing || participant.documentStatus === DocumentStatusEnum.InPreparation) {
          participant.documentStatus = DocumentStatusEnum.AwaitingApproveFromOtherParticipant;
          self[index] = participant;
        }
      }
    });
  }

  return [...documentParticipants];
};

export function isToolbarAvailableForWorkspace(props: {
  mode: DocumentListAction;
  // basic workspace info
  workspaceStatusId?: WorkspaceStatusEnum;
  // full workspace info
  canAddAdditionalDocuments?: boolean;
}) {
  return Boolean(
    props.canAddAdditionalDocuments &&
      (props.mode === 'default' || props.mode === 'add') &&
      Number.isInteger(props.workspaceStatusId) &&
      !SEALED_WORKSPACE_STATUSES.includes(props.workspaceStatusId!)
  );
}

export function consolidateDocumentStatus<
  T extends {
    documentType: WorkspaceDocumentSummaryApiResponse['documentIdentifier']; // LookupEnumModel<DocumentTypeIdentifierEnum>;
    documentStatusId: WorkspaceDocumentSummaryApiResponse['documentStatus']['id']; // DocumentStatusEnum;
    documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; //DocumentParticipant[]; // all document participants
    primaryDocumentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; // DocumentParticipant[]; // primary only
    currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number]; // current document participant resolved based on query parameter
    jurisdiction?: WorkspaceDocumentSummaryApiResponse['jurisdiction']['id']; // JurisdictionsEnum;
  }
>(
  //
  participantMode: DocumentListItemParticipantMode,
  data: T,
  mode: DocumentListAction = 'default',
  isSLC?: boolean
): DocumentListItemStatusEnum {
  const { documentType, documentStatusId, documentParticipants, primaryDocumentParticipants, currentDocumentParticipant, jurisdiction } = data;
  let uiStatus: DocumentListItemStatusEnum = DocumentListItemStatusEnum.Unknown;
  // * Lodgement instruction cannot be reordered or deleted
  if (!isLodgementInstructions(documentType)) {
    if (mode === 'reorder') {
      if (!isSLC) {
        // For SLC doc in FS workspace, it can not be not reordered WEB-37393
        return DocumentListItemStatusEnum.Reorder;
      }
    } else if (mode === 'delete') {
      return DocumentListItemStatusEnum.Delete;
    }
  }

  if (primaryDocumentParticipants.length > 1) {
    // if current participant is ready to sign
    // and there are multiple primary participants, user should not be able to sign the document
    // ...until the rest of the participants has approved their part of the document (e.g. NOA)
    // WEB-17846, WEB-15513 Exception/Special Case: Vic Lodgement Instruction: Document Status should be as per
    // the actual mapped status and not displayed as AwaitingApproveFromOthers
    if (currentDocumentParticipant?.documentStatus === DocumentStatusEnum.Signing && !isVicLodgementInstructions(jurisdiction, documentType?.id)) {
      const primaryOtherThanCurrentParticipant = primaryDocumentParticipants.filter(item => item.id !== currentDocumentParticipant!.id);
      // verify whether someone from the rest of primary participant is still stuck in edit or approval workflow
      if (primaryOtherThanCurrentParticipant.some(item => PRE_SIGN_STEP_DOC_STATUSES.includes(item.documentStatus))) {
        // in this case, current participant can't proceed with signing, because edit/approval part is not finished yet
        return DocumentListItemStatusEnum.AwaitingApproveFromOthers;
      }
    }

    if (participantMode === 'ws-only') {
      if (documentParticipants.every(item => item.documentStatus === DocumentStatusEnum.Awaiting)) {
        return DocumentListItemStatusEnum.AwaitingInviteAcceptance;
      } else if (documentParticipants.every(item => item.documentStatus !== DocumentStatusEnum.Awaiting && PRE_SIGN_STEP_DOC_STATUSES.includes(item.documentStatus))) {
        // for non-document participants we will display In draft if someone's status is edit or approval workflow
        return DocumentListItemStatusEnum.InDraft;
      }
    }
  }

  // * Lodgement instruction
  if (participantMode === 'primary' && isLodgementInstructions(documentType) && documentStatusId === DocumentStatusEnum.InPreparation) {
    return DocumentListItemStatusEnum.InDraft;
  }

  switch (participantMode) {
    case 'primary': {
      // this is the list of possible document statuses for primary participant
      // anything else is considered as a unknown state and should be logged a handled with care
      // see https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-with-edit-document-permission
      const mapping: Partial<Record<DocumentStatusEnum, DocumentListItemStatusEnum>> = {
        [DocumentStatusEnum.Awaiting]: DocumentListItemStatusEnum.AwaitingInviteAcceptance,
        [DocumentStatusEnum.InPreparation]: DocumentListItemStatusEnum.Edit,
        [DocumentStatusEnum.Reviewing]: DocumentListItemStatusEnum.ReadyToApprove,
        [DocumentStatusEnum.Approved]: DocumentListItemStatusEnum.Approved, // this will happen for NOA
        [DocumentStatusEnum.Signing]: DocumentListItemStatusEnum.ReadyToSign,
        [DocumentStatusEnum.Signed]: DocumentListItemStatusEnum.Signed,
        [DocumentStatusEnum.LodgementVerificationInProgress]: DocumentListItemStatusEnum.VerificationInProgress,
        [DocumentStatusEnum.LodgementInQueue]: DocumentListItemStatusEnum.LodgementInQueue,
        [DocumentStatusEnum.LodgementInProgress]: DocumentListItemStatusEnum.LodgementInProgress,
        [DocumentStatusEnum.Lodged]: DocumentListItemStatusEnum.Lodged,
        [DocumentStatusEnum.ResolveIssues]: DocumentListItemStatusEnum.ResolveIssues
      };
      // explicitly use status from currentDocumentParticipant
      uiStatus = mapping[currentDocumentParticipant!.documentStatus] || DocumentListItemStatusEnum.Unknown; // fallback to unknown
      break;
    }
    case 'non-primary': {
      // this is the list of possible document statuses for non-primary participant
      // anything else is considered as a unknown state and should be logged a handled with care
      // see https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-who-DOES-NOT-have-edit-document-permission
      const mapping: Partial<Record<DocumentStatusEnum, DocumentListItemStatusEnum>> = {
        [DocumentStatusEnum.Awaiting]: DocumentListItemStatusEnum.AwaitingInviteAcceptance,
        [DocumentStatusEnum.InPreparation]: DocumentListItemStatusEnum.AwaitingEditFromOthers,
        [DocumentStatusEnum.Reviewing]: DocumentListItemStatusEnum.ReadyToApprove,
        [DocumentStatusEnum.Signing]: DocumentListItemStatusEnum.ReadyToSign,
        [DocumentStatusEnum.Signed]: DocumentListItemStatusEnum.Signed,
        [DocumentStatusEnum.LodgementVerificationInProgress]: DocumentListItemStatusEnum.VerificationInProgress,
        [DocumentStatusEnum.LodgementInQueue]: DocumentListItemStatusEnum.LodgementInQueue,
        [DocumentStatusEnum.LodgementInProgress]: DocumentListItemStatusEnum.LodgementInProgress,
        [DocumentStatusEnum.Lodged]: DocumentListItemStatusEnum.Lodged,
        [DocumentStatusEnum.ResolveIssues]: DocumentListItemStatusEnum.ResolveIssues,
        [DocumentStatusEnum.Approved]: DocumentListItemStatusEnum.Approved
      };
      // explicitly use status from currentDocumentParticipant
      uiStatus = mapping[currentDocumentParticipant!.documentStatus] || DocumentListItemStatusEnum.Unknown; // fallback to unknown

      break;
    }
    default: {
      // ws-only

      // this is the list of possible document statuses for workspace only participants
      // anything else is considered as a unknown state and should be logged a handled with care
      // see https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-workspace-participants-not-listed-as-document-participants
      const mapping: Partial<Record<DocumentStatusEnum, DocumentListItemStatusEnum>> = {
        [DocumentStatusEnum.Awaiting]: DocumentListItemStatusEnum.AwaitingInviteAcceptance,
        [DocumentStatusEnum.InPreparation]: DocumentListItemStatusEnum.InDraft,
        [DocumentStatusEnum.Reviewing]: DocumentListItemStatusEnum.InDraft,
        [DocumentStatusEnum.Approved]: DocumentListItemStatusEnum.Approved,
        [DocumentStatusEnum.Signing]: DocumentListItemStatusEnum.Approved,
        [DocumentStatusEnum.Signed]: DocumentListItemStatusEnum.Signed,
        [DocumentStatusEnum.LodgementVerificationInProgress]: DocumentListItemStatusEnum.VerificationInProgress,
        [DocumentStatusEnum.LodgementInQueue]: DocumentListItemStatusEnum.LodgementInQueue,
        [DocumentStatusEnum.LodgementInProgress]: DocumentListItemStatusEnum.LodgementInProgress,
        [DocumentStatusEnum.Lodged]: DocumentListItemStatusEnum.Lodged,
        [DocumentStatusEnum.ResolveIssues]: DocumentListItemStatusEnum.IssuesFound
      };
      // explicitly use status from DOCUMENT since participant is not listed as a document participant
      uiStatus = mapping[documentStatusId] || DocumentListItemStatusEnum.Unknown; // fallback to unknown
      break;
    }
  }

  if (uiStatus === DocumentListItemStatusEnum.Unknown) {
    const scope = Logger.getDefaultScope().setExtraAttributes({
      document,
      mode: participantMode
    });
    Logger.captureException(new InvalidDataError('Unsupported document status', currentDocumentParticipant?.documentStatus), scope);
  }

  return uiStatus;
}

function resolveParticipantInvitationDescription<
  T extends {
    documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; //DocumentParticipant[]; // all document participants
    currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number]; // current document participant resolved based on query parameter
    workspaceParticipants?: WorkspaceParticipantApiResponse[];
    isCriticalRolesEnabled: boolean;
  }
>(data: T): string {
  const { documentParticipants, currentDocumentParticipant } = data;

  // if single doc and no invite by then it is to be await invitation
  if (documentParticipants.length === 1 && currentDocumentParticipant === undefined && documentParticipants[0].invitedBy === undefined) {
    return DocumentStatusDisplayEnum.ToBeInvited;
  }

  // if it has any invitation send then always display Invited and it always happens when the overall status is await invitee,
  // like IM VIEW for transfer doc, or placeholder doc form 24B
  if (documentParticipants.length > 1) {
    const description =
      currentDocumentParticipant !== undefined &&
      // if an invitation has been send out then InvitedBy section will exist
      currentDocumentParticipant.invitedBy === undefined &&
      currentDocumentParticipant.participantStatus.id !== ParticipantStatusEnum.Accepted
        ? DocumentStatusDisplayEnum.ToBeInvited
        : DocumentStatusDisplayEnum.Invited;

    return resolveNAStatusDescription(data, description);
  }

  return DocumentStatusDisplayEnum.Invited;
}

function resolveMultiParticipantDescription<
  T extends {
    //
    documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; //DocumentParticipant[]; // all document participants
    currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number];
    documentActions: WorkspaceDocumentSummaryApiResponse['documentActions'];
    numberOfRequiredSigners: WorkspaceDocumentSummaryApiResponse['requiredSigners'];
    isCriticalRolesEnabled: boolean;
  }
>(
  uiStatus: DocumentListItemStatusEnum.ReadyToSign | DocumentListItemStatusEnum.Signed | DocumentListItemStatusEnum.VerificationInProgress,
  data: T,
  actionType: DocumentActionTypeEnum
): string {
  const { numberOfRequiredSigners, documentActions, currentDocumentParticipant, documentParticipants } = data;
  const participantIdsAlreadyActioned: string[] = documentActions.filter(action => action.type === actionType).map(action => action.participantId);
  const participantsNotActioned: ConsolidationModel['documentParticipants'] = documentParticipants.filter(participant => !participantIdsAlreadyActioned.includes(participant.id));

  // the required signers only will be Participant Level, For shared doc we will always be get from workflow level instead
  // of the global level.
  const requiredSigners = currentDocumentParticipant?.documentWorkflow.requiredSigners ?? numberOfRequiredSigners;
  const numberOfUserAlreadyActioned = documentActions.filter(action => action.type === actionType && currentDocumentParticipant?.id === action.participantId)?.length;
  switch (uiStatus) {
    case DocumentListItemStatusEnum.VerificationInProgress:
      if (participantsNotActioned.length !== 0) {
        return DocumentStatusDisplayEnum.Approved;
      }
      return DocumentStatusDisplayEnum.Signed;
    case DocumentListItemStatusEnum.ReadyToSign:
      if (requiredSigners > 1) {
        if (currentDocumentParticipant && participantsNotActioned.includes(currentDocumentParticipant)) {
          return DocumentStatusDisplayEnum.Approved;
        }

        if (numberOfUserAlreadyActioned < requiredSigners) {
          return `${DocumentStatusDisplayEnum.PartiallySigned} (${numberOfUserAlreadyActioned} of ${requiredSigners})`;
        }
        return DocumentStatusDisplayEnum.Approved;
      }
      return DocumentStatusDisplayEnum.Approved;
    case DocumentListItemStatusEnum.Signed:
      // if it is signed, then it is always fully signed
      if (requiredSigners > 1) {
        return `${DocumentStatusDisplayEnum.Signed} (${requiredSigners} of ${requiredSigners})`;
      }
      return DocumentStatusDisplayEnum.Signed;

    default:
      Logger.captureException(new InvalidDataError('Unsupported UI document status', uiStatus));
      return '';
  }
}

/**
 * Resolves if the participant description should be set as N/A
 * Conditions: currentDocumentParticipant.DocumentStatus must be awaiting (pending) and
 * if there's any accepted participant which matches the pending role in currentDocumentParticipant
 * @param data
 * @param description
 * @returns
 */
function resolveNAStatusDescription<
  T extends {
    currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number]; // current document participant resolved based on query parameter
    workspaceParticipants?: WorkspaceParticipantApiResponse[];
    isCriticalRolesEnabled: boolean;
  }
>(data: T, description: string): string {
  const { isCriticalRolesEnabled, workspaceParticipants, currentDocumentParticipant } = data;
  if (currentDocumentParticipant === undefined || currentDocumentParticipant.documentStatus !== DocumentStatusEnum.Awaiting) {
    return description;
  }

  if (
    isCriticalRolesEnabled &&
    workspaceParticipants
      ?.filter(wp => wp.participantStatus.id === ParticipantStatusEnum.Accepted)
      .some(wp => wp.workspaceRole.name === currentDocumentParticipant.workspaceRole.name)
  ) {
    return DocumentStatusDisplayEnum.NA;
  }

  return description;
}

export function resolveDocumentStatusText<
  T extends {
    documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; //DocumentParticipant[]; // all document participants
    currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number];
    documentActions: WorkspaceDocumentSummaryApiResponse['documentActions'];
    numberOfRequiredSigners: WorkspaceDocumentSummaryApiResponse['requiredSigners'];
    lrDocumentId?: string;
    documentLodgementStatus?: LookupEnumModel<DocumentLodgementStatusEnum>;
    workspaceParticipants?: WorkspaceParticipantApiResponse[];
    isCriticalRolesEnabled: boolean;
  }
>(
  //
  uiStatus: DocumentListItemStatusEnum,
  data: T
): string {
  // 1:1 mapping of ui status to text being displayed to the user
  const mapping: Record<DocumentListItemStatusEnum, string> = {
    [DocumentListItemStatusEnum.InDraft]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.AwaitingInviteAcceptance]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.AwaitingEditFromOthers]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.AwaitingApproveFromOthers]: DocumentStatusDisplayEnum.Approved,
    [DocumentListItemStatusEnum.Edit]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.ReadyToApprove]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.Approved]: DocumentStatusDisplayEnum.Approved,
    [DocumentListItemStatusEnum.ReadyToSign]: DocumentStatusDisplayEnum.Approved,
    [DocumentListItemStatusEnum.Signed]: DocumentStatusDisplayEnum.Signed,
    [DocumentListItemStatusEnum.VerificationInProgress]: DocumentStatusDisplayEnum.Signed,
    [DocumentListItemStatusEnum.LodgementInQueue]: DocumentStatusDisplayEnum.Lodging,
    [DocumentListItemStatusEnum.LodgementInProgress]: DocumentStatusDisplayEnum.Lodging,
    [DocumentListItemStatusEnum.Lodged]: DocumentStatusDisplayEnum.Lodged,
    [DocumentListItemStatusEnum.ResolveIssues]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.IssuesFound]: DocumentStatusDisplayEnum.InPreparation,
    [DocumentListItemStatusEnum.Unknown]: 'Unknown', // this should never happen
    [DocumentListItemStatusEnum.Reorder]: '', // this will never happen
    [DocumentListItemStatusEnum.Delete]: '' // this will never happen
  };

  let description: string = mapping[uiStatus];

  switch (uiStatus) {
    case DocumentListItemStatusEnum.AwaitingInviteAcceptance:
      description = resolveParticipantInvitationDescription(data);
      break;
    case DocumentListItemStatusEnum.ReadyToSign:
    case DocumentListItemStatusEnum.Signed:
    case DocumentListItemStatusEnum.VerificationInProgress: // this special as if it LV we will either display approved or signed based on current doc status
      description = resolveMultiParticipantDescription(uiStatus, data, DocumentActionTypeEnum.Sign);
      break;
    case DocumentListItemStatusEnum.Lodged:
      if (data.documentLodgementStatus && data.lrDocumentId) {
        return `${data.documentLodgementStatus.name}, DEALING: ${data.lrDocumentId}`;
      }
      break;
    default:
      break;
  }
  return resolveNAStatusDescription(data, description);
}

function resolveDocumentItemDescription<
  T extends {
    documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; //DocumentParticipant[]; // all document participants
    currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number];
    lrDocumentId?: string;
    documentLodgementStatus?: LookupEnumModel<DocumentLodgementStatusEnum>;
    documentActions: WorkspaceDocumentSummaryApiResponse['documentActions'];
    numberOfRequiredSigners: number;
    lodgementDateTime?: WorkspaceDateTimeModel;
    workspaceParticipants?: WorkspaceParticipantApiResponse[];
    isCriticalRolesEnabled: boolean;
  }
>(
  //
  mode: DocumentListItemParticipantMode,
  uiStatus: DocumentListItemStatusEnum,
  data: T,
  badgeOnly: boolean = false
): React.ReactNode {
  let description: string = resolveDocumentStatusText(uiStatus, data);

  switch (uiStatus) {
    case DocumentListItemStatusEnum.Lodged:
      // we only need to show document ID for document StatusBadge
      if (badgeOnly && data.lrDocumentId) {
        return <Typography variant="body6">DEALING: {data.lrDocumentId}</Typography>;
      }

      if (data.documentLodgementStatus) {
        return (
          <FlexLayout justifyContent="flex-start" alignItems="center">
            {data.documentLodgementStatus.id === DocumentLodgementStatusEnum.Lodged ? (
              <Tooltip title={resolveTooltipContentForDocStatus(mode, description, uiStatus, data.documentParticipants.length > 1, badgeOnly)} tooltipType="document">
                <Typography variant="body6" sx={{ maxWidth: '192px' }}>
                  {description}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="body6" sx={{ maxWidth: '192px' }}>
                {description}
              </Typography>
            )}
            {data.lrDocumentId && <CopyIcon className="pl-[4px]" title="Document ID" textToCopy={data.lrDocumentId} />}
          </FlexLayout>
        );
      }
      break;
    default:
      break;
  }

  if (resolveColorVariant(uiStatus) === 'error') {
    return <IconTypography text={description} tooltipTitle={resolveTooltipContentForIssue(mode, data.lodgementDateTime)} />;
  }

  if (uiStatus === DocumentListItemStatusEnum.Reorder || uiStatus === DocumentListItemStatusEnum.Delete) {
    return (
      <Typography variant="body4" sx={{ display: 'block' }}>
        {description}
      </Typography>
    );
  }

  return (
    <Tooltip title={resolveTooltipContentForDocStatus(mode, description, uiStatus, data.documentParticipants.length > 1, badgeOnly)} tooltipType="document">
      <Typography variant="body4">{description}</Typography>
    </Tooltip>
  );
}

function resolveDocumentItemVisualMode(participantMode: DocumentListItemParticipantMode, uiStatus: DocumentListItemStatusEnum): DocumentItemVisualMode {
  switch (participantMode) {
    case 'primary':
      return 'secondary'; // black mode always
    case 'non-primary':
      // user can't enter approval process if it's not ready for approval
      if (PRE_APPROVE_UI_STATUSES.includes(uiStatus)) {
        return 'info'; //  grey mode
      }
      return 'secondary'; // black mode
    default:
      // ws-only participants will be always in grey mode
      return 'info';
  }
}

function resolveDocumentItemLink(
  participantMode: DocumentListItemParticipantMode,
  uiStatus: DocumentListItemStatusEnum,
  linkParams: DocumentDetailLinkModel,
  documentIdentifier: DocumentTypeIdentifierEnum,
  jurisdiction: JurisdictionsEnum
): string | undefined {
  switch (participantMode) {
    case 'primary':
      // primary participant has always access to the document
      break;
    case 'non-primary':
      // user can't enter approval process if it's not ready for approval
      if (PRE_APPROVE_UI_STATUSES.includes(uiStatus)) {
        return;
      }

      // otherwise user will be able to access the document
      break;
    default:
      // WEB-17836
      if (isVicLodgementInstructions(jurisdiction, documentIdentifier)) {
        // only time participant mode will be ws-only for VIC LI is when no dealing requirements for non workspace rs participant
        break;
      }
      // for non-document participants we block access if someone is still stuck in awaiting, edit or approval workflow
      if (uiStatus === DocumentListItemStatusEnum.InDraft || uiStatus === DocumentListItemStatusEnum.AwaitingInviteAcceptance) {
        return;
      }

      // otherwise user will be able to access the document
      break;
  }

  return resolveDocumentDetailLink(linkParams);
}

function resolveDocumentIcon(uiStatus: DocumentListItemStatusEnum) {
  switch (uiStatus) {
    case DocumentListItemStatusEnum.Reorder:
      return <NewReorderIcon />;
    case DocumentListItemStatusEnum.Delete:
      return <NewDeleteIcon />;
    case DocumentListItemStatusEnum.Unknown:
    default:
      return undefined;
  }
}

function resolveDisabled(
  mode: DocumentListAction,
  documentIdentifier: LookupEnumModel<DocumentTypeIdentifierEnum>,
  permissions: DocumentPermissionEnum[],
  visualMode: DocumentItemVisualMode,
  linkTo?: string
) {
  switch (mode) {
    case 'delete':
      return isLodgementInstructions(documentIdentifier) || !permissions.includes(DocumentPermissionEnum.Remove);
    default:
      return !linkTo && visualMode === 'info';
  }
}

function getFilteredDocumentParticipants<
  T extends {
    hasDealingRequirements: WorkspaceDocumentSummaryApiResponse['hasDealingRequirements'];
    jurisdiction: WorkspaceDocumentSummaryApiResponse['jurisdiction']['id'];
    documentType: WorkspaceDocumentSummaryApiResponse['documentIdentifier'];
    documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants'];
  }
>(data: T) {
  // WEB-15513 - Additional participants should be hidden when vic li does not have dealing requirements
  // WEB-38421 - Hide if has additional participants of same role as primary participant
  if (isLodgementInstructions(data.documentType) && data.hasDealingRequirements === false) {
    return data.documentParticipants.filter(participant => participant.isPrimaryDocumentParticipant);
  }

  return data.documentParticipants;
}

/**
 * participantMode defines what level of access user has to given document
 * - primary - user can edit, review, sign
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-with-edit-document-permission
 *
 * - non-primary - user can review and sign
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-who-DOES-NOT-have-edit-document-permission
 *
 * - ws-only - user can only see document after it has been approved
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-workspace-participants-not-listed-as-document-participants
 *
 * default: ws-only
 */
export function resolveDocumentParticipantMode(
  //
  document: WorkspaceDocumentSummaryApiResponse,
  participantId: string
): DocumentListItemParticipantMode {
  //
  const { documentParticipants, documentIdentifier, jurisdiction, hasDealingRequirements } = document;

  // check whether currentParticipant is listed as a document participant
  const currentDocumentParticipant = documentParticipants.find(item => item.id === participantId);

  if (currentDocumentParticipant) {
    if (currentDocumentParticipant.isPrimaryDocumentParticipant) return 'primary';

    if (isLodgementInstructions(documentIdentifier)) {
      // WEB-38421
      const responsibleParticipantRoles = documentParticipants.find(dp => dp.isPrimaryDocumentParticipant)?.workspaceRoles.map(r => r.id) ?? [];
      // Any participant with the same workspace roles of the responsible participant should be able to save + approve + sign
      if (currentDocumentParticipant.workspaceRoles.some(role => responsibleParticipantRoles.includes(role.id))) return 'primary';

      // WEB-17836
      if (jurisdiction.id === JurisdictionsEnum.VIC && !hasDealingRequirements) {
        // VIC LI non workspace responsible participants (has edit permissions but cannot approve or sign) should see ws-only document status
        return 'ws-only';
      }
    }

    return 'non-primary';
  }
  // user is not participating on the document, therefore their actions are very limited
  return 'ws-only';
}

export function resolveDocumentListItem101(
  mode: DocumentListAction,
  document: WorkspaceDocumentSummaryApiResponse,
  workspaceId: string,
  participantId: string,
  isCriticalRolesEnabled: boolean,
  selectedDocumentId?: string,
  lodgementDateTime?: WorkspaceDateTimeModel,
  isSLC?: boolean,
  workspaceParticipants?: WorkspaceParticipantApiResponse[]
): DocumentListModel {
  const {
    name,
    documentId,
    documentStatus: { id: documentStatusId },
    documentParticipants,
    documentActions,
    documentIdentifier,
    lodgementDetail,
    requiredSigners: numberOfRequiredSigners,
    supportingDocuments,
    permissions,
    jurisdiction,
    hasDealingRequirements,
    isFullySignedByAllParticipants
  } = document;

  const linkParams: DocumentDetailLinkModel = {
    workspaceId,
    participantId,
    documentId
  };

  const participantMode: DocumentListItemParticipantMode = resolveDocumentParticipantMode(document, participantId);
  // identify primary participants
  const primaryDocumentParticipants = documentParticipants.filter(item => item.isPrimaryDocumentParticipant);
  // check whether currentParticipant is listed as a document participant
  const currentDocumentParticipant = documentParticipants.find(item => item.id === participantId);

  const data = {
    //
    documentType: documentIdentifier,
    documentStatusId,
    documentParticipants,
    documentActions,
    primaryDocumentParticipants,
    currentDocumentParticipant,
    numberOfRequiredSigners,
    hasDealingRequirements,
    lrDocumentId: lodgementDetail?.lrDocumentId,
    jurisdiction: jurisdiction?.id,
    documentLodgementStatus: lodgementDetail?.status,
    lodgementDateTime,
    isCriticalRolesEnabled
  };

  const uiStatus: DocumentListItemStatusEnum = consolidateDocumentStatus(participantMode, data, mode, isSLC);
  const description: React.ReactNode = resolveDocumentItemDescription(participantMode, consolidateDocumentStatus(participantMode, data, 'default', isSLC), data);
  const visualMode: DocumentItemVisualMode = resolveDocumentItemVisualMode(participantMode, uiStatus);
  const linkTo: string | undefined = resolveDocumentItemLink(participantMode, uiStatus, linkParams, documentIdentifier.id, jurisdiction.id);
  const filteredDocumentParticipants: ConsolidationModel['documentParticipants'] = getFilteredDocumentParticipants(data);
  const icon: JSX.Element | undefined = resolveDocumentIcon(uiStatus);

  const currentWorkspaceParticipant = workspaceParticipants?.find(p => p.id === participantId);
  const participants: DocumentListParticipant[] = sortParticipants(filteredDocumentParticipants, documentParticipantOrderedList).map(participant => {
    const {
      id,
      name,
      avatarUrl,
      reference,
      workspaceRole: { name: role },
      documentStatus,
      workspaceRoles,
      participantStatus
    } = participant;
    // * For each of the participant from the document participants
    // * We should fall back to ws-only when this participant is not the current participant (the query param)
    let participantItemMode: DocumentListItemParticipantMode = participantMode;
    const isCurrentParticipant = id === participantId;
    if (!isCurrentParticipant) {
      // WEB-17846, WEB-15513 Non current participants for Vic Lodgement Instructions should be treated as Non Primary
      // for the purpose displaying document status for the participant
      participantItemMode = isVicLodgementInstructions(jurisdiction?.id, documentIdentifier?.id) ? 'non-primary' : 'ws-only';
    }

    // Consolidation data for each participant
    const participantData: ConsolidationModel = {
      //
      documentType: documentIdentifier,
      documentStatusId: documentStatus,
      documentParticipants,
      documentActions,
      primaryDocumentParticipants,
      currentDocumentParticipant: participant,
      numberOfRequiredSigners,
      lrDocumentId: lodgementDetail?.lrDocumentId,
      jurisdiction: jurisdiction?.id,
      workspaceParticipants,
      isCriticalRolesEnabled
    };

    const participantUiStatus: DocumentListItemStatusEnum = consolidateDocumentStatus(participantItemMode, participantData, mode);
    const participantBadgeLabel: React.ReactNode = resolveDocumentItemDescription(participantMode, participantUiStatus, participantData, true);
    const badge = <>{participantBadgeLabel}</>;

    return {
      name,
      avatarUrl,
      reference,
      role,
      workspaceRoles,
      badge,
      canJoin:
        isCriticalRolesEnabled &&
        documentIdentifier.id === DocumentTypeIdentifierEnum.Transfer &&
        currentWorkspaceParticipant?.workspaceRole?.name === role &&
        documentStatus === DocumentStatusEnum.Awaiting,
      participantStatusId: participantStatus.id
    };
  });

  const isLI = isLodgementInstructions(documentIdentifier);
  const selected = !!selectedDocumentId && selectedDocumentId === documentId;
  const disabled = resolveDisabled(mode, documentIdentifier, permissions, visualMode, linkTo);
  const supportingDocs =
    supportingDocuments?.map(doc =>
      resolveDocumentListItem101(mode, doc, workspaceId, participantId, isCriticalRolesEnabled, selectedDocumentId, undefined, undefined, workspaceParticipants)
    ) ?? [];

  return {
    documentId,
    documentIdentifier: documentIdentifier.id,
    name,
    linkTo,
    participants,
    selected,
    disabled,
    isLI,
    supportingDocuments: supportingDocs,
    description,
    icon,
    uiStatus,
    visualMode,
    canRemove: permissions.includes(DocumentPermissionEnum.Remove),
    isFullySignedByAllParticipants
  };
}

export function sortingByLodgementCase<T extends { lodgementCase?: LodgementCase }>(lodgementCaseDetailModels: Array<T>): Array<T> {
  lodgementCaseDetailModels.sort((a, b) => {
    if (!a.lodgementCase || !b.lodgementCase) {
      return 0;
    }
    if (isAfter(new Date(a.lodgementCase.created), new Date(b.lodgementCase.created))) {
      return 1;
    }

    return -1;
  });
  return lodgementCaseDetailModels;
}

export function groupDocumentsByLodgementCaseId(workspaceDocuments: Array<WorkspaceDocumentSummaryApiResponse>) {
  const groupDocumentsByLodgementCaseId: Map<string, WorkspaceDocumentSummaryApiResponse[]> = workspaceDocuments.reduce((entryMap, doc) => {
    if (!entryMap.has(doc.lodgementCaseId)) {
      entryMap.set(doc.lodgementCaseId, [...(entryMap.get(doc.lodgementCaseId) || []), doc]);
    } else {
      entryMap.get(doc.lodgementCaseId)!.push(doc);
    }
    return entryMap;
  }, new Map<string, WorkspaceDocumentSummaryApiResponse[]>());
  return groupDocumentsByLodgementCaseId;
}
