import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    lockIcon: {
      width: 35,
      height: 35,
      flexShrink: 0
    },
    lockIcon101: {
      width: 32,
      height: 32,
      flexShrink: 0,
      padding: theme.spacing(0, 1.5, 0, 0.5)
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 10px',
      borderRadius: 4,
      height: 24,
      margin: '4px 5px 5px 20px',
      backgroundColor: theme.palette.grey[100],
      fontSize: 13,
      lineHeight: '15px'
    },
    warning: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main
    },
    locked: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.primary.contrastText
    },
    inlineEditorContainer: {
      marginBottom: 8
    },
    header: {
      overflow: 'hidden'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
