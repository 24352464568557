import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    currencyInput: {
      flex: 'calc(50% - 10px)',
      paddingRight: '10px'
    },
    fullWidthInput: {
      paddingRight: '10px'
    }
  }),
  {
    name: 'SalePrice'
  }
);
