import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { StandardTermsDealingNumbersModel, StandardTermsModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function StandardTermsDealingNumbers({ name }: Props): JSX.Element {
  const { values } = useFormikContext<StandardTermsModel>();

  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<StandardTermsDealingNumbersModel>(name);
  const value: StandardTermsDealingNumbersModel = _get(values, name);

  const standardTermsDealingNumbers = values.standardTermsDealingNumbers;

  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(value, values);

  const options = useMemo(() => {
    const preRegisteredStandardTermsDealingNumbers = values.preRegisteredStandardTermsDealingNumbers ?? [];
    const selectedDealingNumbers = standardTermsDealingNumbers?.map(v => (v.termNumber ?? '').trim()).filter(v => v !== '');
    return preRegisteredStandardTermsDealingNumbers.filter(x => !selectedDealingNumbers?.includes(x) || x === (value.termNumber ?? '').trim());
  }, [standardTermsDealingNumbers, value.termNumber, values.preRegisteredStandardTermsDealingNumbers]);

  const placeholder = options.length ? resolveComboboxPlaceholder(isRequired) : resolvePlaceholder(isRequired);

  return (
    <Field //
      aria-label="Dealing number"
      name={fieldName('termNumber')}
      component={SympliAutocompleteField}
      disabled={disabled}
      placeholder={placeholder}
      type="combobox"
      options={options}
    />
  );
}

export default React.memo(StandardTermsDealingNumbers);
