import { DocumentActionTypeEnum } from '@sympli/api-gateway/enums';

export function resolveActionPrefix(type: DocumentActionTypeEnum) {
  switch (type) {
    case DocumentActionTypeEnum.Review: {
      return 'Reviewed by';
    }
    case DocumentActionTypeEnum.Sign: {
      return 'Signed by';
    }
    default: {
      return 'Saved by';
    }
  }
}

export function resolveActionRolePrefix(type: DocumentActionTypeEnum) {
  switch (type) {
    case DocumentActionTypeEnum.Review: {
      return 'Approver';
    }
    case DocumentActionTypeEnum.Sign: {
      return 'Signer';
    }
    default: {
      return '';
    }
  }
}

export function resolveWorkflowPanelMode(displayPdf?: boolean) {
  if (displayPdf) {
    return 'dark';
  }
  return 'light';
}
