import React from 'react';

import { FormikProps, getIn } from 'formik';
import _get from 'lodash-es/get';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import PartyReference from '../../components/party-reference';
import { BaseCaveatModel, TitleReferenceModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  formikProps: FormikProps<BaseCaveatModel>;
  focusRef?: React.RefObject<ButtonBaseActions>;
  disabled: boolean;
  dialogPortalId: string;
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

const SectionRegisteredProprietor = ({
  formikProps,
  disabled,
  name,
  dialogPortalId,
  partyFormConfig,
  addressFormConfig,
  addressFormInitialValues,
  focusRef,
  syncPartyReferences
}: Props): JSX.Element => {
  const classes = useStyles();
  const { values, touched, setFieldValue, setFieldTouched } = formikProps;

  const items: TenancyPartyModel[] = _get(values, name) ?? [];
  const count = items.length;
  const hasMultipleItems = count > 1;
  const disableCheckbox = disabled || count <= 1;

  const renderItem = ({ item, itemIndex }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
    return (
      <PartyReference
        name={`${name}[${itemIndex}]`}
        formikProps={formikProps}
        disabled={disabled || !item.isSelected}
        dialogPortalId={dialogPortalId}
        partyFormConfig={partyFormConfig}
        addressFormConfig={addressFormConfig}
        addressFormInitialValues={addressFormInitialValues}
        syncPartyReferences={syncPartyReferences}
      />
    );
  };

  const renderItemTitle = ({ itemIndex, fieldName }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
    if (!hasMultipleItems) return 'Proprietor';

    return <Field component={CheckboxField} label={`Proprietor ${itemIndex + 1}`} name={fieldName('isSelected')} disabled={disableCheckbox} />;
  };

  const handleSelectAllProprietorsChange = (event: React.ChangeEvent<HTMLInputElement>, newAreAllSelected: boolean): void => {
    const updatedItems = items.map(proprietor => ({
      ...proprietor,
      isSelected: newAreAllSelected
    }));

    setFieldValue(name, updatedItems);
    // we want to show errors immediately, therefore field needs to be marked as touched
    // prevent unnecessary render of Formik tree by calling setFieldTouched only when it makes sense
    !getIn(touched, name) && setFieldTouched(name, true, false /* don't trigger validation */);
  };

  const renderSectionError = () => {
    if (count !== 0) return undefined;

    const fieldName = modelKey<BaseCaveatModel>();
    const titles: TitleReferenceModel[] = _get(values, fieldName('titleReferences')) ?? [];
    const sectionErrorMessage = titles.some(item => item.isSelected)
      ? 'The proprietors on the selected titles do not match. Please review the selected titles.'
      : 'Please select a title reference to identify the proprietor';
    return sectionErrorMessage && <Typography className={classes.noProprietorsMessage}>{sectionErrorMessage}</Typography>;
  };

  return (
    <Section //
      title={pluralize('Registered proprietor', items.length)}
      data-name={name}
      data-testid={name}
      error={getFormikError(formikProps, name)}
    >
      {!disableCheckbox && (
        <FormGroup
          title={
            <Checkbox
              label="Select all proprietors"
              name="select-all-proprietors"
              format="boolean"
              checked={items.every(item => item.isSelected)}
              onChange={handleSelectAllProprietorsChange}
            />
          }
          paddingBottom={true}
        ></FormGroup>
      )}
      {renderSectionError()}
      <DocumentFieldArray //
        arrayBinding={name}
        itemTitle={renderItemTitle}
        renderItem={renderItem}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        focusRef={focusRef}
        mode="fixed"
      />
    </Section>
  );
};

export default SectionRegisteredProprietor;
