import React from 'react';

import classnames from 'classnames';
import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { IS_REQUIRED_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER } from '../../isRequiredChecks';
import { PriorityNoticeModel, TransactionInstrumentModel } from '../../models';
import {
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES
} from '../../visibilityChecks';
import ReceivingParties from '../receiving-parties/ReceivingParties';
import TitleReferences from '../title-references';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
const debug = false && !import.meta.env.PROD;
interface Props {
  name: string;
  className?: string;
  dialogPortalId: string;
}

function TransactionInstrument({ name, className, dialogPortalId }: Props): JSX.Element {
  const classes = useStyles();
  const { values } = useFormikContext<PriorityNoticeModel>();
  const { nextActionDisabled } = useDocumentContext();

  const fieldName = createModelKeyAppender<TransactionInstrumentModel>(name);

  const transactionInstrument: TransactionInstrumentModel = _get(values, name);
  return (
    <div data-section={name} className={classnames(classes.root, className)}>
      <Field //
        name={fieldName('documentType')}
        component={SelectField}
        debug={debug}
        disabled={nextActionDisabled}
        format="number"
        options={TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
        label="Document type"
        fullWidth
      />
      {VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER(transactionInstrument) && (
        <Field //
          name={fieldName('dealingNumber')}
          component={InputField}
          disabled={nextActionDisabled}
          placeholder={resolvePlaceholder(IS_REQUIRED_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER(transactionInstrument))}
          label="Registered dealing number"
        />
      )}
      {VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES(transactionInstrument) && (
        <ReceivingParties //
          name={fieldName('receivingParties')}
          dialogPortalId={dialogPortalId}
          receivingParties={transactionInstrument.receivingParties}
          documentType={transactionInstrument.documentType}
        />
      )}
      {VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES(transactionInstrument) && (
        <TitleReferences //
          name={fieldName('titleReferences')}
        />
      )}
    </div>
  );
}

export default React.memo(TransactionInstrument);
