import * as React from 'react';

import { useStyles } from './styles';

function NotFoundAnimation() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <span>
        <span>4</span>
      </span>
      <span>0</span>
      <span>
        <span>4</span>
      </span>
    </section>
  );
}

export default NotFoundAnimation;
