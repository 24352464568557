import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';
import columnStyles from '../../columnStyles';

const styles = (theme: Theme) => ({
  ...columnStyles(theme),
  ...createStyles({
    grey: {
      opacity: 0.7
    },
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    infoRow: {
      padding: '8px 0px'
    },
    name: {
      fontWeight: 'bold'
    },
    avatar: {
      paddingRight: 10
    },
    showDetailsButtonLink: {
      color: colors.NEUTRAL_500,
      padding: 0
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
