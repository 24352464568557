// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    priorityNotices: {
      paddingBottom: 20
    }
  }),
  {
    name: 'ExtensionOfPriorityNotices'
  }
);
