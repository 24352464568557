import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      padding: 20
    },

    noBullets: {
      listStyleType: 'none',
      padding: 0,
      margin: 0
    },

    iconWarning: {
      color: theme.palette.secondary.main,
      marginRight: 4,
      flexShrink: 0,
      width: 16,
      height: 16,
      margin: 4,
      display: 'inline-block'
    },

    workspaceItem: {
      marginTop: 5
    },

    divider: {
      border: 'none',
      margin: 0,
      background: theme.palette.divider,
      height: 1
    }
  }),
  {
    name: 'DuplicateWorkspaces'
  }
);
