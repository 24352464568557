import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

// tslint:disable:no-string-literal
interface OwnProps {
  className?: string;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class SpinnerLoader extends React.PureComponent<Props> {
  render() {
    const { classes, className } = this.props;

    return (
      <div className={classNames(classes.container, className)}>
        <div className={classes.root}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SpinnerLoader);
