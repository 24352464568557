// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    radioLabels: {
      flexGrow: 'unset'
    }
  }),
  {
    name: 'ExtentOfMoneyDischarged'
  }
);
