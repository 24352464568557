import * as React from 'react';

import { FieldArray, FormikProps } from 'formik';
import _difference from 'lodash-es/difference';
import { Action, Dispatch } from 'redux';
import * as yup from 'yup';
import Divider from '@mui/material/Divider';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, RolePermissionsEnum, UserLevelPermissionEnum, UserRoleEnum } from '@sympli/api-gateway/enums';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import CheckboxGroup from '@sympli/ui-framework/components/form/base-components/checkbox-group';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import Formik from 'src/components/formik';
import CheckboxTreeField from 'src/components/formik/checkbox-tree-field';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import PromptConfirmDialog from 'src/components/prompt-confirm-dialog';
import SettingHeader from 'src/containers/settings/components/setting-header';
import SubheadingWithStatus from 'src/containers/settings/settings/users-setting/components/subheading-with-status';
import SettingsForm from 'src/containers/settings/SettingsForm';
import { DefaultUserSigningRule } from 'src/containers/settings/subscriber-profile/models';
import ProfileContext, { ProfileContextModel } from 'src/containers/shared/auth/profile-security/Profile.context';
import Secured from 'src/containers/shared/auth/profile-security/Secured';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { AUTH_USER_ROLE_OPTIONS } from 'src/models/auth';
import JsonRest from 'src/store/JsonRest';
import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import withRedirectRouter, { RouteRedirectProps } from 'src/utils/withRedirectRouter';
import { actionFetchUserDetails, actionFetchUserPermissions } from '../../../actions/users';
import { showReportPermissionTab } from '../../../users-setting/helpers';
import { USER_PROFILE_ROUTE, USERS_DEFAULT_ROUTE } from '../../detail/constants';
import { JURISDICTIONS_WITH_USER_CERTIFIER_DESIGNATION_ENABLED } from '../../helpers';
import {
  CertifierDesignationEnum,
  ENABLE_SIGNING_LIMIT_OPTIONS,
  PermissionsSpecification,
  RoleEntityModel,
  UpdatePermissionCategories,
  UpdateUserPermissionsApiRequest,
  UserCertifierDesignationsModel,
  UserDetailsPageTabEnum,
  UserGroupAccessModel,
  UserPermissionsFormikValueModel,
  UserSigningRuleModel
} from '../../models';
import { jurisdictionHasUserCertifierDesignationsSelector } from '../../selectors';
import styles, { ClassKeys } from '../../styles';

interface State {
  isBlocking: boolean;
  permissionOptions: Record<string, Array<UpdatePermissionCategories>>;
}
interface OwnProps {
  userId: string;
  initialValues?: UserPermissionsFormikValueModel;
  isEditMode: boolean;
  isLoading: boolean;
  error?: string;

  dispatch: SafeDispatch<Action> | Dispatch<Action>;
  // calculated options
  groupAccessOptions: Array<UserGroupAccessModel>;
  userRoleOptions: Array<RoleEntityModel>;
  isDesignationEditable: boolean;
  biReportingFeatureFlag: boolean;
  isPersonalDetailMode: boolean;

  isSuperAdmin: boolean;
  isCurrentUserActive: boolean;
  defaultUserSigningRules?: DefaultUserSigningRule[];
}

export type FormikPermissionsProps = OwnProps;

type Props = OwnProps & WithStyles<ClassKeys> & RouteRedirectProps;

const fieldName = modelKey<UserPermissionsFormikValueModel>();

interface PermissionSpecificationAuthRoleItemModel extends LookupEnumModel<UserRoleEnum> {
  isEditable: boolean;
}

class FormikPermissions extends React.PureComponent<React.PropsWithChildren<Props>, State> {
  public readonly state: Readonly<State> = {
    isBlocking: true,
    permissionOptions: {}
  };

  private certifierDesignationOptions: Array<LookupEnumModel>;
  private permissionStores: Partial<Record<UserRoleEnum, JsonRest<PermissionSpecificationAuthRoleItemModel[]>>>;

  constructor(props: Props) {
    super(props);

    this.permissionStores = AUTH_USER_ROLE_OPTIONS.reduce((acc, { id }) => {
      acc[id] = new JsonRest<PermissionSpecificationAuthRoleItemModel[]>(`/settings/users/update-permission-specification/${id}`);
      return acc;
    }, {});

    // subscribe to the permission options store, to cache current permission options to display in Task Permissions
    Object.keys(this.permissionStores).forEach(roleKey => {
      const store: JsonRest<PermissionSpecificationAuthRoleItemModel[]> = this.permissionStores[roleKey];
      const unsubscribe = store.subscribe('resolve', options => {
        let newPermissionOptionState: Record<string, UpdatePermissionCategories[]> = { ...this.state.permissionOptions };
        newPermissionOptionState[roleKey] = options as any; // TODO review above typings, it does not seems to be correct!
        this.setState({ permissionOptions: newPermissionOptionState });
        unsubscribe();
      });
    });

    this.certifierDesignationOptions = [
      { id: CertifierDesignationEnum.AustralianLegalPractitioner, name: 'Australian legal practitioner' },
      { id: CertifierDesignationEnum.AuthorisedSignatory, name: 'Authorised signatory' },
      { id: CertifierDesignationEnum.ConveyancingPractice, name: 'Conveyancing practice' },
      { id: CertifierDesignationEnum.LawPractice, name: 'Law practice' },
      { id: CertifierDesignationEnum.LicensedConveyancer, name: 'Licensed conveyancer' }
    ];
  }

  get currentRole() {
    return this.props.initialValues?.role;
  }

  private getInitialValues() {
    const initialValues: UserPermissionsFormikValueModel = {
      role: UserRoleEnum.Standard,
      permissions: [],
      groups: []
    };

    if (this.props.isEditMode) {
      // we are transforming this outside on the page level using selector,
      // otherwise we would have problem with re-running this whenever user switch the tab
      // transform permissions into format that can be used by CheckboxGroup
      // const permissions = this.props.initialValues!.permissions.filter(item => item.isSelected).map(item => item.permissionId);

      return {
        ...initialValues, //
        ...this.props.initialValues
      };
    }

    return initialValues;
  }

  private signingLimitValidation(dependentFieldName: string) {
    return yup.mixed<number>().when(dependentFieldName, {
      is: true,
      then: yup.number().typeError(msg.REQUIRED).required(msg.REQUIRED).min(0.01, msg.VALUE_MUST_BE_MORE_THAN_X('$0')).max(99000000.0, msg.VALUE_MUST_BE_X_OR_LESS('$99 million'))
    });
  }

  private getValidationSchema() {
    const userSigningRuleKey = modelKey<UserSigningRuleModel>();
    return yup.object<UserPermissionsFormikValueModel>({
      role: yup.number().required(msg.REQUIRED),
      permissions: yup.array<UserLevelPermissionEnum>().of(yup.number<UserLevelPermissionEnum>().required(msg.REQUIRED)).required(msg.REQUIRED),
      groups: yup.array<string>().of(yup.string().trim().required(msg.REQUIRED)).required(msg.REQUIRED).min(1, msg.MUST_SELECT_AT_LEAST_X(1, 'group')),
      designation: yup
        .number()
        .nullable(true)
        .default(null)
        .test(
          'designation',
          msg.REQUIRED, //
          function test(this: yup.TestContext<UserPermissionsFormikValueModel>, value: any) {
            const { permissions } = this.options.context!;
            const jurisdictionHasUserCertifierDesignations = jurisdictionHasUserCertifierDesignationsSelector(permissions);
            if (
              JURISDICTIONS_WITH_USER_CERTIFIER_DESIGNATION_ENABLED.some(
                (
                  jurisdiction //
                ) => jurisdictionHasUserCertifierDesignations[jurisdiction]
              )
            ) {
              return Number.isInteger(value) && value >= 0;
            }
            return true;
          }
        ),
      userSigningRule: yup.object<UserSigningRuleModel>({
        hasPaymentSigningLimit: yup.mixed(),
        hasSourceFundSigningLimit: yup.mixed(),
        paymentSigningLimitAmount: this.signingLimitValidation(userSigningRuleKey('hasPaymentSigningLimit')),
        sourceFundSigningLimitAmount: this.signingLimitValidation(userSigningRuleKey('hasSourceFundSigningLimit'))
      })
    });
  }

  private getCurrentPermissionCategories = (role: UserRoleEnum): Array<UpdatePermissionCategories> => {
    return this.state.permissionOptions[role];
  };

  render() {
    const { userId, groupAccessOptions } = this.props;
    const { permissionOptions } = this.state;

    if (this.currentRole === undefined || !(this.currentRole in permissionOptions)) {
      return <BlockLoader />;
    }

    // ! if the group options from redux/backend is NONE, we do prompt
    // do not check initial value because it is a cached value which would cause problem
    const groupAccessNumber = groupAccessOptions.filter(opt => opt.isSelected).length;
    return (
      <>
        <PromptConfirmDialog
          when={this.state.isBlocking && groupAccessNumber === 0}
          title="Assign group to user"
          message="This user does not have permission to access any groups. All users must be granted access to at least 1 group."
          primaryButtonText="Edit permissions"
        />
        <Formik //
          method="put"
          action={`/settings/users/${encodeURIComponent(userId)}/permissions`}
          enableReinitialize={true}
          initialValues={this.getInitialValues()}
          validationSchema={this.getValidationSchema()}
          onPreSubmit={this.handleOnPreSubmit}
          onPostSubmit={this.handleOnPostSubmit}
        >
          {(formikProps: FormikProps<UserPermissionsFormikValueModel>) => this.renderForm(formikProps)}
        </Formik>
      </>
    );
  }

  private renderForm = (formikProps: FormikProps<UserPermissionsFormikValueModel>) => {
    const { error, isLoading, userId } = this.props;
    return (
      <SettingsForm //
        formikProps={formikProps}
        error={error}
        isLoading={isLoading}
        submitButtonWrapper={this.renderSecuredButton}
        onBack={this.handleOnBack}
      >
        <SettingHeader header="Permissions" />
        <SubheadingWithStatus userId={userId} />
        {this.renderFormFields(formikProps)}
      </SettingsForm>
    );
  };

  private renderFormFields(formikProps: FormikProps<UserPermissionsFormikValueModel>) {
    const { values } = formikProps;
    const { userRoleOptions, groupAccessOptions, classes } = this.props;
    return (
      <>
        <FormGroup title="User role">
          <Field
            component={RadioField}
            label="Select user type"
            vertical
            options={userRoleOptions}
            name={fieldName('role')}
            format="number"
            onChange={this.handleOnRoleChange}
            resolveItemDisabled={this.resolveRadioOptionDisabled}
          />
        </FormGroup>
        <FormGroup title="Group access" description="This user will be granted access to all workspaces in the selected groups">
          <Field
            disabled={values.role === UserRoleEnum.SuperAdmin}
            component={CheckboxTreeField}
            label="Select groups"
            name={fieldName('groups')}
            options={groupAccessOptions}
            className={classes.fullWidth}
            resolveItemDisabled={this.resolveCheckBoxDisabled}
            classes={{ tree: classes.treeContainer }}
          />
        </FormGroup>
        <FormGroup
          title="Task permissions"
          description={
            <>
              <span>This user will receive notifications and can complete selected tasks.</span>
              <br />
              <span>Changes to signing permissions will affect new signatures only.</span>
            </>
          }
        >
          <FlexLayout flexDirection="column" fullWidth>
            {this.renderUserPermissions(formikProps)}
          </FlexLayout>
        </FormGroup>
        {this.renderUserCertifierDesignations(formikProps)}
      </>
    );
  }

  private renderUserPermissions = (formikProps: FormikProps<UserPermissionsFormikValueModel>) => {
    const {
      values: { role, permissions },
      setFieldValue
    } = formikProps;
    const { classes } = this.props;
    const currentPermissionCategories: Array<UpdatePermissionCategories> = this.getCurrentPermissionCategories(role as UserRoleEnum);

    if (!currentPermissionCategories) return null;

    return (
      <FieldArray
        name={fieldName('permissions')}
        render={() => {
          return currentPermissionCategories.map((category, i) => {
            // we want to hide the SignDirection permission
            const options = category.permissions.filter(x => x.id !== UserLevelPermissionEnum.SignDirection);
            const availableOptions: UserLevelPermissionEnum[] = options.map(option => option.id);
            const divider = i === 0 ? null : <Divider className={classes.divider} />;
            const label = i === 0 ? 'This user can...' : '';

            if (category.name === 'Sign Financials') {
              return (
                <div key={`permissionCategory-${i}`}>
                  {divider}
                  {this.renderSigningLimitPermissions(formikProps, options)}
                </div>
              );
            }

            return (
              <div key={`permissionCategory-${i}`}>
                {divider}
                <CheckboxGroup
                  options={options}
                  label={label}
                  resolveItemDisabled={this.resolveCheckBoxDisabled}
                  name={fieldName('permissions')}
                  values={permissions}
                  className={classes.checkboxGroups}
                  vertical
                  onChange={(e, resolvedValues) => {
                    //resolvedValues contains only this category's values
                    //values.permissions include other categories' values
                    const prevMinusThisGroup = _difference(permissions, availableOptions);
                    //change field value to: this group's resolved values + any other group's
                    setFieldValue(fieldName('permissions'), [...resolvedValues, ...prevMinusThisGroup]);
                  }}
                  format="number"
                />
              </div>
            );
          });
        }}
      />
    );
  };

  private renderSecuredButton = (children: JSX.Element) => {
    const securedButton = <Secured requiredRolePermissions={RolePermissionsEnum.ManageUserPermissions}>{children}</Secured>;

    if (this.currentRole) {
      // if role of currently logged in user is higher or equal to the role of the user being edited, we display securedButton
      return (
        <ProfileContext.Consumer>
          {(
            profile: ProfileContextModel //
          ) => profile!.role <= (this.currentRole as number)! && securedButton}
        </ProfileContext.Consumer>
      );
    }

    return securedButton;
  };

  private renderUserCertifierDesignations(formikProps: FormikProps<UserPermissionsFormikValueModel>) {
    const { isDesignationEditable } = this.props;
    const { permissions } = formikProps.values;
    const jurisdictionHasUserCertifierDesignations = jurisdictionHasUserCertifierDesignationsSelector(permissions);
    // If there is no any User Certifier Designations
    if (Object.keys(jurisdictionHasUserCertifierDesignations).length === 0) {
      return null;
    }

    return (
      <FormGroup
        title="User certifier designation"
        description={
          <span>The certifier designation is required when a user is signing documents in VIC and WA. It should be set to the role, designation or job title of this user.</span>
        }
      >
        {JURISDICTIONS_WITH_USER_CERTIFIER_DESIGNATION_ENABLED.some(
          (
            jurisdiction //
          ) => jurisdictionHasUserCertifierDesignations[jurisdiction]
        ) && (
          <Field
            component={SelectField}
            options={this.certifierDesignationOptions}
            label="Select signer role for documents"
            name={fieldName('designation')}
            placeholder="Please select"
            disabled={!isDesignationEditable}
          />
        )}
      </FormGroup>
    );
  }

  private renderSigningLimitPermissions(formikProps: FormikProps<UserPermissionsFormikValueModel>, options: PermissionsSpecification[]) {
    const { classes, defaultUserSigningRules } = this.props;
    const {
      values: { permissions, userSigningRule, role },
      setFieldValue
    } = formikProps;
    const defaultRule = defaultUserSigningRules?.find(x => x.role.valueOf() === role.valueOf());
    const userSigningRuleFieldName = createModelKeyAppender<UserSigningRuleModel>('userSigningRule');
    return (
      <>
        {this.getSigningLimitsCheckbox(
          options[0],
          formikProps,
          userSigningRuleFieldName('paymentSigningLimitAmount'),
          userSigningRuleFieldName('hasPaymentSigningLimit'),
          defaultRule?.paymentSigningLimitAmount,
          defaultRule?.hasPaymentSigningLimit
        )}
        {Boolean(~permissions.indexOf(options[0].id)) && (
          <FlexLayout flexDirection="column" className={classes.indentedField}>
            <Field
              name={userSigningRuleFieldName('hasPaymentSigningLimit')}
              component={RadioField}
              options={ENABLE_SIGNING_LIMIT_OPTIONS}
              format="boolean"
              label="Set Payments signing limit"
              className={classes.signingLimitInput}
              classes={{ fieldLabel: classes.inputFieldLabel }}
              disabled={!options[0].isEditable}
              onChange={(_, resolvedValue) => {
                if (resolvedValue && defaultRule?.paymentSigningLimitAmount) {
                  setFieldValue(userSigningRuleFieldName('paymentSigningLimitAmount'), defaultRule.paymentSigningLimitAmount);
                } else {
                  setFieldValue(userSigningRuleFieldName('paymentSigningLimitAmount'), null);
                }
              }}
            />
            {userSigningRule?.hasPaymentSigningLimit && this.getSigningLimitField(userSigningRuleFieldName('paymentSigningLimitAmount'), options[0].isEditable)}
          </FlexLayout>
        )}
        {this.getSigningLimitsCheckbox(
          options[1],
          formikProps,
          userSigningRuleFieldName('sourceFundSigningLimitAmount'),
          userSigningRuleFieldName('hasSourceFundSigningLimit'),
          defaultRule?.sourceFundSigningLimitAmount,
          defaultRule?.hasSourceFundSigningLimit
        )}
        {Boolean(~permissions.indexOf(options[1].id)) && (
          <FlexLayout flexDirection="column" className={classes.indentedField}>
            <Field
              name={userSigningRuleFieldName('hasSourceFundSigningLimit')}
              component={RadioField}
              options={ENABLE_SIGNING_LIMIT_OPTIONS}
              format="boolean"
              label="Set Source Funds signing limit"
              className={classes.signingLimitInput}
              classes={{ fieldLabel: classes.inputFieldLabel }}
              disabled={!options[1].isEditable}
              onChange={(_, resolvedValue) => {
                if (resolvedValue && defaultRule?.sourceFundSigningLimitAmount) {
                  setFieldValue(userSigningRuleFieldName('sourceFundSigningLimitAmount'), defaultRule.sourceFundSigningLimitAmount);
                } else {
                  setFieldValue(userSigningRuleFieldName('sourceFundSigningLimitAmount'), null);
                }
              }}
            />
            {userSigningRule?.hasSourceFundSigningLimit && this.getSigningLimitField(userSigningRuleFieldName('sourceFundSigningLimitAmount'), options[1].isEditable)}
          </FlexLayout>
        )}
      </>
    );
  }

  private getSigningLimitsCheckbox(
    option: PermissionsSpecification,
    formikProps: FormikProps<UserPermissionsFormikValueModel>,
    amountFieldName: string,
    hasLimitFieldName: string,
    defaultAmountValue?: number | null,
    defaultHasLimitValue?: boolean
  ) {
    const {
      values: { permissions },
      setFieldValue
    } = formikProps;
    const { classes } = this.props;
    return (
      <Checkbox //
        key={option.id}
        name={`${fieldName('permissions')}-${option.id}`}
        label={option.name}
        checked={Boolean(~permissions.indexOf(option.id))}
        onChange={(e, resolvedValue) => {
          if (e.target.checked) {
            setFieldValue(fieldName('permissions'), [...permissions, resolvedValue]);
            setFieldValue(hasLimitFieldName, Boolean(defaultHasLimitValue));
            setFieldValue(amountFieldName, defaultAmountValue);
          } else {
            setFieldValue(
              fieldName('permissions'),
              permissions.filter(x => x !== resolvedValue)
            );
          }
        }}
        className={classes.checkboxGroups}
        disabled={!option.isEditable}
        vertical
        value={option.id}
        format="number"
      />
    );
  }

  private getSigningLimitField(fieldName: string, isEditable: boolean) {
    const { classes } = this.props;
    return (
      <Field
        name={fieldName}
        component={CurrencyInputField}
        label="Enter signing limit ($)"
        placeholder="Enter amount"
        format="number"
        className={classes.signingLimitInput}
        classes={{ inputLabel: classes.inputFieldLabel }}
        disabled={!isEditable}
      />
    );
  }

  private handleOnRoleChange = (event: React.ChangeEvent<HTMLInputElement>, ...args) => {
    const [resolvedValue, formikProps] = args as [number, FormikProps<UserPermissionsFormikValueModel>];
    const { defaultUserSigningRules } = this.props;
    const defaultRule = defaultUserSigningRules?.find(x => x.role === resolvedValue);
    switch (resolvedValue) {
      case UserRoleEnum.SuperAdmin: {
        // disabled all group access and check every group
        formikProps.setFieldValue(
          fieldName('groups'),
          this.props.groupAccessOptions.map(({ id }) => id)
        );
        break;
      }
      default: {
        if (formikProps.values.role === UserRoleEnum.SuperAdmin) {
          // if switch role back from super admin, reset/initialise the group access checkbox
          formikProps.setFieldValue(fieldName('groups'), this.props.initialValues!.groups);
        }
      }
    }

    // set signing limits to default values for the selected role
    formikProps.setFieldValue(fieldName('userSigningRule'), {
      hasPaymentSigningLimit: Boolean(defaultRule?.hasPaymentSigningLimit),
      paymentSigningLimitAmount: defaultRule?.paymentSigningLimitAmount,
      hasSourceFundSigningLimit: Boolean(defaultRule?.hasSourceFundSigningLimit),
      sourceFundSigningLimitAmount: defaultRule?.sourceFundSigningLimitAmount
    });
  };

  private resolveCheckBoxDisabled = item => {
    return !item.isEditable;
  };

  private resolveRadioOptionDisabled = item => {
    return !item.isEditable;
  };

  private handleOnPreSubmit = (values: UserPermissionsFormikValueModel): UpdateUserPermissionsApiRequest => {
    const { userId } = this.props;
    // filter the permission that is not in the current permission options
    const currentPermissionOptions = this.getCurrentPermissionCategories(values.role as UserRoleEnum).flatMap(({ permissions }) => permissions);
    const permissions = values.permissions.filter(id => Boolean(currentPermissionOptions.some(option => option.id === id)));
    const jurisdictionHasUserCertifierDesignations = jurisdictionHasUserCertifierDesignationsSelector(permissions);
    const userSigningRule = values.userSigningRule;

    const { designation, ...restValues } = values;

    const userCertifierDesignations: UserCertifierDesignationsModel[] = Object.keys(jurisdictionHasUserCertifierDesignations) //
      .map(jurisdiction => {
        const jurisdictionEnumValue = Number(jurisdiction);
        return {
          jurisdiction: jurisdictionEnumValue,
          certifierDesignation: designation as CertifierDesignationEnum,
          userId
        };
      });
    if (permissions.includes(UserLevelPermissionEnum.SignDocumentQLD)) {
      userCertifierDesignations.push({
        jurisdiction: JurisdictionsEnum.QLD,
        certifierDesignation: CertifierDesignationEnum.AustralianLegalPractitioner,
        userId
      });
    }

    //reset signing limit fields if user doesn't have permission to sign payments/source funds or doesn't have a signing limit
    if (userSigningRule && (!userSigningRule.hasPaymentSigningLimit || permissions.indexOf(UserLevelPermissionEnum.SignPayments) < 0)) {
      userSigningRule.hasPaymentSigningLimit = false;
      userSigningRule.paymentSigningLimitAmount = undefined;
    }
    if (userSigningRule && (!userSigningRule.hasSourceFundSigningLimit || permissions.indexOf(UserLevelPermissionEnum.SignSourceFunds) < 0)) {
      userSigningRule.hasSourceFundSigningLimit = false;
      userSigningRule.sourceFundSigningLimitAmount = undefined;
    }

    return { ...restValues, permissions, userCertifierDesignations, userSigningRule };
  };

  private handleOnPostSubmit = ({ error, formValues }: FormikPostSubmitArgs<UserPermissionsFormikValueModel>) => {
    if (!error) {
      const { dispatch, userId, biReportingFeatureFlag, isCurrentUserActive, navigate, isSuperAdmin, isPersonalDetailMode } = this.props;
      this.setState({ isBlocking: false });

      dispatch(actionFetchUserPermissions.request(userId));

      if (isPersonalDetailMode) {
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            message: 'User permissions updated successfully',
            variant: 'new-success'
          })
        );
      } else {
        // when successfully create a new user's permission, we should refetch the user details
        dispatch(actionFetchUserDetails.request(userId));

        if (!isSuperAdmin) {
          this.handleOnBack();
          return;
        }

        // when a user does have report permission we need to navigate to the reports permissions page.
        const isReportPermissionsEnabled = showReportPermissionTab({
          isBiReportingEnabled: biReportingFeatureFlag,
          isProfileUserSuperAdmin: isSuperAdmin,
          isCurrentUserActive
        });
        isReportPermissionsEnabled ? navigate(`/settings/users/${encodeURIComponent(userId)}/${UserDetailsPageTabEnum.ReportsPermissions}`) : this.handleOnBack();
      }
    }
  };

  private handleOnBack = () => {
    this.redirectBack();
  };

  private redirectBack() {
    this.props.navigate(this.props.isPersonalDetailMode ? USER_PROFILE_ROUTE : USERS_DEFAULT_ROUTE);
  }
}

export default withStyles(styles)(withRedirectRouter(FormikPermissions));
