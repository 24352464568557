import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { PartLandAffectedWithDescriptionsModel, TitleLabel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { RootFormContextProvider } from '@sympli-mfe/document-forms-framework/providers/root-form-context';

import RootForm, { Context } from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/RootForm';
import { QLD_PN_CONFIG, QLD_PN_PARTY_FORM_CONFIG } from './config';
import converter from './conversion';
import { ApiPriorityNoticeModel, PriorityNoticeModel } from './models';
import validationSchema from './validationSchema';

export default function DocumentForm(props: DocumentFormProps<PriorityNoticeModel, ApiPriorityNoticeModel>): JSX.Element {
  return (
    <DndContextProvider>
      <RootFormContextProvider<Context<PartLandAffectedWithDescriptionsModel>>
        partyFormConfig={QLD_PN_PARTY_FORM_CONFIG}
        priorityNoticeConfig={QLD_PN_CONFIG}
        TitleLabelComponent={TitleLabel}
      >
        <DocumentFormWrapper //
          {...props}
          converter={converter}
          validationSchema={validationSchema}
          RootForm={RootForm}
        />
      </RootFormContextProvider>
    </DndContextProvider>
  );
}
