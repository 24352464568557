import React, { useMemo } from 'react';

import { useField } from 'formik';

import Consideration, { ConsiderationModel, ConsiderationTypeEnum, VIC_CONSIDERATION_TYPE_OPTIONS } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

interface Props {
  name: string;
  onConsiderationTypeChange: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: ConsiderationTypeEnum) => void;
  relinquishingProprietorCount: number;
}

function SectionConsideration({ name, onConsiderationTypeChange, relinquishingProprietorCount }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<ConsiderationModel>(name);
  const [, { value: considerationType }] = useField<ConsiderationTypeEnum>(fieldName('type'));

  /**
   * If only one transferor exists, "change in manner of holding" option is not available.
   * (If it is already selected and then, a validation error should be displayed prompting the user to change their selection.)
   */
  const filteredConsiderationTypeLookup = useMemo(
    () =>
      relinquishingProprietorCount > 1 || considerationType === ConsiderationTypeEnum.ChangeInTheMannerOfHolding
        ? VIC_CONSIDERATION_TYPE_OPTIONS
        : VIC_CONSIDERATION_TYPE_OPTIONS.filter(item => item.id !== ConsiderationTypeEnum.ChangeInTheMannerOfHolding),
    [relinquishingProprietorCount, considerationType]
  );

  return (
    <Consideration //
      name={name}
      jurisdiction={JurisdictionsEnum.VIC}
      considerationTypeOptions={filteredConsiderationTypeLookup}
      onChange={onConsiderationTypeChange}
    />
  );
}

export default React.memo(SectionConsideration);
