/* eslint-disable no-console */
import * as React from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';
import pluralize from 'pluralize';
import { Virtuoso } from 'react-virtuoso';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { colors } from 'src/theme';
import {
  useCheckboxOverridesClasses,
  useFormControlStyleOverrides,
  useInputLabelStyleOverrides,
  useListSubheaderOverridesClasses,
  useMenuItemOverridesClasses,
  useMenuOverridesClasses,
  usePaperOverridesClasses,
  useSelectOverridesClasses,
  useTextFieldOverridesClasses,
  useVirtuosoOverridesClasses
} from './styles';

export interface ResolveDisplayedValueProps<T extends string | number, Value> {
  value: Value;
  options: LookupItemModel<T>[];
  groups: GroupedOptions<T>[];
  placeholder: string | JSX.Element;
}

const DEFAULT_ARR = [];

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

interface SingleMode<T extends string | number> {
  mode: 'single';
  value: T | null;
  onChange?(event: SelectChangeEvent<any> | React.MouseEvent<HTMLElement>, resolvedValue: T | null): void;
  resolveDisplayedValue?(props: ResolveDisplayedValueProps<T, T | null>): string | JSX.Element;
  resolveDropdownItemLabel?(item: LookupItemModel<T>): string | JSX.Element;
}

interface MultiMode<T extends string | number> {
  mode: 'multiple';
  value: T[];
  onChange?(event: SelectChangeEvent<any> | React.MouseEvent<HTMLElement>, resolvedValue: T[]): void;
  resolveDisplayedValue?(props: ResolveDisplayedValueProps<T, T[]>): string | JSX.Element;
  resolveDropdownItemLabel?(item: LookupItemModel<T>): string | JSX.Element;
}

export interface GroupedOptions<T extends string | number> {
  title: string;
  options: LookupItemModel<T>[];
}

interface CommonProps<T extends string | number> {
  label: string;
  options?: LookupItemModel<T>[];
  additionalOptionsOnTop?: LookupItemModel<T>[];
  groups?: GroupedOptions<T>[];
  placeholder?: string | JSX.Element;
  searchComponentProps?: {
    label: string;

    unit?: string;
  };
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  name?: string;
  IconComponent?: React.ElementType;
  defaultOpen?: boolean; // this let parent component to take control
}
export type MultipleSelectCheckboxProps<T extends string | number> = XOR<SingleMode<T>, MultiMode<T>> & CommonProps<T>;

function defaultResolveDisplayedValue<T extends string | number>({
  //
  value = [],
  options,
  groups,
  placeholder = 'Select'
}: {
  value: T | T[];
  options: LookupItemModel<T>[];
  groups: GroupedOptions<T>[];
  placeholder?: string | JSX.Element;
}) {
  const selected: T[] = Array.isArray(value) ? value : (value as unknown as string) === '' || value === null ? [] : [value];

  if (selected.length === 0) return placeholder;
  return `${selected.length} Selected`;

  // return selected.length > 1 ? `${selected.length} selected` : options.find(d => d.id === selected[0])?.name || '';
}

function defaultResolveItemLabel(item: LookupItemModel<any>) {
  return item.name;
}

const SelectArrowIconComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 15.4125C11.7825 15.4125 11.5725 15.3225 11.415 15.1725L6.24 9.99751C5.9175 9.67501 5.9175 9.15001 6.24 8.82751C6.5625 8.50501 7.0875 8.50501 7.41 8.82751L12 13.4175L16.59 8.82751C16.9125 8.50501 17.4375 8.50501 17.7525 8.82751C18.075 9.15001 18.075 9.67501 17.7525 9.99751L12.5775 15.1725C12.42 15.33 12.21 15.4125 11.9925 15.4125H12Z"
      fill="#292F36"
    />
  </svg>
);

const CheckedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none" {...props}>
    <path
      d="M4.04709 9.19565C3.91516 9.19604 3.78471 9.16505 3.66458 9.10477C3.54445 9.04449 3.43746 8.95634 3.35089 8.8463L0.57691 5.3419C0.496611 5.24097 0.435116 5.12352 0.395949 4.99628C0.356781 4.86904 0.34071 4.7345 0.348656 4.60038C0.356602 4.46626 0.388409 4.33519 0.442254 4.21469C0.4961 4.09418 0.570928 3.98659 0.662449 3.8981C0.75397 3.80961 0.860387 3.74194 0.975605 3.69898C1.09082 3.65602 1.21258 3.63861 1.33389 3.64775C1.4552 3.65688 1.5737 3.69238 1.68258 3.75222C1.79146 3.81206 1.88859 3.89505 1.96841 3.99644L4.13965 6.73927L9.91741 1.26558C10.0095 1.17822 10.1164 1.11177 10.2317 1.07003C10.3471 1.0283 10.4688 1.01209 10.5898 1.02233C10.7108 1.03257 10.8289 1.06906 10.9372 1.12972C11.0454 1.19039 11.1418 1.27403 11.2209 1.37587C11.2999 1.47772 11.36 1.59577 11.3978 1.7233C11.4355 1.85082 11.4502 1.98532 11.4409 2.11911C11.4317 2.2529 11.3987 2.38336 11.3438 2.50305C11.2889 2.62273 11.2132 2.7293 11.1211 2.81666L4.64848 8.94911C4.48132 9.10847 4.26784 9.19599 4.04709 9.19565Z"
      fill="#292F36"
    />
  </svg>
);

// https://mui.com/material-ui/react-select/#grouping
function OptionsGroupHeader(
  props: ListSubheaderProps & {
    //
    muiSkipListHighlight: boolean;
  }
) {
  const { muiSkipListHighlight, style, ...other } = props;
  return (
    <ListSubheader //
      {...other}
      style={{
        background: 'inherit',
        color: colors.NEUTRAL_1000,
        paddingLeft: 13,
        paddingTop: 24,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.5px',
        ...style
      }}
    />
  );
}

type MenuItemType<T extends string | number> =
  | {
      type: 'option';
      item: LookupItemModel<T>;
    }
  | {
      type: 'group';
      title: string;
    };

type SelectedMenuItemType<T extends string | number> = {
  type: 'option';
  item: LookupItemModel<T>;
};

function resolveMenuItems<T extends string | number>({
  //
  options,
  groups
}: {
  options: LookupItemModel<T>[];
  groups: GroupedOptions<T>[];
}): MenuItemType<T>[] {
  let menuItems: MenuItemType<T>[] = options.map(item => ({ type: 'option', item }));

  if (groups.length) {
    groups.reduce((acc: any[], g: GroupedOptions<T>) => {
      acc.push({
        title: g.title,
        type: 'group'
      });

      g.options.forEach(item => acc.push({ type: 'option', item }));
      return acc;
    }, menuItems);
  }
  return menuItems;
}

function resolveSelectedMenuItems<T extends string | number>(menuItems: MenuItemType<T>[], value: T[]) {
  const newMenuItems: MenuItemType<T>[] = [];
  const selectedItems: SelectedMenuItemType<T>[] = [];
  menuItems.forEach(x => {
    if (x.type === 'group') {
      newMenuItems.push(x);
    } else {
      if (value.includes(x.item.id)) {
        selectedItems.push(x);
      } else {
        newMenuItems.push(x);
      }
    }
  });

  selectedItems.sort((a, b) => {
    if (a.item.id < b.item.id) {
      return -1;
    }
    if (a.item.id > b.item.id) {
      return 1;
    }
    return 0;
  });

  return [...selectedItems, ...newMenuItems];
}

function includeIgnoreCases(name: string, newSearchValue: string) {
  return name.toLowerCase().includes(newSearchValue.toLowerCase());
}

function MultipleSelectCheckbox<T extends string | number>({
  //

  label,
  options = DEFAULT_ARR,
  groups = DEFAULT_ARR,
  value,
  mode,
  onChange,
  placeholder = 'Select',
  resolveDisplayedValue = defaultResolveDisplayedValue,
  resolveDropdownItemLabel = defaultResolveItemLabel,
  searchComponentProps,
  fullWidth,
  className,
  style,
  disabled,
  name,
  IconComponent = SelectArrowIconComponent,
  additionalOptionsOnTop,
  defaultOpen
}: MultipleSelectCheckboxProps<T>) {
  const [scrolled, setScrolled] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(Boolean(defaultOpen));
  const formControlOverridesClasses = useFormControlStyleOverrides();
  const inputLabelOverridesClasses = useInputLabelStyleOverrides();
  const selectOverridesClasses = useSelectOverridesClasses();
  const paperOverridesClasses = usePaperOverridesClasses(Boolean(searchComponentProps))();
  const menuOverridesClasses = useMenuOverridesClasses(Boolean(searchComponentProps))();
  const menuItemOverridesClasses = useMenuItemOverridesClasses();
  const checkboxOverridesClasses = useCheckboxOverridesClasses();
  const listSubheaderOverridesClasses = useListSubheaderOverridesClasses();
  const textFieldOverridesClasses = useTextFieldOverridesClasses();
  const virtuosoOverridesClasses = useVirtuosoOverridesClasses();

  const labelIdRef = React.useRef(_uniqueId('msch-label-'));

  const selectedIds: T[] = (Array.isArray(value) ? value : (value as unknown as string) === '' || value === null ? [] : [value]) as T[];

  // for search input filter list we will need to handle the options based on input change
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [newOptions, setNewOptions] = React.useState(additionalOptionsOnTop ? additionalOptionsOnTop.concat(options) : options);
  // if searchComponentProps we will need to use the value in the state
  const menuItems = resolveMenuItems<T>({ options: !searchComponentProps ? options : newOptions, groups });
  const totalCount = newOptions.filter(({ name }) => includeIgnoreCases(name, searchValue)).length;

  // if option changes then we will need to update the original list
  React.useEffect(() => {
    const newOptions = additionalOptionsOnTop ? additionalOptionsOnTop.concat(options) : options;
    setNewOptions(newOptions);
  }, [options, additionalOptionsOnTop, setNewOptions]);

  // This is not used for Search which we are using react-virtuoso
  const handleOnChange = (event: SelectChangeEvent<any>) => {
    !searchComponentProps && onChange?.(event, event.target.value);
  };

  const handleOnSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value;
    const searchableOptions = additionalOptionsOnTop ? additionalOptionsOnTop.concat(options) : options;
    const results = searchableOptions.filter(({ name, id }) => includeIgnoreCases(name, newSearchValue) || selectedIds.includes(id));
    setNewOptions(results);

    setSearchValue(newSearchValue);
  };

  const getMenuItems = React.useCallback(() => {
    if (searchComponentProps) {
      return resolveSelectedMenuItems(menuItems, value as T[]);
    } else {
      return menuItems;
    }
  }, [menuItems, searchComponentProps, value]);

  const renderMenuItems = () => {
    return getMenuItems().map((m, i) => {
      if (m.type === 'group') {
        return (
          <OptionsGroupHeader //
            key={m.title}
            muiSkipListHighlight
            style={i === 0 ? { paddingTop: 8 } : undefined}
          >
            {m.title}
          </OptionsGroupHeader>
        );
      }

      m = m as {
        type: 'option';
        item: LookupItemModel<T>;
      };

      const handleMenuItemOnClick = (event: React.MouseEvent<HTMLElement>) => {
        event.target['name'] = name;

        if (m.type === 'option') {
          if (selectedIds.includes(m.item.id)) {
            const newSelectedValues = selectedIds.filter(x => m.type === 'option' && x !== m.item.id);

            const results = newOptions.filter(({ name, id }) => includeIgnoreCases(name, searchValue) || newSelectedValues.includes(id));
            setNewOptions(results);

            onChange?.(event, newSelectedValues as any);
          } else {
            const newSelectedValues = [...selectedIds, m.item.id];
            const results = newOptions.filter(({ name, id }) => includeIgnoreCases(name, searchValue) || newSelectedValues.includes(id));

            setNewOptions(results);

            onChange?.(event, newSelectedValues as any);
          }
        }
      };
      return (
        <MenuItem
          //
          key={`menu-${m.item.id}-${i}`}
          value={m.item.id as any}
          classes={menuItemOverridesClasses}
          onClick={searchComponentProps ? handleMenuItemOnClick : undefined}
        >
          {mode === 'multiple' ? (
            <div>
              <Checkbox //
                checked={selectedIds.includes(m.item.id)}
                classes={checkboxOverridesClasses}
                icon={<div className="icon" />}
                checkedIcon={<CheckedIcon className="icon" />}
              />
            </div>
          ) : null}
          <div className="dropdownItemLabel">{resolveDropdownItemLabel(m.item)}</div>
        </MenuItem>
      );
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl //
      disabled={disabled}
      data-name={name}
      className={classNames(!import.meta.env.PROD && 'devHelper', className)}
      classes={formControlOverridesClasses}
      style={style}
      variant="outlined"
      fullWidth={fullWidth}
      sx={{
        width: Array.isArray(value) && value.length >= 10 ? 125 : 120
      }}
    >
      <InputLabel //
        id={labelIdRef.current}
        shrink={true}
        classes={inputLabelOverridesClasses}
      >
        {label}
      </InputLabel>
      <Select //
        onClose={() => {
          setScrolled(false);
          setOpen(false);
        }}
        onOpen={handleOpen}
        open={open}
        name={name}
        classes={selectOverridesClasses}
        labelId={labelIdRef.current}
        variant="outlined"
        displayEmpty
        multiple={mode === 'multiple'}
        value={value === null ? '' : value}
        onChange={handleOnChange}
        IconComponent={IconComponent}
        renderValue={(value: any) => {
          const args = {
            value,
            options,
            groups,
            placeholder
          };
          return resolveDisplayedValue(args);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          autoFocus: !searchComponentProps,
          classes: menuOverridesClasses,

          PaperProps: {
            classes: paperOverridesClasses
          }
        }}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        {searchComponentProps && (
          <ListSubheader //
            classes={listSubheaderOverridesClasses}
          >
            <TextField //
              fullWidth
              variant="outlined"
              classes={textFieldOverridesClasses}
              // Autofocus on textfield
              autoFocus
              label={searchComponentProps.label}
              // InputProps={{
              //   endAdornment: searchComponentProps.isLoading ? (
              //     <InputAdornment position="end">
              //       <CircularProgress size={24} />
              //     </InputAdornment>
              //   ) : undefined
              // }}
              // defaultValue=""
              value={searchValue}
              onChange={handleOnSearchInputChange}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  // Prevents auto-selecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
            {Number.isInteger(totalCount) ? (
              totalCount! > 0 ? (
                <ListItemText //
                  className="totalCount"
                  primary={
                    <>
                      <strong>{totalCount}</strong> {pluralize(searchComponentProps.unit ?? 'Result', totalCount, false)} found
                    </>
                  }
                />
              ) : (
                <ListItemText //
                  className="zero"
                  primary={
                    <>
                      <strong>No</strong> {pluralize(searchComponentProps.unit ?? 'Result')} found
                    </>
                  }
                />
              )
            ) : null}
          </ListSubheader>
        )}

        {searchComponentProps && menuItems.length > 15 ? (
          // Only render Virtuoso when there are more than 15 items to avoid edge case bugs
          <Virtuoso
            // https://tickleme.atlassian.net/browse/WEB-35292 fix the bug of Virtuoso
            isScrolling={(isScrolling: boolean) => {
              if (isScrolling && !scrolled) {
                setNewOptions([...newOptions]);
                setScrolled(true);
              }
            }}
            style={{ height: 400 }}
            className={virtuosoOverridesClasses.root}
            data={getMenuItems()}
            itemContent={(i, m) => {
              if (m.type === 'group') {
                return (
                  <OptionsGroupHeader //
                    key={m.title}
                    muiSkipListHighlight
                    style={i === 0 ? { paddingTop: 8 } : undefined}
                  >
                    {m.title}
                  </OptionsGroupHeader>
                );
              }

              m = m as {
                type: 'option';
                item: LookupItemModel<T>;
              };

              const handleMenuItemOnClick = (event: React.MouseEvent<HTMLElement>) => {
                event.target['name'] = name;

                if (m.type === 'option') {
                  if (selectedIds.includes(m.item.id)) {
                    const newSelectedValues = selectedIds.filter(x => m.type === 'option' && x !== m.item.id);

                    const results = newOptions.filter(({ name, id }) => includeIgnoreCases(name, searchValue) || newSelectedValues.includes(id));
                    setNewOptions(results);

                    onChange?.(event, newSelectedValues as any);
                  } else {
                    const newSelectedValues = [...selectedIds, m.item.id];
                    const results = newOptions.filter(({ name, id }) => includeIgnoreCases(name, searchValue) || newSelectedValues.includes(id));

                    setNewOptions(results);

                    onChange?.(event, newSelectedValues as any);
                  }
                }
              };

              return (
                <MenuItem
                  //
                  key={`menu-${m.item.id}-${i}`}
                  value={m.item.id as any}
                  classes={menuItemOverridesClasses}
                  onClick={handleMenuItemOnClick}
                >
                  {mode === 'multiple' ? (
                    <div>
                      <Checkbox //
                        checked={selectedIds.includes(m.item.id)}
                        classes={checkboxOverridesClasses}
                        icon={<div className="icon" />}
                        checkedIcon={<CheckedIcon className="icon" />}
                      />
                    </div>
                  ) : null}
                  <div>{resolveDropdownItemLabel(m.item)}</div>
                </MenuItem>
              );
            }}
          />
        ) : searchComponentProps ? (
          <div style={{ height: 400, overflow: 'scroll' }} className={virtuosoOverridesClasses.root}>
            {renderMenuItems()}
          </div>
        ) : (
          renderMenuItems()
        )}
      </Select>
    </FormControl>
  );
}

export default React.memo(MultipleSelectCheckbox);
