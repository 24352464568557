import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { AssignmentTypeEnum, DashboardTypeEnum } from 'src/containers/dashboard/shared/models';
import { MyTaskEnum, WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import { AggregatorComponentBaseProps, AggregatorNameEnum, AggregatorQuery, StandaloneDashboardAggregatorApiRequest } from '../models';
import { useStyles } from './styles';

const payloadResolver = (assignmentType: AssignmentTypeEnum): StandaloneDashboardAggregatorApiRequest => {
  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.readyToSign,
      workspaceStatus: [
        //
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors
      ],
      myTasks: [
        //
        MyTaskEnum.SignDocuments
      ]
    }
  ];

  const payload: StandaloneDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

function ReadyToSignCard(props: AggregatorComponentBaseProps) {
  const classes = useStyles();

  return (
    <AggregatorCardWrapper //
      payloadResolver={payloadResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <AggregatorCard
          title=""
          cardType={DashboardTypeEnum.Standalone}
          isLoading={isLoading}
          onActionClick={onActionClick}
          contentStyle={classes.cardToday}
          statusStyle={classes.cardStatusToday}
          actionStyle={classes.cardToday}
          isFetchingGridData={isFetchingGridData}
          items={[
            {
              id: AggregatorNameEnum.readyToSign,
              content,
              status: 'Ready to Sign',
              actionText: 'Show me'
            }
          ]}
        />
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(ReadyToSignCard);
