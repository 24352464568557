import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    listItem: {},
    lodgementStatusCaseDesc: {
      marginLeft: 10,
      fontWeight: 'normal'
    },
    outerGutters: {
      marginLeft: -16,
      marginRight: -16,

      '& $listItem': {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    selected: {
      background: theme.palette.common.white
    },
    accountName: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      width: 'calc(100% - 46px)' // The avatar size is 30px and 16px from the right
    }
  });

export type ClassKeys = typeof styles;

export default styles;
