import { LimitLookupEnumModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { AttachmentTypeEnum, NAME_SUFFIX_LOOKUP_OPTIONS, NameSuffixEnum } from '@sympli-mfe/enums-2-21/nsw';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, EvidenceTypeEnum } from '../../components/deceased-tenancy-detail/components/evidence/enums';
import { DECEASED_TENANCY_PARTY_CAPACITY_LOOKUP_OPTIONS, DeceasedTenancyPartyCapacityEnum } from '../../components/deceased-tenancy-detail/enums';

export const APPLICANT_CAPACITY_LOOKUP_OPTIONS: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[] = restrictedLookupOptions(DECEASED_TENANCY_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    DeceasedTenancyPartyCapacityEnum.Trustee,
    DeceasedTenancyPartyCapacityEnum.Administrator,
    DeceasedTenancyPartyCapacityEnum.Executor,
    DeceasedTenancyPartyCapacityEnum.ExecutorByRepresentation
  ]
});

export const GRANTEE_CAPACITY_LOOKUP_OPTIONS: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[] = restrictedLookupOptions(DECEASED_TENANCY_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    DeceasedTenancyPartyCapacityEnum.Trustee,
    DeceasedTenancyPartyCapacityEnum.Administrator,
    DeceasedTenancyPartyCapacityEnum.Executor,
    DeceasedTenancyPartyCapacityEnum.ExecutorByRepresentation
  ]
});

export const TAE_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS = restrictedLookupOptions(EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, {
  whitelist: [
    EvidenceTypeEnum.CertificateUnderNSWTrusteeAndGuardianAct,
    EvidenceTypeEnum.DeathCertificate,
    EvidenceTypeEnum.DeedOfAppointment,
    EvidenceTypeEnum.LettersOfAdministration,
    EvidenceTypeEnum.Probate,
    EvidenceTypeEnum.StatutoryDeclaration
  ]
});

export const ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS: LimitLookupEnumModel<AttachmentTypeEnum>[] = [
  { id: AttachmentTypeEnum.MinistersConsent, name: AttachmentTypeEnum.MinistersConsent, limit: 1 },
  { id: AttachmentTypeEnum.CaveatorsConsent, name: AttachmentTypeEnum.CaveatorsConsent }
];

export const TAE_NAME_SUFFIX_LOOKUP_OPTIONS: LookupItemModel<NameSuffixEnum>[] = restrictedLookupOptions(NAME_SUFFIX_LOOKUP_OPTIONS, {
  whitelist: [NameSuffixEnum.Junior, NameSuffixEnum.Senior]
});
