import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { VicPartyForm_2_25 as PartyForm } from '@sympli-mfe/document-components/party-form/vic/2-25';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { VIC_WOC_PARTY_FORM_CONFIG } from '../../config';
import { CaveatorPartyModel, WithdrawalOfCaveat2_25_1Model } from '../../models';

type FormValues = WithdrawalOfCaveat2_25_1Model;

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function Caveator({ name, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<FormValues>();
  const value: CaveatorPartyModel = _get(values, name);

  const partyBook: PartyModel[] = values.partyBook;
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  return (
    <div data-name={name}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={VIC_WOC_PARTY_FORM_CONFIG}
        focusRef={focusRef}
      />
    </div>
  );
}

export default React.memo(Caveator);
