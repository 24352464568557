import * as yup from 'yup';

import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DocumentBaseTitleReferenceModel } from '../../../../models';
import { DischargeOfMortgage_2_26_0_Model } from '../../models';

// path: titleReferences
const yupTitleReferences = yup
  .array<DocumentBaseTitleReferenceModel>()
  .defined()
  .max(20, msg.MAX_ITEMS(20))
  .test(
    'titleReferences conditional required check', //
    'At least 1 title must be selected',
    function test(this: yup.TestContext<DischargeOfMortgage_2_26_0_Model>, value: any) {
      const titleReferences: DocumentBaseTitleReferenceModel[] = value ?? [];
      // incase no common title exists, mortgages validation will fail, so allow this one to pass
      if (titleReferences.length === 0) return true;
      return titleReferences.some(({ isSelected }) => isSelected);
    }
  )
  .log();

export default memoizeSchema(yupTitleReferences);
