import Typography from '@mui/material/Typography';

import { InvitationStatusEnum, ParticipantArchivedStatusEnum } from '@sympli/api-gateway/enums';
import { columnFormatterCheck, ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import {
  addressFormatter,
  dashboardTimeFormatter,
  doubleRowPrimarySecondaryFormatter,
  groupNameFormatter,
  invitationStatusBarFormatter,
  invitationStatusFormatter,
  inviteTypeFormatter,
  memberFormatter,
  multiRowPrimaryFormatter,
  participantRoleFormatter,
  sentByFormatter,
  snapshotSettlementDateFormatter,
  titleFormatter
} from 'src/containers/dashboard/shared/formatters';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { getTransactionTypeString } from 'src/pages/workspace/shared/left-panel/helpers';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme/screens';
import { titleAddress, titleAddressLine } from 'src/utils/formatters';
import { modelKey } from 'src/utils/formUtils';
import { InvitationsDashboardRouteTabsEnumV2 } from '../models';
import { InvitationsTableRowModel } from './models';

const cf = columnFormatterCheck<InvitationsTableRowModel>();
type WithExtra<T, ExtraProps> = {
  [P in keyof T]: Omit<T[P], keyof ExtraProps> & ExtraProps;
};
// TODO move this to ui-framework
function dynamicColumns<R extends object, CH>(
  conditionalColumns: WithExtra<
    //
    ColumnsModel<R>,
    {
      thClassName?: ((args: CH) => string | undefined) | string;
      className?: ((args: CH) => string | undefined) | string;
      label: ((args: CH) => string | JSX.Element) | (string | JSX.Element);
      visibilityCheck?: (args: CH) => boolean;
    }
  >,
  args: CH
): ColumnsModel<R> {
  // recalculate columns based on their visibility
  const columns: ColumnsModel<R> = Object.entries(conditionalColumns) //
    .reduce((acc: ColumnsModel<R>, [key, { visibilityCheck, label, thClassName, className, ...columnDef }]) => {
      const isVisible: boolean = visibilityCheck ? visibilityCheck(args) : true;

      if (isVisible) {
        if (typeof label === 'function') {
          label = label(args);
        }

        if (typeof thClassName === 'function') {
          thClassName = thClassName(args);
        }

        if (typeof className === 'function') {
          className = className(args);
        }

        acc[key] = {
          ...columnDef,
          label,
          thClassName,
          className
        };
      }
      return acc;
    }, {});

  // return only visible columns
  return columns;
}

type DynamicArgs = {
  screenSize: ScreenSizeEnum;
  selectedTab: InvitationsDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
};
const fieldName = modelKey<InvitationsTableRowModel>();

export const resolveColumns = ({
  screenVariant,
  invitationGroup,
  assignmentType
}: {
  //
  screenVariant: ScreenSizeVariant;
  invitationGroup: InvitationsDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
}) => {
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);
  const args: DynamicArgs = {
    screenSize,
    selectedTab: invitationGroup,
    assignmentType
  };
  if (assignmentType === AssignmentTypeEnum.AssignedToMe) {
    return resolveAssignToMeColumns(args);
  }
  return resolveAssignToGroupColumns(args);
};

function resolveAssignToMeColumns(args: DynamicArgs) {
  const columns: ColumnsModel<InvitationsTableRowModel> = dynamicColumns<InvitationsTableRowModel, DynamicArgs>(
    {
      received: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'RECEIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   120   N     N
          // 1536   120   N     N
          // 1280   88    N     N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.received;
        },
        name: fieldName('lastInvitationSentDate'),
        formatter: cf<InvitationsTableRowModel['lastInvitationSentDate']>(dashboardTimeFormatter)
      },
      sent: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SENT',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     120   N
          // 1536   N     120   N
          // 1280   N     88    N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.sent;
        },
        name: fieldName('lastInvitationSentDate'),
        formatter: cf<InvitationsTableRowModel['lastInvitationSentDate']>(dashboardTimeFormatter)
      },
      archived: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ARCHIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     N     120
          // 1536   N     N     120
          // 1280   N     N     88
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('archivedDate'),
        formatter: cf<InvitationsTableRowModel['archivedDate']>(dashboardTimeFormatter)
      },
      from: {
        // explicitly commented out width so we have at least one flexible column width
        label: 'FROM',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   Y     N     N
          // 1536   Y     N     N
          // 1280   Y     N     N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.received;
        },
        sortable: false,
        name: fieldName('fromSubscriberName'),
        get: ({
          fromSubscriberName,
          forwardedFromSubscriberName,
          forwardedFromInvitationId
        }: InvitationsTableRowModel): {
          //
          primary: InvitationsTableRowModel['fromSubscriberName'] | InvitationsTableRowModel['forwardedFromSubscriberName'];
          secondary?: string;
        } => {
          return forwardedFromInvitationId
            ? {
                primary: forwardedFromSubscriberName,
                secondary: 'Forwarded'
              }
            : {
                primary: fromSubscriberName
              };
        },
        formatter: (config: FormatterInputModel<InvitationsTableRowModel, { primary: InvitationsTableRowModel['fromSubscriberName']; secondary?: string }>) => {
          return doubleRowPrimarySecondaryFormatter({ ...config, primaryClassOverride: config.value.secondary === undefined ? 'primary bold' : undefined });
        }
      },
      to: {
        // explicitly commented out width so we have at least one flexible column width
        label: 'TO',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     Y     N
          // 1536   N     Y     N
          // 1280   N     Y     N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.sent;
        },
        sortable: false,
        name: fieldName('toSubscriberName'),
        get: ({
          toSubscriberName,
          forwardedFromInvitationId
        }: InvitationsTableRowModel): {
          //
          primary: InvitationsTableRowModel['toSubscriberName'];
          secondary?: string;
        } => {
          return forwardedFromInvitationId
            ? {
                primary: toSubscriberName,
                secondary: 'Forwarded'
              }
            : {
                primary: toSubscriberName
              };
        },
        formatter: (config: FormatterInputModel<InvitationsTableRowModel, { primary: InvitationsTableRowModel['toSubscriberName']; secondary?: string }>) => {
          return doubleRowPrimarySecondaryFormatter({ ...config, primaryClassOverride: config.value.secondary === undefined ? 'primary bold' : undefined });
        }
      },
      fromOrTo: {
        // explicitly commented out width so we have at least one flexible column width
        label: 'FROM/TO',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     N     Y
          // 1536   N     N     Y
          // 1280   N     N     Y
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        sortable: false,
        name: fieldName('fromSubscriberName'),
        get: ({ type, fromSubscriberName, forwardedFromSubscriberName, forwardedToSubscriberName, toSubscriberName, invitationStatus }: InvitationsTableRowModel) => {
          if (type === ParticipantArchivedStatusEnum.Sent) {
            return {
              primary: forwardedToSubscriberName ?? toSubscriberName
            };
          }

          // if invitation is withdrawn by A, we want to show A, for D or B' Table column
          if (type === ParticipantArchivedStatusEnum.Received && invitationStatus === InvitationStatusEnum.Withdrawn) {
            return {
              primary: fromSubscriberName
            };
          }

          // Received, SentAndReceived, Forwarded all using FROM
          return {
            primary: forwardedFromSubscriberName ?? fromSubscriberName
          };
        },
        formatter: cf<{
          //
          primary: InvitationsTableRowModel['fromSubscriberName'] | InvitationsTableRowModel['toSubscriberName'];
        }>(multiRowPrimaryFormatter)
      },
      inviteType: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        label: 'INVITE TYPE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   N     N       120
          // 1536   N     N       88
          // 1280   N     N       88
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('type'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['type']>(inviteTypeFormatter)
      },
      title: {
        thClassName: 'w-[160px]',
        label: 'TITLE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   88    88      88
          // 1536   88    88      88
          // 1280   88    88      88
          return true;
        },
        name: 'titles',
        sortable: false,
        get: ({ titleReferences, sympliId, invitationTransactionType, heroWorkspace }: InvitationsTableRowModel) => {
          const suffix = invitationTransactionType !== undefined ? ' • ' + getTransactionTypeString(invitationTransactionType) : '';
          const prefix = args.selectedTab === InvitationsDashboardRouteTabsEnumV2.sent ? heroWorkspace?.sympliId ?? sympliId : sympliId;

          return {
            titles: titleReferences.map(t => t.reference),
            sympliId: prefix + suffix
          };
        },
        formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'title':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple titles found</b>
                  </div>
                  {row.titleReferences.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item.reference}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      address: {
        thClassName: 'w-[160px]',
        label: 'ADDRESS',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   160   160     160
          // 1536   160   160     160
          // 1280   160   160     160
          return true;
        },
        name: 'addresses',
        sortable: false,
        get: ({ titleReferences, jurisdictionId }: InvitationsTableRowModel) => ({
          addresses: titleReferences.map(t => titleAddressLine(t.address) || ''),
          jurisdictionId
        }),
        formatter: cf<{ addresses: string[]; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple addresses found</b>
                  </div>
                  {row.titleReferences
                    .map(t => titleAddressLine(t.address) || '')
                    .map((item, i) => {
                      return (
                        <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                          {titleAddress(item, row.jurisdictionId)}
                        </Typography>
                      );
                    })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      myRole: {
        thClassName: 'w-[120px]',
        label: 'ROLE',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     120
          // 1536   120   120     120
          // 1280   120   120     120
          return true;
        },
        name: fieldName('roles'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['roles']>(participantRoleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'roles[0]':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple roles found</b>
                  </div>
                  {row.roles.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      proposedSettlementDate: {
        thClassName: 'w-[120px]',
        label: 'PROPOSED SETL',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     N
          // 1536   120   120     N
          // 1280   120   120     N
          return selectedTab !== InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('snapshotSettlementDate'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['snapshotSettlementDate']>(snapshotSettlementDateFormatter)
      },
      status: {
        label: 'STATUS',
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   N     N       120
          // 1536   N     N       88
          // 1280   N     N       88
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('invitationStatus'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['invitationStatus']>(invitationStatusFormatter)
      },
      statusBar: {
        thClassName: 'w-[6px]',
        label: '',
        name: fieldName('invitationStatus'),
        sortable: false,
        padding: 'none',
        formatter: cf<InvitationsTableRowModel['invitationStatus']>(invitationStatusBarFormatter)
      }
    },
    args
  );
  return columns;
}

function resolveAssignToGroupColumns(args: DynamicArgs) {
  const columns: ColumnsModel<InvitationsTableRowModel> = dynamicColumns<InvitationsTableRowModel, DynamicArgs>(
    {
      received: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'RECEIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   120   N     N
          // 1536   88    N     N
          // 1280   88    N     N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.received;
        },
        name: fieldName('lastInvitationSentDate'),
        formatter: cf<InvitationsTableRowModel['lastInvitationSentDate']>(dashboardTimeFormatter)
      },
      sent: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SENT',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     120   N
          // 1536   N     88    N
          // 1280   N     88    N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.sent;
        },
        name: fieldName('lastInvitationSentDate'),
        formatter: cf<InvitationsTableRowModel['lastInvitationSentDate']>(dashboardTimeFormatter)
      },
      archived: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ARCHIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     N     120
          // 1536   N     N     88
          // 1280   N     N     88
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('archivedDate'),
        formatter: cf<InvitationsTableRowModel['archivedDate']>(dashboardTimeFormatter)
      },
      from: {
        // explicitly commented out width so we have at least one flexible column width
        label: 'FROM',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   Y     N     N
          // 1536   Y     N     N
          // 1280   Y     N     N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.received;
        },
        sortable: false,
        name: fieldName('fromSubscriberName'),
        get: ({
          fromSubscriberName,
          forwardedFromSubscriberName,
          forwardedFromInvitationId
        }: InvitationsTableRowModel): {
          //
          primary: InvitationsTableRowModel['fromSubscriberName'] | InvitationsTableRowModel['forwardedFromSubscriberName'];
          secondary?: string;
        } => {
          return forwardedFromInvitationId
            ? {
                primary: forwardedFromSubscriberName,
                secondary: 'Forwarded'
              }
            : {
                primary: fromSubscriberName
              };
        },
        formatter: (config: FormatterInputModel<InvitationsTableRowModel, { primary: InvitationsTableRowModel['fromSubscriberName']; secondary?: string }>) => {
          return doubleRowPrimarySecondaryFormatter({ ...config, primaryClassOverride: config.value.secondary === undefined ? 'primary bold' : undefined });
        }
      },
      to: {
        // explicitly commented out width so we have at least one flexible column width
        label: 'TO',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     Y     N
          // 1536   N     Y     N
          // 1280   N     Y     N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.sent;
        },
        sortable: false,
        name: fieldName('toSubscriberName'),
        get: ({
          toSubscriberName,
          forwardedFromInvitationId
        }: InvitationsTableRowModel): {
          //
          primary: InvitationsTableRowModel['toSubscriberName'];
          secondary?: string;
        } => {
          return forwardedFromInvitationId
            ? {
                primary: toSubscriberName,
                secondary: 'Forwarded'
              }
            : {
                primary: toSubscriberName
              };
        },
        formatter: (config: FormatterInputModel<InvitationsTableRowModel, { primary: InvitationsTableRowModel['toSubscriberName']; secondary?: string }>) => {
          return doubleRowPrimarySecondaryFormatter({ ...config, primaryClassOverride: config.value.secondary === undefined ? 'primary bold' : undefined });
        }
      },
      fromOrTo: {
        // explicitly commented out width so we have at least one flexible column width
        label: 'FROM/TO',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SEN   ARC
          // 1920   N     N     Y
          // 1536   N     N     Y
          // 1280   N     N     Y
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        sortable: false,
        name: fieldName('fromSubscriberName'),
        get: ({ type, fromSubscriberName, toSubscriberName, forwardedFromSubscriberName, forwardedToSubscriberName, invitationStatus }: InvitationsTableRowModel) => {
          if (type === ParticipantArchivedStatusEnum.Sent) {
            return {
              primary: forwardedToSubscriberName ?? toSubscriberName
            };
          }

          if (type === ParticipantArchivedStatusEnum.Received && invitationStatus === InvitationStatusEnum.Withdrawn) {
            return {
              primary: fromSubscriberName
            };
          }

          // Received, SentAndReceived, Forwarded all using FROM
          return {
            primary: forwardedFromSubscriberName ?? fromSubscriberName
          };
        },
        formatter: cf<{
          //
          primary: InvitationsTableRowModel['fromSubscriberName'] | InvitationsTableRowModel['toSubscriberName'];
        }>(multiRowPrimaryFormatter)
      },
      inviteType: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }
          return 'w-[88px]';
        },
        label: 'INVITE TYPE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   N     N       120
          // 1536   N     N       88
          // 1280   N     N       88
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('type'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['type']>(inviteTypeFormatter)
      },
      title: {
        thClassName: 'w-[160px]',
        label: 'TITLE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   88    88      88
          // 1536   88    88      88
          // 1280   88    88      88
          return true;
        },
        name: 'titles',
        sortable: false,
        get: ({ titleReferences, sympliId, invitationTransactionType, heroWorkspace }: InvitationsTableRowModel) => {
          const suffix = invitationTransactionType !== undefined ? ' • ' + getTransactionTypeString(invitationTransactionType) : '';
          const prefix = args.selectedTab === InvitationsDashboardRouteTabsEnumV2.sent ? heroWorkspace?.sympliId ?? sympliId : sympliId;

          return {
            titles: titleReferences.map(t => t.reference),
            sympliId: prefix + suffix
          };
        },
        formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'title':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple titles found</b>
                  </div>
                  {row.titleReferences.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item.reference}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      address: {
        thClassName: 'w-[160px]',
        label: 'ADDRESS',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   160   160     160
          // 1536   160   160     160
          // 1280   160   160     160
          return true;
        },
        name: 'addresses',
        sortable: false,
        get: ({ titleReferences, jurisdictionId }: InvitationsTableRowModel) => ({
          addresses: titleReferences.map(t => titleAddressLine(t.address) || ''),
          jurisdictionId
        }),
        formatter: cf<{ addresses: string[]; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple addresses found</b>
                  </div>
                  {row.titleReferences
                    .map(t => titleAddressLine(t.address) || '')
                    .map((item, i) => {
                      return (
                        <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                          {titleAddress(item, row.jurisdictionId)}
                        </Typography>
                      );
                    })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      myRole: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        label: 'ROLE',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     120
          // 1536   120   120     120
          // 1280   88    88      88
          return true;
        },
        name: fieldName('roles'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['roles']>(participantRoleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'roles[0]':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple roles found</b>
                  </div>
                  {row.roles.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      proposedSettlementDate: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            return 'w-[88px]';
          }
          return 'w-[120px]';
        },
        label: 'PROPOSED SETL',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     N
          // 1536   120   120     N
          // 1280   88    88      N
          return selectedTab !== InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('snapshotSettlementDate'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['snapshotSettlementDate']>(snapshotSettlementDateFormatter)
      },
      status: {
        label: 'STATUS',
        thClassName: 'w-[88px]',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   N     N       88
          // 1536   N     N       88
          // 1280   N     N       88
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('invitationStatus'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['invitationStatus']>(invitationStatusFormatter)
      },

      group: {
        thClassName: ({ selectedTab, screenSize, assignmentType }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen && selectedTab !== InvitationsDashboardRouteTabsEnumV2.archived) {
            return 'w-[120px]';
          }

          return 'w-[88px]';
        },
        label: 'Group',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     88
          // 1536   88    88      88
          // 1280   88    88      88
          return true;
        },
        name: fieldName('groupName'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['groupName']>(groupNameFormatter)
      },
      member: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }

          return 'w-[88px]';
        },
        label: 'MEMBER',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     N
          // 1536   88    88      N
          // 1280   88    88      N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.received;
        },
        name: fieldName('member'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['member']>(memberFormatter)
      },
      sentBy: {
        thClassName: ({ screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[120px]';
          }

          return 'w-[88px]';
        },
        label: 'SENT BY',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //        REC   SENT    ARCH
          // 1920   120   120     N
          // 1536   88    88      N
          // 1280   88    88      N
          return selectedTab === InvitationsDashboardRouteTabsEnumV2.sent;
        },
        name: fieldName('sentBy'),
        sortable: false,
        formatter: cf<InvitationsTableRowModel['sentBy']>(sentByFormatter)
      },
      statusBar: {
        thClassName: 'w-[6px]',
        label: '',
        name: fieldName('invitationStatus'),
        sortable: false,
        padding: 'none',
        formatter: cf<InvitationsTableRowModel['invitationStatus']>(invitationStatusBarFormatter)
      }
    },
    args
  );
  return columns;
}
