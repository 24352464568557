// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { TransferOfControlModel } from './models';
import yupApplicant from './sections/applicant/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupRecipient from './sections/recipient/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<TransferOfControlModel>()
  .shape({
    titleReferences: yupTitleReferences,
    applicant: yupApplicant,
    recipient: yupRecipient,
    partyBook: yupPartyBook
  })
  .log();
