import _uniqBy from 'lodash-es/uniqBy';
import _uniqWith from 'lodash-es/uniqWith';

import { createPartyReference } from '@sympli-mfe/document-forms-framework/components/party-checkbox-list';
import { formatPartyName, PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartyCapacityModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, ApiPartySourceModel, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { claimDetailsConfigs, ClaimStatementEnum } from './enums';
import { BaseCaveatModel, PARTY_BOOK_KEY, TitleReferenceModel } from './models';

export const titlesHasCommonProprietor = (titleReferences: TitleReferenceModel[]) => !!getCommonProprietors(titleReferences).length;

const getCommonProprietors = (titleReferences: TitleReferenceModel[]): ApiPartySourceModel[] => {
  if (!titleReferences.length) return [];

  const commonProprietors = titleReferences.slice(1).reduce((prev, current) => {
    if (!prev.length) return prev;

    return getCommonParties(prev, getProprietors(current));
  }, getProprietors(titleReferences[0]));

  return commonProprietors;
};

const getProprietors = (titleReference: TitleReferenceModel): ApiPartySourceModel[] => titleReference.proprietors.proprietorGroups.flatMap(item => item.parties);

const getCommonParties = (parties1: ApiPartySourceModel[], parties2: ApiPartySourceModel[]): ApiPartySourceModel[] => {
  return parties1.reduce((prev: ApiPartySourceModel[], party1: ApiPartySourceModel) => {
    if (prev.some(item => isSameProprietor(party1, item))) return prev;

    if (parties2.some(party2 => isSameProprietor(party1, party2))) {
      prev.push(party1);
    } else {
      const indeterminateParty = parties2.find(
        party2 =>
          party1.party.legalEntityName === party2.party.legalEntityName &&
          isSamePartyCapacity(party1.partyCapacity, party2.partyCapacity) &&
          (isIndeterminateParty(party1.party) || isIndeterminateParty(party2.party))
      );
      if (indeterminateParty) {
        prev.push(party1, indeterminateParty);
      }
    }

    return prev;
  }, []);
};

const isSameProprietor = (party1: ApiPartySourceModel, party2: ApiPartySourceModel): boolean =>
  party1.party.legalEntityName === party2.party.legalEntityName &&
  party1.party.partyType === party2.party.partyType &&
  isSamePartyCapacity(party1.partyCapacity, party2.partyCapacity);

const isSamePartyCapacity = (partyCapacity1?: PartyCapacityModel, partyCapacity2?: PartyCapacityModel): boolean =>
  partyCapacity1?.capacity === partyCapacity2?.capacity && partyCapacity1?.details === partyCapacity2?.details;

const isIndeterminateParty = (party: ApiDocumentPartyModel): boolean => party.partyType === undefined;

const filterProprietors = (values: BaseCaveatModel, partyFormConfig: PartyFormConfig, allProprietors: ApiPartySourceModel<{}, {}>[]): TenancyPartyModel[] => {
  const commonApiProprietors = getCommonProprietors(values.titleReferences.filter(x => x.isSelected));
  if (!commonApiProprietors.length) return [];

  const currentProprietors = values.proprietors.map(p => ({
    ...p,
    party: values.partyBook.find(pb => pb.id === p.partyBookId)!
  }));
  const commonProprietors = commonApiProprietors.map(x => allProprietors.find(y => isSameProprietor(x, y)) ?? x);
  const commonProprietorReferences = commonProprietors.map((apiProprietor): TenancyPartyModel => {
    const currentProprietor = currentProprietors.find(p => p.partyBookId === apiProprietor.party.id && isSamePartyCapacity(p.partyCapacity, apiProprietor.partyCapacity));
    const existingPartyReference = values.partyReferences.find(p => p.partyBookId === apiProprietor.party.id);
    if (currentProprietor)
      return { ...currentProprietor, isSelected: true, addressBookId: currentProprietor.addressBookId || apiProprietor.addressBookId || existingPartyReference?.addressBookId };

    return toPartyReference(values.partyBook, apiProprietor, partyFormConfig, existingPartyReference?.addressBookId);
  });

  return commonProprietorReferences;
};

export function filterSelectedPartySiblingsFromPartyBook(
  partyBook: PartyModel<{}, {}>[],
  currentSelectedParty: TenancyPartyModel,
  selectedParties?: TenancyPartyModel[],
  partyFormConfig?: PartyFormConfig<{}, {}>
) {
  //
  const selectedPartyIds = selectedParties?.map(party => party.partyBookId) ?? [];

  const partyOptions = partyBook
    .filter(party => {
      const isCurrentlySelected = party.id === currentSelectedParty.partyBookId;
      const hasNotBeenSelected = !selectedPartyIds.includes(party.id);

      /*
       * We need to return current selected party too;
       * otherwise, the selected value of the dropdown list will be empty.
       */
      return isCurrentlySelected || hasNotBeenSelected;
    })
    .map(party => ({ id: party.id, name: formatPartyName(party, partyFormConfig) }));

  return partyOptions;
}

export const filterMortgagees = (values: BaseCaveatModel, partyFormConfig: PartyFormConfig): TenancyPartyModel[] => {
  return values.titleReferences
    .filter(x => x.isSelected && x.mortgagees)
    .flatMap(x => x.mortgagees)
    .sort((a, b) => a!.dealingNumber.localeCompare(b!.dealingNumber))
    .flatMap(x => x?.parties)
    .map(party => {
      const existingPartyReference = values.partyReferences.find(p => p.partyBookId === party?.party.id);
      return toPartyReference(values.partyBook, party!, partyFormConfig, existingPartyReference?.addressBookId);
    });
};

export const toPartyReference = (
  partyBook: PartyModel[],
  apiPartySource: ApiPartySourceModel,
  partyFormConfig: PartyFormConfig,
  defaultAddressBookId?: string
): TenancyPartyModel => {
  const party = partyBook.find(pb => pb.id === apiPartySource.party.id);
  if (party) {
    return {
      isSelected: true,
      partyBookId: party.id,
      partyCapacity: apiPartySource.partyCapacity ?? {},
      addressBookId: apiPartySource.addressBookId || defaultAddressBookId
    };
  }
  const { convertPartyBookFromApiToFormModel } = resolvePartyBookConversion({ partyFormConfig });
  const newParty: PartyModel = { ...convertPartyBookFromApiToFormModel([apiPartySource.party])[0], originalApiParty: apiPartySource.party };
  partyBook.push(newParty);
  return {
    isSelected: true,
    partyBookId: newParty.id,
    partyCapacity: apiPartySource.partyCapacity ?? {},
    addressBookId: apiPartySource.addressBookId || defaultAddressBookId
  };
};

export const populateCaveatModelFromTitleSelected = (values: BaseCaveatModel, partyFormConfig: PartyFormConfig): BaseCaveatModel => {
  const defaultCaveators = filterDefaultCaveators(values, partyFormConfig);
  const mortgagees = filterMortgagees(values, partyFormConfig);
  const allProprietors = getProprietorsFromTitle(values.titleReferences);
  const allProprietorReferences = allProprietors.map(p => toPartyReference(values[PARTY_BOOK_KEY], p, partyFormConfig));
  const commonProprietors = filterProprietors(values, partyFormConfig, allProprietors);
  const partyReferences = includeAdditionalPartyReferences(values.partyReferences, allProprietorReferences, mortgagees);

  return {
    ...values, //
    caveators: defaultCaveators,
    mortgagees: mortgagees,
    proprietors: commonProprietors,
    partyReferences
  };
};

const includeAdditionalPartyReferences = (partyReferences: TenancyPartyModel[], proprietors: TenancyPartyModel[], mortgagees: TenancyPartyModel[]): TenancyPartyModel[] => {
  return _uniqBy<TenancyPartyModel>(partyReferences.concat(proprietors.concat(mortgagees)), p => p.partyBookId);
};

export const isSupportDetailsSupportingTheClaim = (claimStatement: ClaimStatementEnum): boolean => {
  return !!claimDetailsConfigs.find(item => item.claimStatement === claimStatement)?.detailsSupportingTheClaim;
};

export const isSupportClaimPartyDetails = (claimStatement: ClaimStatementEnum): boolean => {
  return !!claimDetailsConfigs.find(item => item.claimStatement === claimStatement)?.claimPartyDetails;
};

export const isSupportClaimDate = (claimStatement: ClaimStatementEnum): boolean => {
  return !!claimDetailsConfigs.find(item => item.claimStatement === claimStatement)?.claimDate;
};

export const buildPartyReferences = (partyBook: PartyModel[], existingPartyReferences?: TenancyPartyModel[]): TenancyPartyModel[] => {
  return partyBook.map(p => {
    const existingClaimParty = (existingPartyReferences ?? []).find(claimParty => claimParty.partyBookId === p.id);
    return existingClaimParty ? existingClaimParty : createPartyReference(p.id);
  });
};

export const filterSelectablePartyReferences = (
  titleReferences: TitleReferenceModel[],
  partyBook: PartyModel[],
  existingPartyReferences?: TenancyPartyModel[]
): TenancyPartyModel[] => {
  const allProprietors = getProprietorsFromTitle(titleReferences);

  const proprietorParties: TenancyPartyModel[] = allProprietors.map(p => {
    const existingPartyReference = existingPartyReferences?.find(e => e.partyBookId === p.party.id);
    return {
      partyBookId: p.party.id,
      partyCapacity: p.partyCapacity ?? {},
      // retain exiting selection and address
      isSelected: !!existingPartyReference?.isSelected,
      addressBookId: existingPartyReference?.addressBookId
    };
  });
  const customParties: TenancyPartyModel[] = partyBook
    .filter(pb => pb.metadata?.source === DataSource.Custom)
    .map(pb => existingPartyReferences?.find(e => e.partyBookId === pb.id) || createPartyReference(pb.id));

  return proprietorParties.concat(customParties);
};

export function updatePartyReferences(partyReferences: TenancyPartyModel[] | undefined, newPartyReference: TenancyPartyModel): TenancyPartyModel[] | undefined {
  if (!partyReferences) return;

  if (partyReferences?.some(x => x.partyBookId === newPartyReference.partyBookId) === false) {
    return partyReferences;
  }

  return partyReferences.map(rp => {
    if (rp.partyBookId === newPartyReference.partyBookId) {
      rp.addressBookId = newPartyReference.addressBookId;
      rp.partyCapacity = newPartyReference.partyCapacity;
      return rp;
    }
    return rp;
  });
}

const filterDefaultCaveators = (values: BaseCaveatModel, partyFormConfig: PartyFormConfig): TenancyPartyModel[] => {
  const defaultCaveators = values.subscriberParties.map(party => {
    return toPartyReference(values.partyBook, party!, partyFormConfig);
  });
  return values.caveators.length === 0 ? defaultCaveators : values.caveators;
};

export const isProprietorOrMortgagee = (values: BaseCaveatModel, partyBookId?: string): boolean => {
  if (!partyBookId) return false;
  return values.proprietors.some(p => p.partyBookId === partyBookId) || values.mortgagees.some(p => p.partyBookId === partyBookId);
};

function getProprietorIdsFromTitle(titleReferences: TitleReferenceModel[]) {
  return titleReferences.flatMap(t => getProprietors(t)).map(p => p.party.id);
}

export function getProprietorAndCustomPartyBook(partyBook: PartyModel<{}, {}>[], titleReferences: TitleReferenceModel[]) {
  const proprietorIds = getProprietorIdsFromTitle(titleReferences);
  return partyBook.filter(pb => pb.metadata?.source === DataSource.Custom || proprietorIds.some(rp => rp === pb.id));
}

export const getProprietorsFromTitle = (titleReferences: TitleReferenceModel[]): ApiPartySourceModel<{}, {}>[] => {
  const allParties = titleReferences.flatMap(t => t.proprietors.proprietorGroups.flatMap(pg => pg.parties));
  return _uniqWith(allParties, isSameProprietor);
};

export const resolveDealingNumberOptions = (values: BaseCaveatModel): LookupItemModel[] => {
  const registerDealings = values.titleReferences.filter(x => x.isSelected).flatMap(t => t.registerDealings);
  if (registerDealings.length === 0) return [];

  return registerDealings.map<LookupItemModel>(item => {
    return {
      id: item.registryDocument.dealingNumber,
      name: `${item.registryDocument.dealingNumber} - ${item.type} ${item.parties.length > 0 ? `(${item.parties.map(p => p.party.legalEntityName).join(', ')})` : ''}`
    };
  });
};
