import React from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconLink } from '@sympli/ui-framework/icons';

import ListItemLink from 'src/components/list-item-link';
import WorkspaceExpansionBox from 'src/containers/shared/workspace-expansion-box';
import LodgementOnlyWorkspaceStatusBadge from 'src/containers/workspace/shared/components/lodgement-only-workspace-status-badge';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { LinkedWorkspaceListItem } from './models';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2081751114/linked+workspace+list+-+data+dependency+insights
interface Props {
  currentWorkspaceIndex: number;
  // profile info
  currentSubscriberId: string;
  // linked workspaces
  items: LinkedWorkspaceListItem[];
  collapsedByDefault?: boolean;
}

function LinkedWorkspaceList({
  //
  currentWorkspaceIndex,
  // profile info
  currentSubscriberId,
  // linked workspaces
  items,
  collapsedByDefault
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <WorkspaceExpansionBox
      title={
        <Typography variant="h6" className="text-[18px] font-bold leading-[36px]">
          Linked workspaces
        </Typography>
      }
      className={classes.root}
      collapsedByDefault={collapsedByDefault}
    >
      <FlexLayout alignItems="center" className={classes.subHeader}>
        <IconLink className={classes.iconLink} />
        This is #{currentWorkspaceIndex + 1} of {items.length} linked workspaces
      </FlexLayout>
      <List component="nav" className={classes.list}>
        {items.map(
          (
            {
              //
              order,
              workspaceId,
              participants,
              workspaceStatusId,
              lodgementCaseStatusId,
              workspaceTypeTitle
            }: LinkedWorkspaceListItem,
            i: number
          ) => {
            // find any participant (linked workspace can have only one participant) which belongs to same subscriber otherwise will not create any link to navigate
            // we don't know which workspaces can see current participant that's why using subscriber
            const participantId = participants.find(item => item.subscriberId === currentSubscriberId)?.participantId;
            const linkTo = resolveWorkspaceDetailLink({ workspaceId, participantId });
            return (
              <ListItemLink //
                key={workspaceId}
                disabled={participantId === undefined || workspaceStatusId === WorkspaceStatusEnum.AwaitingInvitee}
                linkTo={linkTo}
                className={classes.listItem}
                classes={{
                  link: classes.listItemLink
                }}
              >
                #{order}: {workspaceTypeTitle}
                <LodgementOnlyWorkspaceStatusBadge //
                  workspaceStatusId={workspaceStatusId}
                  lodgementCaseStatusId={lodgementCaseStatusId}
                  size="small"
                />
              </ListItemLink>
            );
          }
        )}
      </List>
    </WorkspaceExpansionBox>
  );
}

export default React.memo(LinkedWorkspaceList);
