import _isEqual from 'lodash-es/isEqual';
import { createSelector } from 'reselect';

import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';

interface EnabledStepIndexParams<T, V> {
  steps?: Array<StepObjectModel<T, V>>;
  lastEnabledStep: V;
}
const getWorkflowSteps = <T, V>(params: EnabledStepIndexParams<T, V>) => params.steps;
const getLastEnabledStep = <T, V>(params: EnabledStepIndexParams<T, V>) => params.lastEnabledStep;

export const enabledStepIndexSelector = createSelector<
  //
  EnabledStepIndexParams<unknown, unknown>,
  StepObjectModel<unknown, unknown>[] | undefined,
  unknown,
  number
>(getWorkflowSteps, getLastEnabledStep, (steps = [], lastEnabledStep) => {
  return steps.findIndex(item => _isEqual(item.value, lastEnabledStep));
});
