import { useMemo } from 'react';
import * as React from 'react';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import StatusProgress from 'src/components/workspace-status-rebuild/status-progress';
import { WorkspaceStatusColorVariantEnum } from 'src/components/workspace-status-rebuild/status-progress/components/models';
import { financialWorkspaceStatusTooltipContent } from 'src/components/workspace-status-rebuild/status-progress/mappings/financialWorkspaceStatusTooltipContent';
import { VerticalStatusLineVariant } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { FinancialWorkspaceStatusTitle, resolveFinancialWorkspaceStatus, workspaceStatusSteps } from 'src/utils/status-mapping/workspaceStatusHelper';
import { useStyles } from './styles';

interface Props {
  workspaceStatusId: WorkspaceStatusEnum;
  isLocked?: boolean;
}

function FinancialWorkspaceStatusBadgeNew({ workspaceStatusId }: Props) {
  const classes = useStyles();
  //const screenVariant: ScreenSizeVariant = useScreenSize();

  const wsStatusInfo: {
    variant: VerticalStatusLineVariant;
    title: FinancialWorkspaceStatusTitle;
    tooltipContent?: NonNullable<React.ReactNode> | undefined;
  } | null = useMemo(() => {
    const wsStatus = resolveFinancialWorkspaceStatus(workspaceStatusId);

    if (!wsStatus) {
      return null;
    }

    const tooltipContent = financialWorkspaceStatusTooltipContent(wsStatus.variant, wsStatus.title);
    return { ...wsStatus, tooltipContent };
  }, [workspaceStatusId]);

  if (!wsStatusInfo) {
    return null;
  }

  const { variant, title, tooltipContent } = wsStatusInfo;
  const currentStep = variant === 'ok' || variant === 'error' || variant === 'warning' ? workspaceStatusSteps.indexOf(title) + 1 : -1;

  return (
    <div className={classes.FinancialWorkspaceStatusBadgeNew}>
      {/* <VerticalStatusLine //
        text={title}
        variant={variant}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
        tooltipTitle={tooltipContent}
      /> */}
      <StatusProgress
        currentStep={currentStep}
        variant={
          //
          variant === 'ok'
            ? WorkspaceStatusColorVariantEnum.Success
            : variant === 'error'
              ? WorkspaceStatusColorVariantEnum.Failure
              : variant === 'warning'
                ? WorkspaceStatusColorVariantEnum.Warning
                : WorkspaceStatusColorVariantEnum.Inactive
        }
        steps={workspaceStatusSteps}
        tooltipTitle={tooltipContent ?? <div></div>}
        customizedStep={currentStep < 0 ? title : undefined}
      />
    </div>
  );
}

export default React.memo(FinancialWorkspaceStatusBadgeNew);
