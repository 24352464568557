import React, { useMemo } from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import { SelectProps } from '@mui/material/Select';

import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import {
  createModelKeyAppender,
  resolveLabel,
  resolveSelectPlaceholder,
  resolveVisibleEnumOptions,
  useSelectRefCallbackToAssignFauxButtonActions
} from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, VISIBILITY_CHECK_QUALIFICATION } from '../../checks';
import { ENUM_ACTION_PROHIBITED_OPTIONS } from '../../enums';
import { CaveatModel_2_21_1, ExtentOfProhibition } from '../../models';
import { resolveDefaultItem } from './models';
import { useStyles } from './styles';

const debug = !import.meta.env.PROD;

type FormValues = CaveatModel_2_21_1;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
}

class ActionProhibited extends React.PureComponent<Props> {
  render() {
    const { name, formikProps, disabled } = this.props;
    const items: ExtentOfProhibition[] = _get(formikProps.values, name) ?? [];
    return (
      <DocumentFieldArray
        arrayBinding={name}
        minItems={1}
        maxItems={ENUM_ACTION_PROHIBITED_OPTIONS.length}
        createNewItem={resolveDefaultItem}
        itemTitle="Action prohibited"
        addButtonTitle="Add action prohibited"
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        renderItem={({ itemIndex, itemBinding, itemFocusRef }) => (
          <ActionProhibitedItem //
            name={itemBinding}
            root={formikProps.values}
            items={items}
            itemIndex={itemIndex}
            focusRef={itemFocusRef}
            disabled={disabled}
          />
        )}
      />
    );
  }
}

interface ActionProhibitedItemProps {
  focusRef: React.RefObject<ButtonBaseActions>;
  name: string;
  disabled: boolean;
  items: ExtentOfProhibition[];
  root: FormValues;
  itemIndex: number;
}

function ActionProhibitedItem({ name, disabled, items, root, itemIndex, focusRef }: ActionProhibitedItemProps): JSX.Element {
  const item = items[itemIndex];
  const fieldName = createModelKeyAppender<ExtentOfProhibition>(name);

  const visibleActionProhibitedEnumOptions = resolveVisibleEnumOptions<number>(ENUM_ACTION_PROHIBITED_OPTIONS, root, fieldName('actionProhibitedOption'));
  const classes = useStyles();

  const selectRefCallback = useSelectRefCallbackToAssignFauxButtonActions(focusRef);
  const selectProps = useMemo(
    (): SelectProps => ({
      inputRef: selectRefCallback
    }),
    [selectRefCallback]
  );

  return (
    <>
      <Field
        aria-label="Action prohibited"
        disabled={disabled}
        className={classes.fixedWidthDropdown}
        name={fieldName('actionProhibitedOption')}
        format="number"
        options={visibleActionProhibitedEnumOptions}
        placeholder={resolveSelectPlaceholder(true)}
        component={SelectField}
        debug={debug}
        SelectProps={selectProps}
      />
      {VISIBILITY_CHECK_QUALIFICATION(item) && (
        <Field
          disabled={disabled}
          fullWidth
          label={
            <>
              {resolveLabel('Qualification', false)}
              <InfoIconWithTooltip flow="inline" tooltipContent="Qualification can exempt or add or provide additional criteria to the prohibitions of the caveat" />
            </>
          }
          name={fieldName('qualification')}
          component={InputField}
        />
      )}
      {VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID(item) && (
        <Field //
          disabled={disabled}
          className={classes.halfWidth}
          label={resolveLabel('LRS Dealing number', true)}
          name={fieldName('lrDocumentId')}
          component={InputField}
        />
      )}
    </>
  );
}

export default ActionProhibited;
