import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minWidth: 110,
      width: 'auto',
      font: 'inherit',
      color: 'white',
      fontSize: 22,
      fontWeight: 'bold',
      paddingTop: 3,
      margin: 0,
      borderRadius: 2,
      '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
      }
    },
    labelBox: {
      position: 'relative',
      marginLeft: 10,
      borderRadius: 5,
      height: 32,
      width: 'fit-content',
      padding: '0px 10px'
    },
    selectIcon: {
      fill: `${theme.palette.primary.contrastText}`,
      width: 16,
      height: 16,
      paddingLeft: 4
    },
    inputContainer: {
      margin: 10,
      padding: 10
    },
    checkboxGroup: {
      minWidth: 360,
      marginBottom: 10,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 18
    }
  }),
  { name: 'FeatureFlagsController' }
);
