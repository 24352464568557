// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { TransmissionApplicationWithoutDuty2_21_1Model } from './models';
import yupApplicantCapacity from './sections/applicant-capacity/validationSchema';
import yupApplicants from './sections/applicants/validationSchema';
import { resolveDeceasedProprietorGroupsValidationSchema, yupDeceasedProprietorGroupsSectionValidation } from './sections/deceased-proprietor-groups/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupSupportingDocuments from './sections/supporting-documents/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<TransmissionApplicationWithoutDuty2_21_1Model>({
    applicantCapacity: yupApplicantCapacity,
    titleReferences: yupTitleReferences,
    applicants: yupApplicants,
    supportingDocuments: yupSupportingDocuments,
    deceasedProprietorGroups: resolveDeceasedProprietorGroupsValidationSchema,
    deceasedProprietorGroupsValidation: yupDeceasedProprietorGroupsSectionValidation,
    partyBook: yupPartyBook
  })
  .defined()
  .log();
