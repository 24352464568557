import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    buttonLink: {
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  {
    name: 'AuthorityActivityLogItem'
  }
);
