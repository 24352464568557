import * as yup from 'yup';

import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { checkIfJustifiedPartyNamesAreUnique, checkIfMergedPartiesHaveMismatchingPartyType, checkSelectedTitlesHaveSameMortgagors } from '../../helper';
import { Mortgage2_21_1Model, MortgagorPartyModel } from '../../models';
import { VISIBILITY_CHECK_MORTGAGORS } from '../../visibilityChecks';

interface MortgagorsContext {
  isFinancialWorkspace: boolean;
  mortgagorsLength: number;
  partyBook: Mortgage2_21_1Model['partyBook'];
  titleReferences: Mortgage2_21_1Model['titleReferences'];
  isMortgagorsSectionVisible: boolean;
}

const contextResolver = ({ isFinancialWorkspace, mortgagors, partyBook, titleReferences }: Mortgage2_21_1Model): MortgagorsContext => {
  return {
    isFinancialWorkspace,
    mortgagorsLength: mortgagors.length,
    partyBook,
    titleReferences,
    isMortgagorsSectionVisible: VISIBILITY_CHECK_MORTGAGORS({ titleReferences })
  };
};

const yupMortgagors = memoizeSchemaWithContext(
  yup
    .array<MortgagorPartyModel, MortgagorsContext>()
    .defined()
    .testContextualRule({
      uniqueTestName: 'Wait for transfer test',
      message: 'Awaiting Transfer document completion',
      onlyIf: (parent: Mortgage2_21_1Model, ctx: MortgagorsContext) => ctx.isMortgagorsSectionVisible,
      requirement: (parent: Mortgage2_21_1Model, ctx: MortgagorsContext) => {
        // this validation is for 4 party only, and "Transferee" is the "Mortgagor",
        // so it is automatically set from Transfer into Mortgage, and won't be set until the Transfer is saved.
        if (!ctx.isFinancialWorkspace) {
          return true;
        }
        return ctx.mortgagorsLength > 0;
      }
    })
    .testContextualRule({
      uniqueTestName: 'Mortgagors name mismatch test',
      message: 'Please be aware that the parties on the selected titles presently do not match.',
      onlyIf: (parent: Mortgage2_21_1Model, ctx: MortgagorsContext) => ctx.isMortgagorsSectionVisible,
      requirement: (parent: Mortgage2_21_1Model, { partyBook, titleReferences }: MortgagorsContext) => {
        // applies for multi title only, if there is a name mismatch,
        // where the user would either need to add a justification or unselect a title
        // (this is also a business rule that was previously implemented)
        return checkSelectedTitlesHaveSameMortgagors(partyBook, titleReferences);
      }
    })
    .testContextualRule({
      uniqueTestName: 'Check if party is justified twice',
      message: 'Unable to change name, the proposed name has already been used in a justification.',
      onlyIf: (parent: Mortgage2_21_1Model, ctx: MortgagorsContext) => ctx.isMortgagorsSectionVisible,
      requirement: (parent: Mortgage2_21_1Model, { partyBook, titleReferences }: MortgagorsContext) => {
        // two mortgagors should not be justified to the same name
        return checkIfJustifiedPartyNamesAreUnique(partyBook, titleReferences);
      }
    })
    .test('Check if parties justified does not have same party type', 'Unable to change name', function test(this: yup.TestContext<MortgagorsContext>) {
      const { partyBook, titleReferences, isMortgagorsSectionVisible } = this.options.context!;
      if (!isMortgagorsSectionVisible) {
        return true;
      }
      // if party with proposed name already exists but it's of different type we want to show error
      const partyType = checkIfMergedPartiesHaveMismatchingPartyType(partyBook, titleReferences);
      return partyType
        ? this.createError({
            message: `Unable to change name, the proposed name has already been used for an ${partyType === PartyTypeEnum.Individual ? 'individual' : 'organisation'}.`
          })
        : true;
    })
    .log(),
  contextResolver
);

export default yupMortgagors;
