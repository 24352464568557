import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';

import { modelKey } from 'src/utils/formUtils';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file
const fieldName = modelKey<PartyModel>();

export const NSW_DM_PARTY_FORM_CONFIG: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: nswPartyNameChangeConfig,
  upperCaseFields: [fieldName('firstName'), fieldName('lastName'), fieldName('organisationName'), fieldName('nameSuffixValue')]
};
