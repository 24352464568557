import * as yup from 'yup';

import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import {
  AdditionalParticipantRoleConfig,
  InvitationModel,
  WorkspaceInviteParticipantsFormModel
} from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/models';
import {
  getAdditionalInviteValidationSchema,
  getInvitationValidationSchema
} from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/invite-participants/validationSchema';

// tslint:disable:max-line-length
function getValidationSchema(
  currentWorkspaceRoleId: WorkspaceRoleEnum,
  currentSubscriber: UserProfileModel,
  additionalParticipantRoleConfigs: Partial<Record<WorkspaceRoleEnum, AdditionalParticipantRoleConfig>>,
  workspaceParticipants: WorkspaceParticipantApiResponse[] = []
) {
  return yup.object<WorkspaceInviteParticipantsFormModel>({
    invitedRoles: yup.mixed(),
    invitations: yup
      .array()
      .of<InvitationModel>(getInvitationValidationSchema(currentWorkspaceRoleId, currentSubscriber, additionalParticipantRoleConfigs, false, workspaceParticipants))
      .defined(),
    additionalInvitations: yup
      .array()
      .of<InvitationModel>(getAdditionalInviteValidationSchema(currentWorkspaceRoleId, currentSubscriber, additionalParticipantRoleConfigs, workspaceParticipants))
      .defined()
  });
}
export default getValidationSchema;
