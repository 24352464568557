import classNames from 'classnames';

import { currency } from '@sympli/ui-framework/utils/formatters';

import { SympliSourceAccountStatusEnum } from '../../models';

export function resolveBalanceText(balanceAmount: number, amountRequired: number, status: SympliSourceAccountStatusEnum, classes: any) {
  const sourceBalance = balanceAmount - amountRequired;
  let type = '';
  let sign = '';
  let css = '';

  css = classNames((status === SympliSourceAccountStatusEnum.Shortfall || status === SympliSourceAccountStatusEnum.Surplus) && classes.sourceUnbalanced);
  switch (status) {
    case SympliSourceAccountStatusEnum.Shortfall:
      type = 'Shortfall:';
      sign = '-';
      break;
    case SympliSourceAccountStatusEnum.Surplus:
      type = 'Surplus:';
      break;
    default:
      break;
  }

  const value = `${sign}${currency(Math.abs(sourceBalance))}`;

  return {
    value,
    type,
    css
  };
}

export function resolveBalanceText101(balanceAmount: number, amountRequired: number, status: SympliSourceAccountStatusEnum, classes: any) {
  const sourceBalance = balanceAmount - amountRequired;
  let type = '';
  let sign = '';
  let css = '';

  css = classNames(
    status === SympliSourceAccountStatusEnum.Shortfall || status === SympliSourceAccountStatusEnum.Surplus
      ? classes.newSourceUnbalanced
      : status === SympliSourceAccountStatusEnum.Cleared && classes.sourceCleared
  );
  switch (status) {
    case SympliSourceAccountStatusEnum.Shortfall:
      type = 'Shortfall:';
      sign = '-';
      break;
    case SympliSourceAccountStatusEnum.Surplus:
      type = 'Surplus:';
      break;
    default:
      break;
  }

  const value = `${sign}${currency(Math.abs(sourceBalance))}`;

  return {
    value,
    type,
    css
  };
}
