import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { DocumentPartyModel } from './models';

export function formatDocumentPartyName(party: DocumentPartyModel): string | undefined {
  const { legalEntityName } = party;
  if (party.partyType === PartyTypeEnum.Individual) {
    const { firstName, lastName } = party;
    const nameWithoutSuffix = legalEntityName || [firstName, lastName].filter(Boolean).join(' ');
    return nameWithoutSuffix;
  } else if (party.partyType === PartyTypeEnum.Organisation) {
    const { organisationName } = party;
    return legalEntityName || organisationName;
  } else {
    throw new Error('Unsupported party type.');
  }
}
