import * as React from 'react';

import classNames from 'classnames';
import Divider from '@mui/material/Divider';

import { BankAccountTypeEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { currency } from '@sympli/ui-framework/utils/formatters';

import IconTypography from 'src/components/workspace-status-rebuild/icon-typography';
import { getExpectedSympliSourceAccountAmount, getVerifiedSympliSourceAccountAmount } from '../../helpers';
import { DirectionsTrustAccountModel, SourceFundModel, SympliSourceAccountStatusEnum, TrustAccountMap } from '../../models';
import SympliSourceAccountBalanceText from '../sympli-source-account-balance-text';
import { resolveSympliSourceAccountStatus, showCalculator } from './helper';
import useStyles from './styles';

interface Props {
  trustAccounts: Array<DirectionsTrustAccountModel>;
  trustAccountBankDetailMap?: TrustAccountMap;
  sourceFunds?: Array<SourceFundModel>;
}
function SympliSourceAccountCalculator({
  //
  trustAccounts,
  trustAccountBankDetailMap,
  sourceFunds
}: Props) {
  const classes = useStyles();

  if (!showCalculator(sourceFunds, trustAccountBankDetailMap)) {
    return null;
  }

  const verifiedAmount = getVerifiedSympliSourceAccountAmount(trustAccounts);
  const expectedAmount = getExpectedSympliSourceAccountAmount(sourceFunds, trustAccountBankDetailMap!);

  // do not show if there is no sympli source account
  if (verifiedAmount === null || expectedAmount === null) {
    return null;
  }

  const sympliSourceAccountStatus = resolveSympliSourceAccountStatus(verifiedAmount, expectedAmount);

  const expectedAmountText = currency(expectedAmount);
  const verifiedAmountText = sympliSourceAccountStatus === SympliSourceAccountStatusEnum.Unverified ? 'Unverified' : currency(verifiedAmount);

  //All deposit from links are the same for sympli source account so we just need to pick one of them
  const depositFormPdfUrl = sourceFunds?.find(x => x.accountType === BankAccountTypeEnum.SympliSourceAccount)?.depositFormPdfUrl;

  const renderBalanceStatus = () => {
    switch (sympliSourceAccountStatus) {
      case SympliSourceAccountStatusEnum.Surplus:
        return <IconTypography text="Surplus Found" />;
      case SympliSourceAccountStatusEnum.Shortfall:
        return <IconTypography text="Shortfall Found" />;
      case SympliSourceAccountStatusEnum.Cleared:
        return <IconTypography hideIcon text="Cleared" />;
      default:
        return null;
    }
  };

  return (
    <>
      <Divider className={classes.divider} />
      <FlexLayout className={classNames(classes.summaryRow, classes.summaryTitle, classes.summaryTitleDesc)} flexDirection="row" alignItems="center">
        Sympli Source Account
      </FlexLayout>
      {depositFormPdfUrl ? (
        <FlexLayout>
          <ButtonLink className={classNames(classes.depositFormLink101)} onClick={() => window.open(depositFormPdfUrl)}>
            Deposit Form
          </ButtonLink>
        </FlexLayout>
      ) : null}
      {renderBalanceStatus()}
      <FlexLayout className={classNames(classes.summaryRow)} justifyContent="space-between">
        <div>Account Balance:</div>
        <div>{verifiedAmountText}</div>
      </FlexLayout>
      <FlexLayout className={classNames(classes.summaryRow)} justifyContent="space-between">
        <div>Amount Required:</div>
        <div>{expectedAmountText}</div>
      </FlexLayout>
      <SympliSourceAccountBalanceText //
        balanceAmount={verifiedAmount}
        amountRequired={expectedAmount}
        status={sympliSourceAccountStatus}
      />
    </>
  );
}

export default React.memo(SympliSourceAccountCalculator);
