import { CaveatParty } from '../../models';

export const resolveDefaultItem = () => {
  const defaultItem: CaveatParty = {
    // id: resolveUuid('string', 20) as string,
    // partyType: null,
    // firstName: '',
    // lastName: '',
    // organisationName: '',
    // organisationType: null,
    // administrationStatus: null,
    // abn: '',
    // acn: '',
    // arbn: '',
    // acln: '',
    // nameSuffix: null,
    // //nameSuffixValue null, // replaced by nameSuffix
    // address: {
    //   ...newAddressDetails
    // },

    // custom attributes to support address/client book
    partyBookId: null, // reference to party store
    addressBookId: null // reference to address store;
  };
  return defaultItem;
};
