import { createAction, createAsyncAction } from 'typesafe-actions';

import { AxiosError } from 'src/utils/http';
import { StampDutyApiResponse } from './models';

export interface FetchStampDutyDetailRequestModel {
  workspaceId: string;
  participantId: string;
}

export const actionFetchStampDutyDetails = createAsyncAction(
  //
  'FETCH_STAMP_DUTY_LIST',
  'FETCH_STAMP_DUTY_LIST_SUCCESS',
  'FETCH_STAMP_DUTY_LIST_ERROR'
)<
  //
  FetchStampDutyDetailRequestModel,
  { data: StampDutyApiResponse },
  { error: AxiosError }
>();

export const actionUpdateStampDutyDetails = createAction(
  //
  'UPDATE_STAMP_DUTY_DETAILS'
)<
  //
  Partial<StampDutyApiResponse>
>();
