import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';
import { colors } from '@sympli/ui-framework/theme';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...dataBindingHelper()
    },
    emptySectionPadding: {
      padding: `20px 20px`
    },
    error: {
      color: colors.RED
    }
  });

export type ClassKeys = typeof styles;

export default styles;
