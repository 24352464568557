import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: 28,
      paddingBottom: 40,
      flexGrow: 1
    },
    greyBackground: {
      background: theme.palette.grey[50]
    },
    header: {
      paddingTop: 9,
      paddingRight: 4
    },
    message: {
      fontSize: 16,
      lineHeight: '19px',
      maxWidth: 505,
      paddingTop: 4,
      paddingLeft: 40,
      paddingRight: 40,
      textAlign: 'center'
    },
    actionButton: {
      marginTop: 16,
      '& + $actionButton': {
        marginLeft: 20
      }
    }
  }),
  {
    name: 'NoTasks'
  }
);
