import { createSelector } from 'reselect';

import { DocumentTypeIdentifierEnum, LodgementCaseStatusEnum, ParticipantStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import { LinkedWorkspaceRowDetailModel, LinkedWorkspaceRowItem, LinkedWorkspaceRowOverviewModel } from './models';

interface ComponentItemsSelectorProps {
  originalItems: LinkedWorkspaceApiResponseModel[];
}
export const resolveComponentItemsSelector = createSelector<
  //
  ComponentItemsSelectorProps,
  LinkedWorkspaceApiResponseModel[],
  LinkedWorkspaceRowItem[]
>(
  ({ originalItems }) => originalItems,
  (originalItems: LinkedWorkspaceApiResponseModel[]) => {
    const items: LinkedWorkspaceRowItem[] = originalItems.map(
      (
        {
          //
          jurisdiction,
          participants,
          workspaceStatusId,
          documents: originalDocuments,
          titles,
          lodgementCaseStatusId,
          workspaceId,
          sympliId
        }: LinkedWorkspaceApiResponseModel,
        i: number
      ) => {
        // ignore lodgement instructions
        const documents = originalDocuments.filter(item => item.documentTypeId !== DocumentTypeIdentifierEnum.LodgementInstructions);
        let workspaceTypeTitle = '';
        // we receive empty documents in case when invitation has not been accepteds
        if (participants.some(e => e.invitationStatusId !== ParticipantStatusEnum.Pending)) {
          const [firstDocument, ...restOfDocuments] = documents;
          workspaceTypeTitle = `${firstDocument?.documentName}${restOfDocuments.length ? ` (+${restOfDocuments.length})` : ''}`;
        } else {
          workspaceTypeTitle = 'Awaiting invitee';
          workspaceStatusId = WorkspaceStatusEnum.AwaitingInvitee;
          lodgementCaseStatusId = LodgementCaseStatusEnum.AwaitingInvitee;
        }

        const overview: LinkedWorkspaceRowOverviewModel = {
          order: i + 1,
          jurisdictionName: jurisdiction,
          participants,
          workspaceStatusId,
          lodgementCaseStatusId,
          documents,
          workspaceTypeTitle
        };

        const detail: LinkedWorkspaceRowDetailModel = {
          titles,
          participants,
          lodgementCaseStatusId,
          workspaceStatusId,
          documents,
          workspaceId,
          sympliId
        };

        return {
          overview,
          detail
        };
      }
    );
    return items;
  }
);
