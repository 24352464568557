import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  UpdateWorkspaceDirectionsApiResponse,
  WorkspaceDirectionsApiResponse,
  WorkspaceDirectionsCategoriesApiResponse,
  WorkspaceDirectionsOverviewApiResponse,
  WorkspaceDirectionsStatusApiResponse,
  WorkspaceParticipantApiResponse
} from '@sympli/api-gateway/models';
import { DistributionsParticipantModel } from '@sympli/api-gateway/shared';

import { DirectionsApiRequest } from './sagas';

export const actionFetchDirectionsList = createAsyncAction(
  //
  'FETCH_DIRECTIONS_LIST',
  'FETCH_DIRECTIONS_LIST_SUCCESS',
  'FETCH_DIRECTIONS_LIST_ERROR'
)<
  //
  DirectionsApiRequest,
  { data: WorkspaceDirectionsStatusApiResponse },
  { error: Error }
>();

export const actionResetDirectionsList = createAction('RESET_DIRECTIONS_LIST')();

export const actionFetchDirections = createAsyncAction(
  //
  'FETCH_DIRECTIONS',
  'FETCH_DIRECTIONS_SUCCESS',
  'FETCH_DIRECTIONS_ERROR'
)<
  //
  DirectionsApiRequest,
  { data: WorkspaceDirectionsApiResponse },
  { error: Error }
>();

export const actionFetchDirectionsCategories = createAsyncAction(
  //
  'FETCH_DIRECTIONS_CATEGORIES',
  'FETCH_DIRECTIONS_CATEGORIES_SUCCESS',
  'FETCH_DIRECTIONS_CATEGORIES_ERROR'
)<
  //
  { workspaceId: string; participantId: string },
  { data: WorkspaceDirectionsCategoriesApiResponse },
  { error: Error }
>();

export const actionUpdateDirectionsWorkForm = createAction(
  //
  'UPDATE_DIRECTIONS_FORM'
)<
  //
  Partial<UpdateWorkspaceDirectionsApiResponse>
>();

export interface UpdateDirectionsWorkspaceParticipantModel {
  workspaceParticipant: Partial<WorkspaceParticipantApiResponse>;
}

export const actionUpdateDirectionsWorkspaceCurrentParticipantDetail = createAction(
  //
  'UPDATE_DIRECTIONS_WORKSPACE_CURRENT_PARTICIPANT_DETAIL'
)<UpdateDirectionsWorkspaceParticipantModel>();

export const actionUpdateDirectionsListTaaStatus = createAction(
  //
  'UPDATE_DIRECTIONS_LIST_TAA_STATUS'
)<
  //
  Partial<Exclude<WorkspaceDirectionsStatusApiResponse['trustAccountAuthorisationRecordStatuses'], undefined>[number]>
>();

export const actionUpdateDirectionsListPaymentsStatus = createAction(
  //
  'UPDATE_DIRECTIONS_LIST_PAYMENTS_STATUS'
)<
  //
  Partial<WorkspaceDirectionsStatusApiResponse['paymentsStatus']>
>();

export const actionUpdateDirectionsListSourceFundsStatus = createAction(
  //
  'UPDATE_DIRECTIONS_LIST_SOURCE_FUNDS_STATUS'
)<
  //
  Partial<WorkspaceDirectionsStatusApiResponse['sourceFundsStatus']>
>();

export const actionCombinationAllDirectionActionsApprove = createAsyncAction(
  //
  'COMBINATION_ALL_DIRECTION_ACTION_APPROVE',
  'COMBINATION_ALL_DIRECTION_ACTION_APPROVE_SUCCESS',
  'COMBINATION_ALL_DIRECTION_ACTION_APPROVE_ERROR'
)<
  //
  {
    newDirectionForm: Partial<UpdateWorkspaceDirectionsApiResponse>;
    newDistributionsParticipantData: Partial<DistributionsParticipantModel>;
    participantId?: string;
    workspaceId?: string;
  },
  { data: WorkspaceDirectionsOverviewApiResponse },
  { error: Error }
>();
