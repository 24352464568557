import * as React from 'react';

import { FormikProps } from 'formik';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { UserTimeZoneEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import Formik from 'src/components/formik';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { Header } from 'src/components/layout';
import SettingsForm from 'src/containers/settings/SettingsForm';
import { actionFetchProfile } from 'src/containers/shared/auth/actions';
import ProfileImage from 'src/containers/shared/profile-image';
import { ProfileImageModel } from 'src/containers/shared/profile-image/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { modelKey } from 'src/utils/formUtils';
import { useSupportedTimeZoneOptions } from 'src/utils/timezone/timezoneHelper';
import { DEFAULT_USER_PROFILE, UpdateUserProfileApiRequest, UserProfileForm } from './models';
import styles, { ClassKeys } from './styles';
import { getValidationSchema } from './validationSchema';

export interface OwnProps {
  data?: UserProfileModel;
  isLoading: boolean;
  error?: string;
  dispatch: SafeDispatch<Action>;
}

export type Props = OwnProps & WithStyles<ClassKeys>;

const fieldName = modelKey<UserProfileForm>();

class UserProfile extends React.Component<Props> {
  private formikProps: FormikProps<UserProfileForm>;

  get getUserId() {
    const { data } = this.props;
    return data?.userId || '';
  }

  private getInitialValues = (): UserProfileForm => {
    const { data } = this.props;

    if (data == null) {
      return DEFAULT_USER_PROFILE;
    }

    return {
      ...DEFAULT_USER_PROFILE,
      ...data
    };
  };

  render() {
    if (!this.getUserId) {
      return null;
    }

    return (
      <Formik //
        method="put"
        action="/profile"
        getInitialValues={this.getInitialValues}
        validationSchema={getValidationSchema()}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
      >
        {(formikProps: FormikProps<UserProfileForm>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private renderForm = (formikProps: FormikProps<UserProfileForm>) => {
    this.formikProps = formikProps;
    const { isLoading, error } = this.props;
    const timeZoneOptions = useSupportedTimeZoneOptions(formikProps.values.timezone);

    return (
      <SettingsForm formikProps={formikProps} error={error} isLoading={isLoading}>
        {this.renderHeader()}
        {this.renderFormFields(timeZoneOptions)}
      </SettingsForm>
    );
  };

  private renderHeader() {
    return <Header>Personal details</Header>;
  }

  private renderFormFields(timeZoneOptions: LookupEnumModel<keyof typeof UserTimeZoneEnum>[]) {
    const { classes, data } = this.props;
    if (!data) return null;
    return (
      <>
        <Typography variant="subtitle1" className={classes.bottomLine}>
          User account details
        </Typography>
        <FormGroup title="Personal details" fieldAreaDirection="column" className={classes.bottomLineNone}>
          <Field label="Job title (optional)" name={fieldName('jobTitle')} fullWidth={true} component={InputField} />
          <Field label="First name" name={fieldName('firstName')} component={InputField} fullWidth={true} readOnly={true} />
          <Field label="Middle name (if applicable)" name={fieldName('middleName')} fullWidth={true} component={InputField} readOnly={true} />
          <Field label="Last name" name={fieldName('lastName')} component={InputField} fullWidth={true} readOnly={true} />
          <Field label="Email" name={fieldName('email')} component={InputField} fullWidth={true} readOnly={true} />
        </FormGroup>
        <Typography variant="subtitle1" className={classes.bottomLine}>
          User profile preferences
        </Typography>
        <FormGroup title="Contact details (optional)" fieldAreaJustify="flex-start" classes={{ contentContainer: classes.phoneFormGroupContentContainer }}>
          <Field label="Mobile" name={fieldName('mobileNumber')} className={classes.flexGrow} component={InputField} placeholder="Optional" readOnly={data.isSSOEnabled} />
          <Field label="Work phone" name={fieldName('work')} className={classes.flexGrow} component={InputField} placeholder="Optional" readOnly={data.isSSOEnabled} />
        </FormGroup>
        <FormGroup title="Preferences">
          <Field
            component={SelectField}
            options={timeZoneOptions}
            placeholder="Please select"
            label="Local time zone"
            name={fieldName('timezone')}
            fullWidth={true}
            readOnly={data.isSSOEnabled}
          />
        </FormGroup>
        {this.renderProfileImage()}
      </>
    );
  }

  private renderProfileImage() {
    const { avatarUrl } = this.getInitialValues();

    return <ProfileImage avatarUrl={avatarUrl} onDeleteImage={this.handleOnDeleteProfileImage} onImageUploaded={this.handleOnImageUploaded} />;
  }

  private handleOnDeleteProfileImage = () => {
    const { setValues, values } = this.formikProps;
    setValues({
      ...values,
      avatarUrl: '',
      profileImage: undefined
    });
  };

  private handleOnImageUploaded = (profileImage: ProfileImageModel) => {
    const { setFieldValue } = this.formikProps;

    setFieldValue(fieldName('profileImage'), profileImage);
  };

  private handleOnPreSubmit = (values: UserProfileForm): UpdateUserProfileApiRequest => {
    return {
      jobTitle: values.jobTitle,
      mobileNumber: values.mobileNumber,
      work: values.work,
      timezone: values.timezone,
      avatarUrl: values.avatarUrl,
      profileImage: values.profileImage
    };
  };

  private handleOnPostSubmit = ({ error, formikHelpers, formValues }: FormikPostSubmitArgs<UserProfileForm>) => {
    if (!error) {
      formikHelpers.resetForm({ values: formValues });
      this.props.dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: 'Personal details updated successfully',
          variant: 'new-success'
        })
      );
      this.props.dispatch(actionFetchProfile.request());
    }
  };
}

export default withStyles(styles)(UserProfile);
