import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WORKSPACE_DETAIL_PAGE_ALL_DIRECTIONS } from 'src/containers/router/constants';
import { AllDirectionsState, useAllDirections } from 'src/containers/workspace/financial/all-directions/reducer';
import { DirectionsListState, useDirectionsList } from 'src/containers/workspace/financial/directions/reducers/directionsList';
import { useWorkspaceDetail, WorkspaceDetailState } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants, WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useRouterPathMath, useSafeDispatch } from 'src/hooks';
import NewAllDirectionsItem from './NewAllDirectionsItem';

interface Props {
  workspaceId: string;
  currentParticipantId: string;
}

function AllDirectionsItemContainer(props: Props) {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceDetailState: WorkspaceDetailState = useWorkspaceDetail(props.workspaceId, props.currentParticipantId);
  const workspaceParticipantsState: WorkspaceParticipantsState = useWorkspaceParticipants(props.workspaceId);
  const allDirectionsState: AllDirectionsState = useAllDirections(props.workspaceId, props.currentParticipantId);
  const directionsListState: DirectionsListState = useDirectionsList(props.workspaceId, props.currentParticipantId);
  const isAllDirectionPage = useRouterPathMath(WORKSPACE_DETAIL_PAGE_ALL_DIRECTIONS);

  return (
    <NewAllDirectionsItem //
      {...props}
      dispatch={dispatch}
      workspaceDetailState={workspaceDetailState}
      workspaceParticipantsState={workspaceParticipantsState}
      allDirectionsState={allDirectionsState}
      navigate={navigate}
      data={directionsListState.detail!}
      isAllDirectionPage={isAllDirectionPage}
    />
  );
}

export default React.memo(AllDirectionsItemContainer);
