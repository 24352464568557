import { Action, createReducer } from 'typesafe-actions';

import {
  actionFetchMessageHistory,
  actionInitialiseChatData,
  actionReceiveNewMessage,
  actionReceiveOnlineStatus,
  actionSwitchChatPerson,
  actionSwitchUserStatus
} from 'src/actions/chat';
import { MessageApiResponse } from 'src/components/chat-window/models';
import { OnlineStatusEnum } from 'src/components/online-status-icon/models';

export interface ChatState {
  channelId: string;
  selectedPersonId?: string;
  history: { [sourceId: string]: Array<MessageApiResponse> };
  onlineStatuses: { [id: string]: OnlineStatusEnum };
}

export interface MessagePayload {
  roomId: string;
  message: MessageApiResponse;
}

const initialState: ChatState = {
  channelId: '',
  history: {},
  onlineStatuses: {}
};

const reducer = createReducer<
  //
  ChatState,
  Action
>(initialState)
  .handleAction(actionReceiveNewMessage, (state, action): ChatState => {
    const { roomId = 'unknow_room', message } = action.payload;

    if (!message) {
      return state;
    }

    if (!state.history[roomId]) {
      return {
        ...state,
        history: {
          ...state.history,
          [roomId]: [message]
        }
      };
    }

    return {
      ...state,
      history: {
        ...state.history,
        [roomId]: state.history[roomId].concat(message)
      }
    };
  })
  .handleAction(actionFetchMessageHistory.success, (state, action): ChatState => {
    const { roomId = 'unknow_room', messageHistory } = action.payload;

    if (!messageHistory) {
      return state;
    }

    if (!state.history[roomId]) {
      return {
        ...state,
        history: {
          ...state.history,
          [roomId]: messageHistory.reverse()
        }
      };
    }

    return {
      ...state,
      history: {
        ...state.history,
        [roomId]: messageHistory.reverse().concat(state.history[roomId])
      }
    };
  })
  .handleAction(actionReceiveOnlineStatus, (state, action): ChatState => {
    const { onlineStatusArray } = action.payload;
    const onlineStatusMap = onlineStatusArray.reduce((map, status) => {
      map[status] = OnlineStatusEnum.Online;
      return map;
      // tslint:disable-next-line:align
    }, {});
    return {
      ...state,
      onlineStatuses: onlineStatusMap
    };
  })
  .handleAction(actionSwitchUserStatus, (state, action): ChatState => {
    const { id, status } = action.payload;
    return {
      ...state,
      onlineStatuses: {
        ...state.onlineStatuses,
        [id]: status
      }
    };
  })
  .handleAction(actionSwitchChatPerson, (state, action): ChatState => {
    const { selectedPersonId } = action.payload;
    return {
      ...state,
      selectedPersonId: selectedPersonId
    };
  })
  .handleAction(actionInitialiseChatData, (state, action): ChatState => {
    const { channelId } = action.payload;
    return {
      ...initialState,
      selectedPersonId: state.selectedPersonId,
      channelId
    };
  });

export default reducer;
