import { FormikState, FormikValues } from 'formik';
import _get from 'lodash-es/get';
import randomize from 'randomatic';

const UUID_STRING_PATTERN = 'A0'; // upper-case alpha and numeric characters only
const UUID_NUMBER_PATTERN = '0'; // numeric characters only

export function resolveUuid(type: 'string', length: number, pattern?: 'Aa0' | 'A0' | 'a0'): string; // see https://www.npmjs.com/package/randomatic#pattern
export function resolveUuid(type: 'number', length: number, pattern?: 'Aa0' | 'A0' | 'a0'): number;
export function resolveUuid(type: 'string' | 'number', length: number, pattern: 'Aa0' | 'A0' | 'a0' = UUID_STRING_PATTERN): string | number {
  if (type === 'number') {
    return Number(randomize(UUID_NUMBER_PATTERN, length)); // explicitly use numbers only
  }

  return randomize(pattern, length);
}

export type MinimalFormikState<T> = Pick<FormikState<T>, 'values' | 'errors' | 'touched'>;

export function getFormikError<T = FormikValues>({ touched, errors }: MinimalFormikState<T>, path: string, skipTouchedCheck = false) {
  if (skipTouchedCheck || _get(touched, path)) {
    const error = _get(errors, path);
    if (typeof error === 'string') {
      return error;
    }
  }
  return;
}

export const resolveLabel = (label: string, isRequired: boolean): string => (isRequired ? label : `${label} (optional)`);
export const resolvePlaceholder = (isRequired = false): string | undefined => (isRequired ? undefined : `Optional`);
export const resolveSelectPlaceholder = (isRequired = false): string => (isRequired ? 'Please select' : 'Optional');

type ContextCheckFunction = (...paramArray: any[]) => boolean;

/**
 * Key of property attached to context check functions, indicating that final parameter to be supplied is the root context.
 */
export const USES_ROOT_SYMBOL = Symbol('usesRoot');

export type ContextCheck = ContextCheckFunction & {
  [USES_ROOT_SYMBOL]?: boolean;
};

export const createContextCheck = <TCheckFunction extends ContextCheckFunction>(fn: TCheckFunction): TCheckFunction & { [USES_ROOT_SYMBOL]: false } => {
  (fn as any as ContextCheck)[USES_ROOT_SYMBOL] = false;
  return fn as any;
};

/** Used to create a pass-through function that Typescript can use to ensure that field names belong to a model (or, that keys belong to an object type). */
export const modelKey =
  <TModel>() =>
  <TFieldName extends keyof TModel & string>(passThroughKey: TFieldName): TFieldName =>
    passThroughKey;

export const createModelKeyAppender =
  <TModel, TParent = undefined, TPrefix = TParent extends undefined ? string : keyof TParent>(prefix: TPrefix) =>
  <TFieldName extends keyof TModel & string>(key: TFieldName): string =>
    `${prefix}.${key}`;

export const createArrayItemModelKeyAppender =
  <TArrayItemModel>(prefix: string) =>
  (index: number, key: keyof TArrayItemModel): string =>
    `${prefix}[${index}].${String(key)}`;
