import * as React from 'react';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { ADDRESS_BOOK_KEY, ApplicantAddressModel } from '../../models';

interface Props {
  name: string;
  sharedAddressFormConfig: AddressFieldFormConfig;
  sharedInitialValuesForNew: AddressFormValueModel<InitialValuesAddressEntityModel>;
}

function ApplicantAddress({ name, sharedAddressFormConfig, sharedInitialValuesForNew }: Props) {
  const { disabled, dialogPortalId } = useDocumentContext();

  const fieldName = createModelKeyAppender<ApplicantAddressModel>(name);

  return (
    <Section title="Applicant Address">
      <FormGroup title="Applicant address" description="All applicants must have the same service of notice address" fieldAreaDirection="column" paddingBottom>
        <AddressSelectField
          name={fieldName('addressBookId')}
          disabled={disabled}
          bookRef={ADDRESS_BOOK_KEY}
          dialogPortalId={dialogPortalId}
          sharedAddressFormConfig={sharedAddressFormConfig}
          sharedInitialValuesForNew={sharedInitialValuesForNew}
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(ApplicantAddress);
