import queryString from 'query-string';
import { call, put, takeLatest } from 'typed-redux-saga';

import environments from 'src/environments';
import http from 'src/utils/http';
import {
  actionFetchGroupMembers,
  actionFetchSingleGroupMembers,
  actionFetchSubscriberAssignableGroups,
  actionFetchSupportedJurisdictions,
  actionFetchUserJurisdictions,
  actionFetchWorkspaceAssignableGroups,
  actionFetchWorkspaceGroups
} from './actions';
import {
  SingleGroupMembersRequestModel,
  SubscriberAssignableGroupsApiResponse,
  WorkspaceAssignableGroupsApiResponse,
  WorkspaceGroupsApiResponse,
  WorkspaceGroupRequestModel as WorkspaceGroupsRequestModel
} from './models';
import { GroupMembersApiResponse } from './reducers/groupMembers';
import { SupportedJurisdictionApiResponse } from './reducers/supportedJurisdictions';
import { UserJurisdictionsAPIResponse } from './reducers/userJurisdictions';

const fetchSupportedJurisdictions = () => {
  return http.get<SupportedJurisdictionApiResponse>('/workspaces/supported-jurisdictions');
};

export function* sagaFetchSupportedJurisdictions() {
  try {
    const data = yield* call(fetchSupportedJurisdictions);
    yield put(actionFetchSupportedJurisdictions.success({ data }));
  } catch (error) {
    yield put(actionFetchSupportedJurisdictions.failure({ error }));
  }
}

const fetchWorkspaceAssignableGroups = () => {
  return http.get<WorkspaceAssignableGroupsApiResponse>('/workspaces/assignable-groups');
};

export function* sagaFetchWorkspaceAssignableGroups() {
  try {
    const data = yield* call(fetchWorkspaceAssignableGroups);
    yield put(actionFetchWorkspaceAssignableGroups.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspaceAssignableGroups.failure({ error }));
  }
}

const fetchWorkspaceGroups = (payload?: WorkspaceGroupsRequestModel) => {
  let uri = '/workspaces/groups';
  if (payload) {
    const query = queryString.stringify(payload);
    return http.get<WorkspaceGroupsApiResponse>(uri + '?' + query);
  }
  return http.get<WorkspaceGroupsApiResponse>(uri);
};

export function* sagaFetchWorkspaceGroups(action: ReturnType<typeof actionFetchWorkspaceGroups.request>) {
  try {
    const data = yield* call(fetchWorkspaceGroups, action.payload);
    yield put(actionFetchWorkspaceGroups.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspaceGroups.failure({ error }));
  }
}

const fetchSubscriberAssignGroups = () => {
  return http.get<SubscriberAssignableGroupsApiResponse>('/workspaces/subscriber-assignable-groups');
};

export function* sagaFetchSubscriberAssignGroups() {
  try {
    const data = yield* call(fetchSubscriberAssignGroups);
    yield put(actionFetchSubscriberAssignableGroups.success({ data }));
  } catch (error) {
    yield put(actionFetchSubscriberAssignableGroups.failure({ error }));
  }
}

const fetchUserJurisdictions = () => {
  return http.get<UserJurisdictionsAPIResponse>('dashboards/user-jurisdictions');
};

export function* sagaFetchUserJurisdictions() {
  try {
    const data = yield* call(fetchUserJurisdictions);
    yield put(actionFetchUserJurisdictions.success({ data }));
  } catch (error) {
    yield put(actionFetchUserJurisdictions.failure({ error }));
  }
}

// v2 endpoint for the group members
const { TARGET_MACHINE, TARGET_API_V2 } = environments;

const fetchGroupMembers = (payload?: SingleGroupMembersRequestModel) => {
  const uri = '/dashboards/group-users';

  if (payload) {
    const query = queryString.stringify(payload);

    return http.get<GroupMembersApiResponse>(uri + '?' + query, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
  }

  return http.get<GroupMembersApiResponse>(uri, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};

export function* sagaFetchGroupMembers() {
  try {
    const data = yield* call(fetchGroupMembers);
    yield put(actionFetchGroupMembers.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupMembers.failure({ error }));
  }
}

export function* sagaFetchSingleGroupMembers(action: ReturnType<typeof actionFetchSingleGroupMembers.request>) {
  try {
    const data = yield* call(fetchGroupMembers, action.payload);
    yield put(actionFetchSingleGroupMembers.success({ data }));
  } catch (error) {
    yield put(actionFetchSingleGroupMembers.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchSupportedJurisdictions.request, sagaFetchSupportedJurisdictions),
  takeLatest(actionFetchWorkspaceAssignableGroups.request, sagaFetchWorkspaceAssignableGroups),
  takeLatest(actionFetchWorkspaceGroups.request, sagaFetchWorkspaceGroups),

  takeLatest(actionFetchSubscriberAssignableGroups.request, sagaFetchSubscriberAssignGroups),
  takeLatest(actionFetchUserJurisdictions.request, sagaFetchUserJurisdictions),
  takeLatest(actionFetchGroupMembers.request, sagaFetchGroupMembers),
  takeLatest(actionFetchSingleGroupMembers.request, sagaFetchSingleGroupMembers)
];
