import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DateOfDeathModel } from '../../models';
import { DateOfDeathTypeEnum } from './enums';

export const VISIBILITY_CHECK_DATE_OF_DEATH_DATE = createContextCheck(
  (context?: DateOfDeathModel): boolean => context !== undefined && ![DateOfDeathTypeEnum.DateRange, DateOfDeathTypeEnum.DateDescription].includes(context.type!)
);

export const VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE = createContextCheck(
  (context?: DateOfDeathModel): boolean => context !== undefined && context.type === DateOfDeathTypeEnum.DateRange
);

export const VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION = createContextCheck(
  (context?: DateOfDeathModel): boolean => context !== undefined && context.type === DateOfDeathTypeEnum.DateDescription
);
