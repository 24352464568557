import * as React from 'react';

import classNames from 'classnames';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { SympliSourceAccountStatusEnum } from '../../models';
import { resolveBalanceText101 as resolveBalanceText } from './helper';
import useStyles from './styles';

interface Props {
  balanceAmount: number;
  amountRequired: number;
  status: SympliSourceAccountStatusEnum;
}

function SympliSourceAccountBalanceText({ balanceAmount, amountRequired, status }: Props) {
  const classes = useStyles();

  const { css, value } = resolveBalanceText(balanceAmount, amountRequired, status, classes);

  return (
    status !== SympliSourceAccountStatusEnum.Unverified && (
      <FlexLayout className={classNames(classes.summaryRow, classes.lastSummaryRow, classes.halfMarginLeft)} justifyContent="flex-end">
        <div className={css}>{value}</div>
      </FlexLayout>
    )
  );
}

export default React.memo(SympliSourceAccountBalanceText);
