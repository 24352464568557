import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchBillingInvoicing } from '../actions/billingInvoicing';
import { BillingInvoicingApiResponse } from '../billing-invoicing-detail/models';

export const billingInvoicingUrl = '/settings/subscriber/billing-invoicing';

const fetchBillingInvoicing = () => http.get<BillingInvoicingApiResponse>(billingInvoicingUrl);

function* sagaFetchBillingInvoicing() {
  try {
    const data = yield* call(fetchBillingInvoicing);
    yield put(actionFetchBillingInvoicing.success({ data }));
  } catch (error) {
    yield put(actionFetchBillingInvoicing.failure({ error }));
  }
}

const billingInvoicing = [takeLatest(actionFetchBillingInvoicing.request, sagaFetchBillingInvoicing)];

export default billingInvoicing;
