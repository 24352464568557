import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { resolveTitleReferenceItemSchema } from '../../../../components/title-reference/validationSchema';
import { getCaveatsBeingWithdrawn, getSelectedTitleReferences } from '../../helper';
import { BaseTitleReferenceModel, WithdrawalOfCaveat2_26_0Model } from '../../models';

// path: titleReferences
const yupTitleReferenceItem = resolveTitleReferenceItemSchema<BaseTitleReferenceModel, WithdrawalOfCaveat2_26_0Model>(false);
const yupTitleReferences = resolveTitleReferencesValidationSchema<BaseTitleReferenceModel, WithdrawalOfCaveat2_26_0Model>(yupTitleReferenceItem);

// if two or more are selected, then all title should have the same caveat
export default yupTitleReferences.testContextualRule({
  uniqueTestName: 'titleReferences must contain one common caveat',
  onlyIf: (context: WithdrawalOfCaveat2_26_0Model): boolean => {
    const selectedTitles = getSelectedTitleReferences(context.titleReferences);
    return selectedTitles.length > 1;
  },
  requirement: (context: WithdrawalOfCaveat2_26_0Model): boolean => {
    const selectedTitles = getSelectedTitleReferences(context.titleReferences);
    // verify that caveats share some common title reference
    return getCaveatsBeingWithdrawn(context.caveats, selectedTitles).length > 0;
  },
  message: 'There are no common caveats across selected titles'
});
