import dateFormat from 'dateformat';
import _isEqual from 'lodash-es/isEqual';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';

import { convertPartiesToFormModel } from '../../helpers';
import { NSW_NOD_PARTY_FORM_CONFIG } from './config';
import { DateOfDeathTypeEnum, EvidenceTypeValueEnum } from './enums';
import { DateOfDeathModel, DeceasedJointTenantsModel, DeceasedProprietorModel, EvidenceModel, SurvivingJointTenantModel, TitleReferenceModel } from './models';

const allEqual = <T>(array: T[]) => new Set(array).size === 1;

export const DEFAULT_DEATH_OF_DATE: DateOfDeathModel = { dateOfDeathType: null, deathDate: null, fromDate: null, toDate: null, dateDescription: '' };
export const DEFAULT_EVIDENCE: EvidenceModel = { evidenceDate: null, evidenceTypeValue: null, evidenceDocumentReference: '' };

export const filterDeceasedProprietorGroups = (titleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]): DeceasedJointTenantsModel[] => {
  const selectedTitleReferences = titleReferences.filter(r => r.isSelected);
  return combineDeceasedJointTenantsFromSelectedTitleReferences(selectedTitleReferences, partyBook);
};

export const filterDeceasedProprietors = (groups: DeceasedJointTenantsModel[]): DeceasedProprietorModel[] => {
  const proprietors = groups.flatMap(group => group.deceasedProprietors);
  return proprietors;
};

export function getPartyNameAndTypes(partyBookIds: string[], partyBook: PartyModel<NswNameChange>[]): string[] {
  return partyBookIds.map(partyBookId => {
    const party = partyBook.find(x => x.id === partyBookId)!;
    const legalEntityName = NODformatPartyName(party);
    return `${legalEntityName.trim().toUpperCase()} - ${getPartyType(party)}`;
  });
}

function NODformatPartyName(partyBook: PartyModel<NswNameChange>): string {
  const { partyType, firstName, lastName, organisationName, receivingOrRelinquishingDetails, isSingleName } = partyBook;
  if (receivingOrRelinquishingDetails.isRelinquishing && receivingOrRelinquishingDetails.isChangingName && receivingOrRelinquishingDetails.nameChange) {
    const { nameChange } = receivingOrRelinquishingDetails;
    return getPartyName(partyType, nameChange.isSingleName, nameChange.organisationName, nameChange.firstName, nameChange.lastName);
  }
  return getPartyName(partyType, isSingleName, organisationName, firstName, lastName);
}

const getPartyName = (partyType: PartyTypeEnum, isSingleName: boolean, organisationName: string, firstName: string, lastName = ''): string => {
  if (partyType === PartyTypeEnum.Organisation) {
    return organisationName.trim();
  } else {
    return isSingleName ? firstName.trim() : `${firstName.trim()} ${lastName.trim()}`;
  }
};

export function combineDeceasedJointTenantsFromSelectedTitleReferences(
  selectedTitleReferences: TitleReferenceModel[],
  partyBook: PartyModel<NswNameChange>[]
): DeceasedJointTenantsModel[] {
  if (selectedTitleReferences.length === 0) {
    return [];
  }

  if (selectedTitleReferences.length === 1) {
    const { deceasedJointTenants } = { ...selectedTitleReferences[0] };
    const convertedGroups = deceasedJointTenants.map(group => {
      return {
        ...group,
        deceasedProprietors: group.deceasedProprietors.map(proprietor => {
          return {
            ...proprietor,
            deceasedProprietorId: resolveUuid('string', 20)
          };
        })
      };
    });
    return convertedGroups;
  }
  const mergedProprietorGroups = mergeSelectedTitlesProprietorGroup(selectedTitleReferences, partyBook);
  return mergedProprietorGroups;
}

export const titlesHasSameNumberOfProprietorGroups = (selectedTitleReferences: TitleReferenceModel[]) =>
  allEqual(
    selectedTitleReferences.flatMap(({ deceasedJointTenants }) => {
      return deceasedJointTenants.length;
    })
  );

export const titlesHasMatchingPartyNameAndPartyType = (selectedTitleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]) => {
  const proprietorGroupsByTitleReference = selectedTitleReferences.map(({ deceasedJointTenants }) => deceasedJointTenants);
  // Check share fractions and parties names by group, must the same across all titles
  const proprietorGroupShareFractionsAndPartyNamesByTitleReference = proprietorGroupsByTitleReference.map(deceasedJointTenants =>
    deceasedJointTenants.map(proprietor => {
      return {
        partyNames: getPartyNameAndTypes(
          proprietor.deceasedProprietors!.map(p => p.partyBookId!),
          partyBook
        )
          //In order to consolidate groups, we need to reorder the list before consolidating, to avoid the error that [1, 2] cannot be consolidated with [2, 1]
          .sort((a, b) => a.localeCompare(b))
      };
    })
  );

  const [firstTitleGroups, ...restTitleGroups] = proprietorGroupShareFractionsAndPartyNamesByTitleReference;
  return restTitleGroups.every(titleGroups => _isEqual(titleGroups, firstTitleGroups));
};

function mergeSelectedTitlesProprietorGroup(selectedTitleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]): DeceasedJointTenantsModel[] {
  let partiesByProprietorGroupsAdded: string[][] = [];
  const mergedProprietorGroups = selectedTitleReferences
    .flatMap(({ deceasedJointTenants }) => [...deceasedJointTenants])
    .reduce((groupArray: DeceasedJointTenantsModel[], group: DeceasedJointTenantsModel) => {
      const incomingPartyIds = group.deceasedProprietors!.map(({ partyBookId }) => partyBookId!);

      // Check if group has been added to lookup
      if (
        !partiesByProprietorGroupsAdded.some(
          existingPartyIds =>
            incomingPartyIds.every(incomingPartyId => existingPartyIds.includes(incomingPartyId)) &&
            existingPartyIds.every(existingPartyId => incomingPartyIds.includes(existingPartyId))
        )
      ) {
        partiesByProprietorGroupsAdded.push(incomingPartyIds);
        groupArray.push({
          ...group,
          deceasedProprietors: group.deceasedProprietors.map(proprietor => {
            return {
              ...proprietor,
              deceasedProprietorId: resolveUuid('string', 20)
            };
          })
        });
      }
      return groupArray;
    }, []);

  if (mergedProprietorGroups.length === 1) {
    mergedProprietorGroups[0].deceasedProprietors.map(proprietor => {
      return {
        ...proprietor,
        isAffectedProprietor: true
      };
    });
  }
  return mergedProprietorGroups;
}

export const getJustifiedRelinquishingProprietorGroupsMap = <NC = {}>(
  deceasedProprietorGroups: DeceasedJointTenantsModel[],
  partyBook: PartyModel<NC>[],
  getPartyNames: (ids: string[], partyBook: PartyModel<NC>[]) => string[]
): Map<string, DeceasedJointTenantsModel[]> => {
  const justifiedRelinquishingProprietorGroupsMap = deceasedProprietorGroups
    .map(proprietorGroup => ({
      partyNames: getPartyNames(
        proprietorGroup.deceasedProprietors!.map(p => p.partyBookId!),
        partyBook
      ).sort(),
      proprietorGroup
    }))
    .reduce((transferorsGroupMap: Map<string, DeceasedJointTenantsModel[]>, { partyNames, proprietorGroup }) => {
      const transferGroupKey = buildDeceasedJoinTenantKey(partyNames);
      if (transferorsGroupMap.has(transferGroupKey)) {
        transferorsGroupMap.get(transferGroupKey)!.push(proprietorGroup);
      } else {
        transferorsGroupMap.set(transferGroupKey, [proprietorGroup]);
      }
      return transferorsGroupMap;
    }, new Map<string, DeceasedJointTenantsModel[]>());

  return justifiedRelinquishingProprietorGroupsMap;
};

export const extractSurvivingJointTenants = (deceasedJointTenants: DeceasedJointTenantsModel[], deceasedProprietorId: string): SurvivingJointTenantModel[] => {
  const selectedJointTenant = deceasedJointTenants.find(groups => groups.deceasedProprietors.find(group => group.isAffectedProprietor));
  if (!selectedJointTenant) return [];
  const unselectedJointTenant = selectedJointTenant.deceasedProprietors.filter(
    proprietor => !proprietor.isAffectedProprietor && proprietor.deceasedProprietorId !== deceasedProprietorId
  );
  const survivingArray: SurvivingJointTenantModel[] = unselectedJointTenant.map(jointTenant => {
    return {
      partyBookId: jointTenant.partyBookId,
      isMakingApplication: unselectedJointTenant.length === 1 ? true : false
    };
  });

  return survivingArray;
};

export const convertDeceasedJointTenantsFromFormToApi = (
  partyIdsByDeceasedJointTenants: string[][],
  deceasedJointTenantMap: Map<string, DeceasedJointTenantsModel>
): DeceasedJointTenantsModel[] => {
  return partyIdsByDeceasedJointTenants.map(partyIds => deceasedJointTenantMap.get(buildDeceasedJoinTenantKey(partyIds))!);
};

const buildDeceasedJoinTenantKey = (keys: string[]) => keys.concat().sort().join(','); // WEB-32796 - make sure the key is consistent

export const getDeceasedJointTenantsMap = (deceasedJointTenants: DeceasedJointTenantsModel[]): Map<string, DeceasedJointTenantsModel> => {
  const deceasedJointTenantsMap = new Map<string, DeceasedJointTenantsModel>();
  for (let deceasedJointTenant of deceasedJointTenants) {
    const relinquishingGroupKey = buildDeceasedJoinTenantKey(deceasedJointTenant.deceasedProprietors.map(p => p.partyBookId));
    deceasedJointTenantsMap.set(relinquishingGroupKey, deceasedJointTenant);
  }
  return deceasedJointTenantsMap;
};

export const cleanUnselectedProprietorsDataFromTitleReference = (selectedTitleReferences: TitleReferenceModel[]): TitleReferenceModel[] => {
  return selectedTitleReferences.map(titleReference => {
    return {
      ...titleReference,
      deceasedJointTenants: titleReference.deceasedJointTenants.map(jointTenant => {
        return {
          ...jointTenant,
          deceasedProprietors: jointTenant.deceasedProprietors.map(deceasedProprietor => {
            const isAffectedProprietor = titleReference.isSelected && deceasedProprietor.isAffectedProprietor;
            const dateOfDeathUpdated = isAffectedProprietor
              ? {
                  ...deceasedProprietor.dateOfDeath!,
                  deathDate:
                    deceasedProprietor.dateOfDeath!.dateOfDeathType === DateOfDeathTypeEnum.ActualDate
                      ? dateFormat(deceasedProprietor.dateOfDeath!.deathDate as Date, DateFormatEnum.DATE)
                      : undefined,
                  fromDate:
                    deceasedProprietor.dateOfDeath!.dateOfDeathType === DateOfDeathTypeEnum.DateRange
                      ? dateFormat(deceasedProprietor.dateOfDeath!.fromDate as Date, DateFormatEnum.DATE)
                      : undefined,
                  toDate:
                    deceasedProprietor.dateOfDeath!.dateOfDeathType === DateOfDeathTypeEnum.DateRange
                      ? dateFormat(deceasedProprietor.dateOfDeath!.toDate as Date, DateFormatEnum.DATE)
                      : undefined
                }
              : undefined;
            return {
              //
              ...deceasedProprietor,
              isAffectedProprietor: isAffectedProprietor,
              dateOfDeath: dateOfDeathUpdated,
              evidence: isAffectedProprietor ? clearEvidenceDateAndReferenceToApiModel(deceasedProprietor.evidence!) : undefined
            };
          })
        };
      })
    };
  });
};

const clearEvidenceDateAndReferenceToApiModel = (evidence: EvidenceModel): EvidenceModel => {
  if (evidence.evidenceTypeValue === EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct) {
    return {
      ...evidence,
      evidenceDate: null,
      evidenceDocumentReference: ''
    };
  }
  return evidence;
};

export function convertPartiesToFormModel2211(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  const uniqueParties = Array.from(
    parties
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.id!)) entryMap.set(e.id!, e);
        return entryMap;
      }, new Map<string, ApiDocumentPartyModel<NswDocumentPartyJustification>>())
      .values()
  );

  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_NOD_PARTY_FORM_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    uniqueParties,
    relinquishingIds
  );
}

export const titlesHasSameTenancyStructureAndNumberOfGroups = (selectedTitleReferences: TitleReferenceModel[]) => {
  return titlesHasSameNumberOfProprietorGroups(selectedTitleReferences) && titlesHasMatchingTenancyType(selectedTitleReferences);
};

export const titlesHasMatchingTenancyType = (selectedTitleReferences: TitleReferenceModel[]) => {
  return allEqual(
    selectedTitleReferences.flatMap(({ deceasedJointTenants }) =>
      deceasedJointTenants.map(({ tenancyType }) => {
        return tenancyType!;
      })
    )
  );
};

export const getPartyType = (party: PartyModel<NswNameChange>) => {
  if (party.readonlyMap?.partyType === false) return 'Indeterminate';
  else return party.partyType.toString();
};
