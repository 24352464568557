import * as yup from 'yup';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { DeceasedTenancyDetailModel } from '../../../../components/deceased-tenancy-detail/models';
import { NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from '../../config';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from '../../models';

interface PartyBookValidationContext {
  deceasedTenancyDetail: DeceasedTenancyDetailModel;
}

const resolveValidationContext = ({ deceasedTenancyDetail }: TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model): PartyBookValidationContext => ({
  deceasedTenancyDetail
});

const checkShouldValidateItem = ({ deceasedTenancyDetail }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => {
  const partySelectedInDeceasedTenancy = deceasedTenancyDetail.proprietorGroups.some(
    ({ parties, isSelected }) => parties.some(e => e.partyBookId === partyIdForCheck) && isSelected
  );
  return partySelectedInDeceasedTenancy;
};

const yupPartyItem: yup.ObjectSchema<PartyModel<NswNameChange>, PartyBookValidationContext> = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(
  NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE
);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
