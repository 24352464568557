import React, { memo } from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

export interface Props {
  dismissMessageHandler(): void;
}

const RefreshIconMessage = ({ dismissMessageHandler }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>DATA REFRESH BUTTON</Typography>
      <Typography className={classNames(classes.text, 'mb-[8px]')}>
        Get the <b>latest updates</b> from the system by simply pressing the refresh button!
      </Typography>
      <Typography className={classes.text}>Nice & easy!</Typography>
      <ButtonBase className={classes.button} onClick={dismissMessageHandler}>
        Dismiss
      </ButtonBase>
    </Box>
  );
};

export default memo(RefreshIconMessage);
