import React from 'react';

import { AuthorityComplianceReportTypeEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { LodgementCase } from '@sympli/api-gateway/shared';
import ButtonLink from '@sympli/ui-framework/components/button-link';

import { AuthorityComplianceReportDisplay, getAuthorityComplianceDisplay } from '../../helper';
import { AuthorityComplianceData, AuthorityLodgementComplianceLogType, WorkspaceActivityLogModel } from '../../models';
import ActivityLogItem from '../activity-log-item';
import { useStyles } from './styles';

interface Props {
  workspaceId: string;
  item: WorkspaceActivityLogModel;
  participantId: string;
  workspaceStatus: WorkspaceStatusEnum;
  onClick: (authorityMessageId?: string, authorityComplianceReportType?: AuthorityComplianceReportTypeEnum, lodgementCaseId?: string) => void;
  dataTestId?: string;
  lodgementCases: LodgementCase[];
}

function AuthorityActivityLogItemContainer({ item, workspaceId, onClick, workspaceStatus, participantId, dataTestId, lodgementCases }: Props) {
  const classes = useStyles();
  const authorityComplianceLog = item.dataObject as AuthorityComplianceData;
  const authorityMessageId = authorityComplianceLog.AuthorityMessageId;
  const lodgementCaseId = authorityComplianceLog.LodgementCaseId ?? (lodgementCases.length === 1 ? lodgementCases[0].id : lodgementCases.find(x => x.isPrimary)?.id);
  const authorityComplianceDisplay =
    getAuthorityComplianceDisplay(item.workspaceLogType, authorityComplianceLog.LodgementCaseName) ?? ({ linkText: item.template } as AuthorityComplianceReportDisplay);
  const authorityComplianceReportType = AuthorityLodgementComplianceLogType.includes(item.workspaceLogType)
    ? AuthorityComplianceReportTypeEnum.Lodgement
    : AuthorityComplianceReportTypeEnum.LodgementVerification;
  if (authorityMessageId) {
    return (
      <ActivityLogItem workspaceId={workspaceId} item={item} participantId={participantId} dataTestId={dataTestId}>
        {workspaceStatus === WorkspaceStatusEnum.Abandoned ? (
          `${authorityComplianceDisplay?.linkText} ${authorityComplianceDisplay?.suffixText}`
        ) : (
          <>
            <ButtonLink className={classes.buttonLink} onClick={() => onClick(authorityMessageId, authorityComplianceReportType, lodgementCaseId)} color="inherit">
              {authorityComplianceDisplay?.linkText}
            </ButtonLink>
            {authorityComplianceDisplay?.suffixText}
          </>
        )}
      </ActivityLogItem>
    );
  }

  return <ActivityLogItem workspaceId={workspaceId} item={item} participantId={participantId} dataTestId={dataTestId} />;
}

export default React.memo(AuthorityActivityLogItemContainer);
