import * as React from 'react';

export function IconAggregatorLoading(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="76" viewBox="0 0 96 76" fill="none">
      <rect x="23" y="66" width="50" height="10" rx="5" fill="#EAEAEB" />
      <rect y="44" width="96" height="16" rx="8" fill="#EAEAEB" />
      <rect x="23" width="50" height="38" rx="8" fill="#EAEAEB" />
    </svg>
  );
}
