// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const sharedStyles = (theme: Theme) =>
  ({
    formControlValidation: {
      position: 'unset',
      marginBottom: 4
    },
    noBottomMargin: {
      marginBottom: 'unset'
    },
    inputFormControlGrid: {
      display: 'grid',
      gridTemplateRows: `auto minmax(${theme.spacing(2.5)}, max-content)`,
      gridTemplateAreas: '"input" "helper"'
    },
    gridAreaInput: {
      gridArea: 'input'
    },
    gridAreaHelper: {
      gridArea: 'helper'
    }
  }) as const;

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...sharedStyles(theme),
    root: {}
  }),
  {
    name: 'TermsAndConditions'
  }
);
