import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    buttonContainer: {
      height: 50,
      minWidth: 200,
      width: '100%',
      backgroundColor: theme.palette.grey[100],
      padding: '0 14px',
      boxSizing: 'border-box'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
