import http, { NoResponseData } from 'src/utils/http';

export interface UpdateExpectedLodgementDateApiRequest {
  participantId: string;
  date: string;
}

export function updateExpectedLodgementDate(workspaceId: string, payload: UpdateExpectedLodgementDateApiRequest) {
  return http.put<NoResponseData>(`/workspaces/${encodeURIComponent(workspaceId)}/expected-lodgement-date`, payload);
}
