import React from 'react';

import Divider from '@mui/material/Divider';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import { MessageNotificationVariant } from '@sympli/ui-framework/components/message-notification';
import MessageGrid, { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import { useStyles } from './styles';

interface Props {
  variant: MessageNotificationVariant;
  messageHeader?: MessageModel['message'];
  remarkHeader?: MessageModel['remark'];
  messages?: MessageModel[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function ExpandableContent({ variant, messageHeader, remarkHeader, messages, onClick }: Props) {
  const classes = useStyles({ variant });

  return (
    <div className={classes.wrapper}>
      <MessageGrid //
        variant={variant}
        messageHeader={messageHeader}
        remarkHeader={remarkHeader}
        messages={messages}
      />
      <Divider className={classes.divider} />

      <div className={classes.assistanceFooter}>
        <ButtonLink onClick={onClick} color="inherit">
          Show verification results
        </ButtonLink>
        <br />
        If you require further assistance please contact customer support on <strong>1300 SYMPLI (1300 796 754)</strong>.
      </div>
    </div>
  );
}

export default ExpandableContent;
