import { createAsyncAction } from 'typesafe-actions';

import { WorkspaceFilesApiResponse } from '../workspace-files/models';

export interface WorkspaceFilesApiRequest {
  workspaceId: string;
  participantId: string;
}

export interface DeleteFileApiRequest {
  workspaceId: string;
  participantId: string;
  fileId: string;
}

export const actionFetchWorkspaceFiles = createAsyncAction(
  //
  'FETCH_WORKSPACE_FILES',
  'FETCH_WORKSPACE_FILES_SUCCESS',
  'FETCH_WORKSPACE_FILES_ERROR'
)<
  //
  WorkspaceFilesApiRequest,
  { items: WorkspaceFilesApiResponse[] },
  { error: Error }
>();

export const actionRemoveFileWithIssue = createAsyncAction(
  //
  'REMOVE_FILE_WITH_ISSUE',
  'REMOVE_FILE_WITH_ISSUE_SUCCESS',
  'REMOVE_FILE_WITH_ISSUE_ERROR'
)<
  //
  DeleteFileApiRequest,
  undefined,
  { error: Error }
>();
