import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ButtonLink, { ButtonLinkProps } from '@sympli/ui-framework/components/button-link';

import styles, { ClassKeys } from './styles';

interface OwnProps extends ButtonLinkProps {
  onClick: () => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class AddItemButton extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, className, onClick, children, ...rest } = this.props;

    return (
      <ButtonLink icon="add" className={classNames(classes.root, className)} onClick={this.handleOnClick} {...rest} color="inherit">
        {children}
      </ButtonLink>
    );
  }

  private handleOnClick = e => {
    e.preventDefault();
    this.props.onClick();
  };
}

export default withStyles(styles)(AddItemButton);
