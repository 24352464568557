import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchPreferences } from '../actions/preferences';
import { PreferencesApiResponse } from '../models';

const fetchPreferences = () => http.get<PreferencesApiResponse>('/settings/subscriber/preferences');

function* sagaFetchPreferences() {
  try {
    const data = yield* call(fetchPreferences);
    yield put(actionFetchPreferences.success({ data }));
  } catch (error) {
    yield put(actionFetchPreferences.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchPreferences.request, sagaFetchPreferences)
];
