import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import {
  actionFetchAutoJustificationDetail,
  actionFetchChangeOfNameEvidence as actionFetchAutoJustificationEvidence,
  actionFetchAutoJustifications,
  AutoJustificationDetailRequest,
  AutoJustificationEvidenceRequest
} from '../actions/autoJustification';
import { SubscriberNameJustificationsApiResponse } from '../auto-justifications/dashboard/models';
import {
  AutoJustificationSupportedDocumentsApiResponse,
  ChangeOfNameEvidenceApiResponse,
  ChangeOfNameEvidenceOptionsApiResponse,
  SubscriberNameJustificationDetailApiResponse
} from '../auto-justifications/detail/models';
import { CompanyProfileApiResponse } from '../models';

const fetchAutoJustifications = () => http.get<SubscriberNameJustificationsApiResponse>(`/settings/auto-justifications`);

export function* sagaFetchAutoJustifications(action: ReturnType<typeof actionFetchAutoJustifications.request>) {
  try {
    const data = yield* call(fetchAutoJustifications);
    yield put(actionFetchAutoJustifications.success({ data }));
  } catch (error) {
    yield put(actionFetchAutoJustifications.failure({ error }));
  }
}
const fetchAutoJustificationDetail = (justificationId: AutoJustificationDetailRequest) =>
  Promise.all([
    justificationId !== 'new' ? http.get<SubscriberNameJustificationDetailApiResponse>(`/settings/auto-justification/${encodeURIComponent(justificationId)}/detail`) : undefined,
    http.get<AutoJustificationSupportedDocumentsApiResponse>(`/settings/auto-justification/supported-documents`),
    http.get<CompanyProfileApiResponse>('/settings/subscriber/details')
  ]);

export function* sagaFetchAutoJustificationDetail(action: ReturnType<typeof actionFetchAutoJustificationDetail.request>) {
  try {
    const data: [SubscriberNameJustificationDetailApiResponse | undefined, AutoJustificationSupportedDocumentsApiResponse, CompanyProfileApiResponse] = yield* call(
      fetchAutoJustificationDetail,
      action.payload
    );
    yield put(actionFetchAutoJustificationDetail.success({ data }));
  } catch (error) {
    yield put(actionFetchAutoJustificationDetail.failure({ error }));
  }
}

const fetchAutoJustificationEvidence = (justificationId: AutoJustificationEvidenceRequest) =>
  Promise.all([
    http.get<ChangeOfNameEvidenceApiResponse>(`/settings/auto-justification/${encodeURIComponent(justificationId)}/evidences`),
    http.get<ChangeOfNameEvidenceOptionsApiResponse>(`/settings/auto-justification/definitions/evidences`)
  ]);

export function* sagaFetchAutoJustificationEvidence(action: ReturnType<typeof actionFetchAutoJustificationEvidence.request>) {
  try {
    const data: [ChangeOfNameEvidenceApiResponse, ChangeOfNameEvidenceOptionsApiResponse] = yield* call(fetchAutoJustificationEvidence, action.payload);
    yield put(actionFetchAutoJustificationEvidence.success({ data }));
  } catch (error) {
    yield put(actionFetchAutoJustificationEvidence.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchAutoJustifications.request, sagaFetchAutoJustifications),
  takeLatest(actionFetchAutoJustificationDetail.request, sagaFetchAutoJustificationDetail),
  takeLatest(actionFetchAutoJustificationEvidence.request, sagaFetchAutoJustificationEvidence)
];
