import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    suspendIcon: {
      width: 14,
      height: 14,
      paddingRight: 6,
      fill: theme.palette.primary.main
    },
    paddingLeft: {
      paddingLeft: 20
    }
  });

export type ClassKeys = typeof styles;

export default styles;
