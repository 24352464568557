import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import PartyForm from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG, PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG } from '../../config';
import { Mortgage2_21_1Model, MortgagorPartyModel } from '../../models';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

const rootFieldName = modelKey<Mortgage2_21_1Model>();

function MortgagorPartyReadonlyArray({ name, className, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<Mortgage2_21_1Model>();
  const { partyBook, isFinancialWorkspace } = values;

  const partyFormConfig = React.useMemo(() => {
    return isFinancialWorkspace ? PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG : PARTY_FORM_WITH_NAME_CHANGE_CONFIG;
  }, [isFinancialWorkspace]);

  const renderItem = useCallback(
    //
    ({ item }: DocumentArrayItemRenderProps<MortgagorPartyModel>) => {
      // we will be doing updates directly to partyBook
      // find the index of current mortgagor within partyBook
      const bookIndexOfPartyId = partyBook.findIndex(party => party.id === item.partyBookId);
      const itemBinding = `${rootFieldName('partyBook')}[${bookIndexOfPartyId}]`;

      return (
        <Subform //
          subformBindingPath={itemBinding}
          component={PartyForm}
          disabled={disabled}
          config={partyFormConfig}
          showFormGroups={false}
          focusRef={focusRef}
        />
      );
    },
    [partyBook, disabled, partyFormConfig, focusRef]
  );

  return (
    <DocumentFieldArray //
      shouldMemoizeContext={false}
      arrayBinding={name}
      renderItem={renderItem}
      itemTitle={'Mortgagor'}
      disabled={disabled}
      itemStyle="formGroup"
      mode="fixed"
    />
  );
}

export default React.memo(MortgagorPartyReadonlyArray);
