import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { ApplicantModel } from '../../models';
import { VISIBILITY_CHECK_APPLICANTS } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: applicants
const yupApplicants = yup.mixed<ApplicantModel[]>().test(
  validateWhenVisible({
    isObjectOrArray: true,
    visibilityCheck: VISIBILITY_CHECK_APPLICANTS,
    validationWhenVisible: yup
      .array<ApplicantModel>()
      .defined()
      .required(msg.REQUIRED)
      .min(1, msg.MIN_ITEMS(1))
      .max(20, msg.MAX_ITEMS(20))
      .of(
        yup
          .object<ApplicantModel>()
          .nullable()
          .shape({
            partyBookId: yup.string().default('').trim().required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(NSW_PARTY_FORM_CONFIG))
          })
      )
      .log()
  })
);

export default yupApplicants;
