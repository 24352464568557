import { Action } from 'typesafe-actions';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { actionCreateGlobalErrorMessage, GlobalErrorMessageModel } from 'src/actions/globalErrors';
import { DocumentRoleMappingLookupModel, DocumentRoleMappingResponse } from 'src/containers/dashboard/components/create-new-workspace/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import JsonRest from 'src/store/JsonRest';
import { AvailableTitleDealingsResponse } from './models';

/*
  stop caching this.
  In SA, when a DM document has no dealings to add, it cannot add more documents, but when you delete one,
  you can add more, caching will remember the state you cannot add, causing bug.
  Also in other states, when you add a document, the next time you want to add another document, the options will
  change, some document types are no longer available.
*/
export function getDocumentTypeOptionsStores(workspaceId: string, participantId: string, dispatch: SafeDispatch<Action<any>>): JsonRest<Array<DocumentRoleMappingLookupModel>> {
  return new JsonRest<Array<DocumentRoleMappingLookupModel>>({
    url: `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/supported-additional-documents`,
    immediate: false, // don't fetch the results immidiately, do fetch only when getDocumentTypeOptionsStores.query() is called
    onResolve: (response: DocumentRoleMappingResponse) =>
      response.documentRoles.map(d => ({
        id: d.documentFormIdentifier,
        name: d.documentDescription,
        roleIds: d.workspaceRoles,
        validationMessage: d.validationMessage
      })),
    onError: (error?: GlobalErrorMessageModel) => {
      error && dispatch(actionCreateGlobalErrorMessage(error));
    }
  });
}

/*
  Don't cache this response, because it should always get the latest,
  otherwise the next time user wants to add a DM/M document, he will see the dealings that already been used
*/
export function getDealingNumbersOptionsStores(workspaceId: string, participantId: string, dispatch: SafeDispatch<Action<any>>): JsonRest<Array<LookupItemModel>> {
  return new JsonRest<Array<LookupItemModel>>({
    url: `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/available-title-dealings`,
    immediate: true,
    onResolve: (response: AvailableTitleDealingsResponse) =>
      response.titleDealings.map(dealing => ({
        id: dealing.dealingNumber,
        name: `${dealing.dealingNumber} (${dealing.partyName.replace(/,/g, ', ')})`
      })),
    onError: (error?: GlobalErrorMessageModel) => {
      error && dispatch(actionCreateGlobalErrorMessage(error));
    }
  });
}

export function formatLodgementCaseName(originalName: string) {
  const regex = /Case #(\d+)/;
  return originalName.replace(regex, (match, number) => `Case (#${number})`);
}
