import * as React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface Props {
  statusColumn?: string;
  detailColumn?: string;
  amountColumn?: string;
}

function GridHead({ statusColumn, detailColumn, amountColumn }: Props) {
  const classes = useStyles();

  return (
    <FlexLayout fullWidth justifyContent="space-between" className={classes.bottomBorder}>
      <div className={classes.columnOne}>
        <Typography className={classes.formLabel}>{statusColumn}</Typography>
      </div>
      <div className={classes.columnTwo}>
        <Typography className={classes.formLabel}>{detailColumn}</Typography>
      </div>
      <div className={classes.columnThree}>
        <Typography className={classes.formLabel}>{amountColumn}</Typography>
      </div>
    </FlexLayout>
  );
}

GridHead.defaultProps = {
  statusColumn: 'Status',
  detailColumn: 'Account details',
  amountColumn: 'Amount ($)'
};

export default React.memo(GridHead);
