import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    // workspace type
    header: {
      background: theme.palette.grey[50],
      margin: '-40px -40px 0px',
      padding: '40px 40px 6px',
      borderBottom: 0
    },
    workspaceType: {
      marginBottom: 0
    },

    fieldset: {
      border: 0,
      padding: 0,
      '&[disabled]': {
        opacity: 0.5
      },
      paddingTop: 26
    },
    formGroup: {},
    borderTop: {
      borderTop: `1px solid ${theme.palette.divider}`
    },

    // custom field style
    jurisdictionField: {
      width: 120,
      marginRight: 10
    },
    deleteButton: {
      marginLeft: -36,
      width: 36,
      height: 36
    },

    controllingParty: {
      paddingBottom: 0
    },
    controllingPartyCheckbox: {
      marginTop: -6
    },

    // common style
    fullWidth: {
      width: '100%'
    },
    message: {
      fontWeight: 'bold'
    },

    description: {
      color: theme.palette.text.secondary,
      fontSize: 13
    },
    noMargin: {
      margin: 0
    }
  });

export type ClassKeys = typeof styles;

export default styles;
