import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import loginBackground from 'src/assets/login-background.svg';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      backgroundImage: `url(${loginBackground})`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    paper: {
      width: '400px',
      minHeight: '280px',
      marginBottom: '20px',
      padding: '41px 58px 25px',
      // border: '1px solid #ddd',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 4
    },
    logo: {
      width: 180,
      paddingBottom: 30
    },
    qrCode: {
      width: 245,
      height: 245
    },
    qrCodeText: {
      fontSize: 16,
      minWidth: 64
    },
    qrCodeRefresh: {
      paddingLeft: 4,
      marginLeft: 4,
      fontSize: 16
    },
    qrCodeDescription: {
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center'
    },
    counter: {
      fontWeight: 'bold'
    },
    counterRed: {
      color: theme.palette.error.main
    }
  });
export type ClassKeys = typeof styles;

export default styles;
