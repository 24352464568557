import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DEFAULT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { modelKey } from 'src/utils/formUtils';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file
const fieldName = modelKey<PartyModel>();

export const PARTY_FORM_CONFIG: PartyFormConfig = {
  ...DEFAULT_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...DEFAULT_PARTY_FORM_CONFIG.individualPartyFormConfig,
    allowsNameSuffix: false
  },
  upperCaseFields: [fieldName('firstName'), fieldName('lastName'), fieldName('organisationName'), fieldName('nameSuffixValue')]
};
