import * as yup from 'yup';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { DeceasedTenancyDetailModel } from '../../../../components/deceased-tenancy-detail/models';
import { NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from '../../config';
import { GranteeModel, TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../../models';

interface PartyBookValidationContext {
  deceasedTenancyDetail: DeceasedTenancyDetailModel;
  grantees: GranteeModel[];
}

const resolveValidationContext = ({ deceasedTenancyDetail, grantees }: TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model): PartyBookValidationContext => ({
  deceasedTenancyDetail,
  grantees
});

const checkShouldValidateItem = ({ deceasedTenancyDetail, grantees }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => {
  const partySelectedInDeceasedTenancy = deceasedTenancyDetail.proprietorGroups.some(
    ({ parties, isSelected }) => parties.some(e => e.partyBookId === partyIdForCheck) && isSelected
  );
  if (partySelectedInDeceasedTenancy) return partySelectedInDeceasedTenancy;

  const partySelectedInGrantees = grantees.some(g => g.partyBookId === partyIdForCheck);
  return partySelectedInGrantees;
};

const yupPartyItem: yup.ObjectSchema<PartyModel<NswNameChange>, PartyBookValidationContext> = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(
  NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE
);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
