import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchDocumentMultiSign } from './actions';
import { DocumentMultiSignApiResponse } from './models';
import { DocumentMultiSignArgs } from './reducer';

const fetchMultiSign = ({ workspaceId, participantId }: DocumentMultiSignArgs) =>
  http.get<DocumentMultiSignApiResponse>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/multi-Sign`);

function* sagaFetchMultiSign(action: ReturnType<typeof actionFetchDocumentMultiSign.request>) {
  try {
    const data = yield* call(fetchMultiSign, action.payload);

    yield put(actionFetchDocumentMultiSign.success({ data }));
  } catch (error) {
    yield put(actionFetchDocumentMultiSign.failure({ error }));
  }
}

export default [takeLatest(actionFetchDocumentMultiSign.request, sagaFetchMultiSign)];
