import * as React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveLabel } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field/InputField';

import { CURRENCY_PLACEHOLDER, Form24B_2_24_1Model, SalePriceModel } from '../../models';
import { VISIBILITY_CHECK_IS_OTHER } from '../../visibilityCheck';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;

function SalePrice() {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const fieldName = createModelKeyAppender<SalePriceModel>('salePrice');

  const handleOtherAmountChange = (_: any, resolvedValue: number) => {
    if (resolvedValue === 0 || resolvedValue === null) {
      setFieldValue(fieldName('otherDescription'), null, false);
    }
  };

  return (
    <Section title="Details of sale price" data-testid="salePrice">
      <FormGroup title="Amounts in AUD ($)">
        <Field //
          label="Cash amount"
          name={fieldName('cash')}
          format="number"
          component={CurrencyInputField}
          disabled={disabled}
          className={classes.currencyInput}
          placeholder={resolveLabel(CURRENCY_PLACEHOLDER, true)}
        />
        <Field //
          label="Vendor terms amount"
          name={fieldName('vendorTerms')}
          format="number"
          component={CurrencyInputField}
          disabled={disabled}
          className={classes.currencyInput}
          placeholder={resolveLabel(CURRENCY_PLACEHOLDER, true)}
        />
        <Field //
          label="Liabilities amount"
          name={fieldName('liabilities')}
          format="number"
          component={CurrencyInputField}
          disabled={disabled}
          className={classes.currencyInput}
          placeholder={resolveLabel(CURRENCY_PLACEHOLDER, true)}
        />
        <Field //
          label="Other amount (If applicable)"
          name={fieldName('other')}
          format="number"
          component={CurrencyInputField}
          disabled={disabled}
          className={classes.currencyInput}
          placeholder={resolveLabel(CURRENCY_PLACEHOLDER, true)}
          onChange={handleOtherAmountChange}
        />
        {VISIBILITY_CHECK_IS_OTHER(values) && (
          <Field //
            label="Other description"
            name={fieldName('otherDescription')}
            format="string"
            component={InputField}
            disabled={disabled}
            fullWidth
            className={classes.fullWidthInput}
          />
        )}
      </FormGroup>
    </Section>
  );
}

export default React.memo(SalePrice);
