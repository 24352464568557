import * as React from 'react';

import _isEqual from 'lodash-es/isEqual';
import { MenuItemProps } from '@mui/material/MenuItem';

import WorkflowStepper, { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';

import { enabledStepIndexSelector } from '../../../workspace/shared/components/workflow-panel/selector';
import WorkflowPanel from '../../../workspace/shared/components/workflow-panel/WorkflowPanel';
import { useStyles } from './styles';

interface Props<T, V> {
  /**
   * Default mode is 'dark'
   */
  mode?: 'light' | 'dark';
  steps: Array<StepObjectModel<T, V>>;
  currentStep: V;
  lastEnabledStep?: V;
  onStepClick?(e: React.MouseEvent<HTMLButtonElement>, stepValue: V): void;
  disableStepper?: boolean;
  disableMenu?: boolean;
  menuItems?: Array<React.ReactElement<MenuItemProps>>;
  documentIssues?: React.ReactNode;
}

export type DocumentWorkflowPanelProps<T, V> = Props<T, V>;

function DocumentWorkflowPanel<T, V>(props: React.PropsWithChildren<Props<T, V>>, ref: React.Ref<HTMLDivElement>) {
  const {
    //
    mode = 'dark',
    currentStep,
    steps,
    disableStepper,
    lastEnabledStep,
    children,
    disableMenu,
    menuItems = [],
    onStepClick,
    documentIssues
  } = props;
  const classes = useStyles(props);
  const initialCurrentStep = React.useRef(currentStep);
  const enabledStepIndex = enabledStepIndexSelector({ steps, lastEnabledStep: lastEnabledStep ?? initialCurrentStep.current });

  const resolveStepButtonProps = (item: StepObjectModel<T, V>, index: number) => {
    if (_isEqual(item.value, currentStep)) {
      return { disabled: disableStepper, className: classes.currentStepButton };
    }

    if (index <= enabledStepIndex) {
      return { disabled: disableStepper };
    }

    return {};
  };

  const stepButtons = (
    <WorkflowStepper
      className={classes.stepContainer}
      classes={{ horizontalLine: classes.stepConnector }}
      orientation="horizontal"
      mode={mode}
      steps={steps}
      selectedStepValue={currentStep}
      onStepClick={onStepClick}
      resolveStepButtonProps={resolveStepButtonProps}
    />
  );

  return (
    <WorkflowPanel //
      mode={mode}
      stepper={stepButtons}
      issues={documentIssues}
      disableMenu={disableMenu}
      menuItems={menuItems}
      className={classes.sticky}
      ref={ref}
    >
      {children}
    </WorkflowPanel>
  );
}

export default React.memo(React.forwardRef(DocumentWorkflowPanel)) as <T, V>(
  props: React.PropsWithChildren<Props<T, V>> & React.RefAttributes<HTMLDivElement>
) => React.ReactElement;
