import * as React from 'react';

type CreateNewWorkspaceDialogContextType = {
  /**
   * When join workspace dialog is open, we want to hide the create workspace dialog,
   * if user clicks cancel, we want to go back to workspace dialog view with all the data unchanged
   * this is done by setting css visibility: hidden
   */
  hide: boolean;
  setVisibility: (visible: boolean) => void;
};

const CreateNewWorkspaceDialogContext = React.createContext<CreateNewWorkspaceDialogContextType>({
  hide: false,
  setVisibility: () => {}
});

export default CreateNewWorkspaceDialogContext;
