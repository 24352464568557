import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { DocumentListAction } from 'src/containers/shared/document-list/models';
import { resolveDocumentStatusDescriptionForNonParticipants } from 'src/containers/shared/helper';
import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import {
  actionCleanupWorkspaceDocumentErrors,
  actionFetchWorkspaceDocuments,
  actionSetDocumentListAction,
  actionUpdateDocumentDataInWorkspaceDocuments,
  actionUpdateWorkspaceDocumentCurrentParticipantDetail,
  actionUpdateWorkspaceDocuments,
  actionUpdateWorkspaceDocumentsStatus,
  UpdatedDocumentStatus,
  WorkspaceRelatedApiRequest
} from '../actions';

export interface WorkspaceDocumentsState {
  items: Array<WorkspaceDocumentSummaryApiResponse>;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceRelatedApiRequest;
  mode: DocumentListAction;
}

const initialState: WorkspaceDocumentsState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined,
  mode: 'default'
};

export function useWorkspaceDocuments(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.workspaceDocuments);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const workspaceDocumentsReducer = createReducer<
  //
  WorkspaceDocumentsState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceDocuments.request, (state, action): WorkspaceDocumentsState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchWorkspaceDocuments.success,
    (state, action): WorkspaceDocumentsState => ({
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.items
    })
  )
  .handleAction(
    actionFetchWorkspaceDocuments.failure,
    (state, action): WorkspaceDocumentsState => ({
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    })
  )
  .handleAction(actionUpdateWorkspaceDocuments, (state, action): WorkspaceDocumentsState => {
    const updatedItems = action.payload.items.map(item => item.documentId);
    let newItems = state.items.filter(item => updatedItems.includes(item.documentId));
    return {
      ...state,
      items: newItems.sort((a, b) => updatedItems.indexOf(a.documentId) - updatedItems.indexOf(b.documentId))
    };
  })
  .handleAction(actionUpdateDocumentDataInWorkspaceDocuments, (state, action): WorkspaceDocumentsState => {
    let hasChanged = false;

    const newItems = state.items.map(item => {
      if (item.documentId === action.payload.documentId && item.data !== action.payload.data) {
        hasChanged = true;
        return {
          //
          ...item,
          data: action.payload.data
        };
      }

      return item;
    });

    if (!hasChanged) {
      return state;
    }

    return {
      ...state,
      items: newItems
    };
  })
  .handleAction(actionSetDocumentListAction, (state, action): WorkspaceDocumentsState => {
    return {
      ...state,
      mode: action.payload.mode
    };
  })
  .handleAction(actionUpdateWorkspaceDocumentsStatus, (state, action): WorkspaceDocumentsState => {
    if (!state.items.length) {
      return state;
    }

    const { documents, participantId } = action.payload;
    const documentsStatusMap = documents.reduce((statusMap, item) => statusMap.set(item.documentId, item), new Map<string, UpdatedDocumentStatus>());

    const workspaceDocuments = state.items.map((item: WorkspaceDocumentSummaryApiResponse) => {
      const updatedItem: WorkspaceDocumentSummaryApiResponse = updatedDocumentStatus(participantId, item, documentsStatusMap);

      // TODO refactor using hasChange flag.
      if (item.supportingDocuments?.length) {
        // check supporting doc
        const newSupportingDocuments = item.supportingDocuments.map(supportItem => {
          const newSupportItem = updatedDocumentStatus(participantId, supportItem, documentsStatusMap);
          if (newSupportItem === supportItem) {
            return supportItem;
          }

          return { ...newSupportItem };
        });

        // TODO this is incorrect! It will always be non equal.
        if (newSupportingDocuments !== item.supportingDocuments) {
          return { ...updatedItem, supportingDocuments: [...newSupportingDocuments] };
        }
      }

      // if it is main doc change then no need to check for the supporting docs, cannot do this since sign all model will break
      if (updatedItem !== item) {
        return { ...updatedItem };
      }

      return item;
    });

    return { ...state, items: workspaceDocuments };
  })
  .handleAction(actionCleanupWorkspaceDocumentErrors, (state): WorkspaceDocumentsState => {
    if (!state.items.length) {
      return state;
    }

    let hasChanged = false;
    const workspaceDocuments = state.items.map(item => {
      // reset compliances
      if (item.lodgementDetail?.documentLodgementCompliances) {
        hasChanged = true;
        item.lodgementDetail.documentLodgementCompliances = [];
      }

      if (item.supportingDocuments?.length) {
        let hasChangedSupportingDocuments = false;
        const newSupportingDocuments = item.supportingDocuments.map(supportItem => {
          // reset compliances
          if (supportItem.lodgementDetail?.documentLodgementCompliances) {
            hasChangedSupportingDocuments = true;
            supportItem.lodgementDetail.documentLodgementCompliances = [];
            return { ...supportItem };
          }
          return supportItem;
        });

        if (hasChangedSupportingDocuments) {
          hasChanged = true;
          return { ...item, supportingDocuments: newSupportingDocuments };
        }
      }

      if (hasChanged) {
        return { ...item };
      }

      return item;
    });

    if (!hasChanged) {
      return state;
    }

    return {
      ...state,
      items: workspaceDocuments
    };
  })
  .handleAction(actionUpdateWorkspaceDocumentCurrentParticipantDetail, (state, action): WorkspaceDocumentsState => {
    const { items } = state;
    if (items == null) {
      return state;
    }

    const { documentParticipant } = action.payload;
    // create the new items for updating
    let hasChanged = false;
    const newItems = items.map(item => {
      const updateIndex = item.documentParticipants.findIndex(participant => participant.id === documentParticipant.id);

      if (~updateIndex) {
        const newDocumentParticipants = item.documentParticipants.concat();
        // update document participant at current index with received data
        newDocumentParticipants[updateIndex] = {
          ...newDocumentParticipants[updateIndex],
          ...documentParticipant
        };
        hasChanged = true;

        return {
          ...item,
          documentParticipants: newDocumentParticipants
        };
      }

      return item;
    });

    if (!hasChanged) {
      return state;
    }

    return {
      ...state,
      items: newItems
    };
  });

function updatedDocumentStatus(
  participantId: string,
  item: WorkspaceDocumentSummaryApiResponse,
  documentsStatusMap: Map<string, UpdatedDocumentStatus>
): WorkspaceDocumentSummaryApiResponse {
  const updatedDocument = documentsStatusMap.get(item.documentId);
  if (updatedDocument === undefined) {
    return item;
  }

  const { statusId } = updatedDocument;
  if (isNaN(statusId)) {
    return item;
  }

  if (
    item.documentStatus.id === statusId &&
    item.documentActions.length === updatedDocument.documentActions.length &&
    item.isFullySignedByAllParticipants === updatedDocument.isFullySignedByAllParticipants
  ) {
    return item;
  }

  // update Document Participants
  const { documentParticipants } = item;
  let updatedDocumentParticipants = documentParticipants;

  // if new document status is verification in progress, update all doc participants.
  if (statusId === DocumentStatusEnum.LodgementVerificationInProgress) {
    updatedDocumentParticipants = documentParticipants.map(p => ({ ...p, documentStatus: statusId }));
  } else {
    const idx = documentParticipants.findIndex(p => p.id === participantId);
    if (~idx) {
      updatedDocumentParticipants = documentParticipants.concat();
      updatedDocumentParticipants[idx] = {
        ...documentParticipants[idx],
        documentStatus: statusId
      };
    }
  }

  // update the status and action for given document
  const { documentActions, isFullySignedByAllParticipants, documentPermissions: permissions } = updatedDocument;
  const DOCUMENT_STATUS_OPTIONS: LookupEnumModel<DocumentStatusEnum> = {
    id: statusId,
    name: resolveDocumentStatusDescriptionForNonParticipants(statusId)
  };

  return {
    ...item,
    documentStatus: DOCUMENT_STATUS_OPTIONS,
    documentActions,
    permissions,
    isFullySignedByAllParticipants,
    documentParticipants: updatedDocumentParticipants
  };
}

export default workspaceDocumentsReducer;
