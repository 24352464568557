import { PARTY_TYPE_LOOKUP_OPTIONS, PartyTypeEnum } from '@sympli-mfe/enums-2-18/vic';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache
/**
 path:
 - justifications[*].partyType

 schema version:
[{"id":1,"value":"Individual","name":"Individual","_meta":null},{"id":2,"value":"Organisation","name":"Organisation","_meta":null}]

 hash:
 WzEsMl0=
*/
export { PartyTypeEnum, PARTY_TYPE_LOOKUP_OPTIONS };

/**
 path:
 - directionPreference.ctDirection

 schema version:
[{"id":0,"value":"Paper","name":"Paper","_meta":null},{"id":1,"value":"Electronic","name":"Electronic","_meta":null}]

 hash:
 WzAsMV0=
*/
export enum DirectionPreference$CtDirectionEnum {
  Paper = 0,
  Electronic = 1,
  NotApplicable = 2
}
export const DIRECTION_PREFERENCE$CT_DIRECTION_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: DirectionPreference$CtDirectionEnum.NotApplicable,
    name: 'Not applicable'
  },
  {
    id: DirectionPreference$CtDirectionEnum.Paper,
    name: 'Paper'
  },
  {
    id: DirectionPreference$CtDirectionEnum.Electronic,
    name: 'Electronic'
  }
];

/**
 path:
 - dealingRequirementsSummary.dealingRequirementsDetail[*].dealingRequirement
 - dealingRequirementDefinitions[*].id

 schema version:
[{"id":1,"value":"ConsentOfCaveator","name":"Consent of Caveator, if required","_meta":null},{"id":2,"value":"ConsentOfCouncil","name":"Consent of Council, if required","_meta":null},{"id":3,"value":"ConsentOfSubsequentRegisteredMortgagee","name":"Consent of subsequent registered mortgagee or chargee, if required","_meta":null},{"id":4,"value":"CrownLeaseOrCrownSubLease","name":"Dealing with Crown lease or Crown sub-lease - any necessary consents obtained","_meta":null},{"id":5,"value":"EnduringPowerOfAttorney","name":"Enduring Power of Attorney - this is not a conflict transaction under legislation","_meta":null},{"id":6,"value":"EvidenceOfCompliance","name":"Evidence of compliance with Settled Land Act 1958","_meta":null},{"id":7,"value":"EvidenceOfDeath","name":"Evidence of death","_meta":null},{"id":8,"value":"GAIC","name":"GAIC - evidence that transfer may proceed","_meta":null},{"id":9,"value":"GrantOfProbateAndAdministration","name":"Grant of Probate/Administration/Administration with limitations","_meta":null},{"id":10,"value":"LeaseNotRequired","name":"Lease not required to be assessed for duty or lease assessed for duty","_meta":null},{"id":11,"value":"VCATOrder","name":"VCAT Order with supporting statutory declaration","_meta":null},{"id":12,"value":"StateTrustees","name":"State Trustees Section 19 Certificate","_meta":null},{"id":13,"value":"TrusteeEvidenceOfCompliance","name":"Trustee - evidence of compliance with section 30 of Trustee Act 1958","_meta":null}]

 hash:
 WzEsMiwzLDQsNSw2LDcsOCw5LDEwLDExLDEyLDEzXQ==
*/
export enum DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum {
  ConsentOfCaveator = 1,
  ConsentOfCouncil = 2,
  ConsentOfSubsequentRegisteredMortgagee = 3,
  CrownLeaseOrCrownSubLease = 4,
  EnduringPowerOfAttorney = 5,
  EvidenceOfCompliance = 6,
  EvidenceOfDeath = 7,
  GAIC = 8,
  GrantOfProbateAndAdministration = 9,
  LeaseNotRequired = 10,
  VCATOrder = 11,
  StateTrustees = 12,
  TrusteeEvidenceOfCompliance = 13
}
export const DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdLookup: LookupEnumModel<number>[] = [
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.ConsentOfCaveator,
    name: 'Consent of Caveator, if required'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.ConsentOfCouncil,
    name: 'Consent of Council, if required'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.ConsentOfSubsequentRegisteredMortgagee,
    name: 'Consent of subsequent registered mortgagee or chargee, if required'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.CrownLeaseOrCrownSubLease,
    name: 'Dealing with Crown lease or Crown sub-lease - any necessary consents obtained'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.EnduringPowerOfAttorney,
    name: 'Enduring Power of Attorney - this is not a conflict transaction under legislation'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.EvidenceOfCompliance,
    name: 'Evidence of compliance with Settled Land Act 1958'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.EvidenceOfDeath,
    name: 'Evidence of death'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.GAIC,
    name: 'GAIC - evidence that transfer may proceed'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.GrantOfProbateAndAdministration,
    name: 'Grant of Probate/Administration/Administration with limitations'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.LeaseNotRequired,
    name: 'Lease not required to be assessed for duty or lease assessed for duty'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.VCATOrder,
    name: 'VCAT Order with supporting statutory declaration'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.StateTrustees,
    name: 'State Trustees Section 19 Certificate'
  },
  {
    id: DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.TrusteeEvidenceOfCompliance,
    name: 'Trustee - evidence of compliance with section 30 of Trustee Act 1958'
  }
];
