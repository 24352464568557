import React, { useEffect, useRef, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { batch, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase } from '@sympli/api-gateway/shared';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import ErrorBoundary from 'src/components/error-boundary';
import Switch from 'src/components/router/switch';
import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import { actionFetchWorkspaceAssignableGroups } from 'src/containers/dashboard/shared/actions';
import { actionFetchDocumentDetail, actionFetchPaymentSummary } from 'src/containers/documents/actions';
import { WORKSPACE_DETAIL_PAGE_ALL_DIRECTIONS } from 'src/containers/router/constants';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { actionFetchLinkedWorkspaces } from 'src/containers/shared/linked-workspace-list/actions';
import { isCurrentUserWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { actionFetchAllDirections } from 'src/containers/workspace/financial/all-directions/actions';
import { actionFetchDirectionsList } from 'src/containers/workspace/financial/directions/actions';
import { actionFetchSettlementDetails } from 'src/containers/workspace/financial/settlement-date/actions';
import LodgementVerificationDialog from 'src/containers/workspace/shared/components/lodgement-verification-dialog';
import WorkspacePersistedNotification from 'src/containers/workspace/shared/components/workspace-persisted-notification/WorkspacePersistedNotification';
import {
  actionFetchWorkspaceBasicInfo,
  actionFetchWorkspaceById,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceParticipants,
  actionFetchWorkspaceTasks
} from 'src/containers/workspace/shared/detail/actions';
import { actionFetchWorkspaceReportsFeed } from 'src/containers/workspace/shared/detail/components/tab-workspace-report/actions';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import SignDocumentsContainer from 'src/containers/workspace/sign-documents';
import SignMLCDocumentsContainer from 'src/containers/workspace/sign-documents/SignMLCDocumentsContainer';
import { useFeatureFlag } from 'src/hooks';
import useRouterParams from 'src/hooks/useRouterParams';
import useSafeDispatch from 'src/hooks/useSafeDispatch';
import { socket } from 'src/hooks/useWorkspaceSocket';
import { SocketClientEventToServer, SocketServerEventToClient } from 'src/socket-2.0';
import { useRealtimeConnection } from 'src/socket-2.0/RealtimeConnectionProvider';
import { WorkspaceUpdateMessage } from 'src/socket/workspace-update/models';
import useWorkspaceUpdateListener from '../../hooks/useWorkspaceUpdateListener';
import WorkspaceAllDirectionsPageContainer from './all-directions';
import { updateWorkspaceLastAccessedDate } from './api';
import WorkspaceDetailPageContainer from './detail';
import WorkspaceDirectionsPageContainer from './directions';
import WorkspaceDocumentsPageContainer from './documents';
import { WorkspacePageRouteParams } from './models';
import WorkspaceSettlementDatePageContainer from './settlement-date';
import LeftPanelContainer from './shared/left-panel';
import WorkspaceStampDutyPageContainer from './stamp-duty';
import WorkspaceTitlesPageContainer from './titles';
import WorkspaceTrustAccountAuthorisationRecordsContainer from './trust-account-authorisation-records';
import WorkspacePageLayout from './WorkspacePageLayout';

function WorkspacePageContainer({ isAutoRefreshNeeded = false }: { isAutoRefreshNeeded?: boolean }) {
  const refInitialConnectionRequested = useRef<boolean>(false);
  // this state is only used for silent data fetch when socket is not connected
  const [isSilentFetchNeeded, setIsSilentFetchNeeded] = useState<object>({});
  const reduxDispatch = useDispatch();
  const dispatch = useSafeDispatch(reduxDispatch);
  const { workspaceId, participantId, documentId } = useRouterParams<WorkspacePageRouteParams>();

  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const lodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);

  const workspaceStatusId: WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const workspaceTypeId: WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const isLodgementOnly = workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
  const isNonLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly;
  const isNotAbandoned = Number.isInteger(workspaceStatusId) && ![WorkspaceStatusEnum.Abandoned, WorkspaceStatusEnum.Withdrawn].includes(workspaceStatusId!);

  const isMLCEnabled = useFeatureFlag(FeatureToggleEnum.mlcEnabled);

  const isCurrentUserWorkspaceParticipant: boolean = isCurrentUserWorkspaceParticipantSelector({
    workspaceTypeId: workspaceBasicInfoState.detail?.workspaceTypeId,
    workspaceStatusId: workspaceBasicInfoState.detail?.workspaceStatusId,
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const fireImmediately = true;

  const fetchData = React.useCallback(async () => {
    try {
      await updateWorkspaceLastAccessedDate({ workspaceId, participantId });
    } catch (error) {
      const scope = Logger.scopeWithCustomAttributes({
        message: 'fail to update last accessed time when opening a workspace',
        workspaceId,
        participantId,
        apiName: '{workspaceId}/participants/{participantId}/last-access'
      });
      Logger.captureException(error, scope);
    }
  }, [workspaceId, participantId]);

  React.useEffect(() => {
    if (workspaceStatusId !== undefined && workspaceStatusId !== WorkspaceStatusEnum.Archived && workspaceStatusId !== WorkspaceStatusEnum.Abandoned) {
      fetchData();
    }
  }, [fetchData, workspaceStatusId]);

  // always fetch
  // full info for workspace detail box
  // basic info that will help us to resolve things like workspaceType, workspaceStatus, lodgementStatus etc
  useEffect(() => {
    batch(() => {
      dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
      dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
      dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
    });
  }, [isSilentFetchNeeded, dispatch, workspaceId, participantId]);

  // for active workspace participant
  // workspace detail box displays information about outstanding tasks
  const CONDITION_FETCH_WORKSPACE_TASKS = fireImmediately || isNotAbandoned; // PERF boost
  useEffect(() => {
    // if (isNotAbandoned) { // original condition
    if (CONDITION_FETCH_WORKSPACE_TASKS) {
      dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
    }
  }, [isSilentFetchNeeded, dispatch, workspaceId, participantId, CONDITION_FETCH_WORKSPACE_TASKS]);

  // for active workspace participant
  // workspace detail box displays information about assigned group
  const CONDITION_FETCH_WORKSPACE_ASSIGNABLE_GROUPS = fireImmediately || isCurrentUserWorkspaceParticipant; // PERF boost
  useEffect(() => {
    // if (isCurrentUserWorkspaceParticipant) { // original condition
    if (CONDITION_FETCH_WORKSPACE_ASSIGNABLE_GROUPS) {
      dispatch(actionFetchWorkspaceAssignableGroups.request());
    }
  }, [isSilentFetchNeeded, dispatch, CONDITION_FETCH_WORKSPACE_ASSIGNABLE_GROUPS]);

  // for active workspace participant
  // for financial workspace with settlement date
  // workspace detail box displays settlement details
  useEffect(() => {
    if (isCurrentUserWorkspaceParticipant) {
      if (
        workspaceBasicInfoState.detail?.settlementDate &&
        // if workspace is withdrawn or abandoned, there is no need to call the settlement-date-proposal endpoint
        workspaceBasicInfoState.detail?.workspaceStatusId !== WorkspaceStatusEnum.Withdrawn &&
        workspaceBasicInfoState.detail?.workspaceStatusId !== WorkspaceStatusEnum.Abandoned
      ) {
        dispatch(actionFetchSettlementDetails.request({ workspaceId, participantId }));
      }
    }
  }, [
    isSilentFetchNeeded,
    dispatch,
    workspaceId,
    participantId,
    isCurrentUserWorkspaceParticipant,
    workspaceBasicInfoState.detail?.settlementDate,
    workspaceBasicInfoState.detail?.workspaceStatusId
  ]);

  // for active workspace participant
  // for all active workspaces
  const CONDITION_FETCH_LODGEMENT_REPORTS_AND_DOCUMENTS_FEED = fireImmediately || (isCurrentUserWorkspaceParticipant && isNotAbandoned); // PERF boost
  useEffect(() => {
    // if (isCurrentUserWorkspaceParticipant && isNotAbandoned) { // original condition
    if (CONDITION_FETCH_LODGEMENT_REPORTS_AND_DOCUMENTS_FEED) {
      batch(() => {
        dispatch(actionFetchWorkspaceReportsFeed.request({ workspaceId, participantId }));
        dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
      });
    }
  }, [isSilentFetchNeeded, dispatch, workspaceId, participantId, CONDITION_FETCH_LODGEMENT_REPORTS_AND_DOCUMENTS_FEED]);

  // for all active workspaces, independent on participant
  const CONDITION_FETCH_LINKED_WORKSPACES = fireImmediately || isNotAbandoned; // PERF boost
  useEffect(() => {
    // if (isNotAbandoned) { // original condition
    if (CONDITION_FETCH_LINKED_WORKSPACES) {
      dispatch(actionFetchLinkedWorkspaces.request({ workspaceId }));
    }
  }, [isSilentFetchNeeded, dispatch, workspaceId, CONDITION_FETCH_LINKED_WORKSPACES]);

  // for active financial workspaces, left panel requires additional data
  useEffect(() => {
    if (isNonLodgementOnly && isNotAbandoned) {
      batch(() => {
        dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
        // FetchAllDirections data which is used by AllDirectionsItem
        dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
      });
    }
  }, [isSilentFetchNeeded, dispatch, workspaceId, participantId, isNonLodgementOnly, isNotAbandoned]);

  // only on document page, we fetch this data
  useEffect(() => {
    if (documentId) {
      dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId }));
    }
  }, [isSilentFetchNeeded, dispatch, workspaceId, participantId, documentId]);

  // for active non-financial workspaces, left panel requires additional data
  const CONDITION_FETCH_PAYMENT_SUMMARY = fireImmediately || (isLodgementOnly && isNotAbandoned); // PERF boost
  useEffect(() => {
    // if (isLodgementOnly && isNotAbandoned) { // original condition
    if (CONDITION_FETCH_PAYMENT_SUMMARY) {
      dispatch(actionFetchPaymentSummary.request({ workspaceId, participantId }));
    }
    // we need to reload payment summary also when lodgement case status has changed
  }, [isSilentFetchNeeded, dispatch, workspaceId, participantId, CONDITION_FETCH_PAYMENT_SUMMARY, lodgementCaseStatusId]);

  // if user has been inactive for more than 3 minutes and comes back
  // we request silent refresh
  useIdleTimer({
    // this action is triggered immediately when page loads
    // and then it gets triggered once user comes back from being inactive for at least time specified by throttle value
    onAction: () => {
      if (!isAutoRefreshNeeded) return;

      // this will guarantee that we don't trigger silent refresh when page loads for the first time
      if (refInitialConnectionRequested.current && !socket.isConnected) {
        Logger.capturePageAction(PageActionEnum.Realtime, {
          message: 'Silent refresh triggered'
        });
        setIsSilentFetchNeeded({});
      } else {
        refInitialConnectionRequested.current = true;
      }
    },
    // this value needs to be large enough so we can trigger onAction immediately when user is active
    timeout: 24000000,
    // by throttling we make sure that onAction is triggered only once in specified window
    throttle: 3 * 60000
  });

  return (
    <WorkspacePageLayout //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      sidePanel={<LeftPanelContainer />}
      showWorkspaceMessenger={isNonLodgementOnly}
    >
      <ErrorBoundary component={GeneralCrashedContent}>
        {/* Element conditionals so that we show workspace logs for a withdrawn participant */}
        <Switch>
          <Route path={'detail'} element={<WorkspaceDetailPageContainer />} />
          <Route path={'documents'} element={isNotAbandoned ? <WorkspaceDocumentsPageContainer /> : <WorkspaceDetailPageContainer />} />
          <Route path={'sign-documents'} element={isNotAbandoned ? isMLCEnabled ? <SignMLCDocumentsContainer /> : <SignDocumentsContainer /> : <WorkspaceDetailPageContainer />} />
          <Route path={'titles'} element={isNotAbandoned ? <WorkspaceTitlesPageContainer /> : <WorkspaceDetailPageContainer />} />
          <Route
            path={'trust-account-authorisation-records/:trustAccountId'}
            element={isNotAbandoned ? <WorkspaceTrustAccountAuthorisationRecordsContainer /> : <WorkspaceDetailPageContainer />}
          />
          <Route path={WORKSPACE_DETAIL_PAGE_ALL_DIRECTIONS} element={isNotAbandoned ? <WorkspaceAllDirectionsPageContainer /> : <WorkspaceDetailPageContainer />} />
          <Route path={'stamp-duty'} element={isNotAbandoned ? <WorkspaceStampDutyPageContainer /> : <WorkspaceDetailPageContainer />} />
          <Route path={'directions'} element={isNotAbandoned ? <WorkspaceDirectionsPageContainer /> : <WorkspaceDetailPageContainer />} />
          <Route path={'settlement-date'} element={isNotAbandoned ? <WorkspaceSettlementDatePageContainer /> : <WorkspaceDetailPageContainer />} />
        </Switch>
      </ErrorBoundary>

      <LodgementVerificationDialog />
      <WorkspacePersistedNotification workspaceId={workspaceId} participantId={participantId} />
    </WorkspacePageLayout>
  );
}

function useWithStr2JSON(processor: (message: WorkspaceUpdateMessage) => void) {
  return React.useCallback(
    (str: string) => {
      try {
        const message = JSON.parse(str);
        return processor(message);
      } catch (e) {
        const scope = Logger.scopeWithCustomAttributes({ message: str });
        Logger.captureException(new Error('Could not parse message'), scope);
      }
    },
    [processor]
  );
}

function WithRealtime(props: React.PropsWithChildren<{}>) {
  const { workspaceId, participantId, documentId } = useRouterParams<WorkspacePageRouteParams>();
  const { socket } = useRealtimeConnection();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const workspaceTypeId: WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const isLodgementOnly = workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
  const lodgementCases: LodgementCase[] | undefined = workspaceBasicInfoState.detail?.lodgementCases;
  const documents: WorkspaceDocumentSummaryApiResponse[] = workspaceDocumentsState.items;

  const onWorkspaceUpdated = useWithStr2JSON(
    useWorkspaceUpdateListener({
      //
      workspaceId,
      participantId,
      isLodgementOnly,
      documentId,
      lodgementCases,
      documents
    })
  );

  React.useLayoutEffect(() => {
    socket.emit(SocketClientEventToServer.Log, {
      message: 'Socket is requesting to join workspace room',
      workspaceId,
      socketIdClientSide: socket.id
    });
    socket.emit(SocketClientEventToServer.JoinWorkspaceRoom, workspaceId);
    socket.on(SocketServerEventToClient.WorkspaceUpdated, onWorkspaceUpdated);

    return () => {
      socket.emit(SocketClientEventToServer.Log, {
        message: 'Socket is requesting to leave workspace room',
        workspaceId,
        socketIdClientSide: socket.id
      });
      socket.emit(SocketClientEventToServer.LeaveWorkspaceRoom, workspaceId);
      socket.off(SocketServerEventToClient.WorkspaceUpdated, onWorkspaceUpdated);
    };
  }, [socket, workspaceId, onWorkspaceUpdated]);

  return props.children as JSX.Element;
}

export default function WorkspacePageContainerWithRealtime() {
  return (
    <WithRealtime>
      <WorkspacePageContainer />
    </WithRealtime>
  );
}
