import { OffPlatformTaskStatusEnum } from './enums';

const OffPlatformTaskStatusNameMapping: Partial<Record<OffPlatformTaskStatusEnum, string>> = {
  [OffPlatformTaskStatusEnum.Todo]: 'To do',
  [OffPlatformTaskStatusEnum.Sent]: 'Sent',
  [OffPlatformTaskStatusEnum.Received]: 'Received',
  [OffPlatformTaskStatusEnum.Verified]: 'Verified',
  [OffPlatformTaskStatusEnum.Returned]: 'Returned'
};

export function resolveOffPlatformTaskStatusName(status?: OffPlatformTaskStatusEnum | string): string {
  if (status != null) {
    const name = OffPlatformTaskStatusNameMapping[status];
    if (name != null) {
      return name;
    }
  }
  return 'Unknown status';
}
