import * as React from 'react';

import _debounce from 'lodash-es/debounce';
import { batch, useDispatch } from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DEBOUNCE_LIMIT } from 'src/containers/dashboard/shared/helpers';
import {
  actionFetchSearchBoxUpdatedArchivedFeed,
  actionFetchSearchBoxUpdatedFeed,
  actionResetSearchBoxArchivedFeed,
  actionResetSearchBoxFeed
} from 'src/containers/shared/app-bar/actions';
import { useSearchBoxUpdated } from 'src/containers/shared/app-bar/reducers/searchBoxUpdated';
import { useSearchBoxUpdatedArchived } from 'src/containers/shared/app-bar/reducers/searchBoxUpdatedArchived';
import { useSafeDispatch } from 'src/hooks';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import GlobalSearchBox from './components/global-search-box';
import { SEARCH_TERM_MIN_LENGTH, SearchBoxUpdatedApiRequest } from './components/global-search-box/models';
import SearchBoxIcon from './components/search-box-icon';
import SearchTable from './components/search-table/SearchTable';
import SearchTabs from './components/search-tabs';
import { DialogControlContext } from './GlobalSearchContext';
import { SearchCategoryEnum, SearchDashboardPageTabsEnum, SearchPredefineEnum } from './models';
import { useStyles } from './styles';

function GlobalSearchContainer() {
  // internal state
  const [value, setValue] = React.useState(''); // search input value
  const [isOpen, setIsOpen] = React.useState(false); // control global search dialog
  const [selectedTab, setSelectedTab] = React.useState<SearchDashboardPageTabsEnum>(SearchDashboardPageTabsEnum.Workspaces); // global search tab
  // redux data
  const searchesFeed = useSearchBoxUpdated(value);
  const searchesArchivedFeed = useSearchBoxUpdatedArchived(value);
  // else
  const dispatch = useSafeDispatch(useDispatch());
  const classes = useStyles();

  // TODO move it to helper function file
  const fetchSearchBoxWithDebounce = _debounce(
    (
      //
      selectedTab: SearchDashboardPageTabsEnum,
      searchTerm: string | undefined,
      searchCategoryId: SearchCategoryEnum | undefined,
      pageNumber: number = 1
    ) => {
      const dispatchAction = (inputLength: number, query: SearchBoxUpdatedApiRequest, action: SafeDispatch<any>, resetAction: SafeDispatch<any>) => {
        if (inputLength >= SEARCH_TERM_MIN_LENGTH) {
          dispatch(action(query));
        } else {
          dispatch(resetAction(query));
        }
      };

      const q: SearchBoxUpdatedApiRequest = { searchTerm: searchTerm?.trim(), searchCategoryId: searchCategoryId, pageSize: 15, pageNumber };

      const inputValue = searchTerm ? searchTerm.trim().toLowerCase() : '';

      if (selectedTab === SearchDashboardPageTabsEnum.Archived) {
        dispatchAction(
          //
          inputValue.length,
          { ...q, searchPredefineId: SearchPredefineEnum.Archived },
          actionFetchSearchBoxUpdatedArchivedFeed.request,
          actionResetSearchBoxArchivedFeed
        );
      } else {
        dispatchAction(
          //
          inputValue.length,
          q,
          actionFetchSearchBoxUpdatedFeed.request,
          actionResetSearchBoxFeed
        );
      }
    },
    DEBOUNCE_LIMIT
  );

  const handleOnExitSearch = () => {
    batch(() => {
      // always reset tab to active workspace when exit global search
      setSelectedTab(SearchDashboardPageTabsEnum.Workspaces);
      setValue('');
      setIsOpen(false);
      dispatch(actionResetSearchBoxArchivedFeed({ searchTerm: '' }));
      dispatch(actionResetSearchBoxFeed({ searchTerm: '' }));
    });
  };

  return (
    <DialogControlContext.Provider value={{ isOpen, setIsOpen }}>
      <SearchBoxIcon handleOnClick={() => setIsOpen(true)} />
      <Dialog
        open={isOpen}
        fullWidth
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogPaper
        }}
        hideBackdrop={true}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <FlexLayout flexDirection="row" className={classes.SearchBar}>
            <GlobalSearchBox //
              value={value}
              setValue={setValue}
              selectedTab={selectedTab}
              fetchSearchBoxWithDebounce={fetchSearchBoxWithDebounce}
              setSelectedTab={setSelectedTab}
            />
            <ButtonBase onClick={handleOnExitSearch} className={classes.existSearchStyle}>
              Exit Search
            </ButtonBase>
          </FlexLayout>
          {value.trim().length >= 3 && (
            <SearchTabs //
              searchTerm={value}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              cachedSearchTerm={searchesFeed.query.searchTerm}
              cachedArchivedSearchTerm={searchesArchivedFeed.query.searchTerm}
              fetchSearchBoxWithDebounce={fetchSearchBoxWithDebounce}
            />
          )}
          <FlexLayout flexDirection="row" justifyContent="center">
            <SearchTable //
              searchTerm={value.trim()}
              searchesFeed={selectedTab === SearchDashboardPageTabsEnum.Archived ? searchesArchivedFeed : searchesFeed}
              selectedTab={selectedTab}
              fetchSearchBoxWithDebounce={fetchSearchBoxWithDebounce}
            />
          </FlexLayout>
        </DialogContent>
      </Dialog>
    </DialogControlContext.Provider>
  );
}
export default React.memo(GlobalSearchContainer);
