import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

// this file was automatically generated from models.ts.mustache
export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

export interface ApiNoticeOfSaleModel {
  addressBook: BaseAddress[];
  consideration?: ConsiderationModel | null;
  detailsForServiceOfNotices?: DetailsForServiceOfNoticesModel;
  partyBook: ApiDocumentPartyModel[];
  property?: PropertyModel | null;
  isPropertyHidden: boolean;
  relatedDocuments?: RelatedDocumentModel[];
  titleReferences?: TitleReferenceModel[];
}

export interface ConsiderationModel {
  considerationType?: number | null;
  isMonetaryConsideration?: boolean;
  monetaryConsideration?: MonetaryConsiderationModel;
  nonMonetaryConsideration?: NonMonetaryConsiderationModel;
}

export interface MonetaryConsiderationModel {
  dateOfContractForSale: Date | string | null;
  settlementDate: Date | string | null;
  gst: number | null;
  otherLandIncludedIndication: boolean | null;
  nonFixedImprovementsIncludedIndication: boolean | null;
  purchasePrice: number | null;
}

export interface NonMonetaryConsiderationModel {
  acquisitionDate?: Date | string | null;
  acquisitionDateFromOtherDocument?: Date | string | null;
  acquisitionDatesFromOtherDocument?: Date[] | string[] | null;
  acquisitionType: number | null;
  hideCourtOrderAcquisitionType?: boolean;
  hideDeedOfAssignmentAcquisitionType?: boolean;
  hideDeedOfPartitionAcquisitionType?: boolean;
  hideIntergenerationalAssignmentAcquisitionType?: boolean;
  hideOtherAcquisitionTypeAcquisitionType?: boolean;
  hideWillOrIntestacyAcquisitionType?: boolean;
  relatedDocuments?: RelatedDocumentModel[];
}

export interface RelatedDocumentModel {
  documentIdentifier?: string;
  documentNumber?: string;
}

export interface DetailsForServiceOfNoticesModel {
  addressBookId: string;
  ownerAgent: number | null;
  partyBookId: string;
}

export interface PropertyModel {
  transferArrangement?: number | null;
  addressBookId?: string;
  area?: AreaModel;
  isPartLandAffected?: boolean;
  natureOfProperty?: number | null;
  shareTransferred?: FractionModel | null;
}

export interface AreaModel {
  numberOfUnits: number | null;
  unit: number | null;
}

export interface FractionModel {
  denominator?: number | null;
  id?: string;
  numerator?: number | null;
}

export interface TitleReferenceModel {
  isPartLandAffected?: boolean;
  isSelected: boolean;
  landDescriptions?: LandDescriptionModel[];
  propertyAddress?: DocumentStreetAddressModel;
  reference?: string;
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export interface DocumentStreetAddressModel {
  addressType?: number | null;
  levelNumber?: string;
  levelType?: number | null;
  locality: string;
  postcode: string;
  streetName: string;
  streetNumber?: string;
  streetType: number | null;
  unitNumber?: string;
  unitType?: number | null;
}

export type NoticeOfSaleModel = Omit<ApiNoticeOfSaleModel, 'addressBook' | 'partyBook'> & {
  [ADDRESS_BOOK_KEY]: AddressBookEntityModel[];
  [PARTY_BOOK_KEY]: PartyModel[];
};
