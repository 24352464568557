import makeStyles from '@mui/styles/makeStyles';

import loginBackground from 'src/assets/login-background.svg';

export const useStyles = makeStyles(
  {
    container: {
      width: '100%',
      height: '100%',
      backgroundImage: `url(${loginBackground})`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    paper: {
      width: '400px',
      minHeight: '280px',
      marginBottom: '20px',
      padding: '41px 58px 25px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 4
    },
    logo: {
      width: 180,
      paddingBottom: 30
    },
    description: {
      padding: '10px 20px 20px',
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center'
    }
  },
  {
    name: 'LoggedOut'
  }
);
