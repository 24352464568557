import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { IS_REQUIRED_CHECK_DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { PreRegisteredStandardTerm, StandardTermsDealingNumbersModel, StandardTermsModel } from '../../models';

interface Props {
  name: string;
  className?: string;
  additionalAttachments?: DocumentAttachmentItemModel[];
}

function StandardTermsDealingNumbers({ name, additionalAttachments }: Props): JSX.Element {
  const { values } = useFormikContext<StandardTermsModel>();

  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<StandardTermsDealingNumbersModel>(name);
  const value: StandardTermsDealingNumbersModel = _get(values, name);

  const standardTermsDealingNumbers = values.standardTermsDealingNumbers;

  const isRequired = IS_REQUIRED_CHECK_DOCUMENT_REFERENCE(value, values.additionalCovenants, additionalAttachments);

  const options = useMemo(() => {
    const preRegisteredStandardTerms: PreRegisteredStandardTerm[] =
      values.preRegisteredStandardTerms ??
      values.preRegisteredStandardTermsDealingNumbers?.map(x => {
        return { value: x };
      }) ??
      []; // preRegisteredStandardTermsDocumentReferences is obsolete, do not use in new document version. Use PreRegisteredStandardTerms instead

    const selectedDealingNumbers = standardTermsDealingNumbers?.map(v => (v.termNumber ?? '').trim()).filter(v => v !== '');

    return preRegisteredStandardTerms
      .filter(x => !selectedDealingNumbers?.includes(x.value) || x.value === (value.termNumber ?? '').trim())
      .map(({ value, description }) => ({ key: value, value: description ? `${value} (${description})` : value }));
  }, [values.preRegisteredStandardTerms, values.preRegisteredStandardTermsDealingNumbers, standardTermsDealingNumbers, value.termNumber]);

  const placeholder = options.length ? resolveComboboxPlaceholder(isRequired) : resolvePlaceholder(isRequired);

  return (
    <Field //
      aria-label="Dealing number"
      name={fieldName('termNumber')}
      component={SympliAutocompleteField}
      disabled={disabled}
      placeholder={placeholder}
      type="combobox"
      options={options}
      optionIdAttr="key"
      optionNameAttr="value"
      getCustomSelectedOptionLabel={(item: any) => item.key}
    />
  );
}

export default React.memo(StandardTermsDealingNumbers);
