import { QldDocumentPartyJustification, QldNameChange, QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-23/components/party-justification';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { QLD_DM_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterMortgagees, filterTitleReferences } from './helpers';
import { ApiDischargeOfMortgage2_23_1Model, DischargeOfMortgage2_23_1Model } from './models';

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<QldDocumentPartyJustification, QldNameChange>({
  partyFormConfig: QLD_DM_PARTY_FORM_CONFIG,
  nameChangeConversion: QldNameChangeConversion
});

// this file was automatically generated from conversion.ts.mustache
function convertFromApiToFormModel(apiModel: ApiDischargeOfMortgage2_23_1Model): DischargeOfMortgage2_23_1Model {
  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.mortgages.map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId)).flat();
  let formModel: DischargeOfMortgage2_23_1Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
    titleReferences: filterTitleReferences(apiModel),
    mortgagees: filterMortgagees(apiModel)
  };
  formModel = applyDefaultMap(formModel, fallbackMap);
  return formModel;
}

function convertFromFormToApiModel(formModel: DischargeOfMortgage2_23_1Model, originalApiModel: ApiDischargeOfMortgage2_23_1Model): ApiDischargeOfMortgage2_23_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    titleReferences,
    mortgagees,
    ...rest
  } = formModel;

  const selectedTitles = titleReferences.filter(x => x.isSelected).map(x => x.reference);

  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
    mortgages: formModel.mortgages.map(x => {
      return {
        ...x,
        titleReferences: x.titleReferences.map(t => {
          return {
            ...t,
            isSelected: x.isSelected ? selectedTitles.includes(t.reference) : t.isSelected
          };
        }),
        mortgagees: x.mortgagees.map(m => {
          const selectedMortgagee = mortgagees.find(x => x.partyBookId === m.partyBookId) || m;
          return selectedMortgagee;
        })
      };
    })
  };
}

class Converter implements IConverter<DischargeOfMortgage2_23_1Model, ApiDischargeOfMortgage2_23_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
