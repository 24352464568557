import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { ApiPriorityNoticeModel, PARTY_BOOK_KEY, PriorityNoticeModel } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: PARTY_FORM_CONFIG
});

class Converter implements IConverter<PriorityNoticeModel, ApiPriorityNoticeModel> {
  fromApiToFormModel(apiModel: ApiPriorityNoticeModel, _context: IConverterContext): PriorityNoticeModel {
    let formModel: PriorityNoticeModel = {
      ...apiModel,
      [PARTY_BOOK_KEY]: convertPartyBookFromApiToFormModel(apiModel[PARTY_BOOK_KEY], [])
    };

    formModel = applyDefaultMap(formModel, fallbackMap);

    return formModel;
  }

  fromFormToApiModel(formValues: PriorityNoticeModel, _originalApiModel: ApiPriorityNoticeModel): ApiPriorityNoticeModel {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const { partyBook, ...rest } = formModel;

    return {
      ...rest,
      [PARTY_BOOK_KEY]: convertPartyBookFromFormToApiModel(partyBook, _originalApiModel[PARTY_BOOK_KEY])
    };
  }
}

export default new Converter();
