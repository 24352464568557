import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconTimes } from '@sympli/ui-framework/icons';

import { useStyles } from '../sharedStyles';

interface Props {
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
}

function WithdrawDocument(props: Props) {
  const { isLoading, onClick } = props;
  const classes = useStyles();
  return (
    <MenuItem onClick={onClick}>
      <IconTimes className={classes.menuItemIconRedTimes} />
      <Typography className={classes.menuItemLabel}>Withdraw from document</Typography>
      {isLoading && <InlineLoader size={18} />}
    </MenuItem>
  );
}

export default WithdrawDocument;
