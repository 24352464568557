import { AssignmentTypeEnum, DashboardDateRangeModel } from 'src/containers/dashboard/shared/models';
import { StandaloneDashboardFilterModel, StandaloneDashboardRouteTabsEnumV2, StandaloneWorkspacesV2ApiRequest } from '../models';

// to be discussed, what backend will send back to us
export interface AggregatorComponentBaseProps {
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(payload: AggregatorsQueryParamsModel): Promise<StandaloneDashboardAggregatorApiResponse>;
  onActionClick(filters: StandaloneWorkspacesV2ApiRequest, engagedTab: StandaloneDashboardRouteTabsEnumV2, currentTabFilters: StandaloneDashboardFilterModel): void;
}

// might be easier if we can just have the object per type
export interface Aggregators {
  name: string;
  count: number;
}

export interface AggregatorsQueryParamsModel {
  assignmentType: AssignmentTypeEnum;
  queries: AggregatorQuery[];
}

export interface AggregatorQuery {
  name: string;
  myTasks?: number[];
  workspaceStatus?: number[];
  settlementPeriod?: DashboardDateRangeModel;
  withProposals?: boolean;
}

export type StandaloneDashboardAggregatorApiRequest = AggregatorsQueryParamsModel;
export type StandaloneDashboardAggregatorApiResponse = Aggregators[];

export enum AggregatorNameEnum {
  totalOpenWorkspaces = 'total-open-workspaces',
  readyToLodge = 'ready-to-lodge',
  toBeReworked = 'to-be-reworked',
  readyToSign = 'ready-to-sign'
}

export interface TodayCountModel {
  settlements: number;
  notReady: number;
  readyToSign: number;
}

export interface TomorrowCountModel {
  settlements: number;
  notReady: number;
}
