import * as React from 'react';

import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

function PartiallySignedTrustAccountAuthorisationRecord() {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Partially Signed
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        This document has now <b>been partially signed</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        Once the second user has signed the document, it will be ready for settlement.
      </Typography>
    </>
  );
}

export default React.memo(PartiallySignedTrustAccountAuthorisationRecord);
