import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    listItem: {},
    outerGutters: {
      marginLeft: -16,
      marginRight: -16,
      '& $listItem': {
        paddingLeft: 16,
        paddingRight: 16
      }
    }
  },
  { name: 'DocumentList' }
);
