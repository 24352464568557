import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { LodgementInstructions2_19_1Model } from './models';
import { VISIBILITY_CHECK_ISSUING_BOX_NUMBER, VISIBILITY_CHECK_ISSUING_METHOD, VISIBILITY_CHECK_ISSUING_OFFICE, VISIBILITY_CHECK_ISSUING_PARTY } from './visibilityChecks';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<LodgementInstructions2_19_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  documentFees: NO_FALLBACK,
  lodgementCaseFeeDetail: NO_FALLBACK,
  lodgementCaseFeeTotal: NO_FALLBACK,
  lodgementDocumentSummaries: NO_FALLBACK,
  lodgementOrderSummary: NO_FALLBACK,
  responsibleSubscriber: NO_FALLBACK,
  responsibleParticipantId: NO_FALLBACK,
  participants: NO_FALLBACK,
  isResponsibleParticipant: NO_FALLBACK,
  isResponsibleParticipantChangeable: NO_FALLBACK,
  justifications: NO_FALLBACK,
  duplicateHoldingDetail: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        titleReference: NO_FALLBACK,
        editionNumber: [''],
        editionDate: [null],
        issuingInstructionDetails: {
          $children: {
            issuingInstruction: [null],
            issuingParty: {
              $children: {
                partyBookId: [VISIBILITY_CHECK_ISSUING_PARTY, ''],
                addressBookId: [VISIBILITY_CHECK_ISSUING_PARTY, '']
              }
            },
            issuingMethod: [VISIBILITY_CHECK_ISSUING_METHOD, null],
            issuingOffice: [VISIBILITY_CHECK_ISSUING_OFFICE, null],
            issuingBoxNumber: [VISIBILITY_CHECK_ISSUING_BOX_NUMBER, '']
          }
        }
      }
    }
  },
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  currentParticipantId: NO_FALLBACK,
  isSameRoleAsResponsible: NO_FALLBACK
};
