// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'Lodgement queued', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="--------🎨-Iconography-&amp;-illustrations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Illustrations" transform="translate(-257.000000, -423.000000)">
          <g id="illo-queued" transform="translate(257.000000, 423.000000)">
            <g id="Group">
              <circle id="Oval" fill="#F2F2F2" fillRule="nonzero" cx="65" cy="65" r="65"></circle>
              <g transform="translate(28.785714, 15.785714)">
                <polygon
                  id="Rectangle"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  transform="translate(36.214371, 49.219036) rotate(-0.095425) translate(-36.214371, -49.219036) "
                  points="0.696465062 0.260039794 71.7322779 0.260039794 71.7322779 98.1780327 0.696465062 98.1780327"
                ></polygon>
                <g fillRule="nonzero" id="Rectangle">
                  <g opacity="0.1" transform="translate(1.857143, 1.857143)" fill="#000000">
                    <polygon
                      transform="translate(36.372226, 49.367595) rotate(-0.095425) translate(-36.372226, -49.367595) "
                      points="0.854319837 0.408598125 71.8901326 0.408598125 71.8901326 98.3265911 0.854319837 98.3265911"
                    ></polygon>
                  </g>
                  <polygon
                    fill="#FFFFFF"
                    transform="translate(36.214371, 49.219036) rotate(-0.095425) translate(-36.214371, -49.219036) "
                    points="0.696465062 0.260039794 71.7322779 0.260039794 71.7322779 98.1780327 0.696465062 98.1780327"
                  ></polygon>
                </g>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(21.635831, 29.779345) rotate(-0.095425) translate(-21.635831, -29.779345) "
                  points="6.11938064 28.5722007 37.1522808 28.5722007 37.1522808 30.9864898 6.11938064 30.9864898"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#F2F2F2"
                  fillRule="nonzero"
                  transform="translate(35.889419, 22.373928) rotate(-0.095425) translate(-35.889419, -22.373928) "
                  points="6.11009162 21.5614264 65.6687457 21.5614264 65.6687457 23.1864287 6.11009162 23.1864287"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#F2F2F2"
                  fillRule="nonzero"
                  transform="translate(35.954339, 63.918291) rotate(-0.095425) translate(-35.954339, -63.918291) "
                  points="6.17501162 63.1057896 65.7336657 63.1057896 65.7336657 64.7307918 6.17501162 64.7307918"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(35.833635, 70.789744) rotate(-0.095417) translate(-35.833635, -70.789744) "
                  points="6.18430772 70.0979574 65.4829614 70.0979574 65.4829614 71.4815307 6.18430772 71.4815307"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(35.842978, 73.556871) rotate(-0.095417) translate(-35.842978, -73.556871) "
                  points="6.19365167 72.8650848 65.4923053 72.8650848 65.4923053 74.2486581 6.19365167 74.2486581"
                ></polygon>
                <rect
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(31.283674, 76.333371) rotate(-0.095417) translate(-31.283674, -76.333371) "
                  x="6.19363883"
                  y="75.6415847"
                  width="50.1800696"
                  height="1.38357335"
                ></rect>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(26.905384, 33.405462) rotate(-0.095425) translate(-26.905384, -33.405462) "
                  points="6.12856965 32.7136749 47.6821987 32.7136749 47.6821987 34.0972483 6.12856965 34.0972483"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(26.905458, 36.181890) rotate(-0.095425) translate(-26.905458, -36.181890) "
                  points="6.12864376 35.4901035 47.6822728 35.4901035 47.6822728 36.8736768 6.12864376 36.8736768"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(21.672934, 50.551519) rotate(-0.095425) translate(-21.672934, -50.551519) "
                  points="6.15648349 49.3443746 37.1893837 49.3443746 37.1893837 51.7586636 6.15648349 51.7586636"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#F2F2F2"
                  fillRule="nonzero"
                  transform="translate(35.917236, 43.146117) rotate(-0.095425) translate(-35.917236, -43.146117) "
                  points="6.13790876 42.3336157 65.6965628 42.3336157 65.6965628 43.958618 6.13790876 43.958618"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(26.933310, 54.186844) rotate(-0.095425) translate(-26.933310, -54.186844) "
                  points="6.15649512 53.4950571 47.7101242 53.4950571 47.7101242 54.8786304 6.15649512 54.8786304"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(26.942561, 56.954064) rotate(-0.095425) translate(-26.942561, -56.954064) "
                  points="6.16574661 56.2622773 47.7193757 56.2622773 47.7193757 57.6458507 6.16574661 57.6458507"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(14.532182, 88.831883) rotate(-0.095425) translate(-14.532182, -88.831883) "
                  points="6.22145603 88.1400966 22.8429077 88.1400966 22.8429077 89.52367 6.22145603 89.52367"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(13.148669, 91.599102) rotate(-0.095425) translate(-13.148669, -91.599102) "
                  points="6.22151658 90.9073152 20.0758215 90.9073152 20.0758215 92.2908886 6.22151658 92.2908886"
                ></polygon>
                <g transform="translate(5.571429, 7.428571)" fill="#888888" fillRule="nonzero" id="Rectangle">
                  <polygon
                    transform="translate(7.437944, 1.258248) rotate(-0.095425) translate(-7.437944, -1.258248) "
                    points="0.510791856 0.752176025 14.3650968 0.752176025 14.3650968 1.76432029 0.510791856 1.76432029"
                  ></polygon>
                  <polygon
                    transform="translate(7.447225, 5.325376) rotate(-0.095425) translate(-7.447225, -5.325376) "
                    points="0.520072768 4.81930342 14.3743777 4.81930342 14.3743777 5.83144768 0.520072768 5.83144768"
                  ></polygon>
                  <polygon
                    transform="translate(7.447181, 3.291804) rotate(-0.095425) translate(-7.447181, -3.291804) "
                    points="0.520028741 2.78573199 14.3743337 2.78573199 14.3743337 3.79787625 0.520028741 3.79787625"
                  ></polygon>
                  <polygon
                    transform="translate(7.447176, 7.358947) rotate(-0.095425) translate(-7.447176, -7.358947) "
                    points="0.520023939 6.85287485 14.3743289 6.85287485 14.3743289 7.86501911 0.520023939 7.86501911"
                  ></polygon>
                </g>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(35.866165, 9.582895) rotate(-0.095425) translate(-35.866165, -9.582895) "
                  points="26.8636528 8.06003553 44.8686778 8.06003553 44.8686778 11.105754 26.8636528 11.105754"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#888888"
                  fillRule="nonzero"
                  transform="translate(35.870757, 13.733601) rotate(-0.095425) translate(-35.870757, -13.733601) "
                  points="29.6400339 12.2107421 42.1014797 12.2107421 42.1014797 15.2564606 29.6400339 15.2564606"
                ></polygon>
                <polygon id="Path" stroke="#888888" strokeWidth="0.5" points="50.7464286 6.955 50.765 16.6492857 65.65 16.6214286 65.6314286 6.92714286"></polygon>
              </g>
            </g>
            <g id="Group" transform="translate(81.714286, 45.500000)">
              <circle
                id="Oval"
                fill="#FF811A"
                transform="translate(20.176033, 20.421186) rotate(-13.285796) translate(-20.176033, -20.421186) "
                cx="20.1760334"
                cy="20.4211856"
                r="16.7136696"
              ></circle>
              <path
                d="M22.035,8.35714286 L22.035,21.3571429 C22.035,21.8771429 21.6264286,22.2857143 21.1064286,22.2857143 L11.8207143,22.2857143 C11.3007143,22.2857143 10.8921429,21.8771429 10.8921429,21.3571429 L10.8921429,19.5 C10.8921429,18.98 11.3007143,18.5714286 11.8207143,18.5714286 L18.3207143,18.5714286 L18.3207143,8.35714286 C18.3207143,7.83714286 18.7292857,7.42857143 19.2492857,7.42857143 L21.1064286,7.42857143 C21.6357143,7.42857143 22.035,7.83714286 22.035,8.35714286 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
