import * as React from 'react';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { PROPERTY_DETAILS_LAND_DIMENSIONS_LAND_AREA_AREA_UNIT_LOOKUP_OPTIONS } from '../../enums';
import { LandAreaModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
}

function LandArea({ name }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<LandAreaModel>(name);

  return (
    <FlexLayout flexDirection="row" justifyContent="flex-start" alignItems="baseline">
      <Field //
        name={fieldName('quantity')}
        component={InputField}
        disabled={disabled}
        label="Area"
        className={classes.quarterWidth}
      />
      <Field //
        aria-label="Area unit"
        name={fieldName('areaUnit')}
        component={RadioField}
        disabled={disabled}
        format="number"
        options={PROPERTY_DETAILS_LAND_DIMENSIONS_LAND_AREA_AREA_UNIT_LOOKUP_OPTIONS}
        className={classes.areaUnitLeftMargin}
      />
    </FlexLayout>
  );
}

export default React.memo(LandArea);
