import React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { getFormikError } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import PartyReference from '../../components/party-reference';
import { BaseCaveatModel } from '../../models';

interface Props {
  name: string;
  formikProps: FormikProps<BaseCaveatModel>;
  focusRef?: React.RefObject<ButtonBaseActions>;
  disabled: boolean;
  dialogPortalId: string;
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

const SectionMortgagees = ({
  formikProps,
  disabled,
  name,
  dialogPortalId,
  partyFormConfig,
  addressFormConfig,
  addressFormInitialValues,
  focusRef,
  syncPartyReferences
}: Props): JSX.Element => {
  const { values } = formikProps;

  const items = _get(values, name) ?? [];
  const hasMultipleItems = items.length > 1;
  const renderItem = ({ itemIndex }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
    const itemBindingPath = `${name}[${itemIndex}]`;
    return (
      <PartyReference //
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        formikProps={formikProps}
        name={itemBindingPath}
        data-testid={itemBindingPath}
        partyFormConfig={partyFormConfig}
        addressFormConfig={addressFormConfig}
        addressFormInitialValues={addressFormInitialValues}
        syncPartyReferences={syncPartyReferences}
      />
    );
  };

  const renderItemTitle = ({ itemIndex }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
    return hasMultipleItems ? `Mortgagee ${itemIndex + 1}` : '';
  };

  return (
    <Section //
      title={pluralize('Mortgagee', items.length)}
      data-name={name}
      data-testid={name}
      error={getFormikError(formikProps, name)}
    >
      <DocumentFieldArray
        arrayBinding={name}
        itemTitle={renderItemTitle}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        renderItem={renderItem}
        mode="fixed"
        focusRef={focusRef}
      />
    </Section>
  );
};

export default SectionMortgagees;
