import React from 'react';

import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { useStyles } from './styles';

interface Props {
  mode?: 'light' | 'dark';
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  href?: string;
  children: React.ReactNode;
  variant?: 'contained' | 'outlined';
  showArrow?: boolean;
}

function SignButton({ onClick, href, isLoading, mode = 'dark', disabled, children, variant = 'contained', showArrow = true }: Props) {
  const classes = useStyles();
  return (
    <SympliButton //
      href={href}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      arrowRight={showArrow}
      color="primary"
      variant={variant}
      classes={mode === 'dark' ? { root: classes.root } : undefined}
    >
      {children}
    </SympliButton>
  );
}

export default SignButton;
