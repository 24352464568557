import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { VIC_ADDRESS_FORM_INITIAL_VALUES as VIC_M_ADDRESS_FORM_INITIAL_VALUES } from '@sympli-mfe/document-components/address-field/vic/2-23/config';
import { VIC_PARTY_FORM_CONFIG as VIC_M_PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-23/config';
import SectionTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { PartyCapacityEnum, StateEnum } from '@sympli-mfe/enums-2-23/vic';

import { VIC_M_ADDRESS_FORM_CONFIG, VIC_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { MortgageeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup as PartyCapacityLookup } from '../../enums';
import { Mortgage2_23_1Model } from '../../models';

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionMortgageeDetails({ name, focusRef }: Props): JSX.Element {
  const {
    values: { mortgagors }
  } = useFormikContext<Mortgage2_23_1Model>();

  const mortgagorsPartyIds = useMemo(
    () =>
      mortgagors.proprietorGroups
        .flatMap(pg => pg.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgagors]
  );

  return (
    <SectionTenancy //
      name={name}
      jurisdiction={StateEnum.VIC}
      componentLabels={VIC_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG}
      // party setup
      partyFormConfig={VIC_M_PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG}
      partyCapacityLookup={PartyCapacityLookup}
      partyCapacityMinor={PartyCapacityEnum.Minor}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={mortgagorsPartyIds}
      // address setup
      addressFormConfig={VIC_M_ADDRESS_FORM_CONFIG}
      addressFormInitialValues={VIC_M_ADDRESS_FORM_INITIAL_VALUES}
      focusRef={focusRef}
    />
  );
}

export default React.memo(SectionMortgageeDetails);
