import { LookupEnumModel } from '@sympli/ui-framework/models';

/**
 path:
 - duplicateHoldingDetail[*].issuingInstructionDetails.issuingInstruction

 schema version:
[{"id":0,"value":"DuplicateToIssue","name":"Duplicate to Issue","_meta":null},{"id":1,"value":"NoDuplicateToIssue","name":"No duplicate to issue","_meta":null}]

 hash:
 WzAsMV0=
*/
export enum IssuingInstructionEnum {
  DuplicateToIssue = 0,
  NoDuplicateToIssue = 1
}
export const ISSUING_INSTRUCTION_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: IssuingInstructionEnum.NoDuplicateToIssue,
    name: 'No duplicate to issue'
  },
  {
    id: IssuingInstructionEnum.DuplicateToIssue,
    name: 'Duplicate to issue'
  }
];

/**
 path:
 - duplicateHoldingDetail[*].issuingInstructionDetails.issuingMethod

 schema version:
[{"id":0,"value":"Post","name":"Post","_meta":null},{"id":1,"value":"PickUp","name":"Pick Up","_meta":null},{"id":2,"value":"IssuingBox","name":"Issuing Box","_meta":null}]

 hash:
 WzAsMSwyXQ==
*/
export enum IssuingMethodEnum {
  Post = 0,
  PickUp = 1,
  IssuingBox = 2
}
export const ISSUING_METHOD_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: IssuingMethodEnum.Post,
    name: 'Post'
  },
  {
    id: IssuingMethodEnum.PickUp,
    name: 'Pick up'
  },
  {
    id: IssuingMethodEnum.IssuingBox,
    name: 'Issuing box'
  }
];

/**
 path:
 - duplicateHoldingDetail[*].issuingInstructionDetails.issuingOffice

 schema version:
[{"id":0,"value":"Midland","name":"Midland Office","_meta":null},{"id":1,"value":"Perth","name":"Perth Office","_meta":null}]

 hash:
 WzAsMV0=
*/
export enum IssuingOfficeEnum {
  Midland = 0,
  Perth = 1
}
export const ISSUING_OFFICE_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: IssuingOfficeEnum.Midland,
    name: 'Midland'
  },
  {
    id: IssuingOfficeEnum.Perth,
    name: 'Perth'
  }
];
