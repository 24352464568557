import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    inputContainer: {
      margin: 0,
      paddingTop: 10,
      paddingBottom: 10
    },
    stepper: {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: 15,
      backgroundColor: '#f2f2f2'
    },
    radioRoot: {
      width: 'auto',
      marginBottom: 10
    },
    radioFormControl: {
      border: 'none',
      background: 'none',
      fontSize: 13
    },
    radioFormControlLabel: {
      paddingBottom: 5,
      marginRight: 0
    },
    formControlLabelRoot: {
      '& .MuiFormControlLabel-label': {
        fontSize: 12
      }
    },
    rangeTypeColumn: {
      width: 224,
      padding: '5px 15px 0',
      boxSizing: 'border-box',
      borderRight: `1px solid ${theme.palette.divider}`
    },
    customDatesColumn: {
      width: 225,
      padding: '5px 15px 0',
      boxSizing: 'border-box'
    },

    customDateInput: {
      width: 120,
      marginLeft: 26
    },
    divider: {
      marginTop: 0,
      marginBottom: 8
    },
    error: {
      color: 'red',
      fontSize: 12,
      marginTop: 20
    },
    helperTextError: {
      zIndex: 2,
      width: 185
    }
  }),
  {
    name: 'LodgementDatepicker'
  }
);
