import React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { CT_DIRECTION_PREFERENCE_LOOKUP_OPTIONS, CtDirectionTypeEnum } from '../../enums';
import { updateIssuingInstructionsAndPartyIssued } from '../../helpers';
import { DirectionPreferenceTypeModel, LodgementInstructions_2_25_1 } from '../../models';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionDirectionPreference({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DirectionPreferenceTypeModel>(name);
  const formikProps = useFormikContext<LodgementInstructions_2_25_1>();
  const { values, setValues } = formikProps;
  const ectDirectionEnabled = disabled || (values.directionPreference && !values.directionPreference.editable);

  const handleCtDirectionChange = (_, resolvedValue: CtDirectionTypeEnum) => {
    const [issuingInstructionsUpdated, partyIssuedUpdated] = updateIssuingInstructionsAndPartyIssued(resolvedValue, values.workspaceType!, values.lodgementOrderSummary!);
    setValues(values => {
      return {
        ...values,
        issuingInstructions: issuingInstructionsUpdated,
        partyIssued: partyIssuedUpdated
      };
    });
  };

  return (
    <FormGroup //
      title={
        <>
          Select your preference
          <InfoIconWithTooltip
            flow="inline"
            offsetTop={0}
            tooltipContent="CT directions are only applicable when a new title is to be issued by the Land Registry.  They should only be completed by the party to whom the title will issue."
          />
        </>
      }
      fieldAreaDirection="column"
    >
      <Field //
        name={fieldName('ctDirection')}
        component={RadioField}
        disabled={ectDirectionEnabled}
        onChange={handleCtDirectionChange}
        format="number"
        label="Select your preference"
        options={CT_DIRECTION_PREFERENCE_LOOKUP_OPTIONS}
        vertical={true}
      />
    </FormGroup>
  );
}

export default React.memo(SectionDirectionPreference);
