import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

interface Props {
  className?: string;
}

function SectionHeader({ children, className }: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <Typography variant="h2" className={classNames(classes.title, className)}>
      {children}
    </Typography>
  );
}

export default React.memo(SectionHeader);
