import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';

import { ErrorHint } from '../icons';

export interface Props {
  icon?: JSX.Element;
  text: string;
  tooltipTitle?: JSX.Element;
  hideIcon?: boolean;
}

function IconTypography({ icon = <ErrorHint />, text, tooltipTitle, hideIcon }: Props) {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        gap: theme => theme.spacing(0.5)
      }}
    >
      {!hideIcon && icon}
      {tooltipTitle ? (
        <Tooltip title={tooltipTitle}>
          <Typography variant="body6">{text}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body6">{text}</Typography>
      )}
    </Box>
  );
}

export default React.memo(IconTypography);
