import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { SubLease2_21_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<SubLease2_21_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  lessors: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        partDescription: [''],
        interestsOnTitle: NO_FALLBACK
      }
    }
  },
  interestsOnTitle: NO_FALLBACK,
  lessees: resolveTenancyFallback(),
  documentAttachments: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        documentType: [AttachmentTypeEnum.ConditionsAndProvisions],
        attachment: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              fileName: [null],
              fileType: [null],
              id: [null],
              uploadedByParticipantId: NO_FALLBACK,
              uploadedDateTimeUtc: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  leaseDetails: {
    $children: {
      leasePeriod: {
        $children: {
          startDate: [null],
          endDate: [null],
          dateLength: [null]
        }
      },
      renewDetails: {
        $children: {
          optionToRenew: [false],
          renewalPeriods: {
            $shouldAddDefaultItemToEmptyArray: true,
            $arrayItem: {
              $children: {
                startDate: [null],
                endDate: [null],
                dateLength: [null]
              }
            }
          }
        }
      },
      optionToPurchase: [null]
    }
  },
  rentDetails: {
    $children: {
      amount: [null],
      description: [''],
      frequency: [''],
      termsAndConditions: [''],
      mcpNumber: ['']
    }
  }
};
