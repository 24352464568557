import * as React from 'react';

import { getIn, useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field/CheckboxField';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { Form24A_2_24_1Model, TransactionDataModel } from '../../models';
import { useStyles } from './styles';

type FormValues = Form24A_2_24_1Model;

function TransactionDetails() {
  const classes = useStyles();
  const { values, getValues, setFieldValue } = useFormikContext<FormValues>();
  const { disabled } = useDocumentContext();
  const name = 'transactionDetails';
  const fieldName = createModelKeyAppender<TransactionDataModel>(name);
  const settlementDate = values.transactionDetails.settlementDate && new Date(values.transactionDetails.settlementDate);

  const handleIsPossessionDateSameAsSettlementChange = (): void => {
    let currentTransactionData: TransactionDataModel = getIn(getValues(), name);
    if (currentTransactionData.isPossessionDateSameAsSettlement) {
      setFieldValue(name, {
        ...currentTransactionData,
        possessionDate: currentTransactionData.settlementDate!
      });
    }
  };

  return (
    <Section title="Details of transaction">
      <FormGroup title="Date of possession" fieldAreaDirection="column">
        <Field //
          label="Same as settlement date"
          name={fieldName('isPossessionDateSameAsSettlement')}
          component={CheckboxField}
          disabled={disabled}
          onChange={handleIsPossessionDateSameAsSettlementChange}
        />
        <Field //
          name={fieldName('possessionDate')}
          component={DatePickerField}
          disabled={disabled || values.transactionDetails.isPossessionDateSameAsSettlement}
          maxDate={settlementDate}
          className={classes.possessionDate}
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(TransactionDetails);
