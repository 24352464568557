import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchStampDutyDetails, actionUpdateStampDutyDetails, FetchStampDutyDetailRequestModel } from './actions';
import { StampDutyApiResponse } from './models';

export interface StampDutyState {
  detail?: StampDutyApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: FetchStampDutyDetailRequestModel;
}

const initialState: StampDutyState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useStampDuty(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.stampDuty);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  StampDutyState,
  Action
>(initialState)
  .handleAction(actionFetchStampDutyDetails.request, (state, action): StampDutyState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchStampDutyDetails.success, (state, action): StampDutyState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: action.payload.data
    };
  })
  .handleAction(actionFetchStampDutyDetails.failure, (state, action): StampDutyState => {
    return {
      ...state,
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateStampDutyDetails, (state, action): StampDutyState => {
    return {
      ...state,
      detail: {
        ...state.detail!,
        ...action.payload
      }
    };
  });

export default reducer;
