import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dialogContent: {
      '&:first-child': {
        paddingTop: 0
      }
    },
    headingIcon: {
      fill: theme.palette.secondary.main,
      width: 28,
      height: 28
    },
    headingText: {
      marginLeft: 8
    },
    options: {
      listStyle: 'none',
      margin: 0,
      padding: 0
    }
  }),
  {
    name: 'AbandonLodgementOnlyDialog'
  }
);
