import { AssignmentTypeEnum, DashboardDateRangeModel } from 'src/containers/dashboard/shared/models';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel, InvitationsDashboardV2ApiRequest } from '../models';
import { DashboardInvitationTypeFilterEnum } from '../table/models';

// to be discussed, what backend will send back to us
export interface AggregatorComponentBaseProps {
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(payload: AggregatorsQueryParamsModel): Promise<InvitationsDashboardAggregatorApiResponse>;
  onActionClick(filters: InvitationsDashboardV2ApiRequest, engagedTab: InvitationsDashboardRouteTabsEnumV2, currentTabFilters: InvitationsDashboardUiFilterModel): void;
}

// might be easier if we can just have the object per type
interface Aggregators {
  name: string;
  count: number;
}

export interface AggregatorsQueryParamsModel {
  assignmentType: AssignmentTypeEnum;
  queries: AggregatorQuery[];
}

export interface AggregatorQuery {
  name: string;
  invitationType: DashboardInvitationTypeFilterEnum;
  lastInvitationSentPeriod?: DashboardDateRangeModel;
}

export type InvitationsDashboardAggregatorApiRequest = AggregatorsQueryParamsModel;
export type InvitationsDashboardAggregatorApiResponse = Aggregators[];

export enum AggregatorNameEnum {
  totalReceived = 'total-received',
  totalSent = 'total-sent',
  todayReceived = 'today-received',
  todaySent = 'today-sent'
}

export interface TodayCountModel {
  received: number;
  sent: number;
}

export interface TotalCountModel {
  received: number;
  sent: number;
}
