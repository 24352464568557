import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// this file was automatically generated from sections/styles.ts.mustache
export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    partyTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      display: 'block'
    },
    partyDisplay: {
      marginBottom: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: theme.spacing(2.5)
      }
    },
    noSurvivingsMessage: {
      padding: theme.spacing(2.5),
      fontSize: 13,
      fontStyle: 'italic'
    }
  }),

  {
    name: 'SurvivingJointTenants'
  }
);
