import queryString from 'query-string';

import endpoints from '@sympli/api-gateway/endpoints';
import { UpdateWorkspaceDocumentApiRequestBody, UploadDocumentAttachmentsApiResponse } from '@sympli/api-gateway/models';

import http, { AxiosRequestConfig } from 'src/utils/http';
import { DocumentDetailApiRequest, SaveDocumentFormRequest } from '../../models';

export const saveDocumentForm = (data: object, query: SaveDocumentFormRequest, hash: string = '') => {
  const body: UpdateWorkspaceDocumentApiRequestBody = {
    data: JSON.stringify(data),
    hash
  };
  return endpoints.updateDocument(query, body);
};

export const uploadDocumentAttachments = async (
  files: File[],
  { workspaceId, documentId, participantId }: DocumentDetailApiRequest
): Promise<UploadDocumentAttachmentsApiResponse['files']> => {
  const svc = `/workspaces/${encodeURIComponent(workspaceId)}/documents/${documentId}/participants/${participantId}/attachments`;

  const formData = new FormData();
  files.forEach(d => formData.append('Files', d));

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  // TODO logging
  const response = await http.post<{
    files: UploadDocumentAttachmentsApiResponse['files'];
  }>(svc, formData, config);
  return response.files;
};

export const downloadDocumentAttachment = (attachmentId: string, { workspaceId, documentId, participantId }: DocumentDetailApiRequest) => {
  const query = queryString.stringify({ attachmentId });
  const svc = `/workspaces/${encodeURIComponent(workspaceId)}/documents/${documentId}/participants/${participantId}/attachments?${query}`;
  // TODO logging
  return http.get<{
    pdfUrl: string;
    expiry: string;
  }>(svc);
};
