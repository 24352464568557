import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { getCaveatsBeingWithdrawn, getRegisteredProprietors, getSelectedCaveat, getSelectedTitleReferences } from './helpers';
import { ProprietorModel, WithdrawalOfCaveat2_23_1Model } from './models';

// this file was automatically generated from checks.ts.mustache
export const VISIBILITY_CHECK_WITHDRAW_CAVEAT = createContextCheck((context: WithdrawalOfCaveat2_23_1Model): boolean => {
  // skip this validation if no titles are selected
  const selectedTitles = getSelectedTitleReferences(context.titleReferences);
  if (!selectedTitles.length) {
    return false;
  }

  // verify that caveats share some common title reference
  return getCaveatsBeingWithdrawn(context.caveats, selectedTitles).length > 0 && (getRegisteredProprietors(selectedTitles) ?? []).length > 0;
});

export const VISIBILITY_CHECK_CAVEATORS = createContextCheck((context: WithdrawalOfCaveat2_23_1Model): boolean => {
  const selectedCaveat = getSelectedCaveat(context.withdrawCaveats);
  return selectedCaveat !== undefined;
});

// only display when there is party capacity returned from api
// otherwise hide the entire field
export const VISIBILITY_CHECK_PARTY_CAPACITY = createContextCheck((context: ProprietorModel): boolean => context.partyCapacity != null && !!context.partyCapacity.partyCapacity);

// only display when there is party capacity and detail returned from api
// otherwise hide the entire field
export const VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL = createContextCheck(
  (context: ProprietorModel): boolean => context.partyCapacity != null && !!context.partyCapacity.partyCapacityDetail
);
