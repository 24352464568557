import React from 'react';

import { useFormikContext } from 'formik';
import Divider from '@mui/material/Divider';

import PartyForm, { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import Subform from '@sympli/ui-framework/components/formik/subform';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import PartyCapacity from '../../../party-capacity';
import DateOfDeath from '../../components/date-of-death';
import EvidenceArray from '../../components/evidence-array';
import { DeceasedTenancyConfig } from '../../deceasedTenancyConfig';
import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorModel } from '../../models';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_DATE_OF_DEATH } from './visibilityChecks';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props<TNameChange extends {}> {
  name: string;
  partyFormConfig: PartyFormConfig<TNameChange>;
  maxEvidences: number;
  proprietor: DeceasedProprietorModel;
  showEvidences: boolean;
  onPartyTypeChange(partyType: PartyTypeEnum): void;
  deceasedTenancyConfig: DeceasedTenancyConfig;
}

function DeceasedProprietor<TNameChange extends {}>({
  name,
  partyFormConfig,
  maxEvidences,
  proprietor,
  showEvidences,
  onPartyTypeChange,
  deceasedTenancyConfig
}: Props<TNameChange>): JSX.Element {
  const { divider } = useStyles();
  const { disabled } = useDocumentContext();

  const { values } = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { partyBook } = values;
  const fieldName = createModelKeyAppender<DeceasedProprietorModel>(name);
  const partyBookIndex = partyBook.findIndex(party => party.id === proprietor.partyBookId);
  const partyBindingPath = `partyBook[${partyBookIndex}]`;

  const party = partyBook[partyBookIndex];

  // if party merge marks that the party requires name change and it is not provided yet, disable all fields after party form
  const requiresJustificationButIsNotChanging = Boolean(
    party.mergeMetadata?.requiresJustification && party.receivingOrRelinquishingDetails.isRelinquishing && !party.receivingOrRelinquishingDetails.isChangingName
  );

  const partyConfig: PartyFormConfig = { ...partyFormConfig, nameChangeConfig: !proprietor.isSelected ? undefined : partyFormConfig.nameChangeConfig };

  return (
    <div data-section={name} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Subform //
          subformBindingPath={partyBindingPath}
          component={PartyForm}
          disabled={disabled}
          config={partyConfig}
          showFormGroups={false}
          onPartyTypeChange={onPartyTypeChange}
        />
        {proprietor.partyCapacity && <PartyCapacity name={fieldName('partyCapacity')} partyCapacity={proprietor.partyCapacity} isReadonly />}
        {VISIBILITY_CHECK_DATE_OF_DEATH(proprietor) && (
          <>
            <Divider className={divider} />
            <DateOfDeath //
              name={fieldName('dateOfDeath')}
              disabled={disabled || requiresJustificationButIsNotChanging}
              dateOfDeathLookupOptions={deceasedTenancyConfig.dateOfDeathLookupOptions}
              allowTodayDeathOfDate={deceasedTenancyConfig.allowTodayDeathOfDate}
            />
          </>
        )}
        {showEvidences && (
          <>
            <Divider className={divider} />
            <EvidenceArray //
              name={fieldName('evidences')}
              maxItems={maxEvidences}
              disabled={disabled || requiresJustificationButIsNotChanging}
              proprietorSelected={proprietor.isSelected}
              deceasedTenancyConfig={deceasedTenancyConfig}
            />
          </>
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(DeceasedProprietor);
