import { NSW_ADDRESS_FORM_CONFIG, NSW_PHYSICAL_ADDRESS_ITEM } from '@sympli-mfe/document-components/address-field/nsw/2-17/config';
import { ADD_NEW, AddressFieldFormConfig, AddressFormValueModel, AddressTypeEnum, PhysicalAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';

export const NSW_CAVEAT_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...NSW_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false, // turn off pobox address
  overseasAddressFormConfig: false // turn off
};

// we want to allow only NSW addresses

export const NSW_CAVEAT_SERVICE_OF_NOTICE_ADDRESS_FORM_INITIAL_VALUES: AddressFormValueModel<PhysicalAddressEntityModel> = {
  existingOrNew: ADD_NEW,
  isEdit: true, // turn the edit more, which means we show the form and turn on the validation
  addressDetail: {
    type: AddressTypeEnum.PhysicalAddress,
    physicalAddress: {
      ...NSW_PHYSICAL_ADDRESS_ITEM
    }
  }
};
