import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS, AttachmentTypeEnum } from '@sympli-mfe/enums-2-25/wa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// only subset of NECDS lookup options
export const WA_M_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<string>[] = restrictedLookupOptions(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS, {
  whitelist: [
    AttachmentTypeEnum.ConsentLetter,
    AttachmentTypeEnum.CourtOrder,
    AttachmentTypeEnum.Other,
    AttachmentTypeEnum.PaidDutyCertificate,
    AttachmentTypeEnum.StatutoryDeclaration,
    AttachmentTypeEnum.TermsAndConditions
  ]
});
