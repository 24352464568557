import * as React from 'react';

import { Location } from 'react-router-dom';

import Logger from '@sympli/ui-logger';

import { ErrorBoundaryInjectedProps, Props } from './ErrorBoundaryContainer';

interface State {
  error: Error | null;
  componentStack: string | null;
  eventId: string | null;
}

const initialState: Readonly<State> = {
  error: null,
  componentStack: null,
  eventId: null
};

type Route = {
  location: Location;
};

class ErrorBoundary<TComponentProps> extends React.Component<React.PropsWithChildren<Props<TComponentProps>> & Route, State> {
  public readonly state = initialState;

  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo): void {
    const scope = Logger.getDefaultScope();
    this.props.beforeCapture?.(scope, error, componentStack);

    scope.setExtraAttributes({ componentStack });
    const eventId = Logger.captureException(error, scope);

    this.props.onError?.(error, componentStack, eventId);

    this.setState({
      error,
      componentStack,
      eventId
    });
  }

  componentDidUpdate(prevProps: React.PropsWithChildren<Props<TComponentProps>> & Route) {
    // for top level ErrorBoundary we need to make sure that we try to rerender the content again whenever pathname has changed
    // because top level means it typically wraps React Router that in case of error is no longer rendered to DOM so it can't handle pathname changes anymore
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(initialState);
    }
  }

  render() {
    const { beforeCapture, onError, children, component, ...componentProps } = this.props;
    const Fallback = component as <TComponentProps>(props: React.PropsWithChildren<TComponentProps & Partial<ErrorBoundaryInjectedProps>>) => JSX.Element;

    const { error, componentStack, eventId } = this.state;

    if (error) {
      return (
        <Fallback //
          error={error}
          reset={() => {
            this.setState(initialState);
          }}
          componentStack={componentStack}
          eventId={eventId}
          {...componentProps}
        >
          {children}
        </Fallback>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
