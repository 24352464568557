import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionMortgageDate({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();

  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Mortgage date"
        fieldAreaDirection="column"
      >
        <Field //
          aria-label="Mortgage date"
          name={name}
          component={DatePickerField}
          disabled={disabled}
        />
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionMortgageDate);
