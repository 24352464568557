import * as React from 'react';

import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { PORTAL_ID } from './models';

function SettingsTopPanelPortalTarget() {
  return <PortalTarget id={PORTAL_ID} />;
}

export default SettingsTopPanelPortalTarget;
