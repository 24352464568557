import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { mergeArrays } from '@sympli-mfe/document-forms-framework/utils';

import { Lease2_21_1Model, LessorPartyModel, TitleReferenceModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';

export const filterLessors = (titleReferences: TitleReferenceModel[]): LessorPartyModel[] => {
  const result = mergeArrays(
    titleReferences.filter(r => r.isSelected).map(x => x.lessors),
    'partyBookId'
  );
  return result;
};

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const getLessorPartyBookIds = defaultMemoize((selectedTitles: TitleReferenceModel[]) => {
  const lessorPartyBookIds = selectedTitles.map(title => title.lessors.map(lessor => lessor.partyBookId)).flat();
  return _uniq(lessorPartyBookIds);
});

export function isLessorNameMismatch({ titleReferences, partyBook }: Lease2_21_1Model): boolean {
  const selectedTitles = titleReferences.filter(s => s.isSelected);
  if (selectedTitles.length <= 1) {
    return false;
  }

  // Map title references to lessor party legal entity names
  const titleReferenceToLessorNameMap = titleReferences
    .filter(x => x.isSelected)
    .reduce<Map<string, string[]>>(
      //
      (result, titleReference) =>
        result.set(
          titleReference.reference!,
          titleReference.lessors.map(l => partyBook.find(pb => pb.id === l.partyBookId)!.legalEntityName!)
        ),
      new Map<string, string[]>()
    );

  for (let [titleReference, lessorNames] of titleReferenceToLessorNameMap) {
    // Grab all lessor names not in titleReference
    const lessorNamesToCompare = new Map([...titleReferenceToLessorNameMap].filter(([tr, _]) => tr !== titleReference)).values();
    for (let names of lessorNamesToCompare) {
      // Check all lessor names are in lessorNamesToCompare
      if (!lessorNames.every(lessorName => names.some(n => n === lessorName))) {
        return true;
      }
    }
  }
  return false;
}
