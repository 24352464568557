import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { StampDutyPaymentMethodEnum } from './models';

export default function getValidationSchema() {
  const yupObject = {
    paymentOption: yup.number().required(msg.REQUIRED),
    bankDetails: yup.object({}).when('paymentOption', {
      is: (value: StampDutyPaymentMethodEnum) => value === StampDutyPaymentMethodEnum.CommittedAsPartOfElno,
      then: yup.object({
        bsb: yup.string().required(msg.REQUIRED).length(6, msg.LENGTH_MUST_BE_X_NUMBERS(6)),
        accountNumber: yup
          .string() //
          .required(msg.REQUIRED)
          .matches(/^\d*$/, 'Must be numbers only')
          .max(9, msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(9)),
        accountName: yup.string().required(msg.REQUIRED).max(32, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(32))
      })
    })
  };
  const schema = yup.object(yupObject);
  return schema;
}
