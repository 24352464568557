import dateFormat from 'dateformat';

import { JurisdictionsEnum, SettlementDateKindEnum, WorkspaceRoleEnum, WorkspaceTransactionTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import { prepareTitleForSubmission } from 'src/containers/shared/verify-property-section/helpers';
import { TitleVerificationStatusEnum } from 'src/containers/shared/verify-property-section/models';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { CreateWorkspaceInvitationRequestItemModel } from '../../models';
import { CreateFinancialWorkspaceSharedModel, InvitationModel, WorkspaceInviteParticipantsFormModel } from './models';
import { CreateFinancialWorkspaceInvitationsStepRequest, InvitationItemModel } from './steps/invite-participants/models';

export function prepareFinSubmitData(
  values: CreateFinancialWorkspaceSharedModel,
  currentSubscriberData: UserProfileModel
): Omit<CreateFinancialWorkspaceInvitationsStepRequest, 'workspaceTypeId'> {
  const { matterDetailsStepData, inviteParticipantsStepData } = values;
  const {
    //
    isInteroperable,
    jurisdictionId,
    createdByRoleId,
    createdByReference,
    settleDate,
    settleTime,
    titleInformation: { titleVerificationResult },
    groupId,
    instructingOrganisationSettlementAgentId,
    transactionTypeId,
    transactionTypeOtherDescription
  } = matterDetailsStepData;
  const { vendors } = inviteParticipantsStepData;

  const formattedSettleDate = settleDate ? dateFormat(settleDate, DateFormatEnum.DATE) : undefined;
  // titles will be always present in this step
  const titleReferences = titleVerificationResult?.titles.map(title => prepareTitleForSubmission(jurisdictionId!, title.titleReference)) || [];
  const invitations = collectInvitations(inviteParticipantsStepData, currentSubscriberData);

  // WEB-16073 - the current subscriber (workspace creator) chose to be the controlling party
  // so send an invite to the current subscriber as a party to the linked workspace
  if (isSelfControllingParty(matterDetailsStepData)) {
    const { subscriberId, name, subscriberAbn } = currentSubscriberData;
    invitations.push({
      roleId: WorkspaceRoleEnum.ControllingParty,
      subscriberId: subscriberId,
      groupId,
      reference: createdByReference,
      displayName: name,
      abn: subscriberAbn
    });
  }
  const transactionTypeOtherDescriptionToBePosted = transactionTypeId === WorkspaceTransactionTypeEnum.Other ? transactionTypeOtherDescription?.trim() : undefined;
  return {
    isInteroperable,
    // jurisdictionId will be always present in this step
    jurisdictionId: jurisdictionId as JurisdictionsEnum,
    createdByReference,
    // createdByRoleId will be always present in this step
    createdByRoleId: createdByRoleId as WorkspaceRoleEnum,
    instructingOrganisationSettlementAgentId: instructingOrganisationSettlementAgentId?.length ? instructingOrganisationSettlementAgentId : undefined,
    vendors,

    settlementDate: formattedSettleDate ? `${formattedSettleDate}T${settleTime ? settleTime : '00:00'}:00.000Z` : undefined,
    settlementDateKind: getSettlementDateKind(settleDate, settleTime),
    titleReferences,
    groupId,
    invitations,
    transactionTypeId: transactionTypeId,
    transactionTypeOtherDescription: transactionTypeOtherDescriptionToBePosted
  };
}

function getSettlementDateKind(settleDate: string | Date | null | undefined, settleTime?: string) {
  if (settleTime && settleDate) {
    return SettlementDateKindEnum.DateAndTime;
  } else if (settleDate) {
    return SettlementDateKindEnum.DateOnly;
  }
  return undefined;
}

export function isSelfControllingParty(matterDetailsStepData) {
  return (
    matterDetailsStepData.jurisdictionId === JurisdictionsEnum.VIC && //
    matterDetailsStepData.titleVerificationResult?.status === TitleVerificationStatusEnum.WarningPaperTitle && //
    matterDetailsStepData.isControllingParty // current subscriber chose to be a controlling party
  );
}

export function collectInvitations(data: WorkspaceInviteParticipantsFormModel, currentSubscriber: UserProfileModel) {
  // find checked roles
  const { invitedRoles, additionalInvitations, invitations } = data;

  const apiInvitations = Object.keys(invitedRoles)
    .filter(role => invitedRoles[role])
    .reduce((result: CreateWorkspaceInvitationRequestItemModel[], roleId) => {
      const { invitation } = invitations.find(i => i.role === Number(roleId))!;
      const invitationRequest = mapToCreateWorkspaceInvitationRequest(invitation, currentSubscriber, roleId);
      result.push(invitationRequest);
      return result;
    }, []);
  const apiAdditionalInvitations = additionalInvitations
    .filter(additionalInvitation => additionalInvitation.role !== undefined)
    .map((additionalInvitation: InvitationModel) =>
      mapToCreateWorkspaceInvitationRequest(additionalInvitation.invitation, currentSubscriber, additionalInvitation.role!.toString())
    );
  return apiInvitations.concat(apiAdditionalInvitations);
}

function mapToCreateWorkspaceInvitationRequest(
  { subscriber: { id, elnoId, elnoSubscriberId, name, abn }, groupId, matterNumber, inviteRoleAsSelf, invitationDescription }: InvitationItemModel,
  { subscriberId, subscriberName, subscriberElnoId, subscriberAbn }: UserProfileModel,
  roleId: string
): CreateWorkspaceInvitationRequestItemModel {
  if (inviteRoleAsSelf) {
    return {
      subscriberId: subscriberId,
      roleId: Number(roleId),
      elnoId: subscriberElnoId,
      groupId: groupId,
      reference: matterNumber,
      displayName: subscriberName,
      abn: subscriberAbn
    };
  }
  return {
    subscriberId: subscriberElnoId === elnoId ? id : undefined,
    roleId: Number(roleId),
    elnoId: elnoId,
    elnoSubscriberId: elnoSubscriberId, // Sympli subscribers will not have this populated
    displayName: name,
    invitationDescription: invitationDescription,
    abn: abn
  };
}
