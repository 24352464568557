import queryString from 'query-string';
import { all, call, put, takeLatest } from 'typed-redux-saga';

import { convertDateOrNull } from 'src/components/date-range-picker/helpers';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { actionFetchAllDirections } from 'src/containers/workspace/financial/all-directions/actions';
import { actionFetchSettlementDetails } from 'src/containers/workspace/financial/settlement-date/actions';
import {
  actionFetchWorkspaceBasicInfo,
  actionFetchWorkspaceById,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceParticipants,
  actionFetchWorkspaceTasks
} from 'src/containers/workspace/shared/detail/actions';
import environments from 'src/environments';
import http from 'src/utils/http';
import { actionFetchFinancialWorkspacesFeedDetailV2, actionFetchFinancialWorkspacesFeedV2 } from './actions';
import { FinancialWorkspacesV2ApiRequest, FinancialWorkspacesV2ApiResponse } from './models';

// note: dashboard is using v2 endpoint
const { TARGET_MACHINE, TARGET_API_V2 } = environments;

const fetchFinancialWorkspacesV2Feed = (q: FinancialWorkspacesV2ApiRequest) => {
  const uri = '/dashboards/financial';
  const { settlementPeriod, lastAccessPeriod, archivedDatePeriod, assignmentType: existingAssignmentType, ...rest } = q;

  // reassign is equal to assign to group
  const assignmentType = existingAssignmentType === AssignmentTypeEnum.ReAssigned ? AssignmentTypeEnum.AssignedToGroup : existingAssignmentType;

  const query = queryString.stringify({
    ...rest,
    ...convertDateOrNull(lastAccessPeriod, 'lastAccessPeriod'),
    ...convertDateOrNull(settlementPeriod, 'settlementPeriod'),
    ...convertDateOrNull(archivedDatePeriod, 'archivedDatePeriod'),
    assignmentType
  });

  return http.get<FinancialWorkspacesV2ApiResponse>(uri + '?' + query, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};

export function* sagaFetchFinancialWorkspacesFeedV2(action: ReturnType<typeof actionFetchFinancialWorkspacesFeedV2.request>) {
  try {
    const data = yield* call(fetchFinancialWorkspacesV2Feed, action.payload);

    yield put(actionFetchFinancialWorkspacesFeedV2.success({ data }));
  } catch (error) {
    yield all([
      //
      yield put(actionFetchFinancialWorkspacesFeedV2.failure({ error }))
    ]);
  }
}

export function* sagaFetchFinancialWorkspacesFeedDetailV2(action: ReturnType<typeof actionFetchFinancialWorkspacesFeedDetailV2.request>) {
  try {
    const { workspaceId, participantId } = action.payload;
    yield all([
      put(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId })),
      put(actionFetchWorkspaceById.request({ workspaceId, participantId })),
      put(actionFetchWorkspaceDocuments.request({ workspaceId, participantId })),
      put(actionFetchWorkspaceParticipants.request({ workspaceId, participantId })),
      put(actionFetchWorkspaceTasks.request({ workspaceId, participantId })),
      put(actionFetchAllDirections.request({ workspaceId, participantId })),
      put(actionFetchSettlementDetails.request({ workspaceId, participantId }))
    ]);
  } catch (error) {
    yield all([
      //
      yield put(actionFetchFinancialWorkspacesFeedV2.failure({ error }))
    ]);
  }
}

export default [
  //
  takeLatest(actionFetchFinancialWorkspacesFeedV2.request, sagaFetchFinancialWorkspacesFeedV2),
  takeLatest(actionFetchFinancialWorkspacesFeedDetailV2.request, sagaFetchFinancialWorkspacesFeedDetailV2)
];
