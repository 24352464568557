import React, { memo, useCallback } from 'react';

import { useField } from 'formik';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { createModelKeyAppender } from 'src/utils/formUtils';
import { DocumentWithInvitationModel } from '../../../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
}

function YourClients({ name }: Props) {
  const fieldName = createModelKeyAppender<DocumentWithInvitationModel>(name);

  const [, { value, error, touched }, { setValue }] = useField<DocumentWithInvitationModel>(name);
  const { roleOptions, selectedRoleIds } = value;
  const classes = useStyles();

  const handleOnSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let selectedRoleIds: WorkspaceRoleEnum[] = [];
      if (e.target.checked) {
        selectedRoleIds = roleOptions.map(({ id }) => id);
      }
      // update field value
      setValue({
        ...value,
        selectedRoleIds
      });
    },
    [setValue, roleOptions, value]
  );

  const checkedAll = roleOptions.length === selectedRoleIds.length;

  return (
    <>
      <FlexLayout alignItems="center">
        <Typography variant="subtitle1">Your clients</Typography>
        <Checkbox //
          name={name}
          label="Select all"
          checked={checkedAll}
          className={classes.checkboxSelectAll}
          onChange={handleOnSelectAll}
          error={touched && typeof error === 'string' ? error : undefined}
        />
      </FlexLayout>
      <Divider className={classes.divider} />
      <Field
        aria-label="Selected roles"
        component={CheckboxGroupField} //
        name={fieldName('selectedRoleIds')}
        vertical={true}
        format="number"
        options={value.roleOptions}
        fullWidth
      />
    </>
  );
}

export default memo(YourClients);
