import { $eq, $get, $oneOfItems, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum } from './enums';
import { DischargeOfMortgageDocument2_26_0Model } from './models';

// this file was automatically generated from checks.ts.mustache
export const VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES = createContextCheck((context: DischargeOfMortgageDocument2_26_0Model): boolean =>
  $oneOfItems(context.mortgages ?? [], item => item.isSelected === true)
);

/**
 path:
 - dischargeAmount

 schema version:
[
  {
    "extentOfMoneyDischarged": 1
  }
]
*/
export const VISIBILITY_CHECK_DISCHARGE_AMOUNT = createContextCheck((context: DischargeOfMortgageDocument2_26_0Model): boolean =>
  $eq($get(context, 'extentOfMoneyDischarged'), ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum.Part)
);
