import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { NewDeleteIcon } from '@sympli/ui-framework/icons';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchWorkspaceBasicInfo, actionFetchWorkspaceById } from 'src/containers/workspace/shared/detail/actions';
import { TitleRouteParams } from 'src/containers/workspace/titles/models';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { TitleListModel } from '../../models';
import RemoveTitleDialog from '../remove-title-dialog';
import { TitlesAndAddressesBaseProps } from '../titles-and-addresses-base/TitlesAndAddressesBase';
import { deleteTitle } from './api';
import { useStyles } from './styles';
import TitlesAndAddressesDelete from './TitlesAndAddressesDelete';

interface Props extends TitlesAndAddressesBaseProps {
  onCancel(): void;
  workspaceType: WorkspaceTypeEnum;
}

function TitlesAndAddressesDeleteContainer(props: Props) {
  const { titleReferences, workspaceId, participantId, onCancel } = props;
  const dispatch = useSafeDispatch(useDispatch());
  const navigate = useNavigate();
  const queryParams = useRouterParams<TitleRouteParams>();
  const [dialogState, setDialogState] = React.useState<{
    //
    isOpen: boolean;
    title?: TitleListModel;
  }>({
    //
    isOpen: false,
    title: undefined
  });
  const [isDeleting, setIsDeleting] = React.useState(false);
  const classes = useStyles();

  // add delete icons and assign handler that displays dialog
  const itemsToDelete = React.useMemo(() => {
    return titleReferences.map(item => {
      return {
        ...item,
        icon: (
          <NewDeleteIcon
            role="button"
            className={classes.deleteIcon}
            onClick={(event: React.MouseEvent) => {
              // prevent default behaviour which is navigation to a particular title
              event.preventDefault();
              event.stopPropagation();

              setDialogState({
                //
                isOpen: true,
                title: item
              });
            }}
          />
        )
      };
    });
  }, [titleReferences, classes]);

  const handleOnClose = () => {
    setDialogState({
      //
      isOpen: false,
      title: undefined
    });
  };

  const handleOnNext = async () => {
    setIsDeleting(true);
    try {
      await deleteTitle(workspaceId, dialogState.title!.reference, participantId);
      setIsDeleting(false);
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: 'Title has been deleted',
          autoHideDuration: 5000,
          variant: 'new-success'
        })
      );
      handleOnClose();

      batch(() => {
        dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
        dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));

        if (queryParams.titleReference === dialogState.title!.reference) {
          navigate(resolveWorkspaceDetailLink({ workspaceId: queryParams.workspaceId, participantId: queryParams.participantId }));
        }
      });

      // close the TitlesAndAddressesDelete
      onCancel();
    } catch (error) {
      setIsDeleting(false);
      dispatch(actionCreateGlobalErrorMessage(error));
    }
  };

  return (
    <>
      <TitlesAndAddressesDelete {...props} titleReferences={itemsToDelete} />
      <RemoveTitleDialog isOpen={dialogState.isOpen} titleReference={dialogState.title?.reference ?? ''} isDeleting={isDeleting} onNext={handleOnNext} onClose={handleOnClose} />
    </>
  );
}

export default React.memo(TitlesAndAddressesDeleteContainer);
