import { defaultMemoize } from 'reselect';

import { NoaPropertyDetailModel, TitleReferenceModel } from './models';

export const checkIfAllTitlesAreAssigned = defaultMemoize(
  (arePropertiesSeparatelyRated: boolean, propertyDetails: NoaPropertyDetailModel[], titleReferences: TitleReferenceModel[]): boolean => {
    if (!arePropertiesSeparatelyRated) {
      return true;
    }
    const usedTitles = propertyDetails.flatMap(pd => pd.titleReferences.filter(Boolean));
    return titleReferences.length === usedTitles.length;
  }
);
