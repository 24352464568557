import dateFormat from 'dateformat';

import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-18/config';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import { VIC_NOA_ADDRESS_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { checkIfAllTitlesAreAssigned } from './helper';
import { ApiNoticeOfAcquisitionDocumentModel, NoticeOfAcquisitionDocumentModel, TransactionDetailModel } from './models';
import { VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL } from './visibilityChecks';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: VIC_NOA_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: VIC_PARTY_FORM_CONFIG
  // Add name change conversion here if necessary
  // eg. nameChangeConversion: saNameChangeConversion
});

const fieldNameOfTransactionDetail = modelKey<TransactionDetailModel>();

function convertFromApiToFormModel(apiModel: ApiNoticeOfAcquisitionDocumentModel, { currentParticipant }: IConverterContext): NoticeOfAcquisitionDocumentModel {
  // Implement any necessary conversion from API to form values
  let formModel: NoticeOfAcquisitionDocumentModel = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || []),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
    transfereePrimaryContactPartyBookId: null
  };
  formModel = applyDefaultMap(formModel, fallbackMap);
  // assign users role in current workspace
  formModel.workspaceRoleId = currentParticipant?.workspaceRole.id;

  // calculate primary contact only if section is visible to the user
  if (VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL(formModel)) {
    // select transferee marked as lead owner if exists
    formModel.transfereePrimaryContactPartyBookId = formModel.transferees.find(t => t.isLeadOwner)?.partyBookId ?? null;
    // make sure that lead owner is selected
    if (!formModel.transfereePrimaryContactPartyBookId && formModel.transferees.length === 1) {
      formModel.transferees[0].isLeadOwner = true;
      formModel.transfereePrimaryContactPartyBookId = formModel.transferees[0].partyBookId;
    }
  }
  formModel.allTitlesAssigned = checkIfAllTitlesAreAssigned(formModel.arePropertiesSeparatelyRated, formModel.propertyDetails, formModel.titleReferences);
  // assign users matter number
  formModel.matter = currentParticipant?.reference;

  return formModel;
}

function convertFromFormToApiModel(formModel: NoticeOfAcquisitionDocumentModel, originalApiModel: ApiNoticeOfAcquisitionDocumentModel): ApiNoticeOfAcquisitionDocumentModel {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    transfereePrimaryContactPartyBookId,
    transferees,
    transferors,
    ...rest
  } = formModel;

  formModel.transactionDetail.contractDate = formModel.transactionDetail.contractDate ? dateFormat(formModel.transactionDetail.contractDate, DateFormatEnum.DATE) : null;
  formModel.transactionDetail.dateOfPossessionTransfer = formModel.transactionDetail.dateOfPossessionTransfer
    ? dateFormat(formModel.transactionDetail.dateOfPossessionTransfer, DateFormatEnum.DATE)
    : null;

  // convert currency values to make sure that we sent 0 in cases when field was left empty
  [
    // --- monetary ---
    // details of transaction
    fieldNameOfTransactionDetail('gstAmount'),
    fieldNameOfTransactionDetail('deposit'), //
    // additional amounts
    fieldNameOfTransactionDetail('businessGoodwillAmount'),
    fieldNameOfTransactionDetail('businessIntellectualPropertyAmount'),
    fieldNameOfTransactionDetail('goodsComponentAmount'),
    fieldNameOfTransactionDetail('contractRebateAmount')
    // --- non-monetary ---
    // market value
  ].forEach(field => {
    formModel.transactionDetail[field] = formModel.transactionDetail[field] || 0;
  });

  formModel.propertyDetails.forEach(item => {
    if (typeof item.landDimensions.unitOfEntitlement === 'string') {
      // user can enter / and : but LRS requires us to send : only
      item.landDimensions.unitOfEntitlement = item.landDimensions.unitOfEntitlement.replace('/', ':');
    }
  });

  return {
    ...rest,
    transferees: transferees.map(item => ({
      ...item,
      birthDate: item.birthDate ? dateFormat(item.birthDate, DateFormatEnum.DATE) : null
    })),
    transferors: transferors.map(item => ({
      ...item,
      birthDate: item.birthDate ? dateFormat(item.birthDate, DateFormatEnum.DATE) : null
    })),
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || [])
  };
}

class Converter implements IConverter<NoticeOfAcquisitionDocumentModel, ApiNoticeOfAcquisitionDocumentModel> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
