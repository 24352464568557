import { LookupEnumModel } from '@sympli/ui-framework/models';

// Standard time options [{id: '13:00', name: '13:00'}]
// default start = 9 * 60 = 540, end = 16 * 60 + 30 = 990
export function initializeTimeOptions(start: number = 540, end: number = 990) {
  const TIME_OPTIONS: LookupEnumModel<string>[] = [];
  let hour, min, id;
  for (let i = start; i <= end; i += 30) {
    hour = Math.floor(i / 60);
    if (hour < 10) {
      hour = '0' + hour;
    }
    min = i % 60;
    if (!min) {
      min = '00';
    }
    id = `${hour}:${min}`;
    TIME_OPTIONS.push({ id, name: id });
  }
  return TIME_OPTIONS;
}
