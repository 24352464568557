import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  (theme: Theme) => ({
    formGroup: {
      border: 0
    },
    profileImageBox: {
      marginTop: 30,
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }),
  {
    name: 'ProfileImage'
  }
);

export default useStyles;
