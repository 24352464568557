import * as React from 'react';

import { IconProps } from '@sympli/ui-framework/icons';

export const IconUserActivity = React.forwardRef(({ fill, ...rest }: IconProps, ref: React.Ref<any>) => {
  return (
    <svg width="140px" height="140px" viewBox="0 0 140 140" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...rest}>
      <title>User Activities</title>
      <g id="Icon_User_Activities" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon_User_Activities_a">
          <path
            d="M140,70 C140,108.66 108.66,140 70,140 C31.34,140 0,108.66 0,70 C0,31.34 31.34,0 70,0 C108.66,0 140,31.34 140,70"
            id="Icon_User_Activities_1"
            fill={fill ?? '#F2F2F2'}
          ></path>
          <polygon id="Fill-3" fill="#DDDDDD" points="110.1659 122.0452 34.3399 122.1662 34.1659 22.2862 109.9919 22.1662"></polygon>
          <polygon id="Fill-5" fill="#FFFFFF" points="108 119.8793 32.174 120.0003 32 20.1203 107.826 20.0003"></polygon>
          <polygon id="Fill-6" fill="#9A9A9A" points="42 34 66 34 66 30 42 30"></polygon>
          <polygon id="Fill-7" fill="#D6D6D6" points="42 86 98 86 98 80 42 80"></polygon>
          <polygon id="Fill-8" fill="#9A9A9A" points="42 92 98 92 98 90 42 90"></polygon>
          <polygon id="Fill-9" fill="#9A9A9A" points="42 98 98 98 98 96 42 96"></polygon>
          <polygon id="Fill-10" fill="#9A9A9A" points="42 104 98 104 98 102 42 102"></polygon>
          <polygon id="Fill-11" fill="#9A9A9A" points="42 110 98 110 98 108 42 108"></polygon>
          <polygon id="Fill-12" fill="#18CDB6" points="46.0159 48 45.9999 44.019 71.9839 44 71.9999 47.981"></polygon>
          <polygon id="Fill-13" fill="#FF811A" points="46.0297 56 45.9997 52.019 97.9707 52 97.9997 55.981"></polygon>
          <polygon id="Fill-14" fill="#18CDB6" points="46.0212 64 46.0002 60.019 81.9792 60 82.0002 63.981"></polygon>
          <polygon id="Fill-15" fill="#18CDB6" points="46.0159 72 45.9999 68.019 71.9839 68 71.9999 71.981"></polygon>
          <polygon id="Fill-16" fill="#D6D6D6" points="42 72 44 72 44 44 42 44"></polygon>
        </g>
      </g>
    </svg>
  );
});
