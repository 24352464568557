import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { QldPartyForm_2_24 as PartyForm } from '@sympli-mfe/document-components/party-form/qld/2-24';
import { QLD_PARTY_FORM_CONFIG as QLD_MORTGAGE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { Mortgage2_24_0Model, MortgagorPartyModel } from '../../models';
import { VISIBILITY_CHECK_PARTY_CAPACITY, VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL } from '../../visibilityChecks';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function MortgagorParty({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<Mortgage2_24_0Model>();
  const { values } = formikProps;
  const { disabled } = useDocumentContext();
  const mortgagorParty: MortgagorPartyModel = _get(values, name);
  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === mortgagorParty.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  const documentPartyCapacity = mortgagorParty.partyCapacity;
  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={QLD_MORTGAGE_PARTY_FORM_CONFIG}
      />
      {VISIBILITY_CHECK_PARTY_CAPACITY(mortgagorParty) && <ReadOnlyField label="Capacity" value={documentPartyCapacity!.partyCapacity!} />}
      {VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL(mortgagorParty) && <ReadOnlyField label="Capacity detail" value={documentPartyCapacity!.partyCapacityDetail!} />}
    </div>
  );
}

export default React.memo(MortgagorParty);
