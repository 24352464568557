import { memo } from 'react';

import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import { TooltipType } from '@sympli/ui-framework/components/form/base-components/tooltip/Tooltip';
import { IconDelete, IconEnvelope } from '@sympli/ui-framework/icons';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionResendInvite, actionWithdrawParticipantInvitation, ResendInviteApiRequest } from 'src/containers/dashboard/invitation/actions';
import { WithdrawParticipantInvitationRequestModel } from 'src/containers/dashboard/invitation/models';
import WithdrawDialogContainer from 'src/containers/workspace/shared/components/withdraw-dialog';
import { useSafeDispatch } from 'src/hooks';
import useAsyncDialog from 'src/hooks/useAsyncDialog';
import { useStyles } from './styles';

interface Props extends TooltipType {
  sentToName: string | undefined;
  canResend: boolean;

  resolveResendInvitePayload(): ResendInviteApiRequest;
  resolveWithdrawInvitePayload(): WithdrawParticipantInvitationRequestModel;
}

const WithdrawResendFormatter = (props: Props) => {
  const dispatch = useSafeDispatch(useDispatch());
  const classes = useStyles();
  const { sentToName, resolveWithdrawInvitePayload, resolveResendInvitePayload, canResend, tooltipType } = props;
  const { isOpen, asyncDialogDispatch } = useAsyncDialog();

  function onClose(confirmed?: boolean) {
    if (!confirmed) {
      asyncDialogDispatch({ type: 'close' });
      return;
    }

    dispatch(actionWithdrawParticipantInvitation.request(resolveWithdrawInvitePayload()));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly'
      }}
    >
      <Tooltip title="Resend this invitation" placement="right-start" tooltipType={tooltipType}>
        <IconButton
          className={classes.button}
          onClick={() =>
            canResend
              ? dispatch(actionResendInvite.request(resolveResendInvitePayload()))
              : dispatch(
                  actionOpenGlobalSimpleNotification({
                    //
                    message: 'An invite has already been sent to this subscriber in the last 24 hours',
                    autoHideDuration: 2000,
                    variant: 'error'
                  })
                )
          }
          aria-label="Resend this invitation"
          color="primary"
          size="large"
        >
          <IconEnvelope className={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Withdraw this invitation" placement="right-start" tooltipType={tooltipType}>
        <IconButton //
          className={classes.button}
          onClick={() => {
            asyncDialogDispatch({ type: 'open' });
          }}
          aria-label="Withdraw this invitation"
          color="primary"
          size="large"
        >
          <IconDelete className={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
      {isOpen && <WithdrawDialogContainer sentToName={sentToName} onClose={onClose} />}
    </Box>
  );
};
export default memo(WithdrawResendFormatter);
