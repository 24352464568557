import * as React from 'react';

import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import ChipDetail from 'src/containers/dashboard/components/chip/ChipDetail';
import { isTaskAccessible } from 'src/containers/workspace/financial/helpers';
import { resolveTaskRedirect, resolveTaskUrgency } from 'src/containers/workspace/shared/detail/components/my-tasks/helpers';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { WorkspaceBasicInfoState } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { WorkspaceTasksState } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';
import { colors } from 'src/theme';

interface Row {
  description: string;
  variant: 'error' | 'warning';
  linkTo: string;
}

function MyTasksCardDetail({
  //
  workspaceBasicInfoState,
  workspaceTasksState: { status, items, isLoading }
}: {
  workspaceBasicInfoState: WorkspaceBasicInfoState;
  workspaceTasksState: WorkspaceTasksState;
}) {
  const isWorkspaceReady = workspaceBasicInfoState.status === 'resolved' || workspaceBasicInfoState.status === 'refetching';
  const isAccessible = isTaskAccessible(workspaceBasicInfoState.detail?.workspaceStatusId);
  const isReady: boolean = isWorkspaceReady && (status === 'resolved' || status === 'refetching');

  const rows: Row[] = isReady
    ? items.length > 0
      ? items.map(t => {
          const { workspaceId, id: taskId, taskType, taskGroupType, participantId, data } = t;
          const { isRollover, workspaceStatusId } = workspaceBasicInfoState.detail!;
          const urgency = resolveTaskUrgency({ isRollover, taskType, workspaceStatusId });
          const { link } = resolveTaskRedirect({ workspaceId, participantId, taskType, taskGroupType, taskId, documentId: data?.id, data: data });

          return {
            description: t.description || 'Task',
            variant: urgency === 'high' ? 'error' : 'warning',
            // some tasks does not resolve to a link so we do a fallback
            linkTo: link.length ? link : resolveWorkspaceDetailLink({ workspaceId, participantId })
          };
        })
      : [{ description: '', linkTo: '', variant: 'error' }]
    : [];

  const columns: ColumnsModel<Row> = {
    myTasks: {
      label: 'MY TASKS',
      formatter: ({ row: { linkTo, variant, description }, tooltipHandlers, rowIndex }: FormatterInputModel<Row>) => {
        if (!description) {
          return <ChipDetail text="0 Tasks" />;
        }
        const border = isAccessible
          ? variant === 'error'
            ? `2px solid ${colors.WATERMELON_LUMINOUS}`
            : `2px solid ${colors.SUNNY_DAY_LUMINOUS}`
          : `1px solid ${colors.NEUTRAL_200}`;

        return (
          <SympliButton //
            variant="outlined"
            href={linkTo}
            color={variant}
            disabled={!isAccessible}
            data-binding="description"
            className="truncated inline-block max-w-[95%] items-start rounded-[34px] px-[24px] py-[8px] text-xs font-bold"
            sx={{
              marginTop: rowIndex === 0 ? 0 : '-8px',
              border: border,
              '&.Mui-disabled': { backgroundColor: colors.NEUTRAL_000 },
              color: variant === 'error' ? colors.WATERMELON : colors.SUNNY_DAY_DARK,
              '&:hover': {
                border: variant === 'error' ? `2px solid ${colors.WATERMELON_LUMINOUS}` : `2px solid ${colors.SUNNY_DAY_LUMINOUS}`,
                color: variant === 'error' ? colors.WATERMELON : colors.SUNNY_DAY_DARK,
                backgroundColor: variant === 'error' ? colors.WATERMELON_TRANSLUCENT : colors.SUNNY_DAY_TRANSLUCENT
              }
            }}
            {...tooltipHandlers}
          >
            {description}
          </SympliButton>
        );
      }
    }
  };
  return (
    <CardDetail //
      columns={columns}
      rows={rows}
      {...((isLoading || workspaceBasicInfoState.isLoading) && {
        isLoading: true,
        pageSize: 4
      })}
      {...(isReady && {
        pageSize: rows.length
      })}
    />
  );
}

export default React.memo(MyTasksCardDetail);
