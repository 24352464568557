import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { DateOfDeathModel } from '../../models';
import { DateOfDeathTypeEnum } from './enums';
import { useStyles } from './styles';
import { END_OF_TODAY, END_OF_YESTERDAY } from './validationSchema';
import { VISIBILITY_CHECK_DATE_OF_DEATH_DATE, VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION, VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE } from './visibilityChecks';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  disabled: boolean;
  dateOfDeathLookupOptions: LookupItemModel<DateOfDeathTypeEnum>[];
  allowTodayDeathOfDate?: boolean;
}

function DateOfDeath({ name, disabled, dateOfDeathLookupOptions, allowTodayDeathOfDate }: Props): JSX.Element {
  const formikProps = useFormikContext();
  const { values } = formikProps;
  const value: DateOfDeathModel = _get(values, name);
  const fieldName = createModelKeyAppender<DateOfDeathModel>(name);
  const classes = useStyles();

  return (
    <FlexLayout justifyContent="flex-start" flexDirection="column" data-testid={name}>
      <Field //
        name={fieldName('type')}
        label="Details of death date"
        component={SelectField}
        disabled={disabled}
        format="string"
        options={dateOfDeathLookupOptions}
        placeholder={resolveSelectPlaceholder(true)}
        className={classes.fieldWidth}
      />
      {VISIBILITY_CHECK_DATE_OF_DEATH_DATE(value) && (
        <Field //
          name={fieldName('date')}
          label="Date of death"
          component={DatePickerField}
          disabled={disabled}
          maxDate={allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY}
          className={classes.fieldWidth}
        />
      )}
      {VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(value) && (
        <Field //
          name={fieldName('from')}
          label="From date"
          component={DatePickerField}
          disabled={disabled}
          maxDate={allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY}
          className={classes.fieldWidth}
        />
      )}
      {VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(value) && (
        <Field //
          name={fieldName('to')}
          label="To date"
          component={DatePickerField}
          disabled={disabled}
          maxDate={allowTodayDeathOfDate ? END_OF_TODAY : END_OF_YESTERDAY}
          className={classes.fieldWidth}
        />
      )}
      {VISIBILITY_CHECK_DATE_OF_DEATH_DESCRIPTION(value) && (
        <Field //
          name={fieldName('description')}
          label="Date description"
          component={InputField}
          disabled={disabled}
          className={classes.fieldWidth}
        />
      )}
    </FlexLayout>
  );
}

export default React.memo(DateOfDeath);
