import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { LodgementInstructions2_21_1Model } from './models';

export default yup
  .object<LodgementInstructions2_21_1Model>()
  .shape({
    responsibleParticipantId: yup.string().required(msg.REQUIRED)
  })
  .defined();
