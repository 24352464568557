// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    checkboxLabelRoot: {
      marginBottom: 0,
      // The Tickle-UI Framework checbox is not prviding any means of overriding Material UI checkbox's classes,
      // so we have to drill down into it using the FormControlLabel's classes.
      '& > span:first-child': {
        height: 'unset'
      }
    }
  }),
  {
    name: 'MortgagorPartyArray'
  }
);
