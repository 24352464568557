import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { IconSearchNew } from '@sympli/ui-framework/icons';

interface Props {
  handleOnClick: () => void;
}

function SearchBoxIcon(props: Props) {
  return (
    <ButtonBase
      onClick={props.handleOnClick}
      className="group flex h-[28px] min-w-0 max-w-[28px] items-center gap-[4px] rounded-[30px] bg-[var(--sunny-day)] transition-all duration-[500] ease-in-out hover:max-w-max hover:cursor-pointer focus:max-w-max medium-screen:h-[36px] medium-screen:max-w-[36px] medium-screen:gap-[6px]"
    >
      <IconSearchNew className="mx-[4px] h-[20px] w-[20px] medium-screen:mx-[6px] medium-screen:h-[24px] medium-screen:w-[24px]" />
      <Typography
        variant="subtitle3"
        className="mr-[10px] hidden font-volkswagen-serial-medium font-medium tracking-[0.8px] text-[white] group-hover:flex group-focus:flex medium-screen:mr-[15px]"
      >
        Search
      </Typography>
    </ButtonBase>
  );
}

export default React.memo(SearchBoxIcon);
