import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder, resolveVisibleEnumOptions } from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import {
  PROPERTY_DETAILS_CONSTRUCTION_OF_MAIN_STRUCTURE_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_LAND_USE_TYPE_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_MUNICIPALITY_NAME_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_PRIMARY_LAND_USE_LOOKUP_OPTIONS
} from '../../enums';
import { NoaPropertyDetailModel, NoticeOfAcquisitionDocumentModel } from '../../models';
import {
  VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE,
  VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS,
  VISIBILITY_CHECK_PROPERTY_DETAILS$PRIMARY_LAND_USE,
  VISIBILITY_CHECK_PROPERTY_DETAILS$SALE_PRICE_PER_PROPERTY
} from '../../visibilityChecks';
import LandDimensions from '../land-dimensions';
import PropertyTitlesAndAddress from '../property-titles-and-address';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
  disabled: boolean;
}

function NoaPropertyDetail({ name, disabled }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;
  const value: NoaPropertyDetailModel = _get(values, name);
  const fieldName = createModelKeyAppender<NoaPropertyDetailModel>(name);

  return (
    <>
      <FormGroup title="Property details" fieldAreaDirection="column" data-name={fieldName('titleReferences')}>
        <PropertyTitlesAndAddress name={name} />
      </FormGroup>
      {VISIBILITY_CHECK_PROPERTY_DETAILS$SALE_PRICE_PER_PROPERTY(value, null, values) && (
        <FormGroup title="Sale price in AUD ($)" fieldAreaDirection="column">
          {({ titleId }) => (
            <Field //
              aria-labelledby={titleId}
              placeholder="0.00"
              name={fieldName('salePricePerProperty')}
              component={CurrencyInputField}
              disabled={disabled}
              format="number"
              classes={{
                helperTextError: classes.salePricePerPropertyHelperTextError
              }}
            />
          )}
        </FormGroup>
      )}

      <FormGroup //
        title="Municipality name"
        fieldAreaDirection="column"
        formTip="Please ensure that you forward this NOA form to the council after lodgement."
      >
        {({ titleId }) => (
          <>
            <Field //
              aria-labelledby={titleId}
              name={fieldName('municipalityName')}
              component={SelectField}
              debug={debug}
              disabled={disabled}
              format="number"
              options={PROPERTY_DETAILS_MUNICIPALITY_NAME_LOOKUP_OPTIONS}
              placeholder={resolveSelectPlaceholder(true)}
              fullWidth
            />
            <Field //
              name={fieldName('municipalityPropertyNumber')}
              component={InputField}
              disabled={disabled}
              label={
                <>
                  Municipality property number
                  <InfoIconWithTooltip
                    flow="inline"
                    tooltipContent="This is the unique number by which your municipality identifies the land. This number may be found on the rates notice or by inquiry to the municipality."
                  />
                </>
              }
              placeholder={resolvePlaceholder()}
            />
          </>
        )}
      </FormGroup>

      <FormGroup title="Land dimensions" fieldAreaDirection="column">
        <LandDimensions //
          name={fieldName('landDimensions')}
        />
      </FormGroup>

      <FormGroup title="Primary land use" fieldAreaDirection="column">
        {({ titleId }) => (
          <>
            <Field //
              aria-labelledby={titleId}
              name={fieldName('landUseType')}
              component={SelectField}
              debug={debug}
              disabled={disabled}
              format="number"
              options={PROPERTY_DETAILS_LAND_USE_TYPE_LOOKUP_OPTIONS}
              placeholder={resolveSelectPlaceholder(true)}
              fullWidth
            />

            {VISIBILITY_CHECK_PROPERTY_DETAILS$PRIMARY_LAND_USE(value) && (
              <Field //
                name={fieldName('primaryLandUse')}
                component={SelectField}
                debug={debug}
                disabled={disabled}
                label="Primary land use category"
                format="number"
                options={resolveVisibleEnumOptions<number>(PROPERTY_DETAILS_PRIMARY_LAND_USE_LOOKUP_OPTIONS, values, fieldName('primaryLandUse'))}
                placeholder={resolveSelectPlaceholder(true)}
                fullWidth
              />
            )}

            {VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE(value) && (
              <Field //
                name={fieldName('constructionOfMainStructure')}
                component={SelectField}
                debug={debug}
                disabled={disabled}
                format="number"
                label="Construction of main structure"
                options={PROPERTY_DETAILS_CONSTRUCTION_OF_MAIN_STRUCTURE_LOOKUP_OPTIONS}
                placeholder={resolveSelectPlaceholder(true)}
                fullWidth
              />
            )}
            {VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS(value) && (
              <Field //
                name={fieldName('numberOfBedrooms')}
                component={InputField}
                disabled={disabled}
                label="Number of bedrooms"
              />
            )}
          </>
        )}
      </FormGroup>
    </>
  );
}

export default React.memo(NoaPropertyDetail);
