import * as yup from 'yup';

import { testContextualRule } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { CaveatModel, Proprietor } from '../../models';

const yupProprietorItem = yup.object<Proprietor>().shape({
  // party: yupNSWDocumentParty
  // address: yupNSWDeliveryAddress

  partyBookId: yup
    .string() //
    .when('isAffected', {
      is: true,
      then: yup.string().default('').nullable(true).required(msg.REQUIRED), // suspicious validation, user can't see this!
      otherwise: yup.string().default('').nullable(true)
    }),

  addressBookId: yup
    .string() //
    .when('isAffected', {
      is: true,
      then: yup.string().default('').nullable(true).required(msg.REQUIRED),
      otherwise: yup.string().default('').nullable(true)
    }),

  isAffected: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED)
});

// path: proprietors
export default yup
  .array<Proprietor>()
  .test(
    testContextualRule<CaveatModel, Proprietor[]>({
      requirement: (proprietors: Proprietor[]): boolean => (proprietors ?? []).some(({ isAffected }) => isAffected),
      message: 'At least 1 proprietor must be affected.'
    })
  )
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .of(yupProprietorItem);
