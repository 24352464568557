import { endOfYesterday } from 'date-fns';
import * as yup from 'yup';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-24/necds';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { Form24A_2_24_1Model } from '../../models';

const END_OF_YESTERDAY = endOfYesterday();

const yupPartyBookItem = yup.mixed<PartyModel>().test(
  'birthDate-test',
  'Individual party require BirthDate', //
  function test(this: yup.TestContext<Form24A_2_24_1Model>) {
    const party: PartyModel = this.originalValue;
    if (party.partyType === PartyTypeEnum.Organisation) return true;

    const birthDate = party.dob;
    const path = `${this.path}.dob`;

    if (!birthDate) {
      return this.createError({ path: path, message: msg.REQUIRED });
    }
    if (!yupDatePicker.isValidSync(birthDate)) {
      return this.createError({ path: path, message: msg.INVALID_DATE });
    }
    if (birthDate > END_OF_YESTERDAY) {
      return this.createError({ path: path, message: msg.DATE_MUST_BE_PAST_DATE });
    }

    return true;
  }
);

export const yupPartyBook = yup.array<PartyModel>(yupPartyBookItem).defined();
