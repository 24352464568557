import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    documentActionIcon: {
      fill: theme.palette.primary.main,
      padding: '0px 4px'
    },
    documentActionTextDark: {
      fontSize: 16,
      lineHeight: '19px',
      color: theme.palette.common.white,
      marginRight: 10
    },
    // just in case
    documentActionTextLight: {
      fontSize: 16,
      lineHeight: '19px',
      color: theme.palette.text.primary,
      marginRight: 10
    },
    documentActionToolTip: {
      marginTop: 20,
      fontSize: 13,
      fontWeight: 'bold',
      lineHeight: '18px',
      color: theme.palette.text.primary
    },
    multipleSignersDark: {
      fontWeight: 'normal',
      color: theme.palette.common.white,
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.common.white}`,
      paddingLeft: 3
    },
    multipleSignersLight: {
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      paddingLeft: 3
    },
    toolTip: {
      background: theme.palette.grey[50],
      padding: 20
    },
    toolTipLabel: {
      width: '30%'
    },
    toolTipDes: {
      width: '70%'
    },
    text: {
      paddingRight: 10
    }
  });

export type ClassKeys = typeof styles;

export default styles;
