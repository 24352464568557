import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { OnlineStatusEnum } from './models';
import styles, { ClassKeys } from './styles';

export interface OnlineStatusIconProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: OnlineStatusEnum;
  tooltip?: string;
  small?: boolean;
}
type Props = OnlineStatusIconProps & WithStyles<ClassKeys>;

class OnlineStatusIcon extends React.PureComponent<Props> {
  render() {
    const { classes, value: status, tooltip, small, className } = this.props;
    if (status === undefined) {
      return null;
    }
    return <div className={classNames(classes.root, classes.status, status, tooltip && classes.tooltip, small && classes.small, className)} title={tooltip} />;
  }
}

export default withStyles(styles)(OnlineStatusIcon);
