import * as React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';
import MuiSkeleton from '@mui/material/Skeleton';

import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import CopyIcon from 'src/components/copy-icon';
import { CardDetail } from 'src/containers/dashboard/components/card';
import { WorkspaceBasicInfoState } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { WorkspaceDetailState } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';

import type { AsyncRecord } from './models';

type Row = AsyncRecord<'sympliId' | 'reference', string> | AsyncRecord<'titleReferences', string[]>;

function SimplePlusN({
  //
  plusN,
  dataBinding,
  tooltipHandlers
}: {
  plusN?: number;
  dataBinding: string;
  tooltipHandlers: {
    onMouseEnter(event: React.MouseEvent<HTMLElement>): void;
    onMouseLeave(event: React.MouseEvent<HTMLElement>): void;
  };
}) {
  if (!plusN) {
    return null;
  }
  return (
    <div data-binding={dataBinding} className="bold pl-[4px]" data-explicit-tooltip {...tooltipHandlers}>
      +{plusN}
    </div>
  );
}

function WorkspaceCardDetail({
  matterReference,
  workspaceBasicInfoState,
  workspaceDetailState,
  workspaceParticipantsState
}: {
  //
  matterReference?: string;
  workspaceBasicInfoState: WorkspaceBasicInfoState;
  workspaceDetailState: WorkspaceDetailState;
  workspaceParticipantsState: WorkspaceParticipantsState;
}) {
  const titleReferences: string[] = workspaceDetailState.detail?.titleReferences.map(item => item.landRegistryDetail.reference) || [];

  // they must satisfy type T
  const rows: Row[] = [
    {
      id: 'reference',
      header: 'Reference',
      value: matterReference,
      status: workspaceParticipantsState.status as any,
      className: 'bold'
    },
    {
      id: 'sympliId',
      header: 'Sympli ID',
      value: workspaceBasicInfoState.detail?.sympliId,
      status: workspaceBasicInfoState.status as any
    },
    {
      id: 'titleReferences',
      header: pluralize('Title', titleReferences.length),
      value: titleReferences,
      status: workspaceDetailState.status
    }
  ];

  const columns: ColumnsModel<Row> = {
    workspaceDetails: {
      label: 'WORKSPACE DETAILS',
      formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
        const isResolved = row.status === 'resolved' || row.status === 'refetching';
        if (isResolved) {
          if (row.id === 'titleReferences') {
            const [first, ...rest] = row.value;

            return (
              <div className="flex items-center text-[12px] leading-[14px]">
                <div className="w-[62px] text-[var(--neutral-400)]">{row.header}</div>
                <div className="flex items-center">
                  {row.value.length ? (
                    <>
                      {first}
                      <SimplePlusN //
                        dataBinding={row.id}
                        plusN={rest.length}
                        tooltipHandlers={tooltipHandlers}
                      />
                      <CopyIcon className="pl-[4px]" title={`${row.header}`} textToCopy={row.value.join(', ')} />
                    </>
                  ) : (
                    <label>-</label>
                  )}
                </div>
              </div>
            );
          }
        }

        return (
          <div className="text-[12px] leading-[14px]">
            <div className="float-left w-[62px] text-[var(--neutral-400)]">{row.header}</div>
            {isResolved ? (
              <div data-binding={row.id} className="flex">
                <div
                  className={classNames(row.id === 'reference' ? 'overflow-hidden break-words' : 'truncated', row.id === 'reference' && 'bold', row.className)}
                  {...tooltipHandlers}
                >
                  {row.value}
                </div>

                <CopyIcon className="pl-[4px]" title={`${row.header}`} textToCopy={row.value as string} />
              </div>
            ) : (
              <div className="flex-1">
                <MuiSkeleton variant="text" />
              </div>
            )}
          </div>
        );
      },
      tooltipFormatter({ anchorEl, row }) {
        switch (anchorEl.dataset.binding) {
          case 'reference':
          case 'sympliId':
            return row.value;
          case 'titleReferences':
            return (
              <div>
                <b>Multiple titles found</b>
                <ul>
                  {(row.value as string[]).map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              </div>
            );
          default:
            return anchorEl.innerText;
        }
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
    />
  );
}

export default React.memo(WorkspaceCardDetail);
