import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DocumentPermissionEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import DocumentViewer from 'src/containers/documents/components/document-viewer';
import DocumentWorkflowPanel, { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import { DocumentFormAndDetailModel, DocumentWorkflowStepsEnum, SaveDocumentFormRequest } from 'src/containers/documents/models';
import ActionLabel from 'src/containers/workspace/shared/components/action-label';
import { resolveReviewDocumentActionInfo } from 'src/containers/workspace/shared/detail/selectors';
import { useSafeDispatch } from 'src/hooks';
import { reviewDocument } from '../shared/helpers';
import { useStyles } from './styles';

interface Props {
  queryParams: SaveDocumentFormRequest;
  displayDocumentAsPdf: boolean;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId?: JurisdictionsEnum;
  documentIds: string[];
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
}

function ReviewDocument({
  //
  queryParams,
  displayDocumentAsPdf,
  documentDetail,
  jurisdictionId,
  documentIds,
  workflowPanelProps
}: Props) {
  const navigate = useNavigate();
  const [isWorkflowLoading, setIsWorkflowLoading] = React.useState(false);
  const [hash] = React.useState(documentDetail.hash); // contains the initial document detail hash and used in submission, must be prevented from getting updated

  const dispatch = useSafeDispatch(useDispatch());
  const classes = useStyles();
  const { participantId } = queryParams;
  const hasPermissionToReview = documentDetail.documentPermissions.includes(DocumentPermissionEnum.Review);
  const nextActionDisabled = isWorkflowLoading || !hasPermissionToReview;
  const mode = displayDocumentAsPdf ? 'dark' : 'light';
  const disabled = isWorkflowLoading || nextActionDisabled;
  const reviewActionInfo = resolveReviewDocumentActionInfo({ detail: documentDetail, participantId });
  const nextButtonStyling = mode === 'dark' ? { root: classes.disabledNextDark } : { root: classes.disabledNextLight };
  const handleReviewClick = React.useCallback(() => {
    reviewDocument(queryParams, documentDetail, documentIds, dispatch, navigate, setIsWorkflowLoading, hash);
  }, [dispatch, documentDetail, documentIds, hash, navigate, queryParams]);

  const documentWorkflowPanel = (
    <DocumentWorkflowPanel //
      disableStepper={isWorkflowLoading}
      disableMenu={isWorkflowLoading}
      {...workflowPanelProps}
    >
      <ActionLabel //
        documentActionInfo={reviewActionInfo}
        mode={mode}
        currentParticipantId={participantId}
      />
      <SympliButton //
        classes={nextButtonStyling}
        color="primary"
        variant="contained"
        onClick={handleReviewClick}
        arrowRight={true}
        isLoading={isWorkflowLoading}
        disabled={disabled}
      >
        Approve
      </SympliButton>
    </DocumentWorkflowPanel>
  );

  return (
    <>
      {documentWorkflowPanel}
      <DocumentViewer //
        displayDocumentAsPdf={displayDocumentAsPdf}
        signMultiple={false}
        queryParams={queryParams}
        documentDetail={documentDetail}
        jurisdictionId={jurisdictionId}
      />
    </>
  );
}

export default React.memo(ReviewDocument);
