import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import MortgagorPartyReadonlyArray from './components/mortgagor-party-readonly-array';
import { getMortgagorPartyBookIds, getMortgagorsFromTitleReferences, getSelectedTitleReferences } from './helper';
import { Mortgage_2_23_0__Model, MortgagorPartyModel } from './models';
import MortgageeDetails from './sections/mortgagee-details';
import TermsAndConditions from './sections/terms-and-conditions';
import { useStyles } from './styles';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Mortgage_2_23_0__Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage_2_23_0__Model>();
  const { setValues } = formikProps;
  const classes = useStyles();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgagorsRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgageesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnMortgagorsRef.current || focusOnMortgageesRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        // values already contains updated titles
        const selectedTitles = getSelectedTitleReferences(values.titleReferences);
        // collect unique list of mortgagors present under selected title references
        const selectedTitlesMortgagorPartyBookIds = getMortgagorPartyBookIds(selectedTitles);
        const allMortgagors = getMortgagorsFromTitleReferences(values.titleReferences);

        // update list of mortgagors based on title selection
        let updated = false;
        // first, remove those that should not be displayed anymore
        const updatedMortgagors = values.mortgagors.reduce<MortgagorPartyModel[]>((acc, item) => {
          if (!selectedTitlesMortgagorPartyBookIds.includes(item.partyBookId)) {
            updated = true;
          } else {
            // make sure that we keep unique list of mortgagors
            if (!acc.find(({ partyBookId }) => item.partyBookId === partyBookId)) {
              acc.push(item);
            }
          }
          return acc;
        }, []);

        // second, add missing ones
        if (updatedMortgagors.length < selectedTitlesMortgagorPartyBookIds.length) {
          selectedTitlesMortgagorPartyBookIds.reduce<MortgagorPartyModel[]>((acc, partyBookId) => {
            if (!acc.find(item => item.partyBookId === partyBookId)) {
              // we need to push the party with full details
              const mortgagors = allMortgagors.find(d => d.partyBookId === partyBookId)!;
              acc.push(mortgagors);
              updated = true;
            }
            return acc;
          }, updatedMortgagors);
        }

        if (updated) {
          return {
            ...values,
            mortgagors: updatedMortgagors
          };
        }
        // because nothing has been changed we want to keep original reference and prevent unnecessary re-rendering
        return values;
      });
    },
    [setValues]
  );

  return (
    <>
      <Form className={classNames(classes.root, className)}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
        />
        <Section title="Mortgagors" data-name={fieldName('mortgagors')} error={getFormikError(formikProps, fieldName('mortgagors'), true)}>
          <MortgagorPartyReadonlyArray name={fieldName('mortgagors')} focusRef={focusOnMortgagorsRef} />
        </Section>

        <MortgageeDetails name={fieldName('mortgageeDetails')} focusRef={focusOnMortgageesRef} />

        <Section title="Terms and conditions" data-name={fieldName('termsAndConditions')}>
          <TermsAndConditions name={fieldName('termsAndConditions')} />
        </Section>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
