import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { ForeignTypeEnum } from './enums';
import { Form25_2_24_1Model } from './models';
import { VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION } from './visibilityCheck';

export const qldForm25FallbackMap: FallbackMap<Form25_2_24_1Model> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  transferee: NO_FALLBACK,
  foreignType: [VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION, ForeignTypeEnum.NaturalPerson, ''],
  countryCode: [''],
  foreignShareholders: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        country: [''],
        percent: [0]
      }
    }
  }
};
