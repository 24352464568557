import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-26/sa';

import { SA_MORTGAGE_PARTY_FORM_CONFIG } from '../../config';
import { MortgageeDetails$receivingProprietorGroups$parties$PartyCapacity$PartyCapacityLookup as PartyCapacityLookup } from '../../enums';

// path: mortgageeDetails
const yupMortgageeDetails = resolveMemoizedTenancyValidation({
  partyCapacityLookup: PartyCapacityLookup,
  partyCapacityMinor: NecdsPartyCapacityEnum.Minor,
  isAddressRequired: true,
  partyFormConfig: SA_MORTGAGE_PARTY_FORM_CONFIG
});

export default yupMortgageeDetails;
