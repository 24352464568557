import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import sharedStyles from '../../../edit-directions/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...sharedStyles(theme),
    deleteButton: {
      position: 'absolute',
      left: -10
    },
    okButton: {
      width: 50,
      whiteSpace: 'nowrap'
    },
    amountField: {
      width: 180,
      whiteSpace: 'nowrap'
    },
    autoBalanceCheckBox: {
      marginRight: 5
    },
    iconInfo: {
      width: 13,
      height: 13,
      marginBottom: 14
    }
  }),
  {
    name: 'SourceFundEditor'
  }
);
