import { ClaimPartyCapacityEnum } from '../../enums';
import { ClaimParty } from '../../models';

export const resolveDefaultItem = () => {
  const defaultItem: ClaimParty = {
    claimPartyCapacity: ClaimPartyCapacityEnum.None,
    partyBookId: '' // reference to party store
  };

  return defaultItem;
};
