import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiNominateeCtToPaperInstrument_2_18_1Model, NominateeCtToPaperInstrument_2_18_1Model } from './models';

class Converter implements IConverter<NominateeCtToPaperInstrument_2_18_1Model, ApiNominateeCtToPaperInstrument_2_18_1Model> {
  fromApiToFormModel(apiModel: ApiNominateeCtToPaperInstrument_2_18_1Model, context: IConverterContext): NominateeCtToPaperInstrument_2_18_1Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: NominateeCtToPaperInstrument_2_18_1Model, _originalApiModel: ApiNominateeCtToPaperInstrument_2_18_1Model): ApiNominateeCtToPaperInstrument_2_18_1Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
