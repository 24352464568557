import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

interface Props {
  title: string | JSX.Element;
  className?: string;
}

function SettingsHeader({ title, children, className }: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <header className={classNames(classes.root, className)}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      {children}
    </header>
  );
}

export default React.memo(SettingsHeader);
