import { useEffect, useState } from 'react';

export default function useShowMessage(key: string): [boolean, () => void] {
  const [showMessage, setShowMessage] = useState(false);

  const handler = () => {
    setShowMessage(false);
    localStorage.setItem(key, 'true');
  };

  useEffect(() => {
    if (localStorage.getItem(key) !== 'true') setShowMessage(true);
  }, [key, setShowMessage]);

  return [showMessage, handler];
}
