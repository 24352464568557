import { VicDocumentPartyJustification, VicNameChange } from '@sympli-mfe/document-components/party-form/vic/2-23/components/party-justification';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ApiReceivingTenancyDetailModel, FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FormTenancyDetailModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

// this file was automatically generated from models.ts.mustache
export interface ApiMortgage2_23_1Model {
  addressBook?: DocumentDeliveryAddressModel[];
  mortgagors: TenancyPartyModel[];
  mortgageeDetails: ApiReceivingTenancyDetailModel;
  partyBook?: ApiDocumentPartyModel<VicDocumentPartyJustification>[];
  termsAndConditions?: StandardTermsModel;
  titleReferences: TitleReferenceModel[];
  workspaceTitleReferences?: string[];
  proprietors: any; // Do not use. Used only by backend.
  needToGenerateMortgagors: boolean;
  transferees: TenancyPartyModel[];
}

export interface DocumentDeliveryAddressModel {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  addressSiteName?: string;
  addressType: number | null;
  countryCode: number | null;
  id?: string;
  isUnstructuredAddress?: boolean;
  levelNumber?: string;
  levelType?: number | null;
  locality?: string;
  lotNumber?: string;
  overseasPostcode?: string;
  postcode?: string;
  state?: string;
  streetName?: string;
  streetNumber?: string;
  streetSuffix?: number | null;
  streetType?: number | null;
  unitNumber?: string;
  unitType?: string | number | null;
}

export interface StandardTermsModel {
  additionalCovenants: string;
  dealingNumber: string;
  mortgageDate?: Date | string | null;
  subscriberDefaultDealingNumber?: string;
}

export interface TitleReferenceModel {
  estateType?: string;
  folio?: string;
  isPartLandAffected?: boolean;
  isSelected: boolean;
  landDescriptions?: LandDescriptionModel[];
  mortgagors: TenancyPartyModel[];
  reference?: string;
  volume?: string;
  hasTransfer?: boolean;
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export const EMPTY_MORTGAGOR_PARTY: TenancyPartyModel = {
  id: '',
  partyBookId: undefined
};

export type Mortgage2_23_1Model = Omit<ApiMortgage2_23_1Model, 'partyBook' | 'addressBook' | 'mortgagors' | 'mortgageeDetails'> & {
  partyBook: PartyModel<VicNameChange>[];
  addressBook: AddressBookEntityModel[];
  mortgagors: FormTenancyDetailModel;
  mortgageeDetails: FormReceivingTenancyDetailModel;
};

export interface UseCaseModel {
  title: string;
  data: object;
  workspaceDetail?: {
    workspaceRoleId?: WorkspaceRoleEnum | null;
    reference?: string;
  };
  currentParticipant?: {
    isResponsibleParticipant: boolean;
  };
  localStorageIndex?: number;
}
