import React from 'react';

import Typography from '@mui/material/Typography';

import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import Table, { ColumnsModel } from '@sympli/ui-framework/components/table';
import { IconExclamation } from '@sympli/ui-framework/icons';

import { SavedScheduleReportModel, SchedulerFormikModel } from '../../models';
import ReportSchedulerDialog from '../report-scheduler-dialog';
import { formatDownloadColumn, formatReportDateGenerated, formatReportTypeColumn } from './formatters';
import { useStyles } from './styles';

export interface Props {
  savedReports: SavedScheduleReportModel[];
  handleOnDownloadClick: (id: number) => Promise<void>;
  handleOnSchedulerClose: (value?: boolean) => void;
  isOpenScheduler: boolean;
  onPostSubmit: (args: FormikPostSubmitArgs<SchedulerFormikModel>) => void;
  initialValues: SchedulerFormikModel;
  showDisclaimer?: boolean;
}

export interface HistoryTableContext {
  onDownloadClick: (id: number) => Promise<void>;
}

function ScheduledReportHistory({
  //
  savedReports,
  handleOnDownloadClick,
  handleOnSchedulerClose,
  isOpenScheduler,
  initialValues,
  onPostSubmit,
  showDisclaimer
}: Props) {
  const classes = useStyles();

  const columns: ColumnsModel<SavedScheduleReportModel> = {
    generatedDateTime: {
      label: 'Date generated',
      formatter: formatReportDateGenerated,
      className: classes.reportDateGenerated
    },
    reportType: {
      label: 'Report type',
      formatter: formatReportTypeColumn,
      className: classes.reportTypes
    },
    downloadButton: {
      label: '',
      formatter: formatDownloadColumn,
      className: classes.reportDownload
    }
  };

  return (
    <>
      <Typography component="strong" variant="h3" className={classes.heading}>
        Report history
      </Typography>
      {showDisclaimer && (
        <FlexLayout justifyContent="space-evenly" alignItems="center" className={classes.disclaimer}>
          <IconExclamation />
          <span>Please note that reports will expire and no longer be available on this page 1 year from their generated date.</span>
        </FlexLayout>
      )}
      <Table //
        pagination={false}
        sortable={false}
        rows={savedReports}
        columns={columns}
        bordersWithSpacing={false}
        context={{ onDownloadClick: handleOnDownloadClick }}
        classes={{
          //
          tableCellBody: classes.tableCellBody
        }}
      />
      <ReportSchedulerDialog //
        isOpen={isOpenScheduler}
        onClose={handleOnSchedulerClose}
        onPostSubmit={onPostSubmit}
        initialValues={initialValues}
      />
    </>
  );
}

export default React.memo(ScheduledReportHistory);
