import * as yup from 'yup';

import yupResponsibleParticipantId from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber/validation-schema';

import { LodgementInstructionsDocumentModel } from './models';
import yupDealingRequirementsSummary from './sections/dealing-requirements-summary/validationSchema';
import { yupDirectionPreferenceValidationSchema } from './sections/direction-preference/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<LodgementInstructionsDocumentModel>({
    responsibleParticipantId: yupResponsibleParticipantId,
    dealingRequirementsSummary: yupDealingRequirementsSummary,
    directionPreference: yupDirectionPreferenceValidationSchema<LodgementInstructionsDocumentModel>()
  })
  .defined()
  .log();
