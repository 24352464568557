import React from 'react';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import NumberInputField from '@sympli/ui-framework/components/formik/number-input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { PROPERTY$AREA$UNIT_LOOKUP_OPTIONS } from '../../enums';
import { AreaModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function Area({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<AreaModel>(name);
  const classes = useStyles();

  return (
    <div data-section={name} className={className}>
      <FlexLayout flexDirection="row" justifyContent="flex-start" alignItems="baseline">
        <Field //
          label="Area"
          name={fieldName('numberOfUnits')}
          component={NumberInputField}
          disabled={disabled}
          className={classes.area}
          validationOverflowDirection="horizontal"
          fixedDecimalScale={false}
          decimalScale={2}
        />
        <Field //
          aria-label="Area Unit"
          name={fieldName('unit')}
          component={RadioField}
          disabled={disabled}
          format="number"
          options={PROPERTY$AREA$UNIT_LOOKUP_OPTIONS}
        />
      </FlexLayout>
    </div>
  );
}

export default React.memo(Area);
