import * as React from 'react';

import { FormikProps } from 'formik';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS } from '../../enums';
import { WithdrawalOfCaveat_2_23_0_Model } from '../../models';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface OwnProps {
  name: string;
  formikProps: FormikProps<WithdrawalOfCaveat_2_23_0_Model>;
  disabled: boolean;
  ariaLabelledbyId: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class SectionSetOfOperativeWords extends React.PureComponent<Props> {
  render() {
    const { classes, name, disabled, ariaLabelledbyId } = this.props;

    return (
      <div className={classes.root}>
        <Field //
          aria-labelledby={ariaLabelledbyId}
          component={RadioField}
          disabled={disabled}
          name={name}
          options={SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS}
          format="number"
          vertical
          className={classes.setOfOperativeWords}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SectionSetOfOperativeWords);
