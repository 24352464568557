import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      marginBottom: 12,
      '& .MuiTabs-indicator': {
        backgroundColor: 'unset'
      },
      '& .MuiTabs-scroller': {
        borderBottom: 'none'
      }
    },
    tab: {
      display: 'flex',
      width: 120,
      height: 36,
      minHeight: 36,
      padding: '8px 11px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      flexShrink: 0,
      textAlign: 'center',
      fontSize: 12,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 1,
      textTransform: 'uppercase',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      '&:hover': {
        color: colors.NEUTRAL_1000,
        fontFamily: 'volkswagen_serial-xbold',
        background: 'unset',
        fontWeight: 800
      }
    },
    tabNotSelected: {
      color: colors.NEUTRAL_800,
      fontFamily: 'volkswagen_serial-medium',
      fontWeight: 400,
      borderBottom: `1px solid ${colors.NEUTRAL_100}`
    },
    tabSelected: {
      color: colors.NEUTRAL_1000,
      fontFamily: 'volkswagen_serial-xbold',
      fontWeight: 800,
      borderBottom: `2px solid ${colors.NEUTRAL_1000}`
    }
  }),
  {
    name: 'NavTabs'
  }
);
