import * as React from 'react';

import dateFormat from 'dateformat';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import NavLink from '@sympli/ui-framework/components/nav-link';
import Table, { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { DocumentIdentifiersEnum, StampDutyDocumentItem } from '../../models';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  queryParams: WorkspaceDetailRouteParams;
  items: Array<StampDutyDocumentItem>;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class StampDutyDocumentList extends React.PureComponent<Props> {
  private getColumnDefinition(hasTabDocument: boolean): ColumnsModel<StampDutyDocumentItem> {
    const { classes, queryParams } = this.props;

    const defaultColumns: ColumnsModel<StampDutyDocumentItem> = {
      documentNumber: {
        className: classes.firstColumn,
        label: hasTabDocument ? 'Transmission Application' : 'Transfer',
        formatter: ({ row, value }: FormatterInputModel<StampDutyDocumentItem, string>) => {
          const { workspaceId, participantId } = queryParams;
          const { documentId } = row;
          const link = resolveDocumentDetailLink({ workspaceId, participantId, documentId });
          return (
            <NavLink icon={false} to={link}>
              {value}
            </NavLink>
          );
        }
      },
      titleReferences: {
        label: 'Title',
        formatter: ({ value }: FormatterInputModel<StampDutyDocumentItem, Array<string>>) => value.join(', ')
      }
    };

    return hasTabDocument
      ? defaultColumns
      : {
          ...defaultColumns,
          contractSignedDate: {
            label: 'Agreement date',
            formatter: ({ value }: FormatterInputModel<StampDutyDocumentItem, Date>) => dateFormat(value, DateFormatEnum.AUSDATE)
          }
        };
  }

  public render() {
    const { classes, items = [] } = this.props;
    if (items.length === 0) {
      return null;
    }

    const hasTabDocument = items.some(x => x.documentIdentifier === DocumentIdentifiersEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin);
    return (
      <div className={classes.container}>
        <Table
          pagination={false}
          sortable={false}
          classes={{
            //
            tableCellBody: classes.tableCellBody,
            tableRowRoot: classes.tableRowRoot
          }}
          rows={items}
          columns={this.getColumnDefinition(hasTabDocument)}
          noDataMessage="Enter your title reference details to begin"
          bordersWithSpacing={false}
        />
      </div>
    );
  }
}

export default withStyles(styles)(StampDutyDocumentList);
