import * as React from 'react';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import { DealingModel } from '../../../../models';
import { HandleSpecialNoneExistDealingDocuments } from './helper';
import { useStyles } from './styles';

interface Props {
  dealings: DealingModel[];
  title: string;
  dateLabel: string;
  jurisdiction: LookupEnumModel<JurisdictionsEnum>;
  isRegisteredDealing: boolean;
}

const formatDate = (date?: string): string => (date ? dateTimeLine(date, 'dd mmm yyyy') : '');

function TitleDealings(props: Props) {
  const classes = useStyles();
  if (!props.dealings.length) return null;

  const groups = props.dealings.reduce((result, dealing) => {
    let list = result.get(dealing.documentType);
    if (!list) {
      list = new Array<DealingModel>();
      result.set(dealing.documentType, list);
    }

    list.push(dealing);
    return result;
  }, new Map<string, DealingModel[]>());

  const specialNotExistDocs = props.isRegisteredDealing ? HandleSpecialNoneExistDealingDocuments(props.jurisdiction.id, Array.from(groups.keys())) : [];

  const getItems = (items?: DealingModel[]) =>
    items?.map((e, index) => {
      return (
        <React.Fragment key={e.dealingNumber}>
          {e.partyName && <ReadOnlyField label={e.partyLabel ?? ''} value={e.partyName} />}
          <FlexLayout alignItems="flex-start" fullWidth className={index !== items.length - 1 ? classes.divider : ''}>
            <ReadOnlyField label="Dealing number" value={e.dealingNumber} />
            <div className={classes.optionalSpace}>
              {e.expiryDate && <ReadOnlyField label="Expiry date" value={formatDate(e.expiryDate)} />}
              {e.registeredDate && <ReadOnlyField label={props.dateLabel} value={formatDate(e.registeredDate)} />}
            </div>
          </FlexLayout>
        </React.Fragment>
      );
    });

  return (
    <Section title={props.title}>
      {Array.from(groups, ([key, value]) => {
        return (
          <FormGroup title={key} key={key} classes={{ container: classes.formGroupContainer }}>
            {getItems(value)}
          </FormGroup>
        );
      })}
      {specialNotExistDocs.map(d => {
        return (
          <FormGroup title={d} key={d} classes={{ container: classes.formGroupContainer }}>
            <ReadOnlyField value={'None'} />
          </FormGroup>
        );
      })}
    </Section>
  );
}

export default React.memo(TitleDealings);
