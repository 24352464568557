import { FormikValues } from 'formik';
import * as yup from 'yup';

import { BaseTitleReferenceModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from '../../visibilityChecks';

const yupTitleReference = yup
  .object<BaseTitleReferenceModel>()
  .nullable(true)
  .shape({
    isSelected: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED)
  }) as yup.ObjectSchema<BaseTitleReferenceModel>;

const yupTitleReferences = yup
  .array<BaseTitleReferenceModel>()
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .of(yupTitleReference)
  .test('titleReferences conditional required check', 'At least 1 title must be selected', function test(this: yup.TestContext<FormikValues>, value: any) {
    /**
     * what we found in schema:
     * [{"required":{"titleReferences":{"$items":{"$oneOf":{"isSelected":true}}}},"message":null}]
     */
    const titleReferences: BaseTitleReferenceModel[] = value ?? [];
    const isValid = titleReferences.some(({ isSelected }) => isSelected);
    return isValid;
  });

export default yup //
  .mixed<BaseTitleReferenceModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES,
      validationWhenVisible: yupTitleReferences,
      isObjectOrArray: true
    })
  );
