import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { PartLandExtentEnum } from './enums';

// this file was automatically generated from models.ts.mustache
export const PARTY_BOOK_KEY = 'partyBook';

export interface ApiPriorityNoticeModel {
  applicants: PartyBookIdentifierModel[];
  partyBook: ApiDocumentPartyModel[];
  titleReferencesData: TitleReferenceModel[];
  transactionInstruments: TransactionInstrumentModel[];
  workspaceTitleReferences?: string[];
}

export interface PartyBookIdentifierModel {
  partyBookId: string | null;
}

export interface DocumentPartyModel {
  abn?: string;
  acln?: string;
  acn?: string;
  administrationStatus?: number | null;
  arbn?: string;
  externalId?: string;
  familyNameOrder?: string;
  firstName?: string;
  id?: string;
  isAutoJustified?: boolean;
  lastName?: string;
  legalEntityName?: string;
  nameChange?: DocumentPartyJustificationModel;
  nameSuffix?: string;
  organisationName?: string;
  organisationType?: number | null;
  partyType: number | null;
  propertiesMappedFromRis?: string[];
  requiresNameChange: boolean;
  risInvolvedPartyInfo?: RisInvolvedPartyInfoModel[];
}

export interface DocumentPartyJustificationModel {
  actionRequiredValue?: number | null;
  firstName?: string;
  justificationReason: number | null;
  lastName?: string;
  legalEntityName?: string;
  organisationName?: string;
  partyType?: number | null;
  supportingEvidences?: SupportingEvidenceModel[];
}

export interface SupportingEvidenceModel {
  evidenceDate?: Date | string | null;
  evidenceDocumentReference?: string;
  evidenceTypeValue: number | null;
}

export interface RisInvolvedPartyInfoModel {
  nameOnTitle?: string;
  risInvolvedPartyId?: string;
  title?: string;
}

export interface TitleReferenceModel {
  isPartLandAffected?: boolean;
  isSelected: boolean;
  partLandExtentType?: PartLandExtentEnum;
  landDescription?: string;
  partDescription?: string;
  reference?: string;
}

export interface TransactionInstrumentModel {
  dealingNumber?: string;
  documentType: number | null;
  receivingParties?: PartyBookIdentifierModel[];
  titleReferences?: TitleReferenceModel[];
}

export type PriorityNoticeModel = Omit<ApiPriorityNoticeModel, 'partyBook'> & {
  [PARTY_BOOK_KEY]: PartyModel[];
};
