import { defaultMemoize } from 'reselect';

import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

export const memoizedAllParticipantSigned = defaultMemoize((documentList: Array<WorkspaceDocumentSummaryApiResponse>) => {
  if (documentList.length === 0) {
    return false;
  }
  return documentList.every(doc => {
    return doc.documentParticipants.every(participant => participant.documentStatus === DocumentStatusEnum.Signed);
  });
});
