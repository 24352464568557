import * as React from 'react';

import { useDispatch } from 'react-redux';

import { UserProfileModel } from '@sympli/api-gateway/shared';

import { UserDetailsState } from 'src/containers/settings/settings/reducers/userDetails';
import { UserSuspensionState } from 'src/containers/settings/settings/reducers/userSuspension';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import UserMoreActionButton from './UserMoreActionButton';

interface Props {
  userId: string;
}

function UserMoreActionButtonContainer({ userId }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const userDetails: UserDetailsState = useStoreSelector(store => store.userDetails);

  const userSuspension: UserSuspensionState = useStoreSelector(store => store.userSuspension);
  const userProfile: UserProfileModel = useProfile().data!;
  const isEditMode: boolean = userId !== 'new';

  return (
    <UserMoreActionButton //
      // route params
      userId={userId}
      // redux
      userDetails={userDetails}
      userSuspension={userSuspension}
      userProfile={userProfile}
      // other
      isEditMode={isEditMode}
      dispatch={dispatch}
    />
  );
}

export default React.memo(UserMoreActionButtonContainer);
