import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS, AttachmentTypeEnum } from '@sympli-mfe/enums-2-27/wa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache
/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 path:
 - extentOfLandDischarged
 - extentOfMoneyDischarged

 schema version:
[{"id":0,"value":"Whole","name":"Whole","_meta":null},{"id":1,"value":"Part","name":"Part","_meta":null}]

 hash:
 WzAsMV0=
*/
export enum ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum {
  Whole = 0,
  Part = 1
}
export const EXTENT_OF_LAND_DISCHARGED_OR_EXTENT_OF_MONEY_DISCHARGED_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum.Whole,
    name: 'Whole'
  },
  {
    id: ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum.Part,
    name: 'Part'
  }
];

// only subset of NECDS lookup options
export const WA_DM_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<string>[] = restrictedLookupOptions(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS, {
  whitelist: [
    AttachmentTypeEnum.AustralianSecuritiesInvestmentCommissionCertificate,
    AttachmentTypeEnum.ChangeOfNameCertificate,
    AttachmentTypeEnum.CitizenshipCertificate,
    AttachmentTypeEnum.ConsentLetter,
    AttachmentTypeEnum.CourtOrder,
    AttachmentTypeEnum.MarriageCertificate,
    AttachmentTypeEnum.Other,
    AttachmentTypeEnum.PaidDutyCertificate,
    AttachmentTypeEnum.StatutoryDeclaration
  ]
});
