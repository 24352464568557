import { createAsyncAction } from 'typesafe-actions';

import { NotificationSettingsApiResponse } from '../preferences/detail/notifications/models';

export const actionFetchNotificationsSettings = createAsyncAction(
  //
  'FETCH_NOTIFICATIONSSETTINGS',
  'FETCH_NOTIFICATIONSSETTINGS_SUCCESS',
  'FETCH_NOTIFICATIONSSETTINGS_ERROR'
)<
  //
  undefined,
  { data: NotificationSettingsApiResponse },
  { error: Error }
>();
