import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    bottomLine: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 10,
      marginBottom: 20
    },
    bottomLineNone: {
      borderBottom: 'none'
    },
    phoneFormGroupContentContainer: {
      '& :not(:last-child)': {
        marginRight: 16
      }
    },
    desc: { marginBottom: 4 },
    heading: { fontWeight: 'bold', marginTop: 14 },
    lightText: { color: theme.palette.action.disabled },
    topBorder: { paddingTop: 14, borderTop: '1px solid #d0d0d0' },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 14
    },
    description: {
      fontSize: 16,
      lineHeight: 1.2
    },
    alertContainer: {
      padding: '20px 30px',
      background: theme.palette.grey[100],
      marginBottom: 24
    },
    submitButton: {
      height: 34,
      width: 200,
      minWidth: 0,
      marginLeft: 8,
      paddingLeft: 10,
      paddingRight: 10
    },
    flexGrow: {
      flexGrow: 1
    }
  }),
  {
    name: 'PersonalDetails'
  }
);
