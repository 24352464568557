import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    vertical: {
      height: 18,
      width: 2,
      border: 'none',
      margin: 0,
      marginLeft: 4,
      marginRight: 4,
      background: theme.palette.divider
    },
    reorderIcon: {
      color: colors.TEAL
    },
    iconDisabled: {
      fill: theme.palette.action.disabled,
      color: theme.palette.action.disabled,
      pointerEvents: 'none'
    },
    btnText: {
      color: colors.GREY_NEW
    }
  }),
  { name: 'DocumentListToolbar' }
);
