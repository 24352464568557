import { createAction, createAsyncAction } from 'typesafe-actions';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { GroupDetailsApiResponse, GroupDocumentsWorkflowApiResponse, GroupsApiResponse, SubscriberTrustAccountsApiResponse, UserApiResponse } from '../group-settings/models';
import { GroupLodgementApiResponse } from '../reducers/groupLodgement';

// groups settings
export const actionFetchGroupsFeed = createAsyncAction(
  //
  'FETCH_GROUPS_FEED',
  'FETCH_GROUPS_FEED_SUCCESS',
  'FETCH_GROUPS_FEED_ERROR'
)<
  //
  undefined,
  { data: GroupsApiResponse[] },
  { error: Error }
>();

export type GroupDetailsApiRequest = string;
export const actionFetchGroupDetails = createAsyncAction(
  //
  'FETCH_GROUP_DETAILS',
  'FETCH_GROUP_DETAILS_SUCCESS',
  'FETCH_GROUP_DETAILS_ERROR'
)<
  //
  GroupDetailsApiRequest,
  { data: GroupDetailsApiResponse },
  { error: Error }
>();

export const actionResetGroupDetails = createAction(
  //
  'RESET_GROUP_DETAILS'
)();

export const actionFetchSubscriberTrustAccounts = createAsyncAction(
  //
  'FETCH_SUBSCRIBER_TRUST_ACCOUNTS',
  'FETCH_SUBSCRIBER_TRUST_ACCOUNTS_SUCCESS',
  'FETCH_SUBSCRIBER_TRUST_ACCOUNTS_ERROR'
)<
  //
  undefined,
  { data: SubscriberTrustAccountsApiResponse },
  { error: Error }
>();

export const actionFetchGroupUsers = createAsyncAction(
  //
  'FETCH_GROUP_USERS',
  'FETCH_GROUP_USERS_SUCCESS',
  'FETCH_GROUP_USERS_ERROR'
)<
  //
  GroupDetailsApiRequest,
  { data: UserApiResponse[] },
  { error: Error }
>();

export const actionDeleteGroupUser = createAction(
  //
  'DELETE_GROUP_USER'
)<string>();

export const actionFetchGroupDocumentsWorkflow = createAsyncAction(
  //
  'FETCH_GROUP_DOCUMENTS_WORKFLOW',
  'FETCH_GROUP_DOCUMENTS_WORKFLOW_SUCCESS',
  'FETCH_GROUP_DOCUMENTS_WORKFLOW_ERROR'
)<
  //
  GroupDetailsApiRequest,
  { data: GroupDocumentsWorkflowApiResponse },
  { error: Error }
>();

export const actionFetchGroupDistributionsWorkflow = createAsyncAction(
  //
  'FETCH_GROUP_DISTRIBUTIONS_WORKFLOW',
  'FETCH_GROUP_DISTRIBUTIONS_WORKFLOW_SUCCESS',
  'FETCH_GROUP_DISTRIBUTIONS_WORKFLOW_ERROR'
)<
  //
  GroupDetailsApiRequest,
  { data: GroupDocumentsWorkflowApiResponse },
  { error: Error }
>();

export const actionFetchGroupLodgement = createAsyncAction(
  //
  'FETCH_GROUP_LODGEMENT',
  'FETCH_GROUP_LODGEMENT_SUCCESS',
  'FETCH_GROUP_LODGEMENT_ERROR'
)<
  //
  GroupDetailsApiRequest,
  { data: GroupLodgementApiResponse },
  { error: Error }
>();

export const actionUpdateGroupLodgement = createAction('UPDATE_GROUP_LODGEMENT')<GroupLodgementApiResponse>();

export interface UpdateGroupUsersRequestModel {
  groupId: string;
  addUsers: Array<LookupItemModel>;
}
