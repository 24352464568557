// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    frequency: {
      marginLeft: 10
    }
  }),
  {
    name: 'RentDetails'
  }
);
