import queryString from 'query-string';
import { call, cancelled, put, takeLatest } from 'typed-redux-saga';

import Logger, { PageActionEnum } from '@sympli/ui-logger';

import http from 'src/utils/http';
import { actionFetchLinkedWorkspaceUpdatedFeed, actionFetchSearchBoxUpdatedArchivedFeed, actionFetchSearchBoxUpdatedFeed } from './actions';
import { SearchBoxUpdatedApiRequest, SearchCategoryEnum } from './components/search-box-new/models';
import { SearchLinkedWorkspaceApiRequest, SearchLinkedWorkspaceApiResponse } from './components/search-linked-workspace/models';
import { SearchBoxesUpdatedApiResponse } from './reducers/searchBoxUpdated';

//todo: the function does not needed at all since we always search
function captureUserAction(searchCategoryId = SearchCategoryEnum.All) {
  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
    feature: 'global-search',
    searchBy: SearchCategoryEnum[searchCategoryId],
    logGroupId: ''
  });
}

//SearchBox update
const fetchSearchBoxUpdatedFeed = (q: SearchBoxUpdatedApiRequest = {}) => http.get<SearchBoxesUpdatedApiResponse>(`/Search/workspaces?${queryString.stringify(q)}`);

// fetch with debounce input change
export function* sagaDebounceFetchSearchBoxUpdatedFeed(action: ReturnType<typeof actionFetchSearchBoxUpdatedFeed.request>) {
  try {
    const data = yield* call(fetchSearchBoxUpdatedFeed, action.payload);

    const isCancelled = yield cancelled();

    if (!isCancelled) {
      captureUserAction(action.payload.searchCategoryId);
      // match empty string address to NO ADDRESS HELD
      const i = data.items.map(x => {
        return {
          ...x,
          titleAddresses: x.titleAddresses?.map(x => (x.length === 0 ? 'NO ADDRESS HELD' : x))
        };
      });
      yield put(actionFetchSearchBoxUpdatedFeed.success({ data: { ...data, items: i, searchTerm: action.payload.searchTerm } }));
    }
  } catch (error) {
    yield put(actionFetchSearchBoxUpdatedFeed.failure({ error }));
  }
}

//linked workspace search update
const fetchSearchLinkedWorkspaceUpdatedFeed = (q: SearchLinkedWorkspaceApiRequest) =>
  http.get<SearchLinkedWorkspaceApiResponse>(`/Search/linked-workspaces?${queryString.stringify(q)}`);

// fetch with debounce input change
export function* sagaDebounceFetchSearchLinkedWorkspaceFeed(action: ReturnType<typeof actionFetchLinkedWorkspaceUpdatedFeed.request>) {
  try {
    const data = yield* call(fetchSearchLinkedWorkspaceUpdatedFeed, action.payload);

    const isCancelled = yield cancelled();

    if (!isCancelled) {
      // match empty string address to NO ADDRESS HELD
      const i = data.items.map(x => {
        return {
          ...x,
          titleAddresses: x.titleAddresses?.map(x => (x.length === 0 ? 'NO ADDRESS HELD' : x))
        };
      });
      yield put(actionFetchLinkedWorkspaceUpdatedFeed.success({ data: { ...data, items: i, searchTerm: action.payload.searchTerm } }));
    }
  } catch (error) {
    yield put(actionFetchLinkedWorkspaceUpdatedFeed.failure({ error }));
  }
}

// fetch with debounce input change
export function* sagaDebounceFetchSearchBoxUpdatedArchivedFeed(action: ReturnType<typeof actionFetchSearchBoxUpdatedArchivedFeed.request>) {
  try {
    const data = yield* call(fetchSearchBoxUpdatedFeed, action.payload);

    const isCancelled = yield cancelled();

    if (!isCancelled) {
      //TODO improve our logging, we no longer rely on searchCategoryId
      captureUserAction(action.payload.searchCategoryId);
      // match empty string address to NO ADDRESS HELD
      const i = data.items.map(x => {
        return {
          ...x,
          titleAddresses: x.titleAddresses?.map(x => (x.length === 0 ? 'NO ADDRESS HELD' : x))
        };
      });
      yield put(actionFetchSearchBoxUpdatedArchivedFeed.success({ data: { ...data, items: i, searchTerm: action.payload.searchTerm } }));
    }
  } catch (error) {
    yield put(actionFetchSearchBoxUpdatedArchivedFeed.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchSearchBoxUpdatedFeed.request, sagaDebounceFetchSearchBoxUpdatedFeed),
  takeLatest(actionFetchSearchBoxUpdatedArchivedFeed.request, sagaDebounceFetchSearchBoxUpdatedArchivedFeed),
  takeLatest(actionFetchLinkedWorkspaceUpdatedFeed.request, sagaDebounceFetchSearchLinkedWorkspaceFeed)
];
