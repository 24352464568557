import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    formGroupContainer: {
      margin: 0
    },
    fullWidth: {
      width: '100%'
    },
    searchInviteSubscriber: {
      display: 'block',
      width: '85%'
    },
    invitationDescription: {
      width: '125%'
    },
    formGroupTitle: {
      color: theme.palette.text.primary,
      fontSize: 14,
      lineHeight: '18px'
    },
    formGroupColumnTitleContainer: {
      paddingBottom: 0
    },
    checkbox: {
      marginBottom: '10px',
      '& > span.MuiFormControlLabel-label': {
        width: '100%'
      }
    },
    searchContainer: {
      width: '92%'
    },
    fieldLine: {
      position: 'relative',
      '& + $fieldLine': {
        borderTop: `1px solid ${theme.palette.grey['300']}`,
        paddingTop: 10
      },

      '& > div + div': {
        marginLeft: 10
      }
    },
    suggestionsContainer: {
      maxHeight: 110,
      overflow: 'auto'
    },
    noDataMessage: {
      minHeight: 20,
      fontSize: 14,
      color: theme.palette.text.secondary,
      fontStyle: 'italic'
    },
    error: {
      color: theme.palette.error.dark,
      '& svg': {
        fill: theme.palette.error.dark
      },
      width: '90%'
    },
    roleValidationError: {
      color: theme.palette.error.main,
      paddingLeft: 20
    }
  }),
  {
    name: 'Invitations'
  }
);
