import * as React from 'react';

import classNames from 'classnames';

import ReportSectionHeader from './ReportSectionHeader';
import { useStyles } from './styles';

interface Props {
  title: string | JSX.Element;
  className?: string;
}

function ReportSectionWrapper({ title, children, className }: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const header: JSX.Element =
    typeof title === 'string' ? ( //
      <ReportSectionHeader className={classes.titleWrapper}>{title}</ReportSectionHeader>
    ) : (
      <div className={classNames(classes.titleWrapper, className)}>{title}</div>
    );

  return (
    <section className={classes.root}>
      {header}
      {children}
    </section>
  );
}

export default React.memo(ReportSectionWrapper);
