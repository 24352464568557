import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap, FallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructionsDocumentModel, LodgementInstructionsDocumentModel } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructionsDocumentModel, ApiLodgementInstructionsDocumentModel> {
  constructor(readonly fallback: FallbackMap<ApiLodgementInstructionsDocumentModel> = fallbackMap) {}

  fromApiToFormModel(apiValues: ApiLodgementInstructionsDocumentModel, context: IConverterContext): LodgementInstructionsDocumentModel {
    let formValues = applyDefaultMap(apiValues, this.fallback);
    formValues.isResponsibleParticipant = context.currentParticipant?.isResponsibleParticipant || false;
    return formValues;
  }
  fromFormToApiModel(formValues: LodgementInstructionsDocumentModel, _originalApiModel?: ApiLodgementInstructionsDocumentModel): ApiLodgementInstructionsDocumentModel {
    return applyVisibilityFallbackMap(formValues, this.fallback);
  }
}

export { Converter };

export default new Converter();
