import http from 'src/utils/http';
import { PrepareFileUploadResponse, WorkspaceQueryParams } from './models';

export const uploadFile = async (file: File, query: WorkspaceQueryParams) => {
  const { workspaceId, participantId } = query;

  const { uploadUrl, uploadedFileItem } = await http.post<PrepareFileUploadResponse>( //
    `/files/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/prepare`,
    {
      fileName: file.name
    }
  );

  await http.put<File>(uploadUrl, file, {
    headers: {
      'Content-Type': uploadedFileItem.fileType,
      'X-Amz-Server-Side-Encryption': 'AES256',
      Authorization: undefined // explictly ignore Bearer token [WEB-27330]
    }
  });

  return [
    {
      id: uploadedFileItem.id,
      fileName: uploadedFileItem.fileName,
      suggestedFileName: uploadedFileItem.suggestedFileName,
      fileType: uploadedFileItem.fileType
    }
  ];
};
