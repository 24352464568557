import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage, GlobalErrorMessageModel } from 'src/actions/globalErrors';
import { useProfileUserReportsPermissions } from 'src/containers/dashboard/reports/reducers/profileUserReportsPermissions';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { actionFetchProfileUserReportsPermissions } from '../../../actions';
import { fetchFavouriteReport, fetchReportDetail } from '../../../api';
import { getReportPermissionOptions } from '../../../helper';
import { ReportDetailsState, ReportRoutingToTypeMap, ReportSubTypesMap } from '../../../models';

interface RouteParams {
  tab: string;
  id: string;
  subType?: string;
}

const useReportDetails = () => {
  const dispatch = useSafeDispatch(useDispatch());
  const { tab, id, subType: subTypeQuery } = useRouterParams<RouteParams>();
  const [reportDetails, setReportDetails] = useState<ReportDetailsState | null>(null);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [reportType, setReportType] = useState<ReportTypeEnum>(ReportRoutingToTypeMap[tab]);
  const subType = subTypeQuery || ReportSubTypesMap[reportType ?? '']?.[0];

  const userProfile = useProfile().data;

  const userReportPermissions = useProfileUserReportsPermissions(userProfile!.userId).detail;
  const userReportPermissionStatus = useProfileUserReportsPermissions(userProfile!.userId).status;

  const initialiseFavouriteReportDetail = useCallback(async () => {
    try {
      setIsReportLoading(true);

      const favouriteReport = await fetchFavouriteReport({ reportId: id });
      const reportDetail = await fetchReportDetail({ reportType: favouriteReport.reportType, subType });

      //The favouriteReport.filterData is a serialized string. Reason why we return a serialized string from backend is because each report type's filter is very different and dynamic.
      //Since we just apply these filters directly to Tableau's viz object, our application doesn't care what's in these fields.
      setReportDetails({
        endpoint: reportDetail.endpoint,
        filterData: favouriteReport.filterData ? JSON.parse(favouriteReport.filterData) : undefined,
        reportName: favouriteReport.name,
        parameterData: favouriteReport.parameterData ? JSON.parse(favouriteReport.parameterData) : undefined
      });
    } catch (error) {
      Logger.console(SeverityEnum.Log, error.message);

      let errorPayload: GlobalErrorMessageModel;
      switch (error.response.status) {
        case 403:
          errorPayload = { ...error, redirectOnClose: '/reports' };
          break;
        case 404:
          errorPayload = { ...error, title: 'Report has been deleted', redirectOnClose: '/reports' };
          break;
        default:
          errorPayload = { ...error };
      }

      dispatch(actionCreateGlobalErrorMessage(errorPayload));
    } finally {
      setIsReportLoading(false);
    }
  }, [id, subType, dispatch]);

  const initialiseNewReportDetail = useCallback(
    async (reportTypeParam: ReportTypeEnum, subTypeParam?: string) => {
      try {
        if (!userReportPermissions) return;
        setIsReportLoading(true);

        const authorisedReports = getReportPermissionOptions(userReportPermissions);
        const errorMessage: GlobalErrorMessageModel = {
          title: 'Something went wrong',
          message: 'Access is denied',
          redirectOnClose: '/reports'
        };
        if (authorisedReports.length) {
          const selectedAuthorisedReport = authorisedReports.find(report => report.id === reportTypeParam);
          if (selectedAuthorisedReport) {
            const reportDetail = await fetchReportDetail({ reportType: selectedAuthorisedReport.id, subType: subTypeParam });
            setReportDetails({ endpoint: reportDetail.endpoint });
          } else {
            throw errorMessage;
          }
        } else {
          throw errorMessage;
        }
      } catch (error) {
        Logger.console(SeverityEnum.Log, error.message);
        dispatch(actionCreateGlobalErrorMessage(error));
      } finally {
        setIsReportLoading(false);
      }
    },

    [userReportPermissions, dispatch]
  );

  useEffect(() => {
    if (!userReportPermissions) {
      dispatch(actionFetchProfileUserReportsPermissions.request(userProfile!.userId));
    }
  }, [userReportPermissions, userProfile, dispatch]);

  const isNewGeneratedReport = id === 'new';

  useEffect(() => {
    if (isNewGeneratedReport) {
      initialiseNewReportDetail(reportType, subType);
    }
  }, [isNewGeneratedReport, reportType, subType, initialiseNewReportDetail]);

  useEffect(() => {
    const isExistingFavouriteReport = !isNewGeneratedReport;
    if (isExistingFavouriteReport) {
      initialiseFavouriteReportDetail();
    }
  }, [isNewGeneratedReport, initialiseFavouriteReportDetail]);

  useEffect(() => {
    // when routing changes, we need to update internal reportType value
    setReportType(ReportRoutingToTypeMap[tab]);
  }, [tab]);

  return {
    //
    userReportPermissions: {
      result: userReportPermissions,
      status: userReportPermissionStatus
    },
    reportDetails: {
      isLoading: isReportLoading,
      isNewGeneratedReport: isNewGeneratedReport,
      result: reportDetails
    },
    setReportType,
    reportType,
    subType
  };
};

export default useReportDetails;
