// this file was automatically generated from components/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    quarterWidth: {
      width: '25%'
    },
    areaUnitLeftMargin: {
      marginLeft: 12
    }
  }),
  {
    name: 'LandArea'
  }
);
