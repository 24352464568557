import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { getGeneralAccountsLookup } from '../../helper';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  name: string;
  category: string;
  financialAccounts?: FinancialAccountApiResponse[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function SelectAccounts({
  //
  name,
  category,
  financialAccounts,
  onChange,
  classes
}: Props) {
  const options = getGeneralAccountsLookup(financialAccounts, category);
  if (options.length) {
    return (
      <Field //
        label="Account"
        component={SelectField}
        name={name}
        placeholder="Enter account details manually"
        options={options}
        onChange={onChange}
        className={classes.fullWidth}
      />
    );
  }
  return null;
}

export default React.memo(withStyles(styles)(SelectAccounts));
