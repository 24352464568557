import * as yup from 'yup';

import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { PartyCapacityEnum } from '@sympli-mfe/enums-2-26/wa';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_ADDRESS } from '../../../../visibilityChecks';
import { CaveatorPartyModel, WithdrawalOfCaveat2_26_0Model } from '../../models';

interface CaveatorsContext {
  partyBook: WithdrawalOfCaveat2_26_0Model['partyBook'];
}

const contextResolver = ({ partyBook }: WithdrawalOfCaveat2_26_0Model): CaveatorsContext => {
  return {
    partyBook
  };
};

const yupCaveatorItem = yup
  .object<CaveatorPartyModel, CaveatorsContext>({
    partyBookId: yup.mixed<string>(),
    partyCapacity: yup.mixed<PartyCapacityEnum | null>(),
    partyCapacityDetail: yup.mixed<string>(),
    addressBookId: yup
      .string()
      .default('')
      .trim()
      .test('addressId conditional required check', msg.REQUIRED, function test(this: yup.TestContext<CaveatorsContext>, value: string) {
        const { partyBookId } = this.parent as CaveatorPartyModel;
        const { partyBook } = this.options.context!;

        const party = partyBook.filter(x => x.id === partyBookId);

        return !!value?.trim()?.length || !VISIBILITY_CHECK_ADDRESS(party[0]);
      })
  })
  .defined()
  .log();
const yupCaveators = memoizeSchemaWithContext<CaveatorPartyModel[], WithdrawalOfCaveat2_26_0Model, CaveatorsContext>(
  yup.array<CaveatorPartyModel, CaveatorsContext>().of<CaveatorPartyModel>(yupCaveatorItem).defined().log(),
  contextResolver
);

export default yupCaveators;
