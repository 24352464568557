import { asDecimal } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { FractionModel, ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { ApiPartyCapacityModel, Identity, MergeMetadata } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';

export declare type MergeParty = {
  id?: string;
  partyType?: PartyTypeEnum;
  legalEntityName?: string;
  externalId?: string;
  mergeMetadata?: MergeMetadata;
  partyCapacity?: ApiPartyCapacityModel;
};

export class Container<T extends MergeParty> {
  constructor(
    public key: string,
    public tenancyDetail: TenancyDetail<T>
  ) {}

  /**
   * Will deep copy of top-level party data and a shallow copy of the nested data.
   * @returns Return a new container
   */
  clone() {
    return new Container(this.key, this.tenancyDetail.clone());
  }

  /**
   * Reset merge meta data
   * @returns Return same object after mutation
   */
  resetMetadata(requiresJustification: boolean) {
    this.tenancyDetail.proprietorGroups.forEach((pg, pgIndex) =>
      pg.parties.forEach((p, pIndex) => {
        const identity = new Identity(this.key, pgIndex, pIndex);
        p.mergeMetadata = {
          requiresJustification: requiresJustification,
          matchingGroupHash: identity.groupHash,
          matchingItemHash: identity.itemHash,
          identity: identity
        };
      })
    );

    return this;
  }
}

export class TenancyDetail<T extends MergeParty> {
  constructor(
    public proprietorGroups: ProprietorGroup<T>[],
    public tenancyType?: TenancyTypeEnum
  ) {}

  clone() {
    return new TenancyDetail(
      this.proprietorGroups.map(pg => pg.clone()),
      this.tenancyType
    );
  }
}

export class ProprietorGroup<T extends MergeParty> {
  mergedParties: T[];

  constructor(
    public parties: T[],
    public tag?: any,
    public shareFraction?: FractionModel,
    public proprietorGroupType?: ProprietorGroupTypeEnum
  ) {
    this.mergedParties = [...parties];
  }

  /**
   * Will deep copy of top-level party data and a shallow copy of the nested data.
   * @returns Return a new proprietor group
   */
  clone() {
    return new ProprietorGroup(
      this.parties.map(p => ({ ...p })),
      this.tag,
      this.shareFraction,
      this.proprietorGroupType
    );
  }

  /**
   * Will check whether it is possible to merge or not
   * @returns return None if there is no problem otherwise failed reason
   */
  verifyMerge(reference: ProprietorGroup<T>): MergeFailedReasonsEnum {
    if (this.parties.length !== reference.parties.length) return MergeFailedReasonsEnum.StructureNotSame;
    if (!this.sameShareFraction(reference)) return MergeFailedReasonsEnum.ShareFractionMismatch;
    if (!this.matchedPartyTypes(reference)) return MergeFailedReasonsEnum.PartyTypeMismatch;
    return MergeFailedReasonsEnum.None;
  }
  /**
   * Will check the party types are same between groups
   * @returns true when parties are same between groups
   */
  matchedPartyTypes(reference: ProprietorGroup<T>): boolean {
    return (
      this.parties
        .map(cp => cp.partyType)
        .sort()
        .join(',') ===
      reference.parties
        .map(rp => rp.partyType)
        .sort()
        .join(',')
    );
  }

  /**
   * Will check the share fraction is same
   * @returns true when same
   */
  sameShareFraction(reference: ProprietorGroup<T>): boolean {
    if (!this.shareFraction && !reference.shareFraction) {
      return true;
    }

    if (!this.shareFraction || !reference.shareFraction) {
      return false;
    }

    return asDecimal(this.shareFraction) === asDecimal(reference.shareFraction);
  }
}

export enum MergeFailedReasonsEnum {
  None,
  StructureNotSame,
  ShareFractionMismatch,
  PartyTypeMismatch
}

export class PartyMergeResult<T extends MergeParty> {
  constructor(
    public containers: Container<T>[],
    public failedReason?: MergeFailedReasonsEnum
  ) {}
}

export class ComparisonMappings<T extends MergeParty> {
  comparisons: ReferenceGroup<T>[][];
  failedReason: MergeFailedReasonsEnum;
  constructor(comparisons?: ReferenceGroup<T>[][], failedReason?: MergeFailedReasonsEnum) {
    this.comparisons = comparisons ?? new Array<Array<ReferenceGroup<T>>>();
    this.failedReason = failedReason ?? MergeFailedReasonsEnum.None;
  }

  public static StructureNotSame<T extends MergeParty>() {
    return new ComparisonMappings<T>(undefined, MergeFailedReasonsEnum.StructureNotSame);
  }
}

export class ReferenceGroup<T extends MergeParty> {
  comparisonGroups: ComparisonGroup<T>[];

  constructor(public proprietorGroup: ProprietorGroup<T>) {
    this.comparisonGroups = [];
  }

  getMaxCompare(): number {
    return Math.max(...this.comparisonGroups.map(x => x.matchingPartyMaps.length));
  }
}

export class ComparisonGroup<T extends MergeParty> {
  constructor(
    public proprietorGroup: ProprietorGroup<T>,
    public matchingPartyMaps: PartyMap<T>[]
  ) {}
}

export class PartyMap<T extends MergeParty> {
  constructor(
    public referenceParty: T,
    public matchedParty: T
  ) {}
}
