import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { resolveContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { CaveatModel_2_21_1 } from '../../models';

export const yupParty = resolvePartyModelValidationSchema(NSW_CAVEAT_PARTY_FORM_CONFIG);

export const yupPartyBook = resolveContextBasedPartyBookValidationSchema<CaveatModel_2_21_1>(
  yupParty,

  // If the party is not affected proprietor, we do not need detailed validation
  // (Note: If unaffected proprietors are selected in other sections, non-detailed validation (abortEarly: true) should be performed in the validation of those sections.)
  ({ proprietors }, partyIdForCheck) => proprietors?.some(proprietor => proprietor.isAffected && proprietor.partyBookId === partyIdForCheck) || false
);
