import * as React from 'react';

import ProfileContext, { ProfileContextModel } from './Profile.context';
import { IsAuthorizedSelectorType, makeSelector, SecurityRequirementProps } from './selectors';

type ChildrenAsFunction = (props: { disabled: boolean }) => React.ReactNode;

interface OwnProps extends SecurityRequirementProps {
  children: React.ReactElement<{ disabled?: boolean }> | ChildrenAsFunction;
  hiddenForDisabled?: boolean;
  // provide what should be rendered if the authorisation check fails. Defaults to null.
  fallback?: React.ReactNode;
}

type Props = OwnProps;

class Secured extends React.PureComponent<Props> {
  private isAuthorizedSelector: IsAuthorizedSelectorType;

  constructor(props: Props) {
    super(props);
    this.isAuthorizedSelector = makeSelector();
  }

  render() {
    // console.log('%cProfile.context.Consumer - render (OUTSIDE of the context.CONSUMER), %s', logStyles, getCurrentTime());
    return <ProfileContext.Consumer>{profile => this.renderContent(profile)}</ProfileContext.Consumer>;
  }

  renderContent(profile: ProfileContextModel) {
    // console.log('%cProfile.context.Consumer - render (INSIDE of the context.CONSUMER), %s', logStyles, getCurrentTime());
    const {
      //
      minRole,
      requiredUserPermissions,
      requiredRolePermissions,
      children,
      hiddenForDisabled,
      fallback = null,
      ...rest
    } = this.props;
    const isAuthorized = this.isAuthorizedSelector({
      minRole,
      requiredUserPermissions,
      requiredRolePermissions,
      profile
    });
    // console.log(
    //   'Profile.context.Consumer - render (INSIDE of the context.CONSUMER) - after calling selector, resolved as %s, %s',
    //   isAuthorized,
    //   getCurrentTime()
    // );

    if (!isAuthorized && fallback) {
      if (typeof fallback === 'function') {
        return fallback(rest);
      }

      if (!React.isValidElement(fallback)) {
        return fallback;
      }

      return React.cloneElement(fallback, rest);
    }

    if (hiddenForDisabled && !isAuthorized) {
      return false;
    }

    if (typeof children === 'function') {
      return children({ disabled: !isAuthorized });
    }

    React.Children.only(children);
    if (React.isValidElement(children)) {
      const { disabled } = (children as React.ReactElement<{ disabled?: boolean }>).props;
      return React.cloneElement(children, { disabled: !!disabled || !isAuthorized });
    }
  }
}

export default Secured;
