import { ComplianceLevelEnum } from '@sympli/api-gateway/enums';

export enum ErrorVariantEnum {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

// MR - moved
// export enum ComplianceLevelEnum {
//   Error = 0,
//   Warning = 1,
//   Information = 2
// }

export enum AlertTypeEnum {
  Warning = 0,
  Error = 1
}

export interface SettlementErrorModel {
  alertType?: AlertTypeEnum;
  alertHeader?: string;
  errors: ErrorMessageModel[];
  dateCreated: Date;
}

export interface WorkspaceNotificationModel {
  alertType?: AlertTypeEnum;
  alertHeader?: string;
  messages?: (string | JSX.Element)[];
  notificationId?: string;
}

export interface ErrorMessageModel {
  level: ComplianceLevelEnum;
  message: string | JSX.Element;
}

export interface HeaderModel {
  primary: string;
  secondary?: string;
}
