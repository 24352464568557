import { useCallback } from 'react';

import { FormikProps, getIn } from 'formik';
import _get from 'lodash-es/get';

import DocumentFieldArray, { DocumentAddButtonRenderProps, DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { createModelKeyAppender, getFormikError, resolveLabel, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import ApplicableInstrument from '../../components/applicable-instrument';
import { ActionProhibitedEnum, ENUM_ACTION_PROHIBITED_OPTIONS } from '../../enums';
import { ApplicableInstrumentModel, BaseCaveatModel, ExtentOfProhibitionModel } from '../../models';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_NOT_APPLICABLE_INSTRUMENTS } from './visibilityChecks';

type FormValues = BaseCaveatModel;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

const debug = !import.meta.env.PROD;

const SectionInterestBeingClaimed = ({ name, formikProps, disabled, dialogPortalId, syncPartyReferences }: Props): JSX.Element => {
  const classes = useStyles();
  const { values, getValues, setFieldValue } = formikProps;
  const fieldName = createModelKeyAppender<ExtentOfProhibitionModel>(name);
  const prohibition: ExtentOfProhibitionModel = _get(values, name);

  const handleActionProhibitedChange = (): void => {
    let currentProhibition: ExtentOfProhibitionModel = getIn(getValues(), name);

    if (currentProhibition.actionProhibited === ActionProhibitedEnum.AbsoluteWithExceptions) {
      currentProhibition = {
        ...currentProhibition,
        notApplicableInstruments:
          currentProhibition.notApplicableInstruments.length !== 0
            ? currentProhibition.notApplicableInstruments
            : [
                {
                  instrumentType: undefined,
                  relinquishingParties: [],
                  receivingParties: []
                }
              ]
      };
    }

    if (!VISIBILITY_CHECK_NOT_APPLICABLE_INSTRUMENTS(currentProhibition)) {
      currentProhibition = { ...currentProhibition, notApplicableInstruments: [] };
    }

    setFieldValue(name, currentProhibition);
  };

  const renderNotApplicableInstrumentItem = ({ item, itemBinding, fieldName, itemFocusRef }: DocumentArrayItemRenderProps<ApplicableInstrumentModel>) => {
    return (
      <ApplicableInstrument //
        name={itemBinding}
        formikProps={formikProps}
        focusRef={itemFocusRef}
        disabled={disabled}
        dialogPortalId={dialogPortalId}
        syncPartyReferences={syncPartyReferences}
      />
    );
  };
  const createNewNotApplicableInstrumentItem = (): ApplicableInstrumentModel => ({
    instrumentType: undefined,
    relinquishingParties: [],
    receivingParties: []
  });
  const renderAddNewNotApplicableInstrumentButton = useCallback(
    ({ addButtonFocusRef, onAdd }: DocumentAddButtonRenderProps) => {
      return (
        <FormGroupFooter //
          icon="add"
          onClick={onAdd}
          buttonActions={addButtonFocusRef}
          title="Add new Not Applicable Instrument"
          className={classes.addNotApplicableInstrument}
          disabled={disabled}
        />
      );
    },
    [disabled, classes.addNotApplicableInstrument]
  );
  const renderNotApplicableInstruments = () => {
    return (
      <DocumentFieldArray //
        arrayBinding={fieldName('notApplicableInstruments')}
        itemTitle={'Not Applicable Instrument'}
        renderItem={renderNotApplicableInstrumentItem}
        createNewItem={createNewNotApplicableInstrumentItem}
        renderAddButton={renderAddNewNotApplicableInstrumentButton}
        maxItems={20}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        minItems={1}
      />
    );
  };

  return (
    <Section //
      title="Interest being claimed"
      data-name={name}
      data-testid="prohibition"
      error={getFormikError(formikProps, name)}
    >
      <FormGroup title="Estate or interest claimed" fieldAreaDirection="column">
        <Field
          label={resolveLabel('Select interest being claimed', true)}
          name={fieldName('actionProhibited')}
          format="string"
          options={ENUM_ACTION_PROHIBITED_OPTIONS}
          onChange={handleActionProhibitedChange}
          placeholder={resolveSelectPlaceholder(true)}
          component={SelectField}
          disabled={disabled}
          debug={debug}
        />
      </FormGroup>

      {!!prohibition.notApplicableInstruments.length && <hr className={classes.divider} />}
      {VISIBILITY_CHECK_NOT_APPLICABLE_INSTRUMENTS(prohibition) && renderNotApplicableInstruments()}
    </Section>
  );
};

export default SectionInterestBeingClaimed;
