import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    environmentLabel: {
      fontSize: 22,
      fontWeight: 'bold',
      color: 'white',
      display: 'table',
      margin: '0 auto',
      paddingTop: 3,
      cursor: 'default'
    },
    v2Label: {
      position: 'absolute',
      width: 12,
      height: 12,
      top: -12,
      right: -8,
      border: '1px solid #18CDB6',
      background: '#18CDB6',
      borderRadius: '50%',
      fontSize: 12,
      padding: 4,
      color: 'white',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600
    },
    labelBox: {
      position: 'relative',
      marginLeft: 10,
      borderRadius: 5,
      height: 32,
      width: 'fit-content',
      padding: '0px 10px'
    }
  },
  { name: 'EnvironmentLabel' }
);
