import * as React from 'react';

import pluralize from 'pluralize';

import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { IconCheck, IconMapMaker } from '@sympli/ui-framework/icons';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import TacChanges, { TitleProps } from '../tac-changes';
import { useStyles } from './styles';

function TitleActivity(props: TitleProps) {
  const classes = useStyles();

  if (!props.tac) return null;

  const { hasChanges, numberOfChanges, lastCheckedDate, documentChanges } = props.tac;
  const title = hasChanges ? `${numberOfChanges} ${pluralize('change', numberOfChanges)} found on ${props.reference}` : `No changes found on ${props.reference}`;
  const icon = hasChanges ? <IconMapMaker className={classes.iconWarning} /> : <IconCheck className={classes.iconPass} />;

  return (
    <Section title={title} titlePrefix={icon}>
      <FormGroup title="Last title activity check" classes={{ container: classes.formGroupContainer }}>
        <ReadOnlyField value={dateTimeLine(lastCheckedDate, DateFormatEnum.AUSDATETIMETEXT)} className={classes.bottomSpace}></ReadOnlyField>
        {!!documentChanges.length && <TacChanges {...props}></TacChanges>}
      </FormGroup>
    </Section>
  );
}

export default React.memo(TitleActivity);
