import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    arrowBox: {
      marginTop: -10,
      marginBottom: 10
    },
    arrowBoxContent: {
      backgroundColor: theme.palette.grey[100],
      padding: '10px 0px 0px 10px'
    },
    addButton: {
      paddingLeft: 0
    },
    formGroupTitle: {
      color: theme.palette.text.primary,
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'bold'
    }
  }),
  {
    name: 'RenewDetails'
  }
);
