import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import IconButton from '@mui/material/IconButton';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { IconDelete } from '@sympli/ui-framework/icons';

import { StandardTermsDealingNumbersModel, StandardTermsModel } from '../../models';
import StandardTermsDealingNumbers from '../standard-terms-dealing-numbers';
import { useStyles } from './styles';

interface Props {
  name: string;
  minItems: number;
  maxItems: number;
  additionalAttachments?: DocumentAttachmentItemModel[];
}

function StandardTermsDealingNumbersArray({ name, minItems, maxItems, additionalAttachments }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const { values, contextPath } = useFormikContext<StandardTermsModel>();
  const dataName = contextPath ? `${contextPath}.${name}` : name;
  const createNewItem = useCallback(
    (): StandardTermsDealingNumbersModel => ({
      termNumber: ''
    }),
    []
  );

  const renderItem = useCallback(
    ({ itemIndex, itemCount, itemBinding, handleRemove, removeButtonFocusRef, contextPath }: DocumentArrayItemRenderProps<StandardTermsDealingNumbersModel>) => {
      const { preRegisteredStandardTermsDealingNumbers, standardTermsDealingNumbers } = values;
      const itemValue = standardTermsDealingNumbers[itemIndex];
      const isFilled = (itemValue.termNumber ?? '').trim() !== '';
      const title = itemCount > 1 ? `Document reference ${itemIndex + 1}` : 'Document reference';
      const showFormTip = isFilled && !preRegisteredStandardTermsDealingNumbers.includes((itemValue.termNumber ?? '').trim());

      const formTip = showFormTip
        ? 'This document reference will only be used in this workspace. Please contact your Super Admin to have this added to the default list.'
        : undefined;

      const isShowingRemoveButton = standardTermsDealingNumbers.length > minItems;
      const canRemove = isShowingRemoveButton && !disabled;
      const dataName = contextPath ? `${contextPath}.${itemBinding}` : itemBinding;

      return (
        <FormGroup //
          data-name={dataName}
          key={itemBinding}
          title={title}
          fieldAreaDirection="column"
          formTip={formTip}
          {...(isShowingRemoveButton && {
            iconPrefix: (
              <IconButton color="primary" disabled={!canRemove} onClick={handleRemove} action={removeButtonFocusRef} size="large">
                <IconDelete fill="currentColor" />
              </IconButton>
            )
          })}
        >
          <StandardTermsDealingNumbers name={itemBinding} additionalAttachments={additionalAttachments} />
        </FormGroup>
      );
    },
    [values, disabled, minItems, additionalAttachments]
  );

  return (
    <div data-testid={dataName} data-name={dataName} className={classes.root}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        disabled={disabled}
        itemStyle="none"
        minItems={minItems}
        maxItems={maxItems}
        createNewItem={createNewItem}
        addButtonTitle="Add document reference"
      />
    </div>
  );
}

export default React.memo(StandardTermsDealingNumbersArray);
