import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import Applicant from '../../components/applicant';
import { resolvePartyLookupOptions } from '../../helpers';
import { ApplicantModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionApplicants({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const partyOptions = resolvePartyLookupOptions(formikProps.values);
  const items: ApplicantModel[] = _get(formikProps.values, name) ?? [];

  const createNewItem = (): ApplicantModel => ({
    partyBookId: ''
  });

  return (
    <div data-testid={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={({ itemIndex, itemBinding }) => (
          <Applicant //
            disabled={disabled}
            data-testid="applicant"
            name={itemBinding}
            itemIndex={itemIndex}
            partyOptions={partyOptions}
            items={items}
          />
        )}
        itemTitle={'Applicant'}
        disabled={disabled}
        itemStyle="formGroup"
        minItems={1}
        maxItems={20}
        createNewItem={createNewItem}
        addButtonTitle="Add Applicant"
      />
    </div>
  );
}

export default React.memo(SectionApplicants);
