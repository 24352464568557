import * as React from 'react';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LinkedSettlementItemModel } from '@sympli/api-gateway/shared';
import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';

import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { SearchTableContext, SearchTableModel } from '../search-linked-workspace/components/linked-workspace-search-table/models';
import { resolveColumns } from './helpers';

interface LinkedWorkspaceDisplayContainerProps {
  isLoading: boolean;
  linkedWorkspaceItem: LinkedSettlementItemModel;
}

function LinkedWorkspaceDisplayContainer({ isLoading, linkedWorkspaceItem }: LinkedWorkspaceDisplayContainerProps) {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const tableContext = React.useMemo<SearchTableContext>(() => ({ screenVariant }), [screenVariant]);

  const columns: ColumnsModel<SearchTableModel> = resolveColumns({
    screenVariant
  });

  function GetItem(): SearchTableModel {
    return {
      sympliId: linkedWorkspaceItem.linkedToSympliId,
      settlementDateTime: linkedWorkspaceItem.linkedToSettlementDate,
      address: linkedWorkspaceItem.linkedToWorkspaceAddress,
      reference: linkedWorkspaceItem.linkedToParticipantMatter ?? '',
      jurisdiction: linkedWorkspaceItem.linkedToWorkspaceJurisdiction,
      titles: linkedWorkspaceItem.linkedToTitles,
      workspaceId: linkedWorkspaceItem.linkedToWorkspaceId,
      participantId: linkedWorkspaceItem.linkedToParticipantId,
      role: linkedWorkspaceItem.linkedToWorkspaceRole,
      id: '',
      workspaceType: WorkspaceTypeEnum.FinancialSettlement
    };
  }
  return (
    <Table //
      noFooter
      context={tableContext}
      columns={columns}
      rows={[GetItem()]}
      isLoading={isLoading}
    />
  );
}

export default React.memo(LinkedWorkspaceDisplayContainer);
