import React from 'react';

import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';

import DeceasedProprietorReadonlyArray from '../../components/deceased-proprietor-readonly-array';
import { DeceasedJointTenantsModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  disabled: boolean;
  numberOfPartiesInPreceedingGroups: number;
}

function DeceasedJointTenants({ name, className, numberOfPartiesInPreceedingGroups }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<DeceasedJointTenantsModel>(name);

  return (
    <div data-section={name} className={className}>
      <DeceasedProprietorReadonlyArray //
        name={fieldName('deceasedProprietors')}
        numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
      />
    </div>
  );
}

export default React.memo(DeceasedJointTenants);
