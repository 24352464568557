export enum WorkspaceFileStatusEnum {
  Scanning = 0,
  Clean = 1,
  VirusFound = 2,
  ScriptFound = 3,
  VirusScanError = 4,
  ScriptScanError = 5
}

export enum UploadFileTypeEnum {
  WorkspaceFile,
  DocumentAttachment
}
