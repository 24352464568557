import React from 'react';

import { ColumnsModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import { LinkedType } from '../models';
import { useLinkingParticipantCardStyles } from './styles';

export interface LinkedParticipantRow {
  linkedType: LinkedType;
  linkingSubscriberName: string;
  linkingSubscriberRole: string;

  // those two are purely ui to calculated as display sequence
  linkedFromOrder: number;
  linkedToOrder: number;
}

export interface Props {
  rows: LinkedParticipantRow[];
  columns: ColumnsModel<LinkedParticipantRow>;
}

function LinkingParticipantCard({
  //
  rows,
  columns
}: Props) {
  const classes = useLinkingParticipantCardStyles();
  return (
    <CardDetail //
      columns={columns}
      rows={rows}
      className={classes.root}
    />
  );
}

export default React.memo(LinkingParticipantCard);
