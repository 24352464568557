import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';

import { QLD_PARTY_FORM_CONFIG as QLD_MORTGAGE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import Tenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { StateEnum } from '@sympli-mfe/enums-2-24/qld';

import { QLD_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '../../enums';
import { Mortgage2_24_0Model } from '../../models';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
}

function SectionMortgageeDetails({ name }: Props): JSX.Element {
  const {
    values: { mortgagors }
  } = useFormikContext<Mortgage2_24_0Model>();

  const partyBookIdsOfAllMortgagors = useMemo(() => mortgagors.map(mortgagor => mortgagor.partyBookId).filter(Boolean), [mortgagors]);

  return (
    <Tenancy
      name={name}
      partyFormConfig={QLD_MORTGAGE_PARTY_FORM_CONFIG}
      jurisdiction={StateEnum.QLD}
      partyCapacityLookup={PARTY_CAPACITY_LOOKUP_OPTIONS}
      componentLabels={QLD_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={partyBookIdsOfAllMortgagors}
    />
  );
}

export default React.memo(SectionMortgageeDetails);
