import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import { DocumentWorkflowPanelProps } from './DocumentWorkflowPanel';

export const useStyles = makeStyles<Theme, DocumentWorkflowPanelProps<any, any>>(
  theme => ({
    sticky: {
      position: 'sticky',
      top: 0,
      padding: '0 32px',
      zIndex: 2
    },
    stepContainer: {
      height: 60,
      flexShrink: 0
    },
    stepConnector: {
      borderWidth: 6,
      borderStyle: 'solid',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
      color: theme.palette.text.disabled,
      minWidth: 4,
      marginLeft: 20
    },
    currentStepButton: {
      borderBottom: ({ currentStep }) => `4px solid ${currentStep === DocumentWorkflowStepsEnum.Resolve ? theme.palette.error.main : theme.palette.primary.main}`,
      borderTop: `4px solid transparent`,
      height: '100%'
    }
  }),
  { name: 'DocumentWorkflowPanel' }
);
