import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { MortgageePartyModel } from '../../models';

export default yup.array<MortgageePartyModel>().of(
  yup.object<MortgageePartyModel>().shape({
    addressBookId: yup.string().default('').trim().nullable().required(msg.REQUIRED)
  })
);
