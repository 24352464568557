import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    halfWidth: {
      width: 120
    },
    devHelper: {
      right: 0,
      color: `rgba(210, 105, 30, 0.5)`,
      bottom: -12,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'sans-serif'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
