import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const sharedStyles = (theme: Theme) =>
  createStyles({
    menuItemIcon: {
      paddingRight: 6,
      color: theme.palette.text.primary,
      fill: theme.palette.primary.main
    },
    menuItemLabel: {
      fontSize: 13,
      textDecoration: 'underline',
      color: theme.palette.text.primary
    },
    menuItemIconUnsign: {
      paddingRight: 6,
      color: theme.palette.text.primary,
      fill: theme.palette.secondary.main
    },
    menuItemIconRedTimes: {
      paddingRight: 6,
      color: theme.palette.text.primary,
      fill: theme.palette.secondary.main
    }
  });

export type ClassKeys = typeof sharedStyles;

export default sharedStyles;

export const useStyles = makeStyles(sharedStyles, { name: 'UnsignMenuButton' });
