import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import DocumentItemLoader from '../document-item-loader';

interface Props {
  lineCount: number;
  color?: 'grey' | 'white';
  disableAvatar?: boolean;
}

function ListLoader({ lineCount, color = 'grey', disableAvatar }: Props) {
  return (
    <List>
      {new Array(lineCount).fill(0).map((_, index) => (
        <ListItem key={index} disableGutters={true}>
          <DocumentItemLoader color={color} disableAvatar={disableAvatar} />
        </ListItem>
      ))}
    </List>
  );
}

export default React.memo(ListLoader);
