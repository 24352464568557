import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.grey[50],
      padding: 20,
      marginBottom: 25,
      lineHeight: '22px'
    },
    // * heading and icon should have the same marginTop/PaddingTop
    icon: {
      paddingRight: 8
    },
    bold: {
      fontWeight: 500
    },
    message: {
      marginTop: 14
    }
  });

export type ClassKeys = typeof styles;

export default styles;
