import * as React from 'react';

import { Action } from 'redux';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Table, { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import { MfaLinkedDeviceModel } from 'src/containers/personal-profile/multi-factor-auth-devices/api';
import { ConfirmDialogModel } from 'src/containers/settings/settings/actions/users';
import SubheadingWithStatus from 'src/containers/settings/settings/users-setting/components/subheading-with-status';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { actionDeleteUserLinkedDevice, actionUpdateConfirmDialog } from '../../actions/users';

interface OwnProps {
  userId: string;
  isEditMode: boolean;

  isLoading: boolean;
  error?: string;
  initialValues?: Array<MfaLinkedDeviceModel>;
  loggedUserId?: string;
  confirmDialog: ConfirmDialogModel;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps;

class TabLinkedDevices extends React.Component<Props> {
  private columns: ColumnsModel<MfaLinkedDeviceModel>;

  constructor(props: Props) {
    super(props);
    this.columns = this.getColumnDefinitions();
  }

  get isLoading() {
    const { initialValues, isLoading, error, isEditMode } = this.props;
    return isEditMode && !error && (isLoading || !initialValues);
  }

  private formatTimeStamp = (config: FormatterInputModel<MfaLinkedDeviceModel>) => {
    const { created, updated } = config.row;
    const dateToBeFormatted = config.column.name === 'created' ? created : updated;
    const formattedDate = dateTimeLine(dateToBeFormatted, DateFormatEnum.AUSDATETIMETEXT);

    return <span>{formattedDate}</span>;
  };

  private formatUnlink = (config: FormatterInputModel<MfaLinkedDeviceModel>) => {
    const { id } = config.row;
    const { initialValues: devices, loggedUserId, userId } = this.props;
    const disabled = loggedUserId === userId && devices != null && devices.length <= 1;
    return (
      <ButtonLink color="primary" data-device-id={id} onClick={this.handleOnUnlinkClick} disabled={disabled}>
        Unlink
      </ButtonLink>
    );
  };

  private readonly defaultColumns = {
    name: {
      label: 'Name'
    },
    created: {
      label: 'Linked On',
      formatter: this.formatTimeStamp
    }
  };

  private readonly additionalColumns = {
    unlink: {
      label: 'Unlink',
      formatter: this.formatUnlink
    }
  };

  private readonly emptyColumns = {};

  private getColumnDefinitions = () => {
    const extraColumns = this.props.loggedUserId === this.props.userId ? this.emptyColumns : this.additionalColumns;

    return {
      ...this.defaultColumns,
      ...extraColumns
    };
  };

  private renderConfirmUnlinkDialog = () => {
    const { isOpen, isLoading } = this.props.confirmDialog;
    return (
      <ConfirmationDialog title="Unlink connected device" okButtonText="Yes, continue" open={isOpen} isLoading={isLoading} onClose={this.handleOnDialogClose} showActionButtons>
        Are you sure you want to unlink the connected device?
      </ConfirmationDialog>
    );
  };

  render() {
    const { isLoading, initialValues: devices = [], userId } = this.props;

    return (
      <>
        {this.renderConfirmUnlinkDialog()}
        <SubheadingWithStatus userId={userId} />
        <Table //
          pagination={false}
          bordersWithSpacing={true}
          columns={this.columns}
          rows={devices}
          loading={isLoading}
          sortable={false}
        />
      </>
    );
  }

  private handleOnUnlinkClick = (event: React.MouseEvent<HTMLElement>) => {
    const deviceId = dataAttribute('device-id', event) || '';
    this.props.dispatch(actionUpdateConfirmDialog({ isOpen: true, deviceId }));
  };

  private handleOnDialogClose = (confirmed?: boolean) => {
    const { userId, confirmDialog, dispatch } = this.props;
    if (confirmed) {
      dispatch(actionDeleteUserLinkedDevice({ userId, deviceId: confirmDialog.deviceId || '' }));
    } else {
      dispatch(actionUpdateConfirmDialog({ isOpen: false, deviceId: undefined }));
    }
  };
}

export default TabLinkedDevices;
