// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    issuingInstruction: {
      width: 'auto'
    },
    isDuplicateToIssue: {
      marginBottom: 0
    },
    issuingInstructionDetails: {
      paddingBottom: 0,
      marginBottom: 20
    },
    radioFormControlLabel: {
      flexGrow: 0
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'min-content min-content',
      columnGap: theme.spacing(1.25),
      gridAutoFlow: 'column'
    },
    halfWidth: {
      width: '120px'
    }
  }),
  {
    name: 'DuplicateHoldingDetail'
  }
);
