import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ClassNameMap } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconErrorCross } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  id: string;
  errorMessages?: ErrorMessage[];
}

export interface ErrorMessage {
  message: string;
  hint?: Hint;
}

export interface Hint {
  message: string;
  linkDetails?: LinkDetails;
}

export interface LinkDetails {
  linkTo: string;
  wordSliceStart?: number;
  wordSliceEnd?: number;
}

function getHintMessageDetails({ message, linkDetails }: Hint): {
  // If there is a link in the hint, it can either replace the hint message or
  // split the message into one (if the link is at the start or end of the string)
  // or two parts (if it is in the middle)
  linkMessage?: string;
  messagePartOne?: string;
  messagePartTwo?: string;
} {
  const space = ' ';

  if (!linkDetails) {
    return {
      messagePartOne: message
    };
  }

  const wordSplit = message.split(space);
  const splitStart = linkDetails.wordSliceStart ?? 0;
  const splitEnd = linkDetails.wordSliceEnd ?? wordSplit.length;

  // Start and end are out of bounds, return the entire message as a link
  if (splitStart > wordSplit.length - 1 || splitEnd <= splitStart || (splitStart === 0 && splitEnd === wordSplit.length)) {
    return {
      linkMessage: message
    };
  }

  const linkMessage = wordSplit.slice(splitStart, splitEnd).join(space);
  const messagePartOne = splitStart > 0 ? wordSplit.slice(0, splitStart).join(space) : undefined;
  const messagePartTwo = splitEnd < wordSplit.length ? wordSplit.slice(splitEnd, wordSplit.length).join(space) : undefined;

  return {
    linkMessage: linkMessage,
    messagePartOne: messagePartOne,
    messagePartTwo: messagePartTwo
  };
}

function renderHint({ hint }: ErrorMessage, classes: ClassNameMap, boldLeadingPeriod: boolean): JSX.Element | null {
  if (!hint) {
    return null;
  }

  const { linkMessage, messagePartOne, messagePartTwo } = getHintMessageDetails(hint);

  return (
    <>
      <Typography variant={boldLeadingPeriod ? 'body2_bold' : 'body2'}>.&nbsp;</Typography>
      {messagePartOne && (
        <Typography className={classes.hint} variant="body2">
          {messagePartOne}&nbsp;
        </Typography>
      )}
      {linkMessage && (
        <Link href={hint.linkDetails!.linkTo} target="_blank" rel="noopener noreferrer" underline="always" className={classes.hintLink} variant="body2_bold">
          {linkMessage}
        </Link>
      )}
      {messagePartTwo && (
        <Typography className={classes.hint} variant="body2">
          &nbsp;{messagePartTwo}
        </Typography>
      )}
      <Typography className={classes.hint} variant={messagePartTwo ? 'body2' : 'body2_bold'}>
        .
      </Typography>
    </>
  );
}

function SignError({ id, errorMessages }: Props) {
  const classes = useStyles();

  const singleMessage = errorMessages?.length === 1;

  return errorMessages && errorMessages.length ? (
    <div id={id} className={classes.root}>
      <FlexLayout alignItems="center">
        <IconErrorCross className={classes.errorIcon} />
        <Typography variant="body2_bold">{singleMessage ? errorMessages[0].message : 'Verification errors:'}</Typography>
        {singleMessage ? renderHint(errorMessages[0], classes, true) : null}
      </FlexLayout>
      {errorMessages.length > 1 && (
        <ul className={classes.errors}>
          {errorMessages.map((item, i) => (
            <li key={i}>
              <FlexLayout flexDirection="row" key={`flex-${i}`}>
                <Typography variant="body2">{item.message}</Typography>
                {renderHint(item, classes, false)}
              </FlexLayout>
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null;
}
export default SignError;
