import * as React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import logo from 'src/assets/logo-sympli.svg';
import UserManagerProvider from 'src/auth/UserManagerProvider';
import environments from 'src/environments';
import { useStyles } from './styles';

const LoggedOut = () => {
  const classes = useStyles();

  const onLogOut = () => {
    // to avoid infinite loop, we need to clean up the staled token in the storage before hard reload
    UserManagerProvider.authClient.clearStorage();
    window.location.href = environments.BASENAME;
  };

  return (
    <FlexLayout justifyContent="space-around" alignItems="center" className={classes.container}>
      <Paper className={classes.paper}>
        <img className={classes.logo} src={logo} alt="logo-sympli" />
        <Typography variant="subtitle1">You are now logged out.</Typography>
        <Typography className={classes.description}>If you wish to log in again, please click on the button below.</Typography>
        <SympliButton onClick={onLogOut} variant="contained" color="primary" arrowRight>
          Login
        </SympliButton>
      </Paper>
    </FlexLayout>
  );
};

export default React.memo(LoggedOut);
