import React, { useCallback, useState } from 'react';

import { FormikProps, FormikValues } from 'formik';
import Typography from '@mui/material/Typography';

import { UserRoleEnum } from '@sympli/api-gateway/enums';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import Formik from 'src/components/formik';
import DetailForm from 'src/containers/settings/components/detail-form';
import SettingSectionTopAlert from 'src/containers/settings/components/setting-section-top-alert';
import ProfileImage from 'src/containers/shared/profile-image';
import { ProfileImageModel } from 'src/containers/shared/profile-image/models';
import { AppEnvironmentEnum } from 'src/environments/models';
import { modelKey } from 'src/utils/formUtils';
import http from 'src/utils/http';
import { useSupportedTimeZoneOptions } from 'src/utils/timezone/timezoneHelper';
import SubheadingWithStatus from '../../../users-setting/components/subheading-with-status';
import { getValidationSchema } from '../../validationSchema';
import { UserDetailsFormModel } from '../models';
import ConfirmNameChangeDialog from './ConfirmNameChangeDialog';
import PersonalDetailsDialog from './PersonalDetailsDialog';
import { useStyles } from './styles';

export interface Props {
  initialValues: Partial<UserDetailsFormModel>;
  routeUserId: string;
  onCancel(): void;
  isEditMode: boolean;
  editUserEmailEnabled: boolean;
  loggedInUserIsSuperAdmin: boolean;
  onPostSubmit(args: FormikPostSubmitArgs<UserDetailsFormModel>): void;
  wrapSaveButton?(button: JSX.Element, position: 'top' | 'bottom'): JSX.Element;

  environmentName: AppEnvironmentEnum;
}

interface InitialState {
  showDialog?: boolean;
  showEditDialog?: boolean;
  dialogOnCloseHandler?: (confirmed: boolean) => void;
}

const fieldName = modelKey<UserDetailsFormModel>();

export default React.memo(function PersonalDetailsFormik({
  isEditMode,
  environmentName,
  initialValues,
  routeUserId,
  onCancel,
  onPostSubmit,
  wrapSaveButton,
  editUserEmailEnabled,
  loggedInUserIsSuperAdmin
}: Props): JSX.Element {
  const classes = useStyles();
  const [confirmationDialogValues, setConfirmationDialogValues] = useState<InitialState>({ showDialog: false });
  const [confirmNameChangeDialogValues, setConfirmNameChangeDialogValues] = useState<InitialState>({ showEditDialog: false });
  const timeZoneOptions = useSupportedTimeZoneOptions(initialValues.timezone);

  const renderForm = useCallback(
    ({ isSubmitting, dirty, isValid, setFieldValue, submitForm, values }: FormikProps<UserDetailsFormModel>) => {
      // Email can also be changed when creating new users
      const canEditEmail = !isEditMode || (editUserEmailEnabled && !values.isSubscriberSSOEnabled && loggedInUserIsSuperAdmin && values?.role !== UserRoleEnum.SuperAdmin);

      return (
        <>
          <DetailForm //
            isValid={isValid}
            dirty={dirty}
            isSubmitting={isSubmitting}
            onSave={submitForm}
            onCancel={onCancel}
            wrapSaveButton={wrapSaveButton}
          >
            {isEditMode && <SubheadingWithStatus userId={values.id} />}
            {!isEditMode && (
              <SettingSectionTopAlert
                title="Before you start"
                description=" Please ensure the full name (including any middle names) of this user account is the same as the user's digital certificate. This is important for the digital
      certificate to validate against the user signature"
              />
            )}
            {!values.isActivated && isEditMode && (
              <div className={classes.alertContainer} role="alert">
                <Typography variant="subtitle1" className={classes.title}>
                  This user account is not activated yet
                </Typography>
                <FlexLayout justifyContent="space-between">
                  <Typography className={classes.description}>
                    We've sent an email to this user with instructions on how to activate their account. Would you like to resend this email?
                  </Typography>
                  <SympliButton color="primary" variant="contained" className={classes.submitButton} onClick={() => resendActivationEmailHandler(values.id)} arrowRight>
                    Resend email
                  </SympliButton>
                </FlexLayout>
              </div>
            )}
            <FormGroup title="Personal details" className={classes.bottomLineNone}>
              <Field component={InputField} label="Job title (optional)" name={fieldName('jobTitle')} fullWidth readOnly={isEditMode} />
              <Field component={InputField} label="First name" name={fieldName('firstName')} fullWidth readOnly={values.isSubscriberSSOEnabled} />
              <Field component={InputField} label="Middle name (if applicable)" name={fieldName('middleName')} fullWidth readOnly={values.isSubscriberSSOEnabled} />
              <Field component={InputField} label="Last name" name={fieldName('lastName')} fullWidth readOnly={values.isSubscriberSSOEnabled} />
              <Field component={InputField} label="Email" name={fieldName('email')} fullWidth readOnly={!canEditEmail} />
            </FormGroup>
            <Typography variant="subtitle1" className={classes.bottomLine}>
              User profile preferences
            </Typography>
            <FormGroup title="Contact details (optional)" fieldAreaJustify="flex-start" classes={{ contentContainer: classes.phoneFormGroupContentContainer }}>
              <Field
                component={InputField}
                className={classes.flexGrow}
                label="Mobile"
                name={fieldName('mobileNumber')}
                placeholder="Optional"
                readOnly={values.isSubscriberSSOEnabled}
              />
              <Field
                component={InputField}
                className={classes.flexGrow}
                label="Work phone"
                name={fieldName('work')}
                placeholder="Optional"
                readOnly={values.isSubscriberSSOEnabled}
              />
            </FormGroup>
            <FormGroup title="Preferences">
              <Field
                component={SelectField}
                options={timeZoneOptions}
                placeholder="Please select"
                label="Local time zone"
                name={fieldName('timezone')}
                fullWidth
                readOnly={values.isSubscriberSSOEnabled}
              />
            </FormGroup>
            <ProfileImage
              avatarUrl={values.avatarUrl}
              onDeleteImage={() => {
                setFieldValue(fieldName('avatarUrl'), null);
                setFieldValue(fieldName('profileImage'), null);
              }}
              onImageUploaded={(profileImage: ProfileImageModel) => setFieldValue(fieldName('profileImage'), profileImage)}
            />
          </DetailForm>
          {confirmationDialogValues.showDialog && (
            <PersonalDetailsDialog
              open={confirmationDialogValues.showDialog}
              onClose={confirmationDialogValues.dialogOnCloseHandler}
              values={values}
              environmentName={environmentName}
            />
          )}
          {confirmNameChangeDialogValues.showEditDialog && (
            <ConfirmNameChangeDialog open={confirmNameChangeDialogValues.showEditDialog} onClose={confirmNameChangeDialogValues.dialogOnCloseHandler} />
          )}
        </>
      );
    },

    [
      classes,
      confirmationDialogValues,
      environmentName,
      confirmNameChangeDialogValues,
      isEditMode,
      onCancel,
      wrapSaveButton,
      editUserEmailEnabled,
      loggedInUserIsSuperAdmin,
      timeZoneOptions
    ]
  );

  const handleOnPreSubmit = (values: UserDetailsFormModel): FormikValues => {
    if (!isEditMode) {
      return new Promise((resolve, reject) => {
        const dialogOnCloseHandler = (confirmed: boolean) => {
          // explicitly reject returned promise so we don't continue with submit flow
          confirmed ? resolve(values) : reject();
          setConfirmationDialogValues({ showDialog: false, showEditDialog: false, dialogOnCloseHandler: undefined });
        };
        setConfirmationDialogValues({ showDialog: true, showEditDialog: false, dialogOnCloseHandler });
      });
    }
    // if any kind of name has been changed, a dialog should be shown to the user alerting that all signed docs will be unsigned
    else if (values.profile?.role === UserRoleEnum.SuperAdmin || values.profile?.role === UserRoleEnum.Admin) {
      if (values.firstName !== initialValues.firstName || values.middleName !== initialValues.middleName || values.lastName !== initialValues.lastName) {
        return new Promise((resolve, reject) => {
          const dialogOnCloseHandler = (confirmed: boolean) => {
            // explicitly reject returned promise so we don't continue with submit flow
            confirmed ? resolve(values) : reject();
            setConfirmNameChangeDialogValues({ showDialog: false, showEditDialog: false, dialogOnCloseHandler: undefined });
          };
          setConfirmNameChangeDialogValues({ showDialog: false, showEditDialog: true, dialogOnCloseHandler });
        });
      }
    }

    return values;
  };

  const resendActivationEmailHandler = (userId: string) => {
    http.post<string, string>(`/settings/users/${encodeURIComponent(userId)}/activationEmail`, userId);
  };

  return (
    <Formik //
      method={isEditMode ? 'put' : 'post'}
      initialValues={initialValues}
      action={isEditMode ? `/settings/users/${encodeURIComponent(routeUserId)}` : '/settings/users'}
      onPostSubmit={onPostSubmit}
      validateOnMount={true}
      validationSchema={getValidationSchema(editUserEmailEnabled, initialValues.email)}
      onPreSubmit={handleOnPreSubmit}
      enableReinitialize
    >
      {(formikProps: FormikProps<UserDetailsFormModel>) => renderForm(formikProps)}
    </Formik>
  );
});
