import * as React from 'react';
import { useCallback } from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { ADDRESS_BOOK_KEY, Form24B_2_24_1Model, TransferorAddressTypeEnum, TransferorsAddressOption } from '../../models';
import { Context } from '../../RootForm';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;

function TransferorsAddress() {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const context = useRootFormContext<Context>();
  const { disabled, dialogPortalId } = useDocumentContext();

  const fieldName = modelKey<FormValues>();
  const setAddressBook = useCallback((value: AddressBookEntityModel[]) => setFieldValue(fieldName(ADDRESS_BOOK_KEY), value), [setFieldValue, fieldName]);

  const resolveItemLabel = (item: TransferorsAddressOption) => {
    const addressOptionFieldName = createModelKeyAppender<TransferorsAddressOption>(`[${item.id}]`);
    return (
      <>
        <Typography>{item.key?.toString()}</Typography>
        {item.isSelected && item.key === TransferorAddressTypeEnum.HasAKnownAddress && (
          <ArrowBox backgroundColor="darkGrey" className={classes.arrowBox}>
            <AddressSelectField
              name={addressOptionFieldName('addressBookId')}
              label="Transferor's address after settlement"
              disabled={disabled}
              book={values[ADDRESS_BOOK_KEY]}
              setBookValue={setAddressBook}
              dialogPortalId={dialogPortalId}
              sharedAddressFormConfig={context.addressFormConfig}
              sharedInitialValuesForNew={context.addressFormInitialValues}
            />
          </ArrowBox>
        )}
      </>
    );
  };

  return (
    <Section title="Transferor's address">
      <FormGroup title="Address details" description="All future correspondence will be sent to this address" fieldAreaDirection="column">
        <ReadOnlyField //
          label="Transferor's address?"
          value=""
        />
        <GenericItemSelection //
          name={fieldName('transferorsAddressOptions')}
          mode="single"
          disabled={disabled}
          resolveItemLabel={resolveItemLabel}
          className={classes.itemSelection}
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(TransferorsAddress);
