import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import { batch } from 'react-redux';
import { Action } from 'redux';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { GetLandRegistryInformationResupplyCostApiResponse, ResupplyLandRegistryInformationApiResponse } from '@sympli/api-gateway/models';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { currency } from '@sympli/ui-framework/utils/formatters';
import msg from '@sympli/ui-framework/utils/messages';

import Formik from 'src/components/formik';
import Line from 'src/components/loaders/line-loader';
import {
  actionFetchWorkspaceDocuments,
  actionUpdateWorkspaceTitleLandRegistryDetail,
  UpdateWorkspaceTitleLandRegistryDetailRequestModel
} from 'src/containers/workspace/shared/detail/actions';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { titleAddressLine } from 'src/utils/formatters';
import { ApiStatus } from 'src/utils/http';
import { actionFetchTitleResupplyCost } from '../../actions';
import IconSendApplication from './icons/send-application';
import styles, { ClassKeys } from './styles';

interface TitleReferenceResupplyModel {
  titleReference: string;
}

interface OwnProps {
  workspaceId: string;
  participantId: string;
  ris: {
    reference: string;
    propertyAddress?: ResupplyLandRegistryInformationApiResponse['propertyAddress'];
  };
  open: boolean;
  onClose: () => void;
  status: ApiStatus;
  detail?: GetLandRegistryInformationResupplyCostApiResponse;
  error?: string;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class OrderTitleConfirmationDialog extends React.PureComponent<Props> {
  componentDidUpdate(preProps: Props) {
    const { open, workspaceId, participantId, detail, dispatch } = this.props;
    if (preProps.open !== open && open === true && detail == null) {
      dispatch(actionFetchTitleResupplyCost.request({ workspaceId, participantId }));
    }
  }

  render() {
    const { classes, status, open } = this.props;
    const title = 'Order title information resupply';
    return (
      <ConfirmationDialog
        ariaLabel={title}
        disabled={status === 'pending'}
        open={open}
        showActionButtons={false}
        onClose={this.props.onClose}
        maxWidth={false}
        classes={{ dialogContent: classes.dialogContent }}
      >
        <FlexLayout flexDirection="row">
          <div className={classes.dialogInfoArea}>
            <IconSendApplication />
            <Typography variant="h2" className={classes.dialogTitle}>
              {title}
            </Typography>
            {this.renderPriceDescription()}
            <Typography className={classes.dialogMessage}>Title information changes will be automatically updated on all relevant documents.</Typography>
            <Typography className={classes.dialogMessage}>Any approved or signed documents will be affected.</Typography>
          </div>
          {this.renderOrderResupply()}
        </FlexLayout>
      </ConfirmationDialog>
    );
  }

  private renderPriceDescription() {
    let { classes, status, detail, error } = this.props;

    if (status === 'pending') {
      return <Line color="white" variant="large" widthPercent={100} className={classes.loader} />;
    }

    if (status === 'rejected') {
      return error;
    }

    if (!detail) {
      return null;
    }

    return detail.hasError ? (
      <Typography className={classes.dialogSubTitle} variant="h2">
        {detail.message}
      </Typography>
    ) : (
      <Typography className={classes.dialogSubTitle} variant="h2">
        {detail.totalCost > 0 ? `This will incur a ${currency(detail.totalCost)} fee per title` : detail.message}
      </Typography>
    );
  }

  private getValidationSchema = () =>
    yup.object<TitleReferenceResupplyModel>({
      titleReference: yup.string().required(msg.REQUIRED)
    });

  private renderOrderResupply() {
    const { classes, workspaceId, participantId } = this.props;
    // MR - TODO reuse endpoint svc
    const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/title-references/resupply`;

    return (
      <FlexLayout flexDirection="column" className={classes.paymentArea}>
        {this.renderPaymentSummary()}
        <Formik //
          method="post"
          action={action}
          validationSchema={this.getValidationSchema}
          getInitialValues={() => ({ titleReference: this.props.ris.reference })}
          onPostSubmit={this.handleOnPostSubmit}
        >
          {(formikProps: FormikProps<TitleReferenceResupplyModel>) => (
            <Form>
              <SympliButton //
                classes={{ root: classes.orderNowButton }}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                arrowRight
                isLoading={formikProps.isSubmitting}
                disabled={formikProps.isSubmitting}
              >
                Ok, order now
              </SympliButton>
            </Form>
          )}
        </Formik>
      </FlexLayout>
    );
  }

  private renderPaymentSummary = () => {
    const { classes, detail, ris } = this.props;
    if (detail?.totalCost) {
      return (
        <FlexLayout flexDirection="column">
          <Typography variant="h2" className={classes.dialogTitle}>
            Payments summary
          </Typography>
          <Typography className={classes.dialogSubTitle}>Amount to be debited:</Typography>
          {this.renderCostDetail()}
        </FlexLayout>
      );
    }

    return (
      <Checkbox
        className={classes.titleWithAddress}
        disabled
        label={
          <>
            {ris.reference}
            <br />
            {titleAddressLine(ris.propertyAddress)}
          </>
        }
        checked
      />
    );
  };

  private renderCostDetail = () => {
    const { classes, ris, status, detail, error } = this.props;

    if (status === 'pending' || status === 'idle' || status === 'refetching') {
      return <BlockLoader />;
    }

    if (status === 'rejected') {
      return error;
    }

    if (detail == null) {
      return null;
    }

    return (
      <>
        <FlexLayout justifyContent="space-between" className={classes.paymentAreaDetailLine}>
          <Typography className={classes.paymentAreaCostDetail}>
            Title information resupply <span>&#215;</span> 1
            <br />
            {ris.reference}
          </Typography>
          <Typography className={classes.paymentAreaCostDetail}>{currency(detail.totalCost)}</Typography>
        </FlexLayout>
        <FlexLayout justifyContent="space-between">
          <Typography className={classNames(classes.paymentAreaCostDetail, classes.paymentAreaCostTotal)}>Total:</Typography>
          <Typography className={classNames(classes.paymentAreaCostDetail, classes.paymentAreaCostTotal)}>{currency(detail.totalCost)}</Typography>
        </FlexLayout>
        <Typography className={classNames(classes.paymentAreaCostDetail, classes.paymentAreaCostGst)}>Inclusive of {currency(detail.gst)} GST</Typography>
      </>
    );
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<TitleReferenceResupplyModel, ResupplyLandRegistryInformationApiResponse>) => {
    if (!args.error) {
      const { ris, workspaceId, participantId, dispatch, onClose } = this.props;
      const payload: UpdateWorkspaceTitleLandRegistryDetailRequestModel = {
        reference: ris.reference,
        workspaceId,
        landRegistryDetail: args.response
      };

      batch(() => {
        dispatch(actionUpdateWorkspaceTitleLandRegistryDetail(payload));
        // WEB-20889 - refresh documents list
        dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
      });

      onClose();
    }
  };
}

const StyledComponent = withStyles(styles)(OrderTitleConfirmationDialog);

export default StyledComponent;
