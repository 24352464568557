import * as React from 'react';

import classNames from 'classnames';
import dateFormat from 'dateformat';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconCheck } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import Tooltip from 'src/components/tooltip';
import { DateFormatEnum } from '../../../models';
import { DateCellBaseModel, DateCellStatusEnum } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  key: string;
  dateDetail: DateCellBaseModel;
  item?: any;
  value?: number;
  status: DateCellStatusEnum;
  isSelectedDate: boolean;
  onCellClick?: (event: React.MouseEvent<HTMLTableRowElement>, item: any, date: string) => void;
  settlementAcceptedByAll?: boolean;
  isEditing?: boolean;
  isUnsupported?: boolean;
}

interface OtherProps {
  onClick?: (event: any) => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class DateCell extends React.PureComponent<Props> {
  private toolTipClasses = { tooltip: this.props.classes.toolTip };
  render() {
    const { classes, dateDetail, onCellClick, status, isSelectedDate, settlementAcceptedByAll, isEditing, isUnsupported } = this.props;

    const invalidCellStyle =
      (!settlementAcceptedByAll && !isSelectedDate && !isEditing) || //
      dateDetail.isPast ||
      !!dateDetail.holidayName ||
      isUnsupported ||
      dateDetail.isOverOneYear;

    const containerClassName = classNames(
      classes.container,
      !isUnsupported && classes[status],
      { [classes.invalid]: invalidCellStyle },
      isSelectedDate && classes.selected && !isUnsupported
    );

    const canSelect = onCellClick && !dateDetail.isPast && !dateDetail.holidayName && !isUnsupported && !dateDetail.isOverOneYear;

    const rootClassName = classNames(classes.root, canSelect && classes.withSelect);
    const formattedDate = dateFormat(dateDetail.date, DateFormatEnum.DATE);

    const otherProps: OtherProps = {
      onClick: canSelect ? this.handleCellClick : undefined
    };

    const cellJSX = (
      <div className={rootClassName} data-date={formattedDate} {...otherProps}>
        <div className={classNames(containerClassName)}>
          <p className={classNames(classes.date, dateDetail.isToday && classes.today)}>{dateDetail.date.getDate()}</p>
          {isSelectedDate && this.renderCheck()}
        </div>
      </div>
    );

    if (dateDetail.holidayName) {
      const title = (
        <>
          <p>
            <strong>Public holiday</strong>
          </p>
          <p>{dateDetail.holidayName}</p>
        </>
      );
      return this.renderCellWithToolTip(cellJSX, title);
    }

    if (isUnsupported) {
      const title = (
        <p>
          <strong>Unavailable</strong>
        </p>
      );
      return this.renderCellWithToolTip(cellJSX, title);
    }

    return cellJSX;
  }

  private renderCellWithToolTip(cellJSX: JSX.Element, title: React.ReactNode) {
    return (
      <Tooltip title={title} placement="bottom" classes={this.toolTipClasses}>
        {cellJSX}
      </Tooltip>
    );
  }

  private renderCheck() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.bag, classes.unsupported)}>
        <IconCheck className={classes.icon} />
      </div>
    );
  }

  private handleCellClick = event => {
    if (this.props.onCellClick) {
      const date = dataAttribute('date', event) || '';
      const item = this.props.item;
      this.props.onCellClick(event, item, date);
    }
  };
}

export default withStyles(styles)(DateCell);
