import * as React from 'react';

import { useFormikContext } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field/CheckboxField';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field/InputField';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import LandTitle from '../../components/land-title';
import {
  BOOLEAN_OPTIONS,
  CurrentLandUseDataModel,
  Form24B_2_24_1Model,
  InclusionDataModel,
  PropertyDataModel,
  SafetySwitchDataModel,
  SmokeAlarmDataModel,
  TitleReferenceModel
} from '../../models';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;

function PropertyData() {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<FormValues>();
  const rootFieldName = modelKey<FormValues>();
  const propertyFieldName = createModelKeyAppender<PropertyDataModel>(rootFieldName('property'));

  const renderInclusion = () => {
    const fieldName = createModelKeyAppender<InclusionDataModel>(propertyFieldName('inclusion'));

    return (
      <FormGroup title="Property transferred includes" className={classes.container}>
        <Field //
          name={fieldName('plant')}
          component={CheckboxField}
          label="Plant & machinery"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('chattels')}
          component={CheckboxField}
          label="Moveable chattels"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('livestock')}
          component={CheckboxField}
          label="Livestock"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('waterLicence')}
          component={CheckboxField}
          label="Water licence"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('crops')}
          component={CheckboxField}
          label="Crops"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('waterAllocation')}
          component={CheckboxField}
          label="Interim water allocation"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('existingRight')}
          component={CheckboxField}
          label="Existing right"
          className={classes.option}
          disabled={disabled}
        />

        <Field //
          name={fieldName('other')}
          component={InputField}
          label="Other"
          fullWidth
          disabled={disabled}
        />
      </FormGroup>
    );
  };

  const renderCurrentLandUse = () => {
    const fieldName = createModelKeyAppender<CurrentLandUseDataModel>(propertyFieldName('currentLandUse'));
    const errorMessage = getFormikError(formikProps, propertyFieldName('currentLandUse'), false);

    return (
      <FormGroup //
        title="Current land use"
        className={classes.container}
      >
        {errorMessage && (
          <FormHelperText //
            role="alert"
            error
            data-error-name={propertyFieldName('currentLandUse')}
            className={classes.errorMessage}
          >
            {errorMessage}
          </FormHelperText>
        )}
        <Field //
          name={fieldName('vacantLand')}
          component={CheckboxField}
          label="Vacant land"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('guestHousePrivateHotel')}
          component={CheckboxField}
          label="Guest house or private hotel"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('dwelling')}
          component={CheckboxField}
          label="Dwelling"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('farming')}
          component={CheckboxField}
          label="Farming"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('flats')}
          component={CheckboxField}
          label="Flats"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('industrial')}
          component={CheckboxField}
          label="Industrial"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('multiUnit')}
          component={CheckboxField}
          label="Multi-unit"
          className={classes.option}
          disabled={disabled}
        />
        <Field //
          name={fieldName('commercial')}
          component={CheckboxField}
          label="Commercial"
          className={classes.option}
          disabled={disabled}
        />

        <Field //
          name={fieldName('other')}
          component={InputField}
          label="Other"
          fullWidth
          disabled={disabled}
        />
      </FormGroup>
    );
  };

  const renderSafetySwitch = () => {
    const fieldName = createModelKeyAppender<SafetySwitchDataModel>(propertyFieldName('safetySwitch'));

    return (
      <FormGroup title="Safety switch" fieldAreaDirection="column">
        <Field //
          label="Is an electrical safety switch installed?"
          name={fieldName('installed')}
          component={RadioField}
          disabled={disabled}
          format="string"
          options={BOOLEAN_OPTIONS}
          autoFocusOption="controlled"
          className={classes.radio}
        />
        <Field //
          label="Has transferee been informed in writing about its existence?"
          name={fieldName('informed')}
          component={RadioField}
          disabled={disabled}
          format="string"
          options={BOOLEAN_OPTIONS}
          autoFocusOption="controlled"
          className={classes.radio}
        />
      </FormGroup>
    );
  };

  const renderSmokeAlarm = () => {
    const fieldName = createModelKeyAppender<SmokeAlarmDataModel>(propertyFieldName('smokeAlarm'));

    return (
      <FormGroup title="Smoke alarm" fieldAreaDirection="column">
        <Field //
          label="Is a compliant smoke alarm/s installed?"
          name={fieldName('installed')}
          component={RadioField}
          disabled={disabled}
          format="string"
          options={BOOLEAN_OPTIONS}
          autoFocusOption="controlled"
          className={classes.radio}
        />
        <Field //
          label="Has transferee been informed in writing about its existence?"
          name={fieldName('informed')}
          component={RadioField}
          disabled={disabled}
          format="string"
          options={BOOLEAN_OPTIONS}
          autoFocusOption="controlled"
          className={classes.radio}
        />
      </FormGroup>
    );
  };

  const renderLandTitleItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<TitleReferenceModel>) => {
    return <LandTitle title={item} name={itemBinding} />;
  };

  const renderLandTitles = () => {
    return (
      <DocumentFieldArray //
        arrayBinding={rootFieldName('titleReferences')}
        renderItem={renderLandTitleItem}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="none"
        mode="fixed"
      />
    );
  };

  return (
    <Section title="Property details">
      {renderLandTitles()}
      {renderInclusion()}
      {renderCurrentLandUse()}
      {renderSafetySwitch()}
      {renderSmokeAlarm()}
    </Section>
  );
}

export default React.memo(PropertyData);
