import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: { padding: `15px 20px 5px 20px` },
    issuingPartyDirection: {
      width: 'auto'
    }
  }),
  {
    name: 'IssuingPartyDirection'
  }
);
