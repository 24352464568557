import * as React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { modelKey } from 'src/utils/formUtils';
import { FORM_25_COUNTRY_CODE_OPTIONS } from '../../../enums';
import { Form25_2_24_1Model } from '../../../models';

const fieldName = modelKey<Form25_2_24_1Model>();

function Individual() {
  const { disabled } = useDocumentContext();
  return (
    <FormGroup title="Foreign country">
      <Field //
        name={fieldName('countryCode')}
        label="Foreign type"
        component={SelectField}
        disabled={disabled}
        options={FORM_25_COUNTRY_CODE_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
        fullWidth
      />
    </FormGroup>
  );
}

export default React.memo(Individual);
