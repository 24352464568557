import { Action, createReducer } from 'typesafe-actions';

import { actionCloseGlobalSimpleNotification, actionOpenGlobalSimpleNotification } from './actions';
import { SimpleSnackbarNotificationProps } from './simple-snackbar-notification';

export type GlobalSimpleNotificationState = SimpleSnackbarNotificationProps;

const initialState: GlobalSimpleNotificationState = {
  open: false,
  autoHideDuration: 3000,
  message: ''
};

const globalSimpleNotification = createReducer<
  //
  GlobalSimpleNotificationState,
  Action
>(initialState)
  .handleAction(
    actionOpenGlobalSimpleNotification,
    (state, action): GlobalSimpleNotificationState => ({
      ...state,
      ...action.payload,
      open: true
    })
  )
  .handleAction(actionCloseGlobalSimpleNotification, (): GlobalSimpleNotificationState => ({ ...initialState }));

export default globalSimpleNotification;
