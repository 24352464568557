import React, { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';

import { ApplicantCapacityEnum } from '../../enums';
import { isDisabledGroup, updateProprietorWithCapacity, visibleEvidenceWhenLastDeceasedChanged } from '../../helpers';
import { DeceasedProprietorModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import DeceasedProprietor from '../deceased-proprietor/DeceasedProprietor';
import { useStyles } from './styles';

interface Props {
  name: string;
  className?: string;
  hasMultipleParties: boolean;
  numberOfPartiesInPreceedingGroups: number;
  isSoleProprietor: boolean;
  haveOrgPartyInGroup: boolean;
}

function DeceasedProprietorReadonlyArray({ name, className, hasMultipleParties, numberOfPartiesInPreceedingGroups, isSoleProprietor, haveOrgPartyInGroup }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const { values, setFieldValue } = formikProps;
  const { applicantCapacity } = values;
  const isSingleEvidence = (applicantCapacity === ApplicantCapacityEnum.Administrator || applicantCapacity === ApplicantCapacityEnum.Executor) && isSoleProprietor;
  const value: DeceasedProprietorModel[] = getIn(values, name);

  const renderItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    return (
      <DeceasedProprietor //
        name={itemBinding}
        isSingleEvidence={!item.isLastDeceased || isSingleEvidence}
      />
    );
  };

  const handleIsAffectedProprietorChange = (_: React.ChangeEvent<HTMLInputElement>, newAreAllAffected: boolean): void => {
    const proprietors: DeceasedProprietorModel[] = getIn(values, name) ?? [];
    const updatedItems: DeceasedProprietorModel[] = proprietors.map(proprietor => {
      const updateProprietor: DeceasedProprietorModel = {
        ...proprietor,
        isLastDeceased: newAreAllAffected,
        isAffectedProprietor: newAreAllAffected
      };

      return updateProprietorWithCapacity(updateProprietor, true, updateProprietor, values.applicantCapacity);
    });

    setFieldValue(name, visibleEvidenceWhenLastDeceasedChanged(updatedItems));
  };

  const renderPartyLabel = (item: DeceasedProprietorModel, itemIndex: number): string => {
    if (isSoleProprietor) return `Party ${numberOfPartiesInPreceedingGroups + itemIndex + 1}`;

    return `Party ${numberOfPartiesInPreceedingGroups + itemIndex + 1} ${
      value.some(s => s.isLastDeceased) && item.isAffectedProprietor ? (item.isLastDeceased ? '(recently deceased)' : '(pre-deceased)') : ''
    }`;
  };

  const renderItemTitle = ({ itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    const label = renderPartyLabel(item, itemIndex);
    const disabledCheckbox = disabled || isDisabledGroup(formikProps.values.deceasedProprietorGroups, item.isAffectedProprietor) || haveOrgPartyInGroup;
    return (
      <>
        {hasMultipleParties ? (
          <FormControlLabel
            className={classes.radioButtonRoot}
            classes={{ label: classes.radioButtonLabel }}
            key={item.deceasedProprietorId}
            value={item.deceasedProprietorId}
            control={<Radio color="primary" aria-label="Last Deceased Party" />}
            label={label}
            onChange={handleOnRadioButtonChange}
            checked={item.isLastDeceased}
            disabled={disabledCheckbox}
          />
        ) : (
          <Checkbox
            aria-labelledby="Is Affected Proprietor"
            label={label}
            name={'isAffectedProprietor'}
            format="boolean"
            className={classes.checkboxLabelRoot}
            checked={item.isAffectedProprietor}
            onChange={handleIsAffectedProprietorChange}
            disabled={disabledCheckbox}
            data-testid={label}
          />
        )}
      </>
    );
  };

  const handleOnRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const proprietors: DeceasedProprietorModel[] = getIn(values, name) ?? [];
      //The idea here is that when the user clicks the radio to change the Last Deceased Party,
      //we will exchange the DateOfDeath and Evidences model between the two Proprietors.
      //The other parties in group have not changed.
      const lastDeceasedProprietor: DeceasedProprietorModel | undefined = proprietors.filter(x => x.isLastDeceased)[0] ?? proprietors.filter(x => x.isLastDeceasedBefore)[0];
      const swapDeceasedProprietor = proprietors.filter(x => x.deceasedProprietorId === event.target.value)[0];
      const jointTenantCheckboxValue = proprietors.every(item => item.isAffectedProprietor);
      let properietorWithCapacity;
      const updatedItems = proprietors.map(proprietor => {
        const isLastDeceased = proprietor.deceasedProprietorId! === event.target.value;

        let newGroup: DeceasedProprietorModel = {
          ...proprietor,
          isLastDeceased: isLastDeceased,
          isLastDeceasedBefore: isLastDeceased
        };

        if (isLastDeceased) {
          properietorWithCapacity = updateProprietorWithCapacity(newGroup, isLastDeceased, lastDeceasedProprietor ?? newGroup, values.applicantCapacity);
        } else if (proprietor.deceasedProprietorId === lastDeceasedProprietor?.deceasedProprietorId) {
          properietorWithCapacity = updateProprietorWithCapacity(newGroup, isLastDeceased, swapDeceasedProprietor ?? newGroup, values.applicantCapacity);
        } else {
          properietorWithCapacity = updateProprietorWithCapacity(newGroup, isLastDeceased, proprietor, values.applicantCapacity);
        }

        return jointTenantCheckboxValue ? properietorWithCapacity : { ...properietorWithCapacity, isAffectedProprietor: true };
      });
      setFieldValue(name, visibleEvidenceWhenLastDeceasedChanged(updatedItems));
    },
    [name, setFieldValue, values]
  );

  return (
    <div data-section={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={renderItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(DeceasedProprietorReadonlyArray);
