import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { CtControllerPartyModel } from '../../models';

interface Props {
  name: string;
  value: CtControllerPartyModel;
}

function SectionNominationDetails({ name, value }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<CtControllerPartyModel>(name);

  return (
    <Section title="Nomination details">
      <FormGroup title="CT controller">
        <TextOnly name={fieldName('legalEntityName')} value={value.legalEntityName} fullWidth />
      </FormGroup>
    </Section>
  );
}

export default SectionNominationDetails;
