import React, { ChangeEvent, useEffect, useState } from 'react';

import classNames from 'classnames';
import { format, isValid, parse } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import isBefore from 'date-fns/isBefore';
import isToday from 'date-fns/isToday';
import _uniqueId from 'lodash-es/uniqueId';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import FormControl from '@mui/material/FormControl';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import Popover from '@mui/material/Popover';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconArrowDown, IconArrowLeft, IconArrowRight, IconArrowUp } from '@sympli/ui-framework/icons';

import { DATE_PICKER_MAX_DATE, DATE_PICKER_MIN_DATE, DateTimePickerEnum, dateTimePickerTabOptions } from './models';
import {
  useButtonBaseStyleOverrides,
  useDateCalendarStyleOverrides,
  useFormControlStyleOverrides,
  useInputLabelStyleOverrides,
  usePopoverStyleOverrides,
  useTabsStyleOverrides
} from './styles';

export interface ResolveDisplayedValueProps {
  value: Array<string>;
  placeholder: string;
}

export interface Props {
  value: Array<string>;
  resolveDisplayedValue?(props: ResolveDisplayedValueProps): string | JSX.Element;
  label: string;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  name?: string;
  handleOnSettlementDateTimeFilterChange: (newValues: string[], name?: string) => void;
  defaultOpen?: boolean; // this let parent component to take control
}

function MultipleTabsDateTimePicker({
  //
  label,
  value,
  placeholder = 'Select',
  className,
  style,
  disabled,
  name,
  // setDateTime,
  handleOnSettlementDateTimeFilterChange,
  defaultOpen
}: Props) {
  const [tab, setTab] = useState<DateTimePickerEnum>(DateTimePickerEnum.SingleDay); // default to SingleDate
  const [singleDate, setSingleDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startDateTouched, setStartDateTouched] = useState<boolean>(false);
  const [endDateTouched, setEndDateTouched] = useState<boolean>(false);
  const [startDateErrorMessage, setStartDateErrorMessage] = useState<string | null>(null);
  const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | null>(null);

  const [hasError, setHasError] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(Boolean(defaultOpen));

  const anchorEl = React.useRef<HTMLDivElement>(null);

  const formControlOverridesClasses = useFormControlStyleOverrides();
  const inputLabelOverridesClasses = useInputLabelStyleOverrides();
  const tabsOverridesClasses = useTabsStyleOverrides();
  const buttonBaseOverridesClasses = useButtonBaseStyleOverrides();
  const popoverOverridesClasses = usePopoverStyleOverrides();
  const dateCalendarOverridesClasses = useDateCalendarStyleOverrides();

  const menuId = _uniqueId(`multiple_tab_date_time_picker`);
  const id = open ? menuId : undefined;

  useEffect(() => {
    // All Dates
    if (value.length === 0) {
      if (tab !== DateTimePickerEnum.AllDates) {
        setTab(DateTimePickerEnum.AllDates);
      }
      return;
    }

    // Single Date
    if (value.length === 1) {
      if (tab !== DateTimePickerEnum.SingleDay) {
        setTab(DateTimePickerEnum.SingleDay);
      }
      setSingleDate(value[0]);
      return;
    }

    // Date Range
    if (tab !== DateTimePickerEnum.DateRange) {
      setTab(DateTimePickerEnum.DateRange);
    }
    setStartDate(value[0]);
    setEndDate(value[1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]); // value is the source of truth which tab is now active

  useEffect(() => {
    if (startDate === '' || endDate === '' || startDateErrorMessage || endDateErrorMessage) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [startDate, endDate, startDateErrorMessage, endDateErrorMessage]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const numberOfDays = (start: string, end: string): number => {
    const startDate = parse(start, 'dd/MM/yyyy', new Date());
    const endDate = parse(end, 'dd/MM/yyyy', new Date());
    const diff = differenceInDays(endDate, startDate) + 1;
    return diff;
  };

  const clearDateRangeValues = () => {
    setStartDateErrorMessage(null);
    setEndDateErrorMessage(null);
    setStartDate('');
    setEndDate('');
    setStartDateTouched(false);
    setEndDateTouched(false);
    handleOnSettlementDateTimeFilterChange([], name);
    handleClose();
  };

  const renderDates = (tab: DateTimePickerEnum) => {
    switch (tab) {
      case DateTimePickerEnum.SingleDay:
        return (
          <>
            <DateCalendar
              value={singleDate !== '' ? parse(singleDate, 'dd/MM/yyyy', new Date()) : null}
              onChange={newValue => {
                if (newValue) {
                  setSingleDate(format(newValue!, 'dd/MM/yyyy'));
                  handleOnSettlementDateTimeFilterChange([format(newValue!, 'dd/MM/yyyy')], name);
                  handleClose();
                }
              }}
              slots={{
                leftArrowIcon: IconArrowLeft,
                rightArrowIcon: IconArrowRight
              }}
              slotProps={{
                day: { classes: { root: dateCalendarOverridesClasses.day, today: dateCalendarOverridesClasses.today } },
                calendarHeader: { classes: { root: dateCalendarOverridesClasses.yearMonth, label: dateCalendarOverridesClasses.yearMonthLabel } }
              }}
              showDaysOutsideCurrentMonth
              dayOfWeekFormatter={(_day: string, date: Date) => format(date, 'EEEEEE').toUpperCase()}
              classes={{ root: dateCalendarOverridesClasses.dateCalendarRoot }}
            />
            <FlexLayout className={dateCalendarOverridesClasses.clearButtonWrapper}>
              <Button
                variant="outlined"
                onClick={() => {
                  setSingleDate('');
                  handleOnSettlementDateTimeFilterChange([], name);
                  handleClose();
                }}
                className={classNames(dateCalendarOverridesClasses.clearButton, dateCalendarOverridesClasses.singleDayClearButton)}
              >
                Clear
              </Button>
            </FlexLayout>
          </>
        );
      case DateTimePickerEnum.DateRange:
        const inputProps: InputBaseComponentProps = {
          placeholder: 'dd/mm/yyyy',
          spellCheck: false, // stop browser to do spellcheck, otherwise red underline will be displayed for YYYY part
          style: {
            // https://mui.com/x/react-date-pickers/adapters-locale/#set-a-custom-locale
            // MUI will normally display DD/MM/YYYY as a default value when user clicks into the DatePicker input.
            // we want to display it the same way as placeholder
            textTransform: 'lowercase'
          },
          classes: {
            root: dateCalendarOverridesClasses.dateTimeInput
          }
        };

        return (
          <FlexLayout flexDirection="column" alignItems="center" justifyContent="center" className={dateCalendarOverridesClasses.dateRangeContainer}>
            <DatePicker
              label="Start Date"
              value={parse(startDate, 'dd/MM/yyyy', new Date())}
              onChange={newValue => {
                if (!startDateTouched) {
                  setStartDateTouched(true);
                }
                if (newValue && isValid(newValue)) {
                  setStartDate(format(newValue, 'dd/MM/yyyy'));
                  setStartDateErrorMessage(null);
                } else {
                  setStartDateErrorMessage('You have entered an invalid date');
                }
              }}
              className={classNames(
                dateCalendarOverridesClasses.dateStartPicker,
                dateCalendarOverridesClasses.datePicker,
                startDateTouched && dateCalendarOverridesClasses.datePickerTouched
              )}
              format="dd/MM/yyyy"
              minDate={DATE_PICKER_MIN_DATE}
              maxDate={DATE_PICKER_MAX_DATE}
              showDaysOutsideCurrentMonth
              dayOfWeekFormatter={(_day: string, date: Date) => format(date, 'EEEEEE').toUpperCase()}
              slots={{
                textField: TextField,
                leftArrowIcon: IconArrowLeft,
                rightArrowIcon: IconArrowRight
              }}
              slotProps={{
                textField: {
                  inputProps: {
                    ...inputProps,
                    // we want to set touched here because as soon as user starts to type, we need to update CSS styles
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      if (!startDateTouched) {
                        setStartDateTouched(true);
                      }
                      if (isValid(parse(event.target.value, 'dd/MM/yyyy', new Date()))) {
                        setStartDateErrorMessage(null);
                      }
                    }
                  },
                  error: startDateTouched && Boolean(startDateErrorMessage),
                  helperText: startDateErrorMessage
                },
                day: { classes: { root: dateCalendarOverridesClasses.day, today: dateCalendarOverridesClasses.today } },
                calendarHeader: { classes: { root: dateCalendarOverridesClasses.yearMonth, label: dateCalendarOverridesClasses.yearMonthLabel } },
                popper: { className: dateCalendarOverridesClasses.datePickerPopper }
              }}
            />
            <DatePicker
              label="End Date"
              value={parse(endDate, 'dd/MM/yyyy', new Date())}
              onChange={newValue => {
                if (!endDateTouched) {
                  setEndDateTouched(true);
                }
                if (newValue && isValid(newValue)) {
                  setEndDate(format(newValue, 'dd/MM/yyyy'));
                  setEndDateErrorMessage(null);
                } else {
                  setEndDateErrorMessage('You have entered an invalid date');
                }
              }}
              className={classNames(
                dateCalendarOverridesClasses.dateEndPicker,
                dateCalendarOverridesClasses.datePicker,
                endDateTouched && dateCalendarOverridesClasses.datePickerTouched
              )}
              minDate={DATE_PICKER_MIN_DATE}
              maxDate={DATE_PICKER_MAX_DATE}
              showDaysOutsideCurrentMonth
              dayOfWeekFormatter={(_day: string, date: Date) => format(date, 'EEEEEE').toUpperCase()}
              format="dd/MM/yyyy"
              slots={{
                textField: TextField,
                leftArrowIcon: IconArrowLeft,
                rightArrowIcon: IconArrowRight
              }}
              slotProps={{
                textField: {
                  inputProps: {
                    ...inputProps,
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      // we want to set touched here because as soon as user starts to type, we need to update CSS styles
                      if (!endDateTouched) {
                        setEndDateTouched(true);
                      }
                      if (isValid(parse(event.target.value, 'dd/MM/yyyy', new Date()))) {
                        setEndDateErrorMessage(null);
                      }
                    }
                  },
                  error: endDateTouched && Boolean(endDateErrorMessage),
                  helperText: endDateErrorMessage
                },
                day: { classes: { root: dateCalendarOverridesClasses.day, today: dateCalendarOverridesClasses.today } },
                calendarHeader: { classes: { root: dateCalendarOverridesClasses.yearMonth, label: dateCalendarOverridesClasses.yearMonthLabel } },
                popper: { className: dateCalendarOverridesClasses.datePickerPopper }
              }}
            />
            <FlexLayout justifyContent="space-between" className={dateCalendarOverridesClasses.buttons}>
              <FlexLayout flexDirection="row" justifyContent="flex-end">
                <Button variant="outlined" onClick={clearDateRangeValues} className={dateCalendarOverridesClasses.clearButton}>
                  Clear
                </Button>
                <ButtonBase
                  onClick={() => {
                    if (isBefore(parse(startDate, 'dd/MM/yyyy', new Date()), parse(endDate, 'dd/MM/yyyy', new Date()))) {
                      handleOnSettlementDateTimeFilterChange([startDate, endDate], name);
                      setStartDateErrorMessage(null);
                      setEndDateErrorMessage(null);
                      handleClose();
                    } else {
                      setEndDateErrorMessage('You have entered an invalid date range');
                    }
                  }}
                  className={dateCalendarOverridesClasses.applyButton}
                  disabled={hasError}
                >
                  Apply
                </ButtonBase>
              </FlexLayout>
              {startDate && endDate && startDate !== endDate && numberOfDays(startDate, endDate) >= 1 && (
                <Typography className={classNames(dateCalendarOverridesClasses.selectedDaysWrapper)}>
                  {numberOfDays(startDate, endDate)} <span className={classNames(dateCalendarOverridesClasses.selectedDaysDescription)}>days selected</span>
                </Typography>
              )}
            </FlexLayout>
          </FlexLayout>
        );

      case DateTimePickerEnum.AllDates:
        return (
          <FlexLayout flexDirection="column" alignItems="center" justifyContent="center">
            <ButtonBase
              onClick={() => {
                // setDateTime([]);
                handleOnSettlementDateTimeFilterChange([], name);
                handleClose();
              }}
              className={dateCalendarOverridesClasses.selectAllDateButton}
            >
              {' '}
              Select all dates
            </ButtonBase>
          </FlexLayout>
        );
      default:
        return null;
    }
  };

  const resolveDateString = () => {
    if ((tab === DateTimePickerEnum.AllDates || !open) && value.length === 0) {
      return 'All Dates';
    }
    if ((tab === DateTimePickerEnum.SingleDay || !open) && value.length === 1) {
      if (value[0] === '') {
        return 'Select';
      } else if (value[0] === 'AllDates') {
        return 'All Dates';
      }

      if (isToday(parse(value[0], 'dd/MM/yyyy', new Date()))) {
        return 'Today';
      } else {
        return format(parse(value[0], 'dd/MM/yyyy', new Date()), 'dd MMM yy');
      }
    }

    if ((tab === DateTimePickerEnum.DateRange || !open) && value.length === 2) {
      if (value[0] === '' || value[1] === '') {
        return 'Select';
      }

      const startDate = parse(value[0], 'dd/MM/yyyy', new Date());
      const endDate = parse(value[1], 'dd/MM/yyyy', new Date());

      const days = differenceInDays(endDate, startDate) + 1;
      return `${days} days`;
    }
    return 'Select';
  };

  return (
    <>
      <FormControl //
        disabled={disabled}
        data-name={name}
        className={classNames(!import.meta.env.PROD && 'devHelper', className, open && formControlOverridesClasses.popOpen)}
        classes={{ root: formControlOverridesClasses.root }}
        style={style}
        variant="outlined"
        ref={anchorEl}
      >
        <Typography classes={inputLabelOverridesClasses}>{label}</Typography>
        <ButtonBase /*aria-owns={anchorEl ? menuId : undefined} aria-haspopup="true"*/ disableRipple onClick={handleClick} classes={buttonBaseOverridesClasses}>
          <Typography classes={{ root: buttonBaseOverridesClasses.text }}>{resolveDateString()}</Typography>
          {open ? <IconArrowUp width={24} height={24} /> : <IconArrowDown width={24} height={24} />}
        </ButtonBase>
      </FormControl>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{
          root: popoverOverridesClasses.root,
          paper: popoverOverridesClasses.paper
        }}
      >
        <Tabs
          value={tab}
          aria-label="new date time picker"
          classes={{
            root: tabsOverridesClasses.root,
            flexContainer: tabsOverridesClasses.flexContainer,
            indicator: tabsOverridesClasses.indicator,
            scroller: tabsOverridesClasses.scroller
          }}
        >
          {dateTimePickerTabOptions.map(option => (
            <Tab
              key={option.id}
              className={tab === option.id ? tabsOverridesClasses.tabSelected : tabsOverridesClasses.tab}
              label={option.name}
              value={option.id}
              onClick={() => setTab(option.id)}
              classes={{ root: tabsOverridesClasses.tabRoot }}
            />
          ))}
        </Tabs>
        {renderDates(tab)}
      </Popover>
    </>
  );
}

export default React.memo(MultipleTabsDateTimePicker);
