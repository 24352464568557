import * as yup from 'yup';

import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS, IssuingPartyDirectionEnum } from '../enums';

const yupIssuingPartyDirection = yup //
  .mixed<IssuingPartyDirectionEnum>()
  .nullable()
  .required(msg.REQUIRED)
  .oneOf(getLookupValuesAllowingEmpty(ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS), msg.INVALID_SELECTION);

export default yupIssuingPartyDirection;
