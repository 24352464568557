import * as React from 'react';

import { dateTimeLine } from 'src/utils/formatters';
import { useProfile } from '../reducers';
import ProfileContext from './Profile.context';

export const getCurrentTime = () => {
  const now = new Date(); // .toLocaleString('en-AU');
  return dateTimeLine(now, 'HH:mm:ss.L');
};

interface Props {}

function ProfileContextProvider({ children }: React.PropsWithChildren<Props>) {
  const profile = useProfile().data;
  // console.log('%cProfile.context.Provider - render (OUTSIDE of the context.PROVIDER), %s', logStyles, getCurrentTime());
  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
}

export default React.memo(ProfileContextProvider);
