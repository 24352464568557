import { $get, $noneOf, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { TransactionInstruments$DocumentTypeEnum } from './enums';
import { TransactionInstrumentModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - transactionInstruments[*].dealingNumber

 schema version:
{
  "documentType": {
    "$noneOf": [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      13
    ]
  }
}
*/
export const IS_REQUIRED_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER = createContextCheck((context: TransactionInstrumentModel): boolean =>
  $noneOf($get(context, 'documentType'), [
    TransactionInstruments$DocumentTypeEnum.Transfer,
    TransactionInstruments$DocumentTypeEnum.Mortgage,
    TransactionInstruments$DocumentTypeEnum.Lease,
    TransactionInstruments$DocumentTypeEnum.NoticeOfDeath,
    TransactionInstruments$DocumentTypeEnum.TransmissionApplicationDeviseeBeneficiaryNextOfKin,
    TransactionInstruments$DocumentTypeEnum.TransmissionApplicationExecutorAdministratorTrustee,
    TransactionInstruments$DocumentTypeEnum.ApplicationToRecordNewRegisteredProprietor,
    TransactionInstruments$DocumentTypeEnum.ChangeOfName,
    TransactionInstruments$DocumentTypeEnum.Charge,
    TransactionInstruments$DocumentTypeEnum.Request
  ])
);
