import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import sharedStyles from '@sympli/ui-framework/components/form/base-components/shared-styles';

const styles = (theme: Theme) => ({
  ...sharedStyles(theme),
  ...createStyles({
    root: {},
    checkboxComponentRoot: {
      display: 'flex'
    },
    marginBottom: {
      marginBottom: 14
    },

    fieldLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: 4
    },
    tree: {
      fontSize: 13,
      margin: 0,
      padding: 0,
      '& *': {
        marginBottom: 0
      },
      '& li': {
        listStyleType: 'none'
      },
      '& > li:first-child': {
        marginLeft: 0,
        marginTop: 4
      },
      '& > li:first-child::before': {
        display: 'none'
      },
      '& > li:first-child::after': {
        borderRadius: '5px 0 0 0'
      },
      '& ul': {
        paddingLeft: 0,
        marginLeft: 30
      }
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
