import { Form, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';

import { ParticipantStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field/CheckboxField';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import Formik from 'src/components/formik';
import { useSafeDispatch } from 'src/hooks';
import { modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import WorkspaceFileField from '../workspace-file-field';
import { WorkspaceQueryParams } from '../workspace-file-uploader/models';
import { actionFetchWorkspaceFiles } from '../workspace-files-container/actions';
import { WorkspaceFile } from '../workspace-files/models';
import { FILE_DESCRIPTION_LOOKUP_OPTIONS, IS_SHARED_OPTIONS } from './enums';
import { WorkspaceFileUploadModel } from './models';
import NotesField from './NotesField';
import { useStyles } from './styles';
import validationSchema from './validationSchema';

export interface OwnProps {
  workspaceId: string;
  participantId: string;
  participants: WorkspaceParticipantApiResponse[];
  editFile: WorkspaceFile | null;
  openDialog: boolean;
  error?: string;
  disabled?: boolean;
  workspaceType?: WorkspaceTypeEnum;
  isWorkspaceInteroperable?: boolean;
  onClose: () => void;
}

const fieldName = modelKey<WorkspaceFileUploadModel>();

export default function WorkspaceFileUploadDialog(props: OwnProps) {
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { openDialog, onClose, editFile, workspaceId, participantId, participants, disabled, workspaceType } = props;

  const getInitialValues = (): WorkspaceFileUploadModel => {
    if (editFile) {
      return {
        id: editFile.id,
        description: editFile.description,
        notes: editFile.notes,
        isShared: Boolean(editFile.sharedWith?.length) || Boolean(editFile.sharedWithInvitationIds?.length) || editFile.isForFutureParticipants,
        sharedWithParticipantIds: editFile.sharedWith,
        isForFutureParticipants: editFile.isForFutureParticipants,
        sharedWithInvitationIds: editFile.sharedWithInvitationIds
      };
    }
    // new file
    return {
      isShared: workspaceType === WorkspaceTypeEnum.FinancialSettlement,
      isForFutureParticipants: participants.length === 1,
      files: []
    };
  };

  const renderDialogContent = () => {
    const action = `/files/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}`;
    return (
      <Formik //
        getInitialValues={getInitialValues}
        method="post"
        action={action}
        validationSchema={validationSchema}
        validateOnMount={true}
        onPreSubmit={onPreSubmit}
        onPostSubmit={onPostSubmit}
      >
        {(formikProps: FormikProps<WorkspaceFileUploadModel>) => renderForm(formikProps)}
      </Formik>
    );
  };

  const onPostSubmit = (args: FormikPostSubmitArgs<WorkspaceFileUploadModel>) => {
    if (args.error) {
      return;
    }
    onClose();
    // Refresh uploaded files list
    dispatch(actionFetchWorkspaceFiles.request({ workspaceId, participantId }));
  };

  const onPreSubmit = (values: WorkspaceFileUploadModel) => {
    // before submit, remove shared with participant ids based on isShared radio button
    return {
      ...values,
      sharedWithParticipantIds: values.isShared ? values.sharedWithParticipantIds : null,
      isForFutureParticipants: values.isShared ? values.isForFutureParticipants : null,
      sharedWithInvitationIds: values.isShared ? values.sharedWithInvitationIds : null
    };
  };

  const renderForm = (formikProps: FormikProps<WorkspaceFileUploadModel>) => {
    const {
      isValid,
      isSubmitting,
      dirty,
      values: { isShared, id, description }
    } = formikProps;
    const isEditMode = !!id; // true indicates an existing file is being updated
    const isSaveDisabled = isSubmitting || !isValid || !dirty || disabled;
    const queryParams: WorkspaceQueryParams = { workspaceId, participantId };

    // include all the pending and accepted participants and the with the extra for future participant option
    const PARTICIPANT_OPTIONS: LookupEnumModel<string>[] = participants //
      .filter(p => p.id !== participantId && p.participantStatus.id === ParticipantStatusEnum.Accepted)
      .map(item => ({ id: item.id, name: item.name }));

    const INVITATION_OPTIONS: LookupEnumModel<string>[] = participants //
      .filter(p => p.participantStatus.id === ParticipantStatusEnum.Pending)
      .map(item => ({ id: item.id, name: item.name }));

    const resolveParticipantLabel = (item: any) => {
      const participant = participants.find(p => p.id === item.id);

      return (
        <>
          <div className={classes.participantName}>{participant!.name}</div>
          <div className={classes.participantRole}>{participant!.workspaceRole.name}</div>
        </>
      );
    };

    return (
      <Form>
        <FlexLayout alignItems="flex-start">
          <FlexLayout className={classes.leftPanel} flexDirection="column">
            {!isEditMode ? (
              <Field //
                label="File description"
                name={fieldName('description')}
                component={SelectField}
                placeholder={resolveSelectPlaceholder(true)}
                options={FILE_DESCRIPTION_LOOKUP_OPTIONS}
                format="string"
                fullWidth
              />
            ) : (
              <ReadOnlyField label="File description" value={description!} />
            )}

            <NotesField //
              name={fieldName('notes')}
            />
            {!isEditMode && (
              <WorkspaceFileField //
                name={fieldName('files')}
                titleLabel="Upload file"
                queryParams={queryParams}
              />
            )}
          </FlexLayout>
          <FlexLayout className={classes.rightPanel} flexDirection="column">
            <Field //
              label="Share"
              name={fieldName('isShared')}
              placeholder="Please select"
              component={RadioField}
              format="boolean"
              options={IS_SHARED_OPTIONS}
              fullWidth
              vertical
              disabled={workspaceType === WorkspaceTypeEnum.RegistrationOnly}
              className={classes.shareWith}
            />
            {isShared && (
              <>
                {PARTICIPANT_OPTIONS.length > 0 && (
                  <Field //
                    aria-label="Share with participants"
                    name={fieldName('sharedWithParticipantIds')}
                    component={CheckboxGroupField}
                    format="string"
                    options={PARTICIPANT_OPTIONS}
                    resolveItemLabel={resolveParticipantLabel}
                    fullWidth
                    vertical
                    className={classes.participants}
                  />
                )}
                {INVITATION_OPTIONS.length > 0 && (
                  <Field //
                    aria-label="Share with invitations"
                    name={fieldName('sharedWithInvitationIds')}
                    component={CheckboxGroupField}
                    format="string"
                    options={INVITATION_OPTIONS}
                    resolveItemLabel={resolveParticipantLabel}
                    fullWidth
                    vertical
                    className={PARTICIPANT_OPTIONS.length === 0 ? classes.invitationsOnly : classes.invitations}
                  />
                )}
                <Field //
                  aria-label="Share with future participant"
                  name={fieldName('isForFutureParticipants')}
                  component={CheckboxField}
                  format="boolean"
                  label="Any future participants"
                  className={INVITATION_OPTIONS.length === 0 && PARTICIPANT_OPTIONS.length === 0 ? classes.futureParticipantsOnly : classes.futureParticipants}
                />
              </>
            )}
          </FlexLayout>
        </FlexLayout>
        <WizardStepper //
          className={classes.stepper}
          onBack={onClose}
          nextLabel={editFile ? 'Save' : 'Upload'}
          backLabel="Close"
          showBackIcon
          showNextIcon
          disabled={isSaveDisabled}
          isLoading={isSubmitting}
        />
      </Form>
    );
  };
  return (
    <ConfirmationDialog
      title={editFile ? 'Edit file attributes' : 'Upload file'}
      classes={{ root: classes.root, dialogPaper: classes.dialogPaper, dialogTitle: classes.dialogTitle, dialogContent: classes.dialogContent }}
      open={openDialog}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      <Divider className={classes.divider} />
      {renderDialogContent()}
    </ConfirmationDialog>
  );
}
