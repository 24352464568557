import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    dark: {
      color: 'white'
    }
  },
  {
    name: 'WorkflowPanelIssuesButton'
  }
);
