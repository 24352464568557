import * as yup from 'yup';

import yupMortgagees from './components/mortgagee-party-readonly-array/validationSchema';
import { DischargeOfMortgage_2_26_0_Model } from './models';
import yupMortgages from './sections/mortgages/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupSetOfOperativeWords from './sections/set-of-operative-words/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache

export default yup
  .object<DischargeOfMortgage_2_26_0_Model>({
    workspaceTitleReferences: yup.mixed<string[]>(),
    addressBook: yup.mixed<DischargeOfMortgage_2_26_0_Model['addressBook']>(),
    dealingNumbersSelectedInOtherDms: yup.mixed<DischargeOfMortgage_2_26_0_Model['dealingNumbersSelectedInOtherDms']>(),

    mortgages: yupMortgages,
    setOfOperativeWords: yupSetOfOperativeWords,
    titleReferences: yupTitleReferences,
    mortgagees: yupMortgagees,
    partyBook: yupPartyBook
  })
  .defined()
  .log();
