import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';

export enum PlatformEnum {
  LaunchDarkley = 'LD',
  TickleUI = 'UI'
}

export interface FeatureFlags {
  name: FeatureToggleEnum;
  isControlledBy: PlatformEnum;
  isEnabled: boolean;
}
