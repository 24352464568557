import _get from 'lodash-es/get';
import _uniqueId from 'lodash-es/uniqueId';
import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

const REGEXP_INTEGER = /^\-?(\d|[1-9]\d+)$/;
const REGEXP_NUMBER = /^\-?(\d|[1-9]\d+)(\.\d+)?$/;

function createRegexTest(REGEX: RegExp) {
  return function test(this: yup.TestContext, value: any) {
    // this test will be executed only if it passes the Number type check.
    // since this field is marked as nullable, null is considered as valid value as well.
    // we don't care whether it's required or not here.
    // required rule will be triggered after this test.
    if (this.schema['_nullable'] && value === null) {
      return true;
    }

    let inputValue = _get(this.options.context, this.path);
    inputValue = typeof inputValue === 'string' ? inputValue : '' + inputValue;
    return REGEX.test(inputValue);
  };
}

const defaultNumber = yup //
  .number()
  .nullable(true)
  .default(null)
  .typeError(msg.INVALID_VALUE)
  .transform(function transform(this: any, newValue: any, originalValue: any) {
    // we need to have this transformer in order to transform empty strings to null
    // otherwise we would fail the type check
    if (typeof originalValue === 'string') {
      if (originalValue === '') {
        return null;
      }

      // since number("")  is 0 so trim it
      const trimmedOriginalValue = originalValue.trim();
      if (trimmedOriginalValue !== originalValue || isNaN(Number(trimmedOriginalValue))) {
        return originalValue;
      }
    }

    return newValue;
  });

export function createNumberTypeCheck() {
  return createRegexTest(REGEXP_NUMBER);
}

export function createIntegerTypeCheck() {
  return createRegexTest(REGEXP_INTEGER);
}

export function number() {
  return defaultNumber //
    .clone()
    .test(_uniqueId('Type check test'), msg.INVALID_VALUE, createRegexTest(REGEXP_NUMBER));
}

export function integer() {
  return defaultNumber //
    .clone()
    .integer(msg.INVALID_VALUE)
    .test(_uniqueId('Type check test'), msg.INVALID_VALUE, createRegexTest(REGEXP_INTEGER));
}
