import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { GroupOptionModel } from '../../../shared/models';

export const resolveAssignableGroups = (assignableGroups: GroupOptionModel[], jurisdictionId: JurisdictionsEnum): LookupEnumModel<string>[] => {
  return assignableGroups
    .filter(group => group.jurisdictions.some(({ id }) => id === jurisdictionId))
    .map(group => {
      return { id: group.id, name: group.name };
    });
};

export const getDescriptionLengthText = (messageLength: number, messageMaxLength: number): string => {
  return `${messageLength} / ${messageMaxLength} characters (including spaces and formatting)`;
};
