import { nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-17/components/party-justification';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterMortgagors } from './helper';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE } from './isRequiredChecks';
import { ApiMortgage2_17_1Model, Mortgage2_17_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiMortgage2_17_1Model, context: IConverterContext): Mortgage2_17_1Model {
  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.titleReferences
    .map(t => t.mortgagors!)
    .map(mortgagor => mortgagor.map(party => party.partyBookId))
    .flat();
  let formModel: Mortgage2_17_1Model = {
    ...apiModel,
    mortgagors: filterMortgagors(apiModel),
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
    mortgagees: convertReceivingTenancyDetailFromApiToForm(apiModel.mortgagees)
  };
  formModel = applyDefaultMap(formModel, fallbackMap);

  // WEB-18683: The term number was not filled with the default one.
  // The following logic will check if the standard term dealing number is required or not.
  // If the user has provided one, or it provided the additional term number or uploaded documents, the standard term dealing number is not required.
  // It will fill the standard dealing term number with the default one in the user's setting only when it is empty and required.
  // The empty check is actually a safe guard just in case the require check failed to check the emptiness and overwrite the value already filled by the user.
  const termsAndConditions = formModel.termsAndConditions;
  const firstStandardTermsDealingNumber = termsAndConditions.standardTermsDealingNumbers[0];
  const originalTermNumber = firstStandardTermsDealingNumber.termNumber;
  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(firstStandardTermsDealingNumber, termsAndConditions);

  if (isRequired && !originalTermNumber) {
    firstStandardTermsDealingNumber.termNumber = termsAndConditions.preRegisteredStandardTermsDealingNumbers?.[0] || originalTermNumber;
  }
  return formModel;
}

function convertFromFormToApiModel(formModel: Mortgage2_17_1Model, originalApiModel: ApiMortgage2_17_1Model): ApiMortgage2_17_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    mortgagees: mortgageesFromForm,
    ...rest
  } = formModel;
  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []) as any, // TODO resolve this typing
    mortgagees: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: mortgageesFromForm, partyBookFromForm: partyBookFromForm || [] })
  };
}

class Converter implements IConverter<Mortgage2_17_1Model, ApiMortgage2_17_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
