import { FormikProps, setIn } from 'formik';
import { Action } from 'redux';

import { DirectionTypeEnum } from '@sympli/api-gateway/enums';
import msg from '@sympli/ui-framework/utils/messages';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { BankDetailsModel, BpayDetailsModel, LinkedSettlementItemModel } from 'src/containers/workspace/financial/directions/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { CheckHelper, displayToastMessage, getKeyValueMatchingNumberInObject, saveFSSLineItem } from '../../helper';
import { BankTransferDistribution, BpayDistribution, LinkedDistribution } from '../direction-record/models';

export async function bsbCheckFormikHelper(
  itemFieldName: string,
  bankDetails: BankDetailsModel,
  formikProps: FormikProps<any>,
  workspaceId: string,
  participantId: string,
  dispatch: SafeDispatch<Action>,
  savingFssLineItem?: boolean,
  keepDialogOpen?: boolean,
  distribution?: BankTransferDistribution
) {
  try {
    const response = await CheckHelper.checkBsb(bankDetails.bsb);
    let res: boolean = true;

    // when DM editor wants to keep dialog open, there is no need to fire save endpoint
    if (!keepDialogOpen && savingFssLineItem && distribution) {
      const { paymentMethod, amount, directionCategory, category, categoryOther, id } = distribution;
      res = await saveFSSLineItem(
        {
          workspaceId,
          participantId,
          requestPayload: {
            type: DirectionTypeEnum.Direction,
            direction: {
              paymentMethod,
              amount: Number(amount),
              directionCategory,
              category,
              categoryOther,
              id,
              bankDetails: distribution.bankDetails
            }
          }
        },
        dispatch,
        getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) === 1
      );
      displayToastMessage(res, dispatch);
    }

    const fieldName = createModelKeyAppender<BankTransferDistribution>(itemFieldName);
    const bankDetailsFieldName = createModelKeyAppender<BankDetailsModel>(fieldName('bankDetails'));

    const { setValues } = formikProps;

    let newValues = formikProps.values;
    newValues = setIn(newValues, bankDetailsFieldName('bankName'), response.name);
    if (!keepDialogOpen && res) {
      // when api call is failure, we don't want to close the editor
      newValues = setIn(newValues, fieldName('isEditorOpen'), false);
    }

    setValues(newValues);
    return response;
  } catch (error) {
    const scope = Logger.scopeWithCustomAttributes({ bsbNumber: bankDetails.bsb });
    Logger.captureException(error, scope);
  }
}

export async function bpayCheckFormikHelper(
  workspaceId: string,
  participantId: string,
  itemFieldName: string,
  bpayDetails: BpayDetailsModel,
  amount: string | number,
  formikProps: FormikProps<any>,
  dispatch: SafeDispatch<Action>,
  savingFssLineItem?: boolean,
  distribution?: BpayDistribution
) {
  const { billerCode, billerReference } = bpayDetails;
  try {
    const response = await CheckHelper.checkBillerCode(workspaceId, billerCode);

    const fieldName = createModelKeyAppender<BpayDistribution>(itemFieldName);
    const bpayDetailsFieldName = createModelKeyAppender<BpayDetailsModel>(fieldName('bpayDetails'));
    const { crnPrefix, checkDigitRule, minAmount = 0.01, maxAmount = 1000000, minCrnLength = 2, maxCrnLength = 20 } = response;
    const billerReferenceErr = getBpayCrnErrorMessage(billerReference, crnPrefix, checkDigitRule, minCrnLength, maxCrnLength);
    const amountErrorMessage = getAmountErrorMessage(amount, minAmount, maxAmount);

    let res: boolean = true;

    if (!billerReferenceErr && !amountErrorMessage) {
      if (savingFssLineItem && distribution) {
        const { paymentMethod, bpayDetails, amount, directionCategory, category, categoryOther, id } = distribution;
        res = await saveFSSLineItem(
          {
            workspaceId,
            participantId,
            requestPayload: {
              type: DirectionTypeEnum.Direction,
              direction: {
                paymentMethod,
                amount: Number(amount),
                directionCategory,
                category,
                categoryOther,
                id,
                bpayDetails
              }
            }
          },
          dispatch,
          getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) === 1
        );

        displayToastMessage(res, dispatch);
      }

      const { setValues } = formikProps;
      let newValues = formikProps.values;
      newValues = setIn(newValues, bpayDetailsFieldName('billerName'), response.billerLongName);
      if (res) {
        // when api call is failure, we don't want to close the editor
        newValues = setIn(newValues, fieldName('isEditorOpen'), false);
      }

      setValues(newValues);
    } else {
      // TODO formik should get this error from validation schema
      const scope = Logger.scopeWithCustomAttributes({ workspaceId, bpayDetails, response, billerReferenceErr, amountErrorMessage });
      Logger.captureException(new BusinessLogicError('Error in bpay validation'), scope);
    }
  } catch (error) {
    const scope = Logger.scopeWithCustomAttributes({ workspaceId, billerCode });
    Logger.captureException(error, scope);
  }
}

export async function linkedCheckFormikHelper(
  workspaceId: string,
  participantId: string,
  itemFieldName: string,
  formikProps: FormikProps<any>,
  dispatch: SafeDispatch<Action>,
  savingFssLineItem?: boolean,
  distribution?: LinkedDistribution
) {
  let res: boolean = true;
  const fieldName = createModelKeyAppender<LinkedDistribution>(itemFieldName);
  const linkedSettlementItemFieldName = createModelKeyAppender<LinkedSettlementItemModel>(fieldName('linkedSettlementItem'));

  const existingLink: LinkedSettlementItemModel | undefined = distribution?.linkedSettlementItem
    ? {
        linkedParticipantId: distribution.linkedSettlementItem?.linkedToParticipantId,
        linkedWorkspaceId: distribution.linkedSettlementItem?.linkedToWorkspaceId,
        linkedToParticipantMatterReference: distribution.linkedSettlementItem?.linkedToParticipantMatter
      }
    : undefined;
  if (savingFssLineItem && distribution) {
    const { amount, paymentMethod, linkedDetails, directionCategory, category, categoryOther, id } = distribution;
    res = await saveFSSLineItem(
      {
        workspaceId,
        participantId,
        requestPayload: {
          type: DirectionTypeEnum.Direction,
          direction: {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id,
            linkedSettlementItem: id ? existingLink : linkedDetails
          }
        }
      },
      dispatch,
      getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) === 1,
      distribution.linkedDetails?.linkedToParticipantMatterReference
    );
  }
  const { setValues } = formikProps;
  let newValues = formikProps.values;
  newValues = setIn(newValues, linkedSettlementItemFieldName('reference'), distribution?.reference);
  if (res) {
    // when api call is failure, we don't want to close the editor
    newValues = setIn(newValues, fieldName('isEditorOpen'), false);
  }

  setValues(newValues);
}

// crn === billerReference
export function getBpayCrnErrorMessage(billerReference: string, crnPrefix: string, checkDigitRule: string, minCrnLength: number, maxCrnLength: number) {
  if (!billerReference) {
    return 'Reference number is required';
  }

  if (billerReference.length < minCrnLength) {
    return `Bpay reference must be at least ${minCrnLength} digits for this biller`;
  } else if (billerReference.length > maxCrnLength) {
    return `Bpay reference number must be at most ${maxCrnLength} digits for this biller`;
  } else if (crnPrefix.length > 0 && !billerReference.startsWith(crnPrefix)) {
    return `Reference number must contain ${crnPrefix} prefix for this biller`;
  }
  return undefined;
}

export function getAmountErrorMessage(amount: string | number, minAmount: number, maxAmount: number) {
  const amountNumber = Number(amount);
  if (isNaN(amountNumber)) {
    return 'Must be a valid number'; // fallback
  } else if (amountNumber < minAmount) {
    return msg.VALUE_MUST_BE_AT_LEAST_X(minAmount);
  } else if (amountNumber > maxAmount) {
    return msg.VALUE_MUST_BE_X_OR_LESS(maxAmount);
  }
  return undefined;
}
