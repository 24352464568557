import React, { useCallback } from 'react';

import { useField, useFormikContext } from 'formik';
import IconButton from '@mui/material/IconButton';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { IconDelete } from '@sympli/ui-framework/icons';

import StandardTermsDealingNumbers from '../../components/standard-terms-dealing-numbers';
import { Mortgage_2_23_0__Model, StandardTermsDealingNumbersModel } from '../../models';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  minItems: number;
  maxItems: number;
}

function StandardTermsDealingNumbersArray({ name, className, minItems, maxItems }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const [, { value }] = useField<StandardTermsDealingNumbersModel[]>(name);
  const {
    values: { termsAndConditions }
  } = useFormikContext<Mortgage_2_23_0__Model>();
  const preRegisteredStandardTermsDealingNumbers = termsAndConditions?.preRegisteredStandardTermsDealingNumbers;

  const createNewItem = useCallback(
    (): StandardTermsDealingNumbersModel => ({
      termNumber: ''
    }),
    []
  );

  const renderItem = useCallback(
    ({ itemIndex, itemCount, itemBinding, handleRemove, removeButtonFocusRef }: DocumentArrayItemRenderProps<StandardTermsDealingNumbersModel>) => {
      const itemValue = value[itemIndex];
      const isFilled = !!itemValue.termNumber?.trim();

      const title = `Document reference ${itemCount > 1 ? itemIndex + 1 : ''}`;

      // we use show the tooltip if the user manually type in the value other than the preRegisteredStandardTermsDealingNumbers
      const showFormTip = preRegisteredStandardTermsDealingNumbers?.length
        ? isFilled && itemValue.termNumber && !preRegisteredStandardTermsDealingNumbers?.includes(itemValue.termNumber)
        : false;

      const formTip = showFormTip
        ? 'This document reference will only be used in this workspace. Please contact your Super Admin to have this added to the default list.'
        : undefined;

      const isShowingRemoveButton = value.length > minItems;
      const canRemove = isShowingRemoveButton && !disabled;

      return (
        <FormGroup //
          key={`${itemBinding}-${itemIndex}`}
          title={title}
          fieldAreaDirection="column"
          formTip={formTip}
          {...(isShowingRemoveButton && {
            iconPrefix: (
              <IconButton color="primary" disabled={!canRemove} onClick={handleRemove} action={removeButtonFocusRef} size="large">
                <IconDelete fill="currentColor" />
              </IconButton>
            )
          })}
        >
          <StandardTermsDealingNumbers name={itemBinding} />
        </FormGroup>
      );
    },
    [value, preRegisteredStandardTermsDealingNumbers, disabled, minItems]
  );

  return (
    <div data-name={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        disabled={disabled}
        itemStyle="none"
        minItems={minItems}
        maxItems={maxItems}
        createNewItem={createNewItem}
        addButtonTitle="Add document reference"
      />
    </div>
  );
}

export default React.memo(StandardTermsDealingNumbersArray);
