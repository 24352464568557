import { LookupItemModel } from '@sympli/ui-framework/models';

export enum EvidenceTypeEnum {
  //NSW
  Probate = 'Probate',
  LettersOfAdministration = 'Letters of Administration',
  DeedOfAppointment = 'Deed of Appointment',
  StatutoryDeclaration = 'Statutory Declaration',
  DeathCertificate = 'Death Certificate',
  CertificateUnderNSWTrusteeAndGuardianAct = 'Certificate under NSW Trustee and Guardian Act',
  //QLD
  QueenslandGrantOfRepresentation = 'Queensland Grant of Representation',
  ExemplificationOfQueenslandGrantOfRepresentation = 'Exemplification of Queensland Grant of Representation',
  QueenslandRecognisedGrant = 'Queensland recognised grant',
  ExemplificationOfQueenslandRecognisedGrant = 'Exemplification of Queensland recognised grant'
}

export const EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupItemModel<EvidenceTypeEnum>[] = Object.values(EvidenceTypeEnum).map(name => ({ id: name, name }));
