import Typography from '@mui/material/Typography';

import Tooltip from 'src/components/tooltip/Tooltip';
import { titleAddress } from 'src/utils/formatters';

interface Props {
  matterReference: string;
  sympliId: string;
  addresses: Array<string>;
}

function LinkedSettlementToParticipantDetail({ matterReference, sympliId, addresses }: Props) {
  const formattedAddresses = addresses.map(address => titleAddress(address)).join(', ');

  return (
    <Typography className="inline-flex pr-[5px]">
      Matter Reference {matterReference} | ID {sympliId} |
      {formattedAddresses && (
        <Tooltip title={formattedAddresses} placement="bottom-end">
          <div className="flex pl-[5px]">
            <div className="truncate w-[200px]">Address {formattedAddresses} </div>
            <span className="font-bold">+{addresses.length}</span>
          </div>
        </Tooltip>
      )}
    </Typography>
  );
}

export default LinkedSettlementToParticipantDetail;
