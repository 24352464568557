import * as yup from 'yup';

import { ConsiderationModel, ConsiderationTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { yupConsiderationAmount, yupConsiderationType } from '@sympli-mfe/document-forms-framework/components/sections/consideration/validation-schema';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { TransferDocumentModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: consideration
const yupConsideration = yup
  .object<ConsiderationModel>({
    type: yupConsiderationType(JurisdictionsEnum.VIC)
      .testContextualRule({
        uniqueTestName: 'Must have multiple transferrors when Change in manner of holding is selected test',
        message: 'Cannot select "change in manner of holding" unless there are multiple transferors.',
        onlyIf: (consideration: ConsiderationModel, context: TransferDocumentModel): boolean => consideration.type === ConsiderationTypeEnum.ChangeInTheMannerOfHolding,
        requirement: (consideration: ConsiderationModel, transferContext: TransferDocumentModel): boolean => {
          const relinquishingProprietorCount = transferContext.transferorDetails.relinquishingProprietorGroups.flatMap(group => group.parties).length;
          return relinquishingProprietorCount > 1;
        }
      })
      .defined(),
    amount: yupConsiderationAmount
  })
  .defined();

export default yupConsideration;
