import React from 'react';

import { useAsyncDialog } from 'src/hooks';
import { defaultValues } from '../components/report-scheduler-dialog/ReportSchedulerDialogContainer';
import { SchedulerFormikModel } from '../models';

export function useSchedulerDialog() {
  const [schedulerInitialValues, setSchedulerInitialValues] = React.useState<SchedulerFormikModel>(defaultValues);

  const { isOpen: isSchedulerDialogOpen, asyncDialogDispatch: asyncDialogSchedulerDispatch } = useAsyncDialog();

  const schedulerDialogCloseHandler = (value?: boolean) => {
    // now close the dialog regardless of value
    asyncDialogSchedulerDispatch({ type: 'close' });
  };

  const schedulerDialogClickHandler = (initialValues?: SchedulerFormikModel) => {
    asyncDialogSchedulerDispatch({ type: 'open' });
    if (initialValues) setSchedulerInitialValues(initialValues);
  };

  return {
    schedulerInitialValues,
    isSchedulerDialogOpen,
    schedulerDialogCloseHandler,
    schedulerDialogClickHandler,
    asyncDialogSchedulerDispatch
  };
}
