import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useCardDetailStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& thead th:not(:last-child)': {
        paddingRight: 30,
        boxSizing: 'content-box'
      },
      '& tbody td:not(:last-child)': {
        paddingRight: 30,
        boxSizing: 'content-box'
      },
      '& .MuiTableCell-head': {
        '&:not(:first-child)': {
          fontSize: 14 //override default styles in CardDetail
        }
      }
    }
  }),
  {
    name: 'InvitationsCardDetail'
  }
);
