import { createAsyncAction } from 'typesafe-actions';

import { StandaloneWorkspacesV2ApiRequest, StandaloneWorkspacesV2ApiResponse } from './models';

export const actionFetchStandaloneWorkspacesFeedV2 = createAsyncAction(
  //
  'FETCH_STANDALONE_WORKSPACES_FEED_V2',
  'FETCH_STANDALONE_WORKSPACES_FEED_V2_SUCCESS',
  'FETCH_STANDALONE_WORKSPACES_FEED_V2_ERROR'
)<
  //
  StandaloneWorkspacesV2ApiRequest,
  { data: StandaloneWorkspacesV2ApiResponse },
  { error: Error }
>();
