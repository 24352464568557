import dateFormat from 'dateformat';
import _uniqBy from 'lodash-es/uniqBy';

import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { getProprietorsFromTitle, populateCaveatModelFromTitleSelected, toPartyReference } from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/helpers';
import { ADDRESS_BOOK_KEY, ApiBaseCaveatModel, BaseCaveatModel, PARTY_BOOK_KEY } from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/models';
import { QLD_CAVEAT_ADDRESS_FORM_CONFIG, QLD_CAVEAT_PARTY_FORM_CONFIG } from './config';
import { caveatFallbackMap } from './fallback';

class Converter implements IConverter<BaseCaveatModel, ApiBaseCaveatModel> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: QLD_CAVEAT_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_CAVEAT_PARTY_FORM_CONFIG
});

export function convertFromApiToFormModel(apiModel: ApiBaseCaveatModel): BaseCaveatModel {
  let formModel: BaseCaveatModel = {
    ...apiModel,
    [PARTY_BOOK_KEY]: convertPartyBookFromApiToFormModel(apiModel[PARTY_BOOK_KEY]),
    [ADDRESS_BOOK_KEY]: convertAddressBookFromApiToFormModel(apiModel[ADDRESS_BOOK_KEY]),
    partyReferences: []
  };
  if (apiModel.invalidParties) {
    formModel = populateCaveatModelFromTitleSelected(formModel, QLD_CAVEAT_PARTY_FORM_CONFIG);
  }
  formModel = applyDefaultMap(formModel, caveatFallbackMap);
  formModel.partyReferences = getPartyReferences(formModel, QLD_CAVEAT_PARTY_FORM_CONFIG);

  return formModel;
}

export function convertFromFormToApiModel(formModel: BaseCaveatModel, originalApiModel: ApiBaseCaveatModel): ApiBaseCaveatModel {
  formModel = applyVisibilityFallbackMap(formModel, caveatFallbackMap);
  const { [PARTY_BOOK_KEY]: formPartyBook, [ADDRESS_BOOK_KEY]: formAddressBook, claimDetails, ...rest } = formModel;
  return {
    ...rest,
    invalidParties: false,
    [PARTY_BOOK_KEY]: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel[PARTY_BOOK_KEY]),
    [ADDRESS_BOOK_KEY]: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel[ADDRESS_BOOK_KEY]),
    claimDetails: {
      ...claimDetails,
      claimDate: claimDetails.claimDate ? dateFormat(claimDetails.claimDate as Date, DateFormatEnum.DATE) : undefined
    }
  };
}

function getPartyReferences(formModel: BaseCaveatModel, partyFormConfig: PartyFormConfig) {
  const proprietors = getProprietorsFromTitle(formModel.titleReferences);
  const proprietorReferences = proprietors.map(p => toPartyReference(formModel[PARTY_BOOK_KEY], p, partyFormConfig));
  const partyReferences = formModel.caveators
    .concat(formModel.proprietors)
    .concat(formModel.mortgagees ?? [])
    .concat(formModel.otherParties.proprietorGroups.flatMap(pg => pg.parties))
    .concat(formModel.prohibition.notApplicableInstruments.flatMap(i => i.receivingParties.concat(i.relinquishingParties)))
    .concat(formModel.claimDetails.claimParties ?? [])
    .concat(proprietorReferences)
    .concat(formModel.partyReferences);

  return _uniqBy<TenancyPartyModel>(partyReferences, p => p.partyBookId).filter(p => !!p.partyBookId);
}

export default new Converter();
