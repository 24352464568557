import { useState } from 'react';

import classNames from 'classnames';
import { titleCase } from 'title-case';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';
import { LineItemsModel } from '../../models';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2082603015/Payment+summary+box
export interface Props {
  lineItems?: LineItemsModel;
  jurisdictionId?: JurisdictionsEnum;
}

const PaymentLineItems = ({ lineItems, jurisdictionId }: Props) => {
  const { total, totalGst, documentFees } = lineItems || {};
  const classes = useStyles();
  const sympliServiceFeeAmount = lineItems?.serviceFees.reduce((pre, cur) => pre + cur.fee.total, 0);
  const documentFeeAmount = documentFees?.reduce((pre, cur) => pre + cur.fee.total, 0);
  const breakdownLineItemsFeatureEnabled: boolean = useFeatureFlag(FeatureToggleEnum.breakdownLineItems);
  const [showBreakdowns, setShowBreakdowns] = useState(false);

  return (
    <div className={classNames(classes.root101, classes.feeItems101)}>
      <FlexLayout className={classes.row101} justifyContent="space-between">
        <div>Sympli (Service fee):</div>
        <div>{currency(sympliServiceFeeAmount ?? 0)}</div>
      </FlexLayout>

      {breakdownLineItemsFeatureEnabled && documentFeeAmount !== 0 && (
        <FlexLayout className={classes.row101} justifyContent="space-between">
          <div>{getConsolidationFeeItemName(jurisdictionId)}:</div>
          <div>
            <ButtonLink onClick={() => setShowBreakdowns(!showBreakdowns)} color="inherit" className={classes.breakdown}>
              {showBreakdowns ? 'Hide' : 'Show'}
            </ButtonLink>
          </div>
          <div>{currency(documentFeeAmount ?? 0)}</div>
        </FlexLayout>
      )}

      {(!breakdownLineItemsFeatureEnabled || showBreakdowns) &&
        documentFees?.map((item, i) => {
          return (
            <FlexLayout
              className={breakdownLineItemsFeatureEnabled ? classNames(classes.breakdown, classes.breakdownItalic) : classes.row101}
              justifyContent="space-between"
              key={i}
            >
              {!breakdownLineItemsFeatureEnabled && <div>{titleCase(item.description)}:</div>}
              {showBreakdowns && <div>&nbsp;- {titleCase(item.description)}</div>}
              <div>{currency(item.fee.total)}</div>
            </FlexLayout>
          );
        })}
      <FlexLayout className={classNames(classes.row101, classes.feeTotal101)} justifyContent="space-between">
        <div>Total:</div>
        <div>{currency(total ?? 0)}</div>
      </FlexLayout>
      <FlexLayout className={classNames(classes.gstInclusive101)} justifyContent="space-between">
        <div>Inclusive of {currency(totalGst ?? 0)} GST</div>
      </FlexLayout>
    </div>
  );
};

function getConsolidationFeeItemName(jurisdiction) {
  const lodgementFeeCategory = 'Lodgement fee';
  switch (jurisdiction) {
    case JurisdictionsEnum.NSW:
      return `NSW LRS (${lodgementFeeCategory})`;
    case JurisdictionsEnum.QLD:
      return `Titles QLD (${lodgementFeeCategory})`;
    case JurisdictionsEnum.VIC:
      return `SER VIC (${lodgementFeeCategory})`;
    case JurisdictionsEnum.SA:
      return `Land Services SA (${lodgementFeeCategory})`;
    case JurisdictionsEnum.WA:
      return `Landgate (${lodgementFeeCategory})`;
    case JurisdictionsEnum.ACT:
      return `Access Canberra ACT (${lodgementFeeCategory})`;
    default:
      return lodgementFeeCategory;
  }
}

export default PaymentLineItems;
