import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    breakdownList: {
      paddingTop: 8
    }
  }),
  {
    name: 'BreakdownList'
  }
);
