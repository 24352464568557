import * as React from 'react';

import { getIn, setIn, useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import Box from '@mui/material/Box';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import { AddressBookEntityModel, AddressTypeEnum } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { LandDescriptionModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import { StateEnum } from '@sympli-mfe/enums-2-24/qld';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';

import { QLD_FORM24B_ADDRESS_FORM_INITIAL_VALUES, QLD_FORM24B_PROPERTY_DETAILS_TITLE_ADDRESS_FORM_CONFIG } from '../../config';
import { ADDRESS_BOOK_KEY, Form24B_2_24_1Model, TitleReferenceModel } from '../../models';
import { Context } from '../../RootForm';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;

interface Props {
  name: string;
  title: TitleReferenceModel;
}

const landDescriptionFieldName = modelKey<LandDescriptionModel>();

function LandTitle({ name, title }: Props) {
  const classes = useStyles();
  const { values, setValues, setFieldTouched } = useFormikContext<FormValues>();
  const { disabled, dialogPortalId } = useDocumentContext();
  const context = useRootFormContext<Context>();
  const fieldName = React.useMemo(() => createModelKeyAppender<TitleReferenceModel>(name), [name]);
  const partLandAffectedFieldName = React.useMemo(() => createModelKeyAppender<PartLandAffectedWithDescriptionsModel>(fieldName('partLandAffected')), [fieldName]);

  const { addressBook } = values;
  const filterPhysicalAddresses = React.useCallback((address: AddressBookEntityModel) => {
    return address?.type === AddressTypeEnum.PhysicalAddress && address?.physicalAddress?.state === StateEnum.QLD;
  }, []);
  const addressBookOptions = useAddressBookOptions(addressBook, context.titleAddressFormConfig, filterPhysicalAddresses);

  React.useEffect(() => {
    let updatedIndexes: number[] = [];
    setValues(prevValues => {
      const ldItems: LandDescriptionModel[] | undefined = getIn(prevValues, partLandAffectedFieldName('landDescriptions'));

      const landDescriptions = ldItems?.map((landDescription, i) => {
        if (addressBookOptions.length > 0 && !addressBookOptions.find(item => item.id === landDescription.addressBookId)) {
          updatedIndexes.push(i);
          return {
            ...landDescription,
            addressBookId: undefined
          };
        }
        return landDescription;
      });

      if (updatedIndexes.length) {
        const updatedValues = setIn(prevValues, partLandAffectedFieldName('landDescriptions'), landDescriptions);
        return {
          ...prevValues,
          ...updatedValues
        };
      }
      return prevValues;
    });

    if (updatedIndexes.length) {
      updatedIndexes.forEach(index => {
        setFieldTouched(`${partLandAffectedFieldName('landDescriptions')}[${index}]${landDescriptionFieldName('addressBookId')}`, true);
      });
    }
  }, [addressBookOptions, partLandAffectedFieldName, setValues, filterPhysicalAddresses, setFieldTouched]);

  const renderItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<LandDescriptionModel>) => {
    const landDescriptionFieldName = createModelKeyAppender<LandDescriptionModel>(itemBinding);
    return (
      <Box className={classes.landDescription}>
        <ReadOnlyField //
          label="Land description"
          value={item.legalDescription || title.partLandAffected?.partDescription || ''}
        />
        <ArrowBox backgroundColor="darkGrey" className={classes.arrowBox}>
          <AddressSelectField
            name={landDescriptionFieldName('addressBookId')}
            label="Property address"
            optionsOverride={addressBookOptions}
            disabled={disabled}
            bookRef={ADDRESS_BOOK_KEY}
            dialogPortalId={dialogPortalId}
            sharedAddressFormConfig={QLD_FORM24B_PROPERTY_DETAILS_TITLE_ADDRESS_FORM_CONFIG}
            sharedInitialValuesForNew={QLD_FORM24B_ADDRESS_FORM_INITIAL_VALUES}
          />
        </ArrowBox>
      </Box>
    );
  };

  return (
    <FormGroup title={title.reference} className={classes.container}>
      <DocumentFieldArray //
        arrayBinding={partLandAffectedFieldName('landDescriptions')}
        renderItem={renderItem}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="none"
        mode="fixed"
      />
    </FormGroup>
  );
}

export default React.memo(LandTitle);
