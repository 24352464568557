import { SA_ADDRESS_FORM_CONFIG as SA_WOC_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-26/config';
import { saNameChangeConversion } from '@sympli-mfe/document-components/party-form/sa/2-26/components/party-justification';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { SA_WOC_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { getCaveatorsForSelectedCaveat, getSelectedCaveat, filterTitleReferences as getTitleReferences, getCaveatsBeingWithdrawn as getWithdrawCaveatsSelection } from './helper';
import { ApiWithdrawalOfCaveat_2_26_0_Model, WithdrawalOfCaveat_2_26_0_Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: SA_WOC_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: SA_WOC_PARTY_FORM_CONFIG,
  nameChangeConversion: saNameChangeConversion
});

class Converter implements IConverter<WithdrawalOfCaveat_2_26_0_Model, ApiWithdrawalOfCaveat_2_26_0_Model> {
  fromApiToFormModel(apiModel: ApiWithdrawalOfCaveat_2_26_0_Model): WithdrawalOfCaveat_2_26_0_Model {
    // Implement any necessary conversion from API to form values
    const { caveats } = apiModel;
    const relinquishingIds = caveats.map(caveat => caveat.caveators.map(caveator => caveator.partyBookId)).flat();

    const titleReferences = getTitleReferences(caveats);
    const withdrawCaveats = getWithdrawCaveatsSelection(caveats, titleReferences);
    let formModel: WithdrawalOfCaveat_2_26_0_Model = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
      addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
      titleReferences: titleReferences,
      withdrawCaveats: withdrawCaveats,
      caveators: getCaveatorsForSelectedCaveat(withdrawCaveats)
    };
    formModel = applyDefaultMap(formModel, fallbackMap as any);

    return formModel;
  }
  fromFormToApiModel(formModel: WithdrawalOfCaveat_2_26_0_Model, originalApiModel: ApiWithdrawalOfCaveat_2_26_0_Model): ApiWithdrawalOfCaveat_2_26_0_Model {
    formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      addressBook: addressBookFromForm,
      titleReferences,
      caveators,
      withdrawCaveats,
      ...rest
    } = formModel;

    const selectedTitles = titleReferences.filter(x => x.isSelected).map(x => x.reference);
    const selectedCaveat = getSelectedCaveat(withdrawCaveats);
    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
      addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
      caveats: formModel.caveats.map(caveat => {
        const isSelected = caveat.dealingNumber === selectedCaveat?.dealingNumber;
        // Updating caveats object with changes made by the user within EditForm
        return {
          ...caveat,
          isSelected: isSelected,
          titleReferences: caveat.titleReferences.map(t => {
            // Updating title reference selection incase it has been updated by the user
            return {
              ...t,
              isSelected: isSelected ? selectedTitles.includes(t.reference) : false
            };
          }),
          caveators: caveat.caveators.map(m => {
            // Updating caveator incase party book has been updated by the user
            if (caveators) {
              return caveators.find(x => x.partyBookId === m.partyBookId) || m;
            }
            return m;
          })
        };
      })
    };
  }
}

export default new Converter();
