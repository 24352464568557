import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& + &': {
        paddingTop: 16
      }
    },
    title: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '22px'
    },
    titleWrapper: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 11,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }),
  {
    name: 'SettingsSection'
  }
);
