import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '8px 16px 10px 16px',
      backgroundColor: theme.palette.secondary.light,
      marginBottom: 5,
      /* this will prevent inheriting opacity from parent */
      position: 'relative'
    },
    heading: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    warningIcon: {
      fill: theme.palette.secondary.main,
      marginRight: 6
    }
  }),
  {
    name: 'SignWarning'
  }
);
