import * as React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { WorkspaceDirectionsOverviewApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { Main } from 'src/components/layout';
import { LineLoader } from 'src/components/loaders';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import useFeatureFlag from 'src/hooks/useFeatureFlag';
import ParticipantDistributions from './components/participant-distributions';
import ParticipantSourceFunds from './components/participant-sourcefunds';
import { EyeToggleButton } from './components/styled-components';
import { useStyles } from './styles';

interface Props {
  // route parameter
  participantId: string;
  // list of current workspace participants
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  // details of all directions
  allDirectionsDetail: WorkspaceDirectionsOverviewApiResponse;
  // other
  isLoading: boolean;
}

function AllDirections({ workspaceParticipants, allDirectionsDetail, participantId, isLoading }: Props) {
  const classes = useStyles();
  const [openAll, setOpenAll] = React.useState(false);
  const breakdownLineItemsFeatureEnabled: boolean = useFeatureFlag(FeatureToggleEnum.breakdownLineItems);

  return (
    <FlexLayout flexDirection="column" fullHeight fullWidth>
      <Main className={classes.flexGrow}>
        <FlexLayout alignItems="baseline">
          <Typography variant="h1" className={classes.title}>
            Financial Settlement Summary
          </Typography>
          {isLoading ? (
            <LineLoader color="grey" variant="large" marginLeft={20} />
          ) : (
            <Typography className={classes.toggleShowHide}>
              <EyeToggleButton
                open={openAll}
                hideText="Hide all line items"
                showText="Show all line items"
                onClick={() => {
                  setOpenAll(!openAll);
                }}
              />{' '}
            </Typography>
          )}
        </FlexLayout>
        <div className={classes.summaryBody}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={260} />
          ) : (
            <ParticipantDistributions //
              allDirectionsDetail={allDirectionsDetail}
              workspaceParticipants={workspaceParticipants}
              currentParticipantId={participantId}
              openAll={openAll}
              breakdownLineItemsFeatureEnabled={breakdownLineItemsFeatureEnabled}
            />
          )}
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={260} />
          ) : (
            <ParticipantSourceFunds //
              allDirectionsDetail={allDirectionsDetail}
              workspaceParticipants={workspaceParticipants}
              currentParticipantId={participantId}
              openAll={openAll}
            />
          )}
        </div>
      </Main>
    </FlexLayout>
  );
}

export default React.memo(AllDirections);
