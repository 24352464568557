import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';

import { ApiWithdrawalOfPriorityNoticeModel, WithdrawalOfPriorityNoticeModel } from '../../../../shared/2-24/withdrawal-of-priority-notice/models';
import RootForm from '../../../../shared/2-24/withdrawal-of-priority-notice/RootForm';
import validationSchema from '../../../../shared/2-24/withdrawal-of-priority-notice/validationSchema';
import converter from './conversion';

export default function DocumentForm(
  //
  props: DocumentFormProps<WithdrawalOfPriorityNoticeModel, ApiWithdrawalOfPriorityNoticeModel>
): JSX.Element {
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validationSchema={validationSchema}
        RootForm={RootForm}
      />
    </DndContextProvider>
  );
}
