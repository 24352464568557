import { createAsyncAction } from 'typesafe-actions';

import { TableFiltersModel } from 'src/models';
import { FinancialAccountApiResponse } from '../financial-accounts/models';

export type DebitAccountApiRequest = TableFiltersModel;

export const actionFetchFinancialAccounts = createAsyncAction(
  //
  'FETCH_FINANCIAL_ACCOUNTS',
  'FETCH_FINANCIAL_ACCOUNTS_SUCCESS',
  'FETCH_FINANCIAL_ACCOUNTS_ERROR'
)<
  //
  undefined,
  { data: FinancialAccountApiResponse[] },
  { error: Error }
>();
