import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    flexLayoutRoot: {
      margin: '1rem',
      width: 315
    },
    itemRoot: {
      padding: 0
    },
    subtitle2: {
      fontWeight: 'bold'
    },
    body1: {
      textDecoration: 'underline'
    }
  }),
  {
    name: 'TeamWorkloadReportAccessOption'
  }
);
