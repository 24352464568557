import { FieldProps, FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { resolveDataBindingReference } from './helpers';

const DEFAULT_SOURCE_DATA = [];

interface Props extends FieldProps {
  // represents a source from which we are getting data
  // if the source has changed we need to reconcile our data as well (e.g. remove or add)
  sourceName: string;
  // unique identifier in the source
  sourceIdentifierName: string;
  // name of property in the destination object under which we store the identifier from the source
  // defaults to sourceIdentifierName
  destinationIdentifierName: string;
}
export class SourceDataReconciler {
  private field: {
    value: any;
    name: string;
  };
  private form: FormikProps<any>;

  private sourceName: string;
  private sourceIdentifierName: string;
  private destinationIdentifierName: string;

  constructor(props?: Props) {
    if (props) {
      Object.assign(this, props);
    }
  }

  public reconcileFieldValues() {
    const {
      field,
      form: { setFieldValue, values },
      sourceName,
      sourceIdentifierName,
      destinationIdentifierName
    } = this;

    const sourceBinding = resolveDataBindingReference(field.name, sourceName);
    const sourceData = _get(values, sourceBinding, DEFAULT_SOURCE_DATA);

    // the source can be typically either object or an array
    const sourceDataType = Object.prototype.toString.call(sourceData);
    // the source id will be assigned under specified property name or if not specified, under the original one.

    if (sourceDataType === '[object Object]') {
      const sourceId = sourceData[sourceIdentifierName];
      if (!(destinationIdentifierName in field.value)) {
        // merge the source id value into our field
        field.value[destinationIdentifierName] = sourceId;
        setFieldValue(field.name, field.value, false);
      }
    } else if (sourceDataType === '[object Array]') {
      // collect source ids
      const sourceIds = sourceData.map(item => item[sourceIdentifierName]);
      let destinationIds = field.value.map(item => item[destinationIdentifierName]);
      if (sourceIds.length !== destinationIds.length || sourceIds.join(',') !== destinationIds.join(',')) {
        let hasChanged = false;
        destinationIds = []; // rebuild destination ids again

        const reconciledFieldValue = field.value.reduce((acc, item, idx) => {
          const destinationId = item[destinationIdentifierName];
          if (destinationId === undefined) {
            // the item has not been reconciled yet
            const sourceId = _get(sourceData, `[${idx}].${sourceIdentifierName}`);
            item[destinationIdentifierName] = sourceId;
            destinationIds.push(sourceId);
            hasChanged = true;
            acc.push(item);
            return acc;
          } else {
            // if the item has been removed from the source,
            // we need to remove it from destination
            if (!sourceIds.includes(destinationId)) {
              // we don't push it into our final result
              // we also remove it from the list of known destinationIds
              destinationIds.splice(idx, 1);
              hasChanged = true;
            } else {
              destinationIds.push(destinationId);
              acc.push(item);
            }
            return acc;
          }
        }, []);

        // if something new has been added to the source
        if (destinationIds.length < sourceIds.length) {
          for (let i = destinationIds.length; i < sourceIds.length; i++) {
            // add new object with source id
            reconciledFieldValue[i] = { [destinationIdentifierName]: _get(sourceData, `[${i}].${sourceIdentifierName}`) };
          }
          hasChanged = true;
        }

        // update field data
        if (hasChanged) {
          field.value = reconciledFieldValue;
          setFieldValue(field.name, reconciledFieldValue, false);
        }
      }
    }
  }
}
