import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import sharedStyles from '../../../edit-directions/styles';

const styles = (theme: Theme) => ({
  ...sharedStyles(theme),
  ...createStyles({
    bold: {
      fontWeight: 'bold'
    },
    italic: {
      fontStyle: 'italic'
    },
    editButton: {
      padding: '0px 0px 0px 10px'
    },
    flexGrow: {
      flexGrow: 1
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
