import React from 'react';

import { Form, FormikProps } from 'formik';
import Typography from '@mui/material/Typography';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconCalendar } from '@sympli/ui-framework/icons';

import Formik from 'src/components/formik';
import { modelKey } from 'src/utils/formUtils';
import { ScheduledFrequencyEnum, SchedulerFormikModel } from '../../models';
import ReportName from './components/report-name';
import ReportSchedulerFrequency from './components/report-scheduler-frequency';
import { useStyles } from './styles';
import validationSchema from './validationSchema';

const fieldName = modelKey<SchedulerFormikModel>();

export interface Props {
  isOpen: boolean;
  onClose: (value?: boolean) => void;
  maxNameLength: number;
  userEmail: string;
  initialValues: SchedulerFormikModel;
  onPostSubmit?: (args: FormikPostSubmitArgs<SchedulerFormikModel>) => void;
}

function ReportSchedulerDialog({ isOpen, onClose, maxNameLength, userEmail, initialValues, onPostSubmit }: Props) {
  const classes = useStyles();

  const renderForm = React.useCallback(
    (formikProps: FormikProps<SchedulerFormikModel>) => {
      const { dirty, isValid, isSubmitting } = formikProps;
      const disabled = !dirty || !isValid || isSubmitting;

      return (
        <Form>
          <Typography variant="body1" className={classes.headerFont}>
            Scheduled reports will be saved and available for download as PDFs at your convenience.
          </Typography>
          <ReportName maxNameLength={maxNameLength} reportNameField={fieldName('name')} disabled={isSubmitting} />
          <ReportSchedulerFrequency userEmail={userEmail} disabled={isSubmitting} />
          <WizardStepper //
            onBack={onClose}
            backLabel="Cancel"
            nextLabel="Save"
            disabled={disabled}
            isLoading={isSubmitting}
          />
        </Form>
      );
    },
    [classes, maxNameLength, onClose, userEmail]
  );

  const onPreSubmitHandler = (values: SchedulerFormikModel) => {
    const filteredValues: SchedulerFormikModel = { ...values };

    switch (values.scheduledFrequency) {
      case ScheduledFrequencyEnum.Daily:
        delete filteredValues.daysOfWeek;
        delete filteredValues.dayOfMonth;
        break;
      case ScheduledFrequencyEnum.Weekly:
        delete filteredValues.interval;
        delete filteredValues.dayOfMonth;
        break;
      case ScheduledFrequencyEnum.Monthly:
        delete filteredValues.daysOfWeek;
    }
    filteredValues.filterData = initialValues.filterData;
    // trim the name before sending to the backend
    filteredValues.name = filteredValues.name.trim();
    return filteredValues;
  };

  return (
    <ConfirmationDialog //
      title={
        <FlexLayout justifyContent="flex-start" alignItems="center">
          <IconCalendar className={classes.sectionIcon} width={32} height={32} /> Schedule report
        </FlexLayout>
      }
      classes={{ dialogPaper: classes.dialogPaper, root: classes.root }}
      open={isOpen}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      <Formik //
        method={initialValues.id ? 'put' : 'post'}
        action={initialValues.id ? `/reports/scheduleReportParameters/${initialValues.id}` : '/reports/scheduleReportParameters'}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onPostSubmit={onPostSubmit}
        onPreSubmit={onPreSubmitHandler}
      >
        {(formikProps: FormikProps<SchedulerFormikModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}

export default React.memo(ReportSchedulerDialog);
