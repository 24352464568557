import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { NSW_NOD_PARTY_FORM_CONFIG } from '../../config';
import { DeceasedProprietorModel, NoticeOfDeath2_21_1Model } from '../../models';

interface PartyBookValidationContext {
  deceasedProprietors: DeceasedProprietorModel[];
  survivingJointTenantPartyBookIds: string[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: NoticeOfDeath2_21_1Model): PartyBookValidationContext => {
  return {
    deceasedProprietors: parentContext.deceasedJointTenants.flatMap(deceased => deceased.deceasedProprietors),
    survivingJointTenantPartyBookIds: parentContext.survivingJointTenants?.flatMap(surviving => surviving.partyBookId) ?? []
  };
};

const checkShouldValidateItem = ({ deceasedProprietors, survivingJointTenantPartyBookIds }: PartyBookValidationContext, partyIdForCheck: PartyModel<NswNameChange>['id']) => {
  return (
    deceasedProprietors.some(proprietor => proprietor.isAffectedProprietor && proprietor.partyBookId === partyIdForCheck) ||
    survivingJointTenantPartyBookIds?.some(id => id === partyIdForCheck)
  );
};

export const yupPartyItem = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(NSW_NOD_PARTY_FORM_CONFIG);
const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
