// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    fullWidth: {
      width: '100%'
    }
  }),
  {
    name: 'Applicants'
  }
);
