import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { FinalTenancyGroupModel, FinalTenancyHoldingsModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/final-holdings';
import {
  ApiReceivingProprietorGroupModel,
  FormReceivingTenancyDetailModel,
  ReceivingTenancyDetails$ShareSplitTypeEnum
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { convertShareQuantitiesToFractions } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving/components/transferee-group-array';
import { RelinquishingProprietorGroupModel, ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { multiplyFraction, subtractFraction, sumFraction, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { FractionModel } from '@sympli-mfe/document-forms-framework/core/models';
import { lowestCommonMultiple } from '@sympli-mfe/document-forms-framework/utils';

export function buildFinalTenancyHoldings(
  relinquishingProprietorGroups: RelinquishingProprietorGroupModel<TenancyPartyModel>[],
  receivingTenancy: FormReceivingTenancyDetailModel,
  partyBook: PartyModel<NswNameChange>[]
): FinalTenancyHoldingsModel {
  const sharesTransferred: FractionModel | undefined = relinquishingProprietorGroups
    .filter(proprietorGroup => proprietorGroup.isTransacting)
    .map(({ shareTransferred, previouslyHeldShareFraction, shareFraction }) => {
      const transferringShare =
        shareTransferred === ShareTransferredTypeEnum.Partial
          ? isValidFraction(shareFraction)
            ? multiplyFraction(previouslyHeldShareFraction, shareFraction!)
            : undefined
          : previouslyHeldShareFraction;
      return transferringShare;
    })
    .reduce(sumFraction);

  const receivingGroupsWithShareFractions = getFinalTenancyReceivingGroups(receivingTenancy, sharesTransferred);

  const remainingRelinquishingProprietors = relinquishingProprietorGroups
    .filter(({ isTransacting, shareTransferred }) => !isTransacting || shareTransferred === ShareTransferredTypeEnum.Partial)
    .map(({ isTransacting, parties, shareFraction, previouslyHeldShareFraction }) => {
      if (!isTransacting) {
        return { parties, shareFraction: previouslyHeldShareFraction };
      }
      let adjustedShareFraction: FractionModel | undefined = undefined;
      if (isValidFraction(shareFraction)) {
        const transferringFraction = multiplyFraction(previouslyHeldShareFraction, shareFraction!);
        adjustedShareFraction = subtractFraction(previouslyHeldShareFraction, transferringFraction);
      }

      return {
        parties,
        shareFraction: adjustedShareFraction
      };
    });

  const finalTenancyGroupsByPartiesKey = remainingRelinquishingProprietors
    .concat(receivingGroupsWithShareFractions.map(({ parties, shareFraction }) => ({ parties, shareFraction })))
    .reduce((finalTenancyGroupsMap: Map<string, { parties: TenancyPartyModel[]; shareFraction: FractionModel | undefined }[]>, { parties, shareFraction }) => {
      const partiesKey = parties
        .map(e => {
          const party = partyBook.find(pb => pb.id === e.partyBookId);
          if (!party) return 'none';
          return party.mergeMetadata ? party.mergeMetadata!.matchingItemHash : party.id;
        })
        .sort()
        .join(',');
      if (finalTenancyGroupsMap.has(partiesKey)) {
        finalTenancyGroupsMap.get(partiesKey)!.push({ parties, shareFraction });
      } else {
        finalTenancyGroupsMap.set(partiesKey, [{ parties, shareFraction }]);
      }
      return finalTenancyGroupsMap;
    }, new Map<string, { parties: TenancyPartyModel[]; shareFraction: FractionModel | undefined }[]>());

  const finalTenancyGroups = Array.from(finalTenancyGroupsByPartiesKey.values()).map(groups => ({
    parties: groups[0].parties,
    shareFractions: groups.map(g => g.shareFraction)
  }));

  const everyGroupHasValidFraction = finalTenancyGroups.every(({ shareFractions }) => shareFractions.every(s => isValidFraction(s)));
  if (!everyGroupHasValidFraction) {
    return {
      tenancyGroups: finalTenancyGroups.map(({ parties }): FinalTenancyGroupModel => ({ parties, shareQuantity: 0 })),
      lcm: 0
    };
  }

  const finalTenancyGroupsWithSummedFractions = finalTenancyGroups.map(({ parties, shareFractions }) => ({ parties, shareFraction: shareFractions.reduce(sumFraction) }));
  const lcm = finalTenancyGroupsWithSummedFractions.map(({ shareFraction }) => shareFraction!.denominator!).reduce(lowestCommonMultiple);
  const finalTenancyGroupsWithShareQuantity = finalTenancyGroupsWithSummedFractions.map(
    ({ parties, shareFraction }): FinalTenancyGroupModel => ({
      parties,
      shareQuantity: shareFraction!.numerator! * (lcm / shareFraction!.denominator!)
    })
  );

  return { tenancyGroups: finalTenancyGroupsWithShareQuantity, lcm };
}

function getFinalTenancyReceivingGroups(
  { receivingProprietorGroups, shareSplitType }: FormReceivingTenancyDetailModel,
  shareTransferred?: FractionModel
): ApiReceivingProprietorGroupModel[] {
  let groups: ApiReceivingProprietorGroupModel[] = [];
  const hasMultipleGroups = receivingProprietorGroups.length > 1;
  if (hasMultipleGroups && shareSplitType === ReceivingTenancyDetails$ShareSplitTypeEnum.TenantsInCommonUnequalShare) {
    groups = convertShareQuantitiesToFractions(receivingProprietorGroups);
  } else if (receivingProprietorGroups.length === 1 || (hasMultipleGroups && shareSplitType === ReceivingTenancyDetails$ShareSplitTypeEnum.TenantsInCommonEqualShare)) {
    groups = receivingProprietorGroups.map(group => ({ ...group, shareFraction: { numerator: 1, denominator: receivingProprietorGroups.length } }));
  }
  const shareTransferredIsValid = isValidFraction(shareTransferred);
  return groups.map(({ shareFraction, ...group }) => {
    const adjustedShareFraction = shareTransferredIsValid && isValidFraction(shareFraction) ? multiplyFraction(shareFraction!, shareTransferred!) : undefined;
    return { ...group, shareFraction: adjustedShareFraction };
  });
}

const isValidFraction = (fraction: FractionModel | undefined): boolean => (fraction && Number.isInteger(fraction.numerator) && Number.isInteger(fraction.denominator)) ?? false;
