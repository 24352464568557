import * as React from 'react';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDetailApiResponse } from '@sympli/api-gateway/models';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import PropertyDetails from './components/property-details/PropertyDetails';
import TitleActivity from './components/title-activity/TitleActivity';
import TitleDealings from './components/title-dealings/TitleDealings';
import Dealings from './dealings';
import { useStyles } from './styles';

export interface Props {
  jurisdiction?: LookupEnumModel<JurisdictionsEnum>;
  detail?: WorkspaceDetailApiResponse['titleReferences'][number];
  user: { id: string; name: string };
  participant: { id: string; subscriberName: string };
  workspaceId: string;
  onBack: (...args: Array<any>) => any;
  onTitleActivitiesCheck: (...args: Array<any>) => any;
  onTitleInformationResupply: (...args: Array<any>) => any;

  disabledActivitiesCheck?: boolean;
  isLoadingActivitiesCheck?: boolean;
  disabledInformationResupply?: boolean;
  isLoadingInformationResupply?: boolean;

  onShowWarningClick: () => void;
  showWarning: boolean;
}

function TitleDetail(props: Props) {
  const classes = useStyles();
  const { detail } = props;
  if (!detail?.landRegistryDetail) return null;
  const dealings = new Dealings(detail.landRegistryDetail);

  const renderActionsPanel = () => {
    return (
      <WizardStepper
        disabled={props.disabledInformationResupply}
        isLoading={props.isLoadingInformationResupply}
        onNext={props.onTitleInformationResupply}
        nextLabel="Order title information resupply"
        onBack={props.onBack}
        classes={{ nextButton: classes.buttonOrderTitleInformationResupply }}
      >
        <SympliButton
          disabled={props.disabledActivitiesCheck}
          isLoading={props.isLoadingActivitiesCheck}
          color="primary"
          variant="outlined"
          className={classes.buttonOrderTitleActivityCheck}
          onClick={props.onTitleActivitiesCheck}
        >
          Check for title activities
        </SympliButton>
      </WizardStepper>
    );
  };

  return (
    <div className={classes.root}>
      <TitleActivity
        onShowWarningClick={props.onShowWarningClick}
        showWarning={props.showWarning}
        user={props.user}
        workspaceId={props.workspaceId}
        participant={props.participant}
        reference={detail.landRegistryDetail.reference}
        tac={detail.titleActivityCheckResult}
      />
      <PropertyDetails //
        jurisdiction={props.jurisdiction!}
        ris={detail.landRegistryDetail}
      />
      <TitleDealings dealings={dealings.registered} jurisdiction={props.jurisdiction!} isRegisteredDealing={true} title="Registered dealings" dateLabel="Registered date" />
      <TitleDealings dealings={dealings.unregistered} jurisdiction={props.jurisdiction!} isRegisteredDealing={false} title="Unregistered dealings" dateLabel="Lodgement date" />
      {renderActionsPanel()}
    </div>
  );
}

export default React.memo(TitleDetail);
