import { memo } from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from '../styles';

export interface Props {
  count: number;
  className: string;
}

const AggregatorCardExtraInfo = ({
  //
  count,
  className
}: Props) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top-end"
      title={
        <FlexLayout className={classes.toolTip} flexDirection="column" justifyContent="flex-end" alignItems="flex-start">
          <Typography className={classes.toolTipHeader}>TODAY’S SETTLEMENTS</Typography>
          <Typography className={classes.toolTipContent}>
            These figures show the number of workspaces that have <span className={classes.toolTipBold}>successfully settled today (green) </span>or
            <span className={classes.toolTipBold}> contain errors (red).</span>
          </Typography>
        </FlexLayout>
      }
    >
      <Box className={classNames(classes.extraBox, className)}>{count}</Box>
    </Tooltip>
  );
};

export default memo(AggregatorCardExtraInfo);
