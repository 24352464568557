import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    requirement: {
      // fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      fontSize: 14
    },
    toolTip: {
      padding: '20px 25px'
    },
    description: {
      fontSize: 13,
      color: theme.palette.common.black
    },
    descriptionList: {
      margin: 0
    },
    descriptionListItem: {
      paddingLeft: 20,
      paddingTop: 8
    },
    groupItem: {
      width: 300
    },
    confirmPassword: {
      width: 300,
      marginTop: 15
    },
    header: {
      marginBottom: 30,
      marginTop: 20,
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    snackBar: {
      paddingTop: 10
    },
    snackBarError: {
      backgroundColor: colors.RED
    }
  });

export type ClassKeys = typeof styles;

export default styles;
