import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartLandAffectedModel, TitleReferenceModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

// this file was automatically generated from models.ts.mustache
export const PARTY_BOOK_KEY = 'partyBook';

export interface BaseApiPriorityNoticeModel<T extends PartLandAffectedModel> {
  applicants: PartyBookIdentifierModel[];
  partyBook: ApiDocumentPartyModel[];
  transactionInstruments: TransactionInstrumentModel<T>[];
  workspaceTitleReferences?: string[];
}

export interface PartyBookIdentifierModel {
  partyBookId: string | null;
}

export interface TransactionInstrumentModel<T extends PartLandAffectedModel> {
  dealingNumber?: string;
  documentType: string | null;
  receivingParties?: PartyBookIdentifierModel[];
  titleReferences?: TitleReferenceModel<T>[];
}

export type BasePriorityNoticeModel<T extends PartLandAffectedModel> = Omit<BaseApiPriorityNoticeModel<T>, 'partyBook'> & {
  [PARTY_BOOK_KEY]: PartyModel[];
};
