import * as yup from 'yup';

import { $eq, $get, $items, $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { CaveatModel, WithdrawalOfCaveat2_19_1Model } from '../../models';
import { VISIBILITY_CHECK_WITHDRAW_CAVEAT } from '../../visibilityChecks';

// path: withdrawCaveat
const yupWithdrawCaveat = yup
  .array<CaveatModel, WithdrawalOfCaveat2_19_1Model>()
  .required(msg.REQUIRED)
  .testContextualRule({
    /**
         * what we found in schema:
         [{"required":{"withdrawCaveats":{"$items":{"$oneOf":{"isSelected":true}}}},"message":null}]
         *
         */
    uniqueTestName: '"withdrawCaveats" contextual validation rule #1',
    onlyIf: (context: WithdrawalOfCaveat2_19_1Model): boolean => VISIBILITY_CHECK_WITHDRAW_CAVEAT(context),
    requirement: (context: WithdrawalOfCaveat2_19_1Model): boolean =>
      $oneOfItems($items(context, 'withdrawCaveats'), item => context.withdrawCaveats.length === 1 || $eq($get(item, 'isSelected'), true)),
    message: msg.REQUIRED
  });

export default yupWithdrawCaveat;
