import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = (theme: Theme) =>
  createStyles({
    documentActionIcon: {
      fill: theme.palette.primary.main,
      padding: '0px 4px'
    },
    documentActionTextDark: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.common.white,
      marginRight: 10
    },
    documentActionTextLight: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.text.primary,
      marginRight: 10
    },
    documentActionToolTipTitle: {
      marginTop: '16px',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
      color: theme.palette.text.primary
    },
    documentActionToolTipContent: {
      marginTop: 16,
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.text.primary
    },
    multipleSignersDark: {
      fontWeight: 'bold',
      color: theme.palette.common.white,
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.common.white}`,
      padding: 0,
      minWidth: 20,
      minHeight: 0,
      marginLeft: 4
    },
    multipleSignersLight: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      padding: 0,
      minWidth: 20,
      minHeight: 0,
      marginLeft: 4
    },
    toolTip: {
      background: theme.palette.grey[50],
      padding: '16px 48px 16px 16px'
    },
    text: {
      paddingRight: 10
    }
  });

export const useStyles = makeStyles(styles, { name: 'SignFinancialsActionLabel' });

export default styles;
