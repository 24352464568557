import React, { useCallback } from 'react';

import { useField } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getNestedErrorStringByPriority } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import RelinquishingProprietorGroup from '../../components/relinquishing-proprietor-group';
import { RelinquishingProprietorGroupModel, RelinquishingTenancyDetailModelWithMeta } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

function SectionTransferorDetails({ name, focusRef }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const [, { value, error, touched, contextPath: fieldContextPath = name }] = useField<RelinquishingTenancyDetailModelWithMeta>(name);
  const fieldName = createModelKeyAppender<RelinquishingTenancyDetailModelWithMeta>(name);
  const [errorForSectionDisplay, dataErrorName] = getNestedErrorStringByPriority<RelinquishingTenancyDetailModelWithMeta>(fieldContextPath, error, touched, [
    fieldName('relinquishingProprietorGroups')
  ]);
  const { relinquishingProprietorGroups, isGroupStructureMeaningful } = value;
  const hasMultipleGroups = relinquishingProprietorGroups.length > 1;

  const sectionTitle = ((): string => {
    if (!isGroupStructureMeaningful) {
      // There are instances when a group structure is not meaningful (e.g. when multiple titles are selected and those titles have multiple groups which are difficult to combine)
      // In this case, we cannot append data to the title as it would not be meaningful
      return 'Transferors';
    }
    if (hasMultipleGroups) {
      return 'Transferors (Tenants in Common)';
    }
    // There is only a single group, so the section label is essentially the group label
    const [soleRelinquishingProprietorGroup] = relinquishingProprietorGroups;
    if (soleRelinquishingProprietorGroup.parties.length > 1) {
      return 'Transferors (Joint Proprietors)';
    } else {
      return 'Transferor (Sole Proprietor)';
    }
  })();

  const renderSingleGroup = useCallback(
    ({ itemIndex, itemBinding, itemFocusRef, nextItemFocusRef }: DocumentArrayItemRenderProps<RelinquishingProprietorGroupModel>) => {
      return (
        <SectionLike //
          key={itemIndex}
          className={`${itemIndex === 0 ? classes.transferorFirst : ''} ${itemIndex === relinquishingProprietorGroups.length - 1 ? '' : classes.marginBottomIfNotLast}`}
        >
          {itemIndex === 0 ? (
            <RelinquishingProprietorGroup //
              name={itemBinding}
              numberOfTenantsInPrecedingGroups={0}
              hasSiblingGroups={hasMultipleGroups}
              focusRef={focusRef}
              nextFocusRef={nextItemFocusRef}
              isGroupStructureMeaningful={isGroupStructureMeaningful}
            />
          ) : (
            <RelinquishingProprietorGroup //
              name={itemBinding}
              numberOfTenantsInPrecedingGroups={relinquishingProprietorGroups.slice(0, itemIndex).flatMap(group => group.parties ?? []).length}
              focusRef={itemFocusRef}
              nextFocusRef={nextItemFocusRef}
              isGroupStructureMeaningful={isGroupStructureMeaningful}
            />
          )}
        </SectionLike>
      );
    },
    [classes.transferorFirst, classes.marginBottomIfNotLast, relinquishingProprietorGroups, hasMultipleGroups, focusRef, isGroupStructureMeaningful]
  );

  if (!relinquishingProprietorGroups.length) {
    return (
      <Section //
        data-name={name}
        error={errorForSectionDisplay}
        data-error-name={dataErrorName}
        title={sectionTitle}
        className={classes.marginBottomIfNotLast}
      >
        <Typography className={classes.noTransferorsMessage}>There are no transferors to display. Please ensure that a title is selected.</Typography>
      </Section>
    );
  }

  return (
    <Section //
      data-name={name}
      error={errorForSectionDisplay}
      title={sectionTitle}
      className={classes.transferorsSection}
    >
      <DocumentFieldArray //
        arrayBinding={fieldName('relinquishingProprietorGroups')}
        renderItem={renderSingleGroup}
        disabled={disabled}
        itemStyle="none"
        mode="fixed"
      />
    </Section>
  );
}

export default React.memo(SectionTransferorDetails);
