import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchTrustAccountLists, actionToggleNewAccountAddedFlag } from '../actions/trustAccounts';
import { TrustAccountDetailsApiResponse, TrustAccountRequestDetailsApiResponse } from '../trust-accounts/detail/models';

export interface TrustAccountsState {
  requestedItems: TrustAccountRequestDetailsApiResponse[];
  registeredItems: TrustAccountDetailsApiResponse[];
  status: ApiStatus;
  error?: string;
  accountAdded: boolean;
}

const initialState: TrustAccountsState = {
  requestedItems: [],
  registeredItems: [],
  status: 'idle',
  error: undefined,
  accountAdded: false
};

const reducer = createReducer<
  //
  TrustAccountsState,
  Action
>(initialState)
  .handleAction(actionFetchTrustAccountLists.request, (state): TrustAccountsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchTrustAccountLists.success, (state, { payload: { data } }): TrustAccountsState => {
    return {
      ...state,
      ...data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchTrustAccountLists.failure, (state, action): TrustAccountsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionToggleNewAccountAddedFlag, (state, { payload }): TrustAccountsState => {
    return {
      ...state,
      accountAdded: payload
    };
  });

export default reducer;
