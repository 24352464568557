import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { DateOfDeathModel, NoticeOfDeath2_21_1Model } from '../../models';
import { END_OF_YESTERDAY } from '../../sections/deceased-joint-tenants/validationSchema';
import {
  VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE
} from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
  className?: string;
}

function DateOfDeath({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();
  const { values } = formikProps;
  const value: DateOfDeathModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DateOfDeathModel>(name);
  const classes = useStyles();

  return (
    <div data-section={name} className={className}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Field //
          name={fieldName('dateOfDeathType')}
          label={'Details of death date'}
          component={SelectField}
          debug={debug}
          disabled={disabled}
          format="number"
          options={TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          aria-label="Date Of Death Type"
          data-testid={'dateOfDeathType'}
          className={classes.fieldWidth}
        />
        {VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE(value) && (
          <Field //
            name={fieldName('deathDate')}
            label={'Date of death'}
            component={DatePickerField}
            disabled={disabled}
            maxDate={END_OF_YESTERDAY}
            aria-label="Death Date"
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(value) && (
          <Field //
            name={fieldName('fromDate')}
            label={'From date'}
            component={DatePickerField}
            disabled={disabled}
            maxDate={END_OF_YESTERDAY}
            aria-label="From Date"
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(value) && (
          <Field //
            name={fieldName('toDate')}
            label={'To date'}
            component={DatePickerField}
            disabled={disabled}
            maxDate={END_OF_YESTERDAY}
            aria-label="To Date"
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION(value) && (
          <Field //
            name={fieldName('dateDescription')}
            label={'Date description'}
            component={InputField}
            disabled={disabled}
            placeholder={resolvePlaceholder()}
            aria-label="Date Description"
            className={classes.fieldWidth}
          />
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(DateOfDeath);
