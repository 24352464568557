import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    notificationContent: {
      padding: '0 10px 0 16px'
    },

    notificationRoot: {
      border: 'none',
      borderRadius: 'unset',
      '& + &': {
        marginTop: 8
      }
    },

    dividerWarning: {
      marginBottom: 8,
      backgroundColor: theme.palette.secondary.main
    },
    dividerError: {
      marginBottom: 8,
      backgroundColor: theme.palette.error.main
    },
    assistanceFooter: {
      padding: '10px 0',
      textAlign: 'center',
      '& > span': {
        fontSize: 13,
        fontStyle: 'italic'
      },
      '& > button, & > a': {
        fontSize: 14,
        fontWeight: 'bold'
      }
    }
  }),
  {
    name: 'LandRegistryNotifications'
  }
);
