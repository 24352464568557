import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { TransmissionApplicationWithoutDuty2_21_1Model } from './models';
import ApplicantCapacity from './sections/applicant-capacity';
import Applicants from './sections/applicants';
import DeceasedProprietorGroups from './sections/deceased-proprietor-groups';
import SupportingDocuments from './sections/supporting-documents';
import TitleReferences from './sections/title-references';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<TransmissionApplicationWithoutDuty2_21_1Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const { values } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          focusRef={focusOnTitleReferencesRef}
        />
        <Section title="Completed on behalf of">
          <ApplicantCapacity name={fieldName('applicantCapacity')} />
        </Section>

        <DeceasedProprietorGroups name={fieldName('deceasedProprietorGroups')} />

        <Section title={pluralize('Applicant', values.applicants!.length)}>
          <Applicants name={fieldName('applicants')} />
        </Section>

        <SupportingDocuments name={fieldName('supportingDocuments')} />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
