import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    fieldWidth: {
      width: '450px'
    }
  }),
  {
    name: 'Applicant'
  }
);
