import * as React from 'react';

import classNames from 'classnames';
import { titleCase } from 'title-case';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

// tslint:disable:no-string-literal
interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: boolean; // show icon at the front of the line
  variant?: string; // small | medium | large
  color?: 'white' | 'grey';
  marginLeft?: number;
  widthPercent?: number; // width of animated part
}

type Props = OwnProps & WithStyles<ClassKeys>;

class Line extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    icon: false,
    color: 'white',
    variant: 'line',
    widthPercent: 50
  };

  render() {
    const { classes, color, variant, widthPercent, icon, className, marginLeft, style, ...rest } = this.props;
    const backgroundColorClassName = classes[`background${titleCase(color!)}`];
    let customizedStyle = style;
    if (typeof marginLeft === 'number') {
      customizedStyle = { ...this.props.style, marginLeft, width: `calc(100% - ${marginLeft}px)` };
    }

    return (
      <div
        className={classNames(classes.root, classes['animatedBackground'], backgroundColorClassName, classes[`variant${titleCase(variant!)}`], className)}
        style={customizedStyle}
        {...rest}
      >
        {icon ? <div className={classNames(classes['backgroundMasker'], backgroundColorClassName)} style={{ left: 15, width: 5 }} /> : null}
        <div className={classNames(classes['backgroundMasker'], backgroundColorClassName)} style={{ left: `${widthPercent!}%`, right: 0 }} />
      </div>
    );
  }
}

export default withStyles(styles)(Line);
