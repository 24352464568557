import _cloneDeep from 'lodash-es/cloneDeep';
import _get from 'lodash-es/get';
import _mergeWith from 'lodash-es/mergeWith';
import _set from 'lodash-es/set';
import randomize from 'randomatic';

import { checkRule, isObject, isSimpleType, resolveBinding, resolveDataBindingReference } from './helpers';
import { ParsedFieldDefinition } from './models';

// tslint:disable:comment-format
const UUID_MIXED_PATTERN = 'Aa0';
const UUID_NUMBER_PATTERN = '0';

// tslint:disable:no-string-literal
export const resolveDefaultValues = (fields: Array<ParsedFieldDefinition>, valuesForDataReferencing: object) => {
  return resolveObject(fields, valuesForDataReferencing, '');
};

export const resolveItemForArray = (fd: ParsedFieldDefinition, values: object, bindingPrefix: string) => {
  // here we got the original array definition and we look at the children type
  let { children = [], childrenType, ...rest } = fd;
  let definition: ParsedFieldDefinition;

  // for simple types, grab definition from children itself
  if (isSimpleType(childrenType!)) {
    definition = children[0];
  } else {
    definition = {
      ...rest,
      name: '',
      type: childrenType!,
      children
    };
  }

  return resolveByType(definition, values, bindingPrefix);
};

// this will happen only during initialization of the data
const updateWithDataRefedValues = (fd: ParsedFieldDefinition, values: object, bindingPath: string, value: any) => {
  //! Please note this method explicitly mutates values argument.

  // if our value was referenced, we will modify the source values
  // since some other property might later want to use it
  // for example:
  // - directors refers to employees using dataRef
  // - mainContact referes to directors using dataRef
  // if we would not do it, the mainContact would be empty
  //! Please note the order in which the properties are processed matters.
  if (typeof fd.extra.dataRef === 'string') {
    _set(values, bindingPath, value);
  }
};

const getDataRefedValues = (fd: ParsedFieldDefinition, values: object, dataBinding: string) => {
  const {
    extra: { dataRefFilter }
  } = fd;

  let value: any = _get(values, dataBinding);

  // if the referenced values is an array we will filter the data
  if (Array.isArray(value) && typeof dataRefFilter === 'object') {
    value = (value as Array<any>).filter(item => {
      let passed = checkRule(dataRefFilter, item);
      return passed;
    });
  }

  return _cloneDeep(value);
};

const resolveObject = (fields: Array<ParsedFieldDefinition>, values: object, bindingPrefix: string) => {
  return fields.reduce((acc, fd) => {
    let { name, extra = {} } = fd;
    const { metadata, uuid } = extra;
    // we don't want to create any default data if it's marked as metadata but it's not a uuid
    // neither for custom components
    if (metadata && !uuid /*|| isCustomComponent(component)*/) {
      return acc;
    }

    const bindingPath = resolveBinding(name, bindingPrefix);
    const value: any = resolveByType(fd, values, bindingPath);

    return _set(acc, name, value);
  }, {});
};

const resolveArray = (fd: ParsedFieldDefinition, values: object, bindingPrefix: string) => {
  let {
    extra: { minItems = 1 }
  } = fd;

  return Array(minItems)
    .fill(null)
    .map((_, i) => resolveItemForArray(fd, values, `${bindingPrefix}[${i}]`));
};

const resolveUuid = (type: string, length: number) => {
  if (type === 'number') {
    return Number(randomize(UUID_NUMBER_PATTERN, length));
  }

  return randomize(UUID_MIXED_PATTERN, length);
};

const resolveByType = (fd: ParsedFieldDefinition, values: object, bindingPath: string) => {
  let {
    type,
    children = [],
    extra: { dataRef, uuid }
  } = fd;

  // if the value is defined by reference, just return it
  if (typeof dataRef === 'string') {
    const dataBinding = resolveDataBindingReference(bindingPath, dataRef!);
    const value: any = getDataRefedValues(fd, values, dataBinding);

    updateWithDataRefedValues(fd, values, bindingPath, value);
    // TODO we should first generate default data and then merge the dataRef-ed data into it
    return value;
  }

  if (typeof uuid === 'number') {
    return resolveUuid(type, uuid);
  }

  // date picker needs to be initialized with null value instead of '', othewise it does not work properly
  if (fd.extra?.component === 'DatePicker') {
    return null;
  }

  switch (type) {
    case 'boolean':
      return false;
    case 'number':
    case 'integer':
      return null;
    case 'object':
      return resolveObject(children, values, bindingPath);
    case 'array':
      return resolveArray(fd, values, bindingPath);
    default:
      return '';
  }
};

// targetVal -> pre-calculated schema value
// sourceVal -> value from backed
function customizer(targetVal: any, sourceVal: any, key: string, target: any, source: any) {
  // if we have received array from backend,
  // we have probably also precalculated default value based on schema

  if (Array.isArray(sourceVal) && Array.isArray(targetVal) && targetVal.length === 1) {
    const defaultObj = targetVal[0];
    if (isObject(defaultObj)) {
      if (sourceVal.length === 0) {
        return targetVal;
      }
      // we need to make sure that every item that came from backedn contains all precalculated value attributes
      // otherwise formik touched would not work correctly especially for dynamic objects
      return sourceVal.map(item => mergeObjects(_cloneDeep(defaultObj), item));
    }
  }
  // explicitly ignore null values which incorrectly comes from backend and override them with precalculated value
  return sourceVal === null ? targetVal : undefined;
}

export function mergeObjects(target: object = {}, source: object = {}) {
  return _mergeWith(
    target,
    source, //
    customizer
  );
}

export const resetInvisibleValue = (values: object, bindingPath: string, invisibleValue: any) => {
  //! Please note this method explicitly mutates values argument.
  if (isObject(invisibleValue)) {
    const origValue = _get(values, bindingPath);
    if (isObject(origValue)) {
      // we merge the data;
      return _set(values, bindingPath, { ...origValue, ...invisibleValue });
    }
  }
  // we just replace with new value
  return _set(values, bindingPath, invisibleValue);
};
