import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import _debounce from 'lodash-es/debounce';
import _isEqual from 'lodash-es/isEqual';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserLevelPermissionEnum } from '@sympli/api-gateway/enums';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import DashboardWrapper from 'src/components/layout/v2/DashboardWrapper';
import { AssignmentTypeEnum, DashboardSortDirectionEnum, DashboardTypeEnum } from 'src/containers/dashboard/shared/models';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import Secured from 'src/containers/shared/auth/profile-security/Secured';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import useScreenSize from 'src/hooks/useScreenSize';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme/screens';
import CreateNewWorkspaceContainer from '../components/create-new-workspace';
import { actionFetchGroupMembers } from '../shared/actions';
import { DEBOUNCE_LIMIT } from '../shared/helpers';
import { usePermissionDialog } from '../shared/hooks/usePermissionDialog';
import useShowMessage from '../shared/hooks/useShowMessage';
import { useWorkspaceAssignableGroups } from '../shared/reducers/workspaceAssignableGroups';
import { actionFetchFinancialWorkspacesFeedV2, showFinancialWorkspaceFeedDetail } from './actions';
import { removeLocalStorageDateTime, removeLocalStorageSavedModel, updateLocalStorage } from './aggregators/helper';
import { fetchAggregatorInfo } from './api';
import { defaultDashboardFilterModel, getFiltersWithSelectedTab } from './filters/helper';
import FinancialWorkspaces from './FinancialWorkspaces';
import FinancialWorkspacesContext from './FinancialWorkspaces.context';
import FinancialWorkspacesRowContainer from './FinancialWorkspacesRowContainer';
import { defaultTabQuery, getDefaultAssignmentTypeTab, resolveSavedTabFiltersQuery, resolveWorkspaceDetailArgs } from './helper';
import { getWarningMessage, WarningMessageEnum } from './messages';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from './models';
import { useFinancialWorkspacesFeedV2 } from './reducers/financialWorkspacesFeedV2';
import { FinancialTableModel } from './table/models';

function FinancialWorkspacesContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const dispatchErrorDialog = usePermissionDialog('workspaces');
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);
  const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([]);
  const [isRefetchingData, setIsRefetchingData] = React.useState<boolean>(false);
  const [currentUpdatedTime, setCurrentUpdatedTime] = React.useState<Date>(new Date());
  const [selectedTab, setSelectedTab] = React.useState<FinancialDashboardRouteTabsEnumV2>(FinancialDashboardRouteTabsEnumV2.active);
  const { query, totalCount, items, rowDetailIndex, status } = useFinancialWorkspacesFeedV2();
  // assigned to group is always there for the group value
  const assignedGroup = useWorkspaceAssignableGroups().items;
  const defaultGroup = assignedGroup.length > 0 ? assignedGroup[0].id : undefined;
  const navigate = useNavigate();

  const { userId, userPermissions } = useProfile().data!;
  const hasManageInvitationPermission = userPermissions.includes(UserLevelPermissionEnum.ManageInvitations);

  const assignmentTypeLocalStorageKey = `${userId}_financialDashboard_assignmentType`;
  const [assignmentType, setAssignmentType] = React.useState<AssignmentTypeEnum>(getDefaultAssignmentTypeTab(assignmentTypeLocalStorageKey));

  // localStorageKey is for storing filter config
  const localStorageKey = `${userId}_financialDashboard`;
  const uniqueStorageKeyForWelcomeMessage = `FS_DB_${userId}`;
  const uniqueStorageKeyForRefreshMessage = `FS_DB_REFRESH_${userId}`;
  const isTransactionTypeEnabled: boolean = useFeatureFlag(FeatureToggleEnum.transactionTypeEnabled);

  const [selectedFilters, hasSavedValue] = useMemo(
    () => getFiltersWithSelectedTab(localStorageKey, selectedTab, assignmentType, isTransactionTypeEnabled),
    [localStorageKey, selectedTab, assignmentType, isTransactionTypeEnabled]
  );
  // this is filters we need to persistent during all actions
  // as aggregators can impact filters (override), so it is always top down actions
  const [currentTabFilters, setCurrentTabFilters] = React.useState(selectedFilters);

  // reset checkbox values
  const resetSelectedItems = () => {
    setSelectedIndexes([]);
  };

  const sorting: {
    sortBy: string | undefined;
    sortOrder: DashboardSortDirectionEnum | undefined;
  } = useMemo(() => {
    const { sortBy, sortOrder }: FinancialWorkspacesV2ApiRequest = defaultTabQuery(selectedTab, assignmentType, defaultGroup);

    return {
      sortBy: query.sortBy !== undefined ? query.sortBy : sortBy,
      sortOrder: query.sortOrder !== undefined ? query.sortOrder : sortOrder
    };
  }, [selectedTab, assignmentType, defaultGroup, query.sortBy, query.sortOrder]);

  const fetchInitialData = React.useCallback(
    (assignmentType: AssignmentTypeEnum, defaultGroup?: string) => {
      const { sortBy, sortOrder }: FinancialWorkspacesV2ApiRequest = defaultTabQuery(selectedTab, assignmentType, defaultGroup);
      const filter = hasSavedValue
        ? {
            withProposals: selectedTab === FinancialDashboardRouteTabsEnumV2.toBeRebooked ? false : undefined,
            ...resolveSavedTabFiltersQuery(selectedFilters, assignmentType),
            sortBy,
            sortOrder
          }
        : defaultTabQuery(selectedTab, assignmentType, defaultGroup);
      if (!filter.workspaceStatus || filter.workspaceStatus.length === 0) {
        filter.workspaceStatus = [...(defaultTabQuery(selectedTab, assignmentType, defaultGroup).workspaceStatus ?? [])];
      }

      if ((status === 'pending' || status === 'refetching') && _isEqual(query, filter)) {
        return;
      }

      dispatch(actionFetchFinancialWorkspacesFeedV2.request(filter));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, hasSavedValue, selectedFilters, selectedTab]
  );

  useEffect(() => {
    // defaultGroup is only used for Reassign Tab, other tabs don't need to listen to the change of defaultGroup
    if (assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
      fetchInitialData(assignmentType, defaultGroup);
    }
  }, [assignmentType, defaultGroup, fetchInitialData]);

  useEffect(() => {
    if (assignmentType !== AssignmentTypeEnum.ReAssigned) {
      fetchInitialData(assignmentType);
    }
  }, [fetchInitialData, assignmentType]);

  useEffect(() => {
    if (currentTabFilters.groupIds?.length === 0 && assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
      setCurrentTabFilters({ ...currentTabFilters, groupIds: [defaultGroup] });
    }
  }, [currentTabFilters, assignmentType, defaultGroup]);

  useEffect(() => {
    dispatch(actionFetchGroupMembers.request());
  }, [dispatch]);

  // clean up the date time filters when un-mount since the SDT/LAST ACCESS should always be default
  useEffect(() => {
    return () => {
      removeLocalStorageDateTime(localStorageKey);
    };
  }, [localStorageKey]);

  const fetchData = useCallback(
    (updatedFilter: Partial<FinancialWorkspacesV2ApiRequest>) => {
      const shouldUseDefaultWorkspaceStatus = updatedFilter => {
        const combinedQuery: FinancialWorkspacesV2ApiRequest = {
          ...query,
          ...updatedFilter
        };
        return !combinedQuery.workspaceStatus || combinedQuery.workspaceStatus.length === 0;
      };

      if (shouldUseDefaultWorkspaceStatus(updatedFilter)) {
        const defaultQuery = defaultTabQuery(selectedTab, assignmentType, defaultGroup);

        updatedFilter = {
          ...updatedFilter,
          workspaceStatus: defaultQuery.workspaceStatus
        };
      }

      const newQuery: FinancialWorkspacesV2ApiRequest = {
        ...query,
        ...updatedFilter,
        assignmentType
      };

      dispatch(actionFetchFinancialWorkspacesFeedV2.request(newQuery));
    },
    [query, assignmentType, dispatch, selectedTab, defaultGroup]
  );

  const handleOnAssignmentChange = useCallback(
    (assignmentType: AssignmentTypeEnum) => {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'assignment-tab',
        logGroupId: `${DashboardTypeEnum.Financial}-dashboard`,
        tabName: `${assignmentType}`
      });

      setAssignmentType(assignmentType);
      setSelectedTab(FinancialDashboardRouteTabsEnumV2.active);
      localStorage.setItem(assignmentTypeLocalStorageKey, assignmentType.toString());
      const [selectedFilters] = getFiltersWithSelectedTab(localStorageKey, FinancialDashboardRouteTabsEnumV2.active, assignmentType, isTransactionTypeEnabled);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...selectedFilters });

      resetSelectedItems();
    },
    [assignmentTypeLocalStorageKey, localStorageKey, isTransactionTypeEnabled]
  );

  const refreshTableData = useCallback(() => {
    dispatch(actionFetchFinancialWorkspacesFeedV2.request(query));
  }, [dispatch, query]);

  const handleOnWorkspaceStatusTabChange = useCallback(
    (selectedTab: FinancialDashboardRouteTabsEnumV2, skipLogging?: boolean) => {
      if (!skipLogging) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'filter-tab',
          logGroupId: `${DashboardTypeEnum.Financial}-dashboard`,
          tabName: `${selectedTab}`
        });
      }

      setSelectedTab(selectedTab);
      // have to reset this as the current filters been in state
      const [selectedFilters, _] = getFiltersWithSelectedTab(localStorageKey, selectedTab, assignmentType, isTransactionTypeEnabled);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...selectedFilters });

      resetSelectedItems();
    },
    [setSelectedTab, localStorageKey, assignmentType, isTransactionTypeEnabled]
  );

  const fetchDataWithDebounce = useMemo(() => _debounce(fetchData, DEBOUNCE_LIMIT), [fetchData]);

  const handleOnAddFilterUpdate = useCallback(
    (currentTabFilters: DashboardFilterModel) => {
      updateLocalStorage(localStorageKey, currentTabFilters);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...currentTabFilters });
    },
    [localStorageKey]
  );

  const handleOnTableFilterChange = useCallback(
    (updatedFilters: FinancialWorkspacesV2ApiRequest, currentTabFilters: DashboardFilterModel) => {
      updateLocalStorage(localStorageKey, currentTabFilters);
      resetSelectedItems();
      if (typeof updatedFilters.settlementPeriod === 'string' && updatedFilters.settlementPeriod === 'AllDates') {
        updatedFilters.settlementPeriod = null;
      }

      if (typeof updatedFilters.lastAccessPeriod === 'string' && updatedFilters.lastAccessPeriod === 'AllDates') {
        updatedFilters.lastAccessPeriod = null;
      }

      if (typeof updatedFilters.archivedDatePeriod === 'string' && updatedFilters.archivedDatePeriod === 'AllDates') {
        updatedFilters.archivedDatePeriod = null;
      }

      // state update so force the filter component update
      setCurrentTabFilters({ ...currentTabFilters });

      fetchDataWithDebounce({
        ...query, // merge current query
        ...updatedFilters,
        pageNumber: 1
      });
    },
    [fetchDataWithDebounce, localStorageKey, query]
  );

  const handleOnAggregatorActionClick = useCallback(
    (
      //
      filters: FinancialWorkspacesV2ApiRequest,
      engagedTab: FinancialDashboardRouteTabsEnumV2,
      currentTabFilters: DashboardFilterModel
    ) => {
      // for this one we should always fire the call direction instead of getting existing query cached in redux
      if (assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
        filters.groupIds = [defaultGroup];
        currentTabFilters.groupIds = [defaultGroup];
      }
      updateLocalStorage(localStorageKey, currentTabFilters);
      //setCurrentTabFilters({ ...currentTabFilters });
      handleOnWorkspaceStatusTabChange(engagedTab, true);

      // must manually fire the call since since tab will not trigger the api call
      if (selectedTab === engagedTab) {
        dispatch(actionFetchFinancialWorkspacesFeedV2.request(filters));
      }

      resetSelectedItems();
    },
    [localStorageKey, handleOnWorkspaceStatusTabChange, assignmentType, defaultGroup, selectedTab, dispatch]
  );

  const handleOnPageChange = useCallback(
    (args: { pageNumber: number }) => {
      fetchData({
        ...args,
        assignmentType
      });
      resetSelectedItems();
      if (selectedIndexes.length > 0) {
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: getWarningMessage(WarningMessageEnum.NoteReassign),
            variant: 'new-warning',
            autoHideDuration: 5000
          })
        );
      }
    },
    [fetchData, assignmentType, selectedIndexes, dispatch]
  );

  const handleOnSortChange = useCallback(
    (args: { sortBy: string; sortOrder: DashboardSortDirectionEnum }) => {
      fetchData({
        ...args,
        assignmentType
      });
      resetSelectedItems();
    },
    [fetchData, assignmentType]
  );

  const handleOnRowToggle = React.useCallback(
    (newRowDetailIndex: number, rowData: FinancialTableModel) => {
      dispatch(
        showFinancialWorkspaceFeedDetail({
          rowDetailIndex: rowDetailIndex === newRowDetailIndex ? undefined : newRowDetailIndex
        })
      );
    },
    [dispatch, rowDetailIndex]
  );

  let rowDetailNode: React.ReactNode = null;

  if (selectedTab !== FinancialDashboardRouteTabsEnumV2.archived) {
    const { workspaceId, participantId } = resolveWorkspaceDetailArgs(items, rowDetailIndex);
    if (workspaceId && participantId) {
      rowDetailNode = (
        <FinancialWorkspacesRowContainer
          //
          workspaceId={workspaceId}
          participantId={participantId}
        />
      );
    }
  }

  const handleOnRowClick = React.useCallback(
    (rowDetailIndex: number, rowData: FinancialTableModel) => {
      if (assignmentType === AssignmentTypeEnum.ReAssigned) {
        if (!hasManageInvitationPermission) {
          dispatchErrorDialog();
        } else {
          setSelectedIndexes(selectedIndexes.includes(rowDetailIndex) ? selectedIndexes.filter(x => x !== rowDetailIndex) : selectedIndexes.concat([rowDetailIndex]));
        }
      } else {
        const { workspaceId, participantId } = rowData;

        if (workspaceId && participantId) {
          const linkTo: string = resolveWorkspaceDetailLink({
            workspaceId,
            participantId
          });
          navigate(linkTo);
        }
      }
    },
    [assignmentType, hasManageInvitationPermission, dispatchErrorDialog, selectedIndexes, navigate]
  );

  const handleOnTableFiltersReset = useCallback(() => {
    removeLocalStorageSavedModel(localStorageKey, selectedTab, assignmentType);
    setCurrentTabFilters(defaultDashboardFilterModel(selectedTab, assignmentType));
    // fire the default api call as we cleanup all the filters
    dispatch(actionFetchFinancialWorkspacesFeedV2.request(defaultTabQuery(selectedTab, assignmentType, defaultGroup)));
    resetSelectedItems();
  }, [localStorageKey, selectedTab, assignmentType, dispatch, defaultGroup]);

  const [shouldShowWelcomeMessage, dismissWelcomeMessageHandler] = useShowMessage(uniqueStorageKeyForWelcomeMessage);
  const [shouldShowRefreshMessage, dismissRefreshIconMessageHandler] = useShowMessage(uniqueStorageKeyForRefreshMessage);

  // Reassign handlers
  const handleOnDismissReassignBar = (event: React.MouseEvent) => {
    resetSelectedItems();
  };

  const refreshHandler = () => {
    setIsRefetchingData(true);
    refreshTableData();
  };

  React.useEffect(() => {
    if (isRefetchingData && (status === 'resolved' || status === 'rejected')) {
      setIsRefetchingData(false);
    }
  }, [status, isRefetchingData]);

  const resetCurrentUpdatedTime = React.useCallback((date: Date) => {
    setCurrentUpdatedTime(date);
  }, []);

  return (
    <DashboardWrapper>
      <FinancialWorkspacesContext.Provider value={{ currentUpdatedTime, resetCurrentUpdatedTime }}>
        <FinancialWorkspaces
          /**
           * header component props
           */
          screenSize={screenSize}
          assignmentType={assignmentType}
          onAssignmentTypeChange={handleOnAssignmentChange}
          createNewButtonNode={
            <Secured requiredUserPermissions={UserLevelPermissionEnum.ManageInvitations} hiddenForDisabled>
              <CreateNewWorkspaceContainer />
            </Secured>
          }
          /**
           * aggregators component props
           */
          localStorageKey={localStorageKey}
          fetchAggregatorInfo={fetchAggregatorInfo}
          onAggregatorClick={handleOnAggregatorActionClick}
          /**
           * nav-tabs component props
           */
          workspaceStatusGroup={selectedTab}
          onWorkspaceStatusTabChange={handleOnWorkspaceStatusTabChange}
          /**
           * table filters component props
           */
          currentTableFilters={currentTabFilters}
          onTableAddFilterChange={handleOnAddFilterUpdate}
          onTableFiltersReset={handleOnTableFiltersReset}
          onTableFiltersChange={handleOnTableFilterChange}
          /**
           * table component props
           */
          rows={items}
          apiStatus={status}
          totalCount={totalCount}
          pageSize={query.pageSize}
          pageNumber={query.pageNumber}
          sortBy={sorting.sortBy}
          sortOrder={sorting.sortOrder}
          onPageChange={handleOnPageChange}
          onSortChange={handleOnSortChange}
          rowDetailNode={rowDetailNode}
          rowDetailIndex={rowDetailIndex}
          onRowToggle={handleOnRowToggle}
          onRowClick={selectedTab === FinancialDashboardRouteTabsEnumV2.archived || assignmentType === AssignmentTypeEnum.ReAssigned ? handleOnRowClick : undefined}
          /**
           * Reassign Welcome
           * */
          shouldShowWelcomeMessage={shouldShowWelcomeMessage && assignmentType === AssignmentTypeEnum.ReAssigned}
          dismissWelcomeMessageHandler={dismissWelcomeMessageHandler}
          /**
           * Reassign Table extra props
           */
          selectedRowIndexes={selectedIndexes}
          onSelectionChange={(selectedRowIndexes: number[]) => {
            if (!hasManageInvitationPermission) {
              dispatchErrorDialog();
            } else {
              setSelectedIndexes(selectedRowIndexes);
            }
          }}
          /**
           * Reassign bar props
           */
          shouldShowReassignBar={selectedIndexes.length > 0}
          handleOnDismissReassignBar={handleOnDismissReassignBar}
          selectedItems={items.filter((x, index) => selectedIndexes.includes(index)).map(x => ({ workspaceId: x.workspaceId, participantId: x.participantId }))}
          groupId={currentTabFilters.groupIds && currentTabFilters.groupIds.length > 0 ? currentTabFilters.groupIds[0] : defaultGroup}
          resetSelectedItems={resetSelectedItems}
          autoRefresh={refreshTableData}
          //
          refetchingAggregators={isRefetchingData}
          isRefetchingData={isRefetchingData}
          refreshHandler={refreshHandler}
          shouldShowRefreshMessage={shouldShowRefreshMessage}
          dismissRefreshIconMessageHandler={dismissRefreshIconMessageHandler}
        />
      </FinancialWorkspacesContext.Provider>
    </DashboardWrapper>
  );
}

export default React.memo(FinancialWorkspacesContainer);
