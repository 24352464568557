import { LodgementCaseStatusEnum, SettlementStatusEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { ResupplyLandRegistryInformationApiResponse, WorkspaceDetailApiResponse } from '@sympli/api-gateway/models';
import { DocumentChangeEntityModel } from '@sympli/api-gateway/shared';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { wordifyEnum } from 'src/utils/enums';

export {
  RoleChatPermissionMapping,
  DEFAULT_WORKSPACE_ROLE_OPTIONS,
  DEFAULT_WORKSPACE_TYPE_OPTIONS,
  resolveFinancialRoleType,
  resolveWorkspaceType,
  resolveFinancialSettlementType,
  getWorkspaceRoleTypeOptions
} from 'src/models/roles';

export { jurisdictionOptions, supportedJurisdictionStateOptions as jurisdictionStateOptions, allJurisdictionStateOptions } from './jurisdictions';
export { TaskTypesEnum } from 'src/models/tasks';

export interface TableFiltersModel {
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: SortDirectionEnum;
}

export type PaginatedItemsModel<T extends object = {}> = {
  items: T[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
};

export interface PersonNameModel {
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName?: string;
}
export interface PersonModel extends PersonNameModel {
  id?: string;
  mobile?: string;
  jobTitle: string;
  email: string;
}

export interface ContactNumberModel {
  mobile: string;
  work?: string;
}

export interface AccountModel {
  bsbNumber: string;
  accountNumber: string;
  bankName: string;
  accountName: string;
}

export interface AbnLookupResultModel {
  organisationName: string;
  abn: string;
}

export interface OrganisationNameModel extends AbnLookupResultModel {
  acn: string;
}

// MR - moved
// be aware the enum number are different since we remove a few obsoleted status.
// export enum WorkspaceStatusEnum {
//   Failed = -1, // Settlement failed
//   OnSchedule = 0,
//   AtRisk = 1,
//   ReadyForSettlement = 2,
//   SettlementInProgress = 19,
//   SettlementIssue = 26,
//   ReadyForLodge = 27,
//   Archived = 28,
//   Abandoned = 29,
//   LodgementInQueue = 30,
//   Finalised = 31,
//   Caution = 32,
//   AwaitingInvitee = 33, //ToDo: Should add in backend to return,
//   Withdrawn = 34, //ToDo: Should add in backend to return
//   Success = 100
// }

// MR - moved
// export enum LodgementCaseStatusEnum {
//   Unverified = 0,
//   LodgementVerificationRequested = 1, // Verification in progress
//   LodgementVerificationSuccess = 2, // Ready to lodge
//   LodgementVerificationError = 3, // Attention required
//   LodgementRequested = 4, // Lodgement in progress
//   LodgementSuccess = 5, // Lodgement completed
//   LodgementError = 6, // Attention required
//   Registered = 7,
//   Rejected = 8,
//   Withdrawn = 9,
//   AwaitingInvitee = 10, //ToDo: Should add in backend to return
//   Unnecessary = 11,
//   LodgementConnectionError = 14
// }

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.LodgementCaseLogDetail
 */
export interface LodgementCaseLogModel {
  createAt: string; // UTC datetime
  lodgementCaseStatus: LodgementCaseStatusEnum;
  workspaceId: string;
}

export enum ShiftEnum {
  Back = -1,
  Next = 1
}

export interface TitleReferenceEntityModel {
  reference: string;
  address: ResupplyLandRegistryInformationApiResponse['propertyAddress'];
  proprietors?: Array<{
    legalEntityName: string;
  }>;
  documentChanges?: DocumentChangeEntityModel[];
  hasChanges?: boolean;
}

export interface CurrentPartiesModel {
  names: Array<string>;
  workspaceRole: LookupEnumModel<WorkspaceRoleEnum>;
  participantId: string;
  documentNames: Array<string>;
  allocatedUser?: AllocatedUser;
  groupId: string;
}

export interface AllocatedUser {
  userId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  avatarUrl?: string;
}

/**
 * MR - svc moved
 * Tickle.Application.Service.Shared.WorkspaceDateTime
 */
// export interface SettlementDateTimeModel extends DueDateModel {}
export type SettlementDateTimeModel = Exclude<WorkspaceDetailApiResponse['settlementDateTime'], undefined>;

// MR - moved
// export enum SettlementStatusEnum {
//   Ready = 0,
//   OnSchedule = 1,
//   AtRisk = 2,
//   Locked = 3,
//   Success = 4,
//   Failed = 5
// }

export const settlementStatusMap = wordifyEnum(SettlementStatusEnum);
