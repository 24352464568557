import { SearchDashboardPageTabsEnum } from '../../models';

export interface SearchTabsItem {
  id: SearchDashboardPageTabsEnum;
  label: string;
}

export const TABS: Array<SearchTabsItem> = [
  {
    id: SearchDashboardPageTabsEnum.Workspaces,
    label: 'All' // SearchDashboardPageTabsEnum.Workspaces
  },
  {
    id: SearchDashboardPageTabsEnum.Archived,
    label: SearchDashboardPageTabsEnum.Archived
  }
];
