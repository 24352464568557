import { CreateLodgementOnlyWorkspaceSharedModel } from './models';
import { createDocumentItem } from './steps/matter-detail/helpers';

export const initialValues: CreateLodgementOnlyWorkspaceSharedModel = {
  matterDetailsStepData: {
    createdByReference: '',
    groupId: '',
    jurisdictionId: null,
    documents: [createDocumentItem()],
    titleInformation: {
      jurisdictionId: null,
      titleInputValue: '',
      titleChips: [],
      // results from additional svc calls that we need for validation to be handled purely within yup
      titleVerificationResult: undefined
    },
    isControllingParty: false,
    workspaceCreationErrorsMap: undefined
  },
  inviteParticipantsStepData: {
    documents: [],
    invitationDescription: undefined
  }
};
