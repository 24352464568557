import { AdditionalAttachmentsDocumentTypeEnum } from '@sympli-mfe/enums-2-23/wa';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// only subset of NECDS lookup options
export const ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<AdditionalAttachmentsDocumentTypeEnum>[] = [
  {
    id: AdditionalAttachmentsDocumentTypeEnum.ConsentLetter,
    name: 'Consent letter'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.CourtOrder,
    name: 'Court Order'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.Other,
    name: 'Other'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.PaidDutyCertificate,
    name: 'Paid Duty Certificate'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.StatutoryDeclaration,
    name: 'Statutory Declaration'
  },
  {
    id: AdditionalAttachmentsDocumentTypeEnum.TermsAndConditions,
    name: 'Terms and Conditions'
  }
];
