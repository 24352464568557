import * as React from 'react';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  documentName: string;
  isDeleting?: boolean;
  onNext(): Promise<void>;
  onClose(): void;
}

function DeleteDocumentDialog({ isOpen, documentName, isDeleting, children, onNext, onClose }: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  const title = <span>Delete {documentName}?</span>;

  return (
    <ConfirmationDialog //
      title={title}
      open={isOpen}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      {children}
      <WizardStepper //
        color="error"
        onBack={onClose}
        backLabel="Cancel"
        nextLabel="Delete"
        onNext={onNext}
        isLoading={isDeleting}
        disabled={isDeleting}
        showBackIcon={false}
        classes={{ errorButton: classes.errorButton }}
      />
    </ConfirmationDialog>
  );
}

export default React.memo(DeleteDocumentDialog);
