import React, { createContext, useContext } from 'react';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, EvidenceTypeEnum } from '../components/evidence/enums';
import { DeceasedTenancyPartyCapacityEnum } from '../enums';

export interface PartyCapacityLookup {
  capacity: DeceasedTenancyPartyCapacityEnum;
  evidenceTypes: LookupItemModel<EvidenceTypeEnum>[];
}

export class DeceasedTenancyContextData {
  partyCapacities: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[];
  showPartyRoleCapacityDescription?: boolean;
  disableDefaultAddedEvidenceType?: boolean;
  /**
   *
   */
  constructor(
    public partyCapacityLookups: PartyCapacityLookup[],
    showPartyRoleCapacityDescription?: boolean,
    disableDefaultAddedEvidenceType?: boolean
  ) {
    this.partyCapacities = partyCapacityLookups.map(p => ({ id: p.capacity, name: p.capacity }));
    this.disableDefaultAddedEvidenceType = disableDefaultAddedEvidenceType;
    this.showPartyRoleCapacityDescription = showPartyRoleCapacityDescription;
  }

  public GetEvidenceTypes(defaultAdded: boolean, isSelected: boolean, partyCapacity?: DeceasedTenancyPartyCapacityEnum | null): LookupItemModel<EvidenceTypeEnum>[] {
    if (!isSelected) {
      return [{ id: EvidenceTypeEnum.DeathCertificate, name: EvidenceTypeEnum.DeathCertificate }];
    }

    if (!partyCapacity || !defaultAdded) {
      return EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS;
    }

    const evidenceTypes = this.partyCapacityLookups.find(pcl => pcl.capacity === partyCapacity)!.evidenceTypes;
    return evidenceTypes;
  }
}

const DeceasedTenancyContext = createContext<DeceasedTenancyContextData>(new DeceasedTenancyContextData([]));

type DeceasedTenancyContextProviderProps = Omit<React.ComponentProps<typeof DeceasedTenancyContext.Provider>, 'value'> & {
  config: DeceasedTenancyContextData;
};

export function DeceasedTenancyContextProvider({ children, config }: DeceasedTenancyContextProviderProps): JSX.Element {
  const contextValue = React.useMemo(() => config, [config]);
  return <DeceasedTenancyContext.Provider value={contextValue}>{children}</DeceasedTenancyContext.Provider>;
}

export const useDeceasedTenancyContext = () => useContext(DeceasedTenancyContext);
