import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root() {
      return {
        padding: '48px 0 0 32px'
      };
    }
  }),
  {
    name: 'Main'
  }
);
