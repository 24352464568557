import { QldDocumentPartyJustification, QldNameChange, QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { generateTransferors } from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/helpers';
import { convertPartiesToFormModel, getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { ApiTransfer2_24_1Model, Transfer2_24_1Model } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<QldDocumentPartyJustification, QldNameChange>({
  partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG,
  nameChangeConversion: QldNameChangeConversion
});

class Converter implements IConverter<Transfer2_24_1Model, ApiTransfer2_24_1Model> {
  fromApiToFormModel(apiModel: ApiTransfer2_24_1Model, _context: IConverterContext): Transfer2_24_1Model {
    const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
    const formModel: Transfer2_24_1Model = {
      ...apiModel,
      partyBookApi: apiModel.partyBook,
      partyBook: convertPartiesToFormModel(
        { partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG, nameChangeConversion: QldNameChangeConversion },
        apiModel.partyBook,
        risInvolvedPartyIds
      )
    };

    if (apiModel.invalidParties) {
      generateTransferors(formModel, QLD_TRANSFER_PARTY_FORM_CONFIG, QldNameChangeConversion, convertPartiesToFormModel);
    }

    const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
    return formModelWithDefaultMapApplied;
  }

  fromFormToApiModel(formValues: Transfer2_24_1Model, _originalApiModel: ApiTransfer2_24_1Model): ApiTransfer2_24_1Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook,
      partyBookApi,
      mergeFailedReason: mergeFailed,
      ...rest
    } = formModel;

    PartyJustification.adjustJustification(partyBook, getLegalEntityNameQLD);

    const result: ApiTransfer2_24_1Model = {
      ...rest,
      invalidParties: false,
      partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi)
    };

    return result;
  }
}

export default new Converter();
