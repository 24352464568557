import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { PropertyDetails$LandDimensions$KnownDetailsEnum } from './enums';
import { NoticeOfAcquisitionDocumentModel } from './models';
import {
  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
  VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER,
  VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE,
  VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA,
  VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT,
  VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS,
  VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL,
  VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS,
  VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE,
  VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL
} from './visibilityChecks';

export const fallbackMap: FallbackMap<NoticeOfAcquisitionDocumentModel> = {
  titleReferences: NO_FALLBACK,
  workspaceTitleReferences: NO_FALLBACK,
  transferors: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        partyBookId: [''],
        lrPartyCapacity: NO_FALLBACK,
        sroPartyCapacity: [null],
        partyCapacityDetail: [VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL, '', ''],
        birthDate: [VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE, null],
        addressAtTimeOfTransferBookId: [''],
        correspondenceAddressBookId: [''],
        contactPhoneNumber: [''],
        emailAddress: [''],
        missingContactDetails: NO_FALLBACK // this field is used just for validation purposes
      }
    }
  },
  transferorRepresentativeDetail: {
    $children: {
      name: [''],
      contactPhoneNumber: [''],
      emailAddress: ['']
    }
  },
  transferees: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isLeadOwner: [false],
        partyBookId: [''],
        lrPartyCapacity: NO_FALLBACK,
        sroPartyCapacity: [null],
        partyCapacityDetail: [VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL, '', ''],
        shareFraction: {
          $children: {
            id: NO_FALLBACK,
            numerator: [null],
            denominator: [null]
          }
        },
        birthDate: [null],
        principalResidentialAddressBookId: [VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS, '', ''],
        addressAtTimeOfTransferBookId: [''],
        correspondenceAddressBookId: [''],
        contactPhoneNumber: [''],
        emailAddress: [''],
        missingContactDetails: NO_FALLBACK // this field is used just for validation purposes
      }
    }
  },
  arePropertiesSeparatelyRated: NO_FALLBACK,
  allTitlesAssigned: NO_FALLBACK,
  propertyDetails: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        propertyId: NO_FALLBACK,
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: NO_FALLBACK
        },
        propertyStreetAddressBookId: [''],
        salePricePerProperty: [null],
        municipalityName: [null],
        municipalityPropertyNumber: [''],
        landDimensions: {
          $children: {
            knownDetails: [PropertyDetails$LandDimensions$KnownDetailsEnum.Unknown],
            landArea: {
              $objectVisibility: [VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA, null as any],
              $children: {
                areaUnit: [null],
                quantity: [null]
              }
            },
            unitOfEntitlement: [VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT, null as any, '']
          }
        },
        landUseType: [null],
        primaryLandUse: [null],
        constructionOfMainStructure: [VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE, 3 as any, null],
        numberOfBedrooms: [VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS, null, null]
      }
    }
  },
  transactionDetail: {
    $children: {
      considerationType: [null],
      totalSalePrice: [null],
      gstAmount: [
        VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
        null
      ],
      deposit: [
        VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
        null
      ],
      businessGoodwillAmount: [
        VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
        0 as any,
        null
      ],
      businessIntellectualPropertyAmount: [
        VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
        0 as any,
        null
      ],
      goodsComponentAmount: [
        VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
        0 as any,
        null
      ],
      contractRebateAmount: [
        VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
        0 as any,
        null
      ],
      marketValue: [VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER, 0 as any, null],
      valuerName: [VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER, null as any, ''],
      valuerLicenceNumber: [VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER, null as any, ''],
      contractDate: [null],
      isTransferDateSameAsSettlementDate: [false],
      dateOfPossessionTransfer: [null],
      settlementDate: [null],
      isPurchaseTermsSale: [false]
    }
  },
  transfereeAgentSolicitorDetail: {
    $children: {
      name: [''],
      contactPhoneNumber: [''],
      emailAddress: ['']
    }
  },
  workspaceRoleId: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  elnRegistryInstrumentReference: NO_FALLBACK,
  transfereePrimaryContactPartyBookId: [null]
};
