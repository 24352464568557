import * as React from 'react';

import { Form, FormikProps, FormikValues } from 'formik';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import SettingContentTopPanel from 'src/containers/settings/components/setting-content-top-panel';
import RenderWhenReady from './RenderWhenReady';
import SettingsPageContentWrapper from './SettingsPageContentWrapper';
import styles, { ClassKeys } from './styles';

interface OwnProps<T extends FormikValues> {
  showNext?: boolean;
  formikProps: FormikProps<T>;
  showBackIcon?: boolean;
  nextLabel?: string;
  onBack?: (...args: Array<any>) => any;
  onNext?: (...args: Array<any>) => any;
  isLoading?: boolean;
  moreActionButton?: React.ReactNode;
  submitButtonWrapper?(button: JSX.Element): React.ReactNode;
  error?: string;
}

type Props<T extends FormikValues> = OwnProps<T> & WithStyles<ClassKeys>;

function isDisabled<T extends FormikValues>(props: { showNext?: boolean; formikProps: FormikProps<T> }) {
  if (!props.showNext) return true;
  const { dirty, isValid, isSubmitting } = props.formikProps;
  return !dirty || !isValid || isSubmitting;
}

function SettingsForm<T extends FormikValues>(props: React.PropsWithChildren<Props<T>>) {
  const disabled = isDisabled({ showNext: props.showNext, formikProps: props.formikProps });
  const isSubmitting = props.formikProps.isSubmitting;

  function renderTopPanel() {
    const { submitButtonWrapper, nextLabel, moreActionButton, onNext, showNext } = props;
    if (!showNext) return null;

    const button = (
      <SympliButton
        arrowRight
        isLoading={isSubmitting}
        disabled={disabled}
        color="primary"
        variant="contained"
        {...(onNext
          ? {
              type: 'button',
              onClick: onNext
            }
          : {
              type: 'submit'
            })}
      >
        {nextLabel}
      </SympliButton>
    );

    return (
      <SettingContentTopPanel>
        {submitButtonWrapper ? submitButtonWrapper(button) : button}
        {moreActionButton}
      </SettingContentTopPanel>
    );
  }

  function renderSubmitButton() {
    const { submitButtonWrapper, showBackIcon, nextLabel, onBack, onNext, showNext } = props;

    const button = (
      <WizardStepper //
        isLoading={isSubmitting}
        disabled={disabled}
        showBackIcon={showBackIcon}
        backLabel={showNext ? 'Cancel' : 'Back'}
        nextLabel={nextLabel}
        onBack={onBack}
        onNext={onNext}
        showNext={showNext}
      />
    );

    return submitButtonWrapper ? submitButtonWrapper(button) : button;
  }

  const { classes, children, isLoading, error } = props;
  return (
    <RenderWhenReady isLoading={isLoading} error={error}>
      <Form className={classes.root}>
        {renderTopPanel()}
        <SettingsPageContentWrapper>
          {children}
          {renderSubmitButton()}
        </SettingsPageContentWrapper>
      </Form>
    </RenderWhenReady>
  );
}

SettingsForm.defaultProps = {
  showNext: true,
  showBackIcon: true,
  nextLabel: 'Save changes',
  isLoading: false
};

export default withStyles(styles)(SettingsForm);
