import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { AssignmentTypeEnum, DashboardTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardInvitationTypeFilterEnum } from '../../table/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import {
  AggregatorComponentBaseProps,
  AggregatorNameEnum,
  AggregatorQuery,
  InvitationsDashboardAggregatorApiRequest,
  InvitationsDashboardAggregatorApiResponse,
  TotalCountModel
} from '../models';

const payloadResolver = (assignmentType: AssignmentTypeEnum): InvitationsDashboardAggregatorApiRequest => {
  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.totalReceived,
      invitationType: DashboardInvitationTypeFilterEnum.Received
    },
    {
      name: AggregatorNameEnum.totalSent,
      invitationType: DashboardInvitationTypeFilterEnum.Sent
    }
  ];

  const payload: InvitationsDashboardAggregatorApiRequest = {
    assignmentType,
    queries
  };

  return payload;
};

function TotalCard(props: AggregatorComponentBaseProps) {
  const defaultContent = React.useMemo<TotalCountModel>(() => ({ received: 0, sent: 0 }), []);
  const contentResolver = React.useCallback(
    (response: InvitationsDashboardAggregatorApiResponse): TotalCountModel => ({
      received: response.find(d => d.name === AggregatorNameEnum.totalReceived)?.count ?? 0,
      sent: response.find(d => d.name === AggregatorNameEnum.totalSent)?.count ?? 0
    }),
    []
  );
  return (
    <AggregatorCardWrapper //
      payloadResolver={payloadResolver}
      defaultContent={defaultContent}
      contentResolver={contentResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <>
          <AggregatorCard
            title="Total"
            cardType={DashboardTypeEnum.Invitation}
            isLoading={isLoading}
            onActionClick={onActionClick}
            isFetchingGridData={isFetchingGridData}
            items={[
              {
                id: AggregatorNameEnum.totalReceived,
                content: content.received,
                status: 'Received',
                actionText: 'Show me'
              }
            ]}
          />

          {props.assignmentType !== AssignmentTypeEnum.ReAssigned && (
            <AggregatorCard
              title=""
              cardType={DashboardTypeEnum.Invitation}
              isLoading={isLoading}
              onActionClick={onActionClick}
              isFetchingGridData={isFetchingGridData}
              items={[
                {
                  id: AggregatorNameEnum.totalSent,
                  content: content.sent,
                  status: 'Sent',
                  actionText: 'Show me'
                }
              ]}
            />
          )}
        </>
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(TotalCard);
