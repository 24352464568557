import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import { ClassNameMap } from '@mui/styles/withStyles';

import { JurisdictionsEnum, LodgementCaseStatusEnum, WorkspaceRoleEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import CopyIcon from 'src/components/copy-icon';
import LineLoader from 'src/components/loaders/line-loader';
import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import WorkspaceMenuItemStaffAssignContainer from 'src/containers/workspace/shared/detail/components/workspace-menu-item-staff-assign';
import { AllocatedUser, SettlementDateTimeModel, TitleReferenceEntityModel } from 'src/models';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';
import { resolveWorkspaceType } from 'src/models/roles';
import PartClientNames from './components/client-names';
import LodgementDateAndAwaitingTasks from './components/lodgement-date-and-awaiting-tasks';
import SettlementDateBoxNewContainer from './components/settlement-date-box-new';
import TitleAndAddressesContainer from './components/title-and-addresses';
import { ClassKeys, useStyles } from './styles';

// DOCS https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2068906002/workspace+detail+box+-+data+dependency+insights
const IS_DEV = import.meta.env.DEV;

export function PartWorkspaceType(props: {
  //
  isLoading?: boolean;
  // basic workspace info
  workspaceTypeId: WorkspaceTypeEnum;
  jurisdictionId?: JurisdictionsEnum;
  // workspace participant info
  partyNames?: string[];
  roleId?: WorkspaceRoleEnum;
  matter?: string;

  // inherited from parent
  loaderBackgroundColor: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  classes: ClassNameMap<keyof ClassKeys>;
}) {
  const { workspaceTypeId, jurisdictionId, partyNames, roleId, matter, classes, isLoading, loaderBackgroundColor } = props;

  if (isLoading) {
    return <LineLoader data-part="workspace-type" color={loaderBackgroundColor} variant="medium" widthPercent={50} style={{ marginBottom: 4 }} />;
  }

  let workspaceType: string = resolveWorkspaceType(workspaceTypeId, roleId);
  // display jurisdiction when in development mode
  if (IS_DEV && Number.isInteger(jurisdictionId)) {
    workspaceType = `${workspaceType} (${jurisdictionNameMapping[jurisdictionId!]})`;
  }

  // if clients are specified we render small text
  if (partyNames?.length) {
    return (
      <Typography
        aria-label={`Workspace type ${workspaceType}`}
        component="div"
        variant="subtitle1"
        className={classNames(classes.normalSubheading, classes.textEllipsis, classes.verticallyAlign, classes.marginTop)}
      >
        {workspaceType}
      </Typography>
    );
  }

  return (
    <Typography //
      component="div"
      variant={matter ? 'subtitle1' : 'h2'}
      className={classNames({
        [classes.subheading]: matter,
        [classes.title]: !matter,
        [classes.verticallyAlign]: true,
        [classes.textEllipsis]: true,
        [classes.marginTop]: true
      })}
    >
      {workspaceType}
    </Typography>
  );
}

function PartSympliId(props: {
  // basic workspace info
  sympliId?: string;
  // inherited from parent
  classes: ClassNameMap<keyof ClassKeys>;
}) {
  if (props.sympliId) {
    return (
      <FlexLayout //
        justifyContent="flex-start"
        flexDirection="row"
        className={props.classes.sectionSpacingTop}
        aria-label={`Sympli ID ${props.sympliId}`}
        data-testid="workspace-sympli-id"
      >
        <Typography variant="body2_bold">Sympli ID:&nbsp;</Typography>
        <Typography variant="body2">{props.sympliId}</Typography>
        <CopyIcon className="pl-[4px]" title="Sympli ID" textToCopy={props.sympliId} />
      </FlexLayout>
    );
  }
  return null;
}

export interface WorkspaceDetailBoxProps {
  // route props
  workspaceId: string;
  participantId: string;

  isLoadingWorkspaceParticipantData: boolean;
  isLoadingFullWorkspaceData: boolean;
  isLoadingWorkspaceAssignableGroupsData: boolean;
  isLoadingSettlementDetailsData: boolean;

  loaderBackgroundColor?: 'white' | 'grey';
  onUpdateLodgementDate?(): void; // * Only applicable to lodgement only workspace
  panelPosition?: 'left' | 'right';
  disableSettlementDateLink?: boolean;

  showForwardedComment?: boolean;
  skipFetchingWorkspaceTasks?: boolean;
  hideMatterIdOnAwaitingTask?: boolean;
  disableTitleClickNavigation?: boolean;
  hideAssignedStaff?: boolean;
  workspaceDetail: {
    // basic workspace info
    sympliId?: string;
    workspaceTypeId?: WorkspaceTypeEnum;
    workspaceStatusId?: WorkspaceStatusEnum;
    lodgementCaseStatusId?: LodgementCaseStatusEnum;
    settlementDate?: SettlementDateTimeModel; // only available for non lodgement workspaces
    expectedLodgementDate?: WorkspaceDateTimeModel; // only available for lodgement workspaces
    isLocked?: boolean;
    jurisdictionId?: JurisdictionsEnum;

    // full workspace info
    propertyAddress?: string;
    titleReferences?: TitleReferenceEntityModel[];
  };
  currentParticipant: {
    // workspace participant info
    roleId?: WorkspaceRoleEnum;
    groupId?: string;
    allocatedUser?: AllocatedUser;
    matter?: string;
    partyNames?: string[];
  };

  settlementDetails: {
    // workspace settlement info
    proposedSettlementDate?: string;
    currentSettlementDate?: string;
  };
  // workspace assignable groups info
  assignedWorkspaceGroupName?: string;

  // only relevant for settlement-date page
  portalIdForSettlementDate?: string;

  linkedWorkspaces: LinkedWorkspaceApiResponseModel[];

  onUpdateAssignee: (workspaceId: string, participantId: string, allocatedUser: AllocatedUser) => void;
}

const WorkspaceDetailBox = (props: React.PropsWithChildren<WorkspaceDetailBoxProps>) => {
  const classes = useStyles();

  const {
    // route params
    workspaceId,
    participantId,
    //
    isLoadingWorkspaceParticipantData,
    isLoadingFullWorkspaceData,
    isLoadingSettlementDetailsData,
    loaderBackgroundColor = 'grey',
    panelPosition = 'left',
    disableSettlementDateLink = false,
    skipFetchingWorkspaceTasks = false,
    hideMatterIdOnAwaitingTask = false,
    // basic workspace info
    workspaceDetail: {
      //
      sympliId,
      workspaceTypeId,
      workspaceStatusId,
      settlementDate,
      expectedLodgementDate,
      isLocked,
      jurisdictionId,
      // full workspace info
      propertyAddress,
      titleReferences
    },
    // current participant info
    currentParticipant: {
      //
      roleId,
      groupId,
      allocatedUser,
      matter,
      partyNames
    },
    // workspace assignable groups info
    assignedWorkspaceGroupName,
    //
    //
    children,
    portalIdForSettlementDate,
    linkedWorkspaces,
    onUpdateAssignee
  } = props;

  function renderDetailsWorkspace101() {
    return (
      <div>
        {workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly && renderWorkspaceType()}
        {renderClientNames()}
        {renderTitleAndAddresses()}
        {panelPosition === 'left' && renderExpectedLodgementDateAndAwaitingTasks()}
        {renderSettlementDateWorkspaces101()}
        {renderSympliId()}
        {panelPosition === 'right' && renderExpectedLodgementDateAndAwaitingTasks()}
        {renderAssignedStaff()}
        {children}
      </div>
    );
  }

  function renderExpectedLodgementDateAndAwaitingTasks() {
    return (
      <LodgementDateAndAwaitingTasks //
        isLoading={isLoadingWorkspaceParticipantData}
        // basic workspace info
        workspaceTypeId={workspaceTypeId!}
        workspaceStatusId={workspaceStatusId!}
        expectedLodgementDate={expectedLodgementDate}
        isLocked={isLocked!}
        // workspace participant info
        roleId={roleId}
        matter={matter}
        // inherited from parent
        workspaceId={workspaceId}
        participantId={participantId}
        loaderBackgroundColor={loaderBackgroundColor}
        hideMatterIdOnAwaitingTask={hideMatterIdOnAwaitingTask}
        skipFetchingWorkspaceTasks={skipFetchingWorkspaceTasks}
      />
    );
  }

  function renderClientNames() {
    return (
      <PartClientNames //
        className={classNames(workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly && classes.clientsSectionSpacingTop)}
        isLoading={isLoadingWorkspaceParticipantData}
        // workspace participant info
        partyNames={partyNames}
        // inherited from parent
        loaderBackgroundColor={loaderBackgroundColor}
      />
    );
  }

  function renderWorkspaceType() {
    return (
      <PartWorkspaceType //
        isLoading={isLoadingWorkspaceParticipantData}
        // basic workspace info
        jurisdictionId={jurisdictionId}
        workspaceTypeId={workspaceTypeId!}
        // workspace participant info
        partyNames={partyNames}
        roleId={roleId}
        matter={matter}
        // inherited from parent
        classes={classes}
        loaderBackgroundColor={loaderBackgroundColor}
      />
    );
  }

  function renderTitleAndAddresses() {
    return (
      <TitleAndAddressesContainer //
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        //
        isLoading={isLoadingFullWorkspaceData}
        // basic workspace info
        jurisdictionId={jurisdictionId!}
        workspaceType={workspaceTypeId!}
        workspaceStatusId={workspaceStatusId!}
        workspaceIsLocked={isLocked!}
        // full workspace info
        propertyAddress={propertyAddress}
        titleReferences={titleReferences ?? []}
        // current participant info
        participantRoleId={roleId}
        // TODO explain this
        loaderBackgroundColor={loaderBackgroundColor}
        linkedWorkspaces={linkedWorkspaces}
      />
    );
  }

  function renderSettlementDateWorkspaces101() {
    return (
      <SettlementDateBoxNewContainer
        isLoading={isLoadingSettlementDetailsData}
        // basic workspace info
        workspaceTypeId={workspaceTypeId}
        workspaceStatusId={workspaceStatusId}
        settlementDate={settlementDate}
        isLocked={isLocked}
        // inherited from parent
        workspaceId={workspaceId}
        participantId={participantId}
        disableSettlementDateLink={disableSettlementDateLink}
        loaderBackgroundColor={loaderBackgroundColor}
        portalIdForSettlementDate={portalIdForSettlementDate}
      />
    );
  }

  function renderSympliId() {
    return (
      <PartSympliId
        // basic workspace info
        sympliId={sympliId}
        // inherited from parent
        classes={classes}
      />
    );
  }

  function renderAssignedStaff() {
    return (
      <WorkspaceMenuItemStaffAssignContainer //
        isLoading={isLoadingWorkspaceParticipantData}
        workspaceId={workspaceId}
        participantId={participantId}
        // basic workspace info
        workspaceTypeId={workspaceTypeId}
        workspaceStatusId={workspaceStatusId!}
        // current participant info
        groupId={groupId}
        assignee={allocatedUser}
        onUpdateAssignee={onUpdateAssignee}
        assignedGroupName={assignedWorkspaceGroupName}
      />
    );
  }

  return renderDetailsWorkspace101();
};

export default React.memo(WorkspaceDetailBox);
