import * as yup from 'yup';

import { Mortgage2_24_0Model } from './models';
import yupMortgageeDetails from './sections/mortgagee-details/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupTermsAndConditions from './sections/terms-and-conditions/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<Mortgage2_24_0Model>({
    titleReferences: yupTitleReferences,
    mortgageeDetails: yupMortgageeDetails,
    termsAndConditions: yupTermsAndConditions,
    isFinancialWorkspace: yup.boolean().defined(),
    mortgagors: yupMortgagors
  })
  .defined()
  .log();
