import * as React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface Props {
  maxNameLength: number;
  reportNameField: string;
  disabled?: boolean;
}

function ReportName({ maxNameLength, reportNameField, disabled }: Props) {
  const classes = useStyles();
  const [, { value: reportName }] = useField<string>(reportNameField);

  return (
    <Field //
      label={
        <FlexLayout justifyContent="space-between">
          <span>Report name</span>
          <span className={classNames(reportName.trim().length <= maxNameLength && classes.validationText)}>
            {reportName.trim().length}/{maxNameLength} Characters
          </span>
        </FlexLayout>
      }
      disabled={disabled}
      name={reportNameField}
      component={InputField}
      fullWidth
    />
  );
}

export default React.memo(ReportName);
