import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    toggleButton: {
      color: colors.NEUTRAL_700,
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      border: 'none',

      '&.Mui-selected': {
        color: colors.NEUTRAL_1000,
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '16px',
        borderRadius: 80,
        background: `${colors.WHITE} !important`,
        boxShadow: '0px 2px 2px 0px rgba(27, 127, 121, 0.15) inset'
      },
      '&:hover': {
        color: colors.NEUTRAL_1000,
        background: 'unset',
        fontWeight: 700
      },

      '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        borderRadius: 80
      },
      '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderRadius: 80
      }
    }
  }),
  {
    name: 'Overview'
  }
);
