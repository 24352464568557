import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    description: {
      fontSize: 13,
      paddingLeft: 6
    },
    fieldset: {
      border: 0,
      padding: 0,
      '&[disabled]': {
        opacity: 0.5
      }
    },
    divider: {
      marginTop: 14,
      marginBottom: 18
    }
  });

export type ClassKeys = typeof styles;

export default styles;
