import * as yup from 'yup';

import { $eq, $exactlyOneOfItems, $get, $items } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { CurrentPriorityNoticeModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache

// path: priorityNotices
// If you want to use memoize, wrap your yup definition in memoizeSchema()
const yupPriorityNotices = yup
  .array()
  .required(msg.REQUIRED)
  .test('required', 'A priority notice must be selected', (value: CurrentPriorityNoticeModel[]) => {
    return value?.some(element => element.isSelected);
  })
  .test(
    testContextualRule({
      /**
           * what we found in schema:
           [{"required":{"priorityNotices":{"$items":{"$exactlyOne":{"isSelected":true}}}},"message":"Only one priority notice can be withdrawn at a time"}]
           *
           */
      uniqueTestName: '"priorityNotices" contextual validation rule #1',
      requirement: (priorityNotices: CurrentPriorityNoticeModel[], context: object): boolean =>
        $exactlyOneOfItems($items(context, 'priorityNotices'), item => $eq($get(item, 'isSelected'), true)),
      message: 'Only one priority notice can be withdrawn at a time'
    })
  )
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .of(
    yup
      .object<CurrentPriorityNoticeModel>()
      .nullable()
      .shape({
        isSelected: yup.boolean().required(msg.REQUIRED)
      })
  );

export default yupPriorityNotices;
