import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import DeceasedJointTenants from '../../components/deceased-joint-tenants';
import { DeceasedJointTenantsModel, NoticeOfDeath2_21_1Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  className?: string;
}

const fieldName = modelKey<NoticeOfDeath2_21_1Model>();

function SectionDeceasedJointTenants({ name }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();

  const [, { value: items }] = useField<DeceasedJointTenantsModel[]>(name);

  const hasMultipleGroups = items.length > 1;
  const [value, setValue] = React.useState('partyId');

  const handleOnRadioButtonChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  }, []);
  const renderItem = useCallback(
    ({ itemBinding, itemIndex }: DocumentArrayItemRenderProps<DeceasedJointTenantsModel>) => {
      const numberOfPartiesInPreceedingGroups = items.slice(0, itemIndex).flatMap(group => group.deceasedProprietors ?? []).length;
      const isFirstGroup = itemIndex === 0;
      return (
        <SectionLike //
          className={classNames(isFirstGroup && classes.deceasedGroupFirst, classes.marginBottomIfNotLast)}
          key={itemBinding}
        >
          <div key={itemBinding} className={classes.bottomDivider}>
            <DeceasedJointTenants //
              name={itemBinding}
              disabled={disabled}
              numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
            />
          </div>
        </SectionLike>
      );
    },
    [classes.bottomDivider, classes.deceasedGroupFirst, classes.marginBottomIfNotLast, disabled, items]
  );

  const sectionErrorMessage = getFormikError(formikProps, fieldName('deceasedJointTenantsValidation'), true);
  const pluraliseSectionTitle = hasMultipleGroups || (items.length === 1 && items[0].deceasedProprietors.length > 1);
  const sectionTitle = `${pluraliseSectionTitle ? 'Deceased joint tenants' : 'Deceased joint tenant'}`;

  return (
    <div data-section={fieldName('deceasedJointTenantsValidation')} data-testid={fieldName('deceasedJointTenantsValidation')}>
      <Section //
        error={sectionErrorMessage}
        title={sectionTitle}
        data-name={fieldName('deceasedJointTenantsValidation')}
        className={classNames(items.length && classes.section)}
      >
        {!items.length || !items[0].deceasedProprietors.length ? (
          <Typography className={classes.noDeceasedGroupsMessage}>There are no deceased registered parties to display. Please ensure that a title is selected.</Typography>
        ) : (
          <RadioGroup //
            aria-label={'Deceased Joint Tenants'}
            name={fieldName('deceasedJointTenants')}
            value={value}
            onChange={handleOnRadioButtonChange}
          >
            <DocumentFieldArray //
              arrayBinding={name}
              renderItem={renderItem}
              disabled={disabled}
              itemStyle="none"
              mode="fixed"
            />
          </RadioGroup>
        )}
      </Section>
    </div>
  );
}

export default React.memo(SectionDeceasedJointTenants);
