import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchMergedDocumentsPdf } from '../actions';

export interface DocumentMergedPdfState {
  detail?: {
    pdfUrl: string;
  };
  status: ApiStatus;
  error?: string;
}

const initialState: DocumentMergedPdfState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const documentMergedPdfReducer = createReducer<
  //
  DocumentMergedPdfState,
  Action
>(initialState)
  .handleAction(
    actionFetchMergedDocumentsPdf.request,
    (state, action): DocumentMergedPdfState => ({
      ...initialState,
      error: undefined,
      status: 'pending'
    })
  )
  .handleAction(
    actionFetchMergedDocumentsPdf.success,
    (state, action): DocumentMergedPdfState => ({
      ...initialState,
      detail: {
        pdfUrl: action.payload
      },
      status: 'resolved'
    })
  )
  .handleAction(
    actionFetchMergedDocumentsPdf.failure,
    (state, action): DocumentMergedPdfState => ({
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    })
  );

export default documentMergedPdfReducer;
