import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';

// path: mortgageeDetails
const yupMortgagee = resolveMemoizedTenancyValidation({
  isAddressRequired: false,
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG
});

export default yupMortgagee;
