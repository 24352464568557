import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...dataBindingHelper()
    },
    quarterWidth: {
      width: '25%'
    },
    sameAsSettlementDate: {
      marginBottom: 4
    },
    radioFormControlLabel: {
      flexGrow: 0
    },
    totalSalePriceHelperTextError: {
      width: 330
    },
    currencyGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: theme.spacing(1.25)
    }
  }),
  {
    name: 'DetailsOfTransaction'
  }
);
