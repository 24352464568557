import React, { useEffect, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getCharacterCountHelperText, modelKey, resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { InputClassKeys, InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Subform from '@sympli/ui-framework/components/formik/subform';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { ADDITIONAL_COVENANT_WARNING_VALUES } from '../../helpers';
import { IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS, IS_REQUIRED_CHECK_DEALING_NUMBER } from '../../isRequiredChecks';
import { StandardTermsModel } from '../../models';
import { useStyles } from './styles';
import { ADDITIONAL_COVENANT_LIMIT } from './validationSchema';

interface Props {
  name: string;
}

function resolveAdditionalCovenantsHelper(
  additionalCovenants: string,
  classes: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>>
): Pick<InputComponentProps, 'classes' | 'helperText'> {
  const additionalCovenantsCount = (additionalCovenants ?? '').trim().length;
  const helperText = getCharacterCountHelperText(additionalCovenantsCount, ADDITIONAL_COVENANT_LIMIT);
  if (ADDITIONAL_COVENANT_WARNING_VALUES.some(warningValue => ~additionalCovenants.indexOf(warningValue))) {
    return {
      helperText: `Please be aware that certain words within the terms and conditions are used for specific purposes only e.g. Trust, Trustee, Beneficial, Beneficiary. You may proceed to lodgement, however, there may be delays in registration. ${helperText}`,
      classes
    };
  }
  return {
    helperText
  };
}

const fieldName = modelKey<StandardTermsModel>();

function TermsAndConditions(): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<StandardTermsModel>();

  const [showDealingNumberTooltip, setShowDealingNumberTooltip] = useState(false);
  const dealingNumber = values.dealingNumber?.trim();
  const additionalCovenants = values.additionalCovenants.trim().toLowerCase();
  const subscriberDefaultDealingNumber = values.subscriberDefaultDealingNumber?.trim();
  const isDealingNumberRequired = IS_REQUIRED_CHECK_DEALING_NUMBER(values);
  const isAdditionalCovenantsRequired = IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS(values);

  useEffect(() => {
    if (!dealingNumber || dealingNumber === subscriberDefaultDealingNumber) {
      setShowDealingNumberTooltip(false);
    } else {
      setShowDealingNumberTooltip(true);
    }
  }, [dealingNumber, subscriberDefaultDealingNumber]);

  const options: string[] = useMemo(() => {
    if (!subscriberDefaultDealingNumber) {
      return [];
    }
    return [subscriberDefaultDealingNumber];
  }, [subscriberDefaultDealingNumber]);

  const dealingNumberFormTip =
    showDealingNumberTooltip || !subscriberDefaultDealingNumber
      ? 'This document reference will only be used in this workspace. Please contact your Super Admin to have this added to the default list.'
      : undefined;
  const dealingNumberPlaceholder = subscriberDefaultDealingNumber ? resolveComboboxPlaceholder(isDealingNumberRequired) : resolvePlaceholder(isDealingNumberRequired);

  const acClasses: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControl: classes.additionalCovenantWarningBorder,
      formControlValidation: classes.additionalCovenantWarningHelperText
    };
  }, [classes]);

  const fieldClasses: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControlValidation: classes.formControlValidation
    };
  }, [classes]);

  return (
    <div className={classes.root}>
      <FormGroup //
        title="MCP Number"
        fieldAreaDirection="column"
        formTip={dealingNumberFormTip}
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('dealingNumber')}
            component={SympliAutocompleteField}
            placeholder={dealingNumberPlaceholder}
            type="combobox"
            options={options}
            disabled={disabled}
          />
        )}
      </FormGroup>
      <FormGroup //
        title="Additional terms and conditions"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('additionalCovenants')}
            component={InputField}
            classes={fieldClasses}
            className={classes.noBottomMargin}
            fullWidth
            multiline
            minRows={2}
            disabled={disabled}
            placeholder={resolvePlaceholder(isAdditionalCovenantsRequired)}
            {...resolveAdditionalCovenantsHelper(additionalCovenants, acClasses)}
          />
        )}
      </FormGroup>
      <FormGroup //
        title="Mortgage date (optional)"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('mortgageDate')}
            component={DatePickerField}
            disabled={disabled}
            disableFuture
            className={classes.quarterWidth}
          />
        )}
      </FormGroup>
    </div>
  );
}

const MemoizedTermsAndConditions = React.memo(TermsAndConditions);

export default ({ name }: Props) => {
  return (
    <Subform //
      subformBindingPath={name}
      component={MemoizedTermsAndConditions}
    />
  );
};
