import { CaveatParty } from '../../models';

export const resolveDefaultItem = () => {
  const defaultItem: CaveatParty = {
    // custom attributes to support address/client book
    partyBookId: null, // reference to ClientNameStore
    addressBookId: null // reference to AddressStore;
  };

  return defaultItem;
};
