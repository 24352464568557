export enum LodgementOnlyWorkspaceStatusEnum {
  InPreparation = 'IN PREPARATION',
  Ready = 'READY',
  Queued = 'QUEUED',
  Lodging = 'LODGING',
  Lodged = 'LODGED',
  Completed = 'COMPLETED',
  Abandoned = 'ABANDONED',
  Archived = 'ARCHIVED'
}
export const LodgementOnlyWorkspaceSteps: string[] = [
  LodgementOnlyWorkspaceStatusEnum.InPreparation,
  LodgementOnlyWorkspaceStatusEnum.Ready,
  LodgementOnlyWorkspaceStatusEnum.Lodging,
  LodgementOnlyWorkspaceStatusEnum.Lodged,
  LodgementOnlyWorkspaceStatusEnum.Completed
];

export const LodgementOnlyQueuedWorkspaceSteps: string[] = [
  LodgementOnlyWorkspaceStatusEnum.InPreparation,
  LodgementOnlyWorkspaceStatusEnum.Queued,
  LodgementOnlyWorkspaceStatusEnum.Lodging,
  LodgementOnlyWorkspaceStatusEnum.Lodged,
  LodgementOnlyWorkspaceStatusEnum.Completed
];
