import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { Form24B_2_24_1Model, TransactionDataModel } from '../../models';
import { VISIBILITY_CHECK_ARE_PARTIES_RELATED, VISIBILITY_CHECK_HAS_AGREEMENT, VISIBILITY_CHECK_IS_GST_PAYABLE } from '../../visibilityCheck';

export const yupTransactionData = yup
  .object<TransactionDataModel>({
    hasAgreement: yup.boolean().required(msg.REQUIRED),
    agreementDate: validateWhenVisible2<Date>({
      validationWhenVisible: yup.date().nullable().typeError(msg.INVALID_DATE).required(msg.REQUIRED),
      visibilityCheck: (_transaction: TransactionDataModel, context: Form24B_2_24_1Model) => VISIBILITY_CHECK_HAS_AGREEMENT(context)
    }),
    arePartiesRelated: yup.boolean().required(msg.REQUIRED),
    relatedDegree: validateWhenVisible2<string>({
      validationWhenVisible: yup.string().nullable().required(msg.REQUIRED).max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30)),
      visibilityCheck: (_transaction: TransactionDataModel, context: Form24B_2_24_1Model) => VISIBILITY_CHECK_ARE_PARTIES_RELATED(context)
    }),
    isConsiderationLess: yup.boolean().required(msg.REQUIRED),
    isOtherDutiable: yup.boolean().required(msg.REQUIRED),
    isGstPayable: yup.boolean().required(msg.REQUIRED),
    isMarginScheme: validateWhenVisible2<boolean>({
      validationWhenVisible: yup.boolean().nullable().required(msg.REQUIRED),
      visibilityCheck: (_transaction: TransactionDataModel, context: Form24B_2_24_1Model) => VISIBILITY_CHECK_IS_GST_PAYABLE(context)
    }),
    isTransferorNonAus: yup.boolean().required(msg.REQUIRED)
  })
  .defined();
