import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchAutoJustificationDetail } from '../actions/autoJustification';
import { AutoJustificationDetailModel } from '../auto-justifications/detail/models';

export interface AutoJustificationDetailState {
  detail?: AutoJustificationDetailModel;
  status: ApiStatus;
  error?: string;
}

const initialState: AutoJustificationDetailState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  AutoJustificationDetailState,
  Action
>(initialState)
  .handleAction(actionFetchAutoJustificationDetail.request, (state): AutoJustificationDetailState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchAutoJustificationDetail.success, (state, action): AutoJustificationDetailState => {
    return {
      ...state,
      detail: {
        justificationDetail: action.payload.data[0],
        documentTree: action.payload.data[1],
        organisationProfile: action.payload.data[2]
      },
      status: 'resolved'
    };
  })
  .handleAction(actionFetchAutoJustificationDetail.failure, (state, action): AutoJustificationDetailState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
