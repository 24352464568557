import { $allOfItems, $eq, $get, $items, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { getCaveatsBeingWithdrawn, getSelectedCaveats, getSelectedTitleReferences } from './helper';
import { BaseTitleReferenceModel, WithdrawalOfCaveat2_25_0Model } from './models';

// this file was automatically generated from checks.ts.mustache
export const VISIBILITY_CHECK_WITHDRAW_CAVEAT = createContextCheck((context: WithdrawalOfCaveat2_25_0Model): boolean => {
  // skip this validation if no titles are selected
  const selectedTitles = getSelectedTitleReferences(context.titleReferences);
  if (!selectedTitles.length) {
    return false;
  }

  // verify that caveats share some common title reference
  return getCaveatsBeingWithdrawn(context.caveats, selectedTitles).length > 0;
});

export const VISIBILITY_CHECK_CAVEATORS = createContextCheck((context: WithdrawalOfCaveat2_25_0Model): boolean => {
  const selectedCaveats = getSelectedCaveats(context.withdrawCaveats);
  return selectedCaveats.length > 0;
});

/**
 path:
 - caveats[*].titleReferences[*].partDescription

 schema version:
[
  {
    "isPartLandAffected": true,
    "landDescriptions": {
      "$items": {
        "$allOf": {
          "isSelected": false
        }
      }
    }
  }
]
*/
export const VISIBILITY_CHECK_CAVEATS$TITLE_REFERENCES$PART_DESCRIPTION = createContextCheck(
  (context: BaseTitleReferenceModel): boolean =>
    $eq($get(context, 'isPartLandAffected'), true) && $allOfItems($items(context, 'landDescriptions'), item => $eq($get(item, 'isSelected'), false))
);
