import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiWithdrawalOfNomination_2_18_1_Model, WithdrawalOfNomination_2_18_1_Model } from './models';

class Converter implements IConverter<WithdrawalOfNomination_2_18_1_Model, ApiWithdrawalOfNomination_2_18_1_Model> {
  fromApiToFormModel(apiModel: ApiWithdrawalOfNomination_2_18_1_Model, context: IConverterContext): WithdrawalOfNomination_2_18_1_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: WithdrawalOfNomination_2_18_1_Model, _originalApiModel: ApiWithdrawalOfNomination_2_18_1_Model): ApiWithdrawalOfNomination_2_18_1_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
