import { JurisdictionsEnum, UserTimeZoneEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';

// * timezone option string for 'date-fns-timezone'
const UserTimezoneMapping: Partial<Record<UserTimeZoneEnum, string>> = {
  [UserTimeZoneEnum.WA]: 'Australia/Perth', // UTC +8 AWST
  [UserTimeZoneEnum.SA]: 'Australia/Adelaide', // UTC +9:30 ACST
  [UserTimeZoneEnum.NSW]: 'Australia/Sydney', // UTC +10 AEST
  [UserTimeZoneEnum.QLD]: 'Australia/Brisbane', // UTC +10 AEST
  [UserTimeZoneEnum.VIC]: 'Australia/Melbourne', // UTC +10 AEST
  [UserTimeZoneEnum.IND_IST]: 'Asia/Kolkata' // UTC +5.5 IST
};

const AU_TIMEZONE_OPTIONS: LookupEnumModel<keyof typeof UserTimeZoneEnum>[] = [
  { name: 'NSW - Australian Eastern Standard Time', id: 'NSW' },
  { name: 'VIC - Australian Eastern Standard Time', id: 'VIC' },
  { name: 'TAS - Australian Eastern Standard Time', id: 'TAS' },
  { name: 'QLD - Australian Eastern Standard Time', id: 'QLD' },
  { name: 'ACT - Australian Eastern Standard Time', id: 'ACT' },
  { name: 'NT - Australian Central Standard Time', id: 'NT' },
  { name: 'SA - Australian Central Standard Time', id: 'SA' },
  { name: 'WA - Australian Western Standard Time', id: 'WA' }
];

const FOREIGN_TIMEZONE_OPTIONS: LookupEnumModel<keyof typeof UserTimeZoneEnum>[] = [{ name: 'IND - Indian Standard Time', id: 'IND_IST' }];

export const useSupportedTimeZoneOptions = (selectedTimezoneId?: string): LookupEnumModel<keyof typeof UserTimeZoneEnum>[] => {
  const showForeignTimeZoneInUserProfile = useFeatureFlag(FeatureToggleEnum.showForeignTimeZoneInUserProfile);
  const result = showForeignTimeZoneInUserProfile
    ? AU_TIMEZONE_OPTIONS.concat(FOREIGN_TIMEZONE_OPTIONS)
    : AU_TIMEZONE_OPTIONS.concat(FOREIGN_TIMEZONE_OPTIONS.filter(timezone => timezone.id === selectedTimezoneId));
  return result;
};

export function getTimeInTimeZone(date: Date, jurisdictionId: UserTimeZoneEnum | JurisdictionsEnum | null) {
  if (!Number.isInteger(jurisdictionId)) {
    return date;
  }
  const timeZone = UserTimezoneMapping[jurisdictionId as UserTimeZoneEnum];
  if (timeZone == null) {
    return date;
  }
  return new Date(date.toLocaleString('en-US', { timeZone }));
}

const stdTimezoneOffset = (date: Date): number => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (date: Date): boolean => {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
};

// * this is to find if Daylight Saving Time (DST) is observed in AU eastern states on selected date
export const isDstObservedInNSW = (date: Date): boolean => {
  const dateInNSW = getTimeInTimeZone(date, UserTimeZoneEnum.NSW);
  return isDstObserved(dateInNSW);
};
