import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

export default function getValidationSchema() {
  return yup.object({
    transactionId: yup
      .string()
      .required(msg.REQUIRED)
      .max(10, msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(10))
      .matches(/^\d*[1-9]+\d*$/, 'Invalid DAN'),
    transactionIdPostfix: yup
      .string()
      .required(msg.REQUIRED)
      .min(3, msg.LENGTH_MUST_BE_AT_LEAST_NUMBERS(3))
      .max(3, msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(3))
      .matches(/^\d*[1-9]+\d*$/, 'Invalid suffix')
  });
}
