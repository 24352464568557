import React from 'react';

import { differenceInMilliseconds } from 'date-fns';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
/* eslint-disable no-restricted-imports */
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { WorkspaceStatusColorVariantEnum } from './components/models';
import Status from './components/Status';

export interface Props {
  steps: Array<string>;
  currentStep: number;
  variant: WorkspaceStatusColorVariantEnum;
  customizedStep?: string;
  sxWrapper?: SxProps<Theme>;
  sxIcons?: SxProps<Theme>;
  sxTitle?: SxProps<Theme>;
  tooltipTitle: NonNullable<React.ReactNode>;
  tooltipOtherProps?: Omit<MuiTooltipProps, 'title' | 'children'>;
  dataTestId?: string; // mainly for automation test and unit tests
}

function StatusProgress({ steps, currentStep, variant, customizedStep, tooltipTitle, sxWrapper, sxTitle, sxIcons, tooltipOtherProps, dataTestId }: Props) {
  const text = customizedStep ? customizedStep : steps[currentStep - 1];

  const openTimeRef = React.useRef<Date | null>(null);

  const handleOnClose = () => {
    const now = new Date();
    const ms: number = differenceInMilliseconds(now, openTimeRef.current || now);
    const seconds = ms / 1000;
    if (seconds >= 1) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'variant-progress',
        logGroupId: 'workspace',
        text,
        variant: variant,
        seconds: ms / 1000
      });
    }
  };

  const handleOnOpen = () => {
    openTimeRef.current = new Date();
  };

  return (
    <Box
      data-testid={dataTestId}
      sx={[
        { display: 'flex', flexDirection: 'column' },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sxWrapper) ? sxWrapper : [sxWrapper])
      ]}
      // Needs to stop event propagation e.g MUI-Accordion expanding/collapsing
      onClick={e => e.stopPropagation()}
    >
      <Tooltip title={tooltipTitle} {...tooltipOtherProps} onClose={handleOnClose} onOpen={handleOnOpen} tooltipType="document">
        <Typography
          data-testid={`${dataTestId}-text`}
          variant="body4"
          sx={[
            // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
            ...(Array.isArray(sxTitle) ? sxTitle : [sxTitle])
          ]}
        >
          {text}
        </Typography>
      </Tooltip>
      <Box
        sx={[
          { display: 'flex', gap: '3px' },
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sxIcons) ? sxIcons : [sxIcons])
        ]}
      >
        {steps.map((step, index) => {
          if (index < currentStep) {
            return <Status variant={variant} key={index} />;
          }
          return <Status variant={WorkspaceStatusColorVariantEnum.Inactive} key={index} />;
        })}
      </Box>
    </Box>
  );
}

export default React.memo(StatusProgress);
