import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import { colors } from 'src/theme';

interface Props {
  currentStep: number;
  steps: Array<string>;
  title: JSX.Element;
  body: JSX.Element;
  body2?: JSX.Element;
}

function FssSectionTooltip(props: Props) {
  const { steps, currentStep, title, body, body2 } = props;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        {body}
      </Typography>
      {body2 && (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
          {body2}
        </Typography>
      )}
      <Breadcrumbs //
        currentStep={currentStep}
        steps={steps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

export default React.memo(FssSectionTooltip);
