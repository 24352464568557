import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginLeft: -18
    },
    rootV2: {
      position: 'relative'
    },
    content: {
      paddingLeft: 18,
      width: 'auto'
    },
    formGroupTitle: {
      fontWeight: 'bold'
    },
    formSubmitButtonContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },

    largeField: {
      width: 510
    },
    smallField: {
      width: 120
    },

    marginRight: {
      marginRight: 10
    },
    buttonMarginRight: {
      marginRight: 20
    },

    timestamp: {
      marginRight: 'auto'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
