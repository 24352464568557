export enum AppEnvironmentEnum {
  dev = 'dev1',
  sit = 'sit1',
  feat = 'feat1',
  iwt = 'iwt',
  pp = 'pp',
  production = 'production',
  test4 = 'test4',
  local = 'localhost'
}

export const emailPattern = /@(dev1.|feat1.|sit.|iwt.|pp.|test4.)?sympli.com.au$/i;
