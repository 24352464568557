export enum WeekdayEnum {
  Su = 0,
  Mo = 1,
  Tu = 2,
  We = 3,
  Th = 4,
  Fr = 5,
  Sa = 6
}

export const WeekdayEnumEnumMap: Record<WeekdayEnum, string> = {
  [WeekdayEnum.Su]: 'Su',
  [WeekdayEnum.Mo]: 'Mo',
  [WeekdayEnum.Tu]: 'Tu',
  [WeekdayEnum.We]: 'We',
  [WeekdayEnum.Th]: 'Th',
  [WeekdayEnum.Fr]: 'Fr',
  [WeekdayEnum.Sa]: 'Sa'
};
