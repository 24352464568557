import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    checkboxLabelRoot: {
      // The Tickle-UI Framework checbox is not prviding any means of overriding Material UI checkbox's classes,
      // so we have to drill down into it using the FormControlLabel's classes.
      '& > span:first-child': {
        height: 'unset'
      }
    },
    jointTenantCheckbox: {
      paddingLeft: 20,
      paddingTop: 16,
      marginBottom: 5,
      fontWeight: 'bold'
    },
    jointTenantsRelinquishingRoleText: {
      fontSize: 14
    },
    section: {
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      background: 'none'
    },
    radioButtonRoot: {
      alignItems: 'flex-start',
      paddingBottom: 12
    },
    radioButtonLabel: {
      paddingTop: 6
    }
  }),
  {
    name: 'DeceasedProprietorReadonlyArray'
  }
);
