import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { resolveMemoizedTenancyEditableValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing/validation-schema';

import { checkSelectedTitlesHaveSameMortgagors } from '../../helper';
import { Mortgage2_24_1Model } from '../../models';

const yupMortgagors = resolveMemoizedTenancyEditableValidation({
  isAddressRequired: false,
  partyCapacityDetailMaxLength: 300,
  partyFormConfig: QLD_PARTY_FORM_CONFIG
}).testContextualRule({
  uniqueTestName: 'Mortgagors name mismatch test',
  message: 'Please be aware that the parties on the selected titles presently do not match.',
  requirement: ({ partyBook, titleReferences }: Mortgage2_24_1Model) => {
    return checkSelectedTitlesHaveSameMortgagors(partyBook, titleReferences);
  }
});

export default yupMortgagors;
