import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import useRouterParams from 'src/hooks/useRouterParams';
import { RouteParams } from '../../models';
import DocumentItemWithAction, { Props } from './DocumentItemWithAction';

function DocumentItemWithActionContainer(props: Omit<Props, 'variant'>) {
  const { workspaceId, participantId } = useRouterParams<RouteParams>();
  const mode = useWorkspaceDocuments(workspaceId, participantId).mode;
  return <DocumentItemWithAction {...props} variant={mode} />;
}

export default DocumentItemWithActionContainer;
