import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { LodgementInstructions2_21_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<LodgementInstructions2_21_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  documentFees: NO_FALLBACK,
  lodgementCaseFeeDetail: NO_FALLBACK,
  lodgementCaseFeeTotal: NO_FALLBACK,
  lodgementDocumentSummaries: NO_FALLBACK,
  responsibleSubscriber: NO_FALLBACK,
  responsibleParticipantId: NO_FALLBACK,
  participants: NO_FALLBACK,
  isResponsibleParticipant: NO_FALLBACK,
  isResponsibleParticipantChangeable: NO_FALLBACK,
  lodgementOrderSummary: NO_FALLBACK,
  justifications: NO_FALLBACK,
  ctDirections: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        title: [''],
        ctDirections: [null]
      }
    }
  },
  selectedTitleReferences: NO_FALLBACK,
  currentParticipantId: NO_FALLBACK,
  isSameRoleAsResponsible: NO_FALLBACK
};
