import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import IconButton from '@mui/material/IconButton';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { IconDelete } from '@sympli/ui-framework/icons';

import StandardTermsDocumentReference from '../../components/standard-terms-document-reference';
import { MortgageTermsAndConditionsModel, StandardTermsDocumentReferenceModel } from '../../models';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  minItems: number;
  maxItems: number;
}

function StandardTermsDocumentReferenceArray({ name, className, minItems, maxItems }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<MortgageTermsAndConditionsModel>();
  const value = values.standardTermsDocumentReferences;

  const createNewItem = useCallback(
    (): StandardTermsDocumentReferenceModel => ({
      documentReference: ''
    }),
    []
  );

  const renderItem = useCallback(
    ({ itemIndex, itemCount, itemBinding, handleRemove, removeButtonFocusRef }: DocumentArrayItemRenderProps<StandardTermsDocumentReferenceModel>) => {
      const itemValue = value[itemIndex];
      const isFilled = (itemValue.documentReference ?? '').trim() !== '';

      const title = `Standard Terms${itemCount > 1 ? ' ' + (itemIndex + 1) : ''}`;

      const preRegisteredStandardTerms = values.preRegisteredStandardTerms?.map(x => x.value) ?? values.preRegisteredStandardTermsDocumentReferences ?? []; // preRegisteredStandardTermsDocumentReferences is obsolete, do not use in new document version. Use PreRegisteredStandardTerms instead

      const showFormTip = isFilled && !preRegisteredStandardTerms.includes((itemValue.documentReference ?? '').trim());
      const formTip = showFormTip
        ? 'These standard terms will only be used in this workspace. Please contact your Super Admin to have these added to the default list.'
        : undefined;

      const isShowingRemoveButton = value.length > minItems;
      const canRemove = isShowingRemoveButton && !disabled;
      return (
        <FormGroup //
          key={itemBinding}
          title={title}
          fieldAreaDirection="column"
          formTip={formTip}
          {...(isShowingRemoveButton && {
            iconPrefix: (
              <IconButton color="primary" disabled={!canRemove} onClick={handleRemove} action={removeButtonFocusRef} size="large">
                <IconDelete fill="currentColor" />
              </IconButton>
            )
          })}
        >
          <StandardTermsDocumentReference name={itemBinding} />
        </FormGroup>
      );
    },
    [value, values.preRegisteredStandardTerms, values.preRegisteredStandardTermsDocumentReferences, disabled, minItems]
  );

  return (
    <div data-name={name} className={className}>
      <DocumentFieldArray
        arrayBinding={name}
        renderItem={renderItem}
        disabled={disabled}
        itemStyle="none"
        minItems={minItems}
        maxItems={maxItems}
        createNewItem={createNewItem}
        addButtonTitle="Add standard terms"
      />
    </div>
  );
}

export default React.memo(StandardTermsDocumentReferenceArray);
