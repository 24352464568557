import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

function InvitedFinancialSettlementSummary() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        invited
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The participant has now <b>been invited to the workspace</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        When they accept the invitation their financials status will change to ‘In Preparation’.
      </Typography>
    </Box>
  );
}

export default React.memo(InvitedFinancialSettlementSummary);
