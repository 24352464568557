import * as React from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import DocumentPreview from 'src/components/document-preview/DocumentPreview';
import { ApiStatus } from 'src/utils/http';

interface Props {
  pdfUrl?: string;
  error?: string;
  status: ApiStatus;
}

function DocumentPreviewEnhanced({ pdfUrl, error, status }: Props) {
  if (status === 'rejected') {
    return (
      <FormHelperText data-error-name="documentPreviewEnhancedRejected" role="alert" error={true}>
        {error}
      </FormHelperText>
    );
  }

  if (status === 'idle' || status === 'pending') {
    return <BlockLoader />;
  }

  if (pdfUrl) {
    return <DocumentPreview src={pdfUrl} />;
  }

  return null;
}

export default DocumentPreviewEnhanced;
