import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import MonetaryConsideration from '../../components/monetary-consideration';
import NonMonetaryConsideration from '../../components/non-monetary-consideration';
import { CONSIDERATION$CONSIDERATION_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { ConsiderationModel, NoticeOfSaleModel } from '../../models';
import { VISIBILITY_CHECK_CONSIDERATION$MONETARY_CONSIDERATION, VISIBILITY_CHECK_CONSIDERATION$NON_MONETARY_CONSIDERATION } from '../../visibilityChecks';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
const debug = !import.meta.env.PROD;
interface Props {
  name: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionConsideration({ name, className, focusRef }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfSaleModel>();
  const { values } = formikProps;
  const value: ConsiderationModel = _get(values, name);
  const fieldName = createModelKeyAppender<ConsiderationModel>(name);

  return (
    <div data-section={name} className={className} data-testid={name}>
      <FormGroup //
        title="Consideration"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('isMonetaryConsideration')}
            component={SelectField}
            debug={debug}
            disabled={true}
            options={CONSIDERATION$CONSIDERATION_TYPE_LOOKUP_OPTIONS}
            placeholder={resolveSelectPlaceholder(true)}
          />
        )}
      </FormGroup>
      {VISIBILITY_CHECK_CONSIDERATION$MONETARY_CONSIDERATION(value) && (
        <MonetaryConsideration //
          name={fieldName('monetaryConsideration')}
        />
      )}
      {VISIBILITY_CHECK_CONSIDERATION$NON_MONETARY_CONSIDERATION(value) && (
        <NonMonetaryConsideration //
          name={fieldName('nonMonetaryConsideration')}
        />
      )}
    </div>
  );
}

export default React.memo(SectionConsideration);
