import { VicNameChange } from '@sympli-mfe/document-components/party-form/vic/2-18/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { getMortgagorPartyBookIds, getSelectedTitleReferences } from '../../helpers';
import { Mortgage2_18_1Model, TitleReferenceModel } from '../../models';

interface PartyBookValidationContext {
  titleReferences: TitleReferenceModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: Mortgage2_18_1Model): PartyBookValidationContext => {
  return {
    titleReferences: parentContext.titleReferences
  };
};

const checkShouldValidateItem = ({ titleReferences }: PartyBookValidationContext, partyIdForCheck: PartyModel<VicNameChange>['id']) => {
  const selectedTitleReferences = getSelectedTitleReferences(titleReferences);
  const selectedMortgagorPartyBookIds = getMortgagorPartyBookIds(selectedTitleReferences);
  const isSelectedMortgagor = !!~selectedMortgagorPartyBookIds.indexOf(partyIdForCheck);
  return isSelectedMortgagor;
};

const yupPartyItem = resolvePartyModelValidationSchema<VicNameChange, PartyBookValidationContext>(VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<VicNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;

// const yupPartyBookEntry = yup.object<PartyModel>().test(
//   //
//   'Vic M party test',
//   'Vic M party failed validation',
//   function test(this: yup.TestContext<Mortgage2_18_1Model>, party: PartyModel): boolean {
//     const mortgageDocument = this.options.context!;
//     const partyIdForCheck = party.id;

//     const selectedMortgagorsIds = mortgageDocument.titleReferences.map(title => (title.isSelected ? title.mortgagors.map(mortgagor => mortgagor.partyBookId) : [])).flat();
//     const isSelectedMortgagor: boolean = selectedMortgagorsIds.includes(partyIdForCheck);

//     if (!isSelectedMortgagor) {
//       return true;
//     }

//     try {
//       yupParty.validateSync(party, {
//         path: this.path,
//         abortEarly: false,
//         context: mortgageDocument
//       } as any);
//       return true;
//     } catch (ex) {
//       return ex;
//     }
//   }
// );

// export const yupPartyBook = yup.array<PartyModel<VicNameChange>>(yupPartyBookEntry as yup.Schema<PartyModel<VicNameChange>>).defined();
