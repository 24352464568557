import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';

import { titlesHasCommonProprietor } from '../../helpers';
import { BaseCaveatModel, TitleReferenceModel } from '../../models';

export const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel>().test(
  //
  'Title references required common proprietor',
  'The proprietors on the selected titles do not match. Please review the selected titles.',
  function test(this: yup.TestContext<BaseCaveatModel>) {
    const { titleReferences } = this.options.context!;
    const selectedTitleReferences = titleReferences.filter(x => x.isSelected);
    if (selectedTitleReferences.length <= 1 || selectedTitleReferences.length === 1) return true;

    if (!titlesHasCommonProprietor(selectedTitleReferences)) {
      return this.createError({ message: 'The proprietors on the selected titles do not match. Please review the selected titles.' });
    }
    return true;
  }
);
