import { createSelector } from 'reselect';

import { ClientWorkspaceRoleEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { SubscriberWorkspaceRoleModel, UserProfileModel } from '@sympli/api-gateway/shared';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { getWorkspaceRoleTypeOptions } from 'src/models/roles';

interface WorkspaceRoleTypeOptionsSelectorParams {
  subscriberProfile: UserProfileModel;
  jurisdiction: JurisdictionsEnum | null;
  areCriticalRolesEnabled: boolean;
  areCriticalRolesNSWEnabled: boolean;
}

const getSubscriberProfileWorkspaceRoles = (data: WorkspaceRoleTypeOptionsSelectorParams) => data.subscriberProfile.subscriberWorkspaceRoles;

export const workspaceRoleTypeOptionsSelector = createSelector<
  WorkspaceRoleTypeOptionsSelectorParams,
  SubscriberWorkspaceRoleModel[],
  JurisdictionsEnum | null,
  boolean,
  boolean,
  LookupEnumModel<ClientWorkspaceRoleEnum>[]
>(
  getSubscriberProfileWorkspaceRoles,
  data => data.jurisdiction,
  data => data.areCriticalRolesEnabled,
  data => data.areCriticalRolesNSWEnabled,
  (roles, jurisdiction, areCriticalRolesEnabled, areCriticalRolesNSWEnabled) => {
    const enabledRoles: ClientWorkspaceRoleEnum[] = roles.filter(role => role.isEnabled).map(role => role.workspaceRole);
    const enabledRolesSet = new Set<ClientWorkspaceRoleEnum>(enabledRoles);
    const roleTypeOptions: LookupEnumModel<ClientWorkspaceRoleEnum>[] = getWorkspaceRoleTypeOptions(jurisdiction, areCriticalRolesEnabled, areCriticalRolesNSWEnabled);
    return roleTypeOptions.filter(role => enabledRolesSet.has(role.id));
  }
);
