import { memo, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import {
  AssignmentTypeEnum,
  DashboardDateRangeModel,
  DashboardTypeEnum,
  FilterDataModel,
  MyRoleEnum,
  MyRoleOptions,
  UNASSIGNED_OPTION
} from 'src/containers/dashboard/shared/models';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { actionFetchWorkspaceGroups } from '../../shared/actions';
import { GroupMembersState } from '../../shared/reducers/groupMembers';
import { UserJurisdictionsState } from '../../shared/reducers/userJurisdictions';
import { useWorkspaceGroups } from '../../shared/reducers/workspaceGroups';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel, InvitationsDashboardV2ApiRequest } from '../models';
import { InvitationSubscriberSearchState } from '../reducers/invitationSubscribersSearch';
import Filters from './Filters';
import { convertDateTimeObjectToArrayString, getDefaultSelectOptions } from './helper';
import { ArchivedTypeOptions, FilterEnum, InvitationStatusOptions, TransactionTypeOptions } from './models';

const fieldName = modelKey<InvitationsDashboardV2ApiRequest>();

interface FiltersContainerProps {
  selectedTab: InvitationsDashboardRouteTabsEnumV2;
  currentTableFilters: InvitationsDashboardUiFilterModel;
  onAddFilterChange(currentTabFilters: InvitationsDashboardUiFilterModel): void;
  onFilterChange(changedFilter: Partial<InvitationsDashboardV2ApiRequest>, currentTabFilters: InvitationsDashboardUiFilterModel): void;
  assignmentType: AssignmentTypeEnum;
  userJurisdictions: UserJurisdictionsState['items'];
  groupMembers: GroupMembersState['items'];
  subscribers: InvitationSubscriberSearchState['items'];
  isTransactionTypeEnabled: boolean;
}

const FiltersContainer = ({
  //
  selectedTab,
  currentTableFilters,
  onAddFilterChange,
  onFilterChange,
  assignmentType,
  userJurisdictions,
  groupMembers,
  subscribers,
  isTransactionTypeEnabled
}: FiltersContainerProps) => {
  const isCriticalRolesEnabled: boolean = useFeatureFlag(FeatureToggleEnum.criticalRoles);

  // rendered filters
  const handleOnAddFilter = (newValue: FilterEnum) => {
    const newLocalStorageModel: InvitationsDashboardUiFilterModel = {
      ...currentTableFilters,
      selectedFilters: [...currentTableFilters.selectedFilters!, newValue],
      defaultOpenFilter: newValue
    };
    onAddFilterChange(newLocalStorageModel);
  };

  const dispatch = useSafeDispatch(useDispatch());
  useEffect(() => {
    dispatch(actionFetchWorkspaceGroups.request({ includeUnassignableGroups: true }));
  }, [dispatch]);

  const workspaceGroupState = useWorkspaceGroups().items;

  const handleOnFilterChange = (name: string, newValues: number[] | string[]) => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'filters',
      logGroupId: `${DashboardTypeEnum.Invitation}-dashboard`,
      filterName: name,
      filterValue: newValues
    });
    const newLocalStorageModel = { ...currentTableFilters };
    newLocalStorageModel[name] = [...newValues];

    onFilterChange(
      {
        [name]: newValues
      },
      newLocalStorageModel
    );
  };

  const handleOnDateTimeFilterChange = (name: string, newValues: string[]) => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'filters',
      logGroupId: `${DashboardTypeEnum.Invitation}-dashboard`,
      filterName: name,
      filterValue: newValues
    });
    let datePeriod: DashboardDateRangeModel | undefined | string =
      newValues.length === 0 ? 'AllDates' : newValues.length === 1 ? { start: newValues[0], end: newValues[0] } : { start: newValues[0], end: newValues[1] };

    // for single day or date range, in case we use the clear button, the value would be empty string,
    // when this happens, we don't want to set the filter on settlementPeriod
    if (newValues.some(x => x === '')) {
      datePeriod = undefined;
    }

    const localStorageDataModel: InvitationsDashboardUiFilterModel = { ...currentTableFilters, [name]: datePeriod };
    if (datePeriod) {
      onFilterChange(
        {
          [name]: datePeriod
        },
        localStorageDataModel
      );
    } else {
      onFilterChange(
        {
          [name]: null
        },
        localStorageDataModel
      );
    }
  };

  const selectedFilters = currentTableFilters.selectedFilters;

  // on the assign to group,  groups filters are default show up and all options should be selected. Unless
  // user un-select and then check the data also member is only allowed for group
  const allowedFilters = useMemo(() => {
    let defaultFilters = getDefaultSelectOptions(selectedTab, assignmentType, isTransactionTypeEnabled);
    return defaultFilters.filter(o => selectedFilters!.indexOf(o.id) === -1);
  }, [selectedTab, selectedFilters, assignmentType, isTransactionTypeEnabled]);

  const WORKSPACE_ASSIGNABLE_GROUP_OPTIONS = useMemo(() => {
    return workspaceGroupState.map(
      (item): LookupItemModel<string> => ({
        id: item.id,
        name: item.name
      })
    );
  }, [workspaceGroupState]);

  const GROUP_MEMBER_OPTIONS = useMemo(() => {
    return groupMembers.map(
      (item): LookupItemModel<string> => ({
        id: item.id,
        name: item.name
      })
    );
  }, [groupMembers]);

  const MY_ROLES_OPTIONS = useMemo(() => {
    return isCriticalRolesEnabled
      ? MyRoleOptions
      : MyRoleOptions.filter(o => ![MyRoleEnum.IncomingCaveator, MyRoleEnum.ToDealWithAnInterest, MyRoleEnum.SourceFunder].includes(o.id));
  }, [isCriticalRolesEnabled]);

  const selectedFiltersData = useMemo((): FilterDataModel[] => {
    // TODO why do we need to do this mapping here? the name should be already coming as string
    const JURISDICTION_OPTIONS = userJurisdictions.map(x => ({ id: x.id, name: JurisdictionsEnum[x.id] }));

    let filterModels: FilterDataModel[] = [];

    currentTableFilters.selectedFilters?.forEach(selectedFilter => {
      switch (selectedFilter) {
        case FilterEnum.ReceivedDate:
        case FilterEnum.SentDate: {
          filterModels.push({
            name: fieldName('lastInvitationSentPeriod'),
            label: selectedFilter === FilterEnum.ReceivedDate ? 'Received Date' : 'Sent Date',
            placeHolder: 'Select',
            values: convertDateTimeObjectToArrayString(currentTableFilters.lastInvitationSentPeriod),
            type: 'datetimePicker',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        }
        case FilterEnum.ArchivedDate: {
          filterModels.push({
            name: fieldName('archivedDatePeriod'),
            label: 'Archived Date',
            placeHolder: 'Select',
            values: convertDateTimeObjectToArrayString(currentTableFilters.archivedDatePeriod),
            type: 'datetimePicker',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        }
        case FilterEnum.ToSubscriber:
        case FilterEnum.FromSubscriber:
        case FilterEnum.FromToSubscriber: {
          filterModels.push({
            name: fieldName('subscriberIds'),
            label: selectedFilter === FilterEnum.ToSubscriber ? 'To' : selectedFilter === FilterEnum.FromSubscriber ? 'From' : 'From/To',
            searchBoxLabel: 'Subscriber',
            placeHolder: 'Select',
            values: currentTableFilters.subscriberIds ?? [],
            type: 'multipleSearchCheckbox',
            options: subscribers,
            unit: 'Subscribers',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        }
        case FilterEnum.Jurisdiction:
          filterModels.push({
            label: 'Jurisdiction',
            placeHolder: 'Select',
            values: currentTableFilters.jurisdictions ?? [],
            options: JURISDICTION_OPTIONS,
            type: 'multipleCheckbox',
            name: fieldName('jurisdictions'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.MyRole:
          filterModels.push({
            name: fieldName('myRoles'),
            label: 'Role',
            placeHolder: 'Select',
            values: currentTableFilters.myRoles ?? [],
            options: MY_ROLES_OPTIONS,
            type: 'multipleCheckbox',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.ProposedSettlementDate:
          filterModels.push({
            name: fieldName('settlementPeriod'),
            label: 'Proposed Setl',
            placeHolder: 'Select',
            values: convertDateTimeObjectToArrayString(currentTableFilters.settlementPeriod),
            type: 'datetimePicker',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.ArchivedType:
          filterModels.push({
            name: fieldName('invitationArchivedType'),
            label: 'Invite Type',
            placeHolder: 'Select',
            values: currentTableFilters.invitationArchivedType ?? [],
            type: 'multipleCheckbox',
            options: ArchivedTypeOptions,
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.InvitationStatus:
          filterModels.push({
            name: fieldName('status'),
            label: 'STATUS',
            placeHolder: 'Select',
            values: currentTableFilters.status ?? [],
            type: 'multipleCheckbox',
            options: InvitationStatusOptions,
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.Groups:
          assignmentType !== AssignmentTypeEnum.AssignedToMe &&
            filterModels.push({
              name: fieldName('groupIds'),
              label: 'Group',
              placeHolder: 'Select',
              values: currentTableFilters.groupIds ?? [],
              type: assignmentType === AssignmentTypeEnum.ReAssigned ? 'singleCheckbox' : 'multipleCheckbox',
              options: WORKSPACE_ASSIGNABLE_GROUP_OPTIONS,
              defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
            });
          break;
        case FilterEnum.Members:
          assignmentType !== AssignmentTypeEnum.AssignedToMe &&
            filterModels.push({
              name: fieldName('userIds'),
              label: selectedTab === InvitationsDashboardRouteTabsEnumV2.sent ? 'Sent By' : 'Member',
              placeHolder: 'Select',
              values: currentTableFilters.userIds ?? [],
              type: 'multipleSearchCheckbox',
              unit: 'Member',
              options: GROUP_MEMBER_OPTIONS,
              additionalOptionsOnTop: selectedTab !== InvitationsDashboardRouteTabsEnumV2.sent ? UNASSIGNED_OPTION : undefined,
              defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
            });
          break;
        case FilterEnum.TransactionType:
          filterModels.push({
            name: fieldName('invitationTransactionType'),
            label: 'TRANSACTION TYPE',
            placeHolder: 'Select',
            values: currentTableFilters.invitationTransactionType ?? [],
            options: TransactionTypeOptions,
            type: 'multipleCheckbox',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
      }
    });

    return filterModels;
  }, [
    userJurisdictions,
    currentTableFilters.selectedFilters,
    currentTableFilters.jurisdictions,
    currentTableFilters.defaultOpenFilter,
    currentTableFilters.myRoles,
    currentTableFilters.settlementPeriod,
    currentTableFilters.invitationArchivedType,
    currentTableFilters.status,
    currentTableFilters.groupIds,
    currentTableFilters.userIds,
    currentTableFilters.invitationTransactionType,
    currentTableFilters.lastInvitationSentPeriod,
    currentTableFilters.archivedDatePeriod,
    currentTableFilters.subscriberIds,
    MY_ROLES_OPTIONS,
    assignmentType,
    WORKSPACE_ASSIGNABLE_GROUP_OPTIONS,
    selectedTab,
    GROUP_MEMBER_OPTIONS,
    subscribers
  ]);

  return (
    <Filters //
      selectedFiltersData={selectedFiltersData}
      allowedFilters={allowedFilters}
      selectedTab={selectedTab}
      assignmentType={assignmentType}
      onFilterChange={handleOnFilterChange}
      onAddNewFilterChange={handleOnAddFilter}
      onDateTimeFilterChange={handleOnDateTimeFilterChange}
    />
  );
};

export default memo(FiltersContainer);
