import * as React from 'react';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import { SupportedJurisdictionsState } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { useWorkspaceAssignableGroups, WorkspaceAssignableGroupsState } from 'src/containers/dashboard/shared/reducers/workspaceAssignableGroups';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useStoreSelector } from 'src/hooks';
import useInteroperable from '../../useInteroperable';
import AboutYourMatterSection from './AboutYourMatterSection';

interface Props {
  onGroupFieldChange: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => void;
  workspaceTypeId?: WorkspaceTypeEnum;
}

function AboutYourMatterSectionContainer(props: Props) {
  const { workspaceTypeId } = props;
  const assignGroupsState: WorkspaceAssignableGroupsState = useWorkspaceAssignableGroups();
  const supportedJurisdictionsState: SupportedJurisdictionsState = useStoreSelector(store => store.supportedJurisdictions);
  const subscriberProfile: UserProfileModel = useProfile().data!;
  const isInteroperable = useInteroperable(workspaceTypeId);

  return (
    <AboutYourMatterSection //
      {...props}
      assignGroupsState={assignGroupsState}
      supportedJurisdictionsState={supportedJurisdictionsState}
      subscriberProfile={subscriberProfile}
      isInteroperable={isInteroperable}
    />
  );
}

export default React.memo(AboutYourMatterSectionContainer);
