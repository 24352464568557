import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    header: {
      width: '100%',
      height: 26,
      color: theme.palette.primary.contrastText,
      backgroundColor: 'black',
      fontSize: 13,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    row: {
      width: '100%',
      '&>div': {
        flexGrow: 1,
        flexBasis: 0
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
