import { createSelector } from 'reselect';

import { JurisdictionsEnum, UserLevelPermissionEnum } from '@sympli/api-gateway/enums';

import { Store } from 'src/reducers';
import { UserPermissionsState } from '../reducers/userPermissions';
import { JURISDICTIONS_WITH_USER_CERTIFIER_DESIGNATION_ENABLED } from './helpers';

// get a GroupMap like (only for group Children Auto Check)
// {
//   'groupid':{
//     id: 'groupId',
//     name: 'groupName',
//     parentId: 'parentId',
//     isEditable: true,
//     childrenIds: [ 'childrenId1', 'childrenId2']
//   }
// }

// TODO: Deprecated, remove later
// function getGroupMapWithChildrenList(origGroupAccessList: Array<UserGroupAccessModel>) {
//   return origGroupAccessList.reduce((acc, group) => {
//     // add info to THIS group
//     if (acc[group.id!]) {
//       acc[group.id!] = { ...acc[group.id!], name: group.name, parentId: group.parentId, isEditable: group.isEditable };
//     } else {
//       // initialize this group option if not exist
//       acc[group.id!] = {
//         id: group.id,
//         name: group.name,
//         parentId: group.parentId,
//         isEditable: group.isEditable,
//         childrenIds: []
//       };
//     }
//     // add child Id to PARENT group
//     if (group.parentId != null) {
//       if (acc[group.parentId]) {
//         acc[group.parentId].childrenIds.push(group.id);
//       } else {
//         // initialize this parent group option if not exist
//         acc[group.parentId] = {
//           id: group.parentId,
//           name: '',
//           parentId: '',
//           isEditable: true,
//           childrenIds: [group.id]
//         };
//       }
//     }
//     return acc;
//   }, {});
// }

const getUserPermissions = <T extends Pick<Store, 'userPermissions'>>(state: T) => {
  return state.userPermissions;
};

export const userPermissionsFormValueSelector = createSelector<
  //
  Pick<Store, 'userPermissions'>,
  UserPermissionsState,
  any
>(
  getUserPermissions, //
  userPermissions => {
    const { detail } = userPermissions;
    if (detail == null) {
      return { ...userPermissions, detail: undefined };
    }
    const { roles, groups, permissions, userCertifierDesignations = [], userSigningRule } = detail;
    // roles: Array<RoleEntityModel>;
    // groups: Array<UserGroupAccessModel>;
    // permissions: Array<UserPermissionsEntityModel>;
    // updatePermissionsSpecification: Array<PermissionsSpecification>;
    const role = roles.find(item => item.isSelected);
    return {
      ...userPermissions,
      detail: {
        role: role != null ? role.id : '',
        groups: groups.filter(item => item.isSelected).map(({ id }) => id),
        permissions: permissions.filter(item => item.isSelected).map(({ id }) => id),
        designation: userCertifierDesignations.reduce((result: number | null, designation) => {
          const { jurisdiction, certifierDesignation } = designation;

          if (JURISDICTIONS_WITH_USER_CERTIFIER_DESIGNATION_ENABLED.includes(jurisdiction)) {
            result = certifierDesignation;
            return result;
          }
          return result;
        }, null),
        userSigningRule: userSigningRule
      }
    };
  }
);

// * If the user has permission to sign either of these jurisdictions: VIC, WA, we also need to ask for User Certifier Designations
const getPermissionsFormValue = (permissions: UserLevelPermissionEnum[]) => permissions;
export const jurisdictionHasUserCertifierDesignationsSelector = createSelector<
  //
  UserLevelPermissionEnum[],
  UserLevelPermissionEnum[],
  Partial<Record<JurisdictionsEnum, boolean>>
>(
  getPermissionsFormValue, //
  userPermissions => {
    return userPermissions.reduce((result, permission) => {
      switch (permission) {
        case UserLevelPermissionEnum.SignDocumentVIC:
          result[JurisdictionsEnum.VIC] = true;
          break;
        case UserLevelPermissionEnum.SignDocumentWA:
          result[JurisdictionsEnum.WA] = true;
          break;
      }
      return result;
    }, {});
  }
);
