import { isAfter, isSameDay } from 'date-fns';

import { WorkspaceParticipant } from '@sympli/api-gateway/shared';
import { MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';

import { WorkspaceActivityLogsState } from '../../reducers/workspaceActivityLogs';
import { WorkspaceActivityLogModel } from '../tab-logs/models';
import { mapLogToNotificationContent } from './workspaceLogNotificationMapper';

interface Props {
  workspaceId: string;
  participantId: string;
  workspaceLogs: WorkspaceActivityLogsState['items'];
  workspaceParticipants: WorkspaceParticipant[];
  withdrawnParticipants: WorkspaceParticipant[];
}

function WorkspaceLogNotifications({ workspaceId, participantId, workspaceLogs, workspaceParticipants, withdrawnParticipants }: Props) {
  const participant = workspaceParticipants.find(wp => wp.id === participantId);

  if (participant === undefined || withdrawnParticipants.find(wp => wp.id === participantId)) {
    return [];
  }

  const acceptedDateTime = new Date(participant.lastInvitationStatusUpdatedDate!);
  const workspaceLogsForNotification: WorkspaceActivityLogModel[] = Object.keys(workspaceLogs)
    .filter(dateOnlyValue => isAfter(new Date(dateOnlyValue), acceptedDateTime) || isSameDay(new Date(dateOnlyValue), acceptedDateTime))
    .flatMap(dateOnlyValue => {
      const logs = workspaceLogs[dateOnlyValue] || [];
      return logs.filter(l => isAfter(new Date(l.dateTimeUtc), acceptedDateTime));
    });

  if (workspaceLogsForNotification.length === 0) {
    return [];
  }

  return workspaceLogsForNotification.map(log => {
    const key = `${workspaceId}/${participantId}/${log.participantId}/${log.workspaceLogType}/${log.dateTimeUtc}`;
    const ContentComponent = mapLogToNotificationContent(log);
    if (ContentComponent === undefined) return undefined;

    const content = ContentComponent({ log, participantId, workspaceParticipants, withdrawnParticipants });
    if (!content) return undefined;

    return (
      <MessageNotificationWithLocalStorage //
        key={key}
        v2
        storageNamespace={key}
        messageId={key}
        open={undefined}
        variant="warning"
        primary={content}
      />
    );
  });
}

export default WorkspaceLogNotifications;
