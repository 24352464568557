import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { LandDescriptionModel, Mortgage2_21_2Model, TitleReferenceModel } from '../../models';

interface MortgageContext {
  partyBook: Mortgage2_21_2Model['partyBook'];
}
// ToDo: Check message for Mortgage document. The one below was copied from Transfer.
const MISMATCHED_TENANCY_STRUCTURE_MESSAGE = 'The tenancy of the proprietors does not match; please remove the mismatched titles';

const contextResolver = ({ partyBook }: Mortgage2_21_2Model): MortgageContext => ({ partyBook });

// path: titleReferences
const yupTitleReferenceItemSchema = yup
  .object<TitleReferenceModel>({
    isSelected: yup.boolean(),
    reference: yup.string(),
    landDescriptions: yup.mixed<LandDescriptionModel[]>(),
    mortgagors: yup.mixed(),
    estateType: yup.mixed()
  })
  .defined();

const yupTitleReferences = resolveTitleReferencesValidationSchema(yupTitleReferenceItemSchema).test(
  //
  'Multiple title selected: check for matching relinquishing proprietor details',
  MISMATCHED_TENANCY_STRUCTURE_MESSAGE,
  function test(this: yup.TestContext<MortgageContext>) {
    const { partyBook } = this.options.context!;
    const result = PartyValidations.verifyTenancyStructure(partyBook);
    return result === undefined;
  }
);

export default memoizeSchemaWithContext(yupTitleReferences, contextResolver);
