import React, { useCallback } from 'react';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import { DealingRequirementsDetailModel } from '../../models';
import DealingRequirementsDetail from '../dealing-requirements-detail';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  minItems: number;
  maxItems: number;
}

function DealingRequirementsDetailArray({ name, className, minItems, maxItems }: Props): JSX.Element {
  const { disabled } = useDocumentContext();

  const createNewItem = useCallback(
    (): DealingRequirementsDetailModel => ({
      dealingRequirement: null
    }),
    []
  );

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<DealingRequirementsDetailModel>) => <DealingRequirementsDetail name={itemBinding} />,
    []
  );

  return (
    <DocumentFieldArray //
      arrayBinding={name}
      renderItem={renderItem}
      itemTitle={'Dealing requirement detail'}
      disabled={disabled}
      itemStyle="formGroup"
      minItems={minItems}
      maxItems={maxItems}
      createNewItem={createNewItem}
      addButtonTitle="Add dealing requirement detail"
    />
  );
}

export default React.memo(DealingRequirementsDetailArray);
