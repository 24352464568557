import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';
import { colors } from '@sympli/ui-framework/theme';

// this file was automatically generated from sections/styles.ts.mustache
export const useStyles = makeStyles(
  () => ({
    root: {
      ...dataBindingHelper()
    },
    halfWidth: {
      width: '50%'
    },
    quarterWidth: {
      width: '25%'
    },
    additionalCovenantWarningBorder: {
      borderColor: colors.ORANGE
    },
    additionalCovenantWarningHelperText: {
      color: colors.ORANGE,
      position: 'unset',
      marginBottom: 4
    },
    noBottomMargin: {
      marginBottom: 'unset'
    },
    formControlValidation: {
      position: 'unset',
      marginBottom: 4
    }
  }),
  {
    name: 'TermsAndConditions'
  }
);
