import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import sharedStyles from 'src/containers/workspace/registration-only/detail/components/tab-tasks/styles';

const styles = (theme: Theme) => ({
  ...sharedStyles(theme),
  ...createStyles({
    descriptionList: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: '17px',
      maxWidth: 360,
      textAlign: 'left'
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
