import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-25/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { WaAddressChange } from '../../../../components/address-justification';
import { WA_DM_PARTY_FORM_CONFIG } from '../../config';
import { getMortgageePartyBookIds, getSelectedMortgages } from '../../helpers';
import { DischargeOfMortgageDocument2_25_0Model, MortgageDetailModel } from '../../models';

interface PartyBookValidationContext {
  mortgages: MortgageDetailModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: DischargeOfMortgageDocument2_25_0Model): PartyBookValidationContext => {
  return {
    mortgages: parentContext.mortgages
  };
};

const checkShouldValidateItem = ({ mortgages }: PartyBookValidationContext, partyIdForCheck: PartyModel<WaNameChange, WaAddressChange>['id']) => {
  const selectedMortgages = getSelectedMortgages(mortgages);
  const selectedMortgageesIds = getMortgageePartyBookIds(selectedMortgages);

  const isSelectedMortgagee = !!~selectedMortgageesIds.indexOf(partyIdForCheck);
  return isSelectedMortgagee;
};

const yupPartyItem = resolvePartyModelValidationSchema<WaNameChange, PartyBookValidationContext, WaAddressChange>(WA_DM_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<WaNameChange, WaAddressChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
