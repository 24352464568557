import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { FinancialRoleMapping } from 'src/models/roles';
import { useConfirmationDialogStyles } from './styles';

interface Props {
  onClose: (data?: boolean) => void;
  workspaceRole: WorkspaceRoleEnum;
  documentType: string;
  isSubmitting: boolean;
}

function WithdrawDialog({ onClose, workspaceRole, documentType, isSubmitting }: Props): JSX.Element {
  const confirmationDialogClasses = useConfirmationDialogStyles();

  return (
    <ConfirmationDialog
      title="Withdraw from document"
      color="primary"
      showActionButtons
      open
      scroll="body"
      okButtonText="Withdraw"
      onClose={onClose}
      isLoading={isSubmitting}
      classes={{
        //
        dialogPaper: confirmationDialogClasses.dialogPaper,
        okButton: confirmationDialogClasses.withdrawButton,
        dialogTitle: confirmationDialogClasses.dialogTitle,
        dialogActions: confirmationDialogClasses.dialogActions,
        dialogContent: confirmationDialogClasses.dialogContent
      }}
    >
      You are <strong>removing your participation</strong> as <strong>{FinancialRoleMapping[workspaceRole]}</strong> from the {documentType} document. You will no longer be able to
      edit the {documentType} document and any of it's supporting documents will be deleted.
    </ConfirmationDialog>
  );
}

export default WithdrawDialog;
