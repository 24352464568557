// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { Lease2_21_1Model } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import yupDocumentAttachments from './sections/document-attachments/validationSchema';
import yupLeaseDetails from './sections/lease-details/validationSchema';
import yupLessees from './sections/lessees/validationSchema';
import yupLessors from './sections/lessors/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupRentDetails from './sections/rent-details/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<Lease2_21_1Model>({
    workspaceTitleReferences: yup.mixed(), // no validation
    partyBook: yupPartyBook,
    titleReferences: yupTitleReferences,
    lessees: yupLessees,
    lessors: yupLessors,
    leaseDetails: yupLeaseDetails,
    rentDetails: yupRentDetails,
    documentAttachments: yupDocumentAttachments
  })
  .defined()
  .log();
