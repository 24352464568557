import * as React from 'react';

import classNames from 'classnames';
import { Action } from 'redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconExclamationCircle, IconInfoCircle } from '@sympli/ui-framework/icons';
import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import Tooltip from 'src/components/tooltip';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import JsonRest from 'src/store/JsonRest';
import { modelKey } from 'src/utils/formUtils';
import withRouterProps, { RouterProps } from 'src/utils/withRouterProps';
import { unacceptSettlementDateTime } from '../../../api';
import { RescindApprovalModel, SettlementDateFormModel } from '../../../models';
import styles, { ClassKeys } from './styles';

export interface State {
  isLoading: boolean;
}

export enum SettlementTimeTypeEnum {
  Previous,
  Current,
  Proposed,
  Initial
}
interface OwnProps {
  // for renderSelectedDateTime
  dateTimeDisplay: string;
  settlementTimeType: SettlementTimeTypeEnum;

  jurisdictionId: JurisdictionsEnum;

  // for form
  isEditable: boolean;
  settlementTimeOptions: Array<LookupItemModel>;
  handleOnTimeChange?: (...args: any[]) => void;
  reasonText?: string;
  rescindApprovalData: RescindApprovalModel;
  isRevokeSettlementDateApprovalEnabled: boolean;
  dispatch: SafeDispatch<Action>;
  isCurrentSettlementDateUnsupported?: boolean;
  isSelectedSettlementDateUnsupported?: boolean;
}

export type SettlementTimeAndReasonEditProps = OwnProps & WithStyles<ClassKeys> & RouterProps;
export class SettlementTimeAndReasonEdit extends React.PureComponent<SettlementTimeAndReasonEditProps> {
  private storeSettlementDateProposalReasons = new JsonRest<LookupItemModel[]>('/lookups/settlement-date-proposal-reasons');

  public readonly state: Readonly<State> = {
    isLoading: false
  };

  render() {
    const {
      classes,
      dateTimeDisplay,
      settlementTimeType,
      handleOnTimeChange,
      isEditable,
      settlementTimeOptions,
      reasonText,
      rescindApprovalData,
      isRevokeSettlementDateApprovalEnabled,
      isCurrentSettlementDateUnsupported,
      isSelectedSettlementDateUnsupported
    } = this.props;

    const fieldName = modelKey<SettlementDateFormModel>();

    return (
      <div className={classes.inputContainer}>
        {this.renderSelectedDateTime(dateTimeDisplay, settlementTimeType, reasonText)}
        {isRevokeSettlementDateApprovalEnabled &&
          settlementTimeType !== SettlementTimeTypeEnum.Initial &&
          // we don't show set to pending when the date is current settlement date and is not supported by other RELNO
          !isCurrentSettlementDateUnsupported && (
            <Box className={classes.displayDateTimeContainer}>
              <Box className={classes.box}>
                <Typography component="small">
                  <strong>Unaccept settlement date and time</strong>
                </Typography>
                <Tooltip leaveDelay={500} placement="top-end" title="Remove your acceptance of the settlement date and time and set it back to pending.">
                  <IconInfoCircle className={classes.tooltipIconInfo} />
                </Tooltip>
              </Box>
              <SympliButton
                disabled={!rescindApprovalData.canRescind || this.state.isLoading}
                variant="contained"
                color="primary"
                onClick={() => this.handleUnacceptSettlementDateTime!()}
              >
                Set to pending
              </SympliButton>
              {rescindApprovalData.canRescind ? null : (
                <Box className={classes.rescindReasonBox}>
                  <IconExclamationCircle className={classes.iconWarning} />
                  <Typography component="small">{rescindApprovalData.reason}</Typography>
                </Box>
              )}
            </Box>
          )}
        {!isSelectedSettlementDateUnsupported && (
          <Field
            disabled={!isEditable}
            component={SelectField}
            options={settlementTimeOptions}
            name={fieldName('selectedTime')}
            label="Select new time"
            placeholder="Please select"
            fullWidth
            onChange={handleOnTimeChange}
            className={classes.selectedTime}
            classes={{ helperTextError: classes.helperTextError }}
          />
        )}
        {settlementTimeType === SettlementTimeTypeEnum.Initial || isSelectedSettlementDateUnsupported ? (
          ''
        ) : (
          <Field
            disabled={!isEditable}
            component={SelectField}
            format="number"
            store={this.storeSettlementDateProposalReasons}
            name={fieldName('reasonId')}
            label="Select reason for new date and/or time"
            fullWidth
            placeholder="Please select"
          />
        )}
      </div>
    );
  }

  private renderSelectedDateTime(dateTimeDisplay: string, settlementTimeType: SettlementTimeTypeEnum, reasonText?: string) {
    const { classes } = this.props;
    switch (settlementTimeType) {
      case SettlementTimeTypeEnum.Initial:
        return (
          <div className={classes.displayDateTimeContainer}>
            <Typography component="small">Settlement Date and Time</Typography>
            <Typography className={classes.dateTime}>{!dateTimeDisplay ? 'Select a settlement date' : dateTimeDisplay}</Typography>
          </div>
        );
      // TODO previous settlement date, this is the state when there is a proposal to be agreed on, we have not checked that condition yet
      case SettlementTimeTypeEnum.Previous: {
        return (
          <div className={classes.displayDateTimeContainer}>
            <Typography component="small">Previous settlement date and time</Typography>
            <Typography className={classes.dateTime}>{dateTimeDisplay}</Typography>
          </div>
        );
      }
      case SettlementTimeTypeEnum.Proposed: {
        return (
          <div className={classes.displayDateTimeContainer}>
            <Typography component="small" className={classes.proposedDateTime}>
              Proposed settlement date and time&nbsp;
            </Typography>
            <Typography className={classNames(classes.dateTime, classes.proposedDateTime)}>{dateTimeDisplay}</Typography>
            {reasonText && (
              <Typography display="block" component="small" className={classes.proposedDateTime}>
                {reasonText}
              </Typography>
            )}
          </div>
        );
      }
      case SettlementTimeTypeEnum.Current:
      default: {
        return (
          <div className={classes.displayDateTimeContainer}>
            <Typography component="small">Current settlement date and time</Typography>
            <Typography className={classes.dateTime}>{dateTimeDisplay}</Typography>
          </div>
        );
      }
    }
  }

  private async handleUnacceptSettlementDateTime() {
    const { workspaceId, participantId } = this.props.routerParams;
    const { dispatch } = this.props;

    try {
      this.setState({ isLoading: true });

      await unacceptSettlementDateTime(workspaceId!, participantId!);
      const link = resolveWorkspaceDetailLink({ workspaceId: workspaceId!, participantId: participantId });

      dispatch(
        actionOpenGlobalSimpleNotification({
          message: "You've unaccepted the settlement date and time. ",
          secondaryMessage: 'All participants have been notified.',
          variant: 'new-warning',
          autoHideDuration: 5000
        })
      );
      this.setState({ isLoading: false });
      this.props.navigate(link);
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch(actionCreateGlobalErrorMessage({ message: 'This action cannot be completed at this time. Please try again later.', title: 'System error' }));
      Logger.captureException(error);
    }
  }
}

export default withStyles(styles)(withRouterProps(SettlementTimeAndReasonEdit));
