export const BROWSER_TOP_PANEL = 97;
export const VIEWPORT_FOR_SCREEN_HEIGHT_800 = 800 - BROWSER_TOP_PANEL;

export const WIDTH_MAIN_CONTENT_880 = 880;
export const WIDTH_MAIN_CONTENT_800 = 800;

export const WIDTH_NAVIGATION_80 = 80;
export const WIDTH_PANEL_SIDEBAR_320 = 320;
export const WIDTH_PANEL_NAVIGATION_240 = WIDTH_PANEL_SIDEBAR_320 - WIDTH_NAVIGATION_80;
export const WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272 = 272;
export const WIDTH_PANEL_SIDEBAR_CONTENT_304 = 304;

export const MARGIN_MAIN_40 = 40;
export const PADDING_MAIN_40 = MARGIN_MAIN_40;

export const WIDTH_SCROLLBAR_17 = 17;
export const PADDING_SIDEBAR_98 = 98;
