import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { RegistryInstrumentTypeEnum } from '../../enums';
import { ApplicableInstrumentModel } from '../../models';
import yupPartyReferences from '../party-reference/validationSchema';

const yupApplicableInstrument = yup
  .object<ApplicableInstrumentModel>({
    instrumentType: yup.mixed().oneOf(Object.values(RegistryInstrumentTypeEnum), msg.REQUIRED).required(msg.REQUIRED),
    receivingParties: yupPartyReferences({ isRequired: false, maxLength: 20 }).defined(),
    relinquishingParties: yupPartyReferences({ maxSelection: 20, maxLength: undefined, isRequired: false }).defined(),
    dealingNumber: yup.string().notRequired().max(9, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(9))
  })
  .defined();

export default yupApplicableInstrument;
