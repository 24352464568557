import React from 'react';

import classNames from 'classnames';
import List from '@mui/material/List';

import { DocumentItem } from 'src/containers/shared/document-list/components/document-item';
import { DocumentItemWithAction } from 'src/containers/shared/document-list/components/document-item-with-action';
import { DocumentListItemStatusEnum, DocumentListModel } from 'src/containers/shared/document-list/models';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  items?: DocumentListModel[];
  disabled?: boolean;
  dataTestId?: string;
  isCriticalRolesEnabled: boolean;
}

function DocumentListBase({ items, disabled, dataTestId, isCriticalRolesEnabled }: Props) {
  const classes = useStyles();

  function renderList(items?: DocumentListModel[]) {
    return items?.map((item, index) => (
      <React.Fragment key={`${item.name}-${index}`}>
        {!item.disabled && (item.uiStatus === DocumentListItemStatusEnum.Reorder || item.uiStatus === DocumentListItemStatusEnum.Delete) ? (
          <DocumentItemWithAction document={item} dataTestId={`${dataTestId}-${item.name}`} />
        ) : (
          <DocumentItem
            document={item}
            forceDisable={disabled}
            dataTestId={`${dataTestId}-${item.name}`}
            className={classNames(item.className, 'mb-[8px]')}
            isCriticalRolesEnabled={isCriticalRolesEnabled}
          />
        )}
        {item.supportingDocuments && renderList(item.supportingDocuments)}
      </React.Fragment>
    ));
  }

  return <List className={classes.outerGutters}>{renderList(items)}</List>;
}

export default DocumentListBase;
