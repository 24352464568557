import * as React from 'react';

type StandaloneWorkspacesDashboardContextType = { currentUpdatedTime: Date; resetCurrentUpdatedTime: (date: Date) => void };

const StandaloneWorkspacesContext = React.createContext<StandaloneWorkspacesDashboardContextType>({
  currentUpdatedTime: new Date(),
  resetCurrentUpdatedTime: () => {}
});

export default StandaloneWorkspacesContext;
