import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    headingIcon: {
      fill: theme.palette.error.main,
      width: 20,
      height: 20
    },
    errorButton: {
      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: colors.WATERMELON_DARK
      }
    }
  }),
  { name: 'DeleteDocumentDialog' }
);
