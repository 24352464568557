import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(3, 3, 4, 3),
      backgroundColor: colors.GREEK_WATERS_TRANSLUCENT,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    dividerGray: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderColor: colors.GREY_40
    },
    dividerBlack: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderColor: colors.BLACK
    },
    darkGrey: {
      '& span': {
        color: colors.DARK_GREY
      }
    },
    mediumGrey: {
      '& span': {
        color: colors.MEDIUM_GREY
      }
    },
    black: {
      '& span': {
        color: colors.BLACK
      }
    },
    alignRight: {
      textAlign: 'right'
    },
    alignLeft: {
      textAlign: 'left'
    },
    cellSpacing: {
      paddingRight: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.25)
    },
    gridTitle: {
      marginBottom: theme.spacing(2)
    },
    optedOutGrid: {
      opacity: 0.5
    }
  }),
  { name: 'LineItemsGrid' }
);
