// this file was automatically generated
import _map from 'lodash-es/map';
import * as yup from 'yup';
import { TestContext } from 'yup';

import { createContextCheckUsingRoot, testValueReuseInArray } from '@sympli-mfe/document-forms-framework/utils';
import { getLookupValuesAllowingEmpty, testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, VISIBILITY_CHECK_QUALIFICATION } from '../../checks';
import { ActionProhibitedEnum, ENUM_ACTION_PROHIBITED_OPTIONS } from '../../enums';
import { CaveatModel, ExtentOfProhibition } from '../../models';
import { atLeastOneProprietorListedAsCaveator } from '../../sharedValidation';

type FormValues = CaveatModel;

const yupExtentOfProhibition = yup
  .object<ExtentOfProhibition>()
  .nullable(true)
  .shape({
    actionProhibitedOption: v
      .number()
      .required(msg.REQUIRED)
      .oneOf(getLookupValuesAllowingEmpty(ENUM_ACTION_PROHIBITED_OPTIONS), msg.INVALID_VALUE)
      .test(
        'ACTION ALREADY SELECTED CHECK',
        'Cannot select the same action more than once',
        testValueReuseInArray(
          (model: FormValues) => model.extentOfProhibitions!,
          extentOfProhibition => extentOfProhibition.actionProhibitedOption
        )
      )
      .test(
        testContextualRule<CaveatModel, ActionProhibitedEnum>({
          message: 'You must have at least one Registered Proprietor listed as a Caveator',
          requirement: createContextCheckUsingRoot((actionProhibitedOption: ActionProhibitedEnum, _, caveatModel: CaveatModel) => {
            if (actionProhibitedOption !== ActionProhibitedEnum.ActionProhibited5) {
              // Rule only applies to AP5
              return true;
            }
            if (!VISIBILITY_CHECK_CAVEATORS(caveatModel)) {
              // If caveators section is not selectable, this rule does not apply
              return true;
            }
            const { caveators, proprietors } = caveatModel;
            return atLeastOneProprietorListedAsCaveator(proprietors ?? [], caveators ?? []);
          })
        })
      ),
    qualification: yup //
      .mixed<string>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_QUALIFICATION,
          validationWhenVisible: yup.string().default('').trim().typeError(msg.INVALID_VALUE).nullable(true).max(255, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(255))
        })
      ),
    lrDocumentId: yup //
      .mixed<string>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID,
          validationWhenVisible: yup.string().default('').trim().required(msg.REQUIRED)
        })
      )
  });

// path: extentOfProhibitions
export default yup
  .array<ExtentOfProhibition>() //
  .required(msg.REQUIRED)
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(ENUM_ACTION_PROHIBITED_OPTIONS.length))
  .of(yupExtentOfProhibition)
  .test(
    'if 7 then either 1 or 5 is required',
    "Additional prohibition required, either Action 1 (Registry Instruments Prohibited) or Action 5 (Caveator's Estate Protected).",
    function test(this: TestContext<FormValues>, value: any) {
      const usedIds = _map(value ?? [], 'actionProhibitedOption') as number[];
      if (usedIds.includes(ActionProhibitedEnum.ActionProhibited7)) {
        return usedIds.includes(ActionProhibitedEnum.ActionProhibited1) || usedIds.includes(ActionProhibitedEnum.ActionProhibited5);
      }
      return true;
    }
  );
