import * as React from 'react';

import classNames from 'classnames';
import { FieldArray, getIn, useFormikContext } from 'formik';
import IconButton from '@mui/material/IconButton';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';

import AddItemButton from 'src/containers/documents/dynamic-form/components/add-item-button';
import Evidence from '../../components/evidence';
import { EvidenceModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  maxItems: number;
}

function EvidenceArray({ name, className, maxItems }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const { values } = formikProps;
  const classes = useStyles();
  const evidences: EvidenceModel[] = getIn(values, name) ?? [];
  const createNewItem = (): EvidenceModel => ({
    evidenceTypeValue: null,
    evidenceDate: null,
    evidenceDocumentReference: '',
    disableSelection: false,
    isDeletable: true
  });

  return (
    <div data-section={name} className={className}>
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <>
            {evidences.map((item, index) => (
              <FlexLayout
                className={classNames(item.isDeletable && classes.flexEvidence)}
                justifyContent="flex-start"
                flexDirection="row"
                alignContent="stretch"
                alignItems="baseline"
                key={index}
              >
                {item.isDeletable && (
                  <IconButton className={classes.removeButton} color="primary" disabled={disabled} onClick={() => remove(index)} size="large">
                    <IconDelete fill="currentColor" />
                  </IconButton>
                )}
                <Evidence //
                  name={`${name}[${index}]`}
                  className={classes.sectionWidth}
                />
              </FlexLayout>
            ))}
            {evidences.length < maxItems && (
              <AddItemButton //
                onClick={() => push(createNewItem())}
                disabled={disabled}
              >
                Add another evidence
              </AddItemButton>
            )}
          </>
        )}
      />
    </div>
  );
}

export default React.memo(EvidenceArray);
