import { createAsyncAction } from 'typesafe-actions';

import { OffPlatformTaskModel } from '../off-platform-tasks/models';

export interface OffPlatformTasksApiRequest {
  workspaceId: string;
}

export const actionFetchOffPlatformTasks = createAsyncAction('FETCH_OFF_PLATFORM_TASKS', 'FETCH_OFF_PLATFORM_TASKS_SUCCESS', 'FETCH_OFF_PLATFORM_TASKS_ERROR')<
  OffPlatformTasksApiRequest,
  { items: OffPlatformTaskModel[] },
  { error: Error }
>();

export interface UpdateOffPlatformTaskStatusApiRequest {
  workspaceId: string;
  participantId: string;
  offPlatformTaskId: string;
  newStatus: number;
}

export const actionUpdateOffPlatformTaskStatus = createAsyncAction(
  'UPDATE_OFF_PLATFORM_TASK_STATUS',
  'UPDATE_OFF_PLATFORM_TASK_STATUS_SUCCESS',
  'UPDATE_OFF_PLATFORM_TASK_STATUS_ERROR'
)<UpdateOffPlatformTaskStatusApiRequest, undefined, { error: Error }>();
