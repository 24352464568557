import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch } from 'src/hooks';
import MultiFactorAuthDevices from './MultiFactorAuthDevices';

function MultiFactorAuthDevicesContainer() {
  const dispatch = useSafeDispatch(useDispatch());

  return (
    <MultiFactorAuthDevices //
      dispatch={dispatch}
    />
  );
}

export default React.memo(MultiFactorAuthDevicesContainer);
