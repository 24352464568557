import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    activityParticipant: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      marginRight: 5
    },
    participantInfo: {
      textAlign: 'right'
    }
  }),
  {
    name: 'ActivityLogItemParticipant'
  }
);
