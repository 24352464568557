import { DocumentTypeIdentifierEnum, JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { VerifyTitleFormModel } from 'src/containers/shared/verify-property-section/models';
import { CreateWorkspaceInvitationRequestItemModel, TitleReferenceErrorItem, WorkspaceCreationErrorsMap } from '../../models';

export enum CreateLodgeOnlyWorkspaceStepEnum {
  AddMatterDetails = 0,
  InviteParticipants = 1,
  InviteParticipantsForLinkedWorkspace = 2
}

export interface CreateLodgementOnlyWorkspaceSharedModel {
  matterDetailsStepData: CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel;
  matterDetailsStepApiResponse?: CreateLodgementOnlyWorkspaceMatterDetailsStepResponse;
  inviteParticipantsStepData: CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel;
  inviteParticipantsStepApiResponse?: CreateLodgementOnlyWorkspaceInvitationsStepResponse;
}

export interface MatterDetailDocumentItemModel {
  documentIdentifier: DocumentTypeIdentifierEnum | null;
  roleOptions: LookupItemModel<WorkspaceRoleEnum>[];
}

export interface CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel {
  createdByReference: string;
  groupId: string;

  jurisdictionId: JurisdictionsEnum | null;
  documents: MatterDetailDocumentItemModel[];

  titleInformation: VerifyTitleFormModel;
  isControllingParty: boolean;
  instructingOrganisationSettlementAgentId?: string;
  // here we store errors that came after attempt to create workspace
  workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap;
}

export interface RepresentedClientRoleItem extends LookupEnumModel<WorkspaceRoleEnum> {
  isSelected: boolean;
}

export interface DocumentWithInvitationModel {
  documentIdentifier: DocumentTypeIdentifierEnum;
  roleOptions: LookupEnumModel<WorkspaceRoleEnum>[];
  selectedRoleIds: WorkspaceRoleEnum[];

  invitations: DocumentInvitationItemModel[];
}
export interface CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel {
  documents: DocumentWithInvitationModel[];
  invitationDescription?: string;
}

export interface CreateLodgementOnlyWorkspaceTitleDealingsStepFormModel {
  titleDealings: string[];
}

export interface TitleDealingsItemModel {
  dealingNumber: string;
  partyName: string;
}

export interface DocumentWithDealingItemModel {
  documentIdentifier: DocumentTypeIdentifierEnum | null;
  roleIds: WorkspaceRoleEnum[];
  dealingNumber: string;
}

export interface DocumentInvitationItemModel {
  subscriberIsReadOnly: boolean; // this will be set if the controlling party received from title-verification is registered with Sympli
  subscriber: SubscriberSearchItemModel;
  roleId: WorkspaceRoleEnum;
}
export interface CreateLodgementOnlyWorkspaceMatterDetailsStepRequest {
  jurisdictionId: JurisdictionsEnum;
  titleReferences: string[];
  workspaceType: WorkspaceTypeEnum;
  documents: DocumentTypeIdentifierEnum[];
  groupId: string;
}

export interface CreateLodgementOnlyWorkspaceMatterDetailsStepResponse {
  isSuccess: boolean;
  propertyAddress?: string; //ToDo: We don't need it anymore, remove it from backend
  titleReferenceErrors?: TitleReferenceErrorItem[];
}

export interface CreateDocumentsItemModel {
  documentIdentifier: DocumentTypeIdentifierEnum;
  id: string;
}

export interface CreateLodgementOnlyWorkspaceInvitationsStepRequest {
  jurisdictionId: JurisdictionsEnum;
  createdByReference: string;
  documents: Array<{
    documentIdentifier: DocumentTypeIdentifierEnum;
    roleIds: WorkspaceRoleEnum[];

    invitations: CreateWorkspaceInvitationRequestItemModel[];
  }>;
  titleReferences: string[];
  groupId: string;
  clientMappingId?: string;
  instructingOrganisationSettlementAgentId?: string;
  invitationDescription?: string;
}

export interface CreateLodgementOnlyWorkspaceInvitationsStepResponse {
  isSuccess: boolean;
  workspaceId: string;
  createdByParticipantId: string;
  createdDocuments: Array<{ id: string }>;
  titleReferenceErrors: TitleReferenceErrorItem[];
}

export interface CreateLodgementOnlyWorkspaceTitleDealingsStepResponse {
  isSuccess: boolean;
  workspaceId: string;
  createdByParticipantId: string;
  createdDocuments: Array<{ id: string }>;
  titleReferenceErrors: TitleReferenceErrorItem[];
  titleDealings: TitleDealingsItemModel[];
}

export interface CreateLodgementOnlyWorkspaceTitleDealingsStepRequest {
  jurisdictionId: JurisdictionsEnum;
  createdByReference: string;
  documents: DocumentWithDealingItemModel[];
  titleReferences: string[];
  groupId: string;
}

// ToDo: Refactor. Shared between RegOnly and Financial Workspaces.
export interface SubscriberSearchItemModel {
  id: string;
  elnoSubscriberId?: string; // Sympli subscribers will not have this populated
  elnoId?: string;
  name: string;
  abn: string;
}

export type SubscriberSearchApiResponse = SubscriberSearchItemModel[];
