// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'Lodgement success', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="01C-//-REGISTRATION-ONLY-(WITH-SIGN-ALL-DOCS)-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Lodgment-completed" transform="translate(-775.000000, -459.000000)">
          <g id="illo-success-lodgment" transform="translate(775.000000, 459.000000)">
            <circle id="Oval" fill="#F2F2F2" fillRule="nonzero" cx="65" cy="65" r="65" />
            <rect
              id="Rectangle-path"
              fill="#FFFFFF"
              fillRule="nonzero"
              transform="translate(65.046507, 65.000030) rotate(-0.095425) translate(-65.046507, -65.000030) "
              x="29.5286002"
              y="16.0642482"
              width="71.0358128"
              height="97.8715643"
            />
            <g id="Group" transform="translate(28.785714, 15.785714)" fillRule="nonzero">
              <g opacity="0.1" transform="translate(1.857143, 1.857143)" fill="#000000" id="Rectangle-path">
                <rect
                  transform="translate(36.353554, 49.307268) rotate(-0.095425) translate(-36.353554, -49.307268) "
                  x="0.835647884"
                  y="0.37148623"
                  width="71.0358128"
                  height="97.8715643"
                />
              </g>
              <rect
                id="Rectangle-path"
                fill="#FFFFFF"
                transform="translate(36.260792, 49.214316) rotate(-0.095425) translate(-36.260792, -49.214316) "
                x="0.742885901"
                y="0.278533928"
                width="71.0358128"
                height="97.8715643"
              />
            </g>
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(50.421550, 45.592921) rotate(-0.095425) translate(-50.421550, -45.592921) "
              x="34.9143856"
              y="44.3857765"
              width="31.0143287"
              height="2.41428906"
            />
            <rect
              id="Rectangle-path"
              fill="#F2F2F2"
              fillRule="nonzero"
              transform="translate(64.675027, 38.117856) rotate(-0.095425) translate(-64.675027, -38.117856) "
              x="34.9142713"
              y="37.3285693"
              width="59.5215111"
              height="1.57857362"
            />
            <rect
              id="Rectangle-path"
              fill="#F2F2F2"
              fillRule="nonzero"
              transform="translate(64.767933, 79.717887) rotate(-0.095425) translate(-64.767933, -79.717887) "
              x="35.0071777"
              y="78.9286004"
              width="59.5215111"
              height="1.57857362"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(64.675086, 86.589294) rotate(-0.095417) translate(-64.675086, -86.589294) "
              x="35.007188"
              y="85.8928646"
              width="59.3357966"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(64.675175, 89.375008) rotate(-0.095417) translate(-64.675175, -89.375008) "
              x="35.0072772"
              y="88.6785789"
              width="59.3357966"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(60.078743, 92.160856) rotate(-0.095417) translate(-60.078743, -92.160856) "
              x="35.0072799"
              y="91.4644264"
              width="50.1429267"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(55.667889, 49.167955) rotate(-0.095425) translate(-55.667889, -49.167955) "
              x="34.9142889"
              y="48.4715252"
              width="41.5072004"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(55.667915, 51.953681) rotate(-0.095425) translate(-55.667915, -51.953681) "
              x="34.9143151"
              y="51.257251"
              width="41.5072004"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(50.421443, 66.300150) rotate(-0.095425) translate(-50.421443, -66.300150) "
              x="34.9142788"
              y="65.0930058"
              width="31.0143287"
              height="2.41428906"
            />
            <rect
              id="Rectangle-path"
              fill="#F2F2F2"
              fillRule="nonzero"
              transform="translate(64.675070, 58.917949) rotate(-0.095425) translate(-64.675070, -58.917949) "
              x="34.9143142"
              y="58.1286622"
              width="59.5215111"
              height="1.57857362"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(55.667945, 69.968000) rotate(-0.095425) translate(-55.667945, -69.968000) "
              x="34.9143446"
              y="69.2715707"
              width="41.5072004"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(55.667941, 72.753807) rotate(-0.095425) translate(-55.667941, -72.753807) "
              x="34.9143413"
              y="72.0573779"
              width="41.5072004"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(43.317873, 104.603708) rotate(-0.095425) translate(-43.317873, -104.603708) "
              x="35.0071471"
              y="103.907279"
              width="16.6214516"
              height="1.39285907"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(41.925105, 107.389447) rotate(-0.095425) translate(-41.925105, -107.389447) "
              x="35.0072386"
              y="106.693018"
              width="13.8357335"
              height="1.39285907"
            />
            <g id="Group" transform="translate(34.357143, 23.214286)" fill="#888888" fillRule="nonzero">
              <rect
                id="Rectangle-path"
                transform="translate(7.475048, 1.253543) rotate(-0.095425) translate(-7.475048, -1.253543) "
                x="0.557180802"
                y="0.742827571"
                width="13.8357335"
                height="1.02142999"
              />
              <rect
                id="Rectangle-path"
                transform="translate(7.475079, 5.339274) rotate(-0.095425) translate(-7.475079, -5.339274) "
                x="0.557212038"
                y="4.82855877"
                width="13.8357335"
                height="1.02142999"
              />
              <rect
                id="Rectangle-path"
                transform="translate(7.475063, 3.296408) rotate(-0.095425) translate(-7.475063, -3.296408) "
                x="0.55719642"
                y="2.78569317"
                width="13.8357335"
                height="1.02142999"
              />
              <rect
                id="Rectangle-path"
                transform="translate(7.475094, 7.382139) rotate(-0.095425) translate(-7.475094, -7.382139) "
                x="0.557227655"
                y="6.87142438"
                width="13.8357335"
                height="1.02142999"
              />
            </g>
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(64.628711, 25.396505) rotate(-0.095425) translate(-64.628711, -25.396505) "
              x="55.6215557"
              y="23.8643599"
              width="18.0143107"
              height="3.06428996"
            />
            <rect
              id="Rectangle-path"
              fill="#888888"
              fillRule="nonzero"
              transform="translate(64.628750, 29.575076) rotate(-0.095425) translate(-64.628750, -29.575076) "
              x="58.4073132"
              y="28.0429313"
              width="12.4428744"
              height="3.06428996"
            />
            <g id="Group" transform="translate(78.928571, 22.285714)">
              <polygon id="Shape" stroke="#888888" strokeWidth="0.5" points="0.65 0.464285714 0.65 10.1214286 15.5071429 10.1214286 15.5071429 0.464285714" />
              <g transform="translate(2.785714, 3.714286)" fill="#18CDB6" fillRule="nonzero" id="Rectangle-path">
                <rect
                  transform="translate(0.708969, 1.745453) rotate(-90.095425) translate(-0.708969, -1.745453) "
                  x="-0.59103302"
                  y="1.23473808"
                  width="2.60000361"
                  height="1.02142999"
                />
                <rect
                  transform="translate(2.543116, 1.809060) rotate(-90.095425) translate(-2.543116, -1.809060) "
                  x="1.2431143"
                  y="1.29834522"
                  width="2.60000361"
                  height="1.02142999"
                />
                <rect
                  transform="translate(4.377109, 1.779903) rotate(-90.095425) translate(-4.377109, -1.779903) "
                  x="3.07710697"
                  y="1.26918808"
                  width="2.60000361"
                  height="1.02142999"
                />
                <rect
                  transform="translate(6.211194, 1.750746) rotate(-90.095425) translate(-6.211194, -1.750746) "
                  x="4.9111925"
                  y="1.24003094"
                  width="2.60000361"
                  height="1.02142999"
                />
                <rect
                  transform="translate(8.045187, 1.721589) rotate(-90.095425) translate(-8.045187, -1.721589) "
                  x="6.74518516"
                  y="1.21087379"
                  width="2.60000361"
                  height="1.02142999"
                />
                <rect
                  transform="translate(9.879334, 1.785196) rotate(-90.095425) translate(-9.879334, -1.785196) "
                  x="8.57933249"
                  y="1.27448094"
                  width="2.60000361"
                  height="1.02142999"
                />
              </g>
            </g>
            <path
              d="M115.05,56.55 C115.421429,56.9214286 115.607143,57.3857143 115.607143,57.85 C115.607143,58.4071429 115.421429,58.7785714 115.05,59.15 L100.935714,73.2642857 L98.2428571,75.9571429 C97.8714286,76.3285714 97.4071429,76.5142857 96.9428571,76.5142857 C96.3857143,76.5142857 96.0142857,76.3285714 95.6428571,75.9571429 L92.95,73.2642857 L85.8928571,66.2071429 C85.5214286,65.8357143 85.3357143,65.3714286 85.3357143,64.9071429 C85.3357143,64.35 85.5214286,63.9785714 85.8928571,63.6071429 L88.5857143,60.9142857 C88.9571429,60.5428571 89.4214286,60.3571429 89.8857143,60.3571429 C90.4428571,60.3571429 90.8142857,60.5428571 91.1857143,60.9142857 L96.9428571,66.6714286 L109.757143,53.8571429 C110.128571,53.4857143 110.592857,53.3 111.057143,53.3 C111.614286,53.3 111.985714,53.4857143 112.357143,53.8571429 L115.05,56.55 Z"
              id="Shape"
              fill="#18CDB6"
              fillRule="nonzero"
            />
            <g id="Group" transform="translate(91.928571, 12.071429)" fill="#FF811A" fillRule="nonzero">
              <path
                d="M12.6285714,13.65 L6.12857143,13.65 C5.66428571,13.65 5.29285714,13.2785714 5.29285714,12.8142857 C5.29285714,12.35 5.66428571,11.9785714 6.12857143,11.9785714 L12.6285714,11.9785714 C13.0928571,11.9785714 13.4642857,12.35 13.4642857,12.8142857 C13.4642857,13.2785714 13.0928571,13.65 12.6285714,13.65 Z"
                id="Shape"
              />
              <path
                d="M2.04285714,1.39285714 L2.04285714,7.89285714 C2.04285714,8.35714286 1.67142857,8.72857143 1.20714286,8.72857143 C0.742857143,8.72857143 0.371428571,8.35714286 0.371428571,7.89285714 L0.371428571,1.39285714 C0.371428571,0.928571429 0.742857143,0.557142857 1.20714286,0.557142857 C1.67142857,0.557142857 2.04285714,0.928571429 2.04285714,1.39285714 Z"
                id="Shape"
              />
              <path
                d="M10.9571429,4.27142857 L5.47857143,9.75 C5.10714286,10.1214286 4.64285714,10.1214286 4.27142857,9.75 C3.9,9.37857143 3.9,8.91428571 4.27142857,8.54285714 L9.75,3.06428571 C10.1214286,2.69285714 10.5857143,2.69285714 10.9571429,3.06428571 C11.3285714,3.34285714 11.3285714,3.9 10.9571429,4.27142857 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
