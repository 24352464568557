import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { EvidenceModel } from '../../models';
import { EvidenceTypeEnum } from './enums';

export const VISIBILITY_CHECK_EVIDENCE_DOCUMENT_REFERENCE = createContextCheck(
  (context: EvidenceModel): boolean =>
    context.type !== null &&
    [
      EvidenceTypeEnum.Probate,
      EvidenceTypeEnum.LettersOfAdministration,
      EvidenceTypeEnum.DeedOfAppointment,
      EvidenceTypeEnum.DeathCertificate,
      EvidenceTypeEnum.CertificateUnderNSWTrusteeAndGuardianAct
    ].includes(context.type)
);
