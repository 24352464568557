import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchOrganisationProfile, actionUpdateOrganisationProfilePicture } from '../actions/organisationProfile';
import { CompanyProfileApiResponse } from '../models';

export interface OrganisationProfileState {
  detail?: CompanyProfileApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: OrganisationProfileState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  OrganisationProfileState,
  Action
>(initialState)
  .handleAction(actionFetchOrganisationProfile.request, (state): OrganisationProfileState => {
    return {
      ...state,
      status: 'pending',
      error: undefined
    };
  })
  .handleAction(actionFetchOrganisationProfile.success, (state, action): OrganisationProfileState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchOrganisationProfile.failure, (state, action): OrganisationProfileState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateOrganisationProfilePicture, (state, action): OrganisationProfileState => {
    const { detail } = state;

    if (detail == null) {
      return state;
    }

    const { organisationDetails } = detail;

    if (organisationDetails == null) {
      return state;
    }

    const { profileImageUrl = '' } = action.payload;
    return {
      ...state,
      detail: {
        ...detail,
        organisationDetails: {
          ...organisationDetails,
          profileImageUrl
        }
      }
    };
  });

export default reducer;
