import { createContextCheck, createContextCheckUsingRoot } from '@sympli-mfe/document-forms-framework/utils';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-25/necds';
import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import { PartyBook$PartyTypeEnum, PropertyDetails$LandDimensions$KnownDetailsEnum, PropertyDetails$PrimaryLandUseEnum, TransactionDetail$ConsiderationTypeEnum } from './enums';
import {
  EMPTY_ARRAY,
  LandDimensionsModel,
  NoaPropertyDetailModel,
  NoticeOfAcquisitionDocumentModel,
  TransactionDetailModel,
  TransfereeDetailModel,
  TransferorDetailModel
} from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - transferors
 - transferorRepresentativeDetail

 schema version:
[
  {
    "workspaceRoleId": 0
  },
  {
    "workspaceRoleId": 3
  }
]
*/
export const VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL = createContextCheck((context: NoticeOfAcquisitionDocumentModel): boolean => {
  const { workspaceRoleId } = context;
  return workspaceRoleId === WorkspaceRoleEnum.IncomingMortgagee || workspaceRoleId === WorkspaceRoleEnum.Vendor;
});

/**
 path:
 - transferees
 - propertyDetails
 - transactionDetail
 - transfereeAgentSolicitorDetail

 schema version:
[
  {
    "workspaceRoleId": 0
  },
  {
    "workspaceRoleId": 2
  }
]
*/
export const VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL = createContextCheck(
  (context: NoticeOfAcquisitionDocumentModel): boolean => {
    const { workspaceRoleId } = context;
    return workspaceRoleId === WorkspaceRoleEnum.IncomingMortgagee || workspaceRoleId === WorkspaceRoleEnum.Purchaser;
  }
);

/**
 path:
 - propertyDetails[*].landDimensions.landArea

 schema version:
[
  {
    "knownDetails": 1
  }
]
*/
export const VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA = createContextCheck(
  (context: LandDimensionsModel): boolean => context.knownDetails === PropertyDetails$LandDimensions$KnownDetailsEnum.LandArea
);

/**
 path:
 - propertyDetails[*].landDimensions.unitOfEntitlement

 schema version:
[
  {
    "knownDetails": 2
  }
]
*/
export const VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT = createContextCheck(
  (context: LandDimensionsModel): boolean => context.knownDetails === PropertyDetails$LandDimensions$KnownDetailsEnum.UnitOfEntitlement
);

/**
 path:
 - propertyDetails[*].constructionOfMainStructure

 schema version:
[
  {
    "primaryLandUse": 110
  },
  {
    "primaryLandUse": 112
  },
  {
    "primaryLandUse": 117
  },
  {
    "primaryLandUse": 120
  },
  {
    "primaryLandUse": 125
  },
  {
    "primaryLandUse": 126
  },
  {
    "primaryLandUse": 140
  },
  {
    "primaryLandUse": 141
  },
  {
    "primaryLandUse": 142
  },
  {
    "primaryLandUse": 210
  },
  {
    "primaryLandUse": 211
  },
  {
    "primaryLandUse": 212
  },
  {
    "primaryLandUse": 213
  },
  {
    "primaryLandUse": 214
  },
  {
    "primaryLandUse": 215
  },
  {
    "primaryLandUse": 216
  },
  {
    "primaryLandUse": 220
  },
  {
    "primaryLandUse": 230
  },
  {
    "primaryLandUse": 234
  },
  {
    "primaryLandUse": 240
  },
  {
    "primaryLandUse": 250
  },
  {
    "primaryLandUse": 251
  },
  {
    "primaryLandUse": 270
  },
  {
    "primaryLandUse": 271
  },
  {
    "primaryLandUse": 275
  },
  {
    "primaryLandUse": 280
  },
  {
    "primaryLandUse": 281
  },
  {
    "primaryLandUse": 284
  },
  {
    "primaryLandUse": 310
  },
  {
    "primaryLandUse": 311
  },
  {
    "primaryLandUse": 320
  },
  {
    "primaryLandUse": 321
  },
  {
    "primaryLandUse": 325
  },
  {
    "primaryLandUse": 331
  },
  {
    "primaryLandUse": 525
  },
  {
    "primaryLandUse": 540
  },
  {
    "primaryLandUse": 541
  },
  {
    "primaryLandUse": 544
  },
  {
    "primaryLandUse": 545
  },
  {
    "primaryLandUse": 546
  },
  {
    "primaryLandUse": 550
  },
  {
    "primaryLandUse": 561
  },
  {
    "primaryLandUse": 562
  },
  {
    "primaryLandUse": 610
  },
  {
    "primaryLandUse": 620
  },
  {
    "primaryLandUse": 710
  },
  {
    "primaryLandUse": 711
  },
  {
    "primaryLandUse": 720
  },
  {
    "primaryLandUse": 721
  },
  {
    "primaryLandUse": 722
  },
  {
    "primaryLandUse": 723
  },
  {
    "primaryLandUse": 725
  },
  {
    "primaryLandUse": 726
  },
  {
    "primaryLandUse": 740
  },
  {
    "primaryLandUse": 750
  },
  {
    "primaryLandUse": 820
  },
  {
    "primaryLandUse": 821
  }
]
*/
export const VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE = createContextCheck((context: NoaPropertyDetailModel): boolean => {
  const primaryLandUse = context.primaryLandUse;
  return (
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Dwelling ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RowHouseTerrace ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RuralLifestyleDwelling ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.UnitFreestanding ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.FlatMultiStorey ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CarPark ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RetirementUnit ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RetirementComplex ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.AgedCareComplex ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.SingleRetail ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.MultiRetail ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.MixedUseRetail ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.ShoppingCentre ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.NationalRetail ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.ServiceStation ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.MultiServiceStation ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.OfficePremises ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.HotelMotel ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CaravanPark ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.LicensedPremisesrestaurant ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.EntertainmentComplex ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CinemaComplex ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.HealthSurgery ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.HealthClinic ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.VeterinaryClinic ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.GroundParking ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.MultiLevelParking ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CarYard ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Factory ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.ProcessingFactory ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.GeneralWarehouse ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.OpenStorage ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CoolstoreColdstore ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Abattoirs ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.DairyCattle ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CattleFeedLot ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Poultry ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.HorseStudStables ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Piggery ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.KennelCattery ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.MarketGarden ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Vineyard ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PlantNursery ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.GasFuelWells ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PowerStation ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PublicHospital ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PrivateHospital ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Kindergarten ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.GovernmentSchool ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.SchoolCamps ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PrivateSchool ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.University ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Tafe ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PlaceOfWorship ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Hall ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.IndoorSports ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.OutdoorSports
  );
});

/**
 path:
 - propertyDetails[*].numberOfBedrooms

 schema version:
[
  {
    "primaryLandUse": 110
  },
  {
    "primaryLandUse": 112
  },
  {
    "primaryLandUse": 117
  },
  {
    "primaryLandUse": 120
  },
  {
    "primaryLandUse": 125
  },
  {
    "primaryLandUse": 140
  },
  {
    "primaryLandUse": 141
  },
  {
    "primaryLandUse": 142
  },
  {
    "primaryLandUse": 212
  },
  {
    "primaryLandUse": 230
  },
  {
    "primaryLandUse": 234
  },
  {
    "primaryLandUse": 710
  },
  {
    "primaryLandUse": 711
  },
  {
    "primaryLandUse": 722
  },
  {
    "primaryLandUse": 725
  }
]
*/
export const VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS = createContextCheck((context: NoaPropertyDetailModel): boolean => {
  const primaryLandUse = context.primaryLandUse;
  return (
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.Dwelling ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RowHouseTerrace ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RuralLifestyleDwelling ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.UnitFreestanding ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.FlatMultiStorey ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RetirementUnit ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.RetirementComplex ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.AgedCareComplex ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.MixedUseRetail ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.HotelMotel ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.CaravanPark ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PublicHospital ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.PrivateHospital ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.SchoolCamps ||
    primaryLandUse === PropertyDetails$PrimaryLandUseEnum.University
  );
});

/**
 path:
 - transactionDetail.gstAmount
 - transactionDetail.deposit
 - transactionDetail.businessGoodwillAmount
 - transactionDetail.businessIntellectualPropertyAmount
 - transactionDetail.goodsComponentAmount
 - transactionDetail.contractRebateAmount

 schema version:
[
  {
    "considerationType": 1
  }
]
*/
export const VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT =
  createContextCheck((context: TransactionDetailModel): boolean => context.considerationType === TransactionDetail$ConsiderationTypeEnum.Monetary);

/**
 path:
 - transactionDetail.marketValue
 - transactionDetail.valuerName
 - transactionDetail.valuerLicenceNumber

 schema version:
[
  {
    "considerationType": 2
  }
]
*/
export const VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER = createContextCheck(
  (context: TransactionDetailModel): boolean => context.considerationType === TransactionDetail$ConsiderationTypeEnum.NonMonetary
);

/**
 path:
 - transactionDetail.dateOfPossessionTransfer

 schema version:
[
  {
    "isTransferDateSameAsSettlementDate": false
  }
]
*/
export const VISIBILITY_CHECK_TRANSACTION_DETAIL$DATE_OF_POSSESSION_TRANSFER = createContextCheck(
  (context: TransactionDetailModel): boolean => context.isTransferDateSameAsSettlementDate === false
);

/**
 path:
 - transactionDetail.settlementDate

 schema version:
[
  {
    "isTransferDateSameAsSettlementDate": true
  }
]
*/
export const VISIBILITY_CHECK_TRANSACTION_DETAIL$SETTLEMENT_DATE = createContextCheck(
  (context: TransactionDetailModel): boolean => context.isTransferDateSameAsSettlementDate === true
);

// custom visibility checks
const isIndividualPartyType = (partyBookId: string, values: NoticeOfAcquisitionDocumentModel): boolean => {
  const parties = values.partyBook ?? EMPTY_ARRAY;
  return parties.find(({ id }) => id === partyBookId)?.partyType === PartyBook$PartyTypeEnum.Individual;
};

export const VISIBILITY_CHECK_TRANSFEREE_BIRTH_DATE = createContextCheckUsingRoot((context: TransfereeDetailModel, _, root: NoticeOfAcquisitionDocumentModel): boolean => {
  return isIndividualPartyType(context.partyBookId, root);
});

export const VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS = createContextCheckUsingRoot(
  ({ sroPartyCapacity, partyBookId }: TransfereeDetailModel, _, root: NoticeOfAcquisitionDocumentModel): boolean => {
    return (
      isIndividualPartyType(partyBookId, root) && //
      (!sroPartyCapacity || sroPartyCapacity === NecdsPartyCapacityEnum.NoneOfTheAbove)
    );
  }
);

export const VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL = createContextCheck(({ sroPartyCapacity }: TransfereeDetailModel): boolean => {
  return sroPartyCapacity === NecdsPartyCapacityEnum.CustodianBareTrustee || sroPartyCapacity === NecdsPartyCapacityEnum.TrusteeOfATrust;
});

export const VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE = createContextCheckUsingRoot((context: TransferorDetailModel, _, root: NoticeOfAcquisitionDocumentModel): boolean => {
  return isIndividualPartyType(context.partyBookId, root);
});

export const VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL = createContextCheck(({ sroPartyCapacity }: TransferorDetailModel): boolean => {
  return sroPartyCapacity === NecdsPartyCapacityEnum.CustodianBareTrustee || sroPartyCapacity === NecdsPartyCapacityEnum.TrusteeOfATrust;
});

export const VISIBILITY_CHECK_PROPERTY_DETAILS$PRIMARY_LAND_USE = createContextCheck((context: NoaPropertyDetailModel): boolean => {
  return Number.isInteger(context.landUseType);
});

export const VISIBILITY_CHECK_PROPERTY_DETAILS$SALE_PRICE_PER_PROPERTY = createContextCheckUsingRoot(
  (context: NoaPropertyDetailModel, _, root: NoticeOfAcquisitionDocumentModel): boolean => {
    return root.transactionDetail.considerationType === TransactionDetail$ConsiderationTypeEnum.Monetary;
  }
);

export const VISIBILITY_CHECK_STATE_REVENUE_OFFICE_HELPER = createContextCheck((context: TransfereeDetailModel | TransferorDetailModel): boolean => {
  return (
    context.sroPartyCapacity === NecdsPartyCapacityEnum.CustodianBareTrustee || context.sroPartyCapacity === NecdsPartyCapacityEnum.TrusteeOfATrust //
  );
});

export const VISIBILITY_CHECK_ALL_PROPERTIES_ASSIGNED = createContextCheckUsingRoot((_, root: NoticeOfAcquisitionDocumentModel): boolean => {
  const { allTitlesAssigned } = root;
  return allTitlesAssigned!;
});

export const VISIBILITY_CHECK_TRANSFEREE_PRIMARY_CONTRACT = createContextCheck((context: NoticeOfAcquisitionDocumentModel): boolean => {
  return context.transferees?.length > 1;
});
