import * as React from 'react';

import classNames from 'classnames';
import MuiSkeleton from '@mui/material/Skeleton';

import { ParticipantStatusEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { CardDetail } from 'src/containers/dashboard/components/card';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { SettlementDateDetailsState } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { WorkspaceBasicInfoState } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';

interface Row {
  subscriberName: string;
  workspaceRole: LookupEnumModel<WorkspaceRoleEnum>;
  participantStatus: LookupEnumModel<ParticipantStatusEnum>;
  settlementAcceptance: {
    isLoading: boolean;
    isAcceptedByUser?: boolean;
    hasProposedNewDate: boolean;
    isDateOnlyOrNoDateTime: boolean;
  };
}

type NestedFormatterProps = Pick<FormatterInputModel<Row>, 'row' | 'tooltipHandlers'> & {
  className?: string;
  isReady: boolean;
};

function workspaceRolesFormatter({
  //
  className,
  row,
  isReady,
  tooltipHandlers
}: NestedFormatterProps) {
  return (
    <div data-binding="workspaceRoles" className={classNames('truncated font-[400] text-[var(--neutral-400)]', className)} {...(isReady && tooltipHandlers)}>
      {isReady ? row.workspaceRole.name : <MuiSkeleton variant="text" />}
    </div>
  );
}

const mapping: Record<ParticipantStatusEnum, 'ok' | 'neutral' | 'warning' | 'error'> = {
  [ParticipantStatusEnum.Pending]: 'warning',
  [ParticipantStatusEnum.Accepted]: 'ok',
  [ParticipantStatusEnum.Rejected]: 'error',
  [ParticipantStatusEnum.InviteForwarded]: 'neutral',
  [ParticipantStatusEnum.Withdrawn]: 'neutral'
};

function InvitationsCardDetail({
  //
  workspaceParticipantsState: {
    //
    status,
    isLoading,
    items
  },
  settlementDateDetailsState,
  workspaceBasicInfoState: { detail, status: workspaceBasicInfoStatus }
}: {
  workspaceParticipantsState: WorkspaceParticipantsState;
  settlementDateDetailsState: SettlementDateDetailsState;
  workspaceBasicInfoState: WorkspaceBasicInfoState;
}) {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const isReady: boolean = (status === 'resolved' || status === 'refetching') && (workspaceBasicInfoStatus === 'resolved' || workspaceBasicInfoStatus === 'refetching');
  const isSettlementAcceptanceReady = settlementDateDetailsState.status === 'resolved';

  const rows: Row[] = isReady
    ? items
        .filter(p => !p.archivedStatus)
        .map(p => {
          const settlementParticipant = settlementDateDetailsState.detail?.participants.find(sp => sp.id === p.id);

          return {
            subscriberName: p.name,
            workspaceRole: p.workspaceRole,
            participantStatus: p.participantStatus,
            // TODO THIS LOGIC NEEDS TO BE REVIEWED TO MATCH RULES IN WORKSPACE DETAIL
            settlementAcceptance: {
              isDateOnlyOrNoDateTime: !detail?.settlementDate,
              isLoading: !isSettlementAcceptanceReady,
              isAcceptedByUser: settlementParticipant?.hasAccepted,
              hasProposedNewDate: !!(settlementDateDetailsState.detail?.proposedByParticipant?.id === settlementParticipant?.id)
            }
          };
        })
    : [];

  const columns: ColumnsModel<Row> = {
    invitations: {
      verticalAlign: 'top',
      label: 'Invitations',
      formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
        return (
          <div className="text-[12px] leading-[14px]">
            <div data-binding="subscriberName" className="truncated font-[700] text-[var(--neutral-1000)]" {...(isReady && tooltipHandlers)}>
              {isReady ? row.subscriberName : <MuiSkeleton variant="text" />}
            </div>
            {(screenVariant === 'large-screen' || screenVariant === 'medium-screen') &&
              workspaceRolesFormatter({
                //
                row,
                isReady,
                tooltipHandlers
              })}
          </div>
        );
      }
    },
    ...(screenVariant === 'small-laptop' && {
      workspaceRole: {
        verticalAlign: 'top',
        width: 150,
        label: '',
        formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
          return workspaceRolesFormatter({
            //
            row,
            isReady,
            tooltipHandlers,
            className: 'text-[12px] leading-[14px]'
          });
        }
      }
    }),
    participantStatus: {
      verticalAlign: 'top',
      width: screenVariant === 'medium-screen' ? 70 : 80,
      label: 'INVITE',
      formatter: ({ row: { participantStatus }, tooltipHandlers }: FormatterInputModel<Row>) => {
        const variant = mapping[participantStatus.id];
        return (
          <div data-binding="participantStatus">
            {isReady ? (
              <VerticalStatusLine //
                text={participantStatus.name}
                variant={variant}
                tooltipHandlers={tooltipHandlers}
                className="h-[14px]"
                textClassName="truncated"
              />
            ) : (
              <MuiSkeleton variant="text" />
            )}
          </div>
        );
      }
    },
    settlementAcceptanceStatus: {
      width: screenVariant === 'medium-screen' ? 65 : 80,
      verticalAlign: 'top',
      label: 'DATE & TIME',
      formatter: ({ row: { settlementAcceptance }, tooltipHandlers }: FormatterInputModel<Row>) => {
        const text = settlementAcceptance.isDateOnlyOrNoDateTime
          ? 'Not set'
          : settlementAcceptance.isAcceptedByUser
            ? 'Accepted'
            : settlementAcceptance.isAcceptedByUser === undefined
              ? 'Pending'
              : 'New proposal';
        const variant = settlementAcceptance.isDateOnlyOrNoDateTime
          ? 'neutral'
          : settlementAcceptance.isAcceptedByUser
            ? 'ok'
            : settlementAcceptance.isAcceptedByUser === undefined
              ? 'warning'
              : 'error';

        return (
          <div data-binding="settlementAcceptanceStatus">
            {isReady && !settlementAcceptance.isLoading ? (
              <VerticalStatusLine //
                text={text}
                variant={variant}
                tooltipHandlers={tooltipHandlers}
                className="h-[14px]"
                textClassName="truncated"
              />
            ) : (
              <MuiSkeleton variant="text" />
            )}
          </div>
        );
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
      {...(isLoading && {
        isLoading: true,
        pageSize: 4
      })}
      {...(isReady && {
        pageSize: rows.length
      })}
    />
  );
}

export default React.memo(InvitationsCardDetail);
