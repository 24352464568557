import { NSW_PARTY_FORM_CONFIG as NSW_LEASE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { ApiLease2_21_1Model, DatePeriod, FormDatePeriod, Lease2_21_1Model } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import { fallbackMap } from './fallback';
import { filterLessors } from './helpers';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_LEASE_PARTY_FORM_CONFIG
});
class Converter implements IConverter<Lease2_21_1Model, ApiLease2_21_1Model> {
  fromApiToFormModel(apiModel: ApiLease2_21_1Model, _context: IConverterContext): Lease2_21_1Model {
    const { titleReferences, leaseDetails, partyBook, lessees } = apiModel;
    // Implement any necessary conversion from API to form values
    const relinquishingIds = titleReferences
      .map(tr => tr.lessors!)
      .map(lessors => lessors.map(x => x.partyBookId))
      .flat();

    if (titleReferences.length === 1) {
      titleReferences[0].isSelected = true;
    }

    let formModel: Lease2_21_1Model = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(partyBook || [], relinquishingIds),
      leaseDetails: leaseDetails
        ? {
            ...leaseDetails,
            leasePeriod: this.convertDatePeriodWithoutTime(leaseDetails.leasePeriod),
            renewDetails: {
              ...leaseDetails.renewDetails,
              renewalPeriods: leaseDetails.renewDetails.optionToRenew ? leaseDetails.renewDetails.renewalPeriods.map(x => this.convertDatePeriodWithoutTime(x)) : []
            }
          }
        : undefined,
      lessors: filterLessors(titleReferences),
      lessees: convertReceivingTenancyDetailFromApiToForm(lessees)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: Lease2_21_1Model, _originalApiModel: ApiLease2_21_1Model): ApiLease2_21_1Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      lessees: lesseesFromForm,
      partyBook: partyBookFromForm,
      lessors,
      ...rest
    } = formModel;

    return {
      ...rest,
      attachments: formModel.documentAttachments,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], _originalApiModel.partyBook || []) as any, // TODO resolve this typing
      lessees: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: lesseesFromForm!, partyBookFromForm: partyBookFromForm || [] })
    };
  }

  private convertDatePeriodWithoutTime(datePeriod: DatePeriod): FormDatePeriod {
    if (!datePeriod || !datePeriod.startDate || !datePeriod.endDate) {
      return { startDate: null, endDate: null, dateLength: null };
    }

    return {
      ...datePeriod,
      startDate: new Date(new Date(datePeriod.startDate).toDateString()),
      endDate: new Date(new Date(datePeriod.endDate).toDateString())
    };
  }
}

export default new Converter();
