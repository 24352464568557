import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: 4,
      paddingBottom: 4,
      minHeight: 30,
      boxSizing: 'border-box',
      '& + &': {
        borderTop: `1px solid ${theme.palette.divider}`
      }
    },
    alignTop: {
      alignItems: 'flex-start'
    },
    alignMiddle: {
      alignItems: 'center'
    },
    header: {
      flex: `0 0 150px`
    },
    body: {
      flex: 'auto'
    }
  }),
  { name: 'DetailLine' }
);
