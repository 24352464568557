import { UserRoleEnum } from '@sympli/api-gateway/enums';

export enum UserStatusEnum {
  Initial = 0,
  Active = 1,
  Suspended = 2,
  Terminated = 3,
  Current = 4
}

export const UserRoleEnumDisplayNameMap: Record<UserRoleEnum, string> = {
  [UserRoleEnum.SuperAdmin]: 'Super admin',
  [UserRoleEnum.Admin]: 'Admin',
  [UserRoleEnum.Standard]: 'Standard'
};

type SelectOptions = Array<{ id: number; name: string }>;

export const UserStatusTabOptions: SelectOptions = [
  { id: UserStatusEnum.Current, name: 'Current' },
  { id: UserStatusEnum.Suspended, name: 'Suspended' },
  { id: UserStatusEnum.Terminated, name: 'Archived' }
];
export const UserStatusSelectOptions: SelectOptions = [
  { id: UserStatusEnum.Active, name: 'Active' },
  { id: UserStatusEnum.Initial, name: 'Not Activated' }
];

export const UserRoleSelectOptions: SelectOptions = [
  { id: UserRoleEnum.SuperAdmin, name: 'Super admin' },
  { id: UserRoleEnum.Admin, name: 'Admin' },
  { id: UserRoleEnum.Standard, name: 'Standard' }
];
