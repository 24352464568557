import queryString from 'query-string';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { UserTimeZoneEnum } from '@sympli/api-gateway/enums';

import { Store } from 'src/reducers';
import http from 'src/utils/http';
import { getTimeInTimeZone } from 'src/utils/timezone/timezoneHelper';
import { actionFetchLogsFeed, LogsApiRequest, LogsApiResponse } from '../actions/logs';
import { getTemplate } from '../log/helper';
import { LogModel } from '../log/models';

const fetchLogsFeed = (query: LogsApiRequest) => {
  const { pageNumber, pageSize } = query;
  const q = {
    pageNumber,
    pageSize
  };

  const uri = `/settings/reporting/logs?${queryString.stringify(q)}`;
  return http.get<LogsApiResponse>(uri);
};

function selectUserProfileTimezone(state: Store) {
  return state.profile.data?.timezone;
}

export function* sagaFetchLogsFeed(action: ReturnType<typeof actionFetchLogsFeed.request>) {
  try {
    const { items, ...rest } = yield* call(fetchLogsFeed, action.payload);

    const timezone: string = yield select(selectUserProfileTimezone);
    const jurisdictionId = UserTimeZoneEnum[timezone];

    const converted: Array<LogModel> = items.map(item => {
      const { category, data, created } = item;
      const dataObject = JSON.parse(data);
      return {
        ...item,
        data: dataObject,
        created: getTimeInTimeZone(new Date(created), jurisdictionId),
        template: getTemplate(category, dataObject)
      };
    });
    yield put(actionFetchLogsFeed.success({ data: { ...rest, items: converted } }));
  } catch (error) {
    yield put(actionFetchLogsFeed.failure({ error }));
  }
}

export default [takeLatest(actionFetchLogsFeed.request, sagaFetchLogsFeed)];
