import * as React from 'react';

import ProprietorNames from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared/components/proprietor-names';
import { NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-shared/necds';
import { DuplicateCtIssuedTypeEnum, DuplicateCtTypeEnum, JurisdictionsEnum, ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli/api-gateway/enums';
import { LandRegistryDetail } from '@sympli/api-gateway/shared';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { DuplicateCtIssuedTypeMapping } from 'src/models/title';
import { wordifyEnum } from 'src/utils/enums';
import { titleAddress } from 'src/utils/formatters';
import { useStyles } from './styles';

const groupPadding: React.CSSProperties = { paddingTop: 20, paddingBottom: 6 };
interface Props {
  ris: LandRegistryDetail;
  jurisdiction: LookupEnumModel<JurisdictionsEnum>;
}

function PropertyDetails(props: Props) {
  const { proprietors, propertyAddress, landDescriptions, estateType, titleProductionRecord, ctController, duplicateCtType, duplicateCtIssuedType } = props.ris;
  const { jurisdiction } = props;

  const controllingParties: string[] =
    titleProductionRecord?.consentingParties.map(x => x.legalEntityName) || (ctController?.legalEntityName && [ctController.legalEntityName]) || [];

  const getTitleStatus = () => {
    const duplicateCtIssuedTypeStatus: string | undefined =
      duplicateCtIssuedType === DuplicateCtIssuedTypeEnum.None ? undefined : DuplicateCtIssuedTypeMapping.get(duplicateCtIssuedType);
    const duplicateCtTypeStatus: string | undefined = duplicateCtType === DuplicateCtTypeEnum.None ? undefined : wordifyEnum(DuplicateCtTypeEnum).get(duplicateCtType);
    return duplicateCtIssuedTypeStatus ?? duplicateCtTypeStatus;
  };

  return (
    <Section title="Property details">
      <Property {...{ propertyAddress, landDescriptions, estateType, jurisdiction }} />
      <Proprietors proprietors={proprietors} jurisdiction={jurisdiction} />
      <TitleStatus status={getTitleStatus()} />
      <ControllingParty parties={controllingParties} />
    </Section>
  );
}

function Property({
  propertyAddress,
  landDescriptions,
  estateType,
  jurisdiction
}: {
  propertyAddress?: LandRegistryDetail['propertyAddress'];
  landDescriptions?: LandRegistryDetail['landDescriptions'];
  estateType?: string;
  jurisdiction: LookupEnumModel<JurisdictionsEnum>;
}) {
  const classes = useStyles();
  const formattedAddress = titleAddress(propertyAddress, jurisdiction.id);
  if (!formattedAddress && !landDescriptions?.length && !estateType) return null;
  const formattedLadnDescriptions = landDescriptions?.map((e, index) => (
    <span style={{ display: 'block' }} key={index}>
      {e.legalDescription}
    </span>
  ));

  return (
    <FormGroup title="Property" style={groupPadding} classes={{ container: classes.formGroupContainer }}>
      <React.Fragment>
        {formattedAddress && <ReadOnlyField label="Address" value={formattedAddress} />}
        <FlexLayout alignItems="flex-start" fullWidth>
          {!!landDescriptions?.length && <ReadOnlyField label="Land description" value={<>{formattedLadnDescriptions}</>} />}
          {estateType && <ReadOnlyField label="Estate type" value={estateType} />}
        </FlexLayout>
      </React.Fragment>
    </FormGroup>
  );
}

function Proprietors({ proprietors, jurisdiction }: { proprietors?: LandRegistryDetail['proprietors']; jurisdiction: LookupEnumModel<JurisdictionsEnum> }) {
  const classes = useStyles();
  if (!proprietors?.length) return null;

  const dividerClass = (length: number, index: number) => (index !== length - 1 ? classes.divider : undefined);

  const mappings = new Map<ProprietorGroupTypeEnum, (e: JurisdictionsEnum) => string>([
    [ProprietorGroupTypeEnum.JointTenantsInterse, e => (e === JurisdictionsEnum.VIC ? 'Joint proprietors' : 'Joint tenants')],
    [ProprietorGroupTypeEnum.JointTenantsWithNoSurvivorship, _e => 'Joint Tenants With No Survivorship'],
    [ProprietorGroupTypeEnum.LifeEstate, _e => 'Life Estate']
  ]);

  function getNameAndShares({ proprietorGroups, tenancyType }: Exclude<LandRegistryDetail['proprietors'], undefined>[number]) {
    return proprietorGroups.map((pg, index) => {
      const share = tenancyType === TenancyTypeEnum.TenantsInCommon ? `${pg.shareFraction?.numerator}/${pg.shareFraction?.denominator}` : undefined;
      return (
        <FlexLayout key={index} fullWidth className={dividerClass(proprietorGroups.length, index)}>
          <ReadOnlyField
            label={index === 0 ? 'Name' : undefined}
            value={
              <ProprietorNames
                tenantsInCommon={tenancyType === TenancyTypeEnum.TenantsInCommon}
                label={mappings.get(pg.proprietorGroupType)?.(jurisdiction.id) ?? ''}
                names={pg.parties.map(getPartyName)}
              />
            }
          />
          {share && <ReadOnlyField label={index === 0 ? 'Share holdings' : undefined} value={share} className={classes.columnWidthAuto} />}
        </FlexLayout>
      );
    });
  }
  type Party = Exclude<LandRegistryDetail['proprietors'], undefined>[number]['proprietorGroups'][number]['parties'][number];
  function getPartyName(party: Party): string {
    if (party.partyCapacity) {
      // Note: Incase of party capacity within the title references returned from RIS we need to use the NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS
      // because atleast for title references the LRS RISConverter uses Tickle.LRS.Core.Contract.Common.PartyCapacity for all the jurisdictions
      const partyCapacityName = NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS.find(x => x.id === party.partyCapacity)?.name;
      if (partyCapacityName) {
        return `${party.legalEntityName} (${partyCapacityName})`;
      }
    }
    return party.legalEntityName;
  }

  return (
    <FormGroup title="Proprietors" style={groupPadding} classes={{ container: classes.formGroupContainer }}>
      {proprietors.map((proprietor, index) => {
        return (
          <React.Fragment key={index}>
            <ReadOnlyField label="Tenancy Type" value={wordifyEnum(TenancyTypeEnum).get(proprietor.tenancyType) ?? ''} />
            <div style={{ width: '100%' }} className={dividerClass(proprietors.length, index)}>
              {getNameAndShares(proprietor)}
            </div>
          </React.Fragment>
        );
      })}
    </FormGroup>
  );
}

function TitleStatus({ status }: { status?: string }) {
  const classes = useStyles();
  if (!status) return null;
  return (
    <FormGroup title="Title status" style={groupPadding} classes={{ container: classes.formGroupContainer }}>
      <ReadOnlyField value={status}></ReadOnlyField>
    </FormGroup>
  );
}

function ControllingParty({ parties }: { parties?: string[] }) {
  const classes = useStyles();
  if (!parties?.length) return null;
  return (
    <FormGroup title="Controlling party" style={groupPadding} classes={{ container: classes.formGroupContainer }}>
      <React.Fragment>
        {parties.map((party, index) => {
          return <ReadOnlyField key={index} value={party}></ReadOnlyField>;
        })}
      </React.Fragment>
    </FormGroup>
  );
}

export default React.memo(PropertyDetails);
