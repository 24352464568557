import * as React from 'react';

import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { PortalProps } from '@mui/material/Portal';
import { Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme, Theme, ThemeProvider } from '@mui/material/styles';

import { createTheme } from '.';
import TailwindIndicator from '../components/layout/v2/TailwindIndicator';

function ThemeWrapper({
  children,
  disableTransitions = false,
  rootElement,
  tailwindIndicatorProps
}: React.PropsWithChildren<{
  disableTransitions?: boolean;
  rootElement?: PortalProps['container'];
  tailwindIndicatorProps?: React.ComponentProps<typeof TailwindIndicator>;
}>) {
  const theme: Theme = createTheme({
    //
    disableTransitions,
    container: rootElement
  });

  const muiCache: EmotionCache = createCache({
    key: 'mui',
    prepend: true
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <TailwindIndicator {...tailwindIndicatorProps} />
        <CssVarsProvider
          defaultMode={theme.palette.mode}
          theme={extendTheme({
            colorSchemes: {
              light: {
                palette: theme.palette
              }
            },
            components: theme.components,
            typography: theme.typography
          })}
        >
          {children}
        </CssVarsProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
export default ThemeWrapper;
