import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: 4,
      background: theme.palette.error.light,
      padding: '14px 16px',
      marginBottom: 25
    },
    bold: {
      fontWeight: 500
    },
    icon: {
      paddingRight: 8
    },
    alert: {
      marginBottom: 25
    }
  }),
  {
    name: 'WizardErrorMessage'
  }
);
