import dateFormat from 'dateformat';

import { WA_ADDRESS_FORM_CONFIG as WA_M_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/wa/2-26/config';
import { waAddressChangeConversion } from '@sympli-mfe/document-components/party-form/wa/2-26/components/address-justification';
import { waNameChangeConversion } from '@sympli-mfe/document-components/party-form/wa/2-26/components/party-justification';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { BaseAddress, resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { WA_MORTGAGE_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterMortgagors, getMortgagorPartyBookIds } from './helper';
import { ApiMortgage2_26_0Model, Mortgage2_26_0Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: WA_M_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: WA_MORTGAGE_PARTY_FORM_CONFIG,
  // Add name change conversion here if necessary
  nameChangeConversion: waNameChangeConversion,
  addressChangeConversion: waAddressChangeConversion
});

class Converter implements IConverter<Mortgage2_26_0Model, ApiMortgage2_26_0Model> {
  fromApiToFormModel(apiValues: ApiMortgage2_26_0Model, context: IConverterContext): Mortgage2_26_0Model {
    // Implement any necessary conversion from API to form values
    const relinquishingIds = getMortgagorPartyBookIds(apiValues.titleReferences) as string[];
    let formModel: Mortgage2_26_0Model = {
      ...apiValues,
      partyBook: convertPartyBookFromApiToFormModel(apiValues.partyBook || [], relinquishingIds),
      addressBook: convertAddressBookFromApiToFormModel(apiValues.addressBook || []),
      mortgagors: filterMortgagors(apiValues),
      mortgageeDetails: convertReceivingTenancyDetailFromApiToForm(apiValues.mortgageeDetails)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: Mortgage2_26_0Model, originalApiModel: ApiMortgage2_26_0Model): ApiMortgage2_26_0Model {
    let formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      addressBook: addressBookFromForm,
      titleReferences,
      mortgagors,
      mortgageeDetails: mortgageeDetailsFromForm,
      mortgageDate,
      ...rest
    } = formModel;

    const addressBookToApi: BaseAddress[] = convertAddressBookFromFormToApiModel(addressBookFromForm?.filter(party => party.id) || [], originalApiModel.addressBook || []);
    const returnValue: ApiMortgage2_26_0Model = {
      ...rest,
      titleReferences: titleReferences.map(t => {
        return {
          ...t,
          mortgagors: {
            tenancyType: t.mortgagors.tenancyType,
            relinquishingProprietorGroups: t.mortgagors.relinquishingProprietorGroups.map(g => {
              return {
                previouslyHeldShareFraction: g.previouslyHeldShareFraction,
                proprietorGroupType: g.proprietorGroupType,
                shareFraction: g.shareFraction,
                shareTransferred: g.shareTransferred,
                parties: g.parties.map(m => {
                  return mortgagors.find(x => x.partyBookId === m.partyBookId) || m; // the could be happen if user modify the mortgagors manually
                })
              };
            })
          }
        };
      }),
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []) as any, // TODO resolve this typing
      addressBook: addressBookToApi.filter(x => x.id),
      mortgageeDetails: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: mortgageeDetailsFromForm }),
      mortgageDate: dateFormat(mortgageDate as Date, DateFormatEnum.DATE),
      attachments: formModel.additionalAttachments
    };
    return returnValue;
  }
}

export default new Converter();
