import * as React from 'react';

import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
/* eslint-disable no-restricted-imports */
import MuiTooltip, { TooltipProps as MuiTooltipProps, TooltipClassKey } from '@mui/material/Tooltip';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconClose } from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';
import styles, { ClassKeys } from './styles';

interface OwnProps extends MuiTooltipProps {
  onCloseClick?(event: React.SyntheticEvent<HTMLButtonElement>): void;
}
type Props = OwnProps & WithStyles<ClassKeys>;

// TODO: Migrate to use ui-framework tooltip
class Tooltip extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, children, onCloseClick, ...otherProps } = this.props;

    const classesObj: Partial<Record<TooltipClassKey, string>> = {
      popper: classes.popper,
      tooltip: classNames(classes.tooltip, classes.open),
      arrow: classes.arrow
    };

    return (
      <MuiTooltip classes={classesObj} {...otherProps} title={this.renderContent()}>
        {children}
      </MuiTooltip>
    );
  }

  private renderContent() {
    const { classes, title, onCloseClick } = this.props;

    if (!onCloseClick) {
      return title;
    }

    return (
      <React.Fragment>
        <IconButton className={classes.closeButton} onClick={this.handleOnClose} aria-label="Close" size="large">
          <IconClose className={classes.closeIcon} stroke={colors.BLACK} />
        </IconButton>
        {title}
      </React.Fragment>
    );
  }

  private handleOnClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onCloseClick } = this.props;
    onCloseClick && onCloseClick(event);
  };
}

export default withStyles(styles)(Tooltip);
