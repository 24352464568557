import React from 'react';

import { StyleProps, useStyles } from './styles';

type Props = StyleProps;

function Status({ variant }: Props) {
  const classes = useStyles({ variant });

  return <div className={classes.root}></div>;
}

export default React.memo(Status);
