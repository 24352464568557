import { axiosClient as axios, AxiosError, AxiosResponse, HEADER_APPLICATION_ENVIRONMENT, HEADER_CORRELATION_ID } from '@sympli/http-agent';
import Logger, { PageActionEnum, resolveUrls, SeverityEnum } from '@sympli/ui-logger';

import environments from 'src/environments';

// eslint-disable-next-line no-restricted-imports
// constants
const { TARGET_MACHINE, TARGET_API, APP_ENV } = environments;

// prefix all requests with base URL
axios.defaults.baseURL = `${TARGET_MACHINE}${TARGET_API}`; // http://localhost:3000/gateway/api/1

// WEB-21607 inject special header for localhosts
APP_ENV === 'localhost' && (axios.defaults.headers.common[HEADER_APPLICATION_ENVIRONMENT] = APP_ENV);

// intercept all requests and to log them
axios.interceptors.request.use(
  //
  config => {
    // WEB-18254 log minimal info about this request
    const httpMethod = config.method?.toUpperCase() as string;
    // if (httpMethod !== 'GET') {
    const correlationId = config.headers?.[HEADER_CORRELATION_ID] as string;
    const [requestUrl, groupedRequestUrl, requestPathUrl, groupedRequestPathUrl] = resolveUrls(`${config.baseURL}${config.url}`);
    Logger.capturePageAction(PageActionEnum.AjaxRequest, {
      correlationId,
      httpMethod,
      requestUrl,
      groupedRequestUrl,
      requestPathUrl,
      groupedRequestPathUrl,
      body: config.data
    });
    // }

    return config;
  }
);
// intercept all responses and to log them
axios.interceptors.response.use(
  // this covers only non-error responses
  ({ config, data, status }: AxiosResponse<any>) => {
    // WEB-18254 log minimal info about this response
    const httpMethod = config.method?.toUpperCase() as string;
    // if (httpMethod !== 'GET') {
    const correlationId = config.headers?.[HEADER_CORRELATION_ID] as string;
    const [requestUrl, groupedRequestUrl, requestPathUrl, groupedRequestPathUrl] = resolveUrls(`${config.baseURL}${config.url}`);
    Logger.capturePageAction(PageActionEnum.AjaxResponse, {
      correlationId,
      httpMethod,
      requestUrl,
      groupedRequestUrl,
      requestPathUrl,
      groupedRequestPathUrl,
      httpResponseCode: status,
      body: data
    });
    // }

    // explicitly return data only
    return data;
  },
  (err: AxiosError) => {
    const { config, response } = err;

    // When we don't mock a server response, axios could not send config in its error
    if (!config) {
      Logger.console(SeverityEnum.Warning, 'You might have missed mocking http call', JSON.stringify(err));
      return Promise.reject(err); // explicitly return rejected promise
    }
    // We print error response when it's in our localhost env for easy debugging
    if (config.headers?.[HEADER_APPLICATION_ENVIRONMENT] === 'localhost' && import.meta.env.DEV) {
      Logger.console(SeverityEnum.Warning, '', JSON.stringify(response));
    }

    // WEB-18254 log minimal info about this response
    const httpMethod = config.method?.toUpperCase() as string;
    // if (httpMethod !== 'GET') {
    const correlationId = config.headers?.[HEADER_CORRELATION_ID] as string;

    const [requestUrl, groupedRequestUrl, requestPathUrl, groupedRequestPathUrl] = resolveUrls(`${config.baseURL}${config.url}`);
    Logger.capturePageAction(PageActionEnum.AjaxResponse, {
      correlationId,
      httpMethod,
      requestUrl,
      groupedRequestUrl,
      requestPathUrl,
      groupedRequestPathUrl,
      httpResponseCode: response?.status,
      body: response?.data as any // TODO fix this
    });
    // }

    return Promise.reject(err); // explicitly return rejected promise
  }
);
