import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';

import { TransmissionApplicationWithoutDuty2_21_1Model } from './models';
import { VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE } from './visibilityChecks';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<TransmissionApplicationWithoutDuty2_21_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  relatedDocumentIds: NO_FALLBACK,
  applicantCapacity: [null],
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,

        deceasedProprietorGroups: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              deceasedProprietors: {
                $shouldAddDefaultItemToEmptyArray: true,
                $arrayItem: {
                  $children: {
                    isAffectedProprietor: NO_FALLBACK,
                    isLastDeceased: NO_FALLBACK,
                    isLastDeceasedBefore: NO_FALLBACK,
                    isEvidenceVisible: NO_FALLBACK,
                    deceasedProprietorId: [resolveUuid('string', 20)],
                    partyBookId: [''],
                    dateOfDeath: {
                      $children: {
                        dateOfDeathType: [0],
                        deathDate: [null],
                        fromDate: [null],
                        toDate: [null],
                        dateDescription: ['']
                      }
                    },
                    evidences: {
                      $shouldAddDefaultItemToEmptyArray: true,
                      $arrayItem: {
                        $children: {
                          evidenceTypeValue: [12],
                          evidenceDate: [null],
                          evidenceDocumentReference: [VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE, ''],
                          disableSelection: NO_FALLBACK,
                          isDeletable: NO_FALLBACK
                        }
                      }
                    }
                  }
                }
              },
              previouslyHeldShareFraction: NO_FALLBACK,
              tenancyType: NO_FALLBACK,
              lastDeceasedValidation: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  deceasedProprietorGroups: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        deceasedProprietors: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              isAffectedProprietor: NO_FALLBACK,
              isLastDeceased: NO_FALLBACK,
              isLastDeceasedBefore: NO_FALLBACK,
              isEvidenceVisible: NO_FALLBACK,
              deceasedProprietorId: [resolveUuid('string', 20)],
              partyBookId: [''],
              dateOfDeath: {
                $children: {
                  dateOfDeathType: [0],
                  deathDate: [null],
                  fromDate: [null],
                  toDate: [null],
                  dateDescription: ['']
                }
              },
              evidences: {
                $shouldAddDefaultItemToEmptyArray: true,
                $arrayItem: {
                  $children: {
                    evidenceTypeValue: [12],
                    evidenceDate: [null],
                    evidenceDocumentReference: [VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE, ''],
                    disableSelection: NO_FALLBACK,
                    isDeletable: NO_FALLBACK
                  }
                }
              }
            }
          }
        },
        previouslyHeldShareFraction: NO_FALLBACK,
        tenancyType: NO_FALLBACK,
        lastDeceasedValidation: NO_FALLBACK
      }
    }
  },
  partyBook: NO_FALLBACK,
  applicants: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        partyBookId: ['']
      }
    }
  },
  supportingDocuments: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        documentType: [''],
        attachment: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              id: NO_FALLBACK,
              fileName: [''],
              fileType: [''],
              uploadedByParticipantId: NO_FALLBACK,
              uploadedDateTimeUtc: NO_FALLBACK
            }
          }
        }
      }
    }
  }
};
