import { StampDutyApiResponse } from '../../models';
import { QldStampDutyFormDataModel } from './models';

export function getInitialValues(detail: StampDutyApiResponse): QldStampDutyFormDataModel {
  const { paymentOption = '', bankDetails } = detail;
  return {
    paymentOption,
    bankDetails: {
      accountName: '',
      bsb: '',
      accountNumber: '',
      ...bankDetails
    }
  };
}
