import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      marginTop: 16,
      marginBottom: 16,
      '& > .MuiPaper-root:first-child': {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
      },
      '& > .MuiPaper-root:last-child': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        '& > .MuiAccordionSummary-root': {
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4
        },
        '& .MuiAccordionDetails-root': {
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4
        }
      }
    }
  }),
  {
    name: 'LodgementVerificationResult'
  }
);
