// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    partyOrAddressTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      display: 'block'
    },
    partyOrAddressDisplay: {
      marginBottom: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: theme.spacing(2.5)
      }
    },
    errorMessage: {
      marginBottom: 5
    }
  }),
  {
    name: 'Mortgagee'
  }
);
