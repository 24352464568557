import React from 'react';

import classNames from 'classnames';
import { Form, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { JurisdictionsEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import Formik from '@sympli/ui-framework/components/formik';
import AutocompleteField from '@sympli/ui-framework/components/formik/autocomplete-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Button from '@sympli/ui-framework/components/sympli-button';
import { IconSearch } from '@sympli/ui-framework/icons';
import msg from '@sympli/ui-framework/utils/messages';

import { getSubscriberSuggestion } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/invite-participants/api';
import { SubscriberSearchItemModel } from 'src/containers/dashboard/components/create-new-workspace/views/reg-only-form/models';
import { GroupOptionModel } from '../../../shared/models';
import { getDescriptionLengthText } from './helpers';

export interface ForwardInviteToSubscriberFormValues {
  invitationId: string;
  workspaceId: string;
  isLinkedWorkspace?: boolean;
  newInvitationDescription?: string;
  forwardedSubscriberDisplayName: string;
  forwardedSubscriberElnoId: string;
  forwardedSubscriberId: string;
}

const fieldName = modelKey<ForwardInviteToSubscriberFormValues>();
const Message_MAX_LENGTH = 250;
const searchingStartAtCharacter: number = 3;
const DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE: string = 'You can not forward the invitation to the same subscriber';

interface ForwardInviteFormProps {
  invitationId: string;
  workspaceId: string;
  currentSubscriberId: string;
  invitationForwardableGroups: GroupOptionModel[];
  jurisdictionId: JurisdictionsEnum;
  invitedRole: WorkspaceRoleEnum;
  invitationDescription?: string;
  className?: string;
  isLinkedWorkspace?: boolean;
  onCancel(): void;
  onSubmit(data: ForwardInviteToSubscriberFormValues, formikHelpers: FormikHelpers<ForwardInviteToSubscriberFormValues>): Promise<void>;
}

export function getForwardInviteValidationSchema(currentSubscriberId: string) {
  return yup.object<ForwardInviteToSubscriberFormValues>({
    invitationId: yup.mixed<string>(),
    workspaceId: yup.mixed<string>(),
    isLinkedWorkspace: yup.mixed<boolean>(),
    newInvitationDescription: yup
      .string()
      .max(Message_MAX_LENGTH, ({ max, value }) => ` ${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${getDescriptionLengthText(value.length, max)}`),

    forwardedSubscriberDisplayName: yup.mixed<string>(),
    forwardedSubscriberElnoId: yup.mixed<string>(),
    forwardedSubscriberId: yup
      .string()
      .test(
        '_subscriberId_check',
        DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE, //
        function test(this: yup.TestContext, subscriberId: string): boolean {
          return currentSubscriberId !== subscriberId;
        }
      )
      .required(msg.REQUIRED)
  });
}

function ForwardInviteToSubscriberForm({
  //
  invitationDescription,
  jurisdictionId,
  invitationId,
  workspaceId,
  invitedRole,
  isLinkedWorkspace,
  currentSubscriberId,
  onCancel,
  onSubmit
}: ForwardInviteFormProps) {
  const initialValues: ForwardInviteToSubscriberFormValues = React.useMemo<ForwardInviteToSubscriberFormValues>(
    () => ({
      invitationId,
      workspaceId,
      isLinkedWorkspace: isLinkedWorkspace,
      newInvitationDescription: invitationDescription,
      forwardedSubscriberDisplayName: '',
      forwardedSubscriberElnoId: '',
      forwardedSubscriberId: ''
    }),
    [invitationId, workspaceId, invitationDescription, isLinkedWorkspace]
  );

  const fieldClasses = {
    inputLabel: 'text-[16px] font-[700] leading-[24px] tracking-[0.15px] text-[var(--neutral-1000)]',
    formControl: 'mt-[28px]',
    marginBottom: 'mb-[24px]'
  };

  return (
    <Formik //
      initialValues={initialValues}
      validationSchema={getForwardInviteValidationSchema(currentSubscriberId)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, errors, dirty, setFieldValue, values }: FormikProps<ForwardInviteToSubscriberFormValues>) => {
        const handleOnSuggestionSelected = (e: React.ChangeEvent<HTMLInputElement>, selectedSuggestion: SubscriberSearchItemModel) => {
          const subscriber: SubscriberSearchItemModel = {
            id: selectedSuggestion.id,
            name: selectedSuggestion.name,
            elnoId: selectedSuggestion.elnoId,
            elnoSubscriberId: selectedSuggestion.elnoSubscriberId, // Sympli subscribers will not have this populated
            abn: selectedSuggestion.abn
          };

          setFieldValue(fieldName('forwardedSubscriberId'), subscriber.id, true);
          setFieldValue(fieldName('forwardedSubscriberElnoId'), subscriber.elnoId, true);
          setFieldValue(fieldName('forwardedSubscriberDisplayName'), subscriber.name, true);
        };

        const message = values.newInvitationDescription ?? '';
        const descriptionLengthText = getDescriptionLengthText(message.length, Message_MAX_LENGTH);

        return (
          <Form className="flex grow flex-col justify-between">
            <Typography className={fieldClasses.inputLabel}>Subscribers</Typography>
            <Typography className={'mb-[5px] text-[14px] font-[400] text-[var(--neutral-600)]'}>Search to invite subscriber</Typography>
            <Field //isInteroperable is not not supported yet as row record is not returning the data
              fullWidth
              name={fieldName('forwardedSubscriberId')}
              resolvedValueName={fieldName('forwardedSubscriberDisplayName')}
              component={AutocompleteField}
              getSuggestions={(name: string) => getSubscriberSuggestion(name, invitedRole, jurisdictionId, false, undefined)}
              onSuggestionSelected={handleOnSuggestionSelected}
              placeholder="Start typing"
              endAdornment={<IconSearch style={{ marginRight: 4 }} />}
              searchingStartsAt={searchingStartAtCharacter}
              noDataMessage={<Typography>No results found. Please check your spelling and try again.</Typography>}
              disabled={isSubmitting}
              suggestionsListClassName="max-h-[200px] overflow-y-auto"
            />

            <div className="relative mt-[10px] w-full">
              <Field //
                component={InputField}
                classes={fieldClasses}
                format="string"
                label="Message"
                placeholder="Enter your message"
                name={fieldName('newInvitationDescription')}
                multiline
                minRows={6}
                maxRows={10}
                inputProps={{
                  style: {
                    height: 72
                  }
                }}
                fullWidth
                disabled={isSubmitting}
              />
              {!errors.newInvitationDescription && (
                <div //
                  className={classNames('absolute bottom-[8px] left-0 text-[12px] font-[400] leading-[12px]', 'text-[var(--neutral-600)]')}
                >
                  {descriptionLengthText}
                </div>
              )}
            </div>

            <div className="flex justify-center gap-[8px]">
              <Button
                //
                disabled={isSubmitting}
                className="rounded-[34px] border-[2px] px-[24px] py-[6px]"
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                //
                isLoading={isSubmitting}
                disabled={isSubmitting || !(isValid && dirty)}
                type="submit"
                variant="contained"
                color="primary"
                className="rounded-[34px] px-[24px] py-[6px]"
              >
                Forward
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(ForwardInviteToSubscriberForm);
