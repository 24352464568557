import * as React from 'react';

function WithPlusN({
  primary,
  primaryClassName,
  primaryDataBinding,
  secondary,
  secondaryClassName,
  secondaryDataBinding,
  plusN,
  tooltipHandlers
}: {
  //
  primary: string;
  primaryClassName: string;
  primaryDataBinding: string;
  secondary?: string;
  secondaryClassName?: string;
  secondaryDataBinding?: string;
  plusN?: number;
  tooltipHandlers: {
    onMouseEnter(event: React.MouseEvent<HTMLElement>): void;
    onMouseLeave(event: React.MouseEvent<HTMLElement>): void;
  };
}) {
  return (
    <>
      {secondary ? (
        plusN ? (
          <>
            <div className={primaryClassName} data-binding={primaryDataBinding} {...tooltipHandlers}>
              {primary}
            </div>
            <div className="flex">
              <div className={secondaryClassName} data-binding={secondaryDataBinding} {...tooltipHandlers}>
                {secondary}
              </div>
              &nbsp;
              <div className="bold pl-[4px]" data-explicit-tooltip data-binding="plusN" {...tooltipHandlers}>
                +{plusN}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={primaryClassName} data-binding={primaryDataBinding} {...tooltipHandlers}>
              {primary}
            </div>
            <div className={secondaryClassName} data-binding={secondaryDataBinding} {...tooltipHandlers}>
              {secondary}
            </div>
          </>
        )
      ) : plusN ? (
        <div className="flex">
          <div className={primaryClassName} data-binding={primaryDataBinding} {...tooltipHandlers}>
            {primary}
          </div>
          &nbsp;
          <div className="bold" data-explicit-tooltip data-binding="plusN" {...tooltipHandlers}>
            +{plusN}
          </div>
        </div>
      ) : (
        <div className={primaryClassName} data-binding={primaryDataBinding} {...tooltipHandlers}>
          {primary}
        </div>
      )}
    </>
  );
}

export default WithPlusN;
