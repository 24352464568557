import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      ...dataBindingHelper()
    },
    radioSelected: {
      marginBottom: 0
    },
    referenceBox: {
      paddingBottom: 0,
      marginBottom: 10
    }
  }),
  {
    name: 'BenefitedLandReference'
  }
);
