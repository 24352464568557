import { $contains, $get, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { AdditionalCovenantListingModel, Mortgage2_27_0Model } from './models';

// this file was automatically generated from checks.ts.mustache
export const VISIBILITY_CHECK_MORTGAGORS = createContextCheck(({ titleReferences }: { titleReferences: Mortgage2_27_0Model['titleReferences'] }): boolean => {
  return titleReferences.some(({ isSelected }) => isSelected);
});

/**
 path:
 - additionalCovenants.warning

 schema version:
[
  {
    "value": {
      "$contains": "Trust"
    }
  },
  {
    "value": {
      "$contains": "trust"
    }
  },
  {
    "value": {
      "$contains": "trustee"
    }
  },
  {
    "value": {
      "$contains": "Trustee"
    }
  },
  {
    "value": {
      "$contains": "Beneficial"
    }
  },
  {
    "value": {
      "$contains": "benefitial"
    }
  },
  {
    "value": {
      "$contains": "benefisial"
    }
  },
  {
    "value": {
      "$contains": "Beneficiary"
    }
  },
  {
    "value": {
      "$contains": "benefitiary"
    }
  },
  {
    "value": {
      "$contains": "beneficiary"
    }
  }
]
*/
export const VISIBILITY_CHECK_ADDITIONAL_COVENANTS$WARNING = createContextCheck((context: AdditionalCovenantListingModel): boolean => {
  const value = $get(context, 'value');
  return (
    $contains(value, 'Trust') ||
    $contains(value, 'trust') ||
    $contains(value, 'trustee') ||
    $contains(value, 'Trustee') ||
    $contains(value, 'Beneficial') ||
    $contains(value, 'benefitial') ||
    $contains(value, 'benefisial') ||
    $contains(value, 'Beneficiary') ||
    $contains(value, 'benefitiary') ||
    $contains(value, 'beneficiary')
  );
});
