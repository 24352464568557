import * as yup from 'yup';

import { QldNameChange } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';
import { RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';

import { QLD_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { Transfer2_24_1Model } from '../../models';

interface PartyBookValidationContext {
  transferors?: RelinquishingTenancyDetailModel;
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = ({ transferors }: Transfer2_24_1Model): PartyBookValidationContext => ({
  transferors: transferors
});

const checkShouldValidateItem = ({ transferors }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) =>
  transferors?.proprietorGroups.some(({ parties }) => parties.some(e => e.partyBookId === partyIdForCheck)) ?? false;

const yupPartyItem: yup.ObjectSchema<PartyModel<QldNameChange>, PartyBookValidationContext> = resolvePartyModelValidationSchema<QldNameChange, PartyBookValidationContext>(
  QLD_TRANSFER_PARTY_FORM_CONFIG
);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<QldNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
