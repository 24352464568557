// as it is backend name is just combination of first name + last name
export function getNameInit(name: string) {
  // be aware of system support empty space in names, so it can be N N
  const splitNames = name
    .split(' ')
    .map(function (item) {
      return item.trim();
    })
    .filter(d => d !== '');

  const firstLetter = splitNames[0][0];
  const lastLetter = splitNames.length <= 1 ? '' : splitNames[splitNames.length - 1][0];
  return `${firstLetter}${lastLetter}`.toUpperCase();
}

export function getNameInitWithFirsLastName(firstName: string, lastName: string) {
  return `${firstName.length > 0 ? firstName[0] : ''}${lastName.length > 0 ? lastName[0] : ''}`.toUpperCase();
}
