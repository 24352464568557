import { MergeParty } from './model';
import PartyJustification from './PartyJustification';

/**
 * https://tickleme.atlassian.net/wiki/spaces/~5de440aa7095a40d125388c9/pages/2415887591/Party+Merge+NSW
 */

export default class PartyValidations {
  /**
   * Will check whether justification done or not
   * @param parties Parties to check the justification
   * @returns Return false when not justified
   */
  public static verifyJustification<T extends MergeParty>(parties: T[], getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined): boolean {
    const partiesNeedJustification: T[] = parties.filter(e => e.mergeMetadata?.requiresJustification);
    if (partiesNeedJustification.length) {
      const partiesNeedJustificationCopied = partiesNeedJustification.map(p => ({ ...p, mergeMetadata: { ...p.mergeMetadata } }));
      const message = PartyJustification.adjustJustification(partiesNeedJustificationCopied, getLegalEntityName);
      return message === undefined;
    }

    return true;
  }

  /**
   * Will check the tenancy structure is same or not
   * @param parties Parties to check whether can merge or not
   * @returns Return the reason of unsuccessful otherwise undefined
   */
  public static verifyTenancyStructure<T extends MergeParty>(parties: T[]): string | undefined {
    const mergedParties = parties.filter(p => p.mergeMetadata);
    const containers: string[] = [...new Set(mergedParties.map(e => e.mergeMetadata!.identity.key))];

    if (containers.length === 1) return undefined;

    if (mergedParties.some(p => p.mergeMetadata!.matchingGroupHash === p.mergeMetadata!.identity.groupHash)) return 'Structure is not right';

    return undefined;
  }
}
