import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    title: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '22px'
    }
  }),
  {
    name: 'SettingsSectionHeader'
  }
);
