import * as React from 'react';

import classNames from 'classnames';

import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';

import DidNotLoad from 'src/containers/dashboard/components/svg/DidNotLoad';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { ApiStatus } from 'src/utils/http';
import { LinkedWorkspaceParticipant } from '../../../models';
import { UserProfileDetail } from '../models';
import { resolveColumns } from './helpers';
import { LinkedWorkspaceTableContext, LinkedWorkspaceTableModel } from './models';

const LinkedWorkspacesTable = ({
  rows,
  apiStatus,
  rowDetailNode,
  rowDetailIndex,
  onRowToggle,
  userProfileDetail,
  onViewClick
}: {
  rows: LinkedWorkspaceTableModel[];
  apiStatus?: ApiStatus;
  // detail
  rowDetailNode: React.ReactNode;
  rowDetailIndex?: number;
  onRowToggle(rowIndex: number, rowData: LinkedWorkspaceTableModel): void;
  userProfileDetail: UserProfileDetail;
  onViewClick: (participantsHaveAccess: LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => void;
}) => {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const tableContext = React.useMemo<LinkedWorkspaceTableContext>(() => ({ screenVariant }), [screenVariant]);

  const columns: ColumnsModel<LinkedWorkspaceTableModel> = React.useMemo(
    () => resolveColumns({ screenVariant, userProfileDetail, onViewClick }),
    [screenVariant, userProfileDetail, onViewClick]
  );
  const isLoading = apiStatus === 'idle' || apiStatus === 'pending';

  return (
    <Table //
      className={classNames('mb-[144px]')}
      context={tableContext}
      noDataMessage={<DidNotLoad />}
      recordType="linked Workspaces"
      columns={columns}
      rows={rows}
      noFooter
      isLoading={isLoading}
      {...{
        onRowToggle,
        rowHoverActionNode: 'Workspace Details',
        rowHideActionNode: 'Hide Workspace Details',
        rowHoverActionColumnStartIndex: -2,
        rowHoverActionColumnEndIndex: 0,
        rowDetailNode,
        rowDetailIndex
      }}
    />
  );
};

export default React.memo(LinkedWorkspacesTable);
