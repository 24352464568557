import _isEqual from 'lodash-es/isEqual';

import { ConsiderationTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import {
  FormReceivingProprietorGroupModel,
  FormReceivingTenancyDetailModel,
  ReceivingTenancyDetails$ShareSplitTypeEnum
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';

import { RelinquishingTenancyDetailModel, RelinquishingTenancyDetailModelWithMeta, TenancyPartyWithAddressModel, TitleReferenceModel } from './models';

export function combineTransferorsFromSelectedTitleReferences(titleReferences: TitleReferenceModel[]): RelinquishingTenancyDetailModelWithMeta {
  const selectedTitleReferences = titleReferences.filter(({ isSelected }) => isSelected);
  if (selectedTitleReferences.length === 0) {
    return {
      isGroupStructureMeaningful: false,
      relinquishingProprietorGroups: []
    };
  }
  if (selectedTitleReferences.length === 1) {
    return {
      isGroupStructureMeaningful: true,
      ...selectedTitleReferences[0].transferorDetails
    };
  }
  const uniquePartyIdsFoundInSelectedTitles: string[] = [...new Set(getPartyIdsAssociatedWithTitleReferences(selectedTitleReferences)).values()];
  return {
    relinquishingProprietorGroups: [
      {
        parties: uniquePartyIdsFoundInSelectedTitles.map(partyBookId => ({ partyBookId }))
      }
    ], // Assume only one group, as the logic to break up groups when multiple titles are selected is not confirmed
    // As we piled all discovered parties into one group, we can only really sure that this structure is meaningul if all titles have a single group
    isGroupStructureMeaningful: selectedTitleReferences.every(({ transferorDetails }) => transferorDetails.relinquishingProprietorGroups.length === 1)
  };
}

export function getPartyIdsAssociatedWithTitleReferences(titleReferences: TitleReferenceModel[]): string[] {
  return titleReferences
    .flatMap(({ transferorDetails }) => transferorDetails.relinquishingProprietorGroups.flatMap(({ parties }) => parties.map(party => party.partyBookId)))
    .filter(Boolean) as string[];
}

export const shouldUpdateTransfereesAsTransferors = (
  considerationType: ConsiderationTypeEnum,
  transferorDetails: RelinquishingTenancyDetailModel,
  transfereeDetails: FormReceivingTenancyDetailModel
): boolean => {
  if (considerationType !== ConsiderationTypeEnum.ChangeInTheMannerOfHolding) {
    // Transferees only need to match transferors if changing manner of holding
    return false;
  }
  // As consideration is change in manner of holding, transferees must match transferors. However, there is a chance that transferees already meet this requirement, so we do not want to reset this section unnecessarily.
  const sortedTransferorPartyIds = getSortedPartyIds(transferorDetails.relinquishingProprietorGroups);
  const sortedTransfereePartyIds = getSortedPartyIds(transfereeDetails.receivingProprietorGroups);
  return !_isEqual(sortedTransferorPartyIds, sortedTransfereePartyIds);
};

function getSortedPartyIds(groups: { parties: { partyBookId?: string }[] }[]): string[] {
  return groups
    .flatMap(group => group.parties.map(party => party.partyBookId))
    .filter((id): id is string => Boolean(id))
    .sort();
}

export const getTransfereeDetailsFromTransferors = (transferorDetails: RelinquishingTenancyDetailModel): FormReceivingTenancyDetailModel => ({
  shareSplitType: ReceivingTenancyDetails$ShareSplitTypeEnum.TenantsInCommonEqualShare,
  receivingProprietorGroups: transferorDetails.relinquishingProprietorGroups.map(
    (relinquishingProprietorGroup): FormReceivingProprietorGroupModel => ({
      id: resolveUuid('string', 20),
      parties: relinquishingProprietorGroup.parties.map(
        (transferor): TenancyPartyWithAddressModel => ({
          id: resolveUuid('string', 20),
          partyBookId: transferor.partyBookId,
          partyCapacity: null,
          addressBookId: '',
          birthDate: null
        })
      ),
      shareQuantity: null
    })
  )
});
