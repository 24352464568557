import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    menuItemIcon: {
      paddingRight: 6,
      color: theme.palette.text.primary,
      fill: theme.palette.primary.main
    },
    menuItemLabel: {
      fontSize: 13,
      textDecoration: 'underline',
      color: theme.palette.text.primary
    },
    menuItemIconUnsign: {
      paddingRight: 6,
      color: theme.palette.text.primary,
      fill: theme.palette.secondary.main
    }
  }),
  { name: 'UnsignTaa' }
);
