import { call, put, takeLatest } from 'typed-redux-saga';

import { UserProfileModel } from '@sympli/api-gateway/shared';

import http from 'src/utils/http';
import { actionFetchProfile } from './actions';

const fetchProfile = async () => http.get<UserProfileModel>('/profile');

export function* sagaFetchProfile() {
  try {
    const data = yield* call(fetchProfile);
    yield put(actionFetchProfile.success({ data }));
  } catch (error) {
    yield put(actionFetchProfile.failure({ error, redirectOnClose: '/logout' }));
  }
}

export default [
  //
  takeLatest(actionFetchProfile.request, sagaFetchProfile)
];
