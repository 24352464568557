import { NotificationContentProps } from '../../models';
import { DocumentParticipationDataModel } from './models';

export function DocumentParticipationWithdrawnNotificationContent({ log, participantId, withdrawnParticipants }: NotificationContentProps) {
  if (log.participantId === participantId || Boolean(withdrawnParticipants.find(wp => wp.id === log.participantId))) {
    return null;
  }

  const { WorkspaceRole, DocumentName } = log.dataObject as DocumentParticipationDataModel;

  return (
    <span>
      '{WorkspaceRole}' has withdrawn from {DocumentName} document. This action may have caused changes to your workspace, please check your tasks.
    </span>
  );
}
