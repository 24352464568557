import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import Logger from '@sympli/ui-logger';

import { LoginCallback } from 'src/auth/okta';
import UserManagerProvider from 'src/auth/UserManagerProvider';
import environments from 'src/environments';
import LoadingProfile from '../shared/loading-profile';
import { ErrorResponse, OKTA_INVALID_ERROR_CODES } from './models';

const LoggedIn = () => {
  const navigate = useNavigate();

  const ErrorCallback = function ({ error }: { error: Error | ErrorResponse }): JSX.Element | null {
    // avoid infinite loop for below case:
    // 1. iat is in the future
    // 2. exp is in the past
    // 3. localstorage/sessionstorage out of limit
    const message = error?.message;
    if (message?.match(/iat is in the future/i) || message?.match(/exp is in the past/i) || message?.match(/Failed to execute 'setItem' on 'Storage'/i)) {
      const scope = Logger.scopeWithCustomAttributes({ clientUTCTimestamp: +new Date(), ...error });
      Logger.captureException(error, scope);
      navigate('/unauthorized');
      return null;
    }

    // the above is still needed, okta is return 200 ok but with different error codes, but we don't see those scenarios handled by OKTA error codes
    if ((error as ErrorResponse)?.error && OKTA_INVALID_ERROR_CODES.includes((error as ErrorResponse)?.error)) {
      const scope = Logger.scopeWithCustomAttributes({ clientUTCTimestamp: +new Date(), ...error });
      Logger.captureException(error, scope);

      //clean up staled token in the storage before navigating
      UserManagerProvider.authClient.clearStorage();
      navigate('/unauthorized');
      return null;
    }

    // to avoid infinite loop, we need to clean up the staled token in the storage  before hard reload
    UserManagerProvider.authClient.clearStorage();
    window.location.href = environments.BASENAME;
    return null;
  };

  return <LoginCallback errorComponent={ErrorCallback} loadingElement={<LoadingProfile />}></LoginCallback>;
};

export default React.memo(LoggedIn);
