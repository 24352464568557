import * as React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { getSelectedTitleReferences } from '../../helpers';
import { InterestOnTitle, SubLease2_21_1Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
}

function InterestsOnTitle({ name }: Props): JSX.Element {
  const { values, setValues } = useFormikContext<SubLease2_21_1Model>();
  const { disabled } = useDocumentContext();
  const { partyBook, interestsOnTitle, titleReferences } = values;
  const hasMultipleSelection = interestsOnTitle.length > 1;
  const anySelectedTitleHasNoInterestOnTitle = getSelectedTitleReferences(titleReferences).some(t => !t.interestsOnTitle.length);
  const noDataMessage = anySelectedTitleHasNoInterestOnTitle
    ? 'There is currently no Lease interest available on the selected title references'
    : 'The titles selected do not share a common Lease interest. Please review the selected titles';
  const classes = useStyles();
  const resolveItemLabel = React.useCallback(
    (item: InterestOnTitle) => {
      const lessors = item.lessors.flatMap(c => c.partyBookId);

      return (
        <>
          <span className={classNames(classes.dealingNumber)}>{item.dealingNumber}</span>
          <span className={classNames(hasMultipleSelection ? classes.multipleDealingNumber : classes.singleDealingNumber)}>
            {partyBook
              ?.filter(x => lessors.includes(x.id))
              .map(x => x.legalEntityName)
              .join(', ')}
          </span>
        </>
      );
    },
    [classes.dealingNumber, classes.multipleDealingNumber, classes.singleDealingNumber, hasMultipleSelection, partyBook]
  );

  const handleInterestOnTitleSelect = React.useCallback(() => {
    setValues(values => {
      return {
        ...values,
        lessors: values.interestsOnTitle.find(i => i.isSelected)?.lessors ?? []
      };
    });
  }, [setValues]);
  return (
    <div data-testid={name}>
      <Section title="Interest on title" data-name={name}>
        <div className={classes.root}>
          <GenericItemSelection //
            name={name}
            mode="single"
            resolveItemLabel={resolveItemLabel}
            disabled={disabled}
            variant="naked"
            noDataMessage={noDataMessage}
            onChange={handleInterestOnTitleSelect}
            className={classes.itemSelection}
          />
        </div>
      </Section>
    </div>
  );
}

export default React.memo(InterestsOnTitle);
