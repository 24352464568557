import { DuplicateCtIssuedTypeEnum } from '@sympli/api-gateway/enums';

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.WorkspaceTitleReferenceDetails
 */
// export interface TitleReference {
//   landRegistryDetail: ResupplyLandRegistryInformationApiResponse;
//   titleActivityCheckResult?: CheckTitleActivityApiResponse;
//   // Not part of api
//   previousLandRegistryDetail?: ResupplyLandRegistryInformationApiResponse;
// }

// ! note the data is mismatch for backend as backend has more data
// for example, RequestedDate, issueDate etc.
/**
 * MR - svc moved (ResupplyLandRegistryInformationApiResponse)
 * Tickle.LRS.Core.Contract.Common.TitleReference
 */
// export interface TitleRegistryDetail {
//   id: string;
//   isPartLandAffected: boolean;
//   reference: string;
//   estateType?: string;
//   coRDHolder?: CoRDHolderType | null;
//   proprietors?: Array<ProprietorEntityModel>;
//   interestInLands?: Array<RegisteredDocument>;
//   constraintAffectingEstates?: Array<RegisteredDocument>;
//   mortgages?: Array<RegisteredDocumentPartyEntityModel>;
//   leases?: Array<RegisteredDocumentPartyEntityModel>;
//   subLeases?: Array<RegisteredDocumentPartyEntityModel>;
//   charges?: Array<RegisteredDocumentPartyEntityModel>;
//   caveats?: Array<RegisteredDocumentPartyEntityModel>;
//   priorityNotices?: Array<RegisteredDocumentPartyEntityModel>;
//   // NSW LRS doesn't provide this yet but we'll need this later
//   landDescriptions?: Array<LandDescription>;
//   propertyAddress: DeliveryAddress;
//   bundleSubscriptionId?: string;

//   // add the below missing type
//   titleType?: string;
//   subJurisdiction?: string;
//   titleShareFraction?: ShareFraction;
//   interestsInLand?: InterestInLand[];
//   nominations?: Nominations[];
//   issuedDate: string;
//   requestedDate: string;
//   ctController?: Party;
//   duplicateCtIssuedType: DuplicateCtIssuedTypeEnum;
//   duplicateCtType: DuplicateCtTypeEnum;
//   writs?: Array<RegisteredDocumentPartyEntityModel>;
//   unregisteredDocuments?: UnregisteredDocument[];
//   titleProductionRecord?: TitleProductionRecord;
//   authorityMessageId?: string;
// }

// MR - moved
// export enum TenancyTypeEnum {
//   Encumbrancee = 0,
//   JointTenants = 1,
//   Mortgagee = 2,
//   Multi = 3,
//   SoleProprietor = 4,
//   Survivorship = 5,
//   TenantsInCommon = 6,
//   None = 7,
//   LifeEstate = 8
// }

// MR - moved
// export enum ProprietorGroupTypeEnum {
//   JointTenantsInterse = 0,
//   JointTenantsWithNoSurvivorship = 1,
//   LifeEstate = 2
// }

// MR - svc moved
// export interface ProprietorEntityModel {
//   tenancyType: TenancyTypeEnum;
//   proprietorGroups: Array<ProprietorGroupEntityModel>;
// }

// MR - svc moved
// export interface LifeEstateTerminationConditionModel {
//   type: number;
//   terminationDate?: string;
//   otherDescription?: string;
// }

// MR - svc moved
// export interface ProprietorGroupEntityModel {
//   proprietorGroupType: ProprietorGroupTypeEnum;
//   shareFraction?: { numerator?: string; denominator?: string };
//   minimumTrusteesTreshold?: string;
//   parties: Array<Party>;
//   lifeTenancy?: ProprietorEntityModel;
//   remainderTenancy?: ProprietorEntityModel;
//   lifeOfParties?: Party[];
//   lifeEstateTerminationConditions?: LifeEstateTerminationConditionModel[];
// }

// MR - svc moved (see above)
// export interface ProprietorGroupEntityModel {
//   proprietorGroupType: ProprietorGroupTypeEnum;
//   shareFraction?: { numerator?: string; denominator?: string };
//   minimumTrusteesTreshold?: string;
//   parties: Array<Party>;
//   lifeTenancy?: Exclude<ResupplyLandRegistryInformationApiResponse['proprietors'], undefined>[number];
//   remainderTenancy?: Exclude<ResupplyLandRegistryInformationApiResponse['proprietors'], undefined>[number];
//   lifeOfParties?: Party[];
//   lifeEstateTerminationConditions?: LifeEstateTerminationConditionModel[];
// }

// MR - svc moved
// export interface AdministrationChangeEntityModel {
//   type: string;
//   actionedDateTime: string;
// }

/**
 * MR - svc moved
 * Tickle.DocumentService.Client.V2.Model.TAC.TitleActivityCheckResult+DocumentChange
 */
// export interface DocumentChangeEntityModel {
//   documentType: string;
//   documentName: string;
//   dealingNumber: string;
//   statuses: Array<{ status: string; actionedDateTime: string }>;
// }

/**
 * MR - svc moved (CheckTitleActivityApiResponse)
 * Tickle.DocumentService.Client.V2.Model.TAC.TitleActivityCheckResult
 */
// export interface TitleActivityApiResponse {
//   id: string;
//   administrationChanges: Array<AdministrationChangeEntityModel>;
//   documentChanges: Array<DocumentChangeEntityModel>;
//   hasChanges: boolean;
//   numberOfChanges: number;
//   lastCheckedDate: string;
//   canCheckActivity: boolean;
// }

// MR - svc moved
// export interface CoRDHolderType {
//   status?: string;
//   party?: Party;
//   editionNumber?: string;
//   editionDate: Date | string;
//   isOutOfAction: boolean;
// }

// MR - svc moved
// export interface RegistryDocumentModel {
//   dealingNumber: string;
//   documentType: string;
//   expiry?: string | null;
//   registeredDate?: string | null;
// }

// MR - svc moved
// export interface RegisteredDocument {
//   registryDocument: RegistryDocumentModel;
//   parties: Party[];
//   type: string;
// }

// MR - svc moved
// export interface RegisteredDocumentPartyEntityModel {
//   registryDocument: RegistryDocumentModel;
//   parties: Party[];
//   isOutOfAction: boolean;
//   tenancyDetails?: ProprietorEntityModel[];
// }

// MR - svc moved
// export interface LandDescription {
//   isSelected: boolean;
//   legalDescription: string;
//   landDescriptionComponents?: any[]; // TODO Add proper type
// }

// MR - not used
// export interface TitleAddressModel {
//   addressLine1: string;
//   addressLine2: string;
// }

// MR - svc moved (ResupplyLandRegistryInformationApiResponse['propertyAddress'])
// export interface DeliveryAddress {
//   id?: string;
//   addressType?: DeliveryAddressTypeEnum;
//   dxLocationName?: string;
//   dxNumber?: string;
//   addressLine1?: string;
//   addressLine2?: string;
//   addressLine3?: string;
//   addressLine4?: string;
//   isUnstructuredAddress?: boolean;
//   addressSiteName?: string;
//   secondaryComplex?: string | null;
//   unitType?: number;
//   unitNumber?: string;
//   levelType?: number;
//   levelNumber?: string;
//   lotNumber?: string;
//   streetNumber?: string;
//   streetName?: string;
//   streetType?: number;
//   streetSuffix?: number;
//   locality?: string;
//   state?: string;
//   postcode?: string;
//   countryCode?: number;
//   postalDeliveryTypeCode?: number;
//   postalDeliveryNumber?: string;
// }

// MR - moved
// export interface Party {
//   id: string;
//   isSelected?: boolean;
//   lrCustomerCode?: string;
//   partyType: CommonPartyTypeEnum;
//   legalEntityName: string;
//   name?: string;
//   nameSuffix?: string;
//   firstName?: string;
//   middleName?: string;
//   lastName?: string;
//   organisationName?: string;
//   businessUnit?: string;
//   organisationType?: OrganisationTypeEnum;
//   administrationStatus?: string;
//   abn?: string;
//   acln?: string;
//   acn?: string;
//   arbn?: string;
//   gpr?: string;
//   deliveryAddress?: ResupplyLandRegistryInformationApiResponse['propertyAddress'];
//   justifications?: any[]; // TODO Add proper type
//   propertiesMappedFromRis?: string[];
//   risInvolvedPartyId?: string;
//   partyCapacity?: NecdsPartyCapacityEnum;
// }

// MR - moved
// export enum DeliveryAddressTypeEnum {
//   DxAddress = 1,
//   StreetAddress = 4
// }

// MR - moved
// export enum PartyTypeEnum {
//   Indeterminate, // Individual
//   Individual, // Individual
//   Organisation, // Organisation
//   Partnership, // Organisation
//   SoleTrader // Individual
// }

// MR - moved
// export enum OrganisationTypeEnum {
//   Charitablebody = 0,
//   Commonwealthgovernmentorbody = 1,
//   Cooperative = 2,
//   Foreigncompanyunregistered = 3,
//   IncorporatedAssociation = 4,
//   Localgovernmentorbody = 5,
//   Nongovernmentstatutoryentity = 6,
//   RegisteredAustralianbodyorforeigncompany = 7,
//   Registeredcompany = 8,
//   Registeredcompanyinexternaladministration = 9,
//   Religiousbody = 10,
//   StateTerritorygovernmentorbody = 11,
//   Subdivisionalbody = 12,
//   TradeUnion = 13
// }

// MR - svc moved
// export interface ShareFraction {
//   numerator: string;
//   denominator: string;
// }

// MR - svc moved
// export interface InterestInLand {
//   typeOfInterestInLand: string;
//   registryDocument: RegistryDocumentModel;
//   parties: Party[];
//   isOutOfAction: boolean;
//   tenancyDetails: ProprietorEntityModel[];
//   affectedInterest: AffectedInterest[];
// }

// MR - svc moved
// export interface AffectedInterest {
//   documentType?: string;
//   dealingNumber?: string;
//   affectedDocumentId?: string;
// }

// MR - svc moved
// export interface Nominations {
//   registryDocument: RegistryDocumentModel;
//   parties: Party[];
//   isOutOfAction: boolean;
//   issuingPartyDirection: IssuingPartyDirectionTypeEnum;
// }

/**
 * MR - moved
 * Tickle.LRS.Core.Contract.Common.IssuingPartyDirectionType
 */
// export enum IssuingPartyDirectionTypeEnum {
//   DoNotReturnToNominatingParty,
//   ReturnToNominatingParty
// }

// MR - moved
// export enum DuplicateCtIssuedTypeEnum {
//   None = -1,
//   Issued = 0,
//   NotIssued = 1,
//   YettobeIssued
// }

export const DuplicateCtIssuedTypeMapping = new Map<DuplicateCtIssuedTypeEnum, string>([
  [DuplicateCtIssuedTypeEnum.None, 'None'],
  [DuplicateCtIssuedTypeEnum.Issued, 'Issued'],
  [DuplicateCtIssuedTypeEnum.NotIssued, 'Not Issued'],
  [DuplicateCtIssuedTypeEnum.YetToBeIssued, 'Yet to be Issued']
]);

// MR - moved
// export enum DuplicateCtTypeEnum {
//   None = -1,
//   Electronic = 0,
//   Indeterminate = 1,
//   Paper = 2
// }

// MR - moved
// export enum UnregisteredDocumentTypeEnum {
//   PreLodgementPlan,
//   UnregisteredPrimaryApplication,
//   UnregisteredRegistryInstrument,
//   UnregisteredRegistryPlan
// }

// MR - moved
// export interface RegistryPlan {
//   registryDocument: RegistryDocumentModel;
//   registryPlanType: string;
//   registryPlanPurpose: string;
// }

// MR - svc moved
// export interface UnregisteredDocument {
//   lodgementOrder: string;
//   type: UnregisteredDocumentTypeEnum;
//   unregisteredRegistryInstrument?: RegistryDocumentModel;
//   unregisteredRegistryPlan?: RegistryPlan;
// }

// export interface TitleProductionRecord {
//   lrProductionReceiptId: string;
//   productionType: string;
//   producingParty: Party;
//   consentingParties: Party[];
//   subjectRegistryInstruments: RegistryDocumentModel[];
//   subjectRegistryPlans: RegistryPlan[];
// }
