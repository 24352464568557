import * as React from 'react';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { useConfirmationDialogStyles } from './styles';

interface Props {
  onClose: (data?: boolean) => void;
  isLoading: boolean;

  sentToName: string | undefined;
}

function WithdrawDialog(props: Props): JSX.Element {
  const { onClose, sentToName, isLoading } = props;
  const confirmationDialogClasses = useConfirmationDialogStyles();

  return (
    <ConfirmationDialog
      title={'Withdraw Invite'}
      color="primary"
      showActionButtons={true}
      open={true}
      scroll="body"
      okButtonText="Withdraw invite"
      onClose={onClose}
      isLoading={isLoading}
      classes={{
        //
        dialogPaper: confirmationDialogClasses.dialogPaper,
        okButton: confirmationDialogClasses.withdrawInviteButton
      }}
    >
      <strong>{sentToName} </strong>
      <span className={confirmationDialogClasses.description}>will no longer be invited to collaborate in this workspace.</span>
    </ConfirmationDialog>
  );
}

export default WithdrawDialog;
