import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

export const sharedStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0.5, 2), // '4px 16px'
      padding: theme.spacing(0.25, 1), // '2px 8px'
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      '&:hover': {
        backgroundColor: colors.GREY_10
      },
      transition: 'background 0.7s'
    },
    link: {
      color: colors.BLACK,
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43 // 20px
    },
    linkForTooltipContent: {
      fontWeight: 400,
      lineHeight: 1.43,
      fontSize: '13px'
    }
  });
