import { createSelector } from 'reselect';

import { TitleVerificationResponse } from '../../models';
import { DuplicateWorkspaceItem } from './models';

const DEFAULT_DUPLICATE_WORKSPACES: DuplicateWorkspaceItem[] = [];

const getDuplicateWorkspaces = (response?: TitleVerificationResponse) => response?.duplicateWorkspaces ?? DEFAULT_DUPLICATE_WORKSPACES;

export const duplicateWorkspacesSelector = createSelector<
  //
  TitleVerificationResponse | undefined,
  DuplicateWorkspaceItem[],
  DuplicateWorkspaceItem[]
>(getDuplicateWorkspaces, (duplicateWorkspaces: Array<DuplicateWorkspaceItem>) => {
  return duplicateWorkspaces;
});
