import { createSelector, OutputSelector } from 'reselect';
import store from 'store2';

import { ComplianceLevelEnum } from '@sympli/api-gateway/enums';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { ErrorMessageModel } from './models';

const EMPTY: ErrorMessageModel[] = [];
const getMessages = (messages: ErrorMessageModel[] = EMPTY) => messages;

/**
 * reselect creator function to support generics
 * call this function to create selector, then call the selector
 **/
export function createRegroupIntoErrorsAndWarningsSelector<T extends { complianceLevel: ComplianceLevelEnum }>(): OutputSelector<
  T[] | undefined,
  {
    errors: T[];
    warnings: T[];
  },
  (res: T[]) => {
    errors: T[];
    warnings: T[];
  }
> {
  const EMPTY_WITH_LEVEL = [];
  // return regroupIntoErrorsAndWarningsSelector as any; // dirty hack to trick TS since reselect does not support generics
  return createSelector<
    //
    T[] | undefined,
    T[],
    {
      errors: T[];
      warnings: T[];
    }
  >(
    (messages: T[] = EMPTY_WITH_LEVEL) => messages,
    messages => {
      return {
        errors: messages.filter(m => m.complianceLevel === ComplianceLevelEnum.Error),
        warnings: messages.filter(m => m.complianceLevel === ComplianceLevelEnum.Warning || m.complianceLevel === ComplianceLevelEnum.Information)
      };
    }
  );
}

export const convertMessagesSelector = createSelector<
  //
  ErrorMessageModel[] | undefined,
  ErrorMessageModel[],
  {
    errors: Array<string | JSX.Element>;
    warnings: Array<string | JSX.Element>;
  }
>(getMessages, messages => {
  const errors: Array<string | JSX.Element> = [];
  const warnings: Array<string | JSX.Element> = [];

  messages.forEach(compliance => {
    switch (compliance.level) {
      case ComplianceLevelEnum.Error:
        errors.push(compliance.message);
        break;
      case ComplianceLevelEnum.Warning:
      case ComplianceLevelEnum.Information:
      default:
        if (store.local.get('hideDocWarnings')) {
          Logger.console(SeverityEnum.Debug, 'Warning from document: ' + compliance.message);
        } else {
          warnings.push(compliance.message);
        }
    }
  });

  return { errors, warnings };
});
