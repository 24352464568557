import * as React from 'react';

import { Form, FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCaretLeft } from '@sympli/ui-framework/icons';

import Formik from 'src/components/formik';
import { Header } from 'src/components/layout';
import { actionFetchDirectionsList } from 'src/containers/workspace/financial/directions/actions';
import { resolveDirectionsLink } from 'src/containers/workspace/financial/directions/helpers';
import WorkflowPanel from 'src/containers/workspace/shared/components/workflow-panel';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { modelKey } from 'src/utils/formUtils';
import withRedirectRouter, { RouteRedirectProps } from 'src/utils/withRedirectRouter';
import { actionUpdateStampDutyDetails } from '../../actions';
import StampDutyDocumentList from '../../components/stamp-duty-document-list';
import TransactionStatus from '../../components/transaction-status';
import TransferDetails from '../../components/transfer-details';
import { resolveVerificationTimestampDescription } from '../../helpers';
import { StampDutyApiResponse, StampDutyProgressStatusEnum, VerifyStampDutyApiResponse } from '../../models';
import ProgressMessageNotification from '../components/progress-message-notification';
import { getInitialValues } from './helper';
import { SaStampDutyFormDataModel } from './models';
import styles, { ClassKeys } from './styles';
import getValidationSchema from './validationSchema';

const titleId: string = _uniqueId();

interface OwnProps {
  queryParams: WorkspaceDetailRouteParams;
  detail: StampDutyApiResponse;
  dispatch: Dispatch<Action>;
  isLocked: boolean;
}

interface State {
  isWorkflowLoading: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys> & RouteRedirectProps;

const fieldName = modelKey<SaStampDutyFormDataModel>();

class SaStampDuty extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isWorkflowLoading: false
  };
  private formikProps: FormikProps<SaStampDutyFormDataModel>;

  render() {
    const {
      classes,
      detail,
      queryParams: { workspaceId, participantId },
      isLocked
    } = this.props;
    const { isWorkflowLoading } = this.state;
    const disableVerify = !detail.canVerify || isLocked;
    const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/stampduties`;

    return (
      <FlexLayout flexDirection="column" fullWidth className={classes.rootV2}>
        <ProgressMessageNotification jurisdictionId={JurisdictionsEnum.SA} detail={detail} />
        <WorkflowPanel mode="light">
          {this.renderVerificationTimestamp()}
          {this.renderSubmitButton(disableVerify, isWorkflowLoading)}
        </WorkflowPanel>
        <WorkspacePageContentWrapper className={undefined}>
          <Formik //
            method="post"
            action={action}
            initialValues={getInitialValues(detail)}
            validationSchema={getValidationSchema}
            onPreSubmit={this.handleOnPreSubmit}
            onPostSubmit={this.handleOnPostSubmit}
          >
            {(formikProps: FormikProps<SaStampDutyFormDataModel>) => this.renderForm(formikProps)}
          </Formik>
        </WorkspacePageContentWrapper>
      </FlexLayout>
    );
  }

  private renderVerificationTimestamp() {
    const { classes, detail } = this.props;
    const description = resolveVerificationTimestampDescription(detail);
    if (!description) {
      return null;
    }
    return <Typography className={classes.timestamp}>{description}</Typography>;
  }

  private renderForm(formikProps: FormikProps<SaStampDutyFormDataModel>) {
    const { classes, queryParams, detail, isLocked } = this.props;
    const disableVerify = !detail.canVerify || isLocked;

    const { isSubmitting } = formikProps;
    this.formikProps = formikProps;

    return (
      <Form>
        <Header>Stamp Duty</Header>
        <FlexLayout alignItems="center" className={classes.transactionIdContainer}>
          <Typography className={classes.labelMarginRight} id={titleId}>
            Enter your Transaction ID:{' '}
          </Typography>
          <Field
            aria-labelledby={titleId}
            name={fieldName('transactionId')}
            component={InputField}
            className={classes.transactionIdField}
            disabled={isSubmitting || detail.status === StampDutyProgressStatusEnum.Verifying}
          />
        </FlexLayout>
        <FormGroup title="Transfer details" classes={{ title: classes.formGroupTitle }}>
          {this.renderTransactionId()}
          {this.renderTransferDetails()}
          <StampDutyDocumentList items={detail.stampDutyDocuments} queryParams={queryParams} />
        </FormGroup>
        <FlexLayout justifyContent="space-between" className={classes.formSubmitButtonContainer}>
          <ButtonLink onClick={this.handleOnBackClick} icon={<IconCaretLeft width="18" height="18" />} color="inherit">
            Cancel
          </ButtonLink>
          {this.renderSubmitButton(disableVerify, isSubmitting)}
        </FlexLayout>
      </Form>
    );
  }

  private renderSubmitButton = (disableVerify: boolean, isLoading: boolean) => {
    const { classes, detail, queryParams } = this.props;
    switch (detail.status) {
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.VerificationError:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={disableVerify || isLoading} color="primary" variant="contained">
            Verify Stamp Duty
          </SympliButton>
        );
      case StampDutyProgressStatusEnum.Verifying:
        return (
          <SympliButton onClick={this.handleOnStepperBarSubmit} arrowRight isLoading={isLoading} disabled={true} color="primary" variant="contained">
            Verify Stamp Duty
          </SympliButton>
        );
      case StampDutyProgressStatusEnum.Verified:
        return (
          <FlexLayout>
            <SympliButton
              onClick={this.handleOnStepperBarSubmit}
              isLoading={isLoading}
              disabled={disableVerify || isLoading}
              color="primary"
              variant="outlined"
              className={classes.buttonMarginRight}
            >
              Reverify
            </SympliButton>
            <SympliButton href={resolveDirectionsLink(queryParams)} arrowRight color="primary" variant="contained">
              See settlement distributions
            </SympliButton>
          </FlexLayout>
        );
      case StampDutyProgressStatusEnum.Paid:
        return (
          <SympliButton href={resolveDirectionsLink(queryParams)} arrowRight color="primary" variant="contained">
            See settlement distributions
          </SympliButton>
        );

      case StampDutyProgressStatusEnum.NotApplicable:
      case StampDutyProgressStatusEnum.PreparingDocument:
      default:
        return `Unsupported status: ${detail.status}`;
    }
  };

  private renderTransactionId() {
    const { detail } = this.props;
    const { status, referenceNumber, assessmentNumber } = detail;
    switch (status) {
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.VerificationError:
        return <TransactionStatus referenceNumber={referenceNumber} />;
      case StampDutyProgressStatusEnum.Verified:
        return <TransactionStatus referenceNumber={referenceNumber} assessmentNumber={assessmentNumber} verified />;
      default:
        return null;
    }
  }

  private renderTransferDetails() {
    const { detail, classes } = this.props;
    const { consideration, dutyAmount, payableDuty, penaltyTax = '', foreignOwnershipSurcharge = '', utiAmount = '' } = detail;
    switch (detail.status) {
      case StampDutyProgressStatusEnum.PreparingDocument:
        return <TransferDetails items={[{ label: 'Consideration ($)', value: consideration }]} />;
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.Verifying:
      case StampDutyProgressStatusEnum.Verified:
      case StampDutyProgressStatusEnum.VerificationError:
      case StampDutyProgressStatusEnum.Paid:
        return (
          <TransferDetails
            items={[
              { label: 'Consideration ($)', value: consideration },
              { label: 'Stamp Duty ($)', value: dutyAmount },
              { label: 'Penalty tax ($)', value: penaltyTax },
              { label: 'Foreign ownership Surcharge ($)', value: foreignOwnershipSurcharge, className: classes.transferDetailsLabel },
              { label: 'UTI ($)', value: utiAmount },
              { label: 'Payable duty ($)', value: payableDuty }
            ]}
          />
        );
      default:
        return `Unsupported status: ${detail.status}`;
    }
  }

  private handleOnStepperBarSubmit = () => {
    this.formikProps && this.formikProps.submitForm();
  };

  private handleOnPreSubmit = (values: SaStampDutyFormDataModel): SaStampDutyFormDataModel => {
    this.setState({ isWorkflowLoading: true });
    return values;
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<SaStampDutyFormDataModel, VerifyStampDutyApiResponse>) => {
    this.setState({ isWorkflowLoading: false });
    if (!args.error) {
      batch(() => {
        this.props.dispatch(actionUpdateStampDutyDetails({ ...args.response }));
        this.props.dispatch(actionFetchDirectionsList.request(this.props.queryParams));
      });
    }
  };

  private handleOnBackClick = () => {
    const { queryParams } = this.props;
    this.props.navigate(resolveWorkspaceDetailLink(queryParams));
  };
}

const styledComponent = withStyles(styles)(withRedirectRouter(SaStampDuty));
export default styledComponent;
