export enum TitleListItemStatusEnum {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
  Existing = 'Existing'
}
export interface TitleListItem {
  titleReference: string;
  status: TitleListItemStatusEnum;
  message?: string;
  address?: string;
  additionalMessage?: string;
}
