import * as React from 'react';

import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import MuiPopover, { PopoverProps } from '@mui/material/Popover';

import { IconClose } from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';
import { StyleProps, useStyles } from './styles';

type Props = Omit<PopoverProps, 'onClose' | 'onEnter' | 'onEntered' | 'onEntering' | 'onExit' | 'onExited' | 'onExiting'> & {
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
  hideCloseButton?: boolean;
  closeIconColor?: string;
} & StyleProps;

function Popover({
  id,
  open,
  anchorEl,
  children,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  },
  arrowPosition = 'top',
  offset,
  onClose,
  hideCloseButton,
  PaperProps = {},
  closeIconColor,
  ...otherProps
}: Props) {
  const classes = useStyles({ arrowPosition, offset });
  return (
    <MuiPopover
      id={id}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      anchorEl={anchorEl}
      PaperProps={{
        ...PaperProps,
        className: classNames(classes.paper, PaperProps.className)
      }}
      {...otherProps}
    >
      {Boolean(!hideCloseButton) && (
        <IconButton
          className={classes.closeButton}
          onClick={e => {
            onClose?.(e, 'closeButtonClick');
          }}
          aria-label="Close"
          size="large"
        >
          <IconClose className={classes.closeIcon} stroke={closeIconColor ?? colors.BLACK} />
        </IconButton>
      )}

      {children}
    </MuiPopover>
  );
}

export default Popover;
