import { OidcClient } from 'src/models/auth';
import { CONFIG_PATH } from './resolveRunTimeEnv';

export type Configurations = {
  oidcClients: OidcClient[];
};

const fetchConfigurations = async (): Promise<Configurations> => {
  return fetch(CONFIG_PATH).then(response => response.json());
};

export default fetchConfigurations;
