import * as yup from 'yup';

export const vicTitleReferenceTest: yup.TestOptions = {
  name: 'Vic title reference check',
  message: 'Not a valid Victorian title reference',
  test: function test(this: yup.TestContext, titleReference: string) {
    if (typeof titleReference !== 'string' || titleReference === '') {
      return true; // This test does not check type or existence
    }
    const basicTitleMatchResult = titleReference.match(/^(\d+)\/(\d+)([a-zA-Z]*)$/);
    if (!basicTitleMatchResult) {
      return false;
    }
    const [, firstNumeric, secondNumeric, alphabeticSuffix] = basicTitleMatchResult;
    if (![4, 5].includes(firstNumeric.length)) {
      return this.createError({ message: '4 or 5 numbers must precede slash' });
    }
    if (secondNumeric.length !== 3) {
      return this.createError({ message: '3 numbers must follow slash' });
    }
    if (alphabeticSuffix) {
      // Suffix is not required, but when it is present, we must test it.
      if (alphabeticSuffix.length !== 1) {
        return this.createError({ message: 'Suffix must be single letter' });
      }
      if (alphabeticSuffix !== alphabeticSuffix.toUpperCase()) {
        return this.createError({ message: 'Suffix must be uppercase' });
      }
      if (!['A', 'B', 'C', 'K', 'L'].includes(alphabeticSuffix)) {
        return this.createError({ message: 'Suffix must be A, B, C, K or L' });
      }
    }
    return true;
  }
};

export const vicMcpNumberTest: yup.TestOptions = {
  name: 'Vic MCP number check',
  message: 'Not a valid Victorian MCP number',
  test: function test(this: yup.TestContext, mcpNumber: string) {
    // TODO: Uncomment all following code (and modify if necessary) when required MCP format is clarified
    // if (typeof mcpNumber !== 'string' || mcpNumber === '') {
    //   return true; // This test does not check type or existence
    // }
    // const matchResult = mcpNumber.match(/^([a-zA-Z]*)(\d+)$/);
    // if (!matchResult) {
    //   return false;
    // }
    // const [, alphabeticPrefix, numeric] = matchResult;
    // if (alphabeticPrefix) {
    //   // Prefix is not required, but when it is present, we must test it.
    //   if (alphabeticPrefix !== alphabeticPrefix.toUpperCase()) {
    //     return this.createError({ message: 'Prefix must be uppercase' });
    //   }
    //   if (alphabeticPrefix.length > 2) {
    //     return this.createError({ message: 'Prefix must not exceed 2 letters' });
    //   }
    // }
    // if (numeric.length < 6) {
    //   return this.createError({ message: alphabeticPrefix ? 'At least 6 numbers must follow prefix' : 'Requires at least 6 numbers' });
    // }
    // if (mcpNumber.length > 15) {
    //   return this.createError({ message: msg.MAX_LENGTH(15) });
    // }
    return true;
  }
};
