import * as React from 'react';

import classNames from 'classnames';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';

import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { resolveColumns } from './helpers';
import { SearchTableContext, SearchTableModel } from './models';
import { useFooterStyles, useSearchTableStyles } from './styles';

const LinkedWorkspaceSearchTable = ({
  //
  pageNumber,
  pageSize,
  rows,
  isLoading,
  totalCount,
  totalPages,
  onRowClick,
  isRefetching,
  onLoadMoreClick
}: {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  rows: SearchTableModel[];
  isLoading: boolean;
  totalCount: number;
  onRowClick: (rowIndex: number, rowData: SearchTableModel) => void;
  isRefetching: boolean;
  onLoadMoreClick: () => void;
}) => {
  const classesFooter = useFooterStyles();
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const tableContext = React.useMemo<SearchTableContext>(() => ({ screenVariant }), [screenVariant]);

  const columns: ColumnsModel<SearchTableModel> = resolveColumns({
    screenVariant
  });

  const classes = useSearchTableStyles({ screenVariant });

  const renderFooterContent = () => {
    return (
      <TableRow
        sx={{
          width: '100%',
          height: 70,
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '8px',
          paddingRight: '8px',
          border: 'none'
        }}
      >
        <TableCell colSpan={6} className="border-none">
          <FlexLayout justifyContent="center" alignItems="center">
            <SympliButton variant="outlined" className={classesFooter.button} onClick={onLoadMoreClick} disabled={isRefetching}>
              Load more
            </SympliButton>
          </FlexLayout>
        </TableCell>
      </TableRow>
    );
  };

  // 64 is the default height of a table row, and also we need to count 1px for border
  // with Footer, we will need to count footer into the whole height
  // 61 header height 65 row height, 72 footer height, 16 or 8 for margin bottom
  const tableHeight = totalCount > pageSize ? 61 + 10 * 65 + 72 + 16 : (rows.length + 1) * 64 + 8;

  return (
    <Table //
      paginationMode={'virtual'}
      virtualTableStyle={{ height: tableHeight, marginBottom: totalCount > pageSize ? 0 : 24 }}
      renderFooterContent={renderFooterContent}
      className={classNames(classes.root)}
      totalPages={totalPages}
      context={tableContext}
      totalCount={totalCount}
      pageNumber={pageNumber}
      pageSize={pageSize}
      columns={columns}
      rows={rows}
      isLoading={isLoading}
      onRowClick={onRowClick}
    />
  );
};

export default React.memo(LinkedWorkspaceSearchTable);
