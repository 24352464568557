import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 0
    },
    icon: {
      paddingRight: 6,
      width: 17,
      height: 17,
      alignSelf: 'flex-start'
    },
    iconGreen: {
      fill: colors.GREEN
    },
    iconRed: {
      fill: colors.RED
    },
    iconOrange: {
      fill: '#FF8200'
    },
    dialogPaper: {
      width: 320
    },
    dialogTitle: {
      textAlign: 'center'
    },
    dialogSubTitle: {
      fontSize: 16,
      color: theme.palette.text.primary
    },
    dialogContent: {
      textAlign: 'center',
      overflow: 'unset',
      padding: 0
    },
    dialogContentTop: {
      width: 250,
      padding: '32px 35px 0px'
    },
    dialogContentBottom: {
      width: 250,
      background: theme.palette.grey[100],
      color: theme.palette.text.disabled,
      padding: '25px 35px 10px'
    },
    summaryHeading: {
      marginBottom: 23
    },
    summaryRow: {
      fontSize: 13,
      paddingTop: 4,
      paddingBottom: 4
    },
    lastSummaryRow: {
      fontWeight: 'bold',
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: 5,
      paddingBottom: 0
    },
    message: {
      fontSize: 13,
      marginLeft: 25,
      marginTop: 15,
      marginBottom: 28,
      textAlign: 'left',
      color: theme.palette.text.disabled
    },
    contentRow: {
      fontSize: 13,
      marginBottom: 15
    },
    marginBottom: {
      marginBottom: 20
    },
    infoTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '20px',
      marginBottom: 12
    },
    infoContentPanel: {
      padding: '10px 0 5px',
      textAlign: 'left'
    },
    infoContent: {
      fontSize: 13,
      color: theme.palette.text.disabled,
      textAlign: 'left'
    },
    button: {
      width: '100%'
    },
    buttonRow: {
      padding: '0 0 20px'
    },
    bold: {
      fontWeight: 'bold'
    },
    sourceSurplus: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold'
    }
  }),
  {
    name: 'SourceFundsDialog'
  }
);
