import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiWithdrawalOfCaveat2_19_1Model, WithdrawalOfCaveat2_19_1Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<WithdrawalOfCaveat2_19_1Model, ApiWithdrawalOfCaveat2_19_1Model>;

function DocumentForm(props: Props): JSX.Element {
  const { validateDebounce } = useDocumentContext();

  const validate = useMemoizableValidation<WithdrawalOfCaveat2_19_1Model>(validationSchema, validateDebounce);

  return (
    <DocumentFormWrapper //
      {...props}
      converter={converter}
      validate={validate}
      RootForm={RootForm}
    />
  );
}

export default React.memo(DocumentForm);
