import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import sharedStyles from '../../../edit-directions/styles';

const styles = (theme: Theme) => ({
  ...sharedStyles(theme),
  ...createStyles({
    flexGrow: {
      flexGrow: 1
    },
    // misc
    deleteButton: {
      position: 'absolute',
      left: -10,
      width: 48 //.MuiIconButton-root has removed the default 48 width
    },
    newRecordText: {
      fontStyle: 'italic',
      color: theme.palette.text.secondary
    },
    autoSurplusEditLink: {
      display: 'inline'
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
