import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { VicAddressSelectField_2_25 as AddressSelectField } from '@sympli-mfe/document-components/address-field/vic/2-25';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createArrayItemModelKeyAppender, createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import { StateEnum } from '@sympli-mfe/enums-2-25/vic';

import { VIC_NOA_PROPERTY_ADDRESS_FORM_CONFIG } from '../../config';
import { NoaPropertyDetailModel, NoticeOfAcquisitionDocumentModel, TitleReferenceModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache

export const isValidPropertyAddress = (address: AddressBookEntityModel) => {
  // [WEB-12460] All addresses allow interstate address except for property address
  return address?.physicalAddress?.state === StateEnum.VIC;
};

interface Props {
  name: string;
}

function PropertyTitlesAndAddress({ name }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;
  const propertyDetail: NoaPropertyDetailModel = _get(values, name);
  const addressBookOptions = useAddressBookOptions(values.addressBook, VIC_NOA_PROPERTY_ADDRESS_FORM_CONFIG /*, isValidPropertyAddress*/);
  const fieldName = createModelKeyAppender<NoaPropertyDetailModel>(name);
  const arrayItemFieldName = createArrayItemModelKeyAppender<TitleReferenceModel>(fieldName('titleReferences'));
  const { disabled, dialogPortalId } = useDocumentContext();

  const hasMultipleTitles = propertyDetail.titleReferences.length > 1;
  return (
    <>
      {propertyDetail.titleReferences.map((tr, i) => {
        const name = arrayItemFieldName(i, 'reference');
        return (
          <TextOnly //
            key={name}
            label={hasMultipleTitles ? `Title ${i + 1}` : 'Title'}
            name={arrayItemFieldName(i, 'reference')}
            fullWidth
          >
            {tr!.reference}
            <br />
            {tr!.landDescriptions
              ?.map(x => x.legalDescription?.trim())
              .filter(Boolean)
              .join(', ')}
          </TextOnly>
        );
      })}

      <AddressSelectField //
        name={fieldName('propertyStreetAddressBookId')}
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        label="address of property"
        optionsOverride={addressBookOptions}
        addressFormConfig={VIC_NOA_PROPERTY_ADDRESS_FORM_CONFIG}
      />
    </>
  );
}

export default React.memo(PropertyTitlesAndAddress);
