import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { UnsignDirectionTrustAccountAuthorisationRecordsApiResponse } from '@sympli/api-gateway/models';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconTimes } from '@sympli/ui-framework/icons';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionUpdateDirectionsListTaaStatus } from 'src/containers/workspace/financial/directions/actions';
import { actionUpdateTrustAccountAuthorisationRecords } from 'src/containers/workspace/financial/trust-account-authorisation-records/actions';
import { UnsignTaaRequestModel, unsignTrustAccountAuthorisationRecords } from './api';
import { useStyles } from './styles';

interface UnsignTaaProps {
  queryParams: UnsignTaaRequestModel;
  isLoading?: boolean;
  setIsWorkflowLoading: (isLoading: boolean) => void;
}

function UnsignTaa(props: UnsignTaaProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, queryParams, setIsWorkflowLoading } = props;

  const handleOnClick = React.useCallback(() => {
    setIsWorkflowLoading(true);
    unsignTrustAccountAuthorisationRecords(queryParams)
      .then((response: UnsignDirectionTrustAccountAuthorisationRecordsApiResponse) => {
        const {
          status,
          authorisationRecordDetail: { accountId, authorisationRecordActions }
        } = response;
        batch(() => {
          dispatch(actionUpdateDirectionsListTaaStatus({ accountId, status, documentActions: authorisationRecordActions }));
          dispatch(actionUpdateTrustAccountAuthorisationRecords({ ...response }));
          dispatch(
            actionOpenGlobalSimpleNotification({
              //
              message: 'Trust account authorisation record was unsigned.',
              variant: 'new-success'
            })
          );
        });
      })
      .catch(error => {
        dispatch(actionCreateGlobalErrorMessage(error));
      })
      .finally(() => {
        setIsWorkflowLoading(false);
      });
  }, [dispatch, queryParams, setIsWorkflowLoading]);

  return (
    <MenuItem onClick={handleOnClick}>
      <IconTimes className={classes.menuItemIconUnsign} />
      <Typography className={classes.menuItemLabel}>Unsign record</Typography>
      {isLoading ? <InlineLoader size={18} /> : null}
    </MenuItem>
  );
}

export default React.memo(UnsignTaa);
