import React, { useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';

import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-25/config';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { getPartyEntityTitle } from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { usePartyBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import TransfereeDetail from '../../components/transferee-detail';
import { EMPTY_ARRAY, NoticeOfAcquisitionDocumentModel, TransfereeDetailModel } from '../../models';
import { VISIBILITY_CHECK_TRANSFEREE_PRIMARY_CONTRACT } from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
}

const debug = !import.meta.env.PROD;

const fieldName = modelKey<NoticeOfAcquisitionDocumentModel>();

function SectionTransferees({ name }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const { values, setFieldValue } = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const partyBookOptions = usePartyBookOptions(values.partyBook, VIC_PARTY_FORM_CONFIG);
  const transferees: TransfereeDetailModel[] = values.transferees ?? EMPTY_ARRAY;

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<TransfereeDetailModel>) => <TransfereeDetail name={itemBinding} />,
    []
  );

  const primaryContactOptions = useMemo(() => {
    return transferees.map(t => ({
      id: t.partyBookId,
      name: partyBookOptions.find(p => p.id === t.partyBookId)?.name ?? 'UNKNOWN'
    }));
  }, [transferees, partyBookOptions]);

  const resolveItemTitle = useCallback(
    ({ item }: DocumentArrayItemRenderProps<TransfereeDetailModel>) => {
      const party = values.partyBook?.find(book => book.id === item.partyBookId);
      return party ? getPartyEntityTitle(party) : 'Individual or organisation';
    },
    [values.partyBook]
  );

  const handleOnPrimaryContactChange = useCallback(
    (e, selectedContact) => {
      const updatedTransferees = values.transferees.map((item: TransfereeDetailModel) => {
        if (!item.isLeadOwner && item.partyBookId === selectedContact) {
          return {
            ...item,
            isLeadOwner: true
          };
        } else if (item.isLeadOwner && item.partyBookId !== selectedContact) {
          return {
            ...item,
            isLeadOwner: false
          };
        }
        // keep the original item
        return item;
      });
      setFieldValue(fieldName('transferees'), updatedTransferees);
    },
    [setFieldValue, values]
  );

  return (
    <div data-name={name} className={classes.root}>
      {VISIBILITY_CHECK_TRANSFEREE_PRIMARY_CONTRACT(values) && (
        <FormGroup
          paddingBottom={false}
          title="Primary contact"
          formTip="Please note, if you are a related corporation or joint owner (as defined in the Land Tax Act 2005) you may be liable for a joint land tax assessment. Only one joint owner will receive the joint assessment on behalf of all owners. Please nominate the person (or corporation) to receive the joint assessment."
        >
          {({ titleId }) => (
            <Field //
              aria-labelledby={titleId}
              name={fieldName('transfereePrimaryContactPartyBookId')}
              component={SelectField}
              options={primaryContactOptions}
              debug={debug}
              placeholder={resolveSelectPlaceholder(true)}
              onChange={handleOnPrimaryContactChange}
              fullWidth
            />
          )}
        </FormGroup>
      )}
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={resolveItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(SectionTransferees);
