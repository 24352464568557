import * as yup from 'yup';

import { $not, getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { APPLICANT_CAPACITY_LOOKUP_OPTIONS } from '../../enums';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: applicantCapacity
// If you want to use memoize, wrap your yup definition in memoizeSchema()
const yupApplicantCapacity = yup
  .mixed()
  .required(msg.REQUIRED)
  .test(
    testContextualRule({
      /**
           * what we found in schema:
           [{"required":{"applicantCapacity":{"$not":0}},"message":"Must be provided"}]
           *
           */
      uniqueTestName: '"applicantCapacity" contextual validation rule #1',
      requirement: (applicantCapacity: number): boolean => $not(applicantCapacity, 0),
      message: 'Must be provided'
    })
  )
  .oneOf(getLookupValuesAllowingEmpty(APPLICANT_CAPACITY_LOOKUP_OPTIONS), msg.INVALID_SELECTION);

export default yupApplicantCapacity;
