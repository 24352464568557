import * as React from 'react';

import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconZeroInbox } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import UserAvatar from 'src/components/avatars/user-avatar';
import MessengerActionBox from 'src/containers/messenger/components/messenger-action-box';
import MessengerHeaderBox from 'src/containers/messenger/components/messenger-header-box';
import { getConversationSubject, getFormattedMessageDate } from 'src/containers/messenger/helpers';
import { ConversationDocument, MessageModel } from 'src/containers/messenger/models';
import NewMessage from '../new-message';
import { MessageListViewEnum } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  // profile
  timezone: UserProfileModel['timezone'];
  // workspace participants
  participants: WorkspaceParticipantApiResponse[];
  // conversations
  items: ConversationDocument[];
  newMessageCount: number;
  onConversationRead(conversationId: string): void;
  onConversationSelect(selectedConversationId?: string): void;
  newMessageDisabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  view: MessageListViewEnum | null;
}

class MessageList extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    view: MessageListViewEnum.MessageList
  };

  private currentDate = new Date();

  render() {
    const { classes, workspaceId, participantId, newMessageCount, onConversationSelect, newMessageDisabled } = this.props;
    const { view } = this.state;
    switch (view) {
      case MessageListViewEnum.NewMessage:
        return <NewMessage workspaceId={workspaceId} participantId={participantId} onConversationSelect={onConversationSelect} onBackClick={this.handleOnBackToListClick} />;
      case MessageListViewEnum.MessageList:
      default:
        return (
          <React.Fragment>
            <MessengerHeaderBox>
              <Typography variant="h2" className={classes.title2}>
                {'Messenger'}
                {newMessageCount !== 0 && (
                  <FlexLayout alignItems="center" justifyContent="center" className={classes.messageCount}>
                    {newMessageCount}
                  </FlexLayout>
                )}
              </Typography>
            </MessengerHeaderBox>
            {this.renderMessageList()}
            <MessengerActionBox>
              <SympliButton color="primary" variant="contained" fullWidth onClick={this.handleOnNewMessageClick} disabled={newMessageDisabled}>
                New message
              </SympliButton>
            </MessengerActionBox>
          </React.Fragment>
        );
    }
  }

  private renderMessageList() {
    const { classes, items = [], participants } = this.props;
    if (items.length === 0) {
      return (
        <FlexLayout fullHeight flexDirection="column" alignItems="center" justifyContent="center">
          <IconZeroInbox className={classes.zeroInboxIcon} />
          <Typography variant="subtitle1">No messages yet</Typography>
          <Typography className={classes.zeroInboxDesc}>This is where your messages will appear</Typography>
        </FlexLayout>
      );
    }
    return (
      <div className={classes.messageList}>
        {items.map((conversion: ConversationDocument) => {
          const recipientId = conversion.recipientParticipantIds[0];
          const recipientParticipant = participants.find(p => p.id === recipientId);
          if (recipientParticipant == null) {
            return null;
          }
          const { conversationId: id, messages, isRead } = conversion;
          const latest = messages[messages.length - 1];
          const subject = getConversationSubject(conversion);
          return (
            <ListItem className={classes.messageItem} button key={id} data-conversation-id={id} onClick={this.handleOnConversationItemClick}>
              {!isRead && <div className={classes.highlightDot} />}
              <UserAvatar src={recipientParticipant.avatarUrl} size="small" />
              <div className={classes.messageItemContent}>
                <FlexLayout alignItems="center" justifyContent="space-between">
                  <Typography className={classNames(classes.messageItemHeader, !isRead && classes.highlightHeader)} title={recipientParticipant.workspaceRole.name}>
                    {recipientParticipant.name} ({recipientParticipant.workspaceRole.name})
                  </Typography>
                  {this.renderConversationDate(latest, isRead)}
                </FlexLayout>
                <Typography className={classNames(classes.messageItemHeader, !isRead && classes.highlightHeader)}>{subject}</Typography>
                <Typography className={classNames(classes.messageItemDesc, !isRead && classes.highlightDesc)}>{latest && latest.text}</Typography>
              </div>
            </ListItem>
          );
        })}
      </div>
    );
  }

  private renderConversationDate(message: MessageModel, isRead: boolean) {
    if (message == null) {
      return null;
    }
    const { classes, timezone } = this.props;
    const { createdDate } = message;
    return (
      <Typography className={classNames(classes.messageItemDate, !isRead && classes.highlightHeader)}>
        {getFormattedMessageDate(new Date(createdDate), this.currentDate, timezone)}
      </Typography>
    );
  }

  private handleOnConversationItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const conversationId = dataAttribute('conversation-id', event) || '';
    const { items, onConversationSelect, onConversationRead } = this.props;
    const conversation = items.find(item => item.conversationId === conversationId);
    if (conversation && !conversation.isRead) {
      onConversationRead(conversationId);
    }
    onConversationSelect(conversationId);
  };

  private handleOnNewMessageClick = () => {
    this.setState({ view: MessageListViewEnum.NewMessage });
  };

  private handleOnBackToListClick = () => {
    this.setState({ view: MessageListViewEnum.MessageList });
  };
}

const styledComponent = withStyles(styles)(MessageList);
export default styledComponent;
