import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspaceLatestTransaction } from '../actions';
import { WorkspaceLatestTransactionApiResponse } from '../models';

export interface WorkspaceLatestTransactionState {
  detail?: WorkspaceLatestTransactionApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: string;
}

const initialState: WorkspaceLatestTransactionState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useWorkspaceLatestTransaction(workspaceId: string) {
  const state = useStoreSelector(store => store.workspaceLatestTransaction);
  if (state.args === workspaceId) {
    return state;
  }
  return initialState;
}

const workspaceLatestTransactionReducer = createReducer<
  //
  WorkspaceLatestTransactionState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceLatestTransaction.request, (state, action): WorkspaceLatestTransactionState => {
    const status = _isEqual(state.args, action.payload.workspaceId) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload.workspaceId
    };
  })
  .handleAction(actionFetchWorkspaceLatestTransaction.success, (state, action): WorkspaceLatestTransactionState => {
    return {
      ...state,
      detail: action.payload.detail,
      status: 'resolved',
      error: undefined
    };
  })
  .handleAction(actionFetchWorkspaceLatestTransaction.failure, (state, action): WorkspaceLatestTransactionState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default workspaceLatestTransactionReducer;
