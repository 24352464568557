import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { LineLoader } from 'src/components/loaders';

interface Props {
  className?: string;
  color?: 'white' | 'grey';
}

function LinkedWorkspaceListLoader({
  color = 'grey', //
  className
}: Props): JSX.Element {
  return (
    <List className={className}>
      <ListItem disableGutters>
        <LineLoader color={color} />
      </ListItem>
      <ListItem disableGutters>
        <LineLoader color={color} />
      </ListItem>
    </List>
  );
}

export default React.memo(LinkedWorkspaceListLoader);
