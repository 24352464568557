import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-17/components/party-justification';
import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { PartyModel, RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { arraysIntersectionsBy, mergeArrays } from '@sympli-mfe/document-forms-framework/utils';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { DischargeOfMortgage2_17_1Model, MortgageePartyModel, MortgageModel, TitleReferenceSelectionModel } from './models';

export const getSelectedMortgages = defaultMemoize((mortgages: MortgageModel[]) => {
  return mortgages.filter(({ isSelected }) => isSelected);
});

export const getMortgageePartyBookIds = defaultMemoize((selectedMortgages: MortgageModel[]) => {
  const mortgageePartyBookIds = selectedMortgages
    .map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId))
    .flat()
    .sort();
  return mortgageePartyBookIds;
});

// returns shared title references across given mortgages
export const filterTitleReferences = defaultMemoize((mortgages: MortgageModel[]): TitleReferenceSelectionModel[] => {
  const titleReferences = getSelectedMortgages(mortgages).map(x => x.titleReferences);
  const result = arraysIntersectionsBy<TitleReferenceSelectionModel>(titleReferences, 'reference');
  return result;
});

export const filterMortgagees = defaultMemoize((mortgages: MortgageModel[]): MortgageePartyModel[] => {
  const mortgagees = getSelectedMortgages(mortgages).map(x => x.mortgagees);
  const result = mergeArrays(mortgagees, 'partyBookId');
  return result;
});

const checkIfNamesAreMismatched = defaultMemoize((selectedMortgages: MortgageModel[], partyBook: PartyModel<NswNameChange>[]) => {
  const mortgageePartyBookIds = getMortgageePartyBookIds(selectedMortgages);
  const selectedMortgageesPartyDetails = partyBook.filter(party => mortgageePartyBookIds.includes(party.id));

  const uniqNames = _uniq(selectedMortgageesPartyDetails.map(mortgagee => mortgagee.legalEntityName)).length;
  if (uniqNames === 1) {
    return false;
  }
  const mortgagees = selectedMortgageesPartyDetails.map(mortgagee => mortgagee.receivingOrRelinquishingDetails) as RelinquishingModel<NswNameChange>[];
  const isJustified = mortgagees.some(({ isChangingName }) => isChangingName);
  if (uniqNames > 1 && !isJustified) {
    return true;
  }
  return false;
});

export const isMortgageeNameMismatch = ({ mortgages, titleReferences, partyBook }: DischargeOfMortgage2_17_1Model): boolean => {
  const selectedMortgages = getSelectedMortgages(mortgages);
  const singleMortgageSelected = selectedMortgages.length === 1;
  if (singleMortgageSelected) {
    return false;
  }

  const isMismatched = checkIfNamesAreMismatched(selectedMortgages, partyBook);
  return isMismatched;
};

export const getOtherDmDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.DischargeOfMortgage && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherDms = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as DischargeOfMortgage2_17_1Model;
    return data.mortgages.filter(mortgage => mortgage.isSelected).flatMap(mortgage => mortgage.dealingNumber);
  });
};
