import * as yup from 'yup';

import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: mortgageDate
const yupMortgageDate = memoizeSchema<Date | string | null, object>(
  yup //
    .date()
    .nullable(true)
    .default(null)
    .typeError(msg.INVALID_DATE)
    .required(msg.REQUIRED)
);

export default yupMortgageDate;
