import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    box: {
      paddingBottom: 0,
      marginBottom: 20
    },
    checkboxGroup: {
      minWidth: 300,
      marginBottom: 10,
      color: colors.BLACK,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 18
    },
    navLink: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textDecoration: 'none'
    }
  }),
  {
    name: 'ReportsPermissions'
  }
);
