import { resolveMemoizedTenancyEditableValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing/validation-schema';

import { SA_MORTGAGE_PARTY_FORM_CONFIG } from '../../config';
import { checkIfJustifiedPartiesAreTheSame, isMortgagorNamesMatchesOrJustified } from '../../helper';
import { Mortgage_2_23_1_Model } from '../../models';

const yupMortgagors = resolveMemoizedTenancyEditableValidation({
  isAddressRequired: true,
  partyCapacityDetailMaxLength: 1000,
  partyFormConfig: SA_MORTGAGE_PARTY_FORM_CONFIG
})
  .testContextualRule({
    uniqueTestName: 'Mortgagors name mismatch test',
    message: 'Please be aware that the parties on the selected titles presently do not match.',
    requirement: (formModel: Mortgage_2_23_1_Model) => isMortgagorNamesMatchesOrJustified(formModel)
  })
  .testContextualRule({
    uniqueTestName: 'Check if party is justified twice',
    message: 'Unable to change name, the proposed name has already been used in a justification.',
    requirement: (formModel: Mortgage_2_23_1_Model) => !checkIfJustifiedPartiesAreTheSame(formModel)
  });

export default yupMortgagors;
