import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import msg from '@sympli/ui-framework/utils/messages';

import { PROPERTY$AREA$UNIT_LOOKUP_OPTIONS, PROPERTY$NATURE_OF_PROPERTY_LOOKUP_OPTIONS, TRANSFER_ARRANGEMENT_LOOKUP_OPTIONS } from '../../enums';
import { AreaModel, FractionModel, PropertyModel } from '../../models';
import { VISIBILITY_CHECK_PROPERTY, VISIBILITY_CHECK_PROPERTY$SHARE_TRANSFERRED } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupProperty = yup
  .mixed()
  .nullable()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_PROPERTY,
      validationWhenVisible: yup
        .object<PropertyModel>()
        .nullable()
        .shape({
          transferArrangement: yup.mixed().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(TRANSFER_ARRANGEMENT_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
          addressBookId: yup.string().default('').trim().required(msg.REQUIRED),
          natureOfProperty: yup.mixed().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(PROPERTY$NATURE_OF_PROPERTY_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
          area: yup
            .object<AreaModel>()
            .required(msg.REQUIRED)
            .shape({
              unit: yup.mixed().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(PROPERTY$AREA$UNIT_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
              numberOfUnits: v.number().required(msg.REQUIRED).min(0.01, msg.VALUE_MUST_BE_AT_LEAST_X(0.01)).max(99999999999.99, msg.VALUE_MUST_BE_X_OR_LESS(99999999999.99))
            }),
          shareTransferred: yup
            .mixed()
            .nullable()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_PROPERTY$SHARE_TRANSFERRED,
                validationWhenVisible: yup
                  .object<FractionModel>()
                  .nullable()
                  .shape({
                    numerator: v.integer().required(msg.REQUIRED),
                    denominator: v.integer().required(msg.REQUIRED)
                  }),
                isObjectOrArray: true
              })
            )
        }),
      isObjectOrArray: true
    })
  )
  .defined();

export default yupProperty;
