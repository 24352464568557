import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { SaAddressSelectField_2_23 as AddressSelectField } from '@sympli-mfe/document-components/address-field/sa/2-23';
import { SA_ADDRESS_FORM_CONFIG as SA_DM_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-23/config';
import { SaPartyForm_2_23 as PartyForm } from '@sympli-mfe/document-components/party-form/sa/2-23';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import Subform from '@sympli/ui-framework/components/formik/subform';

import PartyCapacity from '../../../../components/party-capacity';
import { SA_DM_PARTY_FORM_CONFIG } from '../../config';
import { DischargeOfMortgage_2_23_0_Model, MortgageePartyModel } from '../../models';

type FormValues = DischargeOfMortgage_2_23_0_Model;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  className?: string;
}

function Mortgagee({ name, formikProps, disabled, dialogPortalId, className }: Props): JSX.Element {
  //! we can't use useDocumentContext hook here for dialogPortalId because the DocumentForm
  //! is a class component and generates it's own dialogPortalId
  const fieldName = createModelKeyAppender<MortgageePartyModel>(name);
  const { values } = formikProps;

  const addressBookOptions = useAddressBookOptions(values.addressBook, SA_DM_ADDRESS_FORM_CONFIG);
  const value: MortgageePartyModel = _get(values, name);

  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={SA_DM_PARTY_FORM_CONFIG}
      />
      <PartyCapacity name={fieldName('partyCapacity')} formikProps={formikProps} isReadonly />
      <AddressSelectField //
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        name={fieldName('addressBookId')}
        optionsOverride={addressBookOptions}
      />
    </div>
  );
}

export default React.memo(Mortgagee);
