import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const PAGINATION_HEIGHT_37 = 37;

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    pagination: {
      height: PAGINATION_HEIGHT_37
    }
  });

export type ClassKeys = typeof styles;

export default styles;
