import makeStyles from '@mui/styles/makeStyles';

import { colors as tickleColors } from '@sympli/ui-framework/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      flexShrink: 0,
      height: 34,
      fontSize: 14,
      lineHeight: '16px',
      '&$success': {
        background: tickleColors.TEAL,
        color: tickleColors.WHITE
      },
      '&$warning': {
        background: tickleColors.ORANGE_BG,
        color: tickleColors.BLACK
      },
      '& > *': {
        marginLeft: 4
      }
    },
    primary: {
      fontWeight: 'bold'
    },
    secondary: {
      fontWeight: 'normal'
    },

    link: {
      textDecoration: 'underline'
    },
    icon: {
      width: 16,
      height: 16,
      '&$warning': {
        color: tickleColors.ORANGE
      },
      '&$success': {
        color: tickleColors.WHITE
      },
      marginLeft: 0,
      marginRight: 4
    },

    warning: {},
    success: {}
  }),
  {
    name: 'DocumentNotificationPanel'
  }
);
