import { ConditionalLookupEnumModel } from '@sympli-mfe/document-forms-framework/utils';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { ClaimDetailsModel } from './models';

export enum ClaimCategoryEnum {
  Charge = 'Charge',
  ConstructiveTrust = 'Constructive Trust',
  Mortgage = 'Mortgage',
  Purchase = 'Purchase'
}
export enum EstateOrInterestClaimedEnum {
  AsMortgageeOfAnEstateInTheFeeSimple = 'An equitable interest as mortgagee of an estate in fee simple',
  AsPurchaserOfAnEstateInTheFeeSimple = 'An equitable interest as purchaser of an estate in fee simple',
  AsChargeeOfAnEstateInTheFeeSimple = 'An equitable interest as chargee of an estate in fee simple',
  AsChargeeOfAnEstateInTheFeeSimpleInTheInterestOf = 'An equitable interest as chargee of an estate in fee simple in the interest of',
  InTheFeeSimpleEstate = 'An equitable interest in the fee simple estate'
}
export enum ClaimStatementEnum {
  Statement1 = 'The Caveator claims an interest as mortgagee pursuant to a clause in an agreement between the registered owner and the Caveator charging the property to secure to the Caveator the money therein stated. The clause number and agreement date are:',
  Statement2 = 'The Caveator claims an interest as mortgagee pursuant to an unregistered mortgage in registrable form, to secure the money therein stated, executed by the registered owner on',
  Statement3 = 'Pursuant to a contract/agreement between the registered owner as vendor and the Caveator as purchaser, dated',
  Statement4 = 'Pursuant to a clause in an agreement between the registered owner and the Caveator, whereby the registered owner agreed to charge all their interest in the land with payment of monies owing to the Caveator. The clause number and agreement date are:',
  Statement5 = 'Pursuant to an agreement between the registered owner and the Caveator, whereby the registered owner agreed to charge all their interest in the land with payment of monies owing to the Caveator, dated',
  Statement6 = "Pursuant to a clause of that part of a written account application entitled 'Deed of Guarantee and Indemnity' between the registered owner and the Caveator by which the registered owner charged their interest in the land with payment of monies owning to the Caveator. The clause number and application date are:",
  Statement7 = "Pursuant to a clause in an agreement between the registered owner identified in the 'Interest being claimed' and the Caveator whereby the registered owner agreed to charge all their interest in the land with payment of monies owing to the Caveator. The clause number and agreement date are:",
  Statement8 = "Pursuant to an agreement between the registered owner identified in the 'Interest being claimed' and the Caveator, whereby the registered owner agreed to charge all their interest in the land with payment of monies owing to the Caveator dated",
  Statement9 = "Pursuant to a clause of that part of a written account application entitled 'Deed of Guarantee and Indemnity' between the registered owner identified in the 'Interest being claimed' and the Caveator by which the registered owner charged their interest in the land with payment of monies owning to the Caveator. The clause number and application date are:",
  Statement10 = 'Pursuant to a constructive and/or implied or resulting trust arising upon the financial and nonfinancial contributions of the Caveator to the acquisition, conservation and/or improvement of the property.'
}

interface ClaimDetailsConfigModel {
  estateOrInterestClaimed: EstateOrInterestClaimedEnum;
  claimCategory: ClaimCategoryEnum;
  claimStatement: ClaimStatementEnum;
  detailsSupportingTheClaim: boolean;
  claimPartyDetails: boolean;
  claimDate: boolean;
}
export const claimDetailsConfigs: ClaimDetailsConfigModel[] = [
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsMortgageeOfAnEstateInTheFeeSimple,
    claimCategory: ClaimCategoryEnum.Mortgage,
    claimStatement: ClaimStatementEnum.Statement1,
    detailsSupportingTheClaim: true,
    claimPartyDetails: false,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsMortgageeOfAnEstateInTheFeeSimple,
    claimCategory: ClaimCategoryEnum.Mortgage,
    claimStatement: ClaimStatementEnum.Statement2,
    detailsSupportingTheClaim: false,
    claimPartyDetails: false,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsPurchaserOfAnEstateInTheFeeSimple,
    claimCategory: ClaimCategoryEnum.Purchase,
    claimStatement: ClaimStatementEnum.Statement3,
    detailsSupportingTheClaim: false,
    claimPartyDetails: false,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsChargeeOfAnEstateInTheFeeSimple,
    claimCategory: ClaimCategoryEnum.Charge,
    claimStatement: ClaimStatementEnum.Statement4,
    detailsSupportingTheClaim: true,
    claimPartyDetails: false,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsChargeeOfAnEstateInTheFeeSimple,
    claimCategory: ClaimCategoryEnum.Charge,
    claimStatement: ClaimStatementEnum.Statement5,
    detailsSupportingTheClaim: false,
    claimPartyDetails: false,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsChargeeOfAnEstateInTheFeeSimple,
    claimCategory: ClaimCategoryEnum.Charge,
    claimStatement: ClaimStatementEnum.Statement6,
    detailsSupportingTheClaim: true,
    claimPartyDetails: false,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsChargeeOfAnEstateInTheFeeSimpleInTheInterestOf,
    claimCategory: ClaimCategoryEnum.Charge,
    claimStatement: ClaimStatementEnum.Statement7,
    detailsSupportingTheClaim: true,
    claimPartyDetails: true,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsChargeeOfAnEstateInTheFeeSimpleInTheInterestOf,
    claimCategory: ClaimCategoryEnum.Charge,
    claimStatement: ClaimStatementEnum.Statement8,
    detailsSupportingTheClaim: false,
    claimPartyDetails: true,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.AsChargeeOfAnEstateInTheFeeSimpleInTheInterestOf,
    claimCategory: ClaimCategoryEnum.Charge,
    claimStatement: ClaimStatementEnum.Statement9,
    detailsSupportingTheClaim: true,
    claimPartyDetails: true,
    claimDate: true
  },
  {
    estateOrInterestClaimed: EstateOrInterestClaimedEnum.InTheFeeSimpleEstate,
    claimCategory: ClaimCategoryEnum.ConstructiveTrust,
    claimStatement: ClaimStatementEnum.Statement10,
    detailsSupportingTheClaim: false,
    claimPartyDetails: false,
    claimDate: false
  }
];

export const ENUM_CLAIM_CATEGORY_OPTIONS: LookupEnumModel<ClaimCategoryEnum>[] = [
  {
    id: ClaimCategoryEnum.Charge,
    name: ClaimCategoryEnum.Charge
  },
  {
    id: ClaimCategoryEnum.ConstructiveTrust,
    name: ClaimCategoryEnum.ConstructiveTrust
  },
  {
    id: ClaimCategoryEnum.Mortgage,
    name: ClaimCategoryEnum.Mortgage
  },
  {
    id: ClaimCategoryEnum.Purchase,
    name: ClaimCategoryEnum.Purchase
  }
];

export const ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS: ConditionalLookupEnumModel<EstateOrInterestClaimedEnum>[] = Object.values(EstateOrInterestClaimedEnum).map(value => {
  return {
    id: value,
    name: value,
    _isVisible: (claimDetails: ClaimDetailsModel): boolean => {
      return claimDetailsConfigs.some(item => item.claimCategory === claimDetails.claimCategory && item.estateOrInterestClaimed === value);
    }
  };
});

export const ENUM_CLAIM_STATEMENT_OPTIONS: ConditionalLookupEnumModel<ClaimStatementEnum>[] = Object.values(ClaimStatementEnum).map(value => {
  return {
    id: value,
    name: value,
    _isVisible: (claimDetails: ClaimDetailsModel): boolean => {
      return claimDetailsConfigs.some(
        item => item.claimCategory === claimDetails.claimCategory && item.estateOrInterestClaimed === claimDetails.estateOrInterestClaimed && item.claimStatement === value
      );
    }
  };
});

export enum ActionProhibitedEnum {
  Absolute = 'Absolute',
  AbsoluteWithExceptions = 'Absolute with exceptions'
}

export enum RegistryInstrumentTypeEnum {
  AdministrativeNotingMiscellaneous = 'Administrative noting miscellaneous',
  AffectedAreaSustainablePlanningAct = 'Affected area sustainable planning act',
  Amalgamation = 'Amalgamation',
  Amendment = 'Amendment',
  AmendmentOfLease = 'Amendment of lease',
  AnnulmentOfBankruptcy = 'Annulment of bankruptcy',
  ApplicationForAdversePossession = 'Application for adverse possession',
  ApplicationForTitle = 'Application for title',
  AppointmentOfAdministrator = 'Appointment of administrator',
  AppointmentOfManager = 'Appointment of manager',
  BeachProtectionCompensationNotice = 'Beach protection compensation notice',
  BuildingManagementStatement = 'Building management statement',
  BuildingUnitPlan = 'Building unit plan',
  Cancellation = 'Cancellation',
  CancellationWritCaveat = 'Cancellation writ/caveat',
  CarbonAbatementInterest = 'Carbon abatement interest',
  CarbonFarmingInitiative = 'Carbon farming initiative',
  Caveat = 'Caveat',
  ChangeOfAdministeringDepartment = 'Change of administering department',
  ChangeOfCapabilitiesNotice = 'Change of capabilities notice',
  ChangeOfName = 'Change of name',
  ChangeOfOwnerDetails = 'Change of owner details',
  Charge = 'Charge',
  CoastalProtectionNotice = 'Coastal protection notice',
  CommonwealthAcquisition = 'Commonwealth acquisition',
  CommonwealthApplication = 'Commonwealth application',
  ContaminatedLandNotice = 'Contaminated land notice',
  ContinuationOfEasement = 'Continuation of easement',
  CorrectionOfName = 'Correction of name',
  CorrectionOfOwnerDetails = 'Correction of owner details',
  Covenant = 'Covenant',
  CriminalProceedsConfiscationAct2002 = 'Criminal proceeds confiscation act 2002',
  CtTenureDocDispense = 'Ct/tenure doc dispense',
  DepositOfCt = 'Deposit of ct',
  Determination = 'Determination',
  DeterminationOfRealignment = 'Determination of realignment',
  Discharge = 'Discharge',
  DischargeOfTrustee = 'Discharge of trustee',
  DisclaimerOfLease = 'Disclaimer of lease',
  DispenseWithTitle = 'Dispense with title',
  Easement = 'Easement',
  EasementInGross = 'Easement in gross',
  ExtensionOfPriorityNotice = 'Extension of priority notice',
  ExtensionOfReservationOfName = 'Extension of reservation of name',
  Extinguishment = 'Extinguishment',
  GroupTitlesPlan = 'Group Titles Plan',
  HeritageRegisterSiteNotice = 'Heritage register site notice',
  HighDensityDevelopmentEasement = 'High-density development easement',
  Indemnity = 'Indemnity',
  IndigenousCulturalInterest = 'Indigenous cultural interest',
  LandAndWaterManagementPlan = 'Land and water management plan',
  LandManagementPlan = 'Land management plan',
  LandValuationActNotice = 'Land valuation act notice',
  Lapsing = 'Lapsing Sec 126 (2) (3)',
  Lease = 'Lease',
  LeaseholdBuildingUnitPlan = 'Leasehold building unit plan',
  LocalGovernmentRequest = 'Local government request',
  Merger = 'Merger',
  MixedCommunityPlan = 'Mixed community plan',
  MixedPrecinctPlan = 'Mixed precinct plan',
  MixedStratumPlan = 'Mixed stratum plan',
  Mortgage = 'Mortgage',
  NativeTitleDetermination = 'Native title determination',
  NatureRefugeNoting = 'Nature refuge noting',
  Notice = 'Notice - Sec. 126(2)(3) Land title act',
  NoticeOfAction = 'Notice of action',
  NoticeOfIntentionToResume = 'Notice of intention to resume',
  NoticeOfRealignment = 'Notice of realignment',
  OrderByReferee = 'Order by referee',
  OrderInCouncil = 'Order in council',
  OrderOfTheCourt = 'Order of the court',
  OwnerBuilderNotice = 'Owner builder notice',
  PartialSurrender = 'Partial surrender',
  PartialSurrenderToTheState = 'Partial surrender to the state',
  PrecinctPlan = 'Precinct plan',
  PriorityNotice = 'Priority notice',
  PriorityOfMortgage = 'Priority of mortgage',
  ProfitAPrendre = 'Profit a prendre',
  RecordDeath = 'Record death',
  RecordOfDeath = 'Record of death',
  RecordOfDeathOfLessee = 'Record of death of lessee',
  RegistrarOfTitlesNoting = 'Registrar of titles noting',
  Release = 'Release',
  RemedialActionNotice = 'Remedial action notice',
  Removal = 'Removal',
  RemovalOfAdministrativeAdvice = 'Removal of administrative advice',
  RemovalOfAdministrator = 'Removal of administrator',
  RemovalOfAffectedDealingCorrection = 'Removal of affected dealing (correction)',
  RemovalOfLapsedCaveat = 'Removal of lapsed caveat',
  RemovalOfNatureRefugeNoting = 'Removal of nature refuge noting',
  RemovalOfTrustee = 'Removal of trustee',
  RemovalOfWaterLicenceNotice = 'Removal of water licence notice',
  RequestForFirstCms = 'Request for first cms',
  RequestForIndefeasibleTitle = 'Request for indefeasible title',
  RequestForNewCms = 'Request for new cms',
  ReservationOfName = 'Reservation of name',
  ResignationOfTrustee = 'Resignation of trustee',
  RestorationNotice = 'Restoration notice',
  Resumption = 'Resumption',
  ResumptionByTheState = 'Resumption by the state',
  ResumptionOfDealing = 'Resumption of dealing',
  RetirementOfTrustee = 'Retirement of trustee',
  Revocation = 'Revocation',
  RevocationOfResumptionOfLand = 'Revocation of resumption of land',
  RiverImprovementAgreement = 'River improvement agreement',
  RiverImprovementTrustNotice = 'River improvement trust notice',
  RoadDedication = 'Road dedication',
  RollingTermLeaseNoting = 'Rolling term lease noting',
  Satisfaction = 'Satisfaction',
  Sec174Notation = 'Sec 174 notation',
  Sec358Surrender = 'Sec 358 surrender',
  Sec359Correction = 'Sec 359 correction',
  StatutoryCharge = 'Statutory charge',
  StockRouteAgreement = 'Stock route agreement',
  StrategicCroppingLand = 'Strategic cropping land',
  StratumPlan = 'Stratum plan',
  SubLease = 'Sub lease',
  SugarAccessRightNotice = 'Sugar access right notice',
  Surrender = 'Surrender',
  SurrenderToTheState = 'Surrender to the state',
  SurveyPlan = 'Survey plan',
  Transfer = 'Transfer',
  TransferByThirdParty = 'Transfer by third party',
  TransferOfRightsAndInterests = 'Transfer of rights and interests',
  TransferToTrustee = 'Transfer to trustee',
  TransferToTrusteesByThirdParty = 'Transfer to trustees by third party',
  Transmission = 'Transmission',
  TransmissionByBankruptcy = 'Transmission by bankruptcy',
  TransmissionByDeath = 'Transmission by death',
  TransmissionWithOriginalWill = 'Transmission with original will',
  VegetationManagementNotice = 'Vegetation management notice',
  Vesting = 'Vesting',
  VoluntaryEnvironmentalAgreement = 'Voluntary environmental agreement',
  WaterAllocationNotice = 'Water allocation notice',
  WaterFacilityAgreement = 'Water facility agreement',
  WetTropicsAreaNotice = 'Wet tropics area notice',
  Withdrawal = 'Withdrawal',
  WithdrawalOfPriorityNotice = 'Withdrawal of priority notice',
  WithdrawalOfReservationOfName = 'Withdrawal of reservation of name',
  Writ = 'Writ'
}

export const ENUM_ACTION_PROHIBITED_OPTIONS: LookupEnumModel<ActionProhibitedEnum>[] = Object.values(ActionProhibitedEnum).map(value => {
  return {
    id: value,
    name: value
  };
});

export const ENUM_REGISTRY_INSTRUMENT_TYPE_OPTIONS: LookupEnumModel<RegistryInstrumentTypeEnum>[] = Object.values(RegistryInstrumentTypeEnum).map(value => {
  return {
    id: value,
    name: value
  };
});
