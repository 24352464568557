import { all, call, put, takeLatest } from 'typed-redux-saga';
import { PayloadAction } from 'typesafe-actions';

import endpoints from '@sympli/api-gateway/endpoints';
import {
  WorkspaceDirectionsApiResponse,
  WorkspaceDirectionsCategoriesApiResponse,
  WorkspaceDirectionsOverviewApiResponse,
  WorkspaceDirectionsStatusApiResponse
} from '@sympli/api-gateway/models';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { actionFetchAllDirections, actionUpdateAllDistributionParticipant } from 'src/containers/workspace/financial/all-directions/actions';
import { actionCombinationAllDirectionActionsApprove, actionUpdateDirectionsWorkForm } from 'src/containers/workspace/financial/directions/actions';
import { actionFetchDirections, actionFetchDirectionsCategories, actionFetchDirectionsList } from './actions';

export interface DirectionsApiRequest {
  workspaceId: string;
  participantId: string;
}

export const fetchDirections = (args: DirectionsApiRequest) => {
  return endpoints.getDirections(args);
};

export function* sagaFetchDirections(action: ReturnType<typeof actionFetchDirections.request>) {
  try {
    const data: WorkspaceDirectionsApiResponse = yield* call(fetchDirections, action.payload);
    yield put(actionFetchDirections.success({ data }));
  } catch (error) {
    yield put(actionFetchDirections.failure({ error }));
  }
}

const fetchDirectionsCategories = (query: DirectionsApiRequest) => {
  return endpoints.getDirectionCategories(query);
};

export function* sagaFetchDirectionsCategories(action: ReturnType<typeof actionFetchDirectionsCategories.request>) {
  try {
    const response: WorkspaceDirectionsCategoriesApiResponse = yield* call(fetchDirectionsCategories, action.payload);
    const data = Object.keys(response).reduce((current, key) => {
      // ToDo: In future, as part of a tech debt ticket replace Id:name with Id:Id
      current[key] = response[key].map((item: LookupEnumModel) => ({ id: item.name, name: item.name }));
      return current;
    }, {} as WorkspaceDirectionsCategoriesApiResponse);
    yield put(actionFetchDirectionsCategories.success({ data }));
  } catch (error) {
    yield put(actionFetchDirectionsCategories.failure({ error }));
  }
}

const fetchDirectionsList = (query: DirectionsApiRequest) => {
  return endpoints.getDirectionsStatus(query);
};

export function* sagaFetchDirectionsList(action: ReturnType<typeof actionFetchDirectionsList.request>) {
  try {
    const data: WorkspaceDirectionsStatusApiResponse = yield* call(fetchDirectionsList, action.payload);
    yield put(actionFetchDirectionsList.success({ data }));
  } catch (error) {
    yield put(actionFetchDirectionsList.failure({ error }));
  }
}

const fetchAllDirections = (args: DirectionsApiRequest) => {
  return endpoints.getDirectionsOverview(args);
};

export function* sagaHandleAllActionsApprove(action: ReturnType<typeof actionCombinationAllDirectionActionsApprove.request>) {
  const { newDirectionForm, newDistributionsParticipantData, workspaceId, participantId } = action.payload;
  const actions: PayloadAction<any, any>[] = [actionUpdateDirectionsWorkForm(newDirectionForm)];
  if (newDistributionsParticipantData != null && !!participantId) {
    actions.push(actionUpdateAllDistributionParticipant.request({ distributionsParticipant: newDistributionsParticipantData, participantId }));
  }

  try {
    if (!!workspaceId && !!participantId) {
      const allDirectionsDetail: WorkspaceDirectionsOverviewApiResponse = yield* call(fetchAllDirections, { workspaceId, participantId });
      actions.push(actionFetchAllDirections.success({ data: allDirectionsDetail }));
    }
  } catch (error) {
    actions.push(actionFetchAllDirections.failure({ error }));
  }

  yield all(actions.map(action => put(action)));
}

export default [
  takeLatest(actionFetchDirections.request, sagaFetchDirections),
  takeLatest(actionFetchDirectionsCategories.request, sagaFetchDirectionsCategories),
  takeLatest(actionFetchDirectionsList.request, sagaFetchDirectionsList),
  takeLatest(actionCombinationAllDirectionActionsApprove.request, sagaHandleAllActionsApprove)
];
