import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { NoticeOfAcquisitionDocumentModel } from '../../models';
import { ERROR_MESSAGE_ASSIGN_ALL_TITLES_TO_PROPERTY } from '../../validationSchema';

export const resolveAllTitlesAssigned = <
  TModel extends {
    //
    allTitlesAssigned?: any;
  }
>(
  { errors, touched }: FormikProps<NoticeOfAcquisitionDocumentModel>,
  fieldName: (name: keyof TModel & string) => string,
  value: TModel
) => {
  const yupAllTitlesAssigned = _get(errors, fieldName('allTitlesAssigned')) || ERROR_MESSAGE_ASSIGN_ALL_TITLES_TO_PROPERTY;
  const showAllTitlesAssignedMessageAsError = yupAllTitlesAssigned && _get(touched, fieldName('allTitlesAssigned'));
  return {
    message: yupAllTitlesAssigned,
    touched: showAllTitlesAssignedMessageAsError
  };
};
