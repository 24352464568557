import * as React from 'react';

import classNames from 'classnames';

import { useStyles } from './styles';

interface MainProps extends React.HTMLAttributes<HTMLDivElement> {}

function Main(props: React.PropsWithChildren<MainProps>) {
  const { className, children, ...otherProps } = props;
  const classes = useStyles();
  const globalCssName = 'tickle-main';

  return (
    <div className={classNames(classes.root, globalCssName, 'tickle-scroll-to-error', className)} {...otherProps}>
      {children}
    </div>
  );
}

export default Main;
