import React from 'react';

import classnames from 'classnames';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { VariantEnum } from '../../models';
import { useStyles } from './styles';

type Props = Exclude<AccordionSummaryProps, 'classes'> & {
  expanded: boolean;
  variant: VariantEnum;
};

const CustomAccordionSummary = ({ expanded, variant, ...rest }: Props) => {
  const classes = useStyles();

  const expandButtonIcon = React.useMemo(() => {
    const expandIconClassName = classnames(
      classes.expandIconContent,
      { [classes.errorExpandIcon]: variant === VariantEnum.Error },
      { [classes.warningExpandIcon]: variant === VariantEnum.Warning },
      { [classes.infoExpandIcon]: variant === VariantEnum.Info }
    );

    return expanded ? (
      <div className={classes.expandContainer}>
        <Typography variant="subtitle2" className={classes.expandText}>
          Hide details
        </Typography>
        <div className={expandIconClassName}>&minus;</div>
      </div>
    ) : (
      <div className={classes.expandContainer}>
        <Typography variant="subtitle2" className={classes.expandText}>
          See details
        </Typography>
        <div className={expandIconClassName}>+</div>
      </div>
    );
  }, [classes, expanded, variant]);

  return (
    <MuiAccordionSummary
      classes={{
        root: classes.root,
        content: classes.content,
        expandIconWrapper: classes.expandIconWrapper,
        expanded: classes.expanded
      }}
      expandIcon={expandButtonIcon}
      {...rest}
    />
  );
};

export default React.memo(CustomAccordionSummary);
