import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createArrayItemModelKeyAppender, createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import DealingRequirementsDetailArray from '../../components/dealing-requirements-detail-array';
import { DEALING_REQUIREMENT_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { DealingRequirementsDetailModel, DealingRequirementsSummaryModel } from '../../models';
import { VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL } from '../../visibilityChecks';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

const HAS_ADD_DEALING_REQUIREMENT_OPTIONS: LookupEnumModel<boolean>[] = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
];

function SectionDealingRequirementsSummary({ name, className, focusRef }: Props): JSX.Element {
  const { values, touched, setFieldTouched } = useFormikContext<DealingRequirementsSummaryModel>();

  const value: DealingRequirementsSummaryModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DealingRequirementsSummaryModel>(name);
  const dealingRequirementDetailItemFieldName = createArrayItemModelKeyAppender<DealingRequirementsDetailModel>(fieldName('dealingRequirementsDetail'));

  const handleHasAddedDealingRequirements = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, hasAddDealingRequirements: boolean): void => {
      if (!hasAddDealingRequirements || !!touched.dealingRequirementsDetail) {
        return;
      }
      setFieldTouched(dealingRequirementDetailItemFieldName(0, 'dealingRequirement'), true, false /* don't trigger validation */);
    },
    [setFieldTouched, dealingRequirementDetailItemFieldName, touched.dealingRequirementsDetail]
  );

  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Add dealing requirements?"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('hasAddedDealingRequirements')}
            component={RadioField}
            disabled={disabled}
            format="boolean"
            options={HAS_ADD_DEALING_REQUIREMENT_OPTIONS}
            onChange={handleHasAddedDealingRequirements}
            action={focusRef}
            autoFocusOption="controlled"
          />
        )}
      </FormGroup>
      {VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL(value) && (
        <DealingRequirementsDetailArray //
          name={fieldName('dealingRequirementsDetail')}
          maxItems={DEALING_REQUIREMENT_TYPE_LOOKUP_OPTIONS.length}
          minItems={1}
        />
      )}
    </div>
  );
}

export default React.memo(SectionDealingRequirementsSummary);
