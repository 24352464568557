import { call, put, takeLatest } from 'typed-redux-saga';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import http, { NoResponseData } from 'src/utils/http';
import { actionArchiveWorkspace, ArchiveWorkspaceApiRequest } from './actions';

export const archiveWorkspaceById = (args: ArchiveWorkspaceApiRequest) => {
  const { workspaceId, participantId } = args;

  if (!participantId) throw new Error(`Invalid participant id`);

  return http.post<NoResponseData>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/archive`, {});
};

export function* sagaArchiveWorkspace(action: ReturnType<typeof actionArchiveWorkspace.request>) {
  try {
    yield* call(archiveWorkspaceById, action.payload);
    yield put(actionArchiveWorkspace.success({ workspaceId: action.payload.workspaceId }));

    const message = 'You have archived this workspace';
    yield put(
      actionOpenGlobalSimpleNotification({
        //
        message,
        autoHideDuration: 5000,
        variant: 'new-success'
      })
    );

    if (!action.payload.callbackAction) {
      return;
    }

    yield put(action.payload.callbackAction);
  } catch (error) {
    yield put(actionArchiveWorkspace.failure({ error }));
    yield put(actionCreateGlobalErrorMessage({ title: 'Unable to archive workspace', message: error.message }));
  }
}

export default [takeLatest(actionArchiveWorkspace.request, sagaArchiveWorkspace)];
