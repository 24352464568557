import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';
import { SearchTableContext } from './models';

export const SCROLL_BAR_WIDTH = '6px';
export const TABLE_WIDTH = 908;

export const useFooterStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      borderRadius: 34,
      borderWidth: 2,
      borderColor: colors.SUNNY_DAY,
      padding: '6px 24px',
      color: colors.SUNNY_DAY_DARK,
      '&:hover': {
        borderColor: colors.SUNNY_DAY,
        backgroundColor: colors.SUNNY_DAY_LITE,
        borderWidth: 2
      }
    }
  }),
  {
    name: 'LinkedWorkspaceSearchTableFooter'
  }
);

export const useSearchTableStyles = makeStyles(
  (theme: Theme) => ({
    root({ screenVariant }: SearchTableContext) {
      if (screenVariant === 'large-screen') {
        return {
          width: '100%',
          '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
            paddingRight: 50
          },
          '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
            paddingRight: 50
          }
        };
      }
      if (screenVariant === 'medium-screen') {
        return {
          width: '100%',
          '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
            paddingRight: 45
          },
          '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
            paddingRight: 45
          }
        };
      }
      return {
        width: '100%',
        '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: 12
        },
        '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: 12
        }
      };
    }
  }),
  {
    name: 'LinkedWorkspaceSearchTable'
  }
);
