import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    // Confirmation dialog
    root: {
      alignItems: 'flex-start'
    },
    dialogPaper: {
      marginTop: 40,
      width: 880,
      maxWidth: 880
    },
    dialogTitle: {
      padding: '36px 40px 12px'
    },
    dialogContent: {
      paddingLeft: 40,
      paddingRight: 40
    },

    // Content
    divider: {
      marginBottom: 22
    },
    stepper: {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: 15,
      paddingBottom: 15,
      marginTop: 15
    },
    leftPanel: {
      width: '65%',
      paddingRight: 30
    },
    rightPanel: {
      width: '35%'
    },
    participants: {
      paddingLeft: 24,
      alignItems: 'start'
    },
    invitations: {
      paddingLeft: 24,
      alignItems: 'start',
      marginTop: -20
    },
    invitationsOnly: {
      paddingLeft: 24,
      alignItems: 'start'
    },
    futureParticipants: {
      paddingLeft: 24,
      marginTop: -15
    },
    futureParticipantsOnly: {
      paddingLeft: 24
    },
    participantName: {
      paddingTop: 12,
      paddingBottom: 0
    },
    participantRole: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px'
    },
    shareWith: {
      marginBottom: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        fontWeight: 700
      }
    }
  }),
  {
    name: 'WorkspaceFileUploadDialog'
  }
);
