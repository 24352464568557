import dateFormat from 'dateformat';

import { vicNameChangeConversion } from '@sympli-mfe/document-components/party-form/vic/2-18/components/party-justification';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { ConversionMap, convertDataStructure } from '@sympli-mfe/document-forms-framework/utils';

import { VIC_TRANSFER_ADDRESS_FORM_CONFIG, VIC_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { ApiTransferDocumentModel, TransferDocumentModel } from './models';
import { combineTransferorsFromSelectedTitleReferences, getPartyIdsAssociatedWithTitleReferences } from './util';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: VIC_TRANSFER_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: VIC_TRANSFER_PARTY_FORM_CONFIG,
  nameChangeConversion: vicNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiTransferDocumentModel, context: IConverterContext): TransferDocumentModel {
  // Implement any necessary conversion from API to form values

  // convert old tenancy structure to the new one.
  // This can be removed once testing in test3 is finished
  const conversionMap: ConversionMap = {
    transferorGroups: 'relinquishingProprietorGroups',
    transfereeGroups: 'receivingProprietorGroups',
    tenancyType: 'shareSplitType'
  };
  apiModel = convertDataStructure(apiModel, conversionMap);

  const relinquishingIds = getPartyIdsAssociatedWithTitleReferences(apiModel.titleReferences);
  const transferorDetails = combineTransferorsFromSelectedTitleReferences(apiModel.titleReferences);
  let formModel: TransferDocumentModel = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
    transferorDetails,
    transfereeDetails: convertReceivingTenancyDetailFromApiToForm(apiModel.transfereeDetails)
  };
  formModel = applyDefaultMap(formModel, fallbackMap);

  return formModel;
}

function convertFromFormToApiModel(formModel: TransferDocumentModel, originalApiModel: ApiTransferDocumentModel): ApiTransferDocumentModel {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    encumbrance,
    transferorDetails, // used by the form but should not be sent back to the server
    transfereeDetails: transfereeDetailsFromForm,
    ...rest
  } = formModel;
  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
    transfereeDetails: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: transfereeDetailsFromForm, partyBookFromForm: partyBookFromForm || [] }),
    encumbrance: {
      ...encumbrance,
      expiryDate: encumbrance.expiryDate ? dateFormat(encumbrance.expiryDate, DateFormatEnum.DATE) : null
    }
  };
}

class Converter implements IConverter<TransferDocumentModel, ApiTransferDocumentModel> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
