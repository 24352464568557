import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  }),
  {
    name: 'DetailForm'
  }
);
