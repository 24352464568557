import * as yup from 'yup';

import { Form24B_2_24_1Model } from './models';
import { yupPartyBook } from './sections/party-book/validationSchema';
import { yupProperty } from './sections/property-data/validationSchema';
import { yupSalePrice } from './sections/sale-price/validationSchema';
import { yupTitleReferences } from './sections/title-references/validationSchema';
import { yupTransactionData } from './sections/transaction-data/validationSchema';
import { yupTransferorsAddress } from './sections/transferors-address/validationSchema';
import yupTransferors from './sections/transferors/validationSchema';

export default yup
  .object<Form24B_2_24_1Model>({
    // no validation
    partyBook: yupPartyBook,
    addressBook: yup.mixed(),
    titleReferences: yupTitleReferences,
    transferors: yupTransferors,
    transferorsAddress: yup.mixed(),
    salePrice: yupSalePrice,
    property: yupProperty.defined(),
    transaction: yupTransactionData,
    transferorsAddressOptions: yupTransferorsAddress.defined()
  })
  .defined()
  .log();
