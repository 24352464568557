import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    acnGroup: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: theme.spacing(1.25)
    },
    dob: {
      '& > div': {
        width: 120
      }
    }
  }),
  {
    name: 'Transferee'
  }
);
