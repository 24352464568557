import React, { useCallback } from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import MortgagorParty from '../../components/mortgagor-party';
import { MortgagorPartyModel } from '../../models';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

function MortgagorPartyReadonlyArray({ name, className, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();

  const renderItem = useCallback(
    //
    ({ itemBinding, itemIndex }: DocumentArrayItemRenderProps<MortgagorPartyModel>) => <MortgagorParty name={itemBinding} focusRef={itemIndex === 0 ? focusRef : undefined} />,
    [focusRef]
  );

  return (
    <div data-name={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={'Mortgagor'}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(MortgagorPartyReadonlyArray);
