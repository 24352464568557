import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructions2_23_1Model, LodgementInstructions2_23_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions2_23_1Model, ApiLodgementInstructions2_23_1Model> {
  fromApiToFormModel(apiValues: ApiLodgementInstructions2_23_1Model, context: IConverterContext): LodgementInstructions2_23_1Model {
    let formValues = applyDefaultMap(apiValues, fallbackMap);
    formValues.isResponsibleParticipant = context.currentParticipant?.isResponsibleParticipant ?? false;
    return formValues;
  }
  fromFormToApiModel(formValues: LodgementInstructions2_23_1Model, _originalApiModel: ApiLodgementInstructions2_23_1Model): ApiLodgementInstructions2_23_1Model {
    return applyVisibilityFallbackMap(formValues, fallbackMap);
  }
}

export default new Converter();
