import { NswDocumentPartyJustification, NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { ApiReceivingTenancyDetailModel, FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FormTenancyDetailModel, PartyGroup, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

// this file was automatically generated from models.ts.mustache
export interface ApiMortgage2_21_2Model {
  mortgagees: ApiReceivingTenancyDetailModel;
  mortgagors: TenancyPartyModel[];
  transferees: PartyGroup<ApiDocumentPartyModel<NswDocumentPartyJustification>>[];
  partyBook: ApiDocumentPartyModel<NswDocumentPartyJustification>[];
  termsAndConditions: StandardTermsModel;
  additionalAttachments?: DocumentAttachmentItemModel[];
  titleReferences: TitleReferenceModel[];
  workspaceTitleReferences?: string[];
  needToGeneratePartyBook: boolean;
  //Refers to all attachments of the document
  attachments?: DocumentAttachmentItemModel[];
}

export interface StandardTermsModel {
  additionalCovenants: string;
  standardTermsDealingNumbers: StandardTermsDealingNumbersModel[];
  preRegisteredStandardTermsDealingNumbers?: string[]; // This is obsolete, do not use or copy in new document version.
  preRegisteredStandardTerms?: PreRegisteredStandardTerm[];
  warning?: string;
}

export interface PreRegisteredStandardTerm {
  value: string;
  description?: string;
}

export interface StandardTermsDealingNumbersModel {
  termNumber: string;
}

export interface TitleReferenceModel {
  estateType?: string;
  isPartLandAffected?: boolean;
  isSelected: boolean;
  landDescriptions?: LandDescriptionModel[];
  mortgagors: PartyGroup<ApiDocumentPartyModel<NswDocumentPartyJustification>>[];
  reference?: string;
  hasTransfer?: boolean;
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export const EMPTY_MORTGAGOR_PARTY: TenancyPartyModel = {
  id: '',
  partyBookId: undefined
};

export type Mortgage2_21_2Model = Omit<ApiMortgage2_21_2Model, 'partyBook' | 'mortgagees' | 'mortgagors'> & {
  partyBookApi: ApiDocumentPartyModel<NswDocumentPartyJustification>[];
  partyBook: PartyModel<NswNameChange>[];
  mortgagors: FormTenancyDetailModel;
  mortgagees: FormReceivingTenancyDetailModel;
};
