import { all, call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchTrustAccountLists, FetchTrustAccountListsApiResponse } from '../actions/trustAccounts';
import { TrustAccountDetailsApiResponse, TrustAccountRequestDetailsApiResponse } from '../trust-accounts/detail/models';

const fetchTrustAccountRequests = () => http.get<TrustAccountRequestDetailsApiResponse[]>('/settings/trust-accounts/requests');

const fetchTrustAccounts = () => http.get<TrustAccountDetailsApiResponse[]>('/settings/trust-accounts');

function* sagaFetchTrustAccountLists() {
  try {
    const data: FetchTrustAccountListsApiResponse = yield* all({
      registeredItems: call(fetchTrustAccounts),
      requestedItems: call(fetchTrustAccountRequests)
    });
    yield put(actionFetchTrustAccountLists.success({ data }));
  } catch (error) {
    yield put(actionFetchTrustAccountLists.failure({ error }));
  }
}

export default [takeLatest(actionFetchTrustAccountLists.request, sagaFetchTrustAccountLists)];
