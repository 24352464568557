import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dialogPaper: {
      marginTop: 40,
      minWidth: 460,
      display: 'flex',
      flexDirection: 'column',
      margin: '48px auto',
      position: 'relative',
      overflowY: 'auto'
    },
    root: {
      '& .MuiDialog-container:after': {
        height: 0
      }
    },
    headerFont: {
      color: colors.BLACK,
      letterSpacing: 0,
      lineHeight: '20px',
      marginBottom: 22
    },
    emptySendDate: {
      fontStyle: 'italic',
      fontColor: theme.palette.common.black,
      fontSize: 13,
      lineHight: 16
    },
    sectionIcon: {
      marginRight: 10
    },
    dateAndTime: {
      width: '40%',
      paddingRight: 10
    },
    dateTimeLayout: {
      marginBottom: 12
    }
  }),
  {
    name: 'ReportSchedulerDialog'
  }
);
