import React from 'react';

import classNames from 'classnames';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';

import { useTabsOverridesClasses } from './styles';

interface TabsProps extends MuiTabsProps {}

function Tabs({ className, children, ...props }: TabsProps) {
  const classes = useTabsOverridesClasses();

  return (
    <MuiTabs indicatorColor="primary" textColor="inherit" className={classNames(classes.root, className)} {...props}>
      {children}
    </MuiTabs>
  );
}
export default React.memo(Tabs);
