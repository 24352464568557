import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    subAvatar: {
      marginLeft: theme.spacing(-0.75)
      // marginTop: 'auto',
      // marginBottom: 'auto'
    }
  }),
  { name: 'MultiUserAvatar' }
);
