import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  root: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
});

export type ClassKeys = typeof styles;

export default styles;
