// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    metaData: {
      marginLeft: 27,
      fontSize: 14,
      lineHeight: '22px',
      '& > p': {
        margin: 0
      }
    },
    checkbox: {
      marginBottom: '0px',
      '& > span:last-child': {
        fontWeight: 'bold'
      }
    }
  }),
  {
    name: 'CurrentPriorityNotice'
  }
);
