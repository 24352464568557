import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    // only apply this when our page is loading, to get rid of scrollbar at this stage
    hideScrollbar: {
      overflow: 'hidden'
    }
  }),
  {
    name: 'DetailContainerWrapper'
  }
);
