import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      background: theme.palette.grey[100],
      border: '1px solid transparent',
      boxShadow: theme.shadows[3],
      padding: '24px 32px'
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      '& > *': {
        margin: 8
      }
    },
    title: {
      fontSize: 18,
      marginTop: 0,
      marginRight: 0,
      marginBottom: 8,
      marginLeft: 0
    }
  }),
  {
    name: 'LodgementVerificationResultContainer'
  }
);
