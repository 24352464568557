import { Action } from 'redux';
import Typography from '@mui/material/Typography';

import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase } from '@sympli/api-gateway/shared';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchWorkspaceById } from 'src/containers/workspace/shared/detail/actions';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { getPersistedNotificationKey, PersistedMessageNotificationWithLocalStorageProps } from 'src/hooks/useWorkspacePersistedNotifications';
import { WorkspaceUpdateMessage, WorkspaceUpdateMessageCategoryEnum } from 'src/socket/workspace-update/models';
import {
  DocumentRealtimeActivityLog,
  DocumentRealtimeActivityTypeEnum,
  LodgementCaseStatusEnum,
  LodgementRealtimeMessagePayload,
  NotificationVariantMapping,
  RealtimeNotificationModel
} from './models';

export function handleRealtimeNotificationUpdate(
  //
  dispatch: SafeDispatch<Action>,
  workspaceId: string,
  participantId: string,
  message: WorkspaceUpdateMessage
) {
  if (message.Category !== WorkspaceUpdateMessageCategoryEnum.Notification) return;

  try {
    const notification: RealtimeNotificationModel = JSON.parse(message.Message);

    // need to make sure the notification is send to the relevant participant
    if (!notification.recipientParticipantIds.includes(participantId)) return;

    if (notification.isPersisted) {
      const key = getPersistedNotificationKey(workspaceId, participantId);
      const existingNotifications: PersistedMessageNotificationWithLocalStorageProps[] = JSON.parse(localStorage.getItem(key) ?? '[]');
      const newNotification: PersistedMessageNotificationWithLocalStorageProps = {
        message: notification.message,
        secondaryMessage: notification.secondaryMessage,
        messageId: message.MessageId,
        variant: NotificationVariantMapping[notification.variant]
      };
      /**
       * We cannot rely on localStorage.setItem here, because it will not cause react component to re-render
       * Instead, we fire an event, to let the event handler inside our useLocalStorageState do the re-render and set localStorage value
       * https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
       */
      window.dispatchEvent(
        new StorageEvent('storage', {
          key,
          newValue: JSON.stringify([...existingNotifications, newNotification])
        })
      );
    } else {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: notification.message,
          secondaryMessage: notification.secondaryMessage,
          autoHideDuration: 5000,
          variant: NotificationVariantMapping[notification.variant]
        })
      );
    }
  } catch (ex) {
    const scope = Logger.scopeWithCustomAttributes({ message });
    Logger.captureException(new InvalidDataError('Invalid message format', message.Category), scope);
  }
}

export function handleDocumentRealtimeToastMessage(
  //
  dispatch: SafeDispatch<Action>,
  workspaceId: string,
  participantId: string,
  message: WorkspaceUpdateMessage
) {
  if (!message.Message) return;

  const toastMessages: DocumentRealtimeActivityLog = JSON.parse(message.Message);

  let isRefetchingWorkspaceDetailNeeded = false;

  if (toastMessages.activities) {
    toastMessages.activities.forEach(x => {
      if (
        //
        x.type === DocumentRealtimeActivityTypeEnum.Added &&
        !x.lodgementCaseIsPrimary &&
        x.doneByParticipantId &&
        x.doneByParticipantId !== participantId
      ) {
        isRefetchingWorkspaceDetailNeeded = true;
        dispatch(
          actionOpenGlobalSimpleNotification({ message: convertMessageToCustomizedJSX(x.documentName, x.lodgementCaseName), autoHideDuration: 5000, variant: 'new-success' })
        );
      }
    });
  }

  // needs to fetch workspace detail endpoint again if SLC document added, because we need new lodgementCaseId
  if (isRefetchingWorkspaceDetailNeeded) {
    dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
  }
}

function convertMessageToCustomizedJSX(documentName: string, lodgementCaseName: string): React.ReactNode {
  return (
    <Typography className="text-[14px] font-[400] leading-[20px] text-[var(--neutral-000)]">
      <b>Note</b> A '{documentName}' document was added in the workspace that requires a separate <b>{lodgementCaseName}</b>. This new Lodgement Case <b>will not affect</b> the
      automated settlement attempt of the <b>Primary Lodgement Case</b>.
    </Typography>
  );
}

export function handleLodgementRealtimeToastMessage(
  //
  dispatch: SafeDispatch<Action>,
  participantId: string,
  message: WorkspaceUpdateMessage,
  lodgementCases: LodgementCase[],
  documents: WorkspaceDocumentSummaryApiResponse[]
) {
  if (!message.Message) return;

  const messagePayload: LodgementRealtimeMessagePayload = JSON.parse(message.Message);

  if (messagePayload?.lodgementCaseId) {
    const documentName: string | undefined = documents.find(
      x => x.lodgementCaseId === messagePayload.lodgementCaseId && x.documentIdentifier.id !== DocumentTypeIdentifierEnum.LodgementInstructions
    )?.name;

    if (
      documentName &&
      messagePayload?.lodgementCaseStatus === LodgementCaseStatusEnum.Lodged &&
      // only send success message to SLC owner
      participantId === lodgementCases.find(x => x.id === messagePayload.lodgementCaseId)?.responsibleParticipantId
    ) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <Typography className="text-[14px] font-[400] leading-[20px] text-[var(--neutral-000)]">
              You have successfully <b>lodged</b> the <b>'{documentName}'</b> document.
            </Typography>
          ),
          autoHideDuration: 5000,
          variant: 'new-success'
        })
      );
    }
  }
}
