import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { getFiltersWithSelectedTab } from '../filters/helper';
import { FilterEnum } from '../filters/models';
import { SortingFieldEnum, StandaloneDashboardFilterModel, StandaloneDashboardRouteTabsEnumV2, StandaloneWorkspacesV2ApiRequest } from '../models';
import { AggregatorNameEnum, AggregatorQuery } from './models';

export const resolveActionArgs = (props: {
  //
  id: string;
  queries: AggregatorQuery[];
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
}): [/*filters: */ StandaloneWorkspacesV2ApiRequest, /*engagedTab: */ StandaloneDashboardRouteTabsEnumV2, /*currentTabFilters: */ StandaloneDashboardFilterModel] => {
  const { id, queries, assignmentType, localStorageKey } = props;

  const mappedTab = id === AggregatorNameEnum.toBeReworked ? StandaloneDashboardRouteTabsEnumV2.toBeReworked : StandaloneDashboardRouteTabsEnumV2.active;

  const [tabFilters, _] = getFiltersWithSelectedTab(localStorageKey, mappedTab, assignmentType);
  const selectedCardQuery = queries.find(d => d.name === id);

  tabFilters.workspaceStatus = selectedCardQuery?.workspaceStatus;
  tabFilters.myTasks = selectedCardQuery?.myTasks;
  tabFilters.selectedFilters = [];
  switch (id) {
    case AggregatorNameEnum.totalOpenWorkspaces:
    case AggregatorNameEnum.readyToLodge:
      tabFilters.selectedFilters.push(FilterEnum.WorkspaceStatus);
      break;
    case AggregatorNameEnum.readyToSign:
      tabFilters.selectedFilters.push(FilterEnum.WorkspaceStatus, FilterEnum.MyTasks);
  }

  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    tabFilters.selectedFilters.push(FilterEnum.Groups);
  }

  return [
    {
      assignmentType,
      myTasks: selectedCardQuery?.myTasks,
      workspaceStatus: selectedCardQuery?.workspaceStatus,
      pageNumber: 1,
      pageSize: 20,
      sortBy: selectedCardQuery?.settlementPeriod ? SortingFieldEnum.SettlementDate.toString() : undefined,
      sortOrder: selectedCardQuery?.settlementPeriod ? DashboardSortDirectionEnum.asc : undefined
    },
    mappedTab,
    tabFilters
  ];
};

export const updateLocalStorage = (
  //
  localStorageKey: string,
  updatedValues: StandaloneDashboardFilterModel
) => {
  const value = localStorage.getItem(localStorageKey);

  const newItems = value ? (JSON.parse(value) as StandaloneDashboardFilterModel[]) : [];

  const existingItemIndex = newItems.findIndex(d => d.selectedTab === updatedValues.selectedTab && d.assignmentType === updatedValues.assignmentType);
  if (existingItemIndex !== -1) {
    newItems[existingItemIndex] = { ...updatedValues };
  } else {
    newItems.push(updatedValues);
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newItems));
};

export const removeLocalStorageSavedModel = (
  //
  localStorageKey: string,
  selectedTab: StandaloneDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
) => {
  const value = localStorage.getItem(localStorageKey);

  if (!value) {
    return;
  }
  const existingItem = JSON.parse(value) as StandaloneDashboardFilterModel[];
  const filteredItem = existingItem.filter(d => d.selectedTab !== selectedTab || d.assignmentType !== assignmentType);

  localStorage.setItem(localStorageKey, JSON.stringify(filteredItem));
};

export const removeLocalStorageDateTime = (localStorageKey: string) => {
  const value = localStorage.getItem(localStorageKey);

  if (!value) {
    return;
  }

  const existingItem = JSON.parse(value) as StandaloneDashboardFilterModel[];

  existingItem.forEach((item, index, items) => {
    if (item.selectedTab === StandaloneDashboardRouteTabsEnumV2.recent && item.lastAccessPeriod) {
      items[index] = { ...item, lastAccessPeriod: undefined };
    }
  });
  localStorage.setItem(localStorageKey, JSON.stringify(existingItem));
};
