import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { NecdsCountryEnum } from '@sympli-mfe/enums-2-24/necds';
import msg from '@sympli/ui-framework/utils/messages';

import { yupForeignShareholders } from './components/foreign-shareholders/validationSchema';
import { ForeignTypeEnum } from './enums';
import { Form25_2_24_1Model } from './models';
import { VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL, VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION } from './visibilityCheck';

export default yup
  .object<Form25_2_24_1Model>({
    countryCode: validateWhenVisible2<NecdsCountryEnum>({
      validationWhenVisible: yup.mixed<NecdsCountryEnum>().required(msg.REQUIRED).oneOf(Object.values(NecdsCountryEnum), msg.REQUIRED),
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL
    }),
    foreignType: validateWhenVisible2<ForeignTypeEnum>({
      validationWhenVisible: yup.mixed<ForeignTypeEnum>().required(msg.REQUIRED).oneOf(Object.values(ForeignTypeEnum), msg.REQUIRED),
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION
    }),
    foreignShareholders: yupForeignShareholders,
    // no validation
    partyBook: yup.mixed(),
    addressBook: yup.mixed(),
    transferee: yup.mixed(),
    titleReferences: yup.mixed(),
    workspaceTitleReferences: yup.mixed()
  })
  .defined()
  .log();
