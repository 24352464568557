import React from 'react';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import DocumentViewer from 'src/containers/documents/components/document-viewer';
import { DocumentFormAndDetailModel, SaveDocumentFormRequest } from 'src/containers/documents/models';
import { DocumentMergedPdfState } from '../../reducers/documentMergedPdf';

interface Props {
  queryParams: SaveDocumentFormRequest;
  disableLodge?: boolean;
  disableNextButton: boolean;
  displayDocumentAsPdf: boolean;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId?: JurisdictionsEnum;
  documentMergedPdfState: DocumentMergedPdfState;
}

function ExternalDocument({
  //
  queryParams,
  displayDocumentAsPdf,
  documentDetail,
  jurisdictionId,
  documentMergedPdfState
}: Props) {
  return (
    <DocumentViewer
      displayDocumentAsPdf={displayDocumentAsPdf}
      signMultiple={false}
      queryParams={queryParams}
      jurisdictionId={jurisdictionId}
      documentDetail={documentDetail}
      documentMergedPdfState={documentMergedPdfState}
    />
  );
}

export default React.memo(ExternalDocument);
