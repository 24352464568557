import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  onLoad?(event: any): void;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class DocumentPreview extends React.PureComponent<Props> {
  private iframe: HTMLIFrameElement | null;

  componentWillUnmount() {
    if (this.iframe && this.props.onLoad) {
      this.iframe.removeEventListener('load', this.props.onLoad);
    }
  }

  render() {
    const { classes, src, onLoad } = this.props;

    return (
      <iframe //
        id="document-preview"
        title="Document preview"
        src={src}
        ref={el => (this.iframe = el)}
        className={classes.root}
        onLoad={onLoad}
      />
    );
  }
}

export default withStyles(styles)(DocumentPreview);
