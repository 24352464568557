import { createAction, createAsyncAction } from 'typesafe-actions';

import { GeneralSagasErrorModel } from 'src/store/globalErrorMiddleware';
import {
  DeleteReportApiRequest,
  FetchReportsDashboardApiResponse,
  FetchScheduledReportHistoryApiRequest,
  FetchScheduledReportHistoryApiResponse,
  SavedReportModel,
  UserReportsPermissionsApiRequest,
  UserReportsPermissionsApiResponse
} from './models';

export const actionFetchScheduledReportHistory = createAsyncAction(
  //
  'FETCH_SCHEDULED_REPORT_HISTORY_FEED',
  'FETCH_SCHEDULED_REPORT_HISTORY_FEED_SUCCESS',
  'FETCH_SCHEDULED_REPORT_HISTORY_FEED_ERROR'
)<
  //
  FetchScheduledReportHistoryApiRequest,
  { data: FetchScheduledReportHistoryApiResponse },
  GeneralSagasErrorModel
>();

export const actionDeleteReport = createAsyncAction(
  //
  'DELETE_REPORT',
  'DELETE_REPORT_SUCCESS',
  'DELETE_REPORT_ERROR'
)<
  //
  DeleteReportApiRequest<SavedReportModel>,
  undefined,
  { error: Error }
>();

export const actionFetchReportsDashboard = createAsyncAction(
  //
  'FETCH_REPORTS_DASHBOARD_FEED',
  'FETCH_REPORTS_DASHBOARD_FEED_SUCCESS',
  'FETCH_REPORTS_DASHBOARD_FEED_ERROR'
)<
  //
  undefined,
  { data: FetchReportsDashboardApiResponse },
  { error: Error }
>();

export const actionUpdateReportsInRedux = createAction(
  //
  'UPDATE_REPORTS_IN_REDUX'
)<{ data: number }>();

export const actionFetchProfileUserReportsPermissions = createAsyncAction(
  //
  'FETCH_PROFILE_USER_REPORTS_PERMISSIONS',
  'FETCH_PROFILE_USER_REPORTS_PERMISSIONS_SUCCESS',
  'FETCH_PROFILE_USER_REPORTS_PERMISSIONS_ERROR'
)<
  //
  UserReportsPermissionsApiRequest,
  { data: UserReportsPermissionsApiResponse },
  { error: Error }
>();
