import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

export default function getValidationSchema() {
  return yup.object({
    transactionId: yup
      .string()
      .required(msg.REQUIRED)
      .max(14, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(14))
      .matches(/^[0-9]{8}[0-9, A-Z][/][0-9]{1,4}$|^[0-9]{8}[0-9, A-Z]$/, 'Invalid transaction id ')
  });
}
