import React from 'react';

import { useDispatch } from 'react-redux';

import { LodgementCaseStatusEnum } from '@sympli/api-gateway/enums';

import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceLatestTransaction } from 'src/containers/workspace/shared/detail/reducers/workspaceLatestTransaction';
import { useWorkspaceTasks } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';
import { useSafeDispatch } from 'src/hooks';
import TabTasks from './TabTasks';

export default function TabTasksContainer({
  //
  workspaceId,
  participantId,
  backLink
}: {
  workspaceId: string;
  participantId: string;
  backLink: string;
}) {
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceTasksState = useWorkspaceTasks(workspaceId, participantId);
  const workspaceLatestTransactionState = useWorkspaceLatestTransaction(workspaceId);
  const lodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);

  // workspace loading is handled from top level
  const workspaceBasicInfo = workspaceBasicInfoState.detail;
  const workspaceDetail = workspaceDetailState.detail;

  return (
    <TabTasks //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // redux
      // basic info
      isRollover={workspaceBasicInfo?.isRollover}
      workspaceStatusId={workspaceBasicInfo?.workspaceStatusId}
      lodgementCaseStatusId={lodgementCaseStatusId}
      settlementDate={workspaceBasicInfo?.settlementDate}
      // full detail
      numberOfAllTasks={workspaceDetail?.numberOfTasks || 0}
      settlementStatusId={workspaceDetail?.settlementStatusDetail?.status}
      settlementFsfReference={workspaceDetail?.settlementStatusDetail?.fsfReference}
      settlementErrors={workspaceDetail?.settlementStatusDetail?.errors}
      lastSettlementReadyCheckReasonId={workspaceDetail?.lastSettlementReadyCheck?.reason?.id}
      // latest transaction
      settlementStep={workspaceLatestTransactionState.detail?.settlementStep}
      // tasks
      workspaceTasksState={workspaceTasksState}
      // other
      isLoadingWorkspace={workspaceBasicInfoState.isLoading || workspaceDetailState.isLoading} // track only first load
      backLink={backLink}
      onTaskAction={dispatch}
    />
  );
}
