import React from 'react';

import { DebouncedFunc } from 'lodash-es/debounce';
import ButtonBase from '@mui/material/ButtonBase';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { SearchCategoryEnum, SearchDashboardPageTabsEnum } from '../../models';
import { TABS } from './models';
import { useStyles } from './styles';

export interface SearchTabsProps {
  selectedTab: SearchDashboardPageTabsEnum;
  setSelectedTab: React.Dispatch<React.SetStateAction<SearchDashboardPageTabsEnum | null>>;
  cachedSearchTerm?: string;
  cachedArchivedSearchTerm?: string;
  searchTerm: string;
  fetchSearchBoxWithDebounce: DebouncedFunc<(selectedTab: SearchDashboardPageTabsEnum, searchTerm?: string, searchCategoryId?: SearchCategoryEnum, pageNumber?: number) => void>;
}

function SearchTabs({
  //
  selectedTab,
  setSelectedTab,
  cachedSearchTerm,
  cachedArchivedSearchTerm,
  searchTerm,
  fetchSearchBoxWithDebounce
}: SearchTabsProps) {
  const classes = useStyles();

  return (
    <FlexLayout justifyContent="center">
      <Tabs //
        value={selectedTab}
        indicatorColor="primary"
        classes={{ root: classes.root }}
        textColor="inherit"
      >
        {TABS.map(tab => (
          <Tab //
            key={tab.id}
            label={tab.label}
            classes={{ root: classes.tabRoot }}
            value={tab.id}
            component={ButtonBase}
            onClick={() => {
              if (tab.id !== selectedTab) {
                setSelectedTab(tab.id);
                // if user switch to active tab, and search term has changed, we fire a call
                if (tab.id === SearchDashboardPageTabsEnum.Workspaces && searchTerm !== cachedSearchTerm) {
                  fetchSearchBoxWithDebounce(tab.id, searchTerm, SearchCategoryEnum.All);
                }
                // if user switch to archive tab, and search term has changed, we also fire a call
                else if (tab.id === SearchDashboardPageTabsEnum.Archived && searchTerm !== cachedArchivedSearchTerm) {
                  fetchSearchBoxWithDebounce(tab.id, searchTerm, SearchCategoryEnum.All);
                }
              }
            }}
          />
        ))}
      </Tabs>
    </FlexLayout>
  );
}

export default React.memo(SearchTabs);
