import { JurisdictionsEnum, ParticipantArchivedStatusEnum, ParticipantStatusEnum, WorkspaceRoleEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';

import { AssignmentTypeEnum, DashboardDateRangeModel, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { WorkspaceDetailBoxDataModel } from 'src/containers/shared/workspace-detail-box/models';
import { CurrentPartiesModel, PaginatedItemsModel, SettlementDateTimeModel, TitleReferenceEntityModel } from 'src/models';
import { DashboardInvitationTypeFilterEnum, InvitationsTableRowModel } from './table/models';

export type InvitationsDashboardV2ApiRequest = DashboardTableFiltersModel & InvitationsFeedFilterQueryParamsModel;
export type InvitationsDashboardV2ApiResponse = PaginatedItemsModel<InvitationsTableRowModel>;

// FIXME: move to shared dashboard models
interface DashboardTableFiltersModel {
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: DashboardSortDirectionEnum;
}

// this is used as backend query params
interface InvitationsFeedFilterQueryParamsModel {
  assignmentType: AssignmentTypeEnum;
  invitationType?: DashboardInvitationTypeFilterEnum;
  status?: number[];
  userIds?: string[];
  groupIds?: string[];
  subscriberIds?: string[];
  jurisdictions?: number[];
  myRoles?: number[];
  settlementPeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  lastInvitationSentPeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string && this is combination of send/receive date
  invitationArchivedType?: number[];
  archivedDatePeriod?: DashboardDateRangeModel | null;
  invitationTransactionType?: number[];
}

export enum InvitationsDashboardRouteTabsEnumV2 {
  received = 'received',
  sent = 'sent',
  archived = 'archived'
}

// this model is used when saving data into local storage
export interface InvitationsDashboardUiFilterModel {
  selectedTab: InvitationsDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
  // invitationType?: DashboardInvitationTypeFilterEnum;
  invitationArchivedType?: number[];
  status?: number[];
  selectedFilters?: number[];
  defaultOpenFilter?: number; // we default open a filter only when it's added by + button

  // this calls diff name on ui within diff tab received date (receive tab)/ sent date (sent tab)
  lastInvitationSentPeriod?: DashboardDateRangeModel;

  archivedDatePeriod?: DashboardDateRangeModel;

  jurisdictions?: number[];
  subscriberIds?: string[]; // filter itself on diff tab can have diff name
  myRoles?: number[];
  settlementPeriod?: DashboardDateRangeModel;
  groupIds?: string[];
  userIds?: string[];
  invitationTransactionType?: number[];
}

export interface InvitationsSubscriberSearchApiRequest {
  assignmentType: AssignmentTypeEnum;
  invitationType?: DashboardInvitationTypeFilterEnum;
}

export type InvitationsSubscriberSearchApiResponse = InvitationsSubscriberModel[];

export interface InvitationsSubscriberModel {
  id: string;
  displayName: string;
}

export interface WithdrawParticipantInvitationRequestModel {
  workspaceId: string;
  participantId: string;
  participantName?: string;
  //TODO review why do we still need InvitesDashboardApiRequest
  query?: InvitesDashboardApiRequest | InvitationsDashboardV2ApiRequest;
  isNewDashboard?: boolean;
  isLinkedWorkspace?: boolean;
}

export interface SentInviteDetailModel extends SentInvitesDashboardItemModel {
  // bellow properties are calculated in saga whenever task is selected
  workspaceDetail: WorkspaceDetailBoxDataModel;
  acceptedParticipants: WorkspaceParticipantApiResponse[];
  invitations: WorkspaceParticipantApiResponse[];
}

interface SentInvitesDashboardItemModel extends InvitationDashboardItemModel {
  matter: string;
  sentTo?: string;
  workspaceStatus: WorkspaceStatusEnum;
}

interface InvitationDashboardItemModel {
  id: string;
  workspaceId: string;
  groupId?: string;
  sympliId: string;
  settlementDateTime?: SettlementDateTimeModel;
  participants: WorkspaceParticipantApiResponse[];
  currentParties: CurrentPartiesModel;
  titleReferences: Array<TitleReferenceEntityModel>;
  canResend: boolean;
  lastInvitationSentDate: string;
  propertyAddress?: string;
  invitationDescription?: string;
  jurisdictionId: JurisdictionsEnum;
  isWorkspaceLocked: boolean;
  workspaceType: WorkspaceTypeEnum;
  invitationTransactionType?: string;
}

/**
 * Tickle.Application.Domain.Dashboard.Model.InvitationResponseType
 */
export enum InvitationResponseTypeEnum {
  Accept = '1',
  Decline = '2',
  Forward = '3',
  Withdraw = '4'
}

//TODO should clean up below
export type InvitesDashboardApiRequest = TableFiltersModel & FilterInvitesQueryParamsModel<InvitationDashboardPageTabsEnum>;
interface TableFiltersModel {
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: SortDirectionEnum;
}
interface FilterInvitesQueryParamsModel<T = any> {
  workspaceRoleIds?: WorkspaceRoleEnum | Array<WorkspaceRoleEnum>;
  jurisdictionId?: JurisdictionsEnum;
  archivedInvitationActionType?: ParticipantArchivedStatusEnum;
  participantStatus?: ParticipantStatusEnum;
  type?: T;
  searchTerm?: string;
  groupId?: string;
}

enum InvitationDashboardPageTabsEnum {
  Received = 'received',
  Sent = 'sent',
  Archived = 'archived'
}

export enum ForwardInvitationEnum {
  Internally = 0,
  Externally = 1
}

//TODO should clean up above
