import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    navLink: {
      fontWeight: 'bold',
      marginLeft: 5
    }
  }),
  {
    name: 'RisTacNotification'
  }
);
