import React from 'react';

import { useAsyncDialog } from 'src/hooks';
import { defaultValues } from '../components/report-details/components/favourite-report-dialog/FavouriteReportDialog';
import { FavouriteFormikModel } from '../models';

export function useFavouriteDialog() {
  const [initialValues, setInitialValues] = React.useState<FavouriteFormikModel>(defaultValues);

  const { isOpen: isFavouriteDialogOpen, asyncDialogDispatch: asyncDialogFavouriteDispatch } = useAsyncDialog();

  const favouriteDialogCloseHandler = (value?: boolean) => {
    // now close the dialog regardless of value
    asyncDialogFavouriteDispatch({ type: 'close' });
  };

  const favouriteDialogClickHandler = (initialValues?: FavouriteFormikModel) => {
    asyncDialogFavouriteDispatch({ type: 'open' });
    if (initialValues) setInitialValues(initialValues);
  };

  return {
    initialValues,
    isFavouriteDialogOpen,
    favouriteDialogCloseHandler,
    favouriteDialogClickHandler,
    asyncDialogFavouriteDispatch
  };
}
