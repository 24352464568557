import { ReportTypeEnum, UserRoleEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { TeamWorkloadReportAssignedUser } from 'src/containers/dashboard/reports/models';
import { ProfileContextModel } from 'src/containers/shared/auth/profile-security/Profile.context';
import { ProfileImageModel } from 'src/containers/shared/profile-image/models';
import { enum2IdNameObjArray } from 'src/utils/enums';

const ReportTypeEnumDisplayNameMap: Record<ReportTypeEnum, string> = {
  [ReportTypeEnum.UserPermissionReport]: 'User permissions report',
  [ReportTypeEnum.UserActivityReport]: 'User activity report',
  [ReportTypeEnum.TeamWorkloadReport]: 'Team workload report'
};

export const ReportTypeLookup: LookupEnumModel<number>[] = enum2IdNameObjArray<number>(ReportTypeEnum, ReportTypeEnumDisplayNameMap).sort((a, b) => a.name.localeCompare(b.name));

export interface UpdateUserReportsPermissionsEntityModel {
  id: ReportTypeEnum;
  hasAccess: boolean;
}

export interface UpdateUserReportsPermissions {
  reportPermissions: Array<UpdateUserReportsPermissionsEntityModel>;
}

export interface UserReportsPermissionsDetailFormModel {
  reportPermissions: Array<ReportTypeEnum>;
  selected?: boolean;
  teamWorkloadReportAssignedUsers: Array<TeamWorkloadReportAssignedUser>;
  maxTeamWorkloadReportAccessNumber: number;
}

export interface UserDetailsApiResponse {
  id: string;
  jobTitle?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  work: string;
  role: UserRoleEnum;
  isSuspended: boolean;
  isActivated: boolean;
  isDeleted: boolean;
  timezone?: string;
  avatarUrl?: string;
  isSubscriberSSOEnabled: boolean;
}

export interface UserDetailsFormModel extends UserDetailsApiResponse {
  profileImage?: ProfileImageModel;
  profile: ProfileContextModel;
}

export const DEFAULT_USER_DETAILS: Partial<UserDetailsApiResponse> = {
  firstName: '',
  middleName: '',
  jobTitle: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  work: '',
  timezone: '',
  avatarUrl: ''
};
