import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchGroupDistributionsWorkflow } from '../actions/groups';
import { GroupDocumentsWorkflowApiResponse } from '../group-settings/models';

export interface GroupDistributionsWorkflowState {
  detail?: GroupDocumentsWorkflowApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: GroupDistributionsWorkflowState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  GroupDistributionsWorkflowState,
  Action
>(initialState)
  .handleAction(actionFetchGroupDistributionsWorkflow.request, (state): GroupDistributionsWorkflowState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchGroupDistributionsWorkflow.success, (state, action): GroupDistributionsWorkflowState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchGroupDistributionsWorkflow.failure, (state, action): GroupDistributionsWorkflowState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
