import { SubscriberBankAccountTypeEnum } from '@sympli/api-gateway/enums';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { BANK_ACCOUNT_TYPE_MAPPINGS } from '../../subscriber-profile/financial-accounts/models';
import { SubscriberActivityLogTypeEnum as LogTypeEnum, SubscriberActivityLogLinkEnum } from './models';

interface GroupMetaData {
  userName?: string;
  groupName: string;
  id: string;
}

interface UserMetaData {
  userName: string;
  id: string;
}

interface LoanAccountMetaData {
  userName?: string;
  loanAccountName: string;
  id: string;
  userId: string;
}

interface FinancialAccountMetaData {
  subscriberId: string;
  accountId: string;
  accountName: string;
  bankAccountType: SubscriberBankAccountTypeEnum;
}

interface DefaultFieldPopulationMetaData {
  jurisdiction: string;
  jurisdictionName: string;
}

interface AutoJustificationMetaData {
  justificationId: string;
  previousKnownAs: string;
}

// TODO this is wrong
// we need to create a helper for link resolutions and use that everywhere
export function resolveLogLink(linkType: SubscriberActivityLogLinkEnum, metaData: unknown): string {
  switch (Number(linkType)) {
    case SubscriberActivityLogLinkEnum.UserDetailLink:
      const userData = metaData as UserMetaData;
      return `/settings/users/${encodeURIComponent(userData.id)}/detail`;
    case SubscriberActivityLogLinkEnum.GroupDetailLink:
      const groupData = metaData as GroupMetaData;
      return `/settings/groups/${encodeURIComponent(groupData.id)}/detail`;
    case SubscriberActivityLogLinkEnum.DefaultFieldPopulationJurisdictionLink:
      const defaultFieldJurisdictionMetaData = metaData as DefaultFieldPopulationMetaData;
      return `/settings/default-field-population/${encodeURIComponent(defaultFieldJurisdictionMetaData.jurisdiction)}`;
    case SubscriberActivityLogLinkEnum.LoanAccountSignerDetailLink:
      const loanAccountSignersMetaData = metaData as LoanAccountMetaData;
      return `/settings/bank-accounts/${encodeURIComponent(loanAccountSignersMetaData.id)}/signers`;
    case SubscriberActivityLogLinkEnum.LoanAccountSignerUserLink:
      const loanAccountSignersUserMetaData = metaData as LoanAccountMetaData;
      return `/settings/users/${encodeURIComponent(loanAccountSignersUserMetaData.userId)}/loan-accounts`;
    case SubscriberActivityLogLinkEnum.AutoJustificationDetailLink:
      const autoJustificationMetaData = metaData as AutoJustificationMetaData;
      return `/settings/auto-justification/${encodeURIComponent(autoJustificationMetaData.justificationId)}/detail`;
    case SubscriberActivityLogLinkEnum.FinancialAccountsLink:
      const financialAccountMetaData = metaData as FinancialAccountMetaData;
      const mapping = BANK_ACCOUNT_TYPE_MAPPINGS.get(financialAccountMetaData.bankAccountType);
      return `/settings/financial-accounts/${mapping?.apiAccountType}/${encodeURIComponent(financialAccountMetaData.accountId)}/details`;
    default:
      Logger.captureException(new InvalidDataError('Link type not supported!', linkType));
      return '#';
  }
}

const defaultActivityLogTemplateDictionary: Record<LogTypeEnum, (metaData: unknown) => string> = {
  [LogTypeEnum.CreateNewGroup]: () => `[:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) group was added`,
  [LogTypeEnum.DeleteGroup]: () => `[:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) group was deleted`,
  [LogTypeEnum.UpdateGroupSettings]: () => `Settings for the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) was saved`,
  [LogTypeEnum.UpdateGroupDistributionWorkflow]: () =>
    `Financial Settlement Schedule workflow for the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) was saved`,
  [LogTypeEnum.UpdateGroupDocumentWorkflow]: () => `Document workflow for the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) was saved`,
  [LogTypeEnum.UpdateGroupAddUser]: () => `':{userName}' was added to the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink})`,
  [LogTypeEnum.CreateNewUser]: () => `:{role} user [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was added`,
  [LogTypeEnum.UpdateUserSuspension]: () => `:{role} [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was suspended`,
  [LogTypeEnum.UpdateUserReActivation]: () => `:{role} [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was reactivated`,
  [LogTypeEnum.UpdateUserDetails]: () => `User details for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was saved`,
  [LogTypeEnum.UpdateUserPermissions]: () => `User permissions for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was saved`,
  [LogTypeEnum.UpdateUserRemoveLinkedDevice]: () => `User linked devices for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was removed`,
  [LogTypeEnum.UpdateSubscriberAddProfileImage]: () => '[Organisation profile image](/settings/organisation) was added',
  [LogTypeEnum.UpdateSubscriberRemoveProfileImage]: () => '[Organisation profile image](/settings/organisation) was deleted',
  [LogTypeEnum.UpdateSubscriberPreferences]: () => '[Subscriber preferences](/settings/preferences) was saved',
  [LogTypeEnum.UpdateSubscriberDefaultFieldPopulation]: () =>
    `Document default field population for [:{jurisdictionName}](${SubscriberActivityLogLinkEnum.DefaultFieldPopulationJurisdictionLink}) was saved`,
  [LogTypeEnum.UpdateUserName]: () => "User name ':{previousName}' was updated to ':{currentName}'",
  [LogTypeEnum.UpdateGroupLodgementSettings]: () => 'Number of days required until lodgement has been changed from :{previousDaysUntilLodgement} to :{daysUntilLodgement} days',
  [LogTypeEnum.TrustAccountRequestCreated]: () => 'Trust account request was initiated',
  [LogTypeEnum.TrustAccountRequestSubmitted]: () => "Trust account request ':{requestId}' was submitted",
  [LogTypeEnum.TrustAccountRequestStatusUpdated]: () => "Trust account request ':{requestId}' was ':{status}'",
  [LogTypeEnum.RegisteredTrustAccountChangeRequested]: () => "Trust account request ':{requestId}' was initiated for registered trust account ':{trustAccountDescription}'",
  [LogTypeEnum.RegisteredTrustAccountDetailsUpdated]: () => "Information were updated in the registered trust account ':{trustAccountDescription}'",
  [LogTypeEnum.UpdateUserReportPermissions]: () => `Report permissions for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was saved`,
  [LogTypeEnum.AutoJustificationAdded]: () => `Auto justification for [:{previousKnownAs}](${SubscriberActivityLogLinkEnum.AutoJustificationDetailLink}) was added`,
  [LogTypeEnum.AutoJustificationModified]: () => `Auto justification for [:{previousKnownAs}](${SubscriberActivityLogLinkEnum.AutoJustificationDetailLink}) was edited`,
  [LogTypeEnum.AutoJustificationRemoved]: () => 'Auto justification for :{previousKnownAs} was deleted',
  [LogTypeEnum.UserDeleted]: () => 'User :{userName} was deleted',
  [LogTypeEnum.UserEmailUpdated]: () => `Email address of [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) is updated`,
  [LogTypeEnum.FinancialAccountDeleted]: () => 'General bank account named :{accountName} was deleted',
  [LogTypeEnum.FinancialAccountUpdated]: metaData => {
    const financialAccountMetaData = metaData as FinancialAccountMetaData;
    const msg = `account named [:{accountName}](${SubscriberActivityLogLinkEnum.FinancialAccountsLink}) was edited`;
    return financialAccountMetaData.bankAccountType === SubscriberBankAccountTypeEnum.General ? `General bank ${msg}` : `Loan ${msg}`;
  },
  [LogTypeEnum.FinancialAccountAdded]: () => `A new general bank account named [:{accountName}](${SubscriberActivityLogLinkEnum.FinancialAccountsLink}) was added`,
  [LogTypeEnum.UpdateSubscriberPreferencesWorkspaces]: () => '[Subscriber preferences workspaces](/settings/preferences/workspaces) was saved',
  [LogTypeEnum.UpdateSubscriberPreferencesNotifications]: () => '[Subscriber preferences notifications](/settings/preferences/notifications) was saved',
  [LogTypeEnum.UpdateSubscriberSigningRules]: () => '[Subscriber preferences signing rules](/settings/preferences/signing-rules) was saved'
};

const matchRegEx = new RegExp(/:\{\w*\}/g); // regex to match template substitution :{value}
export function getTemplate(category: LogTypeEnum, data: object) {
  const getTemplate = defaultActivityLogTemplateDictionary[category];
  const template = getTemplate ? getTemplate(data) : '';

  return template.slice().replace(matchRegEx, (substring, _, __) => {
    const key = substring.slice(2, substring.length - 1);
    return data[key];
  });
}
