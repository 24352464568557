import * as React from 'react';

import Button from '@mui/material/Button';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import MoreActionButton from '@sympli/ui-framework/components/more-action-button';

import ErrorBoundary from 'src/components/error-boundary';
import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import DetailContainerErrorFallback from 'src/containers/settings/components/detail-container-error-fallback';
import Header from 'src/containers/settings/components/header';
import SettingContentTopPanel from 'src/containers/settings/components/setting-content-top-panel';
import { TopPanelPortalTarget } from 'src/containers/settings/components/top-panel-portal';
import { ApiStatus } from 'src/utils/http';
import SettingsPageContentWrapper from '../../SettingsPageContentWrapper';
import { useStyles } from './styles';

interface DetailContainerWrapperProps {
  header: string | JSX.Element;
  status: ApiStatus;
  error?: string;
  moreActionButton?: React.ReactNode;
  hideTopPanel?: boolean;
  classes?: {
    header: string;
  };
}

function ContentResolver({
  status,
  error,
  children
}: React.PropsWithChildren<{
  //
  status: DetailContainerWrapperProps['status'];
  error?: DetailContainerWrapperProps['error'];
}>) {
  switch (status) {
    case 'idle':
    case 'pending':
      return <BlockLoader />;
    case 'rejected':
      // explicitly do not throw an error and rather render generic error page
      return <GeneralCrashedContent />;
    default:
      return typeof children === 'function' ? children() : children;
  }
}

function DetailContainerWrapper({
  //

  status,
  header,
  error,
  children,
  moreActionButton,
  hideTopPanel,
  classes: parentClasses
}: React.PropsWithChildren<DetailContainerWrapperProps>): JSX.Element {
  const classes = useStyles();

  const jsxHeader = typeof header === 'string' ? <Header title={header} className={parentClasses?.header} /> : header;
  return (
    <FlexLayout flexDirection="column" className={classes.root}>
      {!hideTopPanel &&
        (status === 'resolved' ? ( // in resolved status we will provide only portal and rely on form to inject button using TopPanelPortalSource
          <SettingContentTopPanel>
            <TopPanelPortalTarget />
            {moreActionButton}
          </SettingContentTopPanel>
        ) : (
          // otherwise we just fake the panel with button that does not do anything
          <SettingContentTopPanel>
            <Button variant="contained" color="primary" disabled>
              Save changes
            </Button>
            {moreActionButton && <MoreActionButton disabled />}
          </SettingContentTopPanel>
        ))}
      <SettingsPageContentWrapper>
        {jsxHeader}
        <ErrorBoundary //
          component={DetailContainerErrorFallback}
        >
          <ContentResolver status={status} error={error}>
            {children}
          </ContentResolver>
        </ErrorBoundary>
      </SettingsPageContentWrapper>
    </FlexLayout>
  );
}

export default React.memo(DetailContainerWrapper);
