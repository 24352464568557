import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingRight: 10
    },
    button: {
      width: 24,
      height: 24,
      marginTop: -2,
      marginBottom: -2,
      '& svg': {
        fill: theme.palette.primary.main
      }
    },
    buttonIcon: {
      width: 14,
      height: 16
    },
    tooltip: {
      width: 'auto',
      fontSize: 13,
      marginTop: 20
    }
  }),
  {
    name: 'ReportUpdateButtons'
  }
);
