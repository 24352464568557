import React from 'react';

import * as Redux from 'redux';

export type SafeDispatch<A extends Redux.Action = Redux.AnyAction> = Redux.Dispatch<A> | React.Dispatch<A>;

/**
 * dispatch wrapper that makes sure that no action is dispatched after component has been unmounted
 * useful with async flow when component can get unmounted before the promise is resolved
 */
export default function useSafeDispatch<A extends Redux.Action>(dispatch: SafeDispatch<A>): SafeDispatch<A> {
  //! explicitely initialize it with true,
  //! otherwise when useSafeDispatch is created in the parent passed down to a child component
  //! that executes dispatch in its componentDidMount logic it will not work
  //! because parent at that point was not mounted yet.
  const mountedRef = React.useRef(true);
  React.useLayoutEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return React.useCallback<SafeDispatch<A>>(
    (action: any) => {
      if (mountedRef.current) {
        dispatch(action);
      }
    },
    [dispatch, mountedRef]
  );
}
