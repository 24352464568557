// this file was automatically generated from components/styles.ts.mustache

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {}
  });

export type ClassKeys = typeof styles;

export default styles;
