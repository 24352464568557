import * as React from 'react';

import { useField, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import { ClassNameMap } from '@mui/styles/withStyles';

import { JurisdictionsEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import FormGroup, { FormGroupClassKeys } from '@sympli/ui-framework/components/form/layout/form-group';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { RepresentationSubscriberTypeEnum } from 'src/containers/dashboard/components/create-new-workspace/models';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { FinancialRoleMapping } from 'src/models/roles';
import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import { DEFAULT_INVITATION_ITEM_MODEL } from '../../../../initialValues';
import { CreateFinancialWorkspaceInviteParticipantsStepFormModel, InvitationModel } from '../../../../models';
import { InvitationItemModel } from '../../models';
import Invitation from '../invitation';
import { useStyles } from './styles';

interface OwnProps {
  isInteroperable?: boolean;
  jurisdictionId: JurisdictionsEnum;
  roleId: WorkspaceRoleEnum;
  groups: GroupOptionModel[];
  representationSubscriberType?: RepresentationSubscriberTypeEnum;
}

const fieldName = modelKey<CreateFinancialWorkspaceInviteParticipantsStepFormModel>();

function Invitations({ jurisdictionId, roleId, groups, isInteroperable, representationSubscriberType }: OwnProps) {
  const { values: formikValues, isSubmitting, setFieldValue } = useFormikContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>();
  const invitedRolesFieldName = fieldName('invitedRoles');
  // get invitations here and then find the index of roleId
  const { invitations, invitedRoles } = formikValues;
  const invitationIndex = invitations.findIndex(x => x.role === roleId);
  const invitationModel = invitations.find(x => x.role === roleId);
  const classes = useStyles();
  const formGroupClasses: Partial<ClassNameMap<keyof ReturnType<FormGroupClassKeys>>> = {
    container: classes.formGroupContainer,
    title: classes.formGroupTitle,
    columnTitleContainer: classes.formGroupColumnTitleContainer,
    contentContainer: classes.fullWidth
  };

  const itemBinding = `${fieldName('invitations')}[${invitationIndex}]`;

  // Controlling parties in VIC are not labelled as CoRD Holder Consent
  const label = jurisdictionId === JurisdictionsEnum.VIC && roleId === WorkspaceRoleEnum.ControllingParty ? 'Controlling Party' : FinancialRoleMapping[roleId]!;
  const [, { error: roleValidationError }] = useField<InvitationModel>(`${itemBinding}.role`);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (!checked) {
      const itemBindingName = `${itemBinding}.invitation`;
      const fieldName = createModelKeyAppender<InvitationItemModel>(itemBindingName);
      const subscriberItemPath = fieldName('subscriber');
      const inviteRoleAsSelfItemPath = fieldName('inviteRoleAsSelf');

      setFieldValue(inviteRoleAsSelfItemPath, false);
      setFieldValue(subscriberItemPath, DEFAULT_INVITATION_ITEM_MODEL.subscriber);
    }
  };

  const renderCheckBox = (invitedRolesFieldName: string, label: string) => {
    return (
      <Field //
        label={label}
        name={`${invitedRolesFieldName}[${roleId}]`}
        component={CheckboxField}
        className={classes.checkbox}
        disabled={isSubmitting}
        onChange={handleOnChange}
      />
    );
  };

  return (
    <FormGroup //
      title={renderCheckBox(invitedRolesFieldName, label)}
      classes={formGroupClasses}
      direction="column"
      fullWidth
    >
      {invitedRoles[roleId] && invitationModel?.invitation && !roleValidationError && (
        <Invitation //
          isInteroperable={isInteroperable}
          jurisdictionId={jurisdictionId}
          groups={groups}
          name={itemBinding}
          representationSubscriberType={representationSubscriberType}
        />
      )}
      {roleValidationError && (
        <Typography variant="caption" className={classes.roleValidationError}>
          {roleValidationError}
        </Typography>
      )}
    </FormGroup>
  );
}

export default React.memo(Invitations);
