import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    // Confirmation dialog
    root: {
      alignItems: 'flex-start'
    },
    dialogPaper: {
      marginTop: 40,
      width: 880,
      maxWidth: 880
    },
    dialogTitle: {
      padding: '36px 40px 12px'
    },
    dialogContent: {
      paddingLeft: 40,
      paddingRight: 40
    },
    description: {
      fontSize: 13,
      paddingLeft: 20
    },
    // Content
    divider: {
      marginBottom: 22
    },
    greyText: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
