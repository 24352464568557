import queryString from 'query-string';
import { all, call, put, takeLatest } from 'typed-redux-saga';

import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';

import { convertDateOrNull } from 'src/components/date-range-picker/helpers';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import environments from 'src/environments';
import http from 'src/utils/http';
import { actionFetchStandaloneWorkspacesFeedV2 } from './actions';
import { StandaloneWorkspacesV2ApiRequest, StandaloneWorkspacesV2ApiResponse } from './models';

// note: dashboard is using v2 endpoint
const { TARGET_MACHINE, TARGET_API_V2 } = environments;

const fetchStandaloneWorkspacesV2Feed = (q: StandaloneWorkspacesV2ApiRequest) => {
  const uri = '/dashboards/standalone';
  const { lastAccessPeriod, completedDatePeriod, archivedDatePeriod, assignmentType: existingAssignmentType, documentTypes, ...rest } = q;

  // reassign is equal to assign to group
  const assignmentType = existingAssignmentType === AssignmentTypeEnum.ReAssigned ? AssignmentTypeEnum.AssignedToGroup : existingAssignmentType;
  const query = queryString.stringify({
    ...rest,
    documentTypes: documentTypes?.some(e => e === DocumentTypeIdentifierEnum.TransmissionApplication)
      ? [...documentTypes, DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee]
      : documentTypes,
    ...convertDateOrNull(lastAccessPeriod, 'lastAccessPeriod'),
    ...convertDateOrNull(completedDatePeriod, 'completedDatePeriod'),
    ...convertDateOrNull(archivedDatePeriod, 'archivedDatePeriod'),
    assignmentType
  });

  return http.get<StandaloneWorkspacesV2ApiResponse>(uri + '?' + query, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};

export function* sagaFetchStandaloneWorkspacesFeedV2(action: ReturnType<typeof actionFetchStandaloneWorkspacesFeedV2.request>) {
  try {
    const data = yield* call(fetchStandaloneWorkspacesV2Feed, action.payload);

    yield put(actionFetchStandaloneWorkspacesFeedV2.success({ data }));
  } catch (error) {
    yield all([
      //
      yield put(actionFetchStandaloneWorkspacesFeedV2.failure({ error }))
    ]);
  }
}

export default [
  //
  takeLatest(actionFetchStandaloneWorkspacesFeedV2.request, sagaFetchStandaloneWorkspacesFeedV2)
];
