import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      boxSizing: 'border-box'
    },
    tableCellBody: {
      background: 'none',
      borderTopWidth: 2,
      borderBottom: 0
    },
    tableRowRoot: {
      height: 40
    },
    firstColumn: {
      paddingLeft: 0,
      fontWeight: 'bold',
      width: '40%'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
