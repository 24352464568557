import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import {
  createModelKeyAppender,
  getPartyOptionsExcludingSiblings,
  modelKey,
  resolveLabel,
  resolveSelectPlaceholder,
  resolveVisibleEnumOptions
} from '@sympli-mfe/document-forms-framework/utils';
import { InputClassKeys } from '@sympli/ui-framework/components/form/base-components/input';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import {
  VISIBILITY_CHECK_CLAIM_CATEGORY,
  VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID,
  VISIBILITY_CHECK_CLAIM_PARTIES,
  VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER
} from '../../checks';
import { NSW_CAVEAT_PARTY_FORM_CONFIG } from '../../config';
import { ENUM_CLAIM_CATEGORY_OPTIONS, ENUM_CLAIM_PARTY_CAPACITY_OPTIONS, ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS, EstateOfInterestClaimedEnum } from '../../enums';
import { CaveatModel_2_21_1, Claim, ClaimParty, PARTY_BOOK_KEY } from '../../models';
import { REQUIRED_CHECK_CLAIM_DATE, REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM, VISIBILITY_CHECK_CLAIM_DATE, VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM } from './checks';
import { resolveDefaultItem } from './models';
import styles, { ClassKeys } from './styles';

const debug = !import.meta.env.PROD;

interface OwnProps {
  name: string;
  formikProps: FormikProps<CaveatModel_2_21_1>;
  disabled: boolean;
  portalIdDialogs: string;
  queryParams?: DocumentsPageRouteAndQueryModel;
  partyOptions: LookupItemModel[];
}
type Props = OwnProps & WithStyles<ClassKeys>;

const rootFieldName = modelKey<CaveatModel_2_21_1>();

class SectionClaimDetails extends React.PureComponent<Props> {
  private readonly fieldName = createModelKeyAppender<Claim>(this.props.name);
  private readonly detailsSupportingClaimsClasses: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = {
    formControl: this.props.classes.multiline
  };

  render() {
    return (
      <>
        {this.renderEstateOrInterestClaimed()}
        {this.renderDutyAssessment()}
        {this.renderClaimParties()}
      </>
    );
  }

  private renderEstateOrInterestClaimed() {
    const fieldName = this.fieldName;
    const {
      formikProps: { values },
      disabled,
      classes
    } = this.props;
    const claim = values.claimDetails!;

    return (
      <FormGroup title="Estate or interest claimed" fieldAreaDirection="column">
        {({ titleId }) => (
          <>
            <Field
              aria-labelledby={titleId}
              disabled={disabled}
              className={classes.fixedWidthDropdown}
              name={fieldName('estateOrInterestClaimed')}
              format="number"
              options={ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS}
              placeholder={resolveSelectPlaceholder(true)}
              component={SelectField}
              onChange={this.handleEstateOrInterestClaimedChange}
              debug={debug}
            />
            {VISIBILITY_CHECK_CLAIM_CATEGORY(claim) ? (
              <Field
                disabled={disabled}
                className={classes.fixedWidthDropdown}
                label={resolveLabel('Claim category', true)}
                name={fieldName('claimCategory')}
                format="number"
                options={resolveVisibleEnumOptions<number>(ENUM_CLAIM_CATEGORY_OPTIONS, values, fieldName('claimCategory'))}
                placeholder={resolveSelectPlaceholder(true)}
                component={SelectField}
                debug={debug}
              />
            ) : null}
            {this.renderClaimDateAndDetailsIfPresent()}
            {VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID(claim) ? (
              <Field disabled={disabled} className={classes.halfWidth} label={resolveLabel('LRS Dealing number', false)} name={fieldName('lrDocumentID')} component={InputField} />
            ) : null}
          </>
        )}
      </FormGroup>
    );
  }

  private renderClaimDateAndDetailsIfPresent(): React.ReactNode[] {
    const fieldName = this.fieldName;
    const {
      formikProps: { values },
      disabled,
      classes
    } = this.props;
    const claim = values.claimDetails!;

    const claimDateIsVisible = VISIBILITY_CHECK_CLAIM_DATE(claim);
    const claimDetailsIsVisible = VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM(claim);
    const claimDateIsRequired = claimDateIsVisible && REQUIRED_CHECK_CLAIM_DATE(claim);
    const claimDetailsIsRequired = claimDetailsIsVisible && REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM(claim);

    const claimDateIfPresent = claimDateIsVisible && (
      <Field
        key="date" // Adding key as this will be rendered in a React.ReactNode[]
        disabled={disabled}
        className={classes.quarterWidth}
        label={resolveLabel('Date of interest claimed', claimDateIsRequired)}
        name={fieldName('claimDate')}
        component={DatePickerField}
        disableFuture
      />
    );

    const claimDetailsIfPresent = claimDetailsIsVisible && (
      <Field
        key="details" // Adding key as this will be rendered in a React.ReactNode[]
        disabled={disabled}
        fullWidth
        multiline
        label={resolveLabel('Details supporting the claim', claimDetailsIsRequired)}
        name={fieldName('detailsSupportingTheClaim')}
        classes={this.detailsSupportingClaimsClasses}
        component={InputField}
      />
    );

    return claimDetailsIsRequired && !claimDateIsRequired ? [claimDetailsIfPresent, claimDateIfPresent] : [claimDateIfPresent, claimDetailsIfPresent];
  }

  private renderDutyAssessment() {
    const {
      formikProps: { values },
      disabled,
      classes
    } = this.props;

    if (!(VISIBILITY_CHECK_CLAIM_DATE(values.claimDetails!) && VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER(values))) {
      return null;
    }

    return (
      <FormGroup
        title={resolveLabel('Duty assessment number', true)}
        formTip={
          <span>
            This is required where the estate or interest claimed is pursuant to an unregistered mortgage, loan agreement, or deed of charge dated on or after 1/1/1987 & before
            1/7/2016, except where:
            <ul className={classes.dutyAssessmentNumberTooltipList}>
              <li className={classes.dutyAssessmentNumberTooltipListItem}>the claim involves bail that includes a reference to an unregistered mortgage or charge.</li>
              <li className={classes.dutyAssessmentNumberTooltipListItem}>the caveat is by State Debt Recovery pursuant to a charge.</li>
              <li className={classes.dutyAssessmentNumberTooltipListItem}>the claim is a charge pursuant to s355 Water Management Act 2000.</li>
            </ul>
            If this caveat has these exceptions, it needs to be lodged in paper with the Land Registry.
          </span>
        }
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={rootFieldName('dutyAssessmentNumber')}
            disabled={disabled}
            component={InputField}
          />
        )}
      </FormGroup>
    );
  }

  private handleEstateOrInterestClaimedChange = (event: React.ChangeEvent<HTMLInputElement>, newEstateOrInterestClaimed: EstateOfInterestClaimedEnum): void => {
    const fieldName = this.fieldName;
    const {
      formikProps: { setValues }
    } = this.props;

    setValues(updatedValues => {
      const updatedClaimCategoryOptions = resolveVisibleEnumOptions<number>(ENUM_CLAIM_CATEGORY_OPTIONS, updatedValues, fieldName('claimCategory'));
      const updatedClaimCategory = updatedClaimCategoryOptions.length === 1 ? updatedClaimCategoryOptions[0].id : null;

      return {
        ...updatedValues,
        claimDetails: {
          ...updatedValues.claimDetails,
          claimCategory: updatedClaimCategory,
          detailsSupportingTheClaim: ''
        }
      };
    });
  };

  private renderClaimParties(): JSX.Element | null {
    const fieldName = this.fieldName;
    const { formikProps, disabled, partyOptions, portalIdDialogs, classes } = this.props;
    const { values } = formikProps;

    if (!VISIBILITY_CHECK_CLAIM_PARTIES(values.claimDetails!)) {
      return null;
    }

    const bindingPath = fieldName('claimParties');
    const items: ClaimParty[] = _get(values, bindingPath) ?? [];
    // TODO: Set max items
    return (
      <DocumentFieldArray
        arrayBinding={bindingPath}
        createNewItem={resolveDefaultItem}
        minItems={0}
        itemTitle="Other affected party"
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        renderItem={({ itemIndex, itemBinding, itemFocusRef, nextItemFocusRef }) => (
          <ClaimPartyItem //
            name={itemBinding}
            itemIndex={itemIndex}
            items={items}
            focusRef={itemFocusRef}
            nextItemFocusRef={nextItemFocusRef}
            disabled={disabled}
            partyOptions={partyOptions}
            dialogPortalId={portalIdDialogs}
            dropdownClassName={classes.fixedWidthDropdown}
          />
        )}
        renderAddButton={({ addButtonFocusRef, onAdd }) => (
          <FormGroupFooter
            buttonActions={addButtonFocusRef}
            icon="add"
            onClick={onAdd}
            title="Add other affected party (optional)"
            formTip="Other affected parties are those with a subsisting interest that haven't already been mentioned in this form"
          />
        )}
      />
    );
  }
}

interface ClaimPartyItemProps {
  name: string;
  disabled: boolean;
  dialogPortalId: string;
  partyOptions: LookupItemModel[];
  focusRef: React.RefObject<ButtonBaseActions>;
  nextItemFocusRef?: React.RefObject<ButtonBaseActions>;
  itemIndex: number;
  items: ClaimParty[];
  dropdownClassName: string;
}

function ClaimPartyItem({ name, disabled, dialogPortalId, partyOptions, focusRef, nextItemFocusRef, items, itemIndex, dropdownClassName }: ClaimPartyItemProps): JSX.Element {
  const fieldName = createModelKeyAppender<ClaimParty>(name);
  const filteredPartyOptions = getPartyOptionsExcludingSiblings(partyOptions, items, itemIndex);
  return (
    <>
      <PartySelectField
        name={fieldName('partyBookId')}
        partyFormConfig={NSW_CAVEAT_PARTY_FORM_CONFIG}
        disabled={disabled}
        bookRef={PARTY_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        optionsOverride={filteredPartyOptions}
        focusRef={focusRef}
        nextFocusRef={nextItemFocusRef}
      />
      <Field
        disabled={disabled}
        name={fieldName('claimPartyCapacity')}
        format="number"
        options={ENUM_CLAIM_PARTY_CAPACITY_OPTIONS}
        label="Claim party capacity"
        placeholder={resolveSelectPlaceholder(true)}
        className={classNames(dropdownClassName)}
        component={SelectField}
        debug={debug}
      />
    </>
  );
}

export default withStyles(styles)(SectionClaimDetails);
