import { FormikProps, setIn } from 'formik';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';

import { DirectionTypeEnum, PaymentMethodEnum, SubscriberBankAccountTypeEnum } from '@sympli/api-gateway/enums';
import { BsbLookupApiResponse } from '@sympli/ui-framework/components/formik/bank-account-field';
import { DeleteButtonProps } from '@sympli/ui-framework/components/formik/buttons/delete-button';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { HoldingAccountDistribution, TrustAccountDistribution } from 'src/containers/workspace/financial/directions/views/edit-directions/components/direction-record/models';
import { actionFetchWorkspaceById } from 'src/containers/workspace/shared/detail/actions';
import { ParticipantSettingApiResponse } from 'src/containers/workspace/shared/detail/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { actionFetchDirections, actionUpdateDirectionsWorkForm } from '../../actions';
import { deleteWorkspaceDirectionLineItem, updateWorkspaceDirectionLineItem } from '../../api';
import {
  BankDetailsModel,
  DeleteWorkspaceDirectionLineItemRequestModel,
  DistributionFormikModel,
  TrustAccountMap,
  UpdateWorkspaceDirectionLineItemRequestModel
} from '../../models';
import { bpayCheck, bsbCheck } from './components/direction-payee-detail/api';
import { BpayCheckApiResponse } from './components/direction-payee-detail/models';

export class CheckHelper {
  static bsbCheckTable: { [key: string]: Promise<BsbLookupApiResponse> } = {};
  static bpayNumberCheckTable: { [key: string]: Promise<BpayCheckApiResponse> } = {};

  static checkBsb(bsb: string) {
    if (!CheckHelper.bsbCheckTable[bsb]) {
      CheckHelper.bsbCheckTable[bsb] = bsbCheck(bsb)
        .then(response => {
          return response;
        })
        .catch(error => {
          // TODO improvement check error type, if it is not 400 or 404, add a flag to this promise to trigger refetch for this bsb
          // TODO only cache fetch error 404/400
          throw error;
        });
    }
    return CheckHelper.bsbCheckTable[bsb];
  }

  static checkBillerCode(workspaceId: string, billerCode: string) {
    const key = workspaceId + billerCode;
    if (!CheckHelper.bpayNumberCheckTable[key]) {
      CheckHelper.bpayNumberCheckTable[key] = bpayCheck(workspaceId, billerCode)
        .then(response => {
          return response;
        })
        .catch(error => {
          // TODO improvement check error type, if it is not 400 or 404, add a flag to this promise to trigger refetch for this bsb
          // TODO only cache fetch error 404/400
          throw error;
        });
    }
    return CheckHelper.bpayNumberCheckTable[key];
  }
}

export async function saveFSSLineItem(
  payload: UpdateWorkspaceDirectionLineItemRequestModel,
  dispatch: SafeDispatch<Action>,
  isOnlyOpenEditor: boolean,
  linkedToParticipantMatterReference?: string
) {
  try {
    const { workspaceId, participantId } = payload;
    const res = await updateWorkspaceDirectionLineItem(payload);
    if (res?.linkedSettlementWorkspacesDetail) {
      const { numberOfLinkedWorkspaces } = res?.linkedSettlementWorkspacesDetail;
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <>
              <b>Linked workspace</b>
              <br />
              <br />
              This workspace has been linked to "<b>{linkedToParticipantMatterReference}</b>". This workspace <b>has been linked</b> with ({numberOfLinkedWorkspaces}) other
              workspaces, all connected through <b>Settlement Date & Time</b>.
              <br />
              <br />
              Note that you need to <b>make your own arrangements</b> with participants from other participants from other workspaces in order to{' '}
              <b>align Settlements Date & Time</b> between workspaces.
            </>
          ),
          variant: 'new-success',
          autoHideDuration: 5000
        })
      );
      // Refetch the workspace detail data after creating linked line-item
      dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
    }

    if (isOnlyOpenEditor) {
      /* 
        fetch the whole directions when saving is successful. And it's the only open editor
        This solves two problems:
        1. reset forms so when people leave the page, they don't see unsaved change alert
        2. newly saved item will now have an id, so it can be deleted
      */
      dispatch(actionFetchDirections.request({ workspaceId: payload.workspaceId, participantId: payload.participantId }));
    } else {
      // if there are other open editors, it's always dirty and has unsaved data.
      // we need to update redux store with latest data from backend.
      dispatch(actionUpdateDirectionsWorkForm(res));
      // TODO setup the saved line item's Id, this will be done in next stage when backend supports single line item saving.
    }
    return true; // success returns true
  } catch (error) {
    dispatch(
      actionOpenGlobalSimpleNotification({
        message: (
          <>
            <b>Could not Link workspace</b>
            <br />
            <br />
            We were unable to link to workspace "<b>{linkedToParticipantMatterReference}</b>" because {error?.response.data.message}
          </>
        ),
        variant: 'new-warning',
        autoHideDuration: 5000
      })
    );

    const scope = Logger.scopeWithCustomAttributes(payload);
    Logger.captureException(new Error('Saving FSS inline item failed'), scope);
    return false; // failure returns false
  }
}

/**
 * This function will loop through a complex object or array, to see
 * for the give key, return how many times the value will match.
 */
export function getKeyValueMatchingNumberInObject(obj: object | Array<any>, key: string, value: string | boolean | number): number {
  let number = 0;

  const callback = (acc: number, currentValue: any) => {
    if (Array.isArray(currentValue) || (typeof currentValue === 'object' && currentValue != null)) {
      return acc + getKeyValueMatchingNumberInObject(currentValue, key, value);
    }
    return acc;
  };

  if (Array.isArray(obj)) {
    number = number + obj.reduce(callback, number);
  } else if (typeof obj === 'object') {
    for (const [k, v] of Object.entries(obj)) {
      if (Array.isArray(v) || (typeof v === 'object' && v != null)) {
        number = number + getKeyValueMatchingNumberInObject(v, key, value);
      } else if (k === key && v === value) {
        number++;
      }
    }
  }

  return number;
}

export async function deleteFSSLineItem(payload: DeleteWorkspaceDirectionLineItemRequestModel, dispatch: SafeDispatch<Action>) {
  try {
    const res = await deleteWorkspaceDirectionLineItem(payload);
    /* only update redux store when delete is successful, this will make left side calculator gets update:
      we cannot fetch data again because this will cause unsaved editors gone.
    */
    dispatch(actionUpdateDirectionsWorkForm(res));

    return true; // success returns true
  } catch (error) {
    const scope = Logger.scopeWithCustomAttributes(payload);
    Logger.captureException(new Error('deleting a FSS inline item failed'), scope);
    return false; // failure returns false
  }
}

export function displayToastMessage(isSuccess: boolean, dispatch: SafeDispatch<Action>, successMessage?: string) {
  dispatch(
    actionOpenGlobalSimpleNotification({
      message: isSuccess ? (
        successMessage || 'Financial line item has been saved'
      ) : (
        <>
          <b>Something went wrong.</b> We have encountered Technical Issues, please try again
        </>
      ),
      variant: isSuccess ? 'new-success' : 'new-warning',
      autoHideDuration: 5000
    })
  );
}

export function isToggleBillingMethodEnabled(detail: ParticipantSettingApiResponse | undefined) {
  if (detail == null || detail.billingSetting == null) {
    return false;
  }
  const isBillingMethodChangeableByUser = detail.billingSetting.isBillingMethodChangeableByUser;
  return isBillingMethodChangeableByUser;
}

// ToDo: [Tech Debt] WEB-24105 Use category id in future
// Once we have the Id in the SelectField then remove this mapping
export const categoryUsageMappings = new Map<string, string>([
  ['Buyer’s Agent Commission', 'Buyers agent commission'],
  ['Professional Fees', 'Professional fees'],
  ['Customer Loan Account', 'Customer loan account'],
  ['Third Party Beneficiary', 'Third Party Beneficiary']
]);

export function getGeneralAccountsLookup(financialAccounts: FinancialAccountApiResponse[] | undefined, category: string): LookupEnumModel<string>[] {
  if (!financialAccounts) return [];
  return financialAccounts
    .filter(item => item.accountType.value === SubscriberBankAccountTypeEnum.General && categoryUsageMappings.has(category))
    .map(item => ({
      id: item.id,
      name: `${item.accountName} | BSB ${item.bsbNumber} | Acc ${item.accountNumber}`
    }));
}

export function getDefaultAccountId(category: string, usage?: GeneralAccountUsageApiResponse): string | undefined {
  if (!usage) return undefined;
  const map = categoryUsageMappings.get(category);
  const usages = usage.filter(item => item.description === map && item.existingDefault);

  if (!usages?.length) return undefined;
  return usages[0].existingDefault!.accountId;
}

export function getBankDetails(accountId: string, financialAccounts: FinancialAccountApiResponse[]): FinancialAccountApiResponse | undefined {
  return financialAccounts.find(account => account.id === accountId);
}

// we need to do this mapping as if it is loan account/trust account
// the data will be returned from backend as a separate mapping
export async function updateTrustAccountDistributionMapping(
  //
  formikProps: FormikProps<{ distributions: Array<DistributionFormikModel> }>,
  trustAccountMap: TrustAccountMap,
  accountId: string,
  itemFieldName: string,
  workspaceId: string,
  participantId: string,
  dispatch: SafeDispatch<Action>,
  openEditor?: boolean,
  savingFssLineItem?: boolean,
  distribution?: TrustAccountDistribution | HoldingAccountDistribution,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  let res: boolean = true;
  if (!openEditor && savingFssLineItem && distribution) {
    const { paymentMethod, amount, directionCategory, category, categoryOther, id } = distribution;
    if (setIsLoading) {
      setIsLoading(true);
    }
    res = await saveFSSLineItem(
      {
        workspaceId,
        participantId,
        requestPayload: {
          type: DirectionTypeEnum.Direction,
          direction: {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id,
            bankAccountId: paymentMethod === PaymentMethodEnum.TrustAccount ? distribution.bankAccountId : undefined,
            bankDetails: paymentMethod === PaymentMethodEnum.TrustAccount ? distribution.bankDetails : undefined,
            holdingAccountDetails: paymentMethod === PaymentMethodEnum.HoldingAccount ? distribution.holdingAccountDetails : undefined
          }
        }
      },
      dispatch,
      getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) === 1
    );
    if (setIsLoading) {
      setIsLoading(false);
    }
    displayToastMessage(res, dispatch);
  }

  let { setValues, values: newValues } = formikProps;
  const trustAccount = trustAccountMap[accountId];
  const fieldName = createModelKeyAppender<DistributionFormikModel>(itemFieldName);
  const bankDetailsFieldName = createModelKeyAppender<BankDetailsModel>(fieldName('bankDetails'));

  newValues = setIn(newValues, bankDetailsFieldName('bankName'), trustAccount.bankDetails.bankName);
  newValues = setIn(newValues, bankDetailsFieldName('accountName'), trustAccount.bankDetails.accountName);
  newValues = setIn(newValues, bankDetailsFieldName('accountDescription'), trustAccount.bankDetails.accountDescription);
  newValues = setIn(newValues, bankDetailsFieldName('bsb'), trustAccount.bankDetails.bsb);
  newValues = setIn(newValues, bankDetailsFieldName('accountNumber'), trustAccount.bankDetails.accountNumber);

  if (!openEditor && res) {
    // when api call is failure, we don't want to close the editor
    newValues = setIn(newValues, fieldName('isEditorOpen'), false);
  }

  setValues(newValues);
}

export const getConfirmationDialogProps = (
  id: string,
  className: string,
  isLoading: boolean,
  workspaceId: string,
  participantId: string,
  setLoading: () => void,
  stopLoading: () => void,
  dispatch: SafeDispatch<Action>,
  successMessage: string
) => {
  const confirmationAsyncClose = async (value?: boolean) => {
    if (value && workspaceId && participantId && id) {
      setLoading();

      // no error handling needed because it's already handled inside deleteFSSLineItem
      const res = await deleteFSSLineItem(
        {
          workspaceId,
          participantId,
          directionItemId: id!
        },
        dispatch
      );

      stopLoading();

      displayToastMessage(res, dispatch, successMessage);
      return res;
    }
  };

  const confirmProps: Pick<DeleteButtonProps, 'confirmBeforeDelete' | 'confirmationContent' | 'confirmationProps' | 'confirmationAsyncClose'> = {
    confirmBeforeDelete: true,
    confirmationContent: <Typography>This action will delete the financial line item.</Typography>,
    confirmationAsyncClose,
    confirmationProps: {
      title: (
        <FlexLayout flexDirection="row" alignItems="center">
          <IconDelete className={className} />
          <span>Delete line item</span>
        </FlexLayout>
      ),
      color: 'error',
      buttonArrow: true,
      okButtonText: 'Delete',
      isLoading
    }
  };

  return confirmProps;
};
