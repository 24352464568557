import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '@sympli/ui-framework/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    errorMessage: {
      margin: '16px auto'
    },
    documentsForm: {
      marginTop: 10
    },
    checkboxContainer: {
      marginTop: 14
    },
    opacityBox: {
      position: 'relative',
      height: '100%',
      '&::before': {
        content: '""' /* empty content */,
        position: 'absolute',
        /* this ensures the empty content covers entire parent area */
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        /* give it a white background color or opacity won't work */
        backgroundColor: colors.WHITE,
        opacity: 0.4
      }
    },
    opacityFeeBreakdown: {
      opacity: 0.4
    },
    checkboxRoot: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormControlLabel-label': {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      marginTop: 16
    },
    checkbox: {
      marginBottom: 0,
      marginRight: 0,
      '& span': {
        fontSize: '14px'
      }
    },
    checkboxMLC: {
      marginBottom: 0,
      marginRight: 0,
      marginLeft: -25,
      '& span': {
        fontSize: '14px'
      }
    },
    wizardStepper() {
      return {
        borderTop: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between'
      };
    },
    signingButtons: {
      '&> button': {
        marginLeft: 15
      }
    },
    checkboxLabelTeal: {
      marginBottom: 0,
      '& span': {
        marginTop: 1,
        fontSize: '14px',
        fontWeight: 'bold',
        color: colors.TEAL
      }
    },
    checkboxLabelTealMLC: {
      marginBottom: 0,
      marginLeft: -27,
      '& span': {
        marginTop: 1,

        fontSize: '14px',
        fontWeight: 'bold'
        // color: colors.TEAL
      }
    },
    documentLabel: {
      fontSize: 18,
      fontWeight: 'bold'
    },
    documentLabelMLC: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    firstDivider: {
      marginTop: 20,
      marginBottom: 24
    },
    divider: {
      marginTop: 32,
      marginBottom: 32
    },
    documentStatus: {
      marginTop: 4,
      marginBottom: 16,
      lineHeight: '12px'
    },
    contentRoot: {
      marginBottom: 24
    },
    contentRootMLC: {
      marginLeft: 30,
      marginBottom: 24
    }
  }),
  {
    name: 'SignDocuments'
  }
);
