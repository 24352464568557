import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';

import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { TenancyEditable as SectionTenancyEditable } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { StateEnum } from '@sympli-mfe/enums-2-24/qld';

import { QLD_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '../../enums';
import { Mortgage2_24_1Model } from '../../models';

interface Props {
  name: string;
}

function SectionMortgagors({ name }: Props): JSX.Element {
  const {
    values: { mortgageeDetails }
  } = useFormikContext<Mortgage2_24_1Model>();

  const mortgageesPartyIds = useMemo(
    () =>
      mortgageeDetails.receivingProprietorGroups
        .flatMap(x => x.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgageeDetails]
  );

  return (
    <SectionTenancyEditable //
      name={name}
      data-name={name}
      data-testid={name}
      jurisdiction={StateEnum.QLD}
      componentLabels={QLD_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG}
      allowNewGroup={false}
      partyCapacityLookup={PARTY_CAPACITY_LOOKUP_OPTIONS}
      // party setup
      partyFormConfig={QLD_PARTY_FORM_CONFIG}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={mortgageesPartyIds}
    />
  );
}

export default React.memo(SectionMortgagors);
