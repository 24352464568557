import * as React from 'react';

import { Form, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import FormHelperText from '@mui/material/FormHelperText';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { NoticeOfAcquisitionDocumentModel } from './models';
import TransfereeAgentSolicitorDetail from './sections/certification';
import TransactionDetail from './sections/details-of-transaction';
import SectionDetailsOfProperties from './sections/section-details-of-properties';
import Transferees from './sections/transferees';
import TransferorRepresentativeDetail from './sections/transferor-representative-detail';
import Transferors from './sections/transferors';
import {
  VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
  VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL
} from './visibilityChecks';

const fieldName = modelKey<NoticeOfAcquisitionDocumentModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;

  const renderFormGeneralError = () => {
    const sectionErrorMessage = getFormikError(formikProps, fieldName('isPopulatedDataFromTransferDocumentReady'), true);
    return (
      sectionErrorMessage && (
        <FormHelperText data-error-name={fieldName('isPopulatedDataFromTransferDocumentReady')} role="alert" error={true}>
          {sectionErrorMessage}
        </FormHelperText>
      )
    );
  };

  return (
    <>
      <Form className={className}>
        {renderFormGeneralError()}
        {VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL(values) && (
          <Section title={pluralize('Transferor', values.transferors.length)}>
            <Transferors name={fieldName('transferors')} />
            <TransferorRepresentativeDetail name={fieldName('transferorRepresentativeDetail')} />
          </Section>
        )}
        {VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL(values) && (
          <>
            <Section
              title={pluralize('Transferee', values.transferees.length)}
              error={getFormikError(formikProps, fieldName('transferees'))}
              data-error-name={fieldName('transferees')}
            >
              <Transferees name={fieldName('transferees')} />
            </Section>
            <SectionDetailsOfProperties //
              name={fieldName('propertyDetails')}
              formikProps={formikProps}
              disabled={disabled}
              dialogPortalId={dialogPortalId}
            />
            <Section title="Details of transaction">
              <TransactionDetail name={fieldName('transactionDetail')} />
            </Section>
            <Section title="Certification">
              <TransfereeAgentSolicitorDetail name={fieldName('transfereeAgentSolicitorDetail')} />
            </Section>
          </>
        )}
        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
