import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    documentDetail: {},
    // override Accordion
    root: {
      backgroundColor: 'transparent',
      border: '1px solid #bdbdbd',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        backgroundColor: '#F5F5F5',
        margin: 'auto'
      }
    },
    expanded: {},
    // override Accordion Details
    accordionDetailsRoot: {
      display: 'block',
      paddingTop: 0,
      paddingRight: 12,
      paddingBottom: 12,
      paddingLeft: 12,
      backgroundColor: '#F5F5F5'
    },
    dividerError: {
      background: theme.palette.error.main,
      marginBottom: 16
    }
  }),
  {
    name: 'DocumentVerificationResultItem'
  }
);
