import * as React from 'react';

import MessageNotificationV2 from 'src/components/message-notification';

const VerifyingNotification = () => (
  <MessageNotificationV2
    variant="loading"
    primary="Verifying with land registry."
    secondary="This will take a moment, you can leave this page and we'll notify you of any updates."
  />
);

export default VerifyingNotification;
