import { resolveReceivingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { FallbackMap, NO_FALLBACK, ObjectFallback } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { BaseCaveatModel, ClaimDetailsModel, ExtentOfProhibitionModel } from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/models';

const claimDetailsFallback: ObjectFallback<Required<ClaimDetailsModel>> = {
  $children: {
    claimCategory: [undefined as any],
    estateOrInterestClaimed: [undefined as any],
    claimStatement: [undefined as any],
    claimDate: NO_FALLBACK,
    detailsSupportingTheClaim: NO_FALLBACK,
    claimParties: NO_FALLBACK
  }
};

const prohibitionFallback: ObjectFallback<Required<ExtentOfProhibitionModel>> = {
  $children: {
    actionProhibited: [undefined as any],
    notApplicableInstruments: NO_FALLBACK
  }
};

export const caveatFallbackMap: FallbackMap<BaseCaveatModel> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  caveators: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [true],
        partyCapacity: NO_FALLBACK,
        partyBookId: [''],
        addressBookId: ['']
      }
    }
  },
  titleReferences: NO_FALLBACK,
  proprietors: NO_FALLBACK,
  mortgagees: NO_FALLBACK,
  claimDetails: claimDetailsFallback,
  invalidParties: NO_FALLBACK,
  mergeFailedReason: NO_FALLBACK,
  otherParties: resolveReceivingTenancyDetailFallback({ shouldAddDefaultItemToEmptyArray: false }),
  prohibition: prohibitionFallback,
  partyReferences: NO_FALLBACK,
  subscriberParties: NO_FALLBACK
};
