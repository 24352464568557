import * as React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DocumentListAction } from '../../models';
import { useStyles } from './styles';

interface Props {
  addEnabled?: boolean;
  deleteEnabled?: boolean;
  reorderEnabled?: boolean;
  onActionClick(mode: Omit<DocumentListAction, 'default'>): void;
  disabled?: boolean;
}

function DocumentListToolbar({ addEnabled = false, deleteEnabled = false, reorderEnabled = false, onActionClick, disabled = true }: Props) {
  const classes = useStyles();
  const reorderDisabled = disabled || !reorderEnabled;
  const deleteDisabled = disabled || !deleteEnabled;
  const addDisabled = disabled || !addEnabled;
  return (
    <FlexLayout flexDirection="row" alignItems="center">
      <FlexLayout alignItems="center">
        <ButtonLink className={classes.btnText} onClick={() => onActionClick('add')} disabled={addDisabled} color="inherit">
          Add
        </ButtonLink>
      </FlexLayout>
      <hr className={classes.vertical} />
      <FlexLayout alignItems="center">
        <ButtonLink className={classes.btnText} onClick={() => onActionClick('delete')} disabled={deleteDisabled} color="inherit">
          Delete
        </ButtonLink>
      </FlexLayout>
      <hr className={classes.vertical} />
      <FlexLayout alignItems="center">
        <ButtonLink className={classes.btnText} onClick={() => onActionClick('reorder')} disabled={reorderDisabled} color="inherit">
          Reorder
        </ButtonLink>
      </FlexLayout>
    </FlexLayout>
  );
}

export default DocumentListToolbar;
