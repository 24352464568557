import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '@sympli/ui-framework/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    reportDateGenerated: {
      width: '20%',
      paddingLeft: 0
    },
    reportTypes: {
      width: '20%'
    },
    reportDownload: {
      width: '10%',
      textAlign: 'right'
    },

    heading: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: 15,
      marginTop: 15
    },

    disclaimer: {
      maxWidth: 710,
      minHeight: 32,
      backgroundColor: colors.GREY_10,
      fontSize: 14,
      margin: '10px 0'
    },
    tableCellBody: {
      backgroundColor: colors.WHITE
    }
  }),
  {
    name: 'ScheduledReportHistory'
  }
);
