import { QLD_PARTY_FORM_CONFIG as QLD_MORTGAGE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-20/config';
import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '../../enums';

// path: mortgageeDetails
const yupMortgageeDetails = resolveMemoizedTenancyValidation({
  partyCapacityLookup: PARTY_CAPACITY_LOOKUP_OPTIONS,
  isAddressRequired: false,
  partyCapacityDetailMaxLength: 300,
  partyFormConfig: QLD_MORTGAGE_PARTY_FORM_CONFIG
});

export default yupMortgageeDetails;
