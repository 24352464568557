import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { PartyCapacityEnum, PartyTypeEnum } from '@sympli-mfe/enums-2-24/qld';

import { Form24B_2_24_1Model, TransferorReferenceModel } from './models';

export const VISIBILITY_CHECK_IS_OTHER = createContextCheck(({ salePrice }: Form24B_2_24_1Model) => {
  const isOther = salePrice?.other;
  return isOther !== undefined && !isNaN(isOther) && isOther !== null;
});

export const VISIBILITY_CHECK_HAS_AGREEMENT = createContextCheck(({ transaction }: Form24B_2_24_1Model) => {
  const hasAgreement = transaction.hasAgreement;
  return !!hasAgreement;
});

export const VISIBILITY_CHECK_ARE_PARTIES_RELATED = createContextCheck(({ transaction }: Form24B_2_24_1Model) => {
  const arePartiesRelated = transaction.arePartiesRelated;
  return !!arePartiesRelated;
});

export const VISIBILITY_CHECK_IS_GST_PAYABLE = createContextCheck(({ transaction }: Form24B_2_24_1Model) => {
  const isGstPayable = transaction.isGstPayable;
  return !!isGstPayable;
});

export const VISIBILITY_CHECK_CAPACITY = createContextCheck(({ partyCapacity }: TransferorReferenceModel) => {
  return !!partyCapacity?.capacity;
});

export const VISIBILITY_CHECK_CAPACITY_DETAIL = createContextCheck(({ partyCapacity }: TransferorReferenceModel) => {
  return !!partyCapacity?.details;
});

export const VISIBILITY_CHECK_TRUST_NAME = createContextCheck(({ partyCapacity }: TransferorReferenceModel, { partyType }: PartyModel) => {
  return partyCapacity?.capacity === PartyCapacityEnum.Trustee && partyType === PartyTypeEnum.Individual;
});
