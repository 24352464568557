import * as yup from 'yup';

import yupIssuingPartyDirection from 'src/containers/documents/scaffolded-form/vic/2-18/components/nomination/issuing-party-direction/validationSchema';
import yupCtController from 'src/containers/documents/scaffolded-form/vic/2-18/components/nomination/nomination-details/validationSchema';
import yupTitleReferences from 'src/containers/documents/scaffolded-form/vic/2-18/components/nomination/title-references/validationSchema';
import { NominateeCtToPaperInstrument_2_18_1Model } from './models';
import yupNominationDetails from './sections/nomination-details/validationSchema';

export default yup
  .object<NominateeCtToPaperInstrument_2_18_1Model>({
    titleReferences: yupTitleReferences,
    ctController: yupCtController,
    nominationDetails: yupNominationDetails,
    issuingPartyDirection: yupIssuingPartyDirection
  })
  .defined()
  .log();
