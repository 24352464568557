import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchGroupDocumentsWorkflow } from '../actions/groups';
import { GroupDocumentsWorkflowApiResponse } from '../group-settings/models';

export interface GroupDocumentsWorkflowState {
  detail?: GroupDocumentsWorkflowApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: GroupDocumentsWorkflowState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  GroupDocumentsWorkflowState,
  Action
>(initialState)
  .handleAction(actionFetchGroupDocumentsWorkflow.request, (state): GroupDocumentsWorkflowState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchGroupDocumentsWorkflow.success, (state, action): GroupDocumentsWorkflowState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchGroupDocumentsWorkflow.failure, (state, action): GroupDocumentsWorkflowState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
