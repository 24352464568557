import queryString from 'query-string';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import { prefixWithVersion } from '../shared/detail/helpers';

export interface TitleQueryParams {
  workspaceId: string;
  participantId?: string;
  titleReference: string;
}

export const readonlyWorkspaceStatuses: WorkspaceStatusEnum[] = [
  WorkspaceStatusEnum.Abandoned,
  WorkspaceStatusEnum.Archived,
  WorkspaceStatusEnum.Success,
  WorkspaceStatusEnum.Finalised
];

export function resolveTitleDetailLink(queryParams: TitleQueryParams) {
  const { workspaceId, participantId = '', titleReference } = queryParams;
  const query = queryString.stringify({ participantId, titleReference });
  return prefixWithVersion(`/workspace/${encodeURIComponent(workspaceId)}/titles?${query}`);
}

// this means user cannot do anything to the workspace
export function resolveWorkspaceReadonly(workspaceStatus: WorkspaceStatusEnum) {
  return readonlyWorkspaceStatuses.includes(workspaceStatus);
}
