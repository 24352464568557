import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { WorkspaceFilesApiResponse } from '../workspace-files/models';
import { actionFetchWorkspaceFiles, actionRemoveFileWithIssue, DeleteFileApiRequest, WorkspaceFilesApiRequest } from './actions';

const fetchWorkspaceFiles = (args: WorkspaceFilesApiRequest) => {
  const { workspaceId, participantId } = args;
  return http.get<WorkspaceFilesApiResponse[]>(`/files/${encodeURIComponent(workspaceId)}/participants/${participantId}/files`);
};

const removeFileWithIssue = (args: DeleteFileApiRequest) => {
  return http.post<boolean>(
    `/files/${encodeURIComponent(args.workspaceId)}/participants/${encodeURIComponent(args.participantId)}/files/${encodeURIComponent(args.fileId)}/delete`,
    {}
  );
};

export function* sagaFetchWorkspaceFiles(action: ReturnType<typeof actionFetchWorkspaceFiles.request>) {
  try {
    const items = yield* call(fetchWorkspaceFiles, action.payload);
    yield put(actionFetchWorkspaceFiles.success({ items }));
  } catch (error) {
    yield put(actionFetchWorkspaceFiles.failure({ error }));
  }
}

export function* sagaRemoveFileWithIssue(action: ReturnType<typeof actionRemoveFileWithIssue.request>) {
  try {
    yield* call(removeFileWithIssue, action.payload);
    const items = yield* call(fetchWorkspaceFiles, action.payload);
    yield put(actionFetchWorkspaceFiles.success({ items }));
  } catch (error) {
    yield put(actionFetchWorkspaceFiles.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchWorkspaceFiles.request, sagaFetchWorkspaceFiles),
  takeLatest(actionRemoveFileWithIssue.request, sagaRemoveFileWithIssue)
];
