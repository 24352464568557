import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Input, { InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps extends InputComponentProps {
  numeratorName: string;
  denominatorName: string;

  numeratorValue: string;
  denominatorValue: string;

  numeratorError?: string;
  denominatorError?: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class Fraction extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    numeratorName: 'numerator',
    denominatorName: 'denominator'
  };

  render() {
    const { classes, label, numeratorName, denominatorName, disabled, numeratorValue, denominatorValue, numeratorError, denominatorError, onChange, onBlur } = this.props;

    return (
      <FlexLayout flexDirection="row">
        <Input
          label={label}
          disabled={disabled}
          className={classNames(classes.halfWidth, classes.longLabel)}
          name={numeratorName} //
          value={numeratorValue}
          error={numeratorError}
          aria-label="numerator"
          onChange={onChange}
          onBlur={onBlur}
        />
        <FlexLayout flexDirection="column" justifyContent="center" className={classes.forwardSlash}>
          {' / '}
        </FlexLayout>
        <Input
          disabled={disabled}
          className={classNames(classes.halfWidth, classes.noLabel)}
          name={denominatorName} //
          value={denominatorValue}
          error={denominatorError}
          aria-label="denominator"
          onChange={onChange}
          onBlur={onBlur}
        />
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(Fraction);
