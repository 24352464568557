import React, { useMemo } from 'react';

import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { RadioClassKeys } from '@sympli/ui-framework/components/form/base-components/radio';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { EXTENT_OF_LAND_DISCHARGED_OR_EXTENT_OF_MONEY_DISCHARGED_LOOKUP_OPTIONS } from '../../enums';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionExtentOfLandDischarged({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const radioButtonClasses = useMemo<Partial<ClassNameMap<keyof ReturnType<RadioClassKeys>>>>(
    () => ({
      formControlLabel: classes.radioLabels
    }),
    [classes]
  );

  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Extent of land"
        fieldAreaDirection="column"
      >
        <Field //
          name={name}
          aria-label="Extent of land"
          component={RadioField}
          disabled={disabled}
          classes={radioButtonClasses}
          format="number"
          options={EXTENT_OF_LAND_DISCHARGED_OR_EXTENT_OF_MONEY_DISCHARGED_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
        />
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionExtentOfLandDischarged);
