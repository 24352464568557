import React from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
  onChange: (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => void;
}

function SectionTitleReferences({ name, focusRef, onChange }: Props): JSX.Element {
  const { disabled } = useDocumentContext();

  return (
    <TitleReferences //
      name={name}
      disabled={disabled}
      focusRef={focusRef}
      data-name={name}
      data-testid={name}
      onChange={onChange}
    />
  );
}

export default React.memo(SectionTitleReferences);
