import { createAction, createAsyncAction } from 'typesafe-actions';

import { AuthorityComplianceReportTypeEnum, DocumentStatusEnum } from '@sympli/api-gateway/enums';
import {
  AuthorityComplianceReportApiResponse,
  CheckTitleActivityApiResponse,
  ResupplyLandRegistryInformationApiResponse,
  UpdateWorkspaceDocumentApiResponse,
  WorkspaceBasicInfoApiResponse,
  WorkspaceDocumentSummaryApiResponse,
  WorkspaceParticipantApiResponse
} from '@sympli/api-gateway/models';
import { ParticipantArchivedStatus } from '@sympli/api-gateway/shared';

import { DocumentListAction, DocumentListModel } from 'src/containers/shared/document-list/models';
import { WorkspaceActivityLogModel } from 'src/containers/workspace/shared/detail/components/tab-logs/models';
import { PaginatedItemsModel } from 'src/models';
import { TitleListAction } from 'src/pages/workspace/shared/workspace-detail-box/components/title-and-addresses/models';
import { WorkspaceTaskApiResponse } from './components/my-tasks/models';
import {
  FinancialAccount as FinancialAccountApiResponse,
  LinkedSettlementDetailApiRequest,
  LinkedSettlementDetailApiResponse,
  ParticipantSettingApiResponse,
  WorkspaceDetailModel,
  WorkspaceLatestTransactionApiResponse
} from './models';
import { WorkspaceInvitableRoleDetailApiResponse } from './reducers/workspaceInvitation';

export const actionSetDocumentListAction = createAction('SET_DOCUMENT-LIST_ACTION')<{ mode: DocumentListAction }>();
export const actionSetTitleListAction = createAction('SET_TITLE-LIST_ACTION')<{ mode: TitleListAction }>();

// generic api request, do not change this.
// if you need additional parameters, create interface that inherits from this
export interface WorkspaceRelatedApiRequest {
  workspaceId: string;
  participantId: string;
}

export interface UpdateWorkspaceDocumentsStatusRequestModel {
  workspaceId: string;
  participantId: string;
  documents: Array<UpdatedDocumentStatus>;
}

export interface UpdatedDocumentStatus {
  documentId: string;
  statusId: DocumentStatusEnum;
  documentActions: UpdateWorkspaceDocumentApiResponse['documentActions'];
  documentPermissions: UpdateWorkspaceDocumentApiResponse['documentPermissions'];
  documentDenyPermissions: UpdateWorkspaceDocumentApiResponse['documentDenyPermissions'];
  isFullySignedByAllParticipants: boolean;
}

export interface UpdateWorkspaceTitleActivityRequestModel {
  reference: string;
  workspaceId: string;
  titleActivityCheckResult: CheckTitleActivityApiResponse;
}

export interface UpdateWorkspaceTitleLandRegistryDetailRequestModel {
  reference: string;
  workspaceId: string;
  landRegistryDetail: ResupplyLandRegistryInformationApiResponse;
}

export interface UpdateWorkspaceDocumentsRequestModel {
  items: DocumentListModel[];
}

export interface UpdateDocumentDataInWorkspaceDocumentsRequestModel {
  documentId: string;
  data: string;
}

export interface UpdateParticipantListModel {
  workspaceId: string;
  participants: WorkspaceParticipantApiResponse[];
}

export interface WorkspaceActivityLogsApiRequest {
  workspaceId: string;
  participantId: string;
  pageNumber: number;
}

export interface WorkspaceActivityLogsApiResponse extends PaginatedItemsModel<WorkspaceActivityLogModel> {}

export const actionFetchWorkspaceById = createAsyncAction(
  //
  'FETCH_WORKSPACE_BY_ID',
  'FETCH_WORKSPACE_BY_ID_SUCCESS',
  'FETCH_WORKSPACE_BY_ID_ERROR'
)<
  //
  WorkspaceRelatedApiRequest,
  { detail: WorkspaceDetailModel },
  { error: Error }
>();

export const actionFetchWorkspaceBasicInfo = createAsyncAction(
  //
  'FETCH_WORKSPACE_BASIC_INFO',
  'FETCH_WORKSPACE_BASIC_INFO_SUCCESS',
  'FETCH_WORKSPACE_BASIC_INFO_ERROR'
)<
  //
  WorkspaceRelatedApiRequest,
  { detail: WorkspaceBasicInfoApiResponse },
  { error: Error }
>();

export const actionPreloadWorkspaceBasicInfo = createAction('PRELOAD_WORKSPACE_BASIC_INFO')<{
  //
  participantId: string;
  matter?: string;
  data: WorkspaceBasicInfoApiResponse;
}>();

export const actionCleanupWorkspaceDocumentErrors = createAction('CLEANUP_WORKSPACE_DOCUMENT_ERRORS')<string>();

export const actionUpdateWorkspaceDocumentsStatus = createAction('UPDATE_WORKSPACE_DOCUMENTS_STATUS')<UpdateWorkspaceDocumentsStatusRequestModel>();

export const actionUpdateDocumentDataInWorkspaceDocuments = createAction('UPDATE_DOCUMENT_DATA_IN_WORKSPACE_DOCUMENTS')<UpdateDocumentDataInWorkspaceDocumentsRequestModel>();

export const actionFetchWorkspaceTasks = createAsyncAction(
  //
  'FETCH_WORKSPACE_TASKS',
  'FETCH_WORKSPACE_TASKS_SUCCESS',
  'FETCH_WORKSPACE_TASKS_ERROR'
)<
  //
  WorkspaceRelatedApiRequest,
  { items: WorkspaceTaskApiResponse[] },
  { error: Error }
>();

export const actionFetchWorkspaceActivityLogs = createAsyncAction(
  //
  'FETCH_WORKSPACE_ACTIVITY_LOGS',
  'FETCH_WORKSPACE_ACTIVITY_LOGS_SUCCESS',
  'FETCH_WORKSPACE_ACTIVITY_LOGS_ERROR'
)<
  //
  WorkspaceActivityLogsApiRequest,
  {
    items: Array<WorkspaceActivityLogModel>;
    totalCount: number;
    pageNumber: number;
  },
  { error: Error }
>();

export const actionFetchAdditionalInvitableRoles = createAsyncAction(
  //
  'FETCH_ADDITIONAL_INVITABLE_ROLES',
  'FETCH_ADDITIONAL_INVITABLE_ROLES_SUCCESS',
  'FETCH_ADDITIONAL_INVITABLE_ROLES_ERROR'
)<
  //
  WorkspaceRelatedApiRequest,
  { detail: WorkspaceInvitableRoleDetailApiResponse },
  { error: Error }
>();

export const actionUpdateParticipantList = createAction(
  //
  'UPDATE_PARTICIPANT_LIST'
)<UpdateParticipantListModel>();

export interface UpdateCurrentParticipantModel {
  workspaceId: string;
  participantId: string;
  reference?: string;
  participantArchivedStatus?: ParticipantArchivedStatus;
}

export const actionFetchWorkspaceLatestTransaction = createAsyncAction(
  //
  'FETCH_WORKSPACE_LATEST_TRANSACTION',
  'FETCH_WORKSPACE_LATEST_TRANSACTION_SUCCESS',
  'FETCH_WORKSPACE_LATEST_TRANSACTION_ERROR'
)<
  //
  { workspaceId: string },
  { detail: WorkspaceLatestTransactionApiResponse },
  { error: Error }
>();

export const actionUpdateCurrentParticipantDetail = createAction('UPDATE_CURRENT_PARTICIPANT_DETAIL')<UpdateCurrentParticipantModel>();

export const actionToggleCurrentParticipantDocDisplay = createAction('TOGGLE_CURRENT_PARTICIPANT_DOC_DISPLAY')<string>();

export const actionUpdateWorkspaceDocuments = createAction('UPDATE_WORKSPACE_DOCUMENTS')<UpdateWorkspaceDocumentsRequestModel>();

export const actionUpdateWorkspaceTitleActivity = createAction('UPDATE_WORKSPACE_TITLE_ACTIVITY')<UpdateWorkspaceTitleActivityRequestModel>();

export const actionUpdateWorkspaceTitleLandRegistryDetail = createAction('UPDATE_TRANSACTION_TITLE_LAND_REGISTRY_DETAIL')<UpdateWorkspaceTitleLandRegistryDetailRequestModel>();

export const actionUpdateWorkspaceStatusToLodgeInProgress = createAction('UPDATE_WORKSPACE_STATUS_TO_LODGE_IN_PROGRESS')<string /*workspaceId */>();

export const actionUpdateWorkspaceStatusToArchived = createAction('UPDATE_WORKSPACE_STATUS_TO_ARCHIVED')<string /*workspaceId */>();

export const actionUpdateWorkspaceIsLockedStatus = createAction('UPDATE_WORKSPACE_STATUS_TO_LOCKED')<boolean>();

export const actionDismissTacWarning = createAction('DISMISS_TAC_WARNING')<{ titleReference: string; tac: CheckTitleActivityApiResponse }>();

export const actionResetWorkspace = createAction('UPDATE_WORKSPACE_STATUS_TO_LOCKED')();

// separate endpoint to fetch workspace documents and workspace participants to speed up the display
export const actionFetchWorkspaceParticipants = createAsyncAction(
  //
  'FETCH_WORKSPACE_PARTICIPANTS',
  'FETCH_WORKSPACE_PARTICIPANTS_SUCCESS',
  'FETCH_WORKSPACE_PARTICIPANTS_ERROR'
)<
  //
  WorkspaceRelatedApiRequest,
  { items: WorkspaceParticipantApiResponse[] },
  { error: Error }
>();

export const actionFetchWorkspaceDocuments = createAsyncAction(
  //
  'FETCH_WORKSPACE_DOCUMENTS',
  'FETCH_WORKSPACE_DOCUMENTS_SUCCESS',
  'FETCH_WORKSPACE_DOCUMENTS_ERROR'
)<
  //
  WorkspaceRelatedApiRequest,
  { items: WorkspaceDocumentSummaryApiResponse[] },
  { error: Error }
>();

export interface UpdateWorkspaceDocumentParticipantModel {
  documentParticipant: Partial<WorkspaceDocumentSummaryApiResponse['documentParticipants'][number]>;
}

// tslint:disable-next-line:max-line-length
export const actionUpdateWorkspaceDocumentCurrentParticipantDetail = createAction(
  'UPDATE_WORKSPACE_DOCUMENT_CURRENT_PARTICIPANT_DETAIL'
)<UpdateWorkspaceDocumentParticipantModel>();

export interface WorkspaceParticipantSettingApiRequest {
  workspaceId: string;
  participantId: string;
}

export const actionFetchWorkspaceParticipantSetting = createAsyncAction(
  //
  'FETCH_WORKSPACE_PARTICIPANT_SETTING',
  'FETCH_WORKSPACE_PARTICIPANT_SETTING_SUCCESS',
  'FETCH_WORKSPACE_PARTICIPANT_SETTING_ERROR'
)<
  //
  WorkspaceParticipantSettingApiRequest,
  ParticipantSettingApiResponse,
  { error: Error }
>();

export interface WorkspaceAuthorityComplianceReportRequest {
  workspaceId: string;
  participantId: string;
  authorityMessageId: string;
  messageType: AuthorityComplianceReportTypeEnum;
  lodgementCaseId?: string;
}

export const actionFetchWorkspaceAuthorityComplianceReport = createAsyncAction(
  //
  'FETCH_WORKSPACE_AUTHORITY_COMPLIANCE_REPORT',
  'FETCH_WORKSPACE_AUTHORITY_COMPLIANCE_REPORT_SUCCESS',
  'FETCH_WORKSPACE_AUTHORITY_COMPLIANCE_REPORT_ERROR'
)<
  //
  WorkspaceAuthorityComplianceReportRequest,
  { data: AuthorityComplianceReportApiResponse },
  { error: Error }
>();

export const actionFetchWorkspaceFinancialAccounts = createAsyncAction(
  //
  'FETCH_WORKSPACE_FINANCIAL_ACCOUNTS',
  'FETCH_WORKSPACE_FINANCIAL_ACCOUNTS_SUCCESS',
  'FETCH_WORKSPACE_FINANCIAL_ACCOUNTS_ERROR'
)<
  //
  undefined,
  { data: FinancialAccountApiResponse[] },
  { error: Error }
>();

export const actionFetchLinkedSettlementDetail = createAsyncAction(
  //
  'FETCH_LINKED_SETTLEMENT_DETAIL',
  'FETCH_LINKED_SETTLEMENT_DETAIL_SUCCESS',
  'FETCH_LINKED_SETTLEMENT_DETAIL_ERROR'
)<
  //
  LinkedSettlementDetailApiRequest,
  { detail: LinkedSettlementDetailApiResponse },
  { error: Error }
>();
