import * as yup from 'yup';

import { Mortgage2_21_2Model } from './models';
import yupAdditionalAttachments from './sections/document-attachments/validationSchema';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupTermsAndConditions from './sections/terms-and-conditions/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<Mortgage2_21_2Model>({
    partyBook: yup.mixed(),
    partyBookApi: yup.mixed(),
    mortgagors: yupMortgagors,
    titleReferences: yupTitleReferences,
    mortgagees: yupMortgagees,
    termsAndConditions: yupTermsAndConditions,
    additionalAttachments: yupAdditionalAttachments,
    transferees: yup.mixed(),
    needToGeneratePartyBook: yup.mixed()
  })
  .defined()
  .log();
