import * as yup from 'yup';

import { isMortgageeNameMismatch } from '../../helpers';
import { DischargeOfMortgage2_17_1Model, MortgageePartyModel } from '../../models';

// path: mortgagees
const yupMortgagees = yup
  .array<MortgageePartyModel, DischargeOfMortgage2_17_1Model>()
  .defined()
  .testContextualRule({
    message: 'Mortgagee names must match. Please select Name differs to title and provide a reason for name difference.',
    uniqueTestName: 'mortgagees[*] name mismatch',
    requirement: (parent: DischargeOfMortgage2_17_1Model /*, context: DischargeOfMortgage2_17_1Model*/) => {
      const isValid = !isMortgageeNameMismatch(parent);
      return isValid;
    }
  });

export default yupMortgagees;
