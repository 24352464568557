import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { VicAddressSelectField_2_18 as AddressSelectField } from '@sympli-mfe/document-components/address-field/vic/2-18';
import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-18/config';
import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions, usePartyBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import PhoneNumberField from '@sympli/ui-framework/components/formik/phone-number-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { VIC_NOA_ADDRESS_FORM_CONFIG, VIC_NOA_CORRESPONDENCE_ADDRESS_FORM_CONFIG } from '../../config';
import { SRO_PARTY_CAPACITY_LOOKUP_OPTIONS } from '../../enums';
import { NoticeOfAcquisitionDocumentModel, TransfereeDetailModel } from '../../models';
import {
  VISIBILITY_CHECK_STATE_REVENUE_OFFICE_HELPER,
  VISIBILITY_CHECK_TRANSFEREE_BIRTH_DATE,
  VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL,
  VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS
} from '../../visibilityChecks';
import HelperText from '../helper-text';
import { resolveContactDetailsMeta } from './helpers';
import { useStyles } from './styles';

const debug = !import.meta.env.PROD;

interface Props {
  name: string;
}

function TransfereeDetail({ name }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;
  const value: TransfereeDetailModel = _get(values, name);
  const { disabled, dialogPortalId } = useDocumentContext();
  const addressBookOptions = useAddressBookOptions(values.addressBook, VIC_NOA_ADDRESS_FORM_CONFIG);
  const partyBookOptions = usePartyBookOptions(values.partyBook, VIC_PARTY_FORM_CONFIG);
  const fieldName = createModelKeyAppender<TransfereeDetailModel>(name);

  const {
    //
    phoneIsMandatory,
    emailIsMandatory,
    contactDetailMessage,
    showContactDetailMessageAsError
  } = resolveContactDetailsMeta<TransfereeDetailModel>(formikProps, fieldName, value);

  return (
    <div data-name={name} className={classes.root}>
      <TextOnly //
        label="Name"
        name={fieldName('partyBookId')}
        value={value.partyBookId}
        options={partyBookOptions}
        fullWidth
      />

      <TextOnly //
        label="Share holding"
        name={fieldName('shareFraction')}
        value={`${value.shareFraction.numerator}/${value.shareFraction.denominator}`}
        fullWidth
      />

      {VISIBILITY_CHECK_TRANSFEREE_BIRTH_DATE(value, null, values) && (
        <>
          <Field //
            name={fieldName('birthDate')}
            component={DatePickerField}
            disabled={disabled}
            label="Date of birth"
            className={classes.quarterWidth}
            disableFuture
            validationOverflowDirection="horizontal"
          />
          <br />
        </>
      )}
      <Field //
        name={fieldName('sroPartyCapacity')}
        component={SelectField}
        debug={debug}
        disabled={disabled}
        format="number"
        label="Representative capacity for State Revenue Office"
        options={SRO_PARTY_CAPACITY_LOOKUP_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
        fullWidth
      />

      {VISIBILITY_CHECK_STATE_REVENUE_OFFICE_HELPER(value) && (
        <>
          {/**
           * When a user selects either CustodianBareTrustee or TrusteeOfATrust,
           * they have to send a “Trust NOA” to the SRO outside of Sympli.  Not doing so can have dire consequences
           */}
          <HelperText>
            Under section 46K (1) of the Land Tax Act 2005 the Notice of Trust Acquisition of an interest in land form must be lodged with the SRO by a Trustee acquiring land in
            Victoria on trust, within one month of settlement.
          </HelperText>
          <br />
        </>
      )}

      {VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL(value) && (
        <Field //
          name={fieldName('partyCapacityDetail')}
          component={InputField}
          disabled={disabled}
          label="Trust name"
          placeholder={resolvePlaceholder()}
          fullWidth
        />
      )}

      {VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS(value, null, values) && (
        <AddressSelectField //
          name={fieldName('principalResidentialAddressBookId')}
          bookRef="addressBook"
          dialogPortalId={dialogPortalId}
          disabled={disabled}
          addressFormConfig={VIC_NOA_ADDRESS_FORM_CONFIG}
          label={
            <>
              Address for principal place of residence (PPR)
              <InfoIconWithTooltip
                flow="inline"
                tooltipContent="A principal place of residence exemption is available to natural persons for the land on which their family home is situated. This means that an individual is not required to pay land tax if their principal place of residence is the only land they own in Victoria. Any jointly owned land which is the principal residence of any of the individual joint owners is also exempt from land tax. If you run a business from your principal place of residence you may be excluded from receiving this exemption."
              />
            </>
          }
          optionsOverride={addressBookOptions}
        />
      )}
      <AddressSelectField //
        name={fieldName('addressAtTimeOfTransferBookId')}
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        label="Address at TIME of transfer"
        optionsOverride={addressBookOptions}
        addressFormConfig={VIC_NOA_ADDRESS_FORM_CONFIG}
      />
      <AddressSelectField //
        name={fieldName('correspondenceAddressBookId')}
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        label="Address for FUTURE correspondence"
        optionsOverride={addressBookOptions}
        addressFormConfig={VIC_NOA_CORRESPONDENCE_ADDRESS_FORM_CONFIG}
      />
      <HelperText //
        data-error-name={fieldName('missingContactDetails')}
        data-name={fieldName('missingContactDetails')}
        variant={showContactDetailMessageAsError ? 'error' : 'default'}
      >
        {contactDetailMessage}
      </HelperText>

      <Field //
        name={fieldName('contactPhoneNumber')}
        component={PhoneNumberField}
        disabled={disabled}
        label="Phone"
        placeholder={resolvePlaceholder(phoneIsMandatory)}
      />
      <Field //
        name={fieldName('emailAddress')}
        component={InputField}
        disabled={disabled}
        label="Email"
        placeholder={resolvePlaceholder(emailIsMandatory)}
        fullWidth
      />
    </div>
  );
}

export default React.memo(TransfereeDetail);
