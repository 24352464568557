import { resolveConsiderationFallback } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { PartyCapacityEnum } from '@sympli-mfe/enums-2-18/vic';

import { RelinquishingTenancyDetailModel, TransferDocumentModel } from './models';
import { VISIBILITY_CHECK_TRANSFEROR_DETAILS$RELINQUISHING_PROPRIETOR_GROUPS$SHARE_FRACTION } from './visibilityChecks';

const transferorFallbackMap: FallbackMap<Required<RelinquishingTenancyDetailModel>> = {
  relinquishingProprietorGroups: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        shareTransferred: NO_FALLBACK,
        parties: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              partyBookId: [''],
              partyCapacity: NO_FALLBACK
            }
          }
        },
        shareFraction: {
          $objectVisibility: [VISIBILITY_CHECK_TRANSFEROR_DETAILS$RELINQUISHING_PROPRIETOR_GROUPS$SHARE_FRACTION, null as any],
          $children: {
            numerator: [null],
            denominator: [null]
          }
        },
        previouslyHeldShareFraction: NO_FALLBACK,
        proprietorGroupType: NO_FALLBACK
      }
    }
  }
};

export const fallbackMap: FallbackMap<TransferDocumentModel> = {
  workspaceTitleReferences: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        volume: NO_FALLBACK,
        folio: NO_FALLBACK,
        estateType: NO_FALLBACK,
        transferorDetails: {
          $children: transferorFallbackMap
        }
      }
    }
  },
  transferorDetails: {
    $children: {
      ...transferorFallbackMap,
      isGroupStructureMeaningful: NO_FALLBACK
    }
  },
  consideration: resolveConsiderationFallback(),
  transfereeDetails: resolveTenancyFallback({
    partyCapacityMinor: PartyCapacityEnum.Minor
  }),
  encumbrance: {
    $children: {
      isCovenantToBeCreated: [false],
      benefitedLandReferences: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            referenceType: [null],
            referenceNumber: ['']
          }
        }
      },
      covenantDetails: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            referenceNumber: ['']
          }
        }
      },
      expiryDate: [null]
    }
  },
  dutyAssessment: NO_FALLBACK,
  relatedDocumentIds: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  partyBook: NO_FALLBACK
};
