import * as React from 'react';

import Typography from '@mui/material/Typography';

import { DocumentStatusEnum, FinancialStatusEnum } from '@sympli/api-gateway/enums';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import StatusBadge from '@sympli/ui-framework/components/status-badge';

import { isFullyOptedOut } from '../../helpers';
import { resolveAllFinancialSettlementScheduleStatusText } from '../settlement-directions-list/helpers';
import Approved from './components/tooltip-financial-settlement-summary/fss-status-approved/Approved';
import InPreparation from './components/tooltip-financial-settlement-summary/fss-status-in-preparation/InPreparation';
import Invited from './components/tooltip-financial-settlement-summary/fss-status-invited/Invited';
import NotApplicable from './components/tooltip-financial-settlement-summary/fss-status-not-applicable/NotApplicable';
import PartiallySigned from './components/tooltip-financial-settlement-summary/fss-status-partially-signed/PartiallySigned';
import Signed from './components/tooltip-financial-settlement-summary/fss-status-signed/Signed';

interface Props {
  hasIssues?: boolean;
  isCurrentParticipant?: boolean;
  status: DocumentStatusEnum;
  showToolTips?: boolean;
  isPartiallySigned: boolean;
  paymentsStatus: FinancialStatusEnum;
  sourceFundsStatus: FinancialStatusEnum;
}

class DirectionStatusBadgeNew extends React.PureComponent<Props> {
  steps = ['in preparation', 'approved', 'signed'];

  render() {
    const { status, hasIssues, isCurrentParticipant, showToolTips, isPartiallySigned, paymentsStatus, sourceFundsStatus } = this.props;
    const isNotApplicable = isFullyOptedOut(paymentsStatus, sourceFundsStatus);
    const description = resolveAllFinancialSettlementScheduleStatusText(status, isPartiallySigned, isNotApplicable);

    if (hasIssues) {
      return <StatusBadge color="error" size="small" variant="outlined" label={isCurrentParticipant ? 'Resolve issues' : 'Issues found'} />;
    }

    if (isNotApplicable) {
      return showToolTips ? (
        <Tooltip title={<NotApplicable />} tooltipType="document">
          <Typography variant="body5">{description}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body5">{description}</Typography>
      );
    }

    if (isPartiallySigned) {
      return showToolTips ? (
        <Tooltip title={<PartiallySigned steps={this.steps} />} tooltipType="document">
          <Typography variant="body5">{description}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body5">{description}</Typography>
      );
    }

    // ref: https://projects.invisionapp.com/d/main#/console/17568417/375399286/preview
    switch (status) {
      case DocumentStatusEnum.InPreparation:
      case DocumentStatusEnum.Reviewing:
        return showToolTips ? (
          <Tooltip title={<InPreparation steps={this.steps} />} tooltipType="document">
            <Typography variant="body5">{description}</Typography>
          </Tooltip>
        ) : (
          <Typography variant="body5">{description}</Typography>
        );
      case DocumentStatusEnum.Signing:
        return showToolTips ? (
          <Tooltip title={<Approved steps={this.steps} />} tooltipType="document">
            <Typography variant="body5">{description}</Typography>
          </Tooltip>
        ) : (
          <Typography variant="body5">{description}</Typography>
        );
      case DocumentStatusEnum.LodgementInProgress:
      case DocumentStatusEnum.LodgementVerificationInProgress:
      case DocumentStatusEnum.Lodged:
      case DocumentStatusEnum.Signed:
        return showToolTips ? (
          <Tooltip title={<Signed steps={this.steps} />} tooltipType="document">
            <Typography variant="body5">{description}</Typography>
          </Tooltip>
        ) : (
          <Typography variant="body5">{description}</Typography>
        );
      case DocumentStatusEnum.Awaiting:
        return showToolTips ? (
          <Tooltip title={<Invited />} tooltipType="document">
            <Typography variant="body5">{description}</Typography>
          </Tooltip>
        ) : (
          <Typography variant="body5">{description}</Typography>
        );
      default:
        return showToolTips ? (
          <Tooltip title={<InPreparation steps={this.steps} />} tooltipType="document">
            <Typography variant="body5">{description}</Typography>
          </Tooltip>
        ) : (
          <Typography variant="body5">{description}</Typography>
        );
    }
  }
}
export default DirectionStatusBadgeNew;
