import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import Logger from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import Popover from 'src/components/popover';
import LodgementDatepicker from 'src/containers/shared/workspace-tasks-box/components/lodgement-datepicker';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { actionFetchWorkspaceTasks } from 'src/containers/workspace/shared/detail/actions';
import { dateTimeLine } from 'src/utils/formatters';
import IconTypography from '../icon-typography';
import { updateExpectedLodgementDate } from './api';
import { useStyles } from './styles';

export interface LodgementDateTimeProps {
  workspaceId: string;
  participantId: string;
  lodgementDate: Date;
  open?: boolean;
  onOpenOrClose?(open: boolean): void;
  onUpdate?(): void;
  lodgementDateTime: string;
  businessDaysTill: number;
  isLocked?: boolean;
  onclick(): void;
}

function LodgementDateTime(props: LodgementDateTimeProps) {
  const { workspaceId, participantId, open = false, onOpenOrClose, lodgementDate, onUpdate, lodgementDateTime, businessDaysTill, isLocked = false, onclick } = props;
  const achorElem = React.useRef<HTMLDivElement>(null);
  const isOverDue: boolean = businessDaysTill < 0;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isUpdating, setUpdating] = useState(false);
  const handleOnClose = useCallback(() => onOpenOrClose?.(false), [onOpenOrClose]);
  const handleOnChange = useCallback(
    (date: Date) => {
      setUpdating(true);
      const dateString = dateTimeLine(date, DateFormatEnum.DATETIME);
      updateExpectedLodgementDate(workspaceId, { participantId, date: dateString })
        .then(() => onUpdate?.())
        .catch(error => {
          const scope = Logger.scopeWithCustomAttributes({ workspaceId, participantId, date: dateString });
          Logger.captureException(error, scope);
          dispatch(actionCreateGlobalErrorMessage({ title: 'Unable to update expected lodgement date', message: error.message }));
        })
        .finally(() => {
          setUpdating(false);
          handleOnClose();
          dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
        });
    },
    [workspaceId, participantId, onUpdate, dispatch, handleOnClose]
  );

  const lodgementDateTimeSection: JSX.Element = (
    <>
      <div ref={achorElem}>
        {isLocked ? (
          <Button className={classes.buttonlink}>Lodgement</Button>
        ) : (
          <ButtonLink className={classes.buttonlink} onClick={onclick}>
            Lodgement
          </ButtonLink>
        )}
      </div>
      <Typography variant="body2">
        {lodgementDateTime}
        {!isOverDue && ' - ' + (businessDaysTill === 0 ? 'Today' : businessDaysTill + (businessDaysTill > 1 ? ' days' : ' day'))}
      </Typography>
      {isOverDue && <IconTypography text="overdue" />}
      <Popover //
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          classes: {
            root: classes.popper
          }
        }}
        anchorEl={achorElem.current}
        onClose={handleOnClose}
      >
        <LodgementDatepicker //
          initLodgementDate={lodgementDate}
          isUpdating={isUpdating}
          onCancel={handleOnClose}
          onChange={handleOnChange}
        />
      </Popover>
    </>
  );

  return <Box className={classes.root}>{lodgementDateTimeSection}</Box>;
}

export default React.memo(LodgementDateTime);
