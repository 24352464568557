import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { VicNameChange } from '@sympli-mfe/document-components/party-form/vic/2-25/components/party-justification';
import { PartyModel, RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { FormTenancyDetailModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { mergeArrays } from '@sympli-mfe/document-forms-framework/utils';

import { ApiMortgage2_25_1Model, EMPTY_MORTGAGOR_PARTY, Mortgage2_25_1Model, TitleReferenceModel } from './models';

export const ADDITIONAL_COVENANT_WARNING_VALUES: string[] = ['trust', 'trustee', 'beneficial', 'benefitial', 'benefisial', 'beneficiary', ' benefitiary'];

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const filterMortgagors = (values: ApiMortgage2_25_1Model): TenancyPartyModel[] =>
  mergeArrays(
    getSelectedTitleReferences(values.titleReferences).map(x => x.mortgagors),
    'partyBookId'
  ) as TenancyPartyModel[];

export const getMortgagorPartyBookIds = defaultMemoize((mortgagors: FormTenancyDetailModel) => {
  return _uniq(mortgagors.proprietorGroups.flatMap(pg => pg.parties).map(p => p.partyBookId));
});

const getMortgagors = defaultMemoize((formModel: Mortgage2_25_1Model) => {
  const mortgagorPartyBookIds = getMortgagorPartyBookIds(formModel.mortgagors);
  return formModel.partyBook.filter(party => mortgagorPartyBookIds.includes(party.id));
});

const getMortgagorsWithNameChange = defaultMemoize((mortgagorsLinkedToSelectedTitles: PartyModel<VicNameChange>[]) => {
  return mortgagorsLinkedToSelectedTitles.filter(mortgagor => {
    const relinquishingDetails = mortgagor.receivingOrRelinquishingDetails as RelinquishingModel<VicNameChange>;
    return relinquishingDetails.isChangingName;
  });
});

const getMortgagorsWithoutNameChange = defaultMemoize((mortgagorsLinkedToSelectedTitles: PartyModel<VicNameChange>[]) => {
  return mortgagorsLinkedToSelectedTitles.filter(mortgagor => {
    const relinquishingDetails = mortgagor.receivingOrRelinquishingDetails as RelinquishingModel<VicNameChange>;
    return !relinquishingDetails.isChangingName;
  });
});

const getPartyName = (organisationName: string, isSingleName: boolean, firstName: string, lastName = ''): string => {
  if (organisationName) {
    return organisationName.trim();
  } else {
    return isSingleName ? firstName.trim() : `${firstName.trim()} ${lastName.trim()}`;
  }
};

export const isMortgagorNamesMatchesOrJustified = defaultMemoize((formModel: Mortgage2_25_1Model): boolean => {
  const selectedTitles = getSelectedTitleReferences(formModel.titleReferences);
  if (selectedTitles.length <= 1) return true;

  const mortgagorsLinkedToSelectedTitles = getMortgagors(formModel);

  const uniqueNamesCount = _uniq(mortgagorsLinkedToSelectedTitles.map(mortgagor => mortgagor.legalEntityName)).length ?? 0;
  const mortgagorCount = selectedTitles[0].mortgagors.length;
  // Check if a justification is needed
  if (uniqueNamesCount <= mortgagorCount) return true;

  // check if someone has name justification
  return !!getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles).length;
});

export const checkIfJustifiedPartiesAreTheSame = defaultMemoize((formModel: Mortgage2_25_1Model): boolean => {
  const selectedTitles = getSelectedTitleReferences(formModel.titleReferences);
  if (!selectedTitles.length) return false;

  const mortgagorsLinkedToSelectedTitles = getMortgagors(formModel);

  const mortgagorsWithNameChange = getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles);
  const justifications = mortgagorsWithNameChange.map(mortgagor => mortgagor.receivingOrRelinquishingDetails) as RelinquishingModel<VicNameChange>[];
  let justifiedMortgagorsNames: string[] = [];

  // Check if more than one mortgage name change is the same
  return justifications.some(justification => {
    const { organisationName, isSingleName, firstName, lastName } = justification.nameChange!;
    const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
    if (justifiedMortgagorsNames.find(x => x === justifiedName)) {
      return true;
    }
    justifiedMortgagorsNames.push(justifiedName);
    return false;
  });
});

export const checkIfMergedPartiesHaveMismatchingPartyType = defaultMemoize((formModel: Mortgage2_25_1Model): number | null => {
  const selectedTitles = getSelectedTitleReferences(formModel.titleReferences);
  if (!selectedTitles.length) return null;

  const mortgagorsLinkedToSelectedTitles = getMortgagors(formModel);

  const mortgagorsWithNameChange = getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles);
  if (!mortgagorsWithNameChange.length) return null;

  const mortgagorsWithoutNameChange = getMortgagorsWithoutNameChange(mortgagorsLinkedToSelectedTitles);
  if (!mortgagorsWithoutNameChange.length) return null;

  for (const mortgagorWithNameChange of mortgagorsWithNameChange) {
    const { nameChange } = mortgagorWithNameChange.receivingOrRelinquishingDetails as RelinquishingModel<VicNameChange>;
    const { organisationName, isSingleName, firstName, lastName } = nameChange!;
    const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();

    for (const mortgagorWithoutNameChange of mortgagorsWithoutNameChange) {
      const { organisationName, isSingleName, firstName, lastName } = mortgagorWithoutNameChange;
      const partyName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
      if (justifiedName === partyName && mortgagorWithNameChange.partyType !== mortgagorWithoutNameChange.partyType) {
        return mortgagorWithoutNameChange.partyType;
      }
    }
  }

  return null;
});

/**
 *
 * @param parties tenants in the group
 * @param groupId defaults to 1 when only one group can exist in mortgagors section
 * @returns converted form model
 */
export function createFormMortgagors(parties: TenancyPartyModel[], groupId: string = '1'): FormTenancyDetailModel {
  return {
    shareSplitType: null,
    proprietorGroups: [
      {
        parties: parties.map(p => ({ ...p, id: p.partyBookId! })),
        shareQuantity: null,
        id: groupId
      }
    ]
  };
}

function getCustomMortgagors(customParties: PartyModel<VicNameChange, {}>[], formModel: Mortgage2_25_1Model) {
  const customMortgagors = customParties.filter(p => formModel.mortgagors.proprietorGroups.flatMap(pg => pg.parties).some(m => m.partyBookId === p.id));
  return customMortgagors.map(p => ({ id: p.id!, partyBookId: p.id! }));
}

export function generateMortgagors(formModel: Mortgage2_25_1Model): Mortgage2_25_1Model {
  const customParties = formModel.partyBook.filter(party => party.metadata?.source === DataSource.Custom || party.metadata?.source === DataSource.Subscriber);
  const customMortgagors = getCustomMortgagors(customParties, formModel);

  const mortgagors = formModel.titleReferences
    .filter(t => t.isSelected)
    .flatMap(t => {
      if (t.hasTransfer) return formModel.transferees;
      return t.mortgagors;
    })
    .concat(customMortgagors);

  const uniqueParties = Array.from(
    mortgagors
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.partyBookId!)) entryMap.set(e.partyBookId!, e);
        return entryMap;
      }, new Map<string, TenancyPartyModel>())
      .values()
  );

  const partyBook = formModel.partyBook.map(party => ({
    ...party,
    receivingOrRelinquishingDetails:
      party.metadata?.source === DataSource.Title || party.metadata?.source === DataSource.Transfer
        ? { ...party.receivingOrRelinquishingDetails, isRelinquishing: true, isChangingName: false }
        : party.receivingOrRelinquishingDetails
  }));

  return {
    ...formModel,
    partyBook,
    mortgagors: createFormMortgagors(uniqueParties.length ? uniqueParties : [EMPTY_MORTGAGOR_PARTY])
  };
}
