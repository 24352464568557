import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Action } from 'typesafe-actions';

import { Store } from 'src/reducers';
import useSafeDispatch, { SafeDispatch } from './useSafeDispatch';
import useStoreSelector from './useStoreSelector';

/**
 * dispatch redux action and listens for changes in reducer
 */
export default function useRequestReduxDataOnMount<T>(action: Action, selector: (rootReducer: Store) => T): [T, SafeDispatch<any>] {
  const safeDispatch = useSafeDispatch(useDispatch());

  React.useLayoutEffect(() => {
    safeDispatch(action);
    // we explicitly don't list any dependencies as we want to run it only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slice = useStoreSelector<T>(selector);
  return [slice, safeDispatch];
}
