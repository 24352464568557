import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { LodgementInstructions_2_25_1 } from './models';
import { VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL } from './visibilityChecks';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<LodgementInstructions_2_25_1> = {
  workspaceTitleReferences: NO_FALLBACK,
  documentFees: NO_FALLBACK,
  lodgementCaseFeeDetail: NO_FALLBACK,
  lodgementCaseFeeTotal: NO_FALLBACK,
  lodgementDocumentSummaries: NO_FALLBACK,
  responsibleSubscriber: NO_FALLBACK,
  responsibleParticipantId: NO_FALLBACK,
  participants: NO_FALLBACK,
  isResponsibleParticipantChangeable: NO_FALLBACK,
  justifications: NO_FALLBACK,
  isResponsibleParticipant: NO_FALLBACK,
  cordConsentRequired: NO_FALLBACK,
  partyIssued: NO_FALLBACK,
  issuingInstructions: NO_FALLBACK,
  directionPreference: NO_FALLBACK,
  lodgementOrderSummary: {
    $children: {
      isDirty: NO_FALLBACK,
      lodgementOrders: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            order: NO_FALLBACK,
            document: {
              $children: {
                documentId: [''],
                documentName: [''],
                documentIdentifier: [''],
                clients: {
                  $shouldAddDefaultItemToEmptyArray: true,
                  $arrayItem: {
                    $children: {
                      role: [''],
                      clientName: ['']
                    }
                  }
                },
                titleReferences: {
                  $shouldAddDefaultItemToEmptyArray: true,
                  $arrayItem: ['']
                },
                supportingDocuments: {
                  $shouldAddDefaultItemToEmptyArray: true,
                  $arrayItem: {
                    $children: {
                      documentType: ['']
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  dealingRequirementsSummary: {
    $children: {
      hasAddedDealingRequirements: [false],
      dealingRequirementsDetail: {
        $arrayVisibility: [VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL, []],
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            dealingRequirement: [null]
          }
        }
      }
    }
  },
  titleReferences: NO_FALLBACK,
  dealingRequirementDefinitions: NO_FALLBACK,
  workspaceType: NO_FALLBACK,
  issuingPartyDirection: NO_FALLBACK
};
