import { LandRegistryDetail } from '@sympli/api-gateway/shared';

import { DealingModel } from '../../models';

interface DocumentTypeMap {
  type: string;
  label: string;
}

const mortgage: DocumentTypeMap = { type: 'Mortgage', label: 'Mortgagee' };
const writ: DocumentTypeMap = { type: 'Writ', label: 'Judgement creditor' };
const caveat: DocumentTypeMap = { type: 'Caveat', label: 'Caveator' };
const subLease: DocumentTypeMap = { type: 'Sublease', label: 'Sublessee' };

const partyLabelMappings = new Map<string, DocumentTypeMap>([
  ['MORTGAGE', mortgage],
  ['MORTGAGE OF ESTATE', mortgage],
  ['WRIT', writ],
  ['WRIT AFFECTING ESTATE', writ],
  ['CAVEAT', caveat],
  ['CAVEAT AFFECTING ESTATE', caveat],
  ['CHARGE', { type: 'Charge', label: 'Chargee' }],
  ['LEASE', { type: 'Lease', label: 'Lessee' }],
  ['SUBLEASE', subLease],
  ['SUB-LEASE', subLease],
  ['PRIORITY NOTICE', { type: 'Priority Notices', label: 'Applicant' }]
]);

export default class Dealings {
  readonly registered: DealingModel[];
  readonly unregistered: DealingModel[];

  constructor(ris: LandRegistryDetail) {
    this.registered = this.getRegistered(ris);
    this.unregistered = this.getUnregistered(ris);
  }

  private getRegistered(ris: LandRegistryDetail): DealingModel[] {
    const registeredDealings: DealingModel[] = (ris.constraintAffectingEstates ?? []).concat(ris.interestInLands ?? []).map(e => {
      //ToDo: Start - fix unexpected data from backend
      e.registryDocument.documentType = e.registryDocument.documentType ?? e.type;
      e.parties = e.parties ?? [];
      //End - fix unexpected data from backend

      const mapping = partyLabelMappings.get(e.registryDocument.documentType.toUpperCase());
      return {
        documentType: mapping?.type ?? e.registryDocument.documentType,
        dealingNumber: e.registryDocument.dealingNumber,
        partyName: e.parties.map(p => p.legalEntityName).join(', '),
        partyLabel: mapping?.label ?? 'Party',
        registeredDate: e.registryDocument.registeredDate,
        expiryDate: e.registryDocument.expiry
      };
    });
    return registeredDealings;
  }

  private getUnregistered(ris: LandRegistryDetail): DealingModel[] {
    const unregisteredDealings: DealingModel[] = (ris.unregisteredDocuments ?? []).reduce((result, e) => {
      const document = e.unregisteredRegistryInstrument ?? e.unregisteredRegistryPlan?.registryDocument;
      if (document) {
        const dealingModel: DealingModel = {
          documentType: document.documentType,
          dealingNumber: document.dealingNumber,
          registeredDate: document.registeredDate
        };
        result.push(dealingModel);
      }
      return result;
    }, new Array<DealingModel>());

    return unregisteredDealings;
  }
}
