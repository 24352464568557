// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title, ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...rest}>
      {title && <title>{title}</title>}
      <defs>
        <path
          d="M10 2.25c1.396 0 2.687.349 3.875 1.047a7.831 7.831 0 0 1 2.828 2.828A7.513 7.513 0 0 1 17.75 10a7.513 7.513 0 0 1-1.047 3.875 7.831 7.831 0 0 1-2.828 2.828A7.513 7.513 0 0 1 10 17.75a7.513 7.513 0 0 1-3.875-1.047 7.831 7.831 0 0 1-2.828-2.828A7.513 7.513 0 0 1 2.25 10c0-1.396.349-2.687 1.047-3.875a7.831 7.831 0 0 1 2.828-2.828A7.513 7.513 0 0 1 10 2.25zM9.5 12.5v-5a.487.487 0 0 0-.14-.36A.487.487 0 0 0 9 7H7.5a.487.487 0 0 0-.36.14.487.487 0 0 0-.14.36v5c0 .146.047.266.14.36.094.093.214.14.36.14H9a.487.487 0 0 0 .36-.14.487.487 0 0 0 .14-.36zm3.5 0v-5a.487.487 0 0 0-.14-.36.487.487 0 0 0-.36-.14H11a.487.487 0 0 0-.36.14.487.487 0 0 0-.14.36v5c0 .146.047.266.14.36.094.093.214.14.36.14h1.5a.487.487 0 0 0 .36-.14.487.487 0 0 0 .14-.36z"
          id="icon-suspend_svg__a"
        />
      </defs>
      <use fillRule="nonzero" xlinkHref="#icon-suspend_svg__a" transform="translate(-2 -2)" />
    </svg>
  );
};
