import * as yup from 'yup';

import { DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { EmptyContext, memoizeSchema, memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import {
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS_COVENANTS,
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER,
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT
} from '../../isRequiredChecks';
import { StandardTermsDealingNumbersModel, StandardTermsModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: termsAndConditions
export const COVENANTS_MAX_CHARACTERS: number = 4000;
export const MAX_SUPPORTING_DOCUMENT_SIZE: number = 1;
export const MAX_TERM_NUMBERS: number = 1;
interface StandardTermsDealingNumbersContext {
  termsAndConditions: StandardTermsModel;
}

const yupStDealingNumberItem = yup
  .object<StandardTermsDealingNumbersModel, StandardTermsDealingNumbersContext>({
    termNumber: yup
      .string()
      .default('')
      .trim()
      .typeError(msg.INVALID_VALUE)
      .max(9, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(9))
      .testContextualRule({
        uniqueTestName: '"termsAndConditions.standardTermsDealingNumber" contextual validation rule #1',
        onlyIf: (parent: StandardTermsDealingNumbersModel, context: StandardTermsDealingNumbersContext) =>
          REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER(parent, context.termsAndConditions),
        requirement: (parent: StandardTermsDealingNumbersModel /*, context: StandardTermsDealingNumbersContext*/): boolean => Boolean(parent.termNumber),
        message: msg.REQUIRED
      })
    // .test(
    //   testContextualRule({
    //     uniqueTestName: '"termsAndConditions.standardTermsDealingNumber" contextual validation rule #1',
    //     onlyIf: REQUIRED_TERMS_AND_CONDITIONS_DEALING_NUMBERS,
    //     requirement: (value?: string): boolean => Boolean(value),
    //     message: msg.REQUIRED
    //   })
    // )
  })
  .defined()
  .log();

const yupTermsAndConditions = memoizeSchema(
  yup
    .object<StandardTermsModel, EmptyContext>({
      standardTermsDealingNumbers: memoizeSchemaWithContext(
        yup
          .array<StandardTermsDealingNumbersModel, StandardTermsDealingNumbersContext>()
          .defined()
          // .test(
          //   testContextualRule({
          //     /**
          //      * what we found in schema:
          //      [{"required":{"standardTermsDealingNumbers":{"$items":{"$oneOf":{"termNumber":{"$not":""}}}}},"message":"Either a covenant or a standard term must be present","onlyIf":{"additionalCovenants":{"$items":{"$allOf":{"covenant":""}}}}}]
          //      *
          //      */
          //     uniqueTestName: '"termsAndConditions.standardTermsDealingNumbers" contextual validation rule #1',
          //     onlyIf: (context: StandardTermsModel): boolean => REQUIRED_TERMS_AND_CONDITIONS_DEALINGNUMBER(context),
          //     requirement: (standardTermsDealingNumbers: StandardTermsDealingNumbersModel[], context: object): boolean =>
          //       Boolean(standardTermsDealingNumbers.find(d => !!d.termNumber)),
          //     message: msg.REQUIRED
          //   })
          // )
          .max(20, msg.MAX_ITEMS(20))
          .of(yupStDealingNumberItem)
          .log(), //
        (parentContext: EmptyContext, parentValue: StandardTermsModel): StandardTermsDealingNumbersContext => ({
          termsAndConditions: parentValue
        })
      ),
      warning: yup.string(),
      additionalCovenants: yup
        .string()
        .default('')
        .trim()
        .typeError(msg.INVALID_VALUE)
        .max(
          COVENANTS_MAX_CHARACTERS,
          ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(COVENANTS_MAX_CHARACTERS)}. ${value.length}/${max} characters (including spaces and formatting)`
        )
        .testContextualRule({
          /**
         * what we found in schema:
         [{"required":{"additionalCovenants":{"$items":{"$oneOf":{"covenant":{"$not":""}}}}},"message":"Either a covenant or a standard term must be present","onlyIf":{"standardTermsDealingNumbers":{"$items":{"$allOf":{"termNumber":""}}}}}]
         *
         */
          uniqueTestName: '"termsAndConditions.additionalCovenants" contextual validation rule #1',
          onlyIf: (parent: StandardTermsModel /*, context: EmptyContext*/): boolean => REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS_COVENANTS(parent),
          requirement: (parent: StandardTermsModel /*, context: EmptyContext*/): boolean => Boolean(parent.additionalCovenants),
          message: msg.REQUIRED
        }),
      // .test(
      //   testContextualRule({
      //     /**
      //      * what we found in schema:
      //      [{"required":{"additionalCovenants":{"$items":{"$oneOf":{"covenant":{"$not":""}}}}},"message":"Either a covenant or a standard term must be present","onlyIf":{"standardTermsDealingNumbers":{"$items":{"$allOf":{"termNumber":""}}}}}]
      //      *
      //      */
      //     uniqueTestName: '"termsAndConditions.additionalCovenants" contextual validation rule #1',
      //     onlyIf: (context: StandardTermsModel): boolean => REQUIRED_TERMS_AND_CONDITIONS_ADDITIONAL_COVENANTS(context),
      //     requirement: (additionalCovenants: string, context: object): boolean => Boolean(additionalCovenants),
      //     message: msg.REQUIRED
      //   })
      // ),
      supportingDocuments: memoizeSchema(
        yup
          .array<DocumentAttachmentModel, EmptyContext>()
          .defined()
          .max(MAX_SUPPORTING_DOCUMENT_SIZE, msg.MAX_ITEMS(MAX_SUPPORTING_DOCUMENT_SIZE))
          .of(
            yup
              .object<DocumentAttachmentModel, EmptyContext>({
                id: yup.string(),
                fileName: yup.string(),
                fileType: yup.string()
              })
              .defined()
              .log()
          )
          .testContextualRule({
            uniqueTestName: '"termsAndConditions.supportingDocuments" contextual validation rule #1',
            onlyIf: (parent?: StandardTermsModel /*, context: EmptyContext*/): boolean =>
              Boolean(parent && REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT(parent)),
            requirement: (parent: StandardTermsModel /*, context: EmptyContext*/): boolean => Boolean(parent.supportingDocuments.length),
            message: msg.REQUIRED
          })
          .log()
      )
      // .test(
      //   testContextualRule({
      //     uniqueTestName: '"termsAndConditions.supportingDocuments" contextual validation rule #1',
      //     onlyIf: (context: StandardTermsModel): boolean => REQUIRED_TERMS_AND_CONDITIONS_SUPPORTING_DOCUMENTS(context),
      //     requirement: (supportingDocuments: DocumentAttachmentModel[], context: object): boolean => Boolean(supportingDocuments.length),
      //     message: msg.REQUIRED
      //   })
      // )
    })
    .defined()
    .log()
);

export default yupTermsAndConditions;
