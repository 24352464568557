import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import Grid from '@mui/material/Grid';

import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { HoldingAccountDetailsModel } from 'src/containers/workspace/financial/directions/models';
import { createModelKeyAppender, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { ConditionalDistributionModel } from '../../../../../components/direction-record/models';
import { DischargeDirectionsFormModel } from '../../../models';
import { EditBankDetailsProps } from './EditBankDetails';
import { CommonProps } from './models';
import { useStyles } from './styles';

interface LoanPayoutEditBankDetailsProps {
  trustAccountOptions: EditBankDetailsProps['trustAccountOptions'];
  financialAccounts?: EditBankDetailsProps['financialAccounts'];
  itemFieldName: EditBankDetailsProps['itemFieldName'];
  formikProps: EditBankDetailsProps['formikProps'];
  isLoading: EditBankDetailsProps['isLoading'];
  disableSave: boolean;
  onUpdate: CommonProps['onUpdate'];
}

function LoanPayoutEditBankDetails(props: LoanPayoutEditBankDetailsProps) {
  const {
    //
    formikProps,
    itemFieldName,
    trustAccountOptions,
    isLoading,
    disableSave,
    onUpdate
  } = props;
  const classes = useStyles();

  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);
  const holdingAccountDetailsFieldName = createModelKeyAppender<HoldingAccountDetailsModel>(fieldName('holdingAccountDetails'));
  const filteredTrustAccountOptions = trustAccountOptions.filter(option => option.canUseAsLoanPayout);
  const isSingleAccount = filteredTrustAccountOptions.length === 1; //WEB-17440: As Discharging Mortgagee, I want the Loan Payout account name to be pre-populated and greyed out if there is only 1 loan account

  const handleOnClick = (formikProps: FormikProps<DischargeDirectionsFormModel>, itemFieldName: string) => {
    const { setFieldValue } = formikProps;
    setFieldValue(fieldName('isEditorOpen'), false);
    onUpdate(itemFieldName, formikProps, true);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field //
            label="Account"
            component={SelectField}
            className={classes.fullWidth}
            placeholder={resolveSelectPlaceholder(true)}
            name={holdingAccountDetailsFieldName('accountId')}
            options={filteredTrustAccountOptions}
            disabled={isSingleAccount}
          />
        </Grid>
        <Grid item xs={4}>
          <Field //
            label="Reference (optional)"
            component={InputField}
            name={holdingAccountDetailsFieldName('reference')}
            className={classes.fullWidth}
          />
        </Grid>
        <Grid item xs={2}>
          <Field //
            label="Amount ($)"
            name={fieldName('amount')}
            component={CurrencyInputField}
            className={classNames(classes.fullWidth, classes.labelCenter)}
          />
        </Grid>
      </Grid>
      <SympliButton //
        className={classes.saveButton}
        color="primary"
        variant="contained"
        onClick={_ => handleOnClick(formikProps, itemFieldName)}
        isLoading={isLoading}
        disabled={disableSave || isLoading}
      >
        Update
      </SympliButton>
    </React.Fragment>
  );
}

export default LoanPayoutEditBankDetails;
