import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      marginLeft: 'auto'
    },
    fileName: {
      fontSize: 13,
      color: theme.palette.text.primary,
      fontWeight: 500
    },
    buttonText: {
      fontSize: 13,
      textDecoration: 'underline',
      color: theme.palette.text.primary,
      fontWeight: 500,
      whiteSpace: 'nowrap'
    },
    paperclipIcon: {
      fill: theme.palette.primary.main,
      marginRight: 5
    },
    spinner: {
      marginLeft: 5
    },
    tooltip: {
      width: 'auto',
      maxWidth: 450,
      marginRight: -14
    },
    dropDownIcon: {
      marginLeft: 5
    }
  }),
  {
    name: 'DocumentAttachments'
  }
);
