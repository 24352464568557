import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS, AttachmentTypeEnum } from '@sympli-mfe/enums-2-26/wa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache
// only subset of NECDS lookup options
export const WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<string>[] = restrictedLookupOptions(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS, {
  whitelist: [
    AttachmentTypeEnum.AustralianSecuritiesInvestmentCommissionCertificate,
    AttachmentTypeEnum.ChangeOfNameCertificate,
    AttachmentTypeEnum.CitizenshipCertificate,
    AttachmentTypeEnum.ConsentLetter,
    AttachmentTypeEnum.CourtOrder,
    AttachmentTypeEnum.MarriageCertificate,
    AttachmentTypeEnum.Other,
    AttachmentTypeEnum.StateAdministrativeTribunalOrder,
    AttachmentTypeEnum.StatutoryDeclaration
  ]
});
