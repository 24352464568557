import {
  DocumentLodgementStatusEnum,
  DocumentStatusEnum,
  DocumentTypeIdentifierEnum,
  ParticipantStatusEnum,
  WorkspaceRoleEnum,
  WorkspaceStatusEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { UserAvatarModel } from 'src/components/avatars/multi-user-avatar';
import { DocumentWorkflowModel } from 'src/containers/documents/models';

export interface RouteParams {
  workspaceId: string;
  participantId: string;
  documentId?: string;
}

// export interface DocumentParticipant extends InvitedParticipantApiResponse {
//   documentStatus: DocumentStatusEnum;
//   isPrimaryDocumentParticipant: boolean;
//   documentWorkflow: DocumentWorkflowModel;
// }
export type DocumentParticipant = {
  documentStatus: DocumentStatusEnum;
  isPrimaryDocumentParticipant: boolean;
  documentWorkflow: DocumentWorkflowModel;
} & WorkspaceParticipantApiResponse;

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.WorkspaceDocumentSummary
 */
// export interface DocumentApiResponse extends BaseDocumentModel {
//   supportingDocuments?: BaseDocumentModel[];
// }
// export interface BaseDocumentModel {
//   documentId: string;
//   documentStatus: LookupEnumModel<DocumentStatusEnum>;
//   name: string;
//   documentParticipants: DocumentParticipant[];
//   certificationStatements: string[];
//   documentFormId: string;
//   documentFormVersion: string;
//   documentIdentifier: LookupEnumModel<DocumentTypeIdentifierEnum>;
//   lodgementDetail?: DocumentLodgementDetail;
//   permissions: DocumentPermissionEnum[];
//   documentDenyPermissions: DocumentDenyPermissionModel[];
//   requiredSigners: number;
//   documentActions: Array<DocumentActionModel>;
//   hasDealingRequirements?: boolean | null;
//   jurisdiction: LookupEnumModel<JurisdictionsEnum>;
//   dealingNumber?: string;
//   isFullySignedByAllParticipants: boolean;
//   isSigningRole: boolean;
// }
// MR - svc moved
// export interface DocumentLodgementDetail {
//   status?: LookupEnumModel<DocumentLodgementStatusEnum>;
//   lrDocumentId?: string;
//   documentLodgementCompliances: DocumentLodgementCompliance[];
// }
// MR - svc moved
// export interface DocumentLodgementCompliance {
//   complianceMessage: string;
//   complianceLevel: ComplianceLevelEnum;
// }

export type DocumentLodgementDetail = Exclude<WorkspaceDocumentSummaryApiResponse['lodgementDetail'], undefined>;
export type DocumentLodgementCompliance = Exclude<DocumentLodgementDetail['documentLodgementCompliances'][number], 'undefined'>;

// * the display order of the document participants ordered by role in the document list
// * this is a general order for transfer, and lease document, add potential role order for future documents here
// * this general order should hold long enough
export const documentParticipantOrderedList: WorkspaceRoleEnum[] = [
  //
  WorkspaceRoleEnum.Purchaser,
  WorkspaceRoleEnum.Vendor,
  WorkspaceRoleEnum.Lessee,
  WorkspaceRoleEnum.Lessor
];

// the display order of the workspace participants ordered by role in the all distributions list and participants list
export const generalWorkspaceRoleOrderedList: WorkspaceRoleEnum[] = [
  //
  WorkspaceRoleEnum.ControllingParty,
  WorkspaceRoleEnum.DischargingCaveator,
  WorkspaceRoleEnum.DischargingMortgagee,
  WorkspaceRoleEnum.Vendor,
  WorkspaceRoleEnum.Purchaser,
  WorkspaceRoleEnum.IncomingMortgagee,
  WorkspaceRoleEnum.Lessee,
  WorkspaceRoleEnum.Lessor,
  WorkspaceRoleEnum.Beneficiary,
  WorkspaceRoleEnum.IncomingCaveator,
  WorkspaceRoleEnum.ToDealWithAnInterest,
  WorkspaceRoleEnum.SourceFunder
];

export const SEALED_WORKSPACE_STATUSES: WorkspaceStatusEnum[] = [
  //
  WorkspaceStatusEnum.Success,
  WorkspaceStatusEnum.SettlementInProgress,
  WorkspaceStatusEnum.Finalised,
  WorkspaceStatusEnum.Archived,
  WorkspaceStatusEnum.Abandoned,
  WorkspaceStatusEnum.Withdrawn,
  WorkspaceStatusEnum.LodgementInQueue
];

export enum DocumentListItemStatusEnum {
  Reorder = 'REORDER',
  Delete = 'DELETE',
  Unknown = 'UNKNOWN', // special status where we don't know what to do
  InDraft = 'IN_DRAFT',
  AwaitingInviteAcceptance = 'AWAITING_INVITE_ACCEPTANCE',
  Edit = 'EDIT',
  AwaitingEditFromOthers = 'AWAITING_EDIT_FROM_OTHERS',
  ReadyToApprove = 'READY_TO_APPROVE',
  Approved = 'APPROVED',
  AwaitingApproveFromOthers = 'AWAITING_APPROVE_FROM_OTHERS',
  ReadyToSign = 'READY_TO_SIGN',
  Signed = 'SIGNED',
  VerificationInProgress = 'LV_IN_PROGRESS',
  LodgementInQueue = 'LODGEMENT_IN_QUEUE',
  LodgementInProgress = 'LODGEMENT_IN_PROGRESS',
  Lodged = 'LODGED',
  ResolveIssues = 'RESOLVE_ISSUE',
  IssuesFound = 'ISSUES_FOUND'
}

/**
 * participantMode defines what level of access user has to given document
 * - primary - user can edit, review, sign
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-with-edit-document-permission
 *
 * - non-primary - user can review and sign
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-who-DOES-NOT-have-edit-document-permission
 *
 * - ws-only - user can only see document after it has been approved
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-workspace-participants-not-listed-as-document-participants
 *
 * default: ws-only
 */
export type DocumentListItemParticipantMode = 'primary' | 'non-primary' | 'ws-only';
export type DocumentItemVisualMode = 'secondary' | 'info' | 'primary';
export type DocumentListAction = 'default' | 'add' | 'reorder' | 'delete';

export interface ConsolidationModel {
  documentType: WorkspaceDocumentSummaryApiResponse['documentIdentifier']; // LookupEnumModel<DocumentTypeIdentifierEnum>;
  documentStatusId: WorkspaceDocumentSummaryApiResponse['documentStatus']['id']; // DocumentStatusEnum;
  documentActions: WorkspaceDocumentSummaryApiResponse['documentActions']; // DocumentActionModel[];
  documentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; //DocumentParticipant[]; // all document participants
  primaryDocumentParticipants: WorkspaceDocumentSummaryApiResponse['documentParticipants']; // DocumentParticipant[]; // primary only
  currentDocumentParticipant?: WorkspaceDocumentSummaryApiResponse['documentParticipants'][number]; // current document participant resolved based on query parameter
  numberOfRequiredSigners: number;
  lrDocumentId?: string;
  jurisdiction?: WorkspaceDocumentSummaryApiResponse['jurisdiction']['id']; // JurisdictionsEnum;
  hasDealingRequirements?: WorkspaceDocumentSummaryApiResponse['hasDealingRequirements']; // boolean | null;
  documentLodgementStatus?: LookupEnumModel<DocumentLodgementStatusEnum>;
  lodgementDateTime?: WorkspaceDateTimeModel;
  workspaceParticipants?: WorkspaceParticipantApiResponse[];
  isCriticalRolesEnabled: boolean;
}

export interface DocumentListParticipant extends UserAvatarModel {
  role: string;
  badge: JSX.Element;
  canJoin: boolean;
  participantStatusId: ParticipantStatusEnum;
}

export interface LodgementCaseDetail {
  lodgementCase?: LodgementCase;
  documents: DocumentListModel[];
}

export interface DocumentListModel {
  documentId: string;
  documentIdentifier: DocumentTypeIdentifierEnum;
  uiStatus: DocumentListItemStatusEnum;
  description: React.ReactNode;
  visualMode: DocumentItemVisualMode;
  linkTo?: string;
  isLI: boolean;
  name: string;
  icon?: JSX.Element;
  participants: DocumentListParticipant[];
  selected: boolean;
  disabled: boolean;
  supportingDocuments: DocumentListModel[];
  canRemove: boolean;
  isFullySignedByAllParticipants: boolean;
  className?: string;
}

export enum DocumentStatusDisplayEnum {
  ToBeInvited = 'TO BE INVITED',
  Invited = 'INVITED',
  InPreparation = 'IN PREPARATION',
  Approved = 'APPROVED',
  Signed = 'SIGNED',
  PartiallySigned = 'PARTIALLY SIGNED',
  Lodging = 'LODGING',
  Lodged = 'LODGED',
  NA = 'N/A'
}

export const DocumentStatusSteps: string[] = [DocumentStatusDisplayEnum.InPreparation, DocumentStatusDisplayEnum.Approved, DocumentStatusDisplayEnum.Signed];

export const SLCDocumentLists: Array<DocumentTypeIdentifierEnum> = [
  DocumentTypeIdentifierEnum.PriorityNotice,
  DocumentTypeIdentifierEnum.ExtensionOfPriorityNotice,
  DocumentTypeIdentifierEnum.WithdrawalOfPriorityNotice,
  DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase
];
