import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    // warning message
    title: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 'bold',
      padding: '0 0 0 8px'
    }
  }),
  { name: 'SourceFundMessage' }
);
