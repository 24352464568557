import * as React from 'react';

import classNames from 'classnames';
import FormHelperText from '@mui/material/FormHelperText';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import FormGroup, { FormGroupClassKeys, FormGroupProps } from '@sympli/ui-framework/components/form/layout/form-group';

import styles, { ClassKeys } from './styles';

interface OwnProps extends FormGroupProps {
  variant: 'root' | 'nested';
  error?: string;
  topBorder?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class FormPropertyGroup extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    topBorder: false
  };

  private get formGroupClasses() {
    const { classes, error, variant } = this.props;
    let formGroupClasses: Partial<ClassNameMap<keyof ReturnType<FormGroupClassKeys>>> = {};
    if (variant === 'nested') {
      formGroupClasses = {
        container: classes.nestedFormGroupContainer,
        contentContainer: classes.nestedFormGroupContent
      };
    }

    if (error) {
      formGroupClasses.title = classes.errorText;
    }
    return formGroupClasses;
  }

  render() {
    const { title: _title, error: _error, topBorder, classes, children, className, variant, ...rest } = this.props;

    return (
      <FormGroup //
        title={this.renderTitle()}
        fieldAreaDirection="column"
        className={classNames(classes.root, topBorder && classes.topBorder, className)}
        classes={this.formGroupClasses}
        {...rest}
      >
        {children}
      </FormGroup>
    );
  }

  private renderTitle() {
    const { title, error } = this.props;

    if (error) {
      return (
        <React.Fragment>
          {title}
          <FormHelperText style={{ marginTop: 4 }} error>
            {error}
          </FormHelperText>
        </React.Fragment>
      );
    }

    return title;
  }

  // private renderRemoveBtn() {
  //   const { classes, onRemove, titleForRemove, isSimpleType, disableRemove } = this.props;

  //   if (!onRemove) {
  //     return null;
  //   }

  //   return (
  //     <IconButton
  //       disabled={disableRemove}
  //       color="primary"
  //       title={titleForRemove}
  //       onClick={this.handleOnRemove}
  //       className={classNames(classes.deleteButton, isSimpleType && 'simple')}
  //     >
  //       <IconDelete fill="currentColor" />
  //     </IconButton>
  //   );
  // }

  // private handleOnRemove = () => {
  //   this.props.onRemove(this.props.itemIndex);
  // };
}

export default withStyles(styles)(FormPropertyGroup);
