import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    root: {},
    headerLoaderContainer: {
      height: 42
    },
    headerLoader: {
      width: 256,
      height: 32
    },
    tab: {
      paddingTop: 8,
      paddingRight: 24,
      paddingBottom: 6,
      paddingLeft: 18,
      textDecoration: 'none',
      minWidth: 80
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    },
    participantBox: {
      width: 272,
      marginLeft: 60
    }
  },
  { name: 'WorkspaceDetailPageLoader' }
);
