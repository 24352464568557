import { createSelector } from 'reselect';

import { WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase } from '@sympli/api-gateway/shared';

import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';

// const getWorkspaceDetail = <T extends Pick<Store, 'workspaceDetail'>>(state: T) => state.workspaceDetail.detail;

// const getWorkspaceParticipants = <T extends Pick<Store, 'workspaceParticipants'>>(state: T) => state.workspaceParticipants.items;

export const findCurrentWorkspaceParticipantSelector = createSelector<
  {
    //
    participantId: string;
    workspaceParticipants: WorkspaceParticipantsState;
    lodgementCase?: LodgementCase;
  },
  string,
  WorkspaceParticipantApiResponse[],
  LodgementCase | undefined,
  WorkspaceParticipantApiResponse | undefined
>( //
  args => args.participantId,
  args => args.workspaceParticipants.items,
  args => args.lodgementCase,
  (currentParticipantId: string, participants: WorkspaceParticipantApiResponse[], lodgementCase?: LodgementCase) => {
    const currentParticipant = participants.find(participant => participant.id === currentParticipantId);
    if (!currentParticipant) {
      return;
    }
    return { ...currentParticipant, isResponsibleParticipant: currentParticipant.isResponsibleParticipant || lodgementCase?.responsibleParticipantId === currentParticipantId };
  }
);

export const isCurrentUserWorkspaceParticipantSelector = createSelector<
  {
    //
    participantId: string;
    workspaceParticipants: WorkspaceParticipantsState;
    workspaceTypeId?: WorkspaceTypeEnum;
    workspaceStatusId?: WorkspaceStatusEnum;
  },
  string,
  WorkspaceParticipantApiResponse[],
  WorkspaceTypeEnum | undefined,
  WorkspaceStatusEnum | undefined,
  boolean
>( //
  args => args.participantId,
  args => args.workspaceParticipants.items,
  args => args.workspaceTypeId,
  args => args.workspaceStatusId,
  (currentParticipantId: string, participants: WorkspaceParticipantApiResponse[], workspaceTypeId?: WorkspaceTypeEnum, workspaceStatusId?: WorkspaceStatusEnum) => {
    // in LO we can do the check much faster without waiting for list of workspace participants to be returned
    // user is always participant of the workspace unless they have abandoned it
    // atm we have always only one participant in the workspace,
    if (workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly && workspaceStatusId !== WorkspaceStatusEnum.Abandoned) {
      return true;
    }
    return participants.some(participant => participant.id === currentParticipantId);
  }
);

export const resolvedAssignedGroupNameSelector = createSelector<
  //
  {
    //
    workspaceAssignableGroups: GroupOptionModel[];
    participant?: WorkspaceParticipantApiResponse;
  },
  GroupOptionModel[],
  WorkspaceParticipantApiResponse | undefined,
  string | undefined
>(
  args => args.workspaceAssignableGroups,
  args => args.participant,
  (workspaceAssignableGroups: GroupOptionModel[], participant?: WorkspaceParticipantApiResponse) => {
    if (!participant || workspaceAssignableGroups.length < 1) {
      return undefined;
    }

    // it should always has the name
    const currentGroup = workspaceAssignableGroups.find(group => group.id === participant.groupId);
    return currentGroup?.name;
  }
);
