import * as yup from 'yup';

import { WA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/wa/2-19/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { getLookupValuesAllowingEmpty, memoizeSchema, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import resolveIbnValidation from 'src/containers/documents/scaffolded-form/wa/shared-validations/IbnValidation';
import {
  ISSUING_INSTRUCTION_LOOKUP_OPTIONS,
  ISSUING_METHOD_LOOKUP_OPTIONS,
  ISSUING_OFFICE_LOOKUP_OPTIONS,
  IssuingInstructionEnum,
  IssuingMethodEnum,
  IssuingOfficeEnum
} from '../../enums';
import { DuplicateHoldingDetailModel, DuplicateIssuingInstructionModel, IssuingPartyModel, LodgementInstructions2_19_1Model } from '../../models';
import {
  VISIBILITY_CHECK_EDITION_DATE,
  VISIBILITY_CHECK_ISSUING_BOX_NUMBER,
  VISIBILITY_CHECK_ISSUING_METHOD,
  VISIBILITY_CHECK_ISSUING_OFFICE,
  VISIBILITY_CHECK_ISSUING_PARTY,
  VISIBILITY_CHECK_VALIDATE_CT
} from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: duplicateHoldingDetail
const yupDuplicateHoldingDetail = memoizeSchema(
  yup //
    .array<DuplicateHoldingDetailModel>()
    .required()
    .of(
      yup
        .object<DuplicateHoldingDetailModel>({
          titleReference: yup.string().default('').trim(),
          editionNumber: yup
            .string()
            .default('')
            .trim()
            .required(msg.REQUIRED)
            .matches(/^([0-9]{1,4})\b/, msg.VALUE_MUST_BE_BETWEEN(0, 9999)),
          editionDate: validateWhenVisible2<Date | string | null>({
            visibilityCheck: (parent: DuplicateHoldingDetailModel) => VISIBILITY_CHECK_EDITION_DATE(parent),
            validationWhenVisible: yupDatePicker //
              .required(msg.REQUIRED)
          }),
          issuingInstructionDetails: yup.object<DuplicateIssuingInstructionModel>({
            issuingInstruction: yup
              .mixed<IssuingInstructionEnum>()
              .required(msg.REQUIRED)
              .oneOf(getLookupValuesAllowingEmpty(ISSUING_INSTRUCTION_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
            issuingParty: validateWhenVisible2<IssuingPartyModel>({
              visibilityCheck: (parent: DuplicateIssuingInstructionModel, _context: LodgementInstructions2_19_1Model) => {
                return VISIBILITY_CHECK_ISSUING_PARTY(null, parent, null, null, null);
              },
              validationWhenVisible: yup
                .object<IssuingPartyModel>({
                  partyBookId: yup.string().default('').required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(WA_PARTY_FORM_CONFIG)),
                  addressBookId: yup.string().default('').required(msg.REQUIRED)
                })
                .required(),
              isObjectOrArray: true,
              // Set to disableEarlyAbort to display both PartyBookId and AddressBookId validation messages
              disableEarlyAbort: true
            }),
            issuingMethod: validateWhenVisible2<number | null>({
              visibilityCheck: (parent: DuplicateIssuingInstructionModel, context: LodgementInstructions2_19_1Model) => {
                return VISIBILITY_CHECK_ISSUING_METHOD(parent, null, null, null);
              },
              validationWhenVisible: yup.mixed<IssuingMethodEnum>().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(ISSUING_METHOD_LOOKUP_OPTIONS), msg.INVALID_SELECTION)
            }),
            issuingOffice: validateWhenVisible2<number | null>({
              visibilityCheck: VISIBILITY_CHECK_ISSUING_OFFICE,
              validationWhenVisible: yup.mixed<IssuingOfficeEnum>().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(ISSUING_OFFICE_LOOKUP_OPTIONS), msg.INVALID_SELECTION)
            }),
            issuingBoxNumber: validateWhenVisible2<string>({
              visibilityCheck: VISIBILITY_CHECK_ISSUING_BOX_NUMBER,
              validationWhenVisible: resolveIbnValidation(true)
            })
          })
        })
        .defined()
    )
    .defined()
);

export default validateWhenVisible2<DuplicateHoldingDetailModel[]>({
  visibilityCheck: (parent: LodgementInstructions2_19_1Model) => VISIBILITY_CHECK_VALIDATE_CT(parent),
  validationWhenVisible: yupDuplicateHoldingDetail,
  isObjectOrArray: true
});
