import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    headerBox: {
      marginTop: 48,
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 8
    },
    divider: {
      marginTop: 5,
      marginBottom: 5
    }
  }),
  {
    name: 'DirectionRecordList'
  }
);
