import * as React from 'react';

import { useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ErrorBoundary from 'src/components/error-boundary';
import GlobalNotificationsContainer from 'src/containers/global-notifications';
import ProfileContextProvider from 'src/containers/shared/auth/profile-security/Profile.context.Provider';
import { RealtimeConnectionProvider } from 'src/socket-2.0';
import GlobalSimpleNotification from './components/global-simple-notification';
import AppCrashedPage from './containers/app-crashed-page';
import Router from './containers/router';
import { actionFetchProfile } from './containers/shared/auth/actions';
import { useProfile } from './containers/shared/auth/reducers';
import LoadingProfile from './containers/shared/loading-profile';

function AppContent({ performProfileFetch }: { performProfileFetch?: boolean }) {
  const dispatch = useDispatch();
  const { status: userProfileStatus } = useProfile();
  // this logic is here purely for smoke test
  // in normal scenario, the profile fetching is done in AuthContainer
  React.useEffect(() => {
    if (performProfileFetch) {
      dispatch(actionFetchProfile.request());
    }
  }, [dispatch, performProfileFetch]);

  if (performProfileFetch && (userProfileStatus === 'idle' || userProfileStatus === 'pending')) {
    return <LoadingProfile />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ErrorBoundary component={AppCrashedPage}>
        <ProfileContextProvider>
          <RealtimeConnectionProvider>
            <GlobalNotificationsContainer />
            <GlobalSimpleNotification />
            <Router />
          </RealtimeConnectionProvider>
        </ProfileContextProvider>
      </ErrorBoundary>
    </LocalizationProvider>
  );
}

export default React.memo(AppContent);
