import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { QldPartyForm_2_23 as PartyForm } from '@sympli-mfe/document-components/party-form/qld/2-23';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { QLD_DM_PARTY_FORM_CONFIG } from '../../config';
import { DischargeOfMortgage2_23_1Model, MortgageePartyModel } from '../../models';
import PartyCapacity from '../mortgagee-party-capacity/PartyCapacity';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
type FormValues = DischargeOfMortgage2_23_1Model;

interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class Mortgagee extends React.PureComponent<Props> {
  private readonly fieldName = createModelKeyAppender<MortgageePartyModel>(this.props.name);

  render() {
    const fieldName = this.fieldName;
    const { disabled, formikProps, name, classes } = this.props;
    const { values } = formikProps;
    const value: MortgageePartyModel = _get(values, name);
    const partyBook: PartyModel[] = values.partyBook ?? [];
    const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
    const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

    return (
      <div data-name={name} className={classes.root}>
        <Subform //
          subformBindingPath={partyBindingPath}
          component={PartyForm}
          disabled={disabled}
          showFormGroups={false}
          config={QLD_DM_PARTY_FORM_CONFIG}
        />
        <PartyCapacity name={fieldName('partyCapacity')} formikProps={formikProps} isReadonly />
      </div>
    );
  }
}

export default withStyles(styles)(Mortgagee);
