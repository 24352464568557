import * as React from 'react';

import { DocumentPreviewEnhanced } from 'src/components/document-preview';
import { useTrustAccountAuthorisationRecords } from '../../reducer';

interface Props {
  workspaceId: string;
  participantId: string;
  trustAccountId: string;
}

function TaaPreviewContainer({
  //
  workspaceId,
  participantId,
  trustAccountId
}: Props) {
  const { detail, error, status } = useTrustAccountAuthorisationRecords(workspaceId, participantId, trustAccountId);

  return (
    <DocumentPreviewEnhanced //
      pdfUrl={detail?.pdfUrl}
      error={error}
      status={status}
    />
  );
}

export default React.memo(TaaPreviewContainer);
