import * as React from 'react';

import pluralize from 'pluralize';
import Paper from '@mui/material/Paper';

import Alert from '@sympli/ui-framework/components/alert';
import { IconExclamation } from '@sympli/ui-framework/icons';

import { titleVerificationErrorStatuses, TitleVerificationResponse, TitleVerificationStatusEnum } from 'src/containers/shared/verify-property-section/models';
import { WorkspaceCreationErrorsMap } from '../../models';
import { useStyles } from './styles';

interface Props {
  workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap;
  titleVerificationResult?: TitleVerificationResponse;
}

function WizardErrorMessage({
  //
  workspaceCreationErrorsMap = {},
  titleVerificationResult
}: Props) {
  const classes = useStyles();

  if (!titleVerificationResult) {
    return null;
  }

  if (Object.keys(workspaceCreationErrorsMap).length || titleVerificationResult.titles.some(t => titleVerificationErrorStatuses.has(t.status))) {
    return (
      <Paper className={classes.root} elevation={2} role="alert" data-error-name="workspaceCreationErrorsMap">
        {' '}
        {/* TODO replace this with Alert component */}
        <IconExclamation color="error" className={classes.icon} />
        <span className={classes.bold}>The selected document cannot be lodged with the following {pluralize('title', titleVerificationResult?.titles.length)}.</span>&nbsp;Please
        review your {pluralize('title', titleVerificationResult?.titles.length)} to continue.
      </Paper>
    );
  }

  const { titles } = titleVerificationResult;
  if (titles.length > 20) {
    return (
      <Paper className={classes.root} elevation={2} role="alert">
        {' '}
        {/* TODO replace this with Alert component */}
        <IconExclamation color="error" className={classes.icon} />
        <span className={classes.bold}>You have reached the maximum number of titles that can be added to a workspace.</span>&nbsp;Please remove titles to continue.
      </Paper>
    );
  }

  if (titles.some(t => t.status === TitleVerificationStatusEnum.ErrorInconsistentControllers)) {
    return (
      <Alert
        variant="warning"
        primary="In Sympli, multiple titles must have a common nomination type or controlling party within a workspace in order to proceed to workspace creation."
        secondary="Please review your titles to continue."
        className={classes.alert}
      />
    );
  }

  return null;
}

export default React.memo(WizardErrorMessage);
