import React from 'react';

import { useDispatch } from 'react-redux';

import { DocumentPageRouteParams } from 'src/containers/documents/models';
import { actionOpenLodgementVerificationDialog } from 'src/containers/workspace/shared/components/lodgement-verification-dialog/actions';
import { convertComplianceToMessageGridModel } from 'src/containers/workspace/shared/detail/helpers';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import WorkflowPanelIssues from './WorkflowPanelIssues';

type Props = {
  buttonTheme?: 'light' | 'dark';
  queryParams: DocumentPageRouteParams;
};

function WorkflowPanelIssuesContainer({ buttonTheme, queryParams }: Props) {
  const { errors, warnings } = useStoreSelector(store => store.documentMessages);
  const isOpenLodgementVerificationDialog = useStoreSelector(state => state.lodgementVerificationDialog.isOpen);
  const dispatch = useSafeDispatch(useDispatch());
  const handleOpenVerificationResult = () => dispatch(actionOpenLodgementVerificationDialog());
  const issueId = errors?.length ? errors[0].complianceId : warnings?.length ? warnings[0].complianceId : undefined;

  return (
    <WorkflowPanelIssues //
      buttonTheme={buttonTheme}
      queryParams={queryParams}
      isOpenLodgementVerificationDialog={isOpenLodgementVerificationDialog}
      errors={errors?.map(convertComplianceToMessageGridModel)}
      warnings={warnings?.map(convertComplianceToMessageGridModel)}
      onClick={handleOpenVerificationResult}
      issueId={issueId}
    />
  );
}

export default WorkflowPanelIssuesContainer;
