import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LogTypeEnum } from '@sympli/api-gateway/enums';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import ChipDetail from 'src/containers/dashboard/components/chip/ChipDetail';
import { colors } from 'src/theme';
import { dateTimeLine } from 'src/utils/formatters';
import { resolveWorkspaceLogTypeMapping } from '../../helper';
import { LastSystemActivityModel } from '../models';

function SystemActivityCardDetail({
  lastSystemActivity = {
    // give default values which will never been used
    message: '',
    userLocalTime: '',
    userTimezoneAbbreviation: '',
    workspaceLocalTime: '',
    workspaceTimezoneAbbreviation: '',
    logType: LogTypeEnum.WorkspaceCreated
  } //
}: {
  lastSystemActivity: LastSystemActivityModel | undefined;
}) {
  const rows: LastSystemActivityModel[] = [lastSystemActivity];

  const columns: ColumnsModel<LastSystemActivityModel> = {
    systemActivity: {
      label: 'System activity',
      formatter: ({ row: { message, userLocalTime, logType, userTimezoneAbbreviation } }: FormatterInputModel<LastSystemActivityModel>) => {
        if (!message) {
          return <ChipDetail text="0 Updates" />;
        }

        const { desc, panelDesc, source, comment } = resolveWorkspaceLogTypeMapping(logType);

        let [datePart, secondary] = dateTimeLine(userLocalTime, 'd mmm|h:MM TT').split('|');
        if (datePart !== dateTimeLine(new Date(), 'd mmm')) {
          secondary = `${datePart} - ${secondary} ${userTimezoneAbbreviation}`;
        } else {
          secondary = `${secondary} ${userTimezoneAbbreviation}`;
        }

        return (
          <>
            <Typography
              sx={{
                color: colors.NEUTRAL_1000,
                fontFamily: 'Roboto',
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '14px'
              }}
            >
              {panelDesc ? panelDesc : desc}
            </Typography>
            {source && (
              <Typography
                sx={{
                  color: colors.NEUTRAL_1000,
                  fontFamily: 'Roboto',
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '14px'
                }}
              >
                {source}
              </Typography>
            )}

            <Typography
              sx={{
                color: colors.NEUTRAL_400,
                fontFamily: 'Roboto',
                fontSize: 10,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '14px'
              }}
            >
              {secondary}
            </Typography>
            {comment && (
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  '& .MuiTypography-body2': {
                    color: colors.NEUTRAL_1000,
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '14px'
                  }
                }}
              >
                {comment}
              </Box>
            )}
          </>
        );
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
    />
  );
}

export default React.memo(SystemActivityCardDetail);
