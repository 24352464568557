import * as React from 'react';

import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceDirectionsOverviewApiResponse, WorkspaceDirectionsStatusApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { UserAvatar101 } from 'src/components/avatars';
import { AvatarListItem, IconListItem } from 'src/components/list-item';
import { LineLoader } from 'src/components/loaders';
import { actionFetchAllDirections } from 'src/containers/workspace/financial/all-directions/actions';
import { AllDirectionsState } from 'src/containers/workspace/financial/all-directions/reducer';
import { resolveAllDirectionsLink } from 'src/containers/workspace/financial/directions/helpers';
import { WorkspaceDetailState } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import DirectionStatusBadgeNew from '../../../direction-status-badge/DirectionStatusBadgeNew';
import { distributionsParticipantOrderedListSelector } from '../../selectors';
import styles, { ClassKeys } from './newStyles';

export interface OwnProps {
  workspaceId: string;
  currentParticipantId: string;
  workspaceDetailState: WorkspaceDetailState;
  workspaceParticipantsState: WorkspaceParticipantsState;
  allDirectionsState: AllDirectionsState;
  data: WorkspaceDirectionsStatusApiResponse;
  dispatch: SafeDispatch<Action>;
  navigate: NavigateFunction;
  // Routing info
  isAllDirectionPage: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class NewAllDirectionsItem extends React.PureComponent<Props> {
  get isLoading() {
    const { workspaceDetailState, workspaceParticipantsState, allDirectionsState } = this.props;
    return workspaceDetailState.status === 'pending' || workspaceParticipantsState.status === 'pending' || allDirectionsState.status === 'pending';
  }

  get getErrorMessage() {
    const { workspaceDetailState, workspaceParticipantsState, allDirectionsState } = this.props;
    return [workspaceDetailState.error, workspaceParticipantsState.error, allDirectionsState.error].filter(Boolean).join(' ');
  }

  get workspaceParticipants() {
    return this.props.workspaceParticipantsState.items;
  }

  get allDirectionsDetail() {
    return this.props.allDirectionsState.detail;
  }

  get hasParticipantDirections() {
    return this.props.allDirectionsState.detail?.distributionsParticipants.length !== 0;
  }

  render() {
    const { classes, isAllDirectionPage } = this.props;

    return (
      <FlexLayout flexDirection="column" fullWidth>
        {this.isLoading ? (
          <LineLoader color="white" variant="large" widthPercent={100} data-testid="financial-settlement-summary-loading" />
        ) : (
          <IconListItem
            selected={isAllDirectionPage}
            primary={
              this.getErrorMessage ? (
                <Typography data-testid="financial-settlement-summary-error" variant="body2_bold">
                  Financial Settlement Summary
                </Typography>
              ) : (
                <ButtonLink
                  data-testid="financial-settlement-summary"
                  onClick={this.handleSeeDetailsClick}
                  color="inherit"
                  classes={{ text: classes.headTextPrimary, root: classes.buttonLinkRoot }}
                >
                  Financial Settlement Summary
                </ButtonLink>
              )
            }
          />
        )}
        {this.renderLoader()}
        {this.renderError()}
        {this.renderContent()}
      </FlexLayout>
    );
  }

  private renderLoader() {
    if (this.isLoading) {
      return <LineLoader color="white" variant="large" widthPercent={100} />;
    }
    return null;
  }

  private renderError() {
    const { classes } = this.props;
    const errorMessage = this.getErrorMessage;
    if (errorMessage) {
      return (
        <FormHelperText role="alert" className={classes.errorMessage} error>
          {errorMessage}
        </FormHelperText>
      );
    }
    return null;
  }

  private renderContent() {
    if (this.isLoading || !(this.workspaceParticipants && this.allDirectionsDetail && this.hasParticipantDirections)) {
      return null;
    }
    return this.renderParticipantDirections(this.allDirectionsDetail, this.workspaceParticipants);
  }

  private renderParticipantDirections = (detail: WorkspaceDirectionsOverviewApiResponse, workspaceParticipants: WorkspaceParticipantApiResponse[]) => {
    const { currentParticipantId, classes } = this.props;
    const { distributionsParticipants } = detail;

    const distributionsParticipantOrderedList = distributionsParticipantOrderedListSelector(distributionsParticipants);

    return distributionsParticipantOrderedList.map(distributionsParticipant => {
      const { participantId, distributionStatus, acceptingSurplus, isAssignedAutoBalancingSurplusForIM, isPartiallySigned, paymentsFinancialStatus, sourceFundsFinancialStatus } =
        distributionsParticipant;
      const isCurrentParticipant = currentParticipantId === participantId;
      const participant = workspaceParticipants.find(p => p.id === participantId);

      return (
        <AvatarListItem //
          key={participantId}
          avatar={<UserAvatar101 src={participant?.avatarUrl} item={participant} placement="right-start" tooltipType="document" />}
          avatarSize="s"
          primary={participant?.workspaceRole.name}
          secondary={
            <DirectionStatusBadgeNew
              isCurrentParticipant={isCurrentParticipant}
              status={distributionStatus}
              showToolTips={true}
              isPartiallySigned={isPartiallySigned}
              paymentsStatus={paymentsFinancialStatus}
              sourceFundsStatus={sourceFundsFinancialStatus}
            />
          }
        >
          {acceptingSurplus ? (
            <IconListItem //
              classes={{
                textPrimary: classes.textPrimary,
                itemRoot: classes.itemRoot,
                iconRoot: classes.iconRoot,
                itemSelected: classes.itemSelected,
                focusVisible: classes.focusVisible
              }}
              primary="ACCEPTING SURPLUS"
            ></IconListItem>
          ) : null}
          {isAssignedAutoBalancingSurplusForIM && (
            <IconListItem //
              classes={{
                textPrimary: classes.textPrimary,
                itemRoot: classes.itemRoot,
                iconRoot: classes.iconRoot,
                itemSelected: classes.itemSelected,
                focusVisible: classes.focusVisible
              }}
              primary="LOAN ADVANCE ADJUSTED"
            />
          )}
        </AvatarListItem>
      );
    });
  };

  private handleSeeDetailsClick = () => {
    const { workspaceId, currentParticipantId: participantId, dispatch, navigate } = this.props;
    const linkTo = resolveAllDirectionsLink({ workspaceId, participantId });

    navigate(linkTo);
    // * the click will trigger refetch all the time, compare url and linkTo if we do not want that
    dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
  };
}

const styledComponent = withStyles(styles)(NewAllDirectionsItem);

export default styledComponent;
