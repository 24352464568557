import * as React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  className?: string;
  linkTo?: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class ListItemLink extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, className, disabled, onClick, linkTo, selected, children } = this.props;

    if (linkTo) {
      return (
        <ListItem //
          className={classNames(classes.container, selected && classes.selected, className)}
          button
          classes={{
            root: classes.root,
            disabled: classes.disabled,
            focusVisible: classes.focusVisible
          }}
          component={NavLink}
          disableGutters={true}
          to={linkTo}
          disabled={disabled}
        >
          <div className={classes.link}>{children}</div>
        </ListItem>
      );
    }

    return (
      <ListItem //
        className={classNames(classes.container, className)}
        component="li"
        disableGutters={true}
        button={!!onClick as any} // workaround due to this https://github.com/mui-org/material-ui/issues/14971
        classes={{
          root: classes.root,
          disabled: classes.disabled,
          focusVisible: classes.focusVisible
        }}
        disabled={disabled}
        onClick={onClick}
      >
        <div className={classes.link}>{children}</div>
      </ListItem>
    );
  }
}

export default withStyles(styles)(ListItemLink);
