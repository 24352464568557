import * as React from 'react';

import _cloneDeep from 'lodash-es/cloneDeep';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { SharedInitialValues } from '../../models';
import { initialValues } from './initialValues';
import { CreateLodgementOnlyWorkspaceSharedModel, CreateLodgeOnlyWorkspaceStepEnum } from './models';
import InviteParticipantsContainer from './steps/invite-participants';
import MatterDetailContainer from './steps/matter-detail';

interface Props {
  onClose: (link?: string) => void;
  onWorkspaceTypeChange: (workspaceTypeId: WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => void;

  // selected workspace type by the user
  workspaceTypeId?: WorkspaceTypeEnum;
  // list of available workspace types (the value depends on feature flags)
  workspaceTypeOptions: LookupEnumModel<WorkspaceTypeEnum>[];
  // these are the values that are passed around when we change workspace type
  sharedInitialValues: Partial<SharedInitialValues>;
}

function RegOnlyForm(props: Props) {
  const [step, setStep] = React.useState<CreateLodgeOnlyWorkspaceStepEnum>(CreateLodgeOnlyWorkspaceStepEnum.AddMatterDetails);

  const [globalValues, setGlobalValues] = React.useState<CreateLodgementOnlyWorkspaceSharedModel>(() => {
    // initialize globalValues that will be used for formik
    // whenever workspace type is changed to LodgementOnly this merge gets performed
    const globalValues: CreateLodgementOnlyWorkspaceSharedModel = _cloneDeep({
      ...initialValues,
      matterDetailsStepData: {
        ...initialValues.matterDetailsStepData,
        ...props.sharedInitialValues
      }
    });
    return globalValues;
  });

  const onGlobalValuesUpdate = React.useCallback(
    (values?: Partial<CreateLodgementOnlyWorkspaceSharedModel>) => {
      if (values) {
        const updatedGlobalState = {
          ...globalValues,
          ..._cloneDeep(values)
        };
        setGlobalValues(updatedGlobalState);
        return updatedGlobalState;
      }
      return globalValues;
    },
    [globalValues]
  );

  const { onClose, onWorkspaceTypeChange, workspaceTypeId, workspaceTypeOptions } = props;

  switch (step) {
    case CreateLodgeOnlyWorkspaceStepEnum.AddMatterDetails:
      return (
        <MatterDetailContainer
          workspaceTypeId={workspaceTypeId}
          workspaceTypeOptions={workspaceTypeOptions}
          onWorkspaceTypeChange={onWorkspaceTypeChange}
          onGlobalValuesUpdate={onGlobalValuesUpdate}
          onStepChange={setStep}
          onClose={onClose}
        />
      );
    case CreateLodgeOnlyWorkspaceStepEnum.InviteParticipants:
    default:
      return (
        <InviteParticipantsContainer //
          onGlobalValuesUpdate={onGlobalValuesUpdate}
          onStepChange={setStep}
          onClose={onClose}
        />
      );
  }
}

export default React.memo(RegOnlyForm);
