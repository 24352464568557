import * as yup from 'yup';

import { SignDocumentFormItem, SignDocumentsFormModel } from './models';

const validationSchema = yup.object<SignDocumentsFormModel>({
  documents: yup.mixed<SignDocumentFormItem[]>().test('checkedAtLeastOne', 'Must select at least one document', value => {
    return value.filter(doc => doc.isSelected).length > 0;
  })
});

export default validationSchema;
