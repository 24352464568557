import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiNoticeOfSaleModel, NoticeOfSaleModel } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export default function DocumentForm(props: DocumentFormProps<NoticeOfSaleModel, ApiNoticeOfSaleModel>): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<NoticeOfSaleModel>(validationSchema, validateDebounce);
  return (
    <DocumentFormWrapper //
      {...props}
      converter={converter}
      validate={validate}
      RootForm={RootForm}
    />
  );
}
