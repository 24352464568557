import { LookupEnumModel } from '@sympli/ui-framework/models';

import { UserReportsPermissionsApiResponse } from 'src/containers/dashboard/reports/models';
import { ReportTypeLookup, UpdateUserReportsPermissions, UserReportsPermissionsDetailFormModel } from '../models';

export const convertUserReportsPermissionsApiResponseToFormModel = (
  userReportsPermissionsDetailApiResponse: UserReportsPermissionsApiResponse
): //
UserReportsPermissionsDetailFormModel => {
  //
  const reportPermissions = ReportTypeLookup.filter(
    // get all the apiResponse reportPermissions that hasAccess === true
    reportType => userReportsPermissionsDetailApiResponse.reportPermissions.find(x => x.id === reportType.id)?.hasAccess
  ) // only id is needed
    .map(x => x.id);

  return {
    reportPermissions,
    selected: userReportsPermissionsDetailApiResponse.reportPermissions.length === reportPermissions.length,
    teamWorkloadReportAssignedUsers: userReportsPermissionsDetailApiResponse.teamWorkloadReportAssignedUsers,
    maxTeamWorkloadReportAccessNumber: userReportsPermissionsDetailApiResponse.maxTeamWorkloadReportAccessNumber
  };
};

export const convertUserReportsPermissionsFormModelToApiResponse = (
  userReportsPermissionsDetailFormData: UserReportsPermissionsDetailFormModel,
  options: LookupEnumModel<number, string>[]
): UpdateUserReportsPermissions => {
  return {
    reportPermissions: options.map(reportType => ({
      id: reportType.id,
      hasAccess: userReportsPermissionsDetailFormData.reportPermissions.includes(reportType.id)
    }))
  };
};

export const convertUserReportsPermissionsApiResponseToFormOptions = (
  userReportsPermissionsDetailApiResponse: UserReportsPermissionsApiResponse
): //
LookupEnumModel<number>[] => {
  //
  const reportPermissions = ReportTypeLookup.filter(
    // get all the ReportTypeEnums that are in API response
    reportType => userReportsPermissionsDetailApiResponse.reportPermissions.some(x => x.id === reportType.id)
  );

  return reportPermissions;
};
