import * as yup from 'yup';

import { LandDescriptionModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { TitleReferenceModel, Transfer2_21_1Model } from '../../models';

interface TransferContext {
  partyBook: Transfer2_21_1Model['partyBook'];
  mergeFailedReason?: MergeFailedReasonsEnum;
}

const contextResolver = ({ partyBook, mergeFailedReason }: Transfer2_21_1Model): TransferContext => ({ partyBook, mergeFailedReason });

// path: titleReferences
const yupTitleReferenceItemSchema = yup
  .object<TitleReferenceModel>({
    isSelected: yup.boolean(),
    reference: yup.string(),
    landDescriptions: yup.mixed<LandDescriptionModel[]>(),
    transferors: yup.mixed(),
    estateType: yup.mixed()
  })
  .defined();

const yupTitleReferences = resolveTitleReferencesValidationSchema(yupTitleReferenceItemSchema).test(
  //
  'Multiple title selected: check for tenancy structure is same',
  'Party merge failed.',
  function test(this: yup.TestContext<TransferContext>) {
    const { mergeFailedReason } = this.options.context!;
    if (!mergeFailedReason) return true;

    if (mergeFailedReason === MergeFailedReasonsEnum.StructureNotSame)
      return this.createError({ message: 'The tenancy of the proprietors does not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.PartyTypeMismatch)
      return this.createError({ message: 'The party types of the proprietors do not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.ShareFractionMismatch)
      return this.createError({
        message: 'The share fraction of the proprietors does not match; please remove the mismatched titles. To transact on mismatched titles, please create a new workspace.'
      });

    return false;
  }
);

export default memoizeSchemaWithContext(yupTitleReferences, contextResolver);
