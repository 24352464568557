import React from 'react';

import classnames from 'classnames';
import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { PriorityNoticeConfig, TitleReferencesFieldConfig } from '../../config';
import { BasePriorityNoticeModel, TransactionInstrumentModel } from '../../models';
import ReceivingParties from '../receiving-parties/ReceivingParties';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
const debug = false && !import.meta.env.PROD;
interface Props {
  name: string;
  className?: string;
  dialogPortalId: string;
  renderTitleReference: (name: string, config: TitleReferencesFieldConfig) => JSX.Element;
  priorityNoticeConfig: PriorityNoticeConfig;
  partyFormConfig: PartyFormConfig;
}

function TransactionInstrument<T extends PartLandAffectedModel>({
  name,
  className,
  dialogPortalId,
  renderTitleReference,
  priorityNoticeConfig,
  partyFormConfig
}: Props): JSX.Element {
  const classes = useStyles();
  const { values } = useFormikContext<BasePriorityNoticeModel<T>>();
  const { nextActionDisabled } = useDocumentContext();

  const fieldName = createModelKeyAppender<TransactionInstrumentModel<T>>(name);

  const transactionInstrument: TransactionInstrumentModel<T> = _get(values, name);
  const {
    //
    dealingNumber: dealingNumberConfig,
    receivingParties: receivingPartiesConfig,
    titleReferences: titleReferencesConfig,
    documentTypeOptions
  } = priorityNoticeConfig;

  return (
    <div data-section={name} className={classnames(classes.root, className)}>
      <Field //
        name={fieldName('documentType')}
        component={SelectField}
        debug={debug}
        disabled={nextActionDisabled}
        format="string"
        options={documentTypeOptions}
        placeholder={resolveSelectPlaceholder(true)}
        label="Document type"
        fullWidth
      />
      {dealingNumberConfig.visibilityCheck(transactionInstrument) && (
        <Field //
          name={fieldName('dealingNumber')}
          component={InputField}
          disabled={nextActionDisabled}
          placeholder={resolvePlaceholder(dealingNumberConfig.isRequiredCheck && dealingNumberConfig.isRequiredCheck(transactionInstrument))}
          label="Registered dealing number"
        />
      )}
      {receivingPartiesConfig.visibilityCheck(transactionInstrument) && (
        <ReceivingParties //
          name={fieldName('receivingParties')}
          dialogPortalId={dialogPortalId}
          receivingParties={transactionInstrument.receivingParties}
          documentType={transactionInstrument.documentType}
          partyFormConfig={partyFormConfig}
        />
      )}
      {titleReferencesConfig.visibilityCheck(transactionInstrument) && renderTitleReference(fieldName('titleReferences'), priorityNoticeConfig.titleReferences)}
    </div>
  );
}

export default React.memo(TransactionInstrument);
