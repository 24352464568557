import * as React from 'react';

import { WorkspaceDirectionsOverviewApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { SectionTypeEnum } from '../../../directions/components/settlement-directions-list/models';
import ParticipantCollapseBox from './ParticipantCollapseBox';

export interface ParticipantCollapseBoxContainerProps {
  openAll: boolean;
  currentParticipantId: string;
  distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number];
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  hasItems: boolean;
  sectionType?: SectionTypeEnum;
  subTotalAmount: number;
  renderCollapseDetail: (distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]) => React.ReactNode;
}

function ParticipantCollapseBoxContainer(props: ParticipantCollapseBoxContainerProps) {
  return (
    <ParticipantCollapseBox //
      {...props}
    />
  );
}

export default React.memo(ParticipantCollapseBoxContainer);
