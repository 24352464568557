import classNames from 'classnames';
import List from '@mui/material/List';

import { JurisdictionsEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import ListItemLink from 'src/components/list-item-link';
import IconTypography from 'src/components/workspace-status-rebuild/icon-typography';
import DocumentItemBase from 'src/containers/shared/document-list/components/document-item-base';
import { resolveTitleDetailLink } from 'src/containers/workspace/titles/helper';
import { titleAddress } from 'src/utils/formatters';
import { TitleListModel } from '../../models';
import { TitleListAction } from '../title-and-addresses/models';
import TitleItemWithAction from '../title-item-with-action/TitleItemWithAction';
import { useStyles } from './styles';

export interface TitlesAndAddressesBaseProps {
  titleReferences: TitleListModel[];
  propertyAddress?: string;
  workspaceStatusId: WorkspaceStatusEnum;
  jurisdictionId: JurisdictionsEnum;
  workspaceId: string;
  participantId: string;
  selectedTitleId?: string;
  mode?: TitleListAction;
  dataTestId?: string;
}

interface Props extends TitlesAndAddressesBaseProps {}

function TitlesAndAddressesBase({ mode, dataTestId, titleReferences, propertyAddress, workspaceStatusId, selectedTitleId, jurisdictionId, workspaceId, participantId }: Props) {
  const classes = useStyles();
  const notAccessible = workspaceStatusId === WorkspaceStatusEnum.Abandoned || workspaceStatusId === WorkspaceStatusEnum.Withdrawn;
  function renderList(titleReferences: TitleListModel[]) {
    return titleReferences.length === 0 ? (
      <label className={classes.noTitles}>No titles in this workspace.</label>
    ) : (
      titleReferences.map((item, i) => {
        const desc = (propertyAddress ? titleAddress(propertyAddress, jurisdictionId) : titleAddress(item.address, jurisdictionId)) || '';
        const titleLink = notAccessible
          ? undefined
          : resolveTitleDetailLink({
              //
              workspaceId,
              participantId,
              titleReference: item.reference
            });

        const selected = selectedTitleId === item.reference;

        return mode === 'delete' ? (
          <TitleItemWithAction titleReference={item.reference} description={desc} icon={item.icon} dataTestId={`${dataTestId}-${item.reference}`} />
        ) : (
          <ListItemLink //
            key={item.reference + i}
            linkTo={titleLink}
            className={classes.listItem}
            classes={{
              link: classes.listItemLink
            }}
            selected={selected}
          >
            <FlexLayout flexDirection="column">
              <DocumentItemBase
                title={item.reference}
                titleAriaLabel="Title reference"
                description={desc}
                selected={selected}
                classes={{ title: classes.smallRow }}
                underline={!!titleLink}
                enableCopyToClipboardButton
              />
              {item.hasChanges ? <IconTypography text="Positive tac" /> : null}
            </FlexLayout>
          </ListItemLink>
        );
      })
    );
  }

  return <List className={classNames(classes.sectionSpacingTop)}>{renderList(titleReferences)}</List>;
}

export default TitlesAndAddressesBase;
