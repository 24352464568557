import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    imagePreview: {
      height: 70,
      width: 200,
      backgroundColor: theme.palette.grey[50]
    },
    dropZone: {
      height: 200,
      width: 200,
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      cursor: 'pointer'
    },
    dropZoneText: {
      textAlign: 'center',
      color: 'inherit',
      '& > strong': {
        textDecoration: 'underline'
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
