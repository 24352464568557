import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspaceFinancialAccounts } from '../actions';
import { FinancialAccount as FinancialAccountApiResponse } from '../models';

export interface WorkspaceFinancialAccountsState {
  items: Array<FinancialAccountApiResponse>;
  status: ApiStatus;
  error?: string;
}

const initialState: WorkspaceFinancialAccountsState = {
  items: [],
  status: 'idle',
  error: undefined
};

export function useWorkspaceFinancialAccounts() {
  const state = useStoreSelector(store => store.workspaceDebitAccountsFeed);
  if (state) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  WorkspaceFinancialAccountsState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceFinancialAccounts.request, (state): WorkspaceFinancialAccountsState => {
    const status = state.status === 'pending' ? 'pending' : 'refetching';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      error: undefined
    };
  })
  .handleAction(actionFetchWorkspaceFinancialAccounts.success, (state, action): WorkspaceFinancialAccountsState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchWorkspaceFinancialAccounts.failure, (state, action): WorkspaceFinancialAccountsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
