import * as React from 'react';

import classNames from 'classnames';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import CreationFormView from '../../views';
import CreateNewWorkspaceDialogContext from './CreateNewWorkspaceDialog.context';
import { useStyles } from './styles';

export interface OwnProps {
  workspaceTypeOptions: LookupEnumModel<WorkspaceTypeEnum>[];
  isOpen: boolean;
  onClose(path?: string): void;
  isSearchAndJoinWorkspaceEnabled?: boolean;
}

function CreateNewWorkspaceDialog({ isOpen, onClose, workspaceTypeOptions, isSearchAndJoinWorkspaceEnabled }: OwnProps) {
  const classes = useStyles({ isSearchAndJoinWorkspaceEnabled });
  const [hide, setHide] = React.useState<boolean>(false);
  const setVisibility = (visible: boolean) => {
    setHide(!visible);
  };

  return (
    <CreateNewWorkspaceDialogContext.Provider value={{ hide, setVisibility }}>
      <ConfirmationDialog
        open={isOpen}
        classes={{
          //
          dialogPaper: classes.dialogPaper,
          dialogContent: classes.createWorkspaceDialogContent,
          root: classNames(classes.root, hide && 'invisible')
        }}
        onClose={(value?: boolean) => onClose()}
        scroll="body"
        buttonArrow={true}
        showActionButtons={false}
      >
        <CreationFormView //
          workspaceTypeOptions={workspaceTypeOptions}
          onClose={onClose}
        />
      </ConfirmationDialog>
    </CreateNewWorkspaceDialogContext.Provider>
  );
}

export default CreateNewWorkspaceDialog;
