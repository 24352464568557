import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { MortgagorPartyModel } from './models';

/**
 path:
 - titleReferences[*].mortgagors[*].partyCapacity.partyCapacityDetail

 schema version:
[
  {
    "partyCapacity": {
      "$not": null
    }
  }
]
*/
// only display when there is party capacity returned from api
// otherwise hide the entire field
export const VISIBILITY_CHECK_PARTY_CAPACITY = createContextCheck(({ partyCapacity }: MortgagorPartyModel): boolean => partyCapacity != null && !!partyCapacity.partyCapacity);

// only display when there is party capacity and detail returned from api
// otherwise hide the entire field
export const VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL = createContextCheck(
  ({ partyCapacity }: MortgagorPartyModel): boolean => partyCapacity != null && !!partyCapacity.partyCapacityDetail
);
