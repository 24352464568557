import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ParticipantArchivedTypeEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { resolveCanWithdrawLinkedWorkspace } from 'src/containers/shared/helper';
import { useLinkedWorkspaces } from 'src/containers/shared/linked-workspace-list/reducer';
import { useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { actionFetchWorkspaceBasicInfo, actionFetchWorkspaceById, actionFetchWorkspaceParticipants, actionUpdateCurrentParticipantDetail } from '../../detail/actions';
import { useWorkspaceBasicInfo } from '../../detail/reducers/workspaceBasicInfo';
import { isWithdrawReasonOther } from './helpers';
import { WithdrawParticipantApiModel, WithdrawParticipantFormikModel, WithdrawParticipantReasonNameMap } from './model';
import WithdrawFinancialParticipantDialog from './WithdrawFinancialParticipantDialog';

interface WithdrawFinancialParticipantDialogContainerProps {
  // route params
  workspaceId: string;
  participantId: string;
  // redux
  workspaceRoleId: WorkspaceRoleEnum;
  // dialog
  open: boolean;
  onClose(): void;
  // other
  isWithdraw: boolean;
}

function WithdrawFinancialParticipantDialogContainer({
  //
  workspaceId,
  participantId,
  workspaceRoleId,
  open,
  onClose,
  isWithdraw
}: WithdrawFinancialParticipantDialogContainerProps) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceBasicInfo = useWorkspaceBasicInfo(workspaceId).detail;
  const linkedWorkspaces = useLinkedWorkspaces(workspaceId).items;
  const { userId, subscriberId } = useProfile().data!;

  // if there are more than 1 participant that accepts the invite and hasn't withdrawn or abandoned, this should be withdraw

  const handleOnPostSubmit = React.useCallback(
    ({ error, formValues }: FormikPostSubmitArgs<WithdrawParticipantFormikModel>) => {
      /*
       no matter it's success or failure
       1. close the dialog, 
       2. re-fresh the page,
       3. redirect to details page
      */
      // close the dialog
      const { reasonOption, reasonDescription } = formValues;

      /*
       since now we don't re-fetch workspaceInfo when we redirect from document page to detail page,
       we need to manually do it here when we know it's a successful abandon
      */
      dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
      dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));

      const workspaceOverviewLink = `/workspace/${encodeURIComponent(workspaceId)}/detail?participantId=${encodeURIComponent(participantId)}`;
      if (pathname.concat(search).toLowerCase() !== workspaceOverviewLink.toLowerCase()) {
        navigate(workspaceOverviewLink);
      }

      if (error) {
        // when error happens, we should also re-fetch participants
        dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
        return;
      }

      const message = `You have ${isWithdraw ? 'withdrawn from' : 'abandoned'} this workspace`;

      // if it's a success, we update the redux store and show a success toast message
      /*
        We need to update workspace participant as archived 
        so the UI can re-render and hide the abandon tooltip accordingly.
      */
      batch(() => {
        dispatch(
          actionUpdateCurrentParticipantDetail({
            workspaceId,
            participantId,
            participantArchivedStatus: {
              type: isWithdraw ? ParticipantArchivedTypeEnum.Withdraw : ParticipantArchivedTypeEnum.Abandon,
              reason: isWithdrawReasonOther(reasonOption) ? reasonDescription! : WithdrawParticipantReasonNameMap[reasonOption!],
              // the userId for archived status should be the current user who archived it
              userId
            }
          })
        );
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            message,
            autoHideDuration: 5000,
            variant: 'new-success'
          })
        );
        onClose();
      });
    },
    [dispatch, workspaceId, participantId, isWithdraw, pathname, search, navigate, userId, onClose]
  );

  const handleOnPreSubmit = React.useCallback(
    (values: WithdrawParticipantFormikModel): WithdrawParticipantApiModel => {
      const { reasonOption, reasonDescription, isApplyToLinkedWorkspace } = values;
      const reason = isWithdrawReasonOther(reasonOption) ? reasonDescription! : WithdrawParticipantReasonNameMap[reasonOption!];

      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: isWithdraw ? 'withdraw-from-workspace' : 'abandon-workspace',
        logGroupId: 'workspace',
        workspaceId,
        participantId,
        workspaceType: WorkspaceTypeDisplayMapping[workspaceBasicInfo?.workspaceTypeId!],
        reason
      });

      return {
        reason,
        archiveType: isWithdraw ? ParticipantArchivedTypeEnum.Withdraw : ParticipantArchivedTypeEnum.Abandon,
        isApplyToLinkedWorkspace
      };
    },
    [isWithdraw, participantId, workspaceId, workspaceBasicInfo?.workspaceTypeId]
  );

  if (!workspaceBasicInfo) {
    return null;
  }

  const { jurisdictionId } = workspaceBasicInfo;
  const { canWithdrawLinkedWorkspace } = resolveCanWithdrawLinkedWorkspace(subscriberId, workspaceId, linkedWorkspaces);

  return (
    <WithdrawFinancialParticipantDialog //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // redux data
      jurisdictionId={jurisdictionId}
      workspaceRoleId={workspaceRoleId}
      // dialog
      open={open}
      onClose={onClose}
      // other
      isWithdraw={isWithdraw}
      canWithdrawLinkedWorkspace={canWithdrawLinkedWorkspace}
      onPreSubmit={handleOnPreSubmit}
      onPostSubmit={handleOnPostSubmit}
    />
  );
}

export default React.memo(WithdrawFinancialParticipantDialogContainer);
