import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import { ApiLease2_21_1Model, Lease2_21_1Model } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import converter from './conversion';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

type Props = DocumentFormProps<Lease2_21_1Model, ApiLease2_21_1Model> & {
  disableValidateOnMount?: boolean;
};

export default function DocumentForm({ disableValidateOnMount = false, ...props }: Props): JSX.Element {
  const { validateDebounce } = useDocumentContext();

  const validate = useMemoizableValidation<Lease2_21_1Model>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
        validateOnMount={!disableValidateOnMount}
      />
    </DndContextProvider>
  );
}
