import * as React from 'react';

import classNames from 'classnames';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface Props extends React.AriaAttributes {
  title: React.ReactNode;
  verticalAlign?: 'top' | 'bottom' | 'middle';
  className?: string;
}

function DetailLine({
  //
  title,
  verticalAlign = 'top',
  className,
  children,
  ...ariaAttributes
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const alignItems = verticalAlign === 'bottom' ? 'flex-end' : verticalAlign === 'middle' ? 'center' : 'flex-start';

  return (
    <FlexLayout //
      flexDirection="row"
      alignItems={alignItems}
      role="row"
      className={classNames(classes.root, className)}
      {...ariaAttributes}
    >
      <div role="rowheader" className={classes.header}>
        {title}
      </div>
      <div role="cell" className={classes.body}>
        {children}
      </div>
    </FlexLayout>
  );
}

export default React.memo(DetailLine);
