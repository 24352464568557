import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionDeleteGroupUser, actionFetchGroupUsers } from '../actions/groups';
import { UserApiResponse } from '../group-settings/models';

export interface GroupUsersState {
  items: Array<UserApiResponse>;
  status: ApiStatus;
  error?: string;
}

const initialState: GroupUsersState = {
  items: [],
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  GroupUsersState,
  Action
>(initialState)
  .handleAction(actionFetchGroupUsers.request, (state): GroupUsersState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchGroupUsers.success, (state, action): GroupUsersState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchGroupUsers.failure, (state, action): GroupUsersState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionDeleteGroupUser, (state, action): GroupUsersState => {
    const deletedUserId: string = action.payload;
    return {
      ...state,
      items: state.items.filter(item => item.id !== deletedUserId)
    };
  });

export default reducer;
