import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from '@sympli/ui-framework/theme';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 40,
      height: 40,
      flexShrink: 0
    },
    avatar: {
      width: '100%',
      height: '100%'
    },
    status: {
      position: 'absolute',
      bottom: 0,
      right: 0
    },

    highlighted: {
      position: 'absolute',
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      top: -3,
      left: -3,
      width: 40,
      height: 40
    },
    sizeSmall: {
      width: 30,
      height: 30
    },
    sizeExtraSmall: {
      width: 20,
      height: 20
    },
    border: {
      border: `1px solid ${theme.palette.divider}`
    },
    tooltip: {
      width: 'auto',
      fontSize: 13,
      boxShadow: theme.shadows[1]
    },
    tooltipContent: {
      color: colors.WHITE
    },
    textEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    marginLeft: {
      marginLeft: 4
    },
    marginRight: {
      marginRight: 4
    }
  });

export type ClassKeys = typeof styles;

export default styles;
