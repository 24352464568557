import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { CaveatorPartyModel } from '../../models';

export default yup
  .array()
  .of<CaveatorPartyModel>(
    yup
      .object<CaveatorPartyModel>({
        partyBookId: yup.mixed<string>(),
        partyCapacity: yup.mixed<number | null>(),
        partyCapacityDetail: yup.mixed<string>(),

        addressBookId: yup.string().default('').trim().required(msg.REQUIRED)
      })
      .defined()
  )
  .defined();
