import * as React from 'react';

import { useLocation } from 'react-router-dom';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { useRequestReduxDataOnMount } from 'src/hooks';
import { actionFetchUserDetails } from '../settings/settings/actions/users';
import { UserDetailsState } from '../settings/settings/reducers/userDetails';
import PersonalProfileRoot from './PersonalProfileRoot';

function PersonalProfileRootContainer() {
  const { userId, role, isSSOEnabled } = useProfile().data!;
  const pathname: string = useLocation().pathname;

  useRequestReduxDataOnMount<UserDetailsState>( //
    actionFetchUserDetails.request(userId), //
    state => state.userDetails
  );

  if (!userId) return null;

  return (
    <PersonalProfileRoot //
      userId={userId}
      roleId={role}
      pathname={pathname}
      isSSOEnabled={isSSOEnabled}
    />
  );
}

export default React.memo(PersonalProfileRootContainer);
