import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dialogContent: {
      '&:first-child': {
        paddingTop: 0
      }
    },
    headingIcon: {
      fill: 'red',
      width: 28,
      height: 28
    },
    headingText: {
      marginLeft: 8,
      marginTop: 0
    }
  }),
  {
    name: 'WorkspaceFileDeleteDialog'
  }
);
