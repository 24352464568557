import { defaultMemoize } from 'reselect';

import { nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { arraysIntersectionsBy } from '@sympli-mfe/document-forms-framework/utils';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { NSW_DM_PARTY_FORM_CONFIG } from './config';
import { ApiDischargeOfMortgage2_21_1Model, DischargeOfMortgage2_21_1Model, MortgageModel, TitleReferenceSelectionModel } from './models';

const { convertPartyBookFromApiToFormModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_DM_PARTY_FORM_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

export const getSelectedMortgages = defaultMemoize((mortgages: MortgageModel[]) => {
  return mortgages.filter(e => e.isSelected);
});

export const getMortgageePartyBookIds = defaultMemoize((selectedMortgages: MortgageModel[]) => {
  const mortgageePartyBookIds = selectedMortgages
    .map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.id))
    .flat()
    .sort();
  return mortgageePartyBookIds;
});

/**
 * returns shared title references across given mortgages
 */
export const filterTitleReferences = (mortgages: MortgageModel[]): TitleReferenceSelectionModel[] => {
  const titleReferences = mortgages.filter(e => e.isSelected).map(x => x.titleReferences);
  const result = arraysIntersectionsBy<TitleReferenceSelectionModel>(titleReferences, 'reference');
  return result;
};

export const getOtherDmDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.DischargeOfMortgage && //
        document.documentId !== currentDocumentId
    )
    .map(document => document.documentId);

export const getDealingNumbersSelectedInOtherDms = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as ApiDischargeOfMortgage2_21_1Model;
    return data.mortgages.filter(mortgage => mortgage.isSelected).map(mortgage => mortgage.dealingNumber);
  });
};

export const generateMortgagees = defaultMemoize((formModel: DischargeOfMortgage2_21_1Model): DischargeOfMortgage2_21_1Model => {
  // ToDo: Mortgagees under dealing does not have any tenancy structure/group. Backend need to supply WEB-27471
  const containers = getSelectedMortgages(formModel.mortgages).map(x => new Container(x.dealingNumber!, new TenancyDetail([new ProprietorGroup(x.mortgagees)])));

  const result = PartyMerger.merge(containers);

  const partyBookApi = result.containers.flatMap(e => e.tenancyDetail.proprietorGroups.flatMap(e => e.mergedParties));
  const partyBook = convertPartyBookFromApiToFormModel(
    partyBookApi,
    partyBookApi.map(p => p.id!)
  );
  const mortgagees = partyBookApi.map(p => ({ id: p.id!, partyBookId: p.id! }));
  const titleReferences = filterTitleReferences(formModel.mortgages).map(e => ({ ...e, isSelected: true }));

  return { ...formModel, titleReferences, partyBookApi, partyBook, mortgagees };
});
