import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { NominationDetailsModel } from '../../models';

const INSTRUMENT_TEXT_LIMIT = 100;
const yupNominationDetails = yup
  .object<NominationDetailsModel>({
    instrumentText: yup.string().default('').required(msg.REQUIRED).max(INSTRUMENT_TEXT_LIMIT, msg.MAX_LENGTH(INSTRUMENT_TEXT_LIMIT)),
    partiesInInstrumentText: yup.string().default('').required(msg.REQUIRED).max(INSTRUMENT_TEXT_LIMIT, msg.MAX_LENGTH(INSTRUMENT_TEXT_LIMIT))
  })
  .defined();

export default yupNominationDetails;
