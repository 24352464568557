import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      fontSize: 14,
      textDecoration: 'underline'
    },
    icon: {
      color: theme.palette.text.primary,
      fill: theme.palette.primary.main
    }
  }),
  {
    name: 'HeadingToolbar'
  }
);
