import { createAsyncAction } from 'typesafe-actions';

import { PreferencesApiResponse } from '../models';

export const actionFetchPreferences = createAsyncAction(
  //
  'FETCH_PREFERENCES',
  'FETCH_PREFERENCES_SUCCESS',
  'FETCH_PREFERENCES_ERROR'
)<
  //
  undefined,
  { data: PreferencesApiResponse },
  { error: Error }
>();
