import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: 13,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
      ...dataBindingHelper({ marginTop: 12 })
    },
    variantError: {
      color: theme.palette.error.main,
      fontWeight: 'bold'
    }
  }),
  {
    name: 'HelperText'
  }
);
