import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { WithdrawalOfCaveat2_19_1Model } from './models';
import { VISIBILITY_CHECK_CAVEATS$TITLE_REFERENCES$PART_DESCRIPTION } from './visibilityChecks';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<WithdrawalOfCaveat2_19_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  caveats: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        displayName: NO_FALLBACK,
        isSelected: [false],
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              titleType: NO_FALLBACK,
              subJurisdiction: NO_FALLBACK,
              titleShareFraction: NO_FALLBACK,
              reference: NO_FALLBACK,
              isSelected: [false],
              isPartLandAffected: [false],
              partDescription: [VISIBILITY_CHECK_CAVEATS$TITLE_REFERENCES$PART_DESCRIPTION, null as any, ''],
              landDescriptions: {
                $shouldAddDefaultItemToEmptyArray: false,
                $arrayItem: {
                  $children: {
                    isSelected: [false],
                    legalDescription: NO_FALLBACK,
                    landDescriptionComponents: NO_FALLBACK
                  }
                }
              },
              sltReference: NO_FALLBACK
            }
          }
        },
        caveators: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              partyBookId: [''],
              addressBookId: [''],
              partyCapacity: [null],
              partyCapacityDetail: ['']
            }
          }
        },
        dealingNumber: NO_FALLBACK,
        parentInterest: NO_FALLBACK,
        siilReference: NO_FALLBACK,
        titleInterestsInLand: NO_FALLBACK
      }
    }
  },
  additionalAttachments: NO_FALLBACK,
  withdrawCaveats: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        displayName: NO_FALLBACK,
        isSelected: [false],
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              titleType: NO_FALLBACK,
              subJurisdiction: NO_FALLBACK,
              titleShareFraction: NO_FALLBACK,
              reference: NO_FALLBACK,
              isSelected: [false],
              isPartLandAffected: [false],
              partDescription: [VISIBILITY_CHECK_CAVEATS$TITLE_REFERENCES$PART_DESCRIPTION, null as any, ''],
              landDescriptions: {
                $shouldAddDefaultItemToEmptyArray: false,
                $arrayItem: {
                  $children: {
                    isSelected: [false],
                    legalDescription: NO_FALLBACK,
                    landDescriptionComponents: NO_FALLBACK
                  }
                }
              },
              sltReference: NO_FALLBACK
            }
          }
        },
        caveators: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              partyBookId: [''],
              addressBookId: [''],
              partyCapacity: [null],
              partyCapacityDetail: ['']
            }
          }
        },
        dealingNumber: NO_FALLBACK,
        parentInterest: NO_FALLBACK,
        siilReference: NO_FALLBACK,
        titleInterestsInLand: NO_FALLBACK
      }
    }
  },
  caveators: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        titleType: NO_FALLBACK,
        subJurisdiction: NO_FALLBACK,
        titleShareFraction: NO_FALLBACK,
        reference: NO_FALLBACK,
        isSelected: [false],
        isPartLandAffected: [false],
        partDescription: [VISIBILITY_CHECK_CAVEATS$TITLE_REFERENCES$PART_DESCRIPTION, null as any, ''],
        landDescriptions: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              isSelected: [false],
              legalDescription: NO_FALLBACK,
              landDescriptionComponents: NO_FALLBACK
            }
          }
        },
        sltReference: NO_FALLBACK
      }
    }
  },
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK
};
