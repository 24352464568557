import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    titleLink: {
      lineHeight: 1.5,
      minWidth: 0,
      fontWeight: 'bold',
      fontSize: 18
    },
    titleLeftIcon: {
      marginLeft: -16,
      fontSize: 18
    },
    titleRightIcon: {
      fontWeight: 'bold'
    },
    iconLocked: {
      minWidth: 14,
      height: 16,
      padding: theme.spacing(0.25, 0.5, 0.25, 0.25)
    },
    alignCenter: {
      alignItems: 'center'
    },
    transactionTypeTitle: {
      color: '#D6AB00',
      fontSize: 12,
      fontWeight: 500
    }
  });

export type ClassKeys = typeof styles;

export default styles;
