import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';

import { QLD_TENANCY_COMPONENT_LABEL_CONFIG } from '../../components/tenancy/config';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const QLD_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...QLD_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.Mortgagee
};

export const QLD_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...QLD_TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: 'Mortgagor',
  footer: { jointTenants: 'Add Mortgagor', tenantsInCommon: '' }
};
