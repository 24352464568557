import { resolveReceivingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model> = {
  partyBookApi: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  invalidParties: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  deceasedTenancyDetail: NO_FALLBACK,
  applicantCapacity: [null],
  applicants: resolveReceivingTenancyDetailFallback(),
  granteeCapacity: [null],
  grantees: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        partyBookId: [''],
        consent: [false]
      }
    }
  }
};
