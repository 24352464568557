import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { SetOfOperativeWordsEnum, SetOfOperativeWordsLookup } from '../../enums';
import { DischargeOfMortgage_2_26_0_Model } from '../../models';
import { VISIBILITY_CHECK_MORTGAGEES } from '../../visibilityChecks';

// path: setOfOperativeWords
const yupSetOfOperativeWords = validateWhenVisible2<SetOfOperativeWordsEnum | null>({
  visibilityCheck: (parentValue: DischargeOfMortgage_2_26_0_Model) => VISIBILITY_CHECK_MORTGAGEES(parentValue),
  validationWhenVisible: yup.mixed<SetOfOperativeWordsEnum>().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(SetOfOperativeWordsLookup), msg.INVALID_SELECTION)
});

export default yupSetOfOperativeWords;
