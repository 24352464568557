import React from 'react';

import { useFormikContext } from 'formik';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import SectionTenancyReceiving from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { LookupEnumModel } from '@sympli-mfe/enums-shared/models';

import { getProprietorAndCustomPartyBook, isProprietorOrMortgagee } from '../../helpers';
import { BaseCaveatModel } from '../../models';

interface Props {
  name: string;
  partyFormConfig: PartyFormConfig;
  partyCapacityLookup: LookupEnumModel<any>[];
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  syncPartyReferences: (tenancyParty: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

export const QLD_OTHER_PARTY_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  sectionTitle: 'Other party details',
  partyRole: 'Other party',
  partyCapacity: 'Capacity',
  footer: { jointTenants: 'Add other party', tenantsInCommon: '' },
  header: {
    jointTenants: 'Other party',
    tenantsInCommon: ''
  },
  items: {
    jointTenants: 'Other party',
    tenantsInCommon: ''
  }
};

function SectionOtherPartyDetails({ name, partyFormConfig, partyCapacityLookup, addressFormConfig, addressFormInitialValues, syncPartyReferences }: Props): JSX.Element {
  const formikProps = useFormikContext<BaseCaveatModel>();
  const { values } = formikProps;
  const { partyBook, titleReferences } = values;
  const proprietorAndCustomPartyBook = getProprietorAndCustomPartyBook(partyBook, titleReferences);

  return (
    <SectionTenancyReceiving //
      name={name}
      data-testid={name}
      componentLabels={QLD_OTHER_PARTY_TENANCY_COMPONENT_LABEL_CONFIG}
      allowNewGroup={false}
      canRemoveAllItems={true}
      enablePartyCapacityDetail={true}
      partyFormConfig={partyFormConfig}
      addressFormConfig={addressFormConfig}
      addressFormInitialValues={addressFormInitialValues}
      partyCapacityLookup={partyCapacityLookup}
      filterPartyBook={(party: PartyModel<any>) => proprietorAndCustomPartyBook.some(p => p.id === party.id)}
      onDataChange={async (tenancyParty: TenancyPartyModel, fieldChanged: TenancyPartyFields) => {
        if (
          isProprietorOrMortgagee(values, tenancyParty.partyBookId) &&
          (fieldChanged === TenancyPartyFields.PartyCapacity || fieldChanged === TenancyPartyFields.PartyCapacityDetail)
        ) {
          return new Promise<TenancyPartyModel>(resolve => resolve(tenancyParty));
        }

        return syncPartyReferences(tenancyParty, fieldChanged);
      }}
    />
  );
}

export default React.memo(SectionOtherPartyDetails);
