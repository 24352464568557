import * as React from 'react';
import { memo } from 'react';

import Chip from '@mui/material/Chip';
import { SxProps, Theme } from '@mui/material/styles';

function ChipDetail({ text, sx }: { text: React.ReactNode; sx?: SxProps<Theme> }) {
  return (
    <Chip
      label={text}
      sx={[
        {
          background: 'var(--greek-waters-translucent)',
          '& .MuiChip-label': {
            color: 'var(--sympli-green)',
            fontFamily: 'Roboto',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            padding: '6px 24px',
            lineHeight: '16px'
          }
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    />
  );
}

export default memo(ChipDetail);
