import * as React from 'react';

import classNames from 'classnames';
import { getIn } from 'formik';
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { getFormikError, MinimalFormikState } from 'src/utils/formUtils';
import styles, { ClassKeys } from './styles';

type ChildrenAsFn = (jsxError?: React.ReactNode) => React.ReactNode;

interface OwnProps<T, F extends MinimalFormikState<T>> extends FormHelperTextProps {
  name: string;
  mode?: 'default' | 'text';
  formikProps: F;
  skipTouchedCheck?: boolean;
  children?: ChildrenAsFn | React.ReactNode;
}

type Props<T, F extends MinimalFormikState<T>> = OwnProps<T, F> & WithStyles<ClassKeys>;

class ErrorMessage<T, F extends MinimalFormikState<T>> extends React.Component<Props<T, F>> {
  public static defaultProps: Partial<Props<any, any>> = {
    mode: 'default'
  };

  shouldComponentUpdate(props: Props<T, F>) {
    const { name, children } = props;
    if (typeof children === 'function') {
      return true;
    } else if (
      getIn(this.props.formikProps.values, name) !== getIn(props.formikProps.values, name) ||
      getIn(this.props.formikProps.errors, name) !== getIn(props.formikProps.errors, name) ||
      getIn(this.props.formikProps.touched, name) !== getIn(props.formikProps.touched, name) ||
      Object.keys(this.props).length !== Object.keys(props).length
    ) {
      return true;
    }

    return false;
  }

  render() {
    let {
      //
      name,
      mode,
      className,
      formikProps,
      skipTouchedCheck,
      children,
      classes,
      ...rest
    } = this.props;

    const errorMessage = getFormikError(formikProps, name, skipTouchedCheck);

    if (errorMessage) {
      if (mode === 'text') {
        return errorMessage;
      }

      const jsxError = (
        <FormHelperText {...rest} className={classNames(classes.root, className)} error>
          {errorMessage}
        </FormHelperText>
      );

      if (typeof children === 'function') {
        return (children as ChildrenAsFn)(jsxError);
      } else if (children) {
        const child = React.Children.only(children) as JSX.Element;
        return (
          <>
            {React.cloneElement(child, { className: classNames(child.props.className, classes.hasError) })}
            {jsxError}
          </>
        );
      }

      return jsxError;
    }

    if (children) {
      if (typeof children === 'function') {
        return (children as ChildrenAsFn)();
      }
      return children;
    }
    return null;
  }
}

export default withStyles(styles)(ErrorMessage);
