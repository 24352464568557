import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import http from 'src/utils/http';

export type JoinWorkspaceApiRequest = {
  workspaceId: string;
  groupId: string;
  reference: string;
  acceptSettlementDate: boolean;
  role: WorkspaceRoleEnum;
};

export type JoinWorkspaceApiResponse = {
  isSuccessful: boolean;
  participantId?: string;
  validationErrorType?: JoinWorkspaceErrorEnum;
};

export enum JoinWorkspaceErrorEnum {
  ActiveWorkspaceNotFound = 0,
  WorkspaceLocked = 1,
  RoleNotAllowedOnTitle = 2,
  InvalidRoleSelected = 3,
  InvitationExistsForRole = 4
}

export function joinWorkspace(body: JoinWorkspaceApiRequest): Promise<JoinWorkspaceApiResponse> {
  return http.post<
    //
    JoinWorkspaceApiResponse,
    JoinWorkspaceApiRequest
  >('/workspaces/join', body);
}
