import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      paddingLeft: 18,
      width: 'auto'
    },
    boxContainer: {
      marginTop: 32,
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 8
    },
    divider: {
      marginTop: 5,
      marginBottom: 5
    },
    title: {
      color: colors.NEUTRAL_1000,
      fontFamily: 'volkswagen_serial-medium',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '40px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
