import { call, put, takeLatest } from 'typed-redux-saga';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { GeneralSagasErrorModel } from 'src/store/globalErrorMiddleware';
import http from 'src/utils/http';
import {
  actionFetchParticipantConversationSummary,
  actionFetchParticipantConversationSummarySilently,
  actionSendConversationMessage,
  actionUpdateConversationIsRead,
  actionUpdateSendConversationMessage,
  SendConversationMessageApiRequest,
  UpdateConversationApiRequest
} from './actions';
import { ConversationDocumentsApiRequest, ConversationDocumentsApiResponse } from './models';

const fetchParticipantConversationSummary = (payload: ConversationDocumentsApiRequest) => {
  const { workspaceId, participantId } = payload;
  const uri = `/workspaces/${workspaceId}/participants/${participantId}/conversations`;
  return http.get<ConversationDocumentsApiResponse>(uri);
};

export function* sagaFetchParticipantConversationSummary(action: ReturnType<typeof actionFetchParticipantConversationSummary.request>) {
  try {
    const data = yield* call(fetchParticipantConversationSummary, action.payload);
    yield put(actionFetchParticipantConversationSummary.success({ data }));
  } catch (error) {
    yield put(actionFetchParticipantConversationSummary.failure({ error }));
  }
}

const sendConversationMessage = (payload: SendConversationMessageApiRequest) => {
  const { workspaceId, participantId, conversationId, message } = payload;
  const uri = `/workspaces/${workspaceId}/participants/${participantId}/conversations/${conversationId}/messages`;
  return http.post<undefined>(uri, { message });
};

export function* sagaSendConversationMessage(action: ReturnType<typeof actionSendConversationMessage.request>) {
  try {
    yield* call(sendConversationMessage, action.payload);
    yield put(actionSendConversationMessage.success());
    yield put(actionUpdateSendConversationMessage({ messageId: action.payload.id, conversationId: action.payload.conversationId, isSuccess: true }));
  } catch (error) {
    const errorPayload: GeneralSagasErrorModel = { error, skipGlobalErrorHandling: true };
    yield put(actionSendConversationMessage.failure(errorPayload));
    yield put(actionUpdateSendConversationMessage({ messageId: action.payload.id, conversationId: action.payload.conversationId, isSuccess: false }));
    yield put(
      actionOpenGlobalSimpleNotification({
        message: 'Sending message failed',
        autoHideDuration: 3000,
        variant: 'new-warning'
      })
    );
  }
}

const updateConversationIsRead = (payload: UpdateConversationApiRequest) => {
  const { workspaceId, participantId, conversationId, isRead } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/conversations/${encodeURIComponent(conversationId)}`;
  // TODO define payload [#2307]
  return http.post(uri, { isRead });
};

export function* sagaUpdateConversationIsRead(action: ReturnType<typeof actionUpdateConversationIsRead.request>) {
  try {
    yield* call(updateConversationIsRead, action.payload);
    yield put(actionUpdateConversationIsRead.success());
  } catch (error) {
    yield put(actionUpdateConversationIsRead.failure({ error }));
  }
}

export function* sagaFetchParticipantConversationSummarySilently(action: ReturnType<typeof actionFetchParticipantConversationSummarySilently.request>) {
  try {
    const data = yield* call(fetchParticipantConversationSummary, action.payload);
    yield put(actionFetchParticipantConversationSummarySilently.success({ data, conversationId: action.payload.conversationId }));
  } catch (error) {
    yield put(actionFetchParticipantConversationSummarySilently.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchParticipantConversationSummary.request, sagaFetchParticipantConversationSummary),
  takeLatest(actionUpdateConversationIsRead.request, sagaUpdateConversationIsRead),
  takeLatest(actionSendConversationMessage.request, sagaSendConversationMessage),
  takeLatest(actionFetchParticipantConversationSummarySilently.request, sagaFetchParticipantConversationSummarySilently)
];
