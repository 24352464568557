import isAfter from 'date-fns/isAfter';
import dateformat from 'dateformat';

import { convertDateObject, getDateRange } from 'src/components/date-range-picker/helpers';
import { DateRangeTypeEnum } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum, DashboardDateRangeModel, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { WorkspaceStatusEnum } from './filters/models';
import { SortingOptionEnum, StandaloneDashboardFilterModel, StandaloneDashboardRouteTabsEnumV2, StandaloneWorkspacesV2ApiRequest } from './models';

export const resolveFilterTabName = (tab: string) => {
  switch (tab) {
    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return 'to be reworked';
    case StandaloneDashboardRouteTabsEnumV2.all:
    case StandaloneDashboardRouteTabsEnumV2.active:
    case StandaloneDashboardRouteTabsEnumV2.archived:
    case StandaloneDashboardRouteTabsEnumV2.complete:
    case StandaloneDashboardRouteTabsEnumV2.recent:
    default:
      return tab;
  }
};

export const resolveSavedTabFiltersQuery = (currentTabFilters: StandaloneDashboardFilterModel, assignmentType: AssignmentTypeEnum): StandaloneWorkspacesV2ApiRequest => {
  const { selectedTab, selectedFilters, lastAccessPeriod, completedDatePeriod, archivedDatePeriod, ...rest } = currentTabFilters;

  return {
    pageNumber: 1,
    pageSize: 20,
    ...convertDateObject(lastAccessPeriod, 'lastAccessPeriod'),
    ...convertDateObject(completedDatePeriod, 'completedDatePeriod'),
    ...convertDateObject(archivedDatePeriod, 'archivedDatePeriod'),
    ...rest
  };
};

// TODO replace below
export const defaultTabQuery = (
  //
  selectedTab: StandaloneDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum,
  groupId: string | undefined
): StandaloneWorkspacesV2ApiRequest => {
  let sortBy: string | undefined = undefined;
  let sortOrder: DashboardSortDirectionEnum | undefined = DashboardSortDirectionEnum.asc;
  let lastAccessPeriod: DashboardDateRangeModel | undefined = undefined;
  let workspaceStatus: number[] = [];
  let groupIds: string[] = [];

  const { from, to } = getDateRange(DateRangeTypeEnum.Today);
  const pageSize = 20;
  const pageNumber = 1;
  workspaceStatus = getWorkspaceStatusFilter(selectedTab, assignmentType);
  if (assignmentType === AssignmentTypeEnum.ReAssigned && groupId) {
    groupIds = [groupId];
  }
  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.all: {
      sortBy = SortingOptionEnum.LastAccessed;
      sortOrder = DashboardSortDirectionEnum.desc;
      break;
    }
    case StandaloneDashboardRouteTabsEnumV2.active: {
      sortBy = SortingOptionEnum.LastAccessed;
      sortOrder = DashboardSortDirectionEnum.desc;
      break;
    }
    case StandaloneDashboardRouteTabsEnumV2.toBeReworked: {
      sortBy = SortingOptionEnum.LastAccessed;
      sortOrder = DashboardSortDirectionEnum.asc;
      break;
    }
    case StandaloneDashboardRouteTabsEnumV2.complete: {
      sortBy = SortingOptionEnum.CompletedDate;
      sortOrder = DashboardSortDirectionEnum.desc;
      break;
    }
    case StandaloneDashboardRouteTabsEnumV2.archived: {
      sortBy = SortingOptionEnum.ArchivedDate;
      sortOrder = DashboardSortDirectionEnum.desc;
      break;
    }
    case StandaloneDashboardRouteTabsEnumV2.recent: {
      sortOrder = DashboardSortDirectionEnum.desc;
      sortBy = SortingOptionEnum.LastAccessed;
      lastAccessPeriod = {
        start: from,
        end: to
      };
      break;
    }
  }

  return { sortBy, sortOrder, pageSize, pageNumber, lastAccessPeriod, workspaceStatus, assignmentType: assignmentType, groupIds: groupIds };
};

const getWorkspaceStatusFilter = (selectedTab: StandaloneDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): number[] => {
  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    return getReassignWorkspaceStatusFilter(selectedTab);
  }
  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.all:
      return [
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready,
        WorkspaceStatusEnum.Queued,
        WorkspaceStatusEnum.Lodging,
        WorkspaceStatusEnum.Lodged,
        WorkspaceStatusEnum.Completed
      ];
    case StandaloneDashboardRouteTabsEnumV2.active:
      return [
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready,
        WorkspaceStatusEnum.Queued,
        WorkspaceStatusEnum.Lodging,
        WorkspaceStatusEnum.Lodged
      ];
    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return [WorkspaceStatusEnum.InPreparationWithErrors];
    case StandaloneDashboardRouteTabsEnumV2.complete:
      return [WorkspaceStatusEnum.Completed];
    case StandaloneDashboardRouteTabsEnumV2.archived:
      return [WorkspaceStatusEnum.Archived, WorkspaceStatusEnum.Abandoned, WorkspaceStatusEnum.Withdrawn];
    case StandaloneDashboardRouteTabsEnumV2.recent:
      return [
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready,
        WorkspaceStatusEnum.Queued,
        WorkspaceStatusEnum.Lodging,
        WorkspaceStatusEnum.Lodged,
        WorkspaceStatusEnum.Completed
      ];
  }
};

const getReassignWorkspaceStatusFilter = (selectedTab: StandaloneDashboardRouteTabsEnumV2): number[] => {
  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.all:
    case StandaloneDashboardRouteTabsEnumV2.active:
    case StandaloneDashboardRouteTabsEnumV2.recent:
      return [WorkspaceStatusEnum.InPreparation, WorkspaceStatusEnum.InPreparationWithErrors, WorkspaceStatusEnum.Ready];
    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return [WorkspaceStatusEnum.InPreparationWithErrors];
    default:
      return [];
  }
};

export const shouldUpdateTime = (updatedTime: Date, currentUpdatedTime: Date) => {
  return (
    isAfter(updatedTime, currentUpdatedTime) &&
    dateformat(updatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER) !== dateformat(currentUpdatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER)
  );
};

export const getDefaultAssignmentTypeTab = (key: string): AssignmentTypeEnum => {
  const data = localStorage.getItem(key);
  if (data) {
    return Number(data) as AssignmentTypeEnum;
  }
  return AssignmentTypeEnum.AssignedToMe;
};
