import * as yup from 'yup';

import { $exactlyOneOfItems, $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DocumentBaseTitleReferenceModel } from '../../../../models';
import { DischargeOfMortgage_2_23_0_Model, MortgageePartyModel, MortgageModel } from '../../models';
import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from '../../visibilityChecks';

// path: mortgages
const yupMortgages = yup
  .array<MortgageModel>()
  .required(msg.REQUIRED)
  .test(
    testContextualRule({
      /**
           * what we found in schema:
           [{"required":{"mortgages":{"$items":{"$exactlyOne":{"isSelected":true}}}},"message":"Only one mortgage can be discharged at a time"}]
           *
           */
      uniqueTestName: '"mortgages" contextual validation rule #1',
      requirement: (mortgages: MortgageModel[], context: DischargeOfMortgage_2_23_0_Model): boolean =>
        $exactlyOneOfItems(context.mortgages ?? [], item => item.isSelected === true),
      message: 'Only one mortgage can be discharged per document'
    })
  )
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .of(
    yup
      .object<MortgageModel>()
      .nullable(true)
      .shape({
        isSelected: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
        mortgagees: yup //
          .mixed<MortgageePartyModel[]>()
          .test(
            validateWhenVisible({
              visibilityCheck: VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES,
              validationWhenVisible: yup
                .array<MortgageePartyModel>()
                .nullable(true)
                .of(
                  yup
                    .object<MortgageePartyModel>()
                    .nullable(true)
                    .shape({
                      partyBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED), // suspicious validation, user can't see this!
                      addressBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED) // suspicious validation, user can't see this!
                    })
                ),
              isObjectOrArray: true
            })
          ),
        titleReferences: yup //
          .mixed<DocumentBaseTitleReferenceModel[]>()
          .test(
            validateWhenVisible({
              visibilityCheck: VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES,
              validationWhenVisible: yup
                .array<DocumentBaseTitleReferenceModel>()
                .required(msg.REQUIRED)
                .test(
                  testContextualRule({
                    /**
           * what we found in schema:
           [{"required":{"titleReferences":{"$items":{"$oneOf":{"isSelected":true}}}},"message":"At least one title reference needs to be selected"}]
           *
           */
                    uniqueTestName: '"mortgages[*].titleReferences" contextual validation rule #1',
                    requirement: (titleReferences: DocumentBaseTitleReferenceModel[], context: DischargeOfMortgage_2_23_0_Model): boolean =>
                      $oneOfItems(context.titleReferences ?? [], item => item.isSelected === true),
                    message: 'At least 1 title reference needs to be selected'
                  })
                )
                .min(1, msg.MIN_ITEMS(1))
                .of(
                  yup
                    .object<DocumentBaseTitleReferenceModel>()
                    .nullable(true)
                    .shape({
                      isSelected: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED)
                    })
                ),
              isObjectOrArray: true
            })
          )
      })
  );

export default yupMortgages;
