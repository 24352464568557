import * as React from 'react';

import { DirectionCategoryEnum } from '@sympli/api-gateway/enums';
import Checkbox, { CheckboxProps } from '@sympli/ui-framework/components/form/base-components/checkbox';
import BaseField, { FieldComponentProps } from '@sympli/ui-framework/components/formik/base-field';

// import { connect, getIn } from 'formik';

type Props = FieldComponentProps<CheckboxProps>;

export default class VendorDistributionCheckboxField extends BaseField<Props> {
  public static displayName = 'CheckboxField';

  public getFieldValue = (e: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => {
    if (e.target.checked) {
      return DirectionCategoryEnum.Settlement;
    }
    return DirectionCategoryEnum.Purchaser;
  };

  render() {
    const { value, label, ...restOfProps } = this.resolveComponentProps<CheckboxProps>();
    const checked = value === DirectionCategoryEnum.Settlement;
    return <Checkbox label={label} {...restOfProps} checked={checked} />;
  }
}
