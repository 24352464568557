import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    overdueWarning: {
      color: '#E15252',
      marginTop: 0
    },
    listItem: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      color: theme.palette.text.primary,
      background: theme.palette.grey[50],
      marginBottom: theme.spacing(1),
      '&:hover': {
        borderColor: theme.palette.text.secondary
      }
    },
    listItemFirst: {
      flex: '0 1 auto',
      marginRight: '5%'
    },
    listItemReference: {
      color: theme.palette.grey[400]
    },
    downloadLabel: {
      textDecoration: 'underline',
      fontWeight: 'bold'
    },
    downloadIcon: {
      color: theme.palette.text.primary,
      paddingRight: 5
    }
  });

export type ClassKeys = typeof styles;

export default styles;
