import * as yup from 'yup';

import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { EXTENT_OF_LAND_DISCHARGED_OR_EXTENT_OF_MONEY_DISCHARGED_LOOKUP_OPTIONS, ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum } from '../../enums';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: extentOfLandDischarged
const yupExtentOfLandDischarged = yup
  .mixed<ExtentOfLandDischarged_Or_ExtentOfMoneyDischargedEnum>()
  .required(msg.REQUIRED)
  .oneOf(getLookupValuesAllowingEmpty(EXTENT_OF_LAND_DISCHARGED_OR_EXTENT_OF_MONEY_DISCHARGED_LOOKUP_OPTIONS), msg.INVALID_SELECTION);

export default yupExtentOfLandDischarged;
