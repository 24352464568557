import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const AdditionalAttachmentDocumentTypes: AttachmentTypeEnum[] = [
  AttachmentTypeEnum.TermsAndConditions,
  AttachmentTypeEnum.MinistersConsent,
  AttachmentTypeEnum.CaveatorsConsent
];

export const ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS: LookupEnumModel<AttachmentTypeEnum>[] = AdditionalAttachmentDocumentTypes.map(
  (x): LookupEnumModel<AttachmentTypeEnum> => ({ id: x, name: x })
);
