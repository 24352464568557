import { Dispatch } from 'redux';
import SocketIO, { Socket } from 'socket.io-client';

import Logger, { PageActionEnum, SeverityEnum } from '@sympli/ui-logger';

import { actionPushErrorToQueue, actionRemoveGlobalErrorById } from 'src/actions/globalErrors';
import { DialogTypeEnum } from 'src/components/message-dialog/models';
import environments from 'src/environments';
import { AppEnvironmentEnum } from 'src/environments/models';
import { AuthenticationAck, ConnectParams } from './model';
import { SocketClientEventEnum } from './workspace-update/events';

const CONNECT = 'connect';
const DISCONNECT = 'disconnect';
const AUTHENTICATE = 'authenticate';

// type DisconnectDescription =
//   | Error
//   | {
//       description: string;
//       context?: unknown;
//     };

export function obfuscate(text?: string) {
  return text ? text.replace(/(...)./g, '$1*') : undefined;
}

export class SocketIoConnectionBase<T extends ConnectParams = ConnectParams> {
  protected socket: Socket;

  private socketPath: string;
  private socketServerUri: string;

  //TODO review why do we need this token here?
  private token?: string;

  private readonly RECONNECTION: boolean = true;
  private readonly RECONNECTION_DELAY: number = 1000;
  private readonly RECONNECTION_DELAY_MAX: number = 5000;
  private readonly RECONNECTION_ATTEMPTS = 5;

  constructor(serverPathUri: string, socketPath: string) {
    this.socketServerUri = `${environments.SOCKET_SERVER_URL}${serverPathUri}`;
    this.socketPath = socketPath;
  }

  public connect(params: T, dispatch: Dispatch<any>) {
    Logger.capturePageAction(PageActionEnum.Realtime, {
      message: 'Connect requested',
      socketPath: this.socketPath,
      ...(params as any),
      token: obfuscate(params.token)
    });
    this.disconnect();
    this.initialiseSocketConnection();

    // Fired upon connection to the Namespace (including a successful reconnection).
    this.socket.on(CONNECT, () => {
      if ([AppEnvironmentEnum.local, AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.iwt].includes(environments.APP_ENV)) {
        dispatch(
          // cleanup previous disconnect message
          actionRemoveGlobalErrorById({
            id: DISCONNECT,
            snackbar: true
          })
        );

        dispatch(
          actionPushErrorToQueue({
            type: DialogTypeEnum.Success,
            message: 'Realtime - connected',
            id: CONNECT,
            snackbar: true,
            autohideSec: 5
          })
        );
      }
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Connected',
        socketPath: this.socketPath,
        socketId: this.socket?.id,
        ...(params as any),
        token: obfuscate(params.token)
      });

      Logger.console(SeverityEnum.Info, `%cconnected socketId: ${this.socket.id}`, 'color:green');
      this.emitLog({ ...params, socketIdClientSide: this.socket.id, message: 'The client has confirmed connection' });
      // after connection setup, we need to validate token
      this.authenticate(params);
    });

    /**
     * https://socket.io/docs/v4/client-api/#event-disconnect
     * For io client disconnect or io server disconnect, the client will not try to reconnect and you need to manually call socket.connect().
     */
    // Fired upon disconnection
    this.socket.on(DISCONNECT, (reason: Socket.DisconnectReason) => {
      if ([AppEnvironmentEnum.local, AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.iwt].includes(environments.APP_ENV)) {
        dispatch(
          // cleanup previous connection message
          actionRemoveGlobalErrorById({
            id: CONNECT,
            snackbar: true
          })
        );
        if (reason !== 'io client disconnect') {
          dispatch(
            actionPushErrorToQueue({
              type: DialogTypeEnum.Error,
              message: 'Realtime - connection lost',
              id: DISCONNECT,
              snackbar: true
            })
          );
        }
      }
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Disconnected',
        reason,
        // not sure about the values here, are these already updated by new connection?
        socketPath: this.socketPath,
        socketId: this.socket?.id,
        ...(params as any),
        token: obfuscate(params.token)
      });
      Logger.console(SeverityEnum.Info, `%cdisconnected ${this.socketPath} due to: ${reason}`, 'color:red');
    });

    this.socket.io.on('reconnect_attempt', attempt => {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Reconnect attempt',
        attempt,
        socketPath: this.socketPath,
        socketId: this.socket?.id,
        ...(params as any),
        token: obfuscate(params.token)
      });
      // we have no socket id at this stage
      this.emitLog({ ...params, attempt, message: 'Client tried to reconnect realtime server.' });
    });

    // Fired when couldn't reconnect within reconnectionAttempts
    this.socket.io.on('reconnect_failed', () => {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: `Reconnect failed after ${this.RECONNECTION_ATTEMPTS} attempts`,
        socketPath: this.socketPath,
        socketId: this.socket?.id,
        ...(params as any),
        token: obfuscate(params.token)
      });
      // because connection is closed, we can only send log to NewRelics
      const scope = Logger.scopeWithCustomAttributes({
        //
        ...params,
        token: obfuscate(params.token)
      });
      Logger.captureException(new Error(`Socket reconnect failed after ${this.RECONNECTION_ATTEMPTS} times`), scope);
    });

    this.socket.io.on('ping', () => {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: `Ping received from server`,
        socketPath: this.socketPath,
        socketId: this.socket?.id,
        ...(params as any),
        token: obfuscate(params.token)
      });
    });

    return this;
  }

  public manualReConnect() {
    Logger.capturePageAction(PageActionEnum.Realtime, {
      message: 'Manual reconnect requested',
      socketPath: this.socketPath,
      socketId: this.socket?.id
    });
    Logger.console(SeverityEnum.Warning, 'Manual socket reconnect');
    this.socket.connect();
  }

  public disconnect() {
    if (this.socket) {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Disconnect requested',
        socketPath: this.socketPath,
        socketId: this.socket?.id
      });
      this.socket.disconnect();
    }
    return this;
  }

  private authenticate(params: T) {
    const { token } = params;
    Logger.capturePageAction(PageActionEnum.Realtime, {
      message: 'Authentication requested',
      socketPath: this.socketPath,
      socketId: this.socket?.id,
      ...(params as any),
      token: obfuscate(token)
    });

    this.socket.emit(AUTHENTICATE, { ...params, socketIdClientSide: this.socket.id }, (ack: AuthenticationAck) => {
      if (ack.authenticated) {
        Logger.console(SeverityEnum.Debug, 'authenticated');
        this.token = token;
        this.onAuthenticated(params);
      }
    });
  }

  public get isConnected() {
    return this.socket && this.socket.connected;
  }

  public emitWithAuth<T>(event: string, payload: T, callback?: (...args: any[]) => void): Socket {
    return this.socket.emit(event, { ...payload, socketIdClientSide: this.socket.id }, callback);
  }

  public emitLog<T>(payload: T, callback?: (...args: any[]) => void): Socket {
    return this.socket.emit(SocketClientEventEnum.WorkspaceClientLog, { ...payload, socketIdClientSide: this.socket.id }, callback);
  }

  protected onAuthenticated(params: T) {
    Logger.capturePageAction(PageActionEnum.Realtime, {
      message: 'Authenticated',
      socketPath: this.socketPath,
      socketId: this.socket?.id,
      ...(params as any),
      token: obfuscate(params.token)
    });
  }

  private initialiseSocketConnection() {
    Logger.capturePageAction(PageActionEnum.Realtime, {
      message: 'Initialise new SocketIO instance',
      socketPath: this.socketPath
    });
    this.socket = SocketIO(this.socketServerUri, {
      path: this.socketPath,
      reconnection: this.RECONNECTION,
      reconnectionDelay: this.RECONNECTION_DELAY,
      reconnectionDelayMax: this.RECONNECTION_DELAY_MAX,
      reconnectionAttempts: this.RECONNECTION_ATTEMPTS,
      forceNew: true,
      withCredentials: true
    });
  }
}
