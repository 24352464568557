import React from 'react';

import classNames from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';

import { Card } from '@sympli/components-library/card';

export type AnimationVariant = //

    | 'success' //
    | 'error'
    | 'in-progress'
    | 'paused'
    | 'rollover'
    | 'lodged';

interface ProgressNotificationPanelProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  animationVariant?: AnimationVariant;
}

function resolveAnimationAssetUrl(animationVariant: ProgressNotificationPanelProps['animationVariant']) {
  switch (animationVariant) {
    case 'success':
      return new URL('./animations/STL-Successful.json', import.meta.url).href;
    case 'in-progress':
      return new URL('./animations/STL-In_Progress.json', import.meta.url).href;
    case 'paused':
      return new URL('./animations/STL-Paused.json', import.meta.url).href;
    case 'rollover':
      return new URL('./animations/STL-RollOver.json', import.meta.url).href;
    case 'error':
      return new URL('./animations/STL-Error.json', import.meta.url).href;
    case 'lodged':
      return new URL('./animations/LDG-Lodged.json', import.meta.url).href;
    default:
      return undefined;
  }
}

export function ProgressNotificationPanel({ className, animationVariant, children, ...props }: React.PropsWithChildren<ProgressNotificationPanelProps>) {
  const animationSrc: string | undefined = resolveAnimationAssetUrl(animationVariant);
  return (
    <Card
      {...props}
      className={classNames(
        //
        'flex items-center',
        animationVariant && 'gap-6 px-6 medium-screen:gap-8 medium-screen:px-10 large-screen:px-16',
        !animationVariant && 'px-8 py-6 medium-screen:px-16',
        className
      )}
    >
      {animationSrc && (
        <div className="relative flex h-[160px] w-[160px] flex-shrink-0 flex-grow-0 flex-col items-center justify-start">
          <Player //
            autoplay
            loop
            src={animationSrc}
            className="h-[160px] w-[160px]"
          />
          {animationVariant === 'paused' && (
            <div className="absolute bottom-2">
              <div className="text-center font-roboto text-sm font-bold not-italic leading-5 text-[color:var(--watermelon-luminous)]">Customer Support</div>
              <div className="text-center font-volkswagen-serial-regular text-[22px] font-extrabold not-italic leading-[normal] text-[color:var(--watermelon)]">1300 796 754</div>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(
          // layout
          'flex h-full flex-col justify-start gap-3',
          // font
          'font-roboto text-sm not-italic leading-5 text-[color:var(--neutral-700)]',
          // nested b tags
          '[&_b]:font-bold',
          // nested strong tags
          '[&_strong]:font-bold',
          // nested small tags
          'text-[color:var(--neutral-500) italic leading-4 [&>small]:text-xs'
        )}
      >
        {children}
      </div>
    </Card>
  );
}
