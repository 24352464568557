import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';

import { SA_ADDRESS_FORM_CONFIG, SA_ADDRESS_FORM_INITIAL_VALUES } from '@sympli-mfe/document-components/address-field/sa/2-26/config';
import { TenancyEditable as SectionTenancyEditable } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, NecdsPartyCapacityEnum, StateEnum } from '@sympli-mfe/enums-2-26/sa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli-mfe/enums-shared/models';

import { SA_MORTGAGE_PARTY_FORM_CONFIG, SA_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { Mortgage_2_26_0_Model } from '../../models';

interface Props {
  name: string;
}

export const PARTY_CAPACITY_LOOKUP_OPTIONS: LookupEnumModel<NecdsPartyCapacityEnum>[] = restrictedLookupOptions(NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    //
    NecdsPartyCapacityEnum.Administrator,
    NecdsPartyCapacityEnum.Executor,
    NecdsPartyCapacityEnum.ExecutorLeaveReserved,
    NecdsPartyCapacityEnum.Manager,
    NecdsPartyCapacityEnum.Receiver,
    NecdsPartyCapacityEnum.ProtectedPersonAgedAndInfirmPersons,
    NecdsPartyCapacityEnum.ProtectedPersonGuardianshipAndAdministration,
    NecdsPartyCapacityEnum.SurvivingExecutor,
    NecdsPartyCapacityEnum.UndischargedBankrupt,
    NecdsPartyCapacityEnum.YoungerUndischargedBankrupt,
    NecdsPartyCapacityEnum.ElderUndischargedBankrupt,
    NecdsPartyCapacityEnum.Elder,
    NecdsPartyCapacityEnum.Younger
  ]
});

function SectionMortgagors({ name }: Props): JSX.Element {
  const {
    values: { mortgageeDetails }
  } = useFormikContext<Mortgage_2_26_0_Model>();

  const mortgageesPartyIds = useMemo(
    () =>
      mortgageeDetails.receivingProprietorGroups
        .flatMap(x => x.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgageeDetails]
  );

  return (
    <SectionTenancyEditable //
      name={name}
      data-name={name}
      data-testid={name}
      jurisdiction={StateEnum.SA}
      componentLabels={SA_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG}
      allowNewGroup={false}
      partyCapacityLookup={PARTY_CAPACITY_LOOKUP_OPTIONS}
      // party setup
      partyFormConfig={SA_MORTGAGE_PARTY_FORM_CONFIG}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={mortgageesPartyIds}
      displayPartyCapacityDetail={true}
      // address setup
      addressFormConfig={SA_ADDRESS_FORM_CONFIG}
      addressFormInitialValues={SA_ADDRESS_FORM_INITIAL_VALUES}
    />
  );
}

export default React.memo(SectionMortgagors);
