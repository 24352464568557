import { ExtentOfProhibition } from '../../models';

export const resolveDefaultItem = () => {
  const defaultItem: ExtentOfProhibition = {
    actionProhibitedOption: null,
    qualification: '',
    lrDocumentId: ''
  };

  return defaultItem;
};
