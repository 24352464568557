import { SA_ADDRESS_FORM_CONFIG as SA_M_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-23/config';
import { saNameChangeConversion } from '@sympli-mfe/document-components/party-form/sa/2-23/components/party-justification';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { ConversionMap, convertDataStructure } from '@sympli-mfe/document-forms-framework/utils';

import { SA_MORTGAGE_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterMortgagors } from './helper';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER } from './isRequiredChecks';
import { ApiMortgage_2_23_0_Model, Mortgage_2_23_0__Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: SA_M_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: SA_MORTGAGE_PARTY_FORM_CONFIG,
  // Add name change conversion here if necessary
  nameChangeConversion: saNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiMortgage_2_23_0_Model, context: IConverterContext): Mortgage_2_23_0__Model {
  const conversionMap: ConversionMap = {
    transfereeGroups: 'receivingProprietorGroups',
    tenancyType: 'shareSplitType'
  };
  apiModel = convertDataStructure(apiModel, conversionMap);

  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.titleReferences.map(titleReference => titleReference.mortgagors.map(mortgagor => mortgagor.partyBookId)).flat();

  let formModel: Mortgage_2_23_0__Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
    mortgagors: filterMortgagors(apiModel),
    mortgageeDetails: convertReceivingTenancyDetailFromApiToForm(apiModel.mortgageeDetails)
  };
  formModel = applyDefaultMap(formModel, fallbackMap);

  // WEB-18683: The term number was not filled with the default one.
  // The following logic will check if the standard term dealing number is required or not.
  // If the user has provided one, or it provided the additional term number or uploaded documents, the standard term dealing number is not required.
  // It will fill the standard dealing term number with the default one in the user's setting only when it is empty and required.
  // The empty check is actually a safe guard just in case the require check failed to check the emptiness and overwrite the value already filled by the user.
  const termsAndConditions = formModel.termsAndConditions;
  const firstStandardTermsDealingNumber = termsAndConditions.standardTermsDealingNumbers[0];
  const originalTermNumber = firstStandardTermsDealingNumber.termNumber;
  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER(firstStandardTermsDealingNumber, termsAndConditions);

  if (isRequired && !originalTermNumber) {
    firstStandardTermsDealingNumber.termNumber = termsAndConditions.preRegisteredStandardTermsDealingNumbers?.[0] || originalTermNumber;
  }
  return formModel;
}

function convertFromFormToApiModel(formModel: Mortgage_2_23_0__Model, originalApiModel: ApiMortgage_2_23_0_Model): ApiMortgage_2_23_0_Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);

  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    mortgageeDetails,
    titleReferences,
    mortgagors,
    ...rest
  } = formModel;

  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []) as any, // TODO resolve this typing
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
    titleReferences: titleReferences.map(t => {
      return {
        ...t,
        mortgagors: t.mortgagors.map(m => {
          return mortgagors.find(x => x.partyBookId === m.partyBookId) || m; // the could be happen if user modify the mortgagors manually
        })
      };
    }),
    mortgageeDetails: convertReceivingTenancyDetailFromFormToApi({
      formReceivingTenancyDetail: mortgageeDetails,
      shouldMapSurvivorshipType: true,
      partyBookFromForm: partyBookFromForm || []
    })
  };
}

class Converter implements IConverter<Mortgage_2_23_0__Model, ApiMortgage_2_23_0_Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
