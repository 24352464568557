import { DocumentAttachmentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';

export { NSW_PARTY_FORM_CONFIG as NSW_LEASE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const NSW_LEASE_DOCUMENT_ATTACHMENT_LABEL_CONFIG: DocumentAttachmentLabelConfig = {
  sectionTitle: 'Upload supporting document',
  uploaderTitle: 'Upload document',
  addButtonTitle: 'Add another document'
};
