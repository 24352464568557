import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import { colors } from 'src/theme';

interface Props {
  steps: Array<string>;
}

function PartiallySignedFinancialSettlementSummary(props: Props) {
  const { steps } = props;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Partially signed
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The Financial Settlement Schedule has now <b>been partially signed</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        To understand whether another user is required to sign, or whether only part of the FSS has been signed, please open the Financial Settlement Schedule.
      </Typography>
      <Breadcrumbs //
        currentStep={3}
        steps={steps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

export default React.memo(PartiallySignedFinancialSettlementSummary);
