import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    deleteButton: {
      marginRight: 6,
      marginBottom: 10
    },
    roleDropdownBox: {
      marginTop: 10,
      width: 200,
      '&>p': {
        display: 'none'
      }
    },
    divider: {
      marginBottom: 1
    },
    dividerSelectedRole: {
      marginTop: 14,
      marginBottom: 1
    },
    roleValidationErrorIcon: {
      color: theme.palette.error.main,
      paddingRight: 5
    },
    roleValidationError: {
      paddingLeft: 25,
      paddingBottom: 10
    }
  }),
  {
    name: 'AdditionalInvitationItem'
  }
);
