import * as React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconCheckCircle, IconExclamationCircle } from '@sympli/ui-framework/icons';

import { PanelVariantEnum } from '../../models';
import { useStyles } from './styles';

interface Props {
  variant: PanelVariantEnum;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  linkToWorkspace: string;
}

function DocumentNotificationPanel({
  //
  variant,
  primary,
  secondary,
  linkToWorkspace
}: Props) {
  const classes = useStyles();

  const Icon =
    variant === PanelVariantEnum.Success ? ( //
      <IconCheckCircle className={classNames(classes.icon, classes.success)} />
    ) : (
      <IconExclamationCircle className={classNames(classes.icon, classes.warning)} />
    );

  return (
    <FlexLayout //
      justifyContent="center"
      alignItems="center"
      fullWidth
      className={classNames(classes.root, variant === PanelVariantEnum.Success ? classes.success : classes.warning, classes.primary)}
    >
      {Icon}
      {primary}
      {secondary && <span className={classes.secondary}>{secondary}</span>}
      <Link to={linkToWorkspace} className={classes.link}>
        See workspace overview
      </Link>
    </FlexLayout>
  );
}

export default DocumentNotificationPanel;
