import React from 'react';

import { useNavigate } from 'react-router-dom';

/**
 * returns handler that can be directly used with button when we want to redirect the user to given location after clicking on button
 */
export default function useRedirectDispatch(targetLocation: string) {
  const navigate = useNavigate();
  return React.useCallback(() => {
    navigate(targetLocation);
  }, [navigate, targetLocation]);
}
