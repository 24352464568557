import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DealingRequirementsSummaryModel, DirectionPreferenceTypeModel, IssuingInstructionsModel, LodgementInstructions_2_25_1 } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - directionPreference
 - lodgementOrderSummary

 schema version:
[
  {
    "isResponsibleParticipant": true
  }
]
*/
export const VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY = createContextCheck(
  (context: LodgementInstructions_2_25_1): boolean => context.isResponsibleParticipant === true
);

/**
 path:
 - dealingRequirementsSummary.dealingRequirementsDetail

 schema version:
[
  {
    "hasAddedDealingRequirements": true
  }
]
*/
export const VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL = createContextCheck(
  (context: DealingRequirementsSummaryModel): boolean => context.hasAddedDealingRequirements === true
);

export const VISIBILITY_CHECK_DIRECTION_PREFERENCE = createContextCheck(
  (context: LodgementInstructions_2_25_1): boolean => context.directionPreference !== undefined && Object.keys(context.directionPreference).length > 0
);

export const VISIBILITY_CHECK_ISSUING_INSTRUCTIONS = createContextCheck(
  (context: LodgementInstructions_2_25_1): boolean => context.issuingInstructions !== undefined && Object.keys(context.issuingInstructions).length > 0
);

export const VISIBILITY_CHECK_ISSUING_INSTRUCTIONS_ENABLED = createContextCheck((context?: IssuingInstructionsModel): boolean => context !== undefined && context.editable);

export const VISIBILITY_CHECK_DIRECTION_PREFERENCE_ENABLED = createContextCheck((context: DirectionPreferenceTypeModel): boolean => context.editable);
