import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';
import sharedStyles from '../../styles';

const styles = (theme: Theme) => ({
  ...sharedStyles(theme),
  ...createStyles({
    formContainer: {
      padding: '20px 60px'
    },
    plusDesReadOnly: {
      fontSize: 13,
      fontStyle: 'italic',
      color: theme.palette.text.secondary
    },
    deleteButton: {
      position: 'absolute',
      left: -10,
      width: 48 //.MuiIconButton-root has removed the default 48 width
    },
    header: {
      color: colors.NEUTRAL_1000
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
