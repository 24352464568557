import { Action, createReducer } from 'typesafe-actions';

import useStoreSelector from 'src/hooks/useStoreSelector';
import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspaceGroups } from '../actions';
import { GroupOptionModel } from '../models';

export interface WorkspaceGroupsState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  items: GroupOptionModel[];
  error?: string;
}

const initialState: WorkspaceGroupsState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined
};

export function useWorkspaceGroups() {
  const state = useStoreSelector(store => store.workspaceGroups);
  return state;
}

const workspaceGroupsReducer = createReducer<
  //
  WorkspaceGroupsState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceGroups.request, (state): WorkspaceGroupsState => {
    const status = state.status === 'resolved' ? 'refetching' : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined
    };
  })
  .handleAction(actionFetchWorkspaceGroups.success, (state, action): WorkspaceGroupsState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.data
    };
  })
  .handleAction(actionFetchWorkspaceGroups.failure, (state, action): WorkspaceGroupsState => {
    return {
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    };
  });

export default workspaceGroupsReducer;
