import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getCharacterCountHelperText, modelKey, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { InputClassKeys, InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import StandardTermsDealingNumbersArray from '../../components/standard-terms-dealing-numbers-array';
import { IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS } from '../../isRequiredChecks';
import { StandardTermsModel } from '../../models';
import { useStyles } from './styles';
import { COVENANTS_MAX_CHARACTERS } from './validationSchema';

const ADDITIONAL_COVENANT_WARNING_VALUES: string[] = ['trust', 'trustee', 'beneficial', 'benefitial', 'benefisial', 'beneficiary', 'benefitiary'];
// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  queryParams?: DocumentsPageRouteAndQueryModel;
  additionalAttachments?: DocumentAttachmentItemModel[];
}

function resolveAdditionalCovenantsHelper(
  additionalCovenants: string,
  classes: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>>
): Pick<InputComponentProps, 'classes' | 'helperText'> {
  const additionalCovenantsCount = (additionalCovenants ?? '').trim().length;
  const helperText = getCharacterCountHelperText(additionalCovenantsCount, COVENANTS_MAX_CHARACTERS);

  if (ADDITIONAL_COVENANT_WARNING_VALUES.some(warningValue => ~additionalCovenants.indexOf(warningValue))) {
    return {
      helperText: `Please be aware that certain words within the terms and conditions are used for specific purposes only e.g. Trust, Trustee, Beneficial, Beneficiary. You may proceed to lodgement, however, there may be delays in registration. ${helperText}`,
      classes
    };
  }
  return {
    helperText
  };
}

function SectionTermsAndConditions({ additionalAttachments }: Props): JSX.Element {
  const classes = useStyles();
  const { values } = useFormikContext<StandardTermsModel>();
  const fieldClassesWarning: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControl: classes.additionalCovenantWarningBorder,
      formControlValidation: classes.additionalCovenantWarningHelperText
    };
  }, [classes]);

  const fieldClassesError: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControlValidation: classes.formControlValidation
    };
  }, [classes]);

  const { disabled } = useDocumentContext();
  const fieldName = modelKey<StandardTermsModel>();
  const isAdditionalTermsAndConditionsRequired = IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS(values.standardTermsDealingNumbers, additionalAttachments);

  const additionalTermsAndConditionsTitle = 'Additional terms and conditions';
  const additionalTermsAndConditionsPlaceholder = resolvePlaceholder(isAdditionalTermsAndConditionsRequired);
  const additionalCovenants = values.additionalCovenants.trim().toLowerCase();

  return (
    <>
      <StandardTermsDealingNumbersArray //
        name={fieldName('standardTermsDealingNumbers')}
        maxItems={20}
        minItems={1}
        additionalAttachments={additionalAttachments}
      />
      <FormGroup //
        title={additionalTermsAndConditionsTitle}
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('additionalCovenants')}
            component={InputField}
            disabled={disabled}
            classes={fieldClassesError}
            className={classes.noBottomMargin}
            fullWidth
            multiline
            minRows={2}
            placeholder={additionalTermsAndConditionsPlaceholder}
            {...resolveAdditionalCovenantsHelper(additionalCovenants, fieldClassesWarning)}
          />
        )}
      </FormGroup>
    </>
  );
}

const MemoizedTermsAndConditions = React.memo(SectionTermsAndConditions);

export default ({ name, queryParams, additionalAttachments }: Props) => {
  return (
    <Subform //
      subformBindingPath={name}
      component={MemoizedTermsAndConditions}
      name={name}
      queryParams={queryParams}
      additionalAttachments={additionalAttachments}
    />
  );
};
