import React from 'react';

import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useModalDialogStyles = makeStyles(
  () => ({
    paper: {
      borderRadius: 32,
      background: colors.WHITE,
      boxShadow: '0px 5px 30px 2px rgba(0, 0, 0, 0.25)',
      padding: '0 48px 0 48px'
    }
  }),
  {
    name: 'SympliDialog'
  }
);

function SympliDialog(props: React.PropsWithChildren<MuiDialogProps>) {
  const modalDialogClasses = useModalDialogStyles();
  return (
    <Dialog //
      classes={modalDialogClasses}
      {...props}
    />
  );
}

export default React.memo(SympliDialog);
