import { DocumentAttachmentModel, MortgageTermsAndConditionsModel, StandardTermsDocumentReferenceModel } from './models';

const IS_STANDARD_TERMS_DOCUMENT_REFERENCES_FILLED = (standardTermsDocumentReferences: StandardTermsDocumentReferenceModel[]) =>
  standardTermsDocumentReferences.some(item => item.documentReference?.trim());

const IS_STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE_FILLED = (item: StandardTermsDocumentReferenceModel) => {
  return (item.documentReference ?? '').trim() !== '';
};

const IS_ADDITIONAL_TERMS_AND_CONDITIONS_FILLED = (additionalTermsAndConditions: string) => {
  return additionalTermsAndConditions.trim() !== '';
};

const IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED = (termsAndConditionsAttachment: DocumentAttachmentModel[]) => {
  return termsAndConditionsAttachment.length > 0;
};

export const REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE = (
  item: StandardTermsDocumentReferenceModel,
  termsAndConditions: MortgageTermsAndConditionsModel
) => {
  // if it's already populated, we don't need to check for required() rule
  if (IS_STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE_FILLED(item)) {
    return false;
  }
  // otherwise check if at least one of the other two fields is populated
  return !(
    IS_ADDITIONAL_TERMS_AND_CONDITIONS_FILLED(termsAndConditions.additionalTermsAndConditions) ||
    IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED(termsAndConditions.termsAndConditionsAttachment)
  );
};

export const REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS = (parent: MortgageTermsAndConditionsModel) => {
  // if it's already populated, we don't need to check for required() rule
  if (IS_ADDITIONAL_TERMS_AND_CONDITIONS_FILLED(parent.additionalTermsAndConditions)) {
    return false;
  }
  // otherwise check if at least one of the other two fields is populated
  return !(IS_STANDARD_TERMS_DOCUMENT_REFERENCES_FILLED(parent.standardTermsDocumentReferences) || IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED(parent.termsAndConditionsAttachment));
};

export const REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT = (parent: MortgageTermsAndConditionsModel) => {
  // if it's already populated, we don't need to check for required() rule
  if (IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED(parent.termsAndConditionsAttachment)) {
    return false;
  }

  // otherwise check if at least one of the other two fields is populated
  return !(IS_ADDITIONAL_TERMS_AND_CONDITIONS_FILLED(parent.additionalTermsAndConditions) || IS_STANDARD_TERMS_DOCUMENT_REFERENCES_FILLED(parent.standardTermsDocumentReferences));
};
