import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 13
    },
    backgroundWhite: {
      background: theme.palette.common.white
    },
    backgroundGrey: {
      background: theme.palette.grey[100]
    },
    '@keyframes placeHolderShimmer': {
      '0%': {
        backgroundPosition: '-468px 0'
      },
      '100%': {
        backgroundPosition: '468px 0'
      }
    },
    animatedBackground: {
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$placeHolderShimmer',
      animationTimingFunction: 'linear',
      background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
      backgroundSize: '800px 104px',
      position: 'relative'
    },
    backgroundMasker: {
      position: 'absolute',
      top: 0,
      bottom: 0
    },
    variantSmall: {
      height: 13,
      marginBottom: 2
    },
    variantMedium: {
      height: 16,
      marginBottom: 6
    },
    variantLarge: {
      height: 18,
      marginBottom: 12
    }
  });

export type ClassKeys = typeof styles;

export default styles;
