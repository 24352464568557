import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

export const sharedStyles = (theme: Theme) =>
  createStyles({
    submitButton: {
      minWidth: 0,
      marginLeft: 8,
      paddingLeft: 4,
      paddingRight: 0,
      backgroundColor: colors.SYMPLI_GREEN,
      '&:hover': {
        backgroundColor: colors.GREEK_WATERS_BRIGHT
      },
      '&.Mui-disabled': {
        opacity: 0.4,
        backgroundColor: colors.SYMPLI_GREEN,
        '& path': {
          fill: colors.GREEK_WATERS_DARK
        }
      }
    }
  });
