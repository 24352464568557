import * as React from 'react';

import { DialogTypeEnum } from 'src/components/message-dialog/models';
import useSafeDispatch from 'src/hooks/useSafeDispatch';

export interface AsyncDialogState<T> {
  status: 'open' | 'pending' | 'closed' | 'resolved' | 'rejected';
  data?: T;
  error?: ResolvedGeneralError;
}

export type AsyncDialogAction<T> =
  | { type: 'open' } // To open the dialog
  | { type: 'submit' } // To set the dialog continue/save button to loading
  | { type: 'close'; data?: T; error?: ResolvedGeneralError } // To close the dialog, while still passing data or error
  | { type: 'succeed'; data?: T } // To not close the dialog when receiving response
  | { type: 'fail'; error: ResolvedGeneralError }; // To not close the dialog when receiving error response

interface ResolvedGeneralError {
  title: string;
  message: string;
  type?: DialogTypeEnum;
}

const asyncDialogReducer = <T>(state: AsyncDialogState<T>, action: AsyncDialogAction<T>): AsyncDialogState<T> => {
  switch (action.type) {
    case 'open':
      return { status: 'open' };
    case 'submit':
      return { status: 'pending' };
    case 'close':
      return { status: 'closed', data: action.data, error: action.error };
    case 'succeed':
      return { status: 'resolved', data: action.data };
    case 'fail':
      return { status: 'rejected', error: action.error };
  }
};

export default function useAsyncDialog<T>(initialState?: AsyncDialogState<T>) {
  const [state, unsafeDispatch] = React.useReducer(asyncDialogReducer, {
    status: 'closed',
    ...initialState
  });

  const { status, data, error } = state;
  const dispatch = useSafeDispatch<AsyncDialogAction<T>>(unsafeDispatch);

  return {
    ...state,
    isOpen: status !== 'closed',
    isLoading: status === 'pending',
    isResolved: status === 'resolved',
    isRejected: status === 'rejected',
    error,
    data,
    asyncDialogDispatch: dispatch
  };
}
