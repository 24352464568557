import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';

import { BaseApiPriorityNoticeModel, BasePriorityNoticeModel } from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/models';

// this file was automatically generated from models.ts.mustache
export const PARTY_BOOK_KEY = 'partyBook';

export interface ApiPriorityNoticeModel extends BaseApiPriorityNoticeModel<PartLandAffectedWithDescriptionsModel> {}

export type PriorityNoticeModel = BasePriorityNoticeModel<PartLandAffectedWithDescriptionsModel>;
