import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '8px 16px 10px 16px',
      backgroundColor: theme.palette.error.light,
      marginBottom: 5,
      /* this will prevent inheriting opacity from parent */
      position: 'relative'
    },
    hint: {
      color: colors.MEDIUM_GREY
    },
    hintLink: {
      color: colors.MEDIUM_GREY,
      textDecoration: 'underline'
    },
    errorIcon: {
      fill: theme.palette.error.main,
      marginRight: 6
    },
    errors: {
      marginBottom: 0,
      marginTop: 0,
      textAlign: 'left',
      paddingLeft: 35,
      '& li': {
        fontSize: 13,
        lineHeight: '17px',
        paddingTop: 8
      }
    }
  }),
  {
    name: 'SignError'
  }
);
