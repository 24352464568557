import { endOfToday, isBefore } from 'date-fns';
import * as yup from 'yup';

import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { DATE_PICKER_MIN_DATE } from '@sympli/ui-framework/components/form/base-components/date-picker';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { DeceasedTenancyDetailValidationConfiguration } from '../../config';
import { DeceasedProprietorModel, EvidenceModel } from '../../models';
import { VISIBILITY_CHECK_DATE_OF_DEATH_DATE, VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE } from '../date-of-death/visibilityChecks';
import { EvidenceTypeEnum } from './enums';
import { VISIBILITY_CHECK_EVIDENCE_DATE, VISIBILITY_CHECK_EVIDENCE_DOCUMENT_REFERENCE, VISIBILITY_CHECK_EVIDENCE_JURISDICTION } from './visibilityChecks';

const END_OF_TODAY = endOfToday();

export const yupEvidence = (config?: DeceasedTenancyDetailValidationConfiguration) =>
  yup
    .object<EvidenceModel>({
      type: yup.mixed<EvidenceTypeEnum>().required(msg.REQUIRED).defined().oneOf(Object.values(EvidenceTypeEnum), msg.REQUIRED),
      date: validateWhenVisible2({
        visibilityCheck: VISIBILITY_CHECK_EVIDENCE_DATE,
        validationWhenVisible: yupDatePicker //
          .required(msg.REQUIRED)
          .min(config?.minEvidenceDate ?? DATE_PICKER_MIN_DATE, config?.minEvidenceDateError ?? msg.INVALID_VALUE)
          .max(END_OF_TODAY, msg.DATE_MUST_BE_TODAY_OR_PAST_DATE)
          .test('Validate evidence date', 'Evidence date can not be earlier than date of death', function test(this: yup.TestContext, evidenceDate: Date) {
            const parentProprietor: DeceasedProprietorModel = this.from[1].value!;
            if (!parentProprietor.isSelected) return true;

            if (!evidenceDate) return true;
            const lastDeceasedDateDeath = parentProprietor.dateOfDeath;
            if (VISIBILITY_CHECK_DATE_OF_DEATH_DATE(lastDeceasedDateDeath!)) {
              if (isNotNullOrEmpty(lastDeceasedDateDeath!.date!)) {
                const deathDate = new Date(lastDeceasedDateDeath!.date!);
                deathDate.setHours(0, 0, 0, 0);
                return !isBefore(evidenceDate, deathDate);
              }
            }
            if (VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(lastDeceasedDateDeath!)) {
              if (isNotNullOrEmpty(lastDeceasedDateDeath!.to)) {
                const toDate = new Date(lastDeceasedDateDeath!.to!);
                toDate.setHours(0, 0, 0, 0);
                return !isBefore(evidenceDate, toDate);
              }
            }
            return true;
          })
      }),
      documentReference: validateWhenVisible2({
        visibilityCheck: VISIBILITY_CHECK_EVIDENCE_DOCUMENT_REFERENCE,
        validationWhenVisible: yup.string().default('').trim().required(msg.REQUIRED)
      }),
      jurisdiction: validateWhenVisible2({
        visibilityCheck: VISIBILITY_CHECK_EVIDENCE_JURISDICTION,
        validationWhenVisible: yup.string().default('').nullable(true).trim().required(msg.REQUIRED)
      }),
      defaultAdded: yup.mixed()
    })
    .defined();
