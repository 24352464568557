import React, { useRef } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { VicAddressSelectField_2_18 as AddressSelectField } from '@sympli-mfe/document-components/address-field/vic/2-18';
import { VIC_ADDRESS_FORM_CONFIG as VIC_TOC_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/vic/2-18/config';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';

import { VIC_TOC_PARTY_FORM_CONFIG } from '../../config';
import { RecipientPartyModel, TransferOfControlModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  disabled: boolean;
  focusRef?: React.RefObject<ButtonBaseActions>;
  isSympliSubRecipient: boolean;
}

function Recipient({ name, className, disabled, focusRef, isSympliSubRecipient }: Props): JSX.Element {
  const { dialogPortalId } = useDocumentContext();
  const postPartyDialogFocusRef = useRef<ButtonBaseActions>(null);
  const fieldName = createModelKeyAppender<RecipientPartyModel>(name);
  const formikProps = useFormikContext<TransferOfControlModel>();
  const { values } = formikProps;
  const addressBookOptions = useAddressBookOptions(values.addressBook, VIC_TOC_ADDRESS_FORM_CONFIG);

  return (
    <>
      <PartySelectField
        name={fieldName('partyBookId')}
        partyFormConfig={VIC_TOC_PARTY_FORM_CONFIG}
        disabled={disabled || isSympliSubRecipient}
        bookRef="partyBook"
        dialogPortalId={dialogPortalId}
        focusRef={focusRef}
        nextFocusRef={postPartyDialogFocusRef}
      />
      <AddressSelectField
        name={fieldName('addressBookId')}
        disabled={disabled || isSympliSubRecipient}
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        label="Address"
        optionsOverride={addressBookOptions}
      />
    </>
  );
}

export default React.memo(Recipient);
