import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchSubscriberSigningRule } from '../actions/subscriberSigningRule';
import { SubscriberSigningRule } from '../models';

const fetchSubscriberSigningRule = () => http.get<SubscriberSigningRule>('/settings/subscriber/signing-rule');

function* sagaFetchSubscriberSigningRule() {
  try {
    const data = yield* call(fetchSubscriberSigningRule);
    yield put(actionFetchSubscriberSigningRule.success({ data }));
  } catch (error) {
    yield put(actionFetchSubscriberSigningRule.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchSubscriberSigningRule.request, sagaFetchSubscriberSigningRule)
];
