import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    optionIcon: {
      '& Rect': {
        fill: theme.palette.primary.main
      },
      marginRight: 4,
      width: 38,
      height: 36
    },
    toggleButton: {}
  }),
  {
    name: 'ArchiveDialog'
  }
);
