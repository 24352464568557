import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { ApplicantAddressModel } from '../../models';

export const yupApplicantAddress = yup
  .object<ApplicantAddressModel>({
    addressBookId: yup.string().default('').nullable(true).trim().required(msg.REQUIRED)
  })
  .defined();
