import React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';

import { MessageNotificationContainer, MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';
import { IconDocError } from '@sympli/ui-framework/icons';

import { DocumentPageRouteParams } from 'src/containers/documents/models';
import ExpandableContent from '../expandable-content';
import WorkflowPanelIssuesButton from './WorkflowPanelIssuesButton';

export type DocumentIssuesMode = 'error' | 'warning' | 'all' | 'default';

type Props = {
  buttonTheme?: 'light' | 'dark';
  queryParams: DocumentPageRouteParams;
  isOpenLodgementVerificationDialog: boolean;
  errors?: MessageModel[];
  warnings?: MessageModel[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  issueId?: string;
};

function WorkflowPanelIssues({
  //
  buttonTheme,
  queryParams: { participantId, workspaceId },
  isOpenLodgementVerificationDialog,
  errors,
  warnings,
  issueId,
  onClick
}: Props) {
  const storageNamespace = `${workspaceId}/${participantId}`;

  // if it is undefined, the open state is managed by MessageNotificationWithLocalStorage
  const [isOpenErrors, setIsOpenErrors] = React.useState<boolean | undefined>(undefined);
  const [isOpenWarnings, setIsOpenWarnings] = React.useState<boolean | undefined>(undefined);

  const openError = isOpenLodgementVerificationDialog ? false : isOpenErrors;
  const openWarning = isOpenLodgementVerificationDialog ? false : isOpenWarnings;

  const mode = React.useMemo<DocumentIssuesMode>(() => {
    // * If there are no errors/warnings
    if (!errors?.length && !warnings?.length) {
      return 'default';
    }

    // * If there are only warnings
    if (!errors?.length && warnings?.length) {
      return isOpenWarnings ? 'default' : 'warning';
    }

    // * If there are only errors
    if (errors?.length && !warnings?.length) {
      return isOpenErrors ? 'default' : 'error';
    }

    // * If there are both errors and warnings
    // 1. error is closed but warning is open
    if (!isOpenErrors && isOpenWarnings) {
      return 'error';
    }

    // 2. warning is closed but error is open
    if (!isOpenWarnings && isOpenErrors) {
      return 'warning';
    }
    return 'all';
  }, [errors?.length, isOpenErrors, isOpenWarnings, warnings?.length]);

  const handleClick = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, model: DocumentIssuesMode) => {
    switch (model) {
      case 'error':
        setIsOpenErrors(true);
        break;
      case 'warning':
        setIsOpenWarnings(true);
        break;
      case 'all':
        setIsOpenErrors(true);
        setIsOpenWarnings(true);
        break;
      case 'default':
      default:
        break;
    }
  };

  return (
    <>
      <WorkflowPanelIssuesButton //
        buttonTheme={buttonTheme}
        mode={mode}
        onClick={handleClick}
      />
      {errors?.length || warnings?.length ? (
        <MessageNotificationContainer className={classNames('top-[95px]')}>
          {errors?.length ? (
            <MessageNotificationWithLocalStorage //
              storageNamespace={`${storageNamespace}/workflowPanelErrors`}
              messageId={issueId}
              open={openError}
              TransitionProps={{
                onEntering: () => setIsOpenErrors(true)
              }}
              onClose={() => setIsOpenErrors(false)}
              variant="error"
              icon={<IconDocError />}
              primary={`${pluralize('error', errors.length, true)} found on this document`}
              expandableContent={
                <ExpandableContent //
                  variant="error"
                  messageHeader="Land registry verification response"
                  remarkHeader="Action required"
                  messages={errors}
                  onClick={onClick}
                />
              }
            />
          ) : null}
          {warnings?.length ? (
            <MessageNotificationWithLocalStorage //
              storageNamespace={`${storageNamespace}/workflowPanelWarnings`}
              messageId={issueId}
              open={openWarning}
              TransitionProps={{
                onEntering: () => setIsOpenWarnings(true)
              }}
              onClose={() => setIsOpenWarnings(false)}
              variant="warning"
              primary={`${pluralize('warning', warnings.length, true)} found on this document`}
              expandableContent={
                <ExpandableContent //
                  variant="warning"
                  messageHeader="Land registry verification response"
                  remarkHeader="Action required"
                  messages={warnings}
                  onClick={onClick}
                />
              }
            />
          ) : null}
        </MessageNotificationContainer>
      ) : null}
    </>
  );
}

export default React.memo(WorkflowPanelIssues);
