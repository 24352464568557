import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useWorkspaceParticipantSettings, WorkspaceParticipantSettingState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipantSetting';
import { useSafeDispatch } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import BillingMethodDialog from './BillingMethodDialog';

interface Props {
  queryParams: WorkspaceDetailRouteParams;
  open: boolean;
  onClose: () => void;
}

function BillingMethodDialogContainer(props: Props) {
  const { workspaceId, participantId } = props.queryParams;
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceParticipantSetting: WorkspaceParticipantSettingState = useWorkspaceParticipantSettings(workspaceId, participantId);

  return (
    <BillingMethodDialog //
      {...props}
      dispatch={dispatch}
      workspaceParticipantSetting={workspaceParticipantSetting}
    />
  );
}

export default React.memo(BillingMethodDialogContainer);
