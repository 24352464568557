import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, ApiPartySourceModel, NameChangeConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { updateProprietorWithCapacity } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/helpers';
import {
  DeceasedProprietorModel,
  DeceasedTenancyDetailModel,
  initDeceasedProprietor
} from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/models';
import { convertPartiesToFormModel } from '../../../qld/2-24/helpers';
import { BaseTransmissionApplicationModel } from './models';

export function generateDeceasedTenancy<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(
  formModel: BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>,
  partyFormConfig: PartyFormConfig<TNameChange>,
  nameChangeConversion: NameChangeConversion<TDocumentPartyJustification, TNameChange>
): BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange> {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Subscriber || (pb.metadata?.source === DataSource.Custom && !pb.metadata?.partyBookId));
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.proprietor.proprietorGroups.map(
            pg =>
              new ProprietorGroup(
                pg.parties.map(({ party, partyCapacity }) => ({ ...party, partyCapacity })),
                pg,
                pg.shareFraction,
                pg.proprietorGroupType
              )
          ),
          title.proprietor.tenancyType
        )
      )
  );

  const mergeResult = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiPartySourceModel<TDocumentPartyJustification>>[] = mergeResult.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties.map(p => ({ party: p, partyCapacity: p.partyCapacity })),
      isSelected: false,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const tenancyType = mergeResult.containers[0]?.tenancyDetail.tenancyType! ?? TenancyTypeEnum.None;
  const deceasedTenancyDetail: DeceasedTenancyDetailModel = {
    tenancyType,
    proprietorGroups: groups.map(pg => ({
      ...pg,
      isSelected: groups.length === 1 && (pg.parties.length === 1 || tenancyType === TenancyTypeEnum.JointTenants),
      parties: pg.parties.map(({ party, partyCapacity }) => {
        const defaultSelected = groups.length === 1 && pg.parties.length === 1;
        const proprietor: DeceasedProprietorModel = initDeceasedProprietor(defaultSelected, party.id!, partyCapacity);
        return defaultSelected ? updateProprietorWithCapacity(proprietor, proprietor.isSelected) : proprietor;
      })
    }))
  };

  const unselectedProprietorParties = formModel.titleReferences
    .filter(tr => !tr.isSelected)
    .flatMap(tr => tr.proprietor.proprietorGroups.flatMap(pg => pg.parties))
    .map(({ party, ...rest }: ApiPartySourceModel<TDocumentPartyJustification>, index: number) => ({
      ...rest,
      party: {
        ...party,
        id: (party.id = party.externalId ? party.externalId : `TITLE-${++index}`)
      }
    }));

  const titleProprietors = groups
    .flatMap(pg => pg.parties)
    .concat(unselectedProprietorParties)
    .map(p => p.party);

  const partyBook = convertPartiesToFormModel_2_24(
    titleProprietors,
    partyFormConfig,
    nameChangeConversion,
    titleProprietors.map(p => p.id!)
  ).concat(customParties);

  return {
    ...formModel,
    mergeFailedReason: mergeResult.failedReason,
    deceasedTenancyDetail: deceasedTenancyDetail,
    partyBookApi: titleProprietors,
    partyBook,
    applicants: {
      ...formModel.applicants,
      proprietorGroups: formModel.applicants.proprietorGroups.map(pg => ({
        ...pg,
        parties: pg.parties.map(p => ({ ...p, partyBookId: partyBook.some(pb => pb.id === p.partyBookId) ? p.partyBookId : '' }))
      }))
    }
  };
}

export function convertPartiesToFormModel_2_24<TDocumentPartyJustification, TNameChange>(
  parties: ApiDocumentPartyModel<TDocumentPartyJustification>[],
  partyFormConfig: PartyFormConfig<TNameChange>,
  nameChangeConversion: NameChangeConversion<TDocumentPartyJustification, TNameChange>,
  relinquishingIds?: string[]
): PartyModel<TNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: partyFormConfig,
      nameChangeConversion: nameChangeConversion
    },
    parties,
    relinquishingIds
  );
}
