import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useCardDetailStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& .MuiTableCell-root': {
        height: 'auto',
        borderBottom: 'none',
        paddingRight: 6
      },
      '& tr:last-child .MuiTableCell-head': {
        borderBottom: '1px solid var(--greek-waters)'
      },
      '& .MuiTableCell-head': {
        color: 'var(--sympli-green)',
        fontFamily: 'volkswagen_serial-medium',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'unset',

        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        paddingBottom: 8,

        borderTop: 'none',
        '&:not(:first-child)': {
          fontSize: 10
        }
      },

      '& .MuiTableCell-body': {
        paddingTop: 16
      },

      '& + &': {
        marginTop: 32
      }
    }
  }),
  {
    name: 'CardDetail'
  }
);

export const useCardStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: 10,
      background: '#FFF',
      boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.07)',
      padding: '24px 40px',
      '@media (min-width:1920px)': {
        padding: 24
      },
      '@media (min-width:1536px) and (max-width:1919px)': {
        padding: '24px 20px'
      }
    }
  }),
  {
    name: 'Card'
  }
);
