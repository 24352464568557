import * as React from 'react';

import { FormikProps } from 'formik';
import Typography from '@mui/material/Typography';

import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { CaveatModel_2_21_1 } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated
interface Props {
  name: string;
  formikProps: FormikProps<CaveatModel_2_21_1>;
  disabled: boolean;
}

function CaveatStatementLabel({ name, disabled }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ul className={classes.caveatStatementList}>
        {[
          'The Caveator, to the best knowledge of the Subscriber identified in the execution of the Caveat document, has a good and valid claim to the estate or interest claimed as specified in this Caveat.',
          'This Caveat, to the best knowledge of the Subscriber identified in the execution of the Caveat document, does not require the leave of the Supreme Court.',
          'This Caveat, to the best knowledge of the Subscriber identified in the execution of the Caveat document, does not require the written consent of the Registered Proprietor Of Estate or possessory applicant (as applicable) for the purposes of section 740 Real Property Act 1900.',
          'The Caveator, to the best knowledge of the Subscriber identified in the execution of the Caveat document, has provided the correct address of the Registered Proprietor as specified in this Caveat.'
        ].map((text, index) => (
          <Typography component="li" className={classes.caveatStatementListItem} key={index}>
            {text}
          </Typography>
        ))}
      </ul>
      <Field //
        name={name}
        disabled={disabled}
        label="I have read and agree to the above statements"
        component={CheckboxField}
      />
    </div>
  );
}

export default CaveatStatementLabel;
