import * as yup from 'yup';

import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS, SetOfOperativeWordsEnum } from '../../enums';

// path: setOfOperativeWords
const yupSetOfOperativeWords = yup
  .mixed<SetOfOperativeWordsEnum>()
  .required(msg.REQUIRED)
  .oneOf(getLookupValuesAllowingEmpty(SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS), msg.INVALID_SELECTION);

export default yupSetOfOperativeWords;
