import * as yup from 'yup';

import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-19/sa';
import msg from '@sympli/ui-framework/utils/messages';

import { checkIfJustifiedPartiesAreTheSame, checkIfMergedPartiesHaveMismatchingPartyType, isMortgagorNamesMatchesOrJustified } from '../../helper';
import { Mortgage2_19_1Model, MortgagorPartyModel } from '../../models';

interface MortgagorsContext {
  isFinancialWorkspace: boolean;
  mortgagorsLength: number;
  partyBook: Mortgage2_19_1Model['partyBook'];
  titleReferences: Mortgage2_19_1Model['titleReferences'];
}

const contextResolver = ({ isFinancialWorkspace, mortgagors, partyBook, titleReferences }: Mortgage2_19_1Model): MortgagorsContext => {
  return {
    isFinancialWorkspace,
    mortgagorsLength: mortgagors.length,
    partyBook,
    titleReferences
  };
};

const yupMortgagorsItem = yup
  .object<MortgagorPartyModel, MortgagorsContext>({
    partyBookId: yup.mixed<MortgagorPartyModel['partyBookId']>(),
    partyCapacity: yup.mixed<MortgagorPartyModel['partyCapacity']>(),
    addressBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED)
  })
  .defined();

const yupMortgagors = memoizeSchemaWithContext(
  yup
    .array()
    .of<MortgagorPartyModel>(yupMortgagorsItem)
    .defined()
    .testContextualRule({
      uniqueTestName: 'Wait for transfer test',
      message: 'Awaiting Transfer document completion',
      requirement: (parent: Mortgage2_19_1Model, ctx: MortgagorsContext) => {
        if (!ctx.isFinancialWorkspace) {
          return true;
        }
        return ctx.mortgagorsLength !== 0;
      }
    })
    .testContextualRule({
      uniqueTestName: 'Mortgagors name mismatch test',
      message: 'Please be aware that the parties on the selected titles presently do not match.',
      requirement: (parent: Mortgage2_19_1Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return isMortgagorNamesMatchesOrJustified(partyBook, titleReferences);
      }
    })
    .testContextualRule({
      uniqueTestName: 'Check if party is justified twice',
      message: 'Unable to change name, the proposed name has already been used in a justification.',
      requirement: (parent: Mortgage2_19_1Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return !checkIfJustifiedPartiesAreTheSame(partyBook, titleReferences);
      }
    })
    .test('Check if parties justified does not have same party type', 'Unable to change name', function test(this: yup.TestContext<MortgagorsContext>) {
      const { partyBook, titleReferences } = this.options.context!;
      const partyType = checkIfMergedPartiesHaveMismatchingPartyType(partyBook, titleReferences);
      return partyType
        ? this.createError({
            message: `Unable to change name, the proposed name has already been used for an ${partyType === PartyTypeEnum.Individual ? 'individual' : 'organisation'}.`
          })
        : true;
    })
    .log(),
  contextResolver
);

export default yupMortgagors;
