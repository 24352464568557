import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root() {
      return {
        padding: '0 32px',
        backgroundColor: theme.palette.grey[50],
        position: 'sticky',
        top: 0,
        zIndex: 2
      };
    },
    rightContainer: {
      flexGrow: 1
    },
    wizardStepper: {
      width: 500
    },
    showWarningIcon: { fill: 'black', paddingRight: 5 }
  }),
  {
    name: 'TitleTopPanel'
  }
);
