import React from 'react';

import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import DocumentsPageContainer from 'src/containers/documents';
import { DocumentPageRouteParams } from 'src/containers/documents/models';
import { useDocumentDetail } from 'src/containers/documents/reducers/document';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useRouterParams } from 'src/hooks';

export default function WorkspaceDocumentsPageContainer() {
  const { workspaceId, participantId, documentId } = useRouterParams<DocumentPageRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const documentDetailState = useDocumentDetail(workspaceId, participantId, documentId);

  if (workspaceBasicInfoState.error || workspaceDetailState.error || documentDetailState.error) {
    return <GeneralCrashedContent />;
  }

  return <DocumentsPageContainer />;
}
