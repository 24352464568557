// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { WithdrawalOfCaveat2_20_1Model } from './models';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupWithdrawCaveats from './sections/withdraw-caveat/validationSchema';

export default yup.object<WithdrawalOfCaveat2_20_1Model>().shape({
  titleReferences: yupTitleReferences,
  withdrawCaveats: yupWithdrawCaveats,
  partyBook: yupPartyBook
});
