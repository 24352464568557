import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { LodgementCaseStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceBasicInfoApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import NavLink from '@sympli/ui-framework/components/nav-link';
import { IconLocked } from '@sympli/ui-framework/icons';
import Logger from '@sympli/ui-logger';

import ProfileContext, { ProfileContextModel } from 'src/containers/shared/auth/profile-security/Profile.context';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { getTransactionTypeString } from '../../../left-panel/helpers';
import WorkspaceStatus from '../workspace-status';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  // basic workspace info
  workspaceId?: string;
  // current participant info
  participantId?: string;
  lodgementCaseStatusId?: LodgementCaseStatusEnum;
  matter?: string;
  // workspace documents
  // other
  isTopLevelOverView?: boolean;
  linkTo?: string;
  linkIconPosition?: 'left' | 'right';
  showMatterLinkOverride?: boolean;
  isLocked?: boolean;
  workspaceBasicInfo?: WorkspaceBasicInfoApiResponse;
  showWorkspaceStatus?: boolean;
  showIcon?: boolean; // This will always show icon no matter what feature flag's value is
  isTransactionTypeEnabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class BoxHeaderLink extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    linkIconPosition: 'left',
    showMatterLinkOverride: false,
    isTopLevelOverView: false
  };

  render() {
    return <ProfileContext.Consumer>{profile => this.renderContent(profile)}</ProfileContext.Consumer>;
  }

  private renderContent(profile: ProfileContextModel) {
    return (
      <FlexLayout flexDirection="row" alignItems="center">
        {this.renderMatterNumberLink()}
      </FlexLayout>
    );
  }

  private renderWorkspaceStatus() {
    const { lodgementCaseStatusId } = this.props;
    const { workspaceTypeId, workspaceStatusId, isLocked } = this.props.workspaceBasicInfo!;
    if (lodgementCaseStatusId === undefined) return null;
    return (
      <WorkspaceStatus //
        // basic workspace info
        workspaceTypeId={workspaceTypeId}
        workspaceStatusId={workspaceStatusId}
        lodgementCaseStatusId={lodgementCaseStatusId}
        isLocked={isLocked}
      />
    );
  }

  private renderMatterNumberLink() {
    const {
      classes,
      showMatterLinkOverride,
      linkIconPosition,
      isTopLevelOverView,
      linkTo,
      matter,
      workspaceId,
      participantId,
      isLocked,
      showWorkspaceStatus,
      showIcon,
      isTransactionTypeEnabled
    } = this.props;
    let headerText;
    if (showMatterLinkOverride) {
      headerText = matter?.length ? (
        <>
          {isLocked && <IconLocked className={classes.iconLocked} />}
          <Typography component="span" className={classes.titleLink}>
            {matter}
          </Typography>
        </>
      ) : (
        ''
      );
    } else {
      headerText = isTopLevelOverView ? 'Workspace overview' : 'See workspace overview';
    }

    if (!(workspaceId && participantId)) {
      return <Typography variant="subtitle1">{headerText}</Typography>;
    }

    if (isTopLevelOverView) {
      return (
        <Box display="flex" flexDirection="column">
          {showWorkspaceStatus && isTransactionTypeEnabled && this.renderTransactionType()}
          <Typography variant="subtitle1">{headerText}</Typography>
          {showWorkspaceStatus && this.renderWorkspaceStatus()}
        </Box>
      );
    }

    const headerLink = linkTo || resolveWorkspaceDetailLink({ workspaceId, participantId });

    // When feature flag is turned on, we need to check showIcon prop
    if (showIcon) {
      return (
        <Box display="flex" flexDirection="column">
          {showWorkspaceStatus && isTransactionTypeEnabled && this.renderTransactionType()}
          <NavLink
            data-testid="workspace-overview-link"
            iconColor="primary"
            iconPosition={linkIconPosition}
            to={headerLink}
            className={classes.titleLink}
            onClick={e => {
              // to avoid event propagate to the parent eg: WorkspaceExpansionBox
              // fix https://tickleme.atlassian.net/browse/WEB-20496
              e.stopPropagation();
            }}
            classes={{ iconLeft: classes.titleLeftIcon, iconRight: classes.titleRightIcon }}
          >
            {headerText}
          </NavLink>
          {showWorkspaceStatus && this.renderWorkspaceStatus()}
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="column">
        <NavLink
          data-testid="workspace-overview-link"
          icon={false}
          to={headerLink}
          className={classes.titleLink}
          onClick={e => {
            // to avoid event propagate to the parent eg: WorkspaceExpansionBox
            // fix https://tickleme.atlassian.net/browse/WEB-20496
            e.stopPropagation();
          }}
          classes={{ iconLeft: classes.titleLeftIcon, iconRight: classes.titleRightIcon }}
        >
          {headerText}
        </NavLink>
        {showWorkspaceStatus && this.renderWorkspaceStatus()}
      </Box>
    );
  }

  private renderTransactionType() {
    const { workspaceId } = this.props;
    const { workspaceTypeId, transactionType } = this.props.workspaceBasicInfo!;
    const { classes } = this.props;
    if (workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly) {
      if (transactionType) {
        return <Typography className={classes.transactionTypeTitle}>Financial • {getTransactionTypeString(transactionType.type)}</Typography>;
      }
      // report error to NewRelics
      const scope = Logger.getDefaultScope();
      scope.setExtraAttributes({ workspaceId });
      Logger.captureException(new Error('Financial workspace does not have transaction type'), scope);
    }
    return <Typography className={classes.transactionTypeTitle}>Standalone</Typography>;
  }
}

const styledComponent = withStyles(styles)(BoxHeaderLink);
export default styledComponent;
