import * as yup from 'yup';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { TransferorAddressTypeEnum, TransferorsAddressOption } from '../../models';

const fieldName = modelKey<TransferorsAddressOption>();

const yupTransferorsAddressOptionItem = yup.object<TransferorsAddressOption>({
  isSelected: yup.mixed(),
  key: yup.mixed(),
  id: yup.mixed(),
  addressBookId: yup
    .string() //
    .when(
      [fieldName('isSelected'), fieldName('key')], //
      (isSelected: boolean, key: TransferorAddressTypeEnum) => {
        return isSelected && key === TransferorAddressTypeEnum.HasAKnownAddress
          ? yup
              .string() //
              .default('')
              .nullable(true)
              .trim()
              .required(msg.REQUIRED)
          : yup.string().nullable();
      }
    )
    .defined()
});

export const yupTransferorsAddress = yup
  .array<TransferorsAddressOption>(yupTransferorsAddressOptionItem.defined())
  .test(
    'option selection', //
    msg.REQUIRED,
    function test(this: yup.TestContext<TransferorsAddressOption[]>, values: TransferorsAddressOption[]) {
      return (values || []).some(({ isSelected }) => isSelected);
    }
  )
  .defined();
