import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root() {
      return {
        position: 'relative'
      };
    },
    errorMessage: {
      margin: '16px auto'
    }
  }),
  {
    name: 'DocumentsPageContainer'
  }
);
