import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { NominateLodgementCase_2_18_1_Model } from './models';

export const fallbackMap: FallbackMap<NominateLodgementCase_2_18_1_Model> = {
  titleReferences: NO_FALLBACK,
  ctController: NO_FALLBACK,
  issuingPartyDirection: [null],
  bridgeWorkspace: NO_FALLBACK
};
