import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    content: {
      paddingLeft: 18,
      flexGrow: 1
    },
    dividerError: {
      background: theme.palette.error.main
    },
    dividerWarning: {
      background: theme.palette.secondary.main
    },

    assistanceFooter: {
      padding: '10px 0',
      fontSize: 13,
      fontStyle: 'italic',
      textAlign: 'center',
      '& button': {
        fontStyle: 'initial',
        fontSize: 14
      }
    }
  }),
  {
    name: 'LodgementErrorMessageNotification'
  }
);
