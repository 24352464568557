import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { RelinquishingProprietorGroupModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - transferorDetails.relinquishingProprietorGroups[*].shareFraction

 schema version:
[
  {
    "shareTransferred": 1
  }
]
*/
export const VISIBILITY_CHECK_TRANSFEROR_DETAILS$RELINQUISHING_PROPRIETOR_GROUPS$SHARE_FRACTION = createContextCheck(
  (context: RelinquishingProprietorGroupModel): boolean => context.shareTransferred === 1
);
