import * as yup from 'yup';

import { DischargeOfMortgage2_24_0Model } from './models';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgages from './sections/mortgages/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-reference/validationSchema';

export default yup
  .object<DischargeOfMortgage2_24_0Model>()
  .shape({
    mortgages: yupMortgages,
    titleReferences: yupTitleReferences,
    partyBook: yupPartyBook,
    mortgagees: yupMortgagees
  })
  .defined()
  .log();
