import { SA_ADDRESS_FORM_CONFIG as SA_DM_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-19/config';
import { saNameChangeConversion } from '@sympli-mfe/document-components/party-form/sa/2-19/components/party-justification';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { SA_DM_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterMortgagees, filterTitleReferences } from './helpers';
import { ApiDischargeOfMortgage2_19_1Model, DischargeOfMortgage2_19_1Model } from './models';

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: SA_DM_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: SA_DM_PARTY_FORM_CONFIG,
  nameChangeConversion: saNameChangeConversion
});

// this file was automatically generated from conversion.ts.mustache
export function convertFromApiToFormModel(apiModel: ApiDischargeOfMortgage2_19_1Model): DischargeOfMortgage2_19_1Model {
  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.mortgages.map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId)).flat();
  let formModel: DischargeOfMortgage2_19_1Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
    titleReferences: filterTitleReferences(apiModel),
    mortgagees: filterMortgagees(apiModel)
  };
  formModel = applyDefaultMap(formModel, fallbackMap);
  return formModel;
}

export function convertFromFormToApiModel(formModel: DischargeOfMortgage2_19_1Model, originalApiModel: ApiDischargeOfMortgage2_19_1Model): ApiDischargeOfMortgage2_19_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    titleReferences,
    mortgagees,
    ...rest
  } = formModel;

  const selectedTitles = titleReferences.filter(x => x.isSelected).map(x => x.reference);
  // const selectedMortgagees = mortgagees.map(x => x.partyBookId);
  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
    mortgages: formModel.mortgages.map(x => {
      return {
        ...x,
        titleReferences: x.titleReferences.map(t => {
          return {
            ...t,
            isSelected: x.isSelected ? selectedTitles.includes(t.reference) : t.isSelected
          };
        }),
        mortgagees: x.mortgagees.map(m => {
          const selectedMortgagee = mortgagees.find(x => x.partyBookId === m.partyBookId) || m;
          return selectedMortgagee;
        })
      };
    })
  };
}
