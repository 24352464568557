import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      width: 24,
      height: 24,
      marginTop: -2,
      marginBottom: -2,
      '& svg': {
        fill: theme.palette.primary.main
      }
    },
    buttonIcon: {
      width: 14,
      height: 16,
      fill: colors.GREY_40
    },
    tooltip: {
      color: colors.BLACK,
      width: 'auto',
      fontSize: 13,
      marginTop: 20
    }
  }),
  {
    name: 'WithdrawResendFormatter'
  }
);
