import React from 'react';

import classNames from 'classnames';

export type StatusChipVariant = 'light-green' | 'green' | 'red' | 'yellow' | 'grey';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  //
  variant: StatusChipVariant;
}

export function StatusChip({ children, variant, className, ...rest }: React.PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(
        //
        'flex items-center justify-center rounded-[40px] px-3 py-1',
        'w-fit font-roboto text-sm font-bold not-italic leading-4',
        {
          'bg-[var(--greek-waters-translucent)] text-[var(--greek-waters-dark)]': variant === 'light-green',
          'bg-[var(--greek-waters-lite)] text-[var(--greek-waters-dark)]': variant === 'green',
          'bg-[var(--watermelon-lite)] text-[var(--watermelon)]': variant === 'red',
          'bg-[var(--secondary-sunny-day)] text-[var(--neutral-000)]': variant === 'yellow',
          'bg-[var(--neutral-025)] text-[var(--neutral-600)]': variant === 'grey'
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
