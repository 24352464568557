import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    title: {
      fontWeight: 'bold',
      fontSize: 32,
      lineHeight: '32px',
      marginTop: 40,
      marginBottom: 40
    },
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 20,
      gap: 16
    }
  },
  {
    name: 'ReportDashboard'
  }
);
