import * as React from 'react';

import { TooltipType } from '@sympli/ui-framework/components/form/base-components/tooltip/Tooltip';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { UserAvatar101 as UserAvatar } from 'src/components/avatars/user-avatar';
import { useStyles } from './styles';

export interface UserAvatarModel {
  name: string;
  workspaceRoles: Array<LookupEnumModel>;
  reference?: string;
  avatarUrl?: string;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: Array<UserAvatarModel>;
  size?: 'default' | 'small';
  showToolTip?: boolean;
}

function MultiUserAvatar(props: Props & TooltipType) {
  const classes = useStyles();
  const { className, style, items, size = 'small', placement = 'bottom', tooltipType, showToolTip = true } = props;

  return (
    <FlexLayout className={className} style={style}>
      {items.map((item, index) => {
        const subAvatarStyle = index !== 0 ? { root: classes.subAvatar } : undefined;
        const avatarStyle: React.CSSProperties = { zIndex: items.length - index };
        return (
          <UserAvatar //
            src={item.avatarUrl}
            classes={subAvatarStyle}
            style={avatarStyle}
            key={index}
            item={item}
            size={size}
            placement={placement}
            tooltipType={tooltipType}
            showToolTip={showToolTip}
          />
        );
      })}
    </FlexLayout>
  );
}

export default React.memo(MultiUserAvatar);
