import Logger, { InvalidDataError } from '@sympli/ui-logger';

export enum WarningMessageEnum {
  SomethingWentWrong
}

export function getWarningMessage(message: WarningMessageEnum) {
  switch (message) {
    case WarningMessageEnum.SomethingWentWrong:
      return (
        <>
          <b>Something went wrong.</b> We have encountered Technical Issues, please try again
        </>
      );

    default:
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Invalid message format', WarningMessageEnum[message]), scope);
      return 'Warning';
  }
}
