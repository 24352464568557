import { VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/vic/2-23/config';
import { VicNameChange, VicPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/vic/2-23/components/party-justification';
import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-23/config';
import { AddressFieldFormConfig } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';

import { VIC_TENANCY_COMPONENT_LABEL_CONFIG } from 'src/containers/documents/scaffolded-form/vic/2-23/components/tenancy/config';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const VIC_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...VIC_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.Mortgagee
};

export const VIC_M_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG
};

export const VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG: PartyFormConfig<VicNameChange> = {
  ...VIC_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...VicPartyNameChangeConfig, hideWhenEditable: true }
};

export const VIC_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...VIC_TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: 'Mortgagor',
  footer: { jointTenants: 'Add Mortgagor', tenantsInCommon: '' }
};
