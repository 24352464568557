import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';

import { IssuingInstructionEnum, IssuingMethodEnum } from './enums';
import { DuplicateHoldingDetailModel, DuplicateIssuingInstructionModel, LodgementInstructions2_19_1Model } from './models';

// this file was automatically generated from checks.ts.mustache

/**
 * This visibilityCheck needs to be created using root because IssuingParty is a nested object
 * and cannot access DuplicateIssuingInstructionModel.issuingInstruction without it's parent
 * see WA Lodgement Instructions fallback.ts
 */
export const VISIBILITY_CHECK_ISSUING_PARTY = createContextCheck(
  (
    issuingParty, //: IssuingPartyModel
    issuingInstructions, //DuplicateIssuingInstructionModel
    duplicateHoldingDetail, // DuplicateHoldingDetailModel
    duplicateHoldingDetails, // DuplicateHoldingDetailModel[]
    root //: LodgementInstructions_2_19_1_Model
  ): boolean => isIssuingInstructionDuplicateToIssue(issuingInstructions)
);

export const VISIBILITY_CHECK_ISSUING_METHOD = createContextCheck(
  (
    issuingInstructions, //DuplicateIssuingInstructionModel
    duplicateHoldingDetail, // DuplicateHoldingDetailModel
    duplicateHoldingDetails, // DuplicateHoldingDetailModel[]
    root //: LodgementInstructions_2_19_1_Model
  ): boolean => isIssuingInstructionDuplicateToIssue(issuingInstructions)
);

export const VISIBILITY_CHECK_ISSUING_OFFICE = createContextCheck((issuingInstructions?: DuplicateIssuingInstructionModel): boolean =>
  Boolean(isIssuingInstructionDuplicateToIssue(issuingInstructions) && issuingInstructions!.issuingMethod === IssuingMethodEnum.PickUp)
);

export const VISIBILITY_CHECK_ISSUING_BOX_NUMBER = createContextCheck((issuingInstructions?: DuplicateIssuingInstructionModel): boolean =>
  Boolean(isIssuingInstructionDuplicateToIssue(issuingInstructions) && issuingInstructions!.issuingMethod === IssuingMethodEnum.IssuingBox)
);

const isIssuingInstructionDuplicateToIssue = (issuingInstructions?: DuplicateIssuingInstructionModel): boolean => {
  return issuingInstructions?.issuingInstruction === IssuingInstructionEnum.DuplicateToIssue;
};

export const VISIBILITY_CHECK_EDITION_DATE = createContextCheck(
  (duplicateHoldingDetail: DuplicateHoldingDetailModel): boolean => duplicateHoldingDetail.editionNumber?.trim() !== '0'
);

// WEB-21508 Validate CT field is not applicable for few documents
export const VISIBILITY_CHECK_VALIDATE_CT = createContextCheck(
  (root: LodgementInstructions2_19_1Model): boolean =>
    Boolean(root.duplicateHoldingDetail?.length) === true &&
    Boolean(
      root.lodgementDocumentSummaries?.every(
        summary =>
          summary.documentIdentifier &&
          (DocumentTypeIdentifierEnum[summary.documentIdentifier] === DocumentTypeIdentifierEnum.NationalMortgageOverInterestInLand ||
            DocumentTypeIdentifierEnum[summary.documentIdentifier] === DocumentTypeIdentifierEnum.DischargeOfMortgageOverInterestInLand ||
            DocumentTypeIdentifierEnum[summary.documentIdentifier] === DocumentTypeIdentifierEnum.Caveat ||
            DocumentTypeIdentifierEnum[summary.documentIdentifier] === DocumentTypeIdentifierEnum.CaveatOverInterestInLand ||
            DocumentTypeIdentifierEnum[summary.documentIdentifier] === DocumentTypeIdentifierEnum.WithdrawalOfCaveat)
      )
    ) === false
);
