import * as React from 'react';

import Box from '@mui/material/Box';

import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs';
import StatusProgress from 'src/components/workspace-status-rebuild/status-progress';
import VerticalStatusLine, { VerticalStatusLineProps } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { ProgressStep, StatusProgressModel } from './models';
import { useStyles } from './styles';

interface Props {
  currentProgress: StatusProgressModel | null;
  steps: ProgressStep[];
  dataTestId?: string;
  verticalStatusLineProps?: VerticalStatusLineProps;
}

function StatusProgressWithBreadcrumb({ currentProgress, steps, dataTestId, verticalStatusLineProps }: Props) {
  const classes = useStyles();

  if (!currentProgress) {
    return null;
  }

  const { step, variant, tooltipContent } = currentProgress;
  const stepDescriptions = steps.map(ps => ps.description);

  const tooltipTitle = (
    <Box display="flex" flexDirection="column">
      {tooltipContent}
      <Breadcrumbs //
        currentStep={step.value}
        steps={stepDescriptions}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );

  if (verticalStatusLineProps) {
    return (
      <VerticalStatusLine //
        {...verticalStatusLineProps}
        tooltipTitle={tooltipTitle}
        tooltipOtherProps={{ classes: { popper: classes.popper } }}
      />
    );
  }

  return (
    <StatusProgress //
      dataTestId={dataTestId}
      steps={stepDescriptions}
      currentStep={step.value}
      variant={variant}
      tooltipTitle={tooltipTitle}
    />
  );
}

export default React.memo(StatusProgressWithBreadcrumb);
