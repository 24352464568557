import { resolveMemoizedTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { resolveTitleReferenceItemSchema } from '../../../../components/title-reference/validationSchema';
import { BaseTitleReferenceModel } from '../../models';

// path: titleReferences
const yupTitleReferenceItem = resolveTitleReferenceItemSchema<BaseTitleReferenceModel>(false);
const yupTitleReferences = resolveMemoizedTitleReferencesValidationSchema<BaseTitleReferenceModel>(yupTitleReferenceItem);

export default yupTitleReferences;
