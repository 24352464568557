import * as React from 'react';

import { FormikProps } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { CheckboxGroupClassKeys } from '@sympli/ui-framework/components/form/base-components/checkbox-group';

import { generateMortgagees } from '../../helpers';
import { DischargeOfMortgage2_21_1Model, MortgageModel } from '../../models';
import styles, { ClassKeys } from './styles';

type FormValues = DischargeOfMortgage2_21_1Model;

interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const SectionMortgages = (props: Props) => {
  const { name, formikProps, disabled, focusRef, classes } = props;
  const {
    values: { dealingNumbersSelectedInOtherDms },
    setValues
  } = formikProps;

  const handleOnChange = React.useCallback(() => {
    setValues(updatedValues => generateMortgagees(updatedValues));
  }, [setValues]);

  const resolveItemLabel = React.useCallback(
    (mortgage: MortgageModel) => {
      const isSelectedWarning = mortgage.isSelected && dealingNumbersSelectedInOtherDms?.includes(mortgage.dealingNumber ?? '');
      return (
        <>
          <Typography component="p" className={classes.mortgageItem}>
            Mortgage being discharged:{' '}
            {
              <strong>
                {mortgage.dealingNumber} ({mortgage.mortgagees.map(x => x.legalEntityName).join(', ')} )
              </strong>
            }
          </Typography>
          {isSelectedWarning && (
            <Typography component="p" className={classes.warning}>
              This mortgage is part of another Discharge of Mortgage document in this workspace
            </Typography>
          )}
        </>
      );
    },
    [classes.mortgageItem, classes.warning, dealingNumbersSelectedInOtherDms]
  );

  const overrideClasses: Partial<ClassNameMap<keyof ReturnType<CheckboxGroupClassKeys>>> = {
    checkboxComponentRoot: classes.checkboxComponentRoot
  };

  return (
    <GenericItemSelection //
      name={name}
      data-testid={name}
      mode="multiple"
      disabled={disabled}
      focusRef={focusRef}
      onChange={handleOnChange}
      resolveItemLabel={resolveItemLabel}
      overrideClasses={overrideClasses}
    />
  );
};

export default withStyles(styles)(SectionMortgages);
