// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { Mortgage2_23_1Model } from './models';
import yupMortgageeDetails from './sections/mortgagee-details/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupTermsAndConditions from './sections/terms-and-conditions/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<Mortgage2_23_1Model>({
    titleReferences: yupTitleReferences,
    mortgageeDetails: yupMortgageeDetails,
    termsAndConditions: yupTermsAndConditions,
    isFinancialWorkspace: yup.boolean().defined(),
    mortgagors: yupMortgagors
  })
  .defined()
  .log();
