import { createAsyncAction } from 'typesafe-actions';

import { WorkspaceReportApiResponse } from '@sympli/api-gateway/models';

export interface WorkspaceReportsApiRequest {
  workspaceId: string;
  participantId: string;
}

export const actionFetchWorkspaceReportsFeed = createAsyncAction(
  //
  'FETCH_LODGEMENT_REPORTS_FEED',
  'FETCH_LODGEMENT_REPORTS_FEED_SUCCESS',
  'FETCH_LODGEMENT_REPORTS_FEED_ERROR'
)<
  //
  WorkspaceReportsApiRequest,
  { data: WorkspaceReportApiResponse[] },
  { error: Error }
>();
