import {
  CommonTenancyLabelsEnum,
  RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  ReceivingTenancyComponentLabelConfig
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

export const VIC_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  header: {
    ...RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG.header,
    tenantsInCommon: CommonTenancyLabelsEnum.TenantsInCommon
  },
  footer: {
    jointTenants: CommonTenancyLabelsEnum.AddJointTenants,
    tenantsInCommon: CommonTenancyLabelsEnum.AddTenantsInCommon
  },
  partyCapacity: CommonTenancyLabelsEnum.RepresentativeCapacity
};
