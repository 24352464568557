import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    chipInputWidth: {
      width: 510
    },
    chipInputFullWidth: {
      width: 800,
      marginTop: 10
    },
    columnTitleContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    createWorkspaceDescription: {
      paddingTop: 0,
      marginLeft: 140
    },
    addTitlesDescription: {
      paddingTop: 0,
      marginLeft: 50
    },
    labelHelp: {
      fontWeight: 'normal'
    }
  },
  {
    name: 'VerifyPropertySection'
  }
);
