import { LookupEnumModel } from '@sympli/ui-framework/models';

/**
 path:
 - dealingRequirementsSummary.dealingRequirementsDetail[*].dealingRequirement
 - dealingRequirementDefinitions[*].id

 schema version:
[{"id":1,"value":"ConsentOfCaveator","name":"Consent of Caveator, if required","_meta":null},{"id":2,"value":"ConsentOfCouncil","name":"Consent of Council, if required","_meta":null},{"id":3,"value":"ConsentOfSubsequentRegisteredMortgagee","name":"Consent of subsequent registered mortgagee or chargee, if required","_meta":null},{"id":4,"value":"CrownLeaseOrCrownSubLease","name":"Dealing with Crown lease or Crown sub-lease - any necessary consents obtained","_meta":null},{"id":5,"value":"EnduringPowerOfAttorney","name":"Enduring Power of Attorney - this is not a conflict transaction under legislation","_meta":null},{"id":6,"value":"EvidenceOfCompliance","name":"Evidence of compliance with Settled Land Act 1958","_meta":null},{"id":7,"value":"EvidenceOfDeath","name":"Evidence of death","_meta":null},{"id":8,"value":"GAIC","name":"GAIC - evidence that transfer may proceed","_meta":null},{"id":9,"value":"GrantOfProbateAndAdministration","name":"Grant of Probate/Administration/Administration with limitations","_meta":null},{"id":10,"value":"LeaseNotRequired","name":"Lease not required to be assessed for duty or lease assessed for duty","_meta":null},{"id":11,"value":"VCATOrder","name":"VCAT Order with supporting statutory declaration","_meta":null},{"id":12,"value":"StateTrustees","name":"State Trustees Section 19 Certificate","_meta":null},{"id":13,"value":"TrusteeEvidenceOfCompliance","name":"Trustee - evidence of compliance with section 30 of Trustee Act 1958","_meta":null}]

 hash:
 WzEsMiwzLDQsNSw2LDcsOCw5LDEwLDExLDEyLDEzXQ==
*/
export enum DealingRequirementTypeEnum {
  ConsentOfCaveator = 1,
  ConsentOfCouncil = 2,
  ConsentOfSubsequentRegisteredMortgagee = 3,
  CrownLeaseOrCrownSubLease = 4,
  EnduringPowerOfAttorney = 5,
  EvidenceOfCompliance = 6,
  EvidenceOfDeath = 7,
  GAIC = 8,
  GrantOfProbateAndAdministration = 9,
  LeaseNotRequired = 10,
  VCATOrder = 11,
  StateTrustees = 12,
  TrusteeEvidenceOfCompliance = 13
}
export const DEALING_REQUIREMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: DealingRequirementTypeEnum.ConsentOfCaveator,
    name: 'Consent of Caveator, if required'
  },
  {
    id: DealingRequirementTypeEnum.ConsentOfCouncil,
    name: 'Consent of Council, if required'
  },
  {
    id: DealingRequirementTypeEnum.ConsentOfSubsequentRegisteredMortgagee,
    name: 'Consent of subsequent registered mortgagee or chargee, if required'
  },
  {
    id: DealingRequirementTypeEnum.CrownLeaseOrCrownSubLease,
    name: 'Dealing with Crown lease or Crown sub-lease - any necessary consents obtained'
  },
  {
    id: DealingRequirementTypeEnum.EnduringPowerOfAttorney,
    name: 'Enduring Power of Attorney - this is not a conflict transaction under legislation'
  },
  {
    id: DealingRequirementTypeEnum.EvidenceOfCompliance,
    name: 'Evidence of compliance with Settled Land Act 1958'
  },
  {
    id: DealingRequirementTypeEnum.EvidenceOfDeath,
    name: 'Evidence of death'
  },
  {
    id: DealingRequirementTypeEnum.GAIC,
    name: 'GAIC - evidence that transfer may proceed'
  },
  {
    id: DealingRequirementTypeEnum.GrantOfProbateAndAdministration,
    name: 'Grant of Probate/Administration/Administration with limitations'
  },
  {
    id: DealingRequirementTypeEnum.LeaseNotRequired,
    name: 'Lease not required to be assessed for duty or lease assessed for duty'
  },
  {
    id: DealingRequirementTypeEnum.VCATOrder,
    name: 'VCAT Order with supporting statutory declaration'
  },
  {
    id: DealingRequirementTypeEnum.StateTrustees,
    name: 'State Trustees Section 19 Certificate'
  },
  {
    id: DealingRequirementTypeEnum.TrusteeEvidenceOfCompliance,
    name: 'Trustee - evidence of compliance with section 30 of Trustee Act 1958'
  }
];

export enum IssuingInstructionTypeEnum {
  RetainOrAcquireEctControl = 0,
  TransferEctControlToRegistrarOfTitles = 1
}

export enum CtDirectionEnum {
  Electronic = 'Electronic',
  Paper = 'Paper'
}

export enum PartyIssuedWithCoRDEnum {
  IssuingParty = 'Issuing Party',
  None = 'None',
  RegistrarOfTitles = 'Registrar of Titles'
}

export const ISSUING_INSTRUCTION_TYPE_LOOKUP_OPTIONS: LookupEnumModel<IssuingInstructionTypeEnum>[] = [
  {
    id: IssuingInstructionTypeEnum.RetainOrAcquireEctControl,
    name: 'Retain or acquire eCT control'
  },
  {
    id: IssuingInstructionTypeEnum.TransferEctControlToRegistrarOfTitles,
    name: 'Transfer eCT control to Registrar of Titles'
  }
];
