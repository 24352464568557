import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchChangeOfNameEvidence } from '../actions/autoJustification';
import { AutoJustificationEvidenceModel } from '../auto-justifications/detail/models';

export interface AutoJustificationEvidenceState {
  detail?: AutoJustificationEvidenceModel;
  status: ApiStatus;
  error?: string;
}

const initialState: AutoJustificationEvidenceState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  AutoJustificationEvidenceState,
  Action
>(initialState)
  .handleAction(actionFetchChangeOfNameEvidence.request, (state): AutoJustificationEvidenceState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchChangeOfNameEvidence.success, (state, action): AutoJustificationEvidenceState => {
    return {
      ...state,
      detail: {
        evidence: action.payload.data[0],
        evidenceOptions: action.payload.data[1]
      },
      status: 'resolved'
    };
  })
  .handleAction(actionFetchChangeOfNameEvidence.failure, (state, action): AutoJustificationEvidenceState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
