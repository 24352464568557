import * as yup from 'yup';

import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { resolveMemoizedReceivingTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/validation-schema';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

import { yupDeceasedTenancyDetailSchema } from '../../components/deceased-tenancy-detail/validationSchema';
import { resolvePartyRoleCapacitySchema } from '../../components/party-role-capacity/validationSchema';
import { NSW_TAB_PARTY_FORM_CONFIG } from './config';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS, APPLICANT_CAPACITY_LOOKUP_OPTIONS, GRANTEE_CAPACITY_LOOKUP_OPTIONS } from './enums';
import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from './models';
import { yupGrantees } from './sections/grantees/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import { yupTitleReferences } from './sections/title-references/validationSchema';

export default yup
  .object<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>({
    partyBookApi: yup.array<ApiDocumentPartyModel<NswDocumentPartyJustification>>().defined(),
    deceasedTenancyDetail: yupDeceasedTenancyDetailSchema,
    titleReferences: yupTitleReferences,
    applicantCapacity: resolvePartyRoleCapacitySchema(APPLICANT_CAPACITY_LOOKUP_OPTIONS),
    granteeCapacity: resolvePartyRoleCapacitySchema(GRANTEE_CAPACITY_LOOKUP_OPTIONS),
    grantees: yupGrantees,
    partyBook: yupPartyBook,
    applicants: resolveMemoizedReceivingTenancyValidation({ partyFormConfig: NSW_TAB_PARTY_FORM_CONFIG, isAddressRequired: false, forceDobVisibilityOnReceivingIndividual: true }),
    invalidParties: yup.mixed<boolean>(),
    attachments: memoizeSchema(yupAdditionalAttachments(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS))
  })
  .defined()
  .log();
