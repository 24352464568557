import * as React from 'react';

import { Action } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Table, { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { Header } from 'src/components/layout';
import SettingsPageContentWrapper from 'src/containers/settings/SettingsPageContentWrapper';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { dateTimeLine } from 'src/utils/formatters';
import { getLinkedDevices, MfaLinkedDeviceModel } from './api';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  dispatch: SafeDispatch<Action>;
}
type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isLoading: boolean;
  showConfirmDialog: boolean;
  showDialogLoading: boolean;
  unlinkMobileDevice?: string;
  devices: Array<MfaLinkedDeviceModel>;
}

class MultiFactorAuthDevices extends React.Component<Props, State> {
  public readonly state: Readonly<State> = {
    isLoading: false,
    devices: [],
    showConfirmDialog: false,
    showDialogLoading: false
  };

  private columns: ColumnsModel<MfaLinkedDeviceModel>;

  constructor(props: Props) {
    super(props);
    this.columns = this.getColumnDefinitions();
  }

  // TODO move this to container file
  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    this.setState({ isLoading: true }, () => {
      getLinkedDevices()
        .then(res => {
          const allLinkedDevices = res.filter((item: MfaLinkedDeviceModel) => {
            return item.status === 'Linked';
          });

          this.setState({ isLoading: false, devices: allLinkedDevices });
        })
        .catch(error => {
          this.setState({ isLoading: false }, () => {
            this.props.dispatch(actionCreateGlobalErrorMessage(error));
          });
        });
    });
  };

  private getColumnDefinitions = () => {
    return {
      name: {
        label: 'Name'
      },
      created: {
        label: 'Linked On',
        formatter: this.formatTimeStamp
      }
    };
  };

  private formatTimeStamp = (config: FormatterInputModel<MfaLinkedDeviceModel>) => {
    const { created, updated } = config.row;

    const dateToBeFormatted = config.column.name === 'created' ? created : updated;
    const formattedDate = dateTimeLine(dateToBeFormatted, DateFormatEnum.AUSDATETIMETEXT);

    return <span>{formattedDate}</span>;
  };

  render() {
    const { isLoading, devices } = this.state;

    return (
      <SettingsPageContentWrapper>
        <Header>Linked devices</Header>
        <Table //
          pagination={false}
          bordersWithSpacing={true}
          columns={this.columns}
          rows={devices}
          loading={isLoading}
          sortable={false}
        />
      </SettingsPageContentWrapper>
    );
  }
}

export default withStyles(styles)(MultiFactorAuthDevices);
