import _uniq from 'lodash-es/uniq';
import _uniqWith from 'lodash-es/uniqWith';
import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';

import { MortgagorPartyModel, TitleReferenceModel } from './models';

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const getMortgagors = defaultMemoize((selectedTitles: TitleReferenceModel[]) => {
  const mortgagorParties = selectedTitles.flatMap(title => title.mortgagors);
  return _uniqWith(mortgagorParties, mortgagorsAreEqual);
});

// partyCapacity and partyCapacityDetail can be null or undefined. Use ?? so that null and undefiend would be equal.
export const mortgagorsAreEqual = (source: MortgagorPartyModel, toCompare: MortgagorPartyModel): boolean => {
  return source.partyBookId === toCompare.partyBookId && source.partyCapacity === toCompare.partyCapacity && source.partyCapacityDetail === toCompare.partyCapacityDetail;
};

export const getTitleMortgagorLegalEntityNames = (partyBook: PartyModel[], titleReference: TitleReferenceModel) => {
  const mortgagorPartyBookIds = titleReference.mortgagors.map(mortgagor => mortgagor.partyBookId);
  return _uniq(partyBook.filter(party => mortgagorPartyBookIds.includes(party.id)).map(party => party.legalEntityName));
};

export const checkSelectedTitlesHaveSameMortgagors = (partyBook: PartyModel[], titleReferences: TitleReferenceModel[]) => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  const mortgagorLegalEntityNamesOfTitleReferences = selectedTitles.map(tr => getTitleMortgagorLegalEntityNames(partyBook, tr));
  if (!mortgagorLegalEntityNamesOfTitleReferences.length) {
    return true;
  }
  const [firstTitle, ...otherTitles] = mortgagorLegalEntityNamesOfTitleReferences;

  for (const names of otherTitles) {
    if (_xor(firstTitle, names).length) {
      return false; // return false if the title's mortgagors name are NOT the same
    }
  }
  return true;
};
