import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { NswPartyForm_2_21 as PartyForm } from '@sympli-mfe/document-components/party-form/nsw/2-21';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { NSW_DM_PARTY_FORM_CONFIG } from '../../config';
import { DischargeOfMortgage2_21_1Model } from '../../models';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
type FormValues = DischargeOfMortgage2_21_1Model;

interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class Mortgagee extends React.PureComponent<Props> {
  render() {
    const { disabled, formikProps, name } = this.props;
    const { values } = formikProps;
    const value: TenancyPartyModel = _get(values, name);

    const partyBook: PartyModel[] = values.partyBook;
    const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
    const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

    return (
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        data-name={name}
        config={NSW_DM_PARTY_FORM_CONFIG}
      />
    );
  }
}

export default withStyles(styles)(Mortgagee);
