import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: { width: '100%' },
    section: { padding: '0 0 6px 0' },
    content: { padding: '20px 20px 0 20px' },
    action: { padding: '0 20px' },
    divider: { borderBottom: `1px solid ${theme.palette.divider}` },
    dividerWarning: { borderBottom: `1px solid ${theme.palette.warning.main}` },
    button: {
      '&:disabled': {
        backgroundColor: 'rgb(255, 217, 179)',
        color: 'white'
      }
    },
    iconConfirmation: {
      fill: theme.palette.warning.main,
      stroke: theme.palette.warning.main,
      strokeWidth: 2,
      paddingRight: 4,
      width: 36,
      height: 36
    }
  }),
  {
    name: 'TacChanges'
  }
);
