import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    purchaserPaysVendorContainer: {
      background: '#f2f2f2',
      marginBottom: 10,
      fontSize: 14,
      '& input:disabled': {
        background: 'rgb(235, 235, 228);'
      }
    },
    row: {
      padding: 13
    },
    inputPurchaserPaysVendor: {
      width: 120,
      height: 34,
      marginBottom: 0,
      verticalAlign: 'unset' // this is to override the upgrade .MuiFormControl-root auto setup default to top
    },
    amount: {
      marginLeft: 5
    },
    textAlignLeft: {
      textAlign: 'left'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
