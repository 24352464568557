import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { Form24B_2_24_1Model, TransferorReferenceModel } from '../../models';
import { VISIBILITY_CHECK_TRUST_NAME } from '../../visibilityCheck';

export default yup
  .array<TransferorReferenceModel>(
    yup
      .object<TransferorReferenceModel>({
        trustName: validateWhenVisible2<string>({
          validationWhenVisible: yup.string().required(msg.REQUIRED).max(80, msg.MAX_LENGTH(80)),
          visibilityCheck: (transferorReference: TransferorReferenceModel, context: Form24B_2_24_1Model) => {
            const party = context.partyBook.find(pb => pb.id === transferorReference.partyBookId)!;
            return VISIBILITY_CHECK_TRUST_NAME(transferorReference, party);
          }
        }),
        isSelected: yup.mixed(),
        partyBookId: yup.mixed(),
        addressBookId: yup.mixed(),
        partyCapacity: yup.mixed()
      })
      .defined()
  )
  .defined();
