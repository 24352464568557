import * as React from 'react';

import { useDispatch } from 'react-redux';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { WorkspaceDetailBoxLoader } from 'src/components/loaders';
import { actionFetchWorkspaceGroups } from 'src/containers/dashboard/shared/actions';
import { useWorkspaceGroups } from 'src/containers/dashboard/shared/reducers/workspaceGroups';
import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { useLinkedWorkspaces } from 'src/containers/shared/linked-workspace-list/reducer';
import { findCurrentWorkspaceParticipantSelector, resolvedAssignedGroupNameSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useSettlementDateDetails } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { actionUpdateParticipantList } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useSafeDispatch } from 'src/hooks';
import { AllocatedUser, TitleReferenceEntityModel } from 'src/models';
import WorkspaceDetailBox, { WorkspaceDetailBoxProps } from './WorkspaceDetailBox';

// DOCS https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2068906002/workspace+detail+box+-+data+dependency+insights
export interface WorkspacePageDetailBoxContainerProps {
  // route parameters
  workspaceId: WorkspaceDetailBoxProps['workspaceId'];
  participantId: WorkspaceDetailBoxProps['participantId'];
  // loaderBackgroundColor?: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  // panelPosition?: WorkspaceDetailBoxProps['panelPosition'];
  disableTitleClickNavigation?: WorkspaceDetailBoxProps['disableTitleClickNavigation'];
  disableSettlementDateLink?: WorkspaceDetailBoxProps['disableSettlementDateLink'];
  hideAssignedStaff?: WorkspaceDetailBoxProps['hideAssignedStaff'];
  proposedSettlementDate?: WorkspaceDetailBoxProps['settlementDetails']['proposedSettlementDate'];
  currentSettlementDate?: WorkspaceDetailBoxProps['settlementDetails']['currentSettlementDate'];
  // showForwardedComment?: WorkspaceDetailBoxProps['showForwardedComment'];
  // hideMatterIdOnAwaitingTask?: WorkspaceDetailBoxProps['hideMatterIdOnAwaitingTask'];
  // other
  portalIdForSettlementDate?: string;
}

export default function WorkspacePageDetailBoxContainer(props: React.PropsWithChildren<WorkspacePageDetailBoxContainerProps>) {
  const dispatch = useSafeDispatch(useDispatch());

  const workspaceDetailState = useWorkspaceDetail(props.workspaceId, props.participantId);
  const workspaceBasicInfoState = useWorkspaceBasicInfo(props.workspaceId);
  const workspaceParticipantsState = useWorkspaceParticipants(props.workspaceId);
  const workspaceGroupsState = useWorkspaceGroups();
  const settlementDetailsState = useSettlementDateDetails(props.workspaceId, props.participantId);
  const linkedWorkspaceState = useLinkedWorkspaces(props.workspaceId);
  const lodgementCaseStatusId: LodgementCaseStatusEnum | undefined = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);
  const currentWorkspaceParticipant: WorkspaceParticipantApiResponse | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: props.participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  React.useEffect(() => {
    dispatch(actionFetchWorkspaceGroups.request({ includeUnassignableGroups: true }));
  }, [dispatch]);

  const handleOnAssigneeUpdated = React.useCallback(
    (workspaceId: string, participantId: string, selectedSuggestion: AllocatedUser) => {
      const workspaceParticipants = workspaceParticipantsState.items;

      const participantIndex = workspaceParticipants.findIndex(workspaceParticipant => workspaceParticipant.id === participantId);
      if (participantIndex !== -1) {
        const newParticipants = workspaceParticipants.concat();
        const currentParticipant = workspaceParticipants[participantIndex];
        newParticipants[participantIndex] = { ...currentParticipant, allocatedUser: selectedSuggestion };
        dispatch(actionUpdateParticipantList({ participants: newParticipants, workspaceId }));
      }
    },
    [workspaceParticipantsState.items, dispatch]
  );

  const assignedWorkspaceGroupName: string | undefined = resolvedAssignedGroupNameSelector({
    //
    participant: currentWorkspaceParticipant,
    workspaceAssignableGroups: workspaceGroupsState.items
  });

  // TODO change backend to return very basic info only
  const titleReferences: TitleReferenceEntityModel[] = React.useMemo(() => {
    return (workspaceDetailState.detail?.titleReferences || []).map(title => {
      const {
        landRegistryDetail: { propertyAddress: address, reference },
        titleActivityCheckResult
      } = title;

      return {
        address,
        reference,
        documentChanges: titleActivityCheckResult?.documentChanges,
        hasChanges: !!titleActivityCheckResult && titleActivityCheckResult.hasChanges
      };
    });
  }, [workspaceDetailState.detail?.titleReferences]);

  if (workspaceBasicInfoState.isLoading) {
    return <WorkspaceDetailBoxLoader color="grey" />;
  }

  if (workspaceBasicInfoState.status === 'rejected') {
    // TODO add some nice error message
    return null;
  }

  // TODO fix this logic after settlement date edit page is refactored
  // we might need to accept also props params here
  const { isAcceptedByAll, isAcceptedByUser } = settlementDetailsState.detail;
  const currentSettlementDate =
    !isAcceptedByAll && !isAcceptedByUser && settlementDetailsState.detail.localWorkspaceSettlementDate ? settlementDetailsState.detail.localWorkspaceSettlementDate : undefined;
  const proposedSettlementDate =
    !isAcceptedByAll && !isAcceptedByUser && settlementDetailsState.detail.localProposedSettlementDate ? settlementDetailsState.detail.localProposedSettlementDate : undefined;

  return (
    <WorkspaceDetailBox
      {...props}
      isLoadingWorkspaceParticipantData={workspaceParticipantsState.isLoading}
      isLoadingFullWorkspaceData={workspaceDetailState.isLoading}
      isLoadingWorkspaceAssignableGroupsData={workspaceGroupsState.isLoading}
      isLoadingSettlementDetailsData={settlementDetailsState.isLoading}
      skipFetchingWorkspaceTasks={true} // we don't need to fetch tasks because workspace page wrapper takes care of it
      workspaceDetail={{
        // basic workspace info
        sympliId: workspaceBasicInfoState.detail?.sympliId,
        workspaceTypeId: workspaceBasicInfoState.detail?.workspaceTypeId,
        workspaceStatusId: workspaceBasicInfoState.detail?.workspaceStatusId,
        lodgementCaseStatusId,
        settlementDate: workspaceBasicInfoState.detail?.settlementDate,
        expectedLodgementDate: workspaceBasicInfoState.detail?.expectedLodgementDate,
        isLocked: workspaceBasicInfoState.detail?.isLocked,
        jurisdictionId: workspaceBasicInfoState.detail?.jurisdictionId,
        // full workspace info
        titleReferences: titleReferences
      }}
      // current participant info
      currentParticipant={{
        roleId: currentWorkspaceParticipant?.workspaceRole.id,
        groupId: currentWorkspaceParticipant?.groupId,
        allocatedUser: currentWorkspaceParticipant?.allocatedUser,
        matter: currentWorkspaceParticipant?.reference,
        partyNames: currentWorkspaceParticipant?.partyNames
      }}
      settlementDetails={{
        proposedSettlementDate,
        currentSettlementDate
      }}
      // workspace assignable groups
      assignedWorkspaceGroupName={assignedWorkspaceGroupName}
      // withdrawn or abandoned workspace should not enable the title link
      disableTitleClickNavigation={
        workspaceBasicInfoState.detail?.workspaceStatusId === WorkspaceStatusEnum.Abandoned || //
        workspaceBasicInfoState.detail?.workspaceStatusId === WorkspaceStatusEnum.Withdrawn
      }
      linkedWorkspaces={linkedWorkspaceState.items}
      onUpdateAssignee={handleOnAssigneeUpdated}
    />
  );
}
