import React from 'react';

import { formatInTimeZone } from 'date-fns-tz';
import { enAU } from 'date-fns/locale';

import { FormatterInputModel } from '@sympli/ui-framework/components/table';

import { ReportTypeEnumDisplayMap, SavedScheduleReportModel, scheduledFrequencyMapping } from '../../models';
import ReportHistoryDownloadButton from './components/report-history-download-button';
import { HistoryTableContext } from './ScheduledReportHistory';

const formatFrequencyColumn = (config: FormatterInputModel<SavedScheduleReportModel, string>) => {
  const { row } = config;

  return scheduledFrequencyMapping[row.scheduledFrequency];
};

const formatDownloadColumn = (config: FormatterInputModel<SavedScheduleReportModel, undefined, HistoryTableContext>) => {
  const { row, context } = config;
  const { onDownloadClick } = context!;

  return (
    <ReportHistoryDownloadButton
      isLoading={row.isLoading}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onDownloadClick(row.id);
      }}
    />
  );
};

const formatReportTypeColumn = (config: FormatterInputModel<SavedScheduleReportModel, string>) => {
  const { row } = config;

  return ReportTypeEnumDisplayMap[row.reportType];
};

const formatReportDateGenerated = (config: FormatterInputModel<SavedScheduleReportModel, string>) => {
  const aestTimeZone = 'Australia/Sydney'; // always display UTC as AEST/AEDT time
  return formatInTimeZone(new Date(config.row.generatedAtUtc), aestTimeZone, 'dd MMM yyyy (ccc hh.mm a zzz)', { locale: enAU });
};

export { formatFrequencyColumn, formatDownloadColumn, formatReportTypeColumn, formatReportDateGenerated };
