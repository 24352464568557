import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconEye } from '@sympli/ui-framework/icons';

import { DisplayViewEnum } from '../../workflow-panel/models';
import styles, { ClassKeys } from '../sharedStyles';

interface OwnProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  displayDocumentAsPdf?: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class SwitchFormView extends React.PureComponent<Props> {
  render() {
    const { classes, displayDocumentAsPdf, onClick } = this.props;
    const displayView = !displayDocumentAsPdf ? DisplayViewEnum.PDF : DisplayViewEnum.Form;

    return (
      <MenuItem onClick={onClick}>
        <IconEye className={classes.menuItemIcon} />
        <Typography className={classes.menuItemLabel}>{`Switch to ${displayView.toString()}`}</Typography>
      </MenuItem>
    );
  }
}

export default withStyles(styles)(SwitchFormView);
