import { LodgementOrderSummaryModel } from '@sympli-mfe/document-forms-framework/components/sections/lodgement-order-summary';
import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { CtDirectionTypeEnum, IssuingInstructionTypeEnum, PartyIssuedWithCoRDEnum } from './enums';
import { IssuingInstructionsModel } from './models';

export const updateIssuingInstructionsAndPartyIssued = (
  ctDirectionTypeEnum: CtDirectionTypeEnum,
  workspaceType: WorkspaceTypeEnum,
  lodgementOrderSummary: LodgementOrderSummaryModel
): [IssuingInstructionsModel | undefined, PartyIssuedWithCoRDEnum | undefined] => {
  switch (ctDirectionTypeEnum) {
    case CtDirectionTypeEnum.Paper:
      return getPartyIssuedWhenDirectionIsPaper(workspaceType, lodgementOrderSummary);
    case CtDirectionTypeEnum.Electronic:
      return getPartyIssuedWhenDirectionIsElectronic(workspaceType, lodgementOrderSummary);
    default:
      return [undefined, undefined];
  }
};

const getPartyIssuedWhenDirectionIsPaper = (
  workspaceType: WorkspaceTypeEnum,
  lodgementOrderSummary: LodgementOrderSummaryModel
): [IssuingInstructionsModel | undefined, PartyIssuedWithCoRDEnum | undefined] => {
  if (workspaceType === WorkspaceTypeEnum.FinancialSettlement) {
    return [undefined, PartyIssuedWithCoRDEnum.IssuingParty];
  } else if (workspaceType === WorkspaceTypeEnum.RegistrationOnly) {
    if (lodgementOrderSummary.lodgementOrders.some(x => x.document.documentIdentifier === 'NationalMortgage' || x.document.documentIdentifier === 'DischargeOfMortgage')) {
      return [undefined, PartyIssuedWithCoRDEnum.IssuingParty];
    }
    if (lodgementOrderSummary.lodgementOrders.some(x => x.document.documentIdentifier === 'WithdrawalOfNomination')) {
      return [undefined, PartyIssuedWithCoRDEnum.None];
    }
  }
  return [undefined, undefined];
};

const getPartyIssuedWhenDirectionIsElectronic = (
  workspaceType: WorkspaceTypeEnum,
  lodgementOrderSummary: LodgementOrderSummaryModel
): [IssuingInstructionsModel | undefined, PartyIssuedWithCoRDEnum | undefined] => {
  if (workspaceType === WorkspaceTypeEnum.FinancialSettlement) {
    return [
      {
        editable: true,
        issuingInstructionType: IssuingInstructionTypeEnum.RetainOrAcquireEctControl
      },
      PartyIssuedWithCoRDEnum.IssuingParty
    ];
  } else if (workspaceType === WorkspaceTypeEnum.RegistrationOnly) {
    let partyIssued: [IssuingInstructionsModel | undefined, PartyIssuedWithCoRDEnum | undefined] = [undefined, undefined];
    lodgementOrderSummary.lodgementOrders.forEach(x => {
      partyIssued = getPartyIssuedByDocumentIdentifier(x.document.documentIdentifier);
    });
    return partyIssued;
  }
  return [undefined, undefined];
};

const getPartyIssuedByDocumentIdentifier = (documentIdentifier: string): [IssuingInstructionsModel | undefined, PartyIssuedWithCoRDEnum | undefined] => {
  switch (documentIdentifier) {
    case 'NationalMortgage':
      return [
        {
          editable: true,
          issuingInstructionType: IssuingInstructionTypeEnum.RetainOrAcquireEctControl
        },
        PartyIssuedWithCoRDEnum.IssuingParty
      ];
    case 'DischargeOfMortgage':
      return [
        {
          editable: true,
          issuingInstructionType: IssuingInstructionTypeEnum.TransferEctControlToRegistrarOfTitles
        },
        PartyIssuedWithCoRDEnum.RegistrarOfTitles
      ];
    case 'WithdrawalOfNomination':
      return [
        {
          editable: false,
          issuingInstructionType: undefined
        },
        PartyIssuedWithCoRDEnum.None
      ];
    case 'RequestTransferEctControlToRegistrar':
    case 'RequestEctControlFromRegistrar':
      return [undefined, undefined];
    default:
      return [
        {
          editable: false,
          issuingInstructionType: undefined
        },
        undefined
      ];
  }
};
