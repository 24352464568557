import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';
import columnStyles from '../../columnStyles';

const styles = (theme: Theme) => ({
  ...columnStyles(theme),
  ...createStyles({
    bottomPadding: {
      paddingBottom: 8
    },
    description: {
      fontSize: 13,
      color: theme.palette.text.disabled
    },
    showDetailsButtonLink: {
      color: colors.NEUTRAL_500,
      paddingLeft: 0
    },
    divider: {
      marginBottom: 8
    },
    dividerColumn: {
      width: '70%'
    },
    breakdownLineItemsColOne: {
      width: '65%'
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
