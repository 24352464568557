import Button from '@mui/material/Button';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

export interface Props {
  handleOnCancel(): void;
}

function DeleteModeButtons({ handleOnCancel }: Props) {
  return (
    <FlexLayout justifyContent="center" alignItems="center" className="bg-[var(--watermelon)] mx-[-24px] py-[8px]">
      <Button
        variant="outlined"
        className="hover:bg-[var(--watermelon-luminous)] rounded-[34px] border-[2px] border-[var(--neutral-000)] text-[var(--neutral-000)] px-[14px] py-[4px] text-[12px] font-bold leading-[16px] min-h-[20px]"
        onClick={handleOnCancel}
      >
        Exit Delete Mode
      </Button>
    </FlexLayout>
  );
}

export default DeleteModeButtons;
