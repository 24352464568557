import React from 'react';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

export interface Props {
  steps: Array<string>;
  currentStep: number;
  // styleOverrides
  sxBold?: SxProps<Theme>;
  sxNoBold?: SxProps<Theme>;
  sxBox?: SxProps<Theme>;
}

function Breadcrumbs({ steps, currentStep, sxBold = [], sxNoBold = [], sxBox = [] }: Props) {
  const bold = steps.slice(0, currentStep).join(' > '); // 'in preparation > prepared'
  const noBold = steps
    .slice(currentStep)
    .map(x => ' > ' + x)
    .join(''); //  ' > ready > lodging > lodged > completed'

  return (
    <Box
      sx={[
        { margin: theme => theme.spacing(0.5, 2) },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sxBox) ? sxBox : [sxBox])
      ]}
    >
      {currentStep > 0 && (
        <Typography
          variant="body3_bold"
          sx={[
            {
              color: colors.GREY_40
            },
            // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
            ...(Array.isArray(sxBold) ? sxBold : [sxBold])
          ]}
        >
          {bold}
        </Typography>
      )}
      {currentStep < steps.length && (
        <Typography
          variant="body3"
          sx={[
            {
              color: colors.GREY_40
            },
            // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
            ...(Array.isArray(sxNoBold) ? sxNoBold : [sxNoBold])
          ]}
        >
          {noBold}
        </Typography>
      )}
    </Box>
  );
}

export default React.memo(Breadcrumbs);
