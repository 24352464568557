import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { TitleReferenceModel as BaseTitleReferenceModel, PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ProprietorGroupModel, TenancyDetail, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel, ApiPartySourceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { RegistryDocumentModel } from '@sympli/api-gateway/shared';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { ActionProhibitedEnum, ClaimCategoryEnum, ClaimStatementEnum, EstateOrInterestClaimedEnum, RegistryInstrumentTypeEnum } from './enums';

export interface BaseTitleReference {
  isPartLandAffected?: boolean; // we made this optional
  isSelected: boolean;
  landDescriptions?: LandDescription[];
  reference?: string;
}

interface LandDescription {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponent[];
  legalDescription?: string;
}

interface LandDescriptionComponent {
  name?: string;
  value?: string;
}

export interface ApiBaseCaveatModel {
  addressBook: BaseAddress[];
  partyBook: ApiDocumentPartyModel[];
  titleReferences: TitleReferenceModel[];
  caveators: TenancyPartyModel[];
  invalidParties: boolean;
  proprietors: TenancyPartyModel[];
  mortgagees: TenancyPartyModel[];
  claimDetails: ClaimDetailsModel;
  otherParties: ReceivingTenancyDetailModel;
  prohibition: ExtentOfProhibitionModel;
  subscriberParties: ApiPartySourceModel[];
}

export interface MortgageeModel {
  dealingNumber: string;
  parties: ApiPartySourceModel[];
}
export interface TitleReferenceModel extends BaseTitleReferenceModel<PartLandAffectedModel> {
  proprietors: TenancyDetail<ProprietorGroupModel<ApiPartySourceModel>>;
  mortgagees?: MortgageeModel[];
  registerDealings: RegisterDealingModel[];
}

export interface ClaimDetailsModel {
  claimCategory?: ClaimCategoryEnum;
  estateOrInterestClaimed?: EstateOrInterestClaimedEnum;
  claimStatement?: ClaimStatementEnum;
  claimDate?: Date | string | null;
  detailsSupportingTheClaim?: string;
  claimParties?: TenancyPartyModel[];
}

export interface ExtentOfProhibitionModel {
  actionProhibited?: ActionProhibitedEnum;
  notApplicableInstruments: ApplicableInstrumentModel[];
}

export interface RegisterDealingModel {
  type: string;
  registryDocument: RegistryDocumentModel;
  parties: ApiPartySourceModel[];
}

export interface ApplicableInstrumentModel {
  instrumentType?: RegistryInstrumentTypeEnum;
  dealingNumber?: string;
  relinquishingParties: TenancyPartyModel[];
  receivingParties: TenancyPartyModel[];
}

export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

export type BaseCaveatModel = Omit<ApiBaseCaveatModel, 'addressBook' | 'partyBook' | 'mortgagees'> & {
  [ADDRESS_BOOK_KEY]: AddressBookEntityModel[];
  [PARTY_BOOK_KEY]: PartyModel[];
  mergeFailedReason?: MergeFailedReasonsEnum;
  invalidParties: boolean;
  proprietors: TenancyPartyModel[];
  mortgagees: TenancyPartyModel[];
  claimDetails: ClaimDetailsModel;
  partyReferences: TenancyPartyModel[];
};
