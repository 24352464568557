import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';

import { WORKSPACE_DETAIL_PAGE } from 'src/containers/router/constants';
import { actionFetchWorkspaceBasicInfo, actionFetchWorkspaceById, actionFetchWorkspaceTasks } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceTasks } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';
import { useSafeDispatch } from 'src/hooks';
import WorkspaceTasksBox from './WorkspaceTasksBox';

interface Props {
  workspaceId: string;
  participantId: string;
  workspaceTypeId?: WorkspaceTypeEnum;
  matter: string;
  isLocked?: boolean;
  expectedLodgementDate?: WorkspaceDateTimeModel;
  lineLoaderBackgroundColor?: 'white' | 'grey';
  hideMatterIdOnAwaitingTask?: boolean;
  skipFetching?: boolean;
}

function WorkspaceTasksBoxContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceBasicInfoState = useWorkspaceBasicInfo(props.workspaceId);
  const workspaceTasksState = useWorkspaceTasks(props.workspaceId, props.participantId);
  const isLodgementOnly: boolean = props.workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
  // find fastest way to resolve number of tasks for current participant:
  // workspaceBasicDetail provides us with numberOfTasks which represents all tasks within workspace for all participants.
  // in case of LO (since there is only one participant) we can use it (if it's faster than resolving list of tasks)
  let numberOfTasksForCurrentParticipant: number | undefined =
    workspaceTasksState.status === 'resolved' || workspaceTasksState.isRefetching ? workspaceTasksState.items.length : undefined;

  let isLoadingNumberOfTasks = workspaceTasksState.isLoading;
  if (isLodgementOnly && numberOfTasksForCurrentParticipant === undefined) {
    // if this endpoint was resolved faster, we can use it for LO
    if (workspaceBasicInfoState.status === 'resolved' && workspaceBasicInfoState.detail?.numberOfTasks !== undefined) {
      // TODO remove this undefined check once we update dashboards
      numberOfTasksForCurrentParticipant = workspaceBasicInfoState.detail?.numberOfTasks;
      isLoadingNumberOfTasks = false;
    }
  }

  const { skipFetching, workspaceId, participantId } = props;
  useEffect(() => {
    if (!skipFetching) {
      dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
    }
  }, [dispatch, skipFetching, workspaceId, participantId]);

  const handleOnUpdateLodgementDate = useCallback(() => {
    // TODO explain why we need to refetch workspace and decide which one to use (full or basic)
    dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
    dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
  }, [dispatch, workspaceId, participantId]);

  const { pathname } = useLocation();
  const isMatterAndTasksSectionSelected = pathname.endsWith(WORKSPACE_DETAIL_PAGE);

  return (
    <WorkspaceTasksBox //
      {...props}
      isLoading={isLoadingNumberOfTasks}
      error={workspaceTasksState.error}
      numberOfTasksForCurrentParticipant={numberOfTasksForCurrentParticipant}
      onUpdateLodgementDate={handleOnUpdateLodgementDate}
      isMatterAndTasksSectionSelected={isMatterAndTasksSectionSelected}
    />
  );
}

export default React.memo(WorkspaceTasksBoxContainer);
