import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      height: '100%'
    }
  }),
  {
    name: 'UserReport'
  }
);
