import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { Header } from 'src/components/layout';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  header: React.ReactNode;
  description?: React.ReactNode;
  marginBottom?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class SettingHeader extends React.PureComponent<React.PropsWithChildren<Props>> {
  public static defaultProps: Partial<Props> = {
    marginBottom: true
  };

  render() {
    return (
      <React.Fragment>
        {this.renderHeaderLine()}
        {this.renderDescription()}
      </React.Fragment>
    );
  }

  private renderHeaderLine() {
    const { header, children, classes } = this.props;
    if (children) {
      return (
        <FlexLayout justifyContent="space-between" alignItems="center" className={classNames(classes.marginBottom, classes.root)}>
          <Header className={classes.header}>{header}</Header>
          {children}
        </FlexLayout>
      );
    }

    return <Header>{header}</Header>;
  }
  private renderDescription() {
    const { classes, description, marginBottom } = this.props;
    if (description) {
      return (
        <Typography variant="subtitle1" className={classNames(classes.description, marginBottom && classes.marginBottom)}>
          {description}
        </Typography>
      );
    }
    return null;
  }
}

export default withStyles(styles)(SettingHeader);
