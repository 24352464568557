import React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import { ClassNameMap } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface Props {
  amount: string;
  description: string;
  accountDisplayName?: string;
  noBottomBorder?: boolean;
  columnClasses: ClassNameMap<'columnOne' | 'columnTwo' | 'columnThree'>;
}

function BreakdownLineItem({ amount, description, accountDisplayName, noBottomBorder: disableBottomBorder = false, columnClasses }: Props) {
  const classes = useStyles();
  return (
    <FlexLayout className={classes.lineItem}>
      <div className={classNames(columnClasses.columnOne)}></div>
      <div className={classNames(columnClasses.columnTwo, disableBottomBorder || classes.bottomBorder, classes.bottomPadding)}>
        {accountDisplayName && <Typography className={classes.displayName}>{accountDisplayName}</Typography>}
        <Typography className={classes.description}>{description}</Typography>
      </div>
      <FlexLayout justifyContent="flex-end" className={classNames(columnClasses.columnThree, disableBottomBorder || classes.bottomBorder, classes.bottomPadding)}>
        <Typography>{amount}</Typography>
      </FlexLayout>
    </FlexLayout>
  );
}

export default React.memo(BreakdownLineItem);
