import React, { useEffect } from 'react';

import { Form } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { TransferOfControlModel } from './models';
import SectionApplicant from './sections/applicant';
import SectionRecipient from './sections/recipient';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<TransferOfControlModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          disabled={disabled}
          name={fieldName('titleReferences')}
          focusRef={focusOnTitleReferencesRef}
        />
        <SectionApplicant //
          name={fieldName('applicant')}
          disabled={disabled}
        />
        <SectionRecipient //
          name={fieldName('recipient')}
          disabled={disabled}
        />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
