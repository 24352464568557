import * as React from 'react';

import { WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { SharedInitialValues } from '../../models';
import { initialValues } from './initialValues';
import { CreateFinancialWorkspaceSharedModel, CreateFinancialWorkspaceStepEnum } from './models';
import InviteParticipantsContainer from './steps/invite-participants';
import MatterDetailContainer from './steps/matter-detail';

interface Props {
  onClose: (link?: string) => void;
  onWorkspaceTypeChange: (workspaceTypeId: WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => void;
  workspaceTypeOptions: LookupEnumModel<WorkspaceTypeEnum>[];
  workspaceTypeId: WorkspaceTypeEnum;
  createdByRoleId?: WorkspaceRoleEnum | null;
  // these are the values that are passed around when we change workspace type
  sharedInitialValues: Partial<SharedInitialValues>;
}

interface State {
  step: CreateFinancialWorkspaceStepEnum;
}

class FinancialForm extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    createdByRoleId: null // initialize role to null if not provided
  };

  public readonly state: Readonly<State> = {
    step: CreateFinancialWorkspaceStepEnum.AddMatterDetails
  };

  private globalValues: CreateFinancialWorkspaceSharedModel = {
    //
    ...initialValues,
    matterDetailsStepData: {
      ...initialValues.matterDetailsStepData,
      ...this.props.sharedInitialValues, // merge in shared data
      createdByRoleId: this.props.createdByRoleId! // createdByRoleId will be null | or enum
    }
  };

  render() {
    const { step } = this.state;
    switch (step) {
      case CreateFinancialWorkspaceStepEnum.AddMatterDetails:
        return (
          <MatterDetailContainer
            onGlobalValuesUpdate={this.handleOnGlobalValuesUpdate}
            onStepChange={this.handleOnStepChange}
            onClose={this.props.onClose}
            onWorkspaceTypeChange={this.props.onWorkspaceTypeChange}
            //
            workspaceTypeOptions={this.props.workspaceTypeOptions}
            workspaceTypeId={this.props.workspaceTypeId}
          />
        );
      case CreateFinancialWorkspaceStepEnum.InviteMembers:
        return (
          <InviteParticipantsContainer
            onGlobalValuesUpdate={this.handleOnGlobalValuesUpdate}
            onStepChange={this.handleOnStepChange}
            onClose={this.props.onClose}
            workspaceTypeId={this.props.workspaceTypeId}
          />
        );
      case CreateFinancialWorkspaceStepEnum.JoinActiveWorkspace:
        //TODO our component should be here
        return null;
      default:
        return 'error';
    }
  }

  private handleOnGlobalValuesUpdate = (value?: Partial<CreateFinancialWorkspaceSharedModel>) => {
    if (value) {
      this.globalValues = {
        ...this.globalValues,
        ...value
      };
    }
    return this.globalValues;
  };
  private handleOnStepChange = (step: CreateFinancialWorkspaceStepEnum) => {
    this.setState({ step });
  };
}

export default FinancialForm;
