import { Interval, intervalToDuration, isValid } from 'date-fns';
import pluralize from 'pluralize';

import { DateLength } from '../../../models';
import { getDateAfter } from '../helpers';

export function getLeasePeriod(start: Date | null, end: Date | null): DateLength | undefined {
  if (!isValid(start) || !isValid(end)) {
    return;
  }
  const startWithoutTime = new Date(start!.toDateString());
  // Lease period will be expiry date inclusive
  const endWithoutTime = new Date(getDateAfter(end!)!.toDateString());
  const interval: Interval = {
    start: startWithoutTime,
    end: endWithoutTime
  };
  const diff = intervalToDuration(interval);
  return {
    years: diff.years ?? 0,
    months: diff.months ?? 0,
    days: diff.days ?? 0
  };
}

export function formatDateLength(dateLength: DateLength | null): string | null {
  if (!dateLength) {
    return null;
  }
  const { years, months, days } = dateLength;
  let message: string[] = [`${years > 0 ? `${years} ${pluralize('year', years)}` : ''}`];
  message.push(`${months > 0 ? `${months} ${pluralize('month', months)}` : ''}`);
  message.push(`${days > 0 ? `${days} ${pluralize('day', days)}` : ''}`);
  return message.filter(x => Boolean(x)).join(', ');
}
