import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      fontSize: 14,
      marginRight: 5,
      minWidth: '40px',
      height: '30px'
    },
    notSelectedButton: {
      backgroundColor: theme.palette.grey[50]
    }
  }),
  {
    name: 'Weekday'
  }
);
