import React from 'react';

import classNames from 'classnames';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';

import { useTabOverridesClasses } from './styles';

interface TabProps extends MuiTabProps {}

function Tab({ className, ...props }: TabProps) {
  const classes = useTabOverridesClasses();

  return (
    <MuiTab //
      className={classNames(classes.root, className)}
      {...props}
    />
  );
}

export default React.memo(Tab);
