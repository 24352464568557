import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { $get, $noneOf, $oneOf, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { TransactionInstrumentModel } from '../../../../shared/2-24/priority-notice/models';
import { TransactionInstruments$DocumentTypeEnum } from './enums';

// this file was automatically generated from checks.ts.mustache

export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER = createContextCheck((context: TransactionInstrumentModel<PartLandAffectedWithDescriptionsModel>): boolean =>
  $oneOf($get(context, 'documentType'), [
    TransactionInstruments$DocumentTypeEnum.ReleaseOfMortgage,
    TransactionInstruments$DocumentTypeEnum.WithdrawalOfCaveat,
    TransactionInstruments$DocumentTypeEnum.RemovalOfLapsedCaveat,
    TransactionInstruments$DocumentTypeEnum.Release,
    TransactionInstruments$DocumentTypeEnum.Withdrawal
  ])
);

export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES = createContextCheck(
  (context: TransactionInstrumentModel<PartLandAffectedWithDescriptionsModel>): boolean =>
    $noneOf($get(context, 'documentType'), [
      TransactionInstruments$DocumentTypeEnum.ReleaseOfMortgage,
      TransactionInstruments$DocumentTypeEnum.WithdrawalOfCaveat,
      TransactionInstruments$DocumentTypeEnum.RemovalOfLapsedCaveat,
      TransactionInstruments$DocumentTypeEnum.Release,
      TransactionInstruments$DocumentTypeEnum.Withdrawal,
      null
    ])
);

export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES = createContextCheck((context: TransactionInstrumentModel<PartLandAffectedWithDescriptionsModel>): boolean =>
  $noneOf($get(context, 'documentType'), [null])
);
