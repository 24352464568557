import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  //
  (theme: Theme) => ({
    vertical: {
      height: 18,
      width: 2,
      border: 'none',
      margin: 0,
      marginLeft: 4,
      marginRight: 4,
      background: theme.palette.divider
    },
    smallRow: {
      fontSize: 13,
      lineHeight: '15px'
    },
    sectionSpacingTop: {
      paddingTop: 10
    },
    sectionSpacingTopSmall: {
      paddingTop: 5
    },
    sectionSpacingBottom: {
      paddingBottom: 16
    },
    sectionIcon: {
      paddingRight: 4
    },
    iconMapMaker: {
      marginLeft: -2,
      paddingRight: 2
    },
    warning: {
      fill: colors.ORANGE,
      color: colors.ORANGE
    },
    titleList: {
      marginLeft: -16,
      marginRight: -16
    },
    titleItems: {
      paddingLeft: 16,
      paddingRight: 16
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: 16
    },
    buttonLink: {
      color: colors.GREY_NEW
    }
  }),
  { name: 'TitleAndAddresses' }
);

export type ClassKeys = ReturnType<typeof useStyles>;
