import * as React from 'react';

import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import logoLocked from 'src/assets/locked.svg';
import logoSympli from 'src/assets/logo-sympli.svg';
import { useStyles } from './styles';

function Unauthorized() {
  const classes = useStyles();
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .container */}

      {/* .header */}
      <div className="flex-0 flex flex-col justify-center">
        <nav
          className="relative z-[5] mx-auto box-border w-screen shrink-0 px-[24px] medium-screen:px-[32px] large-screen:px-[48px]"
          style={{
            boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)'
          }}
        >
          <div className="flex h-[64px] items-center justify-between medium-screen:h-[90px]">
            <div className="flex items-center">
              <Link to="/" className="mr-[16px] large-screen:mr-[32px]" data-testid="sympli-logo">
                <img src={logoSympli} alt="Back to home page" className="h-[48px]" />
              </Link>
            </div>
          </div>
        </nav>
        <div
          className="absolute top-[64px] hidden h-[48px] w-screen bg-mui-palette-common-white medium-screen:top-[90px]"
          style={{
            boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
            zIndex: 4
          }}
        />
      </div>
      <div className="grid grid-cols-[1fr] grid-rows-[1fr] overflow-hidden">
        {/* .body */}

        <div className="overflow-y-auto">
          {/* .scrollable-content */}
          <div className="mx-auto max-w-[var(--small-content)] pb-[var(--bottom-content-spacing)] pt-[16px] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
            <FlexLayout //
              flexDirection="column"
              alignItems="center"
              fullWidth
              className={classes.container}
            >
              <Typography variant="h1" className={classes.heading}>
                Access denied
              </Typography>
              <img height={130} width={130} src={logoLocked} alt="access denied" />
              <Typography className={classes.description}>
                You do not have permission to access this page. Please contact us on{' '}
                <Typography component="span" className={classes.contact}>
                  1300 SYMPLI (1300 796 754).
                </Typography>
              </Typography>
            </FlexLayout>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unauthorized;
