import React, { memo } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { NewArrowDown, NewIndicator } from '@sympli/ui-framework/icons';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Dialog from 'src/containers/dashboard/components/dialog/Dialog';
import { convertWorkspaceDetail } from '../modal-dialog-content/helpers';
import ModalDialogContent from '../modal-dialog-content/ModalDialogContent';
import SearchJoinTable from '../search-join-table';
import { ActiveWorkspaceItem } from '../search-join-table/models';
import { JoinWorkspaceApiRequest } from './api';
import { useStyles } from './styles';

export interface ActiveWorkspaceAccordionProps {
  // search table
  items: ActiveWorkspaceItem[];
  workspaceType: WorkspaceTypeEnum;
  isLoadingTable: boolean;
  groupId: string;

  // join dialog
  row?: number;
  open: boolean;
  handleOnClose: () => void;
  role?: WorkspaceRoleEnum;
  onJoinWorkspace: (data: JoinWorkspaceApiRequest) => Promise<void>;
  matter?: string;
}

const ActiveWorkspaceAccordion = ({
  //
  open,
  items,
  groupId,
  handleOnClose,
  onJoinWorkspace,
  isLoadingTable,
  role,
  workspaceType,
  row,
  matter
}: ActiveWorkspaceAccordionProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
    if (isExpanded) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'open-active-workspace-accordion',
        logGroupId: 'search-and-join-workspace'
      });
    }
  };

  const renderAccordionSummary = () => {
    const activeWorkspaceCount: number = items.length;
    return (
      <FlexLayout alignItems="center">
        <NewIndicator />
        <Typography className={classes.accordionSummaryText}>
          The system has found{' '}
          <b>
            ({activeWorkspaceCount}) active {activeWorkspaceCount === 1 ? 'workspace' : 'workspaces'}
          </b>{' '}
          for the above titles.{' '}
          <b>
            <u>{expanded ? 'Hide' : 'Show'} details</u>
          </b>
        </Typography>
      </FlexLayout>
    );
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary classes={{ root: classes.accordionSummaryRoot }} expandIcon={<NewArrowDown />}>
          {renderAccordionSummary()}
        </AccordionSummary>
        <AccordionDetails>
          <SearchJoinTable rows={items} workspaceType={workspaceType} role={role} isLoading={isLoadingTable} />
        </AccordionDetails>
      </Accordion>
      {row !== undefined && items[row] && role !== undefined && (
        <Dialog open={open} maxWidth={false} onClose={handleOnClose}>
          <ModalDialogContent
            role={role}
            workspaceDetail={convertWorkspaceDetail(items[row])}
            onCancel={handleOnClose}
            groupId={groupId}
            onJoinWorkspace={onJoinWorkspace}
            matter={matter}
          />
        </Dialog>
      )}
    </>
  );
};

export default memo(ActiveWorkspaceAccordion);
