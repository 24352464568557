import { addDays, addYears, endOfMonth, endOfWeek, isAfter, isBefore, isMonday, isSameDay, isSameMonth, isWeekend, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { getHoliday } from 'src/utils/holiday/holidayCheck';
import { DateCellBaseModel } from './date-cell/models';

function firstWeekDayInMonth(date: Date) {
  const monthStart = startOfMonth(date);
  if (isWeekend(monthStart)) {
    // if monthStart is weekend, get first monday in this month ( next monday )
    return endOfWeek(monthStart, { weekStartsOn: 2 });
  } else {
    // if monthStart is weekday, get last monday in last month ( previous monday )
    return startOfWeek(monthStart, { weekStartsOn: 1 });
  }
}

function lastWeekDayInMonth(date: Date) {
  const monthEnd = endOfMonth(date);
  if (isWeekend(monthEnd)) {
    // if monthEnd is weekend, get last friday in this month ( previous friday )
    return startOfWeek(monthEnd, { weekStartsOn: 5 });
  } else {
    // if monthEnd is weekday, get first friday in last month ( next friday )
    return endOfWeek(monthEnd, { weekStartsOn: 6 });
  }
}

const ONE_YEAR_FROM_TODAY = addYears(new Date(), 1);

function getDateDetail(currentDay: Date, selectedMonth: string, startOfToday: Date, jurisdictionId: JurisdictionsEnum) {
  const dateDetail: DateCellBaseModel = {
    date: currentDay,
    isInMonth: isSameMonth(currentDay, new Date(selectedMonth)),
    isPast: isBefore(currentDay, startOfToday),
    isOverOneYear: isAfter(currentDay, ONE_YEAR_FROM_TODAY),
    isToday: isSameDay(currentDay, startOfToday),
    holidayName: getHoliday(currentDay, jurisdictionId)
  };
  return dateDetail;
}

export function initialiseWeeks(yearAndMonth: string, jurisdictionId: JurisdictionsEnum) {
  const weeksInTable: DateCellBaseModel[][] = [];
  const monthStart = startOfMonth(new Date(yearAndMonth));
  const tableStartDate = firstWeekDayInMonth(monthStart);
  const tableEndDate = lastWeekDayInMonth(monthStart);
  const startOfToday = startOfDay(new Date());
  // Start Date
  let dateIterator = tableStartDate;
  const lastDate = addDays(tableEndDate, 1);
  while (!isSameDay(dateIterator, lastDate)) {
    if (isMonday(dateIterator)) {
      weeksInTable.push([]);
    }
    // Skipping weekends
    if (isWeekend(dateIterator)) {
      dateIterator = addDays(dateIterator, 1);
      continue;
    }
    const dateDetail: DateCellBaseModel = getDateDetail(dateIterator, yearAndMonth, startOfToday, jurisdictionId);
    weeksInTable[weeksInTable.length - 1].push(dateDetail);
    dateIterator = addDays(dateIterator, 1);
  }
  return weeksInTable;
}
