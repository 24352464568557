export interface ConversationDocumentsApiRequest {
  workspaceId: string;
  participantId: string;
}
export interface ConversationDocumentsApiResponse {
  conversations: ConversationDocument[];
}

export interface ConversationDocument {
  conversationId: string;
  isRead: boolean;
  subject: string;
  messages: Array<MessageModel>;
  recipientParticipantIds: Array<string>;
  workspaceId?: string;
}

export enum MessagingSendingStatusEnum {
  Sending = 0,
  Sent = 1,
  Failed = 2
}

export interface MessageModel {
  text: string;
  sentByUserName: string;
  sentBySubscriberName: string;
  sentByParticipantId: string;
  createdDate: string;
  readBy?: Array<{
    id: string;
    readTime: string;
  }>;
  sendingStatus?: MessagingSendingStatusEnum; // This is a flag maintained by UI, not from backend
  id?: string; // id used for resend message
}

export interface UpdatedRealtimeMessageModel {
  ParticipantId: string;
  RecipientParticipantIds: Array<string>;
  ConversationId: string;
}

export interface MessagingDisabledStatus {
  isDisabled: boolean;
  disabledReason?: MessagingDisabledReasonEnum;
}

export enum MessagingDisabledReasonEnum {
  OwnWithdrawn = 0,
  OtherPartyWithdrawn = 1,
  WorkspaceInActive = 2
}

export const MessagingDisabledPrimaryMessageEnumMap: Record<MessagingDisabledReasonEnum, string> = {
  [MessagingDisabledReasonEnum.OwnWithdrawn]: 'You have left the workspace.',
  [MessagingDisabledReasonEnum.OtherPartyWithdrawn]: 'One or more participants have left the workspace.',
  [MessagingDisabledReasonEnum.WorkspaceInActive]: 'This workspace is inactive.'
};

export const MessagingDisabledSecondaryMessageEnumMap: Record<MessagingDisabledReasonEnum, string> = {
  [MessagingDisabledReasonEnum.OwnWithdrawn]: 'This message thread is now closed.',
  [MessagingDisabledReasonEnum.OtherPartyWithdrawn]: 'This message thread is now closed.',
  [MessagingDisabledReasonEnum.WorkspaceInActive]: 'Messaging participants is not available.'
};
