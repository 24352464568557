import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-24/necds';

import { ForeignTypeEnum } from './enums';
import { Form25_2_24_1Model } from './models';

export const VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL = createContextCheck(
  ({ transferee, partyBook }: Form25_2_24_1Model) => partyBook.find(pb => pb.id === transferee.partyBookId)!.partyType === PartyTypeEnum.Individual
);
export const VISIBILITY_CHECK_TRANSFEREE_IS_ORGANISATION = createContextCheck(
  ({ transferee, partyBook }: Form25_2_24_1Model) => partyBook.find(pb => pb.id === transferee.partyBookId)!.partyType === PartyTypeEnum.Organisation
);
export const VISIBILITY_CHECK_FOREIGN_SHAREHOLDER = createContextCheck(({ foreignType }: Form25_2_24_1Model) => Boolean(foreignType));

export const VISIBILITY_CHECK_FOREIGN_SHAREHOLDER_PERCENT = createContextCheck(({ foreignType, foreignShareholders }: Form25_2_24_1Model) => {
  return (foreignType === ForeignTypeEnum.WhollyForeignCompany && foreignShareholders.length > 1) || foreignType === ForeignTypeEnum.PartlyForeignCompany;
});
