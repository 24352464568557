import * as React from 'react';

import Typography from '@mui/material/Typography';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { useStyles } from './styles';

interface OwnProps {
  open: boolean;
  onClose?: (confirmed?: boolean) => void;
}

export default React.memo(function ConfirmNameChangeDialog(props: OwnProps) {
  const classes = useStyles();
  const { open, onClose } = props;
  return (
    <ConfirmationDialog open={open} title="Name Change Request" okButtonText="Save Changes" onClose={onClose}>
      <Typography className={classes.desc}>By changing this users name all inflight documents and financials that are already signed by the user will be unsigned.</Typography>
      <Typography className={classes.heading}>This action cannot be undone.</Typography>
    </ConfirmationDialog>
  );
});
