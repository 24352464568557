import { memo } from 'react';

import classNames from 'classnames';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import Logger from '@sympli/ui-logger';

import TabNavLink from 'src/components/tab-nav-link';
import { useStyles } from './styles';

export interface NavTabsProps<T extends string> {
  tabs: T[];
  selectedTab: T;
  resolveLabel(selectedTab: T): string;
  resolveHref?(selectedTab: T): string;
  onChange(newTab: T): void;
}

function NavTabs<T extends string>({
  //
  tabs,
  selectedTab,
  onChange,
  resolveLabel,
  resolveHref
}: NavTabsProps<T>) {
  const classes = useStyles();

  return (
    <FlexLayout justifyContent="center" alignItems="flex-start">
      <Tabs value={selectedTab} indicatorColor="primary" textColor="inherit" classes={{ root: classes.root }}>
        {tabs.map(tabItem => {
          const label = resolveLabel(tabItem);
          return (
            <Tab //
              key={tabItem}
              label={label}
              value={tabItem}
              className={classNames(classes.tab, selectedTab === tabItem ? classes.tabSelected : classes.tabNotSelected)}
              component={TabNavLink}
              href={resolveHref?.(tabItem)}
              onClick={() => {
                onChange(tabItem);
                Logger.setActionText(label);
              }}
            />
          );
        })}
      </Tabs>
    </FlexLayout>
  );
}

export default memo(NavTabs);
