import React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import PhoneNumberField from '@sympli/ui-framework/components/formik/phone-number-field';

import { NoticeOfAcquisitionDocumentModel, RepresentativeDetailModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
const rootFieldName = modelKey<NoticeOfAcquisitionDocumentModel>();

interface Props {
  name: string;
}

function SectionCertification({ name }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const fieldName = createModelKeyAppender<RepresentativeDetailModel>(name);

  return (
    <div data-name={name} className={classes.root}>
      <FormGroup //
        title="Transferee solicitor or agent details"
        fieldAreaDirection="column"
      >
        <Field //
          name={fieldName('name')}
          component={InputField}
          disabled={disabled}
          label="Name"
          fullWidth
        />
        <Field //
          name={fieldName('contactPhoneNumber')}
          component={PhoneNumberField}
          disabled={disabled}
          label="Phone"
        />
        <Field //
          name={fieldName('emailAddress')}
          component={InputField}
          disabled={disabled}
          label="Email"
          fullWidth
        />
        <TextOnly name={rootFieldName('matter')} label="Reference">
          {formikProps.values.matter}
        </TextOnly>
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionCertification);
