import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    date: {
      padding: '10px 0px'
    },
    loadButton: {
      marginTop: 16,
      marginBottom: 32
    },
    list: {
      borderLeft: '2px solid #18cdb6',
      marginLeft: '20px',
      padding: '8px 0px 20px 20px'
    }
  }),
  {
    name: 'TabLogsContainer'
  }
);
