import { LogApiResponse } from '../actions/logs';

export interface LogModel extends LogApiResponse {
  template: string;
}
export interface GroupedLogItem {
  formattedDate: string;
  logs: LogModel[];
}

export enum SubscriberActivityLogTypeEnum {
  CreateNewGroup = 1,
  DeleteGroup = 2,
  UpdateGroupSettings = 3,
  UpdateGroupDocumentWorkflow = 4,
  UpdateGroupDistributionWorkflow = 5,
  UpdateGroupAddUser = 6,
  CreateNewUser = 7,
  UpdateUserSuspension = 8,
  UpdateUserReActivation = 9,
  UpdateUserDetails = 10,
  UpdateUserPermissions = 11,
  UpdateUserRemoveLinkedDevice = 13,
  UpdateSubscriberAddProfileImage = 14,
  UpdateSubscriberRemoveProfileImage = 15,
  UpdateSubscriberPreferences = 20,
  UpdateSubscriberDefaultFieldPopulation = 21,
  UpdateUserName = 28,
  UpdateGroupLodgementSettings = 29,
  TrustAccountRequestCreated = 30,
  TrustAccountRequestSubmitted = 31,
  TrustAccountRequestStatusUpdated = 32,
  RegisteredTrustAccountChangeRequested = 33,
  RegisteredTrustAccountDetailsUpdated = 34,
  UpdateUserReportPermissions = 35,
  AutoJustificationAdded = 36,
  AutoJustificationRemoved = 37,
  AutoJustificationModified = 38,
  UserDeleted = 39,
  UserEmailUpdated = 40,
  FinancialAccountAdded = 41,
  FinancialAccountUpdated = 42,
  FinancialAccountDeleted = 43,
  UpdateSubscriberPreferencesWorkspaces = 44,
  UpdateSubscriberPreferencesNotifications = 45,
  UpdateSubscriberSigningRules = 46
}

export enum SubscriberActivityLogLinkEnum {
  GroupDetailLink = 1,
  UserDetailLink = 2,
  DefaultFieldPopulationJurisdictionLink = 3,
  LinkedSubscriberDetailLink = 4,
  LoanAccountSignerDetailLink = 5,
  LoanAccountSignerUserLink = 6,
  LinkedSubscriberGroupDetailLink = 7,
  AutoJustificationDetailLink = 8,
  FinancialAccountsLink = 9
}
