import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-17/necds';

import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG2 } from '../../config';
import { DeceasedProprietorModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';

interface PartyBookValidationContext {
  deceasedProprietors: DeceasedProprietorModel[];
  organizationPartyBookIds: string[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: TransmissionApplicationWithoutDuty2_21_1Model): PartyBookValidationContext => {
  return {
    deceasedProprietors: parentContext.deceasedProprietorGroups.flatMap(deceased => deceased.deceasedProprietors),
    organizationPartyBookIds: parentContext.partyBook.filter(p => p.partyType === PartyTypeEnum.Organisation).flatMap(p => p.id)
  };
};

const checkShouldValidateItem = ({ deceasedProprietors, organizationPartyBookIds }: PartyBookValidationContext, partyIdForCheck: PartyModel<NswNameChange>['id']) => {
  if (organizationPartyBookIds?.some(id => id === partyIdForCheck)) {
    return false;
  }
  const selecteGroups = deceasedProprietors.reduce((groupArray: DeceasedProprietorModel[], group: DeceasedProprietorModel) => {
    if (group.isAffectedProprietor) {
      groupArray.push(group);
    }
    return groupArray;
  }, []);
  return selecteGroups.some(proprietor => proprietor.isAffectedProprietor && proprietor.partyBookId === partyIdForCheck) || false;
};

export const yupPartyItem = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(PARTY_FORM_WITH_NAME_CHANGE_CONFIG2);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
