import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    divider: {
      width: '100%',
      borderTop: `solid 1px ${theme.palette.divider}`
    },
    addNotApplicableInstrument: {
      borderTop: `solid 1px ${theme.palette.divider} !important`,
      backgroundColor: `${theme.palette.action.disabledBackground} !important`
    }
  }),
  {
    name: 'Prohibition'
  }
);
