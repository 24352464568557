import {
  RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  ReceivingTenancyComponentLabelConfig,
  ReceivingTenancyTotalShareConfig
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import {
  RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG,
  RelinquishingTenancyComponentLabelConfig
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';

export const NSW_RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG: RelinquishingTenancyComponentLabelConfig = {
  ...RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG
};

export const NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG: ReceivingTenancyTotalShareConfig = {
  hasLimit: true,
  totalShareLimit: 100000
};

export const NSW_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG
};
