import React from 'react';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { SchedulerFormikModel } from '../../models';
import ReportSchedulerDialog from './ReportSchedulerDialog';

interface Props {
  isOpen: boolean;
  onClose: (value?: boolean) => void;
  initialValues: SchedulerFormikModel;
  onPostSubmit?: (args: FormikPostSubmitArgs<SchedulerFormikModel>) => void;
  filterData?: string;
}

export const defaultValues: SchedulerFormikModel = {
  name: '',
  scheduledFrequency: null,
  interval: 1,
  daysOfWeek: [],
  reportSendTime: '',
  filterData: '',
  reportType: ReportTypeEnum.UserPermissionReport
};

function ReportSchedulerDialogContainer({ isOpen, onClose, initialValues, onPostSubmit, filterData }: Props) {
  const userEmail = useProfile().data!.email;

  return (
    <ReportSchedulerDialog
      initialValues={filterData ? { ...initialValues, filterData } : { ...initialValues }}
      onClose={onClose}
      isOpen={isOpen}
      maxNameLength={50}
      userEmail={userEmail}
      onPostSubmit={onPostSubmit}
    />
  );
}

export default React.memo(ReportSchedulerDialogContainer);
