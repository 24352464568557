import { WorkspaceTransactionTypeEnum } from '@sympli/api-gateway/enums';

import { SelectedSettlementDistributionsTypeEnum } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';

export function resolveSelectedSettlementDistributionsType(pathname: string): SelectedSettlementDistributionsTypeEnum | undefined {
  const route = pathname.split('/').pop();
  switch (route) {
    case 'directions':
      return SelectedSettlementDistributionsTypeEnum.Distributions;
    case 'stamp-duty':
      return SelectedSettlementDistributionsTypeEnum.StampDuty;
    case 'trust-account-authorisation-records':
      return SelectedSettlementDistributionsTypeEnum.TrustAccountAuthorisationRecords;
    default:
      return;
  }
}

export function getTransactionTypeString(type: WorkspaceTransactionTypeEnum): string {
  switch (type) {
    case WorkspaceTransactionTypeEnum.Refinance:
      return 'Refinance';
    case WorkspaceTransactionTypeEnum.Transfer:
      return 'Transfer';
    case WorkspaceTransactionTypeEnum.Other:
      return 'Other';
    default:
      return '';
  }
}
