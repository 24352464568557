import Logger, { InvalidDataError } from '@sympli/ui-logger';

export enum WarningMessageEnum {
  NoteReassign
}

export function getWarningMessage(message: WarningMessageEnum) {
  switch (message) {
    case WarningMessageEnum.NoteReassign:
      return (
        <>
          <b>Note:</b> You can only reassign workspaces from one page at the time, your previous selections have been cleared
        </>
      );

    default:
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Invalid message format', WarningMessageEnum[message]), scope);
      return 'Warning';
  }
}
