import * as React from 'react';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { ApiStatus } from 'src/utils/http';

export interface Props {
  status: ApiStatus;
}

function AsyncContentWrapper({ status, children }: React.PropsWithChildren<Props>) {
  if (status === 'pending' || status === 'idle') {
    return <BlockLoader />;
  }

  if (status === 'rejected') {
    return null;
  }

  return children;
}

export default React.memo(AsyncContentWrapper);
