import React from 'react';

import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconPencil } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface ScheduledReportHistoryHeaderProps {
  reportName: string;
  onEditClick(): void;
}

function ScheduledReportHistoryHeader({
  //
  reportName,
  onEditClick
}: ScheduledReportHistoryHeaderProps) {
  const classes = useStyles();

  return (
    <FlexLayout justifyContent="space-between" alignItems="center">
      <div className={classes.selectReports}>
        <NavLink title="Reports" to="/reports">
          <Typography variant="h1">Reports</Typography>
        </NavLink>
        <span className={classes.separator}>/</span>
        <Typography variant="h1" className={classes.reportName}>
          {reportName}
        </Typography>
      </div>

      <SympliButton variant="contained" className={classes.button} color="primary" onClick={onEditClick}>
        <IconPencil className={classes.iconPencil} /> Edit schedule
      </SympliButton>
    </FlexLayout>
  );
}

export default React.memo(ScheduledReportHistoryHeader);
