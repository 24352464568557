import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    divider: {
      width: '100%',
      borderTop: `solid 1px ${theme.palette.divider}`
    },
    addParty: {
      border: 'none',
      backgroundColor: 'transparent',
      paddingLeft: 0,
      '& button': {
        paddingLeft: 0
      },
      '& span:first-of-type': {
        fontWeight: 'bold'
      }
    },
    receivingParty: {
      paddingTop: 5,
      paddingBottom: 5,
      '& *': {
        fontSize: 13,
        fontWeight: 'bold'
      }
    }
  }),
  {
    name: 'ApplicableInstrument'
  }
);
