import React from 'react';

import pluralize from 'pluralize';

import LodgementStatusBadge from 'src/containers/documents/components/lodgement-status-badge';
import { LinkedWorkspaceRowDetailModel } from '../../models';
import DetailLine from '../detail-line';
import DocumentList from '../document-list';
import ParticipantList from '../participant-list';
import SympliId from '../sympli-id';
import TitleList from '../title-list';
import { useStyles } from './styles';

interface Props extends LinkedWorkspaceRowDetailModel {
  currentWorkspaceId: string;
  currentParticipantId: string;
  currentSubscriberId: string;
  // handlers
  onUnlinkWorkspaceClick(workspaceId: string): void;
}

function Detail({
  // data
  currentWorkspaceId,
  currentParticipantId,
  titles,
  participants,
  lodgementCaseStatusId,
  workspaceStatusId,
  documents,
  workspaceId,
  sympliId,
  currentSubscriberId,
  // handlers
  onUnlinkWorkspaceClick
}: Props) {
  const classes = useStyles();

  return (
    <div role="table" className={classes.root}>
      <DetailLine title={pluralize('Title', titles.length)}>
        <TitleList titles={titles} />
      </DetailLine>
      <DetailLine title={pluralize('Participant', participants.length)}>
        <ParticipantList //
          workspaceId={workspaceId}
          participants={participants}
          currentWorkspaceId={currentWorkspaceId}
          currentSubscriberId={currentSubscriberId}
        />
      </DetailLine>
      <DetailLine title="Lodgement verification" verticalAlign="middle">
        <LodgementStatusBadge //
          lodgementCaseStatus={lodgementCaseStatusId}
          workspaceStatus={workspaceStatusId}
          aria-label="Lodgement verification status"
        />
      </DetailLine>
      {documents.length > 0 && (
        <DetailLine title={pluralize('Document', documents.length)}>
          <DocumentList //
            documents={documents}
            participants={participants}
            currentParticipantId={currentParticipantId}
          />
        </DetailLine>
      )}
      <DetailLine title="Sympli ID" verticalAlign="middle">
        <SympliId //
          workspaceId={workspaceId}
          onUnlinkWorkspaceClick={onUnlinkWorkspaceClick}
        >
          {sympliId}
        </SympliId>
      </DetailLine>
    </div>
  );
}

export default React.memo(Detail);
