import React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface Props {
  icon: React.ReactNode;
  headerText: string;
  message: string | React.ReactNode;
  children?: React.ReactNode;
}

const HelpPageCard = ({ icon, headerText, message, children }: Props) => {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="column" alignItems="center" fullWidth className={classes.container}>
      {icon}
      <Typography className={classes.header} variant="subtitle1">
        {headerText}
      </Typography>
      {React.isValidElement(message) ? message : <Typography className={classes.message}>{message}</Typography>}
      {children && <div className={classes.body}>{children}</div>}
    </FlexLayout>
  );
};

export default HelpPageCard;
