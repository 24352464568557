import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    radioButtonLabel: {
      paddingTop: 6
    },
    radioLabel: {
      color: 'inherit',
      fontSize: 14
    },
    radioFormControlLabel: {
      paddingBottom: 5,
      marginRight: 0
    },
    radioFormLabel: {
      display: 'flex',
      alignItems: 'start',
      paddingBottom: 5,
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginRight: 0,
      marginBottom: 5
    },
    radioFormControl: {
      border: 'none',
      background: 'none',
      width: '100%'
    },
    radioFieldLabel: {
      fontSize: 14
    }
  }),
  {
    name: 'IssuingInstructions'
  }
);
