import makeStyles from '@mui/styles/makeStyles';

export const useSharedStyles = makeStyles(
  () => ({
    table: {
      minWidth: 574
    },
    row: {
      flex: 'auto',
      fontSize: 13,
      '& > div': {
        flexGrow: 0,
        flexShrink: 0
      },
      '& > $columnWorkspaceType': {
        flexGrow: 1,
        flexShrink: 1
      },
      '& > div + div': {
        marginLeft: 16
      }
    },
    item: {
      '& + &': {
        marginTop: 4
      }
    },
    columnOrder: {
      width: 44
    },
    columnJurisdiction: {
      width: 95
    },
    columnWorkspaceType: {
      minWidth: 100
    },
    columnParticipants: {
      width: 110
    },
    columnStatus: {
      width: 115
    },
    textAlignRight: {
      textAlign: 'right'
    }
  }),
  {
    name: 'TabLinkedWorkspaces'
  }
);
