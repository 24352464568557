import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      paddingLeft: 18,
      width: 'auto'
    },
    paymentTypeCol: {
      width: '10%',
      paddingLeft: 20,
      paddingRight: 9,
      position: 'relative'
    },
    accountDetailCol: {
      width: '75%'
    },
    accountDetailWithBreakdownsCol: {
      width: '45%'
    },
    feeBreakdownCol: {
      width: '30%'
    },
    amountCol: {
      width: '15%',
      textAlign: 'center'
    },
    recordRow: {
      position: 'relative'
    },
    marginRight: {
      marginRight: 10
    },
    marginBottom: {
      marginBottom: 15
    },
    largeField: {
      width: 250
    },
    smallField: {
      width: 120
    },
    growField: {
      flexGrow: 1
    },
    avatar: {
      paddingTop: 5,
      paddingBottom: 5,
      marginLeft: 18
    },
    // account detail
    bold: {
      fontWeight: 'bold'
    },
    normal: {
      fontWeight: 'normal'
    },
    italic: {
      fontStyle: 'italic'
    },
    editButton: {
      paddingTop: 0,
      paddingBottom: 0,
      '& svg': {
        marginRight: 2
      },
      margin: '0px 10px'
    },
    editIcon: {
      marginRight: 2
    },
    // purchaserPaysVendorContainer styles
    purchaserPaysVendorContainer: {
      background: '#f2f2f2',
      marginBottom: 10,
      fontSize: 14,
      '& .row': {
        padding: 13
      },
      '& .firstRow': {
        borderBottom: '1px solid #D0D0D0'
      },
      '& .amountStyle': {
        marginLeft: 5
      },
      '& .inputPurchaserPaysVendor': {
        width: 120,
        height: 34,
        marginBottom: 0
      },
      '& input:disabled': {
        background: 'rgb(235, 235, 228);'
      }
    },
    // TODO need some style fix for scrollbar
    // editor styles
    editorBox: {
      marginTop: 20,
      marginBottom: 8,
      padding: '20px 20px 10px 20px',
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.grey[50],
      position: 'relative',
      // arrow for the box
      '&:before, &:after': {
        position: 'absolute',
        left: 38,
        border: '16px solid transparent',
        content: '" "'
      },
      '&:before': {
        top: -32,
        borderBottomColor: theme.palette.divider
      },
      '&:after': {
        top: -30,
        borderBottomColor: theme.palette.grey[50]
      }
    },
    saveButton: {
      display: 'flex',
      // * ideally same size as small field
      width: 120,
      // to solve the issue that loader expands width
      whiteSpace: 'nowrap',
      marginLeft: 'auto'
    },
    // general
    boxContainer: {
      marginTop: 32,
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 8
    },
    fullWidth: {
      width: '100%'
    },
    halfWidth: {
      width: '50%'
    },
    divider: {
      marginTop: 5,
      marginBottom: 5
    },
    formLabel: {
      fontSize: 13,
      fontWeight: 'bold',
      paddingTop: 16
    },
    formContainer: {
      padding: '20px 60px'
    },
    category: {
      width: '50%'
    },
    categoryName: {
      width: 'calc(50% - 10px)',
      marginLeft: 10
    },
    labelCenter: {
      '& label': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },
    descField: {
      width: 290
    },
    autoBalanceTitle: {
      fontWeight: 'bold',
      paddingBottom: 4,
      marginBottom: 10,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    autoBalanceItemName: {
      fontWeight: 'bold'
    },
    autoBalanceFirstField: {
      width: '20%'
    },
    autoBalanceSecField: {
      width: '50%'
    },
    autoBalanceShortfallContainer: {
      alignItems: 'center',
      marginBottom: 5,
      paddingBottom: 5,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    autoBalanceSurplusContainer: {
      alignItems: 'center',
      marginBottom: 10
    },
    shortfallAmount: {
      marginBottom: 0
    },
    acceptSurplus: {
      marginTop: 0,
      paddingLeft: 0,
      marginBottom: 0
    },
    leftTextError: {
      top: 0,
      position: 'absolute',
      right: '100%',
      marginTop: 4,
      marginRight: 4,
      width: 132
    },
    explanationDesc: {
      color: theme.palette.action.disabled
    },
    formGroupFooter: {
      backgroundColor: 'none',
      border: 'none',
      paddingLeft: 0
    },
    optionIcon: {
      '& path': {
        fill: theme.palette.error.main
      },
      marginRight: 5,
      width: 38,
      height: 36
    },
    title: {
      color: colors.NEUTRAL_1000,
      fontFamily: 'volkswagen_serial-medium',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '40px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
