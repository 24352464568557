import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272 } from 'src/components/layout/constants';

const styles = (theme: Theme) =>
  createStyles({
    inputContainer: {
      width: WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272, // ? change
      padding: '31px 40px 0px 40px',
      backgroundColor: theme.palette.grey[50]
    },
    selectedTime: {
      marginBottom: 30
    },
    displayDateTimeContainer: {
      paddingBottom: 20
    },
    dateTime: {
      fontWeight: 'bold',
      fontSize: '18px'
    },
    proposedDateTime: {
      color: '#F58923'
    },
    helperTextError: {
      width: 250
    },
    box: {
      marginBottom: 5
    },
    tooltipIconInfo: {
      marginLeft: 5
    },
    iconWarning: {
      color: theme.palette.secondary.main,
      marginRight: 4,
      verticalAlign: 'middle'
    },
    rescindReasonBox: {
      marginTop: 5
    }
  });

export type ClassKeys = typeof styles;

export default styles;
