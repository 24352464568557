import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface Props {
  className?: string;
}

function Currency({ children, className }: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <FlexLayout justifyContent="flex-end" className={classNames(classes.currency, className)}>
      <Typography>{children}</Typography>
    </FlexLayout>
  );
}

export default React.memo(Currency);
