import _uniqBy from 'lodash-es/uniqBy';

import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { arraysIntersectionsBy } from '@sympli-mfe/document-forms-framework/utils';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { ApiDischargeOfMortgage2_24_0Model, DischargeOfMortgage2_24_0Model, DocumentBaseTitleReferenceModel, MortgageePartyModel, MortgageModel } from './models';

const createMortgageeKey = (mortgagee: MortgageePartyModel): string =>
  `${mortgagee.partyBookId}:${mortgagee.partyCapacity?.partyCapacity}:${mortgagee.partyCapacity?.partyCapacityDetail}`;

export const filterTitleReferences = <T extends Pick<ApiDischargeOfMortgage2_24_0Model, 'mortgages'>>(values: T): DocumentBaseTitleReferenceModel[] => {
  const titleReferences = values.mortgages?.filter(r => r.isSelected).map(x => x.titleReferences);
  const result = arraysIntersectionsBy<DocumentBaseTitleReferenceModel>(titleReferences, 'reference');
  return result;
};

export const filterMortgagees = <T extends Pick<ApiDischargeOfMortgage2_24_0Model, 'mortgages'>>(values: T): MortgageePartyModel[] => {
  const mortgagees = values.mortgages
    ?.filter(mortgages => mortgages.isSelected)
    .map(mortgages => mortgages.mortgagees)
    .flat();

  return _uniqBy(mortgagees, createMortgageeKey);
};

export const isMortgageeMismatch = (values: DischargeOfMortgage2_24_0Model): boolean => {
  const selectedMortgages = values.mortgages.filter(s => s.isSelected);
  if (selectedMortgages.length <= 1) return false;
  const createKey = (e: MortgageModel): string =>
    e.mortgagees
      .sort((a, b) => (a.partyBookId > b.partyBookId ? 1 : -1))
      .map(createMortgageeKey)
      .join('|');

  const keyToCompare = createKey(selectedMortgages[0]);
  return selectedMortgages.slice(1).some(e => keyToCompare !== createKey(e));
};

export const getOtherDmDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.DischargeOfMortgage && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherDms = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as DischargeOfMortgage2_24_0Model;
    return data.mortgages.filter(mortgage => mortgage.isSelected).flatMap(mortgage => mortgage.dealingNumber);
  });
};
