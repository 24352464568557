import * as React from 'react';

import EyeToggleButton from './EyeToggleButton';

export interface EyeToggleButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  hideText?: string;
  showText?: string;
}

function EyeToggleButtonContainer(props: EyeToggleButtonProps) {
  return (
    <EyeToggleButton //
      {...props}
    />
  );
}

export default React.memo(EyeToggleButtonContainer);
