import { UserLevelPermissionEnum } from '@sympli/api-gateway/enums';

import { AuthProfileState, useProfile } from 'src/containers/shared/auth/reducers';

export default function useUserPermission(requiredPermission: UserLevelPermissionEnum): boolean {
  const userProfileState: AuthProfileState = useProfile();

  if (userProfileState.status !== 'resolved') {
    return false;
  }

  const isUserPermissionAllowed = userProfileState.data!.userPermissions.includes(requiredPermission);

  return isUserPermissionAllowed;
}
