import { $noneOf } from '@sympli-mfe/document-forms-framework/utils';

import { ClaimCategoryEnum, EstateOfInterestClaimedEnum } from '../../enums';
import { Claim } from '../../models';

/**
 * what we found in schema:	[{"required":{"claimDate":{"$not":null}},"message":null,"onlyIf":{"claimCategory":{"$noneOf":[0,1,2,3,5,4,9,10,16,17,25]}}}]
 */
export const REQUIRED_CHECK_CLAIM_DATE = (values: Claim): boolean => $noneOf(values.claimCategory, [0, 1, 2, 3, 5, 4, 9, 10, 16, 17, 25]);

/*
 path:
 - claimDetails.claimDate
 schema version:
[
  {
    "claimCategory": {
      "$not": null
    },
    "estateOrInterestClaimed": {
      "$not": 13
    }
  }
]
*/
export const VISIBILITY_CHECK_CLAIM_DATE = (values: Claim): boolean =>
  values.claimCategory !== null && values.estateOrInterestClaimed !== EstateOfInterestClaimedEnum.RegisteredProprietor;

/**
 * what we found in schema:
 * [{"required":{"detailsSupportingTheClaim":{"$not":""}},"message":null,"onlyIf":{"claimCategory":{"$noneOf":[7,18]}}},{"required":{"detailsSupportingTheClaim":{"$not":""}},"message":null,"onlyIf":{"estateOrInterestClaimed":13}}]
 */
export const REQUIRED_CHECK_DETAILS_SUPPORTING_THE_CLAIM = (claimDetails: Claim): boolean => {
  const { claimCategory, estateOrInterestClaimed } = claimDetails!;
  return (
    estateOrInterestClaimed === EstateOfInterestClaimedEnum.RegisteredProprietor ||
    ![null, undefined, ClaimCategoryEnum.ContractForSaleOfLand, ClaimCategoryEnum.Transfer].includes(claimCategory)
  );
};

/**
 * Not found in schema
 */
export const VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM = (claimDetails: Claim): boolean => {
  const estateOrInterestClaimed = claimDetails.estateOrInterestClaimed ?? null;
  return estateOrInterestClaimed !== null;
};
