import * as React from 'react';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { LoanAdvanceCategoryEnum, ParticipantStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsOverviewApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { currency } from '@sympli/ui-framework/utils/formatters';

import DirectionHeaderBox from 'src/containers/workspace/financial/directions/components/direction-header-box';
import { distributionsParticipantOrderedListSelector } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/selectors';
import { accountDescriptionMapping } from 'src/containers/workspace/financial/directions/helpers';
import { SourceFundModel } from 'src/containers/workspace/financial/directions/models';
import { SectionTypeEnum } from '../../../directions/components/settlement-directions-list/models';
import ParticipantCollapseBoxContainer from '../participant-collapse-box';
import { GridHead, LineItem, TotalAmount } from '../styled-components';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  currentParticipantId: string;
  allDirectionsDetail: WorkspaceDirectionsOverviewApiResponse;
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  openAll: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;
class ParticipantSourceFunds extends React.PureComponent<Props> {
  render() {
    const {
      allDirectionsDetail: { distributionsParticipants, totalSourceFundsAndLoanAdvance }
    } = this.props;
    const acceptedParticipants = distributionsParticipants.filter(p => p.participant.participantStatus.id === ParticipantStatusEnum.Accepted);
    const distributionsParticipantOrderedList = distributionsParticipantOrderedListSelector(acceptedParticipants);
    return (
      <DirectionHeaderBox header="Source funds">
        <GridHead />
        {distributionsParticipantOrderedList.map(this.renderParticipant)}
        <TotalAmount label="Total source funds">{currency(totalSourceFundsAndLoanAdvance)}</TotalAmount>
      </DirectionHeaderBox>
    );
  }

  private renderParticipant = (distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number], idx: number) => {
    const { workspaceParticipants, currentParticipantId, openAll } = this.props;
    const subTotalAmount = distributionsParticipant.subTotalSourceFundsAndLoanAdvance;
    const hasItems = !!distributionsParticipant.loanAdvance || !!distributionsParticipant.sourceFunds.length;
    return (
      <ParticipantCollapseBoxContainer
        key={idx}
        openAll={openAll}
        currentParticipantId={currentParticipantId}
        distributionsParticipant={distributionsParticipant}
        workspaceParticipants={workspaceParticipants}
        subTotalAmount={subTotalAmount}
        hasItems={hasItems}
        sectionType={SectionTypeEnum.Sources}
        renderCollapseDetail={this.renderSourceFundsList}
      />
    );
  };

  private renderSourceFundsList = (distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]) => {
    const { sourceFunds } = distributionsParticipant;

    if (!sourceFunds) {
      return null;
    }
    return (
      <>
        {this.renderLoanAdvanceDetail(distributionsParticipant)}
        {sourceFunds.map((sourceFund, idx) => {
          const isLast = idx === sourceFunds.length - 1;
          return (
            <LineItem amount={currency(sourceFund.amount, '')} withDivider={!isLast} key={idx} showBreakdownsCol={false}>
              {this.renderAccountDetailCol(sourceFund)}
            </LineItem>
          );
        })}
      </>
    );
  };

  // TODO extract and merge with loan advance renderDetail
  private renderLoanAdvanceDetail = (distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]) => {
    const { classes } = this.props;
    const { loanAdvance, sourceFunds, autoBalancedLoanAdvanceAmount } = distributionsParticipant;
    if (!loanAdvance) {
      return null;
    }
    const { bankDetails, amount, categoryReferenceDescription, category, isAutoBalancingSurplusForIMAllowed } = loanAdvance;
    const { accountName, accountDescription } = bankDetails;
    const withDivider = sourceFunds && sourceFunds.length > 0;
    const bankAccountDescription = accountDescriptionMapping(bankDetails!);
    const loanAdvanceAvailableEnabled = category === LoanAdvanceCategoryEnum.Range;
    const loanAdvanceAmount = loanAdvanceAvailableEnabled && autoBalancedLoanAdvanceAmount ? autoBalancedLoanAdvanceAmount : amount;
    return (
      <LineItem amount={currency(loanAdvanceAmount, '')} withDivider={withDivider} showBreakdownsCol={false}>
        <Typography className={classes.bold}>{accountDescription || accountName}</Typography>
        {/* WEB-7478: remove double up matter reference. The categoryReferenceDescription already contains matter reference in it */}
        <Typography>{categoryReferenceDescription}</Typography>
        <Typography className={classes.description}>{bankAccountDescription}</Typography>
        {isAutoBalancingSurplusForIMAllowed && <Typography className={classes.description}>Auto balance enabled. Loan advance can be up to ${currency(amount, '')}</Typography>}
      </LineItem>
    );
  };

  // TODO extract and merge with sourcefund renderDetail
  private renderAccountDetailCol(details: SourceFundModel) {
    const { classes } = this.props;
    const { category, categoryOther, reference, bankDetails } = details;
    if (!bankDetails) {
      return null;
    }
    const { accountName, accountDescription } = bankDetails;
    const description = accountDescriptionMapping(bankDetails);
    return (
      <React.Fragment>
        <Typography className={classes.bold}>{accountDescription || accountName}</Typography>
        <Typography>
          {category === 'Other' ? categoryOther : category}
          {reference && ` (${reference})`}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </React.Fragment>
    );
  }
}

const styledComponent = withStyles(styles)(ParticipantSourceFunds);

export default styledComponent;
