import { nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import {
  appendShareQuantitiesToReceivingPartyGroups,
  convertShareQuantitiesToFractions
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving/components/transferee-group-array';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { convertPartiesToFormModel2212, createFormMortgagors, generateMortgagors, getPartyAdder } from './helper';
import { IS_REQUIRED_CHECK_DOCUMENT_REFERENCE } from './isRequiredChecks';
import { ApiMortgage2_21_2Model, Mortgage2_21_2Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiMortgage2_21_2Model, context: IConverterContext): Mortgage2_21_2Model {
  // Implement any necessary conversion from API to form values
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);

  let formModel: Mortgage2_21_2Model = {
    ...apiModel,
    mortgagors: createFormMortgagors(apiModel.mortgagors),
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel2212(apiModel.partyBook, risInvolvedPartyIds, getPartyAdder(context.participants)),
    mortgagees: {
      ...apiModel.mortgagees,
      receivingProprietorGroups: appendShareQuantitiesToReceivingPartyGroups(apiModel.mortgagees.receivingProprietorGroups)
    }
  };

  if (apiModel.needToGeneratePartyBook) {
    formModel = generateMortgagors(formModel, getPartyAdder(context.participants));
  }
  formModel = applyDefaultMap(formModel, fallbackMap);

  // WEB-18683: The term number was not filled with the default one.
  // The following logic will check if the standard term dealing number is required or not.
  // If the user has provided one, or it provided the additional term number or uploaded documents, the standard term dealing number is not required.
  // It will fill the standard dealing term number with the default one in the user's setting only when it is empty and required.
  // The empty check is actually a safe guard just in case the require check failed to check the emptiness and overwrite the value already filled by the user.
  const termsAndConditions = formModel.termsAndConditions;
  const firstStandardTermsDealingNumber = termsAndConditions.standardTermsDealingNumbers[0];
  const originalTermNumber = firstStandardTermsDealingNumber.termNumber;
  const additionalAttachments = formModel.additionalAttachments;
  const isRequired = IS_REQUIRED_CHECK_DOCUMENT_REFERENCE(firstStandardTermsDealingNumber, termsAndConditions.additionalCovenants, additionalAttachments);

  if (isRequired && !originalTermNumber) {
    firstStandardTermsDealingNumber.termNumber = termsAndConditions.preRegisteredStandardTermsDealingNumbers?.[0] || originalTermNumber;
  }

  return formModel;
}

function convertFromFormToApiModel(formValues: Mortgage2_21_2Model, originalApiModel: ApiMortgage2_21_2Model): ApiMortgage2_21_2Model {
  const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook,
    partyBookApi,
    mortgagees,
    mortgagors,
    ...rest
  } = formModel;

  PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);

  const result: ApiMortgage2_21_2Model = {
    ...rest,
    mortgagors: mortgagors.proprietorGroups.flatMap(pg => pg.parties),
    needToGeneratePartyBook: false,
    partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
    mortgagees: {
      ...formModel.mortgagees,
      receivingProprietorGroups: convertShareQuantitiesToFractions(formModel.mortgagees.receivingProprietorGroups)
    },
    attachments: formModel.additionalAttachments
  };

  return result;
}

class Converter implements IConverter<Mortgage2_21_2Model, ApiMortgage2_21_2Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
