import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    outerGutters: {
      marginLeft: -16,
      marginRight: -16,

      '& > $listItem': {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    listItem: {
      // leave here to allow override
    },
    selected: {
      background: theme.palette.common.white
    }
  });

export type ClassKeys = typeof styles;

export default styles;
