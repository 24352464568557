import React from 'react';

import TopPanel from 'src/components/layout/v2/TopPanel';

function NonDashboardWrapper({ children }: React.PropsWithChildren<unknown>) {
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .container */}
      {/* .header */}
      <TopPanel />
      <div className="grid grid-cols-[1fr] grid-rows-[1fr] overflow-hidden">
        {/* .body */}

        <div className="overflow-y-auto">
          {/* .scrollable-content */}
          {/* <div className="h-[128px] bg-[#EBFBFA]">
              <div className="mx-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
                <div className="flex h-[128px] shrink-0 flex-row items-center justify-between">
                  <div>
                    <Typography variant="h1">Reports</Typography>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="mx-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(NonDashboardWrapper);
