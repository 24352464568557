import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { EvidenceTypeValueEnum } from './enums';
import { EvidenceModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].evidences[*].evidenceDocumentReference

 schema version:
[
  {
    "evidenceTypeValue": 12
  },
  {
    "evidenceTypeValue": 9
  },
  {
    "evidenceTypeValue": 5
  },
  {
    "evidenceTypeValue": 4
  },
  {
    "evidenceTypeValue": 0
  }
]
*/
export const IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE = createContextCheck(
  (context: EvidenceModel): boolean =>
    context.evidenceTypeValue !== null &&
    [
      EvidenceTypeValueEnum.Probate,
      EvidenceTypeValueEnum.LettersOfAdministration,
      EvidenceTypeValueEnum.DeedOfAppointment,
      EvidenceTypeValueEnum.DeathCertificate,
      EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct
    ].includes(context.evidenceTypeValue)
);
