import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import {
  convertRelinquishingGroupsToGroupsWithMeta,
  RelinquishingProprietorGroupModel,
  RelinquishingTenancyDetailModel,
  RelinquishingTenancyDetailModelWithMeta
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { convertPartiesToFormModel } from '../../helpers';
import { NSW_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { Transfer2_21_1Model } from './models';

export function generateTransferors(formModel: Transfer2_21_1Model) {
  const customParties = formModel.partyBook.filter(party => party.metadata?.source === DataSource.Custom || party.metadata?.addedFrom === DataSource.Custom);
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    formModel.transferors = {
      relinquishingProprietorGroups: [],
      tenancyType: null,
      isGroupStructureMeaningful: false
    };
    return;
  }

  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.transferors.relinquishingProprietorGroups.map(
            pg =>
              new ProprietorGroup(
                pg.parties.map(p => (p.mortgagorParty ? p.mortgagorParty : p.originalRisParty)),
                pg,
                pg.previouslyHeldShareFraction,
                pg.proprietorGroupType
              )
          )
        )
      )
  );

  const result = PartyMerger.merge(containers);

  const groups: RelinquishingProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = result.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => {
      const proprietorGroup: RelinquishingProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>> = { ...pg.tag } as RelinquishingProprietorGroupModel<
        ApiDocumentPartyModel<NswDocumentPartyJustification>
      >;
      proprietorGroup.parties = pg.mergedParties;
      proprietorGroup.previouslyHeldShareFraction = pg!.shareFraction!;
      proprietorGroup.proprietorGroupType = pg!.proprietorGroupType;
      return proprietorGroup;
    })
  );
  const transferors: RelinquishingTenancyDetailModel<TenancyPartyModel> = {
    tenancyType: selectedTitles[0].transferors.tenancyType,
    relinquishingProprietorGroups: groups.map(pg => {
      return {
        ...pg,
        parties: pg.parties.map(p => ({ id: p.id!, partyBookId: p.id! })),
        isTransacting: groups.length === 1 || pg.isTransacting,
        shareFraction: pg.shareFraction
      };
    })
  };

  formModel.transferors = convertTransferorsToFormModel(transferors, result.containers.length === 1);

  const transferorsParties = groups.flatMap(pg => pg.parties);
  formModel.mergeFailedReason = result.failedReason;
  formModel.partyBookApi = transferorsParties;
  formModel.partyBook = convertPartiesToFormModel_2_21_1(
    transferorsParties,
    transferorsParties.map(p => p.id!)
  ).concat(customParties);

  formModel.transferees.receivingProprietorGroups.forEach(g =>
    g.parties.forEach(p => {
      if (!formModel.partyBook.some(pb => pb.id === p.partyBookId)) p.partyBookId = undefined;
    })
  );
}

export function convertPartiesToFormModel_2_21_1(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_TRANSFER_PARTY_FORM_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    relinquishingIds
  );
}

export function convertTransferorsToFormModel(
  transferors: RelinquishingTenancyDetailModel<TenancyPartyModel>,
  isGroupStructureMeaningful: boolean
): //
RelinquishingTenancyDetailModelWithMeta<TenancyPartyModel> {
  const groups: RelinquishingProprietorGroupModel<TenancyPartyModel>[] = transferors.relinquishingProprietorGroups.map(pg => {
    return convertRelinquishingGroupsToGroupsWithMeta(pg, transferors.tenancyType, pg.isTransacting);
  });

  return {
    ...transferors,
    relinquishingProprietorGroups: groups,
    isGroupStructureMeaningful: isGroupStructureMeaningful,
    tenancyType: isGroupStructureMeaningful ? transferors.tenancyType : null
  };
}

export function convertTransferorsFromFormToApiModel(transferors: RelinquishingTenancyDetailModel<TenancyPartyModel>): //
RelinquishingTenancyDetailModel<TenancyPartyModel> {
  const groups: RelinquishingProprietorGroupModel<TenancyPartyModel>[] = transferors.relinquishingProprietorGroups;

  return {
    relinquishingProprietorGroups: groups.map(e => {
      const { isGroupFromMulti, ...api } = e;
      return api;
    }),
    tenancyType: transferors.tenancyType
  };
}
