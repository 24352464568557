import * as React from 'react';

import classNames from 'classnames';
import { Action } from 'redux';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCaretLeft, IconEnvelopeRead, IconEnvelopeUnRead, IconPaperPlane } from '@sympli/ui-framework/icons';

import UserAvatar from 'src/components/avatars/user-avatar';
import LineLoader from 'src/components/loaders/line-loader';
import { actionSendConversationMessage, actionUpdateConversationIsRead, SendConversationMessageApiRequest } from 'src/containers/messenger/actions';
import MessengerActionBox from 'src/containers/messenger/components/messenger-action-box';
import MessengerHeaderBox from 'src/containers/messenger/components/messenger-header-box';
import { getConversationSubject, getFormattedMessageDate } from 'src/containers/messenger/helpers';
import { ConversationDocument, MessageModel } from 'src/containers/messenger/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  workspaceId: string;
  participantId: string;

  isWorkspaceLoading: boolean;

  userName: UserProfileModel['name'];
  timezone: UserProfileModel['timezone'];

  conversationParticipantsMap: Map<string, WorkspaceParticipantApiResponse>;
  selectedConversationId: string | null;
  conversationId: string;
  conversation?: ConversationDocument;
  onConversationSelect: (selectedConversationId?: string) => void;
  dispatch: SafeDispatch<Action>;
  sendMessageDisabled?: boolean;
  readButtonDisabled?: boolean;
}
interface State {
  text: string;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class MessageDetail extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    text: ''
  };

  private currentDate = new Date();
  private messageEndRef: HTMLDivElement | null;
  private scrollToBottom = (arg?: boolean | ScrollIntoViewOptions) => {
    this.messageEndRef && this.messageEndRef.scrollIntoView(arg);
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.conversation && !!this.props.conversation && prevProps.conversation.messages !== this.props.conversation.messages) {
      this.scrollToBottom({ behavior: 'smooth' });
    }
  }

  render() {
    const { classes, sendMessageDisabled } = this.props;
    const { text } = this.state;
    return (
      <React.Fragment>
        <MessengerHeaderBox>
          <FlexLayout alignItems="center" justifyContent="space-around" className={classes.headerContainer}>
            <IconButton className={classes.backButton} onClick={this.handleOnLeftClick} aria-label="go back to list" size="large">
              <IconCaretLeft className={classes.backIcon} />
            </IconButton>
            {this.renderReadButton()}
          </FlexLayout>
        </MessengerHeaderBox>
        {this.renderSubject()}
        <FlexLayout flexDirection="column" className={classes.conversationContainer}>
          {this.renderConversation()}
          <div ref={this.saveMessageEndRef} />
        </FlexLayout>
        <MessengerActionBox>
          <Input
            aria-label="Message"
            name="message"
            placeholder="Type your message here..."
            margin="none"
            multiline
            value={text}
            onChange={this.handleOnInputChange}
            classes={{ formControl: classes.multiline }}
            inputProps={{ maxLength: 5000, className: classes.multilineInput }}
            disabled={sendMessageDisabled}
          />
          <SympliButton
            color="primary"
            variant="contained"
            className={classes.submitButton}
            onClick={this.handleOnSendClick}
            aria-label="Send message"
            disabled={sendMessageDisabled}
          >
            <IconPaperPlane className={classes.submitIcon} />
          </SympliButton>
        </MessengerActionBox>
      </React.Fragment>
    );
  }

  private renderSubject() {
    const { classes, conversation } = this.props;

    if (conversation == null) {
      return null;
    }

    const { isRead } = conversation;
    const subject = getConversationSubject(conversation);
    return (
      <Typography variant="subtitle1" className={classNames(classes.conversationHeader, !isRead && classes.conversationHeaderHighlight)}>
        {!isRead && <div className={classes.highlightDot} />}
        {subject}
      </Typography>
    );
  }

  private renderReadButton() {
    const { classes, conversation, readButtonDisabled } = this.props;

    if (conversation == null) {
      return null;
    }

    const { isRead } = conversation;
    return (
      <IconButton className={classes.readButton} onClick={this.handleOnReadButtonClick} size="large" disabled={readButtonDisabled}>
        {isRead ? <IconEnvelopeUnRead className={classes.readIcon} /> : <IconEnvelopeRead className={classes.readIcon} />}
      </IconButton>
    );
  }
  private saveMessageEndRef = (el: HTMLDivElement | null) => {
    this.messageEndRef = el;
  };

  private renderConversation() {
    const { classes, isWorkspaceLoading, conversation, conversationParticipantsMap: participantMap, timezone } = this.props;

    if (conversation == null) {
      return null;
    }

    const { messages, recipientParticipantIds } = conversation;
    return messages.map(message => {
      if (isWorkspaceLoading) {
        return (
          <div key={message.createdDate} className={classes.messageBox}>
            <LineLoader variant="medium" widthPercent={100} />
            <LineLoader variant="medium" widthPercent={100} />
          </div>
        );
      }
      const { sentByParticipantId, createdDate, text } = message;
      const senderParticipant = participantMap.get(sentByParticipantId);
      const avatar = senderParticipant && senderParticipant.avatarUrl;
      const subscriberName = (senderParticipant && senderParticipant.name) || message.sentBySubscriberName;
      const dateLabel = getFormattedMessageDate(new Date(createdDate), this.currentDate, timezone);

      return (
        <div key={createdDate} className={classes.messageBox}>
          <FlexLayout>
            <UserAvatar src={avatar} text={subscriberName} size="small" />
            <div className={classes.messageHeaderBox}>
              {this.renderMessageSender(message, senderParticipant)}
              {this.renderMessageRecipient(message, recipientParticipantIds)}
            </div>
            <Typography className={classes.messageTime}>{dateLabel}</Typography>
          </FlexLayout>
          <Typography variant="body1" className={classes.messageBody}>
            {text}
          </Typography>
        </div>
      );
    });
  }

  private renderMessageSender(message: MessageModel, participant: WorkspaceParticipantApiResponse | undefined) {
    const { classes, participantId } = this.props;
    const { sentByParticipantId, sentByUserName } = message;
    const subscriberName = (participant && participant.name) || message.sentBySubscriberName;
    if (sentByParticipantId === participantId) {
      return (
        <Typography className={classNames(classes.messageHeader, classes.messageSenderLabel)} title={subscriberName}>
          {sentByUserName} ({subscriberName})
        </Typography>
      );
    }
    const roleName = participant && participant.workspaceRole.name;
    return (
      <Typography className={classNames(classes.messageHeader, classes.messageSenderLabel)} title={roleName}>
        {subscriberName} {roleName != null && `(${roleName})`}
      </Typography>
    );
  }

  private renderMessageRecipient(message: MessageModel, recipientParticipantIds: Array<string>) {
    const { classes, participantId, conversationParticipantsMap: participantMap } = this.props;
    const { sentByParticipantId } = message;

    if (sentByParticipantId === participantId) {
      const recipientNameList = recipientParticipantIds
        .map(recipientId => {
          const recipient = participantMap.get(recipientId);
          return recipient && `${recipient.name} (${recipient.workspaceRole.name})`;
        })
        .filter(value => !!value);
      return <Typography className={classNames(classes.messageHeader, classes.messageRecipientLabel)}>To {recipientNameList.join(', ')}</Typography>;
    }
    const currentParticipant = participantMap.get(participantId);
    const name = currentParticipant && currentParticipant.name;
    return <Typography className={classNames(classes.messageHeader, classes.messageRecipientLabel)}>To {name}</Typography>;
  }

  private handleOnInputChange = (_unusedEvent: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => {
    this.setState({ text: resolvedValue });
  };

  private handleOnReadButtonClick = () => {
    const { workspaceId, participantId, conversation, dispatch } = this.props;
    if (conversation != null) {
      const { conversationId, isRead } = conversation;
      dispatch(actionUpdateConversationIsRead.request({ workspaceId, participantId, conversationId, isRead: !isRead }));
    }
  };
  private handleOnSendClick = () => {
    const { text: newMessageText } = this.state;

    if (!newMessageText || newMessageText.trim().length === 0) {
      return;
    }

    const { conversationParticipantsMap: participantMap, workspaceId, participantId, conversationId: id, userName, dispatch } = this.props;
    const currentParticipant = participantMap.get(participantId);
    if (currentParticipant != null) {
      const { name } = currentParticipant;
      const messageItem: SendConversationMessageApiRequest = {
        workspaceId,
        participantId,
        conversationId: id,
        message: newMessageText,
        sentByUserName: userName,
        sentBySubscriberName: name
      };
      dispatch(actionSendConversationMessage.request(messageItem));
      this.setState({ text: '' });
    }
  };

  private handleOnLeftClick = () => {
    this.props.onConversationSelect(undefined);
  };
}

export default withStyles(styles)(MessageDetail);
