import * as React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { useStyles } from './styles';

interface SettingMenuNavLinkProps {
  to: string;
  title?: string;
  disabled?: boolean;
}

function resolveNavLinkTitle({ title, children }: React.PropsWithChildren<{ title?: string }>) {
  if (title != null) {
    return title;
  }
  if (typeof children === 'string') {
    return children;
  }
  return undefined;
}

function SettingMenuNavLink(props: React.PropsWithChildren<SettingMenuNavLinkProps>) {
  const { children, to, disabled, title } = props;
  const classes = useStyles();

  return (
    <NavLink
      title={resolveNavLinkTitle({ children, title })}
      className={classNames(classes.link, disabled && classes.disabled)}
      to={to}
      {...(disabled
        ? {
            //
            'aria-disabled': true,
            tabIndex: -1,
            onClick: e => e.preventDefault()
          }
        : {})}
    >
      {children}
    </NavLink>
  );
}

export default SettingMenuNavLink;
