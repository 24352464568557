import { addDays, addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, getISODay, isBefore, isValid, startOfMonth, startOfWeek, subDays } from 'date-fns';
import { startOfDay } from 'date-fns/fp';
import parse from 'date-fns/parse';
import dateFormat from 'dateformat';
import * as yup from 'yup';

import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { DashboardDateRangeModel } from 'src/containers/dashboard/shared/models';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { DateRangeModel, DateRangeTypeEnum } from './models';

export const isDueTomorrow = (): boolean => {
  const now = new Date();
  const day = getISODay(now);
  return !(day >= 5 && day <= 6);
};

export const getDateRange = (dateRangeType: DateRangeTypeEnum): DateRangeModel => {
  const now = new Date();

  switch (dateRangeType) {
    case DateRangeTypeEnum.AllDates: {
      return { dateRangeType, from: null, to: null };
    }
    case DateRangeTypeEnum.TodayAndOverdue: {
      return { dateRangeType, from: null, to: now };
    }
    case DateRangeTypeEnum.Overdue: {
      const yesterday = subDays(now, 1);
      return { dateRangeType, from: null, to: yesterday };
    }
    case DateRangeTypeEnum.Today: {
      return { dateRangeType, from: now, to: now };
    }
    case DateRangeTypeEnum.Tomorrow: {
      const isoDay = getISODay(now);
      let tomorrow;
      if (isoDay === 5) {
        tomorrow = addDays(now, 3);
      } else if (isoDay === 6) {
        tomorrow = addDays(now, 2);
      } else {
        tomorrow = addDays(now, 1);
      }
      return { dateRangeType, from: tomorrow, to: tomorrow };
    }
    case DateRangeTypeEnum.ThisWeek: {
      const weekStart = startOfWeek(now);
      const weekEnd = endOfWeek(now);
      return { dateRangeType, from: weekStart, to: weekEnd };
    }
    case DateRangeTypeEnum.ThisWeekStartsOnMonday: {
      const weekStart = startOfWeek(now, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(now, { weekStartsOn: 1 });
      return { dateRangeType, from: weekStart, to: weekEnd };
    }
    case DateRangeTypeEnum.NextWeek: {
      const nextWeek = addWeeks(now, 1);
      const nextWeekStart = startOfWeek(nextWeek);
      const nextWeekEnd = endOfWeek(nextWeek);
      return { dateRangeType, from: nextWeekStart, to: nextWeekEnd };
    }
    case DateRangeTypeEnum.ThisMonth: {
      const monthStart = startOfMonth(now);
      const monthEnd = endOfMonth(now);
      return { dateRangeType, from: monthStart, to: monthEnd };
    }
    case DateRangeTypeEnum.NextMonth: {
      const nextMonth = addMonths(now, 1);
      const nextMonthStart = startOfMonth(nextMonth);
      const nextMonthEnd = endOfMonth(nextMonth);
      return { dateRangeType, from: nextMonthStart, to: nextMonthEnd };
    }
    case DateRangeTypeEnum.Custom:
    default:
      return { dateRangeType, from: null, to: null };
  }
};

export const REGEX_AUS_DATE = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

export const testValidAusDate = (value: string = '') => {
  const [d = '', m = '', y = ''] = value.replace(/_/g, '').split('/');

  if (y.length !== 4 || m.length === 0 || d.length === 0) {
    return false;
  }

  const date = new Date(+y, +m - 1, +d);
  if (date.getFullYear() !== +y || date.getMonth() !== +m - 1 || date.getDate() !== +d) {
    return false;
  }

  return isValid(new Date(`${y}-${m}-${d}`));
};

export const resolveIsoDate = (inputDate?: Date, startEnd: 'start' | 'end' = 'start') => {
  if (!inputDate) {
    return null;
  }

  if (startEnd === 'end') {
    return dateFormat(endOfDay(inputDate), DateFormatEnum.DATETIME);
  } else {
    return dateFormat(startOfDay(inputDate), DateFormatEnum.DATETIME);
  }
};

export const convertDateObject = (period: DashboardDateRangeModel | undefined, periodName: string) => {
  let dateObject = {};
  const startKey = `${periodName}.start`;
  const endKey = `${periodName}.end`;

  if (period && typeof period !== 'string') {
    const { start, end } = convertRangeDateToIsoDate(period);
    dateObject = {
      [startKey]: start,
      [endKey]: end
    };
  }

  return dateObject;
};

export const convertDateOrNull = (period: DashboardDateRangeModel | null | undefined, periodName: string) => {
  let dateObject = {};
  const startKey = `${periodName}.start`;
  const endKey = `${periodName}.end`;

  if (period) {
    const { start, end } = convertRangeDateToIsoDate(period);
    dateObject = {
      [startKey]: start,
      [endKey]: end
    };
  } else if (period === null) {
    dateObject = {
      [startKey]: undefined,
      [endKey]: undefined
    };
  }
  return dateObject;
};

export const convertRangeDateToIsoDate = <
  T extends {
    //
    start: Date | string | null;
    end: Date | string | null;
  }
>(
  dateRange: T
): { start: string | null; end: string | null } => {
  const start =
    typeof dateRange.start === 'string' //
      ? resolveIsoDate(parse(dateRange.start, 'dd/MM/yyyy', new Date()), 'start')
      : //TODO Review: this should never happen, how about test for null?!
        resolveIsoDate(dateRange.start as Date, 'start');
  const end =
    typeof dateRange.end === 'string' //
      ? resolveIsoDate(parse(dateRange.end, 'dd/MM/yyyy', new Date()), 'end')
      : resolveIsoDate(dateRange.end as Date, 'end');
  return {
    start,
    end
  };
};

// function testValidDate(this: yup.TestContext, value: string): boolean | yup.ValidationError {
//   const { dateRangeType, from, to } = this.parent;
//   const [y = '', m = '', d = ''] = value.split('-');

//   // for custom date range, both from and to should have a correct value
//   // as it's been checked for validation with testValidAusDate so it's
//   // valid as long as both value are non empty
//   if (dateRangeType === DateRangeTypeEnum.Custom) {
//     return !!from && !!to;
//   }

//   // otherwise, empty is considered to be a valid value
//   if (y.length === 0 && m.length === 0 && d.length === 0) {
//     return true;
//   }

//   const date = new Date(+y, +m - 1, +d);
//   if (date.getFullYear() !== +y || date.getMonth() !== +m - 1 || date.getDate() !== +d) {
//     return false;
//   }

//   return isValid(`${y}-${m}-${d}`);
// }

function testValidDateRange(this: yup.TestContext): boolean | yup.ValidationError {
  const { dateRangeType, from, to } = this.parent;
  if (dateRangeType === DateRangeTypeEnum.Custom) {
    // must use !isBefore as we convert to end of day while submitting the request
    return !isBefore(to, from);
  } else {
    return true;
  }
}

export const validationSchema = yup.object<DateRangeModel>({
  dateRangeType: yup.string(),
  from: yup.mixed<Date | string | null>().when('dateRangeType', {
    is: DateRangeTypeEnum.Custom,
    then: yupDatePicker.required(msg.REQUIRED)
  }),

  to: yup.mixed<Date | string | null>().when('dateRangeType', {
    is: DateRangeTypeEnum.Custom,
    then: yupDatePicker.required(msg.REQUIRED).test('isValidDateRange', 'From date must be earlier than To date', testValidDateRange)
  })
});
