import * as React from 'react';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import ActivationStatusBadge from 'src/containers/settings/components/activation-status-badge';
import { UserDetailsApiResponse } from '../../../users-detail/detail/models';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  // route params
  userId: string;
  // redux
  userDetail?: Partial<UserDetailsApiResponse>;
  // other
  description?: React.ReactNode;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class SubheadingWithStatus extends React.PureComponent<Props> {
  render() {
    const { classes, description } = this.props;
    return (
      <FlexLayout alignItems="center" className={classes.bottomLine}>
        <Typography variant="subtitle1" className={classes.userName}>
          User: {this.getName()}
        </Typography>
        {this.resolveUserStatus()}
        {description && <Typography className={classes.description}>{description}</Typography>}
      </FlexLayout>
    );
  }

  private getName = () => {
    const { userDetail } = this.props;
    const { firstName = '', middleName = '', lastName = '' } = userDetail ?? {};
    return `${firstName} ${middleName} ${lastName}`.replace(/\s{2,}/g, ' ');
  };

  private resolveUserStatus() {
    const { userDetail } = this.props;
    const { isSuspended: suspendStatus, isActivated: activationStatus } = userDetail ?? {};
    // TODO review this logic,
    // according to model both properties are boolean, so why are we testing it for emptiness?
    if (!userDetail || suspendStatus == null || activationStatus == null) {
      return null;
    }
    return this.resolveActivationStatus(suspendStatus, activationStatus);
  }

  private resolveActivationStatus(isSuspended: boolean, isActivated: boolean) {
    if (isSuspended) {
      return <ActivationStatusBadge status="suspended" />;
    }
    return <ActivationStatusBadge status={isActivated ? 'active' : 'inactive'} />;
  }
}

const styledComponent = withStyles(styles)(SubheadingWithStatus);

export default styledComponent;
