import { endOfToday } from 'date-fns';
import * as yup from 'yup';

import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { EmptyContext, memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS, IS_REQUIRED_CHECK_DEALING_NUMBER } from '../../isRequiredChecks';
import { StandardTermsModel } from '../../models';

export const ADDITIONAL_COVENANT_LIMIT: number = 4000;
const END_OF_TODAY = endOfToday();

// path: termsAndConditions
const yupTermsAndConditions = memoizeSchema(
  yup.object<StandardTermsModel, EmptyContext>({
    dealingNumber: yup
      .string()
      .defined()
      .default('')
      .trim()
      .typeError(msg.INVALID_VALUE)
      .max(9, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(9))
      .testContextualRule({
        message: msg.REQUIRED,
        uniqueTestName: '"termsAndConditions.dealingNumber" required check',
        onlyIf: (parent: StandardTermsModel): boolean => IS_REQUIRED_CHECK_DEALING_NUMBER(parent),
        requirement: (parent: StandardTermsModel) => isNotNullOrEmpty(parent.dealingNumber)
      }),
    additionalCovenants: yup
      .string()
      .defined()
      .default('')
      .trim()
      .typeError(msg.INVALID_VALUE)
      .max(
        ADDITIONAL_COVENANT_LIMIT,
        ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(ADDITIONAL_COVENANT_LIMIT)}. ${value.length}/${max} characters (including spaces and formatting)`
      )
      .testContextualRule({
        uniqueTestName: '"termsAndConditions.additionalCovenants" required check',
        onlyIf: (parent: StandardTermsModel): boolean => IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS(parent),
        requirement: (parent: StandardTermsModel) => isNotNullOrEmpty(parent.additionalCovenants),
        message: msg.REQUIRED
      }),
    mortgageDate: yup //
      .date()
      .nullable(true)
      .default(null)
      .typeError(msg.INVALID_DATE)
      .max(END_OF_TODAY, msg.DATE_MUST_BE_TODAY_OR_PAST_DATE) as yup.MixedSchema<Date | string | null>
  })
);

export default yupTermsAndConditions;
