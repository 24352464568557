import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { FeatureToggleEnum } from '../shared/auth/profile-feature-toggle/models';
import { actionFetchParticipantConversationSummary } from './actions';
import Messenger from './Messenger';
import Messenger101 from './Messenger101';
import { useMessengerConversations } from './reducer';
import { newMessageCountSelector } from './views/message-list/selectors';

interface CustomizedState {
  messenger: string;
}

interface Props {
  // route params
  workspaceId: string;
  participantId: string;
  // other
  className?: string;
}

function MessengerContainer({ workspaceId, participantId, className }: Props) {
  const isWorkspaceMessenger101Enabled = useFeatureFlag(FeatureToggleEnum.workspaceMessenger101Enabled);
  const location = useLocation();
  // Type Casting, then you can get the params passed via router
  const state = location.state as CustomizedState;
  const { messenger } = state || {};
  const isMessengerOpen = messenger === 'open';
  const dispatch = useSafeDispatch(useDispatch());
  const messengerConversations = useMessengerConversations(workspaceId, participantId);
  const newMessageCount: number = newMessageCountSelector({
    messengerConversations
  });

  // TODO since messenger is used as part of the workspace page only, consider moving fetch logic to page level
  useEffect(() => {
    dispatch(actionFetchParticipantConversationSummary.request({ workspaceId, participantId }));
  }, [dispatch, participantId, workspaceId]);

  if (isWorkspaceMessenger101Enabled) {
    return (
      <Messenger101
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        // conversations
        newMessageCount={newMessageCount}
        messengerOpen={isMessengerOpen}
        className={className}
      />
    );
  }

  return (
    <Messenger
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // conversations
      newMessageCount={newMessageCount}
      messengerOpen={isMessengerOpen}
      hasItems={messengerConversations.items.length !== 0}
      className={className}
    />
  );
}

export default React.memo(MessengerContainer);
