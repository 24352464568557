import React from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import GenericSectionTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference';

interface Props {
  name: string;
  disabled: boolean;
  focusRef: React.RefObject<ButtonBaseActions>;
}

const SectionTitleReferences = ({ disabled, name, focusRef }: Props): JSX.Element => {
  return (
    <GenericSectionTitleReferences //
      name={name}
      data-testid={name}
      variant="naked"
      disabled={disabled}
      focusRef={focusRef}
      noDataMessage="The mortgages selected do not share a common title. Please review the selected mortgages."
      allowPartLandSelection
    />
  );
};

export default SectionTitleReferences;
