// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `15px 20px 5px 20px`
    },
    setOfOperativeWords: {
      width: 'auto'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
