import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import yupTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { ADDRESS_BOOK_KEY, CaveatModel, PARTY_BOOK_KEY } from './models';
import yupExtentOfProhibitions from './sections/action-prohibited/validationSchema';
import yupCaveators from './sections/caveator/validationSchema';
import yupClaimDetails from './sections/claim-details/validationSchema';
import yupDutyAssessmentNumber from './sections/duty-assessment-number/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupProprietors from './sections/registered-proprietor/validationSchema';
import yupDetailsForServiceOfNotice from './sections/service-of-notice/validationSchema';

// this file was automatically generated
export default yup.object<CaveatModel>().shape({
  [ADDRESS_BOOK_KEY]: yup.array<AddressBookEntityModel>(),
  [PARTY_BOOK_KEY]: yupPartyBook,
  titleReferences: yupTitleReferences,
  extentOfProhibitions: yupExtentOfProhibitions,
  claimDetails: yupClaimDetails,
  dutyAssessmentNumber: yupDutyAssessmentNumber,
  caveators: yupCaveators,
  detailsForServiceOfNotice: yupDetailsForServiceOfNotice,
  proprietors: yupProprietors,
  caveatStatementsAgreed: yup.boolean().test('Checked test', 'Must agree to caveat statement', Boolean)
});
