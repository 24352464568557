import * as React from 'react';

import AvatarEditor from 'react-avatar-editor';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import { DropzoneFile } from '@sympli/ui-framework/components/form/base-components/dropzone';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';

import { ProfileImageModel } from 'src/containers/shared/profile-image/models';
import AvatarDropzone from '../avatar-dropzone';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  src?: string;
  deleteImage?: () => void;
  onImageUploaded?: (profileImage: ProfileImageModel) => void;
}
type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  showDropzone: boolean;
  openedImage: string | File;
  isUploading: boolean;
  src?: string;
}

class AvatarUploader extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    showDropzone: !this.props.src,
    openedImage: '',
    isUploading: false
  };

  private editor: AvatarEditor;
  private setEditorRef = (editor: AvatarEditor) => (this.editor = editor);

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { src: nextSrc } = nextProps;
    const { src } = prevState;

    if (src !== nextSrc) {
      return {
        showDropzone: !nextSrc,
        src: nextSrc
      };
    }
    return { src: nextSrc };
  }

  render() {
    const { src } = this.props;
    const { openedImage, showDropzone } = this.state;

    return (
      <FlexLayout flexDirection="column">
        <AvatarDropzone
          src={src}
          showDropzone={showDropzone}
          openedImage={openedImage}
          dropzoneProps={{
            onAddFile: this.handleAddFile
          }}
          setEditorRef={this.setEditorRef}
          editorProps={{ onImageReady: this.handleOnImageUploaded }}
        />
        {this.renderActionButton()}
      </FlexLayout>
    );
  }

  private renderActionButton() {
    const { classes } = this.props;
    const { isUploading, showDropzone, openedImage } = this.state;

    if (isUploading) {
      return (
        <FlexLayout alignItems="center" justifyContent="space-around" className={classes.buttonContainer}>
          <InlineLoader size={24} />
        </FlexLayout>
      );
    }

    if (!showDropzone || openedImage) {
      return (
        <FlexLayout alignItems="center" className={classes.buttonContainer}>
          <ButtonLink icon="delete" onClick={this.handleOnDeleteClick} color="inherit">
            Delete
          </ButtonLink>
        </FlexLayout>
      );
    }

    return null;
  }

  private handleAddFile = (file: DropzoneFile) => {
    this.setState({ openedImage: file.preview ?? '' });
  };

  private handleOnImageUploaded = () => {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const base64String = canvasScaled.toDataURL().split('base64,')[1]; // * Strip base64 string from image file
      const profileImage: ProfileImageModel = {
        base64String,
        imageType: 'png' // ! TickleApi only allows 'png' file
      };

      this.props.onImageUploaded?.(profileImage);
    }
  };

  private handleOnDeleteClick = () => {
    this.setState({ showDropzone: true, openedImage: '' });

    this.props.deleteImage?.();
  };
}

export default withStyles(styles)(AvatarUploader);
