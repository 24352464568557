import { Key, pathToRegexp } from 'path-to-regexp';
import queryString from 'query-string';

import { resolveRunTimeEnv } from 'src/environments/resolveRunTimeEnv';

const { BASENAME } = resolveRunTimeEnv();
interface CompiledPath {
  regexp: RegExp;
  keys: Key[];
  index: boolean;
}

interface RouteDefinitionOptions {
  caseSensitive: boolean;
  index: boolean;
}

const cache: Record<string, Record<string, CompiledPath>> = {
  // we have multiple cache groups
  // these are ordered based on matching priority,
  // do NOT change this order
  'index:true|caseSensitive:true': {},
  'index:true|caseSensitive:false': {},
  'index:false|caseSensitive:true': {},
  'index:false|caseSensitive:false': {}
};

function compilePath(path: string, { index, caseSensitive }: RouteDefinitionOptions): CompiledPath {
  const keys: Key[] = [];

  // React-router 6 use /* as descendant-routes
  // https://github.com/pillarjs/path-to-regexp
  const newPath = path.replace('*', '(.*)');

  const regexp: RegExp = pathToRegexp(newPath, keys, {
    sensitive: caseSensitive
  });
  const result: CompiledPath = {
    regexp,
    keys,
    index
  };

  return result;
}

export function registerPath(path: string, { index, caseSensitive }: RouteDefinitionOptions): CompiledPath {
  // calculate cache group into which we will store our processed path
  const cacheGroup = `index:${index}|caseSensitive:${caseSensitive}`;

  const pathCache: Record<string, CompiledPath> = cache[cacheGroup] || (cache[cacheGroup] = {});

  if (pathCache[path]) {
    return pathCache[path];
  }

  const result = compilePath(path, { index, caseSensitive });
  pathCache[path] = result;

  return result;
}

export interface match<Params extends { [K in keyof Params]?: string } = {}> {
  params: Params;
  isExact: boolean;
  path: string;
  url: string;
}

function matchPath<Params extends { [K in keyof Params]?: string } = {}>(pathname: string): match<Params> | undefined {
  // groups are ordered based on the priority
  for (const group in cache) {
    for (const path in cache[group]) {
      const { regexp, keys, index } = cache[group][path];

      const match = regexp.exec(pathname);
      if (match) {
        const [url, ...values] = match;
        const isExact = pathname === url;
        if (index && !isExact) {
          continue;
        }

        return {
          path, // the path used to match
          url: path === '/' && url === '' ? '/' : url, // the matched portion of the URL
          isExact, // whether or not we matched exactly
          params: keys.reduce((memo, key, index) => {
            memo[key.name] = values[index];
            return memo;
          }, {} as Params)
        };
      }
    }
  }
  return;
}

const ROUTE_PREFIX_REGEXP: RegExp = new RegExp(`^${BASENAME}`);
export function getAllLocationParams<Params extends { [K in keyof Params]?: string } = {}, Query extends object = {}>(): Partial<Params & Query> {
  const match: match<Params> | undefined = matchPath(window.location.pathname.replace(ROUTE_PREFIX_REGEXP, ''));
  const query: Query = queryString.parse(window.location.search) as Query;

  return {
    ...(match?.params || {}),
    ...query
  };
}
