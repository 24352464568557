import React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder, resolveVisibleEnumOptions } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { CONSIDERATION$NON_MONETARY_CONSIDERATION$ACQUISITION_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { NonMonetaryConsiderationModel, NoticeOfSaleModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
  className?: string;
}

function NonMonetaryConsideration({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfSaleModel>();
  const { values } = formikProps;

  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<NonMonetaryConsiderationModel>(name);
  return (
    <>
      <FormGroup //
        title="Type of acquisition"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('acquisitionType')}
            component={SelectField}
            debug={debug}
            disabled={disabled}
            format="number"
            options={resolveVisibleEnumOptions<number>(CONSIDERATION$NON_MONETARY_CONSIDERATION$ACQUISITION_TYPE_LOOKUP_OPTIONS, values, fieldName('acquisitionType'))}
            placeholder={resolveSelectPlaceholder(true)}
            data-testid={'Acquisition Type'}
          />
        )}
      </FormGroup>

      <FormGroup //
        title="Date of acquisition"
        fieldAreaDirection="column"
        data-testid={fieldName('acquisitionDate')}
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('acquisitionDate')}
            component={DatePickerField}
            disabled={disabled}
            data-testid={'Acquisition Date'}
          />
        )}
      </FormGroup>
    </>
  );
}

export default React.memo(NonMonetaryConsideration);
