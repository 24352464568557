import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useFormControlStyleOverrides = makeStyles(
  {
    root: {
      width: 120,
      boxSizing: 'border-box',
      borderRadius: 4,
      background: colors.NEUTRAL_025,
      '&:hover': {
        background: colors.GREEK_WATERS_TRANSLUCENT
      },
      '&.devHelper': {
        '&[data-name]::after': {
          position: 'absolute',
          content: 'attr(data-name)',
          fontSize: 12,
          color: '#d2691e80',
          fontWeight: 'normal',
          pointerEvents: 'none',
          marginTop: 65
        }
      },
      display: 'flex',
      flexDirection: 'column',
      //alignItems: 'center',
      padding: '13px 12px'
    },
    popOpen: {
      background: colors.GREEK_WATERS_TRANSLUCENT
    }
  },
  { name: 'MultipleTabsDateTimePicker_FormControlStyleOverrides' }
);

export const useInputLabelStyleOverrides = makeStyles(
  {
    root: {
      color: colors.NEUTRAL_600,
      fontFamily: 'Roboto',
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      textTransform: 'uppercase',

      '&.Mui-focused': {
        color: colors.NEUTRAL_600
      }
    }
  },
  { name: 'MultipleTabsDateTimePicker_InputLabelStyleOverrides' }
);

export const useTabsStyleOverrides = makeStyles(
  {
    root: {
      width: 320
    },
    flexContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      marginTop: 24,
      borderRadius: '80px',
      boxShadow: '0px 2px 2px 0px rgba(27, 127, 121, 0.20) inset',
      width: 260,
      height: 36
    },
    tabRoot: {
      color: colors.NEUTRAL_1000,
      textAlign: 'center',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      width: 80,
      borderRadius: '80px'
    },
    tab: {
      display: 'flex',
      width: 83,
      height: 32,
      minHeight: 32,
      padding: '8px 12px',
      transition: 'background-color 0.5s ease'
    },
    tabSelected: {
      display: 'flex',
      width: 83,
      height: 32,
      minHeight: 32,
      padding: '8px 12px',
      backgroundColor: 'white',
      transition: 'background-color 0.5s ease'
    },
    scroller: {
      borderBottom: 'none'
    },
    indicator: {
      display: 'none'
    }
  },
  {
    name: 'MultipleTabsDateTimePicker_TabsStyleOverrides'
  }
);

export const useButtonBaseStyleOverrides = makeStyles(
  {
    root: {
      display: 'flex',
      height: 24,
      justifyContent: 'space-between',
      borderRadius: 4
    },
    text: {
      color: colors.NEUTRAL_1000,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      whiteSpace: 'nowrap'
    }
  },
  {
    name: 'MultipleTabsDateTimePicker_ButtonBaseStyleOverrides'
  }
);

export const usePopoverStyleOverrides = makeStyles(
  {
    root: {
      marginTop: 4
    },
    paper: {
      backgroundColor: colors.GREEK_WATERS_TRANSLUCENT,
      borderRadius: '4px',
      boxShadow: '0px 20px 20px 0px rgba(0, 0, 0, 0.15)'
    }
  },
  {
    name: 'MultipleTabsDateTimePicker_PopoverStyleOverrides'
  }
);

export const useDateCalendarStyleOverrides = makeStyles(
  {
    dateCalendarRoot: {
      height: 310,
      minHeight: 310,
      '& .MuiDayCalendar-weekDayLabel': {
        color: colors.SYMPLI_GREEN,
        textAlign: 'center',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '1.5px'
      }
    },
    datePickerPopper: {
      '& .MuiPickersCalendarHeader-root': {
        marginTop: 24
      },

      '& .MuiPaper-root': {
        background: colors.GREEK_WATERS_TRANSLUCENT
      },
      '& .MuiDayCalendar-weekDayLabel': {
        color: colors.SYMPLI_GREEN,
        textAlign: 'center',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '1.5px'
      }
    },

    yearMonth: {
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 32,
      paddingRight: 20,
      '& .MuiPickersCalendarHeader-switchViewIcon': {
        fill: colors.GREEK_WATERS_DARK
      }
    },
    hide: {
      display: 'none'
    },
    yearMonthLabel: {
      color: colors.GREEK_WATERS_DARK,
      textAlign: 'center',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase'
    },
    clearButtonWrapper: {
      flexDirection: 'row-reverse',
      marginBottom: 24,
      marginRight: 24,
      marginLeft: 32
    },
    singleDayClearButton: {},
    selectedDaysWrapper: {
      color: colors.SUNNY_DAY_DARK,
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      marginLeft: 0,
      paddingTop: '4px'
    },
    selectedDaysDescription: {
      color: colors.SUNNY_DAY_DARK,
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      paddingTop: '4px'
    },
    selectedDays: {
      color: colors.SUNNY_DAY_DARK,
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px'
    },
    day: {
      color: colors.NEUTRAL_1000,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',

      '&.MuiPickersDay-dayOutsideMonth': {
        color: colors.NEUTRAL_400
      },

      '&.Mui-selected': {
        backgroundColor: colors.SUNNY_DAY
      },
      '&.Mui-selected:hover': {
        backgroundColor: colors.SUNNY_DAY
      },
      '&:hover': {
        backgroundColor: colors.GREEK_WATERS_TRANSLUCENT,
        border: `1px solid ${colors.SUNNY_DAY}`
      },
      '&:focus.Mui-selected': {
        backgroundColor: colors.SUNNY_DAY
      },
      '&.MuiPickersDay-today': {
        border: 'none'
      },
      '&.MuiPickersDay-today:hover': {
        border: `1px solid ${colors.SUNNY_DAY}`
      }
    },
    today: {
      fontWeight: 700
    },
    selectAllDateButton: {
      borderRadius: 34,
      width: 160,
      height: 40,
      backgroundColor: colors.SUNNY_DAY,
      marginTop: 36,
      marginBottom: 96,
      color: colors.WHITE,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '20px'
    },
    dateRangeContainer: {
      margin: '8px 32px 32px 32px',
      '& .MuiFormHelperText-root.Mui-error': {
        color: colors.WATERMELON_DARK
        /**
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px'
         */
      }
    },
    buttons: {
      flexDirection: 'row-reverse',
      width: '100%',
      marginTop: 32
    },
    clearButton: {
      height: 24,
      minHeight: 24,
      minWidth: 'auto',
      padding: '4px 12px',
      borderRadius: 17,
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',

      border: '1.5px solid #D4D5D7',

      color: colors.NEUTRAL_700,
      '&:hover': {
        borderColor: '#D4D5D7',
        backgroundColor: 'inherit',
        border: '1.5px solid #D4D5D7'
      },
      marginRight: 4
    },
    applyButton: {
      height: 24,
      padding: '4px 12px',
      borderRadius: 17,
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      backgroundColor: colors.SUNNY_DAY,
      color: colors.WHITE,
      minWidth: 'auto'
    },
    dateStartPicker: {
      marginTop: 8,
      marginBottom: 16,
      width: 260
    },
    dateEndPicker: {
      width: 260
    },
    datePicker: {
      '& .MuiInputLabel-root': {
        left: 6,
        top: -6,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: colors.GREEK_WATERS_TRANSLUCENT,
        transform: 'none',
        color: colors.NEUTRAL_700
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: colors.WATERMELON_DARK
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: colors.SUNNY_DAY_DARK
      },
      '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.WATERMELON,
        borderWidth: '1px'
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.SUNNY_DAY,
        borderWidth: '1px'
      },
      '& input': {
        color: colors.NEUTRAL_500,
        fontWeight: 400,
        fontSize: 14,
        fontStyle: 'normal',
        lineHeight: '20px',
        letterSpacing: '0.5px'
      }
    },
    datePickerTouched: {
      '& input': {
        color: colors.NEUTRAL_1000,
        fontWeight: 700
      }
    },
    dateTimeInput: {}
  },
  {
    name: 'MultipleTabsDateTimePicker_DateCalendarStyleOverrides'
  }
);
