import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { actionUpdateConversationIsRead } from '../../actions';
import { useMessengerConversations } from '../../reducer';
import { messagingDisabledSelector, messagingDisabledSelector101 } from '../../selectors';
import MessageDetail from './MessageDetail';
import MessageDetail101 from './MessageDetail101';
import { conversationDetailSelector, conversationParticipantMapSelector } from './selectors';

interface Props {
  conversationId: string;
  onConversationSelect(selectedConversationId?: string): void;
  // route params
  workspaceId: string;
  participantId: string;
}
function MessageDetailContainer({ workspaceId, participantId, conversationId, onConversationSelect }: Props) {
  const isWorkspaceMessenger101Enabled = useFeatureFlag(FeatureToggleEnum.workspaceMessenger101Enabled);
  const dispatch = useSafeDispatch(useDispatch());
  const { name, timezone } = useProfile().data!;
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const isWorkspaceLoading = useWorkspaceDetail(workspaceId, participantId).isLoading;
  const messengerConversationsState = useMessengerConversations(workspaceId, participantId);
  const selectedConversationId = messengerConversationsState.realtimeUpdatedConversationId;

  const participantMap = conversationParticipantMapSelector({
    workspaceParticipants: workspaceParticipantsState
  });
  const conversation = conversationDetailSelector({
    conversationId,
    messengerConversations: messengerConversationsState
  });
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const isParticipantArchived: boolean = !!workspaceParticipantsState.items.find(x => x.id === participantId)?.archivedStatus;
  const subscriberNames = React.useMemo(() => {
    return workspaceParticipantsState.items.reduce((acc, curr) => {
      acc[curr.id] = curr.name;
      return acc;
    }, {});
  }, [workspaceParticipantsState.items]);

  useEffect(() => {
    /**
     * if user is already in the thread list and real time pushed an update
     * we need to update the message read flag when:
     * 1. the participant is not archived
     * 2. the isRead flag is false
     */
    if (selectedConversationId != null && !isParticipantArchived && !conversation?.isRead) {
      dispatch(
        actionUpdateConversationIsRead.request({
          //
          workspaceId,
          participantId,
          conversationId: selectedConversationId,
          isRead: true
        })
      );
    }
  }, [dispatch, workspaceId, participantId, selectedConversationId, isParticipantArchived, conversation]);

  if (isWorkspaceMessenger101Enabled) {
    const sendingMessagingDisabledStatus = messagingDisabledSelector101({
      participantId,
      workspaceBasicInfoState,
      workspaceParticipantsState,
      recipientParticipantIds: conversation?.recipientParticipantIds
    });

    return (
      <MessageDetail101 //
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        // workspace info
        isWorkspaceLoading={isWorkspaceLoading}
        // profile info
        userName={name}
        timezone={timezone}
        // conversation info
        conversationId={conversationId}
        conversation={conversation}
        onConversationSelect={onConversationSelect}
        conversationParticipantsMap={participantMap}
        selectedConversationId={messengerConversationsState.realtimeUpdatedConversationId}
        dispatch={dispatch}
        sendingMessagingDisabledStatus={sendingMessagingDisabledStatus}
        readButtonDisabled={isParticipantArchived}
        subscriberNames={subscriberNames}
      />
    );
  }

  const isSendMessageDisabled: boolean = messagingDisabledSelector({
    participantId,
    workspaceBasicInfoState,
    workspaceParticipantsState,
    recipientParticipantIds: conversation?.recipientParticipantIds
  });

  return (
    <MessageDetail //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // workspace info
      isWorkspaceLoading={isWorkspaceLoading}
      // profile info
      userName={name}
      timezone={timezone}
      // conversation info
      conversationId={conversationId}
      conversation={conversation}
      onConversationSelect={onConversationSelect}
      conversationParticipantsMap={participantMap}
      selectedConversationId={messengerConversationsState.realtimeUpdatedConversationId}
      dispatch={dispatch}
      sendMessageDisabled={isSendMessageDisabled}
      readButtonDisabled={isParticipantArchived}
    />
  );
}

export default React.memo(MessageDetailContainer);
