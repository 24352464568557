// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { PriorityNoticeModel } from './models';
import yupApplicants from './sections/applicants/validationSchema';
import yupTransactionInstruments from './sections/transaction-instruments/validationSchema';

export default yup.object<PriorityNoticeModel>().shape({
  applicants: yupApplicants,
  transactionInstruments: yupTransactionInstruments
});
