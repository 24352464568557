import * as React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';

import converter from './conversion';
import { ApiTransferOfControlModel, TransferOfControlModel } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<TransferOfControlModel, ApiTransferOfControlModel>;

function DocumentForm(props: Props): JSX.Element {
  return (
    <DocumentFormWrapper //
      {...props}
      converter={converter}
      validationSchema={validationSchema}
      RootForm={RootForm}
    />
  );
}

export default React.memo(DocumentForm);
