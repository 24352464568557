import * as React from 'react';

import { getIn, useFormikContext } from 'formik';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';

import { Form24B_2_24_1Model, TransferorReferenceModel } from '../../models';
import { VISIBILITY_CHECK_CAPACITY, VISIBILITY_CHECK_CAPACITY_DETAIL } from '../../visibilityCheck';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;
interface Props {
  party: PartyModel;
  name: string;
}

function Transferor({ party, name }: Props) {
  const classes = useStyles();
  const displayName = party.organisationName;

  const formikProps = useFormikContext<FormValues>();
  const { values } = formikProps;
  const transferorReferenceModel: TransferorReferenceModel = getIn(values, name);

  return (
    <>
      <ReadOnlyField //
        label="Name"
        value={displayName}
      />
      <div className={classes.acnGroup}>
        {!!party.acn && (
          <ReadOnlyField //
            label="ACN"
            value={party.acn}
          />
        )}
        {!!party.abn && (
          <ReadOnlyField //
            label="ABN"
            value={party.abn}
          />
        )}
        {!!party.acln && (
          <ReadOnlyField //
            label="ACLN"
            value={party.acln}
          />
        )}
        {!!party.arbn && (
          <ReadOnlyField //
            label="ARBN"
            value={party.arbn}
          />
        )}
      </div>
      {VISIBILITY_CHECK_CAPACITY(transferorReferenceModel) && (
        <ReadOnlyField //
          label="Party capacity"
          value={`${transferorReferenceModel.partyCapacity?.capacity}`}
        />
      )}

      {VISIBILITY_CHECK_CAPACITY_DETAIL(transferorReferenceModel) && (
        <ReadOnlyField //
          label="Capacity details"
          value={transferorReferenceModel.partyCapacity?.details!}
        />
      )}
    </>
  );
}

export default React.memo(Transferor);
