import { SaNameChange, saPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/sa/2-26/components/party-justification';
import { SA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/sa/2-26/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const SA_DM_PARTY_FORM_CONFIG: PartyFormConfig<SaNameChange> = {
  ...SA_PARTY_FORM_CONFIG,
  nameChangeConfig: saPartyNameChangeConfig
};
