import endOfToday from 'date-fns/endOfToday';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-25/necds';
import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import {
  PropertyDetails$ConstructionOfMainStructureEnum,
  PropertyDetails$LandDimensions$KnownDetailsEnum,
  PropertyDetails$LandDimensions$LandArea$AreaUnitEnum,
  PropertyDetails$LandUseTypeEnum,
  PropertyDetails$MunicipalityNameEnum,
  PropertyDetails$PrimaryLandUseEnum,
  TransactionDetail$ConsiderationTypeEnum
} from './enums';

// this file was automatically generated from models.ts.mustache
export interface ApiNoticeOfAcquisitionDocumentModel {
  titleReferences: TitleReferenceModel[];
  addressBook?: BaseAddress[];
  elnRegistryInstrumentReference?: string;
  partyBook: ApiDocumentPartyModel[];
  arePropertiesSeparatelyRated: boolean;
  propertyDetails: NoaPropertyDetailModel[];
  transactionDetail: TransactionDetailModel;
  transfereeAgentSolicitorDetail: RepresentativeDetailModel;
  transferees: TransfereeDetailModel[];
  transferorRepresentativeDetail: RepresentativeDetailModel;
  transferors: TransferorDetailModel[];
  workspaceRoleId?: WorkspaceRoleEnum | null;
  workspaceTitleReferences?: string[];
}

export interface NoaPropertyDetailModel {
  constructionOfMainStructure: PropertyDetails$ConstructionOfMainStructureEnum | null; // number
  landDimensions: LandDimensionsModel;
  landUseType: PropertyDetails$LandUseTypeEnum | null; // number
  municipalityName: PropertyDetails$MunicipalityNameEnum | null; // number;
  municipalityPropertyNumber?: string;
  numberOfBedrooms: number | null;
  primaryLandUse: PropertyDetails$PrimaryLandUseEnum | null; // number
  propertyId: number;
  propertyStreetAddressBookId: string;
  salePricePerProperty?: number | null;
  titleReferences: Array<TitleReferenceModel | null>; // added null option for property combinator
}

export interface LandDimensionsModel {
  knownDetails: PropertyDetails$LandDimensions$KnownDetailsEnum | null; // number
  landArea?: LandAreaModel;
  unitOfEntitlement?: string;
}

export interface LandAreaModel {
  areaUnit: PropertyDetails$LandDimensions$LandArea$AreaUnitEnum | null; // number
  quantity: number | null;
}

export interface TitleReferenceModel {
  reference: string;
  landDescriptions?: LandDescriptionModel[];
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export interface TransactionDetailModel {
  businessGoodwillAmount: number | null;
  businessIntellectualPropertyAmount: number | null;
  considerationType: TransactionDetail$ConsiderationTypeEnum | null; // number
  contractDate?: Date | string | null;
  contractRebateAmount: number | null;
  dateOfPossessionTransfer?: Date | string | null;
  deposit?: number | null;
  goodsComponentAmount: number | null;
  gstAmount?: number | null;
  isPurchaseTermsSale: boolean;
  isTransferDateSameAsSettlementDate: boolean;
  marketValue: number | null;
  settlementDate: Date | string | null;
  totalSalePrice: number | null;
  valuerLicenceNumber?: string;
  valuerName?: string;
}

export interface RepresentativeDetailModel {
  contactPhoneNumber: string;
  emailAddress: string;
  name: string;
}

export interface TransfereeDetailModel {
  addressAtTimeOfTransferBookId: string;
  birthDate: Date | string | null; // it can be null when party type is organisation
  contactPhoneNumber?: string;
  correspondenceAddressBookId: string;
  emailAddress?: string;
  isLeadOwner: boolean;
  lrPartyCapacity?: NecdsPartyCapacityEnum | string | null;
  partyBookId: string;
  principalResidentialAddressBookId: string;
  shareFraction: FractionModel;
  sroPartyCapacity?: NecdsPartyCapacityEnum | string | null;
  partyCapacityDetail?: string;
  missingContactDetails?: any; // this field is used just for validation purposes
}

export interface FractionModel {
  denominator: number | null;
  id?: string;
  numerator: number | null;
}

export interface TransferorDetailModel {
  addressAtTimeOfTransferBookId: string;
  birthDate: Date | string | null; // it can be null when party type is organisation
  contactPhoneNumber?: string;
  correspondenceAddressBookId: string;
  emailAddress?: string;
  lrPartyCapacity?: NecdsPartyCapacityEnum | string | null;
  partyBookId: string;
  sroPartyCapacity?: NecdsPartyCapacityEnum | string | null;
  partyCapacityDetail?: string;
  missingContactDetails?: any; // this field is used just for validation purposes
}

export type NoticeOfAcquisitionDocumentModel = Omit<ApiNoticeOfAcquisitionDocumentModel, 'partyBook' | 'addressBook'> & {
  partyBook?: PartyModel[];
  addressBook?: AddressBookEntityModel[];
  transfereePrimaryContactPartyBookId: string | null;
  matter?: string;
  allTitlesAssigned?: boolean;
  isPopulatedDataFromTransferDocumentReady?: unknown; //This property use for validation only.
};

export interface UseCaseModel {
  title: string;
  data: object;
  workspaceDetail?: {
    workspaceRoleId?: WorkspaceRoleEnum | null;
    reference?: string;
  };
  currentParticipant?: {
    isResponsibleParticipant: boolean;
  };
  localStorageIndex?: number;
}

export const EMPTY_ARRAY = []; // non editable empty array used as a fallback only

export const END_OF_TODAY = endOfToday(); // transferee could have been born today
