import * as React from 'react';

import classNames from 'classnames';
import Fade from '@mui/material/Fade';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconMessengerClose, IconMessengerOpen } from 'src/components/layout/v2/Icons';
import styles, { ClassKeys } from './styles';
import MessageDetail from './views/message-detail';
import MessageList from './views/message-list';

export interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  // conversations
  newMessageCount: number;
  // pre-open messenger
  messengerOpen?: boolean;
  hasItems?: boolean;
  // other
  className?: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  selectedConversationId?: string;
}

class Messenger extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    anchorEl: null,
    isOpen: false
  };
  private messengerButtonRef = React.createRef<HTMLButtonElement>();

  componentDidMount() {
    if (this.props.messengerOpen) {
      this.setState({ anchorEl: this.messengerButtonRef.current, isOpen: true });
    }
  }

  render() {
    return this.renderIcon();
  }

  private renderIcon() {
    const { classes, newMessageCount, className } = this.props;

    const iconClass = 'h-[28px] w-[28px] large-screen:h-[40px] large-screen:w-[40px]';
    return (
      <>
        <IconButton
          //
          ref={this.messengerButtonRef}
          className={classNames(classes.toggleButton2, className)}
          aria-label={`${this.state.isOpen ? 'close' : 'open'} messenger`}
          onClick={this.handleOnToggleButtonClick}
          size="large"
        >
          <div className={classNames(classes.circle, 'h-[48px] w-[48px] large-screen:h-[72px] large-screen:w-[72px]')}>
            {
              <div className={classes.messengerOpen}>
                {!this.state.isOpen && newMessageCount !== 0 && <div className={classNames(classes.redDot, 'h-[8px] w-[8px] large-screen:h-[10px] large-screen:w-[10px]')}></div>}
                {this.state.isOpen ? <IconMessengerClose className={iconClass} /> : <IconMessengerOpen className={iconClass} />}
              </div>
            }
          </div>
        </IconButton>
        {this.renderMessengerDialog2()}
      </>
    );
  }

  private renderMessengerDialog() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    return (
      <Popper open={this.state.isOpen} anchorEl={anchorEl} transition className={classes.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper className={classes.paper}>
              <IconButton className={classes.closeButton} onClick={this.handleOnCloseClick} aria-label="Close messenger" size="large">
                <Icon className={classes.closeIcon}>close</Icon>
              </IconButton>
              {this.renderContent()}
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  }

  private renderMessengerDialog2() {
    const { classes, hasItems } = this.props;
    const { anchorEl } = this.state;
    return (
      <Popper
        open={this.state.isOpen}
        anchorEl={anchorEl}
        placement="left-end"
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -12]
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper className={classes.paper2}>
              {hasItems && (
                <IconButton className={classes.closeButton} onClick={this.handleOnCloseClick} aria-label="Close messenger" size="large">
                  <Icon className={classes.closeIcon}>close</Icon>
                </IconButton>
              )}
              {this.renderContent()}
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  }

  private renderContent() {
    const { workspaceId, participantId } = this.props;
    const { selectedConversationId } = this.state;
    if (selectedConversationId) {
      return (
        <MessageDetail
          //
          workspaceId={workspaceId}
          participantId={participantId}
          conversationId={selectedConversationId}
          onConversationSelect={this.handleOnConversationSelect}
        />
      );
    }
    return <MessageList workspaceId={workspaceId} participantId={participantId} onConversationSelect={this.handleOnConversationSelect} />;
  }

  private handleOnToggleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anchorEl = this.state.anchorEl ?? (event.currentTarget as HTMLButtonElement);

    //need to use .currentTarget here since .target is the element which initiated the event, not the element the event listener
    //is attached to (we need to anchor to the messenger button, not the unread message number badge which will disappear once all messages are read)
    this.setState(prevState => ({ anchorEl, isOpen: !prevState.isOpen }));
  };

  private handleOnConversationSelect = (selectedConversationId?: string) => {
    this.setState({ selectedConversationId });
  };

  private handleOnCloseClick = () => {
    this.setState({ isOpen: false });
  };
}

export default withStyles(styles)(Messenger);
