import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { SubscriberElnEnum } from './enums';
import { TransferOfControlModel } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<TransferOfControlModel> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        volume: NO_FALLBACK,
        folio: NO_FALLBACK
      }
    }
  },
  applicant: {
    $children: {
      partyBookId: ['']
    }
  },
  recipient: {
    $children: {
      subscriberElno: [SubscriberElnEnum.Sympli],
      partyBookId: [''],
      addressBookId: [''],
      votsId: [''],
      email: [''],
      subscriberId: [''],
      subscriberName: ['']
    }
  }
};
