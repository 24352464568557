import { createSelector } from 'reselect';

import { DirectionCategoryEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsApiResponse, WorkspaceDirectionsOverviewApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { getExpectedSympliSourceAccountAmount, getVerifiedSympliSourceAccountAmount } from 'src/containers/workspace/financial/directions/helpers';
import { SourceFundsStatusEnum } from 'src/containers/workspace/financial/directions/views/edit-directions/components/source-funds-dialog/models';
import { Store } from 'src/reducers';
import { ErrorVariantEnum } from '../../../detail/components/error-message-panel/models';
import { calSum } from '../../helpers';
import { DirectionsTrustAccountModel, TrustAccountOptionModel } from '../../models';

interface OtherPartiesDirectionsSummaryCalcModel {
  workspaceRole: WorkspaceRoleEnum;
  directionsSummary: WorkspaceDirectionsApiResponse['directionsSummary'];
  sourceFunds: WorkspaceDirectionsApiResponse['sourceFunds'];
  directions: WorkspaceDirectionsApiResponse['directions'];
  initialLoanAdvanceAmount?: number;
}

export const otherPartiesDirectionsSummarySelector = createSelector<
  OtherPartiesDirectionsSummaryCalcModel,
  WorkspaceRoleEnum,
  WorkspaceDirectionsApiResponse['directionsSummary'],
  WorkspaceDirectionsApiResponse['sourceFunds'],
  WorkspaceDirectionsApiResponse['directions'],
  number | undefined,
  WorkspaceDirectionsApiResponse['directionsSummary']
>(
  (values: OtherPartiesDirectionsSummaryCalcModel) => values.workspaceRole,
  (values: OtherPartiesDirectionsSummaryCalcModel) => values.directionsSummary,
  (values: OtherPartiesDirectionsSummaryCalcModel) => values.sourceFunds,
  (values: OtherPartiesDirectionsSummaryCalcModel) => values.directions,
  (values: OtherPartiesDirectionsSummaryCalcModel) => values.initialLoanAdvanceAmount,
  (workspaceRole, directionsSummary, sourceFunds, directions, initialLoanAdvanceAmount): WorkspaceDirectionsApiResponse['directionsSummary'] => {
    // TODO we will need to separate directions and sourceFunds for IM/DM/P/V soon
    // * directionsSummary: TOTAL directionsSummary for this workspace
    // * sourceFunds: sourceFunds rendered/controlled in the Form
    // * distributions: distributions rendered/controlled in the Form
    switch (workspaceRole) {
      case WorkspaceRoleEnum.Purchaser:
        // * Need to filter directionCategory because Purchaser directions Form contains :
        // * - [Purchaser distributions] and,
        // * - [Settlement distributions]
        return {
          ...directionsSummary,
          purchaserDirections: directionsSummary.purchaserDirections - calSum(directions.filter(item => item.directionCategory === DirectionCategoryEnum.Purchaser)),
          purchaserSourceFunds: directionsSummary.purchaserSourceFunds - calSum(sourceFunds),
          settlementDirections: directionsSummary.settlementDirections - calSum(directions.filter(item => item.directionCategory === DirectionCategoryEnum.Settlement)),
          loanAdvanceRequiredAmount: 0
        };
      case WorkspaceRoleEnum.IncomingMortgagee:
        return {
          ...directionsSummary,
          purchaserDirections: directionsSummary.purchaserDirections - calSum(directions.filter(item => item.directionCategory === DirectionCategoryEnum.Purchaser)),
          settlementDirections: directionsSummary.settlementDirections - calSum(directions.filter(item => item.directionCategory === DirectionCategoryEnum.Settlement)),
          loanAdvanceRequiredAmount: 0,
          calculatedLoanAdvanceAmount: directionsSummary.calculatedLoanAdvanceAmount - (initialLoanAdvanceAmount || 0),
          incomingMortgageeSourceFunds: directionsSummary.incomingMortgageeSourceFunds - calSum(sourceFunds)
        };
      case WorkspaceRoleEnum.Vendor:
      case WorkspaceRoleEnum.DischargingMortgagee:
      default:
        // * DO NOT need to filter directionCategory because Vendor/DischargingMortgagee directions Form
        // * - ONLY contains [Settlement distributions]
        return {
          ...directionsSummary,
          settlementDirections: directionsSummary.settlementDirections - calSum(directions),
          vendorSourceFunds: directionsSummary.vendorSourceFunds - calSum(sourceFunds),
          loanAdvanceRequiredAmount: 0
        };
    }
  }
);

interface DirectionsSummaryCalcModel {
  workspaceRole: WorkspaceRoleEnum;
  otherPartiesSummary: WorkspaceDirectionsApiResponse['directionsSummary'];
  sourceFunds: WorkspaceDirectionsApiResponse['sourceFunds'];
  distributions: WorkspaceDirectionsApiResponse['directions'];
  purchaserPayAmount?: number;
  loanAdvanceRequiredAmount?: number;
  editingLoanAdvanceAmount?: number;
}

const getWorkspaceRoleType = (values: DirectionsSummaryCalcModel) => values.workspaceRole;
const getOtherPartiesSummary = (values: DirectionsSummaryCalcModel) => values.otherPartiesSummary;
const getSourceFunds = (values: DirectionsSummaryCalcModel) => values.sourceFunds;
const getDistributions = (values: DirectionsSummaryCalcModel) => values.distributions;
const getPurchaserPayAmount = (values: DirectionsSummaryCalcModel) => values.purchaserPayAmount;
const getLoanAdvanceRequiredAmount = (values: DirectionsSummaryCalcModel) => values.loanAdvanceRequiredAmount;

const getEditingLoanAdvanceAmount = (values: DirectionsSummaryCalcModel) => values.editingLoanAdvanceAmount;

export const directionsSummarySelector = createSelector<
  DirectionsSummaryCalcModel,
  WorkspaceRoleEnum,
  WorkspaceDirectionsApiResponse['directionsSummary'],
  WorkspaceDirectionsApiResponse['sourceFunds'],
  WorkspaceDirectionsApiResponse['directions'],
  number | undefined,
  number | undefined,
  number | undefined,
  WorkspaceDirectionsApiResponse['directionsSummary']
>(
  getWorkspaceRoleType,
  getOtherPartiesSummary,
  getSourceFunds,
  getDistributions,
  getPurchaserPayAmount,
  getLoanAdvanceRequiredAmount,
  getEditingLoanAdvanceAmount,
  (
    workspaceRole,
    otherPartiesSummary,
    sourceFunds,
    distributions,
    purchaserPayAmount = otherPartiesSummary.purchaserPayAmount,
    loanAdvanceRequiredAmount = 0,
    editingLoanAdvanceAmount = 0
  ): WorkspaceDirectionsApiResponse['directionsSummary'] => {
    // TODO we will need to separate directions and sourceFunds for IM/DM/P/V soon
    // * otherPartiesSummary: directionsSummary WITHOUT sum(distributions) and sum(sourceFunds) from Form
    // * sourceFunds: sourceFunds rendered/controlled in the Form
    // * distributions: distributions rendered/controlled in the Form
    switch (workspaceRole) {
      case WorkspaceRoleEnum.Purchaser:
        // * Need to filter directionCategory because Purchaser directions Form contains :
        // * - [Purchaser distributions] and,
        // * - [Settlement distributions]
        return {
          ...otherPartiesSummary,
          purchaserPayAmount,
          purchaserSourceFunds: otherPartiesSummary.purchaserSourceFunds + calSum(sourceFunds),
          purchaserDirections:
            otherPartiesSummary.purchaserDirections + calSum(distributions.filter(distribution => distribution.directionCategory === DirectionCategoryEnum.Purchaser)),
          settlementDirections:
            otherPartiesSummary.settlementDirections + calSum(distributions.filter(distribution => distribution.directionCategory === DirectionCategoryEnum.Settlement)),
          loanAdvanceRequiredAmount: otherPartiesSummary.loanAdvanceRequiredAmount + loanAdvanceRequiredAmount,
          calculatedLoanAdvanceAmount: otherPartiesSummary.calculatedLoanAdvanceAmount + editingLoanAdvanceAmount,
          incomingMortgageeSourceFunds: otherPartiesSummary.incomingMortgageeSourceFunds
        };
      case WorkspaceRoleEnum.IncomingMortgagee:
        return {
          ...otherPartiesSummary,
          purchaserPayAmount,
          purchaserSourceFunds: otherPartiesSummary.purchaserSourceFunds,
          purchaserDirections:
            otherPartiesSummary.purchaserDirections + calSum(distributions.filter(distribution => distribution.directionCategory === DirectionCategoryEnum.Purchaser)),
          settlementDirections:
            otherPartiesSummary.settlementDirections + calSum(distributions.filter(distribution => distribution.directionCategory === DirectionCategoryEnum.Settlement)),
          loanAdvanceRequiredAmount: otherPartiesSummary.loanAdvanceRequiredAmount + loanAdvanceRequiredAmount,
          calculatedLoanAdvanceAmount: otherPartiesSummary.calculatedLoanAdvanceAmount + editingLoanAdvanceAmount,
          incomingMortgageeSourceFunds: otherPartiesSummary.incomingMortgageeSourceFunds + calSum(sourceFunds)
        };
      case WorkspaceRoleEnum.Vendor:
      case WorkspaceRoleEnum.DischargingMortgagee:
      default:
        // * DO NOT need to filter directionCategory because Vendor/DischargingMortgagee directions Form
        // * - ONLY contains [Settlement distributions]
        return {
          ...otherPartiesSummary,
          purchaserPayAmount,
          vendorSourceFunds: otherPartiesSummary.vendorSourceFunds + calSum(sourceFunds),
          settlementDirections: otherPartiesSummary.settlementDirections + calSum(distributions),
          loanAdvanceRequiredAmount,
          calculatedLoanAdvanceAmount: otherPartiesSummary.calculatedLoanAdvanceAmount,
          incomingMortgageeSourceFunds: otherPartiesSummary.incomingMortgageeSourceFunds
        };
    }
  }
);

export const trustAccountOptionsSelector = createSelector<
  //
  WorkspaceDirectionsApiResponse | WorkspaceDirectionsOverviewApiResponse,
  DirectionsTrustAccountModel[],
  {
    trustAccountOptions: TrustAccountOptionModel[];
    trustAccountBankDetailMap: Record<string, DirectionsTrustAccountModel>;
  }
>(
  (detail: WorkspaceDirectionsApiResponse | WorkspaceDirectionsOverviewApiResponse) => detail.trustAccounts,
  (trustAccounts: Array<DirectionsTrustAccountModel>) => {
    const trustAccountOptions = trustAccounts.map(({ id, bankDetails, canUseAsLoanPayout, canUseAsLoanAdvance }) => ({
      id, //
      name: bankDetails.accountDescription || bankDetails.accountName,
      canUseAsLoanPayout,
      canUseAsLoanAdvance
    }));
    const trustAccountBankDetailMap: Record<string, DirectionsTrustAccountModel> = trustAccounts.reduce(
      (detailMap: Record<string, DirectionsTrustAccountModel>, currentValue: DirectionsTrustAccountModel) => {
        detailMap[currentValue.id] = currentValue;
        return detailMap;
      },
      {}
    );
    return { trustAccountOptions, trustAccountBankDetailMap };
  }
);

export const getDirectionForm = <T extends Pick<Store, 'directionsBreakdown'>>(state: T): WorkspaceDirectionsApiResponse | undefined => state.directionsBreakdown.detail;

export const sympliSourceFundCalculatorMessageSelector = createSelector<
  {
    participantId: string;
    workspaceParticipants: Store['workspaceParticipants'];
    directionsBreakdown: Store['directionsBreakdown'];
  },
  WorkspaceParticipantApiResponse | undefined,
  WorkspaceDirectionsApiResponse | undefined,
  {
    warningMessage?: string;
    buttonLinkText?: string;
    sourceFundStatus: SourceFundsStatusEnum;
    variant: ErrorVariantEnum;
  }
>(
  //
  args =>
    findCurrentWorkspaceParticipantSelector({
      participantId: args.participantId,
      workspaceParticipants: args.workspaceParticipants
    }),
  args => args.directionsBreakdown.detail,
  (currentParticipant: WorkspaceParticipantApiResponse | undefined, detail: WorkspaceDirectionsApiResponse | undefined) => {
    let warningMessage: string | undefined;
    let buttonLinkText: string | undefined;
    let sourceFundStatus: SourceFundsStatusEnum = SourceFundsStatusEnum.Surplus;
    let variant = ErrorVariantEnum.Warning;
    if (detail && (currentParticipant?.workspaceRole.id === WorkspaceRoleEnum.Vendor || currentParticipant?.workspaceRole.id === WorkspaceRoleEnum.Purchaser)) {
      const { trustAccountBankDetailMap } = trustAccountOptionsSelector(detail);
      const verifiedAmount = getVerifiedSympliSourceAccountAmount(detail.trustAccounts);
      const expectedAmount = getExpectedSympliSourceAccountAmount(detail.sourceFunds, trustAccountBankDetailMap);
      if (verifiedAmount && expectedAmount !== null) {
        const sourceBalance = Number(verifiedAmount) - Number(expectedAmount);
        if (sourceBalance > 0) {
          warningMessage = `You have ${currency(sourceBalance)} surplus available in your Sympli source account.`;
          buttonLinkText = 'Add surplus to distributions';
          sourceFundStatus = SourceFundsStatusEnum.Surplus;
          variant = ErrorVariantEnum.Warning;
        } else if (sourceBalance !== 0) {
          warningMessage = `You have a shortfall of ${currency(Math.abs(sourceBalance))} in your Sympli source account.`;
          // TODO: enable download deposit form once ready
          // buttonLinkText = 'Download deposit form';
          sourceFundStatus = SourceFundsStatusEnum.ShortFall;
          variant = ErrorVariantEnum.Error;
        }
      }
    }

    return {
      warningMessage,
      buttonLinkText,
      sourceFundStatus,
      variant
    };
  }
);
