import * as React from 'react';

import { Form, FormikProps } from 'formik';
import { batch } from 'react-redux';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import MessageList from '@sympli/ui-framework/components/message-notification/components/message-list';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import Formik from 'src/components/formik';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { collectInvitations } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/helpers';
import { WorkspaceInviteParticipantsFormModel } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/models';
import AdditionalInvitations from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/invite-participants/components/additional-invitations';
import Invitations from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/invite-participants/components/invitations';
import { getAdditionalParticipantRoleConfigs } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/invite-participants/helpers';
import { getRepresentationSubscriberType } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/matter-detail/helpers';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { actionFetchWorkspaceDocuments, actionUpdateParticipantList } from 'src/containers/workspace/shared/detail/actions';
import { WorkspaceInvitableRoleDetailApiResponse } from 'src/containers/workspace/shared/detail/reducers/workspaceInvitation';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { resolveFinancialRoleType } from 'src/models';
import { ApiStatus } from 'src/utils/http';
import { actionFetchAllDirections } from '../../../all-directions/actions';
import { getInitialValues } from './initialValues';
import { ADDITIONAL_INVITATION_TOAST_MESSAGE, UpdateInvitationParticipantsApiRequest } from './models';
import styles, { ClassKeys } from './styles';
import getValidationSchema from './validationSchema';

export interface OwnProps {
  isInteroperable?: boolean;
  jurisdictionId: JurisdictionsEnum;
  workspaceId: string;
  participantId: string;
  currentSubscriber: UserProfileModel;
  currentWorkspaceRoleId: WorkspaceRoleEnum;
  openDialog: boolean;
  onClose: () => void;
  detail?: WorkspaceInvitableRoleDetailApiResponse;
  status: ApiStatus;
  error?: string;
  dispatch: SafeDispatch<Action>;
  workspaceType: WorkspaceTypeEnum;
  currentParticipantGroupId?: string;
  currentParticipantReference?: string;
  assignableGroups: GroupOptionModel[];

  // list of current workspace participants
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  invitationSubscriberSearch: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class InviteAdditionalParticipants extends React.PureComponent<Props> {
  // ! for the release version2.0 and ticket web-5021 by default we will select all vendors

  render() {
    const { classes, openDialog, onClose } = this.props;
    return (
      <ConfirmationDialog
        title={this.renderTitle()}
        classes={{ root: classes.root, dialogPaper: classes.dialogPaper, dialogTitle: classes.dialogTitle, dialogContent: classes.dialogContent }}
        open={openDialog}
        onClose={onClose}
        showActionButtons={false}
        scroll="body"
      >
        <Divider className={classes.divider} />
        {this.renderDialogContent()}
      </ConfirmationDialog>
    );
  }

  private renderTitle() {
    const { classes } = this.props;
    return (
      <FlexLayout alignItems="center">
        <Typography variant="subtitle1">Workspace roles</Typography>
        <Typography //
          className={classes.description}
        >
          Invite others to fill these roles or assume them yourself.
        </Typography>
      </FlexLayout>
    );
  }

  private renderDialogContent() {
    const {
      workspaceId,
      participantId,
      status,
      detail,
      error,
      currentSubscriber,
      currentWorkspaceRoleId,
      currentParticipantGroupId = '',
      currentParticipantReference = '',
      workspaceParticipants
    } = this.props;

    if (status === 'pending') {
      return <BlockLoader />;
    }
    if (status === 'rejected') {
      return error;
    }
    if (detail == null) {
      return null;
    }
    const { invitableRoles, allowedAdditionalParticipantRoles } = detail;

    const additionalParticipantRoleConfigs = getAdditionalParticipantRoleConfigs(allowedAdditionalParticipantRoles);
    const validationSchema = getValidationSchema(currentWorkspaceRoleId, currentSubscriber, additionalParticipantRoleConfigs, workspaceParticipants);

    return (
      <Formik //
        method="post"
        action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/financial/additional-invitations`}
        initialValues={getInitialValues(invitableRoles, currentParticipantGroupId, currentParticipantReference)}
        validationSchema={validationSchema}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
      >
        {(formikProps: FormikProps<WorkspaceInviteParticipantsFormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private renderForm(formikProps: FormikProps<WorkspaceInviteParticipantsFormModel>) {
    const { detail, onClose } = this.props;
    const { isValid, isSubmitting } = formikProps;
    const invitationSelected =
      Object.keys(formikProps.values.invitedRoles).some(role => formikProps.values.invitedRoles[role]) ||
      Boolean(formikProps.values.additionalInvitations.length && formikProps.values.additionalInvitations.every(additionalInvitation => additionalInvitation.role !== undefined));
    const disabled = isSubmitting || !detail?.invitableRoles.length || !isValid || !invitationSelected;

    return (
      <Form>
        {this.renderInvitations()}
        <WizardStepper onBack={onClose} nextLabel="Continue" showBackIcon showNextIcon disabled={disabled} isLoading={isSubmitting} />
      </Form>
    );
  }

  private renderInvitations = () => {
    const {
      detail,
      isInteroperable,
      jurisdictionId,
      classes,
      assignableGroups: forwardableGroups,
      currentParticipantGroupId = '',
      currentParticipantReference = '',
      invitationSubscriberSearch,
      currentSubscriber
    } = this.props;
    if (detail == null) {
      return null;
    }

    const representationSubscriberType = getRepresentationSubscriberType(invitationSubscriberSearch, currentSubscriber.subscriberOrganisationType);

    if (detail.invitableRoles.length === 0) {
      return <Typography className={classes.greyText}>All available invitations have been sent</Typography>;
    }

    return (
      <>
        {detail.invitableRoles.map(roleId => {
          return (
            <Invitations //
              isInteroperable={isInteroperable}
              jurisdictionId={jurisdictionId}
              roleId={roleId}
              key={`invitations-${roleId}`}
              groups={forwardableGroups}
              representationSubscriberType={representationSubscriberType}
            />
          );
        })}
        <AdditionalInvitations //
          isInteroperable={isInteroperable}
          jurisdictionId={jurisdictionId}
          groups={forwardableGroups}
          allowedRoleIds={detail.invitableRoles}
          currentParticipantGroupId={currentParticipantGroupId}
          currentParticipantReference={currentParticipantReference}
          representationSubscriberType={representationSubscriberType}
        />
      </>
    );
  };

  private handleOnPreSubmit = (values: WorkspaceInviteParticipantsFormModel): UpdateInvitationParticipantsApiRequest => ({
    invitations: collectInvitations(values, this.props.currentSubscriber)
  });

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<WorkspaceInviteParticipantsFormModel, WorkspaceParticipantApiResponse[]>) => {
    if (args.error) {
      return;
    }
    const {
      workspaceId,
      participantId,
      dispatch,
      onClose,
      currentSubscriber: { subscriberName }
    } = this.props;
    const { formValues } = args;
    const { invitedRoles, additionalInvitations } = formValues;

    const invitationToastMessages: string[] = Object.keys(invitedRoles)
      .filter(role => invitedRoles[role])
      .reduce((result: string[], roleId: string) => {
        const roleType: WorkspaceRoleEnum = Number(roleId);
        const { invitation } = formValues.invitations.find(x => x.role === roleType)!;
        const {
          subscriber: { name },
          inviteRoleAsSelf
        } = invitation;

        const toastMessage = ADDITIONAL_INVITATION_TOAST_MESSAGE(inviteRoleAsSelf ? subscriberName : name, resolveFinancialRoleType(roleType));
        result.push(toastMessage);
        return result;
      }, []);

    const additionalInvitationsToastMessage: string[] = additionalInvitations.map(
      ({
        role,
        invitation: {
          subscriber: { name },
          inviteRoleAsSelf
        }
      }) => ADDITIONAL_INVITATION_TOAST_MESSAGE(inviteRoleAsSelf ? subscriberName : name, resolveFinancialRoleType(role!))
    );
    const allInvitationsToastMessage: string[] = [...invitationToastMessages, ...additionalInvitationsToastMessage];
    batch(() => {
      if (allInvitationsToastMessage.length) {
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: 'New roles have been added into the workspace ',
            autoHideDuration: 5000,
            expandableContent: <MessageList messages={allInvitationsToastMessage} />,
            variant: 'success'
          })
        );
      }
      dispatch(actionUpdateParticipantList({ participants: args.response, workspaceId }));
      dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
      dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
    });
    onClose();
  };
}

const styledComponent = withStyles(styles)(InviteAdditionalParticipants);

export default styledComponent;
