import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    groupSelectionField: {
      marginRight: '15px'
    },
    error: {
      color: theme.palette.error.dark,
      '& svg': {
        fill: theme.palette.error.dark
      },
      width: '90%'
    },
    searchInviteSubscriber: {
      display: 'block',
      width: '85%'
    }
  }),
  {
    name: 'GroupAndMatterNumber'
  }
);
