import * as React from 'react';

import { BundleType, JurisdictionsEnum, LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDetailApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { MessageNotificationContainer } from '@sympli/ui-framework/components/message-notification';
import { useLocalStorageState } from '@sympli/ui-framework/hooks';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { Header, Main } from 'src/components/layout';
import OrderTitleConfirmationDialog from './components/order-title-confirmation-dialog';
import RisNotificationMessage from './components/ris-notification-message';
import TitleDetail from './components/title-detail';
import TitleTopPanel from './components/title-top-panel';

interface Props {
  // route params
  workspaceId: string;
  participant: { id: string; subscriberName: string };
  titleReference: string;

  workspaceDetail: {
    tacKey?: string;
    isLocked?: boolean;
    supportTitleActivityCheck?: boolean;
    jurisdiction?: LookupEnumModel<JurisdictionsEnum>;
    lodgementCaseStatusId?: LodgementCaseStatusEnum;
    workspaceStatus?: LookupEnumModel<WorkspaceStatusEnum>;
  };
  // data about selected title
  titleDetail?: WorkspaceDetailApiResponse['titleReferences'][number];
  // profile info
  user: { id: string; name: string };
  // other
  isLoading: boolean;
  refetching: boolean;
  error?: string;
  isLoadingActivitiesCheck: boolean;
  tacOnClick(): void;
  onBack(): void;
  onTitleActivitiesCheck(): void;
}

function PageWrapper({ topPanel, children }: React.PropsWithChildren<{ topPanel: React.ReactNode }>) {
  return (
    <FlexLayout flexDirection="column" fullWidth fullHeight>
      {topPanel}
      <Main>{children}</Main>
    </FlexLayout>
  );
}

function Titles({
  //
  workspaceId,
  participant,
  titleReference,
  // redux data
  workspaceDetail: { jurisdiction, supportTitleActivityCheck, isLocked, tacKey, lodgementCaseStatusId, workspaceStatus },
  titleDetail,
  user,
  // other
  isLoading,
  refetching,
  isLoadingActivitiesCheck,
  onTitleActivitiesCheck,
  onBack,
  tacOnClick
}: Props) {
  const titleId = titleDetail?.landRegistryDetail.reference;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [showWarning, setShowWarning] = useLocalStorageState(`${tacKey}/show`, true);

  const titleResupplyDisabledCheck =
    isLoadingActivitiesCheck ||
    refetching ||
    lodgementCaseStatusId === LodgementCaseStatusEnum.Registered ||
    lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementSuccess ||
    workspaceStatus?.id === WorkspaceStatusEnum.Success;

  const showWarningHandler = () => {
    setShowWarning(!showWarning);
  };

  const disabledActivitiesCheck = React.useMemo(() => {
    if (isLoadingActivitiesCheck || refetching || titleId === undefined) {
      return true;
    }

    if (isLocked || !supportTitleActivityCheck || titleDetail?.landRegistryDetail.bundleType !== BundleType.LSS1) {
      return true;
    }

    const titleActivityCheckResult = titleDetail?.titleActivityCheckResult;
    if (!titleActivityCheckResult) {
      return false;
    }

    return !titleActivityCheckResult.canCheckActivity;
  }, [isLoadingActivitiesCheck, refetching, titleId, isLocked, supportTitleActivityCheck, titleDetail?.landRegistryDetail.bundleType, titleDetail?.titleActivityCheckResult]);

  const topPanel: React.ReactNode = (
    <TitleTopPanel
      onShowWarningClick={showWarningHandler}
      showWarningLink={(!showWarning && titleDetail?.titleActivityCheckResult?.hasChanges) ?? false}
      titleResupplyDisabled={titleResupplyDisabledCheck}
      titleResupplyOnNext={() => {
        setIsConfirmDialogOpen(true);
      }}
      tacIsLoading={isLoadingActivitiesCheck}
      tackIsDisabled={disabledActivitiesCheck}
      tacOnClick={tacOnClick}
    />
  );

  return (
    <PageWrapper //
      topPanel={topPanel}
    >
      <Header>Title information</Header>
      {/* parent component takes care of title detail fetch error */}
      {isLoading || refetching ? (
        <div style={{ height: 200 }}>
          <BlockLoader />
        </div>
      ) : (
        <>
          <MessageNotificationContainer>
            <RisNotificationMessage titleReference={titleDetail!} userId={user.id} participantId={participant.id} />
          </MessageNotificationContainer>

          <OrderTitleConfirmationDialog //
            workspaceId={workspaceId}
            participantId={participant.id}
            ris={{ reference: titleReference, propertyAddress: titleDetail?.landRegistryDetail.propertyAddress }}
            open={isConfirmDialogOpen}
            onClose={() => {
              setIsConfirmDialogOpen(false);
            }}
          />
          <TitleDetail
            onShowWarningClick={showWarningHandler}
            showWarning={showWarning}
            jurisdiction={jurisdiction}
            detail={titleDetail}
            workspaceId={workspaceId}
            user={user}
            participant={participant}
            onBack={onBack}
            isLoadingActivitiesCheck={isLoadingActivitiesCheck}
            disabledActivitiesCheck={disabledActivitiesCheck}
            disabledInformationResupply={titleResupplyDisabledCheck}
            onTitleActivitiesCheck={onTitleActivitiesCheck}
            onTitleInformationResupply={() => {
              setIsConfirmDialogOpen(true);
            }}
          />
        </>
      )}
    </PageWrapper>
  );
}

export default Titles;
