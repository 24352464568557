import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';

import { ReferenceParty, Result } from './models';

export default class PartyReferencer {
  /**
   * Copies the referenced party with a new Id, External and updated Metadata
   */
  private static reference<T extends ReferenceParty>(referencedParty: T): T {
    let newParty: T = {
      ...referencedParty,
      id: resolveUuid('string', 20),
      externalId: undefined,
      metadata: { ...referencedParty.metadata, source: DataSource.Custom, addedFrom: DataSource.Custom, partyBookId: referencedParty.id }
    };
    return newParty;
  }

  /**
   * Reassigns party references that use title party information
   * @param partyBook
   * @param partyReferences - Parties which can reference title parties
   * @returns updated party book with new custom parties
   */
  public static adjustReferences<TParty extends ReferenceParty, TReference extends TenancyPartyModel>(
    partyBook: TParty[],
    partyReferences: TReference[]
  ): Result<TParty, TReference> {
    const titleParties = partyBook.filter(pb => pb.metadata?.source === DataSource.Title);
    let newParties: TParty[] = [];
    for (let partyReference of partyReferences) {
      const referenced = titleParties.find(tp => tp.id === partyReference.partyBookId);
      if (referenced) {
        const result = this.reference(referenced);
        newParties.push(result);
      }
    }

    const updatedReferences = partyReferences.map(pr => {
      const newParty = newParties.find(np => np.metadata!.partyBookId === pr.partyBookId);
      return { ...pr, partyBookId: newParty ? newParty.id : pr.partyBookId };
    });

    return new Result([...partyBook, ...newParties], updatedReferences);
  }
}
