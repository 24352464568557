import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

// this file was automatically generated from components/styles.ts.mustache
export const useStyles = makeStyles(
  () => ({
    root: {
      ...dataBindingHelper()
    },
    quarterWidth: {
      width: '25%'
    }
  }),
  {
    name: 'TransferorDetail'
  }
);
