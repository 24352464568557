import React, { useCallback } from 'react';

import { Form, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconDoorOpen } from '@sympli/ui-framework/icons';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { resolveCanWithdrawLinkedWorkspace } from 'src/containers/shared/helper';
import { useLinkedWorkspaces } from 'src/containers/shared/linked-workspace-list/reducer';
import { useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { modelKey } from 'src/utils/formUtils';
import { actionFetchWorkspaceBasicInfo, actionFetchWorkspaceById } from '../../detail/actions';
import { resolveWorkspaceDetailLink } from '../../detail/helpers';
import { isAbandonReasonOther } from './helpers';
import { ABANDON_WORKSPACE_REASON_OPTIONS, AbandonReasonEnum, AbandonWorkspaceApiModel, AbandonWorkspaceFormikModel, AbandonWorkspaceReasonNameMap } from './model';
import { useStyles } from './styles';
import { getValidationSchema } from './validationSchema';
import { ABANDON_DESCRIPTION_VISIBILITY_CHECK } from './visibilityCheck';

interface AbandonLodgementOnlyDialogProps {
  // route params
  workspaceId: string;
  participantId: string;
  // redux
  workspaceTypeId?: WorkspaceTypeEnum;
  // dialog
  open: boolean;
  onClose(): void;
  // other
  defaultReason?: AbandonReasonEnum;
}

const fieldName = modelKey<AbandonWorkspaceFormikModel>();

function AbandonLodgementOnlyDialog({
  //
  workspaceId,
  participantId,
  workspaceTypeId,
  open,
  onClose,
  defaultReason
}: AbandonLodgementOnlyDialogProps): JSX.Element {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { pathname, search } = useLocation();
  const workspaceOverviewLink: string = resolveWorkspaceDetailLink({ workspaceId, participantId });
  const linkedWorkspaces = useLinkedWorkspaces(workspaceId).items;
  const { subscriberId } = useProfile().data!;

  const { canWithdrawLinkedWorkspace, isWithdraw } = resolveCanWithdrawLinkedWorkspace(subscriberId, workspaceId, linkedWorkspaces);

  const renderTitle = () => {
    return (
      <FlexLayout alignItems="center">
        <IconDoorOpen className={classes.headingIcon} />
        <span className={classes.headingText}>Abandon workspace</span>
      </FlexLayout>
    );
  };

  const handleOnPreSubmit = (values: AbandonWorkspaceFormikModel): AbandonWorkspaceApiModel => {
    const { reasonOption, reasonDescription, isApplyToLinkedWorkspace } = values;
    const reason = isAbandonReasonOther(reasonOption) ? reasonDescription! : AbandonWorkspaceReasonNameMap[reasonOption!];

    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'abandon-workspace',
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!],
      reason
    });
    return {
      reason,
      isApplyToLinkedWorkspace
    };
  };

  const handleOnPostSubmit = ({ error }: FormikPostSubmitArgs<AbandonWorkspaceFormikModel>) => {
    if (error) {
      return;
    } else {
      // close the dialog immediately
      onClose();
      /*
       since now we don't re-fetch workspaceInfo when we redirect from document page to detail page,
       we need to manually do it here when we know it's a successful abandon
      */
      //TODO explain why we need to refetch workspace and decide which one to use (full or basic)
      dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
      dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));

      if (pathname.concat(search).toLowerCase() !== workspaceOverviewLink.toLowerCase()) {
        navigate(workspaceOverviewLink);
      }

      const message = 'You have abandoned this workspace';
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message,
          autoHideDuration: 5000,
          variant: 'new-success'
        })
      );
    }
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<AbandonWorkspaceFormikModel>) => {
      const { dirty, isValid, isSubmitting, values } = formikProps;
      const disabled = !dirty || !isValid || isSubmitting;
      return (
        <Form aria-label="Abandon workspace form">
          <Field //
            name={fieldName('reasonOption')}
            component={SelectField}
            format="number"
            options={ABANDON_WORKSPACE_REASON_OPTIONS}
            placeholder="Select reason"
            aria-label="Abandon reason"
          />
          {ABANDON_DESCRIPTION_VISIBILITY_CHECK(values) && (
            <Field //
              name={fieldName('reasonDescription')}
              component={InputField}
              placeholder="Enter reason"
              aria-label="Abandon reason description"
            />
          )}
          {canWithdrawLinkedWorkspace && (
            <Field //
              name={fieldName('isApplyToLinkedWorkspace')}
              component={CheckboxField}
              label={`${isWithdraw ? 'Withdraw from' : 'Abandon'} linked workspace`}
            />
          )}
          <WizardStepper //
            disabled={disabled}
            isLoading={isSubmitting}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel="Abandon"
            color="secondary"
          />
        </Form>
      );
    },
    [canWithdrawLinkedWorkspace, isWithdraw, onClose]
  );

  const initialValues: AbandonWorkspaceFormikModel = {
    reasonOption: defaultReason ?? null,
    reasonDescription: '',
    isApplyToLinkedWorkspace: canWithdrawLinkedWorkspace
  };

  return (
    <ConfirmationDialog //
      open={open}
      onClose={onClose}
      title={renderTitle()}
      showActionButtons={false}
      classes={{ dialogContent: classes.dialogContent }}
    >
      <p>You will not be able to access data entered in this workspace.</p>
      <p>Please select your reason for abandoning this workspace:</p>
      <Formik //
        method="post"
        action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/abandon`}
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onPreSubmit={handleOnPreSubmit}
        onPostSubmit={handleOnPostSubmit}
      >
        {(formikProps: FormikProps<AbandonWorkspaceFormikModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}

export default React.memo(AbandonLodgementOnlyDialog);
