import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { DischargeOfMortgage2_23_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<DischargeOfMortgage2_23_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  mortgages: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        dealingNumber: NO_FALLBACK,
        documentType: NO_FALLBACK,
        isSelected: [false],
        mortgagees: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              partyBookId: [''],
              addressBookId: [''],
              partyCapacity: NO_FALLBACK
            }
          }
        },
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              isSelected: [false],
              reference: NO_FALLBACK,
              isPartLandAffected: NO_FALLBACK,
              landDescriptions: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  addressBook: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK
      }
    }
  },
  mortgagees: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        partyBookId: [''],
        addressBookId: [''],
        partyCapacity: NO_FALLBACK
      }
    }
  }
};
