import _uniq from 'lodash-es/uniq';
import _uniqBy from 'lodash-es/uniqBy';

import { QldNameChange } from '@sympli-mfe/document-components/party-form/qld/2-23/components/party-justification';
import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { arraysIntersectionsBy } from '@sympli-mfe/document-forms-framework/utils';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { ApiDischargeOfMortgage2_23_1Model, DischargeOfMortgage2_23_1Model, DocumentBaseTitleReferenceModel, MortgageePartyModel } from './models';

export const filterTitleReferences = <T extends Pick<ApiDischargeOfMortgage2_23_1Model, 'mortgages'>>(values: T): DocumentBaseTitleReferenceModel[] => {
  const titleReferences = values.mortgages?.filter(r => r.isSelected).map(x => x.titleReferences);
  const result = arraysIntersectionsBy<DocumentBaseTitleReferenceModel>(titleReferences, 'reference');
  return result;
};

export const filterMortgagees = <T extends Pick<ApiDischargeOfMortgage2_23_1Model, 'mortgages'>>(values: T): MortgageePartyModel[] => {
  const mortgagees = values.mortgages
    ?.filter(mortgages => mortgages.isSelected)
    .map(mortgagees => mortgagees.mortgagees)
    .flat();
  return _uniqBy(mortgagees, 'partyBookId');
};

export const isMortgageeNameMismatch = (values: DischargeOfMortgage2_23_1Model): boolean => {
  const singleMortgageSelected = values.mortgages.filter(s => s.isSelected).length === 1;
  if (singleMortgageSelected) {
    return false;
  }
  const selectedPartyBookIds = values.mortgages
    .filter(s => s.isSelected)
    .map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId))
    .flat();
  const partyDetails = values.partyBook?.filter(partyBook => selectedPartyBookIds.includes(partyBook.id));
  const uniqNames = _uniq(partyDetails?.map(mortgagee => mortgagee.legalEntityName)).length ?? 0;
  if (uniqNames === 1) {
    return false;
  }
  const mortgagees = partyDetails?.map(mortgagee => mortgagee.receivingOrRelinquishingDetails) as RelinquishingModel<QldNameChange>[];
  const isJustified = mortgagees.some(({ isChangingName }) => isChangingName);
  if (uniqNames > 1 && !isJustified) {
    return true;
  }
  return false;
};

export const getOtherDmDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.DischargeOfMortgage && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherDms = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as DischargeOfMortgage2_23_1Model;
    return data.mortgages.filter(mortgage => mortgage.isSelected).flatMap(mortgage => mortgage.dealingNumber);
  });
};
