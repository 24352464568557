import * as React from 'react';

import { WorkspaceRoleEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';

import LineLoader from 'src/components/loaders/line-loader';
import WorkspaceTasksBoxContainer from 'src/containers/shared/workspace-tasks-box/WorkspaceTasksBoxContainer';
import { WorkspaceDetailBoxProps } from '../../WorkspaceDetailBox';

type Props = {
  className?: string;
  isLoading: boolean;
  workspaceId: string;
  participantId: string;
  // basic workspace info
  workspaceStatusId: WorkspaceStatusEnum;
  workspaceTypeId: WorkspaceTypeEnum;
  expectedLodgementDate?: WorkspaceDateTimeModel;
  isLocked: boolean;
  // workspace participant info
  roleId?: WorkspaceRoleEnum;
  matter?: string;
  // other specialities
  // TODO explain this
  hideMatterIdOnAwaitingTask?: WorkspaceDetailBoxProps['hideMatterIdOnAwaitingTask'];
  skipFetchingWorkspaceTasks?: WorkspaceDetailBoxProps['skipFetchingWorkspaceTasks'];
  // inherited from parent
  loaderBackgroundColor?: WorkspaceDetailBoxProps['loaderBackgroundColor'];
};

function LodgementDateAndAwaitingTasks(props: Props) {
  const {
    //
    className,
    isLoading,
    workspaceId,
    participantId,
    workspaceStatusId,
    workspaceTypeId,
    expectedLodgementDate,
    isLocked,
    roleId,
    matter,
    hideMatterIdOnAwaitingTask,
    skipFetchingWorkspaceTasks,
    loaderBackgroundColor
  } = props;

  // workspace status takes priority in this case and so user won't see a loader when it's not useful
  const disabledWorkspaceStatuses: WorkspaceStatusEnum[] = [
    WorkspaceStatusEnum.Withdrawn,
    WorkspaceStatusEnum.Archived,
    WorkspaceStatusEnum.Abandoned,
    WorkspaceStatusEnum.Success,
    WorkspaceStatusEnum.Finalised
  ];

  if (disabledWorkspaceStatuses.includes(workspaceStatusId)) {
    return null;
  }
  // loader should be displayed while we are loading current participant or tasks
  if (isLoading) {
    return (
      <>
        <LineLoader color={loaderBackgroundColor} variant="medium" icon style={{ marginBottom: 0, marginTop: 12 }} />
        <LineLoader color={loaderBackgroundColor} widthPercent={70} variant="small" marginLeft={20} style={{ marginBottom: 6, marginTop: 4 }} />
      </>
    );
  }

  // if current user is not participant, there is no need to display tasks
  if (!Number.isInteger(roleId)) {
    return null;
  }

  return (
    <div className={className} data-testid="lodgement-date-and-awaiting-tasks">
      <WorkspaceTasksBoxContainer
        workspaceId={workspaceId}
        participantId={participantId}
        // basic workspace info
        workspaceTypeId={workspaceTypeId}
        expectedLodgementDate={expectedLodgementDate}
        isLocked={isLocked}
        // workspace participant info
        matter={matter!}
        // other specialities
        lineLoaderBackgroundColor={loaderBackgroundColor}
        hideMatterIdOnAwaitingTask={hideMatterIdOnAwaitingTask}
        skipFetching={skipFetchingWorkspaceTasks}
      />
    </div>
  );
}

export default React.memo(LodgementDateAndAwaitingTasks);
