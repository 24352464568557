import React, { useCallback, useEffect, useMemo } from 'react';

import { Form, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import MortgagorPartyReadyOnlyArray from './components/mortgagor-party-readonly-array';
import { getMortgagors, getSelectedTitleReferences, mortgagorsAreEqual } from './helper';
import { Mortgage2_24_0Model, MortgagorPartyModel } from './models';
import MortgageeDetails from './sections/mortgagee-details';
import TermsAndConditions from './sections/terms-and-conditions';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Mortgage2_24_0Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage2_24_0Model>();
  const { values, setValues } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        // values already contains updated titles
        const selectedTitles = getSelectedTitleReferences(values.titleReferences);
        // collect unique list of mortgagors present under selected title references
        const mortgagors = getMortgagors(selectedTitles);

        // update list of mortgagors based on title selection
        let updated = false;
        // first, remove those that should not be displayed anymore
        const updatedMortgagors = values.mortgagors.reduce<MortgagorPartyModel[]>((acc, item) => {
          if (!mortgagors.some(m => mortgagorsAreEqual(m, item))) {
            updated = true;
          } else {
            // make sure that we keep unique list of mortgagors
            if (!acc.some(x => mortgagorsAreEqual(x, item))) {
              acc.push(item);
            }
          }
          return acc;
        }, []);
        // second, add missing ones
        if (updatedMortgagors.length < mortgagors.length) {
          mortgagors.reduce<MortgagorPartyModel[]>((acc, m) => {
            if (!acc.some(x => mortgagorsAreEqual(x, m))) {
              acc.push(m);
              updated = true;
            }
            return acc;
          }, updatedMortgagors);
        }

        if (updated) {
          return {
            ...values,
            mortgagors: updatedMortgagors.sort((a, b) => {
              return mortgagors.findIndex(m => mortgagorsAreEqual(a, m)) - mortgagors.findIndex(m => mortgagorsAreEqual(b, m));
            })
          };
        }
        // because nothing has been changed we want to keep original reference and prevent unnecessary re-rendering
        return values;
      });
    },
    [setValues]
  );

  const mortgagorTitle = useMemo(() => pluralize('Mortgagor', values.mortgagors.length), [values.mortgagors]);

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
          focusRef={focusOnTitleReferencesRef}
        />
        <Section //
          data-name={fieldName('mortgagors')}
          title={mortgagorTitle}
          error={getFormikError(formikProps, fieldName('mortgagors'), true)}
        >
          <MortgagorPartyReadyOnlyArray name={fieldName('mortgagors')} />
        </Section>

        <MortgageeDetails name={fieldName('mortgageeDetails')} />

        <Section //
          title="Terms and conditions"
          data-name={fieldName('termsAndConditions')}
        >
          <TermsAndConditions name={fieldName('termsAndConditions')} />
        </Section>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
