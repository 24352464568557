import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    popper: {
      '& .MuiTooltip-tooltipPlacementRight': {
        '& .MuiTooltip-arrow': {
          marginLeft: `-${theme.spacing(2)}`,
          marginRight: `-${theme.spacing(2)}`
        },
        '& .MuiTooltip-arrow::before': {
          top: 0
        }
      },
      '& .MuiTooltip-tooltip': {
        transform: 'translate(0, -10px)'
      }
    }
  }),
  {
    name: 'StatusProgressWithBreadcrumb'
  }
);
