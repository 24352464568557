import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { SaAddressSelectField_2_23 as AddressSelectField } from '@sympli-mfe/document-components/address-field/sa/2-23';
import { SA_ADDRESS_FORM_CONFIG as SA_M_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-23/config';
import { SaPartyForm_2_23 } from '@sympli-mfe/document-components/party-form/sa/2-23';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import PartyCapacity from '../../../../components/party-capacity/PartyCapacity';
import { SA_MORTGAGE_PARTY_FORM_CONFIG } from '../../config';
import { Mortgage_2_23_0__Model, MortgagorPartyModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function MortgagorParty({ name, className, focusRef }: Props): JSX.Element {
  const formikProps = useFormikContext<Mortgage_2_23_0__Model>();
  const { values } = formikProps;
  const { disabled, dialogPortalId } = useDocumentContext();
  const fieldName = createModelKeyAppender<MortgagorPartyModel>(name);

  const addressBookOptions = useAddressBookOptions(values.addressBook, SA_M_ADDRESS_FORM_CONFIG);
  const value: MortgagorPartyModel = _get(values, name);

  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  const { isFinancialWorkspace } = values;

  const addressWithFormat = addressBookOptions?.find(address => address.id === value.addressBookId)?.name;

  if (isFinancialWorkspace) {
    return (
      <div data-name={name} className={className}>
        <Subform //
          subformBindingPath={partyBindingPath}
          component={SaPartyForm_2_23}
          disabled={true}
          showFormGroups={false}
          focusRef={focusRef}
        />
        {addressWithFormat && <ReadOnlyField label="Address" value={addressWithFormat} />}
      </div>
    );
  }

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={SaPartyForm_2_23}
        disabled={disabled}
        showFormGroups={false}
        config={SA_MORTGAGE_PARTY_FORM_CONFIG}
        focusRef={focusRef}
      />
      <PartyCapacity name={fieldName('partyCapacity')} formikProps={formikProps} isReadonly />
      <AddressSelectField //
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        name={fieldName('addressBookId')}
        optionsOverride={addressBookOptions}
      />
    </div>
  );
}

export default React.memo(MortgagorParty);
