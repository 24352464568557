import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import CurrencyInput from '@sympli/ui-framework/components/form/base-components/currency-input';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  items: Array<{ label: string; value: string | number; className?: string }>;
}
type Props = OwnProps & WithStyles<ClassKeys>;
class TransferDetails extends React.PureComponent<Props> {
  render() {
    const { items, classes } = this.props;
    return (
      <FlexLayout flexWrap="wrap" className={classes.transferDetailsContainer}>
        {items.map(({ label, value, className }) => {
          return this.renderCommonCurrencyField(label, value, className);
        })}
      </FlexLayout>
    );
  }

  private renderCommonCurrencyField(label: string, value?: string | number, className?: string) {
    const { classes } = this.props;
    if (value == null) {
      return null;
    }
    return (
      <CurrencyInput //
        key={label}
        label={label}
        value={value}
        readOnly
        className={classNames(classes.smallField, classes.labelCenter, classes.marginRight, className)}
      />
    );
  }
}

export default withStyles(styles)(TransferDetails);
