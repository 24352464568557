import Typography from '@mui/material/Typography';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { colors } from '@sympli/ui-framework/theme';

import { WorkspaceStatusColorVariantEnum } from 'src/components/workspace-status-rebuild/status-progress/components/models';
import { LodgementOnlyWorkspaceStatusEnum } from './models';

export const tooltipDescription = (lodgementOnlyWorkspaceStatusEnum: LodgementOnlyWorkspaceStatusEnum, workspaceStatusEnum: WorkspaceStatusEnum) => {
  switch (lodgementOnlyWorkspaceStatusEnum) {
    case LodgementOnlyWorkspaceStatusEnum.InPreparation: {
      if (workspaceStatusEnum === WorkspaceStatusEnum.Failed) {
        return (
          <>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              Something went wrong.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              This workspace has <b> attempted to lodge but was unsuccessful</b>. It has returned to the 'In Preparation' state.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              <b>Edit, save and approve</b> the unverified document(s) to initiate another automated Lodgement Verification.
            </Typography>
          </>
        );
      }

      return (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
          The workspace is <b>now active</b> for you to start completing.
        </Typography>
      );
    }

    case LodgementOnlyWorkspaceStatusEnum.Ready: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The documents have <b>been approved, verified and signed</b>.
          </Typography>

          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The workspace is now ready for Lodgement. <b> Click Lodge</b> in the Workspace to submit the lodgement.{' '}
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Queued: {
      return (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
          This Workspace was submitted for lodgement <b>outside of Land Registry business hours</b> or there was an issue lodging this Workspace, this has been queued to be lodged
          at the next available time.
        </Typography>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Lodging: {
      return (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
          The lodgement process has <b>now started</b>. This might take a few minutes.
        </Typography>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Lodged: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The lodgement has <b>been accepted</b> by the Land Registry.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            They are presently <b>processing your documents</b>.
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Completed: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            All done! The matter has now <b>been completed</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            For further information regarding the registration please refer to the <b>individual document status(es)</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            This workspace will be archived in 90 days.
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Abandoned: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            You have <b>abandoned this workspace</b> or this workspace was inactive for 120 days.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            Note: Abandoned workspaces can not be reactivated.
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Archived: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The system <b>automatically archives completed workspaces</b> after 90 days.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            Note: Archived workspaces can not be reactivated.
          </Typography>
        </>
      );
    }
  }
};

export function getLodgementOnlyWorkspaceStatusEnumMapping({
  //
  workspaceStatusId,
  lodgementCaseStatusId
}: {
  //
  workspaceStatusId: WorkspaceStatusEnum;
  lodgementCaseStatusId: LodgementCaseStatusEnum;
}): {
  //
  step: LodgementOnlyWorkspaceStatusEnum;
  variant: WorkspaceStatusColorVariantEnum;
} {
  const variant =
    workspaceStatusId === WorkspaceStatusEnum.OnSchedule
      ? WorkspaceStatusColorVariantEnum.Warning
      : workspaceStatusId !== WorkspaceStatusEnum.Failed
        ? WorkspaceStatusColorVariantEnum.Success
        : WorkspaceStatusColorVariantEnum.Failure;

  if (workspaceStatusId === WorkspaceStatusEnum.Archived) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Archived, variant };
  }

  if (workspaceStatusId === WorkspaceStatusEnum.Abandoned) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Abandoned, variant };
  }
  if (workspaceStatusId === WorkspaceStatusEnum.LodgementInQueue) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Queued, variant };
  }

  // lodging is always override the workspace status(except Archived, Abandoned, lodgement in queue) as it is
  if (lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementRequested) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Lodging, variant };
  }

  // we don't need to consider lodgement case status of technical/connection error because the WorkspaceStatus will stay as ReadyForLodge
  if (workspaceStatusId === WorkspaceStatusEnum.ReadyForLodge) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Ready, variant };
  }

  if (lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementSuccess) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Lodged, variant };
  }

  // completed status is any status after registered. WEB-31851
  if (
    [LodgementCaseStatusEnum.Registered, LodgementCaseStatusEnum.Rejected, LodgementCaseStatusEnum.Withdrawn, LodgementCaseStatusEnum.Unnecessary].includes(lodgementCaseStatusId)
  ) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Completed, variant };
  }

  if (workspaceStatusId === WorkspaceStatusEnum.Failed) {
    return { step: LodgementOnlyWorkspaceStatusEnum.InPreparation, variant };
  }

  if (workspaceStatusId === WorkspaceStatusEnum.OnSchedule) {
    return { step: LodgementOnlyWorkspaceStatusEnum.InPreparation, variant };
  }

  return { step: LodgementOnlyWorkspaceStatusEnum.InPreparation, variant };
}
