import * as React from 'react';

import { useRouterParams } from 'src/hooks';
import { useWorkspaceParticipants } from '../../shared/detail/reducers/workspaceParticipants';
import AllDirections from './AllDirections';
import { useAllDirections } from './reducer';

export interface AllDirectionsRouteParams {
  workspaceId: string;
  participantId: string;
}

function AllDirectionsContainer() {
  const { workspaceId, participantId } = useRouterParams<AllDirectionsRouteParams>();

  //TODO Decide full or basic in the future
  // const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  // const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const allDirectionsState = useAllDirections(workspaceId, participantId);

  return (
    <AllDirections //
      participantId={participantId}
      allDirectionsDetail={allDirectionsState.detail!}
      workspaceParticipants={workspaceParticipantsState.items}
      // isLoading={workspaceBasicInfoState.isLoading || workspaceDetailState.isLoading || workspaceParticipantsState.isLoading || allDirectionsState.isLoading}
      isLoading={workspaceParticipantsState.isLoading || allDirectionsState.isLoading}
    />
  );
}

export default React.memo(AllDirectionsContainer);
