import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const columnStyles = (theme: Theme) =>
  createStyles({
    columnOne: {
      width: '30%'
    },
    columnTwo: {
      width: '60%'
    },
    columnTwoWithBreakdowns: {
      width: '35%'
    },
    breakdownsCol: {
      width: '25%'
    },
    columnThree: {
      width: '10%',
      textAlign: 'right'
    }
  });

export default columnStyles;
