import * as yup from 'yup';

import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { LandDescriptionModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/models';
import msg from '@sympli/ui-framework/utils/messages';

import { TitleReferenceModel } from '../../models';

const yupLandDescription = yup
  .object<LandDescriptionModel>() //
  .shape({
    //
    addressBookId: yup.string().required(msg.REQUIRED)
  });

const yupPartLandAffected = yup
  .object<PartLandAffectedWithDescriptionsModel>() //
  .shape({
    //
    landDescriptions: yup.array<LandDescriptionModel>(yupLandDescription.defined()).defined()
  });

const yupTitleReferenceItem = yup
  .object<TitleReferenceModel>() //
  .shape({
    //
    partLandAffected: yupPartLandAffected.defined()
  })
  .defined();

export const yupTitleReferences = yup.array<TitleReferenceModel>(yupTitleReferenceItem).defined();
