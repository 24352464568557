import { useCallback, useContext, useEffect } from 'react';

import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 */
export function useBlocker(blocker: (tx: any) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    //@ts-ignore
    const unblock = navigator.block((tx: { retry: () => void }) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it.
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 */
export function usePrompt(message: () => any, when = true) {
  const blocker = useCallback(
    tx => {
      const callback = (result: boolean) => {
        if (result) {
          tx.retry();
        }
      };

      // if when is true, block the navigation, show the dialog by running the message function
      // pass the callback so that we can redirect or forbid redirect
      // when user clicks on different buttons.
      if (when && window && window[message()]) {
        // @ts-ignore
        window[message()](callback);
      }
    },
    [message, when]
  );

  useBlocker(blocker, when);
}
