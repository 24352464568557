import * as React from 'react';

import BaseField, { FieldComponentProps } from '@sympli/ui-framework/components/formik/base-field';

import CheckboxTree, { CheckboxTreeProps, TreeNodeItem } from '../checkbox-tree/CheckboxTree';

type Props<T extends TreeNodeItem> = Omit<FieldComponentProps<CheckboxTreeProps<T>>, 'values'>;

export default class CheckboxTreeField<T extends TreeNodeItem> extends BaseField<Props<T>> {
  public static displayName = 'CheckboxGroupField';

  render() {
    const { value: values, ...restProps } = this.resolveComponentProps();
    return <CheckboxTree {...restProps} values={values} />;
  }
}
