import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    documentActionTextDark: {
      fontSize: 16,
      lineHeight: '19px',
      color: theme.palette.common.white
    },
    documentActionIcon: {
      fill: theme.palette.primary.main,
      padding: '0px 4px'
    }
  }),
  { name: 'LodgedDocument' }
);
