import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { EvidenceTypeValueEnum } from './enums';
import { EvidenceModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].evidence.evidenceDocumentReference

 schema version:
{
  "evidenceTypeValue": {
    "$not": 0
  }
}
*/
export const IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_DOCUMENT_REFERENCE = createContextCheck(
  (context: EvidenceModel): boolean => context.evidenceTypeValue !== EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct
);
