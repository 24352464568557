import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { getCaveatsBeingWithdrawn, getSelectedCaveat, getSelectedTitleReferences } from './helper';
import { WithdrawalOfCaveat2_25_1Model } from './models';

// this file was automatically generated from checks.ts.mustache
export const VISIBILITY_CHECK_WITHDRAW_CAVEAT = createContextCheck((context: WithdrawalOfCaveat2_25_1Model): boolean => {
  // skip this validation if no titles are selected
  const selectedTitles = getSelectedTitleReferences(context.titleReferences);
  if (!selectedTitles.length) {
    return false;
  }

  // verify that caveats share some common title reference
  return getCaveatsBeingWithdrawn(context.caveats, selectedTitles).length > 0;
});

export const VISIBILITY_CHECK_CAVEATORS = createContextCheck((context: WithdrawalOfCaveat2_25_1Model): boolean => {
  const selectedCaveat = getSelectedCaveat(context.withdrawCaveats);
  return selectedCaveat !== undefined;
});
