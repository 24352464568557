import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    errorMessage: {
      margin: '16px auto'
    },
    content: {
      paddingLeft: 18
    },
    toggleShowHide: {
      marginLeft: 'auto',
      fontSize: 13,
      lineHeight: '15px',
      height: '20px'
    },
    flexGrow: {
      flex: 1
    },
    title: {
      color: colors.NEUTRAL_1000,
      fontFamily: 'volkswagen_serial-medium',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '40px'
    },
    summaryBody: {
      marginTop: 32
    }
  }),
  {
    name: 'AllDirections'
  }
);
