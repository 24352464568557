import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...dataBindingHelper()
    }
  }),
  {
    name: 'Certification'
  }
);
