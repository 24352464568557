import * as yup from 'yup';

import { DischargeOfMortgage2_17_1Model } from './models';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgages from './sections/mortgages/validationSchema';
import yupPartyBook from './sections/part-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<DischargeOfMortgage2_17_1Model>({
    mortgages: yupMortgages,
    titleReferences: yupTitleReferences,
    partyBook: yupPartyBook,
    mortgagees: yupMortgagees,
    workspaceTitleReferences: yup.mixed<string[]>()
  })
  .defined()
  .log();
