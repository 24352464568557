import { all, ForkEffect } from 'redux-saga/effects';
import { SagaGenerator } from 'typed-redux-saga/dist';

import { submitFormikSagas as formikWrapper } from '@sympli/ui-framework/components/formik';

import financialWorkspaces from 'src/containers/dashboard/financial-workspaces/sagas';
import invitations2 from 'src/containers/dashboard/invitation/sagas';
import reportDetails from 'src/containers/dashboard/reports/sagas';
import workspaceAssignableGroups from 'src/containers/dashboard/shared/sagas';
import standaloneWorkspacesDashboard from 'src/containers/dashboard/standalone-workspaces/sagas';
import documentPage from 'src/containers/documents/sagas';
import messengerConversations from 'src/containers/messenger/sagas';
import logs from 'src/containers/settings/reporting/sagas/logs';
import groups from 'src/containers/settings/settings/sagas/groups';
import users from 'src/containers/settings/settings/sagas/users';
import autoJustification from 'src/containers/settings/subscriber-profile/sagas/autoJustification';
import billingInvoicing from 'src/containers/settings/subscriber-profile/sagas/billingInvoicing';
import defaultFieldPopulation from 'src/containers/settings/subscriber-profile/sagas/defaultFieldPopulation';
import financialAccounts from 'src/containers/settings/subscriber-profile/sagas/financialAccounts';
import jurisdictions from 'src/containers/settings/subscriber-profile/sagas/jurisdictions';
import notificationsSettings from 'src/containers/settings/subscriber-profile/sagas/notificationsSettings';
import organisationProfile from 'src/containers/settings/subscriber-profile/sagas/organisationProfile';
import preferences from 'src/containers/settings/subscriber-profile/sagas/preferences';
import subscriberSigningRule from 'src/containers/settings/subscriber-profile/sagas/subscriberSigningRule';
import trustAccountDetails from 'src/containers/settings/subscriber-profile/sagas/trustAccountDetails';
import settings from 'src/containers/settings/subscriber-profile/sagas/trustAccounts';
import workspacePreferences from 'src/containers/settings/subscriber-profile/sagas/workspacePreferences';
import searchBox from 'src/containers/shared/app-bar/sagas';
import auth from 'src/containers/shared/auth/sagas';
import linkedWorkspaces from 'src/containers/shared/linked-workspace-list/sagas';
import allDirections from 'src/containers/workspace/financial/all-directions/sagas';
import directions from 'src/containers/workspace/financial/directions/sagas';
import settlementDetail from 'src/containers/workspace/financial/settlement-date/sagas';
import stampDutyDetail from 'src/containers/workspace/financial/stamp-duty/sagas';
import trustAccountAuthorisationRecords from 'src/containers/workspace/financial/trust-account-authorisation-records/sagas';
import offPlatformTasks from 'src/containers/workspace/shared/components/off-platform-tasks-container/sagas';
import certificates from 'src/containers/workspace/shared/components/signing-provider/sagas';
import workspaceFiles from 'src/containers/workspace/shared/components/workspace-files-container/sagas';
import workspaceReports from 'src/containers/workspace/shared/detail/components/tab-workspace-report/sagas';
import workspaceDetail from 'src/containers/workspace/shared/detail/sagas';
import archiveWorkspace from 'src/containers/workspace/shared/sagas';
import documentMultiSign from 'src/containers/workspace/sign-documents/sagas';
import titleActions from 'src/containers/workspace/titles/sagas';
import chat from './chat';
import globalErrors from './globalErrors';

const sagas: (ForkEffect<any> | SagaGenerator<never, ForkEffect<never>>)[] = [
  //
  ...formikWrapper, // formik
  ...auth,
  // ...authenticationDialog,
  ...certificates,

  ...globalErrors,
  ...workspaceDetail,
  ...archiveWorkspace,

  ...directions,
  ...allDirections,
  ...trustAccountAuthorisationRecords,
  ...settings,

  ...invitations2,
  ...messengerConversations,
  ...chat,
  // ...permission,
  ...financialWorkspaces,
  ...standaloneWorkspacesDashboard,

  ...documentPage,
  ...documentMultiSign,
  ...settlementDetail,
  ...stampDutyDetail,
  ...titleActions, //
  ...searchBox,
  ...linkedWorkspaces,
  // settings pages
  ...groups,
  ...users,
  // settings - subscriber profile pages
  ...financialAccounts,
  ...jurisdictions,
  ...organisationProfile,
  ...trustAccountDetails,
  ...autoJustification,
  ...subscriberSigningRule,
  // settings - reporting pages
  ...logs,
  // assign groups
  ...workspaceAssignableGroups,
  // lodgement reports
  ...workspaceReports,
  ...preferences,
  ...notificationsSettings,
  ...defaultFieldPopulation,

  ...billingInvoicing,

  ...reportDetails,
  ...workspaceFiles,
  ...offPlatformTasks,
  ...workspacePreferences
];

export default function* root() {
  yield all(sagas);
}
