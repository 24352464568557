import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    noBorder: {
      border: 0
    },
    fullWidth: {
      width: '100%'
    },
    message: {
      fontWeight: 'bold'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
