import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '&.Mui-disabled': {
        backgroundColor: colors.DARK_GREY,
        '& svg': {
          fill: theme.palette.text.secondary
        }
      }
    }
  }),
  { name: 'SignButton' }
);
