// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    radioFormControlLabel: {
      flexGrow: 0
    },
    fieldMarginRight: {
      marginRight: 10
    }
  }),
  {
    name: 'MonetaryConsideration'
  }
);
