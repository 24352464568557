import { resolveIsoDate } from 'src/components/date-range-picker/helpers';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import environments from 'src/environments';
import http from 'src/utils/http';
import { FinancialDashboardAggregatorApiRequest, FinancialDashboardAggregatorApiResponse } from './aggregators/models';

const { TARGET_MACHINE, TARGET_API_V2 } = environments;

export const fetchAggregatorInfo = (request: FinancialDashboardAggregatorApiRequest) => {
  const { queries: existingQueries, assignmentType: existingAssignmentType, ...rest } = request;

  // we need to format the datetime in this case
  const queries = existingQueries.map(query => {
    if (query.settlementPeriod) {
      const formatPeriod = {
        start: resolveIsoDate(query.settlementPeriod.start as Date, 'start'),
        end: resolveIsoDate(query.settlementPeriod.end as Date, 'end')
      };
      return { ...query, settlementPeriod: { ...formatPeriod } };
    }

    return query;
  });

  // reassign is equal to assign to group
  const assignmentType = existingAssignmentType === AssignmentTypeEnum.ReAssigned ? AssignmentTypeEnum.AssignedToGroup : existingAssignmentType;

  return http.post<FinancialDashboardAggregatorApiResponse>('/dashboards/aggregators', { queries, assignmentType, ...rest }, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};
