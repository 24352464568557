import { Action, createReducer } from 'typesafe-actions';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { useStoreSelector } from 'src/hooks';
import { actionFetchFinancialWorkspacesFeedV2, showFinancialWorkspaceFeedDetail } from '../actions';
import { FinancialWorkspacesStateV2 } from '../models';

export const initialState: FinancialWorkspacesStateV2 = {
  rowDetailIndex: undefined,

  items: [],
  status: 'idle',
  error: undefined,

  totalCount: 0,
  updatedTime: new Date(),

  query: {
    assignmentType: AssignmentTypeEnum.AssignedToMe,
    sympliId: undefined,
    reference: undefined,
    settlementPeriod: undefined,
    archivedDatePeriod: undefined,
    myTasks: undefined,
    myRoles: undefined,
    jurisdictions: undefined,
    financialStatus: undefined,
    fundsStatus: undefined,
    workspaceStatus: undefined,
    pageSize: 20,
    pageNumber: 1 // service page number is index from 1
  }
};

export const useFinancialWorkspacesFeedV2 = () => {
  const workspacesFeed = useStoreSelector(store => store.financialWorkspacesFeedV2);

  if (workspacesFeed) {
    return workspacesFeed;
  }

  // We need to retain searchTerm (if any) when switching between tabs
  return { ...initialState, query: { ...initialState.query } };
};

const financialWorkspacesFeedV2Reducer = createReducer<
  //
  FinancialWorkspacesStateV2,
  Action
>(initialState)
  .handleAction(showFinancialWorkspaceFeedDetail, (state, action) => {
    return {
      ...state,
      rowDetailIndex: action.payload.rowDetailIndex
    };
  })
  .handleAction(actionFetchFinancialWorkspacesFeedV2.request, (state, action): FinancialWorkspacesStateV2 => {
    return {
      ...initialState,
      query: action.payload,
      items: [],
      status: 'pending',
      error: undefined
    };
  })
  .handleAction(actionFetchFinancialWorkspacesFeedV2.success, (state, action): FinancialWorkspacesStateV2 => {
    return {
      ...state,
      ...action.payload.data,
      rowDetailIndex: undefined,
      status: 'resolved',
      updatedTime: new Date(),
      error: undefined
    };
  })
  .handleAction(actionFetchFinancialWorkspacesFeedV2.failure, (state, action): FinancialWorkspacesStateV2 => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default financialWorkspacesFeedV2Reducer;
