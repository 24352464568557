import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { yupGenericPhoneNumber } from '@sympli/ui-framework/components/formik/phone-number-field';
import msg from '@sympli/ui-framework/utils/messages';

import { yupEmailAddress } from 'src/containers/documents/scaffolded-form/vic/2-25/helpers/yupEmailAddress';
import { RepresentativeDetailModel } from '../../models';
import { VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: transfereeAgentSolicitorDetail
const yupTransfereeAgentSolicitorDetail = yup //
  .mixed<RepresentativeDetailModel>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
      validationWhenVisible: yup
        .object<RepresentativeDetailModel>()
        .required(msg.REQUIRED)
        .shape({
          name: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
          contactPhoneNumber: yupGenericPhoneNumber.required(msg.REQUIRED).max(20, msg.MAX_LENGTH(20)),
          emailAddress: yupEmailAddress.required(msg.REQUIRED)
        }),
      isObjectOrArray: true
    })
  );

export default yupTransfereeAgentSolicitorDetail;
