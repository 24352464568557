import * as React from 'react';

import { differenceInCalendarMonths, isBefore, parseISO, startOfDay } from 'date-fns';
import dateFormat from 'dateformat';
import { FormikProps } from 'formik';
import { batch } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import * as yup from 'yup';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, SettlementDateKindEnum } from '@sympli/api-gateway/enums';
import { ExpectedSettlementDate } from '@sympli/api-gateway/shared';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import msg from '@sympli/ui-framework/utils/messages';

import Formik from 'src/components/formik';
import { settlementTimeCheck } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/matter-detail/validationSchema';
import { actionFetchWorkspaceBasicInfo, actionFetchWorkspaceById } from 'src/containers/workspace/shared/detail/actions';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';
import { isBusinessDay } from 'src/utils/holiday/holidayCheck';
import { actionFetchCalendarItems, actionFetchSettlementDetails, FetchCalendarItemsApiRequest } from '../../actions';
import { DateFormatEnum, RescindApprovalModel, SettlementDateFormModel, UpdateSettlementDateApiRequest } from '../../models';
import RenderedEditFormContainer from './rendered-edit-form';
import styles, { ClassKeys } from './styles';

function getValidationSchema(jurisdictionId: JurisdictionsEnum, expectedSettlementDate?: ExpectedSettlementDate) {
  return yup.object<SettlementDateFormModel>({
    selectedDate: yup // TODO review this
      .string()
      .required(msg.REQUIRED)
      .test(
        'Settlement date validation',
        'Must enter a valid business day', //
        function test(this: yup.TestContext, selectedDate: string) {
          const date = new Date(selectedDate);
          const today = new Date();
          const notPastDay = !isBefore(date, startOfDay(today));
          return isBusinessDay(date, jurisdictionId) && notPastDay;
        }
      ),
    selectedTime: yup
      .string()
      .required(msg.REQUIRED)
      .test(
        'Settlement Time',
        'This time has passed. Please select a new time.', //
        function test(this: yup.TestContext, selectedTime: string) {
          const { selectedDate } = this.parent as SettlementDateFormModel;
          return settlementTimeCheck(jurisdictionId, selectedDate, selectedTime);
        }
      ),
    reasonId: yup //
      .number()
      .nullable()
      .defined()
      .test(
        'Reason ID',
        'Reason ID required', //
        function test(this: yup.TestContext, reasonId: number | null) {
          if (!expectedSettlementDate || expectedSettlementDate.settlementDateKind === SettlementDateKindEnum.DateOnly) {
            return true;
          }
          return reasonId !== null;
        }
      )
  });
}

interface OwnProps {
  workspaceId: string;
  proposedSettlementDate?: string;
  workspaceSettlementDate?: string; // * '2018-04-04T13:00:00'
  expectedSettlementDate?: ExpectedSettlementDate;
  participantId: string;
  // These two props are for valueInitialise in first render only
  // resetForm() valueInitialise are managed from <RenderedForm>
  onSettlementDateChange(newWorkspaceSettlementDate: string): void; // notify parent that there was a change in date
  jurisdictionId: JurisdictionsEnum;
  onRedirect?: (message: string, secondaryMessage?: string) => void;
  minReschedulingInHours: number;
  reasonId?: number;
  reasonText?: string;
  calendarYearAndMonth: string;
  declined?: boolean;
  dispatch: SafeDispatch<Action>;
  settlementAcceptedByAll: boolean;
  numberOfParticipants: number;
  navigate: NavigateFunction;
  isRollover?: boolean;
  rescindApprovalData: RescindApprovalModel;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class SettlementDateEditForm extends React.PureComponent<Props> {
  componentDidMount() {
    const { jurisdictionId, calendarYearAndMonth, dispatch } = this.props;
    const isDateinPast = differenceInCalendarMonths(new Date(calendarYearAndMonth), new Date()) < 0;
    const payload: FetchCalendarItemsApiRequest = {
      jurisdiction: jurisdictionNameMapping[jurisdictionId],
      // If settlement date is in the past we want to display the calendar for the current month
      calendarYearAndMonth: isDateinPast ? dateFormat(new Date(), DateFormatEnum.DATE) : calendarYearAndMonth || new Date().toString()
    };
    dispatch(actionFetchCalendarItems.request(payload));
  }
  // This initial value only triggered once the formik being rendered at first time
  private getInitialValues = (): SettlementDateFormModel => {
    const { workspaceSettlementDate, proposedSettlementDate, expectedSettlementDate, isRollover } = this.props;

    // we take proposed settlement date if there was any
    // https://date-fns.org/v2.9.0/docs/parseISO  the new format only expect date | number, not string

    const initialDate = isRollover ? workspaceSettlementDate : proposedSettlementDate || workspaceSettlementDate;
    const settlementDateTime = parseISO(initialDate || new Date().toISOString());
    const defaultSelectedDate = expectedSettlementDate
      ? dateFormat(settlementDateTime, DateFormatEnum.DATETIME)
      : proposedSettlementDate
        ? dateFormat(proposedSettlementDate, DateFormatEnum.DATETIME)
        : '';

    return {
      selectedDate: defaultSelectedDate ? dateFormat(defaultSelectedDate, DateFormatEnum.DATE) : '',
      selectedTime:
        expectedSettlementDate?.settlementDateKind === SettlementDateKindEnum.DateAndTime || proposedSettlementDate ? dateFormat(defaultSelectedDate, DateFormatEnum.TIME) : '',
      reasonId: null
    };
  };

  render() {
    const { jurisdictionId, workspaceId, expectedSettlementDate } = this.props;
    return (
      <Formik //
        validationSchema={getValidationSchema(jurisdictionId, expectedSettlementDate)}
        method="post"
        action={`/workspaces/${encodeURIComponent(workspaceId)}/settlement-date-proposal`}
        getInitialValues={this.getInitialValues}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
      >
        {(formikProps: FormikProps<SettlementDateFormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private handleOnPreSubmit = (formikValues: SettlementDateFormModel): UpdateSettlementDateApiRequest => {
    const { participantId } = this.props;
    const { selectedDate, selectedTime, reasonId } = formikValues;

    const settlementDate = selectedDate + 'T' + selectedTime + ':00.000Z';

    return {
      participantId,
      settlementDate,
      reasonId
    };
  };

  private handleOnPostSubmit = ({ error }: FormikPostSubmitArgs<SettlementDateFormModel>) => {
    if (!error) {
      // now we need to explicitly re-fetch settlement-date-proposal again when it's success
      const { dispatch, workspaceId, participantId, numberOfParticipants } = this.props;
      batch(() => {
        dispatch(actionFetchSettlementDetails.request({ workspaceId, participantId }));
        // we need to re-fetch workspace basic info to get the latest settlementDate
        dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
        dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
      });
      this.handleGotoWorkspaceDetail("You've proposed a new settlement date and time.", numberOfParticipants > 1 ? 'All participants have been notified.' : undefined);
    }
  };

  private handleGotoWorkspaceDetail = (message: string = '', secondaryMessage?: string) => {
    const { onRedirect } = this.props;
    if (message && onRedirect && typeof onRedirect === 'function') {
      onRedirect(message, secondaryMessage);
    } else {
      this.gotoWorkspaceDetail();
    }
  };

  private gotoWorkspaceDetail() {
    const { workspaceId, participantId, navigate } = this.props;
    const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
    navigate(link);
  }

  private renderForm(formikProps: FormikProps<SettlementDateFormModel>) {
    const {
      workspaceId,
      participantId,
      workspaceSettlementDate,
      jurisdictionId,
      reasonText,
      declined,
      settlementAcceptedByAll,
      rescindApprovalData,
      proposedSettlementDate,
      expectedSettlementDate
    } = this.props;
    return (
      <RenderedEditFormContainer
        workspaceId={workspaceId}
        participantId={participantId}
        jurisdictionId={jurisdictionId}
        reasonText={reasonText}
        workspaceSettlementDate={workspaceSettlementDate}
        expectedSettlementDate={expectedSettlementDate}
        formikProps={formikProps}
        declined={declined}
        onSettlementDateChange={this.props.onSettlementDateChange}
        settlementAcceptedByAll={settlementAcceptedByAll}
        rescindApprovalData={rescindApprovalData}
        proposedSettlementDate={proposedSettlementDate}
      />
    );
  }
}

const styledComponent = withStyles(styles)(SettlementDateEditForm);

export default styledComponent;
