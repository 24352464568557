import * as React from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceDirectionsStatusApiResponse } from '@sympli/api-gateway/models';
import { DistributionsParticipantModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { MultiUserAvatar } from 'src/components/avatars';
import { UserAvatarModel } from 'src/components/avatars/multi-user-avatar';
import { IconListItem } from 'src/components/list-item';
import FinancialsBoxTitle from 'src/containers/documents/components/financials-box-title';
import styles, { ClassKeys } from 'src/containers/shared/document-list/styles';
import WorkspaceExpansionBox from 'src/containers/shared/workspace-expansion-box';
import { resolveDirectionsLink, resolveDirectionsTAAFLink } from 'src/containers/workspace/financial/directions/helpers';
import StampDutyLeftPanelStatus from 'src/containers/workspace/financial/stamp-duty/components/stamp-duty-left-panel-status';
import DirectionStatusBadgeNew from '../direction-status-badge/DirectionStatusBadgeNew';
import AllDirectionsItem from './components/all-directions-item';
import { getSeparateFinancialStatuses, getTrustAccountAuthorisationRecordTooltip } from './helpers';
import { SelectedSettlementDistributionsTypeEnum } from './models';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083782708/settlement+directions+list+-+data+dependency+insights
interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  documentId?: string;
  // basic workspace info
  isLocked?: boolean;
  // directions list data
  data: WorkspaceDirectionsStatusApiResponse;
  // other
  collapsedByDefault?: boolean;
  selectedSettlementDistributionsType?: SelectedSettlementDistributionsTypeEnum;
  onExpandChange?(expand: boolean): void;
  participantDistributionsData?: DistributionsParticipantModel;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class SettlementDirectionsList extends React.PureComponent<Props> {
  render() {
    const { data, collapsedByDefault, onExpandChange } = this.props;

    const { financialSettlementStatus } = data;

    return (
      <WorkspaceExpansionBox
        onExpandChange={onExpandChange}
        collapsedByDefault={collapsedByDefault}
        title={
          <FinancialsBoxTitle //
            financialSettlementStatusId={financialSettlementStatus}
          />
        }
      >
        {this.renderContent()}
      </WorkspaceExpansionBox>
    );
  }

  private renderContent() {
    const { data, classes } = this.props;

    const { stampDutyStatus, trustAccountAuthorisationRecordStatuses } = data;

    return (
      <List className={classNames(classes.root, classes.outerGutters)} disablePadding={true}>
        {this.renderDistributions(data)}
        {trustAccountAuthorisationRecordStatuses ? this.renderDistributionsTAAFs101(trustAccountAuthorisationRecordStatuses) : null}
        {stampDutyStatus ? this.renderStampDuty101(stampDutyStatus) : null}
        {this.renderAllDistributions()}
      </List>
    );
  }

  private renderDistributions(directionsStatusApiResponse: WorkspaceDirectionsStatusApiResponse) {
    const { workspaceId, participantId, selectedSettlementDistributionsType, participantDistributionsData } = this.props;
    const {
      workspaceParticipant: { subscriberName, reference, workspaceRoles, avatarUrl }
    } = directionsStatusApiResponse.paymentsStatus;

    const participants: UserAvatarModel[] = [{ name: subscriberName, reference, workspaceRoles, avatarUrl }];
    const linkTo = resolveDirectionsLink({ workspaceId, participantId });
    const selected = selectedSettlementDistributionsType === SelectedSettlementDistributionsTypeEnum.Distributions;

    return (
      <FlexLayout flexDirection="column" fullWidth>
        <IconListItem
          selected={selected}
          linkTo={linkTo}
          primary="Financial Settlement Schedule"
          secondary={
            participantDistributionsData && (
              <DirectionStatusBadgeNew
                isCurrentParticipant={true}
                status={participantDistributionsData.distributionStatus}
                showToolTips={true}
                isPartiallySigned={participantDistributionsData.isPartiallySigned}
                paymentsStatus={participantDistributionsData.paymentsFinancialStatus}
                sourceFundsStatus={participantDistributionsData.sourceFundsFinancialStatus}
              />
            )
          }
          data-testid="financial-settlement-schedule"
        />
        {getSeparateFinancialStatuses(directionsStatusApiResponse['paymentsStatus'], directionsStatusApiResponse['sourceFundsStatus'], participants[0])}
      </FlexLayout>
    );
  }

  private renderDistributionsTAAFs101(authorisationRecordStatuses: Exclude<WorkspaceDirectionsStatusApiResponse['trustAccountAuthorisationRecordStatuses'], undefined>) {
    const { workspaceId, participantId, selectedSettlementDistributionsType, documentId: selectedId, classes } = this.props;

    return authorisationRecordStatuses.map((status, index) => {
      const {
        workspaceParticipant: { subscriberName, reference, workspaceRoles, avatarUrl },
        status: statusId,
        documentActions,
        requiredSigners,
        accountName
      } = status;

      const participants: UserAvatarModel[] = [{ name: subscriberName, reference, workspaceRoles, avatarUrl }];
      const linkTo = resolveDirectionsTAAFLink({ workspaceId, participantId }, status.accountId);
      const selected = selectedSettlementDistributionsType === SelectedSettlementDistributionsTypeEnum.TrustAccountAuthorisationRecords && selectedId === status.accountId;

      return (
        <IconListItem
          key={`${status.accountId}-${index}`}
          selected={selected}
          linkTo={linkTo}
          primary="Trust Account Auth. Record"
          secondary={
            <Box display="flex" flexDirection="column">
              <Typography className={classNames(classes.accountName)}>{accountName}</Typography>
              <Box>{getTrustAccountAuthorisationRecordTooltip(statusId, documentActions, requiredSigners)}</Box>
            </Box>
          }
          secondaryAction={<MultiUserAvatar items={participants} placement="right-start" />}
          data-testid="trust-account-auth-record"
        />
      );
    });
  }

  private renderStampDuty101(stampDutyStatus: Exclude<WorkspaceDirectionsStatusApiResponse['stampDutyStatus'], undefined>) {
    const { workspaceId, participantId, selectedSettlementDistributionsType, isLocked } = this.props;
    return (
      <StampDutyLeftPanelStatus //
        participantId={participantId}
        workspaceId={workspaceId}
        selectedSettlementDistributionsType={selectedSettlementDistributionsType}
        stampDutyStatus={stampDutyStatus}
        isLocked={isLocked}
      />
    );
  }

  private renderAllDistributions() {
    const { workspaceId, participantId } = this.props;
    return (
      <List disablePadding={true}>
        <AllDirectionsItem workspaceId={workspaceId} currentParticipantId={participantId} />
      </List>
    );
  }
}

export default withStyles(styles)(SettlementDirectionsList);
