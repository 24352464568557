import classNames from 'classnames';

interface Props {
  position?: string; // 'left-1 top-1';
  style?: React.CSSProperties;
}
export default function TailwindIndicator({ position = 'left-1 top-1', style }: Props) {
  if (import.meta.env.PROD) return null;

  return (
    <div
      //
      className={classNames('fixed z-50 flex items-center justify-center rounded-full bg-mui-palette-common-black font-mono text-xs text-mui-palette-common-white', position)}
      style={style}
    >
      <div className="block sm:hidden">xs</div>
      <div className="hidden sm:block tablet:hidden">sm</div>
      <div className="hidden tablet:block tablet-landscape:hidden">tablet 768</div>
      <div className="hidden tablet-landscape:block small-laptop:hidden">tablet-landscape 1024</div>
      <div className="hidden small-laptop:block medium-screen:hidden">small-laptop 1280</div>
      <div className="hidden medium-screen:block large-screen:hidden">medium-screen 1536</div>
      <div className="hidden large-screen:block">large-screen 1920</div>
    </div>
  );
}
