import * as yup from 'yup';

import { SaNameChange } from '@sympli-mfe/document-components/party-form/sa/2-23/components/party-justification';
import { PartyModel, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { SA_DM_PARTY_FORM_CONFIG } from '../../config';
import { DischargeOfMortgage_2_23_0_Model } from '../../models';

export const yupParty = resolvePartyModelValidationSchema(SA_DM_PARTY_FORM_CONFIG);

const yupPartyBookEntry = yup
  .object<PartyModel>()
  .test('Party test', 'Party failed validation', function test(this: yup.TestContext<DischargeOfMortgage_2_23_0_Model>, party: PartyModel): boolean {
    const dmModel = this.options.context!;
    const partyIdForCheck = party.id;

    // Check caveators (if visible), contacts, claim parties (if visible) and AFFECTED proprietors to determine whether this party is in use
    const selectedMortgageeIds = dmModel.mortgages.map(mortgage => (mortgage.isSelected ? mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId) : [])).flat();
    const isSelectedMortgagee: boolean = selectedMortgageeIds.includes(partyIdForCheck);

    if (!isSelectedMortgagee) {
      // If the party is not selected mortgagee, we do not need detailed validation
      return true;
    }

    try {
      // we need to pass context always, otherwise array item properties does not have it!
      yupParty.validateSync(party, {
        path: this.path,
        abortEarly: false,
        context: dmModel
      } as any);
      return true;
    } catch (ex) {
      return ex;
    }
  });

export const yupPartyBook = yup.array<PartyModel<SaNameChange>>(yupPartyBookEntry as yup.Schema<PartyModel<SaNameChange>>); //fixme: double check with martin
