import _get from 'lodash-es/get';
import { sentenceCase } from 'sentence-case';

import {
  ArrayTypeJsonSchemaModel,
  ConditionalModel,
  ConditionalValidationEntityModel,
  ConditionalVisibilityEntityModel,
  GenericJsonSchemaModel,
  JsonSchemaDefinitionsModel,
  JsonSchemaRootModel,
  ResolvedJsonSchemaModel
} from '../models';
import { ExtraDefinition, ParsedFieldDefinition } from './models';

const IS_ROOT = true;
const EXTRAS_STANDARD_PROPS = ['minLength', 'maxLength', 'minimum', 'maximum', 'minItems', 'maxItems'];
const EXTRAS_META_PROPS = [
  'disabled',
  'component',
  'componentProps',
  'componentValidation',
  'dataSourceProps',
  'metadata',
  'uuid',
  'dataTitle',
  'dataEnum',
  'dataRef',
  'dataRefFilter',
  'canAdd',
  'invisibleValue',
  'visibility',
  'validation',
  'datePickerComponentValidation', // keep for backward compactibility 2020-09-25, new schemas use componentValidation
  'textTransform',
  'email'
];
export default class SchemaParser {
  private rootSchema: JsonSchemaRootModel;
  private definitions: JsonSchemaDefinitionsModel;

  constructor(rootSchema: JsonSchemaRootModel) {
    this.rootSchema = rootSchema;
    this.definitions = rootSchema.definitions || {};
  }

  public getFieldDefinitions() {
    return this.parseDefinition(this.rootSchema, IS_ROOT) as Array<ParsedFieldDefinition>;
  }

  private parseDefinition(currentSchema: GenericJsonSchemaModel, isRootSchema: boolean = false): Array<ParsedFieldDefinition> | ParsedFieldDefinition {
    // make sure we have resolved the defintion if there was a $ref
    const schemaDefinition = this.getSchemaDefinition(currentSchema);
    const { type } = schemaDefinition;

    // if (isCustomComponent(_get(schemaDefinition, '_meta.component'))) {
    //   return this.getFieldsForCustomDefinition(schemaDefinition as ResolvedJsonSchemaModel);
    // } else
    if (type === 'object') {
      return this.getFieldsFromObjectTypeDefinition(schemaDefinition, isRootSchema);
    } else if (type === 'array') {
      return this.getFieldsFromArrayTypeDefinition(schemaDefinition as ArrayTypeJsonSchemaModel);
    } else {
      // simple type
      return this.getFieldsFromSimpleTypeDefinition(schemaDefinition as ResolvedJsonSchemaModel);
    }
  }

  private getFieldsFromObjectTypeDefinition(schema: GenericJsonSchemaModel, isRootSchema: boolean = false): Array<ParsedFieldDefinition> {
    const { properties = {}, required = [] } = schema;

    return Object.entries(properties).map(([name, propertyDefinition]: [string, GenericJsonSchemaModel]) => {
      let isRequired = required.includes(name);
      const schemaDefinition = this.getSchemaDefinition(propertyDefinition); // again resolve $ref if needed for every property
      // we want to represent object structures as a field definition with children and type = object
      // similar to what we do when we process type array
      if (schemaDefinition.type === 'object') {
        const { title, type, ...rest } = schemaDefinition;
        return {
          title: this.resolveTitle(name, title),
          type: 'object',
          isRequired,
          name,
          children: this.getFieldsFromObjectTypeDefinition(schemaDefinition),
          extra: {
            ...this.getExtras(rest),
            isRoot: isRootSchema
          }
        };
      }

      // name = resolveBinding(name);
      let fd = this.parseDefinition(schemaDefinition) as ParsedFieldDefinition;
      let title = this.resolveTitle(name, fd.title, fd.extra);

      // if the root object said that this property is required,
      // but this field has some rules around whether is visible
      // we need to rely only on the rules defined by extra.validation
      // TODO add test for this.
      if (isRequired) {
        const { visibility, validation } = fd.extra;
        // if there are any visibility rules, don't treat it directly as required
        if (Array.isArray(visibility)) {
          // but rather than that create a special conditional validation based on the visibility
          if (!Array.isArray(validation)) {
            fd.extra.validation = visibility.map(item => ({ required: true, onlyIf: { ...item } }));
          }

          // global flag, based on which we normally attach the yup.required(msg.REQUIRED) needs to be set to false
          isRequired = false;
        }
      }

      fd = {
        ...fd,
        name,
        title,
        isRequired,
        extra: {
          ...fd.extra,
          isRoot: isRootSchema
        }
      };
      return fd;
    });
  }

  private getFieldsFromArrayTypeDefinition(schema: ArrayTypeJsonSchemaModel): ParsedFieldDefinition {
    let { type, items = {}, title = '', ...rest } = schema;
    const itemsDefinition = this.getSchemaDefinition(items); // again resolve $ref if needed for every property

    const children = this.parseDefinition(itemsDefinition);
    const fd: ParsedFieldDefinition = {
      title,
      type, // array
      name: '',
      // set the list of children here by parsing the rest of the props and ignoring original meta
      children: Array.isArray(children) ? children : [children], // pass the item definition
      childrenType: itemsDefinition.type, // save the info about children type, so it's easier to create default values and validations
      childrenExtra: this.getExtras(itemsDefinition),
      extra: this.getExtras(rest)
    };

    return fd;
  }

  private getFieldsForCustomDefinition(schema: ResolvedJsonSchemaModel): ParsedFieldDefinition {
    return this.getFieldsFromSimpleTypeDefinition(schema);
  }

  private getFieldsFromSimpleTypeDefinition(schema: ResolvedJsonSchemaModel): ParsedFieldDefinition {
    const { type, title = '', ...rest } = schema;

    return {
      title,
      type,
      name: '',
      extra: this.getExtras(rest)
    };
  }

  private getExtras(schema: GenericJsonSchemaModel) {
    const { _meta = {} } = schema;

    let extras = EXTRAS_STANDARD_PROPS.reduce((acc: Partial<ExtraDefinition>, prop) => {
      if (prop in schema) {
        acc[prop] = schema[prop];
      }
      return acc;
    }, {});

    // we are storing enum directly or dataEnum path,
    // because in the renderer we need to know whether we have options
    // no matter whether they are provided or comming from data in order
    // to decide what type of component needs to be rendered
    if (Array.isArray(schema.enum)) {
      extras.options = schema.enum;
    }

    EXTRAS_META_PROPS.reduce((acc, prop) => {
      if (prop in _meta) {
        acc[prop] = _meta[prop];
      }
      return acc;
    }, extras);

    // add old conditional rules pattern into new one using visibility / validation
    // we assume here we are not gonna have booth conditional and visibility present at the same time
    if ('conditional' in _meta) {
      extras = {
        ...extras,
        ...this.convertConditionalIntoGrammarPattern(_meta.conditional!)
      };
    }

    return extras;
  }

  private convertConditionalIntoGrammarPattern(conditional: ConditionalModel) {
    const { rules = [], required = false } = conditional;
    // if there are any rules,
    const result: Partial<{
      validation: Array<ConditionalValidationEntityModel>;
      visibility: Array<ConditionalVisibilityEntityModel>;
    }> = {};

    // if there were any rules, we we know this was originaly controling visibility
    if (rules.length) {
      result.visibility = rules as Array<ConditionalVisibilityEntityModel>;
    }

    // and if there was a required flag we know that this field meant to be required if it's visible
    if (required) {
      result.validation = rules.map(rule => ({
        required: true,
        onlyIf: rule
      }));
    }

    return result;
  }

  private resolveTitle(name: string, title: string = '', extra: Partial<ExtraDefinition> = {}): string {
    // we want to calculate title from name only if the dataTitle was not specified
    // ! dataTitle will be always resolved with each render, we don't return it here
    if (typeof extra.dataTitle === 'string' || title.length) {
      return title;
    }

    return sentenceCase(name).replace(/([^(\d|\W)]+)(\d+)+/g, (match, $1, $2) => {
      // add spacing in scenarios where title contains a number and there is no space
      // for example addressLine1
      return $1 + ' ' + $2;
    });
  }

  // this method makes sure that if the definition was through $ref, it will get resolved
  // if not, returns the schema on the input
  private getSchemaDefinition(schema: Partial<GenericJsonSchemaModel>) {
    const { $ref, ...restOfSchemaProps } = schema;

    // backend sends us $ref also in cases when they create an enum
    // in this case, we don't want to resolve $ref, but just ignore it
    if (Array.isArray(restOfSchemaProps.enum)) {
      return restOfSchemaProps;
    }

    if (typeof $ref === 'string') {
      const schemaFromDefinition = _get(this.definitions, $ref.replace('#/definitions/', ''), {}) as ResolvedJsonSchemaModel;
      return { ...restOfSchemaProps, ...schemaFromDefinition };
    }
    return schema as ResolvedJsonSchemaModel;
  }
}
