import Typography from '@mui/material/Typography';

import { columnFormatterCheck, ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { IconStar } from 'src/components/layout/v2/Icons';
import {
  financialWorkspaceStatusFormatter,
  jurisdictionFormatter,
  settlementTimeFormatter,
  titleFormatter,
  workspaceStatusBarFormatter
} from 'src/containers/dashboard/shared/formatters';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme/screens';
import { modelKey } from 'src/utils/formUtils';
import { LinkedWorkspaceParticipant } from '../../../models';
import { UserProfileDetail } from '../models';
import { LinkedWorkspaceTableModel } from './models';
import ViewButton, { ViewButtonProps } from './row-detail/ViewButton';

const cf = columnFormatterCheck<LinkedWorkspaceTableModel>();

const fieldName = modelKey<LinkedWorkspaceTableModel>();

export const resolveColumns = ({
  screenVariant,
  userProfileDetail,
  onViewClick
}: {
  //
  screenVariant: ScreenSizeVariant;
  userProfileDetail: UserProfileDetail;
  onViewClick: (participantsHaveAccess: LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => void;
}) => {
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);

  return resolveTableColumns(screenSize, userProfileDetail, onViewClick);
};

function resolveTableColumns(
  screenSize: ScreenSizeEnum,
  userProfileDetail: UserProfileDetail,
  onViewClick: (participantsHaveAccess: LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => void
) {
  const columns: ColumnsModel<LinkedWorkspaceTableModel> = {
    isCurrent: {
      thClassName: 'w-[5px] pr-[15px]',
      label: '',
      name: fieldName('isCurrentWorkspace'),
      formatter: cf<LinkedWorkspaceTableModel['isCurrentWorkspace']>(isCurrentWorkspaceFormatter)
    },
    order: {
      thClassName: 'w-[20px]',
      label: 'WS',
      name: fieldName('order'),
      formatter: cf<LinkedWorkspaceTableModel['order']>(orderFormatter)
    },
    setlDate: {
      thClassName: 'w-[90px]',
      className: 'py-[4px]', // special padding to support 3 lines of text
      label: 'SETL DATE',
      name: fieldName('settlementDate'),
      get: ({ settlementDate, workspaceStatus, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: LinkedWorkspaceTableModel) => ({
        settlementDate,
        workspaceStatus,
        pendingProposedSettlementDate,
        expectedSettlementDate,
        isUnsupportedSettlementDate
      }),
      formatter: cf<{
        //
        settlementDate: LinkedWorkspaceTableModel['settlementDate'];
        workspaceStatusId: LinkedWorkspaceTableModel['workspaceStatus'];
        pendingProposedSettlementDate: LinkedWorkspaceTableModel['pendingProposedSettlementDate'];
        expectedSettlementDate: LinkedWorkspaceTableModel['expectedSettlementDate'];
        isUnsupportedSettlementDate: LinkedWorkspaceTableModel['isUnsupportedSettlementDate'];
      }>(settlementTimeFormatter)
    },
    title: {
      label: 'Title',
      thClassName: 'w-[100px]',
      name: fieldName('sympliId'),
      get: ({ titles, sympliId }: LinkedWorkspaceTableModel) => ({
        titles,
        sympliId
      }),
      formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
      tooltipFormatter({ anchorEl, row }) {
        switch (anchorEl.dataset.binding) {
          case 'title':
            return anchorEl.innerText;
          case 'plusN':
            return (
              <div>
                <div className="mb-[16px]">
                  <b>Multiple titles found</b>
                </div>
                {row.titles.map((item, i) => {
                  return (
                    <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                      {item}
                    </Typography>
                  );
                })}
              </div>
            );
          default:
            return null;
        }
      }
    },
    jdx: {
      thClassName: 'w-[50px]',
      label: 'JDX',
      name: fieldName('jurisdiction'),
      formatter: cf<LinkedWorkspaceTableModel['jurisdiction']>(jurisdictionFormatter)
    },
    wsStatusText: {
      thClassName: 'w-[95px]',
      label: 'WS STATUS',
      name: fieldName('workspaceStatus'),
      formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(financialWorkspaceStatusFormatter)
    },

    permission: {
      thClassName: 'w-[80px] px-[20px]',
      label: 'ACCESS',
      name: '-',
      get: ({ linkedWorkspaceParticipants, workspaceId, isCurrentWorkspace }: LinkedWorkspaceTableModel) => ({
        linkedWorkspaceParticipants,
        userProfileDetail,
        linkedToWorkspaceId: workspaceId,
        onViewClick,
        isCurrentWorkspace
      }),
      formatter: cf<ViewButtonProps>(permissionCheckFormatter)
    },
    workspaceStatusBar: {
      thClassName: 'w-[6px]',
      label: '',
      name: fieldName('workspaceStatus'),
      padding: 'none',
      formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(workspaceStatusBarFormatter)
    }
  };

  return columns;
}

function isCurrentWorkspaceFormatter<
  //
  R extends object,
  V extends boolean
>({ value }: FormatterInputModel<R, V>) {
  if (!value) {
    return null;
  }
  return (
    <div>
      <IconStar height={'16px'} width={'16px'} style={{ marginTop: 3 }} />
    </div>
  );
}

function orderFormatter<
  //
  R extends object,
  V extends number
>({ value }: FormatterInputModel<R, V>) {
  return <div className="font-[700]">{value}</div>;
}

// need to pass in the user Id, user allowed group id / user subscriber to check if the user has
// the permission to access the workspace and what role requires to redirect
function permissionCheckFormatter<
  //
  R extends object,
  V extends ViewButtonProps
>({ value }: FormatterInputModel<R, V>) {
  return (
    <ViewButton //
      {...value}
    />
  );
}
