import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  {
    checkboxLabel: {
      fontSize: 18,
      fontWeight: 'bold'
    },
    checkboxLabelTeal: {
      color: colors.TEAL
    },
    checkboxLabelBadge: {
      display: 'flex',
      alignItems: 'center'
    },
    checkboxLabelBadgeIcon: {
      height: 14,
      width: 14
    },
    checkboxLabelBadgeText: {
      marginLeft: 4,
      fontSize: 13,
      color: colors.MEDIUM_GREY
    }
  },
  {
    name: 'CheckboxLabel'
  }
);
