import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { $allOfItems, $eq, $get, $items, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { AdditionalAttachmentsDocumentTypeEnum } from '@sympli-mfe/enums-2-26/wa';

import { TitleReferenceModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - titleReferences[*].partDescription

 schema version:
{
  "landDescriptions": {
    "$items": {
      "$allOf": {
        "isSelected": false
      }
    }
  }
}
*/
export const IS_REQUIRED_CHECK_TITLE_REFERENCES$PART_DESCRIPTION = createContextCheck((context: TitleReferenceModel): boolean =>
  $allOfItems($items(context, 'landDescriptions'), item => $eq($get(item, 'isSelected'), false))
);

export const IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS = (mcpNumber?: string, documentAttachments?: DocumentAttachmentItemModel[]) =>
  !isMcpNumberFilled(mcpNumber) && !hasTermAndConditionAttachment(documentAttachments);

export const IS_REQUIRED_CHECK_MCP_NUMBER = (additionalCovenants?: string, documentAttachments?: DocumentAttachmentItemModel[]) =>
  !isAdditionalCovnenantsFilled(additionalCovenants) && !hasTermAndConditionAttachment(documentAttachments);

export const IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT = (mcpNumber?: string, additionalCovenants?: string) =>
  !isMcpNumberFilled(mcpNumber) && !isAdditionalCovnenantsFilled(additionalCovenants);

const isMcpNumberFilled = (mcpNumber?: string) => Boolean(mcpNumber?.trim());
const isAdditionalCovnenantsFilled = (additionalCovenants?: string) => Boolean(additionalCovenants?.trim());
const hasTermAndConditionAttachment = (documentAttachments?: DocumentAttachmentItemModel[]) =>
  documentAttachments?.some(attachment => attachment.documentType === AdditionalAttachmentsDocumentTypeEnum.TermsAndConditions);
