import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { useSafeDispatch } from 'src/hooks';
import UserProfile from './UserProfile';

function UserProfileContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const { isLoading, data, error } = useProfile();

  return (
    <UserProfile //
      // redux data
      data={data}
      isLoading={isLoading}
      error={error}
      // other
      dispatch={dispatch}
    />
  );
}

export default React.memo(UserProfileContainer);
