import { AddressChangeConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { WaDocumentPartyAddressJustification } from './models';
import { WaAddressChange } from './ui-models';

export const waAddressChangeConversion: AddressChangeConversion<WaDocumentPartyAddressJustification, WaAddressChange> = {
  convertAddressChangeFromApiToForm: ({ addressBookId }: WaDocumentPartyAddressJustification) => ({
    addressBookId: addressBookId ?? ''
  }),
  convertAddressChangeFromFormToApi: ({ addressBookId }: WaAddressChange) => ({
    addressBookId: addressBookId ?? ''
  })
};
