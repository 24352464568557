import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    checkboxComponentRoot: {
      alignItems: 'start'
    },
    mortgageItem: {
      marginTop: '6px'
    },
    warning: {
      color: theme.palette.error.main,
      fontSize: 12,
      paddingBottom: 5
    }
  });

export type ClassKeys = typeof styles;

export default styles;
