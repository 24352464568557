import * as React from 'react';

import { LodgementCaseStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { StatusProgressModel } from 'src/components/workspace-status-rebuild/status-progress-with-breadcrumb/models';
import { getVariant } from 'src/containers/dashboard/shared/formatters/helpers';
import { resolveLodgementCaseStepAndVariant } from '../lodgement-case-status/conversion';
import DeleteModeButtons from './components/delete-mode-buttons';
import DocumentListAddContainer from './components/document-list-add';
import { DocumentListBase } from './components/document-list-base';
import MultipleLodgementCaseHeader from './components/document-list-base/components/MultipleLodgementCaseHeader';
import { DocumentListDeleteContainer } from './components/document-list-delete';
import DocumentListReorderContainer from './components/document-list-reorder';
import { removeIconsOfSupportingDocuments } from './components/document-list-reorder/helpers';
import { DocumentListToolbarContainer } from './components/document-list-toolbar';
import { ReorderButtonsPortalTarget } from './components/reorder-mode-buttons';
import { DocumentListAction, LodgementCaseDetail } from './models';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
export interface Props {
  //route props
  workspaceId: string;
  participantId: string;
  documentId?: string;
  // additional workspace info
  workspaceTypeId?: WorkspaceTypeEnum;
  // workspace document items
  lodgementCases: LodgementCaseDetail[];
  // other
  mode: DocumentListAction;
  onModeChange(mode: DocumentListAction): void;
  isToolbarEnabled: boolean;
  isCriticalRolesEnabled: boolean;
}

function DocumentListMLC({
  //
  workspaceId,
  participantId,
  workspaceTypeId,
  documentId,
  lodgementCases,
  mode,
  onModeChange,
  isToolbarEnabled,
  isCriticalRolesEnabled
}: Props) {
  const handleOnCancel = React.useCallback(() => {
    onModeChange('default');
  }, [onModeChange]);

  function getDocumentList(lodgementCaseDetail: LodgementCaseDetail) {
    switch (mode) {
      case 'reorder':
        if (!lodgementCaseDetail.lodgementCase?.isPrimary) {
          return (
            <DocumentListBase //
              // workspace documents
              items={lodgementCaseDetail.documents}
              dataTestId="document-list"
              isCriticalRolesEnabled={isCriticalRolesEnabled}
            />
          );
        } else {
          return (
            <DocumentListReorderContainer //
              // route props
              workspaceId={workspaceId}
              participantId={participantId}
              // additional workspace info
              workspaceTypeId={workspaceTypeId}
              // workspace documents
              items={removeIconsOfSupportingDocuments(lodgementCaseDetail.documents)}
              // other
              onCancel={handleOnCancel}
              onModeChange={onModeChange}
            />
          );
        }
      case 'delete':
        return (
          <DocumentListDeleteContainer //
            // route props
            workspaceId={workspaceId}
            participantId={participantId}
            documentId={documentId}
            // additional workspace info
            workspaceTypeId={workspaceTypeId}
            // lodgement case and documents info
            lodgementCaseDetail={lodgementCaseDetail}
            // other
            onCancel={handleOnCancel}
          />
        );
      case 'add':
        return (
          <DocumentListAddContainer //
            // route props
            workspaceId={workspaceId}
            participantId={participantId}
            documentId={documentId}
            // additional workspace info
            workspaceTypeId={workspaceTypeId}
            // workspace documents
            items={lodgementCaseDetail.documents}
            isPrimary={lodgementCaseDetail.lodgementCase?.isPrimary ?? false}
            // other
            onCancel={handleOnCancel}
          />
        );
      default:
        return (
          <DocumentListBase //
            // workspace documents
            items={lodgementCaseDetail.documents}
            dataTestId={`${lodgementCaseDetail.lodgementCase?.name}`}
            isCriticalRolesEnabled={isCriticalRolesEnabled}
          />
        );
    }
  }

  return (
    <>
      {lodgementCases.map(lodgementCaseDetail => {
        if (!lodgementCaseDetail.lodgementCase) {
          return null;
        }

        const { lodgementCaseStatusId, id, isPrimary, name, responsibleParticipantId } = lodgementCaseDetail.lodgementCase;
        const documentList = getDocumentList(lodgementCaseDetail);
        const allDocumentsFullySigned: boolean = lodgementCaseDetail.documents.every(d => d.isFullySignedByAllParticipants);

        const info: Pick<StatusProgressModel, 'step' | 'variant'> | null = resolveLodgementCaseStepAndVariant({
          lodgementCaseStatusId: lodgementCaseStatusId,
          allDocumentsSigned: allDocumentsFullySigned
        });

        if (!info) {
          return null;
        }
        const text = info.step.description;
        const variant = getVariant(info);

        return (
          <MultipleLodgementCaseHeader
            defaultOpenExpansion={responsibleParticipantId === participantId}
            lodgementCaseId={id}
            key={id}
            isLocked={lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementRequested} // only show lock when lodging
            disableExpansion={isPrimary}
            name={name}
            text={text}
            children={documentList}
            variant={variant}
            shouldCollapse={mode === 'reorder'}
          />
        );
      })}
      {isToolbarEnabled && (
        <DocumentListToolbarContainer //
          // route props
          workspaceId={workspaceId}
          participantId={participantId}
          // lodgement cases
          lodgementCases={lodgementCases}
          // other
          onActionClick={onModeChange}
        />
      )}
      {mode === 'delete' && <DeleteModeButtons handleOnCancel={handleOnCancel} />}
      {mode === 'reorder' && <ReorderButtonsPortalTarget />}
    </>
  );
}

export default React.memo(DocumentListMLC);
