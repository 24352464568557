import { WA_ADDRESS_FORM_CONFIG as WA_WOC_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/wa/2-26/config';
import { waNameChangeConversion } from '@sympli-mfe/document-components/party-form/wa/2-26/components/party-justification';
import { convertAttachmentsFromFormToApiModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { waAddressChangeConversion } from '../../components/address-justification';
import { WA_WOC_PARTY_FORM_CONFIG } from './config';
import { WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { fallbackMap } from './fallback';
import { getCaveatorsForSelectedCaveat, getCaveatsBeingWithdrawn, getSelectedCaveats, getTitleReferences } from './helper';
import { ApiWithdrawalOfCaveat2_26_0Model, WithdrawalOfCaveat2_26_0Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: WA_WOC_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: WA_WOC_PARTY_FORM_CONFIG,
  nameChangeConversion: waNameChangeConversion,
  addressChangeConversion: waAddressChangeConversion
});

class Converter implements IConverter<WithdrawalOfCaveat2_26_0Model, ApiWithdrawalOfCaveat2_26_0Model> {
  fromApiToFormModel(apiModel: ApiWithdrawalOfCaveat2_26_0Model, _context: IConverterContext): WithdrawalOfCaveat2_26_0Model {
    // Implement any necessary conversion from API to form values
    const { caveats } = apiModel;
    const relinquishingIds = caveats.map(caveat => caveat.caveators.map(caveator => caveator.partyBookId)).flat();
    const titleReferences = getTitleReferences(caveats);
    const withdrawCaveats = getCaveatsBeingWithdrawn(caveats, titleReferences);

    let formModel: WithdrawalOfCaveat2_26_0Model = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
      addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
      titleReferences: titleReferences,
      withdrawCaveats: withdrawCaveats,
      caveators: getCaveatorsForSelectedCaveat(withdrawCaveats)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: WithdrawalOfCaveat2_26_0Model, _originalApiModel: ApiWithdrawalOfCaveat2_26_0Model): ApiWithdrawalOfCaveat2_26_0Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      addressBook: addressBookFromForm,
      titleReferences,
      caveators,
      withdrawCaveats,
      ...rest
    } = formModel;

    const selectedCaveats = getSelectedCaveats(withdrawCaveats);
    const selectedtitleReferences = titleReferences.filter(x => x.isSelected);

    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], _originalApiModel.partyBook || []),
      addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], _originalApiModel.addressBook || []),
      attachments: convertAttachmentsFromFormToApiModel(formModel.additionalAttachments, WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS),
      caveats: formModel.caveats.map(c => {
        const caveat = selectedCaveats.find(e => e.dealingNumber === c.dealingNumber) || { ...c, isSelected: false };
        // Updating caveats object with changes made by the user within EditForm
        return {
          ...caveat,
          titleReferences: caveat.titleReferences.map(t => {
            // Updating title reference selection incase it has been updated by the user
            return selectedtitleReferences.find(x => x.reference === t.reference) || { ...t, isSelected: false };
          }),
          caveators: caveat.caveators.map(m => {
            // Update the caveator with the user modified one
            return caveators.find(x => x.partyBookId === m.partyBookId) || m;
          })
        };
      })
    };
  }
}

export default new Converter();
