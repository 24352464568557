import * as React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import { IconDocError, IconErrorCircle } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';
import { DocumentIssuesMode } from './WorkflowPanelIssues';

interface Props {
  buttonTheme?: 'light' | 'dark';
  mode: DocumentIssuesMode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, mode: DocumentIssuesMode) => void;
}

function WorkflowPanelIssuesButton({ buttonTheme = 'light', mode, onClick }: Props) {
  const classes = useStyles();

  switch (mode) {
    case 'error':
      return (
        <ButtonLink icon={<IconDocError />} onClick={event => onClick(event, 'error')} color="inherit">
          See issues
        </ButtonLink>
      );
    case 'warning':
      return (
        <ButtonLink iconColor="secondary" icon={<IconErrorCircle />} onClick={event => onClick(event, 'warning')} className={classes[buttonTheme]} color="inherit">
          See warnings
        </ButtonLink>
      );
    case 'all':
      return (
        <ButtonLink icon={<IconDocError />} onClick={event => onClick(event, 'all')} className={classes[buttonTheme]} color="inherit">
          See all
        </ButtonLink>
      );
    case 'default':
    default:
      return null;
  }
}

export default React.memo(WorkflowPanelIssuesButton);
