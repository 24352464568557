import React, { memo } from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { useStyles } from './styles';

export interface Props {
  dismissWelcomeMessageHandler(): void;
  open: boolean;
}

const WelcomeMessage = React.forwardRef(({ dismissWelcomeMessageHandler, open }: Props, ref) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          className: 'bg-[var(--neutral-400)] !opacity-50' // because MUI has inline style of opacity 1, we have to use !important
        }
      }}
    >
      <Box ref={ref} className={classes.root}>
        <Typography className={classes.title}>Welcome to Reassign!</Typography>
        <div className={classes.divider}></div>
        <Typography className={classes.text}>
          This page allows you to reassign team members, <b>One Group</b> at a time.
        </Typography>
        <Typography className={classNames(classes.text, 'mb-[32px]')}>
          Change the preselected group by simply using the <b>Group Filter</b>.
        </Typography>

        <Typography className={classNames(classes.subText, 'mb-[16px]')}>
          Note | <b>Navigation</b> to workspaces has been <b>disabled</b> in the ‘Reassign’ view for your ease of use.
        </Typography>

        <SympliButton hasRadius variant="contained" className={classes.button} onClick={dismissWelcomeMessageHandler}>
          Got it!
        </SympliButton>
      </Box>
    </Modal>
  );
});

export default memo(WelcomeMessage);
