import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      boxShadow: 'none',
      background: 'none',
      '&:before': {
        backgroundColor: 'unset'
      },
      marginBottom: 16,
      '&.Mui-expanded': {
        marginTop: 0
      }
    },
    summaryRoot: {
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 'unset',
      borderBottom: `1px solid ${colors.GREEK_WATERS}`,

      '&.Mui-expanded': {
        minHeight: 'unset',
        paddingBottom: 3
      }
    },
    summaryContent: {
      marginTop: 0,
      marginBottom: 0,
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0
      }
    }
  }),
  {
    name: 'MultipleLodgementCaseHeader'
  }
);
