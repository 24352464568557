import * as React from 'react';

import Button from '@mui/material/Button';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import DownloadMenu from 'src/components/download-menu';
import { DownloadItem } from 'src/components/download-menu/DownloadMenu';

export interface Props {
  iconDownload?: JSX.Element;
  primaryButtonText: string;
  menuItems?: Array<{ icon?: JSX.Element; text: string; onClick: (event: React.MouseEvent<HTMLElement>) => void }>;
  disabled?: boolean;
}

function ReportDownload({ iconDownload, primaryButtonText, menuItems, disabled }: Props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  if (!menuItems?.length) return null;

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelection = () => {
    setOpen(false);
  };

  const items: DownloadItem[] = menuItems?.map(item => {
    return { icon: item.icon, text: item.text, onClick: item.onClick };
  });

  return (
    <FlexLayout flexDirection="column" alignItems="flex-end" className="relative">
      <Button variant="outlined" color="primary" ref={anchorRef} onClick={handleClick} disabled={disabled} aria-label={primaryButtonText}>
        {iconDownload}
        {primaryButtonText}
      </Button>
      <DownloadMenu onClickAway={handleClose} onItemSelected={handleSelection} open={open} items={items} arrowOffset={100} />
    </FlexLayout>
  );
}

export default ReportDownload;
