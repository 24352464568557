import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { PaymentMethodEnum } from '@sympli/api-gateway/enums';
import { UpdateWorkspaceDirectionsApiRequestBody } from '@sympli/api-gateway/models';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { useStyles } from './styles';

interface LineItemConfirmationDialogProps {
  open: boolean;
  onClose: (confirmed?: boolean) => any;
  item: UpdateWorkspaceDirectionsApiRequestBody['directions'][number];
}

function LineItemConfirmationDialog({ open, onClose, item }: LineItemConfirmationDialogProps) {
  const { bankDetails, bpayDetails, paymentMethod, amount, holdingAccountDetails, linkedSettlementItem } = item;
  const classes = useStyles();

  const content: JSX.Element | null = useMemo(() => {
    switch (paymentMethod) {
      case PaymentMethodEnum.BankTransfer:
      case PaymentMethodEnum.TrustAccount:
      case PaymentMethodEnum.HoldingAccount:
        if (bankDetails == null) {
          // this should never happen, but more defensive way to protect it
          return null;
        }

        // todo: this is very weird logic, when we post holding account has the reference, but when we get the response back
        // the reference is gone. And backend magic maps the reference to the bank detail. We should fix the api contract to make it consistent.
        // hack way to complete the ticket, new ticket will be create to fix this issue.
        // will update it once WEB-36953 is done
        let reference = bankDetails.reference || '';
        if (paymentMethod === PaymentMethodEnum.HoldingAccount) {
          if (holdingAccountDetails?.reference) {
            reference = (holdingAccountDetails as any).reference;
          }
        }

        return (
          <>
            <ReadOnlyField label="Payee name" value={bankDetails.accountDescription ?? bankDetails.accountName} className={classes.firstItem} />
            <ReadOnlyField label="Reference (optional)" value={reference} />
            <ReadOnlyField label="Account description" value={bankDetails.accountDescription ?? bankDetails.accountName} />
            <FlexLayout flexDirection="row">
              <ReadOnlyField label="BSB" value={bankDetails.bsb} />
              <ReadOnlyField label="Account number" value={bankDetails.accountNumber} />
            </FlexLayout>
            <ReadOnlyField label="Amount" value={`${currency(amount)}`} />
          </>
        );
      case PaymentMethodEnum.BPAY:
        if (bpayDetails == null) {
          // this should never happen, but more defensive way to protect it
          return null;
        }
        return (
          <>
            <ReadOnlyField label="Biller code" value={bpayDetails.billerCode} className={classes.firstItem} />
            <ReadOnlyField label="Biller Ref" value={bpayDetails.billerReference} />
            <ReadOnlyField label="Amount" value={`${currency(amount)}`} />
          </>
        );
      case PaymentMethodEnum.Linked:
        if (linkedSettlementItem == null) {
          return null;
        }
        return (
          <>
            <ReadOnlyField label="Reference" value={linkedSettlementItem.reference ?? ''} className={classes.firstItem} />
            <ReadOnlyField label="Amount" value={`${currency(amount)}`} />
          </>
        );

      default:
        return null;
    }
  }, [bankDetails, bpayDetails, paymentMethod, classes, amount, holdingAccountDetails, linkedSettlementItem]);

  return (
    <ConfirmationDialog open={open} title="Before continuing" okButtonText="Continue" onClose={onClose} classes={{ dialogPaper: classes.dialogPaper }}>
      <Typography className={classes.desc}>
        Please ensure the following details are correct. If these details are incorrect, it may not be possible to recover these funds.
      </Typography>
      {content}
    </ConfirmationDialog>
  );
}

export default React.memo(LineItemConfirmationDialog);
