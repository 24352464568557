import { Action, createReducer } from 'typesafe-actions';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { useStoreSelector } from 'src/hooks';
import { actionFetchStandaloneWorkspacesFeedV2 } from '../actions';
import { StandaloneWorkspacesStateV2 } from '../models';

export const initialState: StandaloneWorkspacesStateV2 = {
  rowDetailIndex: undefined,

  items: [],
  status: 'idle',
  error: undefined,
  updatedTime: new Date(),
  totalCount: 0,

  query: {
    assignmentType: AssignmentTypeEnum.AssignedToMe,
    sympliId: undefined,
    reference: undefined,
    lastAccessPeriod: undefined,
    completedDatePeriod: undefined,
    archivedDatePeriod: undefined,
    jurisdictions: undefined,
    myTasks: undefined,
    documentTypes: undefined,
    documentStatus: undefined,
    workspaceStatus: undefined,
    pageSize: 20,
    pageNumber: 1 // service page number is index from 1
  }
};

export const useStandaloneWorkspacesFeedV2 = () => {
  const workspacesFeed = useStoreSelector(store => store.standaloneWorkspacesFeedV2);

  if (workspacesFeed) {
    return workspacesFeed;
  }

  // We need to retain searchTerm (if any) when switching between tabs
  return { ...initialState, query: { ...initialState.query } };
};

const standaloneWorkspacesFeedV2Reducer = createReducer<
  //
  StandaloneWorkspacesStateV2,
  Action
>(initialState)
  .handleAction(actionFetchStandaloneWorkspacesFeedV2.request, (state, action): StandaloneWorkspacesStateV2 => {
    return {
      ...initialState,
      query: action.payload,
      items: [],
      status: 'pending',
      error: undefined
    };
  })
  .handleAction(actionFetchStandaloneWorkspacesFeedV2.success, (state, action): StandaloneWorkspacesStateV2 => {
    return {
      ...state,
      ...action.payload.data,
      rowDetailIndex: undefined,
      status: 'resolved',
      updatedTime: new Date(),
      error: undefined
    };
  })
  .handleAction(actionFetchStandaloneWorkspacesFeedV2.failure, (state, action): StandaloneWorkspacesStateV2 => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default standaloneWorkspacesFeedV2Reducer;
