import * as React from 'react';

import visuallyHidden from '@mui/utils/visuallyHidden';

import { LinkedWorkspaceTitleModel } from 'src/containers/shared/linked-workspace-list/models';
import { useSharedStyles } from '../styles';

interface Props {
  titles: LinkedWorkspaceTitleModel[];
}

function TitleList({
  //
  titles
}: Props) {
  const sharedClasses = useSharedStyles();
  return titles.map(({ reference, propertyAddress }, i) => (
    <div key={reference} className={sharedClasses.item}>
      <b>
        <span style={visuallyHidden}>Reference</span>
        {reference}
      </b>
      <div>
        <span style={visuallyHidden}>Address</span>
        {propertyAddress}
      </div>
    </div>
  ));
}

export default React.memo(TitleList);
