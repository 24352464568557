import http from 'src/utils/http';

export type UnacceptSettlementDateTimeApiResponse =
  | {
      isSuccess: true;
      errors: null;
    }
  | {
      isSuccess: false;
      errors: Array<string>;
    };

export const unacceptSettlementDateTime = (workspaceId: string, participantId: string) => {
  const url = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/revoke-settlement-date-approval`;
  return http.put<UnacceptSettlementDateTimeApiResponse>(url, {});
};
