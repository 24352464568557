import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    paymentTypeCol: {
      width: '10%',
      paddingLeft: 20,
      paddingRight: 9,
      position: 'relative'
    },
    accountDetailCol: {
      width: '75%'
    },
    accountDetailWithBreakdownsCol: {
      width: '45%'
    },
    feeBreakdownCol: {
      width: '30%'
    },
    amountCol: {
      width: '15%',
      textAlign: 'right'
    },
    formLabel: {
      fontSize: 13,
      fontWeight: 'bold',
      paddingTop: 16
    },
    bottomBorder: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: 8,
      marginTop: 4
    }
  });

export type ClassKeys = typeof styles;

export default styles;
