import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'auto'
      // overflowY: 'auto',
      // overflowX: 'hidden'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
