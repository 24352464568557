import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { yupEmailAddress } from 'src/containers/documents/scaffolded-form/qld/2-24/helpers/yupEmailAddress';
import { yupPhoneNumber } from 'src/containers/documents/scaffolded-form/qld/2-24/helpers/yupPhoneNumber';
import { yupTransferee } from '../../components/transferee/validationSchema';
import { TransfereeDataModel, TransfereeReferenceModel } from '../../models';

export const yupTransfereeData = yup
  .object<TransfereeDataModel>({
    transferees: yup.array<TransfereeReferenceModel>(yupTransferee).defined(),
    phone: yupPhoneNumber,
    postalAddressId: yup.string().required(msg.REQUIRED),
    email: yupEmailAddress,
    trustName: yup.string().nullable(true).default('').trim()
  })
  .defined();
