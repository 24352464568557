import { NSW_PARTY_FORM_CONFIG as NSW_LEASE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { Lease2_21_1Model, TitleReferenceModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import { getLessorPartyBookIds, getSelectedTitleReferences } from '../../helpers';

interface PartyBookValidationContext {
  titleReferences: TitleReferenceModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: Lease2_21_1Model): PartyBookValidationContext => {
  return {
    titleReferences: parentContext.titleReferences
  };
};

const checkShouldValidateItem = ({ titleReferences }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => {
  const selectedTitleReferences = getSelectedTitleReferences(titleReferences);
  const selectedLessorsIds = getLessorPartyBookIds(selectedTitleReferences);

  const isSelectedLessor = !!~selectedLessorsIds.indexOf(partyIdForCheck);
  return isSelectedLessor;
};

const yupPartyItem = resolvePartyModelValidationSchema<{}, PartyBookValidationContext>(NSW_LEASE_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
