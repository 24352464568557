import React, { useCallback } from 'react';

import { useField } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getNestedErrorStringByPriority } from '@sympli-mfe/document-forms-framework/utils';

import { RelinquishingProprietorGroupModel, TenancyPartyModel } from '../../models';
import Transferor from '../transferor';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  numberOfTenantsInPrecedingGroups: number;
  hasSiblingGroups?: boolean;
  isGroupStructureMeaningful: boolean;
  focusRef: React.RefObject<ButtonBaseActions>;
  nextFocusRef?: React.RefObject<ButtonBaseActions>;
}

function RelinquishingProprietorGroup({
  //
  name,
  numberOfTenantsInPrecedingGroups,
  hasSiblingGroups = numberOfTenantsInPrecedingGroups > 0,
  isGroupStructureMeaningful,
  focusRef
}: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<RelinquishingProprietorGroupModel>(name);
  const [, { value, error, touched, contextPath: fieldContextPath = name }] = useField<RelinquishingProprietorGroupModel>(name);
  const [errorMessage, dataErrorName] = getNestedErrorStringByPriority<RelinquishingProprietorGroupModel>(fieldContextPath, error, touched, [fieldName('parties')]); // Error message covers group and group's parties array
  const tenantCount = value.parties.length;
  const shouldShowJointTenantsLabel = isGroupStructureMeaningful && tenantCount > 1;

  const renderItemTitle = useCallback(
    ({ itemIndex, itemCount }: DocumentArrayItemRenderProps<TenancyPartyModel>) =>
      hasSiblingGroups || itemCount > 0 ? `Transferor ${numberOfTenantsInPrecedingGroups + itemIndex + 1}` : 'Transferor', // TODO: Checkbox for non-Vic transferors
    [numberOfTenantsInPrecedingGroups, hasSiblingGroups]
  );

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<TenancyPartyModel>) => <Transferor name={itemBinding} focusRef={focusRef} />,
    [focusRef]
  );

  return (
    <div data-name={fieldContextPath} className={classes.root}>
      {shouldShowJointTenantsLabel && (
        <Typography className={classes.jointTenantsTitle} variant="h3">
          Joint Proprietors
        </Typography>
      )}
      {errorMessage && (
        <FormHelperText role="alert" error data-error-name={dataErrorName}>
          {errorMessage}
        </FormHelperText>
      )}
      <DocumentFieldArray //
        arrayBinding={fieldName('parties')}
        renderItem={renderItem}
        itemTitle={renderItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
      {/* {VISIBILITY_CHECK_TRANSFEROR_DETAILS$RELIQUISHING_PROPRIETOR_GROUPS$SHARE_FRACTION(value) && (
        <Field //
          name={fieldName('shareFraction')}
          component={FractionField}
          disabled={disabled}
          label={resolveLabel('Share fraction', true)}
        />
      )} */}
    </div>
  );
}

export default React.memo(RelinquishingProprietorGroup);
