import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { SA_MORTGAGE_PARTY_FORM_CONFIG } from '../../config';
import { NecdsPartyCapacityEnum, PARTY_CAPACITY_LOOKUP_OPTIONS } from '../../enums';

// path: mortgageeDetails
const yupMortgageeDetails = resolveMemoizedTenancyValidation({
  partyCapacityLookup: PARTY_CAPACITY_LOOKUP_OPTIONS,
  partyCapacityMinor: NecdsPartyCapacityEnum.Minor,
  isAddressRequired: true,
  partyFormConfig: SA_MORTGAGE_PARTY_FORM_CONFIG
});

export default yupMortgageeDetails;
