import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root() {
      return {
        padding: '0 32px',
        height: 60,
        background: theme.palette.grey[50],
        flexShrink: 0,
        position: 'sticky',
        top: 0,
        zIndex: 2
      };
    }
  }),
  {
    name: 'SettingContentTopPanel'
  }
);
