import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { INITIAL_FINAL_TENANCY_HOLDINGS_MODEL } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/final-holdings';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { NSW_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { convertPartiesToFormModel_2_21_1, convertTransferorsFromFormToApiModel, convertTransferorsToFormModel, generateTransferors } from './helpers';
import { ApiTransfer2_21_1Model, Transfer2_21_1Model } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification, NswNameChange>({
  partyFormConfig: NSW_TRANSFER_PARTY_FORM_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

class Converter implements IConverter<Transfer2_21_1Model, ApiTransfer2_21_1Model> {
  fromApiToFormModel(apiModel: ApiTransfer2_21_1Model, _context: IConverterContext): Transfer2_21_1Model {
    const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
    const formModel: Transfer2_21_1Model = {
      ...apiModel,
      partyBookApi: apiModel.partyBook,
      partyBook: convertPartiesToFormModel_2_21_1(apiModel.partyBook, risInvolvedPartyIds),
      finalTenancyHoldings: INITIAL_FINAL_TENANCY_HOLDINGS_MODEL,
      transferees: convertReceivingTenancyDetailFromApiToForm(apiModel.transferees),
      transferors: convertTransferorsToFormModel(apiModel.transferors, true)
    };

    if (apiModel.needToGeneratePartyBook) {
      generateTransferors(formModel);
    }

    const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
    return formModelWithDefaultMapApplied;
  }

  fromFormToApiModel(formValues: Transfer2_21_1Model, _originalApiModel: ApiTransfer2_21_1Model): ApiTransfer2_21_1Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook,
      partyBookApi,
      transferors,
      transferees,
      finalTenancyHoldings,
      mergeFailedReason: mergeFailed,
      ...rest
    } = formModel;

    PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);

    const result: ApiTransfer2_21_1Model = {
      ...rest,
      attachments: formModel.documentAttachments,
      needToGeneratePartyBook: false,
      transferors: convertTransferorsFromFormToApiModel(transferors),
      partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
      transferees: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: transferees, partyBookFromForm: partyBook || [] })
    };

    return result;
  }
}

export default new Converter();
