import * as yup from 'yup';

import { EmptyContext } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { filterTitleReferences, getSelectedMortgages } from '../../helpers';
import { DischargeOfMortgage2_17_1Model, MortgageModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: mortgages
const yupMortgages = yup
  .array<MortgageModel, EmptyContext>()
  .defined()
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .testContextualRule({
    uniqueTestName: '"mortgages" contextual validation rule #1',
    requirement: (parent: DischargeOfMortgage2_17_1Model /*, context: EmptyContext*/): boolean => {
      return getSelectedMortgages(parent.mortgages).length > 0;
    },
    message: 'At least 1 mortgage must be selected'
  })
  .testContextualRule({
    uniqueTestName: 'titleReferences must contain one common mortgage',
    requirement: (parent: DischargeOfMortgage2_17_1Model /*, context: EmptyContext*/): boolean => {
      // skip this validation if no mortgage is selected
      if (!getSelectedMortgages(parent.mortgages).length) {
        return true;
      }
      // verify that mortgages share some common title reference
      return filterTitleReferences(parent.mortgages).length > 0;
    },
    message: 'The mortgages selected do not share a common title. Please review and try again.'
  })
  .log();

export default yupMortgages;
