import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import {
  actionFetchGroupDetails,
  actionFetchGroupDistributionsWorkflow,
  actionFetchGroupDocumentsWorkflow,
  actionFetchGroupLodgement,
  actionFetchGroupsFeed,
  actionFetchSubscriberTrustAccounts,
  GroupDetailsApiRequest
} from '../actions/groups';
import { GroupDetailsApiResponse, GroupDocumentsWorkflowApiResponse, GroupsApiResponse, SubscriberTrustAccountsApiResponse } from '../group-settings/models';
import { GroupLodgementApiResponse } from '../reducers/groupLodgement';

const fetchGroupsFeed = () => http.get<GroupsApiResponse[]>('/settings/groups');

export function* sagaFetchGroupsFeed() {
  try {
    const data = yield* call(fetchGroupsFeed);

    yield put(actionFetchGroupsFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupsFeed.failure({ error }));
  }
}

const fetchGroupsDetail = (groupId: GroupDetailsApiRequest) => http.get<GroupDetailsApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}`);

export function* sagaFetchGroupsDetail(action: ReturnType<typeof actionFetchGroupDetails.request>) {
  try {
    const data = yield* call(fetchGroupsDetail, action.payload);

    yield put(actionFetchGroupDetails.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupDetails.failure({ error }));
  }
}

const fetchSubscriberTrustAccounts = () => http.get<SubscriberTrustAccountsApiResponse>(`/settings/trust-accounts`);

export function* sagaFetchSubscriberTrustAccounts(action: ReturnType<typeof actionFetchSubscriberTrustAccounts.request>) {
  try {
    const data = yield* call(fetchSubscriberTrustAccounts);

    yield put(actionFetchSubscriberTrustAccounts.success({ data }));
  } catch (error) {
    yield put(actionFetchSubscriberTrustAccounts.failure({ error }));
  }
}

const fetchGroupDocumentsWorkflow = (groupId: GroupDetailsApiRequest) =>
  http.get<GroupDocumentsWorkflowApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}/groupDocumentWorkflow`);

export function* sagaFetchGroupDocumentsWorkflow(action: ReturnType<typeof actionFetchGroupDocumentsWorkflow.request>) {
  try {
    const data = yield* call(fetchGroupDocumentsWorkflow, action.payload);

    yield put(actionFetchGroupDocumentsWorkflow.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupDocumentsWorkflow.failure({ error }));
  }
}

const fetchGroupDistributionsWorkflow = (groupId: GroupDetailsApiRequest) =>
  http.get<GroupDocumentsWorkflowApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}/groupDistributionsWorkflow`);

export function* sagaFetchGroupDistributionsWorkflow(action: ReturnType<typeof actionFetchGroupDistributionsWorkflow.request>) {
  try {
    const data = yield* call(fetchGroupDistributionsWorkflow, action.payload);

    yield put(actionFetchGroupDistributionsWorkflow.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupDistributionsWorkflow.failure({ error }));
  }
}

const fetchGroupLodgement = (groupId: GroupDetailsApiRequest) => http.get<GroupLodgementApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}/groupLodgementPreferences`);

export function* sagaFetchGroupLodgement(action: ReturnType<typeof actionFetchGroupLodgement.request>) {
  try {
    const data = yield* call(fetchGroupLodgement, action.payload);

    yield put(actionFetchGroupLodgement.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupLodgement.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchGroupsFeed.request, sagaFetchGroupsFeed),
  takeLatest(actionFetchGroupDetails.request, sagaFetchGroupsDetail),
  takeLatest(actionFetchSubscriberTrustAccounts.request, sagaFetchSubscriberTrustAccounts),
  takeLatest(actionFetchGroupDocumentsWorkflow.request, sagaFetchGroupDocumentsWorkflow),
  takeLatest(actionFetchGroupDistributionsWorkflow.request, sagaFetchGroupDistributionsWorkflow),
  takeLatest(actionFetchGroupLodgement.request, sagaFetchGroupLodgement)
];
