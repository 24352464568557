import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      margin: `20px 0`
    },
    title: {
      fontSize: 14
    },
    changeOrderButton: {
      marginLeft: 4
    },
    changeOrderIcon: {
      marginRight: 2
    }
  }),
  { name: 'LinkAndReorderPanel' }
);
