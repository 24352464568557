import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// MR - moved
// export enum JurisdictionsEnum {
//   NSW = 0,
//   VIC = 1,
//   QLD = 2,
//   TAS = 3,
//   WA = 4,
//   ACT = 5,
//   NT = 6,
//   SA = 7
// }

export const jurisdictionNameMapping: Record<JurisdictionsEnum, keyof typeof JurisdictionsEnum> = {
  [JurisdictionsEnum.NSW]: 'NSW',
  [JurisdictionsEnum.VIC]: 'VIC',
  [JurisdictionsEnum.QLD]: 'QLD',
  [JurisdictionsEnum.TAS]: 'TAS',
  [JurisdictionsEnum.WA]: 'WA',
  [JurisdictionsEnum.ACT]: 'ACT',
  [JurisdictionsEnum.NT]: 'NT',
  [JurisdictionsEnum.SA]: 'SA'
};

const allJurisdictionList = [
  JurisdictionsEnum.NSW,
  JurisdictionsEnum.VIC,
  JurisdictionsEnum.QLD,
  JurisdictionsEnum.TAS,
  JurisdictionsEnum.WA,
  JurisdictionsEnum.ACT,
  JurisdictionsEnum.NT,
  JurisdictionsEnum.SA
];
const supportedJurisdictionList = [JurisdictionsEnum.NSW, JurisdictionsEnum.VIC, JurisdictionsEnum.QLD];
export const jurisdictionOptions: Array<LookupEnumModel<number>> = supportedJurisdictionList.map(jurisdiction => ({
  id: jurisdiction,
  name: jurisdictionNameMapping[jurisdiction]
}));

export const supportedJurisdictionStateOptions: Array<LookupEnumModel<string>> = supportedJurisdictionList.map(jurisdiction => {
  const name = jurisdictionNameMapping[jurisdiction];
  return {
    id: name,
    name
  };
});

export const allJurisdictionStateOptions: Array<LookupEnumModel<string>> = allJurisdictionList.map(jurisdiction => {
  const name = jurisdictionNameMapping[jurisdiction];
  return {
    id: name,
    name
  };
});

export interface JurisdictionOptionModel {
  id: number;
  isActive: boolean;
  isSelected: boolean;
  name: string;
}
