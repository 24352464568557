import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    divider: { borderBottom: `1px solid ${theme.palette.divider}`, margin: '0px 0px 10px 0px' },
    optionalSpace: {
      width: '100%',
      display: 'inline-flex'
    },
    formGroupContainer: {
      padding: 20
    }
  }),
  {
    name: 'TitleDealings'
  }
);
