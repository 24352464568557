// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'Lodgement faild', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="01C-//-REGISTRATION-ONLY-(WITH-SIGN-ALL-DOCS)-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Lodgment-error-1" transform="translate(-775.000000, -459.000000)">
          <g id="illo-error-land-registry" transform="translate(775.000000, 459.000000)">
            <g id="SVGID_1_-link" fill="#F2F2F2" fillRule="nonzero">
              <circle id="SVGID_1_" cx="65" cy="65" r="65" />
            </g>
            <g id="Group" transform="translate(26.928571, 55.714286)" fill="#888888" fillRule="nonzero">
              <rect id="Rectangle-path" x="0.371428571" y="0.0928571429" width="1.11428571" height="21.9142857" />
              <rect
                id="Rectangle-path"
                transform="translate(11.299024, 21.414864) rotate(-89.999889) translate(-11.299024, -21.414864) "
                x="10.7418813"
                y="10.457721"
                width="1.11428571"
                height="21.9142857"
              />
            </g>
            <path
              d="M30.9214286,29.3428571 L30.55,40.0214286 C30.55,40.3 30.4571429,40.4857143 30.2714286,40.6714286 C30.0857143,40.8571429 29.9,40.95 29.6214286,40.95 L26,40.95 C25.7214286,40.95 25.5357143,40.8571429 25.35,40.6714286 C25.1642857,40.4857143 25.0714286,40.3 25.0714286,40.0214286 L24.7,29.3428571 C24.7,29.0642857 24.7928571,28.8785714 24.9785714,28.6928571 C25.1642857,28.5071429 25.35,28.4142857 25.6285714,28.4142857 L30.0857143,28.4142857 C30.3642857,28.4142857 30.55,28.5071429 30.7357143,28.6928571 C30.8285714,28.8785714 30.9214286,29.0642857 30.9214286,29.3428571 Z M30.4571429,44.0142857 L30.4571429,47.1714286 C30.4571429,47.45 30.3642857,47.6357143 30.1785714,47.8214286 C29.9928571,48.0071429 29.8071429,48.1 29.5285714,48.1 L26,48.1 C25.7214286,48.1 25.5357143,48.0071429 25.35,47.8214286 C25.1642857,47.6357143 25.0714286,47.45 25.0714286,47.1714286 L25.0714286,44.0142857 C25.0714286,43.7357143 25.1642857,43.55 25.35,43.3642857 C25.5357143,43.1785714 25.7214286,43.0857143 26,43.0857143 L29.5285714,43.0857143 C29.8071429,43.0857143 29.9928571,43.1785714 30.1785714,43.3642857 C30.3642857,43.55 30.4571429,43.8285714 30.4571429,44.0142857 Z"
              id="Shape"
              fill="#F90000"
              fillRule="nonzero"
            />
            <g id="Group" transform="translate(52.928571, 56.642857)" fillRule="nonzero">
              <path
                d="M34.8214286,38.0714286 L28.8785714,38.0714286 L22.9357143,38.0714286 C22.9357143,38.0714286 24.4214286,43.7357143 20.15,47.5428571 L28.8785714,47.5428571 L37.6071429,47.5428571 C33.3357143,43.6428571 34.8214286,38.0714286 34.8214286,38.0714286 Z"
                id="Shape"
                fill="#888888"
              />
              <path
                d="M57.0142857,37.9785714 C57.0142857,39.7428571 55.6214286,41.1357143 53.8571429,41.1357143 L3.9,41.1357143 C2.13571429,41.1357143 0.742857143,39.7428571 0.742857143,37.9785714 L0.742857143,3.99285714 C0.742857143,2.22857143 2.13571429,0.835714286 3.9,0.835714286 L53.8571429,0.835714286 C55.6214286,0.835714286 57.0142857,2.22857143 57.0142857,3.99285714 L57.0142857,37.9785714 Z"
                id="Shape"
                fill="#333333"
              />
              <rect id="Rectangle-path" fill="#18CDB6" x="3.34285714" y="3.43571429" width="51.1642857" height="31.4785714" />
              <path
                d="M52.65,38.6285714 C52.65,38.1642857 52.2785714,37.8857143 51.9071429,37.8857143 C51.4428571,37.8857143 51.1642857,38.2571429 51.1642857,38.6285714 C51.1642857,39.0928571 51.5357143,39.3714286 51.9071429,39.3714286 C52.2785714,39.3714286 52.65,39.0928571 52.65,38.6285714 Z"
                id="Shape"
                fill="#FF811A"
              />
              <path
                d="M49.4,38.6285714 C49.4,38.1642857 49.0285714,37.8857143 48.6571429,37.8857143 C48.1928571,37.8857143 47.9142857,38.2571429 47.9142857,38.6285714 C47.9142857,39.0928571 48.2857143,39.3714286 48.6571429,39.3714286 C49.1214286,39.3714286 49.4,39.0928571 49.4,38.6285714 Z"
                id="Shape"
                fill="#FF811A"
              />
              <path
                d="M46.2428571,38.6285714 C46.2428571,38.1642857 45.8714286,37.8857143 45.5,37.8857143 C45.0357143,37.8857143 44.7571429,38.2571429 44.7571429,38.6285714 C44.7571429,39.0928571 45.1285714,39.3714286 45.5,39.3714286 C45.8714286,39.3714286 46.2428571,39.0928571 46.2428571,38.6285714 Z"
                id="Shape"
                fill="#FF811A"
              />
              <path
                d="M29.5285714,2.22857143 C29.5285714,1.85714286 29.25,1.57857143 28.8785714,1.57857143 C28.5071429,1.57857143 28.2285714,1.85714286 28.2285714,2.22857143 C28.2285714,2.6 28.5071429,2.87857143 28.8785714,2.87857143 C29.25,2.87857143 29.5285714,2.6 29.5285714,2.22857143 Z"
                id="Shape"
                fill="#5F6A82"
              />
              <path
                d="M39.8357143,48.3785714 C39.8357143,48.8428571 39.4642857,49.2142857 39,49.2142857 L18.7571429,49.2142857 C18.2928571,49.2142857 17.9214286,48.8428571 17.9214286,48.3785714 C17.9214286,47.9142857 18.2928571,47.5428571 18.7571429,47.5428571 L39,47.5428571 C39.4642857,47.5428571 39.8357143,47.9142857 39.8357143,48.3785714 Z"
                id="Shape"
                fill="#333333"
              />
            </g>
            <g id="Group" transform="translate(66.857143, 61.285714)">
              <g fillRule="nonzero" id="Rectangle-path">
                <g opacity="0.1" transform="translate(0.928571, 0.928571)" fill="#000000">
                  <rect
                    transform="translate(14.903728, 15.321510) rotate(-0.095425) translate(-14.903728, -15.321510) "
                    x="0.278707453"
                    y="0.742918828"
                    width="29.2500406"
                    height="29.1571833"
                  />
                </g>
                <rect
                  fill="#FFFFFF"
                  transform="translate(14.996493, 15.414367) rotate(-0.095425) translate(-14.996493, -15.414367) "
                  x="0.371472723"
                  y="0.835774986"
                  width="29.2500406"
                  height="29.1571833"
                />
              </g>
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(8.960921, 13.046534) rotate(-0.095425) translate(-8.960921, -13.046534) "
                x="2.60019746"
                y="12.5358186"
                width="12.7214462"
                height="1.02142999"
              />
              <rect
                id="Rectangle-path"
                fill="#F2F2F2"
                fillRule="nonzero"
                transform="translate(14.857585, 10.121463) rotate(-0.095425) translate(-14.857585, -10.121463) "
                x="2.60042488"
                y="9.65717679"
                width="24.5143197"
                height="1"
              />
              <rect
                id="Rectangle-path"
                fill="#F2F2F2"
                fillRule="nonzero"
                transform="translate(14.857534, 27.207270) rotate(-0.095425) translate(-14.857534, -27.207270) "
                x="2.60037376"
                y="26.7429839"
                width="24.5143197"
                height="1"
              />
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(11.143263, 14.671521) rotate(-0.095425) translate(-11.143263, -14.671521) "
                x="2.60039371"
                y="14.2072343"
                width="17.085738"
                height="1"
              />
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(11.143261, 15.785806) rotate(-0.095425) translate(-11.143261, -15.785806) "
                x="2.60039239"
                y="15.32152"
                width="17.085738"
                height="1"
              />
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(8.960941, 21.589484) rotate(-0.095425) translate(-8.960941, -21.589484) "
                x="2.60021833"
                y="21.0787686"
                width="12.7214462"
                height="1.02142999"
              />
              <rect
                id="Rectangle-path"
                fill="#F2F2F2"
                fillRule="nonzero"
                transform="translate(14.857513, 18.664413) rotate(-0.095425) translate(-14.857513, -18.664413) "
                x="2.60035289"
                y="18.2001268"
                width="24.5143197"
                height="1"
              />
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(11.143284, 23.214471) rotate(-0.095425) translate(-11.143284, -23.214471) "
                x="2.60041458"
                y="22.7501843"
                width="17.085738"
                height="1"
              />
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(11.143189, 24.328756) rotate(-0.095425) translate(-11.143189, -24.328756) "
                x="2.6003204"
                y="23.86447"
                width="17.085738"
                height="1"
              />
              <g transform="translate(1.857143, 3.714286)" fill="#888888" fillRule="nonzero" id="Rectangle-path">
                <rect transform="translate(3.575448, 0.835846) rotate(-0.095425) translate(-3.575448, -0.835846) " x="0.743301675" y="0.371559706" width="5.66429357" height="1" />
                <rect transform="translate(3.575446, 2.507275) rotate(-0.095425) translate(-3.575446, -2.507275) " x="0.743299702" y="2.04298828" width="5.66429357" height="1" />
                <rect transform="translate(3.575447, 1.671560) rotate(-0.095425) translate(-3.575447, -1.671560) " x="0.743300689" y="1.20727399" width="5.66429357" height="1" />
                <rect transform="translate(3.575446, 3.342989) rotate(-0.095425) translate(-3.575446, -3.342989) " x="0.743298715" y="2.87870256" width="5.66429357" height="1" />
              </g>
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(14.857266, 4.689320) rotate(-0.095425) translate(-14.857266, -4.689320) "
                x="11.1429753"
                y="4.08574802"
                width="7.42858173"
                height="1.20714453"
              />
              <rect
                id="Rectangle-path"
                fill="#888888"
                fillRule="nonzero"
                transform="translate(14.810743, 6.360826) rotate(-0.095425) translate(-14.810743, -6.360826) "
                x="12.2571678"
                y="5.75725392"
                width="5.10714994"
                height="1.20714453"
              />
              <polygon id="Shape" stroke="#888888" strokeWidth="0.2057" points="20.8928571 3.62142857 20.9857143 7.61428571 27.1142857 7.52142857 27.0214286 3.52857143" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
