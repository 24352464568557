import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { ErrorBoundaryInjectedProps } from 'src/components/error-boundary';
import { IconSupport } from 'src/containers/help-page/help-icons';
import useRedirectDispatch from 'src/hooks/useRedirectDispatch';
import { useStyles } from './styles';

interface Props extends Partial<ErrorBoundaryInjectedProps> {
  background?: 'white';
}

function GeneralCrashedContent({ eventId, background, reset }: Props): JSX.Element {
  const classes = useStyles();
  const goToDashboard = useRedirectDispatch('/');

  return (
    <FlexLayout //
      flexDirection="column"
      alignItems="center"
      fullWidth
      className={classNames(classes.root, background === 'white' && classes.backgroundWhite)}
    >
      <IconSupport title="Contact support" background={background} />
      <div className={classes.messageContainer}>
        <Typography variant="subtitle1">
          We are experiencing technical issues on this page
          {eventId && <Typography variant="body2">Reference number: {eventId}</Typography>}
        </Typography>
        <Typography>Please call 1300 SYMPLI (1300 796 754)</Typography>
      </div>
      <SympliButton
        variant="contained"
        color="primary"
        className={classes.backButton}
        onClick={() => {
          reset?.();
          goToDashboard();
        }}
      >
        Go to dashboard
      </SympliButton>
    </FlexLayout>
  );
}

export default React.memo(GeneralCrashedContent);
