import * as React from 'react';

import Grid from '@mui/material/Grid';

import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';

import { AvatarUploader } from 'src/components/avatars';
import { ProfileImageModel } from './models';
import useStyles from './styles';

export interface ProfileImageProps {
  avatarUrl?: string;
  title?: string;
  onDeleteImage: (avatarUrl?: string) => void;
  onImageUploaded?: (profileImage: ProfileImageModel) => void;
}

function ProfileImage({ avatarUrl, title = 'Profile picture', onDeleteImage, onImageUploaded }: ProfileImageProps) {
  const classes = useStyles();

  const description = (
    <Grid container={true} direction="row" component="span">
      <span>Accepted format: PNG or JPG</span>
      <span>Maximum file size: 5MB</span>
    </Grid>
  );

  return (
    <Grid //
      container={true}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.profileImageBox}
    >
      <FormGroup //
        className={classes.formGroup}
        title={title}
        description={description}
      >
        <AvatarUploader //
          src={avatarUrl}
          deleteImage={onDeleteImage}
          onImageUploaded={onImageUploaded}
        />
      </FormGroup>
    </Grid>
  );
}

export default React.memo(ProfileImage);
