import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import SupportingDocumentUploader from '@sympli-mfe/document-forms-framework/components/supporting-document-uploader-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getCharacterCountHelperText, modelKey, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { InputClassKeys, InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import StandardTermsDealingNumbersArray from '../../components/standard-terms-dealing-numbers-array';
import { ADDITIONAL_COVENANT_WARNING_VALUES } from '../../helper';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS, REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT } from '../../isRequiredChecks';
import { StandardTermsModel } from '../../models';
import { useStyles } from './styles';
import { COVENANTS_MAX_CHARACTERS } from './validationSchema';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
}

function resolveAdditionalCovenantsHelper(
  additionalCovenants: string,
  classes: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>>
): Pick<InputComponentProps, 'classes' | 'helperText'> {
  const additionalCovenantsCount = (additionalCovenants ?? '').trim().length;
  const helperText = getCharacterCountHelperText(additionalCovenantsCount, COVENANTS_MAX_CHARACTERS);

  if (ADDITIONAL_COVENANT_WARNING_VALUES.some(warningValue => ~additionalCovenants.indexOf(warningValue))) {
    return {
      helperText: `Please be aware that certain words within the terms and conditions are used for specific purposes only e.g. Trust, Trustee, Beneficial, Beneficiary. You may proceed to lodgement, however, there may be delays in registration. ${helperText}`,
      classes
    };
  }
  return {
    helperText
  };
}

function SectionTermsAndConditions(props: Props): JSX.Element {
  const classes = useStyles();
  const { values } = useFormikContext<StandardTermsModel>();
  const fieldClassesWarning: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControl: classes.additionalCovenantWarningBorder,
      formControlValidation: classes.additionalCovenantWarningHelperText
    };
  }, [classes]);

  const fieldClassesError: Partial<ClassNameMap<keyof ReturnType<InputClassKeys>>> = useMemo(() => {
    return {
      formControlValidation: classes.formControlValidation
    };
  }, [classes]);

  const { disabled, filesApi } = useDocumentContext();
  const fieldName = modelKey<StandardTermsModel>();
  const isAdditionalTermsAndConditionsRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS(values);
  const isTermsAndConditionsAttachmentRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT(values);

  const additionalTermsAndConditionsTitle = 'Additional terms and conditions';
  const additionalTermsAndConditionsPlaceholder = resolvePlaceholder(isAdditionalTermsAndConditionsRequired);
  const additionalCovenants = values.additionalCovenants.trim().toLowerCase();

  return (
    <>
      <StandardTermsDealingNumbersArray //
        name={fieldName('standardTermsDealingNumbers')}
        maxItems={20}
        minItems={1}
      />
      <FormGroup //
        title={additionalTermsAndConditionsTitle}
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('additionalCovenants')}
            component={InputField}
            disabled={disabled}
            classes={fieldClassesError}
            className={classes.noBottomMargin}
            fullWidth
            multiline
            minRows={2}
            placeholder={additionalTermsAndConditionsPlaceholder}
            {...resolveAdditionalCovenantsHelper(additionalCovenants, fieldClassesWarning)}
          />
        )}
      </FormGroup>
      <SupportingDocumentUploader //
        name={fieldName('supportingDocuments')}
        titleLabel="Upload supporting document"
        onUpload={filesApi.upload}
        onDownload={filesApi.download}
        disabled={disabled}
        required={isTermsAndConditionsAttachmentRequired}
      />
    </>
  );
}

const MemoizedTermsAndConditions = React.memo(SectionTermsAndConditions);

export default ({ name }: Props) => {
  return (
    <Subform //
      subformBindingPath={name}
      component={MemoizedTermsAndConditions}
      name={name}
    />
  );
};
