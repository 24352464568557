import { arraysIntersectionsBy, mergeArrays } from '@sympli-mfe/document-forms-framework/utils';

import { DocumentBaseTitleReferenceModel } from '../../models';
import { ApiDischargeOfMortgage_2_23_0_Model, MortgageePartyModel } from './models';

export const filterTitleReferences = <T extends Pick<ApiDischargeOfMortgage_2_23_0_Model, 'mortgages'>>(values: T): DocumentBaseTitleReferenceModel[] => {
  const result = arraysIntersectionsBy(
    values.mortgages?.filter(r => r.isSelected).map(x => x.titleReferences),
    'reference'
  );
  return result;
};

export const filterMortgagees = <T extends Pick<ApiDischargeOfMortgage_2_23_0_Model, 'mortgages'>>(values: T): MortgageePartyModel[] => {
  const result = mergeArrays(
    values.mortgages?.filter(r => r.isSelected).map(x => x.mortgagees),
    'partyBookId'
  );
  return result;
};
