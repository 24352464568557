import React from 'react';

import TopPanel from 'src/components/layout/v2/TopPanel';

function DashboardWrapper({
  children,
  topPanelNode = <TopPanel />
}: React.PropsWithChildren<{
  topPanelNode?: JSX.Element;
}>) {
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .container */}
      {/* .header */}
      {topPanelNode}

      <div className="grid grid-cols-[1fr] grid-rows-[1fr] overflow-hidden">
        {/* .body */}
        <div className="overflow-y-auto">
          {/* .scrollable-content */}
          <div className="h-[280px] w-full bg-[var(--profile-green-hover)]"></div>
          <div className="mx-auto mt-[-280px] max-w-[var(--small-content)] pb-[var(--bottom-content-spacing)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(DashboardWrapper);
