import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { IconCaretRight } from '@sympli/ui-framework/icons';

import { Box } from 'src/components/layout';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { useWorkspaceBasicInfo } from '../../detail/reducers/workspaceBasicInfo';
import { useWorkspaceDocuments } from '../../detail/reducers/workspaceDocuments';
import { WorkspaceFile } from '../workspace-files/models';
import WorkspaceFiles from '../workspace-files/WorkspaceFiles';
import { actionFetchWorkspaceFiles } from './actions';
import { useWorkspaceFiles } from './reducer';
import { selectWorkspaceFiles } from './selector';
import { useStyles } from './styles';

interface OwnProps {
  workspaceId: string;
  participantId: string;
  participants: WorkspaceParticipantApiResponse[];
  disabled?: boolean;
  onUploadFile: () => void;
  onEditFile: (file: WorkspaceFile | null) => void;
  onDeleteFile: (file: WorkspaceFile | null) => void;
}

export default function WorkspaceFilesContainer(props: OwnProps) {
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { onUploadFile, onEditFile, onDeleteFile, workspaceId, participantId, participants, disabled } = props;

  const workspaceFilesState = useWorkspaceFiles(workspaceId, participantId);
  const workspaceDocumentState = useWorkspaceDocuments(workspaceId, participantId);
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);

  const isDocumentAttachmentsEnhancementEnabled = useFeatureFlag(FeatureToggleEnum.documentAttachmentsEnhancementEnabled);

  const workspaceFiles = selectWorkspaceFiles({
    workspaceDocuments: workspaceDocumentState.items,
    workspaceFiles: workspaceFilesState.items,
    participantId,
    isDocumentAttachmentsEnhancementEnabled
  });
  const workspaceStatusId: WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const isSettling = workspaceStatusId === WorkspaceStatusEnum.SettlementInProgress || workspaceStatusId === WorkspaceStatusEnum.SettlementIssue;
  const isLoading = workspaceFilesState.isLoading || workspaceDocumentState.isLoading || workspaceBasicInfoState.isLoading;
  const isRejected = workspaceFilesState.status === 'rejected' || workspaceDocumentState.status === 'rejected' || workspaceBasicInfoState.status === 'rejected';
  const hasWorkspaceFiles = workspaceFiles && workspaceFiles.length > 0;

  useEffect(() => {
    dispatch(actionFetchWorkspaceFiles.request({ workspaceId, participantId }));
  }, [dispatch, workspaceId, participantId]);

  return (
    <Box //
      title="Uploaded files"
      action={'Upload file'}
      // ToDo: Update ui framework. actionIcon should ideally accept a React.node type
      actionIcon={(<IconCaretRight width="18" height="18" />) as unknown as string}
      onActionClick={onUploadFile}
      className={classNames(
        classes.workspaceFilesContainer,
        // when workspace is settling, and it doesn't have any workspace files, make the component a bit transparent
        isSettling && !hasWorkspaceFiles && classes.opacityBox
      )}
      disabled={disabled}
    >
      <WorkspaceFiles
        //
        workspaceId={workspaceId}
        participantId={participantId}
        items={workspaceFiles}
        documents={workspaceDocumentState.items}
        isLoading={isLoading}
        isRejected={isRejected}
        participants={participants}
        disabled={disabled}
        onUploadFile={onUploadFile}
        onEditFile={onEditFile}
        onDeleteFile={onDeleteFile}
      />
    </Box>
  );
}
