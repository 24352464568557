import { Player } from '@lottiefiles/react-lottie-player';

const noDataAnimationUrl = new URL('./animations/noData.json', import.meta.url).href;

function NoResults() {
  return (
    <div className="flex h-[400px] items-center justify-center">
      <div className="pr-[20px] text-right font-volkswagen-serial-medium font-normal leading-[30px]">
        <div className="text-[25px] text-[var(--neutral-400)]">No results found.</div>
        <div className="text-[20px] text-[var(--sympli-green)]">Please readjust your filters.</div>
      </div>
      <Player //
        autoplay
        loop
        src={noDataAnimationUrl}
        style={{ height: '300px', width: '300px' }}
      />
      {/* <svg width="285" height="300" viewBox="0 0 285 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M81.853 205.414L118.329 275.964H45.3768L81.853 205.414Z" fill="#D6FBF9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2769 165.315C29.6344 192.024 55.2601 210.071 82.5138 205.625C109.768 201.179 128.329 175.923 123.972 149.215L25.2769 165.315Z"
          fill="#FFDF61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.585 112.919L133.34 121.973C119.518 151.458 84.1199 163.64 54.4838 149.747C24.8477 135.854 11.5682 100.853 25.3897 71.3683L29.6342 62.3137L47.7432 70.8026L43.4988 79.8572C34.5264 98.9977 42.9915 122.271 62.973 131.638C82.9545 141.005 106.258 132.624 115.231 113.484L119.476 104.43L137.585 112.919Z"
          fill="#FF929B"
        />
        <circle cx="143.314" cy="106.28" r="8.56641" fill="#FFE9EB" />
        <line x1="167.362" y1="61.6736" x2="187.831" y2="4.85896" stroke="#72F2EB" strokeLinecap="round" />
        <line x1="188.939" y1="97.184" x2="274.667" y2="91.8822" stroke="#72F2EB" strokeLinecap="round" />
        <line x1="185.473" y1="76.1633" x2="284.175" y2="1.38704" stroke="#72F2EB" strokeLinecap="round" />
      </svg> */}
    </div>
  );
}

export default NoResults;
