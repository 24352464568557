import * as yup from 'yup';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';

import { NSW_DM_PARTY_FORM_CONFIG } from '../../config';
import { DischargeOfMortgage2_21_1Model } from '../../models';

interface PartyBookValidationContext {
  mortgagees: TenancyPartyModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = ({ mortgagees }: DischargeOfMortgage2_21_1Model): PartyBookValidationContext => ({
  mortgagees: mortgagees
});

const checkShouldValidateItem = ({ mortgagees }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => mortgagees.some(e => e.partyBookId === partyIdForCheck) ?? false;

const yupPartyItem: yup.ObjectSchema<PartyModel<NswNameChange>, PartyBookValidationContext> = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(
  NSW_DM_PARTY_FORM_CONFIG
);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
