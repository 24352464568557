import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    fullWidth: {
      width: '100%'
    }
  }),
  { name: 'SelectDirectionCategory' }
);
