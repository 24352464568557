import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    avatar: {
      display: 'inline-block'
    },
    seeWorkspaceIcon: {
      marginRight: 2
    }
  }),
  { name: 'ParticipantList' }
);
