import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 31
    },
    backgroundWhite: {
      background: theme.palette.common.white
    },
    backgroundGrey: {
      background: theme.palette.grey[100]
    },
    '@keyframes placeHolderShimmer': {
      '0%': {
        backgroundPosition: '-468px 0'
      },
      '100%': {
        backgroundPosition: '468px 0'
      }
    },
    animatedBackground: {
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$placeHolderShimmer',
      animationTimingFunction: 'linear',
      background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
      backgroundSize: '800px 104px',
      position: 'relative',
      height: 31
    },
    backgroundMasker: {
      position: 'absolute',
      // border: '1px solid grey',
      boxSizing: 'border-box'
    },
    backgroundMaskerWhite: {
      background: theme.palette.common.white
    },
    backgroundMaskerGrey: {
      background: theme.palette.grey[100]
    },
    backgroundTitleTop: {
      top: 0,
      left: 0,
      height: 1,
      right: 30
    },
    backgroundTitleAfter: {
      top: 0,
      left: 165,
      right: 30,
      height: 15
    },
    backgroundTitleBottom: {
      top: 14,
      left: 22,
      height: 5,
      right: 30
    },
    // backgroundDescriptionBottom: {
    //   top: 30,
    //   left: 0,
    //   height: 15,
    //   right: 30
    // },
    backgroundDescriptionAfter: {
      top: 18,
      left: 95,
      height: 13,
      right: 30
    },
    backgroundIconLeft: {
      left: 0,
      height: '100%',
      width: 3
    },
    backgroundIconRight: {
      left: 15,
      height: '100%',
      width: 7
    },
    backgroundIconBottom: {
      top: 16,
      height: 15,
      width: 22
    },
    backgroundAvatar: {
      right: 0,
      width: 30,
      height: 30,
      backgroundColor: 'transparent',
      '&:after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 30,
        height: 30,
        borderRadius: '100%'
      }
    },
    backgroundAvatarWhite: {
      '&:after': {
        boxShadow: `0px 0px 0px 8px ${theme.palette.common.white}`
      }
    },
    backgroundAvatarGrey: {
      '&:after': {
        boxShadow: `0px 0px 0px 8px ${theme.palette.grey[100]}`
      }
    },
    disableAvatar: {
      right: 0
    }
  });

export type ClassKeys = typeof styles;

export default styles;
