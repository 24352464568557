import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { IconTaskAllDone } from '../task-box-icons';
import { useStyles } from './styles';

interface Props {
  backLink?: string;
  noTaskHeading?: string;
  noTaskMessage?: string;
}

function NoTasks({ backLink, noTaskHeading = 'All done!', noTaskMessage = "You don't have any tasks right now." }: Props) {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="column" alignItems="center" className={classNames(classes.root, classes.greyBackground)}>
      <IconTaskAllDone />
      <Typography className={classes.header} variant="subtitle1">
        {noTaskHeading}
      </Typography>
      <Typography className={classes.message}>{noTaskMessage}</Typography>
      {backLink && (
        <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
          Back to dashboard
        </SympliButton>
      )}
    </FlexLayout>
  );
}

export default NoTasks;
