import { call, put, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { DocumentPermissionEnum, DocumentStatusEnum, DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentDetailApiResponse } from '@sympli/api-gateway/models';

import { JsonSchemaRootModel } from 'src/containers/documents/dynamic-form/models';
import { DocumentDetailDataModel, PaymentSummaryApiRequest } from 'src/containers/documents/models';
import { RegOnlyPaymentSummaryApiResponse } from 'src/containers/shared/payment-summary-box/models';
import http, { NoResponseData } from 'src/utils/http';
import {
  actionFetchDocumentDetail,
  actionFetchMergedDocumentsPdf,
  actionFetchPaymentSummary,
  actionSaveDebitAccount,
  actionUpdatePaymentSummary,
  MergedDocumentsPdfApiRequest
} from './actions';
import { DocumentDetailApiRequest, DocumentFormAndDetailModel, SaveDebitAccountApiRequest } from './models';

const DEFAULT_SCHEMA: Partial<JsonSchemaRootModel> = { required: [], properties: {} };

export function convertToDocumentDetailModel(apiResponse: WorkspaceDocumentDetailApiResponse): DocumentFormAndDetailModel {
  const { data, documentForm, documentPermissions, status } = apiResponse;
  const parsedSchema: JsonSchemaRootModel = documentForm.schema ? JSON.parse(documentForm.schema) : DEFAULT_SCHEMA;

  const parsedData: DocumentDetailDataModel = JSON.parse(data);
  const payload: DocumentFormAndDetailModel = {
    ...apiResponse,
    parsedData,
    parsedSchema
  };

  // * If Transfer document is awaiting for Purchaser
  // * Vendor will go to Review step of Transfer document
  const isTransferDocument = payload.documentForm.documentType === DocumentTypeIdentifierEnum.Transfer || payload.documentForm.name === 'Transfer';

  if (isTransferDocument && status === DocumentStatusEnum.InPreparation) {
    const hasEditPermission = documentPermissions.includes(DocumentPermissionEnum.Write);
    if (!hasEditPermission) {
      payload.status = DocumentStatusEnum.Reviewing;
    }
  }
  return payload;
}

export const fetchDocumentDetail = (q: DocumentDetailApiRequest): Promise<WorkspaceDocumentDetailApiResponse> => {
  return endpoints.getDocumentDetail(q);
};

const fetchPaymentSummaryData = (q: PaymentSummaryApiRequest) => {
  const { workspaceId, participantId } = q;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/payment-summary`;
  return http.get<RegOnlyPaymentSummaryApiResponse>(uri);
};

export function* sagaFetchPaymentSummary(action: ReturnType<typeof actionFetchPaymentSummary.request>) {
  try {
    const payload = yield* call(fetchPaymentSummaryData, action.payload);
    yield put(actionFetchPaymentSummary.success(payload));
  } catch (error) {
    yield put(actionFetchPaymentSummary.failure({ error }));
  }
}

const fetchMergedDocumentsPdf = (payload: MergedDocumentsPdfApiRequest) => {
  const { workspaceId, participantId, documentIds } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/merge-pdf`;
  return http.post<string>(uri, documentIds); // TODO check usage
};

export function* sagaFetchMergedDocumentsPdf(action: ReturnType<typeof actionFetchMergedDocumentsPdf.request>) {
  try {
    const payload = yield* call(fetchMergedDocumentsPdf, action.payload);
    yield put(actionFetchMergedDocumentsPdf.success(payload));
  } catch (error) {
    yield put(actionFetchMergedDocumentsPdf.failure({ error }));
  }
}

const saveDebitAccountData = (q: SaveDebitAccountApiRequest) => {
  const { workspaceId, participantId, debitAccountId } = q;

  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/payment-summary?debitAccountId=${debitAccountId}`;
  return http.post<NoResponseData>(uri, {});
};

export function* sagaSaveDebitAccountData(action: ReturnType<typeof actionSaveDebitAccount.request>) {
  try {
    yield put(actionUpdatePaymentSummary(action.payload.paymentSummaryModel));

    yield* call(saveDebitAccountData, action.payload);
    yield put(actionSaveDebitAccount.success());
  } catch (error) {
    yield put(actionSaveDebitAccount.failure({ error }));
  }
}

/*
 *========================================
 *  General Document in workspace
 *========================================
 */
export function* sagaFetchDocumentDetail(action: ReturnType<typeof actionFetchDocumentDetail.request>) {
  try {
    const result: WorkspaceDocumentDetailApiResponse = yield* call(fetchDocumentDetail, action.payload);

    const payload: DocumentFormAndDetailModel = convertToDocumentDetailModel(result);

    yield put(actionFetchDocumentDetail.success(payload));
  } catch (error) {
    yield put(actionFetchDocumentDetail.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchDocumentDetail.request, sagaFetchDocumentDetail),
  takeLatest(actionFetchPaymentSummary.request, sagaFetchPaymentSummary),
  takeLatest(actionFetchMergedDocumentsPdf.request, sagaFetchMergedDocumentsPdf),
  takeLatest(actionSaveDebitAccount.request, sagaSaveDebitAccountData)
];
