import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

interface Props {
  variant?: 'default' | 'error';
  className?: string;
  style?: React.CSSProperties;
}

function HelperText(props: PropsWithChildren<Props>): JSX.Element {
  const { children, className, variant, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography //
      component="div"
      variant="body1"
      className={classNames(classes.root, variant === 'error' && classes.variantError, className)}
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default React.memo(HelperText);
