import { resolveMemoizedTenancyEditableValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing/validation-schema';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { Mortgage2_21_2Model } from '../../models';

const yupMortgagors = resolveMemoizedTenancyEditableValidation({
  isAddressRequired: false,
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG
}).testContextualRule({
  uniqueTestName: 'Mortgagors name mismatch test',
  message: 'Please be aware that the parties on the selected titles presently do not match.',
  onlyIf: (parent: Mortgage2_21_2Model) => parent.titleReferences.length > 1 && PartyValidations.verifyTenancyStructure(parent.partyBook) === undefined,
  requirement: (parent: Mortgage2_21_2Model) => PartyValidations.verifyJustification(parent.partyBook, getLegalEntityNameNSW)
});

export default yupMortgagors;
