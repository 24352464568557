import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Close from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AuthorityComplianceReportTypeEnum, LodgementCaseStatusEnum } from '@sympli/api-gateway/enums';
import { AuthorityComplianceReportApiResponse } from '@sympli/api-gateway/models';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { actionFetchWorkspaceAuthorityComplianceReport, WorkspaceAuthorityComplianceReportRequest } from 'src/containers/workspace/shared/detail/actions';
import { AuthorityComplianceReportTypeDisplayMapping } from 'src/containers/workspace/shared/detail/components/tab-logs/models';
import { lodgementLogSortByLodgementCaseIdSelector, lodgementLogSortMapSelector } from 'src/containers/workspace/shared/detail/selectors';
import { useRouterParams } from 'src/hooks';
import { LodgementCompliance } from '../../detail/models';
import { useWorkspaceAuthorityComplianceReport, WorkspaceAuthorityComplianceReportState } from '../../detail/reducers/workspaceAuthorityComplianceReport';
import { useWorkspaceDetail } from '../../detail/reducers/workspaceDetail';
import { useWorkspaceDocuments } from '../../detail/reducers/workspaceDocuments';
import LodgementVerificationResult from './components/lodgement-verification-result';
import { AuthorityDocumentModel } from './models';
import { useStyles } from './styles';

interface RouteParams {
  workspaceId: string;
  participantId: string;
  documentId?: string;
}

export interface Props {
  // reference to historic compliance record
  authorityMessageId?: string;
  authorityComplianceReportType?: AuthorityComplianceReportTypeEnum;
  lodgementCaseId?: string; // existing standalone workspace or non MLC workspace doesn't have this data.
  // dialog closing handler
  onClose?: (event: React.SyntheticEvent) => void;
}

function LodgementVerificationResultContainer({
  //
  // reference to historic compliance record
  authorityMessageId,
  authorityComplianceReportType = AuthorityComplianceReportTypeEnum.LodgementVerification,
  lodgementCaseId,
  // dialog closing handler
  onClose
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { workspaceId, participantId, documentId } = useRouterParams<RouteParams>();
  // this will be truthy only if user requested historic lodgement verification result (accessible from workspace log)
  const showPreviousComplianceData: boolean = !!authorityMessageId?.length && Number.isInteger(authorityComplianceReportType);

  React.useEffect(() => {
    if (showPreviousComplianceData) {
      const payload: WorkspaceAuthorityComplianceReportRequest = {
        //
        workspaceId,
        participantId,
        authorityMessageId: authorityMessageId!,
        messageType: authorityComplianceReportType!,
        lodgementCaseId
      };
      dispatch(actionFetchWorkspaceAuthorityComplianceReport.request(payload));
    }
  }, [showPreviousComplianceData, workspaceId, participantId, authorityMessageId, authorityComplianceReportType, dispatch, lodgementCaseId]);

  // redux data
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const { items: workspaceDocuments } = useWorkspaceDocuments(workspaceId, participantId);
  const previousComplianceRecordsState: WorkspaceAuthorityComplianceReportState = useWorkspaceAuthorityComplianceReport(
    workspaceId,
    participantId,
    authorityMessageId,
    authorityComplianceReportType
  );

  const lodgementCaseStatus: LodgementCaseStatusEnum | undefined = React.useMemo(() => {
    return lodgementCaseId
      ? workspaceDetailState.detail?.lodgementCases.find(x => x.id === lodgementCaseId)?.lodgementCaseStatusId
      : workspaceDetailState.detail?.lodgementCases.length === 1
        ? workspaceDetailState.detail?.lodgementCases[0].lodgementCaseStatusId // standalone workspace and financial workspace NON MLC one
        : workspaceDetailState.detail?.lodgementCases.find(x => x.isPrimary)?.lodgementCaseStatusId; // MLC workspace
  }, [lodgementCaseId, workspaceDetailState.detail?.lodgementCases]);

  const lodgementCaseLogSortedMap = lodgementCaseId
    ? lodgementLogSortByLodgementCaseIdSelector({ state: { workspaceDetail: workspaceDetailState }, lodgementCaseId })
    : lodgementLogSortMapSelector({ workspaceDetail: workspaceDetailState });

  // dialog title
  const title = AuthorityComplianceReportTypeDisplayMapping[authorityComplianceReportType];

  // compliance records
  const lodgementCaseCompliances: LodgementCompliance[] | undefined = showPreviousComplianceData //
    ? previousComplianceRecordsState.detail?.lodgementCaseCompliance.lodgementCompliances
    : workspaceDetailState.detail?.lodgementDetails.find(x => x.id === authorityMessageId)?.lodgementCompliances;

  const documents: AuthorityDocumentModel[] = React.useMemo(() => {
    const docs = showPreviousComplianceData //
      ? previousComplianceRecordsState.detail?.documents
      : (lodgementCaseId ? workspaceDocuments.filter(x => x.lodgementCaseId === lodgementCaseId) : workspaceDocuments).reduce(
          (acc: AuthorityComplianceReportApiResponse['documents'], d) => {
            acc.push(d);
            if (d.supportingDocuments) {
              acc.push(...d.supportingDocuments);
            }
            return acc;
          },
          []
        );

    //  we will receive authority compliances on existing documents, so they will definitely contain documentId
    // the placeholder documents wont be there
    // this is just a safe check
    return (docs || []).filter(item => !!item.documentId) as AuthorityDocumentModel[];
  }, [showPreviousComplianceData, previousComplianceRecordsState.detail?.documents, workspaceDocuments, lodgementCaseId]);

  const isLodgementCaseCompliant: boolean | undefined = showPreviousComplianceData //
    ? previousComplianceRecordsState.detail?.lodgementCaseCompliance.isCompliant
    : lodgementCaseStatus === LodgementCaseStatusEnum.LodgementVerificationSuccess;

  const onResolveIssueClick = React.useCallback(
    (event: React.SyntheticEvent, document: AuthorityDocumentModel) => {
      if (onClose) {
        onClose(event);
      }

      const documentIssueLink: string = resolveDocumentDetailLink({ workspaceId, participantId, documentId: document.documentId });
      navigate(documentIssueLink);
    },
    [workspaceId, participantId, onClose, navigate]
  );

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>
        {`${title} results`}
      </Typography>
      <IconButton //
        onClick={onClose}
        aria-label={`Close ${title} results`}
        title={`Close ${title} results`}
        className={classes.closeButton}
        size="large"
      >
        <Close />
      </IconButton>
      {showPreviousComplianceData && previousComplianceRecordsState.status === 'rejected' ? (
        <FormHelperText role="alert" error>
          {previousComplianceRecordsState.error}
        </FormHelperText>
      ) : showPreviousComplianceData && previousComplianceRecordsState.isLoading ? (
        <BlockLoader />
      ) : (
        <LodgementVerificationResult
          // route parameters
          currentDocumentId={documentId}
          // redux data
          lodgementCompliances={lodgementCaseCompliances}
          lodgementCaseLogSortedMap={lodgementCaseLogSortedMap}
          lodgementCaseStatus={lodgementCaseStatus}
          documents={documents}
          pastAuthorityComplianceReport={previousComplianceRecordsState.detail}
          // other
          isLodgementCaseCompliant={isLodgementCaseCompliant}
          onResolveIssueClick={showPreviousComplianceData ? undefined : onResolveIssueClick} // explicitly hide resolve issues button for authorityComplianceReport
        />
      )}
    </div>
  );
}

export default React.memo(LodgementVerificationResultContainer);
