import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiNominateLodgementCase_2_18_1_Model, NominateLodgementCase_2_18_1_Model } from './models';

class Converter implements IConverter<NominateLodgementCase_2_18_1_Model, ApiNominateLodgementCase_2_18_1_Model> {
  fromApiToFormModel(apiModel: ApiNominateLodgementCase_2_18_1_Model, context: IConverterContext): NominateLodgementCase_2_18_1_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: NominateLodgementCase_2_18_1_Model, _originalApiModel: ApiNominateLodgementCase_2_18_1_Model): ApiNominateLodgementCase_2_18_1_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
