import { UserRoleEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export interface AuthJwtModel {
  token?: string;
  name: string;
  email: string;
  // profile?: AuthProfileModel;
  tkSubscriberId: string;
  tkNavigationLinks: Array<string>;
}

export const AUTH_USER_ROLE_OPTIONS: LookupEnumModel<UserRoleEnum>[] = [
  { name: 'User', id: UserRoleEnum.Standard },
  { name: 'Admin', id: UserRoleEnum.Admin },
  { name: 'Super admin', id: UserRoleEnum.SuperAdmin }
];

export type OidcClient = {
  domain: string;
  clientId: string;
  authorityUrl?: string; // make it optional for now so we do not break the existing SSO
  env?: string; // this is only used for local development
};
