import * as React from 'react';

import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { LinkedWorkspaceOverallStatusEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { Box } from 'src/components/layout';
import { IconLinked } from 'src/components/layout/v2/Icons';
import { Card } from 'src/containers/dashboard/components/card';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { useWorkspaceGroups } from 'src/containers/dashboard/shared/reducers/workspaceGroups';
import { useSafeDispatch } from 'src/hooks';
import { FinancialRoleMapping } from 'src/models/roles';
import { LinkedWorkspaceParticipant } from '../../models';
import { useLinkedSettlementWorkspaces } from '../../reducers/linkedSettlementDetail';
import { getWorkspaceOverviewUrl } from './helper';
import LinkedWorkspacesTable from './linked-workspace-table';
import { LinkedWorkspaceTableModel } from './linked-workspace-table/models';
import { getLinkedParticipantColumns, getLinkedParticipantRows } from './linked-workspace-table/row-detail/helper';
import LinkingParticipantCard from './linked-workspace-table/row-detail/LinkingParticipantCard';
import { LinkedWorkspaceOverallStatusEnumMap, ParticipantsHaveAccessToLinkedWorkspace } from './models';

interface LinkedSettlementDetailContainerProps {
  workspaceId: string;
  clusterId: string;
  userId: string;
  subscriberId: string;
  // maybe need current user group id? subscriber id?
}

function LinkedSettlementDetailContainer(props: LinkedSettlementDetailContainerProps) {
  const { workspaceId, clusterId, userId, subscriberId } = props;

  const linkedSettlementDetail = useLinkedSettlementWorkspaces(workspaceId, clusterId);
  const useWorkspaceGroupsState = useWorkspaceGroups();
  const userGroupIds = useWorkspaceGroupsState.items.map(d => d.id);
  const dispatch = useSafeDispatch(useDispatch());
  const [rowDetailIndex, setRowDetailIndex] = React.useState<number | undefined>(undefined);
  const [open, setIsOpen] = React.useState(false);
  const [participantsHaveAccess, setParticipantsHaveAccess] = React.useState<ParticipantsHaveAccessToLinkedWorkspace[]>([]);
  const handleOnClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleOnRoleClick = (linkedToParticipantId: string, linkedToWorkspaceId: string) => {
    window.open(getWorkspaceOverviewUrl(linkedToWorkspaceId, linkedToParticipantId));
  };

  //1: if there is one role, always display view button, if no permission show the toast message, if has permission open new tab
  //2: if there is multiple roles, but only one role has group access, then open the new tab when click on the view button. Otherwise show the pop up dialog with all the accessible roles.
  const handleOnViewClick = React.useCallback(
    (participantsIsSameSubscriber: LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => {
      if (participantsIsSameSubscriber.length === 1) {
        // iop does not have the group id
        if (!participantsIsSameSubscriber[0].groupId || userGroupIds.indexOf(participantsIsSameSubscriber[0].groupId) === -1) {
          dispatch(
            actionOpenGlobalSimpleNotification({
              message: <Typography className="text-[14px] text-[var(--neutral-000)]">You do not have permission to view the workspace</Typography>,
              variant: 'new-error',
              autoHideDuration: 6 * 1000
            })
          );

          return;
        }

        window.open(getWorkspaceOverviewUrl(linkedToWorkspaceId, participantsIsSameSubscriber[0].participantId));
        return;
      }

      const participantsHaveGroupAccess = participantsIsSameSubscriber.filter(d => {
        // block iop workspace access since they do not have group id
        if (!d.groupId) {
          return false;
        }
        if (userGroupIds.indexOf(d.groupId) !== -1) {
          return true;
        }
        return false;
      });

      if (participantsHaveGroupAccess.length === 1) {
        window.open(getWorkspaceOverviewUrl(linkedToWorkspaceId, participantsHaveGroupAccess[0].participantId));
      } else {
        setIsOpen(true);

        setParticipantsHaveAccess(participantsHaveGroupAccess.map(d => ({ ...d, workspaceId: linkedToWorkspaceId }) as ParticipantsHaveAccessToLinkedWorkspace));
      }
    },
    [setIsOpen, setParticipantsHaveAccess, userGroupIds, dispatch]
  );

  const handleOnRowToggle = React.useCallback(
    (newRowDetailIndex: number, _: LinkedWorkspaceTableModel) => {
      if (newRowDetailIndex !== rowDetailIndex) {
        setRowDetailIndex(newRowDetailIndex);
      } else {
        setRowDetailIndex(undefined);
      }
    },
    [setRowDetailIndex, rowDetailIndex]
  );

  const { detail, status } = linkedSettlementDetail;

  // is there is no item, maybe show nothing then why do we need the api status?
  if (!detail) {
    return null;
  }

  const { linkedWorkspaceDetails, linkedWorkspaceOverallStatus } = detail;

  // backend does not return the order but instead it returns all the chained workspace
  const rows =
    linkedWorkspaceDetails.map(
      (d, index) =>
        ({
          //
          ...d,
          order: index + 1,
          isCurrentWorkspace: d.workspaceId === workspaceId,
          className: d.workspaceId === workspaceId ? 'bg-[var(--neutral-025)]' : undefined
        }) as LinkedWorkspaceTableModel
    ) ?? [];

  const linkedDetail =
    rowDetailIndex === undefined ? null : (
      <div className="p-[20px] flex">
        <Card>
          <LinkingParticipantCard
            //
            rows={getLinkedParticipantRows(rows, rowDetailIndex)}
            columns={getLinkedParticipantColumns()}
          />
        </Card>
      </div>
    );

  return (
    <>
      <ConfirmationDialog
        //
        title={'Select your role'}
        open={open}
        showActionButtons={false}
        onClose={handleOnClose}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 400 }}>You have multiple roles in this workspace,</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 400 }}> please select the role you wish to continue with:</Typography>
        <List //
          sx={{
            listStyleType: 'disc',
            paddingLeft: '23px',
            paddingTop: '20px',
            '& .MuiListItem-root': {
              display: 'list-item',
              fontSize: 14,
              fontWeight: 700,
              lineHeight: '20px',
              cursor: 'pointer',
              padding: '2px 0px',
              textDecorationLine: 'underline'
            }
          }}
        >
          {participantsHaveAccess.map(d => {
            return (
              <ListItem
                //
                onClick={() => handleOnRoleClick(d.participantId, d.workspaceId)}
              >
                {FinancialRoleMapping[d.workspaceRole]}
              </ListItem>
            );
          })}
        </List>
        <ButtonLink //
          sx={{ fontSize: 14, fontWeight: 700, lineHeight: '20px' }}
          onClick={handleOnClose}
        >
          Cancel
        </ButtonLink>
      </ConfirmationDialog>

      <Box
        //
        title="Linked Settlement"
      >
        <div className="flex">
          <VerticalStatusLine //
            text={LinkedWorkspaceOverallStatusEnumMap[linkedWorkspaceOverallStatus]}
            variant={linkedWorkspaceOverallStatus === LinkedWorkspaceOverallStatusEnum.NotReady ? 'warning' : 'ok'}
          />
          <IconLinked style={{ marginLeft: 5, marginTop: 1 }} />
        </div>
        <LinkedWorkspacesTable
          //
          apiStatus={status}
          rows={rows}
          rowDetailNode={linkedDetail}
          rowDetailIndex={rowDetailIndex}
          onRowToggle={handleOnRowToggle}
          onViewClick={handleOnViewClick}
          userProfileDetail={{ userId, subscriberId, groupIds: userGroupIds }}
        />
      </Box>
    </>
  );
}

export default React.memo(LinkedSettlementDetailContainer);
