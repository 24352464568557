import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    disabledIcon: {
      fill: theme.palette.text.secondary
    },
    deleteIcon: {
      fill: theme.palette.primary.main
    },
    marginRight: {
      marginRight: 4
    }
  }),
  {
    name: 'DocumentStatusIcon'
  }
);
