import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { Mortgage2_27_0Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Mortgage2_27_0Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        titleType: NO_FALLBACK,
        subJurisdiction: NO_FALLBACK,
        titleShareFraction: NO_FALLBACK,
        reference: NO_FALLBACK,
        isSelected: [false],
        isPartLandAffected: [false],
        partDescription: [''],
        landDescriptions: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              isSelected: [false],
              legalDescription: NO_FALLBACK,
              landDescriptionComponents: NO_FALLBACK
            }
          }
        },
        sltReference: NO_FALLBACK,
        mortgagors: {
          $children: {
            tenancyType: [null],
            relinquishingProprietorGroups: {
              $shouldAddDefaultItemToEmptyArray: true,
              $arrayItem: {
                $children: {
                  shareTransferred: [null],
                  shareFraction: {
                    $children: {
                      numerator: [null],
                      denominator: [null]
                    }
                  },
                  previouslyHeldShareFraction: NO_FALLBACK,
                  proprietorGroupType: [null],
                  parties: {
                    $shouldAddDefaultItemToEmptyArray: false,
                    $arrayItem: {
                      $children: {
                        partyBookId: [''],
                        addressBookId: [''],
                        isTransactingParty: [true],
                        partyCapacity: [null],
                        partyCapacityDetail: ['']
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  additionalCovenants: {
    $children: {
      warning: [''],
      value: ['']
    }
  },
  mcpNumber: [''],
  mortgageDate: [null],
  mortgageeDetails: resolveTenancyFallback({
    defaultIsTransactingParty: true
  }),
  additionalAttachments: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  isFinancialSettlement: NO_FALLBACK,
  mortgagors: NO_FALLBACK,
  preRegisteredStandardTerms: NO_FALLBACK
};
