import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme/colors';
import { sharedStyles } from '../../sharedStyles';

const styles = (theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    headerContainer: {
      height: 56,
      padding: '0 12px 0 8px'
    },
    subheaderLabel: {
      paddingLeft: 16,
      marginBottom: 8,
      fontSize: 12,
      lineHeight: '20px',
      background: theme.palette.grey[100]
    },
    readIcon: {
      fill: theme.palette.common.white
    },
    conversationSubject: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 232
    },
    conversationTitle: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: colors.WHITE,
      maxWidth: 232
    },
    conversationSubtitle: {
      textTransform: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: colors.GREEK_WATERS,
      maxWidth: 232
    },
    subjectTooltip: {
      width: 200,
      backgroundColor: colors.BLACK,
      borderRadius: 6,
      boxShadow: '0px -4px 16px 0px rgba(0, 0, 0, 0.20)',
      padding: `${theme.spacing(2)}`,
      top: -4
    },
    subjectTooltipArrow: {
      '&::before': {
        backgroundColor: colors.BLACK
      },
      //! have to use important here because MUI is using inline styles
      left: '-90px !important',
      marginTop: '-16px !important'
    },
    subjectTooltipTitle: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '20px',
      color: colors.WHITE
    },
    conversationContainer: {
      marginBottom: 8
    },
    virtuosoRoot: {
      overflowY: 'auto',
      borderRadius: 0,
      boxShadow: 'none',

      '&::-webkit-scrollbar': {
        width: '0px'
      }
    },
    alertMessage: {
      backgroundColor: colors.WATERMELON_LITE,
      height: 44,
      padding: '8px 16px'
    },
    alertText: {
      color: colors.WATERMELON,
      textAlign: 'center',
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '14px'
    },
    highlightDot: {
      position: 'absolute',
      marginTop: 5,
      marginLeft: -10,
      width: 6,
      height: 6,
      background: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.common.white}`,
      borderRadius: '50%'
    },

    // conversation message
    messageBox: {
      margin: '0 8px 8px 8px',
      borderRadius: '4px'
    },
    messageDate: {
      margin: '16px 20px 8px 20px',
      display: 'flex',
      justifyContent: 'center'
    },
    messageDateText: {
      color: colors.NEUTRAL_400,
      fontSize: 11,
      lineHeight: '14px',
      fontWeight: 400
    },
    messageTitle: {
      padding: '8px 12px 0 12px'
    },
    messageBody: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.NEUTRAL_700,
      lineHeight: '20px',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      padding: '0 12px'
    },
    tooltipText: {
      color: colors.WHITE
    },
    upperCaseText: {
      textTransform: 'uppercase'
    },
    tooltipContent: {
      fontSize: 14,
      color: colors.WHITE,
      lineHeight: '16px',
      fontWeight: 400
    },
    tooltipPopper: {
      '& .MuiTooltip-tooltipPlacementLeft': {
        '& .MuiTooltip-arrow': {
          marginLeft: `-${theme.spacing(2)}`,
          marginRight: `-${theme.spacing(2)}`
        },
        top: -10
      }
    },
    boldText: {
      fontWeight: 700,
      marginTop: 8
    },
    messageHeaderBox: {
      flexGrow: 1,
      overflow: 'hidden',
      marginBottom: 2
    },
    messageHeader: {
      fontSize: '14px',
      lineHeight: '20px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontWeight: '400',
      color: colors.NEUTRAL_400
    },
    messageHeaderBlack: {
      fontWeight: '700',
      color: colors.NEUTRAL_1000
    },
    messageRecipientLabel: {
      color: theme.palette.text.secondary
    },
    messageTime: {
      fontSize: 11,
      lineHeight: '20px',
      paddingLeft: 4,
      flexShrink: 0,
      color: colors.NEUTRAL_400
    },

    // form
    multiline: {
      maxHeight: 68,
      overflowY: 'auto',
      '& .Mui-disabled': {
        backgroundColor: '#109895' //TODO review this with UX team, why new colour???
      }
    },
    inputRoot: {
      '&.Mui-disabled': {
        border: 'none',
        backgroundColor: 'inherit'
      }
    },
    multilineInput: {
      overflowY: 'hidden'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
