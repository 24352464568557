// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string; background?: 'white' }) => {
  const { title, background, ...rest } = props;
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 120C26.8625 120 0 93.1375 0 60C0 26.8625 26.8625 0 60 0C93.1375 0 120 26.8625 120 60C120 93.1375 93.1375 120 60 120Z"
        fill="#EDEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6154 101.539H38.3077C35.2487 101.539 32.7693 99.0592 32.7693 96.0001V22.1539C32.7693 19.0949 35.2487 16.6155 38.3077 16.6155H82.6154C85.6745 16.6155 88.1539 19.0949 88.1539 22.1539V96.0001C88.1539 99.0592 85.6745 101.539 82.6154 101.539Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6154 97.8461H38.3077C37.2877 97.8461 36.4615 97.0199 36.4615 95.9999V22.1538C36.4615 21.1338 37.2877 20.3076 38.3077 20.3076H82.6154C83.6354 20.3076 84.4615 21.1338 84.4615 22.1538V95.9999C84.4615 97.0199 83.6354 97.8461 82.6154 97.8461Z"
        fill="#18CDB6"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.3076 66.9316H77.9999L68.7692 74.3163V66.9316H65.0769C64.0569 66.9316 63.2307 66.1055 63.2307 65.0855V44.7778C63.2307 43.7578 64.0569 42.9316 65.0769 42.9316H98.3076C99.3276 42.9316 100.154 43.7578 100.154 44.7778V65.0855C100.154 66.1055 99.3276 66.9316 98.3076 66.9316Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.4616 65.0857H76.1539L66.9231 72.4703V65.0857H63.2308C62.2108 65.0857 61.3846 64.2595 61.3846 63.2395V42.9318C61.3846 41.9118 62.2108 41.0857 63.2308 41.0857H96.4616C97.4816 41.0857 98.3077 41.9118 98.3077 42.9318V63.2395C98.3077 64.2595 97.4816 65.0857 96.4616 65.0857Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9999 24C79.5295 24 80.7692 25.2397 80.7692 26.7692C80.7692 28.2988 79.5295 29.5385 77.9999 29.5385C76.4704 29.5385 75.2307 28.2988 75.2307 26.7692C75.2307 25.2397 76.4704 24 77.9999 24Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.8461 50.3164C81.3757 50.3164 82.6154 51.5561 82.6154 53.0856C82.6154 54.6152 81.3757 55.8549 79.8461 55.8549C78.3166 55.8549 77.0769 54.6152 77.0769 53.0856C77.0769 51.5561 78.3166 50.3164 79.8461 50.3164Z"
        fill="#FF8200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6154 50.3164C72.145 50.3164 73.3847 51.5561 73.3847 53.0856C73.3847 54.6152 72.145 55.8549 70.6154 55.8549C69.0859 55.8549 67.8462 54.6152 67.8462 53.0856C67.8462 51.5561 69.0859 50.3164 70.6154 50.3164Z"
        fill="#FF8200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.077 50.3164C90.6065 50.3164 91.8462 51.5561 91.8462 53.0856C91.8462 54.6152 90.6065 55.8549 89.077 55.8549C87.5474 55.8549 86.3077 54.6152 86.3077 53.0856C86.3077 51.5561 87.5474 50.3164 89.077 50.3164Z"
        fill="#FF8200"
      />
    </svg>
  );
};
