import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  {
    validationText: {
      color: colors.MEDIUM_GREY,
      fontWeight: 'normal'
    }
  },
  {
    name: 'ReportName'
  }
);
