import * as React from 'react';

import { Form, FormikProps } from 'formik';

import Fieldset from '@sympli/ui-framework/components/fieldset';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import { TopPanelPortalSource } from 'src/containers/settings/components/top-panel-portal';
import { useStyles } from './styles';

interface Props extends Pick<FormikProps<any>, 'isSubmitting' | 'isValid' | 'dirty'> {
  // formik related props
  // +
  // button related props
  wrapSaveButton?(button: JSX.Element, position: 'top' | 'bottom'): JSX.Element;
  onSave: FormikProps<any>['submitForm'];
  onCancel?(): void;
  hideNext?: boolean;
  backLabel?: string;
}

function DetailForm({
  //
  isSubmitting,
  isValid,
  dirty,
  wrapSaveButton,
  onSave,
  onCancel,
  children,
  hideNext,
  backLabel = 'Cancel'
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const disabled = !(dirty && isValid && !isSubmitting);

  let topSubmitButton: JSX.Element = (
    <SympliButton //
      type="button" // explicitly type button since we will be placed outside of the form anyway
      color="primary"
      variant="contained"
      arrowRight
      isLoading={isSubmitting}
      disabled={disabled}
      onClick={onSave}
    >
      Save changes
    </SympliButton>
  );

  let bottomPanel = (
    <WizardStepper //
      showBackIcon={onCancel ? true : false}
      backLabel={backLabel}
      nextLabel="Save changes"
      isLoading={isSubmitting}
      disabled={disabled}
      onBack={onCancel}
      showNext={!hideNext}
      // we don't provide onNext which means button with type submit will be returned
    />
  );

  topSubmitButton = wrapSaveButton?.(topSubmitButton, 'top') || topSubmitButton;
  bottomPanel = wrapSaveButton?.(bottomPanel, 'bottom') || bottomPanel;
  return (
    <Form className={classes.form}>
      {!hideNext && <TopPanelPortalSource>{topSubmitButton}</TopPanelPortalSource>}
      <Fieldset disabled={isSubmitting}>{children}</Fieldset>
      {bottomPanel}
    </Form>
  );
}

export default React.memo(DetailForm);
