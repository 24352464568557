import * as React from 'react';

import { useDispatch } from 'react-redux';

import { UserProfileModel } from '@sympli/api-gateway/shared';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { useSafeDispatch } from 'src/hooks';
import Password from './Password';

function PasswordContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const userProfile: UserProfileModel = useProfile().data!;

  return (
    <Password //
      dispatch={dispatch}
      userProfile={userProfile}
    />
  );
}

export default React.memo(PasswordContainer);
