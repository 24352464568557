import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    consentTitle: {
      fontSize: 13,
      fontWeight: 'bold'
    },
    consentLabel: {
      width: 475,
      marginTop: 5
    },
    checkbox: {
      alignItems: 'flex-start'
    }
  }),
  {
    name: 'Grantees'
  }
);
