import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import Applicant from '../../components/applicant';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  disabled: boolean;
  className?: string;
}

function SectionApplicant({ name, disabled, className }: Props): JSX.Element {
  return (
    <div data-name={name} className={className}>
      <Section //
        title="Applicant"
        data-name={name}
      >
        <FormGroup title="Applicant" fieldAreaDirection="column">
          <Applicant //
            name={name}
            disabled={disabled}
          />
        </FormGroup>
      </Section>
    </div>
  );
}

export default React.memo(SectionApplicant);
