import * as React from 'react';

import classNames from 'classnames';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import AvatarN from 'src/components/avatars/avatar-n';
import LodgementOnlyWorkspaceStatusBadge from 'src/containers/workspace/shared/components/lodgement-only-workspace-status-badge';
import { LinkedWorkspaceRowOverviewModel } from '../../models';
import { useSharedStyles } from '../styles';

type Props = LinkedWorkspaceRowOverviewModel &
  Pick<React.HTMLAttributes<HTMLDivElement>, 'role'> & {
    order: number;
    currentParticipantId: string;
  };

function Overview({
  // data
  order,
  jurisdictionName,
  workspaceTypeTitle,
  participants,
  workspaceStatusId,
  lodgementCaseStatusId,
  currentParticipantId,
  // html attributes
  role
}: Props) {
  const sharedClasses = useSharedStyles();
  const [firstParticipant, ...restOfParticipants] = participants;

  return (
    <FlexLayout //
      flexDirection="row"
      alignItems="center"
      role={role}
      className={sharedClasses.row}
    >
      <div role="cell" className={classNames(sharedClasses.columnOrder, sharedClasses.textAlignRight)}>
        #{order}
      </div>
      <div role="cell" className={sharedClasses.columnJurisdiction}>
        {jurisdictionName}
      </div>
      <div role="cell" className={sharedClasses.columnWorkspaceType}>
        {workspaceTypeTitle}
      </div>
      <div role="cell" className={sharedClasses.columnParticipants}>
        <AvatarN //
          text={firstParticipant?.subscriberName}
          size="small"
          n={restOfParticipants.length}
          src={firstParticipant?.avatarUrl}
          highlighted={firstParticipant?.participantId === currentParticipantId}
        />
      </div>
      <div role="cell" className={sharedClasses.columnStatus}>
        <LodgementOnlyWorkspaceStatusBadge //
          workspaceStatusId={workspaceStatusId}
          lodgementCaseStatusId={lodgementCaseStatusId}
          size="small"
        />
      </div>
    </FlexLayout>
  );
}

export default React.memo(Overview);
