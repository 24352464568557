import { createAction } from 'typesafe-actions';

import { AuthorityComplianceReportTypeEnum } from '@sympli/api-gateway/enums';

export const actionOpenLodgementVerificationDialogWithContext = createAction(
  //
  'OPEN_LODGEMENT_VERIFICATION_DIALOG_WITH_CONTEXT'
)<{
  //
  authorityMessageId?: string;
  authorityComplianceReportType?: AuthorityComplianceReportTypeEnum;
  lodgementCaseId?: string;
}>();

export const actionOpenLodgementVerificationDialog = createAction(
  //
  'OPEN_LODGEMENT_VERIFICATION_DIALOG'
)();

export const actionCloseLodgementVerificationDialog = createAction(
  //
  'CLOSE_LODGEMENT_VERIFICATION_DIALOG'
)();
