import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    halfWidth: {
      width: 240
    },
    forwardSlash: {
      fontSize: 26
    },
    longLabel: {
      '& > label': {
        width: 'max-content'
      }
    },
    noLabel: {
      marginTop: 20
    },
    devHelper: {
      right: 0,
      color: `rgba(210, 105, 30, 0.5)`,
      bottom: -12,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'sans-serif'
    },
    errorWrapper: {
      marginBottom: 4,
      padding: 8,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light,
      borderWidth: 1,
      borderRadius: 4,
      fontSize: 16,
      '& > label': {
        fontSize: 16
      },
      '& > p': {
        fontSize: 16
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
