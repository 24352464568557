import { call, put, takeLeading } from 'typed-redux-saga';

import { ICertIdentifier } from '@sympli/digital-signing/interfaces';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { DialogTypeEnum } from 'src/components/message-dialog/models';
import {
  errorMessageMapping,
  getDefaultSigningCertificateId,
  getUserCertificatesFromToolkit,
  getUserInfo,
  resolveNoCertificateFoundErrorMsg,
  setDefaultSigningCertificateId
} from 'src/utils/signing/helpers';
import { GetUserCertsArgs } from 'src/utils/signing/model';
import { actionGetCertificates, GetCertificatesRequest, GetCertificatesResponse } from './actions';

// see tests for more details
export const getCertificates = async ({
  //
  userProfile,
  logGroupId // WEB-27575
}: GetCertificatesRequest): Promise<GetCertificatesResponse> => {
  const userCertsArgs: GetUserCertsArgs = {
    userInfo: getUserInfo(userProfile)
  };

  // explicitly always fetch current list of certificates first
  // to prevent situation where previously selected certificate stored in local storage
  // is no longer installed
  const { certs } = await getUserCertificatesFromToolkit(
    //
    userCertsArgs,
    logGroupId
  );

  if (certs.length === 0) {
    const {
      userInfo: { allowSoftToken, allowHardToken }
    } = userCertsArgs;
    // resolve real message that will be shown to the user
    throw Error(resolveNoCertificateFoundErrorMsg(allowSoftToken, allowHardToken));
  }

  // try to get previously selected certificate from local storage
  const defaultCert: ICertIdentifier | undefined = getDefaultSigningCertificateId(userProfile.userId);
  // make sure that previously selected default certificate is still installed
  if (defaultCert && typeof defaultCert === 'object') {
    if (certs.find(c => c.id.certSerialNumber === defaultCert.certSerialNumber)) {
      return {
        // include both, the list of found certs and also selected certificate
        certificatesToSelect: certs,
        previouslySavedCertIdentifier: defaultCert,
        userInfo: userCertsArgs.userInfo
      };
    } else {
      // reset reference in local storage if such a certificate is not installed
      setDefaultSigningCertificateId(userProfile.userId);
    }
  }

  // if there is only one cert, we preselect it
  if (certs.length === 1) {
    return {
      certificatesToSelect: certs,
      previouslySavedCertIdentifier: certs[0].id,
      userInfo: userCertsArgs.userInfo
    };
  }

  return {
    //
    certificatesToSelect: certs,
    userInfo: userCertsArgs.userInfo
  };
};

export function* sagaGetCertificates(action: ReturnType<typeof actionGetCertificates.request>) {
  try {
    const data = yield* call(getCertificates, action.payload);
    yield put(actionGetCertificates.success(data));
  } catch (error) {
    // display global global error message
    yield put(
      actionCreateGlobalErrorMessage({
        type: DialogTypeEnum.Error,
        title: 'Unable to obtain signing certificates',
        // error.message is the message code we received from library.
        // If we can't find any mapping global error message handler will fallback to a generic Please call... message
        message: errorMessageMapping[error.message]
      })
    );
    yield put(actionGetCertificates.failure({ error }));
  }
}

// * We only want to get certificates once
export default [takeLeading(actionGetCertificates.request, sagaGetCertificates)];
