import * as React from 'react';

import { useDispatch } from 'react-redux';

import { UserProfileModel } from '@sympli/api-gateway/shared';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { actionUpdateConversationIsRead } from '../../actions';
import { useMessengerConversations } from '../../reducer';
import { getIsFinalisedOrArchived, messagingDisabledSelector } from '../../selectors';
import MessageList from './MessageList';
import MessageList101 from './MessageList101';
import { newMessageCountSelector } from './selectors';

interface Props {
  workspaceId: string;
  participantId: string;
  onConversationSelect(selectedConversationId?: string): void;
}

function MessageListContainer({ workspaceId, participantId, onConversationSelect }: Props) {
  const isWorkspaceMessenger101Enabled = useFeatureFlag(FeatureToggleEnum.workspaceMessenger101Enabled);
  const dispatch = useSafeDispatch(useDispatch());
  const { timezone }: UserProfileModel = useProfile().data!;
  const workspaceParticipants = useWorkspaceParticipants(workspaceId);
  const messengerConversations = useMessengerConversations(workspaceId, participantId);

  const newMessageCount: number = newMessageCountSelector({
    messengerConversations
  });

  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const isNewMessageDisabled101 = getIsFinalisedOrArchived(workspaceBasicInfoState);

  const handleOnConversationRead = React.useCallback(
    (conversationId: string) => {
      const participantDetail = workspaceParticipants.items.find(x => x.id === participantId);
      if (!participantDetail?.archivedStatus) {
        dispatch(actionUpdateConversationIsRead.request({ workspaceId, participantId, conversationId, isRead: true }));
      }
    },
    [workspaceParticipants.items, participantId, dispatch, workspaceId]
  );

  const sortedConversations = React.useMemo(() => {
    // Sorting conversations based on the last message created time, in descending order
    return [...messengerConversations.items].sort((a, b) => {
      const aTimeStamp = new Date(a.messages[a.messages.length - 1].createdDate).getTime();
      const bTimeStamp = new Date(b.messages[b.messages.length - 1].createdDate).getTime();
      return bTimeStamp - aTimeStamp;
    });
  }, [messengerConversations.items]);

  if (isWorkspaceMessenger101Enabled) {
    return (
      <MessageList101 //
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        // profile
        timezone={timezone}
        // workspace participants
        participants={workspaceParticipants.items}
        // conversation
        items={sortedConversations}
        onConversationRead={handleOnConversationRead}
        onConversationSelect={onConversationSelect}
        newMessageDisabled={isNewMessageDisabled101}
      />
    );
  }

  //TODO bring back this old logic for not changing the old workspace messenger behaviour, will clean it up later because it's wrong
  const isNewMessageDisabled = messagingDisabledSelector({
    participantId,
    workspaceBasicInfoState,
    workspaceParticipantsState: workspaceParticipants
  });

  return (
    <MessageList //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // profile
      timezone={timezone}
      // workspace participants
      participants={workspaceParticipants.items}
      // conversation
      items={messengerConversations.items}
      newMessageCount={newMessageCount}
      onConversationRead={handleOnConversationRead}
      onConversationSelect={onConversationSelect}
      newMessageDisabled={isNewMessageDisabled}
    />
  );
}

export default React.memo(MessageListContainer);
