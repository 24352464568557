import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 28,
      paddingBottom: 40,
      background: theme.palette.grey[50],
      flexGrow: 1
    },
    actionButton: {
      marginTop: 16,
      '& + $actionButton': {
        marginLeft: 20
      }
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    }
  });

export type ClassKeys = typeof styles;

export const useStyles = makeStyles(styles, {
  name: 'LodgementProgress'
});

export default styles;
