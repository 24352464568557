import { SaNameChange, saPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/sa/2-23/components/party-justification';
import { SA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/sa/2-23/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';

import { SA_TENANCY_COMPONENT_LABEL_CONFIG } from '../../components/tenancy/config';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const SA_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...SA_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.Mortgagee
};

export const SA_MORTGAGE_PARTY_FORM_CONFIG: PartyFormConfig<SaNameChange> = {
  ...SA_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...saPartyNameChangeConfig, hideWhenEditable: true }
};

export const SA_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...SA_TENANCY_COMPONENT_LABEL_CONFIG,
  partyCapacity: CommonTenancyLabelsEnum.Capacity,
  partyRole: 'Mortgagor',
  footer: { jointTenants: 'Add Mortgagor', tenantsInCommon: '' }
};
