import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dateAndTime: {
      width: '40%',
      paddingRight: 10
    },
    interval: {
      width: '20%'
    },
    intervalText: {
      paddingLeft: 10,
      paddingTop: 25
    },
    itemSelected: {
      backgroundColor: 'blue'
    },
    inputLabel: {
      whiteSpace: 'nowrap',
      position: 'relative'
    },
    root: {
      paddingBottom: 20
    },
    button: {
      marginTop: 6,
      height: 34,
      width: 40,
      marginRight: 5
    },
    helperTextError: {
      position: 'absolute',
      marginTop: 4,
      bottom: 0
    },
    div: {
      paddingRight: 6
    },
    dayOfWeekTitle: {
      margin: '5px 0px',
      fontWeight: 'bold'
    },
    dayOfMonth: {
      width: '50%',
      marginLeft: 15
    },
    displayMessage: {
      margin: '5px 0px'
    }
  }),
  {
    name: 'ReportSchedulerFrequency'
  }
);
