import { defaultMemoize } from 'reselect';

import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { CaveatModel, CaveatorPartyModel, TitleReferenceModel, WithdrawalOfCaveat2_25_1Model } from './models';

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const getSelectedCaveat = defaultMemoize((caveatSelection: CaveatModel[]) => {
  // get selected caveat
  // user can select only one caveat
  // automatically select the first one incase there is only one caveat
  if (caveatSelection.length === 1) {
    return caveatSelection[0];
  }

  return caveatSelection.find(({ isSelected }) => isSelected);
});

// grab all titles from caveats
export const filterTitleReferences = defaultMemoize((caveats: CaveatModel[]): TitleReferenceModel[] => {
  const titleReferences = caveats
    .flatMap(x => x.titleReferences)
    .reduce<TitleReferenceModel[]>((titles: TitleReferenceModel[], currentTitle: TitleReferenceModel) => {
      const exists = titles.findIndex(tr => tr.reference === currentTitle.reference);
      if (exists !== -1) {
        titles[exists].isSelected = titles[exists].isSelected || currentTitle.isSelected;
      } else {
        titles.push(currentTitle);
      }
      return titles;
    }, []);
  return titleReferences;
});

export const getCaveatsBeingWithdrawn = defaultMemoize((caveats: CaveatModel[], titleReferences: TitleReferenceModel[]): CaveatModel[] => {
  const selectedTitles = titleReferences.filter(({ isSelected }) => isSelected).flat();

  // filter caveats which include all the selected title references
  const caveatsBeingWithdrawn = caveats.filter(c => {
    const caveatTitleReferences = c.titleReferences.flatMap(title => title.reference);
    return selectedTitles.every(x => caveatTitleReferences.includes(x.reference));
  });

  return caveatsBeingWithdrawn;
});

export const getCaveators = defaultMemoize((caveats: CaveatModel[]): CaveatorPartyModel[] => {
  const result = getSelectedCaveat(caveats)?.caveators || [];
  return result;
});

export const getOtherWithdrawalsOfCaveatDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.WithdrawalOfCaveat && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherWithdrawalsOfCaveat = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as WithdrawalOfCaveat2_25_1Model;
    return data.caveats.filter(caveat => caveat.isSelected).flatMap(caveat => caveat.dealingNumber);
  });
};
