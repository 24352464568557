/**
 * This helper is to determine whether we want to show report-permissions link or not.
 */
export const showReportPermissionTab = (
  //
  data: {
    isBiReportingEnabled: boolean;
    isProfileUserSuperAdmin: boolean;
    isCurrentUserActive: boolean;
  }
) => {
  const { isBiReportingEnabled, isProfileUserSuperAdmin, isCurrentUserActive } = data;
  return (
    // bi reporting must be enabled
    isBiReportingEnabled &&
    // current logged in user must be super admin
    isProfileUserSuperAdmin &&
    // current viewing user must be active and not suspended
    isCurrentUserActive
  );
};
