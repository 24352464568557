import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      top: '-28px',
      right: '60px',
      zIndex: 2,
      width: 344,
      height: 124,
      padding: 16,
      display: 'flex',
      flexDirection: 'column',

      borderRadius: '6px',
      background: colors.GREEK_WATERS_TRANSLUCENT,
      boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.10)'
    },
    title: {
      color: colors.SYMPLI_GREEN,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      textTransform: 'uppercase',
      marginBottom: 16
    },
    text: {
      color: colors.NEUTRAL_800,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px'
    },
    button: {
      color: colors.NEUTRAL_400,
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '20px',
      textDecoration: 'underline',
      width: 47
    }
  }),
  {
    name: 'RefreshIconMessage'
  }
);
