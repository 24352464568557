import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

const IBN_PATTERN: RegExp = /^([0-9]{1,3})([A-Z]{1})\b/;
const MIN_CHARS = 2;

export default function resolveIbnValidation(isRequired: boolean) {
  // Note: Using test() instead of when() to avoid cyclic dependency

  return yup
    .string()
    .default('')
    .trim()
    .test(
      'minimum characters', //
      msg.LENGTH_MUST_BE_AT_LEAST_CHARACTERS(MIN_CHARS),
      function test(value: string) {
        // validate only when it is a required field or when user has entered atleast one character
        if (isRequired || value.length >= 1) {
          return value.length >= MIN_CHARS;
        }
        return true;
      }
    )
    .max(4, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(4))
    .test(
      'required', //
      msg.REQUIRED,
      function test(value: string) {
        // validate only when it is a required field
        if (isRequired) {
          return value.length > 0;
        }
        return true;
      }
    )
    .test(
      'pattern matches', //
      'Maximum of 3 digits with 1 alpha always present as last character. Example: 888V',
      function test(value: string) {
        // validate only when user has entered atleast one character
        if (value.length >= 1) {
          return IBN_PATTERN.test(value);
        }
        return true;
      }
    );
}
