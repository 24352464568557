import { NSW_PARTY_FORM_CONFIG as NSW_LEASE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { InterestOnTitle, SubLease2_21_1Model } from '../../models';

interface PartyBookValidationContext {
  interestsOnTitle: InterestOnTitle[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: SubLease2_21_1Model): PartyBookValidationContext => {
  return {
    interestsOnTitle: parentContext.interestsOnTitle
  };
};

const checkShouldValidateItem = ({ interestsOnTitle }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => {
  const selectedInterest = interestsOnTitle.find(x => x.isSelected);
  if (!selectedInterest) return false;
  const selectedLessorsIds = selectedInterest.lessors.map(x => x.partyBookId);

  const isSelectedLessor = !!~selectedLessorsIds.indexOf(partyIdForCheck);
  return isSelectedLessor;
};

const yupPartyItem = resolvePartyModelValidationSchema<{}, PartyBookValidationContext>(NSW_LEASE_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
