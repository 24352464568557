import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import msg from '@sympli/ui-framework/utils/messages';

import { PartyBookIdentifierModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupApplicants = (partyFormConfig: PartyFormConfig) =>
  yup
    .array<PartyBookIdentifierModel>()
    .required(msg.REQUIRED)
    .min(1, msg.MIN_ITEMS(1))
    .max(20, msg.MAX_ITEMS(20))
    .of(
      yup
        .object<PartyBookIdentifierModel>()
        .nullable()
        .shape({
          partyBookId: yup.string().default('').trim().required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(partyFormConfig))
        })
    );

export default yupApplicants;
