import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';

import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import { DocumentActionInfo } from 'src/containers/workspace/shared/detail/models';

export interface WorkflowStepModel {
  steps: Array<StepObjectModel>;
  currentStep: DocumentWorkflowStepsEnum;
  enabledNextStep?: DocumentWorkflowStepsEnum;
  onStepClick(stepValue?: DocumentWorkflowStepsEnum): void;
}

export interface ActionButtonModel {
  documentActionInfo?: DocumentActionInfo;
  onPrimaryActionClick?(): void;
}

export interface MoreActionButtonModel {
  hasMoreActions?: boolean;
  actionItems?: JSX.Element;
}

export enum DisplayViewEnum {
  PDF = 'PDF',
  Form = 'Form'
}
