import * as React from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { Box } from 'src/components/layout';
import DocumentItemLoader from 'src/components/loaders/document-item-loader';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useAllDirections } from 'src/containers/workspace/financial/all-directions/reducer';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { havePurchaserParticipantInWorkspace } from '../../helpers';
import { useDirectionsBreakdown } from '../../reducers/directionsBreakdown';
import { trustAccountOptionsSelector } from '../../views/edit-directions/selectors';
import DirectionsSummary from '../directions-summary';

export default function DirectionsSummaryBoxContainer(props: {
  // route params
  workspaceId: string;
  participantId: string;
  // other
  portalIdForDirectionSummary?: string;
  onExpandChange?(expand: boolean): void;
}) {
  const workspaceBasicInfoState = useWorkspaceBasicInfo(props.workspaceId);
  const workspaceParticipantsState = useWorkspaceParticipants(props.workspaceId);
  const directionsBreakdownState = useDirectionsBreakdown(props.workspaceId, props.participantId);
  const workspaceDetailsState = useWorkspaceDetail(props.workspaceId, props.participantId);
  const allDirectionsState = useAllDirections(props.workspaceId, props.participantId);

  const currentWorkspaceParticipant: WorkspaceParticipantApiResponse | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: props.participantId,
    workspaceParticipants: workspaceParticipantsState
  });
  const havePurchaserParticipant = havePurchaserParticipantInWorkspace(workspaceParticipantsState.items);

  if ((directionsBreakdownState.isLoading && allDirectionsState.isLoading) || workspaceParticipantsState.isLoading) {
    return (
      <Box>
        <List>
          <ListItem disableGutters>
            <DocumentItemLoader color="grey" />
          </ListItem>
          <ListItem disableGutters>
            <DocumentItemLoader color="grey" />
          </ListItem>
        </List>
      </Box>
    );
  }

  if (!currentWorkspaceParticipant) {
    return null;
  }

  if (directionsBreakdownState.status === 'rejected') {
    return (
      <Box>
        <Typography component="div" variant="subtitle1">
          <span>Calculators</span>
        </Typography>
        <FormHelperText error role="alert">
          {directionsBreakdownState.error}
        </FormHelperText>
      </Box>
    );
  }

  if (!directionsBreakdownState.detail && !allDirectionsState.detail) {
    return null;
  }

  if (props.portalIdForDirectionSummary) {
    return <PortalTarget id={props.portalIdForDirectionSummary} className="tickle-box" />;
  }

  const sourcesFunds = allDirectionsState.detail?.distributionsParticipants.filter(x => x.participantId === props.participantId).flatMap(x => x.sourceFunds);

  return (
    <DirectionsSummary // workspace basic info
      workspaceTypeId={workspaceBasicInfoState.detail?.workspaceTypeId}
      // current participant
      workspaceRoleId={currentWorkspaceParticipant.workspaceRole.id}
      // directions breakdown
      directionsSummary={directionsBreakdownState.detail?.directionsSummary ?? allDirectionsState.detail!.directionsSummary}
      trustAccounts={directionsBreakdownState.detail?.trustAccounts ?? allDirectionsState.detail!.trustAccounts}
      sourceFunds={directionsBreakdownState.detail?.sourceFunds ?? sourcesFunds}
      trustAccountBankDetailMap={trustAccountOptionsSelector(directionsBreakdownState.detail ?? allDirectionsState.detail!).trustAccountBankDetailMap}
      requiresStampDuty={workspaceDetailsState.detail?.requiresStampDuty}
      // other
      onExpandChange={props.onExpandChange}
      havePurchaserParticipant={havePurchaserParticipant}
    />
  );
}
