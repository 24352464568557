import _uniq from 'lodash-es/uniq';

import { VicNameChange } from '@sympli-mfe/document-components/party-form/vic/2-17/components/party-justification';
import { RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { arraysIntersectionsBy, mergeArrays } from '@sympli-mfe/document-forms-framework/utils';

import { ApiDischargeOfMortgage2_17_1Model, BaseTitleReferenceModel, DischargeOfMortgage2_17_1Model, MortgageePartyModel } from './models';

export const filterTitleReferences = <T extends Pick<ApiDischargeOfMortgage2_17_1Model, 'mortgages'>>(values: T): BaseTitleReferenceModel[] => {
  const result = arraysIntersectionsBy(
    values.mortgages?.filter(r => r.isSelected).map(x => x.titleReferences),
    'reference'
  );
  return result;
};

export const filterMortgagees = <T extends Pick<ApiDischargeOfMortgage2_17_1Model, 'mortgages'>>(values: T): MortgageePartyModel[] => {
  const result = mergeArrays(
    values.mortgages?.filter(r => r.isSelected).map(x => x.mortgagees),
    'partyBookId'
  );
  return result;
};

export const isMortgageeNameMismatch = (values: DischargeOfMortgage2_17_1Model): boolean => {
  const singleMortgageSelected = values.mortgages.filter(s => s.isSelected).length === 1;
  const singleTitleSelected = values.titleReferences.filter(s => s.isSelected).length === 1;
  if (singleMortgageSelected && singleTitleSelected) {
    return false;
  }
  const selectedPartyBookIds = values.mortgages
    .filter(mortgage => mortgage.isSelected)
    .map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId))
    .flat();

  const partyDetails = values.partyBook?.filter(partyBook => selectedPartyBookIds.includes(partyBook.id));

  const uniqNames = _uniq(partyDetails?.map(mortgagee => mortgagee.legalEntityName)).length ?? 0;
  if (uniqNames === 1) {
    return false;
  }

  const mortgagees = partyDetails?.map(mortgagee => mortgagee.receivingOrRelinquishingDetails) as RelinquishingModel<VicNameChange>[];
  const isJustified = mortgagees.some(({ isChangingName }) => isChangingName);

  if (uniqNames > 1 && !isJustified) {
    return true;
  }
  return false;
};
