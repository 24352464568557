import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import Typography from '@mui/material/Typography';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconExclamation } from '@sympli/ui-framework/icons';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { addAndUpdateDirectionsFormikProps, AddAndUpdateProps } from '../../../../helpers';
import { DistributionFormikModel, TrustAccountMap } from '../../../../models';
import { PurchaserDirectionsFormModel } from '../../forms/purchaser-directions/models';
import { VendorDirectionsFormModel } from '../../forms/vendor-directions/models';
import { SourceFundsStatusEnum } from './models';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  verifiedAmount: number | null;
  expectedAmount: number | null;
  defaultValue: DistributionFormikModel;
  formikProps: FormikProps<PurchaserDirectionsFormModel | VendorDirectionsFormModel>;
  trustAccountBankDetailMap: TrustAccountMap;
  onClose: () => void;
}

function SourceFundsDialog({ open, onClose, trustAccountBankDetailMap, formikProps, verifiedAmount, defaultValue, expectedAmount }: Props) {
  const classes = useStyles();
  if (verifiedAmount == null || expectedAmount == null) {
    return null;
  }

  const handleClose = (onCloseStatus?: SourceFundsStatusEnum) => {
    // open direction payee and pre-populate form fields
    if (onCloseStatus === SourceFundsStatusEnum.Surplus) {
      const sourceBalance = Number(verifiedAmount) - Number(expectedAmount);

      const addAndUpdateArgs: AddAndUpdateProps = {
        formikProps,
        trustAccountBankDetailMap,
        defaultValue,
        sourceBalance
      };

      addAndUpdateDirectionsFormikProps({ ...addAndUpdateArgs });
    }

    onClose();
  };

  const sourceBalance = verifiedAmount - expectedAmount;

  return (
    <ConfirmationDialog
      ariaLabel="Source Funds"
      disabled={false}
      open={open}
      showActionButtons={false}
      onClose={() => {
        handleClose();
      }}
      classes={{
        dialogPaper: classes.dialogPaper,
        dialogContent: classes.dialogContent
      }}
    >
      <FlexLayout flexDirection="row">
        <FlexLayout flexDirection="column" className={classes.dialogContent}>
          <FlexLayout flexDirection="column" justifyContent="center" className={classes.dialogContentTop}>
            <Typography variant="h2" className={classes.dialogTitle}>
              Source funds verified
            </Typography>
            <FlexLayout justifyContent="center" alignItems="center" className={classes.summaryHeading}>
              <IconExclamation className={classNames(classes.icon, classes.iconOrange)} />
              <Typography className={classes.dialogSubTitle}>Surplus available</Typography>
            </FlexLayout>

            <FlexLayout className={classes.summaryRow} justifyContent="space-between">
              <div>Expected amount:</div>
              <div>{currency(expectedAmount)}</div>
            </FlexLayout>

            <FlexLayout className={classes.summaryRow} justifyContent="space-between">
              <div>Verified amount^:</div>
              <div>{currency(verifiedAmount)}</div>
            </FlexLayout>

            <FlexLayout className={classNames(classes.summaryRow, classes.lastSummaryRow)} justifyContent="space-between">
              <div className={classes.sourceSurplus}>Surplus</div>
              <div className={classes.sourceSurplus}>{currency(Math.abs(sourceBalance))}</div>
            </FlexLayout>
          </FlexLayout>
          <Typography className={classes.message}>^ Checked daily at 8:00 AM</Typography>
          <FlexLayout flexDirection="column" justifyContent="center" className={classes.dialogContentBottom}>
            <Typography variant="h2" className={classes.infoTitle}>
              Add surplus to distributions
            </Typography>
            <div className={classes.infoContentPanel}>
              <div className={classes.contentRow}>
                <div className={classes.bold}>1. Directions</div>
                <Typography className={classes.infoContent}>All you need to do is update your directions with the surplus amount.</Typography>
              </div>
              <div className={classes.contentRow}>
                <div className={classes.bold}>2. Source funds</div>
                <Typography className={classes.infoContent}>Your source fund will auto-update to the verified amount.</Typography>
              </div>
            </div>
            <div className={classes.buttonRow}>
              <SympliButton
                color="primary"
                arrowRight
                fullWidth
                className={classes.button}
                onClick={() => {
                  handleClose(verifiedAmount >= expectedAmount ? SourceFundsStatusEnum.Surplus : SourceFundsStatusEnum.ShortFall);
                }}
                variant="contained"
              >
                Update directions now
              </SympliButton>
            </div>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </ConfirmationDialog>
  );
}

export default SourceFundsDialog;
