import * as React from 'react';

import { WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import WorkspaceStaffAssignContainer from 'src/containers/shared/workspace-staff-assign/WorkspaceStaffAssignContainer';
import { AllocatedUser } from 'src/models';

export interface Props {
  // route params
  workspaceId: string;
  participantId: string;
  // workspace basic info
  workspaceTypeId?: WorkspaceTypeEnum;
  workspaceStatusId: WorkspaceStatusEnum;
  // current participant info
  groupId: string;
  assignee?: AllocatedUser;
  assignedGroupName?: string;

  // profile
  userId: string;

  onAssignToMe(): Promise<void>;
  onAssigneeUpdated(workspaceId: string, participantId: string, selectedSuggestion: AllocatedUser): void;
}

function WorkspaceMenuItemStaffAssign({ workspaceStatusId, workspaceId, participantId, workspaceTypeId, groupId, assignee, assignedGroupName, onAssigneeUpdated }: Props) {
  const isWorkspaceFinalized: boolean = [
    WorkspaceStatusEnum.Withdrawn,
    WorkspaceStatusEnum.Archived,
    WorkspaceStatusEnum.Success,
    WorkspaceStatusEnum.Finalised,
    WorkspaceStatusEnum.Abandoned
  ].includes(workspaceStatusId);

  const username = assignee ? `${assignee.firstName} ${assignee.lastName}` : '';

  return (
    <WorkspaceStaffAssignContainer
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      workspaceTypeId={workspaceTypeId}
      // info about currently allocated user
      userName={username}
      groupId={groupId}
      assignedUserId={assignee?.userId}
      avatarUrl={assignee?.avatarUrl}
      onAssigneeUpdated={onAssigneeUpdated}
      assignedGroupName={assignedGroupName}
      isWorkspaceFinalized={isWorkspaceFinalized}
    />
  );
}

export default React.memo(WorkspaceMenuItemStaffAssign);
