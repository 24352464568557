import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const userStyles = makeStyles(
  (theme: Theme) => ({
    marginRight: {
      marginRight: 10
    },
    buttonMarginRight: {
      marginRight: 20
    }
  }),
  {
    name: 'ActionButtons'
  }
);

export default userStyles;
