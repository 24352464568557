import { call, put, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { WorkspaceReportApiResponse } from '@sympli/api-gateway/models';

import { actionFetchWorkspaceReportsFeed, WorkspaceReportsApiRequest } from './actions';

const fetchWorkspaceReportsFeed = (args: WorkspaceReportsApiRequest) => {
  return endpoints.getWorkspaceReports(args);
  // const { workspaceId, participantId } = args;
  // return http.get<WorkspaceReportApiResponse[]>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/reports`);
};

export function* sagaFetchWorkspaceReportsFeed(action: ReturnType<typeof actionFetchWorkspaceReportsFeed.request>) {
  try {
    const data: WorkspaceReportApiResponse[] = yield* call(fetchWorkspaceReportsFeed, action.payload);
    yield put(actionFetchWorkspaceReportsFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspaceReportsFeed.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchWorkspaceReportsFeed.request, sagaFetchWorkspaceReportsFeed)
];
