import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchLinkedWorkspaces } from './actions';
import { LinkedWorkspaceApiResponseModel } from './models';
import { LinkedWorkspacesApiRequest } from './sagas';

export interface LinkedWorkspacesState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  items: LinkedWorkspaceApiResponseModel[];
  error?: string;
  args?: LinkedWorkspacesApiRequest;
}

const initialState: LinkedWorkspacesState = {
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  items: [],
  error: undefined,
  args: undefined
};

export function useLinkedWorkspaces(workspaceId: string) {
  const state = useStoreSelector(store => store.linkedWorkspaces);
  if (state.args?.workspaceId === workspaceId) {
    return state;
  }
  return initialState;
}

const linkedWorkspacesReducer = createReducer<
  //
  LinkedWorkspacesState,
  Action
>(initialState)
  .handleAction(actionFetchLinkedWorkspaces.request, (state, action): LinkedWorkspacesState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchLinkedWorkspaces.success,
    (state, action): LinkedWorkspacesState => ({
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.data
    })
  )
  .handleAction(
    actionFetchLinkedWorkspaces.failure,
    (state, action): LinkedWorkspacesState => ({
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    })
  );

export default linkedWorkspacesReducer;
