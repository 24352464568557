import React, { useCallback } from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import Typography from '@mui/material/Typography';

import { ReportTypeEnum, UserRoleEnum } from '@sympli/api-gateway/enums';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import Formik from 'src/components/formik';
import DetailForm from 'src/containers/settings/components/detail-form';
import SubheadingWithStatus from 'src/containers/settings/settings/users-setting/components/subheading-with-status';
import { modelKey } from 'src/utils/formUtils';
import { UpdateUserReportsPermissions, UserReportsPermissionsDetailFormModel } from '../../../models';
import { useStyles } from '../../styles';
import TeamWorkloadReportAccessOption from '../team-workload-report';
import { useStyles as useDevStyles } from './styles';

export interface Props {
  initialValues: UserReportsPermissionsDetailFormModel;
  userId: string;
  options: LookupEnumModel<ReportTypeEnum>[];
  onCancel(): void;
  onPreSubmit(value: UserReportsPermissionsDetailFormModel): UpdateUserReportsPermissions;
  onPostSubmit(args: FormikPostSubmitArgs<UserReportsPermissionsDetailFormModel>): void;
  isSuperAdmin: boolean;
}

const fieldName = modelKey<UserReportsPermissionsDetailFormModel>();

export default React.memo(function ReportsPermissionsForm({ initialValues, userId, options, onCancel, onPreSubmit, onPostSubmit, isSuperAdmin }: Props): JSX.Element {
  const classes = useStyles();
  const devClasses = useDevStyles();

  const renderForm = useCallback(
    ({ isSubmitting, dirty, isValid, setValues, submitForm }: FormikProps<UserReportsPermissionsDetailFormModel>) => {
      const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) =>
        setValues(values => ({
          ...values,
          selected: e.target.checked,
          reportPermissions: e.target.checked ? options.map(reportType => reportType.id) : []
        }));

      const handleCheckboxGroup = (e: React.ChangeEvent<HTMLElement>, resolvedValues: Array<ReportTypeEnum>) => {
        setValues(values => {
          const selected = resolvedValues.length === options.length;
          if (values.selected !== selected) {
            return {
              ...values,
              selected
            };
          }

          return values;
        });
      };

      const convertNonSuperAdminOptions = (options: LookupEnumModel<number>[]) =>
        options.map(x => ({
          id: x.id,
          name:
            x.id === ReportTypeEnum.TeamWorkloadReport ? (
              <TeamWorkloadReportAccessOption
                name={x.name}
                teamWorkloadReportAssignedNonSuperAdminUsers={nonAdminTeamWorkloadReportAssignedUsers}
                maxTeamWorkloadReportAccessNumber={initialValues.maxTeamWorkloadReportAccessNumber}
              />
            ) : (
              x.name
            )
        }));

      const nonAdminTeamWorkloadReportAssignedUsers = initialValues.teamWorkloadReportAssignedUsers.filter(x => x.role !== UserRoleEnum.SuperAdmin);

      const disableTeamWorkloadReportOption =
        // non super admin users who are granted Team workload report permission cannot greater than or equal to limit
        nonAdminTeamWorkloadReportAssignedUsers.length >= initialValues.maxTeamWorkloadReportAccessNumber &&
        // when limit is hit, the users in the assigned list still can disable TWR permission
        !nonAdminTeamWorkloadReportAssignedUsers.some(x => x.userId === userId);

      const resolveDisableOption = (option: LookupEnumModel<number>) => {
        // for Team workload report
        if (option.id === ReportTypeEnum.TeamWorkloadReport) {
          return disableTeamWorkloadReportOption;
        }
        // for other reports, currently (User Activity and User Permission)
        return false;
      };

      const getReportSubtitle = () => {
        if (options.length) {
          return <Typography variant="subtitle2">This user can access to following reports</Typography>;
        }
        return (
          <Typography variant="subtitle2">
            This user does not have access to Sympli Reports. Please contact <b>Sympli Customer Support</b> for help.
          </Typography>
        );
      };

      return (
        <DetailForm //
          isValid={isValid}
          dirty={dirty}
          isSubmitting={isSubmitting}
          onSave={submitForm}
          onCancel={onCancel}
        >
          <SubheadingWithStatus userId={userId} />
          <FormGroup title="Reports permissions" description="This user will be granted access to Reports on the navigation menu.">
            {({ titleId }) => (
              <FlexLayout flexDirection="column" fullWidth>
                {getReportSubtitle()}
                {isSuperAdmin && <Field component={CheckboxField} name={fieldName('selected')} label="Access all reports" onChange={handleSelectAllCheckbox} />}
                {isSuperAdmin ? (
                  <Field //
                    className={classNames(classes.checkboxGroup)}
                    vertical={true}
                    name={fieldName('reportPermissions')}
                    component={CheckboxGroupField}
                    options={options}
                    format="number"
                    aria-labelledby={titleId}
                    onChange={handleCheckboxGroup}
                  />
                ) : (
                  <Field //
                    className={classNames(classes.checkboxGroup)}
                    vertical={true}
                    name={fieldName('reportPermissions')}
                    component={CheckboxGroupField}
                    options={convertNonSuperAdminOptions(options)}
                    format="number"
                    aria-labelledby={titleId}
                    classes={devClasses}
                    resolveItemDisabled={resolveDisableOption}
                  />
                )}
              </FlexLayout>
            )}
          </FormGroup>
        </DetailForm>
      );
    },
    [classes.checkboxGroup, devClasses, initialValues.maxTeamWorkloadReportAccessNumber, initialValues.teamWorkloadReportAssignedUsers, isSuperAdmin, onCancel, options, userId]
  );

  return (
    <Formik //
      method="put"
      initialValues={initialValues}
      action={`/settings/users/${encodeURIComponent(userId)}/report-permissions`}
      onPostSubmit={onPostSubmit}
      onPreSubmit={onPreSubmit}
    >
      {(formikProps: FormikProps<UserReportsPermissionsDetailFormModel>) => renderForm(formikProps)}
    </Formik>
  );
});
