import React from 'react';

import { ICertIdentifier, ISignResponse } from '@sympli/digital-signing/interfaces';

import { useSafeDispatch } from 'src/hooks';

/**
 * * idle - initial state
 * * started - signing process has started (user clicked the sign button)
 * * certificates-listed - we have a list of certificates, the certificate selection tooltip will open
 * * certificate-selected - user has selected a certificate
 * * confirmed - either user has confirmed via confirmation dialog or confirmation dialog is not required (continue to signing automatically)
 * * signed - documents have been signed by document signing toolkit (onPostSign is required in parent component to call signing APIs)
 * * completed - signing process has completed
 */
type SingingProviderStatus =
  | 'idle'
  | 'started'
  | 'certificates-listed'
  | 'certificate-selected'
  | 'confirmed'
  | 'signing-started'
  | 'signed'
  | 'post-sign-started'
  | 'completed'
  | 'failed'
  | 'post-sign-failed';

export interface SigningProviderState<T extends { signingInfo: ISignResponse['signingInfo'] }> {
  status: SingingProviderStatus;
  signedData?: T;
  certificateSelectedForSigning?: ICertIdentifier;
  shouldRememberCertSelection?: boolean;
  signError?: string;
  logGroupId: string; // WEB-27575
}

export type SigningProviderAction<T> =
  | { type: 'reset' } // * reset signing provider to initial state
  | { type: 'start'; logGroupId: string } // * start signing process
  | { type: 'list-certificates' } // * list available certificates
  | { type: 'select-certificate'; certificateSelectedForSigning: ICertIdentifier; shouldRememberCertSelection?: boolean } // * a certificate is selected
  | { type: 'confirm' } // * confirm to continue signing
  | { type: 'signing-start' } // * start signing documents with document signing toolkit
  | { type: 'sign'; signedData?: T } // * documents signed
  | { type: 'post-sign-start' } // * calling post sign after documents signed
  | { type: 'complete' } // * signing process completed
  | { type: 'failure'; error?: string }
  | { type: 'post-sign-failure'; error?: string };

function signingProviderReducer<T extends { signingInfo: ISignResponse['signingInfo'] }>(
  state: SigningProviderState<T>,
  action: SigningProviderAction<T>
): SigningProviderState<T> {
  switch (action.type) {
    case 'reset':
      return {
        ...state,
        certificateSelectedForSigning: undefined, // explicitly unset the selection
        shouldRememberCertSelection: false,
        status: 'idle',
        logGroupId: '' // reset to non specific group
      };
    case 'start':
      return {
        ...state,
        status: 'started',
        logGroupId: action.logGroupId // preserve newly generated group
      };
    case 'list-certificates':
      return {
        ...state,
        status: 'certificates-listed'
      };
    case 'select-certificate':
      return {
        ...state,
        certificateSelectedForSigning: action.certificateSelectedForSigning,
        shouldRememberCertSelection: action.shouldRememberCertSelection,
        status: 'certificate-selected'
      };
    case 'confirm':
      return {
        ...state,
        status: 'confirmed'
      };
    case 'signing-start':
      return {
        ...state,
        status: 'signing-started'
      };
    case 'sign':
      return {
        ...state,
        status: 'signed',
        // * When post sign failed, we can avoid signing the documents by not passing signedData
        // * Re-use the previous signed data
        signedData: action.signedData ?? state.signedData
      };
    case 'post-sign-start':
      return {
        ...state,
        status: 'post-sign-started'
      };
    case 'complete':
      return {
        status: 'completed',
        logGroupId: state.logGroupId // just making sure we don't lost this info
      };
    case 'failure':
      return {
        ...state,
        shouldRememberCertSelection: false,
        certificateSelectedForSigning: undefined, // explicitly unset the selection since signing failed
        status: 'failed',
        signError: action.error
      };
    case 'post-sign-failure':
      return {
        ...state,
        status: 'post-sign-failed',
        certificateSelectedForSigning: undefined, // explicitly unset the selection since signing failed
        signError: action.error
      };
  }
}

export function useSigningProviderReducer<T extends { signingInfo: ISignResponse['signingInfo'] }>(initialState?: SigningProviderState<T>) {
  const [state, unsafeDispatch] = React.useReducer(signingProviderReducer, {
    status: 'idle',
    logGroupId: '', // set to non specific group
    ...initialState
  });
  const dispatch = useSafeDispatch(unsafeDispatch);

  return {
    ...state,
    isSigning: (['started', 'certificates-listed', 'certificate-selected', 'confirmed', 'signing-started', 'signed', 'post-sign-started'] as SingingProviderStatus[]).includes(
      state.status
    ),
    dispatch
  };
}
