import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { getMortgagorPartyBookIds, getSelectedTitleReferences } from '../../helper';
import { Mortgage2_21_1Model, TitleReferenceModel } from '../../models';

interface PartyBookValidationContext {
  titleReferences: TitleReferenceModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: Mortgage2_21_1Model): PartyBookValidationContext => {
  return {
    titleReferences: parentContext.titleReferences
  };
};

const checkShouldValidateItem = ({ titleReferences }: PartyBookValidationContext, partyIdForCheck: PartyModel<NswNameChange>['id']) => {
  const selectedTitleReferences = getSelectedTitleReferences(titleReferences);
  const selectedMortgagorsIds = getMortgagorPartyBookIds(selectedTitleReferences);

  const isSelectedMortgagor = !!~selectedMortgagorsIds.indexOf(partyIdForCheck);
  return isSelectedMortgagor;
};

const yupPartyItem = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(PARTY_FORM_WITH_NAME_CHANGE_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
