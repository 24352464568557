import * as React from 'react';

import { FinancialLineItemLabelEnum } from './views/edit-directions/models';

type DirectionsContextType = { focusLabel: FinancialLineItemLabelEnum | null; setFocusLabel: (focusLabel: FinancialLineItemLabelEnum | null) => void };

const DirectionsContext = React.createContext<DirectionsContextType>({
  focusLabel: null,
  setFocusLabel: () => {}
});

export default DirectionsContext;
