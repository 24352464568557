import { LookupEnumModel } from '@sympli/ui-framework/models';

export interface BillingInvoicingFormikModel extends BillingInvoicingApiResponse {}
export interface BillingInvoicingApiResponse {
  billingMethodType: BillingMethodTypeEnum;
  invoiceOptionType: InvoiceOptionTypeEnum;
  invoicePeriodType: InvoicePeriodTypeEnum;
  pendingInvoicePeriodType?: InvoicePeriodTypeEnum;
  pendingInvoiceOptionType?: InvoiceOptionTypeEnum;
  isBillingMethodChangeableByUser: boolean;
}
export interface BillingInvoicingModel {
  billingMethodType: BillingMethodTypeEnum;
  invoiceOptionType: InvoiceOptionTypeEnum;
  isBillingMethodChangeableByUser: boolean;
}

export enum BillingMethodTypeEnum {
  SettlementDistribution = 0,
  DirectDebit = 1
}

export enum InvoiceOptionTypeEnum {
  PerWorkspace = 0,
  PerPeriod = 1
}

export enum InvoicePeriodTypeEnum {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  FifteenMin = 3
}

export const DEFAULT_BILLING_OPTIONS: LookupEnumModel[] = [
  { name: 'Direct Debit', id: BillingMethodTypeEnum.DirectDebit },
  { name: 'Paid at Settlement', id: BillingMethodTypeEnum.SettlementDistribution }
];

export const InvoicePeriodTypeMapping: Record<InvoicePeriodTypeEnum, string> = {
  [InvoicePeriodTypeEnum.Daily]: 'Daily',
  [InvoicePeriodTypeEnum.Weekly]: 'Weekly',
  [InvoicePeriodTypeEnum.Monthly]: 'Monthly',
  [InvoicePeriodTypeEnum.FifteenMin]: 'Every 15 minutes'
};

export const DEFAULT_INVOICE_OPTIONS: LookupEnumModel[] = [
  { name: 'One invoice per workspace', id: InvoiceOptionTypeEnum.PerWorkspace },
  { name: 'One invoice per day for all workspaces', id: InvoiceOptionTypeEnum.PerPeriod }
];

export const DEFAULT_BILLING_INVOICING: BillingInvoicingFormikModel = {
  billingMethodType: BillingMethodTypeEnum.DirectDebit,
  invoiceOptionType: InvoiceOptionTypeEnum.PerPeriod,
  invoicePeriodType: InvoicePeriodTypeEnum.Daily,
  isBillingMethodChangeableByUser: false
};

export enum InvoiceOptionsStatusEnum {
  Active = 0,
  Pending = 1
}

export const INVOICE_STATUS_OPTIONS: LookupEnumModel<InvoiceOptionsStatusEnum>[] = [
  { name: 'Active', id: InvoiceOptionsStatusEnum.Active },
  { name: 'Pending changes', id: InvoiceOptionsStatusEnum.Pending }
];
