// this file was automatically generated from sections/styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    marginBottomIfNotLast: {
      '&:not(:last-child)': {
        marginBottom: 10
      }
    },
    transferorFirst: {
      borderTop: 'none'
    },
    transferorsSection: {
      borderLeft: 'none',
      borderRight: 'none',
      background: 'none'
    },
    noTransferorsMessage: {
      padding: theme.spacing(2.5),
      fontSize: 13,
      fontStyle: 'italic'
    }
  }),
  {
    name: 'TransferorDetails'
  }
);
