import { setHours, setMinutes } from 'date-fns';
import { Action, createReducer } from 'typesafe-actions';

import { actionFetchCalendarItems, actionSetCalendarMonth } from '../actions';
import { DateValueMapModel } from '../models';

export interface SettlementEditFormState {
  calendarYearAndMonth: string; // * computed value
  calendarItems: DateValueMapModel; // * Control from service return value
  error?: string;
}

const initialState: SettlementEditFormState = {
  calendarYearAndMonth: setMinutes(setHours(new Date(), 14), 0).toString(),
  calendarItems: {},
  error: undefined
};

const reducer = createReducer<
  //
  SettlementEditFormState,
  Action
>(initialState)
  .handleAction(actionFetchCalendarItems.request, (state, action): SettlementEditFormState => {
    return {
      ...state,
      error: undefined,
      ...action.payload
    };
  })
  .handleAction(actionFetchCalendarItems.success, (state, action): SettlementEditFormState => {
    return {
      ...state,
      ...action.payload,
      error: undefined
    };
  })
  .handleAction(actionFetchCalendarItems.failure, (state, action): SettlementEditFormState => {
    return {
      ...state,
      error: action.payload.error.message
    };
  })
  .handleAction(actionSetCalendarMonth, (state, action): SettlementEditFormState => {
    return {
      ...state,
      calendarYearAndMonth: action.payload
    };
  });

export default reducer;
