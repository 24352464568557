import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    uploaderRootGrid: {
      display: 'grid',
      gridAutoFlow: 'row'
    },
    afterDropZoneOrder: {
      order: 2
    },
    attachmentErrorMessage: {
      margin: '4px 0'
    },
    formGroup: {
      padding: '0px 0px 0px 0px',
      borderBottom: 'none',
      '& + &': {
        borderTop: `1px solid ${theme.palette.divider}`
      },
      background: 'none'
    },
    formGroupTitle: {
      fontSize: 13,
      lineHeight: '15px',
      fontWeight: 'bold',
      color: theme.palette.text.primary
    },
    formGroupDescription: {
      margin: '-14px 0px 0px 0px'
    },
    formGroupTitleContainer: {
      paddingRight: 0
    }
  }),
  {
    name: 'WorkspaceFileField'
  }
);
