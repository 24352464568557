import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { sharedStyles } from 'src/components/list-item/sharedStyles';

const styles = (theme: Theme) =>
  createStyles({
    participantRole: {
      paddingTop: 5
    },
    errorMessage: {
      margin: '16px auto'
    },
    title: {
      paddingBottom: 5
    },
    linkStyleOverride: {
      textDecoration: 'none',
      display: 'flex'
    },
    name: {
      fontSize: 13,
      paddingLeft: 5,
      paddingRight: 3,
      // ellipsis as safe guard cause you never know when the layout would break
      // * e.g. Discharge mortgagee + Ready to approve
      flexGrow: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    iconSuccess: {
      fill: theme.palette.primary.main,
      height: 13,
      width: 13,
      margin: 2
    },
    headTextPrimary: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43 // 20px
    },
    buttonLinkRoot: {
      paddingLeft: 0
    },
    textPrimary: {
      ...sharedStyles.textPrimary,
      fontWeight: 450,
      textTransform: 'uppercase',
      fontSize: 11
    },
    itemRoot: {
      ...sharedStyles.itemRoot,
      marginTop: -10,
      marginBottom: -8
    },
    iconRoot: {
      minWidth: 20
    },
    itemSelected: {},
    focusVisible: {}
  });

export type ClassKeys = typeof styles;

export default styles;
