import * as React from 'react';

import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconZeroInbox } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import MessengerActionBox from 'src/containers/messenger/components/messenger-action-box';
import MessengerHeaderBox from 'src/containers/messenger/components/messenger-header-box';
import { getConversationSubject, getFormattedMessageDate101 } from 'src/containers/messenger/helpers';
import { ConversationDocument, MessageModel } from 'src/containers/messenger/models';
import NewMessage from '../new-message';
import { MessageListViewEnum } from './models';
import styles, { ClassKeys } from './styles101';

interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  // profile
  timezone: UserProfileModel['timezone'];
  // workspace participants
  participants: WorkspaceParticipantApiResponse[];
  // conversations
  items: ConversationDocument[];
  onConversationRead(conversationId: string): void;
  onConversationSelect(selectedConversationId?: string): void;
  newMessageDisabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  view: MessageListViewEnum | null;
}

class MessageList101 extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    view: MessageListViewEnum.MessageList
  };

  private currentDate = new Date();

  render() {
    const { classes, workspaceId, participantId, onConversationSelect, newMessageDisabled } = this.props;
    const { view } = this.state;
    switch (view) {
      case MessageListViewEnum.NewMessage:
        return <NewMessage workspaceId={workspaceId} participantId={participantId} onConversationSelect={onConversationSelect} onBackClick={this.handleOnBackToListClick} />;
      case MessageListViewEnum.MessageList:
      default:
        return (
          <React.Fragment>
            <MessengerHeaderBox>
              <Typography variant="h2" className={classes.title}>
                {'Messages'}
              </Typography>
            </MessengerHeaderBox>
            {this.renderMessageList()}
            <MessengerActionBox>
              <SympliButton
                className="rounded-[18px] bg-[var(--sympli-green)] px-[16px] py-[8px] disabled:text-[var(--greek-waters-dark)] disabled:opacity-[0.4]"
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleOnNewMessageClick}
                disabled={newMessageDisabled}
              >
                New message
              </SympliButton>
            </MessengerActionBox>
          </React.Fragment>
        );
    }
  }

  private renderMessageList() {
    const { classes, items = [] } = this.props;
    if (items.length === 0) {
      return (
        <FlexLayout fullHeight flexDirection="column" alignItems="center" justifyContent="center" className={classes.zeroInboxLayout}>
          <IconZeroInbox className={classes.zeroInboxIcon} />
          <Typography variant="subtitle1" className={classes.noMessage}>
            No messages yet
          </Typography>
          <Typography className={classes.zeroInboxDesc}>This is where your messages will appear</Typography>
        </FlexLayout>
      );
    }
    return (
      <div className={classes.messageList}>
        {items.map((conversion: ConversationDocument) => {
          const { conversationId: id, messages, isRead } = conversion;
          const latest = messages[messages.length - 1];
          const subject = getConversationSubject(conversion);
          return (
            <ListItem className={classes.messageItem} button key={id} data-conversation-id={id} onClick={this.handleOnConversationItemClick}>
              {!isRead && <div className={classes.highlightDot} />}
              <FlexLayout className={classes.messageItemContent} flexDirection="column" justifyContent="center" alignItems="flex-start">
                <Typography className={classNames(classes.messageItemHeader, !isRead && classes.highlightHeader)}>{subject}</Typography>
                <FlexLayout className={classes.messageItemLatestMessage} alignItems="center" justifyContent="space-between">
                  <Typography className={classNames(classes.messageItemDesc, !isRead && classes.highlightDesc)}>{latest && latest.text}</Typography>
                  {this.renderConversationDate(latest, isRead)}
                </FlexLayout>
              </FlexLayout>
            </ListItem>
          );
        })}
      </div>
    );
  }

  private renderConversationDate(message: MessageModel, isRead: boolean) {
    if (message == null) {
      return null;
    }
    const { classes, timezone } = this.props;
    const { createdDate } = message;
    return <Typography className={classNames(classes.messageItemDate)}>{getFormattedMessageDate101(new Date(createdDate), this.currentDate, timezone)}</Typography>;
  }

  private handleOnConversationItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const conversationId = dataAttribute('conversation-id', event) || '';
    const { items, onConversationSelect, onConversationRead } = this.props;
    const conversation = items.find(item => item.conversationId === conversationId);
    if (conversation && !conversation.isRead) {
      onConversationRead(conversationId);
    }
    onConversationSelect(conversationId);
  };

  private handleOnNewMessageClick = () => {
    this.setState({ view: MessageListViewEnum.NewMessage });
  };

  private handleOnBackToListClick = () => {
    this.setState({ view: MessageListViewEnum.MessageList });
  };
}

const styledComponent = withStyles(styles)(MessageList101);
export default styledComponent;
