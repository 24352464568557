// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { NoticeOfSaleModel } from './models';
import yupConsideration from './sections/consideration/validationSchema';
import yupDetailsForServiceOfNotices from './sections/details-for-service-of-notices/validationSchema';
import yupProperty from './sections/property/validationSchema';

export default yup
  .object<NoticeOfSaleModel>()
  .shape({
    detailsForServiceOfNotices: yupDetailsForServiceOfNotices,
    consideration: yupConsideration,
    property: yupProperty
  })
  .defined()
  .log();
