import * as React from 'react';

import { ErrorBoundaryInjectedProps } from 'src/components/error-boundary';
import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';

interface Props extends ErrorBoundaryInjectedProps {}

function DetailContainerErrorFallback(props: React.PropsWithChildren<Props>) {
  // special error handling where we want to keep original content and display only notification
  // const { error, children } = props;
  // if (error.name === 'FormikSubmitError') {
  //   return (
  //     <>
  //       <MessageNotification variant="error" primary={error.message} />
  //       {children}
  //     </>
  //   );
  // }

  // otherwise we display special error
  return <GeneralCrashedContent {...props} background="white" />;
}

export default DetailContainerErrorFallback;
