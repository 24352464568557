import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import IconTypography from 'src/components/workspace-status-rebuild/icon-typography';
import { SettlementDateDetailsState } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { filterAvailableParticipantsSelector, settlementAcceptedByParticipantsSelector } from 'src/containers/workspace/shared/detail/selectors';
import { SettlementDateTimeModel } from 'src/models';
import { DATE_DISPLAY_FORMAT, dateTimeLine } from 'src/utils/formatters';
import { getSettlementDateTime, getSettlementDateTimeStatus, isSameDateTime } from '../helper';
import SettlementDateBox, { Props as SettlementDateBoxProps } from './SettlementDateBox';

export interface BasicParticipantInfo {
  //
  id: WorkspaceParticipantApiResponse['id'];
  participantStatus: WorkspaceParticipantApiResponse['participantStatus'];
  workspaceRole: WorkspaceParticipantApiResponse['workspaceRole'];
  archivedStatus?: WorkspaceParticipantApiResponse['archivedStatus'];
  lastInvitationStatusUpdatedDate?: WorkspaceParticipantApiResponse['lastInvitationStatusUpdatedDate'];
}

interface Props<P extends BasicParticipantInfo> {
  // workspace basic info
  settlementDateTime?: SettlementDateTimeModel;
  workspaceStatusId?: WorkspaceStatusEnum;
  isRollover?: boolean;
  // settlement date details
  settlementDetailsState: SettlementDateDetailsState;
  // other
  proposedSettlementDate?: string;
  workspaceParticipants: P[];
  participantId: string;
}

function ProposalDateBoxContainer<P extends BasicParticipantInfo>({
  // workspace basic info
  settlementDateTime,
  workspaceStatusId,
  isRollover,
  // other
  workspaceParticipants,
  proposedSettlementDate,
  settlementDetailsState,
  participantId
}: Props<P>) {
  const { localProposedSettlementDate: existingSettlementDate, proposedOn, participants } = settlementDetailsState.detail;
  const availableParticipants: P[] = filterAvailableParticipantsSelector(workspaceParticipants);
  const settlementAcceptedByParticipants: string[] | undefined = settlementAcceptedByParticipantsSelector({
    settlementDateDetails: settlementDetailsState
  });
  const [originalProposedDate] = React.useState(proposedSettlementDate);
  const [proposedDateChanged, setProposedDateChanged] = React.useState(false);

  React.useEffect(() => {
    if (proposedSettlementDate) {
      if (originalProposedDate) {
        const dateChanged = !isSameDateTime(originalProposedDate, proposedSettlementDate);
        setProposedDateChanged(dateChanged);
      } else {
        setProposedDateChanged(true); // new proposal (no originaProposedDate)
      }
    }
  }, [proposedSettlementDate, originalProposedDate]);

  if (!settlementDateTime) {
    // TODO implement scenario when workspace detail does not contain settlementDateTime
    return null;
  }

  /*****************************************************
   * calculate data for the main settlement detail info
   */
  // we will also display proposed settlement detail info when there is no settlement problem and:
  // a. settlement date is not accepted by current participant
  // b. settlement date is accepted by all participants and current participant is proposing a new date
  // c. settlement date is accepted by current participant but not accepted by all participants, current participant is proposing a new date
  const settlementDateAcceptedByAll: boolean = settlementAcceptedByParticipants == null || availableParticipants.length === settlementAcceptedByParticipants.length;
  const settlementDateAcceptedByCurrentParticipant: boolean = Boolean(settlementAcceptedByParticipants && settlementAcceptedByParticipants?.some(p => p === participantId));
  const isProposing: boolean = Boolean(settlementDateAcceptedByAll && proposedSettlementDate);
  const isReProposing: boolean = Boolean(!settlementDateAcceptedByAll && proposedSettlementDate && settlementDateAcceptedByCurrentParticipant && proposedDateChanged);
  const showProposedSettlementDate: boolean = Boolean(!settlementDateAcceptedByCurrentParticipant || isProposing || isReProposing);

  // for workspace detail page, title is always hardcoded
  const settlementTitle = 'Settlement';

  if (showProposedSettlementDate) {
    // if the participant joined the ws before the latest settlement datetime proposal was made, we want to show the previous settlement date the workspace had
    // before the proposal; otherwise we want to hide the previous settlement date, unless a new proposal is being made
    const lastInvitationStatusUpdatedDate = workspaceParticipants.find(p => p.id === participantId)?.lastInvitationStatusUpdatedDate;
    const joinedBeforeLatestProposal =
      lastInvitationStatusUpdatedDate && proposedOn ? new Date(lastInvitationStatusUpdatedDate).valueOf() <= new Date(proposedOn).valueOf() : false;
    const description = proposedSettlementDate ? dateTimeLine(proposedSettlementDate, DATE_DISPLAY_FORMAT) : dateTimeLine(existingSettlementDate!, DATE_DISPLAY_FORMAT);
    const showOriginalSettlementDate: boolean = Boolean(
      (joinedBeforeLatestProposal || proposedDateChanged) && proposedSettlementDate && existingSettlementDate && !isSameDateTime(existingSettlementDate!, proposedSettlementDate)
    );
    return (
      <>
        <SettlementDateBox //
          title={settlementTitle}
          ariaLabel={settlementTitle}
          description={
            <Box flexDirection="column">
              <Typography variant="body2">{description}</Typography>
              <IconTypography text="New Proposal" />
            </Box>
          }
          selected
        />
        {showOriginalSettlementDate && ( //only show original ws settlement date if it is different from proposed date
          <SettlementDateBox
            title={settlementTitle}
            ariaLabel={settlementTitle}
            description={<Typography variant="body2">{dateTimeLine(existingSettlementDate!, DATE_DISPLAY_FORMAT)}</Typography>}
            selected
          />
        )}
      </>
    );
  }

  // const description: WorkspaceDateBoxProps['description'] = dateTimeLine(settlementDateTime.workspaceLocalTime, DATE_DISPLAY_FORMAT);
  // let variant: WorkspaceDateBoxProps['variant'] = 'default';

  const descriptionText: SettlementDateBoxProps['description'] = getSettlementDateTime(settlementDateTime, isRollover, workspaceStatusId);

  const status: JSX.Element | null = getSettlementDateTimeStatus(
    workspaceParticipants.filter(p => !p.archivedStatus).map(p => p.participantStatus),
    participants,
    settlementDateTime,
    workspaceStatusId
  );

  return (
    <SettlementDateBox //
      ariaLabel={settlementTitle}
      title={settlementTitle}
      description={
        <Box flexDirection="column">
          {descriptionText}
          {status}
        </Box>
      }
      selected
    />
  );
}

export default React.memo(ProposalDateBoxContainer);
