import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 28,
      paddingBottom: 40,
      background: theme.palette.grey[50],
      flexGrow: 1
    },
    messageContainer: {
      paddingTop: 4
    },
    header: {
      paddingTop: 9,
      paddingRight: 4
    },
    message: {
      fontSize: 16,
      lineHeight: '19px',
      maxWidth: 505,
      paddingTop: 4
    },
    description: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: '17px',
      maxWidth: 360,
      textAlign: 'center',
      paddingTop: 22
    },
    wideWidth: {
      maxWidth: 660
    },
    actionButton: {
      marginTop: 16,
      '& + $actionButton': {
        marginLeft: 20
      }
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    }
  });

export type ClassKeys = typeof styles;

export default styles;
