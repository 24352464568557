import React, { memo } from 'react';

import { useField } from 'formik';

import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { getCharacterCountHelperText } from '../../documents/helpers';

interface Props {
  name: string;
  className?: string;
  fieldOnly?: boolean;
  label?: string;
  disabled?: boolean;
}

function InvitationDescription({ name, className, fieldOnly = false, label = '', disabled = false }: Props) {
  const [field] = useField(name);
  const descriptionLength = (field.value ?? '').trim().length;
  const descriptionLengthText = getCharacterCountHelperText(descriptionLength, 250);

  return fieldOnly ? (
    <Field //
      aria-label="Invitation Description"
      name={name}
      className={className}
      label={label}
      component={InputField}
      fullWidth
      multiline
      minRows={4}
      disabled={disabled}
      helperText={descriptionLengthText}
    />
  ) : (
    <FormGroup //
      title="Invitation description (optional)"
      description="This will be shown on the workspace invite"
    >
      <Field //
        aria-label="Invitation Description"
        name={name}
        className={className}
        label={label}
        component={InputField}
        fullWidth
        multiline
        minRows={4}
        disabled={disabled}
        helperText={descriptionLengthText}
      />
    </FormGroup>
  );
}

export default memo(InvitationDescription);
