// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    titleLabel: {
      marginBottom: 0
    },
    titleReference: {
      marginBottom: 'auto',
      marginTop: 'auto',
      width: 270
    },
    partLandCheckbox: {
      marginBottom: 0
    }
  }),
  {
    name: 'TitleReferences'
  }
);
