import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    bottomLine: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 10,
      marginBottom: 20
    },
    userName: {
      paddingRight: 13
    },
    description: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      paddingLeft: 20
    }
  });

export type ClassKeys = typeof styles;

export default styles;
