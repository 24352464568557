import * as React from 'react';

import classNames from 'classnames';
import { MenuItemProps } from '@mui/material/MenuItem';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import MoreActionButton from '@sympli/ui-framework/components/more-action-button';

import { useStyles } from './styles';

interface Props {
  mode?: 'light' | 'dark';
  stepper?: React.ReactNode;
  issues?: React.ReactNode;
  menuItems?: React.ReactElement<MenuItemProps>[];
  disableMenu?: boolean;
  className?: string;
}

function WorkflowPanel(
  {
    //
    mode = 'dark',
    stepper,
    issues,
    children,
    disableMenu,
    menuItems,
    className
  }: React.PropsWithChildren<Props>,
  ref: React.Ref<HTMLDivElement>
) {
  // * In case menuItems is undefined, we still want to show MoreActionButton but disabled
  const disabled = disableMenu || !menuItems || menuItems?.length === 0;
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, classes[mode], className)} ref={ref}>
      {(stepper || issues) && (
        <FlexLayout justifyContent="flex-start" alignItems="center" fullWidth={true}>
          {stepper}
          {issues ? <div className={classes.issuesButton}>{issues}</div> : null}
        </FlexLayout>
      )}
      <FlexLayout justifyContent="flex-end" alignItems="center" fullWidth={true}>
        {children}
      </FlexLayout>
      <MoreActionButton disabled={disabled} mode={mode} autoClose={true}>
        {menuItems?.map((menu, index) => React.cloneElement(menu, { key: index }))}
      </MoreActionButton>
    </div>
  );
}

export default React.memo(React.forwardRef(WorkflowPanel)) as (props: React.PropsWithChildren<Props> & React.RefAttributes<HTMLDivElement>) => React.ReactElement;
