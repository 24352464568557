import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchUserDetails, actionResetUserDetails, actionUpdateUserDetails } from '../actions/users';
import { UserDetailsApiResponse } from '../users-detail/detail/models';

export interface UserDetailsState {
  detail?: Partial<UserDetailsApiResponse>;
  error?: string;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  args?: {
    userId: string;
  };
}

const initialState: UserDetailsState = {
  detail: undefined,
  status: 'idle',
  error: undefined,
  args: undefined,
  isLoading: true,
  isRefetching: false
};

export function useUserDetails(userId: string) {
  const state = useStoreSelector(store => store.userDetails);
  if (state.args?.userId === userId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  UserDetailsState,
  Action
>(initialState)
  .handleAction(actionFetchUserDetails.request, (state, action): UserDetailsState => {
    const status = _isEqual(state.args?.userId, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: {
        userId: action.payload
      }
    };
  })
  .handleAction(actionFetchUserDetails.success, (state, action): UserDetailsState => {
    return {
      ...state,
      detail: action.payload.data,
      isLoading: false,
      isRefetching: false,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchUserDetails.failure, (state, action): UserDetailsState => {
    return {
      ...state,
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message,
      status: 'rejected'
    };
  })
  .handleAction(actionUpdateUserDetails.request, (state, action): UserDetailsState => {
    const { detail } = state;

    if (detail == null) {
      return state;
    }

    const { id } = action.payload;

    if (id !== detail.id) {
      return state;
    }

    return {
      ...state,
      detail: {
        ...detail,
        ...action.payload
      }
    };
  })
  .handleAction(actionResetUserDetails, (): UserDetailsState => {
    return {
      ...initialState
    };
  });

export default reducer;
