import { Action, createReducer } from 'typesafe-actions';

import { actionFetchTrustAccountDetail, actionUpdateStep, actionUpdateTrustAccountDetails, actionUpdateTrustAccountRequestProgress } from '../actions/trustAccountDetails';
import {
  TrustAccountAddSignersSteps,
  TrustAccountDetails,
  TrustAccountNewRequestStepsEnum,
  TrustAccountRequestStatusEnum,
  TrustAccountRequestTypeEnum,
  TrustAccountSetupStepEnum
} from '../trust-accounts/detail/models';

export enum TrustAccountDetailsStateStatusEnum {
  Idle = 'Idle',
  Pending = 'Pending',
  Resolved = 'Resolved',
  Rejected = 'Rejected'
}
export interface TrustAccountDetailsState {
  progress: TrustAccountSetupStepEnum;
  step: TrustAccountSetupStepEnum;
  status: TrustAccountDetailsStateStatusEnum;
  error?: string;
  details?: TrustAccountDetails;
}

const initialState: TrustAccountDetailsState = {
  progress: TrustAccountSetupStepEnum.AccountDetails,
  step: TrustAccountSetupStepEnum.AccountDetails,
  status: TrustAccountDetailsStateStatusEnum.Idle
};

const reducer = createReducer<
  //
  TrustAccountDetailsState,
  Action
>(initialState)
  .handleAction(actionUpdateTrustAccountRequestProgress, (state, { payload: { status } }): TrustAccountDetailsState => {
    const steps = state.details?.type === TrustAccountRequestTypeEnum.AddSignatories ? TrustAccountAddSignersSteps : TrustAccountNewRequestStepsEnum;
    const currentStepIndex = steps.indexOf(state.step);
    let nextStep = state.step;
    if (currentStepIndex >= 0 && currentStepIndex < steps.length - 1) {
      nextStep = steps[currentStepIndex + 1];
    }
    const newState: TrustAccountDetailsState = { ...state, step: nextStep };
    if (state.progress >= nextStep) {
      if (state.progress > TrustAccountSetupStepEnum.Review && status === TrustAccountRequestStatusEnum.NotSubmitted) {
        return {
          ...newState,
          progress: TrustAccountSetupStepEnum.Review
        };
      }

      return newState;
    }

    return {
      ...newState,
      progress: nextStep
    };
  })
  .handleAction(actionUpdateStep, (state, action): TrustAccountDetailsState => {
    return {
      ...state,
      step: action.payload
    };
  })
  .handleAction(actionUpdateTrustAccountDetails, (state, action): TrustAccountDetailsState => {
    return {
      ...state,
      details: action.payload
    };
  })
  .handleAction(actionFetchTrustAccountDetail.request, (state): TrustAccountDetailsState => {
    return {
      ...state,
      status: TrustAccountDetailsStateStatusEnum.Pending,
      error: undefined,
      details: undefined
    };
  })
  .handleAction(actionFetchTrustAccountDetail.success, (state, action): TrustAccountDetailsState => {
    return {
      ...state,
      status: TrustAccountDetailsStateStatusEnum.Resolved,
      error: undefined,
      ...action.payload.data
    };
  })
  .handleAction(actionFetchTrustAccountDetail.failure, (state, action): TrustAccountDetailsState => {
    return {
      ...state,
      status: TrustAccountDetailsStateStatusEnum.Rejected,
      error: action.payload.error.message
    };
  });

export default reducer;
