import * as React from 'react';

import { FormikProps, useField } from 'formik';
import _get from 'lodash-es/get';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';

import PartyCapacity from '../../../../components/party-capacity/PartyCapacity';
import { ADDRESS_BOOK_KEY, BaseCaveatModel } from '../../models';
import { PartyForm } from './PartyForm';
import { VISIBILITY_CHECK_PARTY_CAPACITY } from './visibilityChecks';

type FormValues = BaseCaveatModel;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

const PartyReference = ({ disabled, formikProps, name, dialogPortalId, partyFormConfig, addressFormConfig, addressFormInitialValues, syncPartyReferences }: Props) => {
  const { values, setFieldValue } = formikProps;
  const fieldName = createModelKeyAppender<TenancyPartyModel>(name);
  const item = _get(values, name);
  const [, { value }] = useField<TenancyPartyModel>(name);

  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === item.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;
  return (
    <>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        sharedConfig={partyFormConfig}
      />
      {VISIBILITY_CHECK_PARTY_CAPACITY(item.partyCapacity) && <PartyCapacity name={fieldName('partyCapacity')} partyCapacity={value.partyCapacity} isReadonly />}
      <AddressSelectField
        name={fieldName('addressBookId')}
        disabled={disabled}
        bookRef={ADDRESS_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        sharedAddressFormConfig={addressFormConfig}
        sharedInitialValuesForNew={addressFormInitialValues}
        onSelect={async (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
          setFieldValue(fieldName('addressBookId'), resolvedValue);
          syncPartyReferences({ ...value, addressBookId: resolvedValue }, TenancyPartyFields.AddressBookId);
        }}
        handleCustomSubmit={async (isNew: boolean, addressBookId: string) => {
          if (isNew) {
            syncPartyReferences({ ...value, addressBookId }, TenancyPartyFields.AddressBookId);
          }
        }}
      />
    </>
  );
};

export default PartyReference;
