import * as React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { getDefaultLodgementCaseId } from 'src/containers/shared/helper';
import LodgementCaseStatus from 'src/containers/shared/lodgement-case-status';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  workspaceId: string;
  participantId: string;
}

function DocumentListTitle({
  //
  workspaceId,
  participantId
}: Props) {
  const workspaceDetail = useWorkspaceDetail(workspaceId, participantId);

  return (
    <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography component="div" variant="subtitle1">
        <span>Documents</span>
        <LodgementCaseStatus //
          data-testid="document-list-title"
          lodgementCaseId={getDefaultLodgementCaseId(workspaceDetail.detail?.lodgementCases)}
        />
      </Typography>
    </FlexLayout>
  );
}

export default React.memo(DocumentListTitle);
