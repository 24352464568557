import * as yup from 'yup';

import { ConsiderationModel } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { yupConsiderationAmount, yupConsiderationType } from '@sympli-mfe/document-forms-framework/components/sections/consideration/validation-schema';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

export default yup
  .object<ConsiderationModel>({
    type: yupConsiderationType(JurisdictionsEnum.NSW),
    amount: yupConsiderationAmount
  })
  .defined();
