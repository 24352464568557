import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      padding: 32,
      overflowY: 'auto',
      boxSizing: 'border-box'
    },
    emailSupport: {
      fontWeight: 'bold'
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 32
    }
  }),
  { name: 'HelpPage' }
);
