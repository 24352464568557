import http from 'src/utils/http';

export interface MfaLinkedDeviceModel {
  id: string;
  name: string;
  status: string;
  type: string;
  created: string;
  updated: string;
}

export const getLinkedDevices = () => http.get<MfaLinkedDeviceModel[]>('/profile/linked-devices'); // TODO check endpoint with xinxin

export const removeLinkedDevice = (deviceId: string) => http.put(`/profile/device/${deviceId}/unlink`, {});
