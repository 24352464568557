import * as yup from 'yup';

import { $exactlyOneOfItems } from '@sympli-mfe/document-forms-framework/utils';

import { InterestOnTitle, SubLease2_21_1Model } from '../../models';

const yupInterestsOnTitleItemSchema = yup
  .object<InterestOnTitle>({
    isSelected: yup.boolean(),
    dealingNumber: yup.string(),
    lessors: yup.mixed()
  })
  .defined();

const yupInterestsOnTitle = yup
  .array<InterestOnTitle>(yupInterestsOnTitleItemSchema)
  .testContextualRule({
    //
    uniqueTestName: 'At least one interest must be selected',
    message: 'At least 1 interest must be selected',
    requirement: ({ interestsOnTitle }: SubLease2_21_1Model) => {
      return $exactlyOneOfItems(interestsOnTitle, item => item.isSelected);
    }
  })
  .defined();

export default yupInterestsOnTitle;
