import { endOfToday } from 'date-fns';
import * as yup from 'yup';

import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import yupPartyReferences from '../../components/party-reference/validationSchema';
import { ClaimCategoryEnum, ClaimStatementEnum, EstateOrInterestClaimedEnum } from '../../enums';
import { ClaimDetailsModel } from '../../models';
import { VISIBILITY_CHECK_CLAIM_DATE, VISIBILITY_CHECK_CLAIM_PARTY_DETAILS, VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM } from './visibilityChecks';

const END_OF_TODAY = endOfToday();

const yupClaimDetails = yup
  .object<ClaimDetailsModel>({
    claimCategory: yup.mixed().oneOf(Object.values(ClaimCategoryEnum), msg.REQUIRED).required(msg.REQUIRED),
    estateOrInterestClaimed: yup.mixed().required(msg.REQUIRED).oneOf(Object.values(EstateOrInterestClaimedEnum), msg.REQUIRED),
    claimStatement: yup.mixed().required(msg.REQUIRED).oneOf(Object.values(ClaimStatementEnum), msg.REQUIRED),

    claimDate: yup //
      .mixed<Date | undefined | null>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_CLAIM_DATE,
          validationWhenVisible: yupDatePicker.required(msg.REQUIRED).max(END_OF_TODAY, msg.DATE_MUST_BE_TODAY_OR_PAST_DATE)
        })
      ),
    detailsSupportingTheClaim: yup //
      .mixed<string | undefined>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM,
          validationWhenVisible: yup.string().nullable(false).trim().max(20, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(20)).required(msg.REQUIRED)
        })
      ),
    claimParties: yup //
      .array<TenancyPartyModel>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_CLAIM_PARTY_DETAILS,
          validationWhenVisible: yupPartyReferences({ partyReferenceLabel: 'claim party', maxSelection: 20, maxLength: undefined, isRequired: false }).defined(),
          isObjectOrArray: true
        })
      )
  })
  .defined();

export default yupClaimDetails;
