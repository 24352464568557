import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionUpdateSuspendConfirmDialog, UPDATE_USER_SUSPENSION } from '../actions/users';

export interface UserSuspensionState {
  isConfirmDialogOpen: boolean;
  status: ApiStatus;
  error?: string;
}

const initialState: UserSuspensionState = {
  isConfirmDialogOpen: false,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  UserSuspensionState,
  Action
>(initialState)
  .handleAction(UPDATE_USER_SUSPENSION.ACTION, (state): UserSuspensionState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(UPDATE_USER_SUSPENSION.SUCCESS, (state): UserSuspensionState => {
    return {
      ...state,
      status: 'resolved'
    };
  })
  .handleAction(UPDATE_USER_SUSPENSION.ERROR, (state, action): UserSuspensionState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateSuspendConfirmDialog as any, (state, action): UserSuspensionState => {
    return {
      ...state,
      status: 'resolved',
      isConfirmDialogOpen: action.payload.isOpen
    };
  });

export default reducer;
