// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'PreSettlement', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>
        title && <title>{title}</title>
      </title>
      <defs>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-1"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-3"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-5"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-7"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-9"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-11"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-13"></path>
        <path
          d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65 C130,29.102 100.898,0 65,0 Z"
          id="LodgementPreSettlement-path-15"
        ></path>
        <path
          d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65 C130,29.102 100.898,0 65,0 Z"
          id="LodgementPreSettlement-path-17"
        ></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-19"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-21"></path>
        <path d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65" id="LodgementPreSettlement-path-23"></path>
        <path
          d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65 C130,29.102 100.898,0 65,0 Z"
          id="LodgementPreSettlement-path-25"
        ></path>
        <path
          d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65 C130,29.102 100.898,0 65,0 Z"
          id="LodgementPreSettlement-path-27"
        ></path>
        <path
          d="M65,0 C29.102,0 0,29.102 0,65 L0,65 C0,100.898 29.102,130 65,130 L65,130 C100.898,130 130,100.898 130,65 L130,65 C130,29.102 100.898,0 65,0 Z"
          id="LodgementPreSettlement-path-29"
        ></path>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M130,65 C130,100.899 100.899,130 65,130 C29.101,130 0,100.899 0,65 C0,29.101 29.101,0 65,0 C100.899,0 130,29.101 130,65" fill="#E4E5E6"></path>
        <polygon fill="#D6D6D6" points="31 115 103 115 103 19 31 19"></polygon>
        <polygon fill="#FFFFFF" points="29 113 101 113 101 17 29 17"></polygon>
        <polygon fill="#9A9A9A" points="57 31 73 31 73 27 57 27"></polygon>
        <polygon fill="#F2F2F2" points="37 43 93 43 93 41 37 41"></polygon>
        <polygon fill="#9A9A9A" points="37 29 51 29 51 27 37 27"></polygon>
        <polygon fill="#FF811A" points="37 51 65 51 65 47 37 47"></polygon>
        <polygon fill="#FF811A" points="37 57 93 57 93 55 37 55"></polygon>
        <polygon fill="#9A9A9A" points="37 103 53 103 53 101 37 101"></polygon>
        <polygon fill="#9A9A9A" points="59 37 71 37 71 33 59 33"></polygon>
        <polygon fill="#9A9A9A" points="37 33 51 33 51 31 37 31"></polygon>
        <polygon fill="#9A9A9A" points="37 37 51 37 51 35 37 35"></polygon>
        <polygon fill="#F2F2F2" points="79 37 93 37 93 27 79 27"></polygon>
        <polygon fill="#F2F2F2" points="37 69 93 69 93 67 37 67"></polygon>
        <polygon fill="#9A9A9A" points="37 83 93 83 93 81 37 81"></polygon>
        <polygon fill="#9A9A9A" points="37 89 79 89 79 87 37 87"></polygon>
        <polygon fill="#FF811A" points="37 63 73 63 73 61 37 61"></polygon>
        <polygon fill="#9A9A9A" points="37 77 65 77 65 73 37 73"></polygon>
        <g>
          <g>
            <mask id="LodgementPreSettlement-mask-2" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-1"></use>
            </mask>
            <g></g>
            <path
              d="M116.9393,113.345 C117.2943,113.243 118.1283,112.688 118.9973,112.042 C120.1123,111.213 121.2873,110.234 121.5873,109.869 C123.3773,107.697 113.2373,100.731 111.9043,101.971 C111.7093,102.153 111.5033,102.4 111.2973,102.687 C110.6043,103.649 109.9043,105.056 109.6023,105.896 C109.3373,106.635 108.4313,107.854 109.3833,109.313 C110.2303,110.612 115.3523,113.798 116.9393,113.345"
              fill="#D1A882"
              mask="url(#LodgementPreSettlement-mask-2)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-4" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-3"></use>
            </mask>
            <g></g>
            <path
              d="M108.3697,107.2673 C109.9487,108.4553 111.5117,109.2283 112.8687,108.8413 C112.9367,108.8223 113.0237,108.7853 113.1247,108.7343 C113.3517,108.6203 117.1367,105.8013 117.4907,105.3963 C117.5007,105.3853 117.5097,105.3743 117.5177,105.3653 C119.1387,103.3963 108.3117,95.0183 106.7687,96.5183 C106.7587,96.5293 103.6437,100.9343 103.6987,101.2363 C103.7837,101.6993 107.1307,106.3343 108.3697,107.2673"
              fill="#E2B588"
              mask="url(#LodgementPreSettlement-mask-4)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-6" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-5"></use>
            </mask>
            <g></g>
            <path
              d="M143.5944,107.1902 C142.7204,105.7892 135.4034,94.1382 131.6934,89.7452 C129.2654,86.8722 125.2024,81.6252 123.2734,79.9772 C120.8774,77.9312 119.6784,78.7562 114.6124,77.2682 C111.3024,76.2962 107.3064,74.2812 103.8574,74.183905 C102.3154,74.1412 95.8114,78.8112 94.4544,80.3322 C92.1014,82.9712 90.9004,87.0002 91.2864,90.8532 C91.3744,91.7382 95.6764,91.4132 96.9194,89.3182 C97.3394,88.6102 97.2004,88.8922 97.4964,88.3812 C97.5874,88.2242 97.6784,88.0602 97.7724,87.8802 C97.8774,87.6762 97.9864,87.4512 98.1044,87.1902 C98.6024,86.0822 110.7434,94.4652 114.2824,105.3392 C114.9454,107.3762 115.3814,108.1572 120.8054,112.8792 C123.5334,115.2542 127.4144,116.3422 129.5484,117.7712 C129.9534,118.0412 131.1354,119.4132 131.6994,120.1052 C136.1894,116.3152 140.1834,111.9712 143.5944,107.1902"
              fill="#F2C28D"
              mask="url(#LodgementPreSettlement-mask-6)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-8" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-7"></use>
            </mask>
            <g></g>
            <path
              d="M101.6078,102.8018 C102.5418,102.9638 105.4938,102.1708 109.6718,101.9868 C110.1088,101.9668 110.3398,101.7738 110.5218,101.6448 C110.5278,101.6398 110.5338,101.6358 110.5408,101.6308 C110.5848,101.5978 110.6258,101.5638 110.6648,101.5298 C111.0698,101.1698 111.1778,100.6188 111.1078,100.0678 C111.0678,99.7518 110.9688,99.4358 110.8338,99.1548 C110.2678,97.9778 98.9288,90.3488 98.9008,90.5018 C97.8838,95.9098 98.4068,99.9618 98.8638,100.9358 C99.3518,101.9778 100.4738,102.6068 101.6078,102.8018"
              fill="#F2C28D"
              mask="url(#LodgementPreSettlement-mask-8)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-10" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-9"></use>
            </mask>
            <g></g>
            <path
              d="M90.799,34.201 C101.734,45.136 101.734,62.864 90.799,73.799 C79.864,84.734 62.136,84.734 51.201,73.799 C40.266,62.864 40.266,45.136 51.201,34.201 C62.136,23.266 79.864,23.266 90.799,34.201"
              fill="#18CDB6"
              mask="url(#LodgementPreSettlement-mask-10)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-12" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-11"></use>
            </mask>
            <g></g>
            <path
              d="M87.9706,37.0294 C97.3436,46.4024 97.3436,61.5984 87.9706,70.9704 C78.5976,80.3434 63.4016,80.3434 54.0296,70.9704 C44.6566,61.5984 44.6566,46.4024 54.0296,37.0294 C63.4016,27.6564 78.5976,27.6564 87.9706,37.0294"
              fill="#E1F2FF"
              mask="url(#LodgementPreSettlement-mask-12)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-14" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-13"></use>
            </mask>
            <g></g>
            <path
              d="M110.4637,91.2461 C110.4637,91.2461 115.2797,92.0651 118.0437,90.2731 C118.0437,90.2731 116.9177,88.6931 117.0857,85.3461 C116.0987,84.9371 113.6427,84.2151 112.3657,81.5421 C108.3927,81.2291 105.6657,77.9851 105.5997,77.9541 C103.3227,81.4821 101.7267,80.2771 101.8187,81.7341 C101.8667,82.4951 110.4637,91.2461 110.4637,91.2461"
              fill="#F2C28D"
              mask="url(#LodgementPreSettlement-mask-14)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-16" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-15"></use>
            </mask>
            <g></g>
            <polygon fill="#18CDB6" mask="url(#LodgementPreSettlement-mask-16)" points="99.2843 76.6274 93.6273 82.2844 87.2633 75.9204 92.9203 70.2634"></polygon>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-18" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-17"></use>
            </mask>
            <g></g>
            <path
              d="M127.2757,100.3762 L117.3767,110.2752 L90.5057,83.4052 C89.7247,82.6242 89.7247,81.3582 90.5057,80.5772 L97.5777,73.5062 C98.3587,72.7252 99.6247,72.7252 100.4057,73.5062 L127.2757,100.3762 Z"
              fill="#FF811A"
              mask="url(#LodgementPreSettlement-mask-18)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-20" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-19"></use>
            </mask>
            <g></g>
            <path
              d="M108.1184,97.3816 C109.6774,99.5166 111.8784,105.8566 115.4964,109.4746 C119.1154,113.0936 122.3374,115.5406 124.6954,116.6986 C128.9674,118.7976 129.4874,118.2646 131.6994,120.1056 C131.6994,120.1056 140.5164,112.8216 143.5944,107.1906 C141.8994,104.6486 129.8144,88.4716 127.2474,87.7626 C124.1554,86.9096 121.1384,89.3396 117.9454,89.6536 C110.9894,90.3396 109.7194,86.1076 106.1164,83.0056 C104.3134,81.4526 100.8574,78.7786 98.4174,78.5226 C96.4574,78.3166 94.5774,80.2616 94.4714,82.1416 C94.3784,83.7966 95.5094,85.4426 97.7714,87.8806 C101.3254,91.7106 105.3514,93.5936 108.1184,97.3816"
              fill="#F2C28D"
              mask="url(#LodgementPreSettlement-mask-20)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-22" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-21"></use>
            </mask>
            <g></g>
            <path
              d="M50.2416,66 L79.3156,66 L79.3156,70 L53.1526,70 C53.4436,70.324 53.7176,70.659 54.0296,70.971 C63.4016,80.343 78.5976,80.343 87.9706,70.971 C91.6346,67.306 93.8456,62.749 94.6446,58 L47.3556,58 C47.8236,60.783 48.7896,63.49 50.2416,66"
              fill="#E1F2FF"
              mask="url(#LodgementPreSettlement-mask-22)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-24" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-23"></use>
            </mask>
            <g></g>
            <path
              d="M87.9706,37.0294 C78.5976,27.6564 63.4016,27.6564 54.0296,37.0294 C53.7176,37.3414 53.4436,37.6754 53.1526,38.0004 L71.3156,38.0004 L71.3156,46.0004 L48.3966,46.0004 C47.4836,48.5854 46.9996,51.2894 46.9996,54.0004 L94.9996,54.0004 C94.9996,47.8574 92.6566,41.7154 87.9706,37.0294"
              fill="#E1F2FF"
              mask="url(#LodgementPreSettlement-mask-24)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-26" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-25"></use>
            </mask>
            <g></g>
            <path d="M71.3154,38 L53.1524,38 C50.9994,40.398 49.4144,43.117 48.3964,46 L71.3154,46 L71.3154,38 Z" fill="#FF811A" mask="url(#LodgementPreSettlement-mask-26)"></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-28" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-27"></use>
            </mask>
            <g></g>
            <path
              d="M47,54 C47,55.339 47.132,56.676 47.355,58 L94.645,58 C94.868,56.676 95,55.339 95,54 L47,54 Z"
              fill="#FF811A"
              mask="url(#LodgementPreSettlement-mask-28)"
            ></path>
          </g>
          <g>
            <mask id="LodgementPreSettlement-mask-30" fill="white">
              <use xlinkHref="#LodgementPreSettlement-path-29"></use>
            </mask>
            <g></g>
            <path d="M79.3154,66 L50.2414,66 C51.0564,67.408 52.0274,68.747 53.1524,70 L79.3154,70 L79.3154,66 Z" fill="#FF811A" mask="url(#LodgementPreSettlement-mask-30)"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
