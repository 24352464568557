import { createSelector } from 'reselect';

import { Store } from 'src/reducers';
import { ConversationDocument } from '../../models';

const getConversations = <T extends Pick<Store, 'messengerConversations'>>(state: T) => state.messengerConversations.items;

export const newMessageCountSelector = createSelector<
  //
  Pick<Store, 'messengerConversations'>,
  ConversationDocument[],
  number
>(getConversations, (conversations = []) => conversations.filter(item => !item.isRead).length);
