import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { isMortgageeNameMismatch } from '../../helpers';
import { DischargeOfMortgage_2_26_0_Model, MortgageePartyModel } from '../../models';

// path: mortgagees
const yupMortgagees = yup
  .array<MortgageePartyModel, DischargeOfMortgage_2_26_0_Model>()
  .of<MortgageePartyModel>(
    yup
      .object<MortgageePartyModel>({
        addressBookId: yup.string().default('').trim().required(msg.REQUIRED),

        partyBookId: yup.mixed<string>(),
        partyCapacity: yup.mixed<MortgageePartyModel['partyCapacity']>()
      })
      .defined()
  )
  .testContextualRule({
    message: 'The mortgagees are not common across the selected Mortgages. Please review the selected mortgages or provide name justification.',
    uniqueTestName: 'mortgagees[*] name mismatch',
    requirement: (parent: DischargeOfMortgage_2_26_0_Model) => !isMortgageeNameMismatch(parent)
  })
  .defined();

export default yupMortgagees;
