import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    icon: {
      minWidth: 16,
      paddingLeft: 4,
      paddingRight: 4
    },
    iconFillRed: {
      fill: colors.RED
    },
    iconMapMaker: {
      width: 20,
      height: 20,
      marginLeft: -2,
      marginRight: -2
    },
    iconFillOrange: {
      fill: colors.ORANGE
    },
    iconCloseWarning: {
      width: 20,
      height: 20,
      fill: colors.ORANGE,
      stroke: colors.ORANGE
    },
    iconFillBlack: {
      fill: colors.BLACK
    }
  }),
  {
    name: 'ActivityLogItemIcon'
  }
);
