import { resolveMemoizedTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import { DischargeOfMortgage2_21_1Model, TitleReferenceSelectionModel } from '../../models';
import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from '../../visibilityChecks';

// path: titleReferences
const yupTitleReferences = resolveMemoizedTitleReferencesValidationSchema<TitleReferenceSelectionModel>();

export default validateWhenVisible2<TitleReferenceSelectionModel[]>({
  visibilityCheck: (parent: DischargeOfMortgage2_21_1Model) => {
    // section must be visible and there must be at least one common mortgage
    return VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES(parent) && Boolean(parent.titleReferences.length);
  },
  validationWhenVisible: yupTitleReferences
});
