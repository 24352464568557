import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    actionIcon: {
      fontSize: 24,
      fill: theme.palette.action.disabled
    },
    searchBoxIconSearch: {
      //margin: '0px 28px',
      width: 15,
      height: 15,
      fill: '#A9ACAF',
      cursor: 'pointer'
    },
    searchBoxIconClose: {
      marginRight: 28,
      width: 32,
      height: 32,
      cursor: 'pointer'
    },
    searchInput: {
      width: '100%',
      flexShrink: 0,
      marginBottom: 0,
      fontFamily: 'volkswagen_serial-medium',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal'
    },
    inputStyle: {
      '& .MuiInputAdornment-positionEnd': {
        marginRight: 1
      },
      borderRadius: 4,
      fontWeight: 500

      // background: 'var(--secondary-sunny-day-translucent, #FFF9E1)'
      // border: 'none',
      // '& .MuiInput-input:-webkit-autofill:focus': {
      //   // This is a hacky way to stop browser update bg color
      //   transition: 'background-color 0s 600000s'
      // },
      // '& .MuiInput-input:-webkit-autofill': {
      //   // This is a hacky way to stop browser update bg color
      //   transition: 'background-color 0s 600000s'
      // },
      // '&.Mui-focused': {
      //   boxShadow: 'none'
      // }
    },
    inputBox: {
      height: 22,
      padding: '8px 10px'
    }
  }),
  {
    name: 'LinkedWorkspaceSearchBox'
  }
);
