import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    section: {
      marginTop: 36
    },
    badge: {
      minWidth: 22,
      minHeight: 22,
      fontSize: 11,
      right: -24,
      top: -8,

      // styling fix .MuiBadge-badge
      width: 22,
      height: 22,
      transform: 'none',
      borderRadius: '50%'
    },
    lockIcon: {
      height: 35,
      width: 35
    },
    headerLoaderContainer: {
      height: 42
    },
    headerLoader: {
      width: 256,
      height: 32
    },
    rightPanel: {
      width: 272,
      marginLeft: 60
    },
    box: {
      marginTop: 40,
      paddingTop: 0,
      paddingBottom: 0,
      border: 'none',
      '& + &': {
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none'
      }
    },

    tab: {
      paddingTop: 8,
      paddingRight: 24,
      paddingBottom: 6,
      paddingLeft: 18
    },
    taskTabWrapper: {
      flex: 1
    }
  });

export type ClassKeys = typeof styles;

export default styles;
