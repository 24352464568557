import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { VerifyTitleFormModel } from 'src/containers/shared/verify-property-section/models';
import { TitleReferenceEntityModel } from 'src/models';

export const getInitialValues = (jurisdiction: JurisdictionsEnum, existingTitles: TitleReferenceEntityModel[]): VerifyTitleFormModel => ({
  existingTitles: existingTitles.map(x => x.reference),
  jurisdictionId: jurisdiction,
  titleInputValue: '',
  titleChips: [],
  // results from additional svc calls that we need for validation to be handled purely within yup
  titleVerificationResult: undefined
});
