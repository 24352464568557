import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { Box } from 'src/components/layout';
import { useStyles } from './styles';

interface Props {
  title: React.ReactNode;
  className?: string;
  collapsedByDefault?: boolean;
  children: React.ReactNode;
  /**
   * this allows us to hook additional callback to be fired when user clicked on collapse/expand icon
   */
  onExpandChange?(expand: boolean): void;
}

function WorkspaceExpansionBox({ className, title, collapsedByDefault, onExpandChange, children }: Props) {
  const [isOpen, setIsOpen] = React.useState(!collapsedByDefault);
  const classes = useStyles();

  React.useEffect(() => {
    setIsOpen(!collapsedByDefault);
  }, [collapsedByDefault]);

  return (
    <Box className={className}>
      <Accordion
        classes={{ root: classes.root, expanded: classes.expanded }}
        expanded={isOpen}
        onChange={e => {
          e.preventDefault();
          onExpandChange?.(!isOpen);
          setIsOpen(!isOpen);
        }}
      >
        <AccordionSummary
          className={classes.panelSummary}
          classes={{ content: classes.panelSummaryContent, expandIconWrapper: classes.expandIcon }}
          expandIcon={<div className={classes.iconNew}>{isOpen ? '-' : '+'}</div>}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetail}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default WorkspaceExpansionBox;
