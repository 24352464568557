import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { CaveatorPartyModel } from '../../models';
import { VISIBILITY_CHECK_CAVEATORS } from '../../visibilityChecks';

const yupCaveators = yup
  .array<CaveatorPartyModel>()
  .defined()
  .of(
    yup
      .object<CaveatorPartyModel>()
      .defined()
      .shape({
        addressBookId: yup.string().default('').trim().required(msg.REQUIRED)
      })
  )
  .log();

export default yup //
  .mixed<CaveatorPartyModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_CAVEATORS,
      validationWhenVisible: yupCaveators,
      isObjectOrArray: true
    })
  );
