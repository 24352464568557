import * as React from 'react';

import classNames from 'classnames';
import List from '@mui/material/List';
import visuallyHidden from '@mui/utils/visuallyHidden';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import ListItemLink from 'src/components/list-item-link';
import DocumentItemBase from '../document-list/components/document-item-base';
import { useStyles } from './styles';

export interface Props {
  title: string;
  description: string | JSX.Element;
  ariaLabel?: string;
  linkTo?: string;
  selected?: boolean;
}

function SettlementDateBox({ title, description, linkTo, selected, ariaLabel = 'Settlement details' }: Props) {
  const classes = useStyles();

  return (
    <FlexLayout justifyContent="flex-start" flexDirection="column" className={classNames(classes.root, selected && classes.selected)} data-testid="settlement-data-time-box">
      <span style={visuallyHidden}>{ariaLabel}</span>
      <List component="nav" disablePadding>
        <ListItemLink linkTo={linkTo} className={classes.listItem} selected={selected}>
          <DocumentItemBase
            underline={!!linkTo}
            title={title}
            classes={{
              title: classes.title
            }}
            description={description}
          />
        </ListItemLink>
      </List>
    </FlexLayout>
  );
}

export default React.memo(SettlementDateBox);
