import { LookupEnumModel } from '@sympli/ui-framework/models';

import { GrammarRule } from './modules/models';

export interface JsonSchemaPropertyBaseModel {
  // order: number;
  // title: string;
  // description: string;
  // readonly: boolean;
  type: string;
  title?: string;
  $ref?: string;
  enum?: Array<{ id: number; name: string }>;
  _meta?: SchemaMetaProps; // this is our custom
}

export interface ConditionalRenderingValueDefinitionModel {
  // value is the current value of entered/selected by user
  schema: string;
  type: 'object' | 'array';
  field: string;
}

export interface ConditionalFieldRulesModel {
  field: string;
  value?: any;
  oneOf?: Array<any>;
  required?: boolean;
}

export interface ConditionalRenderingRulesModel {
  [value: string]: ConditionalRenderingValueDefinitionModel;
}

export type ConditionalRequiredGrammarRule = boolean | GrammarRule;
export interface ConditionalValidationEntityModel {
  // means that this field has special validation, one of them needs to be valid
  required: ConditionalRequiredGrammarRule; // if object is present it's a grammar structure, if boolean, then it's expected to be true
  onlyIf?: object; // condition
  message?: string | null; // error message to be shown
}

export type ConditionalVisibilityEntityModel = GrammarRule;

export interface SchemaMetaProps {
  disabled: boolean;
  component: string;
  componentProps: Record<string, any>;
  componentValidation?: string;
  metadata: boolean;
  uuid: number;
  dataTitle: string;
  dataEnum: string;
  dataRef: string;
  dataRefFilter: GrammarRule;
  conditional: ConditionalModel;
  validation: Array<ConditionalValidationEntityModel>;
  visibility: Array<ConditionalVisibilityEntityModel>;
  invisibleValue: any;
  canAdd: boolean;
  enumBase?: string;
  textTransform: string;
  email: boolean;
}

export interface JsonSchemaStringPropertyModel extends JsonSchemaPropertyBaseModel {
  // type: 'string';
  minLength?: number;
}

export interface JsonSchemaBooleanPropertyModel extends JsonSchemaPropertyBaseModel {
  // type: 'boolean';
}

export interface JsonSchemaNumberPropertyModel extends JsonSchemaPropertyBaseModel {
  // type: 'number';
  minimum?: number;
  maximum?: number;
}

export interface ArrayItemsProp {
  type: string;
  $ref: string;
}

export interface JsonSchemaArrayPropertyModel extends JsonSchemaPropertyBaseModel {
  // type: 'array';
  items: ArrayItemsProp;
  uniqueItems?: boolean;
  maxItems?: number;
  minItems?: number;
}

export type JsonSchemaPropertyType = JsonSchemaStringPropertyModel | JsonSchemaNumberPropertyModel | JsonSchemaArrayPropertyModel | JsonSchemaBooleanPropertyModel;

export interface JsonSchemaDefinitionModel {
  type: string;
  required?: Array<string>;
  additionalProperties?: boolean;
  description?: string;
  properties?: {
    [propertyName: string]: GenericJsonSchemaModel;
  };
  $ref?: string;
  _meta?: SchemaMetaProps; // this is our custom
  enum?: number[];
  'x-enumNames'?: string[];
  'x-abstract'?: boolean;
}

export interface ConditionalModel {
  rules: Array<object>;
  required?: boolean;
}

export interface SchemaEnumModel extends LookupEnumModel<any> {
  _meta?: { conditional: ConditionalModel } | null;
}

export interface GenericJsonSchemaModel {
  type?: string;
  $ref?: string;
  fieldMapping?: string;

  title?: string;
  description?: string;

  // type: 'array';
  items?: GenericJsonSchemaModel;

  // type: object
  required?: Array<string>;
  properties?: JsonSchemaPropertiesModel;

  // type: 'string';
  minLength?: number;
  maxLength?: number;
  enum?: Array<SchemaEnumModel>;

  // type: 'number';
  minimum?: number;
  maximum?: number;
  format?: string;

  // type: 'boolean'
  // -- nothing extra just type

  // type: 'array';
  maxItems?: number;
  minItems?: number;

  _meta?: Partial<SchemaMetaProps>; // this is our custom definition
}
export interface ResolvedJsonSchemaModel extends GenericJsonSchemaModel {
  type: string;
}

export interface ArrayTypeJsonSchemaModel extends ResolvedJsonSchemaModel {
  items: ResolvedJsonSchemaModel;
}

export interface JsonSchemaPropertiesModel {
  [propertyName: string]: GenericJsonSchemaModel;
}

export interface JsonSchemaDefinitionsModel {
  [definitionName: string]: JsonSchemaDefinitionModel;
}

export interface JsonSchemaRootModel extends GenericJsonSchemaModel {
  $schema: string;
  definitions?: JsonSchemaDefinitionsModel;
  type: string;
  state: string;
  version: string;
  additionalProperties?: boolean;
  identifier?: string;
  displayName?: string | null;
}

export enum SchemaTitleEnum {
  Mortgage = 'Mortgage',
  DischargeOfMortgage = 'DischargeOfMortgage',
  Caveat = 'Caveat',
  WithdrawalOfCaveat = 'WithdrawalOfCaveat',
  ExtensionOfPriorityNotice = 'ExtensionOfPriorityNotice',
  WithdrawalOfPriorityNotice = 'WithdrawalOfPriorityNotice',
  PriorityNotice = 'PriorityNotice'
}

export enum TextTransformTypeEnum {
  UPPERCASE = 'uppercase'
}
