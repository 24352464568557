import * as React from 'react';

import _get from 'lodash-es/get';
import { Action } from 'redux';
import warning from 'warning';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { UserRoleEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { UserReportsPermissionsApiResponse } from 'src/containers/dashboard/reports/models';
import { MfaLinkedDeviceModel } from 'src/containers/personal-profile/multi-factor-auth-devices/api';
import DetailNotFoundPage from 'src/containers/settings/components/detail-not-found-page';
import SettingContentTopPanel from 'src/containers/settings/components/setting-content-top-panel';
import { UserDetailsState } from 'src/containers/settings/settings/reducers/userDetails';
import { UserLinkedDevicesFeedState } from 'src/containers/settings/settings/reducers/userLinkedDevicesFeed';
import { UserSuspensionState } from 'src/containers/settings/settings/reducers/userSuspension';
import TabLinkedDevicesContainer from 'src/containers/settings/settings/users-detail/components/TabLinkedDevicesContainer';
import { RoleEntityModel, UserGroupAccessModel } from 'src/containers/settings/settings/users-detail/models';
import AuthenticationDialog from 'src/containers/shared/auth/multi-factor-auth/authentication-dialog';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import SettingsPageContentWrapper from '../../SettingsPageContentWrapper';
import { DefaultUserSigningRule } from '../../subscriber-profile/models';
import { UserReportsPermissionsState } from '../reducers/userReportsPermissions';
import UserMoreActionButtonContainer from '../users-setting/components/user-more-action-button';
import TabPermissions from './components/tab-permissions';
import { UserDetailsApiResponse } from './detail/models';
import PersonalDetailsContainer from './detail/personal-details/PersonalDetailsContainer';
import ReportsPermissionsContainer from './detail/reports-permissions/ReportsPermissionsContainer';
import { UserDetailsPageTabEnum, UserPermissionsApiResponse, UserPermissionsFormikValueModel } from './models';
import styles, { ClassKeys } from './styles';

interface UsersDetailProps {
  // route params
  userId: string;
  tab: UserDetailsPageTabEnum;
  // redux
  userDetails: UserDetailsState;
  userProfile: UserProfileModel;
  userSuspension: UserSuspensionState;
  userPermissions: any;
  userReportsPermissions: UserReportsPermissionsState;
  userRoleOptions?: RoleEntityModel[];
  groupAccessOptions?: UserGroupAccessModel[];
  isDesignationEditable?: boolean;
  userLinkedDevicesFeed: UserLinkedDevicesFeedState;
  // other
  isFormikLoading: boolean;
  biReportingFeatureFlag?: boolean;

  dispatch: SafeDispatch<Action>;

  loggedInUserRole: UserRoleEnum;
  defaultUserSigningRules?: DefaultUserSigningRule[];
  //
  showReportPermissions: boolean;
}

type Props = UsersDetailProps & WithStyles<ClassKeys>;

interface State {
  tabStore: Partial<{
    [UserDetailsPageTabEnum.PersonalDetails]: UserDetailsApiResponse;
    [UserDetailsPageTabEnum.Permissions]: UserPermissionsFormikValueModel;
    [UserDetailsPageTabEnum.ReportsPermissions]: UserReportsPermissionsApiResponse;
    [UserDetailsPageTabEnum.LinkedDevices]: Array<MfaLinkedDeviceModel>;
  }>;
}

class UsersDetail extends React.PureComponent<React.PropsWithChildren<Props>, State> {
  public readonly state: Readonly<State> = {
    tabStore: {}
  };

  private resolveStateBinding(tab: UserDetailsPageTabEnum) {
    switch (tab) {
      case UserDetailsPageTabEnum.PersonalDetails:
        return 'userDetails';
      case UserDetailsPageTabEnum.Permissions:
        return 'userPermissions';
      case UserDetailsPageTabEnum.ReportsPermissions:
        return 'userReportsPermissions';
      case UserDetailsPageTabEnum.LinkedDevices:
        return 'userLinkedDevicesFeed';
      default:
        warning(false, 'Unrecognized tab:', tab);
        return 'unknown';
    }
  }

  private updatePropsToTabStore(tab: UserDetailsPageTabEnum, prevProps: Readonly<Props>, dataPropName: string = 'detail') {
    const mapping = `${this.resolveStateBinding(tab)}.${dataPropName}`;
    const oldStateData = _get(prevProps, mapping);
    const newStateData = _get(this.props, mapping);

    if (newStateData !== oldStateData) {
      this.setState((state: State) => ({
        tabStore: {
          ...state.tabStore,
          [tab]: newStateData
        }
      }));
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    this.updatePropsToTabStore(UserDetailsPageTabEnum.PersonalDetails, prevProps);
    this.updatePropsToTabStore(UserDetailsPageTabEnum.Permissions, prevProps);
    this.updatePropsToTabStore(UserDetailsPageTabEnum.ReportsPermissions, prevProps);
    this.updatePropsToTabStore(UserDetailsPageTabEnum.LinkedDevices, prevProps, 'items');
  }

  get tab(): UserDetailsPageTabEnum {
    return this.props.tab;
  }

  get userId() {
    return this.props.userId;
  }

  get isEditMode() {
    return this.userId !== 'new';
  }

  render() {
    const { userDetails, defaultUserSigningRules, userRoleOptions, groupAccessOptions, isDesignationEditable, loggedInUserRole } = this.props;

    switch (this.tab) {
      case UserDetailsPageTabEnum.PersonalDetails:
        return (
          <>
            <AuthenticationDialog />
            <PersonalDetailsContainer loggedInUserIsSuperAdmin={loggedInUserRole === UserRoleEnum.SuperAdmin} />
          </>
        );
      case UserDetailsPageTabEnum.Permissions:
        return (
          <>
            <AuthenticationDialog />
            <TabPermissions
              {...this.resolveTabProps<UserPermissionsApiResponse>(this.tab)}
              userRoleOptions={userRoleOptions}
              groupAccessOptions={groupAccessOptions}
              isDesignationEditable={isDesignationEditable}
              isSuperAdmin={loggedInUserRole === UserRoleEnum.SuperAdmin}
              isCurrentUserActive={userDetails.detail?.isSuspended === false && userDetails.detail?.isActivated === true}
              defaultUserSigningRules={defaultUserSigningRules}
            />
          </>
        );
      case UserDetailsPageTabEnum.ReportsPermissions:
        if (userDetails.isLoading || userDetails.isRefetching) {
          return <BlockLoader />;
        }

        return this.props.showReportPermissions ? (
          <>
            <AuthenticationDialog />
            <ReportsPermissionsContainer //
              isPersonalDetailMode={this.isPersonalDetailMode}
            />
          </>
        ) : (
          <DetailNotFoundPage />
        );
      case UserDetailsPageTabEnum.LinkedDevices:
        return this.renderTabWrapper(<TabLinkedDevicesContainer {...this.resolveTabProps<UserPermissionsApiResponse>(this.tab)} />);
      default:
        return <DetailNotFoundPage />;
    }
  }

  private get isPersonalDetailMode(): boolean {
    return window.location.pathname.includes('/personal-profile/');
  }

  // eslint-disable-next-line
  private resolveTabProps<T>(tab: UserDetailsPageTabEnum) {
    const { isLoading, error } = _get(this.props, this.resolveStateBinding(tab), { isLoading: false, error: undefined });
    const { isEditMode, userId } = this;
    const { isFormikLoading, userProfile, dispatch, userReportsPermissions, biReportingFeatureFlag } = this.props;

    return {
      dispatch,
      error,
      isLoading,
      initialValues: this.state.tabStore[tab] as any,
      isEditMode,
      userId,
      currentUserId: userProfile.userId,
      features: userProfile.features,
      biReportingFeatureFlag,
      isFormikLoading,
      userReportsPermissionsStatus: userReportsPermissions.status,
      isPersonalDetailMode: this.isPersonalDetailMode
    };
  }

  private renderTabWrapper = (children: JSX.Element) => {
    return (
      <FlexLayout flexDirection="column" fullWidth>
        <SettingContentTopPanel>
          <UserMoreActionButtonContainer userId={this.userId} />
        </SettingContentTopPanel>
        <SettingsPageContentWrapper>
          <AuthenticationDialog />
          {children}
        </SettingsPageContentWrapper>
      </FlexLayout>
    );
  };
}

export default withStyles(styles)(UsersDetail);
