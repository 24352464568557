export enum NewMessageStepEnum {
  SelectParticipant = 1,
  ConversationDetail = 2
}

export interface ConversationDetailFormModel {
  subject: string;
  message: string;
}

export interface CreateConversationDocumentPayload extends ConversationDetailFormModel {
  recipientParticipantIds: Array<string>;
}
