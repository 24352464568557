import { LogTypeEnum } from '@sympli/api-gateway/enums';

import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { resolveAllDirectionsLink, resolveDirectionsLink } from 'src/containers/workspace/financial/directions/helpers';
import { resolveTitleDetailLink } from 'src/containers/workspace/titles/helper';
import { WorkspaceActivityLogLinkEnum } from './models';

// the model matches WorkspaceCreatedData of WorkspaceLogService
// The first letter upper case is because it is directly serialised as String by backend rather C# object
export interface WorkspaceParticipantMetaData {
  ParticipantId: string;
  ParticipantName?: string;
  WorkspaceRoleIds?: number[];
  UserId?: string;
  UserName?: string;
}

interface DirectionMetaData {
  ParticipantId: string;
}

interface TitleMetaData {
  ParticipantId: string;
  TitleReference: string;
}

interface DocumentMetaData {
  DocumentId: string;
  DocumentName: string;
  ParticipantId?: string;
}

// TODO: all the nav links should use helper function eg: resolveWorkspaceDetailLink, resolveTitleDetailLink and etc
export function resolveLogLink(workspaceId: string, participantId: string, linkType: WorkspaceActivityLogLinkEnum, metaData: object | undefined) {
  if (metaData && typeof metaData === 'object') {
    switch (Number(linkType)) {
      case WorkspaceActivityLogLinkEnum.AllDirectionsLink:
      case WorkspaceActivityLogLinkEnum.DirectionLink:
        // Toggle between Directions Overview and Directions Summary based on if current participant is the same recorded in logs.
        const workspaceDirectionLink = metaData as DirectionMetaData;
        if (participantId === workspaceDirectionLink.ParticipantId) {
          return resolveDirectionsLink({ workspaceId, participantId });
        } else {
          return resolveAllDirectionsLink({ workspaceId, participantId });
        }

      // const sameParticipantId = participantId === workspaceDirectionLink.ParticipantId;
      // const directionSummaryPage = sameParticipantId ? 'directions' : 'alldirections';
      // const id = sameParticipantId ? workspaceDirectionLink.ParticipantId : participantId;
      // return `/workspace/${encodeURIComponent(workspaceId)}/${directionSummaryPage}?participantId=${encodeURIComponent(id)}`;
      case WorkspaceActivityLogLinkEnum.TitleLink:
        const workspaceTitleLink = metaData as TitleMetaData;
        return resolveTitleDetailLink({
          workspaceId,
          participantId,
          titleReference: workspaceTitleLink.TitleReference
        });
      case WorkspaceActivityLogLinkEnum.DocumentLink:
        const workspaceDocumentLink = metaData as DocumentMetaData;
        return resolveDocumentDetailLink({ workspaceId, participantId, documentId: workspaceDocumentLink.DocumentId });
      default:
        return linkType;
    }
  } else {
    return linkType;
  }
}

export interface WorkspaceActivityLogParticipantInfo {
  participantId: string;
  userName: string;
}

// we only need to add case if we need to use custom markdown template
export function resolveWorkspaceActivityLogTemplate(type: LogTypeEnum) {
  switch (type) {
    case LogTypeEnum.DirectionsReviewed:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.DirectionLink}) was approved`;
    case LogTypeEnum.DirectionsSigned:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.DirectionLink}) was signed`;
    case LogTypeEnum.DirectionsPartiallySigned:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.DirectionLink}) was partially signed`;
    case LogTypeEnum.DirectionsUnsigned:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.DirectionLink}) was unsigned`;
    case LogTypeEnum.DirectionsUpdated:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.DirectionLink}) was saved`;
    case LogTypeEnum.DirectionsCreated:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' was created`;
    case LogTypeEnum.DirectionsUpdatedBySystem:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' was saved`;
    case LogTypeEnum.DirectionsUnsignedBySystem:
      return `[Financial Settlement Schedule](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' was unsigned`;
    case LogTypeEnum.DocumentUpdated:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was saved`;
    case LogTypeEnum.SLCDocumentUpdated:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) for :{LodgementCaseName} was saved`;
    case LogTypeEnum.DocumentCreated:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was added`;
    case LogTypeEnum.SLCDocumentCreated:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was added to :{LodgementCaseName}`;
    case LogTypeEnum.DocumentReviewed:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was approved`;
    case LogTypeEnum.SLCDocumentReviewed:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) for :{LodgementCaseName} was approved`;
    case LogTypeEnum.DocumentSigned:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was signed`;
    case LogTypeEnum.SLCDocumentSigned:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) for :{LodgementCaseName} was signed`;
    case LogTypeEnum.DocumentPartiallySigned:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was partially signed`;
    case LogTypeEnum.DocumentUnsigned:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) was unsigned`;
    case LogTypeEnum.SLCDocumentUnsigned:
      return `[:{DocumentName}](${WorkspaceActivityLogLinkEnum.DocumentLink}) for :{LodgementCaseName} was unsigned`;
    case LogTypeEnum.TitleLandRegistryInformationSupplied:
      return `Title information resupply for [:{TitleReference}](${WorkspaceActivityLogLinkEnum.TitleLink}) completed`;
    case LogTypeEnum.TitleActivityPositiveChange:
      return `Title activity check for [:{TitleReference}](${WorkspaceActivityLogLinkEnum.TitleLink}) completed. Positive change found.`;
    case LogTypeEnum.TitleActivityNoChange:
      return `Title activity check for [:{TitleReference}](${WorkspaceActivityLogLinkEnum.TitleLink}) completed. No change found.`;
    case LogTypeEnum.TitleActivityPositiveChangeWarningDismissed:
      return `Positive title activity warning and details for [:{TitleReference}](${WorkspaceActivityLogLinkEnum.TitleLink}) were dismissed`;
    case LogTypeEnum.TitleVerified:
      return `[:{TitleReference}](${WorkspaceActivityLogLinkEnum.TitleLink}) was verified`;
    case LogTypeEnum.WorkspaceTitleAdded:
      return `Title [:{TitleReference}](${WorkspaceActivityLogLinkEnum.TitleLink}) was added to the workspace`;
    case LogTypeEnum.SourceFundsSaved:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) saved`;
    case LogTypeEnum.SourceFundsSavedBySystem:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' saved`;
    case LogTypeEnum.SourceFundsApproved:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) approved`;
    case LogTypeEnum.SourceFundsPartiallySigned:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) partially signed`;
    case LogTypeEnum.SourceFundsSigned:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) signed`;
    case LogTypeEnum.SourceFundsUnsigned:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) unsigned`;
    case LogTypeEnum.SourceFundsUnsignedBySystem:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' unsigned`;
    case LogTypeEnum.PaymentsSaved:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) saved`;
    case LogTypeEnum.PaymentsSavedBySystem:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' saved`;
    case LogTypeEnum.PaymentsApproved:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) approved`;
    case LogTypeEnum.PaymentsPartiallySigned:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) partially signed`;
    case LogTypeEnum.PaymentsSigned:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) signed`;
    case LogTypeEnum.PaymentsUnsigned:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) unsigned`;
    case LogTypeEnum.PaymentsUnsignedBySystem:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.AllDirectionsLink}) for ':{ParticipantWorkspaceRole}' unsigned`;
    case LogTypeEnum.SourceFundsOptedOut:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) is N/A`;
    case LogTypeEnum.PaymentsOptedOut:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) is N/A`;
    case LogTypeEnum.PaymentLineItemSaved:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) line item saved`;
    case LogTypeEnum.SourceFundLineItemSaved:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) line item saved`;
    case LogTypeEnum.PaymentLineItemDeleted:
      return `[Financial Payments](${WorkspaceActivityLogLinkEnum.DirectionLink}) line item deleted`;
    case LogTypeEnum.SourceFundLineItemDeleted:
      return `[Financial Source Funds](${WorkspaceActivityLogLinkEnum.DirectionLink}) line item deleted`;
    case LogTypeEnum.LodgementFailedTechnicalError:
      return '**Lodgement paused**. Please retry.';
    case LogTypeEnum.LodgementFailedConnectivityError:
      return '**Lodgement paused**. Please retry.';
    case LogTypeEnum.SLCLodgementFailedConnectivityError:
      return '**Lodgement paused** for :{LodgementCaseName}. Please retry.';
    case LogTypeEnum.SLCLodgementFailedTechnicalError:
      return '**Lodgement paused** for :{LodgementCaseName}. Please retry.';
    default:
      return ':{Message}';
  }
}

export interface AuthorityComplianceReportDisplay {
  linkText: string;
  suffixText: string;
}

const LodgementVerificationFailedDisplay: AuthorityComplianceReportDisplay = { linkText: 'Lodgement verification failed.', suffixText: 'Please resolve your document issues.' };
const LodgementVerificationSuccessDisplay: AuthorityComplianceReportDisplay = { linkText: 'Lodgement verification completed.', suffixText: 'No issue found.' };
const getSLCLodgementVerificationSuccessDisplay = (lodgementCaseName: string): AuthorityComplianceReportDisplay => {
  return { linkText: `Lodgement verification completed for ${lodgementCaseName}.`, suffixText: 'No issue found.' };
};
const getSLCLodgementVerificationFailureDisplay = (lodgementCaseName: string): AuthorityComplianceReportDisplay => {
  return { linkText: `Lodgement verification failed for ${lodgementCaseName}.`, suffixText: 'Please resolve your document issues.' };
};
const LodgementFailedDisplay: AuthorityComplianceReportDisplay = { linkText: 'Lodgement failed.', suffixText: 'Please resolve issues.' };
const getSLCLodgementFailureDisplay = (lodgementCaseName: string): AuthorityComplianceReportDisplay => {
  return { linkText: `Lodgement failed for ${lodgementCaseName}.`, suffixText: 'Please resolve issues.' };
};

export const AuthorityComplianceReportLogToDisplayMap: Partial<Record<LogTypeEnum, AuthorityComplianceReportDisplay>> = {
  [LogTypeEnum.LodgementVerificationFailed]: LodgementVerificationFailedDisplay,
  [LogTypeEnum.LodgementVerificationSuccess]: LodgementVerificationSuccessDisplay,
  [LogTypeEnum.SettlementLodgementVerificationFailed]: LodgementVerificationFailedDisplay,
  [LogTypeEnum.SettlementLodgementVerificationSucceeded]: LodgementVerificationSuccessDisplay,
  [LogTypeEnum.LodgementFailed]: LodgementFailedDisplay,
  [LogTypeEnum.SettlementLodgementFailed]: LodgementFailedDisplay
};

export const getAuthorityComplianceDisplay = (workspaceLogType: LogTypeEnum, lodgementCaseName?: string) => {
  switch (workspaceLogType) {
    case LogTypeEnum.SLCLodgementVerificationSuccess:
      return getSLCLodgementVerificationSuccessDisplay(lodgementCaseName!);
    case LogTypeEnum.SLCLodgementVerificationFailed:
      return getSLCLodgementVerificationFailureDisplay(lodgementCaseName!);
    case LogTypeEnum.SLCLodgementFailed:
      return getSLCLodgementFailureDisplay(lodgementCaseName!);
    default:
      return AuthorityComplianceReportLogToDisplayMap[workspaceLogType];
  }
};

const MATCH_PATTERN = /\:\{\w+\}/g; // regex to match template substitution :{value}

export function getTemplate(category: LogTypeEnum, data: object) {
  const template = resolveWorkspaceActivityLogTemplate(category);
  return template.replace(MATCH_PATTERN, substring => {
    const key = substring.slice(2, substring.length - 1);
    return data[key];
  });
}
