import * as React from 'react';

import classNames from 'classnames';
import { FormikHelpers } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';

import { WorkspaceTransactionTypeEnum } from '@sympli/api-gateway/enums';
import { DateTimeInfo, TransactionType } from '@sympli/api-gateway/shared';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Tab from 'src/containers/dashboard/components/tabs/Tab';
import Tabs from 'src/containers/dashboard/components/tabs/Tabs';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';
import { getTransactionTypeString } from 'src/pages/workspace/shared/left-panel/helpers';
import { dateTimeLine, titleAddress } from 'src/utils/formatters';
import { GroupOptionModel } from '../../../shared/models';
import { InvitationsTableRowModel, InvitationTitle } from '../../table/models';
import AcceptInviteForm, { AcceptInviteFormValues, AcceptInviteSubmitType } from './AcceptInviteForm';
import Accordion from './Accordion';
import DeclineInviteForm, { DeclineInviteFormValues } from './DeclineInviteForm';
import ForwardInvitationForm from './ForwardInvitationForm';
import ForwardInviteForm, { ForwardInviteToGroupFormValues } from './ForwardInviteToGroupForm';
import { ForwardInviteToSubscriberFormValues } from './ForwardInviteToSubscriberForm';

export function SubHeader(props: React.PropsWithChildren<{ className?: string }>) {
  return (
    <h3
      className={classNames(
        'm-0 p-0 pb-[4px] font-volkswagen-serial-regular text-[10px] font-[500] uppercase leading-normal tracking-[1.5px] text-[var(--greek-waters-dark)]',
        props.className
      )}
    >
      {props.children}
    </h3>
  );
}

export function SubContent(props: React.PropsWithChildren<{ className?: string; style?: React.CSSProperties }>) {
  return (
    <div //
      className={classNames('text-[14px] leading-[20px] text-[var(--neutral-1000)]', props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

export function Proprietors(props: {
  //
  className?: string;
  items: InvitationTitle['proprietors'];
}) {
  return (
    <SubContent //
      className="font-[400] tracking-[0.15px]"
    >
      {props.items.map(p => p.legalEntityName).join(', ')}
    </SubContent>
  );
}

export function Title(props: {
  //
  reference: string;
}) {
  return (
    <SubContent //
      className="font-[700] tracking-[0.25px] text-[var(--neutral-1000)]"
    >
      {props.reference}
    </SubContent>
  );
}

export function Message(props: { className?: string; body?: string }) {
  return <SubContent className="font-[400] leading-[18px]">{props.body}</SubContent>;
}

export function Address(props: {
  //
  address: string;
  jurisdictionId: number;
  // sympliId: string;
}) {
  return (
    <>
      <SubContent //
        className="font-[400] leading-[20px] tracking-[0.15px]"
        style={{ fontFeatureSettings: '"clig" off, "liga" off' }}
      >
        {titleAddress(props.address, props.jurisdictionId)}
      </SubContent>
      {/* <SubContent className="text-[12px] font-[400] leading-[16px] text-[var(--neutral-600)]">{props.sympliId}</SubContent> */}
    </>
  );
}

export function RenderTransactionType(props: { transactionType: TransactionType }) {
  return (
    <>
      <SubHeader className="pt-[24px]">Transaction Type</SubHeader>
      <SubContent className="pb-[16px] font-[400] leading-[20px] tracking-[0.15px]" style={{ fontFeatureSettings: '"clig" off, "liga" off', wordWrap: 'break-word' }}>
        <span>{getTransactionTypeString(props.transactionType.type)}</span>
        {props.transactionType.type === WorkspaceTransactionTypeEnum.Other ? <span className="text-[var(--neutral-600)]"> ({props.transactionType.otherDescription})</span> : null}
      </SubContent>
    </>
  );
}

export function Body(props: {
  //
  invitationTitles: InvitationTitle[];
  sympliId: string;
  title: string;
  jurisdictionId: number;
}) {
  const title = props.invitationTitles //
    .find(item => item.reference === props.title);

  const proprietors = title?.proprietors ?? [];
  const address = title?.address?.addressLine1 ?? '';

  return (
    <>
      <SubHeader className="pt-[24px]">Proprietor on title</SubHeader>
      <Proprietors //
        items={proprietors}
      />
      <SubHeader className="pt-[24px]">Address</SubHeader>
      <Address address={address} jurisdictionId={props.jurisdictionId} />
    </>
  );
}

export const useScrollableStyles = makeStyles(
  {
    scrollBar: {
      '&::before': {
        content: 'text'
      },
      borderRadius: 0,
      boxShadow: 'none',

      '&::-webkit-scrollbar': {
        width: 6
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
        borderRadius: 30
      }
    },
    hasOverlay: {
      '& + $overlay': {
        display: 'block'
      }
    },
    overlay: {
      left: 0,
      bottom: -37,
      position: 'absolute',
      width: 'calc(100% - 30px)',
      height: 72,
      background: 'linear-gradient(180deg, rgba(240, 253, 252, 0.00) 0%, var(--greek-waters-translucent) 52%)',
      display: 'none'
    }
  },
  { name: 'Scrollable' }
);

export function Scrollable(props: React.PropsWithChildren<{ className?: string; expandedIndex?: number }>) {
  const classes = useScrollableStyles();
  const divRef = React.useRef<React.ElementRef<'div'>>(null);

  React.useEffect(() => {
    const scroller: HTMLDivElement | null = divRef.current;
    function updateScrollPosition() {
      if (scroller) {
        const hasScrollbar = scroller.scrollHeight > scroller.clientHeight;
        const reachedBottom = scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight;

        if (hasScrollbar && !reachedBottom) {
          scroller.classList.add(classes.hasOverlay);
        } else {
          scroller.classList.remove(classes.hasOverlay);
        }
      }
    }

    if (scroller) {
      updateScrollPosition();
      scroller.addEventListener('scroll', updateScrollPosition, false);
      return function cleanup() {
        scroller?.removeEventListener('scroll', updateScrollPosition, false);
      };
    }
  }, [classes.hasOverlay]);

  return (
    <div className="relative">
      <div //
        ref={divRef}
        className={classNames('overflow-y-auto pr-[24px]', classes.scrollBar, props.className)}
      >
        {props.children}
      </div>
      <div className={classes.overlay} />
    </div>
  );
}

interface Props {
  invitationDetail: InvitationsTableRowModel;
  invitationAssignableGroups: GroupOptionModel[];
  invitationForwardableGroups: GroupOptionModel[];
  onAccept(data: AcceptInviteFormValues, type: AcceptInviteSubmitType): Promise<void>;
  onForwardToGroup(data: ForwardInviteToGroupFormValues): Promise<void>;
  onForwardToSubscriber(data: ForwardInviteToSubscriberFormValues, formikHelpers: FormikHelpers<ForwardInviteToSubscriberFormValues>): Promise<void>;
  onDecline(data: DeclineInviteFormValues): Promise<void>;
  onCancel(): void;
  isForwardInvitationToSubscriberEnabled: boolean;
}

type TabValue = 'a' | 'f' | 'd';
function ModalDialogContent(props: Props) {
  const {
    //
    workspaceId,
    participantId: invitationId,
    jurisdictionId,
    sympliId,
    titleReferences,
    matter,
    workspaceTypeId,
    snapshotSettlementDate,
    groupName,
    forwardedFromSubscriberName,
    fromSubscriberName,
    invitationDescription,
    heroWorkspace,
    participantRoles,
    roles,
    originalInvitationLastSentDate
  } = props.invitationDetail;
  const [selectedTab, setSelectedTab] = React.useState<TabValue>('a');
  const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(titleReferences.length > 1 ? 0 : undefined);

  const isTransactionTypeEnabled: boolean = useFeatureFlag(FeatureToggleEnum.transactionTypeEnabled);

  const handleAccordionExpandChange = (panelIndex: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedIndex(newExpanded ? panelIndex : undefined);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    if (newValue === 'f') {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'forward-invitation',
        logGroupId: 'dashboard',
        sympliId,
        workspaceId,
        invitationId
      });
    }
    setSelectedTab(newValue);
  };

  // only show the Proprietors & Address if it is not linked workspace
  const shouldShowProprietorsAndAddress = Boolean(heroWorkspace) === false;

  const renderOriginalInviter = (subscriberName: string, originalInvitationLastSentDate: DateTimeInfo) => {
    return (
      <>
        <SubHeader className="pt-[24px] text-[var(--sunny-day-dark)]">Original Inviter</SubHeader>
        <SubContent>{subscriberName}</SubContent>
        <div className="text-[12px] text-[var(--neutral-600)]">
          {dateTimeLine(originalInvitationLastSentDate.workspaceLocalTime, 'd mmm yyyy, h:MMTT') + ' ' + originalInvitationLastSentDate.workspaceTimezoneAbbreviation}
        </div>
      </>
    );
  };

  if (!roles.length) {
    const scope = Logger.scopeWithCustomAttributes({ sympliId });
    Logger.captureException(new Error('Invalid invitation due to no role'), scope);
  }
  if (titleReferences.length === 0) {
    const scope = Logger.scopeWithCustomAttributes({ sympliId });
    Logger.captureException(new Error(`invitation with Id: ${invitationId} contains no title references`), scope);
  }

  return (
    <div className="flex">
      <div className="box-border w-[448px] bg-[var(--greek-waters-translucent)] pb-[37px] pl-[48px] pr-[24px] pt-[56px]">
        {originalInvitationLastSentDate && <SubHeader className="text-[12px] font-[700] text-[var(--sunny-day-dark)]">Forwarded Invite</SubHeader>}
        <DialogTitle //
          className="m-0 p-0 font-volkswagen-serial-xbold text-[34px] font-[800] text-[var(--sympli-green)] "
        >
          You are invited!
        </DialogTitle>

        <SubHeader className="pt-[8px] text-[14px] normal-case">{roles[0] ? `As ${roles[0]} to collaborate on` : "Let's collaborate on"}</SubHeader>
        <Scrollable className="h-[350px] grow" expandedIndex={expandedIndex}>
          {titleReferences.length === 0 ? (
            <Title reference="-" />
          ) : titleReferences.length > 1 ? (
            titleReferences.map((title, index) => {
              return (
                <Accordion
                  //
                  key={index}
                  expanded={index === expandedIndex}
                  onChange={handleAccordionExpandChange(index)}
                  className={index === 0 ? 'pt-[0px]' : 'pt-[8px]'}
                  summary={
                    <Title //
                      reference={title.reference}
                    />
                  }
                >
                  {shouldShowProprietorsAndAddress && (
                    <Body //
                      invitationTitles={props.invitationDetail.titleReferences}
                      sympliId={sympliId}
                      title={title.reference}
                      jurisdictionId={jurisdictionId}
                    />
                  )}
                </Accordion>
              );
            })
          ) : (
            <>
              <Title //
                reference={titleReferences[0].reference}
              />
              {shouldShowProprietorsAndAddress && (
                <Body //
                  invitationTitles={props.invitationDetail.titleReferences}
                  sympliId={sympliId}
                  title={titleReferences[0].reference}
                  jurisdictionId={jurisdictionId}
                />
              )}
            </>
          )}

          {!!invitationDescription?.length && (
            <>
              <SubHeader className={'pt-[24px]'}>Message from {forwardedFromSubscriberName ?? fromSubscriberName}</SubHeader>
              <Message body={invitationDescription} />
            </>
          )}
          {originalInvitationLastSentDate && renderOriginalInviter(fromSubscriberName, originalInvitationLastSentDate)}

          {isTransactionTypeEnabled && props.invitationDetail.invitationTransactionType !== undefined && (
            <RenderTransactionType
              transactionType={{ type: props.invitationDetail.invitationTransactionType, otherDescription: props.invitationDetail.invitationTransactionTypeOtherDescription }}
            />
          )}
        </Scrollable>
      </div>
      <div className="box-border flex w-[536px] flex-col bg-[var(--neutral-000)] py-[56px] pl-[48px] pr-0">
        <Tabs value={selectedTab} onChange={handleTabChange} className="mb-[32px]">
          <Tab label="ACCEPT" value="a" />
          <Tab label="FORWARD" value="f" />
          <Tab label="DECLINE" value="d" />
        </Tabs>

        {selectedTab === 'a' && (
          <AcceptInviteForm //
            invitationId={invitationId}
            workspaceId={workspaceId}
            workspaceTypeId={workspaceTypeId}
            snapshotSettlementDate={snapshotSettlementDate}
            invitationAssignedGroup={groupName}
            invitationAssignableGroups={props.invitationAssignableGroups}
            jurisdictionId={jurisdictionId}
            onCancel={props.onCancel}
            onSubmit={props.onAccept}
            matter={matter}
          />
        )}
        {selectedTab === 'f' &&
          (props.isForwardInvitationToSubscriberEnabled ? (
            <ForwardInvitationForm
              //
              invitationId={invitationId}
              workspaceId={workspaceId}
              isLinkedWorkspace={Boolean(heroWorkspace) === true}
              invitationForwardableGroups={props.invitationForwardableGroups}
              jurisdictionId={jurisdictionId}
              invitedRole={participantRoles[0].id}
              onCancel={props.onCancel}
              invitationDescription={invitationDescription}
              onForwardToGroupSubmit={props.onForwardToGroup}
              onForwardToSubscriberSubmit={props.onForwardToSubscriber}
            />
          ) : (
            <ForwardInviteForm
              //
              invitationId={invitationId}
              workspaceId={workspaceId}
              invitationForwardableGroups={props.invitationForwardableGroups}
              jurisdictionId={jurisdictionId}
              onCancel={props.onCancel}
              onSubmit={props.onForwardToGroup}
            />
          ))}
        {selectedTab === 'd' && (
          <DeclineInviteForm
            //
            invitationId={invitationId}
            workspaceId={workspaceId}
            onCancel={props.onCancel}
            onSubmit={props.onDecline}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(ModalDialogContent);
