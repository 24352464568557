import { formatPartyName, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';

import { PartyBookIdentifierModel } from './models';

export function filterSelectedPartySiblingsFromPartyBook(
  partyBook: PartyModel<{}, {}>[],
  currentSelectedParty: PartyBookIdentifierModel,
  selectedParties?: PartyBookIdentifierModel[],
  partyFormConfig?: any
) {
  //
  const selectedPartyIds = selectedParties?.map(party => party.partyBookId) ?? [];

  const partyOptions = partyBook
    .filter(party => {
      const isCurrentlySelected = party.id === currentSelectedParty.partyBookId;
      const hasNotBeenSelected = !selectedPartyIds.includes(party.id);

      /*
       * We need to return current selected party too;
       * otherwise, the selected value of the dropdown list will be empty.
       */
      return isCurrentlySelected || hasNotBeenSelected;
    })
    .map(party => ({ id: party.id, name: formatPartyName(party, partyFormConfig) }));

  return partyOptions;
}
