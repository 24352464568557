import * as React from 'react';

import { useDispatch } from 'react-redux';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { orderedAvailableParticipantsSelector } from 'src/containers/workspace/shared/detail/selectors';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { actionCreateNewConversation } from '../../actions';
import NewMessage from './NewMessage';
import NewMessage101 from './NewMessage101';

interface Props {
  workspaceId: string;
  participantId: string;
  onConversationSelect: (selectedConversationId?: string) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function NewMessageContainer({ workspaceId, participantId, onConversationSelect, onBackClick }: Props) {
  const isWorkspaceMessenger101Enabled = useFeatureFlag(FeatureToggleEnum.workspaceMessenger101Enabled);
  const dispatch = useSafeDispatch(useDispatch());
  const { name }: UserProfileModel = useProfile().data!;
  const workspaceParticipants = useWorkspaceParticipants(workspaceId);
  const participants: WorkspaceParticipantApiResponse[] = orderedAvailableParticipantsSelector({
    workspaceParticipants
  });

  const currentParticipant: WorkspaceParticipantApiResponse | undefined = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants
  });
  const currentParticipantSubscriberName = currentParticipant?.name;

  const handleOnConversationCreated = React.useCallback(
    (args: {
      //
      conversationId: string;
      recipientParticipantIds: string[];
      subject: string;
      message: string;
    }) => {
      /// if user was withdrawn we don't do anything
      if (!currentParticipantSubscriberName) {
        return;
      }
      dispatch(
        actionCreateNewConversation({
          //
          workspaceId,
          participantId,
          ...args,
          sentBySubscriberName: currentParticipantSubscriberName,

          sentByUserName: name
        })
      );
      onConversationSelect(args.conversationId);
    },
    [dispatch, onConversationSelect, workspaceId, participantId, name, currentParticipantSubscriberName]
  );

  if (isWorkspaceMessenger101Enabled) {
    return (
      <NewMessage101 //
        workspaceId={workspaceId}
        participantId={participantId}
        participants={participants}
        onConversationCreated={handleOnConversationCreated}
        onBackClick={onBackClick}
      />
    );
  }

  return (
    <NewMessage //
      workspaceId={workspaceId}
      participantId={participantId}
      participants={participants}
      onConversationCreated={handleOnConversationCreated}
      onBackClick={onBackClick}
    />
  );
}

export default React.memo(NewMessageContainer);
