import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    header: {
      paddingRight: 46, // border + icon + spacing around
      paddingBottom: 4,
      color: theme.palette.text.primary,
      '& > div': {
        fontWeight: 'bold'
      }
    }
  }),
  { name: 'TableLikeHeader' }
);
