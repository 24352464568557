import { createAction, createAsyncAction } from 'typesafe-actions';

import { WorkspaceSummaryModel } from '../shared/models';
import { FinancialWorkspaceRowDetailV2ApiRequest, FinancialWorkspacesV2ApiRequest, FinancialWorkspacesV2ApiResponse } from './models';
import { FinancialTableRowModel } from './table/models';

export interface SelectDashboardFeedDetailModel {
  data: WorkspaceSummaryModel | null;
  selectedRowIndex: number;
}

export const actionFetchFinancialWorkspacesFeedV2 = createAsyncAction(
  //
  'FETCH_FINANCIAL_WORKSPACES_FEED_V2',
  'FETCH_FINANCIAL_WORKSPACES_FEED_V2_SUCCESS',
  'FETCH_FINANCIAL_WORKSPACES_FEED_V2_ERROR'
)<
  //
  FinancialWorkspacesV2ApiRequest,
  { data: FinancialWorkspacesV2ApiResponse },
  { error: Error }
>();

export const showFinancialWorkspaceFeedDetail = createAction(
  //
  'SHOW_FINANCIAL_WORKSPACE_FEED_DETAIL'
)<{ rowDetailIndex?: number; workspaceId?: string; participantId?: string }>();

export const actionFetchFinancialWorkspacesFeedDetailV2 = createAsyncAction(
  //
  'FETCH_FINANCIAL_WORKSPACE_FEED_DETAIL_V2',
  'FETCH_FINANCIAL_WORKSPACE_FEED_DETAIL_V2_SUCCESS',
  'FETCH_FINANCIAL_WORKSPACE_FEED_DETAIL_V2_ERROR'
)<
  //
  FinancialWorkspaceRowDetailV2ApiRequest,
  FinancialTableRowModel, // TODO replace with real model
  { error: Error }
>();
