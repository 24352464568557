import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative'
    },
    avatar: {
      zIndex: 2
    },
    avatarWithNumber: {
      zIndex: 1,
      width: 30,
      height: 30,
      background: 'none',
      border: '1px solid #999999',
      color: '#666666',
      fontSize: 12,
      fontWeight: 500,
      marginLeft: -4,
      boxSizing: 'border-box'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
