import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { ParticipantArchivedStatusEnum } from '@sympli/api-gateway/enums';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';

import { useSafeDispatch } from 'src/hooks';
import { actionFetchInvitationsArchivedFeed } from './actions';
import { InvitationArchivedViewTypeEnum } from './api';
import { resolveInvitationDetail } from './helper';
import { useInvitationsArchivedFeed } from './reducers/invitationsArchivedFeed';
import { useInvitationsFeedV2 } from './reducers/invitationsFeedV2';
import InvitationsTableRowDetail from './table/InvitationsTableRowDetail';

interface InvitationsDashboardRowContainerProps {}

function InvitationsDashboardRowContainer(props: InvitationsDashboardRowContainerProps) {
  const dispatch = useSafeDispatch(useDispatch());
  const { items, rowDetailIndex } = useInvitationsFeedV2();
  const item = resolveInvitationDetail(items, rowDetailIndex);
  const showLinkedInvitations = item?.originalInvitationId && item?.type === ParticipantArchivedStatusEnum.Sent;
  const invitationId = showLinkedInvitations ? item.originalInvitationId : item?.participantId;

  const { status, items: archivedLogs } = useInvitationsArchivedFeed(invitationId);

  useEffect(() => {
    if (invitationId) {
      dispatch(
        actionFetchInvitationsArchivedFeed.request({
          invitationId,
          invitationArchivedViewType:
            item?.type === ParticipantArchivedStatusEnum.Forwarded
              ? InvitationArchivedViewTypeEnum.Forwarder
              : item?.type === ParticipantArchivedStatusEnum.Sent
                ? InvitationArchivedViewTypeEnum.Sender
                : InvitationArchivedViewTypeEnum.Receiver
        })
      );
    }
  }, [dispatch, item?.type, invitationId]);

  if (status === 'idle' || status === 'pending') {
    return (
      <div className="p-[20px]">
        <InlineLoader />
      </div>
    );
  }

  if (!item || !archivedLogs.length) {
    return null;
  }

  return <InvitationsTableRowDetail items={archivedLogs} invitationDetail={item} />;
}

export default React.memo(InvitationsDashboardRowContainer);
