import * as React from 'react';

import classNames from 'classnames';

import StatusBadge, { StatusBadgeColor } from '@sympli/ui-framework/components/status-badge';

import { useStyles } from './styles';

interface Props {
  status: 'inactive' | 'active' | 'suspended' | 'terminated';
  margin?: 'left';
}

function ActivationStatusBadge({
  //
  status,
  margin
}: Props) {
  const classes = useStyles();

  let text: string = 'Not activated';
  let color: StatusBadgeColor = 'secondary'; //inactive

  if (status === 'active') {
    color = 'success';
    text = 'Active';
  } else if (status === 'suspended') {
    color = 'warning';
    text = 'Suspended';
  } else if (status === 'terminated') {
    color = 'error';
    text = 'Terminated';
  }

  return (
    <StatusBadge //
      color={color}
      size="small"
      variant="filled"
      className={classNames(classes.root, margin === 'left' && classes.marginLeft)}
    >
      {text}
    </StatusBadge>
  );
}

export default React.memo(ActivationStatusBadge);
