import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';

import { StandardTermsDealingNumbersModel } from './models';

export const IS_REQUIRED_CHECK_DOCUMENT_REFERENCE = (item: StandardTermsDealingNumbersModel, additionalCovenants: string, additionalAttachments?: DocumentAttachmentItemModel[]) =>
  !isDocumentReferenceFilled(item) && !isAdditionalCovenantsFilled(additionalCovenants) && !isAdditionalAttachmentsFilled(additionalAttachments);

export const IS_REQUIRED_CHECK_ADDITIONAL_COVENANTS = (standardTermsDealingNumbers: StandardTermsDealingNumbersModel[], additionalAttachments?: DocumentAttachmentItemModel[]) =>
  !isStandardTermsDealingNumbersFilled(standardTermsDealingNumbers) && !isAdditionalAttachmentsFilled(additionalAttachments);

export const IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT = (standardTermsDealingNumbers: StandardTermsDealingNumbersModel[], additionalCovenants: string) =>
  !isAdditionalCovenantsFilled(additionalCovenants) && !isStandardTermsDealingNumbersFilled(standardTermsDealingNumbers);

const isStandardTermsDealingNumbersFilled = (standardTermsDocumentReferences: StandardTermsDealingNumbersModel[]) =>
  standardTermsDocumentReferences.some(item => item.termNumber?.trim());

const isAdditionalCovenantsFilled = (additionalCovenant: string) => additionalCovenant.trim() !== '';

const isAdditionalAttachmentsFilled = (additionalAttachments?: DocumentAttachmentItemModel[]) => !!additionalAttachments && additionalAttachments.length > 0;

const isDocumentReferenceFilled = (item: StandardTermsDealingNumbersModel) => (item.termNumber ?? '').trim() !== '';
