import { QLD_ADDRESS_FORM_CONFIG, QLD_ADDRESS_FORM_INITIAL_VALUES, QLD_PHYSICAL_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/qld/2-24/config';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import {
  AddressFieldFormConfig,
  AddressFormValueModel,
  InitialValuesAddressEntityModel,
  PhysicalAddressFormConfig
} from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { UnitTypeEnum } from '@sympli-mfe/enums-shared/necds';

import { resolvePlaceholder } from 'src/utils/formUtils';

export const QLD_CAVEAT_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...QLD_PARTY_FORM_CONFIG
};

const QLD_CAVEAT_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG,
  unitType: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitType,
    hidden: true
  },
  unitNumber: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitNumber,
    placeholder: resolvePlaceholder(false)
  }
};

export const QLD_CAVEAT_ADDRESS_FORM_INITIAL_VALUES: AddressFormValueModel<InitialValuesAddressEntityModel> = {
  ...QLD_ADDRESS_FORM_INITIAL_VALUES,
  addressDetail: {
    ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail,
    physicalAddress: {
      ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail.physicalAddress!,
      unitType: UnitTypeEnum.UNIT
    }
  }
};

export const QLD_CAVEAT_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG,
  physicalAddressFormConfig: QLD_CAVEAT_PHYSICAL_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false,
  overseasAddressFormConfig: false
};
