import * as yup from 'yup';

import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-18/vic';

import { checkIfJustifiedPartiesAreTheSame, checkIfMergedPartiesHaveMismatchingPartyType, isMortgagorNamesMatchesOrJustified } from '../../helpers';
import { Mortgage2_18_1Model, MortgagorPartyModel } from '../../models';

interface MortgagorsContext {
  isFinancialSettlement: boolean;
  mortgagorsLength: number;
  partyBook: Mortgage2_18_1Model['partyBook'];
  titleReferences: Mortgage2_18_1Model['titleReferences'];
}

const contextResolver = ({ isFinancialSettlement, mortgagors, partyBook, titleReferences }: Mortgage2_18_1Model): MortgagorsContext => {
  return {
    isFinancialSettlement,
    mortgagorsLength: mortgagors.length,
    partyBook,
    titleReferences
  };
};

const yupMortgagors = memoizeSchemaWithContext(
  yup
    .array<MortgagorPartyModel, MortgagorsContext>()
    .defined()
    // .test('Wait for transfer test', 'Awaiting Transfer document completion', function test(this: yup.TestContext<Mortgage2_18_1Model>) {
    //   const mortgageDocument = this.options.context!;
    //   if (!mortgageDocument.isFinancialSettlement) {
    //     return true;
    //   }
    //   return mortgageDocument.mortgagors.length !== 0;
    // })
    .testContextualRule({
      uniqueTestName: 'Wait for transfer test',
      message: 'Awaiting Transfer document completion',
      requirement: (parent: Mortgage2_18_1Model, ctx: MortgagorsContext) => {
        if (!ctx.isFinancialSettlement) {
          return true;
        }
        return ctx.mortgagorsLength !== 0;
      }
    })
    // .test('Mortgagors name mismatch test', 'Please be aware that the parties on the selected titles presently do not match.', function test(
    //   this: yup.TestContext<Mortgage2_18_1Model>,
    //   value: any
    // ) {
    //   const mortgageDocument = this.options.context!;
    //   return isMortgagorNamesMatchesOrJustified(mortgageDocument);
    // })
    .testContextualRule({
      uniqueTestName: 'Mortgagors name mismatch test',
      message: 'Please be aware that the parties on the selected titles presently do not match.',
      requirement: (parent: Mortgage2_18_1Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return isMortgagorNamesMatchesOrJustified(partyBook, titleReferences);
      }
    })

    // .test('Check if party is justified twice', 'Unable to change name, the proposed name has already been used in a justification.', function test(
    //   this: yup.TestContext<Mortgage2_18_1Model>,
    //   value: any
    // ) {
    //   const mortgageDocument = this.options.context!;
    //   return !checkIfJustifiedPartiesAreTheSame(mortgageDocument);
    // })
    .testContextualRule({
      uniqueTestName: 'Check if party is justified twice',
      message: 'Unable to change name, the proposed name has already been used in a justification.',
      requirement: (parent: Mortgage2_18_1Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return !checkIfJustifiedPartiesAreTheSame(partyBook, titleReferences);
      }
    })
    .test('Check if parties justified does not have same party type', 'Unable to change name', function test(this: yup.TestContext<MortgagorsContext>) {
      const { partyBook, titleReferences } = this.options.context!;
      const partyType = checkIfMergedPartiesHaveMismatchingPartyType(partyBook, titleReferences);
      return partyType
        ? this.createError({
            message: `Unable to change name, the proposed name has already been used for an ${partyType === PartyTypeEnum.Individual ? 'individual' : 'organisation'}.`
          })
        : true;
    })
    .log(),
  contextResolver
);

export default yupMortgagors;
