import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  //
  (theme: Theme) => ({
    root: {
      paddingTop: '-16px'
    },
    errorMessage: {
      margin: '16px auto'
    },
    boxHeader: {
      paddingBottom: 14
    },
    boxTitle: {
      width: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    titleLink: {
      fontSize: 18,
      minWidth: 0,
      '& > span': {
        textDecoration: 'none'
      }
    },
    titleLeftIcon: {
      marginLeft: -16,
      fontSize: 18
    },
    titleRightIcon: {
      fontSize: 18
    },
    toggleButton: {
      display: 'inline-flex',
      flexShrink: 0,
      height: 16,
      width: 16,
      fontSize: 13,
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      userSelect: 'none',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    title: {
      fontWeight: 'bold',
      fontSize: 18
    },
    subheading: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.primary.main
    },
    normalSubheading: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.primary.main
    },
    smallRow: {
      fontSize: 13,
      lineHeight: '15px'
    },
    textEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    sectionSpacingTop: {
      paddingTop: theme.spacing(0.5)
    },
    sectionSpacingTopSmall: {
      paddingTop: 5
    },
    sectionSpacingBottom: {
      paddingBottom: 10
    },
    sectionIcon: {
      paddingRight: 4
    },
    grow: {
      flex: '1 0'
    },
    verticallyAlign: {
      display: 'flex',
      alignItems: 'center'
    },
    marginTop: {
      marginTop: theme.spacing(2)
    },
    iconMapMaker: {
      marginLeft: -2,
      paddingRight: 2
    },
    warning: {
      fill: colors.ORANGE,
      color: colors.ORANGE
    },
    main: {
      overflow: 'hidden'
    },
    description: {
      fontSize: 13,
      lineHeight: '15px',
      color: theme.palette.text.secondary
    },
    addressSection: {
      '&+ $addressSection': {
        paddingTop: 4,
        '&>a:first-child': {
          paddingTop: 4,
          borderTop: `1px dashed ${theme.palette.divider}`
        }
      }
    },
    titleList: {
      marginLeft: -16,
      marginRight: -16
    },
    titleItems: {
      paddingLeft: 16,
      paddingRight: 16
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    clientsSectionSpacingTop: {
      paddingTop: theme.spacing(2)
    }
  }),
  { name: 'WorkspaceDetailBox' }
);

export type ClassKeys = ReturnType<typeof useStyles>;
