import { applyMiddleware, compose, createStore, StoreEnhancer } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { Store } from 'src/reducers';
import { createRootReducer } from '../reducers';
import sagas from '../sagas';
import globalErrorMiddleware from './globalErrorMiddleware';
import loggerMiddleware from './loggerMiddleware';

const configureStore = (preloadedState: Partial<Store>) => {
  const rootReducer = createRootReducer();

  const sagaMiddleware = createSagaMiddleware();

  // initialize Redux DevTools Extension
  const composeEnhancers = (import.meta.env.DEV && window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const composers: StoreEnhancer[] = [
    applyMiddleware(
      //
      loggerMiddleware,
      sagaMiddleware,

      globalErrorMiddleware
    )
  ];

  const store = createStore(
    //
    rootReducer,
    preloadedState,
    composeEnhancers(...composers)
  );

  if (import.meta.hot) {
    // Enable Webpack hot module replacement for reducers
    import.meta.hot.accept('../reducers', () => {
      const nextRootReducer = rootReducer;
      store.replaceReducer(nextRootReducer);
    });
  }
  sagaMiddleware.run(sagas);
  return store;
};

export default configureStore;
