import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

//TODO move this DateFormatEnum somewhere else, it should be across all platform
export enum DateFormatEnum {
  DATE = 'yyyy-mm-dd', // isoDate
  DATETIME = "yyyy-mm-dd'T'HH:MM:ss", // isoDateTime
  SHORTTIME = 'h:MM TT',
  TIME = 'HH:MM',
  AUSSHORTDATE = 'dd/mm/yy',
  AU_SHORT_DAY_MONTH = 'dd mmm',
  HOUR_MIN_TIMEMARKER = 'h:MMtt',
  AUSDATE = 'dd/mm/yyyy',
  AUSDATETEXT = 'ddd dd mmm yyyy',
  AUSDATETIME = 'dd mmm yyyy h:MM TT',
  AUSDATETIMETEXT = 'dd mmm yyyy, h:MM TT',
  DATEMONTH = 'd mmm',
  DATEMONTHYEAR = 'd mmm yyyy',
  DATEMONTH_SHORTYEAR = 'd mmm yy',
  DAY_MONTH_HOUR_MIN_MARKER = 'd mmm h:MMTT', //15 Jan 9:31AM
  //TODO review all these date format
  DAY_MONTH_HOUR_MIN_MARKER_2 = 'd mmm, h:MMtt' // 22 Apr, 9:18pm
}

export const REASON_OPTIONS: Array<{ name: string; id: number }> = [
  { name: 'Need more time', id: 0 },
  { name: 'Bank not ready', id: 1 },
  { name: 'Date change agreed', id: 2 },
  { name: 'Other', id: 3 }
];

export interface SettlementDateFormModel {
  selectedDate: string; // DateFormatEnum.DATE 'yyyy-mm-dd'
  selectedTime: string; // id in SETTLEMENT_TIME_OPTIONS : 'HH:MM'
  reasonId: number | null;
}

export interface UpdateSettlementDateApiRequest {
  participantId: string;
  settlementDate: string;
  reasonId: number | null;
}

export enum SettlementDateStatusEnum {
  Accepted,
  ReviewAndAccept
}

// export interface SettlementParticipantItemModel extends InvitedParticipantApiResponse {
//   settlementDateStatus: SettlementDateStatusEnum;
// }
export type SettlementParticipantItemModel = {
  settlementDateStatus: SettlementDateStatusEnum;
} & WorkspaceParticipantApiResponse;

export interface Map<T> {
  [key: string]: T;
}

export interface DateValueMapModel {
  [key: string]: DayCountModel;
}

export interface DayCountModel {
  date: string;
  count: number;
  hourCount: Map<number>;
}

export type DayCountFeedModel = Array<DayCountModel>;

export enum MonthEnum {
  Jan = 0,
  Feb = 1,
  Mar = 2,
  Apr = 3,
  May = 4,
  Jun = 5,
  Jul = 6,
  Aug = 7,
  Sep = 8,
  Oct = 9,
  Nov = 10,
  Dec = 11
}

export enum WeekEnum {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Web = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6
}

export interface RescindSettlementDateTimeModel {
  id: number;
  participantId: string;
  workspaceId: string | undefined;
}

export interface RescindApprovalModel {
  canRescind: boolean;
  reason?: JSX.Element;
}

export enum RescindDisabledReasonEnum {
  ProposingParticipant = 'you proposed new settlement date and time',
  PastSettlementDate = 'the settlement date and time is in the past',
  ProposalNotAccepted = 'you have not accepted the proposed settlement date and time'
}
