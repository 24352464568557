import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    addReceivingParty: {
      border: 'none',
      backgroundColor: 'transparent',
      paddingLeft: 0,
      marginTop: -10,
      '& button': {
        paddingLeft: 0
      },
      '& span:first-of-type': {
        fontWeight: 'bold'
      }
    },
    noBordersAndPadding: {
      padding: 0,
      '& + &': {
        padding: 0,
        borderTop: 'none'
      }
    },
    receivingPartyItem: {
      padding: 0
    },
    titleContainer: {
      flex: 'none',
      width: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingRight: 5
    },
    fullWidth: {
      width: 'auto'
    },
    iconButton: {
      paddingLeft: 0,
      '& > *:first-child': {
        position: 'relative',
        inset: 'unset',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    receivingPartyTitle: {
      marginBottom: 10
    },
    footerError: {
      color: theme.palette.error.main
    },
    footerDisabled: {
      footerDisabled: theme.palette.action.disabled
    }
  }),
  { name: 'ReceivingParties' }
);

export default useStyles;
