import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    divider: {
      marginTop: 14,
      marginBottom: 18
    },
    checkboxSelectAll: {
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 18
    }
  }),
  {
    name: 'YourClients'
  }
);
