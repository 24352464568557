import * as React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';

import DocumentFieldArray, { DocumentAddButtonRenderProps, DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { NecdsCountryEnum } from '@sympli-mfe/enums-2-24/necds';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { FORM_25_COUNTRY_CODE_OPTIONS } from '../../enums';
import { ForeignShareholderModel, Form25_2_24_1Model } from '../../models';
import { VISIBILITY_CHECK_FOREIGN_SHAREHOLDER_PERCENT } from '../../visibilityCheck';
import { useStyles } from './styles';

interface ItemProps {
  itemBinding: string;
  itemIndex: number;
  isLastItem: boolean;
  countryOptions: LookupItemModel<string>[];
  parentError?: string;
}

const fieldName = modelKey<Form25_2_24_1Model>();

function ForeignShareholder({ countryOptions, itemIndex, isLastItem, itemBinding, parentError }: ItemProps) {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<Form25_2_24_1Model>();
  const { values } = formikProps;
  const itemFieldName = createModelKeyAppender<ForeignShareholderModel>(itemBinding);
  const itemPercentError = getFormikError(formikProps, createModelKeyAppender<ForeignShareholderModel>(itemBinding)('percent'));

  const PercentField = (
    <Field
      name={itemFieldName('percent')}
      aria-label={itemFieldName('percent')}
      component={InputField}
      disabled={disabled}
      format="number"
      endAdornment={<InputAdornment position="end">%</InputAdornment>}
      className={classNames(itemIndex === 0 ? classes.fullWidth : classes.quarterWidth, classes.foreignShareholderContent)}
      inputProps={{ className: classes.percentFormControl, maxLength: 2 }}
      InputProps={{ error: Boolean(parentError || itemPercentError) }}
      validationOverflowDirection="horizontal"
    />
  );

  return (
    <>
      <FlexLayout flexDirection="row" classes={{ root: classes.fullWidth }}>
        <Field //
          name={itemFieldName('country')}
          aria-label={itemFieldName('country')}
          component={SelectField}
          disabled={disabled}
          options={countryOptions}
          placeholder="Select country"
          className={classNames(classes.country, classes.foreignShareholderContent)}
          fullWidth
        />
        {VISIBILITY_CHECK_FOREIGN_SHAREHOLDER_PERCENT(values) &&
          (itemIndex === 0 ? (
            <FlexLayout flexDirection="column" className={classNames(classes.percentLayout, classes.quarterWidth)}>
              <InputLabel className={classes.percentLabel} shrink>
                Percentage
              </InputLabel>
              {PercentField}
            </FlexLayout>
          ) : (
            PercentField
          ))}
      </FlexLayout>

      {isLastItem && Boolean(parentError) && (
        <FormHelperText role="alert" error className={classes.errorMessage} data-error-name={fieldName('foreignShareholders')}>
          {parentError}
        </FormHelperText>
      )}
    </>
  );
}

function ForeignShareholders() {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<Form25_2_24_1Model>();
  const {
    values: { foreignShareholders }
  } = formikProps;
  const classes = useStyles();
  const selectedForeignShareholderCountries: NecdsCountryEnum[] = foreignShareholders.map(fsp => NecdsCountryEnum[fsp.country]);
  const renderItem = React.useCallback(
    ({ itemBinding, item, itemIndex, itemCount }: DocumentArrayItemRenderProps<ForeignShareholderModel>) => {
      const countryOptions = restrictedLookupOptions<NecdsCountryEnum>(FORM_25_COUNTRY_CODE_OPTIONS, {
        blacklist: selectedForeignShareholderCountries.filter(country => country !== item.country)
      });
      const isLastItem = itemIndex === itemCount - 1;

      const foreignShareholderError = getFormikError(formikProps, fieldName('foreignShareholders'));
      return <ForeignShareholder itemBinding={itemBinding} itemIndex={itemIndex} isLastItem={isLastItem} countryOptions={countryOptions} parentError={foreignShareholderError} />;
    },
    [selectedForeignShareholderCountries, formikProps]
  );

  const createNewForeignShareholder = React.useCallback((): ForeignShareholderModel => ({ country: '', percent: 0 }), []);

  const renderAddButton = React.useCallback(
    ({ onAdd }: DocumentAddButtonRenderProps) => {
      return (
        <ButtonLink icon="add" onClick={onAdd} color="inherit" disabled={disabled} className={classes.addButton}>
          Add another foreign shareholder country
        </ButtonLink>
      );
    },
    [classes.addButton, disabled]
  );

  return (
    <FormGroup
      title="Foreign stakeholder breakdown"
      description="Enter all the foreign stakeholders by country that have ownership in this organisation and provide their percentage of ownership."
      classes={{ root: classes.foreignShareholders }}
    >
      <DocumentFieldArray
        arrayTitle="Foreign stakeholder by country"
        renderItem={renderItem}
        arrayBinding={fieldName('foreignShareholders')}
        itemStyle="formGroup"
        disabled={disabled}
        minItems={1}
        maxItems={20}
        hideAddButtonOnDisable={false}
        createNewItem={createNewForeignShareholder}
        renderAddButton={renderAddButton}
        classes={{
          //
          root: classes.fullWidth,
          formGroup: classes.formGroup,
          formGroupTitle: classes.formGroupTitle,
          formGroupIconPrefix: classes.formGroupIconPrefix
        }}
      />
    </FormGroup>
  );
}

export default React.memo(ForeignShareholders);
