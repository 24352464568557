import {
  AccessOptionEnum,
  DocumentActionTypeEnum,
  DocumentDenyPermissionReasonCodeEnum,
  DocumentPermissionEnum,
  DocumentStatusEnum,
  DocumentTypeIdentifierEnum,
  DocumentWorkflowTypeEnum,
  WorkspaceRoleEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceDocumentDetailApiResponse } from '@sympli/api-gateway/models';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { RegOnlyPaymentSummaryApiResponse } from 'src/containers/shared/payment-summary-box/models';
import { AuthorisationDenyPermissionReasonCodeEnum } from '../workspace/financial/directions/models';
import { JsonSchemaRootModel } from './dynamic-form/models';

export interface DocumentPageRouteParams {
  workspaceId: string;
  participantId: string;
  documentId: string;
}

export type DocumentSigningMessageApiRequest = {
  workspaceId: string;
  participantId: string;
  documents: Array<{ documentState: string; documentType: string; documentVersion: string }>;
};

export type DocumentSigningMessageApiResponse = {
  documentState?: string;
  documentType?: string;
  documentVersion?: string;
  documentId: string;
};

export interface DocumentDetailApiRequest {
  workspaceId: string;
  documentId: string;
  participantId: string;
  // taskId?: string;
}

export type SaveDocumentFormRequest = DocumentDetailApiRequest;
export type DocumentsPageRouteAndQueryModel = DocumentDetailApiRequest;

export interface PaymentSummaryApiRequest {
  workspaceId: string;
  participantId: string;
}

// export interface DocumentFormModel {
//   id: string;
//   name: string;
//   documentType: DocumentTypeIdentifierEnum;
//   schema: JsonSchemaRootModel; // needs to be converted to JSON
//   documentViewerType: DocumentViewerTypeEnum;

//   template?: string;

//   // // TODO: Probably not useful, remove later
//   // permission: Array<{
//   //   workspaceRoleId: WorkspaceRoleEnum;
//   //   accessOptions: Array<DocumentPermissionEnum>;
//   // }>;
// }

export interface DocumentNotificationModel {
  heading: string;
  message: string;
}

export type DocumentDetailDataModel<D = { [key: string]: any }> = D & { [key: string]: any }; // dynamic object

export interface DocumentDetailUpdateModel extends DocumentSummary {
  pdfUrl?: string;
  expiry?: string;
  data: DocumentDetailDataModel;
  hash: string;
  version: number;
  documentWorkflowType: DocumentWorkflowTypeEnum;
}
export interface DocumentFormAndDetailModel extends WorkspaceDocumentDetailApiResponse {
  parsedData: DocumentDetailDataModel /* data (originally string) parsed to an object format */;
  parsedSchema?: JsonSchemaRootModel /* documentForm.schema (originally string) parsed to an object format */;
}

export interface AddAdditionalDocumentsRequestModel {
  workspaceId: string;
  participantId: string;
  workspaceDocument: WorkspaceDocumentModel;
}

export interface WorkspaceDocumentModel {
  documentIdentifier: DocumentTypeIdentifierEnum;
  roles: WorkspaceRoleEnum[];
}
// MR - check (AccessOptionEnum)
export enum DocumentWorkflowStepsEnum {
  Read = AccessOptionEnum.Read,
  Write = AccessOptionEnum.Write,
  Sign = AccessOptionEnum.Sign,
  Review = AccessOptionEnum.Review,
  // UI models only
  Unsign = 4,
  Resolve = 5, // * UI use only for edit document with issues
  Lodged = 6 // * UI use only for display lodged document
}

// Workflow step should be mapped to their respective next action label
export const DocumentWorkflowStepsEnumMap = new Map<DocumentWorkflowStepsEnum, string>([
  [DocumentWorkflowStepsEnum.Write, 'Edit'],
  [DocumentWorkflowStepsEnum.Sign, 'Sign'],
  [DocumentWorkflowStepsEnum.Review, 'Approve']
]);

// MR moved
// export enum DocumentPermissionEnum {
//   Read = 0,
//   Write = 1,
//   Sign = 2,
//   Review = 3,
//   Unsign = 4,
//   Remove = 5
// }

// MR moved
// export enum DocumentStatusEnum {
//   Awaiting = 0,
//   InPreparation = 1,
//   Reviewing = 2,
//   Signing = 3,
//   Signed = 4,
//   LodgementVerificationInProgress = 5,
//   LodgementInProgress = 6,
//   Lodged = 7,
//   Approved = 8,
//   ResolveIssues = 9,
//   LodgementInQueue = 10,
//   // ! this only has been used by shared doc which has multiple responsibilities
//   // those are inferred status based on the workflow/permission/document status
//   // and only been used in the ui view
//   AwaitingApproveFromOtherParticipant = 100
// }

// MR - moved
// export enum DocumentLodgementStatusEnum {
//   Lodged = 0,
//   Registered = 1,
//   Unnecessary = 2,
//   Withdrawn = 3,
//   Rejected = 4
// }

// MR moved
// export enum DocumentViewerTypeEnum {
//   FormGenerator = 0,
//   CustomReactForm = 1
// }

export const extendedDocumentWorkflowMapping: Partial<Record<DocumentStatusEnum, DocumentWorkflowStepsEnum>> = {
  [DocumentStatusEnum.InPreparation]: DocumentWorkflowStepsEnum.Write,
  [DocumentStatusEnum.Reviewing]: DocumentWorkflowStepsEnum.Review,
  [DocumentStatusEnum.Approved]: DocumentWorkflowStepsEnum.Review,
  [DocumentStatusEnum.Signing]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.Signed]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.LodgementVerificationInProgress]: DocumentWorkflowStepsEnum.Review,
  [DocumentStatusEnum.LodgementInProgress]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.Lodged]: DocumentWorkflowStepsEnum.Lodged,
  [DocumentStatusEnum.ResolveIssues]: DocumentWorkflowStepsEnum.Resolve
};

export const standardDocumentWorkflowMapping: Partial<Record<DocumentStatusEnum, DocumentWorkflowStepsEnum>> = {
  ...extendedDocumentWorkflowMapping,
  [DocumentStatusEnum.LodgementVerificationInProgress]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.Reviewing]: DocumentWorkflowStepsEnum.Sign,
  [DocumentStatusEnum.Approved]: DocumentWorkflowStepsEnum.Sign
};

export interface DocumentActionModel {
  type: DocumentActionTypeEnum;
  userId: string;
  participantId: string;
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  participantSubscriberName: string;
  createdDateTime: string;
}

export interface DocumentDenyPermissionModel<T = DocumentDenyPermissionReasonCodeEnum | AuthorisationDenyPermissionReasonCodeEnum> {
  documentPermissionType: DocumentPermissionEnum;
  reasonCode: LookupEnumModel<T>;
}

// MR - moved
// export enum DocumentDenyPermissionReasonCodeEnum {
//   DPD001_MissingWriteDocumentPermission = 1,
//   DPD002_MissingReviewDocumentPermission = 2,
//   DPD003_MissingSignDocumentPermission = 3,

//   DPD010_MissingWriteFinancialsPermission = 10,
//   DPD011_MissingReviewFinancialsPermission = 11,
//   DPD012_MissingSignFinancialsPermission = 12,

//   DPD025_OnlyAllowResponsibleParticipantReviewLodgementInstructions = 25,
//   DPD026_OnlyAllowResponsibleParticipantSignLodgementInstructions = 26,

//   DPD034_SeparateSigner = 34,
//   DPD035_SeparateSaver = 35,
//   DPD036_SeparateApproverAndSigner = 36,
//   DPD039_CurrentUserAlreadySigned = 39,
//   DPD040_OtherParticipantMustReviewFirst = 40,
//   DPD046_LodgementInstructionsPendingParticipantsAcceptingInvites = 46,
//   DPD047_LodgementVerificationFaultMustNotBeSigned = 47
// }

// This enum value is to determine different user action that has already been done for the document
// MR - this was replaced by DocumentActionTypeEnum
// export enum CompletedActionTypeEnum {
//   Save = 0,
//   Review = 1,
//   Sign = 2
// }

export interface DocumentWorkflowModel {
  isWorkspaceLocked: boolean;
  steps: Array<DocumentWorkflowStepsEnum>;
  requiredSigners: number;
}

export interface SaveDebitAccountApiRequest {
  workspaceId: string;
  participantId: string;
  debitAccountId: string;
  paymentSummaryModel: RegOnlyPaymentSummaryApiResponse;
}

export interface UpdateWorkspaceDocumentRequestModel {
  data: string;
  hash: string;
}

export interface DocumentSummary {
  documentActions: Array<DocumentActionModel>;
  documentId?: string;
  documentPermissions: Array<DocumentPermissionEnum>;
  documentWorkflow: DocumentWorkflowModel;
  status: DocumentStatusEnum;
  isFullySignedByAllParticipants: boolean;
  documentDenyPermissions: Array<DocumentDenyPermissionModel>;
}

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.Documents.UpdateDocumentResponse
 */
// export interface UpdateWorkspaceDocumentApiResponse extends DocumentSummary {
//   hash: string;
//   pdfUrl: string;
//   version: string;
//   documentList?: DocumentApiResponse[];
//   updatedData: string;
// }

export interface PanelDetails {
  primaryMessage?: string;
  secondaryMessage?: string;
  panelVariant?: PanelVariantEnum;
  priority: number;
}

export enum PanelVariantEnum {
  Warning = 0,
  Success = 1
}

export interface DocumentPrerequisite {
  isPrerequisiteNotReady?: boolean;
  prerequisiteError?: string;
}
