import React from 'react';

import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Select from '@sympli/ui-framework/components/form/base-components/select';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { modelKey } from 'src/utils/formUtils';
import { OffPlatformTaskStatusEnum } from './enums';
import { resolveOffPlatformTaskStatusName } from './helpers';
import { OffPlatformTaskModel } from './models';
import { useStyles } from './styles';

const fieldName = modelKey<OffPlatformTaskModel>();

export interface OwnProps {
  items: OffPlatformTaskModel[];
  disabled?: boolean;
  onUpdateStatus: (opTask: OffPlatformTaskModel, newStatus: OffPlatformTaskStatusEnum) => void;
}

export default function OffPlatformTasks({ items, disabled, onUpdateStatus }: OwnProps) {
  const classes = useStyles();

  const renderItem = (item: OffPlatformTaskModel) => {
    const { id, title, description, status } = item;
    let { availableStatuses } = item;
    if (!availableStatuses || availableStatuses.length === 0) {
      availableStatuses = [
        {
          id: status,
          name: resolveOffPlatformTaskStatusName(status)
        }
      ];
    }

    return (
      <FlexLayout flexDirection="row" className={classes.task} justifyContent="space-between" fullWidth key={`file-${id}`}>
        <FlexLayout flexDirection="column" className={classes.titleColumn}>
          <FlexLayout flexDirection="row">
            <span className={classes.title}>{title}</span>
          </FlexLayout>
          <FlexLayout flexDirection="row">
            <span className={classes.description}>{description}</span>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexDirection="column" className={classes.actionColumn}>
          <Select
            name={fieldName('status')}
            aria-label="off-platform task status"
            className={classes.statusSelect}
            placeholderColor="text"
            options={availableStatuses}
            value={status}
            disabled={disabled}
            onChange={(e, newStatus) => onUpdateStatus(item, newStatus)}
          />
        </FlexLayout>
      </FlexLayout>
    );
  };

  return (
    <>
      <Grid container={true} direction="row">
        <Typography variant="subtitle2" className={classes.titleColumn}>
          Document
        </Typography>
        <Typography variant="subtitle2" className={classNames(classes.actionColumn, classes.actionColumnTitle)}>
          Action
        </Typography>
      </Grid>
      {items.map(renderItem)}
    </>
  );
}
