import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    flexEvidence: {
      marginLeft: -30
    },
    removeButton: {
      marginRight: 15
    }
  }),
  {
    name: 'EvidenceArray'
  }
);
