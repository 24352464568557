import * as React from 'react';

import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { LookupItemModel } from '@sympli/ui-framework/models';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';

import { DeceasedTenancyPartyCapacityEnum } from '../deceased-tenancy-detail/enums';

interface Props {
  name: string;
  options: LookupItemModel<string>[];
  title: string;
  partyRole: 'Applicant' | 'Grantee';
  onChange?: (_, resolvedValue: DeceasedTenancyPartyCapacityEnum) => void;
  showDescription?: boolean;
}

function PartyRoleCapacity({ name, options, title, partyRole, onChange, showDescription }: Props) {
  const { disabled } = useDocumentContext();
  if (options && options.length === 1) return null;
  return (
    <Section title={title}>
      <FormGroup //
        title={`${partyRole} Capacity`}
        fieldAreaDirection="column"
        description={showDescription ? `This will apply to all the ${partyRole}s.` : null}
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={name}
            label="Party capacity"
            component={SelectField}
            disabled={disabled}
            format="string"
            options={options}
            placeholder={resolveSelectPlaceholder(true)}
            fullWidth
            onChange={onChange}
          />
        )}
      </FormGroup>
    </Section>
  );
}

export default React.memo(PartyRoleCapacity);
