// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      padding: 10
    },
    formGroup: {
      paddingTop: '20px !important',
      '& + &': {
        borderTop: 'none'
      }
    }
  }),
  {
    name: 'PriorityNotices'
  }
);
