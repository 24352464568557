import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconTimes } from '@sympli/ui-framework/icons';

import styles, { ClassKeys } from '../sharedStyles';

interface OwnProps {
  onClick(): void;
  isLoading?: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

function DeleteDocument({ onClick, classes, isLoading }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <IconTimes className={classes.menuItemIconRedTimes} />
      <Typography className={classes.menuItemLabel}>Delete document</Typography>
      {isLoading && <InlineLoader size={18} />}
    </MenuItem>
  );
}

export default withStyles(styles)(DeleteDocument);
