import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    createWorkspaceButton: {
      minWidth: 248,
      borderRadius: 34,
      padding: '16px 72px',
      maxHeight: 'none',
      lineHeight: '24px',
      fontWeight: 700,
      gap: 4
    },
    icon: {
      margin: 3
    }
  }),
  { name: 'CreateNewWorkspaceContainer' }
);
