import * as React from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconEditNew } from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';
import UserProfileAvatar from '../user-profile-avatar/UserProfileAvatar';
import styles, { ClassKeys } from './styles';

const RTL_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

interface OwnProps {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  subscriberName?: string;
  timezone?: string;
  onClose: (event?: React.SyntheticEvent<HTMLUListElement>) => void;
  onItemClick?: (event: React.SyntheticEvent<HTMLLIElement>, pathName: string) => void;
  anchorEl: any;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const UserProfileModal = ({ classes, id, anchorEl, email, name, firstName, lastName, avatarUrl, subscriberName, timezone, onClose }: Props) => {
  const navigate = useNavigate();
  const open = !!anchorEl;

  const handleMenuOnClose = () => {
    onClose && onClose();
  };

  const handleMenuOnProfileClick = () => {
    handleMenuOnClose();
    navigate('/personal-profile/details');
  };

  const handleMenuOnLogout = () => {
    handleMenuOnClose();
    navigate('/logout');
  };

  const timeZoneToDisplay = timezone?.split('_')[0];

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleMenuOnClose}
      anchorOrigin={RTL_ORIGIN}
      transformOrigin={RTL_ORIGIN}
      PaperProps={{
        elevation: 2,
        classes: {
          root: classes.paper
        }
      }}
      anchorEl={anchorEl}
    >
      <div className={classes.detailContainer}>
        <div className={classNames(classes.upArrow, 'medium-screen:right-[8px] large-screen:right-[10px]')}></div>
        <div className={classes.profileContainer}>
          <FlexLayout flexDirection="column" alignItems="center">
            <UserProfileAvatar to="/personal-profile/details" src={avatarUrl} firstName={firstName} lastName={lastName} />
            <FlexLayout flexDirection="column" alignItems="center">
              <Typography variant="subtitle1" className={classes.name}>
                {name}
              </Typography>
              <FlexLayout alignItems="center">
                <Typography className={classes.email}>{email}</Typography>
                <IconButton data-testid="edit-profile" color="default" className={classes.editIcon} onClick={handleMenuOnProfileClick}>
                  <IconEditNew fill={colors.NEUTRAL_600} height={20} width={20} />
                </IconButton>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <Grid container className={classes.marginTopSpace} spacing={2}>
            <Grid item xs={6} classes={{ root: classes.subscriberName }}>
              <span>{subscriberName}</span>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.timeZone }}>
              <Box display="flex" justifyContent="flex-end">
                <span>{timeZoneToDisplay} - Time zone</span>
              </Box>
            </Grid>
          </Grid>
        </div>

        <FlexLayout alignItems="center" flexDirection="column">
          <Button variant="outlined" className={classes.logoutButton} onClick={handleMenuOnLogout}>
            <Typography className={classes.logout}>Log out</Typography>
          </Button>
        </FlexLayout>
      </div>
    </Popover>
  );
};
export default withStyles(styles)(UserProfileModal);
