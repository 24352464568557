// this file was automatically generated from styles.ts.mustache
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {}
  }),
  {
    name: 'RootForm'
  }
);
