import React, { memo } from 'react';

import { useField } from 'formik';

import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

interface Props {
  name: string;
}

function ReasonField({ name }: Props) {
  const [field] = useField(name);
  const descriptionLength = (field.value ?? '').trim().length;
  const descriptionLengthText = `${descriptionLength} / 250 Characters`;

  return (
    <Field //
      label="Reason for deleting file"
      name={name}
      component={InputField}
      fullWidth
      multiline
      minRows={4}
      helperText={descriptionLengthText}
    />
  );
}

export default memo(ReasonField);
