import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    text: {
      marginLeft: theme.spacing(),
      overflow: 'hidden'
    },
    lastColumn: {
      // marginLeft: 'auto'
      marginLeft: 8
    },
    currentUserLabel: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px',
      display: 'inline-block'
    },
    name: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '16px'
    },
    description: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px'
    },
    withdrawButton: {
      width: 40,
      height: 40,
      marginRight: -16,
      '& svg': {
        fill: theme.palette.primary.main
      }
    },
    disabledParticipant: {
      color: theme.palette.text.disabled
    },
    invitationStatusLabel: {
      padding: '0px 3px',
      borderRadius: 2,
      height: 15,
      marginLeft: 4,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      fontSize: 11,
      lineHeight: '13px'
    },
    a: {
      textDecoration: 'none'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
