import * as React from 'react';

import { validateABN } from 'au-bn-validator';
import { FieldProps, getIn } from 'formik';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Input, { InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';

import http from 'src/utils/http';
import { resolveDataBindingReference } from '../../modules/helpers';
import styles, { ClassKeys } from './styles';

interface OwnProps extends InputComponentProps {
  acnName?: string;
}

interface State {
  isLoading: boolean;
}

type Props = OwnProps & FieldProps & WithStyles<ClassKeys>;

interface AbnLookupApiResponse {
  abn: string;
  organisationName: string;
  acn: string;
  entityType: string;
  status: string;
}

class AbnField extends React.PureComponent<Props, State> {
  static defaultProps: Partial<Props> = {
    acnName: 'acn'
  };

  public readonly state: Readonly<State> = {
    isLoading: false
  };

  static uri: string = '/lookups/abn';

  private _mounted: boolean = false;

  private get acnName() {
    const {
      field: { name },
      acnName
    } = this.props;

    return resolveDataBindingReference(name, acnName!);
  }

  componentDidMount() {
    this._mounted = true;
    const { field, form } = this.props;
    const { value = '' } = field;
    if (value.length === 11 && validateABN(value) && !getIn(form.values, this.acnName)) {
      this.executeAbnLookup(value);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps: Props) {
    const {
      field: { value = '' }
    } = this.props;

    if (prevProps.field.value !== value && value.length === 11 && validateABN(value)) {
      this.executeAbnLookup(value);
    }
  }

  private executeAbnLookup = (value: string) => {
    const { form } = this.props;
    const svc = `${AbnField.uri}/${encodeURIComponent(value)}`;

    this.setState({ isLoading: true }, () => {
      http
        .get<AbnLookupApiResponse>(svc) //
        .then(item => {
          if (this._mounted) {
            if (item.abn === value) {
              form.setFieldValue(this.acnName, item.acn);
            }
          }
        })
        .finally(() => {
          if (this._mounted) {
            this.setState(() => ({
              isLoading: false
            }));
          }
        });
    });
  };

  get error() {
    const {
      field: { name },
      form: { touched, errors }
    } = this.props;

    const error: string | undefined = getIn(touched, name) && (getIn(errors, name) as any);
    return error;
  }

  render() {
    const {
      classes,
      form,
      field: { name, value },
      label,
      acnName,
      ...rest
    } = this.props;

    return (
      <Input //
        label={label}
        {...rest}
        error={this.error}
        name={name}
        showLoader={this.state.isLoading}
        onBlur={this.handleOnBlur}
        defaultValue={value}
      />
    );
  }

  private handleOnBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, resolvedValue: any) => {
    const { form, field } = this.props;
    const name = field.name;

    if (field.value !== resolvedValue) {
      // mutate data directly since we are triggering onChange that will internally reset the state
      field.onChange(event); // this will trigger validation
    }
    // make sure that we trigger validation if validateOnBlur is on
    // which will happen automatically whenever field.onBlur is called
    if (form.validateOnBlur || !getIn(form.touched, name)) {
      field.onBlur(event);
    }
  };
}

const styledComponent = withStyles(styles)(AbnField);
export default styledComponent;
