import { call, put, takeLatest } from 'typed-redux-saga';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { resolveGeneralErrorMessageByStatusCode } from 'src/sagas/globalErrors';
import { GeneralSagasErrorModel } from 'src/store/globalErrorMiddleware';
import http, { NoResponseData } from 'src/utils/http';
import {
  actionDeleteReport,
  actionFetchProfileUserReportsPermissions,
  actionFetchReportsDashboard,
  actionFetchScheduledReportHistory,
  actionUpdateReportsInRedux
} from './actions';
import { fetchUserReportsPermissionsDetail } from './api';
import {
  DeleteReportApiRequest,
  FetchReportsDashboardApiResponse,
  FetchScheduledReportHistoryApiRequest,
  FetchScheduledReportHistoryApiResponse,
  ReportFeatureTypeEnum,
  SavedReportModel
} from './models';

const deleteReport = (payload: DeleteReportApiRequest<SavedReportModel>) => {
  const url =
    payload.reportFeatureType === ReportFeatureTypeEnum.Favourite
      ? `/reports/favouriteReportParameters/${encodeURIComponent(payload.id)}`
      : `/reports/scheduleReportParameters/${encodeURIComponent(payload.id)}`;
  return http.delete<NoResponseData>(url);
};

function* sagaDeleteReport(action: ReturnType<typeof actionDeleteReport.request>) {
  const { asyncDialogDispatch } = action.payload;
  try {
    asyncDialogDispatch({ type: 'submit' });
    yield* call(deleteReport, action.payload);

    const data = action.payload.id;
    // delete the record from the redux store, avoid re-fetch data from backend
    yield put(actionUpdateReportsInRedux({ data }));

    // close the dialog, can pass data or if don't want to close the dialog, can define type to succeed
    asyncDialogDispatch({ type: 'close' });

    const message = `Report has been deleted`;
    yield put(
      actionOpenGlobalSimpleNotification({
        message,
        autoHideDuration: 5000,
        variant: 'new-success'
      })
    );
  } catch (error) {
    // close the dialog, can define type to others to stop closing the dialog
    asyncDialogDispatch({ type: 'close', error: resolveGeneralErrorMessageByStatusCode(error) });
    if (error.response?.status === 404) {
      // flexible error handling message
      yield put(
        actionCreateGlobalErrorMessage({
          //
          message: 'The report that you are trying to access has been deleted and is no longer available.',
          title: 'Report has been deleted'
        })
      );
      // re-fetch reports data again when this kind of error happens
      yield put(actionFetchReportsDashboard.request());
    } else {
      // general error handling
      yield put(actionCreateGlobalErrorMessage(error));
    }
  }
}

const fetchReportsDashboard = () => {
  const uri = `/reports`;

  return http.get<FetchReportsDashboardApiResponse>(uri);
};

export function* sagaFetchReportsDashboard(action: ReturnType<typeof actionFetchReportsDashboard.request>) {
  try {
    const data = yield* call(fetchReportsDashboard);

    yield put(actionFetchReportsDashboard.success({ data }));
  } catch (error) {
    yield put(actionFetchReportsDashboard.failure({ error }));
  }
}

const fetchScheduledReportHistory = (payload: FetchScheduledReportHistoryApiRequest) => {
  const uri = `/reports/scheduleReportParameters/${encodeURIComponent(payload.id)}`;

  return http.get<FetchScheduledReportHistoryApiResponse>(uri);
};

export function* sagaFetchScheduledReportHistory(action: ReturnType<typeof actionFetchScheduledReportHistory.request>) {
  try {
    const data = yield* call(fetchScheduledReportHistory, action.payload);

    yield put(actionFetchScheduledReportHistory.success({ data }));
  } catch (error) {
    let errorPayload: GeneralSagasErrorModel;
    switch (error.response.status) {
      case 403:
        errorPayload = { error, redirectOnClose: '/reports' };
        break;
      case 404:
        errorPayload = { error, title: 'Report has been deleted', redirectOnClose: '/reports' };
        break;
      default:
        errorPayload = { error };
    }

    yield put(actionFetchScheduledReportHistory.failure(errorPayload));
  }
}

export function* sagaFetchProfileUserReportsPermissionsDetail(action: ReturnType<typeof actionFetchProfileUserReportsPermissions.request>) {
  try {
    const data = yield* call(fetchUserReportsPermissionsDetail, action.payload);

    yield put(actionFetchProfileUserReportsPermissions.success({ data }));
  } catch (error) {
    yield put(actionFetchProfileUserReportsPermissions.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionDeleteReport.request, sagaDeleteReport),
  takeLatest(actionFetchReportsDashboard.request, sagaFetchReportsDashboard),
  takeLatest(actionFetchScheduledReportHistory.request, sagaFetchScheduledReportHistory),
  takeLatest(actionFetchProfileUserReportsPermissions.request, sagaFetchProfileUserReportsPermissionsDetail)
];
