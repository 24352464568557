import * as React from 'react';

import classNames from 'classnames';

import { hasV2Flag } from 'src/containers/workspace/shared/detail/helpers';
import { AppEnvironmentEnum } from 'src/environments/models';
import { LOCAL_ENV } from 'src/environments/resolveRunTimeEnv';
import { randomPaletteColor } from './randomPaletteColor';
import { useStyles } from './styles';

interface Props {
  environmentName: AppEnvironmentEnum;
  className?: string;
}

const EnvironmentLabel = ({ environmentName, className }: Props) => {
  const classes = useStyles();

  if (shouldShowEnvironmentLabel(environmentName)) {
    const labelColor = randomPaletteColor({ seed: environmentName });

    return (
      <div className={classNames(classes.labelBox, className)} style={{ backgroundColor: labelColor }}>
        <span className={classes.environmentLabel}>{environmentName === AppEnvironmentEnum.local ? `local-${LOCAL_ENV}`.toUpperCase() : environmentName.toUpperCase()}</span>
        {hasV2Flag() && <span className={classes.v2Label}>v2</span>}
      </div>
    );
  }

  return <div className={classes.labelBox} />;
};

const shouldShowEnvironmentLabel = (environmentName: string) => {
  return !['production'].includes(environmentName);
};

export default React.memo(EnvironmentLabel);
