import { ConditionalLookupEnumModel } from '@sympli-mfe/document-forms-framework/utils';
import { AdditionalAttachmentsDocumentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { Claim, ExtentOfProhibition } from './models';

export enum ActionProhibitedEnum {
  ActionProhibited1 = 1,
  ActionProhibited2 = 2,
  ActionProhibited3 = 3,
  ActionProhibited4 = 4,
  ActionProhibited5 = 5,
  ActionProhibited6a = 6,
  ActionProhibited6b = 7,
  ActionProhibited7 = 8
}

function isCurrentSelectionOrNotSelectedBySibling(targetValue: number, currentSelection: number | null | undefined, prohibitionArray: ExtentOfProhibition[]): boolean {
  if (currentSelection === targetValue) {
    return true;
  }
  return prohibitionArray.every(extentOfProhibition => extentOfProhibition.actionProhibitedOption !== targetValue);
}

function siblingsDoNotContain(targetValue: number, currentProhibition: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean {
  const siblingsExcludingSelf = prohibitionArray.filter(item => item !== currentProhibition);
  return siblingsExcludingSelf.every(({ actionProhibitedOption }) => actionProhibitedOption !== targetValue);
}

export const ENUM_ACTION_PROHIBITED_OPTIONS: ConditionalLookupEnumModel<number>[] = [
  {
    id: 1,
    value: 'ActionProhibited1',
    name: '1. The recording in the Register of any dealing other than a plan affecting the estate or interest claimed by the Caveator.',
    _isVisible: (extentOfProhibition: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean => {
      const currentSelection = extentOfProhibition.actionProhibitedOption;
      return siblingsDoNotContain(5, extentOfProhibition, prohibitionArray) && isCurrentSelectionOrNotSelectedBySibling(1, currentSelection, prohibitionArray);
    }
  },
  {
    id: 2,
    value: 'ActionProhibited2',
    name: '2. The registration or recording of any plan other than a delimitation plan affecting the estate or interest claimed by the Caveator.',
    _isVisible: ({ actionProhibitedOption: currentSelection }: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean =>
      isCurrentSelectionOrNotSelectedBySibling(2, currentSelection, prohibitionArray)
  },
  {
    id: 3,
    value: 'ActionProhibited3',
    name: '3. The registration of delimitation plan:',
    _isVisible: ({ actionProhibitedOption: currentSelection }: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean =>
      isCurrentSelectionOrNotSelectedBySibling(3, currentSelection, prohibitionArray)
  },
  {
    id: 4,
    value: 'ActionProhibited4',
    name: '4. The granting of any possessory application with respect to the land in the Torrens Title referred to above.',
    _isVisible: ({ actionProhibitedOption: currentSelection }: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean =>
      isCurrentSelectionOrNotSelectedBySibling(4, currentSelection, prohibitionArray)
  },
  {
    id: 5,
    value: 'ActionProhibited5',
    name: '5. The recording in the Register of any dealing affecting the estate of which the Caveator is Registered Proprietor.',
    _isVisible: (extentOfProhibition: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean => {
      const { actionProhibitedOption: currentSelection } = extentOfProhibition;
      return siblingsDoNotContain(1, extentOfProhibition, prohibitionArray) && isCurrentSelectionOrNotSelectedBySibling(5, currentSelection, prohibitionArray);
    }
  },
  {
    id: 6,
    value: 'ActionProhibited6a',
    name: '6a. The granting of an application to extinguish the restrictive covenant created by:',
    _isVisible: ({ actionProhibitedOption: currentSelection }: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean =>
      isCurrentSelectionOrNotSelectedBySibling(6, currentSelection, prohibitionArray)
  },
  {
    id: 7,
    value: 'ActionProhibited6b',
    name: '6b. The granting of an application to extinguish the easement created by:',
    _isVisible: ({ actionProhibitedOption: currentSelection }: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean =>
      isCurrentSelectionOrNotSelectedBySibling(7, currentSelection, prohibitionArray)
  },
  {
    id: 8,
    value: 'ActionProhibited7',
    name: '7. The recording in the Register of a Writ affecting the estate or interest claimed by the Caveator.',
    _isVisible: ({ actionProhibitedOption: currentSelection }: ExtentOfProhibition, prohibitionArray: ExtentOfProhibition[]): boolean =>
      isCurrentSelectionOrNotSelectedBySibling(8, currentSelection, prohibitionArray)
  }
];

export enum EstateOfInterestClaimedEnum {
  Charge = 0,
  CovenantCharge = 1,
  Easement = 2,
  EstateInFeeSimple = 3,
  EstateInRemainder = 4,
  ForestryRight = 5,
  Lease = 6,
  Lien = 7,
  LifeEstate = 8,
  Mortgage = 9,
  PublicPositiveConvenant = 10,
  PositiveCovenantForMaintenanceAndRepair = 11,
  ProfitAPrendre = 12,
  RegisteredProprietor = 13,
  RestrictiveCovenant = 14,
  StatutoryCharge = 15
}

export const ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: 3,
    value: 'EstateInFeeSimple',
    name: 'Estate In Fee Simple'
  },
  {
    id: 9,
    value: 'Mortgage',
    name: 'Mortgage'
  },
  {
    id: 6,
    value: 'Lease',
    name: 'Lease'
  },
  {
    id: 0,
    value: 'Charge',
    name: 'Charge'
  },
  {
    id: 15,
    value: 'StatutoryCharge',
    name: 'Statutory Charge'
  },
  {
    id: 4,
    value: 'EstateInRemainder',
    name: 'Estate In Remainder'
  },
  {
    id: 8,
    value: 'LifeEstate',
    name: 'Life Estate'
  },
  {
    id: 13,
    value: 'RegisteredProprietor',
    name: 'Registered Proprietor'
  },
  {
    id: 2,
    value: 'Easement',
    name: 'Easement'
  },
  {
    id: 14,
    value: 'RestrictiveCovenant',
    name: 'Restrictive Covenant'
  },
  {
    id: 11,
    value: 'PositiveCovenantForMaintenanceAndRepair',
    name: 'Positive Covenant For Maintenance And Repair'
  },
  {
    id: 10,
    value: 'PublicPositiveConvenant',
    name: 'Public Positive Covenant'
  },
  {
    id: 12,
    value: 'ProfitAPrendre',
    name: 'Profit a Prendre'
  },
  {
    id: 1,
    value: 'CovenantCharge',
    name: 'Covenant Charge'
  },
  {
    id: 7,
    value: 'Lien',
    name: 'Lien'
  },
  {
    id: 5,
    value: 'ForestryRight',
    name: 'Forestry Right'
  }
];

export enum ClaimCategoryEnum {
  AdversePossession = 0,
  Agreement = 1,
  AppointmentAsExecutorOrAdministrator = 2,
  BankruptcyTrustee = 3,
  BeneficialInterestInDeceasedEstate = 4,
  BeneficialInterestInTrust = 5,
  ChargeOfEstateInFeeSimple = 6,
  ContractForSaleOfLand = 7,
  CourtOrder = 8,
  DepositedPlan = 9,
  DeterminationOfTitleBoundary = 10,
  LeaseOfEstateInFeeSimple = 11,
  LoanAgreement = 12,
  MortgageOfEstateInFeeSimple = 13,
  PositiveCovenant = 14,
  RestrictionOnTheUseOfLand = 15,
  StatutoryAuthorisation = 16,
  StrataPlan = 17,
  Transfer = 18,
  TransferCreatingAForestryRight = 19,
  TransferCreatingAProfitAPrendre = 20,
  TransferGrantingEasement = 21,
  TransferIncludingCovenant = 22,
  TransferIncludingEasement = 23,
  TransmissionApplication = 24,
  TrusteeOfTrust = 25
}

export const ENUM_CLAIM_CATEGORY_OPTIONS: ConditionalLookupEnumModel<number>[] = [
  {
    id: 0,
    value: 'AdversePossession',
    name: 'Adverse Possession',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3
  },
  {
    id: 1,
    value: 'Agreement',
    name: 'Agreement',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [0, 2, 3, 4, 6, 7, 11, 12, 14, 8];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 2,
    value: 'AppointmentAsExecutorOrAdministrator',
    name: 'Appointment As Executor Or Administrator',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3
  },
  {
    id: 3,
    value: 'BankruptcyTrustee',
    name: 'Bankruptcy (Trustee)',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [3, 4, 8];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 4,
    value: 'BeneficialInterestInDeceasedEstate',
    name: 'Beneficial Interest In Deceased Estate',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [3, 4, 8];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 5,
    value: 'BeneficialInterestInTrust',
    name: 'Beneficial Interest In Trust',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [3, 4, 8];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 6,
    value: 'ChargeOfEstateInFeeSimple',
    name: 'Charge Of Estate In Fee Simple',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 0 || claimDetails.estateOrInterestClaimed === 15
  },
  {
    id: 7,
    value: 'ContractForSaleOfLand',
    name: 'Contract For Sale Of Land',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3 || claimDetails.estateOrInterestClaimed === 7
  },
  {
    id: 8,
    value: 'CourtOrder',
    name: 'Court Order',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [0, 1, 2, 3, 4, 5, 7, 9, 11, 12, 10, 14, 15, 6, 8];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 9,
    value: 'DepositedPlan',
    name: 'Deposited Plan',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [2, 5, 11, 12, 10, 14];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 10,
    value: 'DeterminationOfTitleBoundary',
    name: 'Determination Of Title Boundary',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3
  },
  {
    id: 11,
    value: 'LeaseOfEstateInFeeSimple',
    name: 'Lease Of Estate In Fee Simple',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 6
  },
  {
    id: 12,
    value: 'LoanAgreement',
    name: 'Loan Agreement',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 9
  },
  {
    id: 13,
    value: 'MortgageOfEstateInFeeSimple',
    name: 'Mortgage Of Estate In Fee Simple',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 9
  },
  {
    id: 14,
    value: 'PositiveCovenant',
    name: 'Positive Covenant',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 10
  },
  {
    id: 15,
    value: 'RestrictionOnTheUseOfLand',
    name: 'Restriction On The Use Of Land',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 14
  },
  {
    id: 16,
    value: 'StatutoryAuthorisation',
    name: 'Statutory Authorisation',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3 || claimDetails.estateOrInterestClaimed === 15
  },
  {
    id: 17,
    value: 'StrataPlan',
    name: 'Strata Plan',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [2, 11, 12, 10, 14];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 18,
    value: 'Transfer',
    name: 'Transfer',
    _isVisible: (claimDetails: Claim): boolean => {
      const allowedValues: EstateOfInterestClaimedEnum[] = [3, 4, 8];
      return allowedValues.includes(claimDetails.estateOrInterestClaimed as number);
    }
  },
  {
    id: 19,
    value: 'TransferCreatingAForestryRight',
    name: 'Transfer Creating A Forestry Right',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 5
  },
  {
    id: 20,
    value: 'TransferCreatingAProfitAPrendre',
    name: 'Transfer Creating A Profit A Prendre',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 12
  },
  {
    id: 21,
    value: 'TransferGrantingEasement',
    name: 'Transfer Granting Easement',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 2 || claimDetails.estateOrInterestClaimed === 11
  },
  {
    id: 22,
    value: 'TransferIncludingCovenant',
    name: 'Transfer Including Covenant',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 14
  },
  {
    id: 23,
    value: 'TransferIncludingEasement',
    name: 'Transfer Including Easement',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 2 || claimDetails?.estateOrInterestClaimed === 11
  },
  {
    id: 24,
    value: 'TransmissionApplication',
    name: 'Transmission Application',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3
  },
  {
    id: 25,
    value: 'TrusteeOfTrust',
    name: 'Trustee Of Trust',
    _isVisible: (claimDetails: Claim): boolean => claimDetails.estateOrInterestClaimed === 3
  }
];

export enum ClaimPartyCapacityEnum {
  None = -1,
  Administrator = 0
  // TODO add rest of ENUM_CLAIM_PARTY_CAPACITY values
}

export const ENUM_CLAIM_PARTY_CAPACITY_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: -1,
    value: 'None',
    name: 'None'
  },
  {
    id: 0,
    value: 'Administrator',
    name: 'Administrator'
  },
  {
    id: 1,
    value: 'Beneficiary',
    name: 'Beneficiary'
  },
  {
    id: 2,
    value: 'Borrower',
    name: 'Borrower'
  },
  {
    id: 3,
    value: 'Chargor',
    name: 'Chargor'
  },
  {
    id: 4,
    value: 'Chargee',
    name: 'Chargee'
  },
  {
    id: 5,
    value: 'Devisee',
    name: 'Devisee'
  },
  {
    id: 6,
    value: 'Executor',
    name: 'Executor'
  },
  {
    id: 7,
    value: 'Lessee',
    name: 'Lessee'
  },
  {
    id: 8,
    value: 'Lessor',
    name: 'Lessor'
  },
  {
    id: 9,
    value: 'Mortgagor',
    name: 'Mortgagor'
  },
  {
    id: 10,
    value: 'Mortgagee',
    name: 'Mortgagee'
  },
  {
    id: 11,
    value: 'NextOfKin',
    name: 'Next Of Kin'
  },
  {
    id: 12,
    value: 'PartyBenefiting',
    name: 'Party Benefiting'
  },
  {
    id: 13,
    value: 'PartyBurdened',
    name: 'Party Burdened'
  },
  {
    id: 14,
    value: 'PossessoryApplicant',
    name: 'Possessory Applicant'
  },
  {
    id: 15,
    value: 'Purchaser',
    name: 'Purchaser'
  },
  {
    id: 16,
    value: 'PurchaserAndBorrower',
    name: 'Purchaser And Borrower'
  },
  {
    id: 17,
    value: 'RegisteredProprietorOfEstate',
    name: 'Registered Proprietor Of Estate'
  },
  {
    id: 18,
    value: 'Transferee',
    name: 'Transferee'
  },
  {
    id: 19,
    value: 'TransfereeAndChargor',
    name: 'Transferee And Chargor'
  },
  {
    id: 20,
    value: 'TransfereeAndLessor',
    name: 'Transferee And Lessor'
  },
  {
    id: 21,
    value: 'TransfereeAndMortgagor',
    name: 'Transferee And Mortgagor'
  },
  {
    id: 22,
    value: 'Transferor',
    name: 'Transferor'
  },
  {
    id: 23,
    value: 'Vendor',
    name: 'Vendor'
  }
];

export const SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: AdditionalAttachmentsDocumentTypeEnum.ClaimDetails,
    name: 'Claim Details'
  }
];
