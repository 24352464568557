import { SaNameChange } from '@sympli-mfe/document-components/party-form/sa/2-19/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { SA_WOC_PARTY_FORM_CONFIG } from '../../config';
import { getSelectedCaveat } from '../../helper';
import { CaveatModel, WithdrawalOfCaveat2_19_1Model } from '../../models';

// import * as yup from 'yup';
interface PartyBookValidationContext {
  withdrawalOfCaveats: CaveatModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: WithdrawalOfCaveat2_19_1Model): PartyBookValidationContext => {
  return {
    withdrawalOfCaveats: parentContext.withdrawCaveats
  };
};

const checkShouldValidateItem = ({ withdrawalOfCaveats }: PartyBookValidationContext, partyIdForCheck: PartyModel<SaNameChange>['id']) => {
  const selectedCaveat = getSelectedCaveat(withdrawalOfCaveats);
  const selectedCaveatorsIds = selectedCaveat?.caveators.map(x => x.partyBookId) ?? [];
  const isSelectedCaveator = !!~selectedCaveatorsIds.indexOf(partyIdForCheck);
  return isSelectedCaveator;
};

const yupPartyItem = resolvePartyModelValidationSchema<SaNameChange, PartyBookValidationContext>(SA_WOC_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<SaNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
