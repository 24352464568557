import React from 'react';

import classNames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import MessageList from '@sympli/ui-framework/components/form/base-components/message-list';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import PartyRoleCapacity from '../party-role-capacity';
import DeceasedProprietorArray from './components/deceased-proprietor-array';
import { DeceasedTenancyConfig } from './deceasedTenancyConfig';
import { DeceasedTenancyPartyCapacityEnum } from './enums';
import { haveOrganisationPartyInGroup, isDisabledGroup, updateProprietorWithCapacity } from './helpers';
import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorGroupModel, DeceasedProprietorModel, DeceasedTenancyDetailModel } from './models';
import { useStyles } from './styles';

interface Props<TNameChange extends {}> {
  partyFormConfig: PartyFormConfig<TNameChange>;
}

type SectionProps<TNameChange extends {}> = Props<TNameChange> & {
  deceasedTenancyConfig: DeceasedTenancyConfig;
};

const fieldName = modelKey<BaseDeceasedTenancyDetailComponentModel>();
const deceasedTenancySectionName = fieldName('deceasedTenancyDetail');

function DeceasedTenancyDetail<TNameChange extends {}>({ partyFormConfig, deceasedTenancyConfig }: SectionProps<TNameChange>) {
  const fieldName = createModelKeyAppender<DeceasedTenancyDetailModel>(deceasedTenancySectionName);
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { partyBook, deceasedTenancyDetail, mergeFailedReason } = formikProps.values;
  const classes = useStyles();

  const { disabled } = useDocumentContext();

  const handleSelectAllProprietorsChange =
    (itemBinding: string) =>
    (_: React.ChangeEvent<HTMLInputElement>, isSelected: boolean): void => {
      const groupItems: DeceasedProprietorGroupModel = getIn(formikProps.values, itemBinding) ?? [];
      let updatedItems: DeceasedProprietorGroupModel = {
        ...groupItems,
        parties: groupItems.parties.map(p => ({ ...p, isSelected: false })),
        isSelected: isSelected
      };
      formikProps.setFieldValue(itemBinding, updatedItems);
    };

  const hasNonJustifiedParties = !deceasedTenancyConfig.getLegalEntityName || !PartyValidations.verifyJustification(partyBook, deceasedTenancyConfig.getLegalEntityName);
  const hasMergedFailedReason = mergeFailedReason !== undefined && mergeFailedReason !== MergeFailedReasonsEnum.None;

  const renderItem = ({ itemBinding, itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorGroupModel>) => {
    const numberOfPartiesInPreceedingGroups = deceasedTenancyDetail.proprietorGroups.slice(0, itemIndex).flatMap(group => group.parties ?? []).length;
    const isJointTenants = deceasedTenancyDetail.tenancyType === TenancyTypeEnum.JointTenants || item.proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse;
    const isFirstGroup = itemIndex === 0;
    const disabledCheckbox =
      disabled ||
      (deceasedTenancyConfig.disableJointTenants && isJointTenants) ||
      (deceasedTenancyConfig.disableProprietorCapacity && item.parties.some(p => p.partyCapacity?.capacity)) ||
      haveOrganisationPartyInGroup(item, partyBook) ||
      isDisabledGroup(deceasedTenancyDetail.proprietorGroups, item.isSelected) ||
      hasNonJustifiedParties ||
      hasMergedFailedReason;

    const groupFieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);

    return (
      <SectionLike //
        className={classNames(isFirstGroup && classes.deceasedGroupFirst, classes.marginBottomIfNotLast)}
        key={itemBinding}
        data-testid={itemBinding}
      >
        <div key={itemBinding} className={classes.bottomDivider}>
          {isJointTenants && (
            <div className={classes.jointTenants}>
              {deceasedTenancyConfig.disableJointTenants && deceasedTenancyDetail.tenancyType === TenancyTypeEnum.TenantsInCommon && (
                <MessageList dataErrorName={'select-all-parties-error'} messages={[{ message: 'Joint Tenants are not supported in this document', type: 'error' }]} />
              )}
              <Field
                name={groupFieldName('isSelected')}
                component={CheckboxField}
                aria-labelledby="selectAllParties"
                label={'Joint Tenants'}
                format="boolean"
                className={classes.jointTenantCheckbox}
                onChange={handleSelectAllProprietorsChange(itemBinding)}
                disabled={disabledCheckbox}
                data-testid={'select-all-parties'}
                validationOverflowDirection="horizontal"
              />
            </div>
          )}
          <DeceasedProprietorArray //
            itemBinding={itemBinding}
            numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
            partyFormConfig={partyFormConfig}
            disabledCheckbox={disabledCheckbox}
            deceasedTenancyConfig={deceasedTenancyConfig}
          />
        </div>
      </SectionLike>
    );
  };

  return (
    <DocumentFieldArray //
      arrayBinding={fieldName('proprietorGroups')}
      renderItem={renderItem}
      disabled={disabled}
      itemStyle="none"
      mode="fixed"
    />
  );
}

function SectionDeceasedTenancyDetail<TNameChange extends {}>({ partyFormConfig, deceasedTenancyConfig }: SectionProps<TNameChange>): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { setFieldValue } = formikProps;
  const {
    deceasedTenancyDetail: { proprietorGroups }
  } = formikProps.values;

  const sectionErrorMessage = getFormikError(formikProps, deceasedTenancySectionName, true);
  const sectionTitle = `Deceased registered ${pluralize('party', proprietorGroups.flatMap(group => group.parties).length)}`;

  const deceasedTenancyDetailFieldName = createModelKeyAppender<DeceasedTenancyDetailModel>(fieldName('deceasedTenancyDetail'));
  const handleApplicantCapacityChange = React.useCallback(
    (_, applicantCapacity: DeceasedTenancyPartyCapacityEnum) => {
      if (proprietorGroups.some(pg => pg.isSelected)) {
        const updatedProprietorGroups = proprietorGroups.map(group => {
          if (!group.isSelected) {
            return group;
          }
          return {
            ...group,
            parties: group.parties.map((party): DeceasedProprietorModel => updateProprietorWithCapacity(party, party.isSelected, applicantCapacity))
          };
        });
        setFieldValue(deceasedTenancyDetailFieldName('proprietorGroups'), updatedProprietorGroups);
      }
    },
    [deceasedTenancyDetailFieldName, proprietorGroups, setFieldValue]
  );

  return (
    <>
      <PartyRoleCapacity
        name={fieldName('applicantCapacity')}
        options={deceasedTenancyConfig.partyCapacities}
        title="Complete on behalf of"
        partyRole="Applicant"
        onChange={handleApplicantCapacityChange}
        showDescription={deceasedTenancyConfig.showPartyRoleCapacityDescription}
      />

      <Section //
        title={sectionTitle}
        className={classNames(proprietorGroups.length && classes.section)}
        error={sectionErrorMessage}
        data-name={deceasedTenancySectionName}
        data-testid={deceasedTenancySectionName}
      >
        {!proprietorGroups.length ? (
          <Typography className={classes.noDeceasedGroupsMessage}>There are no deceased registered parties to display. Please ensure that a title is selected.</Typography>
        ) : (
          <DeceasedTenancyDetail partyFormConfig={partyFormConfig} deceasedTenancyConfig={deceasedTenancyConfig} />
        )}
      </Section>
    </>
  );
}

export default React.memo(SectionDeceasedTenancyDetail);
