import { Action, createReducer } from 'typesafe-actions';

import { MfaLinkedDeviceModel } from 'src/containers/personal-profile/multi-factor-auth-devices/api';
import { actionFetchUserLinkedDevicesFeed, actionUpdateConfirmDialog, ConfirmDialogModel, DELETE_USER_LINKED_DEVICE } from '../actions/users';

export interface UserLinkedDevicesFeedState {
  items: Array<MfaLinkedDeviceModel>;
  confirmDialog: ConfirmDialogModel;
  isLoading: boolean;
  error?: string;
}

const initialState: UserLinkedDevicesFeedState = {
  items: [],
  confirmDialog: {
    isLoading: false,
    isOpen: false
  },
  isLoading: false,
  error: undefined
};

const reducer = createReducer<
  //
  UserLinkedDevicesFeedState,
  Action
>(initialState)
  .handleAction(actionFetchUserLinkedDevicesFeed.request, (state): UserLinkedDevicesFeedState => {
    return {
      ...state,
      error: undefined,
      isLoading: true
    };
  })
  .handleAction(actionFetchUserLinkedDevicesFeed.success, (state, action): UserLinkedDevicesFeedState => {
    return {
      ...state,
      items: action.payload.data,
      isLoading: false
    };
  })
  .handleAction(actionFetchUserLinkedDevicesFeed.failure, (state, action): UserLinkedDevicesFeedState => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateConfirmDialog, (state, action): UserLinkedDevicesFeedState => {
    return {
      ...state,
      confirmDialog: {
        ...state.confirmDialog,
        ...action.payload
      }
    };
  })
  .handleAction(DELETE_USER_LINKED_DEVICE.ACTION, (state): UserLinkedDevicesFeedState => {
    return {
      ...state,
      confirmDialog: {
        ...state.confirmDialog,
        isLoading: true
      }
    };
  })
  .handleAction(DELETE_USER_LINKED_DEVICE.SUCCESS, (state, action): UserLinkedDevicesFeedState => {
    const { payload } = action;

    if (payload == null) {
      return state;
    }

    return {
      ...initialState,
      items: state.items.filter((item: MfaLinkedDeviceModel) => item.id !== payload.data.deviceId)
    };
  })
  .handleAction(DELETE_USER_LINKED_DEVICE.ERROR, (state, action): UserLinkedDevicesFeedState => {
    return {
      ...state,
      confirmDialog: {
        ...state.confirmDialog,
        isLoading: false
      },
      error: action.payload.error.message
    };
  });

export default reducer;
