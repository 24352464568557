import http from 'src/utils/http';

export interface MfaDataApiResponse {
  code: string;
  qrCode: string;
}

export const getQrCode = () => http.get<MfaDataApiResponse>('/profile/link-device');

export interface FactorStatusApiResponse {
  isActive: boolean;
  factorId: string;
}

export const pollFactorStatus = (factorId: string) => http.get<FactorStatusApiResponse>(`/mfa/factor/${factorId}/enroll-status`);
