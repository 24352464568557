import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import {
  TitleReferenceModel as BaseTitleReferenceModel,
  PartLandAffectedWithDescriptionsModel
} from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ApiDocumentPartyModel, ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

// this file was automatically generated from models.ts.mustache
export interface ApiForm24B_2_24_1Model {
  partyBook: ApiDocumentPartyModel[];
  addressBook: any;
  titleReferences: TitleReferenceModel[];
  transferors: TransferorReferenceModel[];
  transferorsAddress: TransferorsAddressModel;
  salePrice: SalePriceModel;
  property: PropertyDataModel;
  transaction: TransactionDataModel;
}

export type TitleReferenceModel = BaseTitleReferenceModel<PartLandAffectedWithDescriptionsModel>;

export interface TransferorsAddressModel {
  futureAddressId?: string | null;
  noAddressReason?: string | null;
}

export interface SalePriceModel {
  cash?: number;
  vendorTerms?: number;
  liabilities?: number;
  other?: number;
  otherDescription?: string;
  waterAllocation: WaterAllocationModel;
}

export interface WaterAllocationModel {
  isWaterAllocationApplicable: boolean;
}

export interface PropertyDataModel {
  inclusion: InclusionDataModel;
  currentLandUse: CurrentLandUseDataModel;
  safetySwitch: SafetySwitchDataModel;
  smokeAlarm: SmokeAlarmDataModel;
}

export interface InclusionDataModel {
  plant: boolean;
  livestock: boolean;
  crops: boolean;
  existingRight: boolean;
  chattels: boolean;
  waterLicence: boolean;
  waterAllocation: boolean;
  other?: string | null;
}

export interface CurrentLandUseDataModel {
  vacantLand: boolean;
  dwelling: boolean;
  flats: boolean;
  multiUnit: boolean;
  guestHousePrivateHotel: boolean;
  farming: boolean;
  industrial: boolean;
  commercial: boolean;
  other?: string | null;
}

export interface SafetySwitchDataModel {
  installed?: string | null;
  informed?: string | null;
}

export interface SmokeAlarmDataModel {
  installed?: string | null;
  informed?: string | null;
}

export interface TransactionDataModel {
  hasAgreement?: boolean;
  agreementDate?: Date | string;
  arePartiesRelated?: boolean;
  relatedDegree?: string;
  isConsiderationLess?: boolean;
  isOtherDutiable?: boolean;
  isGstPayable?: boolean;
  isMarginScheme?: boolean;
  isTransferorNonAus?: boolean;
}

export enum TransferorAddressTypeEnum {
  HasAKnownAddress = 'Has a known address',
  NewAddressNotKnown = 'New address not known',
  NoPermanentResidentialAddress = 'No permanent residential address'
}

export interface TransferorsAddressOption {
  isSelected: boolean;
  key: TransferorAddressTypeEnum;
  id: number;
  addressBookId: string | null;
}

export type Form24B_2_24_1Model = Omit<ApiForm24B_2_24_1Model, 'partyBook' | 'addressBook'> & {
  [PARTY_BOOK_KEY]: PartyModel[];
  [ADDRESS_BOOK_KEY]: AddressBookEntityModel[];
  transferorsAddressOptions: TransferorsAddressOption[];
};

export const BOOLEAN_OPTIONS: LookupEnumModel<string>[] = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
  { id: 'na', name: 'N/A' }
];

export const SIMPLE_BOOLEAN_OPTIONS: LookupEnumModel<boolean>[] = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
];

export const CURRENCY_PLACEHOLDER: string = '0.00';

export interface TransferorReferenceModel extends ApiPartyReferenceModel {
  trustName?: string;
}

export const QLD_STATE_OPTION: LookupEnumModel<string>[] = [{ id: 'QLD', name: 'QLD' }];
