import * as React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';

import converter from './conversion';
import { ApiDischargeOfMortgage2_18_0Model, DischargeOfMortgage2_18_0Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<DischargeOfMortgage2_18_0Model, ApiDischargeOfMortgage2_18_0Model>;

function DocumentForm(props: Props): JSX.Element {
  return (
    <DocumentFormWrapper //
      {...props}
      validationSchema={validationSchema}
      converter={converter}
      RootForm={RootForm}
    />
  );
}

export default React.memo(DocumentForm);
