import _cloneDeep from 'lodash-es/cloneDeep';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { NSW_NOD_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import {
  cleanUnselectedProprietorsDataFromTitleReference,
  convertDeceasedJointTenantsFromFormToApi,
  convertPartiesToFormModel2211,
  filterDeceasedProprietorGroups,
  getDeceasedJointTenantsMap
} from './helpers';
import { ApiNoticeOfDeath2_21_1Model, NoticeOfDeath2_21_1Model, TitleReferenceModel } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification, NswNameChange>({
  partyFormConfig: NSW_NOD_PARTY_FORM_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

export function convertFromApiToFormModel(apiModel: ApiNoticeOfDeath2_21_1Model): NoticeOfDeath2_21_1Model {
  const { titleReferences: apiTitleReferences } = apiModel;
  const convertedTitleReferences: TitleReferenceModel[] = apiTitleReferences.filter(title => title.deceasedJointTenants.length > 0);
  if (convertedTitleReferences.length === 1) {
    convertedTitleReferences[0].isSelected = true;
  }
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
  const partyBook = convertPartiesToFormModel2211(apiModel.partyBook || [], risInvolvedPartyIds);
  let formModel: NoticeOfDeath2_21_1Model = {
    ...apiModel,
    titleReferences: convertedTitleReferences,
    partyBook: partyBook,
    deceasedJointTenants: filterDeceasedProprietorGroups(_cloneDeep(convertedTitleReferences), partyBook)
  };

  formModel = applyDefaultMap(formModel, fallbackMap);
  return formModel;
}

export function convertFromFormToApiModel(formModel: NoticeOfDeath2_21_1Model, originalApiModel: ApiNoticeOfDeath2_21_1Model): ApiNoticeOfDeath2_21_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: formPartyBook,
    deceasedJointTenants,
    titleReferences: titleReferencesFromForm,
    ...rest
  } = formModel;

  const relinquishingGroupMap = getDeceasedJointTenantsMap(deceasedJointTenants);
  const convertedTitleReferences: TitleReferenceModel[] = titleReferencesFromForm.map(titleReference => {
    const { deceasedJointTenants: deceasedProprietorGroupsFromForm } = titleReference;
    return {
      ...titleReference,
      deceasedJointTenants: titleReference.isSelected
        ? convertDeceasedJointTenantsFromFormToApi(
            //
            deceasedProprietorGroupsFromForm.map(group => group.deceasedProprietors.map(p => p.partyBookId!)),
            relinquishingGroupMap
          )
        : deceasedProprietorGroupsFromForm
    };
  });

  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(formPartyBook || [], originalApiModel.partyBook || []),
    titleReferences: cleanUnselectedProprietorsDataFromTitleReference(convertedTitleReferences)
  };
}

class Converter implements IConverter<NoticeOfDeath2_21_1Model, ApiNoticeOfDeath2_21_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
