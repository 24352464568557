/**
 * We need to put tailwindCSS screen size config here to share between tailwindcss.config.ts and useScreenSize hook
 */
export enum ScreenSizeEnum {
  LargeScreen = 1920,
  MediumScreen = 1536,
  SmallLaptop = 1280
  // explicitly commented out as our APP does not support these so no referencing is allowed across our APP
  // TabletLandscape = 1024,
  // Tablet = 768,
  // Sm = 640
}

// The default breakpoints are inspired by common device resolutions:
// !important do not update the sequences of object keys
export const screens: Record<ScreenSizeVariant, string> = {
  'large-screen': `${ScreenSizeEnum.LargeScreen}px`,
  // => @media (min-width: 1920px) { ... },

  'medium-screen': `${ScreenSizeEnum.MediumScreen}px`,
  // => @media (min-width: 1536px) { ... },

  'small-laptop': `${ScreenSizeEnum.SmallLaptop}px`,
  // => @media (min-width: 1280px) { ... }

  'tablet-landscape': `1024px`,
  // => @media (min-width: 1024px) { ... }

  tablet: `768px`,
  // => @media (min-width: 768px) { ... }

  sm: `640px`
  // => @media (min-width: 640px) { ... }
};

export type ScreenSizeVariant = 'large-screen' | 'medium-screen' | 'small-laptop' | 'tablet-landscape' | 'tablet' | 'sm';
