import * as yup from 'yup';

import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { TransactionDataModel } from '../../models';

export const yupTransactionData = yup
  .object<TransactionDataModel>({
    settlementDate: yup.mixed(),
    isPossessionDateSameAsSettlement: yup.bool(),
    possessionDate: yupDatePicker.required(msg.REQUIRED)
  })
  .defined();
