import { createSelector, defaultMemoize } from 'reselect';

import { DocumentPermissionEnum, DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { DocumentLodgementDetail } from 'src/containers/shared/document-list/models';
import { Store } from 'src/reducers';

const getWorkspaceDocumentList = <T extends Pick<Store, 'workspaceDocuments'>>(state: T) => state.workspaceDocuments.items;
const getCurrentDocumentId = <T extends Pick<Store, 'document'>>(state: T) => state.document.detail?.id;

export const getCurrentParticipantDocuments = (currentParticipantId: string, documents: WorkspaceDocumentSummaryApiResponse[]): WorkspaceDocumentSummaryApiResponse[] => {
  const docs = documents.filter(document => {
    // The document is available for current user, only if:
    // current user is one of the participant in the document
    return document.documentParticipants.some(participant => {
      return participant.id === currentParticipantId;
    });
  });

  const supportingDocs = documents.flatMap(d => d.supportingDocuments ?? []);

  if (supportingDocs.length) {
    return docs.concat(getCurrentParticipantDocuments(currentParticipantId, supportingDocs));
  }

  return docs;
};

export const documentLodgementDetailSelector = createSelector<
  Pick<Store, 'workspaceDocuments' | 'document'>,
  WorkspaceDocumentSummaryApiResponse[],
  string | undefined,
  DocumentLodgementDetail | undefined
>(
  getWorkspaceDocumentList, //
  getCurrentDocumentId,
  (documents: Array<WorkspaceDocumentSummaryApiResponse>, id?: string) => {
    if (!documents.length || id == null) {
      return undefined;
    }

    //flatten supporting documents and documents.
    const flattenedDocuments = documents.flatMap(d => d.supportingDocuments ?? []).concat(documents);

    return flattenedDocuments.find(doc => doc.documentId === id)?.lodgementDetail;
  }
);

interface AvailableDocumentSelectorDataModel {
  documentList: Array<WorkspaceDocumentSummaryApiResponse>;
  participantId: string;
}

const getDocumentList = (data: AvailableDocumentSelectorDataModel) => data.documentList;
const getCurrentParticipantId = (data: AvailableDocumentSelectorDataModel) => data.participantId;

export const currentParticipantDocumentListSelector = createSelector<
  //
  AvailableDocumentSelectorDataModel,
  WorkspaceDocumentSummaryApiResponse[],
  string,
  WorkspaceDocumentSummaryApiResponse[]
>(
  getDocumentList, //
  getCurrentParticipantId,
  (documentList, currentParticipantId) => {
    const participantDocuments = getCurrentParticipantDocuments(currentParticipantId, documentList);
    return participantDocuments;
  }
);

const DEFAULT_MESSAGES: DocumentLodgementDetail['documentLodgementCompliances'] = [];
export const readyToSignDocumentLodgementCompliancesSelector = createSelector<
  //
  AvailableDocumentSelectorDataModel,
  WorkspaceDocumentSummaryApiResponse[],
  string,
  DocumentLodgementDetail['documentLodgementCompliances']
>(
  getDocumentList, //
  getCurrentParticipantId,
  (documentList, participantId) => {
    const currentParticipantDocumentList = currentParticipantDocumentListSelector({ participantId, documentList });
    const readyToSignDocList = currentParticipantDocumentList.filter(document => document.documentStatus.id === DocumentStatusEnum.Signing);

    const mergedMessages: DocumentLodgementDetail['documentLodgementCompliances'] = readyToSignDocList.reduce(
      (messageList: DocumentLodgementDetail['documentLodgementCompliances'], { lodgementDetail }: WorkspaceDocumentSummaryApiResponse) => {
        if (lodgementDetail) {
          messageList.push(...lodgementDetail.documentLodgementCompliances);
        }
        return messageList;
      },
      []
    );

    return mergedMessages.length ? mergedMessages : DEFAULT_MESSAGES;
  }
);

// todo: somehow this method is shared with financial, so make lodgementCase optional. We should not combine them all in one method
export const getDocumentsReadyToSign = defaultMemoize((documents: WorkspaceDocumentSummaryApiResponse[], lodgementCaseId?: string): WorkspaceDocumentSummaryApiResponse[] => {
  const allDocuments = documents.concat(documents.flatMap(d => d.supportingDocuments ?? []));
  // Sign permissions already calculated from backend response
  // See Document domain model - VerifySignAccess in DocumentService

  const signedDocs = allDocuments.filter(d => d.permissions.some(x => x === DocumentPermissionEnum.Sign));

  if (lodgementCaseId) {
    return signedDocs.filter(d => d.lodgementCaseId === lodgementCaseId);
  }

  return signedDocs;
});
