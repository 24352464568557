import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

import yupCaveators from './components/caveator-party-readonly-array/validationSchema';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { CaveatModel, WithdrawalOfCaveat2_23_0Model } from './models';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupWithdrawCaveats from './sections/withdraw-caveat/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<WithdrawalOfCaveat2_23_0Model>({
    caveats: yup.mixed<CaveatModel[]>(),
    workspaceTitleReferences: yup.mixed<string[]>(),
    attachments: yup.mixed<DocumentAttachmentItemModel[]>(),
    dealingNumbersSelectedInOtherWithdrawalsOfCaveat: yup.mixed<string[]>(),
    addressBook: yup.mixed<AddressBookEntityModel[]>(),

    titleReferences: yupTitleReferences,
    withdrawCaveats: yupWithdrawCaveats,
    partyBook: yupPartyBook,
    caveators: yupCaveators,
    additionalAttachments: memoizeSchema(yupAdditionalAttachments(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS))
  })
  .defined()
  .log();
