import React from 'react';

import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import { NewIconTick } from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';

export interface Props {
  title: string;
  textToCopy: string;
  className?: string;
}

function CopyIcon({ title, textToCopy, className }: Props) {
  const [copied, setCopied] = React.useState(false);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const Component = React.forwardRef((props: any, ref: React.Ref<any>) => (
    <CopyToClipboard text={textToCopy}>
      <IconButton {...props} ref={ref} size="large" className={classNames('group', className)} onClick={handleOnClick}>
        {copied ? (
          <NewIconTick fill={colors.BLACK} width={14} height={14} />
        ) : (
          // TODO this should be moved to new ui-framework later on as new Icons
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M9.1873 13.5625H3.0623C2.31418 13.5625 1.70605 12.9544 1.70605 12.2063V3.54375C1.70605 2.79563 2.31418 2.1875 3.0623 2.1875H9.18293C9.93106 2.1875 10.5392 2.79563 10.5392 3.54375V12.2019C10.5392 12.95 9.93106 13.5581 9.18293 13.5581L9.1873 13.5625ZM3.0623 3.15C2.84355 3.15 2.66855 3.32938 2.66855 3.54375V12.2019C2.66855 12.4206 2.84793 12.6 3.0623 12.6H9.18293C9.40168 12.6 9.57668 12.4206 9.57668 12.2019V3.54375C9.57668 3.325 9.3973 3.15 9.18293 3.15H3.0623Z"
              className="fill-[var(--neutral-500)] group-hover:fill-[var(--sympli-green)]"
            />
            <path
              d="M11.8128 8.39998C11.5459 8.39998 11.3315 8.18561 11.3315 7.91873V3.53936C11.3315 2.35811 10.369 1.39561 9.18777 1.39561H6.5584C6.29152 1.39561 6.07715 1.18123 6.07715 0.914355C6.07715 0.64748 6.29152 0.433105 6.5584 0.433105H9.18777C10.8984 0.433105 12.294 1.82436 12.294 3.53936V7.91873C12.294 8.18561 12.0796 8.39998 11.8128 8.39998Z"
              className="fill-[var(--neutral-500)] group-hover:fill-[var(--sympli-green)]"
            />
          </svg>
        )}
      </IconButton>
    </CopyToClipboard>
  ));

  return (
    <Tooltip title={<Typography className="font-bold text-[var(--neutral-000)]">{copied ? 'Copied' : `Copy ${title}`}</Typography>} aria-label={textToCopy} placement="right">
      <Component />
    </Tooltip>
  );
}

export default React.memo(CopyIcon);
