import * as React from 'react';

import { useFormikContext } from 'formik';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DisableTenancyFieldConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import SectionTenancyReceiving from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_TAB_PARTY_FORM_CONFIG, NSW_TAB_RECEIVING_TENANCY_LABEL_CONFIG } from '../../config';
import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../../models';

interface Props {
  name: string;
}

function Applicants({ name }: Props) {
  const {
    values: { deceasedTenancyDetail }
  } = useFormikContext<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>();
  const relinquishingPartyIds = deceasedTenancyDetail.proprietorGroups.find(pg => pg.isSelected)?.parties.map(p => p.partyBookId) ?? [];

  const disablePartyFieldEditConfig: DisableTenancyFieldConfig<PartyModel> = {
    disabled: (party: PartyModel) => party.partyType === PartyTypeEnum.Individual && party.metadata?.source === DataSource.Title,
    disabledTooltip: () => <span>This party cannot be edited as it has been returned from the title. Please add a new party.</span>
  };

  return (
    <SectionTenancyReceiving
      name={name}
      partyFormConfig={NSW_TAB_PARTY_FORM_CONFIG}
      componentLabels={NSW_TAB_RECEIVING_TENANCY_LABEL_CONFIG}
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
      forceDobOnIndividual
      partyIdsToFilter={relinquishingPartyIds}
      disablePartyEditConfig={disablePartyFieldEditConfig}
    />
  );
}

export default React.memo(Applicants);
