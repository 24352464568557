import { createAction, createAsyncAction } from 'typesafe-actions';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { PlatformEnum } from 'src/hooks/models';
import { WorkspaceSearchApiRequest } from './components/search-box-new/models';
import { SearchLinkedWorkspaceApiRequest, SearchLinkedWorkspaceApiResponse } from './components/search-linked-workspace/models';
import { SearchBoxesUpdatedApiResponse } from './reducers/searchBoxUpdated';

export const actionFetchSearchBoxUpdatedFeed = createAsyncAction(
  //
  'FETCH_SEARCHBOX_UPDATED_FEED',
  'FETCH_SEARCHBOX_UPDATED_FEED_SUCCESS',
  'FETCH_SEARCHBOX_UPDATED_FEED_ERROR'
)<
  //
  WorkspaceSearchApiRequest,
  { data: SearchBoxesUpdatedApiResponse },
  { error: Error }
>();

export const actionFetchLinkedWorkspaceUpdatedFeed = createAsyncAction(
  //
  'FETCH_LINKEDWORKSPACE_UPDATED_FEED',
  'FETCH_LINKEDWORKSPACE_UPDATED_FEED_SUCCESS',
  'FETCH_LINKEDWORKSPACE_UPDATED_FEED_ERROR'
)<
  //
  SearchLinkedWorkspaceApiRequest,
  { data: SearchLinkedWorkspaceApiResponse },
  { error: Error }
>();

export const actionResetFetchLinkedWorkspaceFeed = createAction(
  //
  'FETCH_LINKEDWORKSPACE_FEED_RESET'
)<SearchLinkedWorkspaceApiRequest>();

export const actionResetSearchBoxFeed = createAction(
  //
  'FETCH_SEARCHBOX_FEED_RESET'
)<WorkspaceSearchApiRequest>();

export const actionFetchSearchBoxUpdatedArchivedFeed = createAsyncAction(
  //
  'FETCH_SEARCHBOX_UPDATED_ARCHIVED_FEED',
  'FETCH_SEARCHBOX_UPDATED_ARCHIVED_FEED_SUCCESS',
  'FETCH_SEARCHBOX_UPDATED_ARCHIVED_FEED_ERROR'
)<
  //
  WorkspaceSearchApiRequest,
  { data: SearchBoxesUpdatedApiResponse },
  { error: Error }
>();

export const actionResetSearchBoxArchivedFeed = createAction(
  //
  'FETCH_SEARCHBOX_ARCHIVED_FEED_RESET'
)<WorkspaceSearchApiRequest>();

export interface FlagStatus {
  name: FeatureToggleEnum;
  status: boolean;
  isControlledBy: PlatformEnum;
}

export const actionAddCurrentPageFeatureFlag = createAction(
  //
  'ADD_CURRENT_PAGE_FEATURE_FLAG'
)<FlagStatus>();

export const actionClearCurrentPageFeatureFlag = createAction(
  //
  'CLEAR_CURRENT_PAGE_FEATURE_FLAG'
)();

export interface FlagStatusByUI {
  name: FeatureToggleEnum;
  status: boolean;
}

export const actionUpdateFeatureFlagsControlledByUI = createAction(
  //
  'UPDATE_FEATURE_FLAGS_ENABLED_BY_UI'
)<Array<FlagStatusByUI>>();

export const actionClearFeatureFlagsControlledByUI = createAction(
  //
  'CLEAR_FEATURE_FLAGS_ENABLED_BY_UI'
)();
