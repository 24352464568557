import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    itemSelection: {
      border: 'none',
      '& > div': {
        width: '100%'
      },
      '& label > span:last-child': {
        width: '100%'
      }
    },
    arrowBox: {
      paddingBottom: 0
    }
  }),
  {
    name: 'TransferorsAddress'
  }
);
