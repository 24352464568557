import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS } from '../../enums';
import { IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { EvidenceModel, NoticeOfDeath2_21_1Model } from '../../models';
import { VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE } from '../../visibilityChecks';
import { useStyles } from './styles';

interface Props {
  name: string;
  className?: string;
}

function Evidence({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();
  const { values } = formikProps;
  const value: EvidenceModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<EvidenceModel>(name);
  const classes = useStyles();

  return (
    <div data-section={name} className={className} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Field //
          name={fieldName('evidenceTypeValue')}
          label={'Evidence type'}
          component={SelectField}
          disabled={disabled}
          format="number"
          options={TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          aria-label="Evidence Type Value"
          data-testid={'evidenceTypeValue'}
          className={classes.fieldWidth}
        />
        {VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE(value) && (
          <Field //
            name={fieldName('evidenceDate')}
            label={'Evidence date'}
            component={DatePickerField}
            disabled={disabled}
            disableFuture={true}
            aria-label="Evidence Date"
            className={classes.fieldWidth}
          />
        )}
        {VISIBILITY_CHECK_EVIDENCE_DATE_AND_EVIDENCE_REFERENCE(value) && (
          <Field //
            name={fieldName('evidenceDocumentReference')}
            label={'Evidence document reference'}
            component={InputField}
            disabled={disabled}
            placeholder={resolvePlaceholder(IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_DOCUMENT_REFERENCE(value))}
            aria-label="Evidence Document Reference"
            className={classes.fieldWidth}
          />
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(Evidence);
