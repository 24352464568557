import { JurisdictionsEnum, LodgementCaseStatusEnum, LogTypeEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { DateTimeInfo, ExpectedSettlementDate, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';

import { AssignmentTypeEnum, DashboardDateRangeModel } from 'src/containers/dashboard/shared/models';
import { FinancialSettlementStatusEnum } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import { ScreenSizeVariant } from 'src/theme/screens';
import { FinancialDashboardRouteTabsEnumV2 } from '../models';

export interface LastSystemActivityModel {
  message: string;
  userLocalTime: string;
  workspaceLocalTime: string;
  userTimezoneAbbreviation: string;
  workspaceTimezoneAbbreviation: string;
  logType: LogTypeEnum;
}

export interface FinancialTableModel {
  workspaceId: string;
  participantId: string;
  settlementDate: WorkspaceDateTimeModel;
  expectedSettlementDate?: ExpectedSettlementDate;
  sympliId: string;
  addresses: string[];
  jurisdictionId: JurisdictionsEnum;
  reference: string;
  roles: string[];
  clients: Array<
    | {
        clientType: 0; // Individual
        firstName?: string;
        middleName?: string;
        lastName?: string;
      }
    | {
        clientType: 1; //  Organisation
        organisationName: string;
      }
  >;
  taskInfo: {
    taskCount: number;
    status: 'ok' | 'outstanding' | 'overdue';
  };
  workspaceStatusId: WorkspaceStatusEnum;
  lastSystemActivity?: LastSystemActivityModel;
  lodgementCaseStatusId: LodgementCaseStatusEnum;
  allDocumentsFullySigned: boolean;
  financialSettlementStatus: FinancialSettlementStatusEnum;
  isFinancialBalanced: boolean;
  groupName?: string;
  member?: string;
  unreadMessagesCount: number;
  lastAccessed?: DateTimeInfo;
  archivedDate?: DateTimeInfo;
  pendingProposedSettlementDate?: WorkspaceDateTimeModel;
  workspaceTransactionType?: string;
  isUnsupportedSettlementDate?: boolean;
}

export type FinancialTableRowModel = FinancialTableModel; // TODO replace with real model definition

export enum SortingFieldEnum {
  SettlementDate = 0,
  LastUpdated = 1,
  LastAccessed = 2
}

export enum SortingOptionEnum {
  SettlementDate = 'settlementDate',
  LastUpdated = 'lastUpdated',
  LastAccessed = 'lastAccessed',
  ArchivedDate = 'archivedDate'
}

export interface FinancialWorkspaceV2FilterQueryParamsModel {
  assignmentType: AssignmentTypeEnum;
  // primaryWorkspaceStatus: PrimaryWorkspaceStatus;
  sympliId?: string;
  reference?: string;
  settlementPeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  lastAccessPeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  archivedDatePeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  myTasks?: number[];
  myRoles?: number[];
  jurisdictions?: number[];
  financialStatus?: number[];
  fundsStatus?: number[];
  workspaceStatus?: number[];
  documentStatus?: number[];
  withProposals?: boolean;
  groupIds?: string[];
  userIds?: string[];
  workspaceMessageStatus?: number[];
  workspaceTransactionType?: number[];
}

export interface FinancialTableContext {
  screenVariant: ScreenSizeVariant;
  selectedTab: FinancialDashboardRouteTabsEnumV2;
}
