import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import SectionNominationDetails from 'src/containers/documents/scaffolded-form/vic/2-18/components/nomination/nomination-details';
import { WithdrawalOfNomination_2_18_1_Model } from './models';

const fieldName = modelKey<WithdrawalOfNomination_2_18_1_Model>();

function RootForm({ className, hideActionsPanel, onSaveChanges, onCancel }: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, nextActionLabel } = useDocumentContext();
  const { values } = useFormikContext<WithdrawalOfNomination_2_18_1_Model>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    focusOnTitleReferencesRef.current?.focusVisible();
  }, []);

  return (
    <Form className={className}>
      <SectionTitleReference name={fieldName('titleReferences')} disabled={disabled} focusRef={focusOnTitleReferencesRef} />
      <SectionNominationDetails name={fieldName('ctController')} value={values.ctController} />
      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={nextActionDisabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default RootForm;
