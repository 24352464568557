import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { $and, $noneOf, $oneOf } from '@sympli-mfe/document-forms-framework/utils';

import { ActionProhibitedEnum, ClaimCategoryEnum, EstateOfInterestClaimedEnum } from './enums';
import { CaveatModel_2_21_1, Claim, ExtentOfProhibition } from './models';
import { VISIBILITY_CHECK_CLAIM_DATE } from './sections/claim-details/checks';

export const VISIBILITY_CHECK_QUALIFICATION = (extentOfProhibition: ExtentOfProhibition): boolean => {
  return [ActionProhibitedEnum.ActionProhibited1, ActionProhibitedEnum.ActionProhibited2, ActionProhibitedEnum.ActionProhibited5].includes(
    extentOfProhibition.actionProhibitedOption as number
  );
};

export const VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID = (extentOfProhibition: ExtentOfProhibition): boolean => {
  return [ActionProhibitedEnum.ActionProhibited3, ActionProhibitedEnum.ActionProhibited6a, ActionProhibitedEnum.ActionProhibited6b].includes(
    extentOfProhibition.actionProhibitedOption as number
  );
};

export const VISIBILITY_CHECK_CLAIM_CATEGORY = (claimDetails: Claim): boolean => {
  const estateOrInterestClaimed = claimDetails.estateOrInterestClaimed ?? null;
  return ![null, EstateOfInterestClaimedEnum.RegisteredProprietor].includes(estateOrInterestClaimed);
};

export const VISIBILITY_CHECK_CLAIM_PARTIES = (claimDetails: Claim): boolean => {
  const allowedClaimCategoryValues: ClaimCategoryEnum[] = [1, 6, 7, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23];

  return $and(
    //
    $oneOf(claimDetails.claimCategory, allowedClaimCategoryValues),
    $noneOf(claimDetails.estateOrInterestClaimed, [null, EstateOfInterestClaimedEnum.RegisteredProprietor])
  );
};

export const VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID = (claimDetails: Claim): boolean => {
  const allowedClaimCategoryValues: ClaimCategoryEnum[] = [3, 6, 9, 10, 11, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24];

  return $and(
    //
    $oneOf(claimDetails.claimCategory, allowedClaimCategoryValues),
    claimDetails.estateOrInterestClaimed !== EstateOfInterestClaimedEnum.RegisteredProprietor
  );
};

const DAN_MIN_DATE = new Date('1987-01-01T00:00:00');
const DAN_MAX_DATE = new Date('2016-06-30T23:59:59');
const dateFallsWithinDanRange = (claimDate?: Date | string | null): boolean => {
  if (typeof claimDate !== 'string' && !(claimDate instanceof Date)) {
    return false;
  }
  const claimDateAsDate = typeof claimDate === 'string' ? new Date(claimDate) : claimDate;
  return claimDateAsDate >= DAN_MIN_DATE && claimDateAsDate <= DAN_MAX_DATE;
};
const danAppliesToClaimCategory = (estateOrInterestClaimed: EstateOfInterestClaimedEnum, claimCategory: ClaimCategoryEnum): boolean => {
  switch (estateOrInterestClaimed) {
    case EstateOfInterestClaimedEnum.Mortgage:
      return [ClaimCategoryEnum.LoanAgreement, ClaimCategoryEnum.MortgageOfEstateInFeeSimple].includes(claimCategory);
    case EstateOfInterestClaimedEnum.Charge:
      return [ClaimCategoryEnum.Agreement, ClaimCategoryEnum.ChargeOfEstateInFeeSimple].includes(claimCategory);
    default:
      return false;
  }
};
export const VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER = ({ claimDetails }: CaveatModel_2_21_1): boolean => {
  const { estateOrInterestClaimed, claimCategory, claimDate } = claimDetails!;
  return danAppliesToClaimCategory(estateOrInterestClaimed!, claimCategory!) && dateFallsWithinDanRange(claimDate);
};

/*
 path:
 - caveators    
*/
export const VISIBILITY_CHECK_CAVEATORS = ({ extentOfProhibitions, proprietors }: CaveatModel_2_21_1): boolean => {
  const caveatorsMustBeSameAsProprietors =
    (proprietors || []).every(({ isAffected }) => isAffected) &&
    (extentOfProhibitions || []).some(({ actionProhibitedOption }) => actionProhibitedOption === ActionProhibitedEnum.ActionProhibited5);

  return !caveatorsMustBeSameAsProprietors;
};

export const caveatFallbackMap: FallbackMap<CaveatModel_2_21_1> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,

  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        reference: NO_FALLBACK
      }
    }
  },
  extentOfProhibitions: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        actionProhibitedOption: [null],
        qualification: [VISIBILITY_CHECK_QUALIFICATION, ''],
        lrDocumentId: [VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, '']
      }
    }
  },
  claimDetails: {
    $children: {
      estateOrInterestClaimed: [null],
      claimCategory: [VISIBILITY_CHECK_CLAIM_CATEGORY, null],
      detailsSupportingTheClaim: [''],
      claimDate: [VISIBILITY_CHECK_CLAIM_DATE, null as any],
      lrDocumentID: [VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID, ''],
      claimParties: {
        $arrayVisibility: [VISIBILITY_CHECK_CLAIM_PARTIES, null as any],
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: {
          $children: {
            partyBookId: [null],
            party: NO_FALLBACK,
            claimPartyCapacity: [null]
          }
        }
      }
    }
  },
  supportingDocuments: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: NO_FALLBACK
  },
  dutyAssessmentNumber: [VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER, null as any, ''],
  caveators: {
    $arrayVisibility: [VISIBILITY_CHECK_CAVEATORS, null as any],
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        addressBookId: [null],
        partyBookId: [null],
        address: NO_FALLBACK,
        party: NO_FALLBACK
      }
    }
  },

  detailsForServiceOfNotice: {
    $children: {
      contacts: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            partyBookId: [null],
            addressBookId: [null],
            address: NO_FALLBACK,
            party: NO_FALLBACK
          }
        }
      }
    }
  },
  proprietors: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isAffected: [true],
        addressBookId: [null],
        partyBookId: [null],
        address: NO_FALLBACK,
        party: NO_FALLBACK
      }
    }
  },

  caveatStatementsAgreed: [false]
};
