import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    disabledNextDark: {
      '&.Mui-disabled': {
        backgroundColor: colors.DARK_GREY,
        '& svg': {
          fill: theme.palette.text.secondary
        }
      }
    },
    disabledNextLight: {
      '&.Mui-disabled': {
        backgroundColor: 'none',
        '& svg': {
          fill: theme.palette.text.secondary
        }
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
