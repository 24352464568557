import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    backButton: {
      marginTop: 60
    }
  }),
  { name: '404Page' }
);
