// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { DischargeOfMortgage2_17_1Model } from './models';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgages from './sections/mortgages/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup.object<DischargeOfMortgage2_17_1Model>().shape({
  mortgages: yupMortgages,
  titleReferences: yupTitleReferences,
  partyBook: yupPartyBook,
  mortgagees: yupMortgagees
});
