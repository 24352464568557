import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

const styles = (theme: Theme) =>
  createStyles({
    // Dropzone style
    root: {
      position: 'relative',
      ...dataBindingHelper()
    },

    // Uploaded files
    uploadedFile: {
      marginTop: '4px'
    },
    deleteButton: {
      width: 24,
      height: 24,
      marginLeft: -6
    },
    deleteIcon: {
      fontSize: 14,
      width: '1em',
      height: '1em',
      fill: theme.palette.primary.main
    },
    deleteIconDisabled: {
      fill: theme.palette.action.disabled
    },
    downloadButtonRoot: {
      fontWeight: 'inherit'
    },
    downloadButtonText: {
      display: 'flex'
    },

    // Field information
    errorMessage: {
      marginTop: 4
    }
  });

export type ClassKeys = typeof styles;

export default styles;
