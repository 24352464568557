import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DETAILS_FOR_SERVICE_OF_NOTICES$OWNER_AGENT_LOOKUP_OPTIONS, DetailsForServiceOfNotices$OwnerAgentEnum } from '../../enums';
import { DetailsForServiceOfNoticesModel, NoticeOfSaleModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupDetailsForServiceOfNotices = yup
  .object<DetailsForServiceOfNoticesModel, NoticeOfSaleModel>()
  .required(msg.REQUIRED)
  .shape({
    ownerAgent: yup
      .mixed<DetailsForServiceOfNotices$OwnerAgentEnum>()
      .required(msg.REQUIRED)
      .oneOf(getLookupValuesAllowingEmpty(DETAILS_FOR_SERVICE_OF_NOTICES$OWNER_AGENT_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
    partyBookId: yup.string().default('').trim().required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(NSW_PARTY_FORM_CONFIG)),
    addressBookId: yup.string().default('').trim().required(msg.REQUIRED)
  });

export default yupDetailsForServiceOfNotices;
