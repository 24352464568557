import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useToken } from 'src/auth/useToken';
import { useSafeDispatch } from 'src/hooks';
import MfaLinkDevice from './MfaLinkDevice';

function MfaLinkDeviceContainer() {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const token = useToken();

  return <MfaLinkDevice dispatch={dispatch} token={token} navigate={navigate} />;
}

export default React.memo(MfaLinkDeviceContainer);
