import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    editorBox: {
      marginTop: 20,
      marginBottom: 8,
      padding: '20px 20px 10px 20px',
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.grey[50],
      position: 'relative',
      // arrow for the box
      '&:before, &:after': {
        position: 'absolute',
        left: '17%',
        border: '16px solid transparent',
        content: '" "'
      },
      '&:before': {
        top: -32,
        borderBottomColor: theme.palette.divider
      },
      '&:after': {
        top: -30,
        borderBottomColor: theme.palette.grey[50]
      }
    }
  }),
  { name: 'MortgageEditor' }
);
