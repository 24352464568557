import { Action, createReducer } from 'typesafe-actions';

import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';

import { ApiStatus } from 'src/utils/http';
import { actionFetchAvailableDocumentFeed } from '../actions/defaultFieldPopulation';

export interface AvailableDocumentApiResponse {
  identifier: DocumentTypeIdentifierEnum;
  name: string;
}

export interface AvailableDocumentFeedState {
  // * wait for AvailableDocumentFeed api change
  items: Array<AvailableDocumentApiResponse>;
  status: ApiStatus;
  error?: string;
}

const initialState: AvailableDocumentFeedState = {
  items: [],
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  AvailableDocumentFeedState,
  Action
>(initialState)
  .handleAction(actionFetchAvailableDocumentFeed.request, (state): AvailableDocumentFeedState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchAvailableDocumentFeed.success, (state, action): AvailableDocumentFeedState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchAvailableDocumentFeed.failure, (state, action): AvailableDocumentFeedState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
