import * as React from 'react';

import { NavLink, Route } from 'react-router-dom';

import { UserRoleEnum } from '@sympli/api-gateway/enums';

import { Box } from 'src/components/layout';
import Switch from 'src/components/router/switch';
import SettingMenuNavLink from 'src/containers/settings/components/setting-menu-nav-link';
import { UserDetailsPageTabEnum } from 'src/containers/settings/settings/users-detail/models';
import FeatureToggle from 'src/containers/shared/auth/profile-feature-toggle';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import SettingsPageLayout from '../settings/components/layout';
import UsersDetail from '../settings/settings/users-detail';
import Details from './details';
import MultiFactorAuthDevice from './multi-factor-auth-devices';
import Password from './password';
import { useStyles } from './styles';

export interface PersonalProfileRootProps {
  pathname: string;
  userId: string;
  roleId: UserRoleEnum;
  isSSOEnabled: boolean;
}
function SidePanel(props: { userId: string; roleId: UserRoleEnum; isSSOEnabled: boolean }) {
  const { userId, roleId, isSSOEnabled } = props;
  const classes = useStyles();

  return (
    <Box title="Personal profile" classes={{ boxHeader: 'pl-[14px]' }}>
      <NavLink title="Personal details" className={classes.link} to="/personal-profile/details">
        Personal details
      </NavLink>
      {isSSOEnabled ? null : (
        <NavLink title="Change password" className={classes.link} to="/personal-profile/password">
          Change password
        </NavLink>
      )}
      <NavLink title="Permissions" className={classes.link} to={`/personal-profile/${userId}/permissions`}>
        Permissions
      </NavLink>
      {roleId === UserRoleEnum.SuperAdmin && (
        <FeatureToggle featureToggleType={FeatureToggleEnum.bIReporting}>
          <SettingMenuNavLink //
            to={`/personal-profile/${userId}/${UserDetailsPageTabEnum.ReportsPermissions}`}
          >
            Reports permissions
          </SettingMenuNavLink>
        </FeatureToggle>
      )}
      {isSSOEnabled ? null : (
        <NavLink title="Linked devices" className={classes.link} to="/personal-profile/mfa">
          Linked devices
        </NavLink>
      )}
    </Box>
  );
}

function PersonalProfile(props: PersonalProfileRootProps) {
  const { isSSOEnabled, roleId, userId } = props;
  return (
    <SettingsPageLayout //
      sidePanel={
        <SidePanel //
          isSSOEnabled={isSSOEnabled}
          roleId={roleId}
          userId={userId}
        />
      }
    >
      <Switch>
        <Route path="details" element={<Details />} />
        <Route path="password" element={<Password />} />
        <Route path=":userId/:tab" element={<UsersDetail />} />
        <Route path="mfa" element={<MultiFactorAuthDevice />} />
      </Switch>
    </SettingsPageLayout>
  );
}

export default PersonalProfile;
