import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingBottom: 24
    },
    header: {
      width: '15%',
      paddingRight: 20,
      paddingBottom: 6,
      color: colors.NEUTRAL_1000
    },
    description: {
      fontSize: 13,
      lineHeight: '15px',
      color: theme.palette.text.secondary
    }
  });

export type ClassKeys = typeof styles;

export default styles;
