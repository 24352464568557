import * as yup from 'yup';

import yupResponsibleParticipantId from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber/validation-schema';
import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { LodgementInstructions_2_25_2 } from './models';
import yupDealingRequirementsSummary from './sections/dealing-requirements-summary/validationSchema';
import { yupIssuingInstructionsValidationSchema } from './sections/issuing-instructions/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<LodgementInstructions_2_25_2>({
    responsibleParticipantId: yupResponsibleParticipantId,
    dealingRequirementsSummary: yupDealingRequirementsSummary,
    issuingInstructions: yupIssuingInstructionsValidationSchema<LodgementInstructions_2_25_2>(),

    workspaceType: yup.mixed<WorkspaceTypeEnum>(),
    isSameRoleAsResponsible: yup.mixed<boolean>(),
    currentParticipantId: yup.mixed<string>(),
    isResponsibleParticipant: yup.mixed<boolean>()
  })
  .defined()
  .log();
