import * as React from 'react';

import { FieldProps } from 'formik';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  label: string;
  actionLabel: string;
  disabled?: boolean;
}

type Props = OwnProps & FieldProps & WithStyles<ClassKeys>;

class ToggleValueField extends React.PureComponent<Props> {
  render() {
    const { classes, label, actionLabel, disabled } = this.props;
    return (
      <FlexLayout alignItems="center" flexDirection="row" className={classes.root}>
        {label}
        <ButtonLink disabled={disabled} className={classes.button} icon="edit" onClick={this.handleOnClick} color="inherit">
          {actionLabel}
        </ButtonLink>
      </FlexLayout>
    );
  }

  private handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { form, field } = this.props;
    // negate the value, which we always expect to be a boolean!
    form.setFieldValue(field.name, !field.value);
  };
}

const styledComponent = withStyles(styles)(ToggleValueField);
export default styledComponent;
