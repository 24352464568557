import * as React from 'react';

import { useDispatch } from 'react-redux';

import { WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import LineLoader from 'src/components/loaders/line-loader';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { saveAssignee } from 'src/containers/shared/workspace-staff-assign/api';
import { useSafeDispatch } from 'src/hooks';
import { AllocatedUser } from 'src/models';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import WorkspaceMenuItemStaffAssign101 from './WorkspaceMenuItemStaffAssign';

interface Props {
  isLoading: boolean;
  // route params
  workspaceId: string;
  participantId: string;
  // basic workspace info
  workspaceTypeId?: WorkspaceTypeEnum;
  workspaceStatusId: WorkspaceStatusEnum;
  // current participant info
  groupId?: string;
  assignee?: AllocatedUser;
  assignedGroupName?: string;

  onUpdateAssignee: (workspaceId: string, participantId: string, allocatedUser: AllocatedUser) => void;
}

function WorkspaceMenuItemStaffAssignContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId, workspaceTypeId, onUpdateAssignee, assignedGroupName } = props;
  const { userId, firstName, middleName, lastName, avatarUrl }: UserProfileModel = useProfile().data!;

  const handleOnAssigneeUpdated = React.useCallback(
    (workspaceId: string, participantId: string, selectedSuggestion: AllocatedUser) => {
      onUpdateAssignee(workspaceId, participantId, selectedSuggestion);
    },
    [onUpdateAssignee]
  );

  const handleOnAssignToMeClick = React.useCallback(async () => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'assign-to-me',
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!]
    });
    try {
      await saveAssignee([
        {
          userId,
          workspaceId,
          participantId
        }
      ]);

      handleOnAssigneeUpdated(workspaceId, participantId, {
        userId,
        firstName,
        middleName,
        lastName,
        avatarUrl
      });
    } catch (error) {
      dispatch(actionCreateGlobalErrorMessage(error));
    }
  }, [workspaceId, participantId, workspaceTypeId, userId, handleOnAssigneeUpdated, firstName, middleName, lastName, avatarUrl, dispatch]);

  if (props.isLoading) {
    return (
      <LineLoader //
        color="grey"
        variant="medium"
        icon
        style={{ marginBottom: 0, marginTop: 12 }}
      />
    );
  }

  // this component is relevant only if groupId is present
  if (typeof props.groupId !== 'string') {
    return null;
  }

  return (
    <WorkspaceMenuItemStaffAssign101 //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // workspace basic info
      workspaceTypeId={workspaceTypeId}
      workspaceStatusId={props.workspaceStatusId}
      // current participant
      groupId={props.groupId}
      assignee={props.assignee}
      // profile
      userId={userId}
      onAssignToMe={handleOnAssignToMeClick}
      onAssigneeUpdated={handleOnAssigneeUpdated}
      assignedGroupName={assignedGroupName}
    />
  );
}

export default React.memo(WorkspaceMenuItemStaffAssignContainer);
