import * as React from 'react';

import Link from '@mui/material/Link';

import { ComplianceLevelEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import MessageList from '@sympli/ui-framework/components/message-notification/components/message-list';

import MessageNotificationV2 from 'src/components/message-notification';
import { createRegroupIntoErrorsAndWarningsSelector } from 'src/containers/workspace/financial/detail/components/error-message-panel/selectors';
import { convertComplianceToMessageGridModel } from 'src/containers/workspace/shared/detail/helpers';
import StampDutyCreationError from '../../../components/shared-status-notification/stamp-duty-creation-error';
import StampDutyVerificationError from '../../../components/shared-status-notification/stamp-duty-verification-error';
import { TransactionCreatedOsrCaseStatus } from '../../../helpers';
import { OsrCaseStatusEnum, StampDutyApiResponse, StampDutyProgressStatusEnum } from '../../../models';
import { stampDutyProcessMessagesSelector } from '../../../selectors';
import { resolveVerifiedDescription } from './helpers';

const regroupIntoErrorsAndWarningsSelector = createRegroupIntoErrorsAndWarningsSelector<{
  //
  complianceLevel: ComplianceLevelEnum;
  complianceMessage: string;
}>();

export type ProgressMessageNotificationProps = {
  jurisdictionId: JurisdictionsEnum;
  detail: StampDutyApiResponse;
};

function ProgressMessageNotification(props: ProgressMessageNotificationProps): JSX.Element | null {
  const {
    jurisdictionId,
    detail: { payableDuty, processMessages, status, osrCaseStatus, paymentOption }
  } = props;

  const messages = stampDutyProcessMessagesSelector(processMessages);

  const creatingTransaction =
    jurisdictionId === JurisdictionsEnum.QLD || jurisdictionId === JurisdictionsEnum.VIC ? (
      <MessageNotificationV2 variant="loading">The Office of State Revenue is creating your transaction</MessageNotificationV2>
    ) : null;

  const transactionCreated = () => {
    const variant = osrCaseStatus === OsrCaseStatusEnum.WaitingForClaim ? 'warning' : 'info';
    switch (jurisdictionId) {
      case JurisdictionsEnum.VIC:
        return osrCaseStatus != null && TransactionCreatedOsrCaseStatus.includes(osrCaseStatus) ? (
          <MessageNotificationV2 //
            variant={variant}
            primary={osrCaseStatus === OsrCaseStatusEnum.WaitingForClaim ? 'Case reference created.' : 'Stamp Duty in progress.'}
            {...(!!messages.length && {
              expandableContent: (
                <MessageList //
                  variant={variant}
                  messages={messages.map(m => m.complianceMessage)}
                />
              )
            })}
            secondary={
              osrCaseStatus === OsrCaseStatusEnum.WaitingForClaim ? (
                <>
                  Please visit{' '}
                  <Link href="https://www.sro.vic.gov.au/duties-online" target="_blank" rel="noopener noreferrer" variant="subtitle2" underline="always" color="textPrimary">
                    Duties Online (DOL)
                  </Link>{' '}
                  to complete your transaction details and verify stamp duty
                </>
              ) : null
            }
          />
        ) : null;
      case JurisdictionsEnum.QLD:
        return (
          <MessageNotificationV2 //
            variant="warning"
            primary="Stamp Duty transaction created."
            secondary={
              <>
                Please visit{' '}
                <Link
                  href="https://amun5.osr.qld.gov.au/osrconnect/obligation/Obligation.xhtml"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="subtitle2"
                  underline="always"
                  color="textPrimary"
                >
                  OSR Connect
                </Link>{' '}
                to complete your transaction details and verify stamp duty.
              </>
            }
          />
        );
      default:
        return null;
    }
  };

  switch (status) {
    case StampDutyProgressStatusEnum.CreatingTransaction:
      return creatingTransaction;
    case StampDutyProgressStatusEnum.TransactionCreated:
      return transactionCreated();
    case StampDutyProgressStatusEnum.TransactionCancellationError:
      if (jurisdictionId === JurisdictionsEnum.VIC) {
        const { errors, warnings } = regroupIntoErrorsAndWarningsSelector(messages);
        return (
          <StampDutyVerificationError //
            warnings={warnings.map(convertComplianceToMessageGridModel)}
            errors={errors.map(convertComplianceToMessageGridModel)}
          />
        );
      }
      return null;
    case StampDutyProgressStatusEnum.TransactionCreationError:
      switch (jurisdictionId) {
        case JurisdictionsEnum.VIC:
        case JurisdictionsEnum.QLD:
          const { errors, warnings } = regroupIntoErrorsAndWarningsSelector(messages);

          return (
            <StampDutyCreationError //
              warnings={warnings.map(convertComplianceToMessageGridModel)}
              errors={errors.map(convertComplianceToMessageGridModel)}
            />
          );
        default:
          return null;
      }
    case StampDutyProgressStatusEnum.Verifying:
      return <MessageNotificationV2 variant="loading">Verifying stamp duty</MessageNotificationV2>;
    case StampDutyProgressStatusEnum.VerificationError:
      const { errors, warnings } = regroupIntoErrorsAndWarningsSelector(messages);
      return (
        <StampDutyVerificationError //
          warnings={warnings.map(convertComplianceToMessageGridModel)}
          errors={errors.map(convertComplianceToMessageGridModel)}
        />
      );
    case StampDutyProgressStatusEnum.Verified:
      if (jurisdictionId === JurisdictionsEnum.QLD) {
        return (
          <MessageNotificationV2 //
            variant="success"
            primary="Stamp Duty successfully verified."
            secondary={resolveVerifiedDescription(jurisdictionId, payableDuty, paymentOption)}
          />
        );
      }
      return (
        <MessageNotificationV2 //
          variant="success"
          primary="Stamp Duty successfully verified."
          secondary={resolveVerifiedDescription(jurisdictionId, payableDuty)}
          {...(!!messages.length && {
            expandableContent: (
              <MessageList //
                variant="success"
                messages={messages.map(m => m.complianceMessage)}
              />
            )
          })}
        />
      );

    case StampDutyProgressStatusEnum.Paid:
      return <MessageNotificationV2 variant="success">Stamp Duty successfully verified and paid in full.</MessageNotificationV2>;
    case StampDutyProgressStatusEnum.CancellingTransaction:
      if (jurisdictionId === JurisdictionsEnum.VIC) {
        return <MessageNotificationV2 variant="loading">Cancelling stamp duty</MessageNotificationV2>;
      }

      return null;
    case StampDutyProgressStatusEnum.ReadyForTransactionCreation:
      if (osrCaseStatus === OsrCaseStatusEnum.Cancelled && jurisdictionId === JurisdictionsEnum.VIC) {
        return <MessageNotificationV2 variant="success" primary="Stamp Duty case reference has been cancelled." />;
      }
      return null;
    default:
      return null;
  }
}

export default React.memo(ProgressMessageNotification);
