import { QldNameChange } from '@sympli-mfe/document-components/party-form/qld/2-23/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { QLD_WOC_PARTY_FORM_CONFIG } from '../../config';
import { getSelectedCaveat } from '../../helpers';
import { CaveatModel, ProprietorModel, WithdrawalOfCaveat2_23_1Model } from '../../models';

// import * as yup from 'yup';
interface PartyBookValidationContext {
  withdrawalOfCaveats: CaveatModel[];
  registeredProprietors: ProprietorModel[] | undefined;
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: WithdrawalOfCaveat2_23_1Model): PartyBookValidationContext => {
  return {
    withdrawalOfCaveats: parentContext.withdrawCaveats,
    registeredProprietors: parentContext.registeredProprietors
  };
};

const checkShouldValidateItem = (context: PartyBookValidationContext, partyIdForCheck: PartyModel<QldNameChange>['id']) => {
  // caveators party check
  const selectedCaveat = getSelectedCaveat(context.withdrawalOfCaveats);
  const partyInCaveator = selectedCaveat?.caveators?.some(e => e.partyBookId === partyIdForCheck) ?? false;
  if (partyInCaveator) return true;
  // proprietors party check
  return context.registeredProprietors?.some(e => e.partyBookId === partyIdForCheck) ?? false;
};

const yupPartyItem = resolvePartyModelValidationSchema<QldNameChange, PartyBookValidationContext>(QLD_WOC_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<QldNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
