import { Action, createReducer } from 'typesafe-actions';

import { actionFetchGroupLodgement, actionUpdateGroupLodgement } from '../actions/groups';

export interface GroupLodgementApiResponse {
  daysUntilLodgement: number;
}

export interface GroupLodgementState {
  detail: GroupLodgementApiResponse;
  isLoading: boolean;
  error?: string;
}

const initialState: GroupLodgementState = {
  detail: {
    daysUntilLodgement: 10
  },
  isLoading: false,
  error: undefined
};

const reducer = createReducer<
  //
  GroupLodgementState,
  Action
>(initialState)
  .handleAction(actionFetchGroupLodgement.request, (state): GroupLodgementState => {
    return {
      ...state,
      error: undefined,
      isLoading: true
    };
  })
  .handleAction(actionFetchGroupLodgement.success, (state, action): GroupLodgementState => {
    return {
      ...state,
      detail: action.payload.data,
      isLoading: false
    };
  })
  .handleAction(actionFetchGroupLodgement.failure, (state, action): GroupLodgementState => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateGroupLodgement, (state, action): GroupLodgementState => {
    return {
      ...state,
      detail: action.payload
    };
  });

export default reducer;
