import React from 'react';

import { useMatch } from 'react-router-dom';

import TopPanelIconLink from './TopPanelIconLink';

interface Props {
  pattern: string;
  // props for presentational component
  icon: JSX.Element;
  to: string;
  dataTestId?: string;
}

function TopPanelIconLinkContainer({ pattern, ...rest }: Props) {
  const match = useMatch(pattern);

  return <TopPanelIconLink isActive={Boolean(match)} {...rest} />;
}

export default React.memo(TopPanelIconLinkContainer);
