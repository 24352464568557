import _get from 'lodash-es/get';

import { PartyCapacityModel } from '@sympli-mfe/document-forms-framework/core/models';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL } from './visibilityChecks';

interface Props {
  name: string;
  isReadonly: boolean;
  partyCapacity?: PartyCapacityModel;
}

const PartyCapacity = ({ isReadonly, partyCapacity }: Props) => {
  return isReadonly && partyCapacity?.capacity ? (
    <FlexLayout flexDirection="column" fullWidth>
      <ReadOnlyField label="Party capacity" value={partyCapacity.capacity.toString()} />
      {VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL(partyCapacity) && partyCapacity.details && <ReadOnlyField label="Party capacity detail" value={partyCapacity.details} />}
    </FlexLayout>
  ) : null;
};

export default PartyCapacity;
