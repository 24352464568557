import * as React from 'react';

import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import endpoints from '@sympli/api-gateway/endpoints';
import { UnsignSourceFundsApiResponse } from '@sympli/api-gateway/models';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconTimes } from '@sympli/ui-framework/icons';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionUpdateDirectionsListSourceFundsStatus } from 'src/containers/workspace/financial/directions/actions';
import { directionUpdateHelper } from 'src/containers/workspace/financial/directions/helpers';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { useStyles } from '../sharedStyles';

interface Props {
  queryParams: WorkspaceDetailRouteParams;
  isLoading?: boolean;
  setIsWorkflowLoading: (isLoading: boolean) => void;
  dispatch: SafeDispatch<Action> | Dispatch<Action>;
}

function UnsignSourceFunds(props: Props): JSX.Element {
  const classes = useStyles();
  const { isLoading } = props;

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    const { queryParams, setIsWorkflowLoading, dispatch } = props;
    setIsWorkflowLoading(true);
    endpoints
      .unsignSourceFunds(queryParams)
      .then((response: UnsignSourceFundsApiResponse) => {
        // update the initial state values
        const { participantId, workspaceId } = queryParams;
        batch(() => {
          directionUpdateHelper({
            dispatch,
            newDirectionForm: { documentSummary: response },
            newDistributionsParticipantData: { distributionStatus: response.status, isPartiallySigned: response.isPartiallySigned },
            participantId,
            workspaceId
          });
          dispatch(actionUpdateDirectionsListSourceFundsStatus({ financialStatus: response.sourceFundsSummary!.financialStatus }));
          dispatch(
            actionOpenGlobalSimpleNotification({
              //
              message: 'Source funds were unsigned.',
              variant: 'new-success'
            })
          );
        });
        setIsWorkflowLoading(false);
      })
      .catch(error => {
        dispatch(actionCreateGlobalErrorMessage(error));
        setIsWorkflowLoading(false);
      });
  };

  return (
    <MenuItem onClick={handleOnClick}>
      <IconTimes className={classes.menuItemIconUnsign} />
      <Typography className={classes.menuItemLabel}>Unsign Source Funds</Typography>
      {isLoading && <InlineLoader size={18} />}
    </MenuItem>
  );
}

export default UnsignSourceFunds;
