import * as React from 'react';

import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { TabProps } from '@mui/material/Tab';

import SympliButton from '@sympli/ui-framework/components/sympli-button';

export default React.forwardRef((props: TabProps<'a', MuiButtonProps>, ref: any) => {
  return <SympliButton ref={ref} {...props} color="inherit" />;
});
