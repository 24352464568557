import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    partyOrAddressTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      display: 'block'
    },
    partyOrAddressDisplay: {
      marginBottom: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: theme.spacing(2.5)
      }
    },
    checkboxLabelRoot: {
      marginBottom: 0,
      // The Tickle-UI Framework checbox is not prviding any means of overriding Material UI checkbox's classes,
      // so we have to drill down into it using the FormControlLabel's classes.
      '& > span:first-child': {
        height: 'unset'
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
