import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import { isAfter } from 'date-fns';
import Divider from '@mui/material/Divider';
import { ClassNameMap } from '@mui/styles/withStyles';

import DatePicker from '@sympli/ui-framework/components/form/base-components/date-picker';
import Radio, { RadioClassKeys } from '@sympli/ui-framework/components/form/base-components/radio';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import { postpone } from './helpers';
import { CUSTOM_DATE_OPTIONS, DATE_OPTIONS, DateTypeEnum, MAX_DATE, MIN_DATE, WEEK_OPTIONS } from './models';
import { useStyles } from './styles';

export type LodgementDatepickerProps = {
  initLodgementDate: Date;
  className?: string;
  isUpdating: boolean;
  onCancel?(): void;
  onChange?(date: Date): void;
};

function LodgementDatepicker(props: LodgementDatepickerProps) {
  const { initLodgementDate, className, isUpdating, onCancel, onChange } = props;
  const classes = useStyles();
  const [dateType, setDateType] = useState<DateTypeEnum | null>(null);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [error, setError] = React.useState<string>('');

  const nakedRadioClasses: Partial<ClassNameMap<keyof ReturnType<RadioClassKeys>>> = useMemo(
    () => ({
      formControl: classes.radioFormControl,
      formControlLabel: classes.radioFormControlLabel,
      formControlLabelRoot: classes.formControlLabelRoot
    }),
    [classes]
  );

  const handleOnDateChange = useCallback(
    (_unusedEvent: React.ChangeEvent<HTMLInputElement>, dateType: DateTypeEnum) => {
      setDateType(dateType);
      switch (dateType) {
        case DateTypeEnum.ONE_DAY:
          setCustomDate(postpone(initLodgementDate, 1));
          break;
        case DateTypeEnum.TWO_DAYS:
          setCustomDate(postpone(initLodgementDate, 2));
          break;
        case DateTypeEnum.ONE_WEEK:
          // 5 days as there are 5 business days in a week
          setCustomDate(postpone(initLodgementDate, 5));
          break;
        case DateTypeEnum.CUSTOM:
          setCustomDate(null);
          break;
      }
    },
    [setDateType, setCustomDate, initLodgementDate]
  );

  const handleOnChangeCustomDate = useCallback(
    (date: Date | null) => {
      setCustomDate(date);
      if (date && isAfter(date, MAX_DATE)) {
        setError('Cannot postpone more than 50 days');
      } else {
        setError('');
      }
    },
    [setCustomDate, setError]
  );

  const disableWeekends = useCallback((day: Date) => day.getDay() === 0 || day.getDay() === 6, []);

  const handleOnClick = useCallback(() => {
    customDate && onChange?.(customDate);
  }, [customDate, onChange]);

  return (
    <FlexLayout flexDirection="column" className={classNames(classes.root, className)}>
      <FlexLayout justifyContent="space-evenly" className={classes.inputContainer}>
        <div className={classes.rangeTypeColumn}>
          <Radio //
            options={DATE_OPTIONS}
            classes={nakedRadioClasses}
            className={classes.radioRoot}
            onChange={handleOnDateChange}
            format="number"
            vertical={true}
            value={dateType}
            aria-label="Postpone by day"
            dataName="postponeByDay"
          />
          <Divider className={classes.divider} />
          <Radio //
            options={WEEK_OPTIONS}
            classes={nakedRadioClasses}
            className={classes.radioRoot}
            onChange={handleOnDateChange}
            format="number"
            vertical={true}
            value={dateType}
            aria-label="Postpone by week"
            dataName="postponeByWeek"
          />
        </div>
        <FlexLayout flexDirection="column" className={classes.customDatesColumn}>
          <Radio //
            options={CUSTOM_DATE_OPTIONS}
            classes={nakedRadioClasses}
            className={classes.radioRoot}
            onChange={handleOnDateChange}
            format="number"
            value={dateType}
            aria-label="Date"
            dataName="postponeByCustomDay"
          />
          {dateType === DateTypeEnum.CUSTOM && (
            <DatePicker
              className={classes.customDateInput}
              value={customDate}
              label="Postpone until"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              // TODO test UI behavior once mui upgrade is completed
              // maxDateMessage="Cannot postpone more than 90 days"
              onChange={handleOnChangeCustomDate}
              classes={{ helperTextError: classes.helperTextError }}
              shouldDisableDate={disableWeekends}
              dataName="customDatePicker"
              error={error}
            />
          )}
        </FlexLayout>
      </FlexLayout>
      <WizardStepper //
        onBack={onCancel}
        backLabel="Cancel"
        onNext={handleOnClick}
        nextLabel="Update"
        isLoading={isUpdating}
        disabled={!customDate}
        className={classes.stepper}
      />
    </FlexLayout>
  );
}

export default React.memo(LodgementDatepicker);
