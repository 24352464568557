import * as React from 'react';

import { useFeatureFlag } from 'src/hooks';
import { FeatureToggleEnum } from './models';

interface Props {
  featureToggleType: FeatureToggleEnum | FeatureToggleEnum[];
  // provide what should be rendered if feature is not allowed. Defaults to null.
  fallback?: React.ReactNode;
}

function FeatureToggle(props: React.PropsWithChildren<Props>) {
  const {
    //
    featureToggleType,
    fallback = null,
    children: childrenProp,
    ...rest
  } = props;

  const isFeatureEnabled = useFeatureFlag(featureToggleType);

  if (isFeatureEnabled) {
    const children = React.Children.map(childrenProp, child => {
      if (!React.isValidElement(child)) {
        return child;
      }
      // it's important that we forward rest of the properties that might have been injected by parent
      // e.g Tabs -> Tab
      return React.cloneElement(child, rest);
    });
    // children could be undefined but JSX return element can only be JSX or null
    return children ? children : null;
  }

  if (typeof fallback === 'function') {
    return fallback(rest);
  }

  if (!React.isValidElement(fallback)) {
    return fallback;
  }

  return React.cloneElement(fallback, rest);
}

export default React.memo(FeatureToggle);
