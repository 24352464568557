import React from 'react';

import pluralize from 'pluralize';
import Divider from '@mui/material/Divider';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import { MessageNotificationContainer, MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';
import MessageGrid, { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';
import MessageList from '@sympli/ui-framework/components/message-notification/components/message-list';

import { AlertTypeEnum, WorkspaceNotificationModel } from 'src/containers/workspace/financial/detail/components/error-message-panel/models';
import { useStyles } from './styles';

export type LodgementErrorMessageNotificationProps = {
  workspaceId: string;
  participantId: string;
  errors?: MessageModel[];
  warnings?: MessageModel[];
  workspaceErrors?: WorkspaceNotificationModel;
  isAutoTacCeased?: boolean;
  lodgementComplianceId?: string;
  // if it is undefined, the open state is managed by MessageNotificationWithLocalStorage component
  // see the original logic here:
  // https://github.com/ticklesource/tickle-ui/commit/934bc41ca4e8737157c94d3bba78a2898e24d435#diff-3e68d8918b252b4aa23a6f57de4603c936bb93bd1b1b7aadbcee715b7c571d44R42-R47
  // boolean value (no matter )
  open?: boolean;
  // other
  onShowAllClick?(): void;
};

function LodgementErrorMessageNotification({
  //
  participantId,
  workspaceId,
  workspaceErrors,
  lodgementComplianceId,
  errors = [],
  warnings = [],
  isAutoTacCeased = false,
  // other
  open,
  onShowAllClick
}: LodgementErrorMessageNotificationProps) {
  const classes = useStyles();

  return (
    <MessageNotificationContainer>
      {isAutoTacCeased && (
        <MessageNotificationWithLocalStorage
          storageNamespace={`${workspaceId}/${participantId}`}
          messageId="autoTacCeasedWarning"
          open={open}
          variant="warning"
          primary="Automated Title Activity Check (TAC) has ceased as it's been more than 90 days since workspace created. Please initiate manual TAC from the title page if needed."
        />
      )}
      {workspaceErrors?.alertHeader && (
        <MessageNotificationWithLocalStorage //
          storageNamespace={`${workspaceId}/${participantId}/workspaceErrors`}
          messageId={workspaceErrors.notificationId}
          open={open}
          variant={workspaceErrors.alertType === AlertTypeEnum.Warning ? 'warning' : 'error'}
          primary={workspaceErrors.alertHeader ?? 'We found issues in this workspace'}
          expandableContent={
            !workspaceErrors?.messages?.length ? null : (
              <MessageList //
                variant={workspaceErrors.alertType === AlertTypeEnum.Warning ? 'warning' : 'error'}
                messages={workspaceErrors.messages}
              />
            )
          }
        />
      )}
      {!!errors.length && (
        <MessageNotificationWithLocalStorage //
          storageNamespace={`${workspaceId}/${participantId}/lodgementErrors`}
          messageId={lodgementComplianceId}
          open={open}
          variant="error"
          primary={`${pluralize('error', errors.length, true)} found with the lodgement case`}
          expandableContent={
            <div className={classes.content}>
              <MessageGrid //
                variant="error"
                messageHeader="Land registry verification response"
                remarkHeader="Action required"
                messages={errors}
              />
              <Divider className={classes.dividerError} />
              <div className={classes.assistanceFooter}>
                <ButtonLink onClick={onShowAllClick} color="inherit">
                  Show verification results
                </ButtonLink>
                <br />
                If you require further assistance please contact customer support on <strong>1300 SYMPLI (1300 796 754)</strong>.
              </div>
            </div>
          }
        />
      )}
      {!!warnings.length && (
        <MessageNotificationWithLocalStorage //
          storageNamespace={`${workspaceId}/${participantId}/lodgementWarnings`}
          messageId={lodgementComplianceId}
          open={open}
          variant="warning"
          primary={`${pluralize('warning', warnings.length, true)} found with the lodgement case`}
          expandableContent={
            <div className={classes.content}>
              <MessageGrid //
                variant="warning"
                messageHeader="Land registry verification response"
                remarkHeader="Action required"
                messages={warnings}
              />
              <Divider className={classes.dividerWarning} />
              <div className={classes.assistanceFooter}>
                <ButtonLink onClick={onShowAllClick} color="inherit">
                  Show verification results
                </ButtonLink>
                <br />
                If you require further assistance please contact customer support on <strong>1300 SYMPLI (1300 796 754)</strong>.
              </div>
            </div>
          }
        />
      )}
    </MessageNotificationContainer>
  );
}

export default React.memo(LodgementErrorMessageNotification);
