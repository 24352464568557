import * as React from 'react';
import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';

import { LodgementCaseStatusEnum } from '@sympli/api-gateway/enums';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { getDefaultLodgementCaseId, resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { actionUpdateWorkspaceStatusToLodgeInProgress } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useSafeDispatch } from 'src/hooks';
import { useLrsInfo } from 'src/reducers/lrsInfo';
import { sendLodgementRequest } from './api';
import LodgementProgress from './LodgementProgress';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2084897049/Tasks+tab+-+data+dependency+insights
export default function LodgementProgressContainer({
  workspaceId,
  participantId,
  backLink,
  onSelectReportsTab
}: {
  // route parameters
  workspaceId: string;
  participantId: string;
  // other
  backLink: string;
  onSelectReportsTab?(): void;
}) {
  const classes = useStyles();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const lrsInfo = useLrsInfo(workspaceBasicInfoState.detail?.jurisdictionId);

  const lodgementCaseStatusId: LodgementCaseStatusEnum | undefined = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);
  const lodgementCaseId = getDefaultLodgementCaseId(workspaceBasicInfoState.detail?.lodgementCases);
  const dispatch = useSafeDispatch(useDispatch());

  const handleOnLodgementAttemptClick = useCallback(async () => {
    try {
      await sendLodgementRequest(workspaceId, participantId, lodgementCaseId ?? '');
      dispatch(actionUpdateWorkspaceStatusToLodgeInProgress(workspaceId));
    } catch (error) {
      dispatch(actionCreateGlobalErrorMessage(error));
    }
  }, [workspaceId, participantId, lodgementCaseId, dispatch]);

  if (workspaceBasicInfoState.isLoading) {
    return (
      <div className={classes.loader}>
        <BlockLoader />
      </div>
    );
  }

  if (workspaceBasicInfoState.status === 'rejected') {
    return (
      <FormHelperText //
        error={true}
        role="alert"
      >
        {workspaceBasicInfoState.error}
      </FormHelperText>
    );
  }

  return (
    <LodgementProgress
      // basic workspace info
      workspaceStatusId={workspaceBasicInfoState.detail!.workspaceStatusId}
      jurisdictionId={workspaceBasicInfoState.detail!.jurisdictionId}
      lodgementCaseStatusId={lodgementCaseStatusId}
      // LRS info
      isValidOperatingHours={lrsInfo.isValidOperatingHoursForRegOnly}
      // other
      backLink={backLink}
      onSelectReportsTab={onSelectReportsTab}
      onLodgementAttemptClick={handleOnLodgementAttemptClick}
    />
  );
}
