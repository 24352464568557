import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

export interface PanelProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'navigation' | 'sidebar';
}

type Props = PanelProps & WithStyles<ClassKeys>;

class Panel extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    type: 'sidebar'
  };

  private globalCssName = 'tickle-panel';

  render() {
    const { classes, className, type, children, ...otherProps } = this.props;
    const globalCssName = `${this.globalCssName}-${type}`;

    return (
      <div className={classNames(classes.root, globalCssName, classes[type as string], className)} {...otherProps}>
        <div className={classNames(classes.content, `${globalCssName}-content`)}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(Panel);
