import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    landDescriptionsBox: {
      paddingBottom: 5,
      marginBottom: 14,
      '&:after': {
        marginLeft: 239
      },
      '&:before': {
        marginLeft: 238
      }
    },

    partLandSection: {
      marginTop: 0,
      paddingBottom: 0,
      width: '100%'
    },
    errorMessage: {
      paddingBottom: 10
    },
    partLandDescription: {
      marginBottom: 10
    }
  }),
  { name: 'PartLandAffected' }
);
