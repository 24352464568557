import { createAsyncAction } from 'typesafe-actions';

import { DocumentMultiSignApiResponse } from './models';
import { DocumentMultiSignArgs } from './reducer';

export const actionFetchDocumentMultiSign = createAsyncAction(
  //
  'FETCH_DOCUMENT_MULTISIGN',
  'FETCH_DOCUMENT_MULTISIGN_SUCCESS',
  'FETCH_DOCUMENT_MULTISIGN_ERROR'
)<
  //
  DocumentMultiSignArgs,
  { data: DocumentMultiSignApiResponse },
  { error: Error }
>();
