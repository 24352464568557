import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { MessageNotificationVariant } from '@sympli/ui-framework/components/message-notification';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    divider: {
      marginBottom: 8,
      backgroundColor: ({ variant }: { variant: MessageNotificationVariant }) => {
        return variant === 'error' ? theme.palette.error.main : theme.palette.secondary.main;
      }
    },
    wrapper: {
      paddingLeft: 18,
      flexGrow: 1
    },
    assistanceFooter: {
      padding: '10px 0',
      fontSize: 13,
      fontStyle: 'italic',
      textAlign: 'center',
      '& button': {
        fontStyle: 'initial',
        fontSize: 14
      }
    }
  }),
  {
    name: 'ExpandableContent'
  }
);
