import * as React from 'react';

import { useDispatch } from 'react-redux';

import { UserRoleEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import { UserDetailsState, useUserDetails } from 'src/containers/settings/settings/reducers/userDetails';
import { UserLinkedDevicesFeedState } from 'src/containers/settings/settings/reducers/userLinkedDevicesFeed';
import { UserSuspensionState } from 'src/containers/settings/settings/reducers/userSuspension';
import { RoleEntityModel, UserDetailsPageTabEnum, UserGroupAccessModel } from 'src/containers/settings/settings/users-detail/models';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useFeatureFlag, useRouterParams, useSafeDispatch, useStoreSelector } from 'src/hooks';
import { actionFetchSubscriberSigningRule } from '../../subscriber-profile/actions/subscriberSigningRule';
import { SubscriberSigningRuleState, useSubscriberSigningRule } from '../../subscriber-profile/reducers/subscriberSigningRule';
import { actionFetchUserDetails } from '../actions/users';
import { UserReportsPermissionsState } from '../reducers/userReportsPermissions';
import { useReportsPermissionCheck } from '../users-setting/hooks/useReportsPermissionCheck';
import { userPermissionsFormValueSelector } from './selectors';
import UsersDetail from './UsersDetail';

interface UserDetailRouteParams {
  userId: string;
  tab: UserDetailsPageTabEnum;
}

function UsersDetailContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const { userId, tab } = useRouterParams<UserDetailRouteParams>();

  const userDetails: UserDetailsState = useUserDetails(userId);
  const userProfile: UserProfileModel = useProfile().data!;
  const showReportPermissions = useReportsPermissionCheck();
  const userSuspension: UserSuspensionState = useStoreSelector(store => store.userSuspension);
  const userReportsPermissions: UserReportsPermissionsState = useStoreSelector(store => store.userReportsPermissions);
  const userLinkedDevicesFeed: UserLinkedDevicesFeedState = useStoreSelector(store => store.userLinkedDevicesFeed);
  const userPermissionsState = useStoreSelector(store => store.userPermissions);
  const userPermissions = userPermissionsFormValueSelector({
    userPermissions: userPermissionsState
  });
  const userRoleOptions: RoleEntityModel[] | undefined = userPermissionsState.detail?.roles;
  const groupAccessOptions: UserGroupAccessModel[] | undefined = userPermissionsState.detail?.groups;
  const isDesignationEditable: boolean | undefined = userPermissionsState.detail?.isEditableUserCertifierDesignations;
  const isFormikLoading: boolean = useStoreSelector(store => store.submitFormik.isLoading);
  const biReportingFeatureFlag: boolean = useFeatureFlag(FeatureToggleEnum.bIReporting);
  const isEditMode = userId !== 'new';

  const subscriberSigningRule: SubscriberSigningRuleState = useSubscriberSigningRule();

  React.useEffect(() => {
    if (isEditMode) {
      if (userId !== userDetails.args?.userId) {
        dispatch(actionFetchUserDetails.request(userId));
      }
    }
  }, [dispatch, isEditMode, userDetails.args?.userId, userId]);

  React.useEffect(() => {
    // Only used when updating user permissions, standard users can't use this
    if (userProfile.role === UserRoleEnum.Admin || userProfile.role === UserRoleEnum.SuperAdmin) dispatch(actionFetchSubscriberSigningRule.request());
  }, [dispatch, userProfile.role]);

  return (
    <UsersDetail //
      // route params
      userId={userId}
      tab={tab}
      // redux
      userDetails={userDetails}
      userProfile={userProfile}
      userSuspension={userSuspension}
      userPermissions={userPermissions}
      userReportsPermissions={userReportsPermissions}
      userRoleOptions={userRoleOptions}
      groupAccessOptions={groupAccessOptions}
      isDesignationEditable={isDesignationEditable}
      userLinkedDevicesFeed={userLinkedDevicesFeed}
      isFormikLoading={isFormikLoading}
      biReportingFeatureFlag={biReportingFeatureFlag}
      dispatch={dispatch}
      loggedInUserRole={userProfile.role}
      defaultUserSigningRules={subscriberSigningRule.detail?.defaultUserSigningRules}
      showReportPermissions={showReportPermissions}
    />
  );
}

export default React.memo(UsersDetailContainer);
