// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    labelFlexLayout: {
      marginTop: 5
    },
    titleReferenceContainer: {
      width: 250
    },
    checkboxComponentRoot: {
      alignItems: 'flex-start'
    }
  }),
  {
    name: 'TitleReferences'
  }
);
