import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { ExtensionOfPriorityNoticeModel } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<ExtensionOfPriorityNoticeModel> = {
  workspaceTitleReferences: NO_FALLBACK,
  priorityNotices: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        dealingNumber: NO_FALLBACK,
        parties: NO_FALLBACK,
        titleReferences: NO_FALLBACK,
        expiry: NO_FALLBACK
      }
    }
  },
  documentNotification: NO_FALLBACK
};
