import * as React from 'react';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { ParticipantStatusEnum, PaymentMethodEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsOverviewApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { currency } from '@sympli/ui-framework/utils/formatters';

import DirectionHeaderBox from 'src/containers/workspace/financial/directions/components/direction-header-box';
import { distributionsParticipantOrderedListSelector } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/selectors';
import { accountDescriptionMapping, ASTERISK, isVendorAmountDirection, resolveBankTransferCategoryDescription } from 'src/containers/workspace/financial/directions/helpers';
import { SectionTypeEnum } from '../../../directions/components/settlement-directions-list/models';
import ParticipantCollapseBoxContainer from '../participant-collapse-box';
import { GridHead, LineItem, TotalAmount } from '../styled-components';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  currentParticipantId: string;
  allDirectionsDetail: WorkspaceDirectionsOverviewApiResponse;
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  openAll: boolean;
  breakdownLineItemsFeatureEnabled: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;
class ParticipantDistributions extends React.PureComponent<Props> {
  render() {
    const {
      allDirectionsDetail: { distributionsParticipants, totalDistributions }
    } = this.props;
    const acceptedParticipants = distributionsParticipants.filter(p => p.participant.participantStatus.id === ParticipantStatusEnum.Accepted);
    const distributionsParticipantOrderedList = distributionsParticipantOrderedListSelector(acceptedParticipants);

    return (
      <DirectionHeaderBox header="Payments">
        <GridHead detailColumn="Payee and payment details" />

        {distributionsParticipantOrderedList.map(this.renderParticipant)}

        <TotalAmount label="Total payments">{currency(totalDistributions)}</TotalAmount>
      </DirectionHeaderBox>
    );
  }

  private renderParticipant = (distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number], idx: number) => {
    const { workspaceParticipants, currentParticipantId, openAll } = this.props;
    const subTotalAmount = distributionsParticipant.subTotalDistributions;
    const hasItems = !!distributionsParticipant.distributions.length;
    return (
      <ParticipantCollapseBoxContainer
        key={idx}
        openAll={openAll}
        currentParticipantId={currentParticipantId}
        distributionsParticipant={distributionsParticipant}
        workspaceParticipants={workspaceParticipants}
        subTotalAmount={subTotalAmount}
        hasItems={hasItems}
        sectionType={SectionTypeEnum.Payments}
        renderCollapseDetail={this.renderParticipantDirectionList}
      />
    );
  };

  private getDirectionAddedByPurchaserDescription(
    participantRoleId: WorkspaceRoleEnum,
    directions: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]['distributions']
  ) {
    if (participantRoleId === WorkspaceRoleEnum.Vendor) {
      const hasDirectionAddedByPurchaser = directions.some(details => isVendorAmountDirection(participantRoleId, details));
      if (hasDirectionAddedByPurchaser) {
        return `${ASTERISK} Added by purchaser`;
      }
    }
    return '';
  }

  private renderParticipantDirectionList = (distributionsParticipant: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]) => {
    const { workspaceParticipants, breakdownLineItemsFeatureEnabled } = this.props;
    const { participantId, distributions } = distributionsParticipant;
    const participant = workspaceParticipants.find(p => p.id === participantId)!;
    const participantRoleId = participant?.workspaceRole.id;
    const desc = this.getDirectionAddedByPurchaserDescription(participantRoleId, distributions);
    return distributions.map((direction, idx) => {
      const isLast = idx === distributionsParticipant.distributions.length - 1;
      const description = idx === 0 ? desc : ''; // add desc at top left side
      return (
        <LineItem
          amount={currency(direction.amount, '')}
          description={description}
          withDivider={!isLast}
          key={idx}
          showBreakdownsCol={breakdownLineItemsFeatureEnabled}
          lineItemBreakdowns={direction.lineItemBreakdowns}
          breakdownCategory={direction.category}
          breakdownAccountDisplayName={direction.bankDetails?.accountDisplayName}
        >
          {this.renderAccountDetailCol(participantRoleId, direction)}
        </LineItem>
      );
    });
  };

  // TODO extract and merge with direction account renderDetail
  private renderAccountDetailCol(workspaceRole: WorkspaceRoleEnum, details: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]['distributions'][number]) {
    const { classes, breakdownLineItemsFeatureEnabled } = this.props;
    const asterisk = isVendorAmountDirection(workspaceRole, details) && `${ASTERISK} `;
    switch (details.paymentMethod) {
      case PaymentMethodEnum.HoldingAccount: {
        const { reference, shortfallAmount, acceptSurplus } =
          details.holdingAccountDetails as WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]['distributions'][number]['holdingAccountDetails'] & {
            reference?: string;
          };
        const { accountName, accountDescription } = details.bankDetails!;
        let description = '';
        if (shortfallAmount) {
          description = `Accepted amount: ${currency(Number(details.amount) - Number(shortfallAmount))} to ${currency(Number(details.amount))}`;
        } else {
          description = `Accepted amount: ${currency(Number(details.amount))}`;
        }
        if (acceptSurplus) {
          description = description + ' | All surplus accepted ';
        }

        return (
          <React.Fragment>
            <Typography className={classes.bold}>{accountDescription || accountName}</Typography>
            <Typography>Loan payout{!!reference && ` (${reference})`}</Typography>
            <Typography className={classes.description}>{description}</Typography>
          </React.Fragment>
        );
      }
      case PaymentMethodEnum.BankTransfer: {
        const { accountName, accountDescription, accountDisplayName } = details.bankDetails!;
        const { category, categoryOther } = details;
        const bankAccountDescription = accountDescriptionMapping(details.bankDetails!);
        const categoryDescription = resolveBankTransferCategoryDescription('Bank transfer', details.bankDetails!, category, categoryOther);
        // we want to display the accountDisplayName only for lodgement fees and if the feature flag is enabled
        const displayName = breakdownLineItemsFeatureEnabled && details.category.toLocaleLowerCase().startsWith('lodgement fee') ? accountDisplayName : null;

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              {asterisk}
              {displayName || accountDescription || accountName || 'Bank transfer'}
            </Typography>
            <Typography>{categoryDescription}</Typography>
            <Typography className={classes.description}>{bankAccountDescription}</Typography>
          </React.Fragment>
        );
      }
      case PaymentMethodEnum.TrustAccount: {
        const { bankDetails, category, categoryOther } = details;
        const { accountName, accountDescription } = bankDetails!;
        const bankAccountDescription = accountDescriptionMapping(bankDetails!);
        const categoryDescription = resolveBankTransferCategoryDescription('Trust account', bankDetails!, category, categoryOther);

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              {asterisk}
              {accountDescription || accountName || 'Trust account'}
            </Typography>
            <Typography>{categoryDescription}</Typography>
            <Typography className={classes.description}>{bankAccountDescription}</Typography>
          </React.Fragment>
        );
      }
      case PaymentMethodEnum.BPAY: {
        const { billerCode, billerName, billerReference, description } = details.bpayDetails!;

        const billerCodeText = !!billerCode && `BPAY biller code ${billerCode}`;
        const billerReferenceText = !!billerReference && `Reference number ${billerReference}`;

        const { category, categoryOther } = details;
        const desc: string = [billerCodeText, billerReferenceText].filter(Boolean).join(' | ');

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              {asterisk}
              {billerName || 'BPAY'}
            </Typography>
            <Typography>
              {category === 'Other' ? categoryOther : category}
              {description && ` (${description})`}
            </Typography>
            <Typography className={classes.description}>{desc}</Typography>
          </React.Fragment>
        );
      }
      default:
        return (
          <Typography>
            {asterisk}
            Incorrect direction item
          </Typography>
        );
    }
  }
}

const styledComponent = withStyles(styles)(ParticipantDistributions);

export default styledComponent;
