import React from 'react';

import { Form } from 'formik';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import TransactionDetails from './sections/transaction-details';
import TransfereeDetails from './sections/transferee-details';

export interface Context {
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  postalAddressFormConfig: AddressFieldFormConfig;
}

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();

  return (
    <>
      <Form className={className}>
        <TransfereeDetails />
        <TransactionDetails />
        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
