import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 14,
      minHeight: 32,
      marginBottom: 10
    },
    button: {
      marginLeft: 8
    },
    devHelper: {
      right: 0,
      color: `rgba(210, 105, 30, 0.5)`,
      bottom: -12,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'sans-serif'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
