import React, { memo } from 'react';

import Divider from '@mui/material/Divider';

import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';

import DuplicateWorkspace from './DuplicateWorkspace';
import { DuplicateWorkspaceItem } from './models';
import { useStyles } from './styles';

export interface Props {
  duplicateWorkspaces?: DuplicateWorkspaceItem[];
}

const DuplicateWorkspaces = ({ duplicateWorkspaces }: Props): JSX.Element | null => {
  const classes = useStyles();

  return duplicateWorkspaces?.length ? (
    <ArrowBox padding="none">
      {duplicateWorkspaces.map((duplicateWorkspace, index) => (
        <div key={index}>
          {index ? <Divider className={classes.divider} /> : null}
          <DuplicateWorkspace duplicateWorkspace={duplicateWorkspace} />
        </div>
      ))}
    </ArrowBox>
  ) : null;
};

export default memo(DuplicateWorkspaces);
