import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex'
    },
    radio: {
      '& label': {
        whiteSpace: 'normal'
      },
      '& div label': {
        flexGrow: 0
      },
      flex: '100%'
    },
    arrowBox: {
      marginTop: 0,
      marginBottom: 16,
      width: '100%'
    },
    tooltip: {
      textAlign: 'left',
      fontSize: 12
    },
    tooltipList: {
      margin: '8px 0',
      paddingLeft: 16
    },
    tooltipListItem: {
      lineHeight: '12px',
      '& + &': {
        paddingBottom: 4
      }
    },
    relatedDegree: {
      width: '100%',
      '& input': {
        width: 250
      },
      '& label': {
        position: 'relative',
        textWrap: 'wrap'
      },
      '& div': {
        width: 250,
        marginTop: 3
      }
    }
  }),
  {
    name: 'TransactionData'
  }
);
