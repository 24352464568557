import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import { WithStyles } from '@mui/styles/withStyles';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentFormProps, RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { getDealingNumbersSelectedInOtherDms, getOtherDmDocIds } from './helpers';
import { ApiDischargeOfMortgage_2_23_1_Model, DischargeOfMortgage_2_23_1_Model } from './models';
import SectionMortgagees from './sections/mortgagees';
import Mortgages from './sections/mortgages';
import SectionSetOfOperativeWords from './sections/set-of-operative-words';
import SectionTitleReferences from './sections/title-references';
import { ClassKeys } from './styles';
import { VISIBILITY_CHECK_MORTGAGEES, VISIBILITY_CHECK_TITLE_REFERENCES } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache

const fieldName = modelKey<DischargeOfMortgage_2_23_1_Model>();
type FormValues = DischargeOfMortgage_2_23_1_Model;

export type Props = DocumentFormProps<FormValues, ApiDischargeOfMortgage_2_23_1_Model> & WithStyles<ClassKeys>;

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel, workspaceDocuments } = useDocumentContext();
  const formikProps = useFormikContext<DischargeOfMortgage_2_23_1_Model>();
  const { values, setFieldValue } = formikProps;
  const focusOnMortgagesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgageesRef = React.useRef<ButtonBaseActions>(null);
  const { workspaceId, participantId, documentId } = queryParams;

  const otherDmDocumentIds = React.useMemo(() => getOtherDmDocIds(documentId, workspaceDocuments), [documentId, workspaceDocuments]);
  const getDealingNumbersSelectedInOtherDmsWrapper = useCallback(
    // get dealing numbers which are selected in other DM documents in the workspace
    async (
      workspaceId: string,
      participantId: string,
      documentIds: string[] //
    ) => getDealingNumbersSelectedInOtherDms(workspaceId, participantId, documentIds),
    []
  );

  useEffect(() => {
    const focusOnFirstItem = focusOnMortgagesRef.current || focusOnTitleReferencesRef.current || focusOnMortgageesRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  useEffect(() => {
    // WEB-21972 update formik field with selected dealing numbers in other dm documents in the workspace
    // this value is being used by Mortgages component to display a warning
    getDealingNumbersSelectedInOtherDmsWrapper(workspaceId, participantId, otherDmDocumentIds) //
      .then(value => setFieldValue(fieldName('dealingNumbersSelectedInOtherDms'), value, false));
  }, [otherDmDocumentIds, participantId, workspaceId, getDealingNumbersSelectedInOtherDmsWrapper, setFieldValue]);

  return (
    <>
      <Form className={className}>
        <Mortgages //
          name={fieldName('mortgages')}
          formikProps={formikProps}
          disabled={disabled}
          focusRef={focusOnMortgagesRef}
        />

        {VISIBILITY_CHECK_TITLE_REFERENCES(values) && (
          <SectionTitleReferences //
            name={fieldName('titleReferences')}
            disabled={disabled}
            focusRef={focusOnTitleReferencesRef}
          />
        )}

        {VISIBILITY_CHECK_MORTGAGEES(values) && (
          <>
            <SectionMortgagees //
              name={fieldName('mortgagees')}
              formikProps={formikProps}
              disabled={disabled}
              dialogPortalId={dialogPortalId}
              focusRef={focusOnMortgageesRef}
            />
            <SectionSetOfOperativeWords //
              name={fieldName('setOfOperativeWords')}
              formikProps={formikProps}
              disabled={disabled}
            />
          </>
        )}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
