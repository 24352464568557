// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    divider: {
      marginTop: 0,
      marginBottom: 8
    },
    partyTypeRadioLabels: {
      flexGrow: 'unset'
    }
  }),
  {
    name: 'DeceasedProprietor'
  }
);
