import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiSubLease2_21_1Model, SubLease2_21_1Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export default function DocumentForm(props: DocumentFormProps<SubLease2_21_1Model, ApiSubLease2_21_1Model>): JSX.Element {
  const { validateDebounce } = useDocumentContext();

  const validate = useMemoizableValidation<SubLease2_21_1Model>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
      />
    </DndContextProvider>
  );
}
