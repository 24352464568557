import * as React from 'react';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { DEALING_REQUIREMENT_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { DealingRequirementsDetailModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
  className?: string;
}

function DealingRequirementsDetail({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DealingRequirementsDetailModel>(name);

  return (
    <div data-section={name} className={className}>
      <Field //
        aria-label="Dealing Requirement"
        name={fieldName('dealingRequirement')}
        component={SelectField}
        debug={debug}
        disabled={disabled}
        format="number"
        options={DEALING_REQUIREMENT_TYPE_LOOKUP_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
        fullWidth
      />
    </div>
  );
}

export default React.memo(DealingRequirementsDetail);
