import * as React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { ENCUMBRANCE$BENEFITED_LAND_REFERENCE$REFERENCE_TYPE_LOOKUP_OPTIONS, Encumbrance$BenefitedLandReferences$ReferenceTypeEnum as ReferenceTypeEnum } from '../../../enums';
import { BenefitedLandReferenceModel } from '../../../models';
import { VISIBILITY_CHECK_REFERENCE_NUMBER } from '../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
}

function BenefitedLandReference({ name }: Props): JSX.Element {
  const classes = useStyles();
  const [, { value }] = useField<BenefitedLandReferenceModel>(name);
  const { referenceType } = value;

  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<BenefitedLandReferenceModel>(name);

  const isReferenceNumberVisible = VISIBILITY_CHECK_REFERENCE_NUMBER(value);

  return (
    <div data-name={name} className={classes.root}>
      <Field //
        aria-label="Reference type"
        name={fieldName('referenceType')}
        component={RadioField}
        disabled={disabled}
        format="number"
        options={ENCUMBRANCE$BENEFITED_LAND_REFERENCE$REFERENCE_TYPE_LOOKUP_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
        className={classNames(isReferenceNumberVisible && classes.radioSelected)}
      />
      {isReferenceNumberVisible && (
        <ArrowBox //
          className={classes.referenceBox}
          backgroundColor="darkGrey"
          offset={referenceType === ReferenceTypeEnum.LandTitleReference ? -4 : 126} // TODO: Use element bounding rect to calculate arrow offset
        >
          <Field //
            name={fieldName('referenceNumber')}
            component={InputField}
            disabled={disabled}
            label={referenceType === ReferenceTypeEnum.LrMemorandumID ? 'MCP number' : 'Title reference'}
            validationOverflowDirection="horizontal"
          />
        </ArrowBox>
      )}
    </div>
  );
}

export default React.memo(BenefitedLandReference);
