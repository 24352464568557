import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum StampDutyPaymentMethodEnum {
  CommittedAsPartOfElno = 0,
  PaidToCommissioner = 1,
  ReceivedBySelfAssessor = 2
}

export const stampDutyPaymentOptions: Array<LookupEnumModel<StampDutyPaymentMethodEnum>> = [
  { id: StampDutyPaymentMethodEnum.PaidToCommissioner, name: 'Paid to the Commissioner before settlement' },
  {
    id: StampDutyPaymentMethodEnum.ReceivedBySelfAssessor,
    name: 'Received by you before settlement (select this option if the transaction is nil duty due to a first home concession claim)'
  },
  {
    id: StampDutyPaymentMethodEnum.CommittedAsPartOfElno,
    name: 'You commit to pay the duty as part of the settlement. Any committed payment must be paid into your nominated account and then forwarded to us.'
  }
];

export interface StampDutyPaymentBankDetailsModel {
  accountName: string;
  bsb: string;
  accountNumber: string;
}

export interface QldStampDutyFormDataModel {
  paymentOption: StampDutyPaymentMethodEnum | string;
  bankDetails?: StampDutyPaymentBankDetailsModel;
}
