import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import yupApplicableInstrument from '../../components/applicable-instrument/validationSchema';
import { ActionProhibitedEnum } from '../../enums';
import { ApplicableInstrumentModel, ExtentOfProhibitionModel } from '../../models';

const yupApplicableInstruments = yup
  .array<ApplicableInstrumentModel>() //
  .of(yupApplicableInstrument)
  .max(20, msg.MAX_ITEMS(20))
  .defined();

const yupProhibition = yup
  .object<ExtentOfProhibitionModel>({
    actionProhibited: yup.mixed().oneOf(Object.values(ActionProhibitedEnum), msg.REQUIRED).required(msg.REQUIRED),
    notApplicableInstruments: yup
      .array<ApplicableInstrumentModel>()
      .when('actionProhibited', {
        is: ActionProhibitedEnum.AbsoluteWithExceptions,
        then: yupApplicableInstruments,
        otherwise: yup.array<ApplicableInstrumentModel>()
      })
      .defined()
  })
  .defined();

export default yupProhibition;
