import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    foreignShareholders: {
      padding: '20px 20px 10px 20px'
    },
    addButton: {
      marginBottom: 10
    },
    fullWidth: {
      width: '100%',
      maxWidth: '100%'
    },
    foreignShareholderContent: {
      marginBottom: 10
    },
    country: {
      marginRight: 10
    },
    percentLayout: {
      marginTop: -17
    },
    percentLabel: {
      marginBottom: 2
    },
    quarterWidth: {
      width: '25%'
    },
    percentFormControl: {
      textAlign: 'right'
    },
    formGroupIconPrefix: {
      paddingLeft: 0,
      marginRight: 5,
      '& > *:first-child': {
        position: 'relative',
        inset: 'unset',
        padding: 0
      }
    },
    formGroup: {
      padding: 0,
      margin: '0px 0px 10px 0px',
      '& + &': {
        padding: 0,
        borderTop: 'none'
      }
    },
    formGroupTitle: {
      flex: 'none',
      width: 'auto',
      marginTop: 5,
      paddingRight: 0
    },
    errorMessage: {
      fontWeight: 'normal'
    }
  }),
  {
    name: 'ForeignShareholders'
  }
);
