import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    topBorder: {
      borderTop: `solid 1px ${theme.palette.divider}`
    }
  }),
  {
    name: 'TransfereeDetails'
  }
);
