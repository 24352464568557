import CircularProgress from '@mui/material/CircularProgress';

import { DocumentAttachment } from '../../models';
import DocumentAttachments from '../document-attachments';
import { useStyles } from './styles';

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
  attachments?: DocumentAttachment[];
  participantId: string;
  workspaceId: string;
  documentId: string;
}

function CheckboxLabel({ children, isLoading, attachments, participantId, workspaceId, documentId }: Props) {
  const classes = useStyles();
  return (
    <>
      <span className={classes.checkboxLabel}>{children}</span>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <DocumentAttachments attachments={attachments} workspaceId={workspaceId} participantId={participantId} documentId={documentId} />
      )}
    </>
  );
}

export default CheckboxLabel;
