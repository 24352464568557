import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    smallRow: {
      fontSize: 13,
      lineHeight: '15px'
    },
    sectionSpacingTop: {
      paddingTop: 10
    },
    iconRoot: {
      minWidth: 24
    },
    iconAlignItemsFlexStart: {
      marginTop: 4
    },
    listItem: {}, // do not remove
    listItemLink: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    noTitles: {
      fontSize: 13,
      color: theme.palette.text.secondary,
      fontStyle: 'italic'
    }
  }),
  { name: 'TitlesAndAddressesBase' }
);
