import { nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { NSW_DM_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterTitleReferences, generateMortgagees } from './helpers';
import { ApiDischargeOfMortgage2_21_1Model, DischargeOfMortgage2_21_1Model } from './models';

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_DM_PARTY_FORM_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiDischargeOfMortgage2_21_1Model, context: IConverterContext): DischargeOfMortgage2_21_1Model {
  // Implement any necessary conversion from API to form values
  const { mortgages } = apiModel;
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);

  let formModel: DischargeOfMortgage2_21_1Model = {
    ...apiModel,
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook, risInvolvedPartyIds),
    titleReferences: filterTitleReferences(mortgages)
  };

  if (apiModel.needToGeneratePartyBook) {
    formModel = generateMortgagees(formModel);
  }

  formModel = applyDefaultMap(formModel, fallbackMap);

  return formModel;
}

function convertFromFormToApiModel(formModel: DischargeOfMortgage2_21_1Model, originalApiModel: ApiDischargeOfMortgage2_21_1Model): ApiDischargeOfMortgage2_21_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const { partyBook, partyBookApi, mortgages, ...rest } = formModel;

  mortgages.forEach(m => {
    if (m.isSelected) {
      m.titleReferences.forEach(t => {
        t.isSelected = formModel.titleReferences.find(st => st.reference === t.reference)?.isSelected ?? false;
      });
    }
  });

  PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);

  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
    mortgages,
    mortgagees: formModel.mortgagees,
    needToGeneratePartyBook: false
  };
}

class Converter implements IConverter<DischargeOfMortgage2_21_1Model, ApiDischargeOfMortgage2_21_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
