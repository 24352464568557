import * as React from 'react';

import { endOfYesterday } from 'date-fns';
import { getIn, useFormikContext } from 'formik';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field/InputField';

import { Form24B_2_24_1Model, PARTY_BOOK_KEY, TransferorReferenceModel } from '../../models';
import { VISIBILITY_CHECK_CAPACITY, VISIBILITY_CHECK_CAPACITY_DETAIL, VISIBILITY_CHECK_TRUST_NAME } from '../../visibilityCheck';
import { useStyles } from './styles';

type FormValues = Form24B_2_24_1Model;

const END_OF_YESTERDAY = endOfYesterday();

interface Props {
  party: PartyModel;
  name: string;
}

function Transferor({ party, name }: Props) {
  const formikProps = useFormikContext<FormValues>();
  const { values } = formikProps;
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  const displayName: string = [party.firstName, party?.lastName].filter(Boolean).join(' ');

  const bookIndexOfPartyId = values.partyBook.indexOf(party);
  const partyBookFieldName = createModelKeyAppender<PartyModel>(`${PARTY_BOOK_KEY}[${bookIndexOfPartyId}]`);
  const fieldName = createModelKeyAppender<TransferorReferenceModel>(name);
  const transferorReferenceModel: TransferorReferenceModel = getIn(values, name);

  return (
    <>
      <ReadOnlyField //
        label="Name"
        value={displayName}
      />

      {VISIBILITY_CHECK_CAPACITY(transferorReferenceModel) && (
        <ReadOnlyField //
          label="Party capacity"
          value={`${transferorReferenceModel.partyCapacity?.capacity}`}
        />
      )}

      {VISIBILITY_CHECK_CAPACITY_DETAIL(transferorReferenceModel) && (
        <ReadOnlyField //
          label="Capacity details"
          value={transferorReferenceModel.partyCapacity?.details!}
        />
      )}

      <Field //
        label="Date of birth"
        name={partyBookFieldName('dob')}
        component={DatePickerField}
        disableFuture
        maxDate={END_OF_YESTERDAY}
        disabled={disabled}
        className={classes.dob}
      />

      {VISIBILITY_CHECK_TRUST_NAME(transferorReferenceModel, party) && (
        <Field //
          label="Name of trust"
          name={fieldName('trustName')}
          component={InputField}
          disabled={disabled}
        />
      )}
    </>
  );
}

export default React.memo(Transferor);
