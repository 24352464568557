import * as React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';

import { WithdrawalOfCaveat2_25_1Model } from '../../models';
import Caveator from '../caveator';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
type FormValues = WithdrawalOfCaveat2_25_1Model;

interface OwnProps {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function CaveatorPartyReadonlyArray({ name, focusRef }: OwnProps): JSX.Element {
  const { values } = useFormikContext<FormValues>();
  const items = values.caveators;
  const count = items.length;

  const renderItem = (itemIndex: number, count: number) => {
    const hasMultipleItems = count > 1;
    const itemBindingPath = `${name}[${itemIndex}]`;
    const titlePrefix = 'Caveator';
    const title = hasMultipleItems ? `${titlePrefix} ${itemIndex + 1}` : titlePrefix;

    return (
      <FormGroup title={title} fieldAreaDirection="column" key={`item-caveators[${itemIndex}]`}>
        <Caveator //
          name={itemBindingPath}
          focusRef={itemIndex === 0 ? focusRef : undefined}
        />
      </FormGroup>
    );
  };

  return (
    <div //
      data-name={name}
    >
      {items.map((_, itemIndex) => renderItem(itemIndex, count))}
    </div>
  );
}
export default React.memo(CaveatorPartyReadonlyArray);
