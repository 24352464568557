// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import resolvePriorityNoticesValidationSchema from '@sympli-mfe/document-forms-framework/components/sections/current-priority-notice/validation-schema';

import { ExtensionOfPriorityNoticeModel } from './models';

export default yup.object<ExtensionOfPriorityNoticeModel>().shape({
  priorityNotices: resolvePriorityNoticesValidationSchema(true),
  partyBook: yup.mixed()
});
