import React, { memo } from 'react';

import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { LookupItemModel } from '@sympli/ui-framework/models';

import MultipleSelectCheckbox from 'src/components/multiple-select-checkbox';
import AddFilter from 'src/components/multiple-select-checkbox/AddFilter';
import MultipleTabsDateTimePicker from 'src/components/multiple-tabs-date-time-picker';
import { AssignmentTypeEnum, FilterDataModel } from 'src/containers/dashboard/shared/models';
import { FinancialDashboardRouteTabsEnumV2 } from '../models';
import { FilterEnum } from './models';
import { useStyles } from './styles';

export interface FiltersProps {
  selectedFiltersData: FilterDataModel[];
  allowedFilters: LookupItemModel<FilterEnum>[];
  selectedTab: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;

  onAddNewFilterChange(newValue: FilterEnum): void;
  onFilterChange(name: string, newValues: number[] | string[]): void;
  onDateTimeFilterChange(name: string, newValues: string[]): void;
}

const Filters = ({
  //
  selectedFiltersData,
  allowedFilters,
  selectedTab,
  assignmentType,
  onFilterChange,
  onAddNewFilterChange,
  onDateTimeFilterChange
}: FiltersProps) => {
  const classes = useStyles();

  const handleDefaultFilterChange = (_: SelectChangeEvent<any>, newValue: FilterEnum) => {
    onAddNewFilterChange(newValue);
  };

  const handleOnFilterChange = (e: SelectChangeEvent<any>, newValues: number[] | string[]) => {
    onFilterChange(e.target.name, newValues);
  };

  const handleOnSingleFilterChange = (e: SelectChangeEvent<any>, newValue: string) => {
    onFilterChange(e.target.name, [newValue]);
  };

  const handleOnDateTimeFilterChange = (newValues: string[], name: string) => {
    onDateTimeFilterChange(name, newValues);
  };

  const resolveFilterComponent = (data: FilterDataModel) => {
    const { type, label, name, options, values, GroupOptions, placeHolder, additionalOptionsOnTop, unit, defaultOpen } = data;
    if (type === 'datetimePicker') {
      // return type
      return (
        <MultipleTabsDateTimePicker //
          value={values! as string[]}
          label={label}
          name={name}
          placeholder={placeHolder}
          handleOnSettlementDateTimeFilterChange={handleOnDateTimeFilterChange}
          defaultOpen={defaultOpen}
        />
      );
    }

    if (type === 'multipleSearchCheckbox') {
      return (
        <MultipleSelectCheckbox //
          //
          key={`${assignmentType}_${selectedTab}_${label}`} // this is to re-render the filter on tab change to empty the search box
          label={label}
          placeholder={placeHolder}
          value={values!}
          groups={GroupOptions}
          options={options}
          additionalOptionsOnTop={additionalOptionsOnTop}
          onChange={handleOnFilterChange}
          mode="multiple"
          name={name}
          searchComponentProps={{
            label: `Search ${label}`,
            unit
          }}
          defaultOpen={defaultOpen}
        />
      );
    }

    if (type === 'singleCheckbox' && values && values.length > 0) {
      let val = values[0];
      return (
        <MultipleSelectCheckbox
          //
          label={label}
          placeholder={placeHolder}
          value={val}
          groups={GroupOptions}
          options={options}
          onChange={handleOnSingleFilterChange}
          mode="single"
          name={name}
          defaultOpen={defaultOpen}
        />
      );
    }

    return (
      <MultipleSelectCheckbox
        //
        label={label}
        placeholder={placeHolder}
        value={values!}
        groups={GroupOptions}
        options={options}
        onChange={handleOnFilterChange}
        mode="multiple"
        name={name}
        defaultOpen={defaultOpen}
      />
    );
  };

  return (
    <FlexLayout flexDirection="row" justifyContent="flex-start" flexWrap="wrap" classes={{ root: classes.root }}>
      {selectedFiltersData.map(d => {
        return (
          <Box key={d.name} className={classes.filterBox}>
            {resolveFilterComponent(d)}
          </Box>
        );
      })}
      {allowedFilters.length ? (
        <AddFilter
          //
          label="Filter"
          options={allowedFilters}
          onChange={handleDefaultFilterChange}
        />
      ) : null}
    </FlexLayout>
  );
};

export default memo(Filters);
