import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      height: 65,
      backgroundColor: 'white',
      position: 'relative',
      border: `1px solid #bcbdbe`,
      borderLeft: 0,
      borderTop: 0,
      fontSize: '0.8125rem',
      '&:first-child': {
        borderLeft: '1px solid #D0D0D0'
      }
    },
    container: {
      height: '100%',
      width: '100%',
      '&:hover': {
        cursor: 'not-allowed'
      }
    },
    bag: {
      position: 'absolute',
      right: 9,
      bottom: 9,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 38,
      height: 31
    },

    date: {
      position: 'absolute',
      top: 5,
      left: 13
    },
    icon: {
      position: 'absolute',
      width: 28,
      height: 28,
      bottom: 0,
      right: 0
    },
    count: {
      display: 'flex',
      color: theme.palette.primary.main,
      zIndex: 10
    },
    withSelect: {
      '&:hover $container': {
        cursor: 'pointer',
        backgroundColor: 'rgba(245, 137, 35, 0.1)'
      }
    },
    invalid: {
      backgroundColor: theme.palette.grey[300],
      '& $date': {
        color: theme.palette.grey[500]
      }
    },

    selected: {
      boxSizing: 'border-box',
      border: `3px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'rgba(245, 137, 35, 0.1)',
      '& $date': {
        fontWeight: 'bold'
      },
      '& $today': {
        backgroundColor: theme.palette.secondary.main
      },
      '& $icon': {
        fill: theme.palette.secondary.main
      },
      '& $count': {
        color: theme.palette.primary.contrastText
      }
    },

    unsupported: {
      '& $icon': {
        fill: theme.palette.primary.contrastText
      }
    },

    currentSettlement: {
      boxSizing: 'border-box',
      backgroundColor: theme.palette.primary.light,
      '&$selected': {
        border: `3px solid ${theme.palette.primary.main}`
      },
      '& $icon': {
        fill: theme.palette.primary.main
      },
      '& $count': {
        color: theme.palette.primary.contrastText
      }
    },
    //same as selected
    proposedSettlement: {
      boxSizing: 'border-box',
      border: `3px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'rgba(245, 137, 35, 0.1)',
      '& $date': {
        fontWeight: 'bold'
      },
      '& $today': {
        backgroundColor: theme.palette.secondary.main
      },
      '& $icon': {
        fill: theme.palette.secondary.main
      },
      '& $count': {
        color: theme.palette.primary.contrastText
      }
    },
    today: {
      width: 16,
      borderRadius: '50%',
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
      padding: 6,
      marginTop: 6,
      marginLeft: -6,
      color: 'white !important'
    },
    // holiday tooltip
    toolTip: {
      padding: 8,
      width: 'auto',
      textAlign: 'center'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
