import { validateABN } from 'au-bn-validator';
import _get from 'lodash-es/get';
import warning from 'warning';
import * as yup from 'yup';

import { LookupEnumModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import { ConditionalValidationEntityModel, ConditionalVisibilityEntityModel } from '../models';
import { checkRule, isEnumField, isFieldVisible, isObject, isSimpleType, resolveBinding, resolveEnumOptions } from './helpers';
import { GrammarRule, ParsedFieldDefinition } from './models';
import * as v from './validators';

// tslint:disable:no-string-literal
export function getYupDefinition(fields: Array<ParsedFieldDefinition>) {
  return resolveObjectRules({ name: '', title: '', type: 'object', children: fields, extra: {} });
}

function resolveByType(fd: ParsedFieldDefinition, bindingPrefix: string = '', parentVisibility?: Array<ConditionalVisibilityEntityModel>) {
  // we don't want to render any rules for metadata properties
  if (fd.extra.metadata) {
    return;
  }

  switch (fd.type) {
    case 'string':
      return resolveStringType(fd, bindingPrefix);
    case 'integer':
      return resolveIntegerType(fd, bindingPrefix);
    case 'number':
      return resolveNumberType(fd, bindingPrefix);
    case 'boolean':
      return resolveBooleanType(fd, bindingPrefix);
    case 'array':
      return resolveArrayType(fd, bindingPrefix);
    case 'object':
      return resolveObjectRules(fd, bindingPrefix);
    default:
      return yup.mixed(); // Creates a schema that matches all types
  }
}

export function isConditionallyRequired(fd: ParsedFieldDefinition) {
  // we need to check whether there are any validation rules defined
  // validation: [
  //   {
  //     required: true,
  //     onlyIf: {
  //       Manufacturer: 'bmw'
  //     }
  //   }
  //   or complex grammar rule typically used for array validations
  //   {
  //     required: {
  //        $not: 'diesel'
  //     },
  //     onlyIf: {
  //       Manufacturer: 'bmw'
  //     }
  //   }
  // ]
  return !!fd.extra.validation?.length;
}

function resolveStringType(fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  const { extra } = fd;
  const { minLength = 0, email } = extra;
  const maxLength = extra.maxLength as number;

  let rule = yup //
    .string()
    .default('')
    .trim()
    .nullable() //fixme: double check with martin
    .typeError(msg.INVALID_VALUE);

  if (!fd.isRequired) {
    rule = rule.nullable(true);
  }

  rule = resolveRequiredRule(rule, fd, bindingPrefix);

  if (minLength > 0) {
    rule = rule.min(minLength, msg.LENGTH_MUST_BE_AT_LEAST_CHARACTERS(minLength));
  }

  if (Number.isInteger(maxLength) && maxLength > 0) {
    rule = rule.max(maxLength, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(maxLength));
  }

  if (email) {
    rule = rule.email(msg.INVALID_EMAIL);
  }

  if (isEnumField(fd)) {
    rule = resolveEnumRule(rule, fd, bindingPrefix);
  }

  rule = resolveCustomComponentRule(rule, fd);

  const bindingName = resolveBinding(fd.name, bindingPrefix);
  rule = wrapInVisibilityCheck(rule, { ...fd, name: bindingName }, `check string ${bindingName} based on it's visibility`);

  return rule;
}

function resolveIntegerType(fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  const rule = resolveNumberType(fd, bindingPrefix);
  return rule;
}

function resolveBooleanType(fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  let rule = yup //
    .boolean()
    .nullable() //fixme: double check with martin
    .typeError(msg.INVALID_VALUE);

  if (!fd.isRequired) {
    rule = rule.nullable(true);
  }

  rule = resolveRequiredRule(rule as yup.MixedSchema, fd, bindingPrefix) as yup.BooleanSchema;

  if (isEnumField(fd)) {
    rule = resolveEnumRule(rule as yup.MixedSchema, fd, bindingPrefix) as yup.BooleanSchema;
  }

  rule = resolveCustomComponentRule(rule as yup.MixedSchema, fd) as yup.BooleanSchema;

  const bindingName = resolveBinding(fd.name, bindingPrefix);
  rule = wrapInVisibilityCheck(rule as yup.MixedSchema, { ...fd, name: bindingName }, `check boolean ${bindingName} based on it's visibility`) as yup.BooleanSchema;

  return rule;
}

function resolveNumberType(fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  const {
    type,
    extra: { minimum, maximum }
  } = fd;

  const bindingName = resolveBinding(fd.name, bindingPrefix);

  let rule = type === 'integer' ? v.integer() : v.number();
  rule = resolveRequiredRule(rule, fd, bindingPrefix);

  if (typeof minimum === 'number') {
    rule = rule.min(minimum, msg.VALUE_MUST_BE_AT_LEAST_X(minimum));
  }

  if (typeof maximum === 'number') {
    rule = rule.max(maximum, msg.VALUE_MUST_BE_X_OR_LESS(maximum));
  }

  if (isEnumField(fd)) {
    rule = resolveEnumRule(rule, fd, bindingPrefix);
  }

  rule = resolveCustomComponentRule(rule, fd);

  rule = wrapInVisibilityCheck(rule, { ...fd, name: bindingName }, `check number ${bindingName} based on it's visibility`);

  return rule;
}

function isMatchingType(type: string, value: any) {
  switch (type) {
    case 'string':
      return typeof value === 'string';
    case 'integer':
    case 'number':
      // numbers and integers has special type check implemented in validators.ts
      return true;
    case 'boolean':
      return typeof value === 'boolean';
    case 'array':
      return Array.isArray(value);
    default:
      return isObject(value);
  }
}

function resolveCustomComponentRule<T = yup.Schema<any, any>>(rule: T, fd: ParsedFieldDefinition) {
  const component = fd.extra.component;
  switch (component) {
    case 'abn':
      return (rule as unknown as yup.MixedSchema<any, any>).test(
        'abn check.',
        msg.INVALID_ABN, //
        function test(this: yup.TestContext, value: string = '') {
          if (fd.isRequired || value.length) {
            const isValid = validateABN(value);
            return isValid;
          }
          return true;
        }
      ) as unknown as T;
    default:
      return rule;
  }
}

function resolveConditionalRequiredRule<T = yup.Schema<any, any>>(rule: T, fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  // resolve full binding name and pass it down
  const validationRules = fd.extra.validation || [];
  const bindingName = resolveBinding(fd.name, bindingPrefix);

  rule = (rule as unknown as yup.MixedSchema<any, any>).test(
    `${bindingName} conditional required check.`,
    msg.REQUIRED, //
    function test(this: yup.TestContext, value: any) {
      const values = this.options.context!;
      const name = this.path;

      if (value === undefined) {
        value = _get(values, name); // formik explicitly passes empty strings as undefined, in such a case we want to grab real value from formik's state
      }

      const requiredRulesToEvaluate = resolveActiveValidationRules(validationRules, values, name);
      if (!requiredRulesToEvaluate.length) {
        return true;
      }

      const passedAtLeastOneRule = isMatchingOneOfTheRequiredRules(requiredRulesToEvaluate, values, name, fd.type);
      if (!passedAtLeastOneRule) {
        // create custom error message
        return this.createError({ message: resolveRequiredMessage(requiredRulesToEvaluate) });
      }

      return passedAtLeastOneRule;
    }
  ) as unknown as T;

  return rule;
}

function resolveRequiredMessage(rules: Array<ConditionalValidationEntityModel>) {
  // custom message can be defined
  const errors = rules.map(item => item.message).filter(item => item);
  const message = errors.join(', ') || msg.REQUIRED;
  return message;
}

function resolveActiveValidationRules(rules: Array<ConditionalValidationEntityModel>, values: object, propertyBinding: string) {
  // we search for validation rules that are currently useful
  // for this purpose we have onlyIf attribute that if present, contains grammar rule
  // that needs to be executed.
  const activeRules: Array<ConditionalValidationEntityModel> = rules.reduce((acc: Array<ConditionalValidationEntityModel>, item) => {
    const { onlyIf = {} } = item;
    if (checkRule(onlyIf as GrammarRule, values, propertyBinding)) {
      acc.push(item);
    }
    return acc;
  }, []);

  return activeRules;
}

function isMatchingOneOfTheRequiredRules(rules: Array<ConditionalValidationEntityModel>, values: object, propertyBinding: string, type: string) {
  let passedAtLeastOneRule = rules.some(({ required: rule }) => {
    // validation rules can be either saying that this field is required,
    // or defining more complex validation, such as in cases of arrays,
    // when we want to check things like, one of the items has flag isSelected set to true and so on...
    if (typeof rule === 'boolean' && rule === true) {
      const value = _get(values, propertyBinding);
      return isMatchingType(type, value) && type === 'string' ? value.length : true;
    }

    const passedComplexRule = checkRule(rule as GrammarRule, values, propertyBinding);
    return passedComplexRule;
  });

  return passedAtLeastOneRule;
}

function resolveRequiredRule<T extends yup.Schema<any, any>>(rule: T, fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  const {
    isRequired = false,
    extra: { minLength = 0 }
  } = fd;

  let required = isRequired || (fd.type === 'string' && minLength > 0);

  // if there are special rules for parent visibility
  if (isConditionallyRequired(fd)) {
    // if it's conditionally required, we need to add test function
    // to check whether given field is visible and if so,
    rule = resolveConditionalRequiredRule(rule, fd, bindingPrefix) as T;
  } else if (required) {
    rule = (rule as unknown as yup.MixedSchema<any, any>).required(msg.REQUIRED) as unknown as T;
  }

  return rule;
}

function resolveEnumRule<T extends yup.Schema<any, any>>(rule: T, fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  // resolve full binding name and pass it down
  const bindingName = resolveBinding(fd.name, bindingPrefix);

  rule = (rule as unknown as yup.MixedSchema<any, any>).test(
    `${bindingName} enum check`,
    msg.INVALID_SELECTION, //
    function test(this: yup.TestContext, value: any) {
      if (value === '' || value == null) {
        return true;
      }

      const values = this.options.context!;

      const name = this.path; // we can get full path from here without using binding prefix
      const visibleOptions = resolveEnumOptions(values, { ...fd, name }) as Array<LookupEnumModel<any>>;

      // we need to explicitly check whether the selected value is among the visible one, otherwise it's not valid.
      let isValid = visibleOptions.some(item => item.id === value);
      return isValid;
    }
  ) as unknown as T;

  return rule;
}

function wrapInVisibilityCheck<T extends yup.Schema<any, any>>(
  rule: T, //  property rule
  fd: ParsedFieldDefinition,
  comment?: string
) {
  const { type, extra = {} } = fd;
  const { visibility } = extra;
  // if there are no visibility rules for the parent, we just return original rule
  if (!Array.isArray(visibility)) {
    return rule;
  }

  // otherwise we need to build a special rule that always checks whether the parent is visible or not
  const specialRule = yup
    .mixed()
    .nullable(true)
    .test(
      `conditional check by ${fd.name} visibility.`,
      msg.INVALID_VALUE, //
      function test(this: yup.TestContext, value: any) {
        const path = this.path;
        const values = this.options.context!;

        // we basically won't invoke any validation rules if we are not visible
        if (!isFieldVisible({ ...fd, name: path }, values)) {
          return true;
        }

        // otherwise we want to invoke the original rules
        try {
          const opts: any = {
            path,
            abortEarly: false,
            context: values
          };
          // we need to pass context always, otherwise array items properties does not have it!
          rule.validateSync(value, opts);
          return true;
        } catch (ex) {
          if (type === 'object' || type === 'array') {
            return ex;
          } else if (ex.inner.length > 1) {
            return ex.inner[0];
          }

          return this.createError({ message: ex.message, path });
        }
      }
    ) as unknown as T;

  return specialRule;
}

function resolveArrayType(fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  let rule = yup.array().nullable(); /* danger:disable */

  if (!fd.isRequired) {
    rule = rule.nullable(true);
  }

  rule = resolveRequiredRule(rule, fd, bindingPrefix);
  // TODO minItems 1 means automatically required=true.
  rule = resolveArrayLengthRule(rule, fd);

  let itemRule = resolveArrayChildrenType(fd);

  // check visibility of item itself
  const bindingName = resolveBinding(fd.name, bindingPrefix);
  itemRule = wrapInVisibilityCheck(
    itemRule,
    {
      title: '',
      type: fd.childrenType!,
      name: '',
      extra: { visibility: fd.childrenExtra?.visibility }
    },
    `check array item of ${bindingName} based on items visibility`
  ) as yup.MixedSchema;
  rule = rule.of(itemRule);

  // we need to make sure that current field itself is visible
  // otherwise we don't want to validate anything on the array (e.g. minItems, maxItems)
  rule = wrapInVisibilityCheck(
    //
    rule,
    { ...fd, name: bindingName },
    `check array ${bindingName} based on it's visibility`
  );

  return rule;
}

function resolveArrayChildrenType(fd: ParsedFieldDefinition) {
  const children = fd.children!.filter(item => !item.extra.metadata);

  let itemRule = yup.mixed();
  if (children.length) {
    if (isSimpleType(fd.childrenType!)) {
      if (children.length === 1) {
        itemRule = resolveByType(children[0]) as yup.MixedSchema;
      } else {
        // this should never happen
        warning(false, `Simple type items of an array are expected to have only one definition, but %s definitions were found`, children.length);
        //
      }
    } else {
      itemRule = resolveByType({ name: '', title: '', type: fd.childrenType!, children, extra: {} })! as yup.MixedSchema;
    }
  }

  return itemRule;
}

function resolveArrayLengthRule<T extends yup.BasicArraySchema<any, any>>(rule: T, fd: ParsedFieldDefinition) {
  const { extra = {} } = fd;
  const { minItems, maxItems } = extra;

  if (typeof minItems === 'number') {
    rule = rule.min(minItems, msg.MIN_ITEMS(minItems));
  }

  if (typeof maxItems === 'number') {
    rule = rule.max(maxItems, msg.MAX_ITEMS(maxItems));
  }

  return rule;
}

function resolveObjectRules(fd: ParsedFieldDefinition, bindingPrefix: string = '') {
  const {
    name,
    children = [],
    extra: { visibility }
  } = fd;

  const bindingName = resolveBinding(name, bindingPrefix);

  const propertyRules = children.reduce((acc: object, childFd: ParsedFieldDefinition) => {
    const propertyRule = resolveByType(childFd, bindingName, visibility);
    if (propertyRule) {
      // tslint:disable-next-line:no-string-literal
      // propertyRule['parentPath'] = bindingName;
      acc[childFd.name] = propertyRule;
    }
    return acc;
  }, {});

  let rule = yup.object().nullable(); /* danger:disable */

  if (!fd.isRequired) {
    rule = rule.nullable(true);
  }

  rule = resolveRequiredRule(rule, fd, bindingPrefix);

  rule = resolveCustomComponentRule(rule, fd);

  rule = rule.shape(propertyRules);
  rule = wrapInVisibilityCheck(rule, { ...fd, name: bindingName }, `check object ${bindingName} based on it's visibility`);

  return rule;
}
