import React from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';

export interface RouteRedirectProps {
  navigate: NavigateFunction;
}

function withRedirectRouter<P>(Component: React.ComponentType<P & RouteRedirectProps>) {
  function ComponentWithRouterProp(props: P) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

export default withRedirectRouter;
