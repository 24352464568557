import { NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-26/necds';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const PARTY_CAPACITY_LOOKUP_OPTIONS: LookupEnumModel<string>[] = restrictedLookupOptions(NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    NecdsPartyCapacityEnum.Administrator, //
    NecdsPartyCapacityEnum.Executor,
    NecdsPartyCapacityEnum.TrusteeInBankruptcy
  ]
});
