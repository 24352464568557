import * as React from 'react';

import { useDispatch } from 'react-redux';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { useWorkspaceAssignableGroups } from 'src/containers/dashboard/shared/reducers/workspaceAssignableGroups';
import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { verifyTitleReferences } from 'src/containers/shared/verify-property-section/helpers';
import { TitleVerificationRequest, TitleVerificationResponse } from 'src/containers/shared/verify-property-section/models';
import { useFeatureFlag, useSafeDispatch, useStoreSelector } from 'src/hooks';
import { SharedInitialValues } from '../../../../models';
import { CreateLodgementOnlyWorkspaceSharedModel, CreateLodgeOnlyWorkspaceStepEnum } from '../../models';
import MatterDetail from './MatterDetail';

interface Props {
  workspaceTypeOptions: LookupEnumModel<WorkspaceTypeEnum>[];
  workspaceTypeId?: WorkspaceTypeEnum;
  onGlobalValuesUpdate: (value?: Partial<CreateLodgementOnlyWorkspaceSharedModel>) => CreateLodgementOnlyWorkspaceSharedModel;
  onStepChange: (step: CreateLodgeOnlyWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
  onWorkspaceTypeChange: (workspaceTypeId: WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => void;
}

function MatterDetailContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const isSearchAndJoinWorkspaceEnabled: boolean = useFeatureFlag(FeatureToggleEnum.searchAndJoinWorkspaceEnabled);
  const profileState = useProfile();
  const assignGroupsState = useWorkspaceAssignableGroups();
  const supportedJurisdictionsState = useStoreSelector(store => store.supportedJurisdictions);
  const { onClose } = props;

  const onVerifyTitleClick = React.useCallback(
    async (payload: TitleVerificationRequest): Promise<TitleVerificationResponse> => {
      try {
        return verifyTitleReferences(payload);
      } catch (error) {
        dispatch(actionCreateGlobalErrorMessage(error));
        // rethrow the error to inform listener
        throw error;
      }
    },
    [dispatch]
  );

  const onWorkspaceCreated = React.useCallback(
    (props: { workspaceId: string; participantId: string; documentId: string }) => {
      const link = resolveDocumentDetailLink(props);

      onClose(link);
    },
    [onClose]
  );
  return (
    <MatterDetail //
      // other
      {...props}
      onVerifyTitleClick={onVerifyTitleClick}
      onWorkspaceCreated={onWorkspaceCreated}
      // redux
      assignGroupsState={assignGroupsState}
      supportedJurisdictionsState={supportedJurisdictionsState}
      subscriberProfile={profileState.data!}
      dispatch={dispatch}
      isSearchAndJoinWorkspaceEnabled={isSearchAndJoinWorkspaceEnabled}
    />
  );
}

export default React.memo(MatterDetailContainer);
