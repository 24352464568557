import { createAsyncAction } from 'typesafe-actions';

import { BillingInvoicingApiResponse } from '../billing-invoicing-detail/models';

export const actionFetchBillingInvoicing = createAsyncAction(
  //
  'FETCH_BILLING_INVOICEING',
  'FETCH_BILLING_INVOICEING_SUCCESS',
  'FETCH_BILLING_INVOICEING_ERROR'
)<
  //
  undefined,
  { data: BillingInvoicingApiResponse },
  { error: Error }
>();
