import * as React from 'react';

import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import MessageNotificationV2 from 'src/components/message-notification';
import ErrorMessagePanel from 'src/containers/workspace/financial/detail/components/error-message-panel';
import { HeaderModel } from 'src/containers/workspace/financial/detail/components/error-message-panel/models';

interface Props {
  errors?: MessageModel[];
  warnings?: MessageModel[];
}

function StampDutyCreationError({ errors, warnings }: Props) {
  const errorHeader: HeaderModel = {
    //
    primary: 'Stamp Duty transaction could not be created.',
    secondary: 'Please resolve issues.'
  };

  return errors?.length || warnings?.length ? (
    <ErrorMessagePanel //
      errorHeader={errorHeader}
      errors={errors}
      warnings={warnings}
    />
  ) : (
    <MessageNotificationV2 //
      variant="error"
      primary="Stamp Duty transaction could not be created."
      secondary="Please resolve issues."
    />
  );
}

export default React.memo(StampDutyCreationError);
