import React, { useEffect } from 'react';

import classNames from 'classnames';
import { getIn, useField, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import MessageList from '@sympli/ui-framework/components/form/base-components/message-list';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import DeceasedProprietorGroup from '../../components/deceased-proprietor-group';
import { haveOrganisationPartyInGroup, isDisabledGroup, visibleEvidenceWhenJointTenantsCheckBoxChanged } from '../../helpers';
import { DeceasedProprietorGroupModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  className?: string;
}

const fieldName = modelKey<TransmissionApplicationWithoutDuty2_21_1Model>();

function SectionDeceasedProprietorGroups({ name }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const { setFieldTouched } = formikProps;
  const [, { value: items }] = useField<DeceasedProprietorGroupModel[]>(name);
  const hasMultipleGroups = items.length > 1;

  useEffect(() => {
    items.forEach((_, index) => {
      const itemBinding = `deceasedProprietorGroups[${index}]`;
      const fieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);
      setFieldTouched(fieldName('lastDeceasedValidation'), true);
    });
  }, [items, setFieldTouched]);

  const handleSelectAllProprietorsChange =
    (itemBinding: string) =>
    (_: React.ChangeEvent<HTMLInputElement>, newAreAllAffected: boolean): void => {
      const groupItems: DeceasedProprietorGroupModel = getIn(formikProps.values, itemBinding) ?? [];
      let updatedItems: DeceasedProprietorGroupModel = {
        ...groupItems,
        deceasedProprietors: groupItems.deceasedProprietors.map(proprietor => {
          return {
            ...proprietor,
            isAffectedProprietor: newAreAllAffected,
            isLastDeceased: false,
            isEvidenceVisible: newAreAllAffected
          };
        })
      };
      formikProps.setFieldValue(itemBinding, visibleEvidenceWhenJointTenantsCheckBoxChanged(updatedItems));
    };

  const renderItem = ({ itemBinding, itemIndex }: DocumentArrayItemRenderProps<DeceasedProprietorGroupModel>) => {
    const numberOfPartiesInPreceedingGroups = items.slice(0, itemIndex).flatMap(group => group.deceasedProprietors ?? []).length;
    const hasMultipleParties = items[itemIndex].deceasedProprietors.length > 1;
    const isFirstGroup = itemIndex === 0;
    const groupFieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);
    const isSoleProprietor = items[itemIndex].tenancyType === TenancyTypeEnum.SoleProprietor || items[itemIndex].deceasedProprietors.length === 1;
    const disabledCheckbox =
      disabled ||
      isDisabledGroup(
        formikProps.values.deceasedProprietorGroups,
        items[itemIndex].deceasedProprietors.every(item => item.isAffectedProprietor)
      );
    const haveOrgPartyInGroup = haveOrganisationPartyInGroup(items[itemIndex], formikProps.values.partyBook);
    const lastDeceasedValidationError = getFormikError(formikProps, groupFieldName('lastDeceasedValidation'), true);

    return (
      <SectionLike //
        className={classNames(isFirstGroup && classes.deceasedGroupFirst, classes.marginBottomIfNotLast)}
        key={itemBinding}
        data-testid={itemBinding}
      >
        <div key={itemBinding} className={classes.bottomDivider}>
          {hasMultipleParties && (
            <>
              {lastDeceasedValidationError && (
                <MessageList
                  className={classes.errorMessage}
                  dataErrorName={groupFieldName('lastDeceasedValidation')}
                  messages={[{ message: lastDeceasedValidationError, type: 'error' }]}
                />
              )}

              <Checkbox
                aria-labelledby="selectAllParties"
                label="Joint Tenants"
                name={'Select all parties'}
                format="boolean"
                className={classes.jointTenantCheckbox}
                checked={items[itemIndex].deceasedProprietors.every(item => item.isAffectedProprietor)}
                onChange={handleSelectAllProprietorsChange(itemBinding)}
                disabled={disabledCheckbox || haveOrgPartyInGroup}
                data-testid={'select-all-parties'}
              />
            </>
          )}
          <DeceasedProprietorGroup //
            hasMultipleParties={hasMultipleParties}
            name={itemBinding}
            numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
            isSoleProprietor={isSoleProprietor}
            haveOrgPartyInGroup={haveOrgPartyInGroup}
          />
        </div>
      </SectionLike>
    );
  };

  const sectionErrorMessage = getFormikError(formikProps, fieldName('deceasedProprietorGroupsValidation'), true);
  const pluraliseSectionTitle = hasMultipleGroups || (items.length === 1 && items[0].deceasedProprietors.length > 1);
  const sectionTitle = `${pluraliseSectionTitle ? 'Deceased registered parties' : 'Deceased registered party'}`;

  return (
    <div data-section={fieldName('deceasedProprietorGroupsValidation')} data-testid={fieldName('deceasedProprietorGroupsValidation')}>
      <Section //
        error={sectionErrorMessage}
        title={sectionTitle}
        data-name={fieldName('deceasedProprietorGroupsValidation')}
        className={classNames(items.length && classes.section)}
      >
        {!items[0].deceasedProprietors.length ? (
          <Typography className={classes.noDeceasedGroupsMessage}>There are no deceased registered parties to display. Please ensure that a title is selected.</Typography>
        ) : (
          <DocumentFieldArray //
            arrayBinding={name}
            renderItem={renderItem}
            disabled={disabled}
            itemStyle="none"
            mode="fixed"
          />
        )}
      </Section>
    </div>
  );
}
export default React.memo(SectionDeceasedProprietorGroups);
