import * as yup from 'yup';

import { resolveVisibleEnumOptions, testValueReuseInArray } from '@sympli-mfe/document-forms-framework/utils';
import { getLookupValuesAllowingEmpty, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import msg from '@sympli/ui-framework/utils/messages';

import { isValidPropertyAddress } from '../../components/property-titles-and-address/PropertyTitlesAndAddress';
import {
  PROPERTY_DETAILS_CONSTRUCTION_OF_MAIN_STRUCTURE_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_LAND_DIMENSIONS_KNOWN_DETAILS_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_LAND_DIMENSIONS_LAND_AREA_AREA_UNIT_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_LAND_USE_TYPE_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_MUNICIPALITY_NAME_LOOKUP_OPTIONS,
  PROPERTY_DETAILS_PRIMARY_LAND_USE_LOOKUP_OPTIONS,
  PropertyDetails$ConstructionOfMainStructureEnum,
  PropertyDetails$LandDimensions$KnownDetailsEnum,
  PropertyDetails$LandDimensions$LandArea$AreaUnitEnum,
  PropertyDetails$LandUseTypeEnum,
  PropertyDetails$MunicipalityNameEnum,
  PropertyDetails$PrimaryLandUseEnum
} from '../../enums';
import { LandAreaModel, LandDimensionsModel, NoaPropertyDetailModel, NoticeOfAcquisitionDocumentModel, TitleReferenceModel } from '../../models';
import {
  VISIBILITY_CHECK_ALL_PROPERTIES_ASSIGNED,
  VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE,
  VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA,
  VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT,
  VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS,
  VISIBILITY_CHECK_PROPERTY_DETAILS$PRIMARY_LAND_USE,
  VISIBILITY_CHECK_PROPERTY_DETAILS$SALE_PRICE_PER_PROPERTY,
  VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL
} from '../../visibilityChecks';
import { MSG_TOTAL_SALE_PRICE, testTotalSalePrice } from '../details-of-transaction/validationSchema';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupPropertyDetail = yup //
  .mixed<TitleReferenceModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_ALL_PROPERTIES_ASSIGNED,
      validationWhenVisible: yup.object<NoaPropertyDetailModel>().shape({
        titleReferences: yup
          .array<TitleReferenceModel>()
          .of(
            yup //
              .object<TitleReferenceModel>()
              .nullable(true)
              .shape({
                reference: yup.string().required(msg.REQUIRED)
              })
              .required(msg.REQUIRED)
          )
          .min(1, msg.MIN_ITEMS(1))
          .max(20, msg.MAX_ITEMS(20)),
        propertyStreetAddressBookId: yup
          .string()
          .default('')
          .trim()
          .required(msg.REQUIRED)
          .test(
            'address already selected',
            'Address of property must be different for each property',
            testValueReuseInArray<NoticeOfAcquisitionDocumentModel, NoaPropertyDetailModel, string>(
              model => model.propertyDetails,
              propertyDetail => propertyDetail.propertyStreetAddressBookId
            )
          )
          .test(
            'property address',
            'Address of the property must be in VIC',
            function testVicAddress(this: yup.TestContext<NoticeOfAcquisitionDocumentModel>, propertyStreetAddressBookId: any) {
              const { addressBook } = this.options.context!;
              const currentAddress = addressBook?.find(a => a.id === propertyStreetAddressBookId);
              // [WEB-12460] All addresses allow interstate address except for property address
              return isValidPropertyAddress(currentAddress!);
            }
          ),
        salePricePerProperty: yup //
          .mixed<number>()
          .test(
            validateWhenVisible({
              visibilityCheck: VISIBILITY_CHECK_PROPERTY_DETAILS$SALE_PRICE_PER_PROPERTY,
              validationWhenVisible: v
                .number()
                .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                .max(99999999999.99, msg.VALUE_MUST_BE_X_OR_LESS(99999999999.99))
                .test('sum of all property prices', MSG_TOTAL_SALE_PRICE, testTotalSalePrice)
            })
          ),

        municipalityName: yup //
          .mixed<PropertyDetails$MunicipalityNameEnum>()
          .required(msg.REQUIRED)
          .oneOf(getLookupValuesAllowingEmpty(PROPERTY_DETAILS_MUNICIPALITY_NAME_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
        municipalityPropertyNumber: yup.string().default('').trim().typeError(msg.INVALID_VALUE).nullable(true),
        landDimensions: yup
          .object<LandDimensionsModel>()
          .required(msg.REQUIRED)
          .shape({
            knownDetails: yup //
              .mixed<PropertyDetails$LandDimensions$KnownDetailsEnum>()
              .required(msg.REQUIRED)
              .oneOf(getLookupValuesAllowingEmpty(PROPERTY_DETAILS_LAND_DIMENSIONS_KNOWN_DETAILS_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
            landArea: yup //
              .mixed<LandAreaModel>()
              .test(
                validateWhenVisible({
                  visibilityCheck: VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA,
                  validationWhenVisible: yup
                    .object<LandAreaModel>()
                    .required(msg.REQUIRED)
                    .shape({
                      areaUnit: yup
                        .mixed<PropertyDetails$LandDimensions$LandArea$AreaUnitEnum>()
                        .required(msg.REQUIRED)
                        .oneOf(getLookupValuesAllowingEmpty(PROPERTY_DETAILS_LAND_DIMENSIONS_LAND_AREA_AREA_UNIT_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
                      quantity: v.number().required(msg.REQUIRED).min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0)).max(99999999999, msg.VALUE_MUST_BE_X_OR_LESS(99999999999))
                    }),
                  isObjectOrArray: true
                })
              ),
            unitOfEntitlement: yup //
              .mixed<string>()
              .test(
                validateWhenVisible({
                  visibilityCheck: VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT,
                  validationWhenVisible: yup
                    .string()
                    .default('')
                    .trim()
                    .nullable()
                    .required(msg.REQUIRED)
                    .matches(/^\d+[\\:\\/]\d+$/, msg.INVALID_VALUE)
                    .test('check format', msg.INVALID_VALUE, function test(this: yup.TestContext<NoticeOfAcquisitionDocumentModel>, value: any) {
                      const [numerator = '', denumerator = ''] = String(value ?? '').split(/[\:\\/]/);
                      return Number(numerator) <= Number(denumerator);
                    })
                })
              )
          }),
        landUseType: yup //
          .mixed<PropertyDetails$LandUseTypeEnum>()
          .required(msg.REQUIRED)
          .oneOf(getLookupValuesAllowingEmpty(PROPERTY_DETAILS_LAND_USE_TYPE_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
        primaryLandUse: yup.mixed<PropertyDetails$PrimaryLandUseEnum>().test(
          validateWhenVisible({
            visibilityCheck: VISIBILITY_CHECK_PROPERTY_DETAILS$PRIMARY_LAND_USE,
            validationWhenVisible: yup
              .mixed<PropertyDetails$PrimaryLandUseEnum>() //
              .required(msg.REQUIRED)
              .test(
                'propertyDetails[*].primaryLandUse enum check',
                msg.INVALID_SELECTION, //
                function test(this: yup.TestContext<NoticeOfAcquisitionDocumentModel>, value: any) {
                  if (value === '' || value == null) {
                    return true;
                  }
                  const values = this.options.context!;
                  const options = resolveVisibleEnumOptions<number>(PROPERTY_DETAILS_PRIMARY_LAND_USE_LOOKUP_OPTIONS, values, this.path);
                  // check if currently selected value is valid
                  const isValid = options.some(item => item.id === value);
                  return isValid;
                }
              )
          })
        ),
        constructionOfMainStructure: yup //
          .mixed<PropertyDetails$ConstructionOfMainStructureEnum>()
          .test(
            validateWhenVisible({
              visibilityCheck: VISIBILITY_CHECK_PROPERTY_DETAILS$CONSTRUCTION_OF_MAIN_STRUCTURE,
              validationWhenVisible: yup //
                .mixed<PropertyDetails$ConstructionOfMainStructureEnum>()
                .required(msg.REQUIRED)
                .oneOf(getLookupValuesAllowingEmpty(PROPERTY_DETAILS_CONSTRUCTION_OF_MAIN_STRUCTURE_LOOKUP_OPTIONS), msg.INVALID_SELECTION)
            })
          ),
        numberOfBedrooms: yup //
          .mixed<number>()
          .test(
            validateWhenVisible({
              visibilityCheck: VISIBILITY_CHECK_PROPERTY_DETAILS$NUMBER_OF_BEDROOMS,
              validationWhenVisible: v.integer().required(msg.REQUIRED).min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0)).max(99999999999, msg.VALUE_MUST_BE_X_OR_LESS(99999999999))
            })
          )
      }),
      isObjectOrArray: true
    })
  );

// path: propertyDetails
const yupPropertyDetails = yup //
  .mixed<NoaPropertyDetailModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
      validationWhenVisible: yup.array<NoaPropertyDetailModel>().required(msg.REQUIRED).min(1, msg.MIN_ITEMS(1)).max(20, msg.MAX_ITEMS(20)).of(yupPropertyDetail),
      isObjectOrArray: true
    })
  );

export default yupPropertyDetails;
