import * as yup from 'yup';

import { SubscriberOrganisationTypeEnum } from '@sympli/api-gateway/enums';

import { ReferenceFormModel } from './models';

const MAX_MATTER_LENGTH = 200; // WEB-8637

const getValidationSchema = (organisationalType?: SubscriberOrganisationTypeEnum) => {
  const referenceType = organisationalType === SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan' : 'matter';
  return yup.object<ReferenceFormModel>({
    reference: yup
      .string()
      .trim()
      .required(`Your ${referenceType} number cannot be blank`)
      .max(MAX_MATTER_LENGTH, `Your ${referenceType} number exceeds ${MAX_MATTER_LENGTH} characters`)
  });
};

export default getValidationSchema;
