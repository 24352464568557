import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { UserAvatar } from 'src/components/avatars';
import styles, { ClassKeys } from './styles';

export enum SettlementTimeTypeEnum {
  Previous,
  Current,
  Proposed
}

interface OwnProps {
  dateTimeDisplay: string;
  settlementTimeType: SettlementTimeTypeEnum;
  reasonText?: string;
  proposedByParticipant?: WorkspaceParticipantApiResponse;
}

type Props = OwnProps & WithStyles<ClassKeys>;
class SettlementTimeAndReasonReview extends React.PureComponent<Props> {
  render() {
    const { classes, dateTimeDisplay, settlementTimeType, reasonText, proposedByParticipant } = this.props;
    return (
      <div className={classes.inputContainer}>
        {this.renderSelectedDateTime(dateTimeDisplay, settlementTimeType)}
        {proposedByParticipant && this.renderProposedParticipant(proposedByParticipant, reasonText)}
      </div>
    );
  }

  private renderProposedParticipant(participant: WorkspaceParticipantApiResponse, reasonText?: string) {
    const { classes } = this.props;
    return (
      <>
        <Typography className={classes.proposalDetailLabel}>Proposed by</Typography>
        <FlexLayout flexDirection="row">
          <UserAvatar src={participant.avatarUrl} size="extraSmall" className={classes.avatar} />
          <Typography component="small">{participant.workspaceRole?.name}</Typography>
        </FlexLayout>
        {reasonText && (
          <>
            <Typography className={classes.proposalDetailLabel}>Reason for new date and/or time</Typography>
            <Typography component="small">{reasonText}</Typography>
          </>
        )}
      </>
    );
  }

  private renderSelectedDateTime(dateTimeDisplay: string, settlementTimeType: SettlementTimeTypeEnum) {
    const { classes } = this.props;
    switch (settlementTimeType) {
      // TODO previous settlement date, this is the state when there is a proposal to be agreed on, we have not checked that condition yet
      case SettlementTimeTypeEnum.Previous: {
        return (
          <div>
            <Typography component="small">Previous settlement date and time</Typography>
            <Typography className={classes.dateTime}>{dateTimeDisplay}</Typography>
          </div>
        );
      }
      case SettlementTimeTypeEnum.Proposed: {
        return (
          <div>
            <Typography component="small" className={classes.proposedDateTime}>
              Proposed settlement date and time&nbsp;
            </Typography>
            <Typography className={classNames(classes.dateTime, classes.proposedDateTime)}>{dateTimeDisplay}</Typography>
          </div>
        );
      }
      case SettlementTimeTypeEnum.Current:
      default: {
        return (
          <div>
            <Typography component="small">Current settlement date and time</Typography>
            <Typography className={classes.dateTime}>{dateTimeDisplay}</Typography>
          </div>
        );
      }
    }
  }
}

export default withStyles(styles)(SettlementTimeAndReasonReview);
