import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { QldPartyForm_2_23 as PartyForm } from '@sympli-mfe/document-components/party-form/qld/2-23';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-23/qld';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { QLD_WOC_PARTY_FORM_CONFIG } from '../../config';
import { ProprietorModel, WithdrawalOfCaveat2_23_1Model } from '../../models';
import { VISIBILITY_CHECK_PARTY_CAPACITY, VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL } from '../../visibilityChecks';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function RegisteredProprietorParty({ name, className }: Props): JSX.Element {
  const formikProps = useFormikContext<WithdrawalOfCaveat2_23_1Model>();
  const { values } = formikProps;
  const { disabled } = useDocumentContext();
  const value: ProprietorModel = _get(values, name);
  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  const partyCapacity = PARTY_CAPACITY_LOOKUP_OPTIONS.find(({ id }) => id === value.partyCapacity?.partyCapacity)?.name;

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={QLD_WOC_PARTY_FORM_CONFIG}
      />
      {VISIBILITY_CHECK_PARTY_CAPACITY(value) && <ReadOnlyField label="Capacity" value={partyCapacity!} />}
      {VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL(value) && <ReadOnlyField label="Capacity detail" value={value.partyCapacity?.partyCapacityDetail!} />}
    </div>
  );
}

export default React.memo(RegisteredProprietorParty);
