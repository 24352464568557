import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dialogRoot: {
      zIndex: 3, // this has to be lower than the top panel
      top: 60,
      '@media (min-width:1536px)': {
        top: 80
      }
    },
    dialogPaper: {
      borderRadius: 0,
      alignItems: 'center',
      margin: 0,
      border: 'none',
      boxShadow: 'none',
      position: 'absolute',
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%'
    },
    dialogContent: {
      overflowY: 'visible'
    },
    fullWidth: {
      width: '100%'
    },
    SearchBar: {
      marginTop: 64,
      position: 'relative',
      justifyContent: 'center'
    },
    existSearchStyle: {
      textAlign: 'center',
      display: 'inline-flex',
      alignItems: 'center',
      position: 'absolute',
      fontSize: 14,
      lineHeight: '88px', // same height of Search Input
      color: colors.SUNNY_DAY_DARK,
      fontStyle: 'normal',
      fontWeight: 700,
      textDecorationLine: 'underline',
      height: 'min-content',
      margin: 'auto 0',
      right: 0
    }
  }),
  {
    name: 'GlobalSearchContainer'
  }
);
