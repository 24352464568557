import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import * as constants from '../constants';

const { WIDTH_PANEL_NAVIGATION_240, WIDTH_PANEL_SIDEBAR_320, WIDTH_SCROLLBAR_17, WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272, WIDTH_PANEL_SIDEBAR_CONTENT_304 } = constants;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // padding: 8,
      // background: theme.palette.grey[100],
      overflowY: 'auto',
      overflowX: 'hidden',
      zIndex: 1
    },
    sidebar: {
      height: '100%',
      width: WIDTH_PANEL_SIDEBAR_320 + WIDTH_SCROLLBAR_17,
      flex: `0 0 ${WIDTH_PANEL_SIDEBAR_320 + WIDTH_SCROLLBAR_17}px`, // don't shrink content
      '& .tickle-panel-sidebar-content': {
        padding: theme.spacing(1), // 8px
        paddingTop: theme.spacing(4), // 32px
        minHeight: `calc(100% - ${theme.spacing(5)})`, // (32 + 8)px
        width: WIDTH_PANEL_SIDEBAR_CONTENT_304,
        // Explicitly force inner content to be 272px width
        '&>*': {
          width: WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272,
          paddingLeft: (WIDTH_PANEL_SIDEBAR_CONTENT_304 - WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272) / 2,
          paddingRight: (WIDTH_PANEL_SIDEBAR_CONTENT_304 - WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272) / 2
        },
        '&>.tickle-box': {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: (WIDTH_PANEL_SIDEBAR_CONTENT_304 - WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272) / 2,
          marginRight: (WIDTH_PANEL_SIDEBAR_CONTENT_304 - WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272) / 2
        }
      }
    },
    navigation: {
      width: WIDTH_PANEL_NAVIGATION_240 + WIDTH_SCROLLBAR_17,
      '& .tickle-panel-navigation-content': {
        width: WIDTH_PANEL_NAVIGATION_240 - 8,
        height: 'calc(100% - 16px)',
        padding: 8,
        paddingRight: 0
      }
    },
    content: {
      background: theme.palette.grey[50],
      borderRight: `1px solid ${theme.palette.grey[100]}`
    }
  });

export type ClassKeys = typeof styles;

export default styles;
