import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { WaAddressSelectField_2_25 as AddressSelectField } from '@sympli-mfe/document-components/address-field/wa/2-25';
import { WA_ADDRESS_FORM_CONFIG as WA_WOC_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/wa/2-25/config';
import { WaPartyForm_2_25 as PartyForm } from '@sympli-mfe/document-components/party-form/wa/2-25';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import Subform from '@sympli/ui-framework/components/formik/subform';

import PartyCapacity from '../../../../components/party-capacity';
import { WA_WOC_PARTY_FORM_FOR_CAVEATOR_CONFIG } from '../../config';
import { CaveatorPartyModel, WithdrawalOfCaveat2_25_0Model } from '../../models';

type FormValues = WithdrawalOfCaveat2_25_0Model;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function Caveator({ name, formikProps, disabled, dialogPortalId, className, focusRef }: Props): JSX.Element {
  //! we can't use useDocumentContext hook here for dialogPortalId because the DocumentForm
  //! is a class component and generates it's own dialogPortalId
  const fieldName = createModelKeyAppender<CaveatorPartyModel>(name);
  const { values } = formikProps;

  const addressBookOptions = useAddressBookOptions(values.addressBook, WA_WOC_ADDRESS_FORM_CONFIG);
  const value: CaveatorPartyModel = _get(values, name);

  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={WA_WOC_PARTY_FORM_FOR_CAVEATOR_CONFIG}
        focusRef={focusRef}
      />
      <PartyCapacity name={name} formikProps={formikProps} isReadonly />
      <AddressSelectField //
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        name={fieldName('addressBookId')}
        optionsOverride={addressBookOptions}
      />
    </div>
  );
}

export default React.memo(Caveator);
