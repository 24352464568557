import { WorkspaceDirectionsApiResponse } from '@sympli/api-gateway/models';

export function getInitialDmDirection(direction: WorkspaceDirectionsApiResponse['directions'][number]) {
  const { holdingAccountDetails, bankDetails } = direction;
  // equivalent to `paymentMethod !== PaymentMethod.HoldingAccount`
  if (holdingAccountDetails == null || bankDetails == null) {
    return direction;
  }
  const { reference = '' } = bankDetails;
  return {
    ...direction,
    holdingAccountDetails: { ...holdingAccountDetails, reference }
  };
}
