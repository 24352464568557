import React, { useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import { ClassNameMap } from '@mui/styles/withStyles';

import { NswAddressSelectField_2_21 as AddressSelectField } from '@sympli-mfe/document-components/address-field/nsw/2-21';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import { RadioClassKeys } from '@sympli/ui-framework/components/form/base-components/radio';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { NSW_NOS_SERVICE_NOTICE_ADDRESS_FORM_CONFIG } from '../../config';
import { DETAILS_FOR_SERVICE_OF_NOTICES$OWNER_AGENT_LOOKUP_OPTIONS, DetailsForServiceOfNotices$OwnerAgentEnum } from '../../enums';
import { ADDRESS_BOOK_KEY, DetailsForServiceOfNoticesModel, PARTY_BOOK_KEY } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  dialogPortalId: string;
  partyBook: PartyModel[];
  addressBook: AddressBookEntityModel[];
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionDetailsForServiceOfNotices({ name, dialogPortalId, partyBook, addressBook, className, focusRef }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const { touched, setFieldTouched, setFieldValue } = useFormikContext<DetailsForServiceOfNoticesModel>();
  const fieldName = createModelKeyAppender<DetailsForServiceOfNoticesModel>(name);
  const addressBookOptions = useAddressBookOptions(addressBook, NSW_NOS_SERVICE_NOTICE_ADDRESS_FORM_CONFIG);
  const radioFieldClasses = useMemo<Partial<ClassNameMap<keyof ReturnType<RadioClassKeys>>>>(
    () => ({
      formControlLabel: classes.radioFormControlLabel
    }),
    [classes]
  );
  const handleOwnerAgent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, ownerAgent: number | null): void => {
      if (ownerAgent !== DetailsForServiceOfNotices$OwnerAgentEnum.Owner || !!touched.partyBookId || !partyBook.length) {
        return;
      }
      setFieldValue(fieldName('partyBookId'), partyBook[0].id, true);
      setFieldTouched(fieldName('partyBookId'), true, true);
    },
    [setFieldValue, setFieldTouched, fieldName, touched.partyBookId, partyBook]
  );
  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Contact details"
        fieldAreaDirection="column"
      >
        <Field //
          aria-label="Contact details"
          name={fieldName('ownerAgent')}
          component={RadioField}
          disabled={disabled}
          format="number"
          options={DETAILS_FOR_SERVICE_OF_NOTICES$OWNER_AGENT_LOOKUP_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          classes={radioFieldClasses}
          onChange={handleOwnerAgent}
        />
        <PartySelectField
          name={fieldName('partyBookId')}
          partyFormConfig={NSW_PARTY_FORM_CONFIG}
          disabled={disabled}
          bookRef={PARTY_BOOK_KEY}
          dialogPortalId={dialogPortalId}
          focusRef={focusRef}
        />
        <AddressSelectField
          name={fieldName('addressBookId')}
          disabled={disabled}
          bookRef={ADDRESS_BOOK_KEY}
          dialogPortalId={dialogPortalId}
          optionsOverride={addressBookOptions}
          addressFormConfig={NSW_NOS_SERVICE_NOTICE_ADDRESS_FORM_CONFIG}
        />
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionDetailsForServiceOfNotices);
