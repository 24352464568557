import * as React from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import List from '@mui/material/List';

import { DocumentItem } from 'src/containers/shared/document-list/components/document-item';
import { DocumentListModel } from '../../models';
import { DocumentItemWithAction } from '../document-item-with-action';
import { getItemStyle } from './helpers';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  orderedItems: DocumentListModel[];
  onDragEnd(props: DropResult): void;
}

function DocumentListReorder({
  //
  orderedItems,
  onDragEnd
}: Props) {
  const classes = useStyles();

  function renderList() {
    return orderedItems.map((item, index) => {
      const { documentId, isLI } = item;

      // LI and placeholder document can't be reordered
      if (isLI || !documentId) {
        return (
          // placeholder document doesn't have documentId
          <React.Fragment key={documentId ?? index}>
            <DocumentItem document={{ ...item, icon: undefined }} forceDisable={true} isCriticalRolesEnabled={false} />
            {item.supportingDocuments.map(item => (
              <DocumentItem //
                key={item.documentId}
                document={item}
                isCriticalRolesEnabled={false}
              />
            ))}
          </React.Fragment>
        );
      }

      return (
        <Draggable //
          draggableId={documentId}
          key={documentId}
          index={index}
          isDragDisabled={isLI}
        >
          {provided => (
            <div //
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={getItemStyle(provided.draggableProps.style)}
            >
              <DocumentItemWithAction //
                document={item}
              />
              {item.supportingDocuments.map(item => (
                <DocumentItemWithAction //
                  key={item.documentId}
                  document={item}
                />
              ))}
            </div>
          )}
        </Draggable>
      );
    });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable //
        droppableId="droppable"
        isDropDisabled={false}
      >
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <List //
              className={classes.outerGutters}
            >
              {renderList()}
            </List>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DocumentListReorder;
