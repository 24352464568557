import _get from 'lodash-es/get';
import warning from 'warning';

import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { GrammarRule, ParsedFieldDefinition } from './models';

const IS_DEV = import.meta.env.DEV;

export const isObject = (value: any) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

export function isSimpleType(type: string) {
  return /^(string|number|integer|boolean)$/.test(type);
}

export function resolveBinding(binding: string = '', prefix: string = '') {
  return [prefix, binding].filter(t => t.length).join('.');
}

const DEFAULT_OPTIONS = [];
// const COMPARISON_OPERATORS = ['$eq', '$gt', '$lt', '$gte', '$lte'];

export function isEnumField(definition: ParsedFieldDefinition) {
  const {
    extra: { dataEnum, options }
  } = definition;

  return Array.isArray(options) || typeof dataEnum === 'string';
}

export function resolveEnumOptions(values: object, definition: ParsedFieldDefinition) {
  let {
    name,
    extra: { dataEnum, options }
  } = definition;

  if (!isEnumField(definition)) {
    return;
  }

  // in case when options was defined in schema as a dataEnum, we need to resolve it
  if (typeof dataEnum === 'string') {
    options = resolveDataEnumOptions(values, name, dataEnum);
  }

  options = resolveVisibleEnumOptions(values, name, options!);
  if (IS_DEV) {
    // tslint:disable-next-line:no-shadowed-variable
    options = options.map(({ id, name }) => ({ id, name: `[${id}] ${name}` }));
  }

  return options;
}

export function resolveDataEnumOptions(values: object, propertyBinding: string, dataEnumBinding: string): Array<LookupEnumModel<any>> {
  const dataBindingRef = resolveDataBindingReference(propertyBinding, dataEnumBinding);
  return _get(values, dataBindingRef, DEFAULT_OPTIONS);
}

export function resolveTitle(definition: ParsedFieldDefinition, values: object) {
  let {
    name,
    title,
    extra: { dataTitle }
  } = definition;

  if (typeof dataTitle === 'string') {
    const dataBindingRef = resolveDataBindingReference(name, dataTitle);
    title = _get(values, dataBindingRef);
    if (title == null) {
      warning(false, 'Unrecognized or empty dataTitle %s (full resolved path: %s)', dataTitle, dataBindingRef);
      return '';
    }
  }

  return title;
}

// tslint:disable-next-line:typedef
export function enhanceTitle({ title, titleSuffix = '', data }) {
  if (titleSuffix && titleSuffix.length) {
    // itemLabelSuffix can contain variables that we need to replace e.g '$firstName $lastName'
    (titleSuffix.match(/\$\w+/g) || []).forEach(($var: string) => {
      const replacementValue = data[$var.replace('$', '')] || '?'; // TODO
      titleSuffix = titleSuffix.replace($var, replacementValue);
    });

    title = `${title}: ${titleSuffix}`;
  }

  return title;
}

const CHECK_RELATIVE_PATH_REGEXP = /^(\.\.\/)/;
const REPLACE_RELATIVE_PATH_REGEXP = /\.?[^\.]+$/;

export function resolveDataBindingReference(prefix: string = '', dataBinding: string) {
  // the data binding can be defined as an absolute path using ^ character '^address.postcode' or relative 'postcode'

  // absolute from root
  if (dataBinding.startsWith('^')) {
    return dataBinding.substring(1);

    // relative to current level, look in current context
  }

  if (dataBinding.startsWith('./')) {
    // get rid of the prefix
    dataBinding = dataBinding.substring(2);
  } else if (dataBinding.startsWith('../')) {
    while (dataBinding.match(CHECK_RELATIVE_PATH_REGEXP)) {
      dataBinding = dataBinding.replace(CHECK_RELATIVE_PATH_REGEXP, '');
      prefix = prefix.replace(REPLACE_RELATIVE_PATH_REGEXP, '');
    }

    if (prefix.length) {
      if (dataBinding.length) {
        prefix = `${prefix}.${dataBinding}`;
      }

      return prefix;
    }

    return dataBinding;
  }

  if (prefix.endsWith(']')) {
    dataBinding = `${prefix}.${dataBinding}`;
  } else if (/\.[^\.]+$/.test(prefix)) {
    dataBinding = prefix.replace(/\.[^\.]+$/, `.${dataBinding}`);
  } else if (prefix.startsWith('^')) {
    // keep the ^ prefix for nested rules such as:
    // {
    //   address: {
    //     state: 'NSW'
    //   }
    // }
    dataBinding = `${prefix}.${dataBinding}`;
  }

  return dataBinding;
}

export function resolveVisibleEnumOptions(values: object, propertyBinding: string, options: Array<LookupEnumModel<any>>): Array<LookupEnumModel<any>> {
  // enum item can contain extra meta data indicating that given item is present only if one of the conditions is satisfied
  // {
  //   id: 'leather',
  //   name: 'Leather',
  //   _meta: {
  //     conditional: {
  //       rules: [{ Manufacturer: 'bmw', EngineType: 'Petrol' }]
  //     }
  //   }
  // },

  const filteredOptions = options.filter(item => {
    const rules = _get(item, '_meta.conditional.rules', []);
    if (!rules.length) {
      return true;
    }

    // check if we match at least one of the rules definitions
    // if not, this enum item is not going to be present in the list
    const isOptionVisible = matchesOneOfTheGrammarRules(rules, values, propertyBinding);
    return isOptionVisible;
  });

  return filteredOptions;
}

const replaceRefsInRule = (rule: GrammarRule, values: object, propertyBinding: string) => {
  // replace all $ref with real values

  function traverse(r: any) {
    if (Array.isArray(r)) {
      return r.map(traverse);
    }

    if (!isObject(r)) {
      return r;
    }

    const { $ref } = r;
    if (typeof $ref === 'string') {
      return _get(values, resolveDataBindingReference(propertyBinding, $ref));
    }

    return Object.entries(r).reduce((acc, [key, valueOrRule]) => {
      acc[key] = traverse(valueOrRule);
      return acc;
    }, {});
  }

  return traverse(rule);
};

export const matchesOneOfTheGrammarRules = (rules: Array<GrammarRule>, values: object, propertyBinding: string) => {
  // replace all $ref with real values first
  const rulesWithResolvedRef = rules.map(rule => replaceRefsInRule(rule, values, propertyBinding));
  // now use preprocessed rules
  let passedAtLeastOneRule = rulesWithResolvedRef.some(gr => {
    const passed = checkRule(gr, values, propertyBinding);
    return passed;
  });

  return passedAtLeastOneRule;
};

export const isFieldVisible = (definition: ParsedFieldDefinition, values: object) => {
  const visibilityRules = definition.extra.visibility;

  if (Array.isArray(visibilityRules)) {
    // check if we match at least one of the rules definitions
    // if not, this field is not visible
    const isVisible = matchesOneOfTheGrammarRules(visibilityRules, values, definition.name);
    return isVisible;
  }

  return true;
};

const checkItems = (rule: GrammarRule, values: object, propertyBinding: string) => {
  const items = _get(values, propertyBinding);

  if (!Array.isArray(items)) {
    return false;
  }

  const passed = Object.entries(rule).every(([key, valueOrRule]) => {
    let matchedItems; // very generic variable, holds either boolean or number value depending on operation

    switch (key) {
      case '$size':
        if (Number.isInteger(valueOrRule)) {
          return items.length === valueOrRule;
        } else if (isObject(valueOrRule)) {
          const [operator, size] = Object.entries(valueOrRule)[0] as any;
          switch (operator) {
            case '$eq':
              return items.length === size;
            case '$lt':
              return items.length < size;
            case '$gt':
              return items.length > size;
            case '$lte':
              return items.length <= size;
            case '$gte':
              return items.length >= size;
            default:
              break;
          }
        }
        return false;
      case '$oneOf': // one of the items needs to match the rule
        if (Array.isArray(valueOrRule)) {
          //   $oneOf: ['AU', 'NZ']
          matchedItems = items.some(itemValue => valueOrRule.includes(itemValue));
        } else {
          // $oneOf: {
          //   isSelected: true
          // }
          matchedItems = items.some((_, i) => checkRule(valueOrRule as GrammarRule, values, `${propertyBinding}[${i}]`));
        }
        return matchedItems;
      case '$exactlyOne': // exactly one and only one item must match the rule
        matchedItems = items.filter((_, i) => checkRule(valueOrRule as GrammarRule, values, `${propertyBinding}[${i}]`));
        return matchedItems.length === 1;
      case '$allOf': // all of the items must match the rule
        matchedItems = items.every((_, i) => checkRule(valueOrRule as GrammarRule, values, `${propertyBinding}[${i}]`));
        return matchedItems;
      case '$noneOf': // none of the items must match the rule
        matchedItems = items.some((_, i) => checkRule(valueOrRule as GrammarRule, values, `${propertyBinding}[${i}]`));
        return matchedItems === false;
      default:
        Logger.captureException(new InvalidDataError(`Unknown grammar rule ${key}`, key));
        break;
    }

    return true;
  });

  return passed;
};

export const checkRule = (rule: GrammarRule, values: object, propertyBinding: string = '', nestedCheck?: boolean) => {
  const allRulesPassed = Object.entries(rule).every(([key, valueOrRule]) => {
    if (key === '$items') {
      if (propertyBinding.startsWith('^')) {
        // remove ^ since we are going check value
        propertyBinding = propertyBinding.substring(1);
      }

      const passedItemsRule = checkItems(valueOrRule as GrammarRule, values, propertyBinding);
      return passedItemsRule;
    }

    let passedCurrentRule = false;
    let resolvedBinding = propertyBinding;

    if (!key.startsWith('$')) {
      if (nestedCheck) {
        resolvedBinding = `${propertyBinding}.${key}`;
      } else {
        resolvedBinding = resolveDataBindingReference(propertyBinding, key);
      }
      // keep the ^ prefix for nested rules such as:
      // {
      //   address: {
      //     state: 'NSW'
      //   }
      // }
      if (key.startsWith('^')) {
        if (isObject(valueOrRule)) {
          resolvedBinding = `^${resolvedBinding}`;
        }
      } else if (resolvedBinding.startsWith('^')) {
        // remove ^ since we are going check value
        if (!isObject(valueOrRule)) {
          resolvedBinding = resolvedBinding.substring(1);
        }
      }
    }

    const hasPassedCurrentRule = (expectedValue: any, existingValue: any) => {
      // add special exception for invisible values, empty strings can be null
      if (expectedValue === '' || expectedValue === null || expectedValue === undefined) {
        return existingValue === '' || existingValue === null || existingValue === undefined;
      }
      return expectedValue === existingValue;
    };

    switch (key) {
      case '$noneOf':
        if (Array.isArray(valueOrRule)) {
          const existingValue = _get(values, propertyBinding);
          passedCurrentRule = !valueOrRule.some(item => hasPassedCurrentRule(item, existingValue));
        }
        return passedCurrentRule;
      case '$not':
        // same rules as for the default: case below
        // but for easier readability and extendability copied here one to one with negation before return.
        if (isObject(valueOrRule)) {
          passedCurrentRule = checkRule(valueOrRule as GrammarRule, values, propertyBinding);
        } else {
          const existingValue = _get(values, resolvedBinding);
          const expectedValue = valueOrRule;
          passedCurrentRule = hasPassedCurrentRule(expectedValue, existingValue);
        }

        // negate the result
        passedCurrentRule = !passedCurrentRule;
        break;

      case '$contains':
        if (!isObject(valueOrRule) && typeof valueOrRule === 'string') {
          const existingValue = _get(values, resolvedBinding);

          if (existingValue === null || valueOrRule === null) {
            break;
          }

          let valueIndex = existingValue.indexOf(valueOrRule);

          if (valueIndex >= 0) {
            const firstCharIsNotAlpha = !firstCharacterIsAlpha(valueIndex >= 1 ? existingValue[valueIndex - 1] : '');
            const secondCharIsNotAlpha = !firstCharacterIsAlpha(
              existingValue.length - (valueIndex + valueOrRule.length) >= 1 ? existingValue[valueIndex + valueOrRule.length] : ''
            );

            return firstCharIsNotAlpha && secondCharIsNotAlpha;
          }
        }
        break;
      case '$gt':
      case '$lt':
        const existingValue = _get(values, resolvedBinding);
        let expectedValue = valueOrRule;
        if (isObject(expectedValue)) {
          const { $ref } = valueOrRule;

          expectedValue = _get(values, resolveDataBindingReference(propertyBinding, $ref));
        }

        // TODO check this, we might not needed because rules for invisible fields are not being evaluated
        // add special exception for invisible values, empty strings can be null
        if (expectedValue === '' || expectedValue == null) {
          return true;
        }

        if (existingValue instanceof Date) {
          expectedValue = new Date(expectedValue);
        }

        return key === '$gt' ? existingValue > expectedValue : existingValue < expectedValue;

      default:
        // otherwise we expect that 'key' is a property name
        // which sits relatively or absolutely to propertyBinding
        if (isObject(valueOrRule)) {
          passedCurrentRule = checkRule(valueOrRule as GrammarRule, values, resolvedBinding, true);
        } else {
          const existingValue = _get(values, resolvedBinding);
          const expectedValue = valueOrRule;

          // add special exception for invisible values, empty strings can be null
          if (expectedValue === '') {
            passedCurrentRule = existingValue === '' || existingValue === null;
          } else {
            passedCurrentRule = expectedValue === existingValue;
          }
        }
        break;
    }
    return passedCurrentRule;
  });

  return allRulesPassed;
};

function firstCharacterIsAlpha(char: string): boolean {
  if (char.length <= 0) {
    return false;
  }

  let charCode = char.charCodeAt(0);
  return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122);
}
