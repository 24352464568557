import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // padding: '20px 60px'
    },
    spinner: {
      marginRight: 6
    },
    bold: {
      fontWeight: 'bold',
      paddingRight: 2
    }
  });

export type ClassKeys = typeof styles;

export default styles;
