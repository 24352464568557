import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      margin: '2px 0px',
      background: theme.palette.grey[50],
      '&$expanded': {
        background: theme.palette.grey[100],
        margin: '2px 0px'
      },
      '&::before': {
        display: 'none'
      }
    },

    summaryRoot: {
      padding: 0,
      paddingRight: 14,
      '&$expanded': {
        minHeight: 48
      }
    },
    summaryExpandIcon: {
      marginRight: 0,
      textAlign: 'center'
    },
    summaryContent: {
      margin: '0 14px 0 0',
      '&$expanded': {
        margin: '0 14px 0 0'
      }
    },

    expanded: {
      // keep here and keep empty
      // to make the above rules working
    }
  }),
  { name: 'TableRowLikeAccordion' }
);
