import { QLD_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/qld/2-24/config';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { AddressFieldFormConfig } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';

export const QLD_FORM25_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...QLD_PARTY_FORM_CONFIG
};

export const QLD_FORM25_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG
};
