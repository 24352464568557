import { SA_ADDRESS_FORM_CONFIG as SA_DM_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/sa/2-23/config';
import { saNameChangeConversion } from '@sympli-mfe/document-components/party-form/sa/2-23/components/party-justification';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { SA_DM_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterMortgagees, filterTitleReferences } from './helpers';
import { ApiDischargeOfMortgage_2_23_1_Model, DischargeOfMortgage_2_23_1_Model } from './models';

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: SA_DM_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: SA_DM_PARTY_FORM_CONFIG,
  nameChangeConversion: saNameChangeConversion
});

class Converter implements IConverter<DischargeOfMortgage_2_23_1_Model, ApiDischargeOfMortgage_2_23_1_Model> {
  fromApiToFormModel(apiModel: ApiDischargeOfMortgage_2_23_1_Model): DischargeOfMortgage_2_23_1_Model {
    const relinquishingIds = apiModel.mortgages.map(mortgage => mortgage.mortgagees.map(mortgagee => mortgagee.partyBookId)).flat();
    let formModel: DischargeOfMortgage_2_23_1_Model = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
      addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || []),
      titleReferences: filterTitleReferences(apiModel),
      mortgagees: filterMortgagees(apiModel)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: DischargeOfMortgage_2_23_1_Model, _originalApiModel: ApiDischargeOfMortgage_2_23_1_Model): ApiDischargeOfMortgage_2_23_1_Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      addressBook: addressBookFromForm,
      titleReferences,
      mortgagees,
      ...rest
    } = formModel;

    const selectedTitles = titleReferences.filter(x => x.isSelected).map(x => x.reference);
    // const selectedMortgagees = mortgagees.map(x => x.partyBookId);
    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], _originalApiModel.partyBook || []),
      addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], _originalApiModel.addressBook || []),
      mortgages: formModel.mortgages.map(x => {
        return {
          ...x,
          titleReferences: x.titleReferences.map(t => {
            return {
              ...t,
              isSelected: x.isSelected ? selectedTitles.includes(t.reference) : t.isSelected
            };
          }),
          mortgagees: x.mortgagees.map(m => {
            const selectedMortgagee = mortgagees.find(x => x.partyBookId === m.partyBookId) || m;
            return selectedMortgagee;
          })
        };
      })
    };
  }
}

export default new Converter();
