import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    date: {
      width: 150,
      marginRight: 10
    },
    placeholder: {
      color: theme.palette.text.secondary
    },
    iconWarning: {
      color: theme.palette.secondary.main,
      marginRight: 4,
      flexShrink: 0,
      width: 16,
      height: 16,
      margin: 4,
      display: 'inline-block'
    },
    planDocumentWarning: {
      marginBottom: 5
    },
    planDocumentWarningText: {
      marginTop: 2
    },
    datePeriodDates: {
      marginBottom: 10
    }
  }),
  {
    name: 'DatePeriod'
  }
);
