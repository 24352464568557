import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionSaveDebitAccount } from '../actions';

export interface DebitAccountState {
  status: ApiStatus;
  error?: string;
}

const initialState: DebitAccountState = {
  status: 'idle',
  error: undefined
};

const debitAccountReducer = createReducer<
  //
  DebitAccountState,
  Action
>(initialState)
  .handleAction(
    actionSaveDebitAccount.request,
    (): DebitAccountState => ({
      ...initialState,
      error: undefined,
      status: 'pending'
    })
  )
  .handleAction(
    actionSaveDebitAccount.success,
    (): DebitAccountState => ({
      ...initialState,
      status: 'resolved'
    })
  )
  .handleAction(
    actionSaveDebitAccount.failure,
    (_, action): DebitAccountState => ({
      ...initialState,
      error: action.payload.error.message,
      status: 'rejected'
    })
  );

export default debitAccountReducer;
