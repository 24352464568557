// this file was automatically generated from checks.ts.mustache
import { DocumentAttachmentModel, StandardTermsDealingNumbersModel, StandardTermsModel } from './models';

export const REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE = (
  item: StandardTermsDealingNumbersModel,
  termsAndConditions: StandardTermsModel
) => {
  // if it's already populated, we don't need to check for required() rule
  if (IS_STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE_FILLED(item)) {
    return false;
  }
  // otherwise check if at least one of the other two fields is populated
  return !(IS_ADDITIONAL_COVENANT_FILLED(termsAndConditions.additionalCovenants) || IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED(termsAndConditions.supportingDocuments));
};

export const REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS = (parent: StandardTermsModel) => {
  // if it's already populated, we don't need to check for required() rule
  if (IS_ADDITIONAL_COVENANT_FILLED(parent.additionalCovenants)) {
    return false;
  }
  // otherwise check if at least one of the other two fields is populated
  return !(IS_STANDARD_TERMS_DOCUMENT_REFERENCES_FILLED(parent.standardTermsDealingNumbers) || IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED(parent.supportingDocuments));
};

export const REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT = (parent: StandardTermsModel) => {
  // if it's already populated, we don't need to check for required() rule
  if (IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED(parent.supportingDocuments)) {
    return false;
  }

  // otherwise check if at least one of the other two fields is populated
  return !(IS_ADDITIONAL_COVENANT_FILLED(parent.additionalCovenants) || IS_STANDARD_TERMS_DOCUMENT_REFERENCES_FILLED(parent.standardTermsDealingNumbers));
};

const IS_STANDARD_TERMS_DOCUMENT_REFERENCES_FILLED = (standardTermsDocumentReferences: StandardTermsDealingNumbersModel[]) =>
  standardTermsDocumentReferences.some(item => item.termNumber?.trim());

const IS_STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE_FILLED = (item: StandardTermsDealingNumbersModel) => {
  return (item.termNumber ?? '').trim() !== '';
};

const IS_ADDITIONAL_COVENANT_FILLED = (additionalCovenant: string) => {
  return additionalCovenant.trim() !== '';
};

const IS_TERMS_AND_CONDITIONS_ATTACHMENT_FILLED = (termsAndConditionsAttachment: DocumentAttachmentModel[]) => {
  return termsAndConditionsAttachment.length > 0;
};
