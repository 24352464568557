import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      '& > span': {
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: '14px',
        padding: '0px 4px'
      }
    },
    marginLeft: {
      marginLeft: 8
    }
  }),
  {
    name: 'ActivationStatusBadge'
  }
);
