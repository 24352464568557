import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { useWorkspaceBasicInfo } from '../../shared/detail/reducers/workspaceBasicInfo';
import { useStampDuty } from './reducer';
import StampDuty from './StampDuty';

export interface StampDutyRouteParams {
  workspaceId: string;
  participantId: string;
}

function StampDutyContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId } = useRouterParams<StampDutyRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const stampDutyState = useStampDuty(workspaceId, participantId);

  return (
    <StampDuty
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // redux
      // workspace basic info
      isLocked={workspaceBasicInfoState.detail?.isLocked}
      jurisdictionId={workspaceBasicInfoState.detail?.jurisdictionId}
      stampDutyState={stampDutyState}
      // other
      isLoading={workspaceBasicInfoState.isLoading || stampDutyState.isLoading}
      dispatch={dispatch}
    />
  );
}

export default React.memo(StampDutyContainer);
