import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      width: '100%',
      margin: '16px 0',
      flexDirection: 'column',
      background: colors.GREEK_WATERS_TRANSLUCENT,
      position: 'relative',

      left: -24,
      padding: '8px 24px',

      '@media (max-width:1536px)': {
        left: -40,
        padding: '8px 40px'
      },
      '@media (min-width:1536px) and (max-width:1920px)': {
        left: -20,
        padding: '8px 20px'
      }
    },
    header: {
      display: 'flex',
      alignItems: 'center'
    },
    headerText: {
      color: colors.SYMPLI_GREEN,
      fontWeight: 700,
      fontSize: 10,
      lineHeight: 1,
      textTransform: 'uppercase',
      marginLeft: 4
    },
    content: {
      marginTop: 8
    },
    contentText: {
      color: colors.NEUTRAL_600,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px'
    },
    dismissButton: {
      marginTop: 8,
      alignSelf: 'start',

      color: colors.NEUTRAL_400,
      fontSize: 12,
      fontWeight: 400,
      textDecorationLine: 'underline'
    }
  }),
  {
    name: 'MultipleLodgementCaseMessage'
  }
);
