import * as yup from 'yup';

import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { LookupItemModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import { DeceasedTenancyPartyCapacityEnum } from '../deceased-tenancy-detail/enums';

export const resolvePartyRoleCapacitySchema = (options: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[]) =>
  yup.string().nullable(true).required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(options), msg.INVALID_SELECTION).defined();
