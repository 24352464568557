import { LogTypeEnum } from '@sympli/api-gateway/enums';

import { WorkspaceActivityLogModel } from 'src/containers/workspace/shared/detail/components/tab-logs/models';
import { DocumentParticipationAddedNotificationContent } from './components/document-participation/AddedNotificationContent';
import { DocumentParticipationWithdrawnNotificationContent } from './components/document-participation/WithdrawnNotificationContent';

export function mapLogToNotificationContent(log: WorkspaceActivityLogModel) {
  switch (log.workspaceLogType) {
    case LogTypeEnum.DocumentParticipationWithdrawn:
      return DocumentParticipationWithdrawnNotificationContent;
    case LogTypeEnum.DocumentParticipationAdded:
      return DocumentParticipationAddedNotificationContent;
  }
}
