import { defaultMemoize } from 'reselect';

import { CaveatModel, CaveatorPartyModel, TitleReferenceModel } from './models';

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const getSelectedCaveat = defaultMemoize((caveatSelection: CaveatModel[]) => {
  // get selected caveat
  // user can select only one caveat
  // automatically select the first one incase there is only one caveat
  if (caveatSelection.length === 1) {
    return caveatSelection[0];
  }

  return caveatSelection.find(({ isSelected }) => isSelected);
});

// grab all titles from caveats
export const filterTitleReferences = defaultMemoize((caveats: CaveatModel[]): TitleReferenceModel[] => {
  const titleReferences = caveats
    .flatMap(x => x.titleReferences)
    .reduce<TitleReferenceModel[]>((titles: TitleReferenceModel[], currentTitle: TitleReferenceModel) => {
      const exists = titles.findIndex(tr => tr.reference === currentTitle.reference);
      if (exists !== -1) {
        titles[exists].isSelected = titles[exists].isSelected || currentTitle.isSelected;
      } else {
        titles.push(currentTitle);
      }
      return titles;
    }, []);
  return titleReferences;
});

export const getCaveatsBeingWithdrawn = defaultMemoize((caveats: CaveatModel[], titleReferences: TitleReferenceModel[]): CaveatModel[] => {
  const selectedTitles = titleReferences.filter(({ isSelected }) => isSelected).flat();

  // filter caveats which include all the selected title references
  const caveatsBeingWithdrawn = caveats.filter(c => {
    const caveatTitleReferences = c.titleReferences.flatMap(title => title.reference);
    return selectedTitles.every(x => caveatTitleReferences.includes(x.reference));
  });

  return caveatsBeingWithdrawn;
});

export const getCaveators = defaultMemoize((caveats: CaveatModel[]): CaveatorPartyModel[] => {
  const result = getSelectedCaveat(caveats)?.caveators || [];
  return result;
});
