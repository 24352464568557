import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { $and, $noneOf, $oneOf } from '@sympli-mfe/document-forms-framework/utils';

import { ActionProhibitedEnum, ClaimCategoryEnum, EstateOfInterestClaimedEnum } from './enums';
import { CaveatModel, Claim, ExtentOfProhibition } from './models';
import { VISIBILITY_CHECK_CLAIM_DATE } from './sections/claim-details/checks';

/*
 path:
 - qualification    

 schema version:
[
  {
    "actionProhibitedOption": 1
  },
  {
    "actionProhibitedOption": 2
  },
  {
    "actionProhibitedOption": 5
  }
]
*/
export const VISIBILITY_CHECK_QUALIFICATION = (extentOfProhibition: ExtentOfProhibition): boolean => {
  return [ActionProhibitedEnum.ActionProhibited1, ActionProhibitedEnum.ActionProhibited2, ActionProhibitedEnum.ActionProhibited5].includes(
    extentOfProhibition.actionProhibitedOption as number
  );
};

/*
 path:
 - lrDocumentId    

 schema version:
[
  {
    "actionProhibitedOption": 3
  },
  {
    "actionProhibitedOption": 6
  },
  {
    "actionProhibitedOption": 7
  }
]
*/
export const VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID = (extentOfProhibition: ExtentOfProhibition): boolean => {
  return [ActionProhibitedEnum.ActionProhibited3, ActionProhibitedEnum.ActionProhibited6a, ActionProhibitedEnum.ActionProhibited6b].includes(
    extentOfProhibition.actionProhibitedOption as number
  );
};

/*
 path:
 - claimDetails.claimCategory    

 schema version:
[
  {
    "estateOrInterestClaimed": {
      "$not": 13
    }
  }
]
*/
export const VISIBILITY_CHECK_CLAIM_CATEGORY = (claimDetails: Claim): boolean => {
  const estateOrInterestClaimed = claimDetails.estateOrInterestClaimed ?? null;
  return ![null, EstateOfInterestClaimedEnum.RegisteredProprietor].includes(estateOrInterestClaimed);
};

/*
 path:
 - claimDetails.claimParties    

 schema version:
[
  {
    "claimCategory": 1,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 6,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 7,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 11,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 12,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 13,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 14,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 15,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 18,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 19,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 20,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 21,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 22,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 23,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  }
]
*/
export const VISIBILITY_CHECK_CLAIM_PARTIES = (claimDetails: Claim): boolean => {
  const allowedClaimCategoryValues: ClaimCategoryEnum[] = [1, 6, 7, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23];

  return $and(
    //
    $oneOf(claimDetails.claimCategory, allowedClaimCategoryValues),
    $noneOf(claimDetails.estateOrInterestClaimed, [null, EstateOfInterestClaimedEnum.RegisteredProprietor])
  );

  // return $or(
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 1), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 6), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 7), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 11), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 12), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 13), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 14), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 15), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 18), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 19), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 20), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 21), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 22), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 23), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13))
  // );
};

/*
 path:
 - claimDetails.lrDocumentID    

 schema version:
[
  {
    "claimCategory": 3,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 6,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 9,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 10,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 11,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 13,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 14,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 15,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 17,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 18,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 19,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 20,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 21,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 22,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 23,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  },
  {
    "claimCategory": 24,
    "estateOrInterestClaimed": {
      "$not": 13
    }
  }
]
*/
export const VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID = (claimDetails: Claim): boolean => {
  const allowedClaimCategoryValues: ClaimCategoryEnum[] = [3, 6, 9, 10, 11, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24];

  return $and(
    //
    $oneOf(claimDetails.claimCategory, allowedClaimCategoryValues),
    claimDetails.estateOrInterestClaimed !== EstateOfInterestClaimedEnum.RegisteredProprietor
  );

  // $or(
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 3), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 6), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 9), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 10), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 11), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 13), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 14), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 15), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 17), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 18), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 19), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 20), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 21), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 22), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 23), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13)),
  //   $and($eq($get(values, 'claimDetails.claimCategory'), 24), $not($get(values, 'claimDetails.estateOrInterestClaimed'), 13))
  // );
};

/*
 path:
 - dutyAssessmentNumber    

 schema version:
[
  {
    "claimDetails": {
      "estateOrInterestClaimed": 0
    }
  },
  {
    "claimDetails": {
      "estateOrInterestClaimed": 9
    }
  }
]
*/
const DAN_MIN_DATE = new Date('1987-01-01T00:00:00');
const DAN_MAX_DATE = new Date('2016-06-30T23:59:59');
const dateFallsWithinDanRange = (claimDate?: Date | string | null): boolean => {
  if (typeof claimDate !== 'string' && !(claimDate instanceof Date)) {
    return false;
  }
  // just a fallback to make sure that we have a Date type
  // this should in fact never happen since Datepicker returns Date type
  const claimDateAsDate = typeof claimDate === 'string' ? new Date(claimDate) : claimDate;
  // date of interest must be between 01/01/1987 to 01/07/2016
  return claimDateAsDate >= DAN_MIN_DATE && claimDateAsDate <= DAN_MAX_DATE;
};
const danAppliesToClaimCategory = (estateOrInterestClaimed: EstateOfInterestClaimedEnum, claimCategory: ClaimCategoryEnum): boolean => {
  switch (estateOrInterestClaimed) {
    case EstateOfInterestClaimedEnum.Mortgage:
      return [ClaimCategoryEnum.LoanAgreement, ClaimCategoryEnum.MortgageOfEstateInFeeSimple].includes(claimCategory);
    case EstateOfInterestClaimedEnum.Charge:
      return [ClaimCategoryEnum.Agreement, ClaimCategoryEnum.ChargeOfEstateInFeeSimple].includes(claimCategory);
    default:
      return false;
  }
};
export const VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER = ({ claimDetails }: CaveatModel): boolean => {
  // https://tickleme.atlassian.net/browse/WEB-10199
  const { estateOrInterestClaimed, claimCategory, claimDate } = claimDetails!;
  return danAppliesToClaimCategory(estateOrInterestClaimed!, claimCategory!) && dateFallsWithinDanRange(claimDate);
};

/*
 path:
 - caveators    
*/
export const VISIBILITY_CHECK_CAVEATORS = ({ extentOfProhibitions, proprietors }: CaveatModel): boolean => {
  // Business rule:
  // 'The set of Parties Receiving must be the same as ALL of the Proprietors (...) if All Registered Proprietors Affected is "yes"
  // and an Action Prohibied specifies a Type Of Action Prohibited being "Action Prohibited 5" (...) [Rule 1000351]'
  const caveatorsMustBeSameAsProprietors =
    (proprietors || []).every(({ isAffected }) => isAffected) &&
    (extentOfProhibitions || []).some(({ actionProhibitedOption }) => actionProhibitedOption === ActionProhibitedEnum.ActionProhibited5);

  // If the caveators must be the same as the proprietors, there is no reason to display the caveators for selection.
  // If the caveators can be different, we can display this section.
  return !caveatorsMustBeSameAsProprietors;
};

export const caveatFallbackMap: FallbackMap<CaveatModel> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,

  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        reference: NO_FALLBACK
      }
    }
  },
  extentOfProhibitions: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        actionProhibitedOption: [null],
        qualification: [VISIBILITY_CHECK_QUALIFICATION, ''],
        lrDocumentId: [VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, '']
      }
    }
  },
  claimDetails: {
    $children: {
      estateOrInterestClaimed: [null],
      claimCategory: [VISIBILITY_CHECK_CLAIM_CATEGORY, null],
      detailsSupportingTheClaim: [''],
      claimDate: [VISIBILITY_CHECK_CLAIM_DATE, null as any],
      lrDocumentID: [VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID, ''],
      claimParties: {
        $arrayVisibility: [VISIBILITY_CHECK_CLAIM_PARTIES, null as any],
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: {
          $children: {
            partyBookId: [null],
            party: NO_FALLBACK,
            claimPartyCapacity: [null]
          }
        }
      },
      supportingDocuments: {
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: NO_FALLBACK
      }
    }
  },
  dutyAssessmentNumber: [VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER, null as any, ''],
  caveators: {
    $arrayVisibility: [VISIBILITY_CHECK_CAVEATORS, null as any],
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        addressBookId: [null],
        partyBookId: [null],
        address: NO_FALLBACK,
        party: NO_FALLBACK
      }
    }
  },

  detailsForServiceOfNotice: {
    $children: {
      contacts: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            partyBookId: [null],
            addressBookId: [null],
            address: NO_FALLBACK,
            party: NO_FALLBACK
          }
        }
      }
    }
  },
  proprietors: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isAffected: [true],
        addressBookId: [null],
        partyBookId: [null],
        address: NO_FALLBACK,
        party: NO_FALLBACK
      }
    }
  },

  caveatStatementsAgreed: [false]
};
