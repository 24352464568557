import * as React from 'react';

import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

interface Props {
  title: string;
  description: string;
}

function SettingSectionTopAlert({ title, description }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root} role="alert">
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
}

export default React.memo(SettingSectionTopAlert);
