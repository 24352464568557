import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import IconReactivate from 'src/containers/settings/settings/users-detail/icons/IconReactivate';
import IconSuspend from 'src/containers/settings/settings/users-detail/icons/IconSuspend';
import { ApiStatus } from 'src/utils/http';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  isSuspended: boolean;
  open: boolean;
  onClose: (value: boolean | undefined) => any;
  apiStatus: ApiStatus;
}

type Props = OwnProps & WithStyles<ClassKeys>;
const SuspensionDialog = (props: Props) => {
  const { classes, isSuspended, open, onClose, apiStatus } = props;
  const title = isSuspended ? (
    <>
      <IconReactivate className={classes.reactivateIcon} />
      Restore user account
    </>
  ) : (
    <>
      <IconSuspend className={classes.suspendIcon} />
      Suspend user
    </>
  );
  const okButtonText = isSuspended ? 'Reactivate' : 'Suspend';
  const dialogColor = isSuspended ? 'primary' : 'secondary';
  const dialogContent = isSuspended ? "This user's access to their Sympli account will now be restored. " : 'This user will no longer have access to their Sympli account.';
  return (
    <ConfirmationDialog
      title={title}
      okButtonText={okButtonText}
      open={open}
      onClose={onClose}
      showActionButtons
      color={dialogColor}
      isLoading={apiStatus === 'pending'}
      buttonArrow
    >
      {dialogContent}
    </ConfirmationDialog>
  );
};

export default withStyles(styles)(SuspensionDialog);
