import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { sharedStyles } from '../sharedStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...sharedStyles(theme),
    root: {
      ...sharedStyles(theme).root,
      padding: theme.spacing(0, 0, 1.25, 2),
      margin: theme.spacing(0)
    },
    popper: {
      marginTop: theme.spacing(4), // 16px
      '&::before': {
        marginLeft: theme.spacing(6) // 24px
      }
    },
    buttonlink: {
      ...sharedStyles(theme).link,
      padding: theme.spacing(0.62, 0, 0, 0) // 5px
    }
  }),
  {
    name: 'LodgementDateTime'
  }
);
