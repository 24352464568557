import * as yup from 'yup';

import { resolveMemoizedTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';

import { resolveTitleReferenceItemSchema } from '../../../../components/title-reference/validationSchema';
import { BaseTitleReferenceModel } from '../../models';
import { VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES } from '../../visibilityChecks';

// path: titleReferences
const yupTitleReferenceItem = resolveTitleReferenceItemSchema<BaseTitleReferenceModel>(false);
const yupTitleReferences = resolveMemoizedTitleReferencesValidationSchema<BaseTitleReferenceModel>(yupTitleReferenceItem);

export default yup //
  .mixed<BaseTitleReferenceModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES,
      validationWhenVisible: yupTitleReferences,
      isObjectOrArray: true
    })
  );
