import { useEffect, useState } from 'react';

import { AccessToken, TokenManagerInterface } from '@okta/okta-auth-js';

import Logger from '@sympli/ui-logger';

import { useOktaAuth } from 'src/auth/okta';
import http from 'src/utils/http';

/**
 * Helper to get the accessToken
 */
export function useToken() {
  const { oktaAuth, authState } = useOktaAuth();
  const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;
  // explicitly set accessToken for axios bearer token as default to prevent having to wait for the token to be set
  const [accessToken, setAccessToken] = useState<string | undefined>(http.Authorization);

  useEffect(() => {
    async function getToken() {
      try {
        if (authState?.isAuthenticated) {
          const tokenObj = (await tokenManager.get('accessToken')) as AccessToken;
          setAccessToken(tokenObj.accessToken);
        }
      } catch (error) {
        Logger.captureException(error);
      }
    }
    getToken();
    // add check for authState.accessToken because when token gets renewed, tokenManager would not change, we need authState to reflect the token renew action
  }, [authState?.isAuthenticated, tokenManager, authState?.accessToken]);

  return accessToken;
}
