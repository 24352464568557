import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { PartyCapacityEnum } from '@sympli-mfe/enums-2-18/vic';

import { VIC_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { TransfereeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup } from '../../enums';

const yupTransfereeDetails = resolveMemoizedTenancyValidation({
  //
  partyCapacityLookup: TransfereeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup,
  partyCapacityMinor: PartyCapacityEnum.Minor,
  isAddressRequired: true,
  partyFormConfig: VIC_TRANSFER_PARTY_FORM_CONFIG
});

export default yupTransfereeDetails;
