// this file was automatically generated from components/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    suggestionsContainer: {
      maxHeight: 110,
      overflow: 'auto'
    }
  }),
  {
    name: 'RecipientSubscriberLookup'
  }
);
