import * as React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { Form25_2_24_1Model } from '../../models';
import { VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL } from '../../visibilityCheck';
import Individual from './individual';
import Organisation from './organisation';

function Transferee() {
  const { values } = useFormikContext<Form25_2_24_1Model>();
  const transfereeIsIndividual = VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL(values);
  const transfereeParty = values.partyBook.find(pb => pb.id === values.transferee.partyBookId)!;
  const transfereeName = transfereeIsIndividual ? [transfereeParty.firstName, transfereeParty?.lastName].filter(Boolean).join(' ') : transfereeParty.organisationName;

  return (
    <Section title="Foreign transferee details">
      <FormGroup title={transfereeIsIndividual ? 'Individual' : 'Organisation'}>
        <ReadOnlyField //
          label={transfereeIsIndividual ? 'Name' : 'Organisation'}
          value={transfereeName}
        />
      </FormGroup>
      {transfereeIsIndividual ? <Individual /> : <Organisation />}
    </Section>
  );
}

export default React.memo(Transferee);
