import * as React from 'react';
import { memo } from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel, InvitationsDashboardV2ApiRequest } from '../models';
import TodayCard from './components/TodayCard';
import TotalCard from './components/TotalCard';
import { InvitationsDashboardAggregatorApiRequest, InvitationsDashboardAggregatorApiResponse } from './models';
import { useStyles } from './styles';

interface AggregatorsProps {
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(args: InvitationsDashboardAggregatorApiRequest): Promise<InvitationsDashboardAggregatorApiResponse>;
  onActionClick(filters: InvitationsDashboardV2ApiRequest, engagedTab: InvitationsDashboardRouteTabsEnumV2, currentTabFilters: InvitationsDashboardUiFilterModel): void;
  refetching?: boolean;
}

const Aggregators = (props: AggregatorsProps) => {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="row" className={classes.root}>
      <TodayCard {...props} />
      <TotalCard {...props} />
    </FlexLayout>
  );
};

export default memo(Aggregators);
