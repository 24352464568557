import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG as NSW_LEASE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { ApiDocumentPartyModel, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DatePeriod, FormDatePeriod } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import { fallbackMap } from './fallback';
import { getInterestsOnTitle } from './helpers';
import { ApiSubLease2_21_1Model, SubLease2_21_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_LEASE_PARTY_FORM_CONFIG
});
class Converter implements IConverter<SubLease2_21_1Model, ApiSubLease2_21_1Model> {
  fromApiToFormModel(apiModel: ApiSubLease2_21_1Model, _context: IConverterContext): SubLease2_21_1Model {
    const { titleReferences, partyBook, leaseDetails, lessees } = apiModel;
    // Implement any necessary conversion from API to form values
    if (titleReferences.length === 1) {
      titleReferences[0].isSelected = true;
    }

    const interestsOnTitle = getInterestsOnTitle(apiModel);
    const lessors = interestsOnTitle.find(i => i.isSelected)?.lessors ?? [];
    let formModel: SubLease2_21_1Model = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(
        partyBook || [],
        [] // relinquishing id to be add in future pr
      ),
      leaseDetails: leaseDetails
        ? {
            ...leaseDetails,
            leasePeriod: this.convertDatePeriodWithoutTime(leaseDetails.leasePeriod),
            renewDetails: {
              ...leaseDetails.renewDetails,
              renewalPeriods: leaseDetails.renewDetails.optionToRenew ? leaseDetails.renewDetails.renewalPeriods.map(x => this.convertDatePeriodWithoutTime(x)) : []
            }
          }
        : undefined,
      interestsOnTitle: interestsOnTitle,
      lessors: lessors,
      lessees: convertReceivingTenancyDetailFromApiToForm(lessees)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: SubLease2_21_1Model, _originalApiModel: ApiSubLease2_21_1Model): ApiSubLease2_21_1Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      lessees: lessesFromForm,
      lessors,
      titleReferences,
      interestsOnTitle,
      partyBook: partyBookFromForm,
      ...rest
    } = formModel;

    const selectedInterest = interestsOnTitle.find(i => i.isSelected)!;

    return {
      ...rest,
      attachments: formModel.documentAttachments,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], _originalApiModel.partyBook || []) as ApiDocumentPartyModel<NswDocumentPartyJustification>[],
      titleReferences: titleReferences.map(tr => {
        return {
          ...tr,
          interestsOnTitle: tr.interestsOnTitle.map(i => {
            return {
              ...i,
              isSelected: tr.isSelected && selectedInterest.dealingNumber === i.dealingNumber
            };
          })
        };
      }),
      lessees: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: lessesFromForm, partyBookFromForm: partyBookFromForm || [] })
    };
  }

  private convertDatePeriodWithoutTime(datePeriod: DatePeriod): FormDatePeriod {
    if (!datePeriod || !datePeriod.startDate || !datePeriod.endDate) {
      return { startDate: null, endDate: null, dateLength: null };
    }

    return {
      ...datePeriod,
      startDate: new Date(new Date(datePeriod.startDate).toDateString()),
      endDate: new Date(new Date(datePeriod.endDate).toDateString())
    };
  }
}

export default new Converter();
