import Typography from '@mui/material/Typography';

import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import {
  addressFormatter,
  customerFormatter,
  dashboardTimeFormatter,
  distributionFundsStatusFormatter,
  doubleRowPrimarySecondaryFormatter,
  financialSettlementStatusFormatter,
  financialWorkspaceStatusFormatter,
  groupNameFormatter,
  jurisdictionFormatter,
  lodgementCaseStatusFormatter,
  memberFormatter,
  participantRoleFormatter,
  resolveCustomerName,
  settlementTimeFormatter,
  systemActivityFormatter,
  tasksStatusFormatter,
  unreadMessageFormatter,
  workspaceStatusBarFormatter
} from 'src/containers/dashboard/shared/formatters';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { colors } from 'src/theme';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme/screens';
import { titleAddress } from 'src/utils/formatters';
import { modelKey } from 'src/utils/formUtils';
import { FinancialDashboardRouteTabsEnumV2 } from '../models';
import { FinancialTableModel } from './models';

const cf = columnFormatterCheck<FinancialTableModel>();
type WithExtra<T, ExtraProps> = {
  [P in keyof T]: Omit<T[P], keyof ExtraProps> & ExtraProps;
};
// TODO move this to ui-framework
function dynamicColumns<R extends object, CH>(
  conditionalColumns: WithExtra<
    //
    ColumnsModel<R>,
    {
      thClassName?: ((args: CH) => string | undefined) | string;
      className?: ((args: CH) => string | undefined) | string;
      label: ((args: CH) => string | JSX.Element) | (string | JSX.Element);
      visibilityCheck?: (args: CH) => boolean;
    }
  >,
  args: CH
): ColumnsModel<R> {
  // recalculate columns based on their visibility
  const columns: ColumnsModel<R> = Object.entries(conditionalColumns) //
    .reduce((acc: ColumnsModel<R>, [key, { visibilityCheck, label, thClassName, className, ...columnDef }]) => {
      if (visibilityCheck?.(args)) {
        if (typeof label === 'function') {
          label = label(args);
        }

        if (typeof thClassName === 'function') {
          thClassName = thClassName(args);
        }

        if (typeof className === 'function') {
          className = className(args);
        }

        acc[key] = {
          ...columnDef,
          label,
          thClassName,
          className
        };
      }
      return acc;
    }, {});

  // return only visible columns
  return columns;
}

type DynamicArgs = {
  screenSize: ScreenSizeEnum;
  selectedTab: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
};
const fieldName = modelKey<FinancialTableModel>();

export const resolveColumns = ({
  screenVariant,
  workspaceStatusGroup,
  assignmentType
}: {
  //
  screenVariant: ScreenSizeVariant;
  workspaceStatusGroup: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
}) => {
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);
  const args: DynamicArgs = {
    screenSize,
    selectedTab: workspaceStatusGroup,
    assignmentType
  };
  if (assignmentType === AssignmentTypeEnum.AssignedToMe) {
    return resolveAssignToMeColumns(args);
  }
  return resolveAssignToGroupColumns(args);
};

function resolveAssignToMeColumns(args: DynamicArgs) {
  const columns: ColumnsModel<FinancialTableModel> = dynamicColumns<FinancialTableModel, DynamicArgs>(
    {
      accessed: {
        thClassName: 'w-[90px]',
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ACCESSED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   N   Y
          // 1536 N   N   N   N   N   Y
          // 1280 N   N   N   N   N   Y
          return selectedTab === FinancialDashboardRouteTabsEnumV2.recent;
        },
        name: fieldName('lastAccessed'),
        formatter: cf<FinancialTableModel['lastAccessed']>(dashboardTimeFormatter)
      },
      archivedDate: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[250px]';
          }
          return 'w-[200px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ARCHIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   Y   N
          // 1536 N   N   N   N   Y   N
          // 1280 N   N   N   N   Y   N
          return selectedTab === FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('archivedDate'),
        formatter: cf<FinancialTableModel['archivedDate']>(dashboardTimeFormatter)
      },
      setlDate: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[250px]';
            }
          }
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          return 'w-[90px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SETL DATE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('settlementDate'),
        get: ({ settlementDate, workspaceStatusId, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: FinancialTableModel) => ({
          settlementDate,
          workspaceStatusId,
          pendingProposedSettlementDate,
          expectedSettlementDate,
          isUnsupportedSettlementDate
        }),
        formatter: cf<{
          //
          settlementDate: FinancialTableModel['settlementDate'];
          workspaceStatusId: FinancialTableModel['workspaceStatusId'];
          pendingProposedSettlementDate: FinancialTableModel['pendingProposedSettlementDate'];
          expectedSettlementDate: FinancialTableModel['expectedSettlementDate'];
          isUnsupportedSettlementDate: FinancialTableModel['isUnsupportedSettlementDate'];
        }>(settlementTimeFormatter)
      },
      reference: {
        label: 'REFERENCE',
        // auto width
        visibilityCheck() {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        //width: 160, // MR explicitly commented out so we have at least one flexible column width
        sortable: false,
        name: fieldName('sympliId'),
        get: ({ reference: primary, sympliId: secondary, workspaceTransactionType }: FinancialTableModel) => ({
          primary,
          secondary: workspaceTransactionType ? secondary + ' • ' + workspaceTransactionType : secondary
        }),
        formatter: cf<{
          //
          primary: FinancialTableModel['reference'];
          secondary: string;
        }>(doubleRowPrimarySecondaryFormatter)
      },
      address: {
        label: 'Address',
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.complete) {
              return 'w-[200px]';
            }
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[250px]';
            }
          }
          return 'w-[160px]';
        },
        visibilityCheck({ screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 N   N   N   N   N   N
          // 1280 N   N   N   N   N   N
          return screenSize === ScreenSizeEnum.LargeScreen;
        },
        name: fieldName('addresses'),
        sortable: false,
        get: ({ addresses, jurisdictionId }: FinancialTableModel) => ({ addresses, jurisdictionId }),
        formatter: cf<{ addresses: FinancialTableModel['addresses']; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple addresses found</b>
                  </div>
                  {row.addresses.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {titleAddress(item, row.jurisdictionId)}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      jdx: {
        thClassName({ screenSize, selectedTab }: DynamicArgs) {
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          return 'w-[40px]';
        },
        label: 'JDX',
        visibilityCheck({ screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   N   N
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return !(screenSize === ScreenSizeEnum.LargeScreen);
        },
        name: fieldName('jurisdictionId'),
        sortable: false,
        formatter: cf<FinancialTableModel['jurisdictionId']>(jurisdictionFormatter)
      },

      customer: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.complete) {
              return 'w-[200px]';
            }
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[250px]';
            }
          }
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          return 'w-[88px]';
        },
        label: 'CUSTOMER',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   Y   Y   Y   Y   Y
          // 1536 N   Y   Y   Y   Y   Y
          // 1280 N   N   N   N   N   N
          return !(
            selectedTab === FinancialDashboardRouteTabsEnumV2.all || //
            screenSize === ScreenSizeEnum.SmallLaptop
          );
        },
        name: fieldName('clients'),
        sortable: false,
        formatter: cf<FinancialTableModel['clients']>(customerFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple Customers found</b>
                  </div>
                  {row.clients.map((item, i) => {
                    const custName = item ? resolveCustomerName(item) : '';
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {custName ? custName : '-'}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return anchorEl.innerText;
          }
        }
      },

      myRole: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[250px]';
            }
          }
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          return 'w-[88px]';
        },
        label: 'MY ROLE',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        name: fieldName('roles'),
        sortable: false,
        formatter: cf<FinancialTableModel['roles']>(participantRoleFormatter)
      },

      myTasks: {
        thClassName: 'w-[88px]',
        label: 'MY TASKS',
        visibilityCheck({ screenSize, selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   N   N   Y
          // 1536 Y   Y   Y   N   N   Y
          // 1280 N   Y   Y   N   N   N
          return !(
            selectedTab === FinancialDashboardRouteTabsEnumV2.archived ||
            selectedTab === FinancialDashboardRouteTabsEnumV2.complete ||
            (screenSize === ScreenSizeEnum.SmallLaptop && //
              selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
          );
        },
        name: fieldName('taskInfo'),
        sortable: false,
        horizontalAlign: 'center',
        formatter: cf<FinancialTableModel['taskInfo']>(tasksStatusFormatter)
      },

      systemActivity: {
        thClassName: 'w-[240px]',
        label: 'SYSTEM ACTIVITY',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('lastSystemActivity'),
        sortable: false,
        formatter: cf<FinancialTableModel['lastSystemActivity']>(systemActivityFormatter)
      },

      documents: {
        thClassName: ({ screenSize, selectedTab }: DynamicArgs) => {
          return screenSize <= ScreenSizeEnum.SmallLaptop && (selectedTab === FinancialDashboardRouteTabsEnumV2.all || selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
            ? 'w-[56px]'
            : 'w-[88px]';
        },
        label: ({ screenSize, selectedTab }: DynamicArgs) => {
          return screenSize <= ScreenSizeEnum.SmallLaptop && (selectedTab === FinancialDashboardRouteTabsEnumV2.all || selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
            ? 'DOCS'
            : 'DOCUMENTS';
        },
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        sortable: false,
        get: ({ lodgementCaseStatusId, allDocumentsFullySigned }: FinancialTableModel) => ({ lodgementCaseStatusId, allDocumentsFullySigned }),
        name: fieldName('lodgementCaseStatusId'),
        formatter: cf<{
          //
          lodgementCaseStatusId: FinancialTableModel['lodgementCaseStatusId'];
          allDocumentsFullySigned: FinancialTableModel['allDocumentsFullySigned'];
        }>(lodgementCaseStatusFormatter)
      },

      financials: {
        thClassName: ({ screenSize, selectedTab }: DynamicArgs) => {
          return screenSize <= ScreenSizeEnum.SmallLaptop && (selectedTab === FinancialDashboardRouteTabsEnumV2.all || selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
            ? 'w-[56px]'
            : 'w-[88px]';
        },
        label: ({ screenSize, selectedTab }: DynamicArgs) => {
          return screenSize <= ScreenSizeEnum.SmallLaptop && (selectedTab === FinancialDashboardRouteTabsEnumV2.all || selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
            ? 'FINS'
            : 'FINANCIALS';
        },
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('financialSettlementStatus'),
        sortable: false,
        formatter: cf<FinancialTableModel['financialSettlementStatus']>(financialSettlementStatusFormatter)
      },

      funds: {
        thClassName: 'w-[84px]',
        label: 'FUNDS',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('isFinancialBalanced'),
        sortable: false,
        formatter: cf<FinancialTableModel['isFinancialBalanced']>(distributionFundsStatusFormatter)
      },
      wsStatusText: {
        thClassName: 'w-[95px]', //In figma the header label width is 88px, but to display SETTLING (ERRORS) we need 95px
        label({ selectedTab }: DynamicArgs): string {
          return selectedTab === FinancialDashboardRouteTabsEnumV2.archived ? 'STATUS' : 'WS STATUS';
        },
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   N   N   N   Y   N
          // 1536 Y   N   N   N   Y   N
          // 1280 Y   N   N   N   Y   N
          return (
            selectedTab === FinancialDashboardRouteTabsEnumV2.all || //
            selectedTab === FinancialDashboardRouteTabsEnumV2.archived
          );
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        formatter: cf<FinancialTableModel['workspaceStatusId']>(financialWorkspaceStatusFormatter)
      },
      unreadMessageCount: {
        thClassName: 'w-[16px] pr-[18px]',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   N   N   Y
          // 1536 Y   Y   Y   N   N   Y
          // 1280 Y   Y   Y   N   N   Y
          return !(selectedTab === FinancialDashboardRouteTabsEnumV2.complete || selectedTab === FinancialDashboardRouteTabsEnumV2.archived);
        },
        label: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M15.5 5.99995C15.5 5.69495 15.255 5.44995 14.95 5.44995C14.645 5.44995 14.4 5.69495 14.4 5.99995V6.13995L8 8.89995L1.6 6.13995V4.99995C1.6 4.19995 2.25 3.54995 3.05 3.54995H11.45C11.755 3.54995 12 3.30495 12 2.99995C12 2.69495 11.755 2.44995 11.45 2.44995H3.05C1.645 2.44995 0.5 3.59495 0.5 4.99995V11C0.5 12.405 1.645 13.55 3.05 13.55H12.95C14.355 13.55 15.5 12.405 15.5 11V5.99995ZM12.95 12.45H3.05C2.25 12.45 1.6 11.8 1.6 11V7.33495L7.785 10.005C7.855 10.035 7.93 10.05 8.005 10.05C8.08 10.05 8.155 10.035 8.225 10.005L14.41 7.33495V11C14.41 11.8 13.76 12.45 12.96 12.45H12.95Z"
              fill={colors.BLACK}
            />
            <path d="M14.5 4.5C15.3284 4.5 16 3.82843 16 3C16 2.17157 15.3284 1.5 14.5 1.5C13.6716 1.5 13 2.17157 13 3C13 3.82843 13.6716 4.5 14.5 4.5Z" fill={colors.BLACK} />
          </svg>
        ),
        name: fieldName('unreadMessagesCount'),
        padding: 'none',
        sortable: false,

        formatter: cf<FinancialTableModel['unreadMessagesCount']>(unreadMessageFormatter)
      },
      workspaceStatusBar: {
        thClassName: 'w-[6px]',
        label: '',
        visibilityCheck() {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y    Y
          return true;
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        padding: 'none',
        formatter: cf<FinancialTableModel['workspaceStatusId']>(workspaceStatusBarFormatter)
      }
    },
    args
  );
  return columns;
}

function resolveAssignToGroupColumns(args: DynamicArgs) {
  const columns: ColumnsModel<FinancialTableModel> = dynamicColumns<FinancialTableModel, DynamicArgs>(
    {
      accessed: {
        thClassName: 'w-[90px]',
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ACCESSED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   N   Y
          // 1536 N   N   N   N   N   Y
          // 1280 N   N   N   N   N   Y
          return selectedTab === FinancialDashboardRouteTabsEnumV2.recent;
        },
        name: fieldName('lastAccessed'),
        formatter: cf<FinancialTableModel['lastAccessed']>(dashboardTimeFormatter)
      },
      archivedDate: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            return 'w-[200px]';
          }
          return 'w-[180px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'ARCHIVED',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   Y   N
          // 1536 N   N   N   N   Y   N
          // 1280 N   N   N   N   Y   N
          return selectedTab === FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('archivedDate'),
        formatter: cf<FinancialTableModel['archivedDate']>(dashboardTimeFormatter)
      },
      setlDate: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[180px]';
            }
          }
          return 'w-[90px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SETL DATE',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('settlementDate'),
        get: ({ settlementDate, workspaceStatusId, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: FinancialTableModel) => ({
          settlementDate,
          workspaceStatusId,
          pendingProposedSettlementDate,
          expectedSettlementDate,
          isUnsupportedSettlementDate
        }),
        formatter: cf<{
          //
          settlementDate: FinancialTableModel['settlementDate'];
          workspaceStatusId: FinancialTableModel['workspaceStatusId'];
          pendingProposedSettlementDate: FinancialTableModel['pendingProposedSettlementDate'];
          expectedSettlementDate: FinancialTableModel['expectedSettlementDate'];
          isUnsupportedSettlementDate: FinancialTableModel['isUnsupportedSettlementDate'];
        }>(settlementTimeFormatter)
      },
      reference: {
        label: 'REFERENCE',

        visibilityCheck() {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        //width: 160, // MR explicitly commented out so we have at least one flexible column width
        sortable: false,
        name: fieldName('sympliId'),
        get: ({ reference: primary, sympliId: secondary, workspaceTransactionType }: FinancialTableModel) => ({
          primary,
          secondary: workspaceTransactionType ? secondary + ' • ' + workspaceTransactionType : secondary
        }),
        formatter: cf<{
          //
          primary: FinancialTableModel['reference'];
          secondary: string;
        }>(doubleRowPrimarySecondaryFormatter)
      },
      address: {
        label: 'Address',
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          return 'w-[160px]';
        },
        visibilityCheck({ screenSize, selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   Y   N
          // 1536 N   N   N   N   N   N
          // 1280 N   N   N   N   N   N
          return selectedTab === FinancialDashboardRouteTabsEnumV2.archived && screenSize === ScreenSizeEnum.LargeScreen;
        },
        name: fieldName('addresses'),
        sortable: false,
        get: ({ addresses, jurisdictionId }: FinancialTableModel) => ({ addresses, jurisdictionId }),
        formatter: cf<{ addresses: FinancialTableModel['addresses']; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <b>Multiple addresses found</b>
                  <ul>
                    {row.addresses.map(item => {
                      return <li>{titleAddress(item, row.jurisdictionId)}</li>;
                    })}
                  </ul>
                </div>
              );
            default:
              return null;
          }
        }
      },
      jdx: {
        thClassName({ screenSize, selectedTab }: DynamicArgs) {
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[180px]';
            }
          }
          return 'w-[40px]';
        },
        label: 'JDX',
        visibilityCheck({ screenSize, selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return !(selectedTab === FinancialDashboardRouteTabsEnumV2.archived && screenSize === ScreenSizeEnum.LargeScreen);
        },
        name: fieldName('jurisdictionId'),
        sortable: false,
        formatter: cf<FinancialTableModel['jurisdictionId']>(jurisdictionFormatter)
      },

      customer: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          return 'w-[88px]';
        },
        label: 'CUSTOMER',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 N   N   N   N   Y   N
          // 1536 N   N   N   N   Y   N
          // 1280 N   N   N   N   N   N
          return (
            selectedTab === FinancialDashboardRouteTabsEnumV2.archived && //
            screenSize !== ScreenSizeEnum.SmallLaptop
          );
        },
        name: fieldName('clients'),
        sortable: false,
        formatter: cf<FinancialTableModel['clients']>(customerFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple Customers found</b>
                  </div>
                  {row.clients.map((item, i) => {
                    const custName = item ? resolveCustomerName(item) : '';
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {custName ? custName : '-'}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return anchorEl.innerText;
          }
        }
      },

      myRole: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.MediumScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[180px]';
            }
          }
          return 'w-[88px]';
        },
        label: 'MY ROLE',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        name: fieldName('roles'),
        sortable: false,
        formatter: cf<FinancialTableModel['roles']>(participantRoleFormatter)
      },

      myTasks: {
        thClassName: 'w-[88px]',
        label: 'MY TASKS',
        visibilityCheck({ screenSize, selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   N   N   Y
          // 1536 Y   Y   Y   N   N   Y
          // 1280 N   Y   N   N   N   N
          return !(
            selectedTab === FinancialDashboardRouteTabsEnumV2.archived || //
            selectedTab === FinancialDashboardRouteTabsEnumV2.complete || //
            (screenSize === ScreenSizeEnum.SmallLaptop && selectedTab === FinancialDashboardRouteTabsEnumV2.all) || //
            (screenSize === ScreenSizeEnum.SmallLaptop && selectedTab === FinancialDashboardRouteTabsEnumV2.toBeRebooked) || //
            (screenSize === ScreenSizeEnum.SmallLaptop && selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
          );
          // (screenSize === ScreenSizeEnum.SmallLaptop && //
          //   selectedTab === FinancialDashboardRouteTabsEnumV2.all) ||
          // (screenSize === ScreenSizeEnum.SmallLaptop && //
          //   selectedTab === FinancialDashboardRouteTabsEnumV2.recent)
          //);
        },
        name: fieldName('taskInfo'),
        sortable: false,
        horizontalAlign: 'center',
        formatter: cf<FinancialTableModel['taskInfo']>(tasksStatusFormatter)
      },

      systemActivity: {
        thClassName: ({ assignmentType }: DynamicArgs) => {
          if (assignmentType === AssignmentTypeEnum.ReAssigned) {
            return 'w-[220px]';
          }

          return 'w-[240px]';
        },
        label: 'SYSTEM ACTIVITY',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('lastSystemActivity'),
        sortable: false,
        formatter: cf<FinancialTableModel['lastSystemActivity']>(systemActivityFormatter)
      },

      documents: {
        thClassName: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.complete) {
              return 'w-[84px] pr-[8px]';
            }
            return 'w-[52px] pr-[8px]';
          }
          return 'w-[88px]';
        },
        className: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab !== FinancialDashboardRouteTabsEnumV2.complete) {
              return 'text-clip';
            }
          }
        },
        label: ({ screenSize }: DynamicArgs) => (screenSize === ScreenSizeEnum.SmallLaptop ? 'DOCS' : 'DOCUMENTS'),
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        sortable: false,
        get: ({ lodgementCaseStatusId, allDocumentsFullySigned }: FinancialTableModel) => ({ lodgementCaseStatusId, allDocumentsFullySigned }),
        name: fieldName('lodgementCaseStatusId'),
        formatter: cf<{
          //
          lodgementCaseStatusId: FinancialTableModel['lodgementCaseStatusId'];
          allDocumentsFullySigned: FinancialTableModel['allDocumentsFullySigned'];
        }>(lodgementCaseStatusFormatter)
      },

      financials: {
        thClassName: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.complete) {
              return 'w-[84px] pr-[8px]';
            }
            return 'w-[52px] pr-[8px]';
          }
          return 'w-[88px]';
        },
        className: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab !== FinancialDashboardRouteTabsEnumV2.complete) {
              return 'text-clip';
            }
          }
        },
        label: ({ screenSize }: DynamicArgs) => (screenSize === ScreenSizeEnum.SmallLaptop ? 'FINS' : 'FINANCIALS'),
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('financialSettlementStatus'),
        sortable: false,
        formatter: cf<FinancialTableModel['financialSettlementStatus']>(financialSettlementStatusFormatter)
      },

      funds: {
        label: 'FUNDS',
        thClassName: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.complete) {
              return 'w-[84px] pr-[8px]';
            }
            return 'w-[52px] pr-[8px]';
          }
          return 'w-[88px]';
        },
        className: ({ screenSize, selectedTab }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab !== FinancialDashboardRouteTabsEnumV2.complete) {
              return 'text-clip';
            }
          }
        },
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return selectedTab !== FinancialDashboardRouteTabsEnumV2.archived;
        },
        name: fieldName('isFinancialBalanced'),
        sortable: false,
        formatter: cf<FinancialTableModel['isFinancialBalanced']>(distributionFundsStatusFormatter)
      },
      wsStatusText: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[200px]';
            }
          }
          if (screenSize === ScreenSizeEnum.SmallLaptop) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[180px]';
            }
          }
          return 'w-[95px]'; //In figma the header label width is 88px, but to display SETTLING (ERRORS) we need 95px
        },
        label({ selectedTab }: DynamicArgs): string {
          return selectedTab === FinancialDashboardRouteTabsEnumV2.archived ? 'STATUS' : 'WS STATUS';
        },
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   N   N   N   Y   N
          // 1536 Y   N   N   N   Y   N
          // 1280 Y   N   N   N   Y   N
          return (
            selectedTab === FinancialDashboardRouteTabsEnumV2.all || //
            selectedTab === FinancialDashboardRouteTabsEnumV2.archived
          );
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        formatter: cf<FinancialTableModel['workspaceStatusId']>(financialWorkspaceStatusFormatter)
      },
      group: {
        thClassName: ({ selectedTab, screenSize }: DynamicArgs) => {
          if (screenSize === ScreenSizeEnum.LargeScreen) {
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.complete) {
              return 'w-[120px]';
            }
            if (selectedTab === FinancialDashboardRouteTabsEnumV2.archived) {
              return 'w-[100px]';
            }
          }
          return 'w-[88px]';
        },
        label: 'Group',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        name: fieldName('groupName'),
        sortable: false,
        formatter: cf<FinancialTableModel['groupName']>(groupNameFormatter)
      },
      member: {
        thClassName: 'w-[88px]',
        label: 'Member',
        visibilityCheck({ selectedTab, screenSize }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   N   Y
          // 1536 Y   Y   Y   Y   N   Y
          // 1280 Y   Y   Y   Y   N   Y
          return !(selectedTab === FinancialDashboardRouteTabsEnumV2.archived);
        },
        name: fieldName('member'),
        sortable: false,
        formatter: cf<FinancialTableModel['member']>(memberFormatter)
      },

      unreadMessageCount: {
        thClassName: 'w-[16px] pr-[18px]',
        visibilityCheck({ selectedTab }: DynamicArgs) {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   N   N   Y
          // 1536 Y   Y   Y   N   N   Y
          // 1280 Y   Y   Y   N   N   Y
          return !(
            selectedTab === FinancialDashboardRouteTabsEnumV2.complete ||
            //
            selectedTab === FinancialDashboardRouteTabsEnumV2.archived
          );
        },
        label: (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M15.5 5.99995C15.5 5.69495 15.255 5.44995 14.95 5.44995C14.645 5.44995 14.4 5.69495 14.4 5.99995V6.13995L8 8.89995L1.6 6.13995V4.99995C1.6 4.19995 2.25 3.54995 3.05 3.54995H11.45C11.755 3.54995 12 3.30495 12 2.99995C12 2.69495 11.755 2.44995 11.45 2.44995H3.05C1.645 2.44995 0.5 3.59495 0.5 4.99995V11C0.5 12.405 1.645 13.55 3.05 13.55H12.95C14.355 13.55 15.5 12.405 15.5 11V5.99995ZM12.95 12.45H3.05C2.25 12.45 1.6 11.8 1.6 11V7.33495L7.785 10.005C7.855 10.035 7.93 10.05 8.005 10.05C8.08 10.05 8.155 10.035 8.225 10.005L14.41 7.33495V11C14.41 11.8 13.76 12.45 12.96 12.45H12.95Z"
              fill={colors.BLACK}
            />
            <path d="M14.5 4.5C15.3284 4.5 16 3.82843 16 3C16 2.17157 15.3284 1.5 14.5 1.5C13.6716 1.5 13 2.17157 13 3C13 3.82843 13.6716 4.5 14.5 4.5Z" fill={colors.BLACK} />
          </svg>
        ),
        name: fieldName('unreadMessagesCount'),
        padding: 'none',
        sortable: false,
        formatter: cf<FinancialTableModel['unreadMessagesCount']>(unreadMessageFormatter)
      },
      workspaceStatusBar: {
        thClassName: 'w-[6px]',
        label: '',
        visibilityCheck() {
          //      ALL ACT TBR COM ARC REC
          // 1920 Y   Y   Y   Y   Y   Y
          // 1536 Y   Y   Y   Y   Y   Y
          // 1280 Y   Y   Y   Y   Y   Y
          return true;
        },
        name: fieldName('workspaceStatusId'),
        sortable: false,
        padding: 'none',
        formatter: cf<FinancialTableModel['workspaceStatusId']>(workspaceStatusBarFormatter)
      }
    },
    args
  );
  return columns;
}
