import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

import { WA_M_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { Mortgage2_27_0Model } from './models';
import yupAdditionalCovenants from './sections/additional-covenants/validationSchema';
import yupMcpNumber from './sections/mcp-number/validationSchema';
import yupMortgageDate from './sections/mortgage-date/validationSchema';
import yupMortgageeDetails from './sections/mortgagee-details/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<Mortgage2_27_0Model>({
    titleReferences: yupTitleReferences,
    additionalCovenants: yupAdditionalCovenants,
    mcpNumber: yupMcpNumber,
    mortgageDate: yupMortgageDate,
    mortgageeDetails: yupMortgageeDetails,
    additionalAttachments: memoizeSchema(yupAdditionalAttachments(WA_M_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS)),
    isFinancialSettlement: yup.mixed<boolean>(),
    mortgagors: yupMortgagors,
    addressBook: yup.mixed<AddressBookEntityModel[]>(),
    partyBook: yupPartyBook
  })
  .defined()
  .log();
