import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';

import yupCaveators from './components/caveator-party-readonly-array/validationSchema';
import { CaveatModel, WithdrawalOfCaveat2_26_0Model } from './models';
import yupAdditionalAttachments from './sections/additional-attachments/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupWithdrawCaveats from './sections/withdraw-caveat/validationSchema';

export default yup
  .object<WithdrawalOfCaveat2_26_0Model>({
    titleReferences: yupTitleReferences,
    withdrawCaveats: yupWithdrawCaveats,
    partyBook: yupPartyBook,
    caveators: yupCaveators,
    addressBook: yup.mixed<AddressBookEntityModel[]>(),
    additionalAttachments: yupAdditionalAttachments,
    caveats: yup.mixed<CaveatModel[]>()
  })
  .defined()
  .log();
