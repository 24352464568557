import React from 'react';

import { usePrompt } from './usePrompt';

interface Props {
  when: boolean;
  message: () => any;
  children: React.ReactElement<any>;
}

export default function Prompt({ when, message, children }: Props) {
  usePrompt(message, when);
  // if when is true, we cannot leave the page, and if navigate happens,
  // we have the dialog which is the children element
  if (when) {
    return <>{children}</>;
  } else {
    return null;
  }
}
