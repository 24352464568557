import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspaceTasks, WorkspaceRelatedApiRequest } from '../actions';
import { WorkspaceTaskApiResponse } from '../components/my-tasks/models';

export interface WorkspaceTasksState {
  items: WorkspaceTaskApiResponse[];
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceRelatedApiRequest;
}

const initialState: WorkspaceTasksState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useWorkspaceTasks(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.workspaceTasks);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const workspaceTasksReducer = createReducer<
  //
  WorkspaceTasksState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceTasks.request, (state, action): WorkspaceTasksState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchWorkspaceTasks.success,
    (state, action): WorkspaceTasksState => ({
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.items
    })
  )
  .handleAction(
    actionFetchWorkspaceTasks.failure,
    (state, action): WorkspaceTasksState => ({
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    })
  );

export default workspaceTasksReducer;
