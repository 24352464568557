import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    detailRow: {
      marginBottom: 16
    },
    bold: {
      fontWeight: 'bold'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
