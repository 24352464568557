import { VicNameChange, VicPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/vic/2-21/components/party-justification';
import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-21/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const VIC_WOC_PARTY_FORM_CONFIG: PartyFormConfig<VicNameChange> = {
  ...VIC_PARTY_FORM_CONFIG,
  nameChangeConfig: VicPartyNameChangeConfig
};
