import { createAction } from 'typesafe-actions';

import { SimpleSnackbarNotificationProps } from './simple-snackbar-notification';

export const actionOpenGlobalSimpleNotification = createAction(
  //
  'SWITCH_GLOBAL_SIMPLE_NOTIFICATION_OPEN'
)<
  //
  Omit<SimpleSnackbarNotificationProps, 'open'> & {
    //
    variant: Required<SimpleSnackbarNotificationProps['variant']>;
  }
>();

export const actionCloseGlobalSimpleNotification = createAction(
  //
  'SWITCH_GLOBAL_SIMPLE_NOTIFICATION_CLOSE'
)();
