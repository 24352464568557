import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import globalNsStyles from './styles/globalNs';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    '@global': globalNsStyles(theme),
    root: {}
  }),
  {
    name: 'ScaffoldedForm'
  }
);
