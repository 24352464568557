import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      textAlign: 'center',
      fontSize: '180px',
      fontFamily: "'Catamaran', sans-serif",
      fontWeight: 800,
      margin: '20px 15px',
      minHeight: 380, // original height is 254, plus 126(which is the height of the span) so 380 is the height when it rotates to the bottom

      '& > span': {
        display: 'inline-block',
        lineHeight: 0.7,
        position: 'relative',
        color: '#FFB485',

        '& > span': {
          display: 'inline-block',
          position: 'relative'
        }
      },

      '& > span:nth-of-type(1)': {
        perspective: 1000,
        perspectiveOrigin: '500% 50%',
        color: '#F0E395',

        '& > span': {
          transformOrigin: '50% 100% 0px',
          transform: 'rotateX(0)',
          animation: '$easyoutelastic 8s infinite'
        }
      },

      '& > span:nth-of-type(3)': {
        perspective: 'none',
        perspectiveOrigin: '50% 50%',
        color: '#D15C95',
        '& > span': {
          transformOrigin: '100% 100% 0px',
          transform: 'rotate(0deg)',
          animation: '$rotatedrop 8s infinite'
        }
      }
    },
    '@keyframes easyoutelastic': {
      '0%': {
        transform: 'rotateX(0)'
      },
      '9%': {
        transform: 'rotateX(210deg)'
      },
      '13%': {
        transform: 'rotateX(150deg)'
      },
      '16%': {
        transform: 'rotateX(200deg)'
      },
      '18%': {
        transform: 'rotateX(170deg)'
      },
      '20%': {
        transform: 'rotateX(180deg)'
      },
      '60%': {
        transform: 'rotateX(180deg)'
      },
      '80%': {
        transform: 'rotateX(0)'
      },
      '100%': {
        transform: 'rotateX(0)'
      }
    },

    '@keyframes rotatedrop': {
      '0%': {
        transform: 'rotate(0)'
      },
      '10%': {
        transform: 'rotate(30deg)'
      },
      '15%': {
        transform: 'rotate(90deg)'
      },
      '70%': {
        transform: 'rotate(90deg)'
      },
      '80%': {
        transform: 'rotate(0)'
      },
      '100%': {
        transform: 'rotateX(0)'
      }
    }
  }),
  { name: 'NotFoundAnimation' }
);
