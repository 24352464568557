import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';
import { WorkspaceStatusColorVariantEnum } from './models';

export interface StyleProps {
  variant: WorkspaceStatusColorVariantEnum;
}

type StyleClassKeys = 'root';

export const useStyles = makeStyles<Theme, StyleProps, StyleClassKeys>(
  (theme: Theme) => {
    return createStyles<StyleClassKeys, StyleProps>({
      root: ({ variant }) => {
        const root: React.CSSProperties = {
          width: '12px',
          height: '3px',
          borderRadius: '2px',
          transform: 'matrix(1, 0, 0, -1, 0, 0)'
        };

        switch (variant) {
          case 'success':
            return {
              ...root,
              background: colors.TEAL,
              boxShadow: `0px 0px 4px ${colors.GREEN_NEW}`
            };
          case 'warning':
            return {
              ...root,
              background: colors.SUNNY_DAY
            };
          case 'failure':
            return {
              ...root,
              background: colors.RED_NEW,
              boxShadow: '0px 0px 4px rgba(255, 0, 0, 0.6)'
            };
          case 'inactive':
          default:
            return {
              ...root,
              background: colors.GREY_40
            };
        }
      }
    });
  },
  {
    name: 'Status'
  }
);
