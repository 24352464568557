import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { $eq, $get, $items, $noneOfItems, $oneOfItems, getLookupValuesAllowingEmpty, isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { IS_REQUIRED_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER } from '../../isRequiredChecks';
import { PartyBookIdentifierModel, TitleReferenceModel, TransactionInstrumentModel } from '../../models';
import {
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES$LAND_DESCRIPTION,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES$PART_DESCRIPTION
} from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupTransactionInstruments = yup
  .array<TransactionInstrumentModel>()
  .required(msg.REQUIRED)
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .of(
    yup
      .object<TransactionInstrumentModel>()
      .nullable()
      .shape({
        documentType: yup.mixed().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
        dealingNumber: yup
          .mixed()
          .nullable()
          .test(
            validateWhenVisible({
              visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER,
              validationWhenVisible: yup
                .string()
                .default('')
                .trim()
                .nullable()
                .test(
                  testContextualRule({
                    /**
           * what we found in schema:
           [{"required":true,"message":null,"onlyIf":{"documentType":{"$noneOf":[1,2,3,4,5,6,7,8,9,13]}}}]
           *
           */
                    uniqueTestName: '"transactionInstruments[*].dealingNumber" contextual validation rule #1',
                    onlyIf: IS_REQUIRED_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER,
                    requirement: isNotNullOrEmpty,
                    message: msg.REQUIRED
                  })
                )
            })
          ),
        receivingParties: yup.mixed<PartyBookIdentifierModel>().test(
          validateWhenVisible({
            visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES,
            validationWhenVisible: yup
              .array<PartyBookIdentifierModel>()
              .test(
                testContextualRule({
                  uniqueTestName: '"transactionInstruments[*].receivingParties" contextual validation rule #1',
                  requirement: (receivingParties: PartyBookIdentifierModel[], context: object): boolean => {
                    return (
                      receivingParties.length > 0 &&
                      $noneOfItems($items(context, 'receivingParties'), item => $eq($get(item, 'partyBookId'), null) || $eq($get(item, 'partyBookId'), undefined))
                    );
                  },
                  message: msg.REQUIRED
                })
              )
              .max(10, msg.MAX_ITEMS(10))
              .of(
                yup
                  .object<PartyBookIdentifierModel>()
                  .nullable()
                  .shape({
                    partyBookId: yup.string().nullable().default('').trim().required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(NSW_PARTY_FORM_CONFIG))
                  })
              ),
            isObjectOrArray: true
          })
        ),
        titleReferences: yup
          .array<TitleReferenceModel>()
          .required(msg.REQUIRED)
          .test(
            testContextualRule({
              uniqueTestName: '"transactionInstruments[*].titleReferences" contextual validation rule #1',
              requirement: (titleReferences: TitleReferenceModel[], context: object): boolean => {
                return $oneOfItems($items(context, 'titleReferences'), item => $eq($get(item, 'isSelected'), true));
              },
              message: msg.REQUIRED
            })
          )
          .min(1, msg.MIN_ITEMS(1))
          .of(
            yup
              .object<TitleReferenceModel>()
              .nullable()
              .shape({
                isSelected: yup.boolean().required(msg.REQUIRED),
                isPartLandAffected: yup.boolean().required(msg.REQUIRED),
                partDescription: yup
                  .mixed<string>()
                  .nullable()
                  .test(
                    validateWhenVisible({
                      visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES$PART_DESCRIPTION,
                      validationWhenVisible: yup.string().default('').trim().required(msg.REQUIRED)
                    })
                  ),
                landDescription: yup
                  .mixed<string>()
                  .nullable()
                  .test(
                    validateWhenVisible({
                      visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES$LAND_DESCRIPTION,
                      validationWhenVisible: yup.string().default('').trim().required(msg.REQUIRED)
                    })
                  )
              })
          )
      })
  );

export default yupTransactionInstruments;
