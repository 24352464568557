import * as React from 'react';

import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import ChipDetail from 'src/containers/dashboard/components/chip/ChipDetail';

interface Row {
  unreadMessageCount: number;
}

function MessagesCardDetail({ unreadMessageCount = 0 }: { unreadMessageCount?: number }) {
  const rows: Row[] = [{ unreadMessageCount }];

  const columns: ColumnsModel<Row> = {
    messages: {
      label: 'Messages',
      formatter: ({ row: { unreadMessageCount } }: FormatterInputModel<Row>) => {
        if (unreadMessageCount === 0) {
          return <ChipDetail text="0 Messages" />;
        }

        return <div className="text-[var(--neutral-1000) text-[12px] font-[700] leading-[14px]">{`${unreadMessageCount} Unread Messages`}</div>;
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
    />
  );
}

export default React.memo(MessagesCardDetail);
