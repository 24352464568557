import * as React from 'react';

import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

function Completed() {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Completed
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        <b>All done!</b> Your document(s) are <b>now processed</b> by the Land Registry. Please <b>look on individual document status</b> to understand more.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        Note: The system will automatically archive this workspace in 90 days.
      </Typography>
    </>
  );
}

export default React.memo(Completed);
