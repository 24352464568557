import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      marginTop: 10,
      paddingTop: 28,
      paddingBottom: 30,
      background: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4
    },
    header: {
      paddingTop: 16,
      paddingBottom: 4
    },
    message: {
      fontSize: 14,
      lineHeight: '20px',
      paddingTop: 4,
      textAlign: 'center',
      marginLeft: 100,
      marginRight: 100
    },
    body: {
      marginTop: 24
    }
  }),
  { name: 'HelpPageCard' }
);
