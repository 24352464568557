import * as React from 'react';

import { useField } from 'formik';

import { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import {
  DocumentAttachment,
  DocumentAttachmentItemModel,
  DocumentAttachments as SectionDocumentAttachments
} from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_TRANSFER_DOCUMENT_ATTACHMENT_LABEL_CONFIG } from '../../config';
import { TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS, TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS_WITHOUT_MINISTERSCONSENT } from '../../enums';
import { Transfer2_21_1Model } from '../../models';

interface Props {
  name: string;
}

const MINIMUM_DOCUMENT_ATTACHMENTS = 0;
const fieldName = modelKey<Transfer2_21_1Model>();

export default function DocumentAttachments({ name }: Props) {
  const { disabled } = useDocumentContext();
  const [, { value: attachments }] = useField<DocumentAttachmentItemModel[]>(fieldName('documentAttachments'));
  const hasMinisterConsentAttachmentType = attachments.some(x => x.documentType === AttachmentTypeEnum.MinistersConsent);

  const renderItem = React.useCallback(
    ({ itemBinding, item, handleRemove }: DocumentArrayItemRenderProps<DocumentAttachmentItemModel>) => {
      const isShowingRemoveButton = attachments.length > MINIMUM_DOCUMENT_ATTACHMENTS;
      const canRemove = isShowingRemoveButton && !disabled;
      const attachmentTypes =
        hasMinisterConsentAttachmentType && item.documentType !== AttachmentTypeEnum.MinistersConsent
          ? TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS_WITHOUT_MINISTERSCONSENT
          : TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS;

      return (
        <DocumentAttachment //
          name={itemBinding}
          key={itemBinding}
          handleRemove={handleRemove}
          canRemove={canRemove}
          attachmentDocumentTypes={attachmentTypes}
          uploaderTitle={NSW_TRANSFER_DOCUMENT_ATTACHMENT_LABEL_CONFIG.uploaderTitle}
        />
      );
    },
    [attachments.length, disabled, hasMinisterConsentAttachmentType]
  );

  return (
    <div data-testid={name}>
      <SectionDocumentAttachments //
        name={name}
        minItems={MINIMUM_DOCUMENT_ATTACHMENTS}
        attachmentDocumentTypes={TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS}
        renderItem={renderItem}
        labelConfig={NSW_TRANSFER_DOCUMENT_ATTACHMENT_LABEL_CONFIG}
      />
    </div>
  );
}
