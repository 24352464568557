import React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { CheckboxGroupClassKeys } from '@sympli/ui-framework/components/form/base-components/checkbox-group';

import { filterMortgagees, filterTitleReferences } from '../../helpers';
import { DischargeOfMortgageDocument2_19_1Model, MortgageDetailModel } from '../../models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  name: string;
  disabled: boolean;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function SectionMortgages({ name, focusRef, classes }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const {
    values: { partyBook, dealingNumbersSelectedInOtherDms },
    setValues
  } = useFormikContext<DischargeOfMortgageDocument2_19_1Model>();

  const onChange = React.useCallback(
    e => {
      setValues(updatedValues => {
        const mortgagees = filterMortgagees(updatedValues);
        const titleReferences = filterTitleReferences(updatedValues);

        return {
          ...updatedValues,
          mortgagees,
          titleReferences
        };
      });
    },
    [setValues]
  );

  const resolveItemLabel = React.useCallback(
    (mortgage: MortgageDetailModel) => {
      const mortgagees = mortgage.mortgageeDetails.relinquishingProprietorGroups.flatMap(x => x.parties).map(x => x.partyBookId);
      const isSelectedWarning = mortgage.isSelected && dealingNumbersSelectedInOtherDms?.includes(mortgage.dealingNumber ?? '');
      return (
        <>
          <Typography component="p" className={classes.mortgageItem}>
            Mortgage being discharged:{' '}
            {
              <strong>
                {mortgage.dealingNumber} (
                {partyBook
                  ?.filter(x => mortgagees.includes(x.id))
                  .map(x => x.legalEntityName)
                  .join(', ')}
                )
              </strong>
            }
          </Typography>
          {isSelectedWarning && (
            <Typography component="p" className={classes.warning}>
              This mortgage is part of another Discharge of Mortgage document in this workspace
            </Typography>
          )}
        </>
      );
    },
    [classes.mortgageItem, classes.warning, dealingNumbersSelectedInOtherDms, partyBook]
  );

  const overrideClasses: Partial<ClassNameMap<keyof ReturnType<CheckboxGroupClassKeys>>> = {
    checkboxComponentRoot: classes.checkboxComponentRoot
  };

  return (
    <GenericItemSelection //
      name={name}
      data-testid={name}
      mode="multiple"
      disabled={disabled}
      onChange={onChange}
      focusRef={focusRef}
      resolveItemLabel={resolveItemLabel}
      noDataMessage="No mortgages found."
      overrideClasses={overrideClasses}
    />
  );
}

export default withStyles(styles)(SectionMortgages);
