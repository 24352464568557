import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { REGEX_AU_MOBILE_NUMBER_FORMAT, REGEX_AU_MOBILE_NUMBER_STARTING_PREFIX } from './regex';

const isInternationalFormat = (number: string) => number.charAt(0) === '+';
export const yupMobileNumber = yup
  .string()
  .trim()
  .default('')
  .defined()
  .matches(REGEX_AU_MOBILE_NUMBER_STARTING_PREFIX, 'Must begin with 04 or +614')
  .matches(REGEX_AU_MOBILE_NUMBER_FORMAT, 'Must be numbers only')
  .test(
    'Conditional international/local mobile number length validation',
    // International number excludes "+" character from validation message
    ({ value: mobileNumber }) => msg.LENGTH_MUST_BE_X_NUMBERS(isInternationalFormat(mobileNumber) ? 11 : 10),
    mobileNumber => (isInternationalFormat(mobileNumber!) ? mobileNumber!.length === 12 : mobileNumber!.length === 10)
  );
