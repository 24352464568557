import * as yup from 'yup';

import { LandDescriptionModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { titlesHasSameTenancyStructureAndNumberOfGroups } from '../../helpers';
import { NoticeOfDeath2_21_1Model, TitleReferenceModel } from '../../models';

const MISMATCHED_TENANCY_STRUCTURE_MESSAGE = 'The tenancy of the proprietors does not match; please remove the mismatched titles';
interface NoticeOfDeathContext {
  partyBook: NoticeOfDeath2_21_1Model['partyBook'];
}

const contextResolver = ({ partyBook }: NoticeOfDeath2_21_1Model): NoticeOfDeathContext => ({ partyBook });

// path: titleReferences
const yupTitleReferenceItemSchema = yup
  .object<TitleReferenceModel>({
    isSelected: yup.boolean(),
    reference: yup.string(),
    landDescriptions: yup.mixed<LandDescriptionModel[]>(),
    deceasedJointTenants: yup.mixed()
  })
  .defined();

const yupTitleReferences = resolveTitleReferencesValidationSchema(yupTitleReferenceItemSchema).test(
  //
  'Multiple title selected: check for matching relinquishing proprietor details',
  MISMATCHED_TENANCY_STRUCTURE_MESSAGE,
  function test(this: yup.TestContext<NoticeOfDeathContext>, titleReferences: TitleReferenceModel[]) {
    const selectedTitleReferences = titleReferences.filter(({ isSelected }) => isSelected);
    if (selectedTitleReferences.length <= 1) {
      return true;
    }

    // Check tenancy type per title
    if (!titlesHasSameTenancyStructureAndNumberOfGroups(selectedTitleReferences)) {
      this.createError({ message: MISMATCHED_TENANCY_STRUCTURE_MESSAGE });
      return false;
    }

    return true;
  }
);

export default memoizeSchemaWithContext(yupTitleReferences, contextResolver);
