import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '@sympli/ui-framework/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root() {
      return {
        background: 'none',
        boxShadow: 'none'
      };
    },
    expanded: {}, // keep here to make the above rule working
    // Expansion panel header
    panelSummary: {
      padding: 0,
      minHeight: '0px !important',
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 'bold'
    },
    panelSummaryContent: {
      margin: '0px !important'
    },
    // Expansion panel detail content
    panelDetail: {
      flexDirection: 'column',
      padding: '8px 0px',
      '& > *': {
        flexGrow: 1
      }
    },
    // Expansion icon button
    expandIcon: {
      position: 'absolute',
      right: 0,
      transform: 'translateY(-10px)',

      '&.Mui-expanded': {
        transform: 'translateY(-10px)'
      }
    },
    iconNew: {
      width: 12,
      height: 12,
      fontSize: 16,
      lineHeight: '11px',
      border: `2px solid ${colors.GREY_40}`,
      textAlign: 'center',
      margin: 'auto',
      color: colors.WHITE,
      background: colors.GREY_40
    },
    content: {
      '&$expanded': {
        margin: '0 0'
      }
    }
  }),
  {
    name: 'WorkspaceExpansionBox'
  }
);
