import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchJurisdictionDetail } from '../actions/jurisdictions';
import { JurisdictionDetailApiResponse } from '../jurisdictions/models';

export interface JurisdictionDetailState {
  detail?: JurisdictionDetailApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: JurisdictionDetailState = {
  status: 'idle'
};

const reducer = createReducer<
  //
  JurisdictionDetailState,
  Action
>(initialState)
  .handleAction(actionFetchJurisdictionDetail.request, (state): JurisdictionDetailState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchJurisdictionDetail.success, (state, action): JurisdictionDetailState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchJurisdictionDetail.failure, (state, action): JurisdictionDetailState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
