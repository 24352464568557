import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme/colors';

export const useConfirmationDialogStyles = makeStyles(
  (theme: Theme) => ({
    dialogPaper: {
      width: 360,
      borderRadius: 4,
      background: colors.NEUTRAL_000,
      boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.15)'
    },
    dialogTitle: { padding: '24px' },
    dialogContent: { padding: '0 24px 24px', lineHeight: '20px' },
    dialogActions: { padding: '0 24px 24px' },
    withdrawButton: {
      padding: '8px 16px',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px',
      color: colors.NEUTRAL_000,
      borderRadius: 4,
      backgroundColor: colors.RED_NEW,

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: colors.RED_NEW
      }
    }
  }),
  {
    name: 'ConfirmationDialogStylesOverrides'
  }
);
