import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    fieldset: {
      border: `1px dashed ${theme.palette.divider}`,
      marginLeft: -13,
      marginTop: 12
    },
    fieldLabel: {
      color: theme.palette.text.primary,
      fontSize: 13,
      lineHeight: '15px',
      fontWeight: 'bold'
    },
    checkboxTitle: {
      paddingBottom: 2
    },
    // noBottomBorder: {
    //   borderBottom: 'none'
    // },
    // topBorder: {
    //   borderTop: `1px solid ${theme.palette.divider}`
    // },
    topBorderDashed: {
      borderTop: `1px dashed ${theme.palette.divider}`
    },
    // errorText: {
    //   color: theme.palette.error.main
    // },
    // nestedFormGroupContainer: {
    //   paddingTop: 16,
    //   paddingBottom: 0,
    //   flexDirection: 'column',
    //   '& + &': {
    //     paddingTop: 16
    //   },
    //   '&:nth-child(1)': {
    //     paddingTop: 0
    //   }
    // },
    // nestedFormGroupContent: {
    //   marginTop: 8,
    //   marginLeft: 32,
    //   paddingBottom: 16
    // },
    devHelper: {
      // display: 'none'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
