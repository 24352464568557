import { ParticipantStatusEnum } from '@sympli/api-gateway/enums';

// todo: after we move all to invitations only legit status will be accepted.
export const participantStatusNameMapping: Record<ParticipantStatusEnum, string> = {
  [ParticipantStatusEnum.Pending]: 'Invited',
  [ParticipantStatusEnum.Accepted]: 'Accepted',
  [ParticipantStatusEnum.Rejected]: 'Rejected',
  [ParticipantStatusEnum.InviteForwarded]: 'forwarded',
  [ParticipantStatusEnum.Withdrawn]: 'Withdrawn'
};
