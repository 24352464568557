import * as React from 'react';

import Tenancy, { ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { StateEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../tenancy/config';
import { NSW_LEASE_PARTY_FORM_CONFIG } from '../../config';

interface Props {
  name: string;
  labelConfig: ReceivingTenancyComponentLabelConfig;
}
function Lessees({ name, labelConfig }: Props) {
  return (
    <Tenancy
      //
      name={name}
      jurisdiction={StateEnum.NSW}
      componentLabels={labelConfig}
      partyFormConfig={NSW_LEASE_PARTY_FORM_CONFIG}
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
    />
  );
}

export default React.memo(Lessees);
