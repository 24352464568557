import { defaultMemoize } from 'reselect';

import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum DateRangeTypeEnum {
  AllDates = 'AllDates',
  TodayAndOverdue = 'TodayAndOverdue',
  Overdue = 'Overdue',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'ThisWeek',
  ThisWeekStartsOnMonday = 'ThisWeekStartsOnMonday', // Only for dashboard, Week starts from Monday and ends at Sunday
  NextWeek = 'NextWeek',
  ThisMonth = 'ThisMonth',
  NextMonth = 'NextMonth',
  Custom = 'Custom'
}

export const OVERDUE_DATE_RANGE_TYPE_OPTIONS: LookupEnumModel<DateRangeTypeEnum>[] = [
  { id: DateRangeTypeEnum.AllDates, name: 'All tasks' },
  { id: DateRangeTypeEnum.TodayAndOverdue, name: 'Overdue and due today' },
  { id: DateRangeTypeEnum.Overdue, name: 'Overdue' },
  { id: DateRangeTypeEnum.Today, name: 'Due today' },
  { id: DateRangeTypeEnum.Tomorrow, name: 'Due tomorrow' }
];
export const OVERDUE_WEEK_RANGE_TYPE_OPTIONS: LookupEnumModel<DateRangeTypeEnum>[] = [
  { id: DateRangeTypeEnum.ThisWeek, name: 'Due this week' },
  { id: DateRangeTypeEnum.NextWeek, name: 'Due next week' }
];
export const OVERDUE_MONTH_RANGE_TYPE_OPTIONS: LookupEnumModel<DateRangeTypeEnum>[] = [
  { id: DateRangeTypeEnum.ThisMonth, name: 'Due this month' },
  { id: DateRangeTypeEnum.NextMonth, name: 'Due next month' }
];

export const memoizedDateRangeTypeOptions = defaultMemoize((isDueTomorrow?: boolean) => {
  // Dynamically modify label, due monday if its friday or saturday today.
  if (isDueTomorrow) {
    return OVERDUE_DATE_RANGE_TYPE_OPTIONS;
  }
  return OVERDUE_DATE_RANGE_TYPE_OPTIONS.map(option => {
    if (option.id === DateRangeTypeEnum.Tomorrow) {
      return { ...option, name: 'Due next Monday' };
    }
    return option;
  });
});

export const DATE_RANGE_CUSTOM_OPTIONS: LookupEnumModel<DateRangeTypeEnum>[] = [{ id: DateRangeTypeEnum.Custom, name: 'Choose dates' }];

export interface DateRangeModel {
  from: Date | string | null;
  to: Date | string | null;
  dateRangeType: DateRangeTypeEnum;
}
