import { $eq, $get, $not, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { ConsiderationModel, NonMonetaryConsiderationModel, NoticeOfSaleModel, PropertyModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - consideration.monetaryConsideration

 schema version:
[
  {
    "considerationType": 3
  }
]
*/
export const VISIBILITY_CHECK_CONSIDERATION$MONETARY_CONSIDERATION = createContextCheck((context: ConsiderationModel): boolean =>
  $eq($get(context, 'isMonetaryConsideration'), true)
);

/**
 path:
 - consideration.nonMonetaryConsideration.acquisitionDate

 schema version:
[
  {
    "hideAcquisitionDate": false
  }
]
*/
export const VISIBILITY_CHECK_CONSIDERATION$NON_MONETARY_CONSIDERATION$ACQUISITION_DATE = createContextCheck((context: NonMonetaryConsiderationModel): boolean =>
  $eq($get(context, 'hideAcquisitionDate'), false)
);

/**
 path:
 - consideration.nonMonetaryConsideration

 schema version:
[
  {
    "considerationType": {
      "$not": 3
    }
  }
]
*/
export const VISIBILITY_CHECK_CONSIDERATION$NON_MONETARY_CONSIDERATION = createContextCheck((context: ConsiderationModel): boolean =>
  $eq($get(context, 'isMonetaryConsideration'), false)
);

/**
 path:
 - consideration

 schema version:
[
  {
    "relatedDocuments": {
      "documentIdentifier": {
        "$not": "NationalMortgage"
      }
    }
  }
]
*/
export const VISIBILITY_CHECK_CONSIDERATION = createContextCheck(
  (context: NoticeOfSaleModel): boolean => !context.relatedDocuments?.find(d => d.documentIdentifier === 'NationalMortgage')
);

export const VISIBILITY_CHECK_PROPERTY = createContextCheck((context: NoticeOfSaleModel): boolean => $not($get(context, 'isPropertyHidden'), true));

/**
 path:
 - property.shareTransferred

 schema version:
[
  {
    "isPartLandAffected": true
  }
]
*/
export const VISIBILITY_CHECK_PROPERTY$SHARE_TRANSFERRED = createContextCheck((context: PropertyModel): boolean => $eq($get(context, 'isPartLandAffected'), true));
