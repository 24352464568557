import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    header: {
      height: 'auto'
    },
    description: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      lineHeight: '48px'
    },
    marginBottom: {
      marginBottom: 20
    }
  });

export type ClassKeys = typeof styles;

export default styles;
