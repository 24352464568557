import React from 'react';

import { DebouncedFunc } from 'lodash-es/debounce';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';

import { SearchLinkedWorkspaceUpdatedFeedState } from 'src/containers/shared/app-bar/reducers/searchLinkedWorkspaceUpdated';
import LinkedWorkspaceSearchTable from './LinkedWorkspaceSearchTable';
import { SearchTableModel } from './models';

interface LinkedWorkspaceSearchTableProps {
  searchTerm: string;
  searchesFeed: SearchLinkedWorkspaceUpdatedFeedState;
  fetchSearchBoxWithDebounce: DebouncedFunc<(searchTerm?: string, pageNumber?: number) => void>;
  onRowClick: (rowIndex: number, rowData: SearchTableModel) => void;
  selectedRow?: number;
}

function LinkedWorkspaceSearchTableContainer({ searchesFeed, fetchSearchBoxWithDebounce, searchTerm, selectedRow, onRowClick }: LinkedWorkspaceSearchTableProps) {
  const { isLoading, error, totalCount, newSearchItems: items, pageNumber, totalPages, isRefetching } = searchesFeed;

  if (isLoading) {
    return (
      <div>
        <InlineLoader alignCenter />
      </div>
    );
  }

  if (error || items === undefined) {
    return null;
  }

  const handleOnLoadMoreClick = () => {
    fetchSearchBoxWithDebounce(searchTerm, pageNumber + 1);
  };

  const rows: Array<SearchTableModel> =
    items?.map((x, index) => {
      return {
        sympliId: x.sympliId,
        settlementDateTime: x.settlementDateTime,
        address: x.titleAddresses,
        reference: x.matter,
        jurisdiction: x.jurisdiction,
        titles: x.titles,
        id: x.id,
        workspaceId: x.workspaceId,
        participantId: x.participantId,
        searchHighlights: x.searchHighlights,
        workspaceType: x.workspaceType,
        role: x.participantRole,
        workspaceTransactionType: x.workspaceTransactionType,
        isSelected: index === selectedRow
      };
    }) ?? [];

  return (
    <>
      {searchTerm?.length >= 3 && !isLoading && !isRefetching ? (
        <>
          {items?.length === 0 ? (
            <Box className="mb-[16px] mt-[16px]">
              <Typography variant="body3" className="text-[var(--neutral-600)] normal-case">
                There are <b>no active workspaces</b> that meet your search criteria.
              </Typography>
            </Box>
          ) : (
            <Box className="mb-[8px] mt-[16px]">
              <Typography variant="body3" className="text-[var(--neutral-600)] normal-case">
                <b>{totalCount}</b> {totalCount === 1 ? 'Workspace' : 'Workspaces'} found
              </Typography>
            </Box>
          )}
        </>
      ) : null}
      {totalCount ? (
        <LinkedWorkspaceSearchTable //
          onRowClick={onRowClick}
          isLoading={isLoading}
          pageNumber={pageNumber}
          pageSize={10}
          totalPages={totalPages}
          totalCount={totalCount}
          rows={rows}
          isRefetching={isRefetching}
          onLoadMoreClick={handleOnLoadMoreClick}
        />
      ) : null}
    </>
  );
}

export default React.memo(LinkedWorkspaceSearchTableContainer);
