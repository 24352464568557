import * as yup from 'yup';

import { Lease2_21_1Model, LessorPartyModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import { isLessorNameMismatch } from '../../helpers';

const yupLessors = yup
  .mixed<LessorPartyModel[]>()
  .test(
    'Selected titles must have the same lessors',
    'The selected titles do not share the same Lessors/Registered Proprietor. Please review the selected titles',
    function test(this: yup.TestContext<Lease2_21_1Model>, value: any) {
      const values = this.options.context!;
      return !isLessorNameMismatch(values);
    }
  );
export default yupLessors;
