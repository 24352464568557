import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { AuthorityComplianceReportTypeEnum } from '@sympli/api-gateway/enums';
import { AuthorityComplianceReportApiResponse } from '@sympli/api-gateway/models';

import useStoreSelector from 'src/hooks/useStoreSelector';
import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspaceAuthorityComplianceReport, WorkspaceAuthorityComplianceReportRequest } from '../actions';

export interface WorkspaceAuthorityComplianceReportState {
  detail?: AuthorityComplianceReportApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceAuthorityComplianceReportRequest;
}

const initialState: WorkspaceAuthorityComplianceReportState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useWorkspaceAuthorityComplianceReport(workspaceId: string, participantId: string, authorityMessageId?: string, messageType?: AuthorityComplianceReportTypeEnum) {
  const state = useStoreSelector(store => store.workspaceAuthorityComplianceReport);
  if (
    state.args?.workspaceId === workspaceId &&
    state.args?.participantId === participantId &&
    state.args?.authorityMessageId === authorityMessageId &&
    state.args?.messageType === messageType
  ) {
    return state;
  }
  return initialState;
}

const WorkspaceAuthorityComplianceReportReducer = createReducer<
  //
  WorkspaceAuthorityComplianceReportState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceAuthorityComplianceReport.request, (state, action): WorkspaceAuthorityComplianceReportState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchWorkspaceAuthorityComplianceReport.success, (state, action): WorkspaceAuthorityComplianceReportState => {
    return {
      //
      ...state,
      detail: action.payload.data,
      status: 'resolved',
      isLoading: false,
      isRefetching: false
    };
  })
  .handleAction(actionFetchWorkspaceAuthorityComplianceReport.failure, (state, action): WorkspaceAuthorityComplianceReportState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  });

export default WorkspaceAuthorityComplianceReportReducer;
