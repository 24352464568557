import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Markdown, { MarkdownToJSX } from '@sympli/markdown-to-jsx';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import { FormatterInputModel } from '@sympli/ui-framework/components/table';
import { NewRadioIcon, NewRadioIconSelected } from '@sympli/ui-framework/icons';

import { threeLineSettlementDatetimeDisplay } from 'src/containers/dashboard/shared/formatters';
import WithPlusN from 'src/containers/dashboard/shared/formatters/WithPlusN';
import { useContentTruncated } from 'src/hooks/useContentTruncated';
import { colors } from 'src/theme';
import { LinkedWorkspaceResultItem } from '../../models';
import { ColumnKeyName, FormattersProps, SearchTableModel } from './models';

const REGEX_HIGHLIGHT = /\*\*(.*?)\*\*/g; // regexp to match highlighted parts of the text

export const renderSuggestionContent = (
  what: ColumnKeyName, //keyof ResultItemModel['searchHighlights'],
  {
    //
    reference,
    sympliId,
    titles = [],
    address = [],
    jurisdiction,
    searchHighlights
  }: SearchTableModel
) => {
  if (!searchHighlights) {
    return null;
  }
  switch (what) {
    case 'reference':
      return 'matter' in searchHighlights ? convertMd(searchHighlights['matter']![0]) : reference;
    case 'sympliId':
      return 'sympliId' in searchHighlights ? convertMd(searchHighlights['sympliId']![0]) : sympliId;
    case 'address':
      return DisplayItems(address, searchHighlights, 'multiple addresses found', 'titleAddresses');
    case 'jurisdiction':
      return (
        <Typography variant={'body2'} className="pl-[4px]">
          {jurisdiction}
        </Typography>
      );
    case 'titles':
      return DisplayItems(titles, searchHighlights, 'multiple titles found', 'titleReferences');
    default:
      return null;
  }
};

const mdOptions: MarkdownToJSX.Options = {
  disableParsingRawHTML: true,
  // restrict markdown parser to only recognize bold text
  whitelistRules: ['textBolded']
};

function convertMd(mdText: string) {
  return <Markdown options={mdOptions}>{mdText}</Markdown>;
}

function DisplayItems(items: string[] = [], searchHighlights: LinkedWorkspaceResultItem['searchHighlights'], tooltipLabel: string, searchKey: string) {
  const { refContainerRef, isContentTruncated } = useContentTruncated([items]);

  if (!items.length) {
    return null;
  }

  const hasHighlights = searchKey in searchHighlights;

  const highlightItems: string[] = hasHighlights ? searchHighlights[searchKey]!.map(address => address.replace(REGEX_HIGHLIGHT, '$1')) : [];
  const notHighlightItems: string[] = items?.filter(address => !highlightItems.some(ha => ha === address));
  const unitedItems: string[] = hasHighlights ? searchHighlights[searchKey]!.concat(notHighlightItems) : items;

  const firstItem = convertMd(unitedItems[0]);

  return (
    <div className="flex whitespace-nowrap" ref={refContainerRef}>
      {isContentTruncated && (
        <Tooltip
          title={
            <Typography variant="body2" className="text-[white]">
              {firstItem}
            </Typography>
          }
          placement="top"
        >
          <Typography className="truncate" variant="body2">
            {firstItem}
          </Typography>
        </Tooltip>
      )}
      {!isContentTruncated && <Typography variant="body2">{firstItem}</Typography>}
      {unitedItems.length > 1 && (
        <>
          {','}
          <Tooltip title={renderHintInformation(unitedItems, tooltipLabel)} placement="top">
            <Typography variant={hasHighlights ? 'body2_bold' : 'body2'} className="pl-[4px] underline">
              +{unitedItems.length - 1}
            </Typography>
          </Tooltip>
        </>
      )}
    </div>
  );
}

function renderHintInformation(items: string[], tooltipLabel: string) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" className="mb-[16px] uppercase text-[white]">
        {tooltipLabel}
      </Typography>
      {items.map((item, idx) => {
        return (
          <Typography variant="body2" className="text-[white]" key={idx}>
            {convertMd(item)}
          </Typography>
        );
      })}
    </Box>
  );
}

export const FormatReferenceColumn = ({ row }: FormattersProps) => {
  const { refContainerRef, isContentTruncated } = useContentTruncated(row.reference);

  return (
    <div ref={refContainerRef} className="whitespace-nowrap">
      {isContentTruncated && (
        <Tooltip
          title={
            <Typography variant="body2" sx={{ color: colors.WHITE }}>
              {row.reference}
            </Typography>
          }
          placement="top"
        >
          <div className="truncate">{renderSuggestionContent('reference', row)}</div>
        </Tooltip>
      )}
      {!isContentTruncated && renderSuggestionContent('reference', row)}
      <div className="secondary truncated">{row.sympliId}</div>
    </div>
  );
};

export const FormatPlusNColumn = ({ row, columnName }: FormattersProps) => {
  return <div>{renderSuggestionContent(columnName, row)}</div>;
};

export const FormatParticipantRoleColumn = ({ row, columnName }: FormattersProps) => {
  return <div>{row.role}</div>;
};

export const FormatSettlementDatetimeColumn = ({ row }: FormattersProps) => {
  return threeLineSettlementDatetimeDisplay(row.settlementDateTime);
};

export function titleFormatter<
  //
  R extends object,
  V extends {
    titles: string[];
    sympliId: string;
  },
  C = any
>({ value, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const { titles } = value;

  const primary: string = titles.length ? titles[0] : 'No Title';

  return (
    <WithPlusN //
      primary={primary}
      primaryClassName="primary bold"
      primaryDataBinding="title"
      plusN={Math.max(titles.length - 1, 0)}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function radioSelectFormatter<
  //
  R extends object,
  V extends {
    isSelected?: boolean;
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  const { isSelected } = value;

  if (isSelected) {
    return <NewRadioIconSelected />;
  }

  return <NewRadioIcon />;
}
