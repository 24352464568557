import { Action, createReducer } from 'typesafe-actions';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchUserJurisdictions } from '../actions';

export type UserJurisdictionsAPIResponse = LookupEnumModel<JurisdictionsEnum>[];

export interface UserJurisdictionsState {
  items: UserJurisdictionsAPIResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: UserJurisdictionsState = {
  items: [],
  status: 'idle',
  error: undefined
};

export function useUserJurisdictions() {
  const state = useStoreSelector(store => store.userJurisdictions);
  return state;
}

const userJurisdictionsReducer = createReducer<
  //
  UserJurisdictionsState,
  Action
>(initialState)
  .handleAction(actionFetchUserJurisdictions.request, (state): UserJurisdictionsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchUserJurisdictions.success, (state, action): UserJurisdictionsState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchUserJurisdictions.failure, (state, action): UserJurisdictionsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default userJurisdictionsReducer;
