import React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import NoParticipantsSvg from './NoParticipantsSvg';

function NoParticipants() {
  return (
    <FlexLayout fullHeight flexDirection="column" alignItems="center" justifyContent="center" className="mt-[-30px]">
      <NoParticipantsSvg className="rounded-[60px]" />
      <Typography variant="subtitle1" className="pb-[8px] pt-[16px] text-center text-[18px] font-bold leading-[24px] text-[var(--greek-waters-dark)]">
        No participants
      </Typography>
      <Typography className="w-[210px] text-center text-[14px] font-normal leading-[20px]">
        There are currently no other participants in this workspace to send a message to.{' '}
      </Typography>
    </FlexLayout>
  );
}

export default React.memo(NoParticipants);
