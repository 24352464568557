import React, { useCallback } from 'react';

import { Form, FormikProps } from 'formik';

import { JurisdictionsEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconDoorOpen } from '@sympli/ui-framework/icons';

import Formik from 'src/components/formik';
import { modelKey } from 'src/utils/formUtils';
import { useStyles } from '../abandon-lodgement-only-dialog/styles';
import { getInitialValues } from './helpers';
import { WITHDRAW_PARTICIPANT_REASON_OPTIONS, WithdrawParticipantApiModel, WithdrawParticipantFormikModel } from './model';
import { getValidationSchema } from './validationSchema';
import { WITHDRAW_DESCRIPTION_VISIBILITY_CHECK } from './visibilityCheck';

export const EXPECTED_WITHDRAW_IN_VIC_STRING =
  'If you have already assessed the stamp duty, the system will automatically trigger a cancel duty assessment once you withdraw from the workspace.';
export const EXPECTED_ABANDON_IN_VIC_STRING =
  'If you have already assessed the stamp duty, the system will automatically trigger a cancel duty assessment once you abandon the workspace.';
export interface WithdrawFinancialParticipantDialogProps {
  // route params
  workspaceId: string;
  participantId: string;
  // redux data
  jurisdictionId?: JurisdictionsEnum;
  workspaceRoleId?: WorkspaceRoleEnum;
  // dialog
  open: boolean;
  onClose(): void;
  // other
  isWithdraw?: boolean;
  canWithdrawLinkedWorkspace?: boolean;
  onPreSubmit(values: WithdrawParticipantFormikModel): WithdrawParticipantApiModel;
  onPostSubmit(args: FormikPostSubmitArgs<WithdrawParticipantFormikModel>): void;
}

const fieldName = modelKey<WithdrawParticipantFormikModel>();

function WithdrawFinancialParticipantDialog({
  // route params
  workspaceId,
  participantId,
  // redux data
  jurisdictionId,
  workspaceRoleId,
  // dialog
  open,
  onClose,
  // other
  isWithdraw,
  canWithdrawLinkedWorkspace,
  onPreSubmit,
  onPostSubmit
}: WithdrawFinancialParticipantDialogProps): JSX.Element {
  const classes = useStyles();

  const renderTitle = () => {
    return (
      <FlexLayout alignItems="center">
        <IconDoorOpen className={classes.headingIcon} />
        <span className={classes.headingText}>{isWithdraw ? 'Withdraw from workspace' : 'Abandon workspace'}</span>
      </FlexLayout>
    );
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<WithdrawParticipantFormikModel>) => {
      const { dirty, isValid, isSubmitting, values } = formikProps;
      const disabled = !dirty || !isValid || isSubmitting;
      return (
        <Form aria-label={`${isWithdraw ? 'Withdraw' : 'Abandon'} workspace form`}>
          <Field //
            name={fieldName('reasonOption')}
            component={SelectField}
            format="number"
            options={WITHDRAW_PARTICIPANT_REASON_OPTIONS}
            placeholder="Select reason"
            aria-label={`${isWithdraw ? 'Withdraw' : 'Abandon'} reason`}
          />
          {WITHDRAW_DESCRIPTION_VISIBILITY_CHECK(values) && (
            <Field //
              name={fieldName('reasonDescription')}
              component={InputField}
              placeholder="Enter reason"
              aria-label={`${isWithdraw ? 'Withdraw' : 'Abandon'} reason description`}
            />
          )}
          {canWithdrawLinkedWorkspace && (
            <Field //
              name={fieldName('isApplyToLinkedWorkspace')}
              component={CheckboxField}
              label="Abandon linked workspace"
            />
          )}
          <WizardStepper //
            disabled={disabled}
            isLoading={isSubmitting}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel={isWithdraw ? 'Withdraw' : 'Abandon'}
            color="secondary"
          />
        </Form>
      );
    },
    [isWithdraw, canWithdrawLinkedWorkspace, onClose]
  );

  const renderOneMoreLineForVICJurisdiction = () => {
    const isVicJurisdiction = Number.isInteger(jurisdictionId) && jurisdictionId === JurisdictionsEnum.VIC;
    const isPurchaser = Number.isInteger(workspaceRoleId) && workspaceRoleId === WorkspaceRoleEnum.Purchaser;

    if (isPurchaser) {
      return isVicJurisdiction ? <p>{isWithdraw ? EXPECTED_WITHDRAW_IN_VIC_STRING : EXPECTED_ABANDON_IN_VIC_STRING}</p> : null;
    }
  };

  return (
    <ConfirmationDialog //
      open={open}
      onClose={onClose}
      title={renderTitle()}
      showActionButtons={false}
      classes={{ dialogContent: classes.dialogContent }}
    >
      <p>You will not be able to access data entered in this workspace.</p>
      {renderOneMoreLineForVICJurisdiction()}
      <p>Please select your reason for {isWithdraw ? 'withdrawing from' : 'abandoning'} this workspace:</p>
      <Formik //
        method="post"
        action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/archive-participant`}
        initialValues={getInitialValues(canWithdrawLinkedWorkspace)}
        validationSchema={getValidationSchema()}
        onPreSubmit={onPreSubmit}
        onPostSubmit={onPostSubmit}
      >
        {(formikProps: FormikProps<WithdrawParticipantFormikModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}

export default React.memo(WithdrawFinancialParticipantDialog);
