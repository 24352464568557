import * as React from 'react';

import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      height: 'calc(100% - 4px)',
      marginTop: 4,
      flexShrink: 0,

      '&.ok': {
        background: colors.GREEK_WATERS
      },
      '&.warning': {
        background: colors.SUNNY_DAY
      },
      '&.error': {
        background: colors.WATERMELON
      },
      '&.neutral': {
        background: colors.NEUTRAL_300
      }
    }
  }),
  {
    name: 'FullVerticalStatus'
  }
);

interface FullVerticalStatusProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  variant: 'ok' | 'warning' | 'error' | 'neutral';
}

function FullVerticalStatus({ className, variant, ...rest }: FullVerticalStatusProps) {
  const classes = useStyles();

  return (
    <div
      //
      {...rest}
      className={classNames(classes.root, variant, className)}
    />
  );
}

export default FullVerticalStatus;
