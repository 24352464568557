import * as React from 'react';

import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import Table, { ColumnsModel } from '@sympli/ui-framework/components/table';
import { IconStar } from '@sympli/ui-framework/icons';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchReportsDashboard } from 'src/containers/dashboard/reports/actions';
import { useDeleteDialog } from 'src/containers/dashboard/reports/hooks/useDeleteDialog';
import { useFavouriteDialog } from 'src/containers/dashboard/reports/hooks/useFavouriteDialog';
import { FavouriteFormikModel, SavedReportModel } from 'src/containers/dashboard/reports/models';
import { useSafeDispatch } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import FavouriteReportDialog from '../../../report-details/components/favourite-report-dialog';
import ReportSectionWrapper, { ReportSectionHeader } from '../report-section-wrapper';
import ReportDeleteDialog from './components/report-delete-dialog';
import { formatFavouriteReportEditDeleteColumn, formatReportNameColumn, formatReportTypeColumn } from './formatters';
import { useStyles } from './styles';

export interface Props {
  reports: Array<SavedReportModel>;
  savedReportsStatus: ApiStatus;
}

type FormModel = FavouriteFormikModel;

function ReportFavouriteTable({ reports, savedReportsStatus }: Props) {
  const { deleteReportData, isDeleteDialogOpen, isDeleteDialogLoading, rowClickHandler, deleteDialogClickHandler, deleteDialogCloseHandler } = useDeleteDialog();
  const {
    initialValues: favouriteInitialVaues,
    isFavouriteDialogOpen,
    favouriteDialogCloseHandler,
    favouriteDialogClickHandler,
    asyncDialogFavouriteDispatch
  } = useFavouriteDialog();
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());

  const columns: ColumnsModel<SavedReportModel> = {
    name: {
      label: 'Report name',
      className: classes.reportName,
      formatter: formatReportNameColumn
    },
    reportType: {
      label: 'Report type',
      formatter: formatReportTypeColumn,
      className: classes.reportTypes
    },
    buttons: {
      label: '',
      formatter: formatFavouriteReportEditDeleteColumn,
      className: classes.buttons
    }
  };

  const handleOnPostSubmit = (args: FormikPostSubmitArgs<FormModel>) => {
    if (args.error) {
      return;
    }
    // close dialog
    asyncDialogFavouriteDispatch({ type: 'close' });

    dispatch(
      actionOpenGlobalSimpleNotification({
        //
        message: 'Report name has been updated',
        autoHideDuration: 3000,
        variant: 'new-success'
      })
    );
    dispatch(actionFetchReportsDashboard.request());
  };

  const renderTable = () => {
    if (savedReportsStatus === 'pending' || savedReportsStatus === 'idle') return <BlockLoader />;

    return reports?.length ? (
      <Table //
        pagination={false}
        sortable={false}
        rows={reports}
        columns={columns}
        bordersWithSpacing={true}
        context={{ onDeleteClick: deleteDialogClickHandler, onEditClick: favouriteDialogClickHandler }}
        onRowClick={rowClickHandler}
        classes={{ root: classes.reportsSectionRoot }}
      />
    ) : (
      <Typography className={classes.emptyReportList}>Select from any of the reports above to save it as a favourite.</Typography>
    );
  };

  return (
    <>
      <ReportSectionWrapper //
        title={
          <ReportSectionHeader className={classes.sectionHeader}>
            <IconStar className={classes.sectionIcon} /> Favourite reports
          </ReportSectionHeader>
        }
        className={classes.titleWrapper}
      >
        {renderTable()}
      </ReportSectionWrapper>
      <ReportDeleteDialog //
        isLoading={isDeleteDialogLoading}
        isOpen={isDeleteDialogOpen}
        onClose={deleteDialogCloseHandler}
        reportFeatureType={deleteReportData.reportFeatureType}
        reportName={deleteReportData.reportName}
      />
      <FavouriteReportDialog
        initialValues={favouriteInitialVaues!}
        isOpen={isFavouriteDialogOpen}
        onClose={favouriteDialogCloseHandler}
        maxNameLength={50}
        handleOnPostSubmit={handleOnPostSubmit}
      />
    </>
  );
}

export default React.memo(ReportFavouriteTable);
