import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import AuthenticationDialog from './AuthenticationDialog';

function AuthenticationDialogContainer() {
  const dispatch = useSafeDispatch(useDispatch());

  const { mfaId, required: showDialog } = useStoreSelector(store => store.authenticationDialog);

  return (
    <AuthenticationDialog //
      dispatch={dispatch}
      showDialog={showDialog}
      mfaId={mfaId}
    />
  );
}

export default React.memo(AuthenticationDialogContainer);
