import { ProgressStep } from 'src/components/workspace-status-rebuild/status-progress-with-breadcrumb/models';

export class LodgementCaseStatusSteps {
  static InPreparation: ProgressStep = {
    value: 1,
    description: 'In Preparation'
  };

  static Prepared: ProgressStep = {
    value: 2,
    description: 'Prepared'
  };

  static Ready: ProgressStep = {
    value: 3,
    description: 'Ready'
  };

  static Lodging: ProgressStep = {
    value: 4,
    description: 'Lodging'
  };

  static Lodged: ProgressStep = {
    value: 5,
    description: 'Lodged'
  };

  static Completed: ProgressStep = {
    value: 6,
    description: 'Completed'
  };

  static All: ProgressStep[] = [this.InPreparation, this.Prepared, this.Ready, this.Lodging, this.Lodged, this.Completed];
  static LodgementCaseStatuses: string[] = [
    this.InPreparation.description,
    this.Prepared.description,
    this.Ready.description,
    this.Lodging.description,
    this.Lodged.description,
    this.Completed.description
  ];
}
