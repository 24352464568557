import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { ExtensionOfPriorityNoticeModel } from './models';
import PriorityNotices from './sections/priority-notices';
import { useStyles } from './styles';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<ExtensionOfPriorityNoticeModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<ExtensionOfPriorityNoticeModel>();
  const classes = useStyles();

  useEffect(() => {
    // TODO: Add logic here to focus on first field after page load.
  }, []);

  return (
    <>
      <Form className={className}>
        <Section
          //
          title="Priority notices"
          error={getFormikError(formikProps, fieldName('priorityNotices'))}
          data-name={fieldName('priorityNotices')}
        >
          <PriorityNotices name={fieldName('priorityNotices')} className={classes.priorityNotices} />
        </Section>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
