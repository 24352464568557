import React, { useCallback, useState } from 'react';

import classnames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';

import DocumentFieldArray, {
  DocumentAddButtonRenderProps,
  DocumentArrayItemRenderProps,
  RenderedItemWithOverrideConfigs
} from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';

import { filterSelectedPartySiblingsFromPartyBook } from '../../helpers';
import { BasePriorityNoticeModel, PARTY_BOOK_KEY, PartyBookIdentifierModel } from '../../models';
import useStyles from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface ReceivingPartiesProps {
  name: string;
  className?: string;
  dialogPortalId: string;
  receivingParties?: PartyBookIdentifierModel[];
  documentType?: string | null;
  partyFormConfig: PartyFormConfig;
  receivingTitlePartyLabelMapping?: Record<string, string>;
}

function ReceivingParties<T extends PartLandAffectedModel>(props: ReceivingPartiesProps) {
  const { name, dialogPortalId, receivingParties, documentType, partyFormConfig, receivingTitlePartyLabelMapping } = props;
  const [isAddClicked, setIsAddClicked] = useState(false);

  const classes = useStyles();

  const { values, errors, touched } = useFormikContext<BasePriorityNoticeModel<T>>();
  const { nextActionDisabled } = useDocumentContext();
  const partyOptions = values[PARTY_BOOK_KEY];

  //To display errors
  const receivingPartiesError = getIn(errors, name);
  const receivingPartiesTouched = getIn(touched, name);
  const displayReceivingPartiesError = typeof receivingPartiesError === 'string' && receivingPartiesTouched;

  const createNewItem = (): PartyBookIdentifierModel => {
    setIsAddClicked(true);
    return {
      partyBookId: null
    };
  };

  const resolveReceivingPartyTitle = useCallback(
    (): string =>
      //
      (documentType && receivingTitlePartyLabelMapping && receivingTitlePartyLabelMapping[documentType]) || 'Receiving parties',
    [documentType, receivingTitlePartyLabelMapping]
  );

  const resolveAddButtonTitle = (receivingParties: PartyBookIdentifierModel[] | undefined) => {
    if (!receivingParties) {
      return undefined;
    }

    /**
     * Checking length === 0 is not enough here since when we add a new receiving party
     * there is a new item of partybookId = null added to the list causing the title to change WHILE the modal
     * is opening which looks glitchy
     */
    return receivingParties!.length === 0 || receivingParties!.every(p => p.partyBookId === null) //
      ? 'Add a receiving party'
      : 'Add another receiving party';
  };

  const renderItem = useCallback(
    ({ item, itemBinding, handleRemove }: DocumentArrayItemRenderProps<PartyBookIdentifierModel>): RenderedItemWithOverrideConfigs<PartyBookIdentifierModel> => {
      const fieldName = createModelKeyAppender<PartyBookIdentifierModel>(itemBinding);
      /**
       * isAddNewItem indicates the user clicked on "Add receiving party" and a dialog needs to be displayed
       * Without this logic, add party dialog will open for every item with partyBookId = null in receivingParties when drop down value is changed
       */
      const isAddNewItem =
        isAddClicked === true && // the user clicked on Add receiving party"
        receivingParties &&
        receivingParties[receivingParties.length - 1] === item; // the current item being rendered is the last (new item) hence display add party dialog

      if (isAddNewItem) {
        // Consider current click as processed by setting it to false
        // prepare component for next add click by user
        setIsAddClicked(false);
      }

      return {
        configs: { itemStyle: isAddNewItem ? 'none' : 'formGroup' },
        Item: (
          <PartySelectField
            key={itemBinding}
            name={fieldName('partyBookId')}
            partyFormConfig={partyFormConfig}
            disabled={nextActionDisabled}
            bookRef={PARTY_BOOK_KEY}
            dialogPortalId={dialogPortalId}
            skipToDirectAdd={isAddNewItem}
            handleRemove={() => {
              if (isAddNewItem) handleRemove();
            }}
            className={classes.fullWidth}
            optionsOverride={filterSelectedPartySiblingsFromPartyBook(partyOptions, item, receivingParties, partyFormConfig)}
          />
        )
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partyOptions, receivingParties, partyFormConfig, nextActionDisabled, dialogPortalId, classes.fullWidth]
  );

  const renderAddButton = React.useCallback(
    ({ addButtonFocusRef, onAdd }: DocumentAddButtonRenderProps) => {
      return (
        <FormGroupFooter //
          icon="add"
          onClick={e => {
            setIsAddClicked(true);
            onAdd(e);
          }}
          buttonActions={addButtonFocusRef}
          title={resolveAddButtonTitle(receivingParties)}
          className={classnames(
            //
            classes.addReceivingParty,
            displayReceivingPartiesError && classes.footerError
          )}
        />
      );
    },
    [receivingParties, classes.addReceivingParty, displayReceivingPartiesError, classes.footerError]
  );

  return (
    <Box sx={{ mb: '14px' }} data-name={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        disabled={nextActionDisabled}
        itemStyle="formGroup"
        minItems={1}
        maxItems={20}
        createNewItem={createNewItem}
        arrayTitle={resolveReceivingPartyTitle()}
        renderAddButton={renderAddButton}
        canRemoveAllItems={Boolean(partyOptions.length)}
        hideAddButtonOnDisable={false}
        classes={{
          //
          arrayTitle: classes.receivingPartyTitle,
          formGroup: classes.noBordersAndPadding,
          formGroupTitle: classes.titleContainer,
          formGroupIconPrefix: classes.iconButton,
          formGroupFooter: classnames(
            //
            classes.addReceivingParty,
            displayReceivingPartiesError && classes.footerError
          )
        }}
      />
      {displayReceivingPartiesError && (
        <FormHelperText role="alert" error sx={{ mt: 0 }} data-error-name={name}>
          {receivingPartiesError}
        </FormHelperText>
      )}
    </Box>
  );
}

export default ReceivingParties;
