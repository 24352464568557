import * as React from 'react';

import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';

import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';

import { actionCreateGlobalErrorMessage, GlobalErrorMessageModel } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { SimpleSnackbarNotificationProps } from 'src/components/global-simple-notification/simple-snackbar-notification/SimpleSnackbarNotification';

export default function useDetailPostSubmitHandler<T extends FormikValues, R extends unknown = {}>(
  messagePrefix?: string,
  onPostSubmit?: (args: FormikPostSubmitArgs<T, R>) => void,
  simpleSnackbarProps?: Partial<Omit<SimpleSnackbarNotificationProps, 'message'>>
): (args: FormikPostSubmitArgs<T, R>) => void {
  const dispatch = useDispatch();

  const handleOnPostSubmit = React.useCallback(
    (args: FormikPostSubmitArgs<T, R>) => {
      if (args.error) {
        const globalError = args.error as GlobalErrorMessageModel;
        if (globalError.response?.status === 418) {
          //ignore MFA required http code
          return;
        }

        dispatch(actionCreateGlobalErrorMessage({ ...globalError }));
      } else {
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            ...simpleSnackbarProps,
            message: messagePrefix ? `${messagePrefix} updated successfully` : 'Updated successfully',
            variant: 'new-success'
          })
        );
        onPostSubmit && onPostSubmit(args);
      }
    },
    [messagePrefix, simpleSnackbarProps, dispatch, onPostSubmit]
  );

  return handleOnPostSubmit;
}
