import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import { resolveUuid } from 'src/utils/formUtils';
import { DEFAULT_INVITATION_ITEM_MODEL } from '../../../../initialValues';
import { InvitationModel } from '../../../../models';
import { InvitationItemModel } from '../../models';

export function getDefaultAdditionalInvitation(currentParticipantGroupId: string, currentParticipantReference: string): InvitationModel {
  return { invitation: getDefaultInvitation(currentParticipantGroupId, currentParticipantReference), id: resolveUuid('number', 20) };
}

function getDefaultInvitation(currentParticipantGroupId: string, currentParticipantReference: string): InvitationItemModel {
  return { ...DEFAULT_INVITATION_ITEM_MODEL, groupId: currentParticipantGroupId, matterNumber: currentParticipantReference };
}

export const filterAllowedAdditionalRoles = (roles: WorkspaceRoleEnum[]) => roles.filter(role => NON_ADDITIONAL_INVITATION_ROLES.every(x => role !== x));

const NON_ADDITIONAL_INVITATION_ROLES: WorkspaceRoleEnum[] = [WorkspaceRoleEnum.Purchaser, WorkspaceRoleEnum.Vendor, WorkspaceRoleEnum.Beneficiary];

const COMPATIBLE_CHECK_ROLES: WorkspaceRoleEnum[] = [WorkspaceRoleEnum.Beneficiary];

export const isSupportCompatibleRolesCheck = (role: WorkspaceRoleEnum) => COMPATIBLE_CHECK_ROLES.includes(role);
