import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-18/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-18/vic';

// Define any form-specific configuration in this file

export const VIC_TOC_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...VIC_PARTY_FORM_CONFIG,
  fixedPartyType: PartyTypeEnum.Organisation
};
