import { SubscriberBankAccountTypeEnum } from '@sympli/api-gateway/enums';

import { EditBankDetailsProps } from './EditBankDetails';

export const getLoanAccountOptions = (financialAccounts: EditBankDetailsProps['financialAccounts']) => {
  if (!financialAccounts) return [];
  return financialAccounts
    .filter(item => item.accountType.value === SubscriberBankAccountTypeEnum.Loan)
    .map(item => ({
      id: item.id,
      name: `${item.accountName} | BSB ${item.bsbNumber} | Acc ${item.accountNumber}`
    }));
};
