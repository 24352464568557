import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-23/components/party-justification';
import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { PartyModel, RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { arraysIntersectionsBy, mergeArrays } from '@sympli-mfe/document-forms-framework/utils';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { RelinquishingTenancyPartyModel } from '../../models';
import { BaseTitleReferenceModel, DischargeOfMortgageDocument2_23_0Model, MortgageDetailModel } from './models';

export const getSelectedMortgages = defaultMemoize((mortgages: MortgageDetailModel[]) => mortgages.filter(r => r.isSelected));
export const filterMortgagees = <T extends Pick<DischargeOfMortgageDocument2_23_0Model, 'mortgages'>>(values: T): RelinquishingTenancyPartyModel[] => {
  const result = mergeArrays(
    getSelectedMortgages(values.mortgages)
      .map(x => x.mortgageeDetails)
      .flatMap(x => x.relinquishingProprietorGroups)
      .map(x => x.parties),
    'partyBookId'
  );
  return result;
};

export const filterTitleReferences = <T extends Pick<DischargeOfMortgageDocument2_23_0Model, 'mortgages'>>(values: T): BaseTitleReferenceModel[] => {
  const result = arraysIntersectionsBy(
    getSelectedMortgages(values.mortgages).map(x => x.titleReferences),
    'reference'
  );
  return result;
};

const getMortgageesLinkedToSelectedMortgages = defaultMemoize((partyBook: PartyModel<WaNameChange>[], mortgages: MortgageDetailModel[]) => {
  const mortgageePartyBookIds = getMortgageePartyBookIds(mortgages);
  return partyBook.filter(party => mortgageePartyBookIds.includes(party.id));
});

export const getMortgageePartyBookIds = defaultMemoize((mortgages: MortgageDetailModel[]) => {
  const mortgageePartyBookIds = mortgages!
    .map(x => x.mortgageeDetails)
    .flatMap(x => x.relinquishingProprietorGroups)
    .flatMap(x => x.parties)
    .map(x => x.partyBookId)
    .flat();
  return _uniq(mortgageePartyBookIds);
});

export const isMortgageeNameMismatch = (partyBook: PartyModel<WaNameChange>[], mortgages: MortgageDetailModel[]): boolean => {
  const selectedMortgages = getSelectedMortgages(mortgages);
  if (selectedMortgages.length <= 1) return true;

  const partyDetails = getMortgageesLinkedToSelectedMortgages(partyBook, selectedMortgages);

  const uniqueNamesCount =
    _uniq(
      partyDetails.map(mortgagee => {
        const relinquishingDetails = mortgagee.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
        if (relinquishingDetails.isChangingName) {
          const { organisationName, isSingleName, firstName, lastName } = relinquishingDetails.nameChange!;
          const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
          return justifiedName;
        } else {
          return mortgagee.legalEntityName?.toLowerCase();
        }
      })
    ).length ?? 0;

  const mortgageesCount = selectedMortgages[0].mortgageeDetails.relinquishingProprietorGroups.flatMap(x => x.parties).length;

  // Check if a justification is needed
  return uniqueNamesCount <= mortgageesCount;
};

const getMortgageesWithNameChange = defaultMemoize((mortgageesLinkedToSelectedMortgages: PartyModel<WaNameChange>[]) => {
  return mortgageesLinkedToSelectedMortgages.filter(mortgagee => {
    const relinquishingDetails = mortgagee.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
    return relinquishingDetails.isChangingName;
  });
});

const getPartyName = (organisationName: string, isSingleName: boolean, firstName: string, lastName = ''): string => {
  if (organisationName) {
    return organisationName.trim();
  } else {
    return isSingleName ? firstName.trim() : `${firstName.trim()} ${lastName.trim()}`;
  }
};

export const checkIfJustifiedPartiesAreTheSame = defaultMemoize((partyBook: PartyModel<WaNameChange>[], mortgages: MortgageDetailModel[]): boolean => {
  const selectedMortgages = getSelectedMortgages(mortgages);
  if (selectedMortgages.length === 0) return false;

  return selectedMortgages.some(selectedMortgage => {
    const mortgageesLinkedToSelectedMortgages = getMortgageesLinkedToSelectedMortgages(partyBook, [selectedMortgage]);

    const mortgageesWithNameChange = getMortgageesWithNameChange(mortgageesLinkedToSelectedMortgages);
    const justifications = mortgageesWithNameChange.map(mortgagor => mortgagor.receivingOrRelinquishingDetails) as RelinquishingModel<WaNameChange>[];
    let justifiedMortgageesNames: string[] = [];

    // Check if more than one mortgage name change is the same
    return justifications.some(justification => {
      const { organisationName, isSingleName, firstName, lastName } = justification.nameChange!;
      const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
      if (justifiedMortgageesNames.find(x => x === justifiedName)) {
        return true;
      }
      justifiedMortgageesNames.push(justifiedName);
      return false;
    });
  });
});

const getMortgageesWithoutNameChange = defaultMemoize((mortgageesLinkedToSelectedMortgages: PartyModel<WaNameChange>[]) => {
  return mortgageesLinkedToSelectedMortgages.filter(mortgagee => {
    const relinquishingDetails = mortgagee.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
    return !relinquishingDetails.isChangingName;
  });
});

export const checkIfMergedPartiesHaveMismatchingPartyType = defaultMemoize((partyBook: PartyModel<WaNameChange>[], mortgages: MortgageDetailModel[]): number | null => {
  const selectedMortgages = getSelectedMortgages(mortgages);
  if (selectedMortgages.length === 0) return null;

  const mortgageesLinkedToSelectedMortgages = getMortgageesLinkedToSelectedMortgages(partyBook, selectedMortgages);

  const mortgageesWithNameChange = getMortgageesWithNameChange(mortgageesLinkedToSelectedMortgages);
  if (mortgageesWithNameChange.length === 0) return null;

  const mortgageesWithoutNameChange = getMortgageesWithoutNameChange(mortgageesLinkedToSelectedMortgages);
  if (mortgageesWithoutNameChange.length === 0) return null;

  for (const mortgageeWithNameChange of mortgageesWithNameChange) {
    const { nameChange } = mortgageeWithNameChange.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
    const { organisationName, isSingleName, firstName, lastName } = nameChange!;
    const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();

    for (const mortgageeWithoutNameChange of mortgageesWithoutNameChange) {
      const { organisationName, isSingleName, firstName, lastName } = mortgageeWithoutNameChange;
      const partyName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
      if (justifiedName === partyName && mortgageeWithNameChange.partyType !== mortgageeWithoutNameChange.partyType) {
        return mortgageeWithoutNameChange.partyType;
      }
    }
  }

  return null;
});

export const getOtherDmDocIds = (currentDocumentId: string, documents: WorkspaceDocumentSummaryApiResponse[]) =>
  documents
    .filter(
      document =>
        document.documentIdentifier.id === DocumentTypeIdentifierEnum.DischargeOfMortgage && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherDms = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as DischargeOfMortgageDocument2_23_0Model;
    return data.mortgages.filter(mortgage => mortgage.isSelected).flatMap(mortgage => mortgage.dealingNumber);
  });
};
