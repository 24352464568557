import * as yup from 'yup';

import { $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DischargeOfMortgage2_23_1Model, MortgageModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: mortgages
const yupMortgages = yup
  .array<MortgageModel>()
  .required(msg.REQUIRED)
  .test(
    testContextualRule({
      /**
           * what we found in schema:
           [{"required":{"mortgages":{"$items":{"$oneOf":{"isSelected":true}}}},"message":"At least one mortgage must be selected"}]
           *
           */
      uniqueTestName: '"mortgages" contextual validation rule #1',
      requirement: (mortgages: MortgageModel[], context: DischargeOfMortgage2_23_1Model): boolean => $oneOfItems(context.mortgages ?? [], item => item.isSelected === true),
      message: 'At least 1 mortgage must be selected'
    })
  )
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .test(
    'titleReferences must contain one common mortgage',
    'The mortgages selected do not share a common title. Please review and try again.', //
    function test(this: yup.TestContext<DischargeOfMortgage2_23_1Model>, value: any) {
      const values = this.options.context!;
      return values.titleReferences.length !== 0;
    }
  );

export default yupMortgages;
