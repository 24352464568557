import * as React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import NonDashboardWrapper from 'src/components/layout/v2/NonDashboardWrapper';
import NotFoundAnimation from 'src/components/not-found-animation';
import { useStyles } from './styles';

function NotFoundPage() {
  const classes = useStyles();

  const renderContent = () => {
    return (
      <FlexLayout fullWidth fullHeight alignItems="center" flexDirection="column">
        <Typography variant="h1">We can't seem to find the page you're looking for. Please check your URL.</Typography>
        <NotFoundAnimation />
        <SympliButton variant="contained" color="primary" className={classes.backButton} href="/">
          Go to dashboard
        </SympliButton>
      </FlexLayout>
    );
  };

  return (
    <NonDashboardWrapper>
      <div className="p-[32px]">{renderContent()}</div>
    </NonDashboardWrapper>
  );
}

export default NotFoundPage;
