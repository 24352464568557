import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface OwnProps {}
type Props = OwnProps & WithStyles<ClassKeys>;

class MessengerHeaderBox extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, children } = this.props;
    return <div className={classes.root}>{children}</div>;
  }
}
export default withStyles(styles)(MessengerHeaderBox);
