import * as React from 'react';

import visuallyHidden from '@mui/utils/visuallyHidden';

import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import DocumentStatusIcon from 'src/containers/documents/components/document-status-icon';
import { LinkedWorkspaceDocumentModel, LinkedWorkspaceParticipantModel } from 'src/containers/shared/linked-workspace-list/models';
import { useSharedStyles } from '../styles';

interface Props {
  currentParticipantId: string;
  documents: LinkedWorkspaceDocumentModel[];
  participants: LinkedWorkspaceParticipantModel[];
}

function DocumentList({
  //
  currentParticipantId,
  documents,
  participants
}: Props) {
  const sharedClasses = useSharedStyles();
  const isCurrentParticipant = participants.some(({ participantId }) => participantId === currentParticipantId);

  return documents.map(({ documentId, documentName }, i) => (
    <FlexLayout key={documentId} alignItems="center" className={sharedClasses.item}>
      <DocumentStatusIcon //
        isCurrentParticipant={isCurrentParticipant}
        documentStatusId={DocumentStatusEnum.InPreparation}
        margin="right"
      />
      <b>
        <span style={visuallyHidden}>Document name</span>
        {documentName}
      </b>
    </FlexLayout>
  ));
}

export default React.memo(DocumentList);
