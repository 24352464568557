import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { DistributionFormikModel } from 'src/containers/workspace/financial/directions/models';
import { alphanumericalOnly, bankDetailsYupSchema, basicSourceFundsYupObject, maxAmountCheck, numericAmountCheck, positiveAmountCheck } from '../../sharedValidationSchema';
import { IncomingDirectionsFormModel } from '../income-mortgage-directions/models';
import { CategoryEnum } from './models';

// Holding Account validation schema is dependent on the Category of 'Loan Payout'
const getHoldingAccountYupSchema = (amount: string | number) => {
  return yup
    .object({})
    .nullable(true)
    .when('category', {
      is: (value: string) => value === CategoryEnum.LoanPayout,
      then: yup.object({
        accountId: yup.string().required(msg.REQUIRED),
        reference: yup
          .string()
          .max(18, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(18))
          .test('distribution-reference-alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly),
        shortfallAmount: yup.string().test(
          'sourceFund-advanceLimitAmounshortfallAmount',
          'Short fall amount cannot be greater than amount', //
          function test(this: yup.TestContext, shortfallAmount: string) {
            if (Number(shortfallAmount) > Number(amount)) {
              return false;
            }
            return true;
          }
        )
      })
    });
};

export const getLinkedPaymentYupSchema = (numberOfLinkedWorkspaces?: number) => {
  return yup
    .object({})
    .nullable(true)
    .when('category', {
      is: (value: string) => value === CategoryEnum.LinkedPayment,
      then: yup.object({
        category: yup
          .string()
          .test('only-one-linked-payment', 'Only one Linked Payment line item is allowed per Worskspace', oneLinkedPaymentOnly)
          .test(
            'only-one-linked-workspace',
            'Maximum 10 Linked Workspaces are allowed in a Linked Settlement',
            function lessThanTenLinkedWorkspace(this: yup.TestContext, category: string) {
              if (category !== CategoryEnum.LinkedPayment || !numberOfLinkedWorkspaces) {
                return true;
              }
              const direction = this.from[0].value as DistributionFormikModel;
              return !direction.isEditorOpen || numberOfLinkedWorkspaces! < 10;
            }
          )
      })
    });
};

// TODO extra validation rule, need at least one (NEW) distribution OR at least one (NEW) souce fund
export function getValidationSchema(numberOfLinkedWorkspaces?: number) {
  return yup.object({
    distributions: yup.array().of(
      yup.lazy(value => {
        const { isLocked, amount } = value as DistributionFormikModel;
        if (isLocked) {
          return yup.object({});
        } else {
          return yup.object({
            ...dischargeMortgageDistributionYupObject,
            holdingAccountDetails: getHoldingAccountYupSchema(amount),
            linkedSettlementItem: getLinkedPaymentYupSchema(numberOfLinkedWorkspaces)
          });
        }
      })
    ),
    // * Use basic source fund validation for DM FOR TESTING purpose
    sourceFunds: yup.array().of(
      yup.object({
        ...basicSourceFundsYupObject(numberOfLinkedWorkspaces),
        reference: yup
          .string()
          .max(18, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(18))
          .test('source-fund-reference-alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly)
      })
    )
  });
}

// Discharge of Mortgage validation is based on the Category
const dischargeMortgageDistributionYupObject = {
  category: yup.string().required(msg.REQUIRED),
  categoryOther: yup.string().when('category', {
    is: (value: string) => value === CategoryEnum.Other,
    then: yup
      .string()
      .trim()
      .required(msg.REQUIRED)
      .max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
      .test('alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly)
  }),
  bankDetails: yup.object({}).when('category', {
    is: (value: string) => value === CategoryEnum.LoanPayout || value === CategoryEnum.LinkedPayment,
    then: yup.object({}).nullable(true),
    otherwise: bankDetailsYupSchema
  }),
  amount: yup
    .string() //
    .when('category', {
      is: (value: string) => value !== CategoryEnum.AcceptSurplus,
      then: yup
        .string()
        .required(msg.REQUIRED)
        .test('signers-amount', msg.INVALID_VALUE, numericAmountCheck)
        .test('signers-max-amount', msg.VALUE_MUST_BE_LESS_THAN_X('$99 million'), maxAmountCheck)
        .when('linkedSettlementItem.linkedSettlementFundsNotRequired', {
          is: true,
          then: yup.string(),
          otherwise: yup.string().test('signers-positive-amount', msg.VALUE_MUST_BE_MORE_THAN_X('$0'), positiveAmountCheck)
        })
    })
};

export function oneLinkedPaymentOnly(this: yup.TestContext, value: string) {
  const direction = this.from[0].value as DistributionFormikModel;
  const formModel = this.from[1].value as IncomingDirectionsFormModel;
  return !direction.isEditorOpen || formModel.distributions.filter(x => x.category === CategoryEnum.LinkedPayment).length <= 1;
}
