import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { RegOnlyPaymentSummaryApiResponse } from 'src/containers/shared/payment-summary-box/models';
import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchPaymentSummary, actionUpdatePaymentSummary } from '../actions';
import { PaymentSummaryApiRequest } from '../models';

export interface PaymentSummaryDetailState {
  detail?: RegOnlyPaymentSummaryApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: PaymentSummaryApiRequest;
}

const initialState: PaymentSummaryDetailState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function usePaymentSummary(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.paymentSummary);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const paymentSummaryReducer = createReducer<
  //
  PaymentSummaryDetailState,
  Action
>(initialState)
  .handleAction(actionFetchPaymentSummary.request, (state, action): PaymentSummaryDetailState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchPaymentSummary.success,
    (state, action): PaymentSummaryDetailState => ({
      ...state,
      detail: action.payload,
      isLoading: false,
      isRefetching: false,
      status: 'resolved'
    })
  )
  .handleAction(
    actionFetchPaymentSummary.failure,
    (state, action): PaymentSummaryDetailState => ({
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    })
  )
  .handleAction(
    actionUpdatePaymentSummary,
    (state, action): PaymentSummaryDetailState => ({
      ...state,
      detail: action.payload
    })
  );

export default paymentSummaryReducer;
