import * as React from 'react';

import { getIn, useFormikContext } from 'formik';

import { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import {
  DocumentAttachment,
  DocumentAttachmentItemModel,
  DocumentAttachments as SectionDocumentAttachments
} from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import { NSW_LEASE_DOCUMENT_ATTACHMENT_LABEL_CONFIG } from '../../config';
import { LEASE_ATTACHMENT_DOCUMENT_TYPE_CONDITIONS_AND_PROVISIONS, LEASE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS } from '../../enums';
import { Lease2_21_1Model } from '../../models';

interface Props {
  name: string;
  queryParams?: DocumentsPageRouteAndQueryModel;
}

export default function DocumentAttachments({ name, queryParams }: Props) {
  const minItems = 1;
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<Lease2_21_1Model>();
  const attachments = getIn(values, name) ?? [];

  const renderItem = React.useCallback(
    ({ itemBinding, item, handleRemove }: DocumentArrayItemRenderProps<DocumentAttachmentItemModel>) => {
      const isShowingRemoveButton = attachments.length > minItems;
      const isConditionsAndProvisions = item.documentType === AttachmentTypeEnum.ConditionsAndProvisions;
      const canRemove = isShowingRemoveButton && !disabled && !isConditionsAndProvisions;
      return (
        <DocumentAttachment //
          name={itemBinding}
          key={itemBinding}
          handleRemove={handleRemove}
          canRemove={canRemove}
          attachmentDocumentTypes={isConditionsAndProvisions ? LEASE_ATTACHMENT_DOCUMENT_TYPE_CONDITIONS_AND_PROVISIONS : LEASE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS}
          disableSelection={isConditionsAndProvisions}
          uploaderTitle={NSW_LEASE_DOCUMENT_ATTACHMENT_LABEL_CONFIG.uploaderTitle}
        />
      );
    },
    [attachments.length, disabled]
  );

  return (
    <SectionDocumentAttachments //
      name={name}
      minItems={minItems}
      attachmentDocumentTypes={LEASE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS}
      renderItem={renderItem}
      isRequired
      labelConfig={NSW_LEASE_DOCUMENT_ATTACHMENT_LABEL_CONFIG}
    />
  );
}
