import { batch } from 'react-redux';
import { Action } from 'redux';

import { UnsignWorkspaceDocumentsApiResponse } from '@sympli/api-gateway/models';

import { actionFetchDocumentDetail, actionUpdateDocumentData } from 'src/containers/documents/actions';
import { SaveDocumentFormRequest } from 'src/containers/documents/models';
import { actionUpdateWorkspaceDocumentsStatus } from 'src/containers/workspace/shared/detail/actions';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';

export function docUpdateHelper(
  dispatch: SafeDispatch<Action>,
  queryParams: SaveDocumentFormRequest,
  response: UnsignWorkspaceDocumentsApiResponse[]
  // fetchWorkspaceDetail?: boolean
) {
  const { workspaceId, participantId, documentId } = queryParams;
  // update 'documentDetail' change for selected document
  const currentDocument = response.find(document => documentId === document.documentId);
  if (currentDocument) {
    const { documentPermissions, status, documentActions, documentWorkflow, documentDenyPermissions, isFullySignedByAllParticipants } = currentDocument;
    batch(() => {
      dispatch(actionUpdateDocumentData({ documentPermissions, status, documentActions, documentWorkflow, documentDenyPermissions, isFullySignedByAllParticipants }));
      dispatch(
        actionUpdateWorkspaceDocumentsStatus({
          workspaceId,
          participantId,
          documents: [{ statusId: status, documentId, documentActions, documentPermissions, documentDenyPermissions, isFullySignedByAllParticipants }]
        })
      );
      dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId: documentId }));
    });
  }
  // not used
  // // fetch workspace detail again to:
  // // 1. update potential 'workspaceDetail' change
  // // 2. update 'workspaceDocuments' change for document list // TODO what is this and is it stil relevant, why are we not fetching documents?
  // if (fetchWorkspaceDetail) {
  //   // TODO explain why we need to refetch workspace and decide which one to use (full or basic)
  //   dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
  //   dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
  // }
}
