// this file was automatically generated from sections/validationSchema.ts.mustache
import * as yup from 'yup';

import { isMortgageeNameMismatch } from '../../helpers';
import { DischargeOfMortgage2_20_1Model, MortgageePartyModel } from '../../models';

// path: mortgagees
const yupMortgagees = yup.mixed<MortgageePartyModel[]>().test(
  'mortgagees[*] name mismatch',
  'Mortgagee names must match. Please select Name differs to title and provide a reason for name difference.', //
  function test(this: yup.TestContext<DischargeOfMortgage2_20_1Model>, value: any) {
    const values = this.options.context!;
    return !isMortgageeNameMismatch(values);
  }
);

export default yupMortgagees;
