import { NSW_ADDRESS_FORM_CONFIG, NSW_PHYSICAL_ADDRESS_FORM_CONFIG, NSW_PHYSICAL_ADDRESS_ITEM } from '@sympli-mfe/document-components/address-field/nsw/2-21/config';
import {
  ADD_NEW,
  AddressFieldFormConfig,
  AddressFormValueModel,
  AddressTypeEnum,
  PhysicalAddressEntityModel,
  PhysicalAddressFormConfig
} from '@sympli-mfe/document-forms-framework/components/address-field';
import { yupAuPostCodeMandatory, YupPhysicalAddressSchemaMapModel } from '@sympli-mfe/document-forms-framework/components/address-field/validation-schema';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DEFAULT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { NAME_SUFFIX_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-21/nsw';
import SHARED_MSG from '@sympli/ui-framework/utils/messages';

import { modelKey } from 'src/utils/formUtils';
import NSW_MSG from '../../../messages';

const fieldName = modelKey<PartyModel>();

export const NSW_CAVEAT_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...DEFAULT_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...DEFAULT_PARTY_FORM_CONFIG.individualPartyFormConfig,
    allowsNameSuffix: true,
    nameSuffixLookupEnum: NAME_SUFFIX_LOOKUP_OPTIONS
  },
  upperCaseFields: [fieldName('firstName'), fieldName('lastName'), fieldName('organisationName'), fieldName('nameSuffixValue')]
};

const NSW_CAVEAT_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG,
  streetNumber: {
    ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG.streetNumber,
    placeholder: resolvePlaceholder(true) // required
  },
  street: {
    ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG.street,
    placeholder: resolvePlaceholder(true) // required
  },
  postcode: {
    ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG.postcode,
    placeholder: resolvePlaceholder(true) // required
  },
  customizeValidation: (nonJurisdictionSpecificSchemaMap: YupPhysicalAddressSchemaMapModel) => {
    // please note the schemaMap passed in here is the schema passed by the component in mfe-document-forms-framework
    const customiseValidation = NSW_PHYSICAL_ADDRESS_FORM_CONFIG.customizeValidation?.(nonJurisdictionSpecificSchemaMap)!;

    return {
      ...nonJurisdictionSpecificSchemaMap,
      ...customiseValidation,
      streetNumber: customiseValidation.streetNumber.required(NSW_MSG.REQUIRED_STREET_NUMBER),
      street: customiseValidation.street.required(SHARED_MSG.REQUIRED),
      postcode: yupAuPostCodeMandatory //cant chain using customiseValidation.postcode as it is yup.lazy()
    };
  }
};

export const NSW_CAVEAT_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...NSW_ADDRESS_FORM_CONFIG,
  physicalAddressFormConfig: NSW_CAVEAT_PHYSICAL_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false, // turn off pobox address
  overseasAddressFormConfig: false // turn off
};

export const NSW_CAVEAT_SERVICE_OF_NOTICE_ADDRESS_FORM_INITIAL_VALUES: AddressFormValueModel<PhysicalAddressEntityModel> = {
  existingOrNew: ADD_NEW,
  isEdit: true, // turn the edit more, which means we show the form and turn on the validation
  addressDetail: {
    type: AddressTypeEnum.PhysicalAddress,
    physicalAddress: {
      ...NSW_PHYSICAL_ADDRESS_ITEM
    }
  }
};
