import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { Form24B_2_24_1Model, SalePriceModel } from '../../models';
import { VISIBILITY_CHECK_IS_OTHER } from '../../visibilityCheck';

const MAX_AMOUNT = 99999999999.99;
const MIN_AMOUNT = 0;
const MIN_AMOUNT_ERROR_MESSAGE: string = `Must be at least ${MIN_AMOUNT}`;
const MAX_AMOUNT_ERROR_MESSAGE: string = `Must be ${MAX_AMOUNT} or less`;

export const yupSalePrice = yup
  .object<SalePriceModel>({
    cash: yup.number().nullable().min(MIN_AMOUNT, MIN_AMOUNT_ERROR_MESSAGE).max(MAX_AMOUNT, MAX_AMOUNT_ERROR_MESSAGE).typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
    vendorTerms: yup.number().nullable().min(MIN_AMOUNT, MIN_AMOUNT_ERROR_MESSAGE).max(MAX_AMOUNT, MAX_AMOUNT_ERROR_MESSAGE).typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
    liabilities: yup.number().nullable().min(MIN_AMOUNT, MIN_AMOUNT_ERROR_MESSAGE).max(MAX_AMOUNT, MAX_AMOUNT_ERROR_MESSAGE).typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
    other: yup
      .number()
      .max(MAX_AMOUNT, MAX_AMOUNT_ERROR_MESSAGE)
      .typeError(msg.INVALID_VALUE)
      .when(['cash', 'vendorTerms', 'liabilities'], {
        is: (cash, vendorTerms, liabilities) => cash === 0 && vendorTerms === 0 && liabilities === 0,
        then: yup.number().nullable().typeError(msg.INVALID_VALUE).required('Other amount is required if all other price details (cash, vendor terms, and liabilities) are zero'),
        otherwise: yup.number().nullable().max(MAX_AMOUNT, MAX_AMOUNT_ERROR_MESSAGE).typeError(msg.INVALID_VALUE)
      }),
    otherDescription: validateWhenVisible2<string>({
      validationWhenVisible: yup.string().nullable().required(msg.REQUIRED).max(30, msg.MAX_LENGTH(30)),
      visibilityCheck: (_salePrice: SalePriceModel, context: Form24B_2_24_1Model) => VISIBILITY_CHECK_IS_OTHER(context)
    }),
    waterAllocation: yup.mixed()
  })
  .defined();
