import http from 'src/utils/http';

// maybe change to status
export enum VerificationResultEnum {
  SUCCESS = 0,
  WAITING = 1,
  REJECTED = 2,
  TIMEOUT = 3
}

export interface PollApiResponse {
  result: VerificationResultEnum;
  transactionId: string;
}

export const pollMfaStatus = (mfaId: string) => http.get<PollApiResponse>(`/mfa/notification/poll/${mfaId}`);
