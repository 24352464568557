import { QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-23/components/party-justification';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { QLD_WOC_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { filterTitleReferences, getCaveatorsForSelectedCaveat, getCaveatsBeingWithdrawn, getRegisteredProprietors, getSelectedCaveat, getSelectedTitleReferences } from './helpers';
import { ApiWithdrawalOfCaveat2_23_1Model, WithdrawalOfCaveat2_23_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_WOC_PARTY_FORM_CONFIG,
  nameChangeConversion: QldNameChangeConversion
});

class Converter implements IConverter<WithdrawalOfCaveat2_23_1Model, ApiWithdrawalOfCaveat2_23_1Model> {
  fromApiToFormModel(apiModel: ApiWithdrawalOfCaveat2_23_1Model): WithdrawalOfCaveat2_23_1Model {
    // Implement any necessary conversion from API to form values
    const { caveats } = apiModel;
    const relinquishingIds = caveats.map(caveat => caveat.caveators.map(caveator => caveator.partyBookId)).flat();
    // Implement any necessary conversion from API to form values
    const titleReferences = filterTitleReferences(caveats);
    const withdrawCaveats = getCaveatsBeingWithdrawn(caveats, titleReferences);
    let formModel: WithdrawalOfCaveat2_23_1Model = {
      ...apiModel,
      partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
      titleReferences: titleReferences,
      withdrawCaveats: withdrawCaveats,
      registeredProprietors: getRegisteredProprietors(getSelectedTitleReferences(titleReferences)),
      caveators: getCaveatorsForSelectedCaveat(withdrawCaveats)
    };

    formModel = applyDefaultMap(formModel, fallbackMap as any);
    return formModel;
  }
  fromFormToApiModel(formModel: WithdrawalOfCaveat2_23_1Model, originalApiModel: ApiWithdrawalOfCaveat2_23_1Model): ApiWithdrawalOfCaveat2_23_1Model {
    formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      titleReferences,
      caveators,
      withdrawCaveats,
      registeredProprietors,
      ...rest
    } = formModel;

    const selectedTitles = titleReferences.filter(x => x.isSelected).map(x => x.reference);
    const selectedCaveat = getSelectedCaveat(withdrawCaveats);
    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
      caveats: formModel.caveats.map(caveat => {
        const isSelected = caveat.dealingNumber === selectedCaveat?.dealingNumber;
        // Updating caveats object with changes made by the user within EditForm
        return {
          ...caveat,
          isSelected: isSelected,
          titleReferences: caveat.titleReferences.map(t => {
            // Updating title reference selection incase it has been updated by the user
            return {
              ...t,
              isSelected: isSelected ? selectedTitles.includes(t.reference) : false,
              proprietors: t.proprietors.map(proprietor => {
                return registeredProprietors?.find(rp => rp.partyBookId === proprietor.partyBookId) || proprietor;
              })
            };
          }),
          caveators: caveat.caveators.map(m => {
            // Updating caveator incase party book has been updated by the user
            if (caveators) {
              return caveators.find(x => x.partyBookId === m.partyBookId) || m;
            }
            return m;
          })
        };
      })
    };
  }
}

export default new Converter();
