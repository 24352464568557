import * as React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import { IconEye, IconEyeHide } from '@sympli/ui-framework/icons';

import { EyeToggleButtonProps } from './EyeToggleButtonContainer';
import { useStyles } from './styles';

type Props = EyeToggleButtonProps;

function EyeToggleButton({ open, onClick, hideText, showText }: Props) {
  const classes = useStyles();

  return (
    <ButtonLink onClick={onClick} color="inherit">
      {open ? <IconEyeHide className={classes.icon} /> : <IconEye className={classes.icon} />}
      {open ? hideText || 'Hide line items' : showText || 'Show line items'}
    </ButtonLink>
  );
}

export default React.memo(EyeToggleButton);
