import React from 'react';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionDischargeAmount({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  return (
    <div data-section={name} className={className}>
      <FormGroup //
        title="Discharge amount"
        fieldAreaDirection="column"
        classes={{ root: classes.root }}
      >
        <Field //
          name={name}
          aria-label="Discharge amount"
          component={InputField}
          disabled={disabled}
        />
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionDischargeAmount);
