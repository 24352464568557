import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      padding: '16px 20px',
      boxSizing: 'border-box',
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '24px',
      fontFamily: 'volkswagen_serial-bold',
      color: theme.palette.common.white
    },
    messageCount: {
      background: theme.palette.secondary.main,
      width: 24,
      height: 24,
      marginTop: -4,
      fontSize: 13,
      color: theme.palette.common.white,
      borderRadius: '50%'
    },
    noMessage: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '24px',
      color: colors.GREEK_WATERS_DARK,
      marginTop: 4
    },
    messageList: {
      height: '100%',
      overflowY: 'auto',

      borderRadius: 0,
      boxShadow: 'none',

      '&::-webkit-scrollbar': {
        width: '0px'
      }
    },
    messageItem: {
      padding: '12px 20px',
      position: 'relative',
      borderBottom: `1px solid ${colors.NEUTRAL_050}`
    },
    messageItemContent: {
      overflow: 'hidden'
    },
    messageItemHeader: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
      color: colors.NEUTRAL_600
    },
    messageItemLatestMessage: {
      width: 280
    },
    messageItemDesc: {
      fontSize: 13,
      lineHeight: '20px',
      color: colors.NEUTRAL_600,
      fontWeight: 400,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    messageItemDate: {
      flexShrink: 0,
      paddingLeft: 4,
      fontSize: 10,
      lineHeight: 'normal',
      color: colors.NEUTRAL_600
    },
    highlightHeader: {
      fontWeight: 700,
      color: colors.NEUTRAL_1000
    },
    highlightDesc: {
      color: colors.NEUTRAL_800,
      fontWeight: 700
    },
    highlightDot: {
      width: 4,
      height: 4,
      background: colors.WATERMELON,
      borderRadius: '50%',
      flexShrink: 0,
      position: 'absolute',
      top: 20,
      left: 8
    },
    // No conversation style
    zeroInboxIcon: {
      marginBottom: 9
    },
    zeroInboxDesc: {
      padding: '8px 60px',
      fontSize: 14,
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: '20px'
    },
    zeroInboxLayout: {
      marginTop: '-60px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
