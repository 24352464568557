import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { NominateeCtToPaperInstrument_2_18_1Model } from './models';

export const fallbackMap: FallbackMap<NominateeCtToPaperInstrument_2_18_1Model> = {
  titleReferences: NO_FALLBACK,
  ctController: NO_FALLBACK,
  nominationDetails: {
    $children: {
      instrumentText: [''],
      partiesInInstrumentText: ['']
    }
  },
  issuingPartyDirection: [null]
};
