import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { getCaveatorsForSelectedCaveat } from '../../helper';
import { CaveatModel, WithdrawalOfCaveat_2_26_0_Model } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface OwnProps {
  name: string;
  formikProps: FormikProps<WithdrawalOfCaveat_2_26_0_Model>;
  disabled: boolean;
  focusRef: React.RefObject<ButtonBaseActions>;
}

type Props = OwnProps; // & WithStyles<ClassKeys>;

function SectionWithdrawCaveat({
  //
  name,
  formikProps,
  disabled,
  focusRef
}: Props): JSX.Element {
  const {
    values: { partyBook, withdrawCaveats, dealingNumbersSelectedInOtherWithdrawalsOfCaveat },
    setValues
  } = formikProps;
  const classes = useStyles();
  const hasMultipleSelection = withdrawCaveats.length > 1;
  const resolveItemLabel = React.useCallback(
    (caveat: CaveatModel) => {
      const caveators = caveat.caveators.flatMap(c => c.partyBookId);
      const isSelectedWarning = (hasMultipleSelection ? caveat.isSelected : true) && dealingNumbersSelectedInOtherWithdrawalsOfCaveat?.includes(caveat.dealingNumber ?? '');
      return (
        <>
          <span className={classNames(classes.dealingNumber)}>{caveat.dealingNumber}</span>
          <span className={classNames(hasMultipleSelection ? classes.multipleDealingNumber : classes.singleDealingNumber)}>
            {partyBook
              ?.filter(x => caveators.includes(x.id))
              .map(x => x.legalEntityName)
              .join(', ')}
          </span>
          {isSelectedWarning && (
            <Typography component="p" className={classes.warning}>
              This caveat is part of another Withdrawal of Caveat document in this workspace
            </Typography>
          )}
        </>
      );
    },
    [
      dealingNumbersSelectedInOtherWithdrawalsOfCaveat,
      classes.dealingNumber,
      classes.multipleDealingNumber,
      classes.singleDealingNumber,
      classes.warning,
      hasMultipleSelection,
      partyBook
    ]
  );

  const onChange = React.useCallback(() => {
    setValues(updatedValues => {
      const { withdrawCaveats } = updatedValues;
      const caveators = getCaveatorsForSelectedCaveat(withdrawCaveats);

      return {
        ...updatedValues,
        caveators: caveators
      };
    });
  }, [setValues]);

  return (
    <Section //
      title="Caveat being withdrawn"
      data-name={name}
    >
      <div className={classes.root}>
        <GenericItemSelection //
          name={name}
          mode="single" // explicitly single mode WEB-13583
          disabled={disabled}
          variant="naked"
          onChange={onChange}
          focusRef={focusRef}
          resolveItemLabel={resolveItemLabel}
          noDataMessage="There are no common caveats across selected titles"
          className={classes.itemSelection}
        />
      </div>
    </Section>
  );
}

export default React.memo(SectionWithdrawCaveat);
