import * as yup from 'yup';

import { QldDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { resolveTransferorSchema } from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/sections/transferors/validationSchema';
import yupTitleReferences from '../../../../shared/2-24/components/title-references/validationSchema';
import { getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG } from './config';
import { Transfer2_24_1Model } from './models';
import yupPartyBook from './sections/party-book/validationSchema';

export default yup
  .object<Transfer2_24_1Model>({
    titleReferences: yupTitleReferences(),
    partyBook: yupPartyBook,
    transferors: resolveTransferorSchema(QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG.partyRole, getLegalEntityNameQLD),

    // no validation
    partyBookApi: yup.array<ApiDocumentPartyModel<QldDocumentPartyJustification>>().defined(),
    workspaceTitleReferences: yup.mixed<Transfer2_24_1Model['workspaceTitleReferences']>(),
    invalidParties: yup.mixed<Transfer2_24_1Model['invalidParties']>(),
    attachments: yup.mixed<Transfer2_24_1Model['attachments']>(),
    mergeFailedReason: yup.mixed<Transfer2_24_1Model['mergeFailedReason']>()
  })
  .defined()
  .log();
