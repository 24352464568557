import React, { useEffect } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import Divider from '@mui/material/Divider';

import PartyForm, { PARTY_TYPE_OPTIONS, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-17/necds';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Subform from '@sympli/ui-framework/components/formik/subform';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import DateOfDeath from '../../components/date-of-death';
import EvidenceArray from '../../components/evidence-array';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG2 } from '../../config';
import { getParentPath } from '../../helpers';
import { DeceasedProprietorGroupModel, DeceasedProprietorModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import { VISIBILITY_CHECK_DATE_OF_DEATH, VISIBILITY_CHECK_EVIDENCES } from '../../visibilityChecks';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  isSingleEvidence: boolean;
}

function DeceasedProprietor({ name, className, isSingleEvidence }: Props): JSX.Element {
  const { values, setFieldValue, setValues } = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const { divider } = useStyles();
  const value: DeceasedProprietorModel = _get(values, name);
  const parentGroupProprietor = getParentPath(name);
  const groupProprietors: DeceasedProprietorGroupModel = _get(values, parentGroupProprietor);
  const maxItems = isSingleEvidence
    ? 1
    : 20 - groupProprietors.deceasedProprietors.filter(x => x.deceasedProprietorId !== value.deceasedProprietorId).flatMap(x => x.evidences).length;
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<DeceasedProprietorModel>(name);
  const partyBook: PartyModel[] = values.partyBook ?? [];
  const proprietorPartyBook = partyBook.find(party => party.id === value.partyBookId);
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;
  const isShowPartyForm = Boolean(value.isLastDeceased);
  const isOrg = proprietorPartyBook?.partyType === PartyTypeEnum.Organisation;
  const showCustomOrganizationParty = isOrg && !proprietorPartyBook.readonlyMap?.partyType;

  //This for listening to party type change event from Party subform
  //In case this party type change to Organization and isAffectedProprietor is true it will change isAffectedProprietor to false
  useEffect(() => {
    //let updatedGroup: DeceasedProprietorModel[] = [...groupProprietors.deceasedProprietors];
    if (isOrg && value.isAffectedProprietor) {
      const updatedGroup = groupProprietors.deceasedProprietors.map(p => {
        return {
          ...p,
          isAffectedProprietor: false,
          isLastDeceased: false,
          isEvidenceVisible: false
        };
      });
      setFieldValue(parentGroupProprietor, {
        ...groupProprietors,
        deceasedProprietors: updatedGroup
      });
    }
  }, [groupProprietors, groupProprietors.deceasedProprietors, isOrg, name, parentGroupProprietor, setFieldValue, value, value.isAffectedProprietor]);

  const handlePartyTypeChange = (e: React.ChangeEvent<HTMLInputElement>, newPartyType: PartyTypeEnum): void => {
    if (newPartyType === PartyTypeEnum.Individual) {
      const updatedDeceasedProprietorGroupModels: DeceasedProprietorGroupModel[] = values.deceasedProprietorGroups.map(group => {
        const updatedDeceasedProprietors: DeceasedProprietorModel[] = group.deceasedProprietors.map(p => {
          if (value.deceasedProprietorId === p.deceasedProprietorId) {
            return {
              ...p,
              isAffectedProprietor: true,
              isLastDeceased: true,
              isEvidenceVisible: true
            };
          }
          return {
            ...p,
            isAffectedProprietor: false,
            isLastDeceased: false,
            isEvidenceVisible: false
          };
        });
        return {
          ...group,
          deceasedProprietors: updatedDeceasedProprietors
        };
      });

      setValues(values => {
        return {
          ...values,
          deceasedProprietorGroups: updatedDeceasedProprietorGroupModels
        };
      });
    }
    setFieldValue(partyBindingPath, {
      ...proprietorPartyBook,
      partyType: newPartyType
    });
  };

  return (
    <div data-section={name} className={className} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        {isShowPartyForm ? (
          <Subform
            subformBindingPath={partyBindingPath}
            component={PartyForm}
            disabled={disabled || !value.isLastDeceased}
            config={PARTY_FORM_WITH_NAME_CHANGE_CONFIG2}
            showFormGroups={false}
          />
        ) : (
          <div>
            {showCustomOrganizationParty && (
              <Radio
                value={proprietorPartyBook?.partyType}
                name={partyBindingPath}
                disabled={false}
                onChange={handlePartyTypeChange}
                format="number"
                options={PARTY_TYPE_OPTIONS}
                aria-label="Party type"
              />
            )}
            <ReadOnlyField //
              label={proprietorPartyBook?.partyType === PartyTypeEnum.Organisation ? 'Organisation' : 'Individual'}
              value={
                proprietorPartyBook?.partyType === PartyTypeEnum.Organisation
                  ? proprietorPartyBook?.organisationName
                  : [proprietorPartyBook?.firstName, proprietorPartyBook?.lastName].filter(Boolean).join(' ')
              }
            />
          </div>
        )}
      </FlexLayout>

      <FlexLayout justifyContent="flex-start" flexDirection="column">
        {VISIBILITY_CHECK_DATE_OF_DEATH(value) && (
          <>
            <Divider className={divider} />
            <DateOfDeath //
              name={fieldName('dateOfDeath')}
            />
          </>
        )}

        {VISIBILITY_CHECK_EVIDENCES(value) && (
          <>
            <Divider className={divider} />
            <EvidenceArray //
              name={fieldName('evidences')}
              maxItems={maxItems}
            />
          </>
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(DeceasedProprietor);
