import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, memoizeSchema, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import {
  DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum,
  DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdLookup
} from '../../enums';
import { DealingRequirementsDetailModel, DealingRequirementsSummaryModel } from '../../models';
import { VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: dealingRequirementsSummary
export function resolveMemoizedDealingRequirementsSummaryValidationSchema<T extends DealingRequirementsSummaryModel>() {
  const yupDealingRequirementsSummary = yup
    .object<DealingRequirementsSummaryModel>()
    .nullable(true)
    .shape({
      hasAddedDealingRequirements: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
      dealingRequirementsDetail: yup //
        .mixed<DealingRequirementsDetailModel[]>()
        .test(
          validateWhenVisible({
            visibilityCheck: VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL,
            validationWhenVisible: yup
              .array<DealingRequirementsDetailModel>()
              .nullable(true)
              .max(20, msg.MAX_ITEMS(20))
              .of(
                yup
                  .object<DealingRequirementsDetailModel>()
                  .nullable(true)
                  .shape({
                    dealingRequirement: yup
                      .mixed<DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdEnum.ConsentOfCaveator>()
                      .required(msg.REQUIRED)
                      .oneOf(
                        getLookupValuesAllowingEmpty(DealingRequirementsSummary$DealingRequirementsDetail$DealingRequirement_Or_DealingRequirementDefinitions$IdLookup),
                        msg.INVALID_SELECTION
                      )
                  })
              ),
            isObjectOrArray: true
          })
        )
    })
    .defined() as yup.ObjectSchema<T>;

  return memoizeSchema(yupDealingRequirementsSummary);
}

const yupDealingRequirementsSummary = resolveMemoizedDealingRequirementsSummaryValidationSchema<DealingRequirementsSummaryModel>();

export default yupDealingRequirementsSummary;
