import React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

export type IconListItemProps = {
  linkTo?: string;
  icon?: JSX.Element;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  secondaryAction?: JSX.Element;
  dataTestId?: string;
};

type Props<D extends React.ElementType = 'li'> = Omit<ListItemProps<D>, 'button' | 'dense' | 'alignItems'> & IconListItemProps & WithStyles<ClassKeys>;

function IconListItem<D extends React.ElementType = 'li'>(props: React.PropsWithChildren<Props<D>>) {
  const {
    //
    linkTo,
    onClick,
    selected,
    icon,
    primary,
    secondary,
    secondaryAction,
    children,
    classes,
    dataTestId,
    ...restProps
  } = props;

  return (
    <>
      <ListItem //
        alignItems="flex-start"
        dense={true}
        classes={{
          root: classNames(classes.itemRoot, classes.alignCenter),
          selected: classes.itemSelected,
          focusVisible: classes.focusVisible
        }}
        onClick={onClick}
        selected={selected}
        {...restProps}
        component={linkTo ? NavLink : undefined}
        to={linkTo}
        button={(!!onClick || !!linkTo) as any} // workaround due to this https://github.com/mui-org/material-ui/issues/14971
        secondaryAction={secondaryAction ? secondaryAction : undefined}
      >
        {icon ? (
          <ListItemIcon //
            classes={{ root: classes.iconRoot, alignItemsFlexStart: classes.iconAlignItemsFlexStart }}
          >
            {icon}
          </ListItemIcon>
        ) : null}

        <ListItemText //
          data-testid={`${dataTestId}-text`}
          primary={primary}
          secondary={secondary}
          secondaryTypographyProps={{ component: 'div' }}
          classes={{ root: classes.textRoot, primary: classes.textPrimary, secondary: classes.textSecondary }}
        />
      </ListItem>
      {children ? (
        <List disablePadding={true} className={classes.nestedList}>
          {children}
        </List>
      ) : null}
    </>
  );
}

export default withStyles(styles)(IconListItem);
