import * as React from 'react';

import _debounce from 'lodash-es/debounce';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { DEBOUNCE_LIMIT } from 'src/containers/dashboard/shared/helpers';
import { actionFetchLinkedWorkspaceUpdatedFeed, actionResetFetchLinkedWorkspaceFeed } from 'src/containers/shared/app-bar/actions';
import { useSafeDispatch } from 'src/hooks';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { useSearchLinkedWorkspaceUpdated } from '../../reducers/searchLinkedWorkspaceUpdated';
import LinkedWorkspaceSearchBox from './components/linked-workspace-search-box';
import LinkedWorkspaceSearchTableContainer from './components/linked-workspace-search-table';
import { SearchTableModel } from './components/linked-workspace-search-table/models';
import { SearchLinkedWorkspaceApiRequest } from './models';
import { useStyles } from './styles';

interface LinkedWorkspaceSearchContainerProps {
  onRowClick: (rowIndex: number, rowData: SearchTableModel) => void;
  selectedRow?: number;
  clearSelectedRow: () => void;
  searchType: 'excludeLinkedDirections' | 'excludeLinkedSourceFunds';
  excludeWorkspaceId: string;
}

function LinkedWorkspaceSearchContainer({ onRowClick, selectedRow, clearSelectedRow, searchType, excludeWorkspaceId }: LinkedWorkspaceSearchContainerProps) {
  // internal state
  const [value, setValue] = React.useState(''); // search input value
  // redux data
  const searchesFeed = useSearchLinkedWorkspaceUpdated(value);
  // else
  const dispatch = useSafeDispatch(useDispatch());
  const classes = useStyles();
  const SEARCH_TERM_MIN_LENGTH = 3;

  // TODO move it to helper function file
  const fetchSearchBoxWithDebounce = _debounce(
    (
      //
      searchTerm: string | undefined,
      pageNumber: number = 1
    ) => {
      const dispatchAction = (inputLength: number, query: SearchLinkedWorkspaceApiRequest, action: SafeDispatch<any>, resetAction: SafeDispatch<any>) => {
        if (inputLength >= SEARCH_TERM_MIN_LENGTH) {
          dispatch(action(query));
        } else {
          dispatch(resetAction(query));
        }
      };

      const inputValue = searchTerm ? searchTerm.trim().toLowerCase() : '';
      const q: SearchLinkedWorkspaceApiRequest = {
        searchTerm: inputValue,
        pageNumber,
        excludeLinkedDirection: searchType === 'excludeLinkedDirections',
        excludeLinkedSourceFund: searchType === 'excludeLinkedSourceFunds',
        excludeWorkspaceId
      };

      dispatchAction(
        //
        inputValue.length,
        q,
        actionFetchLinkedWorkspaceUpdatedFeed.request,
        actionResetFetchLinkedWorkspaceFeed
      );
    },
    DEBOUNCE_LIMIT
  );

  return (
    <>
      <FlexLayout flexDirection="column" className={classes.SearchBar} fullWidth>
        <Typography variant="subtitle3" className="text-[var(--neutral-1000)] font-[400] mb-[8px] mt-[16px]">
          Link to workspace
        </Typography>
        <LinkedWorkspaceSearchBox //
          value={value}
          setValue={setValue}
          fetchSearchBoxWithDebounce={fetchSearchBoxWithDebounce}
          clearSelectedRow={clearSelectedRow}
        />
      </FlexLayout>

      <FlexLayout flexDirection="row" justifyContent="center" fullWidth>
        <Box className="w-full bg-[var(--sunny-day-translucent)] px-[12px] mb-[16px]">
          <LinkedWorkspaceSearchTableContainer //
            searchTerm={value.trim()}
            searchesFeed={searchesFeed}
            fetchSearchBoxWithDebounce={fetchSearchBoxWithDebounce}
            onRowClick={onRowClick}
            selectedRow={selectedRow}
          />
        </Box>
      </FlexLayout>
    </>
  );
}
export default React.memo(LinkedWorkspaceSearchContainer);
