import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { DischargeOfMortgageDocument2_23_0Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<DischargeOfMortgageDocument2_23_0Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  extentOfLandDischarged: [null],
  extentOfMoneyDischarged: [null],
  dischargeAmount: [null],
  mortgages: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        dealingNumber: NO_FALLBACK,
        isSelected: [false],
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              titleType: NO_FALLBACK,
              subJurisdiction: NO_FALLBACK,
              titleShareFraction: NO_FALLBACK,
              reference: NO_FALLBACK,
              isSelected: [false],
              isPartLandAffected: [false],
              partDescription: NO_FALLBACK,
              landDescriptions: {
                $shouldAddDefaultItemToEmptyArray: false,
                $arrayItem: {
                  $children: {
                    isSelected: [false],
                    legalDescription: NO_FALLBACK,
                    landDescriptionComponents: NO_FALLBACK
                  }
                }
              },
              sltReference: NO_FALLBACK
            }
          }
        },
        mortgageeDetails: {
          $children: {
            tenancyType: NO_FALLBACK,
            relinquishingProprietorGroups: {
              $shouldAddDefaultItemToEmptyArray: true,
              $arrayItem: {
                $children: {
                  shareTransferred: [null],
                  shareFraction: {
                    $children: {
                      numerator: [null],
                      denominator: [null]
                    }
                  },
                  previouslyHeldShareFraction: NO_FALLBACK,
                  proprietorGroupType: [null],
                  parties: {
                    $shouldAddDefaultItemToEmptyArray: true,
                    $arrayItem: {
                      $children: {
                        partyBookId: [''],
                        addressBookId: [''],
                        isTransactingParty: [false],
                        partyCapacity: [null],
                        partyCapacityDetail: ['']
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  additionalAttachments: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  mortgagees: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        partyBookId: [''],
        addressBookId: [''],
        isTransactingParty: [false],
        partyCapacity: [null],
        partyCapacityDetail: ['']
      }
    }
  }
};
