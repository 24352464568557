import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import TextTruncator from '@sympli/ui-framework/components/layout/text-truncator';

import { useStyles } from './styles';

export interface Props {
  icon?: JSX.Element;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  handleSelectionCallback: () => void;
  truncateAt?: number;
  isLoading?: boolean;
}

function DownloadOption({ icon, text, onClick, handleSelectionCallback, truncateAt, isLoading }: Props) {
  const classes = useStyles();
  const handleClick = e => {
    //This is to signal the parent to hide the menuList once the item is selected from the list
    handleSelectionCallback();
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <MenuItem onClick={handleClick} disabled={isLoading}>
      <FlexLayout alignItems="center">
        {icon}
        {truncateAt ? (
          <TextTruncator //
            text={text}
            truncateAt={truncateAt}
            showFullTextAsTooltip={false}
          />
        ) : (
          <Typography className={classes.menuItemLabel}>{text}</Typography>
        )}
        {isLoading && <CircularProgress size={13} className={classes.spinner} />}
      </FlexLayout>
    </MenuItem>
  );
}

export default DownloadOption;
