import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      paddingBottom: 20
    },
    fullWidth: {
      width: 'auto'
    }
  }),
  {
    name: 'TransactionInstrument'
  }
);
