import { useLocation } from 'react-router-dom';

/*
  see if current routing matches the sub route we pass into,
  i.e 
  the current pathname is /workspace/70168382-24de-4815-8b7d-5c18efcadcc8/directions-all
  this is all direction page, if we pass subRoute as directions-all, it return true, 
  this help us check if we are on a certain page or not
*/
export default function useRouterPathMath(subRoute: string) {
  const { pathname } = useLocation();
  return pathname.includes(subRoute);
}
