import { StampDutyStatusEnum } from '@sympli/api-gateway/enums';

export const UNAVAILABLE_DISPLAY_NAME = 'UNAVAILABLE';
export const IN_PREPARATION_DISPLAY_NAME = 'IN PREPARATION';
export const VERIFIED_DISPLAY_NAME = 'VERIFIED';

export const StampDutyStatusDisplayMapping: Record<StampDutyStatusEnum, string> = {
  [StampDutyStatusEnum.AwaitingStampDutyDocuments]: UNAVAILABLE_DISPLAY_NAME,
  [StampDutyStatusEnum.WaitingForClaim]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.Claimed]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.ReadyToSubmit]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.Submitted]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.Allocated]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.ReadyToCertify]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.Assessed]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.Cancelled]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.RequestToCancel]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.ExtendSettlementDate]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.Review]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.ReadyToVerify]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.ReadyToCreateTransaction]: IN_PREPARATION_DISPLAY_NAME,
  [StampDutyStatusEnum.CertifiedAndReadyToSettle]: VERIFIED_DISPLAY_NAME,
  [StampDutyStatusEnum.Returned]: VERIFIED_DISPLAY_NAME,
  [StampDutyStatusEnum.Finalised]: VERIFIED_DISPLAY_NAME,
  [StampDutyStatusEnum.Completed]: VERIFIED_DISPLAY_NAME,
  [StampDutyStatusEnum.Verified]: VERIFIED_DISPLAY_NAME
};

export const StampDutySteps: string[] = [UNAVAILABLE_DISPLAY_NAME, IN_PREPARATION_DISPLAY_NAME, VERIFIED_DISPLAY_NAME];
