import * as React from 'react';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  paymentTypeHeader?: string;
  paymentDetailHeader?: string;
  paymentAmountHeader?: string;
  paymentBreakdownHeader?: string;
  hasBreakdownsCol?: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class FormGridHead extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    paymentTypeHeader: 'Added by',
    paymentDetailHeader: 'Account details',
    paymentAmountHeader: 'Amount ($)',
    paymentBreakdownHeader: 'Fee breakdown'
  };

  render() {
    const { classes, paymentTypeHeader, paymentDetailHeader, paymentAmountHeader, paymentBreakdownHeader, hasBreakdownsCol } = this.props;
    return (
      <FlexLayout className={classes.bottomBorder}>
        <div className={classes.paymentTypeCol}>
          <Typography className={classes.formLabel}>{paymentTypeHeader}</Typography>
        </div>
        <div className={hasBreakdownsCol ? classes.accountDetailWithBreakdownsCol : classes.accountDetailCol}>
          <Typography className={classes.formLabel}>{paymentDetailHeader}</Typography>
        </div>
        {hasBreakdownsCol && (
          <div className={classes.feeBreakdownCol}>
            <Typography className={classes.formLabel}>{paymentBreakdownHeader}</Typography>
          </div>
        )}
        <div className={classes.amountCol}>
          <Typography className={classes.formLabel}>{paymentAmountHeader}</Typography>
        </div>
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(FormGridHead);
