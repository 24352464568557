import * as React from 'react';

import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';

import LineLoader from 'src/components/loaders/line-loader';
import { WorkspaceDetailBoxProps } from '../../WorkspaceDetailBox';

export interface MyProps {
  isLoading?: boolean;
  // workspace participant info
  partyNames?: string[];
  loaderBackgroundColor?: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  className?: string;
  isRightPanel?: boolean;
}

function ClientNames(props: MyProps) {
  const {
    //
    loaderBackgroundColor,
    isLoading,
    className,
    partyNames,
    isRightPanel = false
  } = props;

  if (!partyNames?.length) {
    return null;
  }

  if (isLoading) {
    return <LineLoader data-part="client-names" color={loaderBackgroundColor} variant="large" widthPercent={45} style={{ marginBottom: 4 }} />;
  }

  const sortedPartyNames: string[] = partyNames.sort();

  return (
    <div className={className}>
      <span style={visuallyHidden}>{pluralize('Client name', sortedPartyNames.length)}</span>
      {sortedPartyNames.map((name, index) => (
        <Typography variant={isRightPanel ? 'body2_bold' : 'body2'} key={`${name}-${index}`}>
          {name}
        </Typography>
      ))}
    </div>
  );
}

export default React.memo(ClientNames);
