import React from 'react';

import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { useStyles } from './styles';

export type AvatarListItemProps = {
  linkTo?: string;
  avatar?: JSX.Element;
  avatarSize?: 'm' | 's';
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
};

type Props<D extends React.ElementType = 'li'> = Omit<ListItemProps<D>, 'button' | 'dense' | 'alignItems'> & AvatarListItemProps;

function AvatarListItem<D extends React.ElementType = 'li'>(props: React.PropsWithChildren<Props<D>>) {
  const {
    //
    linkTo,
    onClick,
    avatar,
    avatarSize = 'm',
    children,
    primary,
    secondary,
    selected,
    ...restProps
  } = props;
  const classes = useStyles(props);
  const sizeClassName = classes[`avatarSize${avatarSize.toUpperCase()}`];

  return (
    <>
      <ListItem //
        alignItems="center"
        dense={true}
        classes={{ root: classes.itemRoot, selected: classes.itemSelected, dense: classes.itemDense }}
        onClick={onClick}
        selected={selected}
        {...restProps}
        component={linkTo ? NavLink : undefined}
        to={linkTo}
        button={(!!onClick || !!linkTo) as any} // workaround due to this https://github.com/mui-org/material-ui/issues/14971
      >
        {avatar ? <ListItemAvatar classes={{ root: classes.avatarRoot }}>{React.cloneElement(avatar, { className: sizeClassName })}</ListItemAvatar> : null}

        <ListItemText //
          primary={primary}
          secondary={secondary}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ component: 'div' }}
          classes={{ root: classes.itemTextRoot, primary: classes.textPrimary, secondary: classes.textSecondary }}
        />
      </ListItem>
      {children ? (
        <List disablePadding={true} className={classes.nestedList}>
          {children}
        </List>
      ) : null}
    </>
  );
}

export default React.memo(AvatarListItem);
