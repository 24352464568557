import { createAction, createAsyncAction } from 'typesafe-actions';

import { CompanyProfileApiResponse } from '../models';

export const actionFetchOrganisationProfile = createAsyncAction(
  //
  'FETCH_ORGANISATION_PROFILE',
  'FETCH_ORGANISATION_PROFILE_SUCCESS',
  'FETCH_ORGANISATION_PROFILE_ERROR'
)<
  //
  undefined,
  { data: CompanyProfileApiResponse },
  { error: Error }
>();

export const actionUpdateOrganisationProfilePicture = createAction(
  //
  'UPDATE_ORGANISATION_PROFILE_PICTURE'
)<//
{ profileImageUrl?: string }>();
