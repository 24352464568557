import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    orderNowButton: {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.divider
      }
    },
    loader: {
      margin: 2
    },
    // dialog left part
    dialogInfoArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexShrink: 0,
      padding: '44px',
      width: 340,
      minHeight: 310
    },
    dialogTitle: {
      marginTop: 20,
      fontWeight: 'bold',
      letterSpacing: 0
    },
    dialogSubTitle: {
      fontSize: 15,
      lineHeight: '28px',
      letterSpacing: 0,
      fontWeight: 400
    },
    dialogMessage: {
      paddingTop: 12,
      fontSize: 13,
      lineHeight: '17px',
      textAlign: 'left',
      alignSelf: 'flex-start'
    },
    dialogContent: {
      padding: 0,
      textAlign: 'center'
    },
    // dialog right part
    paymentArea: {
      background: theme.palette.grey[100],
      boxSizing: 'border-box',
      padding: '24px 35px',
      minWidth: 310
    },
    titleWithAddress: {
      textAlign: 'left',
      margin: '16px 0px'
    },
    paymentAreaCostDetail: {
      fontSize: 13,
      lineHeight: '15px',
      textAlign: 'left'
    },
    paymentAreaDetailLine: {
      marginTop: 12,
      paddingBottom: 4,
      marginBottom: 6,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    paymentAreaCostTotal: {
      fontWeight: 'bold'
    },
    paymentAreaCostGst: {
      color: theme.palette.text.secondary,
      marginBottom: 24
    }
  });

export type ClassKeys = typeof styles;

export default styles;
