import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import MortgagorPartyReadonlyArray from './components/mortgagor-party-readonly-array';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS } from './enums';
import { getMortgagorPartyBookIds, getSelectedTitleReferences } from './helper';
import { IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT } from './isRequiredChecks';
import { Mortgage2_21_1Model, MortgagorPartyModel } from './models';
import DocumentAttachments from './sections/document-attachments';
import Mortgagees from './sections/mortgagees';
import TermsAndConditions from './sections/terms-and-conditions';
import { VISIBILITY_CHECK_MORTGAGORS } from './visibilityChecks';

const fieldName = modelKey<Mortgage2_21_1Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage2_21_1Model>();
  const { values, setValues } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgagorRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgageesRef = React.useRef<ButtonBaseActions>(null);
  const {
    termsAndConditions: { standardTermsDealingNumbers, additionalCovenants },
    additionalAttachments
  } = values;

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnMortgagorRef.current || focusOnMortgageesRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        // values already contains updated titles
        const selectedTitles = getSelectedTitleReferences(values.titleReferences);
        // collect unique list of mortgagors present under selected title references
        const selectedTitlesMortgagorPartyBookIds = getMortgagorPartyBookIds(selectedTitles);

        // update list of mortgagors based on title selection
        let updated = false;
        // first, remove those that should not be displayed anymore
        const updatedMortgagors = values.mortgagors.reduce<MortgagorPartyModel[]>((acc, item) => {
          if (!selectedTitlesMortgagorPartyBookIds.includes(item.partyBookId)) {
            updated = true;
          } else {
            // make sure that we keep unique list of mortgagors
            if (!acc.find(({ partyBookId }) => item.partyBookId === partyBookId)) {
              acc.push(item);
            }
          }
          return acc;
        }, []);
        // second, add missing ones
        if (updatedMortgagors.length < selectedTitlesMortgagorPartyBookIds.length) {
          selectedTitlesMortgagorPartyBookIds.reduce<MortgagorPartyModel[]>((acc, partyBookId) => {
            if (!acc.find(item => item.partyBookId === partyBookId)) {
              acc.push({
                partyBookId
              });
              updated = true;
            }
            return acc;
          }, updatedMortgagors);
        }

        if (updated) {
          return {
            ...values,
            mortgagors: updatedMortgagors
          };
        }
        // because nothing has been changed we want to keep original reference and prevent unnecessary re-rendering
        return values;
      });
    },
    [setValues]
  );

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          data-testid={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
          focusRef={focusOnTitleReferencesRef}
        />
        {VISIBILITY_CHECK_MORTGAGORS({ titleReferences: values.titleReferences }) ? (
          <Section //
            title={pluralize('Mortgagor', values.mortgagors.length)}
            data-name={fieldName('mortgagors')}
            data-testid={fieldName('mortgagors')}
            error={getFormikError(formikProps, fieldName('mortgagors'), true)}
          >
            <MortgagorPartyReadonlyArray name={fieldName('mortgagors')} focusRef={focusOnMortgagorRef} />
          </Section>
        ) : null}

        <Mortgagees name={fieldName('mortgagees')} focusRef={focusOnMortgageesRef} />

        <Section //
          title="Terms and conditions"
          data-testid={fieldName('termsAndConditions')}
          data-name={fieldName('termsAndConditions')}
        >
          <TermsAndConditions name={fieldName('termsAndConditions')} queryParams={queryParams} additionalAttachments={additionalAttachments} />
        </Section>

        <DocumentAttachments //
          name={fieldName('additionalAttachments')}
          minItems={0}
          attachmentDocumentTypes={ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS}
          isRequired={IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT(standardTermsDealingNumbers, additionalCovenants)}
        />
        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
