import * as yup from 'yup';

import { CaveatModel, PARTY_BOOK_KEY } from '../../models';
import { yupParty } from './validationSchema';

export function testUnaffectedProvider(this: yup.TestContext<CaveatModel>, conatctPartyStoreId: string) {
  const caveatModel = this.options.context!;
  const isUnaffectedProprietor = caveatModel.proprietors?.some(proprietor => !proprietor.isAffected && proprietor.partyBookId === conatctPartyStoreId);
  if (!isUnaffectedProprietor) {
    // Note: Affected prioprietors should be validated in the proprietors section, where their forms will be shown inline, and field validation will be visible
    return true;
  }
  const referencedParty = caveatModel[PARTY_BOOK_KEY].find(({ id }) => id === conatctPartyStoreId)!;
  try {
    yupParty.validateSync(referencedParty, {
      path: '',
      abortEarly: true, // We don't need detailed errors here
      context: caveatModel
    } as any);
    return true;
  } catch (ex) {
    return false;
  }
}
