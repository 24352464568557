import * as React from 'react';

import { FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { SetOfOperativeWordsLookup } from '../../enums';
import { DischargeOfMortgage_2_26_0_Model } from '../../models';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
type FormValues = DischargeOfMortgage_2_26_0_Model;
const setOfOperativeWordsTitleId: string = _uniqueId();
interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function SectionSetOfOperativeWords(props: Props): JSX.Element {
  const { classes, name, disabled } = props;
  return (
    <Section //
      title="Set of operative words"
      titleId={setOfOperativeWordsTitleId}
      data-name={name}
    >
      <div className={classes.root}>
        <Field //
          aria-labelledby={setOfOperativeWordsTitleId}
          component={RadioField}
          disabled={disabled}
          name={name}
          options={SetOfOperativeWordsLookup}
          format="number"
          vertical
          className={classes.setOfOperativeWords}
        />
      </div>
    </Section>
  );
}

export default withStyles(styles)(SectionSetOfOperativeWords);
