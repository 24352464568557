import * as React from 'react';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import FinancialWorkspaceStatusBadgeNew from './FinancialWorkspaceStatusBadgeNew';

interface Props {
  workspaceStatusId: WorkspaceStatusEnum;
  isLocked?: boolean;
  size?: 'small' | 'medium';
}

function FinancialWorkspaceStatusBadgeContainer(props: Props) {
  const { workspaceStatusId, isLocked } = props;

  return <FinancialWorkspaceStatusBadgeNew isLocked={isLocked} workspaceStatusId={workspaceStatusId} />;
}

export default FinancialWorkspaceStatusBadgeContainer;
