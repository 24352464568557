import * as yup from 'yup';

import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { NoticeOfDeath2_21_1Model, SurvivingJointTenantModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// Uncomment this import if you want to use memoize
// import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

// path: survivingJointTenants
// If you want to use memoize, wrap your yup definition in memoizeSchema()
export const MAKING_APPLICATION_ERROR_MESSAGE: string = 'You must select at least 1 surviving tenant making application';

interface SurvivingJointTenantsContext {
  survivingJointTenants: NoticeOfDeath2_21_1Model['survivingJointTenants'];
}

const contextResolver = ({ survivingJointTenants }: NoticeOfDeath2_21_1Model): SurvivingJointTenantsContext => ({
  survivingJointTenants
});

const yupSurvivingJointTenants = yup
  .array<SurvivingJointTenantModel>()
  .test('Surviving Joint Tenant validation', 'Validate Surviving Joint Tenant', function test(this: yup.TestContext<SurvivingJointTenantsContext>) {
    if (!this.options.context?.survivingJointTenants || this.options.context?.survivingJointTenants.length < 2) {
      return true;
    }

    if (this.options.context?.survivingJointTenants?.every(s => !s.isMakingApplication)) {
      return this.createError({ message: MAKING_APPLICATION_ERROR_MESSAGE });
    }

    return true;
  })
  .defined();

export default memoizeSchemaWithContext(yupSurvivingJointTenants, contextResolver);
