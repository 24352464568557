import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      ...dataBindingHelper()
    }
  }),
  {
    name: 'Transferor'
  }
);
