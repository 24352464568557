import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { ConsiderationTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { TransferDocumentModel } from './models';
import Consideration from './sections/consideration';
import SectionEncumbrance from './sections/encumbrance';
import SectionTransfereeDetails from './sections/transferee-details';
import SectionTransferorDetails from './sections/transferor-details';
import { useStyles } from './styles';
import { combineTransferorsFromSelectedTitleReferences, getTransfereeDetailsFromTransferors, shouldUpdateTransfereesAsTransferors } from './util';

const fieldName = modelKey<TransferDocumentModel>();

function RootForm({
  //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<TransferDocumentModel>();
  const { values, setValues, touched, setTouched, setFieldTouched } = formikProps;
  const classes = useStyles();
  const { isDndMode, setIsDndMode } = useDndContext();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnTransferorRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnTransferorRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const formikHandleSubmit = formikProps.handleSubmit;
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (isDndMode) {
        // When drag-and-drop mode is on, field errors within the draggable sections are hidden.
        // On submit, drag-and-drop mode should be turned off so that errors can be shown.
        setIsDndMode(false);
      }
      formikHandleSubmit(e);
    },
    [formikHandleSubmit, isDndMode, setIsDndMode]
  );

  const { consideration, transferorDetails } = values;
  const isChangingMannerOfHoldingOnly = consideration.type === ConsiderationTypeEnum.ChangeInTheMannerOfHolding;
  const relinquishingProprietorCount = transferorDetails.relinquishingProprietorGroups.flatMap(group => group.parties).length;
  const handleTitleReferenceSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        // values already contains updated titles

        // Must update transferors when selected titles changes, as we should only list transferors from selected titles
        const updatedTransferorDetails = combineTransferorsFromSelectedTitleReferences(values.titleReferences);
        // When the consideration type is change in manner of holding, transferees must match the transferors, so if they do not match after the transferor update, we must update transferees too.
        const shouldUpdateTransfereesFromTransferors = shouldUpdateTransfereesAsTransferors(values.consideration.type, updatedTransferorDetails, values.transfereeDetails);
        shouldUpdateTransfereesFromTransferors && setFieldTouched(fieldName('transfereeDetails'), false, false);
        return {
          ...values,
          transferorDetails: updatedTransferorDetails,
          transfereeDetails: shouldUpdateTransfereesFromTransferors ? getTransfereeDetailsFromTransferors(updatedTransferorDetails) : values.transfereeDetails
        };
      });
    },
    [setValues, setFieldTouched]
  );

  const handleConsiderationTypeChange = useCallback(
    (e, considerationType: ConsiderationTypeEnum) => {
      const shouldUpdateTransfereesFromTransferors = shouldUpdateTransfereesAsTransferors(considerationType, values.transferorDetails, values.transfereeDetails);
      setValues(values => ({
        ...values,
        consideration: {
          ...values.consideration,
          type: considerationType
        },
        transfereeDetails: shouldUpdateTransfereesFromTransferors ? getTransfereeDetailsFromTransferors(values.transferorDetails) : values.transfereeDetails
      }));
      setTouched(
        {
          ...touched,
          consideration: {
            ...touched.consideration,
            type: true
          },
          transfereeDetails: shouldUpdateTransfereesFromTransferors ? undefined : touched.transfereeDetails
        },
        false
      );
    },
    //! FIXME this is anti-pattern, values, touched changes with each formik change,
    //! use setState callback pattern or setFieldValue instead
    //! values should never be in the list of dependencies otherwise memoization won't work
    [values, setValues, touched, setTouched]
  );

  return (
    <>
      <Form className={classes.root} onSubmit={handleSubmit}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
          focusRef={focusOnTitleReferencesRef}
        />

        <SectionTransferorDetails name={fieldName('transferorDetails')} focusRef={focusOnTransferorRef} />

        <Consideration //
          name={fieldName('consideration')}
          relinquishingProprietorCount={relinquishingProprietorCount}
          onConsiderationTypeChange={handleConsiderationTypeChange}
        />

        <SectionTransfereeDetails //
          name={fieldName('transfereeDetails')}
          isChangingMannerOfHoldingOnly={isChangingMannerOfHoldingOnly}
        />

        <SectionEncumbrance name={fieldName('encumbrance')} />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
