import queryString from 'query-string';

import {
  DocumentActionTypeEnum,
  DocumentDenyPermissionReasonCodeEnum,
  DocumentPermissionEnum,
  DocumentStatusEnum,
  FinancialStatusEnum,
  PaymentMethodEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsApiResponse, WorkspaceDirectionsStatusApiResponse, WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { DirectionModel, LoanAdvanceModel, LodgementCase, SourceFundModel } from '@sympli/api-gateway/shared';

import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { groupDocumentsByLodgementCaseId, sortingByLodgementCase } from 'src/containers/shared/document-list/helpers';
import environments from 'src/environments';
import { resolveUuid } from 'src/utils/formUtils';
import http from 'src/utils/http';
import { getTrustAccountAuthorisationRecordStatus } from '../financial/directions/components/settlement-directions-list/helpers';
import { AuthorisationDenyPermissionReasonCodeEnum, BankDetailsModel } from '../financial/directions/models';
import {
  MultiSignedDocumentModel,
  ToBeMultiSignDocumentsModel,
  ToBeSignedDocumentApiResponse,
  TrustAccountAuthorisationRecordsXmls
} from '../shared/components/signing-provider/helpers';
import { WorkspaceFile } from '../shared/components/workspace-files/models';
import { ColumnHeader, FooterItem, GridCell, GridModel, GridRow } from './components/line-items-grid/LineItemsGrid';
import { ErrorMessage, Hint } from './components/sign-error/SignError';
import {
  DocumentAttachment,
  DocumentModel,
  DocumentMultiSignApiResponse,
  DocumentMultiSignRequest,
  FinancialLineItem,
  FinancialModel,
  LodgementCaseDetailsModel,
  SignDocument,
  SignDocumentsFormModel,
  SignDocumentsFormModelMLC,
  SignDocumentsRouteParams,
  SignDocumentTypeEnum
} from './models';

export function multiSign(args: { workspaceId: string; participantId: string; isLodgementRequired: boolean; signedData: MultiSignedDocumentModel }) {
  const { workspaceId, participantId, isLodgementRequired, signedData } = args;
  const { signedDocuments, signedTrustAccountAuthorisationRecords, signedPayments, signedSourceFunds } = signedData;

  const payload: DocumentMultiSignRequest = {
    isLodgementRequired,
    signedDocuments,
    signedPayments,
    signedSourceFunds,
    signedTrustAccountAuthorisationRecords
  };
  return http.post<DocumentMultiSignRequest>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/multi-Sign`, payload);
}

export function resolveSignDocumentsLink(params: SignDocumentsRouteParams) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);
  const returnUrl = encodeURIComponent(window.location.pathname.replace(environments.BASENAME, '') + window.location.search);
  return `/workspace/${encodeURIComponent(workspaceId)}/sign-documents?${query}&returnUrl=${returnUrl}`;
}

function getHintDetails(documentDenyPermissions: WorkspaceDocumentSummaryApiResponse['documentDenyPermissions'], supportPageUrl: string): Hint | undefined {
  const signingError = documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign);

  if (!signingError) return undefined;

  switch (signingError.reasonCode.id) {
    case DocumentDenyPermissionReasonCodeEnum.DPD051_MissingSignPaymentsPermission:
    case DocumentDenyPermissionReasonCodeEnum.DPD052_MissingSignSourceFundsPermission:
      return {
        message: 'Learn more about permissions',
        linkDetails: {
          linkTo: supportPageUrl + '/docs/permissions',
          // Change `permissions` to a link
          wordSliceStart: 3
        }
      };
    case DocumentDenyPermissionReasonCodeEnum.DPD053_TotalAmountOverUserSigningLimit:
      return {
        message: 'Learn more about signing limits',
        linkDetails: {
          linkTo: supportPageUrl + '/docs/signing-docs-and-financials',
          // Change `signing limits` to a link
          wordSliceStart: 3
        }
      };
    default:
      return undefined;
  }
}

function getFinancialSignDocument(
  distributionDetail: WorkspaceDirectionsStatusApiResponse,
  documentType: SignDocumentTypeEnum.Payments | SignDocumentTypeEnum.SourceFunds,
  documentName: string,
  sectionTitle: string,
  supportPageUrl: string
): SignDocument | undefined {
  const distributionsStatus = documentType === SignDocumentTypeEnum.Payments ? distributionDetail.paymentsStatus : distributionDetail.sourceFundsStatus;

  const errorMessage =
    distributionsStatus.financialStatus === FinancialStatusEnum.Signed
      ? undefined
      : resolveSigningWarningMessage(documentType, documentName, distributionsStatus.documentDenyPermissions, distributionsStatus.distributionSummarySigners);

  const verificationErrors = errorMessage
    ? [
        {
          message: errorMessage,
          hint: getHintDetails(distributionsStatus.documentDenyPermissions, supportPageUrl)
        }
      ]
    : [];

  return {
    id: documentName.replace(/\s/g, ''),
    name: sectionTitle,
    documentStatus: distributionsStatus.status,
    isOptedOut: distributionsStatus.financialStatus === FinancialStatusEnum.NotApplicable,
    signed:
      distributionsStatus.financialStatus === FinancialStatusEnum.Signed ||
      distributionsStatus.documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id ===
        DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned,
    certificationStatements: distributionsStatus.certificationStatements,
    type: documentType,
    disabled: !distributionsStatus.documentPermissions.includes(DocumentPermissionEnum.Sign),
    verificationErrors: verificationErrors
  };
}

function addFinancialSignDocument(
  directionsDetail: WorkspaceDirectionsStatusApiResponse,
  documentType: SignDocumentTypeEnum.Payments | SignDocumentTypeEnum.SourceFunds,
  documentName: string,
  sectionTitle: string,
  documentList: SignDocument[],
  supportPageUrl: string
) {
  const paymentSignDocument = getFinancialSignDocument(directionsDetail, documentType, documentName, sectionTitle, supportPageUrl);
  if (paymentSignDocument) {
    documentList.push(paymentSignDocument);
  }
}

function getAccountDescription(bankDetailsModel: BankDetailsModel) {
  const { accountName, accountDisplayName, bsb, accountNumber } = bankDetailsModel!;

  return [accountDisplayName ?? accountName, bsb ? `BSB ${bsb}` : '', accountNumber ? `Account number ${accountNumber}` : '']
    .filter(value => value.length > 0)
    .reduce((val1, val2) => `${val1} | ${val2}`);
}

function getPaymentMethodDescription(directionModel: DirectionModel) {
  switch (directionModel.paymentMethod) {
    case PaymentMethodEnum.BankTransfer:
    case PaymentMethodEnum.TrustAccount:
    case PaymentMethodEnum.HoldingAccount:
    case PaymentMethodEnum.DirectDebit:
      return getAccountDescription(directionModel.bankDetails!);
    case PaymentMethodEnum.BPAY:
      const { billerCode, billerReference, billerName } = directionModel.bpayDetails!;
      return `${billerName} | BPAY biller code ${billerCode} | Reference number ${billerReference}`;
    default:
      return '';
  }
}

function getCategoryDescription(category: string, categoryOther: string | undefined) {
  return category === 'Other' ? categoryOther ?? category : category;
}

function mapSourceFundToFinancialLineItem(sourceFund: SourceFundModel): FinancialLineItem {
  return {
    participantId: sourceFund.participantId,
    description: getAccountDescription(sourceFund.bankDetails!),
    type: `${getCategoryDescription(sourceFund.category, sourceFund.categoryOther)}${sourceFund.bankDetails!.reference && ` (${sourceFund.bankDetails!.reference})`}`,
    addedBy: sourceFund.subscriberName,
    amount: sourceFund.amount
  };
}

function mapDirectionToFinancialLineItem(directionModel: DirectionModel): FinancialLineItem {
  return {
    participantId: directionModel.participantId!,
    description: getPaymentMethodDescription(directionModel),
    type: getCategoryDescription(directionModel.category, directionModel.categoryOther),
    addedBy: directionModel.subscriberName,
    amount: directionModel.amount
  };
}

function mapLoanAdvanceToFinancialLineItem(participantId: string, loanAdvance: LoanAdvanceModel | undefined): FinancialLineItem | undefined {
  if (!loanAdvance || loanAdvance.participantId !== participantId) {
    return undefined;
  }
  return {
    participantId: loanAdvance.participantId,
    description: loanAdvance.bankDetails ? getAccountDescription(loanAdvance.bankDetails) : '',
    type: loanAdvance.categoryReferenceDescription ?? '',
    addedBy: loanAdvance.subscriberName ?? '',
    amount: loanAdvance.amount
  };
}

function resolvePaymentLineItems(directionItems?: WorkspaceDirectionsApiResponse) {
  if (!directionItems) {
    return [];
  }
  return directionItems.directions.map(mapDirectionToFinancialLineItem);
}

function resolveSourceFundLineItems(participantId: string, directionItems?: WorkspaceDirectionsApiResponse) {
  if (!directionItems) {
    return [];
  }
  const returnValue = directionItems.sourceFunds.map(mapSourceFundToFinancialLineItem);
  const loanAdvanceFinancialLine = mapLoanAdvanceToFinancialLineItem(participantId, directionItems.loanAdvance);

  if (!loanAdvanceFinancialLine) {
    return returnValue;
  }

  return returnValue.concat(loanAdvanceFinancialLine!);
}

export function resolveLineItems(participantId: string, directionItems?: WorkspaceDirectionsApiResponse): Map<SignDocumentTypeEnum, FinancialLineItem[]> {
  let returnValue = new Map<SignDocumentTypeEnum, FinancialLineItem[]>();
  if (!directionItems) {
    return returnValue;
  }
  returnValue.set(SignDocumentTypeEnum.Payments, resolvePaymentLineItems(directionItems)!);
  returnValue.set(SignDocumentTypeEnum.SourceFunds, resolveSourceFundLineItems(participantId, directionItems)!);

  return returnValue;
}

export function resolveLodgementCaseDocuments(
  workspaceId: string,
  participantId: string,
  documents: WorkspaceDocumentSummaryApiResponse[], //
  documentAttachments: WorkspaceFile[],
  lodgementCases: LodgementCase[],
  multiSignDetail?: DocumentMultiSignApiResponse
): LodgementCaseDetailsModel[] {
  const populateDocument = (doc: WorkspaceDocumentSummaryApiResponse): DocumentModel | undefined => {
    const { documentId: id, name, certificationStatements, isSigningRole, lodgementDetail, documentDenyPermissions, requiredSigners, documentActions } = doc;
    if (!isSigningRole) {
      return;
    }

    const verificationErrors: ErrorMessage[] | undefined = multiSignDetail?.documents[id]?.flatMap(d => d.verificationErrors.map(err => ({ message: err })));

    const attachments: DocumentAttachment[] = documentAttachments
      .filter(a => a.attachedToDocumentId === id)
      .map(a => ({
        attachmentId: a.id,
        fileName: a.fileName
      }));
    const documentType = SignDocumentTypeEnum.Documents;

    return {
      id,
      name,
      signed:
        doc.documentStatus.id === DocumentStatusEnum.Signed ||
        documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id ===
          DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned,
      certificationStatements,
      disabled: verificationErrors?.length ? true : !doc.permissions.includes(DocumentPermissionEnum.Sign),
      lodgementDetail: lodgementDetail,
      signingWarningMessage: resolveSigningWarningMessage(documentType, name, documentDenyPermissions, requiredSigners, {
        documentParticipants: doc.documentParticipants,
        participantId,
        isFullySignedByAllParticipants: doc.isFullySignedByAllParticipants
      }),
      verificationErrors,
      toBeSignedByOtherSigners:
        doc.requiredSigners > 1 &&
        !doc.isFullySignedByAllParticipants &&
        (!documentActions.some(a => a.participantId === participantId && a.type === DocumentActionTypeEnum.Sign) || // Scenario 1: No one has signed the document
          documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id === // Scenario 2: Current user has signed the document
            DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned),
      attachments,
      documentStatus: doc.documentStatus.id,
      // resolve link for users with Write permission only
      resolveIssuesLink: doc.permissions.includes(DocumentPermissionEnum.Write)
        ? resolveDocumentDetailLink({
            workspaceId,
            participantId,
            documentId: id
          })
        : undefined
    };
  };

  const groupedDocumentsByLodgementCaseId = groupDocumentsByLodgementCaseId(documents);

  const lodgementCaseDetailModels: LodgementCaseDetailsModel[] = [];
  for (let [key, value] of groupedDocumentsByLodgementCaseId) {
    const lodgementCaseDetail = lodgementCases.find(x => x.id === key);
    // add documents and supported docs
    const documentList: DocumentModel[] = [];
    // allow display the doc has the signing role
    value.forEach(doc => {
      const docDetail = populateDocument(doc);
      if (docDetail) {
        documentList.push(docDetail);
      }
      if (doc.supportingDocuments) {
        doc.supportingDocuments.forEach(supportDoc => {
          const supportDocDetail = populateDocument(supportDoc);
          if (supportDocDetail) {
            documentList.push(supportDocDetail);
          }
        });
      }
    });

    const lodgementCaseFees = multiSignDetail?.lodgementCaseFees?.find(x => x.lodgementCaseId === key);

    lodgementCaseDetailModels.push({
      lodgementCase: lodgementCaseDetail!,
      documents: documentList,
      lodgementCaseFees
    });
  }

  // sort by the lodgement case created date
  sortingByLodgementCase(lodgementCaseDetailModels);

  return lodgementCaseDetailModels;
}

export function resolveFinancials(supportPageUrl: string, directionsDetail: WorkspaceDirectionsStatusApiResponse): FinancialModel[] {
  // re-use the same signDocumentModel to avoid logic re-write
  const documentList: SignDocument[] = [];

  // Add payments docs
  addFinancialSignDocument(directionsDetail, SignDocumentTypeEnum.Payments, 'Financial Payments', 'My Payments', documentList, supportPageUrl);

  // Add source funds docs
  addFinancialSignDocument(directionsDetail, SignDocumentTypeEnum.SourceFunds, 'Financial Source Funds', 'My Sources', documentList, supportPageUrl);

  const { trustAccountAuthorisationRecordStatuses } = directionsDetail;
  if (trustAccountAuthorisationRecordStatuses) {
    trustAccountAuthorisationRecordStatuses.forEach(ta => {
      const documentName = `Trust account auth. record - ${ta.accountName}`;
      const documentType = SignDocumentTypeEnum.TrustAccountAuthorisationRecord;
      const signDenyReasonCode = ta.documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id;
      const { description } = getTrustAccountAuthorisationRecordStatus(ta.status, ta.documentActions, ta.requiredSigners);

      documentList.push({
        id: ta.accountId,
        name: documentName,
        documentStatus: ta.status,
        documentDescription: description,
        signed:
          ta.status === DocumentStatusEnum.Signed ||
          signDenyReasonCode === AuthorisationDenyPermissionReasonCodeEnum.APD02_HasBeenSignedCannotSignAgain ||
          signDenyReasonCode === AuthorisationDenyPermissionReasonCodeEnum.APD03_OneOfTheSignersCannotSignAgain,
        certificationStatements: ta.certificationStatements,
        acknowledgementStatements: ta.acknowledgementStatements,
        type: documentType,
        disabled: !ta.documentPermissions.includes(DocumentPermissionEnum.Sign),
        signingWarningMessage: resolveSigningWarningMessage(documentType, documentName, ta.documentDenyPermissions, ta.requiredSigners, {
          isFullySignedByAllParticipants: ta.status === DocumentStatusEnum.Signed
        }),
        totalAmount: ta.totalAmount,
        accountDescription: ta.accountName
      });
    });
  }

  return documentList.map(
    d =>
      ({
        id: d.id,
        name: d.name,
        type: d.type,
        signed: d.signed,
        certificationStatements: d.certificationStatements,
        acknowledgementStatements: d.acknowledgementStatements,
        disabled: d.disabled,
        toBeSignedByOtherSigners: d.toBeSignedByOtherSigners,
        signingWarningMessage: d.signingWarningMessage,
        verificationErrors: d.verificationErrors,
        totalAmount: d.totalAmount,
        accountDescription: d.accountDescription,
        documentStatus: d.documentStatus,
        documentDescription: d.documentDescription,
        isOptedOut: d.isOptedOut
      }) satisfies FinancialModel
  );
}

export function resolveSignDocuments(
  workspaceId: string,
  participantId: string,
  documents: WorkspaceDocumentSummaryApiResponse[], //
  documentAttachments: WorkspaceFile[],
  supportPageUrl: string,
  directionsDetail?: WorkspaceDirectionsStatusApiResponse,
  multiSignDetail?: DocumentMultiSignApiResponse
): SignDocument[] {
  const documentList: SignDocument[] = [];

  const populateDocuments = (doc: WorkspaceDocumentSummaryApiResponse) => {
    const { documentId: id, name, certificationStatements, isSigningRole, lodgementDetail, documentDenyPermissions, requiredSigners, documentActions } = doc;
    if (!isSigningRole) {
      return;
    }

    const verificationErrors: ErrorMessage[] | undefined = multiSignDetail?.documents[id]?.flatMap(d => d.verificationErrors.map(err => ({ message: err })));

    const attachments: DocumentAttachment[] = documentAttachments
      .filter(a => a.attachedToDocumentId === id)
      .map(a => ({
        attachmentId: a.id,
        fileName: a.fileName
      }));
    const documentType = SignDocumentTypeEnum.Documents;
    documentList.push({
      id,
      name,
      signed:
        doc.documentStatus.id === DocumentStatusEnum.Signed ||
        documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id ===
          DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned,
      certificationStatements,
      type: documentType,
      disabled: verificationErrors?.length ? true : !doc.permissions.includes(DocumentPermissionEnum.Sign),
      documentLodgementCompliances: lodgementDetail?.documentLodgementCompliances,
      signingWarningMessage: resolveSigningWarningMessage(documentType, name, documentDenyPermissions, requiredSigners, {
        documentParticipants: doc.documentParticipants,
        participantId,
        isFullySignedByAllParticipants: doc.isFullySignedByAllParticipants
      }),
      verificationErrors,
      toBeSignedByOtherSigners:
        doc.requiredSigners > 1 &&
        !doc.isFullySignedByAllParticipants &&
        (!documentActions.some(a => a.participantId === participantId && a.type === DocumentActionTypeEnum.Sign) || // Scenario 1: No one has signed the document
          documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id === // Scenario 2: Current user has signed the document
            DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned),
      attachments,
      documentStatus: doc.documentStatus.id,
      // resolve link for users with Write permission only
      resolveIssuesLink: doc.permissions.includes(DocumentPermissionEnum.Write)
        ? resolveDocumentDetailLink({
            workspaceId,
            participantId,
            documentId: id
          })
        : undefined
    });
  };
  // add documents and supported docs
  documents.forEach(doc => {
    populateDocuments(doc);
    if (doc.supportingDocuments) {
      doc.supportingDocuments.forEach(populateDocuments);
    }
  });

  if (!directionsDetail) {
    return documentList;
  }

  // Add payments docs
  addFinancialSignDocument(directionsDetail, SignDocumentTypeEnum.Payments, 'Financial Payments', 'My Payments', documentList, supportPageUrl);

  // Add source funds docs
  addFinancialSignDocument(directionsDetail, SignDocumentTypeEnum.SourceFunds, 'Financial Source Funds', 'My Sources', documentList, supportPageUrl);

  const { trustAccountAuthorisationRecordStatuses } = directionsDetail;
  if (trustAccountAuthorisationRecordStatuses) {
    trustAccountAuthorisationRecordStatuses.forEach(ta => {
      const documentName = `Trust account auth. record - ${ta.accountName}`;
      const documentType = SignDocumentTypeEnum.TrustAccountAuthorisationRecord;
      const signDenyReasonCode = ta.documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign)?.reasonCode.id;
      const { description } = getTrustAccountAuthorisationRecordStatus(ta.status, ta.documentActions, ta.requiredSigners);

      documentList.push({
        id: ta.accountId,
        name: documentName,
        documentStatus: ta.status,
        documentDescription: description,
        signed:
          ta.status === DocumentStatusEnum.Signed ||
          signDenyReasonCode === AuthorisationDenyPermissionReasonCodeEnum.APD02_HasBeenSignedCannotSignAgain ||
          signDenyReasonCode === AuthorisationDenyPermissionReasonCodeEnum.APD03_OneOfTheSignersCannotSignAgain,
        certificationStatements: ta.certificationStatements,
        acknowledgementStatements: ta.acknowledgementStatements,
        type: documentType,
        disabled: !ta.documentPermissions.includes(DocumentPermissionEnum.Sign),
        signingWarningMessage: resolveSigningWarningMessage(documentType, documentName, ta.documentDenyPermissions, ta.requiredSigners, {
          isFullySignedByAllParticipants: ta.status === DocumentStatusEnum.Signed
        }),
        totalAmount: ta.totalAmount,
        accountDescription: ta.accountName
      } satisfies SignDocument);
    });
  }

  return documentList;
}

export function resolveToBeMultiSignDocumentsModelMLC(formValues: SignDocumentsFormModelMLC, response: DocumentMultiSignApiResponse): ToBeMultiSignDocumentsModel {
  const selectedDocs = formValues.documents.filter(doc => doc.isSelected).map(d => d);
  const documents: ToBeSignedDocumentApiResponse[] = selectedDocs
    .filter(d => d.type === SignDocumentTypeEnum.Documents)
    .map(d => ({
      documentId: d.id,
      executionItems: response.documents[d.id]
    }));
  const paymentXmlStrings = selectedDocs.some(d => d.type === SignDocumentTypeEnum.Payments) ? response.paymentXmlStrings : [];
  const sourceFundXmlStrings = selectedDocs.some(d => d.type === SignDocumentTypeEnum.SourceFunds) ? response.sourceFundXmlStrings : [];
  const trustAccountAuthorisationRecordsXmlStrings: TrustAccountAuthorisationRecordsXmls[] = selectedDocs
    .filter(d => d.type === SignDocumentTypeEnum.TrustAccountAuthorisationRecord)
    .map(d => ({
      trustAccountId: d.id,
      xmlString: response.trustAccountAuthorisationRecordsXmlStrings[d.id]
    }));

  return {
    documents,
    trustAccountAuthorisationRecordsXmlStrings,
    paymentXmlStrings,
    sourceFundXmlStrings
  };
}

export function resolveToBeMultiSignDocumentsModel(formValues: SignDocumentsFormModel, response: DocumentMultiSignApiResponse): ToBeMultiSignDocumentsModel {
  const selectedDocs = formValues.documents.filter(doc => doc.isSelected).map(d => d.data);
  const documents: ToBeSignedDocumentApiResponse[] = selectedDocs
    .filter(d => d.type === SignDocumentTypeEnum.Documents)
    .map(d => ({
      documentId: d.id,
      executionItems: response.documents[d.id]
    }));
  const paymentXmlStrings = selectedDocs.some(d => d.type === SignDocumentTypeEnum.Payments) ? response.paymentXmlStrings : [];
  const sourceFundXmlStrings = selectedDocs.some(d => d.type === SignDocumentTypeEnum.SourceFunds) ? response.sourceFundXmlStrings : [];
  const trustAccountAuthorisationRecordsXmlStrings: TrustAccountAuthorisationRecordsXmls[] = selectedDocs
    .filter(d => d.type === SignDocumentTypeEnum.TrustAccountAuthorisationRecord)
    .map(d => ({
      trustAccountId: d.id,
      xmlString: response.trustAccountAuthorisationRecordsXmlStrings[d.id]
    }));

  return {
    documents,
    trustAccountAuthorisationRecordsXmlStrings,
    paymentXmlStrings,
    sourceFundXmlStrings
  };
}

function resolveSigningWarningMessage(
  documentType: SignDocumentTypeEnum,
  documentName: string,
  documentDenyPermissions: WorkspaceDocumentSummaryApiResponse['documentDenyPermissions'],
  requiredSigners: number,
  options?: {
    documentParticipants?: WorkspaceDocumentSummaryApiResponse['documentParticipants'];
    isFullySignedByAllParticipants?: boolean;
    participantId?: string;
  }
): string | undefined {
  if (options?.isFullySignedByAllParticipants) {
    return undefined;
  }
  const signingError = documentDenyPermissions.find(p => p.documentPermissionType === DocumentPermissionEnum.Sign);

  if (!signingError) return undefined;
  switch (documentType) {
    case SignDocumentTypeEnum.Documents:
    case SignDocumentTypeEnum.FinancialSettlementSchedule:
    case SignDocumentTypeEnum.Payments:
    case SignDocumentTypeEnum.SourceFunds:
      switch (signingError.reasonCode.id) {
        case DocumentDenyPermissionReasonCodeEnum.DPD003_MissingSignDocumentPermission:
        case DocumentDenyPermissionReasonCodeEnum.DPD012_MissingSignFinancialsPermission:
          return `You do not have permission to sign ${documentName}`;
        case DocumentDenyPermissionReasonCodeEnum.DPD026_OnlyAllowResponsibleParticipantSignLodgementInstructions:
          return `Only the responsible subscriber can sign ${documentName}`;
        case DocumentDenyPermissionReasonCodeEnum.DPD034_SeparateSigner:
        case DocumentDenyPermissionReasonCodeEnum.DPD036_SeparateApproverAndSigner:
          return `A separate signer is required to sign ${documentName}`;
        case DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned:
          return requiredSigners === 1 ? undefined : `You are one of the signers for ${documentName}, you cannot sign again`;
        case DocumentDenyPermissionReasonCodeEnum.DPD040_OtherParticipantMustReviewFirst:
          const awaitingParticipant = options?.documentParticipants?.find(p => p.id !== options?.participantId && p.participantStatus.id < DocumentStatusEnum.Reviewing);
          if (awaitingParticipant) {
            const workspaceRoleName = awaitingParticipant.workspaceRole.name.toLocaleLowerCase();
            return `The ${workspaceRoleName} must approve ${documentName} before you can sign`;
          }
          return undefined;
        case DocumentDenyPermissionReasonCodeEnum.DPD046_LodgementInstructionsPendingParticipantsAcceptingInvites:
          return `All participants invited must accept invitation before you can sign ${documentName}`;
        case DocumentDenyPermissionReasonCodeEnum.DPD051_MissingSignPaymentsPermission:
          return 'You do not have permission to sign Total Payments';
        case DocumentDenyPermissionReasonCodeEnum.DPD052_MissingSignSourceFundsPermission:
          return 'You do not have permission to sign Total Sources';
        case DocumentDenyPermissionReasonCodeEnum.DPD053_TotalAmountOverUserSigningLimit:
          return `The ${documentName} exceed your signing limit`;
        default:
          return undefined;
      }

    case SignDocumentTypeEnum.TrustAccountAuthorisationRecord:
      switch (signingError.reasonCode.id) {
        case AuthorisationDenyPermissionReasonCodeEnum.APD01_MissingSignDocumentPermission:
          return `You do not have permission to sign ${documentName}`;
        case AuthorisationDenyPermissionReasonCodeEnum.APD03_OneOfTheSignersCannotSignAgain:
          return `You are one of the signers for ${documentName}, you cannot sign again`;
        default:
          return undefined;
      }
  }
}

function formatAmount(amount: number, showDollarSign: boolean = false): string {
  return `${showDollarSign ? '$' : ''}${amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
}

function getGridTitle(itemType: SignDocumentTypeEnum, lineItemRecords: FinancialLineItem[]): string | undefined {
  switch (itemType) {
    case SignDocumentTypeEnum.Payments:
      return 'My Payments';
    case SignDocumentTypeEnum.SourceFunds:
      return 'My Sources';
    case SignDocumentTypeEnum.SLCFeeBreakdown:
      return 'Fee Breakdown';
    default:
      return undefined;
  }
}

function getGridFooterTotal(itemType: SignDocumentTypeEnum): string | undefined {
  switch (itemType) {
    case SignDocumentTypeEnum.Payments:
      return 'Total Payments:';
    case SignDocumentTypeEnum.SourceFunds:
      return 'Total Sources:';
    case SignDocumentTypeEnum.SLCFeeBreakdown:
      return 'Total:';
    default:
      return undefined;
  }
}

function getGridHeader(itemType: SignDocumentTypeEnum): ColumnHeader[] {
  return [
    {
      id: 'details',
      name: `${itemType === SignDocumentTypeEnum.Payments ? 'Account' : 'Details'}`,
      width: 5,
      uuid: resolveUuid('string', 20)
    },
    { id: 'type', name: 'Type', width: 3, uuid: resolveUuid('string', 20) },
    { id: 'addedBy', name: 'Added by', width: 2, uuid: resolveUuid('string', 20) },
    { id: 'amount', name: 'Amount ($)', width: 2, uuid: resolveUuid('string', 20) }
  ];
}

function getGridRows(lineItemRecords: FinancialLineItem[]): GridRow[] {
  if (lineItemRecords.length === 0) lineItemRecords.push({ description: '-', participantId: '', type: '-', addedBy: '-', amount: 0 });

  return lineItemRecords.map(item => {
    const mappedObject: GridCell[] = [
      { value: item.description, uuid: resolveUuid('string', 20) },
      { value: item.type, uuid: resolveUuid('string', 20) },
      { value: item.addedBy, uuid: resolveUuid('string', 20) },
      { value: formatAmount(item.amount, false), uuid: resolveUuid('string', 20) }
    ];

    return {
      cells: mappedObject,
      uuid: resolveUuid('string', 20)
    };
  });
}

function getGridFooter(itemType: SignDocumentTypeEnum, lineItemRecords: FinancialLineItem[]): FooterItem[] {
  const total = lineItemRecords.map(item => item.amount).reduce((prev, current) => prev + current, 0);

  return [
    { width: 8, value: '', uuid: resolveUuid('string', 20) },
    { width: 2, value: getGridFooterTotal(itemType) ?? '', uuid: resolveUuid('string', 20) },
    { width: 2, value: formatAmount(total, true), uuid: resolveUuid('string', 20) }
  ];
}

export function getGridModel(records: FinancialLineItem[], documentType: SignDocumentTypeEnum): GridModel {
  return {
    title: getGridTitle(documentType, records),
    header: getGridHeader(documentType),
    rows: getGridRows(records),
    footer: getGridFooter(documentType, records),
    uuid: resolveUuid('string', 20)
  };
}
