import queryString from 'query-string';

import { JurisdictionsEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import Logger from '@sympli/ui-logger';

import http from 'src/utils/http';
import { RepresentationSubscriberTypeEnum } from '../../../../models';
import { SubscriberSearchApiResponse, SubscriberSearchItemModel } from './models';

export const getSubscriberSuggestion = async (
  name: string,
  role: WorkspaceRoleEnum,
  jurisdiction: JurisdictionsEnum,
  isInteroperable?: boolean,
  representationSubscriberType?: RepresentationSubscriberTypeEnum
): Promise<SubscriberSearchApiResponse> => {
  try {
    let response: SubscriberSearchApiResponse;
    let query = queryString.stringify({ role, jurisdiction, isInteroperable, representationSubscriberType });
    let url = '/workspaces/invitation-subscribers/';
    response = await http.get<SubscriberSearchApiResponse>(`${url}${encodeURIComponent(name)}?${query}`);
    // External subscribers will not have an Id, Sympli subscribers will not have an ElnoSubscriberId
    return response.map(({ id, elnoSubscriberId, name, elnoId, abn }: SubscriberSearchItemModel) => {
      return { id: id ?? elnoSubscriberId, name, elnoId, elnoSubscriberId, abn };
    });
  } catch (e) {
    Logger.captureException(e);
    throw e;
  }
};
