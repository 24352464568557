import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { VicPartyForm_2_25 as PartyForm } from '@sympli-mfe/document-components/party-form/vic/2-25';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { VIC_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { TenancyPartyModel, TransferDocumentModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function Transferor({ name, focusRef }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<TransferDocumentModel>();
  const { values } = formikProps;
  const { disabled } = useDocumentContext();
  const transferor: TenancyPartyModel = _get(values, name);
  const { partyBook = [] } = values;
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === transferor.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  return (
    <div data-name={name} className={classes.root}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={VIC_TRANSFER_PARTY_FORM_CONFIG}
        focusRef={focusRef}
      />
    </div>
  );
}

export default React.memo(Transferor);
