import makeStyles from '@mui/styles/makeStyles';

// this file was automatically generated from sections/styles.ts.mustache
export const useStyles = makeStyles(
  () => ({
    root: {}
  }),
  {
    name: 'CaveatorPartyReadonlyArray'
  }
);
