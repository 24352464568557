import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: 24
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
      fontSize: 16,
      fontWeight: 'bold'
    },
    unverified: {
      color: theme.palette.secondary.main
    },
    verified: {
      color: theme.palette.primary.main
    },
    verifiedIcon: {
      width: 20,
      height: 20,
      marginRight: 4,
      marginLeft: -24
    },
    fullWidth: {
      width: '100%'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
