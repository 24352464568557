import TopPanel from 'src/components/layout/v2/TopPanel';

interface SettingsPageLayoutProps {
  sidePanel: React.ReactNode;
}

function SettingsPageLayout({ sidePanel, children }: React.PropsWithChildren<SettingsPageLayoutProps>) {
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .header */}
      <TopPanel />

      <div className="grid grid-cols-[auto_1fr] grid-rows-1 overflow-y-hidden">
        <div
          style={{ borderRight: '1px solid #EDEDED' }}
          className="overflow-y-auto overflow-x-hidden pl-[calc(calc(100vw_-_var(--small-content))_/_2)] medium-screen:pl-[calc(calc(100vw_-_var(--medium-content))_/_2)] large-screen:pl-[calc(calc(100vw_-_var(--large-content))_/_2)]"
        >
          <div className="box-border h-full w-[304px] overflow-x-auto  bg-[#FAFAFA] px-[8px] pt-[32px]">{sidePanel}</div>
        </div>

        <div
          data-scroll-to-error-offset="60" // this is height of workflow panel
          className="tickle-scroll-to-error bg-zinc-100 overflow-y-auto pr-[calc(calc(100vw_-_var(--small-content))_/_2)] medium-screen:pr-[calc(calc(100vw_-_var(--medium-content))_/_2)] large-screen:pr-[calc(calc(100vw_-_var(--large-content))_/_2)]"
        >
          {children}
        </div>
      </div>
    </div>
  );
}
export default SettingsPageLayout;
