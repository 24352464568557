import * as React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field/InputField';

import { IndividualTransferee, OrganisationTransferee } from '../../components/transferee';
import { ADDRESS_BOOK_KEY, Form24A_2_24_1Model, TransfereeDataModel, TransfereeReferenceModel } from '../../models';
import { Context } from '../../RootForm';
import { VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL } from '../../visibilityCheck';
import { useStyles } from './styles';

type FormValues = Form24A_2_24_1Model;

function TransfereeDetails() {
  const { values } = useFormikContext<FormValues>();
  const { disabled, dialogPortalId } = useDocumentContext();
  const context = useRootFormContext<Context>();
  const classes = useStyles();

  const { transfereeDetails } = values;
  const fieldName = createModelKeyAppender<TransfereeDataModel>('transfereeDetails');
  const focusRef = React.useRef<ButtonBaseActions>(null);

  const renderTransfereeItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<TransfereeReferenceModel>) => {
    return VISIBILITY_CHECK_TRANSFEREE_IS_INDIVIDUAL(item.transferee, values) ? (
      <IndividualTransferee name={itemBinding} transferee={item} />
    ) : (
      <OrganisationTransferee name={itemBinding} transferee={item} />
    );
  };

  const renderTransferees = () => {
    const isEmpty = !transfereeDetails.transferees.length;

    if (isEmpty) {
      return (
        <FormGroup title="Individual or Organisation">
          <ReadOnlyField //
            label="Name"
            value=""
          />
        </FormGroup>
      );
    }

    return (
      <DocumentFieldArray //
        arrayBinding={fieldName('transferees')}
        renderItem={renderTransfereeItem}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="none"
        focusRef={focusRef}
        mode="fixed"
      />
    );
  };

  return (
    <Section title="Transferee details">
      {renderTransferees()}

      <FormGroup title="Contact details after possession" fieldAreaDirection="column" className={classes.topBorder}>
        <Field //
          label="Phone"
          name={fieldName('phone')}
          format="string"
          component={InputField}
          disabled={disabled}
        />
        <AddressSelectField
          name={fieldName('postalAddressId')}
          disabled={disabled}
          bookRef={ADDRESS_BOOK_KEY}
          dialogPortalId={dialogPortalId}
          sharedAddressFormConfig={context.postalAddressFormConfig}
          sharedInitialValuesForNew={context.addressFormInitialValues}
          label="Postal address"
        />
        <Field //
          label="Email"
          name={fieldName('email')}
          format="string"
          component={InputField}
          fullWidth
          disabled={disabled}
        />
      </FormGroup>
      <FormGroup title="Name of trust">
        <Field //
          name={fieldName('trustName')}
          format="string"
          placeholder="Please provide name if applicable"
          component={InputField}
          fullWidth
          disabled={disabled}
        />
      </FormGroup>
    </Section>
  );
}

export default React.memo(TransfereeDetails);
