import { call, put, takeLatest } from 'typed-redux-saga';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import http from 'src/utils/http';
import { actionFetchJurisdictionDetail, actionFetchJurisdictionRevenueOffice, actionFetchJurisdictionsFeed, actionFetchJurisdictionSummaryFeed } from '../actions/jurisdictions';
import { JurisdictionApiResponse, JurisdictionDetailApiResponse, JurisdictionRevenueOfficeApiResponse } from '../jurisdictions/models';

export const fetchJurisdictionsSummary = () => http.get<JurisdictionApiResponse[]>('/settings/jurisdiction-summary');

export function* sagaFetchJurisdictionsSummaryFeed() {
  try {
    const data = yield* call(fetchJurisdictionsSummary);

    yield put(actionFetchJurisdictionSummaryFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionSummaryFeed.failure({ error }));
  }
}

export const fetchJurisdictions = () => http.get<JurisdictionsEnum[]>('/settings/jurisdictions');

export function* sagaFetchJurisdictionsFeed() {
  try {
    const data = yield* call(fetchJurisdictions);

    yield put(actionFetchJurisdictionsFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionsFeed.failure({ error }));
  }
}

const fetchJurisdictionDetail = (jurisdictionId: JurisdictionsEnum) => http.get<JurisdictionDetailApiResponse>(`/settings/land-registries/${jurisdictionId}`);

export function* sagaFetchJurisdictionDetail(action: ReturnType<typeof actionFetchJurisdictionDetail.request>) {
  try {
    const data = yield* call(fetchJurisdictionDetail, action.payload);
    yield put(actionFetchJurisdictionDetail.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionDetail.failure({ error }));
  }
}

const fetchJurisdictionRevenueOffice = (jurisdictionId: JurisdictionsEnum) => http.get<JurisdictionRevenueOfficeApiResponse>(`/settings/revenue-offices/${jurisdictionId}`);

export function* sagaFetchJurisdictionRevenueOffice(action: ReturnType<typeof actionFetchJurisdictionRevenueOffice.request>) {
  try {
    const data = yield* call(fetchJurisdictionRevenueOffice, action.payload);
    yield put(actionFetchJurisdictionRevenueOffice.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionRevenueOffice.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchJurisdictionSummaryFeed.request, sagaFetchJurisdictionsSummaryFeed),
  takeLatest(actionFetchJurisdictionsFeed.request, sagaFetchJurisdictionsFeed),
  takeLatest(actionFetchJurisdictionDetail.request, sagaFetchJurisdictionDetail),
  takeLatest(actionFetchJurisdictionRevenueOffice.request, sagaFetchJurisdictionRevenueOffice)
];
