import * as React from 'react';

import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

interface Props {
  title: string;
  message?: string;
  description?: string | JSX.Element;
}

function Summary({ title, message, description }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="subtitle1">
        {title}
      </Typography>
      {message && <Typography className={classes.message}>{message}</Typography>}
      {description && <Typography className={classes.description}>{description}</Typography>}
    </div>
  );
}

export default React.memo(Summary);
