import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';

import { RolePermissionsEnum } from '@sympli/api-gateway/enums';

import DetailContainerWrapper from 'src/containers/settings/components/detail-container-wrapper';
import { useDetailPostSubmitHandler, useRedirectHandler } from 'src/containers/settings/hooks';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import ProfileContext, { ProfileContextModel } from 'src/containers/shared/auth/profile-security/Profile.context';
import Secured from 'src/containers/shared/auth/profile-security/Secured';
import environments from 'src/environments';
import { useFeatureFlag, useRouterParams, useStoreSelector } from 'src/hooks';
import { actionFetchUserDetails } from '../../../actions/users';
import UserMoreActionButton from '../../../users-setting/components/user-more-action-button';
import { UserDetailsPageTabEnum } from '../../models';
import { USERS_DEFAULT_ROUTE } from '../constants';
import { UserDetailsFormModel } from '../models';
import PersonalDetailsFormik from './PersonalDetailsFormik';

interface RouteParams {
  userId: string;
}

interface PersonalDetailsContainerProps {
  loggedInUserIsSuperAdmin: boolean;
}

function PersonalDetailsContainer(props: PersonalDetailsContainerProps) {
  const { loggedInUserIsSuperAdmin } = props;
  const navigate = useNavigate();
  const redirectBack = useRedirectHandler(USERS_DEFAULT_ROUTE);
  const dispatch = useDispatch();
  const { userId } = useRouterParams<RouteParams>();
  const isEditMode = userId !== 'new';
  const { detail, error, status } = useStoreSelector(store => store.userDetails);

  React.useEffect(() => {
    if (userId === 'new') {
      // for existing users, the fetch action is already done in parent components(UsersDetailContainer)
      dispatch(actionFetchUserDetails.request(userId));
    }
  }, [dispatch, userId]);

  const handleOnPostSubmit = useDetailPostSubmitHandler<UserDetailsFormModel, string>(
    'Personal details',
    args => {
      if (!args.error) {
        dispatch(actionFetchUserDetails.request(userId));
        redirectBack();

        if (!isEditMode) {
          // redirect to permissions tab
          navigate(`/settings/users/${encodeURIComponent(args.response)}/${UserDetailsPageTabEnum.Permissions}`);
        } else {
          redirectBack();
        }
      }
    },
    { transitionComponent: p => <Slide {...p} direction="down" />, transitionDuration: 300 }
  );

  const editUserEmailEnabled = useFeatureFlag(FeatureToggleEnum.editUserEmail);

  return (
    <DetailContainerWrapper //
      header={isEditMode ? 'Personal details' : 'Add new user'}
      status={status}
      error={error}
    >
      {() => {
        const wrapSaveButton = (button: JSX.Element, position: 'top' | 'bottom') => {
          const securedButton = <Secured requiredRolePermissions={RolePermissionsEnum.AddUsers}>{button}</Secured>;
          const wrapSecureButton = detail!.role ? (
            <ProfileContext.Consumer>
              {(
                profile: ProfileContextModel //
              ) => profile!.role <= detail!.role! && securedButton}
            </ProfileContext.Consumer>
          ) : (
            securedButton
          );

          if (position === 'top') {
            return (
              <>
                {wrapSecureButton}
                <UserMoreActionButton userId={userId} />
              </>
            );
          }
          return <>{wrapSecureButton}</>;
        };

        return (
          <ProfileContext.Consumer>
            {(profile: ProfileContextModel) => (
              <PersonalDetailsFormik //
                initialValues={{ ...detail!, profile }}
                routeUserId={userId}
                onCancel={redirectBack}
                isEditMode={isEditMode}
                editUserEmailEnabled={editUserEmailEnabled}
                loggedInUserIsSuperAdmin={loggedInUserIsSuperAdmin}
                onPostSubmit={handleOnPostSubmit}
                wrapSaveButton={wrapSaveButton}
                environmentName={environments.APP_ENV}
              />
            )}
          </ProfileContext.Consumer>
        );
      }}
    </DetailContainerWrapper>
  );
}

export default React.memo(PersonalDetailsContainer);
