import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '20px 0px',
      background: theme.palette.grey[50]
    },
    backButton: {
      marginTop: 30
    },
    backgroundWhite: {
      background: '#FFF'
    },
    messageContainer: {
      marginTop: 10,
      textAlign: 'center'
    }
  }),
  {
    name: 'Content'
  }
);
