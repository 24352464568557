import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dialog: {
      paddingRight: 35,
      paddingLeft: 35
    },
    userIcon: {
      fill: theme.palette.primary.main,
      width: 26,
      height: 26
    },
    groupIcon: {
      color: theme.palette.secondary.main,
      height: 26,
      width: 26
    },
    headingText: {
      fontWeight: 'bold',
      marginLeft: 8,
      fontSize: 17
    },
    selectText: {
      fontWeight: 'bold',
      marginBottom: 5
    },
    message: {
      fontSize: 13
    },
    content: {
      height: 55,
      width: 285
    },
    contentWithError: {
      width: 285
    },
    stepper: {
      marginTop: 15,
      paddingBottom: 0
    },
    menuPaper: {
      maxWidth: 285
    },
    menuItem: {
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      fontSize: 15
    },
    dropDown: {
      width: '100%',
      fontSize: 14
    },
    loader: {
      width: '50%',
      float: 'right'
    }
  }),
  {
    name: 'ReassignWorkspaceGroupDialog'
  }
);
