import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    container: {
      display: 'flex'
    },
    option: {
      flex: '50%',
      margin: 0
    },
    radio: {
      '& div label': {
        maxWidth: 48
      }
    },
    errorMessage: {
      width: '100%'
    }
  }),
  {
    name: 'PropertyData'
  }
);
