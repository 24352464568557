import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { ConversationDetailFormModel } from '../views/new-message/models';

export default yup.object<ConversationDetailFormModel>({
  subject: yup.string().trim().required(msg.REQUIRED).max(100, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(100)),
  message: yup.string().trim().required(msg.REQUIRED)
});
