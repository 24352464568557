import { Action, createReducer } from 'typesafe-actions';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { actionClearFeatureFlagsControlledByUI, actionUpdateFeatureFlagsControlledByUI } from '../actions';

/*
  This is the redux data where we set feature flags from UI, 
  by hitting Update button in FeatureFlagsController.
  it only resets when we clicks the Reset button or refresh the page.
*/

interface FeatureFlagsEnabledByUIState {
  items: Map<FeatureToggleEnum, boolean>;
}

const initialState: FeatureFlagsEnabledByUIState = {
  items: new Map()
};

const reducer = createReducer<
  //
  FeatureFlagsEnabledByUIState,
  Action
>(initialState)
  .handleAction(actionUpdateFeatureFlagsControlledByUI, (state, action): FeatureFlagsEnabledByUIState => {
    const newItems = new Map(state.items);
    // loop through the payload to update
    action.payload.map(flag => newItems.set(flag.name, flag.status));

    return {
      items: newItems
    };
  })
  .handleAction(actionClearFeatureFlagsControlledByUI, (): FeatureFlagsEnabledByUIState => {
    return {
      items: new Map()
    };
  });

export default reducer;
