import * as yup from 'yup';

import { Mortgage2_21_1Model } from './models';
import yupAdditionalAttachments from './sections/document-attachments/validationSchema';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTermsAndConditions from './sections/terms-and-conditions/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<Mortgage2_21_1Model>({
    titleReferences: yupTitleReferences,
    mortgagees: yupMortgagees,
    termsAndConditions: yupTermsAndConditions,
    additionalAttachments: yupAdditionalAttachments,
    mortgagors: yupMortgagors,
    partyBook: yupPartyBook,
    isFinancialWorkspace: yup.boolean().defined()
  })
  .defined()
  .log();
