import React from 'react';

import { Form, FormikProps } from 'formik';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Fieldset from '@sympli/ui-framework/components/fieldset';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import { DocumentRoleMappingLookupModel } from 'src/containers/dashboard/components/create-new-workspace/models';
import { modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { SLCDocumentLists } from '../../models';
import { AddDocumentFormikModel, AddWorkspaceDocumentApiResponse } from '../document-list-add/models';
import styles, { ClassKeys } from './styles';
import { validationSchema } from './validationSchema';

const fieldName = modelKey<AddDocumentFormikModel>();

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  documentTypes: DocumentRoleMappingLookupModel[] | null;
  isOpen: boolean;
  initialValues: AddDocumentFormikModel;
  onSave(values: AddDocumentFormikModel): Promise<AddWorkspaceDocumentApiResponse | undefined>;
  onClose(): void;
}

function AddDocumentDialog({
  //
  documentTypes,
  isOpen,
  initialValues,
  onSave,
  onClose,
  children,
  classes
}: Props & WithStyles<ClassKeys>) {
  const dialogClasses = React.useMemo(
    () => ({
      //
      root: classes.dialogRoot,
      dialogPaper: classes.dialogPaper,
      dialogTitle: classes.dialogTitle,
      dialogContent: classes.dialogContent
    }),
    [classes]
  );

  const [error, setError] = React.useState<string | undefined>(undefined);

  const addDocument = async (values: AddDocumentFormikModel) => {
    const result = await onSave(values);
    if (!result?.isSuccess && result!.titleReferenceErrors.length) setError(result!.titleReferenceErrors[0].message);

    if (values.documentType && SLCDocumentLists.includes(values.documentType)) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'creating-slc',
        logGroupId: 'workspace',
        documentType: values.documentType
      });
    }
  };

  function renderForm(formikProps: FormikProps<AddDocumentFormikModel>) {
    const { isSubmitting, values } = formikProps;
    const disabled = isSubmitting || !documentTypes?.length;
    const validationMessage = documentTypes?.find(x => x.id === values.documentType)?.validationMessage || error;
    const nextButtonDisabled = Boolean(validationMessage);

    return (
      <Form>
        <Fieldset disabled={isSubmitting}>
          <FormGroup //
            title="Document to be lodged"
            classes={{ container: classes.formGroupContainer }}
          >
            {Array.isArray(documentTypes) ? (
              <>
                {!documentTypes.length ? (
                  <Typography className={classes.greyText}>All available documents have been added</Typography>
                ) : (
                  <>
                    <Field //
                      fullWidth={true}
                      component={SelectField}
                      label="Select document"
                      name={fieldName('documentType')}
                      placeholder={resolveSelectPlaceholder(true)}
                      format="number"
                      disabled={disabled}
                      onChange={() => setError(undefined)}
                      options={documentTypes}
                    />
                    {values.documentType ? <Typography className={classes.redText}>{validationMessage}</Typography> : null}
                  </>
                )}
              </>
            ) : (
              <InlineLoader />
            )}
          </FormGroup>
          {children}
        </Fieldset>
        <Divider />
        <WizardStepper //
          onBack={onClose}
          backLabel="Cancel"
          nextLabel="Add document"
          disabled={nextButtonDisabled || disabled}
          isLoading={isSubmitting}
        />
      </Form>
    );
  }

  return (
    <ConfirmationDialog //
      title="Add document"
      classes={dialogClasses}
      open={isOpen}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      <Divider className={classes.divider} />
      <Formik //
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addDocument}
      >
        {(formikProps: FormikProps<AddDocumentFormikModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}

const styledComponent = withStyles(styles)(AddDocumentDialog);
export default styledComponent;
