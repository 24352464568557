import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { Form24B_2_24_1Model } from './models';

export const qldForm24BFallbackMap: FallbackMap<Form24B_2_24_1Model> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        partLandAffected: {
          $children: {
            landDescriptions: {
              $shouldAddDefaultItemToEmptyArray: false,
              $arrayItem: {
                $children: {
                  isSelected: NO_FALLBACK,
                  components: NO_FALLBACK,
                  legalDescription: NO_FALLBACK,
                  addressBookId: ['']
                }
              }
            },
            isPartLandAffected: NO_FALLBACK,
            partDescription: NO_FALLBACK
          }
        },
        isSelected: NO_FALLBACK,
        reference: NO_FALLBACK,
        sltReference: NO_FALLBACK,
        subJurisdiction: NO_FALLBACK,
        shareFraction: NO_FALLBACK,
        type: NO_FALLBACK
      }
    }
  },
  transferors: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        addressBookId: NO_FALLBACK,
        isSelected: NO_FALLBACK,
        partyBookId: NO_FALLBACK,
        partyCapacity: NO_FALLBACK,
        trustName: NO_FALLBACK
      }
    }
  },
  transferorsAddress: NO_FALLBACK,
  salePrice: {
    $children: {
      cash: NO_FALLBACK,
      vendorTerms: NO_FALLBACK,
      liabilities: NO_FALLBACK,
      other: NO_FALLBACK,
      otherDescription: NO_FALLBACK,
      waterAllocation: {
        $children: {
          isWaterAllocationApplicable: NO_FALLBACK
        }
      }
    }
  },
  property: {
    $children: {
      inclusion: NO_FALLBACK,
      currentLandUse: {
        $children: {
          vacantLand: NO_FALLBACK,
          dwelling: NO_FALLBACK,
          flats: NO_FALLBACK,
          multiUnit: NO_FALLBACK,
          guestHousePrivateHotel: NO_FALLBACK,
          farming: NO_FALLBACK,
          industrial: NO_FALLBACK,
          commercial: NO_FALLBACK,
          other: [null]
        }
      },
      safetySwitch: {
        $children: {
          installed: [null],
          informed: [null]
        }
      },
      smokeAlarm: {
        $children: {
          installed: [null],
          informed: [null]
        }
      }
    }
  },
  transaction: {
    $children: {
      agreementDate: NO_FALLBACK,
      arePartiesRelated: NO_FALLBACK,
      hasAgreement: NO_FALLBACK,
      isConsiderationLess: NO_FALLBACK,
      isGstPayable: NO_FALLBACK,
      isMarginScheme: NO_FALLBACK,
      isOtherDutiable: NO_FALLBACK,
      isTransferorNonAus: NO_FALLBACK,
      relatedDegree: NO_FALLBACK
    }
  },
  transferorsAddressOptions: NO_FALLBACK
};
