import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconCheck } from '@sympli/ui-framework/icons';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  verified?: boolean;

  referenceNumber?: string;
  assessmentNumber?: string;
  elnoLodgementCaseId?: string;
  transactionIdLabel?: string;
  subStatus?: string; // this is used when some osr will return different sub-status
  earliestSroSettlementDate?: string;
}
type Props = OwnProps & WithStyles<ClassKeys>;
class TransactionStatus extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    transactionIdLabel: 'Transaction ID'
  };

  render() {
    const { classes } = this.props;
    const transactionIdLabel = this.resolveTransactionIdDetailLabel();
    return (
      <div className={classNames(classes.fullWidth, classes.container)}>
        {this.renderStatusLabel()}
        <Typography component="pre">{transactionIdLabel}</Typography>
      </div>
    );
  }

  private renderStatusLabel() {
    const { classes, verified, subStatus } = this.props;
    const displayStatus = subStatus ? `(${subStatus})` : null;
    if (verified) {
      return (
        <Typography className={classNames(classes.label, classes.verified)}>
          <IconCheck color="primary" className={classes.verifiedIcon} />
          Stamp Duty verified {displayStatus}
        </Typography>
      );
    }
    // this is for vic, once the user click on verify button, there will be multiple steps happened, and user needs to login vic sro portal to update status
    if (displayStatus) {
      return (
        <Typography className={classNames(classes.label, classes.unverified)}>
          {subStatus === 'Cancelled' ? 'Unverified' : 'Verifying'} {displayStatus}
        </Typography>
      );
    }
    return <Typography className={classNames(classes.label, classes.unverified)}>Unverified </Typography>;
  }

  private resolveTransactionIdDetailLabel() {
    const { referenceNumber, assessmentNumber, elnoLodgementCaseId, transactionIdLabel, earliestSroSettlementDate } = this.props;
    const labelList: Array<string> = [];
    if (referenceNumber) {
      labelList.push(`${transactionIdLabel}: ${referenceNumber}`);
    }
    if (assessmentNumber) {
      labelList.push(`Assessment ID: ${assessmentNumber}`);
    }
    if (elnoLodgementCaseId) {
      labelList.push(`Lodgement Case ID: ${elnoLodgementCaseId}`);
    }
    if (earliestSroSettlementDate) {
      labelList.push(`Earliest Settlement Date: ${dateTimeLine(earliestSroSettlementDate, 'dd mmm yyyy')}`);
    }
    return labelList.join('  |  ');
  }
}

export default withStyles(styles)(TransactionStatus);
