import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum IssuingPartyDirectionEnum {
  DoNotReturnToNominatingParty = 0,
  ReturnToNominatingParty = 1
}

export const ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS: LookupEnumModel<IssuingPartyDirectionEnum>[] = [
  {
    id: IssuingPartyDirectionEnum.DoNotReturnToNominatingParty,
    name: 'Do not return to nominating party'
  },
  {
    id: IssuingPartyDirectionEnum.ReturnToNominatingParty,
    name: 'Return to nominating party'
  }
];
