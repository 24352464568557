import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { FractionModel, RelinquishingProprietorGroupModel, RelinquishingTenancyDetailModelWithMeta, TenancyPartyModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupTransferor = yup.object<TenancyPartyModel>({
  partyBookId: yup // suspicious validation, user can't see this!
    .string()
    .default('')
    .trim()
    .typeError(msg.INVALID_VALUE)
}) as yup.ObjectSchema<TenancyPartyModel>;

const yupRelinquishingProprietorGroup = yup.object<RelinquishingProprietorGroupModel>({
  parties: yup //
    .array<TenancyPartyModel>()
    .required(msg.REQUIRED)
    .min(1, msg.MIN_ITEMS(1))
    .max(20, msg.MAX_ITEMS(20))
    .of(yupTransferor),
  shareFraction: yup.mixed<FractionModel>() // TODO: Add validation if this section is ever used
}) as yup.ObjectSchema<RelinquishingProprietorGroupModel>;

const yupTransferorDetails = yup.object<RelinquishingTenancyDetailModelWithMeta>({
  isGroupStructureMeaningful: yup.mixed<boolean>(),
  relinquishingProprietorGroups: yup //
    .array<RelinquishingProprietorGroupModel>()
    .required(msg.REQUIRED)
    .min(1, msg.MIN_ITEMS(1))
    .max(20, msg.MAX_ITEMS(20))
    .of<RelinquishingProprietorGroupModel>(yupRelinquishingProprietorGroup)
}) as yup.ObjectSchema<RelinquishingTenancyDetailModelWithMeta>;

export default yupTransferorDetails;
