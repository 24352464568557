import * as React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiMortgage2_17_1Model, Mortgage2_17_1Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<Mortgage2_17_1Model, ApiMortgage2_17_1Model> & {
  // custom for this document only, we need it for tests to disable initial validation
  disableValidateOnMount?: boolean; //
};

function DocumentForm({ disableValidateOnMount, ...props }: Props): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<Mortgage2_17_1Model>(validationSchema, validateDebounce);

  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validate={validate}
        RootForm={RootForm}
        // WEB-14992 explicitly validate on mount
        validateOnMount={disableValidateOnMount ? false : true}
      />
    </DndContextProvider>
  );
}

export default React.memo(DocumentForm);
