import * as React from 'react';

import { useFormikContext } from 'formik';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { NameSuffixEnum, PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { modelKey } from 'src/utils/formUtils';
import { NSW_TAB_GRANTEE_PARTY_FORM_CONFIG } from '../../config';
import { GranteeModel, TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  item: GranteeModel;
  itemBinding: string;
}

const fieldName = modelKey<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>();
function Grantee({ item, itemBinding }: Props) {
  const { dialogPortalId, disabled } = useDocumentContext();
  const classes = useStyles();
  const granteeFieldName = createModelKeyAppender<GranteeModel>(itemBinding);
  const {
    values: {
      deceasedTenancyDetail: { proprietorGroups },
      grantees,
      partyBook
    },
    setFieldValue
  } = useFormikContext<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>();

  const deceasedProprietorIds: string[] = React.useMemo(() => proprietorGroups.find(pg => pg.isSelected)?.parties.map(p => p.partyBookId) ?? [], [proprietorGroups]);

  const filterPartyBook = (party: PartyModel<NswNameChange>): boolean => {
    return (
      party.id === item.partyBookId ||
      (party.nameSuffixValue !== NameSuffixEnum.Minor && !deceasedProprietorIds.includes(party.id) && !grantees.some(g => g.partyBookId === party.id))
    );
  };

  const party = partyBook.find(pb => pb.id === item.partyBookId);
  React.useEffect(() => {
    if (party?.nameSuffixValue === NameSuffixEnum.Minor || deceasedProprietorIds.includes(item.partyBookId)) {
      setFieldValue(granteeFieldName('partyBookId'), '');
    }
  }, [deceasedProprietorIds, granteeFieldName, item.partyBookId, party?.nameSuffixValue, setFieldValue]);

  const disablePartyEditOnly = party?.partyType === PartyTypeEnum.Individual && party.metadata?.source === DataSource.Title;
  const partyEditTooltip: JSX.Element | undefined = disablePartyEditOnly ? (
    <span>This party cannot be edited as it has been returned from the title. Please add a new party.</span>
  ) : undefined;

  return (
    <FlexLayout flexDirection="column">
      <PartySelectField
        //
        partyFormConfig={NSW_TAB_GRANTEE_PARTY_FORM_CONFIG}
        name={granteeFieldName('partyBookId')}
        dialogPortalId={dialogPortalId}
        bookRef={fieldName('partyBook')}
        bookItemFilter={filterPartyBook}
        disableEditOnly={disablePartyEditOnly}
        editTooltip={partyEditTooltip}
        disabled={disabled}
      />

      <Typography className={classes.consentTitle}>Consent</Typography>
      <Field
        name={granteeFieldName('consent')}
        component={CheckboxField}
        label={
          <Typography className={classes.consentLabel}>
            Written consent to this Application has been obtained from the executor of the will/administrator of the estate/trustee of the estate of the deceased registered
            proprietor.
          </Typography>
        }
        className={classes.checkbox}
        disabled={disabled}
      />
    </FlexLayout>
  );
}

interface SectionProps {
  name: string;
}

function Grantees({ name }: SectionProps) {
  const { disabled } = useDocumentContext();
  const {
    values: { grantees }
  } = useFormikContext<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>();

  const renderItem = ({ item, itemBinding }: DocumentArrayItemRenderProps<GranteeModel>) => {
    return <Grantee item={item} itemBinding={itemBinding} />;
  };

  const createNewGrantee = React.useCallback((): GranteeModel => ({ partyBookId: '', consent: false }), []);

  return (
    <Section title={pluralize('Grantee', grantees.length)}>
      <DocumentFieldArray
        itemTitle="Grantee details"
        addButtonTitle="Add another Grantee"
        itemStyle="formGroup"
        disabled={disabled}
        renderItem={renderItem}
        createNewItem={createNewGrantee}
        arrayBinding={name}
        minItems={1}
        maxItems={20}
      />
    </Section>
  );
}

export default React.memo(Grantees);
