import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import columnStyles from '../../columnStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...columnStyles(theme),
    formLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      paddingTop: 18
    },
    bottomBorder: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginTop: 4
    }
  }),
  {
    name: 'GridHead'
  }
);
