import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';

import converter from './conversion';
import { ApiPriorityNoticeModel, PriorityNoticeModel } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export default function DocumentForm(props: DocumentFormProps<PriorityNoticeModel, ApiPriorityNoticeModel>): JSX.Element {
  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validationSchema={validationSchema}
        RootForm={RootForm}
      />
    </DndContextProvider>
  );
}
