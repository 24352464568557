import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';

import { TenancyEditable as SectionTenancyEditable } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { StateEnum } from '@sympli-mfe/enums-2-21/nsw';

import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG, PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { Mortgage2_21_2Model } from '../../models';

interface Props {
  name: string;
}

function SectionMortgagors({ name }: Props): JSX.Element {
  const {
    values: { mortgagees }
  } = useFormikContext<Mortgage2_21_2Model>();

  const partyBookIdsOfAllMortgagors = useMemo(
    () =>
      mortgagees.receivingProprietorGroups
        .flatMap(x => x.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgagees]
  );

  return (
    <SectionTenancyEditable //
      name={name}
      data-name={name}
      data-testid={name}
      jurisdiction={StateEnum.NSW}
      componentLabels={NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG}
      allowNewGroup={false}
      skipTouchedCheckForError
      // party setup
      partyFormConfig={PARTY_FORM_WITH_NAME_CHANGE_CONFIG}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={partyBookIdsOfAllMortgagors}
      // no address
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
    />
  );
}

export default React.memo(SectionMortgagors);
