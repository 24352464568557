import * as yup from 'yup';

import { resolveMemoizedTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { Lease2_21_1Model, TitleReferenceModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import yupTitleReferenceItemSchema from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/sections/title-references/validationSchema';

const yupTitleReferences = resolveMemoizedTitleReferencesValidationSchema<TitleReferenceModel, Lease2_21_1Model>(
  yupTitleReferenceItemSchema as yup.ObjectSchema<TitleReferenceModel, Lease2_21_1Model>
);

export default yupTitleReferences;
