import * as React from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import withStyles, { ClassKeyOfStyles, ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import Checkbox, { CheckboxClassKeys, CheckboxProps } from '@sympli/ui-framework/components/form/base-components/checkbox';
import { LookupTreeEnumModel } from '@sympli/ui-framework/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import styles, { ClassKeys } from './styles';

export interface TreeNodeItem {
  id: string | number;
  parentId?: string | number | null;
  name: string;
}
export interface CheckboxTreeProps<T extends TreeNodeItem> {
  label?: React.ReactNode;
  name: string;
  values: Array<any>;
  error?: string;

  className?: string;
  disabled?: boolean;

  format?: 'number' | 'string';
  margin?: 'default' | 'none';
  options?: T[];
  store?: any;

  onChange?: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => void;
  resolveItemName?: (item: T, index: number) => string; // input checkbox property
  resolveItemLabel?: (item: T) => string; // input checkbox property
  resolveItemValue?: (item: T) => any; // input checkbox property
  resolveItemChecked?: (values: Array<any>, optionItem: T) => boolean; // input checkbox property
  resolveItemDisabled?: (item: T) => any; // input disabled property
  resolveReturnValue?: (item: T) => any; // returned item in resolved items array
  // optional classes as well so it can be used in Field component
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<ClassKeys>>>;
}

export interface RenderItemProps extends CheckboxProps {
  key?: string;
  classes?: Partial<ClassNameMap<keyof ReturnType<CheckboxClassKeys>>>;
}

export type Props<T extends TreeNodeItem> = CheckboxTreeProps<T> & WithStyles<ClassKeys>;

export interface State<T extends TreeNodeItem> {
  store: any;
  options: T[];
  checkedFlags: boolean[];
  loadingError?: string;
  isLoading?: boolean;
}

class CheckboxTree<T extends TreeNodeItem> extends React.PureComponent<Props<T>, State<T>> {
  public static defaultProps: Partial<Props<TreeNodeItem>> = {
    format: 'string',
    margin: 'default'
  };
  public readonly state: Readonly<State<T>> = {
    store: this.props.store,
    options: this.props.options || [],
    loadingError: undefined,
    isLoading: false,
    checkedFlags: this.resolveCheckedFlags(this.props.values, this.props.options || [])
  };

  public displayName = 'FormCheckboxTree';
  private id: string;

  constructor(props: Props<T>) {
    super(props);

    ['resolveItemName', 'resolveItemLabel', 'resolveItemValue', 'resolveItemDisabled', 'resolveItemChecked', 'resolveReturnValue'].forEach(method => {
      this[method] = props[method] || this[method];
    });

    this.id = _uniqueId(`${this.displayName}_`);
  }

  public componentDidMount() {
    this.fetchStoreData();
  }

  static getDerivedStateFromProps(props: Props<any>, state: State<any>) {
    if (props.options != null && props.options !== state.options) {
      return {
        store: undefined,
        options: props.options
      };
    }
    if (props.store != null && props.store !== state.store) {
      return {
        store: props.store
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidUpdate(prevProps: Props<T>, prevState: State<T>) {
    if (prevState.store !== this.state.store) {
      this.fetchStoreData();
    }
  }

  private fetchStoreData = () => {
    const { store } = this.state;
    if (store) {
      // TODO display loading icon
      this.setState({ options: [], loadingError: undefined, isLoading: true });
      store
        .query()
        .then((options: T[]) => {
          this.setState({ options, isLoading: false });
        })
        .catch((err: Error) => {
          this.setState({ loadingError: 'Loading error...', isLoading: false });
          Logger.console(SeverityEnum.Error, err.message);
        });
    }
  };

  private renderLabel() {
    const { classes, label } = this.props;
    return (
      <FormLabel className={classNames(classes.fieldLabel)} htmlFor={this.id}>
        {label}
      </FormLabel>
    );
  }

  private resolveItemProps(item: TreeNodeItem, index: number, checked: boolean) {
    const { classes, disabled, format } = this.props;

    const label = this.resolveItemLabel(item);
    const value = '' + this.resolveItemValue(item);
    const name = this.resolveItemName(item, index);
    const disabledCheckedBox = disabled || this.resolveItemDisabled(item);
    const checkboxProps: RenderItemProps = {
      key: `${this.id}-${index}-${value}`,
      format,
      checked,
      name,
      value,
      label,
      disabled: disabledCheckedBox,
      onChange: this.handleOnChange,
      classes: {
        root: classes.checkboxComponentRoot
      }
    };

    return checkboxProps;
  }

  // label for checkbox item
  protected resolveItemLabel(item: TreeNodeItem): React.ReactNode {
    return item.name;
  }

  // input value
  // this method needs to be bound
  protected resolveItemValue(item: TreeNodeItem) {
    return item.id;
  }

  // input name
  protected resolveItemName(item: TreeNodeItem, index: number) {
    return `${this.props.name}[${index}]`;
  }

  private resolveCheckedFlags(values?: Array<any>, options?: Array<T>): Array<boolean> {
    // * When this method is called from constructor, state is undefined
    const optionsToProcess = options || this.state.options || [];
    const valuesToProcess = values || this.props.values || [];

    return optionsToProcess.map(item => this.resolveItemChecked(valuesToProcess, item));
  }

  // decide whether checkbox will be checked
  protected resolveItemChecked(values: Array<any>, optionItem: TreeNodeItem): boolean {
    // this assumes that values is a list of primitive types (not objects)
    // for example if your values is an array of object with id inside the implementation will be as follows:
    // return [{id: 1}].some(v => v.id === this.resolveItemValue(option))
    return values.some(v => v === this.resolveItemValue(optionItem));
  }

  // decide whether checkbox will be checked
  protected resolveItemDisabled(optionItem: TreeNodeItem): boolean {
    return false;
  }

  // value that will be passed as an item in resolved values array
  // this is mainly useful when we need to return different structure that we received in options
  // this method needs to be bound
  protected resolveReturnValue = (item: TreeNodeItem) => {
    return this.resolveItemValue(item);
  };

  protected renderItem({ label, ...rest }: RenderItemProps): any {
    // const { key, value, checked, name, label, onChange, format } = props;
    return <Checkbox label={label} {...rest} />;
  }

  private renderHelperText() {
    const { error, classes, margin } = this.props;
    if (margin === 'default' && error !== undefined) {
      return <FormHelperText className={classes!.helperTextError}>{error}</FormHelperText>;
    }
    return null;
  }

  public render() {
    const { className, classes, error, margin, name } = this.props;

    return (
      <FormControl //
        data-name={name}
        component="fieldset"
        className={classNames(classes.root, margin === 'default' && classes!.marginBottom, className)}
        error={!!error}
        variant="standard"
      >
        {this.renderLabel()}
        {this.renderTree()}
        {this.renderHelperText()}
      </FormControl>
    );
  }

  private renderTree(item?: TreeNodeItem) {
    const options = this.state.options as Array<LookupTreeEnumModel<string>>;
    const values = this.props.values as Array<string>;
    const { classes } = this.props;
    const self = this;
    let index = 0;

    // tslint:disable-next-line:no-shadowed-variable
    function renderSubTree(item: LookupTreeEnumModel<string>) {
      const children = options.filter(option => option.parentId === item.id);
      const checked = !!~values.indexOf(item.id);
      const props = self.resolveItemProps(item, index++, checked);

      if (children.length) {
        return (
          <li key={item.id}>
            {self.renderItem(props)}
            <ul>{children.map(renderSubTree)}</ul>
          </li>
        );
      } else {
        return <li key={item.id}>{self.renderItem(props)}</li>;
      }
    }

    // find the root object
    // tslint:disable-next-line:no-string-literal
    const root = options.find(option => !option['parentId']);

    if (!root) {
      return null;
    }

    return <ul className={classes.tree}>{renderSubTree(root)}</ul>;
  }

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => {
    if (this.props.onChange == null) {
      return;
    }
    event.persist();
    const { checked } = event.target;
    const newValues = this.resolveNewValue(resolvedValue, checked);
    this.props.onChange(event, newValues);
  };

  private resolveNewValue = (resolvedValue, checked) => {
    const values = this.props.values.concat();
    // Set children value same as current node
    this.changeNodeAndChildrenValue(values, resolvedValue, checked);
    if (!checked) {
      // if uncheck current node, set all parent node to unchecked
      this.uncheckedParents(values, resolvedValue);
    }
    return values;
  };

  private changeNodeAndChildrenValue(checkedValues: Array<number | string>, nodeId: number | string, checked: boolean) {
    if (checked) {
      !checkedValues.includes(nodeId) && checkedValues.push(nodeId);
    } else {
      const nodeIndex = checkedValues.indexOf(nodeId);
      ~nodeIndex && checkedValues.splice(nodeIndex, 1);
    }
    const { options = [] } = this.props;
    options.forEach(option => {
      if (option.parentId === nodeId) {
        this.changeNodeAndChildrenValue(checkedValues, option.id, checked);
      }
    });
  }

  private uncheckedParents(checkedValues: Array<number | string>, nodeId: number | string) {
    const { options = [] } = this.props;
    const currentNode = options.find(option => option.id === nodeId);
    if (currentNode != null && currentNode.parentId != null) {
      const parentNodeIndex = checkedValues.indexOf(currentNode.parentId);
      ~parentNodeIndex && checkedValues.splice(parentNodeIndex, 1);
      this.uncheckedParents(checkedValues, currentNode.parentId);
    }
  }
}

export default withStyles(styles)(CheckboxTree);
