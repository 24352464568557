import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dataBindingHelper } from '@sympli/ui-framework/components/form/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...dataBindingHelper()
    },
    jointTenantsTitle: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 0,
      marginLeft: 20,
      marginTop: 16
    }
  }),
  {
    name: 'RelinquishingProprietorGroup'
  }
);
