import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useSafeDispatch } from 'src/hooks';
import { actionFetchDirectionsList } from '../../actions';
import { DirectionCommonProps } from '../../models';
import { useDirectionsBreakdown } from '../../reducers/directionsBreakdown';
import { useDirectionsList } from '../../reducers/directionsList';
import { sympliSourceFundCalculatorMessageSelector } from '../edit-directions/selectors';
import ReviewDirections from './ReviewDirections';

interface ReviewDirectionsContainerProps extends DirectionCommonProps {}

function ReviewDirectionsContainer(props: ReviewDirectionsContainerProps) {
  const {
    queryParams: { workspaceId, participantId }
  } = props;

  const dispatch = useSafeDispatch(useDispatch());
  const { status, isLoading } = useDirectionsList(workspaceId, participantId);
  const directionsBreakdown = useDirectionsBreakdown(workspaceId, participantId);
  const workspaceParticipants = useWorkspaceParticipants(workspaceId);

  React.useEffect(() => {
    if (status === 'idle' || status === 'rejected') {
      dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
    }
  }, [dispatch, workspaceId, participantId, status]);

  const sympliSourceFundCalculatorMessage = sympliSourceFundCalculatorMessageSelector({
    participantId,
    directionsBreakdown,
    workspaceParticipants
  });

  return (
    <ReviewDirections //
      {...props}
      isLoadingDirectionsList={isLoading}
      sympliSourceFundCalculatorMessage={sympliSourceFundCalculatorMessage}
      dispatch={dispatch}
    />
  );
}
export default React.memo(ReviewDirectionsContainer);
