// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import { TransferDocumentModel } from './models';
import yupConsideration from './sections/consideration/validationSchema';
import yupEncumbrance from './sections/encumbrance/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupTransfereeDetails from './sections/transferee-details/validationSchema';
import yupTransferorDetails from './sections/transferor-details/validationSchema';

export default yup
  .object<TransferDocumentModel>({
    dutyAssessment: yup.mixed<TransferDocumentModel['dutyAssessment']>(),
    relatedDocumentIds: yup.mixed<TransferDocumentModel['relatedDocumentIds']>(),
    workspaceTitleReferences: yup.mixed<TransferDocumentModel['workspaceTitleReferences']>(),
    addressBook: yup.mixed<TransferDocumentModel['addressBook']>(),

    titleReferences: yupTitleReferences,
    transferorDetails: yupTransferorDetails,
    consideration: yupConsideration,
    transfereeDetails: yupTransfereeDetails,
    encumbrance: yupEncumbrance,
    partyBook: yupPartyBook
  })
  .defined()
  .log();
