import * as React from 'react';

import classNames from 'classnames';
import _upperFirst from 'lodash-es/upperFirst';
import Avatar from '@mui/material/Avatar';
/* eslint-disable no-restricted-imports */
import { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import OnlineStatusIcon from 'src/components/online-status-icon';
import { OnlineStatusEnum } from 'src/components/online-status-icon/models';
import Tooltip from 'src/components/tooltip';
import { UserAvatarModel } from '../multi-user-avatar/MultiUserAvatar';
import styles, { ClassKeys } from './styles';
import defaultProfileImage from './svg/default-profile-image.svg';

export interface UserAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  alt?: string;
  text?: string;

  status?: OnlineStatusEnum;
  statusTooltip?: string;

  item?: UserAvatarModel;

  highlighted?: boolean;
  size?: 'default' | 'small' | 'extraSmall';
  border?: boolean;

  showToolTip?: boolean;
  toolTipPlacement?: TooltipProps['placement'];
  margin?: 'left' | 'right';
}
type Props = UserAvatarProps & WithStyles<ClassKeys>;

class UserAvatar extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showToolTip: true,
    size: 'default'
  };

  render() {
    const { classes, style, size = 'default', highlighted, className, margin } = this.props;
    const sizeClassName = classes[`size${_upperFirst(size)}`];
    return (
      <div className={classNames(classes.root, sizeClassName, className, margin === 'left' && classes.marginLeft, margin === 'right' && classes.marginRight)} style={style}>
        {highlighted && <div className={classNames(classes.highlighted, sizeClassName)} />}
        {this.renderAvatarWithToolTip()}
        {this.renderStatus()}
      </div>
    );
  }

  renderAvatarWithToolTip() {
    const { classes, toolTipPlacement } = this.props;
    return (
      <Tooltip classes={{ tooltip: classes.tooltip }} title={this.renderTooltipText()} placement={toolTipPlacement}>
        {this.renderAvatar()}
      </Tooltip>
    );
  }

  renderAvatar() {
    const { classes, alt, src, border } = this.props;

    let avatarSrc = !src ? defaultProfileImage : src;
    return <Avatar className={classNames(classes.avatar, border && classes.border)} alt={alt} src={avatarSrc} />;
  }

  renderStatus() {
    const { classes, status, statusTooltip, size } = this.props;
    if (status === undefined) {
      return null;
    }
    return <OnlineStatusIcon value={status} title={statusTooltip} small={size === 'small'} className={classes.status} />;
  }

  renderTooltipText() {
    const { item, text, showToolTip, classes } = this.props;
    if (!showToolTip) {
      return '';
    }
    if (item) {
      const roles = item.workspaceRoles.map(role => role.name).join(', ');
      return (
        <React.Fragment>
          <Typography component="p" className={classes.tooltipContent}>
            <strong>Name: </strong>
            {item.name}
          </Typography>
          <Typography component="p" className={classes.tooltipContent}>
            <strong>Role: </strong>
            {roles}
          </Typography>
          <Typography component="p" className={classNames(classes.textEllipsis, classes.tooltipContent)}>
            <strong>Matter: </strong>
            {item.reference || 'Pending'}
          </Typography>
        </React.Fragment>
      );
    }
    if (text) {
      return (
        <Typography className={classes.tooltipContent}>
          <strong>Name: </strong>
          {text}
        </Typography>
      );
    }
    return '';
  }
}

export default withStyles(styles)(UserAvatar);
