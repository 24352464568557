import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { PROPERTY_DETAILS_LAND_DIMENSIONS_KNOWN_DETAILS_LOOKUP_OPTIONS } from '../../enums';
import { LandDimensionsModel, NoticeOfAcquisitionDocumentModel } from '../../models';
import { VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA, VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT } from '../../visibilityChecks';
import LandArea from '../land-area';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface Props {
  name: string;
}

function LandDimensions({ name }: Props): JSX.Element {
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;
  const value: LandDimensionsModel = _get(values, name);
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<LandDimensionsModel>(name);

  return (
    <>
      <Field //
        aria-label="Known details "
        name={fieldName('knownDetails')}
        component={SelectField}
        debug={debug}
        disabled={disabled}
        format="number"
        options={PROPERTY_DETAILS_LAND_DIMENSIONS_KNOWN_DETAILS_LOOKUP_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
      />
      {VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$LAND_AREA(value) && (
        <LandArea //
          name={fieldName('landArea')}
        />
      )}
      {VISIBILITY_CHECK_PROPERTY_DETAILS$LAND_DIMENSIONS$UNIT_OF_ENTITLEMENT(value) && (
        <Field //
          name={fieldName('unitOfEntitlement')}
          component={InputField}
          disabled={disabled}
          label={
            <>
              Unit of entitlement
              <InfoIconWithTooltip
                flow="inline"
                tooltipContent="Unit of entitlement of the land or floor space being transferred must be supplied (for example 100:800) and can be found on the Plan of Subdivision."
              />
            </>
          }
        />
      )}
    </>
  );
}

export default React.memo(LandDimensions);
