import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 23,
      paddingBottom: 5,
      paddingLeft: 28,
      paddingRight: 28,
      background: theme.palette.grey[100],
      flexGrow: 1,
      marginBottom: 32
    },
    main: { paddingLeft: 40 },
    messageLine: {
      marginBottom: 4
    },
    header: {
      fontSize: 16
    },
    errorMessage: {
      margin: '16px auto'
    },
    fullWidth: {
      width: '100%'
    },
    uploader: {
      paddingTop: 16,
      paddingBottom: 16,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    treeContainer: {
      backgroundColor: theme.palette.grey[100],
      padding: '1px 7px',
      border: `1px solid ${theme.palette.divider}`
    },
    resetIcon: {
      marginLeft: 2,
      marginRight: 2
    },
    topBarContainer: {
      height: 60,
      padding: '0 30px 0 60px'
    },
    light: {
      background: theme.palette.grey[50]
    },
    dark: {
      background: '#4D5257'
    },
    bottomLine: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 10,
      marginBottom: 20
    },
    bottomLineNone: {
      borderBottom: 'none'
    },
    formGroup: {
      border: 0
    },
    profileImageBox: {
      marginTop: 30,
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    // * Form
    formRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    divider: {
      marginTop: 8,
      marginBottom: 8
    },
    checkboxGroups: {
      minWidth: 300,
      marginBottom: 0
    },
    phoneFormGroupContentContainer: {
      '& >*': {
        '&:not(:last-child)': {
          marginRight: 16
        }
      }
    },
    inputFieldLabel: {
      fontSize: 14
    },
    indentedField: {
      margin: theme.spacing(0.5, 0, 0, 3.25)
    },
    signingLimitInput: {
      width: 'fit-content'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
