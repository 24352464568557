import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { VIC_TOC_PARTY_FORM_CONFIG } from '../../config';
import { SubscriberElnEnum } from '../../enums';
import { RecipientPartyModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
const fieldName = modelKey<RecipientPartyModel>();
// path: recipient
const yupRecipient = yup
  .object<RecipientPartyModel>()
  .nullable(true)
  .shape({
    partyBookId: yup.string().default('').required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(VIC_TOC_PARTY_FORM_CONFIG)),
    addressBookId: yup.string().default('').required(msg.REQUIRED),
    votsId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
    email: yup.string().when(fieldName('subscriberElno'), {
      is: val => val === SubscriberElnEnum.AlternateEln,
      then: yup.string().default('').trim().email(msg.INVALID_EMAIL).typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
      otherwise: yup.string().default('').trim().email(msg.INVALID_EMAIL).typeError(msg.INVALID_VALUE).nullable(true)
    }),
    subscriberId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).nullable(true)
  });

export default yupRecipient;
