import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { isMortgageeNameMismatch } from '../../helpers';
import { DischargeOfMortgage_2_23_1_Model, MortgageePartyModel } from '../../models';

// path: mortgagees
const yupMortgagees = yup
  .array()
  .of<MortgageePartyModel>(
    yup
      .object<MortgageePartyModel>({
        partyBookId: yup.mixed<MortgageePartyModel['partyBookId']>(),
        partyCapacity: yup.mixed<MortgageePartyModel['partyCapacity']>(),
        addressBookId: yup.string().default('').trim().nullable().required(msg.REQUIRED)
      })
      .defined()
  )
  .defined()
  .testContextualRule({
    message: 'The mortgagees are not common across the selected Mortgages. Please review the selected mortgages or provide name justification.',
    uniqueTestName: 'mortgagees[*] name mismatch',
    requirement: (parent: DischargeOfMortgage_2_23_1_Model) => !isMortgageeNameMismatch(parent)
  });

export default yupMortgagees;
