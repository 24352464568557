import { createSelector } from 'reselect';

import { ComplianceLevelEnum } from '@sympli/api-gateway/enums';

import { StampDutyProcessMessage } from './models';

const DEFAULT_MESSAGES: StampDutyProcessMessage[] = [];
const getStampDutyProcessMessages = (messages: StampDutyProcessMessage[] = DEFAULT_MESSAGES) => messages;

// I120 is settlement date extended and we use our own custom message so we don't want to include the error code in it
const showMessageOnlyErrorCodes = ['I120'];

export const stampDutyProcessMessagesSelector = createSelector<
  //
  StampDutyProcessMessage[] | undefined,
  StampDutyProcessMessage[],
  Array<{
    //
    complianceLevel: ComplianceLevelEnum;
    complianceMessage: string;
  }>
>(
  //
  getStampDutyProcessMessages,
  messages =>
    messages.map(message => ({
      complianceLevel: message.errorLevel,
      complianceMessage: showMessageOnlyErrorCodes.includes(message.errorCode) ? message.message : `${message.message} (${message.errorCode})`
    }))
);
