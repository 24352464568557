import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '@sympli/ui-framework/theme';

// this file was automatically generated from sections/styles.ts.mustache
export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    additionalCovenants: {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    uploaderRootGrid: {
      display: 'grid',
      gridTemplateRows: `auto auto minmax(${theme.spacing(2.5)}, max-content) auto`,
      gridAutoFlow: 'column'
    },
    dropzoneUnsetTopMargin: {
      marginTop: 'unset'
    },
    dropzoneGridRow: {
      gridRow: '2 / 3'
    },
    dropzoneOrder: {
      order: 1
    },
    afterDropZoneOrder: {
      order: 2
    },
    attachmentErrorMessage: {
      margin: '4px 0'
    },
    noBottomMargin: {
      marginBottom: 'unset'
    },
    formControlValidation: {
      position: 'unset',
      marginBottom: 4
    },
    additionalCovenantWarningBorder: {
      borderColor: colors.ORANGE
    },
    additionalCovenantWarningHelperText: {
      color: colors.ORANGE,
      position: 'unset',
      marginBottom: 4
    }
  }),
  {
    name: 'AdditionalCovenants'
  }
);
