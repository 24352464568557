import * as React from 'react';

import { connect } from 'react-redux';

import Formik, { TickleFormikProps } from '@sympli/ui-framework/components/formik';

import CheckboxTree from './checkbox-tree';
import CheckboxTreeField from './checkbox-tree-field';

const FormikWithDispatch = connect<{}, {}, React.PropsWithChildren<TickleFormikProps<any>>>(null)(Formik);
export default FormikWithDispatch;

export { CheckboxTree, CheckboxTreeField };

// function test1() {
//   interface FormData {
//     firstName: string;
//     age: number;
//   }
//   const data: FormData = {
//     firstName: 'John',
//     age: 20
//   };

//   interface UpdatedData {
//     firstName: string;
//     age: number;
//     city: string;
//   }

//   return (
//     <>
//       <FormikWithDispatch //
//         method="post"
//         onPreSubmit={(data: FormData) => {
//           return {
//             ...data,
//             city: 'Sydney'
//           };
//         }}
//       />
//       <FormikWithDispatch //
//         getInitialValues={() => data}
//         onSubmit={(data: UpdatedData) => {
//           console.log(data.city);
//         }}
//       />
//     </>
//   );
// }
