import * as React from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme/colors';

const useStyles = makeStyles(
  (theme: Theme) => ({
    accordion: {
      background: 'none',
      paddingTop: 8,
      paddingBottom: 8,
      borderBottom: `1px solid ${colors.NEUTRAL_100}`,
      '&::before': {
        display: 'none'
      }
    },
    accordionSummary: {
      padding: 0,
      minHeight: 'auto',
      '& .MuiAccordionSummary-content': {
        margin: 0
      }
    },
    accordionDetails: {
      padding: 0
    }
  }),
  {
    name: 'SympliAccordion'
  }
);

interface Props extends AccordionProps {
  summary: string | JSX.Element;
  expandIcon?: React.ReactNode;
}

export default function SympliAccordion({ expandIcon, summary, children, className, ...props }: Props) {
  const id = React.useMemo(() => _uniqueId(), []);
  const classes = useStyles();
  return (
    <MuiAccordion className={classNames(classes.accordion, className)} disableGutters elevation={0} square {...props}>
      <MuiAccordionSummary //
        className={classes.accordionSummary}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
        expandIcon={
          expandIcon || (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M9.99961 14.0938C9.81836 14.0938 9.64336 14.0188 9.51211 13.8938L2.69961 7.08135C2.43086 6.8126 2.43086 6.3751 2.69961 6.10635C2.96836 5.8376 3.40586 5.8376 3.67461 6.10635L9.99961 12.4313L16.3246 6.10635C16.5934 5.8376 17.0309 5.8376 17.2934 6.10635C17.5621 6.3751 17.5621 6.8126 17.2934 7.08135L10.4809 13.8938C10.3496 14.0251 10.1746 14.0938 9.99336 14.0938H9.99961Z"
                fill="#94979A"
              />
            </svg>
          )
        }
      >
        {summary}
      </MuiAccordionSummary>
      <MuiAccordionDetails //
        id={`panel-${id}-content`}
        className={classes.accordionDetails}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}
