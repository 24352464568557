import * as React from 'react';

import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { modelKey } from 'src/utils/formUtils';
import ForeignShareholders from '../../../components/foreign-shareholders';
import { ORGANISATION_OWNERSHIP_TYPE_OPTIONS } from '../../../enums';
import { Form25_2_24_1Model } from '../../../models';
import { VISIBILITY_CHECK_FOREIGN_SHAREHOLDER } from '../../../visibilityCheck';

const fieldName = modelKey<Form25_2_24_1Model>();

function Organisation() {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<Form25_2_24_1Model>();
  const { values } = formikProps;

  return (
    <>
      <FormGroup title="Organisation ownership">
        <Field //
          name={fieldName('foreignType')}
          label="Ownership type"
          component={SelectField}
          disabled={disabled}
          options={ORGANISATION_OWNERSHIP_TYPE_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          fullWidth
        />
      </FormGroup>

      {VISIBILITY_CHECK_FOREIGN_SHAREHOLDER(values) && <ForeignShareholders />}
    </>
  );
}

export default React.memo(Organisation);
