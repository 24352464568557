import { ComparisonGroup, ComparisonMappings, Container, MergeFailedReasonsEnum, MergeParty, PartyMap, PartyMergeResult, ProprietorGroup, ReferenceGroup } from './model';

/**
 * https://tickleme.atlassian.net/wiki/spaces/~5de440aa7095a40d125388c9/pages/2415887591/Party+Merge+NSW
 */

export default class PartyMerger {
  /**
   * Will merge the parties in one container when structure matched otherwise will return all without merge
   * @param containers Parties to compare
   * @returns Return the merged containers, single when structure same
   */
  public static merge<T extends MergeParty>(containersToMerge: Container<T>[]): PartyMergeResult<T> {
    let containers = containersToMerge.map(c => c.clone().resetMetadata(containersToMerge.length > 1));
    let result: ComparisonMappings<T> | undefined;
    if (containers.length > 1) {
      result = createComparisonMappings(containers);
      if (result.comparisons.length) {
        containers = [mergeContainersIntoOne(containers, result)];
      }
    }

    return new PartyMergeResult(assignNewIds(containers), result?.failedReason);
  }
}

/**
 * Create the mapping for best matches across containers groups
 * @param containers Containers to merge
 * @returns returns the result of matching between containers
 */
function createComparisonMappings<T extends MergeParty>(containers: Container<T>[]): ComparisonMappings<T> {
  const reference = containers[0]; // Reference container used for comparison with all other containers
  const containersToCompare = containers.slice(1);

  const comparisons = new Array<Array<ReferenceGroup<T>>>();
  for (const containerToCompare of containersToCompare) {
    const proprietorGroups = containerToCompare.tenancyDetail.proprietorGroups;
    if (reference.tenancyDetail.proprietorGroups.length !== proprietorGroups.length) {
      return ComparisonMappings.StructureNotSame();
    }

    const referenceGroups: ReferenceGroup<T>[] = [];
    comparisons.push(referenceGroups);
    for (const referenceProprietorGroup of reference.tenancyDetail.proprietorGroups) {
      const referenceGroup = new ReferenceGroup(referenceProprietorGroup);
      referenceGroups.push(referenceGroup);

      const verifyResults = proprietorGroups.map(pg => ({ failedReason: pg.verifyMerge(referenceProprietorGroup), proprietorGroup: pg }));
      const proprietorGroupsToCompare = verifyResults.filter(e => e.failedReason === MergeFailedReasonsEnum.None).map(e => e.proprietorGroup);
      if (!proprietorGroupsToCompare.length) {
        return new ComparisonMappings(undefined, verifyResults.map(e => e.failedReason).sort((n1, n2) => n2 - n1)[0]);
      }

      proprietorGroupsToCompare.forEach(proprietorGroup => {
        const matchingPartyMaps = getMatchingParties(referenceProprietorGroup.parties, proprietorGroup.parties);
        referenceGroup.comparisonGroups.push(new ComparisonGroup<T>(proprietorGroup, matchingPartyMaps));
      });
    }

    const groupTaken: ProprietorGroup<T>[] = [];
    const orderedReferenceGroups = referenceGroups.sort((a, b) => b.getMaxCompare() - a.getMaxCompare());

    for (const orderedReferenceGroup of orderedReferenceGroups) {
      const comparisonGroups = orderedReferenceGroup.comparisonGroups.filter(cg => !groupTaken.includes(cg.proprietorGroup));
      if (!comparisonGroups.length) return ComparisonMappings.StructureNotSame();

      // Take the best match
      orderedReferenceGroup.comparisonGroups = [comparisonGroups.sort((a, b) => b.matchingPartyMaps.length - a.matchingPartyMaps.length)[0]];

      groupTaken.push(...orderedReferenceGroup.comparisonGroups.map(x => x.proprietorGroup));
    }
  }

  return new ComparisonMappings(comparisons);
}

/**
 * Merge the containers using the best match mappings
 * @param reference First container will keep all the merged parties from rest containers
 * @param comparisonMappings Mapping between containers
 */
function mergeContainersIntoOne<T extends MergeParty>(containers: Container<T>[], comparisonMappings: ComparisonMappings<T>): Container<T> {
  const reference = containers[0];
  reference.tenancyDetail.proprietorGroups.forEach(referenceProprietorGroup => {
    const matchingProprietorGroups = comparisonMappings.comparisons.flatMap(list => list.filter(rg => rg.proprietorGroup === referenceProprietorGroup));

    const matchingGroupHash: string = `${[
      referenceProprietorGroup.parties[0].mergeMetadata!.identity.groupHash,
      ...matchingProprietorGroups.flatMap(e => e.comparisonGroups.flatMap(cg => cg.proprietorGroup.parties[0].mergeMetadata!.identity.groupHash))
    ].join('|')}`;

    referenceProprietorGroup.parties.forEach(party => {
      party.mergeMetadata!.matchingGroupHash = matchingGroupHash;
      const matchedPartiesForAll = matchingProprietorGroups.flatMap(rg => rg.comparisonGroups.flatMap(cg => cg.matchingPartyMaps.filter(mp => mp.referenceParty === party)));
      const hasMatching: boolean = matchedPartiesForAll.length === matchingProprietorGroups.length;

      if (hasMatching) {
        handleMatchedItems();
      }

      function handleMatchedItems() {
        party.mergeMetadata!.requiresJustification = false;
        assignMatchingItemHash<T>(
          matchedPartiesForAll.map(e => e.matchedParty),
          party
        );
      }
    });

    addRemainingUnmatchedItems();

    function addRemainingUnmatchedItems() {
      matchingProprietorGroups.forEach(e =>
        e.comparisonGroups.forEach(cg =>
          cg.proprietorGroup.parties.forEach(p => {
            p.mergeMetadata!.matchingGroupHash = matchingGroupHash;
            const noMatchFound = p.mergeMetadata!.matchingItemHash === p.mergeMetadata!.identity.itemHash;
            if (noMatchFound) referenceProprietorGroup.mergedParties.push(p);
          })
        )
      );
    }
  });

  return reference;
}

/**
 * Create and assign the matching item hash for all matched parties
 * @param matchedPartiesForAll Matching parties from all containers except first
 * @param party First container party as reference
 */
export function assignMatchingItemHash<T extends MergeParty>(matchedPartiesForAll: T[], party: T): string {
  const itemHashes = matchedPartiesForAll.map(e => e.mergeMetadata!.identity.itemHash);
  const matchingItemHash = `${[party.mergeMetadata!.identity.itemHash].concat(itemHashes).join('|')}`;
  party.mergeMetadata!.matchingItemHash = matchingItemHash;
  matchedPartiesForAll.forEach(e => (e.mergeMetadata!.matchingItemHash = party.mergeMetadata!.matchingItemHash));
  return matchingItemHash;
}

/**
 * Generate new id and assign for all parties
 * @param containers Parties need new id
 * @returns returns the parties with new id if external id is undefined
 */
function assignNewIds<T extends MergeParty>(containers: Container<T>[]): Container<T>[] {
  let id = 1;
  containers.forEach(c =>
    c.tenancyDetail.proprietorGroups.forEach(pg =>
      pg.mergedParties.forEach(party => {
        party.id = party.externalId ? party.externalId : `PM-${id++}`;
      })
    )
  );

  return containers;
}

function getMatchingParties<T extends MergeParty>(referenceParties: T[], partiesToCompare: T[]): PartyMap<T>[] {
  const matchParties: PartyMap<T>[] = [];
  const remainingParties: T[] = [...partiesToCompare];

  referenceParties.forEach(referenceParty => {
    const matchedPartyIndex = remainingParties.findIndex(e => compareParties({ party1: referenceParty, party2: e, getLegalEntityName: party => party.legalEntityName }));
    if (matchedPartyIndex >= 0) {
      matchParties.push(new PartyMap<T>(referenceParty, remainingParties[matchedPartyIndex]));
      remainingParties.splice(matchedPartyIndex, 1);
    }
  });

  return matchParties;
}

/**
 * Compare two parties using legal entity name (case insensitive), party type and party capacity
 * @param party1 party to compare
 * @param party2 party to compare
 * @returns returns true when match both condition otherwise false
 */
export function compareParties<T extends MergeParty>({
  party1,
  party2,
  getLegalEntityName
}: {
  party1: T;
  party2: T;
  getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined;
}): boolean {
  return (
    getLegalEntityName(party1)?.toLowerCase() === getLegalEntityName(party2)?.toLowerCase() && //
    party1.partyType === party2.partyType &&
    party1.partyCapacity?.capacity === party2.partyCapacity?.capacity
  );
}
