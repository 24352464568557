import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: theme.palette.text.secondary
    },
    main: {
      overflow: 'hidden'
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '16px',
      color: theme.palette.text.primary,
      '& a': {
        color: 'inherit'
      }
    },
    titleLink: {
      textDecoration: 'underline'
    },
    titlePending: {
      color: theme.palette.text.secondary
    },
    titleDisabled: {
      textDecoration: 'none',
      color: theme.palette.text.secondary
    },
    description: {
      fontSize: 13,
      lineHeight: '15px',
      color: theme.palette.text.primary
    },
    descriptionSecondary: {
      color: theme.palette.text.secondary
    },
    descriptionActive: {
      color: theme.palette.primary.main
    },
    iconSigned: {
      color: theme.palette.primary.main
    },
    avatar: {
      marginLeft: 'auto'
    },
    textEllipsis: {
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    selected: {
      textDecoration: 'none'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
