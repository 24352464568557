import { createSelector } from 'reselect';

import { DirectionCategoryEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsCategoriesApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const categoryOptionsSelector = createSelector<
  {
    directionCategory: DirectionCategoryEnum;
    currentParticipant?: WorkspaceParticipantApiResponse;
    directionsCategoriesDetail?: WorkspaceDirectionsCategoriesApiResponse;
  },
  //
  DirectionCategoryEnum | undefined,
  WorkspaceParticipantApiResponse | undefined,
  WorkspaceDirectionsCategoriesApiResponse | undefined,
  LookupEnumModel<string, string>[]
>(
  args => args.directionCategory,
  args => args.currentParticipant,
  args => args.directionsCategoriesDetail,
  (directionCategory, currentParticipant, directionsCategoriesDetail) => {
    const workspaceRole = currentParticipant?.workspaceRole?.id;
    const { directionCategories, purchaserAddToVendorCategories } = directionsCategoriesDetail || {};

    let categories = directionCategories;
    // show different set of categories drop down for Purchaser role
    if (workspaceRole === WorkspaceRoleEnum.Purchaser) {
      categories = directionCategory === DirectionCategoryEnum.Settlement ? purchaserAddToVendorCategories : directionCategories;
    }

    return categories || [];
  }
);
