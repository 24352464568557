import * as yup from 'yup';

import { DocumentAttachmentItemModel, DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import msg from '@sympli/ui-framework/utils/messages';

// path: DocumentAttachment
export default yup
  .array<DocumentAttachmentItemModel>()
  .nullable(true)
  .min(0, msg.MIN_ITEMS(0))
  .max(1, msg.MAX_ITEMS(1))
  .of(
    yup.object<DocumentAttachmentItemModel>().shape({
      documentType: yup.mixed<string>().required(msg.REQUIRED),
      attachment: yup
        .array<DocumentAttachmentModel>()
        .min(1, msg.REQUIRED)
        .max(1, msg.MAX_ITEMS(1))
        .of(
          //
          yup.object<DocumentAttachmentModel>().shape({
            id: yup.string().default(''),
            fileName: yup.string().default(''),
            fileType: yup.string().default('')
          })
        )
    })
  );
