import React from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { IconDownload } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

export interface Props {
  isLoading: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function ReportHistoryDownloadButton({ onClick, isLoading }: Props) {
  const classes = useStyles();

  if (isLoading) {
    return <BlockLoader />;
  }

  return (
    <IconButton className={classes.iconButton} size="small" onClick={onClick}>
      <IconDownload className={classes.downloadIcon} />
      <Typography className={classes.downloadLabel}>Download report</Typography>
    </IconButton>
  );
}

export default React.memo(ReportHistoryDownloadButton);
