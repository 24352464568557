import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
  createStyles({
    // * Confirmation dialog
    dialogRoot: {
      alignItems: 'flex-start'
    },
    dialogPaper: {
      marginTop: 40,
      width: 880,
      maxWidth: 880
    },
    dialogTitle: {
      padding: '36px 40px 12px'
    },
    dialogContent: {
      paddingLeft: 40,
      paddingRight: 40
    },
    // * Content
    divider: {
      marginBottom: 22
    },
    formGroupContainer: {
      borderBottom: 'none'
    },
    greyText: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px'
    },
    redText: {
      color: theme.palette.error.main,
      fontSize: 13,
      lineHeight: '15px',
      marginTop: -15
    }
  });

export type ClassKeys = typeof styles;

export default styles;
