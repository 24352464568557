import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  popper: {},
  open: {},
  tooltip: {
    width: 360,
    maxWidth: 360,
    boxSizing: 'border-box',
    '&$open': {
      opacity: 1
    }
  },
  arrow: {},
  title: {
    paddingBottom: 20
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 40,
    height: 40,
    zIndex: 1
  },
  closeIcon: {
    fontSize: 20
  }
});

export type ClassKeys = typeof styles;

export default styles;
