import * as React from 'react';

import classNames from 'classnames';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { Action } from 'redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconEnvelopeUnRead, IconErrorCircle, NewArrowLeft, NewEnvelopeRead, NewIconSendMessage, NewIconTick, NewIconTickDouble } from '@sympli/ui-framework/icons';
import Logger, { InvalidDataError, PageActionEnum } from '@sympli/ui-logger';

import LineLoader from 'src/components/loaders/line-loader';
import { actionSendConversationMessage, actionUpdateConversationIsRead, SendConversationMessageApiRequest } from 'src/containers/messenger/actions';
import MessengerActionBox from 'src/containers/messenger/components/messenger-action-box';
import MessengerHeaderBox from 'src/containers/messenger/components/messenger-header-box';
import { getConversationSubject, getFormattedDate101, getFormattedLongDate101 } from 'src/containers/messenger/helpers';
import {
  ConversationDocument,
  MessageModel,
  MessagingDisabledPrimaryMessageEnumMap,
  MessagingDisabledSecondaryMessageEnumMap,
  MessagingSendingStatusEnum
} from 'src/containers/messenger/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { colors } from 'src/theme';
import { resolveUuid } from 'src/utils/formUtils';
import { MessagingDisabledStatus } from '../../models';
import styles, { ClassKeys } from './styles101';

export interface OwnProps {
  workspaceId: string;
  participantId: string;

  isWorkspaceLoading: boolean;

  userName: UserProfileModel['name'];
  timezone: UserProfileModel['timezone'];

  conversationParticipantsMap: Map<string, WorkspaceParticipantApiResponse>;
  selectedConversationId: string | null;
  conversationId: string;
  conversation?: ConversationDocument;
  onConversationSelect: (selectedConversationId?: string) => void;
  dispatch: SafeDispatch<Action>;
  readButtonDisabled?: boolean;
  sendingMessagingDisabledStatus: MessagingDisabledStatus;
  subscriberNames: { [key: string]: string }; // key for participantId, value for subscriber name
}
interface State {
  text: string;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class MessageDetail101 extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    text: ''
  };

  private myRef: React.MutableRefObject<VirtuosoHandle | null>;

  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props) {
    const { conversation } = prevProps;
    if (conversation?.messages) {
      this.myRef.current && this.myRef.current.scrollToIndex({ index: conversation.messages.length, align: 'end', behavior: 'smooth' });
    }
  }

  render() {
    const { classes, sendingMessagingDisabledStatus } = this.props;
    const { text } = this.state;

    return (
      <React.Fragment>
        <MessengerHeaderBox>
          <FlexLayout alignItems="center" justifyContent="space-between" className={classes.headerContainer}>
            <IconButton onClick={this.handleOnLeftClick} aria-label="go back to list" size="large">
              <NewArrowLeft />
            </IconButton>
            {this.renderSubject()}
            {this.renderReadButton()}
          </FlexLayout>
        </MessengerHeaderBox>
        <FlexLayout flexDirection="column" className={classes.conversationContainer}>
          {this.renderConversation()}
        </FlexLayout>
        {sendingMessagingDisabledStatus.isDisabled && (
          <div className={classes.alertMessage}>
            <Typography className={classes.alertText}>{MessagingDisabledPrimaryMessageEnumMap[sendingMessagingDisabledStatus.disabledReason!]}</Typography>
            <Typography className={classes.alertText}>{MessagingDisabledSecondaryMessageEnumMap[sendingMessagingDisabledStatus.disabledReason!]}</Typography>
          </div>
        )}
        <MessengerActionBox>
          <Input
            aria-label="Message"
            name="message"
            placeholder={sendingMessagingDisabledStatus.isDisabled ? '' : 'Type a message'}
            margin="none"
            multiline
            className="w-[260px]"
            value={text}
            onChange={this.handleOnInputChange}
            classes={{ formControl: classes.multiline }}
            InputProps={{ classes: { root: classes.inputRoot } }}
            inputProps={{ maxLength: 5000, className: classes.multilineInput }}
            disabled={sendingMessagingDisabledStatus.isDisabled}
            onKeyDown={this.handleKeyPress}
          />
          <SympliButton
            color="primary"
            variant="contained"
            className={classNames(classes.submitButton, 'h-[36px] w-[36px] rounded-[18px]')}
            onClick={this.handleOnSendClick}
            aria-label="Send message"
            disabled={sendingMessagingDisabledStatus.isDisabled || text.trim().length === 0}
          >
            <NewIconSendMessage fill={colors.WHITE} />
          </SympliButton>
        </MessengerActionBox>
      </React.Fragment>
    );
  }

  private renderSubject() {
    const { classes, conversation } = this.props;

    if (conversation == null) {
      return null;
    }

    const { recipientParticipantIds } = conversation;

    const subject = getConversationSubject(conversation);

    return (
      <Tooltip
        title={this.renderSubjectTooltip(subject, recipientParticipantIds)}
        placement="bottom-start"
        classes={{ tooltip: classes.subjectTooltip, arrow: classes.subjectTooltipArrow }}
      >
        <Box className={classes.conversationSubject}>
          <Typography className={classNames(classes.conversationTitle)}>{subject}</Typography>
          <Typography variant="body3" className={classNames(classes.conversationSubtitle)}>
            {recipientParticipantIds.length > 1 ? `${recipientParticipantIds.length} participants` : this.getParticipantNameAndRole(recipientParticipantIds[0])}
          </Typography>
        </Box>
      </Tooltip>
    );
  }

  private getParticipantNameAndRole(participantId: string) {
    const { conversationParticipantsMap } = this.props;
    const participant = conversationParticipantsMap.get(participantId);
    if (participant) {
      return `${participant.name} • ${participant.workspaceRole.name}`;
    }

    Logger.captureException(new InvalidDataError('Participant id does not exist', { participantId }));
    return '';
  }

  private renderSubjectTooltip(subject: string, recipientParticipantIds: string[]) {
    const { conversationParticipantsMap, classes } = this.props;

    return (
      <Box display="flex" flexDirection="column">
        <Typography className={classes.subjectTooltipTitle}>{subject}</Typography>
        {recipientParticipantIds.map(id => {
          const participant = conversationParticipantsMap.get(id);
          if (!participant) {
            Logger.captureException(new InvalidDataError('Participant id does not exist', { participantId: id }));
            return null;
          }
          return (
            <React.Fragment key={id}>
              <Typography variant="body2_bold" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1.5, 0, 0, 0) }}>
                {participant.name}
              </Typography>
              <Typography variant="body2" sx={{ color: colors.WHITE }}>
                {participant.workspaceRole.name}
              </Typography>
            </React.Fragment>
          );
        })}
      </Box>
    );
  }

  private renderReadButton() {
    const { classes, conversation, readButtonDisabled } = this.props;

    if (conversation == null) {
      return null;
    }

    const { isRead } = conversation;
    return (
      <IconButton onClick={this.handleOnReadButtonClick} size="large" disabled={readButtonDisabled}>
        {isRead ? <NewEnvelopeRead /> : <IconEnvelopeUnRead className={classes.readIcon} />}
      </IconButton>
    );
  }

  private renderConversation() {
    const { classes, participantId, isWorkspaceLoading, conversation, sendingMessagingDisabledStatus, conversationParticipantsMap: participantMap } = this.props;

    if (conversation == null) {
      return null;
    }

    const { messages } = conversation;

    return (
      <Virtuoso
        ref={this.myRef}
        // when the conversation is disabled(participant has withdrawn) we display an alert message with height hardcoded to 44px(classes.alertMessage), so we need to reduce conversation content height
        style={{ height: sendingMessagingDisabledStatus.isDisabled ? 376 - 44 : 376 }}
        initialTopMostItemIndex={messages.length - 1}
        className={classes.virtuosoRoot}
        data={this.getMessagesData(messages)}
        itemContent={(_, message) => {
          if (isWorkspaceLoading) {
            return (
              <div key={message.createdDate} className={classes.messageBox}>
                <LineLoader variant="medium" widthPercent={100} />
                <LineLoader variant="medium" widthPercent={100} />
              </div>
            );
          }

          const { sentByParticipantId, createdDate, text, isDifferentDate, dateHeader, dateLabel } = message;
          const isMessageSentByMyself = sentByParticipantId === participantId;
          const senderParticipant = participantMap.get(sentByParticipantId);

          return (
            <div key={createdDate}>
              {isDifferentDate && (
                <div className={classes.messageDate}>
                  <Typography className={classes.messageDateText}>{dateHeader}</Typography>
                </div>
              )}
              <div className={classNames(classes.messageBox, isMessageSentByMyself ? 'ml-[32px] bg-[var(--greek-waters-translucent)]' : 'mr-[32px] bg-[var(--neutral-050)]')}>
                <FlexLayout className={classes.messageTitle}>
                  <div className={classes.messageHeaderBox}>{this.renderMessageSender(message, senderParticipant)}</div>
                  <Typography className={classes.messageTime}>{dateLabel}</Typography>
                </FlexLayout>
                <Typography variant="body1" className={classNames(classes.messageBody, !isMessageSentByMyself && 'pb-[8px]')}>
                  {text}
                </Typography>
                {isMessageSentByMyself &&
                  // sendingStatus is undefined means it's sent and stored in Database
                  (message.sendingStatus === undefined || message.sendingStatus === MessagingSendingStatusEnum.Sent) &&
                  this.renderReadIcon(message)}
                {message.sendingStatus === MessagingSendingStatusEnum.Failed && (
                  <FlexLayout flexDirection="row-reverse">
                    <Box className="h-[16px]">
                      <IconErrorCircle />
                    </Box>
                  </FlexLayout>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }

  private getMessagesData(messages: MessageModel[]) {
    const { timezone } = this.props;
    let date = '';

    return messages.map(message => {
      const { createdDate } = message;

      const { dateWithShortYear: dateHeader, dateWithoutYear: dateLabel } = getFormattedDate101(new Date(createdDate), timezone);
      const isDifferentDate = date !== dateHeader;

      if (isDifferentDate) {
        date = dateHeader;
      }

      return {
        ...message,
        dateHeader,
        dateLabel,
        isDifferentDate
      };
    });
  }

  private renderReadIcon(message: MessageModel) {
    const { classes, conversation, subscriberNames, timezone } = this.props;

    const isNobodyReadMessage = !message.readBy?.length;
    const isMessageReadByAll = message.readBy && message.readBy.length === conversation?.recipientParticipantIds.length;

    const renderContent = () => {
      if (isNobodyReadMessage) {
        return (
          <Typography variant="body2" className={classes.tooltipText}>
            Delivered
          </Typography>
        );
      }

      return (
        <>
          <Typography variant="body2_bold" className={classNames(classes.tooltipText, classes.upperCaseText)}>
            Viewed
          </Typography>
          {message.readBy?.map(readBy => {
            const subscriberName = subscriberNames[readBy.id] ?? '';
            const readTime = getFormattedLongDate101(new Date(readBy.readTime), timezone);

            return (
              <React.Fragment key={readBy.id}>
                <Typography className={classNames(classes.tooltipContent, classes.boldText)}>{subscriberName}</Typography>
                <Typography className={classes.tooltipContent}>{readTime}</Typography>
              </React.Fragment>
            );
          })}
        </>
      );
    };

    return (
      <FlexLayout flexDirection="row-reverse">
        <Tooltip title={renderContent()} placement="left-start" classes={{ popper: classes.tooltipPopper }}>
          <Box className="h-[16px]">{isNobodyReadMessage ? <NewIconTick /> : <NewIconTickDouble fill={isMessageReadByAll ? colors.SYMPLI_GREEN : colors.NEUTRAL_300} />}</Box>
        </Tooltip>
      </FlexLayout>
    );
  }

  private renderMessageSender(message: MessageModel, participant: WorkspaceParticipantApiResponse | undefined) {
    const { classes, participantId } = this.props;
    const { sentByParticipantId, sentByUserName } = message;
    const subscriberName = (participant && participant.name) || message.sentBySubscriberName;
    const roleName = participant && participant.workspaceRole.name;

    return (
      <Typography className={classes.messageHeader}>
        <span className={classes.messageHeaderBlack}>{sentByParticipantId === participantId ? sentByUserName : subscriberName}</span>
        <span> • {roleName}</span>
      </Typography>
    );
  }

  private handleOnInputChange = (_unusedEvent: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => {
    this.setState({ text: resolvedValue });
  };

  private handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // prevent default behavior (wrap into new line)
      event.preventDefault();
      // send the message
      this.handleOnSendClick();
    }
    // Else default behaviour
  };

  private handleOnReadButtonClick = () => {
    const { workspaceId, participantId, conversation, dispatch, onConversationSelect } = this.props;
    if (conversation != null) {
      const { conversationId, isRead } = conversation;
      dispatch(actionUpdateConversationIsRead.request({ workspaceId, participantId, conversationId, isRead: !isRead }));
    }
    onConversationSelect(undefined);
  };

  private handleOnSendClick = () => {
    const { text: newMessageText } = this.state;

    if (!newMessageText || newMessageText.trim().length === 0) {
      return;
    }

    const { conversationParticipantsMap: participantMap, workspaceId, participantId, conversationId: id, userName, dispatch } = this.props;
    const currentParticipant = participantMap.get(participantId);
    if (currentParticipant != null) {
      const { name } = currentParticipant;
      const messageItem: SendConversationMessageApiRequest = {
        workspaceId,
        participantId,
        conversationId: id,
        message: newMessageText,
        sentByUserName: userName,
        sentBySubscriberName: name,
        id: resolveUuid('string', 20)
      };
      dispatch(actionSendConversationMessage.request(messageItem));

      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'messenger-send-new-message',
        logGroupId: 'workspace',
        ...messageItem
      });

      this.setState({ text: '' });
    }
  };

  private handleOnLeftClick = () => {
    this.props.onConversationSelect(undefined);
  };
}

export default withStyles(styles)(MessageDetail101);
