import * as React from 'react';

import { useDispatch } from 'react-redux';

import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import { actionFetchStampDutyDetails } from 'src/containers/workspace/financial/stamp-duty/actions';
import { useStampDuty } from 'src/containers/workspace/financial/stamp-duty/reducer';
import StampDutyContainer, { StampDutyRouteParams } from 'src/containers/workspace/financial/stamp-duty/StampDutyContainer';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useRouterParams, useSafeDispatch } from 'src/hooks';

export default function WorkspaceStampDutyPageContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId } = useRouterParams<StampDutyRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const stampDutyState = useStampDuty(workspaceId, participantId);

  // fetch data that page needs
  React.useEffect(() => {
    dispatch(actionFetchStampDutyDetails.request({ workspaceId, participantId }));
  }, [dispatch, participantId, workspaceId]);

  if (workspaceBasicInfoState.error || workspaceDetailState.error || stampDutyState.error) {
    return <GeneralCrashedContent />;
  }

  return <StampDutyContainer />;
}
