import { WaAddressChange, waPartyAddressChangeConfig } from '@sympli-mfe/document-components/party-form/wa/2-23/components/address-justification';
import {
  DM_AND_WX_INDIVIDUAL_PARTY_JUSTIFICATION_REASON_LOOKUP_OPTIONS,
  WaNameChange,
  waPartyNameChangeConfig
} from '@sympli-mfe/document-components/party-form/wa/2-23/components/party-justification';
import { WA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/wa/2-23/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { NameSuffixEnum } from '@sympli-mfe/enums-2-23/wa';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const WA_DM_PARTY_FORM_CONFIG: PartyFormConfig<WaNameChange, WaAddressChange> = {
  ...WA_PARTY_FORM_CONFIG,
  nameChangeConfig: {
    ...waPartyNameChangeConfig,
    customIndividualJustificationReasonOptions: DM_AND_WX_INDIVIDUAL_PARTY_JUSTIFICATION_REASON_LOOKUP_OPTIONS
  },
  addressChangeConfig: waPartyAddressChangeConfig
};

//Mortgagees are not allowed to be minors
export const WA_DM_PARTY_FORM_FOR_MORTGAGEE_CONFIG: PartyFormConfig<WaNameChange, WaAddressChange> = {
  ...WA_DM_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...WA_DM_PARTY_FORM_CONFIG.individualPartyFormConfig!,
    nameSuffixLookupEnum: [...WA_DM_PARTY_FORM_CONFIG.individualPartyFormConfig!.nameSuffixLookupEnum!].filter(x => x.id !== NameSuffixEnum.Minor)
  },
  nameChangeConfig: {
    ...WA_DM_PARTY_FORM_CONFIG.nameChangeConfig!,
    hideMinorNameSuffixOption: true
  }
};
