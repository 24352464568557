import dateFormat from 'dateformat';

import { QldDocumentPartyJustification, QldNameChange, QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { prepareReceivingTenancyDetailForApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import PartyReferencer from 'src/containers/documents/party-referencer/PartyReferencer';
import { convertPartiesToFormModel_2_24, generateDeceasedTenancy } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/helpers';
import { ADDRESS_BOOK_KEY } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/models';
import { getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSMISSION_ADDRESS_FORM_CONFIG, QLD_TRANSMISSION_PARTY_CAPACITY_LOOKUPS, QLD_TRANSMISSION_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './config';
import { fallbackMap } from './fallback';
import { ApiTransmissionApplication2_24_0_Model, TransmissionApplication2_24_0_Model } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<QldDocumentPartyJustification, QldNameChange>({
  partyFormConfig: QLD_TRANSMISSION_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
  nameChangeConversion: QldNameChangeConversion
});

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: QLD_TRANSMISSION_ADDRESS_FORM_CONFIG
});

class Converter implements IConverter<TransmissionApplication2_24_0_Model, ApiTransmissionApplication2_24_0_Model> {
  fromApiToFormModel(apiModel: ApiTransmissionApplication2_24_0_Model, _context: IConverterContext): TransmissionApplication2_24_0_Model {
    const risInvolvedPartyIds = apiModel.partyBook
      .filter(p => p.propertiesMappedFromRis?.length)
      .map(p => p.id)
      .filter((id): id is string => id != null);

    let formModel: TransmissionApplication2_24_0_Model = {
      ...apiModel,
      applicantCapacity: QLD_TRANSMISSION_PARTY_CAPACITY_LOOKUPS[0].capacity,
      partyBookApi: apiModel.partyBook,
      partyBook: convertPartiesToFormModel_2_24(apiModel.partyBook, QLD_TRANSMISSION_PARTY_FORM_CONFIG_WITH_NAME_CHANGE, QldNameChangeConversion, risInvolvedPartyIds),
      [ADDRESS_BOOK_KEY]: convertAddressBookFromApiToFormModel(apiModel[ADDRESS_BOOK_KEY])
    };

    if (apiModel.invalidParties) {
      formModel = generateDeceasedTenancy(formModel, QLD_TRANSMISSION_PARTY_FORM_CONFIG_WITH_NAME_CHANGE, QldNameChangeConversion);
    }

    const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
    return formModelWithDefaultMapApplied;
  }

  fromFormToApiModel(formValues: TransmissionApplication2_24_0_Model, _originalApiModel: ApiTransmissionApplication2_24_0_Model): ApiTransmissionApplication2_24_0_Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook,
      partyBookApi,
      mergeFailedReason: mergeFailed,
      applicants: formApplicants,
      addressBook: formAddressBook,
      deceasedTenancyDetail,
      ...rest
    } = formModel;

    PartyJustification.adjustJustification(partyBook, getLegalEntityNameQLD);
    const { updatedParties, updatedReferences } = PartyReferencer.adjustReferences(partyBook, formApplicants.proprietorGroups[0].parties);
    formApplicants.proprietorGroups[0].parties = updatedReferences;

    if (deceasedTenancyDetail?.proprietorGroups) {
      deceasedTenancyDetail.proprietorGroups.forEach(group => {
        group.parties.forEach(({ dateOfDeath }) => {
          if (dateOfDeath?.date) {
            dateOfDeath.date = dateFormat(dateOfDeath.date as Date, DateFormatEnum.DATE);
          }
          if (dateOfDeath?.from) {
            dateOfDeath.from = dateFormat(dateOfDeath.from as Date, DateFormatEnum.DATE);
          }
          if (dateOfDeath?.to) {
            dateOfDeath.to = dateFormat(dateOfDeath.to as Date, DateFormatEnum.DATE);
          }
        });
      });
    }

    const applicants = prepareReceivingTenancyDetailForApi(formApplicants);
    const result: ApiTransmissionApplication2_24_0_Model = {
      ...rest,
      deceasedTenancyDetail,
      invalidParties: false,
      partyBook: convertPartyBookFromFormToApiModel(updatedParties, partyBookApi),
      [ADDRESS_BOOK_KEY]: convertAddressBookFromFormToApiModel(formAddressBook, _originalApiModel[ADDRESS_BOOK_KEY]),
      applicants
    };

    return result;
  }
}

export default new Converter();
