import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import endpoints from '@sympli/api-gateway/endpoints';
import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchDirectionsList } from 'src/containers/workspace/financial/directions/actions';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { DocumentFormIdentifierDisplayMapping } from 'src/models/document';
import { WorkspacePageRouteParams } from 'src/pages/workspace/models';
import { resolveDocumentDetailLink } from '../../helpers';
import JoinDialog from './JoinDialog';

interface Props {
  workspaceRole: string;
  documentType: DocumentTypeIdentifierEnum;
  documentId: string;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

function JoinDialogContainer({ workspaceRole, documentType, documentId, setOpenDialog }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId } = useRouterParams<WorkspacePageRouteParams>();
  const navigate = useNavigate();
  const workspaceOverviewLink: string = resolveDocumentDetailLink({ workspaceId, participantId, documentId });
  const documentIdentifierDisplay = DocumentFormIdentifierDisplayMapping[documentType];
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const handleOnClose = React.useCallback(
    async (confirmed?: boolean) => {
      if (!confirmed) {
        setOpenDialog(false);
        return;
      }
      setIsSubmitting(true);

      await endpoints
        .joinDocumentParticipation({ workspaceId, documentId, participantId })
        .then(() => {
          dispatch(
            actionOpenGlobalSimpleNotification({
              message: `You are now acting as a ${workspaceRole.toLocaleLowerCase()} in the ${documentIdentifierDisplay} document.`,
              variant: 'new-success',
              autoHideDuration: 5000
            })
          );
          setIsSubmitting(false);
          dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));

          navigate(workspaceOverviewLink);
        })
        .catch(error => {
          setIsSubmitting(false);
          dispatch(actionCreateGlobalErrorMessage(error));
        });

      setOpenDialog(false);
    },
    [dispatch, documentId, documentIdentifierDisplay, navigate, participantId, setOpenDialog, workspaceId, workspaceOverviewLink, workspaceRole]
  );

  return (
    <JoinDialog //
      onClose={handleOnClose}
      workspaceRole={workspaceRole}
      documentType={documentIdentifierDisplay}
      isSubmitting={isSubmitting}
    />
  );
}
export default React.memo(JoinDialogContainer);
