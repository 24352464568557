import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LodgementCaseStatusEnum } from '@sympli/api-gateway/enums';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { resolveWorkspaceDetailLink } from '../shared/detail/helpers';
import { useWorkspaceDetail } from '../shared/detail/reducers/workspaceDetail';
import { actionLaunchTitleActivityCheck } from './actions';
import { TitleRouteParams } from './models';
import { useTitleActions } from './reducers/titleActions';
import Titles from './Titles';

function TitlesContainer() {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const routeParams = useRouterParams<TitleRouteParams>();
  const { workspaceId, participantId, titleReference } = routeParams;
  const decodedTitleId = decodeURIComponent(titleReference); // we need decode the title id from url because title ID contains "/" eg: 5850/833
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const { isLoadingActivitiesCheck } = useTitleActions(workspaceId, participantId, decodedTitleId);
  const { userId, subscriberName, name } = useProfile().data!;

  const lodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceDetailState.detail?.lodgementCases);

  const performTac = React.useCallback(() => {
    dispatch(
      actionLaunchTitleActivityCheck.request({
        participantId,
        workspaceId,
        titleReference: decodedTitleId
      })
    );
  }, [dispatch, participantId, decodedTitleId, workspaceId]);

  return (
    <Titles
      // route params
      workspaceId={workspaceId}
      participant={{ id: participantId, subscriberName: subscriberName }}
      // redux data
      workspaceDetail={{
        tacKey: workspaceDetailState.detail?.tacKey,
        isLocked: workspaceDetailState.detail?.isLocked,
        supportTitleActivityCheck: workspaceDetailState.detail?.supportTitleActivityCheck,
        jurisdiction: workspaceDetailState.detail?.jurisdiction,
        lodgementCaseStatusId,
        workspaceStatus: workspaceDetailState.detail?.workspaceStatus
      }}
      titleReference={decodedTitleId}
      titleDetail={workspaceDetailState.detail?.titleReferences.find(e => e.landRegistryDetail.reference === decodedTitleId)}
      user={{ id: userId, name: name }}
      // other
      isLoading={workspaceDetailState.isLoading}
      refetching={workspaceDetailState.isRefetching}
      error={workspaceDetailState.error}
      isLoadingActivitiesCheck={isLoadingActivitiesCheck}
      tacOnClick={performTac}
      onBack={() => {
        navigate(resolveWorkspaceDetailLink({ workspaceId, participantId }));
      }}
      onTitleActivitiesCheck={performTac}
    />
  );
}

export default React.memo(TitlesContainer);
