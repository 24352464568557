import { EvidenceJurisdictionEnum } from '@sympli-mfe/enums-shared/necds';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { MergeParty } from 'src/containers/documents/party-merge/model';
import { DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS, DateOfDeathTypeEnum } from './components/date-of-death/enums';
import { EvidenceTypeEnum } from './components/evidence/enums';
import { DeceasedTenancyPartyCapacityEnum } from './enums';

export interface PartyCapacityLookup {
  capacity: DeceasedTenancyPartyCapacityEnum;
  evidenceTypes: LookupItemModel<EvidenceTypeEnum>[];
}

export class DeceasedTenancyConfig {
  partyCapacities: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[];
  maxEvidences: number;
  dateOfDeathLookupOptions: LookupItemModel<DateOfDeathTypeEnum>[];
  evidenceJurisdictionOptions: LookupEnumModel<EvidenceJurisdictionEnum>[];
  /**
   *
   */
  constructor(
    public partyCapacityLookups: PartyCapacityLookup[],
    public getLegalEntityName?: <T extends MergeParty>(party: T) => string | undefined,
    public showPartyRoleCapacityDescription?: boolean,
    public disableDefaultAddedEvidenceType?: boolean,
    public disableEvidenceType?: boolean,
    maxEvidences?: number,
    dateOfDeathLookupOptions?: LookupItemModel<DateOfDeathTypeEnum>[],
    public disableJointTenants?: boolean,
    evidenceJurisdictionOptions?: LookupEnumModel<EvidenceJurisdictionEnum>[],
    public disableProprietorCapacity?: boolean,
    public allowTodayDeathOfDate?: boolean
  ) {
    this.partyCapacities = partyCapacityLookups.map(p => ({ id: p.capacity, name: p.capacity }));
    this.disableDefaultAddedEvidenceType = disableDefaultAddedEvidenceType;
    this.showPartyRoleCapacityDescription = showPartyRoleCapacityDescription;
    this.disableEvidenceType = disableEvidenceType;
    this.maxEvidences = maxEvidences ?? 20;
    this.dateOfDeathLookupOptions = dateOfDeathLookupOptions ?? DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS;
    this.disableJointTenants = disableJointTenants;
    this.evidenceJurisdictionOptions = evidenceJurisdictionOptions ?? [];
  }
}
