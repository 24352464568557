import * as React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';
import { NavLink } from 'react-router-dom';
import { Action } from 'redux';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { NewIconSingleArrowRight } from '@sympli/ui-framework/icons';

import NoTasks from 'src/containers/workspace/shared/components/no-tasks';
import { TaskTypesEnum } from 'src/models/tasks';
import { colors } from 'src/theme';
import { resolveTaskRedirect } from './helpers';
import { WorkspaceTaskApiResponse } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  // redux data
  // tasks
  items?: Array<WorkspaceTaskApiResponse>;
  error?: string;
  isLoading?: boolean;
  // workspace detail
  workspaceStatusId?: WorkspaceStatusEnum;
  isRollover?: boolean;
  // other
  noTaskMessage?: string;
  backLink?: string;
  disabled?: boolean;
  onTaskAction?(action: Action): void;
  // feature flags
  isMLCEnabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function WithHeader({ children, ...rest }: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <div {...rest}>
      <Grid container={true} direction="row" justifyContent="space-between">
        <Typography variant="subtitle2">Your tasks</Typography>
        <Typography variant="subtitle2" className="pr-9">
          Due in
        </Typography>
      </Grid>
      {children}
    </div>
  );
}

class MyTasks extends React.PureComponent<Props> {
  render() {
    const { isLoading, error, items = [], backLink, noTaskMessage, isMLCEnabled, classes } = this.props;

    if (isLoading) {
      return (
        <div className={classes.loader}>
          <BlockLoader />
        </div>
      );
    }

    if (error) {
      return (
        <div className={classes.root}>
          <FormHelperText role="alert" error className="my-2 text-center">
            {error}
          </FormHelperText>
        </div>
      );
    }

    if (items.length === 0) {
      if (isMLCEnabled) {
        return (
          <WithHeader className={classes.root} data-testid={`workspace-tasks-${items.length}`}>
            <div className="flex h-[84px] flex-col items-center justify-center bg-[var(--greek-waters-translucent)] text-sm font-bold not-italic leading-5 text-[color:var(--sympli-green)]">
              No tasks available
            </div>
          </WithHeader>
        );
      }

      return (
        <div className={classes.root} data-testid={`workspace-tasks-${items.length}`}>
          <NoTasks //
            noTaskMessage={noTaskMessage}
            backLink={backLink}
          />
        </div>
      );
    }

    return (
      <WithHeader className={classes.root} data-testid={`workspace-tasks-${items.length}`}>
        {items.map(this.renderTaskItem)}
      </WithHeader>
    );
  }

  private renderTaskItem = (task: WorkspaceTaskApiResponse) => {
    const { classes, disabled, onTaskAction, workspaceStatusId, isRollover } = this.props;

    const { workspaceId, id: taskId, taskType, taskGroupType, participantId, data, dueDate } = task;
    const isOverdue = dueDate && dueDate.businessDaysTill < 0;
    const isErrorStyle =
      isOverdue ||
      isRollover ||
      taskType === TaskTypesEnum.ResolveDocumentIssues ||
      taskType === TaskTypesEnum.RebookSettlementDate ||
      workspaceStatusId === WorkspaceStatusEnum.Failed;

    const taskRedirect = resolveTaskRedirect({ workspaceId, participantId, taskType, taskGroupType, taskId, documentId: data?.id, data: data });
    const itemClassName = classNames(
      //
      classes.taskItem,
      disabled && classes.disabled,
      isErrorStyle && classes.taskItemError
    );

    //As per the figma design we need to display the task description in two colors(anything after second '-' should be displayed in light color)
    const descriptionSections = task.description?.split(' - ');
    const firstSection = descriptionSections?.slice(0, 2).join(' - ').trim();
    const secondSection = descriptionSections?.slice(2, descriptionSections.length).join(' - ').trim();

    return (
      <FlexLayout className={itemClassName} justifyContent="space-between" fullWidth key={`task-item-${taskId}`}>
        <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
          <NavLink //
            className={classNames(classes.taskItemDescription, disabled && classes.disabledText)}
            to={taskRedirect.link}
            onClick={e => {
              // when action is defined means there is no link, action need to be fired
              if (taskRedirect.action) {
                e.preventDefault();
                onTaskAction?.(taskRedirect.action);
              }
              if (disabled) {
                e.preventDefault();
              }
            }}
            {...(disabled
              ? {
                  //
                  'aria-disabled': true,
                  tabIndex: -1
                }
              : {})}
          >
            {firstSection && <Typography className={classes.descriptionFirstSection}>{firstSection}</Typography>}
            {secondSection && <Typography className={classes.descriptionSecondSection}> - {secondSection}</Typography>}
          </NavLink>
          {dueDate ? this.renderDueDate(dueDate.businessDaysTill) : <Typography className={classes.proposedSettlementDateTime}>-</Typography>}
        </Grid>
        {!disabled && (
          <FlexLayout alignItems="center">
            <NewIconSingleArrowRight width="20" height="20" fill={isErrorStyle ? colors.WATERMELON : colors.SUNNY_DAY} />
          </FlexLayout>
        )}
      </FlexLayout>
    );
  };

  private renderDueDate = (dueIn: number) => {
    const { classes } = this.props;

    if (!Number.isInteger(dueIn)) {
      return null;
    }

    if (dueIn === 0) {
      return <Typography className={classes.overdueWarning}>Today</Typography>;
    } else if (dueIn < 0) {
      return (
        <FlexLayout flexDirection="column">
          <Typography className={classes.overdueWarning}>
            {Math.abs(dueIn)} {pluralize('Day', Math.abs(dueIn))}
          </Typography>
          <Typography className={classes.overdueText}>overdue</Typography>
        </FlexLayout>
      );
    }

    return (
      <Typography className={classes.overdueWarning}>
        {dueIn} {pluralize('Day', dueIn)}
      </Typography>
    );
  };
}

export default withStyles(styles)(MyTasks);
