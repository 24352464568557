import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import { FinancialStatusEnum } from '@sympli/api-gateway/enums';
import { DocumentActionModel, FinancialSectionSummary } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCheck } from '@sympli/ui-framework/icons';

import Popover from 'src/components/popover';
import { getParticipantActionDisplayName } from 'src/containers/workspace/shared/components/action-label/helpers';
import { useStyles } from './styles';

interface Props {
  mode: 'light' | 'dark';
  paymentsSummary: FinancialSectionSummary;
  sourceFundsSummary: FinancialSectionSummary;
  financialActions: Array<DocumentActionModel>;
  currentParticipantId: string;
}

function SignFinancialsActionLabel(props: Props): JSX.Element | null {
  const { paymentsSummary, sourceFundsSummary, financialActions, mode } = props;
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const [showMultipleActionTooltip, setShowMultipleActionTooltip] = React.useState(false);
  const paymentsOptedOut = paymentsSummary.financialStatus === FinancialStatusEnum.NotApplicable;
  const sourceFundsOptedOut = sourceFundsSummary.financialStatus === FinancialStatusEnum.NotApplicable;

  const textStyle = mode === 'dark' ? classes.documentActionTextDark : classes.documentActionTextLight;
  const isFullyOptedOut = paymentsOptedOut && sourceFundsOptedOut;
  const isFullySigned = !isFullyOptedOut && (paymentsSummary.isFullySigned || paymentsOptedOut) && (sourceFundsSummary.isFullySigned || sourceFundsOptedOut);

  const renderSingleActionInfo = (textStyle: string) => {
    const { currentParticipantId } = props;

    const singleDocumentAction = financialActions[0];
    const actionLabel = `Signed by ${getParticipantActionDisplayName(currentParticipantId, singleDocumentAction)}`;
    return (
      <React.Fragment>
        <IconCheck className={classes.documentActionIcon} />
        <Typography className={classNames(classes.text, textStyle)}>{actionLabel}</Typography>
      </React.Fragment>
    );
  };

  const renderMultipleActionInfo = (textStyle: string) => {
    if (financialActions.length === 0) {
      return <span className={textStyle}>2 Signers required</span>;
    }

    return (
      <>
        <Popover
          PaperProps={{
            classes: {
              root: classes.toolTip
            }
          }}
          open={showMultipleActionTooltip}
          anchorEl={buttonRef.current}
          onClose={onToolTipClose}
        >
          {renderMultipleActionContent()}
        </Popover>
        <Typography className={textStyle}>
          <IconCheck className={classes.documentActionIcon} />
          {!isFullyOptedOut && (
            <span>
              {isFullySigned ? 'Signed' : 'Partially signed'} by {financialActions[0].userFullName}
            </span>
          )}
          {financialActions.length > 1 ? (
            <SympliButton
              ref={buttonRef}
              aria-label="View users signed"
              onClick={onToolTipOpen}
              className={mode === 'dark' ? classes.multipleSignersDark : classes.multipleSignersLight}
              color="inherit"
            >
              {`+${financialActions.length - 1}`}
            </SympliButton>
          ) : null}
        </Typography>
      </>
    );
  };

  const renderMultipleActionContent = () => {
    return (
      <>
        <span className={classes.documentActionToolTipTitle}>SIGNED BY</span>
        <div className={classes.documentActionToolTipContent}>
          {financialActions.map((action, index) => {
            return (
              <FlexLayout key={index} justifyContent="flex-start">
                {action.userFullName}
              </FlexLayout>
            );
          })}
        </div>
      </>
    );
  };

  const onToolTipOpen = () => {
    setShowMultipleActionTooltip(true);
  };

  const onToolTipClose = () => {
    setShowMultipleActionTooltip(false);
  };

  if (
    financialActions.length === 0 &&
    (paymentsOptedOut || paymentsSummary.workflow.requiredSigners === 1) &&
    (sourceFundsOptedOut || sourceFundsSummary.workflow.requiredSigners === 1)
  )
    return null;

  if (financialActions.length === 1 && isFullySigned) return renderSingleActionInfo(textStyle);

  return renderMultipleActionInfo(textStyle);
}

export default SignFinancialsActionLabel;
