import React, { useCallback } from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import {
  WA_ADDRESS_FORM_CONFIG as WA_M_ADDRESS_FORM_CONFIG,
  WA_ADDRESS_FORM_INITIAL_VALUES as WA_M_ADDRESS_FORM_INITIAL_VALUES
} from '@sympli-mfe/document-components/address-field/wa/2-19/config';
import { AddressBookEntityModel, AddressTypeEnum } from '@sympli-mfe/document-forms-framework/components/address-field';
import Tenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { StateEnum } from '@sympli-mfe/enums-2-19/wa';

import { TENANCY_COMPONENT_LABEL_CONFIG, WA_MORTGAGE_PARTY_FORM_FOR_MORTGAGEE_CONFIG } from '../../config';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionMortgageeDetails({ name, className, focusRef }: Props): JSX.Element {
  const filterAddressBook = useCallback((address: AddressBookEntityModel) => address.type !== AddressTypeEnum.UnstructuredAddress, []);

  return (
    <Tenancy
      name={name}
      className={className}
      isChangingMannerOfHoldingOnly={false}
      partyFormConfig={WA_MORTGAGE_PARTY_FORM_FOR_MORTGAGEE_CONFIG}
      addressFormConfig={WA_M_ADDRESS_FORM_CONFIG}
      addressFormInitialValues={WA_M_ADDRESS_FORM_INITIAL_VALUES}
      jurisdiction={StateEnum.WA}
      componentLabels={TENANCY_COMPONENT_LABEL_CONFIG}
      defaultIsTransactingParty
      filterAddressBook={filterAddressBook}
      focusRef={focusRef}
    />
  );
}

export default React.memo(SectionMortgageeDetails);
