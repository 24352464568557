import * as React from 'react';

import { ErrorBoundaryInjectedProps } from 'src/components/error-boundary';
import NonDashboardWrapper from 'src/components/layout/v2/NonDashboardWrapper';
import GeneralCrashedContent from './components/content';

function AppCrashedPage(props: Partial<ErrorBoundaryInjectedProps>): JSX.Element {
  return (
    <NonDashboardWrapper>
      <div className="p-[32px]">
        <GeneralCrashedContent {...props} />
      </div>
    </NonDashboardWrapper>
  );
}

export default React.memo(AppCrashedPage);
