import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import NavLink, { IconPosition } from '@sympli/ui-framework/components/nav-link';

import styles, { ClassKeys } from './styles';

interface BoxProps {
  title?: string | JSX.Element | null;
  typographyVariant?: 'h2' | 'subtitle1';
  linkTo?: string;
  linkIconPosition?: IconPosition;
  disabled?: boolean;
  action?: string;
  actionIcon?: string;
  onActionClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
}

type Props = BoxProps & WithStyles<ClassKeys>;

function Box(props: React.PropsWithChildren<Props>) {
  const { classes, className, style, children, title, linkTo, action, typographyVariant, linkIconPosition, disabled, actionIcon, onActionClick } = props;

  const globalCssName = 'tickle-box';

  const renderHeader = () => {
    if (!(title || linkTo || action)) {
      return null;
    }

    return (
      <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.boxHeader}>
        {renderTitle()}
        {renderAction()}
      </FlexLayout>
    );
  };

  const renderTitle = () => {
    if (linkTo) {
      return (
        <NavLink
          to={linkTo}
          className={classNames(classes.linkRoot, linkIconPosition === 'left' && classes.linkMovedLeft)}
          classes={{
            text: classes.linkText,
            icon: classes.linkIcon
          }}
          iconPosition={linkIconPosition}
          iconColor="primary"
        >
          <Typography component="div" variant={typographyVariant}>
            {title}
          </Typography>
        </NavLink>
      );
    }
    return (
      <Typography component="div" variant={typographyVariant} className={classes.title}>
        {title}
      </Typography>
    );
  };

  const renderAction = () => {
    if (!action) {
      return null;
    }

    return (
      <ButtonLink
        disabled={disabled}
        icon={actionIcon as string}
        classes={{ root: classNames(classes.actionRoot, disabled && classes.actionRootDisabled), text: classes.actionLabel, icon: classes.actionIcon }}
        onClick={onActionClick}
      >
        {action}
      </ButtonLink>
    );
  };

  return (
    <div className={classNames(classes.root, globalCssName, className)} style={style}>
      {renderHeader()}
      {children}
    </div>
  );
}

Box.defaultProps = {
  linkIconPosition: 'left',
  typographyVariant: 'subtitle1',
  disabled: false,
  actionIcon: 'add',
  onActionClick: () => {}
};

export default withStyles(styles)(Box);
