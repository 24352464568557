import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      marginBottom: 72
    },
    cardToday: {
      color: colors.SUNNY_DAY_DARK
    },
    cardStatusToday: {
      color: colors.SUNNY_DAY
    }
  }),
  {
    name: 'Aggregators'
  }
);
