import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchDocumentMultiSign } from './actions';
import { DocumentMultiSignApiResponse } from './models';

export interface DocumentMultiSignArgs {
  workspaceId: string;
  participantId: string;
}

export interface DocumentMultiSignState {
  detail?: DocumentMultiSignApiResponse;
  error?: string;
  args?: DocumentMultiSignArgs;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
}

const initialState: DocumentMultiSignState = {
  status: 'idle',
  isLoading: true,
  isRefetching: false
};

export function useDocumentMultiSign(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.documentMultiSign);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const documentMultiSignReducer = createReducer<
  //
  DocumentMultiSignState,
  Action
>(initialState)
  .handleAction(actionFetchDocumentMultiSign.request, (state, action): DocumentMultiSignState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      args: action.payload,
      error: undefined
    };
  })
  .handleAction(actionFetchDocumentMultiSign.success, (state, action): DocumentMultiSignState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: action.payload.data
    };
  })
  .handleAction(actionFetchDocumentMultiSign.failure, (state, action): DocumentMultiSignState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  });

export default documentMultiSignReducer;
