import { createSelector } from 'reselect';

import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { WorkspaceCreationErrorsMap } from 'src/containers/dashboard/components/create-new-workspace/models';
import { titleAddress } from 'src/utils/formatters';
import { TitleVerificationResponse, TitleVerificationStatusEnum, VerifiedTitleResponse } from '../../models';
import { TitleListItem, TitleListItemStatusEnum } from './models';

const DEFAULT_TITLES: VerifiedTitleResponse[] = [];
const DEFAULT_WORKSPACE_ERRORS_MAP: WorkspaceCreationErrorsMap = {};
const VIC_REGISTRAR_OF_TITLES = 'REGISTRAR OF TITLES';
interface TitleVerificationAndWorkspaceErrorsSelector {
  titleVerificationResult?: TitleVerificationResponse;
  workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap;
}

export const titleListItemsSelector = createSelector<TitleVerificationAndWorkspaceErrorsSelector, VerifiedTitleResponse[], WorkspaceCreationErrorsMap, TitleListItem[]>(
  ({ titleVerificationResult }: TitleVerificationAndWorkspaceErrorsSelector) => titleVerificationResult?.titles || DEFAULT_TITLES,
  ({ workspaceCreationErrorsMap }: TitleVerificationAndWorkspaceErrorsSelector) => workspaceCreationErrorsMap || DEFAULT_WORKSPACE_ERRORS_MAP,
  (
    //
    titles: VerifiedTitleResponse[],
    workspaceCreationErrorsMap: WorkspaceCreationErrorsMap
  ): TitleListItem[] => {
    return titles.map(t => {
      const { isElectronicLodgementEligible, titleReference, ineligibleReason, controller, address, status } = t;
      const errorFromWorkspaceCreation: string | undefined = workspaceCreationErrorsMap[titleReference];
      const combinedAddress = titleAddress(address);

      // workspace creation error takes higher priority
      if (errorFromWorkspaceCreation) {
        return {
          titleReference,
          message: errorFromWorkspaceCreation,
          status: TitleListItemStatusEnum.Error,
          address: combinedAddress
        };
      }

      switch (status) {
        case TitleVerificationStatusEnum.Success:
          // if status is Success we will explicitly ignore any ineligibleReason
          return {
            titleReference,
            status: TitleListItemStatusEnum.Success,
            message: 'Valid for electronic lodgement',
            address: combinedAddress
          };
        case TitleVerificationStatusEnum.ErrorControllerNotRegistered:
          if (controller && !controller.subscriberId) {
            return {
              titleReference,
              status: TitleListItemStatusEnum.Error,
              address: combinedAddress,
              message: `The controlling party '${controller?.name}' is not a Sympli subscriber.`
              //message: 'This title does not have a controlling party. Please contact the land registry'
            };
          }
          break;
        case TitleVerificationStatusEnum.ErrorInconsistentControllers:
          if (controller && !controller.isControlledByCurrentSubscriber) {
            return {
              titleReference,
              status: TitleListItemStatusEnum.Warning,
              message: `The controlling party is '${controller?.name}'.`,
              address: combinedAddress
            };
          }
          break;
        case TitleVerificationStatusEnum.ErrorNonElectronicLodgement:
          if (!isElectronicLodgementEligible) {
            return {
              titleReference,
              status: TitleListItemStatusEnum.Error,
              message: "We don't deal with titles that are not suitable for electronic lodgement",
              address: combinedAddress
            };
          }
          break;
        case TitleVerificationStatusEnum.WarningPaperTitle:
          if (!controller) {
            return {
              titleReference,
              status: TitleListItemStatusEnum.Warning,
              message: 'This is a paper title and is not eligible for electronic lodgement.',
              address: combinedAddress,
              additionalMessage: 'To nominate a paper title to the electronic lodgement case, please continue.'
            };
          }
          break;
        case TitleVerificationStatusEnum.TitleAlreadyAdded:
          return {
            titleReference,
            status: TitleListItemStatusEnum.Existing,
            message: 'Already part of this workspace',
            address: combinedAddress
          };
        case TitleVerificationStatusEnum.WarningControllerDoesNotExistInWorkspace:
          return {
            titleReference,
            status: TitleListItemStatusEnum.Warning,
            message:
              "The controller of the title you are attempting to add to the workspace isn't presently part of this workspace." +
              'This may cause issues with lodgement and verification',
            address: combinedAddress
          };
        case TitleVerificationStatusEnum.ErrorRegistrarOfTitles:
          if (controller && controller.name === VIC_REGISTRAR_OF_TITLES) {
            return {
              titleReference,
              status: TitleListItemStatusEnum.Error,
              message: "The controlling party is 'Registrar of Titles'.",
              address: combinedAddress
            };
          }
          break;
        case TitleVerificationStatusEnum.ErrorControllerNotRegistrarOfTitles:
        case TitleVerificationStatusEnum.ErrorControllerNotCurrentSubscriber:
          return {
            titleReference,
            status: TitleListItemStatusEnum.Error,
            message: `The controlling party is '${controller?.name}'.`,
            address: combinedAddress
          };
        case TitleVerificationStatusEnum.ErrorNoEligiblePriorityNotice:
          // this particular error returns correct ineligibleReason, no need for special handling
          break;
        case TitleVerificationStatusEnum.ErrorPaperTitle:
          return {
            titleReference,
            status: TitleListItemStatusEnum.Error,
            message: 'This is a paper title and the selected document cannot be lodged on this title.',
            address: combinedAddress
          };
        default:
          // this should never happen
          const scope = Logger.scopeWithCustomAttributes({ status, titles });
          Logger.captureException(new BusinessLogicError('Invalid title verifications status.'), scope);
          break;
      }

      // here we don't know what to do  if it did not match any of the above errors
      if (isElectronicLodgementEligible) {
        return {
          titleReference,
          status: TitleListItemStatusEnum.Success,
          message: 'Valid for electronic lodgement',
          address: combinedAddress
        };
      }

      // otherwise there must be some issue
      return {
        titleReference,
        status: TitleListItemStatusEnum.Error,
        message: ineligibleReason,
        address: combinedAddress
      };
    });
  }
);
