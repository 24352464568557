import React from 'react';

import { getIn, useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import Recipient from '../../components/recipient';
import RecipientSubscriberLookup from '../../components/recipient-subscriber-lookup/RecipientSubscriberLookup';
import { RecipientPartyModel, TransferOfControlModel } from '../../models';
import { VISIBILITY_CHECK_RECIPIENT_SUBSCRIBER_ELNO } from '../../visibilityChecks';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  disabled: boolean;
}

function SectionRecipient({ name, className, disabled }: Props): JSX.Element {
  const title = 'Recipient';
  const formikProps = useFormikContext<TransferOfControlModel>();
  const { values } = formikProps;
  const recipient: RecipientPartyModel = getIn(values, name);
  const isSympliSubRecipient = VISIBILITY_CHECK_RECIPIENT_SUBSCRIBER_ELNO(recipient);
  const fieldName = createModelKeyAppender<RecipientPartyModel>(name);

  return (
    <div data-name={name} className={className}>
      <Section //
        title={title}
        data-name={name}
      >
        <FormGroup title="Transfer control to a subscriber on" fieldAreaDirection="column">
          <RecipientSubscriberLookup name={name} disabled={disabled} isSympliSubRecipient={isSympliSubRecipient} />
        </FormGroup>

        <FormGroup title={title} fieldAreaDirection="column">
          <Recipient name={name} className={className} disabled={disabled} isSympliSubRecipient={isSympliSubRecipient} />
        </FormGroup>

        <FormGroup //
          title="VOTS ID"
          data-name={name}
          formTip="A subscribers VOTS ID identifies them at the registry, and is also known as the Customer Code."
        >
          {({ titleId }) => (
            <Field //
              aria-labelledby={titleId}
              name={fieldName('votsId')}
              disabled={disabled || isSympliSubRecipient}
              component={InputField}
            />
          )}
        </FormGroup>

        <FormGroup //
          title="Email"
          data-name={name}
        >
          {({ titleId }) => (
            <Field //
              aria-labelledby={titleId}
              name={fieldName('email')}
              disabled={disabled}
              component={InputField}
              fullWidth
            />
          )}
        </FormGroup>
      </Section>
    </div>
  );
}

export default React.memo(SectionRecipient);
