import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';

import { filterSelectedPartySiblingsFromPartyBook } from '../../helpers';
import { BasePriorityNoticeModel, PARTY_BOOK_KEY, PartyBookIdentifierModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  dialogPortalId: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
  partyFormConfig: PartyFormConfig;
}

function SectionApplicants<T extends PartLandAffectedModel>({
  //
  name,
  dialogPortalId,
  className,
  focusRef,
  partyFormConfig
}: Props): JSX.Element {
  const classes = useStyles();
  const { nextActionDisabled } = useDocumentContext();
  const { values } = useFormikContext<BasePriorityNoticeModel<T>>();

  const partyBook = values[PARTY_BOOK_KEY];
  const applicants = values.applicants;

  const createNewItem = useCallback(
    (): PartyBookIdentifierModel => ({
      partyBookId: ''
    }),
    []
  );

  const renderItem = useCallback(
    ({
      //
      item,
      itemBinding,
      itemFocusRef,
      nextItemFocusRef
    }: DocumentArrayItemRenderProps<PartyBookIdentifierModel>) => {
      const fieldName = createModelKeyAppender<PartyBookIdentifierModel>(itemBinding);

      return (
        <PartySelectField
          key={itemBinding}
          name={fieldName('partyBookId')}
          partyFormConfig={partyFormConfig}
          disabled={nextActionDisabled}
          bookRef={PARTY_BOOK_KEY}
          dialogPortalId={dialogPortalId}
          nextFocusRef={nextItemFocusRef}
          focusRef={itemFocusRef}
          className={classes.fullWidth}
          optionsOverride={filterSelectedPartySiblingsFromPartyBook(partyBook, item, applicants)}
        />
      );
    },
    [applicants, classes.fullWidth, dialogPortalId, nextActionDisabled, partyBook, partyFormConfig]
  );

  return (
    <div data-section={name} className={className}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={'Applicant'}
        disabled={nextActionDisabled}
        itemStyle="formGroup"
        minItems={1}
        maxItems={20}
        createNewItem={createNewItem}
        addButtonTitle="Add new applicant"
        focusRef={focusRef}
      />
    </div>
  );
}

export default React.memo(SectionApplicants);
