import { LookupEnumModel } from '@sympli/ui-framework/models';

export interface AbandonWorkspaceFormikModel {
  reasonOption: AbandonReasonEnum | null;
  reasonDescription?: string;
  isApplyToLinkedWorkspace: boolean;
}

export interface AbandonWorkspaceApiModel {
  reason: string;
  isApplyToLinkedWorkspace: boolean;
}

export enum AbandonReasonEnum {
  DocumentNoLongerNeeded,
  ClientsChangedTheirMinds,
  FeesUnpaid,
  SupportingDocumentationNotProvided,
  Other
}

export const ABANDON_WORKSPACE_REASON_OPTIONS: LookupEnumModel<AbandonReasonEnum>[] = [
  { id: AbandonReasonEnum.DocumentNoLongerNeeded, name: 'Document no longer needed' },
  { id: AbandonReasonEnum.ClientsChangedTheirMinds, name: 'Clients changed their minds' },
  { id: AbandonReasonEnum.FeesUnpaid, name: 'Fees not paid' },
  { id: AbandonReasonEnum.SupportingDocumentationNotProvided, name: 'Supporting documentation not provided' },
  { id: AbandonReasonEnum.Other, name: 'Other' }
];

export const AbandonWorkspaceReasonNameMap: Record<AbandonReasonEnum, string> = {
  [AbandonReasonEnum.DocumentNoLongerNeeded]: 'Document no longer needed',
  [AbandonReasonEnum.ClientsChangedTheirMinds]: 'Clients changed their minds',
  [AbandonReasonEnum.FeesUnpaid]: 'Fees not paid',
  [AbandonReasonEnum.SupportingDocumentationNotProvided]: 'Supporting documentation not provided',
  [AbandonReasonEnum.Other]: 'Other'
};
