import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { CaveatorPartyModel } from '../../models';
import { VISIBILITY_CHECK_CAVEATORS } from '../../visibilityChecks';

export default yup.mixed<CaveatorPartyModel[]>().test(
  validateWhenVisible({
    isObjectOrArray: true,
    visibilityCheck: VISIBILITY_CHECK_CAVEATORS,
    validationWhenVisible: yup.array<CaveatorPartyModel>().of(
      yup.object<CaveatorPartyModel>().shape({
        addressBookId: yup.string().default('').trim().required(msg.REQUIRED)
      })
    )
  })
);
