import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      boxSizing: 'border-box'
    },

    // title table style
    tableCell: {
      display: 'flex',
      paddingTop: 2
    },
    tableCellBody: {
      background: 'none',
      borderBottom: 0,
      borderTop: 0,
      paddingTop: 0,
      paddingBottom: 0,
      verticalAlign: 'top'
    },
    tableCellHead: {
      paddingTop: 0,
      paddingBottom: '6px !important',
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    tableRowRoot: {
      height: 'unset',
      '&:nth-of-type(1) > td': {
        paddingTop: 6
      }
    },
    tableButtonColumn: {
      paddingLeft: 0,
      width: 24
    },
    tableTitleColumn: {
      paddingLeft: 0,
      width: 100,
      fontWeight: 'bold'
    },

    // other specific style
    iconSuccess: {
      fill: theme.palette.primary.main,
      marginRight: 4,
      flexShrink: 0
    },
    iconError: {
      fill: theme.palette.error.main,
      marginRight: 4,
      flexShrink: 0
    },
    iconWarning: {
      fill: colors.ORANGE,
      marginRight: 4,
      flexShrink: 0
    },
    iconMapMarker: {
      fill: colors.BLACK,
      marginLeft: 4,
      flexShrink: 0
    },
    existingTitle: {
      fontStyle: 'italic',
      color: theme.palette.text.secondary
    },

    deleteButton: {
      width: 24,
      height: 24,
      marginTop: -2,
      marginBottom: -2
    },
    deleteButtonIcon: {
      width: 11,
      height: 12,
      fill: theme.palette.primary.main
    },
    deleteButtonIconDisabled: {
      fill: theme.palette.action.disabled
    }
  });

export type ClassKeys = typeof styles;

export default styles;
