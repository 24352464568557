import { Action, createReducer } from 'typesafe-actions';

import { ICertIdentifier, ICertInfo, IUserInfo } from '@sympli/digital-signing/interfaces';

import { ApiStatus } from 'src/utils/http';
import { actionGetCertificates, actionResetCertificates, actionUpdateSelectedCertificate } from './actions';

export interface CertificateState {
  status: ApiStatus;
  certificatesToSelect?: Array<ICertInfo>;
  previouslySavedCertIdentifier?: ICertIdentifier;
  error?: string;
  userInfo?: IUserInfo;
}

const initialState: CertificateState = {
  status: 'idle'
};

const reducer = createReducer<
  //
  CertificateState,
  Action
>(initialState)
  .handleAction(actionGetCertificates.request, (): CertificateState => {
    return {
      ...initialState, // reset state
      status: 'pending'
    };
  })
  .handleAction(actionGetCertificates.success, (state, action): CertificateState => {
    return {
      ...state,
      status: 'resolved',
      ...action.payload
    };
  })
  .handleAction(actionGetCertificates.failure, (state, action): CertificateState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateSelectedCertificate, (state, action): CertificateState => {
    if (state.status !== 'resolved') {
      return state;
    }

    return {
      ...state,
      previouslySavedCertIdentifier: action.payload
    };
  })
  .handleAction(actionResetCertificates, (): CertificateState => {
    return {
      status: 'idle'
    };
  });

export default reducer;
