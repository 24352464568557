import React, { useState } from 'react';

import { MenuItemProps } from '@mui/material/MenuItem';

import { DocumentActionTypeEnum, DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { GetDirectionTrustAccountAuthorisationRecordsApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';
import { IconUsb } from '@sympli/ui-framework/icons';

import DocumentWorkflowPanel from 'src/containers/documents/components/document-workflow-panel';
import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import ActionLabel from 'src/containers/workspace/shared/components/action-label';
import UnsignTaa from 'src/containers/workspace/shared/components/menu-items/unsign-taa';
import SignButton from 'src/containers/workspace/shared/components/sign-button';
import { resolveSignDocumentsLink } from 'src/containers/workspace/sign-documents/helpers';
import AuthorisationPermissionPanelContainer from '../directions/components/authorisation-permission-panel-container';
import TaaPreview from './components/taa-preview';
import { resolveTaaSignDocumentActionInfoSelector } from './selectors';
import { useStyles } from './styles';

interface Props {
  // route parameters
  workspaceId: string;
  participantId: string;
  trustAccountId: string;
  // redux data
  detail?: GetDirectionTrustAccountAuthorisationRecordsApiResponse;
  isLoading: boolean;
  disableNextActionButton: boolean;
}

const workflowSteps: Array<StepObjectModel<string, DocumentWorkflowStepsEnum>> = [
  {
    icon: <IconUsb />,
    label: 'Sign',
    value: DocumentWorkflowStepsEnum.Sign
  }
];

function TrustAccountAuthorisationRecords({
  //
  workspaceId,
  participantId,
  trustAccountId,
  detail,
  isLoading,
  disableNextActionButton
}: Props) {
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);
  const classes = useStyles();

  if (isLoading) {
    // TODO improve this loader
    return <BlockLoader />;
  }

  if (!detail) {
    return null;
  }

  const {
    authorisationRecordDetail: { authorisationRecordActions: documentActions },
    workflow: { requiredSigners },
    status,
    denyPermissions
  } = detail;

  const menuList: React.ReactElement<MenuItemProps>[] =
    status === DocumentStatusEnum.Signed || documentActions.some(x => x.type === DocumentActionTypeEnum.Sign)
      ? [
          <div key={0}>
            <UnsignTaa //
              queryParams={{ workspaceId, participantId, trustAccountId }}
              setIsWorkflowLoading={setIsWorkflowLoading}
            />
          </div>
        ]
      : [];
  const actionInfo = resolveTaaSignDocumentActionInfoSelector({ documentActions, requiredSigners, participantId });

  return (
    <FlexLayout flexDirection="column" fullWidth fullHeight className={classes.root}>
      <AuthorisationPermissionPanelContainer //
        workspaceId={workspaceId}
        status={status}
        participantId={participantId}
        denyPermissions={denyPermissions}
        currentWorkflowStep={DocumentWorkflowStepsEnum.Sign}
        documentType="trust account authorisation record"
      />
      <DocumentWorkflowPanel steps={workflowSteps} currentStep={DocumentWorkflowStepsEnum.Sign} menuItems={menuList} disableStepper={isWorkflowLoading} disableMenu={isLoading}>
        <ActionLabel documentActionInfo={actionInfo} mode="dark" currentParticipantId={participantId} />
        <SignButton disabled={disableNextActionButton} href={resolveSignDocumentsLink({ workspaceId, participantId })}>
          Sign
        </SignButton>
      </DocumentWorkflowPanel>
      <TaaPreview workspaceId={workspaceId} participantId={participantId} trustAccountId={trustAccountId} />
    </FlexLayout>
  );
}

export default React.memo(TrustAccountAuthorisationRecords);
