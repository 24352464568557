import createStyles from '@mui/styles/createStyles';

const styles = () =>
  createStyles({
    paper: {
      width: '400px',
      minHeight: '280px',
      marginBottom: '20px',
      padding: '41px 58px 25px',
      // border: '1px solid #ddd',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 4
    },
    logo: {
      width: 180,
      paddingBottom: 30
    },
    description: {
      padding: '10px 20px 20px',
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center'
    },

    // Loading profile
    largeLogo: {
      width: 272
    },
    progressBar: {
      height: 8,
      width: 300,
      marginTop: 58,
      marginBottom: 16
    },
    loadingText: {
      fontWeight: 'normal'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
