import { isBefore, isSameDay } from 'date-fns';
import * as yup from 'yup';

import { getTimeInJurisdiction, resolveVisibleEnumOptions } from '@sympli-mfe/document-forms-framework/utils';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { modelKey } from 'src/utils/formUtils';
import { CONSIDERATION$NON_MONETARY_CONSIDERATION$ACQUISITION_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { ConsiderationModel, MonetaryConsiderationModel, NonMonetaryConsiderationModel, NoticeOfSaleModel } from '../../models';
import {
  VISIBILITY_CHECK_CONSIDERATION,
  VISIBILITY_CHECK_CONSIDERATION$MONETARY_CONSIDERATION,
  VISIBILITY_CHECK_CONSIDERATION$NON_MONETARY_CONSIDERATION
} from '../../visibilityChecks';

export const MISS_MATCH_ACQUISITION_DATE_WITH_TAE = 'The Acquisition date must match with the Evidence date provided in other documents';
export const MISS_MATCH_ACQUISITION_DATE_WITH_TAB = 'The Acquisition date must match with the Evidence date provided in other documents';
export const MISS_MATCH_ACQUISITION_DATE_WITH_NOD = 'The Acquisition date must match with the Date of death provided in other documents';
const fieldName = modelKey<MonetaryConsiderationModel>();

const yupConsideration = yup
  .mixed()
  .nullable()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_CONSIDERATION,
      validationWhenVisible: yup
        .object<ConsiderationModel>()
        .nullable()
        .shape({
          monetaryConsideration: yup
            .mixed()
            .nullable()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_CONSIDERATION$MONETARY_CONSIDERATION,
                validationWhenVisible: yup
                  .object<MonetaryConsiderationModel>()
                  .nullable()
                  .shape({
                    purchasePrice: v.number().required(msg.REQUIRED),
                    gst: v
                      .number()
                      .required(msg.REQUIRED)
                      .min(0.0, msg.VALUE_MUST_BE_AT_LEAST_X(0.0))
                      .lessThan(yup.ref(fieldName('purchasePrice')), 'The GST amount provided is equal to or greater than the total sale price'),
                    dateOfContractForSale: yupDatePicker //
                      .required(msg.REQUIRED)
                      .testContextualRule({
                        uniqueTestName: 'Must be a date that is the same as or earlier than the Settlement Date',
                        message: 'Must be a date that is the same as or earlier than the Settlement Date',
                        requirement: (parent: MonetaryConsiderationModel): boolean => {
                          if (!parent.dateOfContractForSale || !parent.settlementDate) return true;
                          const dateOfContractForSale = getTimeInJurisdiction(new Date(parent.dateOfContractForSale), JurisdictionsEnum.NSW).setHours(0, 0, 0);
                          const settlementDate = getTimeInJurisdiction(new Date(parent.settlementDate), JurisdictionsEnum.NSW).setHours(0, 0, 0);
                          return isSameDay(settlementDate, dateOfContractForSale) || isBefore(dateOfContractForSale, settlementDate);
                        }
                      }),
                    nonFixedImprovementsIncludedIndication: yup.mixed<boolean | null>().required(msg.REQUIRED),
                    otherLandIncludedIndication: yup.mixed<boolean | null>().required(msg.REQUIRED)
                  }),
                isObjectOrArray: true
              })
            ),
          nonMonetaryConsideration: yup
            .mixed()
            .nullable()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_CONSIDERATION$NON_MONETARY_CONSIDERATION,
                validationWhenVisible: yup
                  .object<NonMonetaryConsiderationModel>()
                  .nullable()
                  .shape({
                    acquisitionType: yup
                      .mixed()
                      .required(msg.REQUIRED)
                      .test(
                        'consideration.nonMonetaryConsideration.acquisitionType enum check',
                        msg.INVALID_SELECTION,
                        function test(this: yup.TestContext<NoticeOfSaleModel>, value: any) {
                          if (value === '' || value == null) {
                            return true;
                          }
                          const values = this.options.context!;
                          const options = resolveVisibleEnumOptions<number>(CONSIDERATION$NON_MONETARY_CONSIDERATION$ACQUISITION_TYPE_LOOKUP_OPTIONS, values, this.path);
                          // check if currently selected value is valid
                          const isValid = options.some(item => item.id === value);
                          return isValid;
                        }
                      ),
                    acquisitionDate: yupDatePicker //
                      .required(msg.REQUIRED)
                      .testContextualRule({
                        message: MISS_MATCH_ACQUISITION_DATE_WITH_NOD,
                        uniqueTestName: 'acquisitionDate with Date of Death from NOD',
                        requirement: validateAcquisitionDate('NoticeOfDeath')
                      })
                      .testContextualRule({
                        message: MISS_MATCH_ACQUISITION_DATE_WITH_TAE,
                        uniqueTestName: 'acquisitionDate with Evidence Date from TAE',
                        requirement: validateAcquisitionDate('TransmissionApplicationByExecutorAdministratorTrustee')
                      })
                      .testContextualRule({
                        message: MISS_MATCH_ACQUISITION_DATE_WITH_TAB,
                        uniqueTestName: 'acquisitionDate with Evidence Date from TAB',
                        requirement: validateAcquisitionDate('TransmissionApplicationByBeneficiaryDeviseeNextOfKin')
                      })
                  }),
                isObjectOrArray: true
              })
            )
        }),
      isObjectOrArray: true
    })
  );

export default yupConsideration;

function validateAcquisitionDate(documentIdentifier: string): (parent: any, context: object) => boolean {
  return (parent: NonMonetaryConsiderationModel): boolean => {
    const findDoc = parent.relatedDocuments?.find(d => d.documentIdentifier === documentIdentifier);
    if (!findDoc) return true;

    const acquisitionDatesFromOtherDocument =
      (!parent.acquisitionDatesFromOtherDocument || !parent.acquisitionDatesFromOtherDocument.length) && parent.acquisitionDateFromOtherDocument
        ? [parent.acquisitionDateFromOtherDocument]
        : parent.acquisitionDatesFromOtherDocument;

    if (!acquisitionDatesFromOtherDocument || !acquisitionDatesFromOtherDocument.length) return true;

    const dateMatchedFromOtherDoc = acquisitionDatesFromOtherDocument.some(e => isSameDay(new Date(e), new Date(parent.acquisitionDate!)));
    return dateMatchedFromOtherDoc;
  };
}
