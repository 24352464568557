import * as React from 'react';

import classNames from 'classnames';
import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup, { FormGroupClassKeys } from '@sympli/ui-framework/components/form/layout/form-group';
import Field from '@sympli/ui-framework/components/formik/field';

import { resolveLabel } from 'src/utils/formUtils';
import WorkspaceFileUploader from '../workspace-file-uploader';
import { WorkspaceQueryParams } from '../workspace-file-uploader/models';
import { useStyles } from './styles';

interface OwnProps {
  name: string;
  titleLabel: string;
  disabled?: boolean; // disabled the dropzone
  multiple?: boolean; // allow upload of multiple files
  maxItems?: number; // maximum number of files
  maxSize?: number; // maxSize in MB
  queryParams?: WorkspaceQueryParams;
  required?: boolean;
}

export default React.memo(function WorkspaceFileField({
  name,
  titleLabel,
  disabled,
  queryParams,
  maxItems = 1,
  maxSize = 250,
  multiple = false,
  required = true
}: OwnProps): JSX.Element {
  const classes = useStyles();
  const uploaderClasses = React.useMemo(
    () => ({
      root: classes.uploaderRootGrid,
      uploadedFile: classes.afterDropZoneOrder,
      errorMessage: classNames(classes.afterDropZoneOrder, classes.attachmentErrorMessage)
    }),
    [classes]
  );

  const formGroupClasses = React.useMemo<Partial<ClassNameMap<keyof ReturnType<FormGroupClassKeys>>>>(
    () => ({
      title: classes.formGroupTitle,
      titleContainer: classes.formGroupTitleContainer
    }),
    [classes]
  );

  return (
    <FormGroup
      classes={formGroupClasses}
      className={classNames(classes.formGroup)}
      title={resolveLabel(`${titleLabel}`, required)}
      fieldAreaDirection="column"
      description={
        <div className={classes.formGroupDescription}>
          Accepted format: PDF, DOCX
          <br />
          Maximum file size: {maxSize}MB
        </div>
      }
    >
      <Field //
        disabled={disabled}
        name={name}
        multiple={multiple}
        maxItems={maxItems}
        maxSize={maxSize}
        component={WorkspaceFileUploader}
        queryParams={queryParams}
        classes={uploaderClasses}
      />
    </FormGroup>
  );
});
