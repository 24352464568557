import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    task: {
      fontSize: '14px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[50],
      padding: '14px 10px',
      boxSizing: 'border-box',
      marginTop: 4
    },
    titleColumn: {
      flex: 10,
      alignSelf: 'center'
    },
    actionColumn: {
      flex: 2,
      flexDirection: 'row',
      justifyContent: 'end'
    },
    actionColumnTitle: {
      textAlign: 'right',
      marginRight: 72
    },
    title: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 'bolder'
    },
    description: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px'
    },
    status: {
      fontSize: 14,
      lineHeight: '22px'
    },
    statusSelect: {
      width: 100,
      marginBottom: 0,
      marginLeft: 0,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#E4E5E6',
      borderRadius: 4
    },
    error: {
      color: theme.palette.error.dark,
      '& svg': {
        fill: theme.palette.error.dark
      }
    }
  }),
  {
    name: 'OffPlatformTasks'
  }
);
