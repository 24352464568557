import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

export interface Props {
  titleReference: string;
  description: string;
  dataTestId?: string;
  icon?: JSX.Element;
}

interface Styles {
  iconBackgroundColor: string;
  linearBackground: string;
}

const getStyles = (): Styles => {
  return {
    iconBackgroundColor: 'bg-[var(--watermelon)] hover:bg-[var(--watermelon-dark)]',
    linearBackground: 'linear-gradient(90deg, rgba(219, 61, 75, 0.00) 0%, #DB3D4B 100%)'
  };
};

function TitleItemWithAction({ titleReference, description, icon, dataTestId }: Props) {
  const styles = getStyles();

  return (
    <FlexLayout className="h-[42px] py-[4px]" data-testid={`${dataTestId}-text`}>
      <FlexLayout className={classNames('relative w-[56px] rounded-l-[6px]', styles.iconBackgroundColor)} justifyContent="center" alignItems="center">
        {icon}
        <div className="w-[4px] h-[42px] absolute right-[0px]" style={{ background: styles.linearBackground }}></div>
      </FlexLayout>
      <FlexLayout className="bg-[var(--neutral-000)] grow px-[8px] py-[4px]" flexDirection="column" justifyContent="center">
        <Typography variant="body2_bold">{titleReference}</Typography>
        <Typography variant="body4" className="*:text-[var(--neutral-600)]">
          {description}
        </Typography>
      </FlexLayout>
    </FlexLayout>
  );
}

export default TitleItemWithAction;
