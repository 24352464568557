import * as React from 'react';

import { FormikProps } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';

import { filterMortgagees, filterTitleReferences } from '../../helpers';
import { DischargeOfMortgage_2_23_0_Model, MortgageModel } from '../../models';

interface Props {
  name: string;
  formikProps: FormikProps<DischargeOfMortgage_2_23_0_Model>;
  disabled: boolean;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionMortgages({
  //
  name,
  formikProps,
  disabled,
  focusRef
}: Props): JSX.Element {
  const {
    values: { partyBook },
    setValues
  } = formikProps;

  const onChange = React.useCallback(
    e => {
      setValues(updatedValues => {
        const mortgagees = filterMortgagees(updatedValues);
        const titleReferences = filterTitleReferences(updatedValues);
        return {
          ...updatedValues,
          mortgagees,
          titleReferences
        };
      });
    },
    [setValues]
  );

  const resolveItemLabel = React.useCallback(
    (mortgage: MortgageModel) => {
      const mortgagees = mortgage.mortgagees.map(x => x.partyBookId);
      return (
        <>
          Mortgage being discharged:{' '}
          <strong>
            {mortgage.dealingNumber} (
            {partyBook
              ?.filter(x => mortgagees.includes(x.id))
              .map(x => x.legalEntityName)
              .join(', ')}
            )
          </strong>
        </>
      );
    },
    [partyBook]
  );

  return (
    <GenericItemSelection //
      name={name}
      mode="single" // explicitly single mode WEB-13583
      disabled={disabled}
      onChange={onChange}
      focusRef={focusRef}
      resolveItemLabel={resolveItemLabel}
      noDataMessage="No mortgages found."
    />
  );
}

export default React.memo(SectionMortgages);
