import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { DeleteFileApiModel } from './models';

export const getValidationSchema = () => {
  return yup.object<DeleteFileApiModel>({
    reason: yup
      .string()
      .required()
      .max(250, ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${value.length}/${max} Characters`)
  });
};
