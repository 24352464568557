import { JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTransactionTypeEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { VerifyTitleFormModel } from 'src/containers/shared/verify-property-section/models';
import { AllowedAdditionalParticipantRole, TitleReferenceErrorItem, WorkspaceCreationErrorsMap } from '../../models';
import { CreateFinancialWorkspaceInvitationsStepResponse, InvitationItemModel } from './steps/invite-participants/models';

export enum CreateFinancialWorkspaceStepEnum {
  AddMatterDetails = 0,
  InviteMembers = 1,
  JoinActiveWorkspace = 2
}

export interface CreateFinancialWorkspaceSharedModel {
  matterDetailsStepData: CreateFinancialWorkspaceMatterDetailsStepFormModel;
  matterDetailsStepApiResponse?: CreateFinancialWorkspaceMatterDetailsStepResponse; // response from the server
  inviteParticipantsStepData: CreateFinancialWorkspaceInviteParticipantsStepFormModel;
  inviteParticipantsStepApiResponse?: CreateFinancialWorkspaceInvitationsStepResponse; // response from server
}

export interface CreateFinancialWorkspaceMatterDetailsStepFormModel {
  createdByReference: string;
  jurisdictionId: JurisdictionsEnum | null;

  instructingOrganisationSettlementAgentId: string;
  groupId: string;
  createdByRoleId: WorkspaceRoleEnum | null;

  // * material-ui-pickers can NOT deal date with empty string, use null
  settleDate?: string | Date | null;
  // * settleTime is in jurisdictionId's timezone, 06/03/2019
  settleTime?: string;

  isNoSettlementDateTime?: boolean; // flag used in UI for a checkbox field

  isControllingParty: boolean;
  titleInformation: VerifyTitleFormModel;
  // here we store errors that came after attempt to create workspace
  workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap;
  isInteroperable?: boolean;
  transactionTypeId: WorkspaceTransactionTypeEnum | null;
  transactionTypeOtherDescription?: string;
}

export interface FinancialWorkspaceTitleCreationDetailsStepRequest {
  jurisdiction: JurisdictionsEnum;
  workspaceRole: WorkspaceRoleEnum;
  titleReferences: string[];
  workspaceType: WorkspaceTypeEnum;
  isInteroperable?: boolean;
}

export interface CreateFinancialWorkspaceMatterDetailsStepResponse {
  canCreateWorkspace: boolean;
  titleReferenceErrors: TitleReferenceErrorItem[];
  invitableRoles: WorkspaceRoleEnum[];
  vendors: LookupItemModel[];
  mortgages: Mortgage[];
  invitationDescription?: string;
  allowedAdditionalParticipantRoles: AllowedAdditionalParticipantRole[];
}

interface Mortgage {
  dealingNumber: string;
  parties: LookupItemModel[];
}

export interface InvitationModel {
  id: number;
  role?: WorkspaceRoleEnum;
  invitation: InvitationItemModel;
}

export interface WorkspaceInviteParticipantsFormModel {
  invitedRoles: Partial<Record<WorkspaceRoleEnum, boolean>>;
  invitations: InvitationModel[];
  additionalInvitations: InvitationModel[];
}

export interface CreateFinancialWorkspaceInviteParticipantsStepFormModel extends WorkspaceInviteParticipantsFormModel {
  vendors: string[];
}

export interface AdditionalParticipantRoleConfig {
  compatibleRoles: WorkspaceRoleEnum[];
  numberOfParticipantsAllowed: number;
}
