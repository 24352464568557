import React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch } from 'src/hooks';
import DirectionRecordList, { FormModel, Props } from './DirectionRecordList';

function DirectionRecordListContainer(props: Omit<Props<FormModel>, 'dispatch'>) {
  const dispatch = useSafeDispatch(useDispatch());
  return (
    <DirectionRecordList //
      {...props}
      dispatch={dispatch}
    />
  );
}

export default React.memo(DirectionRecordListContainer);
