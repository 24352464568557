import { RolePermissionsEnum } from '@sympli/api-gateway/enums';

import { AuthProfileState, useProfile } from 'src/containers/shared/auth/reducers';

export default function useRolePermission(requiredPermission: RolePermissionsEnum): boolean {
  const userProfileState: AuthProfileState = useProfile();

  if (userProfileState.status !== 'resolved') {
    return false;
  }

  const isAllowed = userProfileState.data!.rolePermissions.includes(requiredPermission);

  return isAllowed;
}
