import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-19/components/party-justification';
import { PartyModel, RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { mergeArrays } from '@sympli-mfe/document-forms-framework/utils';

import { RelinquishingTenancyPartyModel } from '../../models';
import { ApiMortgage2_19_1Model, TitleReferenceModel } from './models';

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export const filterMortgagors = <T extends Pick<ApiMortgage2_19_1Model, 'titleReferences'>>(values: T): RelinquishingTenancyPartyModel[] =>
  mergeArrays(
    getSelectedTitleReferences(values.titleReferences)
      .map(x => x.mortgagors)
      .flatMap(x => x.relinquishingProprietorGroups)
      .map(x => x.parties),
    'partyBookId'
  ) as RelinquishingTenancyPartyModel[];

export const getMortgagorPartyBookIds = defaultMemoize((selectedTitles: TitleReferenceModel[]) => {
  const mortgagorPartyBookIds = selectedTitles!
    .map(x => x.mortgagors)
    .flatMap(x => x.relinquishingProprietorGroups)
    .flatMap(x => x.parties)
    .map(x => x.partyBookId)
    .flat();
  return _uniq(mortgagorPartyBookIds);
});

const getMortgagorsLinkedToSelectedTitlesDetails = defaultMemoize((partyBook: PartyModel<WaNameChange>[], selectedTitles: TitleReferenceModel[]) => {
  const mortgagorPartyBookIds = getMortgagorPartyBookIds(selectedTitles);
  return partyBook.filter(party => mortgagorPartyBookIds.includes(party.id));
});

const getMortgagorsWithNameChange = defaultMemoize((mortgagorsLinkedToSelectedTitles: PartyModel<WaNameChange>[]) => {
  return mortgagorsLinkedToSelectedTitles.filter(mortgagor => {
    const relinquishingDetails = mortgagor.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
    return relinquishingDetails.isChangingName;
  });
});

const getMortgagorsWithoutNameChange = defaultMemoize((mortgagorsLinkedToSelectedTitles: PartyModel<WaNameChange>[]) => {
  return mortgagorsLinkedToSelectedTitles.filter(mortgagor => {
    const relinquishingDetails = mortgagor.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
    return !relinquishingDetails.isChangingName;
  });
});

const getPartyName = (organisationName: string, isSingleName: boolean, firstName: string, lastName = ''): string => {
  if (organisationName) {
    return organisationName.trim();
  } else {
    return isSingleName ? firstName.trim() : `${firstName.trim()} ${lastName.trim()}`;
  }
};

export const isMortgagorNamesMatchesOrJustified = defaultMemoize((partyBook: PartyModel<WaNameChange>[], titleReferences: TitleReferenceModel[]): boolean => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  if (selectedTitles.length <= 1) return true;

  const mortgagorsLinkedToSelectedTitles = getMortgagorsLinkedToSelectedTitlesDetails(partyBook, selectedTitles);
  const uniqueNamesCount = _uniq(mortgagorsLinkedToSelectedTitles.map(mortgagor => mortgagor.legalEntityName)).length ?? 0;
  const mortgagorCount = selectedTitles[0].mortgagors.relinquishingProprietorGroups.flatMap(x => x.parties).length;
  // Check if a justification is needed
  if (uniqueNamesCount <= mortgagorCount) return true;

  // check if someone has name justification
  return Boolean(getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles).length);
});

export const checkIfJustifiedPartiesAreTheSame = defaultMemoize((partyBook: PartyModel<WaNameChange>[], titleReferences: TitleReferenceModel[]): boolean => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  if (selectedTitles.length === 0) return false;

  return selectedTitles.some(title => {
    const mortgagorsLinkedToSelectedTitles = getMortgagorsLinkedToSelectedTitlesDetails(partyBook, [title]);

    const mortgagorsWithNameChange = getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles);
    const justifications = mortgagorsWithNameChange.map(mortgagor => mortgagor.receivingOrRelinquishingDetails) as RelinquishingModel<WaNameChange>[];
    let justifiedMortgagorsNames: string[] = [];

    // Check if more than one mortgage name change is the same
    return justifications.some(justification => {
      const { organisationName, isSingleName, firstName, lastName } = justification.nameChange!;
      const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
      if (justifiedMortgagorsNames.find(x => x === justifiedName)) {
        return true;
      }
      justifiedMortgagorsNames.push(justifiedName);
      return false;
    });
  });
});

export const checkIfMergedPartiesHaveMismatchingPartyType = defaultMemoize((partyBook: PartyModel<WaNameChange>[], titleReferences: TitleReferenceModel[]): number | null => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  if (selectedTitles.length === 0) return null;

  const mortgagorsLinkedToSelectedTitles = getMortgagorsLinkedToSelectedTitlesDetails(partyBook, selectedTitles);

  const mortgagorsWithNameChange = getMortgagorsWithNameChange(mortgagorsLinkedToSelectedTitles);
  if (mortgagorsWithNameChange.length === 0) return null;

  const mortgagorsWithoutNameChange = getMortgagorsWithoutNameChange(mortgagorsLinkedToSelectedTitles);
  if (mortgagorsWithoutNameChange.length === 0) return null;

  for (const mortgagorWithNameChange of mortgagorsWithNameChange) {
    const { nameChange } = mortgagorWithNameChange.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange>;
    const { organisationName, isSingleName, firstName, lastName } = nameChange!;
    const justifiedName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();

    for (const mortgagorWithoutNameChange of mortgagorsWithoutNameChange) {
      const { organisationName, isSingleName, firstName, lastName } = mortgagorWithoutNameChange;
      const partyName = getPartyName(organisationName, isSingleName, firstName, lastName).toLowerCase();
      if (justifiedName === partyName && mortgagorWithNameChange.partyType !== mortgagorWithoutNameChange.partyType) {
        return mortgagorWithoutNameChange.partyType;
      }
    }
  }

  return null;
});

export const ADDITIONAL_COVENANT_WARNING_VALUES: string[] = ['trust', 'trustee', 'beneficial', 'benefitial', 'benefisial', 'beneficiary', ' benefitiary'];
