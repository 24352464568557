import endpoints from '@sympli/api-gateway/endpoints';

export interface ReportDetailApiRequest {
  workspaceId: string;
  participantId: string;
  reportId: string;
}

export const getReport = (args: ReportDetailApiRequest) => {
  // const { workspaceId, reportId, participantId } = args;
  // const svc = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/reports/${encodeURIComponent(reportId)}`;
  // return http.get<WorkspaceReportApiResponse>(svc);
  return endpoints.getWorkspaceReportDetail(args);
};
