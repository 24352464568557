import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';

import { isMortgageeMismatch } from '../../helpers';
import { DischargeOfMortgage2_24_0Model, MortgageePartyModel } from '../../models';
import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from '../../visibilityChecks';

// path: mortgagees
const yupMortgagees = yup.mixed<MortgageePartyModel[]>().test(
  'mortgagees[*] name mismatch',
  'The parties in the selected dealings do not match. Please remove the mismatched dealings and add a new document to transact on other dealings.', //
  function test(this: yup.TestContext<DischargeOfMortgage2_24_0Model>, value: any) {
    if (!this.options.context) return false;
    return !isMortgageeMismatch(this.options.context);
  }
);

export default yup //
  .mixed<MortgageePartyModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES,
      validationWhenVisible: yupMortgagees,
      isObjectOrArray: true
    })
  );
