import * as React from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { Box } from 'src/components/layout';
import { LineLoader, ListLoader } from 'src/components/loaders';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { useStyles } from './styles';

function WorkspaceDetailPageLoader() {
  const classes = useStyles();

  return (
    <WorkspacePageContentWrapper>
      <div className={classes.headerLoaderContainer}>
        <LineLoader color="white" variant="small" widthPercent={100} classes={{ root: classes.headerLoader }} />
      </div>
      <Tabs value="1" indicatorColor="primary" textColor="inherit">
        <Tab //
          value="1"
          label={<LineLoader variant="small" icon widthPercent={100} style={{ marginBottom: 0 }} />}
          classes={{ root: classes.tab }}
          disabled
        />
        <Tab //
          label={<LineLoader variant="small" icon widthPercent={100} style={{ marginBottom: 0 }} />}
          classes={{ root: classes.tab }}
          disabled
        />
      </Tabs>

      <FlexLayout>
        <div className={classes.loader}>
          <BlockLoader />
        </div>
        <FlexLayout flexDirection="column">
          <Box //
            title="Participants"
            disabled
            className={classes.participantBox}
          >
            <ListLoader //
              lineCount={2}
              color="white"
              disableAvatar
            />
          </Box>
        </FlexLayout>
      </FlexLayout>
    </WorkspacePageContentWrapper>
  );
}

export default WorkspaceDetailPageLoader;
