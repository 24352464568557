import React from 'react';

import Typography from '@mui/material/Typography';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

type Props = {
  title: string;
  actionText?: string;
  onClick?(): void;
};

function SourceFundMessage(props: Props) {
  const classes = useStyles();
  return (
    <FlexLayout alignItems="center">
      <Typography className={classes.title}>{props.title}</Typography>
      {props.actionText && (
        <ButtonLink className={classes.buttonText} onClick={props.onClick} color="inherit">
          {props.actionText}
        </ButtonLink>
      )}
    </FlexLayout>
  );
}

export default React.memo(SourceFundMessage);
