import { ISignRequestPayload, ISignResponse } from '@sympli/digital-signing/interfaces';

import { FinancialLineItemXml } from 'src/containers/workspace/sign-documents/models';
import { getSignedData } from 'src/utils/signing/helpers';
import { OnSignArgs, SignedDocumentCounterpart, SignedDocumentDataEntity } from './models';

export interface ToBeMultiSignDocumentsModel {
  documents: ToBeSignedDocumentApiResponse[];
  trustAccountAuthorisationRecordsXmlStrings: TrustAccountAuthorisationRecordsXmls[];
  paymentXmlStrings: FinancialLineItemXml[];
  sourceFundXmlStrings: FinancialLineItemXml[];
}

export interface TrustAccountAuthorisationRecordsXmls {
  trustAccountId: string;
  xmlString: string;
}

export interface MultiSignedDocumentModel {
  signingInfo: ISignResponse['signingInfo'];
  signedDocuments: SignedDocumentDataEntity[];
  signedTrustAccountAuthorisationRecords: SignedTrustAccountAuthorisationRecord[];
  signedPayments: SignedFinancialLineItem[];
  signedSourceFunds: SignedFinancialLineItem[];
}

export interface ToBeSignedDocumentApiResponse {
  documentId: string;
  executionItems: Array<ToBeSignedDocumentCounterpart>;
}

export interface ToBeSignedDocumentCounterpart {
  counterpartData: string;
  renderedData: string;
  signingPartyRole: number;
  verificationErrors: Array<string>;
}

export interface SignedTrustAccountAuthorisationRecord {
  trustAccountId: string;
  signedXml: string;
}

export interface SignedFinancialLineItem {
  id: string;
  signedXml: string;
}

type MultiSignDocumentsArgs = OnSignArgs & ToBeMultiSignDocumentsModel;

export async function multiSignDocuments({
  //
  documents,
  paymentXmlStrings,
  sourceFundXmlStrings,
  trustAccountAuthorisationRecordsXmlStrings,
  certificateIdentifier,
  userInfo,
  logGroupId // WEB-27575
}: MultiSignDocumentsArgs): Promise<MultiSignedDocumentModel> {
  const payloads: ISignRequestPayload[] = [];

  // 1. documents
  // ! explicitly execute this in series
  for (let i = 0; i < documents.length; i++) {
    const { executionItems } = documents[i];
    for (let j = 0; j < executionItems.length; j++) {
      const { counterpartData, renderedData } = executionItems[j];
      payloads.push({
        payload: counterpartData,
        identifier: '//CounterpartData',
        xPath: '//CounterpartData'
      });
      payloads.push({
        payload: renderedData,
        identifier: '//RenderedData',
        xPath: '//RenderedData'
      });
    }
  }

  // 2. Distributions
  for (const p of paymentXmlStrings) {
    payloads.push({
      payload: p.xmlString,
      identifier: '//SigningDetail',
      xPath: '//SigningDetail'
    });
  }

  for (const s of sourceFundXmlStrings) {
    payloads.push({
      payload: s.xmlString,
      identifier: '//SigningDetail',
      xPath: '//SigningDetail'
    });
  }

  // 4. TrustAccountAuthorisationRecords
  for (const ta of trustAccountAuthorisationRecordsXmlStrings) {
    payloads.push({
      payload: ta.xmlString,
      identifier: '//SigningDetail',
      xPath: '//SigningDetail'
    });
  }

  const { payloads: signedData, signingInfo } = await getSignedData({
    //
    payloads,
    certificateIdentifier,
    userInfo,
    logGroupId // WEB-27575
  });

  // we need to rewind
  // It must follow exact the same order that how the payload was populated
  let currentSignedDataIndex = 0;

  // 1. signedDocuments
  const signedDocuments: SignedDocumentDataEntity[] = [];

  for (let i = 0; i < documents.length; i++) {
    const { documentId, executionItems } = documents[i];

    const signedCounterpartItems: SignedDocumentCounterpart[] = [];
    for (let j = 0; j < executionItems.length; j++) {
      const { signingPartyRole } = executionItems[j];
      const signedCounterpartData = signedData[currentSignedDataIndex];
      const signedRenderedData = signedData[currentSignedDataIndex + 1];
      signedCounterpartItems.push({
        // pass back only base64. This is only needed for documents.
        signedCounterpartData: signedCounterpartData.base64,
        signedRenderedData: signedRenderedData.base64,
        signingPartyRole
      });
      currentSignedDataIndex = currentSignedDataIndex + 2;
    }

    signedDocuments.push({
      documentId,
      signedCounterpartItems
    });
  }

  //2. signed Distributions
  const signedPayments: SignedFinancialLineItem[] = [];
  for (const p of paymentXmlStrings) {
    signedPayments.push({
      id: p.id,
      signedXml: signedData[currentSignedDataIndex].xml
    });
    currentSignedDataIndex++;
  }

  const signedSourceFunds: SignedFinancialLineItem[] = [];
  for (const s of sourceFundXmlStrings) {
    signedSourceFunds.push({
      id: s.id,
      signedXml: signedData[currentSignedDataIndex].xml
    });
    currentSignedDataIndex++;
  }

  // 4. signed TrustAccountAuthorisationRecords
  const signedTrustAccountAuthorisationRecords: SignedTrustAccountAuthorisationRecord[] = [];
  for (const ta of trustAccountAuthorisationRecordsXmlStrings) {
    const signedXml = signedData[currentSignedDataIndex].xml;
    signedTrustAccountAuthorisationRecords.push({
      trustAccountId: ta.trustAccountId,
      signedXml
    });
    currentSignedDataIndex++;
  }

  return {
    signingInfo,
    signedDocuments,
    signedPayments,
    signedSourceFunds,
    signedTrustAccountAuthorisationRecords
  };
}
