import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '20px 30px',
      background: theme.palette.grey[100],
      marginBottom: 24
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 14
    },
    description: {
      fontSize: 16,
      lineHeight: 1.2
    }
  }),
  {
    name: 'SettingSectionTopAlert'
  }
);
