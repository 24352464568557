import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  header: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class DirectionHeaderBox extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, header, description, children, className } = this.props;
    return (
      <div className={classNames(classes.root, className)}>
        <FlexLayout alignItems="baseline">
          <Typography variant="subtitle1" className={classes.header}>
            {header}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </FlexLayout>
        {children}
      </div>
    );
  }
}

const styledComponent = withStyles(styles)(DirectionHeaderBox);

export default styledComponent;
