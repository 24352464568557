import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingTop: 0,
      paddingBottom: 0
    },
    root: {
      '&$disabled': {
        opacity: 1
      },
      '&$focusVisible': {
        background: 'rgba(0, 0, 0, 0.04)'
      }
    },
    focusVisible: {},
    disabled: {},
    link: {
      padding: '6px 0px',
      width: '100%',
      textDecoration: 'none'
    },
    selected: {
      background: theme.palette.common.white
    }
  });

export type ClassKeys = typeof styles;

export default styles;
