import * as React from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';

import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';

import DownloadOption from './download-option/DownloadOption';

export interface DownloadItem {
  icon?: JSX.Element;
  text: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void | Promise<void>;
  className?: string;
  id?: string;
}

export interface Props {
  items?: Array<DownloadItem>;
  open: boolean;
  onClickAway?: () => void;
  onItemSelected?: () => void;
  className?: string;
  truncateAt?: number;
  arrowOffset?: number;
}

const DownloadMenu = ({ items, open, onClickAway, onItemSelected, truncateAt, arrowOffset }: Props) => {
  const [loadingDocumentIds, setLoadingDocumentIds] = React.useState<string[]>([]);

  if (!open || !items?.length) return null;

  const handleClickAway = () => {
    onClickAway?.();
  };

  const handleItemSelected = () => {
    onItemSelected?.();
  };

  const handleOnDownload = async ({ id, text, onClick }: DownloadItem, event: React.MouseEvent<HTMLElement>) => {
    const fileId = id ?? text;
    setLoadingDocumentIds([...loadingDocumentIds, fileId]);
    await Promise.resolve(onClick(event)).finally(() => {
      setLoadingDocumentIds(loadingDocumentIds.filter(d => d !== fileId));
    });
  };

  return (
    <ArrowBox backgroundColor="white" borderRadius={5} padding="none" offset={arrowOffset ?? 0} className="absolute top-[36px] w-max">
      <ClickAwayListener onClickAway={handleClickAway}>
        <MenuList autoFocusItem={open} id="menu-list-grow">
          {items.map(item => (
            <DownloadOption
              key={item.text}
              icon={item.icon}
              text={item.text}
              truncateAt={truncateAt}
              onClick={e => {
                e.stopPropagation();
                handleOnDownload(item, e);
              }}
              handleSelectionCallback={handleItemSelected}
              isLoading={loadingDocumentIds.includes(item.id ?? item.text)}
            />
          ))}
        </MenuList>
      </ClickAwayListener>
    </ArrowBox>
  );
};

export default DownloadMenu;
