import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

enum FrequencyEnum {
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year'
}

export const FREQUENCY_OPTIONS: LookupEnumModel<FrequencyEnum>[] = [
  { id: FrequencyEnum.Day, name: 'Day' },
  { id: FrequencyEnum.Week, name: 'Week' },
  { id: FrequencyEnum.Fortnight, name: 'Fortnight' },
  { id: FrequencyEnum.Month, name: 'Month' },
  { id: FrequencyEnum.Quarter, name: 'Quarter' },
  { id: FrequencyEnum.Year, name: 'Year' }
];

const LeaseApplicableAttachmentDocumentTypes: AttachmentTypeEnum[] = [
  AttachmentTypeEnum.Plan,
  AttachmentTypeEnum.CaveatorsConsent,
  AttachmentTypeEnum.MinistersConsent,
  AttachmentTypeEnum.MortgageesConsent,
  AttachmentTypeEnum.StatutoryDeclaration,
  AttachmentTypeEnum.SupportingEvidence
];
export const LEASE_ATTACHMENT_DOCUMENT_TYPE_CONDITIONS_AND_PROVISIONS: LookupEnumModel<AttachmentTypeEnum>[] = [
  { id: AttachmentTypeEnum.ConditionsAndProvisions, name: AttachmentTypeEnum.ConditionsAndProvisions }
];
export const LEASE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS: LookupEnumModel<AttachmentTypeEnum>[] = LeaseApplicableAttachmentDocumentTypes.map(
  (x): LookupEnumModel<AttachmentTypeEnum> => ({ id: x, name: x })
);
