import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { NoticeOfSaleModel } from './models';
import { VISIBILITY_CHECK_PROPERTY$SHARE_TRANSFERRED } from './visibilityChecks';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<NoticeOfSaleModel> = {
  relatedDocuments: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        propertyAddress: NO_FALLBACK
      }
    }
  },
  detailsForServiceOfNotices: {
    $children: {
      ownerAgent: [null],
      partyBookId: [''],
      addressBookId: ['']
    }
  },
  consideration: {
    $children: {
      considerationType: NO_FALLBACK,
      isMonetaryConsideration: [false],
      monetaryConsideration: {
        $children: {
          purchasePrice: [null],
          gst: [null],
          dateOfContractForSale: [null],
          settlementDate: [null],
          nonFixedImprovementsIncludedIndication: [null],
          otherLandIncludedIndication: [null]
        }
      },
      nonMonetaryConsideration: {
        $children: {
          acquisitionType: [null],
          acquisitionDate: [null],
          acquisitionDateFromOtherDocument: NO_FALLBACK,
          acquisitionDatesFromOtherDocument: NO_FALLBACK,
          relatedDocuments: NO_FALLBACK,
          hideCourtOrderAcquisitionType: NO_FALLBACK,
          hideDeedOfAssignmentAcquisitionType: NO_FALLBACK,
          hideDeedOfPartitionAcquisitionType: NO_FALLBACK,
          hideIntergenerationalAssignmentAcquisitionType: NO_FALLBACK,
          hideOtherAcquisitionTypeAcquisitionType: NO_FALLBACK,
          hideWillOrIntestacyAcquisitionType: NO_FALLBACK
        }
      }
    }
  },
  property: {
    $children: {
      transferArrangement: [null],
      addressBookId: [''],
      natureOfProperty: [null],
      area: {
        $children: {
          unit: [null],
          numberOfUnits: [null]
        }
      },
      isPartLandAffected: NO_FALLBACK,
      shareTransferred: {
        $objectVisibility: [VISIBILITY_CHECK_PROPERTY$SHARE_TRANSFERRED, null as any],
        $children: {
          id: NO_FALLBACK,
          numerator: [null],
          denominator: [null]
        }
      }
    }
  },
  isPropertyHidden: NO_FALLBACK
};
