import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DealingRequirementsSummaryModel, IssuingInstructionsModel, LodgementInstructions_2_25_2 } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - directionPreference
 - lodgementOrderSummary

 schema version:
[
  {
    "isResponsibleParticipant": true
  }
]
*/
export const VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY = createContextCheck(
  (context: LodgementInstructions_2_25_2): boolean => context.responsibleParticipantId === context.currentParticipantId
);

/**
 path:
 - dealingRequirementsSummary.dealingRequirementsDetail

 schema version:
[
  {
    "hasAddedDealingRequirements": true
  }
]
*/
export const VISIBILITY_CHECK_DEALING_REQUIREMENTS_SUMMARY$DEALING_REQUIREMENTS_DETAIL = createContextCheck(
  (context: DealingRequirementsSummaryModel): boolean => context.hasAddedDealingRequirements === true
);

export const VISIBILITY_CHECK_ISSUING_INSTRUCTIONS_ENABLED = createContextCheck((context: IssuingInstructionsModel): boolean => context.editable);
