import { ActiveWorkspaceItem, Title } from '../search-join-table/models';
import { JoinWorkspaceDialogModel } from './models';

export const convertWorkspaceDetail = (item: ActiveWorkspaceItem): JoinWorkspaceDialogModel => {
  return {
    workspaceId: item.workspaceId,
    sympliId: item.sympliId,
    titleReferences: item.titles.map((x: Title) => {
      return {
        reference: x.reference,
        address: x.address,
        proprietors: x.proprietors.map(p => ({ legalEntityName: p })),
        landDescriptions: x.landDescriptions
      };
    }),
    jurisdictionId: item.jurisdiction,
    proposedSettlementDate: item.expectedSettlementDate?.workspaceDateTime ?? item.pendingProposedSettlementDate,
    expectedSettlementDate: item.expectedSettlementDate,
    participants: item.participants
  };
};
