import { WA_ADDRESS_FORM_CONFIG as WA_DM_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/wa/2-27/config';
import { waNameChangeConversion } from '@sympli-mfe/document-components/party-form/wa/2-27/components/party-justification';
import { convertAttachmentsFromFormToApiModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { waAddressChangeConversion } from '../../components/address-justification';
import { WA_DM_PARTY_FORM_CONFIG } from './config';
import { WA_DM_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { fallbackMap } from './fallback';
import { filterMortgagees, filterTitleReferences, getMortgageePartyBookIds } from './helpers';
import { ApiDischargeOfMortgageDocument2_27_0Model, DischargeOfMortgageDocument2_27_0Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: WA_DM_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: WA_DM_PARTY_FORM_CONFIG,
  nameChangeConversion: waNameChangeConversion,
  addressChangeConversion: waAddressChangeConversion
});

class Converter implements IConverter<DischargeOfMortgageDocument2_27_0Model, ApiDischargeOfMortgageDocument2_27_0Model> {
  fromApiToFormModel(apiValues: ApiDischargeOfMortgageDocument2_27_0Model, context: IConverterContext): DischargeOfMortgageDocument2_27_0Model {
    // Implement any necessary conversion from API to form values
    const relinquishingIds = getMortgageePartyBookIds(apiValues.mortgages) as string[];
    let formModel: DischargeOfMortgageDocument2_27_0Model = {
      ...apiValues,
      partyBook: convertPartyBookFromApiToFormModel(apiValues.partyBook || [], relinquishingIds),
      addressBook: convertAddressBookFromApiToFormModel(apiValues.addressBook || []),
      titleReferences: filterTitleReferences(apiValues),
      mortgagees: filterMortgagees(apiValues)
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: DischargeOfMortgageDocument2_27_0Model, originalApiModel: ApiDischargeOfMortgageDocument2_27_0Model): ApiDischargeOfMortgageDocument2_27_0Model {
    let formModel = applyVisibilityFallbackMap(formValues, fallbackMap);

    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      addressBook: addressBookFromForm,
      titleReferences,
      mortgagees,
      ...rest
    } = formModel;

    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
      addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
      attachments: convertAttachmentsFromFormToApiModel(formModel.additionalAttachments, WA_DM_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS),
      mortgages: formModel.mortgages.map(x => {
        return {
          ...x,
          titleReferences: x.isSelected
            ? x.titleReferences.map(t => {
                return titleReferences.find(e => e.reference === t.reference) || t;
              })
            : x.titleReferences,
          mortgageeDetails: {
            ...x.mortgageeDetails,
            relinquishingProprietorGroups: x.mortgageeDetails.relinquishingProprietorGroups.map(g => {
              return {
                ...g,
                parties: g.parties.map(m => {
                  return mortgagees.find(x => x.partyBookId === m.partyBookId) || m;
                })
              };
            })
          }
        };
      })
    };
  }
}

export default new Converter();
