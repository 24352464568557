import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { OnlineStatusEnum } from './models';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 7,
      height: 7,
      border: `2px solid ${theme.palette.common.white}`,
      borderRadius: '50%'
    },
    status: {
      [`&.${OnlineStatusEnum.Offline}`]: {
        backgroundColor: '#EF5350'
      },
      [`&.${OnlineStatusEnum.Online}`]: {
        backgroundColor: '#8BC34A'
      },
      // this is used for settlement date acceptance status
      [`&.${OnlineStatusEnum.Idle}`]: {
        backgroundColor: '#F58923'
      }
    },
    tooltip: {
      '&:hover': {
        cursor: 'help'
      }
    },
    small: {
      width: 5,
      height: 5,
      borderWidth: 1
    }
  });

export type ClassKeys = typeof styles;

export default styles;
