import queryString from 'query-string';

import { RescindApprovalModel, RescindDisabledReasonEnum } from 'src/containers/workspace/financial/settlement-date/models';

export const getSubmitTime = (selectedDate: string, selectedTime: string) => {
  return selectedDate + (selectedTime ? `T${selectedTime}` : '');
};

interface SettlementDateLinkModel {
  workspaceId: string;
  participantId?: string;
  declined?: boolean;
}

export function resolveSettlementDateLink(params: SettlementDateLinkModel) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);

  return `/workspace/${encodeURIComponent(workspaceId)}/settlement-date?${query}`;
}

export enum TimeFormatTypeEnum {
  _24H,
  _12H
}

// time: '13' | '13:00' | '13:00:00' => '1 PM' | '1:00 PM' | '1:00:00 PM'
export function timeStringConvert(time: string, convertTo: TimeFormatTypeEnum = TimeFormatTypeEnum._12H) {
  switch (convertTo) {
    case TimeFormatTypeEnum._12H: {
      const [hourString, minuteString, secondString] = time.trim().split(':');
      const hourNumber = Number(hourString);
      const newHour = hourNumber % 12 || 12;
      const period = hourNumber < 12 || hourNumber === 24 ? 'AM' : 'PM';
      return newHour + (minuteString ? `:${minuteString}` : '') + (secondString ? `:${secondString}` : '') + ` ${period}`;
    }
    default: {
      // TODO need a standardrized time string
      return time;
    }
  }
}

const getRescindDisabledReasonTemplate = (reason: RescindDisabledReasonEnum): JSX.Element => {
  return (
    <span>
      <strong>The button is disabled</strong> as {reason}
    </span>
  );
};

export function getRescindApprovalData(
  currentParticipantId: string,
  isProposedSettlementDateInPast: boolean,
  proposedParticipantId?: string,
  settlementAcceptedByParticipants?: string[]
): RescindApprovalModel {
  if (isProposedSettlementDateInPast) {
    return {
      canRescind: false,
      reason: getRescindDisabledReasonTemplate(RescindDisabledReasonEnum.PastSettlementDate)
    };
  }

  if (proposedParticipantId === currentParticipantId) {
    return {
      canRescind: false,
      reason: getRescindDisabledReasonTemplate(RescindDisabledReasonEnum.ProposingParticipant)
    };
  }

  if (!settlementAcceptedByParticipants?.includes(currentParticipantId)) {
    return {
      canRescind: false,
      reason: getRescindDisabledReasonTemplate(RescindDisabledReasonEnum.ProposalNotAccepted)
    };
  }

  return { canRescind: true };
}
