import * as React from 'react';

import { FieldArray, FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';

import { DischargeOfMortgage_2_26_0_Model, MortgageePartyModel } from '../../models';
import Mortgagee from '../mortgagee';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
type FormValues = DischargeOfMortgage_2_26_0_Model;

interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function MortgageePartyReadonlyArray(props: Props): JSX.Element {
  const { name, formikProps, classes, focusRef } = props;
  const { values } = formikProps;
  const items: MortgageePartyModel[] = _get(values, name) ?? [];
  const count = items.length;
  const renderItem = (item: MortgageePartyModel, itemIndex: number, count: number) => {
    const { disabled, formikProps, name } = props;
    const hasMultipleItems = count > 1;
    const itemBindingPath = `${name}[${itemIndex}]`;
    const title = hasMultipleItems ? `Mortgagee ${itemIndex + 1}` : 'Mortgagee';

    return (
      <FormGroup title={title} fieldAreaDirection="column" key={`item-mortgagees[${itemIndex}]`}>
        <Mortgagee //
          dialogPortalId={props.dialogPortalId}
          disabled={disabled}
          formikProps={formikProps}
          name={itemBindingPath}
          focusRef={focusRef}
        />
      </FormGroup>
    );
  };

  return (
    <div data-name={name} className={classes.root}>
      <FieldArray //
        name={name}
        render={() => items.map((item, itemIndex) => renderItem(item, itemIndex, count))}
      />
    </div>
  );
}

export default withStyles(styles)(MortgageePartyReadonlyArray);
