import * as React from 'react';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';
import MessengerActionBox from './MessengerActionBox';
import MessengerActionBox101 from './MessengerActionBox101';

interface Props {
  children: React.ReactNode;
}

function MessengerActionBoxContainer({ children }: Props) {
  const isWorkspaceMessenger101Enabled = useFeatureFlag(FeatureToggleEnum.workspaceMessenger101Enabled);

  if (isWorkspaceMessenger101Enabled) {
    return <MessengerActionBox101>{children}</MessengerActionBox101>;
  }

  return <MessengerActionBox>{children}</MessengerActionBox>;
}

export default MessengerActionBoxContainer;
