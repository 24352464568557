import { useCallback, useEffect, useRef, useState } from 'react';

import { TableauInitialiseData } from '../models';

export const useTableau = () => {
  let vizContainerRef = useRef<HTMLDivElement>(null);
  const [viz, setViz] = useState<tableau.Viz | null>(null);
  const [initialiseData, setInitialiseData] = useState<TableauInitialiseData | null>(null);
  const [tableauInstanceCreated, setTableauInstanceCreated] = useState<boolean>(false);
  const [tableauHasLoadedReport, setTableauHasLoadedReport] = useState<boolean>(false);

  const initialiseViz = useCallback((containerDiv: HTMLDivElement | null, initialiseData: TableauInitialiseData | null) => {
    if (initialiseData == null || containerDiv == null) {
      return null;
    }
    const url = initialiseData.reportLink;
    const options: tableau.VizCreateOptions = {
      width: '100%',
      height: '100%',
      hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: (e: tableau.TableauEvent) => {
        setTableauHasLoadedReport(true);
      },
      ...initialiseData.filters //applying existing filters before Viz Load
    };
    setViz(new tableau.Viz(containerDiv, url, options));
    setTableauInstanceCreated(true);
  }, []);

  useEffect(() => {
    if (!viz && initialiseData && !tableauInstanceCreated) {
      initialiseViz(vizContainerRef.current, initialiseData);
    }

    return () => {
      if (viz) {
        viz.dispose();
        setViz(null);
        setTableauInstanceCreated(false);
        setTableauHasLoadedReport(false);
      }
    };
  }, [initialiseData, initialiseViz, vizContainerRef, viz, tableauInstanceCreated]);

  return {
    setInitialiseData,
    tableauRef: vizContainerRef,
    viz,
    tableauHasLoadedReport
  };
};
