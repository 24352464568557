import * as yup from 'yup';

import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { ISSUING_INSTRUCTION_TYPE_LOOKUP_OPTIONS, IssuingInstructionTypeEnum } from '../../enums';
import { IssuingInstructionsModel, LodgementInstructions_2_25_2 } from '../../models';
import { VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY, VISIBILITY_CHECK_ISSUING_INSTRUCTIONS_ENABLED } from '../../visibilityChecks';

export function yupIssuingInstructionsValidationSchema<CRoot extends object = {}>() {
  const yupDirectionPreference = yup.object<IssuingInstructionsModel, CRoot>({
    editable: yup.bool(),
    issuingInstructionType: validateWhenVisible2<IssuingInstructionTypeEnum | undefined>({
      visibilityCheck: (parent: LodgementInstructions_2_25_2, context: LodgementInstructions_2_25_2) =>
        VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY(context) && VISIBILITY_CHECK_ISSUING_INSTRUCTIONS_ENABLED(context.issuingInstructions!),
      validationWhenVisible: yup //
        .mixed<number | null>()
        .required(msg.REQUIRED)
        .oneOf(getLookupValuesAllowingEmpty(ISSUING_INSTRUCTION_TYPE_LOOKUP_OPTIONS), msg.INVALID_SELECTION)
    }),
    cordConsentRequired: yup.bool()
  });

  return memoizeSchemaWithContext(yupDirectionPreference, (parentContext: CRoot): CRoot => parentContext);
}

export default yupIssuingInstructionsValidationSchema;
