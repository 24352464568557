import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

import Markdown, { MarkdownToJSX } from '@sympli/markdown-to-jsx';

type AnchorTagProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

type LinkOverrides = {
  /**
   * link href resolver
   * @param origHref - original href attribute
   * @returns modified href
   */
  resolveHref?(origHref: string): string;

  // /**
  //  * allows to trigger a custom callback whenever user clicks on the link
  //  */
  // onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  // /**
  //  * disable link and render button instead
  //  */
  // disabled?: boolean;
  //
} & Partial<
  Pick<
    AnchorTagProps,
    | 'className' //
    | 'target'
    | 'style'
  >
>;

interface Props {
  linkOverrides?: LinkOverrides;
  /**
   * markdown text to be rendered
   */
  children: string;
}

function convertToLinkKnownFormat(href): LinkProps['to'] {
  if (href.startsWith('http')) {
    return { pathname: href };
  }
  return href;
}

function resolveLinkProps(
  {
    //
    href = '',
    ...aRest
  }: AnchorTagProps,
  {
    //
    resolveHref,
    ...linkOverridesRest
  }: LinkOverrides = {}
): LinkProps {
  //
  //
  return {
    to: resolveHref?.(href) || href,
    ...aRest,
    ...linkOverridesRest
  };
}

function RichText({ children, linkOverrides }: Props) {
  const options: MarkdownToJSX.Options = {
    // disable parsing raw HTML is parsed to JSX to prevent possible XSS
    disableParsingRawHTML: true,
    // overrides
    overrides: {
      a: {
        component: (props: AnchorTagProps) => {
          const linkProps = resolveLinkProps(props, linkOverrides);
          return <Link {...linkProps} to={convertToLinkKnownFormat(linkProps.to)} />;
        }
      }
    }
  };

  return (
    <Markdown //
      options={options}
    >
      {children}
    </Markdown>
  );
}

export default React.memo(RichText);
