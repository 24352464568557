import { Action, createReducer } from 'typesafe-actions';

import { DocumentLodgementCompliance } from 'src/containers/shared/document-list/models';
import { actionClearDocumentMessages, actionFetchDocumentMessages } from './actions';

export interface DocumentMessagesState {
  errors?: DocumentLodgementCompliance[];
  warnings?: DocumentLodgementCompliance[];
}

const initialState: DocumentMessagesState = {
  errors: [],
  warnings: []
};

const documentMessagesReducer = createReducer<
  //
  DocumentMessagesState,
  Action
>(initialState)
  .handleAction(actionFetchDocumentMessages, (state, action): DocumentMessagesState => {
    return { ...state, ...action.payload };
  })
  .handleAction(actionClearDocumentMessages, (): DocumentMessagesState => {
    return {
      errors: [],
      warnings: []
    };
  });

export default documentMessagesReducer;
