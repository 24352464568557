import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchUserPermissions } from '../actions/users';
import { UserPermissionsApiResponse } from '../users-detail/models';

export interface UserPermissionsState {
  detail?: UserPermissionsApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: UserPermissionsState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  UserPermissionsState,
  Action
>(initialState)
  .handleAction(actionFetchUserPermissions.request, (state): UserPermissionsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchUserPermissions.success, (state, action): UserPermissionsState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchUserPermissions.failure, (state, action): UserPermissionsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
