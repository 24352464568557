import queryString from 'query-string';
import { Action } from 'typesafe-actions';

import { JurisdictionsEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { actionCreateGlobalErrorMessage, GlobalErrorMessageModel } from 'src/actions/globalErrors';
import { SubscriberAssignableGroupsApiResponse } from 'src/containers/dashboard/shared/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { RegistrationRoleMapping } from 'src/models/roles';
import JsonRest from 'src/store/JsonRest';
import { DocumentRoleMappingLookupModel, DocumentRoleMappingResponse } from '../../models';

const DOCUMENT_TYPE_OPTIONS_STORE_CACHE: Partial<Record<JurisdictionsEnum, JsonRest<DocumentRoleMappingLookupModel[]>>> = {};
export function getCachedOrCreateDocumentRoleMappingOptionsStores(
  jurisdictionId: JurisdictionsEnum,
  dispatch: SafeDispatch<Action<any>>
): JsonRest<DocumentRoleMappingLookupModel[]> {
  if (jurisdictionId in DOCUMENT_TYPE_OPTIONS_STORE_CACHE) {
    return DOCUMENT_TYPE_OPTIONS_STORE_CACHE[Number(jurisdictionId)];
  }

  const query = queryString.stringify({ state: jurisdictionId });

  const jurisdictionJsonRest = new JsonRest<DocumentRoleMappingLookupModel[]>({
    url: `/lookups/supported-documents-metadata?${query}`,
    immediate: true, // immediately trigger fetch for faster UX
    onResolve: (response: DocumentRoleMappingResponse) => {
      const LookupEnumModels = response.documentRoles.map(d => ({
        id: d.documentFormIdentifier,
        name: d.documentDescription,
        roleIds: d.workspaceRoles
      }));
      return LookupEnumModels;
    },
    onError: (error?: GlobalErrorMessageModel) => {
      error && dispatch(actionCreateGlobalErrorMessage(error));
      delete DOCUMENT_TYPE_OPTIONS_STORE_CACHE[jurisdictionId];
    }
  });

  return (DOCUMENT_TYPE_OPTIONS_STORE_CACHE[jurisdictionId] = jurisdictionJsonRest);
}

const assignableGroupOptionsStores: Partial<Record<JurisdictionsEnum, JsonRest<SubscriberAssignableGroupsApiResponse>>> = {};
export function getAssignableGroupOptionsStores(jurisdictionId: JurisdictionsEnum | string): JsonRest<SubscriberAssignableGroupsApiResponse> {
  if (jurisdictionId in assignableGroupOptionsStores) {
    return assignableGroupOptionsStores[jurisdictionId];
  } else {
    const query = queryString.stringify({ jurisdiction: jurisdictionId });
    return (assignableGroupOptionsStores[jurisdictionId] = new JsonRest<SubscriberAssignableGroupsApiResponse>({
      url: `/workspaces/assignable-groups?${query}`,
      immediate: false
    }));
  }
}

export function getRoleOption(id: WorkspaceRoleEnum): LookupEnumModel<WorkspaceRoleEnum> {
  return {
    id,
    name: RegistrationRoleMapping[id]!
  };
}

export function unifyTitleFormat(titleReference) {
  return titleReference.replace(/\W+/g, '/');
}
