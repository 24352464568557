import { $eq, $get, $noneOf, $not, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { JustificationReasonEnum, OrganisationTypeEnum as PartyBook$OrganisationTypeEnum, PartyTypeEnum as PartyBook$PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { TransactionInstruments$DocumentTypeEnum } from './enums';
import { DocumentPartyJustificationModel, DocumentPartyModel, TitleReferenceModel, TransactionInstrumentModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - partyBook[*].firstName
 - partyBook[*].lastName

 schema version:
[
  {
    "partyType": 1
  }
]
*/
export const VISIBILITY_CHECK_FIRST_NAME_AND_LAST_NAME = createContextCheck((context: DocumentPartyModel): boolean =>
  $eq($get(context, 'partyType'), PartyBook$PartyTypeEnum.Individual)
);

/**
 path:
 - partyBook[*].organisationName
 - partyBook[*].organisationType
 - partyBook[*].abn
 - partyBook[*].acln

 schema version:
[
  {
    "partyType": 2
  }
]
*/
export const VISIBILITY_CHECK_ORGANISATION_NAME_AND_ORGANISATION_TYPE_AND_ABN_AND_ACLN = createContextCheck((context: DocumentPartyModel): boolean =>
  $eq($get(context, 'partyType'), PartyBook$PartyTypeEnum.Organisation)
);

/**
 path:
 - partyBook[*].administrationStatus

 schema version:
[
  {
    "organisationType": 10
  }
]
*/
export const VISIBILITY_CHECK_PARTY_BOOK$ADMINISTRATION_STATUS = createContextCheck((context: DocumentPartyModel): boolean =>
  $eq($get(context, 'organisationType'), PartyBook$OrganisationTypeEnum.Registeredcompanyinexternaladministration)
);

/**
 path:
 - partyBook[*].acn

 schema version:
[
  {
    "organisationType": 1,
    "partyType": 2
  },
  {
    "organisationType": 5,
    "partyType": 2
  },
  {
    "organisationType": 8,
    "partyType": 2
  },
  {
    "organisationType": 9,
    "partyType": 2
  },
  {
    "organisationType": 10,
    "partyType": 2
  },
  {
    "organisationType": 12,
    "partyType": 2
  }
]
*/
export const VISIBILITY_CHECK_PARTY_BOOK$ACN = createContextCheck((context: DocumentPartyModel): boolean => {
  const organisationType = $get(context, 'organisationType');
  const partyType = $get(context, 'partyType');
  return (
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.Commonwealthgovernmentorbody) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.Localgovernmentorbody) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.RegisteredAustralianbodyorforeigncompany) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.Registeredcompany) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.Registeredcompanyinexternaladministration) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.StateTerritorygovernmentorbody) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation))
  );
});

/**
 path:
 - partyBook[*].arbn

 schema version:
[
  {
    "organisationType": 1,
    "partyType": 2
  },
  {
    "organisationType": 5,
    "partyType": 2
  },
  {
    "organisationType": 8,
    "partyType": 2
  },
  {
    "organisationType": 12,
    "partyType": 2
  }
]
*/
export const VISIBILITY_CHECK_PARTY_BOOK$ARBN = createContextCheck((context: DocumentPartyModel): boolean => {
  const organisationType = $get(context, 'organisationType');
  const partyType = $get(context, 'partyType');
  return (
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.Commonwealthgovernmentorbody) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.Localgovernmentorbody) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.RegisteredAustralianbodyorforeigncompany) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation)) ||
    ($eq(organisationType, PartyBook$OrganisationTypeEnum.StateTerritorygovernmentorbody) && $eq(partyType, PartyBook$PartyTypeEnum.Organisation))
  );
});

/**
 path:
 - partyBook[*].nameChange.firstName
 - partyBook[*].nameChange.lastName

 schema version:
[
  {
    "partyType": 1
  }
]
*/
export const VISIBILITY_CHECK_FIRST_NAME_AND_LAST_NAME_2 = createContextCheck((context: DocumentPartyJustificationModel): boolean => $eq($get(context, 'partyType'), 1));

/**
 path:
 - partyBook[*].nameChange.organisationName

 schema version:
[
  {
    "partyType": 2
  }
]
*/
export const VISIBILITY_CHECK_PARTY_BOOK$NAME_CHANGE$ORGANISATION_NAME = createContextCheck((context: DocumentPartyJustificationModel): boolean =>
  $eq($get(context, 'partyType'), 2)
);

/**
 path:
 - partyBook[*].nameChange.supportingEvidences

 schema version:
[
  {
    "justificationReason": {
      "$not": 1
    }
  }
]
*/
export const VISIBILITY_CHECK_PARTY_BOOK$NAME_CHANGE$SUPPORTING_EVIDENCES = createContextCheck((context: DocumentPartyJustificationModel): boolean =>
  $not($get(context, 'justificationReason'), JustificationReasonEnum.ApprovedAbbreviation)
);

/**
 path:
 - partyBook[*].nameChange

 schema version:
[
  {
    "requiresNameChange": true
  }
]
*/
export const VISIBILITY_CHECK_PARTY_BOOK$NAME_CHANGE = createContextCheck((context: DocumentPartyModel): boolean => $eq($get(context, 'requiresNameChange'), true));

/**
 path:
 - transactionInstruments[*].dealingNumber

 schema version:
[
  {
    "documentType": {
      "$noneOf": [
        1,
        3,
        7,
        9
      ]
    }
  }
]
*/
export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER = createContextCheck((context: TransactionInstrumentModel): boolean =>
  $noneOf($get(context, 'documentType'), [
    TransactionInstruments$DocumentTypeEnum.Transfer,
    TransactionInstruments$DocumentTypeEnum.Lease,
    TransactionInstruments$DocumentTypeEnum.ApplicationToRecordNewRegisteredProprietor,
    TransactionInstruments$DocumentTypeEnum.Charge,
    null
  ])
);

/**
 path:
 - transactionInstruments[*].receivingParties

 schema version:
[
  {
    "documentType": {
      "$noneOf": [
        0,
        10
      ]
    }
  }
]
*/
export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES = createContextCheck((context: TransactionInstrumentModel): boolean =>
  $noneOf($get(context, 'documentType'), [TransactionInstruments$DocumentTypeEnum.DischargeOfMortgage, TransactionInstruments$DocumentTypeEnum.DischargeOfCharge, null])
);

export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES = createContextCheck((context: TransactionInstrumentModel): boolean =>
  $noneOf($get(context, 'documentType'), [null])
);

/**
 path:
 - transactionInstruments[*].titleReferences[*].partDescription

 schema version:
[
  {
    "isPartLandAffected": true
  }
]
*/
export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES$PART_DESCRIPTION = createContextCheck((context: TitleReferenceModel): boolean => {
  const isTitleSelected = $get(context, 'isSelected');
  const isPartLandAffected = $get(context, 'isPartLandAffected');
  const partLandExtentType = $get(context, 'partLandExtentType');
  return $eq(isTitleSelected, true) && $eq(isPartLandAffected, true) && $eq(partLandExtentType, 0);
});

export const VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES$LAND_DESCRIPTION = createContextCheck((context: TitleReferenceModel): boolean => {
  const isTitleSelected = $get(context, 'isSelected');
  const isPartLandAffected = $get(context, 'isPartLandAffected');
  const partLandExtentType = $get(context, 'partLandExtentType');

  return $eq(isTitleSelected, true) && $eq(isPartLandAffected, true) && ($eq(partLandExtentType, 1) || $eq(partLandExtentType, 2));
});
