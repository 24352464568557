import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    workspaceFilesContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: 24,
      paddingTop: 24
    },
    opacityBox: {
      opacity: 0.4
    }
  }),
  { name: 'WorkspaceFilesContainer' }
);
