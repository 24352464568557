import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/enums';
import { RelinquishingProprietorGroupModel, RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, ApiPartySourceModel, NameChangeConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { ResolvePartyConversionArgs } from '../../components/party-book/models';
import { BaseTransfer2_24_1Model } from './models';

export function generateTransferors<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(
  formModel: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>,
  partyFormConfig: PartyFormConfig<TNameChange>,
  nameChangeConversion: NameChangeConversion<TDocumentPartyJustification, TNameChange>,
  convertPartiesToFormModel: (
    resolvePartyConversion: ResolvePartyConversionArgs<TDocumentPartyJustification, TNameChange>,
    parties: ApiDocumentPartyModel<TDocumentPartyJustification>[],
    relinquishingIds?: string[],
    dataSourceToNameMap?: Map<DataSource, string>
  ) => PartyModel<TNameChange>[]
) {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Subscriber || (pb.metadata?.source === DataSource.Custom && !pb.metadata?.partyBookId));
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    formModel.transferors = { tenancyType: TenancyTypeEnum.None, proprietorGroups: [] };
    return;
  }

  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.proprietor.proprietorGroups.map(
            pg =>
              new ProprietorGroup(
                pg.parties.map(({ party, partyCapacity }) => ({ ...party, partyCapacity })),
                pg,
                pg.shareFraction,
                pg.proprietorGroupType
              )
          ),
          title.proprietor.tenancyType
        )
      )
  );

  const result = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiPartySourceModel<TDocumentPartyJustification>>[] = result.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties.map(p => ({ party: p, partyCapacity: p.partyCapacity })),
      isSelected: false,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const transferors: RelinquishingTenancyDetailModel = {
    tenancyType: selectedTitles[0].proprietor.tenancyType,
    proprietorGroups: groups.map((pg: ProprietorGroupModel<ApiPartySourceModel<TDocumentPartyJustification>>): RelinquishingProprietorGroupModel => {
      return {
        ...pg,
        parties: pg.parties.map(p => ({ isSelected: false, partyBookId: p.party.id!, partyCapacity: p.partyCapacity ?? {} })),
        isSelected: groups.length === 1 || pg.isSelected,
        shareFraction: pg.shareFraction,
        shareTransferred: ShareTransferredTypeEnum.Whole
      };
    })
  };

  formModel.transferors = transferors;

  const transferorsParties = groups.flatMap(pg => pg.parties.map(p => p.party));
  formModel.mergeFailedReason = result.failedReason;
  formModel.partyBookApi = transferorsParties;
  formModel.partyBook = convertPartiesToFormModel(
    { partyFormConfig: partyFormConfig, nameChangeConversion: nameChangeConversion },
    transferorsParties,
    transferorsParties.map(p => p.id!)
  ).concat(customParties);
}
