import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none'
    },
    topBorder: {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    errorText: {
      color: theme.palette.error.main
    },
    nestedFormGroupContainer: {
      paddingTop: 16,
      paddingBottom: 0,
      flexDirection: 'column',
      '& + &': {
        paddingTop: 16
      },
      '&:nth-child(1)': {
        paddingTop: 0
      }
    },
    nestedFormGroupContent: {
      marginTop: 8,
      marginLeft: 32,
      paddingBottom: 16
    }
  });

export type ClassKeys = typeof styles;

export default styles;
