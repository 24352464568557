import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { PriorityNoticeModel } from './models';
import { VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER, VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES } from './visibilityChecks';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<PriorityNoticeModel> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  titleReferencesData: NO_FALLBACK,
  applicants: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        partyBookId: ['']
      }
    }
  },
  transactionInstruments: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        documentType: [null],
        dealingNumber: [VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER, ''],
        receivingParties: {
          $arrayVisibility: [VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES, null as any],
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              partyBookId: ['']
            }
          }
        },
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              reference: NO_FALLBACK,
              isSelected: [false],
              isPartLandAffected: [false],
              partDescription: [''],
              landDescription: [''],
              partLandExtentType: [0]
            }
          }
        }
      }
    }
  }
};
