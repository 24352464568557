import { VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/vic/2-25/config';
import { VicPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/vic/2-25/components/party-justification';
import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-25/config';
import { AddressFieldFormConfig } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { VIC_TENANCY_COMPONENT_LABEL_CONFIG } from 'src/containers/documents/scaffolded-form/vic/2-25/components/tenancy/config';

// Define any form-specific configuration in this file

export const VIC_TRANSFER_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...VIC_TENANCY_COMPONENT_LABEL_CONFIG,
  header: {
    ...VIC_TENANCY_COMPONENT_LABEL_CONFIG.header,
    jointTenants: CommonTenancyLabelsEnum.JointPropretiors
  },
  items: CommonTenancyLabelsEnum.JointPropretiors,
  footer: {
    ...VIC_TENANCY_COMPONENT_LABEL_CONFIG.footer,
    jointTenants: CommonTenancyLabelsEnum.AddJointProprietors
  },
  receivingPartyRole: CommonTenancyLabelsEnum.Transferee
};

export const VIC_TRANSFER_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...VIC_PARTY_FORM_CONFIG,
  nameChangeConfig: VicPartyNameChangeConfig
};

export const VIC_TRANSFER_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  // [WEB-12460] All addresses allow interstate address except for property address.
  ...VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG
};
