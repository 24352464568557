import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    icon: {
      verticalAlign: 'sub',
      fill: theme.palette.primary.main,
      paddingRight: 5
    }
  }),
  {
    name: 'EyeToggleButton'
  }
);
