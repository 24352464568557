import { QldNameChange, QldPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { SHARE_TRANSFERRED_TYPE_OPTIONS, ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/enums';
import { TenancyConfigContextData } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared/context/tenancyConfigContext';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const QLD_TRANSFER_PARTY_FORM_CONFIG: PartyFormConfig<QldNameChange> = {
  ...QLD_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...QldPartyNameChangeConfig, formatNewName }
};

function formatNewName(partyType: PartyTypeEnum, nameChangeModel: QldNameChange): string {
  if (partyType === PartyTypeEnum.Individual) {
    const { firstName, lastName } = nameChangeModel;
    return [firstName, lastName].filter(Boolean).join(' ');
  } else if (partyType === PartyTypeEnum.Organisation) {
    const { organisationName } = nameChangeModel;
    return organisationName;
  } else {
    throw new Error('Unsupported party type.');
  }
}

export const QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = { ...TENANCY_COMPONENT_LABEL_CONFIG, partyRole: 'Transferor' };
export const QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG: Partial<TenancyConfigContextData> = {
  componentLabels: QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG,
  allowNewGroup: true
};

// TODO - Filter allowed values
export const QLD_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANSFERRED_TYPE: LookupEnumModel<ShareTransferredTypeEnum, string>[] = restrictedLookupOptions(
  SHARE_TRANSFERRED_TYPE_OPTIONS,
  {}
);
