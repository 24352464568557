export const getDateAfter = (date?: Date | string | null): Date | undefined => {
  if (!date) return undefined;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
};

export const getDateBefore = (date?: Date | string | null): Date | undefined => {
  if (!date) return undefined;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
};
