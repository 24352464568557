import { Theme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles/withStyles';

export default (theme: Theme): StyleRules => {
  let globalNs: StyleRules = {};
  if (!import.meta.env.PROD) {
    globalNs = {
      '[data-section]': {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: 'attr(data-section)',
          fontSize: 12,
          color: '#d2691e80',
          fontWeight: 'normal',
          pointerEvents: 'none'
        }
      }
    };
  }
  return globalNs;
};
