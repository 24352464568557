import * as React from 'react';

import classNames from 'classnames';
import { SxProps, Theme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { colors } from 'src/theme';
import { useStyles } from './styles';

export interface ToggleButtonsProps {
  items: LookupItemModel<number>[];
  onChange: (newValue: number) => void;
  value?: number;
  sxGroupButtons?: SxProps<Theme>;
  className?: string;
  label?: string;
}

function ToggleButtons({ items, onChange, value, sxGroupButtons, className, label }: ToggleButtonsProps) {
  const handleOnChange = React.useCallback(
    (_: React.MouseEvent<HTMLElement>, newValue: number) => {
      // clicking on same tab, the newValue would be null
      if (newValue === null) {
        return;
      }
      onChange(newValue);
    },
    [onChange]
  );

  const classes = useStyles();

  return (
    <ToggleButtonGroup
      //
      color="primary"
      value={value}
      onChange={handleOnChange}
      exclusive
      aria-label={label}
      sx={[
        {
          position: 'absolute',
          left: 'calc((100% - 302px) / 2)', // TODO this is bad way to center an item, 302px is the total length of the button groups
          borderRadius: 80,
          background: colors.GREEK_WATERS_TRANSLUCENT,
          boxShadow: '0px 2px 2px 0px rgba(27, 127, 121, 0.15) inset;'
        },
        ...(Array.isArray(sxGroupButtons) ? sxGroupButtons : [sxGroupButtons])
      ]}
    >
      {items.map(item => {
        return (
          <ToggleButton key={item.id} value={item.id} className={classNames(classes.toggleButton, className)}>
            {item.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default React.memo(ToggleButtons);
