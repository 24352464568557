import * as React from 'react';

import classNames from 'classnames';
import { FieldProps, getIn } from 'formik';
import warning from 'warning';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import { ButtonLinkClassKeys } from '@sympli/ui-framework/components/button-link';
import Dropzone, { DropzoneFile, UploadedFileApiResponse } from '@sympli/ui-framework/components/form/base-components/dropzone';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconDelete } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import { uploadFile } from './api';
import { WorkspaceQueryParams } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  label?: string;
  disabled?: boolean; // disabled the dropzone
  multiple?: boolean; // allow upload of multiple files
  maxItems?: number; // maximum number of files
  maxSize?: number; // maxSize in MB
  accept: string[];
  queryParams: WorkspaceQueryParams;
}

type Props = OwnProps & FieldProps & WithStyles<ClassKeys>;

interface State {
  viewFileLoadingMap: { [key: string]: boolean };
  isUploading: boolean;
  errorInUpload?: string;
  errorInDownload?: string;
}

class WorkspaceFileUploader extends React.PureComponent<Props, State> {
  private readonly buttonLinkClasses: Partial<ClassNameMap<keyof ReturnType<ButtonLinkClassKeys>>> = {
    root: this.props.classes.downloadButtonRoot,
    text: this.props.classes.downloadButtonText
  };

  public static displayName = 'WorkspaceFileUploader';

  public static defaultProps: Partial<OwnProps> = {
    multiple: false,
    accept: ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  };

  constructor(props: Props) {
    super(props);

    warning(typeof props.queryParams === 'object', 'Query params must be provided');
  }

  public readonly state: Readonly<State> = {
    viewFileLoadingMap: {},
    isUploading: false
  };

  private get error() {
    const { errorInUpload, errorInDownload } = this.state;
    if (errorInUpload || errorInDownload) {
      return errorInUpload || errorInDownload;
    }

    const {
      field: { name },
      form: { touched, errors }
    } = this.props;
    return (getIn(touched, name) && getIn(errors, name)) || undefined;
  }

  private get dataName() {
    const {
      field: { name },
      form: { contextPath }
    } = this.props;

    const dataName = contextPath ? `${contextPath}.${name}` : name;
    return dataName;
  }

  render() {
    const { classes, label } = this.props;

    return (
      <div className={classes.root} data-name={this.dataName}>
        {label && <InputLabel shrink={true}>{label}</InputLabel>}
        {this.renderDropZoneArea()}
        {this.renderUploadedFilesArea()}
        {this.renderErrorMessage(this.error as any)}
      </div>
    );
  }

  private renderErrorMessage(error?: string) {
    const { classes } = this.props;
    if (error) {
      return (
        <FormHelperText data-error-name={this.dataName} error className={classes.errorMessage} role="alert">
          {error}
        </FormHelperText>
      );
    }
    return null;
  }

  private renderDropZoneArea() {
    const {
      disabled: fieldDisabled,
      multiple,
      maxItems,
      maxSize,
      field: { value }
    } = this.props;
    const { isUploading } = this.state;

    if (maxItems && value.length >= maxItems) {
      return null;
    }

    const accept = this.props.accept!.join(', ');
    const disabled = fieldDisabled || isUploading;

    return (
      <Dropzone //
        disabled={disabled}
        accept={accept}
        maxSize={maxSize ? maxSize * Math.pow(1024, 2) : undefined}
        multiple={multiple}
        onApiUpload={this.handleApiUpload}
        onAddFile={this.handleAddFile}
      />
    );
  }

  private handleAddFile = (file: DropzoneFile) => {
    const { touched, setFieldTouched, setFieldValue } = this.props.form;
    const { name, value } = this.props.field;
    const newUploadedFiles: Array<UploadedFileApiResponse> = [
      ...value,
      { id: file.fileId, fileName: file.fileName, suggestedFileName: file.suggestedFileName, fileType: file.fileType }
    ];
    // update the value which also triggers validation if form.validateOnChange is on
    setFieldValue(name, newUploadedFiles);
    // we want to show errors immediately, therefore field needs to be marked as touched
    // prevent unnecessary render of Formik tree by calling setFieldTouched only when it makes sense
    !getIn(touched, name) && setFieldTouched(name, true, false /* don't trigger validation */);
  };

  private renderUploadedFilesArea = () => {
    const { classes, field } = this.props;
    const uploadedFiles: Array<UploadedFileApiResponse> = field.value;

    if (!(uploadedFiles instanceof Array)) {
      return this.renderErrorMessage('Incorrect file data.');
    }

    return uploadedFiles.map((file, index) => {
      const { id } = file;
      const { viewFileLoadingMap } = this.state;
      const isLoading = viewFileLoadingMap[id];
      const disabled = isLoading || this.props.disabled;

      return (
        <FlexLayout key={`file-upload-${index}`} alignItems="center" className={classes.uploadedFile}>
          {id && (
            <>
              <IconButton color="primary" disabled={disabled} className={classes.deleteButton} data-file-id={id} onClick={this.handleOnDelete} size="large">
                <IconDelete className={classNames(classes.deleteIcon, disabled && classes.deleteIconDisabled)} />
              </IconButton>
              {file.suggestedFileName}
            </>
          )}
          {isLoading && <InlineLoader size={24} />}
        </FlexLayout>
      );
    });
  };

  private handleOnDelete = (event: React.MouseEvent<HTMLElement>) => {
    const fileId = dataAttribute('file-id', event);
    const { name, value: uploadedFiles } = this.props.field;
    const { setFieldValue } = this.props.form;
    const newUploadedFiles = uploadedFiles.filter((d: UploadedFileApiResponse) => d.id !== fileId);
    this.setState(
      {
        errorInUpload: undefined,
        errorInDownload: undefined
      },
      () => {
        setFieldValue(name, newUploadedFiles);
      }
    );
  };

  private handleApiUpload = (files: File[]) =>
    // user can upload a maximum of only one file
    uploadFile(files[0], this.props.queryParams);
}

export default withStyles(styles)(WorkspaceFileUploader);
