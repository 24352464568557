import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import { actionCloseGlobalSimpleNotification } from './actions';
import SimpleSnackbarNotification, { SimpleSnackbarNotificationProps } from './simple-snackbar-notification';

function GlobalSimpleNotificationContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const cachedProps = React.useRef<SimpleSnackbarNotificationProps | undefined>();
  const globalSimpleNotificationState = useStoreSelector(store => store.globalSimpleNotification);
  const { open, onClose, autoHideDuration } = globalSimpleNotificationState;
  const handleOnCloseNotification = React.useCallback(
    (open: boolean) => {
      dispatch(actionCloseGlobalSimpleNotification());
      onClose?.(open);
    },
    [dispatch, onClose]
  );

  let notificationProps = { ...globalSimpleNotificationState };
  if (!open) {
    // if it's closing snackbar, used cached props for open
    notificationProps = { ...notificationProps, ...cachedProps, open: false };
  } else {
    // if it's opening snackbar, cache the props
    cachedProps.current = globalSimpleNotificationState;
  }

  return (
    <SimpleSnackbarNotification //
      {...notificationProps}
      {...((onClose || autoHideDuration) && { onClose: handleOnCloseNotification })}
    />
  );
}

export default React.memo(GlobalSimpleNotificationContainer);
