import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 50,
      padding: '0px 16px',
      borderTop: `1px solid ${theme.palette.text.secondary}`
    },
    toggleButton: {
      width: 42,
      height: 42
    },
    popper: {
      position: 'absolute',
      top: 0
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      width: 320,
      height: 620,
      borderRadius: 4,
      marginBottom: 11,
      marginRight: 5,
      maxHeight: 'calc(100vh - 130px)'
    },
    closeButton: {
      position: 'absolute',
      top: 5,
      right: 6,
      width: 40,
      height: 40
    },
    closeIcon: {
      fontSize: 28,
      color: 'white',
      fontWeight: 'normal'
    },
    messageCount: {
      position: 'absolute',
      marginTop: -10,
      marginLeft: 20,
      width: 24,
      height: 24,
      fontSize: 13,
      borderRadius: '50%',
      color: theme.palette.common.white,
      background: theme.palette.secondary.main
    },
    circle: {
      backgroundColor: colors.SUNNY_DAY,
      borderRadius: '50%',
      display: 'flex',
      zIndex: 1
    },
    unreadMessageCount: {
      padding: '2px 5px',
      borderRadius: 8,
      color: colors.WHITE,
      backgroundColor: colors.WATERMELON,
      position: 'absolute',
      right: 2,
      bottom: 48,
      zIndex: 2,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '12px',
      '@media (min-width:1536px) and (max-width:1920px)': {
        right: 12,
        bottom: 48
      },
      '@media (min-width:1920px)': {
        right: 16,
        bottom: 80,
        borderRadius: 16,
        padding: '2px 8px',
        fontSize: 14,
        lineHeight: '14px'
      }
    },

    messengerOpen: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'right'
    },
    paper2: {
      display: 'flex',
      flexDirection: 'column',
      width: 320,
      height: 482,
      borderRadius: 8,
      maxHeight: 'calc(100vh - 130px)',
      boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.20)'
    },
    popper2: {
      right: -12
    },
    toggleButton2: {
      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.20)'
    },
    rootBox: {
      position: 'absolute',
      backgroundColor: 'red',
      bottom: 0,
      right: 0
    },
    animatedRootBox: {
      animation: '$bounce 3000ms ease-in-out infinite'
    },
    '@keyframes bounce': {
      '0%': {
        bottom: 0
      },
      '5%': {
        bottom: 30
      },
      '10%': {
        bottom: 0
      },
      '15%': {
        bottom: 30
      },
      '20%': {
        bottom: 0
      },
      '100%': {
        bottom: 0
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
