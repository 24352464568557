import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    total: {
      paddingTop: 10
    },
    totalLabel: {
      marginRight: 8,
      fontWeight: 'bold',
      fontSize: 18
    },
    totalAmount: {
      fontWeight: 'bold',
      fontSize: 18
    }
  });

export type ClassKeys = typeof styles;

export default styles;
