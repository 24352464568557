import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

import { WA_DM_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { DischargeOfMortgageDocument2_27_0Model } from './models';
import yupDischargeAmount from './sections/discharge-amount/validationSchema';
import yupExtentOfLandDischarged from './sections/extent-of-land-discharged/validationSchema';
import yupExtentOfMoneyDischarged from './sections/extent-of-money-discharged/validationSchema';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgages from './sections/mortgages/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<DischargeOfMortgageDocument2_27_0Model>({
    titleReferences: yupTitleReferences,
    mortgagees: yupMortgagees,
    extentOfLandDischarged: yupExtentOfLandDischarged,
    extentOfMoneyDischarged: yupExtentOfMoneyDischarged,
    dischargeAmount: yupDischargeAmount,
    mortgages: memoizeSchema(yupMortgages),
    additionalAttachments: memoizeSchema(yupAdditionalAttachments(WA_DM_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS)),
    addressBook: yup.array<AddressBookEntityModel>(),
    partyBook: yupPartyBook
  })
  .defined()
  .log();
