import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    secondaryMessage: {
      flex: '1 1',
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      display: 'inline-block',
      width: 16,
      height: 16,
      margin: 2,
      marginLeft: 0
    },
    iconError: {
      color: theme.palette.error.main
    },
    iconWarning: {
      color: theme.palette.secondary.main
    },
    iconNoIssues: {
      color: theme.palette.grey[200],
      fill: theme.palette.grey[200],
      display: 'inline-block',
      width: 12,
      height: 12,
      margin: 4,
      marginLeft: 0
    },
    textUnverified: {
      fontWeight: 'normal'
    },
    textNoIssues: {
      fontWeight: 'normal'
    },
    iconVerified: {
      fill: colors.TEAL
    }
  }),
  {
    name: 'DocumentVerificationResultStatus'
  }
);
