import * as yup from 'yup';

import yupResponsibleSubscriber from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber/validation-schema';

import { LodgementInstructions2_19_1Model } from './models';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<LodgementInstructions2_19_1Model>({
    responsibleParticipantId: yupResponsibleSubscriber,
    selectedPriorityNoticeId: yup.string().default('').optional(),

    isSameRoleAsResponsible: yup.mixed<boolean>(),
    currentParticipantId: yup.mixed<string>()
  })
  .defined()
  .log();
