import TopPanel from 'src/components/layout/v2/TopPanel';
import Messenger from 'src/containers/messenger';

interface WorkspacePageLayoutProps {
  workspaceId: string;
  participantId: string;
  sidePanel: React.ReactNode;
  showWorkspaceMessenger?: boolean;
}

function WorkspacePageLayout({ workspaceId, participantId, sidePanel, children, showWorkspaceMessenger }: React.PropsWithChildren<WorkspacePageLayoutProps>) {
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .header */}
      <TopPanel />

      <div className="grid grid-cols-[auto_1fr] grid-rows-1 overflow-y-hidden">
        <div
          style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)' }}
          className="overflow-y-auto overflow-x-hidden pl-[calc(calc(100vw_-_var(--small-content))_/_2)] medium-screen:pl-[calc(calc(100vw_-_var(--medium-content))_/_2)] large-screen:pl-[calc(calc(100vw_-_var(--large-content))_/_2)]"
        >
          <div className="box-border h-full w-[var(--small-content-left-panel)] overflow-x-auto bg-[#FAFAFA] px-[24px] pt-[32px] medium-screen:w-[var(--medium-content-left-panel)] large-screen:w-[var(--large-content-left-panel)]">
            {sidePanel}
          </div>
        </div>

        <div
          data-scroll-to-error-offset="60" // this is height of workflow panel
          className="tickle-scroll-to-error bg-zinc-100 overflow-y-auto pr-[calc(calc(100vw_-_var(--small-content))_/_2)] medium-screen:pr-[calc(calc(100vw_-_var(--medium-content))_/_2)] large-screen:pr-[calc(calc(100vw_-_var(--large-content))_/_2)]"
        >
          {children}

          {showWorkspaceMessenger && (
            <Messenger
              // route params
              workspaceId={workspaceId}
              participantId={participantId}
              //other
              className="absolute bottom-[16px] right-[2px] h-[48px] w-[48px] medium-screen:right-[12px] large-screen:bottom-[24px] large-screen:right-[16px] large-screen:h-[72px] large-screen:w-[72px]"
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default WorkspacePageLayout;
