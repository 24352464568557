import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import { WorkspaceTaskApiResponse } from 'src/containers/workspace/shared/detail/components/my-tasks/models';

export function isLodgementProgressVisible(workspaceStatusId?: WorkspaceStatusEnum, lodgementCaseStatusId?: LodgementCaseStatusEnum, workspaceTasks?: WorkspaceTaskApiResponse[]) {
  if (workspaceStatusId === WorkspaceStatusEnum.LodgementInQueue) {
    return true;
  }

  switch (lodgementCaseStatusId) {
    case LodgementCaseStatusEnum.LodgementRequested:
    case LodgementCaseStatusEnum.LodgementSuccess:
    case LodgementCaseStatusEnum.LodgementConnectionError:
    case LodgementCaseStatusEnum.LodgementTechnicalError:
    case LodgementCaseStatusEnum.LodgementVerificationRequested:
    case LodgementCaseStatusEnum.Registered:
    case LodgementCaseStatusEnum.Rejected:
    case LodgementCaseStatusEnum.Withdrawn:
    case LodgementCaseStatusEnum.Unnecessary:
      return true;
    case LodgementCaseStatusEnum.LodgementVerificationError:
      return true;
    case LodgementCaseStatusEnum.LodgementError:
      if (workspaceTasks && workspaceTasks.length === 0) {
        // Lodgement error without any tasks (example, resolve issues for document)
        return true;
      }
  }

  return false;
}
