import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    table: {
      borderCollapse: 'collapse',
      width: '100%'
    },
    thead: {
      '& >tr': {
        height: theme.spacing(4),
        fontWeight: 'bold'
      }
    },
    tbody: {
      '& >tr': {
        height: theme.spacing(4),
        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
      }
    },
    dropZone: {
      border: '1px solid #333333',
      backgroundColor: 'rgba(242,242,242,0.25)',
      marginBottom: theme.spacing(2),
      cursor: 'pointer'
    },
    acceptedPhoto: {
      background: 'repeating-linear-gradient(-45deg, rgba(242,242,242,0.25), rgba(242,242,242,0.25) 10px, #33cc33 10px, #33cc33 20px)'
    },
    rejectedPhoto: {
      background: 'repeating-linear-gradient(-45deg, rgba(242,242,242,0.25), rgba(242,242,242,0.25) 25px, #ff0000 25px, #ff0000 50px)'
    },
    dropZoneIcon: {
      fontSize: theme.spacing(20),
      margin: -20,
      marginTop: -14,
      color: 'rgba(0, 0, 0, 0.12)'
    },
    dropZoneText: {
      marginTop: theme.spacing(-2),
      height: 24,
      borderTop: '1px solid #333333'
    },
    bottomLine: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 10,
      marginBottom: 20
    },
    bottomLineNone: {
      borderBottom: 'none'
    },
    formGroup: {
      border: 0
    },
    bold: {
      fontWeight: 'bold'
    },
    profileImageBox: {
      marginTop: 30,
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    phoneFormGroupContentContainer: {
      '& >*': {
        '&:not(:last-child)': {
          marginRight: 16
        }
      }
    },
    flexGrow: {
      flexGrow: 1
    }
  });

export type ClassKeys = typeof styles;

export default styles;
