import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { isSupportClaimDate, isSupportClaimPartyDetails, isSupportDetailsSupportingTheClaim } from '../../helpers';
import { ClaimDetailsModel } from '../../models';

export const VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM = createContextCheck(
  (context: ClaimDetailsModel): boolean => !!context.claimStatement && isSupportDetailsSupportingTheClaim(context.claimStatement)
);
export const VISIBILITY_CHECK_CLAIM_DATE = createContextCheck((context: ClaimDetailsModel): boolean => !!context.claimStatement && isSupportClaimDate(context.claimStatement));
export const VISIBILITY_CHECK_CLAIM_PARTY_DETAILS = createContextCheck(
  (context: ClaimDetailsModel): boolean => !!context.claimStatement && isSupportClaimPartyDetails(context.claimStatement)
);
