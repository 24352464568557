import dateFormat from 'dateformat';

import { NSW_ADDRESS_FORM_CONFIG as NSW_NOTICE_OF_SALE_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/nsw/2-21/config';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { fallbackMap } from './fallback';
import { ADDRESS_BOOK_KEY, ApiNoticeOfSaleModel, NoticeOfSaleModel, PARTY_BOOK_KEY } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: NSW_NOTICE_OF_SALE_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_PARTY_FORM_CONFIG
});

class Converter implements IConverter<NoticeOfSaleModel, ApiNoticeOfSaleModel> {
  fromApiToFormModel(apiModel: ApiNoticeOfSaleModel, _context: IConverterContext): NoticeOfSaleModel {
    let partyBook = convertPartyBookFromApiToFormModel(apiModel[PARTY_BOOK_KEY], []);

    // ToDo: Remove after backend type is updated as int/NameSuffixEnum in NSW
    // Updating backend type should be done as part of the next schema upgrade for NSW
    partyBook = partyBook.map(p => {
      if (p.nameSuffixValue) {
        return { ...p, nameSuffixValue: parseInt(p.nameSuffixValue.toString()) };
      }
      return p;
    });

    let formModel: NoticeOfSaleModel = {
      ...apiModel,
      [PARTY_BOOK_KEY]: partyBook,
      [ADDRESS_BOOK_KEY]: convertAddressBookFromApiToFormModel(apiModel[ADDRESS_BOOK_KEY])
    };
    formModel = applyDefaultMap(formModel, fallbackMap);
    return formModel;
  }

  fromFormToApiModel(formValues: NoticeOfSaleModel, _originalApiModel: ApiNoticeOfSaleModel): ApiNoticeOfSaleModel {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const { [PARTY_BOOK_KEY]: formPartyBook, [ADDRESS_BOOK_KEY]: formAddressBook, consideration, ...rest } = formModel;
    return {
      ...rest,
      consideration: consideration
        ? {
            ...consideration,
            monetaryConsideration: consideration.monetaryConsideration
              ? {
                  ...consideration.monetaryConsideration,
                  dateOfContractForSale: consideration.monetaryConsideration.dateOfContractForSale
                    ? dateFormat(consideration.monetaryConsideration.dateOfContractForSale as Date, DateFormatEnum.DATE)
                    : consideration.monetaryConsideration.dateOfContractForSale
                }
              : consideration.monetaryConsideration,
            nonMonetaryConsideration: consideration.nonMonetaryConsideration
              ? {
                  ...consideration.nonMonetaryConsideration,
                  acquisitionDate: consideration.nonMonetaryConsideration.acquisitionDate
                    ? dateFormat(consideration.nonMonetaryConsideration.acquisitionDate as Date, DateFormatEnum.DATE)
                    : consideration.nonMonetaryConsideration.acquisitionDate
                }
              : consideration.nonMonetaryConsideration
          }
        : consideration,
      [PARTY_BOOK_KEY]: convertPartyBookFromFormToApiModel(formPartyBook, _originalApiModel[PARTY_BOOK_KEY]),
      [ADDRESS_BOOK_KEY]: convertAddressBookFromFormToApiModel(formAddressBook, _originalApiModel[ADDRESS_BOOK_KEY])
    };
  }
}

export default new Converter();
