import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const ARROW_SIZE = 20;
const styles = (theme: Theme) =>
  createStyles({
    // body2_bold + underline
    assignToMe: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43, // 20px
      textDecorationLine: 'underline',
      marginLeft: theme.spacing(-1),
      color: 'var(--neutral-1000)'
    },
    unAssign: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43, // 20px
      textDecorationLine: 'underline',
      marginLeft: theme.spacing(-1),
      color: 'var(--neutral-1000)'
    },
    saveButton: {
      width: 82
    },
    searchUser: {
      paddingTop: 20,
      width: 328
    },
    fullWidth: {
      width: '100%'
    },
    searchIcon: {
      paddingRight: 5,
      fill: colors.GREY_400
    },
    suggestionsContainer: {
      borderRadius: 4,
      overflowY: 'auto',
      maxHeight: 90
    },
    suggestionItemContainer: {
      margin: 5,
      padding: '0px 4px',
      height: 40,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    suggestionName: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '18px'
    },
    suggestionEmail: {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '15px'
    },
    // body2_bold + underline
    title: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43, // 20px
      textDecorationLine: 'underline'
    },
    assignTitle: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '24px'
    },
    paper: {
      padding: theme.spacing(3),
      overflowX: 'unset',
      overflowY: 'unset',
      marginTop: -10,
      marginLeft: -5,
      '&::after': {
        content: '""',
        position: 'absolute',
        marginTop: 10,
        left: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: ARROW_SIZE,
        height: ARROW_SIZE,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[0],
        transform: 'rotate(315deg)',
        clipPath: 'polygon(0 100%, 0 0, 100% 100%)'
      }
    },
    list: {
      paddingBottom: 0,
      paddingTop: theme.spacing(0.5)
    },
    itemLink: {
      padding: theme.spacing(0.75, 2)
    },
    root: {
      margin: theme.spacing(0, -2, 0, -2),
      padding: theme.spacing(0)
    }
  });

export type ClassKeys = typeof styles;

export default styles;
