import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchNotificationsSettings } from '../actions/notificationsSettings';
import { NotificationSettingsApiResponse } from '../preferences/detail/notifications/models';

export interface NotificationsSettingsState {
  detail?: NotificationSettingsApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: NotificationsSettingsState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<NotificationsSettingsState, Action>(initialState)
  .handleAction(actionFetchNotificationsSettings.request, (state): NotificationsSettingsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchNotificationsSettings.success, (state, action): NotificationsSettingsState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchNotificationsSettings.failure, (state, action): NotificationsSettingsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
