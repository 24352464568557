import React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import Grid from '@mui/material/Grid';

import { PaymentMethodEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsCategoriesApiResponse } from '@sympli/api-gateway/models';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { HoldingAccountDetailsModel, TrustAccountOptionModel } from 'src/containers/workspace/financial/directions/models';
import { createModelKeyAppender, modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { ConditionalDistributionModel, LinkedDistribution } from '../../../../../components/direction-record/models';
import { CategoryEnum, DischargeDirectionsFormModel } from '../../../models';
import { useStyles } from './styles';

type SelectDirectionCategoryProps = {
  //form
  itemFieldName: string;
  formikProps: FormikProps<DischargeDirectionsFormModel>;
  //data
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  usage?: GeneralAccountUsageApiResponse;
  financialAccounts?: FinancialAccountApiResponse[];
  trustAccountOptions: TrustAccountOptionModel[];
  restrictManualAccountDetailsEntryEnabled: boolean;
  //actions
  autoSelectAccount: (
    selectedCategory: string,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    usage?: GeneralAccountUsageApiResponse,
    financialAccounts?: FinancialAccountApiResponse[]
  ) => void;
  clearBankDetails: (formikProps: FormikProps<DischargeDirectionsFormModel>) => void;
};

function SelectDirectionCategory(props: SelectDirectionCategoryProps) {
  const {
    //
    formikProps,
    directionsCategoriesDetail,
    itemFieldName,
    usage,
    financialAccounts,
    trustAccountOptions,
    autoSelectAccount,
    clearBankDetails
  } = props;

  const classes = useStyles();
  const { values } = formikProps;

  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);
  const linkedSettlementItemDetailsFieldName = createModelKeyAppender<LinkedDistribution>(fieldName('linkedSettlementItem'));

  const category: string = _get(values, fieldName('category'));

  const { directionCategories: categories } = directionsCategoriesDetail;

  const handleOnCategoryChange = (
    //
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    trustAccountOptions: TrustAccountOptionModel[],
    usage?: GeneralAccountUsageApiResponse,
    financialAccounts?: FinancialAccountApiResponse[]
  ) => {
    const resolvedValue = event.target.value;
    const { setFieldValue } = formikProps;

    switch (resolvedValue) {
      case CategoryEnum.LoanPayout: {
        const filteredTrustAccountOptions = trustAccountOptions.filter(option => option.canUseAsLoanPayout);
        const isSingleAccount = filteredTrustAccountOptions.length === 1;

        setFieldValue(fieldName('paymentMethod'), PaymentMethodEnum.HoldingAccount);

        if (isSingleAccount) {
          const holdingAccountDetailsFieldName = createModelKeyAppender<HoldingAccountDetailsModel>(fieldName('holdingAccountDetails'));
          setFieldValue(holdingAccountDetailsFieldName('accountId'), filteredTrustAccountOptions[0].id);
        }
        break;
      }
      case CategoryEnum.CustomerLoanAccount:
      case CategoryEnum.ProfessionalFees:
      case CategoryEnum.ThirdPartyBeneficiary: {
        autoSelectAccount(resolvedValue, formikProps, usage, financialAccounts);
        setFieldValue(fieldName('paymentMethod'), PaymentMethodEnum.BankTransfer);
        break;
      }
      case CategoryEnum.AcceptSurplus:
        setFieldValue(fieldName('amount'), 0, false);
        clearBankDetails(formikProps);
        setFieldValue(fieldName('paymentMethod'), PaymentMethodEnum.BankTransfer);
        break;
      case CategoryEnum.Other: {
        clearBankDetails(formikProps);
        setFieldValue(fieldName('paymentMethod'), PaymentMethodEnum.BankTransfer);
        break;
      }
      case CategoryEnum.LinkedPayment: {
        setFieldValue(fieldName('amount'), 1.0, false);
        clearBankDetails(formikProps);
        setFieldValue(fieldName('paymentMethod'), PaymentMethodEnum.Linked);
        break;
      }
      default: {
        autoSelectAccount(resolvedValue, formikProps, usage, financialAccounts);
        setFieldValue(fieldName('paymentMethod'), '');
      }
    }
  };

  const filterCategories = (categories: LookupEnumModel<string, string>[], formikProps: FormikProps<DischargeDirectionsFormModel>) => {
    const distributionFieldName = modelKey<DischargeDirectionsFormModel>()('distributions');

    const distributions = _get(formikProps.values, distributionFieldName);

    const { restrictManualAccountDetailsEntryEnabled } = props;

    //There can only be 1 maximum accept surplus in the direction line items
    const doesAcceptSurplusLineItemExist = distributions.some(distribution => distribution.category === CategoryEnum.AcceptSurplus && !distribution.isEditorOpen);
    if (doesAcceptSurplusLineItemExist) {
      categories = categories.filter(category => category.id !== CategoryEnum.AcceptSurplus);
    }

    if (restrictManualAccountDetailsEntryEnabled) {
      categories = categories.filter(category => category.id !== CategoryEnum.CustomerLoanAccount && category.id !== CategoryEnum.Other);
    }

    return categories;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Field
          label="Category"
          component={SelectField}
          placeholder={resolveSelectPlaceholder(true)}
          name={fieldName('category')}
          className={classes.fullWidth}
          options={filterCategories(categories, formikProps)}
          onChange={e => handleOnCategoryChange(e, formikProps, trustAccountOptions, usage, financialAccounts)}
        />
      </Grid>
      {category === CategoryEnum.Other && (
        <Grid item xs={6}>
          <Field //
            label="Category name"
            component={InputField}
            className={classes.fullWidth}
            name={fieldName('categoryOther')}
          />
        </Grid>
      )}
      {category === CategoryEnum.LinkedPayment && (
        <Grid item xs={6}>
          <Field //
            label="Reference"
            component={InputField}
            className={classes.fullWidth}
            name={linkedSettlementItemDetailsFieldName('reference')}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default SelectDirectionCategory;
