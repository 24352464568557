import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DateOfDeathTypeEnum, EvidenceTypeValueEnum } from './enums';
import { DateOfDeathModel, DeceasedProprietorModel, EvidenceModel, TransmissionApplicationWithoutDuty2_21_1Model } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.deathDate

 schema version:
[
  {
    "dateOfDeathType": {
      "$noneOf": [
        2,
        1
      ]
    }
  }
]
*/
export const VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE = createContextCheck(
  (context: DateOfDeathModel): boolean => ![DateOfDeathTypeEnum.DateRange, DateOfDeathTypeEnum.DateDescription].includes(context.dateOfDeathType!)
);

/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.fromDate
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.toDate

 schema version:
[
  {
    "dateOfDeathType": 2
  }
]
*/
export const VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE = createContextCheck((context: DateOfDeathModel): boolean => context.dateOfDeathType === DateOfDeathTypeEnum.DateRange);

/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.dateDescription

 schema version:
[
  {
    "dateOfDeathType": 1
  }
]
*/
export const VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION = createContextCheck(
  (context: DateOfDeathModel): boolean => context.dateOfDeathType === DateOfDeathTypeEnum.DateDescription
);

/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].evidences

 schema version:
[
  {
    "isAffectedProprietor": true
  }
]
*/
export const VISIBILITY_CHECK_DATE_OF_DEATH = createContextCheck(
  (context: DeceasedProprietorModel): boolean => context.isAffectedProprietor === true && context.isLastDeceased === true
);

export const VISIBILITY_CHECK_EVIDENCES = createContextCheck((context: DeceasedProprietorModel): boolean => {
  return context.isEvidenceVisible === true;
});

/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].evidences[*].evidenceDocumentReference

 schema version:
[
  {
    "evidenceTypeValue": 12
  },
  {
    "evidenceTypeValue": 9
  },
  {
    "evidenceTypeValue": 5
  },
  {
    "evidenceTypeValue": 4
  },
  {
    "evidenceTypeValue": 0
  }
]
*/
export const VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE = createContextCheck(
  (context: EvidenceModel): boolean => {
    return (
      context.evidenceTypeValue !== null &&
      [
        EvidenceTypeValueEnum.Probate,
        EvidenceTypeValueEnum.LettersOfAdministration,
        EvidenceTypeValueEnum.DeedOfAppointment,
        EvidenceTypeValueEnum.DeathCertificate,
        EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct
      ].includes(context.evidenceTypeValue)
    );
  }
);

/**
 path:
 - .applicants[*]
*/
export const VISIBILITY_CHECK_APPLICANTS = createContextCheck((context: TransmissionApplicationWithoutDuty2_21_1Model): boolean => context.applicants?.length !== 0);

/**
 path:
 - deceasedProprietorGroups[*]
*/
export const VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP = createContextCheck(
  (context: TransmissionApplicationWithoutDuty2_21_1Model): boolean => context.deceasedProprietorGroups.length !== 0
);
