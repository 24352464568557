import { createAsyncAction } from 'typesafe-actions';

import { PaginatedItemsModel } from 'src/models';
import { LogModel, SubscriberActivityLogTypeEnum } from '../log/models';

export type LogsApiRequest = {
  pageSize: number; // defines number of rows shown per page.
  pageNumber: number; // current page number
};

export interface LogsApiResponse extends PaginatedItemsModel<LogApiResponse> {}

export interface LogApiResponse {
  userFullName: string;
  created: Date;
  category: SubscriberActivityLogTypeEnum;
  data: string;
}

export const actionFetchLogsFeed = createAsyncAction(
  //
  'FETCH_LOGS_FEED',
  'FETCH_LOGS_FEED_SUCCESS',
  'FETCH_LOGS_FEED_ERROR'
)<
  //
  LogsApiRequest,
  { data: PaginatedItemsModel<LogModel> },
  { error: Error }
>();
