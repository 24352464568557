import { VIC_PARTY_FORM_CONFIG as VIC_M_PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-18/config';
import { FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving/validation-schema';
import { PartyCapacityEnum } from '@sympli-mfe/enums-2-18/vic';

import { MortgageeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup as PartyCapacityLookup } from '../../enums';
import { Mortgage2_18_1Model } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupMortgageeDetails = resolveMemoizedTenancyValidation<FormReceivingTenancyDetailModel, Mortgage2_18_1Model>({
  isAddressRequired: true,
  partyCapacityLookup: PartyCapacityLookup,
  partyCapacityMinor: PartyCapacityEnum.Minor,
  partyFormConfig: VIC_M_PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG
});

export default yupMortgageeDetails;
