import * as yup from 'yup';

import { $eq, $get, $items, $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { DocumentBaseTitleReferenceModel } from '../../../../models';
import { getCaveatsBeingWithdrawn, getRegisteredProprietors, getSelectedTitleReferences } from '../../helpers';
import { WithdrawalOfCaveat2_20_1Model } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: titleReferences
const yupTitleReferences = yup
  .array<DocumentBaseTitleReferenceModel, WithdrawalOfCaveat2_20_1Model>()
  .required(msg.REQUIRED)
  .testContextualRule({
    /**
           * what we found in schema:
           [{"required":{"titleReferences":{"$items":{"$oneOf":{"isSelected":true}}}},"message":null}]
           *
           */
    uniqueTestName: '"titleReferences" contextual validation rule #1',
    requirement: (context: WithdrawalOfCaveat2_20_1Model): boolean => $oneOfItems($items(context, 'titleReferences'), item => $eq($get(item, 'isSelected'), true)),
    message: 'At least 1 title reference must be selected'
  })
  .testContextualRule({
    /**
           * what we found in schema:
           [{"required":{"titleReferences":{"$items":{"$oneOf":{"isSelected":true}}}},"message":null}]
           *
           */
    uniqueTestName: 'titleReferences must contain one common caveat',
    requirement: (context: WithdrawalOfCaveat2_20_1Model): boolean => {
      // skip this validation if no title is selected
      const selectedTitles = getSelectedTitleReferences(context.titleReferences);
      if (!selectedTitles.length) {
        return true;
      }

      // verify that caveats share some common title reference
      return getCaveatsBeingWithdrawn(context.caveats, selectedTitles).length > 0;
    },
    message: 'There are no common caveats across selected titles'
  })
  .testContextualRule({
    /**
           * what we found in schema:
           [{"required":{"titleReferences":{"$items":{"$oneOf":{"isSelected":true}}}},"message":null}]
           *
           */
    uniqueTestName: 'titleReferences must contain one common caveat',
    requirement: (context: WithdrawalOfCaveat2_20_1Model): boolean => {
      // skip this validation if no title is selected
      const selectedTitles = getSelectedTitleReferences(context.titleReferences);
      if (!selectedTitles.length) {
        return true;
      }

      // verify that caveats share some common title reference
      return getRegisteredProprietors(selectedTitles).length > 0;
    },
    message: 'Registered Proprietors are not the same across selected titles'
  });

export default yupTitleReferences;
