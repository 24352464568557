interface Args {
  seed: string;
}

export const randomPaletteColor = ({ seed }: Args): string => {
  const stringHash = getStringHash(seed);
  const paletteIndex = getSeededRandomInt(stringHash, sympliPalette.length);
  return sympliPalette[paletteIndex];
};

const getStringHash = (input: string): number => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) hash += ((i + 1) * input.charCodeAt(i)) / (1 << 8);
  return hash;
};

const getSeededRandomInt = (seed: number, upperLimit: number): number => {
  const max = Math.floor(upperLimit);
  const pseudoRandomNumber = Math.sin(seed++) * 10000;
  return Math.floor((pseudoRandomNumber - Math.floor(pseudoRandomNumber)) * max);
};

const sympliPalette: string[] = [
  '#AE4A6C',
  '#E48B63',
  '#FBB03B',
  '#E4C463',
  '#96B580',
  '#A3BB56',
  '#599993',
  '#0D665B',
  '#6EC9CC',
  '#98B4CF',
  '#2E91D1',
  '#BF98CF',
  '#24BBD9',
  '#713148',
  '#B46E4F',
  '#A37427',
  '#B49C4F',
  '#808F7A',
  '#7D8A5E',
  '#396562',
  '#042A25',
  '#57A0A4',
  '#62778A',
  '#1D608C',
  '#7C648A',
  '#167E94'
];
