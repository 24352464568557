// this file was automatically generated from sections/styles.ts.mustache
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    fieldWidth: {
      width: '450px'
    }
  }),
  {
    name: 'DateOfDeath'
  }
);
