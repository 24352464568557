import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    optionIcon: {
      '& path': {
        fill: theme.palette.error.dark
      },
      marginRight: 5,
      width: 38,
      height: 36
    },
    okButton: {
      backgroundColor: theme.palette.error.dark,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    cancelButton: {
      '& svg': {
        fill: 'black'
      }
    },
    dialogPaper: {
      minWidth: 460
    },
    dialogContent: {
      lineHeight: '20px'
    }
  }),
  {
    name: 'ReportDeleteDialog'
  }
);
