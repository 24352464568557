import classNames from 'classnames';
import { SelectChangeEvent } from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';

import { LookupItemModel } from '@sympli/ui-framework/models';

import MultipleSelectCheckbox from './MultipleSelectCheckbox';

const useStyles = makeStyles(
  {
    root: {
      width: 64,
      '& label': {
        paddingLeft: 16
      },
      '& [role="combobox"] svg': {
        position: 'absolute',
        bottom: 13,
        left: 20
      }
    }
  },
  { name: 'MultiSelectCheckbox_AddFilter' }
);

function AddFilter<T extends string | number>({
  //
  className,
  label,
  options,
  onChange
}: {
  className?: string;
  label: string;
  options?: LookupItemModel<T>[];
  onChange(event: SelectChangeEvent<any>, resolvedValue: T | null): void;
}) {
  const classes = useStyles();
  return (
    <MultipleSelectCheckbox //
      label={label}
      value={null}
      IconComponent={() => null}
      placeholder={
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M18.7498 11.175H12.8248V5.25005C12.8248 4.79255 12.4573 4.42505 11.9998 4.42505C11.5423 4.42505 11.1748 4.79255 11.1748 5.25005V11.175H5.2498C4.7923 11.175 4.4248 11.5425 4.4248 12C4.4248 12.4575 4.7923 12.825 5.2498 12.825H11.1748V18.75C11.1748 19.2075 11.5423 19.575 11.9998 19.575C12.4573 19.575 12.8248 19.2075 12.8248 18.75V12.825H18.7498C19.2073 12.825 19.5748 12.4575 19.5748 12C19.5748 11.5425 19.2073 11.175 18.7498 11.175Z"
            fill="#292F36"
            stroke="#292F36"
          />
        </svg>
      }
      className={classNames(classes.root, className)}
      options={options}
      onChange={onChange}
      mode="single"
    />
  );
}
export default AddFilter;
