import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      height: 70,
      padding: '28px 15px 8px',
      boxSizing: 'border-box',
      fontSize: 28,
      fontWeight: 500,
      lineHeight: '32px',
      color: theme.palette.common.white
    },
    title2: {
      display: 'flex',
      height: 56,
      padding: '11px 16px',
      boxSizing: 'border-box',
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
      color: theme.palette.common.white
    },
    messageCount: {
      background: theme.palette.secondary.main,
      width: 24,
      height: 24,
      marginTop: -4,
      fontSize: 13,
      color: theme.palette.common.white,
      borderRadius: '50%'
    },
    messageList: {
      height: '100%',
      overflowY: 'auto'
    },
    messageItem: {
      paddingTop: 12,
      paddingBottom: 12
    },
    messageItemContent: {
      paddingLeft: 6,
      flexGrow: 1,
      overflow: 'hidden'
    },
    messageItemHeader: {
      fontSize: 13,
      lineHeight: '15px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    messageItemDesc: {
      fontSize: 12,
      lineHeight: '14px',
      color: theme.palette.text.secondary,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    messageItemDate: {
      flexShrink: 0,
      paddingLeft: 4,
      fontSize: 12,
      lineHeight: '14px'
    },
    highlightHeader: {
      fontWeight: 'bold'
    },
    highlightDesc: {
      color: theme.palette.text.primary
    },
    highlightDot: {
      width: 6,
      height: 6,
      marginLeft: -12,
      marginRight: 2,
      background: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.common.white}`,
      borderRadius: '50%',
      flexShrink: 0
    },
    // No conversation style
    zeroInboxIcon: {
      marginBottom: 9
    },
    zeroInboxDesc: {
      padding: '0px 50px',
      fontSize: '16px',
      textAlign: 'center'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
