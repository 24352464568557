import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconInvoiceDollar } from '@sympli/ui-framework/icons';

import styles, { ClassKeys } from '../sharedStyles';

interface OwnProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class ChangeBillingMethod extends React.PureComponent<Props> {
  render() {
    const { classes, onClick } = this.props;

    return (
      <MenuItem onClick={onClick}>
        <IconInvoiceDollar className={classes.menuItemIcon} />
        <Typography className={classes.menuItemLabel}>Edit Sympli fee billing method</Typography>
      </MenuItem>
    );
  }
}

export default withStyles(styles)(ChangeBillingMethod);
