import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    cardToday: {
      color: colors.SUNNY_DAY_DARK
    },
    cardTodayTitle: {
      color: colors.NEUTRAL_1000
    },
    cardStatusToday: {
      color: colors.SUNNY_DAY
    }
  }),
  {
    name: 'yellowCard'
  }
);
