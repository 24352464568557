import * as React from 'react';

import { FieldProps } from 'formik';
import _get from 'lodash-es/get';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import msg from '@sympli/ui-framework/utils/messages';

import { enhanceTitle } from '../../modules/helpers';
import Fraction from '../fraction';
import styles, { ClassKeys } from './styles';
import withSourceBinding from './withSourceBinding';

interface OwnProps {
  name: string;
  sourceName: string;
  itemIdentifierName: string;
  itemLabelPrefix: string;

  numeratorName?: string;
  denominatorName?: string;
}

type Props = OwnProps & FieldProps & WithStyles<ClassKeys>;

class FractionGroupField extends React.PureComponent<React.PropsWithChildren<Props>> {
  static defaultProps: Partial<Props> = {
    numeratorName: 'numerator',
    denominatorName: 'denominator'
  };

  private resolveItemLabel(item: object, index: number) {
    let { itemLabelPrefix } = this.props;

    let label = `${itemLabelPrefix} ${index + 1}`;
    return enhanceTitle({ title: label, data: item });
  }

  private resolveFieldName(part: string, i: number) {
    const {
      field: { name }
    } = this.props;

    return `${name}[${i}].${part}`;
  }

  private resolveFieldError(name: string) {
    const {
      form: { touched, errors }
    } = this.props;
    return _get(touched, name) && _get(errors, name);
  }

  get error() {
    return msg.REQUIRED;
  }

  render() {
    const { field, itemIdentifierName, numeratorName: numerator, denominatorName: denominator } = this.props;

    // get groups data

    return field.value.map((item, i) => {
      const numeratorName = this.resolveFieldName(numerator!, i);
      const denominatorName = this.resolveFieldName(denominator!, i);

      const numeratorError = this.resolveFieldError(numeratorName) as string;
      const denominatorError = this.resolveFieldError(denominatorName) as string;

      const numeratorValue = item[numerator!];
      const denominatorValue = item[denominator!];

      return (
        <Fraction //
          key={item[itemIdentifierName]}
          label={this.resolveItemLabel(item, i)}
          numeratorName={numeratorName}
          denominatorName={denominatorName}
          numeratorValue={numeratorValue}
          denominatorValue={denominatorValue}
          numeratorError={numeratorError}
          denominatorError={denominatorError}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
      );
    });
  }
}

const styledComponent = withStyles(styles)(FractionGroupField);
export default withSourceBinding(styledComponent);
