import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { MortgageTermsAndConditionsModel, PreRegisteredStandardTerm, StandardTermsDocumentReferenceModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function StandardTermsDocumentReference({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<StandardTermsDocumentReferenceModel>(name);
  const { values } = useFormikContext<MortgageTermsAndConditionsModel>();
  const value: StandardTermsDocumentReferenceModel = _get(values, name);

  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(value, values);

  const options = useMemo(() => {
    const standardTermsDocumentReferences = values.standardTermsDocumentReferences;
    const preRegisteredStandardTerms: PreRegisteredStandardTerm[] =
      values.preRegisteredStandardTerms ??
      values.preRegisteredStandardTermsDocumentReferences?.map(x => {
        return { value: x };
      }) ??
      []; // preRegisteredStandardTermsDocumentReferences is obsolete, do not use in new document version. Use PreRegisteredStandardTerms instead

    const selectedDocumentReferences = standardTermsDocumentReferences.map(v => (v.documentReference ?? '').trim()).filter(v => v !== '');
    return preRegisteredStandardTerms
      .filter(x => !selectedDocumentReferences.includes(x.value) || x.value === (value.documentReference ?? '').trim())
      .map(({ value, description }) => ({ key: value, value: description ? `${value} (${description})` : value }));
  }, [values.preRegisteredStandardTerms, values.preRegisteredStandardTermsDocumentReferences, values.standardTermsDocumentReferences, value.documentReference]);

  const placeholder = options.length ? resolveComboboxPlaceholder(isRequired) : resolvePlaceholder(isRequired);

  return (
    <div data-name={name} className={className}>
      <Field //
        aria-label="Document reference"
        name={fieldName('documentReference')}
        component={SympliAutocompleteField}
        disabled={disabled}
        placeholder={placeholder}
        options={options}
        optionIdAttr="key"
        optionNameAttr="value"
        getCustomSelectedOptionLabel={(item: any) => item.key}
        type="combobox"
      />
    </div>
  );
}

export default React.memo(StandardTermsDocumentReference);
