import * as yup from 'yup';

import yupTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import yupMortgagees from './components/mortgagee-party-readonly-array/validationSchema';
import { DischargeOfMortgage2_19_1Model } from './models';
import yupMortgages from './sections/mortgages/validationSchema';
import { yupPartyBook } from './sections/party-book/validationSchema';
import yupSetOfOperativeWords from './sections/set-of-operative-words/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
type FormValues = DischargeOfMortgage2_19_1Model;

export default yup
  .object<Partial<FormValues>>({
    mortgages: yupMortgages as any,
    setOfOperativeWords: yupSetOfOperativeWords,
    titleReferences: yupTitleReferences as any,
    mortgagees: yupMortgagees as any,
    partyBook: yupPartyBook
  })
  .log();
