import * as React from 'react';

import classNames from 'classnames';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { useStyles } from './styles';

interface SettingContentTopPanelProps {
  className?: string;
}

function SettingContentTopPanel(props: React.PropsWithChildren<SettingContentTopPanelProps>) {
  const { className, children } = props;
  const classes = useStyles();
  return (
    <FlexLayout className={classNames(classes.root, className)} alignItems="center" justifyContent="flex-end">
      {children}
    </FlexLayout>
  );
}

export default SettingContentTopPanel;
