import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    selectReports: {
      display: 'inline-flex',
      alignItems: 'flex-start',
      marginTop: 40,
      marginBottom: 40
    },
    separator: {
      marginLeft: 15,
      marginRight: 4,
      fontSize: 28,
      color: theme.palette.text.primary
    },
    reportName: {
      fontWeight: 'normal'
    },

    iconPencil: {
      marginRight: 5
    },
    button: {
      '& svg': {
        color: theme.palette.common.white
      }
    }
  }),
  {
    name: 'ScheduledReportHistoryHeader'
  }
);
