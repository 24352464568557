import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import MortgagorPartyArray from './components/mortgagor-party-array';
import { WA_M_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { filterMortgagors } from './helper';
import { IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT } from './isRequiredChecks';
import { Mortgage2_27_0Model } from './models';
import AdditionalCovenants from './sections/additional-covenants';
import McpNumber from './sections/mcp-number';
import MortgageDate from './sections/mortgage-date';
import MortgageeDetails from './sections/mortgagee-details';
import { VISIBILITY_CHECK_MORTGAGORS } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Mortgage2_27_0Model>();

function RootForm({
  className, //
  hideActionsPanel,
  queryParams,
  onCancel,
  onSaveChanges
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage2_27_0Model>();
  const { values, setValues } = formikProps;
  const { mcpNumber, additionalCovenants } = values;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgagorsRef = React.useRef<ButtonBaseActions>(null);
  const focusOnMortgageesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnMortgagorsRef.current || focusOnMortgageesRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        return {
          ...values,
          mortgagors: filterMortgagors(values)
        };
      });
    },
    [setValues]
  );

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          data-testid={fieldName('titleReferences')}
          disabled={disabled}
          onChange={handleTitleReferenceSelectionChange}
          focusRef={focusOnTitleReferencesRef}
          allowPartLandSelection
          disablePartDescriptionSupport
        />

        {VISIBILITY_CHECK_MORTGAGORS({ titleReferences: values.titleReferences }) ? (
          <Section
            title={pluralize('Mortgagor', values.mortgagors.length)}
            data-name={fieldName('mortgagors')}
            data-testid={fieldName('mortgagors')}
            error={getFormikError(formikProps, fieldName('mortgagors'))}
          >
            <MortgagorPartyArray name={fieldName('mortgagors')} focusRef={focusOnMortgagorsRef} />
          </Section>
        ) : null}

        <MortgageeDetails name={fieldName('mortgageeDetails')} focusRef={focusOnMortgageesRef} data-testid="mortgageeDetails" />

        <Section title="Terms and conditions">
          <McpNumber name={fieldName('mcpNumber')} />
          <AdditionalCovenants name={fieldName('additionalCovenants')} />
          <MortgageDate name={fieldName('mortgageDate')} />
        </Section>

        <DocumentAttachments //
          name={fieldName('additionalAttachments')}
          minItems={0}
          attachmentDocumentTypes={WA_M_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS}
          isRequired={IS_REQUIRED_CHECK_DOCUMENT_ATTACHMENT(mcpNumber, additionalCovenants?.value)}
        />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            nextLabel={nextActionLabel}
            onBack={onCancel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
