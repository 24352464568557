import { defineAction } from 'redux-define';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { createActionWithHandleAction, ERROR, SUCCESS } from '@sympli/ui-framework/actions';

import { UserReportsPermissionsApiRequest, UserReportsPermissionsApiResponse } from 'src/containers/dashboard/reports/models';
import { MfaLinkedDeviceModel } from 'src/containers/personal-profile/multi-factor-auth-devices/api';
import { PaginatedItemsModel, TableFiltersModel } from 'src/models';
import { UserApiResponse } from '../group-settings/models';
import { UserDetailsApiResponse } from '../users-detail/detail/models';
import { UserPermissionsApiResponse } from '../users-detail/models';

// users settings
export type UsersApiRequest = TableFiltersModel & {
  isArchived?: boolean;
  role?: number;
  status?: number;
  permissions?: string;
  search?: string;
  groupIds?: Array<string>;
};

export interface UsersApiResponse extends PaginatedItemsModel<UserApiResponse> {}

export const actionFetchUsersFeed = createAsyncAction('FETCH_USERS_FEED', 'FETCH_USERS_FEED_SUCCESS', 'FETCH_USERS_FEED_ERROR')<
  UsersApiRequest,
  { data: UsersApiResponse },
  { error: Error }
>();

export type UserDetailsApiRequest = string;

export const actionFetchUserDetails = createAsyncAction(
  //
  'FETCH_USER_DETAILS',
  'FETCH_USER_DETAILS_SUCCESS',
  'FETCH_USER_DETAILS_ERROR'
)<
  //
  UserDetailsApiRequest,
  // we need partial here because we initialize data for creating a new user in our saga and things like id are missing in that case
  { data: Partial<UserDetailsApiResponse> },
  { error: Error }
>();

export const actionResetUserDetails = createAction(
  //
  'RESET_USER_DETAILS'
)();

export const actionDeleteUserFromStore = createAction(
  //
  'DELETE_USER_FROM_STORE'
)<string>();

export const actionUpdateUserDetails = createAsyncAction(
  //
  'UPDATE_USER_DETAILS',
  'UPDATE_USER_DETAILS_SUCCESS',
  'UPDATE_USER_DETAILS_ERROR'
)<
  //
  Partial<UserDetailsApiResponse>,
  { data: any },
  { error: Error }
>();

export type FetchUserPermissionsRequestModel = string;

export const actionFetchUserPermissions = createAsyncAction(
  //
  'FETCH_USER_PERMISSIONS',
  'FETCH_USER_PERMISSIONS_SUCCESS',
  'FETCH_USER_PERMISSIONS_ERROR'
)<
  //
  FetchUserPermissionsRequestModel,
  { data: UserPermissionsApiResponse },
  { error: Error }
>();

export const actionFetchUserReportsPermissions = createAsyncAction(
  //
  'FETCH_USER_REPORTS_PERMISSIONS',
  'FETCH_USER_REPORTS_PERMISSIONS_SUCCESS',
  'FETCH_USER_REPORTS_PERMISSIONS_ERROR'
)<
  //
  UserReportsPermissionsApiRequest,
  { data: UserReportsPermissionsApiResponse },
  { error: Error }
>();

export type DeleteUserRequestModel = string;

export const actionDeleteUser = createAsyncAction(
  //
  'DELETE_USER',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_ERROR'
)<
  //
  DeleteUserRequestModel,
  { data: any },
  { error: Error }
>();

export const actionFetchUserLinkedDevicesFeed = createAsyncAction(
  //
  'FETCH_USER_LINKED_DEVICES_FEED',
  'FETCH_USER_LINKED_DEVICES_FEED_SUCCESS',
  'FETCH_USER_LINKED_DEVICES_FEED_ERROR'
)<
  //
  string,
  { data: MfaLinkedDeviceModel[] },
  { error: Error }
>();

export interface ConfirmDialogModel {
  isLoading: boolean;
  isOpen: boolean;
  deviceId?: string;
}

export const actionUpdateConfirmDialog = createAction(
  //
  'UPDATE_CONFIRM_DIALOG'
)<
  //
  Partial<ConfirmDialogModel>
>();

export interface DeleteUserLinkedDeviceRequestModel {
  userId: string;
  deviceId: string;
  // this can be set by AuthenticationDialog if MFA is activated
  httpConfig?: {
    params: {
      mfaId: string;
    };
  };
}

export const DELETE_USER_LINKED_DEVICE = defineAction('DELETE_USER_LINKED_DEVICE', [ERROR, SUCCESS]);
export const actionDeleteUserLinkedDevice = createActionWithHandleAction<
  //
  DeleteUserLinkedDeviceRequestModel,
  { data: DeleteUserLinkedDeviceRequestModel },
  { error: Error }
>(DELETE_USER_LINKED_DEVICE);

export interface UpdateUserSuspensionRequestModel {
  id: string;
  isSuspended: boolean;
  // this can be set by AuthenticationDialog if MFA is activated
  httpConfig?: {
    params: {
      mfaId: string;
    };
  };
}

export const UPDATE_USER_SUSPENSION = defineAction('UPDATE_USER_SUSPENSION', [ERROR, SUCCESS]);
export const actionUpdateUserSuspension = createActionWithHandleAction<
  //
  UpdateUserSuspensionRequestModel,
  { data: UpdateUserSuspensionRequestModel },
  { error: Error }
>(UPDATE_USER_SUSPENSION);

export const actionUpdateSuspendConfirmDialog = createAction(
  //
  'UPDATE_SUSPEND_CONFIRM_DIALOG'
)<//
{ isOpen?: boolean }>();
