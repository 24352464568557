import React from 'react';

import { useField } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import GenericSectionTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { DischargeOfMortgage_2_23_1_Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  disabled: boolean;
  focusRef: React.RefObject<ButtonBaseActions>;
}

const SectionTitleReferences = ({ disabled, name, focusRef }: Props): JSX.Element => {
  const classes = useStyles();
  const [field] = useField<DischargeOfMortgage_2_23_1_Model['titleReferences']>(name) ?? [];

  return (
    <Section //
      title={pluralize('Title reference', field.value.length)}
      data-name={name}
      data-testid={name}
    >
      <GenericSectionTitleReferences //
        name={name}
        variant="naked"
        disabled={disabled}
        focusRef={focusRef}
        noDataMessage="The mortgages selected do not share a common title. Please review the selected mortgages."
        className={classes.titleReferences}
      />
    </Section>
  );
};

export default SectionTitleReferences;
