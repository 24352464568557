import { Action } from 'redux-actions';
import { createAsyncAction, EmptyAction } from 'typesafe-actions';

export interface ArchiveWorkspaceApiRequest {
  workspaceId: string;
  participantId?: string;
  callbackAction?: Action<unknown> | EmptyAction<string>;
}

export const actionArchiveWorkspace = createAsyncAction('ARCHIVE_WORKSPACE_BY_ID', 'ARCHIVE_WORKSPACE_BY_ID_SUCCESS', 'ARCHIVE_WORKSPACE_BY_ID_ERROR')<
  //
  ArchiveWorkspaceApiRequest,
  { workspaceId: string },
  { error: Error }
>();
