import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { sharedStyles } from '../sharedStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...sharedStyles(theme),
    root: {
      ...sharedStyles(theme).root,
      margin: theme.spacing(0, -2, 0, -2),
      padding: theme.spacing(0)
    },
    selected: {
      background: theme.palette.common.white
    },
    itemLink: {
      padding: theme.spacing(0.25, 2, 0.5, 2)
    },
    // body2_bold
    title: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43 // 20px
    },
    description: {
      lineHeight: 1.2, // 12px
      fontSize: 10
    },
    fullWidth: {
      width: '100%'
    }
  }),
  {
    name: 'MatterAndTasks'
  }
);
