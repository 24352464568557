import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS } from '../enums';
import { useStyles } from './styles';

interface Props {
  name: string;
}

const titleId: string = _uniqueId();

function SectionIssuingPartyDirection({ name }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  return (
    <Section title="Issuing party direction" titleId={titleId} data-name={name} className={classes.root}>
      <Field //
        aria-labelledby={titleId}
        component={RadioField}
        disabled={disabled}
        name={name}
        options={ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS}
        format="number"
        vertical
        className={classes.issuingPartyDirection}
      />
    </Section>
  );
}

export default React.memo(SectionIssuingPartyDirection);
