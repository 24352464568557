import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: 'red',
      marginTop: 0,
      marginRight: 3,
      position: 'relative',
      '& svg': {
        width: 12,
        height: 12,
        verticalAlign: 'bottom',
        paddingRight: 2
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
