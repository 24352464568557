import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    fieldWidth: {
      width: '450px'
    },
    halfFieldWidth: {
      width: '220px'
    },
    sectionWidth: {
      width: '100%'
    }
  }),
  {
    name: 'Evidence'
  }
);
