import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';

import { PriorityNoticeConfig } from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/config';
import { TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import {
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES,
  VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES
} from './visibilityChecks';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file

export const QLD_PN_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...QLD_PARTY_FORM_CONFIG
};

// TODO: WEB-27802, WEB-27837 Fix PN config in ticket
export const QLD_PN_CONFIG: PriorityNoticeConfig = {
  receivingParties: {
    visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$RECEIVING_PARTIES
  },
  titleReferences: {
    visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$TITLE_REFERENCES,
    partLandDescriptionLimit: 120
  },
  dealingNumber: {
    visibilityCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER,
    isRequiredCheck: VISIBILITY_CHECK_TRANSACTION_INSTRUMENTS$DEALING_NUMBER
  },
  documentTypeOptions: TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS
};
