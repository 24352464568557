import * as React from 'react';
import { memo } from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { StandaloneDashboardFilterModel, StandaloneDashboardRouteTabsEnumV2, StandaloneWorkspacesV2ApiRequest } from '../models';
import OpenWorkspacesCard from './components/OpenWorkspacesCard';
import ReadyToLodgeCard from './components/ReadyToLodgeCard';
import ReadyToSignCard from './components/ReadyToSignCard';
import ToBeReworkedCard from './components/ToBeReworkedCard';
import { StandaloneDashboardAggregatorApiRequest, StandaloneDashboardAggregatorApiResponse } from './models';
import { useStyles } from './styles';

export interface AggregatorsProps {
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(args: StandaloneDashboardAggregatorApiRequest): Promise<StandaloneDashboardAggregatorApiResponse>;
  onActionClick(filters: StandaloneWorkspacesV2ApiRequest, engagedTab: StandaloneDashboardRouteTabsEnumV2, currentTabFilters: StandaloneDashboardFilterModel): void;
  refetching?: boolean;
}

const Aggregators = (props: AggregatorsProps) => {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="row" className={classes.root}>
      <ToBeReworkedCard {...props} />
      <ReadyToSignCard {...props} />
      <ReadyToLodgeCard {...props} />
      <OpenWorkspacesCard {...props} />
    </FlexLayout>
  );
};

export default memo(Aggregators);
