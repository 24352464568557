import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartLandAffectedModel, TitleReferenceModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { $eq, $get, $items, $noneOfItems, getLookupValuesAllowingEmpty, isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { PriorityNoticeConfig } from '../../config';
import { PartyBookIdentifierModel, TransactionInstrumentModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
export function yupTransactionInstruments<T extends PartLandAffectedModel>(
  yupTitleReferenceItem: yup.Schema<TitleReferenceModel<T>[]>,
  priorityNoticeConfig: PriorityNoticeConfig,
  partyFormConfig: PartyFormConfig
) {
  const {
    //
    dealingNumber: dealingNumberConfig,
    receivingParties: receivingPartiesConfig,
    documentTypeOptions
  } = priorityNoticeConfig;

  return yup
    .array<TransactionInstrumentModel<T>>()
    .required(msg.REQUIRED)
    .min(1, msg.MIN_ITEMS(1))
    .max(20, msg.MAX_ITEMS(20))
    .of(
      yup
        .object<TransactionInstrumentModel<T>>()
        .nullable()
        .shape({
          documentType: yup.mixed().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(documentTypeOptions), msg.INVALID_SELECTION),
          dealingNumber: yup
            .mixed()
            .nullable()
            .test(
              validateWhenVisible({
                visibilityCheck: dealingNumberConfig.visibilityCheck,
                validationWhenVisible: yup
                  .string()
                  .default('')
                  .trim()
                  .nullable()
                  .test(
                    testContextualRule({
                      /**
           * what we found in schema:
           [{"required":true,"message":null,"onlyIf":{"documentType":{"$noneOf":[1,2,3,4,5,6,7,8,9,13]}}}]
           *
           */
                      uniqueTestName: '"transactionInstruments[*].dealingNumber" contextual validation rule #1',
                      onlyIf: dealingNumberConfig.isRequiredCheck,
                      requirement: isNotNullOrEmpty,
                      message: msg.REQUIRED
                    })
                  )
              })
            ),
          receivingParties: yup.mixed<PartyBookIdentifierModel>().test(
            validateWhenVisible({
              visibilityCheck: receivingPartiesConfig.visibilityCheck,
              validationWhenVisible: yup
                .array<PartyBookIdentifierModel>()
                .test(
                  testContextualRule({
                    uniqueTestName: '"transactionInstruments[*].receivingParties" contextual validation rule #1',
                    requirement: (receivingParties: PartyBookIdentifierModel[], context: object): boolean => {
                      return (
                        receivingParties.length > 0 &&
                        $noneOfItems($items(context, 'receivingParties'), item => $eq($get(item, 'partyBookId'), null) || $eq($get(item, 'partyBookId'), undefined))
                      );
                    },
                    message: msg.REQUIRED
                  })
                )
                .max(10, msg.MAX_ITEMS(10))
                .of(
                  yup
                    .object<PartyBookIdentifierModel>()
                    .nullable()
                    .shape({
                      partyBookId: yup.string().nullable().default('').trim().required(msg.REQUIRED).test(createPartyBookItemTestForPartyBookId(partyFormConfig))
                    })
                ),
              isObjectOrArray: true
            })
          ),
          titleReferences: yupTitleReferenceItem
        })
    );
}
