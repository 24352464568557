import * as React from 'react';
import { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { BaseItemModel } from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { TitleReferenceModel } from '../../models';
import PartLandAffected from './components/part-land-affected';
import { useStyles } from './styles';

type TitleReferencesProps = {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
};

function resolveItemLabelFn(titleReference: TitleReferenceModel) {
  return (
    <span>
      Title reference: <strong>{titleReference.reference}</strong>
    </span>
  );
}

export function TitleReferences<T extends BaseItemModel>({
  //
  name,
  focusRef
}: TitleReferencesProps): JSX.Element {
  const classes = useStyles();
  const { values, errors } = useFormikContext<T>();
  const { nextActionDisabled } = useDocumentContext();

  const error = getIn(errors, name);
  const displayError = typeof error === 'string';

  const renderItem = useCallback(
    (renderProps: DocumentArrayItemRenderProps<TitleReferenceModel>) => {
      const { item, itemBinding, fieldName, itemFocusRef } = renderProps;
      const titles = _get(values, name);
      const isTitleSelected = _get(values, fieldName('isSelected'));

      return (
        <div>
          <FlexLayout>
            <div className={classes.titleReference}>
              {titles.length > 1 ? (
                <Field
                  component={CheckboxField}
                  label={resolveItemLabelFn(item)}
                  name={fieldName('isSelected')}
                  disabled={nextActionDisabled}
                  className={classes.titleLabel}
                  action={itemFocusRef}
                />
              ) : (
                <Typography>{resolveItemLabelFn(item)}</Typography>
              )}
            </div>
            {isTitleSelected && (
              <Field //
                className={classes.partLandCheckbox}
                label="Part land affected"
                format="boolean"
                name={fieldName('isPartLandAffected')}
                component={CheckboxField}
                disabled={nextActionDisabled}
              />
            )}
          </FlexLayout>
          {isTitleSelected && (
            <PartLandAffected //
              disabled={nextActionDisabled}
              renderProps={{ fieldName, item, itemBinding }}
              focusRef={focusRef}
            />
          )}
        </div>
      );
    },

    [classes, nextActionDisabled, focusRef, name, values]
  );

  return (
    <div data-name={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        disabled={nextActionDisabled}
        itemStyle="none"
        mode="fixed"
        arrayTitle="Title References"
      />
      {displayError && (
        <FormHelperText role="alert" error data-error-name={name}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
}

export default React.memo(TitleReferences);
