import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { ResultItem } from '../../models';
import { FormatPlusNColumn, FormatReferenceColumn, FormatSympliIdColumn, FormatWorkspaceTypeColumn } from './Formatters';

export interface Data {
  sympliId: string;
  address?: string[];
  reference: string;
  title?: string[];
  jurisdiction: string;
  id: string;
  customer?: string[];
  workspaceId: string;
  participantId: string;
  searchHighlights?: ResultItem['searchHighlights'];
  workspaceType: WorkspaceTypeEnum;
}

export type ColumnKeyName = Exclude<keyof Data, 'searchHighlights' | 'workspaceId' | 'participantId' | 'id'>;

export interface FormattersProps {
  row: Data;
  value?: string | string[] | WorkspaceTypeEnum;
  columnName: ColumnKeyName;
}

interface ColumnData {
  dataKey: ColumnKeyName;
  label: string;
  width: number;
  formatter?: ({ row }: FormattersProps) => JSX.Element;
}

export const columns: ColumnData[] = [
  {
    width: 160,
    label: 'REFERENCE',
    dataKey: 'reference',
    formatter: FormatReferenceColumn
  },
  {
    width: 69,
    label: 'SYMPLI ID',
    dataKey: 'sympliId',
    formatter: FormatSympliIdColumn
  },
  {
    width: 88,
    label: 'TITLE',
    dataKey: 'title',
    formatter: FormatPlusNColumn
  },
  {
    width: 160,
    label: 'ADDRESS',
    dataKey: 'address',
    formatter: FormatPlusNColumn
  },
  {
    width: 40,
    label: 'JDX',
    dataKey: 'jurisdiction'
  },
  {
    width: 88,
    label: 'CUSTOMER',
    dataKey: 'customer',
    formatter: FormatPlusNColumn
  },
  {
    width: 80,
    label: 'WS TYPE',
    dataKey: 'workspaceType',
    formatter: FormatWorkspaceTypeColumn
  }
];
