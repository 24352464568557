import { CreateFinancialWorkspaceSharedModel } from './models';
import { InvitationItemModel } from './steps/invite-participants/models';

export const DEFAULT_INVITATION_ITEM_MODEL: InvitationItemModel = {
  inviteRoleAsSelf: false,
  subscriber: { id: '', name: '', elnoId: '', abn: '' },
  invitationDescription: '',
  groupId: '',
  matterNumber: ''
};

export const initialValues: CreateFinancialWorkspaceSharedModel = {
  matterDetailsStepData: {
    createdByReference: '',
    jurisdictionId: null,
    groupId: '',
    createdByRoleId: null,
    instructingOrganisationSettlementAgentId: '',

    // * material-ui-pickers can NOT deal date with empty string, use null
    settleDate: null,
    settleTime: '',
    isControllingParty: false,
    titleInformation: {
      jurisdictionId: null,
      titleInputValue: '',
      titleChips: [],
      // results from additional svc calls that we need for validation to be handled purely within yup
      titleVerificationResult: undefined
    },
    workspaceCreationErrorsMap: undefined,
    isInteroperable: undefined,
    transactionTypeId: null,
    transactionTypeOtherDescription: undefined
  },
  inviteParticipantsStepData: {
    vendors: [],
    invitedRoles: {},
    invitations: [],
    additionalInvitations: []
  }
};
