import { createAction, createAsyncAction } from 'typesafe-actions';

import { GetDirectionTrustAccountAuthorisationRecordsApiResponse, UnsignDirectionTrustAccountAuthorisationRecordsApiResponse } from '@sympli/api-gateway/models';

import { TrustAccountAuthorisationRecordsApiRequest } from './models';

export const actionFetchTrustAccountAuthorisationRecords = createAsyncAction(
  //
  'FETCH_TRUST_ACCOUNT_AUTHORISATION_RECORDS',
  'FETCH_TRUST_ACCOUNT_AUTHORISATION_RECORDS_SUCCESS',
  'FETCH_TRUST_ACCOUNT_AUTHORISATION_RECORDS_ERROR'
)<
  //
  TrustAccountAuthorisationRecordsApiRequest,
  { data: GetDirectionTrustAccountAuthorisationRecordsApiResponse },
  { error: Error }
>();

export const actionUpdateTrustAccountAuthorisationRecords = createAction(
  //
  'UPDATE_TRUST_ACCOUNT_AUTHORISATION_RECORDS'
)<
  //
  Partial<UnsignDirectionTrustAccountAuthorisationRecordsApiResponse>
>();
