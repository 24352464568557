import * as yup from 'yup';

import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

const yupPartyBookId = yup
  .string() //
  .default('')
  .nullable(true)
  .trim()
  .required(msg.REQUIRED);

const yupNullableString = yup.string().default('').nullable(true).defined();

const yupPartyReference = (isAddressRequired = true) =>
  yup
    .object<TenancyPartyModel>({
      partyBookId: yup
        .string() //
        .when('isSelected', {
          is: true,
          then: yupPartyBookId,
          otherwise: yupNullableString
        })
        .defined(),
      addressBookId: yup.mixed<string | undefined>().testContextualRule({
        uniqueTestName: '"addressBookId" required check',
        onlyIf: (parent: TenancyPartyModel): boolean => parent.isSelected && isAddressRequired,
        requirement: (parent: TenancyPartyModel) => isNotNullOrEmpty(parent.addressBookId),
        message: msg.REQUIRED
      }),
      isSelected: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
      partyCapacity: yup.mixed()
    })
    .defined();

interface Params {
  isRequired?: boolean;
  isAddressRequired?: boolean;
  partyReferenceLabel?: string;
  maxLength?: number;
  maxSelection?: number;
  requiredErrorMessage?: string;
}

const yupPartyReferences = ({
  isRequired = true,
  isAddressRequired = true,
  partyReferenceLabel = 'party',
  maxLength,
  maxSelection,
  requiredErrorMessage
}: Params | undefined = {}) => {
  const yupPartyReferenceItem = yupPartyReference(isAddressRequired);

  let yupPartyReferencesSchema = yup.array<TenancyPartyModel>(yupPartyReferenceItem); //

  if (maxLength) {
    yupPartyReferencesSchema = yupPartyReferencesSchema.max(maxLength, msg.MAX_ITEMS(maxLength));
  }

  if (maxSelection) {
    yupPartyReferencesSchema = yupPartyReferencesSchema
      .test(
        'Party reference max selection count',
        `Must select ${maxSelection} parties or less`,
        function test(this: yup.TestContext<TenancyPartyModel[]>, values: TenancyPartyModel[]) {
          return (values || []).filter(({ isSelected }) => isSelected).length <= maxSelection;
        }
      )
      .log();
  }

  if (isRequired) {
    yupPartyReferencesSchema = yupPartyReferencesSchema
      .test(
        'Party reference selection',
        requiredErrorMessage || `At least one ${partyReferenceLabel} must be selected.`,
        function test(this: yup.TestContext<TenancyPartyModel[]>, values: TenancyPartyModel[]) {
          return (values || []).some(({ isSelected }) => isSelected);
        }
      )
      .log();
  }

  return yupPartyReferencesSchema;
};

export default yupPartyReferences;
