import * as React from 'react';

import { FieldProps } from 'formik';
import _get from 'lodash-es/get';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { InputComponentProps } from '@sympli/ui-framework/components/form/base-components/input';
import msg from '@sympli/ui-framework/utils/messages';

import Fraction from '../fraction';
import styles, { ClassKeys } from './styles';

interface OwnProps extends InputComponentProps {
  numeratorName?: string;
  denominatorName?: string;
}

type Props = OwnProps & FieldProps & WithStyles<ClassKeys>;

class FractionField extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    numeratorName: 'numerator',
    denominatorName: 'denominator'
  };

  private resolveFieldName(part: string) {
    const {
      field: { name }
    } = this.props;

    return `${name}.${part}`;
  }

  private resolveFieldValue(name: string) {
    return _get(this.props.form.values, name);
  }

  private resolveFieldError(name: string) {
    const {
      form: { touched, errors }
    } = this.props;
    const error: string | undefined = _get(touched, name) && (_get(errors, name) as any);
    return error;
  }

  private get numeratorName() {
    return this.resolveFieldName(this.props.numeratorName!);
  }

  private get denominatorName() {
    return this.resolveFieldName(this.props.denominatorName!);
  }

  // private resolveFractions(value: string = '') {
  //   const [_ = null, numerator = null, denominator = null] = value.match(REGEXP_FRACTION) || [];
  //   return [numerator, denominator];
  // }

  get error() {
    return msg.REQUIRED;

    // const {
    //   field: { name },
    //   form: { touched, errors }
    // } = this.props;

    // const error = (_get(touched, name) && _get(errors, name)) || undefined;
    // return error;
  }

  render() {
    const { disabled, label, field } = this.props;
    const { numeratorName, denominatorName } = this;
    const numeratorValue = this.resolveFieldValue(numeratorName);
    const denominatorValue = this.resolveFieldValue(denominatorName);
    const numeratorError = this.resolveFieldError(numeratorName);
    const denominatorError = this.resolveFieldError(denominatorName);

    return (
      <Fraction
        label={label}
        numeratorName={numeratorName}
        numeratorValue={numeratorValue}
        numeratorError={numeratorError}
        denominatorName={denominatorName}
        denominatorValue={denominatorValue}
        denominatorError={denominatorError}
        disabled={disabled}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
    );
  }

  // private handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: any = '') => {
  //   event.persist();
  //   const { form, field } = this.props;
  //   const name = event.target.name;

  //   if (this.resolveFieldValue(name) !== resolvedValue) {
  //     // mutate data directly since we are trigerring onChange that will internally reset the state
  //     _set(form.touched, name, true);
  //     field.onChange(event);
  //   } else {
  //     form.setFieldTouched(name, true);
  //     field.onBlur(event);
  //   }
  // };
}

const styledComponent = withStyles(styles)(FractionField);
export default styledComponent;
