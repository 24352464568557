import {
  BillingMethodEnum,
  DocumentActionTypeEnum,
  JurisdictionsEnum,
  LinkedWorkspaceOverallStatusEnum,
  LodgementCaseStatusEnum,
  SettleSmartStatusEnum,
  SubscriberBankAccountTypeEnum,
  WorkspaceRoleEnum,
  WorkspaceStatusEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceDetailApiResponse } from '@sympli/api-gateway/models';
import { ExpectedSettlementDate, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';

import { DocumentActionModel } from 'src/containers/documents/models';
import { AccountModel, LodgementCaseLogModel } from 'src/models';

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.BasicWorkspaceDetail
 */
// export interface WorkspaceBasicInfoApiResponse {
//   id: string;
//   sympliId: string;
//   isLocked: boolean;
//   jurisdictionId: JurisdictionsEnum;
//   lodgementCaseStatusId: LodgementCaseStatusEnum;
//   workspaceStatusId: WorkspaceStatusEnum;
//   workspaceTypeId: WorkspaceTypeEnum;
//   settlementDate?: SettlementDateTimeModel; // only available for fi and refinance workspaces
//   lastWorkspaceSettlementReadyCheck?: SettlementReadyCheck; // only available for fi and refinance workspaces
//   expectedLodgementDate?: SettlementDateTimeModel; // only available for lodgement only ws
//   // we made this optional just to satisfy TS so we can preload this basic info when user clicks in workspace dashboard
//   // in reality, the workspace basic info always returns it
//   // TODO xinxin to add this property to dashboard items so we can remove this optional flag
//   numberOfTasks?: number; // number of tasks for all participants in the workspace
//   createdUtc: string;
//   lastUpdatedUtc?: string;
//   isInteroperable?: boolean;
//   isRollover?: boolean;
//   // seems to be unused
//   // stampDutyStatusId: StampDutyStatusEnum;
//   // workspaceNumber: number;
//   // responsibleElnoId: string;
//   // createdByUserId: string;
//   // isSettlement: boolean;
//   // isSettlementDateApproved: boolean;
// }

/**
 *  MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.WorkspaceDetails
 */
// export interface WorkspaceDetailApiResponse {
//   id: string;
//   sympliId: string;
//   isLocked: boolean;
//   isIntegrated?: boolean;
//   jurisdiction: LookupEnumModel<JurisdictionsEnum>;
//   lodgementCaseStatus: LookupEnumModel<LodgementCaseStatusEnum>;
//   workspaceStatus: LookupEnumModel<WorkspaceStatusEnum>;
//   workspaceType: LookupEnumModel<WorkspaceTypeEnum>;
//   settlementDateTime?: SettlementDateTimeModel; // Financial only
//   lastSettlementReadyCheck?: SettlementReadyCheck; // Financial only
//   expectedLodgementDate?: SettlementDateTimeModel;
//   numberOfTasks: number; // total number of unresolved tasks in the workspace for all participants (we currently use the one from basic endpoint)
//   created: string;
//   lastUpdated?: string;

//   // different from the basic endpoint
//   titleReferences: Array<TitleReference>;
//   lodgementDetail?: LodgementDetail;
//   settlementStatusDetail?: SettleSmartDetailModel; // Financial only
//   propertyAddress?: string;
//   lodgementCaseLogs: Array<LodgementCaseLogModel>;
//   supportTitleActivityCheck?: boolean;
//   canRearrangeLodgementInstructions?: boolean;
//   isValidOperatingHoursForRegOnly?: boolean; // LO only
//   isAutoTacCeased: boolean; // LO only
//   canAddAdditionalDocuments?: boolean;
//   requiresStampDuty: boolean;
//   // not used? check with xinxin whether it can be removed
//   // workspaceParticipants?: InvitedParticipantApiResponse[]; // TODO remove this since it's not returned by API anymore
//   // workspaceReference?: string; // not used
// }

export interface WorkspaceDetailModel extends WorkspaceDetailApiResponse {
  settlementDateInUserTimezone?: string;
  tillSettlement?: number;
  currentParticipantId?: string;
  tacKey?: string;
}

// MR - moved
// export enum SettleSmartStatusEnum {
//   InProgress = 0,
//   Completed = 1,
//   PartiallyCompleted = 2,
//   Fail = 3,
//   Postponed = 4,
//   FailedWithPostponed = 5,
//   Delayed = 6
// }

export enum ReadyForSettlementFailureCodeEnum {
  SroRetrievalError = 1,
  NoSroFound = 2,
  SroCheckFailed = 3,
  LodgementVerificationRetrievalError = 4,
  NoLodgementVerificationFound = 5,
  LodgementVerificationCheckFailed = 6,
  UnfinishedTasks = 7,
  UnfinishedTasksRetrievalError = 8,
  FinancialBalanceCheckRetrievalError = 9,
  FinancialBalanceCheckFailed = 10
}

export enum SettlementStepEnum {
  PreSettlement = 'PreSettlement',
  Bsb = 'Bsb',
  Sro = 'Sro',
  LodgementVerification = 'LodgementVerification',
  FsmReservation = 'FsmReservation',
  Lodgement = 'Lodgement',
  FinancialDisbursement = 'FinancialDisbursement',
  ReservationCancellation = 'ReservationCancellation',
  StampDutyReservation = 'StampDutyReservation'
}

export const WorkspaceSettlementStatusEnumMapping: Record<SettleSmartStatusEnum, string> = {
  [SettleSmartStatusEnum.InProgress]: 'Settlement in progress',
  [SettleSmartStatusEnum.Completed]: 'Settlement completed',
  [SettleSmartStatusEnum.PartiallyCompleted]: 'Settlement partially completed',
  [SettleSmartStatusEnum.Fail]: 'Settlement failed',
  [SettleSmartStatusEnum.Postponed]: 'Settlement in progress',
  [SettleSmartStatusEnum.FailedWithPostponed]: 'Settlement failed',
  [SettleSmartStatusEnum.Delayed]: 'Settlement rolled'
};

// MR - svc moved
// Tickle.Application.Domain.Workspaces.Model.WorkspaceSettlementReadyCheck
// export interface SettlementReadyCheck {
//   isReady: boolean;
//   reason?: { id: ReadyForSettlementFailureCodeEnum; name?: string };
// }

/**
 * MR - moved
 * Tickle.Application.Domain.Workspaces.Model.Lodgement.LodgementDetail
 */
// export interface LodgementDetail {
//   id?: string;
//   isLodgementReady: boolean;
//   isCompliant: boolean;
//   dateTime?: SettlementDateTimeModel;
//   lodgementCompliances: LodgementCompliance[];
// }

/**
 * MR - moved
 * Tickle.Application.Domain.Workspaces.Model.Lodgement.LodgementCompliance
 */
// export interface LodgementCompliance {
//   message: string;
//   level: ComplianceLevelEnum;
// }
export type LodgementCompliance = Exclude<WorkspaceDetailApiResponse['lodgementDetails'], undefined>[number]['lodgementCompliances'][number];
export interface DocumentActionInfo {
  numberOfActions?: number; // this used as number of signers/approvers/savers
  documentActions: Array<DocumentActionModel>;
  actionType: DocumentActionTypeEnum;
}

export interface ParticipantSettingApiResponse {
  billingSetting?: BillingSetting;
}

export interface BillingSetting {
  billingMethodType: BillingMethodEnum;
  isBillingMethodChangeableByUser?: boolean;
}

export type LodgementCaseLogMap = { [key in LodgementCaseStatusEnum]: Array<LodgementCaseLogModel> };

// type AuthorityLodgementDetail = Omit<LodgementDetail, 'dateTime'> & {
//   id: string;
//   dateTime: SettlementDateTimeModel;
// };

// export interface AuthorityComplianceReportApiResponse {
//   lodgementCaseCompliance: AuthorityLodgementDetail;
//   documents: DocumentApiResponse[];
//   messageType: AuthorityComplianceReportTypeEnum;
// }

export interface WorkspaceLatestTransactionApiResponse {
  workspaceId: string;
  settlementDateUtc: string;
  isRollover: boolean;
  settlementStep?: SettlementStepEnum;
}

export interface Enumeration<T> {
  value: T;
  description: string;
}

export interface FinancialAccount extends AccountModel {
  id: string;
  accountType: Enumeration<SubscriberBankAccountTypeEnum>;
  categories: string[];
}

export enum SettlementNotificationStatusEnum {
  InProgress = 0,
  Success = 1,
  Fail = 2,
  PendingDecision = 3
}

export enum SettlementNotificationTypeEnum {
  Undefined = -1,
  Settlement = 0,
  Bsb = 1,
  Sro = 2,
  LodgementVerification = 3,
  FsmReservation = 4,
  Lodgement = 5,
  FinancialDisbursement = 6,
  ReservationCancellation = 7,
  SettleFunds = 8,
  DisburseFunds = 9
}

export interface LinkedSettlementDetailApiRequest {
  workspaceId: string;
  clusterId: string;
}

export type LinkedSettlementDetailApiResponse = LinkedSettlementDetail;

export interface LinkedSettlementDetail {
  clusterId: string;
  linkedWorkspaceOverallStatus: LinkedWorkspaceOverallStatusEnum;
  linkedWorkspaceDetails: LinkedSettlementWorkspaceDetail[];
}

export interface LinkedSettlementWorkspaceDetail {
  workspaceId: string;
  workspaceStatus: WorkspaceStatusEnum;
  settlementDate?: WorkspaceDateTimeModel;
  pendingProposedSettlementDate?: WorkspaceDateTimeModel;
  expectedSettlementDate?: ExpectedSettlementDate;
  jurisdiction: JurisdictionsEnum;
  sympliId: string;
  titles: string[];
  linkedWorkspaceParticipants: LinkedWorkspaceParticipant[];
  sourceFundLinkedBy?: LinkedByParticipant;
  paymentLinkedBy?: LinkedByParticipant;
  isUnsupportedSettlementDate?: boolean;
}

export interface LinkedWorkspaceParticipant {
  participantId: string;
  workspaceRole: WorkspaceRoleEnum;
  subscriberDisplayName: string;
  subscriberId: string;
  groupId?: string;
}

export interface LinkedByParticipant {
  participantId: string;
  workspaceRole: WorkspaceRoleEnum;
  subscriberDisplayName: string;
}
