import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

import { EvidenceTypeEnum } from './components/evidence/enums';
import { DeceasedTenancyPartyCapacityEnum } from './enums';
import { DeceasedProprietorGroupModel, DeceasedProprietorModel, EvidenceModel } from './models';

export const isDisabledGroup = (deceasedProprietorGroups: DeceasedProprietorGroupModel[], isAffected: boolean): boolean => {
  if (!deceasedProprietorGroups.some(g => g.isSelected)) {
    return false;
  }
  return !isAffected;
};

export const filterOrganisationProprietors = (deceasedProprietor: DeceasedProprietorModel, partyBook: PartyModel[]): DeceasedProprietorModel | undefined => {
  const party = partyBook.find(p => p.id === deceasedProprietor.partyBookId);
  return party?.partyType === PartyTypeEnum.Organisation ? deceasedProprietor : undefined;
};

export const haveOrganisationPartyInGroup = (group: DeceasedProprietorGroupModel, partyBook: PartyModel[]): boolean => {
  return group.parties.some(x => filterOrganisationProprietors(x, partyBook) !== undefined);
};

export const updateProprietorWithCapacity = (
  newProprietor: DeceasedProprietorModel,
  isLastDeceased: boolean,
  partyCapacity?: DeceasedTenancyPartyCapacityEnum | null,
  existingProprietor?: DeceasedProprietorModel
): DeceasedProprietorModel => {
  existingProprietor ??= newProprietor;
  const defaultEvidences = initDefaultEvidences(partyCapacity!, isLastDeceased, existingProprietor.evidences);
  const otherEvidences = existingProprietor.evidences!.filter(({ defaultAdded }) => !defaultAdded);
  return { ...newProprietor, dateOfDeath: existingProprietor.dateOfDeath, evidences: [...defaultEvidences, ...otherEvidences] };
};

function initDefaultEvidences(deceasedTenancyPartyCapacity: DeceasedTenancyPartyCapacityEnum, isLastDeceased: boolean, existingEvidences?: EvidenceModel[]): EvidenceModel[] {
  const hasExistingEvidences = existingEvidences && existingEvidences.length;
  const defaultEvidenceDate = hasExistingEvidences ? existingEvidences[0].date : null;
  const defaultEvidenceDocumentReference = hasExistingEvidences ? existingEvidences[0].documentReference : '';
  if (!isLastDeceased) {
    return [createDefaultEvidence(EvidenceTypeEnum.DeathCertificate, defaultEvidenceDate, defaultEvidenceDocumentReference)];
  }

  let evidences: EvidenceModel[];
  switch (deceasedTenancyPartyCapacity) {
    case DeceasedTenancyPartyCapacityEnum.Administrator:
      evidences = [createDefaultEvidence(EvidenceTypeEnum.LettersOfAdministration, defaultEvidenceDate, defaultEvidenceDocumentReference)];
      break;
    case DeceasedTenancyPartyCapacityEnum.ExecutorByRepresentation:
      evidences = [
        createDefaultEvidence(EvidenceTypeEnum.Probate, defaultEvidenceDate, defaultEvidenceDocumentReference),
        createDefaultEvidence(
          EvidenceTypeEnum.Probate,
          existingEvidences && existingEvidences.length > 1 ? existingEvidences[1].date : null,
          existingEvidences && existingEvidences.length > 1 ? existingEvidences[1].documentReference : ''
        )
      ];
      break;
    case DeceasedTenancyPartyCapacityEnum.Trustee:
      evidences = [createDefaultEvidence(EvidenceTypeEnum.DeedOfAppointment, defaultEvidenceDate, defaultEvidenceDocumentReference)];
      break;
    case DeceasedTenancyPartyCapacityEnum.Executor:
    case DeceasedTenancyPartyCapacityEnum.Beneficiary:
    case DeceasedTenancyPartyCapacityEnum.Devisee:
    case DeceasedTenancyPartyCapacityEnum.NextOfKin:
      evidences = [createDefaultEvidence(EvidenceTypeEnum.Probate, defaultEvidenceDate, defaultEvidenceDocumentReference)];
      break;
    default:
      evidences = [createDefaultEvidence()];
      break;
  }

  return evidences;
}

const createDefaultEvidence = (type: EvidenceTypeEnum | null = null, date: string | Date | null = null, documentReference: string = ''): EvidenceModel => ({
  type: type,
  date: date,
  documentReference: documentReference,
  defaultAdded: true,
  jurisdiction: null
});
