import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps {}
type Props = OwnProps & WithStyles<ClassKeys>;

class MessengerActionBox extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, children } = this.props;
    return (
      <FlexLayout alignItems="flex-end" className={classes.container}>
        {children}
      </FlexLayout>
    );
  }
}
export default withStyles(styles)(MessengerActionBox);
