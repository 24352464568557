import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      padding: '15px 20px 0 20px'
    },
    caveatStatementList: {
      paddingLeft: 23,
      margin: 0
    },
    caveatStatementListItem: {
      display: 'list-item',
      lineHeight: '18px',
      paddingLeft: 3,
      fontSize: 14,
      '& + &': {
        paddingTop: 10
      }
    }
  }),
  {
    name: 'CaveatStatement'
  }
);
