type SelectOptions = Array<{ id: number; name: string }>;

export enum DateTimePickerEnum {
  SingleDay = 0,
  DateRange = 1,
  AllDates = 2
}

export const dateTimePickerTabOptions: SelectOptions = [
  { id: DateTimePickerEnum.SingleDay, name: 'Single day' },
  { id: DateTimePickerEnum.DateRange, name: 'Date range' },
  { id: DateTimePickerEnum.AllDates, name: 'All dates' }
];

export const DATE_PICKER_MIN_DATE = new Date('2000-01-01T00:00:00.000');
// https://github.com/mui/mui-x/issues/7072#issuecomment-1336947421
export const DATE_PICKER_MAX_DATE = new Date('2099-12-31T23:59:59.999');
