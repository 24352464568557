import * as React from 'react';

import { useField, useFormikContext } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { modelKey } from 'src/utils/formUtils';
import { APPLICANT_CAPACITY_LOOKUP_OPTIONS, ApplicantCapacityEnum } from '../../enums';
import { updateDeceasedProprietorGroupsWithPartyCapacity } from '../../helpers';
import { DeceasedProprietorGroupModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  className?: string;
}
const fieldName = modelKey<TransmissionApplicationWithoutDuty2_21_1Model>();

function SectionApplicantCapacity({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<TransmissionApplicationWithoutDuty2_21_1Model>();
  const [, { value: items }] = useField<DeceasedProprietorGroupModel[]>(fieldName('deceasedProprietorGroups'));
  const classes = useStyles();

  const handleSelectApplicantCapacityChange = (resolvedValue: ApplicantCapacityEnum) => {
    const { setFieldValue } = formikProps;
    const havePartySelected = items.length > 0 && items.some(group => group.deceasedProprietors.every(x => x.isAffectedProprietor));

    if (havePartySelected) {
      const updatedItems = updateDeceasedProprietorGroupsWithPartyCapacity(_cloneDeep(items), resolvedValue);
      setFieldValue(fieldName('deceasedProprietorGroups'), updatedItems);
    }
  };
  return (
    <div data-testid={name} className={className}>
      <FormGroup //
        title="Applicant capacity"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={name}
            label={'Party capacity'}
            component={SelectField}
            disabled={disabled}
            format="number"
            options={APPLICANT_CAPACITY_LOOKUP_OPTIONS}
            placeholder={resolveSelectPlaceholder(true)}
            onChange={(_, resolvedValue: ApplicantCapacityEnum) => {
              handleSelectApplicantCapacityChange(resolvedValue);
            }}
            className={classes.fieldWidth}
          />
        )}
      </FormGroup>
    </div>
  );
}

export default React.memo(SectionApplicantCapacity);
