import React from 'react';

import classNames from 'classnames';

interface CardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  //
}

export function Card({ children, className, ...rest }: React.PropsWithChildren<CardProps>) {
  return (
    <div className={classNames('sympli-shadow-default rounded-[11px] bg-[var(--neutral-000)]', className)} {...rest}>
      {children}
    </div>
  );
}
