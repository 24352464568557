import { QLD_PARTY_FORM_CONFIG as QLD_MORTGAGE_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-23/config';
import {
  appendShareQuantitiesToReceivingPartyGroups,
  convertShareQuantitiesToFractions
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving/components/transferee-group-array';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { fallbackMap } from './fallback';
import { getMortgagors, getSelectedTitleReferences } from './helper';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE } from './isRequiredChecks';
import { ApiMortgage2_23_1Model, Mortgage2_23_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_MORTGAGE_PARTY_FORM_CONFIG
});

function convertFromApiToFormModel(apiModel: ApiMortgage2_23_1Model, contextx: IConverterContext): Mortgage2_23_1Model {
  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.titleReferences
    .map(t => t.mortgagors!)
    .map(mortgagor => mortgagor.map(party => party.partyBookId))
    .flat();
  let formModel: Mortgage2_23_1Model = {
    ...apiModel,
    mortgagors: getMortgagors(getSelectedTitleReferences(apiModel.titleReferences)),
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds),
    mortgageeDetails: {
      ...apiModel.mortgageeDetails,
      receivingProprietorGroups: appendShareQuantitiesToReceivingPartyGroups(apiModel.mortgageeDetails.receivingProprietorGroups)
    }
  };
  formModel = applyDefaultMap(formModel, fallbackMap);

  // WEB-18683: The term number was not filled with the default one.
  // The following logic will check if the standard term dealing number is required or not.
  // If the user has provided one, or it provided the additional term number or uploaded documents, the standard term dealing number is not required.
  // It will fill the standard dealing term number with the default one in the user's setting only when it is empty and required.
  // The empty check is actually a safe guard just in case the require check failed to check the emptiness and overwrite the value already filled by the user.
  const termsAndConditions = formModel.termsAndConditions;
  const firstStandardTermsDealingNumber = termsAndConditions.standardTermsDocumentReferences[0];
  const originalTermNumber = firstStandardTermsDealingNumber.documentReference;
  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(firstStandardTermsDealingNumber, termsAndConditions);

  if (isRequired && !originalTermNumber) {
    firstStandardTermsDealingNumber.documentReference = termsAndConditions.preRegisteredStandardTermsDocumentReferences?.[0] || originalTermNumber;
  }
  return formModel;
}

function convertFromFormToApiModel(formModel: Mortgage2_23_1Model, originalApiModel: ApiMortgage2_23_1Model): ApiMortgage2_23_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const { partyBook: partyBookFromForm, ...rest } = formModel;

  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []) as any, // TODO resolve this typing
    mortgageeDetails: {
      ...formModel.mortgageeDetails,
      receivingProprietorGroups: convertShareQuantitiesToFractions(formModel.mortgageeDetails.receivingProprietorGroups)
    }
  };
}

class Converter implements IConverter<Mortgage2_23_1Model, ApiMortgage2_23_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
