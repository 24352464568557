import { Action, createReducer } from 'typesafe-actions';

import { actionPushErrorToQueue, actionRemoveFirstGlobalError, actionRemoveGlobalErrorById, ErrorMessageQueueItemModel } from 'src/actions/globalErrors';

export interface GlobalErrorsState {
  queue: Array<ErrorMessageQueueItemModel>;
  snackQueue: Array<ErrorMessageQueueItemModel>;
}

function deduplicate(acc: Map<string, ErrorMessageQueueItemModel>, item: ErrorMessageQueueItemModel) {
  const { response, title, message } = item;

  const key = `${response?.status}_${title}_${message}`;
  if (!acc.has(key)) {
    acc.set(key, item);
  }
  return acc;
}

const initialState: GlobalErrorsState = {
  queue: [],
  snackQueue: []
};

const reducer = createReducer<
  //
  GlobalErrorsState,
  Action
>(initialState)
  .handleAction(actionPushErrorToQueue, (state, action): GlobalErrorsState => {
    // for better UX, group duplicated errors so the user does not see same dialog multiple times.
    const stateQueue = action.payload.snackbar ? state.snackQueue : state.queue;
    const updated = stateQueue.concat(action.payload).reduce(deduplicate, new Map<string, ErrorMessageQueueItemModel>());

    if (stateQueue.length === updated.size) {
      return state;
    }

    return {
      ...state,
      ...{ [action.payload.snackbar ? 'snackQueue' : 'queue']: Array.from(updated.values()) }
    };
  })
  .handleAction(actionRemoveFirstGlobalError, (state): GlobalErrorsState => {
    return {
      ...state,
      queue: state.queue.slice(1)
    };
  })
  .handleAction(actionRemoveGlobalErrorById, (state, action): GlobalErrorsState => {
    const { id, snackbar } = action.payload;
    const stateQueue = snackbar ? state.snackQueue : state.queue;

    return {
      ...state,
      ...{ [snackbar ? 'snackQueue' : 'queue']: stateQueue.filter(item => item.id !== id) }
    };
  });

export default reducer;
