import * as React from 'react';

import { getIn, useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import FormHelperText from '@mui/material/FormHelperText';

import { WaAddressSelectField_2_26 as AddressSelectField } from '@sympli-mfe/document-components/address-field/wa/2-26';
import {
  WA_ADDRESS_FORM_CONFIG as WA_DM_ADDRESS_FORM_CONFIG,
  WA_ADDRESS_FORM_INITIAL_VALUES as WA_DM_ADDRESS_FORM_INITIAL_VALUES
} from '@sympli-mfe/document-components/address-field/wa/2-26/config';
import { WaPartyForm_2_26 as PartyForm } from '@sympli-mfe/document-components/party-form/wa/2-26';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import AddressChangeWrapperForm from '@sympli-mfe/document-forms-framework/components/party-form/address-change';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import Subform from '@sympli/ui-framework/components/formik/subform';

import PartyCapacity from 'src/containers/documents/scaffolded-form/wa/2-26/components/party-capacity';
import { TenancyPartyModel } from '../../../../models';
import { VISIBILITY_CHECK_ADDRESS } from '../../../../visibilityChecks';
import { WA_DM_PARTY_FORM_CONFIG, WA_DM_PARTY_FORM_FOR_MORTGAGEE_CONFIG } from '../../config';
import { DischargeOfMortgageDocument2_26_0Model } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  disabled: boolean;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function Mortgagee({ name, disabled, className, focusRef }: Props): JSX.Element {
  const formikProps = useFormikContext<DischargeOfMortgageDocument2_26_0Model>();
  const { values, errors } = formikProps;

  const { dialogPortalId } = useDocumentContext();
  const fieldName = createModelKeyAppender<TenancyPartyModel>(name);

  const addressBookOptions = useAddressBookOptions(values.addressBook, WA_DM_ADDRESS_FORM_CONFIG);
  const value: TenancyPartyModel = _get(values, name);

  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  const addressWithFormat = addressBookOptions?.find(address => address.id === value.addressBookId)?.name;

  const { readonlyMap } = partyBook[bookIndexOfPartyId];
  const classes = useStyles();

  // Have to do this so we can ignore top level error. We're only interested in individual error
  const topLevelName = name.substring(0, name.lastIndexOf('['));
  const error = Array.isArray(getIn(errors, topLevelName)) ? getIn(errors, name) : null;

  const renderError: JSX.Element | null = React.useMemo(() => {
    if (typeof error === 'string') {
      return (
        <FormHelperText role="alert" className={classes.errorMessage} error data-error-name={name}>
          {error}
        </FormHelperText>
      );
    }

    return null;
  }, [error, classes, name]);

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={WA_DM_PARTY_FORM_FOR_MORTGAGEE_CONFIG}
        focusRef={focusRef}
      />
      <PartyCapacity name={name} formikProps={formikProps} isReadonly />
      {readonlyMap?.deliveryAddress && addressWithFormat && (
        <AddressChangeWrapperForm //
          subformBindingPath={partyBindingPath}
          disabled={disabled}
          config={WA_DM_PARTY_FORM_CONFIG}
          dialogPortalId={dialogPortalId}
          addressFormConfig={WA_DM_ADDRESS_FORM_CONFIG}
          initialValuesForNew={WA_DM_ADDRESS_FORM_INITIAL_VALUES}
          optionsOverride={addressBookOptions}
          currentAddressWithFormat={addressWithFormat}
          currentAddressBookId={value.addressBookId!}
        />
      )}
      {!readonlyMap?.deliveryAddress && VISIBILITY_CHECK_ADDRESS(partyBook[bookIndexOfPartyId]) && (
        <div data-testid={fieldName('addressBookId')}>
          <AddressSelectField //
            bookRef="addressBook"
            dialogPortalId={dialogPortalId}
            disabled={disabled}
            name={fieldName('addressBookId')}
            optionsOverride={addressBookOptions}
          />
        </div>
      )}
      {renderError}
    </div>
  );
}
export default React.memo(Mortgagee);
