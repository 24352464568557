import { ReportTypeEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { resolveReportLink } from 'src/containers/dashboard/reports/helper';
import { ReportFeatureTypeEnum } from 'src/containers/dashboard/reports/models';

export function isActiveNav(
  category: '' | 'new-ws' | 'ws' | 'standalone' | 'financial-workspaces' | 'tasks' | 'settlements' | 'invitations' | 'reports' | 'reports-home' | 'reports-page',
  args: {
    //
    pathname: string;
    workspaceTypeId?: WorkspaceTypeEnum;
    reportTypeId?: ReportTypeEnum;
  }
) {
  const {
    //
    pathname,
    workspaceTypeId,
    reportTypeId
  } = args;

  switch (category) {
    case 'ws':
      return (['standalone', 'financial-workspaces'] as const).some(c => isActiveNav(c, args));
    case 'new-ws':
      return (['', 'financial-workspaces'] as const).some(c => isActiveNav(c, args));
    case '':
      return pathname === '/' || (pathname.startsWith('/workspace') && workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly);
    case 'standalone':
      return pathname.startsWith('/standalone') || (pathname.startsWith('/workspace') && workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly);
    case 'financial-workspaces':
      return pathname.startsWith('/financial-workspaces') || (pathname.startsWith('/workspace') && workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly);
    case 'settlements':
      return pathname.startsWith('/settlements');
    case 'invitations':
      return pathname.startsWith('/invitations');
    case 'tasks':
      return pathname === '/';
    case 'reports':
      return pathname.startsWith('/reports');
    case 'reports-home':
      return pathname === '/reports';
    case 'reports-page':
      if (Number.isInteger(reportTypeId)) {
        return pathname === resolveReportLink(ReportFeatureTypeEnum.Favourite, reportTypeId!);
      }
      return false;
    default:
      return false;
  }
}
