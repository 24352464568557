import React from 'react';

import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { UserAvatar } from 'src/components/avatars';
import { WorkspaceParticipantMetaData } from '../../helper';
import { WorkspaceActivityLogModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  item: WorkspaceActivityLogModel;
}

function ActivityLogItemParticipant({ item }: Props) {
  const classes = useStyles();

  const { dataObject, participant } = item;

  if (!participant) {
    return null;
  }

  if (dataObject && typeof dataObject === 'object') {
    const workspaceParticipant = dataObject as WorkspaceParticipantMetaData;
    const workspaceRole = participant.workspaceRole.name ?? '';
    const src = participant.avatarUrl ?? '';
    return (
      <FlexLayout alignItems="center" justifyContent="flex-end" className={classes.participantInfo}>
        <Typography className={classes.activityParticipant}>
          {workspaceRole} {workspaceParticipant.UserName && <span> ({workspaceParticipant.UserName})</span>}
        </Typography>
        <UserAvatar src={src} size="extraSmall" item={participant} />
      </FlexLayout>
    );
  } else {
    return null;
  }
}

export default React.memo(ActivityLogItemParticipant);
