import { DocumentActionTypeEnum, DocumentDenyPermissionReasonCodeEnum, DocumentPermissionEnum, DocumentStatusEnum, DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentDetailApiResponse, WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';

import { documentWorkflowStepPermissionMapper } from 'src/containers/documents/helpers';
import {
  DocumentDenyPermissionModel,
  DocumentPrerequisite,
  DocumentWorkflowStepsEnum,
  DocumentWorkflowStepsEnumMap,
  PanelDetails,
  PanelVariantEnum
} from 'src/containers/documents/models';
import { getDocumentsReadyToSign } from 'src/containers/documents/selectors';
import { FSS_DOCUMENT_TYPE } from 'src/containers/workspace/financial/directions/helpers';

export interface DocumentResponseBase {
  status: WorkspaceDocumentDetailApiResponse['status'];
  documentDenyPermissions: WorkspaceDocumentDetailApiResponse['documentDenyPermissions'];
  isFullySignedByAllParticipants: WorkspaceDocumentDetailApiResponse['isFullySignedByAllParticipants'];
  documentActions: WorkspaceDocumentDetailApiResponse['documentActions'];
  lodgementCaseId?: WorkspaceDocumentDetailApiResponse['lodgementCaseId']; // this component is shared by financial, so make it nullable
}

export function getPanelDetails<T extends DocumentResponseBase>(
  detail: T,
  currentWorkflowStep: DocumentWorkflowStepsEnum,
  documentType: string,
  documentId: string,
  userId: string,
  participantId: string,
  workspaceDocuments: WorkspaceDocumentSummaryApiResponse[] = [],
  documentPrerequisite: DocumentPrerequisite | undefined = undefined
): PanelDetails {
  const documentStatusId: DocumentStatusEnum = detail.status;

  if (
    // we need to know what is current workflow step in order to continue
    !Number.isInteger(currentWorkflowStep) ||
    // it does not makes sense to continue further if we don't have document detail loaded because everything depends on it.
    !Number.isInteger(documentStatusId)
  ) {
    return emptyPanelDetails;
  }

  // we don't want to display any notifications in lodgement verification unless all participants have signed the document
  if (documentStatusId === DocumentStatusEnum.LodgementVerificationInProgress && !detail.isFullySignedByAllParticipants) {
    return emptyPanelDetails;
  }

  let primaryMessage: string | undefined;
  let secondaryMessage: string | undefined;

  // document Signed status is the highest priority
  // isFullySignedByAllParticipants has the highest priority and remains true after all participants sign the document
  if (documentStatusId === DocumentStatusEnum.Signed || detail.isFullySignedByAllParticipants) {
    return {
      primaryMessage: 'All done!',
      secondaryMessage: documentType === FSS_DOCUMENT_TYPE ? `This ${documentType} has been signed.` : `This ${documentType} is signed.`,
      panelVariant: PanelVariantEnum.Success,
      priority: 0
    };
  }

  const denyPermissionMap: Partial<Record<keyof typeof DocumentPermissionEnum, DocumentDenyPermissionModel>> = detail.documentDenyPermissions.reduce((table, currentValue) => {
    table[currentValue.documentPermissionType] = {
      ...currentValue
    };
    return table;
  }, {});

  const documentPermission: DocumentPermissionEnum = documentWorkflowStepPermissionMapper[currentWorkflowStep];
  const deniedPermission: DocumentDenyPermissionModel | undefined = denyPermissionMap[documentPermission!];
  let warningPriority = 1;

  if (!deniedPermission) {
    if (documentPrerequisite?.isPrerequisiteNotReady === true) {
      return getDocumentPrerequisitePanelDetails(documentPrerequisite);
    }

    return emptyPanelDetails;
  }

  switch (deniedPermission!.reasonCode.id) {
    case DocumentDenyPermissionReasonCodeEnum.DPD001_MissingWriteDocumentPermission:
      primaryMessage = 'You do not have permission to edit or save documents in this jurisdiction.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD002_MissingReviewDocumentPermission:
      primaryMessage = 'You do not have permission to approve documents in this jurisdiction.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD003_MissingSignDocumentPermission:
      primaryMessage = 'You do not have permission to sign documents in this jurisdiction.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD010_MissingWriteFinancialsPermission:
      primaryMessage = 'You do not have permission to edit or save financials.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD011_MissingReviewFinancialsPermission:
      primaryMessage = 'You do not have permission to approve financials.';
      break;
    // Returning generic error message on purpose because the document notification is aggregated for both payments and source funds sections at the moment
    case DocumentDenyPermissionReasonCodeEnum.DPD012_MissingSignFinancialsPermission:
    case DocumentDenyPermissionReasonCodeEnum.DPD051_MissingSignPaymentsPermission:
    case DocumentDenyPermissionReasonCodeEnum.DPD052_MissingSignSourceFundsPermission:
    case DocumentDenyPermissionReasonCodeEnum.DPD053_TotalAmountOverUserSigningLimit:
      primaryMessage = 'You do not have permission to sign financials.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD025_OnlyAllowResponsibleParticipantReviewLodgementInstructions:
      primaryMessage = 'Only the responsible subscriber can approve this document.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD026_OnlyAllowResponsibleParticipantSignLodgementInstructions:
      primaryMessage = 'Only the responsible subscriber can sign this document.';
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD033_NotResponsibleParticipantOfTheDocument:
      if (
        DocumentWorkflowStepsEnumMap.has(currentWorkflowStep) &&
        workspaceDocuments.find(wd => wd.documentId === documentId)?.documentIdentifier.id === DocumentTypeIdentifierEnum.LodgementInstructions
      ) {
        primaryMessage = `Only the responsible subscriber can ${DocumentWorkflowStepsEnumMap.get(currentWorkflowStep)!.toLowerCase()} this document.`;
      }
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD034_SeparateSigner:
      primaryMessage = 'A separate signer is required.';
      secondaryMessage = `You have approved this ${documentType}, so you cannot sign.`;
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD035_SeparateSaver:
      primaryMessage = 'A separate approver is required.';
      secondaryMessage = `You have edited and saved this ${documentType}, so you cannot approve.`;
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD036_SeparateApproverAndSigner:
      const { status, documentActions } = detail;
      if (status === DocumentStatusEnum.Reviewing) {
        primaryMessage = 'A separate approver is required.';
        secondaryMessage = `You have edited and saved this ${documentType}, so you cannot approve.`;
      } else if (status === DocumentStatusEnum.Signing) {
        // check if user has already reviewed this document
        const userHasAlreadyReviewedForThisParticipant: boolean = documentActions!.some(
          da => da.type === DocumentActionTypeEnum.Review && da.userId === userId && da.participantId === participantId
        );
        primaryMessage = 'A separate signer is required.';
        secondaryMessage = userHasAlreadyReviewedForThisParticipant
          ? `You have approved this ${documentType}, so you cannot sign.`
          : `You have edited, saved and/or approved this ${documentType}, so you cannot sign.`;
      }
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD039_CurrentUserAlreadySigned:
      primaryMessage = `You are one of the signers on this ${documentType}. You cannot sign again.`;
      warningPriority = 2;
      break;
    case DocumentDenyPermissionReasonCodeEnum.DPD040_OtherParticipantMustReviewFirst: {
      // get all documents that can be signed now
      const documentsToSignIncludingSupporting: WorkspaceDocumentSummaryApiResponse[] = getDocumentsReadyToSign(workspaceDocuments, detail.lodgementCaseId).reduce(
        (acc: WorkspaceDocumentSummaryApiResponse[], document: WorkspaceDocumentSummaryApiResponse) => {
          acc.push(document, ...(document.supportingDocuments || []));
          return acc;
        },
        []
      );

      // find currently viewed document
      let document: WorkspaceDocumentSummaryApiResponse | undefined = documentsToSignIncludingSupporting.find(sd => sd.documentId === documentId);

      // check if there is another participant that did not finish editing yet.
      const awaitingParticipant = document?.documentParticipants.find(p => p.id !== participantId && p.participantStatus.id < DocumentStatusEnum.Reviewing);
      if (awaitingParticipant) {
        const workspaceRoleName = awaitingParticipant.workspaceRole.name.toLocaleLowerCase();
        const intendedAction = (DocumentPermissionEnum[deniedPermission.documentPermissionType] || 'sign').toLocaleLowerCase();
        primaryMessage = `Awaiting ${workspaceRoleName}.`;
        secondaryMessage = `The ${workspaceRoleName} must approve this ${documentType} before you can ${intendedAction}.`;
      }
      break;
    }
    case DocumentDenyPermissionReasonCodeEnum.DPD046_LodgementInstructionsPendingParticipantsAcceptingInvites: {
      primaryMessage = 'Awaiting participant';
      secondaryMessage = 'All participants invited must accept invitation before you can sign.';
      break;
    }
    case DocumentDenyPermissionReasonCodeEnum.DPD047_LodgementVerificationFaultMustNotBeSigned: {
      primaryMessage = 'Awaiting issue resolution. All documents must be verified before you can sign.';
      break;
    }
    case DocumentDenyPermissionReasonCodeEnum.DPD050_MissingWriteAndReviewDocumentPermissionsForSaveAndApproveWorkflow: {
      primaryMessage = 'You do not have permission to Save and approve documents in this jurisdiction.';
      break;
    }
    default:
      break;
  }

  if (!primaryMessage) {
    return emptyPanelDetails;
  }

  return { primaryMessage: primaryMessage, secondaryMessage, panelVariant: PanelVariantEnum.Warning, priority: warningPriority };
}

const getDocumentPrerequisitePanelDetails = (documentPrerequisite: DocumentPrerequisite): PanelDetails => {
  return {
    primaryMessage: documentPrerequisite.prerequisiteError!,
    secondaryMessage: undefined,
    panelVariant: PanelVariantEnum.Warning,
    priority: 1
  };
};

const emptyPanelDetails: PanelDetails = {
  primaryMessage: undefined,
  secondaryMessage: undefined,
  panelVariant: undefined,
  priority: -1
};
