import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import { defaultMemoize } from 'reselect';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { ParticipantStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { NewArrowLeft, NewIconSendMessage } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import MessengerActionBox from 'src/containers/messenger/components/messenger-action-box';
import MessengerHeaderBox from 'src/containers/messenger/components/messenger-header-box';
import { colors } from 'src/theme';
import { modelKey } from 'src/utils/formUtils';
import getConversationSchema from '../../validation/getConversationSchema';
import NoParticipants from './components/no-participants';
import { ConversationDetailFormModel, CreateConversationDocumentPayload, NewMessageStepEnum } from './models';
import styles, { ClassKeys } from './styles101';

interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  // workspace participants
  participants: WorkspaceParticipantApiResponse[];
  // other
  onConversationCreated: (args: {
    //
    conversationId: string;
    recipientParticipantIds: string[];
    subject: string;
    message: string;
  }) => void;

  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  step: NewMessageStepEnum;
  selected: string[];
}
const fieldName = modelKey<ConversationDetailFormModel>();

class NewMessage101 extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    step: NewMessageStepEnum.SelectParticipant,
    selected: []
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { participants, participantId } = nextProps;
    const filteredParticipants = participants.filter(item => item.participantStatus.id === ParticipantStatusEnum.Accepted && item.id !== participantId);

    // If has only one other participant, select it as default
    if (filteredParticipants.length === 1) {
      return {
        ...prevState,
        selected: [filteredParticipants[0].id]
      };
    }

    return {
      ...prevState
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MessengerHeaderBox>
          <FlexLayout alignItems="center" justifyContent="center" className={classes.headerContainer}>
            <IconButton className={classes.backButton} onClick={this.handleOnLeftClick} aria-label="go back to list" size="large">
              <NewArrowLeft />
            </IconButton>
            <Typography variant="subtitle1" className={classes.headerLabel}>
              New message
            </Typography>
          </FlexLayout>
        </MessengerHeaderBox>
        {this.renderCurrentStep()}
      </React.Fragment>
    );
  }

  private renderCurrentStep() {
    const { step } = this.state;
    switch (step) {
      case NewMessageStepEnum.SelectParticipant:
        return this.renderParticipants();
      case NewMessageStepEnum.ConversationDetail:
      default:
        return this.renderConversationDetail();
    }
  }
  private renderParticipants() {
    const { classes, participants, participantId } = this.props;
    const { selected } = this.state;

    const hasAtLeastOneOtherParticipant = participants.some(
      x =>
        !x.archivedStatus && // not withdrawn participant
        x.participantStatus.id === ParticipantStatusEnum.Accepted && // has accepted invite
        x.id !== participantId // is not current participant
    );

    return (
      <React.Fragment>
        <Box className={classes.content}>
          {!hasAtLeastOneOtherParticipant ? (
            <NoParticipants />
          ) : (
            <>
              <Box className={classes.participantsList}>
                <Typography variant="body2" className={classes.participantsListTitle}>
                  Participants
                </Typography>
                {participants.map(item => {
                  if (item.participantStatus.id !== ParticipantStatusEnum.Accepted || item.id === participantId) {
                    return null;
                  }

                  return (
                    <ListItem className={classNames(classes.participant)} button disableGutters key={item.id} data-id={item.id} onClick={this.handleOnParticipantClick}>
                      <Checkbox
                        color="primary"
                        classes={{ root: classes.checkbox }}
                        checked={selected?.includes(item.id)}
                        value={item.id}
                        aria-label={`Selected participant ${item.partyNames}`}
                      />
                      <div>
                        <Typography className={classes.participantName}>{item.name}</Typography>
                        <Typography className={classes.participantRole}>{item.workspaceRole.name}</Typography>
                      </div>
                    </ListItem>
                  );
                })}
              </Box>
              <Typography className={classes.additionalDesc}>
                {'Any personal information you send through Messenger will be handled in accordance with our '}
                <a href="https://www.sympli.com.au/policies/" target="_blank" rel="noopener noreferrer" className={classes.privacyLink}>
                  Privacy Policy
                </a>
                {' and retained in accordance with the MOR. Please refrain from sending sensitive information through Messenger unless necessary.'}
              </Typography>
            </>
          )}
        </Box>
        {selected != null && (
          <MessengerActionBox>
            <SympliButton
              disabled={this.state.selected.length === 0}
              className="rounded-[18px] bg-[var(--sympli-green)] px-[16px] py-[8px]"
              color="primary"
              variant="contained"
              fullWidth
              onClick={this.handleOnContinueClick}
              classes={{ root: classes.continueButton }}
            >
              Continue
            </SympliButton>
          </MessengerActionBox>
        )}
      </React.Fragment>
    );
  }

  private getInitialValues = (): ConversationDetailFormModel => {
    return { subject: '', message: '' };
  };

  private renderConversationDetail() {
    const { workspaceId, participantId } = this.props;
    const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/conversations/create`;
    return (
      <Formik //
        method="post"
        action={action}
        getInitialValues={this.getInitialValues}
        validationSchema={getConversationSchema}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
        validateOnMount
      >
        {(formikProps: FormikProps<ConversationDetailFormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private memoizedParticipant = defaultMemoize((selected: string[], participants: WorkspaceParticipantApiResponse[]) =>
    //TODO update this, it will be filter function not find anymore
    participants.find(item => selected.includes(item.id))
  );

  private renderForm(formikProps: FormikProps<ConversationDetailFormModel>) {
    const { isSubmitting, isValid, submitForm } = formikProps;
    const { classes, participants } = this.props;
    const { selected = [] } = this.state;
    const selectedParticipant = this.memoizedParticipant(selected, participants);

    if (selectedParticipant == null) {
      return <Form />;
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        // prevent default behavior (wrap into new line)
        event.preventDefault();
        // submit the form
        submitForm();
      }
      // Else default behaviour
    };

    return (
      <Form className={classes.form}>
        <div className={classes.subjectBox}>
          <Typography variant="body2" className={classes.messageTitle}>
            Message Title
          </Typography>
          {/* // use customized Field to get rid of error text */}
          <Field name={fieldName('subject')}>
            {({ field }) => (
              <Input
                {...field}
                aria-label="Subject"
                className={classes.subjectField}
                classes={{ formControl: classes.subjectMultiline }}
                multiline
                inputProps={{ maxLength: 100, className: classes.multilineInput }}
              />
            )}
          </Field>
        </div>
        <MessengerActionBox>
          {/* // use customized Field to get rid of error text */}
          <Field name={fieldName('message')}>
            {({ field }) => (
              <Input
                {...field}
                aria-label="message"
                placeholder="Type a message"
                margin="none"
                multiline
                className="w-[260px]"
                classes={{ formControl: classes.multiline }}
                inputProps={{ maxLength: 5000, className: classes.multilineInput }}
                onKeyDown={handleKeyPress}
              />
            )}
          </Field>
          <SympliButton
            type="submit"
            color="primary"
            variant="contained"
            className={classNames(classes.submitButton, 'h-[36px] w-[36px] rounded-[18px]')}
            disabled={isSubmitting || !isValid}
            aria-label="Send message"
          >
            {isSubmitting ? <InlineLoader size={18} className={classes.loader} /> : <NewIconSendMessage fill={colors.WHITE} />}
          </SympliButton>
        </MessengerActionBox>
      </Form>
    );
  }
  private handleOnPreSubmit = (values: ConversationDetailFormModel): CreateConversationDocumentPayload => {
    const { selected = [] } = this.state;
    const { subject, message } = values;
    return { subject, message, recipientParticipantIds: selected };
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<ConversationDetailFormModel, string>) => {
    const { workspaceId, participantId } = this.props;
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'messenger-create-new-conversation',
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      subject: args.formValues?.subject,
      message: args.formValues?.message,
      isMultipleConversation: this.state.selected && this.state.selected.length > 1,
      isCreated: !args.error
    });

    if (args.error) {
      // TODO error handling?
      return;
    }

    this.props.onConversationCreated({
      conversationId: args.response,
      recipientParticipantIds: this.state.selected || [],
      ...args.formValues
    });
  };

  private handleOnLeftClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (this.state.step) {
      case NewMessageStepEnum.ConversationDetail:
        this.setState({ step: NewMessageStepEnum.SelectParticipant });
        return;
      case NewMessageStepEnum.SelectParticipant:
      default:
        this.props.onBackClick(event);
    }
  };

  private handleOnContinueClick = () => {
    this.setState({ step: NewMessageStepEnum.ConversationDetail });
  };

  private handleOnParticipantClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const id = dataAttribute('id', event) || '';

    const currentSelected = this.state.selected;
    if (currentSelected.includes(id)) {
      this.setState({ selected: currentSelected.filter(x => x !== id) });
    } else {
      this.setState({ selected: currentSelected.concat([id]) });
    }
  };
}

const styled = withStyles(styles)(NewMessage101);

export default styled;
