import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-23/sa';

import { Mortgage_2_23_1_Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Mortgage_2_23_1_Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        estateType: NO_FALLBACK,
        mortgagors: NO_FALLBACK,
        hasTransfer: NO_FALLBACK
      }
    }
  },
  mortgageeDetails: resolveTenancyFallback({
    partyCapacityMinor: NecdsPartyCapacityEnum.Minor
  }),
  termsAndConditions: {
    $children: {
      standardTermsDealingNumbers: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            termNumber: ['']
          }
        }
      },
      warning: [''],
      preRegisteredStandardTermsDealingNumbers: NO_FALLBACK,
      additionalCovenants: [''],
      supportingDocuments: {
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: {
          $children: {
            id: NO_FALLBACK,
            fileName: [''],
            fileType: [''],
            uploadedByParticipantId: NO_FALLBACK,
            uploadedDateTimeUtc: NO_FALLBACK
          }
        }
      }
    }
  },
  addressBook: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  mortgagors: NO_FALLBACK,
  proprietors: NO_FALLBACK,
  needToGenerateMortgagors: NO_FALLBACK,
  transferees: NO_FALLBACK
};
