import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    content() {
      return {
        width: 'auto'
      };
    }
  }),
  {
    name: 'EditDocument'
  }
);
