import {
  CommonTenancyLabelsEnum,
  RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  ReceivingTenancyComponentLabelConfig
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

export const QLD_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...RECEIVING_TENANCY_COMPONENT_LABEL_CONFIG,
  items: CommonTenancyLabelsEnum.JointTenantsInterse,
  partyCapacity: CommonTenancyLabelsEnum.Capacity
};
