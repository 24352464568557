import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiRequestEctControlFromRegistrarOfTitles_2_25_0_Model, RequestEctControlFromRegistrarOfTitles_2_25_0_Model } from './models';

class Converter implements IConverter<RequestEctControlFromRegistrarOfTitles_2_25_0_Model, ApiRequestEctControlFromRegistrarOfTitles_2_25_0_Model> {
  fromApiToFormModel(apiModel: ApiRequestEctControlFromRegistrarOfTitles_2_25_0_Model, context: IConverterContext): RequestEctControlFromRegistrarOfTitles_2_25_0_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(
    model: RequestEctControlFromRegistrarOfTitles_2_25_0_Model,
    _originalApiModel: ApiRequestEctControlFromRegistrarOfTitles_2_25_0_Model
  ): ApiRequestEctControlFromRegistrarOfTitles_2_25_0_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
