import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginLeft: -18
    },
    rootV2: {
      position: 'relative'
    },
    content: {
      paddingLeft: 18,
      width: 'auto'
    },
    bold: {
      fontWeight: 'bold'
    },

    formGroupTitle: {
      fontWeight: 'bold'
    },
    formSubmitButtonContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },

    smallField: {
      width: 120
    },
    labelMarginRight: {
      marginRight: 4
    },
    buttonMarginRight: {
      marginRight: 20
    },

    timestamp: {
      marginRight: 'auto'
    },
    transactionIdContainer: {
      padding: '0px 20px',
      marginBottom: 36,
      backgroundColor: theme.palette.grey[100]
    },
    transactionIdField: {
      width: 110,
      marginTop: 15,
      marginBottom: 15,
      marginLeft: 4,
      marginRight: 4
    },
    transactionIdPostfixField: {
      width: 46,
      marginTop: 15,
      marginBottom: 15,
      marginLeft: 4,
      marginRight: 4
    }
  });

export type ClassKeys = typeof styles;

export default styles;
