import * as React from 'react';

import classNames from 'classnames';
import dateFormat from 'dateformat';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { ICertInfo, TokenKindEnum } from '@sympli/digital-signing/interfaces';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconUsb } from '@sympli/ui-framework/icons';

import { modelKey } from 'src/utils/formUtils';
import { CertificateSelectionModel, SelectedCertificateModel } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  open?: boolean;
  certificatesToSelect: Array<ICertInfo>;
  onClose(): void;
  onConfirm(value: SelectedCertificateModel): void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  setDefault: boolean;
  selectedCertificateIndex: number;
}

const fieldName = modelKey<CertificateSelectionModel>();

class CertificateSelectionDialog extends React.PureComponent<Props, State> {
  public readonly state: State = this.resolveInitialState();

  private resolveInitialState(): State {
    if (this.props.certificatesToSelect.length === 1) {
      return {
        setDefault: false,
        selectedCertificateIndex: 0
      };
    }

    return {
      setDefault: false,
      selectedCertificateIndex: -1
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open && !prevProps.open) {
      // reset state whenever dialog is open
      this.setState(this.resolveInitialState());
    }
  }

  private handleOnClose = (confirm?: boolean) => {
    const { certificatesToSelect, onConfirm, onClose } = this.props;
    if (confirm) {
      const { selectedCertificateIndex, setDefault } = this.state;
      const selectedTokenType = certificatesToSelect[selectedCertificateIndex].tokenType;
      const value: SelectedCertificateModel = {
        ...certificatesToSelect[selectedCertificateIndex],
        // * Only set default when using soft token
        setDefault: setDefault && selectedTokenType === TokenKindEnum.Soft
      };
      onConfirm(value);
    } else {
      onClose();
    }
  };

  render() {
    const { open, classes, certificatesToSelect } = this.props;
    const { setDefault, selectedCertificateIndex } = this.state;
    const certificateOptions = certificatesToSelect.map((cert, index) => ({
      id: index,
      name: this.renderCertInfo(cert)
    }));
    const selectedCert = certificatesToSelect[selectedCertificateIndex];
    const showSetDefault = selectedCert?.tokenType === TokenKindEnum.Soft;
    const haveNotSelected = !selectedCert;
    return (
      <ConfirmationDialog
        title={
          <FlexLayout as="span" alignItems="center">
            <IconUsb className={classes.titleIcon} aria-hidden="true" />
            Select signing certificate
          </FlexLayout>
        }
        classes={{
          dialogContent: classes.dialogContent
        }}
        onClose={this.handleOnClose}
        disableBackdropClick
        disabled={haveNotSelected}
        open={!!open}
      >
        <form onSubmit={this.handleSubmit}>
          <Typography className={classes.note}>{this.resolveDisclaimer(certificatesToSelect.length)}</Typography>
          <Radio //
            aria-label="Certificate"
            name={fieldName('certificatesToSelect')}
            options={certificateOptions}
            format="number"
            value={selectedCertificateIndex}
            onChange={this.handleSelectionChange}
            classes={{
              formControlLabel: classes.radioFormLabel,
              formControlLabelRoot: classes.formControlLabelRoot
            }}
            fullWidth
          />
          {showSetDefault && (
            <Checkbox label="Always sign with this certificate" name={fieldName('disableTip')} checked={setDefault} onChange={this.handleCheckboxChange} format="boolean" />
          )}
        </form>
      </ConfirmationDialog>
    );
  }

  private resolveDisclaimer(numberOfCerts: number): string {
    if (numberOfCerts === 1) {
      return 'Please select the certificate for signing documents in Sympli.';
    }

    return 'You have more than 1 digital certificate installed on this computer. Please select the certificate for signing documents in Sympli.';
  }

  private renderCertInfo(cert: ICertInfo) {
    const { classes } = this.props;

    return (
      <div className={classes.certInfoContainer}>
        <div className={classes.textBold}>{cert.subject.name}</div>
        <div>{cert.subject.companyName}</div>
        <div className={classes.textLight}>
          {cert.subject.email} <span className={classes.textBold}>({this.resolveTokenType(cert.tokenType)})</span>
        </div>
        <div className={classNames(classes.textLight, classes.certProvider)}>{`${cert.issuer.name} | Expires on ${dateFormat(cert.notAfter, 'dd mmm yyyy')}`}</div>
      </div>
    );
  }

  private resolveTokenType(tokenType: TokenKindEnum) {
    switch (tokenType) {
      case TokenKindEnum.Hard: {
        return 'USB';
      }
      case TokenKindEnum.Soft: {
        return 'System';
      }
      default: {
        return 'Unknown';
      }
    }
  }

  private handleSelectionChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: number) => {
    const { selectedCertificateIndex } = this.state;
    if (resolvedValue !== selectedCertificateIndex) {
      this.setState({
        selectedCertificateIndex: resolvedValue,
        // explicitly set default to false
        setDefault: false
      });
    }
  };

  private handleCheckboxChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: boolean) => {
    const { setDefault } = this.state;
    if (resolvedValue !== setDefault) {
      this.setState({ setDefault: resolvedValue });
    }
  };

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
}

export default withStyles(styles)(CertificateSelectionDialog);
