import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspacePreferences } from '../actions/workspacePreferences';
import { WorkspacePreferencesApiResponse } from '../models';

export interface WorkspacePreferencesState {
  detail?: WorkspacePreferencesApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: WorkspacePreferencesState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  WorkspacePreferencesState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspacePreferences.request, (state): WorkspacePreferencesState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchWorkspacePreferences.success, (state, action): WorkspacePreferencesState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchWorkspacePreferences.failure, (state, action): WorkspacePreferencesState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
