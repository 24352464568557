import dateFormat from 'dateformat';
import _isEqual from 'lodash-es/isEqual';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { formatPartyName, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { asDecimal } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { convertPartiesToFormModel } from '../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG2 } from './config';
import { ApplicantCapacityEnum, DateOfDeathTypeEnum, EvidenceTypeValueEnum } from './enums';
import { DeceasedProprietorGroupModel, DeceasedProprietorModel, EvidenceModel, TitleReferenceModel, TransmissionApplicationWithoutDuty2_21_1Model } from './models';

const allEqual = <T>(array: T[]) => new Set(array).size === 1;

const EMPTY_TENANCY: DeceasedProprietorGroupModel[] = [{ deceasedProprietors: [], tenancyType: undefined, lastDeceasedValidation: undefined }];

export const filterDeceasedProprietorGroups = (titleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]): DeceasedProprietorGroupModel[] => {
  const selectedTitleReferences = titleReferences.filter(r => r.isSelected);
  return combineDeceasedProprietorGroupsFromSelectedTitleReferences(selectedTitleReferences, partyBook);
};

export const filterDeceasedProprietors = (groups: DeceasedProprietorGroupModel[]): DeceasedProprietorModel[] => {
  const proprietors = groups
    .flatMap(group => group.deceasedProprietors)
    .reduce((groupArray: DeceasedProprietorModel[], group: DeceasedProprietorModel) => {
      groupArray.push(group);
      return groupArray;
    }, []);
  return proprietors;
};

export function getPartyNameAndPartyTypes(partyBookIds: string[], partyBook: PartyModel<NswNameChange>[]): string[] {
  return partyBookIds.map(partyBookId => {
    const party = partyBook.find(x => x.id === partyBookId)!;
    const { receivingOrRelinquishingDetails, partyType } = party;
    // default to party legalEntityName - if has nameChange, use new name from nameChange
    let legalEntityName = party.legalEntityName!;
    if (receivingOrRelinquishingDetails.isRelinquishing && receivingOrRelinquishingDetails.isChangingName && receivingOrRelinquishingDetails.nameChange) {
      const { organisationName, firstName, lastName } = receivingOrRelinquishingDetails.nameChange;
      legalEntityName = partyType === PartyTypeEnum.Organisation ? organisationName : [firstName, lastName].filter(Boolean).join(' ');
    }
    return `${legalEntityName.trim().toUpperCase()} - ${getPartyType(party)}`;
  });
}

export function combineDeceasedProprietorGroupsFromSelectedTitleReferences(
  selectedTitleReferences: TitleReferenceModel[],
  partyBook: PartyModel<NswNameChange>[]
): DeceasedProprietorGroupModel[] {
  if (selectedTitleReferences.length === 0) {
    return EMPTY_TENANCY;
  }

  if (selectedTitleReferences.length === 1) {
    const { deceasedProprietorGroups } = { ...selectedTitleReferences[0] };
    const convertedGroups = deceasedProprietorGroups.map(group => {
      return {
        ...group,
        deceasedProprietors: group.deceasedProprietors.map(proprietor => {
          const newProprietor: DeceasedProprietorModel = {
            ...proprietor,
            deceasedProprietorId: resolveUuid('string', 20)
          };
          return newProprietor;
        })
      };
    });
    return setSelectedProprietorGroups(convertedGroups);
  }
  const mergedProprietorGroups = mergeSelectedTitlesProprietorGroup(selectedTitleReferences, partyBook);

  return setSelectedProprietorGroups(mergedProprietorGroups);
}

const setSelectedProprietorGroups = (proprietorGroups: DeceasedProprietorGroupModel[]): DeceasedProprietorGroupModel[] => {
  const updatedVisibleGroups = setVisibleEvidences(proprietorGroups);
  if (updatedVisibleGroups.length === 1) {
    return updatedVisibleGroups.map(group => {
      return {
        ...group,
        deceasedProprietors: group.deceasedProprietors.map(proprietor => {
          return {
            ...proprietor,
            isAffectedProprietor: true,
            isLastDeceased: group.deceasedProprietors.length === 1 || proprietor.isLastDeceased
          };
        })
      };
    });
  }

  return updatedVisibleGroups;
};

export const setVisibleEvidences = (proprietorGroups: DeceasedProprietorGroupModel[]): DeceasedProprietorGroupModel[] => {
  return proprietorGroups.map(group => {
    const isVisibleEvidence = group.deceasedProprietors.some(x => x.isLastDeceased) || (proprietorGroups.length === 1 && proprietorGroups[0].deceasedProprietors.length === 1);
    return {
      ...group,
      deceasedProprietors: group.deceasedProprietors.map(proprietor => {
        return {
          ...proprietor,
          isEvidenceVisible: isVisibleEvidence
        };
      })
    };
  });
};

export const titlesHasMatchingTenancyType = (selectedTitleReferences: TitleReferenceModel[]) => {
  return allEqual(
    selectedTitleReferences.flatMap(({ deceasedProprietorGroups }) =>
      deceasedProprietorGroups.map(({ tenancyType }) => {
        return tenancyType!;
      })
    )
  );
};

export const titlesHasSameNumberOfProprietorGroups = (selectedTitleReferences: TitleReferenceModel[]) =>
  allEqual(
    selectedTitleReferences.flatMap(({ deceasedProprietorGroups }) => {
      return deceasedProprietorGroups.length;
    })
  );

export const titlesHasMatchingFraction = (selectedTitleReferences: TitleReferenceModel[]): boolean => {
  const proprietorGroupsByTitleReference = selectedTitleReferences.map(({ deceasedProprietorGroups }) => deceasedProprietorGroups);
  const proprietorGroupShareFractionsByTitleReference = proprietorGroupsByTitleReference.map(deceasedProprietor =>
    deceasedProprietor
      .map(g => ({
        previouslyHeldShareFraction: asDecimal(g.previouslyHeldShareFraction!)
      }))
      .sort((a, b) => a.previouslyHeldShareFraction - b.previouslyHeldShareFraction!)
  );
  const [firstTitleGroups, ...restTitleGroups] = proprietorGroupShareFractionsByTitleReference;
  return restTitleGroups.every(titleGroups => _isEqual(titleGroups, firstTitleGroups));
};

export const titlesHasMatchingPartyNameAndPartyType = (selectedTitleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]) => {
  const proprietorGroupsByTitleReference = selectedTitleReferences.map(({ deceasedProprietorGroups }) => deceasedProprietorGroups);
  // Check parties names by group, must the same across all titles
  const proprietorGroupPartyNamesByTitleReference = proprietorGroupsByTitleReference.map(deceasedProprietor =>
    deceasedProprietor
      .sort((a, b) => asDecimal(a.previouslyHeldShareFraction!) - asDecimal(b.previouslyHeldShareFraction!))
      .map(g => ({
        partyNames: getPartyNameAndPartyTypes(
          g.deceasedProprietors!.map(p => p.partyBookId!),
          partyBook
        )
          //In order to consolidate groups, we need to reorder the list before consolidating, to avoid the error that [1, 2] cannot be consolidated with [2, 1]
          .sort((a, b) => a.localeCompare(b))
      }))
  );
  const [firstTitleGroups, ...restTitleGroups] = proprietorGroupPartyNamesByTitleReference;
  return restTitleGroups.every(titleGroups => _isEqual(titleGroups, firstTitleGroups));
};

export const titlesHaveMatchingPartyTypes = (selectedTitleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]) => {
  const proprietorGroupsByTitleReference = selectedTitleReferences.map(({ deceasedProprietorGroups }) => deceasedProprietorGroups);
  const proprietorGroupPartyTypesByTitleReference = proprietorGroupsByTitleReference.map(deceasedProprietor =>
    deceasedProprietor
      .map(g => ({
        partyTypesHash: getPartyTypesHash(
          g.deceasedProprietors!.map(p => p.partyBookId!),
          partyBook
        ),
        previouslyHeldShareFraction: asDecimal(g.previouslyHeldShareFraction!)
      }))
      .sort((a, b) => a.previouslyHeldShareFraction - b.previouslyHeldShareFraction)
      .map(g => g.partyTypesHash)
  );
  const [firstTitleGroups, ...restTitleGroups] = proprietorGroupPartyTypesByTitleReference;
  return restTitleGroups.every(titleGroups => _isEqual(titleGroups, firstTitleGroups));
};

export function getPartyTypesHash(partyBookIds: string[], partyBook: PartyModel<NswNameChange>[]): string {
  return partyBookIds
    .map(partyBookId => {
      const party = partyBook.find(x => x.id === partyBookId)!;
      return getPartyType(party);
    })
    .sort()
    .join(',');
}

export const titlesHasMatchingShareFraction = (partyBook: PartyModel<NswNameChange>[], deceasedProprietorGroups: DeceasedProprietorGroupModel[]) => {
  const transferorGroupWithJustification = deceasedProprietorGroups.filter(proprietorGroup =>
    proprietorGroup.deceasedProprietors.some(party => {
      const { receivingOrRelinquishingDetails } = partyBook.find(pb => pb.id === party.partyBookId!)!;
      return receivingOrRelinquishingDetails.isRelinquishing && receivingOrRelinquishingDetails.isChangingName;
    })
  );
  if (!transferorGroupWithJustification.length) {
    return true;
  }

  const justifiedTransferorGroupsMap = getJustifiedRelinquishingProprietorGroupsMap(deceasedProprietorGroups!, partyBook, getPartyNameAndPartyTypes);

  for (let transferorGroups of justifiedTransferorGroupsMap.values()) {
    if (transferorGroups.length > 1) {
      // check share transferred
      const [firstTransferorGroup, ...restTransferGroups] = transferorGroups;
      const allGroupsMatches = restTransferGroups.every(
        transferorGroup =>
          asDecimal({
            //
            numerator: transferorGroup.previouslyHeldShareFraction?.numerator ?? 1,
            denominator: transferorGroup.previouslyHeldShareFraction?.denominator ?? 1
          }) ===
          asDecimal({
            //
            numerator: firstTransferorGroup.previouslyHeldShareFraction?.numerator ?? 1,
            denominator: firstTransferorGroup.previouslyHeldShareFraction?.denominator ?? 1
          })
      );

      if (!allGroupsMatches) {
        return false;
      }
    }
  }

  return true;
};

function mergeSelectedTitlesProprietorGroup(selectedTitleReferences: TitleReferenceModel[], partyBook: PartyModel<NswNameChange>[]): DeceasedProprietorGroupModel[] {
  let partiesByProprietorGroupsAdded: string[][] = [];
  const mergedProprietorGroups = selectedTitleReferences
    .flatMap(({ deceasedProprietorGroups }) => [...deceasedProprietorGroups])
    .reduce((groupArray: DeceasedProprietorGroupModel[], group: DeceasedProprietorGroupModel) => {
      const incomingPartyIds = group.deceasedProprietors!.map(({ partyBookId }) => partyBookId!);

      // Check if group has been added to lookup
      if (
        !partiesByProprietorGroupsAdded.some(
          existingPartyIds =>
            incomingPartyIds.every(incomingPartyId => existingPartyIds.includes(incomingPartyId)) &&
            existingPartyIds.every(existingPartyId => incomingPartyIds.includes(existingPartyId))
        )
      ) {
        partiesByProprietorGroupsAdded.push(incomingPartyIds);
        groupArray.push({
          ...group,
          deceasedProprietors: group.deceasedProprietors.map(proprietor => {
            return {
              ...proprietor,
              deceasedProprietorId: resolveUuid('string', 20)
            };
          })
        });
      }
      return groupArray;
    }, []);

  if (mergedProprietorGroups.length === 1) {
    mergedProprietorGroups[0].deceasedProprietors.map(proprietor => {
      return {
        ...proprietor,
        isAffectedProprietor: true,
        deceasedProprietorId: resolveUuid('string', 20)
      };
    });
  }
  return mergedProprietorGroups;
}

export function resolvePartyLookupOptions(transModel: TransmissionApplicationWithoutDuty2_21_1Model): LookupItemModel[] {
  const partyBookIdWithoutDeceased = transModel.deceasedProprietorGroups.flatMap(groups =>
    groups.deceasedProprietors.map(proprietor => {
      return proprietor.partyBookId;
    })
  );
  const partyOptions = transModel.partyBook.filter(p => !partyBookIdWithoutDeceased.includes(p.id));
  const FORMATTED_PARTY_OPTIONS: LookupItemModel[] = partyOptions.map(party => ({ id: party.id, name: formatPartyName(party) }));
  return FORMATTED_PARTY_OPTIONS;
}

export const getJustifiedRelinquishingProprietorGroupsMap = <NC = {}>(
  deceasedProprietorGroups: DeceasedProprietorGroupModel[],
  partyBook: PartyModel<NC>[],
  getPartyNames: (ids: string[], partyBook: PartyModel<NC>[]) => string[]
): Map<string, DeceasedProprietorGroupModel[]> => {
  const justifiedRelinquishingProprietorGroupsMap = deceasedProprietorGroups
    .map(proprietorGroup => ({
      partyNames: getPartyNames(
        proprietorGroup.deceasedProprietors!.map(p => p.partyBookId!),
        partyBook
      ).sort(),
      proprietorGroup
    }))
    .reduce((transferorsGroupMap: Map<string, DeceasedProprietorGroupModel[]>, { partyNames, proprietorGroup }) => {
      const transferGroupKey = partyNames.join(',');
      if (transferorsGroupMap.has(transferGroupKey)) {
        transferorsGroupMap.get(transferGroupKey)!.push(proprietorGroup);
      } else {
        transferorsGroupMap.set(transferGroupKey, [proprietorGroup]);
      }
      return transferorsGroupMap;
    }, new Map<string, DeceasedProprietorGroupModel[]>());

  return justifiedRelinquishingProprietorGroupsMap;
};

export function initDefaultEvidences(resolvedValue: ApplicantCapacityEnum, isLastDeceased: boolean, existingEvidences?: EvidenceModel[]): EvidenceModel[] {
  let evidences: EvidenceModel[];
  const defaultEvidenceDate = existingEvidences ? existingEvidences[0].evidenceDate : null;
  const defaultEvidenceDocumentReference = existingEvidences ? existingEvidences[0].evidenceDocumentReference : '';
  if (isLastDeceased) {
    switch (resolvedValue) {
      case ApplicantCapacityEnum.Administrator:
        evidences = [
          {
            evidenceTypeValue: EvidenceTypeValueEnum.LettersOfAdministration,
            evidenceDate: defaultEvidenceDate,
            evidenceDocumentReference: defaultEvidenceDocumentReference
          }
        ];
        break;
      case ApplicantCapacityEnum.ExecutorByRepresentation:
        evidences = [
          {
            evidenceTypeValue: EvidenceTypeValueEnum.Probate,
            evidenceDate: defaultEvidenceDate,
            evidenceDocumentReference: defaultEvidenceDocumentReference
          },
          {
            evidenceTypeValue: EvidenceTypeValueEnum.Probate,
            evidenceDate: existingEvidences && existingEvidences.length > 1 ? existingEvidences[1].evidenceDate : null,
            evidenceDocumentReference: existingEvidences && existingEvidences.length > 1 ? existingEvidences[1].evidenceDocumentReference : ''
          }
        ];
        break;
      case ApplicantCapacityEnum.Trustee:
        evidences = [
          {
            evidenceTypeValue: EvidenceTypeValueEnum.DeedOfAppointment,
            evidenceDate: defaultEvidenceDate,
            evidenceDocumentReference: defaultEvidenceDocumentReference
          }
        ];
        break;
      default:
        evidences = [
          {
            evidenceTypeValue: EvidenceTypeValueEnum.Probate,
            evidenceDate: defaultEvidenceDate,
            evidenceDocumentReference: defaultEvidenceDocumentReference
          }
        ];
        break;
    }
  } else {
    evidences = [
      {
        evidenceTypeValue: EvidenceTypeValueEnum.DeathCertificate,
        evidenceDate: defaultEvidenceDate,
        evidenceDocumentReference: defaultEvidenceDocumentReference
      }
    ];
  }

  return evidences;
}

export const convertDeceasedProprietorGroupsFromFormToApi = (
  partyIdsByProprietorGroups: string[][],
  deceasedProprietorGroupMap: Map<string, DeceasedProprietorGroupModel>
): DeceasedProprietorGroupModel[] => {
  return partyIdsByProprietorGroups.map(partyIds => deceasedProprietorGroupMap.get(buildDeceasedGroupKey(partyIds))!);
};

const buildDeceasedGroupKey = (partyIds: string[]) => partyIds.join(',');

export const getDeceasedProprietorGroupsMap = (deceasedProprietorGroups: DeceasedProprietorGroupModel[]): Map<string, DeceasedProprietorGroupModel> => {
  const deceasedProprietorGroupsMap = new Map<string, DeceasedProprietorGroupModel>();
  for (let deceasedProprietorGroup of deceasedProprietorGroups) {
    const relinquishingGroupKey = buildDeceasedGroupKey(deceasedProprietorGroup.deceasedProprietors.map(p => p.partyBookId!));
    deceasedProprietorGroupsMap.set(relinquishingGroupKey, deceasedProprietorGroup);
  }
  return deceasedProprietorGroupsMap;
};

export const updateDeceasedProprietorGroupsWithPartyCapacity = (
  items: DeceasedProprietorGroupModel[],
  partyCapacity?: ApplicantCapacityEnum | null
): DeceasedProprietorGroupModel[] => {
  if (!partyCapacity) return items;
  return items.map(groups => {
    const deceasedProprietors = groups.deceasedProprietors.map(proprietor => {
      return updateProprietorWithCapacity(proprietor, proprietor.isLastDeceased, proprietor, partyCapacity);
    });
    return { ...groups, deceasedProprietors: deceasedProprietors };
  });
};

export const updateProprietorWithCapacity = (
  newProprietor: DeceasedProprietorModel,
  isLastDeceased: boolean,
  existingProprietor: DeceasedProprietorModel,
  partyCapacity?: ApplicantCapacityEnum | null
): DeceasedProprietorModel => {
  const defaultEvidences = initDefaultEvidences(partyCapacity!, isLastDeceased, existingProprietor.evidences);
  const otherEvidences = existingProprietor.evidences!.filter(evidence => evidence.isDeletable === true);
  return { ...newProprietor, dateOfDeath: existingProprietor?.dateOfDeath, evidences: [...defaultEvidences, ...otherEvidences] };
};

export const updateDeceasedProprietorGroupsUnSelected = (deceasedProprietorGroups: DeceasedProprietorGroupModel[]) => {
  return deceasedProprietorGroups.map(group => {
    return {
      ...group,
      deceasedProprietors: group.deceasedProprietors.map(proprietor => {
        if (group.deceasedProprietors.every(x => !x.isAffectedProprietor))
          return {
            ...proprietor,
            evidences: undefined,
            dateOfDeath: undefined
          };
        return proprietor;
      })
    };
  });
};

export function getParentGroupPath(fieldName: string = '') {
  const names = fieldName.split('.');
  return names.slice(0, names.length - 3).join('.');
}

export function getParentProprietorPath(fieldName: string = '') {
  const names = fieldName.split('.');
  return names.slice(0, names.length - 2).join('.');
}

export function getParentPath(fieldName: string = '') {
  const names = fieldName.split('.');
  return names.slice(0, names.length - 1).join('.');
}

export const isDisabledGroup = (deceasedProprietorGroups: DeceasedProprietorGroupModel[], isAffected: boolean): boolean => {
  if (!deceasedProprietorGroups.some(x => x.deceasedProprietors.every(d => d.isAffectedProprietor))) {
    return false;
  }
  return !isAffected;
};

export const visibleEvidenceWhenLastDeceasedChanged = (updatedItems: DeceasedProprietorModel[]) => {
  const isVisibleEvidence = updatedItems.some(x => x.isLastDeceased);
  return updatedItems.map(proprietor => {
    return {
      ...proprietor,
      isEvidenceVisible: isVisibleEvidence
    };
  });
};

export const visibleEvidenceWhenJointTenantsCheckBoxChanged = (updatedItem: DeceasedProprietorGroupModel) => {
  const isVisibleEvidence = updatedItem.deceasedProprietors.every(x => x.isAffectedProprietor) && updatedItem.deceasedProprietors.some(x => x.isLastDeceased);
  return {
    ...updatedItem,
    deceasedProprietors: updatedItem.deceasedProprietors.map(proprietor => {
      return {
        ...proprietor,
        isEvidenceVisible: isVisibleEvidence
      };
    })
  };
};

export function convertPartiesToFormModel_2_21_1(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  const uniqueParties = Array.from(
    parties
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.id!)) entryMap.set(e.id!, e);
        return entryMap;
      }, new Map<string, ApiDocumentPartyModel<NswDocumentPartyJustification>>())
      .values()
  );

  return convertPartiesToFormModel(
    {
      partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG2,
      nameChangeConversion: nswNameChangeConversion
    },
    uniqueParties,
    relinquishingIds
  );
}

export const haveOrganisationPartyInGroup = (group: DeceasedProprietorGroupModel, partyBook: PartyModel<NswNameChange>[]): boolean => {
  return group.deceasedProprietors.some(x => {
    const party = partyBook.find(p => p.id === x.partyBookId);
    return party?.partyType === PartyTypeEnum.Organisation;
  });
};

export const titlesHasSameTenancyStructureAndNumberOfGroups = (selectedTitleReferences: TitleReferenceModel[]) => {
  return titlesHasSameNumberOfProprietorGroups(selectedTitleReferences) && titlesHasMatchingTenancyType(selectedTitleReferences);
};

export const getPartyType = (party: PartyModel<NswNameChange>) => {
  if (party.readonlyMap?.partyType === false) return 'Indeterminate';
  else return party.partyType.toString();
};

export const convertTitlesFromFormToApi = (selectedTitleReferences: TitleReferenceModel[]) => {
  return selectedTitleReferences.map(title => {
    return {
      ...title,
      deceasedProprietorGroups: title.deceasedProprietorGroups.map(group => {
        return {
          ...group,
          deceasedProprietors: group.deceasedProprietors.map(proprietor => {
            const isLastDeceased = proprietor.isAffectedProprietor && proprietor.isLastDeceased;
            const dateOfDeathUpdated = isLastDeceased
              ? {
                  ...proprietor.dateOfDeath!,
                  deathDate:
                    proprietor.dateOfDeath!.dateOfDeathType === DateOfDeathTypeEnum.ActualDate
                      ? dateFormat(proprietor.dateOfDeath!.deathDate as Date, DateFormatEnum.DATE)
                      : undefined,
                  fromDate:
                    proprietor.dateOfDeath!.dateOfDeathType === DateOfDeathTypeEnum.DateRange
                      ? dateFormat(proprietor.dateOfDeath!.fromDate as Date, DateFormatEnum.DATE)
                      : undefined,
                  toDate:
                    proprietor.dateOfDeath!.dateOfDeathType === DateOfDeathTypeEnum.DateRange ? dateFormat(proprietor.dateOfDeath!.toDate as Date, DateFormatEnum.DATE) : undefined
                }
              : undefined;
            const evidenceUpdated = isLastDeceased
              ? proprietor.evidences?.map(evidence => {
                  return {
                    ...evidence,
                    evidenceDate: dateFormat(evidence.evidenceDate as Date, DateFormatEnum.DATE)
                  };
                })
              : proprietor.evidences;
            return {
              ...proprietor,
              dateOfDeath: dateOfDeathUpdated,
              evidences: evidenceUpdated
            };
          })
        };
      })
    };
  });
};
