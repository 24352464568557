import { NECDS_COUNTRY_LOOKUP_OPTIONS, NecdsCountryEnum } from '@sympli-mfe/enums-2-24/necds';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupItemModel } from '@sympli/ui-framework/models';

export const FORM_25_COUNTRY_CODE_OPTIONS = restrictedLookupOptions<NecdsCountryEnum>(NECDS_COUNTRY_LOOKUP_OPTIONS, {
  blacklist: [NecdsCountryEnum.AU]
});

export enum ForeignTypeEnum {
  NaturalPerson = 'Natural Person',
  WhollyForeignCompany = 'Wholly Foreign Company',
  PartlyForeignCompany = 'Partly Foreign Company'
}

export const ORGANISATION_OWNERSHIP_TYPE_OPTIONS: LookupItemModel[] = restrictedLookupOptions<ForeignTypeEnum>(
  Object.values(ForeignTypeEnum).map(name => ({ id: name, name })),
  {
    blacklist: [ForeignTypeEnum.NaturalPerson]
  }
);
