import * as React from 'react';

import { useStoreSelector } from 'src/hooks';
import SubheadingWithStatus from './SubheadingWithStatus';

interface Props {
  userId: string;
  description?: React.ReactNode;
}

function SubheadingWithStatusContainer({ userId, description }: Props) {
  const detail = useStoreSelector(store => store.userDetails.detail);

  return (
    <SubheadingWithStatus //
      // route params
      userId={userId}
      // redux
      userDetail={detail}
      // other
      description={description}
    />
  );
}

export default React.memo(SubheadingWithStatusContainer);
