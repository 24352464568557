import { LookupItemModel } from '@sympli/ui-framework/models';

export enum EvidenceTypeEnum {
  Probate = 'Probate',
  LettersOfAdministration = 'Letters of Administration',
  DeedOfAppointment = 'Deed of Appointment',
  StatutoryDeclaration = 'Statutory Declaration',
  DeathCertificate = 'Death Certificate',
  CertificateUnderNSWTrusteeAndGuardianAct = 'Certificate under NSW Trustee and Guardian Act'
}

export const EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupItemModel<EvidenceTypeEnum>[] = Object.values(EvidenceTypeEnum).map(name => ({ id: name, name }));
