import * as React from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

export interface Props extends React.PropsWithChildren<{ error?: string; isLoading?: boolean }> {}

function RenderWhenReady({ error, isLoading, children }: Props) {
  const isReady = !(error?.length || isLoading);
  if (!isReady) {
    return (
      <div className="flex flex-col justify-center align-middle">
        {isLoading && <BlockLoader />}
        {!!error && (
          <FormHelperText role="alert" error>
            {error}
          </FormHelperText>
        )}
      </div>
    );
  }
  return children;
}
export default React.memo(RenderWhenReady);
