import { BankAccountTypeEnum } from '@sympli/api-gateway/enums';

import { SourceFundModel, SympliSourceAccountStatusEnum, TrustAccountMap } from '../../models';

export function showCalculator(sourceFunds?: Array<SourceFundModel>, trustAccountBankDetailMap?: TrustAccountMap) {
  if (trustAccountBankDetailMap == null || sourceFunds == null) {
    return false;
  }

  const hasSympliSourceAccount = sourceFunds.some(item => {
    const trustAccountBankDetail = trustAccountBankDetailMap[item.trustAccountId];
    if (trustAccountBankDetail == null) {
      return false;
    }
    return trustAccountBankDetail.bankAccountType === BankAccountTypeEnum.SympliSourceAccount;
  });

  return hasSympliSourceAccount;
}

export function resolveSympliSourceAccountStatus(balanceAmount: number, amountRequired: number) {
  if (balanceAmount === 0) {
    return SympliSourceAccountStatusEnum.Unverified;
  }

  if (balanceAmount > amountRequired) {
    return SympliSourceAccountStatusEnum.Surplus;
  } else if (balanceAmount < amountRequired) {
    return SympliSourceAccountStatusEnum.Shortfall;
  } else {
    return SympliSourceAccountStatusEnum.Cleared;
  }
}
