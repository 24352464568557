import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { CommonTenancyLabelsEnum, ReceivingTenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import {
  RelinquishingTenancyComponentLabelConfig,
  SHARE_TRANSFERRED_TYPE_OPTIONS,
  ShareTransferredTypeEnum
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { DEFAULT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { NSW_TENANCY_COMPONENT_LABEL_CONFIG } from '../../../2-17/components/tenancy/config';
import { NSW_RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG } from '../../components/tenancy/config';
import { NAME_SUFFIX_LOOKUP_OPTIONS } from './enums';

export const NSW_TAE_PARTY_FORM_CONFIG: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName },
  individualPartyFormConfig: {
    ...DEFAULT_PARTY_FORM_CONFIG.individualPartyFormConfig,
    allowsNameSuffix: true,
    nameSuffixLookupEnum: NAME_SUFFIX_LOOKUP_OPTIONS
  }
};

function formatNewName(partyType: PartyTypeEnum, nameChangeModel: NswNameChange): string {
  if (partyType === PartyTypeEnum.Individual) {
    const { firstName, lastName } = nameChangeModel;
    return [firstName, lastName].filter(Boolean).join(' ');
  } else if (partyType === PartyTypeEnum.Organisation) {
    const { organisationName } = nameChangeModel;
    return organisationName;
  } else {
    throw new Error('Unsupported party type.');
  }
}

export const NSW_TAE_DOCUMENT_ATTACHMENT_LABEL_CONFIG: DocumentAttachmentLabelConfig = {
  sectionTitle: 'Upload supporting documents',
  uploaderTitle: 'Upload document',
  addButtonTitle: 'Add another document'
};

export const NSW_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...NSW_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.Transferee
};
export const NSW_TRANSFER_RELINQUISHING_TENANCY_CONFIG: RelinquishingTenancyComponentLabelConfig = {
  ...NSW_RELINQUISHING_TENANCY_COMPONENT_LABEL_CONFIG,
  relinquishingPartyRole: CommonTenancyLabelsEnum.Transferor
};

const allowedShareTransferredType: ShareTransferredTypeEnum[] = [ShareTransferredTypeEnum.Whole, ShareTransferredTypeEnum.Partial];
export const NSW_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANFERRED_TYPE: LookupEnumModel<ShareTransferredTypeEnum, string>[] = SHARE_TRANSFERRED_TYPE_OPTIONS.filter(x =>
  allowedShareTransferredType.includes(x.id)
);

export const PARTY_FORM_WITH_NAME_CHANGE_CONFIG: PartyFormConfig<NswNameChange> = {
  ...DEFAULT_PARTY_FORM_CONFIG,
  nameChangeConfig: nswPartyNameChangeConfig
};

export const PARTY_FORM_WITH_NAME_CHANGE_CONFIG2: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: nswPartyNameChangeConfig
};

export const NSW_TAE_TENANCY_COMPONENT_LABEL_CONFIG: ReceivingTenancyComponentLabelConfig = {
  ...NSW_TENANCY_COMPONENT_LABEL_CONFIG,
  receivingPartyRole: CommonTenancyLabelsEnum.AddJointTenant
};
