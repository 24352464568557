import { $oneOfItems, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';

import { DischargeOfMortgage2_18_0Model } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - mortgages[*].titleReferences
 - mortgages[*].mortgagees

 schema version:
[
  {
    "isSelected": true
  }
]
*/
export const VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES = createContextCheck((context: DischargeOfMortgage2_18_0Model): boolean =>
  $oneOfItems(context.mortgages ?? [], item => item.isSelected === true)
);
