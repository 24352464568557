import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

const REGEX_PHONE = /^\+?\d+(\s\d+)*$/; // numbers with optional one-space gaps between

export const yupPhoneNumber = yup //
  .string()
  .required(msg.REQUIRED)
  .max(20, msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(20))
  .matches(REGEX_PHONE, msg.INVALID_VALUE);
