import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { FileModel, WorkspaceFileUploadModel } from './models';

const validationSchema = yup.object<WorkspaceFileUploadModel>({
  description: yup.string().nullable().default(null).required(msg.REQUIRED),
  notes: yup
    .string()
    .max(250, ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${value.length}/${max} Characters including spaces`)
    .default(''),
  files: yup.array<FileModel, WorkspaceFileUploadModel>().testContextualRule({
    uniqueTestName: 'files must contain one file',
    onlyIf: (context: WorkspaceFileUploadModel): boolean => Boolean(context.id) === false, // a new file is being uploaded
    requirement: (context: WorkspaceFileUploadModel): boolean => context.files?.length !== 0,
    message: msg.REQUIRED
  }),
  isShared: yup.bool().testContextualRule({
    uniqueTestName: 'must have one share option checked when true',
    onlyIf: (context: WorkspaceFileUploadModel): boolean => Boolean(context.isShared),
    requirement: (context: WorkspaceFileUploadModel): boolean => {
      return Boolean(context.sharedWithInvitationIds?.length) || Boolean(context.sharedWithParticipantIds?.length) || context.isForFutureParticipants;
    },
    message: msg.MUST_SELECT_AT_LEAST_X(1, 'option')
  }),
  sharedWithParticipantIds: yup.array<string, WorkspaceFileUploadModel>(),
  sharedWithInvitationIds: yup.array<string, WorkspaceFileUploadModel>(),
  isForFutureParticipants: yup.boolean().defined().default(false)
});

export default validationSchema;
