import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { VicPartyForm_2_18 as PartyForm } from '@sympli-mfe/document-components/party-form/vic/2-18';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { Mortgage2_18_1Model, MortgagorPartyModel } from '../../models';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

const rootFieldName = modelKey<Mortgage2_18_1Model>();

function Mortgagors({ name, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const { values } = useFormikContext<Mortgage2_18_1Model>();
  const { partyBook, isFinancialSettlement } = values;

  const renderItem = useCallback(
    //
    ({ item, itemIndex }: DocumentArrayItemRenderProps<MortgagorPartyModel>) => {
      // we will be doing updates directly to partyBook
      // find the index of current mortgagor within partyBook
      const bookIndexOfPartyId = partyBook.findIndex(party => party.id === item.partyBookId);
      const itemBinding = `${rootFieldName('partyBook')}[${bookIndexOfPartyId}]`;

      if (isFinancialSettlement) {
        return (
          <div data-name={`${name}[${bookIndexOfPartyId}]`}>
            <Subform //
              subformBindingPath={itemBinding}
              component={PartyForm}
              disabled={disabled}
              showFormGroups={false}
              focusRef={itemIndex === 0 ? focusRef : undefined}
            />
          </div>
        );
      }

      return (
        <div data-name={`${name}[${bookIndexOfPartyId}]`}>
          <Subform //
            subformBindingPath={itemBinding}
            component={PartyForm}
            disabled={disabled}
            config={VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG}
            showFormGroups={false}
            focusRef={itemIndex === 0 ? focusRef : undefined}
          />
        </div>
      );
    },
    [partyBook, isFinancialSettlement, name, disabled, focusRef]
  );

  return (
    <DocumentFieldArray //
      shouldMemoizeContext={false} // explicitly don't memoize the mortgagors array because items are bound to partyBook
      arrayBinding={name}
      renderItem={renderItem}
      itemTitle={'Mortgagor'}
      disabled={disabled}
      itemStyle="formGroup"
      mode="fixed"
    />
  );
}

export default React.memo(Mortgagors);
