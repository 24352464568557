import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { MortgageTermsAndConditionsModel, StandardTermsDocumentReferenceModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function StandardTermsDocumentReference({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<StandardTermsDocumentReferenceModel>(name);
  const { values } = useFormikContext<MortgageTermsAndConditionsModel>();
  const value: StandardTermsDocumentReferenceModel = _get(values, name);

  const standardTermsDocumentReferences = values.standardTermsDocumentReferences;
  const preRegisteredStandardTerms = values.preRegisteredStandardTermsDocumentReferences;

  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(value, values);

  const options = useMemo(() => {
    const selectedDocumentReferences = standardTermsDocumentReferences.map(v => (v.documentReference ?? '').trim()).filter(v => v !== '');
    return preRegisteredStandardTerms.filter(x => !selectedDocumentReferences.includes(x) || x === (value.documentReference ?? '').trim());
  }, [preRegisteredStandardTerms, standardTermsDocumentReferences, value.documentReference]);

  const placeholder = options.length ? resolveComboboxPlaceholder(isRequired) : resolvePlaceholder(isRequired);

  return (
    <div data-name={name} className={className}>
      <Field //
        aria-label="Document reference"
        name={fieldName('documentReference')}
        component={SympliAutocompleteField}
        disabled={disabled}
        placeholder={placeholder}
        options={options}
        type="combobox"
      />
    </div>
  );
}

export default React.memo(StandardTermsDocumentReference);
