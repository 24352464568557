import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { DischargeOfMortgage2_17_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<DischargeOfMortgage2_17_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  mortgages: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        dealingNumber: NO_FALLBACK,
        isSelected: [false],
        mortgagees: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              partyBookId: ['']
            }
          }
        },
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              isSelected: [false],
              reference: NO_FALLBACK,
              landDescriptions: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        landDescriptions: NO_FALLBACK
      }
    }
  },
  mortgagees: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        partyBookId: ['']
      }
    }
  }
};
