import * as yup from 'yup';

import { $exactlyOneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import { getLookupValuesAllowingEmpty, testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { yupGenericPhoneNumber } from '@sympli/ui-framework/components/formik/phone-number-field';
import msg from '@sympli/ui-framework/utils/messages';

import { yupEmailAddress } from 'src/containers/documents/scaffolded-form/vic/2-18/helpers/yupEmailAddress';
import { SRO_PARTY_CAPACITY_LOOKUP_OPTIONS, SroPartyCapacityEnum } from '../../enums';
import { END_OF_TODAY, FractionModel, NoticeOfAcquisitionDocumentModel, TransfereeDetailModel } from '../../models';
import {
  VISIBILITY_CHECK_TRANSFEREE_BIRTH_DATE,
  VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL,
  VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS,
  VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL
} from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
export const yupContactPhone = yupGenericPhoneNumber.default('').nullable(true).max(20, msg.MAX_LENGTH(20));

export const ERROR_PLEASE_PROVIDE_CONTACT_DETAILS = 'Please provide contact phone and/or email';

export const createYupMissingContactDetails = <
  P extends {
    //
    contactPhoneNumber?: string;
    emailAddress?: string;
  }
>(
  yupContactPhone,
  yupEmailAddress
) =>
  yup.mixed<any>().test('valid contact details check', ERROR_PLEASE_PROVIDE_CONTACT_DETAILS, function test(this: yup.TestContext<NoticeOfAcquisitionDocumentModel>) {
    const { contactPhoneNumber, emailAddress } = this.parent as P;
    return yupContactPhone.required(msg.REQUIRED).isValidSync(contactPhoneNumber) || yupEmailAddress.required(msg.REQUIRED).isValidSync(emailAddress);
  });

const yupMissingContactDetails = createYupMissingContactDetails<TransfereeDetailModel>(yupContactPhone, yupEmailAddress);

// path: transferees
const yupTransferees = yup //
  .mixed<TransfereeDetailModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
      validationWhenVisible: yup
        .array<TransfereeDetailModel>()
        .required(msg.REQUIRED)
        .test(
          testContextualRule({
            /**
           * what we found in schema:
           [{"required":{"transferees":{"$items":{"$exactlyOne":{"isLeadOwner":true}}}},"message":"Only one lead owner must be selected"}]
           *
           */
            uniqueTestName: '"transferees" contextual validation rule #1',
            requirement: (transferees: TransfereeDetailModel[], context: NoticeOfAcquisitionDocumentModel): boolean =>
              $exactlyOneOfItems<TransfereeDetailModel>(context.transferees ?? [], item => item.isLeadOwner === true),
            message: 'Only one lead owner must be selected'
          })
        )
        .min(1, msg.MIN_ITEMS(1))
        .max(20, msg.MAX_ITEMS(20))
        .of(
          yup //
            .object<TransfereeDetailModel>({
              isLeadOwner: yup.boolean().required(msg.REQUIRED),
              partyBookId: yup.mixed<string>(), // readonly
              sroPartyCapacity: yup
                .mixed<SroPartyCapacityEnum>()
                .required(msg.REQUIRED)
                .oneOf(getLookupValuesAllowingEmpty(SRO_PARTY_CAPACITY_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
              partyCapacityDetail: yup //
                .mixed<string>()
                .test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_TRANSFEREE_PARTY_CAPACITY_DETAIL,
                    validationWhenVisible: yup.string().default('').trim()
                  })
                ),
              shareFraction: yup.mixed<FractionModel>(), // readonly

              birthDate: yup //
                .mixed<Date | string | null>()
                .test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_TRANSFEREE_BIRTH_DATE,
                    validationWhenVisible: yup //
                      .date()
                      .nullable(true)
                      .default(null)
                      .typeError(msg.INVALID_DATE)
                      .required(msg.REQUIRED)
                      .max(END_OF_TODAY, msg.DATE_MUST_BE_TODAY_OR_PAST_DATE)
                  })
                ),

              principalResidentialAddressBookId: yup //
                .mixed<string>()
                .test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_TRANSFEREE_PRINCIPAL_ADDRESS,
                    validationWhenVisible: yup.string().default('').trim().required(msg.REQUIRED)
                  })
                ),

              addressAtTimeOfTransferBookId: yup.string().default('').trim().required(msg.REQUIRED),
              correspondenceAddressBookId: yup.string().default('').trim().required(msg.REQUIRED),
              contactPhoneNumber: yupContactPhone.nullable(false),
              emailAddress: yupEmailAddress.nullable(false),
              missingContactDetails: yupMissingContactDetails
            })
        ),
      isObjectOrArray: true
    })
  );

export default yupTransferees;
