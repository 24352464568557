import React from 'react';

import { useStyles } from './styles';

function IconExpand({ expanded }: { expanded: boolean }) {
  const classes = useStyles();
  return <div className={classes.root}>{expanded ? '-' : '+'}</div>;
}

export default React.memo(IconExpand);
