export enum TaskTypesEnum {
  CompleteDocument = 0,
  DocumentReview = 1,
  ResendInvitation = 2,
  SendInvitation = 3,
  SignDocumentNSW = 4,
  VerifyStampDuty = 5,
  EnterAvailableFunds = 6,
  EnterBalanceDueToVendor = 7,
  EnterIndicativePayoutFigure = 8,
  EnterFinalPayoutFigure = 9,
  EnterAdvance = 10,
  EnterTotalRequiredSettlementFunds = 11,
  EnterPayments = 12,
  ReceivedDischargeAuthority = 13,
  AuthorityToCollectVendorFunds = 14,
  SentLoanDocuments = 15,
  ReceivedLoanDocuments = 16,
  AuthorityToDebitPurchaserFunds = 17,
  SendDischargeAuthority = 18,
  ConfirmBalanceDueToVendor = 19,
  ReviewNewSettlementDate = 20,
  SignDocumentVIC = 21,
  AddLodgementInstructions = 22,
  ResolveDocumentIssues = 23,
  ReviewPaymentSummary = 24,
  LodgeWorkspace = 25,
  ReattemptWorkspaceLodgement = 26,
  SignDocumentQLD = 27,
  CompleteDirections = 28,
  ReviewDirections = 29,
  SignDirections = 30,
  SignAccountAuthorisationRecord = 31,
  SignDocumentWA = 32,
  SignDocumentTAS = 33,
  SignDocumentACT = 34,
  SignDocumentNT = 35,
  SignDocumentSA = 36,
  WorkspaceReadyToLodge = 37,
  RebookSettlementDate = 38,
  CancelStampDuty = 39,
  AddDocument = 40,
  ProposeSettlementDateAndTime = 43,
  ProposeSettlementTime = 44,
  AddTitle = 45
}

// export enum TaskTypeEnum {
//   DocumentCompletion = 0,
//   DocumentCreation = 1,
//   InvitationResponse = 2,
//   DocumentReview = 3,
//   AddDirections = 4
// }

// MR - moved
// export enum TaskGroupTypeEnum {
//   Document = 1,
//   Directions = 2,
//   Other = 3,
//   PaymentSummaries = 4,
//   Lodgement = 5
// }

// MR - moved
// export enum TaskGroupSubTypeEnum {
//   Sign = 1,
//   Other = 2
// }
