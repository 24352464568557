import * as yup from 'yup';

import { $eq, $get, $oneOfItems } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { MortgageDetailModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: mortgages
const yupMortgages = yup
  .array<MortgageDetailModel>()
  .required(msg.REQUIRED)
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  // .of<MortgageDetailModel>(
  //   yup
  //     .object<MortgageDetailModel>({
  //       dealingNumber: yup.mixed<string>(),
  //       isSelected: yup.mixed<boolean>(),
  //       titleReferences: yup.array<BaseTitleReferenceModel>().defined(),
  //       mortgageeDetails: yup.object<RelinquishingTenancyDetailModel>().defined()
  //     })
  //     .defined()
  // )
  .test(
    testContextualRule({
      /**
           * what we found in schema:
           [{"required":{"mortgages":{"$items":{"$oneOf":{"isSelected":true}}}},"message":"At least one mortgage must be selected"}]
           *
           */
      uniqueTestName: '"mortgages" contextual validation rule #1',
      requirement: (mortgages: MortgageDetailModel[]): boolean => $oneOfItems(mortgages, item => $eq($get(item, 'isSelected'), true)),
      message: 'At least one mortgage must be selected'
    })
  )
  .defined();

export default yupMortgages;
