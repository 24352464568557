import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      fontSize: 13
    },
    subHeader: {
      marginTop: -8
    },
    iconLink: {
      marginRight: 2
    },
    list: {
      marginLeft: -16,
      marginRight: -16,
      '& $listItem': {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    listItem: {}, // do not remove
    listItemLink: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }),
  {
    name: 'LinkedWorkspaceList'
  }
);
