import React from 'react';

import Paper from '@mui/material/Paper';

import { ComplianceLevelEnum } from '@sympli/api-gateway/enums';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import { convertComplianceToMessageGridModel } from 'src/containers/workspace/shared/detail/helpers';
import { LodgementCompliance } from 'src/containers/workspace/shared/detail/models';
import ComplianceMessageLists from '../compliance-message-lists';
import { useStyles } from './styles';

interface Props {
  lodgementCompliances: LodgementCompliance[];
}

export function LodgementCaseComplianceMessages({ lodgementCompliances }: Props) {
  const classes = useStyles();
  const { errors, warnings } = React.useMemo<{
    errors: MessageModel[];
    warnings: MessageModel[];
  }>(
    () => ({
      errors: lodgementCompliances.filter(c => c.complianceLevel === ComplianceLevelEnum.Error).map(convertComplianceToMessageGridModel),
      warnings: lodgementCompliances.filter(c => c.complianceLevel === ComplianceLevelEnum.Warning).map(convertComplianceToMessageGridModel)
    }),
    [lodgementCompliances]
  );

  return (
    <Paper square className={classes.root}>
      <ComplianceMessageLists //
        errors={errors}
        warnings={warnings}
      />
    </Paper>
  );
}

export default React.memo(LodgementCaseComplianceMessages);
