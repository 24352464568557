import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 14,
      color: theme.palette.text.secondary
    },
    main: {
      overflow: 'hidden'
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
      color: theme.palette.text.primary,
      '& a': {
        color: theme.palette.text.primary
      }
    },
    title101: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
      color: theme.palette.text.primary,
      '& a': {
        color: theme.palette.text.primary
      }
    },
    titlePending: {
      color: theme.palette.text.secondary
    },
    description: {
      fontSize: 13,
      lineHeight: '15px',
      color: theme.palette.text.secondary
    },
    description101: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.text.primary
    },
    icon: {
      fontSize: 16,
      paddingRight: 6,
      alignSelf: 'flex-start',
      color: theme.palette.text.primary
    },
    avatar: {
      marginLeft: 'auto'
    },
    textEllipsis: {
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    selected: {}
  });

export type ClassKeys = typeof styles;

export default styles;
