import * as React from 'react';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveLabel } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import { CovenantDetailModel } from '../../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
}

function CovenantDetail({ name }: Props): JSX.Element {
  const classes = useStyles();
  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<CovenantDetailModel>(name);

  return (
    <div data-name={name} className={classes.root}>
      <Field //
        name={fieldName('referenceNumber')}
        component={InputField}
        disabled={disabled}
        label={resolveLabel('MCP containing covenant details', true)}
      />
    </div>
  );
}

export default React.memo(CovenantDetail);
