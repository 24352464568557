import React, { memo, PropsWithChildren, useCallback, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import IconExpand from './components/icon-expand';
import { useStyles } from './styles';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  renderExpandedContent(itemIndex?: number): JSX.Element;
  itemIndex?: number;
  defaultExpanded?: boolean;
}

function TableRowLikeAccordion({
  //
  children,
  itemIndex,
  defaultExpanded = false,
  renderExpandedContent,
  ...rest
}: PropsWithChildren<Props>) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const classes = useStyles();

  const handleOnExpandChange = useCallback(
    (event: React.SyntheticEvent, expanded: boolean) => {
      setIsExpanded(expanded);
    },
    [setIsExpanded]
  );

  return (
    <Accordion //
      classes={{ root: classes.root, expanded: classes.expanded }}
      expanded={isExpanded}
      onChange={handleOnExpandChange}
      elevation={0}
      {...rest}
    >
      <AccordionSummary //
        classes={{
          root: classes.summaryRoot,
          content: classes.summaryContent,
          expanded: classes.expanded,
          expandIconWrapper: classes.summaryExpandIcon
        }}
        expandIcon={<IconExpand expanded={isExpanded} />}
      >
        {children}
      </AccordionSummary>
      {<AccordionDetails>{renderExpandedContent(itemIndex)}</AccordionDetails>}
    </Accordion>
  );
}

export default memo(TableRowLikeAccordion);
