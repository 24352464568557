import * as React from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { AccessToken, Token, TokenManagerInterface } from '@okta/okta-auth-js';

import Logger from '@sympli/ui-logger';

import { useOktaAuth } from './okta';

/**
 *
 * @param expiresAt the timestamp when token is going to be expired
 * returns in how many seconds will the token be expired
 */
const expiresIn = (expiresAt: number) => {
  return (expiresAt * 1000 - new Date().getTime()) / 1000;
};

// Idle timeout is set to 80% of the total expiration (eg: 24 mins of 30 mins)
// If user is idle for 80% of the total expiration, we stop the silent renew
// If user becomes active again, we resume silent renew
const IdleCheck = () => {
  const navigate = useNavigate();
  const [idlerTimeoutInMilliseconds, setIdlerTimeoutInMilliseconds] = React.useState<number | undefined>();
  const { oktaAuth } = useOktaAuth();
  const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;

  const handler = React.useCallback(
    (key: string, expiredToken: Token) => {
      try {
        tokenManager.renew('idToken');
      } catch (error) {
        Logger.captureException(error);
      }
    },
    [tokenManager]
  );

  const { start } = useIdleTimer({
    timeout: idlerTimeoutInMilliseconds,
    onIdle: () => {
      tokenManager.off('expired');
      tokenManager.on('expired', () => {
        navigate('/logout');
      });
    },
    onActive: () => {
      tokenManager.off('expired');
      tokenManager.on('expired', handler);
    },
    startManually: true
  });

  React.useEffect(() => {
    if (idlerTimeoutInMilliseconds != null) {
      start();
    }
  }, [idlerTimeoutInMilliseconds, start]);

  // we need to reset idle timer when user get a new access_token
  React.useEffect(() => {
    async function setup() {
      try {
        const accessToken = (await tokenManager.get('accessToken')) as AccessToken | undefined;

        if (!accessToken?.expiresAt) {
          return;
        }

        tokenManager.off('expired');
        tokenManager.on('expired', handler);

        const newIdlerTimeoutInSeconds = Math.floor(expiresIn(accessToken.expiresAt) * 0.8);
        setIdlerTimeoutInMilliseconds(newIdlerTimeoutInSeconds * 1000);
      } catch (error) {
        Logger.captureException(error);
      }
    }

    setup();
  }, [handler, oktaAuth, tokenManager]);

  return null;
};

export default React.memo(IdleCheck);
