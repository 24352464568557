import { createAsyncAction } from 'typesafe-actions';

import { DocumentTypeIdentifierEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { AvailableDocumentApiResponse } from '../reducers/defaultFieldAvailableDocumentFeed';
import { DocumentFieldDetailApiResponse } from '../reducers/defaultFieldPopulationList';

export const actionFetchDocumentGroupFeed = createAsyncAction(
  //
  'FETCH_DOCUMENT_GROUP_FEED',
  'FETCH_DOCUMENT_GROUP_FEED_SUCCESS',
  'FETCH_DOCUMENT_GROUP_FEED_ERROR'
)<
  //
  undefined,
  { data: JurisdictionsEnum[] },
  { error: Error }
>();

export interface AvailbleDocumentApiRequest {
  jurisdictionId: JurisdictionsEnum;
}

export const actionFetchAvailableDocumentFeed = createAsyncAction(
  //
  'FETCH_AVAILABLE_DOCUMENT_FEED',
  'FETCH_AVAILABLE_DOCUMENT_FEED_SUCCESS',
  'FETCH_AVAILABLE_DOCUMENT_FEED_ERROR'
)<
  //
  AvailbleDocumentApiRequest,
  { data: AvailableDocumentApiResponse[] },
  { error: Error }
>();

export interface DocumentDefaultFieldsApiRequest {
  jurisdictionId: JurisdictionsEnum;
  documentTypeId: DocumentTypeIdentifierEnum;
}

export const actionFetchDocumentDefaultFields = createAsyncAction(
  //
  'FETCH_DOCUMENT_DEFAULT_FIELDS',
  'FETCH_DOCUMENT_DEFAULT_FIELDS_SUCCESS',
  'FETCH_DOCUMENT_DEFAULT_FIELDS_ERROR'
)<
  //
  DocumentDefaultFieldsApiRequest,
  { data: DocumentFieldDetailApiResponse },
  { error: Error }
>();
