import { resolveMemoizedTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { resolveTitleReferenceItemSchema } from '../../../../components/title-reference/validationSchema';
import { TitleReferenceModel } from '../../models';

// path: titleReferences
const yupTitleReferenceItem = resolveTitleReferenceItemSchema<TitleReferenceModel>(true);
const yupTitleReferences = resolveMemoizedTitleReferencesValidationSchema<TitleReferenceModel>(yupTitleReferenceItem);

export default yupTitleReferences;
