import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

export interface ScrollWrapperProps extends React.HTMLAttributes<HTMLDivElement> {}

type Props = ScrollWrapperProps & WithStyles<ClassKeys>;

class ScrollWrapper extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { className, classes, onScroll, id, children } = this.props;
    return (
      <div className={classNames(classes.root, className, 'tickle-body-main')} onScroll={onScroll} id={id}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(ScrollWrapper);
