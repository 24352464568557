import * as React from 'react';

import classNames from 'classnames';
import _upperFirst from 'lodash-es/upperFirst';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import { TooltipType } from '@sympli/ui-framework/components/form/base-components/tooltip/Tooltip';
import { colors } from '@sympli/ui-framework/theme';

import OnlineStatusIcon from 'src/components/online-status-icon';
import { OnlineStatusEnum } from 'src/components/online-status-icon/models';
import { UserAvatarModel } from '../multi-user-avatar/MultiUserAvatar';
import styles, { ClassKeys } from './styles';

export interface UserAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  alt?: string;
  text?: string;

  status?: OnlineStatusEnum;
  statusTooltip?: string;

  item?: UserAvatarModel;

  highlighted?: boolean;
  size?: 'default' | 'small' | 'extraSmall';
  border?: boolean;

  showToolTip?: boolean;
  margin?: 'left' | 'right';
  shouldShowNameInit?: boolean; // we only want to show for the initial
}
type Props = UserAvatarProps & TooltipType & WithStyles<ClassKeys>;

class UserAvatar101 extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showToolTip: true,
    size: 'default'
  };

  render() {
    const { classes, style, size = 'default', highlighted, className, margin, showToolTip } = this.props;
    const sizeClassName = classes[`size${_upperFirst(size)}`];
    return (
      <div className={classNames(classes.root, sizeClassName, className, margin === 'left' && classes.marginLeft, margin === 'right' && classes.marginRight)} style={style}>
        {highlighted && <div className={classNames(classes.highlighted, sizeClassName)} />}
        {showToolTip ? this.renderAvatarWithToolTip() : this.renderAvatar()}
        {this.renderStatus()}
      </div>
    );
  }

  private renderAvatarWithToolTip() {
    const { placement, tooltipType } = this.props;
    return (
      <Tooltip title={this.renderTooltipText()} placement={placement} tooltipType={tooltipType}>
        {this.renderAvatar()}
      </Tooltip>
    );
  }

  renderAvatar() {
    const { classes, alt, src, border, text, shouldShowNameInit } = this.props;

    return (
      <Avatar className={classNames(classes.avatar, border && classes.border)} alt={alt} src={src}>
        {src ? null : shouldShowNameInit && text ? text : null}
      </Avatar>
    );
  }

  renderStatus() {
    const { classes, status, statusTooltip, size } = this.props;
    if (status === undefined) {
      return null;
    }
    return <OnlineStatusIcon value={status} title={statusTooltip} small={size === 'small'} className={classes.status} />;
  }

  private renderTooltipText() {
    const { item, text } = this.props;

    if (item) {
      const roles = item.workspaceRoles.map(role => role.name).join(', ');

      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2_bold" textTransform="uppercase" sx={{ color: colors.WHITE }}>
            {item.name}
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            Role{item.workspaceRoles.length > 1 ? 's' : ''}: {roles}
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
            Matter: {item.reference || 'Pending'}
          </Typography>
        </Box>
      );
    }

    if (text) {
      return (
        <Typography variant="body2_bold" textTransform="uppercase" sx={{ color: colors.WHITE }}>
          Name: {text}
        </Typography>
      );
    }
    return '';
  }
}

export default withStyles(styles)(UserAvatar101);
