import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    heading: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '20px',
      marginBottom: 4
    },
    ackStatementContainer: {
      marginTop: 16
    },
    messages: {
      marginBottom: 0,
      marginTop: 0,
      textAlign: 'left',
      whiteSpace: 'pre-line', // This will respect the \n char from backend
      paddingLeft: 20,
      '& li': {
        fontSize: 14,
        lineHeight: '20px',
        color: theme.palette.text.secondary
      }
    },
    statement: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.text.secondary
    },
    subStatements: {
      listStyleType: 'lower-alpha',
      color: theme.palette.text.secondary
    }
  }),
  {
    name: 'CertificationStatements'
  }
);
