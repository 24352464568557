import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { useConfirmationDialogStyles } from './styles';

interface Props {
  onClose: (data?: boolean) => void;
  workspaceRole: string;
  documentType: string;
  isSubmitting: boolean;
}

function JoinDialog({ onClose, workspaceRole, documentType, isSubmitting }: Props): JSX.Element {
  const confirmationDialogClasses = useConfirmationDialogStyles();

  return (
    <ConfirmationDialog
      title="Join document"
      color="primary"
      showActionButtons
      open
      scroll="body"
      okButtonText="Join"
      onClose={onClose}
      isLoading={isSubmitting}
      classes={{
        //
        dialogPaper: confirmationDialogClasses.dialogPaper,
        okButton: confirmationDialogClasses.joinButton,
        dialogTitle: confirmationDialogClasses.dialogTitle,
        dialogActions: confirmationDialogClasses.dialogActions,
        dialogContent: confirmationDialogClasses.dialogContent
      }}
    >
      You are joining the {documentType} document as the <strong>{workspaceRole}</strong>. You will be able to edit the {documentType} document and any of it's supporting documents
      that have been added.
    </ConfirmationDialog>
  );
}

export default JoinDialog;
