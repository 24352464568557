import React from 'react';

import { useDispatch } from 'react-redux';

import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import { WorkspaceNotificationModel } from 'src/containers/workspace/financial/detail/components/error-message-panel/models';
import { actionOpenLodgementVerificationDialog } from 'src/containers/workspace/shared/components/lodgement-verification-dialog/actions';
import { useStoreSelector } from 'src/hooks';
import LodgementErrorMessageNotification from './LodgementErrorMessageNotification';

interface Props {
  workspaceId: string;
  participantId: string;
  errors?: MessageModel[];
  warnings?: MessageModel[];
  workspaceErrors?: WorkspaceNotificationModel;
  isAutoTacCeased?: boolean;
  lodgementComplianceId?: string;
}

function LodgementErrorMessageNotificationContainer({
  //
  errors = [],
  warnings = [],
  ...rest
}: Props) {
  const dispatch = useDispatch();
  const isOpenLodgementVerificationDialog = useStoreSelector(state => state.lodgementVerificationDialog.isOpen);
  // if it is undefined, the open state is managed by MessageNotificationWithLocalStorage component
  // see the original logic here:
  // https://github.com/ticklesource/tickle-ui/commit/934bc41ca4e8737157c94d3bba78a2898e24d435#diff-3e68d8918b252b4aa23a6f57de4603c936bb93bd1b1b7aadbcee715b7c571d44R42-R47
  // boolean value (no matter )
  const open = isOpenLodgementVerificationDialog ? false : undefined;

  const onShowAllClick = () => {
    dispatch(actionOpenLodgementVerificationDialog());
  };

  return (
    <LodgementErrorMessageNotification //
      {...rest}
      errors={errors}
      warnings={warnings}
      open={open}
      onShowAllClick={onShowAllClick}
    />
  );
}

export default React.memo(LodgementErrorMessageNotificationContainer);
