import * as React from 'react';

import { FormikProps } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import GenericItemSelection from '@sympli-mfe/document-forms-framework/components/generic-item-selection';
import { CheckboxGroupClassKeys } from '@sympli/ui-framework/components/form/base-components/checkbox-group';

import { filterMortgagees, filterTitleReferences } from '../../helpers';
import { DischargeOfMortgage2_24_0Model, MortgageModel } from '../../models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  name: string;
  formikProps: FormikProps<DischargeOfMortgage2_24_0Model>;
  disabled: boolean;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function SectionMortgages({
  //
  name,
  formikProps,
  disabled,
  focusRef,
  classes
}: Props): JSX.Element {
  const {
    values: { partyBook, dealingNumbersSelectedInOtherDms },
    setValues
  } = formikProps;

  const onChange = React.useCallback(
    e => {
      setValues(updatedValues => {
        const mortgagees = filterMortgagees(updatedValues);
        const titleReferences = filterTitleReferences(updatedValues);
        return {
          ...updatedValues,
          mortgagees,
          titleReferences
        };
      });
    },
    [setValues]
  );

  const resolveItemLabel = React.useCallback(
    (mortgage: MortgageModel) => {
      const mortgagees = mortgage.mortgagees.map(x => x.partyBookId);
      const isSelectedWarning = mortgage.isSelected && dealingNumbersSelectedInOtherDms?.includes(mortgage.dealingNumber ?? '');
      return (
        <>
          <Typography component="p" className={classes.mortgageItem}>
            Mortgage being discharged:{' '}
            {
              <strong>
                {mortgage.dealingNumber} (
                {partyBook
                  ?.filter(x => mortgagees.includes(x.id))
                  .map(x => x.legalEntityName)
                  .join(', ')}
                )
              </strong>
            }
          </Typography>
          {isSelectedWarning && (
            <Typography component="p" className={classes.warning}>
              This mortgage is part of another Discharge of Mortgage document in this workspace
            </Typography>
          )}
        </>
      );
    },
    [classes.mortgageItem, classes.warning, dealingNumbersSelectedInOtherDms, partyBook]
  );

  const overrideClasses: Partial<ClassNameMap<keyof ReturnType<CheckboxGroupClassKeys>>> = {
    checkboxComponentRoot: classes.checkboxComponentRoot
  };

  return (
    <GenericItemSelection //
      name={name}
      data-testid={name}
      mode="multiple"
      disabled={disabled}
      focusRef={focusRef}
      onChange={onChange}
      resolveItemLabel={resolveItemLabel}
      noDataMessage="No mortgages found."
      overrideClasses={overrideClasses}
    />
  );
}

// no point to memoized since we are injecting entire FormikProps !!!
export default withStyles(styles)(SectionMortgages);
