import { isDate } from 'date-fns';
import dateFormat from 'dateformat';

import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { QLD_FORM24B_ADDRESS_FORM_CONFIG, QLD_FORM24B_PARTY_FORM_CONFIG } from './config';
import { qldForm24BFallbackMap } from './fallback';
import { ApiForm24B_2_24_1Model, Form24B_2_24_1Model, TransferorAddressTypeEnum, TransferorsAddressModel, TransferorsAddressOption } from './models';

class Converter implements IConverter<Form24B_2_24_1Model, ApiForm24B_2_24_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: QLD_FORM24B_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: QLD_FORM24B_PARTY_FORM_CONFIG
});

const convertTransferorsAddressFromApiToFormModel = (transferorsAddress: TransferorsAddressModel): TransferorsAddressOption[] => {
  return Object.values(TransferorAddressTypeEnum).map((value, index): TransferorsAddressOption => {
    return {
      key: value,
      isSelected:
        transferorsAddress.noAddressReason === value.toString() ||
        (value === TransferorAddressTypeEnum.HasAKnownAddress && (!!transferorsAddress.futureAddressId || !transferorsAddress.noAddressReason)),
      addressBookId: transferorsAddress.futureAddressId || null,
      id: index
    };
  });
};

const convertTransferorsAddressFromFormModelToApi = (options: TransferorsAddressOption[]): TransferorsAddressModel => {
  const selectedOption = options.find(item => item.isSelected);
  return {
    futureAddressId: selectedOption?.key === TransferorAddressTypeEnum.HasAKnownAddress ? selectedOption?.addressBookId : undefined,
    noAddressReason: selectedOption?.key !== TransferorAddressTypeEnum.HasAKnownAddress ? selectedOption?.key : undefined
  };
};

export function convertFromApiToFormModel(apiModel: ApiForm24B_2_24_1Model): Form24B_2_24_1Model {
  const { partyBook, addressBook, transferorsAddress, transaction } = apiModel;
  let formModel: Form24B_2_24_1Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(partyBook),
    addressBook: convertAddressBookFromApiToFormModel(addressBook),
    transferorsAddressOptions: convertTransferorsAddressFromApiToFormModel(transferorsAddress),
    transaction: {
      ...transaction,
      agreementDate: transaction.agreementDate ? new Date(transaction.agreementDate) : undefined
    }
  };

  formModel = applyDefaultMap(formModel, qldForm24BFallbackMap);

  return formModel;
}

export function convertFromFormToApiModel(formModel: Form24B_2_24_1Model, originalApiModel: ApiForm24B_2_24_1Model): ApiForm24B_2_24_1Model {
  formModel = applyVisibilityFallbackMap(formModel, qldForm24BFallbackMap);
  const {
    //
    partyBook: formPartyBook,
    addressBook: formAddressBook,
    transferorsAddressOptions,
    transaction,
    ...rest
  } = formModel;

  return {
    ...rest,
    transferorsAddress: convertTransferorsAddressFromFormModelToApi(transferorsAddressOptions),
    partyBook: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel.partyBook),
    addressBook: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel.addressBook),
    transaction: {
      ...transaction,
      agreementDate: transaction.agreementDate && isDate(transaction.agreementDate) ? dateFormat(transaction.agreementDate, DateFormatEnum.DATE) : undefined
    }
  };
}

export default new Converter();
