import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    suspendIcon: {
      height: 30,
      width: 30,
      verticalAlign: '-43%',
      paddingRight: 10,
      fill: theme.palette.secondary.main
    },
    reactivateIcon: {
      height: 30,
      width: 30,
      verticalAlign: '-43%',
      paddingRight: 10,
      fill: theme.palette.primary.main
    }
  });

export type ClassKeys = typeof styles;

export default styles;
