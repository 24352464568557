import * as yup from 'yup';

import {
  resolveTitleReferencesValidationSchema,
  yupTitleReferenceWithPartLandDescription
} from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';

import yupApplicants from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/sections/applicants/validationSchema';
import { yupTransactionInstruments } from 'src/containers/documents/scaffolded-form/shared/2-24/priority-notice/sections/transaction-instruments/validationSchema';
import { QLD_PN_CONFIG, QLD_PN_PARTY_FORM_CONFIG } from './config';
import { PriorityNoticeModel } from './models';

// this file was automatically generated from validationSchema.ts.mustache
const titleReferenceItemSchema = yupTitleReferenceWithPartLandDescription({ partDescriptionLimit: QLD_PN_CONFIG.titleReferences.partLandDescriptionLimit });

const titleReferenceSchema = resolveTitleReferencesValidationSchema(titleReferenceItemSchema);

export default yup.object<PriorityNoticeModel>().shape({
  applicants: yupApplicants(QLD_PN_PARTY_FORM_CONFIG),
  transactionInstruments: yupTransactionInstruments(titleReferenceSchema, QLD_PN_CONFIG, QLD_PN_PARTY_FORM_CONFIG)
});
