import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    message: {
      fontSize: 16,
      lineHeight: '19px',
      maxWidth: 505,
      paddingTop: 4,
      paddingLeft: 40,
      paddingRight: 40,
      textAlign: 'center'
    },
    uploadFile: {
      textDecoration: 'underline'
    },
    noFiles: {
      background: theme.palette.grey[50],
      marginTop: 10,
      paddingTop: 28,
      paddingBottom: 40,
      flexGrow: 1
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      height: 200,
      flexGrow: 1
    },
    file: {
      fontSize: '14px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[50],
      padding: '14px 10px',
      boxSizing: 'border-box',
      marginTop: 4
    },
    avatarColumn: {
      width: '24px',
      paddingBottom: '5px'
    },
    dateUploaded: {
      flex: 1,
      paddingLeft: '24px'
    },
    column: {
      flex: 1
    },
    detail: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px',
      paddingLeft: '28px'
    },
    fileName: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '15px',
      paddingLeft: 'unset'
    },
    uploadedTo: {
      paddingLeft: '6px'
    },
    descriptionClickable: {
      marginTop: -4
    },
    fileStatus: {
      paddingLeft: '24px',
      fontSize: 13,
      lineHeight: '13px'
    },
    fileStatusIcon: {
      marginRight: 4,
      lineHeight: '13px',
      fontSize: '13px',
      height: 17,
      width: 17
    },
    fileStatusMessage: {
      fontSize: 13
    },
    fileStatusContainer: {
      flexGrow: 1,
      paddingLeft: '24px',
      fontSize: 13,
      lineHeight: '13px'
    },
    error: {
      color: theme.palette.error.dark,
      '& svg': {
        fill: theme.palette.error.dark
      }
    }
  }),
  {
    name: 'WorkspaceFiles'
  }
);
