import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolveComboboxPlaceholder, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';

import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER } from '../../isRequiredChecks';
import { Mortgage_2_26_0_Model, PreRegisteredStandardTerm, StandardTermsDealingNumbersModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function StandardTermsDealingNumbers({ name, className }: Props): JSX.Element {
  const { values } = useFormikContext<Mortgage_2_26_0_Model>();
  const { termsAndConditions } = values;

  const { disabled } = useDocumentContext();
  const fieldName = createModelKeyAppender<StandardTermsDealingNumbersModel>(name);
  const value: StandardTermsDealingNumbersModel = _get(values, name);

  const standardTermsDealingNumbers = termsAndConditions.standardTermsDealingNumbers;
  const isRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS__DEALING_NUMBERS$_DEALING_NUMBER(value, termsAndConditions);

  const options = useMemo(() => {
    const preRegisteredStandardTerms: PreRegisteredStandardTerm[] =
      termsAndConditions.preRegisteredStandardTerms ??
      termsAndConditions.preRegisteredStandardTermsDealingNumbers?.map(x => {
        return { value: x };
      }) ??
      []; // preRegisteredStandardTermsDocumentReferences is obsolete, do not use in new document version. Use PreRegisteredStandardTerms instead

    const selectedDealingNumbers = standardTermsDealingNumbers.map(v => (v.termNumber ?? '').trim()).filter(v => v !== '');
    return (preRegisteredStandardTerms ?? [])
      .filter(x => !selectedDealingNumbers.includes(x.value) || x.value === (value.termNumber ?? '').trim())
      .map(({ value, description }) => ({ key: value, value: description ? `${value} (${description})` : value }));
  }, [termsAndConditions, standardTermsDealingNumbers, value.termNumber]);

  const placeholder = options.length ? resolveComboboxPlaceholder(isRequired) : resolvePlaceholder(isRequired);

  return (
    <div data-name={name} className={className}>
      <Field //
        aria-label="Dealing number"
        name={fieldName('termNumber')}
        component={SympliAutocompleteField}
        disabled={disabled}
        placeholder={placeholder}
        type="combobox"
        options={options}
        optionIdAttr="key"
        optionNameAttr="value"
        getCustomSelectedOptionLabel={(item: any) => item.key}
      />
    </div>
  );
}

export default React.memo(StandardTermsDealingNumbers);
