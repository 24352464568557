import { createAction, createAsyncAction } from 'typesafe-actions';

import { MessageApiResponse } from 'src/components/chat-window/models';
import { OnlineStatusEnum } from 'src/components/online-status-icon/models';

export interface ReceiveNewMessageActionModel {
  roomId: string;
  message: MessageApiResponse;
}

export const actionReceiveNewMessage = createAction(
  //
  'RECEIVE_NEW_MESSAGE'
)<ReceiveNewMessageActionModel>();

export const actionReceiveOnlineStatus = createAction(
  //
  'RECEIVE_ONLINE_STATUS'
)<//
{
  onlineStatusArray: Array<string>;
}>();

export const actionSwitchChatPerson = createAction(
  //
  'SWITCH_CHAT_PERSON'
)<//
{ selectedPersonId: string }>();

export const actionInitialiseChatData = createAction(
  //
  'INITIALISE_CHAT_DATA'
)<//
{ channelId: string }>();

export const actionSwitchUserStatus = createAction(
  //
  'SWITCH_USER_STATUS'
)<//
{ id: string; status: OnlineStatusEnum }>();

export interface MessageHistoryRequest {
  channelId: string;
  sourceId: string;
  destinationId: string;
  page: number;
  size: number;
}

export const actionFetchMessageHistory = createAsyncAction(
  //
  'FETCH_MESSAGE_HISTORY',
  'FETCH_MESSAGE_HISTORY_SUCCESS',
  'FETCH_MESSAGE_HISTORY_ERROR'
)<
  //
  MessageHistoryRequest,
  { messageHistory: MessageApiResponse[]; roomId: string },
  { error: Error }
>();
