import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { BaseAddress } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { ActionProhibitedEnum, ClaimCategoryEnum, ClaimPartyCapacityEnum, EstateOfInterestClaimedEnum } from './enums';

export interface BaseTitleReference {
  isPartLandAffected?: boolean; // we made this optional
  isSelected: boolean;
  landDescriptions?: LandDescription[];
  reference?: string;
}

interface LandDescription {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponent[];
  legalDescription?: string;
}

interface LandDescriptionComponent {
  name?: string;
  value?: string;
}

export interface ApiCaveatModel_2_21_1 {
  addressBook: BaseAddress[];
  partyBook: ApiDocumentPartyModel[];

  titleReferences?: BaseTitleReference[];
  extentOfProhibitions?: ExtentOfProhibition[];
  claimDetails: Claim;
  dutyAssessmentNumber?: string | null;
  caveators?: CaveatParty[] | null;

  detailsForServiceOfNotice?: ServiceOfNoticeDetails;
  proprietors?: Proprietor[];
  workspaceTitleReferences?: string[];

  caveatStatementsAgreed: boolean;
  supportingDocuments?: DocumentAttachmentItemModel[];
  //Refers to all attachments of the document
  attachments?: DocumentAttachmentItemModel[];
}

export const ADDRESS_BOOK_KEY = 'addressBook';
export const PARTY_BOOK_KEY = 'partyBook';

export type CaveatModel_2_21_1 = Omit<ApiCaveatModel_2_21_1, 'addressBook' | 'partyBook'> & {
  [ADDRESS_BOOK_KEY]: AddressBookEntityModel[];
  [PARTY_BOOK_KEY]: PartyModel[];
};

export interface CaveatParty {
  party?: ApiDocumentPartyModel;
  partyBookId: string | null; // reference to party store

  address?: BaseAddress;
  addressBookId: string | null; // reference to address store
}

export interface Claim {
  estateOrInterestClaimed: EstateOfInterestClaimedEnum | null; // number
  claimCategory?: ClaimCategoryEnum | null; // number
  detailsSupportingTheClaim?: string;
  claimParties?: ClaimParty[];
  claimDate?: Date | string | null;
  lrDocumentID?: string | null;
}

export interface ClaimParty {
  claimPartyCapacity: ClaimPartyCapacityEnum | null; // number

  party?: ApiDocumentPartyModel;
  partyBookId: string | null; // reference to party store
}

export interface DocumentAttachment {
  id: string;
  fileName: string;
  fileType: string;
}

export interface ServiceOfNoticeDetails {
  contacts?: CaveatParty[];
}

export interface ExtentOfProhibition {
  actionProhibitedOption?: ActionProhibitedEnum | null; // we made this optional
  lrDocumentId?: string;
  qualification?: string;
}

export interface Proprietor {
  isAffected: boolean;

  party?: ApiDocumentPartyModel;
  partyBookId: string | null; // reference to party store

  address?: BaseAddress;
  addressBookId: string | null; // reference to address store
}
