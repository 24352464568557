import React from 'react';

import classNames from 'classnames';
import { Field, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Formik from '@sympli/ui-framework/components/formik';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Button from '@sympli/ui-framework/components/sympli-button';
import msg from '@sympli/ui-framework/utils/messages';

import { getDescriptionLengthText } from './helpers';

export interface DeclineInviteFormValues {
  invitationId: string;
  workspaceId: string;
  note: string;
}

const fieldName = modelKey<DeclineInviteFormValues>();
const Message_MAX_LENGTH = 250;

interface DeclineInviteFormProps {
  invitationId: string;
  workspaceId: string;

  onCancel(): void;
  onSubmit(data: DeclineInviteFormValues): Promise<void>;
}

const NOTE_MIN_LENGTH = 10;
const validationSchema = yup.object<DeclineInviteFormValues>({
  invitationId: yup.mixed<string>(),
  workspaceId: yup.mixed<string>(),
  note: yup
    .string()
    .required()
    .min(NOTE_MIN_LENGTH, msg.LENGTH_MUST_BE_AT_LEAST_CHARACTERS(NOTE_MIN_LENGTH))
    .max(Message_MAX_LENGTH, ({ max, value }) => ` ${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${getDescriptionLengthText(value.length, max)}`)
});

function DeclineInviteForm({
  //
  invitationId,
  workspaceId,
  onCancel,
  onSubmit
}: DeclineInviteFormProps) {
  const initialValues: DeclineInviteFormValues = React.useMemo<DeclineInviteFormValues>(
    () => ({
      invitationId,
      workspaceId,
      note: ''
    }),
    [invitationId, workspaceId]
  );

  const fieldClasses = {
    inputLabel: 'text-[16px] font-[700] leading-[24px] tracking-[0.15px] text-[var(--neutral-1000)]',
    formControl: 'mt-[28px]',
    marginBottom: 'mb-[24px]'
  };

  return (
    <Formik //
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty, values, errors }: FormikProps<DeclineInviteFormValues>) => {
        const message = values.note ?? '';
        const descriptionLengthText = getDescriptionLengthText(message.length, Message_MAX_LENGTH);

        return (
          <Form className="flex grow flex-col justify-between">
            <div className="relative w-full">
              <Field //
                component={InputField}
                classes={fieldClasses}
                format="string"
                label="Decline Note"
                placeholder="Enter your message"
                name={fieldName('note')}
                multiline
                minRows={6}
                maxRows={10}
                fullWidth
                disabled={isSubmitting}
              />
              {!errors.note && (
                <div //
                  className={classNames('absolute bottom-[8px] left-0 text-[12px] font-[400] leading-[12px]', 'text-[var(--neutral-600)]')}
                >
                  {descriptionLengthText}
                </div>
              )}
            </div>
            <div className="flex justify-center gap-[8px]">
              <Button
                //
                disabled={isSubmitting}
                className="rounded-[34px] border-[2px]  px-[24px] py-[6px]"
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                //
                isLoading={isSubmitting}
                disabled={isSubmitting || !(isValid && dirty)}
                type="submit"
                variant="contained"
                color="primary"
                className="rounded-[34px] px-[24px] py-[6px]"
              >
                Decline
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(DeclineInviteForm);
