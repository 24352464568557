import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-26/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { WaAddressChange } from '../../../../components/address-justification';
import { WA_WOC_PARTY_FORM_CONFIG } from '../../config';
import { getSelectedCaveats } from '../../helper';
import { CaveatModel, WithdrawalOfCaveat2_26_0Model } from '../../models';

interface PartyBookValidationContext {
  withdrawalOfCaveats: CaveatModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: WithdrawalOfCaveat2_26_0Model): PartyBookValidationContext => {
  return {
    withdrawalOfCaveats: parentContext.withdrawCaveats
  };
};

const checkShouldValidateItem = ({ withdrawalOfCaveats }: PartyBookValidationContext, partyIdForCheck: PartyModel<WaNameChange, WaAddressChange>['id']) => {
  const selectedCaveats = getSelectedCaveats(withdrawalOfCaveats);
  return selectedCaveats.some(e => e.caveators.some(e => e.partyBookId === partyIdForCheck));
};

const yupPartyItem = resolvePartyModelValidationSchema<WaNameChange, PartyBookValidationContext, WaAddressChange>(WA_WOC_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<WaNameChange, WaAddressChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
