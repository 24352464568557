// this file was automatically generated from validationSchema.ts.mustache
import * as yup from 'yup';

import yupCaveators from './components/caveator-party-readonly-array/validationSchema';
import { WithdrawalOfCaveat2_19_1Model } from './models';
import yupPartyBook from './sections/party-book/validationSchema';
import yupSetOfOperativeWords from './sections/set-of-operative-words/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupWithdrawCaveats from './sections/withdraw-caveat/validationSchema';

export default yup.object<WithdrawalOfCaveat2_19_1Model>().shape({
  titleReferences: yupTitleReferences,
  withdrawCaveats: yupWithdrawCaveats,
  caveators: yupCaveators,
  setOfOperativeWords: yupSetOfOperativeWords,
  partyBook: yupPartyBook
});
