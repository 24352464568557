import * as React from 'react';

import { FormikProps, FormikValues } from 'formik';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import { AddButton } from '@sympli/ui-framework/components/formik/buttons';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import UserAvatar from 'src/components/avatars/user-avatar';
import DirectionsContext from '../../../../Directions.context';
import { getKeyValueMatchingNumberInObject } from '../../helper';
import styles, { ClassKeys } from '../../styles';
import ConditionalEditTooltip from '../conditional-edit-tooltip';

interface OwnProps<T extends FormikValues> {
  formikProps: FormikProps<T>;
  fieldName: string;
  defaultValue?: any;
  subscriberName: string;
  recordName: string;
  isArray?: boolean;
  currentParticipant: WorkspaceParticipantApiResponse;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, newFieldValues: Array<string | object>) => void;
}
type Props<T extends FormikValues> = OwnProps<T> & WithStyles<ClassKeys>;

function AddNewRecord<T extends FormikValues>({
  //
  formikProps,
  fieldName,
  defaultValue,
  subscriberName,
  classes,
  recordName,
  currentParticipant,
  isArray,
  onClick
}: Props<T>) {
  const { focusLabel } = React.useContext(DirectionsContext);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const isAddButtonDisabled = getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) >= 1;

  React.useEffect(() => {
    if (buttonRef.current && recordName === focusLabel) {
      buttonRef.current?.focus();
    }
  }, [focusLabel, recordName]);

  return (
    <FlexLayout>
      <FlexLayout flexDirection="column" justifyContent="center" className={classes.paymentTypeCol}>
        <UserAvatar src={currentParticipant.avatarUrl} text={subscriberName} className={classes.avatar} />
      </FlexLayout>
      <FlexLayout alignItems="center" className={classes.accountDetailCol}>
        <ConditionalEditTooltip showTooltip={Boolean(isAddButtonDisabled)}>
          <AddButton
            buttonRef={buttonRef}
            disabled={isAddButtonDisabled}
            formikProps={formikProps}
            fieldName={fieldName}
            defaultValue={defaultValue}
            isArray={isArray}
            onClick={onClick}
          >
            {`Add ${recordName}`}
          </AddButton>
        </ConditionalEditTooltip>
      </FlexLayout>
      <FlexLayout flexDirection="column" justifyContent="center" className={classes.amountCol}>
        <Input readOnly={true} fullWidth margin="none" aria-label="Amount" />
      </FlexLayout>
    </FlexLayout>
  );
}

export default withStyles(styles)(AddNewRecord);
