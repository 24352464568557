import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    headerContainer: {
      height: 50
    },
    subheaderLabel: {
      paddingLeft: 16,
      marginBottom: 8,
      fontSize: 12,
      lineHeight: '20px',
      background: theme.palette.grey[100]
    },
    backButton: {
      position: 'absolute',
      left: 0,
      height: 40,
      width: 40
    },
    backIcon: {
      fill: theme.palette.primary.main
    },
    readButton: {
      position: 'absolute',
      right: 40,
      height: 40,
      width: 40
    },
    readIcon: {
      fill: theme.palette.common.white
    },

    // conversation header
    conversationContainer: {
      height: '100%',
      background: theme.palette.grey[100],
      overflowY: 'auto'
    },
    conversationHeader: {
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '19px',
      padding: '10px 15px',
      background: theme.palette.grey[100]
    },
    conversationHeaderHighlight: {
      fontWeight: 'bold'
    },
    highlightDot: {
      position: 'absolute',
      marginTop: 5,
      marginLeft: -10,
      width: 6,
      height: 6,
      background: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.common.white}`,
      borderRadius: '50%'
    },

    // conversation message
    messageBox: {
      background: theme.palette.common.white,
      padding: 12,
      marginBottom: 10
    },
    messageBody: {
      padding: '10px 3px 0px',
      fontSize: 14,
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    messageHeaderBox: {
      marginLeft: 5,
      flexGrow: 1,
      overflow: 'hidden'
    },
    messageHeader: {
      fontSize: '13px',
      lineHeight: '15px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    messageSenderLabel: {
      fontWeight: 'bold'
    },
    messageRecipientLabel: {
      color: theme.palette.text.secondary
    },
    messageTime: {
      fontSize: 12,
      lineHeight: '14px',
      paddingLeft: 2,
      flexShrink: 0
    },

    // form
    multiline: {
      maxHeight: 68,
      overflowY: 'auto'
    },
    multilineInput: {
      overflowY: 'hidden'
    },
    submitButton: {
      height: 34,
      minWidth: 0,
      marginLeft: 8,
      paddingLeft: 10,
      paddingRight: 10
    },
    submitIcon: {
      fill: theme.palette.common.white
    }
  });

export type ClassKeys = typeof styles;

export default styles;
