import * as React from 'react';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';
import MessengerHeaderBox from './MessengerHeaderBox';
import MessengerHeaderBox101 from './MessengerHeaderBox101';

interface Props {
  children: React.ReactNode;
}

function MessengerHeaderBoxContainer({ children }: Props) {
  const isWorkspaceMessenger101Enabled = useFeatureFlag(FeatureToggleEnum.workspaceMessenger101Enabled);

  if (isWorkspaceMessenger101Enabled) {
    return <MessengerHeaderBox101>{children}</MessengerHeaderBox101>;
  }

  return <MessengerHeaderBox>{children}</MessengerHeaderBox>;
}

export default MessengerHeaderBoxContainer;
