import * as React from 'react';

import { FieldProps } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

type Props = FieldProps & WithStyles<ClassKeys>;

class WarningField extends React.PureComponent<Props> {
  render() {
    const {
      field: { value }
    } = this.props;

    return (
      <FlexLayout flexDirection="column" className={this.props.classes.errorWrapper}>
        <InputLabel>Warning</InputLabel>
        <FormHelperText>{value}</FormHelperText>
      </FlexLayout>
    );
  }
}
const styledComponent = withStyles(styles)(WarningField);
export default styledComponent;
