// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'Settlement rolled', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="UI-ELEMENTS/ILLUSTRATIONS/Waiting" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M130,65 C130,100.899 100.899,130 65,130 C29.101,130 0,100.899 0,65 C0,29.101 29.101,0 65,0 C100.899,0 130,29.101 130,65" id="Circle" fill="#EDEDEE"></path>
        <path d="M39,110 L91,110 L91,98 L39,98 L39,110 Z M39,32 L91,32 L91,20 L39,20 L39,32 Z" id="Frame" fill="#18CDB6"></path>
        <path
          d="M85.0003,31.9997 L85.0003,44.1057 C85.0003,46.5987 84.0093,48.9907 82.2463,50.7537 L67.9997,64.9997 L82.2467,79.2463 C84.0097,81.0093 84.9997,83.4013 84.9997,85.8943 L84.9997,98.0003 L44.9997,98.0003 L44.9997,85.8943 C44.9997,83.4013 45.9907,81.0093 47.7537,79.2463 L61.9997,64.9997 L47.7533,50.7537 C45.9903,48.9907 45.0003,46.5987 45.0003,44.1057 L45.0003,31.9997 L85.0003,31.9997 Z"
          id="Hour-glass"
          fill="#FFFFFF"
        ></path>
        <path
          d="M85,44.1057 L85,31.9997 L65,31.9997 L65,97.9997 L85,97.9997 L85,85.8947 C85,83.4007 84.009,81.0097 82.246,79.2467 L68,64.9997 L82.246,50.7537 C84.009,48.9907 85,46.5987 85,44.1057"
          id="Hour-glass-shadow"
          fill="#D6D6D6"
        ></path>
        <path
          d="M80.5,98 L49.5,98 L65,82.501 L80.5,98 Z M50.582,47.9258 C49.561,46.9048 49,45.5488 49,44.1058 L81,44.1058 C81,45.5488 80.438,46.9048 79.418,47.9258 L65,62.3428 L50.582,47.9258 Z"
          id="Sand"
          fill="#FF811A"
        ></path>
        <path
          d="M65,82.501 L65,98 L80.5,98 L65,82.501 Z M65,44.1055 L65,62.3425 L79.418,47.9255 C80.438,46.9055 81,45.5485 81,44.1055 L65,44.1055 Z"
          id="Sand-shadow"
          fill="#EF4623"
        ></path>
      </g>
    </svg>
  );
};
