// https://developer.okta.com/docs/reference/error-codes/#example-errors-for-openid-connect-and-social-login
export const OKTA_INVALID_ERROR_CODES = [
  'unauthorized_client',
  'access_denied',
  'unsupported_response_type',
  'unsupported_response_mode',
  'invalid_scope',
  'server_error',
  'temporarily_unavailable',
  'invalid_client',
  'login_required',
  'invalid_request',
  'user_canceled_request'
];

export interface ErrorResponse {
  error: string;
  error_description: string;
  error_uri: string;
  name: string;
  message: string;
}
