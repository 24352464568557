import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    lineItem: {
      width: '100%'
    },
    bottomBorder: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: 10
    },
    bottomPadding: {
      paddingBottom: 8
    },
    displayName: {
      fontWeight: 'bold',
      color: colors.NEUTRAL_1000
    },
    description: {
      color: colors.NEUTRAL_600
    }
  }),
  {
    name: 'BreakdownLineItem'
  }
);
