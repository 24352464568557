import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { createModelKeyAppender, getFormikError } from '@sympli-mfe/document-forms-framework/utils';
import { UpdateWorkspaceDocumentApiResponse } from '@sympli/api-gateway/models';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Formik from '@sympli/ui-framework/components/formik';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper, { WizardStepperClassKeys } from '@sympli/ui-framework/components/wizard-stepper';
import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { VISIBILITY_CHECK_CAVEATORS } from './checks';
import { convertFromApiToFormModel, convertFromFormToApiModel } from './conversion';
import { ApiCaveatModel, CaveatModel, ServiceOfNoticeDetails } from './models';
import ActionProhibited from './sections/action-prohibited';
import CaveatStatement from './sections/caveat-statement';
import Caveator from './sections/caveator';
import ClaimDetails from './sections/claim-details';
import { resolvePartyLookupOptions } from './sections/party-book/resolve-party-lookup-options';
import RegisteredProprietor from './sections/registered-proprietor';
import ServiceOfNotice from './sections/service-of-notice';
import styles, { ClassKeys } from './styles';
import validationSchema from './validationSchema';

type FormValues = CaveatModel;

export type Props = DocumentFormProps<FormValues, ApiCaveatModel> & WithStyles<ClassKeys>;

class NswCaveatForm extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    isLoading: false,
    disabled: false,
    submitActionLabel: 'Save'
  };

  private readonly wizardStepperClasses: Partial<ClassNameMap<keyof ReturnType<WizardStepperClassKeys>>> = {
    container: this.props.classes.wizardStepperContainer,
    nextButton: this.props.onSaveChanges ? this.props.classes.wizardStepperNextButton : undefined
  };

  private portalIdDialogs: string = _uniqueId();

  private formikProps: FormikProps<FormValues>;

  private focusOnTitleReferenceRef = React.createRef<ButtonBaseActions>();
  private focusOnCaveatorRef = React.createRef<ButtonBaseActions>();
  private focusOnServiceOfNoticeRef = React.createRef<ButtonBaseActions>();

  public componentDidMount(): void {
    this.focusOnFirst();
  }

  private focusOnFirst(): void {
    const focusOnFirstItem = this.focusOnTitleReferenceRef.current || this.focusOnCaveatorRef.current || this.focusOnServiceOfNoticeRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }

  private get isLoading() {
    return this.props.isLoading || this.formikProps?.isSubmitting || false;
  }

  private get disabled() {
    return this.props.disabled || this.isLoading;
  }

  private getInitialValues(): FormValues {
    return convertFromApiToFormModel(this.props.initialApiValues);
  }

  render() {
    return (
      <Formik //
        initialValues={this.getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={this.handleOnSubmit}
        onPostSubmit={this.props.onPostSubmit}
        // render={this.renderForm}
        // debounceValidation={true} // improve performance // fixme: setup any to resolve it first.
      >
        {(formikProps: FormikProps<FormValues>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private renderForm = (formikProps: FormikProps<FormValues>) => {
    this.formikProps = formikProps;
    this.props.onRenderForm(formikProps);

    const { classes, className } = this.props;

    const disabled = this.disabled;

    return (
      <>
        <Form className={classNames(classes.root, className)}>
          {this.renderSections(formikProps, disabled)}
          {this.renderActionsPanel(disabled)}
        </Form>
        <PortalTarget id={this.portalIdDialogs} />
      </>
    );
  };

  private renderSections(formikProps: FormikProps<FormValues>, disabled: boolean) {
    const partyOptions = resolvePartyLookupOptions(formikProps.values);
    return (
      <>
        {this.renderTitleReferencesSection(formikProps, disabled)}
        {this.renderCaveatorsSection(formikProps, disabled, partyOptions)}
        {this.renderServiceOfNoticeSection(formikProps, disabled, partyOptions)}
        {this.renderRegisteredProprietorsSection(formikProps, disabled)}
        {this.renderActionProhibitedSection(formikProps, disabled)}
        {this.renderClaimDetailsSection(formikProps, disabled, partyOptions)}
        {this.renderCaveatStatementSection(formikProps, disabled)}
      </>
    );
  }

  private renderTitleReferencesSection(formikProps: FormikProps<FormValues>, disabled: boolean) {
    const name: keyof FormValues = 'titleReferences';

    return (
      <SectionTitleReference //
        name={name}
        disabled={disabled}
        focusRef={this.focusOnTitleReferenceRef}
        data-name={name}
        data-testid="title-section"
      />
    );
  }

  private renderCaveatorsSection(formikProps: FormikProps<FormValues>, disabled: boolean, partyOptions: LookupItemModel[]): JSX.Element {
    const { classes } = this.props;
    const caveatModel = formikProps.values;
    const name: keyof FormValues = 'caveators';
    const error = getFormikError(formikProps, name); // track section level error

    return (
      <Section //
        title="Caveator"
        error={error}
        data-name={name}
      >
        {VISIBILITY_CHECK_CAVEATORS(caveatModel) ? (
          <Caveator //
            name={name}
            formikProps={formikProps}
            disabled={disabled}
            portalIdDialogs={this.portalIdDialogs}
            focusRef={this.focusOnCaveatorRef}
            partyOptions={partyOptions}
            data-testid="caveator"
          />
        ) : (
          <Typography className={classes.caveatorsUnselectableMessage} data-testid="caveators-unselectable-message">
            Action Prohibited #5 has been selected, which requires the Registered Proprietor(s) to be listed as the Caveator(s) - should you wish to change the Caveator(s), please
            select a different Action Prohibited{(caveatModel.proprietors?.length || 0) > 1 && ' or mark one of the proprietors as unaffected'}.
          </Typography>
        )}
      </Section>
    );
  }

  private renderServiceOfNoticeSection(formikProps: FormikProps<FormValues>, disabled: boolean, partyOptions: LookupItemModel[]) {
    const fieldName = createModelKeyAppender<ServiceOfNoticeDetails, FormValues>('detailsForServiceOfNotice');
    const name = fieldName('contacts');

    return (
      <Section //
        title="Service of notice"
        data-name={name}
      >
        <ServiceOfNotice //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          portalIdDialogs={this.portalIdDialogs}
          focusRef={this.focusOnServiceOfNoticeRef}
          partyOptions={partyOptions}
        />
      </Section>
    );
  }

  private renderRegisteredProprietorsSection(formikProps: FormikProps<FormValues>, disabled: boolean) {
    const name: keyof FormValues = 'proprietors';
    const error = getFormikError(formikProps, name); // track section level error

    return (
      <Section //
        title="Registered proprietor"
        error={error}
        data-name={name}
      >
        <RegisteredProprietor //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          portalIdDialogs={this.portalIdDialogs}
        />
      </Section>
    );
  }

  private renderActionProhibitedSection(formikProps: FormikProps<FormValues>, disabled: boolean) {
    const name: keyof FormValues = 'extentOfProhibitions';
    const error = getFormikError(formikProps, name); // track section level error

    return (
      <Section //
        title="Action prohibited"
        error={error}
        data-name={name}
        data-testid="action-prohibited"
      >
        <ActionProhibited //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
        />
      </Section>
    );
  }

  private renderClaimDetailsSection(formikProps: FormikProps<FormValues>, disabled: boolean, partyOptions: LookupItemModel[]) {
    const name: keyof FormValues = 'claimDetails';

    return (
      <Section //
        title="Claim details"
        data-name={name}
        data-testid="claim-details"
      >
        <ClaimDetails //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          portalIdDialogs={this.portalIdDialogs}
          partyOptions={partyOptions}
        />
      </Section>
    );
  }

  private renderCaveatStatementSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const name: keyof FormValues = 'caveatStatementsAgreed';

    return (
      <Section //
        title="Caveat statement"
        data-name={name}
        data-testid="caveat-statement"
      >
        <CaveatStatement //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
        />
      </Section>
    );
  }

  private renderActionsPanel(disabled: boolean) {
    const { hideActionsPanel, submitActionLabel, onSaveChanges } = this.props;

    if (hideActionsPanel) {
      return null;
    }

    return (
      <WizardStepper
        isLoading={this.isLoading}
        disabled={disabled}
        backLabel="Cancel"
        onBack={this.handleOnBackClick}
        nextLabel={submitActionLabel}
        classes={this.wizardStepperClasses}
      >
        {onSaveChanges && this.renderSaveChangesBtn(disabled)}
      </WizardStepper>
    );
  }

  private renderSaveChangesBtn(disabled: boolean) {
    return (
      <SympliButton color="primary" variant="outlined" className={this.props.classes.wizardStepperSaveChangesButton} disabled={disabled} onClick={this.handleOnSaveChanges}>
        Save changes
      </SympliButton>
    );
  }

  private handleOnSaveChanges = () => {
    const { values } = this.formikProps;
    const apiCaveatModel = convertFromFormToApiModel(values, this.props.initialApiValues);
    this.props.onSaveChanges!(apiCaveatModel, this.formikProps);
  };

  private handleOnSubmit = (values: FormValues, formikProps: FormikProps<FormValues>): Promise<UpdateWorkspaceDocumentApiResponse> => {
    const apiCaveatModel = convertFromFormToApiModel(values, this.props.initialApiValues);
    return this.props.onSave(apiCaveatModel, formikProps);
  };

  private handleOnBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.props.onCancel();
  };
}

export default withStyles(styles)(NswCaveatForm) as React.ComponentType<DocumentFormProps<FormValues, ApiCaveatModel>>;
