import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      borderTop: 'none !important',
      borderBottom: `solid 1px ${theme.palette.divider}`
    },
    landDescription: {
      paddingBottom: 24
    },
    arrowBox: {
      paddingBottom: 0
    }
  }),
  {
    name: 'LandTitle'
  }
);
