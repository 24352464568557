import { LodgementCaseStatusEnum, ParticipantStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { LodgementCase } from '@sympli/api-gateway/shared';

import { canWorkspaceBeArchived, resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { useWorkspaceParticipants } from '../../detail/reducers/workspaceParticipants';

type AdditionalOptionType = 'archivedOnly' | 'reassignOnly' | 'withdraw' | 'abandon';

export function useAdditionalOptionType(workspaceId: string, participantId: string, workspaceStatusId: WorkspaceStatusEnum, lodgementCases: LodgementCase[]): AdditionalOptionType {
  const workspaceParticipants = useWorkspaceParticipants(workspaceId).items;

  // if there are more than 1 participant that accepts the invite and hasn't withdrawn or abandoned, this should be withdraw
  const isWithdraw = workspaceParticipants.filter(participant => !participant.archivedStatus && participant.participantStatus.id === ParticipantStatusEnum.Accepted).length > 1;

  // for iop there is a chance there is no lc, we will use default one if there is no lc
  // for financial ws we will use PLC, otherwise take the first one
  const lodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(lodgementCases);
  const canBeOnlyArchived: boolean = canWorkspaceBeArchived(lodgementCaseStatusId, workspaceStatusId);
  const anyResponsibleLodgementCaseLocked = lodgementCases.some(d => d.responsibleParticipantId === participantId && d.isLocked);

  if (canBeOnlyArchived) {
    return 'archivedOnly';
  }

  if (anyResponsibleLodgementCaseLocked) {
    return 'reassignOnly';
  }

  if (isWithdraw) {
    return 'withdraw';
  }

  return 'abandon';
}
