import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';

// this has to be matched with backend enum
// MR - moved
// export enum DocumentTypeIdentifierEnum {
//   LodgementInstructions = 0,
//   CoRDHolderConsent = 1,
//   Mortgage = 2,
//   DischargeOfMortgage = 3,
//   Caveat = 4,
//   WithdrawalOfCaveat = 5,
//   PriorityNotice = 6,
//   ExtensionOfPriorityNotice = 7,
//   WithdrawalOfPriorityNotice = 8,
//   Transfer = 9,
//   SettlementStatement = 10,
//   NoticeOfSale = 11,
//   TransmissionApplication = 12,
//   WithdrawalCoRDHolderConsent = 13,
//   PaymentSummary = 14,
//   AdministrativeNotice = 15,
//   TransferOfControl = 16,
//   Nomination = 17,
//   NoticeOfAcquisition = 18,
//   Form24PartA = 19,
//   Form24PartB = 20,
//   WithdrawalOfNomination = 22,
//   ConvertPctAndNominateToTheLodgementCase = 23,
//   NominateEctToPaperInstrument = 24,
//   NominateEctToTheLodgementCase = 25,
//   Lease = 26,
//   SubLease = 27,
//   NoticeOfDeath = 28,
//   NationalMortgageOverInterestInLand = 29,
//   TransmissionApplicationByExecutorAdministratorTrustee = 30,
//   TransmissionApplicationByBeneficiaryDeviseeNextOfKin = 31,
//   DischargeOfMortgageOverInterestInLand = 32,
//   TransferOfInterest = 33,
//   CaveatOverInterestInLand = 34
// }

export const DocumentFormIdentifierDisplayMapping: Record<DocumentTypeIdentifierEnum, string> = {
  [DocumentTypeIdentifierEnum.LodgementInstructions]: 'Lodgement Instructions',
  [DocumentTypeIdentifierEnum.CoRDHolderConsent]: 'CoRD Holder Consent',
  [DocumentTypeIdentifierEnum.Mortgage]: 'Mortgage',
  [DocumentTypeIdentifierEnum.DischargeOfMortgage]: 'Discharge Of Mortgage',
  [DocumentTypeIdentifierEnum.Caveat]: 'Caveat',
  [DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: 'Withdrawal Of Caveat',
  [DocumentTypeIdentifierEnum.PriorityNotice]: 'Priority Notice',
  [DocumentTypeIdentifierEnum.ExtensionOfPriorityNotice]: 'Extension Of Priority Notice',
  [DocumentTypeIdentifierEnum.WithdrawalOfPriorityNotice]: 'Withdrawal Of Priority Notice',
  [DocumentTypeIdentifierEnum.Transfer]: 'Transfer',
  [DocumentTypeIdentifierEnum.SettlementStatement]: 'Settlement Statement',
  [DocumentTypeIdentifierEnum.NoticeOfSale]: 'Notice Of Sale',
  [DocumentTypeIdentifierEnum.TransmissionApplication]: 'Transmission Application',
  [DocumentTypeIdentifierEnum.WithdrawalCoRDHolderConsent]: 'Withdrawal CoRD Holder Consent',
  [DocumentTypeIdentifierEnum.PaymentSummary]: 'Payment Summary',
  [DocumentTypeIdentifierEnum.AdministrativeNotice]: 'Administrative Notice',
  [DocumentTypeIdentifierEnum.TransferOfControl]: 'Transfer Of Control',
  [DocumentTypeIdentifierEnum.Nomination]: 'Nomination',
  [DocumentTypeIdentifierEnum.NoticeOfAcquisition]: 'Notice Of Acquisition',
  [DocumentTypeIdentifierEnum.Form24PartA]: 'Form24PartA',
  [DocumentTypeIdentifierEnum.Form24PartB]: 'Form24PartB',
  [DocumentTypeIdentifierEnum.Form25]: 'Form 25',
  [DocumentTypeIdentifierEnum.WithdrawalOfNomination]: 'Withdrawal of Nomination',
  [DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase]: 'Convert pCT and Nominate to the Lodgement Case',
  [DocumentTypeIdentifierEnum.NominateEctToPaperInstrument]: 'Nominate eCT to Paper Instrument',
  [DocumentTypeIdentifierEnum.NominateEctToTheLodgementCase]: 'Nominate eCT to the Lodgement Case',
  [DocumentTypeIdentifierEnum.Lease]: 'Lease',
  [DocumentTypeIdentifierEnum.SubLease]: 'Sub-Lease',
  [DocumentTypeIdentifierEnum.NoticeOfDeath]: 'Notice Of Death',
  [DocumentTypeIdentifierEnum.NationalMortgageOverInterestInLand]: 'Mortgage over an interest in land',
  [DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee]: 'Transmission Application by Executor, Administrator or Trustee',
  [DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin]: 'Transmission Application by Beneficiary, Devisee or Next of Kin',
  [DocumentTypeIdentifierEnum.DischargeOfMortgageOverInterestInLand]: 'Discharge of Mortgage over an interest in land',
  [DocumentTypeIdentifierEnum.TransferOfInterest]: 'Transfer of Interest',
  [DocumentTypeIdentifierEnum.CaveatOverInterestInLand]: 'Caveat over an interest in land',
  [DocumentTypeIdentifierEnum.RequestTransferEctControlToRegistrar]: 'Request to transfer eCT control to Registrar of Titles',
  [DocumentTypeIdentifierEnum.RequestEctControlFromRegistrar]: 'Request eCT control from Registrar of Titles'
};
