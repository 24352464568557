import makeStyles from '@mui/styles/makeStyles';

import { colors } from '@sympli/ui-framework/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      width: 298,
      flexShrink: 0,
      height: 92,
      backgroundColor: colors.GREY_5,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textDecoration: 'none'
    },
    text: {
      color: colors.BLACK,
      fontSize: 18
    },
    avatar: {
      width: 60,
      height: 60,
      margin: 16
    }
  }),
  {
    name: 'ReportCard'
  }
);
