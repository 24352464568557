import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { RootFormContextProvider } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import { QLD_FORM24A_ADDRESS_FORM_CONFIG, QLD_FORM24A_ADDRESS_FORM_INITIAL_VALUES, QLD_FORM24A_PARTY_FORM_CONFIG, QLD_FORM24A_POSTAL_ADDRESS_FORM_CONFIG } from './config';
import converter from './conversion';
import { ApiForm24A_2_24_1Model, Form24A_2_24_1Model } from './models';
import RootForm, { Context } from './RootForm';
import validationSchema from './validationSchema';

export default function DocumentForm(
  //
  props: DocumentFormProps<Form24A_2_24_1Model, ApiForm24A_2_24_1Model>
): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<Form24A_2_24_1Model>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <RootFormContextProvider<Context>
        partyFormConfig={QLD_FORM24A_PARTY_FORM_CONFIG}
        addressFormConfig={QLD_FORM24A_ADDRESS_FORM_CONFIG}
        addressFormInitialValues={QLD_FORM24A_ADDRESS_FORM_INITIAL_VALUES}
        postalAddressFormConfig={QLD_FORM24A_POSTAL_ADDRESS_FORM_CONFIG}
      >
        <DocumentFormWrapper //
          {...props}
          converter={converter}
          validate={validate}
          validationSchema={validationSchema}
          RootForm={RootForm}
        />
      </RootFormContextProvider>
    </DndContextProvider>
  );
}
