import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { NoticeOfSaleModel } from './models';
import Consideration from './sections/consideration';
import DetailsForServiceOfNotices from './sections/details-for-service-of-notices';
import Property from './sections/property';
import { VISIBILITY_CHECK_CONSIDERATION, VISIBILITY_CHECK_PROPERTY } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<NoticeOfSaleModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfSaleModel>();
  const { values } = formikProps;
  const { partyBook, addressBook } = values;
  const focusRefServiceOfNotices = React.useRef<ButtonBaseActions>(null);
  const focusRefConsideration = React.useRef<ButtonBaseActions>(null);
  const focusRefProperty = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusRefServiceOfNotices.current || focusRefConsideration.current || focusRefProperty.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  return (
    <>
      <Form className={className}>
        <Section title="Name and address for service of notices">
          <DetailsForServiceOfNotices
            name={fieldName('detailsForServiceOfNotices')}
            dialogPortalId={dialogPortalId}
            focusRef={focusRefServiceOfNotices}
            partyBook={partyBook}
            addressBook={addressBook}
          />
        </Section>

        {VISIBILITY_CHECK_CONSIDERATION(values) && (
          <Section title="Details of transaction">
            <Consideration name={fieldName('consideration')} focusRef={focusRefConsideration} />
          </Section>
        )}

        {VISIBILITY_CHECK_PROPERTY(values) && (
          <Section title="Property details">
            <Property name={fieldName('property')} dialogPortalId={dialogPortalId} focusRef={focusRefProperty} />
          </Section>
        )}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
