import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG2 } from './config';
import { fallbackMap } from './fallback';
import {
  convertDeceasedProprietorGroupsFromFormToApi,
  convertPartiesToFormModel_2_21_1,
  convertTitlesFromFormToApi,
  filterDeceasedProprietorGroups,
  getDeceasedProprietorGroupsMap,
  updateDeceasedProprietorGroupsUnSelected
} from './helpers';
import { ApiTransmissionApplicationWithoutDuty2_21_1Model, TitleReferenceModel, TransmissionApplicationWithoutDuty2_21_1Model } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification, NswNameChange>({
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG2,
  nameChangeConversion: nswNameChangeConversion
});

export function convertFromApiToFormModel(apiModel: ApiTransmissionApplicationWithoutDuty2_21_1Model): TransmissionApplicationWithoutDuty2_21_1Model {
  const { titleReferences: apiTitleReferences } = apiModel;

  if (apiTitleReferences.length === 1) {
    apiTitleReferences[0].isSelected = true;
  }
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
  const partyBook = convertPartiesToFormModel_2_21_1(apiModel.partyBook || [], risInvolvedPartyIds);
  let formModel: TransmissionApplicationWithoutDuty2_21_1Model = {
    ...apiModel,
    titleReferences: apiTitleReferences,
    partyBook: partyBook,
    deceasedProprietorGroups: filterDeceasedProprietorGroups(apiTitleReferences, partyBook)
  };

  formModel = applyDefaultMap(formModel, fallbackMap);
  return formModel;
}

export function convertFromFormToApiModel(
  formModel: TransmissionApplicationWithoutDuty2_21_1Model,
  originalApiModel: ApiTransmissionApplicationWithoutDuty2_21_1Model
): ApiTransmissionApplicationWithoutDuty2_21_1Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: formPartyBook,
    deceasedProprietorGroups,
    titleReferences: titleReferencesFromForm,
    ...rest
  } = formModel;

  const newDeceasedProprietorGroups = updateDeceasedProprietorGroupsUnSelected(deceasedProprietorGroups);
  const relinquishingGroupMap = getDeceasedProprietorGroupsMap(newDeceasedProprietorGroups);
  const convertedTitleReferences: TitleReferenceModel[] = titleReferencesFromForm.map(titleReference => {
    const { deceasedProprietorGroups: deceasedProprietorGroupsFromForm } = titleReference;

    return {
      ...titleReference,
      deceasedProprietorGroups: titleReference.isSelected
        ? convertDeceasedProprietorGroupsFromFormToApi(
            //
            deceasedProprietorGroupsFromForm.map(group => group.deceasedProprietors.map(p => p.partyBookId!)),
            relinquishingGroupMap
          )
        : deceasedProprietorGroupsFromForm
    };
  });

  return {
    ...rest,
    attachments: formModel.supportingDocuments,
    partyBook: convertPartyBookFromFormToApiModel(formPartyBook || [], originalApiModel.partyBook || []),
    titleReferences: convertTitlesFromFormToApi(convertedTitleReferences)
  };
}

class Converter implements IConverter<TransmissionApplicationWithoutDuty2_21_1Model, ApiTransmissionApplicationWithoutDuty2_21_1Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
