import * as yup from 'yup';

import { RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';
import { resolveRelinquishingTenancyValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/validationSchema';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { MergeParty } from 'src/containers/documents/party-merge/model';
import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { BaseTransfer2_24_1Model } from '../../models';

interface TransferorContext<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}> {
  partyBook: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['partyBook'];
  selectedTitleReferences: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['titleReferences'];
  transferors: RelinquishingTenancyDetailModel;
}

export const MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE = 'The parties on the selected titles do not match; please remove the mismatched titles.';

export function resolveTransferorSchema<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(
  partyRole: string,
  getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined
): yup.Schema<RelinquishingTenancyDetailModel> {
  return memoizeSchemaWithContext<RelinquishingTenancyDetailModel, BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>, object>(
    resolveRelinquishingTenancyValidationSchema({
      //
      // relinquishingPartyRole: partyRole
    }).test(
      //
      'Transferor requires name change test',
      MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE,
      function test(this: yup.TestContext<TransferorContext<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>) {
        const { selectedTitleReferences, partyBook } = this.options.context!;
        if (selectedTitleReferences.length <= 1) return true;
        return PartyValidations.verifyJustification(partyBook, getLegalEntityName);
      }
    ),
    ({
      partyBook,
      titleReferences,
      transferors
    }: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>): TransferorContext<
      TPartLandAffectedModel,
      TDocumentPartyJustification,
      TNameChange
    > => ({
      //
      partyBook,
      transferors,
      selectedTitleReferences: titleReferences.filter(tr => tr.isSelected)
    })
  );
}
