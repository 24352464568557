import { add } from 'date-fns';

import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum DateTypeEnum {
  ONE_DAY,
  TWO_DAYS,
  ONE_WEEK,
  CUSTOM
}

export const DATE_OPTIONS: LookupEnumModel<DateTypeEnum>[] = [
  { id: DateTypeEnum.ONE_DAY, name: 'Postpone by 1 day' },
  { id: DateTypeEnum.TWO_DAYS, name: 'Postpone by 2 days' }
];

export const WEEK_OPTIONS: LookupEnumModel<DateTypeEnum>[] = [{ id: DateTypeEnum.ONE_WEEK, name: 'Postpone by 1 week' }];

export const CUSTOM_DATE_OPTIONS: LookupEnumModel<DateTypeEnum>[] = [{ id: DateTypeEnum.CUSTOM, name: 'Choose date' }];

export const MIN_DATE = add(new Date(), { days: 1 });
export const MAX_DATE = add(new Date(), { days: 90 });
