import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { CtControllerPartyModel, RequestEctControlFromRegistrarOfTitles_2_25_0_Model } from './models';

export default yup
  .object<RequestEctControlFromRegistrarOfTitles_2_25_0_Model>({
    titleReferences: resolveTitleReferencesValidationSchema(),
    ctController: yup.mixed<CtControllerPartyModel>()
  })
  .defined()
  .log();
