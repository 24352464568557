import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
});

export type ClassKeys = typeof styles;

export default styles;
