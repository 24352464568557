import * as yup from 'yup';

import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { IS_REQUIRED_CHECK_MCP_NUMBER } from '../../isRequiredChecks';
import { Mortgage2_26_0Model } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
interface McpNumberParentContext {
  hasAdditionalCovenants: boolean;
}

const REGEX_MCP = /^(([A-Z]{1}[0-9]{6}[ABC]?)|([A-Z]{0,2}[0-9]{1,6}[ABC]?\/[0-9]{4}))$/;
// path: mcpNumber

const contextResolver = ({ additionalCovenants, additionalAttachments }: Mortgage2_26_0Model) => {
  return {
    hasAdditionalCovenants: IS_REQUIRED_CHECK_MCP_NUMBER(additionalCovenants?.value, additionalAttachments)
  };
};

const yupMcpNumber = memoizeSchemaWithContext(
  yup.lazy(v =>
    !v
      ? yup.mixed<string>().testContextualRule({
          uniqueTestName: '"mcpNumber" required check',
          onlyIf: (parent: string, context: McpNumberParentContext): boolean => context.hasAdditionalCovenants,
          requirement: (parent: string) => isNotNullOrEmpty(parent),
          message: msg.REQUIRED
        })
      : yup
          .string()
          .trim()
          .default('')
          .max(15, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(15))
          .matches(
            REGEX_MCP,
            'Current format: prefix (1 character in range A-Z) + number (6 digits) + suffix (optional A, B or C). For example: N123456. Historic formats used prior to 1969: prefix (0..2 uppercase characters) + number (1..6 digits) + suffix (optional A, B or C) + ‘/’+year (4 digit year). For example TR123/1954'
          )
  ),
  contextResolver
);

export default yupMcpNumber;
