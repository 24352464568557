import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    noDataMessage: {
      fontSize: 14,
      fontStyle: 'italic',
      lineHeight: 'normal',
      padding: '15px 20px 20px 20px'
    }
  }),
  {
    name: 'Lessors'
  }
);
