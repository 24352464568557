import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    bottomDivider: {
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 4
      }
    },
    marginBottomIfNotLast: {
      '&:not(:last-child)': {
        marginBottom: 10
      }
    },
    noDeceasedGroupsMessage: {
      padding: theme.spacing(2.5),
      fontSize: 13,
      fontStyle: 'italic'
    },
    section: {
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      background: 'none'
    },
    deceasedGroupFirst: {
      borderTop: 'none'
    },
    jointTenantCheckbox: {
      paddingLeft: 20,
      paddingTop: 16,
      marginBottom: 5,
      fontWeight: 'bold'
    },
    jointTenantsRelinquishingRoleText: {
      fontSize: 14
    },
    errorMessage: {
      padding: '20px 20px 0px 20px',
      fontWeight: 'normal'
    }
  }),
  {
    name: 'DeceasedProprietorGroups'
  }
);
