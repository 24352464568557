import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import allDirectionsCommonStyle from '../../commonStyles';

const styles = (theme: Theme) => ({
  ...allDirectionsCommonStyle(theme),
  ...createStyles({
    description: {
      color: theme.palette.text.disabled
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
