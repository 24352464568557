import React, { useCallback } from 'react';

import addDays from 'date-fns/addDays';
import { useField } from 'formik';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getNestedErrorStringByPriority, resolveLabel } from '@sympli-mfe/document-forms-framework/utils';
import { ParsableDate } from '@sympli/ui-framework/components/form/base-components/date-picker';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { BenefitedLandReferenceModel, CovenantDetailModel, EncumbranceModel } from '../../models';
import BenefitedLandReference from './benefited-land-reference';
import CovenantDetail from './covenant-detail';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_COVENANT } from './visibilityChecks';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
}

export const YES_NO_LOOKUP_OPTIONS: LookupEnumModel<boolean>[] = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
];

function SectionEncumbrance({ name }: Props): JSX.Element {
  const classes = useStyles();
  const fieldName = createModelKeyAppender<EncumbranceModel>(name);
  const [, { value, error, touched, contextPath: fieldContextPath = name }] = useField<EncumbranceModel>(name);
  const [errorForSectionDisplay, dataErrorName] = getNestedErrorStringByPriority<EncumbranceModel>(fieldContextPath, error, touched, [
    fieldName('benefitedLandReferences'),
    fieldName('covenantDetails')
  ]);
  const { disabled } = useDocumentContext();

  const createNewBenefitedLandItem = useCallback((): BenefitedLandReferenceModel => ({ referenceType: null, referenceNumber: '' }), []);
  const renderBenefitedLandItem = useCallback(({ itemBinding }: DocumentArrayItemRenderProps) => <BenefitedLandReference name={itemBinding} />, []);

  const createNewCovenantItem = useCallback((): CovenantDetailModel => ({ referenceNumber: '' }), []);
  const renderCovenantItem = useCallback(({ itemBinding }: DocumentArrayItemRenderProps) => <CovenantDetail name={itemBinding} />, []);

  const minExpiryDate: ParsableDate = addDays(new Date(), 1);

  return (
    <Section //
      title="Covenant"
      error={errorForSectionDisplay}
      data-name={fieldContextPath}
      data-error-name={dataErrorName}
    >
      <FormGroup title="Is a covenant to be created?">
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('isCovenantToBeCreated')}
            component={RadioField}
            options={YES_NO_LOOKUP_OPTIONS}
            format="boolean"
            disabled={disabled}
          />
        )}
      </FormGroup>
      {VISIBILITY_CHECK_COVENANT(value) && (
        <>
          <DocumentFieldArray //
            arrayBinding={fieldName('benefitedLandReferences')}
            renderItem={renderBenefitedLandItem}
            itemTitle="Benefited land"
            disabled={disabled}
            itemStyle="formGroup"
            minItems={1}
            maxItems={20}
            createNewItem={createNewBenefitedLandItem}
            addButtonTitle="Add benefited land"
          />
          <DocumentFieldArray //
            arrayBinding={fieldName('covenantDetails')}
            renderItem={renderCovenantItem}
            itemTitle="Covenant details"
            disabled={disabled}
            itemStyle="formGroup"
            minItems={1}
            maxItems={20}
            createNewItem={createNewCovenantItem}
            addButtonTitle="Add covenant details"
          />
          <FormGroup //
            title={resolveLabel('Expiry date', false)}
            fieldAreaDirection="column"
          >
            {({ titleId }) => (
              <Field //
                aria-labelledby={titleId}
                name={fieldName('expiryDate')}
                component={DatePickerField}
                disabled={disabled}
                className={classes.quarterWidth}
                minDate={minExpiryDate}
                validationOverflowDirection="horizontal"
              />
            )}
          </FormGroup>
        </>
      )}
    </Section>
  );
}

export default React.memo(SectionEncumbrance);
