import React, { useCallback } from 'react';

import { useField, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';

import { filterSelectedPartySiblingsFromPartyBook, getProprietorAndCustomPartyBook } from '../../helpers';
import { ADDRESS_BOOK_KEY, BaseCaveatModel, PARTY_BOOK_KEY } from '../../models';

interface Props {
  name: string;
  disabled: boolean;
  dialogPortalId: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

function SectionCaveator({ name, disabled, dialogPortalId, focusRef, partyFormConfig, addressFormConfig, addressFormInitialValues, syncPartyReferences }: Props): JSX.Element {
  const { values } = useFormikContext<BaseCaveatModel>();
  const caveators = values.caveators;
  const createNewItem = (): TenancyPartyModel => ({
    isSelected: true,
    partyBookId: '',
    addressBookId: '',
    partyCapacity: {}
  });

  const renderItem = useCallback(
    ({ item, itemBinding, itemFocusRef, nextItemFocusRef }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
      return (
        <CaveatorItem //
          name={itemBinding}
          item={item}
          items={caveators}
          focusRef={itemFocusRef}
          nextItemFocusRef={nextItemFocusRef}
          disabled={disabled}
          dialogPortalId={dialogPortalId}
          partyFormConfig={partyFormConfig}
          addressFormConfig={addressFormConfig}
          addressFormInitialValues={addressFormInitialValues}
          syncPartyReferences={syncPartyReferences}
        />
      );
    },
    [addressFormConfig, addressFormInitialValues, syncPartyReferences, caveators, dialogPortalId, disabled, partyFormConfig]
  );

  return (
    <DocumentFieldArray
      arrayBinding={name}
      createNewItem={createNewItem}
      itemTitle="Caveator"
      addButtonTitle="Add caveator"
      minItems={1}
      maxItems={20}
      disabled={disabled}
      isSimpleType={false}
      itemStyle="formGroup"
      focusRef={focusRef}
      renderItem={renderItem}
    />
  );
}

interface CaveatorItemProps {
  name: string;
  disabled: boolean;
  dialogPortalId: string;
  items: TenancyPartyModel[];
  item: TenancyPartyModel;
  focusRef: React.RefObject<ButtonBaseActions>;
  nextItemFocusRef?: React.RefObject<ButtonBaseActions>;
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

function CaveatorItem({
  name,
  item,
  items,
  dialogPortalId,
  disabled,
  focusRef,
  nextItemFocusRef,
  partyFormConfig,
  addressFormConfig,
  addressFormInitialValues,
  syncPartyReferences
}: CaveatorItemProps): JSX.Element {
  const {
    values: { partyBook, titleReferences },
    setFieldValue
  } = useFormikContext<BaseCaveatModel>();
  const [, { value }, { setValue }] = useField<TenancyPartyModel>(name);
  const fieldName = createModelKeyAppender<TenancyPartyModel>(name);
  const proprietorAndCustomPartyBook = getProprietorAndCustomPartyBook(partyBook, titleReferences);
  const subscriberPartyBook = partyBook.filter(pb => pb.metadata?.source === DataSource.Subscriber);
  const filteredPartyOptions = filterSelectedPartySiblingsFromPartyBook(proprietorAndCustomPartyBook.concat(subscriberPartyBook), item, items);
  const addressFieldFocusRef = React.useRef<ButtonBaseActions>(null);

  return (
    <>
      <PartySelectField
        name={fieldName('partyBookId')}
        partyFormConfig={partyFormConfig}
        disabled={disabled}
        bookRef={PARTY_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        optionsOverride={filteredPartyOptions}
        focusRef={focusRef}
        nextFocusRef={addressFieldFocusRef}
        onSelect={async (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
          const existingPartyReference = await syncPartyReferences({ ...value, partyBookId: resolvedValue }, TenancyPartyFields.PartyBookId);
          setValue(existingPartyReference, false);
        }}
        handleCustomSubmit={async (isNew: boolean, partyBookId: string) => {
          if (isNew) {
            syncPartyReferences({ ...value, partyBookId }, TenancyPartyFields.PartyBookId);
          }
        }}
      />
      <AddressSelectField
        name={fieldName('addressBookId')}
        disabled={disabled}
        bookRef={ADDRESS_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        focusRef={addressFieldFocusRef}
        nextFocusRef={nextItemFocusRef}
        sharedAddressFormConfig={addressFormConfig}
        sharedInitialValuesForNew={addressFormInitialValues}
        onSelect={async (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
          setFieldValue(fieldName('addressBookId'), resolvedValue, false);
          syncPartyReferences({ ...value, addressBookId: resolvedValue }, TenancyPartyFields.AddressBookId);
        }}
        handleCustomSubmit={async (isNew: boolean, addressBookId: string) => {
          if (isNew) {
            syncPartyReferences({ ...value, addressBookId }, TenancyPartyFields.AddressBookId);
          }
        }}
      />
    </>
  );
}

export default SectionCaveator;
