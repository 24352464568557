import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { modelKey } from 'src/utils/formUtils';
import { PurchaserDirectionsFormModel } from '../../views/edit-directions/forms/purchaser-directions/models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  canEdit: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const titleId = _uniqueId();
const fieldName = modelKey<PurchaserDirectionsFormModel>(); // TODO check if the model is correct

const PurchaserPaysVendorBox = (props: Props) => {
  const { classes, canEdit } = props;

  return (
    <div className={classes.purchaserPaysVendorContainer}>
      <FlexLayout justifyContent="space-between">
        <div className={classes.row}>
          <span id={titleId}>
            Purchaser pays vendor <b className={classes.amount}>Amount ($): </b>
          </span>
          <Field
            aria-labelledby={titleId}
            className={classes.inputPurchaserPaysVendor}
            component={CurrencyInputField}
            name={fieldName('purchaserPayAmount')}
            inputProps={{ className: classes.textAlignLeft }}
            disabled={!canEdit}
          />
        </div>
      </FlexLayout>
    </div>
  );
};

const styledComponent = withStyles(styles)(PurchaserPaysVendorBox);

export default styledComponent;
