import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { Mortgage2_24_0Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Mortgage2_24_0Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        estateType: NO_FALLBACK,
        mortgagors: {
          $shouldAddDefaultItemToEmptyArray: false,
          $arrayItem: {
            $children: {
              partyBookId: [''],
              partyCapacity: NO_FALLBACK
            }
          }
        }
      }
    }
  },
  partyBook: NO_FALLBACK,
  mortgagors: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        partyBookId: [''],
        partyCapacity: NO_FALLBACK
      }
    }
  },
  mortgageeDetails: resolveTenancyFallback(),
  termsAndConditions: {
    $children: {
      preRegisteredStandardTermsDocumentReferences: NO_FALLBACK,
      standardTermsDocumentReferences: {
        $shouldAddDefaultItemToEmptyArray: true,
        $arrayItem: {
          $children: {
            documentReference: ['']
          }
        }
      },
      additionalTermsAndConditions: [''],
      termsAndConditionsAttachment: {
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: {
          $children: {
            id: NO_FALLBACK,
            fileName: [''],
            fileType: ['']
          }
        }
      }
    }
  },
  isFinancialWorkspace: NO_FALLBACK
};
