import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { MessageNotificationContainer } from '@sympli/ui-framework/components/message-notification';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';
import MessageList from '@sympli/ui-framework/components/message-notification/components/message-list';

import MessageNotificationV2 from 'src/components/message-notification';
import { HeaderModel } from './models';
import { useStyles } from './styles';

export interface ErrorMessagePanelProps {
  errorHeader?: string | HeaderModel;
  warningHeader?: string | HeaderModel;
  otherErrorHeader?: string | HeaderModel;
  otherErrorLink?: string;
  errors?: MessageModel[];
  warnings?: MessageModel[];
}

function convertOtherError(classes: Record<string, string>, otherErrorLink?: string) {
  if (!otherErrorLink) {
    return null;
  }

  return (
    <Link to={otherErrorLink} title="View workspace errors." className={classes.link}>
      <b>See details</b>
    </Link>
  );
}

function ErrorMessagePanel({
  //
  errorHeader = "Lodgement verification failed. We've found issues in your workspace.",
  warningHeader = "We've found warnings in your workspace.",
  otherErrorHeader,
  otherErrorLink,
  errors,
  warnings
}: ErrorMessagePanelProps) {
  const classes = useStyles();
  const otherError = useMemo(() => convertOtherError(classes, otherErrorLink), [classes, otherErrorLink]);

  return (
    <MessageNotificationContainer>
      {errors?.length ? (
        <MessageNotificationV2 //
          variant="error"
          classOverride="top-[50px]"
          expandableContent={
            <MessageList //
              messages={errors.map(item => item.message)}
              variant="error"
            />
          }
          {...(typeof errorHeader === 'string' ? { primary: errorHeader } : { primary: errorHeader?.primary, secondary: errorHeader?.secondary })}
        />
      ) : null}
      {warnings?.length ? (
        <MessageNotificationV2 //
          variant="warning"
          classOverride="top-[50px]"
          expandableContent={
            <MessageList //
              messages={warnings.map(item => item.message)}
              variant="warning"
            />
          }
          {...(typeof warningHeader === 'string' ? { primary: warningHeader } : { primary: warningHeader?.primary, secondary: warningHeader?.secondary })}
        />
      ) : null}
      {!!otherError && (
        <MessageNotificationV2 //
          variant="error"
          classOverride="top-[50px]"
          {...(!!otherError && { secondary: otherError })}
          {...(typeof otherErrorHeader === 'string' ? { primary: otherErrorHeader } : { primary: otherErrorHeader?.primary, secondary: otherErrorHeader?.secondary })}
        />
      )}
    </MessageNotificationContainer>
  );
}

export default React.memo(ErrorMessagePanel);
