import React from 'react';

import { Form } from 'formik';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { Form24B_2_24_1Model } from './models';
import PropertyData from './sections/property-data';
import SalePrice from './sections/sale-price';
import TransactionData from './sections/transaction-data';
import Transferors from './sections/transferors';
import TransferorsAddress from './sections/transferors-address';

type FormValues = Form24B_2_24_1Model;

export interface Context {
  partyFormConfig: PartyFormConfig;
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
  titleAddressFormConfig: AddressFieldFormConfig;
}

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, nextActionDisabled, dialogPortalId, nextActionLabel } = useDocumentContext();

  const fieldName = modelKey<FormValues>();

  return (
    <>
      <Form className={className}>
        <Transferors name={fieldName('transferors')} />
        <TransferorsAddress />
        <SalePrice />
        <PropertyData />
        <TransactionData />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
