import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import { WorkspaceInviteParticipantsFormModel } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/models';
import { getDefaultAdditionalInvitation } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/invite-participants/components/additional-invitations/helpers';

export function getInitialValues(
  invitableRoles: WorkspaceRoleEnum[],
  currentParticipantGroupId: string,
  currentParticipantReference: string
): WorkspaceInviteParticipantsFormModel {
  return {
    invitedRoles: {},
    invitations: invitableRoles.map(role => ({ ...getDefaultAdditionalInvitation(currentParticipantGroupId, currentParticipantReference), role })),
    additionalInvitations: []
  };
}
