import React from 'react';

import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import TitlesContainer from 'src/containers/workspace/titles';
import { TitleRouteParams } from 'src/containers/workspace/titles/models';
import { useRouterParams } from 'src/hooks';

export default function WorkspaceTitlesPageContainer() {
  const { workspaceId, participantId } = useRouterParams<TitleRouteParams>();
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  if (workspaceDetailState.error) {
    return <GeneralCrashedContent />;
  }

  return <TitlesContainer />;
}
