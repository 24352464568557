import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import { defaultMemoize } from 'reselect';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { ParticipantStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCaretLeft, IconPaperPlane } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import { UserAvatar } from 'src/components/avatars';
import Formik from 'src/components/formik';
import MessengerActionBox from 'src/containers/messenger/components/messenger-action-box';
import MessengerHeaderBox from 'src/containers/messenger/components/messenger-header-box';
import { modelKey } from 'src/utils/formUtils';
import getConversationSchema from '../../validation/getConversationSchema';
import { ConversationDetailFormModel, CreateConversationDocumentPayload, NewMessageStepEnum } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  // workspace participants
  participants: WorkspaceParticipantApiResponse[];
  // other
  onConversationCreated: (args: {
    //
    conversationId: string;
    recipientParticipantIds: string[];
    subject: string;
    message: string;
  }) => void;

  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  step: NewMessageStepEnum;
  selected?: string;
}

const fieldName = modelKey<ConversationDetailFormModel>();

class NewMessage extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    step: NewMessageStepEnum.SelectParticipant
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MessengerHeaderBox>
          <FlexLayout alignItems="center" justifyContent="space-around" className={classes.headerContainer}>
            <IconButton className={classes.backButton} onClick={this.handleOnLeftClick} aria-label="go back to list" size="large">
              <IconCaretLeft className={classes.backIcon} />
            </IconButton>
            <Typography variant="subtitle1" className={classes.headerLabel}>
              New message
            </Typography>
          </FlexLayout>
        </MessengerHeaderBox>
        {this.renderCurrentStep()}
      </React.Fragment>
    );
  }

  private renderCurrentStep() {
    const { step } = this.state;
    switch (step) {
      case NewMessageStepEnum.SelectParticipant:
        return this.renderParticipants();
      case NewMessageStepEnum.ConversationDetail:
      default:
        return this.renderConversationDetail();
    }
  }
  private renderParticipants() {
    const { classes, participants, participantId } = this.props;
    const { selected } = this.state;
    return (
      <React.Fragment>
        <Typography className={classes.subheaderLabel}>To:</Typography>
        {participants.map(item => {
          if (item.participantStatus.id !== ParticipantStatusEnum.Accepted || item.id === participantId) {
            return null;
          }
          return (
            <ListItem className={classNames(classes.participant)} button disableGutters key={item.id} data-id={item.id} onClick={this.handleOnParticipantClick}>
              <Radio color="primary" checked={selected === item.id} value={item.id} aria-label="Selected participant" />
              <div>
                <Typography className={classes.participantName}>{item.name}</Typography>
                <Typography className={classes.participantRole}>{item.workspaceRole.name}</Typography>
              </div>
              <UserAvatar className={classes.participantAvatar} src={item.avatarUrl} size="small" />
            </ListItem>
          );
        })}
        <Typography className={classes.additionalDesc}>
          {'Any personal information you send through Messenger will be handled in accordance with our '}
          <a href="https://www.sympli.com.au/policies/" target="_blank" rel="noopener noreferrer" className={classes.privacyLink}>
            Privacy Policy
          </a>
          {'. It will only be disclosed to the recipient, unless required to be disclosed to another party by law.'}
        </Typography>
        {selected != null && (
          <MessengerActionBox>
            <SympliButton color="primary" variant="contained" fullWidth onClick={this.handleOnContinueClick}>
              Continue
            </SympliButton>
          </MessengerActionBox>
        )}
      </React.Fragment>
    );
  }

  private getInitialValues = (): ConversationDetailFormModel => {
    return { subject: '', message: '' };
  };

  private renderConversationDetail() {
    const { workspaceId, participantId } = this.props;
    const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/conversations/create`;
    return (
      <Formik //
        method="post"
        action={action}
        getInitialValues={this.getInitialValues}
        validationSchema={getConversationSchema}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
      >
        {(formikProps: FormikProps<ConversationDetailFormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private memoizedParticipant = defaultMemoize((selected: string, participants: WorkspaceParticipantApiResponse[]) => participants.find(item => item.id === selected));

  private renderForm(formikProps: FormikProps<ConversationDetailFormModel>) {
    const { isSubmitting, errors } = formikProps;
    const { classes, participants } = this.props;
    const { selected = '' } = this.state;
    const selectedParticipant = this.memoizedParticipant(selected, participants);
    if (selectedParticipant == null) {
      return <Form />;
    }
    return (
      <Form className={classes.form}>
        <div className={classes.subjectBox}>
          <Typography className={classes.selectedParticipant} component={FlexLayout}>
            {selectedParticipant.name} ({selectedParticipant.workspaceRole.name})
          </Typography>
          <Field
            aria-label="Subject"
            name={fieldName('subject')}
            component={InputField}
            className={classNames(classes.subjectField, errors.subject && classes.marginBottom)}
            classes={{ formControl: classes.subjectMultiline, helperTextError: classes.helperTextError }}
            multiline
            // give the maxlength a little bit over than the 100 so we can show the error on over the limit
            inputProps={{ maxLength: 110, className: classes.multilineInput }}
            placeholder="Subject"
          />
        </div>
        <MessengerActionBox>
          <Field
            aria-label="message"
            name={fieldName('message')}
            placeholder="Type your message here..."
            component={InputField}
            margin="none"
            multiline
            classes={{ formControl: classes.multiline }}
            inputProps={{ maxLength: 5000, className: classes.multilineInput }}
          />
          <SympliButton type="submit" color="primary" variant="contained" className={classes.submitButton} disabled={isSubmitting} aria-label="Send message">
            {isSubmitting ? <InlineLoader size={18} className={classes.loader} /> : <IconPaperPlane className={classes.submitIcon} />}
          </SympliButton>
        </MessengerActionBox>
      </Form>
    );
  }
  private handleOnPreSubmit = (values: ConversationDetailFormModel): CreateConversationDocumentPayload => {
    const { selected = '' } = this.state;
    const { subject, message } = values;
    return { subject, message, recipientParticipantIds: [selected] };
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<ConversationDetailFormModel, string>) => {
    if (args.error) {
      // TODO error handling?
      return;
    }

    this.props.onConversationCreated({
      conversationId: args.response,
      recipientParticipantIds: [this.state.selected || ''],
      ...args.formValues
    });
  };

  private handleOnLeftClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (this.state.step) {
      case NewMessageStepEnum.ConversationDetail:
        this.setState({ step: NewMessageStepEnum.SelectParticipant });
        return;
      case NewMessageStepEnum.SelectParticipant:
      default:
        this.props.onBackClick(event);
    }
  };

  private handleOnContinueClick = () => {
    this.setState({ step: NewMessageStepEnum.ConversationDetail });
  };

  private handleOnParticipantClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const id = dataAttribute('id', event) || '';
    this.setState({ selected: id });
  };
}

const styled = withStyles(styles)(NewMessage);

export default styled;
