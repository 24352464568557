import { NotificationContentProps } from '../../models';
import { DocumentParticipationDataModel } from './models';

export function DocumentParticipationAddedNotificationContent({ log, participantId }: NotificationContentProps) {
  if (log.participantId === participantId) {
    return null;
  }
  const { WorkspaceRole, DocumentName } = log.dataObject as DocumentParticipationDataModel;

  return (
    <span>
      '{WorkspaceRole}' has joined the {DocumentName} document. This action may have caused changes to your workspace, please check your tasks.
    </span>
  );
}
