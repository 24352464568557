import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { VIEWPORT_FOR_SCREEN_HEIGHT_800 } from 'src/components/layout/constants';
import { WorkspaceProgressPanelNotificationTypeEnum } from './WorkspaceProgressNotificationPanel';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      left: -32,
      // this needs to ba aligned with MuiTab settings in our ui-framework
      top: -28,
      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        top: -8
      }
    }
  }),
  {
    name: 'WorkspaceProgressNotificationPanelBackground'
  }
);

export function WorkspaceProgressNotificationPanelBackground({
  //
  notificationType,
  className
}: {
  //
  notificationType: WorkspaceProgressPanelNotificationTypeEnum;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        //
        'w-[calc(100vw_-_calc(var(--small-content-left-panel)_+_calc(100vw_-_var(--small-content))_/_2)_-_19px)]',
        'medium-screen:w-[calc(100vw_-_calc(var(--medium-content-left-panel)_+_calc(100vw_-_var(--medium-content))_/_2)_-_19px)]',
        'large-screen:w-[calc(100vw_-_calc(var(--large-content-left-panel)_+_calc(100vw_-_var(--large-content))_/_2)_-_19px)]',
        'h-[144px]',
        classes.root,
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementInQueue && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress && 'bg-[var(--sunny-day-translucent)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.Lodged && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementError && 'bg-[var(--watermelon-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementCompleted && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementPaused && 'bg-[var(--watermelon-lite)]',
        className
      )}
    ></div>
  );
}
