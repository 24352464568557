import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    dialogActions: {
      margin: '0',
      padding: '28px 0px 12px'
    },
    dialogActionButton: {
      margin: 0
    },
    primaryButton: {
      marginLeft: 'auto'
    },
    customButton: {
      marginLeft: 10
    }
  });

export type ClassKeys = typeof styles;

export default styles;
