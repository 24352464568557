import { resolveMemoizedTenancyEditableValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing/validation-schema';

import { VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { checkIfJustifiedPartiesAreTheSame, isMortgagorNamesMatchesOrJustified } from '../../helpers';
import { Mortgage2_23_1Model } from '../../models';

const yupMortgagors = resolveMemoizedTenancyEditableValidation({
  isAddressRequired: false,
  partyFormConfig: VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG
})
  .testContextualRule({
    uniqueTestName: 'Mortgagors name mismatch test',
    message: 'Please be aware that the parties on the selected titles presently do not match.',
    requirement: (formModel: Mortgage2_23_1Model) => {
      return isMortgagorNamesMatchesOrJustified(formModel);
    }
  })
  .testContextualRule({
    uniqueTestName: 'Check if party is justified twice',
    message: 'Unable to change name, the proposed name has already been used in a justification.',
    requirement: (formModel: Mortgage2_23_1Model) => {
      return !checkIfJustifiedPartiesAreTheSame(formModel);
    }
  });

export default yupMortgagors;
