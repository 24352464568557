import * as React from 'react';

import { useDispatch } from 'react-redux';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { actionFetchWorkspaceFinancialAccounts } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceFinancialAccounts } from 'src/containers/workspace/shared/detail/reducers/workspaceDebitAccountsFeed';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useSafeDispatch } from 'src/hooks';
import { getGeneralAccountUsages } from '../../../../api';
import DirectionsContext from '../../../../Directions.context';
import { useDirectionsCategories } from '../../../../reducers/categories';
import DirectionPayeeDetail, { DirectionsPayeeDetailProps } from './DirectionPayeeDetail';

function DirectionPayeeDetailContainer(
  props: Omit<DirectionsPayeeDetailProps, 'isLoading' | 'directionsCategoriesDetail' | 'error' | 'currentParticipant' | 'dispatch' | 'setFocusLabel'>
) {
  const [usage, setUsage] = React.useState<GeneralAccountUsageApiResponse>([]);
  const { setFocusLabel } = React.useContext(DirectionsContext);
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceDetail = useWorkspaceDetail(props.workspaceId, props.participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(props.workspaceId);
  const { isLoading, detail: directionsCategoriesDetail, error } = useDirectionsCategories(props.workspaceId, props.participantId);
  const currentParticipant: WorkspaceParticipantApiResponse | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: props.participantId,
    workspaceParticipants: workspaceParticipantsState
  });
  const { items: financialAccounts } = useWorkspaceFinancialAccounts();

  React.useEffect(() => {
    dispatch(actionFetchWorkspaceFinancialAccounts.request());
  }, [dispatch]);

  React.useEffect(() => {
    getGeneralAccountUsages() //
      .then(apiResponse => {
        setUsage(apiResponse);
      });
  }, [dispatch]);

  return (
    <DirectionPayeeDetail //
      {...props}
      isLoading={isLoading}
      directionsCategoriesDetail={directionsCategoriesDetail}
      error={error}
      currentParticipant={currentParticipant}
      dispatch={dispatch}
      usage={usage}
      financialAccounts={financialAccounts}
      setFocusLabel={setFocusLabel}
      workspaceClusterDetail={workspaceDetail.detail?.linkedWorkspaceCluster}
    />
  );
}

export default React.memo(DirectionPayeeDetailContainer);
