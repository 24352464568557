import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import * as v from '@sympli-mfe/document-forms-framework/validation/validators';
import msg from '@sympli/ui-framework/utils/messages';

import { TRANSACTION_DETAIL_CONSIDERATION_TYPE_LOOKUP_OPTIONS, TransactionDetail$ConsiderationTypeEnum } from '../../enums';
import { END_OF_TODAY, NoticeOfAcquisitionDocumentModel, TransactionDetailModel } from '../../models';
import {
  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
  VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER,
  VISIBILITY_CHECK_TRANSACTION_DETAIL$DATE_OF_POSSESSION_TRANSFER,
  VISIBILITY_CHECK_TRANSACTION_DETAIL$SETTLEMENT_DATE,
  VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL
} from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
export function testTotalSalePrice(this: yup.TestContext<NoticeOfAcquisitionDocumentModel>, value: any) {
  const {
    propertyDetails,
    transactionDetail: { totalSalePrice }
  } = this.options.context!;
  const sumOfAllProperties = propertyDetails.reduce((sum, { salePricePerProperty }) => (sum += salePricePerProperty ?? 0), 0);
  return totalSalePrice === sumOfAllProperties;
}

const MAX_PRICE = 99999999999.99;

export const MSG_TOTAL_SALE_PRICE = 'Sum of all property sale prices must equal to total sale price';

const testLessThanTotalSalePrice: yup.TestOptions<TransactionDetailModel> = {
  name: 'Cannot exceed total sale price',
  message: 'Cannot exceed total sale price',
  test(this: yup.TestContext<TransactionDetailModel>, amount: number): boolean {
    const { totalSalePrice } = this.parent as TransactionDetailModel;
    if (typeof totalSalePrice !== 'number') {
      return true;
    }
    return amount <= totalSalePrice;
  }
};

// path: transactionDetail
const yupTransactionDetail = yup //
  .mixed<TransactionDetailModel>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
      validationWhenVisible: yup
        .object<TransactionDetailModel>()
        .required(msg.REQUIRED)
        .shape({
          considerationType: yup //
            .mixed<TransactionDetail$ConsiderationTypeEnum>()
            .required(msg.REQUIRED)
            .oneOf(getLookupValuesAllowingEmpty(TRANSACTION_DETAIL_CONSIDERATION_TYPE_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
          totalSalePrice: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v.number().test('sum of all property prices', MSG_TOTAL_SALE_PRICE, testTotalSalePrice)
              })
            ),
          gstAmount: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v
                  .integer(msg.VALUE_MUST_BE_WHOLE_DOLLARS_ONLY)
                  .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                  .max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
                  .test(testLessThanTotalSalePrice)
              })
            ),
          deposit: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v
                  .integer(msg.VALUE_MUST_BE_WHOLE_DOLLARS_ONLY)
                  .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                  .max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
                  .test(testLessThanTotalSalePrice)
              })
            ),
          businessGoodwillAmount: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v
                  .integer(msg.VALUE_MUST_BE_WHOLE_DOLLARS_ONLY) //
                  // .required(msg.REQUIRED) // allow empty as it defaults to 0 in the fallback
                  .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                  .max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
              })
            ),
          businessIntellectualPropertyAmount: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v
                  .integer(msg.VALUE_MUST_BE_WHOLE_DOLLARS_ONLY) //
                  // .required(msg.REQUIRED) // allow empty as it defaults to 0 in the fallback
                  .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                  .max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
              })
            ),
          goodsComponentAmount: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v
                  .integer(msg.VALUE_MUST_BE_WHOLE_DOLLARS_ONLY) //
                  // .required(msg.REQUIRED) // allow empty as it defaults to 0 in the fallback
                  .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                  .max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
              })
            ),
          contractRebateAmount: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck:
                  VISIBILITY_CHECK_GST_AMOUNT_AND_DEPOSIT_AND_BUSINESS_GOODWILL_AMOUNT_AND_BUSINESS_INTELLECTUAL_PROPERTY_AMOUNT_AND_GOODS_COMPONENT_AMOUNT_AND_CONTRACT_REBATE_AMOUNT,
                validationWhenVisible: v
                  .integer(msg.VALUE_MUST_BE_WHOLE_DOLLARS_ONLY) //
                  // .required(msg.REQUIRED) // allow empty as it defaults to 0 in the fallback
                  .min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0))
                  .max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
              })
            ),
          marketValue: yup //
            .mixed<number>()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER,
                validationWhenVisible: v.number().required(msg.REQUIRED).min(0, msg.VALUE_MUST_BE_AT_LEAST_X(0)).max(MAX_PRICE, msg.VALUE_MUST_BE_X_OR_LESS(MAX_PRICE))
              })
            ),
          valuerName: yup //
            .mixed<string>()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER,
                validationWhenVisible: yup.string().default('').trim().typeError(msg.INVALID_VALUE).nullable(true).max(150, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(150))
              })
            ),
          valuerLicenceNumber: yup //
            .mixed<string>()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_MARKET_VALUE_AND_VALUER_NAME_AND_VALUER_LICENCE_NUMBER,
                validationWhenVisible: yup.string().default('').trim().typeError(msg.INVALID_VALUE).nullable(true).max(20, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(20))
              })
            ),
          contractDate: yup //
            .date()
            .nullable(true)
            .default(null)
            .typeError(msg.INVALID_DATE)
            .max(END_OF_TODAY, msg.DATE_MUST_BE_TODAY_OR_PAST_DATE),
          isTransferDateSameAsSettlementDate: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
          dateOfPossessionTransfer: yup //
            .mixed<Date | null | string>()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_TRANSACTION_DETAIL$DATE_OF_POSSESSION_TRANSFER,
                validationWhenVisible: yup //
                  .date()
                  .nullable(true)
                  .default(null)
                  .typeError(msg.INVALID_VALUE)
                  .required(msg.REQUIRED)
              })
            ),
          settlementDate: yup //
            .mixed<Date | string | null>()
            .test(
              validateWhenVisible({
                visibilityCheck: VISIBILITY_CHECK_TRANSACTION_DETAIL$SETTLEMENT_DATE,
                validationWhenVisible: yup //
                  .date()
                  .nullable(true)
                  .default(null)
                  .typeError(msg.INVALID_DATE)
                  .required(msg.REQUIRED)
              })
            ),
          isPurchaseTermsSale: yup.boolean().typeError(msg.INVALID_VALUE).required(msg.REQUIRED)
        }),
      isObjectOrArray: true
    })
  );

export default yupTransactionDetail;
