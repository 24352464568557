import * as React from 'react';

export interface DialogControlProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DialogControlContext = React.createContext<DialogControlProps | null>(null);

export const useDialogControlContext = () => React.useContext<DialogControlProps | null>(DialogControlContext);
