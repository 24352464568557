import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  //
  (theme: Theme) => ({
    // * Confirmation dialog
    dialogRoot: {
      alignItems: 'flex-start'
    },
    dialogPaper: {
      marginTop: 40,
      width: 880,
      maxWidth: 880
    },
    dialogTitle: {
      padding: '36px 40px 12px'
    },
    dialogContent: {
      paddingLeft: 40,
      paddingRight: 40
    },
    form: {
      paddingTop: 26
    }
  }),
  { name: 'AddTitleDialog' }
);
