import * as yup from 'yup';

import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { memoizeSchema, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';

import { WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from '../../enums';
import { WithdrawalOfCaveat2_26_0Model } from '../../models';
import { VISIBILITY_CHECK_CAVEATORS } from '../../visibilityChecks';

const additionalAttachments = memoizeSchema(yupAdditionalAttachments(WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS));

export default yup //
  .mixed<WithdrawalOfCaveat2_26_0Model['additionalAttachments']>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_CAVEATORS,
      validationWhenVisible: additionalAttachments,
      isObjectOrArray: true
    })
  );
