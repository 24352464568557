import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 28 + 4, // we need to add padding of the td cell
      '@media (max-height: 800px)': {
        // same as tabs navigation margin
        marginBottom: 18 + 4 // we need to add padding of the td cell
      }
    },
    content: {
      height: 31,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      '& $title': {
        display: 'inline-block'
      },
      textDecoration: 'none'
    },
    icon: {
      fontSize: 18,
      fontWeight: 'bold'
    },
    title: {
      fontSize: 16
    },

    panelHeading: {}
  });

export type ClassKeys = typeof styles;

export default styles;
