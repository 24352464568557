import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import TransactionInstrument from '../../components/transaction-instrument';
import { PriorityNoticeConfig, TitleReferencesFieldConfig } from '../../config';
import { BasePriorityNoticeModel, TransactionInstrumentModel } from '../../models';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props<T extends PartLandAffectedModel> {
  fieldName: <TFieldName extends keyof BasePriorityNoticeModel<T> & string>(name: TFieldName) => TFieldName;
  name: string;
  dialogPortalId: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
  renderTitleReferenceComponent: (name: string, config: TitleReferencesFieldConfig) => JSX.Element;
  priorityNoticeConfig: PriorityNoticeConfig;
  partyFormConfig: PartyFormConfig;
}

function SectionTransactionInstruments<T extends PartLandAffectedModel>({
  //
  name,
  dialogPortalId,
  fieldName,
  focusRef,
  renderTitleReferenceComponent,
  priorityNoticeConfig,
  partyFormConfig
}: Props<T>): JSX.Element {
  const { nextActionDisabled } = useDocumentContext();
  const { values } = useFormikContext<BasePriorityNoticeModel<T>>();
  const { isDndMode } = useDndContext();

  const createNewItem = useCallback((): TransactionInstrumentModel<T> => {
    return {
      documentType: null,
      dealingNumber: '',
      receivingParties: [],
      titleReferences: values.transactionInstruments[0].titleReferences
    };
  }, [values]);

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<TransactionInstrumentModel<T>>) => (
      <TransactionInstrument //
        key={itemBinding}
        name={itemBinding}
        dialogPortalId={dialogPortalId}
        renderTitleReference={renderTitleReferenceComponent}
        priorityNoticeConfig={priorityNoticeConfig}
        partyFormConfig={partyFormConfig}
      />
    ),
    [dialogPortalId, renderTitleReferenceComponent, priorityNoticeConfig, partyFormConfig]
  );

  return (
    <div data-testid={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle="Dealing details"
        disabled={nextActionDisabled}
        itemStyle="formGroup"
        minItems={1}
        maxItems={20}
        createNewItem={createNewItem}
        addButtonTitle="Add new transaction dealing"
        focusRef={focusRef}
        dragNDropMode={true}
        dragNDropProps={{
          activated: isDndMode
        }}
      />
    </div>
  );
}

export default React.memo(SectionTransactionInstruments);
