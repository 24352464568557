import * as React from 'react';

import { Route, useNavigate } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';

import Logger from '@sympli/ui-logger';

import Switch from 'src/components/router/switch';
import environments from 'src/environments';
import AppCrashedPage from '../containers/app-crashed-page';
import LoggedIn from '../containers/logged-in';
import LoggedOut from '../containers/logged-out';
import LoadingProfile from '../containers/shared/loading-profile';
import Unauthorized from '../containers/unauthorized';
import AuthContainer from './AuthContainer';
import { Security as OktaSecurity } from './okta';
import UserManagerProvider from './UserManagerProvider';

const { BASENAME } = environments;

function useAuthClient() {
  const [info, setInfo] = React.useState<
    | {
        //
        status: 'pending' | 'rejected';
      }
    | { status: 'resolved'; client: OktaAuth }
  >({
    //
    status: 'pending'
  });

  React.useEffect(() => {
    async function loadApp() {
      try {
        const client = await UserManagerProvider.provide();

        //Don't do the setup, when redirecting from logged-in.
        if (!window.location.pathname.endsWith('/logged-in')) {
          // remove the '/app' because react-router navigation doesn't need BASENAME route
          client.setOriginalUri(`${window.location.pathname.slice(BASENAME.length)}${window.location.search}`);
          await client.start(); // start the service for token management
        }

        setInfo({
          //
          status: 'resolved',
          client
        });
      } catch (error) {
        Logger.captureException(error);
        setInfo({
          //
          status: 'rejected'
        });
      }
    }

    loadApp();
  }, []);

  return info;
}

function WithAuthentication({ children }: React.PropsWithChildren<{}>) {
  const authClient = useAuthClient();
  const navigate = useNavigate();

  switch (authClient.status) {
    case 'pending':
      return <LoadingProfile />;
    case 'resolved':
      const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri?: string) => {
        navigate(originalUri || '');
      };
      return (
        <OktaSecurity oktaAuth={authClient.client} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            <Route path="/logged-in" element={<LoggedIn />} />
            <Route path="/logged-out" element={<LoggedOut />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<AuthContainer>{children}</AuthContainer>} />
          </Switch>
        </OktaSecurity>
      );
    case 'rejected':
    default:
      return <AppCrashedPage />;
  }
}

export default WithAuthentication;
