import { ProfileUserReportsPermissionsState } from 'src/containers/dashboard/reports/reducers/profileUserReportsPermissions';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useRequestReduxDataOnMount } from 'src/hooks';
import { actionFetchProfileUserReportsPermissions } from '../actions';
import { getReportPermissionOptions } from '../helper';

export function useAccessibleReports() {
  const userProfile = useProfile().data!;

  const [{ detail: reportOptionsDetail, status }] = useRequestReduxDataOnMount<ProfileUserReportsPermissionsState>(
    actionFetchProfileUserReportsPermissions.request(userProfile!.userId),
    state => state.profileUserReportsPermissions
  );

  const reports = getReportPermissionOptions(reportOptionsDetail);

  return {
    status,
    reports
  };
}
