import { NSW_ADDRESS_FORM_CONFIG, NSW_PHYSICAL_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/nsw/2-21/config';
import {
  AddressFieldFormConfig,
  DEFAULT_OVERSEAS_ADDRESS_FORM_CONFIG,
  OverseasAddressFormConfig,
  PhysicalAddressFormConfig
} from '@sympli-mfe/document-forms-framework/components/address-field';
import {
  yupAuPostCodeMandatory,
  YupOverseasAddressSchemaMapModel,
  YupPhysicalAddressSchemaMapModel
} from '@sympli-mfe/document-forms-framework/components/address-field/validation-schema';
import { resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import NSW_MSG from '../../../messages';

const NSW_NOS_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG,
  streetNumber: {
    ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG.streetNumber,
    placeholder: resolvePlaceholder(true) // required
  },
  street: {
    ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG.street,
    placeholder: resolvePlaceholder(true) // required
  },
  postcode: {
    ...NSW_PHYSICAL_ADDRESS_FORM_CONFIG.postcode,
    placeholder: resolvePlaceholder(true) // required
  },
  customizeValidation: (nonJurisdictionSpecificSchemaMap: YupPhysicalAddressSchemaMapModel) => {
    const customiseValidation = NSW_PHYSICAL_ADDRESS_FORM_CONFIG.customizeValidation?.(nonJurisdictionSpecificSchemaMap)!;
    return {
      // please note the schemaMap passed in here is the schema passed by the component in mfe-document-forms-framework
      ...nonJurisdictionSpecificSchemaMap,
      floorNumber: customiseValidation.floorNumber,
      streetNumber: customiseValidation.streetNumber.required(NSW_MSG.REQUIRED_STREET_NUMBER),
      street: customiseValidation.street.required(msg.REQUIRED),
      postcode: yupAuPostCodeMandatory //cant chain using customiseValidation.postcode as it is yup.lazy()
    };
  }
};

const NSW_NOS_OVERSEAS_ADDRESS_FORM_CONFIG: OverseasAddressFormConfig = {
  ...DEFAULT_OVERSEAS_ADDRESS_FORM_CONFIG,
  postcode: {
    ...DEFAULT_OVERSEAS_ADDRESS_FORM_CONFIG.postcode,
    placeholder: resolvePlaceholder(true) // required
  },
  addressLine2: {
    ...DEFAULT_OVERSEAS_ADDRESS_FORM_CONFIG.addressLine2,
    placeholder: resolvePlaceholder(true) // required
  },
  customizeValidation: (schemaMap: YupOverseasAddressSchemaMapModel) => ({
    ...schemaMap,
    postcode: schemaMap.postcode.required(msg.REQUIRED),
    addressLine2: schemaMap.addressLine2.required(msg.REQUIRED)
  })
};

export const NSW_NOS_SERVICE_NOTICE_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...NSW_ADDRESS_FORM_CONFIG,
  physicalAddressFormConfig: NSW_NOS_PHYSICAL_ADDRESS_FORM_CONFIG,
  overseasAddressFormConfig: NSW_NOS_OVERSEAS_ADDRESS_FORM_CONFIG
};

export const NSW_NOTICE_OF_SALE_PROPERTY_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...NSW_ADDRESS_FORM_CONFIG,
  physicalAddressFormConfig: NSW_NOS_PHYSICAL_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: false,
  overseasAddressFormConfig: false
};
