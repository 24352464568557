import { NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, PartyCapacityEnum } from '@sympli-mfe/enums-2-23/sa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// TODO Check correctness of this
export const PARTY_CAPACITY_LOOKUP_OPTIONS: LookupEnumModel<PartyCapacityEnum>[] = restrictedLookupOptions(NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    PartyCapacityEnum.Administrator,
    PartyCapacityEnum.AdministratorAsLawfulAttorney,
    PartyCapacityEnum.AdministratorDeBonisNon,
    PartyCapacityEnum.AdministratorOnBehalfOfIncapacitatedExecutor,
    PartyCapacityEnum.Deceased,
    PartyCapacityEnum.Elder,
    PartyCapacityEnum.ElderUndischargedBankrupt,
    PartyCapacityEnum.Executor,
    PartyCapacityEnum.ExecutorLeaveReserved,
    PartyCapacityEnum.Manager,
    PartyCapacityEnum.Minor,
    PartyCapacityEnum.ProtectedPersonAgedAndInfirmPersons,
    PartyCapacityEnum.ProtectedPersonGuardianshipAndAdministration,
    PartyCapacityEnum.Receiver,
    PartyCapacityEnum.RegisteredConveyancerOnBehalfOf,
    PartyCapacityEnum.SolicitorOnBehalfOf,
    PartyCapacityEnum.SurvivingExecutor,
    PartyCapacityEnum.UndischargedBankrupt,
    PartyCapacityEnum.Younger,
    PartyCapacityEnum.YoungerMinor,
    PartyCapacityEnum.YoungerUndischargedBankrupt
  ]
});
