import React, { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { getFormikError } from '@sympli-mfe/document-forms-framework/utils';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { TenancyPartyModel } from '../../../../models';
import Mortgagee from '../../components/mortgagee';
import { DischargeOfMortgageDocument2_25_0Model } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  disabled: boolean;
  className?: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

function SectionMortgagees({ name, disabled, className, focusRef }: Props): JSX.Element {
  const formikProps = useFormikContext<DischargeOfMortgageDocument2_25_0Model>();
  const { values } = formikProps;
  const items: TenancyPartyModel[] = getIn(values, name) ?? [];
  const count = items.length;
  const hasMultipleItems = count > 1;
  const disableCheckbox = disabled || count <= 1;
  const classes = useStyles();

  const handleSelectAllProprietorsChange = (event: React.ChangeEvent<HTMLInputElement>, newAreAllAffected: boolean): void => {
    const { touched, values, setFieldValue, setFieldTouched } = formikProps;

    const items: TenancyPartyModel[] = getIn(values, name) ?? [];
    const updatedItems = items.map(proprietor => ({
      ...proprietor,
      isTransactingParty: newAreAllAffected
    }));

    setFieldValue(name, updatedItems);
    // we want to show errors immediately, therefore field needs to be marked as touched
    // prevent unnecessary render of Formik tree by calling setFieldTouched only when it makes sense
    !getIn(touched, name) && setFieldTouched(name, true, false /* don't trigger validation */);
  };

  const renderItem = useCallback(
    ({ itemBinding }: DocumentArrayItemRenderProps<TenancyPartyModel>) => <Mortgagee name={itemBinding} disabled={disabled} focusRef={hasMultipleItems ? undefined : focusRef} />, // when we have multiple items, focus should only happen in Select All mortgages checkbox
    [disabled, focusRef, hasMultipleItems]
  );

  return (
    <Section title={pluralize('Mortgagee', count)} data-name={name} data-testid={name} error={getFormikError(formikProps, name)} className={className}>
      {hasMultipleItems && (
        <FormGroup
          title={
            <Checkbox
              label="Select all mortgagees"
              name="select-all-mortgagees"
              format="boolean"
              className={classes.checkboxLabelRoot}
              checked={items.every(item => item.isTransactingParty)}
              onChange={handleSelectAllProprietorsChange}
              action={focusRef}
            />
          }
          paddingBottom={true}
        />
      )}
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={({ itemIndex, fieldName, itemFocusRef }) =>
          hasMultipleItems ? (
            <Field
              component={CheckboxField}
              label={hasMultipleItems ? `Mortgagee ${itemIndex + 1}` : 'Mortgagees'}
              name={fieldName('isTransactingParty')}
              disabled={disableCheckbox}
              className={classes.checkboxLabelRoot}
              action={itemFocusRef}
            />
          ) : null
        }
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </Section>
  );
}

export default React.memo(SectionMortgagees);
