// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title = 'Funds not balanced', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="Illustrations/Unbalanced-Funds" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M130.000093,65 C130.000093,100.898571 100.898664,130 65.0000929,130 C29.1015214,130 9.28571429e-05,100.898571 9.28571429e-05,65 C9.28571429e-05,29.1014286 29.1015214,0 65.0000929,0 C100.898664,0 130.000093,29.1014286 130.000093,65"
          id="Fill-1"
          fill="#F2F2F2"
        ></path>
        <polygon id="Fill-11" fill="#CECECE" points="72.0749714 96.8969857 57.2178286 96.8969857 61.8606857 26.3255571 67.4321143 26.3255571"></polygon>
        <path
          d="M87.8606857,95.0398429 C87.8606857,93.5012 86.6126857,92.2541286 85.0749714,92.2541286 L44.2178286,92.2541286 C42.6791857,92.2541286 41.4321143,93.5012 41.4321143,95.0398429 L41.4321143,97.8255571 L87.8606857,97.8255571 L87.8606857,95.0398429 Z"
          id="Fill-13"
          fill="#AAAAAA"
        ></path>
        <path
          d="M88.7892571,97.8255571 L87.8606857,97.8255571 L41.4321143,97.8255571 L40.5035429,97.8255571 C38.9649,97.8255571 37.7178286,99.0726286 37.7178286,100.611271 L37.7178286,108.039843 L91.5749714,108.039843 L91.5749714,100.611271 C91.5749714,99.0726286 90.3269714,97.8255571 88.7892571,97.8255571"
          id="Fill-15"
          fill="#333333"
        ></path>
        <path
          d="M91.5749714,100.611271 C91.5749714,99.0726286 90.3269714,97.8255571 88.7892571,97.8255571 L87.8606857,97.8255571 L87.8606857,95.0398429 C87.8606857,93.5012 86.6126857,92.2541286 85.0749714,92.2541286 L71.7852571,92.2541286 L67.4321143,26.3255571 L64.6464,26.3255571 L64.6464,108.039843 L91.5749714,108.039843 L91.5749714,100.611271 Z"
          id="Fill-17"
          fill="#333333"
          opacity="0.0984024774"
        ></path>
        <path
          d="M70.2178286,24.4684143 C70.2178286,27.5447714 67.7227571,30.0398429 64.6464,30.0398429 C61.5700429,30.0398429 59.0749714,27.5447714 59.0749714,24.4684143 C59.0749714,21.3920571 61.5700429,18.8969857 64.6464,18.8969857 C67.7227571,18.8969857 70.2178286,21.3920571 70.2178286,24.4684143"
          id="Fill-19"
          fill="#CECECE"
        ></path>
        <path
          d="M39.5749714,80.5925714 C39.3558286,80.5925714 39.1469,80.4607143 39.0586857,80.2443571 L27.5035429,51.8003571 L15.9484,80.2443571 C15.8323286,80.5303571 15.5091857,80.6677857 15.2222571,80.5507857 C14.9371857,80.4356429 14.7997571,80.1097143 14.9158286,79.8255714 L26.9872571,50.1112857 C27.1581143,49.6906429 27.8489714,49.6906429 28.0198286,50.1112857 L40.0912571,79.8255714 C40.2073286,80.1097143 40.0699,80.4356429 39.7848286,80.5507857 C39.7161143,80.5786429 39.6446143,80.5925714 39.5749714,80.5925714"
          id="Fill-21"
          fill="#CECECE"
        ></path>
        <path
          d="M113.860686,66.8118286 C113.641543,66.8118286 113.432614,66.6799714 113.3444,66.4636143 L101.789257,38.0196143 L90.2341143,66.4636143 C90.1180429,66.7505429 89.7949,66.8879714 89.5079714,66.7700429 C89.2229,66.6549 89.0854714,66.3289714 89.2015429,66.0448286 L101.272971,36.3305429 C101.443829,35.9099 102.134686,35.9099 102.305543,36.3305429 L114.376971,66.0448286 C114.493043,66.3289714 114.355614,66.6549 114.070543,66.7700429 C114.001829,66.7979 113.930329,66.8118286 113.860686,66.8118286"
          id="Fill-23"
          fill="#CECECE"
        ></path>
        <path
          d="M110.1464,65.6148071 C110.1464,70.2307357 106.405186,73.97195 101.789257,73.97195 C97.1733286,73.97195 93.4321143,70.2307357 93.4321143,65.6148071 C93.4321143,60.9988786 97.1733286,57.2576643 101.789257,57.2576643 C106.405186,57.2576643 110.1464,60.9988786 110.1464,65.6148071"
          id="Fill-25"
          fill="#18CDB6"
        ></path>
        <path
          d="M105.967829,53.2885786 C105.967829,56.1894357 104.096757,58.5405786 101.789257,58.5405786 C99.4817571,58.5405786 97.6106857,56.1894357 97.6106857,53.2885786 C101.789257,55.6044357 101.789257,51.0990071 105.967829,53.2885786"
          id="Fill-27"
          fill="#18CDB6"
        ></path>
        <path
          d="M104.166029,57.6058786 C105.254314,56.6578071 105.967457,55.0783071 105.967457,53.28895 C103.878171,52.1932357 102.833529,52.7726643 101.788886,53.3353786 L101.788886,73.97195 C106.404814,73.97195 110.146029,70.2307357 110.146029,65.6148071 C110.146029,61.8253071 107.6231,58.6300929 104.166029,57.6058786"
          id="Fill-29"
          fill="#2EA392"
        ></path>
        <path
          d="M104.840636,57.9015357 L98.7380643,57.9015357 C98.48085,57.9015357 98.2737786,57.6935357 98.2737786,57.43725 C98.2737786,57.1809643 98.48085,56.9729643 98.7380643,56.9729643 L104.840636,56.9729643 C105.096921,56.9729643 105.304921,57.1809643 105.304921,57.43725 C105.304921,57.6935357 105.096921,57.9015357 104.840636,57.9015357"
          id="Fill-31"
          fill="#2EA392"
        ></path>
        <path
          d="M114.898086,65.3255571 L88.6798714,65.3255571 C88.0335857,65.3255571 87.5860143,65.9690571 87.8014429,66.5782 C89.8452286,72.3381286 95.3284429,76.4684143 101.789443,76.4684143 C108.250443,76.4684143 113.732729,72.3381286 115.776514,66.5782 C115.992871,65.9690571 115.544371,65.3255571 114.898086,65.3255571"
          id="Fill-33"
          fill="#FF811A"
        ></path>
        <path
          d="M35.8606857,79.5433786 C35.8606857,84.1593071 32.1194714,87.9005214 27.5035429,87.9005214 C22.8876143,87.9005214 19.1464,84.1593071 19.1464,79.5433786 C19.1464,74.92745 22.8876143,71.1862357 27.5035429,71.1862357 C32.1194714,71.1862357 35.8606857,74.92745 35.8606857,79.5433786"
          id="Fill-35"
          fill="#18CDB6"
        ></path>
        <path
          d="M31.6821143,67.21715 C31.6821143,70.1180071 29.8110429,72.46915 27.5035429,72.46915 C25.1960429,72.46915 23.3249714,70.1180071 23.3249714,67.21715 C27.5035429,69.5330071 27.5035429,65.0275786 31.6821143,67.21715"
          id="Fill-37"
          fill="#18CDB6"
        ></path>
        <path
          d="M29.8812429,71.53445 C30.9686,70.5863786 31.6817429,69.0068786 31.6817429,67.2175214 C29.5924571,66.1218071 28.5478143,66.7012357 27.5031714,67.26395 L27.5031714,87.9005214 C32.1191,87.9005214 35.8603143,84.1593071 35.8603143,79.5433786 C35.8603143,75.7538786 33.3373857,72.5586643 29.8812429,71.53445"
          id="Fill-39"
          fill="#2EA392"
        ></path>
        <path
          d="M30.5549214,71.8301071 L24.45235,71.8301071 C24.1951357,71.8301071 23.9880643,71.6221071 23.9880643,71.3658214 C23.9880643,71.1095357 24.1951357,70.9015357 24.45235,70.9015357 L30.5549214,70.9015357 C30.8112071,70.9015357 31.0192071,71.1095357 31.0192071,71.3658214 C31.0192071,71.6221071 30.8112071,71.8301071 30.5549214,71.8301071"
          id="Fill-41"
          fill="#2EA392"
        ></path>
        <path
          d="M40.6133,79.2541286 L14.3941571,79.2541286 C13.7478714,79.2541286 13.3003,79.8976286 13.5157286,80.5067714 C15.5595143,86.2667 21.0427286,90.3969857 27.5037286,90.3969857 C33.9647286,90.3969857 39.4479429,86.2667 41.4908,80.5067714 C41.7071571,79.8976286 41.2586571,79.2541286 40.6133,79.2541286"
          id="Fill-43"
          fill="#FF811A"
        ></path>
        <path
          d="M40.6133,79.2541286 L27.5037286,79.2541286 L27.5037286,90.3969857 C33.9647286,90.3969857 39.4470143,86.2667 41.4908,80.5067714 C41.7071571,79.8976286 41.2586571,79.2541286 40.6133,79.2541286"
          id="Fill-45"
          fill="#D66A18"
        ></path>
        <path
          d="M114.898086,65.3255571 L101.789443,65.3255571 L101.789443,76.4684143 C108.250443,76.4684143 113.732729,72.3381286 115.776514,66.5782 C115.992871,65.9690571 115.543443,65.3255571 114.898086,65.3255571"
          id="Fill-47"
          fill="#D66A18"
        ></path>
        <path
          d="M106.86195,30.5648571 C106.419021,29.5926429 105.27595,29.1617857 104.300021,29.6056429 C103.330593,30.0457857 102.900664,31.187 103.336164,32.1573571 C103.528379,32.5984286 103.887736,34.3478571 102.378807,35.1287857 C100.34245,36.1827143 95.81195,33.8510714 93.0708071,32.7479286 C86.1455214,29.9631429 76.66295,26.1457857 62.5078071,28.7875714 L62.5068786,28.7885 C48.3535929,31.4302857 40.8860214,38.4103571 35.4315929,43.5063571 C33.2735929,45.5232143 29.8880214,49.3331429 27.6093071,49.0833571 C25.9211643,48.8995 25.62495,47.1389286 25.64445,46.6579286 C25.7001643,45.5956429 24.8876643,44.6865714 23.8253786,44.6262143 C22.7547357,44.564 21.8438071,45.3783571 21.7815929,46.4452857 C21.7620929,46.7730714 21.6515929,49.7008571 23.58395,51.5645 C24.3528071,52.3064286 25.7382357,53.1458571 27.9723786,52.9369286 C28.1720214,52.9174286 28.3698071,52.8895714 28.5648071,52.8533571 C30.5946643,52.4745 32.4053786,51.1652143 34.8308071,49.4111429 C39.8033071,45.8147857 47.3182357,40.3798571 64.0882357,37.2505714 C64.0882357,37.2505714 64.0882357,37.2496429 64.0891643,37.2496429 C80.8582357,34.1203571 89.8263786,36.478 95.7618071,38.0389286 C98.6561643,38.7994286 100.81695,39.3667857 102.848664,38.9879286 C103.043664,38.9517143 103.237736,38.9071429 103.42995,38.8532857 C105.589807,38.2432143 106.578736,36.9599286 107.029093,35.9905 C108.158236,33.5557857 106.99845,30.8638571 106.86195,30.5648571"
          id="Fill-49"
          fill="#AAAAAA"
        ></path>
      </g>
    </svg>
  );
};
