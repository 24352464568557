import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';

import { TenancyEditable as SectionTenancyEditable } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { NecdsPartyCapacityEnum, StateEnum } from '@sympli-mfe/enums-2-25/necds';

import { VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG, VIC_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG } from '../../config';
import { Mortgage2_25_1Model } from '../../models';

interface Props {
  name: string;
}

function SectionMortgagors({ name }: Props): JSX.Element {
  const {
    values: { mortgageeDetails }
  } = useFormikContext<Mortgage2_25_1Model>();

  const mortgageesPartyIds = useMemo(
    () =>
      mortgageeDetails.receivingProprietorGroups
        .flatMap(x => x.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgageeDetails]
  );

  return (
    <SectionTenancyEditable<NecdsPartyCapacityEnum> //
      name={name}
      data-name={name}
      data-testid={name}
      jurisdiction={StateEnum.VIC}
      componentLabels={VIC_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG}
      allowNewGroup={false}
      // party setup
      partyFormConfig={VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={mortgageesPartyIds}
    />
  );
}

export default React.memo(SectionMortgagors);
