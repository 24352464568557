import classNames from 'classnames';

import { MessageNotification, MessageNotificationComponentProps } from '@sympli/ui-framework/components/message-notification';

type Props = MessageNotificationComponentProps & {
  zIndex?: number;
  classOverride?: string;
};

function MessageNotificationV2(props: Props): JSX.Element {
  const { zIndex, classOverride, ...rest } = props;
  /**
   * For tailwind, it seems it won't load styles if we put calculation into [], like className=`z-[${number}]`
   * but it can understand if we put the calculation out of [] className like this:
   * const z = `z-[${number}]`;
   * className = `${z}`
   */

  const index = zIndex ? `z-[${zIndex}]` : 'z-[3]';

  return <MessageNotification {...rest} className={classNames(`top-[153px] ${index}`, classOverride)} />;
}

export default MessageNotificationV2;
