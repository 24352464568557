import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 2,
      width: '100%',
      height: 52,
      paddingTop: 22,
      paddingBottom: 22,

      background: colors.SUNNY_DAY,
      boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      paddingLeft: 'calc((100% - var(--small-content)) / 2)',
      paddingRight: 'calc((100% - var(--small-content)) / 2)',

      '@media (min-width:1536px) and (max-width:1920px)': {
        paddingLeft: 'calc((100% - var(--medium-content)) / 2)',
        paddingRight: 'calc((100% - var(--medium-content)) / 2)'
      },
      '@media (min-width:1920px)': {
        paddingLeft: 'calc((100% - var(--large-content)) / 2)',
        paddingRight: 'calc((100% - var(--large-content)) / 2)'
      }
    },

    title: {
      color: colors.WHITE,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px'
    },

    searchInputContainer: {
      width: 737,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    paper: {
      marginBottom: 16,
      background: colors.SUNNY_DAY,
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.15)'
    },

    listBox: {
      padding: 0
    },

    baseFontStyle: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: colors.WHITE
    },

    circularProgressSvg: {
      color: colors.WHITE
    },

    textField: {
      width: 400,
      top: 4,
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: colors.WHITE
      },
      '&:hover': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: colors.WHITE
        }
      },
      '& .Mui-focused': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: colors.WHITE
        }
      },
      '& label': {
        color: colors.WHITE,
        '&.Mui-focused': {
          color: colors.WHITE
        },
        top: -6,
        marginLeft: 13,
        paddingRight: 4,
        background: colors.SUNNY_DAY,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '14px',
        letterSpacing: '0.5px',
        // the label should never have any transform otherwise it will drop to the input search, this is to override MUI's default one
        transform: 'none'
      },
      '& .MuiInputBase-input.MuiAutocomplete-input': {
        paddingLeft: 14
      },
      '& .MuiInputBase-root': {
        paddingLeft: 0,
        height: 46,
        '& .MuiInputAdornment-positionStart': {
          paddingLeft: 8,
          paddingRight: 8
        },
        '& input': {
          color: colors.WHITE,
          //fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '20px',
          letterSpacing: '0.5px'
        },
        '& svg': {
          width: 20,
          height: 20
        }
      }
    },

    button: {
      color: colors.SUNNY_DAY,
      backgroundColor: colors.WHITE,

      '&:hover': {
        color: colors.SUNNY_DAY_DARK,
        backgroundColor: colors.SUNNY_DAY_TRANSLUCENT
      },

      '&.Mui-disabled': {
        color: colors.WHITE,
        backgroundColor: colors.SUNNY_DAY_LUMINOUS
      }
    },
    unassignButton: {
      backgroundColor: colors.SUNNY_DAY,
      color: colors.WHITE
    },

    closeIcon: {
      width: 30,
      height: 30,
      cursor: 'pointer'
    }
  }),
  {
    name: 'ReassignBar'
  }
);
