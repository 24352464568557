import React from 'react';

import pluralize from 'pluralize';
import { NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import { ComplianceLevelEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import MessageGrid from '@sympli/ui-framework/components/message-notification/components/message-grid';

import MessageNotificationV2 from 'src/components/message-notification';
import { DocumentLodgementCompliance } from 'src/containers/shared/document-list/models';
import { convertComplianceToMessageGridModel } from 'src/containers/workspace/shared/detail/helpers';
import { useStyles } from './styles';

interface Props {
  id: string;
  documentLodgementCompliances?: DocumentLodgementCompliance[];
  resolveIssuesLink?: string;

  onShowVerificationResultsClick(): void;
}

function LandRegistryNotifications({
  //
  id,
  documentLodgementCompliances,
  resolveIssuesLink,
  onShowVerificationResultsClick
}: Props) {
  const classes = useStyles();
  const warnings = documentLodgementCompliances?.filter(item => item.complianceLevel === ComplianceLevelEnum.Warning).map(convertComplianceToMessageGridModel);
  const errors = documentLodgementCompliances?.filter(item => item.complianceLevel === ComplianceLevelEnum.Error).map(convertComplianceToMessageGridModel);
  return errors?.length || warnings?.length ? (
    <div id={id}>
      {errors?.length ? (
        <MessageNotificationV2 //
          defaultCollapsed
          className={classes.notificationRoot}
          inline={true}
          variant="error"
          primary={`${pluralize('error', errors?.length, true)} found on this document`}
          expandableContent={
            <div className={classes.notificationContent}>
              <MessageGrid //
                variant="error"
                messageHeader="Land registry verification response"
                remarkHeader="Action required"
                messages={errors}
              />
              <Divider className={classes.dividerError} />
              <div className={classes.assistanceFooter}>
                {resolveIssuesLink ? ( // users with write permission will have this link populated (see resolveSignDocuments logic)
                  <>
                    <NavLink to={resolveIssuesLink}>Resolve issues</NavLink>
                    {' | '}
                  </>
                ) : null}
                <ButtonLink onClick={onShowVerificationResultsClick} color="inherit">
                  Show verification results
                </ButtonLink>
                <br />

                <span>
                  If you require further assistance please contact customer support on <strong>1300 SYMPLI (1300 796 754)</strong>.
                </span>
              </div>
            </div>
          }
        />
      ) : null}

      {warnings?.length ? (
        <MessageNotificationV2 //
          defaultCollapsed
          className={classes.notificationRoot}
          inline={true}
          variant="warning"
          primary={`${pluralize('warning', warnings?.length, true)} found on this document`}
          expandableContent={
            <div className={classes.notificationContent}>
              <MessageGrid //
                variant="warning"
                messageHeader="Land registry verification response"
                remarkHeader="Action required"
                messages={warnings}
              />
              <Divider className={classes.dividerWarning} />
              <div className={classes.assistanceFooter}>
                <ButtonLink onClick={onShowVerificationResultsClick} color="inherit">
                  Show verification results
                </ButtonLink>
                <br />
                <span>
                  If you require further assistance please contact customer support on <strong>1300 SYMPLI (1300 796 754)</strong>.
                </span>
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  ) : null;
}
export default React.memo(LandRegistryNotifications);
