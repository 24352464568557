import _cloneDeep from 'lodash-es/cloneDeep';

import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructions2_19_1Model, LodgementInstructions2_19_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions2_19_1Model, ApiLodgementInstructions2_19_1Model> {
  public originalApiModel: ApiLodgementInstructions2_19_1Model;
  constructor(initialValues: ApiLodgementInstructions2_19_1Model) {
    this.originalApiModel = _cloneDeep(initialValues);
  }
  fromApiToFormModel(apiValues: ApiLodgementInstructions2_19_1Model, context: IConverterContext): LodgementInstructions2_19_1Model {
    let formValues = applyDefaultMap(apiValues, fallbackMap);
    formValues.isResponsibleParticipant = context.currentParticipant?.isResponsibleParticipant ?? false;
    const selectedPriorityNotice = formValues.priorityNotices?.find(x => x.isSelected);
    if (selectedPriorityNotice) {
      formValues.selectedPriorityNoticeId = selectedPriorityNotice.dealingNumber;
    }
    return {
      ...formValues,
      isSameRoleAsResponsible: context.participants?.find(p => p.id === formValues.responsibleParticipantId)?.workspaceRole.id === context.currentParticipant?.workspaceRole.id,
      currentParticipantId: context.currentParticipant?.id ?? ''
    };
  }
  fromFormToApiModel(formValues: LodgementInstructions2_19_1Model, _: ApiLodgementInstructions2_19_1Model): ApiLodgementInstructions2_19_1Model {
    const { selectedPriorityNoticeId, isSameRoleAsResponsible, currentParticipantId, ...rest } = formValues;

    // If participant was responsible and then responsibleParticipantId is changed, only persist the responsibleParticipantId change
    if (formValues.isResponsibleParticipant && currentParticipantId !== formValues.responsibleParticipantId) {
      return { ...this.originalApiModel, responsibleParticipantId: rest.responsibleParticipantId };
    }

    let apiModel = applyVisibilityFallbackMap(rest, fallbackMap);
    apiModel.priorityNotices = apiModel.priorityNotices?.map(({ dealingNumber }) => {
      return { dealingNumber: dealingNumber, isSelected: dealingNumber === selectedPriorityNoticeId };
    });

    return apiModel;
  }
}

export default Converter;
