// tslint:disable:max-line-length
import * as React from 'react';

export default (props: React.SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title, ...rest } = props;
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120 60C120 93.1375 93.1375 120 60 120C26.8625 120 0 93.1375 0 60C0 26.8625 26.8625 0 60 0C93.1375 0 120 26.8625 120 60Z"
        fill="#EDEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.9231 84.9231H23.0769C20.0178 84.9231 17.5385 82.4437 17.5385 79.3846V29.5385C17.5385 26.4794 20.0178 24 23.0769 24H96.9231C99.9821 24 102.462 26.4794 102.462 29.5385V79.3846C102.462 82.4437 99.9821 84.9231 96.9231 84.9231Z"
        fill="#333333"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.1538 76.6155H97.8461V28.6155H22.1538V76.6155Z" fill="#18CDB6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.9231 26.7693C60.9231 27.2788 60.5095 27.6923 60 27.6923C59.4904 27.6923 59.0769 27.2788 59.0769 26.7693C59.0769 26.2597 59.4904 25.8462 60 25.8462C60.5095 25.8462 60.9231 26.2597 60.9231 26.7693Z"
        fill="#9E9E9E"
      />
      <path
        d="M95.4999 80.7691C95.4999 81.2573 95.1035 81.6538 94.6153 81.6538C94.1272 81.6538 93.7307 81.2573 93.7307 80.7691C93.7307 80.281 94.1272 79.8845 94.6153 79.8845C95.1035 79.8845 95.4999 80.281 95.4999 80.7691Z"
        fill="#FF8200"
        stroke="#FF8200"
      />
      <path
        d="M89.9615 80.7691C89.9615 81.2573 89.565 81.6538 89.0769 81.6538C88.5887 81.6538 88.1923 81.2573 88.1923 80.7691C88.1923 80.281 88.5887 79.8845 89.0769 79.8845C89.565 79.8845 89.9615 80.281 89.9615 80.7691Z"
        fill="#FF8200"
        stroke="#FF8200"
      />
      <path
        d="M84.423 80.7691C84.423 81.2573 84.0266 81.6538 83.5384 81.6538C83.0503 81.6538 82.6538 81.2573 82.6538 80.7691C82.6538 80.281 83.0503 79.8845 83.5384 79.8845C84.0266 79.8845 84.423 80.281 84.423 80.7691Z"
        fill="#FF8200"
        stroke="#FF8200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2308 97.8461H44.7693C44.005 97.8461 43.3846 97.2258 43.3846 96.4615C43.3846 95.6972 44.005 95.0769 44.7693 95.0769H75.2308C75.9951 95.0769 76.6154 95.6972 76.6154 96.4615C76.6154 97.2258 75.9951 97.8461 75.2308 97.8461Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5269 84.9231C51.4355 87.8548 50.5632 91.872 47.0768 95.0769H60.0008H72.9229C69.4374 91.872 68.5651 87.8548 68.4737 84.9231H51.5269Z"
        fill="#9E9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.7692 48.0001C74.7692 44.4315 68.1572 41.5386 59.9999 41.5386C51.8427 41.5386 45.2307 44.4315 45.2307 48.0001V61.8463C45.2307 65.4149 51.8427 68.3078 59.9999 68.3078C68.1572 68.3078 74.7692 65.4149 74.7692 61.8463V48.0001Z"
        fill="#9E9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1744 58.6641L36.9393 47.5466C36.223 47.1884 36.271 46.1509 37.0177 45.861L59.3313 37.1832C59.7614 37.0161 60.2387 37.0161 60.6688 37.1832L82.9824 45.861C83.7282 46.1509 83.7771 47.1884 83.0608 47.5466L60.8257 58.6641C60.306 58.9244 59.694 58.9244 59.1744 58.6641Z"
        fill="#333333"
      />
      <path
        d="M75.6778 60.6757L75.6824 61.1757L75.6778 60.6757L73.8502 60.6924C73.8502 60.6924 73.8502 60.6924 73.8501 60.6924C73.6148 60.6944 73.4233 60.5046 73.4233 60.2693V58.4314H73.4233L73.4233 58.425C73.4209 58.2378 73.5211 57.789 73.718 57.3745C73.8135 57.1735 73.9162 57.0145 74.0119 56.9133C74.0844 56.8366 74.1251 56.821 74.1331 56.8184L75.3462 56.8074C75.3541 56.8099 75.3956 56.8248 75.4707 56.901C75.5692 57.001 75.6759 57.1586 75.7763 57.3581C75.9824 57.7674 76.0938 58.2127 76.0971 58.4019V60.2526C76.0971 60.485 75.9103 60.6736 75.6778 60.6757Z"
        fill="#FF8200"
        stroke="#FF8200"
      />
      <path d="M60 45.5322L74.7692 51.6919V57.2304" stroke="#FF8200" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
