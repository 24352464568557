import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

import { getNameInitWithFirsLastName } from './helper';
import { useStyles } from './styles';

interface UserProfileAvatarProps {
  to: string;
  src: string | undefined;
  firstName: string;
  lastName: string;
  className?: string;
}

function UserProfileAvatar(props: UserProfileAvatarProps) {
  const { to, src, firstName, lastName } = props;
  const classes = useStyles();
  return (
    <Link className={classes.link} to={to}>
      <Avatar src={src} className={src ? classes.avatar : classes.avatarWithoutImage}>
        {src ? null : getNameInitWithFirsLastName(firstName, lastName)}
      </Avatar>
    </Link>
  );
}

export default UserProfileAvatar;
