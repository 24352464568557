import * as yup from 'yup';

import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DischargeOfMortgage2_21_1Model } from './models';
import yupMortgagees from './sections/mortgagees/validationSchema';
import yupMortgages from './sections/mortgages/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

export default yup
  .object<DischargeOfMortgage2_21_1Model>({
    mortgages: yupMortgages,
    titleReferences: yupTitleReferences,
    partyBook: yupPartyBook,
    mortgagees: yupMortgagees,
    partyBookApi: yup.mixed<ApiDocumentPartyModel<NswDocumentPartyJustification>[]>(),
    needToGeneratePartyBook: yup.boolean()
  })
  .defined()
  .log();
