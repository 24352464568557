import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import TransactionInstrument from '../../components/transaction-instrument';
import { PriorityNoticeModel, TransactionInstrumentModel } from '../../models';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  fieldName: <TFieldName extends keyof PriorityNoticeModel & string>(name: TFieldName) => TFieldName;
  name: string;
  dialogPortalId: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionTransactionInstruments({
  //
  name,
  dialogPortalId,
  fieldName,
  focusRef
}: Props): JSX.Element {
  const { nextActionDisabled } = useDocumentContext();
  const { values } = useFormikContext<PriorityNoticeModel>();
  const { isDndMode } = useDndContext();

  const createNewItem = useCallback((): TransactionInstrumentModel => {
    return {
      documentType: null,
      dealingNumber: '',
      receivingParties: [],
      titleReferences: [..._get(values, fieldName('titleReferencesData'))]
    };
  }, [values, fieldName]);

  const renderItem = useCallback(
    //
    ({ itemBinding }: DocumentArrayItemRenderProps<TransactionInstrumentModel>) => (
      <TransactionInstrument //
        key={itemBinding}
        name={itemBinding}
        dialogPortalId={dialogPortalId}
      />
    ),
    [dialogPortalId]
  );

  return (
    <div data-testid={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle="Dealing details"
        disabled={nextActionDisabled}
        itemStyle="formGroup"
        minItems={1}
        maxItems={20}
        createNewItem={createNewItem}
        addButtonTitle="Add new transaction dealing"
        focusRef={focusRef}
        dragNDropMode={true}
        dragNDropProps={{
          activated: isDndMode
        }}
      />
    </div>
  );
}

export default React.memo(SectionTransactionInstruments);
