import * as React from 'react';
import { ComponentType } from 'react';

import { FormikProps } from 'formik';

import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DocumentTypeIdentifierEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import NswCaveatV2_17_1 from './nsw/2-17/caveat/2-17-1';
import NswDischargeOfMortgageV2_17_1 from './nsw/2-17/discharge-of-mortgage/2-17-1';
import NswMortgageV2_17_1 from './nsw/2-17/mortgage/2-17-1';
import NswCaveatV2_21_0 from './nsw/2-21/caveat/2-21-0';
import NswCaveatV2_21_1 from './nsw/2-21/caveat/2-21-1';
import NswDischargeOfMortgageV2_21_0 from './nsw/2-21/discharge-of-mortgage/2-21-0';
import NswDischargeOfMortgageV2_21_1 from './nsw/2-21/discharge-of-mortgage/2-21-1';
import Nsw_ExtensionOfPriorityNoticeV2_21_1 from './nsw/2-21/extension-of-priority-notice/2-21-1';
import Nsw_LeaseV2_21_1 from './nsw/2-21/lease/2-21-1';
import Nsw_LodgementInstructionsV2_21_1 from './nsw/2-21/lodgement-instructions/2-21-1/';
import Nsw_LodgementInstructionsV2_21_2 from './nsw/2-21/lodgement-instructions/2-21-2/';
import NswMortgageV2_21_0 from './nsw/2-21/mortgage/2-21-0';
import NswMortgageV2_21_1 from './nsw/2-21/mortgage/2-21-1';
import NswMortgageV2_21_2 from './nsw/2-21/mortgage/2-21-2';
import Nsw_NoticeOfDeathV2_21_1 from './nsw/2-21/notice-of-death/2-21-1';
import Nsw_NoticeOfSale_V2_21_2 from './nsw/2-21/notice-of-sale/2-21-2';
import Nsw_PriorityNoticeV2_21_1 from './nsw/2-21/priority-notice/2-21-1';
import Nsw_SubLeaseV2_21_1 from './nsw/2-21/sub-lease/2-21-1';
import Nsw_TransferV2_21_1 from './nsw/2-21/transfer/2-21-1';
import Nsw_TransmissionApplicationBeneficiaryDeviseeNextOfKinV2_21_1 from './nsw/2-21/transmission-application-beneficiary-devisee-next-of-kin/2-21-1';
import Nsw_TransmissionWithoutDutyV2_21_1 from './nsw/2-21/transmission-application-without-duty/2-21-1';
import Nsw_TransmissionWithoutDutyV2_21_2 from './nsw/2-21/transmission-application-without-duty/2-21-2';
import Nsw_WithdrawalOfCaveatV2_21_1 from './nsw/2-21/withdrawal-of-caveat/2-21-1';
import Nsw_WithdrawalOfPriorityNoticeV2_21_1 from './nsw/2-21/withdrawal-of-priority-notice/2-21-1';
import QldDischargeOfMortgageV2_20_1 from './qld/2-20/discharge-of-mortgage/2-20-1';
import QldLodgementInstructionV2_20_1 from './qld/2-20/lodgement-instructions/2-20-1';
import QldMortgageV2_20_1 from './qld/2-20/mortgage/2-20-1';
import QldWithdrawalOfCaveatV2_20_1 from './qld/2-20/withdrawal-of-caveat/2-20-1';
import QldDischargeOfMortgageV2_22_0 from './qld/2-22/discharge-of-mortgage/2-22-0';
import QldLodgementInstructionV2_22_0 from './qld/2-22/lodgement-instructions/2-22-0';
import QldMortgageV2_22_0 from './qld/2-22/mortgage/2-22-0';
import QldWithdrawalOfCaveatV2_22_0 from './qld/2-22/withdrawal-of-caveat/2-22-0';
import QldDischargeOfMortgageV2_23_0 from './qld/2-23/discharge-of-mortgage/2-23-0';
import QldLodgementInstructionV2_23_0 from './qld/2-23/lodgement-instructions/2-23-0';
import QldMortgageV2_23_0 from './qld/2-23/mortgage/2-23-0';
import QldWithdrawalOfCaveatV2_23_0 from './qld/2-23/withdrawal-of-caveat/2-23-0';
import QldCaveatV2_24_1 from './qld/2-24/caveat/2-24-1';
import QldDischargeOfMortgageV2_24_0 from './qld/2-24/discharge-of-mortgage/2-24-0';
import QldExtensionOfPriorityNoticeV2_24_1 from './qld/2-24/extension-priority-notice/2-24-1';
import QldForm24AV2_24_1 from './qld/2-24/form24a/2-24-1';
import QldForm24BV2_24_1 from './qld/2-24/form24b/2-24-1';
import QldForm25V2_24_1 from './qld/2-24/form25/2-24-1';
import QldLodgementInstructionV2_24_0 from './qld/2-24/lodgement-instructions/2-24-0';
import QldMortgageV2_24_0 from './qld/2-24/mortgage/2-24-0';
import QldMortgageV2_24_1 from './qld/2-24/mortgage/2-24-1';
import QldPriorityNoticeV2_24_1 from './qld/2-24/priority-notice/2-24-1';
import QldTransferV2_24_1 from './qld/2-24/transfer/2-24-1';
import QldFormTransmissionV2_24_0 from './qld/2-24/transmission-application/2-24-0';
import QldWithdrawalOfCaveatV2_24_0 from './qld/2-24/withdrawal-of-caveat/2-24-0';
import QldWithdrawalOfPriorityNoticeV2_24_1 from './qld/2-24/withdrawal-of-priority-notice/2-24-1';
import SaDischargeOfMortgageV2_19_1 from './sa/2-19/discharge-of-mortgage/2-19-1';
import Sa_LodgementInstructionsV2_19_1 from './sa/2-19/lodgement-instructions/2-19-1';
import SaMortgageV2_19_1 from './sa/2-19/mortgage/2-19-1';
import Sa_WithdrawalOfCaveatV2_19_1 from './sa/2-19/withdrawal-of-caveat/2-19-1';
import SaDischargeOfMortgageV2_23_0 from './sa/2-23/discharge-of-mortgage/2-23-0';
import SaDischargeOfMortgageV2_23_1 from './sa/2-23/discharge-of-mortgage/2-23-1';
import SaLodgementInstructionsV2_23_0 from './sa/2-23/lodgement-instructions/2-23-0';
import SaMortgageV2_23_0 from './sa/2-23/mortgage/2-23-0';
import SaMortgageV2_23_1 from './sa/2-23/mortgage/2-23-1';
import SaWithdrawalOfCaveatV2_23_0 from './sa/2-23/withdrawal-of-caveat/2-23-0';
import SaDischargeOfMortgageV2_26_0 from './sa/2-26/discharge-of-mortgage/2-26-0';
import SaLodgementInstructionsV2_26_0 from './sa/2-26/lodgement-instructions/2-26-0';
import SaMortgageV2_26_0 from './sa/2-26/mortgage/2-26-0';
import SaWithdrawalOfCaveatV2_26_0 from './sa/2-26/withdrawal-of-caveat/2-26-0';
import { useStyles } from './styles';
import VicDischargeOfMortgageV2_17_1 from './vic/2-17/discharge-of-mortgage/2-17-1/';
import VicDischargeOfMortgageV2_18_0 from './vic/2-18/discharge-of-mortgage/2-18-0/';
import VicLodgementInstructionsV2_18_1 from './vic/2-18/lodgement-instructions/2-18-1';
import VicMortgageV2_18_1 from './vic/2-18/mortgage/2-18-1/';
import VicNominateeCtToPaperInstrumentV2_18_1 from './vic/2-18/nominate-ect-to-paper-instrument/2-18-1';
import VicNominateeToTheLodgementCaseV2_18_1 from './vic/2-18/nominate-to-the-lodgement-case/2-18-1';
import VicNoticeOfAcquisitionV2_18_0 from './vic/2-18/notice-of-acquisition/2-18-0';
import VicTransferOfControlV2_18_0 from './vic/2-18/transfer-of-control/2-18-0';
import VicTransferV2_18_0 from './vic/2-18/transfer/2-18-0';
import VicWithdrawalOfNominationV2_18_1 from './vic/2-18/withdrawal-of-nomination/2-18-1';
import VicDischargeOfMortgageV2_21_0 from './vic/2-21/discharge-of-mortgage/2-21-0/';
import VicLodgementInstructionsV2_21_0 from './vic/2-21/lodgement-instructions/2-21-0';
import VicMortgageV2_21_0 from './vic/2-21/mortgage/2-21-0/';
import VicNominateeCtToPaperInstrumentV2_21_0 from './vic/2-21/nominate-ect-to-paper-instrument/2-21-0';
import VicNominateeToTheLodgementCaseV2_21_0 from './vic/2-21/nominate-to-the-lodgement-case/2-21-0';
import VicNoticeOfAcquisitionV2_21_0 from './vic/2-21/notice-of-acquisition/2-21-0';
import VicTransferOfControlV2_21_0 from './vic/2-21/transfer-of-control/2-21-0';
import VicTransferV2_21_0 from './vic/2-21/transfer/2-21-0';
import VicWithdrawalOfCaveatV2_21_1 from './vic/2-21/withdrawal-of-caveat/2-21-1';
import VicWithdrawalOfNominationV2_21_0 from './vic/2-21/withdrawal-of-nomination/2-21-0';
import VicDischargeOfMortgageV2_23_0 from './vic/2-23/discharge-of-mortgage/2-23-0/';
import VicLodgementInstructionsV2_23_0 from './vic/2-23/lodgement-instructions/2-23-0';
import VicMortgageV2_23_0 from './vic/2-23/mortgage/2-23-0/';
import VicMortgageV2_23_1 from './vic/2-23/mortgage/2-23-1/';
import VicNominateeCtToPaperInstrumentV2_23_0 from './vic/2-23/nominate-ect-to-paper-instrument/2-23-0';
import VicNominateeToTheLodgementCaseV2_23_0 from './vic/2-23/nominate-to-the-lodgement-case/2-23-0';
import VicNoticeOfAcquisitionV2_23_0 from './vic/2-23/notice-of-acquisition/2-23-0';
import VicTransferOfControlV2_23_0 from './vic/2-23/transfer-of-control/2-23-0';
import VicTransferV2_23_0 from './vic/2-23/transfer/2-23-0';
import VicWithdrawalOfCaveatV2_23_0 from './vic/2-23/withdrawal-of-caveat/2-23-0';
import VicWithdrawalOfNominationV2_23_0 from './vic/2-23/withdrawal-of-nomination/2-23-0';
import VicDischargeOfMortgageV2_25_0 from './vic/2-25/discharge-of-mortgage/2-25-0/';
import VicLodgementInstructionsV2_25_0 from './vic/2-25/lodgement-instructions/2-25-0';
import VicLodgementInstructionsV2_25_1 from './vic/2-25/lodgement-instructions/2-25-1';
import VicLodgementInstructionsV2_25_2 from './vic/2-25/lodgement-instructions/2-25-2';
import VicMortgageV2_25_0 from './vic/2-25/mortgage/2-25-0/';
import VicNominateeCtToPaperInstrumentV2_25_0 from './vic/2-25/nominate-ect-to-paper-instrument/2-25-0';
import VicNominateeToTheLodgementCaseV2_25_0 from './vic/2-25/nominate-to-the-lodgement-case/2-25-0';
import VicNoticeOfAcquisitionV2_25_0 from './vic/2-25/notice-of-acquisition/2-25-0';
import VicRequestEctControlFromRegistrarOfTitlesV2_25_0 from './vic/2-25/request-ect-control-from-registrar-of-titles/2-25-0';
import VicRequestToTransferEctControlToRegistrarOfTitlesV2_25_0 from './vic/2-25/request-to-transfer-ect-control-to-registrar-of-titles/2-25-0';
import VicTransferOfControlV2_25_0 from './vic/2-25/transfer-of-control/2-25-0';
import VicTransferV2_25_0 from './vic/2-25/transfer/2-25-0';
import VicWithdrawalOfCaveatV2_25_0 from './vic/2-25/withdrawal-of-caveat/2-25-0';
import VicWithdrawalOfNominationV2_25_0 from './vic/2-25/withdrawal-of-nomination/2-25-0';
import WaDischargeOfMortgageV2_19_1 from './wa/2-19/discharge-of-mortgage/2-19-1';
import WaLodgementInstructionsV2_19_1 from './wa/2-19/lodgement-instructions/2-19-1';
import WaMortgageV2_19_1 from './wa/2-19/mortgage/2-19-1';
import WaWithdrawalOfCaveatV2_19_1 from './wa/2-19/withdrawal-of-caveat/2-19-1';
import WaDischargeOfMortgageV2_23_0 from './wa/2-23/discharge-of-mortgage/2-23-0';
import WaLodgementInstructionsV2_23_0 from './wa/2-23/lodgement-instructions/2-23-0';
import WaMortgageV2_23_0 from './wa/2-23/mortgage/2-23-0';
import WaMortgageV2_23_1 from './wa/2-23/mortgage/2-23-1';
import WaWithdrawalOfCaveatV2_23_0 from './wa/2-23/withdrawal-of-caveat/2-23-0';
import WaDischargeOfMortgageV2_25_0 from './wa/2-25/discharge-of-mortgage/2-25-0';
import WaLodgementInstructionsV2_25_0 from './wa/2-25/lodgement-instructions/2-25-0';
import WaMortgageV2_25_0 from './wa/2-25/mortgage/2-25-0';
import WaWithdrawalOfCaveatV2_25_0 from './wa/2-25/withdrawal-of-caveat/2-25-0';
import WaDischargeOfMortgageV2_26_0 from './wa/2-26/discharge-of-mortgage/2-26-0';
import WaLodgementInstructionsV2_26_0 from './wa/2-26/lodgement-instructions/2-26-0';
import WaMortgageV2_26_0 from './wa/2-26/mortgage/2-26-0';
import WaWithdrawalOfCaveatV2_26_0 from './wa/2-26/withdrawal-of-caveat/2-26-0';
import WaDischargeOfMortgageV2_27_0 from './wa/2-27/discharge-of-mortgage/2-27-0';
import WaLodgementInstructionsV2_27_0 from './wa/2-27/lodgement-instructions/2-27-0';
import WaMortgageV2_27_0 from './wa/2-27/mortgage/2-27-0';
import WaWithdrawalOfCaveatV2_27_0 from './wa/2-27/withdrawal-of-caveat/2-27-0';

type VersionComponentMap = Record<string, ComponentType<DocumentFormProps<any, any>>>;
type DocumentTypeComponentMap = Partial<Record<DocumentTypeIdentifierEnum, VersionComponentMap>>;
type JurisdictionComponentMap = Partial<Record<JurisdictionsEnum, DocumentTypeComponentMap>>;

// use hook concept so we can utilize our feature flag hook
function useDocumentComponent(jurisdictionId: JurisdictionsEnum, documentType: DocumentTypeIdentifierEnum, version: string): ComponentType<DocumentFormProps<any, any>> | string {
  const DOCUMENT_TYPE_MAP: JurisdictionComponentMap = {
    // add your feature flags here
    [JurisdictionsEnum.NSW]: {
      [DocumentTypeIdentifierEnum.Caveat]: {
        '2.17.1': NswCaveatV2_17_1,
        '2.21.0': NswCaveatV2_21_0,
        '2.21.1': NswCaveatV2_21_1
      },
      [DocumentTypeIdentifierEnum.DischargeOfMortgage]: {
        '2.17.1': NswDischargeOfMortgageV2_17_1,
        '2.21.0': NswDischargeOfMortgageV2_21_0,
        '2.21.1': NswDischargeOfMortgageV2_21_1
      },
      [DocumentTypeIdentifierEnum.Mortgage]: {
        '2.17.1': NswMortgageV2_17_1,
        '2.21.0': NswMortgageV2_21_0,
        '2.21.1': NswMortgageV2_21_1,
        '2.21.2': NswMortgageV2_21_2
      },
      [DocumentTypeIdentifierEnum.LodgementInstructions]: {
        '2.21.1': Nsw_LodgementInstructionsV2_21_1,
        '2.21.2': Nsw_LodgementInstructionsV2_21_2
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: {
        '2.21.1': Nsw_WithdrawalOfCaveatV2_21_1
      },
      [DocumentTypeIdentifierEnum.Lease]: {
        '2.21.1': Nsw_LeaseV2_21_1
      },
      [DocumentTypeIdentifierEnum.SubLease]: {
        '2.21.1': Nsw_SubLeaseV2_21_1
      },
      [DocumentTypeIdentifierEnum.PriorityNotice]: {
        '2.21.1': Nsw_PriorityNoticeV2_21_1
      },
      [DocumentTypeIdentifierEnum.ExtensionOfPriorityNotice]: {
        '2.21.1': Nsw_ExtensionOfPriorityNoticeV2_21_1
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfPriorityNotice]: {
        '2.21.1': Nsw_WithdrawalOfPriorityNoticeV2_21_1
      },
      [DocumentTypeIdentifierEnum.Transfer]: {
        '2.21.1': Nsw_TransferV2_21_1
      },
      [DocumentTypeIdentifierEnum.NoticeOfSale]: {
        '2.21.2': Nsw_NoticeOfSale_V2_21_2
      },
      [DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee]: {
        '2.21.1': Nsw_TransmissionWithoutDutyV2_21_1,
        '2.21.2': Nsw_TransmissionWithoutDutyV2_21_2
      },
      [DocumentTypeIdentifierEnum.NoticeOfDeath]: {
        '2.21.1': Nsw_NoticeOfDeathV2_21_1
      },
      [DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin]: {
        '2.21.1': Nsw_TransmissionApplicationBeneficiaryDeviseeNextOfKinV2_21_1
      }
    },
    [JurisdictionsEnum.SA]: {
      [DocumentTypeIdentifierEnum.DischargeOfMortgage]: {
        '2.19.1': SaDischargeOfMortgageV2_19_1,
        '2.23.0': SaDischargeOfMortgageV2_23_0,
        '2.23.1': SaDischargeOfMortgageV2_23_1,
        '2.26.0': SaDischargeOfMortgageV2_26_0
      },
      [DocumentTypeIdentifierEnum.Mortgage]: {
        '2.19.1': SaMortgageV2_19_1,
        '2.23.0': SaMortgageV2_23_0,
        '2.23.1': SaMortgageV2_23_1,
        '2.26.0': SaMortgageV2_26_0
      },
      [DocumentTypeIdentifierEnum.LodgementInstructions]: {
        '2.19.1': Sa_LodgementInstructionsV2_19_1,
        '2.23.0': SaLodgementInstructionsV2_23_0,
        '2.26.0': SaLodgementInstructionsV2_26_0
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: {
        '2.19.1': Sa_WithdrawalOfCaveatV2_19_1,
        '2.23.0': SaWithdrawalOfCaveatV2_23_0,
        '2.26.0': SaWithdrawalOfCaveatV2_26_0
      }
    },
    [JurisdictionsEnum.WA]: {
      [DocumentTypeIdentifierEnum.Mortgage]: {
        '2.19.1': WaMortgageV2_19_1,
        '2.23.0': WaMortgageV2_23_0,
        '2.23.1': WaMortgageV2_23_1,
        '2.25.0': WaMortgageV2_25_0,
        '2.26.0': WaMortgageV2_26_0,
        '2.27.0': WaMortgageV2_27_0
      },
      [DocumentTypeIdentifierEnum.DischargeOfMortgage]: {
        '2.19.1': WaDischargeOfMortgageV2_19_1,
        '2.23.0': WaDischargeOfMortgageV2_23_0,
        '2.25.0': WaDischargeOfMortgageV2_25_0,
        '2.26.0': WaDischargeOfMortgageV2_26_0,
        '2.27.0': WaDischargeOfMortgageV2_27_0
      },
      [DocumentTypeIdentifierEnum.LodgementInstructions]: {
        '2.19.1': WaLodgementInstructionsV2_19_1,
        '2.23.0': WaLodgementInstructionsV2_23_0,
        '2.25.0': WaLodgementInstructionsV2_25_0,
        '2.26.0': WaLodgementInstructionsV2_26_0,
        '2.27.0': WaLodgementInstructionsV2_27_0
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: {
        '2.19.1': WaWithdrawalOfCaveatV2_19_1,
        '2.23.0': WaWithdrawalOfCaveatV2_23_0,
        '2.25.0': WaWithdrawalOfCaveatV2_25_0,
        '2.26.0': WaWithdrawalOfCaveatV2_26_0,
        '2.27.0': WaWithdrawalOfCaveatV2_27_0
      }
    },
    [JurisdictionsEnum.QLD]: {
      [DocumentTypeIdentifierEnum.DischargeOfMortgage]: {
        '2.20.1': QldDischargeOfMortgageV2_20_1,
        '2.22.0': QldDischargeOfMortgageV2_22_0,
        '2.23.0': QldDischargeOfMortgageV2_23_0,
        '2.24.0': QldDischargeOfMortgageV2_24_0
      },
      [DocumentTypeIdentifierEnum.Mortgage]: {
        '2.20.1': QldMortgageV2_20_1,
        '2.22.0': QldMortgageV2_22_0,
        '2.23.0': QldMortgageV2_23_0,
        '2.24.0': QldMortgageV2_24_0,
        '2.24.1': QldMortgageV2_24_1
      },
      [DocumentTypeIdentifierEnum.LodgementInstructions]: {
        '2.20.1': QldLodgementInstructionV2_20_1,
        '2.22.0': QldLodgementInstructionV2_22_0,
        '2.23.0': QldLodgementInstructionV2_23_0,
        '2.24.0': QldLodgementInstructionV2_24_0
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: {
        '2.20.1': QldWithdrawalOfCaveatV2_20_1,
        '2.22.0': QldWithdrawalOfCaveatV2_22_0,
        '2.23.0': QldWithdrawalOfCaveatV2_23_0,
        '2.24.0': QldWithdrawalOfCaveatV2_24_0
      },
      [DocumentTypeIdentifierEnum.PriorityNotice]: {
        '2.24.1': QldPriorityNoticeV2_24_1
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfPriorityNotice]: {
        '2.24.1': QldWithdrawalOfPriorityNoticeV2_24_1
      },
      [DocumentTypeIdentifierEnum.ExtensionOfPriorityNotice]: {
        '2.24.1': QldExtensionOfPriorityNoticeV2_24_1
      },
      [DocumentTypeIdentifierEnum.Caveat]: {
        '2.24.1': QldCaveatV2_24_1
      },
      [DocumentTypeIdentifierEnum.Form25]: {
        '2.24.1': QldForm25V2_24_1
      },
      [DocumentTypeIdentifierEnum.Form24PartA]: {
        '2.24.1': QldForm24AV2_24_1
      },
      [DocumentTypeIdentifierEnum.Form24PartB]: {
        '2.24.1': QldForm24BV2_24_1
      },
      [DocumentTypeIdentifierEnum.TransmissionApplication]: {
        '2.24.0': QldFormTransmissionV2_24_0
      },
      [DocumentTypeIdentifierEnum.Transfer]: {
        '2.24.1': QldTransferV2_24_1
      }
    },
    [JurisdictionsEnum.VIC]: {
      [DocumentTypeIdentifierEnum.Transfer]: {
        '2.17.0': VicTransferV2_18_0, // TODO: Remove this line once backend defaults to 2.18.0
        '2.18.0': VicTransferV2_18_0,
        '2.21.0': VicTransferV2_21_0,
        '2.23.0': VicTransferV2_23_0,
        '2.25.0': VicTransferV2_25_0
      },
      [DocumentTypeIdentifierEnum.NoticeOfAcquisition]: {
        '2.17.0': VicNoticeOfAcquisitionV2_18_0, // TODO: Remove this line once backend defaults to 2.18.0
        '2.18.0': VicNoticeOfAcquisitionV2_18_0,
        '2.21.0': VicNoticeOfAcquisitionV2_21_0,
        '2.23.0': VicNoticeOfAcquisitionV2_23_0,
        '2.25.0': VicNoticeOfAcquisitionV2_25_0
      },
      [DocumentTypeIdentifierEnum.DischargeOfMortgage]: {
        '2.17.1': VicDischargeOfMortgageV2_17_1,
        '2.18.0': VicDischargeOfMortgageV2_18_0,
        '2.21.0': VicDischargeOfMortgageV2_21_0,
        '2.23.0': VicDischargeOfMortgageV2_23_0,
        '2.25.0': VicDischargeOfMortgageV2_25_0
      },
      [DocumentTypeIdentifierEnum.TransferOfControl]: {
        '2.17.0': VicTransferOfControlV2_18_0, // TODO: Remove this line once backend defaults to 2.18.0
        '2.18.0': VicTransferOfControlV2_18_0,
        '2.21.0': VicTransferOfControlV2_21_0,
        '2.23.0': VicTransferOfControlV2_23_0,
        '2.25.0': VicTransferOfControlV2_25_0
      },
      [DocumentTypeIdentifierEnum.Mortgage]: {
        '2.18.1': VicMortgageV2_18_1,
        '2.21.0': VicMortgageV2_21_0,
        '2.23.0': VicMortgageV2_23_0,
        '2.23.1': VicMortgageV2_23_1,
        '2.25.0': VicMortgageV2_25_0
      },
      [DocumentTypeIdentifierEnum.NominateEctToPaperInstrument]: {
        '2.18.1': VicNominateeCtToPaperInstrumentV2_18_1,
        '2.21.0': VicNominateeCtToPaperInstrumentV2_21_0,
        '2.23.0': VicNominateeCtToPaperInstrumentV2_23_0,
        '2.25.0': VicNominateeCtToPaperInstrumentV2_25_0
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfNomination]: {
        '2.18.1': VicWithdrawalOfNominationV2_18_1,
        '2.21.0': VicWithdrawalOfNominationV2_21_0,
        '2.23.0': VicWithdrawalOfNominationV2_23_0,
        '2.25.0': VicWithdrawalOfNominationV2_25_0
      },
      [DocumentTypeIdentifierEnum.NominateEctToTheLodgementCase]: {
        '2.18.1': VicNominateeToTheLodgementCaseV2_18_1,
        '2.21.0': VicNominateeToTheLodgementCaseV2_21_0,
        '2.23.0': VicNominateeToTheLodgementCaseV2_23_0,
        '2.25.0': VicNominateeToTheLodgementCaseV2_25_0
      },
      [DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase]: {
        '2.18.1': VicNominateeToTheLodgementCaseV2_18_1,
        '2.21.0': VicNominateeToTheLodgementCaseV2_21_0,
        '2.23.0': VicNominateeToTheLodgementCaseV2_23_0,
        '2.25.0': VicNominateeToTheLodgementCaseV2_25_0
      },
      [DocumentTypeIdentifierEnum.LodgementInstructions]: {
        '2.18.1': VicLodgementInstructionsV2_18_1,
        '2.21.0': VicLodgementInstructionsV2_21_0,
        '2.23.0': VicLodgementInstructionsV2_23_0,
        '2.25.0': VicLodgementInstructionsV2_25_0,
        '2.25.1': VicLodgementInstructionsV2_25_1,
        '2.25.2': VicLodgementInstructionsV2_25_2
      },
      [DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: {
        '2.21.1': VicWithdrawalOfCaveatV2_21_1,
        '2.23.0': VicWithdrawalOfCaveatV2_23_0,
        '2.25.0': VicWithdrawalOfCaveatV2_25_0
      },
      [DocumentTypeIdentifierEnum.RequestTransferEctControlToRegistrar]: {
        '2.25.0': VicRequestToTransferEctControlToRegistrarOfTitlesV2_25_0
      },
      [DocumentTypeIdentifierEnum.RequestEctControlFromRegistrar]: {
        '2.25.0': VicRequestEctControlFromRegistrarOfTitlesV2_25_0
      }
    }
  };

  if (jurisdictionId === undefined) {
    return 'Jurisdiction not specified';
  }
  if (!DOCUMENT_TYPE_MAP.hasOwnProperty(jurisdictionId)) {
    return 'Unsupported jurisdiction';
  }

  if (!DOCUMENT_TYPE_MAP[jurisdictionId]!.hasOwnProperty(documentType)) {
    return 'Unsupported document';
  }
  const DocumentForm: ComponentType<DocumentFormProps<any, any>> | undefined = DOCUMENT_TYPE_MAP[jurisdictionId]![documentType]![version];
  if (!DocumentForm) {
    return 'Unsupported document';
  }
  return DocumentForm;
}
type Props = {
  documentType: DocumentTypeIdentifierEnum;
  version: string;
  jurisdictionId?: JurisdictionsEnum;
  //
  data: object;
  isLoading: boolean;
  disabled: boolean;
  nextActionLabel: string;
  //
  onRenderForm: (formikProps: FormikProps<any>) => void;
  onSave: (data: object, formikProps: FormikProps<any>) => Promise<any>;
  onCancel: () => void;
  onPostSubmit: (args: FormikPostSubmitArgs<any, any>) => void | Promise<any>;
  //
  onBlockingActionInProgress?: (inProgress: boolean) => void;
  queryParams: DocumentsPageRouteAndQueryModel;

  hideActionsPanel?: boolean; // allows to completely hide actions panel if needed
};

function ScaffoldedForm(props: Props) {
  // calling this just to inject @global styling
  useStyles();
  // resolve the component to render
  const DocumentForm = useDocumentComponent(props.jurisdictionId!, props.documentType, props.version);

  if (typeof DocumentForm === 'string') {
    return DocumentForm;
  }

  const {
    jurisdictionId,
    documentType,
    version,
    data,
    disabled,
    isLoading,
    onRenderForm,
    onSave,
    onCancel,
    onPostSubmit,
    onBlockingActionInProgress,
    queryParams,
    nextActionLabel,
    hideActionsPanel
  } = props;

  const meta: DocumentFormProps<any, any>['meta'] = {
    jurisdiction: JurisdictionsEnum[jurisdictionId!],
    documentType: DocumentTypeIdentifierEnum[documentType],
    schemaVersion: version
  };

  return (
    <React.Suspense fallback={<BlockLoader />}>
      <DocumentForm
        initialApiValues={data}
        isLoading={isLoading}
        disabled={disabled}
        submitActionLabel={nextActionLabel}
        //
        onRenderForm={onRenderForm}
        onSave={onSave}
        onCancel={onCancel}
        onPostSubmit={onPostSubmit}
        //
        onBlockingActionInProgress={onBlockingActionInProgress}
        queryParams={queryParams}
        hideActionsPanel={hideActionsPanel}
        meta={meta}
      />
    </React.Suspense>
  );
}

export default React.memo(ScaffoldedForm);
