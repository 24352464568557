import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-23/sa';

import { DocumentPartyCapacityModel } from './models';

export const VISIBILITY_CHECK_PARTY_CAPACITY_DETAIL = createContextCheck((context: DocumentPartyCapacityModel): boolean => context.partyCapacity !== null);

export const VISIBILITY_CHECK_BIRTH_DATE = createContextCheck((context: DocumentPartyCapacityModel): boolean => {
  const partyCapacity = context.partyCapacity;
  return partyCapacity === NecdsPartyCapacityEnum.Minor || partyCapacity === NecdsPartyCapacityEnum.YoungerMinor;
});
