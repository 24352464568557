import { createAsyncAction } from 'typesafe-actions';

import { SubscriberNameJustificationsApiResponse } from '../auto-justifications/dashboard/models';
import {
  AutoJustificationSupportedDocumentsApiResponse,
  ChangeOfNameEvidenceApiResponse,
  ChangeOfNameEvidenceOptionsApiResponse,
  SubscriberNameJustificationDetailApiResponse
} from '../auto-justifications/detail/models';
import { CompanyProfileApiResponse } from '../models';

export type AutoJustificationsRequest = string;

export const actionFetchAutoJustifications = createAsyncAction(
  //
  'FETCH_AUTOJUSTIFICATIONS',
  'FETCH_AUTOJUSTIFICATIONS_SUCCESS',
  'FETCH_AUTOJUSTIFICATIONS_ERROR'
)<
  //
  AutoJustificationsRequest,
  { data: SubscriberNameJustificationsApiResponse },
  { error: Error }
>();

export type AutoJustificationDetailRequest = string;

export const actionFetchAutoJustificationDetail = createAsyncAction(
  //
  'FETCH_AUTOJUSTIFICATION_DETAILS',
  'FETCH_AUTOJUSTIFICATION_DETAILS_SUCCESS',
  'FETCH_AUTOJUSTIFICATION_DETAILS_ERROR'
)<
  //
  AutoJustificationDetailRequest,
  { data: [SubscriberNameJustificationDetailApiResponse | undefined, AutoJustificationSupportedDocumentsApiResponse, CompanyProfileApiResponse] },
  { error: Error }
>();

export type AutoJustificationEvidenceRequest = string;

export const actionFetchChangeOfNameEvidence = createAsyncAction(
  //
  'FETCH_CHANGE_OF_NAME_EVIDENCE',
  'FETCH_CHANGE_OF_NAME_EVIDENCE_SUCCESS',
  'FETCH_CHANGE_OF_NAME_EVIDENCE_ERROR'
)<
  //
  AutoJustificationEvidenceRequest,
  { data: [ChangeOfNameEvidenceApiResponse, ChangeOfNameEvidenceOptionsApiResponse] },
  { error: Error }
>();
