import * as React from 'react';

import { Navigate, Route } from 'react-router-dom';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import Switch from 'src/components/router/switch';
import FinancialWorkspacesContainer from 'src/containers/dashboard/financial-workspaces';
import InvitationsDashboardContainer from 'src/containers/dashboard/invitation';
import StandaloneWorkspacesContainer from 'src/containers/dashboard/standalone-workspaces';
import FeatureToggle from 'src/containers/shared/auth/profile-feature-toggle/FeatureToggle';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import WorkspacePageContainer from 'src/pages/workspace';
import ExamplesRootPage from '../../examples';
import NotFound from '../404';
import Reports from '../dashboard/reports';
import ReportDetail from '../dashboard/reports/components/report-details';
import ScheduledReportHistory from '../dashboard/reports/components/scheduled-report-history';
import HelpPageContainer from '../help-page/HelpPageContainer';
import Logout from '../logout';
import PersonalProfileRootContainer from '../personal-profile';

const LazySettingsRoot = React.lazy(
  () =>
    import(
      /* webpackChunkName: "settings-pages" */
      /* webpackPrefetch: true */
      'src/containers/settings/SettingsRoot'
    )
);

type RouteElement = {
  path: string;
  element: JSX.Element;
};

const ROUTE_TABLE: Array<RouteElement> = [
  /**
   * Dashboard pages
   */
  { path: '/', element: <StandaloneWorkspacesContainer /> },
  { path: '/standalone', element: <StandaloneWorkspacesContainer /> },
  { path: '/invitations', element: <InvitationsDashboardContainer /> },
  { path: '/financial-workspaces', element: <FinancialWorkspacesContainer /> },
  /**
   * Reports pages
   */
  {
    path: '/reports',
    element: (
      <FeatureToggle //
        featureToggleType={FeatureToggleEnum.bIReporting}
        fallback={() => <Navigate replace to="/" />}
      >
        <Reports />
      </FeatureToggle>
    )
  },
  {
    path: '/reports/scheduled/history/:id',
    element: (
      <FeatureToggle //
        featureToggleType={FeatureToggleEnum.bIReporting}
        fallback={() => <Navigate replace to="/" />}
      >
        <ScheduledReportHistory />
      </FeatureToggle>
    )
  },
  {
    path: '/reports/:tab/:id',
    element: (
      <FeatureToggle //
        featureToggleType={FeatureToggleEnum.bIReporting}
        fallback={() => <Navigate replace to="/" />}
      >
        <ReportDetail />
      </FeatureToggle>
    )
  },
  /**
   * Workspace pages
   */
  { path: '/workspace/:workspaceId/*', element: <WorkspacePageContainer /> },
  /**
   * Auth pages
   */
  { path: '/logout', element: <Logout /> },
  { path: '/personal-profile', element: <NotFound /> },
  { path: '/personal-profile/*', element: <PersonalProfileRootContainer /> },

  /**
   * Settings pages
   */
  { path: '/settings', element: <NotFound /> },
  { path: '/settings/*', element: <LazySettingsRoot /> },
  /**
   * Other pages
   */
  { path: '/help', element: <HelpPageContainer /> },
  { path: '/example/*', element: <ExamplesRootPage /> },
  { path: '*', element: <NotFound /> }
];

const AppRouter = () => {
  return (
    <React.Suspense fallback={<BlockLoader />}>
      <Switch>
        {ROUTE_TABLE.map(route => (
          <React.Fragment key={route.path}>
            <Route path={route.path} element={route.element} />
          </React.Fragment>
        ))}
      </Switch>
    </React.Suspense>
  );
};

export default AppRouter;
