import { ComplianceLevelEnum } from '@sympli/api-gateway/enums';

import { StampDutyPaymentBankDetailsModel, StampDutyPaymentMethodEnum } from './forms/qld-stamp-duty/models';

export interface StampDutyApiResponse {
  stampDutyDocuments: Array<StampDutyDocumentItem>;

  canVerify: boolean;
  status: StampDutyProgressStatusEnum;

  consideration: number;
  payableDuty: number;
  dutyAmount: number;
  utiAmount: number;
  penaltyTax?: number;
  foreignOwnershipSurcharge?: number;

  referenceNumber: string;
  assessmentNumber: string;

  elnoLodgementCaseId: string;

  paymentOption?: StampDutyPaymentMethodEnum;
  bankDetails?: StampDutyPaymentBankDetailsModel;

  processMessages: Array<StampDutyProcessMessage>;
  verificationTimestamp: string;

  osrCaseStatus?: OsrCaseStatusEnum; //this is only available in vic currently
  earliestSroSettlementDate?: string; //this is only available in vic currently
}

export interface StampDutyDocumentItem {
  documentId: string;
  documentNumber: string;
  documentIdentifier: string;
  titleReferences: Array<string>;
  contractSignedDate?: Date;
}

export interface StampDutyProcessMessage {
  errorCode: string;
  errorLevel: ComplianceLevelEnum;
  message: string;
}

export interface VerifyStampDutyApiResponse {
  status: StampDutyProgressStatusEnum;
}

export enum StampDutyProgressStatusEnum {
  NotApplicable = 0,
  PreparingDocument = 1,
  ReadyForTransactionCreation = 2,
  CreatingTransaction = 3,
  TransactionCreationError = 4,
  TransactionCreated = 5,
  Verifying = 6,
  Verified = 7,
  VerificationError = 8,
  Paid = 9,
  CancellingTransaction = 10,
  TransactionCancellationError = 11
}

export enum OsrCaseStatusEnum {
  WaitingForClaim = 0,
  Claimed = 1,
  ReadyToSubmit = 2,
  Submitted = 3,
  Allocated = 4,
  Returned = 5,
  ReadyToCertify = 6,
  CertifiedAndReadyToSettle = 7,
  Assessed = 8,
  Cancelled = 9,
  Finalised = 10,
  Completed = 11,
  RequestToCancel = 12,
  ExtendSettlementDate = 13,
  Review = 14
}

export enum DocumentIdentifiersEnum {
  Transfer = 'Transfer',
  TransmissionApplicationByBeneficiaryDeviseeNextOfKin = 'TransmissionApplicationByBeneficiaryDeviseeNextOfKin'
}
