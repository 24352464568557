import * as React from 'react';

import { useWorkspaceReportsFeed } from './reducer';
import WorkspaceReport from './WorkspaceReport';

interface Props {
  workspaceId: string;
  participantId: string;
}

function WorkspaceReportContainer(props: Props) {
  const workspaceReportsFeedState = useWorkspaceReportsFeed(props.workspaceId, props.participantId);

  return (
    <WorkspaceReport //
      {...props}
      {...workspaceReportsFeedState}
    />
  );
}

export default React.memo(WorkspaceReportContainer);
