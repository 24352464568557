import _uniq from 'lodash-es/uniq';
import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { FormTenancyDetailModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';

import { EMPTY_MORTGAGOR_PARTY, Mortgage2_24_1Model, MortgagorPartyModel, TitleReferenceModel } from './models';

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

// partyCapacity and partyCapacityDetail can be null or undefined. Use ?? so that null and undefiend would be equal.
export const mortgagorsAreEqual = (source: MortgagorPartyModel, toCompare: MortgagorPartyModel): boolean => {
  return (
    source.partyBookId === toCompare.partyBookId &&
    source.partyCapacity?.partyCapacity === toCompare.partyCapacity?.partyCapacity &&
    source.partyCapacity?.partyCapacityDetail === toCompare.partyCapacity?.partyCapacityDetail
  );
};

export const getTitleMortgagorLegalEntityNames = (partyBook: PartyModel[], titleReference: TitleReferenceModel) => {
  const mortgagorPartyBookIds = titleReference.mortgagors.map(mortgagor => mortgagor.partyBookId);
  return _uniq(partyBook.filter(party => mortgagorPartyBookIds.includes(party.id)).map(party => party.legalEntityName));
};

export const checkSelectedTitlesHaveSameMortgagors = (partyBook: PartyModel[], titleReferences: TitleReferenceModel[]) => {
  const selectedTitles = getSelectedTitleReferences(titleReferences);
  const mortgagorLegalEntityNamesOfTitleReferences = selectedTitles.map(tr => getTitleMortgagorLegalEntityNames(partyBook, tr));
  if (!mortgagorLegalEntityNamesOfTitleReferences.length) {
    return true;
  }
  const [firstTitle, ...otherTitles] = mortgagorLegalEntityNamesOfTitleReferences;

  for (const names of otherTitles) {
    if (_xor(firstTitle, names).length) {
      return false; // return false if the title's mortgagors name are NOT the same
    }
  }
  return true;
};

// ToDo: Use it as a reusable function
/**
 *
 * @param parties tenants in the group
 * @param groupId defaults to 1 when only one group can exist in mortgagors section
 * @returns converted form model
 */
export function createFormMortgagors(parties: TenancyPartyModel[], groupId: string = '1'): FormTenancyDetailModel {
  return {
    shareSplitType: null,
    proprietorGroups: [
      {
        parties: parties.map(p => ({ ...p, id: p.partyBookId! })),
        shareQuantity: null,
        id: groupId
      }
    ]
  };
}

// ToDo: Make it a reusable function
export function generateMortgagors(formModel: Mortgage2_24_1Model): Mortgage2_24_1Model {
  const customParties = formModel.partyBook.filter(party => party.metadata?.source === DataSource.Custom || party.metadata?.source === DataSource.Subscriber);
  const customMortgagors = getCustomMortgagors(customParties, formModel);

  const mortgagors = formModel.titleReferences
    .filter(t => t.isSelected)
    .flatMap(t => {
      if (t.hasTransfer) return formModel.transferees;
      return t.mortgagors;
    })
    .concat(customMortgagors);

  const uniqueParties = Array.from(
    mortgagors
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.partyBookId!)) entryMap.set(e.partyBookId!, e);
        return entryMap;
      }, new Map<string, TenancyPartyModel>())
      .values()
  );

  const partyBook = formModel.partyBook.map(party => ({
    ...party,
    receivingOrRelinquishingDetails:
      party.metadata?.source === DataSource.Title || party.metadata?.source === DataSource.Transfer
        ? { ...party.receivingOrRelinquishingDetails, isRelinquishing: true, isChangingName: false }
        : party.receivingOrRelinquishingDetails
  }));

  return {
    ...formModel,
    partyBook,
    mortgagors: createFormMortgagors(uniqueParties.length ? uniqueParties : [EMPTY_MORTGAGOR_PARTY])
  };
}

function getCustomMortgagors(customParties: PartyModel[], formModel: Mortgage2_24_1Model) {
  const customMortgagors = customParties.filter(p => formModel.mortgagors.proprietorGroups.flatMap(pg => pg.parties).some(m => m.partyBookId === p.id));
  return customMortgagors.map(p => ({ id: p.id!, partyBookId: p.id! }));
}

export const getMortgagorPartyBookIds = defaultMemoize((mortgagors: FormTenancyDetailModel) => {
  return _uniq(mortgagors.proprietorGroups.flatMap(pg => pg.parties).map(p => p.partyBookId));
});
