import _uniqueId from 'lodash-es/uniqueId';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum TrustAccountSetupStepEnum {
  AccountDetails = 1,
  Signers = 2,
  Owners = 3,
  Review = 4,
  FormUpload = 5
}

export const TrustAccountNewRequestStepsEnum: TrustAccountSetupStepEnum[] = [
  TrustAccountSetupStepEnum.AccountDetails,
  TrustAccountSetupStepEnum.Signers,
  TrustAccountSetupStepEnum.Owners,
  TrustAccountSetupStepEnum.Review,
  TrustAccountSetupStepEnum.FormUpload
];

export const TrustAccountAddSignersSteps: TrustAccountSetupStepEnum[] = [
  //
  TrustAccountSetupStepEnum.Signers,
  TrustAccountSetupStepEnum.Review,
  TrustAccountSetupStepEnum.FormUpload
];

export enum TrustAccountSigningRuleEnum {
  OneSigner = 0,
  TwoSigners = 1,
  ImposedSigningLimit = 4
}

export enum TrustAccountRequestStatusEnum {
  NotSubmitted = 0,
  Reviewed = 1,
  Submitted = 2,
  Declined = 3,
  PartiallyApproved = 4,
  Approved = 5
}

export enum TrustAccountRequestTypeEnum {
  NewAccount = 0,
  AddSignatories = 1
}

export const TrustAccountRequestTypeMapping: Record<TrustAccountRequestTypeEnum, string> = {
  [TrustAccountRequestTypeEnum.NewAccount]: 'New account',
  [TrustAccountRequestTypeEnum.AddSignatories]: 'Add signers'
};

export enum TrustAccountSignatoriesTypeEnum {
  Existing = 0,
  Added = 1
}

export interface TrustAccountOwnerDetails {
  userId?: string;
  fullName: string;
  email: string;
  workPhone: string;
  jobTitle: string;
}

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.Directions.AuthorisationRecordAction
 */
export interface TrustAccountSignatoriesDetails {
  userId: string;
  fullName: string;
  email: string;
  signingLimitAmount?: number | null;
  jobTitle: string;
  office: string;
  hasSigningLimit: boolean;
  isApproved: boolean;
  type: TrustAccountSignatoriesTypeEnum;
}

export interface TrustAccountContactPerson {
  email: string;
  fullName: string;
  work?: string;
}

export interface AccountDetail {
  accountDescription?: string;
  bsbNumber?: string;
  bankName?: string;
  branchLocation?: string;
  accountName?: string;
  accountNumber?: string;
}

export interface TrustAccountDetails {
  trustAccountId?: string;
  trustAccountRequestId?: string;
  jurisdictionIds: JurisdictionsEnum[];
  contactPerson: TrustAccountContactPerson;
  trustAccountDetail: AccountDetail;
  trustAccountOwners: {
    accountOwners: TrustAccountOwnerDetails[];
  };
  signingRule: TrustAccountSigningRuleEnum;
  trustAccountSignatories: {
    trustAccountSignatoriesDetails: TrustAccountSignatoriesDetails[];
  };
  status: TrustAccountRequestStatusEnum;
  type?: TrustAccountRequestTypeEnum;
}

export type TrustAccountDetailsApiRequest = Omit<TrustAccountDetails, 'trustAccountOwners' | 'trustAccountSignatories'> & {
  trustAccountOwners?: {
    accountOwners: TrustAccountOwnerDetails[];
  };
  trustAccountSignatories?: {
    trustAccountSignatoriesDetails: TrustAccountSignatoriesDetails[];
  };
};

export interface TrustAccountChangeSignersApiRequest {
  trustAccountRequestId?: string;
  trustAccountSignatories: {
    trustAccountSignatoriesDetails: TrustAccountSignatoriesDetails[];
  };
}

export interface TrustAccountRequestDetailsApiResponse {
  requestId: string;
  signingRule: TrustAccountSigningRuleEnum;
  contactPerson: TrustAccountContactPerson;
  owners: TrustAccountOwnerDetails[];
  signatories: TrustAccountSignatoriesDetails[];
  id: string;
  trustAccountId?: string;
  createdDate: string;
  updatedDate: string;
  branchName: string;
  status: TrustAccountRequestStatusEnum;
  version: number;
  jurisdictions: JurisdictionsEnum[];
  accountNumber: string;
  bsbNumber: string;
  bankName: string;
  branchLocation: string;
  accountName: string;
  accountDescription: string;
  type: TrustAccountRequestTypeEnum;
}

export interface TrustAccountDetailsApiResponse {
  signingRule: TrustAccountSigningRuleEnum;
  contactPerson: TrustAccountContactPerson;
  owners: TrustAccountOwnerDetails[];
  signatories: TrustAccountSignatoriesDetails[];
  id: string;
  isDeleted: boolean;
  createdDate: string;
  updatedDate: string;
  jurisdictions: JurisdictionsEnum[];
  accountNumber: string;
  bsbNumber: string;
  bankName: string;
  branchLocation: string;
  accountName: string;
  accountDescription: string;
}

interface SigningRuleItem extends LookupEnumModel<TrustAccountSigningRuleEnum> {}

export const SigningRulesOptions: SigningRuleItem[] = [
  {
    id: TrustAccountSigningRuleEnum.OneSigner,
    name: '1 signer required'
  },
  {
    id: TrustAccountSigningRuleEnum.TwoSigners,
    name: '2 signers required'
  },
  {
    id: TrustAccountSigningRuleEnum.ImposedSigningLimit,
    name: 'Imposed signing limits'
  }
];

export interface UserLookupApiResponse {
  id: string;
  name: string;
  email: string;
  workPhone: string;
  jobTitle?: string;
}

export const DEFAULT_TRUST_ACCOUNT_DETAILS: TrustAccountDetails = {
  jurisdictionIds: [],
  contactPerson: {
    email: '',
    fullName: '',
    work: ''
  },
  trustAccountDetail: {
    accountDescription: '',
    bsbNumber: '',
    bankName: '',
    branchLocation: '',
    accountName: '',
    accountNumber: ''
  },
  trustAccountOwners: {
    accountOwners: []
  },
  signingRule: TrustAccountSigningRuleEnum.OneSigner,
  trustAccountSignatories: {
    trustAccountSignatoriesDetails: []
  },
  status: TrustAccountRequestStatusEnum.NotSubmitted,
  type: TrustAccountRequestTypeEnum.NewAccount
};

export const DEFAULT_ACCOUNT_OWNER: TrustAccountOwnerDetails = {
  fullName: '',
  email: '',
  workPhone: '',
  jobTitle: ''
};

export const PORTAL_ID_FOR_TRUST_ACCOUNT_SETTING = _uniqueId('portal-trust-account-settings');
