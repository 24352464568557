import React from 'react';

import NonDashboardWrapper from 'src/components/layout/v2/NonDashboardWrapper';
import { useRequestReduxDataOnMount } from 'src/hooks';
import { actionFetchReportsDashboard } from './actions';
import ReportsDashboard from './components/report-dashboard';
import { useAccessibleReports } from './hooks/useAcessibleReports';
import { ReportFeatureTypeEnum, SavedReportModel } from './models';
import { ReportsDashboardState } from './reducers/reportsDashboard';

interface ReportsDashboardContainerProps {
  header?: React.ReactNode;
}

function ReportsDashboardContainer(props: ReportsDashboardContainerProps): JSX.Element {
  const { status, reports } = useAccessibleReports();

  const [{ savedReports, status: savedReportsStatus }] = useRequestReduxDataOnMount<ReportsDashboardState>(actionFetchReportsDashboard.request(), state => state.reportsDashboard);

  const favouriteReports: SavedReportModel[] = savedReports?.filter(report => report.feature === ReportFeatureTypeEnum.Favourite) ?? [];
  const scheduledReports: SavedReportModel[] = savedReports?.filter(report => report.feature === ReportFeatureTypeEnum.Scheduled) ?? [];

  return (
    <NonDashboardWrapper>
      <ReportsDashboard //
        favouriteReports={favouriteReports}
        scheduledReports={scheduledReports}
        reportPermissions={reports}
        reportCardsStatus={status}
        savedReportsStatus={savedReportsStatus}
      />
    </NonDashboardWrapper>
  );
}

export default React.memo(ReportsDashboardContainer);
