import * as React from 'react';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  label: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class TotalAmount extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const { classes, label, children } = this.props;
    return (
      <FlexLayout className={classes.total} justifyContent="flex-end">
        <Typography className={classes.totalLabel}>{label || 'Total amount'}:</Typography>
        <Typography className={classes.totalAmount}>{children}</Typography>
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(TotalAmount);
