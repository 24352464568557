import * as yup from 'yup';

import { WaAddressChange } from '@sympli-mfe/document-components/party-form/wa/2-25/components/address-justification';
import { WaNameChange } from '@sympli-mfe/document-components/party-form/wa/2-25/components/party-justification';
import { AddressBookEntityModel, AddressTypeEnum } from '@sympli-mfe/document-forms-framework/components/address-field';
import { RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-25/wa';
import msg from '@sympli/ui-framework/utils/messages';

import { RelinquishingTenancyPartyModel } from '../../../../models';
import { VISIBILITY_CHECK_ADDRESS } from '../../../../visibilityChecks';
import { checkIfJustifiedPartiesAreTheSame, checkIfMergedPartiesHaveMismatchingPartyType, isMortgagorNamesMatchesOrJustified } from '../../helper';
import { Mortgage2_25_0Model } from '../../models';

interface MortgagorsContext {
  isFinancialSettlement: boolean;
  hasAnyTransactingParty: boolean;
  mortgagorsLength: number;
  partyBook: Mortgage2_25_0Model['partyBook'];
  addressBook: AddressBookEntityModel[];
  titleReferences: Mortgage2_25_0Model['titleReferences'];
}

const contextResolver = ({ isFinancialSettlement, mortgagors, partyBook, titleReferences, addressBook }: Mortgage2_25_0Model): MortgagorsContext => {
  return {
    isFinancialSettlement,
    hasAnyTransactingParty: mortgagors.some(x => x.isTransactingParty),
    mortgagorsLength: mortgagors.filter(x => x.partyBookId?.length).length,
    partyBook,
    addressBook: addressBook || [],
    titleReferences
  };
};

const yupMortgagorsItem = yup
  .object<RelinquishingTenancyPartyModel, MortgagorsContext>({
    partyBookId: yup.mixed<string>(),
    partyCapacity: yup.mixed<string | null>(),
    partyCapacityDetail: yup.mixed<string>(),
    isTransactingParty: yup.mixed<boolean>(),
    addressBookId: yup
      .string()
      .default('')
      .trim()
      .test('addressId conditional required check', msg.REQUIRED, function test(this: yup.TestContext<MortgagorsContext>, value: string) {
        const tenancyParty = this.parent as RelinquishingTenancyPartyModel;
        const { partyBook } = this.options.context!;

        const party = partyBook.filter(x => x.id === tenancyParty.partyBookId);

        return !!value?.trim()?.length || !VISIBILITY_CHECK_ADDRESS(party[0]);
      })
  })
  .test(
    'address justification required check',
    'Address justification is required when address is unstructured and party has name change',
    function test(this: yup.TestContext<MortgagorsContext>, value: any) {
      const tenancyParty = value as RelinquishingTenancyPartyModel;
      const { partyBook, addressBook } = this.options.context!;

      const party = partyBook.filter(x => x.id === tenancyParty.partyBookId)[0];
      if (!VISIBILITY_CHECK_ADDRESS(party)) {
        return true;
      }

      const address = addressBook.filter(x => x.id === tenancyParty.addressBookId)[0];
      const relinquishingParty = party.receivingOrRelinquishingDetails as RelinquishingModel<WaNameChange, WaAddressChange>;
      return !relinquishingParty.isChangingName || address.type !== AddressTypeEnum.UnstructuredAddress || relinquishingParty.isChangingAddress === true;
    }
  )
  .defined();

const yupMortgagors = memoizeSchemaWithContext<RelinquishingTenancyPartyModel[], Mortgage2_25_0Model, MortgagorsContext>(
  yup
    .array<RelinquishingTenancyPartyModel, MortgagorsContext>()
    .of<RelinquishingTenancyPartyModel>(yupMortgagorsItem)
    .defined()
    .testContextualRule({
      uniqueTestName: 'Check if at least 1 mortgagor is selected',
      message: 'At least 1 mortgagor must be selected',
      requirement: (parent: Mortgage2_25_0Model, ctx: MortgagorsContext) => ctx.mortgagorsLength === 0 || ctx.hasAnyTransactingParty
    })
    .testContextualRule({
      uniqueTestName: 'Wait for transfer test',
      message: 'Awaiting Transfer document completion',
      requirement: (parent: Mortgage2_25_0Model, ctx: MortgagorsContext) => {
        if (!ctx.isFinancialSettlement) {
          return true;
        }
        return ctx.mortgagorsLength !== 0;
      }
    })
    .testContextualRule({
      uniqueTestName: 'Mortgagors name mismatch test',
      message: 'Please be aware that the parties on the selected titles presently do not match.',
      requirement: (parent: Mortgage2_25_0Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return isMortgagorNamesMatchesOrJustified(partyBook, titleReferences);
      }
    })
    .testContextualRule({
      uniqueTestName: 'Check if party is justified twice',
      message: 'Unable to change name, the proposed name has already been used in a justification.',
      requirement: (parent: Mortgage2_25_0Model, { partyBook, titleReferences }: MortgagorsContext) => {
        return !checkIfJustifiedPartiesAreTheSame(partyBook, titleReferences);
      }
    })
    .test('Check if parties justified does not have same party type', 'Unable to change name', function test(this: yup.TestContext<MortgagorsContext>) {
      const { partyBook, titleReferences } = this.options.context!;
      const partyType = checkIfMergedPartiesHaveMismatchingPartyType(partyBook, titleReferences);
      return partyType
        ? this.createError({
            message: `Unable to change name, the proposed name has already been used for an ${partyType === PartyTypeEnum.Individual ? 'individual' : 'organisation'}.`
          })
        : true;
    })
    .log(),
  contextResolver
);

export default yupMortgagors;
