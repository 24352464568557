import React, { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { TenancyPartyModel } from '../../../../models';
import { Mortgage2_19_1Model } from '../../models';
import MortgagorParty from '../mortgagor-party';
import { useStyles } from './styles';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function MortgagorPartyArray({ name, className, focusRef }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<Mortgage2_19_1Model>();

  const { values } = formikProps;

  const items: TenancyPartyModel[] = getIn(values, name) ?? [];
  const count = items.length;
  const hasMultipleItems = count > 1;
  const disableCheckbox = disabled || count <= 1;
  const classes = useStyles();

  const handleSelectAllProprietorsChange = (event: React.ChangeEvent<HTMLInputElement>, newAreAllAffected: boolean): void => {
    const { touched, values, setFieldValue, setFieldTouched } = formikProps;

    const items: TenancyPartyModel[] = getIn(values, name) ?? [];
    const updatedItems = items.map(proprietor => ({
      ...proprietor,
      isTransactingParty: newAreAllAffected
    }));

    setFieldValue(name, updatedItems);
    // we want to show errors immediately, therefore field needs to be marked as touched
    // prevent unnecessary render of Formik tree by calling setFieldTouched only when it makes sense
    !getIn(touched, name) && setFieldTouched(name, true, false /* don't trigger validation */);
  };

  const renderItem = useCallback(({ itemBinding }: DocumentArrayItemRenderProps<TenancyPartyModel>) => <MortgagorParty name={itemBinding} />, []);

  return (
    <div data-section={name} className={className}>
      {hasMultipleItems && (
        <FormGroup
          title={
            <Checkbox
              label="Select all mortgagors"
              name="select-all-mortgagors"
              format="boolean"
              className={classes.checkboxLabelRoot}
              checked={items.every(item => item.isTransactingParty)}
              onChange={handleSelectAllProprietorsChange}
              action={focusRef}
            />
          }
          paddingBottom={true}
        ></FormGroup>
      )}
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={({ itemIndex, fieldName, itemFocusRef }) =>
          hasMultipleItems ? (
            <Field
              component={CheckboxField}
              label={hasMultipleItems ? `Mortgagor ${itemIndex + 1}` : 'Mortgagors'}
              name={fieldName('isTransactingParty')}
              disabled={disableCheckbox}
              className={classes.checkboxLabelRoot}
              action={itemFocusRef}
            />
          ) : (
            <span>Mortgagor</span>
          )
        }
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(MortgagorPartyArray);
