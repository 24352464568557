import React from 'react';

import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';

import { DeceasedProprietorGroupModel } from '../../models';
import DeceasedProprietorReadonlyArray from '../deceased-proprietor-readonly-array';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  numberOfPartiesInPreceedingGroups: number;
  hasMultipleParties: boolean;
  isSoleProprietor: boolean;
  haveOrgPartyInGroup: boolean;
}

function DeceasedProprietorGroup({ name, className, hasMultipleParties, numberOfPartiesInPreceedingGroups, isSoleProprietor, haveOrgPartyInGroup }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(name);

  return (
    <div data-section={name} className={className}>
      <DeceasedProprietorReadonlyArray //
        name={fieldName('deceasedProprietors')}
        hasMultipleParties={hasMultipleParties}
        numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
        isSoleProprietor={isSoleProprietor}
        haveOrgPartyInGroup={haveOrgPartyInGroup}
      />
    </div>
  );
}

export default React.memo(DeceasedProprietorGroup);
