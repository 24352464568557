import queryString from 'query-string';
import { ActionMeta } from 'redux-actions';
import { call, put, takeLatest } from 'typed-redux-saga';

import { HandleActionMeta } from '@sympli/ui-framework/actions';

import { fetchUserReportsPermissionsDetail } from 'src/containers/dashboard/reports/api';
import { MfaLinkedDeviceModel } from 'src/containers/personal-profile/multi-factor-auth-devices/api';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';
import http from 'src/utils/http';
import { fetchJurisdictions } from '../../subscriber-profile/sagas/jurisdictions';
import {
  actionDeleteUser,
  actionFetchUserDetails,
  actionFetchUserLinkedDevicesFeed,
  actionFetchUserPermissions,
  actionFetchUserReportsPermissions,
  actionFetchUsersFeed,
  actionUpdateSuspendConfirmDialog,
  actionUpdateUserDetails,
  DELETE_USER_LINKED_DEVICE,
  DeleteUserLinkedDeviceRequestModel,
  DeleteUserRequestModel,
  UPDATE_USER_SUSPENSION,
  UpdateUserSuspensionRequestModel,
  UserDetailsApiRequest,
  UsersApiRequest,
  UsersApiResponse
} from '../actions/users';
import { DEFAULT_USER_DETAILS, UserDetailsApiResponse } from '../users-detail/detail/models';
import { UserPermissionsApiResponse } from '../users-detail/models';

const fetchUsersFeed = (query: UsersApiRequest) => {
  const uri = '/settings/users';
  const { sortBy: OrderBy, sortOrder, pageNumber, pageSize, search, role, status, groupIds } = query;

  const q = queryString.stringify({
    OrderBy,
    sortOrder,
    pageNumber,
    pageSize,
    groupIds,
    accountStatusId: status,
    roleId: role,
    freeSearchTerm: search
  });

  return http.get<UsersApiResponse>(uri + '?' + q);
};

export function* sagaFetchUsersFeed(action: ReturnType<typeof actionFetchUsersFeed.request>) {
  try {
    const data = yield* call(fetchUsersFeed, action.payload);
    yield put(actionFetchUsersFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchUsersFeed.failure({ error }));
  }
}

const fetchUserDetail = (userId: UserDetailsApiRequest) => http.get<UserDetailsApiResponse>(`/settings/users/${encodeURIComponent(userId)}`);

export function* sagaFetchUsersDetail(action: ReturnType<typeof actionFetchUserDetails.request>) {
  try {
    let data = DEFAULT_USER_DETAILS;

    if (action.payload === 'new') {
      const jurisdictions = yield* call(fetchJurisdictions);
      if (jurisdictions.length === 1) {
        data = { ...data, timezone: jurisdictionNameMapping[jurisdictions[0]] };
      }
    } else {
      const userDetails = yield* call(fetchUserDetail, action.payload);
      data = { ...data, ...userDetails };
    }

    yield put(actionFetchUserDetails.success({ data }));
  } catch (error) {
    yield put(actionFetchUserDetails.failure({ error }));
  }
}

const fetchUserPermissionsDetail = (userId: UserDetailsApiRequest) => http.get<UserPermissionsApiResponse>(`/settings/users/${encodeURIComponent(userId)}/permissions`);

export function* sagaFetchUserPermissionsDetail(action: ReturnType<typeof actionFetchUserPermissions.request>) {
  try {
    const data = yield* call(fetchUserPermissionsDetail, action.payload);

    yield put(actionFetchUserPermissions.success({ data }));
  } catch (error) {
    yield put(actionFetchUserPermissions.failure({ error }));
  }
}

const updateUserSuspension = (payload: UpdateUserSuspensionRequestModel) => {
  const { id: userId, isSuspended, httpConfig } = payload;
  const uri = `/settings/users/${encodeURIComponent(userId)}/suspension`;
  return http.post<UpdateUserSuspensionRequestModel>(uri, { isSuspended }, httpConfig);
};

export function* sagaUpdateUserSuspension(action: ActionMeta<UpdateUserSuspensionRequestModel, HandleActionMeta>) {
  try {
    const {
      payload: { id, isSuspended }
    } = action;
    yield* call(updateUserSuspension, action.payload);
    yield put(action.meta.createSuccess({}));
    yield put(actionUpdateUserDetails.request({ id, isSuspended }));
    yield put(actionUpdateSuspendConfirmDialog({ isOpen: false }));
  } catch (error) {
    yield put(action.meta.createError(error));
  }
}

const deleteUser = (userId: DeleteUserRequestModel) => {
  const uri = `/settings/users/${encodeURIComponent(userId)}`;
  return http.delete(uri);
};

export function* sagaDeleteUser(action: ReturnType<typeof actionDeleteUser.request>) {
  try {
    const data = yield* call(deleteUser, action.payload);

    yield put(actionDeleteUser.success({ data }));
  } catch (error) {
    yield put(actionDeleteUser.failure({ error }));
  }
}

const fetchUserLinkedDevicesFeed = (userId: string) => http.get<MfaLinkedDeviceModel[]>(`/settings/users/${encodeURIComponent(userId)}/devices`);

export function* sagaFetchUserLinkedDevicesFeed(action: ReturnType<typeof actionFetchUserLinkedDevicesFeed.request>) {
  try {
    const data = yield* call(fetchUserLinkedDevicesFeed, action.payload);
    yield put(actionFetchUserLinkedDevicesFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchUserLinkedDevicesFeed.failure({ error }));
  }
}

const deleteUserLinkedDevice = (payload: DeleteUserLinkedDeviceRequestModel) => {
  const { userId, deviceId, httpConfig } = payload;
  const uri = `/settings/users/${encodeURIComponent(userId)}/devices/${encodeURIComponent(deviceId)}/unlink`;
  return http.put<DeleteUserLinkedDeviceRequestModel>(uri, {}, httpConfig);
};

export function* sagaDeleteUserLinkedDevice(action: ActionMeta<DeleteUserLinkedDeviceRequestModel, HandleActionMeta>) {
  try {
    yield* call(deleteUserLinkedDevice, action.payload);
    yield put(action.meta.createSuccess({ data: action.payload }));
  } catch (error) {
    yield put(action.meta.createError(error));
  }
}

export function* sagaFetchUserReportsPermissionsDetail(action: ReturnType<typeof actionFetchUserReportsPermissions.request>) {
  try {
    const data = yield* call(fetchUserReportsPermissionsDetail, action.payload);

    yield put(actionFetchUserReportsPermissions.success({ data }));
  } catch (error) {
    yield put(actionFetchUserReportsPermissions.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchUsersFeed.request, sagaFetchUsersFeed),
  takeLatest(actionFetchUserDetails.request, sagaFetchUsersDetail),
  takeLatest(actionFetchUserPermissions.request, sagaFetchUserPermissionsDetail),
  takeLatest(actionDeleteUser.request, sagaDeleteUser),
  takeLatest(actionFetchUserLinkedDevicesFeed.request, sagaFetchUserLinkedDevicesFeed),
  takeLatest(DELETE_USER_LINKED_DEVICE.ACTION, sagaDeleteUserLinkedDevice),
  takeLatest(UPDATE_USER_SUSPENSION.ACTION, sagaUpdateUserSuspension),
  takeLatest(actionFetchUserReportsPermissions.request, sagaFetchUserReportsPermissionsDetail)
];
