import { noCase } from 'no-case';
import { sentenceCase } from 'sentence-case';

import { LookupItemModel } from '@sympli/ui-framework/models';

// function that converts
// enum ProperietorGroupTypeEnum {
//   JointTenantsInterse = 0,
//   JointTenantsWithNoSurvivorship = 1
// }

// to:

// new Map([[0, 'Joint tenants interse'], [1, 'Joint tenants with no survivorship']]);
// so later it can be used in table formatters by m.get(id);

export const wordifyEnum = tsEnum => {
  // const enumMap = new Map<number, string>(
  const enumMap = new Map(
    Object.keys(tsEnum)
      // loop over all typescript enum keys (mix of keys and values) and return only enum keys
      .filter(k => typeof tsEnum[k] === 'number')
      // now create a map, where key is the value (since that is what we will be getting from BL)
      // and the value is wordified version of enum key
      .map<[number, string]>(k => [tsEnum[k], sentenceCase(noCase(k))])
  );

  return enumMap;
};

/**
 * Converts Enum into array of objects with id/name properties.
 */
export function enum2IdNameObjArray<EnumIdType extends number | string>(tsEnum: object, displayNameMap: Record<EnumIdType, string>): LookupItemModel<EnumIdType>[] {
  const allEntries = Object.entries(tsEnum);
  const numOptions = allEntries.length / 2;
  // First half of entry array has enum values converted to strings, e.g. '1' in entry ['1', 'ONE'].
  // Second half has values in their original form, e.g. 1 in entry [1, 'ONE'].
  // Therefore, we only want to take the second half.
  const nameIdPairs = allEntries.slice(numOptions);
  return nameIdPairs.map(([name, id]) => ({ id, name: displayNameMap[id] ?? name }));
}
