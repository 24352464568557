import * as yup from 'yup';

import {
  PartyFormConfig,
  PartyModel,
  resolveMemoizedContextBasedPartyBookValidationSchema,
  resolvePartyModelValidationSchema
} from '@sympli-mfe/document-forms-framework/components/party-form';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';

import { DeceasedTenancyDetailModel } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/models';
import { BaseTransmissionApplicationModel } from '../../models';

interface PartyBookValidationContext {
  deceasedTenancyDetail: DeceasedTenancyDetailModel;
}

const resolveValidationContext = <TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({
  deceasedTenancyDetail
}: BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>): PartyBookValidationContext => ({
  deceasedTenancyDetail
});

const checkShouldValidateItem = ({ deceasedTenancyDetail }: PartyBookValidationContext, partyIdForCheck: PartyModel['id']) => {
  const partyInDeceasedTenancy = deceasedTenancyDetail.proprietorGroups.some(({ parties }) => parties.some(e => e.partyBookId === partyIdForCheck));
  return partyInDeceasedTenancy;
};

const yupParty = <TNameChange extends object>(partyFormConfig: PartyFormConfig<TNameChange>): yup.ObjectSchema<PartyModel<TNameChange>, PartyBookValidationContext> => {
  return resolvePartyModelValidationSchema<TNameChange, PartyBookValidationContext>(partyFormConfig);
};

const yupPartyBook = <TNameChange extends object>(partyFormConfig: PartyFormConfig<TNameChange>) => {
  return resolveMemoizedContextBasedPartyBookValidationSchema({
    yupPartyItem: yupParty(partyFormConfig),
    checkShouldValidateItem,
    resolveValidationContext
  });
};

export default yupPartyBook;
