import * as React from 'react';

import { Form, FormikProps } from 'formik';
import { Action } from 'redux';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { BillingMethodEnum } from '@sympli/api-gateway/enums';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconInvoiceDollar } from '@sympli/ui-framework/icons';

import Formik from 'src/components/formik';
import { DEFAULT_BILLING_OPTIONS } from 'src/containers/settings/subscriber-profile/billing-invoicing-detail/models';
import { actionFetchWorkspaceParticipantSetting } from 'src/containers/workspace/shared/detail/actions';
import { BillingSetting, ParticipantSettingApiResponse } from 'src/containers/workspace/shared/detail/models';
import { WorkspaceParticipantSettingState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipantSetting';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { createModelKeyAppender } from 'src/utils/formUtils';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  queryParams: WorkspaceDetailRouteParams;
  open: boolean;
  onClose: () => void;
  workspaceParticipantSetting: WorkspaceParticipantSettingState;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

type BillingMethodFormikModel = ParticipantSettingApiResponse;

const fieldName = createModelKeyAppender<BillingSetting>('billingSetting');

class BillingMethodDialog extends React.PureComponent<Props> {
  private getInitialValues = (): BillingMethodFormikModel => {
    return {
      billingSetting: {
        billingMethodType: BillingMethodEnum.SettlementDistributions
      },
      ...this.props.workspaceParticipantSetting.detail
    };
  };

  private getValidationSchema = () => {
    return yup.object<BillingMethodFormikModel>(); // TODO why is this empty validation?
  };

  render() {
    const { queryParams, open } = this.props;
    const { workspaceId, participantId } = queryParams;

    return (
      <ConfirmationDialog title={this.renderTitle()} okButtonText="Save changes" open={open} onClose={this.handleOnDialogClose} showActionButtons={false}>
        Please allow up to 20 minutes before the change takes into effect.
        <Formik //
          method="put"
          action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/settings`}
          getInitialValues={this.getInitialValues}
          validationSchema={this.getValidationSchema}
          onPostSubmit={this.handleOnPostSubmit}
        >
          {(formikProps: FormikProps<BillingMethodFormikModel>) => this.renderForm(formikProps)}
        </Formik>
      </ConfirmationDialog>
    );
  }

  private renderTitle() {
    const { classes } = this.props;
    return (
      <FlexLayout alignItems="center">
        <IconInvoiceDollar width="32" height="32" className={classes.icon} />
        <Typography variant="h2" className={classes.bold}>
          Sympli fee billing method
        </Typography>
      </FlexLayout>
    );
  }

  private renderForm(formikProps: FormikProps<BillingMethodFormikModel>) {
    const { classes } = this.props;
    const { isSubmitting } = formikProps;
    return (
      <Form>
        <Field
          aria-label="Billing method type"
          classes={{ formControl: classes.billingSelection }}
          vertical
          component={RadioField}
          format="number"
          name={fieldName('billingMethodType')}
          options={DEFAULT_BILLING_OPTIONS}
        />
        <WizardStepper onBack={this.handleOnDialogClose} backLabel="Cancel" nextLabel="Save changes" isLoading={isSubmitting} />
      </Form>
    );
  }

  private handleOnDialogClose = () => {
    this.props.onClose();
  };

  private handleOnPostSubmit = () => {
    // TODO error handling
    const { queryParams, onClose, dispatch } = this.props;

    onClose();
    dispatch(actionFetchWorkspaceParticipantSetting.request(queryParams));
  };
}

const styledComponent = withStyles(styles)(BillingMethodDialog);

export default styledComponent;
