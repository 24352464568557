import { MergeParty } from './model';
import { assignMatchingItemHash, compareParties } from './PartyMerger';

/**
 * https://tickleme.atlassian.net/wiki/spaces/~5de440aa7095a40d125388c9/pages/2415887591/Party+Merge+NSW
 */

export default class PartyJustification {
  /**
   * ! This method will mutate the parties parameter
   * Will adjust the matching id hash once successful otherwise will return reason of unsuccessful
   * @param parties Parties to check the justification
   * @param getLegalEntityName Will use this function to get the legal entity name
   * @returns Return the reason of unsuccessful otherwise undefined
   */
  public static adjustJustification<T extends MergeParty>(parties: T[], getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined): string | undefined {
    const partiesNeedJustification = parties.filter(e => e.mergeMetadata?.requiresJustification);
    if (!partiesNeedJustification.length) return undefined;

    partiesNeedJustification.forEach(p => (p.mergeMetadata!.matchingItemHash = p.mergeMetadata!.identity.itemHash));

    const mergedGroups = partiesNeedJustification.reduce(
      (entryMap, e) => entryMap.set(e.mergeMetadata!.matchingGroupHash, [...(entryMap.get(e.mergeMetadata!.matchingGroupHash) || []), e]),
      new Map<string, T[]>()
    );

    for (const [, value] of mergedGroups) {
      const groupsMap = value.reduce(
        (entryMap, e) => entryMap.set(e.mergeMetadata!.identity.groupHash, [...(entryMap.get(e.mergeMetadata!.identity.groupHash) || []), e]),
        new Map<string, T[]>()
      );

      if (groupsMap.size === 1) continue;

      const groups = Array.from(groupsMap);
      const referenceGroupParties = groups.shift()![1];

      for (const referenceParty of referenceGroupParties) {
        const justifiedParties: T[] = [];
        for (const group of groups) {
          const [, parties] = group;
          const party = parties.find(
            c =>
              c.mergeMetadata!.matchingItemHash === c.mergeMetadata!.identity.itemHash && //
              compareParties({ party1: referenceParty, party2: c, getLegalEntityName })
          );

          if (party) justifiedParties.push(party);
          else return `Justified has not been completed ${referenceParty.legalEntityName}`;
        }

        assignMatchingItemHash(justifiedParties, referenceParty);
      }
    }

    return undefined;
  }
}
