import * as React from 'react';

import { Action, Dispatch } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { actionFetchUserPermissions } from 'src/containers/settings/settings/actions/users';
import { DefaultUserSigningRule } from 'src/containers/settings/subscriber-profile/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { RoleEntityModel, UserGroupAccessModel, UserPermissionsFormikValueModel } from '../../models';
import styles, { ClassKeys } from '../../styles';
import FormikPermissions, { FormikPermissionsProps } from './FormikPermissions';

interface OwnProps {
  userId: string;
  isEditMode: boolean;

  isLoading: boolean;
  error?: string;
  initialValues?: UserPermissionsFormikValueModel;

  dispatch: SafeDispatch<Action> | Dispatch<Action>;

  // permission form dynamic condition and format
  groupAccessOptions?: Array<UserGroupAccessModel>;
  userRoleOptions?: Array<RoleEntityModel>;
  isDesignationEditable?: boolean;
  biReportingFeatureFlag?: boolean;
  isPersonalDetailMode: boolean;

  isSuperAdmin: boolean;
  defaultUserSigningRules?: DefaultUserSigningRule[];
  isCurrentUserActive: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class TabPermissions extends React.PureComponent<Props> {
  componentDidMount() {
    const { isEditMode, initialValues, userId, dispatch } = this.props;
    if (isEditMode && !initialValues) {
      dispatch(actionFetchUserPermissions.request(userId)); // the parent will save this data into tabStore and and pass them down to us so we will re-render
    }
  }

  get isLoading() {
    const { initialValues, isLoading, error, isEditMode } = this.props;
    return isEditMode && !error && (isLoading || !initialValues);
  }

  render() {
    const {
      //
      error,
      biReportingFeatureFlag,
      initialValues,
      isEditMode,
      dispatch,
      userId,
      groupAccessOptions = [],
      userRoleOptions = [],
      isDesignationEditable = false,
      isPersonalDetailMode,
      isSuperAdmin,
      defaultUserSigningRules,
      isCurrentUserActive
    } = this.props;

    const props: FormikPermissionsProps = {
      userId,
      initialValues,
      isEditMode,
      error,
      isLoading: this.isLoading,
      dispatch,
      groupAccessOptions,
      userRoleOptions,
      isDesignationEditable,
      isPersonalDetailMode,
      biReportingFeatureFlag: Boolean(biReportingFeatureFlag),
      isSuperAdmin,
      defaultUserSigningRules,
      isCurrentUserActive
    };

    return <FormikPermissions {...props} />;
  }
}

export default withStyles(styles)(TabPermissions);
