import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  () => ({
    root: {
      marginBottom: 16,
      rowGap: 16
    },
    filterBox: {
      marginRight: 16
    }
  }),
  {
    name: 'Filters'
  }
);
