import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionWithdrawParticipantInvitation } from '../actions';

export interface WithdrawInvitationState {
  status: ApiStatus;
  error?: string;
}

const initialState: WithdrawInvitationState = {
  status: 'idle',

  error: undefined
};

const withdrawInvitationReducer = createReducer<
  //
  WithdrawInvitationState,
  Action
>(initialState)
  .handleAction(actionWithdrawParticipantInvitation.request, (state): WithdrawInvitationState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionWithdrawParticipantInvitation.success, (state): WithdrawInvitationState => {
    return {
      ...state,
      status: 'resolved'
    };
  })
  .handleAction(actionWithdrawParticipantInvitation.failure, (state, action): WithdrawInvitationState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default withdrawInvitationReducer;
