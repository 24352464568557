import { LogTypeEnum } from '@sympli/api-gateway/enums';

import http, { NoResponseData } from 'src/utils/http';

export interface CreateWorkspaceLog {
  dateTime: string;
  message: string;
  userId?: string | null;
  participantId?: string | null;
  data: string;
  reference: string;
  workspaceLogType: LogTypeEnum;
  isPublicView: boolean;
  isTriggeredBySystem: boolean;
  messageId?: string;
}

export const createWorkspaceLogs = (workspaceId: string, request: CreateWorkspaceLog[]) => {
  if (!request) return Promise.resolve();
  const url = `/workspaces/${encodeURIComponent(workspaceId)}/logs`;
  return http.post<NoResponseData, CreateWorkspaceLog[]>(url, request);
};
