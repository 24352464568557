import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  (theme: Theme) => ({
    divider: {
      marginTop: 15,
      marginBottom: 10
    },
    summaryRow: {
      fontSize: 13,
      paddingTop: 4,
      paddingBottom: 5
    },
    summaryTitle: {
      marginTop: 10
    },
    disabledText: {
      color: theme.palette.text.disabled
    },
    icon: {
      fontSize: 16,
      paddingRight: 6,
      alignSelf: 'flex-start',
      color: theme.palette.text.primary
    },
    marginLeft: {
      marginLeft: theme.spacing(3)
    },
    statusBadge: {
      marginTop: 13
    },
    summaryTitleDesc: {
      fontWeight: 'bold'
    },
    depositFormLink: {
      fontWeight: 'normal',
      fontSize: 13,
      marginLeft: 16,
      marginTop: -10
    },
    depositFormLink101: {
      fontWeight: '400',
      fontSize: 13,
      marginLeft: -4,
      marginTop: -4
    }
  }),
  {
    name: 'SympliSourceAccountCalculator'
  }
);

export default useStyles;
