import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { RentDetails } from '../../models';

export const RENT_DETAILS_DESCRIPTION_CHARACTER_LIMIT = 200;
export const RENT_DETAILS_TERMS_AND_CONDITIONS_CHARACTER_LIMIT = 200;
const RENT_DETAILS_MCP_NUMBER = 8;

const yupRentDetails = yup.object<RentDetails>({
  amount: yup.mixed(),
  frequency: yup.mixed(),
  description: yup
    .string()
    .trim()
    .required(msg.REQUIRED)
    .max(
      RENT_DETAILS_DESCRIPTION_CHARACTER_LIMIT,
      ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(RENT_DETAILS_DESCRIPTION_CHARACTER_LIMIT)}. ${value.length}/${max} characters`
    )
    .defined(),
  termsAndConditions: yup
    .string()
    .trim()
    .required(msg.REQUIRED)
    .max(
      RENT_DETAILS_TERMS_AND_CONDITIONS_CHARACTER_LIMIT,
      ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(RENT_DETAILS_TERMS_AND_CONDITIONS_CHARACTER_LIMIT)}. ${value.length}/${max} characters`
    )
    .defined(),
  mcpNumber: yup.string().notRequired().trim().max(RENT_DETAILS_MCP_NUMBER, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(RENT_DETAILS_MCP_NUMBER)).defined()
});

export default yupRentDetails;
