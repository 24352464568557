import { NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-26/sa';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// TODO Check correctness of this
export const PARTY_CAPACITY_LOOKUP_OPTIONS: LookupEnumModel<string>[] = restrictedLookupOptions(NECDS_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    NecdsPartyCapacityEnum.Administrator,
    NecdsPartyCapacityEnum.AdministratorAsLawfulAttorney,
    NecdsPartyCapacityEnum.AdministratorDeBonisNon,
    NecdsPartyCapacityEnum.AdministratorOnBehalfOfIncapacitatedExecutor,
    NecdsPartyCapacityEnum.Deceased,
    NecdsPartyCapacityEnum.Elder,
    NecdsPartyCapacityEnum.ElderUndischargedBankrupt,
    NecdsPartyCapacityEnum.Executor,
    NecdsPartyCapacityEnum.ExecutorLeaveReserved,
    NecdsPartyCapacityEnum.Manager,
    NecdsPartyCapacityEnum.Minor,
    NecdsPartyCapacityEnum.ProtectedPersonAgedAndInfirmPersons,
    NecdsPartyCapacityEnum.ProtectedPersonGuardianshipAndAdministration,
    NecdsPartyCapacityEnum.Receiver,
    NecdsPartyCapacityEnum.RegisteredConveyancerOnBehalfOf,
    NecdsPartyCapacityEnum.SolicitorOnBehalfOf,
    NecdsPartyCapacityEnum.SurvivingExecutor,
    NecdsPartyCapacityEnum.UndischargedBankrupt,
    NecdsPartyCapacityEnum.Younger,
    NecdsPartyCapacityEnum.YoungerMinor,
    NecdsPartyCapacityEnum.YoungerUndischargedBankrupt
  ]
});
