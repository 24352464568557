import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    avatar: {
      width: theme.spacing(10), // 80px
      height: theme.spacing(10) // 80px
    },
    avatarWithoutImage: {
      width: theme.spacing(10), // 80px
      height: theme.spacing(10), // 80px
      fontSize: 26,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      color: colors.SYMPLI_GREEN,
      letterSpacing: 1,
      backgroundColor: colors.GREEK_WATERS_TRANSLUCENT
    },
    link: {
      color: 'inherit',
      textDecoration: 'inherit'
    }
  }),
  {
    name: 'UserProfileAvatar'
  }
);
