import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { getDateRange } from 'src/components/date-range-picker/helpers';
import { DateRangeTypeEnum } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum, DashboardDateRangeModel, DashboardTypeEnum } from 'src/containers/dashboard/shared/models';
import { WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import {
  AggregatorComponentBaseProps,
  AggregatorNameEnum,
  AggregatorQuery,
  FinancialDashboardAggregatorApiRequest,
  FinancialDashboardAggregatorApiResponse,
  TomorrowCountModel
} from '../models';

export const payloadResolver = (assignmentType: AssignmentTypeEnum): FinancialDashboardAggregatorApiRequest => {
  const { from, to } = getDateRange(DateRangeTypeEnum.Tomorrow);

  const settlementPeriod: DashboardDateRangeModel = {
    start: from,
    end: to
  };

  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.tomorrowSettlements,
      workspaceStatus: [
        //
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready
      ],
      settlementPeriod
    },
    {
      name: AggregatorNameEnum.tomorrowNotReady,
      workspaceStatus: [
        //
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors
      ],
      settlementPeriod
    }
  ];

  const payload: FinancialDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

function TomorrowCard(props: AggregatorComponentBaseProps) {
  const defaultContent = React.useMemo<TomorrowCountModel>(() => ({ notReady: 0, settlements: 0 }), []);
  const contentResolver = React.useCallback(
    (response: FinancialDashboardAggregatorApiResponse): TomorrowCountModel => ({
      settlements: response.find(d => d.name === AggregatorNameEnum.tomorrowSettlements)?.count ?? 0,
      notReady: response.find(d => d.name === AggregatorNameEnum.tomorrowNotReady)?.count ?? 0
    }),
    []
  );
  return (
    <AggregatorCardWrapper<TomorrowCountModel> //
      payloadResolver={payloadResolver}
      defaultContent={defaultContent}
      contentResolver={contentResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <AggregatorCard
          title="Tomorrow"
          cardType={DashboardTypeEnum.Financial}
          isLoading={isLoading}
          onActionClick={onActionClick}
          isFetchingGridData={isFetchingGridData}
          items={[
            {
              id: AggregatorNameEnum.tomorrowSettlements,
              content: content.settlements,
              status: 'Settlements',
              actionText: 'Show me',
              displaySeparator: true
            },
            {
              id: AggregatorNameEnum.tomorrowNotReady,
              content: content.notReady,
              status: 'Not Ready',
              actionText: 'Show me'
            }
          ]}
        />
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(TomorrowCard);
