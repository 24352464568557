import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0
    },
    hasError: {
      color: theme.palette.error.main
    }
  });

export type ClassKeys = typeof styles;

export default styles;
