import React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconInfoCircle } from '@sympli/ui-framework/icons';

import { IconListItem } from 'src/components/list-item';
import Popover from 'src/components/popover';
import { TeamWorkloadReportAssignedUser } from 'src/containers/dashboard/reports/models';
import { useStyles } from './styles';

export interface Props {
  name: string;
  teamWorkloadReportAssignedNonSuperAdminUsers: Array<TeamWorkloadReportAssignedUser>;
  maxTeamWorkloadReportAccessNumber: number;
}

function TeamWorkloadReportAccessOption({ name, teamWorkloadReportAssignedNonSuperAdminUsers, maxTeamWorkloadReportAccessNumber }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const open = Boolean(anchorEl);
  // make sure id is unique on each render, so there won't be any duplicate id in DOM
  const id = open ? _uniqueId('reports_permissions_') : undefined;
  const classes = useStyles();

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(undefined);
  };

  const renderContent = (teamWorkloadReportAssignedNonSuperAdminUsers?: Array<TeamWorkloadReportAssignedUser>) => {
    return (
      <FlexLayout classes={{ root: classes.flexLayoutRoot }} alignItems="flex-start" flexDirection="column" fullWidth>
        <Typography variant="subtitle2" classes={{ root: classes.subtitle2 }} gutterBottom>
          This report is accessible by up to {maxTeamWorkloadReportAccessNumber} general users within your organisation
          {teamWorkloadReportAssignedNonSuperAdminUsers && teamWorkloadReportAssignedNonSuperAdminUsers.length > 0 ? ', the following people have been granted access:' : null}
        </Typography>
        <FlexLayout flexDirection="column" justifyContent="flex-start">
          {teamWorkloadReportAssignedNonSuperAdminUsers?.map((x, index) => (
            <IconListItem //
              key={x.userId}
              primary={
                <Typography variant="body1" classes={{ root: classes.body1 }}>
                  {x.name}
                </Typography>
              }
              icon={<Typography variant="body1">{`${index + 1}.`}</Typography>}
              linkTo={`/settings/users/${x.userId}/reports-permissions`}
              classes={{ itemRoot: classes.itemRoot }}
            />
          ))}
        </FlexLayout>
      </FlexLayout>
    );
  };

  return (
    <FlexLayout alignItems="center">
      <Box sx={{ mr: 1 }}>{name}</Box>
      <ButtonBase disableRipple aria-describedby={id} onClick={handleOnClick}>
        <IconInfoCircle style={{ color: 'black' }} />
      </ButtonBase>
      <Popover //
        id={id}
        open={open}
        onClose={handleOnClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        hideCloseButton
        arrowPosition="bottom"
        offset={60}
        PaperProps={{
          sx: {
            borderRadius: 0
          }
        }}
      >
        {renderContent(teamWorkloadReportAssignedNonSuperAdminUsers)}
      </Popover>
    </FlexLayout>
  );
}

export default React.memo(TeamWorkloadReportAccessOption);
