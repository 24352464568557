import { QldDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DocumentAttachmentItemModel, DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { ApiReceivingTenancyDetailModel, FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FormTenancyDetailModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DocumentPartyCapacityModel } from '../../components/party-capacity/models';

// this file was automatically generated from models.ts.mustache
export interface ApiMortgage2_24_1Model {
  mortgagors: TenancyPartyModel[];
  mortgageeDetails: ApiReceivingTenancyDetailModel;
  partyBook?: ApiDocumentPartyModel<QldDocumentPartyJustification>[];
  proprietors: any; // Do not use. Used only by backend.
  termsAndConditions: MortgageTermsAndConditionsModel;
  titleReferences: TitleReferenceModel[];
  workspaceTitleReferences?: string[];
  needToGenerateMortgagors: boolean;
  transferees: TenancyPartyModel[];
  //Refers to all attachments of the document
  attachments?: DocumentAttachmentItemModel[];
}

export interface MortgageTermsAndConditionsModel {
  additionalTermsAndConditions: string;
  preRegisteredStandardTermsDocumentReferences?: string[]; // This is obsolete, do not use or copy in new document version.
  preRegisteredStandardTerms?: PreRegisteredStandardTerm[];
  standardTermsDocumentReferences: StandardTermsDocumentReferenceModel[];
  termsAndConditionsAttachment: DocumentAttachmentModel[];
}

export interface PreRegisteredStandardTerm {
  value: string;
  description?: string;
}

export interface StandardTermsDocumentReferenceModel {
  documentReference: string;
}

export interface TitleReferenceModel {
  estateType?: string;
  isPartLandAffected?: boolean;
  isSelected: boolean;
  landDescriptions?: LandDescriptionModel[];
  mortgagors: TenancyPartyModel[];
  reference: string;
  hasTransfer?: boolean;
}

export interface LandDescriptionModel {
  isSelected: boolean;
  landDescriptionComponents?: LandDescriptionComponentModel[];
  legalDescription?: string;
}

export interface LandDescriptionComponentModel {
  name?: string;
  value?: string;
}

export interface MortgagorPartyModel {
  partyBookId: string;
  partyCapacity?: DocumentPartyCapacityModel;
}

export const EMPTY_MORTGAGOR_PARTY: TenancyPartyModel = {
  id: '',
  partyBookId: undefined
};

export type Mortgage2_24_1Model = Omit<ApiMortgage2_24_1Model, 'partyBook' | 'mortgagors' | 'mortgageeDetails'> & {
  partyBook: PartyModel[];
  mortgagors: FormTenancyDetailModel;
  mortgageeDetails: FormReceivingTenancyDetailModel;
};
