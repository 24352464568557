import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    inlineLoader: {
      marginLeft: 10
    },
    separator: {
      marginLeft: 15,
      marginRight: 4,
      fontSize: 28,
      color: `${theme.palette.text.primary}`
    },
    selectItem: {
      width: 280,
      fontSize: 28,
      color: `${theme.palette.text.primary}`,
      border: 'none',
      '& div': {
        paddingTop: 0
      }
    },
    buttons: {
      display: 'inline-flex',
      '& svg': {
        marginRight: 2
      }
    },
    button: {
      marginLeft: 10
    },
    selectReports: {
      display: 'inline-flex'
    },
    iconStar: {
      fill: 'white'
    },
    reportName: {
      fontWeight: 'normal'
    },
    header: {
      height: 40
    }
  }),
  {
    name: 'ReportDetailHeader'
  }
);
