import React, { useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import CaveatorPartyReadonlyArray from './components/caveator-party-readonly-array';
import { WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { getCaveatorsForSelectedCaveat, getCaveatsBeingWithdrawn, getDealingNumbersSelectedInOtherWithdrawalsOfCaveat, getOtherWithdrawalsOfCaveatDocIds } from './helper';
import { WithdrawalOfCaveat2_27_0Model } from './models';
import SectionWithdrawCaveat from './sections/withdraw-caveat';
import { VISIBILITY_CHECK_CAVEATORS, VISIBILITY_CHECK_WITHDRAW_CAVEAT } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<WithdrawalOfCaveat2_27_0Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel, workspaceDocuments } = useDocumentContext();
  const formikProps = useFormikContext<WithdrawalOfCaveat2_27_0Model>();
  const { setValues, values, setFieldValue } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusOnWithdrawCaveatRef = React.useRef<ButtonBaseActions>(null);
  const focusOnCaveatorRef = React.useRef<ButtonBaseActions>(null);
  const { caveators } = values;
  const { workspaceId, participantId, documentId } = queryParams;

  const otherWithdrawalOfCaveatDocumentIds = React.useMemo(() => getOtherWithdrawalsOfCaveatDocIds(documentId, workspaceDocuments), [documentId, workspaceDocuments]);
  const getDealingNumbersSelectedInOtherWithdrawalsOfCaveatWrapper = useCallback(
    async (
      workspaceId: string,
      participantId: string,
      documentIds: string[] //
    ) => getDealingNumbersSelectedInOtherWithdrawalsOfCaveat(workspaceId, participantId, documentIds),
    []
  );

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusOnWithdrawCaveatRef.current || focusOnCaveatorRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  useEffect(() => {
    getDealingNumbersSelectedInOtherWithdrawalsOfCaveatWrapper(workspaceId, participantId, otherWithdrawalOfCaveatDocumentIds) //
      .then(value => setFieldValue(fieldName('dealingNumbersSelectedInOtherWithdrawalsOfCaveat'), value, false));
  }, [otherWithdrawalOfCaveatDocumentIds, participantId, workspaceId, getDealingNumbersSelectedInOtherWithdrawalsOfCaveatWrapper, setFieldValue]);

  const onTitleReferenceChange = React.useCallback(() => {
    setValues(updatedValues => {
      // get list of withdrawable caveats sharing the selected titles
      const withdrawCaveatsList = getCaveatsBeingWithdrawn(updatedValues.caveats, updatedValues.titleReferences);
      const caveators = getCaveatorsForSelectedCaveat(withdrawCaveatsList);

      return {
        ...updatedValues,
        withdrawCaveats: withdrawCaveatsList,
        caveators: caveators
      };
    });
  }, [setValues]);

  return (
    <>
      <Form className={className}>
        <SectionTitleReference //
          name={fieldName('titleReferences')}
          disabled={disabled}
          focusRef={focusOnTitleReferencesRef}
          onChange={onTitleReferenceChange}
          allowPartLandSelection
        />

        {VISIBILITY_CHECK_WITHDRAW_CAVEAT(values) && (
          <>
            <SectionWithdrawCaveat //
              name={fieldName('withdrawCaveats')}
              formikProps={formikProps}
              disabled={disabled}
              focusRef={focusOnWithdrawCaveatRef}
            />

            {VISIBILITY_CHECK_CAVEATORS(values) && (
              <>
                <Section //
                  title={pluralize('Caveator', caveators.length)}
                  data-name={fieldName('caveators')}
                  error={getFormikError(formikProps, fieldName('caveators'))}
                  data-testid="caveators"
                >
                  <CaveatorPartyReadonlyArray //
                    dialogPortalId={dialogPortalId}
                    disabled={disabled}
                    formikProps={formikProps}
                    name={fieldName('caveators')}
                    focusRef={focusOnCaveatorRef}
                  />
                </Section>
                <DocumentAttachments //
                  name={fieldName('additionalAttachments')}
                  minItems={0}
                  attachmentDocumentTypes={WA_WX_ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_LOOKUP_OPTIONS}
                />
              </>
            )}
          </>
        )}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
