import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { Lease2_21_1Model, LessorPartyModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/lease-base/models';
import { NSW_LEASE_TENANCY_COMPONENT_LABEL_CONFIG } from './config';
import { getLessorPartyBookIds, getSelectedTitleReferences } from './helpers';
import DocumentAttachments from './sections/document-attachments';
import LeaseDetails from './sections/lease-details';
import Lessees from './sections/lessees';
import Lessors from './sections/lessors';
import RentDetails from './sections/rent-details';
import TitleReferences from './sections/title-references';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Lease2_21_1Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Lease2_21_1Model>();
  const { setValues } = formikProps;
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleTitleReferenceSelectionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        const selectedTitles = getSelectedTitleReferences(values.titleReferences);
        const selectedTitlesLessorPartyBookIds = getLessorPartyBookIds(selectedTitles);

        let updated = false;
        const updatedLessors = values.lessors.reduce<LessorPartyModel[]>((acc, item) => {
          if (!selectedTitlesLessorPartyBookIds.includes(item.partyBookId)) {
            updated = true;
          } else {
            if (!acc.find(({ partyBookId }) => item.partyBookId === partyBookId)) {
              acc.push(item);
            }
          }
          return acc;
        }, []);

        if (updatedLessors.length < selectedTitlesLessorPartyBookIds.length) {
          selectedTitlesLessorPartyBookIds.reduce<LessorPartyModel[]>((acc, partyBookId) => {
            if (!acc.find(item => item.partyBookId === partyBookId)) {
              acc.push({
                partyBookId
              });
              updated = true;
            }
            return acc;
          }, updatedLessors);
        }

        if (updated) {
          return {
            ...values,
            lessors: updatedLessors
          };
        }
        return values;
      });
    },
    [setValues]
  );

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          focusRef={focusOnTitleReferencesRef}
          onChange={handleTitleReferenceSelectionChange}
        />
        <Lessors name={fieldName('lessors')} />
        <Lessees name={fieldName('lessees')} labelConfig={NSW_LEASE_TENANCY_COMPONENT_LABEL_CONFIG} />
        <LeaseDetails name={fieldName('leaseDetails')} />
        <RentDetails name={fieldName('rentDetails')} />
        <DocumentAttachments name={fieldName('documentAttachments')} />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
