import * as React from 'react';

import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

function PreparedTrustAccountAuthorisationRecord() {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Prepared
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        This document is now <b>ready to be signed</b>.
      </Typography>
    </>
  );
}

export default React.memo(PreparedTrustAccountAuthorisationRecord);
