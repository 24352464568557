import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272 } from 'src/components/layout/constants';

const PAGINATION_HEIGHT_36 = 36;

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    inputContainer: {
      width: WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272,
      padding: '31px 40px 0px 40px',
      backgroundColor: theme.palette.grey[50]
    },
    calendarContainer: {
      width: 415,
      marginRight: 32
    },
    divider: {
      marginBottom: 30,
      marginTop: 30
    },
    marginBottom: {
      marginBottom: 30
    },
    flexGrow: { flexGrow: 1 },
    marginRight: { marginRight: 32 },
    pagination: {
      height: PAGINATION_HEIGHT_36,
      backgroundColor: '#4D5258',
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'space-around',
      flexDirection: 'column'
    },
    declineAndEdit: {
      flexGrow: 1,
      textAlign: 'right'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
