import { Action } from 'redux';

import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { WorkspaceUpdateMessage, WorkspaceUpdateMessageCategoryEnum } from 'src/socket/workspace-update/models';
import { actionFetchTrustAccountAuthorisationRecords } from './actions';
import { TrustAccountAuthorisationRecordUpdateMessage } from './models';

export function handleTrustAccountAuthorisationRecordUpdate(
  //
  dispatch: SafeDispatch<Action>,
  workspaceId: string,
  participantId: string,
  message: WorkspaceUpdateMessage
) {
  if (message.Category !== WorkspaceUpdateMessageCategoryEnum.TrustAccountAuthorisationRecord) return;

  try {
    const updateMessage: TrustAccountAuthorisationRecordUpdateMessage = JSON.parse(message.Message);
    const { trustAccountId, participantId: messageParticipantId } = updateMessage;

    if (messageParticipantId !== participantId) return;

    dispatch(actionFetchTrustAccountAuthorisationRecords.request({ workspaceId, participantId, trustAccountId }));
  } catch (ex) {
    const scope = Logger.scopeWithCustomAttributes({ message });
    Logger.captureException(new InvalidDataError('Invalid message format', message.Category), scope);
  }
}
