import React from 'react';

import { batch, useDispatch } from 'react-redux';

import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase } from '@sympli/api-gateway/shared';
import Logger, { BusinessLogicError, PageActionEnum } from '@sympli/ui-logger';

import { handleRealtimeNotificationUpdate } from 'src/components/global-simple-notification';
import { handleDocumentRealtimeToastMessage, handleLodgementRealtimeToastMessage } from 'src/components/global-simple-notification/helpers';
import { actionFetchDocumentDetail, actionFetchPaymentSummary, actionResetDocumentData } from 'src/containers/documents/actions';
import { handleRealtimeConversationUpdate } from 'src/containers/messenger/helpers';
import { actionFetchAllDirections } from 'src/containers/workspace/financial/all-directions/actions';
import { actionFetchDirections, actionFetchDirectionsList } from 'src/containers/workspace/financial/directions/actions';
import { actionFetchStampDutyDetails } from 'src/containers/workspace/financial/stamp-duty/actions';
import { handleTrustAccountAuthorisationRecordUpdate } from 'src/containers/workspace/financial/trust-account-authorisation-records/helpers';
import { actionFetchOffPlatformTasks } from 'src/containers/workspace/shared/components/off-platform-tasks-container/actions';
import {
  actionFetchWorkspaceActivityLogs,
  actionFetchWorkspaceBasicInfo,
  actionFetchWorkspaceById,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceLatestTransaction,
  actionFetchWorkspaceParticipants,
  actionFetchWorkspaceTasks
} from 'src/containers/workspace/shared/detail/actions';
import { actionFetchWorkspaceReportsFeed } from 'src/containers/workspace/shared/detail/components/tab-workspace-report/actions';
import { useSafeDispatch } from 'src/hooks';
import { unsupportedSocketIoCategoryTemplate, WorkspaceUpdateMessage, WorkspaceUpdateMessageCategoryEnum } from 'src/socket/workspace-update/models';

export default function useWorkspaceUpdateListener({
  //
  workspaceId,
  participantId,
  isLodgementOnly,
  documentId,
  lodgementCases,
  documents
}: {
  workspaceId: string;
  participantId: string;
  isLodgementOnly?: boolean;
  documentId?: string;
  lodgementCases?: LodgementCase[];
  documents?: WorkspaceDocumentSummaryApiResponse[];
}) {
  const dispatch = useSafeDispatch(useDispatch());

  const argsRef = React.useRef<{
    workspaceId: string;
    participantId: string;
    isLodgementOnly?: boolean;
    documentId?: string;
    lodgementCases?: LodgementCase[];
    documents?: WorkspaceDocumentSummaryApiResponse[];
  }>({
    workspaceId,
    participantId,
    isLodgementOnly,
    documentId,
    lodgementCases,
    documents
  });
  argsRef.current = {
    workspaceId,
    participantId,
    isLodgementOnly,
    documentId,
    lodgementCases,
    documents
  };

  return React.useCallback(
    (message: WorkspaceUpdateMessage) => {
      try {
        const { workspaceId, participantId, isLodgementOnly, documentId, lodgementCases, documents } = argsRef.current;

        if (workspaceId !== message.WorkspaceId) {
          const scope = Logger.scopeWithCustomAttributes({
            messageWorkspaceId: message.WorkspaceId,
            participantId,
            message: message.Message,
            categoryEnum: message.Category,
            correlationId: message.CorrelationId,
            currentWorkspaceId: workspaceId
          });
          Logger.captureException(new Error('Realtime service has sent wrong message to the workspace'), scope);
          return;
        }

        Logger.capturePageAction(PageActionEnum.WorkspaceRealtimeMessage, {
          workspaceId,
          participantId,
          message: message.Message,
          categoryEnum: message.Category,
          correlationId: message.CorrelationId
        });

        // check the message type and decide when to refetch the message
        switch (message.Category) {
          case WorkspaceUpdateMessageCategoryEnum.StampDuty:
            batch(() => {
              dispatch(actionFetchStampDutyDetails.request({ workspaceId, participantId }));
              dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
            });
            break;
          case WorkspaceUpdateMessageCategoryEnum.Document:
            handleDocumentRealtimeToastMessage(dispatch, workspaceId, participantId, message);
            dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
            // refresh document detail data when we are on document page (this does not mean we are going to loose data in the document form)
            if (documentId) {
              dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId }));
            } else {
              // otherwise reset the document data so when user visits the same document, they will receive the latest data
              dispatch(actionResetDocumentData({ workspaceId, participantId }));
            }
            break;
          case WorkspaceUpdateMessageCategoryEnum.Lodgement:
            if (lodgementCases && lodgementCases.length > 1 && documents) {
              // For MLC only
              handleLodgementRealtimeToastMessage(dispatch, participantId, message, lodgementCases, documents);
            }

            batch(() => {
              dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
              dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
              dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
              dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
              // due to DynamoDB checking document permission, when a task is created, permission is not update-to-date, need to fetch tasks when LV is done
              dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
              // refresh document detail data when we are on document page (this does not mean we are going to loose data in the document form)
              if (documentId) {
                dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId }));
              } else {
                // otherwise reset the document data so when user visits the same document, they will receive the latest data
                dispatch(actionResetDocumentData({ workspaceId, participantId }));
              }
              if (isLodgementOnly) {
                dispatch(actionFetchPaymentSummary.request({ workspaceId, participantId }));
              }
            });
            break;
          case WorkspaceUpdateMessageCategoryEnum.Direction:
            batch(() => {
              dispatch(actionFetchDirections.request({ workspaceId, participantId }));
              dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
              dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
            });
            break;
          case WorkspaceUpdateMessageCategoryEnum.Task:
            dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
            break;
          case WorkspaceUpdateMessageCategoryEnum.Workspace:
            batch(() => {
              dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
              dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
              dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
              dispatch(actionFetchWorkspaceLatestTransaction.request({ workspaceId }));
              dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
              // due to DynamoDB checking document permission, when a task is created, permission is not update-to-date, need to fetch tasks when LV is done
              dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
              // refresh document detail data when we are on document page (this does not mean we are going to loose data in the document form)
              if (documentId) {
                dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId }));
              } else {
                // otherwise reset the document data so when user visits the same document, they will receive the latest data
                dispatch(actionResetDocumentData({ workspaceId, participantId }));
              }
            });
            break;
          case WorkspaceUpdateMessageCategoryEnum.Messenger:
            handleRealtimeConversationUpdate(dispatch, workspaceId, participantId, message);
            break;
          case WorkspaceUpdateMessageCategoryEnum.OffPlatformTask:
            dispatch(actionFetchOffPlatformTasks.request({ workspaceId }));
            break;
          case WorkspaceUpdateMessageCategoryEnum.Billing:
            if (isLodgementOnly) {
              dispatch(actionFetchPaymentSummary.request({ workspaceId, participantId }));
            }
            break;
          case WorkspaceUpdateMessageCategoryEnum.Notification:
            handleRealtimeNotificationUpdate(dispatch, workspaceId, participantId, message);
            break;
          case WorkspaceUpdateMessageCategoryEnum.Participant:
            dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
            break;
          case WorkspaceUpdateMessageCategoryEnum.TrustAccountAuthorisationRecord:
            handleTrustAccountAuthorisationRecordUpdate(dispatch, workspaceId, participantId, message);
            break;
          case WorkspaceUpdateMessageCategoryEnum.WorkspaceReport:
            dispatch(actionFetchWorkspaceReportsFeed.request({ workspaceId, participantId }));
            break;
          default:
            const scope = Logger.scopeWithCustomAttributes({ workspaceId, participantId });
            Logger.captureException(new BusinessLogicError(unsupportedSocketIoCategoryTemplate(message.Category)), scope);
        }

        // WEB-27741 When in workspace detail page, we should auto fetch the latest workspace logs if
        // any realtime message arrives except for Category.Messenger, because chatting with each other will not cause any log change.
        // TODO make our routes into a constant file, so we can all reference routing from this file rather than hardcode it everywhere
        if (window.location.pathname.endsWith('detail') && message.Category !== WorkspaceUpdateMessageCategoryEnum.Messenger) {
          dispatch(actionFetchWorkspaceActivityLogs.request({ workspaceId, participantId, pageNumber: 1 }));
        }
      } catch (e) {
        Logger.captureException(e);
      }
    },
    [dispatch]
  );
}
