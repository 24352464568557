import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { CurrentPriorityNoticeModel } from '@sympli-mfe/document-forms-framework/components/sections/current-priority-notice/models';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DocumentNotificationModel } from 'src/containers/documents/models';

export interface ApiWithdrawalOfPriorityNoticeModel {
  priorityNotices: CurrentPriorityNoticeModel[];
  partyBook: ApiDocumentPartyModel[];
  workspaceTitleReferences?: string[];
}

export type WithdrawalOfPriorityNoticeModel = Omit<ApiWithdrawalOfPriorityNoticeModel, 'partyBook'> & {
  partyBook: PartyModel[];
};

export const defaultDocumentNotification: DocumentNotificationModel = {
  heading: 'Information populated on this document has not been supplied by the land registry',
  message:
    "Sympli has provided the most current information available, but cannot guarantee it's accuracy. " +
    'Please review this document prior to lodging to ensure all information is true and correct. ' +
    'By continuing to Save and review you acknowledge that you have read this notice.'
};
