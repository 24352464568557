import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { ApplicantPartyModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: applicant
const yupApplicant = yup
  .object<ApplicantPartyModel>()
  .nullable(true)
  .shape({
    partyBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED).min(1, msg.MIN_LENGTH(1)) // suspicious validation, user can't see this!
  });

export default yupApplicant;
