import { defaultMemoize } from 'reselect';

import { arraysIntersectionsBy } from '@sympli-mfe/document-forms-framework/utils';

import { InterestOnTitle, SubLease2_21_1Model, TitleReferenceModel } from './models';

export const getInterestsOnTitle = <T extends Pick<SubLease2_21_1Model, 'titleReferences'>>(values: T): InterestOnTitle[] => {
  const commonInterestsOnTitle = arraysIntersectionsBy(
    getSelectedTitleReferences(values.titleReferences).map(x => x.interestsOnTitle),
    'dealingNumber'
  ) as InterestOnTitle[];

  if (commonInterestsOnTitle.length === 1) {
    commonInterestsOnTitle[0].isSelected = true;
  }

  return commonInterestsOnTitle;
};

export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});
