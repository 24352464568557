import { createAsyncAction } from 'typesafe-actions';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import { JurisdictionApiResponse, JurisdictionDetailApiResponse, JurisdictionRevenueOfficeApiResponse } from '../jurisdictions/models';

export const actionFetchJurisdictionSummaryFeed = createAsyncAction(
  //
  'FETCH_JURISDICTIONS_SUMMARY_FEED',
  'FETCH_JURISDICTIONS_SUMMARY_FEED_SUCCESS',
  'FETCH_JURISDICTIONS_SUMMARY_FEED_ERROR'
)<
  //
  undefined,
  { data: JurisdictionApiResponse[] },
  { error: Error }
>();

export const actionFetchJurisdictionsFeed = createAsyncAction(
  //
  'FETCH_JURISDICTIONS_FEED',
  'FETCH_JURISDICTIONS_FEED_SUCCESS',
  'FETCH_JURISDICTIONS_FEED_ERROR'
)<
  //
  undefined,
  { data: JurisdictionsEnum[] },
  { error: Error }
>();

export const actionFetchJurisdictionDetail = createAsyncAction(
  //
  'FETCH_JURISDICTION_DETAIL',
  'FETCH_JURISDICTION_DETAIL_SUCCESS',
  'FETCH_JURISDICTION_DETAIL_ERROR'
)<
  //
  JurisdictionsEnum,
  { data: JurisdictionDetailApiResponse },
  { error: Error }
>();

export const actionFetchJurisdictionRevenueOffice = createAsyncAction(
  //
  'FETCH_JURISDICTION_REVENUE_OFFICE',
  'FETCH_JURISDICTION_REVENUE_OFFICE_SUCCESS',
  'FETCH_JURISDICTION_REVENUE_OFFICE_ERROR'
)<
  //
  JurisdictionsEnum,
  { data: JurisdictionRevenueOfficeApiResponse },
  { error: Error }
>();
