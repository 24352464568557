import React, { useMemo } from 'react';

import { ClassNameMap } from '@mui/styles/withStyles';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { RadioClassKeys } from '@sympli/ui-framework/components/form/base-components/radio';
import currencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { YES_NO_LOOKUP_OPTIONS } from '../../enums';
import { MonetaryConsiderationModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function MonetaryConsideration({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const fieldName = createModelKeyAppender<MonetaryConsiderationModel>(name);
  const radioFieldClasses = useMemo<Partial<ClassNameMap<keyof ReturnType<RadioClassKeys>>>>(
    () => ({
      formControlLabel: classes.radioFormControlLabel
    }),
    [classes]
  );

  return (
    <>
      <FormGroup //
        title="Details of transaction in AUD ($)"
        fieldAreaDirection="row"
      >
        <Field //
          placeholder="0.00"
          label="Total sale price (GST inclusive)"
          name={fieldName('purchasePrice')}
          component={currencyInputField}
          disabled={true}
          format="number"
          className={classes.fieldMarginRight}
        />
        <Field //
          placeholder="0.00"
          label="GST amount (if applicable)"
          name={fieldName('gst')}
          component={currencyInputField}
          disabled={disabled}
          format="number"
          validationOverflowDirection="horizontal"
        />
      </FormGroup>
      <FormGroup //
        title="Date of contract"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('dateOfContractForSale')}
            component={DatePickerField}
            disabled={disabled}
            validationOverflowDirection="horizontal"
          />
        )}
      </FormGroup>
      <FormGroup //
        title="Purchase price includes"
        fieldAreaDirection="column"
      >
        <Field //
          label="Items which were not fixed improvements"
          name={fieldName('nonFixedImprovementsIncludedIndication')}
          component={RadioField}
          disabled={disabled}
          options={YES_NO_LOOKUP_OPTIONS}
          classes={radioFieldClasses}
        />
        <Field //
          label="Land not in accompanying instrument"
          name={fieldName('otherLandIncludedIndication')}
          component={RadioField}
          disabled={disabled}
          options={YES_NO_LOOKUP_OPTIONS}
          classes={radioFieldClasses}
        />
      </FormGroup>
    </>
  );
}

export default React.memo(MonetaryConsideration);
