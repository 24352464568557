import { FormatterInputModel } from '@sympli/ui-framework/components/table';

import ThreeLineContent from 'src/containers/dashboard/shared/formatters/ThreeLineContent';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { dateTimeLine } from 'src/utils/formatters';
import { ArchivedInvitationLogsActionEnum } from '../models';
import { Row } from './InvitationsCardDetail';

export function dateFormatter({ row }: FormatterInputModel<Row>) {
  const originalDate = row.date;
  const [date, localTime] = dateTimeLine(originalDate.userLocalTime, 'd mmm|h:MMTT').split('|');

  return originalDate.userTimezoneAbbreviation !== originalDate.workspaceTimezoneAbbreviation ? (
    <ThreeLineContent
      //
      first={date}
      second={`${dateTimeLine(originalDate.workspaceLocalTime, 'h:MMTT')} ${originalDate.workspaceTimezoneAbbreviation}`}
      third={`${localTime} ${originalDate.userTimezoneAbbreviation}`}
      primaryClassName={`font-[500] text-[14px] leading-[14px]'}`}
      secondaryClassName={`font-[400] text-[12px] leading-[12px]'}`}
      thirdClassName={`font-[400] text-[12px] leading-[12px]'}`}
    />
  ) : (
    <ThreeLineContent
      //
      first={date}
      second={`${localTime} ${originalDate.userTimezoneAbbreviation}`}
      primaryClassName={`font-[500] text-[14px] leading-[20px]'}`}
      secondaryClassName={`font-[400] text-[12px] leading-[20px]'}`}
    />
  );
}

export function inviteLogFormatter({ row }: FormatterInputModel<Row>) {
  return (
    <div className="text-[12px] leading-[14px]">
      <div className="truncated font-[700] text-[var(--neutral-1000)]">{row.inviteLog < 9 ? `0${row.inviteLog + 1}` : row.inviteLog + 1}</div>
    </div>
  );
}

export const ActionTypeMapping: Record<ArchivedInvitationLogsActionEnum, string> = {
  [ArchivedInvitationLogsActionEnum.Sent]: 'Sent',
  [ArchivedInvitationLogsActionEnum.Accepted]: 'Accepted',
  [ArchivedInvitationLogsActionEnum.Declined]: 'Declined',
  [ArchivedInvitationLogsActionEnum.Withdrawn]: 'Withdrawn',
  [ArchivedInvitationLogsActionEnum.Forwarded]: 'Forwarded'
};

export const getVariant = (type: ArchivedInvitationLogsActionEnum): 'ok' | 'warning' | 'error' | 'neutral' => {
  switch (type) {
    case ArchivedInvitationLogsActionEnum.Declined:
      return 'error';
    case ArchivedInvitationLogsActionEnum.Forwarded:
    case ArchivedInvitationLogsActionEnum.Withdrawn:
      return 'neutral';
    case ArchivedInvitationLogsActionEnum.Accepted:
    default: // Sent is not going to have vertical status bar, so it falls into default
      return 'ok';
  }
};

export function actionFormatter({
  row,
  tooltipHandlers,
  primaryDataBinding
}: FormatterInputModel<Row> & {
  primaryDataBinding?: string;
}) {
  const { action } = row;
  const variant = getVariant(action.type);

  return (
    <div className="text-[12px] leading-[14px]">
      {action.userName && (
        <div className="truncated font-[700] text-[var(--neutral-1000)]" {...tooltipHandlers} data-binding={primaryDataBinding}>
          {action.userName}
        </div>
      )}
      {action.type === ArchivedInvitationLogsActionEnum.Sent ? (
        <div className="text-[10px] text-[var(--neutral-800)] font-[500] uppercase">{ActionTypeMapping[action.type]}</div>
      ) : (
        <VerticalStatusLine //
          text={ActionTypeMapping[action.type]}
          variant={variant}
          textClassName="truncate"
          tooltipHandlers={tooltipHandlers}
        />
      )}
    </div>
  );
}

export function messageFormatter<
  //
  R extends Row,
  V extends {
    message: string;
  }
>({ value }: FormatterInputModel<R, V>) {
  return (
    <div className="text-[12px] leading-[14px]">
      <div className="font-[500] text-[var(--neutral-1000)]">{value.message}</div>
    </div>
  );
}
